import React, { useEffect, useState } from 'react'
import { connect, useSelector } from "react-redux";
import { setLoading, setSnackInfo } from '../redux/actions/appAction'
import { Table, TableBody, TableCell, TableHead, TableContainer, TableRow, withStyles, Paper, TableFooter, TablePagination } from '@material-ui/core';
import { getAllMaterials } from '../redux/actions/vendorAction';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage'
import PropTypes from 'prop-types';
import RaiseMaterialTransferView from './raiseMaterialTransferView';
import { CSVLink } from "react-csv";
import HttpService from '../services/httpService';

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles({
  table: {
    minWidth: 500,
  },
  textField: {
    width: "150px"
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#ccc",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const StocksView = (props) => {

  const classes = useStyles2();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [allMaterials,setAllMaterials] = useState([]);
  const [csvData, setCsvData] = useState([])

  useEffect(() => {
    let materialData = [["Name", "Available Qty","Raised Qty"]]
    allMaterials.forEach(material => {
      materialData.push([material?.doc?.name, material?.doc?.stocks?.[`${props.TYPE}`]?.available, material?.mtr?.[`${props.TYPE}`]])
    })
    setCsvData(materialData)
  }, [allMaterials, props.TYPE])

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, allMaterials.length - page * rowsPerPage)

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    getAllMaterials()
    // eslint-disable-next-line
  }, [])

  const getAllMaterials = async () => {
    props.setLoading(true)
    try {
      const response = await HttpService.getAllMaterialsStock(props.TYPE);
      setAllMaterials(response.data);
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.mwessage ?? "Something Went Wrong", "error")
    }
    props.setLoading(false)
  }
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between " }}>
        <CSVLink data={csvData}>Download CSV</CSVLink>
        <RaiseMaterialTransferView {...props} />
      </div>
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Sl No</StyledTableCell>
              <StyledTableCell>Material Name</StyledTableCell>
              <StyledTableCell>DP Code</StyledTableCell>
              <StyledTableCell>External Code</StyledTableCell>
              <StyledTableCell>Available Qty</StyledTableCell>
              <StyledTableCell>Consumed Qty</StyledTableCell>
              <StyledTableCell>MTR Raised</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? allMaterials.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : allMaterials
            ).map((data, i) => (
              <StyledTableRow key={i + 1}>
                <StyledTableCell >{i + 1}</StyledTableCell>
                <StyledTableCell >{data?.doc?.name}</StyledTableCell>
                <StyledTableCell >{data?.doc?.code}</StyledTableCell>
                <StyledTableCell >{data.doc.aliasName ? data.doc.aliasName : "null"}</StyledTableCell>
                <StyledTableCell >{data?.doc?.stocks?.[`${props.TYPE}`]?.available}</StyledTableCell>
                <StyledTableCell >{data?.doc?.stocks?.[`${props.TYPE}`]?.consumed}</StyledTableCell>
                <StyledTableCell >{data?.mtr?.[`${props.TYPE}`]}</StyledTableCell>
              </StyledTableRow>
            ))}

            {emptyRows > 0 && (
              <StyledTableRow style={{ height: 53 * emptyRows }}>
                <StyledTableCell colSpan={8} />
              </StyledTableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 20, 50, { label: 'All', value: -1 }]}
                colSpan={8}
                count={allMaterials.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  )
}

export default connect(null, { setLoading, setSnackInfo, getAllMaterials })(StocksView)
