import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { setLoading, setSnackInfo } from '../../../redux/actions/appAction';
import HttpService from '../../../services/httpService'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ApprovePaymentRequestView from '../../../components/approvePaymentRequestView';

const ApprovePayments = (props) => {

  const [radioValue, setRadioValue] = useState("PO")
  const [poSoPaymentRequest, setPoSoPaymentRequest] = useState([])

  useEffect(() => {
    if (radioValue === "PO")
      getAllProcurementsPaymentsForApproval()
    else
      getAllServicePaymentsForApproval()
    // eslint-disable-next-line
  }, [radioValue])

  const getAllProcurementsPaymentsForApproval = async () => {
    props.setLoading(true)
    try {
      const response = await HttpService.getOpenProcurementsPaymentRequest()
      setPoSoPaymentRequest(response.data)
    } catch (error) {
      console.log(error)
      setPoSoPaymentRequest([])
      props.setSnackInfo(error?.response?.data?.message ?? "Something Went Wrong", "error")
    }
    props.setLoading(false)
  }

  const getAllServicePaymentsForApproval = async () => {
    props.setLoading(true)
    try {
      const response = await HttpService.getAllPaymentRequestForSo()
      setPoSoPaymentRequest(response.data)
    } catch (error) {
      console.log(error)
      setPoSoPaymentRequest([])
      props.setSnackInfo(error?.response?.data?.message ?? "Something Went Wrong", "error")
    }
    props.setLoading(false)
  }

  const handleRadioChange = (e) => {
    setRadioValue(e.target.value)
    if (e.target.value === "PO")
      getAllProcurementsPaymentsForApproval()
    else
      getAllServicePaymentsForApproval()
  }

  return (
    <div>
      <FormControl component="fieldset">
        <RadioGroup aria-label="type" name="type" value={radioValue} onChange={handleRadioChange}>
          <div className='modal-header-radio'>
            <FormControlLabel value="PO" control={<Radio />} label="PO" />
            <FormControlLabel value="SO" control={<Radio />} label="SO" />
          </div>
        </RadioGroup>
      </FormControl>
      {poSoPaymentRequest?.length > 0 ? <div>
        <div>{`${radioValue === "PO" ? 'Procurement' : ' Service'}`} Order Payment Request</div>
        <ApprovePaymentRequestView PAYMENTREQUESTS={poSoPaymentRequest} REFRESH={radioValue === "PO" ? getAllProcurementsPaymentsForApproval : getAllServicePaymentsForApproval} RADIOVALUE={radioValue} />
      </div > : <div>  {`No ${radioValue} payment request raised`}</div>
      }
    </div>
  )
}

export default connect(null, { setLoading, setSnackInfo })(ApprovePayments)
