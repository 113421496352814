import React, { useState, useEffect } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { EyeOutlined } from '@ant-design/icons'
import HttpService from '../services/httpService';
import { connect } from 'react-redux'
import { setLoading, setSnackInfo } from '../redux/actions/appAction';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 850,
  }
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#ccc",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const PoSoForApprovalView = (props) => {
  const classes = useStyles();
  const [poSoForApproval, setPoSoForApproval] = useState(props.POSOFORAPPOVAL)

  useEffect(() => {
    setPoSoForApproval(props.POSOFORAPPOVAL)
  }, [props.POSOFORAPPOVAL])

  const generateSo = async (soId) => {
    props.setLoading(true)
    try {
      const result = await HttpService.generateSO(soId)
      window.open(result.data.link)
      props.REFRESHDATA()
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
    }
    props.setLoading(false)
  }

  const generatePo = async (poId) => {
    props.setLoading(true)
    try {
      const result = await HttpService.generatePO(poId)
      window.open(result.data.link)
      props.REFRESHDATA()
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
    }
    props.setLoading(false)
  }

  return (
    <div>
      {poSoForApproval?.length > 0 ? <div>
        <div style={{ fontSize: 18, marginBottom: 10 }}><b>{`${props.RADIOVALUE === "PO" ? 'Procurement' : ' Service'}`} Order Overview</b></div>
        <TableContainer component={Paper}>
          <Table className={classes.table} size="small" aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Sl No</StyledTableCell>
                <StyledTableCell>Order Id</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Code ClientName</StyledTableCell>
                <StyledTableCell>Vendor</StyledTableCell>
                <StyledTableCell>Total</StyledTableCell>
                <StyledTableCell>Created At</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {poSoForApproval?.map((data, i) => <StyledTableRow key={i + 1}>
                <StyledTableCell>{i + 1}</StyledTableCell>
                <StyledTableCell>{data?.orderId}</StyledTableCell>
                <StyledTableCell>{data?.status}</StyledTableCell>
                <StyledTableCell>{`${data?.code} ${data?.client}`}</StyledTableCell>
                <StyledTableCell>{data?.vendorName}</StyledTableCell>
                <StyledTableCell>{data && data.total!==null?data.total.toFixed(2):0}</StyledTableCell>
                <StyledTableCell>{data?.createdAt}</StyledTableCell>
                <StyledTableRow>
                  {data.status === "Approved" && <StyledTableCell style={{ cursor: "pointer" }} onClick={() => props.RADIOVALUE === "PO" ? generatePo(data?._id) : generateSo(data?._id)}>Generate {`${props.RADIOVALUE}`}</StyledTableCell>}
                  <StyledTableCell style={{ cursor: "pointer" }} onClick={() => props.RADIOVALUE === "SO" ? props.OPENDETAILSMODAL(data?.orderId, "Approve SO") : props.OPENDETAILSMODAL(data?._id, "Approve PO")}>View <EyeOutlined /></StyledTableCell>
                </StyledTableRow>
              </StyledTableRow>)}
            </TableBody>
          </Table>
        </TableContainer>
      </div > : <div> {`No ${props.RADIOVALUE} Pending For Approval`}</div>
      }
    </div>
  )
}

export default connect(null, { setLoading, setSnackInfo })(PoSoForApprovalView)
