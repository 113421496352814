import React, { useState, useEffect } from 'react'
import HttpService from '../../../services/httpService'
import { connect } from 'react-redux'
import { setLoading, setSnackInfo } from '../../../redux/actions/appAction'
import PaymentRequestView from '../../../components/paymentRequestView'
import moment from 'moment'
import { Modal } from 'antd';
// import 'antd/dist/antd.css';
import Button from '@material-ui/core/Button';
import ApprovePaymentRequestView from '../../../components/approvePaymentRequestView'

const Payment = (props) => {

  const [openPoForPayment, setOpenPoForPayment] = useState([])
  const [raisePaymentRequestModal, setRaisePaymentRequestModal] = useState(false)
  const [paymentRequestForOpenPO, setPaymentRequestForOpenPO] = useState([])
  useEffect(() => {
    getOpenProcurementsPaymentRequest()
    // eslint-disable-next-line
  }, [])

  const getOpenProcurementsPaymentRequest = async () => {
    props.setLoading(true)
    try {
      const result = await HttpService.getOpenProcurementsPaymentRequest("Open")
      setPaymentRequestForOpenPO(result.data)
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  const openRaisePaymentModal = () => {
    setRaisePaymentRequestModal(true)
    getOpenProcurements()
  }

  const getOpenProcurements = async () => {
    props.setLoading(true)
    try {
      const result = await HttpService.getOpenProcurements()
      const list = result.data.map(item => {
        let remainingAmount = Number(item?.total)
        let obj = {
          orderId: item?.orderId,
          code: "OPEN",
          vendorName: item?.vendorId?.name,
          createdAt: moment(item?.createAt).format("DD-MM-YYYY"),
          status: item?.status,
          total: item?.total,
          link: item?.poLink,
          id: item?._id,
        }
        if (item?.paymentRequests?.length > 0) {
          item?.paymentRequests?.map(request => {
            if (request.status === "Approved") {
              remainingAmount -= request.amount
            }
            return null
          })
        }
        obj.remainingAmount = remainingAmount.toFixed(2)
        return obj
      })
      setOpenPoForPayment(list)
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  const closeModal = () => {
    setRaisePaymentRequestModal(false)
    getOpenProcurementsPaymentRequest()
  }
  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row-reverse", marginBottom: 20 }}>
        <Button variant="contained" color="primary" onClick={openRaisePaymentModal}>
          Raise Payment Request
        </Button>
      </div>
      <ApprovePaymentRequestView PAYMENTREQUESTS={paymentRequestForOpenPO} RADIOVALUE={"PO"} REFRESH={closeModal} />
      <Modal
        title="Raise Open Purchase Order"
        centered
        visible={raisePaymentRequestModal}
        onCancel={() => setRaisePaymentRequestModal(false)}
        width={1000}
        maskClosable={false}
        footer={null}
      >
        <PaymentRequestView RADIOVALUE={"PO"} PAYMENTLIST={openPoForPayment} REFRESH={closeModal} />
      </Modal>
    </div>
  )
}

export default connect(null, { setLoading, setSnackInfo })(Payment)
