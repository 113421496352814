import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import HttpService from '../../../services/httpService'
import { setLoading, setSnackInfo } from '../../../redux/actions/appAction'
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Button, FormControl, Box, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 700,
    },
    root: {
        width: '100%',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
}));

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#ccc",
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const ManageMarketingSource = (props) => {
    const classes = useStyles();
    const [marketingSourceLists, setMarketingSourceLists] = useState([]);
    const [openMarketingSouceModal, setOpenMarketingSouceModal] = useState(false);
    const [checked, setChecked] = useState(true);
    const [marketingSourceFormData, setMarketingSourceFormData] = useState({
        name: ""
    })

    const { name } = marketingSourceFormData

    useEffect(() => {
        getMarketingSource()
        // eslint-disable-next-line
    }, [])

    const getMarketingSource = async () => {
        props.setLoading(true)
        try {
            const response = await HttpService.getLeadMaster();
            setMarketingSourceLists(response.data);
        } catch (error) {
            console.log(error, "Marketing Source not found")
        }
        props.setLoading(false)
    }

    const saveNewMarketingSouce = async () => {
        props.setLoading(true)
        try {
            const response = await HttpService.createMarketingSourceLead(marketingSourceFormData);
            console.log(response.data, "Response");
            props.setSnackInfo(response.data && response.data, 'success')
            setOpenMarketingSouceModal(false);
            getMarketingSource()
        } catch (error) {
            props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const changeStatus = async (id, e, i) => {
        console.log(id, "id", e.target.checked);
        setChecked(e.target.checked);
        let statusObj = {
            isActive: e.target.checked
        }
        props.setLoading(true)
        try {
            const response = await HttpService.changeSourceStatus(id, statusObj);
            console.log(response.data, "Response");
            props.setSnackInfo(response.data && response.data, 'success')
            getMarketingSource()
        } catch (error) {
            props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    return (
        <div>
            <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: 10 }}>
                <Button onClick={() => setOpenMarketingSouceModal(true)} variant="contained" color="primary" >
                    Add Marketing Source
                </Button>
                <Dialog open={openMarketingSouceModal} onClose={() => setOpenMarketingSouceModal(false)}>
                    <DialogTitle>Add Marketing Source</DialogTitle>
                    <DialogContent>
                        <Box sx={{ minWidth: 450 }}>
                            <FormControl fullWidth style={{ marginBottom: "15px" }}>
                                <TextField className={classes.textField} name="name" value={name} onChange={(e) => setMarketingSourceFormData({ ...marketingSourceFormData, [e.target.name]: e.target.value })} label="Marketing Source Name" type="text" variant="outlined" />
                            </FormControl>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenMarketingSouceModal(false)}>Cancel</Button>
                        <Button onClick={saveNewMarketingSouce} variant="contained" color="primary">Save</Button>
                    </DialogActions>
                </Dialog>
            </div>
            <Paper>
                <TableContainer component={Paper}>
                    <Table className={classes.table} stickyHeader aria-label="sticky table">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell>Sr. No.</StyledTableCell>
                                <StyledTableCell>Source</StyledTableCell>
                                <StyledTableCell>Action</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {marketingSourceLists.map((row, i) => {
                                return (
                                    <StyledTableRow key={i + 1}>
                                        <StyledTableCell>{i + 1}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row"> {row?.name} </StyledTableCell>
                                        <StyledTableCell>
                                            {
                                                row?.isActive === true ?
                                                <Checkbox
                                                    checked={checked}
                                                    onChange={(e) => changeStatus(row._id, e, i)}
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                />
                                                :
                                                <Checkbox
                                                    checked=''
                                                    onChange={(e) => changeStatus(row._id, e, i)}
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                />
                                            }

                                        </StyledTableCell>
                                    </StyledTableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    )

}

export default connect(null, { setLoading, setSnackInfo })(ManageMarketingSource)