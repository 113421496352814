import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { getAllPaymentModes } from '../../../redux/actions/projectAction'
import { getAllPaymentPartners } from '../../../redux/actions/projectAction'
import { setLoading, setSnackInfo } from '../../../redux/actions/appAction'
import { Table, TableBody, TextField, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import HttpService from '../../../services/httpService';
import Autocomplete from '@material-ui/lab/Autocomplete';
import EditIcon from "@material-ui/icons/Edit";
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 850,
  },
  addPaymentModeButton: { backgroundColor: 'white', color: '#14419c', padding: '1', marginRight: '0.5em', marginLeft: '0.5em', borderRadius: '20px', "&:hover": { backgroundColor: "#14419c", color: 'white' } },
  editButton: { backgroundColor: 'white', color: '#3d444f', padding: '1', marginRight: '0.5em', borderRadius: '20px', "&:hover": { backgroundColor: "#3d444f", color: 'white' } },
  approveButton: { backgroundColor: 'white', color: '#157525', padding: '1', marginRight: '0.5em', marginLeft: '0.5em', borderRadius: '20px', "&:hover": { backgroundColor: "#157525", color: 'white' } },
  deleteButton: { backgroundColor: 'white', color: '#9c261e', padding: '1', marginRight: '0.5em', borderRadius: '20px', "&:hover": { backgroundColor: "#9c261e", color: 'white' } },
  rejectButton: { backgroundColor: 'white', color: '#9c261e', padding: '1', marginRight: '0.5em', borderRadius: '20px', "&:hover": { backgroundColor: "#9c261e", color: 'white' } },
  yesButton: { backgroundColor: 'white', color: '#14419c', padding: '1', marginRight: '0.5em', marginLeft: '0.5em', borderRadius: '20px', "&:hover": { backgroundColor: "#14419c", color: 'white' } },
  noButton: { backgroundColor: 'white', color: '#151921', padding: '1', marginRight: '0.5em', borderRadius: '20px', "&:hover": { backgroundColor: "#151921", color: 'white' } },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#ccc",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const ManagePaymentModes = (props) => {
  const classes = useStyles();

  useEffect(() => {
    // getAllUnits()
    getAllPaymentModes()
    getAllPaymentPartners()
    // eslint-disable-next-line
  }, [])

  const [showAddPaymentModeModal, setShowAddPaymentModeModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [selectedPaymentPartner, setSelectedPaymentPartner] = useState({})
  const [selectedPaymentType, setSelectedPaymentType] = useState({})
  const [paymentTypes, SetPaymentTypes] = useState([{name: 'Automatic'}, {name: 'Manual'}])
  // const [unitDetails, setUnitDetails] = useState({
  //   id: "",
  //   name: "",
  //   description: ""
  // })
  const [paymentModesDetails, setPaymentModesDetails] = useState({
    name: "",
    type: {},
    paymentPartner: {}
  })
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState("")

  // const { name, description } = unitDetails
  const { name, paymentPartner, type } = paymentModesDetails

  // const getAllUnits = async () => {
  //   props.setLoading(true)
  //   try {
  //     await props.getAllUnits()
  //   } catch (error) {
  //     console.error(error)
  //     props.setSnackInfo('Error fetching UOM', "error")
  //   }
  //   props.setLoading(false)
  // }

  const getAllPaymentModes = async () => {
    props.setLoading(true)
    try {
      console.log('getAllPaymentModes function called')
      await props.getAllPaymentModes()
    } catch (error) {
      console.error(error)
      props.setSnackInfo('Error fetching PaymentModes', "error")
    }
    props.setLoading(false)
  }

  const { userDetails } = useSelector(state => state.user)

  const getAllPaymentPartners = async () => {
    props.setLoading(true)
    try {
      console.log('getAllPaymentPartners function called')
      await props.getAllPaymentPartners()
    } catch (error) {
      console.error(error)
      props.setSnackInfo('Error fetching PaymentPartners', "error")
    }
    props.setLoading(false)
  }
  // const { units } = useSelector(state => state.project)
  const { paymentModes } = useSelector(state => state.project)
  const { paymentPartners } = useSelector(state => state.project)


  const handleDialogClose = () => {
    // setUnitDetails({ name: "", description: "" })
    setPaymentModesDetails({name: "", paymentPartner: {}, type: {}})
    
    setShowAddPaymentModeModal(false)
  }

  const addPaymentMode = async () => {
    props.setLoading(true)
    try {

      console.log('paymentModesDetails', paymentModesDetails)
      // if(!paymentModesDetails.name || !paymentModesDetails.paymentPartner || !paymentModesDetails.type)
      // {
        
      //   props.setLoading(false)
      //   props.setSnackInfo('Please fill all the details', "error")
      //   handleDialogClose()
      //   getAllPaymentModes()
      //   return
      // }
      const response = await HttpService.addPaymentMode({ name, paymentPartner, type, createdBy: userDetails._id })
      props.setSnackInfo(response?.data, "success")
      handleDialogClose()
      getAllPaymentModes()
    } catch (error) {
      console.error(error)
      // props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  const editPaymentMode = (data) => {
    console.log('data', data)
    // setUnitDetails({ id: data?._id, name: data?.name, descirption: data?.descirption })
    setPaymentModesDetails({ id: data?._id, name: data?.name, paymentPartner: data?.paymentPartner, type: {name: data?.type} })
    setShowAddPaymentModeModal(true)
    setIsEdit(true)
  }

  const updatePaymentMode = async () => {
    props.setLoading(true)
    try {
      console.log('paymentModesDetails', paymentModesDetails)
      const response = await HttpService.updatePaymentMode(paymentModesDetails)
      getAllPaymentModes()
      
      // console.log('paymentModes', paymentModes)
      handleDialogClose()
      setIsEdit(false)
      props.setSnackInfo(response?.data, "success")
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  const closeDeleteModal = () => { setShowDeleteModal(false); setDeleteId("") }

  const deletePaymentMode = async () => {
    props.setLoading(true)
    try {
      console.log('deleteId', deleteId)
      await HttpService.deletePaymentMode(deleteId)
      setShowDeleteModal(false)
      setDeleteId("")
      getAllPaymentModes()
      props.setSnackInfo("Payment Mode Deleted Successfully", "success")
    } catch (error) {
      console.log(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  return (
    <div>
      <div style={{ marginBottom: 10, fontSize: 30, textAlign: 'center' }}><b>All Payment Modes</b></div>
      <div style={{display: 'flex', justifyContent: 'flex-end'}}><Button className={classes.addPaymentModeButton} onClick={() => { setShowAddPaymentModeModal(true); setIsEdit(false) }} variant="contained" color="primary" >Add Payment Mode</Button></div>
      <div style={{ marginBottom: 10 }}></div>

      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell style={{textAlign: 'center'}}>Sl No</StyledTableCell>
              <StyledTableCell style={{textAlign: 'center'}}>Name</StyledTableCell>
              <StyledTableCell style={{textAlign: 'center'}}>Payment Partner</StyledTableCell>
              <StyledTableCell style={{textAlign: 'center'}}>Type</StyledTableCell>
              <StyledTableCell style={{textAlign: 'center'}}>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paymentModes?.map((data, i) => <StyledTableRow key={i + 1}>
              <StyledTableCell style={{textAlign: 'center'}}>{i + 1}</StyledTableCell>
              <StyledTableCell style={{textAlign: 'center'}}>{data?.name}</StyledTableCell>
              <StyledTableCell style={{textAlign: 'center'}}>{data?.paymentPartner.name}</StyledTableCell>
              <StyledTableCell style={{textAlign: 'center'}}>{data?.type}</StyledTableCell>
              <StyledTableCell style={{ cursor: "pointer" }} >
                <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                  {/* <Tooltip title="Edit"><EditOutlined style={{ cursor: "pointer" }} onClick={() => editPaymentMode(data)} /></Tooltip> */}
                  <Tooltip title="Edit" placement="top"><Button className={classes.editButton} variant="contained" onClick={() => editPaymentMode(data)}><EditIcon /></Button></Tooltip>
                  {/* <Tooltip title="Delete" onClick={() => { setShowDeleteModal(true); setDeleteId(data?._id) }}><DeleteOutlined /></Tooltip> */}
                  <Tooltip title="Delete" placement="top"><Button className={classes.deleteButton} variant="contained" onClick={() => { setShowDeleteModal(true); setDeleteId(data?._id) }}><DeleteOutlined /></Button></Tooltip>
                </div>
              </StyledTableCell>
            </StyledTableRow>)}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={showAddPaymentModeModal}
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth={'sm'}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{`${isEdit ? 'Update' : 'Enter'} Details`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {/* <TextField label="Name" name="name" value={name} style={{ marginBottom: 10 }} fullWidth onChange={(e) => setUnitDetails({ ...unitDetails, [e.target.name]: e.target.value })} variant="outlined" />
            <TextField label="Description" name="description" value={description} style={{ marginBottom: 10 }} fullWidth onChange={(e) => setUnitDetails({ ...unitDetails, [e.target.name]: e.target.value })} variant="outlined" /> */}
            <TextField label="Name" name="name" value={name} style={{ marginBottom: 10 }} fullWidth onChange={(e) => setPaymentModesDetails({ ...paymentModesDetails, [e.target.name]: e.target.value })} variant="outlined" />
            {/* <TextField label="PaymentPartner" name="paymentPartner" value={paymentPartner} style={{ marginBottom: 10 }} fullWidth onChange={(e) => setPaymentModesDetails({ ...paymentModesDetails, [e.target.name]: e.target.value })} variant="outlined" /> */}
            <Autocomplete
              style={{ marginBottom: 10 }}
              value={paymentPartner}
              id="tags-standard"
              options={paymentPartners}
              onChange={(e, v) => {console.log('paymentPartners', paymentPartners); setPaymentModesDetails({ ...paymentModesDetails, paymentPartner: v })}}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} fullWidth label="Payment Partner" variant="outlined" />
              )}
            />
            {/* <TextField label="Type" name="type" value={type} style={{ marginBottom: 10 }} fullWidth onChange={(e) => setPaymentModesDetails({ ...paymentModesDetails, [e.target.name]: e.target.value })} variant="outlined" /> */}
            <Autocomplete
              style={{ marginBottom: 10 }}
              value={type}
              id="tags-standard"
              options={paymentTypes}
              onChange={(e, v) => {console.log('paymentPartners', paymentPartners); setPaymentModesDetails({ ...paymentModesDetails, type: v })}}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} fullWidth label="Payment Type" variant="outlined" />
              )}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button className={classes.noButton} variant="outlined" onClick={handleDialogClose}>
            Cancel
          </Button>
          {!isEdit?<Button className={classes.yesButton} variant="outlined" onClick={addPaymentMode} >
            Add
          </Button>:
          <Button className={classes.yesButton} variant="outlined" onClick={updatePaymentMode} >
            Update
          </Button>}
        </DialogActions>

      </Dialog>

      <Dialog
        open={showDeleteModal}
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth={'sm'}
        onClose={closeDeleteModal}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Delete Payment Mode</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className={classes.noButton} variant="outlined" onClick={closeDeleteModal}>
            Cancel
          </Button>
          <Button className={classes.rejectButton} variant="outlined" onClick={() => deletePaymentMode()}>
            Delete
          </Button>
        </DialogActions>

      </Dialog>

    </div>
  )
}

export default connect(null, { setLoading, setSnackInfo, getAllPaymentModes, getAllPaymentPartners })(ManagePaymentModes)
