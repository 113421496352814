import { SET_CUSTOMER_DETAILS } from '../actions/types'

const initialState = {
  customerDetails: null
};

const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CUSTOMER_DETAILS:
      return {
        ...state,
        customerDetails: action.payload,
      };
    default:
      return state;
  }
};

export default customerReducer;