// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.container{
  display: flex;
  justify-content: flex-end;
  padding: 10px 0px 10px 0px;
}
.container-button1{
margin-right: 10px;
}
.container-button1 Button{
  height: 2.4rem;
  width: 6rem;
}
.container-button2 Button{
  height: 2.4rem;
  width: 6rem;
}
.content-heading{
  text-align: center;
}

.container1 {
  display: flex;
  align-items: center;
  justify-content: end;
  margin: 5px;
  padding: 0px 40px 0px 0px;
}

.container-button01 {
  display: flex;
  margin: 10px;
  align-items: center; 
  background-color: rgb(201, 201, 110);
  padding: 3px 5px 3px 5px;
  border-radius: 10px;
}

.in-progress-text {
  margin-left: 5px; 
}
.container-button02 Button{
  padding: 10px 15px 13px 15px;

}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;AACA;EACE,aAAa;EACb,yBAAyB;EACzB,0BAA0B;AAC5B;AACA;AACA,kBAAkB;AAClB;AACA;EACE,cAAc;EACd,WAAW;AACb;AACA;EACE,cAAc;EACd,WAAW;AACb;AACA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,oBAAoB;EACpB,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,mBAAmB;EACnB,oCAAoC;EACpC,wBAAwB;EACxB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;AACA;EACE,4BAA4B;;AAE9B","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n.container{\n  display: flex;\n  justify-content: flex-end;\n  padding: 10px 0px 10px 0px;\n}\n.container-button1{\nmargin-right: 10px;\n}\n.container-button1 Button{\n  height: 2.4rem;\n  width: 6rem;\n}\n.container-button2 Button{\n  height: 2.4rem;\n  width: 6rem;\n}\n.content-heading{\n  text-align: center;\n}\n\n.container1 {\n  display: flex;\n  align-items: center;\n  justify-content: end;\n  margin: 5px;\n  padding: 0px 40px 0px 0px;\n}\n\n.container-button01 {\n  display: flex;\n  margin: 10px;\n  align-items: center; \n  background-color: rgb(201, 201, 110);\n  padding: 3px 5px 3px 5px;\n  border-radius: 10px;\n}\n\n.in-progress-text {\n  margin-left: 5px; \n}\n.container-button02 Button{\n  padding: 10px 15px 13px 15px;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
