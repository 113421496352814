import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import { connect } from 'react-redux'
import { setLoading, setSnackInfo } from '../redux/actions/appAction';
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow, TableHead, Button } from '@material-ui/core';
import { Typography } from 'antd';
import HttpService from '../services/httpService'
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }
}));

const ExecutionDashBoardDetail = (props) => {
    const classes = useStyles();
    const { executionStage } = useParams();
    const [imosProjectsList, setImosProjectsList] = useState([]);
    
    useEffect(() => {
        getStageWiseLead(executionStage)
        // eslint-disable-next-line
    }, [props.DATA])

    const getStageWiseLead = async (stage) => {
        let obj = {
            stage: stage.replace(/-/g, ' '),
            expcenterId: props.location.state.experienceCenterId,
            assignTo: props.location.state.assignTo
        }
        props.setLoading(true)
        try {
            const response = await HttpService.getImosStageProject(obj)
            setImosProjectsList(response?.data);
        } catch (error) {
            console.error(error)
        }
        props.setLoading(false)
    }

    return (
        <div>
            <div style={{ marginTop: 25, marginBottom: 25 }}>
                <Paper className={classes.paper}>
                    <Typography component="h3">{executionStage.replace(/-/g, ' ')}</Typography>

                    <TableContainer component={Paper} style={{ marginTop: 10 }}>
                        <Table className={classes.table} stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow >
                                    <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Sl No</TableCell>
                                    <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Project Code</TableCell>
                                    <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Status</TableCell>
                                    <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Division of Projects </TableCell>
                                    <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Design Sign-off Date</TableCell>
                                    <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Total Amount</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    imosProjectsList.length !== 0 ?
                                        imosProjectsList.map((row, i) => {
                                            return (
                                                <TableRow key={i + 1}>

                                                    <TableCell component="th" scope="row" style={{ textAlign: 'center' }} >{i + 1}</TableCell>
                                                    <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>
                                                        <Link to={`/siteqc/project-detail/${row._id}`} style={{ color: "#000", textDecoration: 'underline' }}>
                                                            {row?.erpProjectId?.code}
                                                        </Link>
                                                    </TableCell>

                                                    <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>
                                                        {row?.erpProjectId?.status}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>
                                                        {row?.erpProjectId?.divisionOfProject === '<10L' ? 'Less Than 10L' : row?.erpProjectId?.divisionOfProject}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>
                                                        {moment(row?.startDate).format("DD-MM-YYYY")}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>
                                                        {row?.grandTotal}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                        :
                                        <TableRow>
                                            <TableCell colSpan={9} style={{ textAlign: "center" }}>No Data Found</TableCell>
                                        </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>

                </Paper>
            </div>
        </div>
    )
}

export default connect(null, { setLoading, setSnackInfo })(ExecutionDashBoardDetail)
