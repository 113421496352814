import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import HttpService from '../../../services/httpService'
import { setLoading, setSnackInfo } from '../../../redux/actions/appAction'
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import { Button, FormControl, Box, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Select, InputLabel, MenuItem, Checkbox, FormControlLabel } from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 700,
    },
    root: {
        width: '100%',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
}));

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#ccc",
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const ManageTeam = (props) => {
    const classes = useStyles();
    const [departments, setDepartmentsLists] = useState([]);
    const [departmentName, setDepartmentName] = useState("");
    const [locationName, setLocationName] = useState("");
    const [expcenterList, setExpcenterList] = useState([]);
    const [expcenterName, setExpcenterName] = useState("");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [teamModal, setAddTeamModal] = React.useState(false);
    const [updateteamModal, setupdateTeamModal] = React.useState(false);

    const [teamList, setTeamLists] = useState([])
    const [teamName, setTeamName] = useState("");
    const [viewTeamsUser, SetViewTeamUsers] = React.useState(false);
    const [teamUserList, setTeamUserList] = useState([])
    const [selectedCities, setSelectedCities] = useState([]);
    const [dumpCities, setDumpCities] = useState([]);
    const [expcenters, setExpcenters] = useState([]);
    const [dumpExpCenters, setDumpExpcenters] = useState([]);
    const [selectedExpcenters, setSelectedExpcenters] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState("")
    const [allManagers, setAllManagers] = useState([])
    const [filteredManagers, setFilteredManagers] = useState([])
    const [selectedManager, setSelectedManager] = useState("")
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        setDepartmentsLists(0);
    };
    useEffect(() => {
        getTeams()
        getLocationList();
        getExperienceCenters();
        getAllManagers();
        // eslint-disable-next-line
    }, [])


    const getAllManagers = async () => {
        try {
            props.setLoading(true)
            const response = await HttpService.getUsers();
            setAllManagers(response.data);
        } catch (error) {
            console.log(error, "get department error")
        }
        props.setLoading(false)
    }

    const getDepartments = async () => {
        try {
            props.setLoading(true)
            const department = await HttpService.getDepartments();
            setDepartmentsLists(department.data);
        } catch (error) {
            console.log(error, "get department error")
        }
        props.setLoading(false)
    }

    const getTeams = async () => {
        try {
            props.setLoading(true)
            const team = await HttpService.getTeams();
            setTeamLists(team.data);
        } catch (error) {
            console.log(error, "get department error")
        }
        props.setLoading(false)
    }
    const [cities, setCities] = useState([]);

    const openAddTeamModal = () => {
        setAddTeamModal(true);
        getDepartments()
    }
    const openupdateTeamModal = () => {
        setupdateTeamModal(true);
        getDepartments()
    }
    const handleTeamModalClose = () => {
        setAddTeamModal(false);
        setupdateTeamModal(false);
    };

    const filterManager = (department) =>{
        let arr = allManagers.filter((user)=>{
            const bool1 = user?.departmentId?.name === department?.name
            const bool2 = user?.roles?.find((role)=> role?.name?.includes("Manager"))
            return bool1 && bool2
        })
        if(arr.length == 0){
            arr = allManagers.filter((user)=>{
                return user?.roles?.find((role)=> role?.name?.includes("Manager"));
            })
        }
        setFilteredManagers(arr);
    }

    const openTeamViewModal = (index) => {
        SetViewTeamUsers(true);
        const selectedTeam = teamList[index];
        setTeamName(selectedTeam['name']);
        // Filter users based on isActive and teamId match
        const activeTeamUsers = selectedTeam['users'].filter(user => {
            return user?.isActive === true && user?.teamId === selectedTeam?._id;
        });
        // setTeamName(teamList[index]['name']);
        // const activeTeamUsers = teamList[index]['users'].filter(ele => ele.isActive === true);
        setTeamUserList(activeTeamUsers);
    }

    const handleViewTeamModalClose = () => {
        SetViewTeamUsers(false);
    };

    const saveTeam = async () => {
        let selectedLocation = [];
        let selectedCenter = [];

        cities.forEach((ele, i) => {
            if (selectedCities.includes(ele.name)) {
                selectedLocation.push(ele._id)
            }
        })

        expcenters.forEach((ele, i) => {
            if (selectedExpcenters.includes(ele.name)) {
                selectedCenter.push(ele._id)
            }
        })
        try {
            props.setLoading(true)
            let data = {
                name: teamName,
                departmentId: departmentName._id,
                locationId: selectedLocation,
                experienceCenterId: selectedCenter,
            }
            if(selectedManager!=null) {
                data.manager=selectedManager._id;
            }
            await HttpService.createTeam(data);
            props.setSnackInfo('Team Added Successfully', 'success')
            setTeamName('');
            getTeams();
            setAddTeamModal(false);
            setTimeout(() => {
                window.location.reload()
            },1000)
        } catch (error) {
            props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const getLocationList = async () => {
        props.setLoading(true)
        try {
            const response = await HttpService.getLocationList()
            let citi = []
            response.data.forEach((ele, i) => {
                citi.push({
                    name: ele.name
                })
            })
            setDumpCities(citi)
            setCities(response.data)
        } catch (error) {
            props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const getExperienceCenterByLocation = (e) => {
        setSelectedCities(e.target.value)
        let expcenter = [];
        let existingSelected = [];
        expcenters.forEach((ele, i) => {
            if (e.target.value.includes(ele.locationId.name)) {
                expcenter.push({
                    name: ele.name
                })
            }
            if (e.target.value.includes(ele.locationId.name)) {
                selectedExpcenters.forEach((ele1, i) => {
                    if (ele1 === ele.name) {
                        existingSelected.push(ele.name)
                    }
                })
            }
        })
        setDumpExpcenters(expcenter)
        setSelectedExpcenters(existingSelected)
    }

    const getExperienceCenters = async () => {
        props.setLoading(true)
        try {
            const response = await HttpService.getExpericenCenters()
            setExpcenters(response.data)
        } catch (error) {
            props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const getSelectedExpCenters = (e) => {
        setSelectedExpcenters(e.target.value);
    }

    const isActiveTeam = async (teamId) => {
        props.setLoading(true)
        try {
            const response = await HttpService.deactivateTeam(teamId)
            props.setSnackInfo(response?.data ?? "Team Updated Successfully", "success")
            getTeams();
        } catch (error) {
            props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const updateTeam = async () => {
        let selectedLocation = [];
        let selectedCenter = [];

        cities.forEach((ele, i) => {
            if (selectedCities.includes(ele.name)) {
                selectedLocation.push(ele._id)
            }
        })

        expcenters.forEach((ele, i) => {
            if (selectedExpcenters.includes(ele.name)) {
                selectedCenter.push(ele._id)
            }
        })
        try {
            props.setLoading(true)
            let data = {
                name: teamName,
                teamId: selectedTeam._id,
                locationId: selectedLocation,
                experienceCenterId: selectedCenter
            }
            if(selectedManager!=null) {
                data.manager=selectedManager._id;
            }
            
            await HttpService.editTeam(data);
            props.setSnackInfo('Team Updated Successfully', 'success')
            setTeamName('');
            getTeams();
            setupdateTeamModal(false);
            setTimeout(() => {
                window.location.reload()
            },1000)
        } catch (error) {
            props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }
    const setSelectedTeamvalues =(e)=>{
        setSelectedTeam(e.target.value)
       let value =  teamList.find(ele => ele._id === e.target.value?._id)
        setTeamName(value.name)
        let selectedLocation = [];
        let selectedCenter = [];

        value.locationId.forEach((ele, i) => {
                selectedLocation.push(ele.name)
        })

        value.experienceCenterId.forEach((ele, i) => {
                selectedCenter.push(ele.name)
        })
        setSelectedCities(selectedLocation)
        setSelectedExpcenters(selectedCenter)

        const inputString = e.target.value.name;
        // Split the string into an array of words using whitespace as the delimiter
        const words = inputString.split(/\s+/);
        const department = words[0];
        
        let arr = allManagers.filter((user)=>{
            const bool1 = user?.departmentId?.name === department
            const bool2 = user?.roles?.find((role)=> role?.name?.includes("Manager"))
            return bool1 && bool2
        })
        if(arr.length == 0){
            arr = allManagers.filter((user)=>{
                return user?.roles?.find((role)=> role?.name?.includes("Manager"));
            })
        }
        setFilteredManagers(arr);

    }

    const setSelectedTeamManagervalue = (e) =>{
        setSelectedManager(e.target.value)
    }
    return (
        <div>
            <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: 10 }}>
                <Button onClick={openAddTeamModal} variant="contained" color="primary" >
                    Add Team
                </Button>
            <Button onClick={openupdateTeamModal} variant="contained" color="primary" style={{ marginLeft: "15px" }}>
                Update Team
            </Button>
                <Dialog open={teamModal} onClose={handleTeamModalClose}>
                    <DialogTitle>Add Team</DialogTitle>
                    <DialogContent>
                        <Box sx={{ minWidth: 450 }}>
                            <FormControl fullWidth style={{ marginBottom: "15px" }}>
                                <TextField className={classes.textField} value={teamName} onChange={(e) => setTeamName(e.target.value)} label="Team Name" type="text" variant="outlined" />
                            </FormControl>
                            <FormControl fullWidth style={{ marginBottom: "15px" }}> 
                                <TextField
                                    select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={departmentName}
                                    label="Select Department"
                                    onChange={(e) => {
                                        setDepartmentName(e.target.value);
                                        filterManager(e.target.value);
                                      }}
                                      
                                    variant="outlined"
                                >
                                    {departments.map((row, i) => {
                                        return (
                                            <MenuItem key={i + 1} value={row}>{row?.name}</MenuItem>
                                        )
                                    })}
                                </TextField>
                            </FormControl>
                            <FormControl fullWidth style={{ marginBottom: "15px" }}>
                            <TextField
                                select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedManager}
                                label="Select Team Manager"
                                onChange={setSelectedTeamManagervalue}
                                variant="outlined"
                            >
                                {filteredManagers.map((row, i) => {
                                    return (
                                        <MenuItem key={i + 1} value={row}>{row?.name}</MenuItem>
                                    )
                                })}
                            </TextField>
                        </FormControl>
                            <FormControl fullWidth variant="outlined" style={{ marginBottom: 20 }} className={classes.formControl}>
                                <InputLabel id="demo-mutiple-checkbox-label" htmlFor="outlined-age-native-simple">Select Location</InputLabel>
                                <Select
                                    labelId="demo-mutiple-checkbox-label"
                                    id="demo-mutiple-checkbox"
                                    multiple
                                    value={selectedCities}
                                    name={selectedCities}
                                    onChange={getExperienceCenterByLocation}
                                    input={<Input />}
                                    renderValue={(selected) => selected.join(', ')}
                                    MenuProps={MenuProps}
                                >
                                    {dumpCities.map((name) => (
                                        <MenuItem key={name.name} value={name.name}>
                                            <Checkbox checked={selectedCities.indexOf(name.name) > -1} />
                                            <ListItemText primary={name.name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl fullWidth variant="outlined" style={{ marginBottom: 20 }} className={classes.formControl}>
                                <InputLabel id="demo-mutiple-checkbox-label" htmlFor="outlined-age-native-simple">Select Experience Center</InputLabel>
                                <Select
                                    labelId="demo-mutiple-checkbox-label"
                                    id="demo-mutiple-checkbox"
                                    multiple
                                    value={selectedExpcenters}
                                    onChange={getSelectedExpCenters}
                                    input={<Input />}
                                    renderValue={(selected) => selected.join(', ')}
                                    // renderValue={(selected) => selected.map(t => t.name).join(', ')}
                                    MenuProps={MenuProps}
                                >
                                    {dumpExpCenters.map((name) => (
                                        <MenuItem key={name.name} value={name.name}>
                                            <Checkbox checked={selectedExpcenters.indexOf(name.name) > -1} />
                                            <ListItemText primary={name.name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleTeamModalClose}>Cancel</Button>
                        <Button onClick={saveTeam} variant="contained" color="secondary">Save Team</Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={updateteamModal} onClose={openupdateTeamModal}>
                    <DialogTitle>Update Team</DialogTitle>
                    <DialogContent>
                        <Box sx={{ minWidth: 450 }}>
                            <FormControl fullWidth style={{ marginBottom: "15px" }}>
                                <TextField
                                    select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedTeam}
                                    label="Select Team"
                                    onChange={setSelectedTeamvalues}
                                    variant="outlined"
                                >
                                    {teamList.filter(row => row.isActive).map((activeTeam, i) => (
                                        <MenuItem key={i + 1} value={activeTeam}>{activeTeam?.name}</MenuItem>
                                    ))}
                                </TextField>
                            </FormControl>
                            <FormControl fullWidth style={{ marginBottom: "15px" }}>
                                <TextField
                                    select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedManager}
                                    label="Select Team Manager"
                                    onChange={setSelectedTeamManagervalue}
                                    variant="outlined"
                                >
                                    {filteredManagers.map((row, i) => {
                                        return (
                                            <MenuItem key={i + 1} value={row}>{row?.name}</MenuItem>
                                        )
                                    })}
                                </TextField>
                            </FormControl>
                            <FormControl fullWidth style={{ marginBottom: "15px" }}>
                                <TextField className={classes.textField} value={teamName} onChange={(e) => setTeamName(e.target.value)} label="Team Name" type="text" variant="outlined" />
                            </FormControl>
                            <FormControl fullWidth variant="outlined" style={{ marginBottom: 20 }} className={classes.formControl}>
                                <InputLabel id="demo-mutiple-checkbox-label" htmlFor="outlined-age-native-simple">Select Location</InputLabel>
                                <Select
                                    labelId="demo-mutiple-checkbox-label"
                                    id="demo-mutiple-checkbox"
                                    multiple
                                    value={selectedCities}
                                    name={selectedCities}
                                    onChange={getExperienceCenterByLocation}
                                    input={<Input />}
                                    renderValue={(selected) => selected.join(', ')}
                                    MenuProps={MenuProps}
                                >
                                    {dumpCities.map((name) => (
                                        <MenuItem key={name.name} value={name.name}>
                                            <Checkbox checked={selectedCities.indexOf(name.name) > -1} />
                                            <ListItemText primary={name.name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl fullWidth variant="outlined" style={{ marginBottom: 20 }} className={classes.formControl}>
                                <InputLabel id="demo-mutiple-checkbox-label" htmlFor="outlined-age-native-simple">Select Experience Center</InputLabel>
                                <Select
                                    labelId="demo-mutiple-checkbox-label"
                                    id="demo-mutiple-checkbox"
                                    multiple
                                    value={selectedExpcenters}
                                    onChange={getSelectedExpCenters}
                                    input={<Input />}
                                    renderValue={(selected) => selected.join(', ')}
                                    // renderValue={(selected) => selected.map(t => t.name).join(', ')}
                                    MenuProps={MenuProps}
                                >
                                    {dumpExpCenters.map((name) => (
                                        <MenuItem key={name.name} value={name.name}>
                                            <Checkbox checked={selectedExpcenters.indexOf(name.name) > -1} />
                                            <ListItemText primary={name.name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleTeamModalClose}>Cancel</Button>
                        <Button onClick={updateTeam} variant="contained" color="secondary">Update Team</Button>
                    </DialogActions>
                </Dialog>
            </div>
            <Paper>
                <TableContainer component={Paper}>
                    <Table className={classes.table} stickyHeader aria-label="sticky table">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell>Sr. No.</StyledTableCell>
                                <StyledTableCell>Name</StyledTableCell>
                                <StyledTableCell>Department</StyledTableCell>
                                <StyledTableCell>IsActive</StyledTableCell>
                                <StyledTableCell>Team</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {teamList.map((row, i) => {
                                return (
                                    <StyledTableRow key={i + 1}>
                                        <StyledTableCell>{i + 1}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row"> {row?.name} </StyledTableCell>
                                        <StyledTableCell component="th" scope="row">{row?.departmentId?.name}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={row.isActive}
                                                        onChange={() => isActiveTeam(row._id)}
                                                        name="isActive"
                                                        color="primary"
                                                    />
                                                }
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row">
                                            <Button onClick={() => openTeamViewModal(i)} variant="contained" color="primary" >View Team</Button>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                );
                            })}
                        </TableBody>
                        {/* <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 20, 50, { label: 'All', value: -1 }]}
                                    colSpan={10}
                                    count={teamList.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: { 'aria-label': 'rows per page' },
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </TableRow>
                        </TableFooter> */}
                    </Table>
                </TableContainer>

                <Dialog open={viewTeamsUser} onClose={handleViewTeamModalClose}>
                    <DialogTitle> Team <strong> {teamName} </strong> Users List</DialogTitle>
                    <DialogContent>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <StyledTableRow>
                                        <StyledTableCell>Sr. No.</StyledTableCell>
                                        <StyledTableCell>Name</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {teamUserList.map((row, i) => {
                                        return (
                                            <StyledTableRow key={i + 1}>
                                                <StyledTableCell>{i + 1}</StyledTableCell>
                                                <StyledTableCell component="th" scope="row"> {row.name} </StyledTableCell>
                                            </StyledTableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </DialogContent>
                </Dialog>
            </Paper>
        </div>
    )

}

export default connect(null, { setLoading, setSnackInfo })(ManageTeam)