// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.footer {
    background: rgb(44, 46, 62);
    margin-top: auto;
    position: fixed;
    bottom: 0;
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/cx_customer/Footer/Footer.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;IACV,sBAAsB;AAC1B;AACA;IACI,2BAA2B;IAC3B,gBAAgB;IAChB,eAAe;IACf,SAAS;IACT,WAAW;AACf","sourcesContent":["* {\n    margin: 0;\n    padding: 0;\n    box-sizing: border-box;\n}\n.footer {\n    background: rgb(44, 46, 62);\n    margin-top: auto;\n    position: fixed;\n    bottom: 0;\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
