import React, { useState, useEffect } from 'react'
import { setLoading, setSnackInfo } from '../../redux/actions/appAction'
import { connect } from 'react-redux'
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid } from '@material-ui/core';
import HttpService from '../../services/httpService'
import decorpotLogo from '../../assets/img/org/logo-black-min.png';
import RealForm from './real';
import JunkForm from './junk';
import LostForm from './lost';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import WonForm from './won';
import DesignStageForm from './design';
import ExecutionForm from './execution';
import DesignLostStageForm from './designLost';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    surveyform :
    {
backgroundColor:"#f0ebf8"
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    textFieldWidth: {
        width: "100%"
    },
}))

const CustomerSurveyForm = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const { type, leadId } = useParams();

    useEffect(() => {
        getLeadDetails()
        // eslint-disable-next-line
    }, [])
    const [leadOwnerDetail, setLeadOwnerDetail] = useState({
        name: '',
        email: '',
        mobile: '',
        leadId: ''
    })
    const getLeadDetails = async () => {
        props.setLoading(true)
        try {
            const response = await HttpService.getLeadDetails(leadId);
            let leadOwnersList = [];
            for (let i = 0; i < response.data.previouslyAssignedTo.length; i++) {
                for (let j = 0; j < response.data.previouslyAssignedTo[i].roles.length; j++) {
                    if (response.data.departmentId === '62833e4c6999f0dd8be397a0' && response.data.previouslyAssignedTo[i]['roles'][j].name === 'Marketing User') {
                        leadOwnersList.push(response.data.previouslyAssignedTo[i])
                    } else if (response.data.salesLeadOwnRole === 'SalesUser' && response.data.previouslyAssignedTo[i]['roles'][j].name === 'Sales User') {
                        leadOwnersList.push(response.data.previouslyAssignedTo[i])
                    } else if (response.data.salesLeadOwnRole === 'SalesManager' && response.data.previouslyAssignedTo[i]['roles'][j].name === 'Sales Manager') {
                        leadOwnersList.push(response.data.previouslyAssignedTo[i])
                    } else if (response.data.departmentId === '5cb70b89ffa4965f53aa22d8' && response.data.previouslyAssignedTo[i]['roles'][j].name === 'Design User') {
                        leadOwnersList.push(response.data.previouslyAssignedTo[i])
                    } else if (response.data.departmentId === '62beef42d75fa548e7a03cff'){
                        leadOwnersList = []
                    }
                }
            }
            if (leadOwnersList.length !== 0) {
                let finalLeadOwnerUser = leadOwnersList.pop();
                setLeadOwnerDetail({
                    name: finalLeadOwnerUser.name,
                    email: finalLeadOwnerUser.email,
                    mobile: finalLeadOwnerUser.mobile,
                    _id: finalLeadOwnerUser._id,
                    customerId: response.data.customerId._id,
                    leadId: leadId
                })
            } else {
                setLeadOwnerDetail({
                    name: response.data.assignTo.name,
                    email: response.data.assignTo.email,
                    mobile: response.data.assignTo.mobile,
                    _id: response.data.assignTo._id,
                    customerId: response.data.customerId._id,
                    leadId: leadId
                })
            }
        } catch (error) {
            console.log(error, "error")
            props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    return (
        <div className={classes.surveyform}>
            <div className={classes.root} style={{}}>
                <div style={{paddingBottom: 20 }}>
                    <Grid container>
                        <Grid item md={2} xs={12}></Grid>
                        <Grid item md={8} xs={12}>
                            <div style={{ display: "flex", flexDirection: "column", margin: "auto" }}>
                                <Paper style={{ textAlign: 'center' }}>
                                    <img src={decorpotLogo} alt="decorpot logo" style={{ width: "35%" }} />
                                </Paper>

                                {
                                    type === 'real' &&
                                    <RealForm
                                        LEADOWNERDATA={leadOwnerDetail}
                                        TYPEOPTION={type}
                                    />
                                }

                                {
                                    type === 'junk' &&
                                    <JunkForm
                                        LEADOWNERDATA={leadOwnerDetail}
                                        TYPEOPTION={type}
                                    />
                                }

                                {
                                    type === 'lost' &&
                                    <LostForm
                                        LEADOWNERDATA={leadOwnerDetail}
                                        TYPEOPTION={type}
                                    />
                                }

                                {
                                    type === 'won' &&
                                    <WonForm
                                        LEADOWNERDATA={leadOwnerDetail}
                                        TYPEOPTION={type}
                                    />
                                }

                                {
                                    type === 'design' &&
                                    <DesignStageForm
                                        LEADOWNERDATA={leadOwnerDetail}
                                        TYPEOPTION={type}
                                    />
                                }

                                {
                                    type === 'execution' &&
                                    <ExecutionForm
                                        LEADOWNERDATA={leadOwnerDetail}
                                        TYPEOPTION={type}
                                    />
                                }
                                {
                                    type === 'design-lost' &&
                                    <DesignLostStageForm
                                        LEADOWNERDATA={leadOwnerDetail}
                                        TYPEOPTION={type}
                                    />
                                }
                            </div>
                        </Grid>
                        <Grid item md={2} xs={12}></Grid>
                    </Grid>
                </div>
            </div>
        </div>
    )
}

export default connect(null, { setLoading, setSnackInfo })(CustomerSurveyForm)