import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { getAllProjects } from '../redux/actions/projectAction'
import { connect } from 'react-redux'
import { useSelector } from 'react-redux';
import { setLoading, setSnackInfo } from '../redux/actions/appAction';
import HttpService from '../services/httpService';
import { Button, Paper, Divider, TextField, FormControl, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ROLENAME } from '../utils';
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 600,
    },
    textFieldWidth: {
        width: "100%"
    },
    input: {
        display: 'none',
    },
}));

const VerifyDocuments = (props) => {
    const { leadId } = useParams();
    const history = useHistory();
    const { userDetails } = useSelector(state => state.user)

    const classes = useStyles();
    useEffect(() => {
        getLeadDetails();
        // eslint-disable-next-line
    }, [leadId])

    const [leadDetail, setLeadDetails] = useState([]);
    const [isVerified, setIsVerified] = useState(false);
    const [formData, setFormData] = useState({
        clientName: "",
        code: "",
        apartmentName: "",
        clientAddress: "",
        contactNumber: "",
        divisionOfProject: "",
        startDate: "",
        expectedDeliveryDate: "",
        locationId: "",
        experienceCenterId: "",
        leadId: ""
    })
    const { apartmentName, clientAddress, contactNumber, divisionOfProject, startDate, expectedDeliveryDate, code, clientName } = formData
    const getLeadDetails = async () => {
        props.setLoading(true)
        try {
            const response = await HttpService.getLeadDetails(leadId);
            setLeadDetails(response.data)
            setFormData({
                ...formData,
                clientName: response.data.customerId.name,
                clientAddress: response.data.customerId?.address,
                contactNumber: response.data.customerId?.contact_no,
                locationId: response.data.city,
                experienceCenterId: response.data.experienceCenterId,
                leadId: response.data._id,
                startDate: response.data.customerDesignSignOffDate
            })
        } catch (error) {
            console.log(error, 'error')
            props.setSnackInfo(error?.response ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const actionPerformed = async (status) => {
        if (status === 'Approve') {
            setIsVerified(true)
            getAllProjects()
        } else {
            let users = [];
            for (let i = 0; i < leadDetail.previouslyAssignedTo.length; i++) {
                for (let j = 0; j < leadDetail.previouslyAssignedTo[i].roles.length; j++) {
                    if (leadDetail.previouslyAssignedTo[i].roles[j].name === ROLENAME.DesignUser) {
                        users.push(leadDetail.previouslyAssignedTo[i]);
                    }
                }
            }
            let previousLeadOwner = users.pop();

            let obj = {
                "contractFinanceApproved": false,
                "contractDesignApproved": false,
                "contractDesignManagerApproved": false,
                "contractFinalMarkingApproved": false,
                "contractQualityControlApproved": false,
                "contractOperationApproved": false,
                "contractCustomerApproved": false,
                "designToExecutionLogsStatus": "Initial",
                "designStages": "Design Sign-off",
                "designUser": previousLeadOwner
            }
            props.setLoading(true)
            try {
                const response = await HttpService.rejectContractSignedDocument(leadId, obj);
                props.setSnackInfo(response?.data ?? "Rejected Successfully", "success")
                history.push(`/contract-sign-leads`);
                setIsVerified(false)
            } catch (error) {
                props.setSnackInfo(error?.response ?? "Something went wrong", "error")
            }
            props.setLoading(false)
        }
    }

    const getAllProjects = async () => {
        try {
            props.setLoading(true)
            await props.getAllProjects()
            props.setLoading(false)
        } catch (error) {
            console.error(error)
            props.setLoading(false)
            props.setSnackInfo(error?.response?.data?.message ?? "Something Went Wrong", "error")
        }
    }

    const uploadProductDocument = async (e) => {
        try {
            props.setLoading(true)
            let obj = {
                code: code,
                clientName: clientName,
                apartmentName: apartmentName,
                clientAddress: clientAddress,
                contactNumber: contactNumber,
                locationId: formData.locationId,
                experienceCenterId: formData.experienceCenterId,
                leadId: formData.leadId,
                divisionOfProject: divisionOfProject,
                startDate: formData.startDate,
                expectedDeliveryDate: expectedDeliveryDate,
                existingProject: false,
                documentFiles: [
                    {
                        documentType: 'CheckList',
                        documentLink: leadDetail?.scanCheckListFile,
                        status: 'InSiteQC'
                    },
                    {
                        documentType: 'ContractSignaturePDF',
                        documentLink: leadDetail?.termsAndConditionsPdf,
                        status: 'InSiteQC'
                    },
                    {
                        documentType: 'WorkingDrawingPDF',
                        documentLink: leadDetail?.workingDrawingFile,
                        status: 'InSiteQC'
                    }
                ]
            }
            const result = await HttpService.getProductDocAndCreateProject(obj)
            console.log(result.data.project, "project")
            if (result.status === 200) {
                // setProjectSelected(result.data.project)
                props.setSnackInfo("Project Created Successfully", "success")
            }
        } catch (error) {
            console.log(error)
            props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
        }
        props.setLoading(false)
    }

    const navigateToContractSignList = () => {
        props.setSnackInfo("Project Created Successfully", "success")
        history.push(`/contract-sign-leads`);
    }

    return (
        <div>
            <div style={{ marginBottom: 20 }}>
                <h2 style={{ textAlign: 'center' }}>
                    {
                        !isVerified ?
                            `Verify and Approve Design Sign-off`
                            :
                            `Create Project - All Documents are verified`
                    }</h2>
            </div>
            <Divider />

            <div style={{ margin: '50px auto', width: '80%' }}>
                {
                    !isVerified ?
                        <Paper>
                            <ul style={{ paddingTop: 20 }} >
                                <li><strong>Quotation:</strong> <Button color="primary" onClick={() => window.open(leadDetail?.scanQuotationFile)} style={{ textTransform: 'capitalize', textDecoration: 'underline' }}>View File</Button></li>
                                <li><strong>Check List:</strong> <Button color="primary" onClick={() => window.open(leadDetail?.scanCheckListFile)} style={{ textTransform: 'capitalize', textDecoration: 'underline' }}>View File</Button></li>
                                <li><strong>Project Kick-Off Check List:</strong> <Button color="primary" onClick={() => window.open(leadDetail?.scanProjectKickOffCheckListFile)} style={{ textTransform: 'capitalize', textDecoration: 'underline' }}>View File</Button></li>
                                <li><strong>Working Drawing:</strong> <Button color="primary" onClick={() => window.open(leadDetail?.workingDrawingFile)} style={{ textTransform: 'capitalize', textDecoration: 'underline' }}>View File</Button></li>
                                <li><strong>Contract Sign:</strong> <Button color="primary" onClick={() => window.open(leadDetail?.termsAndConditionsPdf)} style={{ textTransform: 'capitalize', textDecoration: 'underline' }}>View File</Button></li>
                            </ul>

                            <div style={{ marginTop: 20, marginBottom: 20 }}>
                                <Button onClick={() => actionPerformed("Reject")} variant="outlined" style={{ marginRight: 10, marginLeft: 30 }}>Reject</Button>
                                <Button onClick={() => actionPerformed("Approve")} variant="contained" color="primary">Approve</Button>
                            </div>
                            <br />
                        </Paper>
                        :
                        <Paper>
                            <div style={{ width: '90%', margin: 'auto', padding: 20 }}>
                                <div style={{ paddingBottom: 15 }}>
                                    <TextField size="small" className={classes.textFieldWidth} value={code} onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} name="code" label="Project Code" variant="outlined" />
                                </div>
                                <div style={{ paddingBottom: 15 }}>
                                    <TextField size="small" className={classes.textFieldWidth} value={clientName} onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} name="clientName" label="Client Name" variant="outlined" disabled />
                                </div>
                                <div style={{ paddingBottom: 15 }}>
                                    <TextField size="small" className={classes.textFieldWidth} value={apartmentName} onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} name="apartmentName" label="Apartment Name" variant="outlined" />
                                </div>
                                <div style={{ paddingBottom: 15 }}>
                                    <TextField size="small" className={classes.textFieldWidth} value={contactNumber} onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} name="contactNumber" label="Contact Number" variant="outlined" disabled />
                                </div>
                                <div style={{ paddingBottom: 15 }}>
                                    <TextField size="small" className={classes.textFieldWidth} value={clientAddress} onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} name="clientAddress" label="Apartment Address" variant="outlined" disabled />
                                </div>
                                <FormControl fullWidth style={{ marginBottom: 20 }}>
                                    <TextField
                                        id="outlined-select-currency"
                                        select
                                        label="Division of Project"
                                        className={classes.textFieldWidth}
                                        value={divisionOfProject}
                                        name="divisionOfProject"
                                        onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}
                                        variant="outlined"
                                        size="small"
                                    >
                                        <MenuItem value='<10L'>Less than 10L</MenuItem>
                                        <MenuItem value='10-25L'>10L to 25L</MenuItem>
                                        <MenuItem value='25L+'>More than 25L</MenuItem>
                                    </TextField>
                                </FormControl>
                                <div style={{ paddingBottom: 15 }}>
                                    <TextField
                                        id="contract_signed_date"
                                        label="Design Sign-off Date"
                                        name="startDate"
                                        onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}
                                        type="date"
                                        value={moment(startDate).format('YYYY-MM-DD')}
                                        size="small"
                                        className={classes.textFieldWidth}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div>
                                <div style={{ paddingBottom: 15 }}>
                                    <TextField
                                        id="end_date"
                                        label="Expected Delivery Date"
                                        type="date"
                                        name="expectedDeliveryDate"
                                        onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}
                                        size="small"
                                        className={classes.textFieldWidth}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div>
                                <div style={{ display: "flex", flexDirection: "row-reverse", marginTop: 10 }}>
                                    <Button variant="contained" color="primary" onClick={() => { uploadProductDocument(); navigateToContractSignList() }} style={{ background: "#716ACA", margin: 5 }} component="span">
                                        Submit
                                    </Button>
                                </div>
                            </div>
                        </Paper>
                }
            </div>
        </div>
    )
}

export default connect(null, { setLoading, getAllProjects, setSnackInfo })(VerifyDocuments)
