// PrivateRoute.js
import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";

const PrivateRoute = ({ component: Component, path, exact }) => {
  const location = useLocation();

  // Check if the current path exactly matches the desired route
  const isExactPath = location.pathname === path;

  return (
    <Route
      path={path}
      exact={exact}
      render={(props) =>
        isExactPath ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/404", state: { from: location } }} />
        )
      }
    />
  );
};

export default PrivateRoute;