import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { setLoading, setSnackInfo } from '../../../redux/actions/appAction'
import { getAllScopes } from '../../../redux/actions/projectAction'
import { Table, TableBody, TextField, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import HttpService from '../../../services/httpService'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import Tooltip from '@material-ui/core/Tooltip';


const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 850,
  }
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#ccc",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ManageScopes = (props) => {
  const classes = useStyles();

  useEffect(() => {
    getAllScopes()
    // eslint-disable-next-line
  }, [])

  const { scopes } = useSelector(state => state.project)
  const [showAddScopeModal, setShowAddScopeModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [radioValue, setRadioValue] = useState("NO")
  const [scopeDetails, setScopeDetails] = useState({
    id: "",
    name: "",
    description: ""
  })
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState("")

  const { name, description } = scopeDetails

  const getAllScopes = async () => {
    props.setLoading(true)
    try {
      await props.getAllScopes()
    } catch (error) {
      console.error(error)
      props.setSnackInfo('Error fetching scopes', "error")
    }
    props.setLoading(false)
  }

  const handleRadioChange = (e) => {
    console.log(e.target.value)
    setRadioValue(e.target.value)
  }

  const editScope = (data) => {
    setScopeDetails({
      id: data?._id,
      name: data?.name,
      description: data?.description
    })
    setRadioValue(data?.eligibleForDiscount === true ? "YES" : "NO")
    setIsEdit(true)
    setShowAddScopeModal(true)
  }

  const addScope = async () => {
    props.setLoading(true)
    try {
      let obj = {
        name,
        description,
        eligibleForDiscount: radioValue === "YES" ? true : false
      }
      const result = await HttpService.addScope(obj)
      handleDialogClose()
      props.setSnackInfo(result.data, "success")
      getAllScopes()
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "something went wrong", "error")
    }
    props.setLoading(false)
  }

  const updateScope = async () => {
    props.setLoading(true)
    try {
      let obj = {
        name,
        description,
        eligibleForDiscount: radioValue === "YES" ? true : false
      }
      const result = await HttpService.updateScope(obj, scopeDetails.id)
      handleDialogClose()
      setIsEdit(false)
      getAllScopes()
      props.setSnackInfo(result.data, "success")
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "something went wrong", "error")
    }
    props.setLoading(false)
  }

  const handleDialogClose = () => {
    setScopeDetails({ name: "", description: "" })
    setRadioValue("NO")
    setShowAddScopeModal(false)
  }

  const closeDeleteModal = () => { setShowDeleteModal(false); setDeleteId("") }

  const deleteScope = async () => {
    props.setLoading(true)
    try {
      await HttpService.deleteScope(deleteId)
      setShowDeleteModal(false)
      setDeleteId("")
      getAllScopes()
      props.setSnackInfo("Deleted", "success")
    } catch (error) {
      console.log(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  return (
    <div>
      <div style={{ marginBottom: 10, fontSize: 18 }}><b>Manage Scopes</b></div>
      <div style={{ display: "flex", justifyContent: "flex-end" }} ><Button onClick={() => { setShowAddScopeModal(true); setIsEdit(false) }} variant="contained" color="primary" >Add Scope</Button></div>
      <div style={{ marginBottom: 10 }}></div>
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Sl No</StyledTableCell>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Description</StyledTableCell>
              <StyledTableCell>Discount Applicable</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {scopes?.map((data, i) => <StyledTableRow key={i + 1}>
              <StyledTableCell>{i + 1}</StyledTableCell>
              <StyledTableCell>{data?.name}</StyledTableCell>
              <StyledTableCell>{data?.description}</StyledTableCell>
              <StyledTableCell>{data?.eligibleForDiscount ? "YES" : "NO"}</StyledTableCell>
              <StyledTableCell style={{ cursor: "pointer" }}>
                <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                  <Tooltip title="Edit"><EditOutlined style={{ cursor: "pointer" }} onClick={() => editScope(data)} /></Tooltip>
                  <Tooltip title="Delete" onClick={() => { setShowDeleteModal(true); setDeleteId(data?._id) }}><DeleteOutlined /></Tooltip>
                </div>
              </StyledTableCell>
            </StyledTableRow>)}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={showAddScopeModal}
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth={'sm'}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{`${isEdit ? 'Edit' : 'Add New'} Scope`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <TextField label="Name" name="name" value={name} style={{ marginBottom: 10 }} fullWidth onChange={(e) => setScopeDetails({ ...scopeDetails, [e.target.name]: e.target.value })} variant="outlined" />
            <TextField label="Description" name="description" value={description} style={{ marginBottom: 10 }} fullWidth onChange={(e) => setScopeDetails({ ...scopeDetails, [e.target.name]: e.target.value })} variant="outlined" />
            <FormControl component="fieldset" >
              <FormLabel component="legend">Eligible for discount</FormLabel>
              <RadioGroup aria-label="type" name="type" value={radioValue} onChange={handleRadioChange}>
                <div className='modal-header-radio'>
                  <FormControlLabel value="YES" control={<Radio />} label="YES" />
                  <FormControlLabel value="NO" control={<Radio />} label="NO" />
                </div>
              </RadioGroup>
            </FormControl>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {!isEdit ? <Button color="primary" onClick={addScope} >
            Add
          </Button> :
            <Button color="primary" onClick={updateScope} >
              Update
            </Button>}
        </DialogActions>
      </Dialog>

      <Dialog
        open={showDeleteModal}
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth={'sm'}
        onClose={closeDeleteModal}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Delete Scope</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={closeDeleteModal}>
            Cancel
          </Button>
          <Button color="primary" onClick={() => deleteScope()}>
            Delete
          </Button>
        </DialogActions>

      </Dialog>
    </div>
  )
}

export default connect(null, { setLoading, setSnackInfo, getAllScopes })(ManageScopes)
