import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import HttpService from '../../../services/httpService'
import { setLoading, setSnackInfo } from '../../../redux/actions/appAction'
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import { EditOutlined } from '@ant-design/icons';
import { Button, FormControl, Box, Grid, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 700,
    },
    root: {
        width: '100%',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
}));

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#ccc",
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const ManageCustomers = (props) => {
    const classes = useStyles();
    const [customerList, setCustomerList] = useState([]);
    const [dupCustomerList, setDupCustomerList] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        getAllCustomer()
        // eslint-disable-next-line
    }, [])

    const getAllCustomer = async () => {
        try {
            props.setLoading(true)
            const customer = await HttpService.getAllCustomer();
            setCustomerList(customer.data);
            setDupCustomerList(customer.data);
        } catch (error) {
            console.log(error, "get customer error")
        }
        props.setLoading(false)
    }

    const [editCustomerModal, setEditCustomerModal] = React.useState(false);
    const [editName, setCustomerName] = useState("");
    const [editEmail, setCustomerEmail] = useState("");
    const [editContact_no, setCustomerContactNo] = useState("");
    const [editAddress, setCustomerAddress] = useState("");
    const [editCustomerId, setEditCustomerId] = useState("");

    const [searchField, setSearchField] = useState("")

    const getCustomerById = (custId) => {
        customerList.forEach(ele => {
            if (ele._id === custId) {
                // console.log(ele, "Selected");
                setCustomerName(ele['name'])
                setCustomerEmail(ele['email']);
                setCustomerContactNo(ele['contact_no']);
                setCustomerAddress(ele['address']);
                setEditCustomerId(ele['_id'])
                setEditCustomerModal(true)
            }
        })
    }

    const updateCustomer = async () => {
        try {
            props.setLoading(true)
            let data = {
                name: editName,
                email: editEmail,
                contact_no: editContact_no,
                address: editAddress
            }
            if(data.contact_no.length !== 10){
                return props.setSnackInfo("Phone Number should be of 10 digits only!", "error");
            }
            await HttpService.editCustomer(editCustomerId, data);
            props.setSnackInfo('Customer Edited Successfully', 'success')

            setCustomerName('');
            setCustomerEmail('');
            setCustomerContactNo('');
            setCustomerAddress('');
            getAllCustomer();
            setEditCustomerModal(false);
        } catch (error) {
            console.log(error, "error");
            props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const searchCustomer = (e) => {
        setSearchField(e.target.value)
        setCustomerList(dupCustomerList.filter(cust => cust.name.toLowerCase().includes(e.target.value.toLowerCase())))
    }

    // function to handle input of customer name
    const customerNameInputHandler = (event) => {
        try {
            const value = event.target.value;
            // Regex pattern to check for the presence of only letters (uppercase & lowercase) and spaces and excluding special characters and numeric value
            const specialCharacters = /^[a-zA-Z\s]*$/;
            const containsSpecialCharacters = !specialCharacters.test(value);
            // If special characters is present display alert
            if (containsSpecialCharacters) {
                props.setSnackInfo("Letters and spaces only. No special characters.", "warning");
            } else {
                setCustomerName(event.target.value)
            }
        } catch (error) {
            console.log("Error -> ", error);
        }
    }

    // function to handle input of phone number
    const phoneNumberInputHandler = (event) => {
        // regex to except only number in input field
        let mobileNo = event.target.value.replace(/[^0-9]/g, "");
        setCustomerContactNo(mobileNo)
    }

    return (
        <div>
            <Paper>
                <TextField label="Search Customer" value={searchField} style={{ float: 'right', marginBottom: 10, marginRight: 10, marginTop: 10 }} name="name" onChange={searchCustomer} variant="outlined" />
                <TableContainer component={Paper}>
                    <Table className={classes.table} stickyHeader aria-label="sticky table">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell>Sr. No.</StyledTableCell>
                                <StyledTableCell>Name</StyledTableCell>
                                <StyledTableCell>Email</StyledTableCell>
                                <StyledTableCell>Mobile</StyledTableCell>
                                <StyledTableCell>Address</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {customerList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
                                return (
                                    <StyledTableRow key={i + 1}>
                                        <StyledTableCell>{i + 1}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">
                                            {row?.name}
                                            <EditOutlined onClick={() => getCustomerById(row._id)} style={{ marginRight: "10px" }} />
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {row?.email}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {row?.contact_no}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {row?.address}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                );
                            })}
                        </TableBody>
                        <Dialog open={editCustomerModal} onClose={() => setEditCustomerModal(false)}>
                            <Grid item xs>
                                <DialogTitle>Edit Customer</DialogTitle>
                                <DialogContent>
                                    <Box sx={{ minWidth: 450 }}>
                                        <FormControl fullWidth style={{ marginBottom: "15px" }}>
                                            <TextField className={classes.textField} value={editName} onChange={customerNameInputHandler} label="Customer Name" type="text" variant="outlined" />
                                        </FormControl>
                                        <FormControl fullWidth style={{ marginBottom: "15px" }}>
                                            <TextField className={classes.textField} value={editEmail} onChange={(e) => setCustomerEmail(e.target.value)} label="Customer Email" type="text" variant="outlined" />
                                        </FormControl>
                                        <FormControl fullWidth style={{ marginBottom: "15px" }}>
                                            <TextField className={classes.textField} value={editContact_no} onChange={phoneNumberInputHandler} label="Customer Contact Number" type="text" variant="outlined" />
                                        </FormControl>
                                        <FormControl fullWidth style={{ marginBottom: "15px" }}>
                                            <TextField className={classes.textField} value={editAddress} onChange={(e) => setCustomerAddress(e.target.value)} label="Customer Address" type="text" variant="outlined" />
                                        </FormControl>
                                    </Box>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => setEditCustomerModal(false)}>Cancel</Button>
                                    <Button onClick={updateCustomer} variant="contained" color="secondary">Update Customer</Button>
                                </DialogActions>
                            </Grid>

                        </Dialog>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 20, 50, { label: 'All', value: -1 }]}
                                    colSpan={10}
                                    count={customerList.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: { 'aria-label': 'rows per page' },
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    )
}

export default connect(null, { setLoading, setSnackInfo })(ManageCustomers)

