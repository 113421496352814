import { SET_SNACK, SET_SNACK_INFO, SET_USER_DETAILS } from "./types";

export const setUserDetails = (data) => async dispatch => {
  try {
    dispatch({ type: SET_USER_DETAILS, payload: data })
  } catch (error) {
    console.error(error);
    dispatch({ type: SET_SNACK, payload: true })
    dispatch({ type: SET_SNACK_INFO, payload: { msg: error.response.data, snackSeverity: "error" } })

  }
}
