import React, { useState, useEffect } from 'react'
import HttpService from '../services/httpService'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { connect } from 'react-redux'
import { setLoading, setSnackInfo } from '../redux/actions/appAction';
import ApprovePaymentRequestView from './approvePaymentRequestView';

const PaymentRequestModal = (props) => {

  const [radioValue, setRadioValue] = useState("PO")
  const [poSoPaymentRequest, setPoSoPaymentRequest] = useState([])

  useEffect(() => {
    if (radioValue === "PO")
      getPaymentRequestForPo()
    else
      getPaymentRequestForSo()
    // eslint-disable-next-line
  }, [radioValue, props.MOUNTED])

  const getPaymentRequestForPo = async () => {
    props.setLoading(true)
    try {
      const result = await HttpService.getPaymentRequestForPo(props.SELECTEDPROJECT._id, props.SCOPE)
      setPoSoPaymentRequest(result.data)
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data ?? "Something went Wrong", "error")
    }
    props.setLoading(false)
  }

  const getPaymentRequestForSo = async () => {
    props.setLoading(true)
    try {
      const result = await HttpService.getPaymentRequestForSo(props.SELECTEDPROJECT._id, props.SCOPE)
      setPoSoPaymentRequest(result.data)
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data ?? "Something went Wrong", "error")
    }
    props.setLoading(false)
  }
  const handleRadioChange = (e) => {
    setRadioValue(e.target.value)
    if (e.target.value === "PO")
      getPaymentRequestForPo()
    else
      getPaymentRequestForSo()
  }

  return (
    <div>
      <FormControl component="fieldset">
        <RadioGroup aria-label="type" name="type" value={radioValue} onChange={handleRadioChange}>
          <div className='modal-header-radio'>
            <FormControlLabel value="PO" control={<Radio />} label="PO" />
            <FormControlLabel value="SO" control={<Radio />} label="SO" />
          </div>
        </RadioGroup>
      </FormControl>
      {poSoPaymentRequest?.length > 0 ? <div>
        <div>{`${radioValue === "PO" ? 'Procurement' : ' Service'}`} Order Payment Request</div>
        <ApprovePaymentRequestView PAYMENTREQUESTS={poSoPaymentRequest} REFRESH={radioValue === "PO" ? getPaymentRequestForPo : getPaymentRequestForSo} RADIOVALUE={radioValue} />
      </div > : <div>  {`No ${radioValue} payment request raised`}</div>
      }
    </div >
  )
}

export default connect(null, { setLoading, setSnackInfo })(PaymentRequestModal)
