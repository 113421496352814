import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { setLoading, setSnackInfo } from '../redux/actions/appAction'
import HttpService from '../services/httpService'
import PropTypes from 'prop-types';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import TableHead from '@material-ui/core/TableHead';
import { ConsoleSqlOutlined, DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import moment from 'moment'
import { Modal } from 'antd';
// import 'antd/dist/antd.css';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {Checkbox} from '@material-ui/core';

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles({
  table: {
    minWidth: 500,
  },
  textField: {
    width: "150px"
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#ccc",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const InwardingView = (props) => {

  const classes = useStyles2();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(500);
  const [totalPaginationLengthPO, setTotalPaginationLengthPO] = useState(9999999);
  const [procurementForInwarding, setProcurementForInwarding] = useState([])
  const [mtrForInwarding, setMtrForInwarding] = useState([])
  const [radioValue, setRadioValue] = useState("PO")
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, procurementForInwarding.length - page * rowsPerPage)
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getAllProcurementsForInwarding(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    // getAllProcurementsForInwarding()
    clearAll()
    if (radioValue === "PO") {
      getAllProcurementsForInwarding(page)
    } else {
      gatAllMaterialTransferForInwarding()
    }
    // eslint-disable-next-line
  }, [rowsPerPage])


  const getAllProcurementsForInwarding = async (newPage) => {
    props.setLoading(true)
    try {
      // const result = await HttpService.getAllProcurementsForInwarding(null, props.TYPE)
      const result = await HttpService.getAllProcurementsForInwardingPaginated(null, props.TYPE,newPage,rowsPerPage)
      setProcurementForInwarding(result.data.data)
      setTotalPaginationLengthPO(result.data.totalLength)
      // console.log('result.data.data is',result.data.data)
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  const gatAllMaterialTransferForInwarding = async () => {
    props.setLoading(true)
    let vendorType = "";
    if(props.TYPE === 'Store') {
      vendorType = 'factory';
    }
    if(props.TYPE === 'Factory') {
      vendorType = 'store';
    }
    try {
      const result = await HttpService.gatAllMaterialTransferForInwarding(null, vendorType);
      setMtrForInwarding(result.data)
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  const handleRadioChange = (e) => {
    setRadioValue(e.target.value)
    if (e.target.value === "PO"){
      getAllProcurementsForInwarding()
    } else {
      gatAllMaterialTransferForInwarding()
    }
  }

  const [inwardingModal, setInwardingModal] = useState(false)
  const [procurementDetails, setProcurementDetails] = useState({})

  const [mtrInwardingModal, setMtrInwardingModal] = useState(false)
  const [mtrDetails, setMtrDetails] = useState({})

  const [selectedOrderId, setSelectedOrderId] = useState("");
  
  const showInwardingModal = async (orderId) => {
    setSelectedOrderId(orderId)
    props.setLoading(true)
    try {
      const result = await HttpService.getProcurementByOrderIdForInwarding(orderId)
      setProcurementDetails(result.data)
      setInwardingModal(true)
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  const showMtrInwardingModal = async (orderId) => {
    setSelectedOrderId(orderId)
    props.setLoading(true)
    try {
      const result = await HttpService.getMtrByOrderIdForInwarding(orderId)
      setMtrDetails(result.data)
      setMtrInwardingModal(true)
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  const [invoiceModal, setInvoiceModal] = useState(false)
  const [material, setMaterial] = useState({})
  const [index, setIndex] = useState(null)
  const openInvoiceModal = (materialId, i) => {
    setMaterial(procurementDetails.materials.filter(material => material?.materialId._id === materialId)[0])
    setIndex(i)
    setInvoiceModal(true)
  }

  const [mtrInvoiceModal, setMtrInvoiceModal] = useState(false)
  const [mtrMaterial, setMtrMaterial] = useState({})
  const openMtrInvoiceModal = (materialId, i) => {
    setMtrMaterial(mtrDetails.materials.filter(material => material?.materialId._id === materialId)[0])
    setIndex(i)
    setMtrInvoiceModal(true)
  }

  const [inwardingQuantity, setInwardingQuantity] = useState([])
  const [inwardingDate, setInwardingDate] = useState([])

  const [mtrInwardingQuantity, setMtrInwardingQuantity] = useState([])
  const [mtrInwardingDate, setMtrInwardingDate] = useState([])
  const [myInwarding,setMyInwarding] = useState([]);
  const [myObj,setMyObj] = useState([]);

  const [inwardingDetails, setInwardingDetails] = useState({
    invoiceNumber: "",
    invoiceDate: "",
    inwardingSlip:""
  })

  const [mtrInwardingDetails, setMtrInwardingDetails] = useState({
    mtrInvoiceNumber: "",
    mtrInvoiceDate: ""
  })

    const { invoiceNumber } = inwardingDetails
  const { mtrInvoiceNumber } = mtrInwardingDetails
  const [fileUploaded,setFileUploaded] = useState(false)
  const handleSubmit = async () => {
    props.setLoading(true);
    setInvoiceModal(false);
  
    try {
      const myInwardingArray = [...myInwarding];
      const myObjectArray = [...myObj];
  
      if (!checked[index]) {
        // Handle the case when the item is not checked
        myInwardingArray[index] = {
          ...myInwardingArray[index],
          invoiceNumber: inwardingDetails.invoiceNumber,
          invoiceDate: inwardingDetails.invoiceDate,
          inwardingSlip: inwardingDetails.inwardingSlip,
        };
  
        const newObj = {
          // Create an object with the necessary properties
          deliveryDate: deliveryDate[index],
          invoiceDate: inwardingDetails.invoiceDate,
          invoiceNumber: invoiceNumber,
          inwardingQuantity: inwardingQuantity[index],
          materialId: material.materialId,
          pricePerUnit: material.requestedPrice,
          projectMaterialId: material.projectMaterialId,
          quantity: material.quantity,
          receivedQuantity: material.requestedQuantity,
          remarks: procurementDetails.remarks,
          scopeId: material.scopeId,
          status: procurementDetails.status,
          unitId: material.unitId,
          _id: material._id,
          inwardingSlip: inwardingDetails.inwardingSlip,
        };
  
        const result = await HttpService.inwardMaterialToProcurement(procurementDetails._id, newObj);
        if (result.status === 200) {
          props.setSnackInfo("Material Inwarded", "success");
          setInwardingModal(false);
          setIndex(null);
          clearAll();
        }
      } else {
        // Handle the case when multiple items are checked
        for (let i = 0; i < checked.length; i++) {
          if (checked[i]) {
            myInwardingArray[i] = {
              ...myInwardingArray[i],
              invoiceNumber: inwardingDetails.invoiceNumber,
              invoiceDate: inwardingDetails.invoiceDate,
              inwardingSlip: inwardingDetails.inwardingSlip,
            };
  
            const newObj = {

              deliveryDate: deliveryDate[index],
              invoiceDate: myInwardingArray[i].invoiceDate,
              invoiceNumber: myInwardingArray[i].invoiceNumber,
              inwardingQuantity: inwardingQuantity[i],
              materialId: checkMaterialId[i].materialId,
              pricePerUnit: checkMaterialId[i].requestedPrice,
              projectMaterialId: checkMaterialId[i].projectMaterialId,
              quantity: checkMaterialId[i].quantity,
              receivedQuantity: checkMaterialId[i].requestedQuantity,
              remarks: procurementDetails.remarks,
              scopeId: checkMaterialId[i].scopeId,
              status: procurementDetails.status,
              unitId: checkMaterialId[i].unitId,
              _id: checkMaterialId[i]._id,
              inwardingSlip: myInwardingArray[i].inwardingSlip,
            };
  
            myObjectArray[i] = newObj; // Update myObjectArray with the new object
            // console.log("myObjectArray[i]-----",myObjectArray[i])
  
            const result = await HttpService.inwardMaterialToProcurement(procurementDetails._id, newObj);
            if (result.status === 200) {
              props.setSnackInfo("Material Inwarded", "success");
              setInwardingModal(false);
              setIndex(null);
              clearAll();
            }
          }
        }
      }
  
      setMyInwarding(myInwardingArray);
      setMyObj(myObjectArray);
    } catch (error) {
      console.error(error);
      props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error");
    }
    props.setLoading(false);
   
  };
  

  const handleMtrSubmit = async () => {
    setMtrInvoiceModal(false)
    props.setLoading(true)
    try {
      let obj = {
        deliveryDate: mtrInwardingDate[index],
        invoiceDate: mtrInwardingDetails.mtrInvoiceDate,
        invoiceNumber: mtrInwardingDetails.mtrInvoiceNumber,
        inwardingQuantity: mtrInwardingQuantity[index],
        materialId: mtrMaterial?.materialId,
        pricePerUnit: mtrMaterial?.materialId?.pricePerUnit,
        projectMaterialId: mtrMaterial?.projectMaterialId,
        quantity: mtrMaterial?.quantity,
        receivedQuantity: mtrMaterial?.receivedQuantity,
        remarks: mtrDetails?.remarks,
        scopeId: mtrMaterial?.scopeId,
        status: mtrDetails?.status,
        unitId: mtrMaterial?.unitId,
        _id: mtrMaterial?._id
      }
      const result = await HttpService.mtrInwardMaterialToSite(mtrMaterial._id, obj)
      if (result.status === 200) {
        props.setSnackInfo("Material Inwarded", "success")
        setMtrInwardingModal(false)
        setIndex(null)
        clearAll()
      }
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  const clearAll = async () => {
    setInwardingQuantity([])
    setInwardingDate([])
    setInwardingDetails({
      invoiceNumber: "",
      invoiceDate: ""
    })
    
    setMtrInwardingQuantity([])
    setMtrInwardingDate([])
    setMtrInwardingDetails({
      mtrInvoiceNumber: "",
      mtrInvoiceDate: ""
    })
  }

  const [orderId, setOrderId] = useState("")
  const [projectCode, setProjectCode] = useState("")

  const fetchProjectSpecificInwarding = async (code) => {
    props.setLoading(true)
    try {
      const result = await HttpService.getAllProcurementsForInwarding(code)
      setProcurementForInwarding(result.data)
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  const handleInwardingChange = (e, index) => {
    const inwardingQtyArray = [...inwardingQuantity];
    inwardingQtyArray[index] = e.target.value;
    if (parseFloat(inwardingQtyArray[index]) <= 0) {
      return props.setSnackInfo("Please enter a value greater than zero", "error");
    }  
    setInwardingQuantity(inwardingQtyArray);
    /*
    if (/^\d+$/.test( inwardingQtyArray[index]) && parseInt( inwardingQtyArray[index]) > 0) {
      setInwardingQuantity(inwardingQtyArray);
    }
    else{
    props.setSnackInfo("Please enter greater value of zero ","error")
    }
    */


  }

  const handleMtrInwardingChange = (e, index) => {
    props.setLoading(true)
    try {
      let mtrInwardQuantity = [...mtrInwardingQuantity]
      mtrInwardQuantity[index] = e.target.value
      setMtrInwardingQuantity(mtrInwardQuantity)
    } catch (error) {
      console.error(error)
      props.setSnackInfo("Error while adding mtr inwarding quantity", "error")
    }
    props.setLoading(false)

  }

  const [deliveryDate,setDeliveryDate] = useState([]);
  const handleInwardingDateChange = (e, index) => {
    const dateArray = [...deliveryDate];
    if(!checked[index]){
      dateArray[index] = e.target.value;
    }else {
      for (let i = 0; i < checked.length; i++) {
        if (checked[i]) {
          dateArray[i] = e.target.value;
        }
      }
    }
    setDeliveryDate(dateArray);
  }

  const handleMtrInwardingDateChange = (e, index) => {
    props.setLoading(true)
    try {
      let mtrInwardDate = [...mtrInwardingDate]
      mtrInwardDate[index] = e.target.value
      setMtrInwardingDate(mtrInwardDate)
    } catch (error) {
      console.error(error)
      props.setSnackInfo("Error while adding mtr inwarding date", "error")
    }
    props.setLoading(false)
  }

  const onImageChange = async (e) => {
    // console.log(e.target.files[0])
    const extension = e.target.files[0]?.name.split(".").pop().toLowerCase();
    const fd = new FormData()
    fd.append('file', e.target.files[0],`${selectedOrderId}_${inwardingDetails.invoiceNumber}.${extension}`)
    fd.append('customerName', "leadDetail.lead_no")
    try {
        const fileUploadRes = await HttpService.uploadFile(fd);
                    setInwardingDetails({
            ...inwardingDetails,
            inwardingSlip: fileUploadRes.data
        })
        setFileUploaded(true);
    } catch (err) {
        props.setSnackInfo(err?.response?.data ?? "Something went wrong", "error")
    }
    props.setLoading(false)
}

 // handle check function
  const [checked,setChecked]=useState([]);
  const [checkMaterialId,setCheckMaterialId] = useState([]);

  const handleCheckForMultiple = (e, i, materialId) => {
    const checkedArray = [...checked];
    const dateArray = [...deliveryDate];
    const checkedMaterials=[...checkMaterialId];
    checkedArray[i] = e.target.checked;
    if (!checkedArray[i]) {
      dateArray[i] = "";
      checkedMaterials[i]="";
    }

    checkedMaterials[i] = procurementDetails.materials.filter(material => material?.materialId._id === materialId)[0];

    setDeliveryDate(dateArray);
    setChecked(checkedArray);
    setCheckMaterialId(checkedMaterials)
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 25 }}>
        <FormControl component="fieldset" >
          <RadioGroup aria-label="type" name="type" value={radioValue} onChange={handleRadioChange}>
            <div className='modal-header-radio'>
              <FormControlLabel value="PO" control={<Radio />} label="PO" />
              <FormControlLabel value="MTR" control={<Radio />} label="MTR" />
            </div>
          </RadioGroup>
        </FormControl>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 25 }}>
        <div style={{ display: "flex" }}>
          <TextField label="Search For Order Id" name="orderId" value={orderId} onChange={(e) => setOrderId(e.target.value)} variant="outlined" />
          <Button onClick={() => showInwardingModal(orderId)} style={{ marginLeft: 10 }} variant="contained" color="primary">
            Search
          </Button>
        </div>
        {props.TYPE === "Project" &&
          <div style={{ display: "flex" }}>
            <TextField label="Project Code" name="orderId" value={projectCode} onChange={(e) => setProjectCode(e.target.value)} variant="outlined" />
            <Button onClick={() => fetchProjectSpecificInwarding(projectCode)} style={{ marginLeft: 10 }} variant="contained" color="primary">
              Search
            </Button>
          </div>
        }
      </div>

      {radioValue === "PO" &&
        <TableContainer component={Paper}>
          <Table className={classes.table} size="small" aria-label="custom pagination table">
          <TableHead>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[50, 100, 500, 1000]}
                  colSpan={8}
                  count={totalPaginationLengthPO}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableHead>
            
            <TableHead>
              <TableRow>
                <StyledTableCell>Sl No</StyledTableCell>
                <StyledTableCell>Order Id</StyledTableCell>
                <StyledTableCell>Procurement type</StyledTableCell>
                <StyledTableCell>Created At</StyledTableCell>
                <StyledTableCell>Vendor Name</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {procurementForInwarding?.map((data, i) => (
                <StyledTableRow key={i + 1}>
                  <StyledTableCell >{i + 1}</StyledTableCell>
                  <StyledTableCell >{data?.orderId}</StyledTableCell>
                  <StyledTableCell >{data?.procurementType}</StyledTableCell>
                  <StyledTableCell >{moment(data?.createdAt).format('DD-MM-YYYY')}</StyledTableCell>
                  <StyledTableCell >{data?.vendorId?.name}</StyledTableCell>
                  <StyledTableCell >{data?.status}</StyledTableCell>
                  <StyledTableRow >
                    <StyledTableCell ><DownloadOutlined onClick={() => window.open(data?.poLink)} /></StyledTableCell>
                    <StyledTableCell ><EyeOutlined onClick={() => showInwardingModal(data?.orderId)} /></StyledTableCell>
                  </StyledTableRow>
                </StyledTableRow>
              ))}

              {emptyRows > 0 && (
                <StyledTableRow style={{ height: 53 * emptyRows }}>
                  <StyledTableCell colSpan={8} />
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      }
      { radioValue === 'MTR' &&
        <TableContainer component={Paper}>
          <Table className={classes.table} size="small" aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Sl No</StyledTableCell>
                <StyledTableCell>Order Id</StyledTableCell>
                <StyledTableCell>Vendor Name</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Created At</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? mtrForInwarding.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : mtrForInwarding
              ).map((data, i) => (
                <StyledTableRow key={i + 1}>
                  <StyledTableCell >{i + 1}</StyledTableCell>
                  <StyledTableCell >{data?.orderId}</StyledTableCell>
                  <StyledTableCell >{data?.transferFrom}</StyledTableCell>
                  <StyledTableCell >{data?.status}</StyledTableCell>
                  <StyledTableCell >{moment(data?.createdAt).format('DD-MM-YYYY')}</StyledTableCell>
                  <StyledTableRow >
                    <StyledTableCell ><DownloadOutlined onClick={() => window.open(data?.mtrLink)} /></StyledTableCell>
                    <StyledTableCell ><EyeOutlined onClick={() => showMtrInwardingModal(data?.orderId)} /></StyledTableCell>
                  </StyledTableRow>
                </StyledTableRow>
              ))}

              {emptyRows > 0 && (
                <StyledTableRow style={{ height: 53 * emptyRows }}>
                  <StyledTableCell colSpan={8} />
                </StyledTableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 50, { label: 'All', value: -1 }]}
                  colSpan={8}
                  count={mtrForInwarding.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      }

      <Modal
        title={`Inwarding ${selectedOrderId}`}
        centered
        visible={inwardingModal}
        onCancel={() => setInwardingModal(false)}
        width={1000}
        maskClosable={false}
        footer={null}
      >
        {procurementDetails?.materials?.length > 0 &&
        <>
          <TableContainer component={Paper}>
            <Table className={classes.table} size="small" aria-label="customized table">
              <TableHead>
                <TableRow>
                <StyledTableCell>Check For Multiple Upload</StyledTableCell>
                  <StyledTableCell>Sl No</StyledTableCell>
                  <StyledTableCell>Material Name</StyledTableCell>
                  <StyledTableCell>Price</StyledTableCell>
                  <StyledTableCell>Requested Quantity</StyledTableCell>
                  <StyledTableCell>Received Quantity</StyledTableCell>
                  <StyledTableCell>Inwarding Quantity</StyledTableCell>
                  <StyledTableCell>Scope</StyledTableCell>
                  <StyledTableCell>Status</StyledTableCell>
                  <StyledTableCell>Delivery Date</StyledTableCell>
                  <StyledTableCell>Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {procurementDetails?.materials?.map((data, i) => (data.status !== "Rejected" &&
                  <StyledTableRow key={i + 1}>
                     <StyledTableCell><Checkbox
                     disabled={data?.requestedQuantity <= data?.receivedQuantity}
                     checked={checked[i]} onChange={(e)=>handleCheckForMultiple(e,i,data?.materialId?._id)} /></StyledTableCell>
                    <StyledTableCell >{i + 1}</StyledTableCell>
                    <StyledTableCell >{data?.materialId?.name}</StyledTableCell>
                    <StyledTableCell>{data?.requestedPrice}</StyledTableCell>
                    <StyledTableCell>{data?.requestedQuantity}</StyledTableCell>
                    <StyledTableCell>{data?.receivedQuantity}</StyledTableCell>
                    <StyledTableCell><TextField value={inwardingQuantity[i]} name="inwardingQuantity" onChange={(e) => handleInwardingChange(e, i)} variant="outlined" type='number' disabled={data?.requestedQuantity<=data?.receivedQuantity}/></StyledTableCell>
                    <StyledTableCell>{data?.scopeId?.name}</StyledTableCell>
                    <StyledTableCell>{data?.status}</StyledTableCell>
                    <StyledTableCell><TextField
                      id="date"
                      name="deliveryDate"
                      disabled={data?.requestedQuantity<=data?.receivedQuantity}
                      onChange={(e) => handleInwardingDateChange(e,i)}
                      type="date"
                      value={deliveryDate[i]}
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    /></StyledTableCell>
                <StyledTableCell>
                      <button 
                       disabled={!inwardingQuantity[i] || (data?.requestedQuantity <= data?.receivedQuantity)}
                      onClick={() => openInvoiceModal(data?.materialId?._id, i)} style={{ cursor: 'pointer' }}>
                        ADD
                      </button></StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          </>
        }
      </Modal>

      <Dialog
        open={invoiceModal}
        TransitionComponent={Transition}
        keepMounted
        fullWidth={true}
        maxWidth={'xs'}
        onClose={() => setInvoiceModal(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Invoice Details"}</DialogTitle>
        <DialogContent>
          <div style={{  padding:"40px", margin : "30px" }}>
          <div style={{marginTop:"10px", marginBottom:"10px"}}>
                <TextField label="Enter Invoice" name="invoiceNumber" value={invoiceNumber} onChange={(e) => setInwardingDetails({ ...inwardingDetails, [e.target.name]: e.target.value })} variant="outlined" />
              </div>
            <div style={{marginTop:"30px", marginBottom:"20px"}}>
            <TextField
              id="date"
              name="invoiceDate"
              onChange={(e) => setInwardingDetails({ ...inwardingDetails, [e.target.name]: e.target.value })}
              label="Enter Invoice Date"
              type="date" 
              value={inwardingDetails.invoiceDate}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
            </div>
            <div style={{marginTop:"30px", marginBottom:"20px"}}>
            <input
             accept="*"
             className={classes.input}
             id="contained-button-file"
             multiple
             type="file"
             onChange={onImageChange}
             style={{ display: 'none' }}
             name="PaymentSlip"
         />
         </div>
         <div>
         <label htmlFor="contained-button-file">
             <Button variant="contained" color="primary"
                 component="span" startIcon={<CloudUploadIcon />}>
                 inwarding Attachment
             </Button>
             {
                 fileUploaded && <CheckCircleIcon style={{ verticalAlign: 'middle', marginLeft: 10, color: "green", fontSize: 30 }} />
             }
              </label>
              </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setInvoiceModal(false)} color="primary">
            Cancel
          </Button>
          <Button disabled={(inwardingDetails.invoiceDate !== '' && inwardingDetails.invoiceNumber !== '' && fileUploaded)?false:true} 
            onClick={handleSubmit} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>

      <Modal
        title={`MTR Inwarding ${selectedOrderId}`}
        centered
        visible={mtrInwardingModal}
        onCancel={() => setMtrInwardingModal(false)}
        width={1000}
        maskClosable={false}
        footer={null}
      >
        {mtrDetails?.materials?.length > 0 &&
          <TableContainer component={Paper}>
            <Table className={classes.table} size="small" aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Sl No</StyledTableCell>
                  <StyledTableCell>Material Name</StyledTableCell>
                  <StyledTableCell>Price</StyledTableCell>
                  <StyledTableCell>Requested Quantity</StyledTableCell>
                  <StyledTableCell>Received Quantity</StyledTableCell>
                  <StyledTableCell>Inwarding Quantity</StyledTableCell>
                  <StyledTableCell>Scope</StyledTableCell>
                  <StyledTableCell>Status</StyledTableCell>
                  <StyledTableCell>Delivery Date</StyledTableCell>
                  <StyledTableCell>Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {mtrDetails?.materials?.map((data, i) => (data.status !== "Rejected" &&
                  <StyledTableRow key={i + 1}>
                    <StyledTableCell >{i + 1}</StyledTableCell>
                    <StyledTableCell >{data?.materialId?.name}</StyledTableCell>
                    <StyledTableCell>{data?.materialId?.pricePerUnit}</StyledTableCell>
                    <StyledTableCell>{data?.approvedQunatity}</StyledTableCell>
                    <StyledTableCell>{data?.receivedQuantity} </StyledTableCell>
                    <StyledTableCell><TextField value={mtrInwardingQuantity[i]} name="mtrInwardingQuantity" onChange={(e) => handleMtrInwardingChange(e, i)} type="number" variant="outlined" /></StyledTableCell>
                    <StyledTableCell>{data?.scopeId?.name }</StyledTableCell>
                    <StyledTableCell>{data?.status}</StyledTableCell>
                    <StyledTableCell><TextField
                      id="date"
                      name="mtrDeliveryDate"
                      onChange={(e) => handleMtrInwardingDateChange(e, i)}
                      type="date"
                      value={mtrInwardingDate[i]}
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    /></StyledTableCell>
                    <StyledTableCell>
                      <button onClick={() => openMtrInvoiceModal(data?.materialId?._id, i)} style={{ cursor: 'pointer' }} className='primary-btn'>
                        ADD
                      </button></StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        }
      </Modal>

      <Dialog
        open={mtrInvoiceModal}
        TransitionComponent={Transition}
        keepMounted
        fullWidth={true}
        maxWidth={'xs'}
        onClose={() => setMtrInvoiceModal(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Invoice Details"}</DialogTitle>
        <DialogContent>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <TextField label="Enter Invoice" name="mtrInvoiceNumber" value={mtrInvoiceNumber} onChange={(e) => setMtrInwardingDetails({ ...mtrInwardingDetails, [e.target.name]: e.target.value })} variant="outlined" />
            <TextField
              id="date"
              name="mtrInvoiceDate"
              onChange={(e) => setMtrInwardingDetails({ ...mtrInwardingDetails, [e.target.name]: e.target.value })}
              label="Enter Invoice Date"
              type="date"
              value={mtrInwardingDetails.mtrInvoiceDate}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setMtrInvoiceModal(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleMtrSubmit} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div >
  )
}

export default connect(null, { setLoading, setSnackInfo })(InwardingView)
