import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux'
import { Link } from "react-router-dom";
import { setLoading, setSnackInfo } from '../../../redux/actions/appAction'
import HttpService from '../../../services/httpService'
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Route, Routes, useLocation } from 'react-router-dom';
import { ROLENAME } from '../../../utils/index';
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 700,
    },
    root: {
        width: '100%',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    }
}));

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#ccc",
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const ReadyToDispatchProject = (props) => {
    const classes = useStyles();
    const location = useLocation();
    const { userDetails } = useSelector(state => state.user)
    const [readyToDispatchProjects, setReadyToDispatchProjects] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [totalaPages, setTotalPages] = useState(0)

    useEffect(() => {
        getReadyToDispatchProjects()
        // eslint-disable-next-line
    }, [rowsPerPage, page])

    const getReadyToDispatchProjects = async () => {

        props.setLoading(true)
        try {
            
            let result = null

            if(userDetails?.roles?.find(role => role.name === ROLENAME.CHM_Manager || role.name === ROLENAME.CHM_User))
            {
                result = await HttpService.getReadyToDispatchProjectsForCHM(userDetails.selectedExpCenter, userDetails._id)
            }
            else
            {
                result = await HttpService.getReadyToDispatchProjects(userDetails.selectedExpCenter)
            }
            console.log(result.data, "result")
            setReadyToDispatchProjects(result.data);
            setTotalPages(result.data.length)
        } catch (error) {
            console.log(error, "error")
            props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <div>
            <Paper>
                <TableContainer component={Paper}>
                    <Table className={classes.table} stickyHeader aria-label="sticky table">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell>Sr. No.</StyledTableCell>
                                <StyledTableCell>Project Code</StyledTableCell>
                                <StyledTableCell>CHM_User</StyledTableCell>
                                <StyledTableCell>Status</StyledTableCell>
                                {location.pathname !== "/dashboard" && <>
                                <StyledTableCell>Division of Project</StyledTableCell>
                                <StyledTableCell>Design Sign-off Date</StyledTableCell>
                                </>}
                                <StyledTableCell>Ready To Dispatch Date</StyledTableCell>
                                <StyledTableCell>Total Amount</StyledTableCell>
                                {/* <StyledTableCell>Created At</StyledTableCell> */}
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {readyToDispatchProjects.map((row, i) => {
                                return (
                                    <StyledTableRow key={i + 1}>
                                        <StyledTableCell>{i + 1}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">
                                            <Link to={`/ready-to-dispatch-project/logs/${row._id}`} style={{ color: "#000", textDecoration: 'underline' }}>
                                                {row?.erpProjectId?.code ? row?.erpProjectId?.code : '-' }
                                            </Link>
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row">{row?.chmName}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">{row?.factoryStage}</StyledTableCell>
                                        {location.pathname !== "/dashboard" && <>
                                        <StyledTableCell component="th" scope="row">{row?.erpProjectId?.divisionOfProject === '<10L' ? 'Less Than 10L' : row?.erpProjectId?.divisionOfProject }</StyledTableCell>
                                        <StyledTableCell component="th" scope="row"> {moment(row?.erpProjectId?.startDate).format('YYYY-MM-DD')}</StyledTableCell>
                                        </>}
                                        <StyledTableCell component="th" scope="row"> {moment(row?.readyToDispatchDate).format('YYYY-MM-DD')}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">{row?.grandTotal}</StyledTableCell>
                                        {/* <StyledTableCell component="th" scope="row">{moment(row?.erpProjectId.createdAt).format("DD-MM-YYYY")}</StyledTableCell> */}
                                    </StyledTableRow>
                                );
                            })}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 20, 50, { label: 'All', value: totalaPages }]}
                                    colSpan={10}
                                    count={totalaPages}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: { 'aria-label': 'rows per page' },
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>

            </Paper>
        </div>
    )
}
export default connect(null, { setLoading, setSnackInfo })(ReadyToDispatchProject)
