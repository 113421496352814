import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux'
import { Link } from "react-router-dom";
import { setLoading, setSnackInfo } from '../../../redux/actions/appAction'
import HttpService from '../../../services/httpService'
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 700,
    },
    root: {
        width: '100%',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    }
}));

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#ccc",
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const ImosCompletedProjects = (props) => {
    const classes = useStyles();
    const { userDetails } = useSelector(state => state.user)
    const [imosCompletedProjects, setImosCompletedProjects] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [totalaPages, setTotalPages] = useState(0)

    useEffect(() => {
        getIMOSCompletedProjects()
        // eslint-disable-next-line
    }, [rowsPerPage, page])

    const getIMOSCompletedProjects = async () => {
        props.setLoading(true)
        try {
            const result = await HttpService.getImosCompletedProject(userDetails.selectedExpCenter)
            console.log(result.data, "result")
            setImosCompletedProjects(result.data);
            setTotalPages(result.data.length)
        } catch (error) {
            console.log(error, "error")
            props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }
    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <div>
            <Paper>
                <TableContainer component={Paper}>
                    <Table className={classes.table} stickyHeader aria-label="sticky table">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell>Sr. No.</StyledTableCell>
                                <StyledTableCell>Project Code</StyledTableCell>
                                <StyledTableCell>Status</StyledTableCell>
                                <StyledTableCell>Division of Project</StyledTableCell>
                                <StyledTableCell>Design Sign-off Date</StyledTableCell>
                                <StyledTableCell>Total Amount</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {imosCompletedProjects.map((row, i) => {
                                return (
                                    <StyledTableRow key={i + 1}>
                                        <StyledTableCell>{i + 1}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">
                                            <Link to={`/siteqc/project-detail/${row?.leadId?._id}`} style={{ color: "#000", textDecoration: 'underline' }}>
                                                {row?.projectId?.code}
                                            </Link>
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row">{row?.leadId?.currentStage}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">{row?.projectId?.divisionOfProject === '<10L' ? 'Less Than 10L' : row?.projectId?.divisionOfProject}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">{moment(row?.projectId?.startDate).format("DD-MM-YYYY")}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">{row?.leadId?.grandTotal}</StyledTableCell>
                                    </StyledTableRow>
                                );
                            })}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 20, 50, { label: 'All', value: totalaPages }]}
                                    colSpan={10}
                                    count={totalaPages}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: { 'aria-label': 'rows per page' },
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>

            </Paper>
        </div>
    )
}
export default connect(null, { setLoading, setSnackInfo })(ImosCompletedProjects)
