import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux'
import { useHistory } from "react-router-dom";
import { setLoading, setSnackInfo } from '../redux/actions/appAction';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { ROLENAME } from '../utils';
import moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import { Modal } from 'antd';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import HttpService from '../services/httpService';
const useStyles = makeStyles((theme) => ({
    table: {
        width: '80vw',
        textAlign: 'center'
    },
    root: {
        width: '100%',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
}));


const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#ccc",
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

function ComplimentaryApprovalView(props) {
    const classes = useStyles()
    const { userDetails } = useSelector(state => state.user);
    const history = useHistory();
    const [complimentaryItemsApprovalLeads, setComplimentaryItemsApprovalLeads] = useState([]);
    const [allComplimentaryItems, setAllComplimentaryItems] = useState([]);
    const [data, setData] = useState({
        "assignTo": "",
        "complimentaryItemRequestedBy": ""
    });
    const [openItemsModal, setOpenItemsModal] = useState(false);
    const [projectId, setProjectId] = useState("")
    const [openProjectLogsModal, setOpenProjectLogsModal] = useState(false)
    const [projectLogsData, setProjectLogsData] = useState([]);
    const [remarkDialog, setRemarksDialog] = useState(false)
    const [rejectItemInfo, setRejectItemInfo] = useState({
        spaceName: '',
        proIndex: null,
    });

    useEffect(() => {
        getAllApprovedLeads();
    }, [])

    const getAllApprovedLeads = async () => {
        props.setLoading(true)
        try {
            const response = await HttpService.getComplimentaryItemsApprovalLeads(userDetails.selectedExpCenter)
            if (response.data == "No leads found.") {
                setComplimentaryItemsApprovalLeads([])
            } else {
                setComplimentaryItemsApprovalLeads(response.data);
            }
        } catch (error) {
            props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const showLogs = (data) => {
        setOpenProjectLogsModal(true);
        setProjectLogsData(data);
    }

    const showItems = (data) => {
        let obj = {
            assignTo: data?.assignTo?._id,
            complimentaryItemRequestedBy: data?.complimentaryItemRequestedBy ? data?.complimentaryItemRequestedBy?._id : ''
        }
        setData(obj);
        setOpenItemsModal(true)
        setProjectId(data?._id)
    }

    const handleRejectedRemarks = (event) => {
        // setRemarksDialog(true)
        data.rejectedRemarks = event.target.value
    }

    const handleRejectButtonClick = (spaceName, index) => {
        setRemarksDialog(true);
        setRejectItemInfo({ spaceName, proIndex: index });
    };


    const approveComplimentaryItems = async (spaceName, proIndex, status) => {
        props.setLoading(true)

        let existingComplimentaryItems = []
        let rejectedItems = []
        let approvedItems = []


        projectId && complimentaryItemsApprovalLeads.forEach((lead) => {
            // Check if the lead's _id matches the projectId
            if (lead._id === projectId) {
                // Iterate through complimentaryItems within the lead
                lead.complimentaryItems.forEach((item) => {
                    if (item.productNames && item.productNames.length > 0) {
                        existingComplimentaryItems.push({
                            spaceName: item.spaceName,
                            productNames: item.productNames.map((product) => ({
                                name: product.name,
                                area: product.area,
                                chApproved: product.chApproved
                            }))
                        });
                    }
                    // Create an array to hold the updated productNames
                    const updatedProductNames = [];

                    // Iterate through productNames array within the item
                    item.productNames.forEach((product, productIndex) => {
                        // Update chApproval based on the status
                        if (status && proIndex === productIndex && spaceName === item.spaceName) {
                            // Update the entire productNames array
                            item.productNames[productIndex] = {
                                name: product.name,
                                area: product.area,
                                chApproved: true,
                                itemApproved: true
                            };

                            // Push the updated product to the array
                            updatedProductNames[productIndex] = [{
                                name: item.productNames[productIndex].name,
                                area: item.productNames[productIndex].area,
                                chApproved: item.productNames[productIndex].chApproved,
                                itemApproved: item.productNames[productIndex].itemApproved
                            }]

                        } else if (status === false && proIndex === productIndex && spaceName === item.spaceName) {
                            item.productNames[productIndex] = {
                                name: product.name,
                                area: product.area,
                                chApproved: false,
                                itemApproved: false
                            };

                            // Push the updated product to the array
                            updatedProductNames.push({
                                name: item.productNames[productIndex].name,
                                area: item.productNames[productIndex].area,
                                chApproved: item.productNames[productIndex].chApproved,
                                itemApproved: item.productNames[productIndex].itemApproved
                            });
                        }
                        // Find the existingComplimentaryItems element with the matching spaceName
                        const existingItemIndex = existingComplimentaryItems.findIndex(existingItem => existingItem.spaceName === item.spaceName);

                        if (existingItemIndex !== -1) {
                            // Update only the specific productIndex in the productNames array
                            existingComplimentaryItems[existingItemIndex].productNames[productIndex] = {
                                name: item.productNames[productIndex].name,
                                area: item.productNames[productIndex].area,
                                chApproved: item.productNames[productIndex].chApproved,
                                itemApproved: item.productNames[productIndex].itemApproved
                            };
                        }
                    });
                });
            }
        });


        // approvedComplimentaryItems = [...existingComplimentaryItems, ...approvedComplimentaryItems]
        existingComplimentaryItems.forEach((item) => {
            let hasRejectedProduct = false; // Flag to track if any product has 'itemApproved' as false
            let allProductsApproved = true; // Flag to track if all products are approved
            item.productNames.forEach((product) => {
                if (product.itemApproved === false) {
                    // If any product has 'itemApproved' as false, set the flag to true
                    hasRejectedProduct = true;
                    rejectedItems.push({
                        spaceName: item.spaceName,
                        name: product.name,
                        area: product.area,
                        chApproved: product.chApproved,
                        itemApproved: product.itemApproved
                    });
                    // data.complimentaryItemApproval = false
                    data.complimentaryItemStatus = 'Rejected';
                }
                else if (product.itemApproved === true) {
                    approvedItems.push({
                        spaceName: item.spaceName,
                        name: product.name,
                        area: product.area,
                        chApproved: product.chApproved,
                        itemApproved: product.itemApproved
                    });
                    // data.complimentaryItemApproval = true
                    data.complimentaryItemStatus = 'Approved';
                }

                if (product.itemApproved == undefined) {
                    // If any product has 'itemApproved' as undefined, set the flag to false
                    allProductsApproved = false;
                }
            });

            // Set the status based on conditions
            if (allProductsApproved) {
                if (hasRejectedProduct) {
                    data.complimentaryItemApproval = false;
                } else {
                    data.complimentaryItemApproval = true;
                }
            }
        });


        data.rejectedItems = rejectedItems
        data.approvedItems = approvedItems
        data.complimentaryItems = existingComplimentaryItems
        setAllComplimentaryItems(existingComplimentaryItems)
        try {
            const updateComplimentaryItems = await HttpService.approveComplimentaryItems(projectId, data)
        } catch (error) {
            console.log(error, "error")
        }
        props.setLoading(false)
    }


    return (
        <div>
            <div style={{ marginBottom: 20 }}>
                <h3 style={{ textAlign: 'center' }}>
                    {userDetails.roles.find(role => role.name === ROLENAME.CenterHead) && `Center Head Approval`}
                </h3>
            </div>
            <Divider />
            <Paper>
                <TableContainer component={Paper}>
                    <Table className={classes.table} stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow style={{ backgroundColor: '#333', color: '#fff' }}>
                                <TableCell>SL. No</TableCell>
                                <TableCell>Customer Name</TableCell>
                                <TableCell>Lead No.</TableCell>
                                <TableCell>CreatedAt</TableCell>
                                <TableCell>Budget</TableCell>
                                <TableCell>Requested By</TableCell>
                                <TableCell>Assigned To</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {complimentaryItemsApprovalLeads.length !== 0 ?
                                complimentaryItemsApprovalLeads.sort((a, b) => b.createdAt.split('-').reverse().join().localeCompare(a.createdAt.split('-').reverse().join())).map((row, i) => {
                                    return (
                                        <TableRow key={i + 1}>
                                            <TableCell>{i + 1}</TableCell>
                                            <TableCell component="th" scope="row"> {row?.customerId?.name} </TableCell>
                                            <TableCell component="th"> {row?.lead_no}</TableCell>
                                            <TableCell component="th">{moment(row?.createdAt).format('DD-MM-YYYY')}</TableCell>
                                            <TableCell component="th">{row?.grandTotal}</TableCell>
                                            <TableCell component="th">{row?.complimentaryItemRequestedBy ? row?.complimentaryItemRequestedBy?.name : 'NA'}</TableCell>
                                            <TableCell component="th" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                {row?.assignTo?.name}
                                                <Button variant='contained' color="primary" onClick={() => showItems(row)}>View</Button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                                :
                                <TableRow>
                                    <TableCell colSpan={9} style={{ textAlign: 'center' }}>No Records Found</TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>


            <Modal
                title="Complimentary Items"
                centered
                visible={openItemsModal}
                onCancel={() => {
                    setOpenItemsModal(false);
                    getAllApprovedLeads()
                }}
                width={1000}
                maskClosable={false}
                footer={null}
            >
                <Divider />
                <DialogContent>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} stickyHeader aria-label="sticky table">
                            <TableHead>
                                <StyledTableRow>
                                    <StyledTableCell>SL No.</StyledTableCell>
                                    <StyledTableCell>Space Name</StyledTableCell>
                                    <StyledTableCell>Complimentary Items</StyledTableCell>
                                    <StyledTableCell>Area(Sq/Ft)/Qty</StyledTableCell>
                                    <StyledTableCell>Actions/Status</StyledTableCell> {/* Add a new column for actions */}
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {projectId && (
                                    complimentaryItemsApprovalLeads.map((lead, leadIndex) => (
                                        lead._id === projectId && (
                                            lead.complimentaryItems.map((item, itemIndex) => (
                                                item.productNames.map((pro, proIndex) => (
                                                    <TableRow key={`${proIndex}`}>
                                                        <StyledTableCell>{proIndex + 1}</StyledTableCell>
                                                        <StyledTableCell>{item.spaceName}</StyledTableCell>
                                                        <StyledTableCell>{pro.name}</StyledTableCell>
                                                        <StyledTableCell>{pro.area}</StyledTableCell>
                                                        <StyledTableCell>
                                                            {pro.itemApproved === undefined ? (
                                                                <>
                                                                    <Button onClick={() => handleRejectButtonClick(item.spaceName, proIndex)
                                                                    } color="secondary" variant='outlined'>
                                                                        Reject
                                                                    </Button>
                                                                    <Button onClick={() => approveComplimentaryItems(item.spaceName, proIndex, true)} color="primary" variant='contained'>
                                                                        Approve
                                                                    </Button>
                                                                </>
                                                            ) : (
                                                                pro.chApproved ? "Approved" : "Rejected"
                                                            )}
                                                        </StyledTableCell>

                                                    </TableRow>
                                                ))
                                            ))
                                        )
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>

                {remarkDialog && (
                    <Dialog open={remarkDialog} onClose={() => setRemarksDialog(false)}>
                        <DialogTitle id="form-dialog-title">
                            {`Enter Remark`}
                        </DialogTitle>
                        <Divider />
                        <DialogContent>
                            <div style={{ paddingBottom: 15 }}>
                                <FormControl fullWidth >
                                    <TextField
                                        id="outlined-multiline-static"
                                        label="Enter Remark"
                                        multiline
                                        rows={4}
                                        onChange={handleRejectedRemarks}
                                        name="remark"
                                        variant="outlined"
                                        size="small"
                                        className={classes.textFieldWidth}
                                    />
                                </FormControl>
                            </div>
                        </DialogContent>
                        <Divider />
                        <DialogActions>
                            <>
                                <Button onClick={() => setRemarksDialog(false)} color="secondary" variant='outlined'>
                                    Cancel
                                </Button>
                                <Button
                                    onClick={() => {
                                        setRemarksDialog(false);
                                        approveComplimentaryItems(rejectItemInfo.spaceName, rejectItemInfo.proIndex, false);
                                    }}
                                    color="primary"
                                    variant="contained"
                                >
                                    Submit
                                </Button>
                            </>
                        </DialogActions>
                    </Dialog>
                )}
                <Divider />
            </Modal>

            <Dialog onClose={() => setOpenItemsModal(false)} aria-labelledby="form-dialog-title" maxWidth='xl'>
                <Divider />
                <DialogContent>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} stickyHeader aria-label="sticky table">
                            <TableHead>
                                <StyledTableRow>
                                    <StyledTableCell>Sr No.</StyledTableCell>
                                    <StyledTableCell>Space Name</StyledTableCell>
                                    <StyledTableCell>Complimentary Items</StyledTableCell>
                                    <StyledTableCell>Area(Sq/Ft)/Qty</StyledTableCell>
                                    <StyledTableCell>Actions/Status</StyledTableCell> {/* Add a new column for actions */}
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {projectId && (
                                    complimentaryItemsApprovalLeads.map((lead, leadIndex) => (
                                        lead._id === projectId && (
                                            lead.complimentaryItems.map((item, itemIndex) => (
                                                item.productNames.map((pro, proIndex) => (
                                                    <TableRow key={`${proIndex}`}>
                                                        <StyledTableCell>{proIndex + 1}</StyledTableCell>
                                                        <StyledTableCell>{item.spaceName}</StyledTableCell>
                                                        <StyledTableCell>{pro.name}</StyledTableCell>
                                                        <StyledTableCell>{pro.area}</StyledTableCell>
                                                        <StyledTableCell>
                                                            {pro.itemApproved === undefined ? (
                                                                <>
                                                                    <Button onClick={() => approveComplimentaryItems(item.spaceName, proIndex, false)} color="secondary" variant='outlined'>
                                                                        Reject
                                                                    </Button>
                                                                    <Button onClick={() => approveComplimentaryItems(item.spaceName, proIndex, true)} color="primary" variant='contained'>
                                                                        Approve
                                                                    </Button>
                                                                </>
                                                            ) : (
                                                                pro.chApproved ? "Approved" : "Rejected"
                                                            )}
                                                        </StyledTableCell>

                                                    </TableRow>
                                                ))
                                            ))
                                        )
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <Divider />
            </Dialog>
        </div>
    )
}

export default connect(null, { setLoading, setSnackInfo })(ComplimentaryApprovalView)