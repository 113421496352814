import React, { useState, useEffect } from 'react'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import HttpService from '../../../services/httpService';
import { connect } from 'react-redux'
import { setLoading, setSnackInfo } from '../../../redux/actions/appAction';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Button from '@material-ui/core/Button';
import TableCell from '@material-ui/core/TableCell';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import VisibilityIcon from '@material-ui/icons/Visibility';
import GetAppIcon from '@material-ui/icons/GetApp';
import { TextField } from '@material-ui/core';
import moment from 'moment';
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 850,
  },
  sawDownloadButtons: {
    color: theme.palette.primary.main,
    transition: 'color 0.3s ease-in-out',
    '&:hover': {
      color: "#fff",
      backgroundColor :"green",
      cursor: 'pointer',
    },
}}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#ccc",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },


}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const ApproveVendorAndMaterialMapping = (props) => {
  const classes = useStyles();

  const [radioValue, setRadioValue] = useState('vendor')
  const [vendorsForApporvalList, setVendorsForApporvalList] = useState([])
  const [vendorMappedMaterialApprovalList, setVendorMappedMaterialApprovalList] = useState([])
  const [showVendorInfo, setVendorInfo] = useState({
    name : "",
		companyName: "",
    category :"",
    address :"",
    contactNumber: "",
    alternateNumber : "",
		email:"",
    accountHolderName : "",
    accountNumber :"",
    ifsc :"",
    bankName :"",
    bankBranch : "",
    panNumber :"",
    aadharNumber :"",
		gstinNumber: "",
    website :"",
    aadharCardLink :"",
    panCardLink :"",
    bankStatementLink :"",

	})
	const [openModal, setOpenModal] = useState(false);
  useEffect(() => {
    if (radioValue === "vendor")
      getVendorsForApproval()
    else
      getVendorMaterialMappingForApproval()
    // eslint-disable-next-line
  }, [radioValue])

  const getVendorsForApproval = async () => {
    props.setLoading(true)
    try {
      const response = await HttpService.getVendorsForApproval()
      setVendorsForApporvalList(response.data)
    } catch (error) {
      console.error(error)
      setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  const getVendorMaterialMappingForApproval = async () => {
    props.setLoading(true)
    try {
      const response = await HttpService.getVendorMaterialMappingForApproval()
      setVendorMappedMaterialApprovalList(response.data)
    } catch (error) {
      console.error(error)
      setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props.setLoading(false)

  }


  const handleRadioChange = (e) => {
    setRadioValue(e.target.value)
  }

  const editVendorStatus = async (id, status) => {
    props.setLoading(true)
    try {
      await HttpService.editVendorStatus(id, { status })
      props.setSnackInfo(`Vendor ${status}`, 'success')
      getVendorsForApproval()
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  const editVendorMaterialstatus = async (id, status) => {
    props.setLoading(true)
    try {
      await HttpService.editVendorMaterialstatus(id, { status })
      props.setSnackInfo(`Vendor Material ${status}`, 'success')
      getVendorMaterialMappingForApproval()
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }
  const showVendorInformation =(data) =>{
  setOpenModal(true)
    setVendorInfo({
    name : data.name,
    companyName: data.companyName,
    category : data.category,
    address : data.address,
    contactNumber: data.contactNumber,
    alternateNumber : data.alternateNumber,
    email: data.email,
    accountHolderName : data.accountDetails.accountHolderName,
    accountNumber : data.accountDetails.accountNumber,
    ifsc : data.accountDetails.ifsc,
    bankName : data.accountDetails.bankName,
    bankBranch : data.accountDetails.bankBranch,
    panNumber : data.panNumber,
    gstinNumber: data.gstinNumber,
    website : data.website,
    aadharCardLink : data.aadharCardLink,
    panCardLink : data.panCardLink,
    bankStatementLink : data.bankStatementLink
  })
}
  console.log(showVendorInfo,'vendor is callinh')
  return (
    <div>
      <FormControl component="fieldset">
        <RadioGroup aria-label="type" name="type" value={radioValue} onChange={handleRadioChange}>
          <div className='modal-header-radio'>
            <FormControlLabel value='vendor' control={<Radio />} label="Approve Vendors" />
            <FormControlLabel value="material" control={<Radio />} label="Approve Material Mapping" />
          </div>
        </RadioGroup>
      </FormControl>
      {radioValue === "vendor" && <>{
        vendorsForApporvalList.length > 0 ? <TableContainer TableContainer component={Paper}>
          <Table className={classes.table} size="small" aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Sl No</StyledTableCell>
                <StyledTableCell>Vendor Name</StyledTableCell>
                <StyledTableCell>createdAt</StyledTableCell>
                <StyledTableCell>View</StyledTableCell>
                <StyledTableCell>Approve</StyledTableCell>
                <StyledTableCell>Reject</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {vendorsForApporvalList?.map((data, i) => <StyledTableRow key={i + 1}>
                <StyledTableCell>{i + 1}</StyledTableCell>
                <StyledTableCell>{data?.name}</StyledTableCell>
                <StyledTableCell>{moment(data?.createdAt).format('DD-MM-YYYY')}</StyledTableCell>
                <StyledTableCell><VisibilityIcon  onClick = {()=>showVendorInformation(data)}/></StyledTableCell>
                  <StyledTableCell style={{ cursor: "pointer" }} onClick={() => editVendorStatus(data._id, 'Approved')}>
                  <Button variant="contained" color="primary">
              Approve
            </Button>
                  </StyledTableCell>
                  <StyledTableCell onClick={() => editVendorStatus(data._id, 'Rejected')}>
                  <Button variant="contained" color="secondary">
              Reject
            </Button>
                  </StyledTableCell>
              </StyledTableRow>)}
            </TableBody>
          </Table>
        </TableContainer> : <div style={{ display: 'flex', justifyContent: "center" }}> No Vendors to Approve</div>
      }
        <div style ={{width :"100%" , padding :" 100px"}}>
  <Dialog open={openModal} onClose={() => setOpenModal(false)} aria-labelledby="form-dialog-title" maxWidth='lg' >
        <DialogContent>
          <h1 style={{padding :"30px"}}>Verify Vendor Details</h1>
          <DialogContentText id="alert-dialog-slide-description" style={{ display: "flex", flexDirection: "column" }}>
            <TextField label = "Vendor name" style={{ marginBottom: 10 }} name="name" value={showVendorInfo.name ? showVendorInfo.name :"Data is not available"}  variant="outlined" disabled />
            <TextField label = "Company Name" style={{ marginBottom: 10 }} name="companyName" value={showVendorInfo.companyName ? showVendorInfo.companyName :"Data is not available"}  variant="outlined" disabled/>
            <TextField label = "Category" style={{ marginBottom: 10 }} name="category" value={showVendorInfo.category ? showVendorInfo.category :"Data is not available"} variant="outlined" disabled/>
            <TextField label = "Address" style={{ marginBottom: 10 }} name="address" value={showVendorInfo.address ? showVendorInfo.address :"Data is not available"} variant="outlined" disabled/>
            <TextField label = "Contact Number" style={{ marginBottom: 10 }} name="contactNumber" value={showVendorInfo.contactNumber ? showVendorInfo.contactNumber :"Data is not available"}  variant="outlined" disabled/>
            <TextField label = "Alternate Number" style={{ marginBottom: 10 }} name="alternateNumber" value={showVendorInfo.alternateNumber ? showVendorInfo.alternateNumber :"Data is not available"}  variant="outlined" disabled/>
            <TextField label ="Email" style={{ marginBottom: 10 }} name="email" value={showVendorInfo.email ? showVendorInfo.email :"Data is not available"}  variant="outlined" disabled/>
            <hr />
            <div style={{ color: "black", fontWeight: "bold" }}> Vendor A/C Details</div>
            <hr/>
            <TextField label = "AccountHolder-Name" style={{ marginBottom: 10 }} name="accountHolderName" value={showVendorInfo.accountHolderName ? showVendorInfo.accountHolderName :"Data is not available"}  variant="outlined" disabled/>

            <TextField label = "AccountName" style={{ marginBottom: 10 }} name="accountNumber" value={showVendorInfo.accountNumber ? showVendorInfo.accountNumber :"Data is not available"} variant="outlined" disabled/>

            <TextField label ="Ifsc" style={{ marginBottom: 10 }} name="ifsc" value={showVendorInfo.ifsc ? showVendorInfo.ifsc :"Data is not available"} variant="outlined" disabled/>

            <TextField label ="BankName" style={{ marginBottom: 10 }} name="bankName" value={showVendorInfo.bankName ? showVendorInfo.bankName :"Data is not available"} variant="outlined" disabled/>

            <TextField label = "BranchName" style={{ marginBottom: 10 }} name="bankBranch" value={showVendorInfo.bankBranch ? showVendorInfo.bankBranch :"Data is not available"}  variant="outlined" disabled/>
            <hr />

            <TextField  label = "PAN Number" style={{ marginBottom: 10 }} name="panNumber" value={showVendorInfo.panNumber ? showVendorInfo.panNumber :"Data is not available"} variant="outlined" disabled/>
            
            <TextField  label = "Aadhaar Number" style={{ marginBottom: 10 }} name="aadharNumber" value={showVendorInfo.aadharNumber ? showVendorInfo.aadharNumber :"Data is not available"} variant="outlined" disabled/>
            <hr />

            <TextField  label = "GST Number" style={{ marginBottom: 10 }} name="gstinNumber" value={showVendorInfo.gstinNumber ? showVendorInfo.gstinNumber :"Data is not available"}  variant="outlined" disabled/>

            <TextField  label = "Website" style={{ marginBottom: 10 }} name="website" value={showVendorInfo.website ? showVendorInfo.website :"Data is not available"}  variant="outlined" disabled/>
            <div style={{display : "flex", justifyContent : "space-between"}}>
            <div style={{display : "flex"}}>
            <div>
            <GetAppIcon className={classes.sawDownloadButtons}  onClick ={()=>showVendorInfo.aadharCardLink ? window.open(showVendorInfo.aadharCardLink) : alert("Aadhaar File is Not Attached")}/>
            </div>
            <div>Aadhaar</div>
              </div>
              <div style={{display : "flex"}}>
                <div>
              <GetAppIcon className={classes.sawDownloadButtons} onClick ={()=>showVendorInfo.panCardLink ? window.open(showVendorInfo.panCardLink) : alert("PAN is not Attached") }/>
              </div>
              <div>PAN</div>
              </div>
              <div style={{display : "flex"}}>
                <div>
              <GetAppIcon className={classes.sawDownloadButtons} onClick ={()=>showVendorInfo.bankStatementLink ?  window.open(showVendorInfo.bankStatementLink) : alert("Bank Statement is Not Attached")}/>
              </div>
              <div>Bank-Statement</div>
              </div>
            </div>

          </DialogContentText>
        </DialogContent>
 </Dialog> 
 </div>
      </>
      
      }

      {radioValue === "material" && <>
        {vendorMappedMaterialApprovalList.length > 0 ? <TableContainer TableContainer component={Paper}>
          <Table className={classes.table} size="small" aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Sl No</StyledTableCell>
                <StyledTableCell>Vendor Name</StyledTableCell>
                <StyledTableCell>Matrial Name</StyledTableCell>
                <StyledTableCell>Price</StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {vendorMappedMaterialApprovalList?.map((data, i) => <StyledTableRow key={i + 1}>
                <StyledTableCell>{i + 1}</StyledTableCell>
                <StyledTableCell>{data?.vendorId?.name}</StyledTableCell>
                <StyledTableCell>{data?.materialId?.name}</StyledTableCell>
                <StyledTableCell>{data?.pricePerUnit}</StyledTableCell>
                <TableRow>
                  <StyledTableCell style={{ cursor: "pointer" }} onClick={() => editVendorMaterialstatus(data._id, 'Approved')}>Approve</StyledTableCell>
                  <StyledTableCell onClick={() => editVendorMaterialstatus(data._id, 'Rejected')}>Reject</StyledTableCell>
                </TableRow>
              </StyledTableRow>)}
            </TableBody>
          </Table>
        </TableContainer> : <div style={{ display: 'flex', justifyContent: "center" }}> No Vendor Materials to Approve</div>
        }
      </>
      }

    </div>
  )
}

export default connect(null, { setLoading, setSnackInfo })(ApproveVendorAndMaterialMapping)
