import React from 'react'
import StocksView from '../../../components/stocksView'

const Store = () => {
  return (
    <div>
      <StocksView TYPE={'store'} />
    </div >
  )
}

export default Store;
