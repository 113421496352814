import React, { useEffect, useState } from 'react'
import HttpService from '../../services/httpService'
import { setLoading, setSnackInfo } from '../../redux/actions/appAction'
import { connect } from 'react-redux'
import MaterialTransferListView from '../../components/materialTransferListView'
import MaterialTransferDetailsModal from '../../components/materialTransferDetaislModal'
import { Modal } from 'antd';
// import 'antd/dist/antd.css';
const MaterialTransfer = (props) => {
  const [materialTransferList, setMaterialTransferList] = useState([])
  const [mtrDetailsModal, setMtrDetailsModal] = useState(false)
  const [mounted, setMounted] = useState(false)
  const [mtrDetails, setMtrDetails] = useState({})

  useEffect(() => {
    getMaterialTransferRequest()
    // eslint-disable-next-line
  }, [])

  const getMaterialTransferRequest = async () => {
    props.setLoading(true)
    try {
      const response = await HttpService.getMaterialTransferRequest()
      setMaterialTransferList(response.data)
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Error while fetching material transfer request", "error")
    }
    props.setLoading(false)
  }

  const openMaterialTransferDetail = async (id) => {
    props.setLoading(true)
    try {
      const result = await HttpService.materialTransferDetails(id)
      setMtrDetails(result.data)
      setMtrDetailsModal(true)
      setMounted(!false)
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  return (
    <div>
      <MaterialTransferListView MATERIALTRANSFERLIST={materialTransferList} OPENDETAILSMODAL={openMaterialTransferDetail} REFERSH={getMaterialTransferRequest} />

      <Modal
        title="MTR Details"
        centered
        visible={mtrDetailsModal}
        onCancel={() => { setMtrDetailsModal(false); getMaterialTransferRequest() }}
        width={1000}
        maskClosable={false}
        footer={null}
      >
        <MaterialTransferDetailsModal MTRDETAILS={mtrDetails} MOUNTED={mounted} REFRESH={openMaterialTransferDetail} VIEWALL={() => { setMtrDetailsModal(false); getMaterialTransferRequest() }} />
      </Modal>
    </div >
  )
}

export default connect(null, { setLoading, setSnackInfo })(MaterialTransfer)
