import React, { useState, useEffect } from "react";
import { FiMenu, FiX } from "react-icons/fi";
import { Link, useHistory } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { isCustomerLoggedIn, setAuthTokenForCustomer } from '../../../utils';
import HttpService from "../../../services/httpService";
import decorpotLogo from '../../../../src/assets/img/org/newlog.png'
import { useDispatch, useSelector } from 'react-redux';
import "./Navbar.css";
import { Button } from "antd";

const Navbar = (props) => {
    const { customerDetails } = useSelector((state) => state.customer);

    const history = useHistory();
    const [open, setOpen] = useState(false);
    const [checkAuotomationPayment,setAuotmationPayment] = useState([])

    const handleClick = () => {
        setOpen(!open);
    };

    const closeMenu = () => {
        setOpen(false);
    };

    const handleLogOut = async () => {
        try {
        let handleCustomerLogout = await HttpService.handleCustomerLogout(customerDetails._id)
        } catch (error) {
        console.error("Token not available.");
      }
        localStorage.clear();
        history.push('/user-login');
        window.location.reload()
    }

    useEffect(() => {
        getCustomerNewDetails();
      }, []); // Empty dependency array to run once on mount
      
      
      const getCustomerNewDetails = async () => {
        try {
          let res = await HttpService.getCustomerNewDetails(customerDetails.leadId);
          setAuotmationPayment(res.data)
        } catch (error) {
          console.error("Token not available.");
        }
      };

    return (
        <nav className="navbar">
            <Link to="/cx_customer/dashboard" className="nav-logo">
            <img className="decorpot_logo-customer" src={decorpotLogo} alt="decorpot logo" />
            </Link>
            <div onClick={handleClick} className="nav-icon">
                {open ? <FiX /> : <FiMenu />}
            </div>
            <ul className={open ? "nav-links active" : "nav-links"}>
                <li className="nav-item">
                    <Link to="/cx_customer/dashboard" className="nav-link" onClick={closeMenu}>
                         Dashboard
                    </Link>
                </li>
                {checkAuotomationPayment.length > 0 && (
                <li className="nav-item">
                    <Link to="/cx_customer/customer-finance" className="nav-link" onClick={closeMenu}>
                        Finance
                    </Link>
                </li>
                )}
                {/* <li className="nav-item">
                    <Link to="/cx_customer/customer-excalation" className="nav-link" onClick={closeMenu}>
                        Escalation 
                    </Link>
                </li> */}
                <li className="nav-item">
                    <Link to="/cx_customer/customer-downloadcenter" className="nav-link" onClick={closeMenu}>
                        Download Center
                    </Link>
                </li>
                <li className="nav-item">
                <a href="https://decorpot.raiseaticket.com/support/#/newticket" className="nav-link" target="_blank" rel="noopener noreferrer">
                    Raise Ticket
                </a>
                </li>
                <li className="nav-item">
                    <Link to="/cx_customer/customer-profile" className="nav-link" onClick={closeMenu}>
                        Profile
                    </Link>
                </li>
                
                <li className="nav-item">
                    <Link to="/user-login" className="nav-link" onClick={handleLogOut}>
                        Log Out
                    </Link>
                </li>
            </ul>
        </nav>
    );
};
export default Navbar;