import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { getAllProjects, getAllScopes, getAllUnits } from '../../redux/actions/projectAction'
import { setLoading, setSnackInfo } from '../../redux/actions/appAction'
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
// import Button from '@material-ui/core/Button';
import HttpService from '../../services/httpService';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
// import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogTitle from '@material-ui/core/DialogTitle';
// import Slide from '@material-ui/core/Slide';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
  },
  root: {
    minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#ccc",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

const Scoping = (props) => {
  const classes = useStyles();

  useEffect(() => {
    getAllProjectsScopesAndUnits()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    getFilteredProjects()
    // eslint-disable-next-line
  }, [])

  const getAllProjectsScopesAndUnits = async () => {
    try {
      props.setLoading(true)
      await props.getAllProjects()
      await props.getAllScopes()
      await props.getAllUnits()
      props.setLoading(false)
    } catch (error) {
      console.error(error)
      props.setLoading(false)
      props.setSnackInfo(error?.response?.data?.message ?? "Something Went Wrong", "error")
    }
  }

  const [filteredProjects, setFilteredProjects] = useState([])
  const getFilteredProjects = async () => {
    try {
      const result = await HttpService.getFilteredProjects()
      setFilteredProjects(result.data)
    } catch (error) {
      console.error(error)
      props.setLoading(false)
      props.setSnackInfo(error?.response?.data?.message ?? "Something Went Wrong", "error")
    }
  }
  const { projects, scopes } = useSelector(state => state.project)
  const [selectedProject, setSelectedProject] = useState({})
  const [changedScope, setChangedScope] = useState([])
  const [quantity, setQuantity] = useState([])

  const handleSelect = async (event, value) => {
    props.setLoading(true)
    setSelectedProject(value)
    setProjectProducts([])
    if (value)
      getProjectProducts(value?._id)
    props.setLoading(false)
  }

  const handleScopeSelect = async (e, v) => {
    props.setLoading(true)
    if (v !== null) {
      setSelectedScope(v)
      getProjectProducts(selectedProject._id, v._id)
    } else {
      setSelectedScope({})
      getProjectProducts(selectedProject._id)
    }
  }

  const setProject = async (projectId) => {
    props.setLoading(true)
    setSelectedProject(projects.filter(project => project._id === projectId)[0])
    getProjectProducts(projectId)
  }

  const [projectProducts, setProjectProducts] = useState([])

  const getProjectProducts = async (projectId, scopeIds) => {
    props.setLoading(true)
    try {
      const result = await HttpService.getProjectProducts(projectId, scopeIds)
      setProjectProducts(result.data)
      let quant = [...quantity]
      let scope = [...changedScope]
      result.data.map((data, i) => {
        scope[i] = data.scopeId
        quant[i] = data.quantity
        return null
      })
      setChangedScope(scope)
      setQuantity(quant)
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something Went Wrong", "error")
    }
    props.setLoading(false)
  }

  const deleteProjectProduct = async (projectDocId) => {
    props.setLoading(true)
    try {
      const result = await HttpService.deleteProjectProduct(projectDocId)
      if (result.status === 200)
        props.setSnackInfo('Document deleted successfully.', "success")
      getProjectProducts(selectedProject._id)
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something Went Wrong", "error")
    }
    props.setLoading(false)
  }

  // const [addProductsModal, setAddProductsModal] = useState(false)
  // const [products, setProducts] = useState([])

  // const openAddProductModal = async () => {
  //   props.setLoading(true)
  //   try {
  //     const result = await HttpService.getAllProducts()
  //     setProducts(result.data)
  //     setAddProductsModal(true)
  //   } catch (error) {
  //     console.error(error)
  //     props.setSnackInfo(error?.response?.data?.message ?? "Something Went Wrong", "error")
  //   }
  //   props.setLoading(false)
  // }

  // const [productForm, setProdutForm] = useState({
  //   totalPrice: 0,
  //   quant: 0
  // })

  // const { totalPrice, quant } = productForm
  // const [selectedProduct, setSelectedProduct] = useState({})
  const [selectedScope, setSelectedScope] = useState({})
  // const [selectedUnit, setSelectedUnit] = useState({})

  // const addProducts = async () => {
  //   props.setLoading(true)
  //   try {
  //     let obj = {
  //       code: selectedProduct.code,
  //       projectId: selectedProject._id,
  //       quantity: quant,
  //       totalPrice,
  //       scopeId: selectedScope._id,
  //       unitType: selectedUnit
  //     }
  //     const result = await HttpService.addProductToProject(obj)
  //     if (result.status === 200) {
  //       props.setSnackInfo("Product added to project", "success")
  //       setAddProductsModal(false)
  //       getProjectProducts(selectedProject._id)
  //     }
  //   } catch (error) {
  //     console.error(error)
  //     props.setSnackInfo(error?.response?.data?.message ?? "Something Went Wrong", "error")
  //   }
  //   setLoading(false)
  // }

  const getSelectedScope = (i) => {
    const item = changedScope[i]
    return item || {}
  }

  const handleScopeChange = (e, value, i) => {
    let scope = [...changedScope]
    scope[i] = value
    setChangedScope(scope)
  }

  const handleQuantity = (e, i) => {
    let quant = [...quantity]
    quant[i] = e.target.value
    setQuantity(quant)
  }

  const editProjectProduct = async (i, productId) => {
    props.setLoading(true)
    try {
      let obj = {
        quantity: quantity[i],
        scopeId: changedScope[i]
      }
      const result = await HttpService.editProjectProduct(productId, obj)
      if (result.status === 200) {
        props.setSnackInfo('Updated Successfully', "success")
        getProjectProducts(selectedProject._id)

      }
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something Went Wrong", "error")
    }
    props.setLoading(false)
  }

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Autocomplete
          value={selectedProject}
          onChange={handleSelect}
          id="project-code-dropdown"
          options={projects}
          getOptionLabel={(option) => option.searchName}
          style={{ width: 350 }}
          renderInput={(params) => <TextField {...params} label="Select Project" fullWidth variant="outlined" />}
        />
        {selectedProject?._id && <Autocomplete
          value={selectedScope}
          onChange={handleScopeSelect}
          id="project-code-dropdown"
          options={scopes}
          getOptionLabel={(option) => option.name}
          style={{ width: 350 }}
          renderInput={(params) => <TextField {...params} label="Select Scope" fullWidth variant="outlined" />}
        />}
        {/* {projectProducts.length > 0 && <Button variant="contained" color="primary" style={{ background: "#716ACA", margin: 5 }} onClick={openAddProductModal}>
          Add Products
        </Button>} */}
      </div>
      <div style={{ marginTop: 10 }}></div>
      {filteredProjects.length > 0 && projectProducts.length === 0 && selectedProject?._id === undefined && <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Sl No</StyledTableCell>
              <StyledTableCell>Client Name</StyledTableCell>
              <StyledTableCell>Project Code</StyledTableCell>
              <StyledTableCell>Date</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredProjects?.map((data, i) => (
              <StyledTableRow key={i + 1}>
                <StyledTableCell>{i + 1}</StyledTableCell>
                <StyledTableCell>{data?.clientName}</StyledTableCell>
                <StyledTableCell>{data?.code}</StyledTableCell>
                <StyledTableCell>{moment(data?.startDate).format('DD-MM-YYYY')}</StyledTableCell>
                <StyledTableCell>{data?.status}</StyledTableCell>
                <StyledTableCell>
                  <button onClick={() => setProject(data?._id)} className='primary-btn'><EyeOutlined /></button>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>}
      {projectProducts.length > 0 && <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Sl No</StyledTableCell>
              <StyledTableCell>Product Name</StyledTableCell>
              <StyledTableCell>Space</StyledTableCell>
              <StyledTableCell>Code</StyledTableCell>
              <StyledTableCell>Scope</StyledTableCell>
              <StyledTableCell>Quantity</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {projectProducts?.map((data, i) => (
              <StyledTableRow key={i + 1}>
                <StyledTableCell>{i + 1}</StyledTableCell>
                <StyledTableCell>{data?.productId?.name}</StyledTableCell>
                <StyledTableCell>{data?.category}</StyledTableCell>
                <StyledTableCell>{data?.productId?.code}</StyledTableCell>
                <StyledTableCell>
                  <Autocomplete
                    value={getSelectedScope(i)}
                    onChange={(e, v) => handleScopeChange(e, v, i)}
                    options={scopes}
                    getOptionLabel={(option) => option.name}
                    style={{ width: 250 }}
                    renderInput={(params) => <TextField {...params} label="Select Scope" fullWidth variant="outlined" />}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <TextField value={quantity[i]} onChange={(e) => handleQuantity(e, i)} type="number" variant="outlined" />
                </StyledTableCell>
                <StyledTableCell>{data?.status}</StyledTableCell>
                <StyledTableRow>
                  <StyledTableCell><button onClick={() => editProjectProduct(i, data._id)} className='primary-btn'>Save</button></StyledTableCell>
                  {data?.status === "Approved" &&
                    <StyledTableCell><button onClick={() => deleteProjectProduct(data?._id)} className='primary-btn'><DeleteOutlined /></button></StyledTableCell>
                  }
                </StyledTableRow>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>}
      {/* <Dialog
        open={addProductsModal}
        TransitionComponent={Transition}
        keepMounted
        fullWidth={true}
        maxWidth={'sm'}
        onClose={() => setAddProductsModal(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Add Products"}</DialogTitle>
        <DialogContent>
          <Autocomplete
            value={selectedProject}
            onChange={(e, v) => setSelectedProject(v)}
            id="project-code-dropdown"
            options={projects}
            getOptionLabel={(option) => option.searchName}
            style={{ width: 350, marginBottom: 10 }}
            renderInput={(params) => <TextField {...params} label="Select Project" fullWidth variant="outlined" />}
          />
          <Autocomplete
            value={selectedProduct}
            onChange={(e, v) => setSelectedProduct(v)}
            id="project-code-dropdown"
            options={products}
            getOptionLabel={(option) => option.name}
            style={{ width: 350, marginBottom: 10 }}
            renderInput={(params) => <TextField {...params} label="Select Product" fullWidth variant="outlined" />}
          />
          <TextField label="Product Price" InputLabelProps={{
            shrink: true,
          }} value={totalPrice} style={{ width: 350, marginBottom: 10 }} type="number" onChange={(e) => setProdutForm({ ...productForm, [e.target.name]: e.target.value })} name="totalPrice" variant="outlined" />
          <Autocomplete
            value={selectedScope}
            onChange={(e, v) => setSelectedScope(v)}
            id="project-code-dropdown"
            options={scopes}
            getOptionLabel={(option) => option.name}
            style={{ width: 350, marginBottom: 10 }}
            renderInput={(params) => <TextField {...params} label="Select Scope" fullWidth variant="outlined" />}
          />
          <Autocomplete
            value={selectedUnit}
            onChange={(e, v) => setSelectedUnit(v)}
            id="project-code-dropdown"
            options={units}
            getOptionLabel={(option) => option.name}
            style={{ width: 350, marginBottom: 10 }}
            renderInput={(params) => <TextField {...params} label="Select Unit" fullWidth variant="outlined" />}
          />
          <TextField label="Quantity" InputLabelProps={{
            shrink: true,
          }} value={quant} style={{ width: 350, marginBottom: 10 }} type="number" onChange={(e) => setProdutForm({ ...productForm, [e.target.name]: e.target.value })} name="quant" variant="outlined" />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" style={{ background: "#716ACA", margin: 5 }} onClick={addProducts}>
            Add Products
          </Button>
        </DialogActions>
      </Dialog> */}
    </div>
  )
}

export default connect(null, { getAllProjects, setLoading, setSnackInfo, getAllScopes, getAllUnits })(Scoping)
