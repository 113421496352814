import { SET_LOADING, SET_SNACK, SET_SNACK_INFO, GET_APPROVED_PROJECTS } from '../actions/types'

const initialState = {
  loading: false,
  showSnack: false,
  snackMsg: "Message not available",
  snackSeverity: "info",
  approvedProjects: [],
}


export default function appReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        loading: payload
      }
    case SET_SNACK:
      return {
        ...state,
        showSnack: payload
      }
    case SET_SNACK_INFO:
      return {
        ...state,
        snackMsg: payload.msg,
        snackSeverity: payload.snackSeverity,
        showSnack: true
      }
    case GET_APPROVED_PROJECTS:
      return {
        ...state,
        approvedProjects: payload,
        loading: false
      }
    default:
      return state;
  }
}
