import React, { useState, useEffect } from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import HttpService from '../services/httpService';
import { setLoading, setSnackInfo } from '../redux/actions/appAction'
import { getAllScopes, getAllUnits } from '../redux/actions/projectAction';
import { connect, useSelector } from 'react-redux'
import { getAllMaterials } from '../redux/actions/vendorAction';
import { ROLENAME, DONT_SHOW_REMAP_TABLE} from '../utils/index';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import DeleteIcon from '@material-ui/icons/Delete'

const filter = createFilterOptions();


const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  root: {
    width: '100%',
  },
  textField: {
    width: "100px"
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#ccc",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);


const BomTable = (props) => {
  const classes = useStyles();

  useEffect(() => {
    getAllScopes()
    getAllUnits()
    getAllRooms()
    getAllSubCategory()
    getAllMaterials()
    // eslint-disable-next-line
  }, [props.MOUNTED])

  const { userDetails } = useSelector(state => state.user)
  const { allMaterials } = useSelector(state => state.vendor)
  const { scopes, units } = useSelector(state => state.project)

  const [tableRows, setTableRows] = useState([{ value: null }])
  const [rooms, setRooms] = useState([])
  const [subCategory, setSubCategory] = useState([])
  const [subCategoryList, setSubCategoryList] = useState([])
  const [selectedRoom, setSelectedRoom] = useState([])
  const [selectedScope, setSelectedScope] = useState([])
  const [selectedSubCategory, setSelectedSubCategory] = useState([])
  const [selectedMaterial, setSelectedMaterial] = useState([])
  const [selectedUom, setSelectedUom] = useState([])
  const [quantity, setQuantity] = useState([])
  const [rowsToAdd, setRowsToAdd] = useState(0)
  const [userRoleAccess, setUserRoleAccess] = useState(false)
  const [fieldsDisabled, setFieldsDisabled] = useState(false)
  
  const [commonBomItemsAdded, setCommonBomItemsAdded] = useState(false)
  const [showCommonBomItems, setShowCommonBomItems] = useState(false)
  // const [commBomItemTableRows, setCommBomItemTableRows] = useState([{ value: null }])
  
  const [commonBomItems, setCommonBomItems] = useState([])

  const getAllScopes = async () => {
    try {
      await props.getAllScopes()
    } catch (error) {
      console.error(error)
      props.setLoading(false)
      props.setSnackInfo('Error fetching scopes', "error")
    }
  }

  const getAllUnits = async () => {
    try {
      await props.getAllUnits()
      // console.log('units', units)
    } catch (error) {
      console.error(error)
      props.setLoading(false)
      props.setSnackInfo('Error fetching units', "error")
    }
  }


  const getAllRooms = async () => {
    try {
      const result = await HttpService.getAllRooms()
      setRooms(result.data)
    } catch (error) {
      console.error(error)
      props.setLoading(false)
      props.setSnackInfo('Error fetching rooms', "error")

    }
  }

  const getAllSubCategory = async () => {
    try {
      const response = await HttpService.getAllSubCategory()
      setSubCategoryList(response.data)
    } catch (error) {
      console.error(error)
      props.setLoading(false)
      props.setSnackInfo(error?.response?.data?.messsage ?? "Error fetching sub categories", "error")
    }
  }

  const getAllMaterials = async () => {
    props.setLoading(true)
    try {
      await props.getAllMaterials()
      // console.log('allMaterials', allMaterials)
      let my_common_bom_items = allMaterials.filter(material => material.isCommonBomItem)
      // console.log('my_common_bom_items', my_common_bom_items)
      let data = []
      for(let i = 0; i < my_common_bom_items.length; i++)
      {
        let obj = {}
        obj.material = my_common_bom_items[i]
        obj.quantity = ''
        obj.scope = {}
        obj.space = {}
        obj.subCategory = my_common_bom_items[i].subCategoryId
        obj.unitType = {}
        data.push(obj)
      }
      // console.log('data', data)
      // setCommonBomItems([...my_common_bom_items])
      setCommonBomItems([...data])
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.messsage ?? "Error fetching materials", "error")
    }
    props.setLoading(false)
  }

  

  useEffect(() => {
    if (props.TYPE === "CREATE")
      getBom()
    else if(props.TYPE === "VIEW-PENDING")
      showBomApprovalPending();
    else
      viewPreviousBom()

    // eslint-disable-next-line
  }, [])

  const viewPreviousBom = async () => {
    props.setLoading(true)
    try {
      const response = await HttpService.getPreviousBomForProject(props.SELECTEDPROJECT._id)
      setData(response)
      setFieldsDisabled(true)
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
    }
    props.setLoading(false)
  }

  // Get Data for bom whose approval is pending
  const showBomApprovalPending = async () => {
    props.setLoading(true)
    try {
      const bomApprovalPending = await HttpService.getBomPendingForApprovalProjectId(props.SELECTEDPROJECT._id);
      setData(bomApprovalPending);
      setFieldsDisabled(true);
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
    }
    props.setLoading(false)
  }  

  const getBom = async () => {
    props.setLoading(true)
    try {
      const result = await HttpService.getSavedProjectMaterialForBom(props.SELECTEDPROJECT._id)
      if (!result.data) {
        setTableRows([{ value: null }])
        setSelectedRoom([])
        setSelectedScope([])
        setSelectedSubCategory([])
        setSelectedMaterial([])
        setSelectedUom([])
        setQuantity([])
      } else
        await setData(result)
    } catch (error) {
      console.error(error)
      props.setSnackInfo('Error fetching previous data', 'error')
    }
    props.setLoading(false)

  }

  const [inActiveMaterials, setInActiveMaterials] = useState([])
  const setData = (result) => {
      props.setLoading(true)
      let rooms = [...selectedRoom]
      let scope = [...selectedScope]
      let SelectedSubCategory = [...selectedSubCategory]
      let materials = [...selectedMaterial]
      let uom = [...selectedUom]
      let quant = [...quantity]
      // let rooms = []
      // let scope = []
      // let SelectedSubCategory = []
      // let materials = []
      // let uom = []
      // let quant = []
      let inActiveMaterials = []
      result.data?.materials.map((material, i) => {
        if (material.materialId?.isActive) {
          rooms[i] = material.spaceNameId
          scope[i] = material.scopeId
          // getSelectedSubCategory(material.scopeId._id)
          SelectedSubCategory[i] = material.subCategoryId
          // getSelectedSubCategoryMaterial(material.subCategoryId._id)
          materials[i] = material.materialId
          uom[i] = material.unitType
          quant[i] = material.quantity
        } else {
          inActiveMaterials.push(material)
        }
        return null
      })
      addRows(null, rooms.length + 1)
      setInActiveMaterials(inActiveMaterials)
      setUserRoleAccess(userDetails.roles.find(role => role.name === "Operation Head" || role.name === "BOM Manager"))
      setSelectedRoom(rooms)
      setSelectedScope(scope)
      setSelectedSubCategory(SelectedSubCategory)
      setSelectedMaterial(materials)
      setSelectedUom(uom)
      setQuantity(quant)
    // props.setLoading(false)
  }

  const addRows = (e, value) => {
    let length = value ?? rowsToAdd
    // const rows = [...tableRows];
    const rows = []
    for (let i = 0; i < length; i++) {
      rows.push({ value: null })
    }
    setTableRows(rows)
    setRowsToAdd(0)
  }



  const handleScopeSelect = (e, value, i) => {
    if (value?._id) {
      getSelectedSubCategory(value?._id)
    }
    let scope = [...selectedScope]
    scope[i] = value
    setSelectedScope(scope)
  }

  const handleScopeSelectInCommonBomItems = (e, v, i) => {
    // console.log('handleScopeSelect e', e)
    // console.log('handleScopeSelect v', v)
    // console.log('handleScopeSelect i', i)
    // if (value?._id) {
    //   getSelectedSubCategory(value?._id)
    // }
    // let scope = [...selectedScopeInCommonBomItems]
    // scope[i] = v
    // SetSelectedScopeInCommonBomItems(scope)
    let data = [...commonBomItems]
    data[i].scope = v
    setCommonBomItems(data)
  }
  const handleRemoveCommonBomItemFromTable = (i) =>
  {
    // console.log('i',i)
    let data = [...commonBomItems]
    data = data.filter((el, index) => index !== i)
    // console.log('data', data)
    setCommonBomItems([...data])
  }

  const getSelectedSubCategory = async (scopeId) => {
    try {
      props.setLoading(true)
      const result = []
      for (let i = 0; i < subCategoryList.length; i++) {
        let scopedList = subCategoryList[i].scopeId.find(scope => scope._id.toString() === scopeId.toString())
        if (scopedList)
          result.push(subCategoryList[i])
      }
      setSubCategory(result)
      props.setLoading(false)
    } catch (error) {
      console.error(error)
      props.setLoading(false)
      props.setSnackInfo('Error fetching sub category', "error")
    }
  }

  const [selectedSubCategoryMaterial, setSelectedSubCategoryMaterial] = useState([])

  const handleSubCategorySelect = (e, value, i) => {
    if (value?._id) {
      getSelectedSubCategoryMaterial(value?._id)
    }
    let subCategory = [...selectedSubCategory]
    subCategory[i] = value
    setSelectedSubCategory(subCategory)
  }



  const getSelectedSubCategoryMaterial = async (subCatId) => {
    try {
      props.setLoading(true)
      console.log(subCatId.toString())
      const result = allMaterials.filter(material => material?.subCategoryId?._id?.toString() === subCatId.toString() && material?.status === "Active")
      // const resut = await HttpService.getSelectedSubCategoryMaterial(subCatId)
      setSelectedSubCategoryMaterial(result)
      props.setLoading(false)
    } catch (error) {
      console.error(error)
      props.setLoading(false)
      props.setSnackInfo(error?.response?.data?.messsage ?? 'Error fetching materials', "error")
    }
  }

  const handleRoomSelect = (e, v, i) => {
    let rooms = [...selectedRoom]
    rooms[i] = v
    setSelectedRoom(rooms)
  }

  const handleRoomSelectInCommonBomItems = (e, v, i) => {
    // console.log('handleRoomSelect e', e)
    // console.log('handleRoomSelect v', v)
    // console.log('handleRoomSelect i', i)
    // let rooms = [...selectedRoomInCommonBomItems]
    // rooms[i] = v
    // setSelectedRoomInCommonBomItems(rooms)
    let data = [...commonBomItems]
    data[i].space = v
    setCommonBomItems(data)
  }

  const handleMaterialSelect = (e, v, i) => {
    let materials = [...selectedMaterial]
    materials[i] = v
    setSelectedMaterial(materials)
    if (v) {
      let uom = [...selectedUom]
      uom[i] = v?.unitId
      setSelectedUom(uom)
    } else {
      let uom = [...selectedUom]
      uom[i] = v
      setSelectedUom(uom)
    }
  }

  const handleUomSelectInCommonBomItems = (e, v, i) => {
    // console.log('handleUomSelect e', e)
    // console.log('handleUomSelect v', v)
    // console.log('handleUomSelect i', i)
    // let uom = [...selectedUomInCommonBomItems]
    // uom[i] = v
    // console.log('uom', uom)
    // setSelectedUomInCommonBomItems(uom)
    let data = [...commonBomItems]
    data[i].unitType = v
    setCommonBomItems(data)
  }

  const handleUomSelect = (e, v, i) => {
    let uom = [...selectedUom]
    uom[i] = v
    setSelectedUom(uom)
  }

  const handleQuantityInCommonBomItems = (e, i) => {
    // console.log('handleQuantity e', e)
    // console.log('handleQuantity i', i)
    // let quant = [...quantityInCommonBomItems]
    // quant[i] = e.target.value
    // setQuantityInCommonBomItems(quant)
    let data = [...commonBomItems]
    data[i].quantity  = e.target.value
    setCommonBomItems(data)
  }

  const handleQuantity = (e, i) => {
    let quant = [...quantity]
    quant[i] = e.target.value
    setQuantity(quant)
  }

  const submit = async (status) => {
    props.setLoading(true)
    const projectMaterial = selectedRoom.map((item, i) => {
      let obj = {
        spaceNameId: item?._id,
        scopeId: selectedScope[i]._id,
        subCategoryId: selectedSubCategory[i]?._id,
        materialId: selectedMaterial[i],
        unitType: selectedUom[i],
        quantity: quantity[i]
      }
      return obj
    })
    const material = {}
    material.materials = projectMaterial
    material.status = status
    // console.log('SELECTEDPROJECT', props.SELECTEDPROJECT)
    // console.log('material', material)
    // console.log('selectedMaterial', selectedMaterial)
    // !commonBomItemsAdded && setShowCommonBomItems(true)
    // console.log('allMaterials', allMaterials)
    // console.log('commonBomItems', commonBomItems)
    if(!commonBomItemsAdded)
    {
      
      // console.log('common items to added')
      setShowCommonBomItems(true)
      props.setLoading(false)
      return;
    }
    // console.log('now we can uncomment sumit api')
    try {
      await HttpService.createProjectMaterialFromBom(props.SELECTEDPROJECT._id, material)
      props.setLoading(false)
      props.setSnackInfo(`BOM ${status}`)
      await getBom()
    } catch (error) {
      console.error(error)
      props.setLoading(false)
      props.setSnackInfo(error?.response?.data ?? `Error while ${status === "Saved" ? 'saving' : 'submitting'} data`, "error")
    }
  }

  const addCommonBomItemsToBom = () =>
  {
    // console.log('addCommonItemsToBom called')
    // console.log('commonBomItems', commonBomItems)
    setCommonBomItemsAdded(true)
    setShowCommonBomItems(false)
    
    const rows = [...tableRows]
    for (let i = 0; i < commonBomItems.length; i++)
    {
      rows.push({ value: null })
      // setTableRows(rows)
    }
    setTableRows(rows)
    let my_rooms = [...selectedRoom]
    let my_scopes = [...selectedScope]
    let my_subCategorys = [...selectedSubCategory]
    let my_materials = [...selectedMaterial]
    let my_uoms = [...selectedUom]
    let my_quants = [...quantity]
    for (let i = 0; i < commonBomItems.length; i++)
    {
      // let rooms = [...selectedRoom]
      // console.log('rooms', rooms)
      // console.log(i, 'commonBomItems space', commonBomItems[i].space)
      my_rooms.push(commonBomItems[i].space)
      // setSelectedRoom(rooms)
      // setSelectedRoom([...selectedRoom, {...commonBomItems[i].space}])
      // let scope = [...selectedScope]
      // console.log(i, 'commonBomItems scope', commonBomItems[i].scope)
      my_scopes.push(commonBomItems[i].scope)
      // setSelectedScope(scope)
      // setSelectedScope([...selectedScope, {...commonBomItems[i].scope}])
      // let subCategory = [...selectedSubCategory]
      // console.log(i, 'commonBomItems subCategory', commonBomItems[i].subCategory)
      my_subCategorys.push(commonBomItems[i].subCategory)
      // setSelectedSubCategory(subCategory)
      // setSelectedSubCategory([...selectedSubCategory, {...commonBomItems[i].subCategory}])
      // let materials = [...selectedMaterial]
      // console.log(i, 'commonBomItems material', commonBomItems[i].material)
      my_materials.push(commonBomItems[i].material)
      // setSelectedMaterial(materials)
      // setSelectedMaterial([...selectedMaterial, {...commonBomItems[i].material}])
      // let uom = [...selectedUom]
      // console.log(i, 'commonBomItems unitType', commonBomItems[i].unitType)
      my_uoms.push(commonBomItems[i].unitType)
      // setSelectedUom(uom)
      // setSelectedUom([...selectedUom, {...commonBomItems[i].unitType}])
      // let quant = [...quantity]
      // console.log(i, 'commonBomItems quantity', commonBomItems[i].quantity)
      my_quants.push(commonBomItems[i].quantity)
      // setQuantity(quant)
      // setQuantity([...quantity, commonBomItems[i].quantity])
    }
    // console.log('my_rooms', my_rooms)
    // console.log('my_scopes', my_scopes)
    // console.log('my_subCategorys', my_subCategorys)
    // console.log('my_materials', my_materials)
    // console.log('my_uoms', my_uoms)
    // console.log('my_quants', my_quants)
    setSelectedRoom(my_rooms)
    setSelectedScope(my_scopes)
    setSelectedSubCategory(my_subCategorys)
    setSelectedMaterial(my_materials)
    setSelectedUom(my_uoms)
    setQuantity(my_quants)
    
    // console.log('selectedRoomInCommonBomItems', selectedRoomInCommonBomItems)
    // console.log('selectedScopeInCommonBomItems', selectedScopeInCommonBomItems)
    // console.log('selectedSubCategoryInCommonBomItems', selectedSubCategoryInCommonBomItems)
    // console.log('selectedMaterialInCommonBomItems', selectedMaterialInCommonBomItems)
    // console.log('selectedUomInCommonBomItems', selectedUomInCommonBomItems)
    // console.log('quantityInCommonBomItems', quantityInCommonBomItems)
  }
  const resetCommonBomItemsData = () =>
  {
    // console.log('resetCommonBomItemsData called')
    
    
    let my_common_bom_items = allMaterials.filter(material => material.isCommonBomItem)
      // console.log('my_common_bom_items', my_common_bom_items)
      let data = []
      for(let i = 0; i < my_common_bom_items.length; i++)
      {
        let obj = {}
        obj.material = my_common_bom_items[i]
        obj.quantity = ''
        obj.scope = {}
        obj.space = {}
        obj.subCategory = my_common_bom_items[i].subCategoryId
        obj.unitType = {}
        data.push(obj)
      }
      // console.log('data', data)
      // setCommonBomItems([...my_common_bom_items])
      setCommonBomItems([...data])
    setShowCommonBomItems(false)
  }

  const getSelectedRoom = (i) => {
    const item = selectedRoom[i]
    return item || {};
  }
  const getSelectedScope = (i) => {
    const item = selectedScope[i]
    return item || {}
  }
  const getSelectedCategory = (i) => {
    const item = selectedSubCategory[i]
    return item || {}
  }
  const getSelectedMaterial = (i) => {
    const item = selectedMaterial[i]
    return item || {}
  }
  const getSelectedUom = (i) => {
    const item = selectedUom[i]
    return item || {}
  }

  const editBomStatus = async (status) => {
    try {
      props.setLoading(true)
      const result = await HttpService.editBomStatus(props.SELECTEDPROJECT._id, { status })
      if (result.status === 200) {
        props.setLoading(false)
        getBom()
        window.location.reload(true)
      }
      props.setSnackInfo('Bom status updated', "success")
    } catch (error) {
      console.error(error)
      props.setLoading(false)
      props.setSnackInfo('Error while updating the bom status', "error")
    }
  }

  const [remappedMaterial, setRemappedMaterial] = useState([])

  const remapMaterial = async (materialId, i) => {
    console.log(remappedMaterial, materialId, props.SELECTEDPROJECT._id)
    props.setLoading(true)
    try {
      await HttpService.remapMaterials({ remappedMaterial: remappedMaterial[i], materialId }, props.SELECTEDPROJECT._id)
      props.setSnackInfo('Successfully Remapped')
      let remappedMaterials = [...remappedMaterial]
      remappedMaterials.splice(i, 1)
      setRemappedMaterial(remappedMaterials)
      await getBom()
    } catch (error) {
      console.log(error)
      props.setSnackInfo('Error while reampping materials', "error")

    }
    props.setLoading(false)
  }

  const sendForApproval = async (materialId) => {
    props.setLoading(true)
    try {
      await HttpService.sendMaterialForApproval(materialId)
      props.setSnackInfo('Material Sent for Approval')
      await getBom()
    } catch (error) {
      console.log(error)
      props.setSnackInfo('Error while reampping materials', "error")
    }
    props.setLoading(false)
  }

  const handleRemapOfMaterial = async (v, i) => {
    let remappedMaterials = [...remappedMaterial]
    if (v?._id) {
      remappedMaterials[i] = v
    } else {
      remappedMaterials.splice(i, 1)
    }
    setRemappedMaterial(remappedMaterials)
  }

  const getRemappedMaterial = (i) => {
    const item = remappedMaterial[i]
    return item || {}
  }
  return (
    <div>
      <div>
        {userRoleAccess && props.TYPE === "CREATE" && tableRows.length > 1 &&
          <div style={{ display: "flex", flexDirection: "row-reverse", marginTop: 15 }} >
            <Button variant="contained" color="primary" style={{ background: "#34bf73", margin: 5 }} onClick={() => editBomStatus('Approved')}>
              Approve
            </Button>
            <Button variant="contained" color="primary" style={{ background: "#f50057", margin: 5 }} onClick={() => editBomStatus('Rejected')}>
              Reject
            </Button>
          </div>
        }

        <TableContainer component={Paper}>
          <Table className={classes.table} size="small" aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Sl No</StyledTableCell>
                <StyledTableCell>Select Room</StyledTableCell>
                <StyledTableCell>Select Scope</StyledTableCell>
                <StyledTableCell>Select Sub Category</StyledTableCell>
                <StyledTableCell>Select Material</StyledTableCell>
                <StyledTableCell>UOM</StyledTableCell>
                <StyledTableCell>Qty</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableRows?.map((row, i) => (
                <StyledTableRow key={i + 1}
                  style={{ border: selectedMaterial[i]?.status === 'Inactive' ? '2px solid #000' : '' }} >
                  <StyledTableCell>{i + 1}</StyledTableCell>
                  <StyledTableCell><Autocomplete
                    value={getSelectedRoom(i)}
                    disabled={fieldsDisabled}
                    onChange={(e, v) => handleRoomSelect(e, v, i)}
                    options={rooms}
                    getOptionLabel={(option) => option.name}
                    style={{ width: 150 }}
                    renderInput={(params) => <TextField {...params} label="Select Room" fullWidth size="small" variant="outlined" />}
                  /></StyledTableCell>
                  <StyledTableCell><Autocomplete
                    value={getSelectedScope(i)}
                    disabled={fieldsDisabled}
                    onChange={(e, v) => handleScopeSelect(e, v, i)}
                    options={scopes}
                    getOptionLabel={(option) => option.name}
                    style={{ width: 150, fontSize: '12px' }}
                    renderInput={(params) => <TextField {...params} label="Select Scope" fullWidth size="small" variant="outlined" />}
                  /></StyledTableCell>
                  <StyledTableCell><Autocomplete
                    value={getSelectedCategory(i)}
                    disabled={fieldsDisabled}
                    onChange={(e, v) => handleSubCategorySelect(e, v, i)}
                    getOptionLabel={(option) => option.name}
                    options={subCategory}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    style={{ width: 200 }}
                    renderInput={(params) => <TextField {...params} label="Select sub category" fullWidth size="small" variant="outlined" />}
                  /></StyledTableCell>
                  <StyledTableCell><Autocomplete
                    value={getSelectedMaterial(i)}
                    disabled={fieldsDisabled}
                    onChange={(e, v) => {
                      if (typeof v === 'string') {
                        setTimeout(() => {
                          let materials = [...selectedMaterial]
                          materials[i] = { name: v }
                          setSelectedMaterial(materials)
                        });
                      } else if (v && v.inputValue) {
                        v.inputValue = v.inputValue?.toLowerCase().replace(/\b\w/g, match => match.toUpperCase());
                        let materials = [...selectedMaterial]
                        materials[i] = { name: v.inputValue }
                        setSelectedMaterial(materials)
                      } else {
                        handleMaterialSelect(e, v, i)
                      }
                    }}
                    options={selectedSubCategoryMaterial}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      if (params.inputValue !== "") {
                        const words = params.inputValue.split(" ");
                        const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
                        const capitalizedPhrase = capitalizedWords.join(" ");
                        filtered.push({ inputValue: capitalizedPhrase, name: `Add "${capitalizedPhrase}"` });
                      }
                      return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    getOptionLabel={(option) => option.name}
                    style={{ width: 250 }}
                    renderInput={(params) => <TextField {...params} label="Select Material" fullWidth size="small" variant="outlined" />}
                  /></StyledTableCell>
                  <StyledTableCell><Autocomplete
                    value={getSelectedUom(i)}
                    disabled={fieldsDisabled}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      if (params.inputValue !== '') {
                        filtered.push({
                          inputValue: params.inputValue,
                          name: `Add "${params.inputValue}"`,
                        });
                      }
                      return filtered;
                    }}
                    onChange={(e, v) => {
                      if (typeof v === 'string') {
                        setTimeout(() => {
                          handleUomSelect({ name: v })
                        });
                      } else if (v && v.inputValue) {
                        handleUomSelect(null, { name: v.inputValue }, i)
                      } else {
                        handleUomSelect(e, v, i)
                      }
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    options={units}
                    getOptionLabel={(option) => option.name}
                    style={{ width: 150 }}
                    renderInput={(params) => <TextField {...params} label="Select UOM" fullWidth size="small" variant="outlined" />}
                  /></StyledTableCell>
                  <StyledTableCell><TextField className={classes.textField} value={quantity[i]} disabled={fieldsDisabled} onChange={(e) => handleQuantity(e, i)} type="number" size="small" variant="outlined" /></StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Dialog
          open={showCommonBomItems}
          TransitionComponent={Transition}
          keepMounted
          fullWidth={true}
          maxWidth={'xl'}
          onClose={resetCommonBomItemsData}
          aria-labelledby="alert-dialog-slide-title1"
          aria-describedby="alert-dialog-slide-description1"
        >
        <DialogTitle id="alert-dialog-slide-title1">{commonBomItems.length > 0 ? "Common BOM Items" : "There Are No Items To Be Added"}</DialogTitle>
        
        <DialogContent>
          <TableContainer component={Paper}>
            <Table className={classes.table} size="small" aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Sl No</StyledTableCell>
                  <StyledTableCell>Select Room</StyledTableCell>
                  <StyledTableCell>Select Scope</StyledTableCell>
                  <StyledTableCell>Sub Category</StyledTableCell>
                  <StyledTableCell>Material</StyledTableCell>
                  <StyledTableCell>Select UOM</StyledTableCell>
                  <StyledTableCell>Qty</StyledTableCell>
                  <StyledTableCell>Remove</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {commonBomItems?.map((row, i) => (
                  // <StyledTableRow key={i + 1} style={{ border: selectedMaterial[i]?.status === 'Inactive' ? '2px solid #000' : '' }} >
                  <StyledTableRow key={i + 1} >
                    <StyledTableCell>{i + 1}</StyledTableCell>
                    
                    <StyledTableCell>
                      <Autocomplete
                      // value={getSelectedRoomInCommonBomItems(i)}
                      value={row.space}
                      disabled={false}
                      onChange={(e, v) => handleRoomSelectInCommonBomItems(e, v, i)}
                      options={rooms}
                      getOptionLabel={(option) => option.name}
                      style={{ width: 150 }}
                      renderInput={(params) => <TextField {...params} label="Select Room" fullWidth size="small" variant="outlined" />}
                      />
                    </StyledTableCell>
                    
                    <StyledTableCell>
                      <Autocomplete
                      // value={getSelectedScopeInCommonBomItems(i)}
                      value={row.scope}
                      disabled={false}
                      onChange={(e, v) => handleScopeSelectInCommonBomItems(e, v, i)}
                      options={scopes}
                      getOptionLabel={(option) => option.name}
                      style={{ width: 150, fontSize: '12px' }}
                      renderInput={(params) => <TextField {...params} label="Select Scope" fullWidth size="small" variant="outlined" />}
                      />
                    </StyledTableCell>
                    
                    <StyledTableCell>
                    <TextField
                      // className={classes.textField}
                      value={row.subCategory.name}
                      disabled={true}
                      // onChange={(e) => handleQuantityInCommonBomItems(e, i)}
                      type="test"
                      size="large"
                      variant="outlined"
                      />
                    </StyledTableCell>
                    
                    <StyledTableCell>
                      <TextField
                      // className={classes.textField}
                      value={row.material.name}
                      disabled={true}
                      // onChange={(e) => handleQuantityInCommonBomItems(e, i)}
                      type="test"
                      size="large"
                      variant="outlined"
                      />
                    </StyledTableCell>
                    
                    <StyledTableCell>
                      <Autocomplete
                      // value={getSelectedUomInCommonBomItems(i)}
                      value={row.unitType}
                      disabled={false}
                      // filterOptions={(options, params) => {
                      //   const filtered = filter(options, params);
                      //   if (params.inputValue !== '') {
                      //     filtered.push({
                      //       inputValue: params.inputValue,
                      //       name: `Add "${params.inputValue}"`,
                      //     });
                      //   }
                      //   return filtered;
                      // }}
                      onChange={(e, v) => {
                      // if (typeof v === 'string') {
                      //   setTimeout(() => {
                      //     handleUomSelectInCommonBomItems({ name: v })
                      //   });
                      // } else if (v && v.inputValue) {
                      //   handleUomSelectInCommonBomItems(null, { name: v.inputValue }, i)
                      // } else {
                        handleUomSelectInCommonBomItems(e, v, i)
                      // }
                    }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      options={units}
                      getOptionLabel={(option) => option.name}
                      style={{ width: 150 }}
                      renderInput={(params) => <TextField {...params} label="Select UOM" fullWidth size="small" variant="outlined" />}
                      />
                    </StyledTableCell>
                    
                    <StyledTableCell>
                      <TextField
                      className={classes.textField}
                      // value={quantityInCommonBomItems[i]}
                      value={row.quantity}
                      disabled={false}
                      onChange={(e) => handleQuantityInCommonBomItems(e, i)}
                      type="number"
                      size="small"
                      variant="outlined"
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <DeleteIcon style={{ cursor: "pointer", verticalAlign: 'middle', marginLeft: 10, color: "red", fontSize: 20 }} onClick={() => handleRemoveCommonBomItemFromTable(i)} />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          
        </DialogContent>
        <DialogActions>
          <Button onClick={resetCommonBomItemsData} color="primary">
            CANCEL
          </Button>
          <Button disabled={false} 
            onClick={addCommonBomItemsToBom} color="primary">
            {commonBomItems.length > 0 ? 'ADD TO BOM' : 'PROCEED'}
          </Button>
        </DialogActions>
      </Dialog>
      
        {/* {inActiveMaterials?.length > 0 && <> */}
        {(inActiveMaterials?.length > 0 && !DONT_SHOW_REMAP_TABLE.includes(userDetails.email)) && <>
          <div style={{ fontSize: 18, marginTop: 10, marginBottom: 10 }}><b>New Materials</b></div>
          <TableContainer component={Paper}>
            <Table className={classes.table} size="small" aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Sl No</StyledTableCell>
                  <StyledTableCell>Material Name</StyledTableCell>
                  <StyledTableCell>Re Map</StyledTableCell>
                  <StyledTableCell>Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {inActiveMaterials?.map((material, i) => (
                  <StyledTableRow key={i + 1} >
                    <StyledTableCell>{i + 1}</StyledTableCell>
                    <StyledTableCell>{material?.materialId?.name}</StyledTableCell>
                    <StyledTableCell>
                      {material?.materialId?.status !== "Sent for Approval" && <Autocomplete
                        value={getRemappedMaterial(i)}
                        onChange={(e, v) => handleRemapOfMaterial(v, i)}
                        options={allMaterials}
                        getOptionLabel={(option) => option.name}
                        // style={{ width: 250, fontSize: '12px' }}
                        renderInput={(params) => <TextField {...params} label="Remap Materials" fullWidth size="small" variant="outlined" />}
                      />}</StyledTableCell>
                    <StyledTableCell>
                      {material?.materialId?.status !== "Sent for Approval" ? <StyledTableRow>
                        <StyledTableCell>
                          <Button variant="contained" color="primary" style={{ background: "#34bf73", margin: 5 }} onClick={() => remapMaterial(material?.materialId?._id, i)}>
                            REMAP
                          </Button>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Button variant="contained" color="primary" style={{ background: "#716ACA", margin: 5 }} onClick={() => sendForApproval(material?.materialId?._id)}>
                            Send For Approval
                          </Button>
                        </StyledTableCell>
                      </StyledTableRow> :
                        <StyledTableRow> Pending For Approval</StyledTableRow>
                      }
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>}
        {props.TYPE !== "VIEW" && props.TYPE !== "VIEW-PENDING" &&
          <div style={{ display: "flex", flexDirection: "row-reverse", marginTop: 15 }} >
            <Button variant="contained" color="primary" style={{ background: "#716ACA", margin: 5 }} onClick={addRows}>
              Show
            </Button>
            <TextField label="Rows to Show" value={rowsToAdd && Math.max(0,rowsToAdd)} type="number"  min="0" onChange={(e) => setRowsToAdd(e.target.value  ? Number(e.target.value) : e.target.value)} variant="outlined" />
          </div>}
      </div>
      {
        props.TYPE !== "VIEW" && props.TYPE !== "VIEW-PENDING" && <div style={{ display: "flex", flexDirection: "row-reverse", marginTop: 15 }} >
          <Button variant="contained" disbaled={Object.keys(inActiveMaterials).length} color="primary" style={{ background: "#716ACA", margin: 5 }} onClick={() => submit('Submitted')}>
            Submit
          </Button>
          <Button variant="contained" color="primary" style={{ background: "#716ACA", margin: 5 }} onClick={() => submit('Saved')}>
            Save Progress
          </Button>
        </div>
      }
      Note: Only after all materials have been approved, Bom can be submitted
    </div >
  )
}

export default connect(null, { setLoading, setSnackInfo, getAllScopes, getAllMaterials, getAllUnits })(BomTable)
