import React, { useEffect, useState, useRef } from 'react';
import {
  TextField, Button, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell,
  TableContainer, TableHead, Typography, TableRow, Paper, Checkbox, Tooltip
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import { Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons';
import HttpService from '../services/httpService';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { connect } from "react-redux";
import { setLoading, setSnackInfo } from '../redux/actions/appAction';
import { ROLENAME } from '../utils/index';
import { useSelector } from 'react-redux'
import { DownloadOutlined } from '@ant-design/icons';
import { EyeOutlined } from "@ant-design/icons";
import moment from 'moment'


const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    top: '16px',
    right: '16px',
    zIndex: 2,
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    position: 'relative',
  },
  iframe: {
    height: '80vh', // Adjust as needed
    width: '80vw', // Adjust as needed
    border: 'none',
  },
  pdfContainer: {
    position: 'relative',
    width: '80vw', // Match iframe width
    height: '80vh', // Match iframe height
    overflow: 'hidden',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.0001)', // Subtle overlay
    zIndex: 1,
  },
  leftOverlay: {
    left: 0,
    width: '40%',
  },
  rightOverlay: {
    right: 0,
    width: '40%',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    maxWidth: 400,
    margin: '0 auto',
  },
  button: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 650,
    border: '1px solid #ddd', // Add border to the table
  },
  tableCell: {
    textAlign: "center",
    border: '1px solid #ddd', // Add border to table cells
    whiteSpace: 'normal', // Allow wrapping of content
    wordWrap: 'break-word', // Break words that exceed the width
    maxWidth: '150px', // Example maximum width
    padding: '8px', // Adjust padding as needed
  },
  fileContainer :{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    // maxWidth: '150px', // Example maximum width
    whiteSpace: 'nowrap', // Prevent wrapping of content
    padding: '8px', // Adjust padding as needed
  },
  fileNameCell: {
    overflow: 'hidden', // Hide overflowing content
    textOverflow: 'ellipsis', // Show ellipsis (...) when content overflows
  },
  downloadIcon: {
    fontSize: 24, // Increase icon size
    cursor: 'pointer', // Show pointer cursor on hover
    color: theme.palette.primary.main, // Ensure the icon color matches your theme
  },
  
}));

const KnowledgeHub = (props) => {
  const classes = useStyles();
  const containerRef = useRef(null);
  const { userDetails } = useSelector(state => state.user)
  const [formData, setFormData] = useState({
    name: '',
    subTopic: '',
    tag: [],
    departmentIds: [],
    departmentNames: [],
    files: [null],
  });
  const func = Object.keys(formData);
  const { name, tag, departmentIds, departmentNames, files, subTopic } = formData;
  const [departments, setDepartments] = useState([]);
  const [departmentsFilter, setDepartmentsFilter] = useState([]);
  const [tagsFilter, setTagsFilter] = useState([]);
  const [storeTags, setTags] = useState([]);
  const [open, setOpen] = useState(false);
  const [tagDialogOpen, setTagDialogOpen] = useState(false);
  const [newTagName, setNewTagName] = useState('');
  const [printKnowledgeDetails, setKnowledgeDetails] = useState([]);
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [checkedState, setCheckedState] = useState({});
  const [showPpt,setPPt] = useState(false)
  const [error, setError] = useState(null);

  const [fileDialogOpen, setFileDialogOpen] = useState(false);
  const [fileDialogUrl, setFileDialogUrl] = useState('');

  const handleChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const handleDepartmentChange = (event, values) => {
    const selectedIds = values.map(value => value._id);
    const selectedNames = values.map(value => value.name);

    setFormData({
      ...formData,
      departmentIds: selectedIds,
      departmentNames: selectedNames,
    });
  };

  const handleTagChange = (event, values) => {
    const selectedIds = values.map(value => value._id);
    const selectedNames = values.map(value => value.name);

    setFormData({
      ...formData,
      tag: selectedIds,
      tagsName: selectedNames,
    });
  };

  const onImageChange = async (event, index) => {
    props.setLoading(true);
    const fd = new FormData();
    fd.append('file', event.target.files[0]);
  
    try {
      // Upload file to AWS
      const fileUploadRes = await HttpService.uploadFile(fd);
      const uploadedFile = fileUploadRes.data;
  
      // Extract original file name
      const originalFileName = event.target.files[0].name;
  
      // Update files array without mutating state directly
      setFormData(prevFormData => ({
        ...prevFormData,
        files: [
          ...prevFormData.files.slice(0, index), // Keep files before the updated index
          {
            name: uploadedFile, // AWS file URL
            originalFileName: originalFileName // Original file name
          },
          ...prevFormData.files.slice(index + 1), // Keep files after the updated index
        ],
      }));
  
    } catch (error) {
      props.setSnackInfo(error);
    }
    
    props.setLoading(false);
  };
  

  const addFileInput = () => {
    setFormData(prevFormData => ({
      ...prevFormData,
      files: [...prevFormData.files, null], // Add a new file input initialized as null
    }));
  };

  const removeFileInput = (index) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      files: prevFormData.files.filter((_, i) => i !== index), // Remove file input at specified index
    }));
  };

  const handleSubmit = async (event) => {
    props.setLoading(true)
    try {
      let res = await HttpService.saveKnowledgeDetails(formData);
      formData.name = '';
      formData.subTopic = '';
      formData.departmentIds = [];
      formData.departmentNames = [];
      formData.files = [null];
      formData.tag = []
      getAllKnowLedgeDetails(); // Refresh the data after saving
    } catch (error) {
      props.setSnackInfo(error)
    }
    setOpen(false);
    props.setLoading(false)
  };

  const handleClickOpen = () => {
    setOpen(true);
    setIsEditing(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleTagDialogOpen = () => {
    setTagDialogOpen(true);
  };

  const handleTagDialogClose = () => {
    setTagDialogOpen(false);
  };

  const handleSaveTag = async () => {
    props.setLoading(true)
    try {
      setTagDialogOpen(false);
      let res = await HttpService.saveKnowledgeTaging({ name: newTagName });
      setNewTagName('');
      getKnowledgeTags(); // Refresh tags after adding a new one
    } catch (error) {
      props.setSnackInfo(error,"something went wrong")
    }
    props.setLoading(false)
  };

  const handleEditClick = (item) => {
    setFormData({
      name: item.name,
      _id: item._id,
      subTopic : item.subTopic,
      tag: item.tags.map(tag => tag._id),
      tagsName: item.tags.map(tag => tag.name),
      departmentIds: item.departments.map(dept => dept._id),
      departmentNames: item.departments.map(dept => dept.name),
      files: item.files,
    });
    setOpen(true);
    setIsEditing(true);
  };

  const iframeRef = useRef(null);

  const fetchDepartments = async () => {
    try {
      let res = await HttpService.getDepartments();
      setDepartments(res.data);
      setDepartmentsFilter(res.data);
    } catch (error) {
      props.setSnackInfo(error,"something went wrong")
    }
  };

  const getKnowledgeTags = async () => {
    try {
      let res = await HttpService.getKnowledgeTaging();
      setTags(res.data);
      setTagsFilter(res.data)
    } catch (error) {
      props.setSnackInfo(error,"something went wrong")
    }
  };

  const getAllKnowLedgeDetails = async () => {
    props.setLoading(true)
    try {
      let res = await HttpService.getAllKnowLedgeDetails();
      setKnowledgeDetails(res.data);
    } catch (error) {
      props.setSnackInfo(error,"something went wrong")
    }
    props.setLoading(false)
  };


  const handleId = async(event,values) =>{ 
    try { 
     let res = await HttpService.getAllKnowLedgeDetails(values);
     setKnowledgeDetails(res.data); 
 } catch (error) {
    props.setSnackInfo(error,"something went wrong")
     } 
    };

  useEffect(() => {
    getAllKnowLedgeDetails();
    fetchDepartments();
    getKnowledgeTags();
  }, []);

  const handleFileView = (url) => {
    setFileDialogUrl(url.name);
    setFileDialogOpen(true);
  };

  const handleCloseFileDialog = () => {
    setFileDialogOpen(false);
    setFileDialogUrl("")
    setPPt(false)
  };

  const isFormFilled = () => {
    return Object.keys(formData).every(key => {
      if (key === 'files') {
        return formData[key].every(file => file !== null); // Check every element in files array
      }
      if (Array.isArray(formData[key])) {
        return formData[key].length > 0;
      }
      return formData[key];
    });
  };
  
  useEffect(() => {
    const formComplete = isFormFilled();
    setIsFormComplete(formComplete);
  }, [formData]);




  const handleCheckboxChange = async(id) => {
    props.setLoading(true)
    let obj={
      id: id
    }
    try {
       await HttpService.deleteKnowledgeDetails(obj);
       getAllKnowLedgeDetails()
      
    } catch (error) {
      console.error('Error fetching knowledge details:', error);
    }
    props.setLoading(false)
    }
  
    const filteredItems = userDetails?.roles?.some(role => role.name === ROLENAME.LnDManager || role.name === ROLENAME.LnDUser || role.name === ROLENAME.Admin)
    ? printKnowledgeDetails // Show all items for LnD roles
    : printKnowledgeDetails.filter(item => item.active); // Show only active items for other roles



  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ marginRight: '8px' }}>
          <Autocomplete
            multiple
            id="department-autocomplete"
            options={departmentsFilter}
            getOptionLabel={(option) => option?.name}
            style={{ width: 300, flex: 1 }} // Adjust width and flex as needed
            onChange={handleId}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Department"
                placeholder="Select Departments"
              />
            )}
          />
        </div>

        <div style={{ marginRight: '8px' }}>
          <Autocomplete
            multiple
            id="tag_search"
            options={tagsFilter}
            getOptionLabel={(option) => option?.name}
            style={{ width: 300, flex: 1 }} // Adjust width and flex as needed
            onChange={handleId}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Tag Search"
                placeholder="Select Tag"
              />
            )}
          />
        </div>


        {
         userDetails?.roles?.find((role)=>  role.name === ROLENAME.LnDManager || role.name === ROLENAME.LnDUser || role.name === ROLENAME.Admin) && (
        <>
        <div style={{ marginRight: '8px' }}>
          <Button variant="outlined" color="primary" onClick={handleClickOpen}>
            Add New Doc
          </Button>
        </div>
        <div>
          <Button
            type="button"
            variant="outlined"
            color="primary"
            onClick={handleTagDialogOpen}
            style={{ marginLeft: '8px' }} // Adjust margin as needed
          >
            Add New Tag
          </Button>
        </div>
        </>)}
      </div>

      {/* dialog box for previerw pdf */}
      <Dialog
      fullScreen
      open={fileDialogOpen}
      maxWidth="xl"
      onClose={handleCloseFileDialog}
      aria-labelledby="form-dialog-title"
    >
      <Button
        variant="outlined"
        color="primary"
        onClick={handleCloseFileDialog}
        className={classes.closeButton}
      >
        Close Document
      </Button>
      <div className={classes.container}>
        {fileDialogUrl && (
          <>
       
              <div className={classes.pdfContainer} style={{ position: 'relative' }}>
                <iframe
                  // ref={iframeRef}
                  src={`https://docs.google.com/gview?url=${encodeURIComponent(fileDialogUrl)}&embedded=true`}
                  className={classes.iframe}
                  title="PDF Preview"
                />
                <div className={`${classes.overlay} ${classes.leftOverlay}`} />
                <div className={`${classes.overlay} ${classes.rightOverlay}`} />
            </div>
          </>
        )}
      </div>
    </Dialog>

      {/* Dialog for Add/Edit Knowledge Item */}
      <Dialog open={open} maxWidth="xl" onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title"><p style={{ textAlign: "center" }}>{isEditing ? 'Edit' : 'Add New'}</p></DialogTitle>
        <DialogContent>
          <form className={classes.form}>
            <TextField
              label="Topic"
              value={name}
              variant="outlined"
              onChange={(e) => handleChange('name', e.target.value)}
            />
              <TextField
              label="Sub Topic"
              value={subTopic}
              variant="outlined"
              onChange={(e) => handleChange('subTopic', e.target.value)}
            />
            <Autocomplete
              multiple
              id="tag-department"
              options={storeTags}
              getOptionLabel={(option) => option.name}
              onChange={handleTagChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Tags"
                  placeholder="Select Tags"
                />
              )}
              value={storeTags.filter(tag => formData.tag.includes(tag._id))}
            />
            <Autocomplete
              multiple
              id="department-autocomplete"
              options={departments}
              getOptionLabel={(option) => option.name}
              onChange={handleDepartmentChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Department"
                  placeholder="Select Departments"
                />
              )}
              value={departments.filter(dept => formData.departmentIds.includes(dept._id))}
            />
            {formData.files.map((file, index) => (
              <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: 8, margin: "0rem 2rem 0rem 2rem" }}>
                <input
                  id={`contained-button-file-${index}`}
                  type="file"
                  onChange={(e) => onImageChange(e, index)}
                  style={{ display: 'none' }}
                />
                <label htmlFor={`contained-button-file-${index}`}>
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    startIcon={<CloudUploadIcon />}
                  >
                    Upload File
                  </Button>
                </label>
                <Typography variant="body1" style={{ marginLeft: 10 }}>
                  {file ? <CheckCircleIcon style={{ verticalAlign: 'middle', marginLeft: 10, color: "green", fontSize: 30 }} /> : 'No file selected'}
                </Typography>
                {index > 0 && (
                  <IconButton onClick={() => removeFileInput(index)}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </div>
            ))}
            {/* <Button
              variant="contained"
              color="primary"
              onClick={addFileInput}
              startIcon={<AddIcon />}
              style={{ marginTop: 8 }} // Add some spacing above the Add File button
            >
              Add File
            </Button> */}
          </form>
        </DialogContent>
        <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <Button onClick={handleClose} color="primary" style={{ marginLeft: "1rem" }} variant="contained" className={classes.button}>
              Cancel
            </Button>
          </div>
          <div>
            <Button onClick={handleSubmit} disabled={!isFormFilled()} variant="contained" color="primary" className={classes.button}>
              Submit
            </Button>
          </div>
        </DialogActions>
      </Dialog>

      {/* Tag Dialog */}
      <Dialog open={tagDialogOpen} onClose={handleTagDialogClose} aria-labelledby="tag-dialog-title">
        <DialogTitle id="tag-dialog-title">Add New Tag</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="newTagName"
            label="Tag Name"
            type="text"
            fullWidth
            value={newTagName}
            onChange={(e) => setNewTagName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleTagDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSaveTag} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* File Dialog */}


      {/* Knowledge Details Table */}
      <TableContainer component={Paper} style={{ marginTop: "1rem" }}>
      <Table className={classes.table} aria-label="knowledge details table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableCell} style={{fontWeight:800,backgroundColor:"#cccccc"}}>Sl.No</TableCell>
            <TableCell className={classes.tableCell} style={{fontWeight:800,backgroundColor:"#cccccc"}}>Topic</TableCell>
            <TableCell className={classes.tableCell} style={{fontWeight:800,backgroundColor:"#cccccc"}}>Sub Topic</TableCell>
            <TableCell className={classes.tableCell} style={{fontWeight:800,backgroundColor:"#cccccc"}}>Departments</TableCell>
            <TableCell className={classes.tableCell} style={{fontWeight:800,backgroundColor:"#cccccc"}}>Tags</TableCell>
            <TableCell className={classes.tableCell} style={{fontWeight:800,backgroundColor:"#cccccc"}}>Created Date</TableCell>
            <TableCell className={classes.tableCell} style={{fontWeight:800,backgroundColor:"#cccccc"}}>Documents</TableCell>
            {userDetails?.roles?.some(role => role.name === ROLENAME.LnDManager || role.name === ROLENAME.LnDUser || role.name === ROLENAME.Admin) && (
              <TableCell className={classes.tableCell} style={{fontWeight:800,backgroundColor:"#cccccc"}}>Actions</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredItems.map((item, index) => (
            <TableRow key={index}>
              <TableCell className={classes.tableCell}>{index + 1}</TableCell>
              <TableCell className={classes.tableCell}>{item?.name}</TableCell>
              <TableCell className={classes.tableCell}>{item?.subTopic}</TableCell>
              <TableCell className={classes.tableCell}>
                {item.departments.map((dept, deptIndex) => (
                  <span key={deptIndex}>
                    {deptIndex > 0 && ', '}
                    {dept?.name}
                  </span>
                ))}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {item.tags.map((tag, tagIndex) => (
                  <span key={tagIndex}>
                    {tagIndex > 0 && ', '}
                    {tag.name}
                  </span>
                ))}
              </TableCell>
              <TableCell className={classes.tableCell}>{ moment(item?.createdAt).format('DD-MM-YYYY')}</TableCell>
              <TableCell className={classes.tableCell}>
                {item.files.map((file, fileIndex) => (
                  <div key={fileIndex} className={classes.fileContainer}>
                    <Tooltip title={file.originalFileName} arrow>
                      <div className={classes.fileNameCell}>
                        {file?.originalFileName}
                      </div>
                    </Tooltip>
                    <EyeOutlined
                      className={classes.downloadIcon}
                      onClick={()=>handleFileView(file)}
                    />
                  </div>
                ))}
              </TableCell>
              {userDetails?.roles?.some(role => role.name === ROLENAME.LnDManager || role.name === ROLENAME.LnDUser || role.name === ROLENAME.Admin) && (
              <TableCell className={classes.tableCell}>
                  <>
                    <Button variant="contained" color="primary" onClick={() => handleEditClick(item)}>
                      Edit
                    </Button>
                    <Checkbox
                      checked={item.active}
                      onChange={() => handleCheckboxChange(item?._id)}
                    />
                  </>
              </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </div>
  );
};


export default connect(null, { setLoading, setSnackInfo })(KnowledgeHub);

