import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { setLoading, setSnackInfo } from '../redux/actions/appAction';
import HttpService from '../services/httpService';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

const ReUsableExpDetails = (props) => {
    const [expCenterDetails, setExpcentersDetails] = useState([]);
    const [selectedCenter, setSelectedCenter] = useState('');

    useEffect(() => {
        getAllExpCenterDetails();
    }, []);

    const getAllExpCenterDetails = async () => {
        try {
            let response = await HttpService.getAllExpCenter();
            setExpcentersDetails(response.data);
        } catch (error) {
            props.setLoading("Something Went Wrong", error);
        }
    };

    const handleChange = (event) => {
        setSelectedCenter(event.target.value);
        props.onChange(event.target.value);
    };

    return (
        <FormControl fullWidth>
            <InputLabel id="visitedExperienceCenter-label">Select Experience Center</InputLabel>
            <Select
                labelId="visitedExperienceCenter-label"
                id="visitedExperienceCenter-select"
                value={selectedCenter}
                onChange={handleChange}
            >
                {expCenterDetails.map((center, index) => (
                    <MenuItem key={index} value={center.name}>
                        {`Decorpot - ${center.name}`}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default connect(null, { setLoading, setSnackInfo })(ReUsableExpDetails);