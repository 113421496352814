import React, { useState, useEffect, useRef } from 'react';
import { useParams } from "react-router-dom";
import { connect, useSelector } from 'react-redux'
import { Link } from "react-router-dom";
import { setLoading, setSnackInfo } from '../redux/actions/appAction';
import HttpService from '../services/httpService';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { CSVLink } from "react-csv";
import TextField from '@material-ui/core/TextField';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Grid, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import AttachmentIcon from '@material-ui/icons/Attachment';
import { commaSeparateAmount } from './commaSeparate';
// import { DataGrid } from '@material-ui/data-grid';

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 700,
    },
    root: {
        width: '100%',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));

const StageWiseLeads = (props) => {
    const classes = useStyles();
    const { statusName, check, isUser } = useParams();
    const [leadStatusData, setLeadStatusData] = useState([])
    const [duplicateLeadStatusData, setDuplicateLeadStatusData] = useState([])
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const { userDetails } = useSelector(state => state.user)
    const [selectedFilterOption, setSelectedFilterOption] = useState(props.location.state === null ? 'leadWonDate' : props.location.state.filterOption);
    const [startDateFilter, setStartDate] = useState(props.location.state.startDate)
    const [endDateFilter, setEndDate] = useState(props.location.state.endDate);
    const headers = [
        { key: "customerName", label: "Customer Name" },
        { key: "email", label: "Email Id" },
        { key: "contact_no", label: "Mobile No." },
        { key: 'salesStage', label: "salesStage" },
        { key: "address", label: "Address" },
        { key: "area", label: "Area" },
        { key: 'leadType', label: "Lead Type" },
        { key: "assignTo", label: 'Assign To' },
        { key: "estimatedBudget", label: 'Quotation Amount' },
        { key: "createdAt", label: 'Created At' },
    ];

    const [csvData, setCsvData] = useState([]);
    const buttonRef = useRef(null);
    const [searched, setSearched] = useState("")
    const [filterData, setFilterData] = useState({
        assignTo: "",
        quotamount: 0,
        createdAt: "22-04-2022"
    })

    const handleChangeFilterOption = (event) => {
        setSelectedFilterOption(event.target.value);
    };

    const { assignTo, quotamount, createdAt } = filterData

    const [isRemiderStatus, setIsReminderStatus] = useState(false);
    useEffect(() => {
        getLeadCountDetails();
        // eslint-disable-next-line
    }, [props])

    const getLeadCountDetails = async () => {
        let obj = {
            salesStage: props.location.state.salesStage
        }
        if (userDetails.departmentId === '5cb5b38bcf5531e174cb23e0') {
            obj.filterOption = selectedFilterOption
        }
        if (props.location.state !== null) {
            obj.experienceCenterId = props.location.state.experienceCenterId
        } try{
            const response = await HttpService.getStageWiseLeadData(obj)
            getFilterWiseData(response.data);

        }catch (error){
            console.log(error)
        }   
    }

    const getFilterWiseData = (data) => {
        if (props.location.state.salesStage){
            //const sname = data.filter(ele => ele.assignTo.teamId.name.replace(/[\s+%/\\#,+$~.'":*?<>{}]/g, '') === statusName.replace(/-/g, ''));
            const statusLead = data.map(item => {
                let obj = {
                    leadId: item?._id,
                    customerName: item?.customerId?.name,
                    customerId: item?.customerId?._id,
                    email: item?.customerId?.email,
                    contact_no: item?.customerId?.contact_no,
                    address: item?.address,
                    area: item?.area,
                    salesStage: item?.salesStage,
                    leadType: item?.leadType,
                    assignTo: item?.assignTo?.name,
                    estimatedBudget: item?.grandTotal,
                    createdAt: moment(item?.createdAt).format("DD-MM-YYYY")
                }
                return obj
            })
            setLeadStatusData(statusLead);
            setDuplicateLeadStatusData(statusLead);
            setCsvData(statusLead);
        } else {
            const statusLead = data.map(item => {
                let obj = {
                    leadId: item?._id,
                    customerName: item?.customerId?.name,
                    customerId: item?.customerId?._id,
                    salesStage: item?.salesStage,
                    email: item?.customerId?.email,
                    contact_no: item?.customerId?.contact_no,
                    address: item?.address,
                    area: item?.area,
                    leadType: item?.leadType,
                    assignTo: item?.assignTo?.name,
                    estimatedBudget: item?.grandTotal,
                    createdAt: moment(item?.createdAt).format("DD-MM-YYYY")
                }
                return obj
            })
            setLeadStatusData(statusLead);
            setDuplicateLeadStatusData(statusLead);
            setCsvData(statusLead);
        }
    }

    const [reminderNotes, setReminderNotes] = useState([]);
    const setUserReminderData = (data) => {
        setReminderNotes(data)
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const downloadExcel = () => {
        props.setLoading(true)
        try {
            setTimeout(() => {
                buttonRef.current.link.click();
            });
        } catch (err) {
            console.error(err.message);
        }
        props.setLoading(false)
    }

    const setHandleSearched = (e) => {
        setSearched(e.target.vlue)
        requestSearch(e.target.value)
    }

    const requestSearch = (search) => {
        const filteredRow = duplicateLeadStatusData.filter(row =>
            row.customerName.toLowerCase().includes(search.toLowerCase()) ||
            row.email.toLowerCase().includes(search.toLowerCase()) ||
            row.contact_no.toLowerCase().includes(search.toLowerCase()) ||
            row.address.toLowerCase().includes(search.toLowerCase()) ||
            row.area.toString().toLowerCase().includes(search.toLowerCase()) ||
            row.leadType.toString().toLowerCase().includes(search.toLowerCase())
        )
        setLeadStatusData(filteredRow);
        setCsvData(filteredRow);
    }

    const clearFilter = () => {
        getLeadCountDetails();
        setSearched('')
        setFilterData({
            assignTo: "",
            quotamount: "",
            createdAt: ""
        })
    }

    const applyFilter = () => {
        const newArr = [];
        duplicateLeadStatusData.forEach(o => {
            if (o.assignTo.toLowerCase() === filterData?.assignTo.toLowerCase() ||
                o.estimatedBudget === Number(filterData?.quotamount) ||
                o.createdAt === filterData?.createdAt) {
                newArr.push(o)
            }
        });
        setLeadStatusData(newArr);
        setCsvData(newArr);
    }

    const getLeadsOnSelectedDateByRole = async () => {
        
        let obj = {
            salesStage: props.location.state.salesStage,
            startDate: startDateFilter,
            endDate: endDateFilter,
            filterOption : selectedFilterOption,
            experienceCenterId : props.location.state.experienceCenterId
        }
         try {
            const response = await HttpService.getStageWiseLeadData(obj)
            getFilterWiseData(response.data);

        } catch (error) {
            console.log(error)
        }   

    }

    return (
        <>
            <div style={{ marginBottom: 20 }}>
                <h3 style={{ textAlign: 'center' }}>
                    
                </h3>
            </div>
            <Divider />
            {
                !isRemiderStatus ?
                    <>
                        <Paper className={classes.paper}>
                            <FormControl component="fieldset" style={{ marginBottom: 20 }}>
                                <FormLabel component="legend">Select Filter Option</FormLabel>
                                <RadioGroup row aria-label="filterOption" name="filterOption1" value={selectedFilterOption} onChange={handleChangeFilterOption}>
                                    <FormControlLabel value="leadWonDate" control={<Radio />} label="Lead Won Date" />
                                    <FormControlLabel value="createdAt" control={<Radio />} label="Lead Created At" />
                                    {/* {userDetails.departmentId === designDepartment && <FormControlLabel value="contractSignedDate" control={<Radio />} label="Contract Sign Date" />} */}
                                </RadioGroup>
                            </FormControl>
                            <br />
                            <TextField
                                id="date"
                                name="startDate"
                                label="From Date"
                                type="date"
                                value={startDateFilter}
                                onChange={(e) => setStartDate(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                                className={classes.textField}
                            />
                            <TextField
                                id="date"
                                name="endDate"
                                label="End Date"
                                type="date"
                                value={endDateFilter}
                                onChange={(e) => setEndDate(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                                className={classes.textField}
                            />
                            <Button variant="contained" color="primary" style={{ alignSelf: "center", marginLeft: 20 }} onClick={getLeadsOnSelectedDateByRole} >
                                Submit
                            </Button>
                        </Paper>
                        <div style={{ marginTop: 15, marginBottom: 15, textAlign: 'right' }}>
                            <TextField label="Search here.." name="searched" value={searched} size="small" onChange={setHandleSearched} variant="outlined" style={{ marginRight: 20 }} />
                            <Button onClick={downloadExcel} variant="contained" color="primary">
                                Download Excel
                            </Button>
                            <CSVLink
                                headers={headers}
                                // filename={statusName.replace(/-/g, ' ') + '.csv'}
                                data={csvData}
                                ref={buttonRef}
                            />
                        </div>
                        <Divider />
                        <div style={{ display: 'flex', marginTop: 10, marginBottom: 20 }}>
                            <Paper>
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Sr. No.</TableCell>
                                                <TableCell>Customer</TableCell>
                                                <TableCell>Stage</TableCell>
                                                <TableCell>Email</TableCell>
                                                <TableCell>Mobile</TableCell>
                                                <TableCell>Address</TableCell>
                                                <TableCell>Area</TableCell>
                                                <TableCell>Lead Type</TableCell>
                                                <TableCell>Assigned to</TableCell>
                                                <TableCell>Quotation Amount</TableCell>
                                                <TableCell>Created At</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {leadStatusData.length !== 0 ?
                                                leadStatusData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    .sort((a, b) => b.createdAt.split('-').reverse().join().localeCompare(a.createdAt.split('-').reverse().join()))
                                                    .map((row, i) => {
                                                        return (
                                                            <TableRow key={i + 1}>
                                                                <TableCell>{i + 1}</TableCell>
                                                                <TableCell component="th" scope="row">
                                                                    <Link to={`/project-logs/${row?.leadId}`} style={{ color: "#000", textDecoration: 'underline' }}>{row?.customerName}</Link>
                                                                </TableCell>
                                                                <TableCell component="th">{row?.salesStage}</TableCell>
                                                                <TableCell component="th">{row?.email}</TableCell>
                                                                <TableCell component="th">{row?.contact_no}</TableCell>
                                                                <TableCell component="th">{row?.address}</TableCell>
                                                                <TableCell component="th">{row?.area}</TableCell>
                                                                <TableCell component="th">{row?.leadType}</TableCell>
                                                                <TableCell component="th">{row?.assignTo}</TableCell>
                                                                <TableCell component="th">₹ {commaSeparateAmount(row?.estimatedBudget)}</TableCell>
                                                                <TableCell component="th">{row?.createdAt}</TableCell>
                                                            </TableRow>
                                                        );
                                                    })
                                                :
                                                <TableRow>
                                                    <TableCell colSpan={9} style={{ textAlign: 'center' }}>No Records Found</TableCell>
                                                </TableRow>
                                            }
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                <TablePagination
                                                    rowsPerPageOptions={[200, 300, 400, { label: 'All', value: -1 }]}
                                                    colSpan={10}
                                                    count={leadStatusData.length}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    SelectProps={{
                                                        inputProps: { 'aria-label': 'rows per page' },
                                                        native: true,
                                                    }}
                                                    onPageChange={handleChangePage}
                                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                                />
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </div>
                    </>
                    :
                    <>
                        {reminderNotes.length !== 0 ?
                            reminderNotes.map((data, i) => (
                                <Grid item lg={6} md={6} key={i}>
                                    <Paper style={{ padding: 10 }}>
                                        <Accordion defaultExpanded className="accordian">
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography className={classes.heading}>
                                                    <LocalActivityIcon />
                                                    <span className="titlename">Deal Activity</span>
                                                    <span className="datedata">{moment(data?.createdAt).format('lll')}</span>
                                                </Typography>
                                            </AccordionSummary>
                                            <Divider />
                                            <AccordionDetails>
                                                <Typography>
                                                    <strong>Customer Name: {data?.leadId?.customerId?.name}</strong>
                                                    <br />
                                                    <strong>Message:</strong> {data?.notes}
                                                    <br />
                                                    <hr />
                                                    <strong><small>Created By:</small></strong> <small>{data?.createdBy?.name}</small>
                                                </Typography>
                                                <AttachmentIcon onClick={() => window.open(data.s3Location[0])} />
                                            </AccordionDetails>
                                        </Accordion >
                                    </Paper>
                                </Grid>
                            ))
                            :
                            <p style={{ marginTop: 20, textAlign: 'center' }}>No Reminder Found</p>
                        }
                    </>
            }
        </>
    )
}

export default connect(null, { setLoading, setSnackInfo })(StageWiseLeads)