import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { setLoading, setSnackInfo } from '../../../redux/actions/appAction'
import HttpService from '../../../services/httpService'
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Button from '@material-ui/core/Button';
import { Dialog, DialogTitle, Divider, DialogContent } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 700,
    },
    root: {
        width: '100%',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    }
}));

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#ccc",
        color: theme.palette.common.black,
        textAlign: 'center'
    },
    body: {
        fontSize: 14,
        textAlign: 'center'
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const ProjectMaterilData = (props) => {
    const classes = useStyles();
    const [projectMaterilsList, setProjectMaterilsList] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [totalaPages, setTotalPages] = useState(0)

    useEffect(() => {
        getProjectsMaterials()
        // eslint-disable-next-line
    }, [])

    const getProjectsMaterials = async () => {
        props.setLoading(true)
        try {
            const result = await HttpService.getProjectsMaterialsList(props.location.state)
            setProjectMaterilsList(result.data);
            setTotalPages(result.data.length)
        } catch (error) {
            console.log(error, "error")
            props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const [openMaterialView, setOpenMaterialView] = useState(false);
    const [orderMaterials, setOrderMaterials] = useState([]);
    const [orderMaterialsList, setOrderMaterialsList] = useState([]);
    const viewMaterialsOfOrderId = (orderId) => {
        setOpenMaterialView(true);
        let selectedOrderId = projectMaterilsList.filter(ele => ele._id === orderId);
        setOrderMaterials(selectedOrderId[0])
        setOrderMaterialsList(selectedOrderId[0].materials)
    }

    const updateMaterialReceivedStatus = async (materialId) => {
        props.setLoading(true)
        try {
            let obj = {
                materialId: materialId,
                status: 'Received',
                orderId: orderMaterials.orderId
            }
            const response = await HttpService.updateMaterialReceivedStatus(orderMaterials._id, obj)
            getProjectsMaterials()
            setOpenMaterialView(false)
            props.setSnackInfo(response?.data ?? "Something went wrong", "success")
        } catch (error) {
            props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    return (
        <div>
            <Paper>
                <TableContainer component={Paper}>
                    <Table className={classes.table} stickyHeader aria-label="sticky table">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell>Sr. No.</StyledTableCell>
                                <StyledTableCell>Project Code</StyledTableCell>
                                <StyledTableCell>Order Id</StyledTableCell>
                                <StyledTableCell>Status</StyledTableCell>
                                <StyledTableCell>Updated At</StyledTableCell>
                                <StyledTableCell>View Materials</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {projectMaterilsList.map((row, i) => {
                                return (
                                    <StyledTableRow key={i + 1}>
                                        <StyledTableCell>{i + 1}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">{row?.projectId?.code}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">{row?.orderId}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">{row?.status}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">{moment(row?.updatedAt).format('DD-MM-YYYY')}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">
                                            <Button variant="contained" color="primary" onClick={() => viewMaterialsOfOrderId(row._id)}>
                                                View Materials
                                            </Button>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                );
                            })}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 20, 50, { label: 'All', value: totalaPages }]}
                                    colSpan={10}
                                    count={totalaPages}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: { 'aria-label': 'rows per page' },
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Paper>

            {/* View OrderId Materials */}
            <Dialog open={openMaterialView} onClose={() => setOpenMaterialView(false)} aria-labelledby="form-dialog-title" maxWidth='md'>
                <DialogTitle id="form-dialog-title">
                    Order Materials <br />
                    <span style={{ fontSize: 'small' }}><b>Order Id:</b> {orderMaterials.orderId} <br />
                        <b>Project Code:</b> {orderMaterials?.projectId?.code} </span>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} stickyHeader aria-label="sticky table">
                            <TableHead>
                                <StyledTableRow>
                                    <StyledTableCell>Sr. No.</StyledTableCell>
                                    <StyledTableCell>Material Name</StyledTableCell>
                                    <StyledTableCell>Quantity</StyledTableCell>
                                    <StyledTableCell>Received Quantity</StyledTableCell>
                                    <StyledTableCell>Price</StyledTableCell>
                                    <StyledTableCell>Scope</StyledTableCell>
                                    <StyledTableCell>Unit Type</StyledTableCell>
                                    <StyledTableCell>Action</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {orderMaterialsList.map((row, i) => {
                                    return (
                                        <StyledTableRow key={i + 1}>
                                            <StyledTableCell>{i + 1}</StyledTableCell>
                                            <StyledTableCell component="th" scope="row">{row?.materialId?.name}</StyledTableCell>
                                            <StyledTableCell component="th" scope="row">{row?.quantity}</StyledTableCell>
                                            <StyledTableCell component="th" scope="row">{row?.receivedQuantity}</StyledTableCell>
                                            <StyledTableCell component="th" scope="row">{row?.requestedPrice}</StyledTableCell>
                                            <StyledTableCell component="th" scope="row">{row?.scopeId?.name}</StyledTableCell>
                                            <StyledTableCell component="th" scope="row">{row?.unitId?.name}</StyledTableCell>
                                            <StyledTableCell component="th" scope="row">
                                                {
                                                    row.factoryStatus === 'Received' ?
                                                        <Button variant="contained" color="primary" disabled>
                                                            Material Recevied
                                                        </Button> 
                                                        :
                                                        <Button variant="contained" color="primary" onClick={() => updateMaterialReceivedStatus(row._id)}>
                                                            Update Material Status
                                                        </Button>
                                                }
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
            </Dialog>
        </div>
    )
}
export default connect(null, { setLoading, setSnackInfo })(ProjectMaterilData)
