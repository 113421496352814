import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import HttpService from '../../services/httpService';
import { setLoading, setSnackInfo } from '../../redux/actions/appAction'
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow, TableHead, MenuItem, FormControl, DialogContentText, Grid, TableFooter } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import { ROLENAME } from '../../utils/index';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));

const MarketingBudgetPage = (props) => {
    const classes = useStyles();
    const { userDetails } = useSelector(state => state.user)
    const [marketignBudgetSouce, setMarketignBudgetSouce] = useState([]);
    const [marketignBudgetTotal, setMarketignBudgetTotal] = useState([]);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    useEffect(() => {
        // getMarketingBudgetSource()
        years();
        getLocationList();
        // eslint-disable-next-line
    }, [])

    const [yearsList, setYearsList] = useState([])

    const years = () => {
        const year = new Date().getFullYear();
        let lastyears = []
        lastyears = Array.from({ length: 5 }, (v, i) => year - 5 + i + 1);
        setYearsList(lastyears)
    }

    const yr = {
        year: new Date().getFullYear()
    }
    const [selectedYear, setSelectedYear] = useState(yr)
    // const [selectedCity, setSelectedCity] = useState('');

    const [expcenters, setExpcenters] = useState([]);
    const [defaultExpCenter, setDefaultExpCenter] = useState('');
    const [defaultCity, setDefaultCity] = useState('');
    const [cities, setCities] = useState([]);
    const [expCenterId, setExpCenterId] = useState('');
    const [cityId, setCityId] = useState('');

    const { year } = selectedYear

    const getLocationList = async () => {
        try {
            const response = await HttpService.getLocationList()
            if (userDetails?.roles?.find(role => role.name === ROLENAME.Admin)) {
                setCities(response.data)
            } else {
                setCities(userDetails.locationId)
            }
            // const ddd = response.data.filter(ele => ele._id === props.location.state.location);
            setDefaultCity(response.data[0]['name'])
            getDefaultExperienceCenter(response.data[0]['_id'])
            setCityId(response.data[0]['_id']);
        } catch (error) {
            console.log(error)
        }
    }

    const getDefaultExperienceCenter = async (locid) => {
        try {
            const response = await HttpService.getExpericenCentersByLocation(locid)
            let userExpCenters = [];
            for (let i = 0; i < response.data.length; i++) {
                for (let j = 0; j < userDetails.experienceCenterId.length; j++) {
                    if (userDetails.experienceCenterId[j] === response.data[i]['_id']) {
                        userExpCenters.push({
                            name: response.data[i]['name'],
                            _id: response.data[i]['_id']
                        });
                    }
                }
            }
            setExpcenters(userExpCenters);
            console.log(userExpCenters, "userExpCenters");
            // const finalselected = userExpCenters.filter(ele => ele.name === props.location.state.experiencecenter)
            setDefaultExpCenter(userExpCenters[0]['name']);
            setExpCenterId(userExpCenters[0]['_id'])
            getMarketingBudgetSource(locid, userExpCenters[0]['_id'])
        } catch (error) {
            console.log(error)
        }
    }

    const getExperienceCenter = async (e) => {
        try {
            const ddd = cities.filter(ele => ele.name === e.target.value);
            setDefaultCity(ddd[0]['name'])
            setCityId(ddd[0]['_id']);
            const response = await HttpService.getExpericenCentersByLocation(ddd[0]['_id']);
            let userExpCenters = [];
            for (let i = 0; i < response.data.length; i++) {
                for (let j = 0; j < userDetails.experienceCenterId.length; j++) {
                    if (userDetails.experienceCenterId[j] === response.data[i]['_id']) {
                        userExpCenters.push({
                            name: response.data[i]['name'],
                            _id: response.data[i]['_id']
                        });
                    }
                }
            }
            setExpcenters(userExpCenters);
            const finalselected = userExpCenters.filter(ele => ele.name === ddd[0]['name'])
            setDefaultExpCenter(finalselected[0]['name']);
            setExpCenterId(userExpCenters[0]['_id'])
        } catch (error) {
            console.log(error)
        }
    }

    const setExpCenter = (center) => {
        setDefaultExpCenter(center);
        let expcenterId = expcenters.find((ele, i) => ele.name === center);
        setExpCenterId(expcenterId._id)
    }

    const getMarketingBudgetSource = async (city, exp) => {
        props.setLoading(true)
        try {
            const result = await HttpService.getBudgetSelectedYear(selectedYear.year, city, exp)
            setMarketignBudgetSouce(result.data.sort((a, b) => a.name > b.name ? 1 : -1));
            getTotalBudget(result.data.sort((a, b) => a.name > b.name ? 1 : -1));
        } catch (error) {
            console.error(error)
            props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const getLeadsOnSelectedDate = async (e) => {
        setSelectedYear({ [e.target.name]: e.target.value })
    }

    const getTotalBudget = (result) => {
        let allMonthTotal = [];
        result.forEach(ele => {
            ele.budgetArray.forEach((ele1, i) => {
                if (ele1.value !== 'NA') {
                    let totalDataToIndex = {};
                    totalDataToIndex.total = 0;
                    totalDataToIndex.total += ele1.value;
                    totalDataToIndex.month = i;
                    allMonthTotal.push(totalDataToIndex)
                } else {
                    let totalDataToIndex = {};
                    totalDataToIndex.month = i;
                    totalDataToIndex.total = 0;
                    allMonthTotal.push(totalDataToIndex)
                }
            })
        })
        
        var totalIndexValue = {};

        allMonthTotal.forEach(function (d) {
            if (totalIndexValue.hasOwnProperty(d.month)) {
                totalIndexValue[d.month] = totalIndexValue[d.month] + d.total;
            } else {
                totalIndexValue[d.month] = d.total;
            }
        });

        var finalMonthTotal = [];
        
        for (var prop in totalIndexValue) {
            finalMonthTotal.push({ month: prop, total: totalIndexValue[prop] });
        }

        setMarketignBudgetTotal(finalMonthTotal)
    }

    const [openBudgetModal, setOpenBudgetModal] = useState(false);
    const [budgetValue, setBudgetValue] = useState(0)
    const [isNewBudget, setIsNewBudget] = useState(false);
    const [selectedSource, setSelectedSource] = useState({})
    const [selectedMonth, setSelectedMonth] = useState({})

    const setBudget = (budgetId, selectedSource, currentValue, selectedMonth) => {
        setOpenBudgetModal(true);
        setBudgetValue(currentValue);
        setSelectedSource(selectedSource)
        setSelectedMonth(selectedMonth)
        if (budgetId) {
            setIsNewBudget(true)
        } else {
            setIsNewBudget(false)
        }
    }

    const saveBudget = async () => {
        if (budgetValue < 0) return;

        let data = {
            year: selectedMonth.year ? selectedMonth.year : selectedYear.year,
            month: selectedMonth.month,
            value: budgetValue,
            sourceId: selectedSource.sourceid,
            locationId: cityId,
            experienceCenterId: expCenterId
        }

        if (isNewBudget) {
            props.setLoading(true)
            try {
                await HttpService.editMarketBudget(selectedMonth._id, data)
                props.setSnackInfo("Budget Updated Successfully", "Success")
                setOpenBudgetModal(false);
                getMarketingBudgetSource(cityId, expCenterId)
            } catch (error) {
                props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
            }
            props.setLoading(false)

        } else {
            props.setLoading(true)
            try {
                await HttpService.addNewBudget(data)
                props.setSnackInfo("Budget Added Successfully", "Success")
                setOpenBudgetModal(false);
                getMarketingBudgetSource(cityId, expCenterId)
            } catch (error) {
                props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
            }
            props.setLoading(false)
        }
    }

    const getData = async () => {
        props.setLoading(true)
        try {
            const result = await HttpService.getBudgetSelectedYear(year, cityId, expCenterId)
            setMarketignBudgetSouce(result.data.sort((a, b) => a.name > b.name ? 1 : -1));
            getTotalBudget(result.data.sort((a, b) => a.name > b.name ? 1 : -1));
        } catch (error) {
            console.error(error)
            props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    return (
        <div>
            <div style={{ marginTop: 25, marginBottom: 25 }}>
                <Paper className={classes.paper}>
                    <Grid container spacing={2} style={{ marginBottom: 20, margin: 'auto' }}>
                        <Grid item xs={3}>
                            <FormControl style={{ width: 300, marginRight: 20 }}>
                                <TextField
                                    select
                                    labelId="demo-simple-select-label"
                                    id="demo-expcenter-simple-select"
                                    name="city"
                                    value={defaultCity}
                                    label="Select Location"
                                    onChange={getExperienceCenter}
                                    variant="outlined"
                                    size="small"
                                >
                                    {
                                        cities?.map((data, i) => (
                                            <MenuItem value={data.name} key={i + 1}>{data.name}</MenuItem>
                                        ))
                                    }
                                </TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl style={{ width: 300 }}>
                                <TextField
                                    select
                                    labelId="demo-simple-select-label"
                                    id="demo-expcenter-simple-select"
                                    name="experienceCenterId"
                                    value={defaultExpCenter}
                                    label="Select Experience Center"
                                    onChange={(e) => setExpCenter(e.target.value)}
                                    variant="outlined"
                                    size="small"
                                >
                                    {
                                        expcenters?.map((data, i) => (
                                            <MenuItem value={data.name} key={i + 1}>{data.name}</MenuItem>
                                        ))
                                    }
                                </TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <TextField
                                    select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name="year"
                                    value={year}
                                    label="Select Year"
                                    onChange={getLeadsOnSelectedDate}
                                    variant="outlined"
                                    size="small"
                                >
                                    {
                                        yearsList?.map((data, i) => (
                                            <MenuItem value={data} key={i + 1}>{data}</MenuItem>
                                        ))
                                    }
                                </TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <Button onClick={getData} color="primary" variant="outlined">Get Data</Button>
                        </Grid>
                    </Grid>



                    <TableContainer component={Paper} style={{ marginTop: 40 }}>
                        <Table className={classes.table} stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow >
                                    <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Source</TableCell>
                                    {
                                        months.map((row, i) => {
                                            return (
                                                <TableCell key={i} style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>{row}</TableCell>
                                            )
                                        })
                                    }
                                    <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Total</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {marketignBudgetSouce.map((row, i) => {
                                    return (
                                        <TableRow key={i + 1}>
                                            <TableCell key={i} component="th" scope="row" style={{ padding: 10, width: 500, textAlign: 'center' }}>{row.name}</TableCell>
                                            {
                                                row.budgetArray.map((row1, i) => {
                                                    return (
                                                        <TableCell key={i} component="th" scope="row" style={{ padding: 10, width: 500, textAlign: 'center' }}>
                                                            {row1.value}
                                                            <EditTwoToneIcon
                                                                style={{ marginTop: -5, marginLeft: 5, cursor: 'pointer', fontSize: "inherit" }}
                                                                onClick={(e) => setBudget(row1._id, row, row1.value, row1)} />
                                                        </TableCell>
                                                    )
                                                })
                                            }
                                            <TableCell key={i + 1} component="th" scope="row" style={{ padding: 10, width: 500, textAlign: 'center' }}>{row.total}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TableCell component="th" scope="row" style={{ fontWeight: 700, padding: 10, width: 500, textAlign: 'center', fontSize: "inherit" }}>Total</TableCell>
                                    {
                                        marketignBudgetTotal.map((row, i) => {
                                            return (
                                                <TableCell key={i} component="th" scope="row" style={{ fontWeight: 700, padding: 10, width: 500, textAlign: 'center', fontSize: "inherit" }}>{row.total}</TableCell>
                                            )
                                        })
                                    }
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Paper>
            </div>
            <Dialog open={openBudgetModal} onClose={() => setOpenBudgetModal(false)} aria-labelledby="form-dialog-title" fullWidth maxWidth='sm'>
                <DialogTitle id="form-dialog-title">
                    Set Budget for - {selectedSource.name} - {selectedYear.year}
                </DialogTitle>

                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description" style={{ display: "flex", flexDirection: "column" }}>
                        <TextField
                            label="Enter Budget Value"
                            type="number"
                            value={budgetValue}
                            style={{ marginBottom: 10 }}
                            name="budgetValue"
                            onChange={(e) => setBudgetValue(e.target.value)}
                            variant="outlined" />
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => setOpenBudgetModal(false)} variant="contained" color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={saveBudget} variant="contained" color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default connect(null, { setLoading, setSnackInfo })(MarketingBudgetPage)
