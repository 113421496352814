import React, { useState, useEffect } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import HttpService from '../services/httpService';
import { connect } from 'react-redux';
import { setLoading, setSnackInfo } from '../redux/actions/appAction';
import moment from 'moment';
import { TextField } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 850,
  },
  textField: {
    width: 100
  }
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#ccc",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);


const ApprovePaymentRequestView = (props) => {
  const classes = useStyles();
  const [paymentRequests, setPaymentRequests] = useState(props.PAYMENTREQUESTS)
  const [totalValues, setTotalValues] = useState({
    total: 0,
    paymentDoneTotal: 0,
    raisedAmountTotal: 0,
    amountDueTotal: 0,
  })
  useEffect(() => {
    setPaymentRequests(props.PAYMENTREQUESTS)
    setTotal()
    console.log("props.PAYMENTREQUESTS",props.PAYMENTREQUESTS);
    // eslint-disable-next-line 
  }, [props.PAYMENTREQUESTS])

  const setTotal = () => {
    let total = 0, paymentDoneTotal = 0, raisedAmountTotal = 0, amountDueTotal = 0
    if(paymentRequests.length > 0){
      total = paymentRequests.reduce((acc, crr) => ({ total: +acc.total + +crr.total })).total
      paymentDoneTotal = paymentRequests.reduce((acc, crr) => ({ paymentDone: +acc.paymentDone + +crr.paymentDone })).paymentDone
      raisedAmountTotal = paymentRequests.reduce((acc, crr) => ({ raisedAmount: +acc.raisedAmount + +crr.raisedAmount })).raisedAmount
      amountDueTotal = +total - (+paymentDoneTotal + +raisedAmountTotal)
      setTotalValues({ ...totalValues, total, paymentDoneTotal, raisedAmountTotal, amountDueTotal })
    }
  }

  const editPayementRequest = async (status, payment) => {
    props.setLoading(true)
    try {
      if (props.RADIOVALUE === "SO") {
        const response = await HttpService.editServiceOrderPaymentStatus(payment?._id, { status, amount: payment.amount })
        if (response.status === 200) {
          // getPaymentRequestForSo()
          props.REFRESH()
          props.setSnackInfo(`Payment ${status}`, "success")
        }
      } else {
        const response = await HttpService.editProcurementOrderPaymentStatus(payment?._id, { status, amount: payment.amount })
        if (response.status === 200) {
          props.REFRESH()
          props.setSnackInfo(`Payment ${status}`, "success")
        }
      }
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data ?? "Something went Wrong", "error")
    }
    props.setLoading(false)
  }

  const changeRaisedAmount = (e, i, j) => {
    // console.log('triggered changeRaisedAmount')
    // console.log('paymentRequests[i] is ',paymentRequests[i])
    const remainingSoAmount = +paymentRequests[i].total - +paymentRequests[i].statusApprovedAmount
    if(remainingSoAmount >= +e.target.value)
    {
    let payments = [...paymentRequests]
    payments[i].paymentRequests[j].amount = e.target.value
    setPaymentRequests(payments)
    }
    else{
      alert(`Remaining SO Amount limit is ${remainingSoAmount}`)
    }
  }

  return (
    <div>
      {paymentRequests?.length > 0 ? <TableContainer TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Sl No</StyledTableCell>
              <StyledTableCell>Order Id</StyledTableCell>
              <StyledTableCell>Client & Code</StyledTableCell>
              <StyledTableCell>Vendor Name</StyledTableCell>
              <StyledTableCell>Vendor Category</StyledTableCell>
              <StyledTableCell>{`${props.RADIOVALUE} Total`}</StyledTableCell>
              <StyledTableCell>Payment Done</StyledTableCell>
              <StyledTableCell>Raised Amount</StyledTableCell>
              <StyledTableCell>Payment (%)</StyledTableCell>
              <StyledTableCell>Amount Due</StyledTableCell>
              <StyledTableCell>Created At</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Approved</StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paymentRequests?.map((data, i) => <StyledTableRow key={i + 1}>
              <StyledTableCell>{i + 1}</StyledTableCell>
              <StyledTableCell>{data?.orderId}</StyledTableCell>
              <StyledTableCell>{`${data?.projectId?.clientName ?? 'OPEN'} ${data?.projectId?.code ?? 'PROCUREMENT'}`}</StyledTableCell>
              <StyledTableCell>{data?.vendorName || data?.vendorId?.name || data?.materials[0]?.vendorId?.name}</StyledTableCell>
              <StyledTableCell>NA</StyledTableCell>
              <StyledTableCell>{data?.total}</StyledTableCell>
              <StyledTableCell>{`${+data?.paymentDone} (${((+data?.paymentDone / +data?.total) * 100).toFixed(2)}%)`}</StyledTableCell>
              <StyledTableCell>{+data?.raisedAmount}</StyledTableCell>
              <StyledTableCell>{(((+data?.paymentDone + +data?.raisedAmount) / +data?.total) * 100).toFixed(2)}%</StyledTableCell>
              <StyledTableCell>{(+data?.total - (+data?.paymentDone + +data?.raisedAmount)).toFixed(2)}</StyledTableCell>
              <StyledTableCell>{moment(data?.createdAt).format('DD-MM-YYYY')}</StyledTableCell>
              <StyledTableCell>{data?.status}</StyledTableCell>
              <StyledTableCell>Approved by Finance/Vendor Manager</StyledTableCell>
              <TableRow>
                <StyledTableCell>Amount</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
              {data?.paymentRequests?.map((request, j) => <StyledTableRow>
                <StyledTableCell>
                  <StyledTableRow>
                    <StyledTableCell>
                      <TextField value={request?.amount} onChange={(e) => changeRaisedAmount(e, i, j)} variant="outlined" className={classes.textField} />
                    </StyledTableCell>
                    <StyledTableCell>
                      {`(${((+request?.amount / +data?.total) * 100).toFixed(2)}%)`}
                    </StyledTableCell>
                  </StyledTableRow>
                </StyledTableCell>
                <StyledTableCell><div className="approve-reject-btn">
                  <button onClick={() => editPayementRequest("Approved", request)} className='approve-btn'>Approve</button>
                  <button onClick={() => editPayementRequest("Rejected", request)} className='reject-btn'>Reject</button>
                </div></StyledTableCell>
              </StyledTableRow>)}
            </StyledTableRow>)}
            <StyledTableRow key={'total'}>
              <StyledTableCell>Total</StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell>{totalValues.total}</StyledTableCell>
              <StyledTableCell>{totalValues.paymentDoneTotal}</StyledTableCell>
              <StyledTableCell>{totalValues.raisedAmountTotal}</StyledTableCell>
              <StyledTableCell>{(((+totalValues.paymentDoneTotal + +totalValues.raisedAmountTotal) / +totalValues.total) * 100).toFixed(2)}%</StyledTableCell>
              <StyledTableCell>{totalValues.amountDueTotal.toFixed(2)}</StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell>{totalValues.raisedAmountTotal}</StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer> : <div style={{ display: 'flex', justifyContent: "center" }}> No Payment Request Raised</div>
      }
    </div >
  )
}

export default connect(null, { setLoading, setSnackInfo })(ApprovePaymentRequestView)
