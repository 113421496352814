import React, { useState, useEffect, useCallback, useRef } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { setLoading, setSnackInfo } from "../redux/actions/appAction";
import HttpService from "../services/httpService";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import { useSelector } from 'react-redux';
import { Typography, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Select,
} from "@material-ui/core";
import moment from "moment";
import TablePagination from "@material-ui/core/TablePagination";
import { Button, Tooltip } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { TextField } from "@material-ui/core";
import { CSVLink } from "react-csv";
import * as XLSX from 'xlsx';
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import EditIcon from "@material-ui/icons/Edit";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import { commaSeparateAmount } from './commaSeparate';
import { ConsoleSqlOutlined } from "@ant-design/icons";
import { PlusCircleOutlined, CheckCircleOutlined, StopOutlined, DownloadOutlined } from '@ant-design/icons'
import { paymentMode, paymentModeType } from "../utils";


const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 850,
  },
  tableHeader:{
    position: 'sticky',
    top: 0,
    zIndex: 1
  },
  textField: {
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(5),
    width: 300,
  },
  paper: {
    padding: "2rem 2rem 2rem 2rem",
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  radioButton: {
    // padding:20,
    marginLeft: "20px",
  },
  heading: { margin: 'auto', textAlign: "center", color: "white", backgroundColor: "#2C2E3E", padding: 10, borderRadius: 50, width: "50%", fontSize: 20, marginTop: -20},
  editButton: { backgroundColor: 'white', color: '#3d444f', padding: '1', marginRight: '0.5em', borderRadius: '20px', "&:hover": { backgroundColor: "#3d444f", color: 'white' } },
  approveButton: { backgroundColor: 'white', color: '#157525', padding: '1', marginRight: '0.5em', marginLeft: '0.5em', borderRadius: '20px', "&:hover": { backgroundColor: "#157525", color: 'white' } },
  rejectButton: { backgroundColor: 'white', color: '#9c261e', padding: '1', marginRight: '0.5em', borderRadius: '20px', "&:hover": { backgroundColor: "#9c261e", color: 'white' } },
  yesButton: { backgroundColor: 'white', color: '#14419c', padding: '1', marginRight: '0.5em', marginLeft: '0.5em', borderRadius: '20px', "&:hover": { backgroundColor: "#14419c", color: 'white' } },
  noButton: { backgroundColor: 'white', color: '#151921', padding: '1', marginRight: '0.5em', borderRadius: '20px', "&:hover": { backgroundColor: "#151921", color: 'white' } },
  updateButton: { backgroundColor: 'white', color: '#157525', padding: '1', margin: 'auto', borderRadius: '20px', "&:hover": { backgroundColor: "#157525", color: 'white' } },
  financeActionRemarks: { margin: 'auto', width: '100%' },
  downloadButton: { backgroundColor: 'white', color: '#14419c', padding: '1', marginRight: '0.5em',  marginBottom: '0.5em', marginTop: '0.5em',  marginLeft: '0.5em', borderRadius: '20px', "&:hover": { backgroundColor: "#14419c", color: 'white' } },
  searchInput: {
    borderRadius: '50px', // Adjust the border-radius for rounded corners
    borderColor: '#2196F3', // Specify the border color
  }
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#ccc",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const CustomerInformation = (props) =>
{
  // let Amount_Received = ['Approved']
  // let Amount_Requested = ['Sent For Finance Approval']
  const { userDetails } = useSelector(state => state.user)
  const [paymentLogsData, setPaymentLogsData] = useState(
    [
      // { date: '2023-09-25', value: 100 },
      // { date: '2023-09-26', value: 150 },
      // { date: '2023-09-27', value: 200 }
    ]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);//limit
    const [totalCount, setTotalCount] = useState(0);

    const [customersInformation, setCustomersInformation] = useState([])
    const [showPaymentLogs, setShowPaymentLogs] = useState(false)
    const [radioValue, setRadioValue] = useState("all")
    // const [startDate, setStartDate] = useState(moment().subtract(1, 'months').format('YYYY-MM-DD'))
    // const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'))
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
  
  const classes = useStyles();
  const buttonRef = useRef(null);

  const getFilteredData = async () => {
    // console.log('getFilteredData function called')
    // console.log('startDate', startDate, 'endDate', endDate)
    // console.log('radioValue', radioValue)
    props.setLoading(true)

    let obj = {}
    obj.startDate = startDate
    obj.endDate = endDate
    obj.radioValue = radioValue
    obj.searchInput = searchInput
    obj.selectedSearchType = selectedSearchType

    await getCustomersInformation({})

    // let result = await HttpService.getApprovedPaymentTransactionsFiltered(obj)
    // console.log('result', result.data)
    // setCustomersInformation(result.data)
    // if (startDate === endDate) {
    //     obj.equalDate = startDate;
    //     obj.flag = true
    // } else {
    //     obj.dateBetween = [startDate, endDate];
    //     obj.flag = false
    // }
    // try {
    //     const result = await HttpService.reminderLogs(obj)
    //     setReminderNotes(result.data);
    // } catch (error) {
    //     console.error(error)
    //     setReminderNotes([])
    //     props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
    // }
    props.setLoading(false)
}

  const handleRadioChange = async (e) => {

    try
    {
      // console.log('handleRadioChange called', e.target.value)
      setRadioValue(e.target.value)
      // console.log('customersInformation', customersInformation)
    }
    catch(error)
    {
      console.log('error', error.message)
      console.error(error)
    }
}

  const handleViewLogsClick = async (data) =>
  {
    props.setLoading(true)
    try
    {
      // console.log('handleViewLogsClick called')
      // console.log('data', data)
      const response = await HttpService.getPaymentLogsOfLead({leadId: data.leadId})
      // console.log('paymentLogs response', response.data)
      setPaymentLogsData(response.data)
      setShowPaymentLogs(true)
    }
    catch(error)
    {
      console.log('error', error.message)
      console.error(error)
    }
    props.setLoading(false)
  }

  const searchPayments = async () =>
  {
    props.setLoading(true);
    let tempData = [...customersInformation]

    let obj = {}
      obj.startDate = startDate
      obj.endDate = endDate
      obj.searchInput = searchInput
      obj.selectedSearchType = selectedSearchType
      
      let result = await HttpService.getApprovedPaymentTransactionsFilteredBySearch(obj)
      setCustomersInformation(result.data)    
      setTotalCount(result.data.length);
    props.setLoading(false);
  
      // let result = await HttpService.getApprovedPaymentTransactions()
    // let pattern = new RegExp( searchInput, 'i');
    
    // if(selectedSearchType.name == 'Customer Name')
    // {
    //   console.log('in Customer Name')
    //   tempData = tempData.filter(el=> pattern.test(el.customer_name))
    // }
    // else if(selectedSearchType.name == 'Lead No')
    // {
    //   console.log('in Lead No')
    //   tempData = tempData.filter(el=> pattern.test(el.lead_no.toString()))
    // }

    // console.log('tempData', tempData)
    // setCustomersInformation(tempData)
  }
  
  
  useEffect(() => {
    props.setLoading(true)
    getCustomersInformation({})
    props.setLoading(false)
    // getPaymentAllPreview();
    // getDepartments();
    // paymentMethod();
    // getSalesDesginUserDetail()
    // stages();
  // },[getPaymentAllPreview, searchStageWise]);
},[]);
  
// const getCustomersInformation = () =>
// {
//   let data = []

//   let obj_1 = {}

//   obj_1.account_number = 'account_number_1'
//   obj_1.amount_requested = 100
//   obj_1.amount_received_auto_ping = 'amount_received_auto_ping_1'
//   obj_1.amount_received_manual_ping = 'amount_received_manual_ping_1'
//   obj_1.total_amount_received = 90

//   let obj_2 = {}

//   obj_2.account_number = 'account_number_2'
//   obj_2.amount_requested = 50
//   obj_2.amount_received_auto_ping = 'amount_received_auto_ping_2'
//   obj_2.amount_received_manual_ping = 'amount_received_manual_ping_2'
//   obj_2.total_amount_received = 70

//   let obj_3 = {}

//   obj_3.account_number = 'account_number_3'
//   obj_3.amount_requested = 150
//   obj_3.amount_received_auto_ping = 'amount_received_auto_ping_3'
//   obj_3.amount_received_manual_ping = 'amount_received_manual_ping_3'
//   obj_3.total_amount_received = 100

//   data.push(obj_1)
//   data.push(obj_2)
//   data.push(obj_3)

//   setCustomersInformation(data)
// }

  // console.log(rowsPerPage+"this is"+totalPages+"this is length")

  // const getCustomersInformation = async () =>
  // {
  //   try
  //   {
  //     let temp_data = []

  //     let obj = {}
  //     obj.startDate = startDate
  //     obj.endDate = endDate
  //     obj.radioValue = radioValue

  //     console.log('obj', obj)

  //     let result = await HttpService.getApprovedPaymentTransactionsFiltered(obj)

  //     // let result = await HttpService.getApprovedPaymentTransactions()

  //     console.log('result', result.data)

  //     let data = result.data

  //     let cust_id_data = {}

  //     for(let i = 0; i < data.length; i++)
  //     {
  //       let obj = {}
  //       if(data[i]['leadId'])
  //       {
  //         if(!cust_id_data[`${data[i].leadId.uniqueLeadNumber}`])
  //         {
  //           cust_id_data[`${data[i].leadId.uniqueLeadNumber}`] = {}
  //         }

  //         cust_id_data[`${data[i].leadId.uniqueLeadNumber}`].leadId = data[i].leadId._id
  //         cust_id_data[`${data[i].leadId.uniqueLeadNumber}`].amount_requested = (cust_id_data[`${data[i].leadId.uniqueLeadNumber}`].amount_requested + data[i].Amount_Requested || data[i].Amount_Requested)
  //         if(data[i].selectedPaymentModeType == "Automatic")
  //         {
  //           cust_id_data[`${data[i].leadId.uniqueLeadNumber}`].amount_received_auto_ping = (cust_id_data[`${data[i].leadId.uniqueLeadNumber}`].amount_received_auto_ping + data[i].Amount_Received || data[i].Amount_Received)
  //         }
  //         else
  //         {
  //           cust_id_data[`${data[i].leadId.uniqueLeadNumber}`].amount_received_manual_ping = (cust_id_data[`${data[i].leadId.uniqueLeadNumber}`].amount_received_manual_ping + data[i].Amount_Received || data[i].Amount_Received)
  //         }
  //       }
        
  //     }

  //     console.log('cust_id_data', cust_id_data)

  //     let keys = Object.keys(cust_id_data)

  //     console.log('keys', keys)

  //     for (let val of keys)
  //     {
  //       console.log('val', val)
  //       console.log('data', cust_id_data[`${val}`])

  //       let obj = {}
  //       obj.leadId = cust_id_data[`${val}`][`leadId`]
  //       obj.account_number = val
  //       obj['amount_requested'] = cust_id_data[`${val}`][`amount_requested`] || 0
  //       obj['amount_received_auto_ping'] = cust_id_data[`${val}`][`amount_received_auto_ping`] || 0
  //       obj['amount_received_manual_ping'] = cust_id_data[`${val}`][`amount_received_manual_ping`] || 0

  //       temp_data.push(obj)
  //     }

  //     // temp_data = [...Object.entries(cust_id_data)]

  //     console.log('temp_data before', temp_data)

  //     console.log('radioValue', radioValue)

  //     if(radioValue == 'positive')
  //     {
  //       temp_data = temp_data.filter(el => (el.amount_requested - (el.amount_received_auto_ping + el.amount_received_manual_ping)) >= 0)
  //     }
  //     else if(radioValue == 'negative')
  //     {
  //       temp_data = temp_data.filter(el => (el.amount_requested - (el.amount_received_auto_ping + el.amount_received_manual_ping)) < 0)
  //     }
  //     else
  //     {
        
  //     }

  //     console.log('temp_data after', temp_data)


  //     setCustomersInformation(temp_data)
  //   }
  //   catch(error)
  //   {
  //     console.log('error', error.message)
  //     console.error(error)
  //   }
  // }

  const getCustomersInformation = async (input) =>
  {
    props.setLoading(true)
    try
    {
      // console.log('getCustomersInformation function called')
      let temp_data = []

      let obj = {}
      obj.startDate = !input.clearFilter ? startDate : null
      obj.endDate = !input.clearFilter ? endDate : null
      obj.radioValue = radioValue
      obj.searchInput = !input.clearFilter ? searchInput : null
      obj.selectedSearchType = !input.clearFilter ? selectedSearchType : null

      let result = await HttpService.getApprovedPaymentTransactionsFiltered(obj)
      let data = result.data

      let cust_id_data = {}
      
      for(let i = 0; i < data.length; i++)
      {
        let obj = {}
        if(data[i]['leadId'])
        {
          if(!cust_id_data[`${data[i].leadId.uniqueLeadNumber}`])
          {
            cust_id_data[`${data[i].leadId.uniqueLeadNumber}`] = {}
          }

          cust_id_data[`${data[i].leadId.uniqueLeadNumber}`].leadId = data[i].leadId._id
          cust_id_data[`${data[i].leadId.uniqueLeadNumber}`].lead_no = data[i].leadId.lead_no
          cust_id_data[`${data[i].leadId.uniqueLeadNumber}`].erpProjectNo = data[i].leadId.erpProjectNo
          cust_id_data[`${data[i].leadId.uniqueLeadNumber}`].customer_name = data[i]?.leadId?.customerId?.name
          cust_id_data[`${data[i].leadId.uniqueLeadNumber}`].amount_requested = (cust_id_data[`${data[i].leadId.uniqueLeadNumber}`].amount_requested + data[i].Amount_Requested || data[i].Amount_Requested)
          if (data[i].selectedPaymentModeType === paymentModeType.automatic && data[i].Status === "Payment Received") 
          {
            cust_id_data[`${data[i].leadId.uniqueLeadNumber}`].amount_received_auto_ping = (cust_id_data[`${data[i].leadId.uniqueLeadNumber}`].amount_received_auto_ping + data[i].Amount_Received || data[i].Amount_Received)
          }else if(data[i].selectedPaymentModeType === paymentModeType.automatic && data[i].financeActionStatus === "Approved" &&  data[i].selectedPaymentMode === paymentMode.cashFree){
            cust_id_data[`${data[i].leadId.uniqueLeadNumber}`].amount_received_auto_ping = (cust_id_data[`${data[i].leadId.uniqueLeadNumber}`].amount_received_auto_ping + data[i].Amount_Received || data[i].Amount_Received)
          }
          else if(data[i].selectedPaymentModeType === paymentModeType.manual) 
          {
            cust_id_data[`${data[i].leadId.uniqueLeadNumber}`].amount_received_manual_ping = (cust_id_data[`${data[i].leadId.uniqueLeadNumber}`].amount_received_manual_ping + data[i].Amount_Received || data[i].Amount_Received)
          }
        }
        
      }
      let keys = Object.keys(cust_id_data)

      for (let val of keys)
      {

        let obj = {}
        obj.leadId = cust_id_data[`${val}`][`leadId`]
        obj.lead_no = cust_id_data[`${val}`][`lead_no`]
        obj.erpProjectNo = cust_id_data[`${val}`][`erpProjectNo`]
        obj.customer_name = cust_id_data[`${val}`][`customer_name`]
        obj.account_number = val
        obj['amount_requested'] = cust_id_data[`${val}`][`amount_requested`] || 0
        obj['amount_received_auto_ping'] = cust_id_data[`${val}`][`amount_received_auto_ping`] || 0
        obj['amount_received_manual_ping'] = cust_id_data[`${val}`][`amount_received_manual_ping`] || 0

        temp_data.push(obj)
      }

      // temp_data = [...Object.entries(cust_id_data)]

      if(radioValue == 'positive')
      {
        temp_data = temp_data.filter(el => (el.amount_requested - (el.amount_received_auto_ping + el.amount_received_manual_ping)) >= 0)
      }
      else if(radioValue == 'negative')
      {
        temp_data = temp_data.filter(el => (el.amount_requested - (el.amount_received_auto_ping + el.amount_received_manual_ping)) < 0)
      }

        setTotalCount(temp_data.length)
        setCustomersInformation(temp_data)
        }
    catch(error)
    {
      console.log('error', error.message)
      console.error(error)
    }
    props.setLoading(false)
  }
  const [searchInput, setSearchInput] = useState("")
  const [searched, setSearched] = useState("")

  const  setHandleSearchedByEnter  = (e) =>
  {
    if(e.keyCode === 13)
    {
   setSearched(searchInput)
   requestSearch(searchInput)
    }  
  }

  const requestSearch = (inputValue) =>
  {
    // console.log('requestSearch function called')
    // console.log('inputValue', inputValue)
  }

  const handleSearch = () =>
  {
    // console.log('handleSearch function called')
  }

  const [searchTypes,setSearchTypes] = useState([{name: 'Customer Name'}, {name: 'Lead No'}, {name: 'Project No'}, {name: 'Payment Mode'}, {name: 'Unique Lead No'}]);
  const [selectedSearchType, setSelectedSearchType] = useState([]);
  // const [clearFilterStatus, setClearFilterStatus] = useState(false)
  const clearFilter = () =>
  {
    // console.log('clearFilter', startDate, endDate)
    setStartDate('')
    setEndDate('')
    setSelectedSearchType([])
    setSearchInput("")
    // setClearFilterStatus(true)
    getCustomersInformation({clearFilter: true})
    
  }

  const handle_search_type_selection = (value) =>
  {
    // console.log('value', value)
    // console.log('searchTypes', searchTypes)
    // console.log('selectedSearchType', selectedSearchType)

    // selectedPaymentMode
    
    setSelectedSearchType(value)
    setSearchInput('')
  }

  const [csvData, setCsvData] = useState([]);
  const headers =
  [
    { key: "vendorUniqueNo", label: "Vendor Unique Code" },
    { key: "poSoNumber", label: "Po/So Number" },
    { key: "poSoDate", label: "Po/So Date" },
    { key: "materialName", label: "Material Name" },
    { key: "pricePerUnit", label: "Rate" },
    { key: "quantity", label: "Qty" },
    { key: "requestedQuantity", label: "Requested Qty" },
    { key: "itemTotal", label: "Item Total Value" },
    { key: "transportationCharge", label: "Transportation Charge" },
    { key: "gstRate", label: "GST Rate" },
    { key: "discount", label: "Discount" },
    { key: "freight", label: "Fright Charge" },
    { key: "poVal", label: "PO Value" },

  ];

  const downloadExcel = () =>
  {
    props.setLoading(true)
    try
    {
      setTimeout(() => {
        buttonRef.current.link.click();
      });
    }
    catch (err)
    {
      console.error(err.message);
    }
    props.setLoading(false)
  }
  const handleGetCsvClick = async () =>
  {
    props.setLoading(true)
    try
    {
      props.setSnackInfo('handleGetCsvClick clicked', 'success')
      // let obj = {}
      // obj.startDate = startDate
      // obj.endDate = endDate
      // obj.radioValue = radioValue
      // obj.searchInput = searchInput
      // obj.selectedSearchType = selectedSearchType
      // console.log('obj in handleGetCsvClick', obj)
      let lead_ids = []
      const wb = XLSX.utils.book_new();
      let customerPaymentsInfoData = [], allPaymentLogsData = []

      for(let i = 0; i < customersInformation.length; i++)
      {
        let obj = {}
        obj.account_number = customersInformation[i]['account_number']
        obj.erpProjectNo = customersInformation[i]['erpProjectNo']
        obj.customer_name = customersInformation[i]['customer_name']
        obj.lead_no = customersInformation[i]['lead_no']
        obj.amount_requested = customersInformation[i]['amount_requested']
        obj.amount_received_auto_ping = customersInformation[i]['amount_received_auto_ping']
        obj.amount_received_manual_ping = customersInformation[i]['amount_received_manual_ping']
        obj.total_amount_received = customersInformation[i]['amount_received_auto_ping'] + customersInformation[i]['amount_received_manual_ping']
        
        customerPaymentsInfoData.push(obj)
        lead_ids.push(customersInformation[i]['leadId'])
      }
      let response = await HttpService.getPaymentLogsOfMultipleLeads(lead_ids)
      let allPaymentLogs = response.data
      for(let k = 0; k < allPaymentLogs.length; k++)
      {
        let obj = {}
        obj.uniqueLeadNumber = allPaymentLogs[k]?.leadId.uniqueLeadNumber
        obj.refId = allPaymentLogs[k].paymentTransactionId?.trxId || allPaymentLogs[k].paymentTransactionId?.cheQueNo || allPaymentLogs[k].paymentTransactionId?.slip_no || 'NA'
        obj.amount_received_or_requested = allPaymentLogs[k]?.Status
        obj.reqested_by_or_received_mode = allPaymentLogs[k]?.Status == 'Payment Requested' ? allPaymentLogs[k]?.PaymentUser?.name : allPaymentLogs[k]?.paymentMode
        obj.amount = allPaymentLogs[k]?.amount
        obj.date = moment(allPaymentLogs[k]?.createdAt).format('DD-MM-YYYY')
        obj.paymentDate = moment(allPaymentLogs[k]?.paymentDate).format('DD-MM-YYYY')
        allPaymentLogsData.push(obj)
      }

      // Sheet 1
      const ws1 = XLSX.utils.json_to_sheet(customerPaymentsInfoData);
      XLSX.utils.book_append_sheet(wb, ws1, 'Customer Payments Info');

      // Sheet 2
      const ws2 = XLSX.utils.json_to_sheet(allPaymentLogsData);
      XLSX.utils.book_append_sheet(wb, ws2, 'Payment Logs');

      // Save the workbook as a file
      XLSX.writeFile(wb, 'multi-sheet-file.xlsx');
      // let response = await HttpService.getCsvFileForCustomerInfo(obj)
      // console.log('response in handleGetCsvClick', response.data)
      // downloadExcel()
    }
    catch(error)
    {
      console.log('error', error.message)
      console.error(error)
    }
    props.setLoading(false)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
};
const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
};
    return (
    <div>
      <h2 className={classes.heading}>Customer Payments Information</h2>
      
      <br />
      <Button disabled={false} className={classes.approveButton} variant="contained" style={{ alignSelf: "center", marginBottom: 20 }} onClick={handleGetCsvClick} >
            Get CSV File
      </Button>
          <CSVLink
                    headers={headers}
                    filename={"vendorsExcel" + '.csv'}
                    data={csvData}
                    ref={buttonRef}
                  />

      <Paper style={{ textAlign: 'center', marginBottom: 20, display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '50%', margin: 'Auto' }}>
          <TextField
            select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            // disabled={hidePaymentModeInput}
            value={selectedSearchType}
            label="Search Type"
            name="searchType"
            // onChange={(e) => setSelectedPaymentMode(e.target.value)}
            onChange={(e) => handle_search_type_selection(e.target.value)}
            variant="outlined"
            // margin="normal"
            style={{width: '30%', marginRight: '2%'}}
            size="small"
            >

            {searchTypes.map((el) =>(
              <MenuItem value={el}>{el.name}</MenuItem>
            ))}
            
          </TextField>

          <TextField label={`Search payments by input`} name="searched" size="small" value={searchInput} onKeyDown={setHandleSearchedByEnter} onChange={(e) => {setSearchInput(e.target.value)}} variant="outlined" style={{width: '80%'}} />

          <Button disabled={!selectedSearchType.name || !searchInput} className={classes.noButton} variant="contained" style={{ alignSelf: "center", marginLeft: 20 }} onClick={searchPayments} >
                Search
          </Button>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1%', marginBottom: '1%' }}>
          <TextField
              id="date"
              name="startDate"
              label="From Date"
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              InputLabelProps={{
                  shrink: true,
              }}
              fullWidth
              className={classes.textField}
              style={{ width: '30%' }}
          />
          <TextField
              id="date"
              name="endDate"
              label="To Date"
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              InputLabelProps={{
                  shrink: true,
              }}
              fullWidth
              className={classes.textField}
              style={{ width: '30%' }}
          />

          <Button className={classes.yesButton} variant="contained" style={{ alignSelf: "center", marginLeft: 20 }} onClick={getFilteredData} >
                Submit
          </Button>

          <Button className={classes.rejectButton} variant="contained" style={{ alignSelf: "center", marginLeft: 20 }} onClick={()=>clearFilter()} >
                Clear
          </Button>
          
        </div>
        {/* <Typography variant="h6" gutterBottom style={{ paddingTop: 10, fontWeight: 700 }}>
            Balance
        </Typography> */}

        {/* <FormControl component="fieldset" className={classes.root} style={{marginRight: 20}}>
            <RadioGroup aria-label="type" name="type" value={radioValue} onChange={handleRadioChange}>
                <div className={classes.radioButton}>
                    <FormControlLabel value="all" control={<Radio />} label="All" />
                    <FormControlLabel value="positive" control={<Radio />} label="Positive" />
                    <FormControlLabel value="negative" control={<Radio />} label="Negative" />
                </div>
            </RadioGroup>
        </FormControl> */}

    </Paper>
      <TableContainer component={Paper}>
        <Table className="table table-responsive table-bordered" aria-label="sticky table">
        <TableHead className={classes.tableHeader}>
        <StyledTableRow>
         <TablePagination
              rowsPerPageOptions={[50, 100, 500, 5000, { label: "All", value: totalCount }]}
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
          />
            </StyledTableRow>
            <StyledTableRow>
              <TableCell style={{ fontWeight: "800", textAlign:"center" }}>S.No</TableCell>
              <TableCell style={{ fontWeight: "800", textAlign:"center" }}>Account Number</TableCell>
              <TableCell style={{ fontWeight: "800", textAlign:"center" }}>Project No</TableCell>
              <TableCell style={{ fontWeight: "800", textAlign:"center" }}>Amount Requested</TableCell>
              <TableCell style={{ fontWeight: "800", textAlign:"center" }}>Amount Received Automated Ping</TableCell>
              <TableCell style={{ fontWeight: "800", textAlign:"center" }}>Amount Received Manual Approved</TableCell>
              <TableCell style={{ fontWeight: "800", textAlign:"center" }}>Total Amount Received</TableCell>
              <TableCell style={{ fontWeight: "800", textAlign:"center" }}>Requested amount -  Total Amount Received</TableCell>
            </StyledTableRow>
            </TableHead>
            <TableBody>
            {customersInformation?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
              return (
                <TableRow key={i + 1}>

                  <TableCell style={{textAlign:"center" }}>{i + 1}</TableCell>
                  {/* <TableCell style={{textAlign:"center" }}>{row.account_number} <PlusCircleOutlined onClick={() => handleViewLogsClick(row)} /></TableCell> */}
                  <TableCell style={{textAlign:"center" }} component="th" scope="row">
                    <Link to={`/project-logs/${row.leadId}`} style={{ color: "#000", textDecoration: 'underline' }}>{row?.account_number}</Link> <PlusCircleOutlined onClick={() => handleViewLogsClick(row)} />
                  </TableCell>
                  <TableCell style={{textAlign:"center" }}>
                    <Link to={`/project-logs/${row?.leadId}`} style={{ color: "#000", textDecoration: 'underline' }}>{row?.erpProjectNo || "N/A"}</Link>
                  </TableCell>
                  <TableCell style={{textAlign:"center" }}>{row.amount_requested.toFixed(2)}</TableCell>
                  <TableCell style={{textAlign:"center" }}>{row.amount_received_auto_ping.toFixed(2)}</TableCell>
                  <TableCell style={{textAlign:"center" }}>{row.amount_received_manual_ping.toFixed(2)}</TableCell>
                  <TableCell style={{textAlign:"center" }}>{(row.amount_received_auto_ping + row.amount_received_manual_ping).toFixed(2)}</TableCell>
                  <TableCell style={{textAlign:"center" }}>{(row.amount_requested - (row.amount_received_auto_ping + row.amount_received_manual_ping)).toFixed(2)}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={showPaymentLogs} onClose={() => {setShowPaymentLogs(false); setPaymentLogsData([])}} aria-labelledby="form-dialog-title" fullWidth maxWidth='lg' style={{padding:"10rem 10rem 10rem 10rem"}}>
                <DialogTitle id="form-dialog-title" style={{ textAlign: "center" }}>
                    Payment Logs
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Table className={classes.table} size="small" aria-label="customized table">
                            <StyledTableRow>
                                <StyledTableCell style={{textAlign:"center" }} component="th" scope="row">S.No.</StyledTableCell>
                                <StyledTableCell style={{textAlign:"center" }} component="th" scope="row">Ref. ID</StyledTableCell>
                                <StyledTableCell style={{textAlign:"center" }} component="th" scope="row">Amount Received / Requested</StyledTableCell>
                                <StyledTableCell style={{textAlign:"center" }} component="th" scope="row">Requested By / Payment Mode</StyledTableCell>
                                <StyledTableCell style={{textAlign:"center" }} component="th" scope="row">Amount</StyledTableCell>
                                <StyledTableCell style={{textAlign:"center" }} component="th" scope="row">Date</StyledTableCell>
                                <StyledTableCell style={{textAlign:"center" }} component="th" scope="row">Payment Requested Date</StyledTableCell>
                                <StyledTableCell style={{textAlign:"center" }} component="th" scope="row">Payment Approved Date</StyledTableCell>
                                <StyledTableCell style={{textAlign:"center" }} component="th" scope="row">Receipts</StyledTableCell>
                                
                          </StyledTableRow>
                          <TableBody>
                            {paymentLogsData.map((row, i) => {
                                return (
                                    <StyledTableRow key={i + 1}>
                                        <StyledTableCell>{i + 1}</StyledTableCell>
                                        <StyledTableCell style={{textAlign:"center" }} component="td" scope="row">{row?.paymentTransactionId?.trxId || row?.paymentTransactionId?.cheQueNo || row?.paymentTransactionId?.slip_no || 'NA'}</StyledTableCell>
                                        <StyledTableCell style={{textAlign:"center" }} component="td" scope="row">{row?.Status}</StyledTableCell>
                                        {/* <StyledTableCell style={{textAlign:"center" }} component="td" scope="row">{Amount_Received.includes(row?.Status) ? 'Amount Received' : 'Amount Requested'}</StyledTableCell> */}
                                        <StyledTableCell style={{textAlign:"center" }} component="td" scope="row">{row?.Status === 'Payment Requested' ? row?.PaymentUser?.name + ' / ' + row?.paymentMode : row?.paymentMode}</StyledTableCell>
                                        <StyledTableCell style={{textAlign:"center" }} component="td" scope="row">{row?.amount}</StyledTableCell>
                                        {/* <StyledTableCell style={{textAlign:"center" }} component="td" scope="row">{moment(row?.createdAt).format('DD-MM-YYYY')}</StyledTableCell> */}
                                        <StyledTableCell style={{textAlign:"center" }} component="td" scope="row">{moment(row?.paymentDate).format('DD-MM-YYYY')}</StyledTableCell>
                                        <StyledTableCell style={{textAlign:"center" }} component="td" scope="row">{ row?.paymentRequestedDate ? moment(row.paymentRequestedDate).format('DD-MM-YYYY') : "N/A" }</StyledTableCell>
                                        <StyledTableCell style={{textAlign:"center" }} component="td" scope="row">{ row?.paymentApprovedDate ? moment(row.paymentApprovedDate).format('DD-MM-YYYY') : "N/A" }</StyledTableCell>
                                        {/* <StyledTableCell component="th" scope="row" >{}<DownloadOutlined  onClick={() => window.open(row?.paymentTransactionId?.attachment[0])} /></StyledTableCell> */}
                                        <StyledTableCell style={{textAlign:"center" }} component="th" scope="row" >
                                          {/* <Button variant="outlined" className={classes.downloadButton} onClick={() => window.open(row?.paymentTransactionId?.attachment[0])}><DownloadOutlined /></Button> */}
                                          {/* <Button variant="outlined" className={classes.downloadButton} onClick={() => window.open(row?.paymentTransactionId?.financeReceipt)}><DownloadOutlined /></Button> */}
                                          {row?.Status == 'Payment Requested' ? 'NA' : <Button variant="outlined" className={classes.downloadButton} onClick={() => window.open(row?.paymentTransactionId?.financeReceipt)}><DownloadOutlined /></Button>}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                );
                            })}
                        </TableBody>
                        </Table>
                    </DialogContentText>
                </DialogContent>
      </Dialog>
    </div>
  );
};

export default connect(null, { setLoading, setSnackInfo })(CustomerInformation);

