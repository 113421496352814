import React, { useState, useEffect } from 'react'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { connect } from 'react-redux'
import { setLoading, setSnackInfo } from '../../../redux/actions/appAction';
import HttpService from '../../../services/httpService';
import moment from 'moment'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import PoSoForApprovalView from '../../../components/poSoForApprovalView'
import PoDetailsModal from '../../../components/poDetailsModal';
import SoDetailsModal from '../../../components/soDetailsModal'
import { Modal } from 'antd';
// import 'antd/dist/antd.css';
import MaterialTransferListView from '../../../components/materialTransferListView'
import MaterialTransferDetailsModal from '../../../components/materialTransferDetaislModal'
import Autocomplete from '@material-ui/lab/Autocomplete';

const ApprovePoSo = (props) => {
  const [radioValue, setRadioValue] = useState("PO")
  const [poSoForApproval, setPoSoForApproval] = useState([])
  const [dupPoSoForApproval, setDupPoSoForApproval] = useState([])
  const [poDetailsModal, setPoDetailsModal] = useState(false)
  const [poDetails, setPoDetails] = useState({})
  const [soDetailsModal, setSoDetailsModal] = useState(false)
  const [soDetails, setSoDetails] = useState(false)
  const [materialTransferList, setMaterialTransferList] = useState([])
  const [vendorList, setVendorList] = useState([])

  useEffect(() => {
    if (radioValue === "PO")
      getProcurementsForApproval()
    else if (radioValue === "SO")
      getServiceOrdersForApproval()
    else
      getMaterialTransferRequestForApproval()
    // eslint-disable-next-line
  }, [radioValue])

  useEffect(() => {
    getAllVendors()
    // eslint-disable-next-line
  }, [])

  const getAllVendors = async () => {
    try {
      const response = await HttpService.getAllVendors()
      setVendorList(response.data)
    } catch (error) {
      console.error(error)
      props.setSnackInfo('Error fetching Vendors', "error")
    }
  }

  const getProcurementsForApproval = async () => {
    props.setLoading(true)
    try {
      const result = await HttpService.getProcurementsForApproval()
      const poForApproval = result.data.map(item => {
        let obj = {
          _id: item?._id,
          orderId: item?.orderId,
          status: item?.status,
          client: item?.projectId?.clientName ?? "PROCUREMENT",
          code: item?.projectId?.code ?? "OPEN",
          vendorName: item?.vendorId?.name,
          total: item?.total,
          createdAt: moment(item?.createdAt).format("DD-MM-YYYY")
        }
        return obj
      })
      setPoSoForApproval(poForApproval)
      setDupPoSoForApproval(poForApproval)
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
    }
    props.setLoading(false)
  }

  const getServiceOrdersForApproval = async () => {
    props.setLoading(true)
    try {
      const result = await HttpService.getServiceOrdersForApproval()
      const soForApproval = result.data.map(item => {
        let obj = {
          _id: item?._id,
          orderId: item?.orderId,
          status: item?.status,
          client: item?.projectId?.clientName,
          code: item?.projectId?.code,
          vendorName: item?.vendorId?.name,
          total: item?.total,
          createdAt: moment(item?.createdAt).format("DD-MM-YYYY")
        }
        return obj
      })
      setPoSoForApproval(soForApproval)
      setDupPoSoForApproval(soForApproval)
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
    }
    props.setLoading(false)
  }

  const getMaterialTransferRequestForApproval = async () => {
    props.setLoading(true)
    try {
      const result = await HttpService.getMaterialTransferRequest()
      setMaterialTransferList(result.data)
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  const handleRadioChange = (e) => {
    setRadioValue(e.target.value)
  }

  const openPoDetailsModal = async (procurementId) => {
    try {
      props.setLoading(true)
      const response = await HttpService.getProcurementDetails(procurementId)
      setPoDetails(response.data)
      setPoDetailsModal(true)
    } catch (error) {
      console.error(error)
      props.setLoading(false)
      props.setSnackInfo('Couldn\'t get raised po details', 'error')
    }
    props.setLoading(false)

  }

  const openSoDetailsModal = async (serviceOrderId) => {
    props.setLoading(true)
    try {
      const response = await HttpService.getServiceOrderDetails(serviceOrderId)
      setSoDetails(response.data)
      setSoDetailsModal(true)
    } catch (error) {
      console.error(error)
      props.setSnackInfo('Couldn\'t get raised so details', 'error')
    }
    props.setLoading(false)
  }
  const [orderId, setOrderId] = useState("")

  const handleChange = async (e) => {
    try {
      setPoSoForApproval(dupPoSoForApproval.filter(item => item.orderId.includes(e.target.value)))
      setOrderId(e.target.value)
    } catch (error) {
      console.error(error)
    }
  }
  const fetchDetails = async () => {
    try {
      if (radioValue === "PO") {
        const procurement = poSoForApproval.filter(po => po.orderId === orderId)[0]
        openPoDetailsModal(procurement._id)
      }
      else {
        openSoDetailsModal(orderId)
      }
    } catch (error) {
      console.error(error)
      props.setSnackInfo('Couldn\'t get raised so details', 'error')
    }
  }

  const [mtrDetailsModal, setMtrDetailsModal] = useState(false)
  const [mtrDetails, setMtrDetails] = useState({})
  const [mounted, setMounted] = useState(false)

  const openMaterialTransferDetail = async (id) => {
    props.setLoading(true)
    try {
      const result = await HttpService.materialTransferDetails(id)
      setMtrDetails(result.data)
      setMtrDetailsModal(true)
      setMounted(!false)
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  const [selectedVendor, setSelectedVendor] = useState({})
  const fetchSelectedVendorOrder = async (vendor) => {
    props.setLoading(true)
    setSelectedVendor(vendor)
    try {
      let result = []
      if (vendor?._id) {
        if (radioValue == "PO") {
          result = await HttpService.getVendorPoById(vendor._id)
          console.log(result, "resilt")
          let poForApproval = []
          if (result.data.length > 0)
            poForApproval = result.data.map(item => {
              let obj = {
                _id: item?._id,
                orderId: item?.orderId,
                status: item?.status,
                client: item?.projectId?.clientName ?? "PROCUREMENT",
                code: item?.projectId?.code ?? "OPEN",
                vendorName: item?.vendorId?.name,
                total: item?.total,
                createdAt: moment(item?.createdAt).format("DD-MM-YYYY")
              }
              return obj
            })
          setPoSoForApproval(poForApproval)
          setDupPoSoForApproval(poForApproval)
        } else {
          result = await HttpService.getVendorSoById(vendor._id)
          let soForApproval = []
          if (result.data.length > 0)
            soForApproval = result.data.map(item => {
              let obj = {
                _id: item?._id,
                orderId: item?.orderId,
                status: item?.status,
                client: item?.projectId?.clientName,
                code: item?.projectId?.code,
                vendorName: item?.vendorId?.name,
                total: item?.total,
                createdAt: moment(item?.createdAt).format("DD-MM-YYYY")
              }
              return obj
            })
          setPoSoForApproval(soForApproval)
          setDupPoSoForApproval(soForApproval)
        }
      } else {
        if (radioValue === "PO") getProcurementsForApproval()
        else getServiceOrdersForApproval()
      }
    } catch (error) {
      console.error(error)
      props.setSnackInfo("Error fetching Vendor Orders", "error")
    }
    props.setLoading(false)
  }
  return (
    <div>
      <div style={{ alignSelf: "center" }}>
        <FormControl component="fieldset" >
          <RadioGroup aria-label="type" name="type" value={radioValue} onChange={handleRadioChange}>
            <div className='modal-header-radio'>
              <FormControlLabel value="PO" control={<Radio />} label="PO" />
              <FormControlLabel value="SO" control={<Radio />} label="SO" />
              <FormControlLabel value="MTR" control={<Radio />} label="MTR" />
            </div>
          </RadioGroup>
        </FormControl>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between", width: "100%", marginBottom: 25 }}>
        <div style={{ display: "flex", justifyContent: "space-between", width: "25%", marginBottom: 25 }}>
          <TextField label={`Search ${radioValue} order id`} onChange={handleChange} name="orderId" variant="outlined" />
          <Button onClick={fetchDetails} variant="contained" color="primary">
            Search
          </Button>
        </div>
        {radioValue !== "MTR" &&
          <div>
            <Autocomplete
              value={selectedVendor}
              onChange={(e, v) => fetchSelectedVendorOrder(v)}
              options={vendorList}
              getOptionLabel={(option) => option.name}
              style={{ width: 350, marginBottom: 10 }}
              renderInput={(params) => <TextField {...params} label="Select Vendor" fullWidth variant="outlined" />}
            />
          </div>
        }
      </div>
      {radioValue !== "MTR" ? <PoSoForApprovalView POSOFORAPPOVAL={poSoForApproval} RADIOVALUE={radioValue} REFRESHDATA={radioValue === "PO" ? getProcurementsForApproval : getServiceOrdersForApproval} OPENDETAILSMODAL={radioValue === "PO" ? openPoDetailsModal : openSoDetailsModal} /> :
        <MaterialTransferListView MATERIALTRANSFERLIST={materialTransferList} OPENDETAILSMODAL={openMaterialTransferDetail} REFERSH={getMaterialTransferRequestForApproval} />}

      <Modal
        title="Approve PO"
        centered
        visible={poDetailsModal}
        onCancel={() => setPoDetailsModal(false)}
        width={1000}
        footer={null}
        maskClosable={false}

      >
        <PoDetailsModal PODETAILS={poDetails} REFRESH={openPoDetailsModal} VIEWALL={() => { setPoDetailsModal(false); getProcurementsForApproval() }} TITLE={'Approve PO'} />
      </Modal>

      <Modal
        title="Approve SO"
        centered
        visible={soDetailsModal}
        onCancel={() => setSoDetailsModal(false)}
        width={1000}
        maskClosable={false}
        footer={null}
      >
        <SoDetailsModal SODETAILS={soDetails} REFRESH={openSoDetailsModal} VIEWALL={() => { setSoDetailsModal(false); getServiceOrdersForApproval() }} TITLE={'Approve SO'} />
      </Modal>

      <Modal
        title="MTR Details"
        centered
        visible={mtrDetailsModal}
        onCancel={() => setMtrDetailsModal(false)}
        width={1000}
        maskClosable={false}
        footer={null}
      >
        <MaterialTransferDetailsModal MTRDETAILS={mtrDetails} MOUNTED={mounted} REFRESH={openMaterialTransferDetail} VIEWALL={() => { setMtrDetailsModal(false); getMaterialTransferRequestForApproval() }} />
      </Modal>

    </div >
  )
}

export default connect(null, { setLoading, setSnackInfo })(ApprovePoSo)
