import React, { useState, useEffect } from 'react';
import { useParams, useHistory, Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { connect } from 'react-redux'
import { setLoading, setSnackInfo } from '../redux/actions/appAction';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import HttpService from '../services/httpService';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MenuItem from '@material-ui/core/MenuItem';
import { FormControl, TextField, DialogTitle, Typography, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { ROLENAME, rolesId } from '../utils';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 700,
    },
    root: {
        width: '100%',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));
const DesignerAssignmentView = (props) => {
    const classes = useStyles();
    const { statusName, startDate, endDate, userId, isUser } = useParams();
    const { userDetails } = useSelector(state => state.user);
    const history = useHistory();
    const [designerAssignment, setDesignerAssignment] = useState([]);
    const [dupDesignerAssignment, setDupDesignerAssignment] = useState([]);
    const [selectedFilterOption, setSelectedFilterOption] = useState();
    const [startDateFilter, setStartDate] = useState()
    const [endDateFilter, setEndDate] = useState();
    // const [selectedFilterOption, setSelectedFilterOption] = useState(props.location.state === null ? 'leadWonDate' : props.location.state.filterOption);
    // const [startDateFilter, setStartDate] = useState(startDate)
    // const [endDateFilter, setEndDate] = useState(endDate);
    useEffect(() => {
        getLeadCountDetails()
        // eslint-disable-next-line
    }, [])

    const handleChangeFilterOption = (event) => {
        setSelectedFilterOption(event.target.value);
    };

    const getLeadCountDetails = async () => {
        let stage = "";
        if (statusName === 'Won-Deals-Pending-Designer-Assignment') {
            stage = 'Won Deals Pending Designer Assignment'
        } else {
            stage = 'Designer Assignment'
        }

        let obj = {
            startDate: startDateFilter,
            endDate: endDateFilter,
            salesStage: stage,
            selectedStage: stage === 'Won Deals Pending Designer Assignment' ? "Won Deal" : 'other stage'
        }
        if (isUser === 'user') {
            obj.assignTo = userId;
            obj.option = 'user'
        } else if (isUser === 'designHead') {
            // obj.assignTo = userId
            obj.option = 'designHead'
            obj.designHeadAssigned = true
            obj.experienceCenterId = userDetails.selectedExpCenter
        } else if (props.location.state.selectedUserId.length !== 0) {
            obj.selectedUserList = props.location.state.selectedUserId;
            obj.option = 'team';
            obj.teamId = userId;
            obj.experienceCenterId = props.location.state.experienceCenterId;
            obj.isUserSelected = 'true';
            obj.filterOption = selectedFilterOption;
        } else {
            obj.option = 'team'
            obj.teamId = userId;
            obj.experienceCenterId = props.location.state.experienceCenterId;
            obj.isUserSelected = 'false'
            obj.filterOption = selectedFilterOption;
        }

        if (userDetails.roles.find(role => role.name === ROLENAME.SalesUser)) {
            obj.experienceCenterId = props.location.state.experienceCenterId
            obj.option = 'sales';
            obj.filterOption = selectedFilterOption;
        }
        props.setLoading(true)
        try {
            const response = await HttpService.getDesignerAssigmentLeadData(obj)
            const leadListData = response.data.map(item => {
                let obj = {
                    leadId: item?._id,
                    lead_no: item?.lead_no,
                    name: item?.customerId?.name,
                    erpProjectNo:item?.erpProjectNo,
                    customerId: item?.customerId?._id,
                    assignTo: item?.assignTo?.name,
                    salesManagerApproved: item?.salesManagerApproved,
                    finanaceManagerApproved: item?.finanaceManagerApproved,
                    centerHeadApproved: item?.centerHeadApproved,
                    businessHeadApproved: item?.businessHeadApproved,
                    customerApproved: item?.customerApproved,
                    salesExecutiveApproved: item?.salesExecutiveApproved,
                    departmentId: item?.departmentId,
                    createdAt: moment(item?.createdAt).format("DD-MM-YYYY"),
                    reinitiateProcess: item?.reinitiateProcess
                }
                return obj
            })
            setDesignerAssignment(leadListData);
            setDupDesignerAssignment(leadListData);
        } catch (error) {
            props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const [openStatusModal, setOpenStatusModal] = useState(false);
    const [selectedLead, setSelectedLead] = useState([]);

    const reinitialProcess = (data) => {
        setSelectedLead(data)
        setOpenStatusModal(true)
    }

    const updateClosureStatus = async (statusAct) => {
        if (statusAct === 'Yes') {
            console.log(selectedLead, "selectedLead", selectedLead.leadId)
            history.push({
                pathname: '/check-lists',
                state: {
                    actionCheck: statusAct,
                    leadId: selectedLead.leadId
                }
            })
        } else if (statusAct === 'No') {
            let obj = {
                salesStage: 'Won Deals Pending Designer Assignment'
            }
            props.setLoading(true)
            try {
                const response = await HttpService.updateLead(selectedLead.leadId, obj)
                props.setSnackInfo(response?.data ?? "Something went wrong", "success")
                setOpenStatusModal(false);
            } catch (error) {
                props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
            }
            props.setLoading(false)
        }
    }
    const [designManagersUser, setDesginManagersUser] = useState([]);
    const [openDesignAssignModal, setOpenDesginerAssignModal] = useState(false);
    const [selectedDesginUser, setSelectedDesginUser] = useState("");
    // const [selectedTeamDept, setSelectedTeamDept] = useState({
    //     teamId: '',
    //     departmentId: ''
    // })
    const [designDepartmentUsers, setDesignDepartmentUsers] = useState([]);
    const assignDesigner = async (data) => {
        console.log(data)
        props.setLoading(true)
        try {
            const response = await HttpService.getDesginManagersUserList(data.departmentId)
            let designManagersUsers = [];
            for (let i = 0; i < response.data.length; i++) {
                if (response.data[i].roles.includes(rolesId.designManager)) {
                    designManagersUsers.push(response.data[i]);
                }
            }
            const selectedUsersOfExpCenter = response.data.filter(ele => ele.experienceCenterId.includes(userDetails.selectedExpCenter))
            setDesignDepartmentUsers(selectedUsersOfExpCenter)
            // setSelectedTeamDept({
            //     teamId: response.data[0].teamId,
            //     departmentId: response.data[0].departmentId
            // });
            const managersOfExpCenter = designManagersUsers.filter(ele => ele.experienceCenterId.includes(userDetails.selectedExpCenter))
            setDesginManagersUser(managersOfExpCenter)
            setSelectedLead(data)
            setOpenDesginerAssignModal(true);
        } catch (error) {
            props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const assignLeadToDesignUser = async () => {
        const selectedManagerData = designDepartmentUsers.find(ele => ele._id === selectedDesginUser);
        let obj = {
            assignTo: selectedDesginUser,
            teamId: selectedManagerData.teamId,
            departmentId: selectedManagerData.departmentId,
            customerId: selectedLead.customerId,
            designerAssigned: true,
            designStages: 'Assign to Designer',
            currentStage: 'Assign to Designer',
            status: 'Lead Received',
            designStatus: "Design"
        }
        console.log(obj, "Obj")
        setOpenDesginerAssignModal(false);
        props.setLoading(true)
        try {
            const response = await HttpService.assignLeadToDesginUser(selectedLead.leadId, obj)
            props.setSnackInfo(response?.data ?? "Something went wrong", "success")
            history.push('/qtProjects');
            setOpenStatusModal(false);
        } catch (error) {
            props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const [openRejectModal, setOpenRejectModal] = useState(false);
    const [rejectRemark, setRejectRemark] = useState([]);
    const showRejectedStatus = (data, data1) => {
        setOpenRejectModal(true);
        Object.keys(data1).forEach(function (key) {
            if (key === data) {
                setRejectRemark(data1[key][0]);
            }
        });
    }

    const getLeadsOnSelectedDateByRole = () => {
        getLeadCountDetails()
    }

    const [searched, setSearched] = useState("");

    const setHandleSearched = (e) => {
        setSearched(e.target.vlue)
        requestSearch(e.target.value)
    }

    const requestSearch = (search) => {
        const filteredRow = dupDesignerAssignment.filter(row =>
            row.name.toLowerCase().includes(search.toLowerCase()) ||
            row.lead_no.toString().includes(search) ||
            row.assignTo.toLowerCase().includes(search.toLowerCase())
        )
        setDesignerAssignment(filteredRow);
    }

    return (
        <div>
            <div style={{ marginBottom: 20 }}>
                <h3 style={{ textAlign: 'center' }}>
                    {statusName.replace(/-/g, ' ')}
                </h3>
            </div>
            <Divider />
            <Paper className={classes.paper}>
                <FormControl component="fieldset" style={{ marginBottom: 20 }}>
                    <FormLabel component="legend">Select Filter Option</FormLabel>
                    <RadioGroup row aria-label="filterOption" name="filterOption1" value={selectedFilterOption} onChange={handleChangeFilterOption}>
                        <FormControlLabel value="leadWonDate" control={<Radio />} label="Lead Won Date" />
                        <FormControlLabel value="createdAt" control={<Radio />} label="Lead Created At" />
                        {/* {userDetails.departmentId === designDepartment && <FormControlLabel value="contractSignedDate" control={<Radio />} label="Contract Sign Date" />} */}
                    </RadioGroup>
                </FormControl>
                <br />
                <TextField
                    id="date"
                    name="startDate"
                    label="From Date"
                    type="date"
                    value={startDateFilter}
                    onChange={(e) => setStartDate(e.target.value)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    fullWidth
                    className={classes.textField}
                />
                <TextField
                    id="date"
                    name="endDate"
                    label="End Date"
                    type="date"
                    value={endDateFilter}
                    onChange={(e) => setEndDate(e.target.value)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    fullWidth
                    className={classes.textField}
                />
                <Button variant="contained" color="primary" disabled={!startDate || !endDate} style={{ alignSelf: "center", marginLeft: 20 }} onClick={getLeadsOnSelectedDateByRole} >
                    Submit
                </Button>
            </Paper>
            <div style={{ marginTop: 15, marginBottom: 15, textAlign: 'right' }}>
                <TextField label="Search here.." name="searched" value={searched} size="small" onChange={setHandleSearched} variant="outlined" style={{ marginRight: 20 }} />
            </div>
            <Divider />

            <div style={{ display: 'flex', marginTop: 10, marginBottom: 20 }}>
                <Paper>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Sr. No.</TableCell>
                                    <TableCell>Lead Name</TableCell>
                                    <TableCell>Lead No</TableCell>
                                    <TableCell>Project No.</TableCell>
                                    <TableCell>Design Head / Sales Executive</TableCell>
                                    <TableCell>Sales Manager Approval</TableCell>
                                    <TableCell>Finanace Approval</TableCell>
                                    <TableCell>Center Head Approval</TableCell>
                                    <TableCell>Bussiness Head Approval</TableCell>
                                    <TableCell>Customer Approval</TableCell>
                                    {
                                        userDetails.roles.find(role => role.name === ROLENAME.SalesUser || role.name === ROLENAME.DesignHead) &&
                                        <TableCell>Action</TableCell>
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {designerAssignment.length !== 0 ?
                                    designerAssignment.sort((a, b) => b.createdAt.split('-').reverse().join().localeCompare(a.createdAt.split('-').reverse().join())).map((row, i) => {
                                        return (
                                            <TableRow key={i + 1}>
                                                <TableCell>{i + 1}</TableCell>
                                                <TableCell component="th" scope="row">
                                                    <Link to={`/project-logs/${row?.leadId}`} style={{ color: "#000", textDecoration: 'underline' }}>{row?.name}</Link>
                                                </TableCell>
                                                <TableCell component="th">{row?.lead_no}</TableCell>
                                                <TableCell component="th">{row?.erpProjectNo}</TableCell>
                                                <TableCell component="th">{row?.assignTo}</TableCell>
                                                <TableCell component="th">{
                                                    (row.hasOwnProperty('salesManagerApproved') && row?.salesManagerApproved.length !== 0) ?
                                                        row?.salesManagerApproved.find((ele, i) => ele.status)?.status === '' ? `Approval Not Initiated` :
                                                            row?.salesManagerApproved.find((ele, i) => ele.status)?.status === 'Send for Approval' ? `Sent for Approval` :
                                                                row?.salesManagerApproved.find((ele, i) => ele.status)?.status : `Approval Not Initiated`
                                                }
                                                    {
                                                        row?.salesManagerApproved.find((ele, i) => ele.status)?.status === 'Rejected' && <InfoIcon style={{ cursor: 'pointer' }} fontSize="small" color="secondary" onClick={() => showRejectedStatus('salesManagerApproved', row)} />
                                                    }
                                                </TableCell>
                                                <TableCell component="th">{                                                   
                                                (row?.finanaceManagerApproved === undefined  || row?.finanaceManagerApproved === null) ? `Approval Not Initiated` :
                                                    (row.hasOwnProperty('finanaceManagerApproved') && row?.finanaceManagerApproved?.length !== 0) ?
                                                        row?.finanaceManagerApproved?.find((ele, i) => ele.status)?.status === '' ? `Approval Not Initiated` :
                                                            row?.finanaceManagerApproved?.find((ele, i) => ele.status)?.status === 'Send for Approval' ? `Sent for Approval` :
                                                                row?.finanaceManagerApproved?.find((ele, i) => ele.status)?.status : `Approval Not Initiated`
                                                }
                                                    {
                                                        row?.finanaceManagerApproved?.find((ele, i) => ele.status)?.status === 'Rejected' && <InfoIcon style={{ cursor: 'pointer' }} fontSize="small" color="secondary" onClick={() => showRejectedStatus('centerHeadApproved', row)} />
                                                    }
                                                </TableCell>
                                                <TableCell component="th">{
                                                    (row.hasOwnProperty('centerHeadApproved') && row?.centerHeadApproved.length !== 0) ?
                                                        row?.centerHeadApproved.find((ele, i) => ele.status)?.status === '' ? `Approval Not Initiated` :
                                                            row?.centerHeadApproved.find((ele, i) => ele.status)?.status === 'Send for Approval' ? `Sent for Approval` :
                                                                row?.centerHeadApproved.find((ele, i) => ele.status)?.status : `Approval Not Initiated`
                                                }
                                                    {
                                                        row?.centerHeadApproved.find((ele, i) => ele.status)?.status === 'Rejected' && <InfoIcon style={{ cursor: 'pointer' }} fontSize="small" color="secondary" onClick={() => showRejectedStatus('centerHeadApproved', row)} />
                                                    }
                                                </TableCell>
                                                <TableCell component="th">{
                                                    (row.hasOwnProperty('businessHeadApproved') && row?.businessHeadApproved.length !== 0) ?
                                                        row?.businessHeadApproved.find((ele, i) => ele.status)?.status === '' ? `Approval Not Initiated` :
                                                            row?.businessHeadApproved.find((ele, i) => ele.status)?.status === 'Send for Approval' ? `Sent for Approval` :
                                                                row?.businessHeadApproved.find((ele, i) => ele.status)?.status : `Approval Not Initiated`
                                                }
                                                    {
                                                        row?.businessHeadApproved.find((ele, i) => ele.status)?.status === 'Rejected' && <InfoIcon style={{ cursor: 'pointer' }} fontSize="small" color="secondary" onClick={() => showRejectedStatus('businessHeadApproved', row)} />
                                                    }
                                                </TableCell>
                                                <TableCell component="th">{
                                                    (row.hasOwnProperty('customerApproved','businessHeadApproved') && row?.customerApproved.length !== 0) ?
                                                        row?.customerApproved.find((ele, i) => ele.status)?.status === '' ? `Approval Not Initiated` :
                                                            row?.businessHeadApproved.find((ele, i) => ele.status)?.status === 'Send for Approval' ? `Approval Not Initiated` :
                                                            row?.customerApproved.find((ele, i) => ele.status)?.status === 'Send for Approval' ? `Sent for Approval` :

                                                                row?.customerApproved.find((ele, i) => ele.status)?.status : `Approval Not Initiated`
                                                }
                                                    {
                                                        row?.customerApproved.find((ele, i) => ele.status)?.status === 'Rejected' && <InfoIcon style={{ cursor: 'pointer' }} fontSize="small" color="secondary" onClick={() => showRejectedStatus('customerApproved', row)} />
                                                    }
                                                </TableCell>
                                                <TableCell component="th">
                                                    {
                                                        // ((row?.salesManagerApproved.find((ele, i) => ele.status === 'Rejected' || ele.status === 'Approval Not Initiated') ||
                                                        //     row?.customerApproved.find((ele, i) => ele.status === 'Rejected' || ele.status === 'Approval Not Initiated') ||
                                                        //     row?.finanaceManagerApproved.find((ele, i) => ele.status === 'Rejected' || ele.status === 'Approval Not Initiated') ||
                                                        //     row?.centerHeadApproved.find((ele, i) => ele.status === 'Rejected' || ele.status === 'Approval Not Initiated') ||
                                                        //     row?.businessHeadApproved.find((ele, i) => ele.status === 'Rejected' || ele.status === 'Approval Not Initiated'))
                                                        //     row?.salesExecutiveApproved.find((ele, i) => ele.status === 'Send For Approval' || ele.status === 'Approval Not Initiated')) &&
                                                        // <Button color="primary" variant="contained" onClick={() => reinitialProcess(row)} >
                                                        //     Reinitiate Process
                                                        // </Button>
                                                        ((row?.salesExecutiveApproved.find((ele, i) => (ele.status === 'Send for Approval' || ele.status === 'Approval Not Initiated' || row?.reinitiateProcess === "approved") && (userDetails.roles.find(role => role.name === ROLENAME.SalesUser|| role.name === ROLENAME.SalesManager || role.name === ROLENAME.SalesHead || role.name === ROLENAME.AssistantSalesManager))))) &&
                                                        <Button color="primary" variant="contained" onClick={() => reinitialProcess(row)} >
                                                            Reinitiate Process
                                                        </Button>
                                                    }
                                                    {
                                                        (row?.salesManagerApproved.find((ele, i) => ele.status === 'Approved') &&
                                                            row?.customerApproved.find((ele, i) => ele.status === 'Approved') &&
                                                            // row?.finanaceManagerApproved.find((ele, i) => ele.status === 'Approved') &&
                                                            row?.centerHeadApproved.find((ele, i) => ele.status === 'Approved') &&
                                                            row?.businessHeadApproved.find((ele, i) => ele.status === 'Approved' || ele.status === 'Not Required') &&
                                                            userDetails.roles.find(role => role.name === ROLENAME.DesignHead)) &&
                                                        <Button color="primary" variant="contained" onClick={() => assignDesigner(row)} >
                                                            Assign DM/ADM
                                                        </Button>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                    :
                                    <TableRow>
                                        <TableCell colSpan={9} style={{ textAlign: 'center' }}>No Records Found</TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </div>

            <Dialog open={openStatusModal} onClose={() => setOpenStatusModal(false)} aria-labelledby="form-dialog-title" maxWidth='sm'>
                <DialogContent>
                    <div style={{ paddingBottom: 15 }}>
                        <h2>Do you want to assign a designer now?</h2>
                    </div>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={() => updateClosureStatus("No")} variant="outlined">No</Button>
                    <Button onClick={() => updateClosureStatus("Yes")} variant="contained" color="primary">Yes</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openDesignAssignModal} onClose={() => setOpenDesginerAssignModal(false)} aria-labelledby="form-dialog-title" maxWidth='lg'>
                <DialogTitle id="form-dialog-title">
                    Select Design Manager / Assistance Design Manager
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <div style={{ paddingTop: 15 }}>
                        <FormControl fullWidth style={{ marginBottom: 20 }}>
                            <TextField
                                select
                                labelId="demo-simple-select-label1"
                                id="demo-simple-select1"
                                name="designUser"
                                label="Select DM/ADM"
                                onChange={(e) => setSelectedDesginUser(e.target.value)}
                                variant="outlined"
                                size="small"
                            >
                                {
                                    designManagersUser?.map((data, i) => (
                                        <MenuItem value={data._id} key={i + 1}>{data.name}</MenuItem>
                                    ))
                                }
                            </TextField>
                        </FormControl>
                    </div>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={() => setOpenDesginerAssignModal(false)} variant="outlined">No</Button>
                    <Button onClick={() => assignLeadToDesignUser()} variant="contained" color="primary">Assign DM/ADM</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openRejectModal} onClose={() => setOpenRejectModal(false)} aria-labelledby="form-dialog-title" maxWidth='md'>
                <DialogTitle id="form-dialog-title">Rejected Reason</DialogTitle>
                <Divider />
                <DialogContent>
                    <div style={{ paddingBottom: 15 }}>
                        <h2>{rejectRemark.remark ? rejectRemark.remark : `NA`}</h2>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default connect(null, { setLoading, setSnackInfo })(DesignerAssignmentView)
