import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { DownloadOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons';
import { connect } from 'react-redux'
import { setLoading, setSnackInfo } from '../redux/actions/appAction';
import HttpService from '../services/httpService';
import TextField from '@material-ui/core/TextField';
import { useSelector } from 'react-redux';
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core'
import { ROLENAME} from '../utils/index';
const useStyles = makeStyles((theme) => ({
	table: {
		minWidth: 700,
	},
	formControl: {
		margin: theme.spacing(3),
	},
	root: {
		display: 'flex',
	},
	textField: {
		width: "100px"
	},
}));

const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: "#ccc",
		color: theme.palette.common.black,
	},
	body: {
		fontSize: 14,
	},
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
	root: {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover,
		},
	},
}))(TableRow);

const PoDetailsModal = (props) => {
	const classes = useStyles();
	const [poDetails, setPoDetails] = useState([])
	const { userDetails } = useSelector(state => state.user)

	useEffect(() => {
		setPoDetails(props?.PODETAILS)
	}, [props?.PODETAILS])

	const approveProcurement = async (procurementId, index) => {
		props.setLoading(true)
		try {
			const obj = []
			let data = {
				materialElementId: poDetails?.materials[index]?._id,
				materialId: poDetails?.materials[index]?.materialId?._id,
				pricePerUnit: poDetails?.materials[index]?.pricePerUnit,
				requestedPrice: poDetails?.materials[index]?.requestedPrice,
				requestedQuantity: poDetails?.materials[index]?.requestedQuantity,
				projectId: props?.SELECTEDPROJECT?._id ?? poDetails?.projectId?._id,
				projectMaterialId: poDetails?.materials[index]?.projectMaterialId,
				quantity: poDetails?.materials[index]?.quantity,
				scopeId: poDetails?.materials[index]?.scopeId?._id
			}
			obj.push(data)
			const result = await HttpService.approveProcurement(procurementId, obj)
			if (result.status === 200) {
				props.setSnackInfo('PO Approved', "success")
				props.REFRESH(procurementId)
			}
		} catch (error) {
			console.error(error)
			props.setSnackInfo(error?.response?.data, "error")
		}
		props.setLoading(false)
	}

	const rejectProcurement = async (procurementId, index) => {
		props.setLoading(true)
		try {
			const obj = []
			let data = {
				materialElementId: poDetails?.materials[index]?._id,
				materialId: poDetails?.materials[index]?.materialId?._id,
				pricePerUnit: poDetails?.materials[index]?.pricePerUnit,
				requestedPrice: poDetails?.materials[index]?.requestedPrice,
				requestedQuantity: poDetails?.materials[index]?.requestedQuantity,
				projectId: props?.SELECTEDPROJECT?._id ?? poDetails?.projectId?._id,
				projectMaterialId: poDetails?.materials[index]?.projectMaterialId,
				quantity: poDetails?.materials[index]?.quantity,
				scopeId: poDetails?.materials[index]?.scopeId?._id
			}
			obj.push(data)
			const result = await HttpService.rejectProcurement(procurementId, obj)
			if (result.status === 200) {
				props.setSnackInfo('PO Rejected', "success")
				props.REFRESH(procurementId)
			}
		} catch (error) {
			console.error(error)
			props.setSnackInfo(error?.response?.data, "error")
		}
		props.setLoading(false)
	}

	const handleRequestQunatityChange = (e, i) => {
		let po = { ...poDetails }
		po.materials[i].requestedQuantity = e.target.value
		setPoDetails(po)
	}

	const handelRequestPriceChange = (e, i) => {
		let po = { ...poDetails }
		po.materials[i].requestedPrice = e.target.value
		setPoDetails(po)
	}

	const getMaskedPODocument = async (data) => {
		try {
			const result = await HttpService.generateMaskedPo(data._id)
			window.open(result.data.link)
		} catch (error) {
			console.log(error, "error")
		}
	}

	const [deleteOpenModel, setDeleteOpenModel] = useState(false)

	const removePOForProject = () => {
		setDeleteOpenModel(true)
	}

	const deletePoConfirm = async () => {
		props.setLoading(true)
		try {
			let response = await HttpService.removePO(poDetails._id)
			props.setSnackInfo(response?.data ?? "Something went wrong", "success")
			setDeleteOpenModel(false)
			props.VIEWALL(props.TITLE !== "Approve PO" ? 'PO/MTR Raised/Requested' : "TEST")
		} catch (error) {
			console.error(error)
			props.setSnackInfo(error?.response?.data?.message ?? "Error", "error")
		}
		props.setLoading(false)
	}

	return (
		<div>
			<div className='modal-header' style={{ justifyContent: "space-between" }}>
				<div style={{ width: "25%" }}>
					<b>OrderID: </b>
					<span>{poDetails.orderId}</span>
				</div>
				<div style={{ width: "25%" }}>
					<b>Created At: </b>
					<span>{moment(poDetails.createdAt).format('DD-MM-YYYY')}</span>
				</div>
				<div style={{ width: "25%" }}>
					<b>Project Code: </b>
					<span>{poDetails.projectId?.code ?? "OPEN"}</span>
				</div>
			</div>
			<div className='modal-header' style={{ justifyContent: "space-between" }}>
				<div style={{ width: "25%" }}>
					<b>Client: </b>
					<span>{poDetails.projectId?.clientName ?? "PROCUREMENT"}</span>
				</div>
				{poDetails.status !== "Sent for Approval" && poDetails?.poLink !== undefined &&
					<div style={{ width: "25%" }}>
						<span className="btn"onClick={() => window.open(poDetails?.poLink)}>Download PO <DownloadOutlined /></span>
						<br /><br />
						{
							poDetails?.maskedPoLink ?
								<span className="btn" onClick={() => window.open(poDetails?.maskedSoLink)}> Download Masked PO <DownloadOutlined /></span>
								:
								<span className="btn" onClick={() => getMaskedPODocument(poDetails)}> Download Masked PO <DownloadOutlined /></span>
						}
					</div>
				}
				<div style={{ width: "25%" }}>
					<span className="btn" onClick={() => props.VIEWALL(props.TITLE !== "Approve PO" ? 'PO/MTR Raised/Requested' : "TEST")}>View All <EyeOutlined /></span>
					<br /><br />
					{userDetails?.roles?.find(role => role.name == ROLENAME.OperationHead) && <span className="btn" onClick={() => removePOForProject()}>Delete PO<DeleteOutlined /> </span>}
				</div>
			</div>
			<TableContainer component={Paper}>
				<Table className={classes.table} size="small" aria-label="customized table">
					<TableHead>
						<TableRow>
							<StyledTableCell>Sl No</StyledTableCell>
							<StyledTableCell>Material Code</StyledTableCell>
							<StyledTableCell>Scope</StyledTableCell>
							<StyledTableCell>Status</StyledTableCell>
							<StyledTableCell>Vendor Price</StyledTableCell>
							<StyledTableCell>Requested Price</StyledTableCell>
							<StyledTableCell>Unit</StyledTableCell>
							<StyledTableCell> Qty</StyledTableCell>
							<StyledTableCell>Requested Qty</StyledTableCell>
							<StyledTableCell align="center">Total</StyledTableCell>
							{props.TITLE === "Approve PO" && poDetails?.status !== "Approved" && poDetails?.status !== "Rejected" && <StyledTableCell align="center">Action</StyledTableCell>}
						</TableRow>
					</TableHead>
					<TableBody>
						{poDetails?.materials?.map((data, i) => <StyledTableRow StyledTableRow key={i + 1}>
							<StyledTableCell>{i + 1}</StyledTableCell>
							<StyledTableCell>{data?.materialId?.code}</StyledTableCell>
							<StyledTableCell>{data?.scopeId?.name}</StyledTableCell>
							<StyledTableCell>{data?.status}</StyledTableCell>
							<StyledTableCell>{data?.pricePerUnit}</StyledTableCell>
							{props.TITLE === "Approve PO" ? <StyledTableCell><TextField className={classes.textField} value={data?.requestedPrice} onChange={(e) => handelRequestPriceChange(e, i)} type="number" variant="outlined" />	</StyledTableCell> : <StyledTableCell>{data?.requestedPrice}</StyledTableCell>}
							<StyledTableCell>{data?.unitId?.name}</StyledTableCell>
							<StyledTableCell>{data?.quantity}</StyledTableCell>
							{props.TITLE === "Approve PO" ? <StyledTableCell><TextField className={classes.textField} value={data?.requestedQuantity} onChange={(e) => handleRequestQunatityChange(e, i)} type="number" variant="outlined" />	</StyledTableCell> : <StyledTableCell>{data?.requestedQuantity}</StyledTableCell>}
							<StyledTableCell>{(data?.requestedQuantity * data?.requestedPrice)}</StyledTableCell>
							{props.TITLE === "Approve PO" && data?.status !== "Approved" && data?.status !== "Rejected" && poDetails?.status !== "Approved" && poDetails?.status !== "Rejected" && <StyledTableCell> <div className="approve-reject-btn">
								<button onClick={() => approveProcurement(poDetails?._id, i)} className='approve-btn'>Approve</button>
								<button onClick={() => rejectProcurement(poDetails?._id, i)} className='reject-btn'>Reject</button>
							</div>
							</StyledTableCell>}
						</StyledTableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<div>
				<Dialog open={deleteOpenModel} onClose={() => setDeleteOpenModel(false)} aria-labelledby="form-dialog-title" maxWidth='sm'>
					<DialogTitle id="form-dialog-title">This Action cannot be Reverted!</DialogTitle>
					<DialogActions>
						<Button variant="outlined" onClick={() => setDeleteOpenModel(false)} color="secondary">
							Cancel
						</Button>
						<Button onClick={deletePoConfirm} variant="outlined" color="primary">
							Submit
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		</div >
	)
}

export default connect(null, { setLoading, setSnackInfo })(PoDetailsModal)
