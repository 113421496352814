import React, { useState, useEffect, useCallback } from 'react';
import HttpService from '../../../services/httpService';
import { connect, useSelector } from 'react-redux';
import { setLoading, setSnackInfo } from '../../../redux/actions/appAction';
import { getAllRoles } from '../../../redux/actions/rolesActions';
import {
  Table,
  Button,
  TableBody,
  TableCell,
  TableContainer,
  Checkbox,
  TableHead,
  TableRow,
  TextField,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  FormControlLabel,
  TablePagination
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { DeleteOutlined, ToolOutlined, EditOutlined } from '@ant-design/icons';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select/Select';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import { Modal } from 'antd';
import Input from '@material-ui/core/Input';
import ListItemText from '@material-ui/core/ListItemText';
import Switch from '@material-ui/core/Switch';
import { salesDepartment, designDepartment } from '../../../utils';
import _ from 'lodash';

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#ccc",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Users = (props) => {
  const classes = useStyles();

  // Pagination states
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalUsers, setTotalUsers] = useState(0);

  const [users, setUsers] = useState([]);
  const [searchField, setSearchField] = useState("");
  const { rolesList } = useSelector(state => state.roles);

  const [addUserModal, setAddUserModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [userDetails, setUserDetails] = useState({
    name: "",
    mobile: "",
    email: "",
    password: "",
    confirmPassword: "",
    _id: ""
  });
  const { name, mobile, email, password, confirmPassword } = userDetails;

  const [departmentLists, setDepartmentsList] = useState([]);
  const [dupDepartmentLists, setDupDepartmentList] = useState([]);
  const [department, setSelectedDepartment] = useState("");
  const [am, setSelectedAM] = useState("");
  const [team, setSelectedTeam] = useState("");
  const [teamLists, setTeamList] = useState([]);
  const [amList, setAMList] = useState([]);

  const [cities, setCities] = useState([]);
  const [dumpCities, setDumpCities] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [expcenters, setExpcenters] = useState([]);
  const [dumpExpcenters, setDumpExpcenters] = useState([]);
  const [selectedExpcenters, setSelectedExpcenters] = useState([]);
  const [checked, setChecked] = useState(true);
  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState("");

  const [rolesModal, setRolesModal] = useState(false);
  const [selectedUserRoles, setSelectedUserRoles] = useState([]);
  const [isPasswordShort, setIsPasswordShort] = useState(false)
  const [isConfirmPasswordMatched, setIsConfirmPasswordMatched] = useState(true)
  const [passwordStrength, setPasswordStrength] = useState("");
  const [passwordStrengthColor, setPasswordStrengthColor] = useState("red");

  useEffect(() => {
    getUsers(page, rowsPerPage, checked);
    getDepartments();
    getLocationList();
    getExperienceCenters();
  }, [page, rowsPerPage, checked]);

  const getUsers = async (page, rowsPerPage, isActive) => {
    props.setLoading(true);
    try {
      const result = await HttpService.getUsersforUsersPage({
        page,
        rowsPerPage,
        isActive,
      });
      setUsers(result.data.users);
      setTotalUsers(result.data.totalCount);
    } catch (error) {
      console.error(error.message);
      props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error");
    }
    props.setLoading(false);
  };

  const getDepartments = async () => {
    try {
      props.setLoading(true)
      const department = await HttpService.getDepartments();
      let dept = [];
      department.data.forEach(ele => {
        dept.push({ 'name': ele.name, '_id': ele._id })
      })
      setDepartmentsList(dept);
      setDupDepartmentList(department.data);
    } catch (error) {
      console.log(error, "get department error")
    }
    props.setLoading(false)
  }

  const getLocationList = async () => {
    props.setLoading(true)
    try {
      const response = await HttpService.getLocationList()
      let citi = []
      response.data.forEach((ele, i) => {
        citi.push({
          name: ele.name
        })
      })
      setDumpCities(citi)
      setCities(response.data)
    } catch (error) {
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  const getExperienceCenters = async () => {
    props.setLoading(true)
    try {
      const response = await HttpService.getExpericenCenters()
      setExpcenters(response.data)
    } catch (error) {
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const deleteUser = async (userId) => {
    setUserId(userId)
    props.setLoading(true)
    setOpen(true);
    props.setLoading(false)
  }

  const validatePassword = (password) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$~`_\-*&^%])[A-Za-z\d!@#$~`_\-*&^%]{8,}$/;
    return regex.test(password)

  };

  const checkPasswordStrength = (password) => {
    // Define strength levels
    const strengthLevels = {
      veryWeak: /^(?=.*[a-z])/,
      weak: /^(?=.*[a-z])(?=.*[A-Z])/,
      ok: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/,
      strong: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])/,
      veryStrong: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])(?=.{8,})/,
    };

    // Check password against each strength level
    if (strengthLevels.veryStrong.test(password)) {
      setPasswordStrengthColor('darkgreen')
      return 'Very Strong';
    } else if (strengthLevels.strong.test(password)) {
      setPasswordStrengthColor('lightgreen')
      return 'Strong';
    } else if (strengthLevels.ok.test(password)) {
      setPasswordStrengthColor('blue')
      return 'Ok';
    } else if (strengthLevels.weak.test(password)) {
      setPasswordStrengthColor('orange')
      return 'Weak';
    } else {
      setPasswordStrengthColor('red')
      return 'Very Weak';
    }
  };
  
  const changeHandler = (e) => {

    let name = e.target.name
    let value = e.target.value

    if (name === 'password') {
      const passwordStrength = checkPasswordStrength(value)
      setPasswordStrength(passwordStrength)

      if (value.length < 8) {
        setIsPasswordShort(true)
      } else {
        setIsPasswordShort(false)
      }

      if (value !== confirmPassword) {
        setIsConfirmPasswordMatched(false)
      } else {
        setIsConfirmPasswordMatched(true)
      }

    } else if (name === 'confirmPassword') {
      if (password !== value) {
        setIsConfirmPasswordMatched(false)
      } else {
        setIsConfirmPasswordMatched(true)
      }
    }
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value })

  }

  const addUser = async () => {
    const isPasswordValid = validatePassword(password)

    if (!isPasswordValid) {
      props.setSnackInfo('Password should contain atleast one Uppercase, Lowercase, Numeric & Special character !,@,#,$,~,`,_,-,*,&,^,%', "error")
      return null
    }

    let selectedLocation = [];
  
    let selectedCenter = [];

    cities.forEach((ele, i) => {
      if (selectedCities.includes(ele.name)) {
        selectedLocation.push(ele._id)
      }
    })

    expcenters.forEach((ele, i) => {
      if (selectedExpcenters.includes(ele.name)) {
        selectedCenter.push(ele._id)
      }
    })

    props.setLoading(true)
    try {
      setSelectedTeam("");
      if (password !== confirmPassword) {
        props.setSnackInfo('Passwords do not match', "error")
      } else {
        
        userDetails['department'] = department;
        
        userDetails['team'] = team;
        
        if (am) {
          userDetails['assistantManagerId'] = am
        }
        
        userDetails['locationId'] = selectedLocation;
        
        userDetails['experienceCenterId'] = selectedCenter;
        await HttpService.addUser(userDetails)
        props.setSnackInfo('User Added Successfully', "success")
        setAddUserModal(false)
        setSelectedAM("")
        getUsers()
      }
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  const debouncedGetUsers = useCallback(
    _.debounce((page, rowsPerPage, isActive, query) => {
      props.setLoading(true);
      HttpService.getUsersforUsersPage({ page, rowsPerPage, isActive, query })
        .then(result => {
          setUsers(result.data.users);
          setTotalUsers(result.data.totalCount);
        })
        .catch(error => {
          console.error(error.message);
          props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error");
        })
        .finally(() => {
          props.setLoading(false);
        });
    }, 500), // Adjust the delay as needed
    []
  );

  const searchUser = (e) => {
    const query = e.target.value;
    setSearchField(query);

    if (query === "") {
      getUsers(page, rowsPerPage, checked);
    } else {
      debouncedGetUsers(page, rowsPerPage, checked, query);
    }
  };

  const getLocationsAndExpCenter = (userdata) => {
    let citi = []
    userdata.locationId.forEach((ele, i) => {
      citi.push(ele.name)
    })
    setDumpCities(citi)
    setSelectedCities(citi)

    let expcenter = [];
    userdata.experienceCenterId.forEach((ele, i) => {
      expcenter.push(ele.name)
    })
    setDumpExpcenters(expcenter)
    setSelectedExpcenters(expcenter);
  }

  const getUserById = async (userId) => {
    props.setLoading(true)
    try {

      const result = await HttpService.getUserById(userId)
      setSelectedDepartment("");
      setSelectedTeam("");
      setSelectedAM("");

      if (result.data.departmentId) {
        setSelectedDepartment(result.data.departmentId);
        getTeamsList(result.data.departmentId);
        setSelectedTeam(result.data.teamId);
        setSelectedAM(result.data.assistantManagerId);
      }

      getLocationsAndExpCenter(result.data)

      const { name, mobile, email, _id } = result.data
      setUserDetails({ name, mobile, email, _id, password: "", confirmPassword: "" })
      setIsEdit(true)
      setAddUserModal(true)
      getLocationList();
      getExperienceCenters();

    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  const openRoleModal = async (userId) => {
    props.setLoading(true);
    try {
      const result = await HttpService.getUserById(userId);
      const { name, mobile, email, _id } = result.data;
      setUserDetails({ name, mobile, email, _id, password: "", confirmPassword: "" });
      setSelectedUserRoles(result.data.roles.map(role => role.name));
      await props.getAllRoles();
      setRolesModal(true);
    } catch (error) {
      console.error(error);
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error");
    }
    props.setLoading(false);
  };

  const handleCheck = async (action, name) => {
    props.setLoading(true);
    try {
      const roleId = rolesList.filter(role => role.name === name)[0]._id;
      await HttpService.editUserRole({ action, roleId, userId: userDetails._id });
      props.setSnackInfo('User Role Updated', "success");
      await openRoleModal(userDetails._id);
    } catch (error) {
      console.error(error);
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error");
    }
    props.setLoading(false);
  }

  const editUser = async () => {
    let selectedLocation = [];
    let selectedCenter = [];

    cities.forEach((ele, i) => {
      if (selectedCities.includes(ele.name)) {
        selectedLocation.push(ele._id)
      }
    })

    expcenters.forEach((ele, i) => {
      if (selectedExpcenters.includes(ele.name)) {
        selectedCenter.push(ele._id)
      }
    })
    props.setLoading(true)
    try {
      userDetails['departmentId'] = department;
      userDetails['teamId'] = team;
      if (am) {
        userDetails['assistantManagerId'] = am
      }
      userDetails['locationId'] = selectedLocation;
      userDetails['experienceCenterId'] = selectedCenter;
      await HttpService.editUser(userDetails)
      props.setSnackInfo('User Updated', "success")
      setAddUserModal(false)
      setIsEdit(false)
      setSelectedAM("")
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  const changeDepartmentHandler = (event) => {
    setSelectedDepartment(event.target.value);
    getTeamsList(event.target.value);
  }

  const getTeamsList = async (deptid) => {
    try {
      let teams = dupDepartmentLists.filter(ele => ele._id === deptid);
      const assistantManagers = teams[0]['users']
        .filter(user => user.roles.some(role => role.name === "Assistant Sales Manager" || role.name === "Assistant Design Manager"))
        .map(user => ({ id: user._id, name: user.name }));
      const activeTeams = teams[0]['teams'].filter(team => team.isActive === true);
      setTeamList(activeTeams);
      setAMList(assistantManagers)
    } catch (error) {
      console.error('Error in getTeamsList:', error);
    }
  }

  const changeTeamHandler = (event) => {
    setSelectedTeam(event.target.value);
  }

  const changeAMHandler = (event) => {
    setSelectedAM(event.target.value)
  }

  const getExperienceCenterByLocation = (e) => {
    setSelectedCities(e.target.value);
    let expcenter = [];
    let existingSelected = [];
    expcenters.forEach((ele, i) => {
      if (e.target.value.includes(ele.locationId.name)) {
        expcenter.push({
          name: ele.name
        });
      }
      if (e.target.value.includes(ele.locationId.name)) {
        selectedExpcenters.forEach((ele1, i) => {
          if (ele1 === ele.name) {
            existingSelected.push(ele.name)
          }
        })
      }
    })
    setDumpExpcenters(expcenter)
    setSelectedExpcenters(existingSelected)
  }

  const getSelectedExpCenters = (e) => {
    setSelectedExpcenters(e.target.value);
  }

  const setIsActiveUser = async (e, userId) => {
    props.setLoading(true)
    try {
      await HttpService.inActiveUser(userId)
      props.setSnackInfo("Updated Successfully", "success")
      getUsers(page, rowsPerPage)
    } catch (error) {
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  const handleUserDelete = async (userId) => {
    props.setLoading(true);
    setOpen(false);
    try {
      await HttpService.deleteUser(userId);
      props.setSnackInfo('User Deleted', "success");
      getUsers(page, rowsPerPage);
    } catch (error) {
      console.error(error);
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error");
    }
    props.setLoading(false);
  };

  const handleSwitchChange = () => {
    setChecked((prev) => !prev);
    // Reset to the first page when changing the filter
    setPage(1);
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: 10 }}>
        <Button variant="contained" color="primary" onClick={() => setAddUserModal(true)}>
          Create New User
        </Button>
      </div>
      <div>
        <TextField label="Search Users  " value={searchField} style={{ marginBottom: 10 }} name="name" onChange={searchUser} variant="outlined" />
        <FormControlLabel
          value="end"
          style={{ marginLeft: "50px", marginTop: "10px" }}
          control={
            <Switch
              color="primary"
              checked={checked}
              onChange={handleSwitchChange}
            />
          }
          label="Active Users"
        />
      </div>
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Sl No</StyledTableCell>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>ExperienceCenter</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Department</StyledTableCell>
              <StyledTableCell>Team</StyledTableCell>
              <StyledTableCell>IsActive</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users?.map((data, i) => (
              <StyledTableRow key={i + 1}>
                <StyledTableCell>{i + 1}</StyledTableCell>
                <StyledTableCell>{data?.name}</StyledTableCell>
                <StyledTableCell>{data.experienceCenterId.map((el) => el?.name ? el?.name + " " : '-')}</StyledTableCell>
                <StyledTableCell>{data?.email}</StyledTableCell>
                <StyledTableCell>{data?.departmentId?.name}</StyledTableCell>
                <StyledTableCell>{data?.teamId?.name}</StyledTableCell>
                <StyledTableCell>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={data.isActive}
                        onChange={(e) => setIsActiveUser(e, data._id)}
                        name="isActive"
                        color="primary"
                      />
                    }
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <StyledTableRow><EditOutlined onClick={() => getUserById(data._id)} /></StyledTableRow >
                  <StyledTableRow><ToolOutlined onClick={() => openRoleModal(data._id)} /></StyledTableRow>
                  <StyledTableRow><DeleteOutlined onClick={() => deleteUser(data._id)} /></StyledTableRow>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[50, 100, 150]}
        component="div"
        count={totalUsers}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Dialog
        open={addUserModal}
        TransitionComponent={Transition}
        keepMounted
        fullWidth={true}
        maxWidth={'sm'}
        onClose={() => setAddUserModal(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Add User"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description" style={{ display: "flex", flexDirection: "column" }}>
            <TextField label="Name" value={name} size="small" style={{ marginBottom: 20 }} name="name" onChange={changeHandler} variant="outlined" />
            <TextField label="Contact Number" size="small" value={mobile} style={{ marginBottom: 20 }} name="mobile" onChange={changeHandler} variant="outlined" />
            <TextField label="Email" size="small" style={{ marginBottom: 20 }} value={email} name="email" type="email" onChange={changeHandler} variant="outlined" />
            <FormControl fullWidth style={{ marginBottom: 20 }}>
              <TextField
                select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="department"
                value={department}
                label="Select Department"
                onChange={changeDepartmentHandler}
                variant="outlined"
                size="small"
              >
                {
                  departmentLists?.map((data, i) => (
                    <MenuItem value={data._id} key={i + 1}>{data.name}</MenuItem>
                  ))
                }
              </TextField>
            </FormControl>
            <FormControl fullWidth style={{ marginBottom: 20 }}>
              <TextField
                select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="team"
                value={team}
                label="Select Team"
                onChange={changeTeamHandler}
                variant="outlined"
                size="small"
              >
                {
                  teamLists?.map((data, i) => (
                    <MenuItem value={data._id} key={i + 1}>{data.name}</MenuItem>
                  ))
                }
              </TextField>
            </FormControl>
            {(department === salesDepartment || department === designDepartment) && (
              <FormControl fullWidth style={{ marginBottom: 20 }}>
                <TextField
                  select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="am"
                  value={am}
                  label="Select AM"
                  onChange={changeAMHandler}
                  variant="outlined"
                  size="small"
                >
                  {amList.map((item, i) => (
                    <MenuItem value={item.id} key={i + 1}>{item.name}</MenuItem>
                  ))}
                </TextField>
              </FormControl>
            )}
            <FormControl fullWidth variant="outlined" style={{ marginBottom: 20 }} className={classes.formControl}>
              <InputLabel id="demo-mutiple-checkbox-label" htmlFor="outlined-age-native-simple">Select Location</InputLabel>
              <Select
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                multiple
                value={selectedCities}
                name={selectedCities}
                onChange={getExperienceCenterByLocation}
                input={<Input />}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
              >
                {dumpCities.map((name) => (
                  <MenuItem key={name.name} value={name.name}>
                    <Checkbox checked={selectedCities.indexOf(name.name) > -1} />
                    <ListItemText primary={name.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth variant="outlined" style={{ marginBottom: 20 }} className={classes.formControl}>
              <InputLabel id="demo-mutiple-checkbox-label" htmlFor="outlined-age-native-simple">Select Experience Center</InputLabel>
              <Select
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                multiple
                value={selectedExpcenters}
                onChange={getSelectedExpCenters}
                input={<Input />}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
              >
                {dumpExpcenters.map((name) => (
                  <MenuItem key={name.name} value={name.name}>
                    <Checkbox checked={selectedExpcenters.indexOf(name.name) > -1} />
                    <ListItemText primary={name.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {
            !isEdit && <>
              {password.length > 0 && isPasswordShort && <p style={{color: 'red'}}>*Password must be at least 8 characters long</p>}
              
              <TextField label="Password" size="small" style={{ marginBottom: 20, marginTop: 5 }} value={password} name="password" type="password" onChange={changeHandler} variant="outlined" minLength="8"/>
              
              {password.length > 0 && <p style={{ color: passwordStrengthColor, fontWeight: 900, marginBottom: '0.5rem', fontSize: '1.2rem' }}>{passwordStrength}</p>}
              
              {confirmPassword.length > 0 && !isConfirmPasswordMatched && <p style={{color: 'red'}}>*Passwords do not match</p>}
              
              <TextField label="Confirm Password" size="small" style={{ marginBottom: 20, marginTop: 5 }} value={confirmPassword} name="confirmPassword" type="password" onChange={changeHandler} variant="outlined" />
            </>
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {isEdit ? <Button disabled={!(name !== "" && mobile !== "" && email !== "")} onClick={editUser} color="primary">
            UPDATE
            </Button> : <Button disabled={!(name !== "" && mobile !== "" && email !== "" && password !== "" && confirmPassword !== "") || isPasswordShort || !isConfirmPasswordMatched || ['Very Weak', 'Weak'].includes(passwordStrength)} onClick={addUser} color="primary">
            ADD
          </Button>}
        </DialogActions>
      </Dialog>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete User?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you really want to delete this User permanently?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleUserDelete(userId)} color="primary">
            OK
          </Button>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        title="Assign roles"
        centered
        visible={rolesModal}
        onCancel={() => setRolesModal(false)}
        width={1000}
        maskClosable={false}
        footer={null}
      >
        {rolesList?.map(role => (
          <div key={role.name}>
            <Checkbox checked={selectedUserRoles.includes(role.name)} onChange={() => handleCheck(!selectedUserRoles.includes(role.name), role.name)} />
            {role?.name}
          </div>
        ))}
      </Modal>
    </div>
  );
};

export default connect(null, { setLoading, setSnackInfo, getAllRoles })(Users);
