import React, { useState, useEffect } from 'react';
import { Layout, Menu } from 'antd';
// import 'antd/dist/antd.css';
import {
  MenuUnfoldOutlined, ReadOutlined, ReconciliationOutlined,
  MenuFoldOutlined, BankOutlined, CalendarOutlined, FileDoneOutlined,
  CalculatorOutlined, ShoppingCartOutlined, ShopOutlined, RocketOutlined,
  DashboardOutlined, UploadOutlined, DiffOutlined, UserOutlined,
  BarsOutlined, AuditOutlined, GiftOutlined , CarryOutOutlined, HomeOutlined, FileTextOutlined, FolderViewOutlined, SignalFilled, SettingOutlined, ControlOutlined, PhoneOutlined
} from '@ant-design/icons';
import Routes from '../routes'
import MenuBookIcon from '@material-ui/icons/MenuBook';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import BallotIcon from '@material-ui/icons/Ballot';
import AssignmentLateRoundedIcon from '@material-ui/icons/AssignmentLateRounded';
import FaceIcon from '@material-ui/icons/Face'
import { NavLink } from 'react-router-dom';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import decorpotLogo from '../assets/img/org/latestDecorpot.png'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import HttpService from '../services/httpService';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import { connect } from 'react-redux';
import { setLoading, setSnackInfo } from '../redux/actions/appAction';
import { setUserDetails } from '../redux/actions/userAction';
import { Menu as MaterialMenu, FormControl } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Checkbox from '@material-ui/core/Checkbox';
import { ROLENAME, PAYMENT_APPROVER, constantDepartmentId } from '../utils/index';
import moment from 'moment'
import Tooltip from '@material-ui/core/Tooltip';
import vendorPo from '../pages/vendorManagement/vendorPo';
import { makeStyles } from '@material-ui/core/styles';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import Box from "@material-ui/core/Box";
import FolderSharedIcon from '@material-ui/icons/FolderShared';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import { DownloadOutlined, EyeFilled } from '@ant-design/icons';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import CategoryRoundedIcon from '@material-ui/icons/CategoryRounded';
import AssignmentRoundedIcon from '@material-ui/icons/AssignmentRounded';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';

const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;
const ITEM_HEIGHT = 48;
const useStyles = makeStyles({
  input: {
    color: "white",
    borderBottom: "1px solid #fff",
  },
  inputLabel: {
    color: "#aca9a9 !important",
  },
  icon: {
    color: 'white',
  },
  changePasswordButton: { backgroundColor: 'white', color: '#2c2e3e', marginRight: 50, marginLeft: 10, marginTop: 20, padding: 5, borderRadius: '50px', width: '100px', height: '25px', fontSize: '8px', "&:hover": { backgroundColor: "#3f51b5", color: 'white' } },

});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Sidebar = React.memo((props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [collapsed, setCollapsed] = useState(false)
  const { userDetails } = useSelector(state => state.user)
  const [sidebarItems, setSidebarItems] = useState([])
  const [changePasswordModal, setChangePasswordModal] = useState(false)
  const [expcenters, setExpcenters] = useState([]);
  const [locations, setLocations] = useState([]);
  const [defaultLocation, setDefaultLocation] = useState(userDetails.selectedLocation);
  const [defaultExpCenter, setDefaultExpCenter] = useState(userDetails.selectedExpCenter);


  const [password, setPassword] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: ""
  })

  const [showPassword, setShowPassword] = useState(false)
  
  const togglePasswordVisibility = () => {

    // console.log('togglePasswordVisibility called', showPassword)
    
    setShowPassword(!showPassword);

  };
  
  const history = useHistory()
  
  const toggle = () => {

    setCollapsed(!collapsed);
  
  };

  const logout = async () => {
    // localStorage.removeItem('TOKEN_KEY')
    localStorage.clear()
    props.setUserDetails({})
    history.push('/');
  }

  const [isPasswordShort, setIsPasswordShort] = useState(false)
  
  const [isConfirmPasswordMatched, setIsConfirmPasswordMatched] = useState(true)
  
  const [isPasswordValidated, setIsPasswordValidated] = useState({
    hasNumber: false,
    hasUpperCase: false,
    hasLowerCase: false,
    hasSpecialChar: false
  });

  const [passwordStrength, setPasswordStrength] = useState("");
  
  const [passwordStrengthColor, setPasswordStrengthColor] = useState("red");
  
  const [daysRemainingToChangePassword, setDaysRemainingToChangePassword] = useState(90 - userDetails.DaysRemainingToChangePassword)


  useEffect(() => {
    getAsideItems();
    getLocationList();
    expireSession();

    const fetchData = async () => {

      try {
        // Make API call
        const response = await HttpService.getUserById(userDetails._id);
        
        // console.log('CurrentUser', response)
        
        let MyLastPasswordCreationDate = new Date(response.data.LastPasswordCreationDate)
        
        // console.log('MyLastPasswordCreationDate', MyLastPasswordCreationDate)
        
        let CurrentDate = new Date()
        
        // console.log('CurrentDate', CurrentDate)
        
        let timeDifference = CurrentDate.getTime() - MyLastPasswordCreationDate.getTime();
        
        // console.log('timeDifference', timeDifference)
        
        let DaysRemainingToChangePassword = 90 - (Math.floor(timeDifference / (1000 * 60 * 60 * 24)))
        
        // console.log('differenceInDays', DaysRemainingToChangePassword)
        
        setDaysRemainingToChangePassword(DaysRemainingToChangePassword)
        
        if(DaysRemainingToChangePassword < 1) {

          localStorage.clear()
          
          // props.setUserDetails({})
          
          history.push('/')

        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    // Call fetchData initially
    fetchData();
    
    // Call fetchData every 24 hrs
 // const intervalId = setInterval(fetchData, 120000);
  const intervalId = setInterval(fetchData, 86400000);    
    // Cleanup function to clear the interval
    return () => clearInterval(intervalId);
    
    // eslint-disable-next-line
  }, [])

  const expireSession = () => {
    let version = "2.7";
    if (!localStorage.getItem('VERSION_KEY')) {
      localStorage.setItem('VERSION_KEY', version);
    } else if (localStorage.getItem('VERSION_KEY') !== version) {
      localStorage.removeItem('TOKEN_KEY');
      window.location.reload()
      localStorage.setItem('VERSION_KEY', version);
    } else if (localStorage.getItem('VERSION_KEY') === version) {
      localStorage.setItem('VERSION_KEY', version);
    }
  }

  const getAsideItems = async () => {
    props.setLoading(true)
    try {
      const response = await HttpService.getAsideItems()
      setSidebarItems(response.data)
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something Went Wrong", "error")
      if (error?.response?.data?.success === false) {
        // localStorage.removeItem('TOKEN_KEY')
        localStorage.clear()
        props.setUserDetails({})
        history.push('/');
      }
    }
    props.setLoading(false)
  }


  const checkPasswordStrength = (password) => {

    // Define strength levels
    const strengthLevels = {

      veryWeak: /^(?=.*[a-z])/,
      weak: /^(?=.*[a-z])(?=.*[A-Z])/,
      ok: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/,
      strong: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])/,
      veryStrong: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])(?=.{8,})/,
    
    };
  
    // Check password against each strength level
    if (strengthLevels.veryStrong.test(password)) {
    
      setPasswordStrengthColor('darkgreen')
    
      return 'Very Strong';
    
    } else if (strengthLevels.strong.test(password)) {
    
      setPasswordStrengthColor('lightgreen')
    
      return 'Strong';
    
    } else if (strengthLevels.ok.test(password)) {
    
      setPasswordStrengthColor('blue')
    
      return 'Ok';
    
    } else if (strengthLevels.weak.test(password)) {
    
      setPasswordStrengthColor('orange')
    
      return 'Weak';
    
    } else {
    
      setPasswordStrengthColor('red')
    
      return 'Very Weak';
    
    }
  };


  const changePassword = async () => {

    props.setLoading(true)
    
    // console.log('newPassword', password.newPassword)
    
    const isPasswordValid = validatePassword(password.newPassword)
    
    // console.log('isPasswordValid', isPasswordValid)
    
    if(!isPasswordValid) {
    
      props.setSnackInfo('Password should contain atleast one Uppercase, Lowercase, Numeric & Special character !,@,#,$,~,`,_,-,*,&,^,%', "error")
    
      props.setLoading(false)
    
      return null
    
    }
    
    try {
    
      if (password.newPassword !== password.confirmPassword)
        return props.setSnackInfo('Passwords Do Not Match', "error")
      
        let response = await HttpService.changePassword({ password: password.currentPassword, newPassword: password.newPassword })
    
        // console.log('response', response)
    
        props.setSnackInfo(response?.data?.message,"success")
    
        // props.setLoading(false)
    
        // props.setSnackInfo('Password Changed Successfully', "success")
    
        setPassword({
        currentPassword: "",
        newPassword: "",
        confirmPassword: ""
      })
    
      setChangePasswordModal(false)
    
      localStorage.clear()
    
      // props.setUserDetails({})
    
      history.push('/');
    
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went Wrong", "error")
    }
    props.setLoading(false)
  }

  const validatePassword = (password) => {

    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$~`_\-*&^%])[A-Za-z\d!@#$~`_\-*&^%]{8,}$/;
    
    // setIsValid(regex.test(password));
    
    return regex.test(password)
  
  };
  
  const validatePassword2 = (password) => {
  
    const numberRegex = /\d/;
  
    const upperCaseRegex = /[A-Z]/;
  
    const lowerCaseRegex = /[a-z]/;
  
    const specialCharRegex = /[!@#$~`_\-*&^%]/;
    
    const hasNumber = numberRegex.test(password);
  
    const hasUpperCase = upperCaseRegex.test(password);
  
    const hasLowerCase = lowerCaseRegex.test(password);
  
    const hasSpecialChar = specialCharRegex.test(password);
    
    return {
        hasNumber,
        hasUpperCase,
        hasLowerCase,
        hasSpecialChar
    };
  
  };

  const changeHandler = (e) => {

      let name = e.target.name
    
    let value = e.target.value
    
    if(name === 'newPassword') {
    
      const passwordStrength = checkPasswordStrength(value)
    
      setPasswordStrength(passwordStrength)
    
      const passwordValidation = validatePassword2(value)
    
      setIsPasswordValidated(passwordValidation)
    
      if(value.length < 8) {
    
        setIsPasswordShort(true)
    
      } else {
    
        setIsPasswordShort(false)
    
      }
    
      if(value !== password.confirmPassword) {
    
        setIsConfirmPasswordMatched(false)
    
      } else {
    
        setIsConfirmPasswordMatched(true)
    
      }
    
    } else if(name === 'confirmPassword') {
    
      if(password.newPassword !== value) {
    
        setIsConfirmPasswordMatched(false)
    
      } else {
    
        setIsConfirmPasswordMatched(true)
    
      }
    
    }
    setPassword({ ...password, [e.target.name]: e.target.value })
  }
  
  const handleCancelForChangePassword = () => {
  
  
    let MyLastPasswordCreationDate = new Date(userDetails.LastPasswordCreationDate)
  
    let CurrentDate = new Date()
  
    let timeDifference = CurrentDate.getTime() - MyLastPasswordCreationDate.getTime();
  
    let differenceInDays = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  
    setIsPasswordShort(false)
  
    setIsConfirmPasswordMatched(true)
  
    setPassword({
      ...password,
      currentPassword: "",
      newPassword: "",
      confirmPassword: ""
    })
  
    setChangePasswordModal(false)
  
  }

  const getLocationList = async () => {
    try {
      const response = await HttpService.getLocationList();
      let userLocation = [];
      for (let i = 0; i < response.data.length; i++) {
        for (let j = 0; j < userDetails.locationId.length; j++) {
          if (response.data[i]._id === userDetails.locationId[j]._id) {
            userLocation.push({
              _id: userDetails.locationId[j]._id,
              name: userDetails.locationId[j].name
            })
          }
        }
      }
      setLocations(userLocation)
      setDefaultLocation(userDetails.selectedLocation);
      getExperienceCenterByLocation(userDetails.selectedLocation)
    } catch (error) {
      console.log(error)
    }
  }

  const getExpCenterByCity = async (cityid) => {
    setDefaultLocation(cityid);
    await getExperienceCenterByLocation(cityid)
  }

  const getExperienceCenterByLocation = async (locid) => {
    try {
      const response = await HttpService.getExpericenCentersByLocation(locid)
      let userExpCenters = [];
      for (let i = 0; i < response.data.length; i++) {
        for (let j = 0; j < userDetails.experienceCenterId.length; j++) {
          if (userDetails.experienceCenterId[j] === response.data[i]['_id']) {
            userExpCenters.push({
              name: response.data[i]['name'],
              _id: response.data[i]['_id']
            });
          }
        }
      }
      setExpcenters(userExpCenters);
    } catch (error) {
      console.log(error)
    }
  }

  const handleexpcenter = (e) => {
    setDefaultExpCenter(e.target.value)
    props.setUserDetails({
      ...userDetails,
      selectedExpCenter: e.target.value,
      selectedLocation: defaultLocation
    })
    setTimeout(() => {
      window.location.reload(false);
    }, 1000);
  }

  const iconComponents = {
    "DashboardOutlined": DashboardOutlined,
    'SettingOutlined': SettingOutlined,
    'ControlOutlined': ControlOutlined,
    'EqualizerIcon': EqualizerIcon,
    'CalendarOutlined': CalendarOutlined,
    'UserOutlined':UserOutlined,
    'AssignmentRoundedIcon': AssignmentRoundedIcon,
    'CategoryRoundedIcon': CategoryRoundedIcon,
    'CalculatorOutlined':CalculatorOutlined,
    'ReadOutlined':ReadOutlined,
    'BarsOutlined': BarsOutlined,
    'ReconciliationOutlined':ReconciliationOutlined,
    'DiffOutlined':DiffOutlined,
    'ShopOutlined':ShopOutlined,
    'ShoppingCartOutlined':ShoppingCartOutlined,
    'GiftOutlined':GiftOutlined,
    'RocketOutlined':RocketOutlined,
    'HomeOutlined':HomeOutlined,
    'FileDoneOutlined':FileDoneOutlined,
    "SupervisedUserCircleIcon": SupervisedUserCircleIcon,
    "BarsOutlined": BarsOutlined,
    "DashboardOutlined": DashboardOutlined,
    "AssignmentRoundedIcon": AssignmentRoundedIcon,
    "AddCircleOutlineRoundedIcon": AddCircleOutlineRoundedIcon,
    "FolderSharedIcon": FolderSharedIcon,
    "LocalLibraryIcon": LocalLibraryIcon,
    "LiveHelpIcon": LiveHelpIcon,
    "CategoryRoundedIcon": CategoryRoundedIcon,
     "AddShoppingCartIcon":AddShoppingCartIcon,
     "CloudDownloadIcon" : CloudDownloadIcon,
     "BallotIcon":BallotIcon,
     "CarryOutOutlined":CarryOutOutlined,
     "SignalFilled":SignalFilled,
     "UploadOutlined":UploadOutlined,
     'AuditOutlined' : AuditOutlined,
     'PhoneOutlined' : PhoneOutlined,
     'ConfirmationNumber' : ConfirmationNumberIcon
        };


        // const renderMenuItems = (menuItems, parentUrl = '') => {
        //   menuItems.sort((a, b) => a.priority - b.priority);
        
        //   return menuItems.map(menuItem => {
        //       if (menuItem.isActive === true) {
        //           const url = menuItem.url;
        //           const IconComponent = iconComponents[menuItem.Icon]; 
        
        //           if (menuItem.subMenu && menuItem.subMenu.length > 0) {
        //               return (
        //                   <SubMenu
        //                       key={menuItem.key}
        //                       icon={IconComponent ? <IconComponent /> : null}
        //                       title={menuItem.label}
        //                   >
        //                       {renderMenuItems(menuItem.subMenu, url)}
        //                   </SubMenu>
        //               );
        //           } else {
        //               return (
        //                   <Menu.Item key={menuItem.key} icon={IconComponent ? <IconComponent /> : null}>
        //                       <NavLink to={url}>{menuItem.label}</NavLink>
        //                   </Menu.Item>
        //               );
        //           }
        //       }
        //       return null;
        //   });
        // };     
      

        const replaceDatePlaceholders = (url) => {
          const datePattern = /\$\{moment\(\).subtract\((\d+), 'months'\).format\('YYYY-MM-DD'\)\}\/\$\{moment\(\).add\((\d+), 'days'\).format\('YYYY-MM-DD'\)\}/;
        
          return url.replace(datePattern, (match, subtractMonths, addDays) => {
            const startDate = moment().subtract(parseInt(subtractMonths), 'months').format('YYYY-MM-DD');
            const endDate = moment().add(parseInt(addDays), 'days').format('YYYY-MM-DD');
            return `${startDate}/${endDate}`;
          });
        };
        
        const renderMenuItems = (menuItems, parentUrl = '') => {
          menuItems.sort((a, b) => a.priority - b.priority);
        
          return menuItems.map(menuItem => {
            if (menuItem.isActive) {
              let url = menuItem.url;
        
              // Replace date placeholders in the URL
              url = replaceDatePlaceholders(url);
        
              const IconComponent = iconComponents[menuItem.Icon]; 
        
              if (menuItem.subMenu && menuItem.subMenu.length > 0) {
                return (
                  <SubMenu
                    key={menuItem.key}
                    icon={IconComponent ? <IconComponent /> : null}
                    title={menuItem.label}
                  >
                    {renderMenuItems(menuItem.subMenu, url)}
                  </SubMenu>
                );
              } else {
                return (
                  <Menu.Item key={menuItem.key} icon={IconComponent ? <IconComponent /> : null}>
                    <NavLink to={url}>{menuItem.label}</NavLink>
                  </Menu.Item>
                );
              }
            }
            return null;
          });
        };
  
    
  
    const { canAccess } = userDetails.canAccess[0];

  return (
    <Layout>
      <Sider trigger={null} collapsible collapsed={collapsed} width={`${collapsed ? "fit-content" : "fit-content"}`}>
        <div className="logo" >
          {!collapsed &&
            <img className="decorpot_logo" src={decorpotLogo} alt="decorpot logo" />
          }
        </div>
        <Menu theme="dark" mode="inline" defaultSelectedKeys={userDetails?.roles?.find(role => role.name === ROLENAME.TeleCallerUser) ? ['home']: ['dashboard']}>

        { canAccess.length !== 0 ? renderMenuItems(canAccess) : <Menu.Item key="dashboard" icon={<DashboardOutlined />}>
              Dashboard
              <NavLink to="/dashboard"></NavLink>
            </Menu.Item>
        }

            {
                userDetails?.roles?.find(role => role.name === ROLENAME.Admin || role.name === ROLENAME.CHM_Manager || role.name === ROLENAME.CHM_User ||  role.name === ROLENAME.TeleCallerManager) &&
                  <>
                    <Menu.Item key="CHM-Service-Ticket" icon={<FolderSharedIcon />}><a href="https://decorpot.raiseaticket.com/support/#/newticket" target="_blank" rel="noopener noreferrer">Create Service Ticket</a></Menu.Item>
                  </>
                }

        {/* {userDetails?.roles?.find(role => role.name === ROLENAME.TeleCallerUser
          ) &&
            <Menu.Item key="home" icon={<BallotIcon />}>Home
              <Tooltip title="Home">
                <NavLink to="/home"></NavLink>
              </Tooltip>
            </Menu.Item>
          }  */}
  
          {/* {userDetails?.roles?.find(role => role.name !== ROLENAME.TeleCallerUser && role.name !== ROLENAME.TeleCallerManager) && (
          sidebarItems.includes("Dashboard") && (
            
          )
        )} */}
         {/* {
          sidebarItems.includes("Dashboard") && 
          userDetails?.roles?.find(role => role.name === ROLENAME.DesignHead ||
             role.name === ROLENAME.Admin || role.name === ROLENAME.DesignManager ||
             role.name === ROLENAME.AssistantDesignManager ||
             role.name === ROLENAME.OperationDesignManager || 
             role.name === ROLENAME.DGM_Design || role.name === ROLENAME.DesignUser) && (
              <>
            <Menu.Item key="downloadcenter" icon={<CloudDownloadIcon />}>DownLoad Center
              <NavLink to={`/download-center`}></NavLink>
            </Menu.Item>             
             </>
             )
          } */}


          {/* {
            sidebarItems.includes("Dashboard") && (userDetails?.departmentId !== constantDepartmentId.marketing && userDetails?.departmentId !== constantDepartmentId.chm) && userDetails?.roles?.find(role =>role.name !== "TeleCaller" &&  role.name !== "TeleCallerManager")  && 
            <>
              <SubMenu key="dashboard-stats-menu" icon={<EqualizerIcon />} title="Statistics">
                {
                  sidebarItems.includes("Dashboard") && userDetails?.roles?.find(role => role.name === ROLENAME.CenterHead || role.name === ROLENAME.BusinessHead || role.name === ROLENAME.Admin || role.name === ROLENAME.SalesUser || role.name === ROLENAME.SalesManager || role.name === ROLENAME.AssistantSalesManager || role.name === ROLENAME.DGM_Sales || role.name === ROLENAME.DGM_Design || role.name === ROLENAME.OperationDesignManager || role.name === ROLENAME.AssistantDesignManager || role.name === ROLENAME.DesignUser || role.name === ROLENAME.DataAnalyst|| role.name === ROLENAME.FinanceManager ) &&
                  <Menu.Item key="design-review-sheet"><NavLink to="/designstats/design-review-sheet">Design Review Sheet</NavLink></Menu.Item>
                }
                {
                  sidebarItems.includes("Dashboard") && ((userDetails?.departmentId !== constantDepartmentId.design  && userDetails?.departmentId !== constantDepartmentId.sales) || userDetails?.roles?.find(role => role.name === ROLENAME.ProductionManager)) &&
                  <Menu.Item key="factory-review-sheet"><NavLink to="/factory-review-sheet">Factory Review Sheet</NavLink></Menu.Item>
                }
               
              </SubMenu>
            </>
          }
          {
            sidebarItems.includes("Dashboard") && 
            <Menu.Item key="knowledge-hub" icon={<MenuBookIcon />}>Knowledge HUB
              <NavLink to={`/knowledge_hub`}></NavLink>
            </Menu.Item>
          }
          {/* {
            sidebarItems.includes("Dashboard") && userDetails?.roles?.find(role => role.name === ROLENAME.CenterHead || role.name === ROLENAME.BusinessHead || role.name === ROLENAME.DesignHead || role.name === ROLENAME.Admin)
            && <Menu.Item key="dashboard-stats" icon={<EqualizerIcon />}><NavLink to="/designstats">Statistics Report</NavLink></Menu.Item>
          } */}



          {/* {sidebarItems.includes("Dashboard") && <Menu.Item key="reminder" icon={<CalendarOutlined />}> Reminder <NavLink to="/reminder"></NavLink> </Menu.Item>} */}

          {/* {sidebarItems.includes("Dashboard") && userDetails?.roles?.find(role => role.name === ROLENAME.Admin) && <>
            <Menu.Item key="location" icon={<RocketOutlined />}> Location <NavLink to="/location"></NavLink> </Menu.Item>
            <Menu.Item key="experienceCenter" icon={<HomeOutlined />}> Experience Center <NavLink to="/experienceCenter"></NavLink> </Menu.Item>
          </>} */}
          {/* {sidebarItems.includes("Dashboard") &&
            userDetails?.roles?.find(role => role.name === ROLENAME.Admin ||
            role.name === ROLENAME.CHM_Manager ||
            role.name === ROLENAME.CHM_User ||
            role.name === ROLENAME.DesignHead ||
            role.name === ROLENAME.DesignManager ||
            role.name === ROLENAME.AssistanceDesignManager ||
            role.name === ROLENAME.AssistantDesignManager ||
            role.name === ROLENAME.DesignUser ||
            role.name === ROLENAME.SalesHead ||
            role.name === ROLENAME.SalesManager ||
            role.name === ROLENAME.SalesUser ||
            role.name === ROLENAME.AssistantSalesManager ||
            role.name === ROLENAME.Execution_Manager ||
            role.name === ROLENAME.ExecutionUser ||
            role.name === ROLENAME.DGM_Marketing ||
            role.name === ROLENAME.DGM_Sales ||
            role.name === ROLENAME.DGM_Design ||
            role.name === ROLENAME.OperationDesignManager ||
            role.name === ROLENAME.MarketingManager ||
            role.name === ROLENAME.DataAnalyst ||
            role.name === ROLENAME.OperationManager
          ) && 
            <>
              <Menu.Item key="customer-survey-form" icon={<FileDoneOutlined />}> Customer Surveys <NavLink to="/view-customer-survey-forms"></NavLink> </Menu.Item>
            </>} */}


          {/* {
            userDetails?.roles?.find(role => role.name === ROLENAME.Admin || role.name === ROLENAME.FinanceManager || role.name === ROLENAME.FinanceExecutive) &&
              <SubMenu key="financeApprovalScreen" icon={<ReadOutlined />} title="Finance Approval Screen">
                
                    <Menu.Item key="approvalForManualTransaction">Approval For Manual Transaction<NavLink to="/financeApprovalScreen&L/approvalForManualTransaction"></NavLink></Menu.Item>
                
                
                    <Menu.Item key="customerInformation">Customer Information<NavLink to="/financeApprovalScreen&L/customerInformation"></NavLink></Menu.Item>
                    <Menu.Item key="ApprovedPaymentReceipts">Approved Payment Receipts<NavLink to="/financeApprovalScreen&L/approved-payment-receipts"></NavLink></Menu.Item>
                 
              </SubMenu>
          }
                    
          {/* {userDetails?.roles?.find(role => role.name === ROLENAME.Admin || role.name === ROLENAME.FinanceManager) && <>
             <Menu.Item key="hdfc-virtual-payment" icon={<AccountBalanceWalletIcon />}> HDFC_VPA <NavLink to="/hdfc-virtual-payment"></NavLink> </Menu.Item>
          </>} */}
          {/* End - Payment Integration */}

          {/* {(userDetails?.roles?.find(role => role.name === ROLENAME.Admin || role.name === ROLENAME.CHM_Manager || role.name === ROLENAME.CHM_User || role.name === ROLENAME.TeleCallerManager)) &&
            <Menu.Item key="call-center-logs" icon={<PhoneOutlined />}> Call Center Logs <NavLink to="/call-center-logs"></NavLink> </Menu.Item>
          } 

          {sidebarItems.includes("Dashboard") && userDetails?.roles?.find(role => role.name === ROLENAME.Admin || role.name === ROLENAME.MarketingManager || role.name === ROLENAME.MarketingUser || role.name === ROLENAME.SalesHead || role.name === ROLENAME.SalesManager || role.name === ROLENAME.AssistantSalesManager || role.name === ROLENAME.SalesUser || role.name === ROLENAME.DesignUser || role.name === ROLENAME.DesignManager || role.name === ROLENAME.DesignHead || role.name === ROLENAME.ProcurementExecutive || role.name === ROLENAME.SeniorOperationManager || role.name === ROLENAME.FinanceManager || role.name === ROLENAME.FinanceExecutive|| role.name === ROLENAME.AssistantDesignManager|| role.name === ROLENAME.OperationDesignManager || role.name === "Factory BOM Manager") && <Menu.Item key="qtProjects" icon={<BarsOutlined />}>
            Leads
            <NavLink to="/qtProjects"></NavLink>
          </Menu.Item>} */}

          {/* {
            userDetails?.roles?.find
              (
                role =>
                  role.name === ROLENAME.Admin ||
                  role.name === ROLENAME.SalesManager ||
                  role.name === ROLENAME.AssistantSalesManager ||
                  role.name === ROLENAME.SalesUser ||
                  role.name === ROLENAME.DesignUser ||
                  role.name === ROLENAME.DesignManager ||
                  role.name === ROLENAME.DesignHead ||
                  // role.name === ROLENAME.CHM_Manager ||
                  // role.name === ROLENAME.CHM_User||
                  role.name === ROLENAME.AssistantDesignManager ||
                  role.name === ROLENAME.OperationDesignManager    
              ) &&
            <Menu.Item key="design_kick-off" icon={<DashboardOutlined />}>
              Design Kick-Off
              <NavLink to="/design_kick-off"></NavLink>
            </Menu.Item>
          } */}


          {/* {userDetails?.roles?.find(role =>
            role.name === "Admin" ||
            role.name === "IMOS Manager" ||
            role.name === "IMOS User" ||
            role.name === "Factory Bom" ||
            role.name === "Factory Manager" ||
            role.name === "Procurement Executive" ||
            role.name === "BOM Executive"
          ) &&
            <>
              <SubMenu key="optimisationFiles" icon={<FileTextOutlined />} title="Optimisation Files">
                <Menu.Item key="optimisationFilesView" icon={<FolderViewOutlined />}>View<NavLink to="/optimisation_files/view"></NavLink></Menu.Item>
                {userDetails?.roles?.find(role =>
                  role.name === "Admin" ||
                  role.name === "IMOS Manager" ||
                  role.name === "IMOS User"
                ) &&
                  <Menu.Item key="optimisationFilesUpload" icon={<UploadOutlined />}>Upload<NavLink to="/optimisation_files/upload"></NavLink></Menu.Item>

                }
              </SubMenu>
            </>
          }
           */}

          {/* {sidebarItems.includes("CHM") && userDetails?.roles?.find(role => role.name === ROLENAME.CHM_Manager || role.name === ROLENAME.CHM_User || role.name === ROLENAME.FactoryCrmUser) && <Menu.Item key="chmLeads" icon={<BarsOutlined />}>
            CHM Leads
            <NavLink to="/chm-leads"></NavLink>
          </Menu.Item>} */}

          {/* Marketing Manager Role Side Menu */}
          {/* {
            sidebarItems.includes("Dashboard") && userDetails?.roles?.find(role => role.name === ROLENAME.MarketingManager) &&
            <>
              <Menu.Item key="create-new-lead" icon={<DashboardOutlined />}>Create New Lead<NavLink to="/create-new-lead"></NavLink></Menu.Item>
              <Menu.Item key="To-Be-Called" icon={<DashboardOutlined />}>To Be Called<NavLink to={`/status-information/To-Be-Called/${moment().subtract(1, 'months').format('YYYY-MM-DD')}/${moment().add(1, 'days').format('YYYY-MM-DD')}/status/false`}></NavLink></Menu.Item>
              <Menu.Item key="On-Hold" icon={<DashboardOutlined />}>On Hold<NavLink to={`/status-information/Hold/${moment().subtract(1, 'months').format('YYYY-MM-DD')}/${moment().add(1, 'days').format('YYYY-MM-DD')}/status/false`}></NavLink></Menu.Item>
              <Menu.Item key="Lead-Returned-By-Sales" icon={<DashboardOutlined />}>Lead Returned by Sales<NavLink to={`/status-information/Lead-Returned-By-Sales/${moment().subtract(1, 'months').format('YYYY-MM-DD')}/${moment().add(1, 'days').format('YYYY-MM-DD')}/status/false`}></NavLink></Menu.Item>
            </>
          } */}
         
          {/* Sales Manager And Sales User Role Common Side Menu */}
          {
            //sidebarItems.includes("Dashboard") && userDetails?.roles?.find(role => role.name === ROLENAME.SalesHead || role.name === ROLENAME.SalesManager || role.name === ROLENAME.SalesUser || role.name === ROLENAME.AssistantSalesManager) &&
           // <>
             // {/* <Menu.Item key="search-customer" icon={<DashboardOutlined />}>Search Customer Name<NavLink to="/search-customer"></NavLink></Menu.Item> */}
              //<Menu.Item key="won-customer-in-other-dept" icon={<CarryOutOutlined />}>Won Customer in Other Dept<NavLink to={`/status-information/won-customer-in-other-dept/${moment().subtract(1, 'months').format('YYYY-MM-DD')}/${moment().add(1, 'days').format('YYYY-MM-DD')}/status/false`}></NavLink></Menu.Item>
            //</>
          }

          {/* {
            sidebarItems.includes("Dashboard") && userDetails?.roles?.find(role => role.name === ROLENAME.MarketingManager || role.name === ROLENAME.MarketingUser) &&
            <>
              <Menu.Item key="lead-in-other-dept" icon={<DashboardOutlined />}>Leads in Other Dept<NavLink to={`/status-information/won-customer-in-other-dept/${moment().subtract(1, 'months').format('YYYY-MM-DD')}/${moment().add(1, 'days').format('YYYY-MM-DD')}/status/false`}></NavLink></Menu.Item>
            </>
          }

          {
            sidebarItems.includes("Dashboard") && userDetails?.roles?.find(role => role.name === ROLENAME.DesignHead || role.name === ROLENAME.DesignManager || role.name === ROLENAME.DesignUser || role.name === ROLENAME.AssistantDesignManager || role.name === ROLENAME.OperationDesignManager  || role.name === ROLENAME.CHM_Manager || role.name === ROLENAME.CHM_User) &&

            <>
              <Menu.Item key="customer-in-other-dept" icon={<DashboardOutlined />}>Customer in Other Dept
                <Tooltip title="Customer in Other Department">
                  <NavLink to={`/status-information/won-customer-in-other-dept/${moment().subtract(1, 'months').format('YYYY-MM-DD')}/${moment().add(1, 'days').format('YYYY-MM-DD')}/status/false`}></NavLink>
                </Tooltip>
              </Menu.Item>
            </>
          } */}


            

           
          {/* {
            sidebarItems.includes("Dashboard") && userDetails?.roles?.find(role => role.name === ROLENAME.Execution_Manager || role.name === ROLENAME.ExecutionUser || role.name === ROLENAME.OperationHead) &&
            <>
              <Menu.Item key="underExecution" icon={<DashboardOutlined />}> Under Execution <NavLink to="/under-execution-project"></NavLink> </Menu.Item>
              <Menu.Item key="Completed" icon={<DashboardOutlined />}> Completed <NavLink to="/project-completed"></NavLink> </Menu.Item>
              <Menu.Item key="Completed-MWP" icon={<DashboardOutlined />}> Completed-MWP <NavLink to="/project-Completed-MWP"></NavLink> </Menu.Item>

            </>
          } */}

          {/* {
            sidebarItems.includes("Dashboard") && userDetails?.roles?.find(role => role.name === ROLENAME.DesignHead || role.name === ROLENAME.OperationDesignManager) &&
            <>
              <Menu.Item key="designer-pending-lead" icon={<DashboardOutlined />}>Pending Leads Assignment
                <Tooltip title="Pending Leads Assignment">
                  <NavLink to={`/designer-assignment/Won-Deals-Pending-Designer-Assignment/${moment().subtract(1, 'months').format('YYYY-MM-DD')}/${moment().add(1, 'days').format('YYYY-MM-DD')}/${userDetails._id}/designHead`}></NavLink>
                </Tooltip>
              </Menu.Item>
            </>
          }
          {
            sidebarItems.includes("Dashboard") && userDetails?.roles?.find(role => role.name === ROLENAME.MarketingManager || role.name === ROLENAME.MarketingUser) &&
            <>
              <Menu.Item key="raw-leads" icon={<DashboardOutlined />}>Raw Leads<NavLink to="/raw-leads"/></Menu.Item>
            </>
          } */}



          {/* {
            sidebarItems.includes("Dashboard") && userDetails?.roles?.find(role => role.name === ROLENAME.SiteQC || role.name === ROLENAME.IMOSUser || role.name === ROLENAME.IMOSManager || role.name === ROLENAME.BOMExecutive || role.name === ROLENAME.FactoryBom || role.name === ROLENAME.SiteBom || role.name === ROLENAME.FactoryManager) &&
            <>
              <Menu.Item key="siteqc-projects" icon={<DashboardOutlined />}>Projects
                <NavLink to={`/siteqc/projects`}></NavLink>
              </Menu.Item>
            </>
          }

          {
            sidebarItems.includes("Dashboard") && userDetails?.roles?.find(role => role.name === ROLENAME.IMOSUser || role.name === ROLENAME.IMOSManager) &&
            <>
              <Menu.Item key="completed-project" icon={<DashboardOutlined />}>Completed Projects
                <NavLink to={`/siteqc/completed-projects`}></NavLink>
              </Menu.Item>
            </>
          } */}

          {/* {sidebarItems.includes("Dashboard") && userDetails?.roles?.find(role => role.name === ROLENAME.SalesHead || role.name === ROLENAME.SalesManager || role.name === ROLENAME.CenterHead || role.name === ROLENAME.BusinessHead || role.name === ROLENAME.AssistantSalesManager || role.name === ROLENAME.FinanceManager
          ) &&
            <Menu.Item key="lead-approval" icon={<AuditOutlined />}>Approve Lead <NavLink to="/lead-approval"></NavLink></Menu.Item>
          } */}

          {/* {userDetails?.roles?.find(role => role.name === ROLENAME.CenterHead
          ) &&
            <Menu.Item key="complimentary-approval" icon={<GiftOutlined />}>Complimentary Items Approval
              <Tooltip title="Complimentary Items Approval">
                <NavLink to="/complimentary-approval"></NavLink>
              </Tooltip>
            </Menu.Item>
          } */}

          {/* {userDetails?.roles?.find(role => role.name === ROLENAME.CenterHead || role.name === ROLENAME.Admin || role.name === ROLENAME.OperationDesignManager 
                                    || role.name === ROLENAME.DGM_Design || role.name === ROLENAME.SalesManager || role.name === ROLENAME.DGM_Sales
          ) &&
            <Menu.Item key="lost-approvals" icon={<AssignmentLateRoundedIcon />}>Lost Requests
              <Tooltip title="Lost Requests">
                <NavLink to="/lost-approvals"></NavLink>
              </Tooltip>
            </Menu.Item>
          } */}
{/* 
          {userDetails?.roles?.find(role => role.name === ROLENAME.DesignHead || role.name === ROLENAME.Admin || role.name === ROLENAME.DesignManager || role.name === ROLENAME.AssistantDesignManager || role.name === ROLENAME.OperationDesignManager || role.name === ROLENAME.DGM_Design || role.name === ROLENAME.DesignUser
          ) &&
            <Menu.Item key="Design-Signoff Hold Projects" icon={<BallotIcon />}>Design-Signoff Hold Projects
              <Tooltip title="Design-Signoff Hold Projects">
                <NavLink to="/design-signoff-hold-projects"></NavLink>
              </Tooltip>
            </Menu.Item>
          }  */}


          {/* {userDetails?.roles?.find(role => role.name === ROLENAME.FinanceManager || role.name === ROLENAME.QualityControl || role.name === ROLENAME.QCManager || role.name === ROLENAME.FinalMarking || role.name === ROLENAME.FinanceExecutive || role.name === ROLENAME.Admin || role.name === ROLENAME.DesignHead || role.name === ROLENAME.DesignManager || role.name === ROLENAME.DesignUser || role.name === ROLENAME.SeniorOperationManager || role.name === ROLENAME.CHM_Manager || role.name === ROLENAME.CHM_User || role.name === ROLENAME.FinanceUser || role.name === ROLENAME.AssistantDesignManager || role.name === ROLENAME.OperationDesignManager) &&
            <Menu.Item key="lead-design-to-execution" icon={<DashboardOutlined />}>Design to Execution Approval
              <Tooltip title="Design to Execution Approval">
                <NavLink to={`/lead-design-to-execution/${moment().subtract(1, 'months').format('YYYY-MM-DD')}/${moment().add(1, 'days').format('YYYY-MM-DD')}`}></NavLink>
              </Tooltip>
            </Menu.Item>
          } */}

         

          {/* customer app design stage dynamic mapping data */}
          {/* {sidebarItems.includes("Dashboard") && userDetails?.roles?.find(role => role.name === ROLENAME.Admin) && 
              <>
                <Menu.Item key="customerAppDesignStage" icon={<SignalFilled />}> Customer Stage Setting <NavLink to="/customer-app-designstage-setting"></NavLink> </Menu.Item>
              </>
          } */}

          {/* Quotation changes approval for ADM > OPM > Finance */}
          {/* {userDetails?.roles?.find(role => role.name === ROLENAME.FinanceManager || role.name === ROLENAME.AssistantDesignManager || role.name === ROLENAME.OperationManager || role.name === ROLENAME.DesignManager || role.name === ROLENAME.OperationDesignManager || role.name === ROLENAME.DGM_Ops) &&
            <Menu.Item key="lead-view-quotation-changes" icon={<DashboardOutlined />}>Quotation Change Request Approval
              <Tooltip title="Quotation Changes Approval">
                <NavLink to={`/lead-quotation-changes-approval`}></NavLink>
              </Tooltip>
            </Menu.Item>
          } */}

          {/* {userDetails?.roles?.find(role => role.name === ROLENAME.FinanceManager) &&
            <Menu.Item key="lead-finalPayment" icon={<DashboardOutlined />}> Payment Approval
              <Tooltip title="Final Payment Approval">
                <NavLink to={`/final-payment-approval`}></NavLink>
              </Tooltip>
            </Menu.Item>
          } */}

          {/* {userDetails?.roles?.find(role => role.name === "Document Uploader" || role.name === ROLENAME.CHM_Manager || role.name === ROLENAME.CHM_User || role.name === ROLENAME.FactoryManager || role.name === ROLENAME.FactoryCrmUser) &&
            <>
              <Menu.Item key="ready-to-dispatch" icon={<DashboardOutlined />}>Ready to dispatch Projects
                <Tooltip title="Ready to Dispatch Project">
                  <NavLink to={`/ready-to-dispatch-project`}></NavLink>
                </Tooltip>
              </Menu.Item>
            </>
          } */}

          {/* Sales Manager Role Side Menu */}
          {/* {sidebarItems.includes("Dashboard") && userDetails?.roles?.find(role => role.name === ROLENAME.SalesManager || role.name === ROLENAME.Admin || role.name === ROLENAME.MarketingManager || role.name === ROLENAME.AssistantSalesManager) &&
            <Menu.Item key="set-team-target" icon={<ReconciliationOutlined />}>Set Team Target <NavLink to="/set-team-target"></NavLink></Menu.Item>
          }            */}

         
          {/* {sidebarItems.includes("Finance") && <Menu.Item key="finance" icon={<BankOutlined />}>
            Finance
            <NavLink to="/finance"></NavLink>
          </Menu.Item>} */}
          {/* {sidebarItems.includes("Scoping") && <Menu.Item key="scoping" icon={<DiffOutlined />}>
            Scoping
            <NavLink to="/scoping"></NavLink>
          </Menu.Item>} */}
          {/* {(sidebarItems.includes("Bom") || userDetails.roles?.find(role => role.name === ROLENAME.FinanceManager)) && <SubMenu key="Bom" icon={<CalculatorOutlined />} title="BOM">
            <Menu.Item key="bom">
              New/ View BOM
              <NavLink to="/bom"></NavLink>
            </Menu.Item> */}
            {/* {userDetails.roles?.find(role => role.name === "BOM Manager" || role.name === "Operation Head") && <Menu.Item key="materialsForApproval">
              Approve Bom Material
              <NavLink to="/bom/materialsForApproval"></NavLink>
            </Menu.Item>}
          </SubMenu>
          } */}
          {/* {
            sidebarItems.includes("CompanyP&L") &&
            <SubMenu key="companyP&L" icon={<ReadOutlined />} title="Company P&L">
              <Menu.Item key="financeOverview">Overview<NavLink to="/companyP&L/financeOverview"></NavLink></Menu.Item>
              {
                userDetails?.roles?.find(role => role.name === "Operation Head") &&
                <Menu.Item key="approvePoSoMtr">Approve PO/SO/MTR<NavLink to="/companyP&L/approvePoSoMtr"></NavLink></Menu.Item>
              }
              {
                userDetails?.email === PAYMENT_APPROVER &&
                <Menu.Item key="approvePayments">Approve Payments<NavLink to="/companyP&L/approvePayments"></NavLink></Menu.Item>
              }
              <Menu.Item key="ProjectInwarding"><NavLink to="/companyP&L/inwarding"></NavLink>Inwarding</Menu.Item>
              <Menu.Item key="OpenPo"><NavLink to="/companyP&L/openPo"></NavLink>Open PO</Menu.Item>
            </SubMenu>
          } */}
          {/* {userDetails?.roles?.find((role) =>
            role.name === ROLENAME.ProcurementExecutive ||
            role.name === ROLENAME.OperationHead ||
            role.name === ROLENAME.Admin
          ) && (
            <Menu.Item key="Items Below MOQ" icon={<AddShoppingCartIcon />}>
            <NavLink to="/items-below-MOQ">Items Below MOQ</NavLink>
              </Menu.Item>
            )} */}

          {/* {userDetails?.roles?.find(
            (role) =>
              role.name === ROLENAME.FinanceExecutive ||
              role.name === ROLENAME.FinanceManager ||
              role.name === ROLENAME.Admin
          ) && (
              <Menu.Item key="InwardingInvoices" icon={<ReadOutlined />}>
                <NavLink to="/inwarding-invoices">Inwarding Invoices</NavLink>
              </Menu.Item>
            )} */}
          {/* {userDetails?.roles?.find(role => role.name === ROLENAME.FinanceUser || role.name === ROLENAME.Execution_Manager || role.name === "Vendor Manager" || role.name === "Vendor Executive") && <SubMenu key="companyP&L" icon={<ReadOutlined />} title="Company P&L">
            <Menu.Item key="financeOverview">Overview<NavLink to="/companyP&L/financeOverview"></NavLink></Menu.Item>
          </SubMenu>
          } */}

          {/* {sidebarItems.includes("ReturnMTR") && <SubMenu key="MTR" icon={<ReadOutlined />} title="Return MTR">
            <Menu.Item key="returnMTR">Request Return<NavLink to="/material_return"></NavLink></Menu.Item>
            {userDetails?.roles?.find(role => role.name === "Operation Head") && <Menu.Item key="approveMTRreturn">Approve Return<NavLink to="/approve_material_return"></NavLink></Menu.Item>}
          </SubMenu>} */}

          {/* {(sidebarItems.includes("Store") || userDetails?.roles?.find(role => role.name === "Store Manager")) && <SubMenu key="store" icon={<ShopOutlined />} title="Store">
            <Menu.Item key="storeStocks">Stocks<NavLink to="/store/stocks"></NavLink></Menu.Item>
            <Menu.Item key="storePayment">Payment<NavLink to="/store/payments"></NavLink></Menu.Item>
            <Menu.Item key="storeInwarding"><NavLink to="/store/inwarding"></NavLink>Inwarding</Menu.Item>
            <Menu.Item key="storeOutwarding"><NavLink to="/store/outwarding"></NavLink>Outwarding</Menu.Item>
          </SubMenu>}
          {(sidebarItems.includes("Factory") || userDetails?.roles?.find(role => role.name === "Production Manager"|| role.name ==="Factory Manager")) && <SubMenu key="factory" icon={<ShoppingCartOutlined />} title="Factory">
            <Menu.Item key="factoryStocks">Stocks<NavLink to="/factory/stocks"></NavLink></Menu.Item>
            <Menu.Item key="factoryPayment">Payment<NavLink to="/factory/payments"></NavLink></Menu.Item>
            <Menu.Item key="factoryInwarding"><NavLink to="/factory/inwarding"></NavLink>Inwarding</Menu.Item>
            <Menu.Item key="factoryOutwarding"><NavLink to="/factory/outwarding"></NavLink>Outwarding</Menu.Item>
          </SubMenu>} */}
          {/* {sidebarItems.includes("MaterialTransfer") && <Menu.Item key="materialTransfer" icon={<UserOutlined />}>
            Material Transfer
            <NavLink to="/materialTransfer"></NavLink>
          </Menu.Item>} */}
          {/* {(userDetails?.roles?.find(role => role.name === ROLENAME.Admin || role.name === ROLENAME.LnDManager || role.name === ROLENAME.LnDUser)) && <SubMenu key="userManagement" icon={<UserOutlined />} title="User Management">
            <Menu.Item key="users">Users<NavLink to="/userManagement/users"></NavLink></Menu.Item>
            <Menu.Item key="department">Departments<NavLink to="/userManagement/departments"></NavLink></Menu.Item>
            <Menu.Item key="team">Teams<NavLink to="/userManagement/teams"></NavLink></Menu.Item>
            <Menu.Item key="roles">Roles<NavLink to="/userManagement/roles"></NavLink></Menu.Item>
          </SubMenu>} */}
          {/* {sidebarItems.includes("Vendor") && <SubMenu key="vendorManagement" icon={<UserOutlined />} title="Vendor Management">
            <Menu.Item key="vendors">Vendors<NavLink to="/vendorManagement/vendors"></NavLink></Menu.Item>
            {userDetails.roles.find(role => role.name === ROLENAME.OperationHead) && <Menu.Item key="approveVendorsAndMaterialMapping">Approve Vendor And Material Mapping<NavLink to="/vendorManagement/approveVendorsAndMaterialMapping"></NavLink></Menu.Item>}
          </SubMenu>} */}
          {/* {sidebarItems.includes("Vendor") && <Menu.Item key="vendorManagement" icon={<UserOutlined />}>
            Vendor Management
            <NavLink to="/vendorManagement/vendors"></NavLink>
          </Menu.Item>} */}
          {/* {sidebarItems.includes("Dashboard") && userDetails?.roles?.find(role => role.name === ROLENAME.Admin) && <SubMenu key="masterManagement" icon={<DashboardOutlined />} title="Master Management">
            <Menu.Item key="manageCategory">Manage Category<Tooltip title="Manage Category"><NavLink to="/masterManagement/manageCategory"></NavLink></Tooltip></Menu.Item>
            <Menu.Item key="manageSubCategory">Manage Sub Category<Tooltip title="Manage Sub Category"><NavLink to="/masterManagement/manageSubCategory"></NavLink></Tooltip></Menu.Item>
            <Menu.Item key="manageScope">Manage Scopes<Tooltip title="Manage Scopes"><NavLink to="/masterManagement/manageScope"></NavLink></Tooltip></Menu.Item>
            <Menu.Item key="manageUnit">Manage Unit<Tooltip title="Manage Unit"><NavLink to="/masterManagement/manageUnit"></NavLink></Tooltip></Menu.Item>
            <Menu.Item key="manageItem">Manage Materials<Tooltip title="Manage Materials"><NavLink to="/masterManagement/manageItems"></NavLink></Tooltip></Menu.Item >
            <Menu.Item key="manageOrgRate">Manage Org Rates<Tooltip title="Manage Org Rates"><NavLink to="/masterManagement/manageOrgRate"></NavLink></Tooltip></Menu.Item >
            <Menu.Item key="manageProjectRate">Manage Project Rates<Tooltip title="Manage Project Rates"><NavLink to="/masterManagement/manageProjectRate"></NavLink></Tooltip></Menu.Item >
            <Menu.Item key="marketing-sources">Manage Markting Sources<Tooltip title="Manage Markting Sources"><NavLink to="/masterManagement/marketing-sources"></NavLink></Tooltip></Menu.Item >
            <Menu.Item key="set-marketing-budget">Manage Markting Budget<Tooltip title="Manage Markting Budget"><NavLink to="/set-marketing-budget"></NavLink></Tooltip></Menu.Item >
            <Menu.Item key="manage-discount">Manage Discount<Tooltip title="Manage Discount"><NavLink to="/manage-discount"></NavLink></Tooltip></Menu.Item>
            <Menu.Item key="add-checklist">Manage CheckList<Tooltip title="Manage CheckList"><NavLink to="/add-checklist"></NavLink></Tooltip></Menu.Item>
            <Menu.Item key="managePaymentModes">Manage Payment Modes<Tooltip title="Manage Payment Modes"><NavLink to="/masterManagement/managePaymentModes"></NavLink></Tooltip></Menu.Item>
          </SubMenu >}
          {sidebarItems.includes("Dashboard") && userDetails?.roles?.find(role => role.name === ROLENAME.MaterialManager) && <SubMenu key="masterManagement2" icon={<DashboardOutlined />} title="Master Management">
            <Menu.Item key="manageItem2">Manage Materials<Tooltip title="Manage Materials"><NavLink to="/masterManagement/manageItems"></NavLink></Tooltip></Menu.Item >
          </SubMenu >} */}

         
{/* 
          {
            // sidebarItems.includes("Dashboard") && userDetails?.roles?.find(role => role.name === ROLENAME.Admin) &&
            sidebarItems.includes("Dashboard") && userDetails?.roles?.find(role => role.name === ROLENAME.CenterHead || role.name === ROLENAME.DGM_Sales || role.name === ROLENAME.DGM_Design) &&
            <Menu.Item key="discount-approval" icon={<DashboardOutlined />}> Discount Approval <NavLink to="/discount-approval"></NavLink> </Menu.Item>
          } */}
          {/* {
            userDetails?.roles?.find(role => role.name === ROLENAME.Admin || role.name === ROLENAME.FinanceManager || role.name === "Vendor Manager") &&
            <Menu.Item key="payment-approver-dashboard" icon={<DashboardOutlined />}> Payment Approver Dashboard<NavLink to="/payment-approver"></NavLink> </Menu.Item>
          } */}

          {/* { userDetails?.roles?.find(role => role.name === ROLENAME.Admin || role.name === ROLENAME.CenterHead || role.name === ROLENAME.BusinessHead) &&
            <>
              <Menu.Item key="customer-login-activity" icon={<DashboardOutlined />}>Customer Login Activity<NavLink to="/customer-login-activity"/></Menu.Item>
            </>
          } */}

          {/* {(sidebarItems.includes("VendorPOSO") || sidebarItems.includes("Finance")) && <Menu.Item key="vendor_po" icon={<UserOutlined />}>
            Vendor POs/SOs
            <NavLink to="/vendor_po"></NavLink>
          </Menu.Item>} */}

          {/* {(userDetails?.roles?.find(role => role.name === ROLENAME.Admin || role.name === ROLENAME.VendorSOManager || role.name === ROLENAME.VendorSOExecutive || role.name === ROLENAME.OperationManager)) &&
            <Menu.Item key="vendor-so-manage" icon={<DashboardOutlined />}> Manage Vendor SO <NavLink to="/vendor-so-manage"></NavLink> </Menu.Item>
          } */}

    {/* {(!userDetails?.roles?.find(role => role.name === ROLENAME.DataAnalyst)) &&
        (
          <>

          {<SubMenu key="chmMenu" icon={<SupervisedUserCircleIcon />} title="Ticket">
          {
           userDetails?.roles?.find(role =>  role.name === ROLENAME.TeleCallerUser) && (
            <>
              <Menu.Item key="qtProjects" icon={<BarsOutlined />}>Project Search<NavLink to="/qtProjects"></NavLink></Menu.Item>
              <Menu.Item key="create-new-lead" icon={<DashboardOutlined />}>Create New Lead<NavLink to="/create-new-lead"></NavLink></Menu.Item>
              <Menu.Item key="CHMuserDashboard" icon={<AssignmentRoundedIcon />}>Assigned Call Center Tickets<NavLink to="/CHMuserDashboard"></NavLink> </Menu.Item>
              <Menu.Item key="CHM-Ticket-Creation" icon={<AddCircleOutlineRoundedIcon />}>Create Call Center Ticket<NavLink to="/CHM-Ticket-Creation"></NavLink> </Menu.Item>
              <Menu.Item key="CHM-Service-Ticket" icon={<FolderSharedIcon />}><a href="https://decorpot.raiseaticket.com/support/#/newticket" target="_blank" rel="noopener noreferrer">Create Service Ticket</a></Menu.Item>
              <Menu.Item key="To-Be-Called" icon={<DashboardOutlined />}>To Be Called<NavLink to={`/status-information/To-Be-Called/${moment().subtract(1, 'months').format('YYYY-MM-DD')}/${moment().add(1, 'days').format('YYYY-MM-DD')}/status/false`}></NavLink></Menu.Item>
              <Menu.Item key="On-Hold" icon={<DashboardOutlined />}>On Hold<NavLink to={`/status-information/Hold/${moment().subtract(1, 'months').format('YYYY-MM-DD')}/${moment().add(1, 'days').format('YYYY-MM-DD')}/status/false`}></NavLink></Menu.Item>
              <Menu.Item key="Lead-Returned-By-Sales" icon={<DashboardOutlined />}>Lead Returned by Sales<NavLink to={`/status-information/Lead-Returned-By-Sales/${moment().subtract(1, 'months').format('YYYY-MM-DD')}/${moment().add(1, 'days').format('YYYY-MM-DD')}/status/false`}></NavLink></Menu.Item>
            </>
           )}
           {
            userDetails?.roles.find((role) => role.name === ROLENAME.CHM_Manager || role.name === ROLENAME.CHM_User || role.name === ROLENAME.Admin ||  role.name === ROLENAME.TeleCallerManager || role.name === ROLENAME.OperationHead) && (
            <>
              {!userDetails?.roles?.find(role => role.name === ROLENAME.OperationHead) &&<Menu.Item key="qtProjects" icon={<BarsOutlined />}>Project Search<NavLink to="/qtProjects"></NavLink></Menu.Item>}
             {!userDetails?.roles?.find(role => role.name === ROLENAME.OperationHead) && <Menu.Item key="create-new-lead" icon={<DashboardOutlined />}>Create New Lead<NavLink to="/create-new-lead"></NavLink></Menu.Item>}
            { userDetails?.roles.find((role) => role.name === ROLENAME.CHM_Manager || role.name === ROLENAME.CHM_User || role.name === ROLENAME.Admin ||  role.name === ROLENAME.TeleCallerManager || role.name === ROLENAME.OperationHead) && 
                <Menu.Item key="CHMuserDashboard" icon={<AssignmentRoundedIcon />}>Assigned Call Center Tickets<NavLink to="/CHMuserDashboard"></NavLink> </Menu.Item>
              }
                  {
                  userDetails?.roles?.find(role => role.name === ROLENAME.Admin || role.name === ROLENAME.CHM_Manager || role.name === ROLENAME.CHM_User ||  role.name === ROLENAME.TeleCallerManager) &&
                  <>
                    <Menu.Item key="CHM-Ticket-Creation" icon={<AddCircleOutlineRoundedIcon />}>Create Call Center Ticket<NavLink to="/CHM-Ticket-Creation"></NavLink> </Menu.Item>
                    <Menu.Item key="CHM-Service-Ticket" icon={<FolderSharedIcon />}><a href="https://decorpot.raiseaticket.com/support/#/newticket" target="_blank" rel="noopener noreferrer">Create Service Ticket</a></Menu.Item>
                  </>
                }
              
              {!userDetails?.roles?.find(role => role.name === ROLENAME.OperationHead) &&<Menu.Item key="To-Be-Called" icon={<DashboardOutlined />}>To Be Called<NavLink to={`/status-information/To-Be-Called/${moment().subtract(1, 'months').format('YYYY-MM-DD')}/${moment().add(1, 'days').format('YYYY-MM-DD')}/status/false`}></NavLink></Menu.Item>}
              {!userDetails?.roles?.find(role => role.name === ROLENAME.OperationHead) &&<Menu.Item key="On-Hold" icon={<DashboardOutlined />}>On Hold<NavLink to={`/status-information/Hold/${moment().subtract(1, 'months').format('YYYY-MM-DD')}/${moment().add(1, 'days').format('YYYY-MM-DD')}/status/false`}></NavLink></Menu.Item>}
              {!userDetails?.roles?.find(role => role.name === ROLENAME.OperationHead) && <Menu.Item key="Lead-Returned-By-Sales" icon={<DashboardOutlined />}>Lead Returned by Sales<NavLink to={`/status-information/Lead-Returned-By-Sales/${moment().subtract(1, 'months').format('YYYY-MM-DD')}/${moment().add(1, 'days').format('YYYY-MM-DD')}/status/false`}></NavLink></Menu.Item>}
            </>
           )}
           {
          userDetails?.roles?.find(role => role.name === ROLENAME.Admin || role.name === ROLENAME.TeleCallerManager || role.name === ROLENAME.CHM_Manager || role.name === ROLENAME.TeleCallerUser) && (
            <>
            <Menu.Item key="lead-criteria" icon={<LocalLibraryIcon />}>Lead Criteria<NavLink to="/lead-criteria"></NavLink></Menu.Item>
            <Menu.Item key="faq-details" icon={<LiveHelpIcon />}>FAQ<NavLink to="/faq-details"></NavLink></Menu.Item>
            </>
          )}
          { !userDetails?.roles.find((role) => role.name === ROLENAME.CHM_Manager || role.name === ROLENAME.CHM_User || role.name === ROLENAME.Admin || role.name === ROLENAME.TeleCallerUser ||  role.name === ROLENAME.TeleCallerManager || role.name === ROLENAME.OperationHead) && 
            <Menu.Item key="AssignedCHMticket" icon={<AssignmentRoundedIcon />}>Assigned Call Center Ticket<NavLink to="/CHMuserDashboard"></NavLink> </Menu.Item>
          }
         
          { userDetails?.roles.find((role) => role.name === ROLENAME.Admin) && 
            <Menu.Item key="ChmAddTicketCategory" icon={<CategoryRoundedIcon />}>Call Center Ticket Category<NavLink to="/ChmAddTicketCategory"></NavLink> </Menu.Item>
          }
          </SubMenu>}
          </>)} */}

          {/* Alredy removed/commented Menu Items */}

          {/* {userDetails?.roles?.find(role => role.name === ROLENAME.DesignHead) &&
            <Menu.Item key="payment-link-gen-approval" icon={<DashboardOutlined />}>Payment Link Genration Approval
              <Tooltip title="Payment Genration Link Approval">
              <NavLink to="/payment-genrate-link-approval"></NavLink>
              </Tooltip>
            </Menu.Item>
          }  */}

          {/* Start - Payment Integration */}
          {/* {userDetails?.roles?.find(role => role.name === ROLENAME.Admin || role.name === ROLENAME.FinanceManager) && <>
            <Menu.Item key="payment-info" icon={<FileDoneOutlined />}> Payment Details <NavLink to="/paymentDetails"></NavLink> </Menu.Item>
          </>} */}
          {/* {userDetails?.roles?.find(role => role.name === ROLENAME.Admin || role.name === ROLENAME.FinanceManager) && <>
             <Menu.Item key="hdfc-virtual-payment" icon={<AccountBalanceWalletIcon />}> HDFC_VPA <NavLink to="/hdfc-virtual-payment"></NavLink> </Menu.Item>
          </>} */}
          {/* End - Payment Integration */}

          {/* {
            sidebarItems.includes("Dashboard") && userDetails?.roles?.find(role => role.name === ROLENAME.Admin || role.name === ROLENAME.DesignHead || role.name === ROLENAME.DesignManager || role.name === ROLENAME.DesignUser || role.name === ROLENAME.SeniorOperationManager || role.name === ROLENAME.CHM_Manager || role.name === ROLENAME.CHM_User || role.name === ROLENAME.FinanceUser) &&
            <>
              <Menu.Item key="leads-design-to-execution" icon={<DashboardOutlined />}>Design to Execution Approval
                <Tooltip title="Design to Execution Approval">
                  <NavLink to={`/lead-design-to-execution/${moment().subtract(1, 'months').format('YYYY-MM-DD')}/${moment().add(1, 'days').format('YYYY-MM-DD')}`}></NavLink>
                </Tooltip>
              </Menu.Item>
            </>
          } */}

          {/* {
            sidebarItems.includes("Dashboard") && userDetails?.roles?.find(role => role.name === ROLENAME.SeniorOperationManager) &&
            <>
              <Menu.Item key="qtProjects" icon={<DashboardOutlined />}> Design Stage Leads <NavLink to="/qtProjects"></NavLink> </Menu.Item>
              <Menu.Item key="lead-design-to-execution" icon={<DashboardOutlined />}>Design to Execution Approval
                <Tooltip title="Design to Execution Approval">
                  <NavLink to={`/lead-design-to-execution/${moment().subtract(1, 'months').format('YYYY-MM-DD')}/${moment().add(1, 'days').format('YYYY-MM-DD')}`}></NavLink>
                </Tooltip>
              </Menu.Item>
            </>
          } */}
          {/* <Menu.Item key="CHMuserDashboard" icon={<AssignmentRoundedIcon />}>CHM Team Dashboard<NavLink to="/CHMuserDashboard"></NavLink> </Menu.Item> */}

                    {/* {
            sidebarItems.includes("Dashboard") && userDetails?.roles?.find(role => role.name === ROLENAME.CenterHead || role.name === ROLENAME.BusinessHead || role.name === ROLENAME.DesignHead || role.name === ROLENAME.Admin)
            && <Menu.Item key="dashboard-stats" icon={<EqualizerIcon />}><NavLink to="/designstats">Statistics Report</NavLink></Menu.Item>
          } */}
           {/* <Menu.Item key="dashboard-stats-design-sign-off"><NavLink to="/designstats/design-sign-off">Design Sign Off</NavLink></Menu.Item> */}
                {/* <Menu.Item key="dashboard-stats-material-delivery"><NavLink to="/designstats/material-delivery"></NavLink>Material Delivery</Menu.Item> */}
                {/* <Menu.Item key="dashboard-stats-project-completion"><NavLink to="/designstats/project-completion">Project Completion</NavLink></Menu.Item> */}
                {/* <Menu.Item key="dashboard-stats"><NavLink to="/designstats">Statistics Report</NavLink></Menu.Item> */}

                          {/* {sidebarItems && <Menu.Item key="set-marketing-budget" icon={<DashboardOutlined />}>
            Maketing Budget
            <NavLink to="/set-marketing-budget"></NavLink>
          </Menu.Item>} */}


         {/* Alredy removed/commented Menu Items */}

         {/* Removed As per requirement*/}

          {/* {
            sidebarItems.includes("Dashboard") && userDetails?.roles?.find(role => role.name === ROLENAME.Admin || role.name === ROLENAME.SeniorOperationManager || role.name === ROLENAME.OperationHead || role.name === ROLENAME.FactoryManager || role.name === ROLENAME.Store_Manager || role.name === ROLENAME.BOMManager || role.name === ROLENAME.Execution_Manager) &&
            <Menu.Item key="opsOne" icon={<EqualizerIcon />}>Ops-1 Dashboard
              <NavLink to={`/operation_one`}></NavLink>
            </Menu.Item>
          } */}

                    {/* {
            sidebarItems.includes("Dashboard") && userDetails?.roles?.find(role => role.name === ROLENAME.FactoryManager || role.name === ROLENAME.Admin) &&
            <>
              <Menu.Item key="Working-Drawing-Received" icon={<DashboardOutlined />}>
                <Tooltip title="Working Drawing Received">
                  <NavLink to={'/Working-Drawing-Received'}>Working Drawing Received</NavLink>
                </Tooltip>
              </Menu.Item>
            </>
          } */}
{/* 
{
            sidebarItems.includes("Dashboard") && userDetails?.roles?.find(role => role.name === ROLENAME.Admin || role.name === ROLENAME.MarketingManager) && <SubMenu key="manageCustomer" icon={<DashboardOutlined />} title="Customers">
              {userDetails?.roles?.find(role => role.name === ROLENAME.Admin || role.name === ROLENAME.MarketingManager) && <Menu.Item key="manager-customer">Manage Customer<NavLink to="/customer/manager-customer"></NavLink></Menu.Item>}
              {userDetails?.roles?.find(role => role.name !== ROLENAME.MarketingManager) && <Menu.Item key="moveToDesgin">Move To Design<NavLink to="/customer/move-to-design"></NavLink></Menu.Item>}
              {userDetails?.roles?.find(role => role.name !== ROLENAME.MarketingManager) && <Menu.Item key="assingProject">Assign Project<NavLink to="/customer/assign-lead"></NavLink></Menu.Item>}
            </SubMenu>
          } */}

           {/* {sidebarItems.includes("UploadDoc") && <Menu.Item key="projectDocuments" icon={<UploadOutlined />}>
            Upload Documents
            <NavLink to="/uploadProjectDocuments"></NavLink>
          </Menu.Item>} */}

         {/* Removed As per requirement*/}

         

        </Menu >
      </Sider >
      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: 0, color: "white" }}>
          {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: 'trigger',
            onClick: toggle,
          })}
          <div style={{ display: "flex", justifyContent: "space-between", marginRight: 20 }}>

          <div style={{ display: "flex"}}>
            
              <span>Days Remaining to change password: {daysRemainingToChangePassword}</span>
              
              <Button className={classes.changePasswordButton} onClick={() => setChangePasswordModal(true)} variant="contained">
                Click to Change
              </Button>
          
          </div>
            <FormControl style={{ width: 150, height: 20, marginRight: 20, marginBottom: 20, marginTop: 15 }}>
              <Box
                component="form"
                sx={{
                  "& > :not(style)": { width: "15ch" }
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  inputProps={{ className: classes.input }}
                  InputLabelProps={{ className: classes.inputLabel }}
                  SelectProps={{ classes: { icon: classes.icon } }}
                  select
                  labelId="demo-simple-select-label"
                  id="demo-expcenter-simple-select"
                  name="city"
                  value={defaultLocation}
                  label="Location"
                  onChange={(e) => getExpCenterByCity(e.target.value)}
                  size="small"
                >
                  {
                    locations?.map((data, i) => (
                      <MenuItem value={data._id} key={i + 1}>{data.name}</MenuItem>
                    ))
                  }
                </TextField>
              </Box>
            </FormControl>
            <FormControl style={{ width: 150, height: 20, marginRight: 100, marginBottom: 20, marginTop: 15 }}>
              <Box
                component="form"
                sx={{
                  "& > :not(style)": { width: "15ch" }
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  inputProps={{ className: classes.input }}
                  InputLabelProps={{ className: classes.inputLabel }}
                  SelectProps={{ classes: { icon: classes.icon } }}
                  select
                  labelId="demo-simple-select-label"
                  id="demo-expcenter-simple-select"
                  name="experienceCenterId"
                  value={defaultExpCenter}
                  label="Exp Center"
                  onChange={(e) => handleexpcenter(e)}
                  size="small"
                >
                  {
                    expcenters?.map((data, i) => (
                      <MenuItem value={data._id} key={i + 1}>{data.name}</MenuItem>
                    ))
                  }
                </TextField>
              </Box>
            </FormControl>

            <div style={{ marginRight: 10, marginTop: 5 }}>{userDetails?.name} </div>
            <div style={{ cursor: "pointer" }} >
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreVertIcon style={{ color: "#fff", marginTop: 5 }} />
              </IconButton>
              <MaterialMenu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: '20ch',
                  },
                }}
              >
                <MenuItem key={'changePassword'} onClick={() => setChangePasswordModal(true)
                }>
                  Change password
                </MenuItem>
                <MenuItem key={'logout'} onClick={logout}>
                  Logout
                </MenuItem>
              </MaterialMenu>
            </div>
          </div>
        </Header>
        <Content
          className="site-layout-background"
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 280,
            color: "black !important",
            overflow: "auto"
          }}
        >
          <Routes {...props} />
        </Content>
      </Layout>
      <Dialog
        open={changePasswordModal}
        TransitionComponent={Transition}
        keepMounted
        fullWidth={true}
        maxWidth={'xs'}
        onClose={() => setChangePasswordModal(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Change Password"}</DialogTitle>
        <DialogContent>
          <div style={{ display: "flex", flexDirection: "column", }}>
          
            <TextField style={{ marginBottom: 10 }} label="Current Password" name="currentPassword" variant="outlined" type="password" value={password.currentPassword} onChange={changeHandler} />
            
            {password.newPassword.length > 0 && isPasswordShort && <p style={{color: 'red'}}>*Password must be at least 8 characters long</p>}
            
            <TextField style={{ marginBottom: 10, marginTop: 5 }} label="New Password" name="newPassword" variant="outlined" type="password" value={password.newPassword} onChange={changeHandler} />
            
            {/* <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10, marginTop: 5 }}>
              <TextField style={{ flex: '0 0 90%' }}  label="New Password" name="newPassword" variant="outlined" type={showPassword ? 'text' : 'password'} value={password.newPassword} onChange={changeHandler} />
              <EyeFilled style={{ cursor: 'pointer', marginLeft: 8, fontSize: 25, color: showPassword ? 'blue' : 'black'}} onClick={() => togglePasswordVisibility()} />
            </div> */}
            
            {password.newPassword.length > 0 && <p style={{color: passwordStrengthColor, fontWeight: 900, marginBottom: 5}}>{passwordStrength}</p>}
            
            {password.confirmPassword.length > 0 && !isConfirmPasswordMatched && <p style={{color: 'red'}}>*Passwords do not match</p>}
            
            <TextField style={{ marginBottom: 10, marginTop: 5 }} label="Confirm Password" name="confirmPassword" variant="outlined" type="password" value={password.confirmPassword} onChange={changeHandler} />
            
            {
              password.newPassword.length > 0 &&
              <>

                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  
                  <div style={{ display: 'flex', alignItems: 'center', marginRight: 20 }}>
                    <p style={{ marginRight: 10, fontSize: 14 }}>Atleast 1 Uppercase</p>
                    {isPasswordValidated.hasUpperCase ? <CheckCircleIcon style={{ verticalAlign: 'middle', color: 'green', fontSize: 30 }} /> : <span style={{color: 'red'}}>: No</span>}
                  </div>

                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p style={{ marginRight: 10, fontSize: 14 }}>Atleast 1 Lowercase</p>
                    {isPasswordValidated.hasLowerCase ? <CheckCircleIcon style={{ verticalAlign: 'middle', color: 'green', fontSize: 30 }} /> : <span style={{color: 'red'}}>: No</span>}
                  </div>

                </div>

                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                  
                  <div style={{ display: 'flex', alignItems: 'center', marginRight: 20 }}>
                    <p style={{ marginRight: 10, fontSize: 14 }}>Atleast 1 Number</p>
                    {isPasswordValidated.hasNumber ? <CheckCircleIcon style={{ verticalAlign: 'middle', color: 'green', fontSize: 30 }} /> : <span style={{color: 'red'}}>: No</span>}
                  </div>
                  
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p style={{ marginRight: 10, fontSize: 14 }}>Atleast 1 Special Character</p>
                    {isPasswordValidated.hasSpecialChar ? <CheckCircleIcon style={{ verticalAlign: 'middle', color: 'green', fontSize: 30 }} /> : <span style={{color: 'red'}}>: No</span>}
                  </div>
                
                </div>

              </>
            
            }
            
          </div>
        </DialogContent>
        <DialogActions>
          
          <Button onClick={() => handleCancelForChangePassword()} color="primary">
            Cancel
          </Button>
          
          <Button onClick={changePassword} color="primary" disabled={password.currentPassword == "" || password.newPassword == "" || password.confirmPassword == "" || isPasswordShort || !isConfirmPasswordMatched || ['Very Weak', 'Weak'].includes(passwordStrength)}>
            Proceed
          </Button>
        
        </DialogActions>
      </Dialog>
    </Layout >
  )
})

export default connect(null, { setLoading, setSnackInfo, setUserDetails })(Sidebar)