import React, { useState, useEffect, useRef } from 'react'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import HttpService from '../../services/httpService';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import Tooltip from '@material-ui/core/Tooltip';
import BomTable from '../../components/bomTable';
import XLSX from 'xlsx';
import { connect } from "react-redux";
import { setLoading, setSnackInfo } from '../../redux/actions/appAction'
import { getApprovedProjects } from '../../redux/actions/financeAction'
import { useSelector } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { CSVLink } from "react-csv";

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
  table: {
    minWidth: 650,
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#ccc",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const BOM = React.memo((props) => {

  const classes = useStyles();
  const { approvedProjects } = useSelector(state => state.finance)
  const { userDetails } = useSelector(state => state.user)
  const [selectedProject, setSelectedProject] = useState({})
  const [showCreateButton, setShowCreateButton] = useState(false)
  const [bomPendingForApproval, setBomPendingForApproval] = useState([])
  const [show,setShow] = useState(false)
  const [mounted, setMounted] = useState(false)
  const [viewBomTable, setViewBomTable] = useState(false)
  const [viewBomTablePendingApproval, setViewBomTablePendingApproval] = useState(false);

  useEffect(() => {
    getApprovedProjects()
    // eslint-disable-next-line
  }, [])

  const getApprovedProjects = async () => {
    try {
      props.setLoading(true)
      await props.getApprovedProjects()
      if (userDetails.roles.find(role => role.name === "Operation Head" || role.name === "BOM Manager")) {
        const result = await HttpService.getBomPendingForApproval()
        setBomPendingForApproval(result.data)
      }
      props.setLoading(false)
    } catch (error) {
      console.error(error)
      props.setLoading(false)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong ", "error")
    }
  }
  const [projectStatus,setProjectStatus] = useState(null)
  const handleSelect = async (event, value) => {
    if (value) {
      if (!value.searchName)
        value.searchName = `${value.code}_${value.clientName}`
      setSelectedProject(value)
      setProjectStatus(value?.leadId?.currentStage)
      setShowCreateButton((userDetails.roles.find(role => role.name === "Factory Bom" || role.name === "Site Bom")))
      setShowBomTable(false)
      setViewBomTable(false)
      getBom(value?._id)
    }
  }
  const [csvData, setCsvData] = useState([])
  const [filename, setFilename] = useState({});
  const buttonRef = useRef(null);
  const headers = [
    { key: 'selectedRoom', label: 'Selected Room' },
    { key: 'selectedScope', label: 'Selected Scope' },
    { key: 'selectedSubCategory', label: 'Selected Sub Category' },
    { key: 'selectedMaterial', label: 'Selected Material' },
    { key: 'uom', label: 'UOM' },
    { key: 'quantity', label: 'Quantity' }
  ];

  const sampleData = [
    {
      selectedRoom: 'Sample Room',
      selectedScope: 'Sample Scope',
      selectedSubCategory: 'Sample SubCategory',
      selectedMaterial: 'Sample Material',
      uom: 'Sample Unit',
      quantity: "Sample Qunatity"
    },
  ];

  const downloadCSVBom = async (event, projectId, type) => {
    console.log(projectId)
    setFilename({ code: projectId.code, name: projectId.clientName })
    props.setLoading(true)
    try {
      let result
      const bomMaterialLists = [];
      if (type !== "Previous") {
        result = await HttpService.getSavedProjectMaterialForBom(projectId?._id);
      } else {
        result = await HttpService.getPreviousBomForProject(projectId?._id)
      }
      props.setLoading(false)
      if (result?.data?.materials.length > 0) {
        result.data.materials.forEach((ele) => {
          bomMaterialLists.push({
            selectedRoom: ele.spaceNameId.name,
            selectedScope: ele.scopeId.name,
            selectedSubCategory: ele.subCategoryId.name,
            selectedMaterial: ele.materialId.name.replace(/"/g, '""'),
            uom: ele.unitType.name,
            quantity: ele.quantity
          });
        });
      }
      setCsvData(bomMaterialLists)
      setTimeout(() => {
        buttonRef.current.link.click();
      });
    } catch (error) {
      props.setLoading(false)
      props.setSnackInfo('Error fetching previous data', 'error')
    }
  }

  const getBom = async (projectId) => {
    props.setLoading(true)
    try {
      setShow(true)
      const result = await HttpService.getSavedProjectMaterialForBom(projectId)
      props.setLoading(false)
      if (result?.data?.materials.length > 0) {
        setShowBomTable(true)
        props.setLoading(true)
      }
    } catch (error) {
      console.error(error)
      props.setLoading(false)
      props.setSnackInfo('Error fetching previous data', 'error')
    }
  }

  const [showBomTable, setShowBomTable] = useState(false)

  const uploadBom = (e) => {
    setShowBomTable(false)
    const file = e.target.files[0];
    if (file !== undefined) {
      props.setLoading(true)
      if (file.name.split('.').reverse()[0] === 'xlsx') {
        const reader = new FileReader();
        reader.onload = (evt) => {
          const bstr = evt.target.result;
          const workbook = XLSX.read(bstr, { type: "binary" });
          const sheetName = workbook.SheetNames[2];
          const worksheet = workbook.Sheets[sheetName];
          const data = XLSX.utils.sheet_to_csv(worksheet, { header: 1, defval: '' });
          const BomJsonData = convertToJson(data)
          uploadBomJson(BomJsonData)
        };
        reader.readAsBinaryString(file);
      } else {
        props.setSnackInfo('Please upload the file with correct format', "error")
        props.setLoading(false)
      }
    }

  }

  const uploadBomJson = async (data) => {
    try {
      
      const newArray = data.map((obj) => {
        const newObj = { ...obj }; // Create a new object to avoid modifying the original one
        if (newObj.hasOwnProperty("Select Material")) {
          newObj["Select Material"] = newObj["Select Material"]
            ?.toLowerCase()
            ?.replace(/\b\w/g, (match) => match.toUpperCase());
        }
        return newObj;
      });
      const result = await HttpService.uploadBomData(selectedProject._id, newArray)
      props.setSnackInfo("Upload success", "success")
      if (result.status === 201) {
        setMounted(!mounted)
        setShowBomTable(true)
      }
    } catch (error) {
      props.setLoading(false)
      error.response ? props.setSnackInfo(error.response.data, "error") : props.setSnackInfo('Error while uploading BOM', "error")
      console.error(error)
    }
  }

  const convertToJson = (csv) => {
    const lines = csv.split("\n");
    console.log(JSON.stringify(lines))
    const result = [];
    const headers = lines[0].split(",");
    for (let i = 1; i < lines.length; i++) {
      const obj = {};
      const currentline = lines[i].split(",");
      for (let j = 0; j < headers.length; j++) {
        if (currentline[j]?.includes('"')) {
          let arr = currentline[j].split('"')[1]
          currentline[j] = arr.concat('"')
        }
        obj[headers[j]] = currentline[j];
      }
      result.push(obj);
    }
    return result; //JSON
  }

  // Function to get list of boms  whose approval is pending
  const showBomApprovalPending = async () => {
    setViewBomTablePendingApproval(true);
    setViewBomTable(false);
  }
  
  // Check for valid role combination
  const hasInvalidRoleCombination = userDetails.roles.some(role =>
    (role.name === "BOM Executive" || role.name === "Site Bom") &&
    (userDetails.roles.some(innerRole => innerRole.name === "Operation Head" || innerRole.name === "BOM Manager"))
  );

  return (
    <>
      {userDetails.roles.find(role => role.name === "Operation Head" || role.name === "BOM Manager") ? <div style={{ fontSize: 18, marginBottom: 10 }}><b>View BOM</b></div> : <div style={{ fontSize: 18, marginBottom: 10 }}><b>Upload New BOM</b></div>}
      <div style={{display:"flex",flexDirection:"column"}}>
      <div style={{ marginBottom: 20 , display:"flex" }}>
        <Autocomplete
          value={selectedProject}
          onChange={handleSelect}
          id="bom"
          options={approvedProjects}
          getOptionLabel={(option) => option.searchName}
          style={{ width: 500 }}
          renderInput={(params) => <TextField {...params} label="Search Project" variant="outlined" />}
        />
        <div style ={{marginLeft : "30px"}}>
        <h2>{show ? ' ' + (projectStatus ? ('Project Status' +" :" +" " + projectStatus) : '') : ''}</h2>
        </div>
        </div>
      </div>
      {
        showCreateButton  && <div style={{ display: "flex" }}>
          <Button variant="contained" color="primary" style={{ background: "#716ACA", margin: 5 }} onClick={() => { setShowBomTable(true); setViewBomTable(false); setViewBomTablePendingApproval(false); }} disabled={projectStatus === "Completed"}>
            Create BOM
          </Button>
          <input
            accept="*"
            className={classes.input}
            id="contained-button-file"
            onChange={uploadBom}
            type="file"
            disabled={projectStatus === "Completed"}
          />
          <label htmlFor="contained-button-file">
            <Button variant="contained" color="primary" style={{ background: "#716ACA", margin: 5 }} component="span" disabled={projectStatus === "Completed"}>
              Upload Bom
            </Button>
          </label>
          {/* Download sample BOM */}
          <Tooltip title="Download sample BOM">
            <CSVLink
              data={sampleData}
              headers={headers}
              filename="sample_bom.xlsx"
              className="csv-download-button"
              target="_blank"
            >
              <IconButton color="primary">
                <DownloadOutlined />
              </IconButton>
            </CSVLink>
          </Tooltip>
          
        </div> 
      }
      {showBomTable && <BomTable MOUNTED={mounted} SELECTEDPROJECT={selectedProject} TYPE={"CREATE"} />}
      {
        !showBomTable && selectedProject?._id && <>
          <hr></hr>
          <div style={{ fontSize: 18, marginBottom: 10 }}><b>View Or Edit BOM</b></div>
        </>
      }
      {selectedProject?._id && <div style={{ display: "flex", marginBottom: 10 }}>
        <Button variant="contained" color="primary" style={{ background: "#716ACA", margin: 5 }} onClick={() => { setViewBomTable(true); setShowBomTable(false); setViewBomTablePendingApproval(false); }}>
          View Previous BOM
        </Button>
        {viewBomTable && <>
          <Button onClick={() => downloadCSVBom(null, selectedProject, "Previous")} variant="contained" color="primary" style={{ background: "#716ACA", margin: 5 }} >Download CSV<DownloadOutlined /></Button>
          <CSVLink headers={headers} filename={`${filename.code}-${filename.name}.csv`} data={csvData} style={{ color: "white" }} ref={buttonRef}> </CSVLink>
        </>
        }
      </div>
      }
      {selectedProject?._id && !hasInvalidRoleCombination && <div style={{ display: "flex", marginBottom: 10 }}>
        <Button variant="contained" color="primary" style={{ background: "#716ACA", margin: 5 }} onClick={showBomApprovalPending}>
          Bom Pending Approval
        </Button>
      </div>
      }
      {viewBomTablePendingApproval &&
        <>
          <p><b>Bom Approval Pending List</b></p>
          <BomTable SELECTEDPROJECT={selectedProject} TYPE={"VIEW-PENDING"} />
        </>
      }
      {viewBomTable && <BomTable SELECTEDPROJECT={selectedProject} TYPE={"VIEW"} />}

      {
        !showBomTable && userDetails.roles.find(role => role.name === "BOM Manager" || role.name === "Operation Head") && bomPendingForApproval?.length > 0 &&
        <>
          <div style={{ fontSize: 18, marginBottom: 10 }}><b>Pending for approval</b></div>

          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Sl No</StyledTableCell>
                  <StyledTableCell align="center">Project Code</StyledTableCell>
                  <StyledTableCell align="center">Client Name</StyledTableCell>
                  <StyledTableCell align="center" colSpan={2}>Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bomPendingForApproval?.map((data, i) => (
                  <StyledTableRow key={i + 1}>
                    <StyledTableCell component="th" scope="row">
                      {i + 1}
                    </StyledTableCell>
                    <StyledTableCell align="center">{data?.projectId?.code}</StyledTableCell>
                    <StyledTableCell align="center">{data?.projectId?.clientName}</StyledTableCell>
                    <StyledTableCell onClick={() => handleSelect(null, data?.projectId)} align="center"><button className='btn'>
                      View <EyeOutlined />
                    </button>
                    </StyledTableCell>
                    <StyledTableCell onClick={() => downloadCSVBom(null, data?.projectId)} align="center">
                      <button className='btn'>Download <DownloadOutlined /></button>
                    </StyledTableCell>
                    <CSVLink headers={headers} filename={`${filename.code}-${filename.name}.csv`} data={csvData} style={{ color: "white" }} ref={buttonRef}> </CSVLink>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      }
    </>
  )
})

export default connect(null, { setLoading, getApprovedProjects, setSnackInfo })(BOM);
