import React, { useEffect, useState } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import { setLoading, setSnackInfo } from '../../../redux/actions/appAction';
import HttpService from '../../../services/httpService';
import { Table, TableBody, TextField, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Dialog, MenuItem, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, Checkbox } from '@material-ui/core';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}
TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 600,
  },
  textField: {
    width: 500
  }
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#ccc",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const ManageOrgRate = (props) => {
  const classes = useStyles();

  useEffect(() => {
    getLocationList()
    // eslint-disable-next-line 
  }, [])

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rateCardMasterList, setRateCardMasterList] = useState([])
  const [locationList, setLocationList] = useState([])
  const [show,setShow] = useState(false)
  const [tabvalue, setValue] = useState(0);
  const tabName = ['Items', 'Finishes', 'Ply Type'];
  const [selectedDocType, setSelectedDocType] = useState('Product Master');
  const getLocationList = async () => {
    try {
      const response = await HttpService.getLocationList()
      setLocationList(response.data)
    } catch (error) {
      console.log(error)
      props.setSnackInfo('Error while fetching location list', "error")
    }
  }

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    let filterName = "";
    if (newValue === 0) {
      filterName = "ProductMaster"
      setSelectedDocType("Product Master")
    }
    if (newValue === 1) {
      filterName = "FinishTypeMaster"
      setSelectedDocType("Finish Type Master")
    }
    if (newValue === 2) {
      filterName = "PlyTypeMaster"
      setSelectedDocType("Ply Type Master")
    }
    const filterData = dupOrgCardList.filter(orgData => orgData.docType === filterName)
    setOrgRateCardList(filterData)
    let localRate = [...rate]
    filterData.map((orgRate, i) => {
      localRate[i] = orgRate.rate
      return null
    })
    setRate(localRate)
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };



  const [selectedRateCard, setSelectedRateCard] = useState({})
  const [orgRateCardList, setOrgRateCardList] = useState([])
  const [dupOrgCardList, setDupOrgCardList] = useState([])
  const [rate, setRate] = useState([])
  const [unitMasterList, setUnitMasterList] = useState([]);
  const [scopeMasterList, setScopeMasterList] = useState([]);

  const handleRateCardSelect = async (v) => {
    setSelectedRateCard(v)
    if (v?._id) {
      props.setLoading(true)
      try {
        const response = await HttpService.getOrgRateCardForRateCardMaster(v?._id)
        if (response.data.length === 0) {
          setOrgRateCardList([])
          setDupOrgCardList([])
        } else {
          setOrgRateCardList(response.data)
          setDupOrgCardList(response.data)
        }
        let localRate = [...rate]
        response.data.map((orgRate, i) => {
          localRate[i] = orgRate.rate
          return null
        })
        setRate(localRate)
      } catch (error) {
        console.error(error)
        props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
      }
      props.setLoading(false)
    } else {
      setOrgRateCardList([])
    }
  }
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, orgRateCardList.length - page * rowsPerPage)

  const getRate = (i) => {
    const item = rate[i + page * rowsPerPage]
    return item || ""
  }

  const handleRateChange = (e, i) => {
    let localRate = [...rate]
    localRate[i + page * rowsPerPage] = e.target.value
    setRate(localRate)
  }

  const updateOrgRateCard = async () => {
    props.setLoading(true)
    try {
      let orgRateCards = orgRateCardList.map((orgRate, i) => {
        return {
          itemId: orgRate.itemId,
          rate: rate[i]
        }
      })
      const response = await HttpService.updateOrgRateCardPrices(orgRateCards, selectedRateCard._id)
      props.setSnackInfo(response.data, "success")
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  const cloneOrgRateCard = async () => {
    props.setLoading(true)
    try {
      const response = await HttpService.cloneOrgRateCard(selectedRateCard._id, selectedLocation._id, markup)
      props.setSnackInfo('Rate Card Cloned', "success")
      getAllRateCardMaster(selectedLocation._id)
      setMarkup(0)
      handleRateCardSelect(response.data)
      setShow(false)
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  const makeDefault = async () => {
    props.setLoading(true)
    try {
      const response = await HttpService.makeDefault(selectedRateCard._id, selectedLocation._id)
      props.setSnackInfo('Rate card made default', 'success')
      handleRateCardSelect(response.data)
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  const searchHandler = (e) => {
    let list = dupOrgCardList.filter(orgRateCard => orgRateCard?.itemId.name.toLowerCase().includes(e.target.value.toLowerCase()))
    setOrgRateCardList(list)
    let localRate = [...rate]
    list.map((orgRate, i) => {
      localRate[i] = orgRate.rate
      return null
    })
    setRate(localRate)
  }

  const [markup, setMarkup] = useState(0)
  const [selectedLocation, setSelectedLocation] = useState({})

  const handleLocationChange = async (v) => {
    if (v?._id) {
      setSelectedLocation(v)
      getAllRateCardMaster(v?._id)
    } else {
      setRateCardMasterList([])
    }

  }
  const closedpop = () =>{
    setShow(false)
  }

  const getAllRateCardMaster = async (locationId) => {
    props.setLoading(true)
    try {
      const response = await HttpService.getLocationRateCardMasterList(locationId)
      const defRateCard = response.data.filter(ele => ele.isDefault === true);
      let lastElement = defRateCard.slice(-1);
      setRateCardMasterList(response.data)
      handleRateCardSelect(lastElement[0])
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  const cloneConfirm =  () => {
    setShow(true)
    }
    const closeConfirm = () =>{
      setShow(false)
    }

  const [openEditMaterialModal, setOpenEditMaterialModal] = useState(false);
  const [editMaterialField, setEditMaterialField] = useState({
    name: "",
    description: "",
    pricePerUnit: "",
    priority: "",
    unitId: "",
    subUnitType: "",
    scopeId: "",
    code: "",
    plyInclude: false,
    finishInclude: false
  });
  const { name, description, pricePerUnit, priority, unitId, subUnitType, scopeId, plyInclude, finishInclude, code } = editMaterialField;
  const [selectedAction, setSelectedAction] = useState('');
  const editButton = (selectedMaterial) => {
    fetchUnits();
    fetchScopeMaster();
    setOpenEditMaterialModal(true)
    setSelectedAction('edit');
    console.log(selectedMaterial, "Material", selectedRateCard._id, selectedLocation._id);
    setEditMaterialField({
      _id: selectedMaterial.itemId._id,
      name: selectedMaterial.itemId.name,
      code: selectedMaterial.itemId.code,
      description: selectedMaterial.itemId.description,
      pricePerUnit: selectedMaterial.itemId.pricePerUnit,
      priority: selectedMaterial.itemId.priority,
      unitId: selectedMaterial.itemId.unitId,
      subUnitType: selectedMaterial.itemId.subUnitType,
      scopeId: selectedMaterial.itemId.scopeId,
      plyInclude: selectedMaterial.itemId.plyInclude,
      finishInclude: selectedMaterial.itemId.finishInclude
    })
  }

  const fetchUnits = async () => {
    props.setLoading(true)
    try {
      const response = await HttpService.fetchUnits()
      setUnitMasterList(response.data)
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  const fetchScopeMaster = async () => {
    props.setLoading(true)
    try {
      const response = await HttpService.fetchScopeMaster()
      setScopeMasterList(response.data)
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  const addMaterail = () => {
    setOpenEditMaterialModal(true);
    setSelectedAction('add');
    fetchUnits();
    fetchScopeMaster();
  }

  const updateMaterialData = async () => {
    props.setLoading(true)
    try {
      let response = '';
      editMaterialField['docType'] = selectedDocType === 'Product Master' ? 'ProductMaster' : selectedDocType === 'Finish Type Master' ? 'FinishTypeMaster' : 'PlyTypeMaster';
      if (selectedAction === 'add') {
        response = await HttpService.addOrgRateCardMaterial(selectedRateCard._id, editMaterialField)
      } else {
        response = await HttpService.updateOrgRateCardMaterial(selectedRateCard._id, editMaterialField)
      }
      props.setSnackInfo(response?.data ?? "Material Updated Successfully", "success")
      setOpenEditMaterialModal(false)
      handleRateCardSelect(selectedRateCard)
      setEditMaterialField({
        name: "",
        description: "",
        pricePerUnit: "",
        priority: "",
        unitId: "",
        subUnitType: "",
        scopeId: "",
        code: "",
        plyInclude: false,
        finishInclude: false
      })
    } catch (error) {
      console.error(error.errmsg)
      props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  const setIsActiveItem = async (e, itemId) => {
    props.setLoading(true)
    let obj = {
      docType: selectedDocType,
      rateCardMasterId: selectedRateCard._id
    }
    try {
      await HttpService.inActiveProduct(itemId, obj)
      props.setSnackInfo("Updated Successfully", "success")
      handleRateCardSelect(selectedRateCard)
    } catch (error) {
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }
  const setIsComplimentaryItem = async (e, itemId) => {
    props.setLoading(true)
    let obj = {
      docType: selectedDocType,
      rateCardMasterId: selectedRateCard._id,
    }
    if (e.target.checked === true) {
      obj.isComplimentaryItem = true
      obj.isCHApproval = true
    } else if (e.target.checked === false) {
      obj.isComplimentaryItem = false
      obj.isCHApproval = false
    }
    try{
      await HttpService.isComplimentaryItem(itemId, obj)
      props.setSnackInfo("Updated Successfully", "success")
      handleRateCardSelect(selectedRateCard)
    } catch (error) {
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  return (
    <div>
      <div style={{ marginBottom: 10, fontSize: 18 }}><b>Manage Org Rate</b></div>
      <div style={{ marginBottom: 10 }}>
        <Autocomplete
          value={selectedLocation}
          id="tags-standard"
          options={locationList}
          onChange={(e, v) => handleLocationChange(v)}
          getOptionLabel={(option) => option.name}
          style={{ width: 500 }}
          renderInput={(params) => (
            <TextField {...params} label="Choose Location" variant="outlined" size='small' />
          )}
        />
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {selectedLocation?._id && <Autocomplete
          value={selectedRateCard}
          id="tags-standard"
          options={rateCardMasterList}
          onChange={(e, v) => handleRateCardSelect(v)}
          getOptionLabel={(option) => `${option.rateCardCode}__${moment(option.createdAt).format('DD-MM-YYYY')}`}
          style={{ width: 500 }}
          renderInput={(params) => (
            <TextField {...params} label="Choose Rate Card Code" variant="outlined" size='small' />
          )}
        />}
        {selectedRateCard?._id &&
          <>
            {!selectedRateCard.isDefault && <Button variant="contained" color="primary" onClick={makeDefault} style={{ background: "#716ACA", margin: 5 }} >
              Set as default
            </Button>}
            <TextField label="Mark Up in %" value={markup} onChange={(e) => setMarkup(e.target.value)} variant="outlined" size='small' />
            <Button variant="contained" color="primary" onClick={cloneConfirm} style={{ background: "#716ACA", margin: 5 }} >
              Clone Rate Cards
            </Button>
          </>
        }
      </div>
      {selectedRateCard?._id && <>
        <div style={{ marginBottom: 10, marginTop: 10 }}>
          <TextField label="Search" className={classes.textField} onChange={searchHandler} variant="outlined" size='small' />
        </div>
        <div style={{ marginBottom: 10, marginTop: 10 }}>
          <Button variant="contained" color="primary" onClick={() => addMaterail()} style={{ background: "#716ACA", margin: 5 }} >
            Add {selectedDocType} Item
          </Button>
        </div>
      </>}
      <div style={{ marginBottom: 10 }}></div>

      {Object.keys(selectedLocation).length !== 0 &&
        <Paper className={classes.root}>
          <AppBar position="static">
            <Tabs value={tabvalue} onChange={handleTabChange} aria-label="simple tabs example" centered>
              {
                tabName.map((tn, i) => (
                  <Tab label={tn} {...a11yProps(i)} />
                ))
              }
            </Tabs>
          </AppBar>
          {
            tabName.map((tn, i) => (
              <TabPanel value={tabvalue} index={i}>
                <TableContainer component={Paper}>
                  <Table className={classes.table} size="small" aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Sl No</StyledTableCell>
                        <StyledTableCell>Code</StyledTableCell>
                        <StyledTableCell>Name</StyledTableCell>
                        <StyledTableCell>Description</StyledTableCell>
                        <StyledTableCell>Rate</StyledTableCell>
                        <StyledTableCell>Priority</StyledTableCell>
                        <StyledTableCell>IsActive</StyledTableCell>
                        <StyledTableCell>IsComplimentary</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {orgRateCardList?.length > 0 ?
                        (rowsPerPage > 0
                          ? orgRateCardList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          : orgRateCardList
                        )?.map((data, i) => <StyledTableRow key={i + 1} component="th" scope="row">
                          <StyledTableCell component="th" scope="row">{i + 1}</StyledTableCell>
                          <StyledTableCell component="th" scope="row">{data?.itemId?.code}</StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                              <span> {data?.itemId?.name}</span>
                              <span style={{ cursor: "pointer" }} onClick={() => editButton(data)}> <EditIcon /></span>
                            </div>
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">{data?.itemId?.description}</StyledTableCell>
                          <TextField value={getRate(i)} type="number" onChange={(e) => handleRateChange(e, i)} fullWidth variant="outlined" size='small' />
                          <StyledTableCell component="th" scope="row">{data?.itemId?.priority}</StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={data?.isActive}
                                  onChange={(e) => setIsActiveItem(e, data?.itemId?._id)}
                                  name="isActive"
                                  color="primary"
                                />
                              }
                            />
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={data?.itemId?.isComplimentaryItem}
                                  onChange={(e) => setIsComplimentaryItem(e, data?.itemId?._id)}
                                  name="isComplimentaryItem"
                                  color="primary"
                                />
                              }
                            />
                          </StyledTableCell>
                        </StyledTableRow>)
                        :
                        <StyledTableRow key='no'>
                          <StyledTableCell colSpan={8} style={{ textAlign: 'center' }}>No Data Found</StyledTableCell>
                        </StyledTableRow>
                      }

                      {emptyRows > 0 && (
                        <StyledTableRow style={{ height: 53 * emptyRows }}>
                          <StyledTableCell colSpan={8} />
                        </StyledTableRow>
                      )}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[10, 20, 50, { label: 'All', value: -1 }]}
                          colSpan={8}
                          count={orgRateCardList.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          SelectProps={{
                            inputProps: { 'aria-label': 'rows per page' },
                            native: true,
                          }}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          ActionsComponent={TablePaginationActions}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
                <div style={{ display: "flex", flexDirection: "row-reverse", marginTop: 15 }} >
                  <Button variant="contained" color="primary" onClick={updateOrgRateCard} style={{ background: "#716ACA", margin: 5 }} >
                    Update
                  </Button>
                </div>
              </TabPanel>
            ))}
        </Paper>
      }

      <Dialog open={openEditMaterialModal} onClose={() => setOpenEditMaterialModal(false)} aria-labelledby="form-dialog-title" maxWidth='sm'>
        <DialogTitle id="form-dialog-title">{selectedAction === 'add' ? `Add` : `Edit`} {selectedDocType} Material</DialogTitle>
        <Divider />
        <DialogContent>
          <div style={{ paddingBottom: 15, paddingTop: 20 }}>
            <FormControl fullWidth >
              <TextField label="Material Name" size="small" value={name} style={{ marginBottom: 20 }} name="name" onChange={(e) => setEditMaterialField({ ...editMaterialField, [e.target.name]: e.target.value })} variant="outlined" />
            </FormControl>
            <FormControl fullWidth >
              <TextField label="Material Description" size="small" value={description} style={{ marginBottom: 20 }} name="description" onChange={(e) => setEditMaterialField({ ...editMaterialField, [e.target.name]: e.target.value })} variant="outlined" />
            </FormControl>
            <FormControl fullWidth >
              <TextField label="Material Price" size="small" value={pricePerUnit} style={{ marginBottom: 20 }} name="pricePerUnit" onChange={(e) => setEditMaterialField({ ...editMaterialField, [e.target.name]: e.target.value })} variant="outlined" />
            </FormControl>
            {
              selectedDocType === 'Product Master' &&
              <>
                <FormControl fullWidth >
                  <TextField label="Material Priority" size="small" value={priority} type="number" style={{ marginBottom: 20 }} name="priority" onChange={(e) => setEditMaterialField({ ...editMaterialField, [e.target.name]: e.target.value })} variant="outlined" />
                </FormControl>
                <FormControl fullWidth >
                  <TextField label="Material Code" size="small" value={code} style={{ marginBottom: 20 }} name="code" onChange={(e) => setEditMaterialField({ ...editMaterialField, [e.target.name]: e.target.value })} variant="outlined" />
                </FormControl>
              </>
            }
            {
              (selectedDocType === 'Product Master' || selectedDocType === 'Finish Type Master') &&
              <FormControl fullWidth >
                <TextField
                  id="outlined-select-currency"
                  select
                  value={unitId}
                  label="Select Unit"
                  name="unitId"
                  onChange={(e) => setEditMaterialField({ ...editMaterialField, [e.target.name]: e.target.value })}
                  variant="outlined"
                  size="small"
                  style={{ marginBottom: 20 }}
                >
                  {
                    unitMasterList?.map((data, i) => (
                      <MenuItem value={data._id} key={i + 1}>{data.name}</MenuItem>
                    ))
                  }
                </TextField>
              </FormControl>
            }
            {
              selectedDocType === 'Product Master' && <>
                <FormControl fullWidth >
                  <TextField
                    id="outlined-select-currency"
                    select
                    value={subUnitType}
                    label="Select subUnitType"
                    name="subUnitType"
                    onChange={(e) => setEditMaterialField({ ...editMaterialField, [e.target.name]: e.target.value })}
                    variant="outlined"
                    size="small"
                    style={{ marginBottom: 20 }}
                  >
                    <MenuItem value="Area" key="1">Area</MenuItem>
                    <MenuItem value="Qty" key="2">Quantity</MenuItem>
                  </TextField>
                </FormControl>
                <FormControl fullWidth >
                  <TextField
                    id="outlined-select-currency"
                    select
                    value={scopeId}
                    label="Select Scope"
                    name="scopeId"
                    onChange={(e) => setEditMaterialField({ ...editMaterialField, [e.target.name]: e.target.value })}
                    variant="outlined"
                    size="small"
                    style={{ marginBottom: 20 }}
                  >
                    {
                      scopeMasterList?.map((data, i) => (
                        <MenuItem value={data._id} key={i + 1}>{data.name}</MenuItem>
                      ))
                    }
                  </TextField>
                </FormControl>
                <FormControl >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={plyInclude}
                        onChange={(e) => setEditMaterialField({ ...editMaterialField, [e.target.name]: e.target.checked })}
                        name="plyInclude"
                        color="primary"
                      />
                    }
                    label="Include ply price"
                  />
                </FormControl>
                <FormControl >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={finishInclude}
                        onChange={(e) => setEditMaterialField({ ...editMaterialField, [e.target.name]: e.target.checked })}
                        name="finishInclude"
                        color="primary"
                      />
                    }
                    label="Include finish price"
                  />
                </FormControl>
              </>}
          </div>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={() => setOpenEditMaterialModal(false)} variant="outlined" color="secondary">
            Cancel
          </Button>
          <Button onClick={() => updateMaterialData()} variant="contained" color="primary">
            {selectedAction === 'add' ? `Add` : `Update`} {selectedDocType} Material
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={show} maxWidth="lg">
      <DialogContent style={{height:"50px",marginBottom:"12px",padding:"20px"}}><h2>Do you want to Confirm</h2></DialogContent>
      <div style={{display:"flex",justifyContent:"flex-end",marginTop:"30px",margin:"5px", margin:"auto"}}>
        <div style={{marginLeft:"10px", marginBottom:"12px"}}>
          <Button className={classes.customButton} color="primary" variant="outlined" onClick={cloneOrgRateCard} style={{ background: "#716ACA", color:"#fff",textTransform: "none", padding: "3px 20px"}}>Confirm</Button>
      </div>

      <div style={{marginLeft:"10px",marginBottom:"12px"}}>
          <Button className={classes.customButton} color="secondary" onClick={closedpop} style={{ background: "#f50057", color:"#fff",textTransform: "none", padding: "4px 20px"}}>Cancel</Button>
      </div>

        </div>  
      </Dialog>
    </div >
  )
}

export default connect(null, { setLoading, setSnackInfo })(ManageOrgRate)
