import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux'
import { setLoading, setSnackInfo } from '../redux/actions/appAction'
import HttpService from '../services/httpService'
import TableBody from '@material-ui/core/TableBody'
import Paper from '@material-ui/core/Paper';
import { ROLENAME } from '../utils/index';
import Table from '@material-ui/core/Table';
import TextField from '@material-ui/core/TextField';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Accordion, AccordionSummary} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Typography } from '@material-ui/core';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import '../App.css'
import {Checkbox} from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1
    },
    gridWrapper: {
      border: "1px solid grey",
      display: "grid",
      backgroundColor: "grey",
      gridRowGap: 1,
      gridColumnGap: 1,
      gridTemplateAreas: `
      "title title title"
      "a1 a2 a3"
      "b1 b2 b3"
      "c1 c2 c3"
      `,
      gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
      "& > *": {
        backgroundColor: "white"
      }
    },
    paper: {
      padding: theme.spacing(2),
      margin: "auto",
      maxWidth:500
    },
    filteralign:{
    display:"flex",
    },
    accordionSummary: {
      flexDirection: 'row-reverse' 
    },
    bottomRight: {
      justifyContent: "flex-end"
    },
    tablecontainer: {
      display:"flex"

    },
    tableform:{
display:"flex"
    },
    outerColumn: {
      borderRight: "1px solid grey",
      borderBottom: "1px solid grey",
      borderLeft: "1px solid grey"
    },
    centerColumn: {
      borderBottom: "1px solid grey"
    }
  }));

const DesignDashBoard = (props) => {
    const { userDetails } = useSelector(state => state.user)
    const classes = useStyles();
    const [expcenters, setExpcenters] = useState([]);
    const [designdata,setdesigndata]=useState({})
    const [leadDetail,setLeadDetail]=useState({})
    const [selectedExpCenter,setSelectedExpCenter]=useState([])
    const [salesExpCtr,setSalesExpCtr]=useState([])
    const [selectContractCenter,setSelectedContractCenter]=useState([])
    const [designNotAssigned,setNotAssignedData]=useState([])
    const [leadData,setLeadData]=useState([])
    const [designed,setDesign]=useState([])
    const [lost,setLost]=useState([])
    const [designHold,setDesignHold]=useState([]);
    const [designCompleted,setDesignCompleted]=useState([])
    const [catergoriez,setCategories]=useState([])
    const [selectedExpcenters, setSelectedExpcenteres] = useState([]);
    const [checked, setChecked] = useState(false);

    const [allSalesUsersData, setAllSalesUsersData] = useState([])
    const [salesDashboardFields, setSalesDashboardFields] = useState([])
  
    useEffect(() => {
        expcenter()
        getDesignDetails()
        get_sales_statistics()
        getExperienceCenters();
        contractLead()
    }, [])
    

    //for contract
    const contractLead = async () => {
      props.setLoading(true)
        try {  
          let exp_ctr_ids=[]
          exp_ctr_ids.push([userDetails.selectedExpCenter])
          let  result= await HttpService.getContractLeads({exp_ctr_ids: exp_ctr_ids})
            let categories = []
            for(let i = 0; i < Object.keys(result.data).length; i++)
            {   
            categories.push(Object.keys(result.data)[i])
            }
            setCategories(categories)
            let months = []
            months = [...Object.keys(result.data[categories[0]]["value"])]
    
            let contract_leads_array = months.map(el=>{
            return {month: el,value: result.data["contract_leads"].value[el], count: result.data["contract_leads"].count[el]}
                })
            contract_leads_array.push({ 
            month: 'Grand Total', value: result.data["contract_leads"].grand_total_value, count: result.data["contract_leads"].grand_total_count})
        
            setLeadData([...contract_leads_array])
            setLeadDetail(result.data)
        } catch (error) {
            console.log(error, "error")
            props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }
    const contractLeadSearch = async (v) => {
        props.setLoading(true)     
          try {
              let  result = null
            let exp_ctr_ids=[]
            if(v.length===0){
              exp_ctr_ids.push([userDetails.selectedExpCenter])
              result= await HttpService.getContractLeads([])
            }
            else
            {
              for(let i=0;i<v.length;i++)
              {
                  exp_ctr_ids.push(v[i]["_id"])
              }
              result= await HttpService.getContractLeads({exp_ctr_ids: exp_ctr_ids})
            }
              let categories = []
              for(let i = 0; i < Object.keys(result.data).length; i++)
              {   
              categories.push(Object.keys(result.data)[i])
              }
              setCategories(categories)
              let months = []
              months = [...Object.keys(result.data[categories[0]]["value"])]      
              let contract_leads_array = months.map(el=>{
                return {month: el,value: result.data["contract_leads"].value[el], count: result.data["contract_leads"].count[el]}
                    })
                contract_leads_array.push({ 
                month: 'Grand Total', value: result.data["contract_leads"].grand_total_value, count: result.data["contract_leads"].grand_total_count})
            
                setLeadData([...contract_leads_array])
                setLeadDetail(result.data)
            } catch (error) {
                console.log(error, "error")
                props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
            }
            props.setLoading(false)
        }
      //contract close

      // Getting Sales Statistics on initial render
      const get_sales_statistics = async () => {
        props.setLoading(true)
        try
        {
            let query = {}
            query.experienceCenterId = []
            query.experienceCenterId.push((userDetails.selectedExpCenter).toString())
            const response = await HttpService.get_sales_statistics_data(query)
            
            setAllSalesUsersData(response.data.All_Sales_Users_Seperated)
            setSalesDashboardFields(response.data.Fields)

        }
        catch(error)
        {
            console.log(error.message)
            console.error(error)
        }
        props.setLoading(false)
      }


  const getExperienceCenters = async () => {
      props.setLoading(true)
      try {
          const response = await HttpService.getExpericenCenters()
          setSelectedContractCenter([response.data[0]])
          setSelectedExpCenter([response.data[0]])
          setSalesExpCtr([response.data[0]])
      } catch (error) {
          props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
      }
      props.setLoading(false)
  }

    const handleSalesCheckBox = (e) => {
        setChecked(e.target.checked)
        applyFilter(salesExpCtr, e.target.checked)
    }

    // Getting Sales Statistics on Filter
    const applyFilter = async (v,tokenChecked) => {
        props.setLoading(true)
        try
        {
            let query = {}
            query["experienceCenterId"] = []
            v.forEach(item => query.experienceCenterId.push(item._id))
            if(tokenChecked)
            {
                query.TokenPercentage =  "Above-5%"
            }
            
            const response = await HttpService.get_sales_statistics_data(query)

            setAllSalesUsersData(response.data.All_Sales_Users_Seperated)
            setSalesDashboardFields(response.data.Fields)
        }
        catch(error)
        {
            console.log(error.message)
            console.error(error)
        }
        props.setLoading(false)
        
    }

        // sales is close
      const expcenter = async ()=>{
        try {
             let store=[]
            const response = await HttpService.getExpericenCenters() 
            setExpcenters(response.data)
            setSelectedExpcenteres([response.data[0].name])
        } catch (error) {
            console.log(error)
          }
        
      }
      const handleDesignData =(v)=>{
        getDesignSearch(v)
          setSelectedExpCenter(v)
      }
      const handle_Sales_ExpCtr_Search =(v)=>{
            setSalesExpCtr(v)
            applyFilter(v, checked)
            
      }
     const  handleContractData =(v)=>{
      contractLeadSearch(v)
      setSelectedContractCenter(v)

     }
      const getDesignDetails = async () => { 
        props.setLoading(true)    
          try {
            let exp_ctr_ids=[]
            exp_ctr_ids.push([userDetails.selectedExpCenter])
            let  result= await HttpService.getDesignLeads({exp_ctr_ids: exp_ctr_ids})  
              let categories = []
           for(let i = 0; i < Object.keys(result.data).length; i++)
           {   
            categories.push(Object.keys(result.data)[i])
          }
          setCategories(categories)
           let months = []
           months = [...Object.keys(result.data[categories[0]]["value"])]
    
          let designer_not_assigned_array = months.map(el=>{
            return {month: el,value: result.data["designer_not_assigned"].value[el], count: result.data["designer_not_assigned"].count[el]}
        })
        designer_not_assigned_array.push({ 
          month: 'Grand Total', value: result.data["designer_not_assigned"].grand_total_value, count: result.data["designer_not_assigned"].grand_total_count})
         
        setNotAssignedData([...designer_not_assigned_array])  
        let design = months.map(el=>{
          return {month: el,value: result.data["design"].value[el], count: result.data["design"].count[el]}
      })
      design.push({ 
        month: 'Grand Total', value: result.data["design"].grand_total_value, count: result.data["design"].grand_total_count})
      setDesign([...design])
  
      let design_hold = months.map(el=>{
        return {month: el,value: result.data["design_hold"].value[el], count: result.data["design_hold"].count[el]}
    })
    design_hold.push({ 
      month: 'Grand Total', value: result.data["design_hold"].grand_total_value, count: result.data["design_hold"].grand_total_count})
     
    setDesignHold([...design_hold])
  
    let design_lost = months.map(el=>{
      return {month: el,value: result.data["design_lost"].value[el], count: result.data["design_lost"].count[el]}
  })
  design_lost.push({ 
    month: 'Grand Total', value: result.data["design_lost"].grand_total_value, count: result.data["design_lost"].grand_total_count})
   
  setLost([...design_lost])
  
  let design_completed = months.map(el=>{
    return {month: el,value: result.data["design_completed"].value[el], count: result.data["design_completed"].count[el]}
  })
  design_completed.push({ 
    month: 'Grand Total', value: result.data["design_completed"].grand_total_value, count: result.data["design_completed"].grand_total_count})
   
  setDesignCompleted([...design_completed])
          setdesigndata(result.data)
          } catch (error) {
              console.log(error, "error")
              props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
          }
          props.setLoading(false)
      }
    const getDesignSearch = async (v) => { 
      props.setLoading(true) 
      try {
        let  result = null
      let exp_ctr_ids=[]
      if(v.length===0){
        exp_ctr_ids.push([userDetails.selectedExpCenter])
        result= await HttpService.getDesignLeads([])
      }
      else
      {
        for(let i=0;i<v.length;i++)
        {
            exp_ctr_ids.push(v[i]["_id"])
        }
        result= await HttpService.getDesignLeads({exp_ctr_ids: exp_ctr_ids})
      }
         let categories = []
         for(let i = 0; i < Object.keys(result.data).length; i++)
         {   
          categories.push(Object.keys(result.data)[i])
        }
        setCategories(categories)
        console.log("categories", categories)
         let months = []
         months = [...Object.keys(result.data[categories[0]]["value"])]
  
        let designer_not_assigned_array = months.map(el=>{
          return {month: el,value: result.data["designer_not_assigned"].value[el], count: result.data["designer_not_assigned"].count[el]}
      })
      designer_not_assigned_array.push({ 
        month: 'Grand Total', value: result.data["designer_not_assigned"].grand_total_value, count: result.data["designer_not_assigned"].grand_total_count})
       
      setNotAssignedData([...designer_not_assigned_array])


      let design = months.map(el=>{
        return {month: el,value: result.data["design"].value[el], count: result.data["design"].count[el]}
        })
        design.push({ 
        month: 'Grand Total', value: result.data["design"].grand_total_value, count: result.data["design"].grand_total_count})
        setDesign([...design])

        let design_hold = months.map(el=>{
            return {month: el,value: result.data["design_hold"].value[el], count: result.data["design_hold"].count[el]}
        })
        design_hold.push({ 
            month: 'Grand Total', value: result.data["design_hold"].grand_total_value, count: result.data["design_hold"].grand_total_count})
        
        setDesignHold([...design_hold])

        let design_lost = months.map(el=>{
            return {month: el,value: result.data["design_lost"].value[el], count: result.data["design_lost"].count[el]}
        })
        design_lost.push({ 
        month: 'Grand Total', value: result.data["design_lost"].grand_total_value, count: result.data["design_lost"].grand_total_count})
        
        setLost([...design_lost])

        let design_completed = months.map(el=>{
        return {month: el,value: result.data["design_completed"].value[el], count: result.data["design_completed"].count[el]}
        })
        design_completed.push({ 
        month: 'Grand Total', value: result.data["design_completed"].grand_total_value, count: result.data["design_completed"].grand_total_count})
        
        setDesignCompleted([...design_completed])
                setdesigndata(result.data)
                } catch (error) {
                    console.log(error, "error")
                    props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
                }
        props.setLoading(false)
    }
    return (

        <div> 
             <div style={{ marginBottom: 20, alignItems: "center", justifyContent: "center" }}>
                <Paper className={classes.paper}>
                   <center> <h2>Statistics Report</h2></center>
                </Paper>
            </div>

            {/* Sales Dashboard start*/}
            {
                (
                    userDetails?.roles?.find
                    (
                        role =>
                        role.name === ROLENAME.CenterHead ||
                        role.name === ROLENAME.BusinessHead ||
                        role.name ===ROLENAME.Admin
                    )
                ) &&
                
                <>
                    <Accordion style={{width:"200%"}}>
                        <AccordionSummary
                            className="iconposition"
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className={classes.heading}>Sales</Typography>
                        </AccordionSummary>

                        <div className={classes.filteralign}>

                            {/* ExperienceCenter Search */}
                            <Autocomplete
                                multiple
                                value={salesExpCtr}
                                onChange={(e, v) => handle_Sales_ExpCtr_Search(v)}
                                options={expcenters}
                                getOptionLabel={(option) => option.name}
                                style={{ width: "20%", marginBottom: 10 ,marginLeft:25}}
                                renderInput={(params) => <TextField {...params} label="Select Exp-Center" fullWidth variant="outlined" />}
                            />

                            {/* Token %age CheckBox */}
                            <div style={{marginLeft: 20, marginTop: 10}}>
                                <FormControlLabel
                                control={<Checkbox color="primary" checked={checked} onChange={handleSalesCheckBox} />}
                                label="Above 5% Token Value"
                                />
                            </div>

                        </div>

                        {/* Sales Dashboard Table */}
                        <div className={classes.tablecontainer} style={{marginLeft:25}}>
                            <div className={classes.tableform}>
                                <TableContainer component={Paper} style={{width:"200%"}}>
                                    <Table  style={{ width: 450 }} className={classes.table} stickyHeader aria-label="sticky table">
                                        
                                        <TableHead>
                                            <TableRow>
                                                <TableCell rowSpan={2} style={{textAlign:"center"}}>Center</TableCell>
                                                <TableCell rowSpan={2} style={{textAlign:"center"}}>Name</TableCell>
                                                {
                                                    salesDashboardFields.map((el) =>{
                                                        return(
                                                            <>
                                                                <TableCell colSpan={2} style={{textAlign:"center"}}>{el}</TableCell>
                                                            </>
                                                            )
                                                    })
                                                }
                                            </TableRow>
                                            <TableRow>        
                                                {
                                                    salesDashboardFields.map((el, index) =>{
                                                        return(
                                                            <>
                                                                <TableCell style={{textAlign:"center"}}>Count</TableCell>
                                                                <TableCell style={{textAlign:"center"}}>Value</TableCell>
                                                            </>
                                                            )
                                                    })
                                                }
                                            </TableRow>
                                        </TableHead>
                                        
                                        <TableBody>
                                            {
                                                allSalesUsersData.map(el=>{
                                                    return(
                                                        <>
                                                            <TableRow>
                                                                <TableCell component="th" style={{textAlign:"center"}}>{el.isManager?<b>{el.Exp_Ctr}</b>:el.Exp_Ctr}</TableCell>
                                                                <TableCell component="th" style={{textAlign:"center"}}>{el.isManager?<b>{el.Name}</b>:el.Name}</TableCell>
                                                                {
                                                                    salesDashboardFields.map((ele) =>{
                                                                        return(
                                                                            <>
                                                                                <TableCell component="th" style={{textAlign:"center"}}>{el.isManager?<b>{el[ele]["count"]}</b>:el[ele]["count"]}</TableCell>
                                                                                <TableCell component="th" style={{textAlign:"center"}}>{el.isManager?<b>{el[ele]["value"]}</b>:el[ele]["value"]}</TableCell>
                                                                            </>
                                                                            )
                                                                    })
                                                                }
                                                            </TableRow>
                                                        </>
                                                    )
                                                })
                                            }
                                        </TableBody>

                                    </Table>
                                </TableContainer>
                            </div>
                        </div>

                    </Accordion>
                </>
            }
            <br/>
            
            {/* design */}
             <div>
              {
              (userDetails?.roles?.find(role => role.name === ROLENAME.CenterHead || role.name === ROLENAME.BusinessHead || role.name === ROLENAME.DesignHead || role.name ===ROLENAME.Admin)) &&

          <Accordion style={{width:"175vw"}}>
                                <AccordionSummary
                                 className="iconposition"
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Typography className={classes.heading}>Design</Typography>
                                </AccordionSummary>
            <div>
            <Autocomplete
                 multiple
                value={selectedExpCenter}
                onChange={(e, v) => handleDesignData(v)}
                options={expcenters}
                getOptionLabel={(option) => option.name}
                style={{ width: 500, marginBottom: 10 ,marginLeft:25}}
                renderInput={(params) => <TextField {...params} label="Select Exp-Center" fullWidth variant="outlined" />}
              />
           
            </div>
            
            <div className={classes.tablecontainer} style={{marginLeft:25}}>
              <div className={classes.tableform}>
            <TableContainer component={Paper} style={{width:"100%"}}>
                                    <Table  style={{ width: 450 }} className={classes.table} stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell colSpan={2} style={{fontSize:"0.925rem", textAlign:"center"}}><b>Designer not assigned</b></TableCell>
                                            </TableRow> 
                                            <TableRow>
                                            <TableCell style={{textAlign:"center"}}>Month </TableCell>
                                            <TableCell style={{textAlign:"center"}}>value</TableCell>
                                             <TableCell style={{textAlign:"center"}}>count</TableCell>
                                            </TableRow>
                                            {designNotAssigned.map((el, i) => {
                                                          return (
                                                            <>
                                                            {(i === designNotAssigned.length - 1) ?
                                                              (<TableRow> 
                                                              <TableCell style={{fontWeight:"700",textAlign:"center"}}>{el.month}</TableCell>
                                                              <TableCell  style={{fontWeight:"700",textAlign:"center"}}>{(el.value).toFixed(2)}</TableCell>
                                                              <TableCell style={{fontWeight:"700",textAlign:"center"}}>{el.count}</TableCell>
                                                            </TableRow>):(<TableRow> 
                                                              <TableCell style={{textAlign:"center"}}>{el.month}</TableCell>
                                                              <TableCell style={{textAlign:"center"}}>{(el.value).toFixed(2)}</TableCell>
                                                              <TableCell style={{textAlign:"center"}}>{el.count}</TableCell>
                                                            </TableRow>)}
                                                        </>
                                                          )})}
                                            </TableHead>

                                            </Table>
                                            </TableContainer><br/>   </div>
                                            <div className={classes.tableform}>
                                            <TableContainer component={Paper} style={{width:"100%"}}>
                                    <Table style={{ width: 300 }} className={classes.table} stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell colSpan={2} style={{fontSize:"0.925rem",textAlign:"center"}}><b>Design</b></TableCell>
                                            </TableRow> 
                                            <TableRow>
                                            <TableCell style={{textAlign:"center"}}>value</TableCell>
                                             <TableCell style={{textAlign:"center"}}>count</TableCell>
                                            </TableRow>
                                            {designed.map((el, i) => {
                                                          return (
                                                            <>
                                                            {(i === designed.length - 1) ?
                                                              (<TableRow> 
                                                              <TableCell  style={{fontWeight:"700",textAlign:"center"}}>{(el.value).toFixed(2)}</TableCell>
                                                              <TableCell style={{fontWeight:"700",textAlign:"center"}}>{el.count}</TableCell>
                                                            </TableRow>):(<TableRow> 
                                                              <TableCell style={{textAlign:"center"}}>{(el.value).toFixed(2)}</TableCell>
                                                              <TableCell style={{textAlign:"center"}}>{el.count}</TableCell>
                                                            </TableRow>)}
                                                        </>
                                                          )})}
                                            </TableHead>

                                            </Table>
                                            </TableContainer></div>  <br/> 
                                            <div className={classes.tableform}>
                                            <TableContainer component={Paper} style={{width:"100%"}}>
                                    <Table style={{ width: 300 }} className={classes.table} stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell colSpan={2} style={{fontSize:"0.925rem",textAlign:"center"}}><b>DesignHold</b></TableCell>
                                            </TableRow> 
                                            <TableRow>
                                            <TableCell style={{textAlign:"center"}}>value</TableCell>
                                             <TableCell style={{textAlign:"center"}}>count</TableCell>
                                            </TableRow>
                                            {designHold.map((el, i) => {
                                                          return (
                                                            <>
                                                            {(i === designHold.length - 1) ?
                                                              (<TableRow> 
                                                              <TableCell  style={{fontWeight:"700",textAlign:"center"}}>{(el.value).toFixed(2)}</TableCell>
                                                              <TableCell style={{fontWeight:"700",textAlign:"center"}}>{el.count}</TableCell>
                                                            </TableRow>):(<TableRow> 
                                                              <TableCell style={{textAlign:"center"}}>{(el.value).toFixed(2)}</TableCell>
                                                              <TableCell style={{textAlign:"center"}}>{el.count}</TableCell>
                                                            </TableRow>)}
                                                        </>
                                                          )})}
                                            </TableHead>

                                            </Table>
                                            </TableContainer></div>  <br/>
                                            <div className={classes.tableform}>
                                            <TableContainer component={Paper} style={{width:"100%"}}>
                                    <Table style={{ width: 300 }} className={classes.table} stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell colSpan={2} style={{fontSize:"0.925rem",textAlign:"center"}}><b>Lost</b></TableCell>
                                            </TableRow> 
                                            <TableRow>
                                            <TableCell style={{textAlign:"center"}}>value</TableCell>
                                             <TableCell style={{textAlign:"center"}}>count</TableCell>
                                            </TableRow>
                                            {lost.map((el, i) => {
                                                          return (
                                                        <>
                                                        {(i === lost.length - 1) ?
                                                          (<TableRow> 
                                                          <TableCell  style={{fontWeight:"700",textAlign:"center"}}>{(el.value).toFixed(2)}</TableCell>
                                                          <TableCell style={{fontWeight:"700",textAlign:"center"}}>{el.count}</TableCell>
                                                        </TableRow>):(<TableRow> 
                                                          <TableCell style={{textAlign:"center"}}>{(el.value).toFixed(2)}</TableCell>
                                                          <TableCell style={{textAlign:"center"}}>{el.count}</TableCell>
                                                        </TableRow>)}
                                                    </>
                                                      )})}
                                            </TableHead>

                                            </Table>
                                            </TableContainer> </div>  <br/>
                                            <div className={classes.tableform}>
                                            <TableContainer component={Paper} style={{width:"100%"}}>
                                    <Table style={{ width: 300 }} className={classes.table} stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell colSpan={2} style={{fontSize:"0.925rem",textAlign:"center"}}><b>DesignCompleted</b></TableCell>
                                            </TableRow> 
                                            <TableRow>
                                            <TableCell style={{textAlign:"center"}}>value</TableCell>
                                             <TableCell style={{textAlign:"center"}}>count</TableCell>
                                            </TableRow>
                                            {designCompleted.map((el, i) => {
                                                          return (
                                                            <>
                                                            {(i === designCompleted.length - 1) ?
                                                              (<TableRow> 
                                                              <TableCell  style={{fontWeight:"700",textAlign:"center"}}>{(el.value).toFixed(2)}</TableCell>
                                                              <TableCell style={{fontWeight:"700",textAlign:"center"}}>{el.count}</TableCell>
                                                            </TableRow>):(<TableRow> 
                                                              <TableCell style={{textAlign:"center"}}>{(el.value).toFixed(2)}</TableCell>
                                                              <TableCell style={{textAlign:"center"}}>{el.count}</TableCell>
                                                            </TableRow>)}
                                                        </>
                                                          )})}
                                            </TableHead>

                                            </Table>
                                            </TableContainer>
                                          
                                          
                                              </div>
                                            
                                            <div>
                                              </div>
                                              </div>
                                              </Accordion>
}
                                            </div>
                                                            
                                            <br/>

                                            <div>
                                              {
                                            (userDetails?.roles?.find(role => role.name === ROLENAME.CenterHead || role.name === ROLENAME.BusinessHead || role.name ===ROLENAME.Admin)) &&

                                              <div>
                                              <Accordion style={{width:"175vw"}}>
                                <AccordionSummary
                                 className="iconposition"
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Typography className={classes.heading}>Contract</Typography>
                                </AccordionSummary>
               <Autocomplete
                 multiple
                value={selectContractCenter}
                onChange={(e, v) => handleContractData(v)}
                options={expcenters}
                getOptionLabel={(option) => option.name}
                style={{ width: 500, marginBottom: 10,marginLeft:25 }}
                renderInput={(params) => <TextField {...params} label="Select Exp-Center" fullWidth variant="outlined" />}
              />
            <div>
            <TableContainer component={Paper}>
                                    <Table  style={{ width: 600,marginLeft:25 }} className={classes.table} stickyHeader aria-label="sticky table">
                                        <TableHead>
                                        
                                            <TableRow>
                                            <TableCell style={{fontSize:"0.925rem",textAlign:"center"}}><b>Contract Month</b></TableCell>
                                            <TableCell style={{fontSize:"0.925rem",textAlign:"center"}}><b>Sum Of Budget</b></TableCell>
                                             <TableCell style={{fontSize:"0.925rem",textAlign:"center"}}><b>Counts of Sales Month</b></TableCell>
                                            </TableRow>
                                            {leadData.map((el, i) => {
                                                          return (
                                                            <>
                                                            {(i === leadData.length - 1) ?
                                                              (<TableRow> 
                                                              <TableCell style={{fontWeight:"700",textAlign:"center"}}>{el.month}</TableCell>
                                                              <TableCell  style={{fontWeight:"700",textAlign:"center"}}>{(el.value).toFixed(2)}</TableCell>
                                                              <TableCell style={{fontWeight:"700",textAlign:"center"}}>{el.count}</TableCell>
                                                            </TableRow>):(<TableRow> 
                                                              <TableCell style={{textAlign:"center"}}>{el.month}</TableCell>
                                                              <TableCell style={{textAlign:"center"}}>{(el.value).toFixed(2)}</TableCell>
                                                              <TableCell style={{textAlign:"center"}}>{el.count}</TableCell>
                                                            </TableRow>)}
                                                        </>
                                                          )})}
                                            </TableHead>
                                            </Table>
                                            </TableContainer><br/>
                                            </div>
                                            </Accordion>
                                            </div>
}
                                            </div>
                                            </div> 
                                          
                                          
    )
}
export default connect(null, { setLoading, setSnackInfo })(DesignDashBoard)