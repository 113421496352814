import { useState, useEffect } from 'react'
import { setLoading, setSnackInfo } from '../redux/actions/appAction';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { connect, useSelector } from 'react-redux';
import { ROLENAME } from '../utils';
import { useHistory } from "react-router-dom";
import { Paper, Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}))

const SalesUpview = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const { userDetails } = useSelector(state => state.user);
    const [marketingUserCount, setMarketingUserCount] = useState({});
    useEffect(() => {
        checkUser(props.role)
        // eslint-disable-next-line
    }, [props])

    const checkUser = (roleid) => {
        if ((roleid === ROLENAME.SalesManager || roleid === ROLENAME.SalesHead || props.role === ROLENAME.AssistantSalesManager) && (props.isUserSelected.length === 0)) {
            getSalesLeadCountDetails(props.salesdata);
        } else {
            getSalesLeadCountDetails(props.salesdata);
        }
    }
    const getSalesLeadCountDetails = async (data) => {
        separeteLeadStatusWise(data);
    }

    const separeteLeadStatusWise = (data) => {
        if (props.role === ROLENAME.SalesManager || props.role === ROLENAME.SalesHead || props.role === ROLENAME.AssistantSalesManager) {
            data.forEach(ele => {
                if (ele.name === 'topInfo') {
                    setMarketingUserCount(ele)
                }
            })
        } else if (props.role === ROLENAME.SalesUser) {
            setMarketingUserCount(data)
        }
    }

    const changePageRoute = (data) => {
        if (props.role === ROLENAME.SalesManager || props.role === ROLENAME.SalesHead || props.role === ROLENAME.AssistantSalesManager) {
            let userId = [];
            if (props.selectedUserDetails.length !== 0) {
                let filterUser = props.selectedUserDetails.filter((ele, i) => ele.userId);
                filterUser.forEach((ele, i) => {
                    userId.push(ele.userId);
                })
            }
            history.push(`/status-information/${data}/${props.selecteddate.start}/${props.selecteddate.end}/status/false`, {
                experienceCenterId: props.selectedexpcenterid,
                locationId: props.selectedlocationid,
                data: marketingUserCount,
                selectedUserId: userId
            });
        } else {
            history.push(`/status-information/${data}/${props.selecteddate.start}/${props.selecteddate.end}/status/false`, {
                experienceCenterId: props.selectedexpcenterid,
                locationId: props.selectedlocationid,
                data: marketingUserCount.info,
                selectedUserId: []
            });
        }
    }

    const changeSalesStagePageRoute = (stage, teamId, option) => {
        if (props.role === ROLENAME.SalesManager || props.role === ROLENAME.SalesHead || props.role === ROLENAME.AssistantSalesManager) {
            let userId = [];
            if (props.selectedUserDetails.length !== 0) {
                let filterUser = props.selectedUserDetails.filter((ele, i) => ele.userId);
                filterUser.forEach((ele, i) => {
                    userId.push(ele.userId);
                })
            }
            history.push(`/designer-assignment/${stage}/${props.selecteddate.start}/${props.selecteddate.end}/${teamId}/${option}`, {
                experienceCenterId: props.selectedexpcenterid,
                locationId: props.selectedlocationid,
                data: marketingUserCount,
                selectedUserId: userId,
                filterOption: props.filteroption
            });
        } else {
            history.push(`/designer-assignment/${stage}/${props.selecteddate.start}/${props.selecteddate.end}/${teamId}/${option}`, {
                experienceCenterId: props.selectedexpcenterid,
                locationId: props.selectedlocationid,
                role: props.role,
                selectedUserId: [],
                filterOption: props.filteroption
            });
        }
    }

    return (
        <div>
            <Paper className={classes.paper} style={{ marginTop: 20 }}>
                <div style={{ display: 'flex', marginTop: 10, marginBottom: 10 }}>
                    <div className={classes.root}>
                        {userDetails?.roles?.find(role => role.name === ROLENAME.SalesHead || role.name === ROLENAME.SalesManager || role.name === ROLENAME.AssistantSalesManager ) &&
                            <Grid item xs={12} >
                                <Grid container spacing={2} >
                                    <Grid item md={6} xs={12}>
                                        <Paper className={classes.paper}>
                                            <Button style={{ padding: 0 }} onClick={() => changePageRoute('Leads-Pending-Assignment')} color="primary" >
                                                Leads Pending Assignment < br />
                                                ({marketingUserCount.leadsPending ? marketingUserCount.leadsPending : `0`})
                                            </Button >
                                        </Paper >
                                    </Grid >
                                    <Grid item md={6} xs={12}>
                                        <Paper className={classes.paper}>
                                            <Button style={{ padding: 0 }} onClick={() => changePageRoute('Activity-For-Today')} color="primary" >
                                                Activities for Today < br />
                                                ({marketingUserCount.activitiesForToday ? marketingUserCount.activitiesForToday.length : `0`})
                                            </Button >
                                        </Paper >
                                    </Grid >
                                    <Grid item md={6} xs={12}>
                                        <Paper className={classes.paper}>
                                            <Button style={{ padding: 0 }} onClick={() => changePageRoute('Open-Leads(Current-and-last-2-month)')} color="primary" >
                                                Open Leads(Current and last 2 month) < br />
                                                ({marketingUserCount.openLeads ? marketingUserCount.openLeads.length : `0`})
                                            </Button >
                                        </Paper >
                                    </Grid >
                                    <Grid item md={6} xs={12}>
                                        <Paper className={classes.paper}>
                                            <Button style={{ padding: 0 }} onClick={() => changeSalesStagePageRoute('Won-Deals-Pending-Designer-Assignment', userDetails.teamId._id, 'team')} color="primary">
                                                Won Deals Pending Designer Assignment <br />
                                                ({marketingUserCount.designerPending ? marketingUserCount.designerPending : `0`})
                                            </Button>
                                        </Paper >
                                    </Grid >
                                </Grid >
                            </Grid >
                        }
                        {userDetails?.roles?.find(role => role.name === ROLENAME.SalesUser) &&
                            <Grid item xs={12} >
                                <Grid container spacing={2} >
                                    <Grid item md={6} xs={12}>
                                        <Paper className={classes.paper}>
                                            <Button style={{ padding: 0 }} onClick={() => changePageRoute('Leads-Pending-Assignment')} color="primary" >
                                                Leads Pending < br />
                                                ({marketingUserCount?.info?.leadsPending ? marketingUserCount?.info?.leadsPending : `0`})
                                            </Button >
                                        </Paper >
                                    </Grid >
                                    <Grid item md={6} xs={12}>
                                        <Paper className={classes.paper}>
                                            <Button style={{ padding: 0 }} onClick={() => changePageRoute('Activity-For-Today')} color="primary" >
                                                Activities for Today < br />
                                                ({marketingUserCount?.info?.activitiesForToday ? marketingUserCount?.info?.activitiesForToday.length : `0`})
                                            </Button >
                                        </Paper >
                                    </Grid >
                                    <Grid item md={6} xs={12}>
                                        <Paper className={classes.paper}>
                                            <Button style={{ padding: 0 }} onClick={() => changePageRoute('Open-Leads(Current-and-last-2-month)')} color="primary" >
                                                Open Leads(Current and last 2 month) < br />
                                                ({marketingUserCount?.info?.openLeads ? marketingUserCount?.info?.openLeads.length : `0`})
                                            </Button >
                                        </Paper >
                                    </Grid >
                                    <Grid item md={6} xs={12}>
                                        <Paper className={classes.paper}>
                                            <Button style={{ padding: 0 }} onClick={() => changeSalesStagePageRoute('Won-Deals-Pending-Designer-Assignment', userDetails._id, 'user')} color="primary">
                                                Won Deals Pending Designer Assignment <br />
                                                ({marketingUserCount.info?.designerPending ? marketingUserCount.info.designerPending : `0`})
                                            </Button>
                                        </Paper >
                                    </Grid >

                                </Grid >
                            </Grid >
                        }
                    </div >
                </div >
            </Paper >
        </div >
    )
}
export default connect(null, { setLoading, setSnackInfo })(SalesUpview)