import React, { useEffect, useState, useRef } from 'react'
import { connect, useSelector } from 'react-redux'
import { getAllProjects } from '../../../redux/actions/projectAction'
import { setLoading, setSnackInfo } from '../../../redux/actions/appAction'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import HttpService from '../../../services/httpService';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { DownloadOutlined } from '@ant-design/icons';
import Paper from '@material-ui/core/Paper';
import { Modal } from 'antd';
// import 'antd/dist/antd.css';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { DeleteOutlined } from '@ant-design/icons'

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 600,
  },
  textFieldWidth: {
    width: "100%"
  },
  input: {
    display: 'none',
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#ccc",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);


const ProjectDcouments = (props) => {
  const classes = useStyles();
  const projectDocumentRef = useRef()
  const WorkingDrawingPdfRef = useRef()
  const ContractSignaturePdfRef = useRef()
  const CheckListRef = useRef()
  useEffect(() => {
    getAllProjects()
    // eslint-disable-next-line
  }, [])

  const getAllProjects = async () => {
    try {
      props.setLoading(true)
      await props.getAllProjects()
      props.setLoading(false)
    } catch (error) {
      console.error(error)
      props.setLoading(false)
      props.setSnackInfo(error?.response?.data?.message ?? "Something Went Wrong", "error")
    }
  }

  const { projects } = useSelector(state => state.project)
  const [selectedProject, setSelectedProject] = useState({})
  const [showSendToFinance, setShowSendToFinance] = useState(false)

  const handleSelect = async (event, value) => {
    if (value?._id) {
      props.setLoading(true)
      setSelectedProject(value)
      getProjectDocuments(value?._id)
    }
  }

  const [projectDocuments, setProjectDocuments] = useState([])

  const getProjectDocuments = async (projectId) => {
    try {
      const result = await HttpService.getProjectDocuments(projectId)
      setProjectDocuments(result.data)
      console.log(result.data, "result")
      let statusDoc = {
        product: false,
        drawing: false,
        contract: false,
        checkList: false,
        allApproved: true
      }
      result.data.map(ele => {
        if (ele.status !== "InSiteQC")
          statusDoc.allApproved = false
        switch (ele.documentType) {
          case 'ProductDoc':
            statusDoc.product = true;
            break;
          case 'WorkingDrawingPDF':
            statusDoc.drawing = true;
            break;
          case 'ContractSignaturePDF':
            statusDoc.contract = true;
            break;
          case 'CheckList':
            statusDoc.checkList = true;
            break;
          default:
            break;
        }
        return null
      })
      if (statusDoc.product && statusDoc.drawing && statusDoc.contract && statusDoc.checkList && !statusDoc.allApproved) {
        setShowSendToFinance(true)
      }
      props.setLoading(false)
    } catch (error) {
      console.error(error)
      props.setLoading(false)
      props.setSnackInfo(error?.response?.data?.message ?? "Something Went Wrong", "error")
      setProjectDocuments([])
    }
  }

  const [uploadDocumentModal, setUploadDocumentModal] = useState(false)
  const [existingProject, setExistingProject] = useState(null)
  const [fileType, setFileType] = useState("ProductDoc")
  const [formData, setFormData] = useState({
    clientName: "",
    code: "",
    apartmentName: "",
    clientAddress: "",
    startDate: "",
    expectedDeliveryDate: "",
  })

  const { apartmentName, clientAddress, startDate, expectedDeliveryDate, code, clientName } = formData

  const [productDocument, setProductDocument] = useState(false)

  const uploadProductDocument = async (e) => {
    try {
      props.setLoading(true)
      const formData = new FormData()
      formData.append('file', e.target.files[0])
      formData.append('code', code)
      formData.append('clientName', clientName)
      formData.append('apartmentName', apartmentName)
      formData.append('clientAddress', clientAddress)
      formData.append('startDate', startDate)
      formData.append('expectedDeliveryDate', expectedDeliveryDate)
      formData.append('existingProject', existingProject)
      const result = await HttpService.productDocumentUpload(formData)
      console.log(result.data.project, "project")
      if (result.status === 200) {
        setProjectSelected(result.data.project)
        setProductDocument(true)
        props.setSnackInfo("Product Document Upload Success", "success")
      }
    } catch (error) {
      console.log(error)
      props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
    }
    projectDocumentRef.current.value = "";
    props.setLoading(false)
  }
  const [projectSelected, setProjectSelected] = useState({})

  const uploadDocuments = async (e, type = "ProductDoc") => {
    props.setLoading(true)
    try {
      console.log(fileType, type)
      const formData = new FormData()
      if (fileType === type)
        formData.append('fileType', fileType)
      else
        formData.append('fileType', type)
      formData.append('projectId', projectSelected._id)
      let result
      if (fileType === "ProductDoc" && type === "ProductDoc") {
        formData.append('file', e.target.files[0])
        result = await HttpService.productDocumentUpload(formData)
      } else {
        formData.append('document', e.target.files[0])
        console.log('else')
        result = await HttpService.mandatoryDocumentsUpload(formData)
      }
      if (result.status === 200)
        props.setSnackInfo("Upload Success", "success")
    } catch (error) {
      console.log(error)
      props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
    }
    if (WorkingDrawingPdfRef.current) WorkingDrawingPdfRef.current.value = ""
    if (ContractSignaturePdfRef.current) ContractSignaturePdfRef.current.value = ""
    if (CheckListRef.current) CheckListRef.current.value = ""
    props.setLoading(false)
    // setUploadDocumentModal(false)
  }

  const deleteProjectDocument = async (docId) => {
    try {
      props.setLoading(true)
      const result = await HttpService.deleteProjectDocument(docId)
      if (result.status === 200) {
        props.setSnackInfo('Deleted Successfully', 'success')
        console.log(selectedProject, "selectedProject")
        getProjectDocuments(selectedProject._id)
      }
    } catch (error) {
      console.log(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something Went Wrong", "error")
    }
    props.setLoading(false)
  }

  const sendToFinanceForApproval = async () => {
    props.setLoading(true)
    try {
      const obj = projectDocuments.map(ele => {
        return {
          documentType: ele.documentType,
          _id: ele._id
        }
      })
      const result = await HttpService.sendToFinanceForApproval(selectedProject._id, obj)
      if (result.status === 200) {
        getProjectDocuments(selectedProject._id)
        setSelectedProject(selectedProject)
        // setProjectDocuments([])
        props.setSnackInfo("Sent to Finance", "success")
      }
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
    }
    props.setLoading(false)

  }
  const sendToSiteQcForApproval = async () => {
    props.setLoading(true)
    try {
      const obj = projectDocuments.map(ele => {
        return {
          documentType: ele.documentType,
          _id: ele._id
        }
      })
      const result = await HttpService.sendToSiteQcForApproval(selectedProject._id, obj);
      if (result.status === 200) {
        getProjectDocuments(selectedProject._id)
        setSelectedProject(selectedProject)
        props.setSnackInfo("Sent to Site QC", "success")
      }
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
    }
    props.setLoading(false)

  }
  return (
    <div>
      <span style={{ fontSize: 18 }}>
        <b>
          Project documents
        </b>
      </span>
      <div style={{ margin: 15, marginLeft:0 }} >
        <Button variant="contained" color="primary" style={{ background: "#716ACA", margin: 10 }} onClick={() => setUploadDocumentModal(true)}>
          Upload New Documents
        </Button>
      </div>
      <hr></hr>
      {/* <div style={{ marginTop: 40 }}>
        <span style={{ fontSize: 18 }}>
          <b>
            View Existing Project documents
          </b>
        </span>
         <div style={{ marginBottom: 20, marginTop: 10 }}>
          <Autocomplete
            value={selectedProject}
            onChange={handleSelect}
            id="project-code-dropdown"
            options={projects}
            getOptionLabel={(option) => option.searchName}
            style={{ width: 500 }}
            renderInput={(params) => <TextField {...params} label="Select Project" fullWidth variant="outlined" />}
          />
        </div> 
      </div> */}
      {/* {
        projectDocuments.length > 0 &&
        <div>
          <TableContainer component={Paper}>
            <Table className={classes.table} size="small" aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Sl No</StyledTableCell>
                  <StyledTableCell>Document Type</StyledTableCell>
                  <StyledTableCell>Date</StyledTableCell>
                  <StyledTableCell>Status</StyledTableCell>
                  <StyledTableCell>Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {projectDocuments.map((data, i) => (
                  <StyledTableRow key={i + 1}>
                    <StyledTableCell>{i + 1}</StyledTableCell>
                    <StyledTableCell>{data?.documentType}</StyledTableCell>
                    <StyledTableCell>{data?.createdAt}</StyledTableCell>
                    <StyledTableCell>{data?.status}</StyledTableCell>
                    <StyledTableRow>
                      {data?.documentLink && <StyledTableCell >	<button onClick={() => window.open(data?.documentLink)} style={{ cursor: 'pointer' }} className='primary-btn'><DownloadOutlined />
                      </button></StyledTableCell>}
                      {data?.status === "InDesign" && <StyledTableCell> <div className="approve-reject-btn">
                        <button onClick={() => deleteProjectDocument(data?._id)} className='primary-btn'><DeleteOutlined /></button>
                      </div></StyledTableCell>
                      }
                    </StyledTableRow>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 10 }}>
            {!showSendToFinance ?
              <Button variant="contained" color="primary" disabled>
                Sent To Site QC for verification
              </Button>
              :
              <Button onClick={sendToSiteQcForApproval} style={{ background: "#716ACA" }} variant="contained" color="primary">
                Send To Site QC
              </Button>
            }
          </div>
        </div>
      } */}
      <Modal
        title="Upload Documents"
        centered
        visible={uploadDocumentModal}
        onCancel={() => { setUploadDocumentModal(false); setExistingProject(null) }}
        width={1000}
        maskClosable={false}
        footer={null}
      >
        <div >
          <div style={{ display: "flex", justifyContent: "space-evenly", marginTop: 15, paddingBottom: 10 }} >
            <Button variant="contained" color="primary" style={{ background: "#716ACA", margin: 5 }} onClick={() => setExistingProject(false)}>
              New Project
            </Button>
            <Button variant="contained" color="primary" style={{ background: "#716ACA", margin: 5 }} onClick={() => setExistingProject(true)}>
              Existing Project
            </Button>
          </div>
          {!existingProject && existingProject !== null && <div style={{ width: '50%' }}>
            <div style={{ paddingBottom: 15 }}>
              <TextField className={classes.textFieldWidth} value={code} onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} name="code" label="Project Code" variant="outlined" />
            </div>
            <div style={{ paddingBottom: 15 }}>
              <TextField className={classes.textFieldWidth} value={clientName} onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} name="clientName" label="Client Name" variant="outlined" />
            </div>
            <div style={{ paddingBottom: 15 }}>
              <TextField className={classes.textFieldWidth} value={apartmentName} onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} name="apartmentName" label="Apartment Name" variant="outlined" />
            </div>
            <div style={{ paddingBottom: 15 }}>
              <TextField className={classes.textFieldWidth} value={clientAddress} onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} name="clientAddress" label="Apartment Address" variant="outlined" />
            </div>
            <div style={{ paddingBottom: 15 }}>
              <TextField
                id="start_date"
                label="Start Date"
                name="startDate"
                onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}
                type="date"
                className={classes.textFieldWidth}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div style={{ paddingBottom: 15 }}>
              <TextField
                id="end_date"
                label="Expected Delivery Date"
                type="date"
                name="expectedDeliveryDate"
                onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}
                className={classes.textFieldWidth}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div style={{ paddingBottom: 15 }}>
              <div style={{ paddingBottom: 15, paddingTop: 10 }}>
                <input
                  accept="*"
                  ref={projectDocumentRef}
                  className={classes.input}
                  id="project-document"
                  onChange={uploadProductDocument}
                  type="file"
                />
                <label htmlFor="project-document">
                  <Button variant="contained" color="primary" style={{ background: "#716ACA", margin: 5 }} component="span">
                    Upload Project Document
                  </Button>
                </label>
              </div>
              {productDocument &&
                <>
                  <div style={{ paddingBottom: 15, paddingTop: 10 }}>
                    <input
                      accept=".pdf"
                      ref={WorkingDrawingPdfRef}
                      className={classes.input}
                      id="working-drawing"
                      onChange={(e) => uploadDocuments(e, 'WorkingDrawingPDF')}
                      type="file"
                    />
                    <label htmlFor="working-drawing">
                      <Button variant="contained" color="primary" style={{ background: "#716ACA", margin: 5 }} component="span">
                        Upload Working Drawing Pdf
                      </Button>
                    </label>
                  </div>
                  <div style={{ paddingBottom: 15, paddingTop: 10 }}>
                    <input
                      accept=".pdf"
                      ref={ContractSignaturePdfRef}
                      className={classes.input}
                      id="contract-signature"
                      onChange={(e) => uploadDocuments(e, 'ContractSignaturePDF')}
                      type="file"
                    />
                    <label htmlFor="contract-signature">
                      <Button variant="contained" color="primary" style={{ background: "#716ACA", margin: 5 }} component="span">
                        Upload Contract Signature Pdf
                      </Button>
                    </label>
                  </div>
                  <div style={{ paddingBottom: 15, paddingTop: 10 }}>
                    <input
                      accept=".pdf"
                      ref={CheckListRef}
                      className={classes.input}
                      id="checklist"
                      onChange={(e) => uploadDocuments(e, 'CheckList')}
                      type="file"
                    />
                    <label htmlFor="checklist">
                      <Button variant="contained" color="primary" style={{ background: "#716ACA", margin: 5 }} component="span">
                        Upload CheckList
                      </Button>
                    </label>
                  </div>
                </>}
            </div>
            <div style={{ display: "flex", flexDirection: "row-reverse", marginTop: 10 }}>
              <Button variant="contained" color="primary" onClick={() => { getAllProjects(); setUploadDocumentModal(false) }} style={{ background: "#716ACA", margin: 5 }} component="span">
                Submit
              </Button>
            </div>
          </div>}
          {existingProject && <div style={{ width: '50%', display: "flex", flexDirection: "column", float: "right" }}>
            <div style={{ paddingBottom: 20, paddingTop: 20 }}>
              <Autocomplete
                value={projectSelected}
                onChange={(e, v) => setProjectSelected(v)}
                id="project-code-dropdown"
                options={projects}
                getOptionLabel={(option) => option.searchName}
                style={{ width: 500 }}
                renderInput={(params) => <TextField {...params} label="Select Project" fullWidth variant="outlined" />}
              />
            </div>
            <div style={{ paddingBottom: 20, paddingTop: 20 }}>
              <InputLabel id="project-doc">Select Document Type</InputLabel>
              <Select
                labelId="project-doc"
                value={fileType}
                style={{ width: 500 }}
                onChange={(e) => setFileType(e.target.value)}
              >
                <MenuItem value='ProductDoc'>Project Document</MenuItem>
                <MenuItem value='WorkingDrawingPDF'>Working Drawing Pdf</MenuItem>
                <MenuItem value='ContractSignaturePDF'>Contract Signature Pdf</MenuItem>
                <MenuItem value='CheckList'>CheckList</MenuItem>
              </Select>
            </div>
            {/* <div> */}
            <div style={{ paddingBottom: 15, paddingTop: 10 }}>
              <input
                accept="*"
                className={classes.input}
                id="files"
                onChange={(e) => uploadDocuments(e, fileType)}
                type="file"
              />
              <label htmlFor="files">
                <Button variant="contained" color="primary" style={{ background: "#716ACA", margin: 5 }} component="span">
                  Upload
                </Button>
              </label>
            </div>
            {/* <input
                accept="*"
                // className={classes.input}
                onChange={chooseFile}
                type="file"
              />
            </div>
            <div style={{ display: "flex", flexDirection: "row-reverse", marginTop: 10 }}>
              <Button variant="contained" onClick={(e) => uploadDocuments(e)} color="primary" style={{ background: "#716ACA", margin: 5 }} component="span">
                Submit
              </Button>
            </div> */}
          </div>}
        </div>
      </Modal>
    </div >
  )
}

export default connect(null, { setLoading, getAllProjects, setSnackInfo })(ProjectDcouments);
