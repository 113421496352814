import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { setLoading, setSnackInfo } from '../redux/actions/appAction';
import { makeStyles } from '@material-ui/core/styles';
import {
    Paper,
    FormControl,
    TextField,
    Button,
    MenuItem,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    TablePagination,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import HttpService from '../services/httpService';
import { ROLENAME } from '../utils';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
    header: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    content: {
        marginTop: theme.spacing(1),
    },
    answer: {
        marginTop: theme.spacing(1),
        color: theme.palette.text.secondary,
    },
    accordion: {
        margin: theme.spacing(2),
    },
}));

const FaqCreation = (props) => {
    const { userDetails } = useSelector(state => state.user);
    const classes = useStyles();

    const [open, setOpen] = useState(false);
    const [formType, setFormType] = useState('faq');
    const [selectedStoreId, setSelectedStoreId] = useState('');
    const [answer, setTimeline] = useState('');
    const [faqTitle, setFaqTitle] = useState('');
    const [faqContent, setFaqContent] = useState('');
    const [categoriesName, setCatogoriesName] = useState([]);
    const [faqDetails, setFaqDetails] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [totalPages, setTotalPages] = useState(0);
    const [storeCategories, setStoreCatogrories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [inputCategoriesSearch,setInputCategoriesSearch] = useState("")

    useEffect(() => {
        getFaqCategories();
        faqInfo();
    }, [rowsPerPage, page, selectedCategories]);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setFormType('faq');
    };

    const handleStoreChange = (value) => {
        setSelectedStoreId(value);
    };

    const handleTimelineChange = (e) => {
        setTimeline(e.target.value);
    };

    const handleFaqTitleChange = (e) => {
        setFaqTitle(e.target.value);
    };

    const handleFaqContentChange = (e) => {
        setFaqContent(e.target.value);
    };

    const getFaqCategories = async () => {
        try {
            const res = await HttpService.getFaqCategories();
            setCatogoriesName(res.data);
        } catch (error) {
            console.error(error);
        }
    };

    const handleSubmit = async () => {
        if (formType === 'categories') {
            try {
                const data = { categories: selectedStoreId };
                await HttpService.createCategories(data);
                props.setSnackInfo("Category Created Successfully", "success");
            } catch (error) {
                console.error(error);
                props.setSnackInfo("Error creating category", "error");
            }
        } else if (formType === 'faq') {
            try {
                const data = {
                    categoryId: faqTitle,
                    content: faqContent,
                    answer: answer
                };
                await HttpService.createFaq(data);
                faqInfo();
                props.setSnackInfo("FAQ Created Successfully", "success");
            } catch (error) {
                console.error(error);
                props.setSnackInfo("Error creating FAQ", "error");
            }
        }
        setOpen(false);
    };

    const faqInfo = async () => {
        props.setLoading(true);
        try {
            const obj = {
                page: page,
                limit: rowsPerPage,
                inputCategoriesSearch : inputCategoriesSearch,
                categoriesId: selectedCategories.map(cat => cat._id) // Map to extract the IDs
            };

            const res = await HttpService.faqDetails(obj);
            setFaqDetails(res.data.FaqDetails);
            setTotalPages(res.data.totalFaqs);
        } catch (error) {
            console.error(error);
        }
        props.setLoading(false);
    };

    const handleStoreCatogoriesId = (event, newValue) => {
        setSelectedCategories(newValue);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const clearAllFilter = () =>{
        setInputCategoriesSearch('')
        setSelectedCategories([])
    }

    const availableOptions = categoriesName.filter((el) => !selectedCategories.some(cat => cat._id === el._id));



    return (
        <Paper className={classes.paper}>
 <Grid container alignItems="center" spacing={2}>
            <Grid item xs={4}>
                <FormControl fullWidth>
                    <Autocomplete
                        multiple
                        value={selectedCategories}
                        onChange={handleStoreCatogoriesId}
                        options={availableOptions}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Select Catogories"
                                variant="outlined"
                            />
                        )}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <TextField
                    fullWidth
                    label="Search Other *"
                    style={{  textAlign: "center" }}
                    name="reasonForOtherLeadType"
                    value ={inputCategoriesSearch}
                    onChange={(e) => setInputCategoriesSearch(e.target.value)}
                    variant="outlined"
                />
            </Grid>
            <Grid item xs={2}>
                <Button fullWidth variant="contained" color="primary" onClick={faqInfo}>
                    Search
                </Button>
            </Grid>
            <Grid item xs={2}>
                <Button fullWidth variant="contained" color="secondary" onClick={clearAllFilter}>
                    Clear Filter
                </Button>
            </Grid>
        </Grid>
            <TablePagination
                rowsPerPageOptions={[100, 250, 500]}
                count={totalPages}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

            <div style={{ marginBottom: "2rem", display: "flex", justifyContent: "flex-end" }}>
                {userDetails.roles.find((el) => el.name === ROLENAME.Admin) && (
                    <Button variant="contained" color="primary" onClick={handleClickOpen}>
                        Create FAQ / Categories
                    </Button>
                )}

                <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">
                        {formType === 'faq' ? 'Create FAQ' : 'Create Category'}
                    </DialogTitle>
                    <DialogContent>
                        {formType === 'faq' ? (
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TextField
                                        select
                                        id="faq-Categoriy"
                                        value={faqTitle}
                                        label="FAQ-Category"
                                        onChange={handleFaqTitleChange}
                                        variant="outlined"
                                        size='small'
                                        fullWidth
                                    >
                                        {categoriesName.map((el) => (
                                            <MenuItem key={el._id} value={el._id}>
                                                {el.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="faq-content"
                                        value={faqContent}
                                        label="FAQ Question"
                                        onChange={handleFaqContentChange}
                                        variant="outlined"
                                        size='small'
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="faq-question"
                                        value={answer}
                                        label="FAQ Answer"
                                        onChange={handleTimelineChange}
                                        variant="outlined"
                                        size='small'
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TextField
                                        id="FAQ-Categories"
                                        value={selectedStoreId}
                                        label="FAQ-Categories"
                                        onChange={(e) => handleStoreChange(e.target.value)}
                                        variant="outlined"
                                        size='small'
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </DialogContent>
                    <DialogActions>
                        {formType === 'faq' ? (
                            <Button variant="contained" onClick={() => setFormType('categories')} color="secondary">
                                Switch to Create Category
                            </Button>
                        ) : (
                            <Button variant="contained" onClick={() => setFormType('faq')} color="primary">
                                Switch to Create FAQ
                            </Button>
                        )}
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleSubmit} color="primary">
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>

            <div>
                {faqDetails.map((el, index) => (
                    <Accordion key={index} style={{ marginTop: "20px" }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <LiveHelpIcon style={{ marginLeft: "5px" }} />
                            <Typography style={{ marginLeft: "5px" }}>
                                <b>{el.content}</b>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ marginBottom: "10px" }}>
                            <Typography>
                                Answer: {el.answer}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </div>
        </Paper>
    );
};

export default connect(null, { setLoading, setSnackInfo })(FaqCreation);