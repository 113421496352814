import React, { Fragment, useEffect, useRef, useState } from "react";
import {
	AppBar,
	Grid,
	TextField,
	Box,
	Paper,
	Tab,
	Tabs,
	Typography,
	makeStyles,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	withStyles,
	Button,
} from "@material-ui/core";
import PropTypes from "prop-types";
import HttpService from "../../services/httpService";
import { setLoading } from "../../redux/actions/appAction";
import { connect, useSelector } from "react-redux";
import { Modal } from "antd";
import moment from "moment";
import { CSVLink } from "react-csv";
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import {
	CONST_USER_ID,
	PalaPrasanthKumarId,
	PalisettiTejaId,
	ROLENAME,
	SriKrishnaId,
	ThirumaleshId,
} from "../../utils";
import { Link } from 'react-router-dom'


function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const useStyles = makeStyles((theme) => ({
	root: {
		"& > *": {
			margin: theme.spacing(1),
		},
	},
	// table: {
	// 	maxWidth:"67vw",
	// },
	tablecontainer: {
		display: "flex",
		margin: "25px",
	},
	customTableContainer: {
		overflowX: "initial",
	},
	tableform: {
		display: "flex",
		maxWidth: "68vw",
		textAlign: "center"
	},
	paper: {
		padding: theme.spacing(2),
		margin: "auto",
		maxWidth: 400,
	},
	clickableTableCell: {
		cursor: "pointer",
		textDecoration: "underline",
		color: "blue",
	},
	normalTableCell: {
		color: "black",
		"&:hover": {
			cursor: "pointer",
			color: "blue", // you can change the color when hovering here
			textDecoration: "underline",
		},
	},
	tableCell: {
		color: "blue",
		cursor: "pointer",
		textDecoration: "underline",

		// Changing color, cursor and textDecoration on hovering
		// "&:hover": {
		// 	cursor: "pointer",
		// 	color: "blue", // you can change the color when hovering here
		// 	textDecoration: "underline",
		// },
	},
	csv: {
		display: "flex",
		alignItems: "center",
		marginBottom: 20,
	},
	narrowTab: {
		minWidth: "fit-content", // Adjust the width as needed
		// width:"fit-content",
		width: 65,
	},
	margin: {
		margin: theme.spacing(1),
	},
	activeButton: {
		backgroundColor: "#F50057 !important",
		color: "white !important",
		transition: "background-color 0.3s, color 0.3s",
	},
	leadSearchButton: {
		backgroundColor: '#b7ccf7',
		color: '#151921',
		padding: '1',
		marginRight: '0.5em',
		borderRadius: '20px',
		width: '20%',
		"&:hover": { backgroundColor: "#14419c", color: 'white' }
	},
	leadClearButton: {
		backgroundColor: '#f7b7b7', // light red background
		color: '#151921',
		padding: '1',
		marginRight: '0.5em',
		borderRadius: '20px',
		width: '20%',
	}
}));

const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: "#3F51B5",
		color: "#FFF",
		fontWeight: "500",
		width: "102%"
	},
	body: {
		fontSize: 14,
	},
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
	root: {
		"&:nth-of-type(odd)": {
			backgroundColor: theme.palette.action.hover,
		},
	},
}))(TableRow);

const FactoryReviewSheet = (props) => {
	const classes = useStyles();
	const [value, setValue] = useState("");
	const { userDetails } = useSelector((state) => state.user);
	const [materialDeliveryData, setMaterialDeliveryData] = useState([]);
	const [underExecutionData, setUnderExecutionData] = useState([]);
	const [projectCompetionData, setProjectCompletionData] = useState([]);
	const [completedMwpData, setCompletedMwpData] = useState([]);
	const [factoryStageData, setFactoryStageData] = useState([]);
	const [factoryDataForOps1, setFactoryDataForOps1] = useState([]);
	const [completedProjectData, setCompletedProjectData] = useState([]);
	const [showDetailedModal, setShowDetailedModal] = useState(false);
	const [detailDataArray, setDetailDataArray] = useState([]);
	const [title, setTitle] = useState("Dummy Title");
	const [csvData, setCsvData] = useState([]);
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const csvButtonRef = useRef(null);
	const [ShowDesignSignOffRevokeDate, setShowDesignSignOffRevokeDate] = useState(false)


	const [materialDeliveryPercentages, setMaterialDeliveryPercentages] = useState([]);
	const [projectCompletionPercentages, setProjectCompletionPercentages] = useState([]);
	const [factoryDataForOps1Percentages, setFactoryDataForOps1Percentages] = useState([]);
	const [underExecutionPercentages, setUnderExecutionPercentages] = useState([]);
	const [searchInput, setSearchInput] = useState("")
	const [selectedSearchType, setSelectedSearchType] = useState("");
	const searchTypes = ['Customer Name', 'Email ID', 'Lead No', 'Project No', 'Contact No', 'Unique Lead No']

	let includeExperienceCenter = detailDataArray.some(item => item.experienceCenterName !== "" && item.experienceCenterName !== null && item.experienceCenterName !== undefined);
	let includeFactoryBomCompletedDate = detailDataArray.some(item => item.factoryBomCompletedDate !== "" && item.factoryBomCompletedDate !== null && item.factoryBomCompletedDate !== undefined);
	let includeRevocationDate = detailDataArray.some(item => item.designSignoffRevokeDate !== "" && item.designSignoffRevokeDate !== null && item.designSignoffRevokeDate !== undefined);
	let includedesignerAndAdmName = detailDataArray.some(item => item.designManagerName !== "" && item.designManagerName !== null && item.designManagerName !== undefined);
	let includeChmName = detailDataArray.some(item => item.chmUserName !== "" && item.chmUserName !== null && item.chmUserName !== undefined);
	let includeCustomerName = detailDataArray.some(item => item.customerName !== "" && item.customerName !== null && item.customerName !== undefined);
	let includeCustomerAddress = detailDataArray.some(item => item.customerAddress !== "" && item.customerAddress !== null && item.customerAddress !== undefined);
	let includeContractQualityControlApproved = detailDataArray.some(item => item.contractQualityControlApproved !== "" && item.contractQualityControlApproved !== null && item.contractQualityControlApproved !== undefined);
	let includeContractQCManagerApproved = detailDataArray.some(item => item.contractQCManagerApproved !== "" && item.contractQCManagerApproved !== null && item.contractQCManagerApproved !== undefined);
	let includeContractDesignManagerApproved = detailDataArray.some(item => item.contractDesignManagerApproved !== "" && item.contractDesignManagerApproved !== null && item.contractDesignManagerApproved !== undefined);
	let includeContractCustomerApproved = detailDataArray.some(item => item.contractCustomerApproved !== "" && item.contractCustomerApproved !== null && item.contractCustomerApproved !== undefined);
	let includeContractFinanceApproved = detailDataArray.some(item => item.contractFinanceApproved !== "" && item.contractFinanceApproved !== null && item.contractFinanceApproved !== undefined);
	let includeMaterialDeliveryDate = detailDataArray.some(item => item.materialDispatchedDate !== "" && item.materialDispatchedDate !== null && item.materialDispatchedDate !== undefined);
	let includeActualCompletionDate = detailDataArray.some(item => item.executionCompletionDate !== "" && item.executionCompletionDate !== null && item.executionCompletionDate !== undefined);
	let includeExecutionMWPDate = detailDataArray.some(item => item.executionMWPDate !== "" && item.executionMWPDate !== null && item.executionMWPDate !== undefined);


	// Headers for csv file
	const headers = [
		{ key: "serialNo", label: "Sl. No." },
		{ key: "projectCode", label: "Project No." },
		{ key: "leadNo", label: "Lead No" },
		{ key: "currentStage", label: "Stage" },
		{ key: "departmentName", label: "Department" },
		{ key: "totalValue", label: "Value (INR)" },
		{ key: "cityName", label: "City Name" },
		{ key: "salesWonDate", label: "Sales Won Date" },
		{ key: "designSignOffDate", label: "Design Sign-Off Date" },
		// { key: "materialDeliveryDate", label: "Material Delivery Date" },
		// { key: "actualCompletionDate", label: "Actual Completion Date" },
		// { key: "executionMWPDate", label: "MWP Completion Date" },
		// { key: "designerAndAdmName", label: "Designer/ADM name" },
		// { key: "apartmentName", label: "Apartment Name" },
		// { key: "currentStage", label: "Current stage" },
		// { key: "customerName", label: "Customer name" },
		// { key: "chmName", label: "CHM Name" },
	];

	if (includeExperienceCenter) {
		headers.push({ key: "experienceCenter", label: "Experience Center" });
	}
	if (includeFactoryBomCompletedDate) {
		headers.push({ key: "factoryBomCompletedDate", label: "Factory Assignment Date" });
	}
	if (includeRevocationDate) {
		headers.push({ key: "revocationDate", label: "Design-Hold Revoke Date" });
	}
	if (includedesignerAndAdmName) {
		headers.push({ key: "designerAndAdmName", label: "Designer/ADM name" });
	}
	if (includeChmName){
		headers.push({ key: "chmName", label: "CHM Name" });
	}
	if (includeCustomerName){
		headers.push({ key: "customerName", label: "Customer name" });
	}
	if (includeContractQualityControlApproved){
		headers.push({ key: "contractQualityControlApproved", label: "QC Approval" });
	}
	if (includeContractQCManagerApproved){
		headers.push({ key: "contractQCManagerApproved", label: "QC Manager Approval" });
	}
	if (includeContractDesignManagerApproved){
		headers.push({ key: "contractDesignManagerApproved", label: "ADM Approval" });
	}
	if (includeContractCustomerApproved){
		headers.push({ key: "contractCustomerApproved", label: "Customer Approval" });
	}
	if (includeContractFinanceApproved){
		headers.push({ key: "contractFinanceApproved", label: "Finance Approval" });
	}
	if (includeMaterialDeliveryDate){
		headers.push({ key: "materialDeliveryDate", label: "Material Delivery Date" });
	}
	if (includeActualCompletionDate){
		headers.push({ key: "actualCompletionDate", label: "Actual Completion Date" });
	}
	if (includeExecutionMWPDate){
		headers.push({ key: "executionMWPDate", label: "MWP Completion Date" });
	}
	if (includeCustomerAddress){
		headers.push({ key: "apartmentName", label: "Apartment Name" });
	}

	const formatToLakhs = (value) => {
		const valueInLakhs = value / 100000;
		return valueInLakhs.toLocaleString("en-IN", {
		  maximumFractionDigits: 2,
		  minimumFractionDigits: 2
		});
	};
	// Function to handle tab changes
	const handleChange = (newValue) => {
		setValue(newValue);
		setStartDate("");
		setEndDate("");
		setSearchInput("")
		setSelectedSearchType("")
	};

	const handleSearchType = (value) => {
		setSelectedSearchType(value)
		setSearchInput('')
	}

	const handleSearch = async (diffValue) => {
		if (value === "Summary of Delivery") {
			getMaterialDeliveryDataCityWise(startDate, endDate, selectedSearchType, searchInput)
		} else if (value === "Summary of Completion") {
			getProjectCompletionDataCityWise(startDate, endDate, selectedSearchType, searchInput)
		} else if (value === "Ops-1") {
			getFactoryManagerDataForOps1Tab(selectedSearchType, searchInput);
		} else if (value === "Ops-2 (Factory)") {
			getFactoryManagerDataStageWise(selectedSearchType, searchInput);
		} else if (value === "Ops-3 (Execution)") {
			getUnderExecutionDataCityWise(startDate, endDate, selectedSearchType, searchInput);
		} else if (value === "Ops-3 (MWP)") {
			getCompletedMWPDataUserWise(selectedSearchType, searchInput);
		} else if (value === "Completed Project") {
			getProjectCompletionDataUserWise(selectedSearchType, searchInput);
		} else if (value === "Stage-Value") {
			getMaterialDeliveryDataCityWise(startDate, endDate, selectedSearchType, searchInput);
			getProjectCompletionDataCityWise(startDate, endDate, selectedSearchType, searchInput);
			getFactoryManagerDataForOps1Tab(selectedSearchType, searchInput);
			getUnderExecutionDataCityWise(startDate, endDate, selectedSearchType, searchInput);
		}
	}

	// Function to get Material Delivery Project's count loction based
	const getMaterialDeliveryDataCityWise = async (startDate, endDate, selectedSearchType, searchInput) => {
		props.setLoading(true);
		let obj = {
			startDate: startDate,
			endDate: endDate,
			searchType: selectedSearchType ? selectedSearchType : "",
			search: searchInput ? searchInput.toUpperCase() : ""
		}
		try {
			const response = await HttpService.getMaterialDeliveryCityWise(obj);
			setMaterialDeliveryData(response.data);
		} catch (error) {
			console.log("Error ->", error);
		}
		props.setLoading(false);
	};

	// Function to get Project's count which are under execution
	const getUnderExecutionDataCityWise = async (startDate, endDate, selectedSearchType, searchInput) => {
		props.setLoading(true);
		let obj = {
			startDate: startDate,
			endDate: endDate,
			searchType: selectedSearchType ? selectedSearchType : "",
			search: searchInput ? searchInput.toUpperCase() : ""
		}
		try {
			const response = await HttpService.getUnderExecutionDataCityWise(obj);
			setUnderExecutionData(response.data);
		} catch (error) {
			console.log("Error ->", error);
		}
		props.setLoading(false);
	};

	// Function to get Project's count which are completed
	const getProjectCompletionDataCityWise = async (startDate, endDate, selectedSearchType, searchInput) => {
		props.setLoading(true);
		let obj = {
			startDate: startDate,
			endDate: endDate,
			searchType: selectedSearchType ? selectedSearchType : "",
			search: searchInput ? searchInput.toUpperCase() : ""
		}
		try {
			const response = await HttpService.getProjectCompletionDataCityWise(obj);
			setProjectCompletionData(response.data);
		} catch (error) {
			console.log("Error ->", error);
		}
		props.setLoading(false);
	};

	// Function to get Project's count which are under completed mwp
	const getCompletedMWPDataUserWise = async (selectedSearchType, searchInput) => {
		props.setLoading(true);
		let obj = {
			searchType: selectedSearchType ? selectedSearchType : "",
			search: searchInput ? searchInput.toUpperCase() : ""
		}
		try {
			const response = await HttpService.getCompletedMWPDataUserWise(obj);
			setCompletedMwpData(response.data);
		} catch (error) {
			console.log("Error Here ->", error);
		}
		props.setLoading(false);
	};

	// Function to get Project's count which are assigned to factory manager
	const getFactoryManagerDataStageWise = async (selectedSearchType, searchInput) => {
		props.setLoading(true);
		let obj = {
			searchType: selectedSearchType ? selectedSearchType : "",
			search: searchInput ? searchInput.toUpperCase() : ""
		}
		try {
			const response = await HttpService.getFactoryStageData(obj);
			setFactoryStageData(response.data);
		} catch (error) {
			console.log("Error Here ->", error);
		}
		props.setLoading(false);
	};

	// Function to get Project's count for ops-1 tab
	const getFactoryManagerDataForOps1Tab = async (selectedSearchType, searchInput) => {
		props.setLoading(true);
		let obj = {
			searchType: selectedSearchType ? selectedSearchType : "",
			search: searchInput ? searchInput.toUpperCase() : ""
		}
		try {
			const response = await HttpService.getDataForOps1Tab(obj);
			setFactoryDataForOps1(response.data);
		} catch (error) {
			console.log("Error Here ->", error);
		}
		props.setLoading(false);
	};

	// Function to get Project's count for Completed Tab
	const getProjectCompletionDataUserWise = async (selectedSearchType, searchInput) => {
		props.setLoading(true);
		let obj = {
			searchType: selectedSearchType ? selectedSearchType : "",
			search: searchInput ? searchInput.toUpperCase() : ""
		}
		try {
			const response = await HttpService.getProjectCompletionDataUserWise(obj);
			setCompletedProjectData(response.data);
		} catch (error) {
			console.log("Error Here ->", error);
		}
		props.setLoading(false);
	};

	// Function to show Details
	const showDetailedModalHandler = (data, text) => {
		try {
			let call = data.map((el)=>el.currentStage);
			let checkStage = call.every((el)=>el === 'Design Sign-off HOLD');
			setShowDesignSignOffRevokeDate(checkStage)
			setShowDetailedModal(true);
			setTitle(text);
			setDetailDataArray(data);
		} catch (error) {
			console.log("Error - ", error);
		}
	};

	// Function to download csv
	const csvDownloadHandler = async (data) => {
		setLoading(true);
		try {
			let csvArray = [];
			for (let i = 0; i < detailDataArray.length; i++) {
				let obj = {
					serialNo: i + 1,
					projectCode: detailDataArray[i]?.erpProjectNo || "N/A",
					leadNo: detailDataArray[i]?.lead_no,
					currentStage: detailDataArray[i]?.currentStage,
					departmentName: detailDataArray[i]?.departmentName,
					totalValue: detailDataArray[i]?.grandTotal / 1.18 || "-",
					cityName: detailDataArray[i]?.cityName,
					salesWonDate: detailDataArray[i]?.leadWonDate
						? moment(detailDataArray[i]?.leadWonDate).format("DD-MM-YYYY")
						: "-",
					designSignOffDate: detailDataArray[i]?.customerDesignSignOffDate
						? moment(detailDataArray[i]?.customerDesignSignOffDate).format(
							"DD-MM-YYYY"
						)
						: "-",
					materialDeliveryDate: detailDataArray[i]?.materialDispatchedDate
						? moment(detailDataArray[i]?.materialDispatchedDate).format(
							"DD-MM-YYYY"
						)
						: "-",
					actualCompletionDate: detailDataArray[i]?.executionCompletionDate
						? moment(detailDataArray[i]?.executionCompletionDate).format(
							"DD-MM-YYYY"
						)
						: "-",
					executionMWPDate: detailDataArray[i]?.executionMWPDate
						? moment(detailDataArray[i]?.executionMWPDate).format(
							"DD-MM-YYYY"
						)
						: "-",
					designerAndAdmName: detailDataArray[i]?.designUserName + " / " + detailDataArray[i]?.designManagerName,
					apartmentName: detailDataArray[i]?.customerAddress,
					customerName: detailDataArray[i]?.customerName,
					chmName: detailDataArray[i]?.chmUserName,
					factoryBomCompletedDate: detailDataArray[i]?.factoryBomCompletedDate
					? moment(detailDataArray[i]?.factoryBomCompletedDate).format(
						"DD-MM-YYYY"
					)
					: "-",
					revocationDate: detailDataArray[i]?.designSignoffRevokeDate
					? moment(detailDataArray[i]?.designSignoffRevokeDate).format(
						"DD-MM-YYYY"
					)
					: "-",
					experienceCenter: detailDataArray[i]?.experienceCenterIdName,
					contractQualityControlApproved: detailDataArray[i]?.contractQualityControlApproved,
					contractQCManagerApproved: detailDataArray[i]?.contractQCManagerApproved,
					contractDesignManagerApproved: detailDataArray[i]?.contractDesignManagerApproved,
					contractCustomerApproved:  detailDataArray[i]?.contractCustomerApproved,
					contractFinanceApproved: detailDataArray[i]?.contractFinanceApproved,
				};
				csvArray.push(obj);
				obj = {
					serialNo: "",
					projectCode: "",
					leadNo: "",
					totalValue: "",
					cityName: "",
					salesWonDate: "",
					designSignOffDate: "",
					materialDeliveryDate: "",
					actualCompletionDate: "",
					revocationDate: "",
					factoryBomCompletedDate:"",
					experienceCenter:"",
				};
				csvArray.push(obj);
			}
			setCsvData(csvArray);
			downloadExcel();
		} catch (error) {
			console.log("error", error);
			props?.setSnackInfo(
				error?.response?.data?.message ?? "Something went wrong",
				"error"
			);
		}
		setLoading(false);
	};

	const downloadExcel = () => {
		setLoading(true);
		try {
			setTimeout(() => {
				csvButtonRef.current.link.click();
			});
		} catch (err) {
			console.error(err.message);
		}
		setLoading(false);
	};

	// Function to find Match if the UserId matches any of the userId provide in the 'CONST_USER_ID'
	const findMatchingUser = (idToCheck) => {
		for (const [user, userId] of Object.entries(CONST_USER_ID)) {
			if (userId === idToCheck) {
				return true; // Return the user whose ID matches
			}
		}
		return false; // Return null if no match is found
	};

	// Function verify User
	const verifyDataVisibilityAccess = () => {
		if (
			findMatchingUser(userDetails?._id) ||
			userDetails?.roles.find(
				(role) => role.name === ROLENAME.DGM_Ops || role.name === ROLENAME.DataAnalyst || role.name === ROLENAME.FinanceManager
			)
		) {
			setValue("Summary of Delivery");
		} else if (
			userDetails?.roles.find(
				(role) => role.name === ROLENAME.SeniorOperationManager || role.name === ROLENAME.Admin
			)
		) {
			setValue("Ops-1");
		} else if (
			userDetails?.roles.find(
				(role) => role.name === ROLENAME.FactoryManager
			) &&
			userDetails?._id.toString() !== ThirumaleshId
		) {
			setValue("Ops-2 (Factory)");
		} else if (
			userDetails?.roles.find(
				(role) =>
					role.name === ROLENAME.DGM_Ops ||
					role.name === ROLENAME.Execution_Manager
			) ||
			userDetails?._id === SriKrishnaId
		) {
			setValue("Ops-3 (Execution)");
		} else if (
			userDetails?.roles.find(
				(role) =>
					role.name === ROLENAME.DGM_Ops || role.name === ROLENAME.ExecutionUser
			) ||
			userDetails?._id === PalisettiTejaId ||
			userDetails?._id === PalaPrasanthKumarId
		) {
			setValue("Ops-3 (MWP)");
		}
	};

	const dateFilterHandler = () => {
		if (value === "Summary of Delivery")
			getMaterialDeliveryDataCityWise(startDate, endDate, selectedSearchType, searchInput);
		if (value === "Summary of Completion")
			getProjectCompletionDataCityWise(startDate, endDate, selectedSearchType, searchInput);
		if (value === "Stage-Value") {
			getMaterialDeliveryDataCityWise(startDate, endDate, selectedSearchType, searchInput);
			getProjectCompletionDataCityWise(startDate, endDate, selectedSearchType, searchInput);
			getFactoryManagerDataForOps1Tab(selectedSearchType, searchInput);
			getUnderExecutionDataCityWise(startDate, endDate, selectedSearchType, searchInput);
		} else if (value === "Ops-3 (Execution)") {
			getUnderExecutionDataCityWise(startDate, endDate, selectedSearchType, searchInput);
		}
	};

	const clearDateFilter = () => {
		setStartDate("");
		setEndDate("");
		setSearchInput("")
		setSelectedSearchType("")
		if (value === "Summary of Delivery") {
			getMaterialDeliveryDataCityWise("", "", "", "")
		} else if (value === "Summary of Completion") {
			getProjectCompletionDataCityWise("", "", "", "")
		} else if (value === "Ops-1") {
			getFactoryManagerDataForOps1Tab("", "");
		} else if (value === "Ops-2 (Factory)") {
			getFactoryManagerDataStageWise("", "");
		} else if (value === "Ops-3 (Execution)") {
			getUnderExecutionDataCityWise("", "", "", "");
		} else if (value === "Ops-3 (MWP)") {
			getCompletedMWPDataUserWise("", "");
		} else if (value === "Completed Project") {
			getProjectCompletionDataUserWise("", "");
		} else if (value === "Stage-Value") {
			getMaterialDeliveryDataCityWise("", "", "", "");
			getProjectCompletionDataCityWise("", "", "", "");
			getFactoryManagerDataForOps1Tab("", "");
			getUnderExecutionDataCityWise("", "", "", "");
		}
	};

	useEffect(() => {
		if (value === "") {
			verifyDataVisibilityAccess();
		}
		if (value === "Summary of Delivery")
			getMaterialDeliveryDataCityWise(startDate, endDate, selectedSearchType, searchInput);
		else if (value === "Summary of Completion")
			getProjectCompletionDataCityWise(startDate, endDate, selectedSearchType, searchInput);
		else if (value === "Ops-1") getFactoryManagerDataForOps1Tab(selectedSearchType, searchInput);
		else if (value === "Ops-2 (Factory)") getFactoryManagerDataStageWise(selectedSearchType, searchInput);
		else if (value === "Ops-3 (Execution)") getUnderExecutionDataCityWise(startDate, endDate, selectedSearchType, searchInput);
		else if (value === "Ops-3 (MWP)") getCompletedMWPDataUserWise(selectedSearchType, searchInput);
		else if (value === "Completed Project") getProjectCompletionDataUserWise(selectedSearchType, searchInput);
		else if (value === "Stage-Value") {
			getMaterialDeliveryDataCityWise(startDate, endDate, selectedSearchType, searchInput);
			getProjectCompletionDataCityWise(startDate, endDate, selectedSearchType, searchInput);
			getFactoryManagerDataForOps1Tab(selectedSearchType, searchInput);
			getUnderExecutionDataCityWise(startDate, endDate, selectedSearchType, searchInput);
		}
	}, [value]);

	const handlePercentageChangeDelivery = (e, index) => {
		const value = e.target.value;
		setMaterialDeliveryPercentages((prev) => {
			const newPercentages = [...prev];
			newPercentages[index] = value;
			return newPercentages;
		});
	};

	const calculateValueDelivery = (index) => {
		const percentage = materialDeliveryPercentages[index] || 0;
		const grandTotal = materialDeliveryData.reduce((acc, data) => {
			const value = data.monthlyData[index]?.grandTotal || 0;
			return acc + (value / 1.18);
		}, 0);
		const valueInLakhs = (grandTotal * (percentage / 100)) / 2 / 100000;
		return valueInLakhs.toLocaleString("en-IN", {
			maximumFractionDigits: 2,
			minimumFractionDigits: 2
		});
	};	  

	const handlePercentageChangeCompletion = (e, index) => {
		const value = e.target.value;
		setProjectCompletionPercentages((prev) => {
			const newPercentages = [...prev];
			newPercentages[index] = value;
			return newPercentages;
		});
	};

	const calculateValueCompletion = (index) => {
		const percentage = projectCompletionPercentages[index] || 0;
		const grandTotal = projectCompetionData.reduce((acc, data) => {
			const value = data.monthlyData[index]?.grandTotal || 0;
			return acc + (value / 1.18);
		}, 0);
		const valueInLakhs = (grandTotal * (percentage / 100)) / 2 / 100000;
		return valueInLakhs.toLocaleString("en-IN", {
			maximumFractionDigits: 2,
			minimumFractionDigits: 2
		});
	}

	const handlePercentageChangeOps = (e, index) => {
		const value = e.target.value;
		setFactoryDataForOps1Percentages((prev) => {
			const newPercentages = [...prev];
			newPercentages[index] = value;
			return newPercentages;
		});
	};

	const calculateValueOps = (index) => {
		const percentage = factoryDataForOps1Percentages[index] || 0;
		const grandTotal = factoryDataForOps1.reduce((acc, data) => {
			const value = data.stageData[index]?.grandTotal || 0;
			return acc + (value / 1.18);
		}, 0);
		const valueInLakhs = (grandTotal * (percentage / 100)) / 2 / 100000;
		return valueInLakhs.toLocaleString("en-IN", {
			maximumFractionDigits: 2,
			minimumFractionDigits: 2
		});
	}

	const handlePercentageChangeOps3 = (e, index) => {
		const value = e.target.value;
		setUnderExecutionPercentages((prev) => {
			const newPercentages = [...prev];
			newPercentages[index] = value;
			return newPercentages;
		});
	};

	const calculateValueOps3 = (index) => {
		const percentage = underExecutionPercentages[index] || 0;
		const grandTotal = underExecutionData.reduce((acc, data) => {
			const value = data.monthlyData[index]?.grandTotal || 0;
			return acc + (value / 1.18);
		}, 0);
		const valueInLakhs = (grandTotal * (percentage / 100)) / 2 / 100000;
		return valueInLakhs.toLocaleString("en-IN", {
			maximumFractionDigits: 2,
			minimumFractionDigits: 2
		});
	}

	return (
		<Fragment>
			{/* <AppBar position="static">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="simple tabs example"
                >
                    <Tab
                        label="Summary of Delivery"
                        {...a11yProps(0)}
                        className={classes.narrowTab}
                    />
                    <Tab
                        label="Summary of Completion"
                        {...a11yProps(1)}
                        className={classes.narrowTab}
                    />
                    <Tab label="Ops-1" {...a11yProps(2)} className={classes.narrowTab} />
                    <Tab
                        label="Ops-2 (Factory)"
                        {...a11yProps(3)}
                        className={classes.narrowTab}
                    />
                    <Tab
                        label="Ops-3 (Execution)"
                        {...a11yProps(4)}
                        className={classes.narrowTab}
                    />
                    <Tab
                        label="Ops-3 (MWP)"
                        {...a11yProps(5)}
                        className={classes.narrowTab}
                    />
                    <Tab
                        label="Stage-Value"
                        {...a11yProps(6)}
                        className={classes.narrowTab}
                    />
                </Tabs>
            </AppBar> */}

			{materialDeliveryData.length === 0 &&
				underExecutionData.length === 0 &&
				projectCompetionData.length === 0 &&
				completedMwpData.length === 0 &&
				factoryStageData.length === 0 &&
				completedProjectData.length === 0 &&
				factoryDataForOps1.length === 0 ? (
				<div
					style={{
						marginBottom: 20,
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<Paper className={classes.paper}>
						<center>
							<h2>No Data to Show</h2>
						</center>
					</Paper>
				</div>
			) : (
				<Fragment>
					<div className={classes.root}>

						{/* Ops-1 Button */}
						<Fragment>
							{(findMatchingUser(userDetails?._id) ||
								userDetails?.roles.find(
									(role) =>
										role.name === ROLENAME.SeniorOperationManager ||
										role.name === ROLENAME.Admin || role.name === ROLENAME.DataAnalyst || role.name === ROLENAME.FactoryManager || role.name === ROLENAME.FinanceManager
								)) && (
									<Button
										variant="outlined"
										color="primary"
										size="small"
										onClick={() => handleChange("Ops-1")}
										className={value === "Ops-1" ? classes.activeButton : ""}
									>
										Ops-1
									</Button>
								)}
						</Fragment>

						{/* Ops-2 Button */}
						<Fragment>
							{(findMatchingUser(userDetails?._id) ||
								userDetails?.roles.find(
									(role) =>
										role.name === ROLENAME.FactoryManager ||
										role.name === ROLENAME.Admin || role.name === ROLENAME.DataAnalyst ||
										role.name === ROLENAME.SeniorOperationManager || role.name === ROLENAME.FinanceManager
								)) &&
								userDetails?._id.toString() !== ThirumaleshId && (
									<Button
										variant="outlined"
										color="primary"
										size="small"
										onClick={() => handleChange("Ops-2 (Factory)")}
										className={
											value === "Ops-2 (Factory)" ? classes.activeButton : ""
										}
									>
										Ops-2 (Factory)
									</Button>
								)}
						</Fragment>

						{/* Ops-3(Execution) Button */}
						<Fragment>
							{(findMatchingUser(userDetails?._id) ||
								userDetails?.roles.find(
									(role) =>
										role.name === ROLENAME.DGM_Ops ||
										role.name === ROLENAME.Execution_Manager ||
										role.name === ROLENAME.Admin || role.name === ROLENAME.DataAnalyst ||
										role.name === ROLENAME.ExecutionUser || role.name === ROLENAME.FinanceManager
								) ||
								userDetails?._id === SriKrishnaId) && (
									<Button
										variant="outlined"
										color="primary"
										size="small"
										onClick={() => handleChange("Ops-3 (Execution)")}
										className={
											value === "Ops-3 (Execution)" ? classes.activeButton : ""
										}
									>
										Ops-3 (Execution)
									</Button>
								)}
						</Fragment>

						{/* Completed Project Button */}
						{/* <Fragment>
							{(findMatchingUser(userDetails?._id) ||
								userDetails?.roles.find(
									(role) =>
										role.name === ROLENAME.DGM_Ops ||
										role.name === ROLENAME.ExecutionUser ||
										role.name === ROLENAME.Admin || role.name === ROLENAME.DataAnalyst || role.name === ROLENAME.FinanceManager
								) ||
								userDetails?._id === PalisettiTejaId ||
								userDetails?._id === PalaPrasanthKumarId) && (
									<Button
										variant="outlined"
										color="primary"
										size="small"
										onClick={() => handleChange("Completed Project")}
										className={
											value === "Completed Project" ? classes.activeButton : ""
										}
									>
										Completed
									</Button>
								)}
						</Fragment> */}

						{/* Ops-3(MWP) Button */}
						<Fragment>
							{(findMatchingUser(userDetails?._id) ||
								userDetails?.roles.find(
									(role) =>
										role.name === ROLENAME.DGM_Ops ||
										role.name === ROLENAME.ExecutionUser ||
										role.name === ROLENAME.Admin || role.name === ROLENAME.DataAnalyst || role.name === ROLENAME.FinanceManager
								) ||
								userDetails?._id === PalisettiTejaId ||
								userDetails?._id === PalaPrasanthKumarId) && (
									<Button
										variant="outlined"
										color="primary"
										size="small"
										onClick={() => handleChange("Ops-3 (MWP)")}
										className={
											value === "Ops-3 (MWP)" ? classes.activeButton : ""
										}
									>
										Ops-3 Summary of Completion (MWP)
									</Button>
								)}
						</Fragment>

						{/* Summary of Delivery Button */}
						<Fragment>
							{(findMatchingUser(userDetails?._id) ||
								userDetails?.roles.find(
									(role) =>
										role.name === ROLENAME.DGM_Ops || role.name === ROLENAME.FinanceManager ||
									role.name === ROLENAME.Admin || role.name === ROLENAME.DataAnalyst || role.name === ROLENAME.Execution_Manager
								)) && (
									<Button
										variant="outlined"
										color="primary"
										size="small"
										onClick={() => handleChange("Summary of Delivery")}
										className={
											value === "Summary of Delivery" ? classes.activeButton : ""
										}
									>
										Summary of Delivery
									</Button>
								)}
						</Fragment>

						{/* Summary of Completion Button */}
						<Fragment>
							{(findMatchingUser(userDetails?._id) ||
								userDetails?.roles.find(
									(role) =>
										role.name === ROLENAME.DGM_Ops ||
									role.name === ROLENAME.Admin || role.name === ROLENAME.FinanceManager || role.name === ROLENAME.DataAnalyst || role.name === ROLENAME.Execution_Manager
								)) && (
									<Button
										variant="outlined"
										color="primary"
										size="small"
										onClick={() => handleChange("Summary of Completion")}
										className={
											value === "Summary of Completion"
												? classes.activeButton
												: ""
										}
									>
										Summary of Completion
									</Button>
								)}
						</Fragment>

						{/* Stage-Value Button */}
						<Fragment>
							{(findMatchingUser(userDetails?._id) ||
								userDetails?.roles.find(
									(role) => role.name === ROLENAME.Admin || role.name === ROLENAME.FinanceManager || role.name === ROLENAME.DataAnalyst
								)) && (
									<Button
										variant="outlined"
										color="primary"
										size="small"
										onClick={() => handleChange("Stage-Value")}
										className={
											value === "Stage-Value" ? classes.activeButton : ""
										}
									>
										Stage-Value
									</Button>
								)}
						</Fragment>
					</div>

					{/* Table for Summary of Delivery */}
					<Fragment>
						{value === "Summary of Delivery" &&
							(findMatchingUser(userDetails?._id) ||
								userDetails?.roles.find(
									(role) =>
										role.name === ROLENAME.DGM_Ops || role.name === ROLENAME.FinanceManager ||
									role.name === ROLENAME.Admin || role.name === ROLENAME.DataAnalyst || role.name === ROLENAME.Execution_Manager
								)) && (
								<Fragment>
									{/* Date filter component */}
									<Paper style={{ padding: 10, marginLeft: 25 }}>
										<Grid
											container
											style={{ display: "flex", alignItems: "center" }}
										>
											<Grid item md={3} xs={12}>
												<TextField
													id="date"
													name="startDate"
													label="From Date"
													type="date"
													value={startDate}
													onChange={(e) => setStartDate(e.target.value)}
													InputLabelProps={{
														shrink: true,
													}}
													fullWidth
													className={classes.textField}
												/>
											</Grid>
											<Grid item md={1} xs={12}></Grid>
											<Grid item md={3} xs={12}>
												<TextField
													id="date"
													name="endDate"
													label="End Date"
													type="date"
													value={endDate}
													onChange={(e) => setEndDate(e.target.value)}
													InputLabelProps={{
														shrink: true,
													}}
													fullWidth
													className={classes.textField}
												/>
											</Grid>
											<Grid item md={3} xs={12}>
												<Button
													variant="contained"
													color="primary"
													disabled={!startDate || !endDate}
													onClick={dateFilterHandler}
													style={{ marginLeft: 10 }}
												>
													Submit
												</Button>
											</Grid>
											<Grid item md={2} xs={12}>
												<Button
													variant="contained"
													color="secondary"
													onClick={clearDateFilter}
												>
													Clear Filter
												</Button>
											</Grid>
										</Grid>
										<div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '10px' }}>

											{/* Search Type */}
											<TextField
												select
												labelId="demo-simple-select-label"
												id="demo-simple-select"
												value={selectedSearchType}
												label="Search Type"
												name="searchType"
												onChange={(e) => handleSearchType(e.target.value)}
												variant="outlined"
												style={{ width: '30%', marginRight: '2%' }}
												size="small"
											>

												{searchTypes && searchTypes?.map((el, index) => (
													<MenuItem key={index} value={el}>{el}</MenuItem>
												))}
											</TextField>

											{/* Search Input */}
											<TextField
												label="Enter the input"
												name="searched"
												size="small"
												value={searchInput}
												onChange={(e) => { setSearchInput(e.target.value) }}
												variant="outlined"
												style={{ width: '80%' }} />

											{/* Search Button */}
											<Button
												disabled={!selectedSearchType || !searchInput}
												variant="contained"
												color="primary"
												size="medium"
												style={{ alignSelf: "center", marginLeft: 20 }} // Added height
												className={classes.leadSearchButton}
												onClick={() => handleSearch(searchInput.toUpperCase())}
											>
												Search
											</Button>
										</div>
									</Paper>

									{/* Data Table */}
									<div
										className={classes.tablecontainer}
										style={{ marginLeft: 25 }}
									>
										<div className={classes.tableform}>
											<div style={{ maxHeight: '700px', overflow: 'auto' }}>
												<TableContainer component={Paper}
													className={classes.table}
													style={{ width: "100%" }}
													classes={{ root: classes.customTableContainer }}>
													<Table
														className={classes.table}
														stickyHeader
														aria-label="sticky table">
														<TableHead style={{ position: 'sticky', top: 0, zIndex: 9, background: 'white' }}>
															<StyledTableRow>
																<StyledTableCell style={{ position: 'sticky', left: 0, zIndex: 9 }}>
																	Modular Delivery Month
																</StyledTableCell>
																{materialDeliveryData?.map((el) => {
																	return (
																		<StyledTableCell
																			style={{
																				zIndex: 2,
																				fontWeight: `${el?.locationDetail?.locationName ===
																					"Grand Total"
																					? "700"
																					: ""
																					}`,
																			}}
																		>
																			{el?.locationDetail?.locationName}
																		</StyledTableCell>
																	);
																})}
															</StyledTableRow>
														</TableHead>
														<TableHead style={{ position: 'sticky', top: 0 }}>
															{materialDeliveryData[0]?.monthlyData?.map(
																(el, i) => {
																	return (
																		<StyledTableRow key={i}>
																			<StyledTableCell
																				style={{
																					position: "sticky",
																					left: 0,
																					zIndex: 9,
																					fontWeight: `${el?.mmyyyy === "Grand Total"
																						? "700"
																						: ""
																						}`,
																					textAlign: "center"
																				}}
																				component="th"
																			>
																				{el?.mmyyyy === "Grand Total" ? el?.mmyyyy : `${el?.mmyyyy.slice(0, 3)}-${el?.mmyyyy.slice(-2)}`}
																			</StyledTableCell>
																			{materialDeliveryData.map((data, j) => {
																				return (
																					<StyledTableCell
																						style={{
																							zIndex: 1,
																							background: "white",
																							color: "black",
																							fontWeight: `${el?.mmyyyy === "Grand Total" ||
																								data?.locationDetail
																									?.locationName === "Grand Total"
																								? "700"
																								: ""
																								}`,
																						}}
																						component="th"
																						className={classes.tableCell}
																						onClick={() => {
																							showDetailedModalHandler(
																								data?.monthlyData[i].data,
																								"Summary of Delivery" +
																								" | " +
																								data?.monthlyData[i].mmyyyy
																							);
																						}}
																					>
																						{data?.monthlyData[i]?.value}
																					</StyledTableCell>
																				);
																			})}
																		</StyledTableRow>
																	);
																}
															)}
														</TableHead>
													</Table>
												</TableContainer>
											</div>
										</div>
									</div>
								</Fragment>
							)}
					</Fragment>

					{/* Table for Summary of Completion */}
					<Fragment>
						{value === "Summary of Completion" &&
							(findMatchingUser(userDetails?._id) ||
								userDetails?.roles.find(
									(role) =>
										role.name === ROLENAME.DGM_Ops ||
									role.name === ROLENAME.Admin || role.name === ROLENAME.DataAnalyst || role.name === ROLENAME.FinanceManager || role.name === ROLENAME.Execution_Manager
								)) && (
								<Fragment>
									{/* Date filter component */}
									<Paper style={{ padding: 10, marginLeft: 25 }}>
										<Grid
											container
											style={{ display: "flex", alignItems: "center" }}
										>
											<Grid item md={3} xs={12}>
												<TextField
													id="date"
													name="startDate"
													label="From Date"
													type="date"
													value={startDate}
													onChange={(e) => setStartDate(e.target.value)}
													InputLabelProps={{
														shrink: true,
													}}
													fullWidth
													className={classes.textField}
												/>
											</Grid>
											<Grid item md={1} xs={12}></Grid>
											<Grid item md={3} xs={12}>
												<TextField
													id="date"
													name="endDate"
													label="End Date"
													type="date"
													value={endDate}
													onChange={(e) => setEndDate(e.target.value)}
													InputLabelProps={{
														shrink: true,
													}}
													fullWidth
													className={classes.textField}
												/>
											</Grid>
											<Grid item md={3} xs={12}>
												<Button
													variant="contained"
													color="primary"
													disabled={!startDate || !endDate}
													onClick={dateFilterHandler}
													style={{ marginLeft: 10 }}
												>
													Submit
												</Button>
											</Grid>
											<Grid item md={2} xs={12}>
												<Button
													variant="contained"
													color="secondary"
													onClick={clearDateFilter}
												>
													Clear Filter
												</Button>
											</Grid>
										</Grid>
										<div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '10px' }}>

											{/* Search Type */}
											<TextField
												select
												labelId="demo-simple-select-label"
												id="demo-simple-select"
												value={selectedSearchType}
												label="Search Type"
												name="searchType"
												onChange={(e) => handleSearchType(e.target.value)}
												variant="outlined"
												style={{ width: '30%', marginRight: '2%' }}
												size="small"
											>
												{searchTypes && searchTypes?.map((el, index) => (
													<MenuItem key={index} value={el}>{el}</MenuItem>
												))}
											</TextField>

											{/* Search Input */}
											<TextField
												label="Enter the input"
												name="searched"
												size="small"
												value={searchInput}
												onChange={(e) => { setSearchInput(e.target.value) }}
												variant="outlined"
												style={{ width: '80%' }} />

											{/* Search Button */}
											<Button
												disabled={!selectedSearchType || !searchInput}
												variant="contained"
												color="primary"
												size="medium"
												style={{ alignSelf: "center", marginLeft: 20 }} // Added height
												className={classes.leadSearchButton}
												onClick={() => handleSearch(searchInput.toUpperCase())}
											>
												Search
											</Button>
										</div>
									</Paper>

									{/* Data Table */}
									<div
										className={classes.tablecontainer}
										style={{ marginLeft: 25 }}
									>
										<div className={classes.tableform}>
											<div style={{ maxHeight: '700px', overflow: 'auto' }}>
												<TableContainer component={Paper}
													className={classes.table}
													style={{ width: "100%" }}
													classes={{ root: classes.customTableContainer }}>
													<Table
														className={classes.table}
														stickyHeader
														aria-label="sticky table">
														<TableHead style={{ position: 'sticky', top: 0, zIndex: 9, background: 'white' }}>
															<StyledTableRow>
																<StyledTableCell style={{ position: 'sticky', left: 0, zIndex: 9 }}>
																	Project Completion Month
																</StyledTableCell>
																{projectCompetionData?.map((el) => {
																	return (
																		<StyledTableCell
																			style={{
																				zIndex: 2,
																				fontWeight: `${el?.locationDetail?.locationName ===
																					"Grand Total"
																					? "700"
																					: ""
																					}`,
																				textAlign: "center"
																			}}
																		>
																			{el?.locationDetail?.locationName}
																		</StyledTableCell>
																	);
																})}
															</StyledTableRow>
														</TableHead>
														<TableHead style={{ position: 'sticky', top: 0 }}>
															{projectCompetionData[0]?.monthlyData?.map(
																(el, i) => {
																	return (
																		<StyledTableRow key={i}>
																			<StyledTableCell
																				style={{
																					fontWeight: `${el?.mmyyyy === "Grand Total"
																						? "700"
																						: ""
																						}`,
																					textAlign: "center"
																				}}
																				component="th"
																			>
																				{el?.mmyyyy === "Grand Total" ? el?.mmyyyy : `${el?.mmyyyy.slice(0, 3)}-${el?.mmyyyy.slice(-2)}`}
																			</StyledTableCell>
																			{projectCompetionData.map((data, j) => {
																				return (
																					<StyledTableCell
																						style={{
																							zIndex: 1,
																							background: "white",
																							color: "black",
																							fontWeight: `${el?.mmyyyy === "Grand Total" ||
																								data?.locationDetail
																									?.locationName === "Grand Total"
																								? "700"
																								: ""
																								}`,
																						}}
																						className={classes.tableCell}
																						component="th"
																						onClick={() => {
																							showDetailedModalHandler(
																								data?.monthlyData[i].data,
																								"Summary of Completion" +
																								" | " +
																								data?.monthlyData[i].mmyyyy
																							);
																						}}
																					>
																						{data?.monthlyData[i]?.value}
																					</StyledTableCell>
																				);
																			})}
																		</StyledTableRow>
																	);
																}
															)}
														</TableHead>
													</Table>
												</TableContainer>

											</div>
										</div>
									</div>
								</Fragment>
							)}
					</Fragment>

					{/* Table for Ops-1 */}
					<Fragment>
						{value === "Ops-1" &&
							(findMatchingUser(userDetails?._id) ||
								userDetails?.roles.find(
									(role) =>
										role.name === ROLENAME.SeniorOperationManager ||
										role.name === ROLENAME.Admin || role.name === ROLENAME.DataAnalyst || role.name === ROLENAME.FactoryManager || role.name === ROLENAME.FinanceManager
								)) && (
								<Fragment>
									<Paper style={{ padding: 10, marginLeft: 25 }}>
										<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', marginTop: '10px' }}>
											{/* Search Type */}
											<TextField
												select
												labelId="demo-simple-select-label"
												id="demo-simple-select"
												value={selectedSearchType}
												label="Search Type"
												name="searchType"
												onChange={(e) => handleSearchType(e.target.value)}
												variant="outlined"
												style={{ width: '30%', marginRight: '2%' }}
												size="small"
											>
												{searchTypes && searchTypes?.map((el, index) => (
													<MenuItem key={index} value={el}>{el}</MenuItem>
												))}
											</TextField>

											{/* Search Input */}
											<TextField
												label="Enter the input"
												name="searched"
												size="small"
												value={searchInput}
												onChange={(e) => setSearchInput(e.target.value)}
												variant="outlined"
												style={{ width: '50%' }} />

											{/* Search Button */}
											<Button
												disabled={!selectedSearchType || !searchInput}
												variant="contained"
												color="primary"
												size="medium"
												style={{ alignSelf: "center", marginLeft: 20 }} // Added height
												className={classes.leadSearchButton}
												onClick={() => handleSearch(searchInput.toUpperCase())}
											>
												Search
											</Button>

											{/* Clear Filter */}
											<Button
												variant="contained"
												color="secondary"
												size="medium"
												style={{ marginLeft: 10 }}
												className={classes.leadClearButton}
												onClick={clearDateFilter}
											>
												Clear Filter
											</Button>
										</div>
									</Paper>
									<div
										className={classes.tablecontainer}
										style={{ marginLeft: 25 }}
									>
										<div className={classes.tableform}>
											<div style={{ maxHeight: '600px', overflow: 'auto' }}>
												<TableContainer component={Paper}
													className={classes.table}
													style={{ width: "100%" }}
													classes={{ root: classes.customTableContainer }}>

													<Table style={{ width: 450 }}
														className={classes.table}
														stickyHeader
														aria-label="sticky table">

														<TableHead style={{ position: 'sticky', top: 0, zIndex: 9, background: 'white' }}>
															<StyledTableRow>
																<StyledTableCell style={{ position: 'sticky', left: 0, zIndex: 9 }}>
																	Stages</StyledTableCell>
																{factoryDataForOps1?.map((el) => {
																	return (
																		<StyledTableCell
																			style={{
																				zIndex: 2,
																				fontWeight: `${el?.locationDetail?.locationName ===
																					"Grand Total"
																					? "700"
																					: ""
																					}`,
																			}}
																		>
																			{el?.locationDetail?.locationName}
																		</StyledTableCell>
																	);
																})}
															</StyledTableRow>
														</TableHead>
														<TableHead style={{ position: 'sticky', top: 0 }}>
															{factoryDataForOps1[0]?.stageData?.map((el, i) => {
																return (
																	<StyledTableRow key={i}>
																		<StyledTableCell
																			style={{
																				position: "sticky",
																				left: 0,
																				zIndex: 9,
																				fontWeight: `${el?.stage === "Grand Total" ? "700" : ""
																					}`,
																			}}
																			component="th"
																		>
																			{el?.stage}
																		</StyledTableCell>
																		{factoryDataForOps1.map((data, j) => {
																			return (
																				<StyledTableCell
																					style={{
																						zIndex: 1,
																						background: "white",
																						color: "black",
																						fontWeight: `${el?.stage === "Grand Total" ||
																							data?.locationDetail?.locationName ===
																							"Grand Total"
																							? "700"
																							: ""
																							}`,
																					}}
																					component="th"
																					className={classes.tableCell}
																					onClick={() => {
																						showDetailedModalHandler(
																							data?.stageData[i].data,
																							"Ops-1" +
																							" | " +
																							data?.stageData[i]?.stage
																						);
																					}}
																				>
																					{data?.stageData[i]?.value || 0}
																				</StyledTableCell>
																			);
																		})}
																	</StyledTableRow>
																);
															})}
														</TableHead>
													</Table>
												</TableContainer>
											</div>
										</div>
									</div>
								</Fragment>
							)}
					</Fragment>

					{/* Table for Ops-2 Factory */}
					<Fragment>
						{value === "Ops-2 (Factory)" &&
							(findMatchingUser(userDetails?._id) ||
								userDetails?.roles.find(
									(role) =>
										role.name === ROLENAME.FactoryManager ||
										role.name === ROLENAME.Admin || role.name === ROLENAME.DataAnalyst ||
										role.name === ROLENAME.SeniorOperationManager || role.name === ROLENAME.FinanceManager
								)) &&
							userDetails?._id.toString() !== ThirumaleshId && (
								<Fragment>
									<Paper style={{ padding: 10, marginLeft: 25 }}>
										<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', marginTop: '10px' }}>
											{/* Search Type */}
											<TextField
												select
												labelId="demo-simple-select-label"
												id="demo-simple-select"
												value={selectedSearchType}
												label="Search Type"
												name="searchType"
												onChange={(e) => handleSearchType(e.target.value)}
												variant="outlined"
												style={{ width: '30%', marginRight: '2%' }}
												size="small"
											>
												{searchTypes && searchTypes?.map((el, index) => (
													<MenuItem key={index} value={el}>{el}</MenuItem>
												))}
											</TextField>

											{/* Search Input */}
											<TextField
												label="Enter the input"
												name="searched"
												size="small"
												value={searchInput}
												onChange={(e) => setSearchInput(e.target.value)}
												variant="outlined"
												style={{ width: '50%' }} />

											{/* Search Button */}
											<Button
												disabled={!selectedSearchType || !searchInput}
												variant="contained"
												color="primary"
												size="medium"
												style={{ alignSelf: "center", marginLeft: 20 }} // Added height
												className={classes.leadSearchButton}
												onClick={() => handleSearch(searchInput.toUpperCase())}
											>
												Search
											</Button>

											{/* Clear Filter */}
											<Button
												variant="contained"
												color="secondary"
												size="medium"
												style={{ marginLeft: 10 }}
												className={classes.leadClearButton}
												onClick={clearDateFilter}
											>
												Clear Filter
											</Button>
										</div>
									</Paper>
									<div
										className={classes.tablecontainer}
										style={{ marginLeft: 25 }}
									>
										<div className={classes.tableform}>
											<div style={{ maxHeight: '600px', overflow: 'auto' }}>

												<TableContainer component={Paper}
													className={classes.table}
													style={{ width: "100%" }}
													classes={{ root: classes.customTableContainer }}>

													<Table style={{ width: 450 }}
														className={classes.table}
														stickyHeader
														aria-label="sticky table">
														<TableHead style={{ position: 'sticky', top: 0, zIndex: 9, background: 'white' }}>
															<StyledTableRow>
																<StyledTableCell style={{ position: 'sticky', left: 0, zIndex: 9 }}>
																	Stages</StyledTableCell>
																{factoryStageData?.map((el) => {
																	return (
																		<StyledTableCell
																			style={{
																				zIndex: 2,
																				fontWeight: `${el?.locationDetail?.locationName ===
																					"Grand Total"
																					? "700"
																					: ""
																					}`,
																			}}
																		>
																			{el?.locationDetail?.locationName}
																		</StyledTableCell>
																	);
																})}
															</StyledTableRow>
														</TableHead>
														<TableHead style={{ position: 'sticky', top: 0 }}>
															{factoryStageData[0]?.stageData?.map((el, i) => {
																return (
																	<StyledTableRow key={i}>
																		<StyledTableCell
																			style={{
																				position: "sticky",
																				left: 0,
																				zIndex: 9,
																				fontWeight: `${el?.stage === "Grand Total" ? "700" : ""
																					}`,
																			}}
																			component="th"
																		>
																			{el?.stage}
																		</StyledTableCell>
																		{factoryStageData.map((data, j) => {
																			return (
																				<StyledTableCell
																					style={{
																						zIndex: 1,
																						background: "white",
																						color: "black",
																						fontWeight: `${el?.stage === "Grand Total" ||
																							data?.locationDetail?.locationName ===
																							"Grand Total"
																							? "700"
																							: ""
																							}`,
																					}}
																					component="th"
																					className={classes.tableCell}
																					onClick={() => {
																						showDetailedModalHandler(
																							data?.stageData[i].data,
																							"Ops-2 Factory" +
																							" | " +
																							data?.stageData[i]?.stage
																						);
																					}}
																				>
																					{data?.stageData[i]?.value}
																				</StyledTableCell>
																			);
																		})}
																	</StyledTableRow>
																);
															})}
														</TableHead>
													</Table>
												</TableContainer>
											</div>
										</div>
									</div>
								</Fragment>
							)}
					</Fragment>

					{/* Table for Ops-3 Execution */}
					<Fragment>
							{value === "Ops-3 (Execution)" &&
								(findMatchingUser(userDetails?._id) ||
									userDetails?.roles.find(
										(role) =>
											role.name === ROLENAME.DGM_Ops ||
											role.name === ROLENAME.Execution_Manager ||
											role.name === ROLENAME.Admin || role.name === ROLENAME.DataAnalyst ||
											role.name === ROLENAME.ExecutionUser || role.name === ROLENAME.FinanceManager
									) ||
									userDetails?._id === SriKrishnaId) && (
									<Fragment>
										{/* Date filter component */}
										<Paper style={{ padding: 10, marginLeft: 25 }}>
											<Grid
												container
												style={{ display: "flex", alignItems: "center" }}
											>
												<Grid item md={3} xs={12}>
													<TextField
														id="date"
														name="startDate"
														label="From Date"
														type="date"
														value={startDate}
														onChange={(e) => setStartDate(e.target.value)}
														InputLabelProps={{
															shrink: true,
														}}
														fullWidth
														className={classes.textField}
													/>
												</Grid>
												<Grid item md={3} xs={12}>
													<TextField
														id="date"
														name="endDate"
														label="End Date"
														type="date"
														value={endDate}
														onChange={(e) => setEndDate(e.target.value)}
														InputLabelProps={{
															shrink: true,
														}}
														fullWidth
														className={classes.textField}
													/>
												</Grid>
												<Grid item md={3} xs={12}>
													<Button
														variant="contained"
														color="primary"
														disabled={!startDate || !endDate}
														onClick={dateFilterHandler}
														style={{ marginLeft: 10 }}
													>
														Submit
													</Button>
												</Grid>
												<Grid item md={2} xs={12}>
													<Button
														variant="contained"
														color="secondary"
														onClick={clearDateFilter}
													>
														Clear Filter
													</Button>
												</Grid>
											</Grid>
											<div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '25px' }}>

												{/* Search Type */}
												<TextField
													select
													labelId="demo-simple-select-label"
													id="demo-simple-select"
													value={selectedSearchType}
													label="Search Type"
													name="searchType"
													onChange={(e) => handleSearchType(e.target.value)}
													variant="outlined"
													style={{ width: '30%', marginRight: '2%' }}
													size="small"
												>

													{searchTypes && searchTypes?.map((el, index) => (
														<MenuItem key={index} value={el}>{el}</MenuItem>
													))}
												</TextField>

												{/* Search Input */}
												<TextField
													label="Enter the input"
													name="searched"
													size="small"
													value={searchInput}
													onChange={(e) => { setSearchInput(e.target.value) }}
													variant="outlined"
													style={{ width: '80%' }} />

												{/* Search Button */}
												<Button
													disabled={!selectedSearchType || !searchInput}
													variant="contained"
													color="primary"
													size="medium"
													style={{ alignSelf: "center", marginLeft: 20 }} // Added height
													className={classes.leadSearchButton}
													onClick={() => handleSearch(searchInput.toUpperCase())}
												>
													Search
												</Button>
											</div>
										</Paper>
										<div
											className={classes.tablecontainer}
											style={{ marginLeft: 25 }}
										>
											<div className={classes.tableform}>
												<div style={{ maxHeight: '600px', overflow: 'auto' }}>

													<TableContainer component={Paper}
														className={classes.table}
														style={{ width: "100%" }}
														classes={{ root: classes.customTableContainer }}>

														<Table style={{ width: 450 }}
															className={classes.table}
															stickyHeader
															aria-label="sticky table">
															<TableHead style={{ position: 'sticky', top: 0, zIndex: 9, background: 'white' }}>
																<StyledTableRow>
																	<StyledTableCell style={{ position: 'sticky', left: 0, zIndex: 9 }}>
																		Modular Delivery Month
																	</StyledTableCell>
																	{underExecutionData?.map((el) => {
																		return (
																			<StyledTableCell
																				component="th"
																				style={{
																					zIndex: 2,
																					fontWeight: `${el?.locationDetail?.locationName ===
																						"Grand Total"
																						? "700"
																						: ""
																						}`,
																				}}
																			>
																				{el?.locationDetail?.locationName}
																			</StyledTableCell>
																		);
																	})}
																</StyledTableRow>
															</TableHead>
															<TableHead style={{ position: 'sticky', top: 0 }}>
																{underExecutionData[0]?.monthlyData?.map((el, i) => (
																	<StyledTableRow key={i}>
																		<StyledTableCell
																			style={{
																				position: "sticky",
																				left: 0,
																				zIndex: 9,
																				fontWeight: el?.mmyyyy === "Grand Total" ? "700" : "",
																			}}
																			component="th"
																		>
																			{el?.mmyyyy === "Grand Total" ? el?.mmyyyy : `${el?.mmyyyy.slice(0, 3)}-${el?.mmyyyy.slice(-2)}`}
																		</StyledTableCell>
																		{underExecutionData.map((data, j) => (
																			<StyledTableCell
																				style={{
																					zIndex: 1,
																					background: "white",
																					color: "black",
																					fontWeight: el?.mmyyyy === "Grand Total" || data?.locationDetail?.locationName === "Grand Total" ? "700" : "",
																				}}
																				component="th"
																				className={classes.tableCell}
																				onClick={() => {
																					showDetailedModalHandler(
																						data?.monthlyData[i].data,
																						"Ops-3 Under Execution" + " | " + data?.monthlyData[i].mmyyyy
																					);
																				}}
																			>
																				{data?.monthlyData[i]?.value}
																			</StyledTableCell>
																		))}
																	</StyledTableRow>
																))}
															</TableHead>
														</Table>
													</TableContainer>
												</div>
											</div>
										</div>
									</Fragment>
								)}
						</Fragment>

					{/* Table for Ops-3 MWP */}
					<Fragment>
						{value === "Ops-3 (MWP)" &&
							(findMatchingUser(userDetails?._id) ||
								userDetails?.roles.find(
									(role) =>
										role.name === ROLENAME.DGM_Ops ||
										role.name === ROLENAME.ExecutionUser ||
										role.name === ROLENAME.Admin || role.name === ROLENAME.DataAnalyst || role.name === ROLENAME.FinanceManager
								) ||
								userDetails?._id === PalisettiTejaId ||
								userDetails?._id === PalaPrasanthKumarId) && (
								<Fragment>
									<Paper style={{ padding: 10, marginLeft: 25 }}>
										<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', marginTop: '10px' }}>
											{/* Search Type */}
											<TextField
												select
												labelId="demo-simple-select-label"
												id="demo-simple-select"
												value={selectedSearchType}
												label="Search Type"
												name="searchType"
												onChange={(e) => handleSearchType(e.target.value)}
												variant="outlined"
												style={{ width: '30%', marginRight: '2%' }}
												size="small"
											>
												{searchTypes && searchTypes?.map((el, index) => (
													<MenuItem key={index} value={el}>{el}</MenuItem>
												))}
											</TextField>

											{/* Search Input */}
											<TextField
												label="Enter the input"
												name="searched"
												size="small"
												value={searchInput}
												onChange={(e) => setSearchInput(e.target.value)}
												variant="outlined"
												style={{ width: '50%' }} />

											{/* Search Button */}
											<Button
												disabled={!selectedSearchType || !searchInput}
												variant="contained"
												color="primary"
												size="medium"
												style={{ alignSelf: "center", marginLeft: 20 }} // Added height
												className={classes.leadSearchButton}
												onClick={() => handleSearch(searchInput.toUpperCase())}
											>
												Search
											</Button>

											{/* Clear Filter */}
											<Button
												variant="contained"
												color="secondary"
												size="medium"
												style={{ marginLeft: 10 }}
												className={classes.leadClearButton}
												onClick={clearDateFilter}
											>
												Clear Filter
											</Button>
										</div>
									</Paper>
									<div
										className={classes.tablecontainer}
										style={{ marginLeft: 25 }}
									>
										<div className={classes.tableform}>
											<TableContainer
												component={Paper}
												className={classes.table}
												style={{ width: "100%" }}
											>
												<Table
													style={{ width: 450 }}
													className={classes.table}
													stickyHeader
													aria-label="sticky table"
												>
													<TableHead>
														<StyledTableRow>
															<StyledTableCell>Execution Lead</StyledTableCell>
															<StyledTableCell>Count</StyledTableCell>
														</StyledTableRow>
													</TableHead>
													<TableBody>
														{completedMwpData?.map((el, i) => {
															return (
																<StyledTableRow key={i}>
																	<StyledTableCell
																		style={{
																			fontWeight: `${el?._id?.user === "Grand Total"
																				? "700"
																				: ""
																				}`,
																		}}
																		component="th"
																	>
																		{el?._id?.user}
																	</StyledTableCell>
																	<StyledTableCell
																		style={{
																			fontWeight: `${el?._id?.user === "Grand Total"
																				? "700"
																				: ""
																				}`,
																		}}
																		component="th"
																		className={classes.tableCell}
																		onClick={() => {
																			showDetailedModalHandler(
																				el?.data,
																				"Ops-3 MWP" + " | " + el?._id?.user
																			);
																		}}
																	>
																		{el?.count}
																	</StyledTableCell>
																</StyledTableRow>
															);
														})}
													</TableBody>
												</Table>
											</TableContainer>
										</div>
									</div>
								</Fragment>
							)}
					</Fragment>

					{/* Table for Completed Project */}
					<Fragment>
						{value === "Completed Project" &&
							(findMatchingUser(userDetails?._id) ||
								userDetails?.roles.find(
									(role) =>
										role.name === ROLENAME.DGM_Ops ||
										role.name === ROLENAME.ExecutionUser ||
										role.name === ROLENAME.Admin || role.name === ROLENAME.DataAnalyst || role.name === ROLENAME.FinanceManager
								) ||
								userDetails?._id === PalisettiTejaId ||
								userDetails?._id === PalaPrasanthKumarId) && (
								<Fragment>
									<Paper style={{ padding: 10, marginLeft: 25 }}>
										<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', marginTop: '10px' }}>
											{/* Search Type */}
											<TextField
												select
												labelId="demo-simple-select-label"
												id="demo-simple-select"
												value={selectedSearchType}
												label="Search Type"
												name="searchType"
												onChange={(e) => handleSearchType(e.target.value)}
												variant="outlined"
												style={{ width: '30%', marginRight: '2%' }}
												size="small"
											>
												{searchTypes && searchTypes?.map((el, index) => (
													<MenuItem key={index} value={el}>{el}</MenuItem>
												))}
											</TextField>

											{/* Search Input */}
											<TextField
												label="Enter the input"
												name="searched"
												size="small"
												value={searchInput}
												onChange={(e) => setSearchInput(e.target.value)}
												variant="outlined"
												style={{ width: '50%' }} />

											{/* Search Button */}
											<Button
												disabled={!selectedSearchType || !searchInput}
												variant="contained"
												color="primary"
												size="medium"
												style={{ alignSelf: "center", marginLeft: 20 }} // Added height
												className={classes.leadSearchButton}
												onClick={() => handleSearch(searchInput.toUpperCase())}
											>
												Search
											</Button>

											{/* Clear Filter */}
											<Button
												variant="contained"
												color="secondary"
												size="medium"
												style={{ marginLeft: 10 }}
												className={classes.leadClearButton}
												onClick={clearDateFilter}
											>
												Clear Filter
											</Button>
										</div>
									</Paper>
									<div
										className={classes.tablecontainer}
										style={{ marginLeft: 25 }}
									>
										<div className={classes.tableform}>
											<TableContainer
												component={Paper}
												className={classes.table}
												style={{ width: "100%" }}
											>
												<Table
													style={{ width: 450 }}
													className={classes.table}
													stickyHeader
													aria-label="sticky table"
												>
													<TableHead>
														<StyledTableRow>
															<StyledTableCell>Execution Lead</StyledTableCell>
															<StyledTableCell>Count</StyledTableCell>
														</StyledTableRow>
													</TableHead>
													<TableBody>
														{completedProjectData?.map((el, i) => {
															return (
																<StyledTableRow key={i}>
																	<StyledTableCell
																		style={{
																			fontWeight: `${el?._id?.user === "Grand Total"
																				? "700"
																				: ""
																				}`,
																		}}
																		component="th"
																	>
																		{el?._id?.user}
																	</StyledTableCell>
																	<StyledTableCell
																		style={{
																			fontWeight: `${el?._id?.user === "Grand Total"
																				? "700"
																				: ""
																				}`,
																		}}
																		component="th"
																		className={classes.tableCell}
																		onClick={() => {
																			showDetailedModalHandler(
																				el?.data,
																				"Ops-3 MWP" + " | " + el?._id?.user
																			);
																		}}
																	>
																		{el?.count}
																	</StyledTableCell>
																</StyledTableRow>
															);
														})}
													</TableBody>
												</Table>
											</TableContainer>
										</div>
									</div>
								</Fragment>
							)}
					</Fragment>

					{/* Table for Stage Value */}
					<Fragment>
						{value === "Stage-Value" &&
							(findMatchingUser(userDetails?._id) ||
								userDetails?.roles.find(
									(role) => role.name === ROLENAME.Admin || role.name === ROLENAME.DataAnalyst || role.name === ROLENAME.FinanceManager
								)) && (
								<>
									{/* Date filter component */}
									<Paper style={{ padding: 10, marginLeft: 25 }}>
										<Grid
											container
											style={{ display: "flex", alignItems: "center" }}
										>
											<Grid item md={3} xs={12}>
												<TextField
													id="date"
													name="startDate"
													label="From Date"
													type="date"
													value={startDate}
													onChange={(e) => setStartDate(e.target.value)}
													InputLabelProps={{
														shrink: true,
													}}
													fullWidth
													className={classes.textField}
												/>
											</Grid>
											<Grid item md={1} xs={12}></Grid>
											<Grid item md={3} xs={12}>
												<TextField
													id="date"
													name="endDate"
													label="End Date"
													type="date"
													value={endDate}
													onChange={(e) => setEndDate(e.target.value)}
													InputLabelProps={{
														shrink: true,
													}}
													fullWidth
													className={classes.textField}
												/>
											</Grid>
											<Grid item md={3} xs={12}>
												<Button
													variant="contained"
													color="primary"
													disabled={!startDate || !endDate}
													onClick={dateFilterHandler}
													style={{ marginLeft: 10 }}
												>
													Submit
												</Button>
											</Grid>
											<Grid item md={2} xs={12}>
												<Button
													variant="contained"
													color="secondary"
													onClick={clearDateFilter}
												>
													Clear Filter
												</Button>
											</Grid>
										</Grid>
										<div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '10px' }}>

											{/* Search Type */}
											<TextField
												select
												labelId="demo-simple-select-label"
												id="demo-simple-select"
												value={selectedSearchType}
												label="Search Type"
												name="searchType"
												onChange={(e) => handleSearchType(e.target.value)}
												variant="outlined"
												style={{ width: '30%', marginRight: '2%' }}
												size="small"
											>
												{searchTypes && searchTypes?.map((el, index) => (
													<MenuItem key={index} value={el}>{el}</MenuItem>
												))}
											</TextField>

											{/* Search Input */}
											<TextField
												label="Enter the input"
												name="searched"
												size="small"
												value={searchInput}
												onChange={(e) => { setSearchInput(e.target.value) }}
												variant="outlined"
												style={{ width: '80%' }} />

											{/* Search Button */}
											<Button
												disabled={!selectedSearchType || !searchInput}
												variant="contained"
												color="primary"
												size="medium"
												style={{ alignSelf: "center", marginLeft: 20 }} // Added height
												className={classes.leadSearchButton}
												onClick={() => handleSearch(searchInput.toUpperCase())}
											>
												Search
											</Button>
										</div>
									</Paper>

									{/* Summary of Delivery Values */}
									<div
										className={classes.tablecontainer}
										style={{ marginLeft: 25 }}
									>
										<div className={classes.tableform}>
											<div style={{ maxHeight: '700px', overflow: 'auto' }}>
												<TableContainer component={Paper}
													className={classes.table}
													style={{ width: "100%" }}
													classes={{ root: classes.customTableContainer }}>
													<Table style={{ width: 450 }}
														className={classes.table}
														stickyHeader
														aria-label="sticky table">
														<TableHead style={{ position: 'sticky', top: 0, zIndex: 1, background: 'white' }}>
															<StyledTableRow>
																<StyledTableCell style={{ position: 'sticky', left: 0, zIndex: 9 }}>
																	Summary Of Delivery
																</StyledTableCell>
																{materialDeliveryData?.map((el) => {
																	return (
																		<StyledTableCell
																			style={{
																				zIndex: 2,
																				fontWeight: `${el?.locationDetail?.locationName ===
																					"Grand Total"
																					? "700"
																					: ""
																					}`,
																				textAlign: "center"
																			}}
																		>
																			{el?.locationDetail?.locationName !== "Grand Total" ? el?.locationDetail?.locationName : "Grand Total (In Lakhs)" }
																		</StyledTableCell>
																	);
																})}
																<StyledTableCell>Percentage</StyledTableCell>
																<StyledTableCell>Calculated Value</StyledTableCell>
															</StyledTableRow>
														</TableHead>
														<TableHead style={{ position: 'sticky', top: 0 }}>
															{materialDeliveryData[0]?.monthlyData?.map((el, i) => (
																<StyledTableRow key={i}>
																	<StyledTableCell
																		style={{
																			position: "sticky",
																			left: 0,
																			zIndex: 9,
																			fontWeight: el?.mmyyyy === "Grand Total" ? "700" : "",
																		}}
																		component="th"
																	>
																		{el?.mmyyyy === "Grand Total" ? el?.mmyyyy : `${el?.mmyyyy.slice(0, 3)}-${el?.mmyyyy.slice(-2)}`}
																	</StyledTableCell>
																	{materialDeliveryData.map((data, j) => (
																		<StyledTableCell
																			style={{
																				zIndex: 1,
																				background: "white",
																				color: "black",
																				fontWeight: el?.mmyyyy === "Grand Total" || data?.locationDetail?.locationName === "Grand Total" ? "700" : "",
																			}}
																			component="th"
																			className={classes.tableCell}
																			onClick={() => {
																				showDetailedModalHandler(
																					data?.monthlyData[i].data,
																					"Summary of Delivery" + " | " + data?.monthlyData[i].mmyyyy
																				);
																			}}
																		>
																			{formatToLakhs(data?.monthlyData[i]?.grandTotal / 1.18)}
																		</StyledTableCell>
																	))}
																	<StyledTableCell
																		style={{
																			zIndex: 1,
																			background: "white",
																			color: "black",
																			// fontWeight: el?.mmyyyy === "Grand Total" || data?.locationDetail?.locationName === "Grand Total" ? "700" : "",
																		}}
																	>
																		<input
																			type="number"
																			onChange={(e) => handlePercentageChangeDelivery(e, i)}
																			value={materialDeliveryPercentages[i] || ""}
																			placeholder="%"
																			style={{
																				width: "100%",
																				padding: "10px",
																				border: "2px solid #007BFF",
																				borderRadius: "4px",
																				fontSize: "16px",
																				backgroundColor: "#F0F8FF"
																			}}
																		/>
																	</StyledTableCell>
																	<StyledTableCell
																		style={{
																			zIndex: 1,
																			background: "white",
																			color: "black",
																			// fontWeight: el?.mmyyyy === "Grand Total" || data?.locationDetail?.locationName === "Grand Total" ? "700" : "",
																		}}
																	>
																		{calculateValueDelivery(i)}
																	</StyledTableCell>
																</StyledTableRow>
															))}
														</TableHead>

													</Table>
												</TableContainer>{" "}
											</div>
										</div>
									</div>

									{/* Summary of Completion Values */}
									<div
										className={classes.tablecontainer}
										style={{ marginLeft: 25 }}
									>
										<div className={classes.tableform}>
											<div style={{ maxHeight: '700px', overflow: 'auto' }}>
												<TableContainer component={Paper}
													className={classes.table}
													style={{ width: "100%" }}
													classes={{ root: classes.customTableContainer }}>
													<Table
														className={classes.table}
														stickyHeader
														aria-label="sticky table">
														<TableHead style={{ position: 'sticky', top: 0, zIndex: 9, background: 'white' }}>
															<StyledTableRow>
																<StyledTableCell style={{ position: 'sticky', left: 0, zIndex: 9 }}>
																	Summary Of Completion
																</StyledTableCell>
																{projectCompetionData?.map((el) => {
																	return (
																		<StyledTableCell
																			style={{
																				zIndex: 2,
																				fontWeight: `${el?.locationDetail?.locationName ===
																					"Grand Total"
																					? "700"
																					: ""
																					}`,
																				textAlign: "center"
																			}}
																		>
																			{el?.locationDetail?.locationName !== "Grand Total" ? el?.locationDetail?.locationName : "Grand Total (In Lakhs)" }
																		</StyledTableCell>
																	);
																})}
																<StyledTableCell>Percentage</StyledTableCell>
																<StyledTableCell>Calculated Value</StyledTableCell>
															</StyledTableRow>
														</TableHead>
														<TableHead style={{ position: 'sticky', top: 0 }}>
															{projectCompetionData[0]?.monthlyData?.map(
																(el, i) => {
																	return (
																		<StyledTableRow key={i}>
																			<StyledTableCell
																				style={{
																					position: "sticky",
																					left: 0,
																					zIndex: 9,
																					fontWeight: `${el?.mmyyyy === "Grand Total"
																						? "700"
																						: ""
																						}`,
																				}}
																				component="th"
																			>
																				{el?.mmyyyy === "Grand Total" ? el?.mmyyyy : `${el?.mmyyyy.slice(0, 3)}-${el?.mmyyyy.slice(-2)}`}
																			</StyledTableCell>
																			{projectCompetionData.map((data, j) => {
																				return (
																					<StyledTableCell
																						style={{
																							zIndex: 1,
																							background: "white",
																							color: "black",
																							fontWeight: `${el?.mmyyyy === "Grand Total" ||
																								data?.locationDetail
																									?.locationName === "Grand Total"
																								? "700"
																								: ""
																								}`,
																						}}
																						className={classes.tableCell}
																						component="th"
																						onClick={() => {
																							showDetailedModalHandler(
																								data?.monthlyData[i].data,
																								"Summary of Completion" +
																								" | " +
																								data?.monthlyData[i].mmyyyy
																							);
																						}}
																					>
																						{formatToLakhs(data?.monthlyData[i]?.grandTotal /1.18)}
																					</StyledTableCell>
																				);
																			})}
																			<StyledTableCell
																				style={{
																					zIndex: 1,
																					background: "white",
																					color: "black",
																					// fontWeight: el?.mmyyyy === "Grand Total" || data?.locationDetail?.locationName === "Grand Total" ? "700" : "",
																				}}
																			>
																				<input
																					type="number"
																					onChange={(e) => handlePercentageChangeCompletion(e, i)}
																					value={projectCompletionPercentages[i] || ""}
																					placeholder="%"
																					style={{
																						width: "100%",
																						padding: "10px",
																						border: "2px solid #007BFF",
																						borderRadius: "4px",
																						fontSize: "16px",
																						backgroundColor: "#F0F8FF"
																					}}
																				/>
																			</StyledTableCell>
																			<StyledTableCell
																				style={{
																					zIndex: 1,
																					background: "white",
																					color: "black",
																					// fontWeight: el?.mmyyyy === "Grand Total" || data?.locationDetail?.locationName === "Grand Total" ? "700" : "",
																				}}
																			>
																				{calculateValueCompletion(i)}
																			</StyledTableCell>
																		</StyledTableRow>
																	);
																}
															)}
														</TableHead>
													</Table>
												</TableContainer>
											</div>
										</div>
									</div>

									{/* Ops-1 Values */}
									<div
										className={classes.tablecontainer}
										style={{ marginLeft: 25 }}
									>
										<div className={classes.tableform}>
											<div style={{ maxHeight: '600px', overflow: 'auto' }}>
												<TableContainer component={Paper}
													className={classes.table}
													style={{ width: "100%" }}
													classes={{ root: classes.customTableContainer }}>

													<Table style={{ width: 450 }}
														className={classes.table}
														stickyHeader
														aria-label="sticky table">
														<TableHead style={{ position: 'sticky', top: 0, zIndex: 9, background: 'white' }}>
															<StyledTableRow>
																<StyledTableCell style={{ position: 'sticky', left: 0, zIndex: 9 }}>
																	Ops-1 Stages</StyledTableCell>
																{factoryDataForOps1?.map((el) => {
																	return (
																		<StyledTableCell
																			style={{
																				zIndex: 2,
																				fontWeight: `${el?.locationDetail?.locationName ===
																					"Grand Total"
																					? "700"
																					: ""
																					}`,
																			}}
																		>
																			{el?.locationDetail?.locationName !== "Grand Total" ? el?.locationDetail?.locationName : "Grand Total (In Lakhs)" }
																		</StyledTableCell>
																	);
																})}
																<StyledTableCell>Percentage</StyledTableCell>
																<StyledTableCell>Calculated Value</StyledTableCell>
															</StyledTableRow>
														</TableHead>
														<TableHead style={{ position: 'sticky', top: 0 }}>
															{factoryDataForOps1[0]?.stageData?.map((el, i) => {
																return (
																	<StyledTableRow key={i}>
																		<StyledTableCell
																			style={{
																				position: "sticky",
																				left: 0,
																				zIndex: 9,
																				fontWeight: `${el?.stage === "Grand Total" ? "700" : ""
																					}`,
																			}}
																			component="th"
																		>
																			{el?.stage}
																		</StyledTableCell>
																		{factoryDataForOps1.map((data, j) => {
																			return (
																				<StyledTableCell
																					style={{
																						zIndex: 1,
																						background: "white",
																						color: "black",
																						fontWeight: `${el?.stage === "Grand Total" ||
																							data?.locationDetail
																								?.locationName === "Grand Total"
																							? "700"
																							: ""
																							}`,
																					}}
																					component="th"
																					className={classes.tableCell}
																					onClick={() => {
																						showDetailedModalHandler(
																							data?.stageData[i].data,
																							"Ops-1" +
																							" | " +
																							data?.stageData[i]?.stage
																						);
																					}}
																				>
																					{formatToLakhs(data?.stageData[i]?.grandTotal / 1.18 || 0)}
																				</StyledTableCell>
																			);
																		})}
																		<StyledTableCell
																			style={{
																				zIndex: 1,
																				background: "white",
																				color: "black",
																				// fontWeight: el?.mmyyyy === "Grand Total" || data?.locationDetail?.locationName === "Grand Total" ? "700" : "",
																			}}
																		>
																			<input
																				type="number"
																				onChange={(e) => handlePercentageChangeOps(e, i)}
																				value={factoryDataForOps1Percentages[i] || ""}
																				placeholder="%"
																				style={{
																					width: "100%", 
																					padding: "10px", 
																					border: "2px solid #007BFF",
																					borderRadius: "4px",
																					fontSize: "16px", 
																					backgroundColor: "#F0F8FF"
																				}}
																			/>
																		</StyledTableCell>
																		<StyledTableCell
																			style={{
																				zIndex: 1,
																				background: "white",
																				color: "black",
																				// fontWeight: el?.mmyyyy === "Grand Total" || data?.locationDetail?.locationName === "Grand Total" ? "700" : "",
																			}}
																		>
																			{calculateValueOps(i)}
																		</StyledTableCell>
																	</StyledTableRow>
																);
															})}
														</TableHead>
													</Table>
												</TableContainer>
											</div>
										</div>
									</div>

									{/* Ops-3 Under Execution Values */}
									<div
										className={classes.tablecontainer}
										style={{ marginLeft: 25 }}
									>
										<div className={classes.tableform}>
											<div style={{ maxHeight: '600px', overflow: 'auto' }}>

												<TableContainer component={Paper}
													className={classes.table}
													style={{ width: "100%" }}
													classes={{ root: classes.customTableContainer }}>

													<Table style={{ width: 450 }}
														className={classes.table}
														stickyHeader
														aria-label="sticky table">
														<TableHead style={{ position: 'sticky', top: 0, zIndex: 9, background: 'white' }}>
															<StyledTableRow>
																<StyledTableCell style={{ position: 'sticky', left: 0, zIndex: 9 }}>
																	Modular Delivery Month</StyledTableCell>
																{underExecutionData?.map((el) => {
																	return (
																		<StyledTableCell
																			component="th"
																			style={{
																				zIndex: 2,
																				fontWeight: `${el?.locationDetail?.locationName ===
																					"Grand Total"
																					? "700"
																					: ""
																					}`,
																			}}
																		>
																			{el?.locationDetail?.locationName !== "Grand Total" ? el?.locationDetail?.locationName : "Grand Total (In Lakhs)" }
																		</StyledTableCell>
																	);
																})}
																<StyledTableCell>Percentage</StyledTableCell>
																<StyledTableCell>Calculated Value</StyledTableCell>
															</StyledTableRow>
														</TableHead>
														<TableHead style={{ position: 'sticky', top: 0 }}>
															{underExecutionData[0]?.monthlyData?.map((el, i) => {
																return (
																	<StyledTableRow>
																		<StyledTableCell
																			style={{
																				position: "sticky",
																				left: 0,
																				zIndex: 9,
																				fontWeight: `${el?.mmyyyy === "Grand Total" ? "700" : ""
																					}`,
																			}}
																			component="th"
																		>
																			{el?.mmyyyy === "Grand Total" ? el?.mmyyyy : `${el?.mmyyyy.slice(0, 3)}-${el?.mmyyyy.slice(-2)}`}
																		</StyledTableCell>
																		{underExecutionData?.map((data, j) => {
																			return (
																				<StyledTableCell
																					component="th"
																					style={{
																						zIndex: 1,
																						background: "white",
																						color: "black",
																						fontWeight: `${el?.mmyyyy === "Grand Total" || data?.locationDetail
																							?.locationName === "Grand Total"
																							? "700"
																							: ""
																							}`,
																					}}
																					className={classes.tableCell}
																					onClick={() => {
																						showDetailedModalHandler(
																							data?.monthlyData[i]?.data,
																							"Ops-3 Under Execution" +
																							" | " +
																							data?.monthlyData[i]?.mmyyyy
																						);
																					}}
																				>
																					{formatToLakhs(data?.monthlyData[i]?.grandTotal /1.18)}
																				</StyledTableCell>
																			);
																		})}
																		<StyledTableCell
																			style={{
																				zIndex: 1,
																				background: "white",
																				color: "black",
																				// fontWeight: el?.mmyyyy === "Grand Total" || data?.locationDetail?.locationName === "Grand Total" ? "700" : "",
																			}}
																		>
																			<input
																				type="number"
																				onChange={(e) => handlePercentageChangeOps3(e, i)}
																				value={underExecutionPercentages[i] || ""}
																				placeholder="%"
																				style={{
																					width: "100%",
																					padding: "10px",  
																					border: "2px solid #007BFF",
																					borderRadius: "4px",
																					fontSize: "16px",    
																					backgroundColor: "#F0F8FF"
																				}}
																			/>
																		</StyledTableCell>
																		<StyledTableCell
																			style={{
																				zIndex: 1,
																				background: "white",
																				color: "black",
																				// fontWeight: el?.mmyyyy === "Grand Total" || data?.locationDetail?.locationName === "Grand Total" ? "700" : "",
																			}}
																		>
																			{calculateValueOps3(i)}
																		</StyledTableCell>
																	</StyledTableRow>
																);
															})}
														</TableHead>
													</Table>
												</TableContainer>
											</div>
										</div>
									</div>
								</>
							)}
					</Fragment>
				</Fragment>
			)}

			{/* Detailed Modal On Click */}
			<Modal
				title={title}
				centered
				visible={showDetailedModal}
				onCancel={() => setShowDetailedModal(false)}
				width={1000}
				maskClosable={true}
				footer={null}
			>
				<div className={classes.csv}>
					<CSVLink
						headers={headers}
						filename={title.replace("|", "-") + ".csv"}
						data={csvData}
						ref={csvButtonRef}
					/>
					<Button
						onClick={() => csvDownloadHandler(null)}
						style={{ backgroundColor: "green" }}
						variant="contained"
						color="secondary"
					>
						Download CSV
					</Button>
				</div>

				{detailDataArray && (
					<TableContainer component={Paper} className={classes.table}>
						<Table
							style={{ width: 450 }}
							className={classes.table}
							stickyHeader
							aria-label="sticky table"
						>
							<TableHead>
								<StyledTableRow>
									<StyledTableCell>Sl. No.</StyledTableCell>
									<StyledTableCell>Project No.</StyledTableCell>
									<StyledTableCell>Lead No.</StyledTableCell>
									<StyledTableCell>Stage</StyledTableCell>
									<StyledTableCell>Department</StyledTableCell>
									{/* <StyledTableCell>Client Name</StyledTableCell> */}
									<StyledTableCell>Value (INR)</StyledTableCell>
									<StyledTableCell>City</StyledTableCell>
									<StyledTableCell>Sales Won Date</StyledTableCell>
									<StyledTableCell>Design Sign-Off Date</StyledTableCell>
									{(value !== "Ops-1" && value !== "Ops-2 (Factory)") && <StyledTableCell>Material Delivery Date</StyledTableCell>}
									{(value !== "Ops-1" && value !== "Ops-2 (Factory)" && value !== "Ops-3 (Execution)") && <StyledTableCell>Actual Completion Date</StyledTableCell>}
									{ShowDesignSignOffRevokeDate && <StyledTableCell>Unique Lead ID</StyledTableCell>}
									{ShowDesignSignOffRevokeDate && <StyledTableCell>Design Sign-off HOLD Revoke Date</StyledTableCell>}
									{value === "Ops-1" && <StyledTableCell>Designer/ADM name</StyledTableCell>}
									{value === "Ops-1" && <StyledTableCell>Apartment Name</StyledTableCell>}
									 <StyledTableCell>Current stage</StyledTableCell>
									{value === "Ops-1" && <StyledTableCell>Customer name</StyledTableCell>}
									{value === "Ops-1" && <StyledTableCell>CHM Name</StyledTableCell>}
									{value === "Summary of Completion" && <StyledTableCell>MWP Completion Date</StyledTableCell>}
								</StyledTableRow>
							</TableHead>
							<TableBody>
								{detailDataArray?.map((data, i) => {
									return (
										<StyledTableRow key={i}>
											<StyledTableCell component="th">{i + 1}</StyledTableCell>
											<StyledTableCell
												component="th"
												style={{ textAlign: "center" }}
											>
												{/* {data?.erpProjectNo || "N/A"} */}
												{
													data.erpProjectNo ?
														<Link to={`/project-logs/${data?._id}`} target="_blank" rel="noopener noreferrer" style={{ color: "#000", textDecoration: 'underline', textAlign: 'center' }}>  {data.erpProjectNo}</Link> :
														'NA'
												}
											</StyledTableCell>
											<StyledTableCell
												component="th"
												style={{ textAlign: "center" }}
											>
												{/* {data?.lead_no} */}
												{
													data.lead_no ?
														<Link to={`/project-logs/${data._id}`} target="_blank" rel="noopener noreferrer" style={{ color: "#000", textDecoration: 'underline', textAlign: 'center' }}>  {data.lead_no}</Link> :
														'NA'
												}
											</StyledTableCell>
											<StyledTableCell
												component="th"
												style={{ textAlign: "center" }}
											>
												{data?.currentStage}
											</StyledTableCell>
											<StyledTableCell
												component="th"
												style={{ textAlign: "center" }}
											>
												{data?.departmentName}
											</StyledTableCell>
											{/* <StyledTableCell
                                                component="th"
                                                style={{ textAlign: "center" }}
                                            >
                                                {data?.customerId?.name || "N/A"}
                                            </StyledTableCell> */}
											<StyledTableCell
												component="th"
												style={{ textAlign: "center" }}
											>
												{(data?.grandTotal / 1.18)?.toLocaleString("en-IN", {
													maximumFractionDigits: 2, // Maximum number of decimal places
													style: "currency",
													currency: "INR",
												})}
											</StyledTableCell>
											<StyledTableCell
												component="th"
												style={{ textAlign: "center" }}
											>
												{data?.cityName || "N/A"}
											</StyledTableCell>
											<StyledTableCell
												component="th"
												style={{ textAlign: "center" }}
											>
												{data?.leadWonDate
													? moment(data?.leadWonDate).utc().format("DD-MM-YYYY")
													: "-"}
											</StyledTableCell>
											<StyledTableCell
												component="th"
												style={{ textAlign: "center" }}
											>
												{data?.customerDesignSignOffDate
													? moment(data?.customerDesignSignOffDate)
														.utc()
														.format("DD-MM-YYYY")
													: "-"}
											</StyledTableCell>
											{(value !== "Ops-1" && value !== "Ops-2 (Factory)") && <StyledTableCell
												component="th"
												style={{ textAlign: "center" }}
											>
												{data?.materialDispatchedDate
													? moment(data?.materialDispatchedDate)
														.utc()
														.format("DD-MM-YYYY")
													: "-"}
											</StyledTableCell>}
											{(value !== "Ops-1" && value !== "Ops-2 (Factory)" && value !== "Ops-3 (Execution)") && <StyledTableCell
												component="th"
												style={{ textAlign: "center" }}
											>
												{data?.executionCompletionDate
													? moment(data?.executionCompletionDate)
														.utc()
														.format("DD-MM-YYYY")
													: "-"}
											</StyledTableCell>}
											{ShowDesignSignOffRevokeDate && <StyledTableCell
												component="th"
												style={{ textAlign: "center" }}
											>
												{data?.uniqueLeadNumber}
											</StyledTableCell>}
											{ShowDesignSignOffRevokeDate && <StyledTableCell
												component="th"
												style={{ textAlign: "center" }}
											>
												{data?.designSignoffRevokeDate
													? moment(data?.designSignoffRevokeDate)
														.utc()
														.format("DD-MM-YYYY")
													: "-"}
											</StyledTableCell>}
											{value === "Ops-1" && <StyledTableCell
												component="th"
												style={{ textAlign: "center" }}
											>
												{data?.designUserName || "N/A"}
												{" / "}
												{data?.designManagerName || "N/A"}
											</StyledTableCell>}
											{value === "Ops-1" && <StyledTableCell
												component="th"
												style={{ textAlign: "center" }}
											>
												{data?.customerAddress || "N/A"}
											</StyledTableCell>}
											<StyledTableCell
												component="th"
												style={{ textAlign: "center" }}
											>
												{data?.currentStage || "N/A"}
											</StyledTableCell>
											{value === "Ops-1" && <StyledTableCell
												component="th"
												style={{ textAlign: "center" }}
											>
												{data?.customerName || "N/A"}
											</StyledTableCell>}
											{value === "Ops-1" && <StyledTableCell
												component="th"
												style={{ textAlign: "center" }}
											>
												{data?.chmUserName || "N/A"}
											</StyledTableCell>}
											{value === "Summary of Completion" &&
												<StyledTableCell
													component="th"
													style={{ textAlign: "center" }}
												>
													{data?.executionMWPDate
														? moment(data?.executionMWPDate)
															.utc()
															.format("DD-MM-YYYY")
														: "-"}
												</StyledTableCell>}
										</StyledTableRow>
									);
								})}
							</TableBody>
						</Table>
					</TableContainer>
				)}
			</Modal>
		</Fragment>
	);
};

export default connect(null, { setLoading })(FactoryReviewSheet);