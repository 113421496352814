import React, { useState, useEffect } from 'react'
import HttpService from '../../services/httpService'
import { connect, useSelector } from 'react-redux'
import { setLoading, setSnackInfo } from '../../redux/actions/appAction'
import { Button, Typography, TextField, Paper, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
// import 'antd/dist/antd.css';

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    input: {
        flex: 1,
    },
    iconButton: {
        padding: 10,
        width: '10%'
    },
    divider: {
        height: 28,
        margin: 4,
    },
});

const AddEditCheckList = (props) => {
    const classes = useStyles();

    useEffect(() => {
        getCheckList()
        // eslint-disable-next-line
    }, [])
    const { userDetails } = useSelector(state => state.user)

    const [checkList, setCheckList] = useState([])
    const [checkListItems, setCheckListItems] = useState([])
    const [checkListNotes, setCheckListNotes] = useState([])
    const [isEdit, setIsEdit] = useState(false)
    const [addCheckListModal, setAddCheckListModal] = useState(false);

    const getCheckList = async () => {
        props.setLoading(true)
        try {
            const result = await HttpService.getCheckList();
            setCheckList(result.data)
            setCheckListItems(result.data[0]['checkList'])
            setCheckListNotes(result.data[0]['notes'])
            if(result.data.length !== 0) {
                setIsEdit(true)
            }
        } catch (error) {
            console.error(error)
            props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const addCheckItems = (type) => {
        if (type === 'checkList') {
            let newObj = {
                ids: checkListItems.length + 1,
                id: +checkListItems[checkListItems.length - 1].id + 1,
                description: 'Enter Description', yes: '', no: '', na: ''
            }
            let checklistItemArr = []
            checklistItemArr.push(newObj)
            let finalItem = [...checkListItems, ...checklistItemArr]
            setCheckListItems(finalItem)
        } else if (type === 'notes') {
            let newObj = {
                ids: checkListNotes.length + 1,
                id: +checkListNotes[checkListNotes.length - 1].id + 1,
                description: 'Enter Description', yes: '', no: '', na: ''
            }
            let notesArr = []
            notesArr.push(newObj)
            let finalItem = [...checkListNotes, ...notesArr]
            setCheckListNotes(finalItem)
        }
    }

    const removeTerms = (type, removeid) => {
        if (type === 'checkList') {
            let filterItem = checkListItems.filter(ele => ele.id != removeid)
            setCheckListItems(filterItem)
        } else if (type === 'notes') {
            let filterItem = checkListNotes.filter(ele => ele.id != removeid)
            setCheckListNotes(filterItem)
        }
    }

    const updateCheckList = (e, index, type) => {
        if (type === 'checkList') {
            const newSelected = [...checkListItems];
            newSelected[index].description = e.target.value;
            setCheckListItems(newSelected)
        } else if (type === 'notes') {
            const newSelected = [...checkListNotes];
            newSelected[index].value = e.target.value;
            setCheckListNotes(newSelected)
        }
    }

    const addCheckList = async () => {
        props.setLoading(true)
        let obj = {
            checkList: checkListItems,
            notes: checkListNotes,
            createdBy: userDetails._id
        }
        try {
            await HttpService.addCheckList(obj)
            props.setSnackInfo('CheckList Added Successfully', "success")
            setAddCheckListModal(false)
            getCheckList()
        } catch (error) {
            console.error(error)
            props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const editCheckList = async () => {
        props.setLoading(true)
        let obj = {
            checkList: checkListItems,
            notes: checkListNotes,
            updatedBy: userDetails._id
        }
        try {
            await HttpService.editCheckList(checkList[0]._id, obj)
            props.setSnackInfo('Check list Updated successfully', "success")
            setAddCheckListModal(false)
            setIsEdit(false)
            getCheckList()
        } catch (error) {
            console.error(error)
            props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    return (
        <div>
            <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: 10 }}>
                <Button variant="contained" color="primary" onClick={() => setAddCheckListModal(true)}>
                    {
                        isEdit ? <>Edit Check List</> : <>Add Check List</>
                    }
                </Button>
            </div>

            <Paper>
                <Typography variant="h6" gutterBottom style={{ marginLeft: 20, marginTop: 20, paddingTop: 20 }}>
                    Check List
                </Typography >
                <ul style={{ paddingBottom: 20, listStyle: 'none', marginRight: 10 }}>
                    {
                        checkList.length !== 0 &&
                        checkList[0].checkList.map((data, i) => (
                            <li key={i}>{data.id}: {data.description}</li>
                        ))
                    }
                </ul>

                <Divider />

                <Typography variant="h6" gutterBottom style={{ marginLeft: 20, marginTop: 20 }}>
                    Notes
                </Typography >
                <ul style={{ paddingBottom: 20, listStyle: 'none', marginRight: 10 }}>
                    {
                        checkList.length !== 0 &&
                        checkList[0].notes.map((data, i) => (
                            <li key={i}>{data.id}: {data.value}</li>
                        ))
                    }
                </ul>
            </Paper>

            <Dialog
                open={addCheckListModal}
                keepMounted
                fullWidth={true}
                maxWidth={'sm'}
                onClose={() => setAddCheckListModal(false)}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{"Add Check List Items and Notes"}</DialogTitle>
                <Divider />
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description" style={{ display: "flex", flexDirection: "column" }}>
                        <Typography component="h6" gutterBottom><strong>Check list Item</strong></Typography>
                        {
                            checkListItems.length !== 0 &&
                            checkListItems.map((data, i) => (
                                <Paper >
                                    <IconButton className={classes.iconButton} aria-label="menu">
                                        {data?.id}
                                    </IconButton>
                                    <TextField label="Check list" value={data.description} size="small" style={{ verticalAlign: 'middle', width: '70%' }} name="checkListItem" onChange={(e) => updateCheckList(e, i, 'checkList')} variant="outlined" />
                                    {
                                        checkListItems.length === i + 1 && <>
                                            <IconButton type="submit" className={classes.iconButton} aria-label="add" onClick={() => addCheckItems('checkList')}>
                                                <AddIcon />
                                            </IconButton>
                                            <IconButton color="primary" className={classes.iconButton} aria-label="remove" onClick={() => removeTerms('checkList', data.id)}>
                                                <RemoveIcon />
                                            </IconButton>
                                        </>
                                    }
                                    {
                                        checkListItems.length !== i + 1 &&
                                        <IconButton color="primary" className={classes.iconButton} aria-label="directions" onClick={() => removeTerms('checkList', data.id)}>
                                            <RemoveIcon />
                                        </IconButton>
                                    }
                                </Paper>
                            ))
                        }

                        <br />
                        <Typography component="h6" gutterBottom><strong>Notes Item</strong></Typography>
                        {
                            checkListNotes.length !== 0 &&
                            checkListNotes.map((data, i) => (
                                <Paper >
                                    <IconButton className={classes.iconButton} aria-label="menu">
                                        {data?.id}
                                    </IconButton>
                                    <TextField label="Notes" value={data.value} size="small" style={{ verticalAlign: 'middle', width: '70%' }} name="notes" onChange={(e) => updateCheckList(e, i, 'notes')} variant="outlined" />
                                    {
                                        checkListNotes.length === i + 1 && <>
                                            <IconButton type="submit" className={classes.iconButton} aria-label="add" onClick={() => addCheckItems('notes')}>
                                                <AddIcon />
                                            </IconButton>
                                            <IconButton color="primary" className={classes.iconButton} aria-label="remove" onClick={() => removeTerms('notes', data.id)}>
                                                <RemoveIcon />
                                            </IconButton>
                                        </>
                                    }
                                    {
                                        checkListNotes.length !== i + 1 &&
                                        <IconButton color="primary" className={classes.iconButton} aria-label="directions" onClick={() => removeTerms('notes', data.id)}>
                                            <RemoveIcon />
                                        </IconButton>
                                    }
                                </Paper>
                            ))
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {isEdit ? <Button onClick={editCheckList} color="primary" variant="contained">
                        Update Check List
                    </Button> : <Button onClick={addCheckList} color="primary" variant="contained">
                        Add Check List
                    </Button>}
                </DialogActions>
            </Dialog>

        </div >
    )
}

export default connect(null, { setLoading, setSnackInfo })(AddEditCheckList)
