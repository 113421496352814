import React from 'react'
import InwardingView from '../../../components/inwardingView'
const InwardingForFactory = () => {
  return (
    <div>
      <InwardingView TYPE="Factory" />
    </div>
  )
}

export default InwardingForFactory
