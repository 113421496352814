import React, { useState, useEffect } from 'react'
import { useRef } from 'react';
import HttpService from '../services/httpService'
import moment from 'moment'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { connect } from 'react-redux'
import { setLoading, setSnackInfo } from '../redux/actions/appAction'
import PaymentRequestView from './paymentRequestView';

const RaisePayementRequestModal = (props) => {
  const radioClick = useRef(null)
  const radioClick2 = useRef(null)

  const [poSoApprovedList, setPoSoApprovedList] = useState([])
  const [radioValue, setRadioValue] = useState("PO")
  const [clicked, setclicked] = useState(true)

  useEffect(() => {
    // if(clicked){
      // radioClick.current.click()
      // radioClick2.current.click()
      // setclicked(true)
    // }
    if (radioValue === "PO")
      getApprovedPoForPayment()
    else
      getApprovedSoForPayment()
    // eslint-disable-next-line
  }, [radioValue, clicked,props.MOUNTED])

  const getApprovedPoForPayment = async () => {
    props.setLoading(true)
    try {
      const result = await HttpService.getApprovedPoForPayment(props.SELECTEDPROJECT._id, props.SCOPE)
      const poApprovedList = result.data.map(item => {
        let remainingAmount = item?.total
        console.log(item,"itemmmm")
        let obj = {
          orderId: item?.orderId,
          code: `${item?.projectId?.clientName}_${item?.projectId?.code}`,
          vendorName: item?.vendorId?.name,
          vendorAadhar: item?.vendorId?.aadharCardLink,
          vendorPAN: item?.vendorId?.panCardLink,
          createdAt: moment(item?.createAt).format("DD-MM-YYYY"),
          status: item?.status,
          total: item?.total,
          link: item?.poLink,
          id: item?._id,
          paymentCompleted: item?.paymentRequests[item.paymentRequests.length - 1]?.paymentCompleted ? item?.paymentRequests[item.paymentRequests.length - 1]?.paymentCompleted : false
        }
        if (item?.paymentRequests?.length > 0) {
          item?.paymentRequests?.map(request => {
            if (request.status === "Approved") {
              remainingAmount -= request.amount
            }
            return null
          })
        }
        obj.remainingAmount = remainingAmount.toFixed(2)
        return obj
      })
      setPoSoApprovedList(poApprovedList)
    } catch (error) {
      setPoSoApprovedList([])
      console.error(error)
      props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
    }
    props.setLoading(false)
  }

  const getApprovedSoForPayment = async () => {
    props.setLoading(true)
    try {
      const result = await HttpService.getApprovedSoForPayment(props.SELECTEDPROJECT._id, props.SCOPE)
      const soApprovedList = result.data.map(item => {
        let remainingAmount = item?.total
        let obj = {
          orderId: item?.orderId,
          code: `${item?.projectId?.clientName}_${item?.projectId?.code}`,
          vendorName: item?.vendorId?.name,
          vendorAadhar: item?.vendorId?.aadharCardLink,
          vendorPAN: item?.vendorId?.panCardLink,
          createdAt: moment(item?.createAt).format("DD-MM-YYYY"),
          status: item?.status,
          total: item?.total,
          link: item?.soLink,
          id: item?._id,
          paymentCompleted: item?.paymentRequests[item.paymentRequests.length - 1]?.paymentCompleted ? item?.paymentRequests[item.paymentRequests.length - 1]?.paymentCompleted : false
        }
        if (item?.paymentRequests?.length > 0) {
          item?.paymentRequests?.map(request => {
            if (request.status === "Approved") {
              remainingAmount -= request.amount
            }
            return null
          })
        }
        obj.remainingAmount = remainingAmount.toFixed(2)
        return obj
      })
      setPoSoApprovedList(soApprovedList)
    } catch (error) {
      setPoSoApprovedList([])
      console.error(error)
      props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
    }
    props.setLoading(false)
  }

  const handleRadioChange = (e) => {
    setRadioValue(e.target.value)
    if (e.target.value === "PO")
      getApprovedPoForPayment()
    else
      getApprovedSoForPayment()
  }

  return (
    <div>
      <FormControl component="fieldset">
        <RadioGroup aria-label="type" name="type" value={radioValue} onChange={handleRadioChange}>
          <div className='modal-header-radio'>
            <FormControlLabel value="PO" control={<Radio ref={radioClick}/>} label="PO" />
            <FormControlLabel value="SO" control={<Radio ref={radioClick2}/>} label="SO" />
          </div>
        </RadioGroup>
      </FormControl>

      {poSoApprovedList.length > 0 ? <div>
        <div>{`${radioValue === "PO" ? 'Procurement' : ' Service'}`} Order Payment Request</div>
        <PaymentRequestView RADIOVALUE={radioValue} PAYMENTLIST={poSoApprovedList} REFRESH={radioValue === "PO" ? getApprovedPoForPayment : getApprovedSoForPayment}  {...props} />
      </div > : <div>  {`No ${radioValue} to raise payment`}</div>
      }
    </div >
  )
}

export default connect(null, { setSnackInfo, setLoading })(RaisePayementRequestModal)
