import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { setLoading, setSnackInfo } from '../redux/actions/appAction';
import HttpService from '../services/httpService';
import { Table, TableBody, DialogTitle, Dialog, DialogActions, DialogContent, TableCell, TableContainer, TableRow, TableHead, Button, Paper, Divider, TextField, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment'
import { commaSeparateAmount } from './commaSeparate';

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 600,
    },
    textFieldWidth: {
        width: "100%"
    },
    input: {
        display: 'none',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

const TargetDetails = (props) => {
    const classes = useStyles();
    const [selectedFilterOption, setSelectedFilterOption] = useState(props.location.state === null ? 'leadWonDate' : props.location.state.filterOption);
    const [startDateFilter, setStartDate] = useState(props.location.state === null ? moment().subtract(1, 'months').format('YYYY-MM-DD') : props.location.state.startDate)
    const [endDateFilter, setEndDate] = useState(props.location.state === null ? moment().add(1, 'days').format('YYYY-MM-DD') : props.location.state.endDate);
    const [openLeadViewModal, setOpenLeadViewModal] = useState(false);
    const [showLead, setShowLead] = useState([]);
    useEffect(() => {
        getAchivedVsTargetList();
        // eslint-disable-next-line
    }, [])

    const [achivedLeadDetails, setAchivedLeadDetails] = useState([]);

    const getAchivedVsTargetList = async () => {
        let obj = {};
        obj.experienceCenterId = props.location.state.experienceCenterId;
        obj.userInfo = props.location.state.currentUserInfo;
        obj.currentSelecredRole = props.location.state.currentSelecredRole;
        obj.startDate = startDateFilter;
        obj.endDate = endDateFilter;
        obj.filterOption = selectedFilterOption;
        props.setLoading(true)
        try {
            const response = await HttpService.getAchivedVsTargetList(obj);
            let finalResponseData = response.data;
            finalResponseData.sort((a, b) => (a.salesManager > b.salesManager) ? 1 : ((b.salesManager > a.salesManager) ? -1 : 0))

            const countTeamTotal = Array.from(finalResponseData.reduce(
                (m, { salesManager, achived }) => m.set(salesManager, (m.get(salesManager) || 0) + achived), new Map
            ), ([salesManager, achived]) => ({ salesManager, achived }));

            const countTeamTargetTotal = Array.from(finalResponseData.reduce(
                (m, { salesManager, target }) => m.set(salesManager, (m.get(salesManager) || 0) + target), new Map
            ), ([salesManager, target]) => ({ salesManager, target }));

            for (let i = 0; i < finalResponseData.length; i++) {
                for (let j = 0; j < countTeamTotal.length; j++) {
                    if (finalResponseData[i].salesManager === countTeamTotal[j].salesManager) {
                        finalResponseData[i].salesTeamAchived = countTeamTotal[j].achived
                    }
                }
                for (let l = 0; l < countTeamTargetTotal.length; l++) {
                    if (finalResponseData[i].salesManager === countTeamTargetTotal[l].salesManager) {
                        finalResponseData[i].salesTeamTarget = countTeamTargetTotal[l].target
                    }
                }
            }

            if (props.location.state.currentSelecredRole === 'Sales User') {
                setShowLead(response.data.leadList)
            } else {
                setAchivedLeadDetails(finalResponseData)
            }
        } catch (error) {
            console.log(error, 'error')
            props.setSnackInfo(error?.response ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const handleChangeFilterOption = (event) => {
        setSelectedFilterOption(event.target.value);
    }
    const getLeadsOnSelectedDateByRole = async () => {
        let obj = {};
        obj.experienceCenterId = props.location.state.experienceCenterId;
        obj.userInfo = props.location.state.currentUserInfo;
        obj.currentSelecredRole = props.location.state.currentSelecredRole;
        obj.startDate = startDateFilter;
        obj.endDate = endDateFilter;
        obj.filterOption = selectedFilterOption;
        props.setLoading(true)
        try {
            const response = await HttpService.getAchivedVsTargetList(obj);
            let finalResponseData = response.data;
            finalResponseData.sort((a, b) => (a.salesManager > b.salesManager) ? 1 : ((b.salesManager > a.salesManager) ? -1 : 0))

            const countTeamTotal = Array.from(finalResponseData.reduce(
                (m, { salesManager, achived }) => m.set(salesManager, (m.get(salesManager) || 0) + achived), new Map
            ), ([salesManager, achived]) => ({ salesManager, achived }));

            const countTeamTargetTotal = Array.from(finalResponseData.reduce(
                (m, { salesManager, target }) => m.set(salesManager, (m.get(salesManager) || 0) + target), new Map
            ), ([salesManager, target]) => ({ salesManager, target }));

            for (let i = 0; i < finalResponseData.length; i++) {
                for (let j = 0; j < countTeamTotal.length; j++) {
                    if (finalResponseData[i].salesManager === countTeamTotal[j].salesManager) {
                        finalResponseData[i].salesTeamAchived = countTeamTotal[j].achived
                    }
                }
                for (let l = 0; l < countTeamTargetTotal.length; l++) {
                    if (finalResponseData[i].salesManager === countTeamTargetTotal[l].salesManager) {
                        finalResponseData[i].salesTeamTarget = countTeamTargetTotal[l].target
                    }
                }
            }
            props.location.state.currentSelecredRole === 'Sales User' ? setShowLead(response.data.leadList) : setAchivedLeadDetails(finalResponseData)
        } catch (error) {
            console.log(error, 'error')
            props.setSnackInfo(error?.response ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const viewWonLeads = (data) => {
        setOpenLeadViewModal(true);
        setShowLead(data)
    }

    let namesArr = {};
    const rowSpan = achivedLeadDetails.reduce((result, item, key) => {
        if (namesArr[item.salesManager] === undefined) {
            namesArr[item.salesManager] = key;
            result[key] = 1;
        } else {
            const firstIndex = namesArr[item.salesManager];
            if (
                firstIndex === key - 1 ||
                (item.salesManager === achivedLeadDetails[key - 1].salesManager && result[key - 1] === 0)
            ) {
                result[firstIndex]++;
                result[key] = 0;
            } else {
                result[key] = 1;
                namesArr[item.salesManager] = key;
            }
        }
        return result;
    }, []);

    return (
        <div>
            <div style={{ marginBottom: 20 }}>
                <h2 style={{ textAlign: 'center' }}>
                    Target achieved vs Target Details
                </h2>
            </div>
            <Divider />
            <Paper className={classes.paper}>
                <FormControl component="fieldset" style={{ marginBottom: 20 }}>
                    <FormLabel component="legend">Select Filter Option</FormLabel>
                    <RadioGroup row aria-label="filterOption" name="filterOption1" value={selectedFilterOption} onChange={handleChangeFilterOption}>
                        <FormControlLabel value="leadWonDate" control={<Radio />} label="Lead Won Date" />
                        <FormControlLabel value="createdAt" control={<Radio />} label="Lead Created At" />
                    </RadioGroup>
                </FormControl>
                <br />
                <Grid container>
                    <Grid item md={1} xs={12}></Grid>
                    <Grid item md={3} xs={12}>
                        <TextField
                            id="date"
                            name="startDate"
                            label="From Date"
                            type="date"
                            value={startDateFilter}
                            onChange={(e) => setStartDate(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                            className={classes.textField}
                        />
                    </Grid>
                    <Grid item md={1} xs={12}></Grid>
                    <Grid item md={3} xs={12}>
                        <TextField
                            id="date"
                            name="endDate"
                            label="End Date"
                            type="date"
                            value={endDateFilter}
                            onChange={(e) => setEndDate(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                            className={classes.textField}
                        />
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <Button variant="contained" color="primary" disabled={!startDateFilter || !endDateFilter} style={{ alignSelf: "center", marginLeft: 20 }} onClick={getLeadsOnSelectedDateByRole} >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
            <Divider />

            <div style={{ margin: '50px auto' }}>
                <Paper className={classes.paper}>
                    {
                        (props.location.state.currentSelecredRole === 'Sales Manager' || props.location.state.currentSelecredRole === 'Sales Head' || props.location.state.currentSelecredRole === 'Assistant Sales Manager') &&
                        <TableContainer component={Paper}>
                            <Table className={classes.table} stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow >
                                        <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Sales Manager</TableCell>
                                        <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Sales Executive</TableCell>
                                        <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Achieved</TableCell>
                                        <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Target</TableCell>
                                        <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>
                                            Total Team Details <br />
                                            Achieved / Target
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {achivedLeadDetails.length !== 0 ?
                                        achivedLeadDetails.map((row, i) => {
                                            return (
                                                <TableRow key={i + 1}>
                                                    {
                                                        rowSpan[i] > 0 &&
                                                        <TableCell scope="row" style={{ padding: 10, width: 500, textAlign: 'center' }} rowSpan={rowSpan[i]} >
                                                            {row?.name === "Total" ? <b>{row?.salesManager}</b> : row?.salesManager}
                                                        </TableCell>
                                                    }
                                                    <TableCell component={row?.name === "Total" ? "" : "th"} scope="row" style={{ padding: 10, width: 500, textAlign: 'center' }}>
                                                        {row?.name === "Total" ? <b>{row?.name}</b> : row?.name}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" style={{ padding: 10, width: 500, textAlign: 'center' }}>
                                                        {
                                                            row?.name === "Total" ?
                                                                <> ₹ {row.achived ? commaSeparateAmount(row.achived) : `0`} </>
                                                                :
                                                                <Button onClick={() => viewWonLeads(row.leadList)} color="primary">
                                                                    ₹ {row.achived ? commaSeparateAmount(row.achived) : `0`}
                                                                </Button>
                                                        }
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" style={{ padding: 10, width: 500, textAlign: 'center' }}>
                                                        {
                                                            <> ₹ {row.target ? commaSeparateAmount(row.target) : `0`} </>
                                                        }
                                                    </TableCell>
                                                    {rowSpan[i] > 0 &&
                                                        <TableCell component="th" scope="row" style={{ padding: 10, width: 500, textAlign: 'center' }} rowSpan={rowSpan[i]}>
                                                            {
                                                                row?.name === "Total" ? '' :
                                                                    <>₹ {row.salesTeamAchived ? commaSeparateAmount(row.salesTeamAchived) : `0`} / ₹ {row.salesTeamTarget ? commaSeparateAmount(row.salesTeamTarget) : `0`} </>
                                                            }
                                                        </TableCell>
                                                    }
                                                </TableRow>
                                            );
                                        })
                                        :
                                        `No Data found`}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                    {
                        props.location.state.currentSelecredRole === "Sales User" &&
                        <TableContainer component={Paper}>
                            <Table className={classes.table} stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow >
                                        <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Customer Name</TableCell>
                                        <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Assign To</TableCell>
                                        <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Payment Amount</TableCell>
                                        <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Total Amount</TableCell>
                                        <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Token Percentage</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {showLead.length !== 0 ?
                                        showLead.map((row, i) => {
                                            return (
                                                <TableRow key={i + 1}>
                                                    <TableCell component="th" scope="row" style={{ padding: 10, width: 500, textAlign: 'center' }}> {row?.customerId?.name} </TableCell>
                                                    <TableCell component="th" scope="row" style={{ padding: 10, width: 500, textAlign: 'center' }}>{row?.assignTo?.name}</TableCell>
                                                    <TableCell component="th" scope="row" style={{ padding: 10, width: 500, textAlign: 'center' }}>{commaSeparateAmount(row.paymentDone)}</TableCell>
                                                    <TableCell component="th" scope="row" style={{ padding: 10, width: 500, textAlign: 'center' }}>{commaSeparateAmount(row.grandTotal)}</TableCell>
                                                    <TableCell component="th" scope="row" style={{ padding: 10, width: 500, textAlign: 'center' }}>{row.tokenPercent}</TableCell>
                                                </TableRow>
                                            );
                                        })
                                        :
                                        `No Lead Found`
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                </Paper>
            </div>
            <Dialog open={openLeadViewModal} onClose={() => setOpenLeadViewModal(false)}>
                <DialogTitle>Won Leads</DialogTitle>
                <DialogContent>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow >
                                    <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Customer Name</TableCell>
                                    <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Assign To</TableCell>
                                    <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Payment Amount</TableCell>
                                    <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Total Amount</TableCell>
                                    <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Token Percentage</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {showLead.map((row, i) => {
                                    return (
                                        <TableRow key={i + 1}>
                                            <TableCell component="th" scope="row" style={{ padding: 10, width: 500, textAlign: 'center' }}> {row?.customerId?.name} </TableCell>
                                            <TableCell component="th" scope="row" style={{ padding: 10, width: 500, textAlign: 'center' }}>{row?.assignTo?.name}</TableCell>
                                            <TableCell component="th" scope="row" style={{ padding: 10, width: 500, textAlign: 'center' }}>{commaSeparateAmount(row.paymentDone)}</TableCell>
                                            <TableCell component="th" scope="row" style={{ padding: 10, width: 500, textAlign: 'center' }}>{commaSeparateAmount(row.grandTotal)}</TableCell>
                                            <TableCell component="th" scope="row" style={{ padding: 10, width: 500, textAlign: 'center' }}>{row.tokenPercent}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenLeadViewModal(false)} variant="outlined" color="primary">Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default connect(null, { setLoading, setSnackInfo })(TargetDetails)
