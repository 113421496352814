import { connect } from 'react-redux'
import { setLoading, setSnackInfo } from '../redux/actions/appAction'
import { Table, TableBody, TableCell, TableHead, TableContainer, TableRow, withStyles, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { DownloadOutlined, EyeOutlined, DeleteOutlined  } from '@ant-design/icons';
import HttpService from '../services/httpService';
import { useSelector } from 'react-redux';
import React, { useState } from 'react'
import { Box, Dialog, DialogTitle, DialogContent, DialogActions,Button } from '@material-ui/core'
import { ROLENAME} from '../utils/index';

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  }
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#ccc",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const MaterialTransferDetailsModal = (props) => {
  const classes = useStyles();
  const { userDetails } = useSelector(state => state.user)

  const editMaterialTransfer = async (status, id) => {
    props.setLoading(true)
    try {
      await HttpService.editMaterialTransfer(id, { status })
      props.setSnackInfo(`Material Transfer Request ${status}`, 'success')
      props.REFRESH(props.MTRDETAILS._id)
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Error while updating material transfer request", "error")
    }
    props.setLoading(false)
  }

const [deleteOpenModel,setDeleteOpenModel] = useState(false)

  const removeMtrForProject = () => {
    setDeleteOpenModel(true)
  }

  const deleteMtrConfirm = async () =>{
  props.setLoading(true)
  try {
    let response = await HttpService.removeMtr(props.MTRDETAILS._id)
    props.setSnackInfo(response?.data ?? "Something went wrong", "success")
    setDeleteOpenModel(false)
    props.VIEWALL(props.TITLE === "Po/Mtr Raised/Requested Details" ? "Po/Mtr Raised/Requested" : "Pending For Approval")
  } catch (error) {
    console.error(error)
    props.setSnackInfo(error?.response?.data?.message ?? "Error", "error")
  }
  props.setLoading(false)
  }
  return (
    <div>
      <div className='modal-header'>
        <div style={{ width: "25%" }}>
          <b>OrderID: </b>
          <span>{props.MTRDETAILS?.orderId}</span>
        </div>
        <div style={{ width: "25%" }}>
          <b>Created At: </b>
          <span>{moment(props.MTRDETAILS?.createdAt).format('DD-MM-YYYY')}</span>
        </div>
        <div style={{ width: "25%" }}>
          <b>Project Code: </b>
          <span>{props.MTRDETAILS?.projectId?.code ?? "OPEN"}</span>
        </div>
      </div>
      <div className='modal-header'>
        <div style={{ width: "25%" }}>
          <b>Client: </b>
          <span>{props.MTRDETAILS.projectId?.clientName ?? "MTR"}</span>
        </div>
        {props.MTRDETAILS?.status !== "Sent for Approval" && props.MTRDETAILS?.mtrLink !== undefined && <div className="btn" style={{ width: "20%" }}>
          <span onClick={() => window.open(props.MTRDETAILS?.mtrLink)}>Download MTR <DownloadOutlined /></span>
        </div>}
        <div className="btn" style={{ width: "20%" }} onClick={() => props.VIEWALL(props.TITLE === "Po/Mtr Raised/Requested Details" ? "Po/Mtr Raised/Requested" : "Pending For Approval")}>
          <span >View All <EyeOutlined /></span>
        </div>
        {userDetails?.roles?.find(role => role.name === "Operation Head") &&
        <div className="btn" >
          {userDetails?.roles?.find(role => role.name == ROLENAME.OperationHead) && <span onClick={() => removeMtrForProject()}>Delete MTR <DeleteOutlined /> </span>}
        </div>
        }
      </div>
      <div></div>
      {props.MTRDETAILS?.materials?.length && <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Sl No</StyledTableCell>
              <StyledTableCell>Material Name</StyledTableCell>
              <StyledTableCell>Scope</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Quantity</StyledTableCell>
              <StyledTableCell>Requested Qty</StyledTableCell>
              <StyledTableCell>Unit</StyledTableCell>
              <StyledTableCell>Total</StyledTableCell>
              {props.TITLE !== "Po/Mtr Raised/Requested Details" && props.MTRDETAILS.status === "Sent For Approval" && <StyledTableCell StyledTableCell align="center">Action</StyledTableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.MTRDETAILS?.materials?.map((data, i) => (
              <StyledTableRow key={i + 1}>
                <StyledTableCell>{i + 1}</StyledTableCell>
                <StyledTableCell>{data?.materialId?.name}</StyledTableCell>
                <StyledTableCell>{props.MTRDETAILS?.scopeId?.name}</StyledTableCell>
                <StyledTableCell>{data?.status}</StyledTableCell>
                <StyledTableCell>{data?.quantity}</StyledTableCell>
                <StyledTableCell>{data?.requestedQuantity}</StyledTableCell>
                <StyledTableCell>{data?.unitId?.name}</StyledTableCell>
                {/* <StyledTableCell>{data?.materialId.pricePerUnit * data?.requestedQuantity}</StyledTableCell>  */}
                <StyledTableCell>{data?.pricePerUnit * data?.requestedQuantity}</StyledTableCell> 
                {props.TITLE !== "Po/Mtr Raised/Requested Details" && props.MTRDETAILS.status === "Sent For Approval" && <StyledTableCell align="left">
                  {data?.status !== "Approved" && data?.status !== "Rejected" && props.MTRDETAILS?.status !== "Approved" && props.MTRDETAILS?.status !== "Rejected" && <div className="approve-reject-btn">
                    <button onClick={() => editMaterialTransfer("Approved", data?._id)} className='approve-btn'>Approve</button>
                    <button onClick={() => editMaterialTransfer("Rejected", data?._id)} className='reject-btn'>Reject</button>
                  </div>}
                </StyledTableCell>}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>}
      <div>
        <Dialog open={deleteOpenModel} onClose={() => setDeleteOpenModel(false)} aria-labelledby="form-dialog-title" maxWidth='sm'>
          <DialogTitle id="form-dialog-title">This Action cannot be Reverted!</DialogTitle>
          <DialogActions>
            <Button variant="outlined" onClick={() => setDeleteOpenModel(false)} color="secondary">
              Cancel
            </Button>
            <Button onClick={deleteMtrConfirm} variant="outlined" color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div >
  )
}

export default connect(null, { setLoading, setSnackInfo })(MaterialTransferDetailsModal)
