import React, { useEffect, useState, useRef } from "react";
import {
	Accordion,
	AccordionSummary,
	Table,
	Paper,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	makeStyles,
	withStyles,
	Button,
	Grid,
	TextField,
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
	Checkbox,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
	KeyboardArrowDown,
	KeyboardArrowUp,
	SwapVert,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
	tablecontainer: {
		display: "flex",
		margin: "25px",
	},
	tableform: {
		display: "flex",
	},
	paper: {
		padding: theme.spacing(2),
		margin: "auto",
		maxWidth: 500,
	},
	accordion: {
		width: "fit-content",
		minWidth: "100%",
		marginTop: "10px",
		marginBottom: "10px",
		borderRadius: "5px",
		"&::before": {
			height: "0", // Set height to 0 to avoid the pseudo separator provide by MUI for Accordion
		},
	},
	clickableTableCell: {
		textAlign: "center",
		cursor: "pointer",
		color: "blue",
		textDecoration: "underline",
	},
	grandTotalTableCell: {
		textAlign: "center",
		cursor: "pointer",
		color: "blue",
		textDecoration: "underline",
		fontWeight: 700,
	},
	normalTableCell: {
		textAlign: "center",
		fontWeight: 700,
	},
	table: {
		width: "fit-content",
	},
	csv: {
		display: "flex",
		alignItems: "center",
		marginBottom: 20,
	},
	clickSort: {
		textAlign: "center",
		cursor: "pointer",
	},
}));

const AccordionComponent = (props) => {
	const { DATA_ARRAY } = props;
	const classes = useStyles();
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [checked, setChecked] = useState(false);
	const [radioForSalesWon, setRadioForSalesWon] = useState("All Bookings");
	const [ascSort, setAscSort] = useState(true);
	const [nameSortFlag, setNameSortFlag] = useState(true);
	const [dataArray, setDataArray] = useState([]);

	// Function to clear date filter
	const clearFilterDate = (e) => {
		setStartDate("");
		setEndDate("");
		setChecked(false);
		setRadioForSalesWon("All Bookings");
		props?.HANDLE_DATE("", "", "Won", "All Bookings");
	};

	// Function to handle data based on startDate & endDate
	const handleDateFilter = () => {
		props?.HANDLE_DATE(
			startDate,
			endDate,
			checked ? "Lost" : "Won",
			radioForSalesWon
		);
	};

	// Function to format the value in lacs
	const formatNumberToLacs = (number) => {
		if (typeof number !== "number") {
			return "Invalid input";
		}

		// Divide the number by 100000 to get the value in lacs
		const valueInLacs = number / 100000;

		// Round the value to two decimal places
		const roundedValue = Math.round(valueInLacs * 100) / 100;

		return roundedValue;
	};

	// Function to handle the radio value change
	const handleRadioChange = (e) => {
		setRadioForSalesWon(e.target.value);
		props?.HANDLE_DATE(
			startDate,
			endDate,
			checked ? "Lost" : "Won",
			e.target.value
		);
	};

	// Function to handle the checkbox for lost
	const handleChange = (event) => {
		setChecked(event.target.checked);
		props?.HANDLE_DATE(
			startDate,
			endDate,
			event.target.checked ? "Lost" : "Won",
			radioForSalesWon
		);
	};

	// Function to open the pop for Details by calling a callback getting through props
	const showDetailedModalHandlerOnClick = (
		data,
		month,
		year,
		stage,
		leadStatus,
		tokenValue
	) => {	
		// Adding a check so that when there is only one ID, it calls the API based on that. 
		// However, when it clicks on 'Grand Total,' it transforms it into an array of IDs
		if(!data.hasOwnProperty("userId") && props.FIRST_COLUMN_NAME !== "Exp. Ctr Name"){
			// Placing the array into the set to ensure that it does not contain duplicate IDs
			// & filtering out null and undefined values
			const uniqueObjectIds = [...new Set(dataArray.map((el)=>el?.userId).filter((id) => id !== null && id !== undefined))];
			data.userId = uniqueObjectIds;
		}
		props.SHOWDETAILMODALHANDLER(
			data,
			month,
			year,
			stage,
			leadStatus ? "Lost" : "Won",
			tokenValue,
			props.FIRST_COLUMN_NAME
		);
	};

	// Function to custom sort based on count
	const customSortOnCount = (arr, asc, param) => {
		const lastElement = arr[arr.length - 1];
		let sortedArr;
		// Sort all elements except the last one
		if (!asc) {
			sortedArr = arr
				.slice(0, arr.length - 1)
				.sort((a, b) => a[param] - b[param]);
		} else {
			sortedArr = arr
				.slice(0, arr.length - 1)
				.sort((a, b) => b[param] - a[param]);
		}
		// Insert the last element at the end
		sortedArr.push(lastElement);
		return sortedArr;
	};

	// Function to sort the data based on count which is mapped in Grand Total Column
	const sortByCountHandler = (sortParam) => {
		// ascSort here is to check if data is already sorted (asc / desc) to revert the sorting
		if (!ascSort) {
			setDataArray(customSortOnCount(dataArray, ascSort, sortParam));
			setAscSort(true);
		} else {
			setDataArray(customSortOnCount(dataArray, ascSort, sortParam));
			setAscSort(false);
		}
	};

	// Function to custom sort alphabetically
	const customSortOnName = (arr, asc, param) => {
		const lastElement = arr[arr.length - 1];
		let sortedArr;
		// Sort all elements except the last one
		if (!asc) {
			sortedArr = arr
				.slice(0, arr.length - 1)
				.sort((a, b) => a[param].localeCompare(b[param]));
		} else {
			sortedArr = arr
				.slice(0, arr.length - 1)
				.sort((a, b) => b[param].localeCompare(a[param]));
		}
		// Insert the last element at the end
		sortedArr.push(lastElement);
		return sortedArr;
	};

	// Function to sort the data alphabetically
	const sortByNameHandler = (sortParam) => {
		// sortParam value is to check whether to sort on experience center name of user name
		let param = "";
		if (sortParam == "Exp. Ctr Name") {
			param = "experienceCenterName";
		} else if (sortParam == "expCenterName") {
			param = "expCenterName";
		} else {
			param = "name";
		}

		// nameSortFlag here is to check if data is already sorted (A-Z or Z-A) to revert the sorting
		if (!nameSortFlag) {
			setDataArray(customSortOnName(dataArray, nameSortFlag, param));
			setNameSortFlag(true);
		} else {
			setDataArray(customSortOnName(dataArray, nameSortFlag, param));
			setNameSortFlag(false);
		}
	};

	useEffect(() => {
		setDataArray(DATA_ARRAY);
	}, [DATA_ARRAY]);

	return (
		<Accordion className={classes.accordion}>
			<AccordionSummary
				className="iconposition"
				expandIcon={<ExpandMoreIcon />}
				aria-controls="panel2a-content"
				id="panel2a-header"
			>
				<Typography className={classes.heading}>{props?.HEADING}</Typography>
			</AccordionSummary>

			{/* Showing Radio button & checkbox only for booking dashboard */}
			{props.STAGE == "Booking" && (
				<div style={{ marginLeft: 25 }}>
					<FormControl component="fieldset">
						<RadioGroup
							aria-label="type"
							name="type"
							value={radioForSalesWon}
							onChange={handleRadioChange}
						>
							<div className="modal-header-radio">
								<FormControlLabel
									value="All Bookings"
									control={<Radio />}
									label="All Bookings"
								/>
								<FormControlLabel
									value="Medium Risk"
									control={<Radio />}
									label="Medium Risk"
								/>
								<FormControlLabel
									value="Confirmed Bookings"
									control={<Radio />}
									label="Confirmed Bookings"
								/>
							</div>
						</RadioGroup>
						<FormControlLabel
							value={checked}
							control={<Checkbox checked={checked} />}
							onChange={handleChange}
							label="Lost"
							labelPlacement="end"
						/>
					</FormControl>
				</div>
			)}

			{/* Showing date for all dashboard */}
			<Grid
				container
				style={{
					display: "flex",
					alignItems: "center",
					width: "70vw",
					marginLeft: 25,
				}}
			>
				<Grid item md={3} xs={12}>
					<TextField
						id="date"
						name="startDate"
						label="Start Date"
						type="date"
						value={startDate}
						onChange={(e) => setStartDate(e.target.value)}
						InputLabelProps={{
							shrink: true,
						}}
						fullWidth
						className={classes.textField}
					/>
				</Grid>
				<Grid item md={1} xs={12}></Grid>
				<Grid item md={3} xs={12}>
					<TextField
						id="date"
						name="endDate"
						label="End Date"
						type="date"
						value={endDate}
						onChange={(e) => setEndDate(e.target.value)}
						InputLabelProps={{
							shrink: true,
						}}
						fullWidth
						className={classes.textField}
					/>
				</Grid>
				<Grid item md={3} xs={12}>
					<Button
						variant="contained"
						color="primary"
						disabled={!startDate || !endDate}
						onClick={handleDateFilter}
						style={{ marginLeft: 10 }}
					>
						Submit
					</Button>
				</Grid>
				<Grid item md={2} xs={12}>
					<Button
						variant="contained"
						color="secondary"
						onClick={clearFilterDate}
					>
						Clear Filter
					</Button>
				</Grid>
			</Grid>

			{/* Mapping data in table  */}
			<div className={classes.tablecontainer} style={{ marginLeft: 25 }}>
				<div className={classes.tableform}>
					<TableContainer
						component={Paper}
						className={classes.table}
						style={{ width: "100%" }}
					>
						<Table
							style={{ width: 450 }}
							className={classes.table}
							stickyHeader
							aria-label="sticky table"
						>
							<TableHead>
								<TableRow>
									<TableCell
										rowSpan={2}
										style={{ textAlign: "center", cursor: "pointer" }}
										onClick={() => sortByNameHandler(props.FIRST_COLUMN_NAME)}
									>
										{props?.FIRST_COLUMN_NAME}
										{nameSortFlag ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
										{/* <SwapVert /> */}
									</TableCell>

									{/* Showing exp center column for user & manager only along with their name */}
									{props.HEADING != "Experience Center" &&
										props.STAGE == "Booking" && (
											<TableCell
												rowSpan={2}
												style={{ textAlign: "center", cursor: "pointer" }}
												onClick={() => sortByNameHandler("expCenterName")}
											>
												Experience Center
												{nameSortFlag ? (
													<KeyboardArrowDown />
												) : (
													<KeyboardArrowUp />
												)}
												{/* <SwapVert /> */}
											</TableCell>
										)}

									{dataArray[0]?.monthlyGrandTotal?.map((el, i) => {
										return (
											<React.Fragment key={i}>
												<TableCell colSpan={2} style={{ textAlign: "center" }}>
													{el?.monthName} {el?.year}
												</TableCell>
											</React.Fragment>
										);
									})}
									<TableCell
										colSpan={2}
										style={{ fontWeight: "700", textAlign: "center" }}
									>
										Grand Total
									</TableCell>
								</TableRow>
								<TableRow>
									{dataArray[0]?.monthlyGrandTotal?.map((el, i) => {
										return (
											<React.Fragment key={i}>
												<TableCell style={{ textAlign: "center" }}>
													Count
												</TableCell>
												<TableCell style={{ textAlign: "center" }}>
													Value (₹)
												</TableCell>
											</React.Fragment>
										);
									})}
									<TableCell
										style={{
											fontWeight: "700",
											textAlign: "center",
											cursor: "pointer",
										}}
										onClick={() => sortByCountHandler("count")}
									>
										Count
										{ascSort ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
										{/* <SwapVert /> */}
									</TableCell>
									<TableCell
										style={{
											fontWeight: "700",
											textAlign: "center",
											cursor: "pointer",
										}}
										onClick={() => sortByCountHandler("grandTotal")}
									>
										Value (₹)
										{ascSort ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
										{/* <SwapVert /> */}
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{dataArray?.map((data, i) => {
									return (
										<TableRow key={i}>
											<TableCell
												component="th"
												style={{
													textAlign: "center",
													fontWeight: `${
														data?.name === "Grand Total" ? 700 : 0
													}`,
												}}
											>
												{data?.experienceCenterName || data?.name}
											</TableCell>

											{/* Showing exp center column for user & manager only along with their name */}
											{props.HEADING != "Experience Center" &&
												props.STAGE == "Booking" && (
													<TableCell
														component="th"
														style={{
															textAlign: "center",
															fontWeight: `${
																data?.name === "Grand Total" ? 700 : 0
															}`,
														}}
													>
														{data?.expCenterName}
													</TableCell>
												)}

											{/* Mapping the month-wise data */}
											{data?.monthlyGrandTotal?.map((el, j) => {
												return (
													<React.Fragment key={j}>
														<TableCell
															component="th"
															className={
																data?.name === "Grand Total"
																	? classes.grandTotalTableCell
																	: classes.clickableTableCell
															}
															onClick={() => {
																showDetailedModalHandlerOnClick(
																	data,
																	el.monthName,
																	el.year,
																	props.STAGE,
																	checked,
																	radioForSalesWon
																);
															}}
														>
															{el?.count}
														</TableCell>
														<TableCell
															component="th"
															className={
																data?.name === "Grand Total"
																	? classes.grandTotalTableCell
																	: classes.clickableTableCell
															}
															onClick={() => {
																showDetailedModalHandlerOnClick(
																	data,
																	el.monthName,
																	el.year,
																	props.STAGE,
																	checked,
																	radioForSalesWon
																);
															}}
														>
															{/* Display ₹ symbol only when it is grand total  */}
															{data.name === "Grand Total" ? "₹" : ""}

															{/* Dividing the value by 0.18 to exclude GST  */}
															{/* Displaying the value in rupees */}
															{(el?.totalGrandTotal / 0.18)?.toLocaleString(
																"en-IN",
																{
																	maximumFractionDigits: 2, // Maximum number of decimal places
																	// style: "currency",
																	// currency: "INR",
																}
															)}

															{/* Displaying the value in lacs */}
															{/* {formatNumberToLacs(
																el?.totalGrandTotal
															).toLocaleString("en-IN", {
																maximumFractionDigits: 2, // Maximum number of decimal places
																style: "currency",
																currency: "INR",
															})} */}
														</TableCell>
													</React.Fragment>
												);
											})}

											{/* Two cells for total-count and total-value in last column */}
											<TableCell
												component="th"
												className={classes.grandTotalTableCell}
												onClick={() => {
													showDetailedModalHandlerOnClick(
														data,

														// this is to send start-end month
														data?.monthlyGrandTotal[0].monthName +
															"-" +
															data?.monthlyGrandTotal[
																data?.monthlyGrandTotal.length - 1
															].monthName,

														// this is to send start-end year
														data?.monthlyGrandTotal[0].year +
															"-" +
															data?.monthlyGrandTotal[
																data?.monthlyGrandTotal.length - 1
															].year,

														props.STAGE,
														checked,
														radioForSalesWon
													);
												}}
											>
												{data?.count}
											</TableCell>
											<TableCell
												component="th"
												className={classes.grandTotalTableCell}
												onClick={() => {
													showDetailedModalHandlerOnClick(
														data,

														// this is to send start-end month
														data?.monthlyGrandTotal[0].monthName +
															"-" +
															data?.monthlyGrandTotal[
																data?.monthlyGrandTotal.length - 1
															].monthName,

														// this is to send start-end year
														data?.monthlyGrandTotal[0].year +
															"-" +
															data?.monthlyGrandTotal[
																data?.monthlyGrandTotal.length - 1
															].year,

														props.STAGE,
														checked,
														radioForSalesWon
													);
												}}
											>
												{/* Displaying the value in rupees */}
												{/* Dividing the value by 0.18 to exclude GST  */}
												{(data?.grandTotal / 0.18)?.toLocaleString("en-IN", {
													maximumFractionDigits: 2, // Maximum number of decimal places
													style: "currency",
													currency: "INR",
												})}

												{/* Displaying the value in lacs */}
												{/* {formatNumberToLacs(data?.grandTotal).toLocaleString(
													"en-IN",
													{
														maximumFractionDigits: 2, // Maximum number of decimal places
														style: "currency",
														currency: "INR",
													}
												)} */}
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					</TableContainer>
				</div>
			</div>
		</Accordion>
	);
};
export default AccordionComponent;
