import React, { useEffect, useState, useRef, useCallback } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams, Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import PropTypes from 'prop-types';
import moment from 'moment'
import { Modal } from 'antd';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import CardPineLabsPmtPrev from '../../assets/img/org/CardPineLabsPmtPrev.jpeg'
import BankTransfersVirtualAccountPmtPrev from '../../assets/img/org/BankTransfersVirtualAccountPmtPrev.jpeg'
import BankTransfersNonVirtualAccountPmtPrev from '../../assets/img/org/BankTransfersNonVirtualAccountPmtPrev.jpeg'
import UPIpmtPrev from '../../assets/img/org/UPIpmtPrev.jpeg'
import CashPmtPrev from '../../assets/img/org/CashPmtPrev.jpeg'
import ChequePmtPrev from '../../assets/img/org/ChequePmtPrev.jpeg'
import CreditFairPmtPrev from '../../assets/img/org/CreditFairPmtPrev.jpeg'
import OthersPmtPrev from '../../assets/img/org/OthersPmtPrev.jpeg'
// import 'antd/dist/antd.css';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import {
  Table, TableBody, TextField, TableCell, TableContainer, TableHead, TableRow, Paper, Button, FormControl, MenuItem, FormControlLabel, Switch, Checkbox,
  InputLabel, Select, DialogContentText, DialogContent, Divider, DialogTitle, Dialog, DialogActions, Radio
} from '@material-ui/core';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import RadioGroup from '@material-ui/core/RadioGroup';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
// import Typography from '@material-ui/core/Typography';
// import Box from '@material-ui/core/Box';
import { Typography, CircularProgress, Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import Autocomplete from '@material-ui/lab/Autocomplete';

import { FileExcelOutlined, DownloadOutlined } from '@ant-design/icons';

import { constantUnitTypeMaster, constantPlyTypeMaster, constantDepartmentId, ROLENAME, rftUnitTypeId } from '../../utils/index';
import HttpService from '../../services/httpService'
import { setLoading, setSnackInfo } from '../../redux/actions/appAction'
import { commaSeparateAmount } from '../../components/commaSeparate';
import { FaArrowRight } from "react-icons/fa";
import decorpotLogo from '../../assets/img/org/logo-black-min.png'
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  pos:{
      display: "flex",
      flexDirection: 'row',
  },
  checkoutbutton : {
    textAlign : "center",
    margin : 'auto'
  },
  table: {
    minWidth: 850,
  },
  initiatePaymentButton : { backgroundColor: 'white', color: '#1a542e', padding: '1', marginRight: '0.5em', marginLeft: '0.5em', borderRadius: '20px', "&:hover": { backgroundColor: "#1a542e", color: 'white' } },
  recordPaymentButton : { backgroundColor: 'white', color: '#14419c', padding: '1', marginRight: '0.5em', marginLeft: '0.5em', borderRadius: '20px', "&:hover": { backgroundColor: "#14419c", color: 'white' } },

  yesButton: { backgroundColor: 'white', color: '#14419c', padding: '1', marginRight: '0.5em', marginLeft: '0.5em', borderRadius: '20px', "&:hover": { backgroundColor: "#14419c", color: 'white' } },
  noButton: { backgroundColor: 'white', color: '#151921', padding: '1', marginRight: '0.5em', borderRadius: '20px', "&:hover": { backgroundColor: "#151921", color: 'white' } },
  previewButton: { backgroundColor: 'white', color: '#14419c', padding: '1', marginRight: '0.5em', marginLeft: '0.5em', borderRadius: '20px', "&:hover": { backgroundColor: "#14419c", color: 'white' }, width: '40%' },
  
  
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#ccc",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
    padding: "6px 3px 6px 3px",
    textAlign: 'center'
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
//
const ManageQuotation = (props) => {
  useEffect(() => {
    getAllQuotationsForProject()
    // eslint-disable-next-line
  }, [])

  const buttonRef = useRef(null);

  const classes = useStyles();
  const { leadId } = useParams();
  const { userDetails } = useSelector(state => state.user)
  const [value, setValue] = useState(null);
  const [totalPaymentPrice, setTotalPaymentPrice] = useState(0);
  const [totalDescPerAmt, setTotalDescPerAmt] = useState(0);
  const [totalNonDescPerAmt, setTotalNonDescPerAmt] = useState(0);
  const [discount, setChangeDiscount] = useState(0);
  const [discountMaster, setDiscountMaster] = useState(0);
  const [discountItemSubTotal, setDiscountItemSubTotal] = useState(0)
  const [chosenCategory, setChosenCategory] = useState("")
  const [chosenCategoryId, setChosenCategoryId] = useState("")
  const [eligibleDiscountScopes, setEligibleDiscountScopes] = useState('');
  const [eligibleNonDiscountScopes, setEligibleNonDiscountScopes] = useState('');
  const [selectedQuotationVersion, setSelectedQuotationVersion] = useState('')
  const [selectedCatergory, setSelectedCatergory] = useState({})
  const [projectDetails, setProjectDetails] = useState({})
  const [laminateObj, setLaminateObj] = useState({})
  const [projectComponentProducts, setProjectComponentProducts] = useState({})
  const [defaultPlyType, setDefaultPlyType] = useState({});
  const [paymentTotalInfo, setPaymentTotalInfo] = useState({});
  const [prevQtTotalAmount, setPrevQtTotalAmount] = useState(0);
  const [diffQtChanges, setDiffQtChanges]  = useState(0); 
  const [qtPreviewOnlyNewData, setqtPreviewOnlyNewData] = useState();
  const [projectDetailsFields, setProjectDetailsFields] = useState({
    name: "",
    email: "",
    contact_no: "",
    projectType: "",
    propertyType: "",
    scopeOfWork: "",
    description: "",
    floorPlan: "",
    isRegistered: "",
    area: "",
    leadType: "",
    discountOnAllScopes: "",
    assignTo: "",
    assignToManager: ""
  })
  const [isFileUploaded, setIsFileUploaded] = useState(false)
  const [startLoading, setStartLoading] = useState(false);
  
  const [paymentInitiated, setPaymentInitiated] = useState(false);

  const [defaultUnitType, setDefaultUnitType] = useState([])
  const [projectComponents, setProjectComponents] = useState([])
  const [quotationList, setQuotationList] = useState([])
  const [csvData, setCsvData] = useState([]);
  const [header, setHeaders] = useState([])
  const [categoryList, setCategoryList] = useState([])
  const [productsList, setProductsList] = useState([])
  const [finshesList, setFinishesList] = useState([])
  const [selectedFinish, setSelectedFinish] = useState([])
  const [plyList, setPlyList] = useState([])
  const [selectedRoomPly, setSelectedRoomPly] = useState([])
  const [qtPreview, setQtPreview] = useState([]);
  const [newItemAddedArray, setNewItemAddedArray] = useState([]);
  const [discountScopes, setDiscountScopes] = useState([]);
  const [nonDiscountScopes, setNonDiscountScopes] = useState([]);
  const [categoryspecificTotal, setCategoryspecificTotal] = useState([]);
  const [disCountEligibleItemsPriceAmount, setDisCountEligibleItemsPriceAmount] = useState([]);
  const [disCountNonEligibleItemsPriceAmount, setDisCountNonEligibleItemsPriceAmount] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState([]);

  const [showCreateQuotationModal, setShowCreateQuotationModal] = useState(false)
  const [leadInOutOfDesignStage, setLeadInOutOfDesignStage] = useState(false)
  const [serviceItemFlag, setServiceItemFlag] = useState(false)
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [editProjectModal, setEditProjectModal] = useState(false)
  const [openSelectQuotationVersion, setOpenSelectQuotationVersion] = useState(false);
  const [openPaymentCheckoutModal,setOpenPaymentCheckoutModal] = useState(false)
  const [paymentActionChosen, setPaymentActionChosen] = useState('Record');
  // const [process,setProcess] = useState("")
  const [pineLabsPos, setPineLabsPos] = useState([]);
  const [selectedPosMachine, setSelectedPosMachine] = useState([])
  
  const [otherPaymentMethod,setOtherPaymentMethod] = useState({slip_no:"",trxId:"",cheQueNo:"", other_txn_type: "",attachment : null})
  // const [checkoutPopUp,setCheckoutPopUp] = useState(false)
  const [process,setProcess] = useState("")
  const [erpProjectNo,setErpProjectNo] = useState("")

 const [storeTenPercentAmount, setStoreTenPercentAmount] = useState({ amount: 0 });
 const [completedPayment,setCompletedPayment] = useState(false);
 const [plutusTransactionReferenceID, setPlutusTransactionReferenceID] = useState(0);
const [paymentModes,setpaymentModes] = useState([]);
const [selectedPaymentMode, setSelectedPaymentMode] = useState([]);
const [selectedNovVirtualBank, setSelectedNovVirtualBank] = useState([])
const [sequenceNumber, setSequenceNumber] = useState(0)
const [checkTotoalQuotationValue,setTotalQuotationValue] = useState(0)

const [hidePaymentModeInput, setHidePaymentModeInput] = useState(false)
const [nonVirtualBanks, setNonVirtualBanks] = useState([{name: 'ICICI'}, {name: 'BOI'}, {name: 'HDFC'}, {name: 'SBI'}])

const [complimentaryItemPresent, setComplimentaryItemPresent ] = useState(false);
const [complimentaryItemRevoked, setComplimentaryItemRevoked ] = useState(false);
const [complimentarySpaceChange, setComplimentarySpaceChange ] = useState(false);
const [sameItemQty, setSameItemQty] = useState(false);
const [newComplimentaryItem, setNewComplimentaryItem ] = useState(false);
const [rejectedItem, setRejectedItem] = useState(false);
const [rejectedItemDR, setRejectedItemDR] = useState(false);
const [hasMismatch, setHasMismatch] = useState(false);
const [uploadData, setUploadData] = useState([]);
const [editQt, setEditQt] = useState(0);
const [sendApprovalQtCrValue, setSendApprovalQtCrValue ] = useState({
  valuesGreaterThan20k : false,
  valuesLessThan20k : false,
  allPositiveValues : false,
})
const [paymentReceipt, setPaymentReceipt] = useState("");
const [paymentReceiptArray, setPaymentReceiptArray] = useState([]);
// const [paymentReceipt, setPaymentReceipt] = useState([]);
const [addPaymentReceiptFileUploaded, setAddPaymentReceiptFileUploaded] = useState(false);
const [paymentReceiptModal, setPaymentReceiptModal] = useState(false);
const [displayUploadReceiptMsg, setDisplayUploadReceiptMsg]  = useState(true);

const [finalizeButtonVisibility , setFinalizeButtonVisibility] = useState(false);
const [quotationTotalAmount, SetQuotationTotalAmount] = useState(0);
const [uploadPaymentButtonVisibility, SetUploadPaymentButtonVisibility] = useState(false);
const [sendForApprovalDisable, setSendForApprovalDisable] = useState(false);
const [addDataState,setAddDataState] = useState([])
const [newChangesTotalProductsPrice, setNewChangesTotalProductsPrice] = useState({
  totalQuotationValue : 0,
  discount : 0,
  subTotal : 0,
  gst18PerAmount : 0,
  total : 0 
});

const [balanceSheetData, setBalanceSheetData] = useState({
  reviseQuotationValue : 0,
  discount:0,
  subTotal:0,
  GST: 0,
  total:0,
  pendingDue : 0,
  prevQuotationValue : 0,
})
const [damageDelayOtherValue , setDamageDelayOtherValue]  = useState({
  delayPenalityCheck: false,
  damagesCheck : false,
  goodWillDiscountCheck : false,
  otherCheck : false,
  referralBonusCheck : false,
  delayPenality: 0,
  damages : 0,
  goodWillDiscount : 0,
  other : 0,
  referralBonus : 0
})
const [delayToReferralCategoryTotal, setDelayToReferralCategoryTotal] = useState(0);
const [totalValueBeforeAddCategory, setTotalValueBeforeAddCategory] = useState(0)
const [addRemoveTotalValue, setAddRemoveTotalValue] = useState(0);
const [balansheSheetQtTotalPrice,setBalansheSheetQtTotalPrice] = useState(0);
const [amountPaid , setAmountPaid] = useState(0);

let currentPlutusTransactionReferenceID = 0
let allConditionsMet = true;
  const [compareMode, setCompareMode] = useState(false);
  const [selectedQuotations, setSelectedQuotations] = useState([]);
  const [comparisionPreview, setComparisionPreview] = useState(false);
  const [comapredData, setComparedData] = useState([])
  const [pdfLink, setPdfLink] = useState("")
  const [currentPage, setCurrentPage] = useState('view');

function transform(value) {
  if (value === undefined) return ' ';
  let val = String(value);
  let finalval = '';
  let j = 0;
  let flag = 0;
  let isNegative = false;

  // Check if the value is negative
  if (val[0] === '-') {
    isNegative = true;
    val = val.slice(1);
  }

  for (let i = val.length - 1; i > -1; i--) {
    finalval = val[i] + finalval;
    j++;

    if (val[i] === '.') {
      j = 0;
    }

    if (j === 3 && i !== 0) {
      finalval = ',' + finalval;
      j = 0;
      flag = 1;
    }

    if (flag === 1 && i !== 0 && j === 2) {
      finalval = ',' + finalval;
      j = 0;
    }
  }
  return isNegative ? '-' + finalval : finalval;
}

  const handleViewPage = () => {
    setCurrentPage('view');
  };

  const handleSummaryPage = () => {
    setCurrentPage('summary');
  };

  const handleCompareModeChange = (event) => {
    setCompareMode(event.target.checked);
  };

  const handleQuotationSelection = (e, data) => {
    if (selectedQuotations.includes(data)) {
      setSelectedQuotations(selectedQuotations.filter(item => item !== data));
    } else {
      if (selectedQuotations.length === 2) {
        setSelectedQuotations(selectedQuotations.slice(1).concat(data));
      } else {
        setSelectedQuotations([...selectedQuotations, data]);
      }
    }
  };
  
for (let key in storeTenPercentAmount) {
    if (
        (key === 'amount' && storeTenPercentAmount[key] <= 0) ||
        (key === 'leadno' && storeTenPercentAmount[key] === "") ||
        (key === 'posmachine' && storeTenPercentAmount[key] === "")
    ) {
        allConditionsMet = false;
        break; // No need to continue checking other keys if one condition is not met
    }
}

const handleDelete = (index) => {
  const filteredArray = paymentReceiptArray.filter((item,idx) => idx !== index);
  setPaymentReceiptArray(filteredArray);
  if(filteredArray.length <= 0 ){
    setAddPaymentReceiptFileUploaded(false);
    setPaymentReceiptArray([]);
    props.setSnackInfo("Please add payment receipt","warning");
  }
};

// console.log(otherPaymentMethod,"this is called")
  const headers = [
    { key: "categoryname", label: "Category" },
    { key: "code", label: "Product Code" },
    { key: "productname", label: "Name" },
    { key: "description", label: "Description" },
    { key: "plyType", label: "Core Material" },
    { key: "finishType", label: 'Finish' },
    { key: "area", label: 'Area/Qty(in Sq Ft)' },
    { key: "Qty", label: 'Qty' },
    { key: "length", label: 'Length' },
    { key: "width", label: 'Width' },
    { key: "unittype", label: 'Unit' },
    { key: "cost", label: 'Cost(Per Sq Feet/Qty)' },
    { key: "itemTotal", label: 'Item total' },
    // When scope is enable that time remove the comment from next line
    { key: "scope", label: 'Scope' },
    { key: "emptyColumn1", label: '' },
    { key: "emptyColumn2", label: '' },
    { key: "quotationValue", label: 'TOTAL QUOTATION VALUE' },
    { key: "discountPercent", label: 'Discount(%)' },
    { key: "discount", label: 'Discount' },
    { key: "subTotal", label: 'Sub total' },
    { key: "gst", label: 'GST' },
    { key: "miscellaneous", label: 'Miscellaneous amount' },
    { key: "total", label: 'TOTAL' },
    { key: "customerTotalOutflow", label: 'TOTAL CUSTOMER OUTFLOW' }
  ];
  
  const getAllQuotationsForProject = async () => {
    props.setLoading(true)
    try {
      const response = await HttpService.getAllQuotationsForProject(leadId)
      // console.log('getAllQuotationsForProject data', response.data)
      setQuotationList(response.data)
      setErpProjectNo(response.data[0]?.leadId?.erpProjectNo)
      getProjectDetails()
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  const getProjectDetails = async () => {
    props.setLoading(true)
    try {
      const response = await HttpService.getProjectLeadDetails(leadId)
      setProjectDetails(response.data[0])
      getFinishMaster();
      if (response.data[0].departmentId._id === constantDepartmentId.factory || response.data[0].departmentId._id === constantDepartmentId.imos || response.data[0].departmentId._id === constantDepartmentId.operation || response.data[0].departmentId._id === constantDepartmentId.chm) {
        setLeadInOutOfDesignStage(true);
      }
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props.setLoading(false)

  }
  
  const getFinishMaster = async () => {
    props.setLoading(true)
    try {
      const response = await HttpService.getFinish()
      for (let i = 0; i < response.data.length; i++) {
        if (response.data[i]['name'] === "Laminate") {
          
          setLaminateObj({
            pricePerUnit: response.data[i]['pricePerUnit'],
            _id: response.data[i]['_id'],
            name: "Laminate"
          })
          break;
        }
      }
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  // const handle_non_virtual_bank_selection = (e) =>
  // {
  //   console.log('handle_non_virtual_bank_selection function called')
  //   const {name, value} = e.target
  //   console.log('name', name)
  //   console.log('value', value)
  //   selectedNovVirtualBank([value]);
  // }

  

  const handle_internal_payment_methods = (e) =>
  {
    setOtherPaymentMethod({ ...otherPaymentMethod, [e.target.name]: e.target.value });
  };
  
  const handle_external_payment_methods = (e) =>
  {
    setSelectedPosMachine(e.target.value)
  };

  const onImageChange = async (e) => {
    props.setLoading(true);
    const fd = new FormData();
    fd.append('file', e.target.files[0]);
    
    try {
      const fileUploadRes = await HttpService.uploadFile(fd);
      setOtherPaymentMethod({
        ...otherPaymentMethod,
        attachment: fileUploadRes.data, // Store the file information in the state
      });
      setIsFileUploaded(true)
    } catch (err) {
      props.setSnackInfo(err?.response?.data ?? "Something went wrong", "error");
    }
    setPaymentReceiptModal(false)
    props.setLoading(false);
  };
  
  
    // console.log("handlePayment others ", otherPaymentMethod)

  const generateQuotationExcel = async (quotationId) => {
    props.setLoading(true)
    try {
      const response = await HttpService.generateQuotationExcel(quotationId)

      
      let results = response?.data?.components;
      var data = [];

      for (let i = 0; i < results.length; i++) {
        for (let j = 0; j < results[i]['products'].length; j++) {
          let obj = {};
          obj['categoryname'] = results[i]['categoryId']?.['name'];
          obj['code'] = results[i]['products'][j]['product']?.['code'];
          obj['productname'] = results[i]['products'][j]['product']?.['name'];
          obj['description'] = results[i]['products'][j]['product']?.['description'];
          if (results[i]['products'][j]['subUnitType'] === 'Area') {
            obj['plyType'] = results[i]['plyType']?.['name'];
            obj['finishType'] = results[i]['products'][j]['finishType']?.['name'];
            obj['Qty'] = String(1);
          } else {
            obj['plyType'] = 'NA';
            obj['finishType'] = 'NA'
            obj['Qty'] = results[i]['products'][j]['area']
          }
    

          if (results[i]['products'][j]['subUnitType'] === 'Qty') {
            obj['area'] = String(0);
          } else {
            if (results[i]['products'][j]['productCustomArea'] !== 0) {
              obj['area'] = results[i]['products'][j]['productCustomArea']
            } else {
              obj['area'] = results[i]['products'][j]['area']
            }
          }


          obj['length'] = results[i]['products'][j]['length'];
          obj['width'] = results[i]['products'][j]['width'];
          obj['unittype'] = results[i]['products'][j]['unitType'] !== null ? results[i]['products'][j]['unitType']?.['name'] : 'null';
          obj['cost'] = results[i]['products'][j]['productCustomPrice'] === 0 ? results[i]['products'][j]['totalPricePerUnit'] : results[i]['products'][j]['productCustomPrice'];
          obj['itemTotal'] = results[i]['products'][j]['productPrice'];
          // When scope is enable that time remove the comment from next line
          obj['scope'] = results[i]['products'][j]['product']['scopeId']['name'];

          if (i === 0 && j === 0) {
            if (projectDetails.discountOnAllScopes === true) {
              obj['quotationValue'] = response?.data?.['totalAmount'];
              obj['discountPercent'] = response?.data?.['discountPercent'];
              obj['discount'] = response?.data?.['discountAmount'] ? response?.data?.['discountAmount'] : 0;
              obj['subTotal'] = response?.data?.['tempSt'] ? response?.data?.['tempSt'] : 0;
              //obj['customerToProc'] = response?.data?.['customerToBeProcured'] ? response?.data?.['customerToBeProcured'] : 0;
              //obj['customerToProcPercent'] = response?.data?.['materialProcured'] ? response?.data?.['materialProcured'] : 0;
              //obj['decorpotScope'] = response?.data?.['decorpotScope'] ? response?.data?.['decorpotScope'] : 0;
              obj['gst'] = response?.data?.['gstValue'] ? response?.data?.['gstValue'] : 0;
              obj['miscellaneous'] = response?.data?.['miscTotal'] ? response?.data?.['miscTotal'] : 0;
              obj['total'] = response?.data?.['mainTotal'] ? response?.data?.['mainTotal'] : 0;
              obj['customerTotalOutflow'] = response?.data?.['totalCustomerOutflow'] ? response?.data?.['totalCustomerOutflow'] : 0;
            } else {
              obj['discountPercent'] = response?.data?.['discountPercent'];
              obj['discount'] = response?.data?.['discountAmount'] ? response?.data?.['discountAmount'] : 0;
              if (response?.data?.['discItemsSubTotal' && response?.data?.['totalNonDiscPrAmnt']]) {
                obj['quotationValue'] = response?.data?.['totalDiscPrAmnt'] + response?.data?.['totalNonDiscPrAmnt'];
                obj['subTotal'] = response?.data?.['discItemsSubTotal'] ? response?.data?.['discItemsSubTotal'] + response?.data?.['totalNonDiscPrAmnt'] : 0 + response?.data?.['totalNonDiscPrAmnt'];
              } else {
                obj['quotationValue'] = response?.data?.['totalAmount'];
                obj['subTotal'] = response?.data?.['tempSt'] ? response?.data?.['tempSt'] : 0;
              }

              obj['gst'] = response?.data?.['gstValue'] ? response?.data?.['gstValue'] : 0;
              obj['miscellaneous'] = response?.data?.['miscTotal'] ? response?.data?.['miscTotal'] : 0;
              obj['total'] = response?.data?.['mainTotal'] ? response?.data?.['mainTotal'] : 0;
              obj['customerTotalOutflow'] = response?.data?.['totalCustomerOutflow'] ? response?.data?.['totalCustomerOutflow'] : 0;
            }

          }
          data.push(obj);
        }
      }

      if (response.data.hasOwnProperty('miscellaneousComponent')) {
        response.data['miscellaneousComponent']['products'].forEach((misc) => {
          let obj = {}
          obj['categoryname'] = response.data['miscellaneousComponent']['projectComponent']['name'];
          obj['code'] = misc['product']['code'];
          obj['productname'] = misc['product']['name'];
          obj['description'] = misc['product']['description'];
          if (misc['subUnitType'] === 'Area') {
            obj['plyType'] = response.data['miscellaneousComponent']['name'];
            obj['finishType'] = misc['finishType']['name'];
            obj['Qty'] = String(1);
          } else {
            obj['plyType'] = 'NA';
            obj['finishType'] = 'NA'
            obj['Qty'] = misc['area']
          }
          if (misc['subUnitType'] === 'Qty') {
            obj['area'] = String(0);
          } else {
            if (misc['productCustomArea'] !== 0) {
              obj['area'] = misc['productCustomArea']
            } else {
              obj['area'] = misc['area']
            }
          }


          obj['length'] = misc['length'];
          obj['width'] = misc['width'];
          obj['unittype'] = misc['unitType']['name'];
          obj['cost'] = misc['productPricePerUnit'];
          obj['itemTotal'] = misc['productPrice'];
          obj['scope'] = misc['product']['scopeId']['name'];
          data.push(obj);
        })
      }

      return data;
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  const generateQtExcel = async (qtId) => {
    const exdata = await generateQuotationExcel(qtId);
    setCsvData(exdata)
    try {
      setTimeout(() => {
        buttonRef.current.link.click();
      });
    } catch (err) {
      console.error(err.message);
    }
    props.setLoading(false)
  }

  const getPostDesignSignOffObj1 = async ()=>{
    props.setLoading(true)
    try{
      const responseObj = await HttpService.getPostDesignSignOffObj();
      const postDesignCategoryObj = responseObj.data;
      return postDesignCategoryObj;
    }
    catch (err){
      console.error(err);
    }
    props.setLoading(false)
  }

  const openCreateQuotationModal = async (e,changeReq) => {
    let servicesObj;
    let newCategoryObj;
    if(changeReq === 'Raise Change Request')
    {
      await changeEditQuotation(projectDetails);
      newCategoryObj = await getPostDesignSignOffObj1();
    //  let newCategoryObj = { "products": [], "deleted": false, "_id": "661777ffb2de5c06725a646d", "name": "POST DESIGN SIGN-OFF CHANGES", "priority": 1, "description": "Post Design Sign-off Changes", "updatedAt": "2024-04-12T06:02:38.426Z", "createdAt": "2024-04-11T05:41:19.787Z", "__v": 0 }
    }
    else{
      servicesObj = { "products": [], "deleted": false, "_id": "62aaf790d2813657af6e2843", "name": "SERVICES", "priority": 1, "description": "SERVICES", "updatedAt": "2022-06-16T09:45:22.987Z", "createdAt": "2022-06-16T09:27:44.701Z", "__v": 0 }
    }
    getAllRooms()
    getProjectComponent()
    getAllProductsFromRateCard()
    getDiscountMaster();
    setShowCreateQuotationModal(true)
    let unitData = [];
    unitData.push({
      name: 'Sqft',
      _id: constantUnitTypeMaster.Sqft
    },
      {
        name: 'mm',
        _id: constantUnitTypeMaster.mm
      });
    setUnitList(unitData)
    handleCategorySelect(servicesObj)
    if(changeReq === 'Raise Change Request' || changeReq === 'Go To Finalize'){
      handleCategorySelect(newCategoryObj)
    }

    if (projectDetails && projectDetails?.delayToReferralBonusCategory) {
      const {
        delayPenalityCheck,
        damagesCheck,
        goodWillDiscountCheck,
        otherCheck,
        referralBonusCheck,
        delayPenality,
        damages,
        goodWillDiscount,
        other,
        referralBonus
      } = projectDetails.delayToReferralBonusCategory;

      setDamageDelayOtherValue({
        delayPenalityCheck: delayPenalityCheck || false,
        damagesCheck: damagesCheck || false,
        goodWillDiscountCheck: goodWillDiscountCheck || false,
        otherCheck: otherCheck || false,
        referralBonusCheck: referralBonusCheck || false,
        delayPenality: delayPenality || 0,
        damages: damages || 0,
        goodWillDiscount: goodWillDiscount || 0,
        other: other || 0,
        referralBonus: referralBonus || 0
      });
    }
  }

  const getAllRooms = async () => {
    props.setLoading(true)
    try {
      const result = await HttpService.getAllRooms()
      setCategoryList(result.data)
    } catch (error) {
      console.error(error)
      props.setSnackInfo('Error fetching Category', "error")
    }
    props.setLoading(false)
  }
  
  const getProjectComponent = async (categoryName) => {
    props.setLoading(true)
    try {
      const response = await HttpService.getProjectComponent(leadId)
      if (categoryName?.name === 'SERVICES' || categoryName?.name === 'Services') {
        createServiceProjectComp(response.data)
        setProjectComponents(response.data)
      } else {
        let sortedData = response.data.sort((a, b) => a.priority - b.priority)
        setProjectComponents(sortedData)
      }
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", 'error')
    }
    props.setLoading(false)
  }

  const getAllProductsFromRateCard = async () => {
    props.setLoading(true)
    try {
      const response = await HttpService.getAllProductsFromRateCard(leadId)
      setProductsList(response.data.filter(product => product?.docType === "ProductMaster" && product.itemId !== undefined && product.itemId != null))
      setFinishesList(response.data.filter(product => product?.docType === "FinishTypeMaster").map(finish => {
        return {
          name: finish.itemId.name,
          pricePerUnit: finish.rate,
          _id: finish.itemId._id
        }
      }))
      setPlyList(response.data.filter(product => product?.docType === "PlyTypeMaster").map(ply => {
        return {
          name: ply.itemId.name,
          pricePerUnit: ply.rate,
          _id: ply.itemId._id
        }
      }))
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  const getDiscountMaster = async () => {
    const discountResponse = await HttpService.getDiscountMasterData();
    if (discountResponse.data) {
      setDiscountMaster(discountResponse?.data[0]?.value);
    }
  }

  const getRefreshedProjectComponent = async () => {
    props.setLoading(true)
    try {
      const response = await HttpService.getProjectComponent(leadId)
      let sortedData = response.data.sort((a, b) => a.priority - b.priority)
      setProjectComponents(sortedData)
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", 'error')
    }
    props.setLoading(false)
  }

  const handleCategorySelect = async (v) => {
    let prior;
    if (!projectComponents || projectComponents.length === 0) {
      prior = 1;
    } else {
      prior = projectComponents.length + 1;
    }
    props.setLoading(true)
    try {
      setSelectedCatergory(v)
      if (v?._id) {
        await HttpService.addProjectComponent({ projectComponent: v?._id, leadId: leadId, products: [], priority: prior })
        getProjectComponent(v)
      }
    } catch (error) {
      console.log(error, "Component Exist Message");
      // props.setSnackInfo(error?.response?.data ?? "Something went wrong", 'error')
    }
    props.setLoading(false)
  }

  const getDefaultServiceMaterials = async (data, productData, prodCategory) => {
    props.setLoading(true)
    try {
      const response = await HttpService.getServicesComponent();
      response.data.product.forEach(async (ele, i) => {
        let res1 = ele;
        res1.length = 0;
        res1.width = 0;
        res1.area = 1;
        res1.isDelete = false;
        res1.isServiceItem = true;
        handleServiceProductSelect(res1, data, productData, prodCategory);
      })
      // saveQuotationChanges();
    } catch (error) {
      console.error(error)
    }
    props.setLoading(false)
  }

  const createServiceProjectComp = (compData) => {
    setValue(compData.length - 1);
    let defaultPly = {
      createdAt: "2018-10-26T12:00:03.638Z",
      defaultPly: true,
      defaultPlyFor: [],
      deleted: false,
      description: "Plywood",
      isActive: true,
      name: "Plywood",
      pricePerUnit: 0,
      updatedAt: "2020-11-11T10:08:50.018Z",
      __v: 0,
      _id: constantPlyTypeMaster.plywood,
    }
    setDefaultPlyType(defaultPly)

    let defaultUnitType = {}
    if (userDetails.departmentId === constantDepartmentId.sales) {
      defaultUnitType['name'] = 'Sqft';
      defaultUnitType['_id'] = constantUnitTypeMaster.Sqft;
    } else {
      defaultUnitType['name'] = 'mm';
      defaultUnitType['_id'] = constantUnitTypeMaster.mm;
    }
    setDefaultUnitType(defaultUnitType)
    let category = compData.filter((comp, idx) => comp.categoryId?.name === "SERVICES")[0]?.categoryId?.name
    setChosenCategory(category)
    let categoryId = compData.filter((comp, idx) => comp.categoryId?.name === "SERVICES")[0]?._id
    setChosenCategoryId(categoryId)
    let projectProducts = { ...projectComponentProducts }
    if (!projectProducts[category]) {
      projectProducts[category] = {}
      projectProducts[category].plyType = {}
      projectProducts[category].plyType = compData[compData.length - 1].plyType ? compData[compData.length - 1].plyType : defaultPly

      projectProducts[category].unitSelect = {}
      projectProducts[category].unitSelect = compData[compData.length - 1].unitSelect ? compData[compData.length - 1].unitSelect : defaultUnitType

      projectProducts[category]['item'] = []
      projectProducts[category]['item'] = compData[compData.length - 1].products
      setProjectComponentProducts(projectProducts)
      let finish = [{ ...selectedFinish }]
      compData[compData.length - 1].products.map((product, i) => {
        finish[i] = product.finishType
        return null
      })
      setSelectedFinish(finish)
      setServiceItemFlag(true)
      getDefaultServiceMaterials(compData, projectProducts, category)
    }
  }

  const handleChange = (e, v) => {
    setValue(v);
    let defaultPly = {
      createdAt: "2018-10-26T12:00:03.638Z",
      defaultPly: true,
      defaultPlyFor: [],
      deleted: false,
      description: "Plywood",
      isActive: true,
      name: "Plywood",
      pricePerUnit: 0,
      updatedAt: "2020-11-11T10:08:50.018Z",
      __v: 0,
      _id: constantPlyTypeMaster.plywood,
    }
    setDefaultPlyType(defaultPly)
    let defaultUnitType = {}
    if (userDetails.departmentId === constantDepartmentId.sales) {
      defaultUnitType['name'] = 'Sqft';
      defaultUnitType['_id'] = constantUnitTypeMaster.Sqft;
    } else {
      defaultUnitType['name'] = 'mm';
      defaultUnitType['_id'] = constantUnitTypeMaster.mm;
    }
    setDefaultUnitType(defaultUnitType)

    let category = projectComponents.filter((comp, idx) => idx === v)[0]?.categoryId?.name
    setChosenCategory(category)
    let categoryId = projectComponents.filter((comp, idx) => idx === v)[0]?._id
    setChosenCategoryId(categoryId)
    let projectProducts = { ...projectComponentProducts }
    if (!projectProducts[category]) {
      projectProducts[category] = {}
      projectProducts[category].plyType = {}
      projectProducts[category].plyType = projectComponents[v].plyType ? projectComponents[v].plyType : defaultPly

      projectProducts[category].unitSelect = {}
      projectProducts[category].unitSelect = projectComponents[v].unitSelect ? projectComponents[v].unitSelect : defaultUnitType

      projectProducts[category]['item'] = []
      projectProducts[category]['item'] = projectComponents[v].products
      let finish = [{ ...selectedFinish }]
      projectComponents[v].products.map((product, i) => {
        finish[i] = product.finishType
        return null
      })
      setSelectedFinish(finish)
      setProjectComponentProducts(projectProducts)
    }
  };

  const handleServiceProductSelect = (v, dataCategory, productCategoryData, leadCategory) => {
    if (v?._id) {
      let projectProducts = { ...productCategoryData }
      if (projectProducts[leadCategory]['item'].length === 0)
        projectProducts[leadCategory]['item'] = []

      let obj = {
        isDelete: v?.isDelete === undefined ? true : false,
        priority: v?.priority,
        finishType: {
          _id: laminateObj._id,
          name: laminateObj.name,
          pricePerUnit: laminateObj.pricePerUnit
        },
        product: {
          isActive: true,
          name: v?.name,
          finishInclude: v?.finishInclude,
          _id: v?._id,
          plyInclude: v?.plyInclude,
          pricePerUnit: v?.pricePerUnit,
          scope: v?.scopeId?._id,
          projectRCMasterId: v?.projectRCMasterId,
          unitType: v?.unitId?._id,
          subUnitType: v?.subUnitType,
          priority: v?.itemId?.priority
        },
        productPricePerUnit: v?.pricePerUnit,
        totalPricePerUnit: v?.pricePerUnit,
        subUnitType: v?.subUnitType,
        productCustomPrice: 0,
        productCustomArea: 0,
        customPrice: false,
        unitType: constantUnitTypeMaster.mm,
        width: v.width,
        length: v.length,
        area: v.area,
        changeArea: 0
      }
      
      if (obj['subUnitType'] === 'Area') {
        if (obj['product']['finishInclude']) {
          obj['totalPricePerUnit'] += obj['finishType']['pricePerUnit'];
        }
        if (obj['product']['plyInclude']) {
          obj['totalPricePerUnit'] += projectProducts[leadCategory]?.['plyType']?.['pricePerUnit'];
        }
      }
      obj['productPrice'] = obj['area'] * obj['totalPricePerUnit'];
      obj['changePrice'] = obj['productPrice'];
      obj['changeArea'] = obj['area'];
      // ends here
      if (obj['product']['name'] === 'Stone Cladding') {
        obj['fixingPrice'] = v?.fixingPrice;
        obj['productPrice'] = obj['totalPricePerUnit'] + (obj['fixingPrice'] * 100);
      }
      projectProducts[leadCategory]['item'].push(obj)
      setProjectComponentProducts(projectProducts)
    }
  }

  const handleDoubleClick = async (idx) => {
    if (idx > 0) {
      let obj = [
        {
          component_id: projectComponents[idx]['_id'],
          priority: projectComponents[idx - 1]['priority']
        },
        {
          component_id: projectComponents[idx - 1]['_id'],
          priority: projectComponents[idx]['priority']
        },
      ]
      props.setLoading(true)
      const updateCategoryPosition = await HttpService.editCategoryPositionPriority(obj);
      if (updateCategoryPosition.status === 200) {
        props.setSnackInfo('Category position updated', "success")
        getRefreshedProjectComponent()
      } else {
        props.setSnackInfo('Something went wrong', "error")
      }
      props.setLoading(false)
    }
  }

  const handleProductSelect = (v) => {
    // console.log('v',v)
    if (v?._id) {
      let projectProducts = { ...projectComponentProducts }
      if (projectProducts[chosenCategory]['item'].length === 0)
        projectProducts[chosenCategory]['item'] = []

      let calculatedWidth = 300, calculatedArea = 1, fixingCharge = 0, depth = 300, removeCustomAreaCheck = false
      
      if(falseCeilingValidate(v?.itemId?.name))
      {
        calculatedWidth = 300
        calculatedArea = 18
        if(v?.itemId?.unitId == rftUnitTypeId || v?.itemId?.name.toLowerCase().includes('rafter'))
        {
          if(projectComponentProducts?.[chosenCategory]?.unitSelect?.name == "mm")
          {
            calculatedArea = Number(((300 * 3) / 300).toFixed(2))
          }
          else
          {
            calculatedArea = 300 * 3
          }

        }
        else if(v?.itemId?.name.includes('False ceiling (Peripheral with Cove'))
        {
          
          removeCustomAreaCheck = true
          if(projectComponentProducts?.[chosenCategory]?.unitSelect?.name == "mm")
          {
            calculatedArea = Number(((((calculatedWidth + 300) * (depth + 900)) * 2) / 90000).toFixed(2))
          }
          else
          {
            calculatedArea = ((calculatedWidth + 300) * (depth + 3)) * 2
          }
        }
        else if(v?.itemId?.name.includes('False ceiling (Peripheral, No Cove'))
        {
          
          removeCustomAreaCheck = true
          if(projectComponentProducts?.[chosenCategory]?.unitSelect?.name == "mm")
          {
            calculatedArea = Number(((((calculatedWidth + 300) * (depth + 300)) * 2) / 90000).toFixed(2))
          }
          else
          {
            calculatedArea = ((calculatedWidth + 300) * (depth + 1)) * 2
          }
        }
        else if(v?.itemId?.name.includes('False ceiling (Island with Cove'))
        {
    
          if(projectComponentProducts?.[chosenCategory]?.unitSelect?.name == "mm")
          {
            
            calculatedArea = Number((((calculatedWidth * 300) + ((calculatedWidth + 300) * 1800)) / 90000).toFixed(2))
          }
          else
          {
            
            calculatedArea = (calculatedWidth * 300) + ((calculatedWidth + 300) * 6)
          }
        }
        else if(v?.itemId?.name.includes('False ceiling (Island, No Cove'))
        {
          
          if(projectComponentProducts?.[chosenCategory]?.unitSelect?.name == "mm")
          {
            calculatedArea = Number((((calculatedWidth * 300) + ((calculatedWidth + 300) * 600)) / 90000).toFixed(2))
          }
          else
          {
            calculatedArea = (calculatedWidth * 300) + ((calculatedWidth + 300) * 2)
          }
        }
        else if (v?.itemId?.name.includes("False ceiling in Plywood")) {
          if (projectComponentProducts?.[chosenCategory]?.unitSelect?.name == "mm") {
            calculatedArea = 1 ;
          }
          else {
            calculatedArea = Number(calculatedWidth * 300).toFixed(2);
            // calculatedArea = Number(((300 * 3))).toFixed(2)
          }
        }
      }
      else if(v?.itemId?.name == "Granite - Material + Fixing (Min order quantity 20 sft)")
      {
        if(projectComponentProducts?.[chosenCategory]?.unitSelect?.name == "mm")
        {
          calculatedWidth = 750
          calculatedArea = 2.5
        }
        else
        {
          calculatedWidth = 2.5
          calculatedArea = 750
        }
      }
      else if(v?.itemId?.name == "Granite for Island Counter - Material  + Fixing (Min order quantity 20 sft)")
      {
        if(projectComponentProducts?.[chosenCategory]?.unitSelect.name == "mm")
        {
          calculatedArea = Number((((300 + 300) * (300 + 300)) / 90000).toFixed(2))
        }
        else
        {
          calculatedArea = Number((300 + 1) * (300 + 1))
        }
      }
      else if(v?.itemId?.name.includes("TIles fixing charges for tiles"))
      {
        calculatedArea = 0
      }
      else if(v?.itemId?.subUnitType === 'Area')
      {
        calculatedWidth = 300
        calculatedArea = 1
      }
      
      let obj = {
        prevQtData : true,
        newItemAdded: true,
        isDelete: v?.itemId?.isDelete === undefined ? true : false,
        priority: v?.itemId?.priority,
        finishType: {
          _id: laminateObj._id,
          name: laminateObj.name,
          pricePerUnit: laminateObj.pricePerUnit
        },
        product: {
          isActive: true,
          name: v?.itemId?.name,
          finishInclude: v?.itemId?.finishInclude,
          _id: v?.itemId?._id,
          plyInclude: v?.itemId?.plyInclude,
          scope: v?.itemId?.scopeId,
          projectRCMasterId: v?.projectRCMasterId,
          unitType: v?.itemId?.unitId,
          subUnitType: v?.itemId?.subUnitType,
          priority: v?.itemId?.priority
        },
        productPricePerUnit: v?.rate,
        totalPricePerUnit: v?.rate,
        subUnitType: v?.itemId?.subUnitType,
        productCustomPrice: 0,
        productCustomArea: 0,
        customPrice: false,
        unitType: constantUnitTypeMaster.mm, // mm unit id
        moqInSqft: v?.itemId?.moqInSqft,
        fixingCharge: fixingCharge,
        depth: depth,
        width: calculatedWidth,
        length: falseCeilingValidate(v?.itemId?.name) || v?.itemId?.name == "Granite - Material + Fixing (Min order quantity 20 sft)" ? 300 : v?.itemId?.subUnitType === 'Area' ? 300 : 0,
        area: calculatedArea,
        changeArea: removeCustomAreaCheck ? false : falseCeilingValidate(v?.itemId?.name) ? 18 : v?.itemId?.subUnitType === 'Area' && 0,
      }
      
      if (falseCeilingValidate(obj['product'].name)) {
        obj['customArea'] = false;
      }

      if (projectProducts[chosenCategory]?.unitSelect?.name === 'Sqft' && obj['subUnitType'] === 'Area') {
        obj['unitType'] = constantUnitTypeMaster.Sqft; // SqFt unit id
        if (falseCeilingValidate(obj['product'].name)) {
          if(!v?.itemId?.name.includes('False ceiling (Peripheral with Cove') && !v?.itemId?.name.includes('False ceiling (Peripheral, No Cove') && !v?.itemId?.name.includes('False ceiling (Island with Cove') && !v?.itemId?.name.includes('False ceiling (Island, No Cove') && !v?.itemId?.name.includes('False ceiling in Plywood') && !(v?.itemId?.unitId == rftUnitTypeId))
          {
            obj['area'] = 5400;
          }
        }
        else if(v?.itemId?.name == "Granite - Material + Fixing (Min order quantity 20 sft)")
        {
          if(projectComponentProducts?.[chosenCategory]?.unitSelect?.name == "mm")
          {
            obj['width'] = 750
            obj['length'] = 300
            obj['area'] = 2.5
          }
          else
          {
            obj['width'] = 2.5
            obj['length'] = 300
            obj['area'] = 750
          }
        }
        else if(v?.itemId?.name == "Granite for Island Counter - Material  + Fixing (Min order quantity 20 sft)")
        {
          if(projectComponentProducts?.[chosenCategory]?.unitSelect.name == "mm")
          {
            obj['area'] = Number((((300 + 300) * (300 + 300)) / 90000).toFixed(2))
          }
          else
          {
            obj['area'] = Number((300 + 1) * (300 + 1))
          }
        }
        else if(v?.itemId?.name.includes("TIles fixing charges for tiles"))
        {
        }
        else
        {
          obj['area'] = 90000;
        }
      }
      // Add ply price and finish price if the product is eligible for those
      if (obj['subUnitType'] === 'Area') {
        if (obj['product']['finishInclude']) {  
          obj['totalPricePerUnit'] += obj['finishType']['pricePerUnit'];
        }
        if (obj['product']['plyInclude']) {
          obj['totalPricePerUnit'] += projectProducts[chosenCategory]?.['plyType']?.['pricePerUnit'];
        }
      }
      obj['productPrice'] = obj['area'] * obj['totalPricePerUnit'];
      obj['changePrice'] = obj['productPrice'];
      obj['changeArea'] = obj['area'];
      // ends here
      if (obj['product']['name'] === 'Stone Cladding') {
        obj['fixingPrice'] = v?.fixingPrice;
        obj['productPrice'] = obj['totalPricePerUnit'] + (obj['fixingPrice'] * 100);
      }
      setSelectedFinish(obj.finishType)
      projectProducts[chosenCategory]['item'].push(obj)
      projectProducts[chosenCategory]['item'].sort((a, b) => a['priority'] - b['priority']);
      setProjectComponentProducts(projectProducts)
    }
  }

  const handleProductSelect1 = async(v)=> {
    let projectProducts = { ...projectComponentProducts }
    v.forEach((vItem) => {
      // Create a new object for each item in the array 'v'
      let obj = {
        categoryQtData: true,
        isDelete: false,
        priority: 1,
        finishType: {
          _id: laminateObj._id,
          name: "Laminate",
          pricePerUnit: 0
        },
        product: {
          isActive: true,
          name: vItem?.itemId?.name,
          finishInclude: vItem?.itemId?.finishInclude,
          _id: vItem?.itemId?._id,
          plyInclude: vItem?.itemId?.plyInclude,
          scope: vItem?.itemId?.scopeId,
          projectRCMasterId: vItem?.projectRCMasterId,
          unitType: vItem?.itemId?.unitId,
          subUnitType: vItem?.itemId?.subUnitType,
          priority: vItem?.itemId?.priority
        },
        productPricePerUnit: 0,
        productPrice: vItem?.rate,
        changePrice: vItem?.rate,
        totalPricePerUnit: vItem?.rate,
        subUnitType: vItem?.itemId?.subUnitType,
        productCustomPrice: 0,
        productCustomArea: 0,
        customPrice: false,
        unitType: constantUnitTypeMaster.mm, // mm unit id
        moqInSqft: vItem?.itemId?.moqInSqft,
        fixingCharge: 0,
        depth: 0,
        width: 0,
        length: 0,
        area: 0,
        changeArea: 0
      };
      // Push the new object into the 'item' array of the chosen category
      setSelectedFinish(obj.finishType)
      projectProducts[chosenCategory]['item'].push(obj);
    });
    projectProducts[chosenCategory]['item'].sort((a, b) => a['priority'] - b['priority']);
    setProjectComponentProducts(projectProducts)
  }

  const handlePlySelect = (v) => {
    let projectProducts = { ...projectComponentProducts }
    projectProducts[chosenCategory]['plyType'] = v === null ? defaultPlyType : v;
    projectProducts[chosenCategory]['item'].forEach(element => {
      if (!element['product']?.['plyInclude']) {
        return;
      }
      if (element['subUnitType'] === 'Area') {
        let plyPrice = v === null ? defaultPlyType.pricePerUnit : v?.pricePerUnit;
        element['totalPricePerUnit'] = element['productPricePerUnit'] + element['finishType']?.['pricePerUnit'] + plyPrice
        element['productPrice'] = element['totalPricePerUnit'] * element['area'];
        element['changePrice'] = element['productPrice'];
      }
    });
    setProjectComponentProducts(projectProducts)
  }

  const handleFinishSelect = (v, i) => {
    let projectProducts = { ...projectComponentProducts }
    let itemObj = projectProducts[chosenCategory]['item'][i];
    finshesList.forEach((ele, i) => {
      if (v?.name === ele.name) {
        setSelectedFinish({
          pricePerUnit: ele['pricePerUnit'],
          _id: ele['_id'],
          name: ele['name']
        })
        itemObj['finishType']['name'] = ele['name'];
        itemObj['finishType']['pricePerUnit'] = ele['pricePerUnit'];
        itemObj['finishType']['_id'] = ele['_id'];
        itemObj['totalPricePerUnit'] = itemObj['productPricePerUnit'] + itemObj['finishType']['pricePerUnit'];
        if (itemObj['product']?.['plyInclude'] && itemObj['subUnitType'] === 'Area') {
          itemObj['totalPricePerUnit'] = itemObj['totalPricePerUnit'] + projectProducts[chosenCategory]['plyType']['pricePerUnit']
        }
        itemObj['productPrice'] = itemObj['totalPricePerUnit'] * itemObj['area'];
        itemObj['changePrice'] = itemObj['productPrice'];
      }
    })

    setProjectComponentProducts(projectProducts)
  }

  const removeItemFromList = (i) => {
    let projectProducts = { ...projectComponentProducts }
    let chosenCategoryProducts = JSON.parse(JSON.stringify(projectProducts[chosenCategory]))
    delete projectProducts[chosenCategory]
    setProjectComponentProducts({
      ...projectComponentProducts,
      [chosenCategory]: {
        item: chosenCategoryProducts.item?.filter((item, idx) => i !== idx),
        plyType: projectComponentProducts[chosenCategory].plyType,
        unitSelect: projectComponentProducts[chosenCategory].unitSelect
      }
    })
  }

  const handleAreaQtyCheck = (e, i, optionSelected) =>
  {
    let projectProducts = { ...projectComponentProducts }
    let value = Number(e.target.value)

    if(projectComponentProducts?.[chosenCategory]?.unitSelect?.name == 'mm')
    {
      // if(value < 300)
      // {
      //   props.setSnackInfo(`${projectProducts[chosenCategory]['item'][i]['product']['name']} ${e.target.name} could not be less than 300`, 'error')
        let newEvent = {...e}
        newEvent.target.value = value;
        handleAreaQtyChange(newEvent, i, optionSelected)
      // }
      // handleAreaQtyChange(value, i, optionSelected)
    }
    else if(projectComponentProducts?.[chosenCategory]?.unitSelect?.name == 'Sqft')
    {
      // if(value < 1)
      // {
      //   props.setSnackInfo(`${projectProducts[chosenCategory]['item'][i]['product']['name']} ${e.target.name} could not be less than 1`, 'error')
        let newEvent = {...e}
        newEvent.target.value = value
        handleAreaQtyChange(newEvent, i, optionSelected)
      // }
      // handleAreaQtyChange(value, i, optionSelected)
    }
  }
  const handleAreaQtyChange = (e, i, optionSelected) => {
    let projectProducts = { ...projectComponentProducts }
    projectProducts[chosenCategory]['item'][i][e.target.name] = e.target.value
    switch (optionSelected) {
      case 'l':
        
        if (falseCeilingValidate(projectProducts[chosenCategory]['item'][i]['product']['name']))
        {
          if(projectProducts[chosenCategory]['item'][i]['product']['unitType'] == rftUnitTypeId || projectProducts[chosenCategory]['item'][i]['product']['name'].toLowerCase().includes('rafter'))
          {
            if (projectProducts[chosenCategory].unitSelect.name === 'mm')
              {
                projectProducts[chosenCategory]['item'][i]['area'] = Number(((Number(projectProducts[chosenCategory]['item'][i]['length']) * 3) / 300).toFixed(2))
              }
              else
              {
                projectProducts[chosenCategory]['item'][i]['area'] = (Number(projectProducts[chosenCategory]['item'][i]['length']) * 3)
              }
              projectProducts[chosenCategory]['item'][i]['productPrice'] = projectProducts[chosenCategory]['item'][i]['totalPricePerUnit'] * projectProducts[chosenCategory]['item'][i]['area'];
              projectProducts[chosenCategory]['item'][i]['changePrice'] = projectProducts[chosenCategory]['item'][i]['productPrice'];
              projectProducts[chosenCategory]['item'][i]['changeArea'] = projectProducts[chosenCategory]['item'][i]['area'];
          }
          else if(projectProducts[chosenCategory]['item'][i]['product']['name'].includes('False ceiling (Peripheral with Cove'))
          {
            projectProducts[chosenCategory]['item'][i]['depth'] = Number(projectProducts[chosenCategory]['item'][i]['depth'])
            if (projectProducts[chosenCategory].unitSelect.name === 'mm')
            {
              projectProducts[chosenCategory]['item'][i]['area'] = Number(((((Number(projectProducts[chosenCategory]['item'][i]['length']) + Number(projectProducts[chosenCategory]['item'][i]['width'])) * (Number(projectProducts[chosenCategory]['item'][i]['depth']) + 900)) * 2) / 90000).toFixed(2))
            }
            else
            {
              projectProducts[chosenCategory]['item'][i]['area'] = ((Number(projectProducts[chosenCategory]['item'][i]['length']) + Number(projectProducts[chosenCategory]['item'][i]['width'])) * (Number(projectProducts[chosenCategory]['item'][i]['depth']) + 3)) * 2
            }
            projectProducts[chosenCategory]['item'][i]['productPrice'] = projectProducts[chosenCategory]['item'][i]['totalPricePerUnit'] * projectProducts[chosenCategory]['item'][i]['area'];
            projectProducts[chosenCategory]['item'][i]['changePrice'] = projectProducts[chosenCategory]['item'][i]['productPrice'];
            projectProducts[chosenCategory]['item'][i]['changeArea'] = projectProducts[chosenCategory]['item'][i]['area'];
      
          }
          else if(projectProducts[chosenCategory]['item'][i]['product']['name'].includes('False ceiling (Peripheral, No Cove'))
          {
            projectProducts[chosenCategory]['item'][i]['depth'] = Number(projectProducts[chosenCategory]['item'][i]['depth'])
            if (projectProducts[chosenCategory].unitSelect.name === 'mm')
            {
              projectProducts[chosenCategory]['item'][i]['area'] = Number(((((Number(projectProducts[chosenCategory]['item'][i]['length']) + Number(projectProducts[chosenCategory]['item'][i]['width'])) * (Number(projectProducts[chosenCategory]['item'][i]['depth']) + 300)) * 2) / 90000).toFixed(2))
            }
            else
            {
              projectProducts[chosenCategory]['item'][i]['area'] = ((Number(projectProducts[chosenCategory]['item'][i]['length']) + Number(projectProducts[chosenCategory]['item'][i]['width'])) * (Number(projectProducts[chosenCategory]['item'][i]['depth']) + 1)) * 2
            }
            projectProducts[chosenCategory]['item'][i]['productPrice'] = projectProducts[chosenCategory]['item'][i]['totalPricePerUnit'] * projectProducts[chosenCategory]['item'][i]['area'];
            projectProducts[chosenCategory]['item'][i]['changePrice'] = projectProducts[chosenCategory]['item'][i]['productPrice'];
            projectProducts[chosenCategory]['item'][i]['changeArea'] = projectProducts[chosenCategory]['item'][i]['area'];
      
          }
          else if(projectProducts[chosenCategory]['item'][i]['product']['name'].includes('False ceiling (Island with Cove'))
          {
            if (projectProducts[chosenCategory].unitSelect.name === 'mm')
            {
              
              projectProducts[chosenCategory]['item'][i]['area'] = Number((((Number(projectProducts[chosenCategory]['item'][i]['length']) * Number(projectProducts[chosenCategory]['item'][i]['width'])) + ((Number(projectProducts[chosenCategory]['item'][i]['length']) + Number(projectProducts[chosenCategory]['item'][i]['width'])) * 1800)) / 90000).toFixed(2))
            }
            else
            {
              
              projectProducts[chosenCategory]['item'][i]['area'] = (Number(projectProducts[chosenCategory]['item'][i]['length']) * Number(projectProducts[chosenCategory]['item'][i]['width'])) + ((Number(projectProducts[chosenCategory]['item'][i]['length']) + Number(projectProducts[chosenCategory]['item'][i]['width'])) * 6)
            }
            projectProducts[chosenCategory]['item'][i]['productPrice'] = projectProducts[chosenCategory]['item'][i]['totalPricePerUnit'] * projectProducts[chosenCategory]['item'][i]['area'];
            projectProducts[chosenCategory]['item'][i]['changePrice'] = projectProducts[chosenCategory]['item'][i]['productPrice'];
            projectProducts[chosenCategory]['item'][i]['changeArea'] = projectProducts[chosenCategory]['item'][i]['area'];
      
          }
          else if(projectProducts[chosenCategory]['item'][i]['product']['name'].includes('False ceiling (Island, No Cove'))
          {
            if (projectProducts[chosenCategory].unitSelect.name === 'mm')
            {
              
              projectProducts[chosenCategory]['item'][i]['area'] = Number((((Number(projectProducts[chosenCategory]['item'][i]['length']) * Number(projectProducts[chosenCategory]['item'][i]['width'])) + ((Number(projectProducts[chosenCategory]['item'][i]['length']) + Number(projectProducts[chosenCategory]['item'][i]['width'])) * 600)) / 90000).toFixed(2))
            }
            else
            {
              projectProducts[chosenCategory]['item'][i]['area'] = (Number(projectProducts[chosenCategory]['item'][i]['length']) * Number(projectProducts[chosenCategory]['item'][i]['width'])) + ((Number(projectProducts[chosenCategory]['item'][i]['length']) + Number(projectProducts[chosenCategory]['item'][i]['width'])) * 2)
            }
            projectProducts[chosenCategory]['item'][i]['productPrice'] = projectProducts[chosenCategory]['item'][i]['totalPricePerUnit'] * projectProducts[chosenCategory]['item'][i]['area'];
            projectProducts[chosenCategory]['item'][i]['changePrice'] = projectProducts[chosenCategory]['item'][i]['productPrice'];
            projectProducts[chosenCategory]['item'][i]['changeArea'] = projectProducts[chosenCategory]['item'][i]['area'];
          }
          else if(projectProducts[chosenCategory]['item'][i]['product']['name'].includes('False ceiling in Plywood'))
          {
            projectProducts[chosenCategory]['item'][i]['area'] = (Number(projectProducts[chosenCategory]['item'][i]['width']) * Number(projectProducts[chosenCategory]['item'][i]['length']));
            if (projectProducts[chosenCategory].unitSelect.name === 'mm') {
              projectProducts[chosenCategory]['item'][i]['area'] = (Number(projectProducts[chosenCategory]['item'][i]['width']) * Number(projectProducts[chosenCategory]['item'][i]['length'])) / 300;
              projectProducts[chosenCategory]['item'][i]['area'] = projectProducts[chosenCategory]['item'][i]['area'] / 300;
              projectProducts[chosenCategory]['item'][i]['area'] = projectProducts[chosenCategory]['item'][i]['area'].toFixed(2);
            }
            projectProducts[chosenCategory]['item'][i]['changeArea'] = projectProducts[chosenCategory]['item'][i]['area'];
            projectProducts[chosenCategory]['item'][i]['productPrice'] = projectProducts[chosenCategory]['item'][i]['area'] * projectProducts[chosenCategory]['item'][i]['totalPricePerUnit'];
            projectProducts[chosenCategory]['item'][i]['productPrice'] = projectProducts[chosenCategory]['item'][i]['productPrice'].toFixed(2);
            projectProducts[chosenCategory]['item'][i]['changePrice'] = projectProducts[chosenCategory]['item'][i]['productPrice'];
          }
          else
          {
            projectProducts[chosenCategory]['item'][i]['area'] = (Number(projectProducts[chosenCategory]['item'][i]['width']) + Number(projectProducts[chosenCategory]['item'][i]['length'])) * 9;
            if (projectProducts[chosenCategory].unitSelect.name === 'mm') {
              projectProducts[chosenCategory]['item'][i]['area'] = projectProducts[chosenCategory]['item'][i]['area'] / 300;
              projectProducts[chosenCategory]['item'][i]['area'] = projectProducts[chosenCategory]['item'][i]['area'].toFixed(2);
            }
            projectProducts[chosenCategory]['item'][i]['changeArea'] = projectProducts[chosenCategory]['item'][i]['area'];

            projectProducts[chosenCategory]['item'][i]['productPrice'] = projectProducts[chosenCategory]['item'][i]['area'] * projectProducts[chosenCategory]['item'][i]['totalPricePerUnit'];
            projectProducts[chosenCategory]['item'][i]['productPrice'] = projectProducts[chosenCategory]['item'][i]['productPrice'].toFixed(2);
            projectProducts[chosenCategory]['item'][i]['changePrice'] = projectProducts[chosenCategory]['item'][i]['productPrice'];
          }
        }
        else if(projectProducts[chosenCategory]['item'][i]['product']['name'] === 'Granite - Material + Fixing (Min order quantity 20 sft)')
        {
          if (projectProducts[chosenCategory].unitSelect.name === 'mm')
          {
            projectProducts[chosenCategory]['item'][i]['area'] =  Number(((Number(projectProducts[chosenCategory]['item'][i]['width']) * Number(projectProducts[chosenCategory]['item'][i]['length'])) / 90000).toFixed(2))
          }
          else
          {
            projectProducts[chosenCategory]['item'][i]['area'] =  Number((Number(projectProducts[chosenCategory]['item'][i]['width']) * Number(projectProducts[chosenCategory]['item'][i]['length'])).toFixed(2))
          }
          projectProducts[chosenCategory]['item'][i]['productPrice'] = Number((Number(projectProducts[chosenCategory]['item'][i]['area']) * Number(projectProducts[chosenCategory]['item'][i]['totalPricePerUnit'])).toFixed(2));
          projectProducts[chosenCategory]['item'][i]['changePrice'] = projectProducts[chosenCategory]['item'][i]['productPrice'];
          projectProducts[chosenCategory]['item'][i]['changeArea'] = projectProducts[chosenCategory]['item'][i]['area'];
        }
        else if(projectProducts[chosenCategory]['item'][i]['product']['name'] === 'Granite for Island Counter - Material  + Fixing (Min order quantity 20 sft)')
        {
          if (projectProducts[chosenCategory].unitSelect.name === 'mm')
          {
            projectProducts[chosenCategory]['item'][i]['area'] =  Number((((Number(projectProducts[chosenCategory]['item'][i]['width']) + 300) * (Number(projectProducts[chosenCategory]['item'][i]['length']) + 300)) / 90000).toFixed(2))
          }
          else
          {
            projectProducts[chosenCategory]['item'][i]['area'] =  Number(((Number(projectProducts[chosenCategory]['item'][i]['width']) + 1) * (Number(projectProducts[chosenCategory]['item'][i]['length']) + 1)).toFixed(2))
          }
          projectProducts[chosenCategory]['item'][i]['productPrice'] = Number((Number(projectProducts[chosenCategory]['item'][i]['area']) * Number(projectProducts[chosenCategory]['item'][i]['totalPricePerUnit'])).toFixed(2));
          projectProducts[chosenCategory]['item'][i]['changePrice'] = projectProducts[chosenCategory]['item'][i]['productPrice'];
          projectProducts[chosenCategory]['item'][i]['changeArea'] = projectProducts[chosenCategory]['item'][i]['area'];
        }
        else if(projectProducts[chosenCategory]['item'][i]['product']['name'].includes('TIles fixing charges for tiles'))
        {
          projectProducts[chosenCategory]['item'][i]['fixingCharge'] = Number(projectProducts[chosenCategory]['item'][i]['fixingCharge'])
          projectProducts[chosenCategory]['item'][i]['area'] = Number((Number(projectProducts[chosenCategory]['item'][i]['fixingCharge']) * 1.2).toFixed(2))
    
          projectProducts[chosenCategory]['item'][i]['productPrice'] = Number((Number(projectProducts[chosenCategory]['item'][i]['area']) * Number(projectProducts[chosenCategory]['item'][i]['totalPricePerUnit'])).toFixed(2));
          projectProducts[chosenCategory]['item'][i]['changePrice'] = projectProducts[chosenCategory]['item'][i]['productPrice'];
          projectProducts[chosenCategory]['item'][i]['changeArea'] = projectProducts[chosenCategory]['item'][i]['area'];
        }
        else if (projectProducts[chosenCategory]['item'][i]['product']['name'] === 'Stone Cladding')
        {
          projectProducts[chosenCategory]['item'][i]['area'] = Number(projectProducts[chosenCategory]['item'][i]['width']) * Number(projectProducts[chosenCategory]['item'][i]['length']);
          if (projectProducts[chosenCategory].unitSelect.name === 'mm') {
            projectProducts[chosenCategory]['item'][i]['area'] = projectProducts[chosenCategory]['item'][i]['area'] / 90000;
            projectProducts[chosenCategory]['item'][i]['area'] = Number(projectProducts[chosenCategory]['item'][i]['area'].toFixed(2));
          }
          projectProducts[chosenCategory]['item'][i]['changeArea'] = projectProducts[chosenCategory]['item'][i]['area'];
          projectProducts[chosenCategory]['item'][i]['productPrice'] = projectProducts[chosenCategory]['item'][i]['totalPricePerUnit'] * projectProducts[chosenCategory]['item'][i]['area'];
          if (projectProducts[chosenCategory]['item'][i]['area'] > 100) {
            projectProducts[chosenCategory]['item'][i]['productPrice'] += (projectProducts[chosenCategory]['item'][i]['fixingPrice'] * projectProducts[chosenCategory]['item'][i]['area']);
          } else {
            projectProducts[chosenCategory]['item'][i]['productPrice'] += (projectProducts[chosenCategory]['item'][i]['fixingPrice'] * 100);
          }
        }
        else
        {
          projectProducts[chosenCategory]['item'][i]['area'] = Number((projectProducts[chosenCategory]['item'][i]['width']) * Number(projectProducts[chosenCategory]['item'][i]['length'])).toFixed(2);
          if (projectProducts[chosenCategory].unitSelect.name === 'mm') {
            projectProducts[chosenCategory]['item'][i]['area'] = Number(projectProducts[chosenCategory]['item'][i]['area']) / 90000;
            projectProducts[chosenCategory]['item'][i]['area'] = Number(projectProducts[chosenCategory]['item'][i]['area']).toFixed(2);
          }
          projectProducts[chosenCategory]['item'][i]['changeArea'] = projectProducts[chosenCategory]['item'][i]['area'];
          projectProducts[chosenCategory]['item'][i]['productPrice'] =
            Number((projectProducts[chosenCategory]['item'][i]['totalPricePerUnit']
            ) * Number((projectProducts[chosenCategory]['item'][i]['area'])).toFixed(2));
          projectProducts[chosenCategory]['item'][i]['productPrice'] = Number(projectProducts[chosenCategory]['item'][i]['productPrice']).toFixed(2);
          projectProducts[chosenCategory]['item'][i]['changePrice'] = projectProducts[chosenCategory]['item'][i]['productPrice'];
        }
        break;

      case 'a':
        projectProducts[chosenCategory]['item'][i]['productPrice'] = projectProducts[chosenCategory]['item'][i]['productPricePerUnit'] * projectProducts[chosenCategory]['item'][i]['area'];
        projectProducts[chosenCategory]['item'][i]['changePrice'] = projectProducts[chosenCategory]['item'][i]['productPrice'];
        projectProducts[chosenCategory]['item'][i]['changeArea'] = projectProducts[chosenCategory]['item'][i]['area'];
        break;

      case 'r':
        projectProducts[chosenCategory]['item'][i]['productPrice'] = projectProducts[chosenCategory]['item'][i]['productPricePerUnit'] * projectProducts[chosenCategory]['item'][i]['productCustomArea'];
        projectProducts[chosenCategory]['item'][i]['changePrice'] = projectProducts[chosenCategory]['item'][i]['productPrice'];
        break;

      default:
        console.log('default');
    }
    // projectProducts[chosenCategory]['item'][i][e.target.name] = e.target.value
    // if (e.target.name !== "area") {
    //   console.log((projectProducts[chosenCategory]['item'][i].length ?? 1) * (projectProducts[chosenCategory]['item'][i].width ?? 1))
    //   projectProducts[chosenCategory]['item'][i]['area'] = (projectProducts[chosenCategory]['item'][i].length ?? 1) * (projectProducts[chosenCategory]['item'][i].width ?? 1)
    // }
    // projectProducts[chosenCategory]['item'][i]['productPrice'] = projectProducts[chosenCategory]['item'][i].productPricePerUnit * projectProducts[chosenCategory]['item'][i]['area']
    // projectProducts[chosenCategory]['item'][i]['productPrice'] = Number((projectProducts[chosenCategory]['item'][i]['productPrice']).toFixed(2))
    setProjectComponentProducts(projectProducts)
  }

  const handleChangePriority = (e, i) => {
    let projectProducts = { ...projectComponentProducts }
    projectProducts[chosenCategory]['item'][i][e.target.name] = e.target.value
    setProjectComponentProducts(projectProducts)
  }

  const sortPriorityData = (e) => {
    if (e.key === "Enter") {
      let projectProducts = { ...projectComponentProducts }
      projectProducts[chosenCategory]['item'].sort((a, b) => a['priority'] - b['priority']);
      setProjectComponentProducts(projectProducts)
    }
  }

  const sortPriorityOnOnBlur = (e) => {
    let projectProducts = { ...projectComponentProducts }
    projectProducts[chosenCategory]['item'].sort((a, b) => a['priority'] - b['priority']);
    setProjectComponentProducts(projectProducts)
  };

  const saveQuotationChanges = async () => {
    props.setLoading(true)
    let obj = [];
    let validateFlag = validateArea(projectComponentProducts[chosenCategory]['item']);
    if (validateFlag > 0) {
      props.setSnackInfo("Please check MOQ", 'error');
      return; 
    }
    Object.keys(projectComponentProducts).forEach(ele => {
      let tmp = projectComponentProducts[ele];
      projectComponents.forEach(e => {
        if (e.categoryId.name === ele) {
          obj.push({
            categoryId: e.categoryId._id,
            plyType: tmp.plyType,
            leadId: leadId,
            products: tmp.item
          })
        }
      })
    })
    
    let array = [];
    // Check conditions before proceeding with calculations
    if (
      projectDetails.contractCustomerApproved &&
      projectDetails.contractFinanceApproved &&
      projectDetails.designStages === 'Design Sign-off'
    ) {
      // Iterate over obj array (make sure obj is defined and accessible)
      for (let i = 0; i < obj?.length; i++) {
        for (let j = 0; j < obj[i]?.products?.length; j++) {
          if (obj[i]?.products[j]?.prevQtData) {
            if (obj[i]?.products[j]?.productPrice >= 0) {
              obj[i].products[j].negativeQtCr = false;
              obj[i].products[j].positiveQtCr = true;
            } else {
              obj[i].products[j].negativeQtCr = true;
              obj[i].products[j].positiveQtCr = false;
            }
            array.push(+obj[i].products[j]?.productPrice);
          }
        }
      }
      // Perform calculations based on conditions
      const hasNegativeValue = array.some(value => value <= 0);
      if (hasNegativeValue) {
        const sum = parseFloat(array.reduce((accumulator, currentValue) => accumulator + currentValue, 0));
        if (sum <= -20000) {
          setSendApprovalQtCrValue({ ...sendApprovalQtCrValue, valuesGreaterThan20k: true });
          setDiffQtChanges(sum)
        } else {
          setSendApprovalQtCrValue({ ...sendApprovalQtCrValue, valuesLessThan20k: true });
        }
      } else {
        const sum = array.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        setSendApprovalQtCrValue({ ...sendApprovalQtCrValue, allPositiveValues: true });
      }
    }
    const response = await HttpService.editProjectComponentQuotation(obj);

    if (response.status === 200) {
        props.setSnackInfo('Quotation Updated', "success")
        getProjectComponent()
    } else {
      props.setSnackInfo('Something went wrong', "error")
    }
    props.setLoading(false)
  }

  // area calculation here allow -ve value also
  const validateArea = (productArray) => {
    let validateFlag = 0;
    productArray.forEach(ele => {
      // if (ele['subUnitType'] === 'Qty') return;
      if(ele['area'] < ele['moqInSqft'])
      {
        validateFlag = ele['area'];
        // alert(`Area for ${ele['product']['name']} cannot be less than ${ele['moqInSqft']}`)
      }
    });
    props.setLoading(false)
    return validateFlag;
  }

  let discountValue, discItemsSubTotal, material, scopePercentage, subTotal, decorpotScope, taxableAmount;
  let ctpAmount, total, totalPayable, customerSavings, savingPercen, materia, discountPercent;
  let totalDiscPrAmnt = 0;
  let priceAmount = 0;
  let totalNonDiscPrAmnt = 0;

  const previewQuotation = async () => {
    await saveQuotationChanges();
    // Close the Quotation List modal
    setShowCreateQuotationModal(false);
    // Open Preview quotation modal
    setOpenPreviewModal(true);
    // Getting the list of Preview Items
    if (projectDetails.complimentaryItemStatus === "Sent for Approval") {
      props.setSnackInfo("Please wait till the complimentary items are approved", "warning")
    }
    const response = await HttpService.getProjectComponent(leadId)
    let data = response.data.shift();
    let sortedData = response.data.sort((a, b) => a.priority - b.priority)
    sortedData.push(data)
    setProjectComponents(sortedData)

    let obj = [];
    sortedData.forEach(ele => {
      obj.push({
        product: ele.products,
        spacename: ele.categoryId.name,
      })
    })

   // Calculate positive negative quotation total product price using reduce fucntion
    // const calculateTotalProductPrice = (objArray) => {
    //   return objArray.reduce((totalPrice, obj) => {
    //       if (obj.spacename === "POST DESIGN SIGN-OFF CHANGES") {
    //           return obj.product.reduce((subtotal, productItem) => subtotal + productItem.productPrice, totalPrice);
    //       }
    //       return totalPrice;
    //   }, 0);
    // };

    const newItemProductPrice = (objArray) => {
      return objArray.reduce((totalPrice, obj) => {
        if (obj.spacename === "POST DESIGN SIGN-OFF CHANGES") {
          return obj.product.reduce((subtotal, productItem) => {
            // Check if prevQtData is true for the current product
            if (productItem.positiveQtCr || productItem.negativeQtCr) {
              return subtotal + productItem.productPrice;
            }
            return subtotal;
          }, totalPrice);
        }
        return totalPrice;
      }, 0);
    };

    // Call the function passing your obj array
    // const totalProductPrice = calculateTotalProductPrice(obj);
    // Only new Item total Price
    const totalNewItemPrice = newItemProductPrice(obj);
    // calculate positive negative quotation discount value
    const discountValue = (totalNewItemPrice * (projectDetails.discountPercent/ 100)).toFixed(2);
    // calculate positive negative quotation subTotal value
    const subTotalValue = parseInt(totalNewItemPrice - discountValue);
    // calculate positive negative quotation GST 18% value
    const gstAmount = ((18 / 100) * subTotalValue).toFixed(2);
    // calculate positive negative quotation Total value
    const totalValue = parseInt(subTotalValue + parseInt(gstAmount));

    setAddRemoveTotalValue(totalNewItemPrice); // TOTAL amount whatever we are only adding and removig from quotation on post designsignoff category change
    setTotalValueBeforeAddCategory(totalValue); // In total section before removing value from category the total value is storing
    calculateBalnceSheetAmount(totalNewItemPrice); // calculation all amount of BalanceSheet/c
  
    setNewChangesTotalProductsPrice(prevState => ({...prevState,totalQuotationValue: totalNewItemPrice, discount : discountValue, subTotal : subTotalValue , gst18PerAmount : gstAmount , total : totalValue}));
    setQtPreview(obj);
    // Here I am storing only new Item adding selection option new itme and catrgory new Item to store in projectProduct collection
    const d1 = obj.filter(data => data.spacename === 'POST DESIGN SIGN-OFF CHANGES').map((data, i) => {
    const data1 = data.product.filter((item)=> item?.categoryQtData || item.prevQtData) 
      return data1;
    })
    setqtPreviewOnlyNewData(d1[0]);
      obj.filter(data => data.spacename === 'POST DESIGN SIGN-OFF CHANGES').map((data, i) => {
      const filteredProducts = data?.product?.filter(item => item?.negativeQtCr);
      // if(filteredProducts.length > 0 || filteredProducts.length !== 0){
        if(totalNewItemPrice < 0){
        SetUploadPaymentButtonVisibility(true)
        setSendForApprovalDisable(false)
      } else {
        SetUploadPaymentButtonVisibility(false)
        setSendForApprovalDisable(true)
      }
});

// this is for balance sheet data to know how many nee item added
    if (
      projectDetails.contractCustomerApproved &&
      projectDetails.contractFinanceApproved &&
      projectDetails.designStages === 'Design Sign-off'
    ) {
      let extractedData = [];
      obj
        .filter(data => data.spacename === 'POST DESIGN SIGN-OFF CHANGES')
        .forEach(data => {
          // Inside the forEach loop, filter the product array
          const filteredProducts = data?.product?.filter(
            item => item?.negativeQtCr || item?.positiveQtCr
          );

          filteredProducts.forEach(product => {
            // Create an object containing only the name and productPrice fields
            const extractedProduct = {
              name: product.product.name,
              productPrice: product.productPrice,
              action: product.productPrice > 0 ? "Add" : "Less"
            };
            // Push the extracted product object into the extractedData array
            extractedData.push(extractedProduct);
          });
          // Log the filtered products
          // console.log("extractedData",extractedData);
          setNewItemAddedArray(extractedData)
        });
    }
    // getDiscountMaster();

    let miscIndex = -1;
    let dicountScope = [];
    let nonDicountScope = [];
    let categoryspecificTotal = []
    let discNElItemsArray = []
    let discElItemsArray = [];
    let discountOnAllScopeTrue = {};
    let discountOnAllScopeFalse = {};
    let spacesAndProducts = []
    let pdSpacesAndProducts = []
    let dummyproducts = []
    let complimentaryItems = []
    let approvedComplimentaryItems = []
    obj.forEach((preview) => {
      const productNamesInSpace = preview.product
        .flatMap((product) => product)
        .filter((product) => product?.product?.isComplimentaryItem)
        .map((product) => ({
          name: product.product.name,
          area: product.area
        }));
      spacesAndProducts?.push({
        spaceName: preview.spacename,
        productNames: productNamesInSpace
      })
    })
    spacesAndProducts.forEach((product) => {
      if (product.productNames && product.productNames.length != 0) {
        setComplimentaryItemPresent(true);
      }
    })

    projectDetails.complimentaryItems?.forEach((item) => {

      // Check if products array is not empty
      if (item.productNames && item.productNames.length > 0) {
        approvedComplimentaryItems.push({
          spaceName: item.spaceName,
          productNames: item.productNames
        })
        item.productNames.forEach((product) => {
          pdSpacesAndProducts.push({
            roomName: item.spaceName,
            qty: product.area,
            name: product.name,
            chApproved: product.chApproved,
            itemApproved: product.itemApproved
          });
        });
      }
    });

    obj.forEach((preview) => {
      preview.product.forEach((product) => {
        if (product?.product?.isComplimentaryItem) {
          dummyproducts.push({
            roomName: preview.spacename,
            name: product.product.name,
            qty: product.area
          })
        }
      })
    })
    obj.forEach((preview) => {
      const productNames = [];
      preview.product.forEach((product) => {
          if (product?.product?.isComplimentaryItem) {
              productNames.push({
                  name: product.product.name,
                  qty: product.area,
                  chApproved: false
              });
          }
      });
      if (productNames.length > 0) {
        setComplimentaryItemPresent(true)
          complimentaryItems?.push({
              spaceName: preview.spacename,
              productNames: productNames
          });
      }
  });
    for (let i = 0; i < dummyproducts.length; i++) {
      const componentItem = dummyproducts[i];
      let isNewRoomPresent = true; // Flag to check if a new room is present

      for (let j = pdSpacesAndProducts.length - 1; j >= 0; j--) {
        const pdItem = pdSpacesAndProducts[j];
        if (componentItem && pdItem) {
          // Check if the name of the componentItem is present in pdSpacesAndProducts
          const isNameNotPresent = pdSpacesAndProducts.every(pdItem => pdItem.name !== componentItem.name);
          // Check if the componentItem is present in pdSpacesAndProducts and approved
          if (componentItem.name === pdItem.name && componentItem.roomName === pdItem.roomName && pdItem.chApproved) {
            isNewRoomPresent = false; // Set to false as room exists
            // Check if qty and roomName match
            if (componentItem.qty === pdItem.qty && componentItem.roomName === pdItem.roomName) {
              break; // Break the loop if matched
            } else if (componentItem.qty !== pdItem.qty && componentItem.roomName === pdItem.roomName) {
              setComplimentaryItemRevoked(true);
            } else if (componentItem.roomName !== pdItem.roomName) {
              // setComplimentaryItemRevoked(true);
            }
          } else if (componentItem.name === pdItem.name && componentItem.roomName === pdItem.roomName && pdItem.chApproved === false) {
            setRejectedItem(true);
          }
          if (isNameNotPresent) {
            setNewComplimentaryItem(true)
          }
        }
      }
      // If a new room is present in dummyproducts and not in pdSpacesAndProducts, set the flag
      if (isNewRoomPresent) {
        setComplimentaryItemRevoked(true);
      }
    }
    
    for (let i = 0; i < sortedData.length; i++) {
      let price = 0;
      let discElItemsPrAmnt = 0;
      let discNElItemsPrAmnt = 0;
      sortedData[i]['products'].forEach(element => {
        if (element['product']?.['scopeId']?.['eligibleForDiscount'] === true) {
          dicountScope.push(element['product']?.['scopeId']?.['name']);
          setDiscountScopes(dicountScope);
        } else {
          nonDicountScope.push(element['product']?.['scopeId']?.['name']);
          setNonDiscountScopes(nonDicountScope);
        }
      });
      if (sortedData[i]['categoryId']['name'].toLowerCase() === 'miscellaneous') {
        miscIndex = i;
      }

      if (projectDetails.discountOnAllScopes === false) {
        sortedData[i]['products'].forEach(element => {
          if (element['product']?.['scopeId']?.['eligibleForDiscount'] === true) {
            discElItemsPrAmnt = discElItemsPrAmnt + Number(element['productPrice'])
            discElItemsArray.push(discElItemsPrAmnt)
          } else if (element['product']?.['scopeId']?.['eligibleForDiscount'] === false) {
            discNElItemsPrAmnt = discNElItemsPrAmnt + Number(element['productPrice'])
            discNElItemsArray.push(discNElItemsPrAmnt)
          }
        });
        setDisCountEligibleItemsPriceAmount(discElItemsArray)
        setDisCountNonEligibleItemsPriceAmount(discNElItemsArray);
        categoryspecificTotal.push(Number(discNElItemsPrAmnt.toFixed(2)) + Number(discElItemsPrAmnt.toFixed(2)));
        setCategoryspecificTotal(categoryspecificTotal);
      } else if (projectDetails.discountOnAllScopes === true) {
        sortedData[i]['products'].forEach(element => {
          price = Number(price) + Number(element['productPrice']);
        });
        categoryspecificTotal.push(price)
        setCategoryspecificTotal(categoryspecificTotal);
      }
    }

    for (let i = 0; i < categoryspecificTotal.length; i++) {
      if (miscIndex === i) continue;
      priceAmount += categoryspecificTotal[i];
      totalDiscPrAmnt = discElItemsArray.reduce((a, b) => a + b, 0);
      totalNonDiscPrAmnt = discNElItemsArray.reduce((a, b) => a + b, 0);
      setTotalPaymentPrice(priceAmount);
      setTotalDescPerAmt(totalDiscPrAmnt);
      setTotalNonDescPerAmt(totalNonDiscPrAmnt);
    }

    if (projectDetails.discountOnAllScopes === true) {
      priceAmount = Number(priceAmount.toFixed(0));
      taxableAmount = Number((0.18 * priceAmount).toFixed(0));
      totalPayable = Number((priceAmount + taxableAmount).toFixed(0));
      discountOnAllScopeTrue.priceAmount = priceAmount;
      discountOnAllScopeTrue.taxableAmount = taxableAmount;
      discountOnAllScopeTrue.totalPayable = totalPayable;
      calDiscount(projectDetails.discountPercent, discountOnAllScopeTrue);
    } else {
      const uniqueDiscountScopes = Array.from(new Set(discountScopes));
      const uniqueNonDiscountScopes = Array.from(new Set(nonDiscountScopes));
      setEligibleDiscountScopes(uniqueDiscountScopes.length === 0 ? 'None' : uniqueDiscountScopes.join(','));
      setEligibleNonDiscountScopes(uniqueNonDiscountScopes.length === 0 ? 'None' : uniqueNonDiscountScopes.join(','));
      totalDiscPrAmnt = Number(totalDiscPrAmnt.toFixed(0));
      totalNonDiscPrAmnt = Number(totalNonDiscPrAmnt.toFixed(0));
      priceAmount = Number(totalDiscPrAmnt.toFixed(0)) + Number(totalNonDiscPrAmnt.toFixed(0));
      taxableAmount = Number((0.18 * priceAmount).toFixed(0));
      totalPayable = Number((priceAmount + taxableAmount).toFixed(0));
      discountOnAllScopeFalse.eligibleDiscountScopesList = uniqueDiscountScopes.length === 0 ? 'None' : uniqueDiscountScopes.join(',')
      discountOnAllScopeFalse.eligibleNonDiscountScopesList = uniqueNonDiscountScopes.length === 0 ? 'None' : uniqueNonDiscountScopes.join(',')
      discountOnAllScopeFalse.totalDiscPrAmnt = totalDiscPrAmnt;
      discountOnAllScopeFalse.totalNonDiscPrAmnt = totalNonDiscPrAmnt;
      discountOnAllScopeFalse.priceAmount = priceAmount;
      discountOnAllScopeFalse.taxableAmount = taxableAmount;
      discountOnAllScopeFalse.totalPayable = totalPayable;
      calDiscount(projectDetails.discountPercent, discountOnAllScopeFalse);
    }

    if(projectDetails?.sentForQuotationChangeApproval?.checkQtCrAmount?.valuesGreaterThan20k === false && projectDetails?.delayCategoryCheck === false){
      setFinalizeButtonVisibility(true);
    }else{
      setFinalizeButtonVisibility(false);
    }
  }
// calculate balance sheet data
  const calculateBalnceSheetAmount = (total)=> {
    let prevquotationTotalAmount;
    if (projectDetails?.sentForQuotationChangeApproval?.isSendForApproval) {
      SetQuotationTotalAmount(Math.round(projectDetails?.prevQuotationTotalAmount))
      prevquotationTotalAmount = projectDetails?.prevQuotationTotalAmount;
    } else {
      prevquotationTotalAmount = quotationTotalAmount; // Assuming quotationTotal is the total amount from the quotation
    }
    const reviseQuotationValuesCount = parseInt(prevquotationTotalAmount +  total).toFixed(2) ;
    const  discountCount = (reviseQuotationValuesCount * (projectDetails.discountPercent /100)).toFixed(2);
    const subTotalCount  = parseInt(reviseQuotationValuesCount - discountCount);
    const gstCount = (subTotalCount * (18 /100)).toFixed(2);
    const totalCount = parseInt(subTotalCount + (+gstCount));
    getAmountPaid(totalCount)
    setBalansheSheetQtTotalPrice(totalCount);
    setBalanceSheetData((prev) => ({...prev , reviseQuotationValue : reviseQuotationValuesCount, discount: discountCount, subTotal: subTotalCount, GST : gstCount, total : totalCount}));
  }
  const calDiscount = (discntval, valueData) => {
    if (+discntval < 0) {
      // Display a warning message if the discount percentage is negative
      return props.setSnackInfo("Discount % can't be Negative", "warning");
    }

    if (+discntval > 18) {
      // Display a warning message if the discount percentage is greater than 18%
      // return props.setSnackInfo("Cannot raise a discount request greater than 18%.", "warning");
      return;
    }
  
    setChangeDiscount(discntval);
    let previousData;
    if (valueData !== 'val') {
      previousData = valueData
    }
    let materialProcPercent = projectDetails.materialProcuredPercent === null || projectDetails.materialProcuredPercent === undefined ? 0 : projectDetails.materialProcuredPercent;
    let amount = previousData === undefined ? totalPaymentPrice : previousData.priceAmount;
    let totalDiscPrice = previousData === undefined ? totalDescPerAmt : previousData.totalDiscPrAmnt;
    let totalNonDiscPrice = previousData === undefined ? totalNonDescPerAmt : previousData.totalNonDiscPrAmnt;
    if (projectDetails.discountOnAllScopes === false) {
      discountValue = (discntval / 100) * totalDiscPrice;
      discItemsSubTotal = totalDiscPrice - discountValue;
      subTotal = discItemsSubTotal + totalNonDiscPrice;
      scopePercentage = 100 - materialProcPercent;
      decorpotScope = parseInt(String((scopePercentage * subTotal) / 100));
      taxableAmount = parseInt(String(decorpotScope * 0.18));
      total = taxableAmount + decorpotScope;
      ctpAmount = parseInt(String((materialProcPercent * subTotal) / 100));
      totalPayable = parseInt(String(total + ctpAmount));
      customerSavings = parseInt(String((amount * 1.18) - totalPayable));
      savingPercen = Number((customerSavings * 100) / (1.18 * (totalDiscPrice + totalNonDiscPrice)).toFixed(2));

      let paymentInfo = {
        priceAmount: amount,
        discountPercent: Number(discntval),
        discountValue: discountValue.toFixed(2),
        subTotal: subTotal,
        scopePercentage: scopePercentage ? scopePercentage : 0,
        decorpotScope: decorpotScope ? decorpotScope : 0,
        taxableAmount: taxableAmount,
        total: total,
        savingPercen: savingPercen ? savingPercen : 0,
        customerSavings: customerSavings ? customerSavings : 0,
        discItemsSubTotal: discItemsSubTotal,
        totalNonDiscPrAmnt: totalNonDiscPrice
      }
      setPaymentTotalInfo(paymentInfo)
      setDiscountItemSubTotal(discItemsSubTotal);
    } else {
      discountValue = (discntval / 100) * amount;
      subTotal = amount - discountValue;
      scopePercentage = 100 - materialProcPercent;
      decorpotScope = parseInt(String((scopePercentage * subTotal) / 100));
      taxableAmount = parseInt(String(decorpotScope * 0.18));
      total = taxableAmount + decorpotScope;
      ctpAmount = parseInt(String(projectDetails.materialProcuredPercent * subTotal) / 100);
      totalPayable = parseInt(String(total + ctpAmount));
      customerSavings = parseInt(String((amount * 1.18) - totalPayable));
      savingPercen = Number(((customerSavings * 100) / (1.18 * amount)).toFixed(2));

      let paymentInfo = {
        priceAmount: amount,
        discountPercent: Number(discntval),
        discountValue: discountValue.toFixed(2),
        subTotal: subTotal,
        scopePercentage: scopePercentage ? scopePercentage : 0,
        decorpotScope: decorpotScope ? decorpotScope : 0,
        taxableAmount: taxableAmount,
        total: total,
        savingPercen: savingPercen ? savingPercen : 0,
        customerSavings: customerSavings ? customerSavings : 0
      }
      setPaymentTotalInfo(paymentInfo)
    }
  }

  const discountRequest = async (discVal) => {
    let obj = {
      discountPercent: Number(discVal),
      discountStatus: 'Sent for approval',
      discountApprovalRequest: true,
      leadId: leadId
    }
    props.setLoading(true)
    const discountApprovalResponse = await HttpService.discountApproval(obj, leadId);
    if (discountApprovalResponse.status === 200) {
      props.setSnackInfo('Discount request send successfully', "success")
    } else {
      props.setSnackInfo('Something went wrong', "error")
    }
    getAllQuotationsForProject();
    props.setLoading(false)
  }
  const [defaultDimension, setDefaultDimension] = useState({'mm': 300, 'Sqft': 1})
  const revoke = async () => {
    let obj = {
      discountApprovalRequest: false
    }
    props.setLoading(true)
    const discountApprovalResponse = await HttpService.discountApproval(obj, leadId);
    if (discountApprovalResponse.status === 200) {
      props.setSnackInfo('Discount revoke successfully', "success")
    } else {
      props.setSnackInfo('Something went wrong', "error")
    }
    getAllQuotationsForProject()
    props.setLoading(false)
  }
  


  const convertToExcel = () => {
    const wb = XLSX.utils.book_new();

    // Summary sheet
    const wsSummary = XLSX.utils.aoa_to_sheet([
      ["Category", "Particulars", "Differential", "Reason belongs to"],
      ...comapredData.flatMap((data) => ([
        [data.spaceName],
        ...data.products.map(product => [
          '',
          product.product.name,
          commaSeparateAmount(product.differential),
          product.new ? 'Additional' : (product.removed ? 'Descope' : (product.finishChanged ? 'Finish Change' : (product.finishAreaPositive || product.finishAreaNegative ? 'Finish and Sq/Ft' : (product.areaChangedPositive || product.areaChangedNegative ? 'Sq/Ft or Qty' : ''))))
        ])
      ])),
    ]);
    XLSX.utils.book_append_sheet(wb, wsSummary, "Summary");

    // View Page sheet
    const wsViewPage = XLSX.utils.aoa_to_sheet([
      ["", "", "", "", "", "", "", "", "", "", "", ""],
      ...comapredData.flatMap((data) => ([
        [{ v: data.spaceName, s: { alignment: { horizontal: 'center' } } }],
        ["Sl No", "Quotation Name", "Name", "Priority", "Finish", "Price Per Unit", "No/Qty", "Depth", "Width", "Length", "Area/Qty", "Price"],
        ...data.products.map((data1, j) => ([
          j + 1,
          data?.otherQuotationVersion ? data?.otherQuotationVersion : data?.baseQuotationVersion,
          data1?.product?.name,
          data1?.priority,
          data1?.product?.finishInclude ? data1?.finishType?.name : `-`,
          data1?.totalPricePerUnit,
          data1?.product?.name.includes('Tiles fixing charges for tiles') ? data1?.fixingCharge : `-`,
          data1?.product.depth,
          data1?.width,
          data1?.length,
          data1?.area ? data1?.area.toFixed(2) : `-`,
          `₹ ${data1?.removed ? `-${commaSeparateAmount(data1?.productPrice)}` : commaSeparateAmount(data1?.productPrice)}`
        ])),
        ["", "", "", "", "", "", "", "", "", "", "", ""], // Empty row
        ["Total", "", "", "", "", "", "", "", "", "", "", `₹ ${commaSeparateAmount(
          data.products.reduce((acc, crr) => acc + (crr.removed ? -crr.productPrice : crr.productPrice), 0)
        )}`], // Total row
        ["", "", "", "", "", "", "", "", "", "", "", ""], // Empty row
      ])),
    ]);

    // Apply styles to View Page sheet
    wsViewPage['!rows'] = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, { ht: 30 }]; // Set row height for header, total row, and descriptions
    wsViewPage['!cols'] = [{}, { width: 20 }, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}]; // Set column width for specific columns
    wsViewPage['A1'].s = { font: { bold: true }, fill: { bgColor: { indexed: 22 } } }; // Style for header A1
    wsViewPage['B1'].s = { font: { bold: true }, fill: { bgColor: { indexed: 22 } } }; // Style for header B1
    wsViewPage['C1'].s = { font: { bold: true }, fill: { bgColor: { indexed: 22 } } }; // Style for header C1
    wsViewPage['D1'].s = { font: { bold: true }, fill: { bgColor: { indexed: 22 } } }; // Style for header D1
    
    XLSX.utils.book_append_sheet(wb, wsViewPage, "View Page");

    return XLSX.writeFile(wb, 'data.xlsx');
  };

  // Event handler for download button
  const handleDownload = () => {
    convertToExcel();
  };

  const compareVersions = (a, b) => {
    const aParts = a.split('.').map(Number);
    const bParts = b.split('.').map(Number);
  
    for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
      const aPart = aParts[i] || 0;
      const bPart = bParts[i] || 0;
  
      if (aPart < bPart) return -1;
      if (aPart > bPart) return 1;
    }
  
    return 0;
  };

  const compareQuotations = async () => {
    props.setLoading(true)
    setComparisionPreview(true)
    try {
      const [baseQuotation, otherQuotation] = selectedQuotations
        .sort((a, b) => compareVersions(a.version, b.version))
        .map(({ version, components }) => ({ version, components }));
    
      const newProducts = [];
      const removedProducts = [];
      const finishChangedProducts = [];

      // Iterate through the components of the other quotation
      for (const otherComponent of otherQuotation.components) {
        // Compare the categoryId.name of the other component with the base component
        const matchingBaseComponent = baseQuotation.components.find(baseComponent => baseComponent.categoryId.name === otherComponent.categoryId.name);

        const spaceName = otherComponent.categoryId?.name || '';
        const otherQuotationVersion = otherQuotation.version
        const baseQuotationVersion = baseQuotation.version
        if (matchingBaseComponent) {
          // Iterate through the products of the other component
          for (const product of otherComponent.products) {
            const existingProduct = matchingBaseComponent.products.find(baseProduct => baseProduct.product.name === product.product.name);
            const basePrice = existingProduct ? existingProduct.productPrice : null;
            const otherPrice = product.productPrice;
            const differential = (otherPrice - basePrice).toFixed(2)
            // If the product doesn't exist in the base component or finishType name is different, store its data
            if (!existingProduct) {
              let spaceExists = false;

              // Check if spaceName already exists
              newProducts.forEach(space => {
                if (space.spaceName === spaceName) {
                  // If spaceName exists, push products into it
                  spaceExists = true;
                  space.products.push({ ...product, new: true, differential: differential }); // Add spaceName to product data and set new: true
                }
              });

              // If spaceName doesn't exist, create a new space and push products into it
              if (!spaceExists) {
                newProducts.push({
                  spaceName,
                  products: [{ ...product, new: true, differential: differential }],
                  otherQuotationVersion
                });
              }
            } else if (existingProduct.finishType.name !== product.finishType.name && existingProduct.area === product.area) {
              const spaceName = otherComponent.categoryId?.name || '';

              // Check if spaceName already exists in newProducts
              let spaceExistsInNew = false;
              newProducts.forEach(space => {
                if (space.spaceName === spaceName) {
                  spaceExistsInNew = true;
                  space.products.push({ ...product, finishChanged: true, differential: differential }); // Add product to existing space and set finishChanged: true
                }
              });

              // If spaceName doesn't exist, create a new space and push products into it
              if (!spaceExistsInNew) {
                newProducts.push({
                  spaceName,
                  products: [{ ...product, finishChanged: true, differential: differential }],
                  otherQuotationVersion
                });
              }
            } else if (existingProduct.finishType.name !== product.finishType.name && existingProduct.area !== product.area) {
            
              if (existingProduct.area > product.area) {

                const spaceName = otherComponent.categoryId?.name || '';

                // Check if spaceName already exists in newProducts
                let spaceExistsInNew = false;
                newProducts.forEach(space => {
                  if (space.spaceName === spaceName) {
                    spaceExistsInNew = true;
                    space.products.push({ ...product, finishAreaNegative: true, differential: differential });
                  }
                });

                // If spaceName doesn't exist, create a new space and push products into it
                if (!spaceExistsInNew) {
                  newProducts.push({
                    spaceName,
                    products: [{ ...product, finishAreaNegative: true, differential: differential }],
                    otherQuotationVersion
                  });
                }
              } else if (existingProduct.area < product.area) {

                const spaceName = otherComponent.categoryId?.name || '';

                // Check if spaceName already exists in newProducts
                let spaceExistsInNew = false;
                newProducts.forEach(space => {
                  if (space.spaceName === spaceName) {
                    spaceExistsInNew = true;
                    space.products.push({ ...product, finishAreaPositive: true, differential: differential }); // Add product to existing space and set finishChanged: true
                  }
                });

                // If spaceName doesn't exist, create a new space and push products into it
                if (!spaceExistsInNew) {
                  newProducts.push({
                    spaceName,
                    products: [{ ...product, finishAreaPositive: true, differential: differential }],
                    otherQuotationVersion
                  });
                }
              }
            } else if (existingProduct.area !== product.area) {
              if (existingProduct.area > product.area) {

                const spaceName = otherComponent.categoryId?.name || '';

                // Check if spaceName already exists in newProducts
                let spaceExistsInNew = false;
                newProducts.forEach(space => {
                  if (space.spaceName === spaceName) {
                    spaceExistsInNew = true;
                    space.products.push({ ...product, areaChangedNegative: true, differential: differential });
                  }
                });

                // If spaceName doesn't exist, create a new space and push products into it
                if (!spaceExistsInNew) {
                  newProducts.push({
                    spaceName,
                    products: [{ ...product, areaChangedNegative: true, differential: differential }],
                    otherQuotationVersion
                  });
                }
              } else if (existingProduct.area < product.area) {

                const spaceName = otherComponent.categoryId?.name || '';

                // Check if spaceName already exists in newProducts
                let spaceExistsInNew = false;
                newProducts.forEach(space => {
                  if (space.spaceName === spaceName) {
                    spaceExistsInNew = true;
                    space.products.push({ ...product, areaChangedPositive: true, differential: differential }); // Add product to existing space and set finishChanged: true
                  }
                });

                // If spaceName doesn't exist, create a new space and push products into it
                if (!spaceExistsInNew) {
                  newProducts.push({
                    spaceName,
                    products: [{ ...product, areaChangedPositive: true, differential: differential }],
                    otherQuotationVersion
                  });
                }
              }
            }
          }
        } else {
          for (const product of otherComponent.products) {
            let spaceExists = false;
            // const basePrice = existingProduct ? existingProduct.productPrice : null;
            const otherPrice = product.productPrice;
            const differential = otherPrice
              // Check if spaceName already exists
              newProducts.forEach(space => {
                if (space.spaceName === spaceName) {
                  // If spaceName exists, push products into it
                  spaceExists = true;
                  space.products.push({ ...product, new: true, differential: differential  }); // Add spaceName to product data and set new: true
                }
              });

              // If spaceName doesn't exist, create a new space and push products into it
              if (!spaceExists) {
                newProducts.push({
                  spaceName,
                  products: [{ ...product, new: true, differential: differential  }],
                  otherQuotationVersion
                });
              }
          }
        }
      }

      for (const baseComponent of baseQuotation.components) {
        const matchingOtherComponent = otherQuotation.components.find(otherComponent => otherComponent.categoryId.name === baseComponent.categoryId.name);
        const baseQuotationVersion = baseQuotation.version
        if (!matchingOtherComponent) {
          const spaceName = baseComponent.categoryId?.name || '';
          let productNew = baseComponent.products.map(product => ({ ...product, removed: true, differential: -product.productPrice }));

          let spaceExists = false;

          // Check if spaceName already exists in newProducts
          let spaceExistsInNew = false;
          newProducts.forEach(space => {
            if (space.spaceName === spaceName) {
              spaceExistsInNew = true;
              space.products.push(...productNew);
            }
          });

          // If spaceName doesn't exist, create a new space in newProducts and push products into it
          if (!spaceExistsInNew) {
            newProducts.push({
              spaceName,
              products: productNew,
              baseQuotationVersion
            });
          }
        } else {
          for (const baseProduct of baseComponent.products) {
            const otherProduct = matchingOtherComponent.products.find(otherProduct => otherProduct.product.name === baseProduct.product.name);
            const differential = -baseProduct.productPrice
            if (!otherProduct) {
              const spaceName = baseComponent.categoryId?.name || '';

              // Check if spaceName already exists in newProducts
              let spaceExistsInNew = false;
              newProducts.forEach(space => {
                if (space.spaceName === spaceName) {
                  spaceExistsInNew = true;
                  space.products.push({ ...baseProduct, removed: true, differential: differential });
                }
              });

              // If spaceName doesn't exist, create a new space in newProducts and push the product into it
              if (!spaceExistsInNew) {
                newProducts.push({
                  spaceName,
                  products: [{ ...baseProduct, removed: true, differential: differential }],
                  baseQuotationVersion
                });
              }
            }
          }
        }
      }

      const combinedProducts = [...newProducts, ...removedProducts];
      setComparedData(newProducts);
      const pdf = await HttpService.comparedPdf(newProducts);
      setPdfLink(pdf.data.pdfUrl)

    } catch (error) {
      console.error('Error:', error);
    }
    props.setLoading(false);
  };

  const totalDifferential = comapredData.reduce((total, data) => {
    data.products.forEach(product => {
      const differential = product.differential;
      if (typeof differential === 'number' || 
          (typeof differential === 'string' && /^-?\d+(\.\d{1,2})?$/.test(differential))) {
        total += parseFloat(differential);
      } else {
        console.warn('Invalid differential value:', differential);
      }
    });
    return total;
  }, 0);
  
  const totalDifferentialFormatted = Number.isFinite(totalDifferential) ? totalDifferential.toFixed(2) : '0.00';
  
  const sendForComplimentaryApproval = async () => {
    props.setLoading(true);

    const extractedSpacesAndProducts = [];

    qtPreview.forEach(preview => {
      const productNamesInSpace = preview.product
        .flatMap(product => product)
        .filter(product => product.product.isComplimentaryItem)
        .map(product => ({
          name: product.product.name,
          area: product.area
        }));
      // Push into extractedSpacesAndProducts
      if (productNamesInSpace.length > 0) {
        const updateProducts = productNamesInSpace.map(product => ({
          ...product,
          chApproved: false
        }));
        extractedSpacesAndProducts.push({
          spaceName: preview.spacename,
          productNames: updateProducts
        });
      }
    });

    const obj = {
      complimentaryItems: extractedSpacesAndProducts,
      complimentaryItemApproval: true,
      complimentaryItemStatus: 'Sent for Approval',
      lead: projectDetails
    };

    const complimentaryItems = await HttpService.complimentaryApproval(obj, leadId);

    if (complimentaryItems.status === 200) {
      props.setSnackInfo("Complimentary Approval sent successfully", "success");
      window.location.reload();
    } else {
      props.setSnackInfo("Something went wrong", "error");
    }

    props.setLoading(false);
  };
  
  const finalize = () => {
      // warning! when we clicked finalize button for some condition instead of disable button
    const title = (!(discount <= discountMaster) && (discount !== projectDetails.discountPercent) || (projectDetails.discountStatus === "Rejected") && !(discount <= discountMaster) || (projectDetails.discountStatus === "Sent for approval")) &&
      ((complimentaryItemPresent && projectDetails.complimentaryItemStatus === "Not Initiated") || complimentaryItemRevoked || newComplimentaryItem || rejectedItem) ?
      "Complimentary Items & discount" :
      ((complimentaryItemPresent && projectDetails.complimentaryItemStatus === "Not Initiated") || complimentaryItemRevoked || newComplimentaryItem || rejectedItem) ?
        "Complimentary Items" : "discount"
    if(!(discount <= discountMaster) && (discount !== projectDetails.discountPercent) || (projectDetails.discountStatus === "Rejected") && !(discount <= discountMaster) || (projectDetails.discountStatus === "Sent for approval")){
          props.setSnackInfo(`Please get ${title} approved from DGM/CenterHead`, "warning")
    }
    else if (((complimentaryItemPresent && projectDetails.complimentaryItemStatus === 'Not Initiated') || complimentaryItemRevoked || newComplimentaryItem || rejectedItem) && userDetails.roles.find(role => role.name === ROLENAME.CHM_Manager || role.name === ROLENAME.CHM_User)) {
      props.setSnackInfo("Please remove the newly added complimentary items in the quotation before finalizing it", "warning")
    }
    else if ((complimentaryItemPresent && projectDetails.complimentaryItemStatus === 'Not Initiated') || complimentaryItemRevoked || newComplimentaryItem || rejectedItem) {
      props.setSnackInfo(`Please send the ${title} for approval before finalizing the quotation `, "warning")
    }
    else{
    let components = new Array();

    if (material == null || material === undefined) {
      material = 0;
    }
    if (projectDetails.discountPercent == null || projectDetails.discountPercent === undefined) {
      setChangeDiscount(0)
    }
    projectComponents.forEach(element => {
      let w = 0;
      if (element?.['categoryId']?.['_id'] !== null || element?.['categoryId']?.['_id'] !== undefined) {
        let products = new Array();
        for (let i = 0; i < element['products'].length; i++) {
          products.push({
            finishType: element['products'][i]['finishType'] ? element['products'][i]['finishType']?.['_id'] : null,
            priority: Number(element['products'][i]['priority']),
            product: element['products'][i]['product']?.['_id'],
            length: element['products'][i]['length'] ? element['products'][i]['length'] : 0,
            width: element['products'][i]['width'] ? element['products'][i]['width'] : 0,
            // unitType: element['products'][i]['unitType'] == null ? element['products'][i]['product']['unitId'] : null,
            unitType: element['products'][i]['unitType']['_id'],
            area: Number(element['products'][i]['area']) ? Number(element['products'][i]['area']) : 0,
            productPrice: Number(element['products'][i]['productPrice']),
            productPricePerUnit: Number(element['products'][i]['productPricePerUnit']),
            totalPricePerUnit: Number(element['products'][i]['totalPricePerUnit']),
            subUnitType: element['products'][i]['subUnitType'],
            productCustomPrice: Number(element['products'][i]['productCustomPrice']),
            productCustomArea: Number(element['products'][i]['productCustomArea']),
            images: element['products'][i]['images']
          });
        }
        let obj1 = {
          categoryId: {
            _id: element?.['categoryId']?.['_id'],
            name: element?.['categoryId']?.['name']
          },
          products: products,
          projCompId: element['_id'],
          plyType: element?.['plyType']?.['_id'] ? element?.['plyType']?.['_id'] : defaultPlyType._id
        }
        w++;
        if (obj1.products.length === 0) {
        } else {
          components.push(obj1);
        }
      }
    });
    if (projectDetails.discountOnAllScopes === true) {

      let balanceSheetDataObj = {
        positiveNegativeCr : projectDetails?.sentForQuotationChangeApproval?.isSendForApproval ? true : false ,
        prevQuotationAmount: quotationTotalAmount,
        newItemAdded: newItemAddedArray,
        revisedQtAmount : balanceSheetData.reviseQuotationValue,
        discountAmount: balanceSheetData.discount,
        subTotalAmount: balanceSheetData.subTotal,
        gstAmount: balanceSheetData.GST,
        totalAmount: balanceSheetData.total,
        amountPaid: amountPaid,
        delayPenality: damageDelayOtherValue.delayPenality,
        damages: damageDelayOtherValue.damages,
        goodWillDiscount : damageDelayOtherValue.goodWillDiscount,
        other: damageDelayOtherValue.other,
        referralBonus: damageDelayOtherValue.referralBonus,
        pendingDueAmount: balanceSheetData.pendingDue
      }
      let obj = {
        leadId: leadId,
        taxPercent: 18,
        totalAmount: totalPaymentPrice,
        discountOnAllScopes: projectDetails.discountOnAllScopes,
        totalDiscPrAmnt: 0,
        discItemsSubTotal: 0,
        totalNonDiscPrAmnt: 0,
        miscTotal: 0,
        version: projectDetails.nextVersionNo,
        components: components,
        discountPercent: Number(paymentTotalInfo.discountPercent),
        grandTotal: Number(paymentTotalInfo?.total?.toFixed(2)),
        materialProcured: projectDetails.materialProcuredPercent !== null ? projectDetails.materialProcuredPercent : 0,
        paymentReceiptArray:  projectDetails?.sentForQuotationChangeApproval?.paymentReceiptArray,
        staticDesignWonAmount : Number(paymentTotalInfo.total.toFixed(2)),
        balanceSheetData: (projectDetails?.sentForQuotationChangeApproval?.isSendForApproval ? balanceSheetDataObj :{} )
      }
      // if (this.miscIndex > -1) {
      //   obj.totalAmount = Number(obj.totalAmount + categoryspecificTotal[miscIndex]);
      //   obj.miscTotal = Number(categoryspecificTotal[miscIndex]);
      //   obj.grandTotal = Number(paymentTotalInfo.totalPayable + categoryspecificTotal[miscIndex]);
      // }
    let companyDataObject ={
      leadId:leadId,
      taxPercent : 18,
      totalPrice: Math.round(totalPaymentPrice),
      discount : Math.round((Number(totalPaymentPrice) * (Number(paymentTotalInfo.discountPercent)/100))) ,
      gst:  ((totalPaymentPrice - (Number(totalPaymentPrice) * (Number(paymentTotalInfo.discountPercent)/100))) * (18/100)).toFixed(2),
      grandTotal : Math.round(paymentTotalInfo?.total?.toFixed(2)),
      customerOutflow : Number(paymentTotalInfo?.total?.toFixed(2)),
      projectId: projectDetails.erpProjectId,
      newTemAddedDataArray : qtPreviewOnlyNewData,
    }
      finalizeQuotation(obj, companyDataObject);
    } else {

        let balanceSheetDataObj = {
          positiveNegativeCr : projectDetails?.sentForQuotationChangeApproval?.isSendForApproval ? true : false ,
          prevQuotationAmount: quotationTotalAmount,
          newItemAdded: newItemAddedArray,
          revisedQtAmount : balanceSheetData.reviseQuotationValue,
          discountAmount: balanceSheetData.discount,
          subTotalAmount: balanceSheetData.subTotal,
          gstAmount: balanceSheetData.GST,
          totalAmount: balanceSheetData.total,
          amountPaid: amountPaid,
          delayPenality: damageDelayOtherValue.delayPenality,
          damages: damageDelayOtherValue.damages,
          goodWillDiscount : damageDelayOtherValue.goodWillDiscount,
          other: damageDelayOtherValue.other,
          referralBonus: damageDelayOtherValue.referralBonus,
          pendingDueAmount: balanceSheetData.pendingDue
        }
      let obj = {
        leadId: leadId,
        taxPercent: 18,
        discountOnAllScopes: projectDetails.discountOnAllScopes,
        eligibleDiscountScopes: eligibleDiscountScopes,
        eligibleNonDiscountScopes: eligibleNonDiscountScopes,
        totalAmount: discountItemSubTotal + totalNonDescPerAmt,
        totalDiscPrAmnt: totalDescPerAmt,
        discItemsSubTotal: discountItemSubTotal,
        totalNonDiscPrAmnt: totalNonDescPerAmt,
        miscTotal: 0,
        version: projectDetails.nextVersionNo,
        components: components,
        discountPercent: Number(paymentTotalInfo.discountPercent),
        grandTotal: Number(paymentTotalInfo.total.toFixed(2)),
        staticDesignWonAmount : Number(paymentTotalInfo.total.toFixed(2)),
        materialProcured: projectDetails.materialProcuredPercent !== null ? projectDetails.materialProcuredPercent : 0,
        paymentReceiptArray:  projectDetails?.sentForQuotationChangeApproval?.paymentReceiptArray,
        balanceSheetData: (projectDetails?.sentForQuotationChangeApproval?.isSendForApproval ? balanceSheetDataObj :{} )
      }
      // if (miscIndex > -1) {
      //   obj.totalAmount = Number(obj.totalAmount + categoryspecificTotal[miscIndex]);
      //   obj.miscTotal = Number(categoryspecificTotal[miscIndex]);
      //   obj.grandTotal = Number(paymentTotalInfo.totalPayable + categoryspecificTotal[miscIndex]);
      // }
      let companyDataObject ={
        leadId:leadId,
        taxPercent : 18,
        totalPrice: Math.round(totalPaymentPrice),
        discount : Math.round((Number(totalPaymentPrice) * (Number(paymentTotalInfo.discountPercent)/100))) ,
        gst:  ((totalPaymentPrice - (Number(totalPaymentPrice) * (Number(paymentTotalInfo.discountPercent)/100))) * (18/100)).toFixed(2),
        grandTotal : Math.round(paymentTotalInfo?.total?.toFixed(2)),
        customerOutflow : Number(paymentTotalInfo?.total?.toFixed(2)),
        projectId: projectDetails.erpProjectId,
        newTemAddedDataArray : qtPreviewOnlyNewData
      }
      finalizeQuotation(obj,companyDataObject);
    }
  }
  }

  // Payment Receipt Details
  const paymentUploadFile = async (e) => {
    props.setLoading(true);
    const files = e.target.files;

    // Iterate over each selected file
    for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const fd = new FormData();
        fd.append("file", file);
        try {
            const fileUploadRes = await HttpService.uploadFile(fd);
            if (fileUploadRes.data) {
                setAddPaymentReceiptFileUploaded(true);
            }
            setPaymentReceipt(fileUploadRes.data);
            // setPaymentReceiptArray(...fileUploadRes.data)
            setPaymentReceiptArray(prev => [...prev, fileUploadRes.data]);
        } catch (err) {
            props.setSnackInfo(
                err?.response?.data ?? "Something went wrong",
                "error"
            );
        }
    }
    setDisplayUploadReceiptMsg(false);
    props.setLoading(false);
};

  // Quotation Changes Approval - When send for approval quotation to approver
  const sendForApproval = async()=> {
    await handleProductSelect1(addDataState);
    await saveQuotationChanges();
    let delayCategory = false;
    const { delayPenalityCheck, damagesCheck, goodWillDiscountCheck, otherCheck, referralBonusCheck } = damageDelayOtherValue;
    if(delayPenalityCheck || damagesCheck || goodWillDiscountCheck || otherCheck || referralBonusCheck){
      delayCategory = true;
    }
    const obj = {
      leadId : leadId,
      expCenter : projectDetails.experienceCenterId,
      negativeCR : 0,                                                                                                                       
      contractFinanceApproved : projectDetails.contractFinanceApproved,
      contractCustomerApproved : projectDetails.contractCustomerApproved,
      designStages : projectDetails.designStages,
      currentStage : projectDetails.currentStage,
      qtAmountCheck  : sendApprovalQtCrValue,
      cloneSelectedIndex : editQt, 
      // paymentReceipt: paymentReceipt,
      paymentReceiptArray: paymentReceiptArray,
      discountPercentage: paymentTotalInfo.discountPercent,
      newQuotationAmount: parseFloat(paymentTotalInfo.total).toFixed(2),
      prevQuotationAmount : parseFloat(prevQtTotalAmount).toFixed(2),
      diffQtChanges : parseFloat(diffQtChanges).toFixed(2),
      damagetoReferralCategory:damageDelayOtherValue,
      onlyQuotationAmount : parseInt(newChangesTotalProductsPrice.total), //only normal quotation calculation total value
      balnceSheetPendingDueTotal : parseFloat(balanceSheetData.pendingDue), //balnce sheet total value
      amountPaid : parseFloat(amountPaid),  
      delayCategoryCheck : delayCategory,
      totalAmountOfCategory : delayToReferralCategoryTotal, 
      amountPaid : amountPaid,
      prevQuotationTotalAmount : quotationTotalAmount//total amount of only category item from damage till bonus item
      }
    quotationChangesApproval(obj);
  }

  const updateCompanyData = async (obj) => {
    props.setLoading(true)
      const companyPLData = await HttpService.updateCompanyPLData(obj);
      if(companyPLData.status === 200){
        console.log("company pl data updated")
      }
      else {
        props.setSnackInfo('Something went wrong', "error")
      }
    props.setLoading(false)
  }

  const finalizeQuotation = async (val,companyObj) => {

    if((projectDetails.contractCustomerApproved && projectDetails.contractFinanceApproved && projectDetails.designStages === 'Design Sign-off')){
      await updateCompanyData(companyObj);
    }
    props.setLoading(true)
    const saveQuotationResponse = await HttpService.saveQuotation(val);
    if (saveQuotationResponse.status === 200) {
      props.setSnackInfo('Quotation saved successfully', "success")
      getAllQuotationsForProject()
      setOpenPreviewModal(false);
    } else {
      props.setSnackInfo('Something went wrong', "error")
    }
    props.setLoading(false)
  }

  // Send data for approval
  const quotationChangesApproval = async (val) => {
    props.setLoading(true)
    const response = await HttpService.quotationChangesApproval(val);
    if (response.status === 200) {
      props.setSnackInfo('Quotation Sent for Approval', "success")
      getAllQuotationsForProject();
      setOpenPreviewModal(false);
    } else {
      props.setSnackInfo('Something went wrong', "error")
    }
    window.location.reload(true);
    props.setLoading(false)
  }

  const removeCategoryFromList = async (id, name) => {
    props.setLoading(true)
    const deleteProjectComponentResponse = await HttpService.deleteProjectComponent(id);
    if (deleteProjectComponentResponse.status === 200) {
      props.setSnackInfo('Project Component Deleted successfully', "success")
      getAllRooms()
      getProjectComponent()
      getAllProductsFromRateCard()
      setShowCreateQuotationModal(true)
      let projectProducts = { ...projectComponentProducts };
      delete projectProducts[name];
      /*
        Uncomment below lines of code when on delete we have to set chosenCategory.
      */
      // let keysNames = []
      // Object.keys(projectProducts).forEach(function (key) {
      //   keysNames.push(key);
      // })
      // setChosenCategory(keysNames[keysNames.length - 1])
      // let categoryId = projectComponents.filter((comp, idx) => comp.categoryId.name === keysNames[keysNames.length - 1])[0]?._id
      // setChosenCategoryId(categoryId)
      setValue(null)
      setProjectComponentProducts(projectProducts);
    } else {
      props.setSnackInfo('Something went wrong', "error")
    }
    props.setLoading(false)
  }

  const getUnitByIdFunc = (val) => {
    if (val === constantUnitTypeMaster.Sqft) {
      return 'Sqft';
    }
    else {
      return 'mm';
    }
  }
  const unitChange = (v) => {
      let projectProducts = { ...projectComponentProducts }
      let unitSelect = v === null ? defaultUnitType : v
      let unitName = v?.name
      projectProducts[chosenCategory]['item'].forEach(async (ele, i) => {

        if(Number(ele['width']) < defaultDimension[`${unitName}`])
        {
          (ele['width']) = Number(defaultDimension[`${unitName}`])
        }

        if(Number(ele['length']) < defaultDimension[`${unitName}`])
        {
          (ele['length']) = Number(defaultDimension[`${unitName}`])
        }

        if(Number(ele['depth']) < defaultDimension[`${unitName}`])
        {
          (ele['depth']) = Number(defaultDimension[`${unitName}`])
        }

        
        if (falseCeilingValidate(ele['product']['name']))
        {
          if (unitSelect.name === 'Sqft') {
            ele['unitType'] = constantUnitTypeMaster.Sqft; // sqft unit id
            if(ele['product']['unitType'] == rftUnitTypeId || ele['product']['name'].toLowerCase().includes('rafter'))
            {
              ele['area'] = Number(ele['length']) * 3
            }
            else if(ele['product']['name'].includes('False ceiling (Peripheral with Cove'))
            {
              ele['area'] = ((Number(ele['width']) + Number(ele['length'])) * (Number(ele['depth']) + 3)) * 2
            }
            else if(ele['product']['name'].includes('False ceiling (Peripheral, No Cove'))
            {
              ele['area'] = ((Number(ele['width']) + Number(ele['length'])) * (Number(ele['depth']) + 1)) * 2
            }
            else if(ele['product']['name'].includes('False ceiling (Island with Cove'))
            {
              ele['area'] = (Number(ele['width']) * Number(ele['length'])) + ((Number(ele['width']) + Number(ele['length'])) * 6)
            }
            else if(ele['product']['name'].includes('False ceiling (Island, No Cove'))
            {
              ele['area'] = (Number(ele['width']) * Number(ele['length'])) + ((Number(ele['width']) + Number(ele['length'])) * 2)
            } 
            else if(ele['product']['name'].includes('False ceiling in Plywood'))
            {
              ele['area'] = (Number(ele['width']) * Number(ele['length'])).toFixed(2);
            }
            else
            {
              ele['area'] = (Number(ele['length']) + Number(ele['width'])) * 9;
              ele['area'] = Number(ele['area']).toFixed(2);
            }
            
          } else if (unitSelect.name === 'mm') {
            ele['unitType'] = constantUnitTypeMaster.mm; // mm unit id
            if(ele['product']['unitType'] == rftUnitTypeId || ele['product']['name'].toLowerCase().includes('rafter'))
            {
              ele['area'] = Number(((Number(ele['length']) * 3) / 300).toFixed(2))
            }
            else if(ele['product']['name'].includes('False ceiling (Peripheral with Cove'))
            {
              ele['area'] = Number(((((Number(ele['width']) + Number(ele['length'])) * (Number(ele['depth']) + 900)) * 2) / 90000).toFixed(2))
            }
            else if(ele['product']['name'].includes('False ceiling (Peripheral, No Cove'))
            {
              ele['area'] = Number(((((Number(ele['width']) + Number(ele['length'])) * (Number(ele['depth']) + 300)) * 2) / 90000).toFixed(2))
            }
            else if(ele['product']['name'].includes('False ceiling (Island with Cove'))
            {
              ele['area'] = Number((((Number(ele['width']) * Number(ele['length'])) + ((Number(ele['width']) + Number(ele['length'])) * 1800)) / 90000).toFixed(2))
            }
            else if(ele['product']['name'].includes('False ceiling (Island, No Cove'))
            {
              ele['area'] = Number((((Number(ele['width']) * Number(ele['length'])) + ((Number(ele['width']) + Number(ele['length'])) * 600)) / 90000).toFixed(2))
            }
            else if(ele['product']['name'].includes('False ceiling in Plywood'))
            {
              ele['area'] = Number((((Number(ele['width']) * Number(ele['length']))/300).toFixed(2)))
              ele['area'] = projectProducts[chosenCategory]['item'][i]['area'] / 300;
            }
            else
            {
              ele['area'] = ((Number(ele['length']) + Number(ele['width'])) * 9) / 300;
              ele['area'] = Number(ele['area']).toFixed(2);
            }
          }
        }
        else if(ele['product']['name'] == "Granite - Material + Fixing (Min order quantity 20 sft)")
        {
          if(unitSelect.name == 'Sqft')
          {
            ele['width'] = 2.5
            ele['area'] = Number(((Number(ele['length']) * Number(ele['width']))).toFixed(2))
            ele['unitType'] = constantUnitTypeMaster.Sqft;
          }
          else if(unitSelect.name == 'mm')
          {
            ele['width'] = 750
            ele['area'] = Number(((Number(ele['length']) * Number(ele['width']))/ 90000).toFixed(2))
            ele['unitType'] = constantUnitTypeMaster.mm;
          }
        }
        else if(ele['product']['name'] == "Granite for Island Counter - Material  + Fixing (Min order quantity 20 sft)")
        {
          if(unitSelect.name == 'Sqft')
          {
            
            ele['area'] = Number((((Number(ele['length']) + 1) * (Number(ele['width']) + 1))).toFixed(2))
            ele['unitType'] = constantUnitTypeMaster.Sqft;
          }
          else if(unitSelect.name == 'mm')
          {
            
            ele['area'] = Number((((Number(ele['length']) + 300) * (Number(ele['width']) + 300))/ 90000).toFixed(2))
            ele['unitType'] = constantUnitTypeMaster.mm;
          }
        }
        else
        {
          if (ele['subUnitType'] === 'Area') {     //condition for all products
            if (unitSelect.name === 'Sqft') {
              // eslint-disable-next-line default-case
              switch (getUnitByIdFunc(ele.unitType)) {
                case 'Sqft':
                  ele['area'] = (Number(ele['length']) * Number(ele['width']));
                  ele['area'] = Number(ele['area']).toFixed(2);
                  break;
                case 'mm':
                  ele['area'] = (Number(ele['length']) * Number(ele['width']));
                  ele['area'] = Number(ele['area']).toFixed(2);
                  break;
              }
              ele['unitType'] = unitSelect._id; // sqft unit id
            } else if (unitSelect.name === 'mm') {
              switch (getUnitByIdFunc(ele.unitType)) {
                case 'Sqft':
                  ele['area'] = (Number(ele['length']) * Number(ele['width'])) / 90000;
                  ele['area'] = Number(ele['area'].toFixed(2));
                  break;
                case 'mm':
                  break;
              }
              ele['unitType'] = unitSelect._id; // mm unit id
            }
          } else { }
        }
        ele['productPrice'] = ele['totalPricePerUnit'] * ele['area'];
        ele['productPrice'] = Number(ele['productPrice'].toFixed(2));
        ele.changePrice = ele['productPrice'];
        ele.changeArea = ele.area;
      })
      setSelectedUnit(projectProducts[chosenCategory]['unitSelect'] = unitSelect)
      projectProducts[chosenCategory]['unitSelect'] = unitSelect
      setProjectComponentProducts(projectProducts)
  }

  const falseCeilingValidate = (name) => {    //Validation for false ceiling
    let nm = name.split(' ');
    if (nm[0].toLowerCase() === 'false' && nm[1].toLowerCase() === 'ceiling') {
      return true;
    } else {
      return false;
    }
  }

  const customArea = (i, val, custArea) => {
    let projectProducts = { ...projectComponentProducts }
    projectProducts[chosenCategory]['item'][i]['customArea'] = val.target.checked
    if (val.target.checked) {
      projectProducts[chosenCategory]['item'][i].productCustomArea = Number(projectProducts[chosenCategory]['item'][i].area).toFixed(2);
      projectProducts[chosenCategory]['item'][i].area = 0;
    } else {
      projectProducts[chosenCategory]['item'][i].productCustomArea = 0;
      projectProducts[chosenCategory]['item'][i]['area'] = Number((projectProducts[chosenCategory]['item'][i]['width']) + Number(projectProducts[chosenCategory]['item'][i]['length']) * 9).toFixed(2);
      if (projectProducts[chosenCategory]['unitSelect']['name'] === 'mm') {
        projectProducts[chosenCategory]['item'][i]['area'] = projectProducts[chosenCategory]['item'][i]['area'] / 300;
        projectProducts[chosenCategory]['item'][i]['area'] = Number(projectProducts[chosenCategory]['item'][i]['area']).toFixed(2);
      }
    }
    setProjectComponentProducts(projectProducts)
  }

  const { name, email, contact_no, projectType, propertyType, scopeOfWork, description, floorPlan, isRegistered, area, leadType, discountOnAllScopes, assignTo, assignToManager } = projectDetailsFields

  const openEditProjectModal = () => {
    setEditProjectModal(true);
    setProjectDetailsFields({
      name: projectDetails.customerId.name,
      email: projectDetails.customerId.email,
      contact_no: projectDetails.customerId.contact_no,
      projectType: projectDetails.projectType,
      propertyType: projectDetails.propertyType,
      scopeOfWork: projectDetails.scopeOfWork,
      description: projectDetails.remark,
      floorPlan: projectDetails.floorPlan,
      isRegistered: projectDetails.isRegistered,
      leadType: projectDetails.leadType,
      assignTo: projectDetails.assignTo._id,
      area: projectDetails.area,
      discountOnAllScopes: projectDetails.discountOnAllScopes
    })
  }

  const changeHandler = (e) => {
    setProjectDetailsFields({
      ...projectDetailsFields, [e.target.name]: e.target.value,
    })
  }

  const saveProjectDetail = async () => {
    let obj = {
      ...projectDetailsFields,
      'customerId': projectDetails.customerId._id,
      'customer_no': projectDetails.customerId.customer_no,
      'area': projectDetails.area,
      'city': projectDetails.city._id,
      'experienceCenterId': projectDetails.experienceCenterId._id,
      'startInteriors': projectDetails.startInteriors
    }
    props.setLoading(true)
    try {
      const response = await HttpService.createNewProjectForAdditionalQuotation(obj)
      setEditProjectModal(false);
      props.setSnackInfo(response.data, "success")
    } catch (error) {
      props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  const cloneSelectedVersion = async () => {
    let lastVersion = quotationList[quotationList.length - 1];
    let obj = {
      versionId: selectedQuotationVersion,
      leadId: leadId,
      lastVersionNo: lastVersion.version,
    }
    props.setLoading(true)
    try {
      const response = await HttpService.cloneSelectedQuotation(obj)
      setOpenSelectQuotationVersion(false);
      getAllQuotationsForProject();
      window.location.reload(true)
      props.setSnackInfo(response.data.res, "success")
    } catch (error) {
      props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  const downloadMaskedQuotation = async (data) => {
    props.setLoading(true)
    try {
      const response = await HttpService.getMaskedQuotation(data._id)
      window.open(response.data.link)
      getAllQuotationsForProject();
      props.setSnackInfo(response.data.message, "success")
    } catch (error) {
      props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }
//   const handleCheckoutData = (data) =>{
//     let getTenPercentAmount = data.grandTotal/100*10;
//     setStoreTenPercentAmount({
//       ...storeTenPercentAmount,
//       amount: Math.floor(Number(getTenPercentAmount)),
//       leadno:projectDetails?.lead_no,
//     });
//     setCheckoutPopUp(true)
//  }

  // ajit
  const handle_initiate_payment = useCallback(async (data) =>
  {
    // console.log('data', data)
    // setSelectedPaymentMode({...selectedPaymentMode, paymentPatner: {name:'Pine Labs'}})
    
    // selectedPaymentMode(cardPaymentMode[0])

    props.setLoading(true)
    try
    {
      setPaymentActionChosen('Initiate')

      setTotalQuotationValue(data)
      
      // console.log('userDetails', userDetails)

      const response = await HttpService.getSelectedExpCenterDetails(userDetails.selectedExpCenter);
      
      // console.log('response', response)
      
      setPineLabsPos(response.data[0].pineLabsPos)
      
      
      let getTenPercentAmount = data.grandTotal/100*10;
      
      setStoreTenPercentAmount({ ...storeTenPercentAmount, amount: Math.floor(Number(getTenPercentAmount))});
      
      const response2 = await HttpService.getAllPaymentModes();
      
      // console.log('response2', response2)

      let filterd_data = response2.data.filter(el => el.paymentPartner.name == 'Pine Labs')
      
      setpaymentModes(filterd_data)

      // console.log('filterd_data', filterd_data)

      // let cardPaymentMode = response2.data.filter(el=>el.name == 'Card - Pine Labs')
      let cardPaymentMode = response2.data.filter(el=>el.name == 'Card - Pine Labs (via ERP)')
      // console.log('cardPaymentMode', cardPaymentMode)

      setSelectedPaymentMode(cardPaymentMode[0])

      setHidePaymentModeInput(true)


      
      setOpenPaymentCheckoutModal(true)

      get_transaction_partners();
    }
    catch(error)
    {
      console.log('error', error.message)
      
      console.error(error)
      
      props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
    }
    props.setLoading(false)
 },[])

 const handle_record_payment = useCallback(async (data) =>
  {
    // console.log('data', data)

    props.setLoading(true)
    try
    {
      setPaymentActionChosen('Record')
      setTotalQuotationValue(data)
      // console.log('userDetails', userDetails)
      const response = await HttpService.getSelectedExpCenterDetails(userDetails.selectedExpCenter);
      // console.log('response', response)
      setPineLabsPos(response.data[0].pineLabsPos)
      let getTenPercentAmount = data.grandTotal/100*10;
      setStoreTenPercentAmount({ ...storeTenPercentAmount, amount: Math.floor(Number(getTenPercentAmount))});
      
      const response2 = await HttpService.getAllPaymentModes();
      
      // let filterd_data = response2.data.filter(el => el.paymentPartner.name != 'Pine Labs')
      let filterd_data = response2.data
      setpaymentModes(filterd_data)
      setpaymentModes(filterd_data)
      setOpenPaymentCheckoutModal(true)
      get_transaction_partners();
    }
    catch(error)
    {
      console.log('error', error.message)
      
      console.error(error)
      
      props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
    }
    props.setLoading(false)
 },[])

const [paymentPatner,setPaymentPatner] = useState("")
const [internalPaymentPartner,setInternalPaymentPartners] = useState("")

const get_transaction_partners = async() =>{
try
{
  const respo = await HttpService.paymentpatners();
  for(let i = 0; i < respo.data.length ;i++){
    if(respo.data[i].name === "Pine Labs"){
      setPaymentPatner(respo.data[i]._id)
    }else if(respo.data[i].name === "DP internal"){
      setInternalPaymentPartners(respo.data[i]._id)
    }
  }
  }
  catch(error)
  {
    props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
  }
}
const editAmount = (e) => {
  const originalValue = parseFloat(e.target.value); // Convert the input value to a number
  const newValue = originalValue.toFixed(2); // Format to two decimal places

  if (originalValue > 0) { // Compare the original value
    setStoreTenPercentAmount({ amount: parseFloat(newValue) }); // Convert the string back to a number
  } else {
    props.setSnackInfo("Payment cannot be less than 1", "warning");
  }
};

  const handleCloseCheckout = () =>{
    setPaymentPatner("")
    setSelectedPaymentMode([])
    setOtherPaymentMethod({slip_no:"",trxId:"",cheQueNo:"", other_txn_type:"", attachment : null})
    setOpenPaymentCheckoutModal(false)
    setIsFileUploaded(false)
    setHidePaymentModeInput(false)
    setSelectedPosMachine([])
    setSelectedNovVirtualBank([])
  }

  // const handleSubmitCheckout = async () =>
  // {
  //   setCompletedPayment(true)
  //   setStartLoading(true)
  // }
  const handleSubmitCheckout = async () =>
  {
    props.setLoading(true)
    setOpenPaymentCheckoutModal(false)
    setPaymentInitiated(true)
    let transaction = 0;  
    try
    {
      const res = await HttpService.knowTransactionsNoforLead(projectDetails._id);
      // console.log('knowTransactionsNoforLead data',res.data)
      transaction = res.data.transactions;
      // console.log("this is transaction",transaction)
      setSequenceNumber(res.data.transactions)
    }
    catch(error)
    {
      props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
    }

      let amount = storeTenPercentAmount.amount * 100;
  
      let obj = {
        TransactionNumber: projectDetails.uniqueLeadNumber+'0'+transaction,   
        SequenceNumber: transaction + 1,                            
        AllowedPaymentMode: selectedPaymentMode.value,                              
        MerchantStorePosCode: selectedPosMachine.merchantStorePosCode,
        Amount: storeTenPercentAmount.amount,                                     
        // UserID: userDetails.name,                 
        UserID: userDetails._id,
        MerchantID: selectedPosMachine.merchantId ,                                
        SecurityToken: selectedPosMachine.securityToken,
        IMEI: selectedPosMachine.deviceIMEINo,
        AutoCancelDurationInMinutes: 5,
        // AutoCancelDurationInMinutes: 1,
        leadId: projectDetails._id,
        departmentId: projectDetails.departmentId,
        createdBy : userDetails._id,
        updatedBy:userDetails._id,
        customerName:projectDetails.customerId.name,
        customerEmail: projectDetails.customerId.email,
        customerAddress: projectDetails.customerId.address,
        customerMobile: projectDetails.customerId.contact_no,
        paymentPatnerId: paymentPatner,
        selectedPaymentMode : selectedPaymentMode.name,
        selectedPaymentModeType: selectedPaymentMode.type,
        erpProjectNo: erpProjectNo
      }

      // console.log('obj in handleSubmitCheckout', obj)

    try
    {
        setCompletedPayment(true)
        setStartLoading(true)
        const response = await HttpService.createTransactionPineLabs(obj);
        
        // console.log('response after submit checkout', response.data)
        // console.log('in submit checkout plutusTransactionReferenceID', plutusTransactionReferenceID)
        // console.log('in submit checkout response.data.PlutusTransactionReferenceID', response.data.PlutusTransactionReferenceID)
        
        if(response.data.ResponseCode == 0)
        {
          setPlutusTransactionReferenceID(response.data.PlutusTransactionReferenceID)
          currentPlutusTransactionReferenceID = response.data.PlutusTransactionReferenceID
          // setCompletedPayment(true)
          // setStartLoading(true)
          let GetPaymentStatusPayload = {}
          GetPaymentStatusPayload.MerchantID = selectedPosMachine.merchantId
          GetPaymentStatusPayload.IMEI = selectedPosMachine.deviceIMEINo
          GetPaymentStatusPayload.MerchantStorePosCode = selectedPosMachine.merchantStorePosCode
          GetPaymentStatusPayload.PlutusTransactionReferenceID = response.data.PlutusTransactionReferenceID

          // console.log('GetPaymentStatusPayload', GetPaymentStatusPayload)
          await handleCompletedPayment(GetPaymentStatusPayload.PlutusTransactionReferenceID)

        }
        else
        {
          setCompletedPayment(false)
          setStartLoading(false)
          handleCloseCheckout()
          props.setSnackInfo(response.data?.ResponseMessage,"error")
        }

        setPaymentInitiated(false)
          
      }
      catch(error)
      {
        props.setSnackInfo(error?.response?.data?.error || "TXN ALREADY COMPLETED" ,"error")
      }
    
    // setOpenPaymentCheckoutModal(false)
    props.setLoading(false)
  
  }

  const [paymentStatus, setpaymentStatus] = useState('Payment is in Progress')
  const [paymentCancelled, setPaymentCancelled] = useState(false)
  const [handleCompletedPaymentIsRunning, setHandleCompletedPaymentIsRunning] = useState(false)

  const handleCompletedPayment = async (PlutusTransactionReferenceID) =>
  {
    setHandleCompletedPaymentIsRunning(true)
    // console.log('PlutusTransactionReferenceID in handleCompletedPayment', PlutusTransactionReferenceID)
    props.setLoading(true)
    // let amount = storeTenPercentAmount.amount * 100;
    let amount = storeTenPercentAmount.amount;
    let obj = {
      MerchantID:selectedPosMachine.merchantId ,                                
      SecurityToken:selectedPosMachine.securityToken,
      IMEI: selectedPosMachine.deviceIMEINo,
      MerchantStorePosCode: selectedPosMachine.merchantStorePosCode,
      // PlutusTransactionReferenceID: plutusTransactionReferenceID,
      PlutusTransactionReferenceID: PlutusTransactionReferenceID,
      leadId: projectDetails._id,
      SequenceNumber: sequenceNumber + 1,
      createdBy : userDetails._id,
      updatedBy:userDetails._id,
      paymentPatnerId: paymentPatner,
      Amount: amount.toString(),
      customerName:projectDetails.customerId.name,
      customerEmail: projectDetails.customerId.email,
      customerMobile: projectDetails.customerId.contact_no,
      departmentId: projectDetails.departmentId,
      Stage:projectDetails.currentStage

    }
    try
    {
      // console.log('obj in handleCompletedPayment UI', obj)
      const response = await HttpService.addTransactionPineLabs(obj);
      // setCompletedPayment(false)
      // console.log('response in handleCompletedPayment', response.data)
      
      setCompletedPayment(false)
      setStartLoading(false)
      handleCloseCheckout()
      props.setLoading(false)

      if(response.data.ResponseCode == 0)
      {
        // console.log('Payment Received Succesfully',response.data.ResponseMessage )
        // setCompletedPayment(false)
        // setStartLoading(false)
        // handleCloseCheckout()
        // props.setLoading(false)
        props.setSnackInfo("Payment Received Succesfully", 'success')
        return
      }
      else
      {
        // console.log('payment not received')
        // props.setSnackInfo(response.data.ResponseMessage, 'error')
        props.setSnackInfo("Payment Not Received", 'error')
      }
      // else if(response.data.ResponseCode == 1001)
      // {
      //   console.log('calling handleCompletedPayment func again')
      //   console.log('paymentCancelled', paymentCancelled)

      //   console.log('currentPlutusTransactionReferenceID', currentPlutusTransactionReferenceID)
        
        // let timeoutId = setTimeout(handleCompletedPayment(currentPlutusTransactionReferenceID), 5000);

        
        // if(paymentCancelled)
        // {
          // clearTimeout(timeoutId); // This cancels the setTimeout
          // ActualCancelPayment()
          // console.log('handleCompletedPayment stopped')
        // }
        // if(!paymentCancelled)
        // {
          
        //   handleCompletedPayment(PlutusTransactionReferenceID)
        // }
        // else
        // {
        //   setHandleCompletedPaymentIsRunning(false)
        //   ActualCancelPayment()
        // }
      // }
      // else
      // {
      //   setCompletedPayment(false)
      //   props.setLoading(false)
      //   props.setSnackInfo("Payment Cancelled", 'error')
      //   return
      // }
    }
    catch(error)
    {
      props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  // const cancelPayment = async () =>
  // {
  //   console.log('cancelPayment func called')
  //   setPaymentCancelled(true)
  // }

  const cancelPayment = async () =>
  {
    // console.log('cancelPayment function triggered')
    props.setLoading(true)
    // setPaymentCancelled(true)
    let amount = storeTenPercentAmount.amount;
    let obj = {
      MerchantID:selectedPosMachine.merchantId ,                                
      SecurityToken:selectedPosMachine.securityToken,
      IMEI: selectedPosMachine.deviceIMEINo,
      MerchantStorePosCode: selectedPosMachine.merchantStorePosCode,
      PlutusTransactionReferenceID: plutusTransactionReferenceID,
      Amount: amount.toString(),
      paymentPatnerId: paymentPatner,     
      leadId: projectDetails._id,
      createdBy : userDetails._id,
      updatedBy:userDetails._id,
      SequenceNumber: sequenceNumber + 1,
      customerName:projectDetails.customerId.name,
      customerEmail:projectDetails.customerId.email,
      customerMobile:projectDetails.customerId.contact_no,
      departmentId: projectDetails.departmentId,
      Stage:projectDetails.currentStage

    }
    try{
      // console.log('obj', obj)
      const response = await HttpService.cancelTransactionPineLabs(obj);
      setCompletedPayment(false)
      setStartLoading(false)
      handleCloseCheckout()
      props.setLoading(false)
      // console.log('response', response.data)
      props.setSnackInfo(response.data.msg, 'error')
    }catch(error){
      props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }
  const handleMachine = (e) =>{
    setStoreTenPercentAmount({...storeTenPercentAmount,posmachine:e.target.value})
  }
  // close pine lab pop up deepak
  // const handleCloseCheckout = () =>{
  //   setCheckoutPopUp(false)
  // }
  // const handleSubmitCheckout = () =>{
  //   let res  = 'pro'
  //   setProcess(res)
  //   console.log(storeTenPercentAmount,"ten percent is called")
  //   setCheckoutPopUp(false)


  const handleOtherTransactionPayment = async () =>
  {
    // console.log('handleOtherTransactionPayment called')
    props.setLoading(true)
    setOpenPaymentCheckoutModal(false)
    let transaction = 0;  
    try
    {

      // console.log('projectDetails._id', projectDetails._id)
      
      const res = await HttpService.knowTransactionsNoforLead(projectDetails._id);

      // console.log('res', res)
      
      transaction = res.data.transactions;
      
      setSequenceNumber(res.data.transactions)
      
      // props.setSnackInfo("Payment Created Successfully!", "success")
    }
    catch(error)
    {
      console.log('error', error.message)
      console.error(error)
      props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
    }

    let amount = storeTenPercentAmount.amount ;
 
    let obj = {
      cashTransactionNumber : projectDetails.newUniqueLeadId + '0'+ transaction,
      Amount: amount.toString(),
      leadId: projectDetails._id,
      SequenceNumber: sequenceNumber + 1,
      createdBy : userDetails._id,
      updatedBy:userDetails._id,
      customerName:projectDetails.customerId.name,
      customerEmail: projectDetails.customerId.email,
      customerAddress: projectDetails.customerId.address,
      customerMobile: projectDetails.customerId.contact_no,
      slip_no : otherPaymentMethod.slip_no,
      trxId :otherPaymentMethod.trxId,
      other_txn_type: otherPaymentMethod.other_txn_type,
      nonVirtualBankName: selectedNovVirtualBank.name ? selectedNovVirtualBank.name : '',
      cheQueNo:otherPaymentMethod.cheQueNo,
      attachment :otherPaymentMethod.attachment,
      paymentPatnerId: internalPaymentPartner,
      selectedPaymentMode : selectedPaymentMode.name,
      selectedPaymentModeType: selectedPaymentMode.type,
      departmentId: projectDetails.departmentId,
      Stage:projectDetails.currentStage,
      erpProjectNo: erpProjectNo
   }

  //  console.log('obj', obj)

   try
   {
    const response = await HttpService.addOtherTransaction(obj);
    setPaymentPatner("")
    setSelectedPaymentMode([])
    setOtherPaymentMethod({slip_no:"",trxId:"",cheQueNo:"", other_txn_type:"", attachment : null})
    setCompletedPayment(false)
    // setOpenPaymentCheckoutModal(false)
    setIsFileUploaded(false)
    setSelectedNovVirtualBank([])
    props.setSnackInfo("Payment Created Successfully!", "success")
  }
  catch(error)
  {
    props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
    setPaymentPatner("")
    setSelectedPaymentMode([])
    setOtherPaymentMethod({slip_no:"",trxId:"",cheQueNo:"", other_txn_type:"", attachment : null})
  }
  props.setLoading(false)
}

const [showPaymentIdPreview, setShowPaymentIdPreview] = useState(false)

const handlePaymentIDPreviewClick = () =>
{
  // console.log('handlePaymentIDPreviewClick function called')
  console.log('selectedPaymentMode', selectedPaymentMode)
  setShowPaymentIdPreview(true)
}

const handle_payment_mode_selection = (value) =>
{
  // console.log('value', value)
  // console.log('paymentModes', paymentModes)

  // selectedPaymentMode
  
  setSelectedPaymentMode(value)
}

const handle_non_virtual_bank_selection = (value) =>
  {
    // console.log('value', value)
    // console.log('paymentModes', paymentModes)

    // selectedPaymentMode
    
    setSelectedNovVirtualBank(value)
  }

function formatAmountWithCommas(amount) {
  return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
// when Qutation change Request then select radio button and clone any quotatio version
      const changeEditQuotation = async (data) =>{
        setPrevQtTotalAmount(data?.grandTotal.toFixed(2))
        let lastVersion = quotationList[quotationList.length - 1];
        let obj = {
          versionId: (data?.designQuotationVersionId ? data?.designQuotationVersionId : lastVersion._id),
          leadId: leadId,
          lastVersionNo: lastVersion.version,
        }
        props.setLoading(true)
        try {
          let res =  await HttpService.cloneSelectedQuotation(obj);                                                                               
          SetQuotationTotalAmount(Math.round(res.data.quotationTotalAmount));
          getAllQuotationsForProject();
          // if (response.data) {
          //   props.setSnackInfo(`${response.data}`, "success")
          // } else {
          // }
            // props.setSnackInfo("Successfully", "success"); // Show error message
        } catch (error) {
          props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
        }
        // setEditQt(i);
        props.setLoading(false);
      }

      let categoryObjDataArray = [];
      const calculateDamageDelayOtherValue = (value,e)=> {
        categoryObjDataArray = [...addDataState]
        let object = {
          isDelete: false,
          docType: "ProductMaster",
          priority: 1,
          itemId: {
            code: "0",
            createdAt : "2024-04-18T11:52:59.669+0000",
            deleted : false,
            depth: 0,
            length: 0,
            width : 0,
            description : "",
            finishInclude : false,
            isActive : true,
            isCHApproval : false,
            isComplimentaryItem : false,
            moqInSqft : 1,
            name : "",
            newpricePerUnit : 0,
            orderType : "Project",
            plyInclude : false,
            pricePerUnit : 0,
            priority: 0,
            quantity: 0,
            scopeId: "662752df73828b62cb2de79b",
            status: "Active",
            sublinitTune: 'mm',
            unitId: "61338464h421454h9dd7d86c",
            updatedAt: "2024-04-17T05:37:50.599+0000",
            _v: 0,
            subUnitType: "Qty",
            _id: ""
          },
          projectRCMasterId : "64d2380c4d94ac1a527b8815",
          rate : 0,
          _v:0,
          _id : "64d2380c4d94ac1a527b882a"
        }

        let obj = {...damageDelayOtherValue}
        if(e === 'Delay Penality'){
          object.itemId.description = e;
          object.itemId.name = e;
          object.itemId._id = "66278ef073828b62cb2de7b3";
          object.rate = +value;
          setDamageDelayOtherValue((prev) => ({...prev, delayPenality : +value}))
          obj.delayPenalityCheck = true ;
          obj.delayPenality = +value;
        } else if(e === 'Damages'){
          object.itemId.description = e;
          object.itemId.name = e;
          object.itemId._id = "6627b4ee73828b62cb2de7bf";
          object.rate = +value;
          setDamageDelayOtherValue((prev) => ({...prev, damages : +value}))
            obj.damages = +value;
            obj.damagesCheck = true;
        } else if(e === 'Good Will Discount'){
          object.itemId.description = e;
          object.itemId.name = e;
          object.itemId._id = "6627b58773828b62cb2de7c0";
          object.rate = +value;
          setDamageDelayOtherValue((prev) => ({...prev, goodWillDiscount : +value}))
          obj.goodWillDiscount = +value;
          obj.goodWillDiscountCheck = true;
        } else if(e === 'Other'){
          object.itemId.description = 'Others';
          object.itemId.name = e;
          object.itemId._id = "6627b5a673828b62cb2de7c1";
          object.rate = +value;
          setDamageDelayOtherValue((prev) => ({...prev, other : +value}))
          obj.other = +value;
          obj.otherCheck = true;
        } else if(e === 'Referral Bonus'){
          object.itemId.description = e;
          object.itemId.name = e;
          object.itemId._id = "6627b5e973828b62cb2de7c2";
          object.rate = +value;
          setDamageDelayOtherValue((prev) => ({...prev, referralBonus : +value}))
          obj.referralBonus = +value;
          obj.referralBonusCheck = true;
        }
          const existingItem = categoryObjDataArray.find(item => item.itemId.name === object.itemId.name);
          if (existingItem) {
              // Update the existing item with the new values
              const updatedArray = categoryObjDataArray.map(item => {
                  if (item.itemId.name === object.itemId.name) {
                      return object; // Replace the existing item with the new item
                  }
                  return item; // Keep other items unchanged
              });
              categoryObjDataArray = updatedArray;
          } else {
              categoryObjDataArray.push(object);
          }
          setAddDataState(categoryObjDataArray)
          calculateTotalValue(obj);
          // console.log("categoryObjDataArray",categoryObjDataArray)
      }

      const handleCheckBox = (e) => {
        setDamageDelayOtherValue((prev) => {
          let updatedState = { ...prev };
      
          switch (e) {
            case 'Delay Penality':
              updatedState = {
                ...updatedState,
                delayPenalityCheck: !prev.delayPenalityCheck,
                delayPenality: prev.delayPenalityCheck ? 0 : prev.delayPenality
              };
              calculateDamageDelayOtherValue(updatedState.delayPenality, e);
              break;
            case 'Damages':
              updatedState = {
                ...updatedState,
                damagesCheck: !prev.damagesCheck,
                damages: prev.damagesCheck ? 0 : prev.damages
              };
              calculateDamageDelayOtherValue(updatedState.damages, e);
              break;
            case 'Good Will Discount':
              updatedState = {
                ...updatedState,
                goodWillDiscountCheck: !prev.goodWillDiscountCheck,
                goodWillDiscount: prev.goodWillDiscountCheck ? 0 : prev.goodWillDiscount
              };
              calculateDamageDelayOtherValue(updatedState.goodWillDiscount, e);
              break;
            case 'Other':
              updatedState = {
                ...updatedState,
                otherCheck: !prev.otherCheck,
                other: prev.otherCheck ? 0 : prev.other
              };
              calculateDamageDelayOtherValue(updatedState.other, e);
              break;
            case 'Referral Bonus':
              updatedState = {
                ...updatedState,
                referralBonusCheck: !prev.referralBonusCheck,
                referralBonus: prev.referralBonusCheck ? 0 : prev.referralBonus
              };
              calculateDamageDelayOtherValue(updatedState.referralBonus, e);
              break;
            default:
              break;
          }
          // After updating state, call handlePendingDueValue with the updated state
          calculateTotalValue(updatedState);
          return updatedState;
        });
      };

        const calculateTotalValue = (obj) => {
          let total = 0;
          if (obj?.delayPenalityCheck) {
            total += obj.delayPenality;
          }
          if (obj?.damagesCheck) {
            total += obj.damages;
          }
          if (obj?.goodWillDiscountCheck) {
            total += obj.goodWillDiscount;
          }
          if (obj?.otherCheck) {
            total += obj.other;
          }
          if (obj?.referralBonusCheck) {
            total += obj.referralBonus;
          }
          
          const balnceSheetPendingDueTotal = parseInt((balansheSheetQtTotalPrice - amountPaid - total).toFixed(2));
          setBalanceSheetData((prev)=>({...prev, pendingDue: balnceSheetPendingDueTotal}))
          setDelayToReferralCategoryTotal(total);
          return total;
        };

  // get total amount paid for this lead
  const getAmountPaid = async (totalBalanceSheetAmount) => {
    try {
      const res = await HttpService.getAmountPaid(leadId);
      setAmountPaid(res.data.totalPaymentReceived);
      let balnceSheetPendingDueTotal = 0;
      let data = projectDetails.sentForQuotationChangeApproval?.amountPaid
      if ((projectDetails?.delayToReferralBonusCategory?.totalAmountOfCategory !== undefined && projectDetails?.delayToReferralBonusCategory?.totalAmountOfCategory !== 0) || (data !== res.data.totalPaymentReceived) && (data !== 0 && data !== undefined)) {
        setAmountPaid(projectDetails.sentForQuotationChangeApproval?.amountPaid)
        balnceSheetPendingDueTotal = (totalBalanceSheetAmount - projectDetails.sentForQuotationChangeApproval?.amountPaid);
        balnceSheetPendingDueTotal = balnceSheetPendingDueTotal - projectDetails?.delayToReferralBonusCategory?.totalAmountOfCategory
      }
      else {
        balnceSheetPendingDueTotal = parseInt(totalBalanceSheetAmount - res.data.totalPaymentReceived).toFixed(2);
      }
      setBalanceSheetData((prev) => ({ ...prev, pendingDue: balnceSheetPendingDueTotal }));
    }
    catch (err) {
      console.log(err);
    }
  }

  const handleAmountPaid = (amountPaidValue)=> {
    setAmountPaid(amountPaidValue)
    const balnceSheetPendingDueTotal = Number(balansheSheetQtTotalPrice - amountPaidValue - delayToReferralCategoryTotal).toFixed(2)
    setBalanceSheetData((prev)=>({...prev, pendingDue: balnceSheetPendingDueTotal}));
  }

  const handleDownloadPdf = () => {
    // Create a new jsPDF instance with landscape orientation
    const doc = new jsPDF('landscape', 'mm', 'a4');

    // Load the image
    const imageUrl = `${decorpotLogo}`; // Provide the path to your image
    const imageWidth = 80; // Width of the image
    const imageHeight = 20; // Height of the image
    const imageX = (doc.internal.pageSize.getWidth() - imageWidth) / 2;
    const imageY = 10;

    // Add the image to the PDF
    doc.addImage(imageUrl, 'JPEG', imageX, imageY, imageWidth, imageHeight);

    // Adjust the Y position after the image
    let y = imageY + imageHeight + 10;

    // Add address text below the image
    const address = [
        "No 17, 2nd floor, 18th Cross Rd, Sector 4, HSR Layout, Bengaluru",
        "Karnataka 560102"
    ];
    address.forEach((line, index) => {
        doc.text(line, doc.internal.pageSize.getWidth() / 2, y + (index * 6), { align: 'center' });
    });

    y += 20; // Adjust Y position after the address

    // Add the title
    doc.setFontSize(14);
    doc.text("Table Content", doc.internal.pageSize.getWidth() / 2, y, { align: 'center' });
    y += 10;

    // Legend
    const tableData = [];
    const tableHeaders = [
        ["New line item", "Yellow", "Yellow"],
        ["Removed line item(Descope)", "Orange" , "Orange"],
        ["Change in Finish", "Blue","lightblue"],
        ["Increase Area", "Green",  "lightgreen"],
        ["Decrease Area", "Red", "pink"]
    ];

    tableHeaders.forEach((header) => {
        tableData.push([
            header[0],
            { content: header[1], styles: { fillColor: header[2] } }
        ]);
    });

    doc.autoTable({
        head: [['Description', 'Color']],
        body: tableData,
        startY: y,
        margin: { left: 10, right: 10 },
        tableWidth: 'auto', // Adjust table width
        styles: {
            fillColor: [255, 255, 255], // Default background color
            textColor: [0, 0, 0], // Default text color
            fontSize: 10,
        },
        headStyles: {
            fillColor: [200, 200, 200], // Header background color
            textColor: [0, 0, 0], // Header text color
            fontSize: 12,
        },
    });

    y = doc.lastAutoTable.finalY + 10;

    comapredData?.forEach((data, index) => {
        // Add space name as title
        doc.setFontSize(12);
        doc.text(data.spaceName, doc.internal.pageSize.getWidth() / 2, y, { align: 'center' });
        y += 10; // Move down after the title

        // Helper function to split text into lines of four words
        const splitTextIntoLines = (text, wordsPerLine) => {
            const words = text.split(' ');
            const lines = [];
            for (let i = 0; i < words.length; i += wordsPerLine) {
                lines.push(words.slice(i, i + wordsPerLine).join(' '));
            }
            return lines.join('\n');
        };

        // Prepare the table rows
        const tableRows = data.products.sort((a, b) => a['priority'] - b['priority']).map((data1, i) => {
            let rowColor = '';
            if (data1?.new) rowColor = 'yellow';
            else if (data1?.removed) rowColor = 'orange';

            return [
                { content: i + 1, styles: { fillColor: rowColor } },
                { content: data.otherQuotationVersion ? data.otherQuotationVersion : data.baseQuotationVersion, styles: { fillColor: rowColor } },
                { content: splitTextIntoLines(data1?.product?.name, 4), styles: { fillColor: rowColor } },
                { content: data1?.priority, styles: { fillColor: rowColor } },
                { content: data1?.product?.finishInclude ? data1?.finishType?.name : '-', styles: { fillColor: (data1?.finishChanged || data1?.finishAreaPositive || data1?.finishAreaNegative) ? 'lightblue' : rowColor } },
                { content: data1?.totalPricePerUnit, styles: { fillColor: rowColor } },
                { content: data1?.product?.name.includes('Tiles fixing charges for tiles') ? data1?.fixingCharge : '-', styles: { fillColor: rowColor } },
                { content: data1?.product.depth, styles: { fillColor: rowColor } },
                { content: data1?.width, styles: { fillColor: rowColor } },
                { content: data1?.length, styles: { fillColor: rowColor } },
                { content: data1?.area ? data1?.area.toFixed(2) : '-', styles: { fillColor: (data1?.areaChangedPositive || data1?.finishAreaPositive) ? 'lightgreen' : (data1?.areaChangedNegative || data1?.finishAreaNegative) ? 'pink' : rowColor } },
                { content: `${data1?.removed ? `-${commaSeparateAmount(data1?.productPrice)}` : commaSeparateAmount(data1?.productPrice)}`, styles: { fillColor: rowColor } },
            ];
        });

        // Calculate total for the current spaceName
        const spaceTotal = data.products.reduce((acc, crr) => acc + (crr.removed ? -crr.productPrice : crr.productPrice), 0);

        // Append the total row
        tableRows.push([
            { content: '', colSpan: 10, styles: { halign: 'right' } },
            { content: 'Total', styles: { halign: 'right' } },
            { content: `${commaSeparateAmount(spaceTotal)}`, styles: { halign: 'center' } }
        ]);

        // Add table to PDF
        doc.autoTable({
            head: [['Sl No', 'Quotation Name', 'Name', 'Priority', 'Finish', 'Price Per Unit', 'No/Qty', 'Depth', 'Width', 'Length', 'Area/Qty', 'Price']],
            body: tableRows,
            startY: y,
            margin: { left: 10, right: 10 },
            tableWidth: 'auto', // Adjust table width
            theme: 'striped',
            styles: {
                halign: 'center',
                valign: 'middle',
            },
            headStyles: {
                fillColor: [63, 81, 181]
            },
            alternateRowStyles: {
                fillColor: [245, 245, 245]
            },
            didDrawPage: (data) => {
                y = data.cursor.y + 10;
            },
        });

        y = doc.lastAutoTable.finalY + 10; // Update y position for the next section
    });
    let currentDate = new Date()
    
    doc.save(`${projectDetails?.customerId?.name}_${moment(currentDate).format('DD-MM-YYYY')}`+'_'+"summary.pdf");
};


  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-around", marginBottom: 10 }}>
        <div><b> Client: {projectDetails?.customerId?.name}</b></div>
        <div><b>Lead No: {projectDetails?.lead_no}</b></div>
        <Link to={`/project-logs/${projectDetails._id}`} className="btn btn-primary customerActionBtn">Logs</Link>
        {(userDetails?.departmentId === constantDepartmentId.design || userDetails?.departmentId === constantDepartmentId.sales) && (
          <FormControlLabel
            value="end"
            style={{ marginLeft: "5px", marginTop: "10px" }}
            control={
              <Switch
                color="primary"
                checked={compareMode}
                onChange={handleCompareModeChange}
              />
            }
            label="Compare"
          />
        )}
      </div>
      {/* {
        userDetails?.roles?.find(role => role.name === ROLENAME.DesignManager || role.name === ROLENAME.OperationDesignManager || role.name === ROLENAME.DesignHead) && leadInOutOfDesignStage
        &&
        <div>
          <Button onClick={() => openEditProjectModal(true)} variant="outlined" color="primary" style={{ marginRight: 10, marginLeft: 30, marginBottom: 20 }}>Create New Project</Button>
        </div>
      } */}

      {/* Quotation Version Lists */}
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="customized table">
          <TableHead>
            <TableRow>
              { compareMode && (
                <StyledTableCell align="center">Select</StyledTableCell>
              )}
            {/* {(projectDetails.contractCustomerApproved && projectDetails.contractFinanceApproved && projectDetails.designStages === 'Design Sign-off') &&
            <StyledTableCell align="center">Change Quotation</StyledTableCell>
            } */}
              <StyledTableCell align="center">Sl No</StyledTableCell>
              <StyledTableCell align="center">Quotation Name</StyledTableCell>
              <StyledTableCell align="center">Last Updated</StyledTableCell>
              <StyledTableCell align="center">Created By</StyledTableCell>
              <StyledTableCell align="center">Created At</StyledTableCell>
              <StyledTableCell align="center">Finalized</StyledTableCell>
              <StyledTableCell align="center">Lead Amount</StyledTableCell>
              <StyledTableCell align="center" >Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {quotationList?.map((data, i) =>
              <StyledTableRow key={i + 1}>
                {compareMode && (
                  <StyledTableCell align="center">
                    <FormControl component="fieldset">
                      <RadioGroup
                        // aria-label={`select-${data.id}`}
                        // name={`select-${data.id}`}
                        // value={selectedQuotations.includes(data)}
                        // onChange={(e) => handleQuotationSelection(e, data, i + 1)}
                      >
                        <FormControlLabel
                           key={i}
                           value={i + 1}
                           control={<Radio checked={selectedQuotations.includes(data)} />}
                           onChange={(e) => handleQuotationSelection(e, data, i+1)}
                        />
                      </RadioGroup>
                    </FormControl>
                  </StyledTableCell>
                )}
            {/* {(projectDetails.contractCustomerApproved && projectDetails.contractFinanceApproved && projectDetails.designStages === 'Design Sign-off') &&
              <StyledTableCell align="center">
                <FormControl component="fieldset" align="center">
                    <RadioGroup
                      aria-label={`select-${data.id}`}  // Unique aria-label for each row
                      name={`select-${data.id}`} 
                      value={editQt}
                      onChange={(e)=>changeEditQuotation(e,data,i+1)}
                    >
                    <FormControlLabel disabled = {projectDetails?.sentForQuotationChangeApproval?.isSendForApproval} value = {i+1} control={<Radio />}/>
                    </RadioGroup>
                 </FormControl>
               </StyledTableCell>
              } */}
              <StyledTableCell>{i + 1}</StyledTableCell>
              {
                data?.s3Location ?
                <StyledTableCell align="center" style={{ cursor: "pointer" }}>
                  <div style={{ justifyContent: "space-between" }}>
                    {
                      userDetails?.roles?.find(role => role.name !== ROLENAME.ProcurementExecutive) ?

                      <span style={{ textDecoration: "underline", marginRight: 10 }} onClick={() => window.open(data?.s3Location)}> {data?.balanceSheetDataObj?.positiveNegativeCr ? 
                        ( <span>Balance Sheet</span>) : ( <span>{data?.version}</span>)} </span> :
                      
                      data.maskedQuotationS3 ?

                      <span style={{ marginRight: 10 }}>
                        {data?.version}
                        <Tooltip title="Quotation" style={{ marginLeft: 10 }}>
                          <DownloadOutlined onClick={() => window.open(data?.maskedQuotationS3)} />
                        </Tooltip>
                      </span> :

                      <span style={{ marginRight: 10 }}>
                        {data?.version}
                        <Tooltip title="Download Quotation" style={{ marginLeft: 10 }}>
                          <DownloadOutlined onClick={() => downloadMaskedQuotation(data)} />
                        </Tooltip>
                      </span>
                    }
                    <span onClick={() => generateQtExcel(data?._id)}> {data?.balanceSheetDataObj?.positiveNegativeCr === false && <FileExcelOutlined /> }</span>
                    <CSVLink
                      headers={headers}
                      filename={projectDetails?.customerId?.name.split(' ')[0] + '_' + data?.version + '.csv'}
                      data={csvData}
                      ref={buttonRef}
                    />
                </div>
                </StyledTableCell> :
              
                <StyledTableCell >{data?.version}</StyledTableCell>
              }
              <StyledTableCell>{moment(data?.updatedAt).format('DD-MM-YYYY')}</StyledTableCell>
              <StyledTableCell>{data?.createdBy?.name}</StyledTableCell>
              <StyledTableCell>{moment(data?.createdAt).format('DD-MM-YYYY')}</StyledTableCell>
              <StyledTableCell>{data?.s3Location ? 'Yes' : "No"}</StyledTableCell>
              <StyledTableCell align="center">
                ₹ {commaSeparateAmount(data?.grandTotal)}
              </StyledTableCell>

              {/* Start - Payment Integration */}
              <StyledTableCell>
                 {/* <Button className={classes.yesButton} variant="contained"  onClick={() => handle_initiate_payment(data)}>
                   Initiate Payment
                  </Button> */}
                  <Button className={classes.initiatePaymentButton} variant="contained"  onClick={() => handle_initiate_payment(data)}>
                  Initiate POS Payment
                  </Button>
                  <Button className={classes.recordPaymentButton} variant="contained"  onClick={() => handle_record_payment(data)}>
                  Record Payment
                  </Button>
             </StyledTableCell>
             {/* End - Payment Integration */}
             
            </StyledTableRow>)}
            {/* last row of quotation view edit and clone button */}
            {(projectDetails.contractCustomerApproved && projectDetails.contractFinanceApproved && projectDetails.designStages === 'Design Sign-off')
              ? null
              :

              userDetails?.roles?.find(role => role.name !== ROLENAME.ProcurementExecutive) &&
              <StyledTableRow key={quotationList.length + 1}>
                <StyledTableCell>{quotationList.length + 1}</StyledTableCell>
                <StyledTableCell >
                  <div style={{ justifyContent: "space-between" }}>
                    <span style={{ margin: 'auto' }}> {projectDetails?.nextVersionNo}</span>
                    <Tooltip title="Edit">
                      <IconButton color="primary" aria-label="edit" onClick={openCreateQuotationModal}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    {
                      quotationList.length !== 0 &&
                      <Tooltip title="Clone">
                        <IconButton aria-label="clone" onClick={() => setOpenSelectQuotationVersion(true)}>
                          <FileCopyIcon />
                        </IconButton>
                      </Tooltip>
                    }
                  </div>
                </StyledTableCell>
                <StyledTableCell>NA</StyledTableCell>
                <StyledTableCell>NA</StyledTableCell>
                <StyledTableCell>NA</StyledTableCell>
                <StyledTableCell>No</StyledTableCell>
                <StyledTableCell colSpan={2}>NA</StyledTableCell>
                <StyledTableCell>NA</StyledTableCell>
              </StyledTableRow>
            }
          </TableBody>
        </Table>
      </TableContainer>
      {compareMode && (
        <>
          <Button style={{ marginTop: '20px'}} variant='contained' color='primary' onClick={compareQuotations}>
            Compare
          </Button>
        </>
      )}
      {
        (projectDetails.contractCustomerApproved && projectDetails.contractFinanceApproved && projectDetails.designStages === 'Design Sign-off') &&
        <Button variant="contained" color="primary" style={{marginTop: 10, float: "right"}} onClick={(e)=>openCreateQuotationModal(e,projectDetails?.sentForQuotationChangeApproval?.isSendForApproval ? "Go To Finalize" : "Raise Change Request")}>
          {projectDetails?.sentForQuotationChangeApproval?.isSendForApproval ? "Go To Finalize" : "Raise Change Request"}
        </Button>
      }

      {/* Create Quotation Modal */}
      <Modal
        title="Create Quotation"
        centered
        visible={showCreateQuotationModal}
        onCancel={() => setShowCreateQuotationModal(false)}
        width={1000}
        maskClosable={false}
        footer={null}
      >
        <Autocomplete
          value={selectedCatergory}
          onChange={(e, v) => handleCategorySelect(v)}
          options={categoryList}
          // getOptionLabel={(option) => option.name}
          getOptionLabel={(option) => {
            if (option.name === 'POST DESIGN SIGN-OFF CHANGES') {
              return '';   // Return an empty string to hide this option
            } else {
              return option.name;
            }
          }}
          style={{ width: 350, marginBottom: 10 , display : (projectDetails.contractCustomerApproved && projectDetails.contractFinanceApproved && projectDetails.designStages === 'Design Sign-off') ? "none" : "" }}
          renderInput={(params) => <TextField {...params} label="Select New Category" fullWidth variant="outlined" />}
        />
        <div style={{ float: "right", marginBottom: 10 }} >
          <Button variant="contained" color="primary" style={{ marginRight: 5 }} onClick={() => saveQuotationChanges()} disabled={value === null || projectComponentProducts?.[chosenCategory]?.item.length === undefined || projectComponentProducts?.[chosenCategory]?.item.length === 0}>Save Changes</Button>
          <Button variant="contained" color="primary" onClick={() => previewQuotation()} disabled={value === null || projectComponentProducts?.[chosenCategory]?.item.length === undefined || projectComponentProducts?.[chosenCategory]?.item.length === 0} >Preview Quotation</Button>
        </div>
        <div style={{ "margin-top": 20, display : (projectDetails.contractCustomerApproved && projectDetails.contractFinanceApproved && projectDetails.designStages === 'Design Sign-off') ? "none" : "" }}>
          <span><b>Existing categories in quotation</b></span>
        </div>
        <AppBar position="static" color="default" style={{ marginBottom: 25 }}>
          <Tabs
            value={value}
            onChange={handleChange}
            onDoubleClick={() => handleDoubleClick(value)}
            variant="scrollable"
            scrollButtons="on"
            indicatorColor="primary"
            textColor="primary"
            aria-label="scrollable force tabs example"
          >
            {projectDetails.contractCustomerApproved &&
              projectDetails.contractFinanceApproved &&
              projectDetails.designStages === 'Design Sign-off' ?
              (projectComponents.map(comp =>
                <Tab
                  style={{ display: comp?.categoryId?.name === 'POST DESIGN SIGN-OFF CHANGES' ? "" : "none" }}
                  label={"Post Design Sign-off Changes"}
                />
              )) :
              (projectComponents.map(comp =>
                <Tab
                  label={comp?.categoryId?.name}
                // icon={<DeleteForeverIcon style={{ cursor: "pointer", color: 'red', fontSize: "15px", borderBottom: "2px solid #000", marginBottom: "20px" }} onClick={() => removeCategoryFromList(comp._id)} />}
                />
              ))
            }
          </Tabs>
        </AppBar>
        {value !== null && <>
          <div style={{ marginTop: 10, marginBottom: 10, display: "flex", justifyContent: "space-between" }} >
            <Button variant="contained" color="secondary" onClick={() => removeCategoryFromList(chosenCategoryId, chosenCategory)}
              style={{ display: (projectDetails.contractCustomerApproved && projectDetails.contractFinanceApproved && projectDetails.designStages === 'Design Sign-off') ? "none" : ""}}>
              Delete Category
            </Button>
          </div>
          <div style={{ marginTop: 10, marginBottom: 10, display: "flex", justifyContent: "space-between" }}>
            {/* Product Material Select  */}
            <Autocomplete
              onChange={(e, v) => handleProductSelect(v)}
              options={productsList}
              disabled = {projectDetails?.sentForQuotationChangeApproval?.isSendForApproval}
              getOptionLabel={(options) => options?.itemId?.name}
              style={{ width: 350, marginBottom: 10 }}
              renderInput={(params) => <TextField {...params} label="Add Items" fullWidth variant="outlined" size="small" />}
            />
            {/* Unit Changes */}
            <Autocomplete
              value={projectComponentProducts?.[chosenCategory]?.unitSelect}
              disabled = {projectDetails?.sentForQuotationChangeApproval?.isSendForApproval}
              onChange={(e, v) => unitChange(v)}
              options={unitList}
              getOptionLabel={(option) => option?.name}
              style={{ width: 350, marginBottom: 10 }}
              renderInput={(params) => <TextField {...params} label="Choose Unit" fullWidth variant="outlined" size="small" />}
            />
            {/* Ply Select */}
            <Autocomplete
              value={projectComponentProducts?.[chosenCategory]?.plyType}
              disabled = {projectDetails?.sentForQuotationChangeApproval?.isSendForApproval}
              onChange={(e, v) => handlePlySelect(v)}
              options={plyList}
              getOptionLabel={(option) => option?.name}
              style={{ width: 350, marginBottom: 10 }}
              renderInput={(params) => <TextField {...params} label="Choose Ply" fullWidth variant="outlined" size="small" />}
            />
          </div>
          <TableContainer component={Paper}>
            <Table className={classes.table} size="small" aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Sl No</StyledTableCell>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell>Priority</StyledTableCell>
                  <StyledTableCell>Finish</StyledTableCell>
                  <StyledTableCell>Price Per Unit</StyledTableCell>
                  <StyledTableCell>No/Qty</StyledTableCell>
                  <StyledTableCell>Depth</StyledTableCell>
                  <StyledTableCell>Width</StyledTableCell>
                  <StyledTableCell>Length</StyledTableCell>
                  <StyledTableCell>Area(SqFt)/Qty</StyledTableCell>
                  <StyledTableCell>Price</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {projectComponentProducts.hasOwnProperty(chosenCategory) && projectComponentProducts?.[chosenCategory]?.item?.map((data, i) => {

                 if (['A0', 'A1', 'A2', 'A3','A4'].includes(data?.product?.code)) {
                    return null; // Skip rendering this row
                  }
                return (
                 <StyledTableRow key={i + 1}>
                  <StyledTableCell component="th" scope="row">{i + 1}</StyledTableCell>
                  {/* Name */}
                  <StyledTableCell component="th" scope="row">
                    <div style={{ display: "flex", justifyContent: "space-around" }}>
                      <span>{data?.product?.name}</span>
                      {/* <DeleteForeverIcon style={{ cursor: "pointer" }} onClick={() => removeItemFromList(i)} /> */}
                      {(projectDetails.contractCustomerApproved &&
                      projectDetails.contractFinanceApproved &&
                      projectDetails.designStages === 'Design Sign-off') 
                      ? 
                      (projectDetails.contractCustomerApproved &&
                      projectDetails.contractFinanceApproved &&
                      projectDetails.designStages === 'Design Sign-off' &&
                      data?.newItemAdded) 
                      ? (
                      <DeleteForeverIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => removeItemFromList(i)}
                      />
                      ): (
                      <DeleteForeverIcon
                        style={{ cursor: "not-allowed", color: "grey" }}
                        onClick={() =>{} }
                      />
                    )
                    :
                      (  <DeleteForeverIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => removeItemFromList(i)}
                      />
                  ) }
                    </div>
                  </StyledTableCell>
                  {/* Priority */}
                  <StyledTableCell component="th" scope="row">
                    <TextField name="priority" size="small" value={Number(data.priority)}
                      onKeyPress={(e) => sortPriorityData(e)}
                      onBlur={(e) => sortPriorityOnOnBlur(e)}
                      disabled={
                        (projectDetails.contractCustomerApproved &&
                          projectDetails.contractFinanceApproved &&
                          projectDetails.designStages === 'Design Sign-off') &&
                        (data?.newItemAdded ? false : true)
                      }
                      onChange={(e) => handleChangePriority(e, i)} type="number" style={{ marginBottom: 10 }} variant="outlined" />
                  </StyledTableCell>
                  {/* Finish Select */}
                  <StyledTableCell component="th" scope="row">{(data.subUnitType === 'Area' && data?.product?.finishInclude) && <Autocomplete
                    value={projectComponentProducts?.[chosenCategory]?.item[i]['finishType']}
                    onChange={(e, v) => handleFinishSelect(v, i)}
                    options={finshesList}
                    getOptionLabel={(option) => option?.name}
                    style={{ width: 250, marginBottom: 10 }}
                      disabled={
                        (projectDetails.contractCustomerApproved &&
                          projectDetails.contractFinanceApproved &&
                          projectDetails.designStages === 'Design Sign-off') &&
                        (data?.newItemAdded ? false : true)    
                      }
                    renderInput={(params) => <TextField {...params} label="Choose Laminate" fullWidth size="small" variant="outlined" />}
                  />}</StyledTableCell>

                  {/* Per unit for subtype Area and Qty */}
                  {data.subUnitType === 'Area' ? <StyledTableCell component="th" scope="row">{data?.totalPricePerUnit}</StyledTableCell> : <StyledTableCell component="th" scope="row">{data?.totalPricePerUnit}</StyledTableCell>}

                  {/* No/Qty */}
                  {data?.product?.name.includes('TIles fixing charges for tiles') ? <StyledTableCell component="th" scope="row">
                    <TextField name="fixingCharge" size="small" 
                      disabled={
                        (projectDetails.contractCustomerApproved &&
                          projectDetails.contractFinanceApproved &&
                          projectDetails.designStages === 'Design Sign-off') &&
                        (data?.newItemAdded ? false : true)
                      }
                      value={data?.fixingCharge}
                      // onChange={((e) => e.target.value >= 1 ? handleAreaQtyChange(e, i, 'l') : 1}
                      onChange={(e) => {
                        const newValue = (
                          projectDetails.contractCustomerApproved &&
                          projectDetails.contractFinanceApproved &&
                          projectDetails.designStages === 'Design Sign-off'
                        ) ? handleAreaQtyChange(e, i, 'l') : (e.target.value >= 1 ? handleAreaQtyChange(e, i, 'l') : 1);
                      }}
                      type="number" 
                      style={{ marginBottom: 10 }} 
                      fullWidth
                      variant="outlined" />
                  </StyledTableCell> : <StyledTableCell component="th" scope="row">-</StyledTableCell>}

                  {/* Depth */}
                  {(data?.product?.name.includes('False ceiling (Peripheral with Cove') || data?.product?.name.includes('False ceiling (Peripheral, No Cove')) ? <StyledTableCell component="th" scope="row">
                    <TextField
                    onBlur={(e) => handleAreaQtyCheck(e, i, 'l')}
                    onChange={(e) => handleAreaQtyChange(e, i, 'l')}
                    name="depth" size="small" value={data?.depth}
                     disabled={
                        (projectDetails.contractCustomerApproved &&
                          projectDetails.contractFinanceApproved &&
                          projectDetails.designStages === 'Design Sign-off') &&
                        (data?.newItemAdded ? false : true)
                      }
                    type="number" style={{ marginBottom: 10 }} fullWidth variant="outlined" />
                  </StyledTableCell> : <StyledTableCell component="th" scope="row">-</StyledTableCell>}

                  {/* Width */}
                  {(data.subUnitType !== 'Area') || data?.product?.name.includes('TIles fixing charges for tiles') || data?.product?.unitType == rftUnitTypeId || data?.product?.name.toLowerCase().includes('false ceiling rafter')?
                  <StyledTableCell component="th" scope="row">-</StyledTableCell> :
                  <StyledTableCell component="th" scope="row">
                    <TextField
                    onBlur={(e) => handleAreaQtyCheck(e, i, 'l')}
                    onChange={(e) => handleAreaQtyChange(e, i, 'l')}
                    // onChange={(e) => (e.target.value >= 1) ? handleAreaQtyChange(e, i, 'l') : 1}
                    disabled={data?.product?.name.includes("Granite - Material + Fixing (Min order quantity 20 sft)")
                    || (projectDetails.contractCustomerApproved &&
                          projectDetails.contractFinanceApproved &&
                          projectDetails.designStages === 'Design Sign-off') &&
                        (data?.newItemAdded ? false : true)}
                    name="width" size="small" value={data?.width}
                    type="number" style={{ marginBottom: 10 }} fullWidth variant="outlined" />
                  </StyledTableCell>}

                  {/* Length */}
                  {(data.subUnitType !== 'Area') || data?.product?.name.includes('TIles fixing charges for tiles') ? <StyledTableCell component="th" scope="row">-</StyledTableCell> : <StyledTableCell component="th" scope="row">
                    <TextField
                    onBlur={(e) => handleAreaQtyCheck(e, i, 'l')}
                    onChange={(e) => handleAreaQtyChange(e, i, 'l')}
                    name="length" size="small" value={data?.length}
                     disabled={
                        (projectDetails.contractCustomerApproved &&
                          projectDetails.contractFinanceApproved &&
                          projectDetails.designStages === 'Design Sign-off') &&
                        (data?.newItemAdded ? false : true)
                      }
                    type="number" style={{ marginBottom: 10 }} fullWidth variant="outlined" />
                  </StyledTableCell>}

                  {/* Area for subunit area and Product is false ceiling */}
                  {(data.subUnitType === 'Area' && falseCeilingValidate(data?.product?.name)) && <StyledTableCell component="th" scope="row">
                    {(data?.product?.name.toLowerCase().includes('false ceiling')) ? `` :
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={data.customArea}
                          onChange={(e) => customArea(i, e, data.customArea)}
                          name="customeArea"
                          color="primary"
                        />
                      }
                      label="Custom Area"
                    />}
                    {!data.customArea ? Number(data?.area).toFixed(2) :
                      <TextField name="productCustomArea" size="small" value={data?.productCustomArea} onChange={(e) => handleAreaQtyChange(e, i, 'r')} type="number" style={{ marginBottom: 10 }} fullWidth variant="outlined" />
                    }
                  </StyledTableCell>}

                  {/* Area for subunit area and Product is not false ceiling */}
                  {(data.subUnitType === 'Area' && !falseCeilingValidate(data?.product?.name)) && <StyledTableCell component="th" scope="row">
                    {data.area}
                  </StyledTableCell>}

                  {/* Area for subunit Qty */}
                  {(data.subUnitType === 'Qty') && <StyledTableCell component="th" scope="row">
                    {/* <TextField name="area" size="small" value={data?.area} onChange={(e) => handleAreaQtyChange(e, i, 'a')} type="number" style={{ marginBottom: 10 }} fullWidth variant="outlined" /> */}
                    <TextField  
                     disabled={
                        (projectDetails.contractCustomerApproved &&
                          projectDetails.contractFinanceApproved &&
                          projectDetails.designStages === 'Design Sign-off') &&
                        (data?.newItemAdded ? false : true) 
                        ||
                        data?.product?.name == "Granite - Material + Fixing (Min order quantity 20 sft)"
                      }
                    name="area" size="small" value={data?.area} onChange={(e) => handleAreaQtyChange(e, i, 'a')} type="number" style={{ marginBottom: 10 }} fullWidth variant="outlined" />
                  </StyledTableCell>}

                  {/* <StyledTableCell component="th" scope="row">
                  ₹ {(data.subUnitType === 'Area') && commaSeparateAmount(data.productPrice)}
                    {data.product.name === 'Stone Cladding' && <Typography variant='p'>(Includes fixing cost)</Typography>}
                    {(data.subUnitType === 'Qty') && commaSeparateAmount(data.productPrice)}
                  </StyledTableCell> */}

                {/* total price of value of any itme */}
                <StyledTableCell component="th" scope="row">
                  ₹ {
                    (
                      (data.subUnitType === 'Area' || data.subUnitType === 'Qty') ?
                    (( 
                      data.depth < 0 ||
                      data.length < 0 ||
                      data.width < 0 ||
                      data.area < 0 ||
                      (data.fixingCharge && data.fixingCharge < 0)
                    ) ?
                      -1 * Math.abs((data.productPrice)) 
                      : (data?.productPrice)
                      ) 
                      :
                      (data?.productPrice))  
                  }
                  {data?.product?.name === 'Stone Cladding' && <Typography variant='p'>(Includes fixing cost)</Typography>}
                </StyledTableCell>

                  {/* <StyledTableCell component="th" scope="row">{data?.productPricePerUnit}</StyledTableCell> */}
                  {/* <StyledTableCell>{+projectComponentProducts?.[chosenCategory]?.plyType?.pricePerUnit ? data?.productPricePerUnit + +projectComponentProducts?.[chosenCategory]?.plyType?.pricePerUnit : data?.productPricePerUnit}</StyledTableCell> */}
                  {/* <StyledTableCell component="th" scope="row">{data?.subUnitType !== "Qty" && <TextField name="width" size="small" value={data?.width} onChange={(e) => handleAreaQtyChange(e, i)} type="number" style={{ marginBottom: 10 }} fullWidth variant="outlined" />}</StyledTableCell>
                  <StyledTableCell component="th" scope="row">{data?.subUnitType !== "Qty" && <TextField name="length" size="small" value={data?.length} onChange={(e) => handleAreaQtyChange(e, i)} type="number" style={{ marginBottom: 10 }} fullWidth variant="outlined" />}</StyledTableCell>
                  <StyledTableCell component="th" scope="row">{data?.subUnitType === "Qty" ? <TextField name="area" size="small" value={data?.area} onChange={(e) => handleAreaQtyChange(e, i)} type="number" style={{ marginBottom: 10 }} fullWidth variant="outlined" /> : data?.area}</StyledTableCell> */}
                  {/* <StyledTableCell>{+projectComponentProducts?.[chosenCategory]?.plyType?.pricePerUnit ? ((+data?.productPrice + +projectComponentProducts?.[chosenCategory]?.plyType?.pricePerUnit) / 90000).toFixed(2) : (+data?.productPrice / 90000).toFixed(2)}</StyledTableCell> */}
                  {/* <StyledTableCell component="th" scope="row">{data.productPrice}</StyledTableCell> */}
                </StyledTableRow>
                )
              }
                )}

                <StyledTableRow >
                  <StyledTableCell component="th" scope="row" align="right" colSpan={9}></StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center">Total</StyledTableCell>
                  {/* <StyledTableCell component="th" scope="row" align="center">
                  ₹ {
                   
                      projectComponentProducts.hasOwnProperty(chosenCategory) && projectComponentProducts?.[chosenCategory]?.item.length === 0 ? `0` :
                      commaSeparateAmount((projectComponentProducts.hasOwnProperty(chosenCategory) &&  Math.round(projectComponentProducts?.[chosenCategory]?.item?.reduce((acc, crr) => ({ productPrice: Number(acc.productPrice) + Number(crr.productPrice)})).productPrice)))}
                  </StyledTableCell> */}

                  {/* if item is decreasing and progject */}
              <StyledTableCell component="th" scope="row">
                {/* {console.log("calculation total", projectComponentProducts?.[chosenCategory]?.item)}  */}
              ₹ {
                  projectComponentProducts.hasOwnProperty(chosenCategory) &&
                  projectComponentProducts?.[chosenCategory]?.item.length === 0
                    ? '0'
                    : commaSeparateAmount(
                      projectComponentProducts.hasOwnProperty(chosenCategory) &&
                        Math.round(
                          projectComponentProducts?.[chosenCategory]?.item
                          .filter((data) => !['A0', 'A1', 'A2', 'A3', 'A4'].includes(data?.product?.code))
                            .reduce(
                              (acc, curr) => {
                                let productPrice;
                                // Check if any of length, width, or depth is negative
                                if (curr.length < 0 || curr.width < 0 || curr.depth < 0) {
                                  // Make productPrice negative if any of them is negative
                                  curr.productPrice = -1 * Math.abs(curr.productPrice);
                                }
                                // Otherwise, add the current curr.productPrice to acc.productPrice
                                productPrice = Number(acc.productPrice) + Number(curr.productPrice);
                                return { productPrice };
                              },
                              { productPrice: 0 } // Initialize the accumulator with productPrice as 0
                            ).productPrice
                          )
                      )
                    }
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>

        </>}
      </Modal >

      {/* Comparing Quotations Modal */}
      <Modal
          title={
          <div>
            <Button
              variant="outlined"
              onClick={handleViewPage}
              style={{
                borderColor: currentPage === 'summary' ? 'black' : 'white',
                color: currentPage === 'summary' ? 'black' : 'white',
                backgroundColor: currentPage === 'summary' ? 'white' : 'black',
                '&:hover': {
                  borderColor: 'black',
                  backgroundColor: 'black',
                  color: 'white',
                },
                marginRight: '10px'
              }}
            >
              Summary Page
            </Button>
            <Button
              variant="outlined"
              onClick={handleSummaryPage}
              style={{
                borderColor: currentPage === 'view' ? 'black' : 'white',
                color: currentPage === 'view' ? 'black' : 'white',
                backgroundColor: currentPage === 'view' ? 'white' : 'black',
                '&:hover': {
                  borderColor: 'black',
                  backgroundColor: 'black',
                  color: 'white',
                },
              }}
            >
              Internal View
            </Button>
          </div>
        }
        centered
        visible={comparisionPreview}
        onCancel={()=>{
          setComparisionPreview(false)
        }}
        width={1000}
        maskClosable={false}
        footer={null}
      >
        <>
          {currentPage === 'view' && (
            <div>
            <Button variant='contained' color='primary' onClick={handleDownloadPdf}>Download Summary pdf</Button>
              <TableContainer component={Paper} style={{ marginBottom: 20 }}>
                <Table className={classes.table} size="small" aria-label="customized table">
                  <TableBody>
                    <TableRow>
                      <StyledTableCell>New line item</StyledTableCell>
                      <StyledTableCell style={{ backgroundColor: 'yellow' }}>Yellow</StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell>Removed line item(Descope)</StyledTableCell>
                      <StyledTableCell style={{ backgroundColor: 'orange' }}>Orange</StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell>Change in Finish</StyledTableCell>
                      <StyledTableCell style={{ backgroundColor: 'lightblue' }}>Blue</StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell>Increase Area</StyledTableCell>
                      <StyledTableCell style={{ backgroundColor: 'lightgreen' }}>Green</StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell>Decrease Area</StyledTableCell>
                      <StyledTableCell style={{ backgroundColor: 'pink' }}>Red</StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              {comapredData.map((data, i) =>
                <TableContainer key={i + 1} component={Paper} style={{ marginBottom: 20 }}>
                  <div style={{ textAlign: "center", marginBottom: 10, marginTop: 10 }}><b>{data.spaceName}</b></div>
                  <Table className={classes.table} size="small" aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Sl No</StyledTableCell>
                        <StyledTableCell>Quotation Name</StyledTableCell>
                        <StyledTableCell>Name</StyledTableCell>
                        <StyledTableCell>Priority</StyledTableCell>
                        <StyledTableCell>Finish</StyledTableCell>
                        <StyledTableCell>Price Per Unit</StyledTableCell>
                        <StyledTableCell>No/Qty</StyledTableCell>
                        <StyledTableCell>Depth</StyledTableCell>
                        <StyledTableCell>Width</StyledTableCell>
                        <StyledTableCell>Length</StyledTableCell>
                        <StyledTableCell>Area/Qty</StyledTableCell>
                        <StyledTableCell>Price</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.products?.sort((a, b) => a['priority'] - b['priority']).map((data1, i) =>
                        <StyledTableRow key={i + 1} style={{ backgroundColor: data1?.new ? 'yellow' : (data1?.removed ? 'orange' : '') }}>
                          <StyledTableCell component="th" scope="row">{i + 1}</StyledTableCell>
                          <StyledTableCell component="th" scope="row">{data.otherQuotationVersion ? data.otherQuotationVersion : data.baseQuotationVersion}</StyledTableCell>
                          <StyledTableCell component="th" scope="row">{data1?.product?.name}</StyledTableCell>
                          <StyledTableCell component="th" scope="row">{data1?.priority}</StyledTableCell>
                          <StyledTableCell style={{ backgroundColor: (data1?.finishChanged || data1?.finishAreaPositive || data1?.finishAreaNegative) ? 'lightblue' : '' }} component="th" scope="row">{data1?.product?.finishInclude ? data1?.finishType?.name : `-`}</StyledTableCell>
                          <StyledTableCell component="th" scope="row">{data1?.totalPricePerUnit}</StyledTableCell>
                          <StyledTableCell component="th" scope="row">{data1?.product?.name.includes('TIles fixing charges for tiles') ? data1?.fixingCharge : `-`}</StyledTableCell>
                          <StyledTableCell component="th" scope="row">{data1?.product.depth}</StyledTableCell>
                          <StyledTableCell component="th" scope="row">{data1?.width}</StyledTableCell>
                          <StyledTableCell component="th" scope="row">{data1?.length} </StyledTableCell>
                          <StyledTableCell style={{ backgroundColor: (data1?.areaChangedPositive || data1?.finishAreaPositive) ? 'lightgreen' : ((data1?.areaChangedNegative || data1?.finishAreaNegative) ? 'pink' : '') }} component="th" scope="row">{data1?.area ? data1?.area.toFixed(2) : `-`}</StyledTableCell>
                          <StyledTableCell component="th" scope="row">₹ {data1?.removed ? `-${commaSeparateAmount(data1?.productPrice)}` : commaSeparateAmount(data1?.productPrice)}</StyledTableCell>
                        </StyledTableRow>
                      )}
                      <StyledTableRow>
                        <StyledTableCell component="th" scope="row" align="right" colSpan={10}></StyledTableCell>
                        <StyledTableCell component="th" scope="row" align="center">Total</StyledTableCell>
                        <StyledTableCell component="th" scope="row" align="center">
                          ₹ {data?.products.length === 0 ? `0` :
                            commaSeparateAmount(
                              data.products.reduce((acc, crr) =>
                                acc + (crr.removed ? -crr.productPrice : crr.productPrice),
                                0)
                            )}
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </div>
          )}
          {currentPage === 'summary' && (
            <div>
              <div>
                {/* <Button variant='contained' color='primary' style={{ marginTop: '20px', marginBottom: '20px', marginRight: '10px' }} onClick={handleDownload}>Download CSV</Button> */}
                {/* <Button variant='contained' color='primary' style={{ marginTop: '20px', marginBottom: '20px' }} onClick={() => window.open(pdfLink, '_blank')}>Download PDF</Button> */}
              </div>
              <TableContainer component={Paper} style={{ marginBottom: 20 }}>
                <Table className={classes.table} size="small" aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Category</StyledTableCell>
                      <StyledTableCell>Particulars</StyledTableCell>
                      <StyledTableCell>Differential</StyledTableCell>
                      <StyledTableCell>Reason</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {comapredData.map((data, i) => (
                      <>
                      <StyledTableRow key={i + 1}>
                        <StyledTableCell>{data.spaceName}</StyledTableCell>
                        <StyledTableCell>
                          {data.products.map((product, index) => (
                            <div key={index} style={{ marginBottom: '10px' }}>{product.product.name}</div>
                          ))}
                        </StyledTableCell>
                        <StyledTableCell>
                          {data.products.map((product, index) => (
                            <div key={index} style={{ marginBottom: '10px' }}>₹ {commaSeparateAmount(product.differential)}</div>
                          ))}
                        </StyledTableCell>
                        <StyledTableCell>
                          {data.products.map((product, index) => (
                            <div key={index} style={{ marginBottom: '10px' }}>
                              {product.new ? 'Additional' :
                                product.removed ? 'Descope' :
                                  product.finishChanged ? 'Finish Change' :
                                    product.areaChangedPositive || product.areaChangedNegative ? 'Sq/Ft or Qty' :
                                      product.finishAreaPositive || product.finishAreaNegative ? 'Finish Change and Sq/ft' : ''}
                            </div>
                          ))}
                        </StyledTableCell>
                      </StyledTableRow>
                      <tr>
                        <td colSpan="6"><hr /></td>
                      </tr>
                    </>
                    ))}
                    <StyledTableRow>
                      <StyledTableCell colSpan={2} align="right">Total Differential:</StyledTableCell>
                      <StyledTableCell>₹ {commaSeparateAmount(totalDifferentialFormatted)}</StyledTableCell>
                      <StyledTableCell></StyledTableCell> {/* Empty cell for alignment */}
                    </StyledTableRow>
                  </TableBody>
                </Table>
                {/* <div>Total Differential: {totalDifferential.toFixed(2)}</div> */}
              </TableContainer>
              {/* <div style={{ float: 'right' }}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell><b>Reason belongs to</b></StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <StyledTableRow>
                        <StyledTableCell>Descope</StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell>Additional</StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell>Finish Change</StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell>Sq/Ft or Qty</StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell>Plywood</StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div> */}
            </div>
          )}
        </>
      </Modal>
      {/* Preview Quotation Modal */}
      <Modal
        title="Preview Quotation"
        centered
        visible={openPreviewModal}
        onCancel={() => {
          setOpenPreviewModal(false);
          setComplimentaryItemPresent(false);
          setComplimentaryItemRevoked(false);
          setNewComplimentaryItem(false);
          setRejectedItem(false);
        }}
        width={1000}
        maskClosable={false}
        footer={null}
      >
        <div >
          <div style={{ float: 'right', marginBottom: 20 }}>
            <TextField type="number" id="outlined-basic" label="Discount (in %)" size="small" value={discount} onChange={(e) => calDiscount(e.target.value, 'val')} variant="outlined"
               disabled={
                ( projectDetails.contractCustomerApproved &&
                  projectDetails.contractFinanceApproved &&
                  projectDetails.designStages === 'Design Sign-off')}
                />
            {
              (!(discount <= discountMaster) && (discount !== projectDetails.discountPercent) || (projectDetails.discountStatus === 'Rejected') && !(discount <= discountMaster))
                ?
                <div>
                  {discount !== projectDetails.discountPercent && projectDetails.discountStatus !== 'Sent for approval' &&
                    <div>
                      <b>Discount cannot be more than {discountMaster}%</b> <br />
                      <Button variant="contained" color="primary" onClick={() => discountRequest(discount)}>Request</Button>
                    </div>
                  }
                </div>
                :
                null
            }
            {
              (discount === projectDetails.discountPercent && projectDetails.discountStatus === 'Approved')
                ?
                <div>
                  {/* Take the last key of discount approve log */}
                  {projectDetails.discountStatus} by {projectDetails.discountLogs[projectDetails.discountLogs.length - 1]['actionTakenBy']?.['name']}
                </div>
                :
                null
            }
            {
              (discount === projectDetails.discountPercent && projectDetails.discountStatus === 'Rejected' && !(discount <= discountMaster))
                ?
                <div>
                  {/* Take the last key of discount approve log */}
                  {projectDetails.discountStatus} by {projectDetails.discountLogs[projectDetails.discountLogs.length - 1]['actionTakenBy']?.['name']}
                </div>
                :
                null
            }
            {
              (projectDetails.discountStatus === 'Sent for approval')
                ?
                <div>
                  {projectDetails.discountStatus}
                  <Button variant="contained" color="primary" onClick={() => revoke()}>Revoke</Button>
                </div>
                :
                null
            }
          </div>
        </div>
{/* 
        {(projectDetails.contractCustomerApproved && projectDetails.contractFinanceApproved && projectDetails.designStages === 'Design Sign-off') &&
            <div>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue=""
                  name="radio-buttons-group"
                >
                  <FormControlLabel value="" control={<Radio style={{ color: "green" }} />} label={<span style={{ color: "green" }}>Raised quotation changes for positive values</span>} />
                  <FormControlLabel value="" control={<Radio style={{ color: "red" }} />} label={<span style={{ color: "red" }}>Raised quotation changes for negative values</span>} />
                </RadioGroup>
              </FormControl>
            </div>
          } */}
       
          {(projectDetails.contractCustomerApproved &&
            projectDetails.contractFinanceApproved &&
            projectDetails.designStages === 'Design Sign-off')?
              qtPreview.filter(data => data.spacename === 'POST DESIGN SIGN-OFF CHANGES').map((data, i) => 
                <TableContainer key={i + 1} component={Paper} style={{ marginBottom: 20 }}>
                  <div style={{ textAlign: "center", marginBottom: 10, marginTop: 10 }}><b>{data.spacename}</b></div>
                  <Table className={classes.table} size="small" aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Sl No</StyledTableCell>
                        <StyledTableCell>Name</StyledTableCell>
                        <StyledTableCell>Priority</StyledTableCell>
                        {/* <StyledTableCell>Scope</StyledTableCell> */}
                        <StyledTableCell>Finish Type</StyledTableCell>
                        <StyledTableCell>No/Qty</StyledTableCell>
                        <StyledTableCell>Depth</StyledTableCell>
                        <StyledTableCell>Width</StyledTableCell>
                        <StyledTableCell>Length</StyledTableCell>
                        <StyledTableCell>Area/Qty</StyledTableCell>
                        <StyledTableCell>Total</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.product?.filter(item => item?.negativeQtCr || item?.positiveQtCr)?.sort((a, b) => a['priority'] - b['priority']).map((data1, i) =>
                        <StyledTableRow key={i + 1}>
                          <StyledTableCell component="th" scope="row">{i + 1}</StyledTableCell>
                          <StyledTableCell component="th" scope="row">{data1?.product?.name}</StyledTableCell>
                          <StyledTableCell component="th" scope="row">{data1?.priority}</StyledTableCell>
                          {/* <StyledTableCell >{data1?.product?.scope?.name ? data1?.product?.scope?.name : `-`}</StyledTableCell> */}
                          <StyledTableCell component="th" scope="row">{data1?.product?.finishInclude ? data1?.finishType?.name : `-`}</StyledTableCell>
                          <StyledTableCell component="th" scope="row">{data1?.product?.name.includes('TIles fixing charges for tiles') ? data1?.fixingCharge : `-`}</StyledTableCell>
                          <StyledTableCell component="th" scope="row">{(data1?.product?.name.includes('False ceiling (Peripheral with Cove') || data1?.product?.name.includes('False ceiling (Peripheral, No Cove')) ? data1?.depth : `-`}</StyledTableCell>
                          <StyledTableCell component="th" scope="row">{data1?.product?.name.includes('TIles fixing charges for tiles') || data1?.product?.name.toLowerCase().includes('false ceiling rafter') || data1?.product?.unitType?._id == rftUnitTypeId ? `-` : data1?.width ? data1?.width : `-`}</StyledTableCell>
                          <StyledTableCell component="th" scope="row">{data1?.product?.name.includes('TIles fixing charges for tiles') ? `-` : data1?.length ? data1?.length : `-`} </StyledTableCell>
                          <StyledTableCell component="th" scope="row">{data1?.area ? data1?.area.toFixed(2) : `-`}</StyledTableCell>
                          <StyledTableCell component="th" scope="row">₹ {commaSeparateAmount(data1?.productPrice)}</StyledTableCell>
                        </StyledTableRow>
                      )}
                      <StyledTableRow>
                        <StyledTableCell component="th" scope="row" align="right" colSpan={8}></StyledTableCell>
                        <StyledTableCell component="th" scope="row" align="center">Total</StyledTableCell>
                        <StyledTableCell component="th" scope="row" align="center">
                        ₹ {data?.product.length === 0 ? `0` :
                          commaSeparateAmount(
                            data?.product
                              .filter(item => item?.negativeQtCr || item.positiveQtCr)
                              .reduce((acc, crr) => ({ productPrice: acc.productPrice + crr.productPrice }), { productPrice: 0 }) // Provide an initial value of { productPrice: 0 }
                              .productPrice
                          )}
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                )
          :
              qtPreview.map((data, i) => 
              <TableContainer key={i + 1} component={Paper} style={{ marginBottom: 20 }}>
                <div style={{ textAlign: "center", marginBottom: 10, marginTop: 10 }}><b>{data.spacename}</b></div>
                <Table className={classes.table} size="small" aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Sl No</StyledTableCell>
                      <StyledTableCell>Name</StyledTableCell>
                      <StyledTableCell>Priority</StyledTableCell>
                      {/* <StyledTableCell>Scope</StyledTableCell> */}
                      <StyledTableCell>Finish Type</StyledTableCell>
                      <StyledTableCell>No/Qty</StyledTableCell>
                      <StyledTableCell>Depth</StyledTableCell>
                      <StyledTableCell>Width</StyledTableCell>
                      <StyledTableCell>Length</StyledTableCell>
                      <StyledTableCell>Area/Qty</StyledTableCell>
                      <StyledTableCell>Total</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.product?.sort((a, b) => a['priority'] - b['priority']).map((data1, i) =>
                      <StyledTableRow key={i + 1}>
                        <StyledTableCell component="th" scope="row">{i + 1}</StyledTableCell>
                        <StyledTableCell component="th" scope="row">{data1?.product?.name}</StyledTableCell>
                        <StyledTableCell component="th" scope="row">{data1?.priority}</StyledTableCell>
                        {/* <StyledTableCell >{data1?.product?.scope?.name ? data1?.product?.scope?.name : `-`}</StyledTableCell> */}
                        <StyledTableCell component="th" scope="row">{data1?.product?.finishInclude ? data1?.finishType?.name : `-`}</StyledTableCell>
                        <StyledTableCell component="th" scope="row">{data1?.product?.name.includes('TIles fixing charges for tiles') ? data1?.fixingCharge : `-`}</StyledTableCell>
                        <StyledTableCell component="th" scope="row">{(data1?.product?.name.includes('False ceiling (Peripheral with Cove') || data1?.product?.name.includes('False ceiling (Peripheral, No Cove')) ? data1?.depth : `-`}</StyledTableCell>
                        <StyledTableCell component="th" scope="row">{data1?.product?.name.includes('TIles fixing charges for tiles') || data1?.product?.name.toLowerCase().includes('false ceiling rafter') || data1?.product?.unitType?._id == rftUnitTypeId ? `-` : data1?.width ? data1?.width : `-`}</StyledTableCell>
                        <StyledTableCell component="th" scope="row">{data1?.product?.name.includes('TIles fixing charges for tiles') ? `-` : data1?.length ? data1?.length : `-`} </StyledTableCell>
                        <StyledTableCell component="th" scope="row">{data1?.area ? data1?.area.toFixed(2) : `-`}</StyledTableCell>
                        <StyledTableCell component="th" scope="row">₹ {commaSeparateAmount(data1?.productPrice)}</StyledTableCell>
                      </StyledTableRow>
                    )}
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row" align="right" colSpan={8}></StyledTableCell>
                      <StyledTableCell component="th" scope="row" align="center">Total</StyledTableCell>
                      <StyledTableCell component="th" scope="row" align="center">
                      ₹ {data?.product.length === 0 ? `0` :
                          commaSeparateAmount((data?.product?.reduce((acc, crr) => ({ productPrice: acc.productPrice + crr.productPrice }))).productPrice)}
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              )
          }
       
        {
          (projectDetails.contractCustomerApproved &&
            projectDetails.contractFinanceApproved &&
            projectDetails.designStages === 'Design Sign-off') ?
            // When negative changes is there
            (projectDetails.discountOnAllScopes === true ?
              
              <div>
                <TableContainer component={Paper} style={{ marginBottom: 20 }}>
                  <Table>
                    <TableBody>
                      <StyledTableRow>
                        <StyledTableCell component="th" scope="row"><b>Total Quotation value</b></StyledTableCell>
                        <StyledTableCell component="th" scope="row">₹ {commaSeparateAmount(newChangesTotalProductsPrice.totalQuotationValue)}</StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell component="th" scope="row"><b>Discount @ {paymentTotalInfo.discountPercent} %</b></StyledTableCell>
                        <StyledTableCell component="th" scope="row">₹ {commaSeparateAmount(newChangesTotalProductsPrice.discount)}</StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell component="th" scope="row"><b>Sub Total</b></StyledTableCell>
                        <StyledTableCell component="th" scope="row">₹ {commaSeparateAmount(newChangesTotalProductsPrice.subTotal)}</StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell component="th" scope="row"><b>GST @ 18%</b></StyledTableCell>
                        <StyledTableCell component="th" scope="row">₹ {commaSeparateAmount(newChangesTotalProductsPrice.gst18PerAmount)}</StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell component="th" scope="row"><b>Total</b></StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {projectDetails?.sentForQuotationChangeApproval?.onlyQuotationAmount ?
                            `₹ ${commaSeparateAmount(projectDetails?.sentForQuotationChangeApproval?.onlyQuotationAmount)}` :
                            `₹ ${commaSeparateAmount(newChangesTotalProductsPrice.total)}`
                          }
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              :
              <div>
                <TableContainer component={Paper} style={{ marginBottom: 20 }}>
                  <Table>
                    <TableBody>
                      <StyledTableRow>
                        <StyledTableCell component="th" scope="row"><b>Total Value ({eligibleDiscountScopes})</b></StyledTableCell>
                        <StyledTableCell component="th" scope="row">₹ {commaSeparateAmount(totalDescPerAmt)}</StyledTableCell>
                        {/* <StyledTableCell component="th" scope="row">{paymentTotalInfo.priceAmount}</StyledTableCell> */}
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell component="th" scope="row"><b>Discount @ {paymentTotalInfo.discountPercent} %</b></StyledTableCell>
                        <StyledTableCell component="th" scope="row">₹ {commaSeparateAmount(paymentTotalInfo.discountValue)}</StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell component="th" scope="row"><b>Discounted Value ({eligibleDiscountScopes})</b></StyledTableCell>
                        <StyledTableCell component="th" scope="row">₹ {commaSeparateAmount(paymentTotalInfo.discItemsSubTotal)}</StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell component="th" scope="row"><b>Other Items ({eligibleNonDiscountScopes})</b></StyledTableCell>
                        <StyledTableCell component="th" scope="row">₹ {commaSeparateAmount(paymentTotalInfo.totalNonDiscPrAmnt)}</StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell component="th" scope="row"><b>Total</b></StyledTableCell>
                        <StyledTableCell component="th" scope="row">₹ {commaSeparateAmount(paymentTotalInfo.subTotal)}</StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell component="th" scope="row"><b>GST @ 18%</b></StyledTableCell>
                        <StyledTableCell component="th" scope="row">₹ {commaSeparateAmount(paymentTotalInfo.taxableAmount)}</StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell component="th" scope="row"><b>Overall Price</b></StyledTableCell>
                        <StyledTableCell component="th" scope="row">₹ {commaSeparateAmount(paymentTotalInfo.total)}</StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            )
            :
            // When not negative positive quotation changes
              (projectDetails.discountOnAllScopes === true
                ?
                <div>
                  <TableContainer component={Paper} style={{ marginBottom: 20 }}>
                    <Table>
                      <TableBody>
                        <StyledTableRow>
                          <StyledTableCell component="th" scope="row"><b>Total Quotation value</b></StyledTableCell>
                          <StyledTableCell component="th" scope="row">₹ {commaSeparateAmount(paymentTotalInfo.priceAmount)}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledTableCell component="th" scope="row"><b>Discount @ {paymentTotalInfo.discountPercent} %</b></StyledTableCell>
                          <StyledTableCell component="th" scope="row">₹ {commaSeparateAmount(paymentTotalInfo.discountValue)}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledTableCell component="th" scope="row"><b>Sub Total</b></StyledTableCell>
                          <StyledTableCell component="th" scope="row">₹ {commaSeparateAmount(paymentTotalInfo.subTotal)}</StyledTableCell>
                        </StyledTableRow>
                        {/* <StyledTableRow>
                          <StyledTableCell><b>Decorpot Scope @ {paymentTotalInfo.scopePercentage}%</b></StyledTableCell>
                          <StyledTableCell>{paymentTotalInfo.decorpotScope}</StyledTableCell>
                        </StyledTableRow> */}
                        <StyledTableRow>
                          <StyledTableCell component="th" scope="row"><b>GST @ 18%</b></StyledTableCell>
                          <StyledTableCell component="th" scope="row">₹ {commaSeparateAmount(paymentTotalInfo.taxableAmount)}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledTableCell component="th" scope="row"><b>Total</b></StyledTableCell>
                          <StyledTableCell component="th" scope="row">₹ {commaSeparateAmount(paymentTotalInfo.total)}</StyledTableCell>
                        </StyledTableRow>
                        {/* <StyledTableRow>
                          <StyledTableCell style={{ color: 'green' }}><b>Customer Saving @ {paymentTotalInfo.savingPercen}%</b></StyledTableCell>
                          <StyledTableCell style={{ color: 'green' }}>{paymentTotalInfo.customerSavings}</StyledTableCell>
                        </StyledTableRow> */}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
                :
                <div>
                  <TableContainer component={Paper} style={{ marginBottom: 20 }}>
                    <Table>
                      <TableBody>
                        <StyledTableRow>
                          <StyledTableCell component="th" scope="row"><b>Total Value ({eligibleDiscountScopes})</b></StyledTableCell>
                          <StyledTableCell component="th" scope="row">₹ {commaSeparateAmount(totalDescPerAmt)}</StyledTableCell>
                          {/* <StyledTableCell component="th" scope="row">{paymentTotalInfo.priceAmount}</StyledTableCell> */}
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledTableCell component="th" scope="row"><b>Discount @ {paymentTotalInfo.discountPercent} %</b></StyledTableCell>
                          <StyledTableCell component="th" scope="row">₹ {commaSeparateAmount(paymentTotalInfo.discountValue)}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledTableCell component="th" scope="row"><b>Discounted Value ({eligibleDiscountScopes})</b></StyledTableCell>
                          <StyledTableCell component="th" scope="row">₹ {commaSeparateAmount(paymentTotalInfo.discItemsSubTotal)}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledTableCell component="th" scope="row"><b>Other Items ({eligibleNonDiscountScopes})</b></StyledTableCell>
                          <StyledTableCell component="th" scope="row">₹ {commaSeparateAmount(paymentTotalInfo.totalNonDiscPrAmnt)}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledTableCell component="th" scope="row"><b>Total</b></StyledTableCell>
                          <StyledTableCell component="th" scope="row">₹ {commaSeparateAmount(paymentTotalInfo.subTotal)}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledTableCell component="th" scope="row"><b>GST @ 18%</b></StyledTableCell>
                          <StyledTableCell component="th" scope="row">₹ {commaSeparateAmount(paymentTotalInfo.taxableAmount)}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledTableCell component="th" scope="row"><b>Overall Price</b></StyledTableCell>
                          <StyledTableCell component="th" scope="row">₹ {commaSeparateAmount(paymentTotalInfo.total)}</StyledTableCell>
                        </StyledTableRow>
                        {/* <StyledTableRow>
                          <StyledTableCell><b>Customer Saving @ {paymentTotalInfo.savingPercen}%</b></StyledTableCell>
                          <StyledTableCell>{paymentTotalInfo.customerSavings}</StyledTableCell>
                        </StyledTableRow> */}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )
          // :
          // null
        }
      {/* Add category delay penality , damages and referral bonus */}
        <div>
          {(projectDetails.contractCustomerApproved && projectDetails.contractFinanceApproved && projectDetails.designStages === 'Design Sign-off') && (
            <div>
               <TableContainer component={Paper} style={{ marginBottom: 20 }}>
                  <Table>
                    <TableBody>
                      <StyledTableRow>
                        <StyledTableCell component="th" scope="row">
                        <Checkbox onChange={(e) => handleCheckBox('Delay Penality')} checked={damageDelayOtherValue.delayPenalityCheck} disabled={projectDetails?.sentForQuotationChangeApproval?.isSendForApproval} />
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row"><b>Delay Penality</b></StyledTableCell>
                        <StyledTableCell component="th" scope="row"> 
                        <TextField type="number"
                          id="delayPenality"
                          label="Delay Penality"
                          size="small"
                          value={damageDelayOtherValue.delayPenality}
                          onChange={(e) => calculateDamageDelayOtherValue(e.target.value, 'Delay Penality')}
                          variant="outlined"
                          disabled={!damageDelayOtherValue.delayPenalityCheck || projectDetails?.sentForQuotationChangeApproval?.isSendForApproval}
                        />
                        </StyledTableCell>
                      </StyledTableRow>

                      <StyledTableRow>
                        <StyledTableCell component="th" scope="row">             
                         <Checkbox onChange={(e) => handleCheckBox('Damages')} checked={damageDelayOtherValue.damagesCheck} disabled={projectDetails?.sentForQuotationChangeApproval?.isSendForApproval} />
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row"><b>Damages</b></StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                        <TextField type="number"
                          id="damages"
                          label="Damages"
                          size="small"
                          value={damageDelayOtherValue.damages}
                          onChange={(e) => calculateDamageDelayOtherValue(e.target.value, 'Damages')}
                          variant="outlined"
                          disabled={!damageDelayOtherValue.damagesCheck || projectDetails?.sentForQuotationChangeApproval?.isSendForApproval}
                        />
                        </StyledTableCell>
                      </StyledTableRow>

                      <StyledTableRow>
                        <StyledTableCell component="th" scope="row">
                        <Checkbox onChange={(e) => handleCheckBox('Good Will Discount')} checked={damageDelayOtherValue.goodWillDiscountCheck} disabled={projectDetails?.sentForQuotationChangeApproval?.isSendForApproval} />
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row"><b>Good Will Discount</b></StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                        <TextField type="number"
                          id="goodWillDiscount"
                          label="Good Will Discount"
                          size="small"
                          value={damageDelayOtherValue.goodWillDiscount}
                          onChange={(e) => calculateDamageDelayOtherValue(e.target.value, 'Good Will Discount')}
                          variant="outlined"
                          disabled={!damageDelayOtherValue.goodWillDiscountCheck || projectDetails?.sentForQuotationChangeApproval?.isSendForApproval}
                        />
                        </StyledTableCell>
                      </StyledTableRow>

                      <StyledTableRow>
                        <StyledTableCell component="th" scope="row">
                        <Checkbox onChange={(e) => handleCheckBox('Other')} checked={damageDelayOtherValue.otherCheck} disabled={projectDetails?.sentForQuotationChangeApproval?.isSendForApproval} />
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row"><b>Other</b></StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                        <TextField type="number"
                            id="other"
                            label="Other"
                            size="small"
                            value={damageDelayOtherValue.other}
                            onChange={(e) => calculateDamageDelayOtherValue(e.target.value, 'Other')}
                            variant="outlined"
                            disabled={!damageDelayOtherValue.otherCheck || projectDetails?.sentForQuotationChangeApproval?.isSendForApproval}
                          />
                       </StyledTableCell>
                      </StyledTableRow>

                      <StyledTableRow>
                        <StyledTableCell component="th" scope="row">
                        <Checkbox onChange={(e) => handleCheckBox('Referral Bonus')} checked={damageDelayOtherValue.referralBonusCheck} disabled={projectDetails?.sentForQuotationChangeApproval?.isSendForApproval} />
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row"><b>Referral Bonus</b></StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                        <TextField type="number"
                            id="referralBonus"
                            label="Referral Bonus"
                            size="small"
                            value={damageDelayOtherValue.referralBonus}
                            onChange={(e) => calculateDamageDelayOtherValue(e.target.value, 'Referral Bonus')}
                            variant="outlined"
                            disabled={!damageDelayOtherValue.referralBonusCheck || projectDetails?.sentForQuotationChangeApproval?.isSendForApproval}
                          />
                        </StyledTableCell>
                      </StyledTableRow>
                      
                    </TableBody>
                  </Table>
                </TableContainer>
          </div>
          )}
        </div>

     {/* Balance Statment is here */}
        {(projectDetails.contractCustomerApproved && projectDetails.contractFinanceApproved && projectDetails.designStages === 'Design Sign-off') && (
          <div>
            <h2>Balance Sheet</h2>
            <TableContainer component={Paper} style={{ marginBottom: 20 }}>
              <Table>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell component="th" scope="row" colspan={3}><h3>{"Project No." + " " + projectDetails.erpProjectNo}</h3></StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell component="th" scope="row"></StyledTableCell>
                    <StyledTableCell component="th" scope="row"><b>Quotation value</b></StyledTableCell>
                    <StyledTableCell component="th" scope="row">₹ {commaSeparateAmount(quotationTotalAmount)}</StyledTableCell>
                  </StyledTableRow>
                  {
                    projectDetails.contractCustomerApproved && projectDetails.contractFinanceApproved && projectDetails.designStages === 'Design Sign-off' &&
                    qtPreview.filter(data => data.spacename === 'POST DESIGN SIGN-OFF CHANGES').map((data, i) => {
                      return (
                        data.product.map((productItem, index) => (
                        (productItem?.negativeQtCr || productItem?.positiveQtCr) &&
                         (
                          <StyledTableRow key={`${i}-${index}`}>
                            <StyledTableCell component="th" scope="row">{productItem?.positiveQtCr ? "Add" : (productItem?.negativeQtCr ? "Less" : null)}</StyledTableCell>
                            <StyledTableCell component="th" scope="row">{productItem?.product.name}</StyledTableCell>
                            <StyledTableCell component="th" scope="row">₹ {commaSeparateAmount(productItem.productPrice)}</StyledTableCell>
                          </StyledTableRow>
                         )
                        ))
                      )
                    })
                  }
                  <StyledTableRow>
                    <StyledTableCell component="th" scope="row"></StyledTableCell>
                    <StyledTableCell component="th" scope="row"><b>Revise Quotation Value</b></StyledTableCell>
                    <StyledTableCell component="th" scope="row">₹ {commaSeparateAmount(balanceSheetData.reviseQuotationValue)}</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell component="th" scope="row">Less:</StyledTableCell>
                    <StyledTableCell component="th" scope="row">Discount @ {paymentTotalInfo.discountPercent}%</StyledTableCell>
                    <StyledTableCell component="th" scope="row">₹ {transform(balanceSheetData.discount)}</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell component="th" scope="row"></StyledTableCell>
                    <StyledTableCell component="th" scope="row"><b>SubTotal</b></StyledTableCell>
                    <StyledTableCell component="th" scope="row">₹ {commaSeparateAmount(balanceSheetData.subTotal)}</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell component="th" scope="row"></StyledTableCell>
                    <StyledTableCell component="th" scope="row">GST @ 18%</StyledTableCell>
                    <StyledTableCell component="th" scope="row">₹ {commaSeparateAmount(balanceSheetData.GST)}</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell component="th" scope="row"></StyledTableCell>
                    <StyledTableCell component="th" scope="row"><b>Total</b></StyledTableCell>
                    <StyledTableCell component="th" scope="row">₹ {commaSeparateAmount(balanceSheetData.total)}</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell component="th" scope="row"></StyledTableCell>
                    <StyledTableCell component="th" scope="row">Amount Paid</StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                    <TextField 
                     type="number"
                      id="amountPaid"
                      size="small"
                      value={amountPaid}
                      onChange={(e) => handleAmountPaid(e.target.value)}
                      variant="standard"
                      disabled={projectDetails?.sentForQuotationChangeApproval?.isSendForApproval}
                      style={{ width: "150px" , fontSize: '10px'}}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                  {
                    Object.entries(damageDelayOtherValue).map(([key, value]) => {
                      // Check if the key represents a check and if its corresponding value is true
                      if (key.endsWith('Check') && value) {
                        const fieldName = key.slice(0, -5);
                        const capitalizedFieldName =  fieldName
                        .replace(/([A-Z])/g, ' $1') // Insert space before each uppercase letter
                        .replace(/^./, (match) => match.toUpperCase()) // Capitalize the first character
                        .trim(); // Trim any leading/trailing spaces
                        return (
                          <StyledTableRow key={key}>
                            <StyledTableCell component="th" scope="row">Less</StyledTableCell>
                            <StyledTableCell component="th" scope="row"><b>{capitalizedFieldName}</b></StyledTableCell>
                            <StyledTableCell component="th" scope="row">₹ {commaSeparateAmount(damageDelayOtherValue[fieldName])}</StyledTableCell>
                          </StyledTableRow>
                        );
                      }
                      return null; // If the check field is false or if it's not a check field, return null
                    })
                  }
                  <StyledTableRow>
                    <StyledTableCell component="th" scope="row"></StyledTableCell>
                    <StyledTableCell component="th" scope="row"><b>Pending Due</b></StyledTableCell>
                    <StyledTableCell component="th" scope="row">₹ {commaSeparateAmount(parseInt(balanceSheetData.pendingDue))}</StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}

        {(userDetails.roles.find(role => role.name === ROLENAME.SalesManager || role.name === ROLENAME.AssistantSalesManager || role.name === ROLENAME.SalesUser || role.name === ROLENAME.DesignManager || role.name === ROLENAME.OperationDesignManager || role.name === ROLENAME.AssistantDesignManager  || role.name === ROLENAME.DesignUser)) && (
          <div>
            {((complimentaryItemPresent && (projectDetails.complimentaryItemApproval === false || projectDetails.complimentaryItemStatus === "Approved" || projectDetails.complimentaryItemStatus === "Sent for Approval" || projectDetails.complimentaryItemStatus === "Rejected")) || complimentaryItemRevoked || newComplimentaryItem || rejectedItem || rejectedItemDR) && (
              <Button variant='contained' color="primary" 
              onClick={sendForComplimentaryApproval} 
              disabled={((projectDetails.complimentaryItemStatus === "Sent for Approval" && (!complimentaryItemRevoked || !newComplimentaryItem)) || (projectDetails.complimentaryItemStatus === "Approved" && !rejectedItem && !rejectedItemDR && !complimentaryItemRevoked && !newComplimentaryItem) || (projectDetails.complimentaryItemStatus === "Rejected" && !rejectedItem && !rejectedItemDR && !complimentaryItemRevoked && !newComplimentaryItem))}>
                Send for Complimentary Approval
              </Button>
            )}
          </div>
        )}
        <div>
          {(projectDetails.contractCustomerApproved && projectDetails.contractFinanceApproved && projectDetails.designStages === 'Design Sign-off') ?
            ((projectDetails?.sentForQuotationChangeApproval?.FinanceManagerApproval === 'Approved' && finalizeButtonVisibility) ?
              <Button variant="contained" color="primary" style={{ float: "right" }}
                onClick={() => finalize()}
                disabled = {projectDetails.complimentaryItemStatus === "Sent for Approval"}>
                Finalize quotation
              </Button>
              :
              (projectDetails?.sentForQuotationChangeApproval?.OperationHeadApproval === 'Approved')?
                  <Button variant="contained" color="primary" style={{ float: "right" }}
                  onClick={() => finalize()}
                  disabled = {projectDetails.complimentaryItemStatus === "Sent for Approval"}>
                  Finalize quotation
                </Button>
              :
              // <Button variant="contained" color="primary" style={{ float: "right" }}
              //       onClick={() => sendForApproval()}
              //       disabled={projectDetails?.sentForQuotationChangeApproval?.isSendForApproval}
              //     >
              //       Send for approval
              // </Button>
            //  <span>
               
            //   </span>
            <div>
               <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "5px",
                    alignItems: "center",
                  }}
                >
                  <Button onClick={() => setPaymentReceiptModal(true)} 
                      variant="contained" 
                      color="primary"  
                      disabled={projectDetails?.sentForQuotationChangeApproval?.isSendForApproval || uploadPaymentButtonVisibility}>
                       Upload Payment Receipt
                  </Button>
                    {/* Download and delete button  */}
                    <div style={{ marginTop: '5px' }}>
                      <b>View Payment Receipt: </b>
                      {
                        projectDetails?.sentForQuotationChangeApproval?.paymentReceiptArray?.map((row, index) => (
                          <span key={index}>
                            <DownloadOutlined
                              onClick={() => window.open(row)}
                              style={{ color: "green", cursor: "pointer", fontWeight: "bold", fontSize: '24px' }}
                            />
                          </span>
                        ))
                      }
                    </div>
                    <Dialog open={paymentReceiptModal} onClose={() => setPaymentReceiptModal(false)} aria-labelledby="form-dialog-title" maxWidth='sm'>
                      <DialogTitle style={{ fontSize: "10px"}} id="form-dialog-title">Please Upload Payment Receipt(s) for this quotation</DialogTitle>
                      <Divider />
                      <DialogContent>
                        <span>
                          <ul style={{ fontSize: "15px" , lineHeight: '1.6rem', display : displayUploadReceiptMsg ? 'block' : 'none'}}>
                            {/* <li><span style={{color: "red"}}>For Negative Change Values:</span> Please upload <b>existing payment receipt(s)</b>.</li> */}
                            <li><span style={{color: "green"}}>For Positive Change Values:</span> Please share <b> this quotation screenshots with customer </b>and upload receipt(s) after payment along with existing reciept(s).</li>
                          </ul>
                        </span>
                        <div>
                          <FormControl>
                            <>
                              <input
                                accept=".jpg, .jpeg, .png, .pdf"
                                className={classes.input}
                                id="contained-button-customer"
                                disabled={projectDetails?.sentForQuotationChangeApproval?.isSendForApproval}
                                type="file"
                                multiple
                                onChange={paymentUploadFile}
                                style={{ display: 'none' }}
                                name="view_payment_receipt"
                              />
                              <label htmlFor="contained-button-customer" style={{ marginLeft: 5 }}>
                                <Button variant="contained" color="primary"
                                  disabled={projectDetails?.sentForQuotationChangeApproval?.isSendForApproval}
                                  component="span">
                                  Upload multiple payment Receipt
                                </Button>
                                {addPaymentReceiptFileUploaded && <CheckCircleIcon style={{ fontSize: '24px', fontWeight: 'bold', color: "green" }} />}
                              </label>
                            </>
                          </FormControl>
                       {/* Download and and download delete button  */}
                          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                            {paymentReceiptArray.map((data, i) => {
                              return (
                                <div
                                  key={i}
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "10px",
                                    alignItems: "center",
                                    // marginBottom: "20px",
                                  }}
                                >
                                  <span>
                                    <DownloadOutlined
                                      onClick={() => window.open(data)}
                                      style={{ color: "green", cursor: "pointer", fontWeight: "bold", fontSize: '24px' }}
                                    />
                                    <DeleteOutlineOutlinedIcon
                                      onClick={() => handleDelete(i)}
                                      style={{ color: "red", cursor: "pointer" }}
                                    />
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </DialogContent>
                      <Divider />
                      <DialogActions>
                        <Button onClick={() => setPaymentReceiptModal(false)} variant="outlined" color="secondary">
                          Cancel
                        </Button>
                      </DialogActions>
                    </Dialog>
                {/* send for Approval Button */}
                  <Button variant="contained" color="primary" style={{ float: "right" }}
                    onClick={() => sendForApproval()}
                    disabled={
                      projectDetails?.sentForQuotationChangeApproval?.isSendForApproval || (sendForApprovalDisable  && !sendForApprovalDisable) || 
                      (sendForApprovalDisable && (!paymentReceipt || paymentReceiptArray.length <= 0))
                    } >
                    Send for approval
                  </Button>
                </div>
              </div>
            )
            :
            <Button variant="contained" color="primary" style={{ float: "right" }}
              onClick={() => finalize()}
              disabled = {projectDetails.complimentaryItemStatus === "Sent for Approval"}>
              Finalize quotation
            </Button>
          }
        </div>
        <div style={{display: "flex", justifyContent : "center", alignItems : "center"}}>
          <div style={{ display: "flex", gap: '10px' }}>
            {projectDetails.contractCustomerApproved &&
              projectDetails.contractFinanceApproved &&
              projectDetails.designStages === 'Design Sign-off' && projectDetails?.sentForQuotationChangeApproval?.isSendForApproval && (
                <>
                    <h4
                      style={{
                        color: projectDetails?.sentForQuotationChangeApproval?.DesignManagerApproval === 'Sent for Approval' ? 'red'
                          : projectDetails?.sentForQuotationChangeApproval?.DesignManagerApproval === 'Approved' ? 'green' : 'inherit'
                      }}> DM/ADM <FaArrowRight />
                    </h4>
               
                    <h4 style={{
                      color: projectDetails?.sentForQuotationChangeApproval?.OpsDesignApproval === 'Sent for Approval' ? 'red'
                        : projectDetails?.sentForQuotationChangeApproval?.OpsDesignApproval === 'Approved' ? 'green' : 'inherit'
                    }}> Operation DM <FaArrowRight /></h4>
                  

                  {projectDetails?.sentForQuotationChangeApproval?.checkQtCrAmount?.allPositiveValues !== true  ?
                    (
                    <h4 style={{
                      color: projectDetails?.sentForQuotationChangeApproval?.DgmOpsApproval === 'Sent for Approval' ? 'red'
                        : projectDetails?.sentForQuotationChangeApproval?.DgmOpsApproval === 'Approved' ? 'green' : 'inherit'
                    }}> DGM Ops <FaArrowRight /></h4>
                  ) : null}

                  
                    <h4 style={{
                      color: projectDetails?.sentForQuotationChangeApproval?.FinanceManagerApproval === 'Sent for Approval' ? 'red'
                        : projectDetails?.sentForQuotationChangeApproval?.FinanceManagerApproval === 'Approved' ? 'green' : 'inherit'
                    }}> Finance Manager 
                    <FaArrowRight  style={{visibility : projectDetails?.sentForQuotationChangeApproval?.checkQtCrAmount?.valuesGreaterThan20k === true ||
                    projectDetails?.delayCategoryCheck === true  ? 'visible' : 'hidden' }}/>
                    </h4>
              

                  {projectDetails?.sentForQuotationChangeApproval?.checkQtCrAmount?.valuesGreaterThan20k === true ||
                    projectDetails?.delayCategoryCheck === true ? (
                    <h4 style={{
                      color: projectDetails?.sentForQuotationChangeApproval?.OperationHeadApproval === 'Sent for Approval' ? 'red'
                        : projectDetails?.sentForQuotationChangeApproval?.OperationHeadApproval === 'Approved' ? 'green' : 'inherit'
                    }}> Director Approval </h4>
                  ) : null}
                </>
              )}
          </div>
      </div>
      </Modal>

      {/* Create New Project Version for Additional Quotation */}
      <Dialog open={editProjectModal} onClose={() => setEditProjectModal(false)} aria-labelledby="form-dialog-title" fullWidth maxWidth='sm'>
        <DialogTitle id="form-dialog-title">
          Create New Lead for Additional Quotation
        </DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description" style={{ display: "flex", flexDirection: "column" }}>
            <TextField label="Customer Name" size="small" value={name} style={{ marginBottom: 20, marginTop: 20 }} name="name" onChange={changeHandler} variant="outlined" disabled />
            <TextField label="Customer Email Id" size="small" value={email} style={{ marginBottom: 20 }} name="email" onChange={changeHandler} variant="outlined" disabled />
            <TextField label="Customer Contact No." size="small" value={contact_no} style={{ marginBottom: 20 }} name="contact_no" onChange={changeHandler} variant="outlined" disabled />
            <FormControl fullWidth variant="outlined" size="small" style={{ marginBottom: 20 }}>
              <InputLabel id="demo-simple-select-label">Project Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={projectType}
                label="Project Type"
                name="projectType"
                onChange={changeHandler}
                variant="outlined"
              >
                <MenuItem value='1BHK'>1BHK</MenuItem>
                <MenuItem value='2BHK'>2BHK</MenuItem>
                <MenuItem value='3BHK'>3BHK</MenuItem>
                <MenuItem value='Villa'>Villa</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth variant="outlined" size="small" style={{ marginBottom: 20 }}>
              <InputLabel id="demo-simple-select-label">Property Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={propertyType}
                label="Property Type"
                name="propertyType"
                onChange={changeHandler}
                variant="outlined"
              >
                <MenuItem value='Residential/New Apartment'>Residential/New Apartment</MenuItem>
                <MenuItem value='Independent House'>Independent House</MenuItem>
                <MenuItem value='Commercial'>Commercial</MenuItem>
                <MenuItem value='Renovation'>Renovation</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth variant="outlined" size="small" style={{ marginBottom: 20 }}>
              <InputLabel id="demo-simple-select-label">Scope Of Work</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={scopeOfWork}
                label="Scope Of Work"
                name="scopeOfWork"
                onChange={changeHandler}
                variant="outlined"
              >
                <MenuItem value='Kitchens and Wardrobes'>Kitchens and Wardrobes</MenuItem>
                <MenuItem value='Kitchens and Wardrobes + some other elements(movable+Fixed+Decorative'>Kitchens and Wardrobes + some other elements(movable+Fixed+Decorative</MenuItem>
                <MenuItem value='End to End implementation(Entire Home)'>End to End implementation(Entire Home)</MenuItem>
                <MenuItem value='Commercial Requirements'>Commercial Requirements</MenuItem>
              </Select>
            </FormControl>
            <TextField label="Floor Plan" size="small" value={floorPlan} style={{ marginBottom: 20 }} name="floorPlan" onChange={changeHandler} variant="outlined" />
            <FormControl fullWidth variant="outlined" size="small" style={{ marginBottom: 20 }}>
              <InputLabel id="demo-simple-select-label">Registration Done</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={isRegistered}
                label="Registration Done"
                name="isRegistered"
                onChange={changeHandler}
                variant="outlined"
              >
                <MenuItem value='Yes'>Yes</MenuItem>
                <MenuItem value='No'>No</MenuItem>
                <MenuItem value='Will get it in 15-20 days'>Will get it in 15-20 days</MenuItem>
              </Select>
            </FormControl>
            <TextField label="Sq.Ft Area" value={area} size="small" style={{ marginBottom: 20 }} name="area" onChange={changeHandler} variant="outlined" />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditProjectModal(false)} variant="contained" color="secondary">
            Cancel
          </Button>
          <Button onClick={saveProjectDetail} variant="contained" color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {/* Select Quotation Version for clone */}
      <Dialog open={openSelectQuotationVersion} onClose={() => setOpenSelectQuotationVersion(false)} aria-labelledby="form-dialog-title" maxWidth='sm'>
        <DialogTitle id="form-dialog-title">Select Quotation version to clone</DialogTitle>
        <Divider />
        <DialogContent>
          <div style={{ paddingBottom: 15, paddingTop: 20 }}>
            <FormControl fullWidth >
              <TextField
                id="outlined-select-currency"
                select
                label="Select Quotation Version"
                value={selectedQuotationVersion}
                name="selectedQuotationVersion"
                onChange={(e) => setSelectedQuotationVersion(e.target.value)}
                variant="outlined"
              >
                {
                  quotationList?.map((data, i) => (
                    <MenuItem value={data._id} key={i + 1}>{data.version}__₹ {commaSeparateAmount(data.grandTotal)}</MenuItem>
                  ))
                }
              </TextField>
            </FormControl>
          </div>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={() => setOpenSelectQuotationVersion(false)} variant="outlined" color="secondary">
            Cancel
          </Button>
          <Button onClick={() => cloneSelectedVersion()} variant="contained" color="primary">
            Clone Version
          </Button>
        </DialogActions>
      </Dialog>

      {/* pine lab payment pop up here (deepak) */}
      <Dialog open={openPaymentCheckoutModal} onClose={() => handleCloseCheckout()} aria-labelledby="form-dialog-title"  maxWidth={'lg'}>
        {/* <h3 className='content-heading' style={{padding:"1rem 10rem 1rem 10rem"}}>Payment Checkout Details </h3> */}
        {/* <Divider /> */}
        <DialogContent>
          <>
        {  !hidePaymentModeInput &&  <FormControl fullWidth >

            <TextField
              select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              // disabled={hidePaymentModeInput}
              value={selectedPaymentMode}
              label="Payment Mode"
              name="paymentMOde"
              // onChange={(e) => setSelectedPaymentMode(e.target.value)}
              onChange={(e) => handle_payment_mode_selection(e.target.value)}
              variant="outlined"
              margin="normal"
              >

              {paymentModes.map((el) =>(
                <MenuItem value={el}>{el.name}</MenuItem>
              ))}
              
            </TextField>

              
            </FormControl>}

          {/* <FormControl fullWidth> */}
          {/* <TextField
            select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedPaymentMode}
            label="Select Payment Mode"
            name="paymentMOde"
            // onChange={(e) => setSelectedPaymentMode(e.target.value)}
            onChange={(e) => handle_payment_mode_selection(e.target.value)}
            variant="outlined"
            margin="normal"
            >

            {paymentModes.map((el) =>(
              <MenuItem value={el}>{el.name}</MenuItem>
            ))}
            
          </TextField> */}

        {/* </FormControl> */}

                {/* For External Payment Methods -  PINE LABS */}
                {/* {selectedPaymentMode?.paymentPartner?.name ==='Pine Labs' && */}
                {paymentActionChosen ==='Initiate' &&


                (
                  <FormControl fullWidth>
                    <TextField
                      select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedPosMachine}
                      // label="Select POS Machine"
                      label="Select Terminal"
                      name="DeviceIMEI"
                      onChange={(e) => handle_external_payment_methods(e)}
                      variant="outlined"
                      margin="normal"
                    >
                      {pineLabsPos.map((el) =>
                      (
                        <MenuItem value={el}>{el.deviceName}</MenuItem>
                      ))}
                    
                    </TextField>

                  </FormControl>
                  )}

                  {
                    selectedPaymentMode?.name ==='Others' &&
                    (
                      <FormControl fullWidth>
                        <TextField
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={otherPaymentMethod.other_txn_type}
                          label="Others Type"
                          name="other_txn_type"
                          onChange={(e) => handle_internal_payment_methods(e)}
                          variant="outlined"
                          margin="normal"
                        >     
                        </TextField>
                        {/* <span style={{textAlign:'right', textDecoration: 'underline', cursor: 'pointer'}} onMouseLeave={(e) => (e.target.style.color = 'black')} onMouseEnter={(e) => (e.target.style.color = 'blue')} onClick={handlePaymentIDPreviewClick}>Click to view Instamojo Ref. ID type</span> */}
                        {/* <Button variant="outlined" className={classes.previewButton} onClick={() => window.open('')}>Click to view Instamojo Ref. ID type</Button> */}
                      </FormControl>
                    )
                  }

                  

                  {
                    selectedPaymentMode?.name ==='Bank Transfers - Non Virtual Account' &&
                    (
                      <FormControl fullWidth>
                        <TextField
                          select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={selectedNovVirtualBank}
                          // label="Select POS Machine"
                          label="Select Bank"
                          name="nonVirtualBankName"
                          onChange={(e) => handle_non_virtual_bank_selection(e.target.value)}
                          variant="outlined"
                          margin="normal"
                        >
                          {nonVirtualBanks.map((el) =>
                          (
                            <MenuItem value={el}>{el.name}</MenuItem>
                          ))}
                        
                        </TextField>
                      </FormControl>
                    )
                  }

                  
               

                  {/* For Internal Payment Methods */}
                  {/* {selectedPaymentMode?.paymentPartner?.name === 'DP internal' && */}
                  {true &&
                  
                  (
                  <>
                  { setSelectedPaymentMode.length > 0 && 
                  <>
                  {selectedPaymentMode?.name ==='Cash' &&
                  (
                    <FormControl fullWidth>
                      <TextField
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={otherPaymentMethod.slip_no}
                        label="Enter Slip No"
                        name="slip_no"
                        onChange={(e) => handle_internal_payment_methods(e)}
                        variant="outlined"
                        margin="normal"
                      >     
                      </TextField>
                      <span style={{textAlign:'right', textDecoration: 'underline', cursor: 'pointer'}} onMouseLeave={(e) => (e.target.style.color = 'black')} onMouseEnter={(e) => (e.target.style.color = 'blue')} onClick={handlePaymentIDPreviewClick}>{`Click to view sample ${selectedPaymentMode.name} Slip No.`}</span>

                    </FormControl>
                    )}

                  

                    {selectedPaymentMode?.name ==='Cheque' && (
                    <FormControl fullWidth>
                      <TextField                 
                        labelId="demo-simple-select-label"
                        id="demo-simple-select-cheque"
                        value={otherPaymentMethod.cheQueNo}
                        label="Enter Cheque Number"
                        name="cheQueNo"
                        onChange={(e) => handle_internal_payment_methods(e)}
                        variant="outlined"
                        margin="normal"
                        inputProps={{ maxLength: 6 }}
                      >     
                      </TextField>
                      <span style={{textAlign:'right', textDecoration: 'underline', cursor: 'pointer'}} onMouseLeave={(e) => (e.target.style.color = 'black')} onMouseEnter={(e) => (e.target.style.color = 'blue')} onClick={handlePaymentIDPreviewClick}>{`Click to view sample ${selectedPaymentMode.name} No.`}</span>

                    </FormControl>
                    )}

                    {(paymentActionChosen == 'Record' && (selectedPaymentMode?.name ==='Others' || selectedPaymentMode?.name ==='Link Payment' || selectedPaymentMode?.name ==='UPI' ||  selectedPaymentMode?.name =='Credit Fair' || selectedPaymentMode?.name ==='Card - Pine Labs' || selectedPaymentMode?.name ==='Card - Pine Labs (via ERP)' || selectedPaymentMode?.name === 'Card - Pine Labs (not via ERP)' || selectedPaymentMode?.name ==='Bank Transfers - Virtual Account' || selectedPaymentMode?.name ==='Bank Transfers - Non Virtual Account')) && (
                      
                    
                    <FormControl fullWidth>
                    <TextField                 
                      labelId="demo-simple-select-label"
                      id="demo-simple-select-cheque"
                      value={otherPaymentMethod.trxId}
                      // label=`${selectedPaymentMode?.name == 'Others' ? '' : "Enter Transaction ID"}`
                      label={selectedPaymentMode?.name == 'Others' || selectedPaymentMode?.name == 'Bank Transfers - Non Virtual Account' ? 'Enter Reference ID' : "Enter Transaction ID"}
                      name="trxId"
                      onChange={(e) => handle_internal_payment_methods(e)}
                      variant="outlined"
                      margin="normal"
                          >     
                    </TextField>
                    {!(selectedPaymentMode?.name ==='Card - Pine Labs' || selectedPaymentMode?.name ==='Card - Pine Labs (via ERP)' || selectedPaymentMode?.name === 'Card - Pine Labs (not via ERP)') && <span style={{textAlign:'right', textDecoration: 'underline', cursor: 'pointer'}} onMouseLeave={(e) => (e.target.style.color = 'black')} onMouseEnter={(e) => (e.target.style.color = 'blue')} onClick={handlePaymentIDPreviewClick}>{`Click to view sample ${selectedPaymentMode.name} ${selectedPaymentMode?.name == 'Others' || selectedPaymentMode?.name == 'Bank Transfers - Non Virtual Account' ? 'Reference' : "Transaction"} ID`}</span>}

                    </FormControl>
                    
                    )}

                     <FormControl fullWidth>
                      <TextField
                      id="outlined-select-currency"
                      type="number"
                      label="Amount to be Paid"
                      // value={`₹ ${commaSeparateAmount(storeTenPercentAmount.amount)}`}
                      value={`${storeTenPercentAmount.amount}`}
                      onChange={editAmount}
                      name="selectedAmount"
                      variant="outlined"
                      margin="normal"
                      />
                    </FormControl>

                      {/* {(selectedPaymentMode?.name ==='Cheque' || selectedPaymentMode?.name ==='Cash' || selectedPaymentMode?.name ==='UPI others') && ( */}
                      {/* {(['Cheque', 'Cash', 'Others', 'Credit Fair', 'UPI', 'Link Payment', 'Bank Transfers - Virtual Account', 'Link Payment'].includes(selectedPaymentMode?.name)) && */}
                      {paymentActionChosen == 'Record' &&
                      
                      (
                        <FormControl fullWidth margin="normal">
                          <TextField
                            accept="*"
                            id="contained-button-file"
                            type="file"
                            onChange={onImageChange}
                            style={{ display: 'none' }}
                          />
                          <label htmlFor="contained-button-file">
                            <Button className={classes.yesButton} variant="outlined" component="span">
                              Upload File
                            </Button>{isFileUploaded && <CheckCircleIcon style={{ verticalAlign: 'middle', marginLeft: 10, color: "green", fontSize: 30 }} />}
                          </label>
                        </FormControl>
                      )}
                      </>
                        }
                        </>
                    )}
                    
      
                <div className="container">
                  <div className='container-button1'>
                    <Button className={classes.noButton} variant="outlined" size="small" style={{padding:"1rem 1rem 1rem 1rem"}} onClick={handleCloseCheckout}>Cancel</Button>
                  </div>
                  <div className='container-button2'>
                  {/* {selectedPaymentMode?.paymentPartner?.name ==='Pine Labs'  ?  ( */}
                  {paymentActionChosen == 'Initiate'  ?  (
                    <>
                      <Button className={classes.yesButton} variant="contained" size="small" style={{padding:"1rem 1rem 1rem 1rem"}} onClick={handleSubmitCheckout} disabled={storeTenPercentAmount.amount <=0 ||   selectedPaymentMode.length === 0 || selectedPosMachine.length ===0 || paymentInitiated} >Submit</Button>
                    </>) :
                     <Button className={classes.yesButton} variant="contained" size="small" style={{padding:"1rem 1rem 1rem 1rem"}} onClick={handleOtherTransactionPayment}
                     disabled =
                     {
                      otherPaymentMethod.attachment === null ||
                      
                      (selectedPaymentMode?.name ==='Cheque' && otherPaymentMethod.cheQueNo === "") ||
                      (selectedPaymentMode?.name ==='Cash' && otherPaymentMethod.slip_no === "") ||
                      (selectedPaymentMode?.name ==='Credit Fair' && otherPaymentMethod.trxId === "") ||
                      (selectedPaymentMode?.name ==='UPI' && otherPaymentMethod.trxId === "") ||
                      ((selectedPaymentMode?.name ==='Bank Transfers - Virtual Account' || selectedPaymentMode?.name ==='Card - Pine Labs' || selectedPaymentMode?.name === 'Card - Pine Labs (via ERP)' || selectedPaymentMode?.name ==='Card - Pine Labs (not via ERP)') && otherPaymentMethod.trxId === "") ||
                      (selectedPaymentMode?.name ==='Link Payment' && otherPaymentMethod.trxId === "") ||
                      (selectedPaymentMode?.name ==='Others' && (otherPaymentMethod.trxId === "" || otherPaymentMethod.other_txn_type == "")) ||
                      (selectedPaymentMode?.name ==='Bank Transfers - Non Virtual Account' && selectedNovVirtualBank.length <= 0)
                    }
                     >Submit</Button>
                     }

                  </div>
                </div>
                </>
            </DialogContent>
        </Dialog>

        <Dialog
          open={showPaymentIdPreview}
          onClose={() => setShowPaymentIdPreview(false)}
          // fullWidth
          maxWidth="md"
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >

        <img src={
          (selectedPaymentMode.name == 'Card - Pine Labs (via ERP)' || selectedPaymentMode.name == 'Card - Pine Labs (not via ERP)') ? CardPineLabsPmtPrev : selectedPaymentMode.name == 'Bank Transfers - Virtual Account' ? BankTransfersVirtualAccountPmtPrev : selectedPaymentMode.name == 'Bank Transfers - Non Virtual Account' ? BankTransfersNonVirtualAccountPmtPrev : selectedPaymentMode.name == 'UPI' ? UPIpmtPrev : selectedPaymentMode.name == 'Cash' ? CashPmtPrev : selectedPaymentMode.name == 'Cheque' ? ChequePmtPrev : selectedPaymentMode.name == 'Credit Fair' ? CreditFairPmtPrev : OthersPmtPrev
          // } alt="OthersPmtPrev Image" style={{ marginLeft: '5px', width: '100%', height: '40em'}} />
        } alt="OthersPmtPrev Image" style={{ marginLeft: '5px', width: '100%'}} />
        </Dialog>

        {/* <Dialog open={completedPayment} onClose={() => setCompletedPayment(false)} aria-labelledby="form-dialog-title" fullWidth={true}> */}
        <Dialog open={completedPayment} aria-labelledby="form-dialog-title" fullWidth={true}>
          {/* <h3 className='content-heading'>* please complete the payment and click on completed or Cancel the transaction.</h3> */}
          {/* <h3 className='content-heading'>{`${paymentStatus} \n Please do not close or refresh the page until the payment is done.`}</h3> */}
          {/* <LoadingIcon loading={startLoading} /> */}
          {
            startLoading &&
            <Box display="flex" justifyContent="center" alignItems="center">
              <CircularProgress style={{ color:"#25783c", width: '100px', height: '100px', marginTop: '2%' }}  />
          </Box>
          }
          <h2 className='content-heading'>PAYMENT IS BEING PROCESSED</h2>
          
          <h3 className='content-heading' style={{color: 'red', marginTop: -1}}>*Do not refresh or navigate away from this page.</h3>
        {/* <Divider /> */}
        <DialogContent>
        <FormControl fullWidth >
                <div className="container">
                  <div className='container-button1'>
                    {/* <Button variant="contained" color="secondary" size="small" onClick={() => cancelPayment()} >Cancel Payment</Button> */}
                  </div>

                  {/* <div className='container-button1'>
                    <Button variant="outlined" color="secondary" size="small" onClick={() => setCompletedPayment(false)} >Exit</Button>
                  </div> */}
                  {/* <div className='container-button2'>
                  <Button variant="contained" color="primary" size="small" onClick={handleCompletedPayment} >Complete</Button>
                  </div> */}
                </div>

            </FormControl>          
        </DialogContent>
        </Dialog>
    </div >
  )
  }
export default connect(null, { setLoading, setSnackInfo })(ManageQuotation)