import React, { useState, useEffect } from 'react'
import { setLoading, setSnackInfo } from '../../redux/actions/appAction'
import { connect, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import { makeStyles , withStyles} from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { Typography, Paper, Grid, Divider, Button, TextField, MenuItem, InputAdornment, DialogTitle, Dialog, DialogContent, DialogActions, Toolbar , IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, DialogContentText} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import HttpService from '../../services/httpService';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {  DownloadOutlined } from '@ant-design/icons';
import { useRef } from 'react';
import { CSVLink } from "react-csv";
import { ROLENAME } from '../../utils';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#ccc",
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    textFieldWidth: {
        width: "100%"
    },
}))

const CreateLead = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const { userDetails } = useSelector(state => state.user)
    useEffect(() => {
        getLeadMaster();
        // getUsers();
        getLocationList();
        // eslint-disable-next-line
    }, [])

    const [managerOfSalesTeam, setManagerOfSalesTeam] = useState([]);
    const [leadMaster, setLeadMaster] = useState([]);
    const [existingCustomerName, setExistingCustomerName] = useState("");
    const [dupManagerOfSalesTeam, setDupManagerOfSalesTeam] = useState([]);
    const [cities, setCities] = useState([]);
    const [expcenters, setExpcenters] = useState([]);
    const [invalidEmail, setInvalidEmail] = useState(true);
    const [fileUploaded, setFileUploaded] = useState(false);
    const [audioUploaded, setAudioUploaded] = useState(false);
    const [addCustomersFileUploaded, setAddCustomersFileUploaded] = useState(false);
    const [openAddCustomerDialog, setOpenAddCustomerDialog] = useState(false);
    const [notCustomersAdded, setNotCustomersAdded] = useState()
    const [defaultLocation, setDefaultLocation] = useState(userDetails.selectedLocation);
    const [storeDepartmentName, setDepartmentName] = useState(userDetails.departmentId);
    const [storeCriteria,setStoreCriteria] = useState([])
    const [isJunk, setIsJunk] = useState(false);
    const [isHold, setHold] = useState(false);
    const [leadMoved, setLeadMoved] = useState(false);
    const [openModal, setOpenModal] = useState(false);
  


    const buttonRef = useRef(null)


    const getLeadMaster = async () => {
        props.setLoading(true)
        try {
            const response = await HttpService.getLeadMaster()
            setLeadMaster(response.data)
        } catch (error) {
            props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const getLocationList = async () => {
        props.setLoading(true)
        try {
            const response = await HttpService.getLocationList()
            // let filterLocation = response.data.filter((ele, i) => ele._id === userDetails.selectedLocation)
            setCities(response.data)
        } catch (error) {
            props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const getExperienceCenterByLocation = async (e) => {
        setLeadFormData({ ...leadFormData, [e.target.name]: e.target.value });
        props.setLoading(true)
        try {
            const response = await HttpService.getExpericenCentersByLocation(e.target.value)
            // const centerOption = response.data.filter(ele => ele._id === userDetails.selectedExpCenter);
            setExpcenters(response.data)
            getUsers();
        } catch (error) {
            props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const getManagerBasedOnLocation = (e, city) => {
        setLeadFormData({ ...leadFormData, [e.target.name]: e.target.value })
        let salesManagerArr = []
        dupManagerOfSalesTeam.forEach(ele => {
            ele.experienceCenterId.forEach(ele1 => {
                if(ele1._id === e.target.value) {
                    salesManagerArr.push(ele)
                }
            })
        })
        setManagerOfSalesTeam(salesManagerArr)
    }


    const getUsers = async () => {
        props.setLoading(true)
        try {
            const result = await HttpService.getUsers()
            getSalesManager(result.data);
        } catch (error) {
            console.error(error)
            props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const getSalesManager = (data) => {
        let salesArr = [];
        let salesManagerArr = [];
        data.forEach(ele => {
            if (ele.roles?.find(role => role.name === 'Assistant Sales Manager') && ele?.teamId?.isActive === true && ele.isActive === true) {
                salesArr.push(ele);
                salesManagerArr.push(ele.teamId)
            } else if (ele.roles?.find(role => role.name === 'Sales Manager') && ele?.teamId?.assistantManagers?.length === 0 && ele?.teamId?.isActive === true && ele.isActive === true) {
                salesArr.push(ele);
                salesManagerArr.push(ele.teamId)
            }
        })
        // setManagerOfSalesTeam(salesManagerArr)
        setDupManagerOfSalesTeam(salesArr);
    }

    const [leadFormData, setLeadFormData] = useState({
        customerName: "",
        address: "",
        projectType: "",
        area: "",
        propertyType: "",
        city: "",
        experienceCenterId: "",
        contact_no: "",
        reasonForOtherLeadType : "",
        alternativeContactNo: "",
        email: "",
        scopeOfWork: "",
        expectedTimeToCompletion : "",
        remark: "",
        startInteriors: "",
        isRegistered: "",
        sourceOfLead: "",
        estimatedBudget: "",
        floorPlan: "",
        leadType: "To Be Called",
        assignTo: "",
        assignToManager: "",
        expectedHandoverDate: ""
    })
    const { customerName, address, projectType, area, propertyType, city, experienceCenterId, contact_no,reasonForOtherLeadType, alternativeContactNo, email, scopeOfWork,expectedTimeToCompletion,
        remark, startInteriors, isRegistered, sourceOfLead, estimatedBudget, floorPlan, leadType, assignTo, assignToManager, expectedHandoverDate } = leadFormData

    const [mobileError, setMobileError] = useState("");
    const [btnDisable, setBtnDisable] = useState(false);
    const checkMobileExist = async (e) => {
        // regex to except only number in input field
        let mobileNo = e.target.value.replace(/[^0-9]/g, "");
        // eslint-disable-next-line no-new-wrappers
        let n = new String(e.target.value);
        setLeadFormData({ ...leadFormData, [e.target.name]: mobileNo })
        if (n.length === 10) {
            props.setLoading(true)
            try {
                const response = await HttpService.checkCustomer(mobileNo)
                setExistingCustomerName(response.data)
                setLeadFormData({ ...leadFormData, [e.target.name]: mobileNo })
            } catch (error) {
                setExistingCustomerName('');
            }
            props.setLoading(false)
            setMobileError('')
            setBtnDisable(false);
        } else if (n.length >= 10) {
            setMobileError('Invalid Mobile Number')
            setBtnDisable(true);
        } else {
            setBtnDisable(true)
        }
    }
    const [alternateMobileError, setAlternateMobileError] = useState("");
    const checkAlternateNumber = (e) => {
        setLeadFormData({ ...leadFormData, [e.target.name]: e.target.value })
        let alternateMobile = new String(e.target.value);
        if (alternateMobile.length === 10) {
            setLeadFormData({ ...leadFormData, [e.target.name]: e.target.value })
            setAlternateMobileError('')
        } else if (alternateMobile.length >= 10) {
            setAlternateMobileError('Invalid Mobile Number')
        }
    }

    const checkAssignDetail = (e) => {
        if (e.target.value) {
            let teamDetail = [];
            dupManagerOfSalesTeam.forEach(ele => {
                if (ele?._id === e.target.value) {
                    teamDetail.push(ele)
                }
            })
            setLeadFormData({
                ...leadFormData, [e.target.name]: e.target.value,
                'assignToName': teamDetail[0].name,
                'assignTo': teamDetail[0]._id,
                'department': teamDetail[0].departmentId._id,
                'team': teamDetail[0].teamId._id,
                'status': "Lead Received",
                'teamName': teamDetail[0].teamId.name,
            })
        } else {
            setLeadFormData({ ...leadFormData, [e.target.name]: e.target.value, 'assignToName': '', 'assignTo': '', 'department': '', 'team': '',"teamName" : ''})
        }
    }
   
    const addLead = async () => {
        // Check for required fields
        if (scopeOfWork === '' || expectedTimeToCompletion === '' || estimatedBudget === '') {
            return props.setSnackInfo("Please Fill All the Validate Field", 'warning');
        }
        if(leadMoved){
            if (reasonForOtherLeadType === '' ) {
                return props.setSnackInfo("Please Enter the Reason to Assign Lead Other Than Real to Sales Person", 'warning');
            }
        }
    
        // Find the selected experience center
        const selectedExpCenter = expcenters.find(expcenter => expcenter._id === leadFormData?.experienceCenterId);
        const selectedCenterName = selectedExpCenter ? selectedExpCenter.name : '';
    
        // Find the selected city
        const findCity = cities.find(city => city._id === leadFormData?.city);
        const selectedCity = findCity ? findCity.name : '';
    
        // Initialize the object with city and experience center names
        let obj = {
            cityName: selectedCity,
            experienceCenterName: selectedCenterName,
        };
    
    
        if(leadType === "To Be Called"){
            obj = {
                ...obj,  // Spread the initial object first to preserve city and experience center names
                ...leadFormData,  // Spread leadFormData to add/overwrite its properties
                assignTo: userDetails._id,
                department: userDetails.departmentId,
                team: userDetails.teamId._id,
                status: leadFormData.leadType,
                teamName: userDetails.teamId.name,
                assignToName: userDetails.name,
            };
        }
        // Conditional logic based on leadMoved and leadType
       else if (!leadMoved && leadType !=='Real') {
            if (["Hold","Junk"].includes(leadFormData.leadType)) {
                console.log("first")
                obj = {
                    ...obj,  // Spread the initial object first to preserve city and experience center names
                    ...leadFormData,  // Spread leadFormData to add/overwrite its properties
                    assignTo: userDetails._id,
                    department: userDetails.departmentId,
                    team: userDetails.teamId._id,
                    status: leadFormData.leadType,
                    teamName: userDetails.teamId.name,
                    assignToName: userDetails.name,
                };
            } 
            else {
                obj = {
                    ...obj,  // Spread the initial object first to preserve city and experience center names
                    ...leadFormData,  // Spread leadFormData to add/overwrite its properties
                    assignTo: userDetails._id,
                    department: userDetails.departmentId,
                    team: userDetails.teamId._id,
                    status: leadFormData.leadType,
                    teamName: userDetails.teamId.name,
                    assignToName: userDetails.name,
                };
            }
        } else {
            if (leadFormData.leadType === 'Real') {
                obj = {
                    ...obj,  // Spread the initial object first to preserve city and experience center names
                    ...leadFormData,  // Spread leadFormData to add/overwrite its properties
                    teamName: leadFormData.teamName,
                    assignToName: leadFormData.assignToName,
                };
            } else {
                obj = {
                    ...obj,  // Spread the initial object first to preserve city and experience center names
                    ...leadFormData,  // Spread leadFormData to add/overwrite its properties
                    teamName: leadFormData.teamName,
                    assignToName: leadFormData.assignToName,
                    leadType : 'Real'
                };
            }
        }
        props.setLoading(true)
        try {
        const response = await HttpService.createCustomer(obj)
            history.push('/qtProjects');
            props.setSnackInfo(response?.data ?? "Customer Created successfully", "success")
            
        } catch (error) {
            props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }
    const validateEmail = (e) =>{
        let regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
        setLeadFormData({ ...leadFormData, [e.target.name]: e.target.value })
        if (regex.test(e.target.value)) {
            setInvalidEmail(false)
        }else{
            setInvalidEmail(true)
        }
    }

    // function to handle full name input 
    const fullNameChangeHandler = (event)=>{
        const value = event.target.value;
        
        // Regex pattern to check for the presence of special characters
        // const specialCharacters = /^[^$&+,:;=?@#|'<>.^*()%!-€]*$/;

        // Regex pattern to check for the presence of only letters (uppercase & lowercase) and spaces and excluding special characters and numeric value
        const specialCharacters = /^[a-zA-Z\s]*$/;
        
        const containsSpecialCharacters = !specialCharacters.test(value);
        
        // If special characters is present display alert
        if (containsSpecialCharacters) {
            props.setSnackInfo("Input may only contain letters (uppercase or lowercase) and spaces, excluding special characters.","warning");
        } else {
            setLeadFormData({ ...leadFormData, [event.target.name]: event.target.value });
        }
    }
    // function to handle sqFt area 
    const sqFtAreaChangeHandler = (event)=>{
        // regex to except only number in input field
        let area = event.target.value.replace(/[^0-9]/g, "");
        setLeadFormData({ ...leadFormData, [event.target.name]: area })
    }

    const onImageChange = async (e) => {
        const {name,value}=e.target
        props.setLoading(true)
        const fd = new FormData()
        fd.append('file', e.target.files[0])
        fd.append('customerName', "floor" + '_' + "plan")
        try {
            const fileUploadRes = await HttpService.uploadFile(fd);
            setLeadFormData({
                ...leadFormData,
                [name] : fileUploadRes.data
            })
            if ([name][0] === "floorPlanAttachment"){
            setFileUploaded(true);
            } else if ([name][0] === "recordingFile"){
            setAudioUploaded(true)
            }
        } catch (err) {
            props.setSnackInfo(err?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }
    // for expected handover date: all dates after today can be choose
    const today = new Date().toISOString().split('T')[0];

    //  Add customers into erp while uploading the files
      const handleFileChange = async (e) => {
          props.setLoading(true)
          const fd = new FormData()
          fd.append('file', e.target.files[0])
          let randomData= Math.floor(Math.random() * 100)
          fd.append('customerName', "BulkData" + '_' + randomData)
          try {
              const fileUploadRes = await HttpService.uploadFile(fd);
              await callAddCustomers(fileUploadRes.data);
              setAddCustomersFileUploaded(true);
          } catch (err) {
              props.setSnackInfo("Something went wrong", "error")
          }
          props.setLoading(false)
      }
//  Send file and post to server customers list
      const callAddCustomers = async (filepath) => {
        props.setLoading(true)
        try{
           let res = await HttpService.addCustomersToErp({filepath});
           setNotCustomersAdded(res.data);
            props.setSnackInfo("Leads Created Seccessfully!", "success");
        }catch(err){
            props.setSnackInfo("Something went wrong", "error")
        }
        props.setLoading(false)
    }
    // excel sample data download for adding multiple leads
    const headers = [
        { key: "customerName", label: "Customer Name" },
        { key: "apartmentName", label: "Apartment Name" },
        { key: "bhk", label: "BHK" },
        { key: "sqft", label: "Sqft" },
        { key: "branch", label: "Branch" },
        { key: "contactNo", label: "Contact No" },
        { key: "emailId", label: "Email Id" },
        { key: "scopeWork", label: "Scope of work" },
        { key: "whenStart", label: "When do we start" },
        { key: "registration", label: "Registration done" },
        { key: "source", label: "Source" },
        { key: "assignTo", label: "Assign to" },
    ];

    const excelData = [
        {
            customerName: "Test Customer",
            apartmentName: "Amrutha Heights phase 2",
            bhk: '2bhk',
            sqft: '1232',
            branch: 'HSR',
            contactNo: 9334657863,
            emailId: "pritimodanwal@naraci.com",
            scopeWork: "Kitchen & wardrobes",
            whenStart: "This month",
            registration : "Yes",
            source: "Facebook",
            assignTo: 'Daksha.Jain1@decorpot.com'
        }
    ]
    const excelSampleDownload = () => {
        try {
            setTimeout(() => {
                buttonRef.current.link.click();
            },1000);
        } catch (err) {
            console.error(err.message);
        }
        props.setLoading(false)
    }
    const getLeadCriteria = async () => {
        let res = await HttpService.getLeadCriteria();
        const extractCityData = res.data
        .filter(el => el._id === leadFormData?.city);
        setStoreCriteria(extractCityData[0])
    }
    const handleCriteria = (e) => {   
        const { name, value } = e.target;

        const newLeadFormData = { ...leadFormData, [name]: value };
        if (newLeadFormData.estimatedBudget.includes('Junk')){
          setIsJunk(true)
        } else {
          setIsJunk(false);
        }
        setLeadFormData(newLeadFormData);


            
    }
    const handleClickOpen = () => {
        setOpenModal(true);
      };
    
      const handleClose = () => {
        addLead()
        setOpenModal(false);
      };
    
      const handleYes = () => {
        setLeadMoved(true);
        setOpenModal(false);
      };

      useEffect(()=>{
        getLeadCriteria()
    },[leadFormData.city])
    
    return (
        <div>
            <div className={classes.root}>
                <Paper>
                    <Grid container>
                        <div style={{ display: "flex", flexDirection: "column", margin: "auto", width: '60%' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div>
                                <Typography variant="h5" style={{ marginTop: 20 }}>
                                    Decorpot Lead Details
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    Customer details for Keeping a track!
                                </Typography>
                            </div>
                            {
                                userDetails.roles.find(role => role.name !== ROLENAME.TeleCallerManager && role.name !==ROLENAME.TeleCallerUser) && (
                                <>
                            <Button style={{ marginLeft: 40, marginTop: 20, marginBottom: 20, display: 'flex' }} color="primary" variant="contained" onClick={()=> setOpenAddCustomerDialog(true)}>Upload Bulk Leads</Button>
                                {
                                    <>
                                        <CSVLink
                                            headers={headers}
                                            filename={"bulk leads data" + '.xlsx'}
                                            data={excelData}
                                            ref={buttonRef}
                                        />
                                        <Button style={{ marginLeft: 10, marginTop: 20, marginBottom: 20, display: 'flex', background: "green", color: "white" }} onClick={excelSampleDownload}><DownloadOutlined style={{ marginRight: 5 }} /> Excel Sample</Button>
                                    </>
                                }
                                    </>
                                )}
                        </div>
                            <Dialog
                                open={openAddCustomerDialog}
                                onClose={() => setOpenAddCustomerDialog(false)}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"

                            >
                                <DialogTitle id="alert-dialog-title">{"Add Customers To ERP"}</DialogTitle>
                                <DialogContent>
                                    <FormControl style={{ marginRight: 10, marginTop: 20, marginBottom: 10, display: 'block' }}>
                                        <>
                                            <input
                                                accept=".xlsx, .xls"
                                                className={classes.input}
                                                id="contained-button-customer"
                                                multiple
                                                type="file"
                                                onChange={handleFileChange}
                                                style={{ display: 'none' }}
                                                name="filepath"
                                            />
                                            <label htmlFor="contained-button-customer" style={{ marginLeft: 'auto' }}>
                                                <Button variant="contained" color="primary"
                                                    component="span" startIcon={<CloudUploadIcon />}>
                                                    Upload Leads File
                                                </Button>
                                                {addCustomersFileUploaded && <CheckCircleIcon style={{ color: "green", fontSize: 30 }} />}
                                            </label>
                                        </>
                                    </FormControl>

                                    {addCustomersFileUploaded &&
                                        <>
                                            <Typography variant="h6" style={{ marginTop: 20, color: 'red' }}>
                                                Leads Not Created!
                                            </Typography>

                                            <TableContainer component={Paper}>
                                                <Table className={classes.table} stickyHeader aria-label="sticky table">
                                                    <TableHead>

                                                    </TableHead>
                                                    <TableHead>
                                                        <StyledTableRow>
                                                            <StyledTableCell>Customer Name</StyledTableCell>
                                                            <StyledTableCell>Contact No.</StyledTableCell>
                                                            <StyledTableCell>Experience Center</StyledTableCell>
                                                            <StyledTableCell>Assign To</StyledTableCell>
                                                            <StyledTableCell>Error Message</StyledTableCell>

                                                        </StyledTableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                   { notCustomersAdded.map((ele, i) => (
                                                        <StyledTableRow key={i}>
                                                            <StyledTableCell>{ele.name}</StyledTableCell>
                                                            <StyledTableCell>{ele.contactNo}</StyledTableCell>
                                                            <StyledTableCell>{ele.expCenter}</StyledTableCell>
                                                            <StyledTableCell>{ele.email}</StyledTableCell>
                                                            <StyledTableCell style={{color: 'red'}}>{ele.message}</StyledTableCell>
                                                        </StyledTableRow>
                                                        ))
                                                   }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </>
                                    }
                                </DialogContent>

                                <DialogActions>
                                    <Button onClick={() => setOpenAddCustomerDialog(false)} autoFocus >
                                        Cancel
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            <Divider />
                            <TextField label="Full Name *" size="small" style={{ marginBottom: 20, textAlign: "center", marginTop: 20 }} name="customerName" value={customerName} onChange={(event)=>fullNameChangeHandler(event)} variant="outlined" />

                            <TextField label="Apartment / Independent House Name & Location *" size="small" style={{ marginBottom: 20, textAlign: "center" }} name="address" value={address} onChange={(e) => setLeadFormData({ ...leadFormData, [e.target.name]: e.target.value })} variant="outlined" />

                            <FormLabel component="legend">BHK Type</FormLabel>
                            <RadioGroup size="small" style={{ display: 'block', marginBottom: 20 }} aria-label="BHK" name="projectType" value={projectType} onChange={(e) => setLeadFormData({ ...leadFormData, [e.target.name]: e.target.value })}>
                                <FormControlLabel value="1BHK" control={<Radio />} label="1BHK" />
                                <FormControlLabel value="2BHK" control={<Radio />} label="2BHK" />
                                <FormControlLabel value="3BHK" control={<Radio />} label="3BHK" />
                                <FormControlLabel value="Villa" control={<Radio />} label="Villa" />
                            </RadioGroup>

                            <TextField label="Sq.Ft Area *" size="small" style={{ marginBottom: 20, textAlign: "center" }} name="area" value={area} onChange={sqFtAreaChangeHandler} variant="outlined" />

                            <FormControl fullWidth style={{ marginBottom: 20 }}>
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    label="Property Type"
                                    value={propertyType}
                                    name="propertyType"
                                    onChange={(e) => setLeadFormData({ ...leadFormData, [e.target.name]: e.target.value })}
                                    variant="outlined"
                                    size="small"
                                >
                                    <MenuItem value='Residential/New Apartment'>Residential/New Apartment</MenuItem>
                                    <MenuItem value='Independent House'>Independent House</MenuItem>
                                    <MenuItem value='Commercial'>Commercial</MenuItem>
                                    <MenuItem value='Renovation'>Renovation</MenuItem>
                                </TextField>
                            </FormControl>

                            <FormControl fullWidth style={{ marginBottom: 20 }}>
                                <TextField
                                    select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name="city"
                                    value={city}
                                    label="City *"
                                    onChange={getExperienceCenterByLocation}
                                    variant="outlined"
                                    size="small"
                                >
                                    {
                                        cities?.map((data, i) => (
                                            <MenuItem value={data._id} key={i + 1}>{data.name}</MenuItem>
                                        ))
                                    }
                                </TextField>
                            </FormControl>

                            <FormControl fullWidth style={{ marginBottom: 20 }}>
                                <TextField
                                    select
                                    labelId="demo-simple-select-label"
                                    id="demo-expcenter-simple-select"
                                    name="experienceCenterId"
                                    value={experienceCenterId}
                                    label="Experience Center *"
                                    onChange={(e) => getManagerBasedOnLocation(e, leadFormData.city)}
                                    variant="outlined"
                                    size="small"
                                >
                                    {
                                        expcenters?.map((data, i) => (
                                            <MenuItem value={data._id} key={i + 1}>{data.name}</MenuItem>
                                        ))
                                    }
                                </TextField>
                            </FormControl>

                            <TextField label="Contact Number *" size="small" style={{ marginBottom: 20, textAlign: "center" }} name="contact_no" value={contact_no} onChange={checkMobileExist} variant="outlined" />
                            <span style={{ color: "red", marginBottom: 20, marginTop: -20 }}>{existingCustomerName}</span>
                            <span style={{ color: 'red', marginBottom: 20, marginTop: -20 }}>{mobileError}</span>
                            <TextField label="Alternate Contact Number" size="small" style={{ marginBottom: 20, textAlign: "center" }} name="alternativeContactNo" value={alternativeContactNo} variant="outlined" onChange={checkAlternateNumber} />
                            <span style={{ color: "red", marginBottom: 20, marginTop: -20 }}>{alternateMobileError}</span>
                            <TextField label="Email *" style={{ marginBottom: 20, textAlign: "center" }} size="small" name="email" value={email} onChange={validateEmail} variant="outlined" />
                            {
                                invalidEmail && <>
                                    <div style={{ marginBottom: 5, fontWeight: 700 }}>Please update the valid customer email id</div> <br />
                                </>
                            }
                           <FormControl fullWidth style={{ marginBottom: 20 }}>
                                <TextField
                                    select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={scopeOfWork}
                                    name="scopeOfWork"
                                    label="Scope Of Work *"
                                    onChange={(e) => setLeadFormData({ ...leadFormData, [e.target.name]: e.target.value })}
                                    variant="outlined"
                                    size="small"
                                >
                                    {
                                        storeCriteria?.scopeData?.map((data, i) => (
                                            <MenuItem value={data?.name} key={i + 1}>{data.name}</MenuItem>
                                        ))
                                    }
                                </TextField>
                            </FormControl>

                            <FormControl fullWidth style={{ marginBottom: 20 }}>
                                <TextField
                                    select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={estimatedBudget}
                                    name="estimatedBudget"
                                    label="Estimated Budget *"
                                    onChange={(e) => handleCriteria(e)}
                                    variant="outlined"
                                    size="small"
                                >
                                    {
                                        storeCriteria?.budgets?.map((data, i) => (
                                            <MenuItem value={data.budget} key={i + 1}>{data.budget}</MenuItem>
                                        ))
                                    }
                                </TextField>
                            </FormControl>

                            <FormControl fullWidth style={{ marginBottom: 20 }}>
                                <TextField
                                    select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={expectedTimeToCompletion}
                                    name="expectedTimeToCompletion"
                                    label="ExpectedTime ToCompletion *"
                                    onChange={(e) => handleCriteria(e)}
                                    variant="outlined"
                                    size="small"
                                >
                                {
                                        !isJunk ? 
                                        storeCriteria?.timelines?.map((data, i) => (
                                            !data.timeline.includes('Hold') && (
                                            <MenuItem value={data.timeline} key={i}>{data.timeline}</MenuItem>
                                            )
                                        ))
                                        : 
                                        storeCriteria?.timelines?.filter(data => data.timeline.includes('Hold')).map((data, i) => (
                                            <MenuItem value={data.timeline} key={i}>{data.timeline}</MenuItem>
                                        ))
                                    }
                                   
                                </TextField>
                            </FormControl>

                            

                            <TextField label="Other Details or Remark" size="small" style={{ marginBottom: 20, textAlign: "center" }} name="remark" value={remark} onChange={(e) => setLeadFormData({ ...leadFormData, [e.target.name]: e.target.value })} variant="outlined" />

                            <FormControl fullWidth style={{ marginBottom: 20 }}>
                                <TextField
                                    select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={startInteriors}
                                    name="startInteriors"
                                    label="When do you want to start the interiors?"
                                    onChange={(e) => setLeadFormData({ ...leadFormData, [e.target.name]: e.target.value })}
                                    variant="outlined"
                                    size="small"
                                >
                                    <MenuItem value='This month'>This month</MenuItem>
                                    <MenuItem value='1-4 months'>1-4 months</MenuItem>
                                    <MenuItem value='>4months'> More Than 4 months</MenuItem>
                                </TextField>
                            </FormControl>

                            <FormControl fullWidth style={{ marginBottom: 20 }}>
                                <TextField
                                    select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={isRegistered}
                                    name="isRegistered"
                                    label="Registration Done/ Occupation Certificate Received?"
                                    onChange={(e) => setLeadFormData({ ...leadFormData, [e.target.name]: e.target.value })}
                                    variant="outlined"
                                    size="small"
                                >
                                    <MenuItem value='Yes'>Yes</MenuItem>
                                    <MenuItem value='No'>No</MenuItem>
                                    <MenuItem value='Will get it in 15-20 days'>Will get it in 15-20 days</MenuItem>
                                </TextField>
                            </FormControl>

                            <FormControl fullWidth style={{ marginBottom: 20 }}>
                                <TextField
                                    select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={sourceOfLead}
                                    name="sourceOfLead"
                                    label="Source (Only on Incoming Calls) *"
                                    onChange={(e) => setLeadFormData({ ...leadFormData, [e.target.name]: e.target.value })}
                                    variant="outlined"
                                    size="small"
                                >
                                    {
                                        leadMaster?.map((data, i) => (
                                            <MenuItem value={data._id} key={i + 1}>{data.name}</MenuItem>
                                        ))
                                    }
                                </TextField>
                            </FormControl>


                            <FormControl fullWidth style={{ marginBottom: 20 }}>
                                <TextField
                                    select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={floorPlan}
                                    name="floorPlan"
                                    label="Floor Plan"
                                    onChange={(e) => setLeadFormData({ ...leadFormData, [e.target.name]: e.target.value })}
                                    variant="outlined"
                                    size="small"
                                >
                                    <MenuItem value='Yes'>Yes</MenuItem>
                                    <MenuItem value='No'>No</MenuItem>
                                </TextField>
                            </FormControl>
                            
                             {/* Add new field Expected Handover Date when create a new lead */}
                             <TextField
                                id="filled-start-adornment"
                                name="expectedHandoverDate"
                                sx={{ m: 1, width: '25ch' }}
                                type='date'
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">Expected Handover Date</InputAdornment>,
                                }}
                                onChange={(e) => setLeadFormData({ ...leadFormData, [e.target.name]: e.target.value })}
                                variant="outlined"
                                inputProps={{
                                    min: today, // Set the minimum date to today
                                }}
                                />

                            <FormControl style={{ marginLeft: 10, marginRight: 10, marginTop: 20, marginBottom: 20, display: 'block' }}>
                                <>
                                        <input
                                            accept="*"
                                            className={classes.input}
                                            id="contained-button-file"
                                            multiple
                                            type="file"
                                            onChange={onImageChange}
                                            style={{ display: 'none' }}
                                            name="floorPlanAttachment"
                                        />
                                        <label htmlFor="contained-button-file">
                                            <Button variant="contained" color="primary"
                                                component="span" startIcon={<CloudUploadIcon />}>
                                                Upload Floor Plan
                                            </Button>
                                            {
                                                fileUploaded && <CheckCircleIcon style={{ verticalAlign: 'middle', marginLeft: 10, color: "green", fontSize: 30 }} />
                                            }
                                        </label>
                                    </>
                            </FormControl>
                            <FormControl style={{ marginLeft: 10, marginRight: 10, marginTop: 20, marginBottom: 30, display: 'block' }}>
                                <>
                                    <input
                                        accept="*"
                                        className={classes.input}
                                        id="contained-button-file1"
                                        multiple
                                        type="file"
                                        onChange={onImageChange}
                                        style={{ display: 'none' }}
                                        name="recordingFile"
                                    />
                                    <label htmlFor="contained-button-file1">
                                        <Button variant="contained" color="primary"
                                            component="span" startIcon={<CloudUploadIcon />}>
                                            Upload Audio File
                                        </Button>
                                        {
                                            audioUploaded && <CheckCircleIcon style={{ verticalAlign: 'middle', marginLeft: 10, color: "green", fontSize: 30 }} />
                                        }
                                    </label>
                                </>
                            </FormControl>

                            <FormControl fullWidth style={{ marginBottom: 20 }}>
                                <TextField
                                    select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name="leadType"
                                    value={leadType}
                                    label="Status (Do not ask)"
                                    onChange={(e) => setLeadFormData({ ...leadFormData, [e.target.name]: e.target.value })}
                                    variant="outlined"
                                    size="small"
                                >
                                    { (!isJunk  &&  !isHold)  && <MenuItem value='Real'>Real</MenuItem>}
                                    <MenuItem value='Junk'>Junk</MenuItem>
                                    <MenuItem value='Hold'>Hold</MenuItem>
                                    { (!isJunk  &&  !isHold)  &&    <MenuItem value='To Be Called'>To Be Called</MenuItem>}
                                </TextField>
                            </FormControl>

                            

                            {
                              leadType !== 'To Be Called' &&  leadType !=='Real' && leadMoved ?
                                <TextField
                                label="Reason To Assign Other Lead*"
                                size="small" 
                                style={{ marginBottom: 20, textAlign: "center" }} 
                                name="reasonForOtherLeadType" 
                                value={reasonForOtherLeadType}
                                onChange={(e) => setLeadFormData({ ...leadFormData, [e.target.name]: e.target.value })}
                                variant="outlined" />
                                : ''
                            }

                            {
                               (leadType !== 'To Be Called' &&  leadType === 'Real' ||  leadMoved)  &&
                                (
                                <FormControl fullWidth style={{ marginBottom: 20 }}>
                                    <TextField
                                        select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name="assignToManager"
                                        value={assignToManager}
                                        label="Assign To Team"
                                        onChange={checkAssignDetail}
                                        variant="outlined"
                                        size="small"
                                    >
                                         {managerOfSalesTeam?.map((data) => (
                                            <MenuItem key={data._id} value={data._id}>
                                                {`${data?.teamId?.name} - ${data?.name}`}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </FormControl>
                                )
                            }

                            {
                            ( leadType === 'To Be Called' || leadType === 'Real' || leadMoved) ? 
                            <Button onClick={addLead} variant="contained" color="primary" style={{ marginBottom: 20, width: "30%" }}
                                disabled={btnDisable || customerName === '' || address === '' || area === '' || city === '' || experienceCenterId === '' || email === '' || invalidEmail}
                                >
                                Submit
                            </Button>
                            : 
                            ( leadType !== 'Real' && !leadMoved) && (
                            <div>
                            <Button
                              variant="contained"
                              color="primary"
                              style={{ marginBottom: 20, width: "30%" }}
                              onClick={handleClickOpen}
                              disabled={btnDisable || customerName === '' || address === '' || area === '' || city === '' || experienceCenterId === '' || email === '' || invalidEmail}

                            >
                              Submit
                            </Button>
                      
                            <Dialog
                            open={openModal}
                            onClose={(event, reason) => {
                                if (reason !== 'backdropClick') {
                                handleClose();
                                }
                            }}
                            >
                            <DialogTitle>{"Do You Want to Move Lead to Sales User?"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                If you select Yes, the lead will be moved to a Sales User.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="primary">
                                No
                                </Button>
                                <Button onClick={handleYes} color="primary" autoFocus>
                                Yes
                                </Button>
                            </DialogActions>
                            </Dialog>
                      </div>)
                      
                        }
                        </div>

                    </Grid>
                </Paper>

            </div>
        </div>
    )
}

export default connect(null, { setLoading, setSnackInfo })(CreateLead)