import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { setLoading, setSnackInfo } from '../../redux/actions/appAction'
import HttpService from '../../services/httpService'
import PropTypes from 'prop-types';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import TableHead from '@material-ui/core/TableHead';
import { DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import moment from 'moment'
import { Modal } from 'antd';
// import 'antd/dist/antd.css';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles({
  table: {
    minWidth: 500,
  },
  textField: {
    width: "150px"
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#ccc",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const Inwarding = (props) => {
  const classes = useStyles2();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [procurementForInwarding, setProcurementForInwarding] = useState([])

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, procurementForInwarding.length - page * rowsPerPage)

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    getAllProcurementsForInwarding()
    clearAll()
    // eslint-disable-next-line
  }, [])

  const getAllProcurementsForInwarding = async () => {
    props.setLoading(true)
    try {
      const result = await HttpService.getAllProcurementsForInwarding()
      setProcurementForInwarding(result.data)
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  const [inwardingModal, setInwardingModal] = useState(false)
  const [procurementDetails, setProcurementDetails] = useState({})

  const showInwardingModal = async (orderId) => {
    props.setLoading(true)
    try {
      const result = await HttpService.getProcurementByOrderIdForInwarding(orderId)
      setProcurementDetails(result.data)
      setInwardingModal(true)
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  const [invoiceModal, setInvoiceModal] = useState(false)
  const [material, setMaterial] = useState({})
  const [index, setIndex] = useState(null)
  const openInvoiceModal = (materialId, i) => {
    setMaterial(procurementDetails.materials.filter(material => material?.materialId._id === materialId)[0])
    setIndex(i)
    setInvoiceModal(true)
  }
  const [inwardingQuantity, setInwardingQuantity] = useState([])
  const [inwardingDate, setInwardingDate] = useState([])

  const [inwardingDetails, setInwardingDetails] = useState({
    invoiceNumber: "",
    invoiceDate: ""
  })

  const { invoiceNumber } = inwardingDetails

  const handleSubmit = async () => {
    setInvoiceModal(false)
    props.setLoading(true)
    try {
      let obj = {
        deliveryDate: inwardingDate[index],
        invoiceDate: inwardingDetails.invoiceDate,
        invoiceNumber: invoiceNumber,
        inwardingQuantity: inwardingQuantity[index],
        materialId: material.materialId,
        pricePerUnit: material.requestedPrice,
        projectMaterialId: material.projectMaterialId,
        quantity: material.quantity,
        receivedQuantity: material.requestedQuantity,
        remarks: procurementDetails.remarks,
        scopeId: material.scopeId,
        status: procurementDetails.status,
        unitId: material.unitId,
        _id: material._id
      }
      const result = await HttpService.inwardMaterialToProcurement(procurementDetails._id, obj)
      if (result.status === 200) {
        props.setSnackInfo("Material Inwarded", "success")
        setInwardingModal(false)
        setIndex(null)
        clearAll()
      }
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  const clearAll = async () => {
    setInwardingQuantity([])
    setInwardingDate([])
    setInwardingDetails({
      invoiceNumber: "",
      invoiceDate: ""
    })
    getAllProcurementsForInwarding()
  }

  const [orderId, setOrderId] = useState("")
  const [projectCode, setProjectCode] = useState("")

  const fetchProjectSpecificInwarding = async (code) => {
    props.setLoading(true)
    try {
      const result = await HttpService.getAllProcurementsForInwarding(code)
      setProcurementForInwarding(result.data)
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  const handleInwardingChange = (e, index) => {
    props.setLoading(true)
    try {
      let inwardQuantity = [...inwardingQuantity]
      inwardQuantity[index] = e.target.value
      setInwardingQuantity(inwardQuantity)
    } catch (error) {
      console.error(error)
      props.setSnackInfo("Error while adding inwarding quantity", "error")
    }
    props.setLoading(false)

  }
  const handleInwardingDateChange = (e, index) => {
    props.setLoading(true)
    try {
      let inwardDate = [...inwardingDate]
      inwardDate[index] = e.target.value
      setInwardingDate(inwardDate)
    } catch (error) {
      console.error(error)
      props.setSnackInfo("Error while adding inwarding date", "error")
    }
    props.setLoading(false)
  }
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 25 }}>
        <div style={{ display: "flex" }}>
          <TextField label="Search For Order Id" name="orderId" value={orderId} onChange={(e) => setOrderId(e.target.value)} variant="outlined" />
          <Button onClick={() => showInwardingModal(orderId)} style={{ marginLeft: 10 }} variant="contained" color="primary">
            Search
          </Button>
        </div>
        <div style={{ display: "flex" }}>
          <TextField label="Project Code" name="orderId" value={projectCode} onChange={(e) => setProjectCode(e.target.value)} variant="outlined" />
          <Button onClick={() => fetchProjectSpecificInwarding(projectCode)} style={{ marginLeft: 10 }} variant="contained" color="primary">
            Search
          </Button>
        </div>
      </div>


      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Sl No</StyledTableCell>
              <StyledTableCell>Order Id</StyledTableCell>
              <StyledTableCell>Procurement type</StyledTableCell>
              <StyledTableCell>Created At</StyledTableCell>
              <StyledTableCell>Vendor Name</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? procurementForInwarding.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : procurementForInwarding
            ).map((data, i) => (
              <StyledTableRow key={i + 1}>
                <StyledTableCell >{i + 1}</StyledTableCell>
                <StyledTableCell >{data?.orderId}</StyledTableCell>
                <StyledTableCell >{data?.procurementType}</StyledTableCell>
                <StyledTableCell >{moment(data?.createdAt).format('DD-MM-YYYY')}</StyledTableCell>
                <StyledTableCell >{data?.vendorId?.name}</StyledTableCell>
                <StyledTableCell >{data?.status}</StyledTableCell>
                <StyledTableRow >
                  <StyledTableCell ><DownloadOutlined onClick={() => window.open(data?.poLink)} /></StyledTableCell>
                  <StyledTableCell ><EyeOutlined onClick={() => showInwardingModal(data?.orderId)} /></StyledTableCell>
                </StyledTableRow>
              </StyledTableRow>
            ))}

            {emptyRows > 0 && (
              <StyledTableRow style={{ height: 53 * emptyRows }}>
                <StyledTableCell colSpan={8} />
              </StyledTableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 20, 50, { label: 'All', value: -1 }]}
                colSpan={8}
                count={procurementForInwarding.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      <Modal
        title="Inwarding"
        centered
        visible={inwardingModal}
        onCancel={() => setInwardingModal(false)}
        width={1000}
        maskClosable={false}
        footer={null}
      >
        {procurementDetails?.materials?.length > 0 &&
          <TableContainer component={Paper}>
            <Table className={classes.table} size="small" aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Sl No</StyledTableCell>
                  <StyledTableCell>Material Name</StyledTableCell>
                  <StyledTableCell>Price</StyledTableCell>
                  <StyledTableCell>Requested Quantity</StyledTableCell>
                  <StyledTableCell>Received Quantity</StyledTableCell>
                  <StyledTableCell>Inwarding Quantity</StyledTableCell>
                  <StyledTableCell>Scope</StyledTableCell>
                  <StyledTableCell>Status</StyledTableCell>
                  <StyledTableCell>Delivery Date</StyledTableCell>
                  <StyledTableCell>Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {procurementDetails?.materials?.map((data, i) => (data.status !== "Rejected" &&
                  <StyledTableRow key={i + 1}>
                    <StyledTableCell >{i + 1}</StyledTableCell>
                    <StyledTableCell >{data?.materialId?.name}</StyledTableCell>
                    <StyledTableCell>{data?.requestedPrice}</StyledTableCell>
                    <StyledTableCell>{data?.requestedQuantity}</StyledTableCell>
                    <StyledTableCell>{data?.receivedQuantity}</StyledTableCell>
                    <StyledTableCell><TextField value={inwardingQuantity[i]} name="inwardingQuantity" onChange={(e) => handleInwardingChange(e, i)} type="number" variant="outlined" /></StyledTableCell>
                    <StyledTableCell>{data?.scopeId?.name}</StyledTableCell>
                    <StyledTableCell>{data?.status}</StyledTableCell>
                    <StyledTableCell><TextField
                      id="date"
                      name="deliveryDate"
                      onChange={(e) => handleInwardingDateChange(e, i)}
                      type="date"
                      value={inwardingDate[i]}
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    /></StyledTableCell>
                    <StyledTableCell>
                      <button onClick={() => openInvoiceModal(data?.materialId?._id, i)} style={{ cursor: 'pointer' }} className='primary-btn'>
                        ADD
                      </button></StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        }
      </Modal>

      <Dialog
        open={invoiceModal}
        TransitionComponent={Transition}
        keepMounted
        fullWidth={true}
        maxWidth={'xs'}
        onClose={() => setInvoiceModal(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Invoice Details"}</DialogTitle>
        <DialogContent>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <TextField label="Enter Invoice" name="invoiceNumber" value={invoiceNumber} onChange={(e) => setInwardingDetails({ ...inwardingDetails, [e.target.name]: e.target.value })} variant="outlined" />
            <TextField
              id="date"
              name="invoiceDate"
              onChange={(e) => setInwardingDetails({ ...inwardingDetails, [e.target.name]: e.target.value })}
              label="Enter Invoice Date"
              type="date"
              value={inwardingDetails.invoiceDate}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setInvoiceModal(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div >
  )
}

export default connect(null, { setLoading, setSnackInfo })(Inwarding)
