import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import HttpService from '../../../services/httpService'
import { setLoading, setSnackInfo } from '../../../redux/actions/appAction'
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, FormControl, Box, Grid, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 700,
    },
    root: {
        width: '100%',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
}));

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#ccc",
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const ManageDepartment = (props) => {
    const classes = useStyles();
    const [departments, setDdepartmentsLists] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [departmentModal, setAddDepartmentModal] = React.useState(false);
    const [departmentName, setDepartmentName] = useState("");
    const [departmentStages, setDepartmentStages] = useState('');
    const [addedStage, setAddStage] = useState([]);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        setDdepartmentsLists(0);
    };
    useEffect(() => {
        getDepartments()
        // eslint-disable-next-line
    }, [])

    const getDepartments = async () => {
        try {
            props.setLoading(true)
            const department = await HttpService.getDepartments();
            setDdepartmentsLists(department.data);
        } catch (error) {
            console.log(error, "get department error")
        }
        props.setLoading(false)
    }

    const [editDepartmentModal, setEditDepartmentModal] = React.useState(false);
    const [editDepartmentStageData, setUpdatedStage] = useState([''])
    const [editDepartmentName, setEditDepartmentName] = useState("");
    const [editDepartmentId, setEditDepartmentId] = useState("");

    const getDepartmentById = (deptid) => {
        console.log(deptid, "ID", departments);
        departments.forEach(ele => {
            if (ele._id === deptid) {
                setUpdatedStage(ele['stages'])
                setEditDepartmentName(ele['name'])
                setEditDepartmentId(ele['_id'])
                console.log(editDepartmentStageData, "De", editDepartmentName, "deptName", editDepartmentId);
                setEditDepartmentModal(true)
            }
        })
    }

    const openAddDepartmentModal = () => {
        setAddDepartmentModal(true);
    }
    const handleDepartmentModalClose = () => {
        setAddDepartmentModal(false);
    };

    const handleEditDepartmentModalClose = () => {
        setEditDepartmentModal(false);
    };

    const addStage = () => {
        addedStage.push(departmentStages)
        setDepartmentStages('');
        console.log(departmentStages, "departmentStages", addedStage)
    }

    const saveDepartment = async () => {
        try {
            props.setLoading(true)
            let data = {
                name: departmentName,
                stages: addedStage,
            }
            await HttpService.createDepartment(data);
            props.setSnackInfo('Department Added Successfully', 'success')
            console.log(departmentName, "departmentName", data);
            setAddStage([]);
            setDepartmentName('');
            getDepartments();
            setAddDepartmentModal(false);
        } catch (error) {
            console.log(error, "error");
            props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const updateStage = () => {
        editDepartmentStageData.push(departmentStages)
        setDepartmentStages('');
        console.log(departmentStages, "departmentStages", editDepartmentStageData)
    }

    const updateDepartment = async () => {
        try {
            props.setLoading(true)
            let data = {
                name: editDepartmentName,
                stages: editDepartmentStageData,
            }
            await HttpService.editDepartment(data, editDepartmentId);
            props.setSnackInfo('Department Edited Successfully', 'success')
            console.log(editDepartmentName, "departmentName", data, editDepartmentId);
            setUpdatedStage([]);
            setEditDepartmentName('');
            getDepartments();
            setEditDepartmentModal(false);
        } catch (error) {
            console.log(error, "error");
            props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const deleteDepartment = async (deptid) => {
        try {
            props.setLoading(true);
            await HttpService.deleteDepartment(deptid);
            props.setSnackInfo('Department Deleted', 'success');
            getDepartments();
        } catch (error) {
            console.log(error);
            props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
        }
        props.setLoading(false);
    }
    return (
        <div>
            <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: 10 }}>
                <Button onClick={openAddDepartmentModal} variant="contained" color="primary" >
                    Add Department
                </Button>
                <Dialog open={departmentModal} onClose={handleDepartmentModalClose}>
                    <DialogTitle>Add Department</DialogTitle>
                    <DialogContent>
                        <Box sx={{ minWidth: 450 }}>
                            <FormControl fullWidth style={{ marginBottom: "15px" }}>
                                <TextField className={classes.textField} value={departmentName} onChange={(e) => setDepartmentName(e.target.value)} label="Department Name" type="text" variant="outlined" />
                            </FormControl>
                            <FormControl fullWidth>
                                <TextField className={classes.textField} value={departmentStages} onChange={(e) => setDepartmentStages(e.target.value)} label="Department Stage" type="text" variant="outlined" />
                            </FormControl>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDepartmentModalClose}>Cancel</Button>
                        <Button onClick={addStage} variant="contained" color="primary">Add Stage</Button>
                        <Button onClick={saveDepartment} variant="contained" color="secondary">Save Department</Button>
                    </DialogActions>
                </Dialog>
            </div>
            <Paper>
                <TableContainer component={Paper}>
                    <Table className={classes.table} stickyHeader aria-label="sticky table">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell>Sr. No.</StyledTableCell>
                                <StyledTableCell>Name</StyledTableCell>
                                <StyledTableCell>Action</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {departments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
                                return (
                                    <StyledTableRow key={i + 1}>
                                        <StyledTableCell>{i + 1}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row"> {row?.name} </StyledTableCell>
                                        <StyledTableCell>
                                            <EditOutlined onClick={() => getDepartmentById(row._id)} style={{ marginRight: "10px" }} />
                                            <DeleteOutlined onClick={() => deleteDepartment(row._id)} style={{ color: "red" }} />
                                        </StyledTableCell>
                                    </StyledTableRow>
                                );
                            })}
                        </TableBody>
                        <Dialog open={editDepartmentModal} onClose={handleEditDepartmentModalClose}>
                            <Grid item xs>
                                <DialogTitle>Edit Department</DialogTitle>
                                <DialogContent>
                                    <Box sx={{ minWidth: 450 }}>
                                        <FormControl fullWidth style={{ marginBottom: "15px" }}>
                                            <TextField className={classes.textField} value={editDepartmentName} onChange={(e) => setEditDepartmentName(e.target.value)} label="Department Name" type="text" variant="outlined" />
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <TextField className={classes.textField} value={departmentStages} onChange={(e) => setDepartmentStages(e.target.value)} label="Department Stage" type="text" variant="outlined" />
                                        </FormControl>
                                    </Box>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleEditDepartmentModalClose}>Cancel</Button>
                                    <Button onClick={updateStage} variant="contained" color="primary">Add Stage</Button>
                                    <Button onClick={updateDepartment} variant="contained" color="secondary">Update Department</Button>
                                </DialogActions>
                            </Grid>
                            <Grid item xs>
                                <DialogTitle> Department Stages</DialogTitle>
                                <DialogContent>
                                    <TableContainer component={Paper}>
                                        <Table className={classes.table} stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <StyledTableRow>
                                                    <StyledTableCell>Sr. No.</StyledTableCell>
                                                    <StyledTableCell>Name</StyledTableCell>
                                                </StyledTableRow>
                                            </TableHead>
                                            <TableBody>
                                                {editDepartmentStageData.map((row, i) => {
                                                    return (
                                                        <StyledTableRow key={i + 1}>
                                                            <StyledTableCell>{i + 1}</StyledTableCell>
                                                            <StyledTableCell component="th" scope="row"> {row} </StyledTableCell>
                                                        </StyledTableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </DialogContent>
                            </Grid>
                        </Dialog>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 20, 50, { label: 'All', value: -1 }]}
                                    colSpan={10}
                                    count={departments.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: { 'aria-label': 'rows per page' },
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    )

}

export default connect(null, { setLoading, setSnackInfo })(ManageDepartment)