import React, { useState, useEffect, useRef } from 'react';
import { connect, useSelector } from 'react-redux'
import { Link } from "react-router-dom";
import { setLoading, setSnackInfo } from '../redux/actions/appAction'
import HttpService from '../services/httpService'
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import moment from 'moment'
import { useParams } from "react-router-dom";
import { Typography } from 'antd';

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 700,
        marginTop: "30px"
    },
    root: {
        width: '100%',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        border: '2px solid #ccc'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    }
}));
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#ccc",
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const ExecutionDashBoardProjectDetail = (props) => {
    const classes = useStyles();
    const { executionStage } = useParams();
    const { userDetails } = useSelector(state => state.user)
    const [completed, setCompleted] = useState([]);
    const [stage,setStage] = useState("")
    
    useEffect(() => {
        getStageWiseLead(executionStage)
        
    }, [props.DATA])

    const getStageWiseLead = async (stage) => {
        let obj = {
            stage: stage.replace(/-/g, ' '),
            assignTo: props.location.state.assignTo
        }
        setStage(stage)
        props.setLoading(true)
        try {
             const response = await HttpService.getExecutionStage(obj)
            let mwp = []
            response.data.forEach((el) => {
                let obj = {
                    leadId: el?._id,
                    erpproject: el?.erpProjectNo,
                    customerName: el?.customerId.name,
                    currentStage: el?.currentStage,
                    designsignoff: el.designSignOffDate ? moment(el?.designSignOffDate).format('DD-MM-YYYY') : "-",
                    projectCompletion: el.projectCompletionDate ? moment(el?.projectCompletionDate).format('DD-MM-YYYY') : "-",
                    remianingDays: el?.remainingDays,
                    Budget: el?.grandTotal,
                    experienceCenterId: el?.experienceCenterId,
                    executionCompletionDate: el.executionCompletionDate ? moment(el?.executionCompletionDate).format('DD-MM-YYYY') : "-",
                    clientDependency: el?.clientDependency,
                }
                mwp.push(obj)
            })
            setCompleted(mwp);
        } catch (error) {
            console.error(error)
        }
        props.setLoading(false)
    }
    return (
        <div>
            <div style={{ marginTop: 25, marginBottom: 25 }}>
                <Paper className={classes.paper}>
                    <Typography component="h3">{executionStage.replace(/-/g, ' ')}</Typography>
            <Paper className={classes.paper}>
                <TableContainer component={Paper}>
                    <Table className={classes.table} stickyHeader aria-label="sticky table">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell>Sr. No.</StyledTableCell>
                                <StyledTableCell>Project No.</StyledTableCell>
                                <StyledTableCell>Customer Name</StyledTableCell>
                                {(stage === "Under-Execution" || stage === "Completed-MWP") && <>
                                <StyledTableCell>Current Stage</StyledTableCell>
                                <StyledTableCell>Design Signoff Date</StyledTableCell>
                                <StyledTableCell>Expected Completion Date</StyledTableCell>
                                <StyledTableCell>Days Pending for Completion</StyledTableCell>
                                <StyledTableCell>Budget</StyledTableCell>
                                </>
                                }{(stage === "Completed") && <>
                                <StyledTableCell>Design Signoff Date</StyledTableCell>
                                <StyledTableCell>Expected Completion Date</StyledTableCell>
                                <StyledTableCell>Actual Completion Date</StyledTableCell>
                                <StyledTableCell>Client Dependency</StyledTableCell>
                                </>
                                }
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {completed.map((row, i) => {
                                return (
                                    <StyledTableRow key={i + 1}>
                                        <StyledTableCell>{i + 1}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">
                                            <Link to={`/underexecution-project/logs/${row.leadId}`} style={{ color: "#000", textDecoration: 'underline' }}>
                                                {row?.erpproject}
                                            </Link>
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row">{row?.customerName}</StyledTableCell>
                                        {(stage === "Under-Execution" || stage === "Completed-MWP") && <>
                                        <StyledTableCell component="th" scope="row">{row?.currentStage}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">{row?.designsignoff}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">{row?.projectCompletion}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">{row?.remianingDays}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">{row?.Budget}</StyledTableCell>
                                        </>
                                        }
                                        {(stage === "Completed") && <>
                                        <StyledTableCell component="th" scope="row">{row?.designsignoff}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">{row?.projectCompletion}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">{row?.executionCompletionDate}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">{row?.clientDependency ? row?.clientDependency : "-"}</StyledTableCell>
                                        </> }
                                    </StyledTableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
                </Paper>
        </div>
        </div>
    )
}
export default connect(null, { setLoading, setSnackInfo })(ExecutionDashBoardProjectDetail)