import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  AppBar,
  Grid,
  TextField,
  Box,
  Paper,
  Tab,
  Tabs,
  Typography,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  CardActions,
  ImageList,
  ImageListItem,
  FormControl
} from "@material-ui/core";
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import { useHistory, Link } from "react-router-dom";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PropTypes from "prop-types";
import HttpService from "../../services/httpService";
import { setLoading, setSnackInfo } from "../../redux/actions/appAction";
import { connect, useSelector } from "react-redux";
import { Modal } from "antd";
import moment from "moment";
import { CSVLink } from "react-csv";
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import CenterFocusWeakIcon from '@material-ui/icons/CenterFocusWeak';
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';
import { green, red, blue } from '@material-ui/core/colors';
import RepeatIcon from '@material-ui/icons/Repeat';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import CreateTaskTicket from "./CreateTaskTicket";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  table: {
    width: "fit-content",
    // width: "auto", /* or specify a fixed width */
    // min-width: '100%', /* Set a minimum width */
  },
  tablecontainer: {
    display: "flex",
    margin: "25px",
  },
  tableform: {
    display: "flex",
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    maxWidth: 500,
  },
  clickableTableCell: {
    cursor: "pointer",
    textDecoration: "underline",
    color: "blue",
  },
  normalTableCell: {
    color: "black",
    "&:hover": {
      cursor: "pointer",
      color: "blue", // you can change the color when hovering here
      textDecoration: "underline",
    },
  },
  tableCell: {
    color: "blue",
    cursor: "pointer",
    textDecoration: "underline",

    // Changing color, cursor and textDecoration on hovering
    // "&:hover": {
    // 	cursor: "pointer",
    // 	color: "blue", // you can change the color when hovering here
    // 	textDecoration: "underline",
    // },
  },
  csv: {
    display: "flex",
    alignItems: "center",
    marginBottom: 20,
  },
  narrowTab: {
    minWidth: "fit-content", // Adjust the width as needed
    // width:"fit-content",
    width: 65,
  },
  margin: {
    margin: theme.spacing(1),
  },
  activeButton: {
    backgroundColor: "#F50057 !important",
    color: "white !important",
    transition: "background-color 0.3s, color 0.3s",
  },
  redButton: {
    backgroundColor: "#F50057 !important",
    color: "white !important",
    transition: "background-color 0.3s, color 0.3s",
    margin: "10px"
  },
  normalButton:{
    margin: "10px"
  },
  cardDialog: {
    minWidth: "80%",
    cursor: 'default'
  },
  autoComplete: {
    width: "200px",
  },
  searchField: {
    marginTop: "20px",
    width: "800px",
  },
  viewIssueDialog:{
    minWidth: "80%"
  },
  imageList: {
    width: 500,
    // height: 450,
  },
  dialogMargin:{
    margin: "10px",
  },
  actionBar:{ display: "flex", justifyContent: "center", gap: "10px" },
  iconHover:{
  transition: "filter 0.3s ease",
  filter: "brightness(1.3)"
  },
  reassign:{
    minWidth: "500px",
    // minHeight: "300px",
  },
  reassignAutocomplete:{
    margin: "20px",
  },
  editDialogue:{
    minWidth: "600px",
    cursor: 'default'
  },
  dateSize:{
    width: "50%"
  },
  handOnHover:{
    cursor: "pointer"
  },
  remarks:{
    border: '1px solid black'
  },
  iconDisabledColor:{
    color: 'rgb(255, 255, 255)'
  }
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#3F51B5",
    color: "#FFF",
    fontWeight: "500",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const TaskManagementDashBoard = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { userDetails } = useSelector((state) => state.user);
  const [ribbonTagValue, setRibbonTagValue] = useState("Tasks Assigned To Me");
  const [fetchData, setFetchData] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [viewIssueDialog, setViewIssueDialog] = useState(false);
  const [viewConfirmationDialog, setViewConfirmationDialog] = useState(false);
  const [viewDeleteConfirmationDialog, setViewDeleteConfirmationDialog] = useState(false);
  const [viewIssueData, setViewIssueData] = useState({});
  const [closeIssueData, setCloseIssueData] = useState({});
  const [resolveIssueData, setResolveIssueData] = useState({});
  const [categoryData, setCategoryData] = useState(["Pending", "Resolved", "Closed"]);
  const [selectedStatusData, setSelectedStatusData] = useState('');
  const [searchTicketID, setSearchTicketID] = useState("");
  const [remarks, setRemarks] = useState("");
  const [ribbonNumberInfo, setRibbonNumberInfo] = useState({});
  const [deleteIssueData, setDeleteIssueData] = useState({});
  const [viewReassignIssueDialog, setViewReassignIssueDialog] = useState(false);
  const [viewEditIssueDialog, setViewEditIssueDialog] = useState(false);
  const [reassignIssueData, setReassignIssueData] = useState({});
  const [editIssueData, setEditIssueData] = useState({});
  const [editTicketTitle, setEditTicketTitle] = useState('');
  const [editTicketDes, setEditTicketDes] = useState('');
  const [editDueDate, setEditDueDate] = useState('');
  const [editFollowUpDate, setEditFollowUpDate] = useState('');
  
  const [emailTag, setEmailTag] = useState("");
  // const [reRender, setReRender] = useState(false);

  const [ticketTitle, setTicketTitle] = useState('');
  const [ticketDescription, setTicketDescription] = useState('');
  const [ticketETAdate, setTicketETAdate] = useState('');
  const [ticketFollowUpDate, setTicketFollowUpDate] = useState('');
  const [files, setFiles] = useState([""]);
  const [openCreateTicketDialog, setOpenCreateTicketDialog] = useState(false);
  const [isUserWithTeam, setIsUserWithTeam] = useState(true);

  const csvButtonRef = useRef(null);
  const titleRef = useRef(null);
  const desciptionRef = useRef(null);

  const headers = [
    { key: "slno", label: "Sl No" },
    { key: "ticketNo", label: "Task Id." },
    { key: "assignedTo", label: "Assigned To" },
    { key: "assignedBy", label: "Assigned By" },
    { key: "ticketCreatedDate", label: "Task Created Date" },
    { key: "resolutionExpectedDate", label: "Resolution Expected Date" },
    { key: "daysPending", label: "Days Pending" },
    { key: "status", label: "Status" },
  ];

  const handleTickeTitleInputChange = (e)=>{
    setTicketTitle(e.target.value)
}
const handleTickeDescriptionInputChange = (e)=>{
    setTicketDescription(e.target.value)
}
const handleETAdateInputChange = (e) => {
  // setTicketETAdate(e.target.value)
  const selectedDate = new Date(e.target.value);
  const currentDate = new Date();
  currentDate.setHours(0,0,0,0)

  // Check if the selected date is in the future or today
  if (selectedDate >= currentDate) {
    setTicketETAdate(e.target.value);
  } else {
    props.setSnackInfo("Please select a date in the present or future.",'warning');
  }
};
const handleticketFollowUpDateInputChange = (e)=>{
  // setTicketFollowUpDate(e.target.value)
  const selectedDate = new Date(e.target.value);
  const currentDate = new Date();
  currentDate.setHours(0,0,0,0);

  // Check if the selected date is in the future or today
  if (selectedDate >= currentDate) {
    setTicketFollowUpDate(e.target.value);
  } else {
    props.setSnackInfo("Please select a date in the present or future.",'warning');
  }

}

const onImageChangeCheck = async (e, index) => {
  const newFiles = [...files];

      props.setLoading(true);
      const fd = new FormData();
      fd.append("file", e.target.files[0]);
      // fd.append('customerName', projectDetails.lead_no);

      try {
          const fileUploadRes = await HttpService.uploadFile(fd);
          newFiles[index] = fileUploadRes.data;
          setFiles(newFiles);
      } catch (err) {
          props.setSnackInfo(err?.response?.data ?? "Something went wrong", "error");
      }
      props.setLoading(false);
}
const handleAddFileInput = () => {
  let newFiles = [...files];
  newFiles.push("");
  setFiles(newFiles);
};

const handleRemoveFileInput = (index) => {
  const newFiles = files.filter((file,id) => id !== index);
  setFiles([...newFiles]);
}

const closeCreateTicketDialog = ()=>{
  setOpenCreateTicketDialog(false)
}

  // Function to handle tab changes
  const handleChange = (newValue) => {
    setRibbonTagValue(newValue);
  };

  const makeDataForCsv = (arr)=>{

    let csvArrData = []
      arr.forEach((el,i)=>{
        let newObj = {
          slno:i+1,
          ticketNo:el.uniqueTicketNo || 'N/A',
          assignedTo:el.assignedTo?.name || 'N/A',
          assignedBy:el.taskCreatedBy?.name || 'N/A',
          ticketCreatedDate: el.createdAt ? moment(el.createdAt).format("ll") : 'N/A',
          resolutionExpectedDate: el.expectedClosureDate ? moment(el.expectedClosureDate).format("ll") : 'N/A',
          daysPending: el?.expectedClosureDate
          ?
            Math.ceil(
              (new Date(el?.expectedClosureDate) - new Date())/
                (24 * 60 * 60 * 1000)
            )
          : "N/A",
          status:el.status || 'N/A',
        }
        csvArrData.push(newObj)
      })
    setCsvData(csvArrData)
  }

  // Function to get Material Delivery Project's count loction based
  const getFilteredTickets = async (noDateArg) => {
    props.setLoading(true);
    try {
      let dateObj = {
        startDate: startDate,
        endDate: endDate,
      };

      if (noDateArg === "noDate") {
        dateObj.startDate = "";
        dateObj.endDate = "";
      }

      const response = await HttpService.getFilteredTaskTickets(
        ribbonTagValue,
        dateObj,
        selectedStatusData,
      );
      setFetchData(response.data);
      makeDataForCsv(response.data);
    } catch (error) {
      console.log("Error ->", error);
    }
    props.setLoading(false);
  };

  const getDataOnPageLoading = async () => {
    props.setLoading(true);
    try {
      const response3 = await HttpService.getTaskRibbonNumberInfo(selectedStatusData);
      setRibbonNumberInfo(response3.data)

    } catch (error) {
      console.log("Error ->", error);
    }
    props.setLoading(false);
  };

  const handleDateFilter = () => {
    getFilteredTickets();
  };

  // get data without date filtering
  const clearFilterDate = () => {
    setStartDate("");
    setEndDate("");
    getFilteredTickets("noDate");
  };

  const viewIssue = (e, data, i) => {
    setViewIssueDialog(true);
    setViewIssueData(data);
    // console.log('data is ',data)
  };

  const closeIssue = (e, data, i) => {
    setViewConfirmationDialog(true);
    setCloseIssueData(data);
  };

  const resolveIssue = (e, data, i) => {
    setViewConfirmationDialog(true);
    setResolveIssueData(data);
  };

  const deleteIssue = (e, data, i)=>{
    setViewDeleteConfirmationDialog(true);
    setDeleteIssueData(data);
  }
  const reassignIssue = (e, data, i)=>{
    setViewReassignIssueDialog(true);
    setReassignIssueData(data);
  }

  const editIssue = (e, data, i)=>{
    setEditIssueData(data);
    setViewEditIssueDialog(true);
    setEditTicketTitle(data.ticketTitle)
    setEditTicketDes(data.ticketDescription)
    // localStorage.setItem('editIssueTitle', data.ticketTitle);
    // localStorage.setItem('editIssueDes', data.ticketDescription);

    // setReRender(!reRender)
    // console.log('setEditIssueData is ',data)
  }

  const handleDialogClose = () => {
    setViewIssueDialog(false);
    setViewConfirmationDialog(false);
  };

  const handleEditDialogClose = () => {
    setViewEditIssueDialog(false);
  };

  const handleCloseConfirm = async () => {
    try {
      const response = await HttpService.closeTaskticket(closeIssueData._id, {remarks:remarks});

      if (response.status === 200) {
        props.setSnackInfo("Task Closed Succesfully", "Success");
        setViewConfirmationDialog(false);
        // setTimeout(() => {
        //   props.setSnackInfo("Loading Updated Data", "info");
        //   window.location.reload(true);
        // }, 1500);
        getDataOnPageLoading()
        getFilteredTickets()
        setViewConfirmationDialog(false)
      }
    } catch (err) {
      console.log(err);
      props.setSnackInfo(err.message, "Error");
    }
  };

  const handleResolveConfirm = async () => {
    try {
      const response = await HttpService.resolveTaskticket(resolveIssueData._id, {remarks:remarks});

      if (response.status === 200) {
        props.setSnackInfo("Task Resolved Succesfully", "Success");
        setViewDeleteConfirmationDialog(false);
        // setTimeout(() => {
        //   props.setSnackInfo("Loading Updated Data", "info");
        //   window.location.reload(true);
        // }, 1500);
        getDataOnPageLoading()
        getFilteredTickets()
        setViewConfirmationDialog(false)
      }
    } catch (err) {
      console.log(err);
      props.setSnackInfo(err.message, "Error");
    }
  };

  const handleDeleteConfirm = async () => {
    try {
      const response = await HttpService.deleteTaskTicket(deleteIssueData._id);

      if (response.status === 200) {
        props.setSnackInfo("Task Deleted Succesfully", "Success");
        setViewConfirmationDialog(false);
        // setTimeout(() => {
        //   props.setSnackInfo("Loading Updated Data", "info");
        //   window.location.reload(true);
        // }, 1500);
        getDataOnPageLoading()
        getFilteredTickets()
        setViewDeleteConfirmationDialog(false)
      }
    } catch (err) {
      console.log(err);
      props.setSnackInfo(err.message, "Error");
    }
  };

  const onChangeTicketID = (e) => {
    // console.log(e.target.value);
    setSearchTicketID(e.target.value);
  };

  const handleRemarksChange = (e) => {
    setRemarks(e.target.value);
  };

  const handleEditConfirm = async ()=>{
    try{
      // const obj = {
      //   title: titleRef.current.value,
      //   description: desciptionRef.current.value,
      //   ticketId: editIssueData._id
      // }

      const obj = {
        title: editTicketTitle,
        description: editTicketDes,
        ticketId: editIssueData._id,
        newDueDate: editDueDate,
        newFollowUpDate: editFollowUpDate,
      }

      const response = await HttpService.editTaskTicketDetails(obj);

      if(response.status === 200){
        props.setSnackInfo('Success ', 'success')
        getDataOnPageLoading();
        getFilteredTickets();
        setViewEditIssueDialog(false);
        setEditIssueData({})
        
        if (titleRef.current) {
          titleRef.current.value = ''; // Reset the input value to empty string
        }
        if (desciptionRef.current) {
          desciptionRef.current.value = ''; // Reset the input value to empty string
        }
      }
    }
    catch(err){
      console.log(err)
    }
  }

  const handleDownloadAttachments = async ()=>{
    viewIssueData.attachedFiles.forEach(async (item) => (
      item!=="" && await window.open(item)
    ))
  }

  const searchByTicketId = async () => {
    props.setLoading(true);
    try {
      if (searchTicketID !== "") {
        const response = await HttpService.getSearchedTaskTicket(searchTicketID);
        setFetchData(response.data);
        makeDataForCsv(response.data);
        if(response.data.status === 204){
          props.setSnackInfo('No results found', "error");
        }
      }
    } catch (err) {
      console.log(err.message);
      props.setSnackInfo(err.message, "Error");
    }
    props.setLoading(false);
  };

  const csvDownloadHandler = async () => {
		setLoading(true);
		try {
			downloadExcel();
		} catch (error) {
			console.log("error", error);
			props?.setSnackInfo(
				error?.response?.data?.message ?? "Something went wrong",
				"error"
			);
		}
		setLoading(false);
	};

  const downloadExcel = () => {
		setLoading(true);
		try {
			setTimeout(() => {
				csvButtonRef.current.link.click();
			});
		} catch (err) {
			console.error(err.message);
		}
		setLoading(false);
	};


  const redirectToCreateTicket = ()=>{
    setOpenCreateTicketDialog(true)
  }

  const handleReassignIssueConfirm = async ()=>{
          // console.log('reassignIssueData is ',reassignIssueData)

    try{
      const response = await HttpService.changeTaskTicketAssignedUserFromEmail(reassignIssueData._id, emailTag);
      if(response.status == 200){
        props.setSnackInfo('Success ', 'success')
        getDataOnPageLoading()
        getFilteredTickets()
        setViewReassignIssueDialog(false)
      }
    }
    catch(error){
      console.log('error is ',error);
      if(error.response.status == 400){
        props.setSnackInfo(error.response.data, 'error')
      }
      else{
        props.setSnackInfo('Error occoured', 'error')
      }
  }
}

const handleEditDueDateChange = (e) => {
  // setTicketETAdate(e.target.value)
  const selectedDate = new Date(e.target.value);
  const currentDate = new Date();
  currentDate.setHours(0,0,0,0)

  // Check if the selected date is in the future or today
  if (selectedDate >= currentDate) {
    setEditDueDate(e.target.value);
  } else {
    props.setSnackInfo("Please select a date in the present or future.",'warning');
  }
};

const handleEditFollowUpDateChange = (e) => {
  // setTicketETAdate(e.target.value)
  const selectedDate = new Date(e.target.value);
  const currentDate = new Date();
  currentDate.setHours(0,0,0,0)

  // Check if the selected date is in the future or today
  if (selectedDate >= currentDate) {
    setEditFollowUpDate(e.target.value);
  } else {
    props.setSnackInfo("Please select a date in the present or future.",'warning');
  }
};

const handleEmailTagChange = (e,v)=>{
  setEmailTag(e.target.value);
}

const checkUserWithoutTeam = ()=>{
  // set false for executives
  const singleRoleNames = [
    "Design User", "Sales User", "Finance User", "CHM_User", "Procurement Executive",
    "Finance Executive", "BOM Executive", "Marketing User", "Factory Bom", "CRM Factory",
    "Factory CRM", "Execution User", "LnD_User", "IMOS User", "Vendor Executive",
    "TeleCaller", "Project Manager", "Factory Finance Executive"
  ];
  
  if (userDetails.roles.length === 1 && singleRoleNames.includes(userDetails.roles[0].name)) {
    setIsUserWithTeam(false);
  }
  // if(userDetails.roles.length == 1 && (userDetails.roles[0].name == "Design User" || userDetails.roles[0].name == "Sales User" || userDetails.roles[0].name == "Finance User" || userDetails.roles[0].name == "CHM_User" || userDetails.roles[0].name == "Procurement Executive" || userDetails.roles[0].name == "Finance Executive" || userDetails.roles[0].name == "BOM Executive" || userDetails.roles[0].name == "Marketing User" || userDetails.roles[0].name == "Factory Bom" || userDetails.roles[0].name == "CRM Factory" || userDetails.roles[0].name == "Factory CRM" || userDetails.roles[0].name == "Execution User" || userDetails.roles[0].name == "LnD_User" || userDetails.roles[0].name == "IMOS User" || userDetails.roles[0].name == "Vendor Executive" || userDetails.roles[0].name == "TeleCaller" || userDetails.roles[0].name == "Project Manager" || userDetails.roles[0].name == "Factory Finance Executive") ){
  //   setIsUserWithTeam(false)
  // }
}

  // get data on ribbon filter change
  useEffect(() => {
    getFilteredTickets();
    getDataOnPageLoading();
  }, [ribbonTagValue, selectedStatusData]);

  useEffect(() => {
    getDataOnPageLoading();
    checkUserWithoutTeam();
  }, []);

  return (
    <Fragment>
      <Fragment>
            <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center">
              <Grid>

              <Button
                  variant="contained"
                  color="primary"
                  onClick={redirectToCreateTicket}
              >
                Create Task
              </Button>
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
              style={{margin:"15px"}}
            >
              <Grid md={2}>
                <TextField
                  id="date"
                  name="startDate"
                  label="Start Date"
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  className={classes.textField}
                />
              </Grid>
              <Grid md={2}>
                <TextField
                  id="date"
                  name="endDate"
                  label="End Date"
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  className={classes.textField}
                />
              </Grid>
              <Grid>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!startDate || !endDate}
                  onClick={handleDateFilter}
                >
                  Submit
                </Button>
              </Grid>

              <Grid>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={clearFilterDate}
                  style={{marginRight:"200px"}}
                >
                  Clear Filter
                </Button>
              </Grid>
              <Grid></Grid>
              {/* <Grid>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={redirectToCreateTicket}
                  >
                    Create Task
                  </Button>
              </Grid> */}
            </Grid>
      </Fragment>

      <Fragment>
        <div className={classes.root}>
          {/* Created Tickets Button */}

          <Fragment>
            <Grid
              container
              style={{
                display: "flex",
                alignItems: "center",
                width: "70vw",
                // marginLeft: 25,
                marginBottom: 25,
              }}
            >
              <TextField
                id="ticketIdSearch"
                name="ticketIdSearch"
                label="Search Tasks(Task ID)"
                type="string"
                variant="outlined"
                value={searchTicketID}
                onChange={onChangeTicketID}
                InputLabelProps={{
                  shrink: true,
                }}
                className={classes.searchField}
              />

              <Button
                variant="outlined"
                color="primary"
                size="small"
                id="search-btn"
                onClick={searchByTicketId}
                className={classes.textField}
                disabled={searchTicketID === ""}
                style={{ marginLeft: 20 }}
              >
                Search
              </Button>
            </Grid>
          </Fragment>

          <Fragment>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => handleChange("Tasks Assigned To Me")}
                className={
                  ribbonTagValue === "Tasks Assigned To Me" ? classes.activeButton : ""
                }
              >
                Tasks Assign to Me({ribbonNumberInfo.toMe})
              </Button>
          </Fragment>

          { isUserWithTeam && <Fragment>
            
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => handleChange("Tasks Assigned To My Team")}
                className={
                  ribbonTagValue === "Tasks Assigned To My Team" ? classes.activeButton : ""
                }
              >
                Tasks Assigned to My Team({ribbonNumberInfo.toTeam})
              </Button>
            
          </Fragment>}

          <Fragment>
            
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => handleChange("Tasks Assigned By Me")}
                className={
                  ribbonTagValue === "Tasks Assigned By Me" ? classes.activeButton : ""
                }
              >
                Tasks Assigned by Me({ribbonNumberInfo.byMe})
              </Button>
            
          </Fragment>

          <Fragment>
            <Grid
              container
              style={{
                display: "flex",
                alignItems: "center",
                width: "80vw",
                marginBottom: 25,
              }}
            >

              {/* <Grid item md={1} xs={12}></Grid> */}

              <Autocomplete
                  value={selectedStatusData}
                  key="selectedStatusData"
                  onChange={(e, newValue) => setSelectedStatusData(newValue)}
                  options={categoryData}
                  getOptionLabel={(option) => option}
                  style={{ width: "20%", marginBottom: 10,}}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Tag Status"
                      fullWidth
                      variant="outlined"
                    />
                  )}
                />  


              <CSVLink
                headers={headers}
                filename={ribbonTagValue + ".csv"}
                data={csvData}
                ref={csvButtonRef}
              />
              <Button
                onClick={csvDownloadHandler}
                style={{
                  marginLeft: 20,
                  backgroundColor: "green",
                  color: "white",
                  height: 30,
                }}
                variant="contained"
                color="success"
              >
                Download CSV
              </Button>
            </Grid>
          </Fragment>

        </div>
      </Fragment>

      {fetchData && (
        <TableContainer component={Paper} className={classes.table}>
          <Table
            // style={{ width: 450 }}
            className={classes.table}
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead style={{ backgroundColor: 'red', color: 'white' }}>
              <StyledTableRow key='tableHeaders'>
                <StyledTableCell>Sl. No.</StyledTableCell>
                <StyledTableCell>Task Id.</StyledTableCell>
                <StyledTableCell>Assigned To</StyledTableCell>
                <StyledTableCell>Assigned by</StyledTableCell>
                <StyledTableCell>Created On</StyledTableCell>
                <StyledTableCell>Due Date</StyledTableCell>
                {!(ribbonTagValue === "Closed") && <StyledTableCell>Days pending</StyledTableCell>}
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {fetchData?.map((data, i) => {
                return (
                  <StyledTableRow key={i}>
                    <StyledTableCell component="th">{i + 1}</StyledTableCell>

                    <StyledTableCell
                      component="th"
                      style={{ textAlign: "center" }}
                    >
                      {data?.uniqueTicketNo || "N/A"}
                    </StyledTableCell>

                    <StyledTableCell
                      component="th"
                      style={{ textAlign: "center" }}
                    >
                      {data?.assignedTo?.name || "N/A"}
                    </StyledTableCell>

                    <StyledTableCell
                      component="th"
                      style={{ textAlign: "center" }}
                    >
                      {data?.taskCreatedBy?.name || "N/A"}
                    </StyledTableCell>

                    <StyledTableCell
                      component="th"
                      style={{ textAlign: "center" }}
                    >
                      {data?.createdAt
                        ? moment(data?.createdAt).utc().format("DD-MM-YYYY")
                        : "-"}
                    </StyledTableCell>

                    <StyledTableCell
                      component="th"
                      style={{ textAlign: "center" }}
                    >
                      {data?.expectedClosureDate
                        ? moment(data?.expectedClosureDate)
                            .utc()
                            .format("DD-MM-YYYY")
                        : "-"}
                    </StyledTableCell>

                    {!(ribbonTagValue === "Closed") && <StyledTableCell
                      component="th"
                      style={{ textAlign: "center" }}
                    >
                      {data?.expectedClosureDate
                        ? // Check if expectedClosureDate is a valid date
                          Math.ceil(
                            (new Date(data?.expectedClosureDate) - new Date()) /
                              (24 * 60 * 60 * 1000)
                          )
                        : "N/A"}
                    </StyledTableCell>}

                    <StyledTableCell
                      component="th"
                      style={{ textAlign: "center" }}
                    >
                      {data?.status || "N/A"}
                    </StyledTableCell>

                    <StyledTableCell
                      component="th"
                      style={{ textAlign: "center" }}
                    >
                      <div className={classes.actionBar}>

                        <VisibilityRoundedIcon
                        className={classes.handOnHover}
                          style={{ color: green[500] }}
                          onClick={(e) => {
                            viewIssue(e, data, i);
                          }}
                          titleAccess="View Issue"
                        />

                        {/* {userDetails?.roles.find(
                          (role) =>
                            role.name === ROLENAME.Admin ||
                            role.name === ROLENAME.CHM_Manager ||
                            role.name === ROLENAME.OperationHead ||
                            role.name === ROLENAME.CHM_User ||
                            role.name === ROLENAME.TeleCallerManager ||
                            role.name === ROLENAME.TeleCallerUser
                        )
                          ? ribbonTagValue !== "Closed" &&
                            !(data?.status === "Closed") && (
                              <CenterFocusWeakIcon
                              className={classes.handOnHover}
                                style={{ color: blue[500] }}
                                onClick={(e) => {
                                  closeIssue(e, data, i);
                                }}
                                titleAccess="Close Issue"
                              />
                            )
                          : ribbonTagValue !== "Resolved" &&
                            ribbonTagValue !== "Closed" &&
                            !(data?.status === "Resolved") &&
                            !(data?.status === "Closed") && (
                              <CenterFocusWeakIcon
                                className={classes.handOnHover}
                                style={{ color: blue[500] }}
                                onClick={(e) => {
                                  resolveIssue(e, data, i);
                                }}
                                titleAccess="Resolve Issue"
                              />
                            )} */}


                        {ribbonTagValue === "Tasks Assigned By Me" &&
                            data?.status === "Resolved" && (
                              <CenterFocusWeakIcon
                              className={classes.handOnHover}
                                style={{ color: blue[500] }}
                                onClick={(e) => {
                                  closeIssue(e, data, i);
                                }}
                                titleAccess="Close Issue"
                              />
                            )
                          }

                          {ribbonTagValue === "Tasks Assigned To Me" &&
                            !(data?.status === "Closed" || data?.status === "Resolved") && (
                              <CenterFocusWeakIcon
                                className={classes.handOnHover}
                                style={{ color: blue[500] }}
                                onClick={(e) => {
                                  resolveIssue(e, data, i);
                                }}
                                titleAccess="Resolve Issue"
                              />
                            )
                            }

                        {
                          ribbonTagValue === "Tasks Assigned By Me" &&
                          <DeleteOutlineRoundedIcon
                            className={classes.handOnHover}
                            style={{ color: red[500] }}
                            onClick={(e) => {
                              deleteIssue(e, data, i);
                            }}
                            titleAccess="Delete Issue"
                          />
                        }
                        {ribbonTagValue === "Tasks Assigned By Me" &&
                          <RepeatIcon
                            className={classes.handOnHover}
                            style={{ color: blue[500] }}
                            onClick={(e) => {
                              reassignIssue(e, data, i);
                            }}
                            titleAccess="Reassign Issue"
                          />
                        }

                        {ribbonTagValue === "Tasks Assigned By Me" && 
                        <EditOutlinedIcon
                            className={classes.handOnHover}
                            style={{ color: red[500] }}
                            onClick={(e) => {
                              editIssue(e, data, i);
                            }}
                            titleAccess="Edit Issue"
                          />
                        }
                      </div>
                    </StyledTableCell>

                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Fragment>
        <Dialog
          open={viewIssueDialog}
          keepMounted
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          className={classes.viewIssueDialog}
        >
          <Card className={classes.root}>
            <CardActionArea className={classes.cardDialog}>
              <CardContent className={classes.cardDialog}>
                <div style={{marginBottom:"20px"}}></div>
                <span style={{fontWeight: "100", textAlign: "center", fontSize:"25px", marginRight:"10px"}}>Title:</span>
                <span style={{fontSize:"15px"}}>{viewIssueData.ticketTitle}</span>
                <div style={{marginBottom:"20px"}}></div>
                <span style={{fontWeight: "100", textAlign: "center", fontSize:"25px", marginRight:"10px"}}>Description:</span>
                <span style={{fontSize:"15px"}}>{viewIssueData.ticketDescription}</span>
                <div style={{marginBottom:"20px"}}></div>
                <ImageList
                  rowHeight={160}
                  className={classes.imageList}
                  cols={3}
                >
                  {viewIssueData?.attachedFiles?.length > 0 &&
                    viewIssueData.attachedFiles.map(
                      (item) =>
                        (item.endsWith(".jpg") ||
                          item.endsWith(".png") ||
                          item.endsWith(".jpeg")) && (
                          <ImageListItem key={item} cols={1}>
                            <img src={item} alt={"img not found"} />
                          </ImageListItem>
                        )
                    )}
                </ImageList>

                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="h2"
                    style={{ marginTop: 10, marginRight: 10 }}
                  >
                    Download Attachments
                  </Typography>
                  <GetAppRoundedIcon onClick={handleDownloadAttachments}  className ={viewIssueData?.attachedFiles?.length > 0 && viewIssueData?.attachedFiles[0]==="" ? classes.iconDisabledColor : {}}/>
                </div>

                <Typography
                  gutterBottom
                  variant="h5"
                  component="h2"
                  style={{ marginTop: 10 }}
                >
                  Remarks
                </Typography>

                    {viewIssueData?.logs?.length > 0 && (
                            <TableContainer key="remarksTable" component={Paper}>
                              <Table
                                // style={{ width: 450 }}
                                // className={classes.table}
                                stickyHeader
                                aria-label="sticky table"
                              >
                                <TableHead>
                                  <StyledTableRow key='tableHeaders'>
                                    <StyledTableCell>Sl. No.</StyledTableCell>
                                    <StyledTableCell>Remarks</StyledTableCell>
                                    <StyledTableCell>From</StyledTableCell>
                                    <StyledTableCell>Created On</StyledTableCell>
                                    
                                  </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                  {viewIssueData.logs.map((log, i) => (

                                      <StyledTableRow key={i}>

                                        <StyledTableCell component="th">{i + 1}</StyledTableCell>

                                        <StyledTableCell
                                          component="th"
                                          style={{ textAlign: "center" }}
                                        >
                                          {log?.remarks || "-"}
                                        </StyledTableCell>

                                        <StyledTableCell
                                          component="th"
                                          style={{ textAlign: "center" }}
                                        >
                                          {log?.createdBy?.name || "N/A"}
                                        </StyledTableCell>

                                        <StyledTableCell
                                          component="th"
                                          style={{ textAlign: "center" }}
                                        >
                                          {log?.createdOn
                                            ? moment(log?.createdOn).utc().format("DD-MM-YYYY")
                                            : "-"}
                                        </StyledTableCell>

                                      </StyledTableRow>
                                    ))}
                                  
                                </TableBody>
                              </Table>
                            </TableContainer>
                          )}

              </CardContent>
            </CardActionArea>
          </Card>
        </Dialog>
      </Fragment>

      <Fragment>
        <Dialog
          open={viewConfirmationDialog}
          // TransitionComponent={Transition}
          keepMounted
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <Card className={classes.root}>
            <CardActionArea className={classes.cardDialog}>
              <CardContent className={classes.cardDialog}>
                <Typography gutterBottom variant="h6" component="h2">
                  Confirmation
                </Typography>

                {ribbonTagValue === "Tasks Assigned By Me" && 
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Are you sure you want to close this task
                  </Typography>
                  }

                  {ribbonTagValue === "Tasks Assigned To Me" && 
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Click confirm to Resolve this task
                  </Typography>
                  }
              </CardContent>
            </CardActionArea>

            <CardActions>
              <TextField
                id="remarks"
                name="remarks"
                label="Remarks"
                type="string"
                value={remarks}
                onChange={handleRemarksChange}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                className={classes.textField}
              />
            </CardActions>

            <CardActions>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={handleDialogClose}
                className={
                  ribbonTagValue === "Category" ? classes.activeButton : ""
                }
              >
                Cancel
              </Button>

              {ribbonTagValue === "Tasks Assigned By Me" && 
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={handleCloseConfirm}
                  className={
                    ribbonTagValue === "Category" ? classes.activeButton : ""
                  }
                >
                  Confirm
                </Button>
                }
                {ribbonTagValue === "Tasks Assigned To Me" && 
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={handleResolveConfirm}
                  className={
                    ribbonTagValue === "Category" ? classes.activeButton : ""
                  }
                >
                  Confirm
                </Button>
              }
            </CardActions>
          </Card>
        </Dialog>
      </Fragment>
      <Fragment>
        <Dialog
          open={viewDeleteConfirmationDialog}
          // TransitionComponent={Transition}
          keepMounted
          onClick={() => {
            setViewDeleteConfirmationDialog(false);
          }}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <Card className={classes.root}>
            <CardActionArea className={classes.cardDialog}>
              <CardContent className={classes.cardDialog}>
                <Typography gutterBottom variant="h6" component="h2">
                  Confirmation
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Are you sure you want to delete this task
                </Typography>
              </CardContent>
            </CardActionArea>

            {/* <CardActions>
              <TextField
                id="remarks"
                name="remarks"
                label="Remarks"
                type="string"
                value={remarks}
                onChange={handleRemarksChange}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                className={classes.textField}
              />
            </CardActions> */}

            <CardActions>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => {
                  setViewDeleteConfirmationDialog(false);
                }}
                className={
                  ribbonTagValue === "Category" ? classes.activeButton : ""
                }
              >
                Cancel
              </Button>

              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={handleDeleteConfirm}
                className={
                  ribbonTagValue === "Category" ? classes.activeButton : ""
                }
              >
                Delete
              </Button>
            </CardActions>
          </Card>
        </Dialog>

        <Dialog
          open={viewReassignIssueDialog}
          keepMounted
          onClose={() => {
            setViewReassignIssueDialog(false);
        }}
        >
          <Grid
              container
              className={classes.reassign}
              direction="column"
              justifyContent="space-evenly"
              alignItems="stretch"
              key="ReassignGridContainer"
            >
              <h3 className={classes.tablecontainer}>Enter User  Email</h3>
          <TextField
          id="emailTagging"
          name="Tag Email"
          type="email"
          variant="outlined"
          // placeholder="Enter..."
          onChange={(e, v) => handleEmailTagChange(e, v)}
          value={emailTag}
          InputLabelProps={{
            // shrink: true,
          }}
          className={classes.reassignAutocomplete}
          >
          </TextField>

        </Grid>
        <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
        >
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => {
                  setViewReassignIssueDialog(false);
                }}
                className={
                  classes.redButton
                }
              >
                Cancel
              </Button>

              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={handleReassignIssueConfirm}
                className={
                  classes.normalButton
                }
              >
                Confirm
              </Button>
        </Grid>
        </Dialog>
      </Fragment>

      <Fragment>
        <Dialog
          open={viewEditIssueDialog}
          keepMounted
          onClose={handleEditDialogClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          className={classes.editDialogue}
        >
          <Card className={classes.editDialogue}>
            <CardActionArea className={classes.cardDialog}>
              <CardContent className={classes.cardDialog}>
                <Typography gutterBottom variant="h5" component="h2">
                  New Title
                </Typography>

                <TextField
                  id="editTicketTitle"
                  inputRef={titleRef}
                  // label="Helper text"
                  value={editTicketTitle}
                  // defaultValue={JSON.stringify(localStorage.getItem('editIssueTitle'))}
                  // defaultValue='default text'
                  // defaultValue={editTicketTitle}
                  
                  // helperText={`Old Title: ${editIssueData.ticketTitle}`}
                  variant="outlined"
                  className={classes.margin}
                  onChange={(e)=> setEditTicketTitle(e.target.value)}
                >
                </TextField>

                <Typography gutterBottom variant="h5" component="h2">
                  New Description
                </Typography>
                <TextField
                  id="editTicketDescription"
                  value={editTicketDes}
                  inputRef={desciptionRef}
                  // helperText={`Old Description: ${editIssueData.ticketDescription}`}
                  variant="outlined"
                  className={classes.margin}
                  onChange={(e)=> setEditTicketDes(e.target.value)}
                >
                  
                </TextField>

                <div>

                </div>
                <TextField
                  id="date"
                  name="dueDate"
                  label="Due Date"
                  type="date"
                  value={editDueDate}
                  onChange={handleEditDueDateChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  className={classes.margin + " " + classes.dateSize}
                />

                
                <CardActions>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={()=> setViewEditIssueDialog(false)}
                    className={classes.activeButton}
                  >
                    Cancel
                  </Button>

                  <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      onClick={handleEditConfirm}
                      className={
                        ribbonTagValue === "Category" ? classes.activeButton : ""
                      }
                    >
                      Update
                    </Button>
              </CardActions>

              </CardContent>
            </CardActionArea>
          </Card>
        </Dialog>
      </Fragment>

      <Fragment>
        <Dialog
        open={openCreateTicketDialog}
        onClose={() => closeCreateTicketDialog()}
        maxWidth={ "xl" }
        >
          <CreateTaskTicket visibilityFunction = {setOpenCreateTicketDialog} refreshParentDataNo = {getDataOnPageLoading} refreshParentData={getFilteredTickets}></CreateTaskTicket>
        </Dialog>
      </Fragment>
    </Fragment>
  );
};

export default connect(null, { setLoading, setSnackInfo })(TaskManagementDashBoard);
