import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Slide from '@material-ui/core/Slide'; // Add this line to import Slide
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { setSnackInfo, setLoading } from '../redux/actions/appAction';
import HttpService from '../services/httpService';
import { connect } from 'react-redux'
import { DownloadOutlined, EyeFilled } from '@ant-design/icons';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles({
  input: {
    color: "white",
    borderBottom: "1px solid #fff",
  },
  inputLabel: {
    color: "#aca9a9 !important",
  },
  icon: {
    color: 'white',
  },
  proceedButton: { backgroundColor: 'white', color: '#2c2e3e', margin: 'center', padding: 5, borderRadius: '50px', width: '100px', "&:hover": { backgroundColor: "#3f51b5", color: 'white' } },
  cancelButton: { backgroundColor: 'grey', color: '#white', margin: 'center', padding: 5, borderRadius: '50px', width: '100px', marginRight: '1rem', "&:hover": { backgroundColor: "#2c2e3e", color: 'white' } },
  textField: {
    width: '300px',
    borderRadius: '8px',
    marginBottom: '1rem'
  }
  
});
const ChangePasswordScreen = (props) => {
  
  const classes = useStyles();
  
  const { userDetails } = useSelector(state => state.user)
  
  const history = useHistory()
  
  const [changePasswordModal, setChangePasswordModal] = useState(true);
  
  const [password, setPassword] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  
  const [isPasswordShort, setIsPasswordShort] = useState(false)
  
  const [isConfirmPasswordMatched, setIsConfirmPasswordMatched] = useState(true)
  
  const [isPasswordValidated, setIsPasswordValidated] = useState({
    hasNumber: false,
    hasUpperCase: false,
    hasLowerCase: false,
    hasSpecialChar: false
  });
  
  const [passwordStrength, setPasswordStrength] = useState("");
  
  const [passwordStrengthColor, setPasswordStrengthColor] = useState("red");
  
  const [showPassword, setShowPassword] = useState(false)
  
  const togglePasswordVisibility = () => {
    
    // console.log('togglePasswordVisibility called', showPassword)
    
    setShowPassword(!showPassword);

  };
  
  const validatePassword = (password) => {

    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$~`_\-*&^%])[A-Za-z\d!@#$~`_\-*&^%]{8,}$/;
    
    // setIsValid(regex.test(password));
    
    return regex.test(password)

  };
  
  const validatePassword2 = (password) => {

    const numberRegex = /\d/;
    
    const upperCaseRegex = /[A-Z]/;
    
    const lowerCaseRegex = /[a-z]/;
    
    const specialCharRegex = /[!@#$~`_\-*&^%]/;
    
    const hasNumber = numberRegex.test(password);
    
    const hasUpperCase = upperCaseRegex.test(password);
    
    const hasLowerCase = lowerCaseRegex.test(password);
    
    const hasSpecialChar = specialCharRegex.test(password);
    
    return {
        hasNumber,
        hasUpperCase,
        hasLowerCase,
        hasSpecialChar
    };

  };

  const checkPasswordStrength = (password) => {

    // Define strength levels
    const strengthLevels = {
      
        veryWeak: /^(?=.*[a-z])/,
        weak: /^(?=.*[a-z])(?=.*[A-Z])/,
        ok: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/,
        strong: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])/,
        veryStrong: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])(?=.{8,})/,

    };

    // Check password against each strength level
    if (strengthLevels.veryStrong.test(password)) {

      setPasswordStrengthColor('darkgreen')
    
      return 'Very Strong';

    } else if (strengthLevels.strong.test(password)) {

      setPasswordStrengthColor('lightgreen')

      return 'Strong';

    } else if (strengthLevels.ok.test(password)) {
        
      setPasswordStrengthColor('blue')
        
      return 'Ok';

    } else if (strengthLevels.weak.test(password)) {

      setPasswordStrengthColor('orange')
      
      return 'Weak';
      
    } else {

      setPasswordStrengthColor('red')
      
      return 'Very Weak';

    }
  };

  const changeHandler = (e) => {

    // console.log('e.target.name: ', e.target.name)
    
    // console.log('e.target.value: ', e.target.value)
    
    let name = e.target.name
    
    let value = e.target.value
    
    if(name === 'newPassword') {

      const passwordStrength = checkPasswordStrength(value)

      //console.log('passwordStrength', passwordStrength)
      
      setPasswordStrength(passwordStrength)

      const passwordValidation = validatePassword2(value)
      // console.log('passwordValidation', passwordValidation)
      setIsPasswordValidated(passwordValidation)

      if(value.length < 8) {
        setIsPasswordShort(true)
      } else {
        setIsPasswordShort(false)
      }

      if(value !== password.confirmPassword) {
        setIsConfirmPasswordMatched(false)
      } else {
        setIsConfirmPasswordMatched(true)
      }
      
    } else if(name === 'confirmPassword') {

      if(password.newPassword !== value) {

        setIsConfirmPasswordMatched(false)

      } else {

        setIsConfirmPasswordMatched(true)

      }
    }

    setPassword({ ...password, [e.target.name]: e.target.value })

  }

  const handleCancelForChangePassword = () => {

    // console.log('userDetails', userDetails)

    let MyLastPasswordCreationDate = new Date(userDetails.LastPasswordCreationDate)

    // console.log('MyLastPasswordCreationDate', MyLastPasswordCreationDate)
    
    let CurrentDate = new Date()
    
    // console.log('CurrentDate', CurrentDate)
    
    let timeDifference = CurrentDate.getTime() - MyLastPasswordCreationDate.getTime();
    
    // console.log('timeDifference', timeDifference)
    
    let differenceInDays = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    
    // console.log('differenceInDays', differenceInDays)
    
    // console.log('called handleCancelForChangePassword')
    
    setIsPasswordShort(false)
    
    setIsConfirmPasswordMatched(true)
    
    setPassword({
      ...password,
      currentPassword: "",
      newPassword: "",
      confirmPassword: ""
    })
    
    setChangePasswordModal(false)

  }

  const changePassword = async () => {

    props.setLoading(true)
    
    // console.log('newPassword', password.newPassword)
    
    const isPasswordValid = validatePassword(password.newPassword)
    
    // console.log('isPasswordValid', isPasswordValid)
    
    if(!isPasswordValid) {

      props.setSnackInfo('Password should contain atleast one Uppercase, Lowercase, Numeric & Special character !,@,#,$,~,`,_,-,*,&,^,%', "error")
      
      props.setLoading(false)

      return null
    }
    
    try {

      if (password.newPassword !== password.confirmPassword)
        return props.setSnackInfo('Passwords Do Not Match', "error")
    
      let email = localStorage.getItem('EMAIL_ID')
      
      // console.log('email', email)
      
      await HttpService.changePasswordBeforeLogin({email: email, password: password.currentPassword, newPassword: password.newPassword })
      
      props.setSnackInfo('Password changed successfully. Please Re-login.', "success")
      
      setPassword({
        currentPassword: "",
        newPassword: "",
        confirmPassword: ""
      })
      
      setChangePasswordModal(false)
      
      history.push('/');

    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went Wrong", "error")
    }

    props.setLoading(false)

  }

  const Transition = React.forwardRef(function Transition(props, ref) {

    return <Slide direction="up" ref={ref} {...props} />;

  });

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: '80vh', backgroundColor: '2c2e3e' }}>
      
      <h3 style={{ fontSize: '2rem', marginBottom: '1rem' }}>Please change your password</h3>
      
      <TextField style={{ marginBottom: '1rem', width: '300px' }} className={classes.textField} label="Current Password" name="currentPassword" variant="outlined" type="password" value={password.currentPassword} onChange={changeHandler} />
      
      {/* <input style={{ borderRadius: '50px', height: '40px', padding: '0 10px' }} className={classes.textField} placeholder="Current Password" name="currentPassword" type="password" value={password.currentPassword} onChange={changeHandler} /> */}
      
      {password.newPassword.length > 0 && isPasswordShort && <p style={{ color: 'red', fontSize: '1.2rem', marginBottom: '0.5rem' }}>*Password must be at least 8 characters long</p>}
      
      <TextField style={{ marginBottom: '1rem', width: '300px' }} label="New Password" name="newPassword" variant="outlined" type="password" value={password.newPassword} onChange={changeHandler} />
      
      {/* <EyeFilled style={{ cursor: 'pointer', marginLeft: 8, fontSize: 25, color: showPassword ? 'blue' : 'black'}} onClick={() => togglePasswordVisibility()} /> */}
      
      {password.newPassword.length > 0 && <p style={{ color: passwordStrengthColor, fontWeight: 900, marginBottom: '0.5rem', fontSize: '1.2rem' }}>{passwordStrength}</p>}
      
      {password.confirmPassword.length > 0 && !isConfirmPasswordMatched && <p style={{ color: 'red', fontSize: '1.2rem', marginBottom: '0.5rem' }}>*Passwords do not match</p>}
      
      <TextField style={{ marginBottom: '1rem', width: '300px' }} label="Confirm Password" name="confirmPassword" variant="outlined" type="password" value={password.confirmPassword} onChange={changeHandler} />
      {
        password.newPassword.length > 0 &&
        <>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            
            <div style={{ display: 'flex', alignItems: 'center', marginRight: 20 }}>
              <p style={{ marginRight: 10, fontSize: 14 }}>Atleast 1 Uppercase</p>
              {isPasswordValidated.hasUpperCase ? <CheckCircleIcon style={{ verticalAlign: 'middle', color: 'green', fontSize: 30 }} /> : <span style={{color: 'red'}}>: No</span>}
            </div>
            
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <p style={{ marginRight: 10, fontSize: 14 }}>Atleast 1 Lowercase</p>
              {isPasswordValidated.hasLowerCase ? <CheckCircleIcon style={{ verticalAlign: 'middle', color: 'green', fontSize: 30 }} /> : <span style={{color: 'red'}}>: No</span>}
            </div>
          
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
            
            <div style={{ display: 'flex', alignItems: 'center', marginRight: 20 }}>
              <p style={{ marginRight: 10, fontSize: 14 }}>Atleast 1 Number</p>
              {isPasswordValidated.hasNumber ? <CheckCircleIcon style={{ verticalAlign: 'middle', color: 'green', fontSize: 30 }} /> : <span style={{color: 'red'}}>: No</span>}
            </div>
            
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <p style={{ marginRight: 10, fontSize: 14 }}>Atleast 1 Special Character</p>
              {isPasswordValidated.hasSpecialChar ? <CheckCircleIcon style={{ verticalAlign: 'middle', color: 'green', fontSize: 30 }} /> : <span style={{color: 'red'}}>: No</span>}
            </div>
          
          </div>
        </>
            
      }
      <div>

        <Button className={classes.cancelButton} onClick={() => handleCancelForChangePassword()} color="primary" variant="contained">
          Cancel
        </Button>

        <Button className={classes.proceedButton} onClick={changePassword} color="primary" variant="contained" disabled={password.currentPassword === "" || password.newPassword === "" || password.confirmPassword === "" || isPasswordShort || !isConfirmPasswordMatched || ['Very Weak', 'Weak'].includes(passwordStrength)}>
          Proceed
        </Button>
      
      </div>
    
    </div>
  );
};

export default connect(null, { setLoading, setSnackInfo })(ChangePasswordScreen)