import React, { useState, useEffect, useRef } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Dialog,
    Grid,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText,
    TableFooter,
    IconButton,
    TablePagination,
    Divider,
    TextField,
    FormControl,
} from "@material-ui/core";
import { connect } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { setLoading, setSnackInfo } from "../../redux/actions/appAction";
import { DownloadOutlined, EyeOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import moment from "moment";
import HttpService from "../../services/httpService";
import { CSVLink } from "react-csv";
import PropTypes from "prop-types";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { palette } from "@mui/system";
import { commaSeparateAmount } from "../../components/commaSeparate";

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 850,
    },
    textField: {
        width: 100,
    },
    paper: {
        padding: theme.spacing(2),
        margin: "auto",
        width: "auto",
        // maxWidth: 400,
    },
}));

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

const StyledTableCell = withStyles((theme) => ({
    head: {
        // backgroundColor: "#2C2E3E",
        backgroundColor: "#3F51B5",
        color: "#FFF",
        fontWeight: "500",
        // border: '1px solid black'
    },
    body: {
        fontSize: 14,
        // border: '1px solid red'
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === "rtl" ? (
                    <LastPageIcon />
                ) : (
                    <FirstPageIcon />
                )}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                ) : (
                    <KeyboardArrowLeft />
                )}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                ) : (
                    <KeyboardArrowRight />
                )}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === "rtl" ? (
                    <FirstPageIcon />
                ) : (
                    <LastPageIcon />
                )}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const ApprovedPaymentReceivedAnalysis = (props) => {
    const classes = useStyles();
    const [approvedPaymentData, setApprovedPaymentData] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [csvData, setCsvData] = useState([]);
    const csvButtonRef = useRef(null);
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);

    // Headers to for csv
    const headers = [
        { key: "date", label: "Date" },
        { key: "upi", label: "UPI (Automatic)" },
        { key: "cashfree", label: "Cash Free (Automatic)" },
        {
            key: "bankTransferVirtual",
            label: "Bank Transfer Virtual Payment (Automatic)",
        },
        { key: "cardPineLabs", label: "Card - Pine Labs (Automatic)" },
        { key: "totalAutomatic", label: "Total (Automatic)" },
        { key: "cheque", label: "Cheque (Manual)" },
        {
            key: "cardPineLabsNotErp",
            label: "Card - Pine Labs (not via ERP) (Manual)",
        },
        {
            key: "bankTransferNonVirtual",
            label: "Bank Transfers - Non Virtual Account (Manual)",
        },
        { key: "cash", label: "Cash (Manual)" },
        { key: "others", label: "Others (Manual)" },
        { key: "totalManual", label: "Total (Manual)" },
        { key: "grandTotal", label: "Grand Total" },
    ];

    // Function to fetch approved payments data
    const fetchApprovedPaymentsHandler = async (startDate, endDate) => {
        props.setLoading(true);
        try {
            let payload = { startDate, endDate, page, rowsPerPage };
            const response = await HttpService.fetchApprovedPaymentsData(
                payload
            );
            setApprovedPaymentData(response.data);
            // console.log("Response -> ", response.data);
        } catch (error) {
            console.error("Error -> ", error);
        }
        props.setLoading(false);
    };

    // filter function for date range
    const fetchApprovedPaymentsOnSelectedDate = () => {
        fetchApprovedPaymentsHandler(startDate, endDate);
    };

    // function to clear date filter
    const clearFilterDate = (e) => {
        setStartDate("");
        setEndDate("");
        fetchApprovedPaymentsHandler("", "");
    };

    // function to download data in csv
    const csvDownloadHandler = async () => {
        props.setLoading(true);
        try {
            let csvArray = [];
            for (let i = 0; i < approvedPaymentData.length; i++) {
                let el = approvedPaymentData[i];
                let obj = {
                    date: el?.date,
                    upi: el?.Automatic?.UPI?.total?.toLocaleString("en-IN", {
                        maximumFractionDigits: 2,
                        currency: "INR",
                    }),
                    cashfree: el?.Automatic?.Cash_Free?.total?.toLocaleString(
                        "en-IN",
                        { maximumFractionDigits: 2, currency: "INR" }
                    ),
                    bankTransferVirtual:
                        el?.Automatic?.Bank_Transfer_Virtual_Payment?.total?.toLocaleString(
                            "en-IN",
                            { maximumFractionDigits: 2, currency: "INR" }
                        ),
                    cardPineLabs:
                        el?.Automatic?.Card_Pine_Labs?.total?.toLocaleString(
                            "en-IN",
                            { maximumFractionDigits: 2, currency: "INR" }
                        ),
                    totalAutomatic:
                        el?.Automatic?.grandTotal?.total?.toLocaleString(
                            "en-IN",
                            { maximumFractionDigits: 2, currency: "INR" }
                        ),
                    cheque: el?.Manual?.Cheque?.total?.toLocaleString("en-IN", {
                        maximumFractionDigits: 2,
                        currency: "INR",
                    }),
                    cardPineLabsNotErp:
                        el?.Manual?.Card_Pine_Labs?.total?.toLocaleString(
                            "en-IN",
                            { maximumFractionDigits: 2, currency: "INR" }
                        ),
                    bankTransferNonVirtual:
                        el?.Manual?.Bank_Transfer_Non_Virtual_Payment?.total?.toLocaleString(
                            "en-IN",
                            { maximumFractionDigits: 2, currency: "INR" }
                        ),
                    cash: el?.Manual?.Cash?.total?.toLocaleString("en-IN", {
                        maximumFractionDigits: 2,
                        currency: "INR",
                    }),
                    others: el?.Manual?.Others?.total?.toLocaleString("en-IN", {
                        maximumFractionDigits: 2,
                        currency: "INR",
                    }),
                    totalManual: el?.Manual?.grandTotal?.total?.toLocaleString(
                        "en-IN",
                        { maximumFractionDigits: 2, currency: "INR" }
                    ),
                    grandTotal: el?.grandTotal?.total?.toLocaleString("en-IN", {
                        maximumFractionDigits: 2,
                        currency: "INR",
                    }),
                };
                csvArray.push(obj);
                /*
                obj = {
                    date: "",
                    upi: "",
                    cashfree: "",
                    bankTransferVirtual: "",
                    cardPineLabs: "",
                    totalAutomatic: "",
                    cheque: "",
                    cardPineLabsNotErp: "",
                    bankTransferNonVirtual: "",
                    cash: "",
                    others: "",
                    totalManual: "",
                    grandTotal: "",
                };
                csvArray.push(obj);
                */
            }
            setCsvData(csvArray);
            downloadExcel();
        } catch (error) {
            console.error("error", error);
            props?.setSnackInfo(
                error?.response?.data?.message ?? "Something went wrong",
                "error"
            );
        }
        props.setLoading(false);
    };

    // A helper function for csv download
    const downloadExcel = () => {
        props.setLoading(true);
        try {
            setTimeout(() => {
                csvButtonRef.current.link.click();
            });
        } catch (err) {
            console.error(err.message);
        }
        props.setLoading(false);
    };

    // Function to handle the change in page
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // Function to handle change in rows per page
    const handleChangeRowsPerPage = (event) => {
        if (event.target.value === "All") {
            setRowsPerPage(parseInt(totalPages, 10));
        } else {
            setRowsPerPage(parseInt(event.target.value, 10));
        }
        setPage(0);
    };

    useEffect(() => {
        fetchApprovedPaymentsHandler("", "");
    }, []);

    return (
        <div>
            <div
                style={{
                    marginBottom: 20,
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Paper className={classes.paper}>
                    <center>
                        <h2>Approved Payment Receipts</h2>
                    </center>
                </Paper>
            </div>

            {/* Date Filter component */}
            <Paper style={{ padding: 10, marginBottom: 10 }}>
                <Grid
                    container
                    style={{ display: "flex", alignItems: "center" }}
                >
                    <Grid item md={3} xs={12}>
                        <TextField
                            id="date"
                            name="startDate"
                            label="From Received Date"
                            type="date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                            // className={classes.textField}
                        />
                    </Grid>
                    <Grid item md={1} xs={12}></Grid>
                    <Grid item md={3} xs={12}>
                        <TextField
                            id="date"
                            name="endDate"
                            label="To Received Date"
                            type="date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                max: moment().format("YYYY-MM-DD"),
                                onKeyDown: (e) => e.preventDefault(),
                            }}
                            fullWidth
                            // className={classes.textField}
                        />
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            // disabled={!startDate || !endDate}
                            onClick={fetchApprovedPaymentsOnSelectedDate}
                            style={{ marginLeft: 10 }}
                        >
                            Submit
                        </Button>
                    </Grid>
                    <Grid item md={2} xs={12}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={clearFilterDate}
                        >
                            Clear Filter
                        </Button>
                    </Grid>

                    {/* Download CSV Button */}
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <CSVLink
                            headers={headers}
                            filename={
                                "Approved Payment Received Analysis " +
                                new Date()
                                    .toLocaleDateString()
                                    .replaceAll("/", "_") +
                                ".csv"
                            }
                            data={csvData}
                            ref={csvButtonRef}
                        />
                        <Button
                            onClick={csvDownloadHandler}
                            style={{ margin: 10, backgroundColor: "green" }}
                            variant="contained"
                            color="success"
                        >
                            Download CSV
                        </Button>
                    </div>
                </Grid>
            </Paper>

            {/* Data Mapping of Approved Payment Received */}
            <TableContainer component={Paper}>
                <Table
                    className={classes.table}
                    size="small"
                    aria-label="customized table"
                    stickyHeader
                >
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell rowSpan={2}>Sl No</StyledTableCell>
                            <StyledTableCell rowSpan={2}>Date</StyledTableCell>
                            <StyledTableCell colSpan={5} align="center">
                                Payment Mode Automatic
                            </StyledTableCell>
                            {/*
                            <StyledTableCell rowSpan={2}>
                                Total (Automatic)
                            </StyledTableCell>
                            */}
                            <StyledTableCell colSpan={6} align="center">
                                Payment Mode Manual
                            </StyledTableCell>
                            {/*
                            <StyledTableCell rowSpan={2}>
                                Total (Manual)
                            </StyledTableCell>
                            */}
                            <StyledTableCell rowSpan={2}>
                                Grand Total
                            </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <StyledTableCell>UPI</StyledTableCell>
                            <StyledTableCell>Cash free</StyledTableCell>
                            <StyledTableCell>
                                Bank Transfer Virtual Payment
                            </StyledTableCell>
                            <StyledTableCell>Card - Pine Labs</StyledTableCell>
                            <StyledTableCell rowSpan={2}>
                                Total (Automatic)
                            </StyledTableCell>
                            <StyledTableCell>Cheque</StyledTableCell>
                            <StyledTableCell>
                                Card - Pine Labs (not via ERP)
                            </StyledTableCell>
                            <StyledTableCell>
                                Bank Transfers - Non Virtual Account
                            </StyledTableCell>
                            <StyledTableCell>Cash</StyledTableCell>
                            <StyledTableCell>Others</StyledTableCell>
                            <StyledTableCell rowSpan={2}>
                                Total (Manual)
                            </StyledTableCell>
                        </StyledTableRow>
                    </TableHead>

                    <TableBody>
                        {approvedPaymentData?.map((el, i) => {
                            return (
                                <StyledTableRow key={i + 1}>
                                    <StyledTableCell>
                                        {el?.date !== "Grand Total"
                                            ? i + 1
                                            : ""}
                                    </StyledTableCell>
                                    <StyledTableCell
                                        style={{
                                            fontWeight: `${
                                                el?.date === "Grand Total"
                                                    ? "700"
                                                    : ""
                                            }`,
                                        }}
                                    >
                                        {el?.date}
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align="right"
                                        style={{
                                            fontWeight: `${
                                                el?.date === "Grand Total"
                                                    ? "700"
                                                    : ""
                                            }`,
                                        }}
                                    >
                                        {commaSeparateAmount(el?.Automatic?.UPI?.total / 100000)}
                                        {/* {el?.Automatic?.UPI?.total?.toFixed(2)} */}
                                        {/* 
                                        {el?.Automatic?.UPI?.total?.toLocaleString(
                                            "en-IN",
                                            {
                                                maximumFractionDigits: 2, // Maximum number of decimal places
                                                // style: "currency",
                                                currency: "INR",
                                            }
                                        )}
                                        */}
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align="right"
                                        style={{
                                            fontWeight: `${
                                                el?.date === "Grand Total"
                                                    ? "700"
                                                    : ""
                                            }`,
                                        }}
                                    >
                                        {commaSeparateAmount(el?.Automatic?.Cash_Free?.total / 100000)}
                                        {/* {el?.Automatic?.Cash_Free?.total?.toFixed(2)} */}
                                        {/*
                                        {el?.Automatic?.Cash_Free?.total?.toLocaleString(
                                            "en-IN",
                                            {
                                                maximumFractionDigits: 2, // Maximum number of decimal places
                                                // style: "currency",
                                                currency: "INR",
                                            }
                                        )}
                                        */}
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align="right"
                                        style={{
                                            fontWeight: `${
                                                el?.date === "Grand Total"
                                                    ? "700"
                                                    : ""
                                            }`,
                                        }}
                                    >
                                        {commaSeparateAmount(el?.Automatic?.Bank_Transfer_Virtual_Payment?.total / 100000)}
                                        {/* {el?.Automatic?.Bank_Transfer_Virtual_Payment?.total?.toFixed(2)} */}
                                        {/*
                                        {el?.Automatic?.Bank_Transfer_Virtual_Payment?.total?.toLocaleString(
                                            "en-IN",
                                            {
                                                maximumFractionDigits: 2, // Maximum number of decimal places
                                                // style: "currency",
                                                currency: "INR",
                                            }
                                        )}
                                        */}
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align="right"
                                        style={{
                                            fontWeight: `${
                                                el?.date === "Grand Total"
                                                    ? "700"
                                                    : ""
                                            }`,
                                        }}
                                    >
                                        {commaSeparateAmount(el?.Automatic?.Card_Pine_Labs?.total / 100000)}
                                        {/* {el?.Automatic?.Card_Pine_Labs?.total?.toFixed(2)} */}
                                        {/*
                                        {el?.Automatic?.Card_Pine_Labs?.total?.toLocaleString(
                                            "en-IN",
                                            {
                                                maximumFractionDigits: 2, // Maximum number of decimal places
                                                // style: "currency",
                                                currency: "INR",
                                            }
                                        )}
                                        */}
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align="right"
                                        style={{
                                            fontWeight: `${
                                                el?.date === "Grand Total"
                                                    ? "700"
                                                    : ""
                                            }`,
                                        }}
                                    >
                                        {commaSeparateAmount(el?.Automatic?.grandTotal?.total / 100000)}
                                        {/* {el?.Automatic?.grandTotal?.total?.toFixed(2)} */}
                                        {/*
                                        {el?.Automatic?.grandTotal?.total?.toLocaleString(
                                            "en-IN",
                                            {
                                                maximumFractionDigits: 2, // Maximum number of decimal places
                                                // style: "currency",
                                                currency: "INR",
                                            }
                                        )}
                                        */}
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align="right"
                                        style={{
                                            fontWeight: `${
                                                el?.date === "Grand Total"
                                                    ? "700"
                                                    : ""
                                            }`,
                                        }}
                                    >
                                        {commaSeparateAmount(el?.Manual?.Cheque?.total / 100000)}
                                        {/* {el?.Manual?.Cheque?.total?.toFixed(2)} */}
                                        {/*
                                        {el?.Manual?.Cheque?.total?.toLocaleString(
                                            "en-IN",
                                            {
                                                maximumFractionDigits: 2, // Maximum number of decimal places
                                                // style: "currency",
                                                currency: "INR",
                                            }
                                        )}
                                        */}
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align="right"
                                        style={{
                                            fontWeight: `${
                                                el?.date === "Grand Total"
                                                    ? "700"
                                                    : ""
                                            }`,
                                        }}
                                    >
                                        {commaSeparateAmount(el?.Manual?.Card_Pine_Labs?.total / 100000)}
                                        {/* {el?.Manual?.Card_Pine_Labs?.total?.toFixed(2)} */}
                                        {/*
                                        {el?.Manual?.Card_Pine_Labs?.total?.toLocaleString(
                                            "en-IN",
                                            {
                                                maximumFractionDigits: 2, // Maximum number of decimal places
                                                // style: "currency",
                                                currency: "INR",
                                            }
                                        )}
                                        */}
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align="right"
                                        style={{
                                            fontWeight: `${
                                                el?.date === "Grand Total"
                                                    ? "700"
                                                    : ""
                                            }`,
                                        }}
                                    >
                                        {commaSeparateAmount(el?.Manual?.Bank_Transfer_Non_Virtual_Payment?.total / 100000)}
                                        {/* {el?.Manual?.Bank_Transfer_Non_Virtual_Payment?.total?.toFixed(2)} */}
                                        {/*
                                        {el?.Manual?.Bank_Transfer_Non_Virtual_Payment?.total?.toLocaleString(
                                            "en-IN",
                                            {
                                                maximumFractionDigits: 2, // Maximum number of decimal places
                                                // style: "currency",
                                                currency: "INR",
                                            }
                                        )}
                                        */}
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align="right"
                                        style={{
                                            fontWeight: `${
                                                el?.date === "Grand Total"
                                                    ? "700"
                                                    : ""
                                            }`,
                                        }}
                                    >
                                        {commaSeparateAmount(el?.Manual?.Cash?.total / 100000)}
                                        {/* {el?.Manual?.Cash?.total?.toFixed(2)} */}
                                        {/*
                                        {el?.Manual?.Cash?.total?.toLocaleString(
                                            "en-IN",
                                            {
                                                maximumFractionDigits: 2, // Maximum number of decimal places
                                                // style: "currency",
                                                currency: "INR",
                                            }
                                        )}
                                        */}
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align="right"
                                        style={{
                                            fontWeight: `${
                                                el?.date === "Grand Total"
                                                    ? "700"
                                                    : ""
                                            }`,
                                        }}
                                    >
                                        {commaSeparateAmount(el?.Manual?.Others?.total / 100000)}
                                        {/* {el?.Manual?.Others?.total?.toFixed(2)} */}
                                        {/*
                                        {el?.Manual?.Others?.total?.toLocaleString(
                                            "en-IN",
                                            {
                                                maximumFractionDigits: 2, // Maximum number of decimal places
                                                // style: "currency",
                                                currency: "INR",
                                            }
                                        )}
                                        */}
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align="right"
                                        style={{
                                            fontWeight: `${
                                                el?.date === "Grand Total"
                                                    ? "700"
                                                    : ""
                                            }`,
                                        }}
                                    >
                                        {commaSeparateAmount(el?.Manual?.grandTotal?.total / 100000)}
                                        {/* {el?.Manual?.grandTotal?.total?.toFixed(2)} */}
                                        {/*
                                        {el?.Manual?.grandTotal?.total?.toLocaleString(
                                            "en-IN",
                                            {
                                                maximumFractionDigits: 2, // Maximum number of decimal places
                                                // style: "currency",
                                                currency: "INR",
                                            }
                                        )}
                                        */}
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align="right"
                                        style={{
                                            fontWeight: "700",
                                        }}
                                    >
                                        {commaSeparateAmount(el?.grandTotal?.total / 100000)}
                                        {/* {el?.grandTotal?.total?.toFixed(2)} */}
                                        {/*
                                        {el?.grandTotal?.total?.toLocaleString(
                                            "en-IN",
                                            {
                                                maximumFractionDigits: 2, // Maximum number of decimal places
                                                // style: "currency",
                                                currency: "INR",
                                            }
                                        )}
                                        */}
                                    </StyledTableCell>
                                </StyledTableRow>
                            );
                        })}
                    </TableBody>
                    {/*
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[
                                    50,
                                    100,
                                    200,
                                    {
                                        label: "All",
                                        value: totalPages ? totalPages : "",
                                    },
                                ]}
                                colSpan={8}
                                count={totalPages}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        "aria-label": "rows per page",
                                    },
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                    */}
                </Table>
            </TableContainer>
        </div>
    );
};

export default connect(null, { setLoading, setSnackInfo })(
    ApprovedPaymentReceivedAnalysis
);