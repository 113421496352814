import React from "react";
import errorPng from '../../../src/assets/img/org/error.png';

const PageNotFound = () => {
  return (
    <div
      style={{
        backgroundImage: `url(${errorPng})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        height: "100vh", // Adjust as needed
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* Your content goes here */}
    </div>
  );
};

export default PageNotFound;