import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux'
import { setLoading, setSnackInfo } from '../redux/actions/appAction';
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow, TableHead, Button } from '@material-ui/core';
import { ROLENAME } from '../utils/index';
import { Typography } from 'antd';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }
}));

const ImosDashboard = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const [imosData, setImosData] = useState([]);

    useEffect(() => {
        setImosData(props.DATA)
        // eslint-disable-next-line
    }, [props.DATA])


    const changePageRoute = (imosStage, userId) => {
        history.push(`/imos-stage-projects/${imosStage.replace(/[\s+%/\\#,+$~.'":*?<>{}]/g, '-')}`, {
            experienceCenterId: props.selectedexpcenterid,
            locationId: props.selectedlocationid,
            assignTo: userId
        });
    }

    return (
        <div>
            <div style={{ marginTop: 25, marginBottom: 25 }}>
                <Paper className={classes.paper}>
                    <Typography component="h3">{props.TITLE}</Typography>

                    <TableContainer component={Paper} style={{ marginTop: 10 }}>
                        <Table className={classes.table} stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow >
                                    {
                                        props.ROLE === ROLENAME.IMOSManager && <>
                                            <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Sl No</TableCell>
                                            <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>User</TableCell>
                                        </>
                                    }
                                    <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Assigned Lead</TableCell>
                                    <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Drawing In-Progress </TableCell>
                                    <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>IMOS QC In-Progress</TableCell>
                                    <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>IMOS Completed</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    imosData.length !== 0 ?
                                        imosData.map((status, i) => {
                                            return (
                                                <TableRow key={i + 1}>
                                                    {
                                                        props.ROLE === ROLENAME.IMOSManager && <>
                                                            <TableCell component="th" scope="row" style={{ textAlign: 'center' }} >{i + 1}</TableCell>
                                                            <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{status.user}</TableCell>
                                                        </>
                                                    }
                                                    <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>
                                                        <Button onClick={() => changePageRoute('Assign To IMOS User', status.userId)} color="primary">
                                                            {status.stage['Assign To IMOS User']}
                                                        </Button>
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>
                                                        <Button onClick={() => changePageRoute('IMOS Drawing In-Progress', status.userId)} color="primary">
                                                            {status.stage['IMOS Drawing In-Progress']}
                                                        </Button>
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>
                                                        <Button onClick={() => changePageRoute('IMOS QC In-Progress', status.userId)} color="primary">
                                                            {status.stage['IMOS QC In-Progress']}
                                                        </Button>
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>
                                                        <Button onClick={() => changePageRoute('IMOS Completed', status.userId)} color="primary">
                                                            {status.stage['IMOS Completed']}
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                        :
                                        <TableRow>
                                            <TableCell colSpan={9} style={{ textAlign: "center" }}>No Data Found</TableCell>
                                        </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </div>
        </div>
    )
}

export default connect(null, { setLoading, setSnackInfo })(ImosDashboard)
