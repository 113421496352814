import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { setLoading, setSnackInfo } from '../redux/actions/appAction';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import HttpService from '../services/httpService';
import { ROLENAME } from '../utils';
import InfoIcon from '@material-ui/icons/Info';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 700,
        textAlign: 'center'
    },
    root: {
        width: '100%',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
}));

const LeadDesignToExecutionView = (props) => {
    const classes = useStyles();
    const { userDetails } = useSelector(state => state.user);
    const [designToExecutionLeadList, setDesignToExecutionLeadList] = useState([]);

    useEffect(() => {
        getAllDesignToExecutionRequestLeads()
        // eslint-disable-next-line
    }, [])

    const getAllDesignToExecutionRequestLeads = async () => {
        props.setLoading(true)
        try {
            const response = await HttpService.getDesignToFinanceLeadApprovalLeadList(userDetails.selectedExpCenter)
            setDesignToExecutionLeadList(response.data)
        } catch (error) {
            console.log(error, 'error')
            setDesignToExecutionLeadList([]);
        }
        props.setLoading(false)
    }
    const navigateToVerifyPage = (leadId) => {
        props.history.push(`/validate-contract-documents/${leadId}`);
    }

    const [openRejectModal, setOpenRejectModal] = useState(false);
    const [rejectRemark, setRejectRemark] = useState([]);
    const showRejectedStatus = (data) => {
        setOpenRejectModal(true);
        setRejectRemark(data);
    }

    return (
        <div>
            <div style={{ marginBottom: 20 }}>
                <h3 style={{ textAlign: 'center' }}>
                    Design To Execution Approval
                </h3>
            </div>
            <Divider />

            <div style={{ display: 'flex', marginTop: 10, marginBottom: 20 }}>
                <Paper>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} size="small" stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Sr. No.</TableCell>
                                    <TableCell>Lead Name</TableCell>
                                    <TableCell>Project No.</TableCell>
                                    <TableCell>Design User</TableCell>
                                    {/* <TableCell>Final Marking</TableCell> */}
                                    <TableCell>Quality Control</TableCell>
                                    <TableCell>QC Manager Status</TableCell>
                                    <TableCell>ADM Status</TableCell>
                                    {/* <TableCell>Design Head Status</TableCell> */}
                                    {/* <TableCell>Operations</TableCell> */}
                                    <TableCell>Customer Status</TableCell>
                                    <TableCell>Finance Status</TableCell>
                                    {userDetails.roles.find(role => role.name !== ROLENAME.DesignUser) &&
                                        <TableCell colSpan={2}>Action</TableCell>
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {designToExecutionLeadList.length !== 0 ?
                                    designToExecutionLeadList.map((row, i) => {
                                        return (
                                            <TableRow key={i + 1}>
                                                <TableCell>{i + 1}</TableCell>
                                                <TableCell component="th" scope="row">
                                                    <Link to={`/project-logs/${row._id}`} style={{ color: "#000", textDecoration: 'underline' }}>{row?.customerId?.name}</Link>
                                                </TableCell>
                                                <TableCell component="th">{row?.erpProjectNo ? row?.erpProjectNo :'-'}</TableCell>
                                                <TableCell component="th">{row?.assignTo?.name}</TableCell>
                                                {/* <TableCell component="th">
                                                    {row?.contractFinalMarkingApproved ? 'Approved' : row?.contactLeadRejectedRole === ROLENAME.FinalMarking ? 'Rejected' : 'Sent For Approval'}
                                                    {!row?.contractFinalMarkingApproved && row?.contactLeadRejectedRole === ROLENAME.FinalMarking && <InfoIcon style={{ cursor: 'pointer' }} fontSize="small" color="secondary" onClick={() => showRejectedStatus(row)} />}
                                                </TableCell> */}
                                                <TableCell component="th">
                                                    {row?.contractQualityControlApproved ? 'Approved' : row?.contactLeadRejectedRole === ROLENAME.QualityControl ? 'Rejected' : row?.contactLeadRejectedRole !== null ? 'Not Initiated' : 'Sent For Approval'}
                                                    {!row?.contractQualityControlApproved && row?.contactLeadRejectedRole === ROLENAME.QualityControl && <InfoIcon style={{ cursor: 'pointer' }} fontSize="small" color="secondary" onClick={() => showRejectedStatus(row)} />}
                                                </TableCell>
                                                <TableCell component="th">
                                                    {row?.contractQCManagerApproved ? 'Approved' : row?.contactLeadRejectedRole === ROLENAME.QCManager ? 'Rejected' : row?.contractQualityControlApproved ? 'Sent For Approval' : 'Not Approved'}
                                                    {!row?.contractQCManagerApproved && row?.contactLeadRejectedRole === ROLENAME.QCManager && <InfoIcon style={{ cursor: 'pointer' }} fontSize="small" color="secondary" onClick={() => showRejectedStatus(row)} />}
                                                </TableCell>
                                                <TableCell component="th">
                                                    {row?.contractDesignManagerApproved ? 'Approved' : row?.contactLeadRejectedRole === ROLENAME.DesignManager ? 'Rejected' : row?.contractQualityControlApproved  && row?.contractQCManagerApproved ? 'Sent For Approval' : 'Not Approved'}
                                                    {!row?.contractDesignManagerApproved && row?.contactLeadRejectedRole === ROLENAME.DesignManager && <InfoIcon style={{ cursor: 'pointer' }} fontSize="small" color="secondary" onClick={() => showRejectedStatus(row)} />}
                                                </TableCell>
                                                {/* <TableCell component="th">
                                                    {row?.contractDesignApproved ? 'Approved' : row?.contactLeadRejectedRole === ROLENAME.DesignHead ? 'Rejected' : row?.contractFinanceApproved && row?.contractDesignManagerApproved && row?.contractFinalMarkingApproved && row?.contractQualityControlApproved ? 'Sent For Approval' : 'Not Approved'}
                                                    {!row?.contractDesignApproved && row?.contactLeadRejectedRole === ROLENAME.DesignHead && <InfoIcon style={{ cursor: 'pointer' }} fontSize="small" color="secondary" onClick={() => showRejectedStatus(row)} />}
                                                </TableCell> */}
                                                {/* <TableCell component="th">
                                                    {row?.contractOperationApproved ? 'Approved' : row?.contactLeadRejectedRole === ROLENAME.SeniorOperationManager ? 'Rejected' : row?.contractFinanceApproved && row?.contractDesignManagerApproved && row?.contractDesignApproved && row?.contractFinalMarkingApproved && row?.contractQualityControlApproved ? 'Sent For Approval' : 'Not Approved'}
                                                    {!row?.contractOperationApproved && row?.contactLeadRejectedRole === ROLENAME.SeniorOperationManager && <InfoIcon style={{ cursor: 'pointer' }} fontSize="small" color="secondary" onClick={() => showRejectedStatus(row)} />}
                                                </TableCell> */}
                                                <TableCell component="th">
                                                    {row?.contractCustomerApproved ? 'Approved' : row?.contactLeadRejectedRole === 'Customer' ? 'Rejected' : row?.contractDesignManagerApproved && row?.contractQualityControlApproved ? 'Sent For Approval' : 'Not Approved'}
                                                    {!row?.contractCustomerApproved && row?.contactLeadRejectedRole === 'Customer' && <InfoIcon style={{ cursor: 'pointer' }} fontSize="small" color="secondary" onClick={() => showRejectedStatus(row)} />}
                                                </TableCell>
                                                <TableCell component="th">
                                                    {row?.contractFinanceApproved ? 'Approved' : row?.contactLeadRejectedRole === ROLENAME.FinanceManager ? 'Rejected' : row?.contractCustomerApproved && row?.contractQualityControlApproved && row?.contractDesignManagerApproved ? 'Sent For Approval' : 'Not Approved'}
                                                    {!row?.contractFinanceApproved && row?.contactLeadRejectedRole === ROLENAME.FinanceManager && <InfoIcon style={{ cursor: 'pointer' }} fontSize="small" color="secondary" onClick={() => showRejectedStatus(row)} />}
                                                </TableCell>
                                                {userDetails.roles.find(role => role.name !== ROLENAME.DesignUser) &&
                                                    <TableCell>
                                                        <Button variant="contained" color="primary" size="small" style={{ maxWidth: '100px', maxHeight: '70px', minWidth: '30px', minHeight: '30px' }} onClick={() => navigateToVerifyPage(row._id)}>Verify Documents</Button>
                                                    </TableCell>
                                                }
                                            </TableRow>
                                        );
                                    })
                                    :
                                    <TableRow>
                                        <TableCell colSpan={9} style={{ textAlign: 'center' }}>No Records Found</TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Dialog open={openRejectModal} onClose={() => setOpenRejectModal(false)} aria-labelledby="form-dialog-title" maxWidth='md'>
                        <DialogTitle style={{ fontSize: 20 }}><strong>Rejected Reason</strong></DialogTitle>
                        <Divider />
                        <DialogContent>
                            <div style={{ paddingBottom: 15 }}>
                                <h4>{rejectRemark.contractRejectReason ? rejectRemark.contractRejectReason : `NA`}</h4>
                            </div>
                        </DialogContent>
                    </Dialog>
                </Paper>
            </div>
        </div>
    )
}

export default connect(null, { setLoading, setSnackInfo })(LeadDesignToExecutionView)
