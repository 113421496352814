import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { setLoading, setSnackInfo } from '../../../redux/actions/appAction'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import HttpService from '../../../services/httpService';
// import 'antd/dist/antd.css';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';

const AssignProjects = (props) => {

    useEffect(() => {
        getAllLeadsNames()
        // eslint-disable-next-line
    }, [])

    const [projects, setProjects] = useState([]);
    const [selectedLead, setSelectedLead] = useState({})

    const [assignFormData, setAssignFormData] = useState({
        leadId: '',
        department: '',
        team: '',
        user: '',
        stage: '',
        remark: ''
    })
    const { department, team, user, stage, remark } = assignFormData;

    const getAllLeadsNames = async () => {
        try {
            props.setLoading(true)
            const response = await HttpService.getAllLeadsNames();
            const dataLead = [];
            response.data.forEach((ele) => {
                if (ele.customerId !== null) {
                    let obj = {};
                    obj.custName = ele.customerId.name;
                    obj._id = ele._id;
                    dataLead.push(obj);
                }
            })
            setProjects(dataLead);
            props.setLoading(false)
        } catch (error) {
            props.setLoading(false)
            props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
        }
    }

    const [departmentList, setDepartmentList] = useState([]);
    const [allDepartmentList, setAllDepartmentList] = useState([]);
    const [teamList, setTeamList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [stageList, setStageList] = useState([]);

    const handleSelect = async (event, value) => {
        // props.setLoading(true)
        setSelectedLead(value)
        getDepartmentList()
    }

    const getDepartmentList = async () => {
        try {
            const response = await HttpService.getDepartments();
            console.log(response.data, 'dept list');
            setAllDepartmentList(response.data)
            const finalDeptList = [];
            response.data.forEach((ele) => {
                let obj = {};
                obj.name = ele.name;
                obj._id = ele._id;
                finalDeptList.push(obj);
            })
            setDepartmentList(finalDeptList);
        } catch (err) {
            console.log(err.message);
        }
    }

    const getTeamsList = (e) => {
        console.log(e.target.value, 'selected dept name');
        setAssignFormData({ ...assignFormData, [e.target.name]: e.target.value })
        console.log(allDepartmentList, 'allDepartmentList');
        allDepartmentList.forEach(ele => {
            if (ele._id === e.target.value) {
                setTeamList(ele.teams);
            }
        })
    }

    const getTeamsUserList = async (e) => {
        console.log(e.target.value, 'selected team name');
        setAssignFormData({ ...assignFormData, [e.target.name]: e.target.value })
        try {
            const users = await HttpService.getQTTeamUsers(e.target.value);
            console.log(users)
            setUserList(users.data.users)

        } catch (error) {
            console.log(error, "Error");
        }
    }

    const getDepartmentStageList = (e) => {
        console.log(assignFormData.department, 'assignFormData.department');
        setAssignFormData({ ...assignFormData, [e.target.name]: e.target.value })
        console.log(allDepartmentList, 'allDepartmentList');
        allDepartmentList.forEach(ele => {
            if (ele._id === assignFormData.department) {
                setStageList(ele.stages);
            }
        })
    }

    const assignLead = async () => {
        try {
            let obj = {
                leadId: selectedLead._id,
                stage: assignFormData.stage,
                id: assignFormData.user,
                team: assignFormData.team,
                remarks: assignFormData.remark,
                department: assignFormData.department
            };
            const response = await HttpService.assignProject(obj);
            if (response.data) {
                setSelectedLead({})
                setAssignFormData({
                    leadId: '',
                    department: '',
                    team: '',
                    user: '',
                    stage: '',
                    remark: ''
                })
            }
            props.setSnackInfo(response.data, "success")
        } catch (err) {
            console.log(err, 'err');
        }
    }

    return (
        <div>
            <h3 style={{ fontSize: 18 }}>
                <b> Assign Project </b>
            </h3>

            <Grid container spacing={2}>
                <Grid item sm={4}>
                    <Autocomplete
                        value={selectedLead}
                        onChange={handleSelect}
                        id="lead-code-dropdown"
                        options={projects}
                        getOptionLabel={(option) => option.custName}
                        style={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Select Lead" fullWidth variant="outlined" />}
                    />
                </Grid>

                {
                    selectedLead._id &&
                    <Grid item sm={4}>
                        <FormControl>
                            <TextField
                                select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={department}
                                name="department"
                                label="Departments"
                                onChange={getTeamsList}
                                variant="outlined"
                                style={{ width: 300 }}
                                fullWidth
                            >
                                {
                                    departmentList?.map((data, i) => (
                                        <MenuItem value={data._id} key={i + 1}>{data.name}</MenuItem>
                                    ))
                                }
                            </TextField>
                        </FormControl>
                    </Grid>
                }
                {
                    department &&
                    <Grid item sm={4}>
                        <FormControl>
                            <TextField
                                select
                                labelId="demo-team-select-label"
                                id="demo-team-select"
                                value={team}
                                name="team"
                                label="Team"
                                onChange={getTeamsUserList}
                                variant="outlined"
                                style={{ width: 300 }}
                                fullWidth
                            >
                                {
                                    teamList?.map((data, i) => (
                                        <MenuItem value={data._id} key={i + 1}>{data.name}</MenuItem>
                                    ))
                                }
                            </TextField>
                        </FormControl>
                    </Grid>

                }{
                    team &&
                    <Grid item sm={4}>
                        <FormControl>
                            <TextField
                                select
                                labelId="demo-user-select-label"
                                id="demo-user-select"
                                value={user}
                                name="user"
                                label="Users"
                                onChange={getDepartmentStageList}
                                variant="outlined"
                                style={{ width: 300 }}
                                fullWidth
                            >
                                {
                                    userList?.map((data, i) => (
                                        <MenuItem value={data._id} key={i + 1}>{data.name}</MenuItem>
                                    ))
                                }
                            </TextField>
                        </FormControl>
                    </Grid>
                }
                {
                    user &&
                    <Grid item sm={4}>
                        <FormControl>
                            <TextField
                                select
                                labelId="demo-stage-select-label"
                                id="demo-stage-select"
                                value={stage}
                                name="stage"
                                label="Stage"
                                onChange={(e) => setAssignFormData({ ...assignFormData, [e.target.name]: e.target.value })}
                                variant="outlined"
                                style={{ width: 300 }}
                                fullWidth
                            >
                                {
                                    stageList?.map((data, i) => (
                                        <MenuItem value={data} key={i + 1}>{data}</MenuItem>
                                    ))
                                }
                            </TextField>
                        </FormControl>
                    </Grid>
                }
                {
                    stage &&
                    <Grid item sm={4}>
                        <TextField
                            id="standard-multiline-static"
                            label="Requirements (Optional)"
                            multiline
                            rows={4}
                            placeholder="Enter Requirements"
                            value={remark}
                            name="remark"
                            onChange={(e) => setAssignFormData({ ...assignFormData, [e.target.name]: e.target.value })}
                            variant="outlined"
                            size="small"
                            fullWidth
                        />
                    </Grid>
                }
                {
                    stage &&
                    <Grid item sm={4}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={assignLead}
                        >
                            Assign Lead
                        </Button>
                    </Grid>
                }

            </Grid>
        </div >
    )
}

export default connect(null, { setLoading, setSnackInfo })(AssignProjects);
