import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Modal } from 'antd';
// import 'antd/dist/antd.css';
import { useRef } from 'react';
import { CSVLink } from "react-csv";
import './index.css'
import { TABLEHEADER, SCOPEDPRODUCTSHEADER , EM_TABLEHEADER, ADMINHEADER} from '../../../utils'
import PoSoModal from '../../../components/poSoModal'
import { PlusCircleOutlined, CheckCircleOutlined, StopOutlined } from '@ant-design/icons'
import HttpService from '../../../services/httpService';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { connect, useSelector } from "react-redux";
import { setLoading, setSnackInfo } from '../../../redux/actions/appAction';
import { getApprovedProjects } from '../../../redux/actions/financeAction'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Radio, RadioGroup, FormControlLabel, FormControl, Button, Grid, Switch } from '@material-ui/core';
import RaisePoModal from '../../../components/raisePoModal'
import PoDetailsModal from '../../../components/poDetailsModal'
import SoDetailsModal from '../../../components/soDetailsModal'
import RaiseSoModal from '../../../components/raiseSoModal';
import PoSoMtrForApprovalModal from '../../../components/poSoMtrForApprovalModal'
import RaisePayementRequestModal from '../../../components/raisePaymentRequestModal'
import PaymentRequestModal from '../../../components/paymentRequestModal'
import RaiseOpenPoModal from '../../../components/raiseOpenPOModal';
import MaterialTransferDetailsModal from '../../../components/materialTransferDetaislModal'
import MaterialTransferListView from '../../../components/materialTransferListView'
import FinanceOverviewMultiSelect from '../financeOverviewMultiSelect';
import moment from "moment";
// import RaiseMtrModalView from '../../../components/raisedMtrModalView'
const useStyles = makeStyles((theme)=>({
	table: {
		minWidth: 700,
	},
	multiSelect:{
		width: '100%',
        margin: '1rem 0 1rem 0'
    },
	paper: {
		padding: theme.spacing(2),
		textAlign: "center",
		color: theme.palette.text.secondary,
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: 200,
	},
	root: {
		width: '100%',
	},
}));

const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: "#ccc",
		color: theme.palette.common.black,
	},
	body: {
		fontSize: 14,
	},
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
	root: {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover,
		},
	},
}))(TableRow);

const Index = (props) => {

	const classes = useStyles();
	const { approvedProjects } = useSelector(state => state.finance)
	const { userDetails } = useSelector(state => state.user)
	const [projectStatus,setProjectStatus] = useState(false)
	const [projState, setProjState] = useState("")
    const [executionData,setExecutionData] = useState([])
	const buttonRef = useRef(null)
	const [startDate, setStartDate] = useState(moment().subtract(1, "months").format("YYYY-MM-DD"));
  	const [endDate, setEndDate] = useState(moment().add(1, "days").format("YYYY-MM-DD"));
	useEffect(() => {
		getApprovedProjects()
		// eslint-disable-next-line
		getSoNotApproveData()
	}, [])

	const getApprovedProjects = async () => {
		try {
			props.setLoading(true)
			await props.getApprovedProjects()
			props.setLoading(false)
		} catch (error) {
			console.error(error)
			props.setLoading(false)
			props.setSnackInfo(error.response.data.message, "error")
		}
	}
	const [selectedProject, setSelectedProject] = useState({})
	const [selectedProjectsScopedData, setSelectedProjectsScopedData] = useState([])
	const [soNotApproveData, setSoNotApproveData] =useState([]);
	const [projectInfo, setProjectInfo] = useState({
		clientName: "",
		projectCode: ""
	})
	const [totalValues, setTotalValues] = useState({
		totalPrice: 0,
		totalPo: 0,
		totalSo: 0,
		totalOverall: 0
	})
	const [state,setState] = useState(true)
	const handleSelect = async (event, value) => {
		props.setLoading(true)
		setProjectStatus(true)
		try {
			if (value) {
				setSelectedProject(value)
				getProjectsScopedData(value._id)
			} else {
				setSelectedProject({})
			}
		} catch (error) {
			console.error(error)
			props.setSnackInfo('Couldn\'t fetch the data', 'error')
		}
		props.setLoading(false)
	}

	const getProjectsScopedData = async (projectId) => {
		try {
			setState(true)
			const response = await HttpService.getProjectsScopedData(projectId)
			if (response.data?.leadDetails?.currentStage === "Completed"){
				setState(false)
			}
			setProjState(response.data?.leadDetails?.currentStage)
			setSelectedProjectsScopedData(response.data.result)
			setProjectInfo({ ...projectInfo, clientName: response.data.clientName, projectCode: response.data.code })
			let totalPrice = response.data.result.length > 0 ? response.data.result.reduce((acc, crr) => ({ totalDecorpotPrice: acc.totalDecorpotPrice + crr.totalDecorpotPrice })) : 0
			let totalPo = response.data.result.length > 0 ? response.data.result?.reduce((acc, crr) => ({ poRequested: acc.poRequested + crr.poRequested })) : 0
			let totalSo = response.data.result.length > 0 ? response.data.result?.reduce((acc, crr) => ({ soRequested: acc.soRequested + crr.soRequested })) : 0
			let totalOverall = +totalPo.poRequested + +totalSo.soRequested
			setTotalValues({ ...totalValues, totalPrice: totalPrice.totalDecorpotPrice, totalPo: totalPo.poRequested, totalSo: totalSo.soRequested, totalOverall })
		} catch (error) {
			console.log(error)
			props.setSnackInfo(error?.response?.data?.message ?? "Error while fetching project scope data", "error")
		}
	}

	const { totalPrice, totalPo, totalSo, totalOverall } = totalValues

	const [scopeModal, setScopeModal] = useState(false)
	const [scopedProducts, setScopedProducts] = useState([])
	const [scope, setScope] = useState("")

	const openScopeModal = async (scope) => {
		try {
			props.setLoading(true)
			setScope(scope)
			const response = await HttpService.getProjectScopedProducts(selectedProject._id, scope)
			setScopedProducts(response.data)
			setScopeModal(true)
			props.setLoading(false)
		} catch (error) {
			console.error(error)
			props.setLoading(false)
			props.setSnackInfo("Couldn't fetch product details", "error")
		}
	}

	const [poModal, setPoModal] = useState(false)
	const [poSoRequested, setPoSoRequested] = useState([])
	const [materialTransferList, setMaterialTransferList] = useState([])

	const openPoRaisedModal = async (scope, title) => {
		props.setLoading(true)
		try {
			setScope(scope)
			setTitle(title)
			getApprovedPo(scope)
			const result = await HttpService.getMaterailTransferRequestsByScopeForProject(selectedProject._id, scope)
			setMaterialTransferList(result.data)
			setPoModal(true)
		} catch (error) {
			console.error(error)
			props.setSnackInfo('Couldn\'t get raised po details', 'error')
		}
		props.setLoading(false)
	}

	const getApprovedPo = async (scope) => {
		try {
			const response = await HttpService.getProcurementsOfProjectByScope(selectedProject._id, scope)
			const poSoRequested = response.data.map(procurement => {
				let obj = {
					orderId: procurement?.orderId,
					status: procurement?.status,
					vendor: procurement?.vendorId?.name,
					value: procurement?.price,
					createdAt: procurement?.createdAt,
					link: procurement?.poLink,
					maskedLink: procurement?.maskedPoLink,
					id: procurement?._id,
					paymentRequests: procurement?.paymentRequests,
					createdBy: procurement?.createdBy?._id
				}
				return obj
			})
			setPoSoRequested(poSoRequested)
		} catch (error) {
			console.error(error)
			props.setSnackInfo(error?.response?.data?.message ?? "Error while fetching Apporoved PO", "error")
		}
	}

	const [poDetailsModal, setPoDetailsModal] = useState(false)
	const [poDetails, setPoDetails] = useState({})

	const openPoDetailsModal = async (procurementId, title) => {
		try {
			props.setLoading(true)
			setPoModal(false)
			setTitle(title)
			setPoSoForApprovalModal(false)
			const response = await HttpService.getProcurementDetails(procurementId)
			setPoDetails(response.data)
			setPoDetailsModal(true)
			props.setLoading(false)
		} catch (error) {
			console.error(error)
			props.setLoading(false)
			props.setSnackInfo('Couldn\'t get raised po details', 'error')
		}
	}

	const viewAllPO = (title) => {
		setTitle(title)
		setPoDetailsModal(false)
		setPoModal(true)
	}

	const [soModal, setSoModal] = useState(false)

	const openSoRaisedModal = async (scope) => {
		props.setLoading(true)
		try {
			setScope(scope)
			getApprovedSo(scope)
			setSoModal(true)
		} catch (error) {
			console.error(error)
			props.setSnackInfo('Couldn\'t get raised so details', 'error')
		}
		props.setLoading(false)
	}

	const getApprovedSo = async (scope) => {
		try {
			const response = await HttpService.getServiceOrderOfProjectScope(selectedProject._id, scope)
			const poSoRequested = response.data.map(serviceOrder => {
				let obj = {
					orderId: serviceOrder?.orderId,
					vendor: serviceOrder?.vendorId?.name,
					value: serviceOrder?.total,
					createdAt: serviceOrder?.createdAt,
					status: serviceOrder?.status,
					link: serviceOrder?.soLink,
					maskedLink: serviceOrder?.maskedSoLink,
					id: serviceOrder?.orderId,
					_id: serviceOrder?._id,
					paymentRequests: serviceOrder?.paymentRequests,
					createdBy: serviceOrder?.createdBy?._id
				}
				return obj
			})
			setPoSoRequested(poSoRequested)
		} catch (error) {
			console.error(error)
			props.setSnackInfo(error?.response?.data?.message ?? "Error while fetching Approved SO", "error")
		}
	}
	const [soDetailsModal, setSoDetailsModal] = useState(false)
	const [soDetails, setSoDetails] = useState({})
	const [title, setTitle] = useState("SO Raised/Requested Details")
	const [mount, setMounted] = useState(false)

	const openSoDetailsModal = async (serviceOrderId, title) => {
		try {
			props.setLoading(true)
			setSoModal(false)
			setTitle(title)
			setPoSoForApprovalModal(false)
			const response = await HttpService.getServiceOrderDetails(serviceOrderId)
			setSoDetails(response.data)
			setSoDetailsModal(true)
			props.setLoading(false)
		} catch (error) {
			console.error(error)
			props.setLoading(false)
			props.setSnackInfo('Couldn\'t get raised po details', 'error')
		}
	}

	const [mtrDetailsModal, setMtrDetailsModal] = useState(false)
	const [mtrDetails, setMtrDetails] = useState({})

	const openMtrDetail = async (mtrId, title) => {
		try {
			props.setLoading(true)
			setPoModal(false)
			setTitle(title)
			setPoSoForApprovalModal(false)
			const response = await HttpService.materialTransferDetails(mtrId)
			setMtrDetailsModal(true)
			setMtrDetails(response.data)
			props.setLoading(false)
		} catch (error) {
			console.error(error)
			props.setLoading(false)
			props.setSnackInfo('Couldn\'t get raised po details', 'error')
		}
	}

	const viewAllSO = () => {
		setSoDetailsModal(false)
		setSoModal(true)
	}
	const viewAllMtr = (title) => {
		setTitle(title)
		setMtrDetailsModal(false)
		setPoModal(true)
	}
	const [raisePoSoModal, setRaisePoSoModal] = useState(false)
	const [radioValue, setRadioValue] = useState("PO")

	const handleRadioChange = (e) => {
		setRadioValue(e.target.value)
	}

	const openPoSoRaiseModal = async (scope) => {
		props.setLoading(true)
		try {
			setScope(scope)
			setMounted(!mount)
			setRaisePoSoModal(true)
		} catch (error) {
			console.error(error)
			props.setSnackInfo('Couldn\'t fetch details', 'error')
		}
		props.setLoading(false)
	}

	const [poSoForApprovalModal, setPoSoForApprovalModal] = useState(false)
	const [raisePaymentRequest, setRaisePaymentRequest] = useState(false)
	const [paymentRequestModal, setPaymentRequestModal] = useState(false)

	const openPoSoForApproval = async (scope) => {
		setScope(scope)
		setMounted(!mount)
		setPoSoForApprovalModal(true)
	}

	const openRaisePaymentRequestModal = (scope) => {
		// if (projState === 'Completed') {
		// 	// Project is completed, do not open the modal
		// 	return;
  		// }

		setScope(scope)
		setMounted(!mount)
		setRaisePaymentRequest(true)
	}

	const openPaymentRequestModal = (scope) => {
		setScope(scope)
		setMounted(!mount)
		setPaymentRequestModal(true)
	}
	const viewPendingSO = () => {
		setSoDetailsModal(false)
		setMounted(!mount)
		setPoSoForApprovalModal(true)
	}

	const viewPendingMtr = () => {
		setMtrDetailsModal(false)
		setMounted(!mount)
		setPoSoForApprovalModal(true)
	}

	const refreshSO = (id) => {
		setMounted(!mount)
		openSoDetailsModal(id, "Approve SO")
	}

	const viewPendingPO = () => {
		setPoDetailsModal(false)
		setMounted(!mount)
		setPoSoForApprovalModal(true)
	}
	const refreshPO = (id) => {
		setMounted(!mount)
		openPoDetailsModal(id, "Approve PO")
	}

	const [raiseOpenPOModal, setRaiseOpenPoModal] = useState(false)

	const openPORaiseModal = async () => {
		try {
			props.setLoading(true)
			setRaiseOpenPoModal(true);
			props.setLoading(false);
		} catch (error) {
			console.error(error)
			props.setLoading(false)
			props.setSnackInfo('Couldn\'t fetch details', 'error')
		}
	}

	const closePoSoModal = async () => {
		setMounted(!mount)
		setRaisePoSoModal(false);
		handleSelect(null, selectedProject)
	}

	const DeletePO = async (id) => {
		props.setLoading(true)
		try {
			await HttpService.deleteProcurementById(id)
			props.setSnackInfo('PO Deleted', 'success')
			getApprovedPo(scope)
			getProjectsScopedData(selectedProject._id)
		} catch (error) {
			console.error(error)
			props.setSnackInfo(error?.response?.data?.message ?? "Error while deleting", 'error')
		}
		props.setLoading(false)
	}

	const DeleteSO = async (id) => {
		props.setLoading(true)
		try {
			await HttpService.deleteSoByOrderId(id)
			props.setSnackInfo("SO Deleted", "success")
			getApprovedSo(scope)
			getProjectsScopedData(selectedProject._id)
		} catch (error) {
			console.error(error)
			props.setSnackInfo(error?.response?.data?.message ?? "Error while deleting", 'error')
		}
		props.setLoading(false)
	}

	//Download Excel file for Execution Manager of Company P&L Overview data
	const executionDataDownload = () => {
		let info = [...selectedProjectsScopedData];
		let allData = [];
	  
		info.forEach((el, i) => {
		  let obj = {
			sn: i + 1,
			scope: el.type,
			poReq: el.poRequested,
			soReq: el.soRequested,
		  };
	  
		  if (!userDetails?.roles?.some(role => role.name === "Execution Manager")) {
			obj.unit = el.unit;
			obj.area = el.area;
		  }
	  
		  allData.push(obj);
		});
	  
		setExecutionData(allData);
	  };

	let executionHeader = [
		{ key: "sn", label: "Sr. No" },
		{ key: "scope", label: "Scope" },
		...(userDetails?.roles?.find(role => role.name !== "Execution Manager")
		  ? [
			  { key: "unit", label: "Unit" },
			  { key: "area", label: "Qty/Area" }
			]
		  : []),
		{ key: "poReq", label: "Po Requested" },
		{ key: "soReq", label: "Scope Requested" },
	  ]
	const downloadCsv = () => {
        try {
            setTimeout(() => {
                buttonRef.current.link.click();
            });
        } catch (err) {
            console.error(err.message);
        }
        props.setLoading(false)
    }

	const handleDownloadExcelClick = async () => {
		let overview_data = [...selectedProjectsScopedData]
		const response = await HttpService.getProjectExcelFile(selectedProject, {overview_data: overview_data})
		console.log("project_excel_file_link", response.data.path)
		window.open(response.data.path);
	}
	const [projects,setProjects] = useState([]);
	const handleChange = (event,values) => {
		setProjects(values);
	}
	const allProjectsExcelDownloadHandler = async () =>{
		props.setLoading(true);
		try {
			const response = await HttpService.getProjectsExcelFile(projects);
			// window.open(response.data.path);
			window.location.href = response.data.path;		
		} catch (error) {
			console.log(error);
		}
		props.setLoading(false);
	}

	//handleDownloadSoApproval handle click
	
	const getSoNotApproveData = async () => {
		try {
            const result = await HttpService.getServiceOrderOfProjectScope()
            let allData=[]
			let x = 1;
            result.data.forEach((el, i)=>{
                let obj={
                projectCode : el?.projectId?.code,
                client : el?.projectId?.clientName,
                orderId : el?.orderId,
                status : el?.status,
                vendor : el?.vendorId?.name,
                value : el?.total,
                createdAt : el?.createdAt,
                }
                if(obj?.status === 'Sent for Approval') {
					obj.srNo = x;
					x++;
					allData.push(obj)
				}
            })
			setSoNotApproveData(allData)
		} catch (error) {
		  console.error(error);
		  // Handle any error that occurs during the process
		}
	  };

	  const handleCloseModal = () =>{
        setRaisePaymentRequest(false)
        setPaymentRequestModal(false)
        getApprovedProjects()
        // eslint-disable-next-line
        getSoNotApproveData()
    }

	const headers = [
        { key: "srNo", label: "Sr. No" },
        { key: "projectCode", label: "Project Code" },
        { key: "client", label: "Client" },
        { key: "orderId", label : "OrderId"},
        { key: "status", label : "Status"},
        { key: "vendor", label: "Vendor" },
        { key: "value", label: "Value" },
        { key: "createdAt", label: "CreatedAt"},
       
    ];

	const DownloadCsv = () => {
        try {
            setTimeout(() => {
                buttonRef.current.link.click();
            });
        } catch (err) {
            console.error(err.message);
        }
        props.setLoading(false)
    }

	const [multiCheck, setMulticheck] = useState(false)
    const handleMultiCheck = ()=>{
        setMulticheck(!multiCheck); 
    }

	const dateFilter = async () =>{
		props.setLoading(true);

		try{
		 let nextDay = ''
		 if(endDate!=''){
			nextDay = new Date(endDate)
			nextDay.setDate(nextDay.getDate()+1)
		 }

		 let completedAndDateFilteredProjects = []

		if(startDate=='' || endDate==''){
			completedAndDateFilteredProjects = approvedProjects.filter((el)=>{
					return (
						el.leadId &&
						el.leadId.currentStage === "Completed"
				)
			})
		}
		else{
			completedAndDateFilteredProjects = approvedProjects.filter((el)=>{
					return (
						el.leadId &&
			el.leadId.currentStage === "Completed" &&
			el.leadId.executionCompletionDate 
			&&
			new Date(el.leadId.executionCompletionDate) >= new Date(startDate) &&
			new Date(el.leadId.executionCompletionDate) < nextDay
				)
		})
	}

	// console.log('completedAndDateFilteredProjects are ',completedAndDateFilteredProjects)

	const dateObj = {
		startDate:startDate,
		endDate:nextDay
	}
	if(nextDay!== ''){
		dateObj.endDate = nextDay.toLocaleDateString('en-CA')
	}

	const response = await HttpService.getProjectsExcelFile(completedAndDateFilteredProjects,dateObj);
	window.location.href = response.data.path;

}
	catch(error){
		console.log(error)
		}
	props.setLoading(false);
	}

	const clearFilterDate = async () => {
		setStartDate("");
		setEndDate("");
	}

	return (
		<>
		<div>

			<Paper
            className={classes.paper}
            style={{ marginBottom: 20, marginTop: 20 }}
          >
            <h3>Completion Date Filter</h3>
            <Grid container>
              <Grid item md={3} xs={12}>
                <TextField
                  id="date"
                  name="startDate"
                  label="From Date"
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  className={classes.textField}
                />
              </Grid>
              <Grid item md={1} xs={12}></Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  id="date"
                  name="endDate"
                  label="End Date"
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  className={classes.textField}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <Button
                  variant="contained"
                  color="primary"
				  style={{ background: "#24803d"}}
                  onClick={() => {
                    dateFilter();
                  }}
                >
                  Download CSV
                </Button>
              </Grid>
              <Grid item md={2} xs={12}>
                <Button variant="contained" color="secondary" onClick={clearFilterDate}>
                  Clear Filter
                </Button>
              </Grid>
            </Grid>
          </Paper>
			</div>
        {/* {userDetails?.roles?.find(role => role.name !== "Execution Manager")  &&
			<Paper style={{marginBottom : 10}}>
				<Grid container style={{ display: "flex", justifyContent: "space-between",alignItems:"center" ,padding: 10}}>
					<Autocomplete
						multiple
						onChange={(e, v) => {
							handleChange(e,v)
						}}
						id="projects-dropdown"
						options={approvedProjects}
						getOptionLabel={(option) => option.searchName}
						style={{ width: 600 ,margin: 10 }}
						filterSelectedOptions
						renderInput={(params) => (
							<TextField
								{...params}
								variant="outlined"
								label="Select To Download Multiple Projects"
								placeholder="Select To Download Multiple Projects"
							/>
						)}
					/>
    	                {Object.keys(projects).length > 1 && <Button Button variant="contained" color="primary" style={{ background: "#24803d", margin: 5, width: 200, height: 50 }} onClick={allProjectsExcelDownloadHandler}>
						Download Excel (Multiple)
					</Button>}
				</Grid>
			</Paper>
} */}
<FormControlLabel
                control={
                    <Switch
                        checked={multiCheck}
                        onChange={handleMultiCheck}
                        name="multiCheck"
                        color="primary"
                    />
                }
                label='Select Multiple Projects'
                className={classes.multiSelect}
            />
            { !multiCheck ? <div>
			<div style={{ display: "flex", justifyContent: "space-between", paddingBottom: 20 }}>
				<Autocomplete
					value={selectedProject}
					onChange={handleSelect}
					id="project-code-dropdown"
					options={approvedProjects}
					getOptionLabel={(option) => option.searchName}
					style={{ width: 500 }}
					renderInput={(params) => <TextField {...params} label="Search Project" fullWidth variant="outlined" />}
				/>
				<div>
				{/* <h2>{show ? ' ' + (projectStatus ? ('Project Status' +" :" +" " + projectStatus) : '') : ''}</h2> */}
				{/* <h2>{projectStatus  ? ' ' + (projState ? ('Project Status' +" :" +" "+ projState) :'') : ''}</h2> */}
				<h2>{projectStatus && projState && (
					<>Project Status: {projState}</>
					)}
				</h2>

				</div>
                {(Object.keys(selectedProject)).length !== 0 ?
				 <>
				 {
					 userDetails?.roles?.find(role => 
						role.name === "Admin"|| 
						role.name === "Finance Manager" || 
						role.name === "Operation Head" ||
						role.name === "Center Head"
					) ?
					<>
				<Button  variant="contained" color="primary" style={{ background: "#24803d", margin: 5, width: 200, height: 50 }} onClick={handleDownloadExcelClick}>
				Download Excel
				</Button>
					</>
					:
					<>
					{
						userDetails?.roles?.find(role => 
							role.name !== "Admin" &&
							role.name !== "Center Head" &&
							role.name !== "Finance Manager" &&
							role.name !== "Operation Head"
							) ?
							<>
							 <CSVLink
                    headers={executionHeader}
                    filename={"CompanyP&L" + '.csv'}
                    data={executionData}
                    ref={buttonRef}
                    />
					<Button variant="contained" onClick={() => { downloadCsv(); executionDataDownload(); }} style={{ backgroundColor: "green", color: "white" }}>download csv</Button>
							</>
							:""
					}
					</>
				 }
				 </>
				:"" 
				}

			{/* Add button for download excel file where all project which is not approve - priti*/}
				{
					<>
					 <CSVLink
					 headers={headers}
					 filename={"SoNotApprovalDataSheet" + '.csv'}
					 data={soNotApproveData}
					 ref={buttonRef}
					 />
					 {userDetails?.roles?.find(role => role?.name !== "Execution Manager" || role?.name === "Admin" || role?.name === "Finance Manager" || role?.name === "Center Head" || role?.name === "Operation Head" ) && 
					<Button Button variant="contained" color="primary" style={{ background: "#24803d", margin: 5, width: 200, height: 50 }} onClick={()=>DownloadCsv()}>
						Download SO Not Approval 
					</Button> }
				</>}
				
				{!selectedProject?._id && userDetails.roles.find(role => role.name === "Admin" || role.name === "Procurement Executive") && <Button Button variant="contained" color="primary" style={{ background: "#716ACA", margin: 5 }} onClick={openPORaiseModal}>
					Create Open PO
				</Button>}
			</div>

			{
				selectedProject?._id && selectedProjectsScopedData.length > 0 && <div>
					<TableContainer component={Paper}>
						<Table className={classes.table} size="small" aria-label="customized table">
							<TableHead>
                           
							{userDetails?.roles?.find(role => 
							 role.name !== "Execution Manager" && 
							 role.name === "Admin"|| 
							 role.name === "Finance Manager" ||
							 role.name === "Operation Head" ||
							 role.name === "Center Head") ? (
								<TableRow>
									{ADMINHEADER.map(header => <StyledTableCell>{header}</StyledTableCell>)}
									{userDetails.roles.find(role => role.name === "Admin" || role.name === "Procurement Executive" || role.name === "Store Manager" || role.name === "Production Manager") && <StyledTableCell>Raise PO/SO/MTR</StyledTableCell>}
									{userDetails.roles.find(role => role.name === "Operation Head" || role.name === "Admin") && <StyledTableCell>Approve PO/SO/MTR</StyledTableCell>}
									{userDetails.roles.find(role => role.name === "Admin" || role.name === "Finance Manager" || role.name === "Finance Executive") && <StyledTableCell>Raise Payment</StyledTableCell>}
									{/* {userDetails.roles.find(role => role.name === "Admin" || role.name === "Finance Manager") && <StyledTableCell></StyledTableCell>} */}
									{userDetails.roles.find(role => role.name === "Admin" || role.name === "Finance Manager") && <StyledTableCell>Approve Payment</StyledTableCell>}
								</TableRow>
								) : userDetails?.roles?.find(role =>
									role.name !== "Execution Manager" && 
									role.name !== "Admin"&& 
									role.name !== "Finance Manager" && 
									role.name !== "Operation Head" && 
									role.name !== "Center Head"
									) ? (
								<TableRow>
									{TABLEHEADER.map(header => <StyledTableCell>{header}</StyledTableCell>)}
									{userDetails.roles.find(role => role.name === "Admin" || role.name === "Procurement Executive" || role.name === "Store Manager" || role.name === "Production Manager" || role.name === "Vendor Executive" || role.name ==="Vendor Manager") && <StyledTableCell>Raise PO/SO/MTR</StyledTableCell>}
									{userDetails.roles.find(role => role.name === "Operation Head" || role.name === "Admin") && <StyledTableCell>Approve PO/SO/MTR</StyledTableCell>}
									{userDetails.roles.find(role => role.name === "Admin" || role.name === "Finance Manager" || role.name === "Finance Executive" || role.name === "Vendor Executive" || role.name === "Vendor Manager") && <StyledTableCell>Raise Payment</StyledTableCell>}
									{/* {userDetails.roles.find(role => role.name === "Admin" || role.name === "Finance Manager") && <StyledTableCell></StyledTableCell>} */}
									{userDetails.roles.find(role => role.name === "Admin" || role.name === "Finance Manager") && <StyledTableCell>Approve Payment</StyledTableCell>}
								</TableRow>
								) : (
								<TableRow>
									{EM_TABLEHEADER.map(header => <StyledTableCell>{header}</StyledTableCell>)}
								</TableRow>
								)}
							</TableHead>
							<TableBody>
								{selectedProjectsScopedData.map((data, i) => (
									<StyledTableRow key={i + 1}>
										<StyledTableCell>{i + 1}</StyledTableCell>
										<StyledTableCell onClick={() => openScopeModal(data?.type)} style={{ cursor: 'pointer', color: "blue", textDecoration: 'underline' }}>{data?.type}</StyledTableCell>
                                        {userDetails?.roles?.find(role => role.name !== "Execution Manager") && <><StyledTableCell>{data?.unit}</StyledTableCell>
										<StyledTableCell>{data?.area !== 0 ? `${data?.area} (Area)` : `${data?.quantity} (Qty)`}</StyledTableCell>
										{userDetails?.roles?.find(role =>
										role.name === "Admin"||
										role.name === "Finance Manager" || 
										role.name === "Operation Head" || 
										role.name === "Center Head") &&
                                        <StyledTableCell>{data?.totalDecorpotPrice?.toFixed(2)}</StyledTableCell>
                                        }
                                        </>}
										<StyledTableCell onClick={() => openPoRaisedModal(data?.type, "Po/Mtr Raised/Requested")} style={{ cursor: 'pointer', color: "blue", textDecoration: 'underline' }}>{data?.poRequested?.toFixed(2)}</StyledTableCell>
										<StyledTableCell onClick={() => openSoRaisedModal(data?.type)} style={{ cursor: 'pointer', color: "blue", textDecoration: 'underline' }}>{data?.soRequested?.toFixed(2)}</StyledTableCell>
										{userDetails?.roles?.find(role => role.name !== "Execution Manager" ) && <>
                                        {userDetails?.roles?.find(role => 
                                            role.name === "Admin"|| 
                                            role.name === "Finance Manager" || 
                                            role.name === "Operation Head" ||
                                            role.name === "Center Head"
                                       ) && <>
                                    
                                        <StyledTableCell>{(data?.poRequested + data.soRequested).toFixed(2)}</StyledTableCell>
										<StyledTableCell>{data.type !== "Addn Disc-Penality" ? (data?.totalDecorpotPrice ? ((data?.totalDecorpotPrice - (data?.poRequested + data?.soRequested)) / data?.totalDecorpotPrice).toFixed(2) * 100 : 0):0}</StyledTableCell></>}
										{(userDetails.roles.find(role => role.name === "Admin" || role.name === "Procurement Executive" || role.name === "Store Manager" || role.name === "Production Manager" || role.name === "Vendor Executive" || role.name === "Vendor Manager") && state) && <StyledTableCell onClick={() => openPoSoRaiseModal(data?.type)} style={{ cursor: 'pointer', color: "blue", display: data?.type === 'Addn Disc-Penality' ? 'none' :  null }}>{<PlusCircleOutlined />}</StyledTableCell>}
										{(userDetails.roles.find(role => role.name === "Admin" || role.name === "Procurement Executive" || role.name === "Store Manager" || role.name === "Production Manager") && !state)&& <StyledTableCell style={{ cursor: 'pointer', color: "blue" , visibility: data?.type === 'Addn Disc-Penality' ? 'hidden' :  "visible"}}>{<StopOutlined />}</StyledTableCell>}
										{userDetails.roles.find(role => role.name === "Admin" || role.name === "Operation Head") && <StyledTableCell onClick={() => openPoSoForApproval(data?.type)} style={{ cursor: 'pointer', color: "blue", visibility: data?.type === 'Addn Disc-Penality' ? 'hidden' :  "visible" }}>{<CheckCircleOutlined />}</StyledTableCell>}
										{userDetails.roles.find(role => role.name === "Admin" || role.name === "Finance Manager" || role.name === "Finance Executive" || role.name === "Vendor Executive" || role.name === "Vendor Manager") && <StyledTableCell onClick={() => openRaisePaymentRequestModal(data?.type)} style={{ cursor: 'pointer', color: "blue" , visibility: data?.type === 'Addn Disc-Penality' ? 'hidden' :  'visible'}}>{<PlusCircleOutlined />}</StyledTableCell>}
										{/* {userDetails.roles.find(role => role.name === "Admin" || role.name === "Finance Manager" || role.name === "Finance Executive" || role.name === "Vendor Executive" || role.name === "Vendor Manager" ) && <StyledTableCell onClick={() => openRaisePaymentRequestModal(data?.type)} style={{ cursor: 'pointer', color: "blue" }}>{projState === 'Completed' ? <StopOutlined /> : <PlusCircleOutlined /> }</StyledTableCell>} */}
										{userDetails.roles.find(role => role.name === "Admin" || role.name === "Finance Manager") && <StyledTableCell onClick={() => openPaymentRequestModal(data?.type)} style={{ cursor: 'pointer', color: "blue", visibility: data?.type === 'Addn Disc-Penality' ? 'hidden' :  'visible' }}>{<CheckCircleOutlined />}</StyledTableCell>}
                                        </>}
									</StyledTableRow>
								))}
								<StyledTableRow key={'total'}>
									<StyledTableCell>Total</StyledTableCell>
									<StyledTableCell></StyledTableCell>
									{userDetails?.roles?.find(role => role.name !== "Execution Manager") && <>
									<StyledTableCell></StyledTableCell>
									<StyledTableCell></StyledTableCell>
                                    {userDetails?.roles?.find(role => 
										role.name === "Admin"||
										role.name === "Finance Manager" || 
										role.name === "Operation Head" ||
										role.name === "Center Head") &&
									<StyledTableCell>{totalPrice.toFixed(2)}</StyledTableCell>}</>}
									<StyledTableCell>{totalPo.toFixed(2)}</StyledTableCell>
									<StyledTableCell>{totalSo.toFixed(2)}</StyledTableCell>
									{userDetails?.roles?.find(role => role.name !== "Execution Manager" &&
                                    role.name === "Admin"|| 
									role.name === "Finance Manager" || 
									role.name === "Operation Head" ||
									role.name === "Center Head"
                                    ) && <>
									<StyledTableCell>{totalOverall.toFixed(2)}</StyledTableCell>
									<StyledTableCell>{totalPrice ? (((totalPrice - totalOverall) / totalPrice) * 100).toFixed(2) : 0}%</StyledTableCell></>}
								</StyledTableRow>
							</TableBody>
						</Table>
					</TableContainer>
				</div>
			}

			{/* =SCOPE MATERIALS MODAL */}
			<Modal
				title="Scope Materials"
				centered
				visible={scopeModal}
				onCancel={() => setScopeModal(false)}
				width={1000}
				footer={null}
			>
				<div>
					<div className='modal-header'>
						<div>
							<b>Client Name: </b>
							<span>{projectInfo?.clientName}</span>
						</div>
						<div>
							<b>Scope: </b>
							<span>{scope}</span>
						</div>
					</div>
					{scopedProducts.length > 0 && <div>
						<TableContainer component={Paper}>
							<Table className={classes.table} size="small" aria-label="customized table">
								<TableHead>
									<TableRow>
										{SCOPEDPRODUCTSHEADER.map(header => <StyledTableCell>{header}</StyledTableCell>)}
									</TableRow>
								</TableHead>
								<TableBody>
									{scopedProducts.map((data, i) => (
										<StyledTableRow key={i + 1}>
											<StyledTableCell>{i + 1}</StyledTableCell>
											<StyledTableCell>{data?.productId?.name}</StyledTableCell>
											<StyledTableCell>{(data?.area !== 0 && data?.area !== undefined) ? `${data?.area} (Area)` : `${data?.quantity} (Qty)`}</StyledTableCell>
											<StyledTableCell>{data?.pricePerUnit}</StyledTableCell>
											<StyledTableCell>{data?.totalPrice}</StyledTableCell>
										</StyledTableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</div>
					}
				</div>
			</Modal>

			{/* =RAISED PO MODAL */}
			<Modal
				title={title}
				centered
				visible={poModal}
				onCancel={() => setPoModal(false)}
				width={1000}
				maskClosable={false}
				footer={null}
			>
				<FormControl component="fieldset" className={classes.root}>
					<RadioGroup aria-label="type" name="type" value={radioValue} onChange={handleRadioChange}>
						<div className='modal-header-radio'>
							<FormControlLabel value="PO" control={<Radio />} label="PO" />
							<FormControlLabel value="MTR" control={<Radio />} label="MTR" />
						</div>
					</RadioGroup>
				</FormControl>
				{radioValue === "PO" ? <PoSoModal SETLOADING={props.setLoading} SNACKINFO={props.setSnackInfo}  STATECHANGER={setPoModal} REQUESTEDDATA={poSoRequested} PROJECTINFO={projectInfo} TITLE={title} RADIOVALUE={radioValue} SCOPE={scope} OPENDETAILSMODAL={openPoDetailsModal} DELETEREQUEST={DeletePO} /> : <MaterialTransferListView MATERIALTRANSFERLIST={materialTransferList} OPENDETAILSMODAL={openMtrDetail} TITLE={title} />}
			</Modal>

			{/* =PO DETAILS MODAL*/}
			<Modal
				title={title}
				centered
				visible={poDetailsModal}
				onCancel={() => setPoDetailsModal(false)}
				width={1000}
				maskClosable={false}
				footer={null}
			>
				<PoDetailsModal PODETAILS={poDetails} REFRESH={refreshPO} VIEWALL={title === "Approve PO" ? viewPendingPO : viewAllPO} TITLE={title} SELECTEDPROJECT={selectedProject} />
			</Modal>

			{/* =RAISED SO MODAL */}
			<Modal
				title="SO Raised/Requested"
				centered
				visible={soModal}
				onCancel={() => setSoModal(false)}
				width={1000}
				maskClosable={false}
				footer={null}
			>
				<PoSoModal SETLOADING={props.setLoading} SNACKINFO={props.setSnackInfo}  STATECHANGER={setSoModal} REQUESTEDDATA={poSoRequested} PROJECTINFO={projectInfo} DELETEREQUEST={DeleteSO} SCOPE={scope} OPENDETAILSMODAL={openSoDetailsModal} />
			</Modal>

			{/* =SO DETAILS MODAL*/}
			<Modal
				title={title}
				centered
				visible={soDetailsModal}
				onCancel={() => setSoDetailsModal(false)}
				width={1000}
				maskClosable={false}
				footer={null}
			>
				<SoDetailsModal SODETAILS={soDetails} PROJECTINFO={projectInfo} REFRESH={refreshSO} VIEWALL={title === "Approve SO" ? viewPendingSO : viewAllSO} TITLE={title} />
			</Modal>
			{/*  */}

			<Modal
				title={title}
				centered
				visible={mtrDetailsModal}
				onCancel={() => setMtrDetailsModal(false)}
				width={1000}
				maskClosable={false}
				footer={null}
			>
				<MaterialTransferDetailsModal MOUNTED={mount} MTRDETAILS={mtrDetails} REFRESH={openMtrDetail} VIEWALL={title === "Approve MTR" ? viewPendingMtr : viewAllMtr} TITLE={title} />
			</Modal>
			<Modal
				title="Raise Po/So/Mtr"
				centered
				visible={raisePoSoModal}
				onCancel={() => setRaisePoSoModal(false)}
				width={1000}
				maskClosable={false}
				footer={null}
			>
				<FormControl component="fieldset" className={classes.root}>
					<RadioGroup aria-label="type" name="type" value={radioValue} onChange={handleRadioChange}>
						<div className='modal-header-radio'>
							<FormControlLabel value="PO" control={<Radio />} label="PO" />
							{scope === 'Services' && <FormControlLabel value="SO" control={<Radio />} label="SO" />}
							<FormControlLabel value="MTR" control={<Radio />} label="MTR" />
						</div>
					</RadioGroup>
				</FormControl>
				{radioValue === "PO" && <RaisePoModal TYPE="PO" PROJECTINFO={projectInfo} SCOPE={scope} SELECTEDPROJECT={selectedProject} CLOSEMODAL={closePoSoModal} />}
				{radioValue === "SO" && <RaiseSoModal TYPE="SO" PROJECTINFO={projectInfo} SELECTEDPROJECT={selectedProject} SCOPE={scope} CLOSEMODAL={closePoSoModal} />}
				{radioValue === "MTR" && <RaisePoModal TYPE="MTR" PROJECTINFO={projectInfo} SCOPE={scope} SELECTEDPROJECT={selectedProject} CLOSEMODAL={closePoSoModal} />}
			</Modal>

			<Modal
				title="Pending For Approval"
				centered
				visible={poSoForApprovalModal}
				onCancel={() => setPoSoForApprovalModal(false)}
				width={1000}
				maskClosable={false}
				footer={null}
			>
				<PoSoMtrForApprovalModal SCOPE={scope} SELECTEDPROJECT={selectedProject} MOUNTED={mount} OPENSODETAILSMODAL={openSoDetailsModal} OPENPODETAILSMODAL={openPoDetailsModal} OPENMTRDETAILSMODAL={openMtrDetail} />
			</Modal>

			<Modal
				title="Raise Payment Request"
				centered
				visible={raisePaymentRequest}
				// onCancel={() => setRaisePaymentRequest(false)}
				onCancel={()=>handleCloseModal()}
				// onCancel={() => {setRaisePaymentRequest(false);window.location.reload()}}
				maskClosable={false}
				width={1000}
				footer={null}
			>
				<RaisePayementRequestModal SCOPE={scope} SELECTEDPROJECT={selectedProject} MOUNTED={mount} />
			</Modal>

			<Modal
				title="Approve Payment Request"
				centered
				visible={paymentRequestModal}
				// onCancel={() => setPaymentRequestModal(false)}
				onCancel={()=>handleCloseModal()}
				// onCancel={() => {setPaymentRequestModal(false); window.location.reload()}}
				maskClosable={false}
				width={1000}
				footer={null}
			>
				<PaymentRequestModal SCOPE={scope} SELECTEDPROJECT={selectedProject} MOUNTED={mount} />
			</Modal>

			<Modal
				title="Raise Open Purchase Order"
				centered
				visible={raiseOpenPOModal}
				onCancel={() => setRaiseOpenPoModal(false)}
				width={1000}
				footer={null}
				maskClosable={false}
			>
				<RaiseOpenPoModal CLOSEMODAL={() => setRaiseOpenPoModal(false)} />
			</Modal>
			</div>
            : 
            <FinanceOverviewMultiSelect></FinanceOverviewMultiSelect>
                    }

		</>
	)
}

export default connect(null, { setLoading, getApprovedProjects, setSnackInfo })(Index);