import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import { Card, CardContent, Container, Grid, TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import { setCustomerDetails } from '../../redux/actions/customerAction';
import HttpService from '../../services/httpService';

const useStyles = makeStyles((theme) => ({
  // Your existing styles...

  // Add new styles for the buttons
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    flexWrap: 'wrap', // Allow items to wrap to the next line
  },
  button: {
    width: '48%', // Adjust the width as needed
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: '100%', // Full width on small screens
    },
  },
}));

const CustomerDownloadCenter = () => {
  const classes = useStyles();
  const { customerDetails } = useSelector((state) => state.customer);
  const [customerQuotation,setCustomerQuotation] = useState([])
  const [otherAttahcments,setOtherAttachments] = useState([])


  const getCustomerQuotation = async () => {
    try {

      let res = await HttpService.getCustomerQuotation(customerDetails.leadId);
      setCustomerQuotation(res.data);
    } catch (error) {
      console.error("Token not available.");
    }
  };

  const getCustomerDetails = async () => {
    try {
      let res = await HttpService.getCustomerDetails(customerDetails._id);
      setOtherAttachments(res.data.leadDetails);
    } catch (error) {
      console.error("Token not available.");
    }
  };


  const handleQuotation = () =>{
    window.open(customerQuotation)
  }

  const handleLeadAttachment = (e) =>{
    if (e.currentTarget.getAttribute('data-value') === "sales") {
      window.open(otherAttahcments.salesChecklistPdfFile)
    }
    else if (e.currentTarget.getAttribute('data-value') === "salesquotation") {
      window.open(otherAttahcments.salesQuotation)
    }
    else if (e.currentTarget.getAttribute('data-value') === "designQuotation") {
      window.open(otherAttahcments.scanQuotationFile)
    }
  else if(e.currentTarget.getAttribute('data-value') === "contactdoc"){
    window.open(otherAttahcments.termsAndConditionsPdf)
  }
  else if(e.currentTarget.getAttribute('data-value') === "scanProjectKickOffCheckListFile"){
    window.open(otherAttahcments.scanProjectKickOffCheckListFile)
  }
  else if(e.currentTarget.getAttribute('data-value') === "scanSiteQCCheckListFile"){
    window.open(otherAttahcments.scanSiteQCCheckListFile)
  }
  else if(e.currentTarget.getAttribute('data-value') === "workingDrawingFile"){
    window.open(otherAttahcments.workingDrawingFile)
  }
  }
  useEffect(()=>{
    getCustomerDetails();
    getCustomerQuotation();
  },[])

  return (
    <Container maxWidth="md" style={{ marginTop: '7rem', marginBottom: '8rem' }}>
      <Card>
        <CardContent>
          {/* Your existing content... */}
          <div className={classes.buttonContainer}>
            <Button className={classes.button} variant="contained" color="primary" data-value="salesquotation" onClick={(e)=>handleLeadAttachment(e)} disabled={!otherAttahcments.salesQuotation ||   otherAttahcments.salesQuotation === ""}>
              Download Sales Quotation
            </Button>
            <Button className={classes.button} variant="contained" color="primary" data-value="designQuotation" onClick={(e)=>handleLeadAttachment(e)} disabled={otherAttahcments.scanQuotationFile === "" || !otherAttahcments.scanQuotationFile }>
              Download Design Quotation
            </Button>
            <Button className={classes.button} variant="contained" color="primary"  data-value="sales"  onClick={(e)=>handleLeadAttachment(e)} disabled={otherAttahcments.salesChecklistPdfFile === "" || !otherAttahcments.salesChecklistPdfFile }>
              Download Sales Checklist
            </Button>
            <Button className={classes.button} variant="contained"  color="primary" data-value="contactdoc" onClick={(e)=>handleLeadAttachment(e)} disabled={otherAttahcments.termsAndConditionsPdf === "" || otherAttahcments.termsAndConditionsPdf == null }>
              Download Contract Signed Document
            </Button>
            <Button className={classes.button} variant="contained"  color="primary" data-value="scanProjectKickOffCheckListFile" onClick={(e)=>handleLeadAttachment(e)} disabled={otherAttahcments.scanProjectKickOffCheckListFile === "" || !otherAttahcments.scanProjectKickOffCheckListFile}>
              Download Project Kick Off
            </Button>
            <Button className={classes.button} variant="contained"  color="primary" data-value="scanSiteQCCheckListFile" onClick={(e)=>handleLeadAttachment(e)} disabled={otherAttahcments.scanSiteQCCheckListFile === "" || !otherAttahcments.scanSiteQCCheckListFile}>
              Download Site QC File
            </Button>
            <Button className={classes.button} variant="contained"  color="primary" data-value="workingDrawingFile" onClick={(e)=>handleLeadAttachment(e)} disabled={otherAttahcments.workingDrawingFile === "" || !otherAttahcments.workingDrawingFile}>
              Downlaod Working Drawing
            </Button>
          </div>
        </CardContent>
      </Card>
    </Container>
  );
};

export default CustomerDownloadCenter;