import { GET_ALL_MATERIALS, SET_SNACK, SET_SNACK_INFO, SET_VENDOR_NAME } from "./types";
import HttpService from "../../services/httpService";

export const getAllMaterials = (type) => async dispatch => {
  try {
    const result = await HttpService.getAllMaterials(type)
    dispatch({ type: GET_ALL_MATERIALS, payload: result.data })
  } catch (error) {
    console.error(error);
    dispatch({ type: SET_SNACK, payload: true })
    dispatch({ type: SET_SNACK_INFO, payload: { msg: error.response.data, snackSeverity: "error" } })
  }
}

export const setVendorName = (data) => async dispatch => { //changes
  try {
    dispatch({type: SET_VENDOR_NAME, payload: data})
  } catch (err){
    console.error(err)
    dispatch({ type: SET_SNACK, payload: true })
    dispatch({ type: SET_SNACK_INFO, payload: { msg: err.response.data, snackSeverity: "error" } })
  }
}

