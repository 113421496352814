import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setLoading, setSnackInfo } from '../redux/actions/appAction';
import HttpService from '../services/httpService';
import { List, ListItem, ListItemText, Checkbox, Grid, Button, makeStyles, MenuItem, Select, FormControl, InputLabel } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Input,
    FormControlLabel,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    subItem: {
        marginLeft: theme.spacing(4),
    },
    subSubItem: {
        marginLeft: theme.spacing(8),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    dialogTitle: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        textAlign: 'center',
    },
    dialogContent: {
        padding: theme.spacing(3),
    },
    button: {
        margin: theme.spacing(1),
    },
}));

const UserAccessManagement = (props) => {
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [checkedItems, setCheckedItems] = useState({});
    const [selectedRole, setSelectedRole] = useState('');
    const [selectedData, setSelectedData] = useState([]);
    const [selectedProject, setSelectedProject] = useState(null);
    const [userAccessId, setUserAccess] = useState('');
    const [menus, setMenus] = useState([]);
    const [mainMenuModalOpen, setMainMenuModalOpen] = useState(false);
    const [subMenuModalOpen, setSubMenuModalOpen] = useState(false);
    const [formData, setFormData] = useState({
        key: "",
        label: "",
        url: "",
        priority: "",
        isActive: false,
        component: "",
        Icon: "",
        subMenu: [],
    });
    const [selectedParent, setSelectedParent] = useState("");

    useEffect(() => {
        initialData()
    }, []);

    const resetForm = () => {
        setFormData({
            key: "",
            label: "",
            url: "",
            priority: "",
            isActive: true,
            component: "",
            Icon: "",
            subMenu: [],
        });
        setSelectedParent("");
    };

    const initialData = async () => {
        props.setLoading(true);
        try {
            const response = await HttpService.getUserAccessDeatails(null, "main");
            const fetchedData = response.data.length !== 0 ? response.data[0].canAccess : [];
            setMenus(fetchedData)
        } catch (error) {
            console.error(error);
            props.setSnackInfo('Something Went Wrong', "error");
        }
        props.setLoading(false);
    }

    const handleMainMenuSubmit = async (e) => {
        e.preventDefault();
        const maxPriority = menus.reduce(
            (max, menu) => Math.max(max, menu.priority),
            0
        );
        const newMainMenu = { ...formData, priority: maxPriority + 1, isActive: false, subMenu: [] };
        const data = newMainMenu;
        setMenus([...menus, newMainMenu]);
        props.setLoading(true);
        try {
            const response = await HttpService.addMainMenuNavigation(data);
            props.setSnackInfo('Access details updated successfully', "success");
        } catch (error) {
            console.error(error);
            props.setSnackInfo('Something Went Wrong', "error");
        }
        props.setLoading(false);
        resetForm();
        setMainMenuModalOpen(false);
    };

    const handleSubMenuSubmit = async (e) => {
        e.preventDefault();

        const addSubMenu = (menuList) => {
            return menuList.map((menu) => {
                if (menu.key === selectedParent) {
                    const maxPriority = menu.subMenu.reduce(
                        (max, subMenu) => Math.max(max, subMenu.priority),
                        0
                    );
                    return {
                        ...menu,
                        subMenu: [
                            ...menu.subMenu,
                            { ...formData, priority: maxPriority + 1, subMenu: [], isActive: false, isNewItem: true },
                        ],
                    };
                } else if (menu.subMenu) {
                    return { ...menu, subMenu: addSubMenu(menu.subMenu) };
                }
                return menu;
            });
        };

        const data = addSubMenu(menus)
        setMenus(data);
        props.setLoading(true);
        try {
            const response = await HttpService.addSubMenuNavigation(data);
            props.setSnackInfo('Access details updated successfully', "success");
        } catch (error) {
            console.error(error);
            props.setSnackInfo('Something Went Wrong', "error");
        }
        props.setLoading(false);

        resetForm();
        setSubMenuModalOpen(false);
    };

    const renderMenus = (menuList) => {
        return menuList.map((menu) => (
            <div key={menu.key} className="menu-item">
                <div>{menu.label}</div>
                {menu.subMenu && menu.subMenu.length > 0 && (
                    <div className="sub-menu">{renderMenus(menu.subMenu)}</div>
                )}
            </div>
        ));
    };

    const renderMenuOptions = (menuList) => {
        return menuList.flatMap((menu) => [
            <option key={menu.key} value={menu.key}>
                {menu.label}
            </option>,
            menu.subMenu &&
            menu.subMenu.length > 0 &&
            renderMenuOptions(menu.subMenu),
        ]);
    };

    const fetchUserData = async (e) => {
        let value = e;
        props.setLoading(true);
        try {
            const response = await HttpService.getUserAccessDeatails(value, selectedRole);
            const fetchedData = response.data.length !== 0 ? response.data[0].canAccess : [];
            setUserAccess(response.data[0])
            setData(fetchedData);
            const initialCheckedItems = {};
            fetchedData.forEach(menuItem => {
                initialCheckedItems[menuItem.key] = menuItem.isActive;
                menuItem.subMenu.forEach(subItem => {
                    initialCheckedItems[subItem.key] = subItem.isActive;
                    if (subItem.subMenu) {
                        subItem.subMenu.forEach(subSubMenu => {
                            initialCheckedItems[subSubMenu.key] = subSubMenu.isActive;
                        });
                    }
                });
            });
            setCheckedItems(initialCheckedItems);
        } catch (error) {
            console.error(error);
            props.setSnackInfo('Something Went Wrong', "error");
        }
        props.setLoading(false);
    };

    const handleSelectChange = (event, value) => {
        setSelectedProject(value)
        fetchUserData(value?._id);
    };

    const handleCheckboxChange = (key, subMenu, subSubMenu) => {
        setCheckedItems(prevState => {
            const newState = { ...prevState };
            const isChecked = !prevState[key];
            newState[key] = isChecked;

            if (subMenu) {
                subMenu.forEach(subItem => {
                    newState[subItem.key] = isChecked;
                    if (subItem.subMenu) {
                        subItem.subMenu.forEach(subSubItem => {
                            newState[subSubItem.key] = isChecked;
                        });
                    }
                });
            }

            return newState;
        });
    };

    const handleMenuItemClick = (menuItem) => {
        setData(prevState => prevState.map(item => item === menuItem ? { ...item, open: !item.open } : { ...item, open: false }));
    };

    const handleSubMenuClick = (subItem) => {
        setData(prevState => prevState.map(menuItem => ({
            ...menuItem,
            subMenu: menuItem.subMenu.map(item => item === subItem ? { ...item, open: !item.open } : { ...item, open: false })
        })));
    };

    const handleSubSubMenuClick = (subSubMenu) => {
        setData(prevState => prevState.map(menuItem => ({
            ...menuItem,
            subMenu: menuItem.subMenu.map(subItem => subItem.subMenu ? {
                ...subItem,
                subMenu: subItem.subMenu.map(item => item === subSubMenu ? { ...item, open: !item.open } : { ...item, open: false })
            } : subItem)
        })));
    };

    const renderSubMenu = (subMenu) => (
        <List component="div" disablePadding>
            {subMenu.map(subItem => (
                <React.Fragment key={subItem.key}>
                    <ListItem button onClick={() => handleSubMenuClick(subItem)} className={classes.subItem}>
                        <Checkbox
                            edge="start"
                            checked={checkedItems[subItem.key] || false}
                            onClick={(event) => event.stopPropagation()}
                            onChange={() => handleCheckboxChange(subItem.key, subItem.subMenu)}
                        />
                        <ListItemText primary={subItem.label} />
                    </ListItem>
                    {subItem.open && subItem.subMenu && subItem.subMenu.length > 0 && renderSubSubMenu(subItem.subMenu)}
                </React.Fragment>
            ))}
        </List>
    );

    const renderSubSubMenu = (subSubMenu) => (
        <List component="div" disablePadding>
            {subSubMenu.map(subSubItem => (
                <React.Fragment key={subSubItem.key}>
                    <ListItem button onClick={() => handleSubSubMenuClick(subSubItem)} className={classes.subSubItem}>
                        <Checkbox
                            edge="start"
                            checked={checkedItems[subSubItem.key] || false}
                            onClick={(event) => event.stopPropagation()}
                            onChange={() => handleCheckboxChange(subSubItem.key)}
                        />
                        <ListItemText primary={subSubItem.label} />
                    </ListItem>
                </React.Fragment>
            ))}
        </List>
    );

    const renderPrimaryMenu = (menuItem) => (
        <React.Fragment key={menuItem.key}>
            <ListItem button onClick={() => handleMenuItemClick(menuItem)}>
                <Checkbox
                    edge="start"
                    checked={checkedItems[menuItem.key] || false}
                    onClick={(event) => event.stopPropagation()}
                    onChange={() => handleCheckboxChange(menuItem.key, menuItem.subMenu)}
                />
                <ListItemText primary={menuItem.label} />
            </ListItem>
            {menuItem.open && menuItem.subMenu && menuItem.subMenu.length > 0 && renderSubMenu(menuItem.subMenu)}
        </React.Fragment>
    );

    const handleSave = async () => {
        const updatedData = data.map(menuItem => {
            const updatedMenuItem = { ...menuItem, isActive: checkedItems[menuItem.key] };
            if (menuItem.subMenu) {
                updatedMenuItem.subMenu = menuItem.subMenu.map(subItem => {
                    const updatedSubItem = { ...subItem, isActive: checkedItems[subItem.key] };
                    if (subItem.subMenu) {
                        updatedSubItem.subMenu = subItem.subMenu.map(subSubItem => ({
                            ...subSubItem,
                            isActive: checkedItems[subSubItem.key]
                        }));
                    }
                    return updatedSubItem;
                });
            }
            return updatedMenuItem;
        });

        let userAccess = {
            _id: userAccessId._id,
            canAccess: updatedData,
            selected : selectedRole,
            role_id  : selectedRole === "Roles" ? userAccessId.roleId : ""
        }
        try {
            await HttpService.updateUserAccessDeatails(userAccess);
            props.setSnackInfo('Access details updated successfully', "success");
        } catch (error) {
            console.error(error);
            props.setSnackInfo('Failed to update access details', "error");
        }
    };

    const slectedValue = async (e,value) => {
        setSelectedRole(value);
        setSelectedProject(null);
        if (value === 'Roles') {
            try {
                const rolesResponse = await HttpService.getAllRoles();
                setSelectedData(rolesResponse.data);
            } catch (error) {
                console.error(error);
                props.setSnackInfo('Failed to fetch roles', "error");
            }
        } else {
            try {
                const usersResponse = await HttpService.getAllUsersforAcess({ isActive: true });
                setSelectedData(usersResponse.data);
            } catch (error) {
                console.error(error);
                props.setSnackInfo('Failed to fetch users', "error");
            }
        }
    };

    const commonStyle = {
        minWidth: '100px',
        width: '300px',
        height: '30px',
    };

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom:'50px' }}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>

                        <Autocomplete
                            value={selectedRole}
                            onChange={(event, value) => slectedValue(event, value)}
                            id="role-autocomplete"
                            options={['Roles', 'Users']}
                            getOptionLabel={(option) => option}
                            style={commonStyle}
                            renderInput={(params) => <TextField {...params} label="Select option" fullWidth variant="outlined" />}
                        />
                        <Autocomplete
                            value={selectedProject}
                            onChange={handleSelectChange}
                            id="project-code-dropdown"
                            options={selectedData}
                            getOptionLabel={(option) => selectedRole === "Users" ? option.name + ' - ' + option?.departmentId?.name : option.name}
                            style={commonStyle}
                            renderInput={(params) => <TextField {...params} label="Search" fullWidth variant="outlined" />}
                        />

                    </div>
                </div>
                <div>
                    <button
                        style={{
                            backgroundColor: '#4CAF50',
                            border: 'none',
                            color: 'white',
                            padding: '10px 20px',
                            textAlign: 'center',
                            textDecoration: 'none',
                            display: 'inline-block',
                            fontSize: '16px',
                            margin: '4px 2px',
                            cursor: 'pointer',
                            borderRadius: '4px',
                        }}
                        onClick={() => setMainMenuModalOpen(true)}
                    >
                        Create Main Menu
                    </button>
                    <button
                        style={{
                            backgroundColor: '#008CBA',
                            border: 'none',
                            color: 'white',
                            padding: '10px 20px',
                            textAlign: 'center',
                            textDecoration: 'none',
                            display: 'inline-block',
                            fontSize: '16px',
                            margin: '4px 2px',
                            cursor: 'pointer',
                            borderRadius: '4px',
                        }}
                        onClick={() => setSubMenuModalOpen(true)}
                    >
                        Add Sub Menu
                    </button>
                </div>
            </div>
            <div>

                {data && data.length > 0 ?
                    <div>
                        <Grid container spacing={3}>
                            {data.map((menuItem) => (
                                <Grid item key={menuItem.key} xs={12} sm={6} md={4}>
                                    <List>
                                        {renderPrimaryMenu(menuItem)}
                                    </List>
                                </Grid>
                            ))}
                        </Grid>
                        <Button
                            style={{
                                backgroundColor: '#4CAF50',
                                border: 'none',
                                color: 'white',
                                padding: '10px 20px',
                                textAlign: 'center',
                                textDecoration: 'none',
                                display: 'inline-block',
                                fontSize: '16px',
                                margin: '10px 10px',
                                cursor: 'pointer',
                                borderRadius: '4px',
                            }}
                            variant="contained" color="primary" onClick={handleSave}>
                            Save
                        </Button>
                    </div>
                    : (
                        <div></div>
                    )}
            </div>
            <div>
                <>
                    <Dialog
                        open={mainMenuModalOpen}
                        onClose={() => setMainMenuModalOpen(false)}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>Add Main Menu</DialogTitle>
                        <form onSubmit={handleMainMenuSubmit}>
                            <DialogContent className={classes.dialogContent}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="key">Key</InputLabel>
                                    <Input
                                        id="key"
                                        value={formData.key}
                                        onChange={(e) => setFormData({ ...formData, key: e.target.value })}
                                    />
                                </FormControl>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="label">Label</InputLabel>
                                    <Input
                                        id="label"
                                        value={formData.label}
                                        onChange={(e) => setFormData({ ...formData, label: e.target.value })}
                                    />
                                </FormControl>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="url">/URL</InputLabel>
                                    <Input
                                        id="url"
                                        // type="url"
                                        value={formData.url}
                                        onChange={(e) => setFormData({ ...formData, url: e.target.value })}
                                    />
                                </FormControl>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="component">Component</InputLabel>
                                    <Input
                                        id="component"
                                        value={formData.component}
                                        onChange={(e) => setFormData({ ...formData, component: e.target.value })}
                                    />
                                </FormControl>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="icon">Icon</InputLabel>
                                    <Input
                                        id="icon"
                                        value={formData.Icon}
                                        onChange={(e) => setFormData({ ...formData, Icon: e.target.value })}
                                    />
                                </FormControl>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={() => {
                                        setMainMenuModalOpen(false);
                                        resetForm();
                                    }}
                                    color="primary"
                                    className={classes.button}
                                >
                                    Cancel
                                </Button>
                                <Button type="submit" color="primary" className={classes.button}>
                                    Save
                                </Button>
                            </DialogActions>
                        </form>
                    </Dialog>

                    <Dialog
                        open={subMenuModalOpen}
                        onClose={() => setSubMenuModalOpen(false)}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>Add Sub Menu</DialogTitle>
                        <form onSubmit={handleSubMenuSubmit}>
                            <DialogContent className={classes.dialogContent}>
                                <FormControl fullWidth required>
                                    <InputLabel htmlFor="parentMenu">Parent Menu</InputLabel>
                                    <Select
                                        value={selectedParent}
                                        onChange={(e) => setSelectedParent(e.target.value)}
                                    >
                                        <MenuItem value="">Select Parent Menu</MenuItem>
                                        {renderMenuOptions(menus)}
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="key">Key</InputLabel>
                                    <Input
                                        id="key"
                                        value={formData.key}
                                        onChange={(e) => setFormData({ ...formData, key: e.target.value })}
                                    />
                                </FormControl>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="label">Label</InputLabel>
                                    <Input
                                        id="label"
                                        value={formData.label}
                                        onChange={(e) => setFormData({ ...formData, label: e.target.value })}
                                    />
                                </FormControl>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="url">/URL</InputLabel>
                                    <Input
                                        id="url"
                                        value={formData.url}
                                        onChange={(e) => setFormData({ ...formData, url: e.target.value })}
                                    />
                                </FormControl>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="component">Component</InputLabel>
                                    <Input
                                        id="component"
                                        value={formData.component}
                                        onChange={(e) => setFormData({ ...formData, component: e.target.value })}
                                    />
                                </FormControl>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="icon">Icon</InputLabel>
                                    <Input
                                        id="icon"
                                        value={formData.Icon}
                                        onChange={(e) => setFormData({ ...formData, Icon: e.target.value })}
                                    />
                                </FormControl>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={() => {
                                        setSubMenuModalOpen(false);
                                        resetForm();
                                    }}
                                    color="primary"
                                    className={classes.button}
                                >
                                    Cancel
                                </Button>
                                <Button type="submit" color="primary" className={classes.button}>
                                    Save
                                </Button>
                            </DialogActions>
                        </form>
                    </Dialog>
                </>
            </div>
        </div>
    );
};

export default connect(null, { setLoading, setSnackInfo })(UserAccessManagement);