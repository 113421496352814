import { GET_APPROVED_PROJECTS, SET_SNACK, SET_SNACK_INFO } from "./types";
import HttpService from "../../services/httpService";

export const getApprovedProjects = () => async dispatch => {
  try {
    const result = await HttpService.getApprovedProjects()
    dispatch({ type: GET_APPROVED_PROJECTS, payload: result.data })
  } catch (error) {
    console.error(error);
    dispatch({ type: SET_SNACK, payload: true })
    dispatch({ type: SET_SNACK_INFO, payload: { msg: error.response.data, snackSeverity: "error" } })

  }
}