import React, { useState } from 'react';
import {
    Dialog, DialogActions, DialogContent, DialogTitle,
    Button, TextField, FormControlLabel, Checkbox, Grid
} from '@material-ui/core';
import HttpService from '../services/httpService';

const EditDialog = ({ open, handleClose, data }) => {

    const [visibleSection, setVisibleSection] = useState('scope');
    const [checkedFields, setCheckedFields] = useState([]);
    const [updatedFields, setUpdatedFields] = useState({});

    const handleCheckboxChange = (section) => {
        setVisibleSection(section);
    };

    const handleCheckboxToggle = (id, type) => {
        setCheckedFields(prevCheckedFields => {
            const existingFieldIndex = prevCheckedFields.findIndex(field => field.id === id && field.type === type);
            if (existingFieldIndex !== -1) {
                // Remove the checkbox state if it already exists
                return prevCheckedFields.filter(field => !(field.id === id && field.type === type));
            } else {
                // Add the checkbox state
                return [...prevCheckedFields, { id, type, isActive: true }];
            }
        });
    };

    const handleFieldChange = (id, type, value) => {
        setUpdatedFields(prevUpdatedFields => ({
            ...prevUpdatedFields,
            [`${type}_${id}`]: { id, type, value }
        }));
    };

    const handleSave = async () => {
        // Filter out the checked fields before sending the data to the server
        const updatedFieldsArray = Object.values(updatedFields);
        handleCheckbox();
        await HttpService.updateLeadCriteria(updatedFieldsArray);
        handleClose();
    };
    const handleCheckbox = async () => {
        let res = await HttpService.removeCriteria(checkedFields);
    }

    const isFieldChecked = (id, type) => {
        const field = checkedFields.find(field => field.id === id && field.type === type);
        return field ? field.isActive : false;
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Edit Details</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={<Checkbox checked={visibleSection === 'scope'} onChange={() => handleCheckboxChange('scope')} />}
                            label="Show Scope"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={visibleSection === 'budget'} onChange={() => handleCheckboxChange('budget')} />}
                            label="Show Budget"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={visibleSection === 'timeline'} onChange={() => handleCheckboxChange('timeline')} />}
                            label="Show Timeline"
                        />
                    </Grid>

                    {visibleSection === 'scope' && data.scopeData.map(scope => (
                        <Grid container spacing={2} alignItems="center" key={scope._id}>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isFieldChecked(scope._id, 'scope')}
                                            onChange={() => handleCheckboxToggle(scope._id, 'scope')}
                                        />
                                    }
                                />
                            </Grid>
                            <Grid item xs>
                                <TextField
                                    fullWidth
                                    defaultValue={scope.name}
                                    onChange={(e) => handleFieldChange(scope._id, 'scope', e.target.value)}
                                    disabled={isFieldChecked(scope._id, 'scope')}
                                />
                            </Grid>
                        </Grid>
                    ))}

                    {visibleSection === 'budget' && data.budgets.map(budget => (
                        <Grid container spacing={2} alignItems="center" key={budget._id}>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isFieldChecked(budget._id, 'budget')}
                                            onChange={() => handleCheckboxToggle(budget._id, 'budget')}
                                        />
                                    }
                                />
                            </Grid>
                            <Grid item xs>
                                <TextField
                                    fullWidth
                                    defaultValue={budget.budget}
                                    onChange={(e) => handleFieldChange(budget._id, 'budget', e.target.value)}
                                    disabled={isFieldChecked(budget._id, 'budget')}
                                />
                            </Grid>
                        </Grid>
                    ))}

                    {visibleSection === 'timeline' && data.timelines.map(timeline => (
                        <Grid container spacing={2} alignItems="center" key={timeline._id}>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isFieldChecked(timeline._id, 'timeline')}
                                            onChange={() => handleCheckboxToggle(timeline._id, 'timeline')}
                                        />
                                    }
                                />
                            </Grid>
                            <Grid item xs>
                                <TextField
                                    fullWidth
                                    defaultValue={timeline.timeline}
                                    onChange={(e) => handleFieldChange(timeline._id, 'timeline', e.target.value)}
                                    disabled={isFieldChecked(timeline._id, 'timeline')}
                                />
                            </Grid>
                        </Grid>
                    ))}

                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSave} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditDialog;