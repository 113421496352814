import React, { useEffect, useState } from 'react'
import HttpService from '../../services/httpService'
import { connect, useSelector } from 'react-redux'
import { setLoading, setSnackInfo } from '../../redux/actions/appAction'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getAllMaterials } from '../../redux/actions/vendorAction';

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  root: {
    width: '100%',
  },
  textField: {
    width: "100px"
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#ccc",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const BomMaterialForApproval = (props) => {
  const classes = useStyles();
  const { allMaterials } = useSelector(state => state.vendor)

  useEffect(() => {
    getBomMaterialsForApproval()
    getAllMaterials()
    // eslint-disable-next-line
  }, [])

  const getAllMaterials = async () => {
    try {
      await props.getAllMaterials()
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.messsage ?? "Error fetching materials", "error")
    }
  }

  const [materialsForApproval, setMaterialsForApproval] = useState([])
  const [remappedMaterial, setRemappedMaterial] = useState([])

  const getBomMaterialsForApproval = async () => {
    props.setLoading(true)
    try {
      const response = await HttpService.getMaterialsForApproval()
      console.log(response.data)
      setMaterialsForApproval(response.data)
    } catch (error) {
      console.log(error)
      setMaterialsForApproval([])
      props.setSnackInfo(error?.response?.data?.message ?? "Error while fetching materials for approval", "error")
    }
    props.setLoading(false)
  }

  const remapMaterial = async (materialId, i) => {
    console.log(remappedMaterial, materialId, null)
    props.setLoading(true)
    try {
      await HttpService.remapMaterials({ remappedMaterial: remappedMaterial[i], materialId }, null)
      props.setSnackInfo('Successfully Remapped')
      getBomMaterialsForApproval()
    } catch (error) {
      console.log(error)
      props.setSnackInfo('Error while reampping materials', "error")

    }
    props.setLoading(false)
  }

  const approveMaterial = async (materialId) => {
    props.setLoading(true)
    try {
      await HttpService.approveMaterial(materialId)
      props.setLoading('Material Approved')
      getBomMaterialsForApproval()
    } catch (error) {
      console.log(error)
      props.setSnackInfo(error?.response?.data?.message ?? 'Error while approving material', "error")
    }
    props.setLoading(false)
  }

  const handleRemapOfMaterial = async (v, i) => {
    let remappedMaterials = [...remappedMaterial]
    if (v?._id) {
      remappedMaterials[i] = v
    } else {
      remappedMaterials.splice(i, 1)
    }
    setRemappedMaterial(remappedMaterials)
  }


  const getRemappedMaterial = (i) => {
    const item = remappedMaterial[i]
    return item || {}
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Sl No</StyledTableCell>
              <StyledTableCell>Material Name</StyledTableCell>
              <StyledTableCell>Re Map</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {materialsForApproval?.map((material, i) => (
              <StyledTableRow key={i + 1} >
                <StyledTableCell>{i + 1}</StyledTableCell>
                <StyledTableCell>{material?.name}</StyledTableCell>
                <StyledTableCell>
                  <Autocomplete
                    value={getRemappedMaterial(i)}
                    onChange={(e, v) => handleRemapOfMaterial(v, i)}
                    options={allMaterials}
                    getOptionLabel={(option) => option.name}
                    // style={{ width: 250, fontSize: '12px' }}
                    renderInput={(params) => <TextField {...params} label="Remap Materials" fullWidth size="small" variant="outlined" />}
                  /></StyledTableCell>
                <StyledTableCell>
                  <StyledTableRow>
                    <StyledTableCell>
                      <Button disabled={!Object.keys(remappedMaterial).length} variant="contained" color="primary" style={{ background: "#34bf73", margin: 5 }} onClick={() => remapMaterial(material?._id, i)}>
                        REMAP
                      </Button>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Button disabled={Object.keys(remappedMaterial).length} variant="contained" color="primary" style={{ background: "#716ACA", margin: 5 }} onClick={() => approveMaterial(material?._id)}>
                        Approve
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>

                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default connect(null, { setLoading, setSnackInfo, getAllMaterials })(BomMaterialForApproval)
