import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getAllUnits } from '../../../redux/actions/projectAction'
import { setLoading, setSnackInfo } from '../../../redux/actions/appAction'
import { Table, TableBody, TextField, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { EditOutlined } from '@ant-design/icons';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import HttpService from '../../../services/httpService';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 850,
    }
}));

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#ccc",
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const ManageDiscount = (props) => {
    const classes = useStyles();

    useEffect(() => {
        getDiscountMasterData()
        // eslint-disable-next-line
    }, [])

    const [discountList, setDiscountList] = useState([]);
    const [showAddDiscountModal, setShowAddDiscountModal] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [discountDetails, setDiscountDetails] = useState({
        id: "",
        name: "",
        value: ""
    })

    const { name, value } = discountDetails

    const getDiscountMasterData = async () => {
        props.setLoading(true)
        try {
            const response = await HttpService.getDiscountMasterData()
            console.log(response.data)
            setDiscountList(response.data)
        } catch (error) {
            props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const handleDialogClose = () => {
        setDiscountDetails({ name: "", description: "" })
        setShowAddDiscountModal(false)
    }

    const addDiscount = async () => {
        props.setLoading(true)
        try {
            const response = await HttpService.newDiscount({ name, value })
            props.setSnackInfo(response?.data, "success")
            handleDialogClose()
            getDiscountMasterData()
        } catch (error) {
            console.error(error)
            props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const editDiscountValue = (data) => {
        setDiscountDetails({ id: data?._id, name: data?.name, value: data?.value })
        setShowAddDiscountModal(true)
        setIsEdit(true)
    }

    const updateDiscount = async () => {
        let obj = {
            name: discountDetails.name,
            value: discountDetails.value
        }
        props.setLoading(true)
        try {
            const response = await HttpService.editDiscount(discountDetails.id, obj)
            console.log(response.data);
            getDiscountMasterData()
            handleDialogClose()
            setIsEdit(false)
            props.setSnackInfo('Discount Updated Successfully', "success")
        } catch (error) {
            console.error(error)
            props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const setActiveDiscount = async (e, id) => {
        props.setLoading(true)
        try {
            const response = await HttpService.inActiveDiscount(id)
            getDiscountMasterData()
            handleDialogClose()
            setIsEdit(false)
            props.setSnackInfo(response?.data, "success")
        } catch (error) {
            console.error(error)
            props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    return (
        <div>
            <div style={{ marginBottom: 10, fontSize: 18 }}><b>Manage Discount</b></div>
            <div style={{ display: "flex", justifyContent: "flex-end" }} >
                {
                    discountList.length === 0 &&
                    <Button onClick={() => { setShowAddDiscountModal(true); setIsEdit(false) }} variant="contained" color="primary" >Add Discount</Button>
                }
            </div>
            <div style={{ marginBottom: 10 }}></div>

            <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Sl No</StyledTableCell>
                            <StyledTableCell>Discount Name</StyledTableCell>
                            <StyledTableCell>Value</StyledTableCell>
                            <StyledTableCell>IsActive</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {discountList.length !== 0 ?
                            discountList?.map((data, i) => <StyledTableRow key={i + 1}>
                                <StyledTableCell>{i + 1}</StyledTableCell>
                                <StyledTableCell>{data?.name} <Tooltip title="Edit"><EditOutlined style={{ cursor: "pointer", marginLeft: 10 }} onClick={() => editDiscountValue(data)} /></Tooltip></StyledTableCell>
                                <StyledTableCell>{data?.value}</StyledTableCell>
                                <StyledTableCell>
                                    <Checkbox
                                        checked={data?.isActive}
                                        onChange={(e) => setActiveDiscount(e, data._id)}
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                    />
                                </StyledTableCell>
                            </StyledTableRow>)
                            :
                            <StyledTableRow>
                                <StyledTableCell colSpan={4}>No Data Found</StyledTableCell>
                            </StyledTableRow>}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog
                open={showAddDiscountModal}
                TransitionComponent={Transition}
                fullWidth={true}
                maxWidth={'sm'}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{`${isEdit ? 'Edit' : 'Add New'} Discount`}</DialogTitle>
                <hr />
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <TextField label="Name" name="name" value={name} style={{ marginBottom: 10 }} fullWidth onChange={(e) => setDiscountDetails({ ...discountDetails, [e.target.name]: e.target.value })} variant="outlined" />
                        <TextField label="Value" name="value" value={value} style={{ marginBottom: 10 }} fullWidth onChange={(e) => setDiscountDetails({ ...discountDetails, [e.target.name]: e.target.value })} variant="outlined" />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {!isEdit ? <Button variant='contained' color="primary" onClick={addDiscount} >
                        Add Discount
                    </Button> :
                        <Button variant='contained' color="primary" onClick={updateDiscount} >
                            Update Discount
                        </Button>}
                </DialogActions>

            </Dialog>

        </div>
    )
}

export default connect(null, { setLoading, setSnackInfo, getAllUnits })(ManageDiscount)
