import React from 'react'
import InwardingView from '../../../components/inwardingView'

const InwardingForProjects = () => {
  return (
    <div>
      <InwardingView TYPE="Project" />
    </div >
  )
}

export default InwardingForProjects
