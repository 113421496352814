const dev = {
    apiUrl: "http://localhost:3005/api/",
    qtUrl: "http://localhost:5003/api/"
}

const test = {
    apiUrl: "http://11.0.128.71:3005/api/",
    qtUrl: "http:///11.0.128.71:5003/api/"

}

// const prod = {
//     apiUrl: "http://13.233.26.162:3005/api/",
//     qtUrl: "http:///13.233.26.162:5003/api/"
// }

const prod = {
    apiUrl: "https://inventoryerp.decorpot.com/api/",
    qtUrl: "https://quotationerp.decorpot.com/api/"
}

const config =
    process.env.REACT_APP_STAGE === "production"
        ? prod :
        process.env.REACT_APP_STAGE === "test"
            ? test
            : dev; 
export default config;
