import React from 'react'
import OutwardingView from '../../../components/outwardingView'

const FactoryOutwarding = () => {
  return (
    <div>
      <OutwardingView TRANSFERFROM={'factory'} />
    </div>
  )
}

export default FactoryOutwarding
