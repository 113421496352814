import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import './Footer.css';

const useStyles = makeStyles((theme) => ({
  socialIcons: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
  },
  socialIcon: {
    margin: theme.spacing(2),
    color: theme.palette.common.white,
    fontSize: 30,
    cursor: 'pointer',
  },
  copyright: {
    color: theme.palette.common.white,
    padding: theme.spacing(2),
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const Footer = () => {
  const classes = useStyles();

  const redirectToLink = (link) => {
    window.location.href = link;
  };

  return (
    <footer className="footer">
      {/* <div className={classes.socialIcons}>
        <FacebookIcon
          className={classes.socialIcon}
          onClick={() => redirectToLink('https://www.facebook.com/decorpot/')}
        />
        <TwitterIcon
          className={classes.socialIcon}
          onClick={() => redirectToLink('https://twitter.com/decorpot/')}
        />
        <InstagramIcon
          className={classes.socialIcon}
          onClick={() => redirectToLink('https://www.instagram.com/p/CWSYuA8PTM8/')}
        />
        <LinkedInIcon
          className={classes.socialIcon}
          onClick={() => redirectToLink('https://www.linkedin.com/company/decorpotinteriors/?originalSubdomain=in')}
        />
      </div> */}
      <Typography variant="body2" className={classes.copyright}>
        © {new Date().getFullYear()} Decorpot. All rights reserved.
      </Typography>
    </footer>
  );
};

export default Footer;