import { SET_LOADING, SET_SNACK, SET_SNACK_INFO } from "./types";

export const setLoading = (flag) => async dispatch => {
  dispatch({ type: SET_LOADING, payload: flag })
};

export const setSnack = (flag) => async dispatch => {
  dispatch({ type: SET_SNACK, payload: flag })
}

export const setSnackInfo = (msg, snackSeverity) => async dispatch => {
  dispatch({ type: SET_SNACK_INFO, payload: { msg, snackSeverity } })
}
