import { GET_ALL_ROLES, GET_ALL_UNIQUE_ROLE_FIELDS, SET_SNACK_INFO, SET_SNACK } from "./types";
import HttpService from "../../services/httpService";

export const getAllRoles = () => async dispatch => {
  try {
    const result = await HttpService.getAllRoles()
    dispatch({ type: GET_ALL_ROLES, payload: result.data })
  } catch (error) {
    console.error(error);
    dispatch({ type: SET_SNACK, payload: true })
    dispatch({ type: SET_SNACK_INFO, payload: { msg: error.response.data, snackSeverity: "error" } })
  }
}
export const getUniqueRoleFields = () => async dispatch => {
  try {
    const result = await HttpService.getUniqueRoleFields()
    dispatch({ type: GET_ALL_UNIQUE_ROLE_FIELDS, payload: result.data })
  } catch (error) {
    console.error(error);
    dispatch({ type: SET_SNACK, payload: true })
    dispatch({ type: SET_SNACK_INFO, payload: { msg: error.response.data, snackSeverity: "error" } })
  }
}