import React, { useState, useEffect } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { DownloadOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons';
import HttpService from '../services/httpService'
import { connect } from 'react-redux'
import { setLoading, setSnackInfo } from '../redux/actions/appAction';
import { useSelector } from 'react-redux';
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core'
import { ROLENAME} from '../utils/index';
const useStyles = makeStyles((theme) => ({
	table: {
		minWidth: 700,
	},
	formControl: {
		margin: theme.spacing(3),
	},
	root: {
		display: 'flex',
	},
}));

const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: "#ccc",
		color: theme.palette.common.black,
	},
	body: {
		fontSize: 14,
	},
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
	root: {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover,
		},
	},
}))(TableRow);

const SoDetailsModal = (props) => {
	const classes = useStyles();
	const { userDetails } = useSelector(state => state.user)

	const editServiceOrderStatus = async (status) => {
		props.setLoading(true)
		try {
			const result = await HttpService.editServiceOrderStatus({ status }, props?.SODETAILS?._id)
			if (result.status === 200) {
				props.setSnackInfo(`Service order ${status}`, "success")
				props.REFRESH(props?.SODETAILS?.orderId)
			}
		} catch (error) {
			console.error(error)
			props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
		}
		props.setLoading(false)
	}

	const getMaskedSODocument = async (data) => {
		props.setLoading(true)
		try {
			const result = await HttpService.generateMaskedSo(data._id)
			window.open(result.data.link)
		} catch (error) {
			console.log(error, "error")
		}
		props.setLoading(false)
	}


	const [deleteOpenModel, setDeleteOpenModel] = useState(false)

	const removeSoForProject = () => {
		setDeleteOpenModel(true)
	}

	const deleteSoConfirm = async () => {
		props.setLoading(true)
		try {
			let response = await HttpService.removeSo(props?.SODETAILS?._id)
			props.setSnackInfo(response?.data ?? "Something went wrong", "success")
			setDeleteOpenModel(false)
			props.VIEWALL()
		} catch (error) {
			console.error(error)
			props.setSnackInfo(error?.response?.data?.message ?? "Error", "error")
		}
		props.setLoading(false)
	}

	return (
		<div>
			<div className='modal-header' style={{ justifyContent: "space-between" }}>
				<div style={{ width: "25%" }}>
					<b>Project: </b>
					<span>{`${props?.SODETAILS?.projectId?.code}_${props?.SODETAILS?.projectId?.clientName}`}</span>
				</div>
				<div style={{ width: "25%" }}>
					<b>Scope: </b>
					<span>{props?.SODETAILS?.scopeId?.name}</span>
				</div>
				<div style={{ width: "25%" }}>
					<b>Vendor Name: </b>
					<span>{props?.SODETAILS?.vendorName}</span>
				</div>
				<div style={{ width: "25%" }}>
					<b>Vendor Mobile: </b>
					<span>{props?.SODETAILS?.vendorMobile}</span>
				</div>
			</div>
			<div className='modal-header' style={{ justifyContent: "space-between" }}>
				<div style={{ width: "25%" }}>
					<b>Expected Delivery Date: </b>
					<span>{props?.SODETAILS?.deliveryDate}</span>
				</div>
				<div style={{ width: "25%" }}>
					<b>Status: </b>
					<span>{props?.SODETAILS?.status}</span>
				</div>
				{(props?.SODETAILS?.status === "Approved" || props?.SODETAILS?.status === "SO Generated") && 
					<div style={{ width: "35%" }}>
						<span style={{ marginBottom: 10 }} className="btn" onClick={() => window.open(props?.SODETAILS?.soLink)}> Download SO <DownloadOutlined /></span>
						<br /><br />
						{
							props?.SODETAILS?.maskedSoLink ?
								<span className="btn" onClick={() => window.open(props?.SODETAILS?.maskedSoLink)}> Download Masked SO <DownloadOutlined /></span>
								:
								<span className="btn" onClick={() => getMaskedSODocument(props?.SODETAILS)}> Download Masked SO <DownloadOutlined /></span>
						}
					</div>
				}
				<div style={{ width: "15%" }}>
					<span className="btn" onClick={() => props.VIEWALL()}>View All <EyeOutlined /></span>
					<br /><br />
					{userDetails?.roles?.find(role => role.name == ROLENAME.OperationHead) && <span className="btn" onClick={() => removeSoForProject()}>Delete SO <DeleteOutlined /> </span>}
				</div>
			</div>
			{
				props.TITLE === "Approve SO" && props?.SODETAILS?.status !== "Approved" && props?.SODETAILS?.status !== "Rejected" && props?.SODETAILS?.status !== "SO Generated" && <div className="approve-reject-btn">
					<button onClick={() => editServiceOrderStatus("Approved")} className='approve-btn'>Approve</button>
					<button onClick={() => editServiceOrderStatus("Rejected")} className='reject-btn'>Reject</button>
				</div>
			}
			<TableContainer component={Paper}>
				<Table className={classes.table} size="small" aria-label="customized table">
					<TableHead>
						<TableRow>
							<StyledTableCell>Sl No</StyledTableCell>
							<StyledTableCell>Product</StyledTableCell>
							{/* <StyledTableCell>Category</StyledTableCell>. */}
							<StyledTableCell>Unit</StyledTableCell>
							<StyledTableCell>Area/Qty</StyledTableCell>
							<StyledTableCell>Requested Area/Qty</StyledTableCell>
							<StyledTableCell>Price</StyledTableCell>
							{/* <StyledTableCell>Decorpot Price</StyledTableCell> */}
							{/* <StyledTableCell>Quotation file</StyledTableCell> */}
							{/* <StyledTableCell>Remarks</StyledTableCell> */}
						</TableRow>
					</TableHead>
					<TableBody>
						{props?.SODETAILS?.materials?.map((data, i) => <StyledTableRow StyledTableRow key={i + 1}>
							<StyledTableCell>{i + 1}</StyledTableCell>
							<StyledTableCell>{data?.materialId?.name}</StyledTableCell>
							{/* <StyledTableCell>{data?.category}</StyledTableCell> */}
							<StyledTableCell>{data?.unitId?.name}</StyledTableCell>
							<StyledTableCell>{data?.quantity}</StyledTableCell>
							<StyledTableCell>{data?.requestedQuantity || data?.quantity}</StyledTableCell>
							<StyledTableCell>{data?.price}</StyledTableCell>
							{/* <StyledTableCell>{data?.decorpotPrice}</StyledTableCell> */}
							{/* <StyledTableCell>{data?.file}</StyledTableCell> */}
							{/* <StyledTableCell>{data?.remark}</StyledTableCell> */}
						</StyledTableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<div>
				<Dialog open={deleteOpenModel} onClose={() => setDeleteOpenModel(false)} aria-labelledby="form-dialog-title" maxWidth='sm'>
					<DialogTitle id="form-dialog-title">This Action cannot be Reverted!</DialogTitle>
					<DialogActions>
						<Button variant="outlined" onClick={() => setDeleteOpenModel(false)} color="secondary">
							Cancel
						</Button>
						<Button onClick={deleteSoConfirm} variant="outlined" color="primary">
							Submit
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		</div >
	)
}

export default connect(null, { setLoading, setSnackInfo })(SoDetailsModal)
