import React, { useState, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { setLoading, setSnackInfo } from '../../../redux/actions/appAction'
import { Table, TableBody, TextField, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { getAllMaterials } from '../../../redux/actions/vendorAction';
import HttpService from '../../../services/httpService';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 850,
  }
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#ccc",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MasterManagement = (props) => {
  const classes = useStyles();

  const [rooms, setRooms] = useState([])
  useEffect(() => {
    getAllRooms()
    // eslint-disable-next-line 
  }, [])

  const getAllRooms = async () => {
    try {
      const result = await HttpService.getAllRooms()
      setRooms(result.data)
    } catch (error) {
      console.error(error)
      props.setLoading(false)
      props.setSnackInfo('Error fetching categories', "error")

    }
  }
  const [showAddCategoryModal, setShowAddCategoryModal] = useState(false)
  const { allMaterials } = useSelector(state => state.vendor)
  const [categoryDetails, setCategoryDetails] = useState({
    id: "",
    name: "",
    description: "",
    priority: ""
  })
  const { name, description, priority } = categoryDetails
  const [selectedMaterial, setSelectedMaterial] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState("")

  const handleDialogClose = () => {
    setCategoryDetails({ name: "", description: "", priority: "" })
    setSelectedMaterial([])
    setShowAddCategoryModal(false)
  }

  const handleMaterialSelect = (v) => {
    setSelectedMaterial(v)
  }

  const addCategory = async () => {
    props.setLoading(true)
    try {
      let obj = {
        name,
        description,
        priority,
        products: selectedMaterial.map(material => material._id)
      }
      const response = await HttpService.addCategory(obj)
      props.setSnackInfo(response.data, "success")
      handleDialogClose()
      getAllRooms()
    } catch (error) {
      console.log(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  const editRoom = (data) => {
    setCategoryDetails({ name: data?.name, description: data?.description, priority: data?.priority, id: data._id })
    setSelectedMaterial(data?.products)
    setShowAddCategoryModal(true)
    setIsEdit(true)
  }

  const updateCategory = async () => {
    props.setLoading(true)
    try {
      let obj = {
        name,
        description,
        priority,
        products: selectedMaterial.map(material => material._id)
      }
      const response = await HttpService.updateCategory(obj, categoryDetails.id)
      props.setSnackInfo(response.data, "success")
      handleDialogClose()
      getAllRooms()
      setIsEdit(false)
    } catch (error) {
      console.log(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  const deleteRoom = async () => {
    props.setLoading(true)
    try {
      await HttpService.deleteCategory(deleteId)
      setShowDeleteModal(false)
      setDeleteId("")
      getAllRooms()
      props.setSnackInfo("Deleted", "success")
    } catch (error) {
      console.log(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  const closeDeleteModal = () => { setShowDeleteModal(false); setDeleteId("") }

  return (
    <div>
      <div style={{ marginBottom: 10, fontSize: 18 }}><b>Manage Category</b></div>
      <div style={{ display: "flex", justifyContent: "flex-end" }} ><Button onClick={() => { props.getAllMaterials(); setShowAddCategoryModal(true); setIsEdit(false) }} variant="contained" color="primary" >Add Category</Button></div>
      <div style={{ marginBottom: 10 }}></div>
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Sl No</StyledTableCell>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Priority</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rooms?.map((data, i) => <StyledTableRow key={i + 1}>
              <StyledTableCell>{i + 1}</StyledTableCell>
              <StyledTableCell>{data?.name}</StyledTableCell>
              <StyledTableCell>{data?.priority}</StyledTableCell>
              <StyledTableCell>
                <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                  <Tooltip title="Edit"><EditOutlined style={{ cursor: "pointer" }} onClick={() => editRoom(data)} /></Tooltip>
                  <Tooltip title="Delete" onClick={() => { setShowDeleteModal(true); setDeleteId(data?._id) }}><DeleteOutlined /></Tooltip>
                </div>
              </StyledTableCell>
            </StyledTableRow>)}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={showAddCategoryModal}
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth={'sm'}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{`${isEdit ? 'Edit' : 'Add New'} Category`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <TextField label="Name" name="name" value={name} style={{ marginBottom: 10 }} fullWidth onChange={(e) => setCategoryDetails({ ...categoryDetails, [e.target.name]: e.target.value })} variant="outlined" />
            <TextField label="Description" name="description" value={description} style={{ marginBottom: 10 }} fullWidth onChange={(e) => setCategoryDetails({ ...categoryDetails, [e.target.name]: e.target.value })} variant="outlined" />
            <TextField label="Priority" name="priority" type="number" value={priority} style={{ marginBottom: 10 }} fullWidth onChange={(e) => setCategoryDetails({ ...categoryDetails, [e.target.name]: e.target.value })} variant="outlined" />
            <Autocomplete
              multiple
              value={selectedMaterial}
              id="tags-standard"
              options={allMaterials}
              onChange={(e, v) => handleMaterialSelect(v)}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} fullWidth label="Select Materials" variant="outlined" />
              )}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {!isEdit ? <Button color="primary" onClick={addCategory}>
            Add
          </Button> : <Button color="primary" onClick={updateCategory}>
            Update
          </Button>}
        </DialogActions>

      </Dialog>

      <Dialog
        open={showDeleteModal}
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth={'sm'}
        onClose={closeDeleteModal}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Delete Category</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={closeDeleteModal}>
            Cancel
          </Button>
          <Button color="primary" onClick={() => deleteRoom()}>
            Delete
          </Button>
        </DialogActions>

      </Dialog>
    </div >
  )
}

export default connect(null, { setLoading, setSnackInfo, getAllMaterials })(MasterManagement)
