import React, { useState, useEffect } from 'react'
import { setLoading, setSnackInfo } from '../../../redux/actions/appAction'
import HttpService from '../../../services/httpService'
import { connect } from 'react-redux'
import { useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import TableHead from '@material-ui/core/TableHead';
import { DownloadOutlined, DeleteOutlined } from '@ant-design/icons';
import moment from 'moment'

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles({
  table: {
    minWidth: 500,
  },
  textField: {
    width: "150px"
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#ccc",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const OpenPo = (props) => {

  const classes = useStyles2();
  useEffect(() => {
    getAllOpenProcurements()
    // eslint-disable-next-line
  }, [])

  const [allOpenPo, setAllOpenPo] = useState([])
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dupAllOpnePo, setDupAllOpenPo] = useState([])

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, allOpenPo.length - page * rowsPerPage)
  const { userDetails } = useSelector(state => state.user)
  const getAllOpenProcurements = async () => {
    props?.setLoading(true)
    try {
      const response = await HttpService.getAllOpenProcurements()
      setAllOpenPo(response.data)
      setDupAllOpenPo(response.data)
    } catch (error) {
      console.error(error)
      props?.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props?.setLoading(false)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [orderId, setorderId] = useState("")
  const filterOpenPo = (e) => {
    setorderId(e.target.value)
    setAllOpenPo(dupAllOpnePo.filter(openPo => openPo.orderId.toLowerCase().includes(e.target.value.toLowerCase())))
  }

  const deleteOpenPo = async (id) => {
    props.setLoading(true)
    try {
      await HttpService.deleteProcurementById(id)
      props?.setSnackInfo('Deleted', "success")
    } catch (error) {
      console.error(error)
      props?.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props.setLoading(false)
    getAllOpenProcurements()
  }

  return (

    <div >
      <div style={{ display: "flex", marginBottom: 10 }}>
        <TextField label="Search For Order Id" name="orderId" value={orderId} onChange={(e) => filterOpenPo(e)} variant="outlined" />
      </div>

      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Sl No</StyledTableCell>
              <StyledTableCell>Order Id</StyledTableCell>
              <StyledTableCell>Procurement type</StyledTableCell>
              <StyledTableCell>Created At</StyledTableCell>
              <StyledTableCell>Vendor Name</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? allOpenPo.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : allOpenPo
            ).map((data, i) => (
              <StyledTableRow key={i + 1}>
                <StyledTableCell >{i + 1}</StyledTableCell>
                <StyledTableCell >{data?.orderId}</StyledTableCell>
                <StyledTableCell >{data?.procurementType}</StyledTableCell>
                <StyledTableCell >{moment(data?.createdAt).format('DD-MM-YYYY')}</StyledTableCell>
                <StyledTableCell >{data?.vendorId?.name}</StyledTableCell>
                <StyledTableCell >{data?.status}</StyledTableCell>
                <StyledTableCell >
                  <StyledTableRow >
                    <StyledTableCell >
                      <DownloadOutlined onClick={() => window.open(data?.poLink)} />
                    </StyledTableCell>
                    {
                      userDetails?.email === "dk@decorpot.com" && <StyledTableCell >
                        <DeleteOutlined onClick={() => deleteOpenPo(data?._id)} />
                      </StyledTableCell>
                    }

                  </StyledTableRow>
                </StyledTableCell>
              </StyledTableRow>
            ))}

            {emptyRows > 0 && (
              <StyledTableRow style={{ height: 53 * emptyRows }}>
                <StyledTableCell colSpan={8} />
              </StyledTableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 20, 50, { label: 'All', value: -1 }]}
                colSpan={8}
                count={allOpenPo.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  )
}

export default connect(null, { setLoading, setSnackInfo })(OpenPo)
