import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { setLoading, setSnackInfo } from '../redux/actions/appAction';
import HttpService from '../services/httpService';
import { makeStyles } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useSelector } from 'react-redux';
import SpellcheckIcon from '@material-ui/icons/Spellcheck';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Table from '@material-ui/core/Table';
import {
    Paper,
    Grid,
    FormControl,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    TextField,
    Button
} from '@material-ui/core';
import EditDialog from './editDialog'; // Import your dialog component

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

const LeadCriteria = (props) => {
    const { userDetails } = useSelector(state => state.user)

    const classes = useStyles();
    const [stores, setStores] = useState([]);
    const [criteria, setCriteria] = useState([])
    const [selectedStoreId, setSelectedStoreId] = useState('');
    const [scope, setScope] = useState('');
    const [budget, setBudget] = useState('');
    const [timeline, setTimeline] = useState('');
    const [open, setOpen] = useState(false);
    const [selectedCity, setSelectedCity] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [editdialogOpen, seteditDialogOpen] = useState(false);
    const [selectedData, setSelectedData] = useState({});




    const getExpCenterLocation = async () => {
        let res = await HttpService.getLocationList();
        if (res.status == 200) {
            getLeadCriteria()
        }
        setStores(res.data);
    };

    useEffect(() => {
        getExpCenterLocation();
    }, []);

    const handleStoreChange = (value) => {
        setSelectedStoreId(value._id);
    };

    const handleSubmit = async () => {
        try {
            let data = {
                createdBy: userDetails?._id,
                city: selectedStoreId,
                scope,
                budget,
                timeline
            };
            let res = await HttpService.creteLeadCriteria(data);
            if (res.status === 201) {
                setOpen(false);
                getExpCenterLocation()
                setBudget('');
                setTimeline('');
                setScope('')
            }
        } catch (error) {
            console.log(error, "this is called")
        }
    }


    const getLeadCriteria = async () => {
        let res = await HttpService.getLeadCriteria()
        setCriteria(res.data)
    }
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        getExpCenterLocation();
        setOpen(false)
        seteditDialogOpen(false);
    };

    const handleEditClick = (data) => {
        setSelectedData(data);
        seteditDialogOpen(true);
    };
    return (
        <Paper className={classes.paper}>
            <div style={{ marginBottom: "2rem", display: "flex", justifyContent: "flex-end" }}>
                <Button variant="contained" color="primary" onClick={handleClickOpen}>
                    Add Exp Center
                </Button>
                <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title" style={{textAlign : "center"}}>Add Lead Criteria According to City Wise</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    select
                                    labelId="demo-simple-select-label"
                                    id="store-select-label"
                                    value={selectedStoreId.name}
                                    label="Select Exp"
                                    name="selectedStoreId"
                                    onChange={(e) => handleStoreChange(e.target.value)}
                                    variant="outlined"
                                    size='small'
                                    fullWidth
                                >
                                    {stores.map((el) => (
                                        <MenuItem key={el._id} value={el}>{el.name}</MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Create Scope"
                                    variant="outlined"
                                    fullWidth
                                    value={scope}
                                    size='small'
                                    onChange={(e) => setScope(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Create Budget"
                                    variant="outlined"
                                    fullWidth
                                    value={budget}
                                    size='small'
                                    onChange={(e) => setBudget(e.target.value.toString())}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Timeline"
                                    variant="outlined"
                                    fullWidth
                                    value={timeline}
                                    size='small'
                                    onChange={(e) => setTimeline(e.target.value.toString())}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleSubmit} color="primary">
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>

            <TableContainer component={Paper}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }}>Sr. No.</TableCell>
                            <TableCell style={{ textAlign: "center" }}>City</TableCell>
                            <TableCell style={{ textAlign: "center" }}>Scope</TableCell>
                            <TableCell style={{ textAlign: "center" }}>Budget</TableCell>
                            <TableCell style={{ textAlign: "center" }}>Expected time to start</TableCell>
                            <TableCell style={{ textAlign: "center" }}>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {criteria?.map((el, i) => (
                            <TableRow key={i}>
                                <TableCell>{i + 1}</TableCell>
                                <TableCell style={{ border: '1px solid rgba(238,238,238,255)' , textAlign : "center"}}>{el.city}</TableCell>
                                <TableCell style={{ border: '1px solid rgba(238,238,238,255)', borderBottom: '0px', padding: 0 }}>
                                    {el?.scopeData?.map((item, index) => (
                                        <React.Fragment key={index}>
                                            {item.name && item.name !== "" && item.name !== null && (
                                                <div style={{ padding: "0.5rem" }}>
                                                    <FiberManualRecordIcon style={{ fontSize: "12px" }} /> {item.name}
                                                </div>
                                            )}
                                        </React.Fragment>
                                    ))}


                                </TableCell>

                                <TableCell style={{ border: '1px solid rgba(238,238,238,255)', padding: 0 }}>
                                    {el?.budgets?.map((item, index) => (
                                        <>
                                            <>
                                                {item.budget && item.budget !== "" && item.budget !== null && (
                                                    <div style={{ padding: "0.5rem" }}>
                                                        <FiberManualRecordIcon style={{ fontSize: "12px" }} />  {item.budget}
                                                    </div>
                                                )}
                                            </>
                                        </>
                                    ))}
                                </TableCell>
                                <TableCell style={{ border: '1px solid rgba(238,238,238,255)', padding: 0 }}>
                                    {el?.timelines?.map((item, index) => (
                                        <>
                                            {item.timeline && item.timeline !== "" && item.timeline !== null && (
                                                <div style={{ padding: "0.5rem" }}>
                                                    <FiberManualRecordIcon style={{ fontSize: "12px" }} />  {item.timeline}

                                                </div>
                                            )}
                                        </>
                                    ))}

                                </TableCell>
                                <TableCell style={{ border: '1px solid rgba(238,238,238,255)', borderBottom: '0px', padding: 0, textAlign: "center" }} onClick={() => handleEditClick(el)}>
                                    <SpellcheckIcon />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

            </TableContainer>
            {
                editdialogOpen ?
                    <EditDialog open={editdialogOpen} handleClose={handleClose} data={selectedData} />
                    : ""
            }


        </Paper>

    );
};

export default connect(null, { setLoading, setSnackInfo })(LeadCriteria);
