import React, { useState, useEffect } from 'react'
import { setLoading, setSnackInfo } from '../../../redux/actions/appAction'
import { connect } from 'react-redux'
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { Typography, Paper, Button, TextField } from '@material-ui/core';
import HttpService from '../../../services/httpService'
import Table from '@material-ui/core/Table';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import FavoriteIcon from '@material-ui/icons/Favorite';
import Box from '@material-ui/core/Box';
import '../style.css';
import backImage from '../../../assets/img/org/Pink-Blue-Texture.jpeg';
import backImage1 from '../../../assets/img/org/white-texture.webp';
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    textFieldWidth: {
        width: "100%"
    },
    junkui :{
        background:'#673ab7',
        marginTop:"20px",
        height:"100%"
    },
    table: {
        minWidth: 700,
    },
    pos: {
        marginBottom: 12,
    },
    container: {
        maxHeight: 450,
    }
}))

const JunkForm = (props) => {
    const classes = useStyles();
    const history = useHistory();

    useEffect(() => {
        if (props.LEADOWNERDATA.leadId) {
            findExistingLead(props.LEADOWNERDATA.leadId)
        }
        // eslint-disable-next-line
    }, [props.LEADOWNERDATA, props.TYPEOPTION])

    const findExistingLead = async (leadId) => {
        props.setLoading(true)
        try {
            const isExistsLead = await HttpService.findExistingCustomerSurveyLead(leadId, 'junk')
            if (isExistsLead.data.length !== 0) {
                history.push('/thank-you');
            }
        } catch (error) {
            // props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
            console.log(error)
        }
        props.setLoading(false)
    }
    const [satisfactionIndexRatioList, setSatisfactionIndexRatioList] = useState([
        { id: 1, description: 'Clarity of communication', 1: '', 2: '', 3: '', 4: '', 5: '', 6: '', 7: '', 8: '', 9: '', 10: '' },
        { id: 2, description: 'Understanding of requirements', 1: '', 2: '', 3: '', 4: '', 5: '', 6: '', 7: '', 8: '', 9: '', 10: '' },
        { id: 3, description: 'Promptness in response', 1: '', 2: '', 3: '', 4: '', 5: '', 6: '', 7: '', 8: '', 9: '', 10: '' },
        { id: 4, description: 'Professionalism', 1: '', 2: '', 3: '', 4: '', 5: '', 6: '', 7: '', 8: '', 9: '', 10: '' }
    ]);

    const [junkFormData, setJunkFormData] = useState({
        satisfactionIndexRatio: [],
        whenFlatAvailbleForInterior: "",
        budgetForInteriors: "",
        feedback: "",
        conveyedMessage: ""
    })
    const [disabledBtn, setDisabledBtn] = useState(true);
    const satisfactionIndexRadio = (event, index, data) => {
        const newSatisfactionIndexRatioList = [...satisfactionIndexRatioList];

        newSatisfactionIndexRatioList[index].actionSelected = event.target.value;
        newSatisfactionIndexRatioList[index].sortId = data.id ? data.id : data.sortId;
        newSatisfactionIndexRatioList[index].description = data.description;
        delete newSatisfactionIndexRatioList[index]['1'];
        delete newSatisfactionIndexRatioList[index]['2'];
        delete newSatisfactionIndexRatioList[index]['3'];
        delete newSatisfactionIndexRatioList[index]['4'];
        delete newSatisfactionIndexRatioList[index]['5'];
        delete newSatisfactionIndexRatioList[index]['6'];
        delete newSatisfactionIndexRatioList[index]['7'];
        delete newSatisfactionIndexRatioList[index]['8'];
        delete newSatisfactionIndexRatioList[index]['9'];
        delete newSatisfactionIndexRatioList[index]['10'];

        setJunkFormData({ ...junkFormData, satisfactionIndexRatio: newSatisfactionIndexRatioList })

        let countCheck = 0;
        newSatisfactionIndexRatioList.forEach((ele, i) => {
            if (ele.hasOwnProperty('actionSelected')) {
                countCheck++
            }
        })
        if (newSatisfactionIndexRatioList.length === countCheck) {
            setDisabledBtn(false);
        } else {
            setDisabledBtn(true)
        }
    }

    const submitJunkForm = async () => {
        let obj = {
            ...junkFormData,
            leadOwner: props.LEADOWNERDATA,
            typeOption: props.TYPEOPTION
        }
        props.setLoading(true)
        try {
            await HttpService.submitSurveyForm(obj)
            history.push('/thank-you');
        } catch (error) {
            props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    return (
        <>
            <Paper className={classes.junkui}>
                <Typography variant="h6" gutterBottom style={{ marginLeft: 10, paddingTop: 20, fontSize: 27, fontWeight: 700 ,color:"white"}}>
                    Customer Satisfaction Survey - Pre-sales Process.
                </Typography>
                <Typography variant='subtitle2' gutterBottom style={{ marginLeft: 10, fontWeight: 550,color:"white" }}>
                    <span className="step1">CRM</span><span style={{marginLeft:3}}>-{'>'}</span> Design expert<span style={{marginLeft:3}}>-{'>'}</span> Designer -{'>'} Operations & Production -{'>'} Site Execution -{'>'} Beautiful home delivered.
                </Typography>
                <Typography variant='subtitle2' gutterBottom style={{ marginLeft: 10, marginTop: 15, fontWeight: 550 ,color:"white" }}>
                    Thank you for choosing Decorpot. It is our great pleasure to provide you the best quality of service at all stages.
                </Typography>
                <Typography variant='subtitle2' gutterBottom style={{ marginLeft: 10, marginTop: 15, fontWeight: 550 ,color:"white"}}>
                    However, we want to inform you that your lead is not qualified due to one of the below mentioned criteria.
                </Typography>
                <Typography variant='subtitle2' gutterBottom style={{ marginLeft: 10, fontWeight: 550,color:"white" }}>
                    Your assistance in completing this form is greatly appreciated. Your valuable feedback will help us to serve you better and enable us to work on improving our service standards.
                </Typography>
                <Typography variant='subtitle2' gutterBottom style={{ marginLeft: 10, marginTop: 15, fontWeight: 550,color:"white" }}>
                    Thank you.
                </Typography>
            </Paper>

            <Paper style={{ marginTop: 20, padding: 10, borderTop: "8px solid #673ab7",borderBottom:"8px solid rgb(93, 65, 173"}}>
                <div style={{ padding: 10 }}>

                <FormControl component="fieldset" style={{ marginBottom: 50, marginTop: 50 }}>
                        <FormLabel component="legend" style={{ color: "#000" }}>Satisfaction index -</FormLabel>
                        <FormLabel component="legend" style={{ color: "#000" ,marginTop:"30px" }}> <span className='satisfiedDetail'> </span> <span style={{ marginLeft: "10px" ,marginTop:"70px" }}>Dissatisfied </span>
                            <span className='DissatisfiedDetail'></span>
                            <span style={{ marginLeft: "10px" ,marginTop:"70px" }}> Satisfied</span> </FormLabel>
                    </FormControl>
                   <div className='junkDesktop'>
                    <Table striped bordered hover style={{width:"70%", marginLeft:"20px", borderCollapse:"collapse"}}>
        <tbody>
        <tr>
                <RadioGroup defaultValue="abc" aria-label="action" name="customized-radios" style={{ display: 'table-cell'}}>
                <td colSpan={10}  style={{ border: '1px solid lightgray', fontWeight: 500, width: "20rem", height: "4"}}></td>
                <td style={{ border: '1px solid lightgray',  textAlign: 'center', fontWeight: 700, width: "3rem", height: "4",  backgroundColor: "#e72600"}}><span style={{color: "#fff"}}> 1 </span></td>
                <td style={{ border: '1px solid lightgray',  textAlign: 'center', fontWeight: 700, width: "3rem", height: "4",  backgroundColor: "#f96500"}}><span style={{color: "#fff"}}> 2 </span></td>
                <td style={{ border: '1px solid lightgray',  textAlign: 'center', fontWeight: 700, width: "3rem", height: "4",  backgroundColor: "#ffe000"}}><span style={{color: "#fff"}}> 3 </span></td>
                <td style={{ border: '1px solid lightgray',  textAlign: 'center', fontWeight: 700, width: "3rem", height: "4",  backgroundColor: "#30ad00"}}><span style={{color: "#fff"}}> 4 </span></td>
                <td style={{ border: '1px solid lightgray',  textAlign: 'center', fontWeight: 700, width: "3rem", height: "4",  backgroundColor: "#218b00"}}><span style={{color: "#fff"}}> 5 </span></td>
                </RadioGroup>
                </tr>
                </tbody>
                                         {satisfactionIndexRatioList.sort().map((row, i) => {
                                                const labelId = `enhanced-table-checkbox-${i}`;
                                                return (

                                                        <RadioGroup aria-label='option' name="customized-radios"
                                                            value={row?.actionSelected}
                                                            onChange={(e) => satisfactionIndexRadio(e, i, row)}
                                                            style={{ display: 'block' }}>
                                                            <tr>
                                                      <td colSpan={10}  style={{ border: '1px solid lightgray', fontWeight: 500, width: "20rem", height: "4"}}> {row?.description} </td>
                                                       <td style={{ border: '1px solid lightgray',  textAlign: 'center', fontWeight: 700, width: "3rem", height: "4"}}> 
                                                                <Radio
                                                                    value='1'
                                                                    checked={row?.actionSelected === '1'}
                                                                    style={{
                                                                        backgroundColor: row?.actionSelected === '1' ? '#fde6f0' : '',
                                                                        borderRadius: row?.actionSelected === '1' ? '0px' : ''
                                                                    }}
                                                                    name="radio-button-demo"
                                                                    inputProps={{ 'aria-label': labelId }}
                                                                />
                                                            </td>
                                                             <td style={{ border: '1px solid lightgray',  textAlign: 'center', fontWeight: 700, width: "3rem", height: "4"}}> 
                                                                <Radio
                                                                    value='2'
                                                                    checked={row?.actionSelected === '2'}
                                                                    name="radio-button-demo"
                                                                    inputProps={{ 'aria-label': labelId }}
                                                                    style={{
                                                                        backgroundColor: row?.actionSelected === '2' ? '#fde6f0' : '',
                                                                        borderRadius: row?.actionSelected === '2' ? '0px' : ''
                                                                    }}
                                                                />
                                                            </td>
                                                            <td style={{ border: '1px solid lightgray',  textAlign: 'center', fontWeight: 700, width: "3rem", height: "4"}}> 
                                                                <Radio
                                                                    value='3'
                                                                    checked={row?.actionSelected === '3'}
                                                                    name="radio-button-demo"
                                                                    inputProps={{ 'aria-label': labelId }}
                                                                    style={{
                                                                        backgroundColor: row?.actionSelected === '3' ? '#fde6f0' : '',
                                                                        borderRadius: row?.actionSelected === '3' ? '0px' : ''
                                                                    }}
                                                                />
                                                            </td>
                                                            <td style={{ border: '1px solid lightgray',  textAlign: 'center', fontWeight: 700, width: "3rem", height: "4"}}> 
                                                                <Radio
                                                                    value='4'
                                                                    checked={row?.actionSelected === '4'}
                                                                    name="radio-button-demo"
                                                                    inputProps={{ 'aria-label': labelId }}
                                                                    style={{
                                                                        backgroundColor: row?.actionSelected === '4' ? '#fde6f0' : '',
                                                                        borderRadius: row?.actionSelected === '4' ? '0px' : ''
                                                                    }}
                                                                />
                                                            </td>
                                                            <td style={{ border: '1px solid lightgray',  textAlign: 'center', fontWeight: 700, width: "3rem", height: "4"}}> 
                                                                <Radio
                                                                    value='5'
                                                                    checked={row?.actionSelected === '5'}
                                                                    name="radio-button-demo"
                                                                    inputProps={{ 'aria-label': labelId }}
                                                                    style={{
                                                                        backgroundColor: row?.actionSelected === '5' ? '#fde6f0' : '',
                                                                        borderRadius: row?.actionSelected === '5' ? '0px' : ''
                                                                    }}
                                                                />
                                                            </td> 
                                                            </tr>
                                                        </RadioGroup>
                                                );
                                            })}
                 <tr> 
                <RadioGroup aria-label='option' name="customized-radios" style={{ display: 'table-cell' }}>
                <td colSpan={10}  style={{ fontWeight: 500, width: "20rem", height: "4"}}></td>
                <td style={{textAlign: 'center', fontWeight: 700, width: "3rem", height: "4rem"}}><ThumbDownIcon style={{ color: "#e72600" }} /></td>
                <td style={{textAlign: 'center', fontWeight: 700, width: "3rem", height: "4rem"}}></td>
                <td style={{textAlign: 'center', fontWeight: 700, width: "3rem", height: "4rem"}}></td>
                <td style={{textAlign: 'center', fontWeight: 700, width: "3rem", height: "4rem"}}></td>
                <td style={{textAlign: 'center', fontWeight: 700, width: "3rem", height: "4rem"}}><ThumbUpIcon style={{ color: "#218b00", marginLeft: 11 }} /></td>
                </RadioGroup>
                </tr>
                </Table>
                </div>
                <div className='junkPhone'>
                <Table striped bordered hover style={{width:"100%", marginLeft:"20px", borderCollapse:"collapse"}}>
        <tbody>
        <tr>
        <RadioGroup defaultValue="abc" aria-label="action" name="customized-radios" style={{ display: 'table-cell', top: 0, left: 0, zIndex: 2, position: 'sticky' }}>                
                <td colSpan={4}  style={{ border: '1px solid lightgray', fontWeight: 500, width: "8rem", height: "4"}}></td>
                <td style={{ border: '1px solid lightgray',  textAlign: 'center', fontWeight: 700, width: "3rem", height: "2rem" , backgroundColor: "#e72600"}}><span style={{color: "#fff" , marginTop:"20px"}}> 1 </span></td>
                <td style={{ border: '1px solid lightgray',  textAlign: 'center', fontWeight: 700, width: "3rem", height: "2rem",  backgroundColor: "#f96500"}}><span style={{color: "#fff"}}> 2 </span></td>
                <td style={{ border: '1px solid lightgray',  textAlign: 'center', fontWeight: 700, width: "3rem", height: "2rem",  backgroundColor: "#ffe000"}}><span style={{color: "#fff"}}> 3 </span></td>
                <td style={{ border: '1px solid lightgray',  textAlign: 'center', fontWeight: 700, width: "3rem", height: "2rem",  backgroundColor: "#30ad00"}}><span style={{color: "#fff"}}> 4 </span></td>
                <td style={{ border: '1px solid lightgray',  textAlign: 'center', fontWeight: 700, width: "3rem", height: "2rem",  backgroundColor: "#218b00"}}><span style={{color: "#fff"}}> 5 </span></td>
                </RadioGroup>
                </tr>
                </tbody>
                                         {satisfactionIndexRatioList.sort().map((row, i) => {
                                                const labelId = `enhanced-table-checkbox-${i}`;
                                                return (
                                                    <tr>
                                                    <RadioGroup aria-label='option' name="customized-radios" value={row?.actionSelected}  onChange={(e) => satisfactionIndexRadio(e, i, row)} style={{ display: 'block' }}>
                                                        <td colSpan={10}  style={{ border: '1px solid lightgray', fontWeight: 500, width:"8rem", height: "4"}}> {row?.description} </td>
                                                        <td style={{ border: '1px solid lightgray',  textAlign: 'center', fontWeight: 700, width: "3rem", height: "4"}}> 
                                                                                <Radio
                                                                                    value='1'
                                                                                    checked={row?.actionSelected === '1'}
                                                                                    style={{
                                                                                        backgroundColor: row?.actionSelected === '1' ? '#fde6f0' : '',
                                                                                        borderRadius: row?.actionSelected === '1' ? '0px' : ''
                                                                                    }}
                                                                                    name="radio-button-demo"
                                                                                    inputProps={{ 'aria-label': labelId }}
                                                                                />
                                                                            </td>
                                                        <td style={{ border: '1px solid lightgray',  textAlign: 'center', fontWeight: 700, width: "3rem", height: "4"}}>                                                                 
                                                                                   <Radio
                                                                                    value='2'
                                                                                    checked={row?.actionSelected === '2'}
                                                                                    name="radio-button-demo"
                                                                                    inputProps={{ 'aria-label': labelId }}
                                                                                    style={{
                                                                                        backgroundColor: row?.actionSelected === '2' ? '#fde6f0' : '',
                                                                                        borderRadius: row?.actionSelected === '2' ? '0px' : ''
                                                                                    }}
                                                                                />
                                                                            </td>
                                                        <td style={{ border: '1px solid lightgray',  textAlign: 'center', fontWeight: 700, width: "3rem", height: "4"}}>                                                                 
                                                        <Radio
                                                                                    value='3'
                                                                                    checked={row?.actionSelected === '3'}
                                                                                    name="radio-button-demo"
                                                                                    inputProps={{ 'aria-label': labelId }}
                                                                                    style={{
                                                                                        backgroundColor: row?.actionSelected === '3' ? '#fde6f0' : '',
                                                                                        borderRadius: row?.actionSelected === '3' ? '0px' : ''
                                                                                    }}
                                                                                />
                                                                            </td>
                                                        <td style={{ border: '1px solid lightgray',  textAlign: 'center', fontWeight: 700, width: "3rem", height: "4"}}>                                                                 
                                                        <Radio
                                                                                    value='4'
                                                                                    checked={row?.actionSelected === '4'}
                                                                                    name="radio-button-demo"
                                                                                    inputProps={{ 'aria-label': labelId }}
                                                                                    style={{
                                                                                        backgroundColor: row?.actionSelected === '4' ? '#fde6f0' : '',
                                                                                        borderRadius: row?.actionSelected === '4' ? '0px' : ''
                                                                                    }}
                                                                                />
                                                                            </td>
                                                        <td style={{ border: '1px solid lightgray',  textAlign: 'center', fontWeight: 700, width: "3rem", height: "4"}}>                                                                 
                                                        <Radio
                                                                                    value='5'
                                                                                    checked={row?.actionSelected === '5'}
                                                                                    name="radio-button-demo"
                                                                                    inputProps={{ 'aria-label': labelId }}
                                                                                    style={{
                                                                                        backgroundColor: row?.actionSelected === '5' ? '#fde6f0' : '',
                                                                                        borderRadius: row?.actionSelected === '5' ? '0px' : ''
                                                                                    }}
                                                                                />
                                                                            </td> 
                                                                            </RadioGroup>
                                                                            </tr>
                                                );
                                            })}
                 <tr> 
                <RadioGroup aria-label='option' name="customized-radios" style={{ display: 'table-cell' }}>
                <td colSpan={5}  style={{ fontWeight: 500, width: "8rem", height: "4"}}></td>
                <td style={{textAlign: 'center', fontWeight: 700, width: "3rem", height: "4rem"}}><ThumbDownIcon style={{ color: "#e72600" }} /></td>
                <td style={{textAlign: 'center', fontWeight: 700, width: "3rem", height: "4rem"}}></td>
                <td style={{textAlign: 'center', fontWeight: 700, width: "3rem", height: "4rem"}}></td>
                <td style={{textAlign: 'center', fontWeight: 700, width: "3rem", height: "4rem"}}></td>
                <td style={{textAlign: 'center', fontWeight: 700, width: "3rem", height: "4rem"}}><ThumbUpIcon style={{ color: "#218b00", marginLeft: 11 }} /></td>
                </RadioGroup>
                </tr>
                </Table> 
                </div>



                    <br />
                    <FormControl component="fieldset" style={{ marginBottom: 20, marginTop: 20 }}>
                        <FormLabel component="legend" style={{ color: "#000" }}>When will your Flat / house be available for interiors? * </FormLabel>
                        <RadioGroup aria-label="flatAvailble" name="whenFlatAvailbleForInterior"
                            onChange={(e) => setJunkFormData({ ...junkFormData, [e.target.name]: e.target.value })}>
                            <FormControlLabel value="More-than-3-months" control={<Radio color="primary" />} label="> 3 months (More than 3 months)" />
                            <FormControlLabel value="Less-than-3-months" control={<Radio color="primary" />} label="< 3 months (Less than 3 months)" />
                        </RadioGroup>
                    </FormControl>
                    <br />
                    <FormControl component="fieldset" style={{ marginBottom: 20, marginTop: 20 }}>
                        <FormLabel component="legend" style={{ color: "#000" }}>Please select the Budget for your interiors * </FormLabel>
                        <RadioGroup aria-label="budget" name="budgetForInteriors"
                            onChange={(e) => setJunkFormData({ ...junkFormData, [e.target.name]: e.target.value })}>
                            <FormControlLabel value="Above-3-lakhs" control={<Radio color="primary" />} label="> 3lakhs (Above 3 lakhs)" />
                            <FormControlLabel value="Below-3-lakhs" control={<Radio color="primary" />} label="< 3lakhs (Below 3 lakhs))" />
                        </RadioGroup>
                    </FormControl>
                    <br />
                    <TextField
                        label="Additional comments *"
                        size="small"
                        style={{ marginBottom: 20, textAlign: "center", marginTop: 20, width: "100%", backgroundColor: "#f8f1fb" }}
                        name="feedback"
                        multiline
                        rows={3}
                        onChange={(e) => setJunkFormData({ ...junkFormData, [e.target.name]: e.target.value })}
                        variant="outlined"
                    />
                    <br />
                    {
                        (junkFormData.whenFlatAvailbleForInterior === "Less-than-3-months" && junkFormData.budgetForInteriors === "Below-3-lakhs") &&
                        <>
                            <Typography variant='subtitle2' gutterBottom style={{ marginTop: 15, marginBottom: 20 }}>
                                We apologize that we are not able to engage with you in your home interior journey at this stage.
                            </Typography>
                        </>
                    }
                </div>
            </Paper>

            <Paper style={{ marginTop: 20, padding: 10 }}>
                <Button onClick={() => submitJunkForm()}
                    disabled={junkFormData.whenFlatAvailbleForInterior === '' || junkFormData.budgetForInteriors === '' || junkFormData.feedback === '' || disabledBtn}
                    variant="contained"
                    color="primary"
                    style={{ width: "30%" }}>
                    Submit
                </Button>
            </Paper>
        </>
    )
}

export default connect(null, { setLoading, setSnackInfo })(JunkForm)