import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setLoading, setSnackInfo } from "../../redux/actions/appAction";
import HttpService from '../../services/httpService'
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import {
  Table,
  Button,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Checkbox,
} from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  table: {
    minWidth: 600,
  },
  updateButton: { backgroundColor: '#0b8072', color: 'white', padding: '1', marginRight: '0.5em', marginLeft: '0.5em', borderRadius: '20px', "&:hover": { backgroundColor: "#075E54", color: 'white' } }

});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#ccc",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const CxStageSetting = (props) => {
  const classes = useStyles();
  const [designNewStageList, setDesignNewStageList] = useState([]);
  const [customerActiveStage, setCustomerActiveStages] = useState([]);
  const [whatsAppNotificationStage, setWhatsAppNotificationStage] = useState([]);


  useEffect(() => {
    getDesignNewStagesList();
  }, [])

  const getDesignNewStagesList = async () => {
    props.setLoading(true);
    try {
      const department = await HttpService.getDepartments();
      department?.data && department?.data?.map((data) => {
        if (data?.name === 'Design') {
          const allStatus = data?.stages;
          allStatus.push('Design-Hold')
          setDesignNewStageList(allStatus);
          setCustomerActiveStages(data.customerStages);
          setWhatsAppNotificationStage(data.whatsAppNotificationStages);

        }
      })
    }
    catch (err) {
      console.error(err);
    }
    props.setLoading(false);
  }

  const handleCheckboxChange = (stage) => {
    props.setLoading(true);
    try{
      setCustomerActiveStages(prev => {
        if (prev.includes(stage)) {
          // Remove the stage if it is already checked
          return prev.filter(s => s !== stage);
        } else {
          // Add the stage if it is not checked
          return [...prev, stage];
        }
      });
     }
    catch(err){
      console.log(err);
    }
    props.setLoading(false);
  };

  const handleWhatsAppCheckboxChange = (stage) => {
    props.setLoading(true);
    try{
      setWhatsAppNotificationStage(prev => {
        if (prev.includes(stage)) {
          return prev.filter(s => s !== stage);
        } else {
          return [...prev, stage];
        }
      });
     }
    catch(err){
      console.log(err);
    }
    props.setLoading(false);
  };

  const handleCustomerDesignStages = async () => {
    props.setLoading(true);
    try {
      const obj = {
        customerStagesArray: customerActiveStage,
      };
      const data = await HttpService.updateCustomerStages(obj);
      if (data.status === 200) {
        props.setSnackInfo("Customer Stages Updated Successfully", "success");
      }
    } catch (err) {
      console.log("Something wents wrong", err);
    }
    props.setLoading(false);
  };

  const handleWhatsAppNotificationStages = async () => {
    props.setLoading(true);
    try {
      const obj = {
        whatsAppNotificationStagsArray: whatsAppNotificationStage,
      };
      const response = await HttpService.updateWhatsAppNotificationStages(obj);
      if (response.status === 200) {
        props.setSnackInfo(response.data, "success");
      }
    } catch (err) {
      console.log("Something went wrong", err);
    }
    
    props.setLoading(false);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: 10,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleCustomerDesignStages()}
        >
          Update Design Stages in Customer App
        </Button>
      </div>

      <TableContainer component={Paper}>
        <Table
          className={classes.table}
          size="small"
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell style={{ textAlign: "center" }}> Sl No. </StyledTableCell>
              <StyledTableCell style={{ textAlign: "center" }}> Name </StyledTableCell>
              <StyledTableCell style={{ textAlign: "center" }}> Active Stages In Customer App </StyledTableCell>
              <StyledTableCell style={{ textAlign: "center" }}>
                WhatsApp Notification Stages
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.updateButton}
                  onClick={() => handleWhatsAppNotificationStages()}
                >
                  Update
                </Button>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {designNewStageList?.length > 0 && designNewStageList.map((stage, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell style={{ textAlign: "center" }}>{index + 1}</StyledTableCell>
                <StyledTableCell style={{ textAlign: "center" }}>{stage}</StyledTableCell>
                <StyledTableCell style={{ textAlign: "center" }}> <Checkbox checked={customerActiveStage?.includes(stage)} onChange={() => handleCheckboxChange(stage, index)} /> </StyledTableCell>
                <StyledTableCell style={{ textAlign: "center" }}> <Checkbox checked={whatsAppNotificationStage?.includes(stage)} onChange={() => handleWhatsAppCheckboxChange(stage, index)} /> </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default connect(null, { setLoading, setSnackInfo })(CxStageSetting);