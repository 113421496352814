// /src/redux/actions/customerAction.js

import { SET_CUSTOMER_DETAILS, SET_SNACK, SET_SNACK_INFO } from './types';

export const setCustomerDetails = (data) => {
  return {
    type: SET_CUSTOMER_DETAILS,
    payload: data,
  };
};

export const setCustomerAction = (data) => async (dispatch) => {
  try {
    dispatch(setCustomerDetails(data));
  } catch (error) {
    dispatch({ type: SET_SNACK, payload: true });
    dispatch({ type: SET_SNACK_INFO, payload: { msg: error.response.data, snackSeverity: "error" } });
  }
};