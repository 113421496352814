import { combineReducers } from "redux";
import appReducer from "./appReducer";
import financeReducer from './financeReducer'
import projectReducer from './projectReducer'
import rolesReducer from './rolesReducer'
import vendorReducer from "./vendorReducer";
import userReducer from "./userReducer";
import customerReducer from "./customerReducer";

export default combineReducers({ app: appReducer, finance: financeReducer, project: projectReducer, roles: rolesReducer, vendor: vendorReducer, user: userReducer, customer: customerReducer });
