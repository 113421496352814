import React, { useState, useEffect, useCallback, useRef } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { setLoading, setSnackInfo } from "../redux/actions/appAction";
import HttpService from "../services/httpService";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import { useSelector } from 'react-redux';
import InfoIcon from '@material-ui/icons/Info';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  TableFooter,
  IconButton,
  Select,
} from "@material-ui/core";
import moment from "moment";
import TablePagination from "@material-ui/core/TablePagination";
import { Button, Tooltip } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { TextField } from "@material-ui/core";
import { CSVLink } from "react-csv";
import * as XLSX from 'xlsx';
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import EditIcon from "@material-ui/icons/Edit";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import { commaSeparateAmount } from './commaSeparate';
import { ConsoleSqlOutlined, DownloadOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import { paymentMode, paymentModeType } from "../utils";

const useStyles1 = makeStyles((theme) => ({
	root: {
		flexShrink: 0,
		marginLeft: theme.spacing(2.5),
	},
}));

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 850,
  },
  tableHeader:{
    position: 'sticky',
    top: 0,
    zIndex: 1
  },
  textField: {
    // marginLeft: theme.spacing(5),
    // marginRight: theme.spacing(5),
    width: 100,
  },
  bank_charges_fiels: {
    // marginLeft: theme.spacing(5),
    // marginRight: theme.spacing(5),
    width: 100,
  },
  gross_amount_field: {
    // marginLeft: theme.spacing(5),
    // marginRight: theme.spacing(5),
    width: 150,
  },
  ref_id_from_bank_field: {
    // marginLeft: theme.spacing(5),
    // marginRight: theme.spacing(5),
    width: 250,
  },
  paper: {
    padding: "2rem 2rem 2rem 2rem",
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  radioButton: {
    // padding:20,
    marginLeft: "20px",
  },
  heading: {
    padding: theme.spacing(2),
    margin: "auto",
    maxWidth:400,
    backgroundColor: "#2C2E3E",
    color: "white"
  },
  // heading: { margin: 'auto', textAlign: "center", color: "white", backgroundColor: "#2C2E3E", padding: 10, borderRadius: 50, width: "25%", fontSize: 25 },
  editButton: { backgroundColor: 'white', color: '#3d444f', padding: '1', marginRight: '0.5em', borderRadius: '20px', "&:hover": { backgroundColor: "#3d444f", color: 'white' } },
  approveButton: { backgroundColor: 'white', color: '#157525', padding: '1', marginRight: '0.5em', marginLeft: '0.5em', borderRadius: '20px', "&:hover": { backgroundColor: "#157525", color: 'white' } },
  rejectButton: { backgroundColor: 'white', color: '#9c261e', padding: '1', marginRight: '0.5em', borderRadius: '20px', "&:hover": { backgroundColor: "#9c261e", color: 'white' } },
  yesButton: { backgroundColor: 'white', color: '#14419c', padding: '1', marginRight: '0.5em', marginLeft: '0.5em', borderRadius: '20px', "&:hover": { backgroundColor: "#14419c", color: 'white' } },
  noButton: { backgroundColor: 'white', color: '#151921', padding: '1', marginRight: '0.5em', borderRadius: '20px', "&:hover": { backgroundColor: "#151921", color: 'white' } },
  updateButton: { backgroundColor: 'white', color: '#157525', padding: '1', margin: 'auto', borderRadius: '20px', "&:hover": { backgroundColor: "#157525", color: 'white' } },
  financeActionRemarks: { margin: 'auto', width: '100%' },
  downloadButton: { backgroundColor: 'white', color: '#14419c', padding: '1', marginRight: '0.5em',  marginBottom: '0.5em', marginTop: '0.5em',  marginLeft: '0.5em', borderRadius: '20px', "&:hover": { backgroundColor: "#14419c", color: 'white' } },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#ccc",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function TablePaginationActions(props) {
	const classes = useStyles1();
	const theme = useTheme();
	const { count, page, rowsPerPage, onPageChange } = props;

	const handleFirstPageButtonClick = (event) => {
		onPageChange(event, 0);
	};

	const handleBackButtonClick = (event) => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = (event) => {
		onPageChange(event, page + 1);
	};

	const handleLastPageButtonClick = (event) => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<div className={classes.root}>
			<IconButton
				onClick={handleFirstPageButtonClick}
				disabled={page === 0}
				aria-label="first page"
			>
				{theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton
				onClick={handleBackButtonClick}
				disabled={page === 0}
				aria-label="previous page"
			>
				{theme.direction === "rtl" ? (
					<KeyboardArrowRight />
				) : (
					<KeyboardArrowLeft />
				)}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
			>
				{theme.direction === "rtl" ? (
					<KeyboardArrowLeft />
				) : (
					<KeyboardArrowRight />
				)}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"
			>
				{theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</div>
	);
}

TablePaginationActions.propTypes = {
	count: PropTypes.number.isRequired,
	onPageChange: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
};

const ApprovalForManualTransaction = (props) =>
{
  const { userDetails } = useSelector(state => state.user)
  const classes = useStyles();
  
  const buttonRef = useRef(null);

  const [manualTransactionsForApproval, setmanualTransactionsForApproval] = useState([])
  
  // const getAllManualTransactionsForApproval = () =>
  // {
  //   let data = []
    
  //   let obj_1 = {}

  //   obj_1.customer_unique_id = 'customer_unique_id_1'
  //   obj_1.requester_name = 'requester_name_1'
  //   obj_1.mode_of_payment = 'mode_of_payment_1'
  //   obj_1.requested_amount = 100
  //   obj_1.reference_id = 'reference_id_1'
  //   obj_1.reference_id_from_bank = ''
  //   obj_1.gross_amount = null
  //   obj_1.bank_charges = null
  //   obj_1.gst_amount = null
  //   obj_1.net_amount_recieved = null
  //   obj_1.remarks = ''

  //   let obj_2 = {}

  //   obj_2.customer_unique_id = 'customer_unique_id_2'
  //   obj_2.requester_name = 'requester_name_2'
  //   obj_2.mode_of_payment = 'mode_of_payment_2'
  //   obj_2.requested_amount = 200
  //   obj_2.reference_id = 'reference_id_2'
  //   obj_2.reference_id_from_bank = ''
  //   obj_2.gross_amount = null
  //   obj_2.bank_charges = null
  //   obj_2.gst_amount = null
  //   obj_2.net_amount_recieved = null
  //   obj_2.remarks = ''

  //   let obj_3 = {}

  //   obj_3.customer_unique_id = 'customer_unique_id_3'
  //   obj_3.requester_name = 'requester_name_3'
  //   obj_3.mode_of_payment = 'mode_of_payment_3'
  //   obj_3.requested_amount = 150
  //   obj_3.reference_id = 'reference_id_3'
  //   obj_3.reference_id_from_bank = ''
  //   obj_3.gross_amount = null
  //   obj_3.bank_charges = null
  //   obj_3.gst_amount = null
  //   obj_3.net_amount_recieved = null
  //   obj_3.remarks = ''

  //   // data.push(obj_1)
  //   // data.push(obj_2)
  //   // data.push(obj_3)

  //   let 
  
  //   setmanualTransactionsForApproval(data)
  // }

  const [datefilter, setDatefilter] = useState({ startDate: "", endDate: "" });
  const [searchStageWise, setStageWiseFilter] = useState("");
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  // Function to handle the change in page
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  }

  // Function to handle change in rows per page
  const handleChangeRowsPerPage = (event) => {
    if (event.target.value === "All"){
      setRowsPerPage (parseInt(totalPages,10))
    }else{
      setRowsPerPage(parseInt(event.target.value, 10));
    }
		setPage(0);
  }

  // const getAllManualTransactionsForApproval = useCallback(async (searchQueries) =>
  const getAllManualTransactionsForApproval = useCallback(async (searchQueries) =>
    {
      // console.log('getAllManualTransactionsForApproval function called - - - - - - - - - - - - - - - - - - - - - - - - - - - - -');
      props.setLoading(true);
      
      const obj =
      {
        startDate: datefilter.startDate,
        endDate: datefilter.endDate,
        selectedPaymentSearch: searchStageWise.toString() ? searchStageWise.toString() : "",
        page: page + 1,
        searchCustomerName: searchQueries ? searchQueries : "",
        rowsPerPage: rowsPerPage,
      };

      try
      {
        // let result = await HttpService.getPaymentAllPreview(obj);

        let result = await HttpService.getAllManualTransactionsForApproval(obj);
        
        // console.log("Response Data -> ", result.data);
        
        setTotalPages(result.data.totalLength);

        let obj_1 = {}

        let data = result.data.paymentTransactions

        let temp_array = []

        for(let i = 0; i < data.length; i++)
        {
          let obj = {}

          obj._id = data[i]._id
          obj.lead_details = data[i].leadId
          obj.selectedPaymentModeType = data[i].selectedPaymentModeType
          obj.customer_unique_id = data[i]?.leadId?.uniqueLeadNumber
          obj.requester_name = data[i]?.createdBy?.name
          obj.mode_of_payment = data[i]?.selectedPaymentMode
          obj.requested_amount = data[i]?.Amount_Requested
          obj.reference_id = data[i].slip_no ? data[i].slip_no : data[i].trxId ? data[i].trxId : data[i].cheQueNo ? data[i].cheQueNo : 'NA'
          obj.reference_id_from_bank = ''
          obj.gross_amount = null
          obj.bank_charges = null
          obj.gst_amount = null
          obj.net_amount_recieved = null
          obj.attachment = data[i].attachment
          obj.nonVirtualBankName = data[i].nonVirtualBankName || 'NA'
          obj.remarks = ''
          obj.createdAt = moment(data[i]?.createdAt).format('DD-MM-YYYY')
          if(obj.mode_of_payment === paymentMode.cashFree){
            obj.reference_id = data[i].cf_payment_id;
          }

          temp_array.push(obj)
        }
        // console.log('temp_array', temp_array)

        setmanualTransactionsForApproval(temp_array)
        
        // let paymentDetails = [];
        
        // result?.data?.paymentTransactions?.forEach((el, i) =>
        // {
        //   let obj =
        //   {
        //     _id: el._id,
        //     leadId: el.leadId,
        //     customerName: el?.customerName ? el.customerName : "NA",
        //     newUniqueId: el?.leadId?.newUniqueLeadId ? el?.leadId?.newUniqueLeadId : "NA",
        //     uniqueLeadNumber: el?.leadId?.uniqueLeadNumber ? el?.leadId?.uniqueLeadNumber : "NA",
        //     transactionNumber: el?.cashTransactionNumber ? el.cashTransactionNumber : el?.paymentLogsId?.TransactionNumber ? el?.paymentLogsId?.TransactionNumber : "NA",
        //     createdAt: el?.createdAt ? moment(el?.createdAt).format("DD-MM-YYYY") : "-",
        //     Amount: el?.Amount ? el.Amount : 0,
        //     Status: el?.Status ? el.Status : "NA",
        //     Stage: el?.Stage ? el?.Stage : "NA",
        //     createdByUser: el?.createdBy.name ? el?.createdBy.name : "NA",
        //     createdBy: el?.createdBy?._id ? el?.createdBy?._id : "NA",
        //     departmentName: el?.departmentId?.name ? el?.departmentId.name : "NA",
        //     departmentId: el?.departmentId?._id ? el?.departmentId?._id : "NA",
        //     paymentMode: el?.selectedPaymentMode ? el?.selectedPaymentMode : el?.TransactionData?.map((transaction, i) => transaction.Tag === "PaymentMode" ? transaction.Value : null ).filter((payment) => payment !== null),
        //     slip_no: el.slip_no ? el.slip_no : 'NA',
        //     trxId: el.trxId ? el.trxId : 'NA',
        //     cheQueNo: el.cheQueNo ? el.cheQueNo : 'NA',
        //     paymentPartnerName: el?.paymentPatnerId?.name ? el?.paymentPatnerId?.name : "NA",
        //     financeAction: el.financeAction ? el.financeAction : 'NA',
        //     financeActionStatus: el.financeActionStatus,
        //     financeActionRemarks: el.financeActionRemarks,
        //     financeEditRemarks: el.financeEditRemarks,
        //     paymentLogs : el.paymentLogs ? el.paymentLogs : []
        //   };
        //   console.log('obj', obj)
        //   paymentDetails.push(obj);
        
        // });

        // console.log('paymentDetails', paymentDetails)
        
        // setPaymentInfo(paymentDetails);
      }
      catch (error)
      {
        console.log(error);
      }

      props.setLoading(false);

    },[datefilter, page, searchStageWise, rowsPerPage]);

  const [openFinanceActionModal, setOpenFinanceActionModal] = useState(false)
  const [financeActionStatus, setFinanceActionStatus] = useState("");
  const [financeActionPaymentDetails, setFinanceActionPaymentDetails] = useState({});

  const handleChange = (e,i, row) =>
  {
    let {name, value} = e.target

    if(['bank_charges', 'gst_amount'].includes(name))
    {
      if(Number(value) < 1 )
      {
        props.setSnackInfo("Amount cannot be less than 1", "warning");
        return
      }
    }
    
    // console.log('manualTransactionsForApproval', manualTransactionsForApproval)
    // console.log('e.target.name', e.target.name)
    // console.log('e.target.value', e.target.value)
    // console.log('i', i)
    // console.log('row', row)
    let temp_data = [...manualTransactionsForApproval]
    temp_data[i][name] = value
    // console.log('temp_data', temp_data)
    setmanualTransactionsForApproval(temp_data)
    
  }

  const handle_finance_action = (data,value) =>
  {
    // console.log('data', data)
    // console.log('value', value)
    // console.log('manualTransactionsForApproval', manualTransactionsForApproval)

    setFinanceActionStatus(value)
    setFinanceActionPaymentDetails(data)
    setOpenFinanceActionModal(true)
  }

  const close_finance_action_model = () =>

  {
    setOpenFinanceActionModal(false)
    setFinanceActionPaymentDetails({})
    setFinanceActionStatus('')
  }

  const handle_finance_confirmation = async (value) => 
  {
    props.setLoading(true)
    // console.log('value', value)

    try
    {
      setOpenFinanceActionModal(false)
      // console.log('financeActionStatus', financeActionStatus)
      // console.log('financeActionPaymentDetails', financeActionPaymentDetails)
      // console.log('now you can proceed')
      if(financeActionPaymentDetails.selectedPaymentModeType === "Automatic"){
        return handleFinanceActionsForAutomaticPayment(value);
      }

      if(value === 'Yes')
      {
        let obj = {}
        obj.financeActionPaymentDetails = financeActionPaymentDetails
        obj.financeActionStatus = financeActionStatus

        // console.log('obj', obj)

        const result = await HttpService.approveManualPayment(obj)
        setmanualTransactionsForApproval([])
        
        // console.log('status', result.status)
        // console.log('result', result)

        // if(result.status == 200)
        // {
        //   props.setSnackInfo(result.data, 'success')
        // }
        
      }

      setFinanceActionStatus('')
      setFinanceActionPaymentDetails({})
      
      getAllManualTransactionsForApproval()
    }
    catch(error)
    {
      console.log('error', error.message)
      console.error(error)
    }
    props.setLoading(false)
    
  }
  const [financeInputsForPayment, setFinanceInputsForPayment] = useState(
    {
      ref_id_from_bank: '',
      gross_amount: null,
      bank_charges: null,
      gst_amount: null,
      remarks: ''
    })

    useEffect(() => {
      getAllManualTransactionsForApproval()
      // getPaymentAllPreview();
      // getDepartments();
      // paymentMethod();
      // getSalesDesginUserDetail()
      // stages();
    // },[getPaymentAllPreview, searchStageWise]);
    },[getAllManualTransactionsForApproval]);

    const handleGetCsvClick = async () =>
  {
    props.setLoading(true)
    try
    {
      // console.log('handleGetCsvClick function clicked')
      props.setSnackInfo('handleGetCsvClick clicked', 'success')
      // console.log('manualTransactionsForApproval', manualTransactionsForApproval)
      let lead_ids = []
      const wb = XLSX.utils.book_new();
      let manualTransactionsForApprovalData = [], allPaymentLogsData = []

      for(let i = 0; i < manualTransactionsForApproval.length; i++)
      {
        let obj = {}
        obj.customer_unique_id = manualTransactionsForApproval[i]['customer_unique_id']
        obj.erpProjectNo = manualTransactionsForApproval[i]['lead_details'].erpProjectNo
        obj.customer_name = manualTransactionsForApproval[i]['lead_details']?.customerId?.name
        obj.lead_no = manualTransactionsForApproval[i]['lead_details']?.lead_no
        obj.requester_name = manualTransactionsForApproval[i]['requester_name']
        obj.mode_of_payment = manualTransactionsForApproval[i]['mode_of_payment']
        obj.selected_bank = manualTransactionsForApproval[i]['nonVirtualBankName']
        obj.requested_amount = manualTransactionsForApproval[i]['requested_amount']
        obj.reference_id = manualTransactionsForApproval[i]['reference_id']
        
        manualTransactionsForApprovalData.push(obj)
        lead_ids.push(manualTransactionsForApproval[i]['lead_details']._id)
      
      }

      lead_ids = [...new Set(lead_ids)]
      // console.log('lead_ids', lead_ids)

      let response = await HttpService.getPaymentLogsOfMultipleLeadsForManualPayments(lead_ids)

      // console.log('response paymentLogs', response.data)

      let allPaymentLogs = response.data

      for(let k = 0; k < allPaymentLogs.length; k++)
      {
        let obj = {}
        obj.uniqueLeadNumber = allPaymentLogs[k]?.leadId.uniqueLeadNumber
        obj.refId = allPaymentLogs[k].paymentTransactionId?.trxId || allPaymentLogs[k].paymentTransactionId?.cheQueNo || allPaymentLogs[k].paymentTransactionId?.slip_no || 'NA'
        obj.amount_received_or_requested = allPaymentLogs[k]?.Status
        obj.reqested_by_or_received_mode = allPaymentLogs[k]?.Status == ('Payment Requested' || 'Sent For Finance Approval') ? allPaymentLogs[k]?.PaymentUser?.name : allPaymentLogs[k]?.paymentMode
        obj.amount = allPaymentLogs[k]?.amount
        obj.date = moment(allPaymentLogs[k]?.createdAt).format('DD-MM-YYYY')
        allPaymentLogsData.push(obj)
      }
      // Sheet 1
      const ws1 = XLSX.utils.json_to_sheet(manualTransactionsForApprovalData);
      XLSX.utils.book_append_sheet(wb, ws1, 'Manual Payment Transactions');

      // Sheet 2
      const ws2 = XLSX.utils.json_to_sheet(allPaymentLogsData);
      XLSX.utils.book_append_sheet(wb, ws2, 'Payment Logs');

      // Save the workbook as a file
      XLSX.writeFile(wb, 'multi-sheet-file.xlsx');
      
    }
    catch(error)
    {
      console.log('error', error.message)
      console.error(error)
    }
    props.setLoading(false)
  }

  // Function to handle the finance Actions for Automatic Payments 
  const handleFinanceActionsForAutomaticPayment = async(value) => {
    props.setLoading(true);
    try {
      if (value === "Yes") {
        let obj = {}
        obj.financeActionPaymentDetails = financeActionPaymentDetails;
        obj.financeActionStatus = financeActionStatus;
        const result = await HttpService.approveAutomaticPayment(obj);
        setmanualTransactionsForApproval([]);
      }

      setFinanceActionStatus("");
      setFinanceActionPaymentDetails({});

      getAllManualTransactionsForApproval();
    }
    catch (error) {
      console.log("Error -> ", error);
      console.error(error);
    }
    props.setLoading(false);
  }

 

  return (
    <div style={{ overflowX: 'auto' }}>
      {/* <h1 className={classes.heading}>Manual Payment Transactions</h1> */}
      <div style={{ marginBottom: 20, alignItems: "center", justifyContent: "center" }}>
        <Paper className={classes.heading}>
          <center> <h2>Manual Payment Transactions</h2></center>
        </Paper>
      </div>
      <br />
      <Button disabled={false} className={classes.approveButton} variant="contained" style={{ alignSelf: "center", marginBottom: 20 }} onClick={handleGetCsvClick} >
        Get CSV File
      </Button>

      <TableContainer component={Paper}>
        <Table className="table table-responsive table-bordered" aria-label="sticky table">
          <TableHead className={classes.tableHeader}>
            <StyledTableRow>
              <TableCell style={{ fontWeight: "600", textAlign: "center" }}>S.No</TableCell>
              <TableCell style={{ fontWeight: "600", textAlign: "center" }}>Cust Unique Id</TableCell>
              <TableCell style={{ fontWeight: "600", textAlign: "center" }}>Project No</TableCell>
              <TableCell style={{ fontWeight: "600", textAlign: "center" }}>Requester Name</TableCell>
              <TableCell style={{ fontWeight: "600", textAlign: "center" }}>Mode of payments</TableCell>
              <TableCell style={{ fontWeight: "600", textAlign: "center" }}>Selected Bank</TableCell>
              <TableCell style={{ fontWeight: "600", textAlign: "center" }}>Amount</TableCell>
              <TableCell style={{ fontWeight: "600", textAlign: "center" }}>Refernce ID</TableCell>
              <TableCell style={{ fontWeight: "600", textAlign: "center" }}>Requested Date</TableCell>
              <TableCell style={{ fontWeight: "600", textAlign: "center" }}>Reference ID from bank Statement</TableCell>
              <TableCell style={{ fontWeight: "600", textAlign: "center" }}>Gross Amount</TableCell>
              <TableCell style={{ fontWeight: "600", textAlign: "center" }}>Bank Charges</TableCell>
              <TableCell style={{ fontWeight: "600", textAlign: "center" }}>GST Amount</TableCell>
              <TableCell style={{ fontWeight: "600", textAlign: "center" }}>Net Amount Recevied</TableCell>
              <TableCell style={{ fontWeight: "600", textAlign: "center" }}>Attachment</TableCell>
              <TableCell style={{ fontWeight: "600", textAlign: "center" }}>Remarks</TableCell>
              <TableCell style={{ fontWeight: "600", textAlign: "center" }}>Action</TableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {manualTransactionsForApproval.map((row, i) => {
              const serialNumber = (page) * rowsPerPage + i + 1;
              return (
                <TableRow key={i + 1}>
                  <TableCell style={{ textAlign: "center" }}>{serialNumber}</TableCell>
                  <TableCell style={{ textAlign: "center" }}>{row.customer_unique_id}</TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <Link to={`/project-logs/${row?.lead_details?._id}`} style={{ color: "#000", textDecoration: 'underline' }}>{row?.lead_details?.erpProjectNo || "N/A"}</Link>
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>{row.requester_name}</TableCell>
                  <TableCell style={{ textAlign: "center" }}>{row.mode_of_payment}</TableCell>
                  <TableCell style={{ textAlign: "center" }}>{row.nonVirtualBankName}</TableCell>
                  <TableCell style={{ textAlign: "center" }}>{row.requested_amount}</TableCell>
                  <TableCell style={{ textAlign: "center" }}>{row.reference_id}</TableCell>
                  <TableCell style={{ textAlign: "center" }}>{row?.createdAt}</TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {/* {row.selectedPaymentModeType === "Automatic" ? "N/A" : */}
                      <TextField
                        id="reference_id_from_bank"
                        name="reference_id_from_bank"
                        type="text"
                        variant="outlined"
                        // placeholder="Enter..."
                        onChange={(e, v) => handleChange(e, i, row)}
                        // onKeyDown={filterValue}
                        value={row.reference_id_from_bank}
                        InputLabelProps={{
                          // shrink: true,
                        }}
                        className={classes.ref_id_from_bank_field}
                        style={{ width: '120px' }}
                      />
                    {/* } */}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {/* {row.selectedPaymentModeType === "Automatic" ? "N/A" : */}
                      <TextField
                        id="gross_amount"
                        name="gross_amount"
                        type="number"
                        variant="outlined"
                        // placeholder="Enter..."
                        onChange={(e, v) => handleChange(e, i, row)}
                        // onKeyDown={filterValue}
                        value={row.gross_amount}
                        InputLabelProps={{
                          // shrink: true,
                        }}
                        className={classes.gross_amount_field}
                        style={{ width: '120px' }}
                      />
                    {/* } */}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {/* {row.selectedPaymentModeType === "Automatic" ? "N/A" : */}
                      <TextField
                        id="bank_charges"
                        name="bank_charges"
                        type="number"
                        variant="outlined"
                        // placeholder="Enter..."
                        onChange={(e, v) => handleChange(e, i, row)}
                        // onKeyDown={filterValue}
                        value={row.bank_charges}
                        InputLabelProps={{
                          // shrink: true,
                        }}
                        className={classes.bank_charges_fiels}
                        style={{ width: '120px' }}
                      />
                    {/* } */}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {/* {row.selectedPaymentModeType === "Automatic" ? "N/A" : */}
                      <TextField
                        id="gst_amount"
                        name="gst_amount"
                        type="number"
                        variant="outlined"

                        onChange={(e, v) => handleChange(e, i, row)}
                        // onKeyDown={filterValue}
                        value={row.gst_amount}
                        InputLabelProps={{
                          // shrink: true,
                        }}
                        className={classes.textField}
                        style={{ width: '120px' }}
                      />
                    {/* } */}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {row.gross_amount - row.bank_charges - row.gst_amount}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <Button variant="outlined" className={classes.downloadButton} onClick={() => window.open(row.attachment[0])}><DownloadOutlined /></Button>
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <TextField
                      id="remarks"
                      name="remarks"
                      type="text"
                      variant="outlined"
                      // placeholder="Enter..."
                      onChange={(e, v) => handleChange(e, i, row)}
                      // onKeyDown={filterValue}
                      value={row.remarks}
                      InputLabelProps={{
                        // shrink: true,
                      }}
                      className={classes.ref_id_from_bank_field}
                      style={{ width: '120px' }}
                    />
                  </TableCell>
                  <TableCell component="th" scope="row" style={{ borderRight: "0px", alignItems: "center" }}>
                    <div style={{ marginLeft: "auto", display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      {/* {row.selectedPaymentModeType !== "Automatic" ? */}
                        <Tooltip title="Approve" placement="top"><Button disabled={row.requested_amount != row.gross_amount || row.reference_id_from_bank == '' || row.bank_charges == '' || row.gst_amount == '' || row.remarks == ''} className={classes.approveButton} variant="contained" onClick={() => handle_finance_action(row, "Approved")}>Approve</Button></Tooltip>
                        {/* : ""} */}
                      <Tooltip title="Reject" placement="top"><Button disabled={row.remarks == ''} className={classes.rejectButton} variant="contained" onClick={() => handle_finance_action(row, "Rejected")}>Reject</Button></Tooltip>
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
              rowsPerPageOptions={[50,100,200, { label: 'All', value: totalPages ? totalPages : '' }]}
                colSpan={8}
                count={totalPages}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      {/* Dialog box to open when clicked on approve/reject */}
      <Dialog
        open={openFinanceActionModal}
        onClose={() => close_finance_action_model()}
        fullWidth
        // maxWidth={"md"}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="form-dialog-title" style={{ margin: "auto" }}>
          Are You Sure, {userDetails.name} ?
        </DialogTitle>
        <p style={{ color: 'red', margin: "auto" }}>** Payment will be marked as {financeActionStatus} **</p>
        <DialogActions>
          <Button className={classes.noButton} onClick={() => handle_finance_confirmation('No')} variant="outlined">
            No
          </Button>
          <Button className={classes.yesButton} onClick={() => handle_finance_confirmation('Yes')} variant="contained">
            Yes
          </Button>
        </DialogActions>

      </Dialog>
    </div>
  );
};

export default connect(null, { setLoading, setSnackInfo })(ApprovalForManualTransaction);

