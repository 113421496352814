import React, { useState, useEffect , useRef} from 'react';
import { connect, useSelector } from 'react-redux'
import HttpService from '../../services/httpService'
import { setLoading, setSnackInfo } from '../../redux/actions/appAction'
import moment from 'moment';
import { Link } from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Divider, FormControl,FormControlLabel,TextField, Radio,RadioGroup, Checkbox} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { FileExcelOutlined, DownloadOutlined } from '@ant-design/icons';
import IconButton from '@material-ui/core/IconButton';
import { useHistory } from "react-router-dom";
import {ROLENAME} from '../../utils/index';
import { commaSeparateAmount } from '../../components/commaSeparate';
import { Modal } from 'antd';
import { rftUnitTypeId } from '../../utils/index';


const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 700,
    },
    root: {
        width: '100%',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
}));

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#ccc",
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const QtChangesApproval = (props) => {

    const buttonRef = useRef(null);
    const history = useHistory();
    const classes = useStyles();
    const { userDetails } = useSelector(state => state.user)
    const [approvalQuotationData, setApprovalQuotationData] = useState([]);
    const [displayArray, setDisplayArray] = useState([]);
    const [viewQuotationModal, setViewQuotationModal] = useState(false);
    const [displayAllQtVersion, setDisplayAllQtVersion] = useState([]);
    const [projectId, setProjectId] = useState();
    const [csvData, setCsvData] = useState([]);
    const [projectDetails, setProjectDetails] = useState({})
    const [index, setIndex] = useState();
     const [openPreviewModal, setOpenPreviewModal] = useState(false);
     const [qtPreview, setQtPreview] = useState([]);
     const [paymentTotalInfo, setPaymentTotalInfo] = useState({});
     const [openRejectRemarkModal, setOpenRejectRemarkModal] = useState(false);
     const [openApproveRemarkModal, setOpenApproveRemarkModal] = useState(false);
     const [remark,setRemark] = useState("");
     const [qtTotalAmouts, setQtTotalAmounts] = useState({});
     const [categoryObject, setCategoryObject] = useState({});
     const [balanceSheetData, setBalanceSheetData] = useState({
      reviseQuotation : 0,
      discount : 0,
      subTotal: 0,
      total : 0,
      gstAmount: 0,
      pendingDue : 0
     });
    useEffect(() => {
        quotationApproval();
    }, []
    )

    const headers = [
        { key: "categoryname", label: "Category" },
        { key: "code", label: "Product Code" },
        { key: "productname", label: "Name" },
        { key: "description", label: "Description" },
        { key: "plyType", label: "Core Material" },
        { key: "finishType", label: 'Finish' },
        { key: "area", label: 'Area/Qty(in Sq Ft)' },
        { key: "Qty", label: 'Qty' },
        { key: "length", label: 'Length' },
        { key: "width", label: 'Width' },
        { key: "unittype", label: 'Unit' },
        { key: "cost", label: 'Cost(Per Sq Feet/Qty)' },
        { key: "itemTotal", label: 'Item total' },
        // When scope is enable that time remove the comment from next line
        { key: "scope", label: 'Scope' },
        { key: "emptyColumn1", label: '' },
        { key: "emptyColumn2", label: '' },
        { key: "quotationValue", label: 'TOTAL QUOTATION VALUE' },
        { key: "discountPercent", label: 'Discount(%)' },
        { key: "discount", label: 'Discount' },
        { key: "subTotal", label: 'Sub total' },
        { key: "gst", label: 'GST' },
        { key: "miscellaneous", label: 'Miscellaneous amount' },
        { key: "total", label: 'TOTAL' },
        { key: "customerTotalOutflow", label: 'TOTAL CUSTOMER OUTFLOW' }
      ];
    
    // get approval request lead data
    const quotationApproval = async function () {
        try{
            const res = await HttpService.getSentApprovalQuotation();
            setApprovalQuotationData(res.data);
            arrayUpdateFunction(res.data);
        }
        catch(err){
            console.error(err);
        }
    }

    // Upload Payment Receipt
    const viewUploadReceipt = async ()=> {
      props.setLoading(true)
      try {
         window.open(displayArray[index]?.sentForQuotationChangeApproval?.uploadPaymentReceipt);}
      catch(error){
        console.log(error, "Receipt not found")
      }
      props.setLoading(false);
    }
    // quotation approval button clicked

    const approveQuotation = async (status) => {
        props.setLoading(true)
        const data = {
          remark: remark ? remark : "",
          approveStatus: status,
          valueLessThan20k: displayArray[index]?.sentForQuotationChangeApproval?.checkQtCrAmount?.valuesLessThan20k,
          valuesGreaterThan20k: displayArray[index]?.sentForQuotationChangeApproval?.checkQtCrAmount?.valuesGreaterThan20k ,
          allPositiveValues: displayArray[index]?.sentForQuotationChangeApproval?.checkQtCrAmount?.allPositiveValues,
          diffQtChanges: displayArray[index]?.sentForQuotationChangeApproval?.diffQtChanges,
          prevQuotationAmount: displayArray[index]?.sentForQuotationChangeApproval?.prevQuotationAmount,
          prevQuotationTotalAmount: displayArray[index]?.prevQuotationTotalAmount,
          newQuotationAmount: displayArray[index]?.sentForQuotationChangeApproval?.newQuotationAmount,
          delayCategoryCheck : displayArray[index]?.delayCategoryCheck,
          totalAmountOfCategory : displayArray[index]?.delayToReferralBonusCategory?.totalAmountOfCategory,
        }   
        
        try {
            const response = await HttpService.approvalQuotation(projectId, data);
            setOpenPreviewModal(false);
            setRemark("")
            setOpenApproveRemarkModal(false);
            setOpenRejectRemarkModal(false);
            quotationApproval();

        } catch (error) {
            console.log(error, "Discounted Project not found")
        }
        props.setLoading(false)
    }

    // View and Edit Quotation by approver
    const obj ={
        editQuotation: false,
        viewQuotation: false,
    }

  /*   View and Edit QT Future Feature----------------------

   const approverEditQuotation = (data)=>{
        try {
            obj.editQuotation = true;
            approverAction(data._id, obj);
            props.history.push(`/manageQuotations/${data._id}`);
        } catch (error) {
            console.log(error);
        }
    }

    const approverViewQuotation = async(data)=>{
        props.setLoading(true);
        try {
            obj.viewQuotation = true;
            approverAction(data._id, obj);
            // setViewQuotationModal(true);
            let res = await HttpService.getAllQuotationsForProject(data._id);
            setDisplayAllQtVersion(res.data)
            getProjectDetails(data._id);
        } catch (error) {
            console.log(error);
        }
        props.setLoading(false);
    }

    const getProjectDetails = async (leadId) => {
        props.setLoading(true)
        try {
          const response = await HttpService.getProjectLeadDetails(leadId);
          console.log("project Details",response.data[0])
          setProjectDetails(response.data[0])
          
        } catch (error) {
          console.error(error)
          props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
        }
        props.setLoading(false)
      }

    const approverAction = async(projectId, obj)=>{
        const response = await HttpService.approverEditViewAction(projectId, obj);
        if (response.status === 200) {
            console.log("success", response);
          } else {
            console.log('Something went wrong')
          }
    }
    const downloadMaskedQuotation = async (data) => {
        props.setLoading(true)
        try {
          const response = await HttpService.getMaskedQuotation(data._id)
          window.open(response.data.link)
          props.setSnackInfo(response.data.message, "success")
        } catch (error) {
          props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false)
      }

---------------------------------------------------------------- */

      const generateQtExcel = async (qtId) => {
        const exdata = await generateQuotationExcel(qtId);
        setCsvData(exdata)
        try {
          setTimeout(() => {
            buttonRef.current.link.click();
          });
        } catch (err) {
          console.error(err.message);
        }
        props.setLoading(false)
      }

    //   get version quotation generated data
      const generateQuotationExcel = async (quotationId) => {
        props.setLoading(true)
        try {
          const response = await HttpService.generateQuotationExcel(quotationId)
          let results = response?.data?.components;
          var data = [];
          for (let i = 0; i < results.length; i++) {
            for (let j = 0; j < results[i]['products'].length; j++) {
              let obj = {};
              obj['categoryname'] = results[i]['categoryId']?.['name'];
              obj['code'] = results[i]['products'][j]['product']?.['code'];
              obj['productname'] = results[i]['products'][j]['product']?.['name'];
              obj['description'] = results[i]['products'][j]['product']?.['description'];
              if (results[i]['products'][j]['subUnitType'] === 'Area') {
                obj['plyType'] = results[i]['plyType']?.['name'];
                obj['finishType'] = results[i]['products'][j]['finishType']?.['name'];
                obj['Qty'] = String(1);
              } else {
                obj['plyType'] = 'NA';
                obj['finishType'] = 'NA'
                obj['Qty'] = results[i]['products'][j]['area']
              }
              if (results[i]['products'][j]['subUnitType'] === 'Qty') {
                obj['area'] = String(0);
              } else {
                if (results[i]['products'][j]['productCustomArea'] !== 0) {
                  obj['area'] = results[i]['products'][j]['productCustomArea']
                } else {
                  obj['area'] = results[i]['products'][j]['area']
                }
              }
              obj['length'] = results[i]['products'][j]['length'];
              obj['width'] = results[i]['products'][j]['width'];
              obj['unittype'] = results[i]['products'][j]['unitType'] !== null ? results[i]['products'][j]['unitType']?.['name'] : 'null';
              obj['cost'] = results[i]['products'][j]['productCustomPrice'] === 0 ? results[i]['products'][j]['totalPricePerUnit'] : results[i]['products'][j]['productCustomPrice'];
              obj['itemTotal'] = results[i]['products'][j]['productPrice'];
              // When scope is enable that time remove the comment from next line
              obj['scope'] = results[i]['products'][j]['product']['scopeId']['name'];
              if (i === 0 && j === 0) {
                if (projectDetails.discountOnAllScopes === true) {
                  obj['quotationValue'] = response?.data?.['totalAmount'];
                  obj['discountPercent'] = response?.data?.['discountPercent'];
                  obj['discount'] = response?.data?.['discountAmount'] ? response?.data?.['discountAmount'] : 0;
                  obj['subTotal'] = response?.data?.['tempSt'] ? response?.data?.['tempSt'] : 0;
                  //obj['customerToProc'] = response?.data?.['customerToBeProcured'] ? response?.data?.['customerToBeProcured'] : 0;
                  //obj['customerToProcPercent'] = response?.data?.['materialProcured'] ? response?.data?.['materialProcured'] : 0;
                  //obj['decorpotScope'] = response?.data?.['decorpotScope'] ? response?.data?.['decorpotScope'] : 0;
                  obj['gst'] = response?.data?.['gstValue'] ? response?.data?.['gstValue'] : 0;
                  obj['miscellaneous'] = response?.data?.['miscTotal'] ? response?.data?.['miscTotal'] : 0;
                  obj['total'] = response?.data?.['mainTotal'] ? response?.data?.['mainTotal'] : 0;
                  obj['customerTotalOutflow'] = response?.data?.['totalCustomerOutflow'] ? response?.data?.['totalCustomerOutflow'] : 0;
                } else {
                  obj['discountPercent'] = response?.data?.['discountPercent'];
                  obj['discount'] = response?.data?.['discountAmount'] ? response?.data?.['discountAmount'] : 0;
                  if (response?.data?.['discItemsSubTotal' && response?.data?.['totalNonDiscPrAmnt']]) {
                    obj['quotationValue'] = response?.data?.['totalDiscPrAmnt'] + response?.data?.['totalNonDiscPrAmnt'];
                    obj['subTotal'] = response?.data?.['discItemsSubTotal'] ? response?.data?.['discItemsSubTotal'] + response?.data?.['totalNonDiscPrAmnt'] : 0 + response?.data?.['totalNonDiscPrAmnt'];
                  } else {
                    obj['quotationValue'] = response?.data?.['totalAmount'];
                    obj['subTotal'] = response?.data?.['tempSt'] ? response?.data?.['tempSt'] : 0;
                  }
                  obj['gst'] = response?.data?.['gstValue'] ? response?.data?.['gstValue'] : 0;
                  obj['miscellaneous'] = response?.data?.['miscTotal'] ? response?.data?.['miscTotal'] : 0;
                  obj['total'] = response?.data?.['mainTotal'] ? response?.data?.['mainTotal'] : 0;
                  obj['customerTotalOutflow'] = response?.data?.['totalCustomerOutflow'] ? response?.data?.['totalCustomerOutflow'] : 0;
                }
              }
              data.push(obj);
            }
          }
          if (response.data.hasOwnProperty('miscellaneousComponent')) {
            response.data['miscellaneousComponent']['products'].forEach((misc) => {
              let obj = {}
              obj['categoryname'] = response.data['miscellaneousComponent']['projectComponent']['name'];
              obj['code'] = misc['product']['code'];
              obj['productname'] = misc['product']['name'];
              obj['description'] = misc['product']['description'];
              if (misc['subUnitType'] === 'Area') {
                obj['plyType'] = response.data['miscellaneousComponent']['name'];
                obj['finishType'] = misc['finishType']['name'];
                obj['Qty'] = String(1);
              } else {
                obj['plyType'] = 'NA';
                obj['finishType'] = 'NA'
                obj['Qty'] = misc['area']
              }
              if (misc['subUnitType'] === 'Qty') {
                obj['area'] = String(0);
              } else {
                if (misc['productCustomArea'] !== 0) {
                  obj['area'] = misc['productCustomArea']
                } else {
                  obj['area'] = misc['area']
                }
              }
    
              obj['length'] = misc['length'];
              obj['width'] = misc['width'];
              obj['unittype'] = misc['unitType']['name'];
              obj['cost'] = misc['productPricePerUnit'];
              obj['itemTotal'] = misc['productPrice'];
              obj['scope'] = misc['product']['scopeId']['name'];
              data.push(obj);
            })
          }
          return data;
        } catch (error) {
          console.error(error)
          props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
        }
        props.setLoading(false)
      }
// Show Data according experience and role
    const arrayUpdateFunction = (masterArray)=>{
        const newArr = masterArray.filter((row)=> {
        return (row.experienceCenterId === userDetails?.selectedExpCenter)
        }
    )
        setDisplayArray(newArr)
    }

// approval button Modal Open Source
    const previewQuotation = async (row,i) => {
        setProjectId(row?._id);
        setOpenPreviewModal(true);
        const response = await HttpService.getProjectComponent(row?._id)
        let data = response.data.shift();
        let sortedData = response.data.sort((a, b) => a.priority - b.priority)
        sortedData.push(data)
        let obj = [];
        sortedData.forEach(ele => {
          obj.push({
            product: ele.products,
            spacename: ele.categoryId.name,
          })
        })

        // Calculation Quotation Amount
      let object = {
        totalQtValue: 0,
        discountPercent:0,
        discount: 0,
        subTotal: 0,
        gst: 0,
        total: 0,
      };
      let totalAmountArray = [];

      const filteredObj = obj.filter(item => item.spacename === "POST DESIGN SIGN-OFF CHANGES");

      // this calculation only for get new item adding or removig value sum
      let totalNewItemAmountArray = []; 
      for (let i = 0; i < filteredObj.length; i++) {
        for (let j = 0; j < filteredObj[i]?.product.length; j++) {
          const product = filteredObj[i].product[j];
          if (product.negativeQtCr || product.positiveQtCr) {
            totalNewItemAmountArray.push(+product.productPrice);
          }
        }
      }
      
      const newItemSum = totalNewItemAmountArray.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );

      let discountPercent = (+displayArray[i]?.sentForQuotationChangeApproval?.discountPercentage)
      object.totalQtValue = newItemSum.toFixed(2);
      object.discountPercent = discountPercent;
      object.discount = ((newItemSum * discountPercent) / 100).toFixed(2);
      object.subTotal = (object.totalQtValue -  object.discount).toFixed(2);
      object.gst = ((object.subTotal * 18) / 100).toFixed(2);
      object.total = (parseInt(object.subTotal) +  parseInt(object.gst)).toFixed(2);
      setQtPreview(obj);
      setQtTotalAmounts(object);
      setCategoryObject(displayArray[i]?.delayToReferralBonusCategory);

      // balance sheet calculation
      const reviseQuotationValuesCount = displayArray[i]?.prevQuotationTotalAmount + newItemSum  ;
      const  discountCount = ((reviseQuotationValuesCount * (displayArray[i]?.discountPercent))/ 100).toFixed(2);
      const subTotalCount  = parseInt(reviseQuotationValuesCount - discountCount);
      const gstCount = (subTotalCount * (18 /100)).toFixed(2);
      const totalCount = subTotalCount + parseInt(gstCount)
      // const pendingDueCount = parseInt(displayArray[i]?.sentForQuotationChangeApproval?.balnceSheetTotalAmount) - amountPaid;
      setBalanceSheetData((prev) => ({...prev , reviseQuotation : reviseQuotationValuesCount, discount: discountCount, subTotal: subTotalCount, gstAmount : gstCount , total : totalCount}));
   }

    return (
        <div>
            <Paper>
                <TableContainer component={Paper}>
                    <Table className={classes.table} stickyHeader aria-label="sticky table">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell>Sl. No.</StyledTableCell>
                                <StyledTableCell>Customer Name</StyledTableCell>
                                <StyledTableCell>Lead No</StyledTableCell>
                                <StyledTableCell>Requested User</StyledTableCell>
                                <StyledTableCell>Action</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                        {
                            displayArray.map((row, i) => {
                                return (
                                    <>
                                        {(
                                                <StyledTableRow key={i + 1}>
                                                    <StyledTableCell>{i + 1}</StyledTableCell>
                                                    <StyledTableCell component="th" scope="row">{row?.customerId?.name}</StyledTableCell>
                                                    <StyledTableCell>{row?.lead_no}</StyledTableCell>
                                                    <StyledTableCell component="th" scope="row">
                                                      {row?.sentForQuotationChangeApproval?.requestedUser}
                                                   </StyledTableCell>
                                                   <StyledTableCell component="th" scope="row" style={{display: "flex", justifyContent: "space-between"}}>
                                                      <Button variant="contained" color="primary" onClick={() => { previewQuotation(row, i); setIndex(i); }}>View</Button>
                                                   </StyledTableCell>
                                                    {/* disable Now, for future we will enable and work on that functionality */}
                                                    {/* <StyledTableCell >
                                                        <Tooltip title="Edit">
                                                            <IconButton disabled color="primary" aria-label="edit" onClick={() => approverEditQuotation(row)}>
                                                                <EditIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="View">
                                                            <IconButton disabled color="primary" aria-label="view" onClick={() => approverViewQuotation(row)}>
                                                                <EyeOutlined />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </StyledTableCell> */}
                                                </StyledTableRow>
                                       )
                                         } 
                                    </>
                                );
                                
                            }) 
                        }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>

         {/* open Preview Quotation Modal */}
        <Modal
          title="Preview Quotation"
          centered
          visible={openPreviewModal}
          onCancel={() => {
            setOpenPreviewModal(false);
          }}
          width={1000}
          maskClosable={false}
          footer={null}
        >
          {/* {
            <div>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue=""
                  name="radio-buttons-group"
                >
                  <FormControlLabel value="" control={<Radio style={{ color: "green" }} />} label={<span style={{ color: "green" }}>Raised quotation changes for positive values</span>} />
                  <FormControlLabel value="" control={<Radio style={{ color: "red" }} />} label={<span style={{ color: "red" }}>Raised quotation changes for negative values</span>} />
                </RadioGroup>
              </FormControl>
            </div>
          } */}
         {
          qtPreview.filter(data => data.spacename === 'POST DESIGN SIGN-OFF CHANGES').map((data, i) => 
            <TableContainer key={i + 1} component={Paper} style={{ marginBottom: 20 }}>
              <div style={{ textAlign: "center", marginBottom: 10, marginTop: 10 }}><b>{data.spacename}</b></div>
              <Table className={classes.table} size="small" aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Sl No</StyledTableCell>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>Priority</StyledTableCell>
                    {/* <StyledTableCell>Scope</StyledTableCell> */}
                    <StyledTableCell>Finish Type</StyledTableCell>
                    <StyledTableCell>No/Qty</StyledTableCell>
                    <StyledTableCell>Depth</StyledTableCell>
                    <StyledTableCell>Width</StyledTableCell>
                    <StyledTableCell>Length</StyledTableCell>
                    <StyledTableCell>Area/Qty</StyledTableCell>
                    <StyledTableCell>Total</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.product?.filter(item => item?.negativeQtCr || item?.positiveQtCr)?.sort((a, b) => a['priority'] - b['priority']).map((data1, i) =>
                    <StyledTableRow key={i + 1}>
                      <StyledTableCell component="th" scope="row">{i + 1}</StyledTableCell>
                      <StyledTableCell component="th" scope="row">{data1?.product?.name}</StyledTableCell>
                      <StyledTableCell component="th" scope="row">{data1?.priority}</StyledTableCell>
                      <StyledTableCell component="th" scope="row">{data1?.product?.finishInclude ? data1?.finishType?.name : `-`}</StyledTableCell>
                      <StyledTableCell component="th" scope="row">{data1?.product?.name.includes('TIles fixing charges for tiles') ? data1?.fixingCharge : `-`}</StyledTableCell>
                      <StyledTableCell component="th" scope="row">{(data1?.product?.name.includes('False ceiling (Peripheral with Cove') || data1?.product?.name.includes('False ceiling (Peripheral, No Cove')) ? data1?.depth : `-`}</StyledTableCell>
                      <StyledTableCell component="th" scope="row">{data1?.product?.name.includes('TIles fixing charges for tiles') || data1?.product?.name.toLowerCase().includes('false ceiling rafter') || data1?.product?.unitType?._id == rftUnitTypeId ? `-` : data1?.width ? data1?.width : `-`}</StyledTableCell>
                      <StyledTableCell component="th" scope="row">{data1?.product?.name.includes('TIles fixing charges for tiles') ? `-` : data1?.length ? data1?.length : `-`} </StyledTableCell>
                      <StyledTableCell component="th" scope="row">{data1?.area ? data1?.area.toFixed(2) : `-`}</StyledTableCell>
                      <StyledTableCell component="th" scope="row">₹ {commaSeparateAmount(data1?.productPrice)}</StyledTableCell>
                    </StyledTableRow>
                  )}
                  <StyledTableRow>
                    <StyledTableCell component="th" scope="row" align="right" colSpan={8}></StyledTableCell>
                    <StyledTableCell component="th" scope="row" align="center">Total</StyledTableCell>
                    <StyledTableCell component="th" scope="row" align="center">
                    ₹ {data?.product.length === 0 ? `0` :
                          commaSeparateAmount(
                            data?.product
                              .filter(item => item?.negativeQtCr || item.positiveQtCr)
                              .reduce((acc, crr) => ({ productPrice: acc.productPrice + crr.productPrice }), { productPrice: 0 }) // Provide an initial value of { productPrice: 0 }
                              .productPrice
                          )}
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>

          )}
      
  {/* Total calculation */}

        {
            <div>
              <TableContainer component={Paper} style={{ marginBottom: 20 }}>
                <Table>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row"><b>Total Quotation value</b></StyledTableCell>
                      <StyledTableCell component="th" scope="row">₹ {commaSeparateAmount(qtTotalAmouts.totalQtValue)}</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row"><b>Discount @ {qtTotalAmouts.discountPercent} %</b></StyledTableCell>
                      <StyledTableCell component="th" scope="row">₹ {commaSeparateAmount(qtTotalAmouts.discount)}</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row"><b>Sub Total</b></StyledTableCell>
                      <StyledTableCell component="th" scope="row">₹ {commaSeparateAmount(qtTotalAmouts.subTotal)}</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row"><b>GST @ 18%</b></StyledTableCell>
                      <StyledTableCell component="th" scope="row">₹ {commaSeparateAmount(qtTotalAmouts.gst)}</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row"><b>Total</b></StyledTableCell>
                      <StyledTableCell component="th" scope="row">₹ {commaSeparateAmount(qtTotalAmouts.total)}</StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          }

  {/* Add category */}
          <div>
            <div>
              <TableContainer component={Paper} style={{ marginBottom: 20 }}>
                <Table>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        <Checkbox checked={displayArray[index]?.delayToReferralBonusCategory?.delayPenalityCheck} disabled={displayArray[index]?.sentForQuotationChangeApproval?.isSendForApproval} />
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row"><b>Delay Penality</b></StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        <TextField type="number"
                          id="delayPenality"
                          label="Delay Penality"
                          size="small"
                          value={displayArray[index]?.delayToReferralBonusCategory?.delayPenality}
                          // onChange={(e) => calculateDamageDelayOtherValue(e.target.value, 'Delay Penality')}
                          variant="outlined"
                          disabled={displayArray[index]?.sentForQuotationChangeApproval?.isSendForApproval}
                        />
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        <Checkbox checked={displayArray[index]?.delayToReferralBonusCategory?.damagesCheck} disabled={displayArray[index]?.sentForQuotationChangeApproval?.isSendForApproval} />
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row"><b>Damages</b></StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        <TextField type="number"
                          id="damages"
                          label="Damages"
                          size="small"
                          value={displayArray[index]?.delayToReferralBonusCategory?.damages}
                          // onChange={(e) => calculateDamageDelayOtherValue(e.target.value, 'Damages')}
                          variant="outlined"
                          disabled={displayArray[index]?.sentForQuotationChangeApproval?.isSendForApproval}
                        />
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        <Checkbox checked={displayArray[index]?.delayToReferralBonusCategory?.goodWillDiscountCheck} disabled={displayArray[index]?.sentForQuotationChangeApproval?.isSendForApproval} />
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row"><b>Good Will Discount</b></StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        <TextField type="number"
                          id="goodWillDiscount"
                          label="Good Will Discount"
                          size="small"
                          value={displayArray[index]?.delayToReferralBonusCategory?.goodWillDiscount}
                          // onChange={(e) => calculateDamageDelayOtherValue(e.target.value, 'Good Will Discount')}
                          variant="outlined"
                          disabled={displayArray[index]?.sentForQuotationChangeApproval?.isSendForApproval}
                        />
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        <Checkbox checked={displayArray[index]?.delayToReferralBonusCategory?.otherCheck} disabled={displayArray[index]?.sentForQuotationChangeApproval?.isSendForApproval} />
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row"><b>Other</b></StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        <TextField type="number"
                          id="other"
                          label="Other"
                          size="small"
                          value={displayArray[index]?.delayToReferralBonusCategory?.other}
                          // onChange={(e) => calculateDamageDelayOtherValue(e.target.value, 'Other')}
                          variant="outlined"
                          disabled={displayArray[index]?.sentForQuotationChangeApproval?.isSendForApproval}
                        />
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        <Checkbox checked={displayArray[index]?.delayToReferralBonusCategory?.referralBonusCheck} disabled={displayArray[index]?.sentForQuotationChangeApproval?.isSendForApproval} />
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row"><b>Referral Bonus</b></StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        <TextField type="number"
                          id="referralBonus"
                          label="Referral Bonus"
                          size="small"
                          value={displayArray[index]?.delayToReferralBonusCategory?.referralBonus}
                          // onChange={(e) => calculateDamageDelayOtherValue(e.target.value, 'Referral Bonus')}
                          variant="outlined"
                          disabled={displayArray[index]?.sentForQuotationChangeApproval?.isSendForApproval}
                        />
                      </StyledTableCell>
                    </StyledTableRow>

                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>

 {/* Balnce sheet statement */}
        <div>
            <h2>Balance Sheet</h2>
            <TableContainer component={Paper} style={{ marginBottom: 20 }}>
              <Table>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell component="th" scope="row" colspan={3}><h3>{"Project No." + " " + displayArray[index]?.erpProjectNo}</h3></StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell component="th" scope="row"></StyledTableCell>
                    <StyledTableCell component="th" scope="row"><b>Quotation value</b></StyledTableCell>
                    <StyledTableCell component="th" scope="row">₹ {commaSeparateAmount(displayArray[index]?.prevQuotationTotalAmount)}</StyledTableCell>
                  </StyledTableRow>
                  {
                    qtPreview.filter(data => data.spacename === 'POST DESIGN SIGN-OFF CHANGES').map((data, i) => {
                      return (
                        data.product.map((productItem, index) => (
                          (productItem?.negativeQtCr || productItem?.positiveQtCr) &&
                            (
                              <StyledTableRow key={`${i}-${index}`}>
                                <StyledTableCell component="th" scope="row">{productItem?.positiveQtCr ? "Add" : (productItem?.negativeQtCr ? "Less" : null)}</StyledTableCell>
                                <StyledTableCell component="th" scope="row">{productItem?.product.name}</StyledTableCell>
                                <StyledTableCell component="th" scope="row">₹ {commaSeparateAmount(productItem.productPrice)}</StyledTableCell>
                              </StyledTableRow>
                            )
                        ))
                      )
                    })
                  }
                  <StyledTableRow>
                    <StyledTableCell component="th" scope="row"></StyledTableCell>
                    <StyledTableCell component="th" scope="row"><b>Revise Quotation Value</b></StyledTableCell>
                    <StyledTableCell component="th" scope="row">₹ {commaSeparateAmount(balanceSheetData.reviseQuotation)}</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell component="th" scope="row">Less:</StyledTableCell>
                    <StyledTableCell component="th" scope="row">Discount @ {qtTotalAmouts.discountPercent}%</StyledTableCell>
                    <StyledTableCell component="th" scope="row">₹ {commaSeparateAmount(balanceSheetData.discount)}</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell component="th" scope="row"></StyledTableCell>
                    <StyledTableCell component="th" scope="row"><b>SubTotal</b></StyledTableCell>
                    <StyledTableCell component="th" scope="row">₹ {commaSeparateAmount(balanceSheetData.subTotal)}</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell component="th" scope="row"></StyledTableCell>
                    <StyledTableCell component="th" scope="row">GST @ 18%</StyledTableCell>
                    <StyledTableCell component="th" scope="row">₹ {commaSeparateAmount(balanceSheetData.gstAmount)}</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell component="th" scope="row"></StyledTableCell>
                    <StyledTableCell component="th" scope="row"><b>Total</b></StyledTableCell>
                    <StyledTableCell component="th" scope="row">₹ {commaSeparateAmount(balanceSheetData.total)}</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell component="th" scope="row"></StyledTableCell>
                    <StyledTableCell component="th" scope="row">Amount Paid</StyledTableCell>
                    <StyledTableCell component="th" scope="row">₹ {commaSeparateAmount(displayArray[index]?.sentForQuotationChangeApproval.amountPaid)}</StyledTableCell>
                  </StyledTableRow>
                  {
                    Object.entries(categoryObject).map(([key, value]) => {
                      // Check if the key represents a check and if its corresponding value is true
                      if (key.endsWith('Check') && value) {
                        const fieldName = key.slice(0, -5);
                        const capitalizedFieldName = fieldName.charAt(0).toUpperCase() + fieldName.slice(1); // Capitalize the first character
                        // Remove 'Check' from the key name
                        return (
                          <StyledTableRow key={key}>
                            <StyledTableCell component="th" scope="row">Less</StyledTableCell>
                            <StyledTableCell component="th" scope="row"><b>{capitalizedFieldName}</b></StyledTableCell>
                            <StyledTableCell component="th" scope="row">₹ {commaSeparateAmount(categoryObject[fieldName])}</StyledTableCell>
                          </StyledTableRow>
                        );
                      }
                      return null; // If the check field is false or if it's not a check field, return null
                    })
                  }
                  <StyledTableRow>
                    <StyledTableCell component="th" scope="row"></StyledTableCell>
                    <StyledTableCell component="th" scope="row"><b>Pending Due</b></StyledTableCell>
                    <StyledTableCell component="th" scope="row">₹ {commaSeparateAmount(displayArray[index]?.sentForQuotationChangeApproval?.balnceSheetPendingDueTotal)}</StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          
            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginRight: "3px" }}>
              {/* <Button onClick={viewUploadReceipt} variant="contained" color="secondary" style={{ background: "green", color: "white", marginRight: "5px" }}>View Payment Receipt</Button> */}
                <b> View Payment Receipt: </b>
                { displayArray[index]?.sentForQuotationChangeApproval?.paymentReceiptArray?.map((row, i) => {
                    return (
                      <span>
                        <DownloadOutlined
                          onClick={() => window.open(row)}
                          style={{ color: "green", cursor: "pointer", fontWeight: "bold", fontSize: '24px' , marginRight: '10px'}}
                        />
                      </span>
                    )
                  })
                }  
              <Button onClick={() => { setOpenRejectRemarkModal(true); }} variant="contained" color="secondary" style={{ marginRight: "5px" }}>Reject</Button>
              <Button onClick={() => { setOpenApproveRemarkModal(true); }} variant="contained" color="primary">Approve</Button>
            </div>
          
        </Modal>
      {/* Remark of reject dialog */}
        <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: 10 }}>
          <Dialog open={openRejectRemarkModal} onClose={() => setOpenRejectRemarkModal(false)}>
            <DialogTitle>Quotation Approval Remark</DialogTitle>
            <div style={{ padding: '16px' }}>
              <TextField
                id="outlined-basic"
                label="Remark"
                variant="outlined"
                value={remark}
                onChange={(e) => setRemark(e.target.value)}
                fullWidth
              />
            </div>
            <DialogActions>
              <Button onClick={() => setOpenRejectRemarkModal(false)} variant="outlined" color="primary">CANCEL</Button>
              <Button onClick={() => approveQuotation('Rejected')} variant="outlined" color="secondary">OK</Button>
            </DialogActions>
          </Dialog>
        </div>
      {/* Remark for Approve */}
        <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: 10 }}>
          <Dialog open={openApproveRemarkModal} onClose={() => setOpenApproveRemarkModal(false)}>
            <DialogTitle>Quotation Approval Remark</DialogTitle>
            <div style={{ padding: '16px' }}>
              <TextField
                id="outlined-basic"
                label="Remark"
                variant="outlined"
                value={remark}
                onChange={(e) => setRemark(e.target.value)}
                fullWidth
              />
            </div>
            <DialogActions>
              <Button onClick={() => setOpenApproveRemarkModal(false)} variant="outlined" color="primary">CANCEL</Button>
              <Button onClick={() => approveQuotation('Approved')} variant="outlined" color="secondary">OK</Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    )

}

export default connect(null, { setLoading, setSnackInfo })(QtChangesApproval);