import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  AppBar,
  Grid,
  TextField,
  Box,
  Paper,
  Tab,
  Tabs,
  Typography,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  CardActions,
  ImageList,
  ImageListItem
} from "@material-ui/core";
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import { useHistory, Link } from "react-router-dom";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PropTypes from "prop-types";
import HttpService from "../../services/httpService";
import { setLoading, setSnackInfo } from "../../redux/actions/appAction";
import { connect, useSelector } from "react-redux";
import { Modal } from "antd";
import moment from "moment";
import { CSVLink } from "react-csv";
import {
  CONST_USER_ID,
  PalaPrasanthKumarId,
  PalisettiTejaId,
  ROLENAME,
  SriKrishnaId,
  ThirumaleshId,
} from "../../utils";
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import CenterFocusWeakIcon from '@material-ui/icons/CenterFocusWeak';
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';
import { green, red, blue } from '@material-ui/core/colors';
import RepeatIcon from '@material-ui/icons/Repeat';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import VisibilityIcon from '@material-ui/icons/Visibility';
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  table: {
    width: "fit-content",
    // width: "auto", /* or specify a fixed width */
    // min-width: '100%', /* Set a minimum width */
  },
  tablecontainer: {
    display: "flex",
    margin: "25px",
  },
  tableform: {
    display: "flex",
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    maxWidth: 500,
  },
  clickableTableCell: {
    cursor: "pointer",
    textDecoration: "underline",
    color: "blue",
  },
  normalTableCell: {
    color: "black",
    "&:hover": {
      cursor: "pointer",
      color: "blue", // you can change the color when hovering here
      textDecoration: "underline",
    },
  },
  tableCell: {
    color: "blue",
    cursor: "pointer",
    textDecoration: "underline",

    // Changing color, cursor and textDecoration on hovering
    // "&:hover": {
    // 	cursor: "pointer",
    // 	color: "blue", // you can change the color when hovering here
    // 	textDecoration: "underline",
    // },
  },
  csv: {
    display: "flex",
    alignItems: "center",
    marginBottom: 20,
  },
  narrowTab: {
    minWidth: "fit-content", // Adjust the width as needed
    // width:"fit-content",
    width: 65,
  },
  margin: {
    margin: theme.spacing(1),
  },
  activeButton: {
    backgroundColor: "#F50057 !important",
    color: "white !important",
    transition: "background-color 0.3s, color 0.3s",
  },
  redButton: {
    backgroundColor: "#F50057 !important",
    color: "white !important",
    transition: "background-color 0.3s, color 0.3s",
    margin: "10px"
  },
  normalButton:{
    margin: "10px"
  },
  cardDialog: {
    minWidth: "80%",
    cursor: 'default'
  },
  autoComplete: {
    width: "200px",
  },
  searchField: {
    width: "800px",
  },
  viewIssueDialog:{
    minWidth: "80%"
  },
  imageList: {
    width: 500,
    // height: 450,
  },
  dialogMargin:{
    margin: "10px",
  },
  actionBar:{ display: "flex", justifyContent: "center", gap: "10px" },
  iconHover:{
  transition: "filter 0.3s ease",
  filter: "brightness(1.3)"
  },
  reassign:{
    minWidth: "500px",
    // minHeight: "300px",
  },
  reassignAutocomplete:{
    margin: "20px",
  },
  editDialogue:{
    minWidth: "600px",
    cursor: 'default'
  },
  dateSize:{
    width: "50%"
  },
  handOnHover:{
    cursor: "pointer"
  }
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#3F51B5",
    color: "#FFF",
    fontWeight: "500",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const CHMuserDashboard = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { userDetails } = useSelector((state) => state.user);
  const [ribbonTagValue, setRibbonTagValue] = useState("Pending");
  const [fetchData, setFetchData] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [viewIssueDialog, setViewIssueDialog] = useState(false);
  const [viewConfirmationDialog, setViewConfirmationDialog] = useState(false);
  const [viewDeleteConfirmationDialog, setViewDeleteConfirmationDialog] = useState(false);
  const [viewIssueData, setViewIssueData] = useState({});
  const [closeIssueData, setCloseIssueData] = useState({});
  const [resolveIssueData, setResolveIssueData] = useState({});
  const [allTicketCategory, setAllTicketCategory] = useState([]);
  const [selectedTicketCategory, setSelectedTicketCategory] = useState([]);
  const [allCHMusers, setAllCHMusers] = useState([]);
  const [selectedCHMusers, setSelectedCHMusers] = useState([]);
  const [searchTicketID, setSearchTicketID] = useState("");
  const [remarks, setRemarks] = useState("");
  const [ribbonNumberInfo, setRibbonNumberInfo] = useState({});
  const [deleteIssueData, setDeleteIssueData] = useState({});
  const [viewReassignIssueDialog, setViewReassignIssueDialog] = useState(false);
  const [viewEditIssueDialog, setViewEditIssueDialog] = useState(false);
  const [reassignIssueData, setReassignIssueData] = useState({});
  const [editIssueData, setEditIssueData] = useState({});
  const [editTicketTitle, setEditTicketTitle] = useState('');
  const [editTicketDes, setEditTicketDes] = useState('');
  const [editDueDate, setEditDueDate] = useState('');
  const [editFollowUpDate, setEditFollowUpDate] = useState('');

  const [allDepartments, setAllDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState({});
  const [usersOfSelectedTeam, setUsersOfSelectedTeam] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [selectedTeam, setSelectedTeam] = useState({});
  const [emailTag, setEmailTag] = useState("");
  // const [reRender, setReRender] = useState(false);

  const [viewOtherCHMTicket, setViewOtherCHMTicket] = useState(false); //handle other lead dialog-box
  const [viewOtherCHMTicketDetails, setViewOtherCHMTicketDetails] = useState([]); //store other lead details
  const csvButtonRef = useRef(null);
  const titleRef = useRef(null);
  const desciptionRef = useRef(null);

  const headers = [
    { key: "slno", label: "Sl No" },
    { key: "ticketNo", label: "Ticket No." },
    { key: "leadNo", label: "Lead No." },
    { key: "customerName", label: "Customer Name" },
    { key: "assignedTo", label: "Assigned To" },
    { key: "raisedBy", label: "Raised By" },
    { key: "ticketCreatedDate", label: "Ticket Created Date" },
    { key: "resolutionExpectedDate", label: "Resolution Expected Date" },
    { key: "daysPending", label: "Days Pending" },
    { key: "status", label: "Status" },
    { key: "category", label: "Category" },
  ];


  // Function to handle tab changes
  const handleChange = (newValue) => {
    setRibbonTagValue(newValue);
  };

  const makeDataForCsv = (arr)=>{

    let csvArrData = []
      arr.forEach((el,i)=>{
        let newObj = {
          slno:i+1,
          ticketNo:el.uniqueTicketNo || 'N/A',
          ticketNo:el.uniqueTicketNo || 'N/A',
          leadNo:el.leadNumber || 'N/A',
          customerName:el.customerId?.name || 'N/A',
          assignedTo:el.assignedTo?.name || 'N/A',
          raisedBy:el.ticketCreatedBy?.name || 'N/A',
          ticketCreatedDate: el.createdAt ? moment(el.createdAt).format("ll") : 'N/A',
          resolutionExpectedDate: el.expectedClosureDate ? moment(el.expectedClosureDate).format("ll") : 'N/A',
          daysPending: el?.expectedClosureDate
          ?
            Math.ceil(
              (new Date(el?.expectedClosureDate) - new Date()) /
                (24 * 60 * 60 * 1000)
            )
          : "N/A",
          status:el.status || 'N/A',
          category:el.category?.value || 'N/A'
        }
        csvArrData.push(newObj)
      })
    setCsvData(csvArrData)
  }

  // Function to get Material Delivery Project's count loction based
  const getFilteredTickets = async (noDateArg) => {
    props.setLoading(true);
    try {
      let dateObj = {
        startDate: startDate,
        endDate: endDate,
      };

      if (noDateArg === "noDate") {
        dateObj.startDate = "";
        dateObj.endDate = "";
      }

      const response = await HttpService.getFilteredTickets(
        ribbonTagValue,
        dateObj,
        selectedTicketCategory,
        selectedCHMusers
      );
      setFetchData(response.data);
      makeDataForCsv(response.data);
    } catch (error) {
      console.log("Error ->", error);
    }
    props.setLoading(false);
  };

  const getDataOnPageLoading = async () => {
    props.setLoading(true);
    try {
      const response = await HttpService.getAllCHMticketCategory();
      const departments = await HttpService.getDepartments();
      const response3 = await HttpService.getRibbonNumberInfo();

      setAllTicketCategory(response.data);
      const chmArr = departments?.data?.filter((el) => el?.name === "CHM")[0]
        ?.users;
      setAllCHMusers(chmArr);
      setRibbonNumberInfo(response3.data)

      setAllDepartments(departments.data);


    } catch (error) {
      console.log("Error ->", error);
    }
    props.setLoading(false);
  };

  const handleDateFilter = () => {
    getFilteredTickets();
  };

  // get data without date filtering
  const clearFilterDate = () => {
    setStartDate("");
    setEndDate("");
    getFilteredTickets("noDate");
  };

  const viewIssue = (e, data, i) => {
    setViewIssueDialog(true);
    setViewIssueData(data);
    // console.log('data is ',data)
  };

  const closeIssue = (e, data, i) => {
    setViewConfirmationDialog(true);
    setCloseIssueData(data);
  };

  const resolveIssue = (e, data, i) => {
    setViewConfirmationDialog(true);
    setResolveIssueData(data);
  };

  const deleteIssue = (e, data, i)=>{
    setViewDeleteConfirmationDialog(true);
    setDeleteIssueData(data);
  }
  const reassignIssue = (e, data, i)=>{
    setViewReassignIssueDialog(true);
    setReassignIssueData(data);
  }

  const editIssue = (e, data, i)=>{
    setEditIssueData(data);
    setViewEditIssueDialog(true);

    setEditTicketTitle(data.ticketTitle)
    setEditTicketDes(data.ticketDescription)
    // localStorage.setItem('editIssueTitle', data.ticketTitle);
    // localStorage.setItem('editIssueDes', data.ticketDescription);
    // setReRender(!reRender)
    // console.log('setEditIssueData is ',data)
  }

  const handleDialogClose = () => {
    setViewIssueDialog(false);
    setViewConfirmationDialog(false);
    setViewOtherCHMTicket(false);
  };

  const handleEditDialogClose = () => {
    setViewEditIssueDialog(false);
  };

  const handleCloseConfirm = async () => {
    try {
      const response = await HttpService.closeCHMticekt(closeIssueData._id, {remarks:remarks});

      if (response.status === 200) {
        props.setSnackInfo("Ticket Closed Succesfully", "Success");
        setViewConfirmationDialog(false);
        // setTimeout(() => {
        //   props.setSnackInfo("Loading Updated Data", "info");
        //   window.location.reload(true);
        // }, 1500);
        getDataOnPageLoading()
        getFilteredTickets()
        setViewConfirmationDialog(false)
      }
    } catch (err) {
      console.log(err);
      props.setSnackInfo(err.message, "Error");
    }
  };

  const handleResolveConfirm = async () => {
    try {
      const response = await HttpService.resolveCHMticekt(resolveIssueData._id, {remarks:remarks});

      if (response.status === 200) {
        props.setSnackInfo("Ticket Resolved Succesfully", "Success");
        setViewDeleteConfirmationDialog(false);
        // setTimeout(() => {
        //   props.setSnackInfo("Loading Updated Data", "info");
        //   window.location.reload(true);
        // }, 1500);
        getDataOnPageLoading()
        getFilteredTickets()
        setViewConfirmationDialog(false)
      }
    } catch (err) {
      console.log(err);
      props.setSnackInfo(err.message, "Error");
    }
  };

  const handleDeleteConfirm = async () => {
    try {
      const response = await HttpService.deleteCHMticekt(deleteIssueData._id);

      if (response.status === 200) {
        props.setSnackInfo("Ticket Deleted Succesfully", "Success");
        setViewConfirmationDialog(false);
        // setTimeout(() => {
        //   props.setSnackInfo("Loading Updated Data", "info");
        //   window.location.reload(true);
        // }, 1500);
        getDataOnPageLoading()
        getFilteredTickets()
        setViewDeleteConfirmationDialog(false)
      }
    } catch (err) {
      console.log(err);
      props.setSnackInfo(err.message, "Error");
    }
  };

  const handleCategorySelectionChange = (e, v) => {
    setSelectedTicketCategory(v.map((el) => el._id));
  };

  const handleCHMuserSelectionChange = (e, v) => {
    setSelectedCHMusers(v.map((el) => el._id));
  };

  const onChangeTicketID = (e) => {
    // console.log(e.target.value);
    setSearchTicketID(e.target.value);
  };

  const handleRemarksChange = (e) => {
    setRemarks(e.target.value);
  };

  const handleEditConfirm = async ()=>{
    try{
      // const obj = {
      //   title: titleRef.current.value,
      //   description: desciptionRef.current.value,
      //   ticketId: editIssueData._id
      // }
      const obj = {
        title: editTicketTitle,
        description: editTicketDes,
        ticketId: editIssueData._id,
        newDueDate: editDueDate,
        newFollowUpDate: editFollowUpDate,
      }

      const response = await HttpService.editTicketDetails(obj);

      if(response.status === 200){
        props.setSnackInfo('Success ', 'success')
        getDataOnPageLoading();
        getFilteredTickets();
        setViewEditIssueDialog(false);
        setEditIssueData({})
        
        if (titleRef.current) {
          titleRef.current.value = ''; // Reset the input value to empty string
        }
        if (desciptionRef.current) {
          desciptionRef.current.value = ''; // Reset the input value to empty string
        }
      }
    }
    catch(err){
      console.log(err)
    }
  }

  const handleDownloadAttachments = async ()=>{
    viewIssueData.attachedFiles.map(async (item) => (
    await window.open(item)
    ))
  }

  const searchByTicketId = async () => {
    props.setLoading(true);
    try {
      if (searchTicketID !== "") {
        const response = await HttpService.getSearchedTicket(searchTicketID);
        setFetchData(response.data);
        makeDataForCsv(response.data);
        if(response.data.status === 204){
          props.setSnackInfo('No results found', "Error");
        }
      }
    } catch (err) {
      console.log(err);
      props.setSnackInfo(err.message, "Error");
    }
    props.setLoading(false);
  };

  const csvDownloadHandler = async () => {
		setLoading(true);
		try {
			downloadExcel();
		} catch (error) {
			console.log("error", error);
			props?.setSnackInfo(
				error?.response?.data?.message ?? "Something went wrong",
				"error"
			);
		}
		setLoading(false);
	};

  const downloadExcel = () => {
		setLoading(true);
		try {
			setTimeout(() => {
				csvButtonRef.current.link.click();
			});
		} catch (err) {
			console.error(err.message);
		}
		setLoading(false);
	};

  const handleTeamSelectionAutocomplete = (v)=>{
    if(v!=null){
      setSelectedTeam(v)
      const teamUsers = selectedDepartment?.users?.filter((user)=> user?.teamId === v._id)
      setUsersOfSelectedTeam(teamUsers)
    }
  }


  const redirectToCreateTicket = async ()=>{
    history.push('/CHM-Ticket-Creation')
  }

//   const handleReassignIssueConfirm = async ()=>{
//           // console.log('reassignIssueData is ',reassignIssueData)

//     try{
//       const response = await HttpService.changeCHMticketAssignedUser(reassignIssueData._id, selectedUser._id, selectedDepartment._id, selectedTeam._id);
//       if(response.status == 200){
//         props.setSnackInfo('Success ', 'success')
//         getDataOnPageLoading()
//         getFilteredTickets()
//         setSelectedDepartment({})
//         setUsersOfSelectedTeam({})
//         setSelectedUser({})
//         setSelectedTeam({})
//         setViewReassignIssueDialog(false)
//       }
//     }
//     catch(error){
//       console.log(error);
//       props.setSnackInfo('Error occoured', 'error')
//   }
// }

  const handleReassignIssueConfirm = async ()=>{
          // console.log('reassignIssueData is ',reassignIssueData)

    try{
      const response = await HttpService.changeCHMticketAssignedUserFromEmail(reassignIssueData._id, emailTag);
      if(response.status == 200){
        props.setSnackInfo('Success ', 'success')
        getDataOnPageLoading()
        getFilteredTickets()
        setSelectedDepartment({})
        setUsersOfSelectedTeam({})
        setSelectedUser({})
        setSelectedTeam({})
        setViewReassignIssueDialog(false)
      }
    }
    catch(error){
      console.log('error is ',error);
      if(error.response.status == 400){
        props.setSnackInfo(error.response.data, 'error')
      }
      else{
        props.setSnackInfo('Error occoured', 'error')
      }
  }
}

const handleEditDueDateChange = (e) => {
  // setTicketETAdate(e.target.value)
  const selectedDate = new Date(e.target.value);
  const currentDate = new Date();
  currentDate.setHours(0,0,0,0)

  // Check if the selected date is in the future or today
  if (selectedDate >= currentDate) {
    setEditDueDate(e.target.value);
  } else {
    props.setSnackInfo("Please select a date in the present or future.",'warning');
  }
};

const handleEditFollowUpDateChange = (e) => {
  // setTicketETAdate(e.target.value)
  const selectedDate = new Date(e.target.value);
  const currentDate = new Date();
  currentDate.setHours(0,0,0,0)

  // Check if the selected date is in the future or today
  if (selectedDate >= currentDate) {
    setEditFollowUpDate(e.target.value);
  } else {
    props.setSnackInfo("Please select a date in the present or future.",'warning');
  }
};

const handleEmailTagChange = (e,v)=>{
  setEmailTag(e.target.value);
}


  // get data on ribbon filter change
  useEffect(() => {
    getFilteredTickets();
  }, [ribbonTagValue, selectedTicketCategory, selectedCHMusers]);

  useEffect(() => {
    getDataOnPageLoading();
  }, []);

  //handle dialog on click on visible icon
  const handleOtherTicketDetails = (data) =>{
    let store = [data.otherProjectDetails]
    setViewOtherCHMTicketDetails(store)
    setViewOtherCHMTicket(true)
  }



  return (
    <Fragment>
      {userDetails?.roles.find(
              (role) =>
                role.name === ROLENAME.CHM_Manager ||
                role.name === ROLENAME.Admin ||
                role.name === ROLENAME.CHM_User ||
                role.name === ROLENAME.TeleCallerUser ||
                role.name === ROLENAME.TeleCallerManager
            ) && ( <Fragment>
        <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center">
          <Button
            size="small"
            id="redirect-btn"
            onClick={redirectToCreateTicket}
            style={{ marginLeft: 20, marginBottom:20}}
            variant="contained"
            color="primary"
          >
            Create Ticket
          </Button>
        </Grid>
      </Fragment>)}
      <Fragment>
        {ribbonTagValue !== "Overdue" &&
          ribbonTagValue !== "Due Today" &&
          ribbonTagValue !== "Due Tomorrow" && (
            <Grid
              container
              style={{
                display: "flex",
                alignItems: "center",
                width: "70vw",
                marginLeft: 10,
                marginBottom: 10,
              }}
            >
              <Grid item md={3} xs={12}>
                <TextField
                  id="date"
                  name="startDate"
                  label="Start Date"
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  className={classes.textField}
                />
              </Grid>
              <Grid item md={1} xs={12}></Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  id="date"
                  name="endDate"
                  label="End Date"
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  className={classes.textField}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!startDate || !endDate}
                  onClick={handleDateFilter}
                  style={{ marginLeft: 10 }}
                >
                  Submit
                </Button>
              </Grid>
              <Grid item md={2} xs={12}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={clearFilterDate}
                >
                  Clear Filter
                </Button>
              </Grid>
            </Grid>
          )}
      </Fragment>

      <Fragment>
        <div className={classes.root}>
          {/* Created Tickets Button */}

          <Fragment>
            {
              // userDetails?.roles.find(
              //   (role) =>
              //     role.name === ROLENAME.CHM_Manager ||
              //     role.name === ROLENAME.Admin ||
              //     role.name === ROLENAME.CHM_User
              // ) &&
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => handleChange("Pending")}
                className={
                  ribbonTagValue === "Pending" ? classes.activeButton : ""
                }
              >
                Pending ({ribbonNumberInfo.pendingCount})
              </Button>
            }
          </Fragment>

              <Fragment>
                {
                  // userDetails?.roles.find(
                  //   (role) =>
                  //     role.name === ROLENAME.CHM_Manager ||
                  //     role.name === ROLENAME.Admin ||
                  //     role.name === ROLENAME.CHM_User
                  // ) &&
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={() => handleChange("Overdue")}
                    className={
                      ribbonTagValue === "Overdue" ? classes.activeButton : ""
                    }
                  >
                    Overdue ({ribbonNumberInfo.overDueCount})
                  </Button>
                }
              </Fragment>

              <Fragment>
                {
                  // userDetails?.roles.find(
                  //   (role) =>
                  //     role.name === ROLENAME.CHM_Manager ||
                  //     role.name === ROLENAME.Admin ||
                  //     role.name === ROLENAME.CHM_User
                  // ) &&
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={() => handleChange("Due Today")}
                    className={
                      ribbonTagValue === "Due Today" ? classes.activeButton : ""
                    }
                  >
                    Due Today ({ribbonNumberInfo.dueTodayCount})
                  </Button>
                }
              </Fragment>
    
              <Fragment>
                {
                  // userDetails?.roles.find(
                  //   (role) =>
                  //     role.name === ROLENAME.CHM_Manager ||
                  //     role.name === ROLENAME.Admin ||
                  //     role.name === ROLENAME.CHM_User
                  // ) &&
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={() => handleChange("Due Tomorrow")}
                    className={
                      ribbonTagValue === "Due Tomorrow" ? classes.activeButton : ""
                    }
                  >
                    Due Tomorrow({ribbonNumberInfo.dueTommorowCount})
                  </Button>
                }
              </Fragment>
          <Fragment>
            {
              // userDetails?.roles.find(
              //   (role) =>
              //     role.name === ROLENAME.CHM_Manager ||
              //     role.name === ROLENAME.Admin ||
              //     role.name === ROLENAME.CHM_User
              // ) &&
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => handleChange("Resolved")}
                className={
                  ribbonTagValue === "Resolved" ? classes.activeButton : ""
                }
              >
                Resolved Tickets ({ribbonNumberInfo.resolvedCount})
              </Button>
            }
          </Fragment>


          {/* <Fragment>
            {userDetails?.roles.find(
              (role) =>
                role.name === ROLENAME.CHM_Manager ||
                role.name === ROLENAME.Admin ||
                role.name === ROLENAME.CHM_User
            ) && (
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => handleChange("Open")}
                className={
                  ribbonTagValue === "Open" ? classes.activeButton : ""
                }
              >
                Open
              </Button>
            )}
          </Fragment> */}

          <Fragment>
            {
              // userDetails?.roles.find(
              //   (role) =>
              //     role.name === ROLENAME.CHM_Manager ||
              //     role.name === ROLENAME.Admin ||
              //     role.name === ROLENAME.CHM_User
              // ) &&
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => handleChange("Closed")}
                className={
                  ribbonTagValue === "Closed" ? classes.activeButton : ""
                }
              >
                Closed ({ribbonNumberInfo.closedCount})
              </Button>
            }
          </Fragment>

          {/* <Fragment>
            {userDetails?.roles.find(
              (role) =>
                role.name === ROLENAME.CHM_Manager ||
                role.name === ROLENAME.Admin ||
                role.name === ROLENAME.CHM_User
            ) && (
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => handleChange("Unresolved")}
                className={
                  ribbonTagValue === "Unresolved" ? classes.activeButton : ""
                }
              >
                Unresolved Tickets
              </Button>
            )}
          </Fragment> */}



          <Fragment>
            {userDetails?.roles.find(
              (role) =>
                role.name === ROLENAME.CHM_Manager ||
                role.name === ROLENAME.OperationHead ||
                role.name === ROLENAME.Admin ||
                role.name === ROLENAME.CHM_User ||
                role.name === ROLENAME.TeleCallerUser ||
                role.name === ROLENAME.TeleCallerManager
            ) && (
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => handleChange("Created")}
                className={
                  ribbonTagValue === "Created" ? classes.activeButton : ""
                }
              >
                All Tickets({ribbonNumberInfo.allCount})
              </Button>
            )}
          </Fragment>
          

          <Fragment>
            <Grid
              container
              style={{
                display: "flex",
                alignItems: "center",
                width: "70vw",
                // marginLeft: 25,
                marginBottom: 25,
              }}
            >
              <TextField
                id="ticketIdSearch"
                name="ticketIdSearch"
                label="Search Tickets(Ticket ID, Lead No, Project No., Unique Lead No., Customer Name)"
                type="string"
                variant="outlined"
                value={searchTicketID}
                onChange={onChangeTicketID}
                InputLabelProps={{
                  shrink: true,
                }}
                className={classes.searchField}
              />

              <Button
                variant="outlined"
                color="primary"
                size="small"
                id="search-btn"
                onClick={searchByTicketId}
                className={classes.textField}
                disabled={searchTicketID === ""}
                style={{ marginLeft: 20 }}
              >
                Search
              </Button>
            </Grid>
          </Fragment>

          <Fragment>
            <Grid
              container
              style={{
                display: "flex",
                alignItems: "center",
                width: "80vw",
                marginBottom: 25,
              }}
            >
              {
                // userDetails?.roles.find(
                //   (role) =>
                //     role.name === ROLENAME.CHM_Manager ||
                //     role.name === ROLENAME.Admin ||
                //     role.name === ROLENAME.CHM_User
                // ) &&
                <Autocomplete
                  style={{ width: 250 }}
                  multiple
                  id="tags-outlined"
                  options={allTicketCategory}
                  getOptionLabel={(option) => option.value}
                  filterSelectedOptions
                  onChange={handleCategorySelectionChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Categories Selected"
                      placeholder="Categories Selected"
                    />
                  )}
                />
              }

              <Grid item md={1} xs={12}></Grid>

              {userDetails?.roles.find(
                (role) =>
                  role.name === ROLENAME.CHM_Manager ||
                  role.name === ROLENAME.OperationHead ||
                  role.name === ROLENAME.Admin
              ) && (
                <Autocomplete
                  style={{ width: 250 }}
                  multiple
                  id="tags-outlined"
                  options={allCHMusers}
                  getOptionLabel={(option) => option.name}
                  filterSelectedOptions
                  onChange={handleCHMuserSelectionChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="CHM user Selected"
                      placeholder="CHM user Selected"
                    />
                  )}
                />
              )}

              <CSVLink
                headers={headers}
                filename={"CHM tickets " + ribbonTagValue + ".csv"}
                data={csvData}
                ref={csvButtonRef}
              />
              <Button
                onClick={csvDownloadHandler}
                style={{
                  marginLeft: 20,
                  backgroundColor: "green",
                  color: "white",
                  height: 30,
                }}
                variant="contained"
                color="success"
              >
                Download CSV
              </Button>
            </Grid>
          </Fragment>
        </div>
      </Fragment>

      {fetchData && (
        <TableContainer component={Paper} className={classes.table}>
          <Table
            // style={{ width: 450 }}
            className={classes.table}
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead>
              <StyledTableRow key='tableHeaders'>
                <StyledTableCell>Sl. No.</StyledTableCell>
                <StyledTableCell>Ticket No.</StyledTableCell>
                <StyledTableCell>Lead No.</StyledTableCell>
                <StyledTableCell>Customer name</StyledTableCell>
                <StyledTableCell>Assigned To</StyledTableCell>
                <StyledTableCell>Raised by</StyledTableCell>
                <StyledTableCell>Ticket created date</StyledTableCell>
                <StyledTableCell>Resolution expected date</StyledTableCell>
                {!(ribbonTagValue === "Closed") && <StyledTableCell>Days pending</StyledTableCell>}
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Category</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
                {/* <StyledTableCell>View issue button</StyledTableCell> */}
                {/* {userDetails?.roles.find(
                  (role) =>
                    role.name === ROLENAME.CHM_User ||
                    role.name === ROLENAME.CHM_Manager ||
                    role.name === ROLENAME.Admin
                ) ? (
                  !(ribbonTagValue === "Closed") ? (
                    <StyledTableCell>Close ticket button</StyledTableCell>
                  ) : null
                ) : !(ribbonTagValue === "Resolved") ? (
                  <StyledTableCell>Resolve ticket button</StyledTableCell>
                ) : null} */}
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {fetchData?.map((data, i) => {
                return (
                  <StyledTableRow key={i}>
                    <StyledTableCell component="th">{i + 1}</StyledTableCell>

                    <StyledTableCell
                      component="th"
                      style={{ textAlign: "center" }}
                    >
                      {data?.uniqueTicketNo || "NA"}
                    </StyledTableCell>

                    <StyledTableCell
                      component="th"
                      style={{ textAlign: "center" }}
                    >
                      {data?.leadNumber || "N/A"}
                    </StyledTableCell>

                    <StyledTableCell component="th" style={{ textAlign: "center" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div style={{ marginRight: 6 }}>
                            {data?.customerId?.name || data?.otherProjectDetails?.name}
                          </div>
                          {data?.otherProjectDetails?.name && (
                            <VisibilityIcon
                              style={{ cursor: "pointer" }}
                              onClick={() => handleOtherTicketDetails(data)}
                            />
                          )}
                        </div>
                    </StyledTableCell>

                    <StyledTableCell
                      component="th"
                      style={{ textAlign: "center" }}
                    >
                      {data?.assignedTo?.name || "N/A"}
                    </StyledTableCell>

                    <StyledTableCell
                      component="th"
                      style={{ textAlign: "center" }}
                    >
                      {data?.ticketCreatedBy?.name || "N/A"}
                    </StyledTableCell>

                    <StyledTableCell
                      component="th"
                      style={{ textAlign: "center" }}
                    >
                      {data?.createdAt
                        ? moment(data?.createdAt).utc().format("DD-MM-YYYY")
                        : "-"}
                    </StyledTableCell>

                    <StyledTableCell
                      component="th"
                      style={{ textAlign: "center" }}
                    >
                      {data?.expectedClosureDate
                        ? moment(data?.expectedClosureDate)
                            .utc()
                            .format("DD-MM-YYYY")
                        : "-"}
                    </StyledTableCell>

                    {!(ribbonTagValue === "Closed") && <StyledTableCell
                      component="th"
                      style={{ textAlign: "center" }}
                    >
                      {data?.expectedClosureDate
                        ? // Check if expectedClosureDate is a valid date
                          Math.ceil(
                            (new Date(data?.expectedClosureDate) - new Date()) /
                              (24 * 60 * 60 * 1000)
                          )
                        : "N/A"}
                    </StyledTableCell>}

                    <StyledTableCell
                      component="th"
                      style={{ textAlign: "center" }}
                    >
                      {data?.status || "N/A"}
                    </StyledTableCell>

                    <StyledTableCell
                      component="th"
                      style={{ textAlign: "center" }}
                    >
                      {data?.category?.value || "N/A"}
                    </StyledTableCell>

                    <StyledTableCell
                      component="th"
                      style={{ textAlign: "center" }}
                    >
                      <div className={classes.actionBar}>
                        <VisibilityRoundedIcon
                        className={classes.handOnHover}
                          style={{ color: green[500] }}
                          onClick={(e) => {
                            viewIssue(e, data, i);
                          }}
                          titleAccess="View Issue"
                        />
                        {userDetails?.roles.find(
                          (role) =>
                            role.name === ROLENAME.Admin ||
                            role.name === ROLENAME.OperationHead ||
                            role.name === ROLENAME.CHM_Manager ||
                            role.name === ROLENAME.CHM_User ||
                            role.name === ROLENAME.TeleCallerUser ||
                            role.name === ROLENAME.TeleCallerManager
                        )
                          ? ribbonTagValue !== "Closed" &&
                            !(data?.status === "Closed") && (
                              <CenterFocusWeakIcon
                              className={classes.handOnHover}
                                style={{ color: blue[500] }}
                                onClick={(e) => {
                                  closeIssue(e, data, i);
                                }}
                                titleAccess="Close Issue"
                              />
                            )
                          : ribbonTagValue !== "Resolved" &&
                            ribbonTagValue !== "Closed" &&
                            !(data?.status === "Resolved") &&
                            !(data?.status === "Closed") && (
                              <CenterFocusWeakIcon
                                className={classes.handOnHover}
                                style={{ color: blue[500] }}
                                onClick={(e) => {
                                  resolveIssue(e, data, i);
                                }}
                                titleAccess="Resolve Issue"
                              />
                            )}

                        {userDetails?.roles.find(
                          (role) => role.name === ROLENAME.Admin
                        ) && (
                          <DeleteOutlineRoundedIcon
                            className={classes.handOnHover}
                            style={{ color: red[500] }}
                            onClick={(e) => {
                              deleteIssue(e, data, i);
                            }}
                            titleAccess="Delete Issue"
                          />
                        )}
                        {userDetails?.roles.find(
                          (role) =>
                            role.name === ROLENAME.Admin ||
                            role.name === ROLENAME.OperationHead ||
                            role.name === ROLENAME.CHM_Manager ||
                            role.name === ROLENAME.CHM_User ||
                            role.name === ROLENAME.TeleCallerManager ||
                            role.name === ROLENAME.TeleCallerUser ||
                            role.name === ROLENAME.DGM_Design ||
                            role.name.toLowerCase().includes('manager')
                        ) && (
                          <RepeatIcon
                            className={classes.handOnHover}
                            style={{ color: blue[500] }}
                            onClick={(e) => {
                              reassignIssue(e, data, i);
                            }}
                            titleAccess="Reassign Issue"
                          />
                        )}
                        {userDetails?.roles.find(
                          (role) =>
                            role.name === ROLENAME.Admin ||
                            role.name === ROLENAME.CHM_Manager ||
                            role.name === ROLENAME.CHM_User ||
                            role.name === ROLENAME.TeleCallerManager ||
                            role.name === ROLENAME.TeleCallerUser
                        ) && (
                          <EditOutlinedIcon
                            className={classes.handOnHover}
                            style={{ color: red[500] }}
                            onClick={(e) => {
                              editIssue(e, data, i);
                            }}
                            titleAccess="Edit Issue"
                          />
                        )}
                      </div>
                    </StyledTableCell>

                    {/* <StyledTableCell
                      component="th"
                      style={{ textAlign: "center" }}
                    >
                      <Button
                        color="primary"
                        variant="contained"
                        style={{
                          padding: 5,
                          width: 120,
                          textAlign: "center",
                          margin: 20,
                          height: 40,
                        }}
                        onClick={(e) => {
                          viewIssue(e, data, i);
                        }}
                      >
                        View Issue
                      </Button>
                    </StyledTableCell> */}

                    {/* {userDetails?.roles.find(
                      (role) =>
                        role.name === ROLENAME.Admin ||
                        role.name === ROLENAME.CHM_Manager ||
                        role.name === ROLENAME.CHM_User
                    )
                      ? ribbonTagValue !== "Closed" && (
                          <StyledTableCell
                            component="th"
                            style={{ textAlign: "center" }}
                          >
                            <Button
                              color="secondary"
                              variant="contained"
                              style={{
                                padding: 5,
                                width: 120,
                                textAlign: "center",
                                margin: 20,
                                height: 40,
                              }}
                              onClick={(e) => {
                                closeIssue(e, data, i);
                              }}
                              disabled={data?.status === "Closed"}
                            >
                              Close Issue
                            </Button>
                          </StyledTableCell>
                        )
                      : ribbonTagValue !== "Resolved" &&
                        ribbonTagValue !== "Closed" && (
                          <StyledTableCell
                            component="th"
                            style={{ textAlign: "center" }}
                          >
                            <Button
                              color="secondary"
                              variant="contained"
                              style={{
                                padding: 5,
                                width: 120,
                                textAlign: "center",
                                margin: 20,
                                height: 40,
                              }}
                              onClick={(e) => {
                                resolveIssue(e, data, i);
                              }}
                              disabled={data?.status === "Resolved"}
                            >
                              Resolve Issue
                            </Button>
                          </StyledTableCell>
                        )} */}
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Fragment>
        <Dialog
          open={viewIssueDialog}
          keepMounted
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          className={classes.viewIssueDialog}
        >
          <Card className={classes.root}>
            <CardActionArea className={classes.cardDialog}>
              <CardContent className={classes.cardDialog}>
                <Typography gutterBottom variant="h5" component="h2">
                  Title
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  className={classes.margin}
                >
                  {viewIssueData.ticketTitle}
                </Typography>

                <Typography gutterBottom variant="h5" component="h2">
                  Description
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  className={classes.margin}
                  style={{ marginBottom: 10 }}
                >
                  {viewIssueData.ticketDescription}
                </Typography>

                <ImageList
                  rowHeight={160}
                  className={classes.imageList}
                  cols={3}
                >
                  {viewIssueData?.attachedFiles?.length > 0 &&
                    viewIssueData.attachedFiles.map(
                      (item) =>
                        (item.endsWith(".jpg") ||
                          item.endsWith(".png") ||
                          item.endsWith(".jpeg")) && (
                          <ImageListItem key={item} cols={1}>
                            <img src={item} alt={"img not found"} />
                          </ImageListItem>
                        )
                    )}
                </ImageList>

                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="h2"
                    style={{ marginTop: 10, marginRight: 10 }}
                  >
                    Download Attachments
                  </Typography>
                  <GetAppRoundedIcon onClick={handleDownloadAttachments} />
                </div>

                <Typography
                  gutterBottom
                  variant="h5"
                  component="h2"
                  style={{ marginTop: 10 }}
                >
                  Remarks
                </Typography>

                {viewIssueData?.logs?.length > 0 &&
                  // <div>hello</div>

                  viewIssueData.logs.map(
                    (log, i) =>
                      log?.remarks && (
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                          className={classes.margin}
                        >
                          {`${i + 1}. ${log?.remarks} - ${
                            log?.createdBy?.name
                          }`}
                        </Typography>
                      )
                  )}
              </CardContent>
            </CardActionArea>
          </Card>
        </Dialog>
      </Fragment>

      <Dialog open={viewOtherCHMTicket} maxWidth="mx"> {/* maxWidth can be adjusted as needed */}
            <DialogTitle id="form-dialog-title" style= {{padding : '2rem 2rem 0.4rem 2rem'}}>Other CHM Ticket Details</DialogTitle>
            <DialogContent>
                {viewOtherCHMTicketDetails?.length > 0 &&  viewOtherCHMTicketDetails?.map((el, index) => (
                  <>
                    <div key={index} style={{ marginBottom: '20px' }}>
                      <p style={{margin: "0.5rem"}}> <strong>Name:</strong> {el?.name ? el.name : "NA"}</p>
                      <p style={{margin: "0.5rem"}}>< strong>Phone : </strong> {el?.mobile ? el.mobile : "NA"}</p>
                      <p style={{margin: "0.5rem"}}><strong> Email : </strong>  {el?.email ? el.email : "NA"}</p>
                    </div>
                    <Button variant="outlined" color="primary" size="small" style={{float:'inline-end', marginTop:"4px"}} onClick={handleDialogClose}>Close</Button>
                    </>
                ))}
            </DialogContent>
      </Dialog>
      <Fragment>
        <Dialog
          open={viewConfirmationDialog}
          // TransitionComponent={Transition}
          keepMounted
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <Card className={classes.root}>
            <CardActionArea className={classes.cardDialog}>
              <CardContent className={classes.cardDialog}>
                <Typography gutterBottom variant="h6" component="h2">
                  Confirmation
                </Typography>

                {userDetails?.roles.find(
                  (role) =>
                    role.name === ROLENAME.CHM_Manager ||
                    role.name === ROLENAME.OperationHead ||
                    role.name === ROLENAME.Admin ||
                    role.name === ROLENAME.CHM_User ||
                    role.name === ROLENAME.TeleCallerUser ||
                    role.name === ROLENAME.TeleCallerManager
                ) ? (
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Are you sure you want to close this ticket
                  </Typography>
                ) : (
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Click confirm to Resolve this ticket
                  </Typography>
                )}

                {/* {userDetails?.roles.find(
                  (role) => role.name === ROLENAME.CHM_User
                ) && (
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Click Confirm to mark this issue as resolved
                  </Typography>
                )} */}
              </CardContent>
            </CardActionArea>

            <CardActions>
              <TextField
                id="remarks"
                name="remarks"
                label="Remarks"
                type="string"
                value={remarks}
                onChange={handleRemarksChange}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                className={classes.textField}
              />
            </CardActions>

            <CardActions>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={handleDialogClose}
                className={
                  ribbonTagValue === "Category" ? classes.activeButton : ""
                }
              >
                Cancel
              </Button>

              {userDetails?.roles.find(
                (role) =>
                  role.name === ROLENAME.CHM_Manager ||
                  role.name === ROLENAME.OperationHead ||
                  role.name === ROLENAME.Admin ||
                  role.name === ROLENAME.CHM_User ||
                  role.name === ROLENAME.TeleCallerUser ||
                  role.name === ROLENAME.TeleCallerManager
              ) ? (
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={handleCloseConfirm}
                  className={
                    ribbonTagValue === "Category" ? classes.activeButton : ""
                  }
                >
                  Confirm
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={handleResolveConfirm}
                  className={
                    ribbonTagValue === "Category" ? classes.activeButton : ""
                  }
                >
                  Confirm
                </Button>
              )}
            </CardActions>
          </Card>
        </Dialog>
      </Fragment>
      <Fragment>
        <Dialog
          open={viewDeleteConfirmationDialog}
          // TransitionComponent={Transition}
          keepMounted
          onClick={() => {
            setViewDeleteConfirmationDialog(false);
          }}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <Card className={classes.root}>
            <CardActionArea className={classes.cardDialog}>
              <CardContent className={classes.cardDialog}>
                <Typography gutterBottom variant="h6" component="h2">
                  Confirmation
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Are you sure you want to delete this ticket
                </Typography>
              </CardContent>
            </CardActionArea>

            {/* <CardActions>
              <TextField
                id="remarks"
                name="remarks"
                label="Remarks"
                type="string"
                value={remarks}
                onChange={handleRemarksChange}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                className={classes.textField}
              />
            </CardActions> */}

            <CardActions>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => {
                  setViewDeleteConfirmationDialog(false);
                }}
                className={
                  ribbonTagValue === "Category" ? classes.activeButton : ""
                }
              >
                Cancel
              </Button>

              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={handleDeleteConfirm}
                className={
                  ribbonTagValue === "Category" ? classes.activeButton : ""
                }
              >
                Delete
              </Button>
            </CardActions>
          </Card>
        </Dialog>

        {/* <Dialog
          open={viewReassignIssueDialog}
          keepMounted
          onClose={() => {
            setViewReassignIssueDialog(false);
        }}
        >
          <Grid
              container
              className={classes.reassign}
              direction="column"
              justifyContent="space-evenly"
              alignItems="stretch"
              key="ReassignGridContainer"
            >

          <Autocomplete
                value={selectedDepartment}
                onChange={(e, newValue) => newValue ? setSelectedDepartment(newValue) : setSelectedDepartment({})}
                options={allDepartments}
                getOptionLabel={(option) => option?.name}
                className={classes.reassignAutocomplete}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Tag Department"
                    fullWidth
                    variant="outlined"
                  />
                )}
              />

              {selectedDepartment?.teams && (
                <Autocomplete
                  value={selectedTeam}
                  onChange={(e, newValue) => handleTeamSelectionAutocomplete(newValue)}
                  options={selectedDepartment?.teams}
                  getOptionLabel={(option) => option?.name}
                  className={classes.reassignAutocomplete}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Tag Team"
                      fullWidth
                      variant="outlined"
                    />
                  )}
                />
              )}

              {selectedDepartment?.users && (
                <Autocomplete
                  value={selectedUser}
                  onChange={(e, newValue) => newValue ? setSelectedUser(newValue) : setSelectedUser({})}
                  options={usersOfSelectedTeam}
                  getOptionLabel={(option) => option?.name}
                  className={classes.reassignAutocomplete}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Tag User"
                      fullWidth
                      variant="outlined"
                    />
                  )}
                />
              )}

        </Grid>
        <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
        >
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => {
                  setViewReassignIssueDialog(false);
                }}
                className={
                  classes.redButton
                }
              >
                Cancel
              </Button>

              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={handleReassignIssueConfirm}
                className={
                  classes.normalButton
                }
              >
                Confirm
              </Button>
        </Grid>
        </Dialog> */}

        <Dialog
          open={viewReassignIssueDialog}
          keepMounted
          onClose={() => {
            setViewReassignIssueDialog(false);
        }}
        >
          <Grid
              container
              className={classes.reassign}
              direction="column"
              justifyContent="space-evenly"
              alignItems="stretch"
              key="ReassignGridContainer"
            >
              <h3 className={classes.tablecontainer}>Enter User  Email</h3>
          <TextField
          id="emailTagging"
          name="Tag Email"
          type="email"
          variant="outlined"
          // placeholder="Enter..."
          onChange={(e, v) => handleEmailTagChange(e, v)}
          value={emailTag}
          InputLabelProps={{
            // shrink: true,
          }}
          className={classes.reassignAutocomplete}
          >
          </TextField>

        </Grid>
        <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
        >
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => {
                  setViewReassignIssueDialog(false);
                }}
                className={
                  classes.redButton
                }
              >
                Cancel
              </Button>

              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={handleReassignIssueConfirm}
                className={
                  classes.normalButton
                }
              >
                Confirm
              </Button>
        </Grid>
        </Dialog>
      </Fragment>

      <Fragment>
        <Dialog
          open={viewEditIssueDialog}
          keepMounted
          onClose={handleEditDialogClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          className={classes.editDialogue}
        >
          <Card className={classes.editDialogue}>
            <CardActionArea className={classes.cardDialog}>
              <CardContent className={classes.cardDialog}>
                <Typography gutterBottom variant="h5" component="h2">
                  New Title
                </Typography>

                <TextField
                  id="editTicketTitle"
                  inputRef={titleRef}
                  // label="Helper text"
                  value={editTicketTitle}
                  // defaultValue={JSON.stringify(localStorage.getItem('editIssueTitle'))}
                  // defaultValue='default text'
                  // defaultValue={editTicketTitle}
                  
                  // helperText={`Old Title: ${editIssueData.ticketTitle}`}
                  variant="outlined"
                  className={classes.margin}
                  onChange={(e)=> setEditTicketTitle(e.target.value)}
                >
                </TextField>

                <Typography gutterBottom variant="h5" component="h2">
                  New Description
                </Typography>
                <TextField
                  id="editTicketDescription"
                  value={editTicketDes}
                  inputRef={desciptionRef}
                  // helperText={`Old Description: ${editIssueData.ticketDescription}`}
                  variant="outlined"
                  className={classes.margin}
                  onChange={(e)=> setEditTicketDes(e.target.value)}
                >
                  
                </TextField>

                <div>

                </div>
                <TextField
                  id="date"
                  name="dueDate"
                  label="Due Date"
                  type="date"
                  value={editDueDate}
                  onChange={handleEditDueDateChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  className={classes.margin + " " + classes.dateSize}
                />

                <TextField
                  id="date"
                  name="followUpDate"
                  label="Follow Up Date"
                  type="date"
                  value={editFollowUpDate}
                  onChange={handleEditFollowUpDateChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  className={classes.margin + " " + classes.dateSize}
                />

                
                <CardActions>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={()=> setViewEditIssueDialog(false)}
                    className={classes.activeButton}
                  >
                    Cancel
                  </Button>

                  <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      onClick={handleEditConfirm}
                      className={
                        ribbonTagValue === "Category" ? classes.activeButton : ""
                      }
                    >
                      Update
                    </Button>
              </CardActions>

              </CardContent>
            </CardActionArea>
          </Card>
        </Dialog>
      </Fragment>

    </Fragment>
  );
};

export default connect(null, { setLoading, setSnackInfo })(CHMuserDashboard);
