import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import HttpService from '../../services/httpService'
import { connect, useSelector } from 'react-redux'
import { useParams } from "react-router-dom";
import { setLoading, setSnackInfo } from '../../redux/actions/appAction'
import { Typography, Paper, Table, TableBody, TableContainer, TableHead, TableRow, TableCell } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
// import 'antd/dist/antd.css';

const useStyles = makeStyles
(
    {
    table: {
        minWidth: 700,
    },
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    input: {
        flex: 1,
    },
    iconButton: {
        padding: 10,
        width: '10%'
    },
    divider: {
        height: 28,
        margin: 4,
    },
    }
);

const StyledTableCell = withStyles((theme) => 
(
    {
    head: {
        backgroundColor: "#ccc",
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
    }
))(TableCell);

const StyledTableRow = withStyles((theme) => 
(
    {
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
    }
))(TableRow);

const ChmUserLeads = (props) => 
{
    const classes = useStyles();
    const { chmUserId } = useParams();
    const { userDetails } = useSelector(state => state.user)

    useEffect(() => {
        getCHMLeadLists()
        // eslint-disable-next-line
    }, [])

    const [chmLeadList, setChmLeadList] = useState([])

    const getCHMLeadLists = async () => 
    {
        props.setLoading(true)
         try
         {
            let result = await HttpService.getCHMLeadLists(userDetails.selectedExpCenter);
            result.data = result.data.filter(el=>el.assignTo._id == chmUserId)
            setChmLeadList(result.data)
         }
         catch (error)
         {
            console.error(error)
            props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    return (
        <div>
            <Paper>
                <h2 align='center'>CHM Leads</h2>
                <TableContainer component={Paper}>
                    <Table className={classes.table} stickyHeader aria-label="sticky table">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell align="center" style={{ verticalAlign: 'top' }}>Sr. No.</StyledTableCell>
                                <StyledTableCell align="center" style={{ verticalAlign: 'top' }}>Project No.</StyledTableCell>
                                <StyledTableCell align="center" style={{ verticalAlign: 'top' }}>Customer</StyledTableCell>
                                <StyledTableCell align="center" style={{ verticalAlign: 'top' }}>Lead No</StyledTableCell>
                                <StyledTableCell align="center" style={{ verticalAlign: 'top' }}>Stage</StyledTableCell>
                                <StyledTableCell align="center" style={{ verticalAlign: 'top' }}>Assigned To</StyledTableCell>
                                <StyledTableCell align="center" style={{ verticalAlign: 'top' }}>Quotation Amount</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {chmLeadList.map((row, i) => {
                                return (
                                    <StyledTableRow key={i + 1}>
                                        <StyledTableCell align="center" style={{ verticalAlign: 'top' }}>{i + 1}</StyledTableCell>
                                        <StyledTableCell align="center" style={{ verticalAlign: 'top' }}>{row?.leadId?.erpProjectNo}</StyledTableCell>
                                        <StyledTableCell align="center" style={{ verticalAlign: 'top' }} component="th" scope="row">
                                            <Link to={`/project-logs/${row.leadId?._id}`} style={{ color: "#000", textDecoration: 'underline' }}>
                                                {row?.leadId?.customerId?.name}
                                            </Link>
                                        </StyledTableCell>
                                        <StyledTableCell align="center" style={{ verticalAlign: 'top' }} component="th" scope="row">{row?.leadId?.lead_no}</StyledTableCell>
                                        <StyledTableCell align="center" style={{ verticalAlign: 'top' }} component="th" scope="row">
                                            {
                                                row?.leadId?.salesStage === 'Won' ? row?.leadId?.designStages :
                                                    row?.leadId?.designStages === 'Desing Sign-Off' ? row?.leadId?.imosStage :
                                                        row?.leadId?.imosStage === 'IMOS Completed' ? row?.leadId?.factoryStage : row?.leadId?.executionStage
                                            }
                                        </StyledTableCell>
                                        <StyledTableCell align="center" style={{ verticalAlign: 'top' }} component="th" scope="row">{row?.assignTo?.name}</StyledTableCell>
                                        <StyledTableCell align="center" style={{ verticalAlign: 'top' }} component="th" scope="row">{row?.leadId?.grandTotal}</StyledTableCell>
                                    </StyledTableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </div >
    )
}

export default connect(null, { setLoading, setSnackInfo })(ChmUserLeads)
