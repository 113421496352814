import React, { useState, useEffect, useRef, useMemo } from 'react'
import { setLoading, setSnackInfo } from '../../../redux/actions/appAction'
import { connect, useSelector } from 'react-redux'
import { useParams, useHistory } from "react-router-dom";
import HttpService from '../../../services/httpService'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles';
import { Accordion, AccordionSummary, AccordionDetails, AccordionActions, Typography, Paper, Grid, Divider, Button, Table, TableBody, TableCell, TableContainer, TableRow, Box, Chip, Avatar, FormControlLabel, Radio, RadioGroup, Tooltip } from '@material-ui/core';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InfoIcon from '@material-ui/icons/Info';
import AttachmentIcon from '@material-ui/icons/Attachment';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select/Select';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
// import './index.css';
import { ROLENAME, constantDepartmentId, opsTeam } from '../../../utils/index';
import { EditorState, convertToRaw } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createMentionPlugin, { defaultSuggestionsFilter, } from '@draft-js-plugins/mention';
import '@draft-js-plugins/mention/lib/plugin.css';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightBold,
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalpaper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    textFieldWidth: {
        width: "100%"
    },
}));


const ManageSiteQcProjectLogs = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const editorRef = useRef();
    const { projectId } = useParams();
    const { userDetails } = useSelector(state => state.user)
    const [projectDetails, setProjectDetails] = useState([]);
    const [projectLogs, setProjectLogs] = useState([]);
    const [openNotesModal, setOpenNotesModal] = useState(false);
    const createNoteFile = useRef()
    const [fileUploaded, setFileUploaded] = useState(false);
    const [radioValue, setRadioValue] = useState("replace_document")
    const [disable, setDisable] = useState(true);
    const [formData, setFormData] = useState({
        notes: "",
        reminderDate: "",
        s3Location: "",
        projectId: "",
        user: "",
        stage: ""
    });
    const [daysfromContractsign, setDaysFromContractSign] = useState("");
    const [daysfromModulardelivery, setDaysFromModularDelivery] = useState("")

    useEffect(() => {
        getProjectLogs();
        getcustomerDetails();
        imosDataOfStartandEnd();
        // eslint-disable-next-line
    }, [])

    const getProjectLogs = async () => {
        props.setLoading(true)
        try {
            const response = await HttpService.getProjectLog(projectId)
            setProjectLogs(response.data)
        } catch (error) {
            console.log("No logs found")
        }
        props.setLoading(false)
    }

    const getcustomerDetails = async () => {
        props.setLoading(true)
        try {
            const response = await HttpService.getProjectLeadDetails(projectId)
            setProjectDetails(response.data[0])
            setSelectedMaterilPercentage(response.data[0].materialReceivedPercentage)
            let date = new Date()
            if (response.data[0].designSignOffDate) {
                let str = moment(response.data[0]?.designSignOffDate).valueOf()
                let time_difference = date.getTime() - str
                let result = time_difference / (1000 * 60 * 60 * 24);
                setDaysFromContractSign(result);
            }
            if (response.data[0]?.materialDispatchedDate) {
                let materialdispatch = moment(response.data[0]?.materialDispatchedDate).valueOf()
                let materialDiff = date.getTime() - materialdispatch
                let materialResult = materialDiff / (1000 * 60 * 60 * 24);
                setDaysFromModularDelivery(materialResult)
            }
            
            if (userDetails?.roles?.find(role => role.name === ROLENAME.DesignHead || role.name === ROLENAME.DesignManager || role.name === ROLENAME.OperationDesignManager || role.name === ROLENAME.AssistantDesignManager || role.name === ROLENAME.DesignUser)) {
                setSelectedStage(response.data[0]['designStages']);
            } else {
                setSelectedStage(response.data[0]['salesStage']);
            }
        } catch (error) {
            props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const handleRadioChange = (e) => {
        setRadioValue(e.target.value)
    }

    const openCreateNotesModal = () => {
        getAllUsers();
        setOpenNotesModal(true);
        setFormData({
            ...formData,
            user: projectDetails?.assignTo?._id,
            stage: userDetails?.roles?.find(role => role.name === ROLENAME.SiteQC) ? 'Working Drawing Received' : projectDetails.imosStage,
            leadId: projectId
        })
    }
    const [moveDealOpen, setMoveDealOpen] = useState(false);
    const moveDeal = () => {
        setMoveDealOpen(true)
        getDepartments();
    }

    const [allUsersList, setAllUsersList] = useState([]);
    const getAllUsers = async () => {
        props.setLoading(true)
        try {
            const response = await HttpService.getAllUsers()
            let userData = [];
            response.data.forEach((ele) => {
                userData.push({
                    name: ele.name,
                    userId: ele._id
                })
            })
            setAllUsersList(userData)

        } catch (error) {
            props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );
    const [mentionOpen, setMentionOpen] = useState(false);
    const [suggestions, setSuggestions] = useState(allUsersList);

    const { MentionSuggestions, plugins } = useMemo(() => {
        const mentionPlugin = createMentionPlugin();
        // eslint-disable-next-line no-shadow
        const { MentionSuggestions } = mentionPlugin;
        // eslint-disable-next-line no-shadow
        const plugins = [mentionPlugin];
        return { plugins, MentionSuggestions };
    }, []);

    const setEditorStateData = (editorState) => {
        setEditorState(editorState);
        setFormData({ ...formData, 'notes': editorRef.current?.editor?.editor?.innerText })
    };

    const onOpenChange = (mentionOpen) => {
        if (mentionOpen === false) {
            setMentionOpen(false)
        } else {
            setMentionOpen(true)
        }
    };

    const onSearchChange = ({ value }) => {
        setSuggestions(defaultSuggestionsFilter(value, allUsersList));
    };

    const onAddMention = (mention) => {
        setMentionOpen(false)
    }

    const [openStatusModal, setOpenStatusModal] = useState(false);
    const openStatusUpdateModal = () => {
        setOpenStatusModal(true);
    }

    const saveNotes = async () => {
        const contentState = editorState.getCurrentContent();
        const raw = convertToRaw(contentState);
        let mentionedUsers = [];
        for (let key in raw.entityMap) {
            const ent = raw.entityMap[key];
            if (ent.type === "mention") {
                mentionedUsers.push(ent.data.mention);
            }
        }
        let taggedUser = [];
        mentionedUsers.forEach((ele, i) => {
            taggedUser.push(ele.userId);
        })

        let obj = {
            ...formData,
            taggedUser: taggedUser
        }
        

        props.setLoading(true);
        try {
            const addNoteResponse = await HttpService.addNotes(obj);
            if (addNoteResponse.status === 200) {
                setOpenNotesModal(false);
                getProjectLogs();
                getcustomerDetails();
                setFormData({
                    ...formData,
                    s3Location: "",
                    reminderDate: ""
                })
                props.setSnackInfo("Notes Added Successfully", "success")
            }
        } catch (err) {
            props.setSnackInfo(err?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false);
    }

    const uploadCreateNoteImg = async (e) => {
        props.setLoading(true)
        const fd = new FormData()
        fd.append('file', e.target.files[0])
        fd.append('customerName', projectDetails.lead_no + '_' + projectDetails.customerId.name)
        try {
            const fileUploadRes = await HttpService.uploadFile(fd);
            setFormData({
                ...formData,
                s3Location: fileUploadRes.data
            })
            setFileUploaded(true);
        } catch (err) {
            props.setSnackInfo(err?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }
    const [stageIndex, setStageIndex] = useState(0);
    const [openNotesInfoModal, setOpenNotesInfoModal] = useState(false);
    const [stageInfo, setStageInfo] = useState("");
    const [openActivity, setOpenActivity] = useState('')
    const showInfo = (i, status) => {
        setStageIndex(i);
        setOpenNotesInfoModal(true);
        setStageInfo(projectLogs[i]['stage'])
        setOpenActivity(status)
    }

    const downloadNotesFile = (data) => {
        window.open(data.s3Location[0])
    }

    const [departmentLists, setDepartmentsList] = useState([]);
    const [selectedStage, setSelectedStage] = useState("");
    const [department, setSelectedDepartment] = useState("");
    const [team, setSelectedTeam] = useState("");
    const [dealOwner, setSelectedDealOwner] = useState("");
    const [teamLists, setTeamList] = useState([]);
    const [dealOwnerLists, setDealOwnersList] = useState([]);
    const [departmentStage, setDepartmentsStage] = useState([]);

    const [openMoveDealDetailsDialog, setOpenMoveDealDetailsDialog] = useState(false)
    const openAnotherDialog = () => {
        setMoveDealOpen(false);
        setOpenMoveDealDetailsDialog(true);
        getDepartments();
    }
    const handleSelectedDepartmentChange = (event) => {
        setSelectedDepartment(event.target.value);
        getTeamsList(event.target.value)
    }
    const handleSelectedTeamChange = (event) => {
        setSelectedTeam(event.target.value);
        getUserList(event.target.value)
    }
    const handleSelectedUserChange = (event) => {
        setSelectedDealOwner(event.target.value);
    }
    const viewCheckList = () => {
        props.history.push(`/view-customer-checklist/${projectId}`);
    }
    const getDepartments = async () => {
        try {
            const department = await HttpService.getDepartments();
            if (userDetails?.roles?.find(role => role.name !== 'Admin')) {
                if (userDetails?.roles?.find(role => role.name === ROLENAME.MarketingUser || role.name === ROLENAME.MarketingManager)) {
                    setDepartmentsList(department.data.filter(ele => ele.name === 'Sales'));
                } else {
                    setDepartmentsList(department.data.filter(ele => ele._id === userDetails.departmentId))
                }
            }
            getDepartmentStages(department.data);
        } catch (error) {
            console.log(error, "get department error")
        }
    }

    const getTeamsList = async (deptid) => {
        let teams = [];
        teams = departmentLists.filter(ele => ele._id === deptid)
        const centerTeams = [];
        teams[0]['teams'].forEach(ele => {
            ele.experienceCenterId.forEach(centerId => {
                if (centerId === userDetails.selectedExpCenter) {
                    centerTeams.push(ele)
                }
            })
        })
        const activeTeamList = centerTeams.filter(ele => ele.isActive === true)
        setTeamList(activeTeamList)
    }

    const getUserList = async (teamid) => {
        if (userDetails?.roles?.find(role => ROLENAME.MarketingUser || role.name === ROLENAME.MarketingManager)) {
            try {
                const users = await HttpService.getQTTeamUsers(teamid);
                let activeUsers = users.data.users.filter(ele => ele.isActive === true)
                setDealOwnersList(activeUsers)
            } catch (error) {
                console.log(error, "Error");
            }
        } else if (teamid === userDetails.teamId._id) {
            try {
                const users = await HttpService.getQTTeamUsers(teamid);
                let activeUsers = users.data.users.filter(ele => ele.isActive === true)
                setDealOwnersList(activeUsers)
            } catch (error) {
                console.log(error, "Error");
            }
        } else {
            try {
                const teamUsers = await HttpService.getQtTeamManager(teamid);
                let teamManager = [];
                let obj = {
                    _id: teamUsers.data.manager._id,
                    name: teamUsers.data.manager.name
                }
                teamManager.push(obj);
                setDealOwnersList(teamManager)
            } catch (error) {
                console.log(error, "Error");
            }
        }
    }

    const getDepartmentStages = async (data) => {
        let stages = []
        if (userDetails.roles.find(role => role.name === ROLENAME.IMOSManager || role.name === ROLENAME.IMOSUser)) {
            data.forEach((element) => {
                if (element['name'] === 'IMOS') {
                    stages.push(element['stages']);
                };
            });
        }
        setDepartmentsStage(stages[0]);
    }

    const handleSelectedStageChange = (event) => {
        setSelectedStage(event.target.value);
    }

    const [checkIsUpload, setCheckIsUpload] = useState({
        finalWorkingDrawingFile: false,
        doc1: false,
        doc2: false,
        doc3: false,
        doc4: false,
        doc5: false,
        doc6: false,
        doc7: false,
        doc8: false,
        doc9: false,
        doc10: false,
        updatedPastingFile: false
    })

    const [finalWDFile, setFinalWDFile] = useState({})
    const [updatedPastingListFile, setUpdatedPastingListFile] = useState({});
    const [imosFiles, setImosFiles] = useState({
        imosCarpentryFile: '',
        imosCustomFile: '',
        imosEdgeBandingFile: '',
        imosHardwareFile: '',
        imosPastingListPdf: '',
        imosPastingListExcel: '',
        imosSawFile: '',
        imosMprFile: '',
        imosTrackingFile: '',
        imosJaliFile: ''
    })
    const [newImosFiles, setNewImosFiles] = useState({
        newImosCarpentryFile: '',
        newImosCustomFile: '',
        newImosEdgeBandingFile: '',
        newImosHardwareFile: '',
        newImosPastingListPdf: '',
        newImosPastingListExcel: '',
        newImosSawFile: '',
        newImosMprFile: '',
        newImosTrackingFile: '',
        newImosJaliFile: ''
    })
    
    const uploadWDFileDocument = async (e, optionType) => {
        try {
            props.setLoading(true)
            const formData = new FormData()
            formData.append('file', e.target.files[0])
            formData.append('customerName', projectDetails.lead_no + '_' + projectDetails.customerId.name)
            const result = await HttpService.wdFileUpload(formData)
            if (result.status === 200 && optionType === 'finalWdfile') {
                setFinalWDFile({ 'finalWorkingDrawingFile': result.data })
                setCheckIsUpload({ ...checkIsUpload, finalWorkingDrawingFile: true })
                props.setSnackInfo("Working Drawing Upload Success", "success")
            } else if (result.status === 200 && optionType === 'doc1') {
                radioValue === 'replace_document' ?
                    setImosFiles({ ...imosFiles, 'imosCarpentryFile': result.data })
                    :
                    setNewImosFiles({ ...newImosFiles, 'newImosCarpentryFile': result.data })
                setCheckIsUpload({ ...checkIsUpload, doc1: true })
                props.setSnackInfo("Carpentry File Upload Successfully", "success")
            } else if (result.status === 200 && optionType === 'doc2') {
                radioValue === 'replace_document' ?
                    setImosFiles({ ...imosFiles, 'imosCustomFile': result.data })
                    :
                    setNewImosFiles({ ...newImosFiles, 'newImosCustomFile': result.data })
                setCheckIsUpload({ ...checkIsUpload, doc2: true })
                props.setSnackInfo("Custom File Upload Successfully", "success")
            } else if (result.status === 200 && optionType === 'doc3') {
                radioValue === 'replace_document' ?
                    setImosFiles({ ...imosFiles, 'imosEdgeBandingFile': result.data })
                    :
                    setNewImosFiles({ ...newImosFiles, 'newImosEdgeBandingFile': result.data })
                setCheckIsUpload({ ...checkIsUpload, doc3: true })
                props.setSnackInfo("Edge Banding File Upload Successfully", "success")
            } else if (result.status === 200 && optionType === 'doc4') {
                radioValue === 'replace_document' ?
                    setImosFiles({ ...imosFiles, 'imosHardwareFile': result.data })
                    :
                    setNewImosFiles({ ...newImosFiles, 'newImosHardwareFile': result.data })
                setCheckIsUpload({ ...checkIsUpload, doc4: true })
                props.setSnackInfo("Hardware File Upload Successfully", "success")
            } else if (result.status === 200 && optionType === 'doc5') {
                radioValue === 'replace_document' ?
                    setImosFiles({ ...imosFiles, 'imosPastingListPdf': result.data })
                    :
                    setNewImosFiles({ ...newImosFiles, 'newImosPastingListPdf': result.data })
                setCheckIsUpload({ ...checkIsUpload, doc5: true })
                props.setSnackInfo("Pasting PDF File Upload Successfully", "success")
            } else if (result.status === 200 && optionType === 'doc6') {
                radioValue === 'replace_document' ?
                    setImosFiles({ ...imosFiles, 'imosPastingListExcel': result.data })
                    :
                    setNewImosFiles({ ...newImosFiles, 'newImosPastingListExcel': result.data })
                setCheckIsUpload({ ...checkIsUpload, doc6: true })
                props.setSnackInfo("Pasting Excel File Upload Successfully", "success")
            } else if (result.status === 200 && optionType === 'doc7') {
                radioValue === 'replace_document' ?
                    setImosFiles({ ...imosFiles, 'imosSawFile': result.data })
                    :
                    setNewImosFiles({ ...newImosFiles, 'newImosSawFile': result.data })
                setCheckIsUpload({ ...checkIsUpload, doc7: true })
                props.setSnackInfo("Saw File Upload Successfully", "success")
            } else if (result.status === 200 && optionType === 'doc8') {
                radioValue === 'replace_document' ?
                    setImosFiles({ ...imosFiles, 'imosMprFile': result.data })
                    :
                    setNewImosFiles({ ...newImosFiles, 'newImosMprFile': result.data })
                setCheckIsUpload({ ...checkIsUpload, doc8: true })
                props.setSnackInfo("MPR File Upload Successfully", "success")
            } else if (result.status === 200 && optionType === 'doc9') {
                radioValue === 'replace_document' ?
                    setImosFiles({ ...imosFiles, 'imosTrackingFile': result.data })
                    :
                    setNewImosFiles({ ...newImosFiles, 'newImosTrackingFile': result.data })
                setCheckIsUpload({ ...checkIsUpload, doc9: true })
                props.setSnackInfo("Tracking File Upload Successfully", "success")
            }
            else if (result.status === 200 && optionType === 'doc10')
            {
                radioValue === 'replace_document' ?
                    setImosFiles({ ...imosFiles, 'imosJaliFile': result.data })
                    :
                    setNewImosFiles({ ...newImosFiles, 'newImosJaliFile': result.data })
                setCheckIsUpload({ ...checkIsUpload, doc10: true })
                props.setSnackInfo("Jali File Upload Successfully", "success")
            } 

            else if (result.status === 200 && optionType === 'updatedPastingFile') {
                setUpdatedPastingListFile({ 'updatedPastingListFile': result.data })
                setCheckIsUpload({ ...checkIsUpload, updatedPastingFile: true })
                props.setSnackInfo("Updated Pasting list File Upload Successfully", "success")
            }
        } catch (error) {
            console.log(error)
            props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
        }
        props.setLoading(false)
        setDisable(false)
    }

    const [openDocUploaderDialog, setOpenDocUploaderDialog] = useState(false);
    const openUploadDocumentsDialog = () => {
        setOpenDocUploaderDialog(true)
    }

    const submitIMOSDocumets = async () => {
        let finalObj = {}
        if (radioValue === 'replace_document') {
            finalObj = {
                ...imosFiles
            }
        } else {
            finalObj = {
                ...newImosFiles
            }
        }
        finalObj.erpProjectId = projectDetails.erpProjectId;
        props.setLoading(true)
        try {
            const response = await HttpService.uploadIMOSFile(projectId, finalObj);
            if (response.status === 200) {
                setOpenDocUploaderDialog(false);
                getProjectLogs();
                getcustomerDetails();
                history.push('/siteqc/projects');
                props.setSnackInfo("IMOS Documents uploaded successful", "success")
            }
        } catch (error) {
            console.log(error, "assign project error");
            props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
        }
        props.setLoading(false)
    }

    const uploadAndSendToImosAndSiteBOM = async () => {
        let obj = {
            finalWorkingDrawingFile: finalWDFile.finalWorkingDrawingFile,
            erpProjectId: projectDetails.erpProjectId
        }
        props.setLoading(true)
        try {
            const response = await HttpService.uploadAndSendToImosAndSiteBOM(projectId);
            if (response.status === 200) {
                setOpenMoveDealDetailsDialog(false);
                getProjectLogs();
                getcustomerDetails();
                history.push('/siteqc/projects');
                props.setSnackInfo("Approved successful", "success")
            }
        } catch (error) {
            console.log(error, "assign project error");
            props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
        }
        props.setLoading(false)
    }

    const assignLeadToUser = async () => {
        let dealData = {};
        if (selectedStage === "IMOS Completed"){
            dealData.stage = selectedStage
            dealData.department = constantDepartmentId.operation
            dealData.team = opsTeam
            dealData.id = dealOwner
            dealData.leadId = projectId
        }else{
        
                dealData.stage= selectedStage
                dealData.department= department
                dealData.team= team
                dealData.id= dealOwner
                dealData.leadId = projectId
        }

        if (userDetails.departmentId === constantDepartmentId.imos) {
            dealData.stageDepartment = 'imos'
        }
        try {
            props.setLoading(true)
            const assignProject = await HttpService.assignProject(dealData);
            if (assignProject.status === 200) {
                setOpenMoveDealDetailsDialog(false);
                setSelectedStage('');
                setSelectedDepartment('');
                setSelectedTeam('');
                setSelectedDealOwner('');
                getProjectLogs();
                getcustomerDetails();
                setMoveDealOpen(false)
                props.setSnackInfo("Project assigned Successful", "success")
            }
        } catch (error) {
            console.log(error, "assign project error");
            props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
        }
        props.setLoading(false)
    }

    const assignLeadToUse = async () => {
        let dealData = {
            stage: selectedStage,
            team: userDetails?.roles?.find(role => role.name === ROLENAME.IMOSUser) ? userDetails.teamId : team,
            id: userDetails?.roles?.find(role => role.name === ROLENAME.IMOSUser) ? userDetails._id : dealOwner,
            department:userDetails.departmentId,
            leadId: projectId
        };
        if (userDetails.departmentId === constantDepartmentId.imos) {
            dealData.stageDepartment = 'imos'
        }
        try {
            props.setLoading(true)
            const assignProject = await HttpService.assignProject(dealData);
            if (assignProject.status === 200) {
                setOpenMoveDealDetailsDialog(false);
                setSelectedStage('');
                setSelectedDepartment('');
                setSelectedTeam('');
                setSelectedDealOwner('');
                getProjectLogs();
                getcustomerDetails();
                setMoveDealOpen(false)
                props.setSnackInfo("Project assigned Successful", "success")
            }
            else if (userDetails.roles.find(role => role.name === ROLENAME.IMOSUser)) {
                dealData = {
                    ...dealData,
                    department: userDetails.departmentId,
                    team: userDetails.teamId._id,
                    id: userDetails._id,
                }
            }
        }

catch (error) {
            console.log(error, "assign project error");
            props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
        }
        props.setLoading(false)
    }

    const [openPastingFileUploadDialog, setOpenPastingFileUploadDialog] = useState(false)
    const [openMoveTofactoryDialog, setOpenMoveTofactoryDialog] = useState(false)
    const [siteBomCompleted, setSiteBomCompleted] = useState(false)
    const opendialogForPastingFileUpload = () => {
        setOpenPastingFileUploadDialog(true)
    }

    const opendialogForSiteBomComplete = () => {
        setSiteBomCompleted(true)
    }

    const uploadUpdatedPastingFile = async () => {
        try {
            props.setLoading(true)
            const response = await HttpService.uploadUpdatedFileAndData(projectId, updatedPastingListFile);
            if (response.status === 200) {
                setOpenPastingFileUploadDialog(false);
                getProjectLogs();
                getcustomerDetails();
                props.setSnackInfo("Pasting file is updated Successfully", "success")
            }
        } catch (error) {
            console.log(error, "assign project error");
            props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
        }
        props.setLoading(false)
    }
    const moveToFactory = () => {
        setOpenMoveTofactoryDialog(true)
    }

    const leadMoveToFactory = async () =>{
        try {
            props.setLoading(true)
            const response = await HttpService.moveLeadToFactory(projectId);
            if (response.status === 200) {
                setOpenMoveTofactoryDialog(false);
                getProjectLogs();
                getcustomerDetails();
                props.setSnackInfo("Lead Moved To Factory Successfully", "success")
            }
        } catch (error) {
            props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
        }
        props.setLoading(false)
    }

    const moveToSiteBomComplete = async () => {
        try {
            props.setLoading(true)
            const response = await HttpService.siteBomcomplted(projectId);
            if (response.status === 200) {
                setSiteBomCompleted(false);
                getProjectLogs();
                getcustomerDetails();
                props.setSnackInfo(response?.data ?? "Updated Successfully", "success")
            }
        } catch (error) {
            props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
        }
        props.setLoading(false)
    }

    const [imosDrawingDateToFactory, setImosDrawingDateToFactory] = useState("")
    const imosDataOfStartandEnd = async () => {
        try {
            props.setLoading(true)
            const response = await HttpService.getDetailsOfImosProject(projectId);
            if(response.length !== 0){
                let date = new Date()
                let startDate = moment(response.data[0]?.startedDate).valueOf()
                let endDate = moment(response.data[0]?.endDate).valueOf()
                let time_difference = endDate - startDate;
                    let result = time_difference / (1000 * 60 * 60 * 24);
                    setImosDrawingDateToFactory(result);
            }
        } catch (error) {
            props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
        }
        props.setLoading(false)
    }

    const [openStageStatusModal, setOpenStageStatusModal] = useState(false)
    const openUpdateStageModal = () => {
        setOpenStageStatusModal(true);
    }
    const [selectedStageStatus, setSelectedStageStatus] = useState('')
    const [dispatchDate, setDispatchDate] = useState(moment().toISOString(new Date()));
    const handleSelectedStageStatus = (event) => {
        setSelectedStageStatus(event.target.value)
    }

    const updateStageStatus = async () => {
        if (selectedStageStatus === 'Ready for dispatch') {
            // Send mail to priyanka with dispatch date
            let obj = {
                readyToDispatchDate: dispatchDate,
                factoryStage: selectedStageStatus,
                projectId: projectDetails.erpProjectId,
                experienceCenterId: userDetails.selectedExpCenter
            }
            try {
                props.setLoading(true)
                const assignProject = await HttpService.updateToReadyToDispatch(projectId, obj);
                if (assignProject.status === 200) {
                    setOpenStageStatusModal(false);
                    getProjectLogs();
                    getcustomerDetails();
                    props.setSnackInfo("Project assinged to CRM Successful", "success")
                }
            } catch (error) {
                console.log(error, "assign project error");
                props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
            }
            props.setLoading(false)
        } else {
            let obj = {
                factoryStage: selectedStageStatus
            }
            try {
                props.setLoading(true)
                const assignProject = await HttpService.updateFactoryStage(projectId, obj);
                if (assignProject.status === 200) {
                    setOpenStageStatusModal(false);
                    getProjectLogs();
                    getcustomerDetails();
                    props.setSnackInfo("Project Factory Stage Successful", "success")
                }
            } catch (error) {
                console.log(error, "stage update error");
                props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
            }
            props.setLoading(false)
        }
    }

    const [openUpdateMaterialStatusModal, setOpenUpdateMaterialStatusModal] = useState(false);
    const [selectedMaterilPercentage, setSelectedMaterilPercentage] = useState('');

    const updateMaterialReceived = async () => {
        let obj = {}
        if (selectedMaterilPercentage === 'Yes') {
            obj = { materialReceived: true }
        } else {
            obj = { materialReceived: false }
        }
        try {
            props.setLoading(true)
            const assignProject = await HttpService.uploadUpdatedFileAndData(projectId, obj);
            if (assignProject.status === 200) {
                setOpenUpdateMaterialStatusModal(false);
                getProjectLogs();
                getcustomerDetails();
                props.setSnackInfo("Project Factory Stage Successful", "success")
            }
        } catch (error) {
            console.log(error, "stage update error");
            props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
        }
        props.setLoading(false)
    }

    const navigateToProjectMaterialPage = () => {
        if (projectDetails.erpProjectId) {
            history.push(`/project-materials-details`, {
                projectId: projectDetails.erpProjectId
            });
        } else {
            props.setSnackInfo("Project Not tag to lead", "error")
        }
    } 

    const viewQuotationPage = () => {
        props.history.push(`/manageQuotations/${projectId}`);
    }

    return (
        <div>
            <div className={classes.root}>
                <Grid container spacing={3}>
                    {/* Details */}
                    <Grid item lg={6} md={6} sm={12}>
                        <Paper className={classes.paper}>

                            {/* Customer Details */}
                            <Accordion defaultExpanded>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className={classes.heading}>Customer Details</Typography>
                                </AccordionSummary>

                                <Divider />

                                <AccordionDetails style={{ padding: '8px 0px 16px' }}>
                                    <TableContainer component={Paper}>
                                        <Table className={classes.table} size="small" aria-label="customized table">
                                            <TableBody>
                                                <TableRow key='1'>
                                                    <TableCell>Customer Name</TableCell>
                                                    <TableCell>{projectDetails?.customerId?.name}</TableCell>
                                                </TableRow>
                                                <TableRow key='2'>
                                                    <TableCell>Contact</TableCell>
                                                    <TableCell>{projectDetails?.customerId?.contact_no}</TableCell>
                                                </TableRow>
                                                <TableRow key='3'>
                                                    <TableCell>Email</TableCell>
                                                    <TableCell>{projectDetails?.customerId?.email}</TableCell>
                                                </TableRow>
                                                <TableRow key='4'>
                                                    <TableCell>Address</TableCell>
                                                    <TableCell>{projectDetails?.customerId?.address}</TableCell>
                                                </TableRow>
                                                <TableRow key='5'>
                                                    <TableCell>Current Stage</TableCell>
                                                    <TableCell>
                                                        {projectDetails.currentStage}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow key='6'>
                                                    <TableCell>Assigned To</TableCell>
                                                    <TableCell>
                                                    {projectDetails?.assignTo?.name}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow key='7'>
                                                    <TableCell>Lead Number</TableCell>
                                                    <TableCell>{projectDetails?.lead_no}</TableCell>
                                                </TableRow>
                                                <TableRow key='8'>
                                                    <TableCell>Project No.</TableCell>
                                                    <TableCell>{projectDetails?.erpProjectNo ? projectDetails?.erpProjectNo : '-'}</TableCell>
                                                </TableRow>
                                                <TableRow key='9'>
                                                    <TableCell>ExperienceCenter</TableCell>
                                                    <TableCell>{projectDetails?.experienceCenterId?.name ? projectDetails?.experienceCenterId?.name :'-'}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </AccordionDetails>

                                <Divider />

                                <AccordionActions>
                                    {
                                        userDetails?.roles?.find(role => role.name === ROLENAME.SiteQC) &&
                                        <Button variant="contained" color="primary" size="small" onClick={openStatusUpdateModal}>Upload File</Button>
                                    }
                                    {
                                        userDetails?.roles?.find(role => role.name === ROLENAME.ProcurementExecutive) &&
                                        <Button variant="contained" color="secondary" size="small" onClick={viewQuotationPage}>View Quotation</Button>
                                    }
                                    <Button variant="contained" color="primary" size="small" onClick={openCreateNotesModal}>Add Notes</Button>
                                    <Button variant="contained" color="primary" size="small" onClick={() => viewCheckList()}>View Check List</Button>
                                    {
                                        userDetails?.roles?.find(role => role.name === ROLENAME.IMOSManager || role.name === ROLENAME.IMOSUser) &&
                                        <>
                                            <Button variant="contained" color="primary" size="small" onClick={openUploadDocumentsDialog}>Upload Documents</Button>
                                            <Button variant="contained" color="primary" size="small" onClick={moveDeal}>Update Status</Button>
                                        </>
                                    }
                                    {(userDetails?.roles?.find(role => role.name === ROLENAME.BOMExecutive) &&
                                        (userDetails?.roles?.find(role => role.name === ROLENAME.FactoryBom))) && 
                                        <>
                                        <Button variant="contained" color="primary" size="small" onClick={opendialogForPastingFileUpload}> Upload Pasting List File</Button>
                                        <Button variant="contained" color="primary" size="small" onClick={moveToFactory}> Move To Factory</Button>
                                        </>}
                                    {(userDetails?.roles?.find(role => role.name === ROLENAME.SiteBom)) &&
                                        <>
                                        <Button variant="contained" color="primary" size="small" onClick={opendialogForSiteBomComplete}> Move to Completed</Button>
                                        </>
                                    }
                                    {
                                        userDetails?.roles?.find(role => role.name === ROLENAME.FactoryManager || role.name === "Factory BOM Manager") &&
                                        <>
                                            <Button variant="contained" color="primary" size="small" onClick={() => navigateToProjectMaterialPage()} >View Project Material</Button>
                                            <Button variant="contained" color="primary" size="small" onClick={() => setOpenUpdateMaterialStatusModal(true)} disabled={projectDetails?.materialReceived}>Update Material Status</Button>
                                            <Button variant="contained" color="primary" size="small" onClick={openUpdateStageModal} disabled={!projectDetails?.materialReceived}>Update Stage</Button>
                                        </>
                                    }
                                </AccordionActions>
                            </Accordion>

                            {/* Deals Details and project documents */}
                            <Accordion defaultExpanded>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Typography className={classes.heading}>Design Details</Typography>
                                </AccordionSummary>
                                <Divider />
                                <AccordionDetails style={{ padding: '8px 0px 16px' }}>
                                    <TableContainer component={Paper}>
                                        <Table className={classes.table} size="small" aria-label="customized table">
                                            <TableBody>
                                                <TableRow key='3'>
                                                    <TableCell>Internal Design Sign-off Date</TableCell>
                                                    <TableCell>{projectDetails?.internalDesignSignOffDate ? moment(projectDetails?.internalDesignSignOffDate).format('DD-MM-YYYY') : `-`}</TableCell>
                                                </TableRow>
                                                <TableRow key='1'>
                                                    <TableCell>Expected Design Sign-off Date</TableCell>
                                                    <TableCell>{projectDetails?.expectedDesignSignOffDate ? moment(projectDetails?.expectedDesignSignOffDate).format('DD-MM-YYYY') : `-`}</TableCell>
                                                </TableRow>
                                                <TableRow key='2'>
                                                    <TableCell>Days from Contract sign:</TableCell>
                                                    <TableCell>{daysfromContractsign !== "" ? daysfromContractsign.toFixed(0) : `-`}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </AccordionDetails>
                            </Accordion>

                            {/* Design Details  */}
                            <Accordion defaultExpanded>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Typography className={classes.heading}>Post Design Sign-off Details</Typography>
                                </AccordionSummary>
                                <Divider />

                                <AccordionDetails style={{ padding: '35px 0px 16px' }}>
                                    <TableContainer component={Paper}>
                                        <Table className={classes.table} size="small" aria-label="customized table">
                                        <TableBody>
                                            <TableRow key='1a'>
                                                    <TableCell>Days from Modular delivery:</TableCell>
                                                    <TableCell>{daysfromModulardelivery !== "" ? daysfromModulardelivery.toFixed(0) : `-`}</TableCell>
                                                </TableRow>
                                                
                                                <TableRow key='1b'>
                                                    <TableCell>Imos Drawing Date to Factory</TableCell>
                                                    <TableCell>{projectDetails?.imosToFactoryDate ? moment(projectDetails?.imosToFactoryDate).format('DD-MM-YYYY') : `-`}</TableCell>
                                                </TableRow>
                                                <TableRow key='3'>
                                                    <TableCell>Design Sign-off</TableCell>
                                                    {/* <TableCell>{projectDetails?.designSignOffDate ? moment(projectDetails?.designSignOffDate).format('DD-MM-YYYY') : `-`}</TableCell> */}
                                                    <TableCell>{projectDetails?.customerDesignSignOffDate ? moment(projectDetails?.customerDesignSignOffDate).format('DD-MM-YYYY') : `-`}</TableCell>
                                                </TableRow>
                                                <TableRow key='3a'>
                                                  <TableCell>Extra Days Due To Client Dependency:</TableCell>
                                                    <TableCell>{projectDetails?.extraDaysDueToClientDependency ? projectDetails?.extraDaysDueToClientDependency : `-`}</TableCell>
                                                </TableRow>
                                                <TableRow key='4'>
                                                    <TableCell>Working Drawing File</TableCell>
                                                    <TableCell>
                                                        {projectDetails?.workingDrawingFile ?
                                                            <span onClick={() => window.open(projectDetails?.workingDrawingFile)} > <GetAppOutlinedIcon /> </span>
                                                            :
                                                            `-`
                                                        }
                                                    </TableCell>
                                                    </TableRow>
                                                    <TableRow key='5'>
                                                    <TableCell>3D Working Drawing File</TableCell>
                                                    <TableCell>
                                                        {projectDetails?.ThreeDworkingDrawingFile ?
                                                            <span onClick={() => window.open(projectDetails?.ThreeDworkingDrawingFile)} > <GetAppOutlinedIcon /> </span>
                                                            :
                                                            `-`
                                                        }
                                                    </TableCell>
                                                    </TableRow>
                                                    <TableRow key='18'>
                                                    <TableCell>Project Kick-Off Check List File</TableCell>
                                                    <TableCell>
                                                        {projectDetails?.scanProjectKickOffCheckListFile ?
                                                            <span onClick={() => window.open(projectDetails?.scanProjectKickOffCheckListFile)} > <GetAppOutlinedIcon /> </span>
                                                            :
                                                            `-`
                                                        }
                                                    </TableCell>
                                                    </TableRow>
                                                    <TableRow key='19'>
                                                    <TableCell>SiteQC Check List File</TableCell>
                                                    <TableCell>
                                                        {projectDetails?.scanSiteQCCheckListFile ?
                                                            <span onClick={() => window.open(projectDetails?.scanSiteQCCheckListFile)} > <GetAppOutlinedIcon /> </span>
                                                            :
                                                            `-`
                                                        }
                                                    </TableCell>
                                                    </TableRow>
                                                    <TableRow key='20'>
                                                        <TableCell>SiteQC Photos</TableCell>
                                                        <TableCell>
                                                            {projectDetails?.scanSiteQCPhotos && projectDetails.scanSiteQCPhotos.length > 0 ? (
                                                                projectDetails.scanSiteQCPhotos.map((photo, index) => (
                                                                    <span key={index} onClick={() => window.open(photo)}>
                                                                        <GetAppOutlinedIcon />
                                                                    </span>
                                                                ))
                                                            ) : (
                                                                `-`
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow key='6'>
                                                    <TableCell>Signed Check list File</TableCell>
                                                    <TableCell>
                                                        {projectDetails?.salesChecklistPdfFile ?
                                                            <span onClick={() => window.open(projectDetails?.salesChecklistPdfFile)} > <GetAppOutlinedIcon /> </span>
                                                            :
                                                            `-`
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow key='7'>
                                                    <TableCell>Contract Sign File</TableCell>
                                                    <TableCell>
                                                        {projectDetails?.termsAndConditionsPdf ?
                                                            <span onClick={() => window.open(projectDetails?.termsAndConditionsPdf)} > <GetAppOutlinedIcon /> </span>
                                                            :
                                                            `-`
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow key='8a'>
                                                    <TableCell>Quotation File</TableCell>
                                                    <TableCell>
                                                        {projectDetails?.scanQuotationFile ?
                                                            <span onClick={() => window.open(projectDetails?.scanQuotationFile)} > <GetAppOutlinedIcon /> </span>
                                                            :
                                                            `-`
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow key='8b'>
                                                    <TableCell>Quotation Csv File</TableCell>
                                                    <TableCell>
                                                        {projectDetails?.quotationCsvFile ?
                                                            <span onClick={() => window.open(projectDetails?.quotationCsvFile)} > <GetAppOutlinedIcon /> </span>
                                                            :
                                                            `-`
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                                {/* <TableRow key='8bc'>
                                                    <TableCell>Final Working Drawing File</TableCell>
                                                    <TableCell>
                                                        {projectDetails?.finalWDFile ?
                                                            <span onClick={() => window.open(projectDetails?.finalWDFile)} > <GetAppOutlinedIcon /> </span>
                                                            :
                                                            `-`
                                                        }
                                                    </TableCell>
                                                </TableRow> */}
                                                <TableRow key='8'>
                                                    <TableCell>Carpentry File</TableCell>
                                                    <TableCell>
                                                        {projectDetails?.imosCarpentryFile ?
                                                            <span style={{ cursor: "pointer" }} onClick={() => window.open(projectDetails?.imosCarpentryFile)} > <GetAppOutlinedIcon /> </span>
                                                            : `-`
                                                        }
                                                    </TableCell>
                                                    <TableCell>
                                                        {projectDetails?.newImosCarpentryFile ?
                                                            <Tooltip title="New File">
                                                                <span style={{ cursor: "pointer" }} onClick={() => window.open(projectDetails?.newImosCarpentryFile)} > <GetAppOutlinedIcon /> </span>
                                                            </Tooltip>
                                                            :
                                                            `-`
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow key='9'>
                                                    <TableCell>Custom File</TableCell>
                                                    <TableCell>
                                                        {projectDetails?.imosCustomFile ?
                                                            <span style={{ cursor: "pointer" }} onClick={() => window.open(projectDetails?.imosCustomFile)} > <GetAppOutlinedIcon /> </span>
                                                            : `-`
                                                        }
                                                    </TableCell>
                                                    <TableCell>
                                                        {projectDetails?.newImosCustomFile ?
                                                            <Tooltip title="New File">
                                                                <span style={{ cursor: "pointer" }} onClick={() => window.open(projectDetails?.newImosCustomFile)} > <GetAppOutlinedIcon /> </span>
                                                            </Tooltip>
                                                            : `-`
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow key='10'>
                                                    <TableCell>Edge Band File</TableCell>
                                                    <TableCell>
                                                        {projectDetails?.imosEdgeBandingFile ?
                                                            <span style={{ cursor: "pointer" }} onClick={() => window.open(projectDetails?.imosEdgeBandingFile)} > <GetAppOutlinedIcon /> </span>
                                                            : `-`
                                                        }
                                                    </TableCell>
                                                    <TableCell>
                                                        {projectDetails?.newImosEdgeBandingFile ?
                                                            <Tooltip title="New File">
                                                                <span style={{ cursor: "pointer" }} onClick={() => window.open(projectDetails?.newImosEdgeBandingFile)} > <GetAppOutlinedIcon /> </span>
                                                            </Tooltip>
                                                            : `-`
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow key='11'>
                                                    <TableCell>Hardware File</TableCell>
                                                    <TableCell>
                                                        {projectDetails?.imosHardwareFile ?
                                                            <span style={{ cursor: "pointer" }} onClick={() => window.open(projectDetails?.imosHardwareFile)} > <GetAppOutlinedIcon /> </span>
                                                            : `-`
                                                        }
                                                    </TableCell>
                                                    <TableCell>
                                                        {projectDetails?.newImosHardwareFile ?
                                                            <Tooltip title="New File">
                                                                <span style={{ cursor: "pointer" }} onClick={() => window.open(projectDetails?.newImosHardwareFile)} > <GetAppOutlinedIcon /> </span>
                                                            </Tooltip>
                                                            : `-`
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow key='12'>
                                                    <TableCell>Pasting PDF File</TableCell>
                                                    <TableCell>
                                                        {projectDetails?.imosPastingListPdf ?
                                                            <span style={{ cursor: "pointer" }} onClick={() => window.open(projectDetails?.imosPastingListPdf)} > <GetAppOutlinedIcon /> </span>
                                                            : `-`
                                                        }
                                                    </TableCell>
                                                    <TableCell>
                                                        {projectDetails?.newImosPastingListPdf ?
                                                            <Tooltip title="New File">
                                                                <span style={{ cursor: "pointer" }} onClick={() => window.open(projectDetails?.newImosPastingListPdf)} > <GetAppOutlinedIcon /> </span>
                                                            </Tooltip>
                                                            : `-`
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow key='13'>
                                                    <TableCell>Pasting Excel File</TableCell>
                                                    <TableCell>
                                                        {projectDetails?.imosPastingListExcel ?
                                                            <span style={{ cursor: "pointer" }} onClick={() => window.open(projectDetails?.imosPastingListExcel)} > <GetAppOutlinedIcon /> </span>
                                                            : `-`
                                                        }
                                                    </TableCell>
                                                    <TableCell>
                                                        {projectDetails?.newImosPastingListExcel ?
                                                            <Tooltip title="New File">
                                                                <span style={{ cursor: "pointer" }} onClick={() => window.open(projectDetails?.newImosPastingListExcel)} > <GetAppOutlinedIcon /> </span>
                                                            </Tooltip>
                                                            : `-`
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow key='14'>
                                                    <TableCell>Saw File</TableCell>
                                                    <TableCell>
                                                        {projectDetails?.imosSawFile ?
                                                            <span style={{ cursor: "pointer" }} onClick={() => window.open(projectDetails?.imosSawFile)} > <GetAppOutlinedIcon /> </span>
                                                            : `-`
                                                        }
                                                    </TableCell>
                                                    <TableCell>
                                                        {projectDetails?.newImosSawFile ?
                                                            <Tooltip title="New File">
                                                                <span style={{ cursor: "pointer" }} onClick={() => window.open(projectDetails?.newImosSawFile)} > <GetAppOutlinedIcon /> </span>
                                                            </Tooltip>
                                                            : `-`
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow key='15'>
                                                    <TableCell>MPR File</TableCell>
                                                    <TableCell>
                                                        {projectDetails?.imosMprFile ?
                                                            <span style={{ cursor: "pointer" }} onClick={() => window.open(projectDetails?.imosMprFile)} > <GetAppOutlinedIcon /> </span>
                                                            : `-`
                                                        }
                                                    </TableCell>
                                                    <TableCell>
                                                        {projectDetails?.newImosMprFile ?
                                                            <Tooltip title="New File">
                                                                <span style={{ cursor: "pointer" }} onClick={() => window.open(projectDetails?.newImosMprFile)} > <GetAppOutlinedIcon /> </span>
                                                            </Tooltip>
                                                            : `-`
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow key='16'>
                                                    <TableCell>Tracking File</TableCell>
                                                    <TableCell>
                                                        {projectDetails?.imosTrackingFile ?
                                                            <span style={{ cursor: "pointer" }} onClick={() => window.open(projectDetails?.imosTrackingFile)} > <GetAppOutlinedIcon /> </span>
                                                            : `-`
                                                        }
                                                    </TableCell>
                                                    <TableCell>
                                                        {projectDetails?.newImosTrackingFile ?
                                                            <Tooltip title="New File">
                                                                <span style={{ cursor: "pointer" }} onClick={() => window.open(projectDetails?.newImosTrackingFile)} > <GetAppOutlinedIcon /> </span>
                                                            </Tooltip>
                                                            : `-`
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow key='17'>
                                                    <TableCell>Updated Pasting List File</TableCell>
                                                    <TableCell>
                                                        {projectDetails?.updatedPastingListFile ?
                                                            <span style={{ cursor: "pointer" }} onClick={() => window.open(projectDetails?.updatedPastingListFile)} > <GetAppOutlinedIcon /> </span>
                                                            : `-`
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                                    <TableCell>Jali File</TableCell>
                                                    <TableCell>
                                                        {projectDetails?.imosJaliFile ?
                                                            <span style={{ cursor: "pointer" }} onClick={() => window.open(projectDetails?.imosJaliFile)} > <GetAppOutlinedIcon /> </span>
                                                            : `-`
                                                        }
                                                    </TableCell>
                                                    <TableCell>
                                                        {projectDetails?.newImosJaliFile ?
                                                            <Tooltip title="New File">
                                                                <span style={{ cursor: "pointer" }} onClick={() => window.open(projectDetails?.newImosJaliFile)} > <GetAppOutlinedIcon /> </span>
                                                            </Tooltip>
                                                            : `-`
                                                        }
                                                    </TableCell>
                                                <TableRow key='9'>
                                                    <TableCell>Factory Ready to Dispatch Date</TableCell>
                                                    <TableCell>{projectDetails?.readyToDispatchDate ? moment(projectDetails?.readyToDispatchDate).format('DD-MM-YYYY') : `-`}</TableCell>
                                                </TableRow>
                                                <TableRow key='10a'>
                                                    <TableCell>Material Dispatched Date</TableCell>
                                                    <TableCell>{projectDetails?.materialDispatchedDate ? moment(projectDetails?.materialDispatchedDate).format('DD-MM-YYYY') : `-`}</TableCell>
                                                </TableRow>
                                                <TableRow key='10b'>
                                                    <TableCell>Modular Delivery Date</TableCell>
                                                    <TableCell>{projectDetails?.modularDeliveryDate ? moment(projectDetails?.modularDeliveryDate).format('DD-MM-YYYY') : `-`}</TableCell>
                                                </TableRow>
                                                <TableRow key='10c'>
                                                    <TableCell>Expected Project Completion Date</TableCell>
                                                    <TableCell>{projectDetails?.projectCompletionDate ? moment(projectDetails?.projectCompletionDate).format('DD-MM-YYYY') : `-`}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </AccordionDetails>

                            </Accordion>
                        </Paper>
                    </Grid>

                    {/* Notes Details */}
                    <Grid item lg={6} md={6} sm={12}>
                        {projectLogs?.map((data, i) => (
                            <Paper className={classes.paper} key={i + 1}>
                                {
                                    data?.dealActivity && <Accordion defaultExpanded className="accordian">
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography className={classes.heading}>
                                                <LocalActivityIcon />
                                                <span className="titlename">Deal Activity</span>
                                                <span className="datedata">{moment(data?.createdAt).format('lll')}</span>
                                                {/* {{pl.createdAt | date}}, {{pl.createdAt | date:'shortTime' }}  */}
                                            </Typography>
                                        </AccordionSummary>
                                        <Divider />
                                        <AccordionDetails>
                                            <Typography variant="body2" gutterBottom>
                                                {data?.dealActivity}
                                            </Typography>
                                        </AccordionDetails>
                                        <Divider />
                                        <AccordionActions style={{ display: "block" }}>
                                            <InfoIcon style={{ cursor: 'pointer' }} onClick={() => showInfo(i, 'dealActivity')} />
                                            {
                                                data.s3Location.length !== 0 &&
                                                <AttachmentIcon style={{ cursor: 'pointer' }} onClick={() => downloadNotesFile(data)} />
                                            }
                                        </AccordionActions>
                                    </Accordion >
                                }
                                {
                                    data?.notes && <Accordion defaultExpanded className="accordian">
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography className={classes.heading}>
                                                <EditTwoToneIcon />
                                                <span className="titlename">Notes</span>
                                                <span className="datedata">{moment(data?.createdAt).format('lll')}</span>
                                            </Typography>
                                        </AccordionSummary>
                                        <Divider />
                                        <AccordionDetails>
                                            <Typography variant="body2" gutterBottom>
                                                {data?.notes} <br /> <span style={{ fontSize: "12px" }}>{data?.createdBy?.name} has left a note</span>
                                            </Typography>
                                        </AccordionDetails>
                                        <Divider />
                                        <AccordionActions style={{ display: "block" }}>
                                            <InfoIcon style={{ cursor: 'pointer' }} onClick={() => showInfo(i, 'notes')} />
                                            {data.s3Location.length !== 0 &&
                                                <AttachmentIcon style={{ cursor: 'pointer' }} onClick={() => downloadNotesFile(data)} />
                                            }
                                            {/* {data.s3Location.length !== 0 && <AttachmentIcon onClick={() => window.open(data.s3Location[0])} />} */}
                                        </AccordionActions>
                                    </Accordion>
                                }
                            </Paper>
                        ))}
                    </Grid>
                </Grid>
            </div>

            {/* Show Notes and Deal Activity Info Modal */}
            <Dialog open={openNotesInfoModal} onClose={() => setOpenNotesInfoModal(false)} aria-labelledby="form-dialog-title" fullWidth maxWidth='sm'>
                <DialogTitle id="form-dialog-title">
                    {(stageInfo === 'Quotation Sent' && openActivity === 'dealActivity') && <>Quotation Sent</>}
                    {(stageInfo === 'Pre - Sales call' && openActivity === 'dealActivity') && <>Pre-sales Call</>}
                    {(stageInfo === 'Sales Call' && openActivity === 'dealActivity') && <>Sales Call</>}
                    {(stageInfo === 'Negotiation' && openActivity === 'dealActivity') && <>Negotiation</>}
                    {(stageInfo === 'Won' && openActivity === 'dealActivity') && <>Won</>}
                    {(stageInfo === 'Site/Office visit' && openActivity === 'dealActivity') && <>Site/Office visit</>}
                    {openActivity === 'notes' && <>Notes</>}
                    {(stageInfo !== 'Pre - Sales call' && stageInfo !== 'Sales Call' && stageInfo !== 'Quotation Sent' && stageInfo !== 'Site/Office visit' && stageInfo !== 'Negotiation' && stageInfo !== 'Won' && openActivity === 'dealActivity') && <>Deal Activity</>}
                </DialogTitle>
                <Divider />
                <DialogContent>
                    {projectLogs[stageIndex]?.['quotationSentDate'] &&
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="customized table">
                                <TableBody>
                                    <TableRow key='1'>
                                        <TableCell>Quotation sent Date</TableCell>
                                        <TableCell>{moment(projectLogs[stageIndex]?.['quotationSentDate']).format('DD-MM-YYYY')}</TableCell>
                                    </TableRow>
                                    <TableRow key='2'>
                                        <TableCell>MOM</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['momQuotationStage']}</TableCell>
                                    </TableRow>
                                    <TableRow key='3'>
                                        <TableCell>Next Follow update</TableCell>
                                        <TableCell>{moment(projectLogs[stageIndex]?.['nextFollowUpDate']).format('DD-MM-YYYY')}</TableCell>
                                    </TableRow>
                                    <TableRow key='3'>
                                        <TableCell>Assigned To</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['user']?.['name']}</TableCell>
                                    </TableRow>
                                    <TableRow key='4'>
                                        <TableCell>Department</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['leadId']?.['departmentId']?.['name']}</TableCell>
                                    </TableRow>
                                    <TableRow key='5'>
                                        <TableCell>Team</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['leadId']?.['teamId']?.['name']}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                    {projectLogs[stageIndex]?.['preSalesCallDate'] &&
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="customized table">
                                <TableBody>
                                    <TableRow key='1'>
                                        <TableCell>Pre-sales Call Date</TableCell>
                                        <TableCell>{moment(projectLogs[stageIndex]?.['preSalesCallDate']).format('DD-MM-YYYY')}</TableCell>
                                    </TableRow>
                                    <TableRow key='3'>
                                        <TableCell>Next Follow update</TableCell>
                                        <TableCell>{moment(projectLogs[stageIndex]?.['nextFollowUpDate']).format('DD-MM-YYYY')}</TableCell>
                                    </TableRow>
                                    <TableRow key='3'>
                                        <TableCell>Assigned To</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['user']?.['name']}</TableCell>
                                    </TableRow>
                                    <TableRow key='4'>
                                        <TableCell>Department</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['leadId']?.['departmentId']?.['name']}</TableCell>
                                    </TableRow>
                                    <TableRow key='5'>
                                        <TableCell>Team</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['leadId']?.['teamId']?.['name']}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                    {projectLogs[stageIndex]?.['salesCallDate'] &&
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="customized table">
                                <TableBody>
                                    <TableRow key='1'>
                                        <TableCell>Sales Call Date</TableCell>
                                        <TableCell>{moment(projectLogs[stageIndex]?.['salesCallDate']).format('DD-MM-YYYY')}</TableCell>
                                    </TableRow>
                                    <TableRow key='2'>
                                        <TableCell>Requirements</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['requirements']}</TableCell>
                                    </TableRow>
                                    <TableRow key='3'>
                                        <TableCell>Estimated Budget</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['estimatedBudget']}</TableCell>
                                    </TableRow>
                                    <TableRow key='4'>
                                        <TableCell>Sales Call Comment</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['salesCallComment']}</TableCell>
                                    </TableRow>
                                    <TableRow key='5'>
                                        <TableCell>Next Follow update</TableCell>
                                        <TableCell>{moment(projectLogs[stageIndex]?.['nextFollowUpDate']).format('DD-MM-YYYY')}</TableCell>
                                    </TableRow>
                                    <TableRow key='6'>
                                        <TableCell>Assigned To</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['user']?.['name']}</TableCell>
                                    </TableRow>
                                    <TableRow key='4'>
                                        <TableCell>Department</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['leadId']?.['departmentId']?.['name']}</TableCell>
                                    </TableRow>
                                    <TableRow key='5'>
                                        <TableCell>Team</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['leadId']?.['teamId']?.['name']}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                    {projectLogs[stageIndex]?.['negotiationDate'] &&
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="customized table">
                                <TableBody>
                                    <TableRow key='1'>
                                        <TableCell>Negotiation Date</TableCell>
                                        <TableCell>{moment(projectLogs[stageIndex]?.['negotiationDate']).format('DD-MM-YYYY')}</TableCell>
                                    </TableRow>
                                    <TableRow key='2'>
                                        <TableCell>MOM Negotitation Stage</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['momNegotitationStage']}</TableCell>
                                    </TableRow>
                                    <TableRow key='5'>
                                        <TableCell>Next Follow update</TableCell>
                                        <TableCell>{moment(projectLogs[stageIndex]?.['nextFollowUpDate']).format('DD-MM-YYYY')}</TableCell>
                                    </TableRow>
                                    <TableRow key='6'>
                                        <TableCell>Assigned To</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['user']?.['name']}</TableCell>
                                    </TableRow>
                                    <TableRow key='7'>
                                        <TableCell>Department</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['leadId']?.['departmentId']?.['name']}</TableCell>
                                    </TableRow>
                                    <TableRow key='8'>
                                        <TableCell>Team</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['leadId']?.['teamId']?.['name']}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                    {projectLogs[stageIndex]?.['Won'] &&
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="customized table">
                                <TableBody>
                                    <TableRow key='1'>
                                        <TableCell>Won Date</TableCell>
                                        <TableCell>{moment(projectLogs[stageIndex]?.['closureDate']).format('DD-MM-YYYY')}</TableCell>
                                    </TableRow>
                                    <TableRow key='2'>
                                        <TableCell>Remarks</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['remark']}</TableCell>
                                    </TableRow>
                                    <TableRow key='3'>
                                        <TableCell>Payment Plan</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['paymentPlan']}</TableCell>
                                    </TableRow>
                                    <TableRow key='4'>
                                        <TableCell>Final Quote Attached</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['finalQuoteAttached'] === true ? `Yes` : `No`}</TableCell>
                                    </TableRow>
                                    <TableRow key='5'>
                                        <TableCell>Client Move in Date</TableCell>
                                        <TableCell>{moment(projectLogs[stageIndex]?.['clientMoveinDate']).format('DD-MM-YYYY')}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                    {projectLogs[stageIndex]?.['siteVisitDate'] &&
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="customized table">
                                <TableBody>
                                    <TableRow key='1'>
                                        <TableCell>Site/Visit Date</TableCell>
                                        <TableCell>{moment(projectLogs[stageIndex]?.['siteVisitDate']).format('DD-MM-YYYY')}</TableCell>
                                    </TableRow>
                                    <TableRow key='2'>
                                        <TableCell>MOM Meeting Stage</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['momMeetingStage']}</TableCell>
                                    </TableRow>
                                    <TableRow key='3'>
                                        <TableCell>MOM Site Visit Stage</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['momSiteVisitStage']}</TableCell>
                                    </TableRow>
                                    <TableRow key='5'>
                                        <TableCell>Next Follow update</TableCell>
                                        <TableCell>{moment(projectLogs[stageIndex]?.['nextFollowUpDate']).format('DD-MM-YYYY')}</TableCell>
                                    </TableRow>
                                    <TableRow key='6'>
                                        <TableCell>Assigned To</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['user']?.['name']}</TableCell>
                                    </TableRow>
                                    <TableRow key='4'>
                                        <TableCell>Department</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['leadId']?.['departmentId']?.['name']}</TableCell>
                                    </TableRow>
                                    <TableRow key='5'>
                                        <TableCell>Team</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['leadId']?.['teamId']?.['name']}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }

                    {
                        (
                            stageInfo !== 'Pre - Sales call' &&
                            stageInfo !== 'Sales Call' &&
                            stageInfo !== 'Negotiation' &&
                            stageInfo !== 'Quotation Sent' &&
                            stageInfo !== 'Site/Office visit' &&
                            stageInfo !== 'Won' &&
                            !projectLogs[stageIndex]?.['notes']
                        )
                            ?
                            <TableContainer component={Paper}>
                                <Table className={classes.table} size="small" aria-label="customized table">
                                    <TableBody>
                                        <TableRow key='1'>
                                            <TableCell>Assigned To</TableCell>
                                            <TableCell>{projectLogs[stageIndex]?.['user']?.['name']}</TableCell>
                                        </TableRow>
                                        <TableRow key='4'>
                                            <TableCell>Department</TableCell>
                                            <TableCell>{projectLogs[stageIndex]?.['leadId']?.['departmentId']?.['name']}</TableCell>
                                        </TableRow>
                                        <TableRow key='5'>
                                            <TableCell>Team</TableCell>
                                            <TableCell>{projectLogs[stageIndex]?.['leadId']?.['teamId']?.['name']}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            :
                            <TableContainer component={Paper}>
                                <Table className={classes.table} size="small" aria-label="customized table">
                                    <TableBody>
                                        {
                                            projectLogs[stageIndex]?.['notes'] &&

                                            <TableRow key='2'>
                                                <TableCell>Notes</TableCell>
                                                <TableCell>{projectLogs[stageIndex]?.['notes']}</TableCell>
                                            </TableRow>
                                        }
                                        {
                                            projectLogs[stageIndex]?.['s3Location'].length !== 0 &&

                                            <TableRow key='3'>
                                                <TableCell>Attachments</TableCell>
                                                {projectLogs[stageIndex]?.['s3Location']?.map((data, i) => (
                                                    <TableCell key={i + 1}>{data}</TableCell>
                                                ))}
                                            </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                    }
                    {
                        (
                            projectLogs[stageIndex]?.['quotationSentDate'] === null ||
                            projectLogs[stageIndex]?.['preSalesCallDate'] === null ||
                            projectLogs[stageIndex]?.['salesCallDate'] === null ||
                            projectLogs[stageIndex]?.['negotiationDate'] === null ||
                            projectLogs[stageIndex]?.['siteVisitDate'] === null
                        )
                        &&
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="customized table">
                                <TableBody>
                                    <TableRow key='1'>
                                        <TableCell>Assigned To</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['user']?.['name']}</TableCell>
                                    </TableRow>
                                    <TableRow key='4'>
                                        <TableCell>Department</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['leadId']?.['departmentId']?.['name']}</TableCell>
                                    </TableRow>
                                    <TableRow key='5'>
                                        <TableCell>Team</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['leadId']?.['teamId']?.['name']}</TableCell>
                                    </TableRow>
                                    <TableRow key='2'>
                                        <TableCell>Notes</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['notes']}</TableCell>
                                    </TableRow>
                                    <TableRow key='3'>
                                        <TableCell>Attachments</TableCell>
                                        {projectLogs[stageIndex]?.['s3Location']?.map((data, i) => (
                                            <TableCell key={i + 1}>{data}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                </DialogContent>
            </Dialog>

            {/* Create Notes Modal */}
            <Dialog open={openNotesModal} onClose={() => setOpenNotesModal(false)} aria-labelledby="form-dialog-title" maxWidth='sm'>
                <DialogTitle id="form-dialog-title">Create Notes</DialogTitle>
                <Divider />
                <DialogContent>
                    <div style={{ paddingBottom: 15 }}>
                        <div className="editor">
                            <Editor
                                editorKey={'editor'}
                                editorState={editorState}
                                onChange={setEditorStateData}
                                plugins={plugins}
                                ref={editorRef}
                            />
                            <MentionSuggestions
                                open={mentionOpen}
                                onOpenChange={onOpenChange}
                                suggestions={suggestions}
                                onSearchChange={onSearchChange}
                                onAddMention={onAddMention}
                                onClose={() => { }}
                            />
                        </div>

                    </div>
                    <div style={{ paddingBottom: 15 }}>
                        <TextField
                            id="start_date"
                            label="Set Reminder"
                            name="reminderDate"
                            onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}
                            type="datetime-local"
                            className={classes.textFieldWidth}
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </div>
                    <div style={{ paddingBottom: 15 }}>
                        <input
                            accept="*"
                            ref={createNoteFile}
                            className={classes.input}
                            id="project-document"
                            onChange={uploadCreateNoteImg}
                            type="file"
                        />
                        <label htmlFor="project-document">
                            {
                                fileUploaded && <CheckCircleIcon />
                            }
                        </label>
                    </div>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={() => setOpenNotesModal(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={saveNotes} color="primary">
                        Save Note
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Change Lead Status Modal */}
            <Dialog open={openStatusModal} onClose={() => setOpenStatusModal(false)} aria-labelledby="form-dialog-title" maxWidth='sm'>
                <DialogTitle id="form-dialog-title">
                    Upload the file
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <div style={{ paddingBottom: 15, marginTop: 15 }}>
                        <input
                            accept="application/pdf"
                            className={classes.input}
                            id="wdfile-document"
                            onChange={(e) => uploadWDFileDocument(e, 'finalWdfile')}
                            type="file"
                            style={{ display: 'none' }}
                        />
                        <label htmlFor="wdfile-document">
                            <Button variant="contained" color="primary" style={{ background: "#716ACA", margin: 5 }} component="span">
                                Upload Final Working Drawing File
                            </Button>
                        </label>
                        {checkIsUpload.finalWorkingDrawingFile && <CheckCircleIcon style={{ color: "green" }} />}
                    </div>
                    {
                        projectDetails?.finalWDFile && <>
                            <hr />
                            <div style={{ paddingBottom: 15, marginTop: 15 }}>
                                <Chip
                                    avatar={<Avatar><GetAppOutlinedIcon /></Avatar>}
                                    clickable
                                    color="primary"
                                    name="working"
                                    label="Download existing uploaded file"
                                    onClick={() => window.open(projectDetails?.finalWDFile)}
                                />
                            </div>
                        </>
                    }
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={() => setOpenStatusModal(false)} variant="outlined" color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={() => uploadAndSendToImosAndSiteBOM()} variant="outlined" color="primary" disabled={disable}>
                        Upload File
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Upload the Pasting file */}
            <Dialog open={openPastingFileUploadDialog} onClose={() => setOpenPastingFileUploadDialog(false)} aria-labelledby="form-dialog-title" maxWidth='sm'>
                <DialogTitle id="form-dialog-title">
                    Upload the Pasting List file
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <div style={{ paddingBottom: 15, marginTop: 15 }}>
                        <input
                            accept=".xlsx, .xls"
                            className={classes.input}
                            id="updatedPasting-document"
                            onChange={(e) => uploadWDFileDocument(e, 'updatedPastingFile')}
                            type="file"
                            style={{ display: 'none' }}
                        />
                        <label htmlFor="updatedPasting-document">
                            <Button variant="contained" color="primary" style={{ background: "#716ACA", margin: 5 }} component="span">
                                Upload Updated Pasting List File
                            </Button>
                        </label>
                        {checkIsUpload.updatedPastingFile && <CheckCircleIcon style={{ color: "green" }} />}
                    </div>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={() => setOpenPastingFileUploadDialog(false)} variant="outlined" color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={() => uploadUpdatedPastingFile()} variant="outlined" color="primary">
                        Upload File
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openMoveTofactoryDialog} onClose={() => setOpenMoveTofactoryDialog(false)} aria-labelledby="form-dialog-title" maxWidth='sm'>
                <DialogTitle id="form-dialog-title">
                   Are you sure want to move the lead to Factory? 
                </DialogTitle>
                <DialogActions>
                    <Button onClick={() => setOpenMoveTofactoryDialog(false)} variant="outlined" color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={() => leadMoveToFactory()} variant="outlined" color="primary">
                        Move
                    </Button>
                </DialogActions>
            </Dialog>
            {}
            <Dialog open={siteBomCompleted} onClose={() => setSiteBomCompleted(false)} aria-labelledby="form-dialog-title" maxWidth='sm'>
                <DialogTitle id="form-dialog-title">
                    Is the Site BOM done for this project ?
                </DialogTitle>
                <DialogActions>
                    <Button onClick={() => setSiteBomCompleted(false)} variant="outlined" color="secondary">
                       No
                    </Button>
                    <Button onClick={() => moveToSiteBomComplete()} variant="outlined" color="primary">
                       Yes
                    </Button>
                </DialogActions>
            </Dialog>
            {/* Move Deal Model window */}
            <Dialog open={moveDealOpen} onClose={() => setMoveDealOpen(false)}>
                <DialogTitle>Change Stage</DialogTitle>
                <DialogContent>
                    <Box sx={{ minWidth: 450 }}>
                        <FormControl fullWidth variant="outlined" size="small">
                            <InputLabel id="demo-simple-select-label">Select Stage</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedStage}
                                label="Select Stage"
                                variant="outlined"
                                onChange={handleSelectedStageChange}
                            >
                                {departmentStage.map((row, i) => {
                                    return (
                                        <MenuItem key={i + 1} value={row}>{row}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setMoveDealOpen(false)} variant="outlined" color="primary">Cancel</Button>
                    {
                    userDetails?.roles?.find(role =>  role.name === ROLENAME.IMOSUser)?
                    <Button variant="contained" color="primary" onClick={() => assignLeadToUse()}>Change</Button>:
                    selectedStage ==="IMOS Completed" ?   <Button onClick={assignLeadToUser} variant="contained" color="primary">Change</Button>:
                    <Button onClick={openAnotherDialog} variant="contained" color="primary">Change</Button>
                    }
                </DialogActions>
            </Dialog>

            {/* Dialog for select the details of previous dialog selected stage */}
            <Dialog open={openMoveDealDetailsDialog} onClose={() => setOpenMoveDealDetailsDialog(false)}>
                <DialogTitle>Select Details</DialogTitle>
                <DialogContent>
                    <Box sx={{ minWidth: 450 }}>
                        {/* Select Department */}
                        <FormControl fullWidth style={{ marginBottom: "15px" }} variant="outlined" size="small">
                            <InputLabel id="demo-simple-select-label">Select Department</InputLabel>
                            <Select
                                labelId="demo-simple-select-label1"
                                id="demo-simple-select1"
                                value={department}
                                label="Select Stage"
                                onChange={handleSelectedDepartmentChange}
                                variant="outlined"
                                size="small"
                            >
                                {departmentLists.map((row, i) => {
                                    return (
                                        <MenuItem key={i + 1} value={row._id}>{row.name}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                        {/* Select Team */}
                        <FormControl fullWidth style={{ marginBottom: "15px" }} variant="outlined" size="small">
                            <InputLabel id="demo-simple-select-label2">Select Team</InputLabel>
                            <Select
                                labelId="demo-simple-select-label2"
                                id="demo-simple-select2"
                                value={team}
                                label="Select Team"
                                onChange={handleSelectedTeamChange}
                                variant="outlined"
                                size="small"
                            >
                                {teamLists.map((row, i) => {
                                    return (
                                        <MenuItem key={i + 1} value={row._id}>{row.name}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                        {/* Select Deal Owner User */}
                        <FormControl fullWidth style={{ marginBottom: "15px" }} variant="outlined" size="small">
                            <InputLabel id="demo-simple-select-label3">Select User</InputLabel>
                            <Select
                                labelId="demo-simple-select-label3"
                                id="demo-simple-select3"
                                value={dealOwner}
                                label="Select User"
                                onChange={handleSelectedUserChange}
                                variant="outlined"
                                size="small"
                            >
                                {dealOwnerLists.map((row, i) => {
                                    return (
                                        <MenuItem key={i + 1} value={row._id}>{row.name}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenMoveDealDetailsDialog(false)} variant="outlined">Cancel</Button>
                    <Button variant="contained" color="primary" onClick={() => assignLeadToUser()}>Assign Project</Button>
                </DialogActions>
            </Dialog>

            {/* Upload IMOS Document Modal */}
            <Dialog open={openDocUploaderDialog} onClose={() => setOpenDocUploaderDialog(false)} aria-labelledby="form-dialog-title" maxWidth='sm'>
                <DialogTitle id="form-dialog-title">Upload Documents</DialogTitle>
                <Divider />
                <DialogContent>
                    {
                        projectDetails.imosStage === 'IMOS Completed' &&
                        <Paper style={{ textAlign: 'center', marginBottom: 20, paddingLeft: 20 }}>
                            <FormControl component="fieldset" className={classes.root}>
                                <RadioGroup aria-label="type" name="type" value={radioValue} onChange={handleRadioChange}>
                                    <div className={classes.radioButton}>
                                        <FormControlLabel value="new_document" control={<Radio />} label="New Document" />
                                        <FormControlLabel value="replace_document" control={<Radio />} label="Replace Document" />
                                    </div>
                                </RadioGroup>
                            </FormControl>
                        </Paper>
                    }
                    <div style={{ paddingBottom: 15 }}>
                        <input
                            accept=".zip, application/pdf"
                            className={classes.input}
                            id="doc1-document"
                            onChange={(e) => uploadWDFileDocument(e, 'doc1')}
                            type="file"
                            style={{ display: 'none' }}
                        />
                        <label htmlFor="doc1-document">
                            <Button variant="contained" color="primary" style={{ background: "#716ACA", margin: 5 }} component="span">
                                {projectDetails.imosStage === 'IMOS Completed' && radioValue == 'replace_document' ? 'Replace Carpentry File': 'Upload Carpentry File'}
                            </Button>
                        </label>
                        {checkIsUpload.doc1 && <CheckCircleIcon style={{ color: "green" }} />}
                    </div>
                    <div style={{ paddingBottom: 15 }}>
                        <input
                            accept=".zip, image/*"
                            className={classes.input}
                            id="doc2-document"
                            onChange={(e) => uploadWDFileDocument(e, 'doc2')}
                            type="file"
                            style={{ display: 'none' }}
                        />
                        <label htmlFor="doc2-document">
                            <Button variant="contained" color="primary" style={{ background: "#716ACA", margin: 5 }} component="span">
                                {projectDetails.imosStage === 'IMOS Completed' && radioValue == 'replace_document' ? 'Replace Custom File' : 'Upload Custom File'}
                            </Button>
                        </label>
                        {checkIsUpload.doc2 && <CheckCircleIcon style={{ color: "green" }} />}
                    </div>
                    <div style={{ paddingBottom: 15 }}>
                        <input
                            accept=".xlsx, .xls"
                            className={classes.input}
                            id="doc3-document"
                            onChange={(e) => uploadWDFileDocument(e, 'doc3')}
                            type="file"
                            style={{ display: 'none' }}
                        />
                        <label htmlFor="doc3-document">
                            <Button variant="contained" color="primary" style={{ background: "#716ACA", margin: 5 }} component="span">
                                {projectDetails.imosStage === 'IMOS Completed' && radioValue == 'replace_document' ? 'Replace Edge Band File' : 'Upload Edge Band File'}
                                
                            </Button>
                        </label>
                        {checkIsUpload.doc3 && <CheckCircleIcon style={{ color: "green" }} />}
                    </div>
                    <div style={{ paddingBottom: 15 }}>
                        <input
                            accept=".xlsx, .xls"
                            className={classes.input}
                            id="doc4-document"
                            onChange={(e) => uploadWDFileDocument(e, 'doc4')}
                            type="file"
                            style={{ display: 'none' }}
                        />
                        <label htmlFor="doc4-document">
                            <Button variant="contained" color="primary" style={{ background: "#716ACA", margin: 5 }} component="span">
                                {projectDetails.imosStage === 'IMOS Completed' && radioValue == 'replace_document' ? 'Replace Hardware File' : 'Upload Hardware File'}
                            </Button>
                        </label>
                        {checkIsUpload.doc4 && <CheckCircleIcon style={{ color: "green" }} />}
                    </div>
                    <div style={{ paddingBottom: 15 }}>
                        <input
                            accept="application/pdf"
                            className={classes.input}
                            id="doc5-document"
                            onChange={(e) => uploadWDFileDocument(e, 'doc5')}
                            type="file"
                            style={{ display: 'none' }}
                        />
                        <label htmlFor="doc5-document">
                            <Button variant="contained" color="primary" style={{ background: "#716ACA", margin: 5 }} component="span">
                                {projectDetails.imosStage === 'IMOS Completed' && radioValue == 'replace_document' ? 'Replace Pasting List PDF' : 'Upload Pasting List PDF'}
                            </Button>
                        </label>
                        {checkIsUpload.doc5 && <CheckCircleIcon style={{ color: "green" }} />}
                    </div>
                    <div style={{ paddingBottom: 15 }}>
                        <input
                            accept=".xlsx, .xls, .csv"
                            className={classes.input}
                            id="doc6-document"
                            onChange={(e) => uploadWDFileDocument(e, 'doc6')}
                            type="file"
                            style={{ display: 'none' }}
                        />
                        <label htmlFor="doc6-document">
                            <Button variant="contained" color="primary" style={{ background: "#716ACA", margin: 5 }} component="span">
                                {projectDetails.imosStage === 'IMOS Completed' && radioValue == 'replace_document' ? 'Replace Pasting List Excel' : 'Upload Pasting List Excel'}
                            </Button>
                        </label>
                        {checkIsUpload.doc6 && <CheckCircleIcon style={{ color: "green" }} />}
                    </div>
                    <div style={{ paddingBottom: 15 }}>
                        <input
                            accept=".saw"
                            className={classes.input}
                            id="doc7-document"
                            onChange={(e) => uploadWDFileDocument(e, 'doc7')}
                            type="file"
                            style={{ display: 'none' }}
                        />
                        <label htmlFor="doc7-document">
                            <Button variant="contained" color="primary" style={{ background: "#716ACA", margin: 5 }} component="span">
                                {projectDetails.imosStage === 'IMOS Completed' && radioValue == 'replace_document' ? 'Replace Saw File' : 'Upload Saw File'}
                            </Button>
                        </label>
                        {checkIsUpload.doc7 && <CheckCircleIcon style={{ color: "green" }} />}
                    </div>
                    <div style={{ paddingBottom: 15 }}>
                        <input
                            accept=".zip,.rar"
                            className={classes.input}
                            id="doc8-document"
                            onChange={(e) => uploadWDFileDocument(e, 'doc8')}
                            type="file"
                            style={{ display: 'none' }}
                        />
                        <label htmlFor="doc8-document">
                            <Button variant="contained" color="primary" style={{ background: "#716ACA", margin: 5 }} component="span">
                                {projectDetails.imosStage === 'IMOS Completed' && radioValue == 'replace_document' ? 'Replace MPR File' : 'Upload MPR File' }
                            </Button>
                        </label>
                        {checkIsUpload.doc8 && <CheckCircleIcon style={{ color: "green" }} />}
                    </div>
                    <div style={{ paddingBottom: 15 }}>
                        <input
                            accept=".xlsx, .xls, .csv"
                            className={classes.input}
                            id="doc9-document"
                            onChange={(e) => uploadWDFileDocument(e, 'doc9')}
                            type="file"
                            style={{ display: 'none' }}
                        />
                        <label htmlFor="doc9-document">
                            <Button variant="contained" color="primary" style={{ background: "#716ACA", margin: 5 }} component="span">
                                {projectDetails.imosStage === 'IMOS Completed' && radioValue == 'replace_document' ? 'Replace Tracking File' : 'Upload Tracking File'}
                            </Button>
                        </label>
                        {checkIsUpload.doc9 && <CheckCircleIcon style={{ color: "green" }} />}
                    </div>
                    <div style={{ paddingBottom: 15 }}>
                        <input
                            accept=".zip,.rar"
                            className={classes.input}
                            id="doc10-document"
                            onChange={(e) => uploadWDFileDocument(e, 'doc10')}
                            type="file"
                            style={{ display: 'none' }}
                        />
                        <label htmlFor="doc10-document">
                            <Button variant="contained" color="primary" style={{ background: "#716ACA", margin: 5 }} component="span">
                                {projectDetails.imosStage === 'IMOS Completed' && radioValue == 'replace_document' ? 'Replace Jali File' : 'Upload Jali File' }
                            </Button>
                        </label>
                        {checkIsUpload.doc10 && <CheckCircleIcon style={{ color: "green" }} />}
                    </div>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={() => setOpenDocUploaderDialog(false)} variant="contained" color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={submitIMOSDocumets} variant="contained" color="primary" >
                        Submit Documents
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Change Stage Status Modal */}
            <Dialog open={openStageStatusModal} onClose={() => setOpenStageStatusModal(false)} aria-labelledby="form-dialog-title" maxWidth='sm'>
                <DialogTitle id="form-dialog-title">
                    Update Stage Staus
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <div style={{ paddingBottom: 15, marginTop: 15 }}>
                        <FormControl fullWidth variant="outlined" size="small">
                            <InputLabel id="demo-simple-select-label">Select Stage Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedStageStatus}
                                label="Select Stage Status"
                                variant="outlined"
                                onChange={(e) => handleSelectedStageStatus(e)}
                            >
                                <MenuItem key='1' value='Under Production - Machine'>Under Production - Machine</MenuItem>
                                <MenuItem key='2' value='Under Production - Fixing'>Under Production - Fixing</MenuItem>
                                <MenuItem key='3' value='Ready for dispatch'>Ready for dispatch</MenuItem>
                            </Select>
                        </FormControl>
                        {
                            selectedStageStatus === 'Ready for dispatch' &&
                            <FormControl fullWidth style={{ paddingTop: 20 }} variant="outlined" size="small">
                                <TextField
                                    style={{ margin: '5px' }}
                                    id="dispatch-Date"
                                    name="dispatchDate"
                                    value={moment(dispatchDate).format('YYYY-MM-DD')}
                                    onChange={(e) => setDispatchDate(e.target.value)}
                                    label="Ready to dispatch date"
                                    type="date"
                                    inputProps={{
                                        max: moment().format('YYYY-MM-DD'),
                                        onKeyDown: (e) => e.preventDefault() 
                                    }}        
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    variant="outlined"
                                    size="small"
                                />
                            </FormControl>
                        }
                    </div>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={() => setOpenStageStatusModal(false)} variant="outlined" color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={() => updateStageStatus()} variant="outlined" color="primary">
                        Update Stage Status
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Update Material Received Status Modal */}
            <Dialog open={openUpdateMaterialStatusModal} onClose={() => setOpenUpdateMaterialStatusModal(false)} aria-labelledby="form-dialog-title" maxWidth='sm'>
                <DialogTitle id="form-dialog-title">
                    Update Material Received Status
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <div style={{ paddingBottom: 15, marginTop: 15 }}>
                        <FormControl fullWidth variant="outlined" size="small">
                            <InputLabel id="demo-simple-select-label">Select Material Received Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedMaterilPercentage}
                                label="Select Stage Status"
                                variant="outlined"
                                onChange={(e) => setSelectedMaterilPercentage(e.target.value)}
                            >
                                <MenuItem key='1' value='Yes'>Yes</MenuItem>
                                <MenuItem key='4' value='No'>No</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={() => setOpenUpdateMaterialStatusModal(false)} variant="outlined" color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={() => updateMaterialReceived()} variant="outlined" color="primary">
                        Update Material Received Status
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}


export default connect(null, { setLoading, setSnackInfo })(ManageSiteQcProjectLogs)