import React, { useState, useEffect, useRef } from 'react';
import { useParams } from "react-router-dom";
import { connect, useSelector } from 'react-redux'
import CryptoJs from 'crypto-js';
import { setLoading, setSnackInfo } from '../redux/actions/appAction';
import HttpService from '../services/httpService';
import { TextField, FormControl, Typography, DialogTitle, Dialog, DialogContent, DialogActions, Button, Paper, Divider, Box, DialogContentText } from '@material-ui/core';
import { ROLENAME, constantDepartmentId} from '../utils/index';
import DesignForm from '../pages/customerSurveyForm/design'
import {  DownloadOutlined } from '@ant-design/icons';
import moment from 'moment'


const CustomerContractSignView = (props) => {
    const { leadId } = useParams();
    const ref = useRef(null);
    useEffect(() => {
        getLeadDetails();
        // eslint-disable-next-line
    }, [leadId])

    const [leadDetail, setLeadDetails] = useState([]);
    const [btnDisable, setBtnDisable] = useState(false);
    const [leadOwnerDetail, setLeadOwnerDetail] = useState({
        name: '',
        email: '',
        mobile: '',
        leadId: ''
    })
    // Created State for reject button modal
    const [openRejectModel, setOpenRejectModal] = useState(false);
    const [rejectReason, setRejectReason] = useState("");
    const getLeadDetails = async () => {
        props.setLoading(true)
        try {
            const response = await HttpService.getLeadDetails(leadId);
            let leadOwnersList = [];
            for (let i = 0; i < response.data.previouslyAssignedTo.length; i++) {
                for (let j = 0; j < response.data.previouslyAssignedTo[i].roles.length; j++) {
                    if (response.data.departmentId === constantDepartmentId.design && response.data.previouslyAssignedTo[i]['roles'][j].name === ROLENAME.AssistanceDesignManager) {
                        leadOwnersList.push(response.data.previouslyAssignedTo[i])
                    } else if (response.data.departmentId === constantDepartmentId.design && response.data.previouslyAssignedTo[i]['roles'][j].name === ROLENAME.DesignManager) {
                        leadOwnersList.push(response.data.previouslyAssignedTo[i])
                    } else if (response.data.departmentId === constantDepartmentId.design && response.data.previouslyAssignedTo[i]['roles'][j].name === ROLENAME.DesignUser) {
                        leadOwnersList.push(response.data.previouslyAssignedTo[i])
                    }
                }
            }
            if (leadOwnersList.length !== 0) {
                let finalLeadOwnerUser = leadOwnersList.pop();
                setLeadOwnerDetail({
                    name: finalLeadOwnerUser.name,
                    email: finalLeadOwnerUser.email,
                    mobile: finalLeadOwnerUser.mobile,
                    _id: finalLeadOwnerUser._id,
                    customerId: response.data.customerId._id,
                    leadId: leadId
                })
            } else {
                setLeadOwnerDetail({
                    name: response.data.assignTo.name,
                    email: response.data.assignTo.email,
                    mobile: response.data.assignTo.mobile,
                    _id: response.data.assignTo._id,
                    customerId: response.data.customerId._id,
                    leadId: leadId
                })
            }
            setLeadDetails(response.data)
            setBtnDisable(response.data.contractCustomerApproved)
        } catch (error) {
            console.log(error, 'error')
            props.setSnackInfo(error?.response ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const [openModal, setOpenModal] = useState(false);
    const { userDetails } = useSelector(state => state.user)

    const customerAction = async (status) => {
        if (status === 'Approve') {
            setOpenModal(true);
        } else {
            setOpenRejectModal(false);
            let obj = {
                contractCustomerApproved: false,
                status:'Reject',
                rejectMessage: rejectReason,
                userDetails: userDetails,
                rejectChecklistStatus: true
            }
            props.setLoading(true)
            try {
                await HttpService.customerContractSingActions(leadDetail._id, obj);
                getLeadDetails()
                props.setSnackInfo("Design Sign-off Rejected Successfully", "success")
            } catch (error) {
                console.log(error, 'error');
            }
            props.setLoading(false)
        }
    }

    const [otpSend, setOtpSend] = useState(false);
    const [otpResponse, setOtpResponse] = useState([]);
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');

    const sendDataToParent = (formData) => {
        setDesignData(formData)
        setOTPMessage()
    };

    const setOTPMessage = async (e) => {
        // eslint-disable-next-line no-new-wrappers
    //    if (mobile.length === 10) {
            let obj = {
                mobile: mobile,
                email: email
            }
            props.setLoading(true)
            try {
                const response = await HttpService.sendOtpMessage(obj)
                setOtpSend(true)

                // Decryption of OTP
                var bytes  = CryptoJs.AES.decrypt(response.data.otp, 'decorpot123');
                var decrypted_otpNumber = bytes.toString(CryptoJs.enc.Utf8);

                setOtpResponse({otp: Number(decrypted_otpNumber)})
                ref.current?.scrollIntoView({behavior: 'smooth'});
                let focusTextFieldOtp = document.getElementById('outlined-basic1-otp');
                focusTextFieldOtp.focus();
            } catch (error) {
                props.setSnackInfo(error.response.data.msg, "error")
            }
            props.setLoading(false)
        //}
    }

    const [otpVerified, setOtpVerified] = useState(false);
    const [designData, setDesignData] = useState({})
    const [disable, setDisable] = useState(false);
    const verifyOTP = (e) => {
        // eslint-disable-next-line no-new-wrappers
        let n = new String(e.target.value);
        if (n.length === 6 && otpResponse.otp === +e.target.value) {
            setOtpVerified(true);
        } else {
            setOtpVerified(false);
        }
    }

    const verifyCustomer = async () => {
        setDisable(true)
        let obj = {
            contractCustomerApproved: true,
            ...designData,
            leadOwner: leadOwnerDetail,
            typeOption: 'design'
        }
        props.setLoading(true)
        try {
            await HttpService.customerContractSingActions(leadDetail._id, obj);
            setOpenModal(false);
            setOtpSend(false);
            setOtpResponse([]);
            getLeadDetails();
            props.setSnackInfo("Design Sign-off Approved Successfullly", "success")
        } catch (error) {
            console.log(error, 'error');
        }
        props.setLoading(false)
    }

    const  handleDownloadClick = (event, attachmentUrl)=> {
        window.open(attachmentUrl);
      }

    return (
        <div>
            <div style={{ marginBottom: 20, marginTop: 50 }}>
                <h2 style={{ textAlign: 'center' }}>Verify and Approve Design Sign-Off</h2>
            </div>
            <Divider />

            <div style={{ margin: '50px auto', width: '80%', marginLeft: '100px' }}>
                <Paper>
                    <ul style={{ paddingTop: 20, marginLeft: '40px', }} >
                        <li><strong>Quotation:</strong> <Button color="primary" onClick={() => window.open(leadDetail?.scanQuotationFile)} style={{ textTransform: 'capitalize', textDecoration: 'underline' }}>View File</Button></li>
                        <li><strong>Check List:</strong> <Button color="primary" onClick={() => window.open(leadDetail?.scanCheckListFile)} style={{ textTransform: 'capitalize', textDecoration: 'underline' }}>View File</Button></li>
                        <li><strong>Project Kick-Off Check List:</strong> <Button color="primary" onClick={() => window.open(leadDetail?.scanProjectKickOffCheckListFile)} style={{ textTransform: 'capitalize', textDecoration: 'underline' }}>View File</Button></li>
                        {leadDetail.currentStage === "Design Sign-off HOLD" && <li><strong>Design Sign-off HOLD Revoke Date: {leadDetail && moment(leadDetail?.designSignoffRevokeDate).format('D MMMM YYYY')}</strong> </li>}
                        <li><strong>Working Drawing:</strong> <Button color="primary" onClick={() => window.open(leadDetail?.workingDrawingFile)} style={{ textTransform: 'capitalize', textDecoration: 'underline' }}>View File</Button></li>
                        {/* <li><strong>Design Sign-off:</strong> <Button color="primary" onClick={() => window.open(leadDetail?.termsAndConditionsPdf)} style={{ textTransform: 'capitalize', textDecoration: 'underline' }}>View File</Button></li> */}
                        {leadDetail?.contractSignedPaymentReceviedAttachemnts ? (
                            <>
                                <li style={{ paddingTop: 10, paddingBottom: 8 }}>
                                    <strong>Payment Reciept: </strong>
                                    {leadDetail?.contractSignedPaymentReceviedAttachemnts.map((el, index) => (
                                        <span key={index}>
                                            <DownloadOutlined onClick={(e) => handleDownloadClick(e, el)} />
                                            {index < leadDetail.contractSignedPaymentReceviedAttachemnts.length - 1 ? ', ' : ''}
                                        </span>
                                    ))}
                                </li>
                            </>
                        ) : (
                            <li>
                                <strong>Payment Reciept: </strong>
                                <Button style={{ textTransform: 'capitalize' }}>Not Available</Button>
                            </li>
                        )}
                          {leadDetail?.siteQcVideo ? (
                            <>
                            <li style={{ paddingTop: 6, paddingBottom: 8 }}>
                            <strong>SiteQC Video: </strong> 
                            {leadDetail?.siteQcVideo?.map((el, index) => (
                                <span key={index}>
                                    <DownloadOutlined  onClick={(e) => handleDownloadClick(e,el)} />
                                {index < leadDetail.siteQcVideo?.length - 1 ? ', ' : ''}
                                </span>
                            ))}
                            </li>
                            </>
                            ) : (
                            <li>
                                <strong>SiteQC Video : </strong>
                                <Button style={{ textTransform: 'capitalize' }}>Not Available</Button>
                            </li>
                        )}

                        {leadDetail?.scanSiteQCPhotos ? (
                            <>
                                <li style={{ paddingTop: 6, paddingBottom: 8 }}>
                                    <strong>SiteQC Photos: </strong>
                                    {leadDetail?.scanSiteQCPhotos.map((el, index) => (
                                        <span key={index}>
                                            <DownloadOutlined onClick={(e) => handleDownloadClick(e, el)} />
                                            {index < leadDetail.scanSiteQCPhotos.length - 1 ? ', ' : ''}
                                        </span>
                                    ))}
                                </li>
                            </>
                        ) : (
                            <li>
                                <strong>SiteQC Photos: </strong>
                                <Button style={{ textTransform: 'capitalize' }}>Not Available</Button>
                            </li>
                        )}
                    </ul>

                    <div style={{ marginTop: 20, marginBottom: 20, marginLeft: 30, fontWeight: 700 }}> 
                        {btnDisable && `The Documents Have Been Verified and Digitally Signed, You'll Receive a Mail Shortly With The Details`}
                    </div>
                    <div style={{ marginTop: 20, marginBottom: 20 }}>
                        <Button onClick={()=>setOpenRejectModal(true)} disabled={btnDisable || leadDetail.contractDesignManagerApproved === false} variant="outlined" style={{ marginRight: 10, marginLeft: 30 }}>Reject</Button>
                        <Button onClick={() => customerAction("Approve")} disabled={btnDisable || leadDetail.contractDesignManagerApproved === false} variant="contained" color="primary">Proceed to Approval</Button>
                    </div>
                    <br />
                </Paper>
                <h3 style={{ marginTop: 20 }}>Note: After approval documents will be signed digitally</h3>
            </div>

            <Dialog open={openModal} onClose={() => setOpenModal(false)} aria-labelledby="form-dialog-title" maxWidth='lg'>
                <DialogTitle id="form-dialog-title">Verify Details To Approve Design Sign-Off</DialogTitle>
                <Divider />
                <DialogContent>
                    <div >
                        <FormControl style={{ width: "100%", marginBottom: 20, marginTop: 20 }}>
                            <TextField id="outlined-basic" label="Enter Email Id" variant="outlined" name="email" onChange={(e) => setEmail(e.target.value)} size="large" />
                        </FormControl>
                        <br />
                        {/* <FormControl style={{ width: "30%", marginBottom: 20 }}>
                            <TextField id="outlined-basic" label="Enter Mobile Number" variant="outlined" name="mobile_no" onChange={(e) => setMobile(e.target.value)} size="small" />
                        </FormControl> */}

                        <DesignForm 
                            TYPEOPTION='design'
                            LEADOWNERDATA={leadOwnerDetail}
                            sendDataToParent={sendDataToParent}
                        />

                        {
                            otpSend === true && <Paper style={{ marginTop: 20, padding: 10 }} ref={ref}>
                                <Typography component="p" style={{ marginBottom: 10 }}> Please check your email id for OTP</Typography>
                                <FormControl style={{ width: '30%', marginBottom: 20 }}>
                                    <TextField id="outlined-basic1-otp" label="Enter OTP" variant="outlined" name="otp" onChange={(e) => verifyOTP(e)} size="small" />
                                </FormControl>
                            </Paper>
                        }
                    </div>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={() => setOpenModal(false)} variant="outlined">No</Button>
                    <Button onClick={() => verifyCustomer()} variant="contained" color="primary" disabled={otpVerified === false || disable}>Verify</Button>
                </DialogActions>
            </Dialog>

        {/* Creating Dialog Box: when Customer want to reject checklist and click reject button then open this dialog and ask for confirmation */}
            <Dialog
                open={openRejectModel}
                onClose={()=>setOpenRejectModal(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Reject Checklist"}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="reject-reason"
                        label="Reason for rejection"
                        type="text"
                        style={{ width: '100%' }} 
                        value={rejectReason}
                        onChange={(e)=>setRejectReason(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => customerAction("Reject")} variant="contained" color="primary" >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default connect(null, { setLoading, setSnackInfo })(CustomerContractSignView)
