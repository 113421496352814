import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { getAllUnits } from '../../../redux/actions/projectAction'
import { setLoading, setSnackInfo } from '../../../redux/actions/appAction'
import { Table, TableBody, TextField, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import HttpService from '../../../services/httpService';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 850,
  }
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#ccc",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const ManageUnits = (props) => {
  const classes = useStyles();

  useEffect(() => {
    getAllUnits()
    // eslint-disable-next-line
  }, [])

  const [showAddUnitModal, setShowAddUnitModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [unitDetails, setUnitDetails] = useState({
    id: "",
    name: "",
    description: ""
  })
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState("")

  const { name, description } = unitDetails

  const getAllUnits = async () => {
    props.setLoading(true)
    try {
      await props.getAllUnits()
    } catch (error) {
      console.error(error)
      props.setSnackInfo('Error fetching UOM', "error")
    }
    props.setLoading(false)
  }
  const { units } = useSelector(state => state.project)


  const handleDialogClose = () => {
    setUnitDetails({ name: "", description: "" })
    setShowAddUnitModal(false)
  }

  const addUnit = async () => {
    props.setLoading(true)
    try {

      const response = await HttpService.addUnit({ name, description })
      props.setSnackInfo(response?.data, "success")
      handleDialogClose()
      getAllUnits()
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  const editUnit = (data) => {
    setUnitDetails({ id: data?._id, name: data?.name, descirption: data?.descirption })
    setShowAddUnitModal(true)
    setIsEdit(true)
  }

  const updateUnit = async () => {
    props.setLoading(true)
    try {
      const response = await HttpService.updateUnit({ name, description }, unitDetails.id)
      getAllUnits()
      handleDialogClose()
      setIsEdit(false)
      props.setSnackInfo(response?.data, "success")
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  const closeDeleteModal = () => { setShowDeleteModal(false); setDeleteId("") }

  const deleteUnit = async () => {
    props.setLoading(true)
    try {
      await HttpService.deleteUnit(deleteId)
      setShowDeleteModal(false)
      setDeleteId("")
      getAllUnits()
      props.setSnackInfo("Deleted", "success")
    } catch (error) {
      console.log(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  return (
    <div>
      <div style={{ marginBottom: 10, fontSize: 18 }}><b>Manage Units</b></div>
      <div style={{ display: "flex", justifyContent: "flex-end" }} ><Button onClick={() => { setShowAddUnitModal(true); setIsEdit(false) }} variant="contained" color="primary" >Add Scope</Button></div>
      <div style={{ marginBottom: 10 }}></div>

      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Sl No</StyledTableCell>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Description</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {units?.map((data, i) => <StyledTableRow key={i + 1}>
              <StyledTableCell>{i + 1}</StyledTableCell>
              <StyledTableCell>{data?.name}</StyledTableCell>
              <StyledTableCell>{data?.description}</StyledTableCell>
              <StyledTableCell style={{ cursor: "pointer" }} >
                <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                  <Tooltip title="Edit"><EditOutlined style={{ cursor: "pointer" }} onClick={() => editUnit(data)} /></Tooltip>
                  <Tooltip title="Delete" onClick={() => { setShowDeleteModal(true); setDeleteId(data?._id) }}><DeleteOutlined /></Tooltip>
                </div>
              </StyledTableCell>
            </StyledTableRow>)}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={showAddUnitModal}
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth={'sm'}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{`${isEdit ? 'Edit' : 'Add New'} Unit`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <TextField label="Name" name="name" value={name} style={{ marginBottom: 10 }} fullWidth onChange={(e) => setUnitDetails({ ...unitDetails, [e.target.name]: e.target.value })} variant="outlined" />
            <TextField label="Description" name="description" value={description} style={{ marginBottom: 10 }} fullWidth onChange={(e) => setUnitDetails({ ...unitDetails, [e.target.name]: e.target.value })} variant="outlined" />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {!isEdit?<Button color="primary" onClick={addUnit} >
            Add
          </Button>:
          <Button color="primary" onClick={updateUnit} >
            Update
          </Button>}
        </DialogActions>

      </Dialog>

      <Dialog
        open={showDeleteModal}
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth={'sm'}
        onClose={closeDeleteModal}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Delete Unit</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={closeDeleteModal}>
            Cancel
          </Button>
          <Button color="primary" onClick={() => deleteUnit()}>
            Delete
          </Button>
        </DialogActions>

      </Dialog>

    </div>
  )
}

export default connect(null, { setLoading, setSnackInfo, getAllUnits })(ManageUnits)
