import React from 'react'
import OutwardingView from '../../../components/outwardingView'

const StoreOutwarding = () => {
  return (
    <div>
      <OutwardingView TRANSFERFROM={'store'} />
    </div>
  )
}

export default StoreOutwarding
