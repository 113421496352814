import { GET_ALL_PROJECTS, GET_ALL_SCOPES, GET_ALL_UNITS, GET_ALL_PAYMENT_MODES, GET_ALL_SUB_CATEGORY, GET_ALL_PAYMENT_PARTNERS } from '../actions/types'

const initialState = {
  projects: [],
  scopes: [],
  units: [],
  paymentModes: [],
  subCategory: [],
  paymentPartners: []
}

export default function projectReducer(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case GET_ALL_PROJECTS:
      return {
        ...state,
        projects: payload,
      }
    case GET_ALL_SCOPES:
      return {
        ...state,
        scopes: payload
      }
    case GET_ALL_UNITS:
      return {
        ...state,
        units: payload
      }
      case GET_ALL_PAYMENT_MODES:
      return {
        ...state,
        paymentModes: payload
      }
      case GET_ALL_PAYMENT_PARTNERS:
      return {
        ...state,
        paymentPartners: payload
      }
    case GET_ALL_SUB_CATEGORY:
      return {
        ...state,
        subCategory: payload
      }
    default:
      return state
  }
}

