import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux'
import { Link } from "react-router-dom";
import { setLoading, setSnackInfo } from '../../redux/actions/appAction'
import HttpService from '../../services/httpService'
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 700,
    },
    root: {
        width: '100%',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
}));


const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#ccc",
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const ContractSignLeads = (props) => {
    const classes = useStyles();
    const { userDetails } = useSelector(state => state.user)
    const [qtprojects, setQTProjectRows] = useState([]);
    const [dupqtprojects, setDupQtProjects] = useState([])
    const [totalaPages, setTotalPages] = useState(0)
    const [searched, setSearched] = useState("")
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        getAllContractSignedLeads()
        // eslint-disable-next-line
    }, [rowsPerPage, page])

    const getAllContractSignedLeads = async () => {
        try {
            const response = await HttpService.getAllContractSignedLeads(userDetails.selectedExpCenter);
            console.log(response.data);
            const qtAllProjects = []
            response.data.forEach((ele) => {
                let obj = {
                    leadId: ele?._id,
                    name: ele?.customerId?.name,
                    customerId: ele?.customerId?._id,
                    status: ele?.status,
                    stage: ele?.designStages === 'Design Sign-off' ? ele.executionStage : ele?.designStages,
                    assignTo: ele?.assignTo?.name,
                    department: ele?.departmentId?.name,
                    totalCustomerOutflow: ele?.grandTotal ? ele?.grandTotal : `0`,
                    customerDesignSignOffDate: ele?.customerDesignSignOffDate ? moment(ele?.customerDesignSignOffDate).format("DD-MM-YYYY") : moment(ele?.createdAt).format("DD-MM-YYYY")
                }
                qtAllProjects.push(obj)
            })
            setQTProjectRows(qtAllProjects);
            setDupQtProjects(qtAllProjects);
            setTotalPages(response.data.length)
        } catch (error) {
            console.log(error, "error")
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const setHandleSearched = (value) => {
        if (value.length === 0) {
            getAllContractSignedLeads()
        }
        setSearched(value)
        requestSearch(value)
    }

    const requestSearch = async (search) => {
        const filteredRow = dupqtprojects.filter(row =>
            row.name.toLowerCase().includes(search.toLowerCase()) ||
            row.status.toLowerCase().includes(search.toLowerCase()) ||
            row.stage.toLowerCase().includes(search.toLowerCase())
            // row.assignTo.toLowerCase().includes(search.toLowerCase()) || 
            // row.projectNo.toString().toLowerCase().includes(search)
        )
        setQTProjectRows(filteredRow);
    }

    return (
        <div>
            <Paper style={{ marginTop: '20px', marginBottom: "20px", padding: "20px" }}>
                <div style={{ display: "flex" }}>
                    <Grid item xs>
                        <TextField label="Search here.." name="searched" size="small" value={searched} onChange={(e) => setHandleSearched(e.target.value)} variant="outlined" />
                    </Grid>
                </div>
            </Paper>

            <Paper>
                <TableContainer component={Paper}>
                    <Table className={classes.table} stickyHeader aria-label="sticky table">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell>Sr. No.</StyledTableCell>
                                <StyledTableCell>Customer</StyledTableCell>
                                <StyledTableCell>Status</StyledTableCell>
                                <StyledTableCell>Stage</StyledTableCell>
                                <StyledTableCell>Assigned to</StyledTableCell>
                                <StyledTableCell>Department</StyledTableCell>
                                <StyledTableCell>Quotation Amount</StyledTableCell>
                                <StyledTableCell>Design Sign-off Date</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {qtprojects.sort((a, b) => b.customerDesignSignOffDate.split('-').reverse().join().localeCompare(a.customerDesignSignOffDate.split('-').reverse().join())).map((row, i) => {
                                return (
                                    <StyledTableRow key={i + 1}>
                                        <StyledTableCell>{i + 1}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">
                                            <Link to={`/project-logs/${row.leadId}`} style={{ color: "#000" }}><u>{row?.name}</u></Link>
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row">{row?.status}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">{row?.stage}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">{row?.assignTo}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">{row?.department}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">{row?.totalCustomerOutflow}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">{row?.customerDesignSignOffDate}</StyledTableCell>
                                    </StyledTableRow>
                                );
                            })}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 20, 50, { label: 'All', value: totalaPages }]}
                                    colSpan={10}
                                    count={totalaPages}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: { 'aria-label': 'rows per page' },
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    )
}
export default connect(null, { setLoading, setSnackInfo })(ContractSignLeads)
