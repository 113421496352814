import React, { useState, useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
	createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import HttpService from "../services/httpService";
import { setLoading, setSnackInfo } from "../redux/actions/appAction";
import { getAllScopes, getAllUnits } from "../redux/actions/projectAction";
import { connect, useSelector } from "react-redux";
import { getAllMaterials } from "../redux/actions/vendorAction";
import { ROLENAME } from "../utils/index";

const filter = createFilterOptions();

const useStyles = makeStyles({
	table: {
		minWidth: 700,
	},
	root: {
		width: "100%",
	},
	textField: {
		width: "100px",
	},
});

const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: "#ccc",
		color: theme.palette.common.black,
	},
	body: {
		fontSize: 14,
	},
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
	root: {
		"&:nth-of-type(odd)": {
			backgroundColor: theme.palette.action.hover,
		},
	},
}))(TableRow);

const PoSoMtrNewMaterialAddition = (props) => {
	const classes = useStyles();
	const { allMaterials } = useSelector((state) => state.vendor);
	const [tableRows, setTableRows] = useState([{ value: null }]);
	const [rooms, setRooms] = useState([]);
	const [selectedRoom, setSelectedRoom] = useState([]);
	const [selectedScope, setSelectedScope] = useState([]);
	const [subCategory, setSubCategory] = useState([]);
	const [subCategoryList, setSubCategoryList] = useState([]);
	const [selectedSubCategory, setSelectedSubCategory] = useState([]);
	const [selectedMaterial, setSelectedMaterial] = useState([]);
	const [selectedUom, setSelectedUom] = useState([]);
	const [quantity, setQuantity] = useState([]);
	const [rowsToAdd, setRowsToAdd] = useState(0);
	const [fieldsDisabled, setFieldsDisabled] = useState(false);
	useEffect(() => {
		getAllScopes();
		getAllRooms();
		getAllSubCategory();
		getAllMaterials();
		getAllUnits();
		// eslint-disable-next-line
	}, [props.MOUNTED]);

	const getAllScopes = async () => {
		try {
			await props.getAllScopes();
		} catch (error) {
			console.error(error);
			props.setLoading(false);
			props.setSnackInfo("Error fetching scopes", "error");
		}
	};

	const getAllRooms = async () => {
		try {
			const result = await HttpService.getAllRooms();
			setRooms(result.data);
		} catch (error) {
			console.error(error);
			props.setLoading(false);
			props.setSnackInfo("Error fetching rooms", "error");
		}
	};

	const getAllSubCategory = async () => {
		try {
			const response = await HttpService.getAllSubCategory();
			setSubCategoryList(response.data);
		} catch (error) {
			console.error(error);
			props.setLoading(false);
			props.setSnackInfo(
				error?.response?.data?.messsage ?? "Error fetching sub categories",
				"error"
			);
		}
	};

	const getAllMaterials = async () => {
		try {
			await props.getAllMaterials();
		} catch (error) {
			console.error(error);
			props.setSnackInfo(
				error?.response?.data?.messsage ?? "Error fetching materials",
				"error"
			);
		}
	};

	const getAllUnits = async () => {
		try {
			await props.getAllUnits();
		} catch (error) {
			console.error(error);
			props.setLoading(false);
			props.setSnackInfo("Error fetching units", "error");
		}
	};

	const addRows = (e, value) => {
		let length = value ?? rowsToAdd;
		const rows = [];
		for (let i = 0; i < length; i++) {
			rows.push({ value: null });
		}
		setTableRows(rows);
		setRowsToAdd(0);
	};

	const { scopes, units } = useSelector((state) => state.project);
	let scopesOption = scopes.filter((scope) => scope.name == props.SCOPE);

	const handleScopeSelect = (e, value, i) => {
		if (scopesOption[0]?._id) {
			getSelectedSubCategory(scopesOption[0]?._id);
		}
		let scope = scopes.filter((el) => el.name == props.SCOPE);
		setSelectedScope(scope);
	};

	const getSelectedSubCategory = async (scopeId) => {
		try {
			props.setLoading(true);
			const result = [];
			for (let i = 0; i < subCategoryList.length; i++) {
				let scopedList = subCategoryList[i].scopeId.find(
					(scope) => scope._id.toString() === scopeId.toString()
				);
				if (scopedList) result.push(subCategoryList[i]);
			}
			setSubCategory(result);
			props.setLoading(false);
		} catch (error) {
			console.error(error);
			props.setLoading(false);
			props.setSnackInfo("Error fetching sub category", "error");
		}
	};

	const [selectedSubCategoryMaterial, setSelectedSubCategoryMaterial] =
		useState([]);

	const handleSubCategorySelect = (e, value, i) => {
		if (value?._id) {
			getSelectedSubCategoryMaterial(value?._id);
		}
		let subCategory = [...selectedSubCategory];
		subCategory[i] = value;
		setSelectedSubCategory(subCategory);
	};

	const getSelectedSubCategoryMaterial = async (subCatId) => {
		try {
			props.setLoading(true);
			const result = allMaterials.filter(
				(material) =>
					material?.subCategoryId?._id?.toString() === subCatId.toString()
			);
			setSelectedSubCategoryMaterial(result);
			props.setLoading(false);
		} catch (error) {
			console.error(error);
			props.setLoading(false);
			props.setSnackInfo(
				error?.response?.data?.messsage ?? "Error fetching materials",
				"error"
			);
		}
	};

	const handleRoomSelect = (e, v, i) => {
		let rooms = [...selectedRoom];
		rooms[i] = v;
		setSelectedRoom(rooms);
	};

	const handleMaterialSelect = (e, v, i) => {
		let materials = [...selectedMaterial];
		materials[i] = v;
		setSelectedMaterial(materials);
		if (v) {
			let uom = [...selectedUom];
			uom[i] = v?.unitId;
			setSelectedUom(uom);
		} else {
			let uom = [...selectedUom];
			uom[i] = v;
			setSelectedUom(uom);
		}
	};

	const handleUomSelect = (e, v, i) => {
		let uom = [...selectedUom];
		uom[i] = v;
		setSelectedUom(uom);
	};

	const handleQuantity = (e, i) => {
		let quant = [...quantity];
		quant[i] = e.target.value;
		setQuantity(quant);
	};

	const submit = async (status) => {
		props.setLoading(true);
		const projectMaterial = selectedRoom.map((item, i) => {
			let obj = {
				spaceNameId: item?._id,
				scopeId: scopesOption[0],
				subCategoryId: selectedSubCategory[i]?._id,
				materialId: selectedMaterial[i],
				unitType: selectedUom[i],
				quantity: quantity[i],
				raisedQty: 0,
			};
			return obj;
		});

		const material = {};
		material.materials = projectMaterial;
		material.status = status;
		material.type = props.TYPE;
		const response = await HttpService.getVendorDetailsMaterialById(material);
		props.addMoreMaterialHandler(response.data);

		props.setShowNewMaterialModal(false);
		props.setLoading(false);
	};

	const getSelectedRoom = (i) => {
		const item = selectedRoom[i];
		return item || {};
	};
	const getSelectedCategory = (i) => {
		const item = selectedSubCategory[i];
		return item || {};
	};
	const getSelectedMaterial = (i) => {
		const item = selectedMaterial[i];
		return item || {};
	};
	const getSelectedUom = (i) => {
		const item = selectedUom[i];
		return item || {};
	};
	return (
		<div>
			<TableContainer component={Paper}>
				<Table
					className={classes.table}
					size="small"
					aria-label="customized table"
				>
					<TableHead>
						<TableRow>
							<StyledTableCell>Sl No</StyledTableCell>
							<StyledTableCell>Select Room</StyledTableCell>
							<StyledTableCell>Select Scope</StyledTableCell>
							<StyledTableCell>Select Sub Category</StyledTableCell>
							<StyledTableCell>Select Material</StyledTableCell>
							<StyledTableCell>UOM</StyledTableCell>
							<StyledTableCell>Qty</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{tableRows?.map((row, i) => (
							<StyledTableRow
								key={i + 1}
								style={{
									border:
										selectedMaterial[i]?.status === "Inactive"
											? "2px solid #000"
											: "",
								}}
							>
								<StyledTableCell>{i + 1}</StyledTableCell>
								<StyledTableCell>
									<Autocomplete
										value={getSelectedRoom(i)}
										disabled={fieldsDisabled}
										onChange={(e, v) => handleRoomSelect(e, v, i)}
										options={rooms}
										getOptionLabel={(option) => option.name}
										style={{ width: 150 }}
										renderInput={(params) => (
											<TextField
												{...params}
												label="Select Room"
												fullWidth
												size="small"
												variant="outlined"
											/>
										)}
									/>
								</StyledTableCell>
								<StyledTableCell>
									<Autocomplete
										value={scopesOption[0]}
										disabled={fieldsDisabled}
										// defaultValue={(scopesOption[0])}
										onChange={(e, v) => handleScopeSelect(e, v, i)}
										options={scopesOption}
										getOptionLabel={(option) => option.name}
										style={{ width: 150, fontSize: "12px" }}
										renderInput={(params) => (
											<TextField
												{...params}
												label="Select Scope"
												fullWidth
												size="small"
												variant="outlined"
											/>
										)}
									/>
								</StyledTableCell>
								<StyledTableCell>
									<Autocomplete
										value={getSelectedCategory(i)}
										disabled={fieldsDisabled}
										onChange={(e, v) => handleSubCategorySelect(e, v, i)}
										getOptionLabel={(option) => option.name}
										options={subCategoryList}
										selectOnFocus
										clearOnBlur
										handleHomeEndKeys
										style={{ width: 200 }}
										renderInput={(params) => (
											<TextField
												{...params}
												label="Select sub category"
												fullWidth
												size="small"
												variant="outlined"
											/>
										)}
									/>
								</StyledTableCell>
								<StyledTableCell>
									<Autocomplete
										value={getSelectedMaterial(i)}
										disabled={fieldsDisabled}
										onChange={(e, v) => {
											if (typeof v === "string") {
												setTimeout(() => {
													let materials = [...selectedMaterial];
													materials[i] = { name: v };
													setSelectedMaterial(materials);
												});
											} else if (v && v.inputValue) {
												let materials = [...selectedMaterial];
												materials[i] = { name: v.inputValue };
												setSelectedMaterial(materials);
											} else {
												handleMaterialSelect(e, v, i);
											}
										}}
										options={selectedSubCategoryMaterial}
										filterOptions={(options, params) => {
											const filtered = filter(options, params);
											if (params.inputValue !== "") {
												filtered.push({
													inputValue: params.inputValue,
													name: `Add "${params.inputValue}"`,
												});
											}
											return filtered;
										}}
										selectOnFocus
										clearOnBlur
										handleHomeEndKeys
										getOptionLabel={(option) => option.name}
										style={{ width: 250 }}
										renderInput={(params) => (
											<TextField
												{...params}
												label="Select Material"
												fullWidth
												size="small"
												variant="outlined"
											/>
										)}
									/>
								</StyledTableCell>
								<StyledTableCell>
									<Autocomplete
										value={getSelectedUom(i)}
										disabled={fieldsDisabled}
										filterOptions={(options, params) => {
											const filtered = filter(options, params);
											if (params.inputValue !== "") {
												filtered.push({
													inputValue: params.inputValue,
													name: `Add "${params.inputValue}"`,
												});
											}
											return filtered;
										}}
										onChange={(e, v) => {
											if (typeof v === "string") {
												setTimeout(() => {
													handleUomSelect({ name: v });
												});
											} else if (v && v.inputValue) {
												handleUomSelect(null, { name: v.inputValue }, i);
											} else {
												handleUomSelect(e, v, i);
											}
										}}
										selectOnFocus
										clearOnBlur
										handleHomeEndKeys
										options={units}
										getOptionLabel={(option) => option.name}
										style={{ width: 150 }}
										renderInput={(params) => (
											<TextField
												{...params}
												label="Select UOM"
												fullWidth
												size="small"
												variant="outlined"
											/>
										)}
									/>
								</StyledTableCell>
								<StyledTableCell>
									<TextField
										className={classes.textField}
										value={quantity[i]}
										disabled={fieldsDisabled}
										onChange={(e) => handleQuantity(e, i)}
										type="number"
										size="small"
										variant="outlined"
									/>
								</StyledTableCell>
							</StyledTableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<div
				style={{ display: "flex", flexDirection: "row-reverse", marginTop: 15 }}
			>
				<Button
					variant="contained"
					color="primary"
					style={{ background: "#716ACA", margin: 5 }}
					onClick={addRows}
				>
					Show
				</Button>
				<TextField
					label="Rows to Show"
					value={rowsToAdd && Math.max(0, rowsToAdd)}
					type="number"
					min="0"
					onChange={(e) =>
						setRowsToAdd(
							e.target.value ? Number(e.target.value) : e.target.value
						)
					}
					variant="outlined"
				/>
			</div>
			<div
				style={{ display: "flex", flexDirection: "row-reverse", marginTop: 15 }}
			>
				<Button
					variant="contained"
					color="primary"
					style={{ background: "#716ACA", margin: 5 }}
					onClick={() => submit("Submitted")}
				>
					Submit
				</Button>
			</div>
		</div>
	);
};

export default connect(null, {
	setLoading,
	setSnackInfo,
	getAllScopes,
	getAllMaterials,
	getAllUnits,
})(PoSoMtrNewMaterialAddition);
