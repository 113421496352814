import React, { useState, useEffect, useRef } from 'react'
import { setLoading, setSnackInfo } from '../../../redux/actions/appAction'
import { connect } from 'react-redux'
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { Typography, Paper, Button, TextField } from '@material-ui/core';
import HttpService from '../../../services/httpService'
import Table from '@material-ui/core/Table';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import '../style.css';
import { red } from '@material-ui/core/colors';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ReUsableExpDetails from '../../../components/reusableExpCenterDetails';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,

    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    wonui: {
        background: '#673ab7',
        marginTop: "20px",
        height: "100%"
    },
    textField: {
        width: "40%"
    },
    textFieldWidth: {
        width: "100%"
    },
    table: {
        minWidth: 700,
    },
    pos: {
        marginBottom: 12,
    },
    container: {
        maxHeight: 450,
    },



}))

const WonForm = (props) => {
    const inputRefEmail = useRef();
    const inputRefcustomerGstInName = useRef();
    const inputRefcustomerGstShipping = useRef();
    const inputRefcustomerRequriedGst = useRef();
    const inputRefisVisitedExperienceCenter= useRef();
    const inputRefReasonToChoseDecorpot = useRef();
    const inputRefVisitedExpCenter = useRef();
    const inputRefSatisfactionIndex = useRef();
    const inputRefAdditonalComments = useRef();
    const classes = useStyles();
    const history = useHistory();
    const [showButton, setShowButton] = useState(false)
    const [error,setError] = useState(false);
    const [gstValidate, setValidate] = useState(false)
    const [satisFactionList, setSatisfactionList] = useState(false)


    useEffect(() => {
        if (props.LEADOWNERDATA.leadId) {
            findExistingLead(props.LEADOWNERDATA.leadId)
        }
        // eslint-disable-next-line
    }, [props.LEADOWNERDATA, props.TYPEOPTION])

    const handleCenterChange = (value) => {
        setWonFormData({ ...wonFormData, visitedExperienceCenter: value });
        setError(false);
    };

    const findExistingLead = async (leadId) => {
        props.setLoading(true)
        try {
            const isExistsLead = await HttpService.findExistingCustomerSurveyLead(leadId, 'won')
            if (isExistsLead.data.length !== 0) {
                // history.push('/thank-you');
            }
        } catch (error) {
            // props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
            console.log('error', error)
            console.error(error)
        }
        props.setLoading(false)
    }
    const [satisfactionIndexRatioList, setSatisfactionIndexRatioList] = useState([
        { id: 1, description: 'Understanding of requirements', 1: '', 2: '', 3: '', 4: '', 5: '', 6: '', 7: '', 8: '', 9: '', 10: '' },
        { id: 2, description: 'Timely delivery of quotations', 1: '', 2: '', 3: '', 4: '', 5: '', 6: '', 7: '', 8: '', 9: '', 10: '' },
        { id: 3, description: 'Experience center walk through', 1: '', 2: '', 3: '', 4: '', 5: '', 6: '', 7: '', 8: '', 9: '', 10: '' },
        { id: 4, description: 'Product range offered', 1: '', 2: '', 3: '', 4: '', 5: '', 6: '', 7: '', 8: '', 9: '', 10: '' },
        { id: 5, description: 'Material knowledge of sales person', 1: '', 2: '', 3: '', 4: '', 5: '', 6: '', 7: '', 8: '', 9: '', 10: '' },
        { id: 6, description: 'Budget expectations met', 1: '', 2: '', 3: '', 4: '', 5: '', 6: '', 7: '', 8: '', 9: '', 10: '' },
        { id: 7, description: 'Professionalism', 1: '', 2: '', 3: '', 4: '', 5: '', 6: '', 7: '', 8: '', 9: '', 10: '' },
        { id: 8, description: 'Approachability', 1: '', 2: '', 3: '', 4: '', 5: '', 6: '', 7: '', 8: '', 9: '', 10: '' },
        { id: 9, description: 'Communication (Calls and follow ups)', 1: '', 2: '', 3: '', 4: '', 5: '', 6: '', 7: '', 8: '', 9: '', 10: '' }
    ]);

    const [wonFormData, setWonFormData] = useState({
        isVisitedExperienceCenter: "",
        visitedExperienceCenter: "",
        satisfactionIndexRatio: [],
        reasonToChooseDecorpot: "",
        otherReasonToChooseDecorpot: "",
        customerRequriedGst: false,
        comments: "",
        customerGstInName: '',
        customerGstShipping: "",
        customerGstInNumber: "",
        email: ""
    })
    const [disabledBtn, setDisabledBtn] = useState(true);
    const satisfactionIndexRadio = (event, index, data) => {
        const newSatisfactionIndexRatioList = [...satisfactionIndexRatioList];

        newSatisfactionIndexRatioList[index].actionSelected = event.target.value;
        newSatisfactionIndexRatioList[index].sortId = data.id ? data.id : data.sortId;
        newSatisfactionIndexRatioList[index].description = data.description;
        delete newSatisfactionIndexRatioList[index]['1'];
        delete newSatisfactionIndexRatioList[index]['2'];
        delete newSatisfactionIndexRatioList[index]['3'];
        delete newSatisfactionIndexRatioList[index]['4'];
        delete newSatisfactionIndexRatioList[index]['5'];
        delete newSatisfactionIndexRatioList[index]['6'];
        delete newSatisfactionIndexRatioList[index]['7'];
        delete newSatisfactionIndexRatioList[index]['8'];
        delete newSatisfactionIndexRatioList[index]['9'];
        delete newSatisfactionIndexRatioList[index]['10'];

        setWonFormData({ ...wonFormData, satisfactionIndexRatio: newSatisfactionIndexRatioList })
        let countCheck = 0;
        newSatisfactionIndexRatioList.forEach((ele, i) => {
            if (ele.hasOwnProperty('actionSelected')) {
                countCheck++
            }
        })
        if (newSatisfactionIndexRatioList.length === countCheck) {
            setDisabledBtn(false);
        } else {
            setDisabledBtn(true)
        }
    }

    const submitWonForm = async () => {
        let obj = {
            ...wonFormData,
            leadOwner: props.LEADOWNERDATA,
            typeOption: props.TYPEOPTION
        }
        props.setLoading(true)
        try {
            await HttpService.submitSurveyForm(obj)
            history.push('/thank-you');
        } catch (error) {
            props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }
    const [disButton, setDisButton] = useState(true)

    const [gstFormat, setGstFormat] = useState(null)

    const gstRequired = (e) => {
        const { name, value } = e.target;
        setWonFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));

        if (name === "customerGstInNumber") {
            isValidGSTNo(value);
        }
        // have to check the button disable
    };


    function isValidGSTNo(str) {
        let regex = new RegExp(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/);

        if (str == null) {
            return "false";
        }
        if (regex.test(str) == true) {
            setGstFormat(false)
            setValidate(true)
            // return "true";
        }
        else {
            setGstFormat(true)
            setValidate(false)
            // return "false";
        }
    }
    const sendDataToParent= () =>{
        let satisfactionIndexRatioListx = satisfactionIndexRatioList.every(el=>el.actionSelected);
        if(satisfactionIndexRatioListx){
            setSatisfactionList(true)
            setError(false);
        }
        
        let isError = false
        if(wonFormData.email ===""){
         inputRefEmail?.current?.scrollIntoView({ behavior: "smooth" });  
         setError(true);
         isError = true
        }
         if(wonFormData.customerGstInName === ""){
        inputRefcustomerGstInName?.current?.scrollIntoView({ behavior: "smooth" });  
        isError = true;
         setError(true)

        }
         if (wonFormData.customerGstShipping === ""){
            inputRefcustomerGstShipping?.current?.scrollIntoView({behavior: "smooth"});
            setError(true);
            isError = true;

        }
         if (wonFormData.isVisitedExperienceCenter === ""){
            inputRefisVisitedExperienceCenter?.current?.scrollIntoView({behavior: "smooth"});
            setError(true)
            isError = true;

        }
         if (wonFormData.reasonToChooseDecorpot === ""){
            inputRefReasonToChoseDecorpot?.current?.scrollIntoView({behavior: "smooth"});
            setError(true)
            isError = true;
        }
         if (wonFormData.customerRequriedGst === true && wonFormData.customerGstInNumber ===""  ||  gstFormat){
            inputRefcustomerRequriedGst?.current?.scrollIntoView({behavior: "smooth"});
            setError(true)
            isError = true;
        }
        if(wonFormData.comments === ""){
            inputRefAdditonalComments?.current?.scrollIntoView({behavior: "smooth"});
            setError(true)
            isError = true;
        }
         if(wonFormData.isVisitedExperienceCenter === 'Yes' && wonFormData.visitedExperienceCenter === ""){
            inputRefVisitedExpCenter?.current?.scrollIntoView({behavior: "smooth"});
            setError(true)
            isError = true;
            
        }
         if(!satisFactionList){
            inputRefSatisfactionIndex?.current?.scrollIntoView({behavior: "smooth"});
            setError(true)
            isError = true;
        }
        if (!isError) {
            props.sendDataToParent(wonFormData);
          }
    }
    return (
        <>
            <Paper className={classes.wonui}>
                <Typography variant="h6" gutterBottom style={{ marginLeft: 10, paddingTop: 20, fontSize: 27, fontWeight: 700, color: "white" }}>
                    Customer Satisfaction Survey - Sales Process
                </Typography>
                <Typography variant='subtitle2' gutterBottom style={{ marginLeft: 10, fontWeight: 550, color: "white" }}>
                    CRM -{'>'} <span className="step1">Design expert</span> -{'>'} Designer -{'>'} Operations & Production -{'>'} Site Execution -{'>'} Beautiful home delivered.
                </Typography>
                <Typography variant='subtitle2' gutterBottom style={{ marginLeft: 10, marginTop: 15, fontWeight: 550, color: "white" }}>
                    Thank you for choosing Decorpot. It is our great pleasure to provide you the best quality of service at all stages.
                </Typography>
                <Typography variant='subtitle2' gutterBottom style={{ marginLeft: 10, marginTop: 15, fontWeight: 550, color: "white" }}>
                    Your assistance in completing this form is greatly appreciated. Your valuable feedback will help us to serve you better and enable us to work on improving our service standards.
                </Typography>
                <Typography variant='subtitle2' gutterBottom style={{ marginLeft: 10, marginTop: 15, fontWeight: 550, color: "white" }}>
                    Thank you.
                </Typography>
            </Paper>

            <Paper style={{ marginTop: 20, padding: 10, borderTop: "8px solid #673ab7", borderBottom: "8px solid rgb(93, 65, 173" }}>
                <FormControl style={{ width: "100%", marginBottom: 20, marginTop: 20 }}>
                    <TextField id="outlined-basic" label="Enter Email Id"  ref={inputRefEmail} value={wonFormData.email} variant="outlined" name="email" onChange={(e) => setWonFormData({ ...wonFormData, [e.target.name]: e.target.value })} size="large" />
                    {error && wonFormData.email ==="" && (
                    <p style={{color:"red"}}>Please Fill the Email Id</p>
                    )}
                </FormControl>
                <div style={{ padding: 10 }}>
                    <FormControl className={classes.textField}>
                        <InputLabel id="demo-controlled-open-select-label" > Is GST invoice required? </InputLabel>
                        <Select
                            labelId="demo-controlled-open-select-label"
                            name="customerRequriedGst"
                            id="demo-controlled-open-select"
                            value={wonFormData.customerRequriedGst}
                            onChange={gstRequired}
                        >
                            <MenuItem value={true}>Yes</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                        </Select>
                    </FormControl>
                    <br />
                    <TextField
                        label="Enter the name on which invoice will be generated, if GST invoice is required, put name as per GST records *"
                        size="small"
                        style={{ marginBottom: 20, textAlign: "center", marginTop: 20, width: "100%", backgroundColor: "#f8f1fb" }}
                        name="customerGstInName"
                        ref={inputRefcustomerGstInName}
                        value={wonFormData.customerGstInName}
                        onChange={gstRequired}
                        variant="outlined"
                    />
                     {error && wonFormData.customerGstInName ==="" && (
                    <p style={{color:"red"}}>Please Enter your Full Name</p>
                    )}

                    {wonFormData.customerRequriedGst === true && (
                        <>
                            <TextField
                                label="GSTIN Number*"
                                size="small"
                                ref={inputRefcustomerRequriedGst}
                                style={{ marginBottom: 20, textAlign: "center", marginTop: 20, width: "100%", backgroundColor: "#f8f1fb" }}
                                name="customerGstInNumber"
                                value={wonFormData.customerGstInNumber.toUpperCase()}
                                onChange={gstRequired}
                                variant="outlined"
                            />
                            { gstFormat  &&                                     
                                  <p style={{color:"red"}}>Enter a valid GST number</p>  
                            }
                        </>
                    )}

                    <TextField
                        label="Shipping address *"
                        size="small"
                        style={{ marginBottom: 20, textAlign: "center", marginTop: 20, width: "100%", backgroundColor: "#f8f1fb" }}
                        name="customerGstShipping"
                        ref={inputRefcustomerGstShipping}
                        value={wonFormData.customerGstShipping}
                        onChange={gstRequired}
                        variant="outlined"
                    />
                      {error && wonFormData.customerGstShipping ==="" && (
                    <p style={{color:"red"}}>Please Enter the shipping address</p>
                    )}
                    <br />
                    <FormControl component="fieldset" style={{ marginBottom: 20, marginTop: 20 }}>
                        <FormLabel component="legend" style={{ color: "#000" }}>Did you Visit our experience center? * </FormLabel>
                        <RadioGroup aria-label="visitedExpCenter" ref={inputRefisVisitedExperienceCenter} name="isVisitedExperienceCenter"
                            onChange={(e) => setWonFormData({ ...wonFormData, [e.target.name]: e.target.value })}>
                            <FormControlLabel value="Yes" control={<Radio color="primary" />} label="Yes" />
                            <FormControlLabel value="No" control={<Radio color="primary" />} label="No" />
                        </RadioGroup>
                        {error && wonFormData.isVisitedExperienceCenter ==="" && (
                            <p style={{color:"red"}}>Please Select Yes or No Visited Center</p>
                            )}
                    </FormControl>
                    <br />
                    {wonFormData.isVisitedExperienceCenter === 'Yes' && <>
                    <FormControl component="fieldset" style={{ marginBottom: 20, marginTop: 20 }}>
                        <FormLabel component="legend" style={{ color: "#000" }}>
                            Which Decorpot Experience Center did you visit? *
                        </FormLabel>
                        <ReUsableExpDetails
                            selectedCenter={wonFormData.visitedExperienceCenter}
                            onChange={handleCenterChange}
                        />
                        {error && wonFormData.visitedExperienceCenter === "" && (
                            <p style={{ color: "red" }}>Please Select Your Visited Exp Center</p>
                        )}
                        </FormControl>
                        <br /></>
                    }
                    <FormControl component="fieldset" style={{ marginBottom: 50, marginTop: 50 }}>
                        <FormLabel component="legend" style={{ color: "#000" }}>Satisfaction index -</FormLabel>
                        <FormLabel component="legend" style={{ color: "#000", marginTop: "30px" }}> <span className='satisfiedDetail'> </span> <span style={{ marginLeft: "10px", marginTop: "70px" }}>Dissatisfied </span>
                            <span className='DissatisfiedDetail'></span>
                            <span style={{ marginLeft: "10px", marginTop: "70px" }}> Satisfied</span> </FormLabel>
                    </FormControl>
                    <div className='wonDesktop'>
                        <Table striped bordered hover style={{ width: "100%", marginLeft: "20px", borderCollapse: "collapse" }}>
                            <tbody>
                                <tr>
                                    <RadioGroup defaultValue="abc" aria-label="action" name="customized-radios" style={{ display: 'table-cell' }}>
                                        <td colSpan={4} style={{ border: '1px solid lightgray', fontWeight: 500, width: "8rem", height: "4" }}></td>
                                        <td style={{ border: '1px solid lightgray', textAlign: 'center', fontWeight: 700, width: "4rem", height: "2rem", backgroundColor: "#e72600" }}><span style={{ color: "#fff", marginTop: "20px" }}> 1 </span></td>
                                        <td style={{ border: '1px solid lightgray', textAlign: 'center', fontWeight: 700, width: "4rem", height: "2rem", backgroundColor: "#f96500" }}><span style={{ color: "#fff" }}> 2 </span></td>
                                        <td style={{ border: '1px solid lightgray', textAlign: 'center', fontWeight: 700, width: "4rem", height: "2rem", backgroundColor: "#ffe000" }}><span style={{ color: "#fff" }}> 3 </span></td>
                                        <td style={{ border: '1px solid lightgray', textAlign: 'center', fontWeight: 700, width: "4rem", height: "2rem", backgroundColor: "#30ad00" }}><span style={{ color: "#fff" }}> 4 </span></td>
                                        <td style={{ border: '1px solid lightgray', textAlign: 'center', fontWeight: 700, width: "4rem", height: "2rem", backgroundColor: "#218b00" }}><span style={{ color: "#fff" }}> 5 </span></td>
                                    </RadioGroup>
                                </tr>

                                {satisfactionIndexRatioList.sort().map((row, i) => {
                                    const labelId = `enhanced-table-checkbox-${i}`;
                                    return (
                                        <tr ref={inputRefSatisfactionIndex}                                        >
                                            <RadioGroup aria-label='option' name="customized-radios"
                                                value={row?.actionSelected}
                                                onChange={(e) => satisfactionIndexRadio(e, i, row)}
                                                style={{ display: 'block' }}>
                                                <td colSpan={10} style={{ border: '1px solid lightgray', fontWeight: 500, width: "8rem", height: "4" }}> {row?.description} </td>
                                                <td style={{ border: '1px solid lightgray', textAlign: 'center', fontWeight: 700, width: "4rem", height: "4" }}>
                                                    <Radio
                                                        value='1'
                                                        checked={row?.actionSelected === '1'}
                                                        style={{
                                                            backgroundColor: row?.actionSelected === '1' ? '#fde6f0' : '',
                                                            borderRadius: row?.actionSelected === '1' ? '0px' : ''
                                                        }}
                                                        name="radio-button-demo"
                                                        inputProps={{ 'aria-label': labelId }}
                                                    />
                                                </td>
                                                <td style={{ border: '1px solid lightgray', textAlign: 'center', fontWeight: 700, width: "4rem", height: "4" }}>
                                                    <Radio
                                                        value='2'
                                                        checked={row?.actionSelected === '2'}
                                                        name="radio-button-demo"
                                                        inputProps={{ 'aria-label': labelId }}
                                                        style={{
                                                            backgroundColor: row?.actionSelected === '2' ? '#fde6f0' : '',
                                                            borderRadius: row?.actionSelected === '2' ? '0px' : ''
                                                        }}
                                                    />
                                                </td>
                                                <td style={{ border: '1px solid lightgray', textAlign: 'center', fontWeight: 700, width: "4rem", height: "4" }}>
                                                    <Radio
                                                        value='3'
                                                        checked={row?.actionSelected === '3'}
                                                        name="radio-button-demo"
                                                        inputProps={{ 'aria-label': labelId }}
                                                        style={{
                                                            backgroundColor: row?.actionSelected === '3' ? '#fde6f0' : '',
                                                            borderRadius: row?.actionSelected === '3' ? '0px' : ''
                                                        }}
                                                    />
                                                </td>
                                                <td style={{ border: '1px solid lightgray', textAlign: 'center', fontWeight: 700, width: "4rem", height: "4" }}>
                                                    <Radio
                                                        value='4'
                                                        checked={row?.actionSelected === '4'}
                                                        name="radio-button-demo"
                                                        inputProps={{ 'aria-label': labelId }}
                                                        style={{
                                                            backgroundColor: row?.actionSelected === '4' ? '#fde6f0' : '',
                                                            borderRadius: row?.actionSelected === '4' ? '0px' : ''
                                                        }}
                                                    />
                                                </td>
                                                <td style={{ border: '1px solid lightgray', textAlign: 'center', fontWeight: 700, width: "4rem", height: "4" }}>
                                                    <Radio
                                                        value='5'
                                                        checked={row?.actionSelected === '5'}
                                                        name="radio-button-demo"
                                                        inputProps={{ 'aria-label': labelId }}
                                                        style={{
                                                            backgroundColor: row?.actionSelected === '5' ? '#fde6f0' : '',
                                                            borderRadius: row?.actionSelected === '5' ? '0px' : ''
                                                        }}
                                                    />
                                                </td>
                                            </RadioGroup>
                                        </tr>

                                    );
                                })}
                                <tr>
                                    <RadioGroup aria-label='option' name="customized-radios" style={{ display: 'table-cell' }}>
                                        <td colSpan={5} style={{ fontWeight: 500, width: "8rem", height: "4" }}></td>
                                        <td style={{ textAlign: 'center', fontWeight: 700, width: "3rem", height: "4rem" }}><ThumbDownIcon style={{ color: "#e72600" }} /></td>
                                        <td style={{ textAlign: 'center', fontWeight: 700, width: "3rem", height: "4rem" }}></td>
                                        <td style={{ textAlign: 'center', fontWeight: 700, width: "3rem", height: "4rem" }}></td>
                                        <td style={{ textAlign: 'center', fontWeight: 700, width: "3rem", height: "4rem" }}></td>
                                        <td style={{ textAlign: 'center', fontWeight: 700, width: "3rem", height: "4rem" }}><ThumbUpIcon style={{ color: "#218b00", marginLeft: 11 }} /></td>
                                    </RadioGroup>
                                </tr>
                            </tbody>
                            {  error && !satisFactionList && (
                                  <p style={{color:"red"}}>Please Select All Radio Option</p>
                                )}
                        </Table>
                    </div>
                    <div className='wonPhone'>
                        <Table striped bordered hover style={{ width: "100%", marginLeft: "20px", borderCollapse: "collapse" }}>
                            <tbody>
                                <tr>
                                    <RadioGroup defaultValue="abc" aria-label="action" name="customized-radios" style={{ display: 'table-cell' }}>
                                        <td colSpan={4} style={{ border: '1px solid lightgray', fontWeight: 500, width: "8rem", height: "4" }}></td>
                                        <td style={{ border: '1px solid lightgray', textAlign: 'center', fontWeight: 700, width: "5rem", height: "2rem", backgroundColor: "#e72600" }}><span style={{ color: "#fff", marginTop: "20px" }}> 1 </span></td>
                                        <td style={{ border: '1px solid lightgray', textAlign: 'center', fontWeight: 700, width: "3rem", height: "2rem", backgroundColor: "#f96500" }}><span style={{ color: "#fff" }}> 2 </span></td>
                                        <td style={{ border: '1px solid lightgray', textAlign: 'center', fontWeight: 700, width: "3rem", height: "2rem", backgroundColor: "#ffe000" }}><span style={{ color: "#fff" }}> 3 </span></td>
                                        <td style={{ border: '1px solid lightgray', textAlign: 'center', fontWeight: 700, width: "3rem", height: "2rem", backgroundColor: "#30ad00" }}><span style={{ color: "#fff" }}> 4 </span></td>
                                        <td style={{ border: '1px solid lightgray', textAlign: 'center', fontWeight: 700, width: "3rem", height: "2rem", backgroundColor: "#218b00" }}><span style={{ color: "#fff" }}> 5 </span></td>
                                    </RadioGroup>
                                </tr>

                                {satisfactionIndexRatioList.sort().map((row, i) => {
                                    const labelId = `enhanced-table-checkbox-${i}`;
                                    return (
                                        <tr>
                                            <RadioGroup aria-label='option' name="customized-radios"
                                                value={row?.actionSelected}
                                                onChange={(e) => satisfactionIndexRadio(e, i, row)}
                                                style={{ display: 'block' }}>
                                                <td colSpan={10} style={{ border: '1px solid lightgray', fontWeight: 500, width: "8rem", height: "4" }}> {row?.description} </td>
                                                <td style={{ border: '1px solid lightgray', textAlign: 'center', fontWeight: 700, width: "3rem", height: "4" }}>
                                                    <Radio
                                                        value='1'
                                                        checked={row?.actionSelected === '1'}
                                                        style={{
                                                            backgroundColor: row?.actionSelected === '1' ? '#fde6f0' : '',
                                                            borderRadius: row?.actionSelected === '1' ? '0px' : ''
                                                        }}
                                                        name="radio-button-demo"
                                                        inputProps={{ 'aria-label': labelId }}
                                                    />
                                                </td>
                                                <td style={{ border: '1px solid lightgray', textAlign: 'center', fontWeight: 700, width: "3rem", height: "4" }}>
                                                    <Radio
                                                        value='2'
                                                        checked={row?.actionSelected === '2'}
                                                        name="radio-button-demo"
                                                        inputProps={{ 'aria-label': labelId }}
                                                        style={{
                                                            backgroundColor: row?.actionSelected === '2' ? '#fde6f0' : '',
                                                            borderRadius: row?.actionSelected === '2' ? '0px' : ''
                                                        }}
                                                    />
                                                </td>
                                                <td style={{ border: '1px solid lightgray', textAlign: 'center', fontWeight: 700, width: "3rem", height: "4" }}>
                                                    <Radio
                                                        value='3'
                                                        checked={row?.actionSelected === '3'}
                                                        name="radio-button-demo"
                                                        inputProps={{ 'aria-label': labelId }}
                                                        style={{
                                                            backgroundColor: row?.actionSelected === '3' ? '#fde6f0' : '',
                                                            borderRadius: row?.actionSelected === '3' ? '0px' : ''
                                                        }}
                                                    />
                                                </td>
                                                <td style={{ border: '1px solid lightgray', textAlign: 'center', fontWeight: 700, width: "3rem", height: "4" }}>
                                                    <Radio
                                                        value='4'
                                                        checked={row?.actionSelected === '4'}
                                                        name="radio-button-demo"
                                                        inputProps={{ 'aria-label': labelId }}
                                                        style={{
                                                            backgroundColor: row?.actionSelected === '4' ? '#fde6f0' : '',
                                                            borderRadius: row?.actionSelected === '4' ? '0px' : ''
                                                        }}
                                                    />
                                                </td>
                                                <td style={{ border: '1px solid lightgray', textAlign: 'center', fontWeight: 700, width: "3rem", height: "4" }}>
                                                    <Radio
                                                        value='5'
                                                        checked={row?.actionSelected === '5'}
                                                        name="radio-button-demo"
                                                        inputProps={{ 'aria-label': labelId }}
                                                        style={{
                                                            backgroundColor: row?.actionSelected === '5' ? '#fde6f0' : '',
                                                            borderRadius: row?.actionSelected === '5' ? '0px' : ''
                                                        }}
                                                    />
                                                </td>
                                            </RadioGroup>
                                        </tr>

                                    );
                                })}
                                <tr>
                                    <RadioGroup aria-label='option' name="customized-radios" style={{ display: 'table-cell' }}>
                                        <td colSpan={5} style={{ fontWeight: 500, width: "8rem", height: "4" }}></td>
                                        <td style={{ textAlign: 'center', fontWeight: 700, width: "3rem", height: "4rem" }}><ThumbDownIcon style={{ color: "#e72600" }} /></td>
                                        <td style={{ textAlign: 'center', fontWeight: 700, width: "3rem", height: "4rem" }}></td>
                                        <td style={{ textAlign: 'center', fontWeight: 700, width: "3rem", height: "4rem" }}></td>
                                        <td style={{ textAlign: 'center', fontWeight: 700, width: "3rem", height: "4rem" }}></td>
                                        <td style={{ textAlign: 'center', fontWeight: 700, width: "3rem", height: "4rem" }}><ThumbUpIcon style={{ color: "#218b00", marginLeft: 11 }} /></td>
                                    </RadioGroup>
                                </tr>
                            </tbody>
                        </Table>
                    </div>




                    <br />
                    <FormControl component="fieldset" style={{ marginBottom: 20, marginTop: 20 }}>
                        <FormLabel component="legend" style={{ color: "#000" }}>Prime reason to choose Decorpot. * </FormLabel>
                        <RadioGroup aria-label="reasonNotChoose" name="reasonToChooseDecorpot"
                            ref={inputRefReasonToChoseDecorpot}
                            onChange={(e) => setWonFormData({ ...wonFormData, [e.target.name]: e.target.value })}>
                            <FormControlLabel value="Price" control={<Radio color="primary" />} label="Price" />
                            <FormControlLabel value="Quality" control={<Radio color="primary" />} label="Quality" />
                            <FormControlLabel value="Reference" control={<Radio color="primary" />} label="Reference" />
                            <FormControlLabel value="In-house-production-facility." control={<Radio color="primary" />} label="In-house production facility." />
                            <FormControlLabel value="Process-in-interior-journey" control={<Radio color="primary" />} label="Process in interior journey" />
                            <FormControlLabel value="Interaction-with-the-sales-representative." control={<Radio color="primary" />} label="Interaction with the sales representative." />
                            <FormControlLabel value="Other" control={<Radio color="primary" />} label="Other" />
                            {error && wonFormData.reasonToChooseDecorpot ==="" && (
                            <p style={{color:"red"}}>Please Choose the reason</p>
                            )}
                        </RadioGroup>
                        {
                            wonFormData.reasonToChooseDecorpot === 'Other' &&
                            <TextField
                                size="small"
                                style={{ marginBottom: 20, textAlign: "center", width: "100%", backgroundColor: "#faf1f5" }}
                                name="otherReasonToChooseDecorpot"
                                multiline
                                rows={3}
                                onChange={(e) => setWonFormData({ ...wonFormData, [e.target.name]: e.target.value })}
                                variant="outlined"
                            />
                        }
                    </FormControl>

                    <br />
                    <TextField
                        label="Additional comments *"
                        size="small"
                        ref={inputRefAdditonalComments}
                        style={{ marginBottom: 20, textAlign: "center", marginTop: 20, width: "100%", backgroundColor: "#f8f1fb" }}
                        name="comments"
                        multiline
                        rows={3}
                        onChange={(e) => setWonFormData({ ...wonFormData, [e.target.name]: e.target.value })}
                        variant="outlined"
                    />
                     {error && wonFormData.comments ==="" && (
                            <p style={{color:"red"}}>Please Mention Additional Comments</p>
                            )}
                    <br />
                </div>
            </Paper>


            <Paper style={{ marginTop: 10, padding: 10 }}>
                    <Button onClick={sendDataToParent}
                        variant="contained"
                        color="primary"
                        style={{ width: "10%" }}>
                        Send OTP
                    </Button>
            </Paper>

        </>
    )
}

export default connect(null, { setLoading, setSnackInfo })(WonForm)

