import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux'
import { Link } from "react-router-dom";
import { setLoading, setSnackInfo } from '../../redux/actions/appAction'
import HttpService from '../../services/httpService'
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { FormControl, MenuItem } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import moment from 'moment'
import { ROLENAME } from '../../utils/index';
import roles from '../userManagement/roles';

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 700,
    },
    root: {
        width: '100%',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    }
}));

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#ccc",
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const SiteQcProjects = (props) => {
    const classes = useStyles();
    const { userDetails } = useSelector(state => state.user)
    const [siteQcProjects, setSiteQcProjects] = useState([]);
    const [dumpSiteQcProjects, setDumpSiteQcProjects] = useState([]);
    const [searched, setSearched] = useState("")
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [totalaPages, setTotalPages] = useState(0)

    useEffect(() => {
        if (userDetails.roles.find(role => role.name === ROLENAME.IMOSUser || role.name === ROLENAME.IMOSManager)) {
            getIMOSProjects()
        } else if (userDetails.roles.find(role => role.name === ROLENAME.FactoryManager || role.name === ROLENAME.SiteQC || role.name === ROLENAME.BOMManager)) {
            getSiteQcProjects()
        } else if (userDetails.roles.find(role => role.name === ROLENAME.BOMExecutive || role.name === ROLENAME.FactoryBom || role.name === ROLENAME.SiteBom)) {
            getSBOMProjects()
        }
        // eslint-disable-next-line
    }, [rowsPerPage, page])

    const getSiteQcProjects = async () => {
        props.setLoading(true)
        try {
            let status = "";
            if (userDetails.roles.find(role => role.name === ROLENAME.SiteQC)) {
                status = 'Site QC'
            } else if (userDetails.roles.find(role => role.name === ROLENAME.SeniorOperationManager)) {
                status = 'Scoping'
            } else if (userDetails.roles.find(role => role.name === ROLENAME.FactoryManager)){
                status = "Factory"
            }
            const result = await HttpService.getFilteredProjectsList(status, userDetails.selectedExpCenter)
            let finalResult = []
            result.data.forEach(ele => {
                let obj = {
                    leadId: ele?.leadId?._id,
                    code: ele?.code,
                    divisionOfProject: ele?.divisionOfProject,
                    status: ele?.leadId?.currentStage,
                    assignedTo:ele?.assignedTo.name,
                    grandTotal: ele?.leadId?.grandTotal,
                    startDate: ele?.startDate,
                    createdAt: ele?.createdAt
                }
                finalResult.push(obj)
            })
            setSiteQcProjects(finalResult);
            setDumpSiteQcProjects(result.data)
            setTotalPages(result.data.length)
        } catch (error) {
            console.log(error, "error")
            props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const getIMOSProjects = async () => {
        props.setLoading(true)
        try {
            const result = await HttpService.getIMOSProjects(userDetails.selectedExpCenter)
            console.log(result.data, "result")
            let finalResult = []
            result.data.forEach(ele => {
                let obj = {
                    leadId: ele?.leadId?._id,
                    code: ele?.projectId?.code,
                    divisionOfProject: ele?.projectId?.divisionOfProject,
                    status: ele?.leadId?.currentStage,
                    grandTotal: ele?.leadId?.grandTotal,
                    imosassignTo:ele?.leadId?.assignTo?.name,
                    startDate: ele?.projectId?.startDate,
                    createdAt: ele?.createdAt
                }
                finalResult.push(obj)
            })
            setSiteQcProjects(finalResult);
            setDumpSiteQcProjects(finalResult)
            setTotalPages(finalResult.length)
        } catch (error) {
            console.log(error, "error")
            props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }
    const getSBOMProjects = async () => {
        props.setLoading(true)
        try {

            let result ;
            let finalResult = []
            if (userDetails.roles.find(role => role.name === ROLENAME.FactoryBom)) {
                result = await HttpService.getFactoryBomProjects(userDetails._id, userDetails.selectedExpCenter);
                result.data.forEach(ele => {
                    let obj = {
                        leadId: ele?._id,
                        code: ele?.erpProjectId?.code,
                        divisionOfProject: ele?.erpProjectId?.divisionOfProject,
                        status: ele?.currentStage,
                        fcBomassignTo: ele?.assignTo?.name,
                        grandTotal: ele?.grandTotal,
                        // startDate: ele?.erpProjectId?.startDate,
                        startDate: ele?.customerDesignSignOffDate,
                        createdAt: ele?.createdAt
                    }
                    finalResult.push(obj)
                })
            } else {
                result = await HttpService.getSBOMProjects(userDetails.selectedExpCenter, userDetails._id);
                result.data.forEach(ele => {
                    let obj = {
                        leadId: ele?.leadId?._id,
                        code: ele?.projectId?.code,
                        divisionOfProject: ele?.projectId?.divisionOfProject,
                        status: ele?.leadId?.currentStage,
                        fcBomassignTo: ele?.leadId?.assignTo?.name,
                        grandTotal: ele?.leadId?.grandTotal,
                        // startDate: ele?.projectId?.startDate,
                        startDate: ele?.leadId?.customerDesignSignOffDate,
                        createdAt: ele?.createdAt
                    }
                    finalResult.push(obj)
                })
            } 
            
            // Getting Project under IMOS
            const projectUnderIMOS = await HttpService.getIMOSProjects(userDetails.selectedExpCenter);
            
            // pushing imos project to the arr
            projectUnderIMOS.data.forEach(ele => {
                let newItem = {
                    leadId: ele?.leadId?._id,
                    code: ele?.projectId?.code,
                    divisionOfProject: ele?.projectId?.divisionOfProject,
                    status: ele?.leadId?.currentStage,
                    fcBomassignTo: ele?.leadId?.assignTo?.name,
                    grandTotal: ele?.leadId?.grandTotal,
                    // startDate: ele?.projectId?.startDate,
                    startDate: ele?.leadId?.customerDesignSignOffDate,
                    createdAt: ele?.createdAt
                }
                // Check if the newItem already exists in finalResult
                const isDuplicate = finalResult.some(item => (
                    item.leadId === newItem.leadId && item.code === newItem.code
                ));
        
                // If not a duplicate, add it to finalResult
                if (!isDuplicate) {
                    finalResult.push(newItem);
                }
            })

            setSiteQcProjects(finalResult);
            setDumpSiteQcProjects(finalResult)
            setTotalPages(finalResult.length)
        } catch (error) {
            console.log(error, "error")
            props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }
    const getFactoryProjects = async () => {
        props.setLoading(true)
        try {
            const result = await HttpService.getFactoryProjects()
            setSiteQcProjects(result.data);
            setDumpSiteQcProjects(result.data)
            setTotalPages(result.data.length)
        } catch (error) {
            console.log(error, "error")
            props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const setHandleSearched = (value) => {
        setSearched(value)
        requestSearch(value)
    }

    const requestSearch = async (search) => {

    }

    return (
        <div>

            {/* <Paper style={{ marginTop: '20px', marginBottom: "20px", padding: "20px" }}>
                <div style={{ display: "flex" }}>
                    <Grid item xs>
                        <TextField label="Search By Customer Name" name="searched" size="small" value={searched} onChange={(e) => setHandleSearched(e.target.value)} variant="outlined" />
                    </Grid>

                </div>
            </Paper> */}


            <Paper>

                <TableContainer component={Paper}>
                    <Table className={classes.table} stickyHeader aria-label="sticky table">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell>Sr. No.</StyledTableCell>
                                <StyledTableCell>Project Code</StyledTableCell>
                                <StyledTableCell>Status</StyledTableCell>
                                <StyledTableCell>Division of Project</StyledTableCell>
                                <StyledTableCell>Design Sign-off Date</StyledTableCell>
                                <StyledTableCell>Assigned To</StyledTableCell>
                                <StyledTableCell>Total Amount</StyledTableCell>
                                {/* <StyledTableCell>Created At</StyledTableCell> */}
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {siteQcProjects.map((row, i) => {
                                return (
                                    <StyledTableRow key={i + 1}>
                                        <StyledTableCell>{i + 1}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">
                                            <Link to={`/siteqc/project-detail/${row.leadId}`} style={{ color: "#000", textDecoration: 'underline' }}>
                                                {row?.code}
                                            </Link>
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row">{row?.status}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">{row?.divisionOfProject === '<10L' ? 'Less Than 10L' : row?.divisionOfProject}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">{moment(row?.startDate).format("DD-MM-YYYY")}</StyledTableCell>
                                        <StyledTableCell>
                                        {
                                        (userDetails.roles.find(role => role.name === ROLENAME.SiteQC ||role.name===ROLENAME.FactoryManager)) &&
                                       <>{row?.assignedTo}</>
                                         }
                                         {
                                              (userDetails.roles.find(role => role.name === ROLENAME.IMOSUser || role.name === ROLENAME.IMOSManager)) &&
                                            <>{row?.imosassignTo}</>
                                         }
                                        {
                                      (userDetails.roles.find(role => role.name === ROLENAME.BOMExecutive || role.name === ROLENAME.FactoryBom || role.name === ROLENAME.SiteBom || role.name === ROLENAME.BOMManager)) &&
                                     <> {row?.fcBomassignTo}</>
                                        }
                            </StyledTableCell>
                                        <StyledTableCell component="th" scope="row">{row?.grandTotal}</StyledTableCell>
                                    </StyledTableRow>
                                );
                            })}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 20, 50, { label: 'All', value: totalaPages }]}
                                    colSpan={10}
                                    count={totalaPages}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: { 'aria-label': 'rows per page' },
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>

            </Paper>
        </div>
    )
}
export default connect(null, { setLoading, setSnackInfo })(SiteQcProjects)
