import {
	Backdrop,
	Button,
	CircularProgress,
	FormControl,
	FormControlLabel,
	Grid,
	IconButton,
	Paper,
	Radio,
	RadioGroup,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	TablePagination,
	TableRow,
	TextField,
	makeStyles,
	useTheme,
	withStyles,
} from "@material-ui/core";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import PropTypes from "prop-types";
import HttpService from "../../services/httpService";
import { useEffect, useRef, useState } from "react";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import { DownloadOutlined, EyeOutlined } from "@ant-design/icons";
import { Modal, Table } from "antd";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { CSVLink } from "react-csv";

const useStyles = makeStyles((theme) => ({
	table: {
		width: "fit-content",
	},
	root: {
		width: "100%",
	},
	titleContent: {
		textAlign: "center",
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: "#fff",
	},
}));

const useStyles1 = makeStyles((theme) => ({
	root: {
		flexShrink: 0,
		marginLeft: theme.spacing(2.5),
	},
}));

const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: "#ccc",
		color: theme.palette.common.black,
	},
	body: {
		fontSize: 14,
	},
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
	root: {
		"&:nth-of-type(odd)": {
			backgroundColor: theme.palette.action.hover,
		},
	},
}))(TableRow);

function TablePaginationActions(props) {
	const classes = useStyles1();
	const theme = useTheme();
	const { count, page, rowsPerPage, onPageChange } = props;

	const handleFirstPageButtonClick = (event) => {
		onPageChange(event, 0);
	};

	const handleBackButtonClick = (event) => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = (event) => {
		onPageChange(event, page + 1);
	};

	const handleLastPageButtonClick = (event) => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<div className={classes.root}>
			<IconButton
				onClick={handleFirstPageButtonClick}
				disabled={page === 0}
				aria-label="first page"
			>
				{theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton
				onClick={handleBackButtonClick}
				disabled={page === 0}
				aria-label="previous page"
			>
				{theme.direction === "rtl" ? (
					<KeyboardArrowRight />
				) : (
					<KeyboardArrowLeft />
				)}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
			>
				{theme.direction === "rtl" ? (
					<KeyboardArrowLeft />
				) : (
					<KeyboardArrowRight />
				)}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"
			>
				{theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</div>
	);
}

TablePaginationActions.propTypes = {
	count: PropTypes.number.isRequired,
	onPageChange: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
};

const InwardingInvoices = (props) => {
	const classes = useStyles();
	const [approvedProjects, setApprovedProjects] = useState([]);
	const [projectsToDisplay, setProjectsToDisplay] = useState([]);
	// const [showInvoicesModal, setShowInvoicesModal] = useState(false);
	// const [selectedProject, setSelectedProject] = useState();
	const [loading, setLoading] = useState(false);
	// const [invoiceArray, setInvoiceArray] = useState([]);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [radioValue, setRadioValue] = useState("PO");
	const [radioValueForDateFilter, setRadioValueForDateFilter] =
		useState("PO Date");
	const [orderId, setOrderId] = useState("");
	const [startDate, setStartDate] = useState();
	const [endDate, setEndDate] = useState();
	let filterFromArray = [...approvedProjects];
	const csvButtonRef = useRef(null);
	const [csvData, setCsvData] = useState([]);

	const headers = [
		{ key: "projectCode", label: "Project No." },
		{ key: "clientName", label: "Client Name" },
		{ key: "poSoNumber", label: "Po/So No." },
		{ key: "poSoDate", label: "Po Date" },
		{ key: "vendorName", label: "Vendor Name" },
		{ key: "invoiceDate", label: "Invoice Date" },
		{ key: "invoiceNumber", label: "Invoice No." },
		{ key: "inwardingDate", label: "Inwarding Date" },
		{ key: "link", label: "Attatchment Link" },
	];

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleRadioChange = (e) => {
		setRadioValue(e.target.value);
		setOrderId("");
	};

	const handleRadioChangeForDate = (e) => {
		setRadioValueForDateFilter(e.target.value);
	};

	const inwardedInvoiceOnSelectedDateHandler = () => {
		setOrderId("");
		filterFromArray = [...approvedProjects];
		if (radioValueForDateFilter == "PO Date") {
			if (startDate && endDate) {
				const filteredArray = filterFromArray.filter((el) => {
					let itemDate = new Date(el.createdAt);
					let selectedStartDate = new Date(startDate);
					let selectedEndDate = new Date(endDate);
					let adjustedEndDate = new Date(selectedEndDate);
					adjustedEndDate.setDate(adjustedEndDate.getDate() + 1);
					return itemDate >= selectedStartDate && itemDate <= adjustedEndDate;
				});
				setProjectsToDisplay(filteredArray);
			}
		} else if (radioValueForDateFilter == "Invoice Date") {
			if (startDate && endDate) {
				const filteredArray = filterFromArray.filter((el) => {
					let itemDate = new Date(el.inwardingDetail.invoiceDate);
					let selectedStartDate = new Date(startDate);
					let selectedEndDate = new Date(endDate);
					return itemDate >= selectedStartDate && itemDate <= selectedEndDate;
				});
				setProjectsToDisplay(filteredArray);
			}
		} else if (radioValueForDateFilter == "Inwarding Date") {
			if (startDate && endDate) {
				const filteredArray = filterFromArray.filter((el) => {
					let itemDate = new Date(el.inwardingDetail.updatedAt);
					let selectedStartDate = new Date(startDate);
					let selectedEndDate = new Date(endDate);
					let adjustedEndDate = new Date(selectedEndDate);
					adjustedEndDate.setDate(adjustedEndDate.getDate() + 1);
					return itemDate >= selectedStartDate && itemDate <= adjustedEndDate;
				});
				setProjectsToDisplay(filteredArray);
			}
		}
		// if(startDate && endDate){
		// 	const filteredArray = filterFromArray.filter((el) => {
		// 		let itemDate = new Date(el.createdAt);
		// 		let selectedStartDate = new Date(startDate);
		// 		let selectedEndDate = new Date(endDate);
		// 		return itemDate >= selectedStartDate && itemDate <= selectedEndDate;
		// 	});
		// 	setProjectsToDisplay(filteredArray);
		// }
	};
	const clearFilterDate = (e) => {
		setStartDate("");
		setEndDate("");
		setOrderId("");
		setProjectsToDisplay(approvedProjects);
	};

	/* const showInvoicesModalHandler = async (data) => {
		try {
			setLoading(true);
			setSelectedProject(
				data?.projectId?.code ? data.projectId.code : "OPEN_PO"
			);
			setShowInvoicesModal(true);
			const response = await HttpService.getInwardingMaterialInvoices(
				data?._id
			);
			setInvoiceArray((el) => [...response?.data]);
			setLoading(false);
		} catch (error) {
			console.log(error);
		}
	}; */

	function compareDates(a, b) {
		const dateA = new Date(a.inwardingDetail.updatedAt);
		const dateB = new Date(b.inwardingDetail.updatedAt);
		return dateB - dateA;
	}

	const getApprovedProjects = async () => {
		try {
			setLoading(true);
			const response =
				await HttpService.getAllProcurementsForInwardingIncludingOpenPO();
			let approvedProjectsss = [];
			approvedProjectsss = response.data.sort(compareDates);
			setApprovedProjects(response.data);
			setProjectsToDisplay(response.data);
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	};

	const getApprovedProjectByOrderIdOrVendorName = (orderId) => {
		if (startDate && endDate) {
			filterFromArray = [...projectsToDisplay];
		} else if (!startDate || !endDate) {
			filterFromArray = [...approvedProjects];
		}
		if (radioValue === "PO" && orderId !== "") {
			let filteredArray = filterFromArray.filter((obj) =>
				obj.orderId.includes(orderId)
			);
			if (filteredArray.length > 0) {
				setProjectsToDisplay(filteredArray);
				setPage(0);
			}
		} else if (radioValue === "Vendor" && orderId !== "") {
			let filteredArray = filterFromArray.filter((obj) =>
				obj.vendorId.name.toLowerCase().includes(orderId.toLowerCase())
			);
			if (filteredArray.length > 0) {
				setProjectsToDisplay(filteredArray);
				setPage(0);
			}
		} else if (orderId === "") {
			if (startDate && endDate) {
				filterFromArray = [...approvedProjects];
				inwardedInvoiceOnSelectedDateHandler();
			} else {
				setProjectsToDisplay(approvedProjects);
			}
		}
	};

	const dateFormatter = (inputDate) => {
		const date = new Date(inputDate);

		const formattedDate = `${date.getUTCDate()}-${date.getUTCMonth() + 1}-${date.getUTCFullYear()}`;
		return formattedDate;
	};

	const csvDownloadHandler = async () => {
		setLoading(true);
		try {
			let csvArray = [];

			for (let i = 0; i < projectsToDisplay.length; i++) {
				let obj = {
					projectCode: projectsToDisplay[i]?.projectId?.code || "OPEN_PO",
					clientName: projectsToDisplay[i]?.projectId?.clientName || "OPEN_PO",
					poSoNumber: projectsToDisplay[i]?.orderId,
					poSoDate: dateFormatter(projectsToDisplay[i]?.createdAt),
					vendorName: projectsToDisplay[i]?.vendorId?.name,
					invoiceDate: dateFormatter(
						projectsToDisplay[i]?.inwardingDetail?.invoiceDate
					),
					invoiceNumber: projectsToDisplay[i]?.inwardingDetail?.invoiceNumber,
					inwardingDate: dateFormatter(
						projectsToDisplay[i]?.inwardingDetail?.updatedAt
					),
					link: projectsToDisplay[i]?.inwardingDetail?.inwardingSlip || "N/A",
				};
				csvArray.push(obj);
				obj = {
					projectCode: "",
					clientName: "",
					poSoNumber: "",
					poSoDate: "",
					vendorName: "",
					invoiceDate: "",
					invoiceNumber: "",
					inwardingDate: "",
					link: "",
				};
				csvArray.push(obj);
			}
			setCsvData(csvArray);
			downloadExcel();
		} catch (error) {
			console.log("error", error);
			props?.setSnackInfo(
				error?.response?.data?.message ?? "Something went wrong",
				"error"
			);
		}
		setLoading(false);
	};

	const downloadExcel = () => {
		setLoading(true);
		try {
			setTimeout(() => {
				csvButtonRef.current.link.click();
			});
		} catch (err) {
			console.error(err.message);
		}
		setLoading(false);
	};

	useEffect(() => {
		getApprovedProjects();
	}, []);

	return (
		<div>
			<Paper style={{ padding: 10, marginBottom: 10 }}>
				<div style={{ display: "flex", justifyContent: "center" }}>
					<FormControl component="fieldset">
						<RadioGroup
							aria-label="type"
							name="type"
							value={radioValueForDateFilter}
							onChange={handleRadioChangeForDate}
						>
							<div className="modal-header-radio">
								<FormControlLabel
									value="PO Date"
									control={<Radio />}
									label="PO Date"
								/>
								<FormControlLabel
									value="Invoice Date"
									control={<Radio />}
									label="Invoice Date"
								/>
								<FormControlLabel
									value="Inwarding Date"
									control={<Radio />}
									label="Inwarding Date"
								/>
							</div>
						</RadioGroup>
					</FormControl>
				</div>
				<Grid container style={{ display: "flex", alignItems: "center" }}>
					<Grid item md={3} xs={12}>
						<TextField
							id="date"
							name="startDate"
							label="From Date"
							type="date"
							value={startDate}
							onChange={(e) => setStartDate(e.target.value)}
							InputLabelProps={{
								shrink: true,
							}}
							fullWidth
							className={classes.textField}
						/>
					</Grid>
					<Grid item md={1} xs={12}></Grid>
					<Grid item md={3} xs={12}>
						<TextField
							id="date"
							name="endDate"
							label="End Date"
							type="date"
							value={endDate}
							onChange={(e) => setEndDate(e.target.value)}
							InputLabelProps={{
								shrink: true,
							}}
							fullWidth
							className={classes.textField}
						/>
					</Grid>
					<Grid item md={3} xs={12}>
						<Button
							variant="contained"
							color="primary"
							disabled={!startDate || !endDate}
							onClick={inwardedInvoiceOnSelectedDateHandler}
							style={{ marginLeft: 10 }}
						>
							Submit
						</Button>
					</Grid>
					<Grid item md={2} xs={12}>
						<Button
							variant="contained"
							color="secondary"
							onClick={clearFilterDate}
						>
							Clear Filter
						</Button>
					</Grid>
				</Grid>
			</Paper>
			<Paper
				style={{
					display: "flex",
					justifyContent: "space-between",
					alignContent: "center",
					// marginBottom: 25,
					padding: 10,
					marginBottom: 10,
				}}
			>
				<FormControl component="fieldset">
					<div style={{ display: "flex", alignItems: "center" }}>
						<RadioGroup
							aria-label="type"
							name="type"
							value={radioValue}
							onChange={handleRadioChange}
						>
							<div className="modal-header-radio">
								<FormControlLabel value="PO" control={<Radio />} label="PO" />
								<FormControlLabel
									value="Vendor"
									control={<Radio />}
									label="Vendor Name"
								/>
							</div>
						</RadioGroup>
						<TextField
							label={`Search By ${
								radioValue == "PO" ? "Order Id" : "Vendor Name"
							}`}
							name="orderId"
							value={orderId}
							style={{ width: 300 }}
							onChange={(e) => {
								setOrderId(e.target.value);
							}}
							variant="outlined"
						/>
						<Button
							onClick={() => getApprovedProjectByOrderIdOrVendorName(orderId)}
							style={{ marginLeft: 10 }}
							variant="contained"
							color="primary"
						>
							Search
						</Button>
					</div>
				</FormControl>
				<div style={{ display: "flex", alignItems: "center" }}>
					<CSVLink
						headers={headers}
						filename={"Inwarding_Invoice_Excel" + ".csv"}
						data={csvData}
						ref={csvButtonRef}
					/>
					<Button
						onClick={csvDownloadHandler}
						style={{ marginLeft: 10, backgroundColor: "green" }}
						variant="contained"
						color="success"
					>
						Download CSV
					</Button>
				</div>
			</Paper>

			{/* 07-06-2023 */}
			<TableContainer
				component={Paper}
				className={classes.table}
				size="small"
				// aria-label="customized table"
			>
				<TableHead>
					<TableRow>
						<StyledTableCell>Project No.</StyledTableCell>
						<StyledTableCell>Client Name</StyledTableCell>
						<StyledTableCell>PO/SO No.</StyledTableCell>
						<StyledTableCell>Po Created Date</StyledTableCell>
						<StyledTableCell>Vendor Name</StyledTableCell>
						<StyledTableCell>Invoice Date</StyledTableCell>
						<StyledTableCell>Invoice Number</StyledTableCell>
						<StyledTableCell>Inwarding Date</StyledTableCell>
						<StyledTableCell>Attachments</StyledTableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{(rowsPerPage > 0
						? projectsToDisplay.slice(
								page * rowsPerPage,
								page * rowsPerPage + rowsPerPage
						  )
						: projectsToDisplay
					).map((data, i) => (
						<StyledTableRow key={i + 1}>
							<StyledTableCell>
								{data?.projectId?.code ? data?.projectId.code : "OPEN_PO"}
							</StyledTableCell>
							<StyledTableCell>
								{data?.projectId?.clientName
									? data?.projectId.clientName
									: "OPEN_PO"}
							</StyledTableCell>
							<StyledTableCell>{data?.orderId}</StyledTableCell>
							<StyledTableCell>
								{dateFormatter(data?.createdAt)}
							</StyledTableCell>
							<StyledTableCell>{data?.vendorId?.name}</StyledTableCell>
							<StyledTableCell>
								{dateFormatter(data?.inwardingDetail?.invoiceDate)}
							</StyledTableCell>
							<StyledTableCell>
								{data?.inwardingDetail?.invoiceNumber}
							</StyledTableCell>
							<StyledTableCell>
								{dateFormatter(data?.inwardingDetail?.updatedAt)}
							</StyledTableCell>
							<StyledTableCell>
								{data?.inwardingDetail?.inwardingSlip ? (
									<DownloadOutlined
										onClick={() =>
											window.open(data?.inwardingDetail?.inwardingSlip)
										}
									/>
								) : (
									"N/A"
								)}
							</StyledTableCell>
						</StyledTableRow>
					))}
				</TableBody>
				<TableFooter>
					<TableRow>
						<TablePagination
							rowsPerPageOptions={[10, 20, 50, { label: "All", value: -1 }]}
							colSpan={8}
							count={projectsToDisplay.length}
							rowsPerPage={rowsPerPage}
							page={page}
							SelectProps={{
								inputProps: { "aria-label": "rows per page" },
								native: true,
							}}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							ActionsComponent={TablePaginationActions}
						/>
					</TableRow>
				</TableFooter>
			</TableContainer>
			{/* <Dialog
				aria-labelledby="form-dialog-title"
				maxWidth="sm"
				open={showInvoicesModal}
				onClose={() => setShowInvoicesModal(false)}
			>
				<DialogTitle className={classes.titleContent}>
					{`Inwarding ${selectedProject}`}
				</DialogTitle>
				<DialogContent>
					<TableContainer
						component={Paper}
						className={classes.table}
						size="small"
					>
						<TableHead>
							<TableRow>
								<StyledTableCell>Invoice Number</StyledTableCell>
								<StyledTableCell>Download Invoices</StyledTableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{invoiceArray &&
								invoiceArray?.map((invoice, i) => (
									<StyledTableRow key={i + 1}>
										<StyledTableCell>{invoice.invoiceNumber}</StyledTableCell>
										<StyledTableCell>
											{invoice.inwardingSlip ? (
												<DownloadOutlined
													onClick={() => window.open(invoice.inwardingSlip)}
												/>
											) : (
												"N/A"
											)}
										</StyledTableCell>
									</StyledTableRow>
								))}
						</TableBody>
					</TableContainer>
				</DialogContent>
			</Dialog> */}
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
		</div>
	);
};
export default InwardingInvoices;
