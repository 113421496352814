import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import HttpService from '../../services/httpService';
import { setLoading, setSnackInfo } from '../../redux/actions/appAction';
import moment from 'moment'
import { Accordion, AccordionSummary, AccordionDetails, Typography, Paper, Grid, Divider, Button } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import AttachmentIcon from '@material-ui/icons/Attachment';

const useStyles = makeStyles((theme) => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));

const ReminderReport = (props) => {
    const classes = useStyles();
    const [startDate, setStartDate] = useState(moment().subtract(1, 'months').format('YYYY-MM-DD'))
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'))
    const [reminderNotes, setReminderNotes] = useState([]);

    useEffect(() => {
        getMonthReminder()
        // eslint-disable-next-line
    }, [])

    const getMonthReminder = async () => {
        props.setLoading(true)
        let obj = {
            dateBetween: [startDate, endDate],
            flag: false
        }
        try {
            const result = await HttpService.reminderLogs(obj)
            setReminderNotes(result.data);
        } catch (error) {
            console.error(error)
            setReminderNotes([])
            props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const getReminderData = async () => {
        props.setLoading(true)
        let obj = {}
        if (startDate === endDate) {
            obj.equalDate = startDate;
            obj.flag = true
        } else {
            obj.dateBetween = [startDate, endDate];
            obj.flag = false
        }
        try {
            const result = await HttpService.reminderLogs(obj)
            setReminderNotes(result.data);
        } catch (error) {
            console.error(error)
            setReminderNotes([])
            props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    return (
        <div>
            <Paper style={{ padding: 10, marginBottom: 20 }}>
                <div style={{ fontSize: 18 }}><b>Reminder</b></div>
                <div style={{ display: 'flex', marginTop: 10, marginBottom: 20 }}>
                    <TextField
                        id="date"
                        name="startDate"
                        label="From Date"
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        fullWidth
                        className={classes.textField}
                    />
                    <TextField
                        id="date"
                        name="endDate"
                        label="To Date"
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        fullWidth
                        className={classes.textField}
                    />
                    <Button variant="contained" color="primary" style={{ alignSelf: "center", marginLeft: 20 }} onClick={getReminderData} >
                        Submit
                    </Button>
                </div>
            </Paper>
            {reminderNotes?.map((data, i) => (
                <Grid item lg={6} md={6} key={i}>
                    <Paper style={{ padding: 10 }}>
                        <Accordion defaultExpanded className="accordian">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography className={classes.heading}>
                                    <LocalActivityIcon />
                                    <span className="titlename">Deal Activity</span>
                                    <span className="datedata">{moment(data?.createdAt).format('lll')}</span>
                                </Typography>
                            </AccordionSummary>
                            <Divider />
                            <AccordionDetails>
                                <Typography>
                                    <strong>Customer Name: {data?.leadId?.customerId?.name}</strong>
                                    <br />
                                    <strong>Message:</strong> {data?.notes}
                                    <br />
                                    <hr />
                                    <strong><small>Created By:</small></strong> <small>{data?.createdBy?.name}</small>
                                </Typography>
                                <AttachmentIcon onClick={() => window.open(data.s3Location[0])} />
                            </AccordionDetails>
                        </Accordion >
                    </Paper>
                </Grid>
            ))}
        </div>
    )
}

export default connect(null, { setLoading, setSnackInfo })(ReminderReport)
