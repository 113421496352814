import { GET_ALL_MATERIALS, SET_VENDOR_NAME } from '../actions/types'

const initialState = {
  allMaterials: [],
  vendorDetail: {},
  loading: true
}

export default function vendorReducer(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case GET_ALL_MATERIALS:
      return {
        ...state,
        allMaterials: payload,
      }
    case SET_VENDOR_NAME: //changes
      return {
        ...state,
        vendorDetail: payload,
      }
    default:
      return state
  }
}