import React, { useState, useEffect } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import { setLoading, setSnackInfo } from '../redux/actions/appAction';
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow, TableHead } from '@material-ui/core';
import { ROLENAME } from '../utils/index';
import { Typography } from 'antd';
import HttpService from '../services/httpService';
import { Link } from 'react-router-dom';
import moment from 'moment'
import { commaSeparateAmount } from './commaSeparate';
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }
}));

const ContractSignLeads = (props) => {
    const classes = useStyles();
    const { userDetails } = useSelector(state => state.user)
    const [leadsData, setLeadsData] = useState([]);

    useEffect(() => {
        checkUserRole(props.ROLE)
        getNextFourMonts()
    }, [props.SELECTEDEXPCENTER])

    const getNextFourMonts = async () => {
        const MONTH_NAMES = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        let month_year_data = []
        const date = new Date()
        let current_month = date.getMonth() 
        let current_year = date.getFullYear()
        
        for(let i = 0; i < 5; i++)
        {
            month_year_data.push({name: MONTH_NAMES[current_month], number: current_month + 1, year: current_year})
            if(current_month == 11)
            {
                current_month = 0
                current_year += 1
            } else {
                current_month += 1

            }
        }
        setMonthyears(month_year_data)
        setSelectedMonthsYear([month_year_data[0]])
    }

    const checkUserRole = (roleid) => {

        let data = [];
        if (roleid.find(role=> role.name === ROLENAME.DesignUser))
        {
            props.DATA.contractSignLeads.forEach((ele, i) => {
                data.push({
                    leadId: ele?._id,
                    customerName: ele.customerId?.name,
                    budget: ele.grandTotal,
                    stage: ele.designStages,
                    designStatus:ele.designStatus,
                    contractSignDate: moment(ele.designSignOffDate).format('DD-MM-YYYY'),
                    closureDate: moment(ele.closureDate).format('DD-MM-YYYY')
                })
            })
            setLeadsData(data.sort((a, b) => a.customerName?.localeCompare(b.customerName)))
        } 
        else if (roleid.find(role=> role.name === ROLENAME.DesignManager) && !roleid.find(role=> role.name === ROLENAME.DesignHead))
        {
            props.DATA.contractSignLeads.forEach((ele, i) => {
                data.push({
                    leadId: ele?._id,
                    customerName: ele.customerId?.name,
                    designerName: ele.assignTo?.name,
                    budget: ele.grandTotal,
                    stage: ele.designStages,
                    designStatus:ele.designStatus,
                    contractSignDate: moment(ele.designSignOffDate).format('DD-MM-YYYY'),
                    closureDate: moment(ele.closureDate).format('DD-MM-YYYY')
                })
            })
            setLeadsData(data.sort((a, b) => a.customerName?.localeCompare(b.customerName)))
        }
        else if ((roleid.find(role=> role.name === ROLENAME.CenterHead || role.name === ROLENAME.DesignHead || role.name === ROLENAME.AssistantDesignManager|| role.name === ROLENAME.OperationDesignManager)))
        {
            props.DATA.contractSignLeads.forEach((ele, i) => {
                data.push({
                    leadId: ele?._id,
                    customerName: ele.customerId?.name,
                    dmOrAdm: ele.dmOrAdm,
                    AssistanceDesignManager: ele.assignTo?.name,
                    designerName: ele.assignTo?.name,
                    budget: ele.grandTotal,
                    stage: ele.designStages,
                    designStatus:ele.designStatus,
                    contractSignDate: moment(ele.designSignOffDate).format('DD-MM-YYYY'),
                    closureDate: moment(ele.closureDate).format('DD-MM-YYYY')
                })
            })
            setLeadsData(data.sort((a, b) => a.customerName?.localeCompare(b.customerName)))
        }
    }
    const [selectedMonthsYear,setSelectedMonthsYear]=useState([])
    const [monthyears,setMonthyears]=useState([])

    const getNewContractLeads = async (v) => {
        
        props.setLoading(true)
        if(v.length>0){
            try {
                let data = []
                const response = await HttpService.getDesignDataForContract(v, userDetails.selectedExpCenter)
                
                response.data.contractSignLeads.forEach((ele,i)=>{
                    data.push({
                        leadId: ele?._id,
                        customerName: ele.customerId?.name,
                        AssistanceDesignManager: ele.assignTo?.name,
                        designerName: ele.assignTo?.name,
                        budget: ele.grandTotal,
                        stage: ele.designStages,
                        designStatus:ele.designStatus,
                        contractSignDate: moment(ele.designSignOffDate).format('DD-MM-YYYY'),
                        closureDate: moment(ele.closureDate).format('DD-MM-YYYY')
                    })
                })
                setLeadsData(data.sort((a, b) => a.customerName?.localeCompare(b.customerName)))
            } catch (error) {
                console.log(error)
            }
        }
        else
        {
            try {
                let data = []
                const response = await HttpService.getDesignDataForContract([monthyears[0]], userDetails.selectedExpCenter)

                response.data.contractSignLeads.forEach((ele,i)=>{
                    data.push({
                        leadId: ele?._id,
                        customerName: ele.customerId?.name,
                        AssistanceDesignManager: ele.assignTo?.name,
                        designerName: ele.assignTo?.name,
                        budget: ele.grandTotal,
                        stage: ele.designStages,
                        designStatus:ele.designStatus,
                        contractSignDate: moment(ele.designSignOffDate).format('DD-MM-YYYY'),
                        closureDate: moment(ele.closureDate).format('DD-MM-YYYY')
                    })
                })
                setLeadsData(data.sort((a, b) => a.customerName?.localeCompare(b.customerName)))
            } catch (error) {
                console.log(error)
            }
        }
        props.setLoading(false)
    }
    const handleMonthYearData =(v)=>{
        setSelectedMonthsYear(v)
        getNewContractLeads(v)
      }
    return (
        <div>
            <div style={{ marginTop: 25, marginBottom: 25 }}>
                <Paper className={classes.paper}>
                    <Typography component="h3">{props.TITLE}</Typography>
                    <Autocomplete
                        multiple
                        value={selectedMonthsYear}
                        onChange={(e, v) => handleMonthYearData(v)}
                        options={monthyears}
                        getOptionLabel={(option) => option.name +" "+ option.year}
                        style={{ width: 500, marginBottom: 15,margin: 'auto'}}
                        renderInput={(params) => <TextField {...params} label="Select Upcoming Months" fullWidth variant="outlined" />}
                        />
                    {
                        <TableContainer component={Paper} style={{ marginTop: 10 }}>
                            <Table className={classes.table} stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow >
                                        <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Sl No</TableCell>
                                        <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Customer Name</TableCell>
                                        {
                                            (props.ROLE.find(role=> role.name === ROLENAME.DesignManager) && (!props.ROLE.find(role=> role.name === ROLENAME.DesignHead))) &&
                                            <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Designer</TableCell>
                                        }
                                        {
                                            (props.ROLE.find(role=> role.name === ROLENAME.CenterHead || role.name === ROLENAME.DesignHead)) && <>
                                                <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>ADM / DM</TableCell>
                                                <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Designer</TableCell>
                                            </>
                                        }
                                        <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Budget</TableCell>
                                        <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Stage</TableCell>
                                        <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Tentative	Contract Sign Date</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        leadsData.length !== 0 ?
                                            leadsData.map((status, i) => {
                                                return (
                                                    <TableRow key={i + 1}>
                                                        <TableCell component="th" scope="row" style={{ textAlign: 'center' }} >{i + 1}</TableCell>
                                                        <TableCell component="th" scope="row" style={{ textAlign: 'center' }} >
                                                        <Link to={`/project-logs/${status?.leadId}`} style={{ color: "#000", textDecoration: 'underline' }}>  {status.customerName}</Link>
                                                            </TableCell>
                                                        {
                                                            (props.ROLE.find(role=> role.name === ROLENAME.DesignManager) && (!props.ROLE.find(role=> role.name === ROLENAME.DesignHead))) &&
                                                            <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{status.designerName}</TableCell>
                                                        }
                                                        {
                                                            (props.ROLE.find(role=> role.name === ROLENAME.CenterHead || role.name === ROLENAME.DesignHead)) && <>
                                                                <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{status.dmOrAdm}</TableCell>
                                                                <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{status.designerName}</TableCell>
                                                            </>
                                                        }
                                                        <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>₹ {commaSeparateAmount(status.budget)}</TableCell>
                                                        <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{status.designStatus === 'Design-Hold'? status.designStatus : status.stage}</TableCell>
                                                        <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{status.closureDate}</TableCell>
                                                    </TableRow>
                                                )
                                            })
                                            :
                                            <TableRow>
                                                <TableCell colSpan={9} style={{ textAlign: "center" }}>No Data Found</TableCell>
                                            </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                </Paper>
            </div>
        </div>
    )
}

export default connect(null, { setLoading, setSnackInfo })(ContractSignLeads)
