import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { getAllMaterials } from '../../../redux/actions/vendorAction';
import { getAllSubCategory } from '../../../redux/actions/projectAction'
import { setLoading, setSnackInfo } from '../../../redux/actions/appAction'
import { Table, TableBody, TextField, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Autocomplete from '@material-ui/lab/Autocomplete';
import HttpService from '../../../services/httpService';
import { ROLENAME } from '../../../utils';
import { TablePagination } from '@material-ui/core';
import { Https } from '@material-ui/icons';
import { Checkbox, FormControl, FormControlLabel, Radio, RadioGroup, Select, InputLabel, MenuItem } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 850,
  }
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#ccc",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ManageItems = (props) => {
  const classes = useStyles();


  const getAllMaterials = async () => {
    props.setLoading(true)
    try {
      await props.getAllMaterials()
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.messsage ?? "Error fetching materials", "error")
    }
    props.setLoading(false)
  }
  const { allMaterials } = useSelector(state => state.vendor)
  const { subCategory } = useSelector(state => state.project)
  const [showAddMaterialModal, setShowAddMaterialModal] = useState(false)
  const [selectedSubCategory, setSelectedSubCategory] = useState({})
  const [selectedTypes, setSelectedTypes] = useState([])
  const [materialDetails, setMaterialDetails] = useState({
    id: "",
    name: "",
    code: "",
    aliasName: "",
    type : [],
    types : ""

  })
  let { name, code, aliasName ,type,types, isCommonBomItem} = materialDetails
  
  const [isEdit, setIsEdit] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [allMaterialsData,setAllMaterialData] = useState([])
  const [deleteId, setDeleteId] = useState("")
  const { userDetails } = useSelector(state => state.user)
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(20)
  
  const [moqEditValue, setMoqEditValue] = useState(100)
  const [nudge, setNudge] = useState(false)
  const [searchBoxOptions, setSearchBoxOptions] = useState([])
  const [selectedFromSearchBox, setSelectedFromSearchBox] = useState([])
  const [displayArray, setDisplayArray] = useState([])
  const openAddMaterialModal = async () => {
    props.setLoading(true)
    try {
      await props.getAllSubCategory()
      setIsEdit(false);
      setShowAddMaterialModal(true);
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.messsage ?? "Error fetching sub category", "error")
    }
    props.setLoading(false)
  }

  const handleDialogClose = () => {
    setSelectedSubCategory({})
    setShowAddMaterialModal(false)
  }

  const getSelectedSearchMaterialData = async (values) => {
    props.setLoading(true)
    try{
      const idArr = values.map((el)=>el._id);
      const response = await HttpService.getSelectedSearchMaterialData(idArr);
      setDisplayArray(response.data)
    }
    catch(err){
      console.log(err)
    }
    props.setLoading(false)
  }
  const getAllMaterialsNameAndCode = async () => {
    props.setLoading(true)
    try{
      const response = await HttpService.getAllMaterialsNameAndCode();
      setSearchBoxOptions(response.data)
    }
    catch(err){
      console.log(err)
    }
    props.setLoading(false)
  }


  const addMaterial = async () => {
    props.setLoading(true)
    aliasName = `${name}-${aliasName}`
    try {
      let obj = {
        code,
        name,
        aliasName,
        type,
        types,
        subCategoryId: selectedSubCategory._id,
        isActive: true,
        isCommonBomItem: isCommonBomItem,
        // moq: moqEditValue
      }
      if(userDetails?.roles?.find(role => role.name === ROLENAME.Admin)){
        obj.moq = moqEditValue
      }
      const result = await HttpService.addMaterial(obj)
      props.setSnackInfo(result.data, "success")
      setShowAddMaterialModal(false);
      setNudge(!nudge)
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }
  
  const updateMaterial = async () => {
    props.setLoading(true)
    aliasName = `${name}-${aliasName}`
    try {
      let obj = {
        code,
        name,
        aliasName,
        type,
        types,
        subCategoryId: selectedSubCategory._id,
        isActive: true,
        isCommonBomItem: isCommonBomItem,
        // moq: moqEditValue
      }
      if(userDetails?.roles?.find(role => role.name === ROLENAME.Admin)){
        obj.moq = moqEditValue
      }
      const result = await HttpService.updateMaterial(obj, materialDetails.id)
      props.setSnackInfo(result.data, "success")
      setShowAddMaterialModal(false);
      setSelectedFromSearchBox([])
      setNudge(!nudge)
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  const editMaterial = (data) => {
    setIsEdit(true)
    setMaterialDetails({ name: data?.name, code: data?.code, id: data?._id, aliasName: data?.aliasName , type: data?.type, isCommonBomItem: data?.isCommonBomItem, types: data?.types,})
    setSelectedSubCategory(data?.subCategoryId)
    setShowAddMaterialModal(true)
  }
  
  const closeDeleteModal = () => { setShowDeleteModal(false); setDeleteId("") }

  const deleteMaterial = async () => {
    props.setLoading(true)
    try {
      await HttpService.deleteMaterial(deleteId)
      setShowDeleteModal(false)
      setDeleteId("")
      getAllMaterials()
      props.setSnackInfo("Deleted", "success")
    } catch (error) {
      console.log(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  const getAllLimitMaterials = async (limit, page) => {
    props.setLoading(true);
    try {
      // Call the backend API with the provided limit and page parameters
      const res = await HttpService.getAllMaterial(limit, page);
      setAllMaterialData(res.data)
      setDisplayArray(res.data)    
      // Process the response as needed
    } catch (error) {
      console.error(error);
      props.setSnackInfo(error?.response?.data?.message ?? "Error fetching materials", "error");
    }
    props.setLoading(false);
  };
  
    //pagination function  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };
  useEffect(() => {
    getAllMaterials()
    getAllLimitMaterials(limit,page);
    getAllMaterialsNameAndCode()
    //eslint-disable-next-line
  }, [limit,page,nudge])

  let allTypes = [
    { name: "factory" },
    { name: "store" },
  ];
  
  const handleOnChange = (event, newValue) => {
    setSelectedTypes(newValue);
    // Extract the "name" values from the newValue array
    const selectedTypeNames = newValue.map((option) => option.name);

    // Convert the array of names to a comma-separated string
    const typesAsString = selectedTypeNames.join(", ");

    // Update the materialDetails with the comma-separated string
    setMaterialDetails({ ...materialDetails, type: selectedTypeNames, types: typesAsString });
  };

    // Filter the available options by excluding the selected ones
    const availableOptions = allTypes.filter(
      (option) => !selectedTypes.find((selectedOption) => selectedOption.name === option.name)
    );

    const handleChange = (event,values) => {
      setSelectedFromSearchBox(values);
      if(values.length!=0){
        getSelectedSearchMaterialData(values)
      }
      else {
      setDisplayArray(allMaterialsData)
      }
    }

  return (
    <div>
      <Autocomplete
						multiple
						onChange={(e, v) => {
							handleChange(e,v)
						}}
						id="projects-dropdown"
            value={selectedFromSearchBox}
						options={searchBoxOptions}
						getOptionLabel={(option) => option.name}
						style={{ width: 600 ,margin: 10 }}
						filterSelectedOptions
						renderInput={(params) => (
							<TextField
								{...params}
								variant="outlined"
								label="Select To View Material"
								placeholder="Select To View Material"
							/>
						)}
					/>
      <div style={{ marginBottom: 10, fontSize: 18 }}><b>Manage Materials</b></div>
      <div style={{ display: "flex", justifyContent: "flex-end" }} ><Button style={{ cursor: "pointer" }} onClick={openAddMaterialModal} variant="contained" color="primary" >Add Material</Button></div>
      <div style={{ marginBottom: 10 }}></div>
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Sl No</StyledTableCell>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>DP Code</StyledTableCell>
              <StyledTableCell>External Code</StyledTableCell>
              <StyledTableCell>Scopes</StyledTableCell>
              <StyledTableCell>Sub Category</StyledTableCell>
              <StyledTableCell>Types</StyledTableCell>
              <StyledTableCell>Common Bom Item</StyledTableCell>
              <StyledTableCell>MOQ</StyledTableCell>
              {/* <StyledTableCell align="center">Stocks
                <StyledTableRow>
                  <StyledTableCell>Factory</StyledTableCell>
                  <StyledTableCell>Store</StyledTableCell>
                </StyledTableRow>
              </StyledTableCell> */}
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
              {displayArray?.map((data, i) => <StyledTableRow key={i + 1}>
              <StyledTableCell>{i + 1}</StyledTableCell>
              <StyledTableCell>{data?.name}</StyledTableCell>
              <StyledTableCell>{data?.code}</StyledTableCell>
              <StyledTableCell>{data?.aliasName}</StyledTableCell>
              <StyledTableCell>{data?.subCategoryId?.scopeId?.map(scope => scope.name)}</StyledTableCell>
              <StyledTableCell>{data?.subCategoryId?.name}</StyledTableCell>
              <StyledTableCell>{data?.types ? data.types : "NA"}</StyledTableCell>
              <StyledTableCell>{data?.isCommonBomItem ? 'Yes' : "No"}</StyledTableCell>
              <StyledTableCell>{data?.moq}</StyledTableCell>
              {/* <StyledTableCell><StyledTableRow>
                <StyledTableCell>{data?.stocks.factory.available}</StyledTableCell>
                <StyledTableCell>{data?.stocks.store.available}</StyledTableCell>
              </StyledTableRow></StyledTableCell> */}
              <StyledTableCell style={{ cursor: "pointer" }}>
                <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                  <Tooltip title="Edit"><EditOutlined style={{ cursor: "pointer" }} onClick={() => editMaterial(data)} /></Tooltip>
                  <Tooltip title="Delete" onClick={() => { setShowDeleteModal(true); setDeleteId(data?._id) }} ><DeleteOutlined /></Tooltip>
                </div>
              </StyledTableCell>
            </StyledTableRow>)}
          </TableBody>
        </Table>
      </TableContainer >

      {selectedFromSearchBox.length==0 && <TablePagination
        rowsPerPageOptions={[20,50,100,250,500, { label: "All", value: allMaterials.length }]}
        component="div"
        count={allMaterials.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={limit}
        onRowsPerPageChange={handleChangeRowsPerPage}
    />
      }

      <Dialog
        open={showAddMaterialModal}
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth={'sm'}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{`${isEdit ? 'Edit' : 'Add New'} Material`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <TextField label="Name" name="name" value={name} style={{ marginBottom: 10 }} fullWidth onChange={(e) => setMaterialDetails({ ...materialDetails, [e.target.name]: e.target.value })} variant="outlined" />
            <TextField label="DP Code" name="code" value={code} style={{ marginBottom: 10 }} fullWidth onChange={(e) => setMaterialDetails({ ...materialDetails, [e.target.name]: e.target.value })} variant="outlined" />
            <TextField label="External Code" name="aliasName" value={aliasName} style={{ marginBottom: 10 }} fullWidth onChange={(e) => setMaterialDetails({ ...materialDetails, [e.target.name]: e.target.value })} variant="outlined" />
            <Autocomplete
              style={{ marginBottom: 10 }}
              value={selectedSubCategory}
              id="tags-standard"
              options={subCategory}
              onChange={(e, v) => setSelectedSubCategory(v)}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} fullWidth label="Select Sub Category" variant="outlined" />
              )}
            />
            <Autocomplete
              multiple
              value={selectedTypes}
              options={availableOptions}
              onChange={handleOnChange}
              getOptionLabel={(option) => option?.name}
              renderInput={(params) => (
                <TextField {...params} fullWidth label="Select Types" variant="outlined" />
              )}
            />
            <br />
            <FormControl component="fieldset">
              
              <span>Is it a Common BOM Item?</span>
              <RadioGroup aria-label="isCommonBomItem" name="isCommonBomItem" value={materialDetails.isCommonBomItem ? "Yes" : "No"} onChange={(e) => setMaterialDetails({ ...materialDetails, [e.target.name]: e.target.value === "Yes" ? true : false })}>
              {/* <RadioGroup aria-label="isCommonBomItem" name="isCommonBomItem" value={isCommonBomItem} onChange={(e) => console.log('name', e.target.name, 'value', e.target.value)}> */}
              
                <div className='gst-type-radio'>
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </div>
              </RadioGroup>
					  </FormControl>
               {/* <Autocomplete
                  style={{ marginBottom: 10 }}
                  multiple
                  value={selectedTypes}
                  options={availableOptions}
                  onChange={handleOnChange}
                  getOptionLabel={(option) => option?.name}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth label="Select Types" variant="outlined" />
                  )}
                /> */}
                {userDetails?.roles?.find(role => role.name === ROLENAME.Admin) && <TextField
              label="MOQ"
              name="MOQ"
              value={moqEditValue}
              style={{ marginBottom: 10 }}
              fullWidth
              onChange={(e) => {
                const inputValue = e.target.value;
                if (!isNaN(inputValue) && Number(inputValue) >= 0) {
                  setMoqEditValue(inputValue);
                }
              }}
              variant="outlined"
            /> }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {!isEdit ? <Button color="primary" onClick={addMaterial} >
            Add
          </Button> :
            <Button color="primary" onClick={updateMaterial} >
              Update
            </Button>}
        </DialogActions>
      </Dialog>

      <Dialog
        open={showDeleteModal}
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth={'sm'}
        onClose={closeDeleteModal}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Delete Material</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={closeDeleteModal}>
            Cancel
          </Button>
          <Button color="primary" onClick={() => deleteMaterial()}>
            Delete
          </Button>
        </DialogActions>

      </Dialog>
    </div >
  )
}

export default connect(null, { setLoading, setSnackInfo, getAllMaterials, getAllSubCategory })(ManageItems)
