import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { setLoading, setSnackInfo } from '../../../redux/actions/appAction';
import HttpService from '../../../services/httpService';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getAllProjects } from '../../../redux/actions/projectAction'
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@material-ui/core';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}
TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 600,
  },
  textField: {
    width: 500
  }
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#ccc",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);



const ManageProjectRate = (props) => {
  const classes = useStyles();

  useEffect(() => {
    getAllProjects()
    getOrgRateCards()
    // eslint-disable-next-line
  }, [])

  const [projectList, setProjectList] = useState([]);
  const getAllProjects = async () => {
    props.setLoading(true)
    try {
      const allLead = await HttpService.getAllLeadsForChangeRate()
      let newLeadList = []
      allLead.data.forEach(ele => {
        let newLeadName = ele.lead_no + '_' + ele?.customerId?.name;
        newLeadList.push({ name: newLeadName, _id: ele._id })
      })
      setProjectList(newLeadList)
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something Went Wrong", "error")
    }
    props.setLoading(false)
  }

  const { projects } = useSelector(state => state.project)
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedLead, setSelectedLead] = useState({})
  const [projectRateCards, setProjectRateCards] = useState([])
  const [dupProjectRateCardList, setdupProjectRateCardList] = useState([]);
  const [selectedRateCardCode, setSelectedRateCardCode] = useState('');
  const [orgRateCards, setOrgRateCards] = useState([]);
  const [selectedLeadId, setSelectedLeadId] = useState('');
  const [getRateCard, setGetrateCard] = useState('')

  const handleSelect = async (event, value) => {
    if (value?._id) {
      setSelectedLead(value)
      getProjectRateCard(value)
      defaultRateCard(value)
    }
    // getProjectDocuments(value?._id)
  }

  const getProjectRateCard = async (project) => {
    props.setLoading(true)
    try {
      const response = await HttpService.getProjectRateCard(project?._id)
      setProjectRateCards(response.data)
      setdupProjectRateCardList(response.data)
      let localRate = [...rate]
      response.data.map((orgRate, i) => {
        localRate[i] = orgRate.rate
        return null
      })
      setRate(localRate)
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something Went Wrong", "error")
    }
    props.setLoading(false)
  }

  const defaultRateCard = async (leadId) => {
    try {
      const response = await HttpService.getDefaultProjectRateCardMaster(leadId._id)
      setSelectedRateCardCode(response.data.rateCardId)
      setGetrateCard(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [tabvalue, setValue] = useState(0);
  const tabName = ['Items', 'Finishes', 'Ply Type'];
  const [rate, setRate] = useState([])

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    let filterName = "";
    if (newValue === 0) {
      filterName = "ProductMaster"
    }
    if (newValue === 1) {
      filterName = "FinishTypeMaster"
    }
    if (newValue === 2) {
      filterName = "PlyTypeMaster"
    }
    const filterData = dupProjectRateCardList.filter(orgData => orgData.docType === filterName)
    setProjectRateCards(filterData)
    let localRate = [...rate]
    filterData.map((orgRate, i) => {
      localRate[i] = orgRate.rate
      return null
    })
    setRate(localRate)
  };

  const handleRateChange = (e, i) => {
    let localRate = [...rate]
    localRate[i + page * rowsPerPage] = +e.target.value
    setRate(localRate)
  }

  const searchHandler = (e) => {
    let list = dupProjectRateCardList.filter(orgRateCard => orgRateCard?.itemId.name.toLowerCase().includes(e.target.value.toLowerCase()))
    setProjectRateCards(list)
    let localRate = [...rate]
    list.map((orgRate, i) => {
      localRate[i] = orgRate.rate
      return null
    })
    setRate(localRate)
  }

  const getRate = (i) => {
    const item = rate[i + page * rowsPerPage]
    return item || ""
  }
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, projectRateCards.length - page * rowsPerPage)

  const updateProjectRate = async () => {
    props.setLoading(true)
    try {
      let projectRateCard = projectRateCards.map((projectRate, i) => {
        return {
          itemId: projectRate.itemId,
          rate: rate[i]
        }
      })
      const response = await HttpService.updateProjectRatePrices(projectRateCard, selectedLead._id)
      props.setSnackInfo(response.data, "success")
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  const getOrgRateCards = async () => {
    try {
      const response = await HttpService.allRateCardsMaster()
      setOrgRateCards(response.data)
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
  }
  const selectedRateCard = (e) => {
    setSelectedRateCardCode(e.target.value)
  }
  const updateratecard = async () => {
    let obj = {
      leadId: selectedLead._id,
      rateCardId: selectedRateCardCode
    }
    try {
      const response = await HttpService.update_project_rate_card(obj);
      props.setSnackInfo(response.data)
      window.location.reload(false);
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
  }
  return (
    <div>
      <div style={{ marginBottom: 10, fontSize: 18 }}><b>Manage Lead Rate</b></div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Autocomplete
          value={selectedLead}
          onChange={handleSelect}
          id="project-code-dropdown"
          options={projectList}
          getOptionLabel={(option) => option.name}
          style={{ width: 500 }}
          renderInput={(params) => <TextField {...params} label="Select Lead" fullWidth variant="outlined" />}
        />
      </div>

      {selectedLead?._id &&
        <div style={{ marginBottom: 10, marginTop: 10 }}>
          <TextField label="Search" className={classes.textField} onChange={searchHandler} variant="outlined" />
        </div>}
      <TextField
        select
        onChange={selectedRateCard}
        labelId="demo-simple-select-label"
        id="demo-expcenter-simple-select"
        name="rateCard"
        label="Select Rate Card"
        value={selectedRateCardCode}
        variant="outlined"
        style={{ width: 500, marginBottom: 10, marginTop: 10 }}
      >
        {
          orgRateCards?.map((data, i) => (
            <MenuItem value={data?._id} key={i + 1}>{data?.rateCardCode + " - " + data?.locationId.name}</MenuItem>
          ))
        }
      </TextField>
      <Button variant="contained" color="primary" style={{ background: "#34bf73", margin: 15 }} onClick={updateratecard} disabled={!selectedRateCardCode}>
        Update Rate Card
      </Button>
      {projectRateCards?.length > 0 &&
        <Paper className={classes.root}>
          <AppBar position="static">
            <Tabs value={tabvalue} onChange={handleTabChange} aria-label="simple tabs example" centered>
              {
                tabName.map((tn, i) => (
                  <Tab label={tn} {...a11yProps(i)} />
                ))
              }
            </Tabs>
          </AppBar>
          {
            tabName.map((tn, i) => (
              <TabPanel value={tabvalue} index={i}>
                <TableContainer component={Paper}>
                  <Table className={classes.table} size="small" aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Sl No</StyledTableCell>
                        <StyledTableCell>Name</StyledTableCell>
                        <StyledTableCell>Rate</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(rowsPerPage > 0
                        ? projectRateCards.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : projectRateCards
                      )?.map((data, i) => <StyledTableRow key={i + 1}>
                        <StyledTableCell>{i + 1}</StyledTableCell>
                        <StyledTableCell>{data?.itemId?.name}</StyledTableCell>
                        <TextField value={getRate(i)} type="number" onChange={(e) => handleRateChange(e, i)} fullWidth variant="outlined" />
                      </StyledTableRow>)}

                      {emptyRows > 0 && (
                        <StyledTableRow style={{ height: 53 * emptyRows }}>
                          <StyledTableCell colSpan={8} />
                        </StyledTableRow>
                      )}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[10, 20, 50, { label: 'All', value: -1 }]}
                          colSpan={8}
                          count={projectRateCards.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          SelectProps={{
                            inputProps: { 'aria-label': 'rows per page' },
                            native: true,
                          }}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          ActionsComponent={TablePaginationActions}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
                <div style={{ display: "flex", flexDirection: "row-reverse", marginTop: 15 }} >
                  <Button variant="contained" color="primary" onClick={updateProjectRate} style={{ background: "#716ACA", margin: 5 }} >
                    Update
                  </Button>
                </div>
              </TabPanel>
            ))}
        </Paper>
      }
    </div>
  )
}

export default connect(null, { setLoading, setSnackInfo, getAllProjects })(ManageProjectRate)
