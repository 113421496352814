import React from "react";
import { connect } from "react-redux";
import { Grid, Button } from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import { setLoading, setSnackInfo } from "../redux/actions/appAction";
import { softwate } from "../utils";
import { makeStyles } from '@material-ui/core/styles';
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ReactPlayer from 'react-player'

import "../App.css";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%"
  },
  // media: {
  //   height: 0,
  //   paddingTop: '56.25%', // 16:9
  // },
}))
const DownloadCenter = (props) => {
  const classes = useStyles();
  const handleDownload = (url) => {
    window.open(url, "_blank");
  }
  return (
<Grid container spacing={2} justify="center" style={{ display: 'flex', alignItems: 'stretch' }}>
       <Grid item xs={6}>
        <Card className={classes.root}>
        <CardActionArea>
            {/* <ReactPlayer url={softwate.naraciMacVideo} playing={false}  controls  width='100%'  height='30vh'  /> */}
            <CardActions>
            <CloudDownloadIcon onClick={() => handleDownload(softwate.naraciMac)} />
            <Typography>
              Naraci For MacBook
            </Typography>
            </CardActions>
          </CardActionArea>
        </Card>
      </Grid>

      <Grid item xs={6}>
        <Card className={classes.root}>
        <CardActionArea>
            {/* <ReactPlayer url={softwate.naraciWindowsVideo} playing={false}  controls  width='100%'  height='30vh'  /> */}
            <CardActions>
            <CloudDownloadIcon onClick={() =>handleDownload(softwate.naraciWindows)} />
            <Typography>
              Naraci For Windows
            </Typography>
            </CardActions>
          </CardActionArea>
        </Card>
      </Grid>
    </Grid>
  );
};

export default connect(null, { setLoading, setSnackInfo })(DownloadCenter);