import React, { useState, useEffect, useRef } from 'react';
import HttpService from '../../services/httpService';
import TablePagination from '@material-ui/core/TablePagination';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tooltip, Button } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { Link } from "react-router-dom";
import { setLoading, setSnackInfo } from "../../redux/actions/appAction";
import { CSVLink } from "react-csv";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    bordercolors: {
        border: '1px solid #eee5e5',
    },
    table: {
        minWidth: 700,
    },
    root: {
        width: '100%',
    },
    title: {
        fontSize: 14,
    },
    customTableContainer: {
        overflowX: "initial"
    },
    pos: {
        marginBottom: 12,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginpivotRight: theme.spacing(1),
        width: 200,
    },
    table: {
        width: "fit-content",
    },
    csv: {
        display: "flex",
        alignItems: "center",
        marginBottom: 20,
    },
    report: {
        marginBottom: 20,
        alignItems: "center",
        justifyContent: "center",
    },
}));

function CustomerLoginActivity(props) {
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [totalPages, setTotalPages] = useState(0);
    const [csvData, setCsvData] = useState([]);
    const csvButtonRef = useRef(null);

    useEffect(() => {
        fetchData();
    }, [page, rowsPerPage]);

    const fetchData = async () => {
        try {
            const response = await HttpService.getcustomerSessionsData(page + 1, rowsPerPage);
            setData(response.data.items);
            setTotalPages(response.data.totalPages);
        } catch (err) {
            console.error(err);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const headers = [
        { key: "serialNo", label: "Sl. No." },
        { key: "projectCode", label: "Project No." },
        { key: "leadNo", label: "Lead No" },
        { key: "customerName", label: "Client Name" },
        { key: "customerEmail", label: "Email" },
        { key: "customerContact", label: "Contact No" },
        { key: "currentStage", label: "Stage" },
        { key: "expCtrName", label: "Exp Ctr Name" },
        { key: "signIn", label: "Sign In" },
        { key: "signOut", label: "Sign Out" },
    ];


    const csvDownloadHandler = async () => {
        setLoading(true);
        try {
            let csvArray = [];
            let serialNo = 1;
            for (let customer of data) {
                const baseInfo = { // Base customer and lead info that doesn't change per session
                    projectCode: customer?.leadId?.erpProjectNo || "N/A",
                    leadNo: customer?.leadId?.lead_no,
                    customerName: customer?.customerId?.name,
                    customerEmail: customer?.customerId?.email,
                    customerContact: customer?.customerId?.contact_no,
                    currentStage: customer?.leadId?.currentStage,
                    expCtrName: customer?.leadId?.experienceCenterId?.name
                };
    
                // If there are sessions, create a row for each
                if (customer.sessions && customer.sessions.length > 0) {
                    for (let i = customer.sessions.length - 1; i >= 0; i--) {
                        let session = customer.sessions[i];
                        let sessionObj = {
                            ...baseInfo, 
                            serialNo: serialNo++,
                            signIn: formatToIST(session.signIn),
                            signOut: session.signOut ? formatToIST(session.signOut) : 'N/A', 
                        };
                        csvArray.push(sessionObj);
                    }
                } else { 
                    csvArray.push({ ...baseInfo, serialNo: serialNo++, signIn: 'N/A', signOut: 'N/A' });
                }
            }
            setCsvData(csvArray);
            downloadExcel();
        } catch (error) {
            console.log("error", error);
            props?.setSnackInfo(
                error?.response?.data?.message ?? "Something went wrong",
                "error"
            );
        }
        setLoading(false);
    };
    
    const downloadExcel = () => {
        setLoading(true);
        try {
            setTimeout(() => {
                csvButtonRef.current.link.click();
            });
        } catch (err) {
            console.error(err.message);
        }
        setLoading(false);
    };


    return (
        <div>
            <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>Customer Activity Log</h1>
            <div className={classes.csv}>
                <CSVLink
                    headers={headers}
                    filename={`customer_activity_log_${page + 1}.csv`}
                    data={csvData}
                    ref={csvButtonRef}
                />
                <Button
                    onClick={csvDownloadHandler}
                    style={{ backgroundColor: "green" }}
                    variant="contained"
                    color="secondary"
                >
                    Download CSV
                </Button>
            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>S.No</TableCell>
                            <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Lead No</TableCell>
                            <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Name</TableCell>
                            <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Email</TableCell>
                            <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Stage</TableCell>
                            <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Exp Center</TableCell>
                            <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Activity Log</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>{index + 1}</TableCell>
                                <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>
                                    <Link to={`/project-logs/${item?.leadId?._id}`} style={{ color: "#000", textDecoration: 'underline' }}>{item?.leadId?.lead_no || "N/A"}</Link>
                                </TableCell>
                                <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>{item?.customerId?.name}</TableCell>
                                <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>{item?.customerId?.email}</TableCell>
                                <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>{item?.leadId?.currentStage}</TableCell>
                                <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>{item?.leadId?.experienceCenterId?.name}</TableCell>
                                <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>
                                    <span><strong>{formatToIST(item?.sessions[item.sessions.length - 1]?.signIn)}</strong></span><br />
                                    <span><strong> {item?.sessions[item.sessions.length - 1]?.signOut ? formatToIST(item?.sessions[item.sessions.length - 1]?.signOut) : 'Active'}</strong></span>
                                    <Tooltip title={<HoveredRowPopup session={item} />} placement="right" interactive>
                                        <InfoIcon />
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[100, 200, 400]}
                component="div"
                count={totalPages * rowsPerPage}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                style={{ marginTop: '20px' }}
            />
        </div>
    );
}

function formatToIST(isoDate) {
    const date = new Date(isoDate);
    const options = { timeZone: 'Asia/Kolkata' };
    return date.toLocaleString('en-IN', options);
}

function HoveredRowPopup({ session }) {
    if (!session) return null;

    const sessions = session.sessions.map((session, index) => (
        <div key={index} style={{ marginBottom: '20px' }}>
            <strong style={{ marginBottom: '10px' }}>Session {index + 1}:</strong><br />
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
                <span style={{ marginBottom: '5px' }}>In: <span style={{ marginLeft: '5px', marginTop: '10px' }}><strong>{formatToIST(session?.signIn)}</strong></span></span>
                <span style={{ marginBottom: '5px' }}>Out: <span style={{ marginLeft: '5px', marginTop: '10px' }}><strong>{session.signOut ? formatToIST(session?.signOut) : 'Active'}</strong></span></span>
            </div>
        </div>
    ));

    return (
        <div style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px', maxHeight: '200px', overflowY: 'auto', padding: '10px' }}>
            <strong>Name:</strong> {session?.customerId?.name}<br />
            <div style={{ marginTop: '10px' }}>{sessions}</div>
        </div>
    );
}

export default CustomerLoginActivity;
