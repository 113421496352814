import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { setLoading, setSnackInfo } from '../../../redux/actions/appAction'
import HttpService from '../../../services/httpService'
import { connect } from 'react-redux'
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { Paper, Button, Table, TableHead, TableBody, TableRow, TableCell, TableContainer, TableFooter, TablePagination } from '@material-ui/core';
import { DownloadOutlined } from '@ant-design/icons';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { TextField } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import FormControl from '@material-ui/core/FormControl';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment';
import { ROLENAME } from '../../../utils/index';
import './index.css'
import { Link } from 'react-router-dom'



const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) =>
({
	root: { flexShrink: 0, marginLeft: theme.spacing(2.5) },
	heading: { margin: 'auto', textAlign: "center", marginBottom: '1%', color: "white", backgroundColor: "#2C2E3E", padding: 10, borderRadius: 50, width: "30%" },
	table: { minWidth: 500 },
	textField: { width: "150px" },
	excelDownloadButton: { backgroundColor: '#19571b', margin: 5, height: '40px', width: "1px", borderRadius: 50, fontSize: '150%', "&:hover": { backgroundColor: "white", color: '#19571b' } },
	sawDownloadButton: { backgroundColor: '#141d5c', margin: 5, height: '40px', width: "1px", borderRadius: 50, fontSize: '150%', "&:hover": { backgroundColor: "white", color: '#141d5c' } }
}));
const OptimisationFiles = (props) => {
	const classes = useStyles();
	const [optimisationFiles, setOptimisationFiles] = useState([])
	const { userDetails } = useSelector(state => state.user)
	const [excelFileUpload, setExcelFIleUpload] = useState(false);
	const [searchField, setSearchField] = useState("")
	const [sawfileUpload, setSawFileUpload] = useState(false);
	const [pastingFileUpload, setpastingFileUpload] = useState(false);
	const [selectedProject, setSelectedProject] = useState([])
	const [approvedProjects, setApprovedProjects] = useState([]);
	const [editOptimisationFile, setEditOptismisationFile] = useState({
		excelFileLink: "",
		sawFileLink: "",
		pastingFile:"",
		projects: [],
	})
	const [isEdit, setIsEdit] = useState(false)
	const [openModal, setOpenModal] = useState(false);



	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	useEffect(() => {
		getOptimisationFiles()
		getApprovedProjects()
	}, [])

	const getOptimisationFiles = async () => {
		props.setLoading(true)
		try {
			let response = await HttpService.getOptimisationFiles()
			setOptimisationFiles(response.data.optimisationFiles)
		}
		catch (error) {
			console.error("error", error)
		}
		props.setLoading(false)
	}



	const StyledTableRow = withStyles((theme) =>
	({
		root: { '&:nth-of-type(odd)': { backgroundColor: theme.palette.action.hover } }
	}))
		(TableRow);

	const StyledTableCell = withStyles((theme) =>
	({
		head: { backgroundColor: "#ccc", color: theme.palette.common.black },
		body: { fontSize: 14 }
	}))
		(TableCell);

	let j = 1;

	const editOptismisationFile = (data) => {
		setOpenModal(true)
		let totalPro = []
		let val = data.projectsIds
		for(let i =0 ; i < val.length;i++){
			totalPro.push({
				searchName : val[i].projectNumber + "_" + val[i].clientName,
				_id: val[i]._id,
		})
		}
		setSelectedProject(totalPro)
		setEditOptismisationFile({
			excelFileLink: data.excelFileLink,
			sawFileLink: data.sawFileLink,
			pastingFile: data.pastingFile,
			_id : data._id
		})
		
	}

	const onImageChange = async (e) => {
		const { name, value } = e.target
		props.setLoading(true)
		const fd = new FormData()
		fd.append('file', e.target.files[0])
		fd.append('customerName', "floor" + '_' + "plan")
		try {
			const fileUploadRes = await HttpService.uploadFile(fd);
			setEditOptismisationFile({
				...editOptimisationFile,
				[name]: fileUploadRes.data
			})
			if ([name][0] === "excelFileLink") {
				setExcelFIleUpload(true);
			}
			else if ([name][0] === "sawFileLink") {
				setSawFileUpload(true)
			} else if ([name][0] === 'pastingFile'){
				setpastingFileUpload(true)
			}
		} catch (err) {
			props.setSnackInfo(err?.response?.data ?? "Something went wrong", "error")
		}
		props.setLoading(false)
	}


	const getApprovedProjects = async () => {
		try {
			props.setLoading(true)
			const response = await HttpService.getApprovedProjects()
			setApprovedProjects(response.data)
			props.setLoading(false)
		} catch (error) {
			props.setLoading(false)
			props.setSnackInfo(error.response.data.message, "error")
		}
	}


	const handleAddProject = async (event, value) => {
			setSelectedProject(value)
	}

	const UpdateData = async () =>{
		let arr = []
		for (let i = 0; i < selectedProject.length;i++){
			arr.push(selectedProject[i]._id)
		}
		let obj = {
			...editOptimisationFile,
			projects: arr,
			uploadedBy : userDetails._id,
		}
		props.setLoading(true)
		try {
			let response = await HttpService.updateOptimizedSingleFile(obj._id,obj)
			props.setSnackInfo("Updated Successfully", "success")
			setOpenModal(false)
			setpastingFileUpload(false)
			getOptimisationFiles()
		}
		catch (error) {
			console.error("error", error)
		}
		props.setLoading(false)
		
	}

	const searchProject = (e) => {
        setSearchField(e.target.value);
        const searchQuery = String(e.target.value).toLowerCase();
        let filteredData = [];
        for (let i = 0; i < optimisationFiles.length; i++) {
            if (String(optimisationFiles[i].projects).toLowerCase().includes(searchQuery)) {
                filteredData.push(optimisationFiles[i]);
                setOptimisationFiles(filteredData);
            }
        }
        if (e.target.value === "") {
            getOptimisationFiles()
        }
    };
	return (
		<div >
			  <TextField
                    label="Search Project"
                    type="text"
                    value={searchField}
                    style={{ marginBottom: 10, marginTop: 15, marginRight: 10, float: "right" }}
                    name="searchField"
                    onChange={searchProject}
                    variant="outlined"
                />
			<h1 className={classes.heading}>All Optimisation Files</h1>
			<TableContainer component={Paper}>
				<Table className={classes.table} size="small" aria-label="custom pagination table">
					<TableHead>
						<TableRow>
							<StyledTableCell style={{ textAlign: 'center' }}>S.No.</StyledTableCell>
							<StyledTableCell style={{ textAlign: 'center' }}>Optimization Number</StyledTableCell>
							<StyledTableCell style={{ textAlign: 'center' }}>Projects</StyledTableCell>
							<StyledTableCell style={{ textAlign: 'center' }}>Uploaded By</StyledTableCell>
							<StyledTableCell style={{ textAlign: 'center' }}>Updated At</StyledTableCell>
							<StyledTableCell style={{ textAlign: 'center' }}>Excel File</StyledTableCell>
							<StyledTableCell style={{ textAlign: 'center' }}>Saw File</StyledTableCell>
							<StyledTableCell style={{ textAlign: 'center' }}>Pasting File</StyledTableCell>
							{
									userDetails.roles.find(role => role.name !== ROLENAME.BOMManager && role.name !== ROLENAME.ProcurementExecutive) &&
									<StyledTableCell style={{ textAlign: 'center' }}>Action</StyledTableCell>
                            }
						</TableRow>
					</TableHead>
					<TableBody>
						{optimisationFiles.map((data, i) =>
						(<>
								<StyledTableRow key={j++}>
									<StyledTableCell style={{ textAlign: 'center' }}>{j}</StyledTableCell>
									<StyledTableCell style={{ textAlign: 'center' }}>{data?.optimizationNumber}</StyledTableCell>
									<StyledTableCell>
									{data.projectsIds.map((el) =>
										<Link to={`/project-logs/${el?.leadId}`} style={{ color: "#000", textDecoration: 'underline', textAlign: 'center' }}>  {el?.projectNumber + ","}</Link>
									)}
									</StyledTableCell>
									<StyledTableCell style={{ textAlign: 'center' }}>{data?.uploadedBy}</StyledTableCell>
									<StyledTableCell style={{ textAlign: 'center' }}>{moment(data?.updatedAt).format('DD-MM-YYYY')}</StyledTableCell>

									<StyledTableCell style={{ textAlign: 'center' }}>
										{
											data.excelFileLink !== '' ?
												(<Button className={classes.excelDownloadButton} variant="contained" shape="round" color="primary" onClick={() => window.open(data.excelFileLink)}><DownloadOutlined /></Button>) :
												"NA"
										}
									</StyledTableCell>

									<StyledTableCell style={{ textAlign: 'center' }}>
										{
											data.sawFileLink !== '' ?
												(<Button className={classes.sawDownloadButton} variant="contained" shape="round" color="primary" onClick={() => window.open(data.sawFileLink)}><DownloadOutlined /></Button>) :
												"NA"
										}
									</StyledTableCell>
									<StyledTableCell style={{ textAlign: 'center' }}>
										{
											data.pastingFile !== '' ?
												(<Button className={classes.sawDownloadButton} variant="contained" shape="round" color="primary" onClick={() => window.open(data.pastingFile)}><DownloadOutlined /></Button>) :
												"NA"
										}
									</StyledTableCell>
									{
									userDetails.roles.find(role => role.name !== ROLENAME.BOMManager && role.name !== ROLENAME.ProcurementExecutive) &&
									<StyledTableCell  style={{ textAlign: "Center" }}>
										<EditIcon className='editbutton' onClick={() => editOptismisationFile(data)} />
									</StyledTableCell>
                                    }
								</StyledTableRow>
							</>
						))}
					</TableBody>
				</Table>
			</TableContainer>

			<Dialog open={openModal} onClose={() => setOpenModal(false)} aria-labelledby="form-dialog-title" maxWidth='lg'>
				<DialogContent>
					<DialogContentText id="alert-dialog-slide-description" style={{ display: "flex", flexDirection: "column" }}>
					{
					userDetails.roles.find(role => role.name === ROLENAME.Admin || role.name === ROLENAME.IMOSManager || role.name ===ROLENAME.IMOSUser) &&
                     <>
						<FormControl style={{ marginLeft: 10, marginRight: 10, marginTop: 20, marginBottom: 20, display: 'block' }}>
							<>
								<input
									accept=".xlsx, .xls, .csv"
									className={classes.input}
									id="contained-button-file"
									multiple
									type="file"
									onChange={onImageChange}
									style={{ display: 'none' }}
									name="excelFileLink"
								/>
								<label htmlFor="contained-button-file">
									<Button variant="contained" color="primary"
										component="span" startIcon={<CloudUploadIcon />}>
										Upload Excel File
									</Button>
									{
										excelFileUpload && <CheckCircleIcon style={{ verticalAlign: 'middle', marginLeft: 10, color: "green", fontSize: 30 }} />
									}
								</label>
							</>
						</FormControl>
						<FormControl style={{ marginLeft: 10, marginRight: 10, marginTop: 20, marginBottom: 30, display: 'block' }}>
							<>
								<input
									accept=".saw"
									className={classes.input}
									id="contained-button-file1"
									multiple
									type="file"
									onChange={onImageChange}
									style={{ display: 'none' }}
									name="sawFileLink"
								/>
								<label htmlFor="contained-button-file1">
									<Button variant="contained" color="primary"
										component="span" startIcon={<CloudUploadIcon />}>
										Upload Saw File
									</Button>
									{
										sawfileUpload && <CheckCircleIcon style={{ verticalAlign: 'middle', marginLeft: 10, color: "green", fontSize: 30 }} />
									}
								</label>
							</>
							{
								userDetails.roles.find(role => role.name === ROLENAME.Admin) &&
								<FormControl style={{ marginLeft: 10, marginRight: 10, marginTop: 20, marginBottom: 30, display: 'block' }}>
								<>
									<input
										accept="*"
										className={classes.input}
										id="contained-button-file2"
										multiple
										type="file"
										onChange={onImageChange}
										style={{ display: 'none' }}
										name="pastingFile"
									/>
									<label htmlFor="contained-button-file2">
										<Button variant="contained" color="primary"
											component="span" startIcon={<CloudUploadIcon />}>
											Upload Pasting File
										</Button>
										{
												pastingFileUpload && <CheckCircleIcon style={{ verticalAlign: 'middle', marginLeft: 10, color: "green", fontSize: 30 }} />
										}
									</label>
								</>
							</FormControl>
							}
						</FormControl>
						<Autocomplete
						multiple
						className={classes.projectInput}
						value={selectedProject}
						onChange={(e, v) => handleAddProject(e, v)}
						options={approvedProjects}
						getOptionLabel={(option) => option.searchName}
						renderInput={(params) => <TextField {...params} label="Tag Projects" fullWidth variant="outlined" />}
					/>
					</>
}
						{
							//diffrent role
							userDetails.roles.find(role => role.name === ROLENAME.FactoryBom || role.name === ROLENAME.FactoryManager) &&
							<>
								<FormControl style={{ marginLeft: 10, marginRight: 10, marginTop: 20, marginBottom: 30, display: 'block' }}>
								<>
									<input
										accept="*"
										className={classes.input}
										id="contained-button-file1"
										multiple
										type="file"
										onChange={onImageChange}
										style={{ display: 'none' }}
										name="pastingFile"
									/>
									<label htmlFor="contained-button-file1">
										<Button variant="contained" color="primary"
											component="span" startIcon={<CloudUploadIcon />}>
											Upload Pasting File
										</Button>
										{
												pastingFileUpload && <CheckCircleIcon style={{ verticalAlign: 'middle', marginLeft: 10, color: "green", fontSize: 30 }} />
										}
									</label>
								</>
							</FormControl>
							</>
						}
						
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button  onClick={UpdateData} color="primary">
						UPDATE
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}

export default connect(null, { setLoading, setSnackInfo })(OptimisationFiles)
