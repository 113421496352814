import React, { useState, useEffect } from 'react'
import { setLoading, setSnackInfo } from '../../redux/actions/appAction'
import { connect } from 'react-redux'
import { useHistory } from "react-router-dom";
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import { Typography, Paper, TextField, Button } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import FavoriteIcon from '@material-ui/icons/Favorite';
import '../customerSurveyForm/style.css';
import backImage1 from '../../assets/img/org/white-texture.webp';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    table: {
        minWidth: 700,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    textFieldWidth: {
        width: "100%"
    }, 
    pos: {
        marginBottom: 12,
    },
    container: {
        maxHeight: 450,
    },
    listStyleInfo: {
        fontSize: "17px",
        marginBottom: 5
    }
}))
const RealFormDetails = (props) => {
    const classes = useStyles();
    const history=useHistory();

    useEffect(() => {
        getRowData(props.location.state.data, props.location.state.type)
        // eslint-disable-next-line
    }, [props.location.state.data, props.location.state.type])


    const [realFormData, setRealFormData] = useState({
        feedback: "",
        howSatisfiedWithInteraction: "",
        satisfactionIndexRatio: []
    })
    const { feedback, howSatisfiedWithInteraction, satisfactionIndexRatio } = realFormData;

    const [junkFormData, setJunkFormData] = useState({
        satisfactionIndexRatioJunk: [],
        whenFlatAvailbleForInterior: "",
        budgetForInteriors: "",
        feedbackJunk: "",
        conveyedMessage: ""
    })
    const { whenFlatAvailbleForInterior, budgetForInteriors, satisfactionIndexRatioJunk, feedbackJunk, conveyedMessage } = junkFormData;

    const [wonFormData, setWonFormData] = useState({
        isVisitedExperienceCenter: "",
        city :"",
        visitedExperienceCenter: "",
        satisfactionIndexRatioWon: [],
        reasonToChooseDecorpot: "",
        otherReasonToChooseDecorpot: "",
        comments: ""
    })
    const { isVisitedExperienceCenter, visitedExperienceCenter, satisfactionIndexRatioWon, reasonToChooseDecorpot, otherReasonToChooseDecorpot, comments, city } = wonFormData;

    const [lostFormData, setLostFormData] = useState({
        isVisitedExperienceCenterLost: "",
        lostCity :"",
        invitedExperienceCenter: "",
        satisfactionIndexRatioLost: [],
        reasonForNotChoosingDecorpot: "",
        otherReasonForNotChoosingDecorpot: "",
        commentsLost: ""
    })
    const { isVisitedExperienceCenterLost, invitedExperienceCenter, satisfactionIndexRatioLost, reasonForNotChoosingDecorpot, otherReasonForNotChoosingDecorpot, commentsLost, lostCity} = lostFormData;

    const [designFormData, setDesignFormData] = useState({
        satisfactionIndexRatioDesign: [],
        isDesignManagerInvolved: "",
        satisfactionIndexForDesignManager: [],
        feedbackDesign: ""
    })
    const { satisfactionIndexRatioDesign, isDesignManagerInvolved, satisfactionIndexForDesignManager, feedbackDesign } = designFormData;

    const [designLostFormData, setDesignLostFormData] = useState({
        satisfactionIndexRatioDesignLost: [],
        isDesignManagerInvolvedLost: "",
        satisfactionIndexForDesignManager: [],
        feedbackDesign: "",
        isChmInvolvedLost:"",
        satisfactionIndexForChmLost:[],
        reasonForNotMovingAhead:""
    })
    const { satisfactionIndexRatioDesignLost, isDesignManagerInvolvedLost, satisfactionIndexForDesignManagerLost, feedbackDesignLost, isChmInvolvedLost, satisfactionIndexForChmLost, reasonForNotMovingAhead } = designLostFormData;

    const [executionFormData, setExecutionFormData] = useState({
        howSatisfiedWithExecutionAndDelivery: "",
        howSatisfiedWithExecutionTeam: "",
        satisfactionIndexRatioExecution: [],
        feedbackForSpecificVendor: "",
        additionalFeedback: ""
    })
    const { howSatisfiedWithExecutionAndDelivery, howSatisfiedWithExecutionTeam, satisfactionIndexRatioExecution, feedbackForSpecificVendor, additionalFeedback } = executionFormData;

    const getRowData = (data, type) => {
        if (type === 'real') {
            setRealFormData({
                feedback: data.feedback,
                howSatisfiedWithInteraction: data.howSatisfiedWithInteraction,
                satisfactionIndexRatio: data.satisfactionIndexRatio
            })
        } else if (type === 'junk') {
            setJunkFormData({
                whenFlatAvailbleForInterior: data.whenFlatAvailbleForInterior,
                budgetForInteriors: data.budgetForInteriors,
                feedbackJunk: data.feedback,
                conveyedMessage: data.conveyedMessage,
                satisfactionIndexRatioJunk: data.satisfactionIndexRatio
            })
        } else if (type === 'won') {
            setWonFormData({
                isVisitedExperienceCenter: data.isVisitedExperienceCenter,
                visitedExperienceCenter: data.visitedExperienceCenter,
                reasonToChooseDecorpot: data.reasonToChooseDecorpot,
                otherReasonToChooseDecorpot: data.otherReasonToChooseDecorpot,
                satisfactionIndexRatioWon: data.satisfactionIndexRatio,
                city : data?.leadId?.city?.name,
                comments: data.comments
            })
            storeDefaultSelectedExpCenter()

        } else if (type === 'lost') {
            setLostFormData({
                isVisitedExperienceCenterLost: data.isVisitedExperienceCenter,
                lostCity : data?.leadId?.city?.name,
                invitedExperienceCenter: data.invitedExperienceCenter,
                reasonForNotChoosingDecorpot: data.reasonForNotChoosingDecorpot,
                otherReasonForNotChoosingDecorpot: data.otherReasonForNotChoosingDecorpot,
                satisfactionIndexRatioLost: data.satisfactionIndexRatio,
                commentsLost: data.comments
            })
        } else if (type === 'design') {
            setDesignFormData({
                satisfactionIndexRatioDesign: data.satisfactionIndexRatio,
                isDesignManagerInvolved: data.isDesignManagerInvolved,
                satisfactionIndexForDesignManager: data.satisfactionIndexForDesignManager,
                feedbackDesign: data.feedback
            })
        
        } else if (type === 'design-lost') {
            setDesignLostFormData({
                satisfactionIndexRatioDesignLost: data.satisfactionIndexRatio,
                isDesignManagerInvolvedLost: data.isDesignManagerInvolved,
                satisfactionIndexForDesignManagerLost: data.satisfactionIndexForDesignManager,
                feedbackDesignLost: data.feedback,
                isChmInvolvedLost: data.isChmInvolved,
                satisfactionIndexForChmLost: data.satisfactionIndexForChm,
                reasonForNotMovingAhead: data.reasonForNotMovingAhead
            })
        }
        else if (type === 'execution') {
            setExecutionFormData({
                howSatisfiedWithExecutionAndDelivery: data.howSatisfiedWithExecutionAndDelivery,
                howSatisfiedWithExecutionTeam: data.howSatisfiedWithExecutionTeam,
                satisfactionIndexRatioExecution: data.satisfactionIndexRatio,
                feedbackForSpecificVendor: data.feedbackForSpecificVendor,
                additionalFeedback: data.additionalFeedback
            })
        }
    }
    const setPrevious=(action)=>{
      history.push('/view-customer-survey-forms',{
        selectedRadio:action
      })
    }
    const storeDefaultSelectedExpCenter = () =>{
        let  defaultValue = `${wonFormData.visitedExperienceCenter} - ${wonFormData?.city}`;
    }  
    return (
        <div >
            {/* <Link
                to={'/view-customer-survey-forms'}
                className={classes.linkViewForm}
            style={{color:"white"}} >
                View All Customer Survey form
            </Link> */}
            {/* Inside Customer selected Data */}
            <Button onClick={()=>setPrevious(props.location.state.type)} variant="contained" color="primary" style={{color:"white",  backgroundColor: '#673ab7',textTransform:'none',marginBottom:"-20px"}} >
            Go Back
            </Button>
            <br />
            <div style={{paddingTop: 20}}></div>
            <Paper style={{ marginTop: 20, padding: 10,borderTop:"8px solid #5D41AD"}}>
                <Typography variant="h6" gutterBottom style={{ paddingTop: 10, fontSize: 27, fontWeight: 600 }}>
                    Lead Details
                </Typography>
                <ul>
                    <li className={classes.listStyleInfo}><b>Customer Name:</b> {props.location.state.data.customerId.name}</li>
                    <li className={classes.listStyleInfo}><b>Lead No:</b> {props.location.state.data.leadId.lead_no}</li>
                    <li className={classes.listStyleInfo}><b>Lead Owner:</b> {props.location.state.data.leadOwner.name}</li>
                </ul>
            </Paper>
            {
                props.location.state.type === 'real' &&
                <Paper style={{ marginTop: 20, padding: 10,borderTop: "8px solid #673ab7",borderBottom:"8px solid rgb(93, 65, 173" }}>

                    <FormControl component="fieldset" style={{ marginBottom: 20, marginTop: 20 }}>
                        <FormLabel component="legend" style={{ color: "#000" }}>How satisfied are you with the interaction? *</FormLabel>
                        <RadioGroup row aria-label="position" name="howSatisfiedWithExecutionAndDelivery" defaultValue="top"
                            value={howSatisfiedWithInteraction}
                        >
                            <FormLabel style={{ marginTop: 30, marginRight: 20, fontWeight: 500, color: "#000" }}>Not satisfied</FormLabel>
                            <FormControlLabel
                                value="1"
                                control={<Radio color="primary" />}
                                label="1"
                                labelPlacement="top"
                                style={{ marginLeft: 0 }}
                            />
                            <FormControlLabel
                                value="2"
                                control={<Radio color="primary" />}
                                label="2"
                                labelPlacement="top"
                                style={{ marginLeft: 0 }}
                            />
                            <FormControlLabel
                                value="3"
                                control={<Radio color="primary" />}
                                label="3"
                                labelPlacement="top"
                                style={{ marginLeft: 0 }}
                            />
                            <FormControlLabel
                                value="4"
                                control={<Radio color="primary" />}
                                label="4"
                                labelPlacement="top"
                                style={{ marginLeft: 0 }}
                            />
                            <FormControlLabel
                                value="5"
                                control={<Radio color="primary" />}
                                label="5"
                                labelPlacement="top"
                                style={{ marginLeft: 0 }}
                            />
                            <FormLabel style={{ marginTop: 30, fontWeight: 500, color: "#000" }}>Very satisfied</FormLabel>
                        </RadioGroup>
                    </FormControl>
                    <br />
                     <FormControl component="fieldset" style={{ marginBottom: 20, marginTop: 20 }}>
                    <FormLabel component="legend" style={{ color: "#000" }}>Satisfaction index -</FormLabel>
                    <FormLabel component="legend" style={{ color: "#000" }}> <span className='satisfiedDetail'> </span> <span style={{marginLeft:"10px"}}>Dissatisfied </span> 
                                             <span className='DissatisfiedDetail'></span>
                                            <span  style={{marginLeft:"10px"}}> Satisfied</span> </FormLabel>
                    </FormControl>

                

                        <TableContainer component={Paper} className={classes.container} style={{ width: '70%' }}>
  <Table className={classes.table} aria-label="sticky table" >
                                        <TableHead>
                                            <TableRow>
                                                <RadioGroup defaultValue="abc" aria-label="action" name="customized-radios" style={{ display: 'table-cell', top:0,left: 0, zIndex: 2, position: 'sticky' }}>
                                                <TableCell  style={{width:"8rem"}}></TableCell>
                                                    <TableCell style={{padding: 16, textAlign: 'center', fontWeight: 700,width:"3.938rem",height:"4.1rem",borderRight:"none",backgroundColor:"#e72600"}}>
                                                        <span style={{
                                                            color:"#fff"
                                                        }}> 1 </span>
                                                    </TableCell>
                                                    <TableCell style={{padding: 16, textAlign: 'center', fontWeight: 700 ,width:"4rem",height:"4rem",backgroundColor:"#f96500"}}>
                                                        <span style={{
                                                          color:"#fff"
                                                        }}>2</span>
                                                    </TableCell>
                                                    <TableCell style={{padding: 16, textAlign: 'center', fontWeight: 700 ,width:"4rem",height:"4rem",backgroundColor:"#ffe000"}}>
                                                        <span style={{
                                                          color:"#fff"
                                                        }}>3</span>
                                                    </TableCell>
                                                    <TableCell style={{padding: 16, textAlign: 'center', fontWeight: 700,width:"4rem",height:"4rem",backgroundColor:"#30ad00"}}>
                                                        <span style={{
                                                         color:"#fff"
                                                        }}>4</span>
                                                    </TableCell>
                                                    <TableCell style={{padding: 16, textAlign: 'center', fontWeight: 700 ,width:"4rem",height:"4rem",backgroundColor:"#218b00"}}>
                                                        <span style={{color:"#fff"
                                                        }}>5</span>
                                                    </TableCell>
                                                </RadioGroup>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {satisfactionIndexRatio.sort().map((row, i) => {
                                        const labelId = `enhanced-table-checkbox-${i}`;
                                        return (
                                            <TableRow key={i + 1}>
                                                <RadioGroup aria-label='option' name="customized-radios"
                                                    value={row?.actionSelected}
                                                    style={{ display: 'block' }}>
                                          <TableCell className='discription' style={{ padding: '5px', fontWeight: 550, width:"15rem" }} component="th" scope="row"><p className='discript'>{row?.description}</p></TableCell>
                                                            <TableCell style={{ padding: 10, textAlign: 'center' }} component="th">
                                                                <Radio
                                                                    value='1'
                                                                    checked={row?.actionSelected === '1'}
                                                                    style={{
                                                                        backgroundColor: row?.actionSelected === '1' ? '#fde6f0' : '',
                                                                        borderRadius: row?.actionSelected === '1' ? '0px' : ''
                                                                    }}
                                                                    name="radio-button-demo"
                                                                    inputProps={{ 'aria-label': labelId }}
                                                                />
                                                            </TableCell>
                                                             <TableCell className='cellwidth' style={{ padding: 10, textAlign: 'center',width:"4rem"}} component="th">
                                                                <Radio
                                                                    value='2'
                                                                    checked={row?.actionSelected === '2'}
                                                                    name="radio-button-demo"
                                                                    inputProps={{ 'aria-label': labelId }}
                                                                    style={{
                                                                        backgroundColor: row?.actionSelected === '2' ? '#fde6f0' : '',
                                                                        borderRadius: row?.actionSelected === '2' ? '0px' : ''
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell className='cellwidth' style={{ padding: 10, textAlign: 'center',borderLeft:"none",width:"4rem" }} component="th">
                                                                <Radio
                                                                    value='3'
                                                                    checked={row?.actionSelected === '3'}
                                                                    name="radio-button-demo"
                                                                    inputProps={{ 'aria-label': labelId }}
                                                                    style={{
                                                                        backgroundColor: row?.actionSelected === '3' ? '#fde6f0' : '',
                                                                        borderRadius: row?.actionSelected === '3' ? '0px' : ''
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell className='cellwidth' style={{ padding: 10, textAlign: 'center',width:"4rem" }} component="th">
                                                                <Radio
                                                                    value='4'
                                                                    checked={row?.actionSelected === '4'}
                                                                    name="radio-button-demo"
                                                                    inputProps={{ 'aria-label': labelId }}
                                                                    style={{
                                                                        backgroundColor: row?.actionSelected === '4' ? '#fde6f0' : '',
                                                                        borderRadius: row?.actionSelected === '4' ? '0px' : ''
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell className='cellwidth' style={{ padding: 10, textAlign: 'center',width:"4rem" }} component="th">
                                                                <Radio
                                                                    value='5'
                                                                    checked={row?.actionSelected === '5'}
                                                                    name="radio-button-demo"
                                                                    inputProps={{ 'aria-label': labelId }}
                                                                    style={{
                                                                        backgroundColor: row?.actionSelected === '5' ? '#fde6f0' : '',
                                                                        borderRadius: row?.actionSelected === '5' ? '0px' : ''
                                                                    }}
                                                                />
                                                            </TableCell> 
                                                </RadioGroup>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                                <TableFooter>
                                            <TableRow key="last">
                                            <RadioGroup aria-label='option' name="customized-radios" style={{ display: 'table-cell' }}>
                                                <TableCell style={{width:"15rem"}}></TableCell>
                                                <TableCell style={{ padding: 19 }}><ThumbDownIcon style={{ color: "#e72600" }} /></TableCell>
                                                    <TableCell style={{ padding: 19 }}><ThumbDownIcon style={{ visibility: "hidden" }} /></TableCell>
                                                    <TableCell style={{ padding: 19 }}><ThumbDownIcon style={{ visibility: "hidden" }} /></TableCell>
                                                    <TableCell style={{ padding: 19 }}><ThumbDownIcon style={{ visibility: "hidden" }} /></TableCell>
                                                    <TableCell style={{ padding: 19 }}><ThumbUpIcon   style={{ color: "#218b00" ,marginLeft:10}} /></TableCell>
                                                </RadioGroup>
                                            </TableRow>
                                        </TableFooter>
                            </Table>
                        </TableContainer>

                    <br />
                    <TextField
                        label="Additional comments *"
                        size="small"
                        style={{ marginBottom: 20, textAlign: "center", marginTop: 20, width: "50%", backgroundColor: "#f8f1fb" }}
                        name="feedback"
                        variant="outlined"
                        multiline
                        rows={3}
                        value={feedback}
                        disabled
                        width="120%"
                    />
                </Paper>
            }

            {
                props.location.state.type === 'junk' &&
                <Paper style={{ marginTop: 20, padding: 10, borderTop: "8px solid #673ab7",borderBottom:"8px solid rgb(93, 65, 173"}}>
                    <div style={{ padding: 10 }}>

                    <FormControl component="fieldset" style={{ marginBottom: 20, marginTop: 20 }}>
                    <FormLabel component="legend" style={{ color: "#000" }}>Satisfaction index -</FormLabel>
                    <FormLabel component="legend" style={{ color: "#000" }}> <span className='satisfiedDetail'> </span> <span style={{marginLeft:"10px"}}>Dissatisfied </span> 
                                             <span className='DissatisfiedDetail'></span>
                                            <span  style={{marginLeft:"10px"}}> Satisfied</span> </FormLabel>
                    </FormControl>

                            <TableContainer component={Paper} className={classes.container} style={{ width: '70%' }}>
                                <Table className={classes.table} aria-label="sticky table">
                                    <TableHead>
                                    <TableRow>
                                                <RadioGroup defaultValue="abc" aria-label="action" name="customized-radios" style={{ display: 'table-cell', top:0,left: 0, zIndex: 2, position: 'sticky' }}>
                                                <TableCell  style={{width:"15rem"}}></TableCell>
                                                    <TableCell style={{padding: 16, textAlign: 'center', fontWeight: 700,width:"3.938rem",height:"4.1rem",borderRight:"none",backgroundColor:"#e72600"}}>
                                                        <span style={{
                                                            color:"#fff"
                                                        }}> 1 </span>
                                                    </TableCell>
                                                    <TableCell style={{padding: 16, textAlign: 'center', fontWeight: 700 ,width:"4rem",height:"4rem",backgroundColor:"#f96500"}}>
                                                        <span style={{
                                                          color:"#fff"
                                                        }}>2</span>
                                                    </TableCell>
                                                    <TableCell style={{padding: 16, textAlign: 'center', fontWeight: 700 ,width:"4rem",height:"4rem",backgroundColor:"#ffe000"}}>
                                                        <span style={{
                                                          color:"#fff"
                                                        }}>3</span>
                                                    </TableCell>
                                                    <TableCell style={{padding: 16, textAlign: 'center', fontWeight: 700,width:"4rem",height:"4rem",backgroundColor:"#30ad00"}}>
                                                        <span style={{
                                                         color:"#fff"
                                                        }}>4</span>
                                                    </TableCell>
                                                    <TableCell style={{padding: 16, textAlign: 'center', fontWeight: 700 ,width:"4rem",height:"4rem",backgroundColor:"#218b00"}}>
                                                        <span style={{color:"#fff"
                                                        }}>5</span>
                                                    </TableCell>
                                                </RadioGroup>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {satisfactionIndexRatioJunk.sort().map((row, i) => {
                                            const labelId = `enhanced-table-checkbox-${i}`;
                                            return (
                                                <TableRow key={i + 1}>
                                                     <RadioGroup aria-label='option' name="customized-radios"
                                                    value={row?.actionSelected}
                                                    style={{ display: 'block' }}>
                                          <TableCell className='discription' style={{ padding: '5px', fontWeight: 550, width:"15rem" }} component="th" scope="row"><p className='discript'>{row?.description}</p></TableCell>
                                                            <TableCell style={{ padding: 10, textAlign: 'center' }} component="th">
                                                                <Radio
                                                                    value='1'
                                                                    checked={row?.actionSelected === '1'}
                                                                    style={{
                                                                        backgroundColor: row?.actionSelected === '1' ? '#fde6f0' : '',
                                                                        borderRadius: row?.actionSelected === '1' ? '0px' : ''
                                                                    }}
                                                                    name="radio-button-demo"
                                                                    inputProps={{ 'aria-label': labelId }}
                                                                />
                                                            </TableCell>
                                                             <TableCell className='cellwidth' style={{ padding: 10, textAlign: 'center',width:"4rem"}} component="th">
                                                                <Radio
                                                                    value='2'
                                                                    checked={row?.actionSelected === '2'}
                                                                    name="radio-button-demo"
                                                                    inputProps={{ 'aria-label': labelId }}
                                                                    style={{
                                                                        backgroundColor: row?.actionSelected === '2' ? '#fde6f0' : '',
                                                                        borderRadius: row?.actionSelected === '2' ? '0px' : ''
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell className='cellwidth' style={{ padding: 10, textAlign: 'center',borderLeft:"none",width:"4rem" }} component="th">
                                                                <Radio
                                                                    value='3'
                                                                    checked={row?.actionSelected === '3'}
                                                                    name="radio-button-demo"
                                                                    inputProps={{ 'aria-label': labelId }}
                                                                    style={{
                                                                        backgroundColor: row?.actionSelected === '3' ? '#fde6f0' : '',
                                                                        borderRadius: row?.actionSelected === '3' ? '0px' : ''
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell className='cellwidth' style={{ padding: 10, textAlign: 'center',width:"4rem" }} component="th">
                                                                <Radio
                                                                    value='4'
                                                                    checked={row?.actionSelected === '4'}
                                                                    name="radio-button-demo"
                                                                    inputProps={{ 'aria-label': labelId }}
                                                                    style={{
                                                                        backgroundColor: row?.actionSelected === '4' ? '#fde6f0' : '',
                                                                        borderRadius: row?.actionSelected === '4' ? '0px' : ''
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell className='cellwidth' style={{ padding: 10, textAlign: 'center',width:"4rem" }} component="th">
                                                                <Radio
                                                                    value='5'
                                                                    checked={row?.actionSelected === '5'}
                                                                    name="radio-button-demo"
                                                                    inputProps={{ 'aria-label': labelId }}
                                                                    style={{
                                                                        backgroundColor: row?.actionSelected === '5' ? '#fde6f0' : '',
                                                                        borderRadius: row?.actionSelected === '5' ? '0px' : ''
                                                                    }}
                                                                />
                                                            </TableCell> 
                                                </RadioGroup>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                    <TableFooter>
                                            <TableRow key="last">
                                            <RadioGroup aria-label='option' name="customized-radios" style={{ display: 'table-cell' }}>
                                                <TableCell style={{width:"15rem"}}></TableCell>
                                                <TableCell style={{ padding: 19 }}><ThumbDownIcon style={{ color: "#e72600" }} /></TableCell>
                                                    <TableCell style={{ padding: 19 }}><ThumbDownIcon style={{ visibility: "hidden" }} /></TableCell>
                                                    <TableCell style={{ padding: 19 }}><ThumbDownIcon style={{ visibility: "hidden" }} /></TableCell>
                                                    <TableCell style={{ padding: 19 }}><ThumbDownIcon style={{ visibility: "hidden" }} /></TableCell>
                                                    <TableCell style={{ padding: 19 }}><ThumbUpIcon   style={{ color: "#218b00" ,marginLeft:10}} /></TableCell>
                                                </RadioGroup>
                                            </TableRow>
                                        </TableFooter>
                                </Table>
                            </TableContainer>
                        <br />
                        <FormControl component="fieldset" style={{ marginBottom: 20, marginTop: 20 }}>
                            <FormLabel component="legend" style={{ color: "#000" }}>When will your Flat / house be available for interiors? * </FormLabel>
                            <RadioGroup aria-label="flatAvailble" name="whenFlatAvailbleForInterior"
                                value={whenFlatAvailbleForInterior}>
                                <FormControlLabel value="More-than-3-months" control={<Radio color="primary" />} label="> 3 months (More than 3 months)" />
                                <FormControlLabel value="Less-than-3-months" control={<Radio color="primary" />} label="< 3 months (Less than 3 months)" />
                            </RadioGroup>
                        </FormControl>
                        <br />
                        <FormControl component="fieldset" style={{ marginBottom: 20, marginTop: 20 }}>
                            <FormLabel component="legend" style={{ color: "#000" }}>Please select the Budget for your interiors * </FormLabel>
                            <RadioGroup aria-label="budget" name="budgetForInteriors"
                                value={budgetForInteriors}>
                                <FormControlLabel value="Above-3-lakhs" control={<Radio color="primary" />} label="> 3lakhs (Above 3 lakhs)" />
                                <FormControlLabel value="Below-3-lakhs" control={<Radio color="primary" />} label="< 3lakhs (Below 3 lakhs))" />
                            </RadioGroup>
                        </FormControl>
                        <br />
                        <TextField
                            label="Additional comments *"
                            size="small"
                            style={{ marginBottom: 20, textAlign: "center", marginTop: 20, width: "50%",backgroundColor: "#f8f1fb" }}
                            name="feedbackJunk"
                            value={feedbackJunk}
                            variant="outlined"
                            multiline
                            rows={3}
                            disabled
                        />
                        <br />
                        {
                            (junkFormData.whenFlatAvailbleForInterior === "Less-than-3-months" && junkFormData.budgetForInteriors === "Below-3-lakhs") &&
                            <>
                                <Typography variant='subtitle2' gutterBottom style={{ marginTop: 15, marginBottom: 20 }}>
                                    We apologize that we are not able to engage with you in your home interior journey at this stage.
                                </Typography>

                                <TextField
                                    label="Your comments *"
                                    size="small"
                                    style={{ marginBottom: 20, textAlign: "center", width: "50%", backgroundColor: "#f0ebf8"}}
                                    name="conveyedMessage"
                                    value={conveyedMessage}
                                    variant="outlined"
                                    multiline
                                    rows={3}
                                    disabled
                                />
                            </>
                        }
                    </div>
                </Paper>
            }

            {
                props.location.state.type === 'won' &&
                <Paper style={{ marginTop: 20, padding: 10, borderTop: "8px solid #673ab7",borderBottom:"8px solid rgb(93, 65, 173" }}>
                    <div style={{ padding: 10 }}>

                        <FormControl component="fieldset" style={{ marginBottom: 20, marginTop: 20 }}>
                            <FormLabel component="legend" style={{ color: "#000" }}>Did you Visit our experience center? * </FormLabel>
                            <RadioGroup aria-label="visitedExpCenter" name="isVisitedExperienceCenter"
                                value={isVisitedExperienceCenter}>
                                <FormControlLabel value="Yes" control={<Radio color="primary" />} label="Yes" />
                                <FormControlLabel value="No" control={<Radio color="primary" />} label="No" />
                            </RadioGroup>
                        </FormControl>
                        <br />
                        {wonFormData.isVisitedExperienceCenter === 'Yes' && (
                            <>
                        <FormControl component="fieldset" style={{ marginBottom: 20, marginTop: 10 }}>
                            <>
                        <RadioGroup aria-label="visitedExpCenter" name="visitedExperienceCenter" >
                            <FormControlLabel
                            value={storeDefaultSelectedExpCenter()}
                            control={<Radio color="primary" />}
                            label={`${wonFormData.visitedExperienceCenter} - ${wonFormData?.city}`}
                            />
                        </RadioGroup>
                        </>
                        </FormControl>
                         </>
                    )}
                        <div>
                    <FormControl component="fieldset" style={{ marginBottom: 20, marginTop: 20 }}>
                                <FormLabel component="legend" style={{ color: "#000" }}>Satisfaction index -</FormLabel>
                        </FormControl>
                        </div>
                        <FormControl component="fieldset" style={{ marginBottom: 20, marginTop: 20 }}>

                    <FormLabel component="legend" style={{ color: "#000" }}> <span className='satisfiedDetail'> </span> <span style={{marginLeft:"10px"}}>Dissatisfied </span> 
                        <span className='DissatisfiedDetail'></span>
                        <span  style={{marginLeft:"10px"}}> Satisfied</span> </FormLabel>
                    </FormControl>
                            <TableContainer component={Paper} style={{ width: '69%' }}>
                                <Table className={classes.table} aria-label="table">
                                    <TableHead>
                                    <TableRow>
                                                <RadioGroup defaultValue="abc" aria-label="action" name="customized-radios" style={{ display: 'table-cell', top:0,left: 0, zIndex: 2, position: 'sticky' }}>
                                                <TableCell  style={{width:"15rem"}}></TableCell>
                                                    <TableCell style={{padding: 16, textAlign: 'center', fontWeight: 700,width:"3.938rem",height:"4.1rem",borderRight:"none",backgroundColor:"#e72600"}}>
                                                        <span style={{
                                                            color:"#fff"
                                                        }}> 1 </span>
                                                    </TableCell>
                                                    <TableCell style={{padding: 16, textAlign: 'center', fontWeight: 700 ,width:"4rem",height:"4rem",backgroundColor:"#f96500"}}>
                                                        <span style={{
                                                          color:"#fff"
                                                        }}>2</span>
                                                    </TableCell>
                                                    <TableCell style={{padding: 16, textAlign: 'center', fontWeight: 700 ,width:"4rem",height:"4rem",backgroundColor:"#ffe000"}}>
                                                        <span style={{
                                                          color:"#fff"
                                                        }}>3</span>
                                                    </TableCell>
                                                    <TableCell style={{padding: 16, textAlign: 'center', fontWeight: 700,width:"4rem",height:"4rem",backgroundColor:"#30ad00"}}>
                                                        <span style={{
                                                         color:"#fff"
                                                        }}>4</span>
                                                    </TableCell>
                                                    <TableCell style={{padding: 16, textAlign: 'center', fontWeight: 700 ,width:"4rem",height:"4rem",backgroundColor:"#218b00"}}>
                                                        <span style={{color:"#fff"
                                                        }}>5</span>
                                                    </TableCell>
                                                </RadioGroup>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {satisfactionIndexRatioWon.sort().map((row, i) => {
                                            const labelId = `enhanced-table-checkbox-${i}`;
                                            return (
                                                <TableRow key={i + 1}>
                                                    {/* <TableCell style={{ padding: '5px', textAlign: 'center' }}>{row?.id}</TableCell> */}
                                                    <RadioGroup aria-label='option' name="customized-radios"
                                                        value={row?.actionSelected}
                                                        style={{ display: 'block' }}>
                                                        <TableCell className='discription' style={{ padding: 5, fontWeight: 550, width:"15rem" }} component="th" scope="row"><p className='discript'>{row?.description}</p></TableCell>
                                                            <TableCell style={{padding:5, textAlign: 'center',width:"3.928rem" }} component="th">
                                                                <Radio
                                                                    value='1'
                                                                    checked={row?.actionSelected === '1'}
                                                                    style={{
                                                                        backgroundColor: row?.actionSelected === '1' ? '#fde6f0' : '',
                                                                        borderRadius: row?.actionSelected === '1' ? '0px' : ''
                                                                    }}
                                                                    name="radio-button-demo"
                                                                    inputProps={{ 'aria-label': labelId }}
                                                                />
                                                            </TableCell>
                                                             <TableCell className='cellwidth' style={{padding: 5,  textAlign: 'center',width:"4rem"}} component="th">
                                                                <Radio
                                                                    value='2'
                                                                    checked={row?.actionSelected === '2'}
                                                                    name="radio-button-demo"
                                                                    inputProps={{ 'aria-label': labelId }}
                                                                    style={{
                                                                        backgroundColor: row?.actionSelected === '2' ? '#fde6f0' : '',
                                                                        borderRadius: row?.actionSelected === '2' ? '0px' : ''
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell className='cellwidth' style={{ padding: 5, textAlign: 'center',borderLeft:"none",width:"4rem" }} component="th">
                                                                <Radio
                                                                    value='3'
                                                                    checked={row?.actionSelected === '3'}
                                                                    name="radio-button-demo"
                                                                    inputProps={{ 'aria-label': labelId }}
                                                                    style={{
                                                                        backgroundColor: row?.actionSelected === '3' ? '#fde6f0' : '',
                                                                        borderRadius: row?.actionSelected === '3' ? '0px' : ''
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell className='cellwidth' style={{ padding: 5, textAlign: 'center',width:"4rem" }} component="th">
                                                                <Radio
                                                                    value='4'
                                                                    checked={row?.actionSelected === '4'}
                                                                    name="radio-button-demo"
                                                                    inputProps={{ 'aria-label': labelId }}
                                                                    style={{
                                                                        backgroundColor: row?.actionSelected === '4' ? '#fde6f0' : '',
                                                                        borderRadius: row?.actionSelected === '4' ? '0px' : ''
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell className='cellwidth' style={{ padding: 5, textAlign: 'center',width:"4rem" ,borderLeft:"none"}} component="th">
                                                                <Radio
                                                                    value='5'
                                                                    checked={row?.actionSelected === '5'}
                                                                    name="radio-button-demo"
                                                                    inputProps={{ 'aria-label': labelId }}
                                                                    style={{
                                                                        backgroundColor: row?.actionSelected === '5' ? '#fde6f0' : '',
                                                                        borderRadius: row?.actionSelected === '5' ? '0px' : ''
                                                                    }}
                                                                />
                                                            </TableCell> 

                                                    </RadioGroup>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                    <TableFooter>
                                            <TableRow key="last">
                                            <RadioGroup aria-label='option' name="customized-radios" style={{ display: 'table-cell' }}>
                                                <TableCell style={{width:"15rem"}}></TableCell>
                                                <TableCell style={{ padding: 19 }}><ThumbDownIcon style={{ color: "#e72600" }} /></TableCell>
                                                    <TableCell style={{ padding: 19 }}><ThumbDownIcon style={{ visibility: "hidden" }} /></TableCell>
                                                    <TableCell style={{ padding: 19 }}><ThumbDownIcon style={{ visibility: "hidden" }} /></TableCell>
                                                    <TableCell style={{ padding: 19 }}><ThumbDownIcon style={{ visibility: "hidden" }} /></TableCell>
                                                    <TableCell style={{ padding: 19 }}><ThumbUpIcon   style={{ color: "#218b00" ,marginLeft:10}} /></TableCell>
                                                </RadioGroup>
                                            </TableRow>
                                        </TableFooter>
                                </Table>
                            </TableContainer>
                        <br />
                        <FormControl component="fieldset" style={{ marginBottom: 20, marginTop: 20 }}>
                            <FormLabel component="legend" style={{ color: "#000" }}>Prime reason to choose Decorpot. * </FormLabel>
                            <RadioGroup aria-label="reasonNotChoose" name="reasonToChooseDecorpot"
                                value={reasonToChooseDecorpot}>
                                <FormControlLabel value="Price" control={<Radio color="primary" />} label="Price" />
                                <FormControlLabel value="Quality" control={<Radio color="primary" />} label="Quality" />
                                <FormControlLabel value="Reference" control={<Radio color="primary" />} label="Reference" />
                                <FormControlLabel value="In-house-production-facility." control={<Radio color="primary" />} label="In-house production facility." />
                                <FormControlLabel value="Process-in-interior-journey" control={<Radio color="primary" />} label="Process in interior journey" />
                                <FormControlLabel value="Interaction-with-the-sales-representative." control={<Radio color="primary" />} label="Interaction with the sales representative." />
                                <FormControlLabel value="Other" control={<Radio color="primary" />} label="Other" />
                            </RadioGroup>
                            {
                                wonFormData.reasonToChooseDecorpot === 'Other' &&
                                <TextField
                                    size="small"
                                    style={{ marginBottom: 20, textAlign: "center", width: "100%", backgroundColor: "#faf1f5" }}
                                    name="otherReasonToChooseDecorpot"
                                    value={otherReasonToChooseDecorpot}
                                    variant="outlined"
                                    multiline
                                    rows={3}
                                    disabled
                                />
                            }
                        </FormControl>

                        <br />
                        <TextField
                            label="Additional comments *"
                            size="small"
                            style={{ marginBottom: 20, textAlign: "center", marginTop: 20, width: "50%",backgroundColor: "#f8f1fb" }}
                            name="comments"
                            value={comments}
                            variant="outlined"
                            multiline
                            rows={3}
                            disabled
                        />
                        <br />
                    </div>
                </Paper>
            }

            {
                props.location.state.type === 'lost' &&
                <Paper style={{ marginTop: 20, padding: 10, borderTop: "8px solid #673ab7",borderBottom:"8px solid rgb(93, 65, 173" }}>
                    <div style={{ padding: 10 }}>

                        <FormControl component="fieldset" style={{ marginBottom: 20, marginTop: 20 }}>
                            <FormLabel component="legend" style={{ color: "#000" }}>Did you Visit our experience center? * </FormLabel>
                            <RadioGroup aria-label="visitedExpCenter" name="isVisitedExperienceCenterLost"
                                value={isVisitedExperienceCenterLost}>
                                <FormControlLabel value="Yes" control={<Radio color="primary" />} label="Yes" />
                                <FormControlLabel value="No" control={<Radio color="primary" />} label="No" />
                            </RadioGroup>
                        </FormControl>
                        <br />

                                    {
                             lostFormData.isVisitedExperienceCenterLost === 'Yes' && <>
                                    <FormControl component="fieldset" style={{ marginBottom: 20, marginTop: 10 }}>
                                    <RadioGroup aria-label="invitedExperienceCenter" name="invitedExperienceCenter" value={storeDefaultSelectedExpCenter()}>
                                        <FormControlLabel
                                        value={storeDefaultSelectedExpCenter()}
                                        control={<Radio color="primary" />}
                                        label={`${invitedExperienceCenter} - ${lostFormData?.lostCity}`}
                                        />
                                    </RadioGroup>
                                    </FormControl>

                                    </>
                                    }
                     
                        {/* {
                            lostFormData.isVisitedExperienceCenterLost === 'Yes' && <>
                                <FormControl component="fieldset" style={{ marginBottom: 20, marginTop: 20 }}>
                                    <FormLabel component="legend" style={{ color: "#000" }}>Which Decorpot Experience Center were you invited to? * </FormLabel>
                                    <RadioGroup aria-label="invitedExpCenter" name="invitedExperienceCenter" value={invitedExperienceCenter}>
                                        <FormControlLabel value="Decorpot-HSRlayout" control={<Radio color="primary" />} label="Decorpot - HSR layout, Bangalore." />
                                        <FormControlLabel value="Decorpot-Whitefield" control={<Radio color="primary" />} label="Decorpot - Whitefield, Bangalore." />
                                        <FormControlLabel value="Decorpot-Sahakar Nagar" control={<Radio color="primary" />} label="Decorpot - Sahakar Nagar, Bangalore." />
                                        <FormControlLabel value="Decorpot-Gachibowli" control={<Radio color="primary" />} label="Decorpot - Gachibowli, Hyderabad." />
                                        <FormControlLabel value="Decorpot-Kompally" control={<Radio color="primary" />} label="Decorpot - Kompally, Hyderabad." />
                                        <FormControlLabel value="Decorpot-Thoraipakkam" control={<Radio color="primary" />} label="Decorpot - Thoraipakkam, Chennai." />
                                        <FormControlLabel value="Decorpot-Coimbatore" control={<Radio color="primary" />} label="Decorpot - Coimbatore, Tamil Nadu." />
                                        <FormControlLabel value="Decorpot-Viman Nagar" control={<Radio color="primary" />} label="Decorpot - Viman Nagar, Pune." />
                                        <FormControlLabel value="Decorpot-Noida" control={<Radio color="primary" />} label="Decorpot - Noida, Uttar Pradesh." />
                                        <FormControlLabel value="Decorpot-Kolkata" control={<Radio color="primary" />} label="Decorpot - Kolkata, West Bengal." />
                                        <FormControlLabel value="Decorpot-Thane" control={<Radio color="primary" />} label="Decorpot - Thane, Maharashtra." /> 

                                    </RadioGroup>
                                </FormControl>
                                <br />
                            </>
                        } */}
                     
                     <div>
                        <FormControl component="fieldset" style={{ marginBottom: 20, marginTop: 20 }}>
                            <FormLabel component="legend" style={{ color: "#000" }}>Satisfaction index -</FormLabel>
                        </FormControl>
                    </div>

                    <FormControl component="fieldset" style={{ marginBottom: 20, marginTop: 20 }}>
                        <FormLabel component="legend" style={{ color: "#000" }}> <span className='satisfiedDetail'> </span> <span style={{marginLeft:"10px"}}>Dissatisfied </span> 
                            <span className='DissatisfiedDetail'></span>
                            <span  style={{marginLeft:"10px"}}> Satisfied</span>
                         </FormLabel>
                    </FormControl>

                            <TableContainer component={Paper} style={{ width: '70%' }}>
                                <Table className={classes.table} aria-label="table">
                                    <TableHead>
                                    <TableRow>
                                                <RadioGroup defaultValue="abc" aria-label="action" name="customized-radios" style={{ display: 'table-cell', top:0,left: 0, zIndex: 2, position: 'sticky' }}>
                                                <TableCell  style={{width:"15rem"}}></TableCell>
                                                    <TableCell style={{padding: 16, textAlign: 'center', fontWeight: 700,width:"3.938rem",height:"4.1rem",borderRight:"none",backgroundColor:"#e72600"}}>
                                                        <span style={{
                                                            color:"#fff"
                                                        }}> 1 </span>
                                                    </TableCell>
                                                    <TableCell style={{padding: 16, textAlign: 'center', fontWeight: 700 ,width:"4rem",height:"4rem",backgroundColor:"#f96500"}}>
                                                        <span style={{
                                                          color:"#fff"
                                                        }}>2</span>
                                                    </TableCell>
                                                    <TableCell style={{padding: 16, textAlign: 'center', fontWeight: 700 ,width:"4rem",height:"4rem",backgroundColor:"#ffe000"}}>
                                                        <span style={{
                                                          color:"#fff"
                                                        }}>3</span>
                                                    </TableCell>
                                                    <TableCell style={{padding: 16, textAlign: 'center', fontWeight: 700,width:"4rem",height:"4rem",backgroundColor:"#30ad00"}}>
                                                        <span style={{
                                                         color:"#fff"
                                                        }}>4</span>
                                                    </TableCell>
                                                    <TableCell style={{padding: 16, textAlign: 'center', fontWeight: 700 ,width:"4rem",height:"4rem",backgroundColor:"#218b00"}}>
                                                        <span style={{color:"#fff"
                                                        }}>5</span>
                                                    </TableCell>
                                                </RadioGroup>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {satisfactionIndexRatioLost.sort().map((row, i) => {
                                            const labelId = `enhanced-table-checkbox-${i}`;
                                            return (
                                                <TableRow key={i + 1}>
                                                    {/* <TableCell style={{ padding: '5px', textAlign: 'center' }}>{row?.id}</TableCell> */}
                                                    <RadioGroup aria-label='option' name="customized-radios"
                                                    value={row?.actionSelected}
                                                    style={{ display: 'block' }}>
                                          <TableCell className='discription' style={{ padding: '5px', fontWeight: 550, width:"15rem" }} component="th" scope="row"><p className='discript'>{row?.description}</p></TableCell>
                                                            <TableCell style={{ padding: 10, textAlign: 'center' }} component="th">
                                                                <Radio
                                                                    value='1'
                                                                    checked={row?.actionSelected === '1'}
                                                                    style={{
                                                                        backgroundColor: row?.actionSelected === '1' ? '#fde6f0' : '',
                                                                        borderRadius: row?.actionSelected === '1' ? '0px' : ''
                                                                    }}
                                                                    name="radio-button-demo"
                                                                    inputProps={{ 'aria-label': labelId }}
                                                                />
                                                            </TableCell>
                                                             <TableCell className='cellwidth' style={{ padding: 10, textAlign: 'center',width:"4rem"}} component="th">
                                                                <Radio
                                                                    value='2'
                                                                    checked={row?.actionSelected === '2'}
                                                                    name="radio-button-demo"
                                                                    inputProps={{ 'aria-label': labelId }}
                                                                    style={{
                                                                        backgroundColor: row?.actionSelected === '2' ? '#fde6f0' : '',
                                                                        borderRadius: row?.actionSelected === '2' ? '0px' : ''
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell className='cellwidth' style={{ padding: 10, textAlign: 'center',borderLeft:"none",width:"4rem" }} component="th">
                                                                <Radio
                                                                    value='3'
                                                                    checked={row?.actionSelected === '3'}
                                                                    name="radio-button-demo"
                                                                    inputProps={{ 'aria-label': labelId }}
                                                                    style={{
                                                                        backgroundColor: row?.actionSelected === '3' ? '#fde6f0' : '',
                                                                        borderRadius: row?.actionSelected === '3' ? '0px' : ''
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell className='cellwidth' style={{ padding: 10, textAlign: 'center',width:"4rem" }} component="th">
                                                                <Radio
                                                                    value='4'
                                                                    checked={row?.actionSelected === '4'}
                                                                    name="radio-button-demo"
                                                                    inputProps={{ 'aria-label': labelId }}
                                                                    style={{
                                                                        backgroundColor: row?.actionSelected === '4' ? '#fde6f0' : '',
                                                                        borderRadius: row?.actionSelected === '4' ? '0px' : ''
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell className='cellwidth' style={{ padding: 10, textAlign: 'center',width:"4rem" }} component="th">
                                                                <Radio
                                                                    value='5'
                                                                    checked={row?.actionSelected === '5'}
                                                                    name="radio-button-demo"
                                                                    inputProps={{ 'aria-label': labelId }}
                                                                    style={{
                                                                        backgroundColor: row?.actionSelected === '5' ? '#fde6f0' : '',
                                                                        borderRadius: row?.actionSelected === '5' ? '0px' : ''
                                                                    }}
                                                                />
                                                            </TableCell> 
                                                </RadioGroup>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                    <TableFooter>
                                            <TableRow key="last">
                                            <RadioGroup aria-label='option' name="customized-radios" style={{ display: 'table-cell' }}>
                                                <TableCell style={{width:"15rem"}}></TableCell>
                                                <TableCell style={{ padding: 19 }}><ThumbDownIcon style={{ color: "#e72600" }} /></TableCell>
                                                    <TableCell style={{ padding: 19 }}><ThumbDownIcon style={{ visibility: "hidden" }} /></TableCell>
                                                    <TableCell style={{ padding: 19 }}><ThumbDownIcon style={{ visibility: "hidden" }} /></TableCell>
                                                    <TableCell style={{ padding: 19 }}><ThumbDownIcon style={{ visibility: "hidden" }} /></TableCell>
                                                    <TableCell style={{ padding: 19 }}><ThumbUpIcon   style={{ color: "#218b00" ,marginLeft:10}} /></TableCell>
                                                </RadioGroup>
                                            </TableRow>
                                        </TableFooter>
                                </Table>
                            </TableContainer>
                        <br />
                        <FormControl component="fieldset" style={{ marginBottom: 20, marginTop: 20 }}>
                            <FormLabel component="legend" style={{ color: "#000" }}>Reason for not choosing Decorpot. * </FormLabel>
                            <RadioGroup aria-label="reasonNotChoose" name="reasonForNotChoosingDecorpot"
                                value={reasonForNotChoosingDecorpot}>
                                <FormControlLabel value="Price" control={<Radio color="primary" />} label="Price" />
                                <FormControlLabel value="Quality" control={<Radio color="primary" />} label="Quality" />
                                <FormControlLabel value="In-house-production-facility." control={<Radio color="primary" />} label="In-house production facility." />
                                <FormControlLabel value="Approach-of-point-of-contact" control={<Radio color="primary" />} label="Approach of point of contact" />
                                <FormControlLabel value="Other" control={<Radio color="primary" />} label="Other" />
                            </RadioGroup>
                            {
                                lostFormData.reasonForNotChoosingDecorpot === 'Other' &&
                                <TextField
                                    size="small"
                                    style={{ marginBottom: 20, textAlign: "center", width: "100%", backgroundColor: "#f0ebf8" }}
                                    name="otherReasonForNotChoosingDecorpot"
                                    value={otherReasonForNotChoosingDecorpot}
                                    variant="outlined"
                                    multiline
                                    rows={3}
                                    disabled
                                />
                            }
                        </FormControl>

                        <br />
                        <TextField
                            label="Additional comments *"
                            size="small"
                            style={{ marginBottom: 20, textAlign: "center", marginTop: 20, width: "50%", backgroundColor: "#f8f1fb" }}
                            name="comments"
                            value={commentsLost}
                            variant="outlined"
                            multiline
                            rows={3}
                            disabled
                        />
                        <br />
                    </div>
                </Paper>
            }

            {
                props.location.state.type === 'design' &&
                <Paper style={{ marginTop: 20, padding: 10, borderTop: "8px solid #673ab7",borderBottom:"8px solid rgb(93, 65, 173" }}>
                    <div style={{ padding: 10 }}>
                    <FormControl component="fieldset" style={{ marginBottom: 20, marginTop: 20 }}>
                    <FormLabel component="legend" style={{ color: "#000" }}>Satisfaction index -</FormLabel>
                    <FormLabel component="legend" style={{ color: "#000" }}> <span className='satisfiedDetail'> </span> <span style={{marginLeft:"10px"}}>Dissatisfied </span> 
                                             <span className='DissatisfiedDetail'></span>
                                            <span  style={{marginLeft:"10px"}}> Satisfied</span> </FormLabel>
                    </FormControl>
                            <TableContainer component={Paper} style={{ width: '70%' }}>
                                <Table className={classes.table} aria-label="table">
                                    <TableHead>
                                    <TableRow>
                                                <RadioGroup defaultValue="abc" aria-label="action" name="customized-radios" style={{ display: 'table-cell', top:0,left: 0, zIndex: 2, position: 'sticky' }}>
                                                <TableCell  style={{width:"15rem"}}></TableCell>
                                                    <TableCell style={{padding: 16, textAlign: 'center', fontWeight: 700,width:"3.938rem",height:"4.1rem",borderRight:"none",backgroundColor:"#e72600"}}>
                                                        <span style={{
                                                            color:"#fff"
                                                        }}> 1 </span>
                                                    </TableCell>
                                                    <TableCell style={{padding: 16, textAlign: 'center', fontWeight: 700 ,width:"4rem",height:"4rem",backgroundColor:"#f96500"}}>
                                                        <span style={{
                                                          color:"#fff"
                                                        }}>2</span>
                                                    </TableCell>
                                                    <TableCell style={{padding: 16, textAlign: 'center', fontWeight: 700 ,width:"4rem",height:"4rem",backgroundColor:"#ffe000"}}>
                                                        <span style={{
                                                          color:"#fff"
                                                        }}>3</span>
                                                    </TableCell>
                                                    <TableCell style={{padding: 16, textAlign: 'center', fontWeight: 700,width:"4rem",height:"4rem",backgroundColor:"#30ad00"}}>
                                                        <span style={{
                                                         color:"#fff"
                                                        }}>4</span>
                                                    </TableCell>
                                                    <TableCell style={{padding: 16, textAlign: 'center', fontWeight: 700 ,width:"4rem",height:"4rem",backgroundColor:"#218b00"}}>
                                                        <span style={{color:"#fff"
                                                        }}>5</span>
                                                    </TableCell>
                                                </RadioGroup>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {satisfactionIndexRatioDesign.sort().map((row, i) => {
                                            const labelId = `enhanced-table-checkbox-${i}`;
                                            return (
                                                <TableRow key={i + 1}>
                                                    <RadioGroup aria-label='option' name="customized-radios"
                                                    value={row?.actionSelected}
                                                    style={{ display: 'block' }}>
                                          <TableCell className='discription' style={{ padding: '5px', fontWeight: 550, width:"15rem" }} component="th" scope="row"><p className='discript'>{row?.description}</p></TableCell>
                                                            <TableCell style={{ padding: 10, textAlign: 'center' }} component="th">
                                                                <Radio
                                                                    value='1'
                                                                    checked={row?.actionSelected === '1'}
                                                                    style={{
                                                                        backgroundColor: row?.actionSelected === '1' ? '#fde6f0' : '',
                                                                        borderRadius: row?.actionSelected === '1' ? '0px' : ''
                                                                    }}
                                                                    name="radio-button-demo"
                                                                    inputProps={{ 'aria-label': labelId }}
                                                                />
                                                            </TableCell>
                                                             <TableCell className='cellwidth' style={{ padding: 10, textAlign: 'center',width:"4rem"}} component="th">
                                                                <Radio
                                                                    value='2'
                                                                    checked={row?.actionSelected === '2'}
                                                                    name="radio-button-demo"
                                                                    inputProps={{ 'aria-label': labelId }}
                                                                    style={{
                                                                        backgroundColor: row?.actionSelected === '2' ? '#fde6f0' : '',
                                                                        borderRadius: row?.actionSelected === '2' ? '0px' : ''
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell className='cellwidth' style={{ padding: 10, textAlign: 'center',borderLeft:"none",width:"4rem" }} component="th">
                                                                <Radio
                                                                    value='3'
                                                                    checked={row?.actionSelected === '3'}
                                                                    name="radio-button-demo"
                                                                    inputProps={{ 'aria-label': labelId }}
                                                                    style={{
                                                                        backgroundColor: row?.actionSelected === '3' ? '#fde6f0' : '',
                                                                        borderRadius: row?.actionSelected === '3' ? '0px' : ''
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell className='cellwidth' style={{ padding: 10, textAlign: 'center',width:"4rem" }} component="th">
                                                                <Radio
                                                                    value='4'
                                                                    checked={row?.actionSelected === '4'}
                                                                    name="radio-button-demo"
                                                                    inputProps={{ 'aria-label': labelId }}
                                                                    style={{
                                                                        backgroundColor: row?.actionSelected === '4' ? '#fde6f0' : '',
                                                                        borderRadius: row?.actionSelected === '4' ? '0px' : ''
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell className='cellwidth' style={{ padding: 10, textAlign: 'center',width:"4rem" }} component="th">
                                                                <Radio
                                                                    value='5'
                                                                    checked={row?.actionSelected === '5'}
                                                                    name="radio-button-demo"
                                                                    inputProps={{ 'aria-label': labelId }}
                                                                    style={{
                                                                        backgroundColor: row?.actionSelected === '5' ? '#fde6f0' : '',
                                                                        borderRadius: row?.actionSelected === '5' ? '0px' : ''
                                                                    }}
                                                                />
                                                            </TableCell> 
                                                </RadioGroup>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                    <TableFooter>
                                            <TableRow key="last">
                                            <RadioGroup aria-label='option' name="customized-radios" style={{ display: 'table-cell' }}>
                                                <TableCell style={{width:"15rem"}}></TableCell>
                                                <TableCell style={{ padding: 19 }}><ThumbDownIcon style={{ color: "#e72600" }} /></TableCell>
                                                    <TableCell style={{ padding: 19 }}><ThumbDownIcon style={{ visibility: "hidden" }} /></TableCell>
                                                    <TableCell style={{ padding: 19 }}><ThumbDownIcon style={{ visibility: "hidden" }} /></TableCell>
                                                    <TableCell style={{ padding: 19 }}><ThumbDownIcon style={{ visibility: "hidden" }} /></TableCell>
                                                    <TableCell style={{ padding: 19 }}><ThumbUpIcon   style={{ color: "#218b00" ,marginLeft:10}} /></TableCell>
                                                </RadioGroup>
                                            </TableRow>
                                        </TableFooter>
                                </Table>
                            </TableContainer>
                        <br />

                        <FormControl component="fieldset" style={{ marginBottom: 20, marginTop: 20 }}>
                            <FormLabel component="legend" style={{ color: "#000" }}>Was the Design Manager involved in your interior design journey? * </FormLabel>
                            <RadioGroup aria-label="designManagerInvolved" name="isDesignManagerInvolved"
                                value={isDesignManagerInvolved}>
                                <FormControlLabel value="Yes" control={<Radio color="primary" />} label="Yes" />
                                <FormControlLabel value="No" control={<Radio color="primary" />} label="No" />
                                <FormControlLabel value="Not-applicable" control={<Radio color="primary" />} label="Not applicable" />
                            </RadioGroup>
                        <br />
</FormControl>
                        {
                            designFormData.isDesignManagerInvolved === 'Yes' && <>
                   <br></br>
                   <FormControl component="fieldset" style={{ marginBottom: 20, marginTop: 20 }}>
                    <FormLabel component="legend" style={{ color: "#000" }}>Satisfaction index -</FormLabel>
                    <FormLabel component="legend" style={{ color: "#000" }}> <span className='satisfiedDetail'> </span> <span style={{marginLeft:"10px"}}>Dissatisfied </span> 
                                             <span className='DissatisfiedDetail'></span>
                                            <span  style={{marginLeft:"10px"}}> Satisfied</span> </FormLabel>
                    </FormControl>

                    <TableContainer component={Paper} style={{ width: '70%' }}>
                                <Table className={classes.table} aria-label="table">
                                    <TableHead>
                                    <TableRow>
                                                <RadioGroup defaultValue="abc" aria-label="action" name="customized-radios" style={{ display: 'table-cell', top:0,left: 0, zIndex: 2, position: 'sticky' }}>
                                                <TableCell  style={{width:"15rem"}}></TableCell>
                                                    <TableCell style={{padding: 16, textAlign: 'center', fontWeight: 700,width:"3.938rem",height:"4.1rem",borderRight:"none",backgroundColor:"#e72600"}}>
                                                        <span style={{
                                                            color:"#fff"
                                                        }}> 1 </span>
                                                    </TableCell>
                                                    <TableCell style={{padding: 16, textAlign: 'center', fontWeight: 700 ,width:"4rem",height:"4rem",backgroundColor:"#f96500"}}>
                                                        <span style={{
                                                          color:"#fff"
                                                        }}>2</span>
                                                    </TableCell>
                                                    <TableCell style={{padding: 16, textAlign: 'center', fontWeight: 700 ,width:"4rem",height:"4rem",backgroundColor:"#ffe000"}}>
                                                        <span style={{
                                                          color:"#fff"
                                                        }}>3</span>
                                                    </TableCell>
                                                    <TableCell style={{padding: 16, textAlign: 'center', fontWeight: 700,width:"4rem",height:"4rem",backgroundColor:"#30ad00"}}>
                                                        <span style={{
                                                         color:"#fff"
                                                        }}>4</span>
                                                    </TableCell>
                                                    <TableCell style={{padding: 16, textAlign: 'center', fontWeight: 700 ,width:"4rem",height:"4rem",backgroundColor:"#218b00"}}>
                                                        <span style={{color:"#fff"
                                                        }}>5</span>
                                                    </TableCell>
                                                </RadioGroup>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                                {satisfactionIndexForDesignManager.sort().map((row, i) => {
                                                    const labelId = `enhanced-table-checkbox-${i}`;
                                                    return (
                                                        <TableRow key={i + 1}>
                                                            {/* <TableCell style={{ padding: '5px', textAlign: 'center' }}>{row?.id}</TableCell> */}
                                                            <RadioGroup aria-label='option' name="customized-radios"
                                                    value={row?.actionSelected}
                                                    style={{ display: 'block' }}>
                                          <TableCell className='discription' style={{ padding: '5px', fontWeight: 550, width:"15rem" }} component="th" scope="row"><p className='discript'>{row?.description}</p></TableCell>
                                                            <TableCell style={{ padding: 10, textAlign: 'center' }} component="th">
                                                                <Radio
                                                                    value='1'
                                                                    checked={row?.actionSelected === '1'}
                                                                    style={{
                                                                        backgroundColor: row?.actionSelected === '1' ? '#fde6f0' : '',
                                                                        borderRadius: row?.actionSelected === '1' ? '0px' : ''
                                                                    }}
                                                                    name="radio-button-demo"
                                                                    inputProps={{ 'aria-label': labelId }}
                                                                />
                                                            </TableCell>
                                                             <TableCell className='cellwidth' style={{ padding: 10, textAlign: 'center',width:"4rem"}} component="th">
                                                                <Radio
                                                                    value='2'
                                                                    checked={row?.actionSelected === '2'}
                                                                    name="radio-button-demo"
                                                                    inputProps={{ 'aria-label': labelId }}
                                                                    style={{
                                                                        backgroundColor: row?.actionSelected === '2' ? '#fde6f0' : '',
                                                                        borderRadius: row?.actionSelected === '2' ? '0px' : ''
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell className='cellwidth' style={{ padding: 10, textAlign: 'center',borderLeft:"none",width:"4rem" }} component="th">
                                                                <Radio
                                                                    value='3'
                                                                    checked={row?.actionSelected === '3'}
                                                                    name="radio-button-demo"
                                                                    inputProps={{ 'aria-label': labelId }}
                                                                    style={{
                                                                        backgroundColor: row?.actionSelected === '3' ? '#fde6f0' : '',
                                                                        borderRadius: row?.actionSelected === '3' ? '0px' : ''
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell className='cellwidth' style={{ padding: 10, textAlign: 'center',width:"4rem" }} component="th">
                                                                <Radio
                                                                    value='4'
                                                                    checked={row?.actionSelected === '4'}
                                                                    name="radio-button-demo"
                                                                    inputProps={{ 'aria-label': labelId }}
                                                                    style={{
                                                                        backgroundColor: row?.actionSelected === '4' ? '#fde6f0' : '',
                                                                        borderRadius: row?.actionSelected === '4' ? '0px' : ''
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell className='cellwidth' style={{ padding: 10, textAlign: 'center',width:"4rem" }} component="th">
                                                                <Radio
                                                                    value='5'
                                                                    checked={row?.actionSelected === '5'}
                                                                    name="radio-button-demo"
                                                                    inputProps={{ 'aria-label': labelId }}
                                                                    style={{
                                                                        backgroundColor: row?.actionSelected === '5' ? '#fde6f0' : '',
                                                                        borderRadius: row?.actionSelected === '5' ? '0px' : ''
                                                                    }}
                                                                />
                                                            </TableCell> 
                                                </RadioGroup>                                                       </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                            <TableFooter>
                                            <TableRow key="last">
                                            <RadioGroup aria-label='option' name="customized-radios" style={{ display: 'table-cell' }}>
                                                <TableCell style={{width:"15rem"}}></TableCell>
                                                <TableCell style={{ padding: 19 }}><ThumbDownIcon style={{ color: "#e72600" }} /></TableCell>
                                                    <TableCell style={{ padding: 19 }}><ThumbDownIcon style={{ visibility: "hidden" }} /></TableCell>
                                                    <TableCell style={{ padding: 19 }}><ThumbDownIcon style={{ visibility: "hidden" }} /></TableCell>
                                                    <TableCell style={{ padding: 19 }}><ThumbDownIcon style={{ visibility: "hidden" }} /></TableCell>
                                                    <TableCell style={{ padding: 19 }}><ThumbUpIcon   style={{ color: "#218b00" ,marginLeft:10}} /></TableCell>
                                                </RadioGroup>
                                            </TableRow>
                                        </TableFooter>
                                        </Table>
                                    </TableContainer>
                                <br />
                            </>
                        }
                        <br></br>
                         <TextField
                        label="Additional comments *"
                        size="small"
                            style={{ marginBottom: 20, textAlign: "center", marginTop: 20, width: "50%", backgroundColor: "#f8f1fb" }}
                        name="feedbackDesign"
                        value={feedbackDesign}
                        variant="outlined"
                        multiline
                        rows={3}
                        disabled
                    />
                        <br />

                    </div>
                </Paper>
            }

            {
                props.location.state.type === 'design-lost' &&
                <Paper style={{ marginTop: 20, padding: 10, borderTop: "8px solid #673ab7", borderBottom: "8px solid rgb(93, 65, 173" }}>
                    <div style={{ padding: 10 }}>
                        <FormControl component="fieldset" style={{ marginBottom: 20, marginTop: 20 }}>
                            <FormLabel component="legend" style={{ color: "#000" }}>Satisfaction index -</FormLabel>
                            <FormLabel component="legend" style={{ color: "#000" }}> <span className='satisfiedDetail'> </span> <span style={{ marginLeft: "10px" }}>Dissatisfied </span>
                                <span className='DissatisfiedDetail'></span>
                                <span style={{ marginLeft: "10px" }}> Satisfied</span> </FormLabel>
                        </FormControl>
                        <TableContainer component={Paper} style={{ width: '70%' }}>
                            <Table className={classes.table} aria-label="table">
                                <TableHead>
                                    <TableRow>
                                        <RadioGroup defaultValue="abc" aria-label="action" name="customized-radios" style={{ display: 'table-cell', top: 0, left: 0, zIndex: 2, position: 'sticky' }}>
                                            <TableCell style={{ width: "15rem" }}></TableCell>
                                            <TableCell style={{ padding: 16, textAlign: 'center', fontWeight: 700, width: "3.938rem", height: "4.1rem", borderRight: "none", backgroundColor: "#e72600" }}>
                                                <span style={{
                                                    color: "#fff"
                                                }}> 1 </span>
                                            </TableCell>
                                            <TableCell style={{ padding: 16, textAlign: 'center', fontWeight: 700, width: "4rem", height: "4rem", backgroundColor: "#f96500" }}>
                                                <span style={{
                                                    color: "#fff"
                                                }}>2</span>
                                            </TableCell>
                                            <TableCell style={{ padding: 16, textAlign: 'center', fontWeight: 700, width: "4rem", height: "4rem", backgroundColor: "#ffe000" }}>
                                                <span style={{
                                                    color: "#fff"
                                                }}>3</span>
                                            </TableCell>
                                            <TableCell style={{ padding: 16, textAlign: 'center', fontWeight: 700, width: "4rem", height: "4rem", backgroundColor: "#30ad00" }}>
                                                <span style={{
                                                    color: "#fff"
                                                }}>4</span>
                                            </TableCell>
                                            <TableCell style={{ padding: 16, textAlign: 'center', fontWeight: 700, width: "4rem", height: "4rem", backgroundColor: "#218b00" }}>
                                                <span style={{
                                                    color: "#fff"
                                                }}>5</span>
                                            </TableCell>
                                        </RadioGroup>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                        {satisfactionIndexRatioDesignLost.sort().map((row, i) => {
                                        const labelId = `enhanced-table-checkbox-${i}`;
                                        return (
                                            <TableRow key={i + 1}>
                                                <RadioGroup aria-label='option' name="customized-radios"
                                                    value={row?.actionSelected}
                                                    style={{ display: 'block' }}>
                                                    <TableCell className='discription' style={{ padding: '5px', fontWeight: 550, width: "15rem" }} component="th" scope="row"><p className='discript'>{row?.description}</p></TableCell>
                                                    <TableCell style={{ padding: 10, textAlign: 'center' }} component="th">
                                                        <Radio
                                                            value='1'
                                                            checked={row?.actionSelected === '1'}
                                                            style={{
                                                                backgroundColor: row?.actionSelected === '1' ? '#fde6f0' : '',
                                                                borderRadius: row?.actionSelected === '1' ? '0px' : ''
                                                            }}
                                                            name="radio-button-demo"
                                                            inputProps={{ 'aria-label': labelId }}
                                                        />
                                                    </TableCell>
                                                    <TableCell className='cellwidth' style={{ padding: 10, textAlign: 'center', width: "4rem" }} component="th">
                                                        <Radio
                                                            value='2'
                                                            checked={row?.actionSelected === '2'}
                                                            name="radio-button-demo"
                                                            inputProps={{ 'aria-label': labelId }}
                                                            style={{
                                                                backgroundColor: row?.actionSelected === '2' ? '#fde6f0' : '',
                                                                borderRadius: row?.actionSelected === '2' ? '0px' : ''
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell className='cellwidth' style={{ padding: 10, textAlign: 'center', borderLeft: "none", width: "4rem" }} component="th">
                                                        <Radio
                                                            value='3'
                                                            checked={row?.actionSelected === '3'}
                                                            name="radio-button-demo"
                                                            inputProps={{ 'aria-label': labelId }}
                                                            style={{
                                                                backgroundColor: row?.actionSelected === '3' ? '#fde6f0' : '',
                                                                borderRadius: row?.actionSelected === '3' ? '0px' : ''
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell className='cellwidth' style={{ padding: 10, textAlign: 'center', width: "4rem" }} component="th">
                                                        <Radio
                                                            value='4'
                                                            checked={row?.actionSelected === '4'}
                                                            name="radio-button-demo"
                                                            inputProps={{ 'aria-label': labelId }}
                                                            style={{
                                                                backgroundColor: row?.actionSelected === '4' ? '#fde6f0' : '',
                                                                borderRadius: row?.actionSelected === '4' ? '0px' : ''
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell className='cellwidth' style={{ padding: 10, textAlign: 'center', width: "4rem" }} component="th">
                                                        <Radio
                                                            value='5'
                                                            checked={row?.actionSelected === '5'}
                                                            name="radio-button-demo"
                                                            inputProps={{ 'aria-label': labelId }}
                                                            style={{
                                                                backgroundColor: row?.actionSelected === '5' ? '#fde6f0' : '',
                                                                borderRadius: row?.actionSelected === '5' ? '0px' : ''
                                                            }}
                                                        />
                                                    </TableCell>
                                                </RadioGroup>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                                <TableFooter>
                                    <TableRow key="last">
                                        <RadioGroup aria-label='option' name="customized-radios" style={{ display: 'table-cell' }}>
                                            <TableCell style={{ width: "15rem" }}></TableCell>
                                            <TableCell style={{ padding: 19 }}><ThumbDownIcon style={{ color: "#e72600" }} /></TableCell>
                                            <TableCell style={{ padding: 19 }}><ThumbDownIcon style={{ visibility: "hidden" }} /></TableCell>
                                            <TableCell style={{ padding: 19 }}><ThumbDownIcon style={{ visibility: "hidden" }} /></TableCell>
                                            <TableCell style={{ padding: 19 }}><ThumbDownIcon style={{ visibility: "hidden" }} /></TableCell>
                                            <TableCell style={{ padding: 19 }}><ThumbUpIcon style={{ color: "#218b00", marginLeft: 10 }} /></TableCell>
                                        </RadioGroup>
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                        <br />

                        <FormControl component="fieldset" style={{ marginBottom: 20, marginTop: 20 }}>
                            <FormLabel component="legend" style={{ color: "#000" }}>Was the Design Manager involved in your interior design journey? * </FormLabel>
                            <RadioGroup aria-label="designManagerInvolved" name="isDesignManagerInvolved"
                                    value={isDesignManagerInvolvedLost}>
                                <FormControlLabel value="Yes" control={<Radio color="primary" />} label="Yes" />
                                <FormControlLabel value="No" control={<Radio color="primary" />} label="No" />
                                <FormControlLabel value="Not-applicable" control={<Radio color="primary" />} label="Not applicable" />
                            </RadioGroup>
                            <br />
                        </FormControl>
                        {
                                designLostFormData.isDesignManagerInvolvedLost === 'Yes' && <>
                                <br></br>
                                <FormControl component="fieldset" style={{ marginBottom: 20, marginTop: 20 }}>
                                    <FormLabel component="legend" style={{ color: "#000" }}>Satisfaction index -</FormLabel>
                                    <FormLabel component="legend" style={{ color: "#000" }}> <span className='satisfiedDetail'> </span> <span style={{ marginLeft: "10px" }}>Dissatisfied </span>
                                        <span className='DissatisfiedDetail'></span>
                                        <span style={{ marginLeft: "10px" }}> Satisfied</span> </FormLabel>
                                </FormControl>

                                <TableContainer component={Paper} style={{ width: '70%' }}>
                                    <Table className={classes.table} aria-label="table">
                                        <TableHead>
                                            <TableRow>
                                                <RadioGroup defaultValue="abc" aria-label="action" name="customized-radios" style={{ display: 'table-cell', top: 0, left: 0, zIndex: 2, position: 'sticky' }}>
                                                    <TableCell style={{ width: "15rem" }}></TableCell>
                                                    <TableCell style={{ padding: 16, textAlign: 'center', fontWeight: 700, width: "3.938rem", height: "4.1rem", borderRight: "none", backgroundColor: "#e72600" }}>
                                                        <span style={{
                                                            color: "#fff"
                                                        }}> 1 </span>
                                                    </TableCell>
                                                    <TableCell style={{ padding: 16, textAlign: 'center', fontWeight: 700, width: "4rem", height: "4rem", backgroundColor: "#f96500" }}>
                                                        <span style={{
                                                            color: "#fff"
                                                        }}>2</span>
                                                    </TableCell>
                                                    <TableCell style={{ padding: 16, textAlign: 'center', fontWeight: 700, width: "4rem", height: "4rem", backgroundColor: "#ffe000" }}>
                                                        <span style={{
                                                            color: "#fff"
                                                        }}>3</span>
                                                    </TableCell>
                                                    <TableCell style={{ padding: 16, textAlign: 'center', fontWeight: 700, width: "4rem", height: "4rem", backgroundColor: "#30ad00" }}>
                                                        <span style={{
                                                            color: "#fff"
                                                        }}>4</span>
                                                    </TableCell>
                                                    <TableCell style={{ padding: 16, textAlign: 'center', fontWeight: 700, width: "4rem", height: "4rem", backgroundColor: "#218b00" }}>
                                                        <span style={{
                                                            color: "#fff"
                                                        }}>5</span>
                                                    </TableCell>
                                                </RadioGroup>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                                {satisfactionIndexForDesignManagerLost.sort().map((row, i) => {
                                                const labelId = `enhanced-table-checkbox-${i}`;
                                                return (
                                                    <TableRow key={i + 1}>
                                                        {/* <TableCell style={{ padding: '5px', textAlign: 'center' }}>{row?.id}</TableCell> */}
                                                        <RadioGroup aria-label='option' name="customized-radios"
                                                            value={row?.actionSelected}
                                                            style={{ display: 'block' }}>
                                                            <TableCell className='discription' style={{ padding: '5px', fontWeight: 550, width: "15rem" }} component="th" scope="row"><p className='discript'>{row?.description}</p></TableCell>
                                                            <TableCell style={{ padding: 10, textAlign: 'center' }} component="th">
                                                                <Radio
                                                                    value='1'
                                                                    checked={row?.actionSelected === '1'}
                                                                    style={{
                                                                        backgroundColor: row?.actionSelected === '1' ? '#fde6f0' : '',
                                                                        borderRadius: row?.actionSelected === '1' ? '0px' : ''
                                                                    }}
                                                                    name="radio-button-demo"
                                                                    inputProps={{ 'aria-label': labelId }}
                                                                />
                                                            </TableCell>
                                                            <TableCell className='cellwidth' style={{ padding: 10, textAlign: 'center', width: "4rem" }} component="th">
                                                                <Radio
                                                                    value='2'
                                                                    checked={row?.actionSelected === '2'}
                                                                    name="radio-button-demo"
                                                                    inputProps={{ 'aria-label': labelId }}
                                                                    style={{
                                                                        backgroundColor: row?.actionSelected === '2' ? '#fde6f0' : '',
                                                                        borderRadius: row?.actionSelected === '2' ? '0px' : ''
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell className='cellwidth' style={{ padding: 10, textAlign: 'center', borderLeft: "none", width: "4rem" }} component="th">
                                                                <Radio
                                                                    value='3'
                                                                    checked={row?.actionSelected === '3'}
                                                                    name="radio-button-demo"
                                                                    inputProps={{ 'aria-label': labelId }}
                                                                    style={{
                                                                        backgroundColor: row?.actionSelected === '3' ? '#fde6f0' : '',
                                                                        borderRadius: row?.actionSelected === '3' ? '0px' : ''
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell className='cellwidth' style={{ padding: 10, textAlign: 'center', width: "4rem" }} component="th">
                                                                <Radio
                                                                    value='4'
                                                                    checked={row?.actionSelected === '4'}
                                                                    name="radio-button-demo"
                                                                    inputProps={{ 'aria-label': labelId }}
                                                                    style={{
                                                                        backgroundColor: row?.actionSelected === '4' ? '#fde6f0' : '',
                                                                        borderRadius: row?.actionSelected === '4' ? '0px' : ''
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell className='cellwidth' style={{ padding: 10, textAlign: 'center', width: "4rem" }} component="th">
                                                                <Radio
                                                                    value='5'
                                                                    checked={row?.actionSelected === '5'}
                                                                    name="radio-button-demo"
                                                                    inputProps={{ 'aria-label': labelId }}
                                                                    style={{
                                                                        backgroundColor: row?.actionSelected === '5' ? '#fde6f0' : '',
                                                                        borderRadius: row?.actionSelected === '5' ? '0px' : ''
                                                                    }}
                                                                />
                                                            </TableCell>
                                                        </RadioGroup>                                                       </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow key="last">
                                                <RadioGroup aria-label='option' name="customized-radios" style={{ display: 'table-cell' }}>
                                                    <TableCell style={{ width: "15rem" }}></TableCell>
                                                    <TableCell style={{ padding: 19 }}><ThumbDownIcon style={{ color: "#e72600" }} /></TableCell>
                                                    <TableCell style={{ padding: 19 }}><ThumbDownIcon style={{ visibility: "hidden" }} /></TableCell>
                                                    <TableCell style={{ padding: 19 }}><ThumbDownIcon style={{ visibility: "hidden" }} /></TableCell>
                                                    <TableCell style={{ padding: 19 }}><ThumbDownIcon style={{ visibility: "hidden" }} /></TableCell>
                                                    <TableCell style={{ padding: 19 }}><ThumbUpIcon style={{ color: "#218b00", marginLeft: 10 }} /></TableCell>
                                                </RadioGroup>
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                                <br />
                            </>
                        }
                            <br />

                            <FormControl component="fieldset" style={{ marginBottom: 20, marginTop: 20 }}>
                                <FormLabel component="legend" style={{ color: "#000" }}>Was the CHM involved in your interior design journey? * </FormLabel>
                                <RadioGroup aria-label="designManagerInvolved" name="isDesignManagerInvolved"
                                    value={isChmInvolvedLost}>
                                    <FormControlLabel value="Yes" control={<Radio color="primary" />} label="Yes" />
                                    <FormControlLabel value="No" control={<Radio color="primary" />} label="No" />
                                    <FormControlLabel value="Not-applicable" control={<Radio color="primary" />} label="Not applicable" />
                                </RadioGroup>
                                <br />
                            </FormControl>
                            {
                                designLostFormData.isChmInvolvedLost === 'Yes' && <>
                                    <br></br>
                                    <FormControl component="fieldset" style={{ marginBottom: 20, marginTop: 20 }}>
                                        <FormLabel component="legend" style={{ color: "#000" }}>Satisfaction index -</FormLabel>
                                        <FormLabel component="legend" style={{ color: "#000" }}> <span className='satisfiedDetail'> </span> <span style={{ marginLeft: "10px" }}>Dissatisfied </span>
                                            <span className='DissatisfiedDetail'></span>
                                            <span style={{ marginLeft: "10px" }}> Satisfied</span> </FormLabel>
                                    </FormControl>

                                    <TableContainer component={Paper} style={{ width: '70%' }}>
                                        <Table className={classes.table} aria-label="table">
                                            <TableHead>
                                                <TableRow>
                                                    <RadioGroup defaultValue="abc" aria-label="action" name="customized-radios" style={{ display: 'table-cell', top: 0, left: 0, zIndex: 2, position: 'sticky' }}>
                                                        <TableCell style={{ width: "15rem" }}></TableCell>
                                                        <TableCell style={{ padding: 16, textAlign: 'center', fontWeight: 700, width: "3.938rem", height: "4.1rem", borderRight: "none", backgroundColor: "#e72600" }}>
                                                            <span style={{
                                                                color: "#fff"
                                                            }}> 1 </span>
                                                        </TableCell>
                                                        <TableCell style={{ padding: 16, textAlign: 'center', fontWeight: 700, width: "4rem", height: "4rem", backgroundColor: "#f96500" }}>
                                                            <span style={{
                                                                color: "#fff"
                                                            }}>2</span>
                                                        </TableCell>
                                                        <TableCell style={{ padding: 16, textAlign: 'center', fontWeight: 700, width: "4rem", height: "4rem", backgroundColor: "#ffe000" }}>
                                                            <span style={{
                                                                color: "#fff"
                                                            }}>3</span>
                                                        </TableCell>
                                                        <TableCell style={{ padding: 16, textAlign: 'center', fontWeight: 700, width: "4rem", height: "4rem", backgroundColor: "#30ad00" }}>
                                                            <span style={{
                                                                color: "#fff"
                                                            }}>4</span>
                                                        </TableCell>
                                                        <TableCell style={{ padding: 16, textAlign: 'center', fontWeight: 700, width: "4rem", height: "4rem", backgroundColor: "#218b00" }}>
                                                            <span style={{
                                                                color: "#fff"
                                                            }}>5</span>
                                                        </TableCell>
                                                    </RadioGroup>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {satisfactionIndexForChmLost.sort().map((row, i) => {
                                                    const labelId = `enhanced-table-checkbox-${i}`;
                                                    return (
                                                        <TableRow key={i + 1}>
                                                            {/* <TableCell style={{ padding: '5px', textAlign: 'center' }}>{row?.id}</TableCell> */}
                                                            <RadioGroup aria-label='option' name="customized-radios"
                                                                value={row?.actionSelected}
                                                                style={{ display: 'block' }}>
                                                                <TableCell className='discription' style={{ padding: '5px', fontWeight: 550, width: "15rem" }} component="th" scope="row"><p className='discript'>{row?.description}</p></TableCell>
                                                                <TableCell style={{ padding: 10, textAlign: 'center' }} component="th">
                                                                    <Radio
                                                                        value='1'
                                                                        checked={row?.actionSelected === '1'}
                                                                        style={{
                                                                            backgroundColor: row?.actionSelected === '1' ? '#fde6f0' : '',
                                                                            borderRadius: row?.actionSelected === '1' ? '0px' : ''
                                                                        }}
                                                                        name="radio-button-demo"
                                                                        inputProps={{ 'aria-label': labelId }}
                                                                    />
                                                                </TableCell>
                                                                <TableCell className='cellwidth' style={{ padding: 10, textAlign: 'center', width: "4rem" }} component="th">
                                                                    <Radio
                                                                        value='2'
                                                                        checked={row?.actionSelected === '2'}
                                                                        name="radio-button-demo"
                                                                        inputProps={{ 'aria-label': labelId }}
                                                                        style={{
                                                                            backgroundColor: row?.actionSelected === '2' ? '#fde6f0' : '',
                                                                            borderRadius: row?.actionSelected === '2' ? '0px' : ''
                                                                        }}
                                                                    />
                                                                </TableCell>
                                                                <TableCell className='cellwidth' style={{ padding: 10, textAlign: 'center', borderLeft: "none", width: "4rem" }} component="th">
                                                                    <Radio
                                                                        value='3'
                                                                        checked={row?.actionSelected === '3'}
                                                                        name="radio-button-demo"
                                                                        inputProps={{ 'aria-label': labelId }}
                                                                        style={{
                                                                            backgroundColor: row?.actionSelected === '3' ? '#fde6f0' : '',
                                                                            borderRadius: row?.actionSelected === '3' ? '0px' : ''
                                                                        }}
                                                                    />
                                                                </TableCell>
                                                                <TableCell className='cellwidth' style={{ padding: 10, textAlign: 'center', width: "4rem" }} component="th">
                                                                    <Radio
                                                                        value='4'
                                                                        checked={row?.actionSelected === '4'}
                                                                        name="radio-button-demo"
                                                                        inputProps={{ 'aria-label': labelId }}
                                                                        style={{
                                                                            backgroundColor: row?.actionSelected === '4' ? '#fde6f0' : '',
                                                                            borderRadius: row?.actionSelected === '4' ? '0px' : ''
                                                                        }}
                                                                    />
                                                                </TableCell>
                                                                <TableCell className='cellwidth' style={{ padding: 10, textAlign: 'center', width: "4rem" }} component="th">
                                                                    <Radio
                                                                        value='5'
                                                                        checked={row?.actionSelected === '5'}
                                                                        name="radio-button-demo"
                                                                        inputProps={{ 'aria-label': labelId }}
                                                                        style={{
                                                                            backgroundColor: row?.actionSelected === '5' ? '#fde6f0' : '',
                                                                            borderRadius: row?.actionSelected === '5' ? '0px' : ''
                                                                        }}
                                                                    />
                                                                </TableCell>
                                                            </RadioGroup>                                                       </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                            <TableFooter>
                                                <TableRow key="last">
                                                    <RadioGroup aria-label='option' name="customized-radios" style={{ display: 'table-cell' }}>
                                                        <TableCell style={{ width: "15rem" }}></TableCell>
                                                        <TableCell style={{ padding: 19 }}><ThumbDownIcon style={{ color: "#e72600" }} /></TableCell>
                                                        <TableCell style={{ padding: 19 }}><ThumbDownIcon style={{ visibility: "hidden" }} /></TableCell>
                                                        <TableCell style={{ padding: 19 }}><ThumbDownIcon style={{ visibility: "hidden" }} /></TableCell>
                                                        <TableCell style={{ padding: 19 }}><ThumbDownIcon style={{ visibility: "hidden" }} /></TableCell>
                                                        <TableCell style={{ padding: 19 }}><ThumbUpIcon style={{ color: "#218b00", marginLeft: 10 }} /></TableCell>
                                                    </RadioGroup>
                                                </TableRow>
                                            </TableFooter>
                                        </Table>
                                    </TableContainer>
                                    <br />
                                </>
                            }
                        <br></br>
                            <FormControl style={{ marginBottom: 20, textAlign: "center", marginTop: 20, width: "40%" }}>
                                <InputLabel id="demo-simple-select-label" style={{ color: "#000" }}>Reason for not moving ahead</InputLabel>
                                <br></br>
                                <Select
                                    name="reasonNotMovingAhead"
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Reason for not moving ahead"
                                    //value={reasonForNotMovingAhead}
                                    disabled
                                >
                                </Select>
                                <MenuItem >{reasonForNotMovingAhead}</MenuItem>
                                <br />
                            </FormControl>
                        <br></br>
                        <TextField
                            label="Additional comments *"
                            size="small"
                            style={{ marginBottom: 20, textAlign: "center", marginTop: 20, width: "50%", backgroundColor: "#f8f1fb" }}
                            name="feedbackDesignLost"
                                value={feedbackDesignLost}
                            variant="outlined"
                            multiline
                            rows={3}
                            disabled
                        />
                        <br />

                    </div>
                </Paper>
            }

            {
                props.location.state.type === 'execution' &&
                <Paper style={{ marginTop: 20, padding: 10, borderTop: "8px solid #673ab7",borderBottom:"8px solid rgb(93, 65, 173" }}>
                    <FormControl component="fieldset" style={{ marginBottom: 20, marginTop: 20 }}>
                        <FormLabel component="legend" style={{ color: "#000" }}>How satisfied are you with the execution and delivery process? *</FormLabel>
                        <RadioGroup row aria-label="position" name="howSatisfiedWithExecutionAndDelivery" defaultValue="top"
                            value={howSatisfiedWithExecutionAndDelivery}>
                            <FormLabel style={{ marginTop: 30, marginRight: 20, fontWeight: 500, color: "#000" }}>Not satisfied</FormLabel>
                            <FormControlLabel
                                value="1"
                                control={<Radio color="primary" />}
                                label="1"
                                labelPlacement="top"
                                style={{ marginLeft: 0 }}
                            />
                            <FormControlLabel
                                value="2"
                                control={<Radio color="primary" />}
                                label="2"
                                labelPlacement="top"
                                style={{ marginLeft: 0 }}
                            />
                            <FormControlLabel
                                value="3"
                                control={<Radio color="primary" />}
                                label="3"
                                labelPlacement="top"
                                style={{ marginLeft: 0 }}
                            />
                            <FormControlLabel
                                value="4"
                                control={<Radio color="primary" />}
                                label="4"
                                labelPlacement="top"
                                style={{ marginLeft: 0 }}
                            />
                            <FormControlLabel
                                value="5"
                                control={<Radio color="primary" />}
                                label="5"
                                labelPlacement="top"
                                style={{ marginLeft: 0 }}
                            />

                            <FormLabel style={{ marginTop: 30, fontWeight: 500, color: "#000" }}>Very satisfied</FormLabel>
                        </RadioGroup>
                    </FormControl>
                    <br />
                    <FormControl component="fieldset" style={{ marginBottom: 20, marginTop: 20 }}>
                        <FormLabel component="legend" style={{ color: "#000" }}>How satisfied are you in your interactions with the Execution team? *</FormLabel>
                        <RadioGroup row aria-label="position" name="howSatisfiedWithExecutionTeam" defaultValue="top"
                            value={howSatisfiedWithExecutionTeam}>
                            <FormLabel style={{ marginTop: 30, marginRight: 20, fontWeight: 500, color: "#000" }}>Not satisfied</FormLabel>
                            <FormControlLabel
                                value="1"
                                control={<Radio color="primary" />}
                                label="1"
                                labelPlacement="top"
                                style={{ marginLeft: 0 }}
                            />
                            <FormControlLabel
                                value="2"
                                control={<Radio color="primary" />}
                                label="2"
                                labelPlacement="top"
                                style={{ marginLeft: 0 }}
                            />
                            <FormControlLabel
                                value="3"
                                control={<Radio color="primary" />}
                                label="3"
                                labelPlacement="top"
                                style={{ marginLeft: 0 }}
                            />
                            <FormControlLabel
                                value="4"
                                control={<Radio color="primary" />}
                                label="4"
                                labelPlacement="top"
                                style={{ marginLeft: 0 }}
                            />
                            <FormControlLabel
                                value="5"
                                control={<Radio color="primary" />}
                                label="5"
                                labelPlacement="top"
                                style={{ marginLeft: 0 }}
                            />

                            <FormLabel style={{ marginTop: 30, fontWeight: 500, color: "#000" }}>Very satisfied</FormLabel>
                        </RadioGroup>
                    </FormControl>
                    <br />
                    <FormControl component="fieldset" style={{ marginBottom: 50, marginTop: 50 }}>
                        <FormLabel component="legend" style={{ color: "#000" }}>Satisfaction index -</FormLabel>
                        <FormLabel component="legend" style={{ color: "#000" ,marginTop:"30px" }}> <span className='satisfiedDetail'> </span> <span style={{ marginLeft: "10px" ,marginTop:"70px" }}>Dissatisfied </span>
                            <span className='DissatisfiedDetail'></span>
                            <span style={{ marginLeft: "10px" ,marginTop:"70px" }}> Satisfied</span> </FormLabel>
                    </FormControl>

                    <TableContainer component={Paper} style={{ width: '70%' }}>
                            <Table className={classes.table} aria-label="table">
                                <TableHead>
                                    <TableRow>
                                        <RadioGroup defaultValue="abc" aria-label="action" name="customized-radios" style={{ display: 'table-cell', top: 0, left: 0, zIndex: 2, position: 'sticky' }}>
                                            <TableCell style={{ width: "15rem" }}></TableCell>
                                            <TableCell style={{ padding: 16, textAlign: 'center', fontWeight: 700, width: "3.938rem", height: "4.1rem", borderRight: "none", backgroundColor: "#e72600" }}>
                                                <span style={{
                                                    color: "#fff"
                                                }}> 1 </span>
                                            </TableCell>
                                            <TableCell style={{ padding: 16, textAlign: 'center', fontWeight: 700, width: "4rem", height: "4rem", backgroundColor: "#f96500" }}>
                                                <span style={{
                                                    color: "#fff"
                                                }}>2</span>
                                            </TableCell>
                                            <TableCell style={{ padding: 16, textAlign: 'center', fontWeight: 700, width: "4rem", height: "4rem", backgroundColor: "#ffe000" }}>
                                                <span style={{
                                                    color: "#fff"
                                                }}>3</span>
                                            </TableCell>
                                            <TableCell style={{ padding: 16, textAlign: 'center', fontWeight: 700, width: "4rem", height: "4rem", backgroundColor: "#30ad00" }}>
                                                <span style={{
                                                    color: "#fff"
                                                }}>4</span>
                                            </TableCell>
                                            <TableCell style={{ padding: 16, textAlign: 'center', fontWeight: 700, width: "4rem", height: "4rem", backgroundColor: "#218b00" }}>
                                                <span style={{
                                                    color: "#fff"
                                                }}>5</span>
                                            </TableCell>
                                        </RadioGroup>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {satisfactionIndexRatioExecution.map((row, i) => {
                                        const labelId = `enhanced-table-checkbox-${i}`;
                                        return (
                                            <TableRow key={i + 1}>
                                                <RadioGroup aria-label='option' name="customized-radios"
                                                    value={row?.actionSelected}
                                                    style={{ display: 'block' }}>
                                          <TableCell className='discription' style={{ padding: '5px', fontWeight: 550, width:"15rem" }} component="th" scope="row"><p className='discript'>{row?.description}</p></TableCell>
                                                            <TableCell style={{ padding: 10, textAlign: 'center' }} component="th">
                                                                <Radio
                                                                    value='1'
                                                                    checked={row?.actionSelected === '1'}
                                                                    style={{
                                                                        backgroundColor: row?.actionSelected === '1' ? '#fde6f0' : '',
                                                                        borderRadius: row?.actionSelected === '1' ? '0px' : ''
                                                                    }}
                                                                    name="radio-button-demo"
                                                                    inputProps={{ 'aria-label': labelId }}
                                                                />
                                                            </TableCell>
                                                             <TableCell className='cellwidth' style={{ padding: 10, textAlign: 'center',width:"4rem"}} component="th">
                                                                <Radio
                                                                    value='2'
                                                                    checked={row?.actionSelected === '2'}
                                                                    name="radio-button-demo"
                                                                    inputProps={{ 'aria-label': labelId }}
                                                                    style={{
                                                                        backgroundColor: row?.actionSelected === '2' ? '#fde6f0' : '',
                                                                        borderRadius: row?.actionSelected === '2' ? '0px' : ''
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell className='cellwidth' style={{ padding: 10, textAlign: 'center',borderLeft:"none",width:"4rem" }} component="th">
                                                                <Radio
                                                                    value='3'
                                                                    checked={row?.actionSelected === '3'}
                                                                    name="radio-button-demo"
                                                                    inputProps={{ 'aria-label': labelId }}
                                                                    style={{
                                                                        backgroundColor: row?.actionSelected === '3' ? '#fde6f0' : '',
                                                                        borderRadius: row?.actionSelected === '3' ? '0px' : ''
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell className='cellwidth' style={{ padding: 10, textAlign: 'center',width:"4rem" }} component="th">
                                                                <Radio
                                                                    value='4'
                                                                    checked={row?.actionSelected === '4'}
                                                                    name="radio-button-demo"
                                                                    inputProps={{ 'aria-label': labelId }}
                                                                    style={{
                                                                        backgroundColor: row?.actionSelected === '4' ? '#fde6f0' : '',
                                                                        borderRadius: row?.actionSelected === '4' ? '0px' : ''
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell className='cellwidth' style={{ padding: 10, textAlign: 'center',width:"4rem" }} component="th">
                                                                <Radio
                                                                    value='5'
                                                                    checked={row?.actionSelected === '5'}
                                                                    name="radio-button-demo"
                                                                    inputProps={{ 'aria-label': labelId }}
                                                                    style={{
                                                                        backgroundColor: row?.actionSelected === '5' ? '#fde6f0' : '',
                                                                        borderRadius: row?.actionSelected === '5' ? '0px' : ''
                                                                    }}
                                                                />
                                                            </TableCell> 
                                                </RadioGroup>                                           
                                                 </TableRow>
                                        );
                                    })}
                                </TableBody>
                                <TableFooter>
                                            <TableRow key="last">
                                            <RadioGroup aria-label='option' name="customized-radios" style={{ display: 'table-cell' }}>
                                                <TableCell style={{width:"15rem"}}></TableCell>
                                                <TableCell style={{ padding: 19 }}><ThumbDownIcon style={{ color: "#e72600" }} /></TableCell>
                                                    <TableCell style={{ padding: 19 }}><ThumbDownIcon style={{ visibility: "hidden" }} /></TableCell>
                                                    <TableCell style={{ padding: 19 }}><ThumbDownIcon style={{ visibility: "hidden" }} /></TableCell>
                                                    <TableCell style={{ padding: 19 }}><ThumbDownIcon style={{ visibility: "hidden" }} /></TableCell>
                                                    <TableCell style={{ padding: 19 }}><ThumbUpIcon   style={{ color: "#218b00" ,marginLeft:10}} /></TableCell>
                                                </RadioGroup>
                                            </TableRow>
                                        </TableFooter>
                            </Table>
                        </TableContainer>

                    <br />
                    <TextField
                        label="Feedback on specific vendor *"
                        size="small"
                        style={{ marginBottom: 20, textAlign: "center", marginTop: 20, width: "50%", backgroundColor: "#faf1f5" }}
                        name="feedbackForSpecificVendor"
                        value={feedbackForSpecificVendor}
                        variant="outlined"
                        multiline
                        rows={3}
                        disabled
                    />

                    <br />
                    <TextField
                        label="Additional comments *"
                        size="small"
                        style={{ marginBottom: 20, textAlign: "center", marginTop: 20, width: "50%", backgroundColor: "#f8f1fb"}}
                        name="additionalFeedback"
                        value={additionalFeedback}
                        variant="outlined"
                        multiline
                        rows={3}
                        disabled
                    />
                </Paper>
            }
        </div>
    )
}

export default connect(null, { setLoading, setSnackInfo })(RealFormDetails)