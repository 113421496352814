import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import HttpService from '../../../services/httpService'
import { setLoading, setSnackInfo } from '../../../redux/actions/appAction'
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import { Button, FormControl, Box, Grid, Dialog, DialogTitle, DialogContent, DialogActions, TextField, MenuItem } from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { InfoOutlined } from '@material-ui/icons';
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 700,
    },
    root: {
        width: '100%',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
}));

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#ccc",
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const MoveToDesign = (props) => {
    const classes = useStyles();
    const [customerList, setCustomerList] = useState([]);
    const [dupCustomerList, setDupCustomerList] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        getAllClosureLead()
        // eslint-disable-next-line
    }, [])

    const getAllClosureLead = async () => {
        try {
            props.setLoading(true)
            const customer = await HttpService.getAllClosureLead('Won');
            setCustomerList(customer.data);
            setDupCustomerList(customer.data);
        } catch (error) {
            console.log(error, "get customer error")
        }
        props.setLoading(false)
    }

    const [changeStageModal, setChangeStageModal] = React.useState(false);

    const [searchField, setSearchField] = useState("")
    const [teamManagersList, setTeamManagersList] = useState([]);

    const getCustomerById = async (cust) => {
        console.log(cust, 'lead data', cust.departmentId);
        try {
            props.setLoading(true)
            const customer = await HttpService.departmentManagers(cust.departmentId);
            console.log(customer.data, 'ddddd');
            setTeamManagersList(customer.data);
        } catch (error) {
            console.log(error, "get customer error")
        }
        props.setLoading(false)
        setChangeStageModal(true)
    }

    const [selectedTeamManager, setSelectedTeamManager] = useState('');

    const getteamsManagers = (e) => {
        console.log(e.target.value);
        setSelectedTeamManager(e.target.value);
    }

    const updateCustomer = async () => {
        try {
            props.setLoading(true)
            console.log(selectedTeamManager, 'selectedTeamManager');
            // await HttpService.editCustomer(editCustomerId, data);
            props.setSnackInfo('Customer Edited Successfully', 'success')
            getAllClosureLead();
            setChangeStageModal(false);
        } catch (error) {
            console.log(error, "error");
            props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const searchCustomer = (e) => {
        setSearchField(e.target.value)
        setCustomerList(dupCustomerList.filter(cust => cust.customerId.name.toLowerCase().includes(e.target.value.toLowerCase())))
    }

    return (
        <div>
            <Paper>
                <TextField label="Search Customer" value={searchField} style={{ float: 'right', marginBottom: 10, marginRight: 10, marginTop: 10 }} name="name" onChange={searchCustomer} variant="outlined" />

                <TableContainer component={Paper}>
                    <Table className={classes.table} stickyHeader aria-label="sticky table">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell>Sr. No.</StyledTableCell>
                                <StyledTableCell>Name</StyledTableCell>
                                <StyledTableCell>Email</StyledTableCell>
                                <StyledTableCell>Budget</StyledTableCell>
                                <StyledTableCell>Created At</StyledTableCell>
                                <StyledTableCell>Assigned To</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {customerList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
                                return (
                                    <StyledTableRow key={i + 1}>
                                        <StyledTableCell>{i + 1}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">
                                            {row?.customerId?.name}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {row?.customerId?.email}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {row?.totalCustomerOutflow}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {moment(row?.createdAt).format('DD-MM-YYYY')}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {row?.assignTo?.name}
                                            <InfoOutlined onClick={() => getCustomerById(row)} style={{ marginRight: "10px" }} />
                                        </StyledTableCell>
                                    </StyledTableRow>
                                );
                            })}
                        </TableBody>
                        <Dialog open={changeStageModal} onClose={() => setChangeStageModal(false)}>
                            <Grid item xs>
                                <DialogTitle>Move Deal</DialogTitle>
                                <hr />
                                <DialogContent>
                                    <Box sx={{ minWidth: 450 }}>
                                        <FormControl>
                                            <TextField
                                                select
                                                labelId="demo-team-manager-select-label"
                                                id="demo-team-manager-select"
                                                name="teamManagers"
                                                label="Select Team Manager"
                                                onChange={getteamsManagers}
                                                variant="outlined"
                                                style={{ width: 300 }}
                                                fullWidth
                                            >
                                                {
                                                    teamManagersList?.map((data, i) => (
                                                        <MenuItem value={data._id} key={i + 1}>{data.name}</MenuItem>
                                                    ))
                                                }
                                            </TextField>
                                        </FormControl>
                                    </Box>
                                </DialogContent>
                                <hr />
                                <DialogActions>
                                    <Button onClick={() => setChangeStageModal(false)}>Cancel</Button>
                                    <Button onClick={updateCustomer} variant="contained" color="secondary">Update</Button>
                                </DialogActions>
                            </Grid>

                        </Dialog>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 20, 50, { label: 'All', value: -1 }]}
                                    colSpan={10}
                                    count={customerList.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: { 'aria-label': 'rows per page' },
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    )
}

export default connect(null, { setLoading, setSnackInfo })(MoveToDesign)