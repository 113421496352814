import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import { setLoading, setSnackInfo } from '../redux/actions/appAction';
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow, TableHead } from '@material-ui/core';
import { ROLENAME } from '../utils/index';
import { Typography } from 'antd';
import { commaSeparateAmount } from './commaSeparate';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }
}));

const DesignPerformance = (props) => {
    const classes = useStyles();
    const [leadsData, setLeadsData] = useState([]);
    const [daysMonth, setDaysMonth] = useState(null);
    useEffect(() => {
        // setCanEdit(props.editable)
        checkUserRole(props.ROLE)
        const date = new Date()
        var currentMonth = date.getMonth() + 1;
        var currentYear = date.getFullYear();
        const daysInMonth = new Date(currentYear, currentMonth, 0).getDate()
        setDaysMonth(daysInMonth)
        // eslint-disable-next-line
    }, [props.SELECTEDDATES, props.SELECTEDEXPCENTER])

    const checkUserRole = (roleid) => {
        let designerNamesList = []
        Object.keys(props.DATA.performance).forEach(st => {
            designerNamesList.push(st)
        })
        let data = [];
        for (let i = 0; i < designerNamesList.length; i++) {
            let views = props.DATA.performance[designerNamesList[i]]
            if (roleid === ROLENAME.DesignUser) {
                data.push({
                    month: views.month,
                    contractSignNumber: views.contractSignNumbers,
                    contractSignValue: views.contractSignValue,
                    avgDaysToSingContract: views.contractSignNumbers === 0 ? 0 : daysMonth / views.contractSignNumbers
                })
                setLeadsData(data);
            } else if (roleid === ROLENAME.DesignManager) {
                data.push({
                    month: views.month,
                    designerName: designerNamesList[i],
                    contractSignNumber: views.contractSignNumbers,
                    contractSignValue: views.contractSignValue,
                    avgDaysToSingContract: views.contractSignNumbers === 0 ? 0 : daysMonth / views.contractSignNumbers
                })
                setLeadsData(data.sort((a, b) => a.designerName?.localeCompare(b.designerName)));
            } else {
                data.push({
                    month: views.month,
                    AssistanceDesignManager: views.dmOrAdm,
                    designerName: designerNamesList[i],
                    contractSignNumber: views.contractSignNumbers,
                    contractSignValue: views.contractSignValue,
                    avgDaysToSingContract: views.contractSignNumbers === 0 ? 0 : daysMonth / views.contractSignNumbers
                })
                setLeadsData(data.sort((a, b) => a.AssistanceDesignManager?.localeCompare(b.AssistanceDesignManager)));
            }
        }
    }

    return (
        <div>
            <div style={{ marginTop: 25, marginBottom: 25 }}>
                <Paper className={classes.paper}>
                    <Typography component="h3">{props.TITLE}</Typography>
                    {
                        <TableContainer component={Paper} style={{ marginTop: 10 }}>
                            <Table className={classes.table} stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow >
                                        <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Sl No</TableCell>
                                        <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Month Name</TableCell>
                                        {
                                            props.ROLE === ROLENAME.DesignManager &&
                                            <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Designer</TableCell>
                                        }
                                        {
                                            props.ROLE === ROLENAME.DesignHead && <>
                                                <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>ADM / DM</TableCell>
                                                <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Designer</TableCell>
                                            </>
                                        }
                                        <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Design Sign-off Numbers </TableCell>
                                        <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Design Sign-off Value</TableCell>
                                        {/* <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Avg days taken for signing contract</TableCell> */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        leadsData.length !== 0 ?
                                            leadsData.map((status, i) => {
                                                return (
                                                    <TableRow key={i + 1}>
                                                        <TableCell component="th" scope="row" style={{ textAlign: 'center' }} >{i + 1}</TableCell>
                                                        <TableCell component="th" scope="row" style={{ textAlign: 'center' }} >{status.month}</TableCell>
                                                        {
                                                            props.ROLE === ROLENAME.DesignManager &&
                                                            <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{status.designerName}</TableCell>
                                                        }
                                                        {
                                                            props.ROLE === ROLENAME.DesignHead && <>
                                                                <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{status.AssistanceDesignManager}</TableCell>
                                                                <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{status.designerName}</TableCell>
                                                            </>
                                                        }
                                                        <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{status.contractSignNumber}</TableCell>
                                                        <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>₹ {commaSeparateAmount(status.contractSignValue)}</TableCell>
                                                        {/* <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{status.avgDaysToSingContract}</TableCell> */}
                                                    </TableRow>
                                                )
                                            })
                                            :
                                            <TableRow>
                                                <TableCell colSpan={9} style={{ textAlign: "center" }}>No Data Found</TableCell>
                                            </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                </Paper>
            </div>
        </div>
    )
}

export default connect(null, { setLoading, setSnackInfo })(DesignPerformance)
