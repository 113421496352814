import React from 'react'
import { useSelector } from 'react-redux';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { POSORAISEDHEADER } from '../utils'
import { DownloadOutlined, DeleteOutlined } from '@ant-design/icons';
import HttpService from '../services/httpService';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles({
	table: {
		minWidth: 700,
	},
});

const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: "#ccc",
		color: theme.palette.common.black,
	},
	body: {
		fontSize: 14,
	},
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
	root: {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover,
		},
	},
}))(TableRow);

const ModalDetails = (props) => {
	const classes = useStyles();
	const { userDetails } = useSelector(state => state.user)

	const getMaskedPODocument = async (data) => {
		let checkType = data.orderId.includes('PO');
		try {
			let result = [];
			if (checkType) {
				result = await HttpService.generateMaskedPo(data.id)
			} else {
				result = await HttpService.generateMaskedSo(data._id)
			}
			window.open(result.data.link)
		} catch (error) {
			console.log(error, "error")
		}
	}

	const handleDeletePoSo = async (data) => {
		props.SETLOADING(true)
		try {
			if(data.orderId.substring(0,2) == 'SO')
			{
				const result = await HttpService.deleteSoByOrderId(data.orderId)
				props.STATECHANGER(false)
				props.SNACKINFO("SO deleted", "success")
			}
			else
			{
				const result = await HttpService.deleteProcurementById(data.id)
				props.STATECHANGER(false)
				props.SNACKINFO("PO deleted", "success")
			}
			
		} catch (error) {
			console.log(error, "error")
		}
		props.SETLOADING(false)
	}
	return (
		<div>
			<div className='modal-header'>
				<div>
					<b>Project Code: </b>
					<span>{props.PROJECTINFO.projectCode}</span>
				</div>
				<div>
					<b>Client: </b>
					<span>{props.PROJECTINFO.clientName}</span>
				</div>
				<div>
					<b>Scope: </b>
					<span>{props.SCOPE}</span>
				</div>
			</div>
			<div>
				{props.REQUESTEDDATA?.length > 0 ? <div>
					<TableContainer component={Paper}>
						<Table className={classes.table} size="small" aria-label="customized table">
							<TableHead>
								<TableRow>
									{POSORAISEDHEADER.map(header => <StyledTableCell>{header}</StyledTableCell>)}
								</TableRow>
							</TableHead>
							<TableBody>
								{props.REQUESTEDDATA.map((data, i) => (
									<StyledTableRow key={i + 1}>
										<StyledTableCell>{i + 1}</StyledTableCell>
										<StyledTableCell>{data?.orderId}</StyledTableCell>
										<StyledTableCell>{data?.status}</StyledTableCell>
										<StyledTableCell>{data?.vendor}</StyledTableCell>
										<StyledTableCell>{data && data.value !== null ? data.value.toFixed(2) : 0}</StyledTableCell>
										<StyledTableCell>{data?.createdAt}</StyledTableCell>
										<StyledTableRow>
											{((data?.paymentRequests.length === 0 || data?.paymentRequests?.find(payment => payment.status !== "Approved")) && userDetails?.email === "dk@decorpot.com") && <StyledTableCell><button onClick={() => props.DELETEREQUEST(data.id)} className='primary-btn'><DeleteOutlined />
											</button></StyledTableCell>
											}
											{
												data.status !== "Sent for Approval" && <>
													{data?.link && <StyledTableCell >
														<button onClick={() => window.open(data?.link)} style={{ cursor: 'pointer' }} className='primary-btn'><DownloadOutlined /></button>
													</StyledTableCell>
													}
													{data?.maskedLink ?
														<StyledTableCell>
															<Tooltip title="Masked File">
																<button onClick={() => window.open(data?.maskedLink)} style={{ cursor: 'pointer' }} className='primary-btn'><DownloadOutlined /> </button>
															</Tooltip>
														</StyledTableCell>
														:
														<StyledTableCell >
															<Tooltip title="Masked File">
																<button onClick={() => getMaskedPODocument(data)} style={{ cursor: 'pointer' }} className='primary-btn'>
																	<DownloadOutlined />
																</button>
															</Tooltip>
														</StyledTableCell>
													}
												</>
											}
											{
												data.status === "Sent for Approval" && (data.createdBy == userDetails["_id"]) && <>
													<StyledTableCell >
													<button onClick={() => handleDeletePoSo(data)} style={{ cursor: 'pointer' }} className='primary-btn'>
													{data.orderId.substring(0,2) == 'SO' ? 'Delete SO' : 'Delete PO'}
													</button>
													</StyledTableCell>
												</>
											}
											<StyledTableCell>
												<button onClick={() => props.OPENDETAILSMODAL(data?.id, `${props.RADIOVALUE === "SO" ? 'SO' : 'PO'} Raised/Requested Details`)} style={{ cursor: 'pointer' }} className='primary-btn'>
													Details
												</button>
											</StyledTableCell>
										</StyledTableRow>
									</StyledTableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</div> : <div>NO DATA TO SHOW</div>
				}
			</div>
		</div >

	)
}

export default ModalDetails

