import React from 'react'
import InwardingView from '../../../components/inwardingView'

const InwardingForStore = () => {
  return (
    <div>
      <InwardingView TYPE="Store" />
    </div >
  )
}

export default InwardingForStore
