import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Dashboard from './Dashboard';
import Footer from './Footer/Footer';
import Navbar from './Navbar/Navbar';
import Profile from './Profile';
import CustomerFinance from './customerFinance';
import CustomerDownloadCenter from './customerDownloadCenter';
import CustomerEscalation from './customerEscalation';
import PageNotFound from './customerPageNotfound';
import PrivateRoute from './PrivateRoute';

const CustomerRoute = () => {
  return (
    <>
      <Navbar />
      <Switch>
        <PrivateRoute path="/cx_customer/dashboard" component={Dashboard} exact />
        <PrivateRoute path="/cx_customer/customer-finance" component={CustomerFinance} exact />
        <PrivateRoute path="/cx_customer/customer-downloadcenter" component={CustomerDownloadCenter} exact />
        <PrivateRoute path="/cx_customer/customer-excalation" component={CustomerEscalation} exact />
        <PrivateRoute path="/cx_customer/customer-profile" component={Profile} exact />
        <Route path="*" component={PageNotFound} />
      </Switch>
      <Footer />
    </>
  );
};

export default CustomerRoute;