import axios from 'axios';
import Config from '../config'

const Apiurl = Config.apiUrl
const QTurl = Config.qtUrl

const HttpService = {
    login: function (data) {
        return axios.post(`${Apiurl}user/login`, data);
    },
    getFaqCategories : function () {
        return axios.get(`${QTurl}call_center/getFaqCategories`);
    },
    faqDetails : function (data) {
        return axios.put(`${QTurl}call_center/faqDetails`,data);
    },
    createFaq : function (data) {
        return axios.post(`${QTurl}call_center/createFaq`,data);
    },
    createCategories : function (data) {
        return axios.post(`${QTurl}call_center/createFaqCategories`, data);
    },
    updateLeadCriteria : function (data) {
        return axios.put(`${QTurl}lead/updateLeadCriteria`, data)
    },
    removeCriteria:  function (data) {
        return axios.put(`${QTurl}lead/removeCriteria`, data)
    },
    getNewlyCreatedQuotation: function (id){
        return axios.get(`${QTurl}customer/getNewlyCreatedQuotation/${id}`)
    },
    updateSalesQuotation: function (data){
        return axios.put(`${QTurl}lead/updateSalesQuotation`,data);
    },
    updateDesignQuotation :function (data){
        return axios.put(`${QTurl}lead/updateDesignQuotation`,data);
    },
    latestQuotation: function (data){
        return axios.post(`${QTurl}lead/updateLatestQuotation`,data);
    },
    getQuotationDownloadLogs : function (id){
        return axios.get(`${QTurl}lead/getQuotationDownloadLogs/${id}`)
    },
    changePasswords : function (data){
        return axios.put(`${QTurl}customer/changePassword`, data)
    },
    userLogin: function(data){
        return axios.post(`${QTurl}customer/customer-login`,data);
    },
    createNewCustomer : function (data) {
        return axios.post(`${QTurl}lead/create-customer`,data);
    },
    getLeadCriteria : function (){
        return axios.get(`${QTurl}lead/getLeadCriteria`);
    },
    handleCustomerLogout : function(id){
        return axios.post(`${QTurl}customer/customer-logout/${id}`);
    },
    getcustomerSessionsData : function(page,rowsPerPage){
        return axios.get(`${QTurl}customerSession/getcustomerSessionsData?page=${page}&limit=${rowsPerPage}`);
    },
    getChmManager: function(){
        return axios.get(`${QTurl}customer/getChmManager`)
    },
    getCustomerDetails: function(id){
        return axios.get(`${QTurl}customer/getcustomer/${id}`)
    },
    getCustomerNewDetails:function(id){
        return axios.get(`${QTurl}customer/customer-otherpayment/${id}`)
    },
    getCustomerLogs: function(id){
        return axios.get(`${QTurl}customer/getcustomelogs/${id}`)
    },
    updateCustomerImage: function(data){
    return axios.put(`${QTurl}customer/update-customer`,data)
    },
    getCustomerQuotation: function(id){
        return axios.get(`${QTurl}customer/getcustomer-quotation/${id}`)
    },
    getAssignedUser: function(id){
        return axios.get(`${QTurl}customer/customer-assingusers/${id}`)

    },
    getCustomerPaymentAttachments: function(id){
        return axios.get(`${QTurl}customer/customerpayments/${id}`)
    },
    getCustomerData: function(){
        return axios.get(`${QTurl}customer/customer-details`)
    },
    forgotPassword: function (email) {
        return axios.put(`${Apiurl}user/forgotPassword`, email)
    },
    forgotCustomerPassword:function (email) {
        return axios.put(`${QTurl}customer/forgotPassword`, email)
    },
    getpaymentPatners: function () {
        return axios.get(`${QTurl}paymentPatners/getpaymentPatners`)
    },
    getPaymentPreview : function (id) {
        return axios.get(`${QTurl}paymentTransactions/getLeadWiseTransactionData/${id}`)
    },
    getPaymentAllPreview : function(data) {
        // console.log(data,"data is callllllednjknnknkbkb")
        return axios.get(`${QTurl}paymentTransactions/getAllTransactionData?startDate=${data.startDate}&endDate=${data.endDate}&selectStage=${encodeURIComponent(data.selectedPaymentSearch.toString())}&searchCustomerName=${data.searchCustomerName}&page=${data.page}&rowsPerPage=${data.rowsPerPage}`)
    },
    getAllManualTransactionsForApproval : function(data) {
        // console.log(data,"data is callllllednjknnknkbkb")
        return axios.get(`${QTurl}paymentTransactions/getAllManualTransactionsForApproval?startDate=${data.startDate}&endDate=${data.endDate}&selectStage=${encodeURIComponent(data.selectedPaymentSearch.toString())}&searchCustomerName=${data.searchCustomerName}&page=${data.page}&rowsPerPage=${data.rowsPerPage}`)
    },
    getPaymentLogsOfLead: function(data)
    {
        return axios.put(`${QTurl}paymentLogs/getPaymentLogsOfLead`, data)
    },
    getPaymentLogsOfMultipleLeads: function(data)
    {
        return axios.put(`${QTurl}paymentLogs/getPaymentLogsOfMultipleLeads`, data)
    },
    getPaymentLogsOfMultipleLeadsForManualPayments: function(data)
    {
        return axios.put(`${QTurl}paymentLogs/getPaymentLogsOfMultipleLeadsForManualPayments`, data)
    },
    getApprovedPaymentTransactions : function() {
        // console.log(data,"data is callllllednjknnknkbkb")
        return axios.get(`${QTurl}paymentTransactions/getApprovedPaymentTransactions`)
    },
    getApprovedPaymentTransactionsFiltered : function(data) {
        // console.log(data,"data is callllllednjknnknkbkb")
        return axios.put(`${QTurl}paymentTransactions/getApprovedPaymentTransactionsFiltered`, data)
    },

    getCsvFileForCustomerInfo : function(data) {
        // console.log(data,"data is callllllednjknnknkbkb")
        return axios.put(`${QTurl}paymentTransactions/getCsvFileForCustomerInfo`, data)
    },
    getApprovedPaymentTransactionsFilteredBySearch : function(data) {
        // console.log(data,"data is callllllednjknnknkbkb")
        return axios.put(`${QTurl}paymentTransactions/getApprovedPaymentTransactionsFilteredBySearch`, data)
    },
    getApprovedPaymentTransactionsForLead : function(data) {
        console.log('getApprovedPaymentTransactionsForLead http service called, data', data)
        // console.log(data,"data is callllllednjknnknkbkb")
        return axios.put(`${QTurl}paymentTransactions/getApprovedPaymentTransactionsForLead`, data)
    },
    getSalesDesignStages : function () {
    // getSalesDesignStages: function () {
        return axios.get(`${QTurl}paymentPatners/salesDesignStage`)
    },
    changePassword: function (data) {
        return axios.put(`${Apiurl}user/changePassword`, data)
    },
    changePasswordBeforeLogin: function (data) {

        return axios.put(`${Apiurl}user/changePasswordBeforeLogin`, data)
        
    },
    getAsideItems: function () {
        return axios.get(`${Apiurl}user/getLeftAside`)
    },
    getApprovedProjects: function () {
        return axios.get(`${Apiurl}finance/approved-projects`);
    },
    getUserAccessDeatails:function (id, selectedData){
        return axios.get(`${Apiurl}userAccess/userAccessDeatails/${id}?data=${selectedData}`);
    },
    addMainMenuNavigation:function (data){
        return axios.put(`${Apiurl}userAccess/addMainMenuItems`,data);
    },
    addSubMenuNavigation:function (data){
        return axios.put(`${Apiurl}userAccess/addSubMenuNavigation`,data);
    },
    getUserNavDeatails:function (id){
        return axios.get(`${Apiurl}userAccess/userNavDeatails/${id}`);
    },
    updateUserAccessDeatails :function (userAccess){
        return axios.put(`${Apiurl}userAccess/updateUserAccess`,userAccess);
    },
    getProjectsScopedData: function (projectId) {
        return axios.get(`${Apiurl}project/get-project-scope/${projectId}`);
    },
    getProjectsExcelFile: function (data,dateObj) {
        if(dateObj){
            return axios.put(`${Apiurl}project/getProjectsExcelFile/?startDate=${dateObj.startDate}&endDate=${dateObj.endDate}`, data);
        }
        else{
            return axios.put(`${Apiurl}project/getProjectsExcelFile`, data);
        }
    },
    // Getting Excel File for multiple projects from companyP&L dashboard
    getProjectsExcelFile: function (data) {
            return axios.put(`${Apiurl}project/getProjectsExcelFile`, data);
    },
    getProjectScopedProducts: function (projectId, scope) {
        return axios.get(`${Apiurl}project/scoped-materials/${projectId}?scope=${scope}`)
    },
    getProcurementsOfProjectByScope: function (projectId, scopeName) {
        return axios.get(`${Apiurl}procurement/getProcurementsOfProject/${projectId}?scope=${scopeName}`)
    },
    getServiceOrderOfProjectScope: function (projectId, scopeName) {
        if(projectId && scopeName) 
            return axios.get(`${Apiurl}serviceOrder/getServiceOrdersOfProject/${projectId}?scope=${scopeName}`)

        return axios.get(`${Apiurl}serviceOrder/getServiceOrdersOfProject/${"getAll"}`)
    },

    getBomOfProjectScope: function (projectId, scopeName, type) {
        return axios.get(`${Apiurl}project-material/bom-materials/${projectId}?scope=${scopeName}&type=${type}`)
    },
    getProcurementDetails: function (procurementId) {
        return axios.get(`${Apiurl}procurement/getProcurementById/${procurementId}`)
    },
    getServiceOrderDetails: function (serviceOrderId) {
        return axios.get(`${Apiurl}serviceOrder/getServiceOrderByOrderId/${serviceOrderId}`)
    },
    getServiceOrderDetailsById: function (serviceOrderId) {
        return axios.get(`${Apiurl}serviceOrder/getServiceOrderById/${serviceOrderId}`)
    },
    deleteSoByOrderId: function (serviceOrderId) {
        return axios.delete(`${Apiurl}serviceOrder/deleteSoByOrderId/${serviceOrderId}`)
    },
    getAllUnits: function () {
        return axios.get(`${Apiurl}unit`)
    },
    fetchUnits: function () {
        return axios.get(`${QTurl}unitTypeMaster/all`)
    },
    getAllVendors: function () {
        return axios.get(`${Apiurl}vendor/getVendors`)
    },
    getVendorPoById: function (vendorId) {
        return axios.get(`${Apiurl}procurement/vendorPoById/${vendorId}`)
    },
    getVendorSoById: function (vendorId) {
        return axios.get(`${Apiurl}serviceOrder/vendorSoById/${vendorId}`)
    },
    getVendorsForApproval: function () {
        return axios.get(`${Apiurl}vendor/getVendorsForApproval`)
    },
    editVendorStatus: function (id, status) {
        return axios.put(`${Apiurl}vendor/editVendorStatus/${id}`, status)
    },
    getVendorMaterialMappingForApproval: function () {
        return axios.get(`${Apiurl}vendor/getVendorMaterialMappingForApproval`)
    },
    editVendorMaterialstatus: function (id, status) {
        return axios.put(`${Apiurl}vendor/editVendorMaterialstatus/${id}`, status)
    },
    getAllMaterialsNames: function () {
        return axios.get(`${Apiurl}materialMaster/materialNames/all`)
    },
    getProcurementsForApprovalByProject: function (projectId, scopeId) {
        return axios.get(`${Apiurl}procurement/getProcurementsForApprovalByProject/${projectId}?scopeId=${scopeId}`)
    },
    getServiceOrdersForApprovalByProject: function (projectId, scopeId) {
        return axios.get(`${Apiurl}serviceOrder/getServiceOrdersForApprovalByProject/${projectId}?scopeId=${scopeId}`)
    },
    generateSO: function (SoId) {
        return axios.get(`${Apiurl}serviceOrder/generateSo/${SoId}`)
    },
    generateMaskedSo: function (SoId) {
        return axios.get(`${Apiurl}serviceOrder/generateMaskedSo/${SoId}`)
    },
    getApprovedPoForPayment: function (projectId, scopeId) {
        return axios.get(`${Apiurl}procurement/procurement/${projectId}?scopeId=${scopeId}`)
    },
    getApprovedSoForPayment: function (projectId, scopeId) {
        return axios.get(`${Apiurl}serviceOrder/service-order/${projectId}?scopeId=${scopeId}`)
    },
    getPaymentRequestForPo: function (projectId, scopeId) {
        return axios.get(`${Apiurl}procurement/payment-request/${projectId}?scopeId=${scopeId}`)
    },
    getPaymentRequestForSo: function (projectId, scopeId) {
        return axios.get(`${Apiurl}serviceOrder/payment-request/${projectId}?scopeId=${scopeId}`)
    },
    getAllPaymentRequestForSo: function () {
        return axios.get(`${Apiurl}serviceOrder/payment-request`)
    },
    getProjectProductByScopeForSo: function (projectId, scopeId) {
        return axios.get(`${Apiurl}projectProducts/projectProductByScope/${projectId}?scopeId=${scopeId}`)
    },
    createServiceOrderFromBomMaterials: function (SO, scope) {
        return axios.post(`${Apiurl}serviceOrder/create-service-order-from-bom?scope=${scope}`, SO)
    },
    createPurchaseOrder: function (PO) {
        return axios.post(`${Apiurl}procurement/createOpenProcurement`, PO)
    },
    editServiceOrderStatus: function (status, soId) {
        return axios.put(`${Apiurl}finance/editServiceOrderStatus/${soId}`, status)
    },
    raiseSOPaymentRequest: function (soId, amount, scopeName) {
        return axios.post(`${Apiurl}serviceOrder/payment-request/${soId}?scope=${scopeName}`, amount)
    },
    soPaymentCompltedRequest: function (soId, obj, scopeName) {
        return axios.post(`${Apiurl}serviceOrder/payment-completed/${soId}?scope=${scopeName}`, obj)
    },
    raisePOPaymentRequest: function (poId, amount, scopeName) {
        if (scopeName)
            return axios.post(`${Apiurl}procurement/payment-request/${poId}?scope=${scopeName}`, amount)
        return axios.post(`${Apiurl}procurement/payment-request/${poId}`, amount)
    },
    raisePOPaymentCompleted: function (poId, obj, scopeName) {
        if (scopeName)
            return axios.post(`${Apiurl}procurement/payment-completed/${poId}?scope=${scopeName}`, obj)
        return axios.post(`${Apiurl}procurement/payment-completed/${poId}`, obj)
    },
    editServiceOrderPaymentStatus: function (paymentId, data) {
        return axios.put(`${Apiurl}serviceOrder/payment-request/${paymentId}`, data)
    },
    approvereditServiceOrderPaymentStatus: function (paymentId, data) {
        return axios.put(`${Apiurl}serviceOrder/payment-approver-request/${paymentId}`, data)
    },
    getAllRooms: function () { //ajit
        return axios.get(`${Apiurl}space-names`)
    },
    getAllScopes: function () {
        return axios.get(`${Apiurl}scoping/getScopes`)
    },
    fetchScopeMaster: function () {
        return axios.get(`${QTurl}scopeMaster/all`)
    },
    getAllSubCategory: function () {
        return axios.get(`${Apiurl}sub-category/sub-category/all`)
    },
    getSelectedScopeSubCategory: function (scopeId) {
        return axios.get(`${Apiurl}sub-category/${scopeId}`)
    },
    getSelectedSubCategoryMaterial: function (subCatId) {
        return axios.get(`${Apiurl}materialMaster/${subCatId}`)
    },
    createProjectMaterialFromBom: function (projectId, projectMaterial) {
        return axios.put(`${Apiurl}project-material/${projectId}`, projectMaterial)
    },
    getSavedProjectMaterialForBom: function (projectId) {
        return axios.get(`${Apiurl}project-material/bom/${projectId}`)
    },
    uploadBomData: function (projectId, bomdata) {
        return axios.post(`${Apiurl}project-material/${projectId}`, bomdata)
    },
    editBomStatus: function (projectId, status) {
        return axios.put(`${Apiurl}project-material/edit-status/${projectId}`, status)
    },
    getProjectDocuments: function (projectId) {
        return axios.get(`${Apiurl}project/getProjectDoc/${projectId}`)
    },
    productDocumentUpload: function (data) {
        return axios.post(`${Apiurl}fileUpload/productDocumentUpload`, data)
    },
    getProductDocAndCreateProject: function (data) {
        return axios.post(`${Apiurl}fileUpload/getProductDocAndCreateProject`, data)
    },
    uploadVendorDocs: function (data) {
        return axios.post(`${QTurl}file/upload?type=vendorDocs`, data)
    },
    UploadVirtualPayments: function (data) {
        return axios.post(`${QTurl}virtualPayments/UploadVirtualPayments`, data)
    },
    getVirtualPaymentsData: function (data) {
        return axios.get(`${QTurl}virtualPayments/getAllPayments?startDate=${data.startDate}&endDate=${data.endDate}&searchQuery=${data.searchQuery}`);
    },
    uploadDesignKickOffDocs: function (data) {
        return axios.post(`${QTurl}file/upload?type=designKickOffDocs`, data);
    },
    getAllProjects: function () {
        return axios.get(`${Apiurl}project/getProjects`)
    },
    mandatoryDocumentsUpload: function (data) {
        return axios.post(`${Apiurl}fileUpload/mandatoryDocumentsUpload`, data)
    },
    getProjectDocumentsForDesign: function () {
        return axios.get(`${Apiurl}project/getProjectsForDesign`)
    },
    sendToFinanceForApproval: function (projectId, data) {
        return axios.put(`${Apiurl}design/sendToFinanceForApproval/${projectId}`, data)
    },
    sendToSiteQcForApproval: function (projectId, data) {
        return axios.put(`${Apiurl}design/sendToSiteQcForApproval/${projectId}`, data)
    },
    deleteProjectDocument: function (docId) {
        return axios.delete(`${Apiurl}projectDocument/deleteDocument/${docId}`)
    },
    getAllProjectForApproval: function () {
        return axios.get(`${Apiurl}finance/getAllProjectForApproval`)
    },
    opsOneTableData:function(obj){        
        return axios.get(`${QTurl}lead/opsTableData?startDate=${obj.startDate}&endDate=${obj.endDate}`)
    },
    getProjectForApproval: function (projectId) {
        return axios.get(`${Apiurl}finance/getProjectForApproval/${projectId}`)
    },
    sendToDesign: function (projectId, data) {
        return axios.put(`${Apiurl}finance/sendBackToDesign/${projectId}`, data)
    },
    approveProject: function (projectId, data) {
        return axios.put(`${Apiurl}finance/approveProject/${projectId}`, data)
    },
    getFilteredProjects: function () {
        return axios.get(`${Apiurl}project/getFilteredProjects?status=Scoping`)
    },
    getFilteredProjectsList: function (status, expCenter) {
        return axios.get(`${Apiurl}project/getFilteredProjects?status=${status}&expCenter=${expCenter}`)
    },
    getIMOSProjects: function (expCenter) {
        return axios.get(`${QTurl}imosProjects?expCenter=${expCenter}`)
    },
    getSBOMProjects: function (expCenter,userId) {
        return axios.get(`${QTurl}siteBomProject/${userId}?expCenter=${expCenter}`)
    },
    getFactoryBomProjects: function (userId, expCenter) {
        return axios.get(`${QTurl}lead/getFactoryBomProjects/${userId}?expCenter=${expCenter}`)
    },
    getImosCompletedProject: function (expCenter) {
        return axios.get(`${QTurl}imosProjects/completedProjects?expCenter=${expCenter}`)
    },
    getProjectsMaterialsList: function (data) {
        return axios.get(`${Apiurl}project/getBomApprovedProject/${data.projectId}`)
    },
    updateMaterialReceivedStatus: function (orderId, data) {
        return axios.put(`${Apiurl}project/updateMaterialReceivedStatus/${orderId}`, data)
    },
    sendFinalEmailToCustomer: function (data) {
        return axios.post(`${QTurl}lead/sendFinalEmailToCustomer`, data)
    },
    updateStatusMaterialDispatched: function (data) {
        return axios.post(`${QTurl}lead/updateStatusMaterialDispatched`, data)
    },
    updateStatusUnderExecution: function (data) {
        return axios.post(`${QTurl}lead/updateStatusUnderExecution`, data)
    },
    updateStatusFromUnderExecutionTo: function (data) {
        return axios.post(`${QTurl}lead/updateStatusFromUnderExecutionTo`, data)
    },
    updateCustomerPaymentAndSendToFinance: function (leadId, data) {
        return axios.put(`${QTurl}lead/updateCustomerPaymentAndSendToFinance/${leadId}`, data)
    },
    changeErpProjectNo: function (erpProjectNo, newErpProjectNo, leadId) {
        return axios.put(`${QTurl}lead/changeErpProjectNo/${erpProjectNo}?newErpProjectNo=${newErpProjectNo}&leadId=${leadId}`)
    },
    getProjectProducts: function (projectId, scopeId) {
        if (scopeId !== undefined)
            return axios.get(`${Apiurl}scoping/getProjectProducts/${projectId}?scopeId=${scopeId}`)
        return axios.get(`${Apiurl}scoping/getProjectProducts/${projectId}`)
    },
    deleteProjectProduct: function (productId) {
        return axios.delete(`${Apiurl}projectProducts/deleteProduct?prodId=${productId}`)
    },
    addProductToProject: function (data) {
        return axios.post(`${Apiurl}scoping/addProductToProject`, data)
    },
    getAllProducts: function () {
        return axios.get(`${Apiurl}productMaster/getAllProducts`)
    },
    getServicesComponent: function () { //ajit
        return axios.get(`${Apiurl}productMaster/services`)
    },
    editCategoryPositionPriority: function (data) {
        return axios.put(`${QTurl}projectComponent/changePriority`, data)
    },
    getFinish: function () { //ajit
        return axios.get(`${QTurl}finishTypeMaster/all`)
    },
    editProjectProduct: function (productId, data) {
        return axios.put(`${Apiurl}scoping/editProjectProduct/${productId}`, data)
    },
    createProcurementForProject: function (data) {
        return axios.post(`${Apiurl}procurement/createProcurementForProject`, data)
    },
    approveProcurement: function (procurementId, data) {
        return axios.put(`${Apiurl}procurement/approveProcurement/${procurementId}`, data)
    },
    approveProcurementByOperationHead: function (procurementId, data) {
            return axios.put(`${Apiurl}procurement/procurement-approved-by-operation-head/${procurementId}`, data)
    },

    getAllProcurementsApprovedByOperationHead: function () {
        return axios.get(`${Apiurl}procurement/get-procurement-approved-by-operation-head`)
    },
    approveProcurementUpdateStdRate: function (procurementId, data) {
        return axios.put(`${Apiurl}procurement/approveProcurement-updateStdRate/${procurementId}`, data)
    },
    rejectProcurement: function (procurementId, data) {
        return axios.put(`${Apiurl}procurement/rejectProcurement/${procurementId}`, data)
    },
    generatePO: function (poId) {
        return axios.put(`${Apiurl}procurement/generatePo/${poId}`)
    },
    generateMaskedPo: function (poId) {
        return axios.put(`${Apiurl}procurement/generateMaskedPo/${poId}`)
    },
    getAllProcurementsForInwarding: function (code, type) {
        if (code)
            return axios.get(`${Apiurl}procurement/getAllProcurementsForInwarding?projectCode=${code}`)
        return axios.get(`${Apiurl}procurement/getAllProcurementsForInwarding?procurementType=${type}`)
    },
    // Getting All Procurements Including Project, Store and Factory
    getAllProcurementsForInwardingIncludingOpenPO: function () {
        return axios.get(`${Apiurl}procurement/getAllProcurementsForInwardingIncludingOpenPO`)
    },
    // Getting All Invoices of a procurement using procurement id 
	getInwardingMaterialInvoices: function(procId){
        return axios.get(
            `${Apiurl}procurement/getInwardingMaterialInvoices/${procId}`
        );
    },
    getAllOpenProcurements: function () {
        return axios.get(`${Apiurl}procurement/getAllOpenProcurements`)
    },
    getAllProcurements: function (vendorId) {
        return axios.get(`${Apiurl}procurement/getAllProcurements/${vendorId}`)
    },
    getProcurementsforVendors: function (data) {
        return axios.put(`${Apiurl}procurement/getProcurementsforVendors`, data)
    },
    getServiceOrdersforVendors: function (data) {
        return axios.put(`${Apiurl}serviceOrder/getServiceOrdersforVendors`, data)
    },
    getServiceOrdersforVendor: function (data) {
        return axios.put(`${Apiurl}serviceOrder/getServiceOrdersforVendor`, data)
    },
    gatAllMaterialTransferForInwarding: function (code, type) {
        return axios.get(`${Apiurl}material-transfer/material-transfer/for-inwarding?transferFrom=${type}`)
    },
    getMtrByOrderIdForInwarding: function (mtrOrderId) {
        return axios.get(`${Apiurl}material-transfer/getMtrByOrderIdForInwarding/${mtrOrderId}`)
    },
    getMaterialTransferForOutwarding: function (transferFrom) {
        return axios.get(`${Apiurl}material-transfer/material-transfer/for-outwarding?transferFrom=${transferFrom}`)
    },
    materialReturnRequest: function (data) {
        return axios.post(`${Apiurl}materialMaster/materials/material-return-request`, data)
    },
    handleReturnAction: function (data) {
        return axios.put(`${Apiurl}materialMaster/materials/material-return-action`, data)
    },
    getMaterialDispatchedQty: function (projectId, materialId) {
        return axios.get(`${Apiurl}material-transfer/material-transfer/project-material-qty/${projectId}/?materialId=${materialId}`)
    },
    outwardMaterial: function (id, data) {
        return axios.put(`${Apiurl}material-transfer/material-transfer/outward-material/${id}`, data)
    },
    getProcurementByOrderIdForInwarding: function (poOrderId) {
        return axios.get(`${Apiurl}procurement/getProcurementByOrderIdForInwarding/${poOrderId}`)
    },
    inwardMaterialToProcurement: function (procId, data) {
        return axios.put(`${Apiurl}procurement/inwardMaterialToProcurement/${procId}`, data)
    },
    mtrInwardMaterialToSite: function (mtrId, data) {
        return axios.put(`${Apiurl}material-transfer/inwarding-material/${mtrId}`, data)
    },
    editProcurementOrderPaymentStatus: function (paymentId, data) {
        return axios.put(`${Apiurl}procurement/payment-request/${paymentId}`, data)
    },
    approverProcurementOrderPaymentStatus: function (paymentId, data) {
        return axios.put(`${Apiurl}procurement/payment-approver-request/${paymentId}`, data)
    },
    getProcurementsForApproval: function () {
        return axios.get(`${Apiurl}procurement/getProcurementsForApproval`)
    },
    deleteProcurementById: function (PoId) {
        return axios.delete(`${Apiurl}procurement/deleteProcurementById/${PoId}`)
    },
    getServiceOrdersForApproval: function () {
        return axios.get(`${Apiurl}serviceOrder/getServiceOrdersForApproval`)
    },
    getOpenProcurements: function () {
        return axios.get(`${Apiurl}procurement/openProcurement`)
    },
    getOpenProcurementsPaymentRequest: function (procurementType) {
        if (procurementType)
            return axios.get(`${Apiurl}procurement/payment-request?procurementType=${procurementType}`)
        return axios.get(`${Apiurl}procurement/payment-request`)
    },
    addUser: function (data) {
        return axios.post(`${Apiurl}user/addUser`, data)
    },
    getUsers: function (store) {
    return axios.put(`${Apiurl}user/getUsers`,store)
    },
    getUsersforUsersPage: function (store) {
        return axios.put(`${Apiurl}user/getUsersforUsersPage`,store)
    },
    getAllUsersforAcess : function (store) {
        return axios.put(`${Apiurl}user/getAllUsers`,store)
    },
    deleteUser: function (userId) {
        return axios.put(`${Apiurl}user/deleteUser/${userId}`)
    },
    getUserById: function (userId) {
        return axios.get(`${Apiurl}user/getUserById/${userId}`)
    },
    getAllRoles: function () {
        return axios.get(`${Apiurl}roles/allRoles`)
    },
    getUniqueRoleFields: function () {
        return axios.get(`${Apiurl}roles/getUniqueRoleFields`)
    },
    addVendor: function (data) {
        return axios.post(`${Apiurl}vendor/addVendor`, data)
    },
    editVendor: function (data) {
        return axios.put(`${Apiurl}vendor/editVendor/${data._id}`, data)
    },
    getVendorById: function (vendorId) {
        return axios.get(`${Apiurl}vendor/getVendorById/${vendorId}`)
    },
    getVendorMaterialById: function (vendorId) {
        return axios.get(`${Apiurl}vendor/getVendorMaterialById/${vendorId}`)
    },
    //Getting Vendor details and mtr quantity while adding new material to the PO/SO/MTR By materialId
    getVendorDetailsMaterialById: function (data) {
        return axios.post(`${Apiurl}project-material/getVendorDetails`,data)
    },
    getAllMaterials: function (type) {
        if (type)
            return axios.get(`${Apiurl}materialMaster/materials/allMaterials?type=${type}`)
        return axios.get(`${Apiurl}materialMaster/materials/allMaterials`)
    },
    // getting all materials of stock including available, consumed and raised MTR
    getAllMaterialsStock: function (type) {
        if (type)
            return axios.get(`${Apiurl}materialMaster/materials/allMaterials/stocks?type=${type}`)
        return axios.get(`${Apiurl}materialMaster/materials/allMaterials/stocks`)
    },
    getAllMaterialsStockAndMoq: function (type) {
        return axios.get(`${Apiurl}materialMaster/materials/allMaterialsStockAndMoq/?type=${type}`)
    },
    getAllMaterialsNameAndCode: function() {
        return axios.get(`${Apiurl}materialMaster/materials/allMaterialsNameAndCode`)
    },
    getSelectedSearchMaterialData: function(data){
        return axios.post(`${Apiurl}materialMaster/materials/selectedSearchMaterialData`,data)
    },
    getAllMaterial: function(limit,page){
        return axios.get(`${Apiurl}materialMaster/materials/allMaterial?limit=${limit}&page=${page}`)
    },

    getMtrReturns: function() {
        return axios.get(`${Apiurl}materialMaster/materials/allMtrReturns`)
    },
    getMtrPendingReturns: function() {
        return axios.get(`${Apiurl}materialMaster/materials/allPendingReturns`)
    },
    getProjectMaterials: function (projectId) {
        return axios.get(`${Apiurl}materialMaster/materials/projectMaterials/${projectId}`)
    },
    mapMaterialsForVendor: function (vendorId, data) {
        return axios.post(`${Apiurl}vendor/mapMaterialsForVendor/${vendorId}`, data)
    },
    addRole: function (data) {
        return axios.post(`${Apiurl}roles/addRole`, data)
    },
    editRole: function (data) {
        return axios.put(`${Apiurl}roles/editRole`, data)
    },
    editScope: function (data) {
        return axios.put(`${Apiurl}roles/editScope`, data)
    },
    editUserRole: function (data) {
        return axios.put(`${Apiurl}user/editUserRole`, data)
    },
    editUser: function (data) {
        return axios.put(`${Apiurl}user/editUser/${data._id}`, data)
    },
    raiseMaterialTransferRequest: function (data) {
        return axios.post(`${Apiurl}material-transfer`, data)
    },
    getMaterialTransferRequest: function () {
        return axios.get(`${Apiurl}material-transfer`)
    },
    materialTransferDetails: function (materialId) {
        return axios.get(`${Apiurl}material-transfer/details/${materialId}`)
    },
    generateMTR: function (mtrId) {
        return axios.put(`${Apiurl}material-transfer/generateMTR/${mtrId}`)
    },
    generateOutwarding: function (mtrId) {
        return axios.put(`${Apiurl}material-transfer/material-transfer/generate-outwarding/${mtrId}`)
    },
    editMaterialTransfer: function (transferId, data) {
        return axios.put(`${Apiurl}material-transfer/${transferId}`, data)
    },
    getMaterailTransferRequestsByScopeForProject: function (projectId, scopeName) {
        return axios.get(`${Apiurl}material-transfer/material-transfer/all?projectId=${projectId}&scopeName=${scopeName}`)
    },
    getPreviousBomForProject: function (projectId) {
        return axios.get(`${Apiurl}project-material/view-previous-bom/${projectId}`)
    },
    getBomPendingForApproval: function () {
        return axios.get(`${Apiurl}project-material/bom-pending-for-approval`)
    },

    // Route to get bom whose approval is pending
    getBomPendingForApprovalProjectId: function (projectId) {
        return axios.get(`${Apiurl}project-material/bom-pending-for-approval/${projectId}`)
    },

    generatePaymentReportForPo: function (data) {
        return axios.post(`${Apiurl}procurement/generate-payment-report`, data)
    },
    generatePaymentReportForSo: function (data) {
        return axios.post(`${Apiurl}serviceOrder/generate-payment-report`, data)
    },
    generatebankformatPaymentReportForPo: function (data) {
        return axios.post(`${Apiurl}procurement/bank-format-generate-payment-report`, data)
    },
    generatebankformatPaymentReportForSo: function (data) {
        return axios.post(`${Apiurl}serviceOrder/bank-format-generate-payment-report`, data)
    },
    addCategory: function (data) {
        return axios.post(`${Apiurl}space-names`, data)
    },
    updateCategory: function (data, id) {
        return axios.put(`${Apiurl}space-names/${id}`, data)
    },
    addScope: function (data) {
        return axios.post(`${Apiurl}scoping`, data)
    },
    updateScope: function (data, id) {
        return axios.put(`${Apiurl}scoping/${id}`, data)
    },
    deleteCategory: function (id) {
        return axios.delete(`${Apiurl}space-names/${id}`)
    },
    deleteUnit: function (id) {
        return axios.delete(`${Apiurl}unit/${id}`)
    },
    deletePaymentMode: function (id) {
        return axios.delete(`${QTurl}paymentModes/${id}`)
    },
    deleteScope: function (id) {
        return axios.delete(`${Apiurl}scoping/${id}`)
    },
    addSubCategory: function (data) {
        return axios.post(`${Apiurl}sub-category`, data)
    },
    updateSubCategory: function (data, id) {
        return axios.post(`${Apiurl}sub-category/${id}`, data)
    },
    deleteSubCategory: function (id) {
        return axios.post(`${Apiurl}sub-category/${id}`)
    },
    addMaterial: function (data) {
        return axios.post(`${Apiurl}materialMaster`, data)
    },
    updateMaterial: function (data, id) {
        return axios.put(`${Apiurl}materialMaster/${id}`, data)
    },
    deleteMaterial: function (id) {
        return axios.post(`${Apiurl}materialMaster/${id}`)
    },
    updateOrgRateCardPrices: function (data, rateCardMasterId) {
        return axios.put(`${Apiurl}org-rate-card/${rateCardMasterId}`, data)
    },
    cloneOrgRateCard: function (rateCardMasterId, locationId, markUp) {
        console.log(rateCardMasterId, locationId, markUp)
        return axios.post(`${Apiurl}org-rate-card/clone-rate-card/${rateCardMasterId}`, { markUp, locationId })
    },
    makeDefault: function (rateCardMasterId, locationId) {
        return axios.put(`${Apiurl}org-rate-card/makeDefault/${rateCardMasterId}`, { locationId })
    },

    //QT Integration API
    getAllLeads: function (limit, page, search, expCenterId) {
        return axios.get(`${QTurl}lead/all?limit=${limit}&page=${page}&search=${search}&experienceCenterId=${expCenterId}`)
    },
    getDatefilterLeads: function (limit, page, expCenterId, data) {
        return axios.get(`${QTurl}lead/all?limit=${limit}&page=${page}&startDate=${data.startDate}&endDate=${data.endDate}&departmentId=${data.departmentId}&experienceCenterId=${expCenterId}&search=${data.search}`)
    },
    getAllSearchLeads: function (searchType, search, expCenterId, data) {
        if (data.role === 'admin' || data.role === 'TeleCaller')
            return axios.get(`${QTurl}lead/all-search-lead?searchType=${searchType}&search=${search}&experienceCenterId=${expCenterId}&startDate=${data.startDate}&endDate=${data.endDate}`)
        return axios.get(`${QTurl}lead/all-search-lead?searchType=${searchType}&search=${search}&experienceCenterId=${expCenterId}`)
    },
    getAllLeadsForChangeRate: function () {
        return axios.get(`${QTurl}lead/allLeadsForProjectRateCard`)
    },
    getFilterResponse: function (data) {
        if (data.startDate && data.endDate)
            return axios.get(`${QTurl}lead/filter-lead-for-admin?teamId=${data.teamId}&departmentId=${data.departmentId}&stage=${data.stage}&startDate=${data.startDate}&endDate=${data.endDate}&experienceCenterId=${data.expCenterId}&sourceOfLead=${data?.sourceOfLead}`)
        return axios.get(`${QTurl}lead/filter-lead-for-admin?teamId=${data.teamId}&departmentId=${data.departmentId}&stage=${data.stage}&experienceCenterId=${data.expCenterId}&sourceOfLead=${data?.sourceOfLead}`)
    },
    getdateFilterResponse: function (data) {
        return axios.get(`${QTurl}lead/filter-lead-for-admin?startDate=${data.startDate}&endDate=${data.endDate}&departmentId=${data.departmentId}&experienceCenterId=${data.expCenterId}`)
    },
    getAllLeadsNames: function () {
        return axios.get(`${QTurl}lead/all/names`)
    },
    saveKnowledgeDetails : function (data){
        return axios.post(`${QTurl}knowledge-taging/createTag`,data)
    },
    saveKnowledgeTaging : function (data) {
        return axios.post(`${QTurl}knowledge-taging`,data)
    },
    getKnowledgeTaging : function () {
        return axios.get(`${QTurl}knowledge-taging/getKnowledgeTaging`)
    },
    getAllKnowLedgeDetails : function (data) {
        return axios.put(`${QTurl}knowledge-taging/getAllKnowLedgeDetails`,data)
    },
    deleteKnowledgeDetails : function (id){
        return axios.put(`${QTurl}knowledge-taging/deleteKnowledgeDetails`,id)
    },
    updateProjectRatePrices: function (data, projectId) {
        return axios.put(`${QTurl}project-rate-card/${projectId}`, data)
    },
    getOrgRateCardForRateCardMaster: function (rateCardMasterId) {
        return axios.get(`${QTurl}org-rate-card/${rateCardMasterId}`)
    },
    getAllRateCardMaster: function (locationId) {
        return axios.get(`${QTurl}rate-card-master?locationId=${locationId}`)
    },
    getProjectRateCard: function (projectId) {
        return axios.get(`${QTurl}project-rate-card/default-rate-card/${projectId}`)
    },
    customerProject: function (data) {
        return axios.get(`${QTurl}project/customer/${data}`)
    },
    getDepartments: function () {
        return axios.get(`${Apiurl}department/all`);
    },
    createDepartment: function (data) {
        return axios.post(`${Apiurl}department/addDepartment/`, data)
    },
    editDepartment: function (data, id) {
        return axios.put(`${Apiurl}department/editDepartment/${id}`, data)
    },
    editTeam: function (data, id) {
        return axios.put(`${Apiurl}team/editTeam/${data.teamId}`, data)
    },
    deleteDepartment: function (deptID) {
        return axios.delete(`${Apiurl}department/deleteDepartment/${deptID}`)
    },
    getTeams: function () {
        return axios.get(`${Apiurl}team/all`)
    },
    createTeam: function (data) {
        return axios.post(`${Apiurl}team/addTeam/`, data)
    },
    getQTTeamUsers: function (id) {
        return axios.get(`${Apiurl}user/usersOfTeam/${id}`);
    },
    getQtTeamManager: function (id) {
        return axios.get(`${QTurl}user/managerOfTeam/${id}`);
    },
    assignProject: function (data) {
        return axios.post(`${QTurl}assignTo`, data);
    },
    leadUpdateMeeting:function (data) {
        return axios.post(`${QTurl}assignTo/leadUpdateMeeting`, data);
    },
    wdFileUpload: function (data) {
        return axios.post(`${QTurl}file/upload`, data)
    },
    uploadAndSendToImosAndSiteBOM: function (leadId) {
        return axios.put(`${QTurl}lead/sendToImosAndSiteQc/${leadId}`)
    },
    uploadIMOSFile: function (leadId, data) {
        return axios.put(`${QTurl}lead/uploadImosFiles/${leadId}`, data)
    },
    updateToReadyToDispatch: function (leadId, data) {
        return axios.put(`${QTurl}lead/updateToReadyToDispatch/${leadId}`, data)
    },
    updateFactoryStage: function (leadId, data) {
        return axios.put(`${QTurl}lead/updateFactoryStage/${leadId}`, data)
    },
    uploadUpdatedFileAndData: function (leadId, data) {
        return axios.put(`${QTurl}lead/uploadUpdatedData/${leadId}`, data)
    },
    getReadyToDispatchProjects: function (expcenter) {
        return axios.get(`${QTurl}lead/getReadyToDispatchProjects/${expcenter}`);
    },
    gerRawLeads: function (data) {
        {
        if(data.startDate !=="" && data.endDate !==""){
            return axios.put(`${QTurl}lead/getrawleads/?page=${data.page}&limit=${data.limit}&startDate=${data.startDate}&endDate=${data.endDate}`);
        }
        else{
            return axios.put(`${QTurl}lead/getrawleads/?page=${data.page}&limit=${data.limit}&startDate=${data.startDate = ""}&endDate=${data.endDate = ""}`);
        }
    }},
    pushRawLead : function (data) {
    return axios.post(`${QTurl}lead/createWebSiteLead`, data)
    },
    getReadyToDispatchProjectsForCHM: function (expcenter, chmId) {
        return axios.get(`${QTurl}lead/getReadyToDispatchProjectsForCHM/${expcenter}?chmId=${chmId}`);
    },
    getAllSiteQcDetails: function () {
        return axios.get(`${QTurl}lead/getAllSiteQcDetails`)
    },
    getAllDesignKickOffLeads: function (selectedExpCenter) {
        return axios.get(`${QTurl}lead/getAllDesignKickOffLeads/${selectedExpCenter}`);
    },
    assignSiteQC_Manually: function(data)
    {
        return axios.put(`${QTurl}lead/assignSiteQC_Manually`,  data)
    },
    getUnderExecutionProjects:function (expcenter,obj)
    {
        return axios.put(`${QTurl}lead/getUnderExecutionProjects/${expcenter}`, obj);
    },
    getCompletedProjects:function (expcenter,obj)
    {
        return axios.put(`${QTurl}lead/getCompletedProjects/${expcenter}`, obj);
    },
    getCompletedMWPProjects:function (expcenter,obj)
    {
        return axios.put(`${QTurl}lead/getCompletedMWPProjects/${expcenter}`, obj);
    },
    getQTUsers(id) {
        return axios.get(`${QTurl}user/usersOfTeam/${id}`);
    },
    getAllQuotationsForProject(projectId) { //ajit
        return axios.get(`${QTurl}quotation/all/${projectId}`)
    },
    getProjectDetails(projectId) {
        return axios.get(`${QTurl}lead/details/?leadId=${projectId}`);
    },
    getProjectLeadDetails(projectId) { //ajit
        return axios.get(`${QTurl}lead/leadDetails/?leadId=${projectId}`);
    },
    getLeadDetails(leadID) {
        return axios.get(`${QTurl}user/leadData/?leadId=${leadID}`);
    },
    generateQuotationExcel(quotationId) { //ajit
        return axios.get(`${QTurl}quotation/excel/${quotationId}`)
    },
    finalPaymentApprovedByFm(leadId) {
        return axios.get(`${QTurl}lead/getFinalPaymentsApproved/${leadId}`)
    },
    updateDesignKickOffCustomerFromDesign(data)
    {
        return axios.put(`${QTurl}lead/updateDesignKickOffCustomerFromDesign`, data)
    },
    updateDesignKickOffCustomerFromChm(data)
    {
        return axios.put(`${QTurl}lead/updateDesignKickOffCustomerFromChm`, data)
    },
    getProjectComponent: function (projectId) { //ajit
        return axios.get(`${QTurl}projectComponent/${projectId}`)
    },
    addProjectComponent: function (data) { //ajit
        return axios.post(`${QTurl}projectComponent/new`, data)
    },
    getAllProductsFromRateCard: function (projectId) { //ajit
        return axios.get(`${QTurl}project-rate-card/default-rate-card/${projectId}`)
    },
    editProjectComponentQuotation: function (data) {
        return axios.put(`${QTurl}projectComponent/edit`, data)
    },
    getDiscountMasterData: function () { //ajit
        return axios.get(`${QTurl}discount/all`)
    },
    newDiscount: function (data) {
        return axios.post(`${QTurl}discount/new`, data)
    },
    editDiscount: function (id, data) {
        return axios.put(`${QTurl}discount/edit/${id}`, data)
    },
    inActiveDiscount: function (id) {
        let data = '';
        return axios.put(`${QTurl}discount/deactivate/${id}`, data)
    },
    discountApproval: function (data, leadId) {
        return axios.put(`${QTurl}lead/discountApproval/${leadId}`, data)
    },
    complimentaryApproval: function (data, leadId) {
        return axios.put(`${QTurl}lead/complimentaryApproval/${leadId}`, data)
    },
    getComplimentaryItemsApprovalLeads: function (selectedExpCenter) {
        return axios.get(`${QTurl}lead/complimentaryItemApprovalLeads/${selectedExpCenter}`)
    },
    getLostLeadRequests: function() {
        return axios.get(`${QTurl}lead/lostRequestApproval`)
       },
    getDesignSignoffHoldProjects: function () {
        return axios.get(`${QTurl}lead/DesignSignoffHOLD`)
    },
    approveLostLead: function  (data) {
        return axios.put(`${QTurl}lead/lostApprovalStatus`,data);
    },
    approveComplimentaryItems: function (projectId, data) {
        return axios.put(`${QTurl}lead/approveComplimentaryItems/${projectId}`, data)
    },
    deleteProjectComponent: function (compId) {
        return axios.delete(`${QTurl}projectComponent/remove/${compId}`)
    },
    saveQuotation: function (data) {
        return axios.post(`${QTurl}quotation/new`, data);
    },
    getProjectLog: function (projId) {
        return axios.get(`${QTurl}leadLog/${projId}`);
    },

    comparedPdf : function (data) {
        return axios.post(`${QTurl}quotation/comparedPdf`, data)
    },
    // Getting Lead-Logs using leadId of Sales-User and Sales-Manager
    getLeadLogsUsingLeadId: function (leadId) {
        return axios.get(`${QTurl}leadLog/leadLogsUsingLeadId/${leadId}`);
    },

    uploadFile: function (data) {
        return axios.post(`${QTurl}file/upload?type=attachment`, data);
    },
    updateOptimisationFiles: function (data)
    {
        return axios.post(`${QTurl}project/updateOptimisationFiles`, data)
    },
    getOptimisationFiles: function ()
    {
        return axios.get(`${QTurl}project/getOptimisationFiles`)
    },
    addNotes: function (data) {
        return axios.post(`${QTurl}leadLog`, data);
    },
    getPlyTypeMaster: function () {
        return axios.get(`${QTurl}plyTypeMaster/all`);
    },
    getManagersOfSalesTeam: function () {
        return axios.get(`${QTurl}user/managerOfSales`);
    },
    getLeadMaster: function () {
        return axios.get(`${QTurl}leadMaster/all/`);
    },
    checkCustomer: function (id) {
        return axios.get(`${QTurl}customer/checkCustomer/${id}`);
    },
    createMarketingSourceLead: function (data) {
        return axios.post(`${QTurl}leadMaster/new`, data);
    },
    changeSourceStatus: function (id, status) {
        return axios.put(`${QTurl}leadMaster/isActive/${id}`, status);
    },
    updateOptimizedSingleFile: function (id, data) {
        return axios.put(`${QTurl}project/updateOptimizedSingleFile/${id}`, data);
    },
    discountedProject: function (selectedExpCenter) {
        return axios.get(`${QTurl}lead/discountedProjects/${selectedExpCenter}`);
    },
    approveDiscount: function (projId, data) {
        return axios.put(`${QTurl}lead/approveDiscount/${projId}`, data);
    },
    getTeamUsersList: function (teamId) {
        return axios.get(`${Apiurl}team/getTeamUser/${teamId}`);
    },
    getDocumentUploaderCount: function (expCenterId) {
        return axios.get(`${QTurl}lead/getDocumentUploaderCount/${expCenterId}`);
    },
    getImosProjectCount: function (expCenterId) {
        return axios.get(`${QTurl}imosProjects/getProjectCount/${expCenterId}`);
    },
    getExecutionProjectCount: function (expCenterId,data) {
        return axios.get(`${QTurl}project/getExecutionProjectCount/${expCenterId}/?startDate=${data.startDate}&endDate=${data.endDate}&isUser=${data.isUser}`);
    },
    getImosStageProject: function (data) {
        return axios.get(`${QTurl}imosProjects/getProjectsByStage/?imosStage=${data.stage}&experienceCenterId=${data.expcenterId}&assignTo=${data.assignTo}`);
    },
    getExecutionStage: function (data) {
        return axios.get(`${QTurl}lead/getExecutionStage/?stage=${data.stage}&assignTo=${data.assignTo}`);
    },
    getSalesDepartmentUsers: function (teamId, expcenter) {
        return axios.get(`${Apiurl}team/getSalesDepartmentUsers/${teamId}/${expcenter}`);
    },
    getSelectedUsersRecords: function (users) {
        return axios.post(`${QTurl}lead/getSelectedUsersData`, users);
    },
    userRecord: function (user) {
        return axios.get(`${QTurl}user/getUserDetails/${user}` );
    },
    getOrganisationList: function () {
        return axios.get(`${Apiurl}organisation/all/`);
    },
    getBudgetSelectedYear: function (year, loc, exp) {
        return axios.get(`${QTurl}sourceOfLead/all/?year=${year}&location=${loc}&expcenter=${exp}`);
    },
    createCustomer: function (data) {
        return axios.post(`${QTurl}lead`, data);
    },
    editMarketBudget: function (id, data) {
        return axios.put(`${QTurl}sourceOfLead/edit/${id}`, data);
    },
    addNewBudget: function (data) {
        return axios.post(`${QTurl}sourceOfLead/addBudget`, data);
    },
    getAllLeadData: function () {
        return axios.get(`${QTurl}lead`);
    },
    getStatusWiseLeadData: function (data) {
        if (data.hasOwnProperty("status") && data.status === undefined) {
            return axios.get(`${QTurl}lead/details/?startDate=${data.startDate}&endDate=${data.endDate}&experienceCenterId=${data.experienceCenterId}`);
        } else if (data.hasOwnProperty("status") && data.status === 'NA') {
            return axios.get(`${QTurl}lead/details/?leadStatus=${data.leadStatus}&startDate=${data.startDate}&endDate=${data.endDate}&assignTo=${data.assignTo}&experienceCenterId=${data.experienceCenterId}&filterOption=${data.filterOption}`);
        } else if (data.hasOwnProperty("status") && data.status === 'salesuser') {
            return axios.get(`${QTurl}lead/details/?salesStage=${data.stageStatus}&startDate=${data.startDate}&endDate=${data.endDate}&assignTo=${data.assignTo}&experienceCenterId=${data.experienceCenterId}&filterOption=${data.filterOption}`);
        } else if (data.hasOwnProperty("status") && data.status === 'salesHead') {
            return axios.get(`${QTurl}lead/details/?salesStage=${data.stageStatus}&startDate=${data.startDate}&endDate=${data.endDate}&assignTo=${data.assignTo}&experienceCenterId=${data.experienceCenterId}&filterOption=${data.filterOption}&userRole=${data.status}`);
        } else if (data.hasOwnProperty("status") && data.status === 'Lead Received') {
            return axios.get(`${QTurl}lead/details/?salesStage=${data.status}&startDate=${data.startDate}&endDate=${data.endDate}&experienceCenterId=${data.experienceCenterId}`);
        } else if (data.hasOwnProperty("teamId")) {
            return axios.get(`${QTurl}lead/details/?startDate=${data.startDate}&endDate=${data.endDate}&teamId=${data.teamId}&experienceCenterId=${data.experienceCenterId}`);
        } else if (data.hasOwnProperty("status") && data.status === 'Total-Leads-Generated') {
            return axios.get(`${QTurl}lead/details/?startDate=${data.startDate}&endDate=${data.endDate}&experienceCenterId=${data.experienceCenterId}`);
        } else if (data.hasOwnProperty("status") && data.status === 'Leads-Assiged-(Stage-1)') {
            return axios.get(`${QTurl}lead/details/?salesStage=${data.salesStage}&assignTo=${data.assignTo}&startDate=${data.startDate}&endDate=${data.endDate}&experienceCenterId=${data.experienceCenterId}`);
        } else if (data.hasOwnProperty("status") && data.status === 'Leads Pending Assignment' && data.isUserSelected === 'false') {
            return axios.get(`${QTurl}lead/details/?status=${data.status}&startDate=${data.startDate}&endDate=${data.endDate}&experienceCenterId=${data.experienceCenterId}&isUser=${data.isUserSelected}&filterOption=${data.filterOption}`);
        } else if (data.hasOwnProperty("status") && data.status === 'Leads Pending Assignment' && data.isUserSelected === 'true') {
            return axios.get(`${QTurl}lead/details/?status=${data.status}&startDate=${data.startDate}&endDate=${data.endDate}&experienceCenterId=${data.experienceCenterId}&selectedUser=${data.selectedUserList}&isUser=${data.isUserSelected}&filterOption=${data.filterOption}`);
        } else if (data.hasOwnProperty("leadRetunedBySales") && data.leadRetunedBySales === 'Lead Returned By Sales') {
            return axios.get(`${QTurl}lead/details/?status=${data.leadRetunedBySales}&startDate=${data.startDate}&endDate=${data.endDate}&experienceCenterId=${data.experienceCenterId}`);
        } else if (data.hasOwnProperty("leadType")) {
            return axios.get(`${QTurl}lead/details/?leadType=${data.status}&startDate=${data.startDate}&endDate=${data.endDate}&experienceCenterId=${data.experienceCenterId}`);
        } else {
            return axios.get(`${QTurl}lead/details/?leadType=${data.status}&startDate=${data.startDate}&endDate=${data.endDate}`);
        }
    },
    // API for marketing inside pages data
    getMarketingStatusWiseData: function (data) {
        if (data.hasOwnProperty("type")) {
            return axios.get(`${QTurl}lead/marketingDetails/?leadType=${data.type}&startDate=${data.startDate}&endDate=${data.endDate}&experienceCenterId=${data.experienceCenterId}`);
        } else if (data.hasOwnProperty("totalLead")) {
            return axios.get(`${QTurl}lead/marketingDetails/?startDate=${data.startDate}&endDate=${data.endDate}&experienceCenterId=${data.experienceCenterId}`);
        } else if (data.hasOwnProperty("status")) {
            return axios.get(`${QTurl}lead/marketingDetails/?status=${data.status}&startDate=${data.startDate}&endDate=${data.endDate}&experienceCenterId=${data.experienceCenterId}`);
        } else if (data.hasOwnProperty("teamId")) {
            return axios.get(`${QTurl}lead/marketingDetails/?teamId=${data.teamId}&startDate=${data.startDate}&endDate=${data.endDate}&experienceCenterId=${data.experienceCenterId}`);
        }
    },

    getStageWiseLeadData: function (data) {
        if (data.startDate) {
            return axios.get(`${QTurl}lead/getStageWiseLeadData/?experienceCenterId=${data.experienceCenterId}&salesStage=${data.salesStage}&startDate=${data.startDate}&endDate=${data.endDate}&filterOption=${data.filterOption}`);
        } else {
            return axios.get(`${QTurl}lead/getStageWiseLeadData/?experienceCenterId=${data.experienceCenterId}&salesStage=${data.salesStage}`);
        }
    },
    getWonCustomerInOtherDeptList: function (data) {
        return axios.get(`${QTurl}lead/won-deals-other-dept/?experienceCenterId=${data.experienceCenterId}&filterOption=${data.selectedFilterOption}&startDate=${data.startDate}&endDate=${data.endDate}`);
    },
    getDesignerAssigmentLeadData: function (data) {
        if (data.option === 'user') {
            return axios.get(`${QTurl}lead/details/?assignTo=${data.assignTo}&salesStage=${data.salesStage}&startDate=${data.startDate}&endDate=${data.endDate}&filterOption=${data.filterOption}`);
        } else if (data.option === 'sales') {
            return axios.get(`${QTurl}lead/details/?experienceCenterId=${data.experienceCenterId}&assignTo=${data.assignTo}&salesStage=${data.salesStage}&startDate=${data.startDate}&endDate=${data.endDate}&filterOption=${data.filterOption}`);
        } else if (data.option === 'designHead') {
            return axios.get(`${QTurl}lead/details/?experienceCenterId=${data.experienceCenterId}&designHeadAssigned=${data.designHeadAssigned}&startDate=${data.startDate}&endDate=${data.endDate}&asUser=${data.option}`);
            // return axios.get(`${QTurl}lead/details/?experienceCenterId=${data.experienceCenterId}&assignTo=${data.assignTo}&designHeadAssigned=${data.designHeadAssigned}&startDate=${data.startDate}&endDate=${data.endDate}&asUser=${data.option}`); // Enable if need to show that lead moved to design stage
        } else if (data.option === 'team' && data.isUserSelected === "false") {
            return axios.get(`${QTurl}lead/details/?experienceCenterId=${data.experienceCenterId}&teamId=${data.teamId}&isUser=${data.isUserSelected}&salesStage=${data.salesStage}&startDate=${data.startDate}&endDate=${data.endDate}&filterOption=${data.filterOption}`);
        } else {
            return axios.get(`${QTurl}lead/details/?experienceCenterId=${data.experienceCenterId}&teamId=${data.teamId}&isUser=${data.isUserSelected}&selectedUser=${data.selectedUserList}&salesStage=${data.salesStage}&startDate=${data.startDate}&endDate=${data.endDate}&filterOption=${data.filterOption}`);
        }

    },
    getAchivedVsTargetList: function (data) {
        if (data.currentSelecredRole === 'Sales Head') {
            return axios.get(`${QTurl}lead/targetAchivedVsTarget/?experienceCenterId=${data.experienceCenterId}&departmentId=${data.userInfo}&startDate=${data.startDate}&endDate=${data.endDate}&filterOption=${data.filterOption}`);
        } else if (data.currentSelecredRole === 'Sales Manager') {
            return axios.get(`${QTurl}lead/targetAchivedVsTarget/?experienceCenterId=${data.experienceCenterId}&teamId=${data.userInfo}&startDate=${data.startDate}&endDate=${data.endDate}&filterOption=${data.filterOption}`);
        } else if (data.currentSelecredRole === 'Assistant Sales Manager') {
            return axios.get(`${QTurl}lead/targetAchivedVsTarget/?experienceCenterId=${data.experienceCenterId}&teamId=${data.userInfo}&startDate=${data.startDate}&endDate=${data.endDate}&filterOption=${data.filterOption}`);
        } else if (data.currentSelecredRole === 'Sales User') {
            return axios.get(`${QTurl}lead/targetAchivedVsTarget/?experienceCenterId=${data.experienceCenterId}&assignTo=${data.userInfo}&startDate=${data.startDate}&endDate=${data.endDate}&filterOption=${data.filterOption}`);
        }
    },
    getDesignLeads :function(data)
    {
        return axios.put(`${QTurl}lead/getDesignLeads`, data);
    },
    getContractLeads :function(data)
    {
        return axios.put(`${QTurl}lead/getContractLeads`, data);
    },
    getLeadsOnSelectedDate: function (data) {
        return axios.get(`${QTurl}lead/?startDate=${data.startDate}&endDate=${data.endDate}&experienceCenterId=${data.experienceCenterId}`);
    },
    getMargetingTargetCount: function () {
        return axios.get(`${QTurl}marketingtarget`);
    },
    getFilterWiseLeadData: function (data) {
        return axios.put(`${QTurl}lead/filter`, data);
    },
    userTarget: function (year, team) {
        return axios.get(`${QTurl}target/all?year=${year}&teamId=${team}`);
    },
    editTarget: function (id, data) {
        return axios.put(`${QTurl}target/edit/${id}`, data);
    },
    newTarget: function (data) {
        return axios.post(`${QTurl}target/new`, data);
    },
    getAllSalesLeadData: function () {
        return axios.get(`${QTurl}lead/sales`);
    },
    getSalesLeadOnSelectedDate: function (data) {
        return axios.get(`${QTurl}lead/sales/?experienceCenterId=${data.experienceCenterId}&startDate=${data.startDate}&endDate=${data.endDate}&filterOption=${data.filterOption}`);
    },
    getAllCustomer: function () {
        return axios.get(`${QTurl}customer/all`);
    },
    editCustomer: function (id, data) {
        return axios.put(`${QTurl}customer/edit/${id}`, data);
    },
    reminderLogs: function (data) {
        return axios.put(`${QTurl}leadLog/remindersOfDate`, data);
    },
    getAllClosureLead: function (salesStage) {
        return axios.get(`${QTurl}lead/details/?salesStage=${salesStage}`);
    },
    departmentManagers: function (deptId) {
        return axios.get(`${QTurl}user/managerOfDepartment/${deptId}`);
    },
    updateLeadStageStatus: function (leadstatus, leadid) {
        return axios.put(`${QTurl}lead/updateLeadStageStatus/${leadid}`, leadstatus);
    },
    updateChmUser: function (data) {
        return axios.put(`${QTurl}lead/updateChmUser`, data)
    },
    updateExpectedDesignSignOffDate: function (designSignOff, projectId) {
        return axios.put(`${QTurl}lead/updateExpectedDesignSignOffDate/${projectId}`, designSignOff);
    },

    updateExpectedSalesClosureDate: function (obj, projectId) {
        return axios.put(`${QTurl}lead/updateExpectedSalesClosureDate/${projectId}`, obj);
    },

    updateLead: function (leadid, leadstage) {
        return axios.put(`${QTurl}lead/updateLead/${leadid}`, leadstage);
    },
    leadWonRejection: function (leadid, obj) {
        return axios.put(`${QTurl}lead/leadWonRejection/${leadid}`, obj);
    },
    customerActions: function (leadid, leadstage) {
        return axios.put(`${QTurl}user/updateLead/${leadid}`, leadstage);
    },
    salesManagersApprovedLead: function (leadid, data) {
        return axios.put(`${QTurl}lead/salesManagersApprovedLead/${leadid}`, data);
    },
    createNewProjectForAdditionalQuotation: function (data) {
        return axios.post(`${QTurl}project/new`, data)
    },
    getDesginManagersUserList: function (designManager) {
        return axios.get(`${QTurl}user/getDesginManagersUserList/${designManager}`);
    },
    assignLeadToDesginUser: function (leadid, data) {
        return axios.put(`${QTurl}lead/assignLeadToDesginUser/${leadid}`, data);
    },
    sendOtpMessage: function (data) {
        return axios.post(`${QTurl}user/sendOtpMessage`, data)
    },
    approveCustomerCheckList: function (leadid, data) {
        return axios.put(`${QTurl}lead/approveCustomerCheckList/${leadid}`, data);
    },
    createDesignMom: function (data)
    {
        return axios.post(`${QTurl}lead/createDesignMom`, data)
    },
    getLocationList: function () {
        return axios.get(`${QTurl}location`);
    },
    creteLeadCriteria: function (data) {
        return axios.post(`${QTurl}lead/lead-criteria`, data);
    },
    getLocationRateCardMasterList: function (locationId) {
        return axios.get(`${QTurl}rate-card-master/${locationId}`)
    },
    getExpericenCenters: function () {
        return axios.get(`${QTurl}experience-center`);
    },
    getAllExpCenter : function () {
        return axios.get(`${QTurl}customerServey/allExpCenterDetails`);
    },
    getExpericenCentersByLocation: function (locationId) {
        return axios.get(`${QTurl}experience-center/${locationId}`);
    },
    deleteLeadConfirm: function (leadData) {
        return axios.put(`${QTurl}lead/deleteLeadConfirm/${leadData}`)
    },
    addLocation: function (name) {
        return axios.post(`${QTurl}location`, name)
    },
    getLocation: function () {
        return axios.get(`${QTurl}location`)
    },
    updateLocation: function (id, data) {
        return axios.put(`${QTurl}location/${id}`, data)
    },
    deleteLocation: function (id) {
        return axios.delete(`${QTurl}location/${id}`)
    },
    addExperinceCenter: function (data) {
        return axios.post(`${QTurl}experience-center`, data)
    },
    getExperinceCenter: function () {
        return axios.get(`${QTurl}experience-center`)
    },
    updateExperinceCenter: function (id, data) {
        return axios.put(`${QTurl}experience-center/${id}`, data)
    },
    deleteExperinceCenter: function (id) {
        return axios.delete(`${QTurl}experience-center/${id}`)
    },
    getApprovedLeadLists: function (expCenterId) {
        return axios.get(`${QTurl}lead/getApprovedLeadLists/?experienceCenterId=${expCenterId}`)
    },
    getDesignData: function (data) {
        return axios.get(`${QTurl}lead/design/?startDate=${data.startDate}&endDate=${data.endDate}&experienceCenterId=${data.expcenterId}`);
    },
    getDesignDataForContract: function (data, expCtr) {
        return axios.put(`${QTurl}lead/getDesignDataForContract/?experienceCenterId=${expCtr}`, {data});
    },
    saveProjectDetails: function (leadId, data) {
        return axios.put(`${QTurl}lead/edit/${leadId}`, data)
    },
    getAllUsers: function () {
        return axios.get(`${QTurl}user/all`)
    },
    updateOrgRateCardMaterial: function (rateCardId, data) {
        return axios.put(`${QTurl}org-rate-card/editProductInOrgRateCard/${rateCardId}`, data)
    },
    addOrgRateCardMaterial: function (rateCardId, data) {
        return axios.put(`${QTurl}org-rate-card/addProductInOrgRateCard/${rateCardId}`, data)
    },
    inActiveProduct(id, data) {
        return axios.put(`${QTurl}org-rate-card/deactivate/${id}`, data)
    },
    isComplimentaryItem(id, data) {
      return axios.put(`${QTurl}org-rate-card/isComplimentaryItem/${id}`, data)
    },
    remapMaterials: function (data, projectId) {
        if (projectId)
            return axios.put(`${Apiurl}project-material/bom-material/remap-material?projectId=${projectId}`, data)
        return axios.put(`${Apiurl}project-material/bom-material/remap-material`, data)
    },
    sendMaterialForApproval: function (materialId) {
        return axios.put(`${Apiurl}project-material/bom/send-for-approval/${materialId}`)
    },
    getMaterialsForApproval: function () {
        return axios.get(`${Apiurl}project-material/bom-material-for-approval`)
    },
    approveMaterial: function (materialId) {
        return axios.put(`${Apiurl}materialMaster/materials/approve-materials/${materialId}`)
    },
    inActiveUser: function (userId) {
        let data = '';
        return axios.put(`${QTurl}user/deactivate/${userId}`, data)
    },
    deactivateTeam: function (teamId) {
        let data = '';
        return axios.put(`${QTurl}team/deactivate/${teamId}`, data)
    },
    sendRequrestToFinanceAndDesgin: function (leadId, data) {
        return axios.put(`${QTurl}lead/designToExecutionFlow/${leadId}`, data)
    },
    getDesignToFinanceLeadApprovalLeadList: function (expcenter) {
        return axios.get(`${QTurl}lead/designToExecutionLogs/${expcenter}`)
    },
    updateDesignToFinanceLeadApprovalStatus: function (leadId, data) {
        return axios.put(`${QTurl}lead/designToExecutionLogs/${leadId}`, data)
    },
    updateDesignToFinanceLeadRejectStatus: function (leadId, data) {
        return axios.put(`${QTurl}lead/designToExecutionRejectLogs/${leadId}`, data)
    },
    customerContractSingActions: function (leadid, data) {
        return axios.put(`${QTurl}user/updateCustomerContractSingedStatus/${leadid}`, data);
    },
    getAllContractSignedLeads: function (expcenter) {
        return axios.get(`${QTurl}lead/contractApprovedLeads/${expcenter}`)
    },
    getAllQuotationsSingleProject(quotationno) {
        return axios.get(`${QTurl}quotation/customerquote/${quotationno}`)
    },
    customerQuotationsActions: function (leadid, data) {
        return axios.put(`${QTurl}user/updatecustomerQuotationsActions/${leadid}`, data);
    },
    CustomerPrivewQuotationView(leadId) {
        return axios.get(`${QTurl}lead/CustomerPrivewQuotationView/${leadId}`)
    },
    reinstate(leadId, obj) {
        return axios.put(`${QTurl}lead/reinstateProcess/${leadId}`, obj)
    },
    cloneSelectedQuotation: function (cloneData) {
        return axios.get(`${QTurl}quotation/cloneSelectedQuotation/?versionId=${cloneData.versionId}&leadId=${cloneData.leadId}&lastVersion=${cloneData.lastVersionNo}`)
    },
    getMaskedQuotation: function (quotationId) {
        return axios.get(`${QTurl}quotation/getMaskedQuotation/${quotationId}`)
    },
    submitSurveyForm: function (data) {
        return axios.post(`${QTurl}customerServey/addCustomerSurvey`, data)
    },
    getSurveyFormList: function (type, obj) {
        return axios.get(`${QTurl}customerServey/getCustomerSurveyList/?typeOption=${type}&experienceCenterId=${obj.experienceCenterId}`);
    },
    findExistingCustomerSurveyLead: function (leadId, type) {
        return axios.get(`${QTurl}customerServey/checkCustomerSurveyByLead/?leadId=${leadId}&typeOption=${type}`);
    },
    getAllMangers: function () {
        return axios.get(`${QTurl}user/getallManagersData`);
    }, allRateCardsMaster: function () {
        return axios.get(`${QTurl}rate-card-master/masterRateCardData`)
    },
    update_project_rate_card: function (obj) {
        return axios.get(`${QTurl}rate-card-master/update_project_rate_card/?leadId=${obj.leadId}&rateCardId=${obj.rateCardId}`)
    },
    getDefaultProjectRateCardMaster: function (selectedLeadId) {
        return axios.get(`${QTurl}project-rate-card-master/getDefaultProjectRateCardMaster/?leadId=${selectedLeadId}`)
    },
    getCheckList() {
        return axios.get(`${QTurl}checklist`)
    },
    addCheckList(data) {
        return axios.post(`${QTurl}checklist`, data)
    },
    editCheckList(id, data) {
        return axios.put(`${QTurl}checklist/update/${id}`, data)
    },
    getAllFinalPaymentApproveRequest(type) {
        return axios.get(`${QTurl}lead/getFinalPayments?stageType=${type}`)
    },
    updateFinalPaymentStatus(id, data) {
        return axios.put(`${QTurl}lead/updateFinalPaymentStatus/${id}`, data)
    },
    updateDesignerPaymentStatus(id, data) {
        return axios.put(`${QTurl}lead/updateDesignerPaymentStatus/${id}`, data)
    },
    rejectContractSignedDocument(leadId, data) {
        return axios.put(`${QTurl}lead/rejectContractSignedDocuments/${leadId}`, data)
    },
    getDesignUserByTeam(teamId) {
        return axios.get(`${QTurl}user/getTeamUser?teamId=${teamId}`)
    },
    updateDesignerAssignementDetail: function (leadid, data) {
        return axios.put(`${QTurl}lead/updateDesignerAssignementDetail/${leadid}`, data);
    },
    getCHMLeadLists: function (data) {
        return axios.put(`${QTurl}chmleads/getData`,data)
    },
    getChmUser: function () {
        return axios.get(`${QTurl}chmleads/getAllChmUsers`)
    },
    get_chm_lead_counts: function (expcenter) {
        return axios.get(`${QTurl}chmleads/chm_lead_counts/${expcenter}`)
    },
    get_sales_statistics_data: function (query) {
        return axios.put(`${QTurl}lead/sales_statistics`,query)
    },
    moveLeadToFactory: function (leadId) {
        return axios.put(`${QTurl}lead/moveLeadToFactory/${leadId}`)
    },
    getSiteQcDocument: function (leadId) {
        return axios.put(`${QTurl}lead/getSiteQcDocument/${leadId}`)
    },
    extraDaysDuetoClientDependency: function (leadid,obj) {
        return axios.put(`${QTurl}lead/extraDaysDuetoClientDependency/${leadid}`,obj);
    },
    getDetailsOfImosProject: function(leadid) {
        return axios.get(`${QTurl}imosProjects/getDetailsOfImosProject/${leadid}`);
    },
    filteredSurveyFormList: function (type, obj) {
        return axios.get(`${QTurl}customerServey/filteredCustomerSurveyList/?typeOption=${type}&startDate=${obj.startDate}&endDate=${obj.endDate}&experienceCenterId=${obj.experienceCenterId}`);
    },
    removeMtr: function (mtrId) {
        return axios.delete(`${Apiurl}material-transfer/removeMtr/${mtrId}`)
    },
    removePO: function (poId) {
        return axios.delete(`${Apiurl}procurement/removePO/${poId}`)
    },removeSo: function ( soId) {
        return axios.delete(`${Apiurl}finance/removeSo/${soId}`)
    },
    siteBomcomplted: function (leadId) {
        return axios.put(`${QTurl}siteBomProject/siteBomcomplted/${leadId}`)
    },
    getTeamsBasedOnTeamId:function(teamId){
        return axios.get(`${QTurl}Team/getTeamofUser/${teamId}`)
    },
    UpdateExecutionUser: function (leadId,obj) {
        return axios.put(`${QTurl}lead/assignLeadToExecutionUsers/${leadId}`,obj)
    },
    getTheVendorSOManage:function(data){
        return axios.get(`${Apiurl}vendorAssignmentLeads/getProjectsSoData/?user=${data}`)
    },
    getVendorSoExecutives: function () {
        return axios.get(`${QTurl}vendorAssignmentLeads/getVendorRoleBasedUsers`);
    },
    updateVendorSoExecutive: function (VendorSoId,assignTo) {
        return axios.put(`${QTurl}vendorAssignmentLeads/assignVendorExcutive/${VendorSoId}/?user=${assignTo}`);
    },
    floorPlanUpdate:function (leadid,obj) {
        return axios.put(`${QTurl}lead/floorPlanUpdate/${leadid}?floorPlanAttachment=${obj}`,obj);
    },

    // Getting Exp Centre wise data for Statistics Dashboard based on stages "Sales", "Design", "Material Delivery", "Completed" 
    getExpCenterBasedData: function (data) {
        return axios.get(`${QTurl}lead/expCenterBasedData?stage=${data.stage}&startDate=${data.startDate}&endDate=${data.endDate}&leadStatus=${data?.leadStatus}&tokenValue=${data.tokenValue}`);
    },

    // Getting Sales Data, Sales Manager & Sales User wise
    getSalesUsersAndManagersBasedData: function (startDate,endDate,leadStatus,tokenValue) {
        return axios.get(`${QTurl}lead/salesUsersAndManagersBasedData?startDate=${startDate}&endDate=${endDate}&leadStatus=${leadStatus}&tokenValue=${tokenValue}`);
    },

    // Getting Design Data, Design Manager & Design User wise
    getDesignUsersAndManagersBasedData: function (startDate,endDate,leadStatus,tokenValue) {
        return axios.get(`${QTurl}lead/designUsersAndManagersBasedData?startDate=${startDate}&endDate=${endDate}&leadStatus=${leadStatus}&tokenValue=${tokenValue}`);
    },

    // Getting Data Based On Click On Count/Value of the table of Statistics
    getDataBasedOnQuery: function (data) {
        return axios.put(`${QTurl}lead/sendDataBasedOnQuery`,data);
    },

    // Getting Modular delivery data for for factory review sheet based on locations
    getMaterialDeliveryCityWise: function (data) {
        return axios.get(`${QTurl}lead/getMaterialDeliveryCityWise?startDate=${data.startDate}&endDate=${data.endDate}&searchType=${data.searchType}&search=${data.search}`);
    },

    // Getting data for factory review sheet which under execution
    getUnderExecutionDataCityWise: function (data) {
        return axios.get(`${QTurl}lead/getUnderExecutionDataCityWise?startDate=${data.startDate}&endDate=${data.endDate}&searchType=${data.searchType}&search=${data.search}`);
    },
    // Getting Project Completion data for factory review sheet 
    getProjectCompletionDataCityWise: function (data) {
        return axios.get(`${QTurl}lead/getProjectCompletionDataCityWise?startDate=${data.startDate}&endDate=${data.endDate}&searchType=${data.searchType}&search=${data.search}`);
    },

    // Getting Completed MWP data for factory review sheet
    getCompletedMWPDataUserWise: function (data) {
        return axios.get(`${QTurl}lead/getCompleted-MWPDataUserWise?searchType=${data.searchType}&search=${data.search}`);
    },
    
    // Getting Completed Project data User - Wise for factory review sheet
    getProjectCompletionDataUserWise: function (data) {
        return axios.get(`${QTurl}lead/getProjectCompletionDataUserWise?searchType=${data.searchType}&search=${data.search}`);
    },

    // Getting Factory Manager data for factory review sheet
    getFactoryStageData: function (data) {
        return axios.get(`${QTurl}lead/getFactoryStageData?searchType=${data.searchType}&search=${data.search}`);
    },

    // Getting Factory Manager data for factory review sheet
    getDataForOps1Tab: function (data) {
        return axios.get(`${QTurl}lead/getDataForOps1Tab?searchType=${data.searchType}&search=${data.search}`);
    },

    getPopaymentreq:function () {
        return axios.get(`${Apiurl}procurement/payment-approver-request`);
    },
    getSopaymentreq:function () {
        return axios.get(`${Apiurl}serviceOrder/payment-approver-request`);
    },
    getSelectedExpCenterDetails:function(experienceCenter){
        return axios.get(`${QTurl}experience-center/experienceCenterDetails?experienceCenter=${experienceCenter}`)
    },
    createTransactionPineLabs:function(obj){
        return axios.post(`${QTurl}paymentTransactions/createTransaction`,obj)
    },
    addTransactionPineLabs:function(obj){
        return axios.post(`${QTurl}paymentTransactions/addTransactionData`,obj)
    },
    cancelTransactionPineLabs:function(obj){
        // console.log('obj in http', obj)
        return axios.post(`${QTurl}paymentTransactions/cancelTranscation`,obj)
    },
    knowTransactionsNoforLead : function (leadId){
        return axios.get(`${QTurl}paymentTransactions/knowTransactionsNoforLead/${leadId}`)
    },

    // Function to get approved payments data
    fetchApprovedPaymentsData: function (payload) {
        return axios.get(`${QTurl}paymentTransactions/fetch-approved-payments-data?startDate=${payload.startDate}&endDate=${payload.endDate}&page=${payload.page}&rowsPerPage=${payload.rowsPerPage}`)
    },
    
    sendRequestForLink : function (leadId){
        return axios.put(`${QTurl}lead/sendRequestForLink/${leadId}`)
    },
    getThePaymentRequestDetails :function (expCenter){
        return axios.get(`${QTurl}lead/getThePaymentRequestDetails/${expCenter}`)  
    },
    updateRequestForLink : function (leadId,status){
        return axios.put(`${QTurl}lead/updateRequestForLink/${leadId}/?status=${status}`)
    },
    addOtherTransaction : function (data){
        return axios.post(`${QTurl}paymentTransactions/addOtherTransaction`, data)
    },
    updateFinancePaymentAction : function (data){
        return axios.put(`${QTurl}paymentTransactions/updateFinancePaymentAction`, data)
    },
    approveManualPayment : function (data){
        return axios.put(`${QTurl}paymentTransactions/approveManualPayment`, data)
    },
    // api function to approve/reject automatic payment
    approveAutomaticPayment : function (data){
        return axios.put(`${QTurl}paymentTransactions/approveAutomaticPayment`, data)
    },
    editPaymentTransaction : function (data){
        return axios.put(`${QTurl}paymentTransactions/editPaymentTransaction`, data)
    },
    updateFinan : function (data){
        return axios.post(`${QTurl}paymentTransactions/addOtherTransaction`, data)
    },
    getAllPaymentModes : function (){
        // console.log('http called')
        return axios.get(`${QTurl}paymentModes/getAllPaymentModes`)
    },
    addPaymentMode : function (data){
        // console.log('http called')
        return axios.post(`${QTurl}paymentModes/addPaymentMode`, data)
    },
    updatePaymentMode : function (data){
        // console.log('http called')
        return axios.post(`${QTurl}paymentModes/updatePaymentMode`, data)
    },

    
    paymentpatners : function (){
        return axios.get(`${QTurl}paymentPatners/getpaymentPatners`)
    },
    cashFreePaymentUpload : function (leadId,data){
        return axios.post(`${QTurl}paymentTransactions/cashFreeTransactionCreate/${leadId}`,data)
    },
    getSalesDesginUserDetail : function (){
        return axios.get(`${QTurl}paymentPatners/getSalesDesginUserDetail`)
    },
    getDataforSentforFinanceApproval:function(){
        return axios.get(`${Apiurl}procurement/getDataforSentforFinanceApproval`);   
    },
    updateFinanceStatus:function (data){
        return axios.put(`${Apiurl}procurement/updateFinanceStatus`,data);
    },
    getAllMaterialsStockAndMoq: function (type) {
        return axios.get(`${Apiurl}materialMaster/materials/allMaterialsStockAndMoq/?type=${type}`)
    },
    getAllMaterialsNameAndCode: function() {
        return axios.get(`${Apiurl}materialMaster/materials/allMaterialsNameAndCode`)
    },
    getSelectedSearchMaterialData: function(data){
        return axios.post(`${Apiurl}materialMaster/materials/selectedSearchMaterialData`,data)
    },
    getAllProcurementsForInwardingPaginated: function (code, type,page,limit) {
        if (code)
            return axios.get(`${Apiurl}procurement/getAllProcurementsForInwardingPaginated?projectCode=${code}`)
        return axios.get(`${Apiurl}procurement/getAllProcurementsForInwardingPaginated?procurementType=${type}&page=${page}&limit=${limit}`)
    },
    getLeadsCreated: function (data) {
        return axios.get(`${QTurl}leadJourney/marketingDashboard?startDate=${data.startDate}&endDate=${data.endDate}`)
    },
    getSalesDataBasedOnExpCenter: function (data) {
        return axios.get(`${QTurl}leadJourney/dataBasedexpCenterBasedData?startDate=${data.startDate}&endDate=${data.endDate}&selectedExperienceCenter=${data.selectedExperienceCenter}&token=${data.tokenPercentage}&lost=${data.lost}&pivotValue=${data.pivotValue}&users=${data.users}&selectedRole=${data.selectedRole}&userExpCenter=${data.userExpCenter}&searchType=${data.searchType}&search=${data.search}`)
    },
    // getDesignSignOffDataBasedOnExpCenter: function (data) {
    //     return axios.get(`${QTurl}leadJourney/dataBasedexpCenterBasedData?startDate=${data.startDate}&endDate=${data.endDate}&selectedExperienceCenter=${data.selectedExperienceCenter}&token=${data.tokenPercentage}&lost=${data.lost}&pivotValue=${data.pivotValue}`)
    // },
    overallMISDashBoard: function (data) {
        return axios.get(`${QTurl}leadJourney/overallMISDashboard?startDate=${data.startDate}&endDate=${data.endDate}&token=${data.tokenPercentage}`)
    },
    overallReviewDashBoard: function (data) {
        return axios.put(`${QTurl}leadJourney/overallReview?startDate=${data.startDate}&endDate=${data.endDate}&selectedExperienceCenter=${data.selectedExperienceCenter}&token=${data.tokenPercentage}`, data)
    },
    overallSummaryDashBoard: function (data) {
        return axios.get(`${QTurl}leadJourney/overallSummary?startDate=${data.startDate}&endDate=${data.endDate}&selectedExperienceCenter=${data.selectedExperienceCenter}&token=${data.tokenPercentage}&users=${data.users}&selectedRole=${data.selectedRole}&userExpCenter=${data.userExpCenter}&searchType=${data.searchType}&search=${data.search}`)
    },
    lostDataAnalysis: function (data) {
        return axios.get(`${QTurl}leadJourney/lostDataAnalysis?startDate=${data.startDate}&endDate=${data.endDate}&selectedExperienceCenter=${data.selectedExperienceCenter}&token=${data.tokenPercentage}&users=${data.users}&selectedRole=${data.selectedRole}&userExpCenter=${data.userExpCenter}&department=${data.department}&searchType=${data.searchType}&search=${data.search}`)
    },
    getSalesDatoverallReviewDashBoard: function (data) {
        return axios.put(`${QTurl}leadJourney/salesOverallReview?startDate=${data.startDate}&endDate=${data.endDate}&selectedExperienceCenter=${data.selectedExperienceCenter}&token=${data.tokenPercentage}&lost=${data.lost}`,data)
    },
    getSelectedUsersBasedOnRoles: function (data) {
        return axios.get(`${QTurl}leadJourney/SelectedusersBasedOnRole?roleName=${data.role}&pivotValue=${data.pivotValue}`)
    },
    getDataBasedClickOverallMIS: function (data) {
        return axios.put(`${QTurl}leadJourney/getDataBasedClickOverallMIS`, data)
    },
    getDataBasedClickOverallSummary: function (data) {
        return axios.put(`${QTurl}leadJourney/getDataBasedClickOverallSummary`, data)
    },
    getDataBasedClickOveralReview: function (data) {
        return axios.put(`${QTurl}leadJourney/getDataBasedClickOveralReview`, data)
    },
    getDataBasedClickMarketing: function (data) {
        return axios.put(`${QTurl}leadJourney/getDataBasedClickMarketing`, data)
    },
    getDataBasedClickSales: function (data) {
        return axios.put(`${QTurl}leadJourney/getDataBasedClickSales`, data)
    },
    // getDataBasedClickDesignSignOff: function (data) {
    //     return axios.put(`${QTurl}leadJourney/getDataBasedClickSales`, data)
    // },
    getDataBasedClickSalesOverview: function (data) {
        return axios.put(`${QTurl}leadJourney/getDataBasedClickSalesOverview`, data)
    },
    getDataBasedClickLost: function (data) {
        return axios.put(`${QTurl}leadJourney/getDataBasedClickLost`, data)
    },
    addCustomersToErp : function(filepath){
        return axios.post(`${QTurl}lead/addCustomersToErp`,filepath);
    },
    // show quotation which is send by approval request
    getSentApprovalQuotation() {
        return axios.get(`${QTurl}lead/quotationApproval`);
    },
    // when quotation is approved or rejected by approval request
    approvalQuotation(projectId,data){
        return axios.post(`${QTurl}lead/approvalQuoationStatus/${projectId}`,data);
    },
    // Send for Approval Quotation Changes
    quotationChangesApproval : function (data) {
        return axios.post(`${QTurl}lead/sendQuotationChangesApproval`, data);
    },
    // when approver making request for quotation changes
    approverEditViewAction(projectId,data){
        return axios.post(`${QTurl}lead/approverEditViewAction/${projectId}`,data);
    },
    getAllLeadDetailsForCHMticketGeneration : function(){
        return axios.get(`${QTurl}lead/getAllLeadDetailsForCHMticketGeneration`);
    },
    createCHMticket: function (obj){
        return axios.post(`${QTurl}chmTicket/new`, obj);
    },
    allusersOfDepartment: function (departmentId) {
        return axios.get(`${QTurl}department/allusersOfDepartment/${departmentId}`);
    },
    getTicketsForUser: function () {
        return axios.get(`${QTurl}chmTicket/getTickets`);
    },
    getFilteredTickets: function (ribbonTagValue,dateObj,categories, chmUsers) {
        return axios.get(`${QTurl}chmTicket/getFilteredTickets/?ribbonTagValue=${ribbonTagValue}&startDate=${dateObj.startDate}&endDate=${dateObj.endDate}&categories=${categories}&chmUsers=${chmUsers}`);
    },
    addCHMticketCategory: function (obj) {
        return axios.post(`${QTurl}CHMticketCategory/new`,obj);
    },
    editCHMticketCategory: function (obj, categoryId) {
        return axios.put(`${QTurl}CHMticketCategory/edit/?categoryId=${categoryId}`,obj);
    },
    getAllCHMticketCategory: function () {
        return axios.get(`${QTurl}CHMticketCategory/all`);
    },
    deleteCHMticketCategory: function (data) {
        return axios.put(`${QTurl}CHMticketCategory/delete/?categoryId=${data}`);
    },
    closeCHMticekt: function (data, remarks) {
        return axios.put(`${QTurl}chmTicket/closeIssue/?ticketId=${data}`,remarks);
    },
    resolveCHMticekt: function (data, remarks) {
        // console.log('remarks are ',remarks);
        return axios.put(`${QTurl}chmTicket/resolveIssue/?ticketId=${data}`,remarks);
    },
    deleteCHMticekt: function (data, remarks) {
        // console.log('remarks are ',remarks);
        return axios.delete(`${QTurl}chmTicket/soft-delete/?ticketId=${data}`);
    },
    getAllCHMusers: function () {
        return axios.get(`${QTurl}user/all`);
    },
    getSearchedTicket: function(ticketId){
        // console.log('search api hit')
        return axios.get(`${QTurl}chmTicket/searchByTicketId/?ticketId=${ticketId}`)
    },
    getRibbonNumberInfo: function(){
        return axios.get(`${QTurl}chmTicket/getRibbonNumberInfo`)
    },
    getOrderedMaterialsQty: function(type){
        return axios.get(`${Apiurl}procurement/getOrderedMaterialsQty?type=${type}`)
    },
    getAmountPaid : function(leadId){
        return axios.get(`${QTurl}lead/getAmountPaid/?leadId=${leadId}`);
    },
    updateCompanyPLData : function(data){
        return axios.post(`${Apiurl}fileUpload/updateCompanyP&LData`, data)
    },
    getPostDesignSignOffObj : function(){
        return axios.get(`${QTurl}lead/postDesignSignOffCategoryObject`)
    },
    getLeadDetailsForChmTicket: function(leadId){
        return axios.get(`${QTurl}lead/getLeadDetailsForChmTicket/?leadId=${leadId}`)
    },
    changeCHMticketAssignedUser: function(ticketId, userId, departmentId, teamId){
        return axios.put(`${QTurl}chmTicket/changeAssignedUser/?ticketId=${ticketId}&userId=${userId}&departmentId=${departmentId}&teamId=${teamId}`)
    },
    getDesignStageList: function(){
        return axios.get(`${QTurl}customer/getAllDesignStages`)
    },
    get_call_center_logs: function(rowsPerPage){
        return axios.get(`${QTurl}call_center/get_call_center_logs?rowsPerPage=${rowsPerPage}`)
    },
    get_filtered_call_center_logs: function(data){
        return axios.put(`${QTurl}call_center/get_filtered_call_center_logs`, data)
    },
    updateCustomerStages : function(data){
        return axios.post(`${QTurl}customer/updateCustomerStages`, data);
    },
    updateWhatsAppNotificationStages : function(data){
        return axios.post(`${QTurl}lead/updateWhatsAppNotificationStages`, data);
    },
    discussionNotesData : function(obj){
        return axios.put(`${QTurl}lead/discussionNotes`,obj)
    },
    editTicketDetails: function(obj){
        return axios.put(`${QTurl}chmTicket/editTicketDetails`,obj)
    },
    updateCustomerDesignHold(leadId,obj) {
        return axios.post(`${QTurl}user/updateCustomerDesignHold/${leadId}`,obj);
    },  
    getAllDesignHoldLeads: function(){
        return axios.get(`${QTurl}lead/getAllDesignHoldLeads`)
    },
    updateDesignHoldApprovalStatus: function(obj,projectId){
        return axios.post(`${QTurl}lead/updateDesignHoldApprovalStatus/${projectId}`,obj)
    },
    getDesignHoldAnalysisData: function (data) {
        return axios.put(`${QTurl}leadJourney/getDesignHoldAnalysisData`, data)
    },
    changeCHMticketAssignedUserFromEmail: function(ticketId, email){
        return axios.put(`${QTurl}chmTicket/changeAssignedUserFromEmail/?ticketId=${ticketId}&email=${email}`)
    },
    createTask: function (obj){
        return axios.post(`${QTurl}taskTicket/new`, obj);
    },
    getFilteredTaskTickets: function (ribbonTagValue,dateObj,status) {
        return axios.get(`${QTurl}taskTicket/getFilteredTickets/?ribbonTagValue=${ribbonTagValue}&startDate=${dateObj.startDate}&endDate=${dateObj.endDate}&status=${status}`);
    },
    closeTaskticket: function (data, remarks) {
        return axios.put(`${QTurl}taskTicket/closeIssue/?ticketId=${data}`,remarks);
    },
    resolveTaskticket: function (data, remarks) {
        // console.log('remarks are ',remarks);
        return axios.put(`${QTurl}taskTicket/resolveIssue/?ticketId=${data}`,remarks);
    },
    deleteTaskTicket: function (data, remarks) {
        // console.log('remarks are ',remarks);
        return axios.delete(`${QTurl}taskTicket/soft-delete/?ticketId=${data}`);
    },
    getSearchedTaskTicket: function(ticketId){
        // console.log('search api hit')
        return axios.get(`${QTurl}taskTicket/searchByTicketId/?ticketId=${ticketId}`)
    },
    getTaskRibbonNumberInfo: function(selectedStatusData){
        return axios.get(`${QTurl}taskTicket/getRibbonNumberInfo/?status=${selectedStatusData}`)
    },
    changeTaskTicketAssignedUserFromEmail: function(ticketId, email){
        return axios.put(`${QTurl}taskTicket/changeAssignedUserFromEmail/?ticketId=${ticketId}&email=${email}`)
    },
    editTaskTicketDetails: function(obj){
        return axios.put(`${QTurl}taskTicket/editTicketDetails`,obj)
    },
    // //QT Integration API
    // getAllQtProjects: function (data) {
    //     return axios.post(`${QTurl}project/activeDashboard`, data)
    // },
    // getOrgRateCardForRateCardMaster: function (rateCardMasterId) {
    //     return axios.get(`${QTurl}org-rate-card/${rateCardMasterId}`)
    // },
    // cloneOrgRateCard: function (rateCardMasterId) {
    //     return axios.post(`${QTurl}org-rate-card/clone-rate-card/${rateCardMasterId}`)
    // },
    // getAllRateCardMaster: function () {
    //     return axios.get(`${QTurl}rate-card-master`)
    // },
    // customerProject: function (data) {
    //     return axios.get(`${QTurl}project/customer/${data}`)
    // },
    // getDepartments: function () {
    //     return axios.get(`${Apiurl}department/all`);
    // },
    // createDepartment: function (data) {
    //     return axios.post(`${Apiurl}department/addDepartment/`, data)
    // },
    // editDepartment: function (data, id) {
    //     return axios.put(`${Apiurl}department/editDepartment/${id}`, data)
    // },
    // deleteDepartment: function (deptID) {
    //     return axios.delete(`${Apiurl}department/deleteDepartment/${deptID}`)
    // },
    // getTeams: function () {
    //     return axios.get(`${Apiurl}team/all`)
    // },
    // createTeam: function (data) {
    //     return axios.post(`${Apiurl}team/addTeam/`, data)
    // },
    // getQTTeamUsers: function (id) {
    //     return axios.get(`${Apiurl}user/usersOfTeam/${id}`);
    // },
    // getQtTeamManager: function (id) {
    //     return axios.get(`${QTurl}user/managerOfTeam/${id}`);
    // },
    // assignProject: function (data) {
    //     return axios.post(`${QTurl}assignTo`, data);
    // },
    // wdFileUpload: function (data) {
    //     return axios.post(`${QTurl}file/upload?type=attachment`, data)
    // },
    // getQTUsers(id) {
    //     return axios.get(`${QTurl}user/usersOfTeam/${id}`);
    // },
    // getAllQuotationsForProject(projectId) {
    //     return axios.get(`${QTurl}quotation/all/${projectId}`)
    // },
    // getProjectDetails(projectId) {
    //     return axios.get(`${QTurl}project/${projectId}`)
    // },
    // generateQuotationExcel(quotationId) {
    //     return axios.get(`${QTurl}quotation/excel/${quotationId}`)
    // },
    // getProjectComponent: function (projectId) {
    //     return axios.get(`${QTurl}projectComponent/${projectId}`)
    // },
    // addProjectComponent: function (data) {
    //     return axios.post(`${QTurl}projectComponent/new`, data)
    // },
    // getAllProductsFromRateCard: function (projectId) {
    //     return axios.get(`${QTurl}project-rate-card/default-rate-card/${projectId}`)
    // }
    getCallLogsForLead : function(data) {
        return axios.put(`${QTurl}call_center/getCallLogsForLead`, data)
    }

}

export default HttpService;
