import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { setLoading, setSnackInfo } from '../../redux/actions/appAction'
import HttpService from '../../services/httpService'
import { Table, Button, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import { DeleteOutlined } from '@ant-design/icons';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  }
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#ccc",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow)

const Location = (props) => {
  const classes = useStyles();

  const [locationModal, setLocationModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [locationList, setLocationList] = useState([])
  const [locationData, setLocationData] = useState({
    _id: "",
    name: ""
  })
  const [name, setName] = useState("")

  useEffect(() => {
    getLocation()
    // eslint-disable-next-
  }, [])

  const submit = async () => {
    props.setLoading(true)
    try {
      await HttpService.addLocation({ name: locationData.name })
      setLocationModal(false)
      props.setSnackInfo('Location Added')
      getLocation()
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  const update = async () => {
    props.setLoading(true)
    try {
      await HttpService.updateLocation(locationData._id, { name: locationData.name })
      setLocationModal(false)
      props.setSnackInfo('Location Updated')
      setIsEdit(false)
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props.setLoading(false)
    getLocation()
  }

  const getLocation = async () => {
    props.setLoading(true)
    try {
      const response = await HttpService.getLocation()
      setLocationList(response.data)
    } catch (error) {
      console.error(error)
      setLocationList([])
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  const editLocation = (data) => {
    setLocationData({ name: data.name, _id: data._id })
    setLocationModal(true)
    setIsEdit(true)
  }

  const deleteLocation = async (id) => {
    props.setLoading(false)
    try {
      await HttpService.deleteLocation(id)
      props.setSnackInfo('Location Deleted', "success")
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props.setLoading(false)
    getLocation()
  }
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: 10 }}>
        <Button variant="contained" color="primary" onClick={() => setLocationModal(true)}>
          Add Location
        </Button>
      </div>

      {locationList.length > 0 && <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Sl No</StyledTableCell>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {locationList?.map((data, i) => (
              <StyledTableRow key={i + 1}>
                <StyledTableCell>{i + 1}</StyledTableCell>
                <StyledTableCell>{data?.name}</StyledTableCell>
                <StyledTableCell>
                  <StyledTableRow>
                    <StyledTableCell>
                      <Tooltip title="Edit Location">
                        <EditIcon onClick={() => editLocation(data)} />
                      </Tooltip></StyledTableCell>
                    <StyledTableCell>
                      <Tooltip title="Delete Location">
                        <DeleteOutlined onClick={() => deleteLocation(data?._id)} />
                      </Tooltip></StyledTableCell>
                  </StyledTableRow >
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>}

      <Dialog
        open={locationModal}
        TransitionComponent={Transition}
        keepMounted
        fullWidth={true}
        maxWidth={'sm'}
        onClose={() => { setLocationModal(false); setIsEdit(false) }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Add Location"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description" style={{ display: "flex", flexDirection: "column" }}>
            <TextField label="Location Name" style={{ marginBottom: 10 }} name="name" onChange={(e) => setLocationData({ ...locationData, name: e.target.value })} value={locationData.name} variant="outlined" />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {isEdit ? <Button onClick={update} color="primary">
            UPDATE
          </Button> : <Button onClick={submit} color="primary">
            ADD
          </Button>}
        </DialogActions>
      </Dialog>
    </div >
  )
}

export default connect(null, { setLoading, setSnackInfo })(Location)
