import React, { useState, useEffect, useRef } from 'react';
import { useParams } from "react-router-dom";
import { connect, useSelector } from 'react-redux'
import CryptoJs from 'crypto-js';
import { setLoading, setSnackInfo } from '../redux/actions/appAction';
import Divider from '@material-ui/core/Divider';
import Table from 'react-bootstrap/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import HttpService from '../services/httpService';
import RadioGroup from '@material-ui/core/RadioGroup';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { TextField, FormControl, Typography, DialogTitle, Card, CardContent } from '@material-ui/core';
import moment from 'moment'
import { commaSeparateAmount } from './commaSeparate';
import WonForm from '../pages/customerSurveyForm/won';
import { ROLENAME, constantDepartmentId } from '../utils/index';
import { textAlign } from '@mui/system';
import '../App.css';



const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 700,
    },
    root: {
        width: '100%',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    container: {
        maxHeight: 450,
    },
}));
const CustomerCheckLists = (props) => {
    const inputChecklistFeids = useRef();
    const classes = useStyles();
    const ref = useRef(null);
    const { leadId } = useParams();
    const [checkList, setCheckList] = useState([]);
    const [data, setData] = useState({
        grandTotal: "",
        paymentDone: "",
        staticSalesWonAmount : ''
    })

    const { grandTotal, paymentDone,staticSalesWonAmount } = data

    useEffect(() => {
        getLeadDetails();
        getNotesData();
        getLastQuotationForProject();
        // eslint-disable-next-line
    }, [leadId])

    const { checkListRemarkBySalesUser, anyComplimentaryValue, backPlySupport } = data
    const [selectedOption, setSelectedOption] = useState("");
    const [showTextBox, setShowTextBox] = useState(false);
    const { userDetails } = useSelector(state => state.user)
    const [leadDetail, setLeadDetails] = useState([]);
    const [selected, setSelected] = useState([]);
    const [spaceAndProductNames, setSpaceAndProductNames] = useState([]);
    const [complimentaryItemPresent, setComplimentaryItemPresent] = useState(false);
    let [salesUserdata, setSalesUserdata] = useState("")
    let [isCustomerApproved, setIsCustomerApproved] = useState(false);
    let [isCenterHeadApproved, setCenterHeadApproved] = useState(false);
    const [rejectCheckListDialog, setRejectCheckListDialog] = useState(false);
    const [leadOwnerDetail, setLeadOwnerDetail] = useState({
        name: '',
        email: '',
        mobile: '',
        leadId: ''
    })
    const getLeadDetails = async () => {
        props.setLoading(true)
        try {
            const response = await HttpService.getLeadDetails(leadId);
            setTimeout(() => {
                setLeadDetails(response.data)
                let salesUser = [];
                let leadOwnersList = [];
                for (let i = 0; i < response.data.previouslyAssignedTo.length; i++) {
                    for (let j = 0; j < response.data.previouslyAssignedTo[i].roles.length; j++) {
                        if (response.data.salesLeadOwnRole !== "NA") {
                            if (response.data.salesLeadOwnRole === 'SalesUser' && response.data.previouslyAssignedTo[i]['roles'][j].name === ROLENAME.SalesUser) {
                                salesUser.push(response.data.previouslyAssignedTo[i])
                                leadOwnersList.push(response.data.previouslyAssignedTo[i])
                            } else if ((response.data.salesLeadOwnRole === 'SalesManager' && response.data.previouslyAssignedTo[i]['roles'][j].name === ROLENAME.SalesManager) || (response.data.salesLeadOwnRole === 'SalesManager' && response.data.previouslyAssignedTo[i]['roles'][j].name === ROLENAME.AssistantSalesManager)) {
                                salesUser.push(response.data.previouslyAssignedTo[i])
                                leadOwnersList.push(response.data.previouslyAssignedTo[i])
                            }
                        } else if (response.data.previouslyAssignedTo[i]['roles'][j].name === ROLENAME.SalesUser) {
                            salesUser.push(response.data.previouslyAssignedTo[i])
                        } else if (response.data.departmentId === constantDepartmentId.marketing && response.data.previouslyAssignedTo[i]['roles'][j].name === ROLENAME.MarketingUser) {
                            leadOwnersList.push(response.data.previouslyAssignedTo[i])
                        } else if (response.data.departmentId === constantDepartmentId.design && response.data.previouslyAssignedTo[i]['roles'][j].name === ROLENAME.DesignUser) {
                            leadOwnersList.push(response.data.previouslyAssignedTo[i])
                        } else if (response.data.previouslyAssignedTo[i]['roles'][j].name === ROLENAME.SalesManager || response.data.previouslyAssignedTo[i]['roles'][j].name === ROLENAME.AssistantSalesManager) {
                            leadOwnersList.push(response.data.previouslyAssignedTo[i])
                        }
                    }
                }
                let finalSalesUser = salesUser.pop();
                setSalesUserdata(finalSalesUser?.name)
                const { grandTotal, paymentDone, staticSalesWonAmount } = response.data
                setData({ grandTotal, paymentDone, staticSalesWonAmount })
                if (response.data.salesCheckList.length !== 0) {
                    setCheckList(response.data.salesCheckList);
                    setSelected(response.data.salesCheckList);
                } else {
                    getNotesData()
                }
                if (response.data.customerApproved.find(status => status.status === 'Approved')) {
                    setIsCustomerApproved(true)
                }
                if (response.data.customerApproved.find(status => status.status === 'Rejected' || status.status === 'Approved')) {
                    setRejectBtnDisable(true)
                }
                if (response.data.centerHeadApproved.find(isApproved => isApproved.isApproved === true)) {
                    setCenterHeadApproved(true)
                }

                if (leadOwnersList.length !== 0) {
                    let finalLeadOwnerUser = leadOwnersList.pop();
                    setLeadOwnerDetail({
                        name: finalLeadOwnerUser.name,
                        email: finalLeadOwnerUser.email,
                        mobile: finalLeadOwnerUser.mobile,
                        _id: finalLeadOwnerUser._id,
                        customerId: response.data.customerId._id,
                        leadId: leadId
                    })
                } else {
                    setLeadOwnerDetail({
                        name: response.data.assignTo.name,
                        email: response.data.assignTo.email,
                        mobile: response.data.assignTo.mobile,
                        _id: response.data.assignTo._id,
                        customerId: response.data.customerId._id,
                        leadId: leadId
                    })
                }
            }, 500);
        } catch (error) {
            props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const [notes, setNotes] = useState([]);
    const getNotesData = async () => {
        props.setLoading(true)
        try {
            const result = await HttpService.getCheckList();
            setCheckList(result.data[0]['checkList'])
            setNotes(result.data[0]['notes'])
        } catch (error) {
            console.error(error)
            props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }
    const handleRadioButtonClick = (event, index, data) => {
        const newSelected = [...selected];
        newSelected[index].actionSelected = event.target.value;
        setSelected(newSelected);
    }

    const [customerSelected, setCustomerSelected] = useState([]);
    const [btnDisable, setBtnDisable] = useState(true);
    const [value, setValue] = useState(false);
    const [rejectBtDisable, setRejectBtnDisable] = useState(false);
    const [clicked, setClicked] = useState(false);
    const handleCustomerRadioButtonClick = (event, index, data) => {
        const newSelected = [...selected];
        const validateSelected = [...selected];
        newSelected[index].customerAction = event.target.value;
        setCustomerSelected(newSelected);

        validateSelected[index].isChecked = true;
        let countCheck = 0;
        newSelected.forEach((ele, i) => {
            if (ele.isChecked === true) {
                countCheck++
            }
        })
        let checkCustomerNoStatus = [];
        newSelected.forEach(ele => {
            if (ele.customerAction === 'no') {
                checkCustomerNoStatus.push(ele);
            }
        })
        if (newSelected.length === countCheck && checkCustomerNoStatus.length === 0 ) {
            setBtnDisable(false)
        } else if (newSelected.length === countCheck && checkCustomerNoStatus.length === countCheck) {
             setBtnDisable(true)
        } else if (newSelected.length === countCheck && checkCustomerNoStatus.length !== 0 && checkCustomerNoStatus.length !== countCheck) {
             setBtnDisable(true)
        }
        if (leadDetail.customerApproved.find(status => status.status === 'Rejected' || status.status === 'Approved')) {
            setRejectBtnDisable(true)
            setBtnDisable(true)

        }
    }

    const [openModal, setOpenModal] = useState(false);
    const [checkListRemarkByCustomer, setCheckListRemarkByCustomer] = useState('');

    const customerApproveRejectStatus = async (status) => {
        if (status === 'Approve') {
            if (btnDisable === true) {
                inputChecklistFeids?.current?.scrollIntoView({ behavior: "smooth" });  
               return props.setSnackInfo("Please Accept all conditions", "error");
            }
            setOpenModal(true);
        } else {
            let salesUser = [];
            for (let i = 0; i < leadDetail.previouslyAssignedTo.length; i++) {
                for (let j = 0; j < leadDetail.previouslyAssignedTo[i].roles.length; j++) {
                    if (leadDetail.previouslyAssignedTo[i]['roles'][j].name === 'Sales User') {
                        salesUser.push(leadDetail.previouslyAssignedTo[i])
                    }
                }
            }
            let finalSalesUser = salesUser.pop();
            let obj = {
                customerApproved: [{
                    isApproved: false,
                    status: 'Rejected',
                    checkListRemarkByCustomer: checkListRemarkByCustomer
                }],
                salesExecutiveApproved: [{ isApproved: false, status: 'Send for Approval' }],
                // salesManagerApproved: [{ isApproved: false, status: 'Approval Not Initiated' }],
                // finanaceManagerApproved: [{ status: 'Approval Not Initiated', isApproved: false }],
                // businessHeadApproved: [{ status: 'Approval Not Initiated', isApproved: false }],
                salesStage: 'Won Deals Pending Designer Assignment',
                departmentId: finalSalesUser.departmentId,
                teamId: finalSalesUser.teamId,
                assignTo: finalSalesUser._id,
                salesLeadOwnRole: "NA"
            }
            props.setLoading(true)
            try {
                await HttpService.customerActions(leadDetail._id, obj);
                props.setSnackInfo("Rejected Successfully", "success")
                setBtnDisable(true)
                setValue(true);
                setRejectBtnDisable(true)
            } catch (error) {
                console.log(error, 'error');
            }
            setRejectCheckListDialog(false);
            props.setLoading(false)
            window.location.reload(true)
        }
    }

    const [otpSend, setOtpSend] = useState(false);
    const [otpResponse, setOtpResponse] = useState([]);
    // const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');



    const [otpVerified, setOtpVerified] = useState(false);

    const [wonData, setWonData] = useState({})
    const verifyOTP = (e) => {
        // eslint-disable-next-line no-new-wrappers
        let n = new String(e.target.value);
        if (n.length === 6 && otpResponse.otp === +e.target.value) {
            setOtpVerified(true);
        } else {
            setOtpVerified(false);
        }
    }

    const sendDataToParent = (formData) => {
        setWonData(formData)
        sendOtp(formData.email)
    };

    const sendOtp = async (email) => {
        // eslint-disable-next-line no-new-wrappers
        //if (mobile.length === 10) {
        let obj = {
            //mobile: mobile,
            email: email
        }
        props.setLoading(true)
        try {
            const response = await HttpService.sendOtpMessage(obj)
            setOtpSend(true)

            // Decryption of OTP
            var bytes = CryptoJs.AES.decrypt(response.data.otp, 'decorpot123');
            var decrypted_otpNumber = bytes.toString(CryptoJs.enc.Utf8);

            setOtpResponse({ otp: Number(decrypted_otpNumber) })
            ref.current?.scrollIntoView({ behavior: 'smooth' });
            let focusTextFieldOtp = document.getElementById('outlined-basic1-otp');
            focusTextFieldOtp.focus();
        } catch (error) {
            props.setSnackInfo(error.response.data.msg, "error")
        }
        props.setLoading(false)
        // }
    }

    const verifyCustomer = async () => {
        setClicked(true); 
        let obj = {
            customerApproved: [{
                isApproved: true,
                status: 'Approved',
                approvedBy: leadDetail.customerId._id,
                checkListRemarkByCustomer: checkListRemarkByCustomer
            }],
            designHeadAssigned: true,
            salesCheckList: customerSelected,
            salesStage: 'Won',
            leadStatus: "Won",
            ...wonData,
            leadOwner: leadOwnerDetail,
            typeOption: 'won'
        }
        console.log(obj, 'obj')
        props.setLoading(true)
        try {
            await HttpService.customerActions(leadDetail._id, obj);
            setOpenModal(false);
            setOtpSend(false);
            setOtpResponse([]);
            getLeadDetails();
            props.setSnackInfo("Checklists item approved successfullly", "success")
        } catch (error) {
            console.log(error, 'error');
        }
        props.setLoading(false)
    }
    const getLastQuotationForProject = async () => {
        props.setLoading(true)
        try {
            const response = await HttpService.getAllQuotationsForProject(leadId)
            // setQuotationList(response.data)
            if (response.data.length > 0) {
                const sortedQuotations = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                // setFinalQuotation(sortedQuotations[0]);
                // Assuming sortedQuotations is an array of Quotation objects and each Quotation object has a 'components' array
                const components = sortedQuotations[0].components;
                const extractedSpacesAndProducts = [];
                components.forEach((component) => {
                    const complimentaryProduct = component.products
                        .flatMap((product) => product.product)
                        .find((product) => product.isComplimentaryItem);
                    
                    // const complimentaryFinish = component.products
                    //     .flatMap((product) => product.finishType)
                    //     .find((finishType) => finishType.isComplimentaryItem)
                    if (complimentaryProduct) {
                        setComplimentaryItemPresent(true)
                        const spaceName = component.categoryId?.name || '';

                        
                        const productNamesInSpace = component.products
                            .flatMap((product) => product)
                            .filter((product) => product.product.isComplimentaryItem)
                            .map((product) => ({
                                name: product.product.name,
                                area: product.area
                            }));                      

                        extractedSpacesAndProducts.push({ spaceName, chApproval: true, productNames: productNamesInSpace});
                    }
                });
                setSpaceAndProductNames(extractedSpacesAndProducts)
            }
        } catch (error) {
            console.error(error)
            // props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    return (
        <div>
            <div style={{ marginBottom: 20, marginTop: 50 }}>
                <Typography variant="h5" component="h2" style={{ textAlign: 'center' }}><strong>Customer Check List</strong></Typography>
            </div>
            <div style={{ margin: 'auto', width: '90%' }}>
                <Paper>
                    <Card className={classes.root} variant="outlined">
                        <CardContent>
                            <Typography variant="h5" component="h2"><strong>Client Master Data</strong></Typography>
                            <hr />
                            <div className='responsiveUserInfo'>
                                <Table striped bordered hover style={{ width: "100%", borderCollapse: "collapse" }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ border: '0.5px solid lightgray', fontWeight: "500" }}> Customer Name</td>
                                            <td style={{ border: '0.5px solid lightgray', fontWeight: "450" }}>{leadDetail?.customerId?.name}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: '0.5px solid lightgray', fontWeight: "500" }}>Property Name</td>
                                            <td style={{ border: '0.5px solid lightgray', fontWeight: "450" }}>{leadDetail?.propertyType} - {leadDetail?.customerId?.address}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: '0.5px solid lightgray', fontWeight: "500" }}>Contact Number</td>
                                            <td style={{ border: '0.5px solid lightgray', fontWeight: "450" }}>{leadDetail?.customerId?.contact_no}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: '0.5px solid lightgray', fontWeight: "500" }}>Email Id</td>
                                            <td style={{ border: '0.5px solid lightgray', fontWeight: "450" }}>{leadDetail?.customerId?.email}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: '0.5px solid lightgray', fontWeight: "500" }}>Sales Executive</td>
                                            <td style={{ border: '0.5px solid lightgray', fontWeight: "450" }}>{salesUserdata}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: '0.5px solid lightgray', fontWeight: "500" }}>Tentative Possession/Registration Date</td>
                                            <td style={{ border: '0.5px solid lightgray', fontWeight: "450" }}>{moment(leadDetail?.possessionDate).format('DD-MM-YYYY')}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: '0.5px solid lightgray', fontWeight: "500" }}>Tentative Design Closure (Contract) Sign Date</td>
                                            <td style={{ border: '0.5px solid lightgray', fontWeight: "450" }}>{moment(leadDetail?.closureDate).format('DD-MM-YYYY')}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: '0.5px solid lightgray', fontWeight: "500" }}>Tentative Client Move-in Date</td>
                                            <td style={{ border: '0.5px solid lightgray', fontWeight: "450" }}>{moment(leadDetail?.clientMoveinDate).format('DD-MM-YYYY')}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </CardContent>
                    </Card>
                </Paper>
                <br />
                <Paper>
                    <Card className={classes.root} variant="outlined">
                        <CardContent>
                            <Typography variant="h5" component="h2"><strong>Quotation Primary Data</strong></Typography>
                            <hr />
                            <Table striped bordered hover style={{ width: "100%", borderCollapse: "collapse" }}>
                                <tbody>
                                    <tr>
                                        <td style={{ border: '0.5px solid lightgray', fontWeight: "500" }}>Tentative Sales Quotation</td>
                                        <TableCell style={{ padding: '5px' }} component="th" scope="row">₹ {(staticSalesWonAmount !== null && staticSalesWonAmount !== undefined && staticSalesWonAmount !== '') ? commaSeparateAmount(staticSalesWonAmount) : commaSeparateAmount(grandTotal)}</TableCell>
                                    </tr>
                                    <tr>
                                        <td style={{ border: '0.5px solid lightgray', fontWeight: "500" }}>Disc agreed</td>
                                        <td style={{ border: '0.5px solid lightgray', fontWeight: "450" }}>{leadDetail?.discountPercent}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: '0.5px solid lightgray', fontWeight: "500" }}>Total Amount Paid</td>
                                        <td style={{ border: '0.5px solid lightgray', fontWeight: "450" }}>₹ {commaSeparateAmount(paymentDone)}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: '0.5px solid lightgray', fontWeight: "500" }}>Uploaded Payment Attachments</td>
                                        {/* <td style={{ border: '0.5px solid lightgray', fontWeight: "450" }}>
                                            {leadDetail?.salesPaymentProofAtachements?.map((file, i) => (
                                                <div style={{ display: 'flex', margin: 10, padding: 2 }}>
                                                    <Typography style={{ width: 50, margin: 3 }}>{file?.amount} </Typography>
                                                    <Typography style={{ marginLeft: 5 }}> -  </Typography>
                                                    <Button color="primary" variant="outlined" onClick={() => window.open(file?.attachment)} style={{ marginLeft: 10 }} disabled={!file?.attachment || file?.attachment === 'NA'}>
                                                        View Payment
                                                    </Button>
                                                </div>
                                            ))
                                            }</td> */}
                                        <td style={{ border: '0.5px solid lightgray', fontWeight: "450" }}>
                                            <Button color="primary" variant="outlined" onClick={() => window.open(leadDetail?.salesPaymentProofAtachement)} style={{ marginLeft: 10 }} disabled={!leadDetail?.salesPaymentProofAtachement || leadDetail?.salesPaymentProofAtachement === 'NA'}> View Payment
                                            </Button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: '0.5px solid lightgray', fontWeight: "500" }}>% Amount Paid</td>
                                        <TableCell style={{ padding: '5px' }} component="th" scope="row">₹ { (staticSalesWonAmount !== null && staticSalesWonAmount !== undefined && staticSalesWonAmount !== '') ? commaSeparateAmount(((paymentDone / staticSalesWonAmount) * 100).toFixed(2)) :commaSeparateAmount(((paymentDone / grandTotal) * 100).toFixed(2))}</TableCell>
                                    </tr>
                                </tbody>
                            </Table>
                        </CardContent>
                    </Card>
                </Paper>

                <br />
                {(leadDetail.salesComplimentaryItems?.length > 0 && (leadDetail.complimentaryItemStatus === "Approved" || leadDetail.complimentaryItemStatus === "Sent for Approval" || leadDetail.complimentaryItemStatus === "Rejected")) &&
                    <>
                    <Paper>
                        <Card className={classes.root} variant='outilned'>
                            <CardContent>
                                <Typography variant='h5' component="h2"><strong>Complimentary Items</strong></Typography>
                                <hr />
                                <TableContainer component={Paper} className={classes.container}>
                                    <Table className={classes.table} striped bordered hover style={{ width: "100%", borderCollapse: "collapse" }} stickyHeader aria-label='sticky table'>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell><strong>Space Name</strong></TableCell>
                                                <TableCell><strong>Complimentary Items</strong></TableCell>
                                                <TableCell><strong>Area(SqFt)/Quantity</strong></TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {leadDetail.salesComplimentaryItems.map((row, i) => {
                                                return (
                                                    <TableRow key={i + 1}>
                                                        <TableCell>{row.spaceName}</TableCell>
                                                        <TableCell>
                                                            {row.productNames.map((product, j) => (
                                                                <div key={j}>
                                                                    <TableCell>{product.name}</TableCell>
                                                                </div>
                                                            ))}
                                                        </TableCell>
                                                        <TableCell>
                                                            {row.productNames.map((product, j) => (
                                                                <div key={j}>
                                                                    <TableCell>{product.area}</TableCell>
                                                                </div>
                                                            ))}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CardContent>

                        </Card>
                    </Paper>
                    </>
                }
                <br />
                <Paper>
                    <Card className={classes.root} variant="outlined">
                        <CardContent>
                            <Typography variant="h5" component="h2"><strong>Quotation Inclusion/Exclusion</strong></Typography>
                            <hr />
                            <div className="scrollTable">
                                <Table striped bordered hover style={{ width: "100%", borderCollapse: "collapse" }} >
                                    <tbody>
                                        <tr ref={inputChecklistFeids} > 
                                            <td style={{ border: '0.5px solid lightgray', fontWeight: "500", width: "5%" }}>Sr. No.</td>
                                            <td style={{ border: '0.5px solid lightgray', fontWeight: "450" }}>Description</td>
                                            <td style={{ border: '0.5px solid lightgray', fontWeight: "450", textAlign: "center" }}>Yes</td>
                                            <td style={{ border: '0.5px solid lightgray', fontWeight: "450", textAlign: "center" }}>No</td>
                                            <td style={{ border: '0.5px solid lightgray', fontWeight: "450", textAlign: "center" }}>NA</td>
                                            <RadioGroup style={{ display: 'table-cell', borderTop: "1px solid lightgray", width: "10px" }} className="resposiveHeading">
                                                <td style={{ border: '1px solid lightgray', borderTop: "1px solid lightgray", fontWeight: "450", textAlign: "center", fontSize: "0.95rem" }}>Accept</td>
                                                <td style={{ border: '1px solid lightgray', borderTop: "1px solid lightgray", fontWeight: "450", textAlign: "center", fontSize: "0.95rem" }}>Reject</td>
                                            </RadioGroup>
                                        </tr>
                                        {checkList.map((row, i) => {
                                            const labelId = `enhanced-table-checkbox-${i}`;
                                            return (
                                                <tr key={i + 1}>
                                                    <td style={{ border: '0.5px solid lightgray', fontWeight: "450" }}>{row?.id}</td>
                                                    <td style={{ border: '0.5px solid lightgray', fontWeight: "450" }}>{row?.description}</td>
                                                    <td style={{ border: '0.5px solid lightgray', fontWeight: "450", textAlign: "center" }}>
                                                        <RadioGroup aria-label={{ 'aria-label': labelId + 1 }}
                                                            name="customized-radios"
                                                            value={row?.actionSelected}
                                                            onChange={(e) => handleRadioButtonClick(e, i, row)} style={{ display: 'block' }}>
                                                            <Radio
                                                                value='yes'
                                                                checked={row?.actionSelected === 'yes'}
                                                                name="radio-button-demo"
                                                                disabled
                                                                inputProps={{ 'aria-label': labelId }}
                                                            />
                                                        </RadioGroup>
                                                    </td>
                                                    <td style={{ border: '0.5px solid lightgray', fontWeight: "450", textAlign: "center" }}>
                                                        <RadioGroup aria-label={{ 'aria-label': labelId + 1 }}
                                                            name="customized-radios"
                                                            value={row?.actionSelected}
                                                            onChange={(e) => handleRadioButtonClick(e, i, row)} style={{ display: 'block' }}>
                                                            <Radio
                                                                value='no'
                                                                checked={row?.actionSelected === 'no'}
                                                                name="radio-button-demo"
                                                                disabled
                                                                inputProps={{ 'aria-label': labelId }}
                                                            />
                                                        </RadioGroup>
                                                    </td>
                                                    <td style={{ border: '0.5px solid lightgray', fontWeight: "450", textAlign: "center" }}>
                                                        <RadioGroup aria-label={{ 'aria-label': labelId + 1 }}
                                                            name="customized-radios"
                                                            value={row?.actionSelected}
                                                            onChange={(e) => handleRadioButtonClick(e, i, row)} style={{ display: 'block' }}>
                                                            <Radio
                                                                value='na'
                                                                checked={row?.actionSelected === 'na'}
                                                                name="radio-button-demo"
                                                                disabled
                                                                inputProps={{ 'aria-label': labelId }}
                                                            />
                                                        </RadioGroup>
                                                    </td>
                                                    {
                                                        isCustomerApproved ?
                                                            <div className="responsiveTable" >
                                                                <RadioGroup aria-label={{ 'aria-label': labelId + 1 }}
                                                                    name="customized-radios-customer"
                                                                    value={row?.customerSelected}
                                                                    style={{ display: 'table-cell' }}>

                                                                    <td style={{ border: '0.5px solid lightgray', fontWeight: "450", textAlign: "center", padding: "10px" }}>
                                                                        <Radio
                                                                            value='yes'
                                                                            checked={row?.customerAction === 'yes'}
                                                                            name="radio-button-demo"
                                                                            disabled
                                                                            inputProps={{ 'aria-label': labelId }}
                                                                        />
                                                                    </td>
                                                                    <td style={{ border: '0.5px solid lightgray', fontWeight: "450", textAlign: "center", padding: "11px" }}>
                                                                        <Radio
                                                                            value='no'
                                                                            checked={row?.customerAction === 'no'}
                                                                            name="radio-button-demo"
                                                                            disabled
                                                                            inputProps={{ 'aria-label': labelId }}
                                                                        />
                                                                    </td>
                                                                </RadioGroup>
                                                            </div>
                                                            :
                                                            <div className="responsiveTable" >
                                                                <RadioGroup aria-label={{ 'aria-label': labelId + 1 }}
                                                                    name="customized-radios"
                                                                    value={row?.customerSelected}
                                                                    onChange={(e) => handleCustomerRadioButtonClick(e, i, row)}
                                                                    style={{ display: 'table-cell' }}
                                                                >
                                                                    <td style={{ border: '0.5px solid lightgray', fontWeight: "450", textAlign: "center", padding: "0.63rem" }}>
                                                                        <Radio
                                                                            value='yes'
                                                                            name="radio-button-demo"
                                                                            inputProps={{ 'aria-label': labelId }}
                                                                        />
                                                                    </td>
                                                                    <td style={{ border: '0.5px solid lightgray', fontWeight: "450", textAlign: "center", padding: "0.44rem" }}>

                                                                        <Radio
                                                                            value='no'
                                                                            name="radio-button-demo"
                                                                            inputProps={{ 'aria-label': labelId }}
                                                                        />
                                                                    </td>
                                                                </RadioGroup>
                                                            </div>}
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        </CardContent>
                    </Card>
                </Paper>
                <br />
                <Paper>
                    <Card className={classes.root} variant="outlined">
                        <CardContent>
                            <Typography variant="h5" component="h2"><strong>Notes</strong></Typography>
                            <hr />
                            <TableContainer component={Paper} style={{ width: '100%' }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableBody>
                                        {
                                            notes.map((data, i) => (
                                                <TableRow key={i + 1}>
                                                    <TableCell style={{ padding: '5px', width: 80, textAlign: 'center' }} component="th" scope="row">{data.id}</TableCell>
                                                    <TableCell style={{ padding: '5px' }} component="th" scope="row">{data.value}</TableCell>

                                                </TableRow>
                                            ))
                                        }
                                        <TableRow key="tc">
                                            <TableCell style={{ padding: '5px', width: 80, textAlign: 'center' }} component="th" scope="row">{notes.length - 2}</TableCell>
                                            <TableCell style={{ padding: '5px' }} component="th" scope="row">
                                                For more terms and conditions, please visit <a href="/terms-and-condition" target="_blank" style={{ color: "blue", textDecoration: 'underline' }}>link</a>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                </Paper>
                <br />
                <Paper>
                    <Card className={classes.root} variant="outlined">
                        <CardContent>
                            <Typography variant="h5" component="h2"><strong>Remarks</strong></Typography>
                            <hr />
                            <Typography variant="p" component="p"><strong>View Sales Remark:</strong> {leadDetail?.checkListRemarkBySalesUser ? leadDetail?.checkListRemarkBySalesUser : 'NA'}</Typography>
                            <FormControl style={{ width: 300, paddingTop: 10 }}>
                                <TextField
                                    id="outlined-multiline-flexible"
                                    label="Enter your remark"
                                    multiline
                                    maxRows={4}
                                    value={checkListRemarkByCustomer}
                                    name="checkListRemarkByCustomer"
                                    onChange={(e) => setCheckListRemarkByCustomer(e.target.value)}
                                    variant="outlined"
                                />
                            </FormControl>
                        </CardContent>
                    </Card>
                </Paper>
                <br />
                {
                    isCustomerApproved ?
                        <Paper style={{ padding: 10 }}>
                            <div style={{ marginTop: '20px', marginBottom: '20px', textAlign: 'center' }}>
                                <p>You have already approved the check list.</p>
                                <p>Thank You!</p>
                            </div>
                        </Paper>
                        :
                        <>
                        { isCenterHeadApproved && !rejectBtDisable ?
                                <Paper style={{ padding: 10 }}>
                                <div style={{ marginTop: '20px', marginBottom: '20px', textAlign: 'center' }}>
                                <Button onClick={() => setRejectCheckListDialog(true)} disabled={rejectBtDisable} variant="outlined" style={{ marginRight: '30px' }}>Reject</Button>  
                                <Button onClick={() => customerApproveRejectStatus("Approve")} variant="contained" color="primary">Submit</Button> 
                            </div>
                            </Paper>
                            :<p style={{display:'flex', justifyContent:'center', color:'red'}}>Lead Rejected!!</p>
                        }
                        </>

                }
                <br />
            </div>

             {/* Creating Dialog Box: when Customer want to reject checklist and click reject button then open this dialog and ask for confirmation */}
             <Dialog
                open={rejectCheckListDialog}
                onClose={() => setRejectCheckListDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Are you sure want to reject the checklist?"}</DialogTitle>
                <DialogContent>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>customerApproveRejectStatus("Reject")} style={{ color: 'Blue' }} autoFocus >
                        Confirm
                    </Button>
                    <Button onClick={() => setRejectCheckListDialog(false)} style={{ color: 'red' }} autoFocus >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openModal} onClose={() => setOpenModal(false)} aria-labelledby="form-dialog-title" maxWidth='lg'>
                <DialogTitle id="form-dialog-title">Verify Details</DialogTitle>
                <Divider />
                <DialogContent>
                    <div >
                        {/* <FormControl style={{ width: "100%", marginBottom: 20, marginTop: 20 }}>
                            <TextField id="outlined-basic" label="Enter Email Id" variant="outlined" name="email" onChange={(e) => setEmail(e.target.value)} size="large" />
                        </FormControl> */}
                        <br />
                        {/* <FormControl style={{ width: "30%", marginBottom: 20 }}>
                            <TextField id="outlined-basic" label="Enter Mobile Number" variant="outlined" name="mobile_no" onChange={(e) => setMobile(e.target.value)} size="small" />
                        </FormControl> */}
                        <WonForm
                            TYPEOPTION='won'
                            LEADOWNERDATA={leadOwnerDetail}
                            sendDataToParent={sendDataToParent}
                        />
                        {
                            otpSend === true &&
                            <Paper style={{ marginTop: 20, padding: 10 }} ref={ref}>
                                <Typography component="p" style={{ marginBottom: 10 }}> Please check your email id for OTP</Typography>
                                <FormControl style={{ width: "30%", marginBottom: 20 }}>
                                    <TextField id="outlined-basic1-otp" label="Enter OTP" variant="outlined" name="otp" onChange={(e) => verifyOTP(e)} size="small" />
                                </FormControl>
                            </Paper>
                        }
                    </div>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={() => setOpenModal(false)} variant="outlined">No</Button>
                    <Button onClick={() => verifyCustomer()} variant="contained" color="primary" disabled={clicked || otpVerified === false}>Verify</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default connect(null, { setLoading, setSnackInfo })(CustomerCheckLists)
