import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { connect, useSelector } from 'react-redux'
import { setLoading, setSnackInfo } from '../redux/actions/appAction';
import HttpService from '../services/httpService';
import { Button, Paper, Divider, TextField, FormControl } from '@material-ui/core';
import {  DownloadOutlined } from '@ant-design/icons';

import moment from 'moment'
import { ROLENAME, rolesId } from '../utils';

const ValidateContractsDocuments = (props) => {
    const history=useHistory();
    const { leadId } = useParams();
    const { userDetails } = useSelector(state => state.user);
    useEffect(() => {
        getLeadDetails();
        // eslint-disable-next-line
    }, [])

    const [leadDetail, setLeadDetails] = useState([]);
    const [isRejected, setIsRejected] = useState(false);
    const [contractRejectReason, setContractRejectReason] = useState('')

    const getLeadDetails = async () => {
        props.setLoading(true)
        try {
            const response = await HttpService.getLeadDetails(leadId);
            setLeadDetails(response.data)
        } catch (error) {
            console.log(error, 'error')
            props.setSnackInfo(error?.response ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const [selectedRole, setSelectedRole] = useState('')
    const updateRejectStatus = (roleSelected) => {
        setIsRejected(true)
        setSelectedRole(roleSelected)
    }

    // Reject Final Action Function
    const rejectContractSignedAction = async () => {
        let obj = {}
        obj.scanQuotationFile = "";
        obj.quotationCsvFile = "";
        obj.contractFinanceApproved = false;
        obj.contractDesignManagerApproved = false;
        obj.contractFinalMarkingApproved = false;
        obj.contractQualityControlApproved = false;
        obj.contractQCManagerApproved = false;
        obj.contractDesignApproved = false;
        obj.contractOperationApproved = false;
        obj.contractCustomerApproved = false; 
        obj.designToExecutionLogsStatus = 'Sent For Approval'
        obj.contractRejectReason = contractRejectReason;
        if (selectedRole === 'isDesignHeadApproved') {
            obj.contactLeadRejectedRole = ROLENAME.DesignHead;
        } else if (selectedRole === 'isQCManagerApproved') {
            obj.contactLeadRejectedRole = ROLENAME.QCManager;
        } else if (selectedRole === 'isFinanceManagerApproved') {
            obj.contactLeadRejectedRole = ROLENAME.FinanceManager;
        } else if (selectedRole === 'isDesignManagerApproved') {
            obj.contactLeadRejectedRole = ROLENAME.DesignManager;
        } else if (selectedRole === 'isFinalMarkingApproved') {
            obj.contactLeadRejectedRole = ROLENAME.FinalMarking;
        } else if (selectedRole === 'isQualityControlApproved') {
            obj.contactLeadRejectedRole = ROLENAME.QualityControl;
        } else if (selectedRole === 'isOperationApproved') {
            obj.contactLeadRejectedRole = ROLENAME.SeniorOperationManager;
        }else if(selectedRole === 'Customer'){
            obj.contactLeadRejectedRole = ROLENAME.Customer;
        }
        // console.log(obj, "reject obj");
        props.setLoading(true)
        try {
            const response = await HttpService.updateDesignToFinanceLeadRejectStatus(leadDetail._id, obj);
            props.setSnackInfo(response?.data ?? "Updated Successfully", "success")
        } catch (error) {
            props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
        }
        setIsRejected(false);
        getLeadDetails();
        props.setLoading(false)
    }

    // Approve Final Action Function
    const approveContractSignedAction = async (roleSelected) => {
        setIsRejected(false)
        // console.log(roleSelected, 'roleSelected', leadDetail)
        let obj = {}
        // if (roleSelected === 'isDesignHeadApproved') {
        //     obj.contractFinanceApproved = leadDetail.contractFinanceApproved;
        //     obj.contractDesignManagerApproved = leadDetail.contractDesignManagerApproved;
        //     obj.contractFinalMarkingApproved = leadDetail.contractFinalMarkingApproved;
        //     obj.contractQualityControlApproved = leadDetail.contractQualityControlApproved;
        //     obj.contractDesignApproved = true;
        //     obj.contractOperationApproved = leadDetail.contractOperationApproved;
        //     obj.designToExecutionLogsStatus = (
        //         leadDetail.contractFinanceApproved &&
        //         leadDetail.contractDesignManagerApproved &&
        //         leadDetail.contractFinalMarkingApproved &&
        //         leadDetail.contractQualityControlApproved &&
        //         leadDetail.contractOperationApproved
        //     ) ? `Approved` : `Sent For Approval`;
        // } else 
        if (roleSelected === 'isFinanceManagerApproved') {
                uploadProductDocument();
        } else if (roleSelected === 'isDesignManagerApproved') {
            obj.contractFinanceApproved = leadDetail.contractFinanceApproved || false;
            obj.contractDesignApproved = leadDetail.contractDesignApproved || false;
            obj.contractDesignManagerApproved = true;
            obj.contractFinalMarkingApproved = leadDetail.contractFinalMarkingApproved || false;
            obj.contractQualityControlApproved = leadDetail.contractQualityControlApproved || false;
            obj.contractQCManagerApproved = leadDetail.contractQCManagerApproved || false;
            obj.contractOperationApproved = leadDetail.contractOperationApproved || false;
            history.push(`/lead-design-to-execution/${moment().subtract(1, 'months').format('YYYY-MM-DD')}/${moment().add(1, 'days').format('YYYY-MM-DD')}`);
            obj.designToExecutionLogsStatus = (
                //leadDetail.contractDesignApproved &&
                leadDetail.contractDesignManagerApproved &&
                //leadDetail.contractFinalMarkingApproved &&
                leadDetail.contractQCManagerApproved &&
                leadDetail.contractQualityControlApproved
                // &&leadDetail.contractOperationApproved
            ) ? `Approved` : `Sent For Approval`;
        // } else if (roleSelected === 'isFinalMarkingApproved') {
            // obj.contractFinanceApproved = leadDetail.contractFinanceApproved;
            // obj.contractDesignApproved = leadDetail.contractDesignApproved;
            // obj.contractDesignManagerApproved = leadDetail.contractDesignManagerApproved;
            // obj.contractFinalMarkingApproved = true;
            // obj.contractQualityControlApproved = leadDetail.contractQualityControlApproved;
            // obj.contractOperationApproved = leadDetail.contractOperationApproved;
            // obj.designToExecutionLogsStatus = (
            //     leadDetail.contractDesignApproved &&
            //     leadDetail.contractFinanceApproved &&
            //     leadDetail.contractDesignManagerApproved &&
            //     leadDetail.contractQualityControlApproved &&
            //     leadDetail.contractOperationApproved
            // ) ? `Approved` : `Sent For Approval`;
        } else if (roleSelected === 'isQualityControlApproved') {
            obj.contractFinanceApproved = leadDetail.contractFinanceApproved;
            obj.contractDesignApproved = leadDetail.contractDesignApproved;
            obj.contractQCManagerApproved = leadDetail.contractQCManagerApproved;
            obj.contractDesignManagerApproved = leadDetail.contractDesignManagerApproved;
            obj.contractFinalMarkingApproved = leadDetail.contractFinalMarkingApproved;
            obj.contractOperationApproved = leadDetail.contractOperationApproved;
            obj.contractQualityControlApproved = true;
            history.push(`/lead-design-to-execution/${moment().subtract(1, 'months').format('YYYY-MM-DD')}/${moment().add(1, 'days').format('YYYY-MM-DD')}`);

            obj.designToExecutionLogsStatus = (
                //leadDetail.contractDesignApproved &&
                leadDetail.contractDesignManagerApproved &&
                //leadDetail.contractFinalMarkingApproved &&
                leadDetail.contractQCManagerApproved &&
                leadDetail.contractQualityControlApproved
                // &&leadDetail.contractOperationApproved
            ) ? `Approved` : `Sent For Approval`;
        // } else if (roleSelected === 'isOperationApproved') {
        //     obj.contractFinanceApproved = leadDetail.contractFinanceApproved;
        //     obj.contractDesignApproved = leadDetail.contractDesignApproved;
        //     obj.contractDesignManagerApproved = leadDetail.contractDesignManagerApproved;
        //     obj.contractFinalMarkingApproved = leadDetail.contractFinalMarkingApproved;
        //     obj.contractQualityControlApproved = leadDetail.contractQualityControlApproved;
        //     obj.contractOperationApproved = true;
        //     obj.designToExecutionLogsStatus = (
        //         leadDetail.contractDesignApproved &&
        //         leadDetail.contractFinanceApproved &&
        //         leadDetail.contractFinalMarkingApproved &&
        //         leadDetail.contractDesignManagerApproved &&
        //         leadDetail.contractQualityControlApproved
        //     ) ? `Approved` : `Sent For Approval`;
        } else if (roleSelected === 'isQCManagerApproved') {
           obj.contractFinanceApproved = leadDetail.contractFinanceApproved;
           obj.contractDesignApproved = leadDetail.contractDesignApproved;
           obj.contractDesignManagerApproved = leadDetail.contractDesignManagerApproved;
           obj.contractFinalMarkingApproved = leadDetail.contractFinalMarkingApproved;
           obj.contractOperationApproved = leadDetail.contractOperationApproved;
           obj.contractQualityControlApproved = leadDetail.contractQualityControlApproved;
           obj.contractQCManagerApproved = true;
           history.push(`/lead-design-to-execution/${moment().subtract(1, 'months').format('YYYY-MM-DD')}/${moment().add(1, 'days').format('YYYY-MM-DD')}`);
           obj.designToExecutionLogsStatus = (
               //leadDetail.contractDesignApproved &&
               leadDetail.contractDesignManagerApproved &&
               leadDetail.contractQCManagerApproved &&
               leadDetail.contractQualityControlApproved
               // &&leadDetail.contractOperationApproved
           ) ? `Approved` : `Sent For Approval`;
        }
        // console.log(obj, "Obj")
        if (roleSelected !== 'isFinanceManagerApproved') {
        props.setLoading(true)
        try {
            const response = await HttpService.updateDesignToFinanceLeadApprovalStatus(leadDetail._id, obj);
            props.setSnackInfo(response?.data ?? "Updated Successfully", "success")
        } catch (error) {
            props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }
    }


    const uploadProductDocument = async () => {
        try {
            props.setLoading(true)
            let divisionOfProject = ""
            if (leadDetail?.grandTotal < 1000000) {
                divisionOfProject = '<10L'
            } else if (leadDetail?.grandTotal > 2500000) {
                divisionOfProject = '25L+'
            } else {
                divisionOfProject = "10-25L"
            }
            let obj = {}

            obj.code = leadDetail.erpProjectNo
            obj.clientName = leadDetail.customerId.name
            obj.apartmentName = leadDetail.customerId.address
            obj.clientAddress = leadDetail.customerId.address
            obj.contactNumber = leadDetail.customerId.contact_no
            obj.status = "Site QC"
            obj.locationId = leadDetail.city
            obj.experienceCenterId = leadDetail.experienceCenterId
            obj.leadId = leadDetail._id
            obj.stage = leadDetail.currentStage
            obj.financeStage = leadDetail.contractFinanceApproved
            obj.divisionOfProject = divisionOfProject
            obj.existingProject = false
            obj.documentFiles = [
                {
                    documentType: 'CheckList',
                    documentLink: leadDetail?.scanCheckListFile,
                    status: 'InSiteQC'
                },
                {
                    documentType: 'ContractSignaturePDF',
                    documentLink: leadDetail?.termsAndConditionsPdf,
                    status: 'InSiteQC'
                },
                {
                    documentType: 'WorkingDrawingPDF',
                    documentLink: leadDetail?.workingDrawingFile,
                    status: 'InSiteQC'
                }
            ]

            const result = await HttpService.getProductDocAndCreateProject(obj)
            if (result.status === 200) {
                sendToImosAndSiteBOM();
            }
        } catch (error) {
            props.setLoading(false)
            console.log(error)
            props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
        }
    }

    const  handleDownloadClick = (event, attachmentUrl)=> {
        window.open(attachmentUrl);
      }

      
    const sendToImosAndSiteBOM = async () =>{
        props.setLoading(true)
        try {
            await HttpService.uploadAndSendToImosAndSiteBOM(leadDetail._id);
            props.setSnackInfo("Project Created Successfully", "success")
            history.push(`/lead-design-to-execution/${moment().subtract(1, 'months').format('YYYY-MM-DD')}/${moment().add(1, 'days').format('YYYY-MM-DD')}`);
        } catch (error) {
            props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    return (
        <div>
            <div style={{ marginBottom: 20 }}>
                <h2 style={{ textAlign: 'center' }}><strong>Validate Documents</strong></h2>
            </div>
            <Divider />

            <div style={{ margin: '20px auto', width: '80%', lineHeight: '30px'}}>
                <Paper>
                    <div style={{ paddingTop: 20, paddingLeft: 20, paddingBottom: 10 }}>
                        <h4><strong>Name:</strong> {leadDetail?.customerId?.name}</h4>
                        <h4><strong>Contact:</strong> {leadDetail?.customerId?.contact_no}</h4>
                        <h4><strong>Email:</strong> {leadDetail?.customerId?.email}</h4>
                        <h4><strong>Address:</strong> {leadDetail?.customerId?.address}</h4>
                        <h4><strong>Lead Number:</strong> {leadDetail?.lead_no}</h4>
                        <h4><strong>Lead Owner:</strong> {leadDetail?.assignTo?.name}</h4>
                    </div>
                    <hr />
                    <ul style={{ paddingTop: 10, marginLeft: '40px', lineHeight: '20px'}}>
                    { leadDetail.currentStage === "Design Sign-off HOLD" && <li style={{ paddingTop: 6, paddingBottom: 8 }}><strong>Design Sign-off HOLD Revoke Date</strong> {moment(leadDetail?.designSignoffRevokeDate).format('DD-MM-YYYY')}</li>}
                        <li style={{ paddingTop: 6, paddingBottom: 8 }}><strong>Expected Project Completion Date:</strong> {moment(leadDetail?.projectCompletionDate).format('DD-MM-YYYY')}</li>
                        <li style={{ paddingTop: 6, paddingBottom: 8 }}><strong>Days from Contract sign:</strong> {leadDetail?.daysAsPerContractSign}</li>
                        {leadDetail?.scanQuotationFile ?
                        <li><strong>Quotation:</strong> <Button color="primary" onClick={() => window.open(leadDetail?.scanQuotationFile)} style={{ textTransform: 'capitalize', textDecoration: 'underline' }}>View File</Button></li>
                        : <li><strong>Quotation:</strong> <Button style={{ textTransform: 'capitalize'}}>Not Available</Button></li> }
                        {leadDetail?.scanCheckListFile ?
                            <li><strong>Check List:</strong> <Button color="primary" onClick={() => window.open(leadDetail?.scanCheckListFile)} style={{ textTransform: 'capitalize', textDecoration: 'underline' }}>View File</Button></li>
                        : <li><strong>Check List:</strong> <Button style={{ textTransform: 'capitalize' }}>Not Available</Button></li>}
                        {leadDetail?.scanProjectKickOffCheckListFile ?
                            <li><strong>Project Kick Off Check List:</strong> <Button color="primary" onClick={() => window.open(leadDetail?.scanProjectKickOffCheckListFile)} style={{ textTransform: 'capitalize', textDecoration: 'underline' }}>View File</Button></li>
                            : <li><strong>Project Kick Off Check List:</strong> <Button style={{ textTransform: 'capitalize' }}>Not Available</Button></li>}
                        {leadDetail?.scanSiteQCCheckListFile ?
                            <li><strong>SiteQC Check List:</strong> <Button color="primary" onClick={() => window.open(leadDetail?.scanSiteQCCheckListFile)} style={{ textTransform: 'capitalize', textDecoration: 'underline' }}>View File</Button></li>
                            : <li><strong>SiteQC Check List:</strong> <Button style={{ textTransform: 'capitalize' }}>Not Available</Button></li>}
                        {leadDetail?.workingDrawingFile ?
                            <li><strong>Working Drawing:</strong> <Button color="primary" onClick={() => window.open(leadDetail?.workingDrawingFile)} style={{ textTransform: 'capitalize', textDecoration: 'underline' }}>View File</Button></li>
                            : <li><strong>Working Drawing:</strong> <Button style={{ textTransform: 'capitalize' }}>Not Available</Button></li>}
                        {leadDetail?.ThreeDworkingDrawingFile ?
                            <li><strong>3D Working Drawing:</strong> <Button color="primary" onClick={() => window.open(leadDetail?.ThreeDworkingDrawingFile)} style={{ textTransform: 'capitalize', textDecoration: 'underline' }}>View File</Button></li>
                            : <li><strong>3D Working Drawing:</strong> <Button style={{ textTransform: 'capitalize' }}>Not Available</Button></li>}
                        {leadDetail?.termsAndConditionsPdf ?
                            <li><strong>Contract Signed: </strong> <Button color="primary" onClick={() => window.open(leadDetail?.termsAndConditionsPdf)} style={{ textTransform: 'capitalize', textDecoration: 'underline' }}>View File</Button></li>
                            : <li><strong>Contract Signed: </strong> <Button style={{ textTransform: 'capitalize' }}>Not Available</Button></li>}
                        {leadDetail?.quotationCsvFile ?
                            <li><strong>Quotation Csv :</strong> <Button color="primary" onClick={() => window.open(leadDetail?.quotationCsvFile)} style={{ textTransform: 'capitalize', textDecoration: 'underline' }}>View File</Button></li>
                            : <li><strong>Quotation Csv :</strong> <Button style={{ textTransform: 'capitalize' }}>Not Available</Button></li>}
                        <li style={{ paddingTop: 6, paddingBottom: 8 }}><strong>Total Amount: </strong> {leadDetail?.grandTotal}</li>
                        <li style={{ paddingTop: 6, paddingBottom: 8 }}><strong>Payment Done: </strong> {leadDetail?.contractSignedValue}</li>
                        <li style={{ paddingTop: 6, paddingBottom: 8 }}><strong>Payment Percentage: </strong> {((leadDetail?.contractSignedValue / leadDetail?.grandTotal) * 100).toFixed(2)} %</li>
                        {leadDetail?.contractSignedPaymentReceviedAttachemnts ? (
                            <>
                                <li style={{ paddingTop: 6, paddingBottom: 8 }}>
                                    <strong>Payment Attachment: </strong>
                                    {leadDetail?.contractSignedPaymentReceviedAttachemnts?.map((el, index) => (
                                        <span key={index}>
                                            <DownloadOutlined onClick={(e) => handleDownloadClick(e, el)} />
                                            {index < leadDetail.contractSignedPaymentReceviedAttachemnts?.length - 1 ? ', ' : ''}
                                        </span>
                                    ))}
                                </li>
                            </>
                        ) : (
                            <li>
                                <strong>Payment Attachment: </strong>
                                <Button style={{ textTransform: 'capitalize' }}>Not Available</Button>
                            </li>
                        )}

                        {leadDetail?.siteQcVideo ? (
                            <>
                            <li style={{ paddingTop: 6, paddingBottom: 8 }}>
                            <strong>SiteQC Video: </strong> 
                            {leadDetail?.siteQcVideo?.map((el, index) => (
                                <span key={index}>
                                    <DownloadOutlined  onClick={(e) => handleDownloadClick(e,el)} />
                                {index < leadDetail.siteQcVideo?.length - 1 ? ', ' : ''}
                                </span>
                            ))}
                            </li>
                            </>
                            ) : (
                            <li>
                                <strong>SiteQC Video : </strong>
                                <Button style={{ textTransform: 'capitalize' }}>Not Available</Button>
                            </li>
                            )}


                        {leadDetail?.scanSiteQCPhotos ? (
                            <>
                                <li style={{ paddingTop: 6, paddingBottom: 8 }}>
                                    <strong>SiteQC Photos: </strong>
                                    {leadDetail?.scanSiteQCPhotos?.map((el, index) => (
                                        <span key={index}>
                                            <DownloadOutlined onClick={(e) => handleDownloadClick(e, el)} />
                                            {index < leadDetail.scanSiteQCPhotos?.length - 1 ? ', ' : ''}
                                        </span>
                                    ))}
                                </li>
                            </>
                        ) : (
                            <li>
                                <strong>SiteQC Photos : </strong>
                                <Button style={{ textTransform: 'capitalize' }}>Not Available</Button>
                            </li>
                        )}
                            </ul>

                    <div style={{ marginTop: 20, marginBottom: 20 }}>
                    {(userDetails.roles.find(role => role.name === ROLENAME.QCManager)) &&
                            <>
                                {
                                    leadDetail?.contractQualityControlApproved ?
                                        <>
                                            <Button color="primary" variant="contained" onClick={() => approveContractSignedAction('isQCManagerApproved')} style={{ marginRight: 10, marginLeft: 30 }}
                                                disabled={leadDetail?.contractQCManagerApproved || isRejected}
                                            >
                                                Approve
                                            </Button>
                                            <Button color="secondary" variant="outlined" onClick={() => updateRejectStatus('isQCManagerApproved')}
                                                disabled={leadDetail?.contractQCManagerApproved}
                                            >
                                                Reject
                                            </Button>
                                        </>
                                        :
                                        <Button color="secondary" variant="outlined" disabled style={{ marginRight: 10, marginLeft: 30 }}>Please wait for Quality Control approval</Button>
                                }
                            </>
                        }
                        {userDetails.roles.find(role => role.name === ROLENAME.QualityControl) &&
                            <>
                               {
                                 leadDetail?.contactLeadRejectedRole === undefined  ||  leadDetail?.contactLeadRejectedRole === null? 
                                 <>
                                <Button color="primary" variant="contained" onClick={() => approveContractSignedAction('isQualityControlApproved')} style={{ marginRight: 10, marginLeft: 30 }}
                                disabled={leadDetail?.contractQualityControlApproved || isRejected}
                                >
                                    Approve
                                </Button>
                                <Button color="secondary" variant="outlined" onClick={() => updateRejectStatus('isQualityControlApproved')}
                                    disabled={leadDetail?.contractQualityControlApproved}
                                >
                                    Reject
                                </Button>
                                </> :
                                <Button color="secondary" variant="outlined" disabled style={{ marginRight: 10, marginLeft: 30 }}>Please wait for Design Sign Off</Button>
                            }
                            </>
                        }
                        {/* {userDetails.roles.find(role => role.name === ROLENAME.QualityControl) &&
                            <>
                                {
                                    leadDetail?.contractFinalMarkingApproved ?
                                        <>
                                            <Button color="primary" variant="contained" onClick={() => approveContractSignedAction('isQualityControlApproved')} style={{ marginRight: 10, marginLeft: 30 }}
                                                disabled={leadDetail?.contractQualityControlApproved || isRejected}
                                            >
                                                Approve
                                            </Button>
                                            <Button color="secondary" variant="outlined" onClick={() => updateRejectStatus('isQualityControlApproved')}
                                                disabled={leadDetail?.contractQualityControlApproved}
                                            >
                                                Reject
                                            </Button>
                                        </>
                                        :
                                        <Button color="secondary" variant="outlined" disabled style={{ marginRight: 10, marginLeft: 30 }}>Please wait for Final Marking approval</Button>
                                }
                            </>
                        } */}
                        {userDetails.roles.find(role => role.name === ROLENAME.FinanceManager || role.name === ROLENAME.FinanceExecutive) && (
                            <>
                                {
                                    leadDetail?.contractDesignManagerApproved && leadDetail?.contractQualityControlApproved && leadDetail?.contractQCManagerApproved && leadDetail?.contractCustomerApproved ?
                                        <>
                                            <Button color="primary" variant="contained" onClick={() => approveContractSignedAction('isFinanceManagerApproved')} style={{ marginRight: 10, marginLeft: 30 }}
                                                disabled={leadDetail?.contractFinanceApproved || isRejected || !leadDetail?.erpProjectNo}
                                            >
                                                Approve
                                            </Button>
                                            <Button color="secondary" variant="outlined" onClick={() => updateRejectStatus('isFinanceManagerApproved')}
                                                disabled={leadDetail?.contractFinanceApproved }
                                            >
                                                Reject
                                            </Button>
                                        </>
                                        :
                                        <Button color="secondary" variant="outlined" disabled style={{ marginRight: 10, marginLeft: 30 }}>Please wait for Customer approval</Button>
                                }
                            </>
                        )}
                        {(userDetails.roles.find(role => role.name === ROLENAME.DGM_Design) || userDetails.roles.find(role => role.name === ROLENAME.AssistantDesignManager)) &&
                            <>
                                {
                                leadDetail?.contractQualityControlApproved && leadDetail?.contractQCManagerApproved ?
                                    <>
                                        <Button color="primary" variant="contained" onClick={() => approveContractSignedAction('isDesignManagerApproved')} style={{ marginRight: 10, marginLeft: 30 }}
                                                disabled={leadDetail?.contractDesignManagerApproved || isRejected}
                                            >
                                                Approve
                                            </Button>
                                            <Button color="secondary" variant="outlined" onClick={() => updateRejectStatus('isDesignManagerApproved')}
                                                disabled={leadDetail?.contractDesignManagerApproved}
                                            >
                                                Reject
                                            </Button>
                                        </>
                                        :
                                    <Button color="secondary" variant="outlined" disabled style={{ marginRight: 10, marginLeft: 30 }}>Please wait for QC Manager approval</Button>
                                }
                            </>
                        }
                        {/* {(userDetails.roles.length === 2 && userDetails.roles.find(role => role.name === ROLENAME.DesignHead)) &&
                            <>
                                {
                                leadDetail?.contractFinanceApproved && leadDetail?.contractDesignManagerApproved && leadDetail?.contractFinalMarkingApproved && leadDetail?.contractQualityControlApproved ?
                                        <>
                                            <Button color="primary" variant="contained" onClick={() => approveContractSignedAction('isDesignHeadApproved')} style={{ marginRight: 10, marginLeft: 30 }}
                                                disabled={leadDetail?.contractDesignApproved || isRejected}
                                            >
                                                Approve
                                            </Button>
                                            <Button color="secondary" variant="outlined" onClick={() => updateRejectStatus('isDesignHeadApproved')}
                                                disabled={leadDetail?.contractDesignApproved}
                                            >
                                                Reject
                                            </Button>
                                        </>
                                        :
                                        <Button color="secondary" variant="outlined" disabled style={{ marginRight: 10, marginLeft: 30 }}>Please wait for ADM / DM approval</Button>
                                }
                            </>
                        } */}
                        {/* {userDetails.roles.find(role => role.name === ROLENAME.SeniorOperationManager) &&
                            <>
                                {leadDetail?.contractFinanceApproved && leadDetail?.contractDesignApproved &&
                                    leadDetail?.contractDesignManagerApproved && leadDetail?.contractFinalMarkingApproved && leadDetail?.contractQualityControlApproved ?
                                    <>
                                        <Button color="primary" variant="contained" onClick={() => approveContractSignedAction('isOperationApproved')} style={{ marginRight: 10, marginLeft: 30 }}
                                            disabled={leadDetail?.contractOperationApproved || isRejected}
                                        >
                                            Approve
                                        </Button>
                                        <Button color="secondary" variant="outlined" onClick={() => updateRejectStatus('isOperationApproved')}
                                            disabled={leadDetail?.contractOperationApproved}
                                        >
                                            Reject
                                        </Button>
                                    </>
                                    :
                                    <Button color="secondary" variant="outlined" disabled style={{ marginRight: 10, marginLeft: 30 }}>Please wait for DesignHead approval</Button>
                                }
                            </>
                        } */}
                    </div>
                    <br />
                </Paper>
                <br />
                {isRejected &&
                    <Paper style={{ padding: 10 }}>
                        <FormControl style={{ width: 300, marginBottom: 10 }}>
                            <TextField
                                id="outlined-multiline-flexible"
                                label="Enter Reject Reason"
                                multiline
                                maxRows={4}
                                name="contractRejectReason"
                                onChange={(e) => setContractRejectReason(e.target.value)}
                                variant="outlined"
                            />
                        </FormControl>
                        <br />
                        <Button onClick={() => rejectContractSignedAction()} variant="contained" color="primary" style={{ marginRight: 10 }}>Update Lead</Button>
                        <Button onClick={() => setIsRejected(false)} variant="contained" color="secondary">Cancel</Button>
                    </Paper>
                }
            </div>
        </div>
    )
}

export default connect(null, { setLoading, setSnackInfo })(ValidateContractsDocuments)
