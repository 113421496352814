import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux'
import { useParams, useHistory, Link } from "react-router-dom";
import { setLoading, setSnackInfo } from '../redux/actions/appAction';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { ROLENAME, saleLostApprovalHeader, designLostApprovalHeader } from '../utils';
import moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import { Modal } from 'antd';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import HttpService from '../services/httpService';
import { commaSeparateAmount } from './commaSeparate';

const useStyles = makeStyles((theme) => ({
    table: {
        width: '80vw',
        textAlign: 'center'
    },
    root: {
        width: '100%',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
}));


const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#ccc",
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

function LostLeadApprovalView(props) {
    const classes = useStyles()
    const { userDetails } = useSelector(state => state.user);
    const history = useHistory();
    const [lostApprovalLeads, setLostApprovalLeads] = useState([]);
    const [remarks, setRemarks] = useState('');
    const [openDialogapprove, setOpenDialogapprove] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [rowLeadId, setRowLeadId] = useState('');

    useEffect(() => {
        getAllApprovedLeads();
    }, [])

    const getAllApprovedLeads = async () => {
        props.setLoading(true)
        try {
            const response = await HttpService.getLostLeadRequests()
            if (response.data == "No leads found.") {
                setLostApprovalLeads([])
            } else {
                arrayUpdateFunction(response.data)
            }
        } catch (error) {
            props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    //  Show Data according experience and role
    const arrayUpdateFunction = (masterArray) => {
        const newArr = masterArray.filter((row) => {
            return userDetails?.experienceCenterId?.includes(row.experienceCenterId);
        });
    
        setLostApprovalLeads(newArr);
    };
    
    const handleApprove = async (v) => {
        props.setLoading(true);
        setRowLeadId(v?._id);
        try {
            setOpenDialogapprove(true);
        } catch (error) {
            console.log("error", error);
        }
        props.setLoading(false);
    }
    const handleReject = async (v) => {
        setRowLeadId(v?._id);
        setOpenDialog(true);
    }

// Handle Approve Button of Lost
    const handleApproveWithRemarks = async (row, status) => {
        // let projectId = rowLeadId
        props.setLoading(true);
        try {
            let obj = {
                projectId : rowLeadId,
                status : status,
                remarks : remarks,
            }
            // const status = "approved";
            const response = await HttpService.approveLostLead(obj);
            props.setSnackInfo("Lost Approval Approved","success");
            setOpenDialogapprove(false);
            getAllApprovedLeads();
        } catch (error) {
            console.log("error", error);
        }
        props.setLoading(false);
    }

// Handle reject Button of Lost
    const handleRejectWithRemarks = async (row, status) => {
        // let projectId = rowLeadId
        props.setLoading(true);
        try {
            const obj = {
                projectId: rowLeadId,
                status: status,
                remarks: remarks
            }
            // const status = "rejected";
            const response = await HttpService.approveLostLead(obj);
            props.setSnackInfo("Lost Request Rejected","success");
            setOpenDialog(false);
            getAllApprovedLeads();
        } catch (error) {
            console.log("error", error);
        }
        props.setLoading(false);
    }

    const handleCloseDialog = () => {
        setOpenDialog(false);
    }
    const handleCloseDialogApprove = () => {
        setOpenDialogapprove(false);
    }

    const handleRemarksChange = (event) => {
        setRemarks(event.target.value);
    }

    return (
        <div>
            <div style={{ marginBottom: 20 }}>
                <h3 style={{ textAlign: 'center' }}>
                    {/* For Shomil role = Center Head */}
                    {userDetails.roles.find(role => role.name === ROLENAME.CenterHead || role.name === ROLENAME.SalesManager || role.name === ROLENAME.DGM_Sales) && saleLostApprovalHeader} 
                    {/* For DK role for Admin */}
                    {userDetails.roles.find(role => role.name === ROLENAME.Admin || role.name === ROLENAME.OperationDesignManager || role.name === ROLENAME.DGM_Design) && designLostApprovalHeader}
                </h3>
            </div>
            <Divider />
            <Paper>
                <TableContainer component={Paper}>
                    <Table className={classes.table} stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow style={{ backgroundColor: '#333', color: '#fff' }}>
                                <TableCell>Sl. No</TableCell>
                                <TableCell>Customer Name</TableCell>
                                <TableCell>Project No.</TableCell>
                                <TableCell>Lead No.</TableCell>
                                {userDetails.roles.find(role => role.name !== ROLENAME.Admin) && (
                                    <>
                                        <TableCell>CHM Design Kick-off Rejection Remarks</TableCell>
                                    </>
                                )}
                                <TableCell>Lost Reason</TableCell>
                                {/* <TableCell>Lost Request User</TableCell> */}
                                <TableCell>Assigned To</TableCell>
                                <TableCell>Lead Value</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {lostApprovalLeads?.length > 0 ?
                                lostApprovalLeads.sort((a, b) => b.createdAt.split('-').reverse().join().localeCompare(a.createdAt.split('-').reverse().join())).map((row, i) => {
                                    return (
                                        <TableRow key={i + 1}>
                                            <TableCell>{i + 1}</TableCell>
                                            <TableCell component="th" scope="row"> {row?.customerId?.name} </TableCell>
                                            <TableCell component="th" scope="row"> {row?.erpProjectNo} </TableCell>
                                            <TableCell component="th">
                                                <Link to={`/project-logs/${row?._id}`} style={{ color: "#000", textDecoration: 'underline' }}>{row?.lead_no || "N/A"}</Link>
                                            </TableCell>
                                            {/* <TableCell component="th">{moment(row?.createdAt).format('DD-MM-YYYY')}</TableCell> */}
                                            {userDetails.roles.find(role => role.name !== ROLENAME.Admin) && (
                                                <>
                                                    <TableCell component="th">{row?.chmReasonForDesignKickOffRejection || 'NA'}</TableCell>
                                                </>
                                            )}

                                            <TableCell component="th">{row?.reasonForLost}</TableCell>
                                            {/* <TableCell component="th">{row?.lostRaiseUser ? row?.lostRaiseUser : 'NA' }</TableCell> */}
                                            <TableCell component="th">{row?.assignTo ? row?.assignTo?.name : 'NA'}</TableCell>
                                            <TableCell component="th">₹ {commaSeparateAmount(row?.grandTotal) || 'NA'}</TableCell>
                                            {/* Lost Approval Approved dialog */}
                                            <TableCell>
                                                <Button variant="contained" color="primary" style={{ marginRight: '10px' , marginBottom: '3px'}} onClick={() => handleApprove(row)}>Approve</Button>
                                                <Dialog open={openDialogapprove} onClose={handleCloseDialog}>
                                                    <DialogTitle>Enter Remarks</DialogTitle>
                                                    <DialogContent>
                                                        <TextField
                                                            autoFocus
                                                            margin="dense"
                                                            id="remarks"
                                                            label="Remarks"
                                                            type="text"
                                                            fullWidth
                                                            style={{width:400}}
                                                            value={remarks}
                                                            onChange={handleRemarksChange}
                                                        />
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button onClick={() => handleCloseDialogApprove(row)} color="primary" variant="outlined">
                                                            Cancel
                                                        </Button>
                                                        <Button onClick={() => handleApproveWithRemarks(row, 'Approved')}  color="primary" variant='outlined'>
                                                            Approve
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>
                                            {/* Lost Reject Dialogs */}
                                            <Button variant="contained" color="secondary" onClick={() => handleReject(row)}>Reject</Button>
                                            </TableCell>
                                            <Dialog open={openDialog} onClose={handleCloseDialog}>
                                                <DialogTitle>Enter Remarks</DialogTitle>
                                                <DialogContent>
                                                    <TextField
                                                        autoFocus
                                                        margin="dense"
                                                        id="remarks"
                                                        label="Remarks"
                                                        type="text"
                                                        fullWidth
                                                        style={{width:400}}
                                                        value={remarks}
                                                        onChange={handleRemarksChange}
                                                    />
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button onClick={() => handleCloseDialog(row)} color="primary" variant="outlined">
                                                        Cancel
                                                    </Button>
                                                    <Button onClick={(e) => handleRejectWithRemarks(row, 'Rejected')} color="primary" variant="outlined">
                                                        Reject
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>
                                        </TableRow>
                                    );
                                })
                                :
                                <TableRow>
                                    <TableCell colSpan={9} style={{ textAlign: 'center' }}>No Records Found</TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    )
}

export default connect(null, { setLoading, setSnackInfo })(LostLeadApprovalView)