import React from 'react';
import { connect } from 'react-redux'
import { setLoading, setSnackInfo } from '../redux/actions/appAction';
import { Paper, Typography } from '@material-ui/core';

const ThankYouPage = () => {
   
    return (
        <div style={{ margin: '50px auto', width: '80%', textAlign: 'center' }}>
            <Paper style={{ paddingTop: 50, paddingBottom: 50, background: "radial-gradient(circle, rgba(147,165,207,1) 0%, rgba(228,239,233,1) 100%)" }}>

                <Typography variant="h4" gutterBottom component="div">
                    Thank you
                </Typography>

                <Typography variant="h6" gutterBottom component="div">
                    Your submission has been received.
                </Typography>

                <Typography variant="h6" gutterBottom component="div" style={{ marginBottom: 20 }}>
                    We will be in touch and contact you soon.
                </Typography>

                <a href="https://www.decorpot.com" style={{
                    color: "#fff", backgroundColor: "#3f51b5", padding: "10px 16px",
                    fontSize: "0.875rem",
                    fontWeight: 500,
                    lineHeight: "1.75",
                    borderRadius: "4px",
                    letterSpacing: "0.02857em",
                    textTransform: "uppercase",
                    boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)"
                }}>Visit our website</a>
            </Paper>
        </div>
    )
}

export default connect(null, { setLoading, setSnackInfo })(ThankYouPage)
