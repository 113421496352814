import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { setLoading, setSnackInfo } from '../../../redux/actions/appAction'
import { getAllScopes, getAllSubCategory } from '../../../redux/actions/projectAction'
import { Table, TableBody, TextField, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import HttpService from '../../../services/httpService'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Tooltip from '@material-ui/core/Tooltip';


const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 850,
  }
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#ccc",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ManageSubCategory = (props) => {
  const classes = useStyles();

  const { scopes, subCategory } = useSelector(state => state.project)
  const [showAddSubCategoryModal, setShowAddSubCategoryModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [deleteId, setDeleteId] = useState("")
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [subCategoryDetails, setSubCategoryDetails] = useState({ code: "", name: "", id: "" })
  const { name, code } = subCategoryDetails
  const [selectedScopes, setSelectedScopes] = useState([])

  useEffect(() => {
    getAllSubCategory()
    // eslint-disable-next-line
  }, [])

  const getAllSubCategory = async () => {
    props.setLoading(true)
    try {
      await props.getAllSubCategory()
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.messsage ?? "Error fetching sub categories", "error")
    }
    props.setLoading(false)
  }

  const openAddSubCategoryModal = async () => {
    props.setLoading(true)
    try {
      await props.getAllScopes()
      setShowAddSubCategoryModal(true);
      setIsEdit(false);
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.messsage ?? "Something went wrong", "error")
    }
    props.setLoading(false)

  }

  const editSubCategory = (data) => {
    setIsEdit(true)
    setSubCategoryDetails({ name: data?.name, code: data?.code, id: data?._id })
    setSelectedScopes(data?.scopeId)
    setShowAddSubCategoryModal(true)
  }

  const handleScopeSelect = (v) => {
    setSelectedScopes(v)
  }

  const handleDialogClose = () => {
    setSubCategoryDetails({ name: "", code: "", id: "" })
    setShowAddSubCategoryModal(false)
  }

  const addSubCategory = async () => {
    props.setLoading(true)
    try {
      let obj = {
        name,
        code,
        scopId: selectedScopes.map(scope => scope._id)
      }
      const result = await HttpService.addSubCategory(obj)
      props.setSnackInfo(result.data, "success")
      handleDialogClose()
      getAllSubCategory()
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.messsage ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  const updateSubCategory = async () => {
    props.setLoading(true)
    try {
      let obj = {
        name,
        code,
        scopId: selectedScopes.map(scope => scope._id)
      }
      const result = await HttpService.updateSubCategory(obj, subCategoryDetails.id)
      props.setSnackInfo(result.data, "success")
      handleDialogClose()
      setIsEdit(false)
      getAllSubCategory()
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.messsage ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }
  const closeDeleteModal = () => { setShowDeleteModal(false); setDeleteId("") }

  const deleteSubCategory = async () => {
    props.setLoading(true)
    try {
      await HttpService.deleteSubCategory(deleteId)
      setShowDeleteModal(false)
      setDeleteId("")
      getAllSubCategory()
      props.setSnackInfo("Deleted", "success")
    } catch (error) {
      console.log(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }
  return (
    <div>
      <div style={{ marginBottom: 10, fontSize: 18 }}><b>Manage Sub Category</b></div>
      <div style={{ display: "flex", justifyContent: "flex-end" }} ><Button onClick={openAddSubCategoryModal} variant="contained" color="primary" >Add Sub Category</Button></div>
      <div style={{ marginBottom: 10 }}></div>

      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Sl No</StyledTableCell>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Code</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {subCategory?.map((data, i) => <StyledTableRow key={i + 1}>
              <StyledTableCell>{i + 1}</StyledTableCell>
              <StyledTableCell>{data?.name}</StyledTableCell>
              <StyledTableCell>{data?.code}</StyledTableCell>
              <StyledTableCell style={{ cursor: "pointer" }}>
                <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                  <Tooltip title="Edit"><EditOutlined style={{ cursor: "pointer" }} onClick={() => editSubCategory(data)} /></Tooltip>
                  <Tooltip title="Delete" onClick={() => { setShowDeleteModal(true); setDeleteId(data?._id) }}><DeleteOutlined /></Tooltip>
                </div>
              </StyledTableCell>
            </StyledTableRow>)}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={showAddSubCategoryModal}
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth={'sm'}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{`${isEdit ? 'Edit' : 'Add New'} Sub Category`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <TextField label="Name" name="name" value={name} style={{ marginBottom: 10 }} fullWidth onChange={(e) => setSubCategoryDetails({ ...subCategoryDetails, [e.target.name]: e.target.value })} variant="outlined" />
            <TextField label="Code" name="code" value={code} style={{ marginBottom: 10 }} fullWidth onChange={(e) => setSubCategoryDetails({ ...subCategoryDetails, [e.target.name]: e.target.value })} variant="outlined" />
            <Autocomplete
              multiple
              value={selectedScopes}
              id="tags-standard"
              options={scopes}
              onChange={(e, v) => handleScopeSelect(v)}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} fullWidth label="Select Scope" variant="outlined" />
              )}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {!isEdit ? <Button color="primary" onClick={addSubCategory} >
            Add
          </Button> :
            <Button color="primary" onClick={updateSubCategory} >
              Update
            </Button>}
        </DialogActions>
      </Dialog>
      <Dialog
        open={showDeleteModal}
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth={'sm'}
        onClose={closeDeleteModal}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Delete Sub Category</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={closeDeleteModal}>
            Cancel
          </Button>
          <Button color="primary" onClick={() => deleteSubCategory()}>
            Delete
          </Button>
        </DialogActions>

      </Dialog>
    </div>
  )
}

export default connect(null, { setLoading, setSnackInfo, getAllScopes, getAllSubCategory })(ManageSubCategory)
