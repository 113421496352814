import React, { useState, useEffect } from 'react'
import moment from 'moment'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import HttpService from '../services/httpService';
import { connect } from 'react-redux'
import { setLoading, setSnackInfo } from '../redux/actions/appAction';
import PoSoForApprovalView from './poSoForApprovalView';
import MaterialTransferListView from './materialTransferListView'

const PoSoMtrForApproval = (props) => {

  const [radioValue, setRadioValue] = useState("PO")
  const [poSoForApproval, setPoSoForApproval] = useState([])
  const [materialTransferList, setMaterialTransferList] = useState([])

  useEffect(() => {
    if (radioValue === "PO")
      getPoForApproval()
    else if (radioValue === "SO")
      getSoForApproval()
    else
      getMtrForApproval()
    // eslint-disable-next-line 
  }, [radioValue, props.MOUNTED])


  const getPoForApproval = async () => {
    props.setLoading(true)
    try {
      const result = await HttpService.getProcurementsForApprovalByProject(props.SELECTEDPROJECT._id, props.SCOPE)
      const poForApproval = result.data.map(item => {
        let obj = {
          _id: item?._id,
          orderId: item?.orderId,
          status: item?.status,
          client: item?.projectId?.clientName,
          code: item?.projectId?.code,
          vendorName: item?.vendorId?.name,
          total: item?.total,
          createdAt: moment(item?.createdAt).format("DD-MM-YYYY")
        }
        return obj
      })
      setPoSoForApproval(poForApproval)
    } catch (error) {
      console.error(error)
      setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  const getSoForApproval = async () => {
    props.setLoading(true)
    try {
      const result = await HttpService.getServiceOrdersForApprovalByProject(props.SELECTEDPROJECT._id, props.SCOPE)
      const soForApproval = result.data.map(item => {
        let obj = {
          orderId: item?.orderId,
          status: item?.status,
          client: item?.projectId.clientName,
          code: item?.projectId.code,
          vendorName: item?.vendorId?.name,
          total: item?.total,
          createdAt: moment(item?.createdAt).format("DD-MM-YYYY"),
          _id: item?._id
        }
        return obj
      })
      setPoSoForApproval(soForApproval)
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
    }
    props.setLoading(false)
  }

  const getMtrForApproval = async () => {
    props.setLoading(true)
    try {
      const result = await HttpService.getMaterailTransferRequestsByScopeForProject(props.SELECTEDPROJECT._id, props.SCOPE)
      setMaterialTransferList(result.data)
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something Went Wrong", "error")
    }
    props.setLoading(false)
  }

  const handleRadioChange = (e) => {
    setPoSoForApproval([])
    setRadioValue(e.target.value)
    if (e.target.value === "PO")
      getPoForApproval()
    else if (e.target.value === "SO")
      getSoForApproval()
    else
      getMtrForApproval()
  }

  return (
    <div>
      <FormControl component="fieldset">
        <RadioGroup aria-label="type" name="type" value={radioValue} onChange={handleRadioChange}>
          <div className='modal-header-radio'>
            <FormControlLabel value="PO" control={<Radio />} label="PO" />
            <FormControlLabel value="SO" control={<Radio />} label="SO" />
            <FormControlLabel value="MTR" control={<Radio />} label="MTR" />
          </div>
        </RadioGroup>
      </FormControl>
      {radioValue !== "MTR" ? <PoSoForApprovalView RADIOVALUE={radioValue} POSOFORAPPOVAL={poSoForApproval} REFRESHDATA={radioValue === "PO" ? getPoForApproval : getSoForApproval} OPENDETAILSMODAL={radioValue === "PO" ? props.OPENPODETAILSMODAL : props.OPENSODETAILSMODAL} /> : <MaterialTransferListView MATERIALTRANSFERLIST={materialTransferList} REFRESH={getMtrForApproval} OPENDETAILSMODAL={props.OPENMTRDETAILSMODAL} />}
    </div >
  )
}

export default connect(null, { setLoading, setSnackInfo })(PoSoMtrForApproval)
