export const SET_LOADING = "SET_LOADING"
export const SET_SNACK = "SET_SNACK"
export const SET_SNACK_INFO = "SET_SNACK_INFO"
export const SET_USER_DETAILS = "SET_USER_DETAILS"
export const GET_ALL_SCOPES = "GET_ALL_SCOPES"
export const GET_ALL_UNITS = "GET_ALL_UNITS"
export const GET_ALL_PAYMENT_MODES = "GET_ALL_PAYMENT_MODES"
export const GET_APPROVED_PROJECTS = "GET_APPROVED_PROJECTS"
export const GET_ALL_PROJECTS = "GET_ALL_PROJECTS"
export const GET_ALL_ROLES = "GET_ALL_ROLES"
export const GET_ALL_UNIQUE_ROLE_FIELDS = "GET_ALL_UNIQUE_ROLE_FIELDS"
export const GET_ALL_MATERIALS = "GET_ALL_MATERIALS"
export const GET_ALL_SUB_CATEGORY = "GET_ALL_SUB_CATEGORY"
export const SET_VENDOR_NAME = 'SET_VENDOR_NAME'
export const GET_ALL_PAYMENT_PARTNERS = 'GET_ALL_PAYMENT_PARTNERS'
export const SET_LOGIN_SUCCESS = 'SET_LOGIN_SUCCESS'
export const SET_LOGOUT = 'SET_LOGOUT'
export const SET_CUSTOMER_DETAILS ='SET_CUSTOMER_DETAILS'
