import React, { useState, useEffect } from 'react'
import { setLoading, setSnackInfo } from '../../../../redux/actions/appAction'
import { setVendorName } from '../../../../redux/actions/vendorAction'
import HttpService from '../../../../services/httpService'
import { connect } from 'react-redux'
import { useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import TableHead from '@material-ui/core/TableHead';
import { DownloadOutlined } from '@ant-design/icons';
import moment from 'moment'

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;
  const { vendorDetail } = useSelector(state => state.vendor)

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };
console.log(vendorDetail, "vendorDetail")
  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles({
  table: {
    minWidth: 500,
  },
  textField: {
    width: "150px"
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#ccc",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const AllPo = (props) => {
  let j = 1;
  const classes = useStyles2();
  useEffect(() => {
    getAllProcurements()
    // eslint-disable-next-line
  }, [])

  const [allPo, setAllPo] = useState([])
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dupAllPo, setDupallPo] = useState([])

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, allPo.length - page * rowsPerPage)
  const getAllProcurements = async () => {
    props?.setLoading(true)
    try {
      let response = await HttpService.getAllProcurements(props.location.state.vendorId)
      let responseData = response.data
      let vendorId = props.location.state.vendorId
      let soResponse = await HttpService.getServiceOrdersforVendor({vendorId})
      let soData = soResponse.data
      responseData = [...responseData, ...soData]
      let projectIds = props.location.state.projectIds
      if(projectIds.length > 0){
        let tempData = []
        for(let i = 0; i < responseData.length; i++){
          if(projectIds.includes(responseData[i].projectId?._id)){
            tempData.push(responseData[i])
          }
        }
        responseData = [...tempData]
      }
      
      setAllPo(responseData)
      setDupallPo(responseData)
    } catch (error) {
      console.error(error)
      props?.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props?.setLoading(false)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [orderId, setorderId] = useState("")
  const filterOpenPo = (e) => {
    setorderId(e.target.value)
    setAllPo(dupAllPo.filter(openPo => openPo.orderId.toLowerCase().includes(e.target.value.toLowerCase())))
  }

  return (
    <div >
        {/* search bar */}
      <div style={{ display: "flex", marginBottom: 10 }}>
        <TextField label="Search by Order ID" name="orderId" value={orderId} onChange={(e) => filterOpenPo(e)} variant="outlined" style={{ marginBottom: 10, width: '90%', marginLeft: '25%', marginRight: '25%' }} />
      </div>

      {/* heading */}
      <h1 style={{textAlign: 'center'}}>All PO/SO</h1>

      <TableContainer component={Paper}>

        <Table className={classes.table} size="small" aria-label="custom pagination table">

          <TableHead>
            <TableRow>
              <StyledTableCell style={{textAlign: 'center'}}>S.No.</StyledTableCell>
              <StyledTableCell style={{textAlign: 'center'}}>Order Id</StyledTableCell>
              <StyledTableCell style={{textAlign: 'center'}}>Type</StyledTableCell>
              <StyledTableCell style={{textAlign: 'center'}}>Created At</StyledTableCell>
              <StyledTableCell style={{textAlign: 'center'}}>Vendor Name</StyledTableCell>
              <StyledTableCell style={{textAlign: 'center'}}>Status</StyledTableCell>
              <StyledTableCell >Download</StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>

            {/* po data */}
            {(rowsPerPage > 0
              ? allPo.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : allPo
            ).map((data, i) => (
              
              <>
                
                {/* {data.vendorId.name === props.location.state.vendorName && */}
                <StyledTableRow key={j++}>

                <StyledTableCell style={{textAlign: 'center'}}>{j}</StyledTableCell>
                <StyledTableCell style={{textAlign: 'center'}}>{data?.orderId}</StyledTableCell>
                {data?.orderId[0] !== "S" ? <StyledTableCell style={{textAlign: 'center'}}>{data?.procurementType}</StyledTableCell> : <StyledTableCell style={{textAlign: 'center'}}>Service</StyledTableCell>}
                <StyledTableCell style={{textAlign: 'center'}}>{moment(data?.createdAt).format('DD-MM-YYYY')}</StyledTableCell>
                <StyledTableCell style={{textAlign: 'center'}}>{data?.vendorId?.name}</StyledTableCell>
                <StyledTableCell style={{textAlign: 'center'}}>{data?.status}</StyledTableCell>
                
                <StyledTableCell >
                  <StyledTableRow>
                    <StyledTableCell>
                      {data?.status.includes("Generated") ? <DownloadOutlined onClick={() => window.open(data.poLink?data.poLink:data.soLink)} style={{color: 'blue'}} /> : "NA"}
                    </StyledTableCell>
                  </StyledTableRow>
                </StyledTableCell>

              </StyledTableRow>
              {/* } */}
              </>
            ))}

            {emptyRows > 0 && (
              <StyledTableRow style={{ height: 53 * emptyRows }}>
                <StyledTableCell colSpan={8} />
              </StyledTableRow>
            )}
            
          </TableBody>

          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 20, 50, { label: 'All', value: -1 }]}
                colSpan={8}
                count={allPo.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
          
        </Table>
      </TableContainer>
    </div>
  )
}

// const mapstateToProps = state => { //changes
//     return {
//         vendorName: state.vendorName
//     }
// }

export default connect(null, { setLoading, setSnackInfo, setVendorName })(AllPo)
