import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { setLoading, setSnackInfo } from '../redux/actions/appAction';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button, Tooltip } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import HttpService from '../services/httpService';
import { ROLENAME } from '../utils';
import InfoIcon from '@material-ui/icons/Info';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogActions';
import { DialogContent, DialogActions } from '@material-ui/core';
import moment from "moment";
const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 700,
        textAlign: 'center'
    },
    root: {
        width: '100%',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
}));

const PaymentGenrateLink = (props) => {
    const classes = useStyles();
    const { userDetails } = useSelector(state => state.user);
    const [data, setData] = useState([]);
    const [status, setStatus] = useState('');
    const [projectId, setProjectId] = useState('')

    useEffect(() => {
        getAllPaymentRequests()
    }, [])

    const getAllPaymentRequests = async () => {
        props.setLoading(true)
        try {
            const response = await HttpService.getThePaymentRequestDetails(userDetails.selectedExpCenter)
            setData(response.data)
        } catch (error) {
            console.log(error, 'error')
            setData([]);
        }
        props.setLoading(false)
    }
    const navigateToVerifyPage = (leadId) => {
        props.history.push(`/validate-contract-documents/${leadId}`);
    }

    const [openRejectModal, setOpenRejectModal] = useState(false);

    const manageButton = (leadId, status) => {
        setOpenRejectModal(true)
        setStatus(status)
        setProjectId(leadId)
    }

    const handleSubmit = async () => {
        props.setLoading(true)
        try {
            let newStatus = status === 'Approve' ? 'Approved' : 'Rejected'
            const response = await HttpService.updateRequestForLink(projectId, newStatus)
            props.setSnackInfo("Updated Successfully", "success")
            setOpenRejectModal(false)
        } catch (err) {
            props.setSnackInfo(err?.response?.data ?? "Something went Wrong", "error")
        }
        getAllPaymentRequests()
        props.setLoading(false)
    }
    return (
        <div>
            <div style={{ marginBottom: 20 }}>
                <h3 style={{ textAlign: 'center' }}>
                    Payment Genration Link Payment
                </h3>
            </div>
            <Divider />

            <div style={{ display: 'flex', marginTop: 10, marginBottom: 20 }}>
                <Paper>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} size="small" stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Sr. No.</TableCell>
                                    <TableCell>Lead No</TableCell>
                                    <TableCell>Lead Name</TableCell>
                                    <TableCell>Project No.</TableCell>
                                    <TableCell>Requested By</TableCell>
                                    <TableCell>Requested Date</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.length !== 0 ?
                                    data.map((row, i) => {
                                        return (
                                            <TableRow key={i + 1}>
                                                <TableCell>{i + 1}</TableCell>
                                                <TableCell component="th">
                                                    <Link to={`/project-logs/${row._id}`} style={{ color: "#000", textDecoration: 'underline' }}>{row?.lead_no ? row?.lead_no : '-'}</Link>
                                                </TableCell>
                                                <TableCell component="th" scope="row">{row?.customerId?.name}</TableCell>
                                                <TableCell component="th">{row?.erpProjectNo ? row?.erpProjectNo : '-'}</TableCell>
                                                <TableCell component="th">{row?.cashFreeLinkRequest?.requestedBy?.name}</TableCell>
                                                <TableCell component="th">{row?.cashFreeLinkRequest?.RequestedCreatedAt ? moment(row?.cashFreeLinkRequest?.RequestedCreatedAt).format('YYYY-MM-DD') : ""}</TableCell>
                                                <TableCell component="th" scope="row" style={{ borderRight: "0px", alignItems: "center" }}>
                                                    <div style={{ marginLeft: "auto", display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                                                        <Tooltip title="Approve" placement="top">
                                                            <Button variant="contained" color="primary" style={{ background: "#716ACA", margin: 5 }} onClick={() => manageButton(row._id, "Approve")}>
                                                                Approve
                                                            </Button>
                                                        </Tooltip>
                                                        <Tooltip title="Reject" placement="top">
                                                            <Button variant="contained" color="primary" style={{ background: "#716ACA", margin: 5 }} onClick={() => manageButton(row._id, "Reject")}>
                                                                Reject
                                                            </Button>
                                                        </Tooltip>

                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                    :
                                    <TableRow>
                                        <TableCell colSpan={9} style={{ textAlign: 'center' }}>No Records Found</TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>


                    <Dialog
                        open={openRejectModal} onClose={() => setOpenRejectModal(false)} aria-labelledby="form-dialog-title" maxWidth='md'>
                        <DialogTitle style={{ fontSize: 30 }}> Are you sure you want to {status}</DialogTitle>
                        <DialogActions>
                            <Button color="primary" onClose={() => setOpenRejectModal(false)}>
                                Cancel
                            </Button>
                            <Button color="primary" onClick={() => handleSubmit()}>
                                Submit
                            </Button>
                        </DialogActions>

                    </Dialog>
                </Paper>
            </div>
        </div>
    )
}

export default connect(null, { setLoading, setSnackInfo })(PaymentGenrateLink)
