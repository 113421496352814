import React, { useState } from 'react'
import { connect, useSelector } from 'react-redux';
import { Button, Slide, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField, Select, InputLabel, MenuItem } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { setLoading, setSnackInfo } from '../redux/actions/appAction';
import HttpService from '../services/httpService';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { getApprovedProjects } from '../redux/actions/financeAction'
import { getAllScopes, getAllUnits } from '../redux/actions/projectAction';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  textField: {
    width: "350px"
  },
});

const MaterialTransferView = (props) => {
  const classes = useStyles();

  const [materialTransferModal, setMaterialTransferModal] = useState(false)
  const [transferFrom,] = useState(props.TYPE === "store" ? 'factory' : 'store')
  const [transferTo,] = useState(props.TYPE === "store" ? 'store' : 'factory')
  const { allMaterials } = useSelector(state => state.vendor)
  const { approvedProjects } = useSelector(state => state.finance)
  const { scopes, units } = useSelector(state => state.project)
  const [selectedScope, setSelectedScope] = useState({})
  const [quantity, setQuantity] = useState(0)
  const [materialSelected, setMaterialSelected] = useState({})
  const [selectedProject, setSelectedProject] = useState({})
  const gstPercent = [
    "5", "12", "18", "28"
  ]

  const [gstDetails, setGstDetails] = useState({
    gst: "",
    rate: ""
  })
  const handleGstType = (e) => {
    setGstDetails({ ...gstDetails, gst: e.target.value })
  }

  const submit = async () => {
    setMaterialTransferModal(false)
    props.setLoading(true)
    try {
      let obj = {}
      if (transferTo === "site") {
        obj = {
          materialId: materialSelected,
          quantity,
          transferFrom,
          transferTo,
          gstDetails,
          projectId: selectedProject,
          scopeId: selectedScope
        }
      } else {
        obj = {
          materialId: materialSelected,
          quantity,
          transferFrom,
          unitId: selectedUom
        }
      }
      const data = {}
      data.transferType = "Open"
      data.transferTo = transferTo
      data.materials = [obj]
      await HttpService.raiseMaterialTransferRequest(data)
      props.setSnackInfo('Material transfer request raised', 'success')
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  const openMaterialTransferModal = async () => {
    props.setLoading(true)
    try {
      await props.getAllScopes()
      await props.getAllUnits()
      await props.getApprovedProjects()
      setMaterialTransferModal(true);
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }
  const [selectedUom, setSelectedUom] = useState({})

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: 10 }}>
        <Button onClick={openMaterialTransferModal} variant="contained" color="primary" >
          Material Transfer
        </Button>
      </div>

      <Dialog
        open={materialTransferModal}
        TransitionComponent={Transition}
        keepMounted
        fullWidth={true}
        maxWidth={'sm'}
        onClose={() => setMaterialTransferModal(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Material Transfer"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div style={{ display: 'flex', flexDirection: "column" }}>
              <InputLabel id="project-doc">Transfer From</InputLabel>
              <Select
                disabled={true}
                labelId="project-doc"
                value={transferFrom}
                style={{ width: 350, marginBottom: 10 }}
              >
                <MenuItem value='factory'>Factory</MenuItem>
                <MenuItem value='store'>Store</MenuItem>
              </Select>

              {/* <Select
                labelId="project-doc"
                value={transferTo}
                onChange={(e) => setTransferTo(e.target.value)}
                style={{ width: 350, marginBottom: 10 }}
              >
                <MenuItem value='factory'>Factory</MenuItem>
                <MenuItem value='store'>Store</MenuItem>
                <MenuItem value='site'>Site</MenuItem>
              </Select> */}
              <Autocomplete
                value={materialSelected}
                onChange={(e, v) => setMaterialSelected(v)}
                options={allMaterials}
                getOptionLabel={(option) => option.name}
                style={{ width: 350, marginBottom: 10 }}
                renderInput={(params) => <TextField {...params} label="Select Material" fullWidth variant="outlined" />}
              />
              <Autocomplete
                value={selectedUom}
                onChange={(e, v) => setSelectedUom(v)}
                options={units}
                getOptionLabel={(option) => option.name}
                style={{ width: 350, marginBottom: 10 }}
                renderInput={(params) => <TextField {...params} label="Select Unit" fullWidth variant="outlined" />}
              />
              <TextField className={classes.textField} value={quantity} onChange={(e) => setQuantity(e.target.value)} label="Quantity" type="number" variant="outlined" />
              {transferTo === "site" && <div style={{ marginTop: 10 }}>
                <Autocomplete
                  value={selectedProject}
                  onChange={(e, v) => setSelectedProject(v)}
                  options={approvedProjects}
                  getOptionLabel={(option) => option.searchName}
                  style={{ width: 350, marginBottom: 10 }}
                  renderInput={(params) => <TextField {...params} label="Select Project" fullWidth variant="outlined" />}
                />
                <Autocomplete
                  value={selectedScope}
                  onChange={(e, v) => setSelectedScope(v)}
                  options={scopes}
                  getOptionLabel={(option) => option.name}
                  style={{ width: 350, marginBottom: 10 }}
                  renderInput={(params) => <TextField {...params} label="Select Scope" fullWidth variant="outlined" />}
                />
                <div className='gst-header'>Add GST and extra charge details</div>
                <div className='gst-radio-percent'>
                  <FormControl component="fieldset" className={classes.root}
                    style={{ border: '1.5px solid #ccc', padding: '5.5px 0px', borderRadius: '3.5px', width: '48%' }}>
                    <RadioGroup aria-label="type" name="type"
                      value={gstDetails?.gst} onChange={handleGstType}
                    >
                      <div className='gst-type-radio'>
                        <FormControlLabel value="SGST" control={<Radio />} label="CGST/SGST" />
                        <FormControlLabel value="IGST" control={<Radio />} label="IGST" />
                      </div>
                    </RadioGroup>
                  </FormControl>
                  <Autocomplete
                    value={gstDetails?.rate}
                    onChange={(e, value) => setGstDetails({ ...gstDetails, rate: value })}
                    id="percent"
                    options={gstPercent}
                    getOptionLabel={(option) => `${option}%`}
                    style={{ width: '48%' }}
                    renderInput={(params) => <TextField {...params} label={`Select ${gstDetails?.gst} percent`} fullWidth variant="outlined" />}
                  /></div>
              </div>
              }
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={submit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div >
  )
}

export default connect(null, { setLoading, setSnackInfo, getApprovedProjects, getAllScopes, getAllUnits })(MaterialTransferView)
