import React, { useState } from 'react';
import {
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Container,
  Card,
  CardContent,
  Link,
} from '@material-ui/core';
import HttpService from "../../../services/httpService";
import { connect, useSelector } from "react-redux";
import { isCustomerLoggedIn, setAuthTokenForCustomer } from '../../../utils';
import { setCustomerDetails } from '../../../redux/actions/customerAction';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import decorpotLogo from '../../../../src/assets/img/org/decorpot_log1.png'
import { setSnackInfo } from '../../../redux/actions/appAction';

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  avatarContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
  },
  avatar: {
    width: theme.spacing(25),
    height: theme.spacing(25),
  },
  card: {
    paddingBottom: theme.spacing(4), // Add paddingBottom
  },
  avatarImage: {
    width: '100%', // Set the width as needed
    height: '100%', // Set the height as needed
    objectFit: 'cover', // Maintain aspect ratio and cover the container
  }
}))

const UserLogin = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const { customerDetails } = useSelector(state => state?.customer)

  const [formData, setFormData] = useState({
    email: '',
    password: '',
    rememberMe: false,
  });
  const [isForgot,setForgotPassword] = useState(false)

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleLogin = async () => {
    try {
      let obj = {
        email: formData.email,
        password: formData.password,
      };
      const response = await HttpService.userLogin(obj);
      const { token, userData } = response.data;
      
      // Save token to localStorage if rememberMe is checked
      if (formData.rememberMe) {
        localStorage.setItem('token', token);
      }
      localStorage.clear()
      localStorage.setItem('token', token);
      setAuthTokenForCustomer(token)
      // Dispatch action to set user details in Redux
      props.setCustomerDetails(userData)
      if(response.status == 200){
        props.setSnackInfo("Login Successful","success");
      }
      if(customerDetails.length !==0){
            history.push('/cx_customer/dashboard');
            window.location.reload()  
      }
    } catch (error) {
      props.setSnackInfo(error.response?.data?.message ?? "Unauthorized", "error");
      // Handle login failure (e.g., show error message)
    }
  };

  const handleResetRequest = () => {
    setForgotPassword(true)
  };
  const handleResetPassword = (e) =>{
    const { name, value } = e.target;
   setFormData({name:value})
  }

  const forgotPassword = async () => {
    setForgotPassword(false)
    try {
      let obj ={
        email: formData.email
      }
      await HttpService.forgotCustomerPassword(obj)
      setFormData(false)
      // history.push('/');
    } catch (error) {
      console.error(error)
    }
  }
  return (
    <div className={classes.cardContainer}>
    <Container  maxWidth="sm">
      <Card className={classes.card}> 
          <CardContent>
        <Grid container className={classes.avatarContainer}>
        <Avatar alt="image" className={classes.avatar}>
                <img
                  src={decorpotLogo}
                  alt="avatar"
                  className={classes.avatarImage}
                />
              </Avatar>
            </Grid>
          <form>
            <Grid container spacing={2} alignItems="center" justify="center">
              { !isForgot ?
              <>
              <Grid item xs={12}>
                <TextField
                  label="Email"
                  name="email"
                  varinat="contained"
                  variant="outlined"
                  value={formData.email}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Password"
                  type="password"
                  name="password"
                  variant="outlined"
                  value={formData.password}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid> 
              <Grid item xs={8}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="rememberMe"
                      checked={formData.rememberMe}
                      onChange={handleInputChange}
                    />
                  }
                  label="Remember me"
                />
              </Grid>
              <Grid item xs={4}>
              <Link
                  variant="contained"
                  color="primary"
                  onClick={handleResetRequest}
                  fullWidth
                >
                  Forgot Password
                </Link>
              </Grid>
              </> : 
              <Grid item xs={12}>
              <TextField
                label="Email"
                name="email"
                varinat="contained"
                variant="outlined"
                value={formData.email}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
              }
              {!isForgot ?
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleLogin}
                  fullWidth
                >
                  Login
                </Button>
              </Grid>
              :
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={forgotPassword}
                  fullWidth
                >
                  Send OTP
                </Button>
              </Grid>
}
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Container>
    </div>
  );
};

export default connect(null, { setCustomerDetails, setSnackInfo })(UserLogin);