import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  AppBar,
  Grid,
  TextField,
  Paper,
  makeStyles,
  TableCell,
  TableRow,
  withStyles,
  Button,
  FormControl
} from "@material-ui/core";
import { useHistory, Link } from "react-router-dom";
import HttpService from "../../services/httpService";
import { setLoading, setSnackInfo } from "../../redux/actions/appAction";
import { connect, useSelector } from "react-redux";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  tablecontainer: {
    display: "flex",
    margin: "20px",
    minWidth: "950px"
  },
  textField: {
    display: "flex",
    margin: "20px",
    maxWidth: "900px",
    cursor: 'default'
  },
  activeButton: {
    backgroundColor: "#F50057 !important",
    color: "white !important",
    transition: "background-color 0.3s, color 0.3s",
  },
  redButton: {
    backgroundColor: "#F50057 !important",
    color: "white !important",
    transition: "background-color 0.3s, color 0.3s",
    margin: "10px"
  },
  normalButton:{
    margin: "10px"
  },
  dateSize:{
    width: "50%"
  },
  handOnHover:{
    cursor: "pointer"
  },
  emailField:{
    width: "300px",
    margin: "20px"
  },
  title:{
    display: "flex",
    margin: "10px",
    marginLeft: "350px",
    maxWidth: "900px",
    cursor: 'default'
  }
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#3F51B5",
    color: "#FFF",
    fontWeight: "500",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const CreateTaskTicket = (props) => {

  const { visibilityFunction, refreshParentDataNo, refreshParentData } = props;

  const classes = useStyles();
  const history = useHistory();
  const { userDetails } = useSelector((state) => state.user);

  const [ticketTitle, setTicketTitle] = useState('');
  const [ticketDescription, setTicketDescription] = useState('');
  const [ticketETAdate, setTicketETAdate] = useState('');
  const [wrongEmail, setWrongEmail] = useState(false);
  const [files, setFiles] = useState([""]);
  const [userEmail, setUserEmail] = useState("");

  const handleTickeTitleInputChange = (e)=>{
    setTicketTitle(e.target.value)
}
const handleTickeDescriptionInputChange = (e)=>{
    setTicketDescription(e.target.value)
}
const handleETAdateInputChange = (e) => {
  // setTicketETAdate(e.target.value)
  const selectedDate = new Date(e.target.value);
  const currentDate = new Date();
  currentDate.setHours(0,0,0,0)

  // Check if the selected date is in the future or today
  if (selectedDate >= currentDate) {
    setTicketETAdate(e.target.value);
  } else {
    props.setSnackInfo("Please select a date in the present or future.",'warning');
  }
};

const onImageChangeCheck = async (e, index) => {
  const newFiles = [...files];

      props.setLoading(true);
      const fd = new FormData();
      fd.append("file", e.target.files[0]);
      try {
          const fileUploadRes = await HttpService.uploadFile(fd);
          newFiles[index] = fileUploadRes.data;
          setFiles(newFiles);
      } catch (err) {
          props.setSnackInfo(err?.response?.data ?? "Something went wrong", "error");
      }
      props.setLoading(false);
}
const handleAddFileInput = () => {
  let newFiles = [...files];
  newFiles.push("");
  setFiles(newFiles);
};

const handleRemoveFileInput = (index) => {
  const newFiles = files.filter((file,id) => id !== index);
  setFiles([...newFiles]);
}

const handleSubmitButton = async (e)=>{
  try{
      let obj = {
          ticketTitle:ticketTitle,
          ticketDescription:ticketDescription,
          ticketETAdate:ticketETAdate,
          uploadedFiles:files,
          email: userEmail,
      }
      const response = await HttpService.createTask(obj);
      if(response.status == 201){
        props.setSnackInfo('Task Created Successfully',"Success")
        visibilityFunction(false)
        refreshParentDataNo()
        refreshParentData()
      }
  }
  catch(error){
      if(error.response.status == 404){
        props.setSnackInfo(error.response.data,"Error");
      }
      setWrongEmail(true)
      console.log(error);
  }
}

const handleUserEmailChange = (e,v)=>{

  setUserEmail(e.target.value);
}


  return (
      <Fragment>        

        <Paper>
            <Grid
              container
              className={classes.tablecontainer}
              direction="column"
              justifyContent="space-evenly"
              alignItems="flex-start"
              key="parentGridContainer"
            >
              <h2 className={classes.title} >Create New Task</h2>

              <TextField
                id="ticketTitle"
                key="ticketTitle"
                name="Task Title"
                label="Task Title"
                value={ticketTitle}
                defaultValue="Task Title"
                onChange={handleTickeTitleInputChange}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                className={classes.textField}
                required
              />

              <TextField
                id="ticketDescription"
                key="ticketDescription"
                name="Task Description"
                label="Task Description"
                value={ticketDescription}
                defaultValue="Task Description"
                onChange={handleTickeDescriptionInputChange}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                className={classes.textField}
                required
              />

              <TextField
                id="ticketETAdate"
                key="ticketETAdate"
                name="ticketETAdate"
                label="Due Date"
                type="date"
                value={ticketETAdate}
                onChange={handleETAdateInputChange}
                InputLabelProps={{
                  shrink: true,
                }}
                className={`${classes.dateField, classes.textField}`}
                required
              />

              {files?.map((file, index) => (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    margin: 10,
                    padding: 2,
                  }}
                >
                  <FormControl style={{ width: 500 }}>
                    <input
                      key={`${index}`}
                      accept="*"
                      className={classes.input}
                      id={`contained-button-file-${index}`}
                      name="chmImageAttachment"
                      onChange={(e) => onImageChangeCheck(e, index)}
                      type="file"
                      style={{ display: "none" }}
                    />
                    <label htmlFor={`contained-button-file-${index}`}>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ background: "#716ACA" }}
                        component="span"
                      >
                        Upload Task Attachments
                      </Button>
                    </label>
                  </FormControl>
                  {files[index] !== "" && (
                    <CheckCircleIcon
                      style={{
                        verticalAlign: "middle",
                        color: "green",
                        fontSize: 30,
                        marginLeft: 10,
                      }}
                    />
                  )}
                  <Button
                    onClick={() => handleAddFileInput(index)}
                    startIcon={<AddCircleOutlineRoundedIcon />}
                    style={{ marginLeft: 10 }}
                  />
                  {index !== 0 && (
                    <Button
                      onClick={() => handleRemoveFileInput(index)}
                      startIcon={<DeleteOutlineOutlinedIcon />}
                      style={{ marginLeft: 10 }}
                    />
                  )}
                </div>
              ))}

              <TextField
                id="tagUser"
                key="tagUser"
                name="Tag User Email"
                label="Tag User Email"
                value={userEmail}
                defaultValue="Task Description"
                onChange={(e,v) => { handleUserEmailChange(e,v)}}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                className={classes.emailField}
                required
              />

              <Button
                color="secondary"
                variant="contained"
                // className={classes.redButton}
                style={
                  {
                  marginLeft:"10px"
                  }
                }
                onClick={handleSubmitButton}
                disabled={
                  ticketTitle === "" ||
                  ticketDescription === "" ||
                  ticketETAdate === "" ||
                  userEmail === ""
                }
              >
                Create New Task
              </Button>
              { wrongEmail && <h5 style={{color:"red"}}>Email Is Wrong, Please Try again</h5>}

              </Grid>

            </Paper>
      </Fragment>
  );
};

export default connect(null, { setLoading, setSnackInfo })(CreateTaskTicket);
