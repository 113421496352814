import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import 'antd/dist/antd.css';
import moment from 'moment'
import HttpService from '../../services/httpService';
import { makeStyles, useTheme,withStyles } from '@material-ui/core/styles';
import { connect, useSelector } from "react-redux";
import { setLoading, setSnackInfo } from '../../redux/actions/appAction';
import { getApprovedProjects } from '../../redux/actions/financeAction'
import { getAllMaterials } from '../../redux/actions/vendorAction';
import { getAllScopes, getAllUnits } from '../../redux/actions/projectAction';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import PropTypes from 'prop-types';


const useStyles = makeStyles({
	table: {
		minWidth: 700,
	},
	root: {
		width: '100%',
	}
});

const useStyles1 = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  }));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
  
  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#ccc",
      color: theme.palette.common.black,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

const Material_Return = (props) => {
    let j = 1
    useEffect(() => {
		getApprovedProjects()
        getMtrReturns()
	}, [])

    const classes = useStyles();

	const { approvedProjects } = useSelector(state => state.finance)
    const [allMaterials, setAllMaterials] = useState([])
    const [allReturns, setAllReturns] = useState([])

    
    const [selectedProject, setSelectedProject] = useState({})
    const [selectedMaterial, setSelectedMaterial] = useState({})
    const [projectQty, setProjectQty] = useState(0)
    const [returnQty, setReturnQty] = useState("")
    const [storageLocation, setStorageLocation] = useState({})
    
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, allReturns.length - page * rowsPerPage)
    
    const storageLocations = [{name: "factory"}, {name: "store"}]

    const getApprovedProjects = async () => {
        props.setLoading(true)
		try {
			await props.getApprovedProjects()
			
		} catch (error) {
			console.error(error)
			props.setSnackInfo(error.response.data.message, "error")
		}
        props.setLoading(false)
	}

    const getMtrReturns = async () => {
        props.setLoading(true)
		try {
			const response = await HttpService.getMtrReturns()
            setAllReturns(response.data)
		} catch (error) {
			console.error(error)
			props.setSnackInfo(error.response.data.message, "error")
		}
        props.setLoading(false)
	}

    const handleProjectSelect = async (event, v) => {
		props.setLoading(true)
		try {
			if (v) {
				setSelectedProject(v)
                setSelectedMaterial({})
                setProjectQty(0)
                setStorageLocation({})
                setReturnQty("")
                const response = await HttpService.getProjectMaterials(v._id)
                setAllMaterials(response.data)
			} else {
				setSelectedProject({})
                setSelectedMaterial({})
                setProjectQty(0)
                setStorageLocation({})
                setReturnQty("")
			}
		} catch (error) {
			console.error(error)
			props.setSnackInfo('Couldn\'t fetch the materials', 'error')
		}
		props.setLoading(false)
	}
    
    const handleMaterialSelect = async (e, v) => {
        props.setLoading(true)
        try {
            if (v) {
                let materials = [v]
                setSelectedMaterial(v)
                setProjectQty(0)
                const response = await HttpService.getMaterialDispatchedQty(selectedProject._id, v._id)
                setProjectQty(response.data.qty)
            } else {
                setSelectedMaterial({})
                setProjectQty(0)
            }
        } catch (error) {
            console.error(error)
			props.setSnackInfo('Couldn\'t fetch the qty', 'error')
        }
        props.setLoading(false)
      }

    const handleStorageLocation = async (e, v) => {
        if(v){
            setStorageLocation(v)
        } else {
            setStorageLocation({})
        }
    }

    const handleMaterialReturn = async (projectId, projectName, materialId, materialName, qty, location) => {

        if(!projectId || !materialId || !qty || !location){
            props.setSnackInfo('Please fill all the details', "error")
            return
        }
        if(projectQty === 0){
            props.setSnackInfo("Project Qty should be greater than 0", "error")
            return
        }
        if(qty > projectQty){
            props.setSnackInfo("Return Qty can't be greater than Project Qty", "error")
            props?.setLoading(false)
            return
        }

        props?.setLoading(true)
        try{
            let data = {projectId, projectName, materialId, materialName, qty, location, status: 'Requested'}
            const response = await HttpService.materialReturnRequest(data)
            props.setSnackInfo(response.data.msg, "success")
            setSelectedProject({})
            setSelectedMaterial({})
            setProjectQty(0)
            setStorageLocation({})
            setReturnQty("")
            getMtrReturns()
        } catch (error) {
            console.error(error)
            props?.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
          }
        props?.setLoading(false)

    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };

    return (
        <>
            <h1 style={{textAlign: "center", marginBottom: 20, color: "white", backgroundColor: "#2C2E3E", padding: 10, borderRadius: 50, width: "100%",}}>Return New Material</h1>
            <div style={{ display: "flex", justifyContent: "space-inside", paddingBottom: 20 }}>
				<Autocomplete
					value={selectedProject}
					onChange={handleProjectSelect}
					id="project-code-dropdown"
					options={approvedProjects}
					getOptionLabel={(option) => option.searchName}
					style={{ width: 200, marginRight: 10 }}
					renderInput={(params) => <TextField {...params} label="Select Project" fullWidth variant="outlined" />}
				/>
                <Autocomplete
					value={selectedMaterial}
					onChange={handleMaterialSelect}
					id="material-code-dropdown"
					options={allMaterials}
					getOptionLabel={(option) => option.name}
					style={{ width: 200, marginRight: 10 }}
					renderInput={(params) => <TextField {...params} label="Select Material" fullWidth variant="outlined" />}
				/>
                <TextField label="Project Qty" name="project_qty" type="number" value={projectQty} variant="outlined" style={{ width: 100, marginRight: 10 }} disabled/>
                <TextField label="  Return Qty" name="return_qty" type="number" value={returnQty} onChange={(e) => setReturnQty(e.target.value)} variant="outlined" style={{ width: 100, marginRight: 10 }}  />
                <Autocomplete
					value={storageLocation}
					onChange={handleStorageLocation}
					id="storage-code-dropdown"
					options={storageLocations}
					getOptionLabel={(option) => option.name}
					style={{ width: 200, marginRight: 10 }}
					renderInput={(params) => <TextField {...params} label="Storage Location" fullWidth variant="outlined" />}
				/>
                <Button onClick={() => handleMaterialReturn(selectedProject._id, selectedProject.searchName, selectedMaterial._id, selectedMaterial.name, returnQty, storageLocation.name)} style={{ marginLeft: 200, backgroundColor: "#2C2E3E", color: 'white' }} variant="contained">
                Submit
                </Button>
            
			</div>
            <h1 style={{textAlign: "center", marginBottom: 20, color: "white", backgroundColor: "#2C2E3E", padding: 10, borderRadius: 50, width: "100%",}}>All Returns</h1>
            <TableContainer component={Paper}>

        <Table className={classes.table} size="small" aria-label="custom pagination table">

          <TableHead>
            <TableRow>
              <StyledTableCell style={{textAlign: 'center'}}>S.No.</StyledTableCell>
              <StyledTableCell style={{textAlign: 'center'}}>Project Name</StyledTableCell>
              <StyledTableCell style={{textAlign: 'center'}}>Material</StyledTableCell>
              <StyledTableCell style={{textAlign: 'center'}}>Return Qty</StyledTableCell>
              <StyledTableCell style={{textAlign: 'center'}}>Approval Date</StyledTableCell>
              <StyledTableCell style={{textAlign: 'center'}}>Location</StyledTableCell>
              <StyledTableCell style={{textAlign: 'center'}}>Status</StyledTableCell>
              
            </TableRow>
          </TableHead>

          <TableBody>

            {/* po data */}
            {(rowsPerPage > 0
              ? allReturns.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : allReturns
            ).map((data, i) => (
                
                <>
                {console.log("data",data)}
                
                {/* {data.vendorId.name === props.location.state.vendorName && */}
                <StyledTableRow key={j++}>

                <StyledTableCell style={{textAlign: 'center'}}>{j}</StyledTableCell>
                <StyledTableCell style={{textAlign: 'center'}}>{data?.projectName}</StyledTableCell>
                <StyledTableCell style={{textAlign: 'center'}}>{data?.materialName}</StyledTableCell>
                <StyledTableCell style={{textAlign: 'center'}}>{data?.qty}</StyledTableCell>
                {data?.status === "Approved" ? <StyledTableCell style={{textAlign: 'center'}}>{moment(data?.updatedAt).format('DD-MM-YYYY')}</StyledTableCell>: <StyledTableCell style={{textAlign: 'center'}}>NA</StyledTableCell>}
                <StyledTableCell style={{textAlign: 'center'}}>{data?.location}</StyledTableCell>
                <StyledTableCell style={{textAlign: 'center'}}>{data?.status}</StyledTableCell>
                
                

              </StyledTableRow>
              {/* } */}
              </>
            ))}

            {emptyRows > 0 && (
              <StyledTableRow style={{ height: 53 * emptyRows }}>
                <StyledTableCell colSpan={8} />
              </StyledTableRow>
            )}
            
          </TableBody>

          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 20, 50, { label: 'All', value: -1 }]}
                colSpan={8}
                count={allReturns.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
          
        </Table>
      </TableContainer>
            
        </>
    )

}

export default connect(null, { setLoading, getApprovedProjects, setSnackInfo, getAllScopes,getAllMaterials, getAllUnits })(Material_Return);