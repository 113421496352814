// the ManageQTCustomer component
import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { connect, useSelector } from 'react-redux'
import { Link } from "react-router-dom";
import HttpService from '../../services/httpService'
import { setLoading, setSnackInfo } from '../../redux/actions/appAction'
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select/Select';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 700,
    },
    root: {
        width: '100%',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
}));
  
const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#ccc",
      color: theme.palette.common.black,
    },
    body: {
      fontSize: 14,
    },
}))(TableCell);
  
const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
}))(TableRow);

const ManageQTCustomer = (props) => {
    const { customerId } = useParams();
    const classes = useStyles();
    const { userDetails } = useSelector(state => state.user)
    const [customerProjects, setCustomerProjectRows] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [open, setOpen] = React.useState(false);
    const [anotherDialog, setOpenAnotherDialog] = React.useState(false);
    const [departmentStage, setDepartmentsStage] = useState([]);
    const [stage, setSelectedStage] = useState("");
    const [departmentLists, setDepartmentsList] = useState([]);
    const [department, setSelectedDepartment] = useState("");
    const [teamLists, setTeamList] = useState([]);
    const [team, setSelectedTeam] = useState("");
    const [dealOwnerLists, setDealOwnersList] = useState([]);
    const [dealOwner, setSelectedDealOwner] = useState("");
    const [projectNo, setProjectNumber] = useState("");
    const [preSalesCallDate, setHandlepreSalesCallDate] = useState(new Date("2018-01-01T00:00:00.000Z"));
    const [nextFollowUpDate, setHandleNextFollowUpDate] = useState(new Date("2018-01-01T00:00:00.000Z"));
    const [salesCallDate, setHandleSalesCallDate] = useState(new Date("2018-01-01T00:00:00.000Z"));
    const [quotationSentDate, setQuotationSentDate] = useState(new Date("2018-01-01T00:00:00.000Z"));
    const [siteVisitDate, setSiteVisitDate] = useState(new Date("2018-01-01T00:00:00.000Z"));
    const [negotiationDate, setNegotiationDate] = useState(new Date("2018-01-01T00:00:00.000Z"));
    const [closureDate, setClosureDate] = useState(new Date("2018-01-01T00:00:00.000Z"));
    const [clientMoveinDate, setClientMoveinDate] = useState(new Date("2018-01-01T00:00:00.000Z"));
    const [contractSignedDate, setContractSignedDate] = useState(new Date("2018-01-01T00:00:00.000Z"));
    const [projectCompletionDate, setProjectCompletionDate] = useState(new Date("2018-01-01T00:00:00.000Z"));
    const [requirements, setRequirements] = useState("");
    const [estimatedBudget, setEstimateBudget] = useState("");
    const [salesCallComment, setSalesCallComment] = useState("");
    const [momQuotationStage, setMomQuotationStage] = useState("");
    const [momMeetingStage, setMomMeetingStage] = useState("");
    const [momSiteVisitStage, setMomSiteVisitStage] = useState("");
    const [momNegotiationStage, setMomNegotiationStage] = useState("");
    const [remark, setRemark] = useState("");
    const [wdFile, setWDFileLink] = useState("");


    const handleClickOpen = () => {
        setOpen(true);
        getDepartments();
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleAnotherDialogClose = () => {
        setOpenAnotherDialog(false);
    }
    const openAnotherDialog = () => {
        setOpen(false);
        setOpenAnotherDialog(open);
        console.log(stage, "selected stage")
    }

    const handleSelectedStageChange = (event) => {
        setSelectedStage(event.target.value);
    }

    const handleSelectedDepartmentChange = (event) => {
        setSelectedDepartment(event.target.value);
        getTeamsList(event.target.value)
    }

    const handleSelectedTeamChange = (event) => {
        setSelectedTeam(event.target.value);
        getUserList(event.target.value)
    }

    const handleSelectedUserChange = (event) => {
        setSelectedDealOwner(event.target.value);
    }

    useEffect(() => {
        getCustomerProjectDetail(customerId)
        // eslint-disable-next-line
    }, [])

    const getCustomerProjectDetail = async (customerId) => {
        try {
            const result = await HttpService.customerProject(customerId);
            console.log(result.data, "Result", result.data[0]._id);
            setCustomerProjectRows(result.data);
            setProjectNumber(result.data[0]._id)
        } catch (error) {
            console.log(error, "Get Customer Project Detail error")
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        setCustomerProjectRows(0);
    };

    const getDepartments = async () => {
        try {
            const department = await HttpService.getDepartments();
            console.log(department.data, "department", userDetails)
            if(userDetails.roles[0]['name'] === 'Manager' || userDetails.roles[0]['name'] === 'User') {
                // Current user Department Id hardcoded
                // Sales DEPT Id: 5ba91ae807324039ac9cd77b
                // Design DEPT Id: 5cb70b89ffa4965f53aa22d8
                setDepartmentsList(department.data.filter(ele => ele._id === userDetails.departmentId))
            }
            getDepartmentStages(department.data);
        } catch (error) {
            console.log(error, "get department error")
        }
    }

    const getDepartmentStages = async (data) => {
        // Using Current user Department Id getting the stages of that particular department
        console.log(data, "Data");
        let stages = []
        data.forEach((element) => {
            if (element['_id'] === userDetails.departmentId) {
                stages.push(element['stages']);
            };
        });
        setDepartmentsStage(stages[0]);
        console.log(stages, "department stages");
    }

    const getTeamsList = async (deptid) => {
        let teams = [];
        teams = departmentLists.filter(ele => ele._id === deptid)
        console.log(deptid, "deptid", teams);
        setTeamList(teams[0]['teams'])
        // if(userDetails.roles[0]['name'] !== 'Manager') {
        //     setTeamList(teamLists.filter(ele => ele._id === '5d2240f85d6fc50bfc823f5c'))
        // }
    }

    const getUserList = async (teamid) => {
        console.log(teamid, "teamid", userDetails.teamId);
        // Team Prasenjit: 5d6e3ca3204a0716948fc635
        // Design Team 3: 5f50b713f42e991154e6b252 
        if (teamid === userDetails.teamId._id) {
            try {
                const users = await HttpService.getQTTeamUsers(teamid);
                console.log(users, "Users")
                setDealOwnersList(users.data.users)
            } catch (error) {
                console.log(error, "Error");
            }
        } else {
            try {
                const teamUsers = await HttpService.getQtTeamManager(teamid);
                console.log(teamUsers, "teamUsers", teamUsers.data.manager)
                let teamManager = [];
                let obj = {
                    _id: teamUsers.data.manager._id,
                    name: teamUsers.data.manager.name
                }
                teamManager.push(obj);
                setDealOwnersList(teamManager)
            } catch (error) {
                console.log(error, "Error");
            }
        }
    }

    const uploadWDFileDocument = async (e) => {
        try {
          props.setLoading(true)
          const formData = new FormData()
          formData.append('file', e.target.files[0])
          const result = await HttpService.wdFileUpload(formData)
          console.log(result.data, "link", result)
          if (result.status === 200) {
            setWDFileLink(result.data)
            props.setSnackInfo("Working Drawing Upload Success", "success")
          }
        } catch (error) {
          console.log(error)
          props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
        }
        props.setLoading(false)
      }

    const assignProject = async () => {
        let dealData = {
            stage: stage,
            department: department,
            team: team,
            id: dealOwner,
            projectId: projectNo
        };
        if(stage === 'Pre - Sales call') {
            if(dealOwner) {
                dealData['preSalesCallDate']= preSalesCallDate
                dealData['nextFollowUpDate']= nextFollowUpDate
            } else {
                dealData = {
                    stage: stage,
                    projectId: projectNo,
                    nextFollowUpDate: nextFollowUpDate,
                    preSalesCallDate: preSalesCallDate
                };
            }
        } else if(stage === 'Sales Call') {
            if(dealOwner) { 
                dealData['salesCallDate']= salesCallDate;
                dealData['requirements']= '';
                dealData['estimatedBudget']= '';
                dealData['nextFollowUpDate']= nextFollowUpDate;
                dealData['salesCallComment']= '';
            } else {
                dealData = {
                    stage: stage,
                    projectId: projectNo,
                    salesCallDate: salesCallDate,
                    requirements: '',
                    estimatedBudget: '',
                    nextFollowUpDate: nextFollowUpDate,
                    salesCallComment: ''
                };
            }
            
        } else if(stage === 'Quotation Sent') {
            if(dealOwner) {
                dealData['quotationSentDate']= quotationSentDate;
                dealData['nextFollowUpDate']= nextFollowUpDate;
                dealData['momQuotationStage']= '';
            } else {
                dealData = {
                    stage: stage,
                    projectId: projectNo,
                    nextFollowUpDate: nextFollowUpDate,
                    quotationSentDate: quotationSentDate,
                    momQuotationStage: ''
                };
            }
            
        } else if(stage === 'Site/Office visit') {
            if(dealOwner) { 
                dealData['siteVisitDate']= siteVisitDate;
                dealData['momMeetingStage']= '';
                dealData['momSiteVisitStage']= '';
                dealData['nextFollowUpDate']= nextFollowUpDate;
            } else {
                dealData = {
                    stage: stage,
                    projectId: projectNo,
                    siteVisitDate: siteVisitDate,
                    momMeetingStage: '',
                    momSiteVisitStage: '',
                    nextFollowUpDate: nextFollowUpDate
                };
            }
            
        } else if(stage === 'Negotiation') {
            if(dealOwner) {
                dealData['negotiationDate']= negotiationDate;
                dealData['momNegotitationStage']= '';
                dealData['nextFollowUpDate']= nextFollowUpDate;
            } else {
                dealData = {
                    stage: stage,
                    projectId: projectNo,
                    negotiationDate: negotiationDate,
                    momNegotitationStage: '',
                    nextFollowUpDate: nextFollowUpDate
                };
            }
        } else if(stage === 'Won') {
            if(dealOwner) {
                dealData['closureDate']= closureDate;
                dealData['remark']= '';
                dealData['finalQuoteAttached']= '';
                dealData['clientMoveinDate']= clientMoveinDate;
            } else {
                dealData = {
                    stage: stage,
                    projectId: projectNo,
                    closureDate: closureDate,
                    remark: '',
                    finalQuoteAttached: '',
                    clientMoveinDate: clientMoveinDate
                };
            }
        } else if(stage === 'Contract Signed') {
            dealData['contractSignedDate'] = contractSignedDate;
            dealData['workingDrawingFile'] = wdFile;
            dealData['projectCompletionDate'] = projectCompletionDate;
        } else {
            dealData = {
                stage: stage,
                department: department,
                team: team,
                id: dealOwner,
                projectId: projectNo
            }
        }
        
        console.log(dealData, "dealData");
        try {
            props.setLoading(true)
            const assignProject = await HttpService.assignProject(dealData);
            if (assignProject.status === 200) {
                setOpenAnotherDialog(false);
                setSelectedStage('');
                setSelectedDepartment('');
                setSelectedTeam('');
                setSelectedDealOwner('');
                getCustomerProjectDetail(customerId);
                props.setSnackInfo("Project assigned Successful", "success")
            }
        } catch(error) {
            console.log(error, "assign project error");
            props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
        }
        props.setLoading(false)
    }

    return (
        <div>
            <Paper className="mainTitileGrid">
                <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center" >
                    <Grid item xs>
                        <p className="headerTitle">
                            Manage Project
                        </p>
                    </Grid>
                    <Grid item xs>
                    {
                        userDetails.roles[0]['name'] !== 'Admin' && 
                        <Button variant="outlined" onClick={handleClickOpen}>
                            Change Stage
                        </Button>
                    }
                        {/* Dialog for select the stage */}
                        <Dialog open={open} onClose={handleClose}>
                            <DialogTitle>Change Stage</DialogTitle>
                            <DialogContent>
                                <Box sx={{ minWidth: 450 }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Select Stage</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={stage}
                                            label="Select Stage"
                                            onChange={handleSelectedStageChange}
                                        >
                                            {departmentStage.map((row, i) => {
                                                return (
                                                    <MenuItem key={i + 1} value={row}>{row}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose}>Cancel</Button>
                                <Button onClick={openAnotherDialog}>Change</Button>
                            </DialogActions>
                        </Dialog>

                        {/* Dialog for select the details of previous dialog selected stage */}
                        <Dialog open={anotherDialog} onClose={handleAnotherDialogClose}>
                            <DialogTitle>Select Details</DialogTitle>
                            <DialogContent>
                                <Box sx={{ minWidth: 450 }}>
                                {
                                    // Pre sales call date
                                    stage === 'Pre - Sales call' &&
                                        <FormControl fullWidth style={{marginBottom: "15px"}}>
                                            <TextField
                                                id="preSalesCall-Date"
                                                name={preSalesCallDate}
                                                onChange={(e) => setHandlepreSalesCallDate(e.target.value)}
                                                label="Pre sales call date"
                                                type="date"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </FormControl>
                                }
                                {
                                    // Next Follow Update Date
                                    (stage === 'Pre - Sales call' || stage === 'Sales Call' || stage === 'Quotation Sent' || stage === 'Site/Office visit' || stage === 'Negotiation') &&
                                        <FormControl fullWidth style={{marginBottom: "15px"}}>
                                            <TextField
                                                id="nextFollowUp-Date"
                                                name={nextFollowUpDate}
                                                onChange={(e) => setHandleNextFollowUpDate(e.target.value)}
                                                label="Next Follow Update Date"
                                                type="date"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </FormControl>
                                }
                                {
                                    // Sales call Data, Requirements, Estimate Budget, Sales call comments
                                    stage === 'Sales Call' && 
                                    <div>
                                        <FormControl fullWidth style={{marginBottom: "15px"}} >
                                            <TextField
                                                id="salesCall-Date"
                                                name={salesCallDate}
                                                onChange={(e) => setHandleSalesCallDate(e.target.value)}
                                                label="sales call date"
                                                type="date"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </FormControl>
                                        <FormControl fullWidth style={{marginBottom: "15px"}}>
                                            <TextField
                                                id="standard-multiline-static"
                                                label="Requirements"
                                                multiline
                                                rows={4}
                                                placeholder="Enter Requirements"
                                                value={requirements}
                                                onChange={(e) => setRequirements(e.target.value)}
                                                variant="standard"
                                            />
                                        </FormControl>
                                        <FormControl fullWidth style={{marginBottom: "15px"}}>
                                            <InputLabel htmlFor="outlined-adornment-amount">Estimate Budget</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-amount"
                                                value={estimatedBudget}
                                                onChange={(e) => setEstimateBudget(e.target.value)}
                                                // startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                label="Estimate Budget"
                                            />
                                        </FormControl>
                                        <FormControl fullWidth style={{marginBottom: "15px"}}>
                                            <TextField
                                                id="standard-multiline-static"
                                                label="Sales call comment"
                                                multiline
                                                rows={4}
                                                placeholder="Enter Sales Call Comment"
                                                value={salesCallComment}
                                                onChange={(e) => setSalesCallComment(e.target.value)}
                                                variant="standard"
                                            />
                                        </FormControl>
                                    </div>
                                }
                                {
                                    // Quotation sent date, mom quotation stage
                                    stage === 'Quotation Sent' && 
                                    <div>
                                        <FormControl fullWidth style={{marginBottom: "15px"}}>
                                            <TextField
                                                id="quotationSent-Date"
                                                name={quotationSentDate}
                                                onChange={(e) => setQuotationSentDate(e.target.value)}
                                                label="Quotation send date"
                                                type="date"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </FormControl>
                                        <FormControl fullWidth style={{marginBottom: "15px"}}>
                                            <TextField
                                                id="standard-multiline-static"
                                                label="MOM Quotation Stage"
                                                multiline
                                                rows={4}
                                                placeholder="Enter MOM Quotation Stage"
                                                value={momQuotationStage}
                                                onChange={(e) => setMomQuotationStage(e.target.value)}
                                                variant="standard"
                                            />
                                        </FormControl>
                                    </div>
                                }
                                {
                                    // site visit date, mom meeting stage, mom site visit stage
                                    stage === 'Site/Office visit' && 
                                    <div>
                                        <FormControl fullWidth style={{marginBottom: "15px"}}>
                                            <TextField
                                                id="siteVisit-Date"
                                                name={siteVisitDate}
                                                onChange={(e) => setSiteVisitDate(e.target.value)}
                                                label="Site Visit date"
                                                type="date"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </FormControl>
                                        <FormControl fullWidth style={{marginBottom: "15px"}}>
                                            <TextField
                                                id="standard-multiline-static"
                                                label="MOM Meeting Stage"
                                                multiline
                                                rows={4}
                                                placeholder="Enter MOM Meeting Stage"
                                                value={momMeetingStage}
                                                onChange={(e) => setMomMeetingStage(e.target.value)}
                                                variant="standard"
                                            />
                                        </FormControl>
                                        <FormControl fullWidth style={{marginBottom: "15px"}}>
                                            <TextField
                                                id="standard-multiline-static"
                                                label="MOM Site visit Stage"
                                                multiline
                                                rows={4}
                                                placeholder="Enter MOM Site visit Stage"
                                                value={momSiteVisitStage}
                                                onChange={(e) => setMomSiteVisitStage(e.target.value)}
                                                variant="standard"
                                            />
                                        </FormControl>
                                    </div>
                                }
                                {
                                    // Negotiation date, mom negotiation stage
                                    stage === 'Negotiation' && 
                                    <div>
                                        <FormControl fullWidth style={{marginBottom: "15px"}}>
                                            <TextField
                                                id="negotiation-Date"
                                                name={negotiationDate}
                                                onChange={(e) => setNegotiationDate(e.target.value)}
                                                label="Negotiation date"
                                                type="date"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </FormControl>
                                        <FormControl fullWidth style={{marginBottom: "15px"}}>
                                            <TextField
                                                id="standard-multiline-static"
                                                label="MOM Negotiation Stage"
                                                multiline
                                                rows={4}
                                                placeholder="Enter MOM Negotiation Stage"
                                                value={momNegotiationStage}
                                                onChange={(e) => setMomNegotiationStage(e.target.value)}
                                                variant="standard"
                                            />
                                        </FormControl>
                                    </div>
                                }
                                {
                                    // Closure date, Remark, client move in date
                                    stage === 'Won' && 
                                    <div>
                                        <FormControl fullWidth style={{marginBottom: "15px"}}>
                                            <TextField
                                                id="closure-Date"
                                                name={closureDate}
                                                onChange={(e) => setClosureDate(e.target.value)}
                                                label="Closure date"
                                                type="date"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </FormControl>
                                        <FormControl fullWidth style={{marginBottom: "15px"}}>
                                            <TextField
                                                id="standard-multiline-static"
                                                label="Remark"
                                                multiline
                                                rows={4}
                                                placeholder="Enter Remark"
                                                value={remark}
                                                onChange={(e) => setRemark(e.target.value)}
                                                variant="standard"
                                            />
                                        </FormControl>
                                        <FormControl fullWidth style={{marginBottom: "15px"}}>
                                            <TextField
                                                id="clientMovein-Date"
                                                name={clientMoveinDate}
                                                onChange={(e) => setClientMoveinDate(e.target.value)}
                                                label="Client Move in Date"
                                                type="date"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </FormControl>
                                    </div>
                                }
                                {
                                    // Contract Signed date, Project Completion date, WD Attachement
                                    stage === 'Contract Signed' && 
                                    <div>
                                        <FormControl fullWidth style={{marginBottom: "15px"}}>
                                            <TextField
                                                id="contractSigned-Date"
                                                name={contractSignedDate}
                                                onChange={(e) => setContractSignedDate(e.target.value)}
                                                label="Contract Signed Date"
                                                type="date"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </FormControl>
                                        <FormControl fullWidth style={{marginBottom: "15px"}}>
                                            <TextField
                                                id="projectCompletionDate-Date"
                                                name={projectCompletionDate}
                                                onChange={(e) => setProjectCompletionDate(e.target.value)}
                                                label="project Completion Date"
                                                type="date"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </FormControl>
                                        <div style={{ paddingBottom: 15, paddingTop: 10 }}>
                                            <input
                                                accept="*"
                                                className={classes.input}
                                                id="wdfile-document"
                                                onChange={uploadWDFileDocument}
                                                type="file"
                                            />
                                            <label htmlFor="wdfile-document">
                                                <Button variant="contained" color="primary" style={{ background: "#716ACA", margin: 5 }} component="span">
                                                    Upload Working Drawing File
                                                </Button>
                                            </label>
                                        </div>
                                    </div>
                                }
                                    {/* Select Department */}
                                    <FormControl fullWidth style={{marginBottom: "15px"}}>
                                        <InputLabel id="demo-simple-select-label">Select Department</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label1"
                                            id="demo-simple-select1"
                                            value={department}
                                            label="Select Stage"
                                            onChange={handleSelectedDepartmentChange}
                                        >
                                            {departmentLists.map((row, i) => {
                                                return (
                                                    <MenuItem key={i + 1} value={row._id}>{row.name}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                    {/* Select Team */}
                                    <FormControl fullWidth style={{marginBottom: "15px"}}>
                                        <InputLabel id="demo-simple-select-label2">Select Team</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label2"
                                            id="demo-simple-select2"
                                            value={team}
                                            label="Select Team"
                                            onChange={handleSelectedTeamChange}
                                        >
                                            {teamLists.map((row, i) => {
                                                return (
                                                    <MenuItem key={i + 1} value={row._id}>{row.name}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                    {/* Select Deal Owner User */}
                                    <FormControl fullWidth style={{marginBottom: "15px"}}>
                                        <InputLabel id="demo-simple-select-label3">Select User</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label3"
                                            id="demo-simple-select3"
                                            value={dealOwner}
                                            label="Select User"
                                            onChange={handleSelectedUserChange}
                                        >
                                            {dealOwnerLists.map((row, i) => {
                                                return (
                                                    <MenuItem key={i + 1} value={row._id}>{row.name}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                    
                                </Box>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleAnotherDialogClose}>Cancel</Button>
                                <Button onClick={assignProject}>Assign Project</Button>
                            </DialogActions>
                        </Dialog>
                    </Grid>
                </Grid>
            </Paper>
            <Paper>
                <TableContainer component={Paper}>
                    <Table className={classes.table} stickyHeader aria-label="sticky table">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell>Sr. No.</StyledTableCell>
                                <StyledTableCell>Project ID</StyledTableCell>
                                <StyledTableCell>Status</StyledTableCell>
                                <StyledTableCell>Stage</StyledTableCell>
                                <StyledTableCell>Type</StyledTableCell>
                                <StyledTableCell>Action</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                        {customerProjects.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
                            return (
                                <StyledTableRow key={i + 1}>
                                    <StyledTableCell>{i + 1}</StyledTableCell>
                                    <StyledTableCell component="th" scope="row"> {row?.projectNumber} </StyledTableCell>
                                    <StyledTableCell>{row?.status}</StyledTableCell>
                                    <StyledTableCell>{row?.stage}</StyledTableCell>
                                    <StyledTableCell>{row?.projectType}</StyledTableCell>
                                    <StyledTableCell>
                                        <Link to={`/project-logs/${row._id}`} className="btn btn-primary customerActionBtn">Logs</Link>
                                        <Link  to={`/manageQuotations/${row._id}`} className="btn btn-primary customerActionBtn">Quotation</Link>
                                        
                                    </StyledTableCell>
                                </StyledTableRow>
                            );
                        })}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 20, 50, { label: 'All', value: -1 }]}
                                    colSpan={10}
                                    count={customerProjects.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                    inputProps: { 'aria-label': 'rows per page' },
                                    native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    )
}

export default connect(null, { setLoading, setSnackInfo })(ManageQTCustomer)
