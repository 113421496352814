import React, { useEffect } from 'react';
import { getAllProjects } from '../redux/actions/projectAction'
import { connect } from 'react-redux'
import { setLoading, setSnackInfo } from '../redux/actions/appAction';
import { Grid, Paper, Divider, Typography, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import decorpotLogo from '../assets/img/org/logo-black-min.png'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: 20,
        padding: 10
    },
    newheading: {
        marginTop: 20,
        fontSize: 18
    },
    subtopic: {
        marginLeft: 20
    },
    subSubtopic: {
        marginLeft: 40
    },
    decorpot_logo1: {
        height: '80%',
        width: '80%'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));
const TermsAndCondition = (props) => {
    const classes = useStyles();
    useEffect(() => {
        // eslint-disable-next-line
    }, [])

    return (
        <div>
            <div style={{ marginBottom: 20, marginTop: 30 }}>
                <h2 style={{ textAlign: 'center' }}>Terms and conditions</h2>
            </div>
            <Divider />

            <div style={{ width: '100%' }}>
                <Paper>
                    <div className={classes.root}>
                        {/* <Grid item xs={12} >
                            <Grid container >
                                <Grid item xs={6} >
                                    <img className={classes.decorpot_logo1} src={decorpotLogo} alt="decorpot logo" />
                                </Grid>
                                <Grid item xs={6} style={{ float: "right", justifyContent: "end", position: "relative", right: "-185px" }}>
                                    17E, 2nd floor 18th cross, <br />
                                    Sector 4,HSR Layout,<br />
                                    Bangalore 560102<br />
                                    E-Mail: sales@decorpot.com<br />
                                    Phone: +91 91086 02000
                                </Grid>
                            </Grid>
                        </Grid> */}

                        <Grid item xs={12} style={{ marginBottom: 40}}>
                            <Grid container spacing={2} >
                                <Grid item xs={12} >
                                    <Typography variant="h6" gutterBottom className={classes.newheading}>
                                        1. Scope of services
                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom className={classes.subtopic}>
                                        a) Supplier agrees to provide interior design solution as per the detail specified in Quotation and 2D Drawings. Interior design solution shall include  materials, services and warranty services post deployment.
                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom className={classes.subtopic}>
                                        b) Supplier shall use the materials as per specification mentioned in Quotation  and 2D Drawings. In event of non-availability of the material of the specified make, supplier shallprovide suitable / comparable material. </Typography>
                                    <Typography variant="subtitle2" gutterBottom className={classes.subtopic}>
                                        c) Work will be done on the site as per the Final 2D working Drawings. 3D pictures shared are for only representation/visualization purpose only.
                                        Supplier doesn’t warrant conformity with the 3D pictures.
                                    </Typography>
                                    {/* <Typography variant="subtitle1" gutterBottom></Typography> */}

                                    <Typography variant="h6" gutterBottom className={classes.newheading}>
                                        2. Delivery
                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom>
                                        Supplier shall provide the design solutions, as mentioned in Quotation and 2D  Drawings within 60 days from the date all the below are conditions are satisfied by the Customer
                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom className={classes.subtopic}>
                                        (i) 60% payment of the overall contract amount,
                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom className={classes.subtopic}>
                                        (ii) Approval of 2D drawings,
                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom className={classes.subtopic}>
                                        (iii) Physical handover of site to the supplier for uninterrupted work
                                        commencements with all permissions, electricity etc.
                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom>
                                        Any delay in satisfying the above conditions would impact the delivery timelines and  the commencement date will change to include the same.
                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom>
                                        The committed Contract timeline can be met only if Supplier is the sole vendor working at the site during the entire contract duration. Involvement or presence of any other vendors in the site during the contract duration engaged by Customer or the Builder will impact the timeline and no committed delivery timelines or delay penalty will be applicable in such cases.
                                    </Typography>


                                    <Typography variant="h6" gutterBottom className={classes.newheading}>
                                        3. Payment and payment term
                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom className={classes.subtopic}>
                                        a) Customer hereby agrees to pay the amount mentioned in Quotation and 2D  Drawings, as full and final consideration for providing the design solution  specified in Quotation and 2D Drawings.
                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom className={classes.subtopic}>
                                        b) Any delay/reductions in making the payments will lead to the warranty and service visits under the contract becoming void, and delivery timelines will be changed to add the days of delay (if any) in making the payments. Till the time the final payments are made, the title of the work carried under this agreement remains with the supplier.
                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom className={classes.subtopic}>
                                        c) In case the modular materials are ready for delivery and the sites is not in a stage of work commencement, customer would pay the balance amount to Supplier on intimation of modular furniture readiness. The Supplier shall complete the work when the site is ready, as notified by the Customer
                                    </Typography>


                                    <Typography variant="h6" gutterBottom className={classes.newheading}>
                                        4. Access to site
                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom className={classes.subtopic}>
                                        a) Customer shall provide the Supplier reasonable; access to the site, Electricity, society pass to the premises for completing the installation and other service work. Any delay in providing access to the facility will result in delay in completion of the work.
                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom className={classes.subtopic}>
                                        b) In event of situation where the Supplier does not have access to the site or the site is not ready to commence the work, the contract start date will change to the date when site becomes ready for uninterrupted work commencement for the entire contract duration
                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom className={classes.subtopic}>
                                        c) Customer shall allow supplier to take photos of the completed work for supplier’s website and marketing purpose.
                                    </Typography>

                                    <Typography variant="h6" gutterBottom className={classes.newheading}>
                                        5. Warranty
                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom className={classes.subtopic}>
                                        
                                        a) Supplier hereby provides a warranty of 10 years from date of deployment, for all the fixed furniture (Woodwork, provided as part of Quotation and 2D Drawings).
                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom className={classes.subtopic}>
                                        b) All accessories, hardware and appliances are covered as per the respective Manufacturer’s Warranty Policy.
                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom className={classes.subtopic}>
                                        c) Supplier also warrants that it will perform services as per generally recognized standards and practices. As an exclusive remedy, Supplier shall rectify the furniture’s in event of any complaint received from the customer. In no event supplier will be held liable for any consequential or incidental losses.
                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom className={classes.subtopic}>
                                        d) This warranty shall be provided subject to the materials and furniture being used with due care and protection. Warranty shall be breached in the following situations: -
                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom className={classes.subSubtopic}>
                                        i. Physical damage to the furniture
                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom className={classes.subSubtopic}>
                                        ii. Damage during shifting of furniture
                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom className={classes.subSubtopic}>
                                        iii. Exposure of furniture to hot temperatures
                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom className={classes.subSubtopic}>
                                        iv. Damage due to water, flooding etc
                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom className={classes.subSubtopic}>
                                        v. Mishandling / using of the furniture for anything other than the desired purpose, which includes exposing the furniture to non-suitable chemicals.
                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom className={classes.subSubtopic}>
                                        vi. Bend on plywood panels
                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom className={classes.subSubtopic}>
                                        vii. Force majeure
                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom className={classes.subtopic}>
                                        e) Warranty will not cover normal wear and Tear due to the usage of the items provided or any glasses, mirror or similar/related products.
                                    </Typography>


                                    <Typography variant="h6" gutterBottom className={classes.newheading}>
                                        6. Service visits
                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom>
                                        Services visits for any work, outside the scope of warranty, e.g.- Normal wear and  tear etc;
                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom className={classes.subtopic}>
                                        a. Supplier hereby also agrees to provide service visits by qualified technician at  the location of the customer, where furniture is deployed only upon intimation by the Customer.
                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom className={classes.subtopic}>
                                        b. Service visits would be charged at the rate of Rupees two thousand (Rs. 2,000 only) per visit.
                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom className={classes.subtopic}>
                                        c. It is hereby clarified that cost of any material replacement not covered by warranty would have to be borne by the Customer.
                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom className={classes.subtopic}>
                                        d. Any desired changes to the service date need to be advised to the supplier beforehand.
                                    </Typography>


                                    <Typography variant="h6" gutterBottom className={classes.newheading}>
                                        7. Changes to scope of work
                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom className={classes.subtopic}>
                                        a) Supplier and customer both acknowledge that there may be additions to the scope of work agreed in Quotation and 2D Drawings.
                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom className={classes.subtopic}>
                                        b) Supplier agrees to carry out the incremental work for suitable prices, as mutually agreed between the Customer and the Supplier.
                                    </Typography>

                                    <Typography variant="h6" gutterBottom className={classes.newheading}>
                                        8. Delay Penalty
                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom>
                                        In case of delay in handover of house, from the committed time line the supplier will be liable to compensate the customer with the below penalty, post a penalty free grace period of 15 days:
                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom>
                                        Indian Rupees five per square feet of the fixed furniture, supplied by the supplier and charged in the quote, for every completed month of delay.
                                    </Typography>


                                    <Typography variant="h6" gutterBottom className={classes.newheading}>
                                        The applicability of penalty, will be subject to the following:
                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom className={classes.subtopic}>
                                        a) The delay is solely attributable to the supplier
                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom className={classes.subtopic}>
                                        b) The penalty clause will become void if the customers has not made the payments on time or not fulfilled other obligations under this contract like access to site on all days, during working hours.
                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom className={classes.subtopic}>
                                        c) The due date of completion is calculated from the day final 2D drawings are approved by the customer and adding the time required for completion as agreed in this contract.
                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom className={classes.subtopic}>
                                        d) Any changes or additional scope from the agreed 2D and quotation will add on to the completion date and hence the penalty completion
                                    </Typography>

                                    <Typography variant="h6" gutterBottom className={classes.newheading}>
                                        9. Legal Jurisdiction
                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom className={classes.subtopic}>
                                        a) The parties shall use their best endeavor to settle amicably amongst
                                        themselves any and all disputes arising out of or the interpretation thereof. Any outstanding dispute shall be settling by sole Arbitration appointed by the Company in accordance with the arbitration act 1996 the venue for
                                        arbitration shall be Bangalore and language shall be English. The cost of the said proceeding shall be borne equally by the parties. However, each party shall bear its own expenses in the prosecution or be defending a claim in the event of the claim by a party is frivolous, substantially or without merit, such party shall reimburse the cost of the other party.

                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom className={classes.subtopic}>
                                        b) All disputes are subject to Bengaluru Jurisdiction only
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* <Grid item xs={12} style={{ marginTop: 30 }}>
                            <Grid container spacing={2} style={{ textAlign: "center", marginBottom: 40 }}>
                                <Grid item xs={6} >
                                    <p>CUSTOMER SIGNATURE</p>
                                    <TextField id="standard-basic" />
                                </Grid>
                                <Grid item xs={6} style={{ float: "right" }}>
                                    <p>SUPPLIER SIGNATURE</p>
                                    <TextField id="standard-basic" />
                                </Grid>
                            </Grid>
                        </Grid> */}
                    </div>
                </Paper>
            </div>
        </div>
    )
}

export default connect(null, { setLoading, getAllProjects, setSnackInfo })(TermsAndCondition)
