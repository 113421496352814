import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
// import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import { setLoading, setSnackInfo } from '../redux/actions/appAction';
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow, TableHead, Divider, Typography, Accordion, AccordionSummary, AccordionDetails, Checkbox } from '@material-ui/core';
import { ROLENAME, LEADSTATUS, EXPCENTERID, LOCATIONID } from '../utils/index';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { commaSeparateAmount } from './commaSeparate';
import HttpService from '../services/httpService'
// import moment from 'moment'
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select/Select';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import Input from '@material-ui/core/Input';
import ListItemText from '@material-ui/core/ListItemText';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    tablealign: {
        display: "flex"
    },
    tablecontainer: {
        display: "flex",
        marginTop: "25px"
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightBold,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    table: {
        textAlign: 'center'
    }
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const CalenderFilterData = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const [leadsOnSelectedDate, setLeadsOnSelectedDate] = useState([]);
    const [salesStageLeadOnSelectedDate, setSalesStageLeadOnSelectedDate] = useState([]);
    const [canEdit, setCanEdit] = useState(props.editable)
    const { userDetails } = useSelector(state => state.user)
    const [locationExpCenter, setLocationExpCenter] = useState(props.locationExperienceCenterList);
    const [totalValue, setTotalValue] = useState(0)
    const [salesDataval, setSalesDataval] = useState([])
    const [sortedData, setSortedData] = useState([])
    const [totalCol, setTotalCol] = useState([]);
    const [rowTotal, setRowTotal] = useState([]);
    const [selectedExpcenters, setSelectedExpcenters] = useState([]);
    const [text, setText] = useState("Site/Office visit");
    const [decode,setDecode] = useState("")
    const [dumpExpCenters, setDumpExpcenters] = useState([]);
    const [TokenPercentage, setTokenPercentage] = useState("")
    useEffect(() => {
        setCanEdit(props.editable)
        checkUserRole(props.role)
        setLocationExpCenter(props.locationExperienceCenterList)
        handleEncode();
        // yearWiseSlectedExpCenterData()
        getExperienceCenters();
        // eslint-disable-next-line
    }, [props, props.role])

    const handleEncode = () => {
        const encodedText = encodeURIComponent(text);
        setDecode(encodedText)
      };    


    const checkUserRole = (roleid) => {
        if (roleid === ROLENAME.MarketingUser || roleid === ROLENAME.MarketingManager) {
            getLeadsOnSelectedDates(props.marketingdata);
        } else if ((roleid === ROLENAME.SalesManager || roleid === ROLENAME.DGM_Sales || roleid === ROLENAME.AssistantSalesManager|| roleid === ROLENAME.SalesHead)) {
            getSalesLeadOnSelectedDates(props.salesdata);
            //console.log(props.salesdata)
             ;
            var lastTotalValue = 0
            for (let i = 0; i < props.salesdata.length-2;i++){
                lastTotalValue += Number(props.salesdata[i].stage.Total) 
            }
            setTotalValue(lastTotalValue)
        } else if (roleid === ROLENAME.SalesUser) {
            getSalesLeadOnSelectedDates(props.salesdata);
        } else {
            separeteLeadStatusWise(props.selectedUserDetails);
        }
    }

    const getLeadsOnSelectedDates = async (data) => {
        let finalData = [];
        Object.keys(data).forEach(function (key) {
            if (props.selectedexpcenter !== 'All') {
                var value = data[props.selectedexpcenter];
                separeteLeadStatusWise(value);
            } else {
                locationExpCenter.forEach((ele, i) => {
                    if (ele.name === key) {
                        finalData.push({
                            expcenter: key,
                            data: data[key]
                        })
                        separeteLeadStatusWise(finalData);
                    }
                })
            }
        });
    }

    const getSalesLeadOnSelectedDates = async (data) => {
        separeteLeadStatusWise(data);
    }

    const [finalStatusData1, setFinalStatusData] = useState([]);

    const separeteLeadStatusWise = (data) => {
        // if (data.length === 0) {
        //     setLeadsOnSelectedDate([])
        // }
        if (props.role === ROLENAME.MarketingManager || props.role === ROLENAME.MarketingUser) {
            if (props.selectedexpcenter !== 'All') {
                let centerList = [];
                centerList.push({
                    expcenter: props.selectedexpcenter
                })
                setLeadsOnSelectedDate(centerList)

                let leadStatus = [];
                leadStatus.push(
                    { name: LEADSTATUS.RealLead },
                    { name: LEADSTATUS.JunkLead },
                    { name: LEADSTATUS.HoldLead },
                    { name: LEADSTATUS.TBCLead },
                    { name: LEADSTATUS.TotalLeadsGenerated },
                    { name: 'sales' }
                )

                let finalStatusData = [];
                let teamData = [];
                for (let j = 0; j < leadStatus.length; j++) {
                    let obj1 = {};
                    if (leadStatus[j].name === 'Real') {
                        obj1.name = 'Real';
                        obj1[props.selectedexpcenter] = data?.realLeadCount ? data?.realLeadCount : `0`
                    }
                    if (leadStatus[j].name === 'Junk') {
                        obj1.name = 'Junk';
                        obj1[props.selectedexpcenter] = data?.junkLeadCount
                    }
                    if (leadStatus[j].name === 'Hold') {
                        obj1.name = 'Hold';
                        obj1[props.selectedexpcenter] = data?.onHoldCount
                    }
                    if (leadStatus[j].name === 'To Be Called') {
                        obj1.name = 'To Be Called';
                        obj1[props.selectedexpcenter] = data?.toBeCalledCount
                    }
                    if (leadStatus[j].name === 'Total Leads Generated') {
                        obj1.name = 'Total Leads Generated';
                        obj1[props.selectedexpcenter] = data?.totalLeadsGenerated
                    }
                    if (leadStatus[j].name === 'sales') {
                        obj1.name = 'Sales';
                        let totalSalesTeamCount = 0;
                        for (let k = 0; k < data?.teamsAssignedCount.length; k++) {
                            if (data?.teamsAssignedCount[k].hasOwnProperty('leadCount')) {
                                totalSalesTeamCount += data?.teamsAssignedCount[k].leadCount;
                                obj1[props.selectedexpcenter] = isNaN(totalSalesTeamCount) ? `0` : totalSalesTeamCount;
                            }
                            if (data?.teamsAssignedCount[k].leadCount !== undefined) {
                                let ss = {
                                    leadCount: data?.teamsAssignedCount[k].leadCount,
                                    name: data?.teamsAssignedCount[k].teamName,
                                    teamId: data?.teamsAssignedCount[k]._id,
                                    expcenter: props.selectedexpcenter
                                }
                                teamData.push(ss)
                            }
                        }
                    }
                    finalStatusData.push(obj1);
                }
                let teamArr = [];
                teamData.forEach((ele) => {
                    let total = 0;
                    let finalObj = {
                        name: ele.name,
                        teamId: ele.teamId,
                        Gachibowli: ele.expcenter === 'Gachibowli' ? total += ele.leadCount : `0`,
                        HSR: ele.expcenter === 'HSR' ? total += ele.leadCount : `0`,
                        Whitefield: ele.expcenter === 'Whitefield' ? total += ele.leadCount : `0`
                    }
                    teamArr.push(finalObj);
                })
                let myFinal = [...finalStatusData, ...teamArr]
                setFinalStatusData(myFinal);
            } else {
                let data1 = []
                let centers = []
                data.forEach(ele => {
                    centers.push(ele.expcenter)
                    data1.push({
                        expcenter: ele.expcenter,
                        'Real': ele?.data?.realLeadCount,
                        'Junk': ele?.data?.junkLeadCount,
                        'Hold': ele?.data?.onHoldCount,
                        'To Be Called': ele?.data?.toBeCalledCount,
                        'Total Leads Generated': ele?.data?.totalLeadsGenerated,
                        'sales': ele?.data?.teamsAssignedCount
                    })
                    setLeadsOnSelectedDate(data1.sort((a, b) => a.expcenter.localeCompare(b.expcenter)))
                })
                let leadStatus = [];

                leadStatus.push(
                    { name: LEADSTATUS.RealLead },
                    { name: LEADSTATUS.JunkLead },
                    { name: LEADSTATUS.HoldLead },
                    { name: LEADSTATUS.TBCLead },
                    { name: LEADSTATUS.TotalLeadsGenerated },
                    { name: 'sales' }
                )
                let finalStatusData = [];
                let teamData = [];
                for (let j = 0; j < leadStatus.length; j++) {
                    let obj1 = {};

                    for (let i = 0; i < data1.length; i++) {
                        if (leadStatus[j].name === 'Real') {
                            obj1.name = 'Real';
                            obj1[data1[i].expcenter] = data1[i]['Real']
                        }
                        if (leadStatus[j].name === 'Junk') {
                            obj1.name = 'Junk';
                            obj1[data1[i].expcenter] = data1[i]['Junk']
                        }
                        if (leadStatus[j].name === 'Hold') {
                            obj1.name = 'Hold';
                            obj1[data1[i].expcenter] = data1[i]['Hold']
                        }
                        if (leadStatus[j].name === 'To Be Called') {
                            obj1.name = 'To Be Called';
                            obj1[data1[i].expcenter] = data1[i]['To Be Called']
                        }
                        if (leadStatus[j].name === 'Total Leads Generated') {
                            obj1.name = 'Total Leads Generated';
                            obj1[data1[i].expcenter] = data1[i]['Total Leads Generated']
                        }

                        if (leadStatus[j].name === 'sales') {
                            obj1.name = 'Sales';
                            let totalSalesTeamCount = 0;
                            for (let k = 0; k < data1[i]['sales'].length; k++) {
                                if (data1[i]['sales'][k].hasOwnProperty('leadCount')) {
                                    totalSalesTeamCount += data1[i]['sales'][k].leadCount;
                                    obj1[data1[i].expcenter] = isNaN(totalSalesTeamCount) ? `0` : totalSalesTeamCount;
                                }
                                if (data1[i]['sales'][k].leadCount !== undefined) {
                                    let ss = {
                                        leadCount: data1[i]['sales'][k].leadCount,
                                        name: data1[i]['sales'][k].teamName,
                                        teamId: data1[i]['sales'][k]._id,
                                        expcenter: data1[i].expcenter
                                    }
                                    teamData.push(ss)
                                }
                            }
                        }
                    }
                    finalStatusData.push(obj1);
                }

                let teamArr = [];
                teamData.forEach((ele) => {
                    let total = 0;
                    let finalObj = {
                        name: ele.name,
                        teamId: ele.teamId,
                        Gachibowli: ele.expcenter === 'Gachibowli' ? total += ele.leadCount : `0`,
                        HSR: ele.expcenter === 'HSR' ? total += ele.leadCount : `0`,
                        Whitefield: ele.expcenter === 'Whitefield' ? total += ele.leadCount : `0`
                    }
                    teamArr.push(finalObj);
                })
                let myFinal = [...finalStatusData, ...teamArr]
                setFinalStatusData(myFinal);
            }
        }

        if (props.role === ROLENAME.SalesManager || props.role === ROLENAME.DGM_Sales || props.role === ROLENAME.AssistantSalesManager|| props.role === ROLENAME.SalesHead) {
            let finalData = [];
            let stagesFinalData = [];
            for (let j = 0; j < data.length; j++) {
                let obj = {};
                obj.salesUser = data[j].name;
                obj.salesId = data[j].userId;
                let statusNamesList = []

                Object.keys(data[j]).forEach(st => {
                    statusNamesList.push(st)
                })

                if (data[j]['name'] !== 'topInfo') {
                    for (let i = 0; i < statusNamesList.length; i++) {
                        if (statusNamesList[i] !== 'stage') {
                            let views = data[j][statusNamesList[i]]
                            if (typeof (views) === 'object') {
                                obj[statusNamesList[i]] = {
                                    value: data[j][statusNamesList[i]].value,
                                    count: data[j][statusNamesList[i]].count
                                }
                            }
                        }
                    }
                    finalData.push(obj)
                }

                // Stage data
                let stageObj = {};
                stageObj.name = data[j].name;
                stageObj.salesId = data[j].userId;
                stageObj.stages = data[j].stage;
                stageObj.teamId = data[j].teamId;
                if (stageObj.name !== undefined && stageObj.salesId !== undefined) {
                    stagesFinalData.push(stageObj)
                }
            }
            let stageCountFinal = [];
            let stageCount = {};
            stageCount.name = 'Total';
            stageCount.stages = {}
            stageCount.stages['Lead Received'] = 0;
            stageCount.stages['Pre - Sales call'] = 0;
            stageCount.stages['Sales Call'] = 0;
            stageCount.stages['Quotation Sent'] = 0;
            stageCount.stages['Site/Office visit'] = 0;
            stageCount.stages['Negotiation'] = 0;
            stageCount.stages['Won'] = 0;
            stageCount.stages['Won Deals Pending Designer Assignment'] = 0;
            stageCount.stages['Lost'] = 0;
            stageCount.stages['Delayed Requirement'] = 0;
            stagesFinalData.forEach((ele, i) => {
                stageCount.stages['Lead Received'] += ele.stages['Lead Received'];
                stageCount.stages['Pre - Sales call'] += ele.stages['Pre - Sales call'];
                stageCount.stages['Sales Call'] += ele.stages['Sales Call'];
                stageCount.stages['Quotation Sent'] += ele.stages['Quotation Sent'];
                stageCount.stages['Site/Office visit'] += ele.stages['Site/Office visit'];
                stageCount.stages['Negotiation'] += ele.stages['Negotiation'];
                stageCount.stages['Won'] += ele.stages['Won'];
                stageCount.stages['Won Deals Pending Designer Assignment'] += ele.stages['Won Deals Pending Designer Assignment'];
                stageCount.stages['Lost'] += ele.stages['Lost'];
                stageCount.stages['Delayed Requirement'] += ele.stages['Delayed Requirement'];
            })
            stageCountFinal.push(stageCount)
            let finalStageCountAndStages = [...stagesFinalData, ...stageCountFinal];
            setLeadsOnSelectedDate(finalData)
            setSalesStageLeadOnSelectedDate(finalStageCountAndStages)
        }

        if (props.role === ROLENAME.SalesUser) {
            let statusNames = []
            Object.keys(data).forEach(st => {
                statusNames.push(st)
            })
            let finalData = [];
            let stageFinalData = [];
            for (let i = 0; i < statusNames.length; i++) {
                if (statusNames[i] !== 'stage') {
                    let views = data[statusNames[i]]
                    if (statusNames[i] !== 'info') {
                        finalData.push({
                            'status': statusNames[i],
                            'value': views.value,
                            'count': views.count
                        })
                    }
                }
            }
            setLeadsOnSelectedDate(finalData)

            // Stage data
            stageFinalData.push({
                stages: data.stage
            })
            setSalesStageLeadOnSelectedDate(stageFinalData)
        }
    }

    const changePageRoute = (data, centerId) => {
        console.log(centerId, 'center name', props.selectedexpcenter === 'All');
        if (props.selectedexpcenter === 'All') {
            if (data.teamId) {
                history.push(`/status-information/${data.name.replace(/[\s+%/\\#,+$~.'":*?<>{}]/g, '-')}/${props.selecteddate.start}/${props.selecteddate.end}/${data.teamId}/false`, {
                    experienceCenterId: centerId,
                    locationId: props.selectedlocationid
                });
            } else {
                history.push(`/status-information/${data.name.replace(/[\s+%/\\#,+$~.'":*?<>{}]/g, '-')}/${props.selecteddate.start}/${props.selecteddate.end}/status/false`, {
                    experienceCenterId: centerId,
                    locationId: props.selectedlocationid
                });
            }
        } else {
            if (data.teamId) {
                history.push(`/status-information/${data.name.replace(/[\s+%/\\#,+$~.'":*?<>{}]/g, '-')}/${props.selecteddate.start}/${props.selecteddate.end}/${data.teamId}/false`, {
                    experienceCenterId: props.selectedexpcenterid,
                    locationId: props.selectedlocationid
                });
            } else {
                history.push(`/status-information/${data.name.replace(/[\s+%/\\#,+$~.'":*?<>{}]/g, '-')}/${props.selecteddate.start}/${props.selecteddate.end}/status/false`, {
                    experienceCenterId: props.selectedexpcenterid,
                    locationId: props.selectedlocationid
                });
            }
        }
    }

    const changeSalesPageRoute = (data, status, user) => {
        if (props.role === ROLENAME.SalesManager || props.role === ROLENAME.DGM_Sales || props.role === ROLENAME.AssistantSalesManager) {
        if (user !== 'NA') {
            history.push(`/status-information/${status}/${props.selecteddate.start}/${props.selecteddate.end}/status/${user}`, {
                experienceCenterId: props.selectedexpcenterid,
                locationId: props.selectedlocationid,
                filterOption: props.filteroption
            });
        } else {
                history.push(`/status-information/${status}/${props.selecteddate.start}/${props.selecteddate.end}/status/${user}`, {
                    experienceCenterId: props.selectedexpcenterid,
                    locationId: props.selectedlocationid,
                    filterOption: props.filteroption,
                });
            }
        } else {
            history.push(`/status-information/${data.status}/${props.selecteddate.start}/${props.selecteddate.end}/status/${userDetails._id}`, {
                experienceCenterId: props.selectedexpcenterid,
                locationId: props.selectedlocationid,
                filterOption: props.filteroption
            });
        }
    }

    const changeSalesStagePageRoute = (stage, teamId, assignToStatus) => {
        if (stage === 'Won Deals Pending Designer Assignment') {
            history.push(`/designer-assignment/Won-Deals-Pending-Designer-Assignment/${props.selecteddate.start}/${props.selecteddate.end}/${teamId}/user`, {
                experienceCenterId: props.selectedexpcenterid,
                locationId: props.selectedlocationid,
                filterOption: props.filteroption
            });
        } else if (props.role === ROLENAME.DGM_Sales) {
            history.push(`/status-information/${stage}/${props.selecteddate.start}/${props.selecteddate.end}/salesHead/${teamId}`, {
                experienceCenterId: props.selectedexpcenterid,
                locationId: props.selectedlocationid,
                filterOption: props.filteroption
            });
        } else {
            history.push(`/status-information/${stage}/${props.selecteddate.start}/${props.selecteddate.end}/salesuser/${teamId}`, {
                experienceCenterId: props.selectedexpcenterid,
                locationId: props.selectedlocationid,
                filterOption: props.filteroption
            });
        }
    }

    const viewSelectedStageLeads = (stage) => {
        history.push(`/Sales-lead-wise-total-data`, {
            experienceCenterId: props.selectedexpcenterid,
            salesStage:stage,
            locationId: props.selectedlocationid,
            filterOption: props.filteroption,
            startDate: props.selecteddate.start,
            endDate: props.selecteddate.end
        });
    }


    // const yearWiseSlectedExpCenterData = async () => {
    //     try {
    //         let exparr = []
    //         let dataquery = {}
    //         exparr.push(userDetails.selectedExpCenter)
    //         dataquery.experienceCenterId = exparr
    //         const response = await HttpService.yearWiseSlectedExpCenterData(dataquery)
    //         setSalesDataval(response.data);
    //         let newarray = []
    //         let grandTotal = 0;
    //         let grandcount = 0;
    //         for (let i = 0; i < response.data.length; i++) {
    //             let total = 0
    //             let count = 0
    //             for (let j = 0; j < response.data[i].length; j++) {

    //                 total += response.data[i][j].totalValue
    //                 count += response.data[i][j].count

    //             }
    //             grandTotal += total;
    //             grandcount += count
    //             newarray.push({
    //                 total: total,
    //                 count: count
    //             })
    //         }
    //         newarray.push({
    //             total: grandTotal,
    //             count: grandcount
    //         })
    //         setTotalCol(newarray)

    //         let array = []
    //         for (let i = 0; i < response.data.length; i++) {
    //             let sortedDates = response.data[i].sort((p1, p2) => (p1.prio > p2.prio) ? 1 : (p1.prio < p2.prio) ? -1 : 0);
    //             let sortedTeams = sortedDates.sort((p1, p2) => (p1.teamId > p2.teamId) ? 1 : (p1.teamId < p2.teamId) ? -1 : 0);


    //             let totalValue = 0;
    //             let totalCount = 0
    //             for (let i = 0; i < sortedTeams.length; i++) {
    //                 if (sortedTeams[i].roles[0] === "Sales User") {
    //                     totalValue += sortedTeams[i].totalValue
    //                     totalCount += sortedTeams[i].count
    //                 } else if (sortedTeams[i].roles[0] === "Sales Manager") {
    //                     sortedTeams[i].totalValue += totalValue
    //                     sortedTeams[i].count += totalCount
    //                     totalValue = 0
    //                     totalCount = 0
    //                 }
    //             }
    //             array.push(sortedTeams)
    //         }

    //         let rowCount = countData(array)
    //         let rowValue = ValueData(array)
    //         let rowTotal = []
    //         for (let k = 0; k < rowCount.length; k++) {
    //             rowTotal.push(
    //                 {
    //                     count: rowCount[k],
    //                     value: rowValue[k]
    //                 }
    //             )
    //         }
    //         setRowTotal(rowTotal)
    //         setSortedData(array)

    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    const countData = (array) => {
        const newArray = [];
        array.forEach(sub => {
            sub.forEach((num, index) => {
                if (newArray[index]) {
                    newArray[index] += num.count;
                } else {
                    newArray[index] = num.count;
                }
            });
        });
        return newArray;
    }

    const ValueData = (array) => {
        const newArray = [];
        array.forEach(sub => {
            sub.forEach((num, index) => {
                if (newArray[index]) {
                    newArray[index] += num.totalValue;
                } else {
                    newArray[index] = num.totalValue;
                }
            });
        });
        return newArray;
    }

    const getSelectedExpCenters = (e) => {
        setSelectedExpcenters(e.target.value);
    }

    const getExperienceCenters = async () => {
        props.setLoading(true)
        try {
            const response = await HttpService.getExpericenCenters()
            setDumpExpcenters(response.data)
        } catch (error) {
            props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }
    const [checked, setChecked] = useState(false);

    const toggleChecked = () => {
        setChecked((prev) => !prev);
    };
    
    // const applyFilter = async () => {
    //     let selectedCenter = [];
    //     let query = {}
    //     if (selectedExpcenters.length !== 0) {
    //         dumpExpCenters.forEach((ele, i) => {
    //             if (selectedExpcenters.includes(ele.name)) {
    //                 selectedCenter.push(ele._id)
    //             }
    //         })
    //         query.experienceCenterId = selectedCenter
    //     }else if(selectedExpcenters.length === 0){
    //         let exparr = []
    //         exparr.push(userDetails.selectedExpCenter)
    //         query.experienceCenterId = exparr
    //     }

    //     if(checked){
    //     query.TokenPercentage =  "Above-5%"
    //     }
    //     props.setLoading(true)
    //     try {
    //         const response = await HttpService.yearWiseSlectedExpCenterData(query)
    //         setSalesDataval(response.data);
    //         let newarray = []
    //         let grandTotal = 0;
    //         let grandcount = 0;
    //         for (let i = 0; i < response.data.length; i++) {
    //             let total = 0
    //             let count = 0
    //             for (let j = 0; j < response.data[i].length; j++) {

    //                 total += response.data[i][j].totalValue
    //                 count += response.data[i][j].count

    //             }
    //             grandTotal += total;
    //             grandcount += count
    //             newarray.push({
    //                 total: total,
    //                 count: count
    //             })
    //         }
    //         newarray.push({
    //             total: grandTotal,
    //             count: grandcount
    //         })
    //         setTotalCol(newarray)

    //         let array = []
    //         for (let i = 0; i < response.data.length; i++) {
    //             let sortedDates = response.data[i].sort((p1, p2) => (p1.prio > p2.prio) ? 1 : (p1.prio < p2.prio) ? -1 : 0);
    //             let sortedTeams = sortedDates.sort((p1, p2) => (p1.teamId > p2.teamId) ? 1 : (p1.teamId < p2.teamId) ? -1 : 0);


    //             let totalValue = 0;
    //             let totalCount = 0
    //             for (let i = 0; i < sortedTeams.length; i++) {
    //                 if (sortedTeams[i].roles[0] === "Sales User") {
    //                     totalValue += sortedTeams[i].totalValue
    //                     totalCount += sortedTeams[i].count
    //                 } else if (sortedTeams[i].roles[0] === "Sales Manager") {
    //                     sortedTeams[i].totalValue += totalValue
    //                     sortedTeams[i].count += totalCount
    //                     totalValue = 0
    //                     totalCount = 0
    //                 }
    //             }
    //             array.push(sortedTeams)
    //         }

    //         let rowCount = countData(array)
    //         let rowValue = ValueData(array)
    //         let rowTotal = []
    //         for (let k = 0; k < rowCount.length; k++) {
    //             rowTotal.push(
    //                 {
    //                     count: rowCount[k],
    //                     value: rowValue[k]
    //                 }
    //             )
    //         }
    //         setRowTotal(rowTotal)
    //         setSortedData(array)

    //     } catch (error) {
    //         props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    //     }
    //     props.setLoading(false)

    // }
    const clearFilter = () => {
        setSelectedExpcenters([])
        setTokenPercentage("")
    }

    return (
        <div>
            {/* Section for Select Date */}
            <div style={{ marginTop: 25, marginBottom: 25 }}>


                {/* Section for to show the records on selected date */}
                {
                    (props.role === ROLENAME.MarketingManager || props.role === ROLENAME.MarketingUser) &&
                    <Paper className={classes.paper}>
                        <TableContainer component={Paper} >
                            <Table className={classes.table} size="small" stickyHeader aria-label="sticky table">
                                <TableHead style={{ textAlign: 'center' }}>
                                    <TableRow>
                                        <TableCell align="center">Status</TableCell>
                                        {
                                            leadsOnSelectedDate.map((row, i) => {
                                                return (
                                                    <TableCell align="center">{row.expcenter}</TableCell>
                                                )
                                            })
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        finalStatusData1.map((status, i) => {
                                            return (
                                                <TableRow>
                                                    <TableCell component="th" scope="row" style={{ fontWeight: status.name === 'Sales' ? 700 : 0, padding: 10, width: 500, textAlign: 'center' }} >
                                                        {status.name}
                                                    </TableCell>
                                                    {
                                                        (props.selectedexpcenter === 'All' && props.selectedLocation === LOCATIONID.bangalore) && <>

                                                            <TableCell component="th" scope="row" style={{ fontWeight: status.name === 'Sales' ? 700 : 0, padding: 10, width: 500, textAlign: 'center' }}>
                                                                {
                                                                    status.name !== 'Sales' && canEdit === true && status.Whitefield !== 0 ?
                                                                        <Button onClick={() => changePageRoute(status, EXPCENTERID.whiltefield)} color="primary">
                                                                            {(status.Whitefield !== 'undefined' || status.Whitefield !== '') && status.hasOwnProperty('Whitefield') ? status.Whitefield : `0`}
                                                                        </Button>
                                                                        :
                                                                        <> {(status.Whitefield !== 'undefined' || status.Whitefield !== '') && status.hasOwnProperty('Whitefield') ? status.Whitefield : `0`} </>
                                                                }
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" style={{ fontWeight: status.name === 'Sales' ? 700 : 0, padding: 10, width: 500, textAlign: 'center' }}>
                                                                {
                                                                    status.name !== 'Sales' && canEdit === true && status.HSR !== 0 ?
                                                                        <Button onClick={() => changePageRoute(status, EXPCENTERID.hsr)} color="primary">
                                                                            {(status.HSR !== 'undefined' || status.HSR !== '') && status.hasOwnProperty('HSR') ? status.HSR : `0`}
                                                                        </Button>
                                                                        :
                                                                        <> {(status.HSR !== 'undefined' || status.HSR !== '') && status.hasOwnProperty('HSR') ? status.HSR : `0`} </>
                                                                }
                                                            </TableCell>
                                                        </>
                                                    }
                                                    {
                                                        (props.selectedexpcenter === 'All' && props.selectedLocation === LOCATIONID.hyderabad) && <>
                                                            <TableCell component="th" scope="row" style={{ fontWeight: status.name === 'Sales' ? 700 : 0, padding: 10, width: 500, textAlign: 'center' }}>
                                                                {
                                                                    status.name !== 'Sales' && canEdit === true && status.Gachibowli !== 0 ?
                                                                        <Button onClick={() => changePageRoute(status, EXPCENTERID.Gachibowli)} color="primary">
                                                                            {(status.Gachibowli !== 'undefined' || status.Gachibowli !== '') && status.hasOwnProperty('Gachibowli') ? status.Gachibowli : `0`}
                                                                        </Button>
                                                                        :
                                                                        <> {(status.Gachibowli !== 'undefined' || status.Gachibowli !== '') && status.hasOwnProperty('Gachibowli') ? status.Gachibowli : `0`} </>
                                                                }
                                                            </TableCell>
                                                        </>
                                                    }

                                                    {
                                                        props.selectedexpcenter === 'HSR' &&
                                                        <TableCell component="th" scope="row" style={{ fontWeight: status.name === 'Sales' ? 700 : 0, padding: 10, width: 500, textAlign: 'center' }}>
                                                            {
                                                                status.name !== 'Sales' && canEdit === true && status.HSR !== 0 ?
                                                                    <Button onClick={() => changePageRoute(status, EXPCENTERID.hsr)} color="primary">
                                                                        {(status.HSR !== 'undefined' || status.HSR !== '') && status.hasOwnProperty('HSR') ? status.HSR : `0`}
                                                                    </Button>
                                                                    :
                                                                    <> {(status.HSR !== 'undefined' || status.HSR !== '') && status.hasOwnProperty('HSR') ? status.HSR : `0`} </>
                                                            }
                                                        </TableCell>
                                                    }

                                                    {
                                                        props.selectedexpcenter === 'Whitefield' &&

                                                        <TableCell component="th" scope="row" style={{ fontWeight: status.name === 'Sales' ? 700 : 0, padding: 10, width: 500, textAlign: 'center' }}>
                                                            {
                                                                status.name !== 'Sales' && canEdit === true && status.Whitefield !== 0 ?
                                                                    <Button onClick={() => changePageRoute(status, EXPCENTERID.Whitefield)} color="primary">
                                                                        {(status.Whitefield !== 'undefined' || status.Whitefield !== '') && status.hasOwnProperty('Whitefield') ? status.Whitefield : `0`}
                                                                    </Button>
                                                                    :
                                                                    <> {(status.Whitefield !== 'undefined' || status.Whitefield !== '') && status.hasOwnProperty('Whitefield') ? status.Whitefield : `0`} </>
                                                            }
                                                        </TableCell>
                                                    }
                                                    {
                                                        props.selectedexpcenter === 'Gachibowli' &&

                                                        <TableCell component="th" scope="row" style={{ fontWeight: status.name === 'Sales' ? 700 : 0, padding: 10, width: 500, textAlign: 'center' }}>
                                                            {
                                                                status.name !== 'Sales' && canEdit === true && status.Gachibowli !== 0 ?
                                                                    <Button onClick={() => changePageRoute(status, EXPCENTERID.Gachibowli)} color="primary">
                                                                        {(status.Gachibowli !== 'undefined' || status.Gachibowli !== '') && status.hasOwnProperty('Gachibowli') ? status.Gachibowli : `0`}
                                                                    </Button>
                                                                    :
                                                                    <> {(status.Gachibowli !== 'undefined' || status.Gachibowli !== '') && status.hasOwnProperty('Gachibowli') ? status.Gachibowli : `0`} </>
                                                            }
                                                        </TableCell>
                                                    }
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                }
                {
                    (props.role === ROLENAME.SalesManager || props.role === ROLENAME.DGM_Sales || props.role === ROLENAME.AssistantSalesManager ||props.role === ROLENAME.SalesHead ) && <>
                        {/* Status wise data for all Sales Executive */}
                        <Paper className={classes.paper}>
                            <Accordion defaultExpanded>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className={classes.heading}>
                                        {props.role === ROLENAME.SalesManager || props.role === ROLENAME.AssistantSalesManager ?
                                            `Team Member wise data (*Please select the "Lead Created At" option to see meaningful data)`
                                            :
                                            `All Sales executive data (*Please select the "Lead Created At" option to see meaningful data)`}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails style={{ padding: 0 }}>
                                    <TableContainer component={Paper}>
                                        <Table className={classes.table} stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow >
                                                    <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Sales Executive</TableCell>
                                                    <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Won</TableCell>
                                                    <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Lost</TableCell>
                                                    <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Open</TableCell>
                                                    <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Total</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {leadsOnSelectedDate.map((row, i) => {
                                                    return (
                                                        <TableRow key={i + 1}>
                                                            <TableCell component="th" scope="row" style={{ padding: 10, width: 500, textAlign: 'center' }}> {row?.salesUser} </TableCell>
                                                            <TableCell component="th" scope="row" style={{ padding: 10, width: 500, textAlign: 'center' }}>
                                                                {
                                                                    canEdit === true && row.hasOwnProperty('Won') && row?.salesUser !== 'Total' ?
                                                                        <Button onClick={() => changeSalesPageRoute(row.Won, 'Won', row?.salesId)} color="primary">
                                                                            {row.Won?.count} - (₹ {commaSeparateAmount(row.Won?.value)})
                                                                        </Button>
                                                                        :
                                                                        <Button onClick={() => changeSalesPageRoute(row.Won, 'Won', "NA")} color="primary">
                                                                            {row.Won?.count ? row.Won?.count : `0`} - (₹ {row.Won?.value ? commaSeparateAmount(row.Won?.value) : `0`})
                                                                        </Button>
                                                                }
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" style={{ padding: 10, width: 500, textAlign: 'center' }}>
                                                                {
                                                                    canEdit === true && row.hasOwnProperty('Lost') && row?.salesUser !== 'Total' ?
                                                                        <Button onClick={() => changeSalesPageRoute(row.Lost, 'Lost', row?.salesId)} color="primary">
                                                                            {row.Lost?.count} - (₹ {commaSeparateAmount(row.Lost?.value)})
                                                                        </Button>
                                                                        :
                                                                        <Button onClick={() => changeSalesPageRoute(row.Lost, 'Lost',  "NA")} color="primary">
                                                                        {row.Lost?.count ? row.Lost?.count : `0`} - (₹ {row.Lost?.value ? commaSeparateAmount(row.Lost?.value) : `0`})
                                                                    </Button>
                                                                }
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" style={{ padding: 10, width: 500, textAlign: 'center' }}>
                                                                {
                                                                    canEdit === true && row.hasOwnProperty('Open') && row?.salesUser !== 'Total' ?
                                                                        <Button onClick={(e) => changeSalesPageRoute(row.Open, 'Open', row?.salesId)} color="primary">
                                                                            {row.Open?.count} - (₹ {commaSeparateAmount(row.Open?.value)})
                                                                        </Button>
                                                                        :
                                                                        <Button onClick={(e) => changeSalesPageRoute(row.Open, 'Open', "NA")} color="primary">
                                                                            {row?.Open?.count ? row.Open?.count : `0`} - (₹ {row?.Open?.value ? commaSeparateAmount(row.Open?.value) : `0`})
                                                                        </Button>
                                                                }
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" style={{ padding: 10, width: 500, textAlign: 'center' }}>
                                                                {
                                                                    canEdit === true && row.hasOwnProperty('salesUserTotal') && row?.salesUser !== 'Total' ?
                                                                        <Button onClick={(e) => changeSalesPageRoute(row.salesUserTotal, 'salesUserTotal', row?.salesId)} color="primary">
                                                                            {row.salesUserTotal?.count} - (₹ {commaSeparateAmount(row.salesUserTotal?.value)})
                                                                        </Button>
                                                                        :
                                                                        <Button onClick={(e) => changeSalesPageRoute(row.salesUserTotal, 'salesUserTotal',"NA")} color="primary">
                                                                            <> {row?.salesUserTotal?.count ? row.salesUserTotal?.count : `0`} - (₹ {row?.salesUserTotal?.value ? commaSeparateAmount(row.salesUserTotal?.value) : `0`}) </>
                                                                        </Button>
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </AccordionDetails>
                            </Accordion>
                        </Paper>

                        {/* Stage Wise Data for All Sales Executive */}
                        <Paper className={classes.paper} style={{ marginTop: 20 }}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className={classes.heading}>Stage-wise data (No Date Filter)</Typography>
                                </AccordionSummary>
                                <AccordionDetails style={{ padding: 0 }}>
                                    <TableContainer component={Paper} >
                                        <Table className={classes.table} stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow >
                                                    <TableCell style={{ padding: 8, width: 500, textAlign: 'center', fontWeight: 700 }}>Sales Executive</TableCell>
                                                    <TableCell style={{ padding: 8, width: 470, textAlign: 'center', fontWeight: 600 }}>Lead Received</TableCell>
                                                    <TableCell style={{ padding: 8, width: 470, textAlign: 'center', fontWeight: 600 }}>Pre - Sales call</TableCell>
                                                    <TableCell style={{ padding: 8, width: 470, textAlign: 'center', fontWeight: 600 }}>Sales Call</TableCell>
                                                    <TableCell style={{ padding: 8, width: 470, textAlign: 'center', fontWeight: 600 }}>Quotation Sent</TableCell>
                                                    <TableCell style={{ padding: 8, width: 470, textAlign: 'center', fontWeight: 600 }}>Site/Office visit</TableCell>
                                                    <TableCell style={{ padding: 8, width: 470, textAlign: 'center', fontWeight: 600 }}>Negotiation</TableCell>
                                                    <TableCell style={{ padding: 8, width: 470, textAlign: 'center', fontWeight: 600 }}>Won</TableCell>
                                                    <TableCell style={{ padding: 8, width: 470, textAlign: 'center', fontWeight: 600 }}>Won Deals Pending Designer Assignment</TableCell>
                                                    <TableCell style={{ padding: 8, width: 470, textAlign: 'center', fontWeight: 600 }}>Lost</TableCell>
                                                    <TableCell style={{ padding: 8, width: 470, textAlign: 'center', fontWeight: 600 }}>Delayed Requirement</TableCell>
                                                    <TableCell style={{ padding: 8, width: 470, textAlign: 'center', fontWeight: 600 }}>Total</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {salesStageLeadOnSelectedDate.map((row, i) => {
                                                    return (
                                                        <TableRow key={i + 1}>
                                                            <TableCell component="th" scope="row" style={{ padding: 10, width: 500, textAlign: 'center' }}> {row?.name} </TableCell>
                                                            <TableCell component="th" scope="row" style={{ padding: 10, width: 500, textAlign: 'center' }}>
                                                                {
                                                                    canEdit === true && row?.name !== 'Total' ?
                                                                        <Button onClick={() => changeSalesStagePageRoute('Lead Received', row?.salesId, 'user')} color="primary">
                                                                            {row.stages['Lead Received']}
                                                                        </Button>
                                                                        :
                                                                        <Button onClick={() => viewSelectedStageLeads('Lead Received')} color="primary">
                                                                            {row.stages['Lead Received'] ? row.stages['Lead Received'] : `0`} 
                                                                        </Button>
                                                                }
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" style={{ padding: 10, width: 500, textAlign: 'center' }}>
                                                                {
                                                                    canEdit === true && row?.name !== 'Total' ?
                                                                        <Button onClick={() => changeSalesStagePageRoute('Pre - Sales call', row?.salesId, 'user')} color="primary">
                                                                            {row.stages['Pre - Sales call']}
                                                                        </Button>
                                                                        :
                                                                        <Button onClick={() => viewSelectedStageLeads('Pre - Sales call')} color="primary">
                                                                            {row.stages['Pre - Sales call'] ? row.stages['Pre - Sales call'] : `0`}
                                                                        </Button>
                                                                }
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" style={{ padding: 10, width: 500, textAlign: 'center' }}>
                                                                {
                                                                    canEdit === true && row?.name !== 'Total' ?
                                                                        <Button onClick={() => changeSalesStagePageRoute('Sales Call', row?.salesId, 'user')} color="primary">
                                                                            {row.stages['Sales Call']}
                                                                        </Button>
                                                                        :
                                                                        <Button onClick={() => viewSelectedStageLeads('Sales Call')} color="primary">
                                                                            {row.stages['Sales Call'] ? row.stages['Sales Call'] : `0`}
                                                                        </Button>
                                                                }
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" style={{ padding: 10, width: 500, textAlign: 'center' }}>
                                                                {
                                                                    canEdit === true && row?.name !== 'Total' ?
                                                                        <Button onClick={() => changeSalesStagePageRoute('Quotation Sent', row?.salesId, 'user')} color="primary">
                                                                            {row.stages['Quotation Sent']}
                                                                        </Button>
                                                                        :
                                                                        <Button onClick={() => viewSelectedStageLeads('Quotation Sent')} color="primary">
                                                                            {row.stages['Quotation Sent'] ? row.stages['Quotation Sent'] : `0`}
                                                                        </Button>
                                                                }
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" style={{ padding: 10, width: 500, textAlign: 'center' }}>
                                                                {
                                                                    canEdit === true && row?.name !== 'Total' ?
                                                                        <Button onClick={() => changeSalesStagePageRoute(decode, row?.salesId, 'user')} color="primary">
                                                                            {row.stages['Site/Office visit']}
                                                                        </Button>
                                                                        :
                                                                        <Button onClick={() => viewSelectedStageLeads('Site/Office visit')} color="primary">
                                                                            {row.stages['Site/Office visit'] ? row.stages['Site/Office visit'] : `0`}
                                                                        </Button>
                                                                }
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" style={{ padding: 10, width: 500, textAlign: 'center' }}>
                                                                {
                                                                    canEdit === true && row?.name !== 'Total' ?
                                                                        <Button onClick={() => changeSalesStagePageRoute('Negotiation', row?.salesId, 'user')} color="primary">
                                                                            {row.stages['Negotiation']}
                                                                        </Button>
                                                                        :
                                                                        <Button onClick={() => viewSelectedStageLeads('Negotiation')} color="primary">
                                                                            {row.stages['Negotiation'] ? row.stages['Negotiation'] : `0`}
                                                                        </Button>
                                                                }
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" style={{ padding: 10, width: 500, textAlign: 'center' }}>
                                                                {
                                                                    canEdit === true && row?.name !== 'Total' ?
                                                                        <Button onClick={() => changeSalesStagePageRoute('Won', row?.salesId, 'user')} color="primary">
                                                                            {row.stages['Won']}
                                                                        </Button>
                                                                        :
                                                                        <Button onClick={() => viewSelectedStageLeads('Won')} color="primary">
                                                                            {row.stages['Won'] ? row.stages['Won'] : `0`}
                                                                        </Button>
                                                                }
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" style={{ padding: 10, width: 500, textAlign: 'center' }}>
                                                                {
                                                                    canEdit === true && row?.name !== 'Total' ?
                                                                        <Button onClick={() => changeSalesStagePageRoute('Won Deals Pending Designer Assignment', row?.salesId, 'user')} color="primary">
                                                                            {row?.stages?.['Won Deals Pending Designer Assignment']}
                                                                        </Button>
                                                                        :
                                                                        <Button onClick={() => viewSelectedStageLeads('Won Deals Pending Designer Assignment')} color="primary">
                                                                            {row.stages['Won Deals Pending Designer Assignment'] ? row.stages['Won Deals Pending Designer Assignment'] : `0`}
                                                                        </Button>
                                                                }
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" style={{ padding: 10, width: 500, textAlign: 'center' }}>
                                                                {
                                                                    canEdit === true && row?.name !== 'Total' ?
                                                                        <Button onClick={() => changeSalesStagePageRoute('Lost', row?.salesId, 'user')} color="primary">
                                                                            {row.stages['Lost']}
                                                                        </Button>
                                                                        :
                                                                        <Button onClick={() => viewSelectedStageLeads('Lost')} color="primary">
                                                                            {row.stages['Lost'] ? row.stages['Lost'] : `0`}
                                                                        </Button>
                                                                }
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" style={{ padding: 10, width: 500, textAlign: 'center' }}>
                                                                {
                                                                    canEdit === true && row?.name !== 'Total' ?
                                                                        <Button onClick={() => changeSalesStagePageRoute('Delayed Requirement', row?.salesId, 'user')} color="primary">
                                                                            {row.stages['Delayed Requirement']}
                                                                        </Button>
                                                                        :
                                                                        <Button onClick={() => viewSelectedStageLeads('Delayed Requirement')} color="primary">
                                                                            {row.stages['Delayed Requirement'] ? row.stages['Delayed Requirement'] : `0`}
                                                                        </Button>
                                                                }
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" style={{ padding: 10, width: 500, textAlign: 'center' }}>
                                                                {
                                                                    canEdit === true && row?.name !== 'Total' ?
                                                                        <Button onClick={() => changeSalesStagePageRoute('salesTotal', row?.salesId, 'user')} color="primary">
                                                                            {row.stages['Total']}
                                                                        </Button>
                                                                        :
                                                                        <Button onClick={() => viewSelectedStageLeads('salesTotal')} color="primary">
                                                                            {totalValue ? totalValue : `0`}
                                                                        </Button>
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </AccordionDetails>
                            </Accordion>
                        </Paper>
                    </>
                }
                {
                    props.role === ROLENAME.SalesUser && <>
                        {/* Status wise data for Log in Sales Executive */}
                        <Paper className={classes.paper}>
                            <Accordion defaultExpanded>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className={classes.heading}>*Please select the "Lead Created At" option to see meaningful data</Typography>
                                </AccordionSummary>
                                <Divider />
                                <AccordionDetails style={{ padding: 0 }}>
                                    <TableContainer component={Paper}>
                                        <Table className={classes.table} stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow >
                                                    {/* <TableCell rowSpan={3} style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Sales Executive</TableCell> */}
                                                    <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Lead Status</TableCell>
                                                    <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Value</TableCell>
                                                    <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Numbers</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    leadsOnSelectedDate.length !== 0 ?
                                                        leadsOnSelectedDate.map((row, i) => {
                                                            return (
                                                                <TableRow key={i + 1}>
                                                                    {/* <TableCell component="th" scope="row" style={{ padding: 10, width: 500, textAlign: 'center' }}> 
                                                                {userDetails?.name} 
                                                            </TableCell> */}
                                                                    <TableCell component="th" scope="row" style={{ padding: 10, width: 500, textAlign: 'center' }}>
                                                                        {row.status}
                                                                    </TableCell>
                                                                    <TableCell component="th" scope="row" style={{ padding: 10, width: 500, textAlign: 'center' }}>
                                                                        ₹ {commaSeparateAmount(row.value)}
                                                                    </TableCell>
                                                                    <TableCell component="th" scope="row" style={{ padding: 10, width: 500, textAlign: 'center' }}>
                                                                        {
                                                                            canEdit === true && row.count !== 0 ?
                                                                                <Button onClick={() => changeSalesPageRoute(row)} color="primary">
                                                                                    {row.count !== 'undefined' || row.count !== '' ? row.count : `-`}
                                                                                </Button>
                                                                                :
                                                                                <> {row.count !== 'undefined' || row.count !== '' ? row.count : `-`} </>
                                                                        }
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })
                                                        :
                                                        <TableRow>
                                                            <TableCell style={{ textAlign: 'center' }}>No Data Found</TableCell>
                                                        </TableRow>
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </AccordionDetails>
                            </Accordion>
                        </Paper>

                        {/* Stage Wise Data for Log in Sales Executive */}
                        <Paper className={classes.paper} style={{ marginTop: 20 }}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className={classes.heading}>Stage-wise data (No Date Filter)</Typography>
                                </AccordionSummary>
                                <Divider />
                                <AccordionDetails style={{ padding: 0 }}>
                                    <TableContainer component={Paper}>
                                        <Table className={classes.table} stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow >
                                                    <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Lead Received</TableCell>
                                                    <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Pre - Sales call</TableCell>
                                                    <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Sales Call</TableCell>
                                                    <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Quotation Sent</TableCell>
                                                    <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Site/Office visit</TableCell>
                                                    <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Negotiation</TableCell>
                                                    <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Won</TableCell>
                                                    <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Won Deals Pending Designer Assignment</TableCell>
                                                    <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Lost</TableCell>
                                                    <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Delayed Requirement</TableCell>
                                                    <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Total</TableCell>

                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    salesStageLeadOnSelectedDate.length !== 0 ?
                                                        salesStageLeadOnSelectedDate.map((row, i) => {
                                                            return (
                                                                <TableRow key={i + 1}>
                                                                    <TableCell component="th" scope="row" style={{ padding: 10, width: 500, textAlign: 'center' }}>
                                                                        {
                                                                            canEdit === true ?
                                                                                <Button onClick={() => changeSalesStagePageRoute('Lead Received', userDetails?._id, 'user')} color="primary">
                                                                                    {row?.stages?.['Lead Received']}
                                                                                </Button>
                                                                                :
                                                                                <> {row?.stages?.['Lead Received'] ? row?.stages?.['Lead Received'] : `0`} </>
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell component="th" scope="row" style={{ padding: 10, width: 500, textAlign: 'center' }}>
                                                                        {
                                                                            canEdit === true ?
                                                                                <Button onClick={() => changeSalesStagePageRoute('Pre - Sales call', userDetails?._id, 'user')} color="primary">
                                                                                    {row?.stages?.['Pre - Sales call']}
                                                                                </Button>
                                                                                :
                                                                                <> {row?.stages?.['Pre - Sales call'] ? row?.stages?.['Pre - Sales call'] : `0`} </>
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell component="th" scope="row" style={{ padding: 10, width: 500, textAlign: 'center' }}>
                                                                        {
                                                                            canEdit === true ?
                                                                                <Button onClick={() => changeSalesStagePageRoute('Sales Call', userDetails._id, 'user')} color="primary">
                                                                                    {row?.stages?.['Sales Call']}
                                                                                </Button>
                                                                                :
                                                                                <> {row?.stages?.['Sales Call'] ? row?.stages?.['Sales Call'] : `0`} </>
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell component="th" scope="row" style={{ padding: 10, width: 500, textAlign: 'center' }}>
                                                                        {
                                                                            canEdit === true ?
                                                                                <Button onClick={() => changeSalesStagePageRoute('Quotation Sent', userDetails._id, 'user')} color="primary">
                                                                                    {row?.stages?.['Quotation Sent']}
                                                                                </Button>
                                                                                :
                                                                                <> {row?.stages?.['Quotation Sent'] ? row?.stages?.['Quotation Sent'] : `0`} </>
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell component="th" scope="row" style={{ padding: 10, width: 500, textAlign: 'center' }}>
                                                                        {
                                                                            canEdit === true ?
                                                                                <Button onClick={() => changeSalesStagePageRoute('Site Office visit', userDetails._id, 'user')} color="primary">
                                                                                    {row?.stages?.['Site/Office visit']}
                                                                                </Button>
                                                                                :
                                                                                <> {row?.stages?.['Site/Office visit'] ? row?.stages?.['Site/Office visit'] : `0`} </>
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell component="th" scope="row" style={{ padding: 10, width: 500, textAlign: 'center' }}>
                                                                        {
                                                                            canEdit === true ?
                                                                                <Button onClick={() => changeSalesStagePageRoute('Negotiation', userDetails._id, 'user')} color="primary">
                                                                                    {row?.stages?.['Negotiation']}
                                                                                </Button>
                                                                                :
                                                                                <> {row?.stages?.['Negotiation'] ? row?.stages?.['Negotiation'] : `0`} </>
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell component="th" scope="row" style={{ padding: 10, width: 500, textAlign: 'center' }}>
                                                                        {
                                                                            canEdit === true ?
                                                                                <Button onClick={() => changeSalesStagePageRoute('Won', userDetails._id, 'user')} color="primary">
                                                                                    {row?.stages?.['Won']}
                                                                                </Button>
                                                                                :
                                                                                <> {row?.stages?.['Won'] ? row?.stages?.['Won'] : `0`} </>
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell component="th" scope="row" style={{ padding: 10, width: 500, textAlign: 'center' }}>
                                                                        {
                                                                            canEdit === true ?
                                                                                <Button onClick={() => changeSalesStagePageRoute('Won Deals Pending Designer Assignment', userDetails._id, 'user')} color="primary">
                                                                                    {row?.stages?.['Won Deals Pending Designer Assignment']}
                                                                                </Button>
                                                                                :
                                                                                <> {row?.stages?.['Won Deals Pending Designer Assignment'] ? row?.stages?.['Won Deals Pending Designer Assignment'] : `0`} </>
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell component="th" scope="row" style={{ padding: 10, width: 500, textAlign: 'center' }}>
                                                                        {
                                                                            canEdit === true ?
                                                                                <Button onClick={() => changeSalesStagePageRoute('Lost', userDetails._id, 'user')} color="primary">
                                                                                    {row?.stages?.['Lost']}
                                                                                </Button>
                                                                                :
                                                                                <> {row?.stages?.['Lost'] ? row?.stages?.['Lost'] : `0`} </>
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell component="th" scope="row" style={{ padding: 10, width: 500, textAlign: 'center' }}>
                                                                        {
                                                                            canEdit === true ?
                                                                                <Button onClick={() => changeSalesStagePageRoute('Delayed Requirement', userDetails._id, 'user')} color="primary">
                                                                                    {row?.stages?.['Delayed Requirement']}
                                                                                </Button>
                                                                                :
                                                                                <> {row?.stages?.['Delayed Requirement'] ? row?.stages?.['Delayed Requirement'] : `0`} </>
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell component="th" scope="row" style={{ padding: 10, width: 500, textAlign: 'center' }}>
                                                                        {
                                                                            canEdit === true ?
                                                                                <Button onClick={() => changeSalesStagePageRoute('salesTotal', userDetails._id, 'user')} color="primary">
                                                                                    {row?.stages?.['Total']}
                                                                                </Button>
                                                                                :
                                                                                <> {row?.stages?.['Total'] ? row?.stages?.['Total'] : `0`} </>
                                                                        }
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })
                                                        :
                                                        <TableRow>
                                                            <TableCell style={{ textAlign: 'center' }}>No Data Found</TableCell>
                                                        </TableRow>
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </AccordionDetails>
                            </Accordion>
                        </Paper>
                    </>
                }
                {
                    ((props.role === ROLENAME.DGM_Sales) && (sortedData !== undefined) && (sortedData.length != 0) && (sortedData[0].length != 0) ) &&

                    <>
                        <div>
                            <FormControl variant="outlined" style={{ margin: 10, width: "30%", marginRight:50 }} className={classes.formControl}>
                                <InputLabel id="demo-simple-select-label">Experience Center</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-mutiple-checkbox"
                                    multiple
                                    value={selectedExpcenters}
                                    label="Exp-Center"
                                    onChange={getSelectedExpCenters}
                                    input={<Input />}
                                    renderValue={(selected) => selected.join(" ,")}
                                    MenuProps={MenuProps}
                                >
                                    {dumpExpCenters.map((name) => (
                                        <MenuItem key={name.name} value={name.name}>
                                            <Checkbox checked={selectedExpcenters.indexOf(name.name) > -1} />
                                            <ListItemText primary={name.name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            {/* <FormControl variant="outlined" style={{ margin: 20, width: "30%" }} className={classes.formControl}>
                                <InputLabel id="demo-simple-select-label">Token Percentage</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="TokenPercentage"
                                    onChange={e => setTokenPercentage(e.target.value)}
                                    value={TokenPercentage}
                                >
                                    <MenuItem value={"Below-5%"}>Below 5%</MenuItem>
                                    <MenuItem value={"Above-5%"}>Above 5%</MenuItem>

                                </Select>
                            </FormControl> */}
                            <FormControlLabel
                                value="end"
                                control={<Switch color="primary" checked={checked} onChange={toggleChecked} />}
                                label="Token Above 5%"
                            />
                            {/* <Button style={{ margin: 20 }} variant="contained" color="secondary" onClick={applyFilter}> Filter</Button> */}
                            {/* <Button style={{ margin: 20 }} variant="contained" color="secondary" onClick={clearFilter}>Clear Filter</Button> */}

                        </div>
                        <div className={classes.tablecontainer}>

                            <div className={classes.tablealign}>
                                <TableContainer style={{ width: "100%" }} component={Paper}>
                                    <Table style={{ width: 400 }} className={classes.table} stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow >
                                                <TableCell component="th" scope="row" style={{ textAlign: 'center', height: 116 }}>Center Name</TableCell>
                                                <TableCell component="th" scope="row" style={{ textAlign: 'center', height: 116 }}>Name</TableCell>


                                            </TableRow>
                                        </TableHead>

                                        <TableBody>

                                            {sortedData.map((items, index) => {
                                                return (
                                                    <>
                                                        {
                                                            items.map((subItems, sIndex) => {
                                                                return (
                                                                    <TableRow >
                                                                        {index === 0 && <>
                                                                            <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{subItems.expcenter}</TableCell>
                                                                            <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{subItems.name}</TableCell>
                                                                        </>
                                                                        }

                                                                    </TableRow>
                                                                )
                                                            })
                                                        }

                                                    </>);
                                            })}
                                            <TableRow >
                                                <TableCell component="th" scope="row" style={{ textAlign: 'center' }} colSpan={2}>
                                                    <b>TOTAL (sum of managers)</b>
                                                </TableCell>

                                            </TableRow>

                                        </TableBody>

                                    </Table>
                                </TableContainer>
                            </div>
                            <div className={classes.tablealign}>
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow >
                                                <TableCell colspan={2} component="th" scope="row" style={{ textAlign: 'center' }}>{sortedData[0][0].month}</TableCell>
                                            </TableRow>
                                            <TableRow >
                                                <>
                                                    <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>Value</TableCell>
                                                    <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>Count</TableCell>

                                                </>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {sortedData[0].map((items, index) => {
                                                return (
                                                    <TableRow >
                                                        <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{items.totalValue}</TableCell>
                                                        <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{items.count}</TableCell>
                                                    </TableRow>
                                                )
                                            })
                                            }
                                            <TableRow>
                                                <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{totalCol[0].total}</TableCell>
                                                <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{totalCol[0].count}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                            <div className={classes.tablealign}>
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow >
                                                <TableCell colspan={2} component="th" scope="row" style={{ textAlign: 'center' }}>{sortedData[1][0].month}</TableCell>
                                            </TableRow>
                                            <TableRow >
                                                <>
                                                    <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>Value</TableCell>
                                                    <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>Count</TableCell>

                                                </>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {sortedData[1].map((items, index) => {
                                                return (
                                                    <TableRow >
                                                        <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{items.totalValue}</TableCell>
                                                        <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{items.count}</TableCell>
                                                    </TableRow>
                                                )
                                            })
                                            }
                                            <TableRow>
                                                <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{totalCol[1].total}</TableCell>
                                                <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{totalCol[1].count}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                            <div className={classes.tablealign}>
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow >
                                                <TableCell colspan={2} component="th" scope="row" style={{ textAlign: 'center' }}>{sortedData[2][0].month}</TableCell>
                                            </TableRow>
                                            <TableRow >
                                                <>
                                                    <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>Value</TableCell>
                                                    <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>Count</TableCell>

                                                </>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {sortedData[2].map((items, index) => {
                                                return (
                                                    <TableRow >
                                                        <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{items.totalValue}</TableCell>
                                                        <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{items.count}</TableCell>
                                                    </TableRow>
                                                )
                                            })
                                            }
                                            <TableRow>
                                                <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{totalCol[2].total}</TableCell>
                                                <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{totalCol[2].count}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                            <div className={classes.tablealign}>
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow >
                                                <TableCell colspan={2} component="th" scope="row" style={{ textAlign: 'center' }}>{sortedData[3][0].month}</TableCell>
                                            </TableRow>
                                            <TableRow >
                                                <>
                                                    <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>Value</TableCell>
                                                    <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>Count</TableCell>

                                                </>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {sortedData[3].map((items, index) => {
                                                return (
                                                    <TableRow >
                                                        <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{items.totalValue}</TableCell>
                                                        <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{items.count}</TableCell>
                                                    </TableRow>
                                                )
                                            })
                                            }
                                            <TableRow>
                                                <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{totalCol[3].total}</TableCell>
                                                <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{totalCol[3].count}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                            <div className={classes.tablealign}>
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow >
                                                <TableCell colspan={2} component="th" scope="row" style={{ textAlign: 'center' }}>{sortedData[4][0].month}</TableCell>
                                            </TableRow>
                                            <TableRow >
                                                <>
                                                    <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>Value</TableCell>
                                                    <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>Count</TableCell>

                                                </>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {sortedData[4].map((items, index) => {
                                                return (
                                                    <TableRow >
                                                        <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{items.totalValue}</TableCell>
                                                        <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{items.count}</TableCell>
                                                    </TableRow>
                                                )
                                            })
                                            }
                                            <TableRow>
                                                <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{totalCol[4].total}</TableCell>
                                                <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{totalCol[4].count}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                            <div className={classes.tablealign}>
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow >
                                                <TableCell colspan={2} component="th" scope="row" style={{ textAlign: 'center' }}>{sortedData[5][0].month}</TableCell>
                                            </TableRow>
                                            <TableRow >
                                                <>
                                                    <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>Value</TableCell>
                                                    <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>Count</TableCell>

                                                </>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {sortedData[5].map((items, index) => {
                                                return (
                                                    <TableRow >
                                                        <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{items.totalValue}</TableCell>
                                                        <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{items.count}</TableCell>
                                                    </TableRow>
                                                )
                                            })
                                            }
                                            <TableRow>
                                                <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{totalCol[5].total}</TableCell>
                                                <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{totalCol[5].count}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                            <div className={classes.tablealign}>
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow >
                                                <TableCell colspan={2} component="th" scope="row" style={{ textAlign: 'center' }}>{sortedData[6][0].month}</TableCell>
                                            </TableRow>
                                            <TableRow >
                                                <>
                                                    <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>Value</TableCell>
                                                    <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>Count</TableCell>

                                                </>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {sortedData[6].map((items, index) => {
                                                return (
                                                    <TableRow >
                                                        <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{items.totalValue}</TableCell>
                                                        <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{items.count}</TableCell>
                                                    </TableRow>
                                                )
                                            })
                                            }
                                            <TableRow>
                                                <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{totalCol[6].total}</TableCell>
                                                <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{totalCol[6].count}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                            <div className={classes.tablealign}>
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow >
                                                <TableCell colspan={2} component="th" scope="row" style={{ textAlign: 'center' }}>{sortedData[7][0].month}</TableCell>
                                            </TableRow>
                                            <TableRow >
                                                <>
                                                    <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>Value</TableCell>
                                                    <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>Count</TableCell>

                                                </>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {sortedData[7].map((items, index) => {
                                                return (
                                                    <TableRow >
                                                        <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{items.totalValue}</TableCell>
                                                        <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{items.count}</TableCell>
                                                    </TableRow>
                                                )
                                            })
                                            }
                                            <TableRow>
                                                <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{totalCol[7].total}</TableCell>
                                                <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{totalCol[7].count}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                            <div className={classes.tablealign}>
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow >
                                                <TableCell colspan={2} component="th" scope="row" style={{ textAlign: 'center' }}>{sortedData[8][0].month}</TableCell>
                                            </TableRow>
                                            <TableRow >
                                                <>
                                                    <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>Value</TableCell>
                                                    <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>Count</TableCell>

                                                </>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {sortedData[8].map((items, index) => {
                                                return (
                                                    <TableRow >
                                                        <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{items.totalValue}</TableCell>
                                                        <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{items.count}</TableCell>
                                                    </TableRow>
                                                )
                                            })
                                            }
                                            <TableRow>
                                                <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{totalCol[8].total}</TableCell>
                                                <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{totalCol[8].count}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                            <div className={classes.tablealign}>
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow >
                                                <TableCell colspan={2} component="th" scope="row" style={{ textAlign: 'center' }}>{sortedData[9][0].month}</TableCell>
                                            </TableRow>
                                            <TableRow >
                                                <>
                                                    <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>Value</TableCell>
                                                    <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>Count</TableCell>

                                                </>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {sortedData[9].map((items, index) => {
                                                return (
                                                    <TableRow >
                                                        <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{items.totalValue}</TableCell>
                                                        <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{items.count}</TableCell>
                                                    </TableRow>
                                                )
                                            })
                                            }
                                            <TableRow>
                                                <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{totalCol[9].total}</TableCell>
                                                <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{totalCol[9].count}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                            <div className={classes.tablealign}>
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow >
                                                <TableCell colspan={2} component="th" scope="row" style={{ textAlign: 'center' }}>{sortedData[10][0].month}</TableCell>
                                            </TableRow>
                                            <TableRow >
                                                <>
                                                    <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>Value</TableCell>
                                                    <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>Count</TableCell>

                                                </>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {sortedData[10].map((items, index) => {
                                                return (
                                                    <TableRow >
                                                        <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{items.totalValue}</TableCell>
                                                        <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{items.count}</TableCell>
                                                    </TableRow>
                                                )
                                            })
                                            }
                                            <TableRow>
                                                <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{totalCol[10].total}</TableCell>
                                                <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{totalCol[10].count}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                            <div className={classes.tablealign}>
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow >
                                                <TableCell colspan={2} component="th" scope="row" style={{ textAlign: 'center' }}>{sortedData[11][0].month}</TableCell>
                                            </TableRow>
                                            <TableRow >
                                                <>
                                                    <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>Value</TableCell>
                                                    <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>Count</TableCell>

                                                </>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {sortedData[11].map((items, index) => {
                                                return (
                                                    <TableRow >
                                                        <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{items.totalValue}</TableCell>
                                                        <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{items.count}</TableCell>
                                                    </TableRow>
                                                )
                                            })
                                            }
                                            <TableRow>
                                                <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{totalCol[11].total}</TableCell>
                                                <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{totalCol[11].count}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                            <div className={classes.tablealign}>
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow >
                                                <TableCell colspan={2} component="th" scope="row" style={{ textAlign: 'center' }}>{sortedData[12][0].month}</TableCell>
                                            </TableRow>
                                            <TableRow >
                                                <>
                                                    <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>Value</TableCell>
                                                    <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>Count</TableCell>

                                                </>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {sortedData[12].map((items, index) => {
                                                return (
                                                    <TableRow >
                                                        <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{items.totalValue}</TableCell>
                                                        <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{items.count}</TableCell>
                                                    </TableRow>
                                                )
                                            })
                                            }
                                            <TableRow>
                                                <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{totalCol[12].total}</TableCell>
                                                <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{totalCol[12].count}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                            <div className={classes.tablealign}>
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow >
                                                <TableCell colspan={2} component="th" scope="row" style={{ textAlign: 'center' }}>Total</TableCell>
                                            </TableRow>
                                            <TableRow >
                                                <>
                                                    <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>Value</TableCell>
                                                    <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>Count</TableCell>

                                                </>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rowTotal.map((items, index) => {
                                                return (
                                                    <TableRow >
                                                        <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{items.value}</TableCell>
                                                        <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{items.count}</TableCell>
                                                    </TableRow>
                                                )
                                            })
                                            }
                                            <TableRow>
                                                <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{totalCol[13].total}</TableCell>
                                                <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{totalCol[13].count}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>

                        </div>
                    </>

                }
            </div>
        </div>
    )
}

export default connect(null, { setLoading, setSnackInfo })(CalenderFilterData)
