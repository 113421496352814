import React, { useState, useEffect } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete';
import HttpService from '../services/httpService';
import TextField from '@material-ui/core/TextField';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { connect } from "react-redux";
import { setLoading, setSnackInfo } from '../redux/actions/appAction';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FormGroup from '@material-ui/core/FormGroup';
import PoSoMtrNewMaterialAddition from './PoSoMtrNewMaterialAddition';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 850,
  },
  formControl: {
    margin: theme.spacing(3),
  },
  root: {
    display: 'flex',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#ccc",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const RaiseSoModal = (props) => {
  const classes = useStyles();

  const [bomMaterials, setBomMaterials] = useState([])
  const [units, setUnits] = useState([])
  const [materialChosen, setMaterialChosen] = useState([])
  const [materialChosenDetails, setMaterialChosenDetails] = useState([])
  // const [vendors, setVendors] = useState([])
  const [selectedVendor, setSelectedVendor] = useState([])
  const [soDetails, setSoDetails] = useState({
    deliveryDate: ""
  })
  useEffect(() => {
    getUnits()
    getBomMaterials()
    // getVendors()
    // eslint-disable-next-line 
  }, [])

  const getBomMaterials = async () => {
    props.setLoading(true)
    try {
      const result = await HttpService.getBomOfProjectScope(props.SELECTEDPROJECT._id, props.SCOPE, props.TYPE)
      setBomMaterials(result.data)
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
    }
    props.setLoading(false)
  }

  const getUnits = async () => {
    try {
      const result = await HttpService.getAllUnits()
      setUnits(result.data)
    } catch (error) {
      console.error(error)
    }
  }

  // const getVendors = async () => {
  //   try {
  //     const result = await HttpService.getAllVendors()
  //     setVendors(result.data)
  //   } catch (error) {
  //     console.error(error)
  //   }
  // }

  const handleChange = (e) => {
    if (e.target.checked) {
      setMaterialChosen([...materialChosen, e.target.name])
      setMaterialChosenDetails([...materialChosenDetails, bomMaterials.filter(bom => {
        if (bom?.materialId?.name === e.target.name) {
          setNewQuantity([...newQuantity, Number(((bom?.quantity - bom?.raisedQty)).toFixed(2))])
          setSelectedUom([...selectedUom, bom?.materialId?.unitId ?? bom?.unitType])
          return bom
        }
        return false
      })[0]])
    } else {
      setMaterialChosen(materialChosen.filter(material => material !== e.target.name))
      setMaterialChosenDetails(materialChosenDetails.filter((bom, i) => {
        if (bom?.materialId?.name !== e.target.name) {
          return bom
        } else {
          setSelectedVendor(selectedVendor.filter((q, idx) => i !== idx))
          setNewPrice(newPrice.filter((q, idx) => i !== idx))
          setSelectedUom(selectedUom.filter((q, idx) => i !== idx))
          setNewQuantity(newQuantity.filter((q, idx) => i !== idx))
        }
        return false
      }
      ))

    }
  }
  const [newQuantity, setNewQuantity] = useState([])
  const [newPrice, setNewPrice] = useState([])

  const handleQuantity = (e, i, data) => {
    let quant = [...newQuantity]
    quant[i] = e.target.value
    setNewQuantity(quant)
  }

  const handlePrice = (e, i) => {
    let price = [...newPrice]
    price[i] = e.target.value
    setNewPrice(price)
  }

  const getSelectedVendor = (i) => {
    const item = selectedVendor[i]
    return item || {}
  }

  const handleVendorSelect = (e, v, i) => {
    setSelectedVendor([])
		let missing_docs = ''
		if(!v.vendorId.aadharCardLink || (v.vendorId.aadharCardLink.length < 1))
		{
			missing_docs += 'Aadhar Card,'
			
		}
		if(!v.vendorId.panCardLink || (v.vendorId.panCardLink.length < 1))
		{
			missing_docs += 'PAN Card,'
			
		}
		if(missing_docs.length > 0)
		{
			props.setSnackInfo(`${v.vendorId.name} did not provided ${missing_docs} can't raise PO/SO for them.`, 'error')
			return
		}
    
    if (v) {
      let vendor = [...selectedVendor]
      vendor[i] = v
      setSelectedVendor(vendor)
      let price = [...newPrice]
      price[i] = v?.pricePerUnit
      setNewPrice(price)
    }
  }

  const [selectedUom, setSelectedUom] = useState([])

  const handleUomSelect = (e, v, i) => {
    let uom = [...selectedUom]
    uom[i] = v
    setSelectedUom(uom)
  }

  const getSelectedUom = (i) => {
    const item = selectedUom[i]
    return item || {}
  }

  const createSO = async () => {
    props.setLoading(true)
    try {
      const obj = materialChosenDetails.map((material, i) => {
        return {
          vendorId: selectedVendor[i],
          materialId: material.materialId,
          projectMaterialId: material?._id,
          newPrice: newPrice[i],
          quantity: material.quantity,
          raisedQty: material.raisedQty,
          newQuantity: newQuantity[i],
          unitId: selectedUom[i]
        }
      })
      const SO = {}
      SO.projectId = props.SELECTEDPROJECT._id
      SO.deliveryDate = soDetails?.deliveryDate
      SO.materials = obj
      const result = await HttpService.createServiceOrderFromBomMaterials(SO, props.SCOPE)
      if (result.status === 201) {
        props.CLOSEMODAL()
        props.setSnackInfo('SO CREATED', "success")
      }
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
    }
    props.setLoading(false)
  }

	const [showNewMaterialModal,setShowNewMaterialModal] = useState(false);
  const addMoreMaterialHandler = (data)=>{
		let newArr  = [...materialChosenDetails,...data];
		setMaterialChosenDetails(newArr);
	}

  return (
    <div>
      {bomMaterials.length > 0 ? <div>
        <div className="procurement-header">Create Service Order</div>
        <div>
          <TableContainer component={Paper}>
            <Table className={classes.table} size="small" aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Sl No</StyledTableCell>
                  <StyledTableCell>Material Name</StyledTableCell>
                  <StyledTableCell>Uom</StyledTableCell>
                  <StyledTableCell>Quantity</StyledTableCell>
                  <StyledTableCell>Raised Qty</StyledTableCell>
                  <StyledTableCell>Received Qty</StyledTableCell>
                  <StyledTableCell>Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bomMaterials.map((data, i) => <StyledTableRow StyledTableRow key={i + 1}>
                  <StyledTableCell>{i + 1}</StyledTableCell>
                  <StyledTableCell>{data?.materialId?.name}</StyledTableCell>
                  <StyledTableCell>{data?.materialId?.unitId?.name}</StyledTableCell>
                  <StyledTableCell>{data?.quantity}</StyledTableCell>
                  <StyledTableCell>{data?.raisedQty}</StyledTableCell>
                  <StyledTableCell>{data?.receivedQty}</StyledTableCell>
                  <StyledTableCell><FormControl component="fieldset" className={classes.formControl}>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox checked={materialChosen.includes(data?.materialId?.name)} onChange={handleChange} name={data?.materialId?.name} />}
                      />
                    </FormGroup>
                  </FormControl></StyledTableCell>
                </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className='modal-header' style={{ justifyContent: "space-between", paddingTop: 10 }}>
          <div style={{ width: "40%" }}>
            <b>Project: </b>
            <span>{`${props.PROJECTINFO.projectCode}_${props.PROJECTINFO.clientName}`}</span>
          </div>
          <div style={{ width: "40%" }}>
            <TextField
              id="date"
              name="deliveryDate"
              onChange={(e) => setSoDetails({ ...soDetails, [e.target.name]: e.target.value })}
              label="Expected Delivery Date"
              type="date"
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        </div>
        {!showNewMaterialModal &&  <Button variant="contained" color="primary" style={{ background: "#716ACA", margin: 5 }} onClick={()=> {setShowNewMaterialModal(true);} }>Add New Material</Button>}
				{showNewMaterialModal && <PoSoMtrNewMaterialAddition MOUNTED={true} SCOPE={props.SCOPE} addMoreMaterialHandler={addMoreMaterialHandler} setShowNewMaterialModal={setShowNewMaterialModal} bomMaterials={bomMaterials} TYPE={props.TYPE}/>}
        {materialChosenDetails.length > 0 && <div>
          <TableContainer component={Paper}>
            <Table className={classes.table} size="small" aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Sl No</StyledTableCell>
                  <StyledTableCell>Name/Code</StyledTableCell>
                  <StyledTableCell>Quantity</StyledTableCell>
                  <StyledTableCell>New Quantity</StyledTableCell>
                  <StyledTableCell>Unit</StyledTableCell>
                  <StyledTableCell>Pick Vendor</StyledTableCell>
                  <StyledTableCell>Price</StyledTableCell>
                  {/* <StyledTableCell>New Price</StyledTableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {materialChosenDetails.map((data, i) => <StyledTableRow StyledTableRow key={i + 1}>
                  <StyledTableCell>{i + 1}</StyledTableCell>
                  <StyledTableCell>{data?.materialId?.name}</StyledTableCell>
                  <StyledTableCell>{data?.quantity}</StyledTableCell>
                  <StyledTableCell><TextField id="newQty" className={classes.textField} type="number" onChange={(e) => handleQuantity(e, i, data)} name="newQuantity" value={newQuantity[i]} variant="outlined" /></StyledTableCell>
                  <StyledTableCell><Autocomplete
                    value={getSelectedUom(i)}
                    onChange={(e, v) => handleUomSelect(e, v, i)}
                    id="Unit"
                    options={units}
                    getOptionLabel={(option) => option.name}
                    style={{ width: 150 }}
                    renderInput={(params) => <TextField {...params} fullWidth variant="outlined" />}
                  /></StyledTableCell>
                  <StyledTableCell>
                    < Autocomplete
                      value={getSelectedVendor(i)}
                      onChange={(e, v) => handleVendorSelect(e, v, i)}
                      id="Vendor"
                      options={data?.vendorDetails}
                      getOptionLabel={(option) => option?.vendorId?.name}
                      style={{ width: 250 }}
                      renderInput={(params) => <TextField {...params} fullWidth variant="outlined" />}
                    />
                  </StyledTableCell>
                  {/* <StyledTableCell>{'selectedVendor[i]?.pricePerUnit'}</StyledTableCell> */}
                  <StyledTableCell><TextField className={classes.textField} id="newPrice" value={newPrice[i]} onChange={(e) => handlePrice(e, i)} type="number" variant="outlined" /></StyledTableCell>
                </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <button onClick={createSO} className='procurement-create-btn'>Create</button>
        </div>}
      </div > : <div>Please Upload a BOM Material for this Scope</div>
      }
    </div >
  )
}

export default connect(null, { setLoading, setSnackInfo })(RaiseSoModal)
