import React from 'react'
// import { Route } from 'react-router-dom';
import PrivateRoute from '../components/privateRoute'
import Dashboard from '../pages/dashboard'
import FinanceOverview from '../pages/companyP&L/financeOverview'
import ApprovalForManualTransaction from '../components/approvalForManualTransaction'
// import ChangePasswordScreen from '../components/changePasswordScreen'
import CustomerInformation from '../components/customerInformation'
import OptimasationFilesView from '../pages/optimisationFiles/view'
import OptimasationFilesUpload from '../pages/optimisationFiles/upload'
import OpenPo from '../pages/companyP&L/openPos'
import BOM from '../pages/bom'
import StoreStocks from '../pages/store/stocks'
import ProjectDocuments from '../pages/design/projectDocuments'
// import SendToFinance from '../pages/design/sendToFinance'
import FinanceApprove from '../pages/finance'
import RawLeads from '../components/rawLeads'
import Scoping from '../pages/scoping'
import Inwarding from '../pages/inwarding'
import ApprovePoSo from '../pages/companyP&L/approvePoSoMtr'
import ApprovePayments from '../pages/companyP&L/approvePayments'
import StorePayment from '../pages/store/payment'
import User from '../pages/userManagement/users'
import Roles from '../pages/userManagement/roles'
import Department from '../pages/userManagement/departments'
import Team from '../pages/userManagement/teams'
import Vendors from '../pages/vendorManagement/vendors'
import FactoryPayment from '../pages/factory/payment'
import FactoryStocks from '../pages/factory/stocks'
import MaterialTransfer from '../pages/materialTransfer'
import ManageCategory from '../pages/masterManagement/manageCategory'
import ManageScopes from '../pages/masterManagement/manageScopes'
import ManageUnits from '../pages/masterManagement/manageUnits'
import ManagePaymentModes from '../pages/masterManagement/managePaymentModes'
import ManageSubCategory from '../pages/masterManagement/manageSubCategory'
import ManageItems from '../pages/masterManagement/manageMaterials'
import ManageOrgRate from '../pages/masterManagement/manageOrgRate'
import InwardingForProjects from '../pages/companyP&L/inwarding'
import InwardingForFactory from '../pages/factory/inwarding'
import InwardingForStore from '../pages/store/inwarding'
import FactoryOutwarding from '../pages/factory/outwarding'
import StoreOutwarding from '../pages/store/outwarding'
import QTProject from '../pages/qtProjects'
import Storelead from '../pages/storelead'
import ManageQTCustomer from '../pages/manageQTCustomer'
import ManageQuotation from '../pages/manageQuotation'
import ApproveVendorAndMaterialMapping from '../pages/vendorManagement/approveVendorAndMaterialMapping'
import manageProjectLog from '../pages/manageProjectLog'
import createLead from '../pages/leadCreation'
import createNewCustomer from '../pages/leadCreationCustomer'
import marketingSource from '../pages/masterManagement/marketingSource'
import discountApproval from '../pages/discountApproval'
import marketingBudget from '../pages/marketingBudget'
import ShowStatusInformation from '../components/showStatusInfo'
import ManageProjectRate from '../pages/masterManagement/manageProjectRate'
import manageTarget from '../pages/masterManagement/manageTarget'
import managerCustomer from '../pages/customers/managerCustomer'
import ReminderReport from '../pages/reminder'
import Home from '../pages/home'
import AssignProjects from '../pages/customers/assignProject';
import MoveToDesign from '../pages/customers/moveToDesign';
import Location from '../pages/location'
import ExperienceCenter from '../pages/experienceCenter'
import DesignerAssignmentView from '../components/designerAssignmentView';
import leadApprovalView from '../components/leadApprovalView';
import ComplimentaryApprovalView from '../components/complimentaryApprovalView'
import CheckLists from '../components/checkList';
import manageDiscount from '../pages/masterManagement/manageDiscount';
import vendorPo from '../pages/vendorManagement/vendorPo'
import AllPo from '../pages/vendorManagement/vendorPo/allPo'

import BomMaterialForApproval from '../pages/bom/bomMaterialForApproval'
import LeadDesignToExecution from '../components/leadDesignToExecution';
import ContractSignLeads from '../pages/contractSignLead';
import VerifyDocuments from '../components/verifyDocuments';
import ValidateContractsDocuments from '../components/validateContractDocuments';
import SiteQcProjects from '../pages/siteQCProject';
import ManageSiteQcProjectLogs from '../pages/siteQCProject/projectlog';
import ReadyToDispatchProject from '../pages/readyToDispatchProject/projects';
import ManageReadyToDispatchProjectLogs from '../pages/readyToDispatchProject/projectLogs'
import ManageExecutionProjectLogs from '../pages/underExecution/projectlog'
import ViewCustomerCheckList from '../pages/viewChecklist';
import ProjectMaterilData from '../pages/siteQCProject/projectMaterial';
// import ViewCustomerCheckList from '../pages/viewChecklist';
// import TermsAndCondition from '../components/termAndCondition';
import TargetDetails from '../components/target-details';
import StageWiseLeads from '../components/stagewiseleads'
import customerSurveyFormsList from '../pages/customerSurveyFormsList'

import Material_Return from '../pages/material/Material_Return'
import Approve_Material_Return from '../pages/material/Approve_Material_Return'
import RealFormDetails from '../pages/customerSurveyFormDetails';
import AddEditCheckList from '../pages/checklist';
import FinalPaymentApproval from '../components/finalPaymentApproval';
import ImosCompletedProjects from '../pages/siteQCProject/imosCompletedProject';
import imosDashboardStageDetails from '../components/imosDashboardStageDetails'
import ExecutionDashBoardDetail from '../components/executionDashBoardDetail'
import ExecutionDashBoardProjectDetail from '../components/executionDashBoardProjectDetails'
import DesignDashBoard from '../components/designstatistics'
import ContractLeads from '../components/contractlead'
import CHMLeads from '../pages/chmLeads'
import ChmUserLeads from '../pages/chmUserLeads'
import UnderExecutionProject from '../pages/underExecution/projects'
import Completed from '../pages/underExecution/projectcompleted'
import CompletedMWP from '../pages/underExecution/projectCompletedMWP'
import OperationOne from '../components/operationsOne';
// import DesignKickOff from '../pages/design_kick-off;
import DesignKickOff from '../pages/design_kick-off'
import { useSelector } from 'react-redux';
import { ROLENAME } from '../utils/index';
import vendorSOManage from '../pages/vendorSOManage'
import callCenterLogs from '../components/callCenterLogs'
import InwardingInvoices from '../pages/InwardingInvoices/InwardingInvoices'
import ProjectCompletionStats from '../pages/StatisticsReport/ProjectCompletionStats'
import DesignSignOffStats from '../pages/StatisticsReport/DesignSignOffStats'
import MaterialDeliveryStats from '../pages/StatisticsReport/MaterialDeliveryStats'
import BookingStats from '../pages/StatisticsReport/BookingStats'
import paymentApprover from '../components/paymentApprover'
import paymentDetails from '../components/paymentDetails'
import hdfcVPC from '../components/hdfcVPC'
// import pendingInwardingApprovals from '../pages/pendingInwardingApprovals'
// import MultiCheckCreatePo from '../components/MultiCheckCreatePo'
import customerInformation from '../components/customerInformation'
import MultiCheckCreatePo from '../components/MultiCheckCreatePo'
import PaymentGenrateLink from '../components/PaymentGenrateLink'
import WorkingDrawingReceived from '../components/WorkingDrawingReceived'
import FactoryReviewSheet from '../pages/FactoryReviewSheet'
import QtChangesApproval from '../pages/QtChangesApproval'
import LostLeadApprovalView from '../components/LostLeadApprovalView'
import chmTicketForm from '../pages/chmTicketForm'
import CHMuserDashboard from '../pages/CHMuserDashBoard'
import ChmAddTicketCategory from '../components/chmAddTicketCategory'
import CustomerLoginActivity from '../pages/cx_customer/customerLoginActivity'
import DesignHoldView from '../components/DesignHoldView'
import UserAccessManagement from '../components/userAccessMangement'
import leadToMeeting from '../components/leadToMeeting'
import leadToOrder from '../components/leadToOrder'
import meetingToOrder from '../components/meetingToOrder'
import leadInMarketing from '../components/leadInMarketing'
import leadCriteria from '../components/leadCriteria'
import FaqCreation from '../components/faqCreation'
import CxStageSetting from '../pages/cxStageSetting'
import downloadCenter from '../components/downloadCenter'
import DesignHoldApprovalView from '../components/designHoldApproval.js';
import ApprovedPaymentReceivedAnalysis from '../pages/ApprovedPaymentReceivedAnalysis/index.js'
import KnowLedgeHub from '../components/knowLedgeHub'
import TaskManagementDashBoard from '../pages/TaskManagementDashBoard'

const Index = () => {
	const { userDetails } = useSelector(state => state.user)
	return (
		<>
			<PrivateRoute component={Dashboard} path="/dashboard" exact />
			<PrivateRoute component={downloadCenter} path="/download-center" exact />
			{/* Start - Payment Integration */}
			<PrivateRoute component={paymentDetails} path="/paymentDetails" exact />
			<PrivateRoute component={hdfcVPC} path="/hdfc-virtual-payment" exact />
			{/* End - Payment Integration */}
			<PrivateRoute component={KnowLedgeHub} path="/knowledge_hub" exact />
			<PrivateRoute component={DesignDashBoard} path="/designstats" exact />
			<PrivateRoute component={ContractLeads} path="/contractleads" exact />
			<PrivateRoute component={QTProject} path="/qtProjects" exact />
			<PrivateRoute component={FinanceOverview} path="/companyP&L/financeOverview" exact />
			<PrivateRoute component={CustomerInformation} path="/financeApprovalScreen&L/customerInformation" exact />
			<PrivateRoute component={ApprovalForManualTransaction} path="/financeApprovalScreen&L/approvalForManualTransaction" exact />
			<PrivateRoute component={ApprovedPaymentReceivedAnalysis} path="/financeApprovalScreen&L/approved-payment-receipts" exact />
			{/* <PrivateRoute component={ChangePasswordScreen} path="/change_password" exact /> */}
			{/* <Route component={ChangePasswordScreen} path="/change_password" exact /> */}
			<PrivateRoute component={OptimasationFilesView} path="/optimisation_files/view" exact />
			<PrivateRoute component={OptimasationFilesUpload} path="/optimisation_files/upload" exact />
			{
				(userDetails.roles?.find(role =>role.name === "Operation Head") && 
				<>
			<PrivateRoute component={ApprovePoSo} path="/companyP&L/approvePoSoMtr" exact />
			   </>
				)
			}			
			<PrivateRoute component={ApprovePayments} path="/companyP&L/approvePayments" exact />
			<PrivateRoute component={RawLeads} path="/raw-leads" exact />
			<PrivateRoute component={InwardingForProjects} path="/companyP&L/inwarding" exact />
			<PrivateRoute component={OpenPo} path="/companyP&L/openPo" exact />
			<PrivateRoute component={ProjectDocuments} path="/uploadProjectDocuments" exact />
			{/* <PrivateRoute component={SendToFinance} path="/design/sendToFinance" exact /> */}
			<PrivateRoute component={FinanceApprove} path="/finance" exact />
			<PrivateRoute component={Scoping} path="/scoping" exact />
			<PrivateRoute component={Inwarding} path="/inwarding" exact />
			<PrivateRoute component={StoreStocks} path="/store/stocks" exact />
			<PrivateRoute component={StorePayment} path="/store/payments" exact />
			<PrivateRoute component={InwardingForStore} path="/store/inwarding" exact />
			<PrivateRoute component={StoreOutwarding} path="/store/outwarding" exact />
			<PrivateRoute component={FactoryStocks} path="/factory/stocks" exact />
			<PrivateRoute component={InwardingForFactory} path="/factory/inwarding" exact />
			<PrivateRoute component={FactoryOutwarding} path="/factory/outwarding" exact />
			<PrivateRoute component={FactoryPayment} path="/factory/payments" exact />
			<PrivateRoute component={BOM} path="/bom" exact />
			<PrivateRoute component={User} path="/userManagement/users" exact />
			<PrivateRoute component={Roles} path="/userManagement/roles" exact />
			<PrivateRoute component={Department} path="/userManagement/departments" exact />
			<PrivateRoute component={Team} path="/userManagement/teams" exact />
			<PrivateRoute component={Vendors} path="/vendorManagement/vendors" exact />
			<PrivateRoute component={vendorPo} path="/vendor_po" exact />
			<PrivateRoute component={AllPo} path="/vendor_po/all" exact />
			<PrivateRoute component={MaterialTransfer} path="/materialTransfer" exact />
			<PrivateRoute component={ManageQTCustomer} path="/manage-customer/:customerId" exact />
			<PrivateRoute component={ManageCategory} path="/masterManagement/manageCategory" exact />
			<PrivateRoute component={ManageScopes} path="/masterManagement/manageScope" exact />
			<PrivateRoute component={ManageUnits} path="/masterManagement/manageUnit" exact />
			<PrivateRoute component={ManagePaymentModes} path="/masterManagement/managePaymentModes" exact />
			<PrivateRoute component={ManageSubCategory} path="/masterManagement/manageSubCategory" exact />
			<PrivateRoute component={ManageItems} path="/masterManagement/manageItems" exact />
			{/* <PrivateRoute component={ManageItems} path="/masterManagement/manageItems" exact /> */}
			<PrivateRoute component={ManageOrgRate} path="/masterManagement/manageOrgRate" exact />
			<PrivateRoute component={ManageProjectRate} path="/masterManagement/manageProjectRate" exact />
			<PrivateRoute component={ManageQuotation} path="/manageQuotations/:leadId" exact />
			{
				(userDetails.roles?.find(role =>role.name === "Operation Head") && 
				<>
			<PrivateRoute component={ApproveVendorAndMaterialMapping} path='/vendorManagement/approveVendorsAndMaterialMapping' exact />
			   </>
				)
			}	
			<PrivateRoute component={manageProjectLog} path="/project-logs/:projectId" exact />
			<PrivateRoute component={createLead} path="/create-new-lead" exact />
			<PrivateRoute component={createNewCustomer} path="/create-new-customer" exact />
			<PrivateRoute component={CxStageSetting} path="/customer-app-designstage-setting" exact />

			<PrivateRoute component={marketingSource} path="/masterManagement/marketing-sources" exact />
			<PrivateRoute component={discountApproval} path="/discount-approval" exact />
			{
			(userDetails?.roles?.find(role =>role.name === "Assistant Design Manager" || role.name === 'Operation Manager' || role.name === 'Finance Manager' || role.name === 'Design Manager' || role.name === 'Operation Design Manager' || role.name === 'DGM Ops') && 
			<>
				<PrivateRoute component={QtChangesApproval} path="/lead-quotation-changes-approval" exact />
			</>
			)
			}
			<PrivateRoute component={marketingBudget} path="/set-marketing-budget" exact />
			<PrivateRoute component={ShowStatusInformation} path="/status-information/:statusName/:startDate/:endDate/:check/:isUser" exact />
			<PrivateRoute component={manageTarget} path="/set-team-target" exact />
			<PrivateRoute component={Location} path="/location" exact />
			<PrivateRoute component={ExperienceCenter} path="/experienceCenter" exact />
			<PrivateRoute component={managerCustomer} path="/customer/manager-customer" exact />
			<PrivateRoute component={ReminderReport} path="/reminder" exact />
			<PrivateRoute component={Home} path="/home" exact />
			<PrivateRoute component={AssignProjects} path="/customer/assign-lead" exact />
			<PrivateRoute component={MoveToDesign} path="/customer/move-to-design" exact />
			<PrivateRoute component={DesignerAssignmentView} path="/designer-assignment/:statusName/:startDate/:endDate/:userId/:isUser" exact />
			<PrivateRoute component={leadApprovalView} path="/lead-approval" exact />
			<PrivateRoute component={ComplimentaryApprovalView} path="/complimentary-approval" exact />
			<PrivateRoute component={LostLeadApprovalView} path="/lost-approvals" exact />
			<PrivateRoute component={leadCriteria} path="/lead-criteria" exact />
			<PrivateRoute component={FaqCreation} path="/faq-details" exact />
			<PrivateRoute component={DesignHoldView} path="/design-signoff-hold-projects" exact />
			<PrivateRoute component={CheckLists} path="/check-lists" exact />
			<PrivateRoute component={manageDiscount} path="/manage-discount" exact />
			{
				(userDetails.roles?.find(role => role.name === ROLENAME.OperationHead || role.name === "BOM Manager" ) && 
				<>
			<PrivateRoute component={BomMaterialForApproval} path="/bom/materialsForApproval" exact />
			   </>
				)
			}		
			<PrivateRoute component={LeadDesignToExecution} path="/lead-design-to-execution/:startDate/:endDate" exact />
			<PrivateRoute component={DesignHoldApprovalView} path="/lead-design-hold-approval" exact />
			<PrivateRoute component={ContractSignLeads} path="/contract-sign-leads" exact />
			<PrivateRoute component={VerifyDocuments} path="/verify-documents/:leadId" exact />
			<PrivateRoute component={ValidateContractsDocuments} path="/validate-contract-documents/:leadId" exact />
			<PrivateRoute component={SiteQcProjects} path="/siteqc/projects" exact />
			<PrivateRoute component={ManageSiteQcProjectLogs} path="/siteqc/project-detail/:projectId" exact />
			<PrivateRoute component={ReadyToDispatchProject} path="/ready-to-dispatch-project" exact />
			<PrivateRoute component={ManageReadyToDispatchProjectLogs} path="/ready-to-dispatch-project/logs/:projectId" exact />
			{/* <PrivateRoute component={ManageExecutionProjectLogs} path="/underexecution-project/logs/:projectId" exact /> */}
			<PrivateRoute component={ManageExecutionProjectLogs} path="/:pageName/logs/:projectId" exact />
			<PrivateRoute component={ViewCustomerCheckList} path="/view-customer-checklist/:leadId" exact />
			<PrivateRoute component={ProjectMaterilData} path="/project-materials-details" exact />
			{/* <PrivateRoute component={CustomerPrivewQuotationView} path="/final-quotation-customer-approval" exact /> */}
			{/* <PrivateRoute component={ViewCustomerCheckList} path="/view-customer-checklist/:leadId" exact /> */}
			{/* <PrivateRoute component={TermsAndCondition} path="/terms-and-condition" exact /> */}
			<PrivateRoute component={TargetDetails} path="/target-details" exact />
			<PrivateRoute component={StageWiseLeads} path="/Sales-lead-wise-total-data" exact />
			<PrivateRoute component={customerSurveyFormsList} path="/view-customer-survey-forms" exact />
			<PrivateRoute component={Material_Return} path="/material_return" exact />
			{
				(userDetails.roles?.find(role => role.name === ROLENAME.OperationHead ) && 
				<>
			<PrivateRoute component={Approve_Material_Return} path="/approve_material_return" exact />
			   </>
				)
			}
			<PrivateRoute component={RealFormDetails} path="/customer-survey-form-details" exact />
			<PrivateRoute component={AddEditCheckList} path="/add-checklist" exact />
			<PrivateRoute component={FinalPaymentApproval} path="/final-payment-approval" exact />
			<PrivateRoute component={ImosCompletedProjects} path="/siteqc/completed-projects" exact />
			<PrivateRoute component={imosDashboardStageDetails} path="/imos-stage-projects/:imosStage" exact />
			<PrivateRoute component={ExecutionDashBoardDetail} path="/execution-stage-projects/:executionStage" exact />
			<PrivateRoute component={ExecutionDashBoardProjectDetail} path="/execution-dashboard-projects/:executionStage" exact />
			<PrivateRoute component={CHMLeads} path="/chm-leads" exact />
			<PrivateRoute component={ChmUserLeads} path="/chm-user-leads/:chmUserId" exact />
			<PrivateRoute component={UnderExecutionProject} path="/under-execution-project" exact />
			<PrivateRoute component={Completed} path="/project-completed" exact />
			<PrivateRoute component={CompletedMWP} path="/project-Completed-MWP" exact />
			<PrivateRoute component={Storelead} path="/store-lead" exact />
			<PrivateRoute component={vendorSOManage} path="/vendor-so-manage" exact />
			<PrivateRoute component={callCenterLogs} path="/call-center-logs" exact />
			<PrivateRoute component={InwardingInvoices} path="/inwarding-invoices" exact />

			{/* Routes for Statistics SubMenu */}
			<PrivateRoute component={BookingStats} path="/designstats/design-review-sheet" exact />
			{/* <PrivateRoute component={DesignSignOffStats} path="/designstats/design-sign-off" exact />
			<PrivateRoute component={MaterialDeliveryStats} path="/designstats/material-delivery" exact />
			<PrivateRoute component={ProjectCompletionStats} path="/designstats/project-completion" exact /> */}
			<PrivateRoute component={paymentApprover} path="/payment-approver" exact />
			<PrivateRoute component={DesignKickOff} path="/design_kick-off" exact />
			{/* <PrivateRoute component={pendingInwardingApprovals} path="/companyP&L/pendingInwardingApprovals" exact /> */}
			{/* <PrivateRoute component={MultiCheckCreatePo} path="/items-to-be-ordered" exact /> */}
			<PrivateRoute component={MultiCheckCreatePo} path="/items-below-MOQ" exact />
			<PrivateRoute component={WorkingDrawingReceived} path="/Working-Drawing-Received" exact />

			{/* Route for Factory Review Sheet Dashboard */}
			<PrivateRoute component={FactoryReviewSheet} path="/factory-review-sheet" exact />
			{ userDetails?.roles.find((role) => role.name === ROLENAME.CHM_Manager || role.name === ROLENAME.CHM_User || role.name === ROLENAME.Admin || role.name === ROLENAME.TeleCallerUser || role.name === ROLENAME.TeleCallerManager) && <PrivateRoute component={chmTicketForm} path="/CHM-Ticket-Creation" exact /> }
			{/* { !userDetails?.roles.find((role) => role.name === ROLENAME.CHM_Manager || role.name === ROLENAME.CHM_User || role.name === ROLENAME.Admin || role.name === ROLENAME.TeleCallerUser || role.name === ROLENAME.TeleCallerManager) && <PrivateRoute component={CHMuserDashboard} path="/AssignedCHMticket" exact /> } */}
			{/* { userDetails?.roles.find((role) => role.name === ROLENAME.CHM_Manager || role.name === ROLENAME.CHM_User || role.name === ROLENAME.Admin || role.name === ROLENAME.TeleCallerUser || role.name === ROLENAME.TeleCallerManager || role.name === ROLENAME.OperationHead) && <PrivateRoute component={CHMuserDashboard} path="/CHMuserDashboard" exact />} */}
			{ <PrivateRoute component={CHMuserDashboard} path="/CHMuserDashboard" exact />}
			{ userDetails?.roles.find((role) => role.name === ROLENAME.Admin) && <PrivateRoute component={ChmAddTicketCategory} path="/ChmAddTicketCategory" exact />}
			<PrivateRoute component={PaymentGenrateLink} path="/payment-genrate-link-approval" exact />
			<PrivateRoute component={CustomerLoginActivity} path="/customer-login-activity" exact />
			<PrivateRoute component={UserAccessManagement} path="/accessmanagement" exact />
			{ <PrivateRoute component={TaskManagementDashBoard} path="/Task-Management-DashBoard" exact />}

			{/* Removed Routes */}
			{/* <PrivateRoute component={OperationOne} path="/operation_one" exact /> */}
			{ userDetails?.roles.find((role) => role.name === ROLENAME.Admin || role.name === ROLENAME.SalesHead || role.name === ROLENAME.SalesManager || role.name === ROLENAME.AssistantSalesManager || role.name === ROLENAME.SalesUser) && <PrivateRoute component={leadToMeeting} path="/Lead-To-Meeting" exact />}
			{ userDetails?.roles.find((role) => role.name === ROLENAME.Admin || role.name === ROLENAME.SalesHead || role.name === ROLENAME.SalesManager || role.name === ROLENAME.AssistantSalesManager || role.name === ROLENAME.SalesUser) && <PrivateRoute component={meetingToOrder} path="/Meeting-To-Order" exact />}
			{ userDetails?.roles.find((role) => role.name === ROLENAME.Admin || role.name === ROLENAME.SalesHead || role.name === ROLENAME.SalesManager || role.name === ROLENAME.AssistantSalesManager || role.name === ROLENAME.SalesUser) && <PrivateRoute component={leadToOrder} path="/Lead-To-Order" exact />}
			{ userDetails?.roles.find((role) => role.name === ROLENAME.Admin || role.name === ROLENAME.SalesHead || role.name === ROLENAME.SalesManager || role.name === ROLENAME.AssistantSalesManager || role.name === ROLENAME.SalesUser) && <PrivateRoute component={leadInMarketing} path="/Lead-In-Marketing" exact />}
		</>
	)
}

export default Index