import React, { useState, useEffect } from 'react';
import { useSelector, connect } from 'react-redux';
import { useHistory, Link } from "react-router-dom";
import { Divider, Paper, TextField, Button, FormControl, MenuItem, Grid, Radio, RadioGroup, FormControlLabel, FormLabel, InputLabel, Select, Input, Checkbox, ListItemText, Typography, Card, CardContent, FormHelperText } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { ROLENAME, designDepartment, salesDepartment } from '../../utils/index';
import { Table, TableBody, TableCell, TableContainer, TableRow, TableHead, Box, Accordion, AccordionSummary, AccordionDetails, AccordionActions } from '@material-ui/core';
import moment from 'moment';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import HttpService from '../../services/httpService'
import { setUserDetails } from '../../redux/actions/userAction';
import { setLoading, setSnackInfo } from '../../redux/actions/appAction';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import isEmpty from 'lodash/isEmpty';
// import RichTextEditor from 'react-rte';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    tablealign: {
        display: "flex"
    },
    tablecontainer: {
        display: "flex",
        marginTop: "25px"
    },
    avatarContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    textField: {
        margin: '30px',
        width: '80%',
    },
    FormControl: {
        margin: '30px'
    },
    dateField:{
      width: "250px",
      margin: '30px',
    },
    textFieldContainer: {
      display: 'flex',
      gap: theme.spacing(2), // Adjust spacing as needed
      alignItems: 'center',
      justifyContent: 'space-between'
    },
   
  
}));

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#ccc",
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);


const ChmTicketForm = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const { userDetails } = useSelector(state => state.user)

    const [ticketTitle, setTicketTitle] = useState('');
    const [otherProjectDetails, setOtherProjectDetails] = useState({
      name: '',
      mobile: '',
      email: ''
    });
    const [ticketDescription, setTicketDescription] = useState('');
    const [ticketETAdate, setTicketETAdate] = useState('');
    const [ticketFollowUpDate, setTicketFollowUpDate] = useState('');

    const [selectedTagValue, setSelectedTagValue] = useState('uniqueLeadNumber');

    const [projectNo, setProjectNo] = useState('');
    const [leadNo, setLeadNo] = useState('');
    const [uniqueLeadNo, setUniqueLeadNo] = useState('');
    const [autoComplete, setAutoComplete] = useState(JSON.parse(localStorage.getItem('CHMTicketLeadDetails')));

    const [searchData, setSearchData] = useState([]);
    const [leadNoData, setLeadNoData] = useState([]);
    const [uniqueLeadNoData, setUniqueLeadNoData] = useState([]);
    const [ProjectNoData, setProjectNoData] = useState([]);

    const [files, setFiles] = useState([""]);
    const [allDepartments, setAllDepartments] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState({});
    const [usersOfSelectedTeam, setUsersOfSelectedTeam] = useState([]);
    const [selectedUser, setSelectedUser] = useState({});
    const [selectedTeam, setSelectedTeam] = useState({});
    const [categoryData, setCategoryData] = useState([]);
    const [selectedCategoryData, setSelectedCategoryData] = useState({});
    const [completeLeadData, setCompleteLeadData] = useState(JSON.parse(localStorage.getItem('CHMTicketLeadDetails')));

    useEffect(()=>{
        getDataOnPageLoading()
    },[])

    const resetFormFields = () => {
      setProjectNo('');
      setLeadNo('');
      setUniqueLeadNo('');
      setAutoComplete('');

      // setSearchData([]);
      // setLeadNoData([]);
      // setUniqueLeadNoData([]);
      // setProjectNoData([]);

      setFiles([""]);
      setAllDepartments([]);
      setSelectedDepartment({});
      setUsersOfSelectedTeam([]);
      setSelectedUser({});
      setSelectedTeam({});
      setTicketTitle('');
      setOtherProjectDetails('');
      setTicketDescription('');
      setTicketETAdate('');
      setTicketFollowUpDate('');
      setSelectedCategoryData({});
    };
    
    
    const handleTickeTitleInputChange = (e)=>{
        setTicketTitle(e.target.value)
    }
    const handleOtherTypeOfTicket = (event) => {
      const { name, value } = event.target;
      setOtherProjectDetails((prevDetails) => ({
        ...prevDetails,
        [name]: value
      }));
    };
  
    
    const handleTickeDescriptionInputChange = (e)=>{
        setTicketDescription(e.target.value)
    }
    const handleETAdateInputChange = (e) => {
      // setTicketETAdate(e.target.value)
      const selectedDate = new Date(e.target.value);
      const currentDate = new Date();
      currentDate.setHours(0,0,0,0)

      // Check if the selected date is in the future or today
      if (selectedDate >= currentDate) {
        setTicketETAdate(e.target.value);
      } else {
        props.setSnackInfo("Please select a date in the present or future.",'warning');
      }
    };
    const handleticketFollowUpDateInputChange = (e)=>{
      // setTicketFollowUpDate(e.target.value)
      const selectedDate = new Date(e.target.value);
      const currentDate = new Date();
      currentDate.setHours(0,0,0,0);

      // Check if the selected date is in the future or today
      if (selectedDate >= currentDate) {
        setTicketFollowUpDate(e.target.value);
      } else {
        props.setSnackInfo("Please select a date in the present or future.",'warning');
      }

    }
    const handleLeadTagChange = async (e)=>{
        setSelectedTagValue(e.target.value)
        setAutoComplete('')
    }
    const handleLeadSelectionAutoComplete = async (e,v)=>{
      if(v){
          setAutoComplete(v)
          const leadId = v._id;
          const response = await HttpService.getLeadDetailsForChmTicket(leadId);
          setCompleteLeadData(response.data)
        }
    }
    const handleTeamSelectionAutocomplete = (v)=>{
      if(v!=null){
        setSelectedTeam(v)
        const teamUsers = selectedDepartment?.users?.filter((user)=> (user.teamId &&  user?.teamId)=== v?._id)
        setUsersOfSelectedTeam(teamUsers)
      }
    }
    const onImageChangeCheck = async (e, index) => {
        const newFiles = [...files];

            props.setLoading(true);
            const fd = new FormData();
            fd.append("file", e.target.files[0]);
            // fd.append('customerName', projectDetails.lead_no);

            try {
                const fileUploadRes = await HttpService.uploadFile(fd);
                newFiles[index] = fileUploadRes.data;
                setFiles(newFiles);
            } catch (err) {
                props.setSnackInfo(err?.response?.data ?? "Something went wrong", "error");
            }
            props.setLoading(false);
    }

    const handleOtherTicket = async(e) =>{
      try{
          const obj = {
              ticketTitle:ticketTitle,
              ticketDescription:ticketDescription,
              ticketETAdate:ticketETAdate,
              ticketFollowUpDate:ticketFollowUpDate,
              selectedTagValue:selectedTagValue,
              selectedLead:autoComplete,
              otherProjectDetails : otherProjectDetails,
              selectedDepartment:selectedDepartment,
              selectedTeam:selectedTeam,
              selectedUser:selectedUser,
              uploadedFiles:files,
              userDetails: userDetails,
              selectedCategoryData:selectedCategoryData._id
          }

          const response = await HttpService.createCHMticket(obj);
          if(response.status == 201){
            props.setSnackInfo('Ticket Created Successfully',"Success")
            resetFormFields();
            setTimeout(()=>{
              userDetails?.roles?.find(role => role.name === ROLENAME.Admin || role.name === ROLENAME.CHM_Manager || role.name === ROLENAME.CHM_User || role.name === ROLENAME.TeleCallerUser ||  role.name === ROLENAME.TeleCallerManager) ?
              history.push('/CHMuserDashboard') : history.push('/AssignedCHMticket')
            },1000)
          }
      }
      catch(error){
          console.log(error);
          props.setSnackInfo('Some Error Occoured',error.message);
      }
    }

    

    const handleSubmitButton = async (e)=>{
        try{
            // const obj = {
            //     ticketTitle:ticketTitle,
            //     ticketDescription:ticketDescription,
            //     ticketETAdate:ticketETAdate,
            //     ticketFollowUpDate:ticketFollowUpDate,
            //     selectedTagValue:selectedTagValue,
            //     selectedLead:autoComplete,
            //     selectedDepartment:selectedDepartment,
            //     selectedTeam:selectedTeam,
            //     selectedUser:selectedUser,
            //     uploadedFiles:files,
            //     userDetails: userDetails,
            //     selectedCategoryData:selectedCategoryData._id
            // }
            let obj = {
                ticketTitle:ticketTitle,
                ticketDescription:ticketDescription,
                ticketETAdate:ticketETAdate,
                ticketFollowUpDate:ticketFollowUpDate,
                selectedTagValue:selectedTagValue,
                selectedLead:autoComplete,
                otherProjectDetails : otherProjectDetails,
                // selectedDepartment:selectedDepartment,
                // selectedTeam:selectedTeam,
                // selectedUser:selectedUser,
                uploadedFiles:files,
                userDetails: userDetails,
                selectedCategoryData:selectedCategoryData._id
            }
            const DSEArray = ['Design', 'Sales', 'Service', 'Procurement', 'Factory', 'Operations']
            const requiredDepartmentIdArray = allDepartments.filter((dp)=> DSEArray.includes(dp.name)).map((el)=> el._id)
            // console.log('completeLeadData.departmentId is ',completeLeadData.departmentId)

            if(requiredDepartmentIdArray.includes(completeLeadData.departmentId) && completeLeadData.assignTo && completeLeadData.currentStage!=='Completed')
            {
              obj.selectedDepartment = {_id: completeLeadData.departmentId};
              obj.selectedTeam = {_id: completeLeadData.teamId};
              obj.selectedUser = {_id: completeLeadData.assignTo};
            }
            else{
              obj.selectedDepartment = {_id: allDepartments.find((dp)=>dp.name === 'CHM')._id};

              const chmTeamArraySize = allDepartments.find((dp)=>dp.name === 'CHM').teams.length;
              const rndTeam = Math.floor(Math.random() * chmTeamArraySize);
              const selectedTeamId = allDepartments.find((dp)=>dp.name === 'CHM').teams[rndTeam]._id

              obj.selectedTeam = {_id: selectedTeamId};
              // console.log('selectedTeamId is ',selectedTeamId)
              const rndUsers =  allDepartments.find((dp)=>dp.name === 'CHM').users.filter((usr)=> usr.teamId === selectedTeamId);
              // console.log('rndUsers', rndUsers);
              const randNum = Math.floor(Math.random() * rndUsers.length);
              obj.selectedUser = {_id: rndUsers[randNum]._id};
            }
            // console.log('obj is ',obj)

            const response = await HttpService.createCHMticket(obj);

            if(response.status == 201){
              props.setSnackInfo('Ticket Created Successfully',"Success")
              resetFormFields();
              // props.setSnackInfo('Redirected Page',"Info")
              localStorage.removeItem('CHMTicketLeadDetails');
              setTimeout(()=>{
                userDetails?.roles?.find(role => role.name === ROLENAME.Admin || role.name === ROLENAME.CHM_Manager || role.name === ROLENAME.CHM_User || role.name === ROLENAME.TeleCallerUser ||  role.name === ROLENAME.TeleCallerManager) ?
                history.push('/CHMuserDashboard') : history.push('/AssignedCHMticket')
              },1000)
            }
        }
        catch(error){
            console.log(error);
            props.setSnackInfo('Some Error Occoured',error.message);
        }
    }

    const getDataOnPageLoading = async () => {
        props.setLoading(true)
        try{
            const departments = await HttpService.getDepartments();
            const categories = await HttpService.getAllCHMticketCategory();
            const leadDetailsAutoComplete = await HttpService.getAllLeadDetailsForCHMticketGeneration();

            setSearchData(leadDetailsAutoComplete.data);
            setLeadNoData(leadDetailsAutoComplete.data.filter((el)=>{ return el.lead_no}))
            setUniqueLeadNoData(leadDetailsAutoComplete.data.filter((el)=>{ return el.uniqueLeadNumber}))
            setProjectNoData(leadDetailsAutoComplete.data.filter((el)=>{ return el.erpProjectNo}))
            setAllDepartments(departments.data.filter((data)=>data !==null && data !==undefined));
            // setCategoryData(categories.data.filter((data)=>data !==null && data !==undefined));

            const selectedCHMArr = ["Referral Bonus", "Refund", "Balance Statement", "Installation Query", "Invoice Query", "Design Query", "Production Query", "False Ceiling", "Snag", "Partial Material Delivery", "DKO Rejection", "Sales query", "Carpenter Issue", "Other query", "Career", "Vendor", "Marketing_Old" ]
            // const arr = categories.data.filter((el)=> selectedCHMArr.includes(el.value));
            const arrExc = categories.data.filter((el)=> !selectedCHMArr.includes(el.value));
            // console.log('arr is ',arr.map((el)=>el.value))
            // console.log('arrExc is ',arrExc.map((el)=>el.value))
            // setCategoryData(arrExc);
            setCategoryData(arrExc.filter((data)=>data !==null && data !==undefined));
        }
        catch(error){
            console.log(error)
        }
        props.setLoading(false);
    }

    const handleAddFileInput = () => {
        let newFiles = [...files];
        newFiles.push("");
        setFiles(newFiles);
    };

    const handleRemoveFileInput = (index) => {
        const newFiles = files.filter((file,id) => id !== index);
        setFiles([...newFiles]);
    }

    return (
      <div>
        {userDetails?.roles?.find(
          (role) =>
            role.name === ROLENAME.CHM_Manager ||
            role.name === ROLENAME.CHM_User ||
            role.name === ROLENAME.TeleCallerUser ||
            role.name === ROLENAME.TeleCallerManager || 
            role.name === ROLENAME.Admin
        ) && (
          <Paper>
            <Grid
              container
              className={classes.tablecontainer}
              direction="column"
              justifyContent="space-evenly"
              alignItems="flex-start"
              key="parentGridContainer"
            >
              <TextField
                id="ticketTitle"
                key="ticketTitle"
                name="Ticket Title"
                label="Ticket Title"
                value={ticketTitle}
                defaultValue="Ticket Title"
                onChange={handleTickeTitleInputChange}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                className={classes.textField}
                required
              />

              <TextField
                id="ticketDescription"
                key="ticketDescription"
                name="Ticket Description"
                label="Ticket Description"
                value={ticketDescription}
                defaultValue="Ticket Description"
                onChange={handleTickeDescriptionInputChange}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                className={classes.textField}
                required
              />

              {categoryData && (
                <Autocomplete
                  value={selectedCategoryData}
                  key="selectedCategoryData"
                  onChange={(e, newValue) => setSelectedCategoryData(newValue)}
                  options={categoryData}
                  getOptionLabel={(option) => option?.value}
                  style={{ width: "20%", marginBottom: 10, marginLeft: 25 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Tag Category"
                      fullWidth
                      variant="outlined"
                    />
                  )}
                />
              )}

              <TextField
                id="ticketETAdate"
                key="ticketETAdate"
                name="ticketETAdate"
                label="Resolution Expected Date"
                type="date"
                value={ticketETAdate}
                onChange={handleETAdateInputChange}
                InputLabelProps={{
                  shrink: true,
                }}
                className={classes.dateField}
                required
              />

              <TextField
                id="ticketFollowUpDate"
                key="ticketFollowUpDate"
                name="ticketFollowUpDate"
                label="Follow Up Date"
                type="date"
                value={ticketFollowUpDate}
                onChange={handleticketFollowUpDateInputChange}
                InputLabelProps={{
                  shrink: true,
                }}
                className={classes.dateField}
                required
              />

              {files?.map((file, index) => (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    margin: 10,
                    padding: 2,
                  }}
                >
                  <FormControl style={{ width: 500 }}>
                    <input
                      key={`${index}`}
                      accept="*"
                      className={classes.input}
                      id={`contained-button-file-${index}`}
                      name="chmImageAttachment"
                      onChange={(e) => onImageChangeCheck(e, index)}
                      type="file"
                      style={{ display: "none" }}
                    />
                    <label htmlFor={`contained-button-file-${index}`}>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ background: "#716ACA" }}
                        component="span"
                      >
                        Upload Customer Issue Attachments
                      </Button>
                    </label>
                  </FormControl>
                  {files[index] !== "" && (
                    <CheckCircleIcon
                      style={{
                        verticalAlign: "middle",
                        color: "green",
                        fontSize: 30,
                        marginLeft: 10,
                      }}
                    />
                  )}
                  <Button
                    onClick={() => handleAddFileInput(index)}
                    startIcon={<AddCircleOutlineRoundedIcon />}
                    style={{ marginLeft: 10 }}
                  />
                  {index !== 0 && (
                    <Button
                      onClick={() => handleRemoveFileInput(index)}
                      startIcon={<DeleteOutlineOutlinedIcon />}
                      style={{ marginLeft: 10 }}
                    />
                  )}
                </div>
              ))}

              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
           <FormControl className={classes.FormControl}>
        <FormLabel id="demo-radio-buttons-group-label">Select Project number</FormLabel>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          value={selectedTagValue}
          onChange={handleLeadTagChange}
          name="radio-buttons-group"
        >
          <FormControlLabel
            value="lead_no"
            control={<Radio />}
            label="Lead No"
          />
          <FormControlLabel
            value="erpProjectNo"
            control={<Radio />}
            label="Project No"
          />
          <FormControlLabel
            value="uniqueLeadNumber"
            control={<Radio />}
            label="Unique Lead No"
          />
          <FormControlLabel
            value="other"
            control={<Radio />}
            label="Other"
          />
        </RadioGroup>

        {selectedTagValue === 'other' && (
        <div className={classes.textFieldContainer}>
        <TextField
          id="otherLeadName"
          key="otherLeadName"
          name="name"
          label="Enter Name"
          variant="outlined"
          value={otherProjectDetails.name}
          onChange={handleOtherTypeOfTicket}
          className={classes.textField}
          required
        />
        <TextField
          id="otherLeadPhone"
          key="otherLeadPhone"
          name="mobile"
          label="Enter Phone"
          variant="outlined"
          value={otherProjectDetails.mobile}
          onChange={handleOtherTypeOfTicket}
          inputProps={{
            minLength: 10,
            maxLength: 10,
            pattern: "[0-9]*"
          }}
          className={classes.textField}
          required
        />
       <TextField
        id="otherEmail"
        key="otherEmail"
        name="email"
        label="Enter Email"
        variant="outlined"
        value={otherProjectDetails.email}
        onChange={handleOtherTypeOfTicket}
       
        className={classes.textField}
        required   
      />
      </div>
        )}
      </FormControl>

                 {selectedTagValue !== 'other' && (
                  <>
                <Autocomplete
                  value={autoComplete}
                  onChange={(e, v) => handleLeadSelectionAutoComplete(e, v)}
                  options={
                    selectedTagValue === "lead_no"
                      ? leadNoData
                      : selectedTagValue === "erpProjectNo"
                      ? ProjectNoData
                      : selectedTagValue === "uniqueLeadNumber"
                      ? uniqueLeadNoData
                      : []
                      // was previously null, updated 
                  }
                  getOptionLabel={(option) =>
                    option[`${selectedTagValue}`]?.toString()
                  }
                  style={{ width: "20%", marginBottom: 10, marginLeft: 25 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Tag Ticket"
                      fullWidth
                      variant="outlined"
                    />
                  )}
                />
                </>
                 )}
              </Grid>

              { selectedTagValue === 'other' && (
               <> <Autocomplete
                value={selectedDepartment}
                onChange={(e, newValue) => newValue ? setSelectedDepartment(newValue) : setSelectedDepartment({})}
                options={allDepartments}
                getOptionLabel={(option) => option?.name}
                style={{ width: "20%", marginBottom: 10, marginLeft: 25 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Tag Department"
                    fullWidth
                    variant="outlined"
                  />
                )}
              />

              {selectedDepartment?.teams && (
                <Autocomplete
                  value={selectedTeam}
                  // onChange={(e, newValue) => setSelectedTeam(newValue)}
                  onChange={(e, newValue) => handleTeamSelectionAutocomplete(newValue)}
                  options={selectedDepartment?.teams}
                  getOptionLabel={(option) => option?.name}
                  style={{ width: "20%", marginBottom: 10, marginLeft: 25 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Tag Team"
                      fullWidth
                      variant="outlined"
                    />
                  )}
                />
              )}

              {selectedDepartment?.users && (
                <Autocomplete
                  value={selectedUser}
                  onChange={(e, newValue) => newValue ? setSelectedUser(newValue) : setSelectedUser({})}
                  options={usersOfSelectedTeam}
                  getOptionLabel={(option) => option?.name}
                  style={{ width: "20%", marginBottom: 10, marginLeft: 25 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Tag User"
                      fullWidth
                      variant="outlined"
                    />
                  )}
                />
              )}
              </>
              )}

              {
                selectedTagValue === 'other' ? (
               <>
              <Button
                color="primary"
                variant="contained"
                style={{
                  padding: 10,
                  width: 180,
                  textAlign: "center",
                  margin: 20,
                  height: 30,
                }}
                onClick={handleOtherTicket}
                disabled={
                  ticketTitle === "" ||
                  ticketDescription === "" ||
                  ticketETAdate === "" ||
                  ticketFollowUpDate === "" ||
                  otherProjectDetails.name === "" ||
                  otherProjectDetails.mobile === "" ||
                  isEmpty(selectedDepartment) ||
                  isEmpty(selectedTeam) ||
                  isEmpty(selectedUser) ||
                  isEmpty(selectedCategoryData)
                }
              >
                Create New Ticket
              </Button>
              </>)
              :
              <>
              <Button
              color="primary"
              variant="contained"
              style={{
                padding: 10,
                width: 180,
                textAlign: "center",
                margin: 20,
                height: 30,
              }}
              onClick={handleSubmitButton}
              disabled={
                ticketTitle === "" ||
                ticketDescription === "" ||
                ticketETAdate === "" ||
                ticketFollowUpDate === "" ||
                autoComplete === "" ||
                autoComplete === null ||
                // isEmpty(selectedDepartment) ||
                // isEmpty(selectedTeam) ||
                // isEmpty(selectedUser) ||
                isEmpty(selectedCategoryData)
              }
            >
              Create New Ticket
            </Button>
            </>}
            </Grid>
          </Paper>
        )}
      </div>
    );
}
export default connect(null, { setUserDetails, setLoading, setSnackInfo })(ChmTicketForm);