import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setLoading, setSnackInfo } from "../redux/actions/appAction";
import HttpService from "../services/httpService";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow, TableHead } from "@material-ui/core";
import moment from "moment";
import TablePagination from "@material-ui/core/TablePagination";
import { Button } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import * as XLSX from 'xlsx';
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 850,
  },
  tableHeader:{
    position: 'sticky',
    top: 0,
    zIndex: 1,
    backgroundColor: "#2C2E3E"
  },
  tableCell: { fontWeight: "800", textAlign:"center", color: 'white', minWidth: '150px' },
  textField: {
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(5),
    width: 300
  },
  paper: {
    padding: "2rem 2rem 2rem 2rem",
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  heading: { margin: 'auto', textAlign: "center", color: "white", backgroundColor: "#2C2E3E", padding: 10, borderRadius: 50, width: "50%", fontSize: 20, marginBottom: 20},
  downloadCsvButton: { backgroundColor: '#a7f2af', color: 'black', padding: '1', marginRight: '0.5em', marginLeft: '0.5em', borderRadius: '20px', alignSelf: "center", marginBottom: 20, "&:hover": { backgroundColor: "#124a17", color: 'white' } },
  resetButton: { backgroundColor: 'cfd0d4', color: 'black', padding: '1', marginRight: '2%', borderRadius: '20px', alignSelf: "center", marginLeft: 20, "&:hover": { backgroundColor: "#4f5052", color: 'white' } },
  filterButton: { backgroundColor: '#b7ccf7', color: '#151921', padding: '1', marginRight: '0.5em', borderRadius: '20px', "&:hover": { backgroundColor: "#14419c", color: 'white' } },
  searchInput: {
    borderRadius: '50px',
    borderColor: '#2196F3'
  },
  noArrow: {
    '& .MuiSelect-icon': {
      display: 'none',
    },
  }
}));
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
const CallCenterLogs = (props) => {
  const [callCenterLogsData, setCallCenterLogsData] = useState([])
  const [selectedSearchType, setSelectedSearchType] = useState([]);
  const [searchTypes, setSearchTypes] = useState([ {name: 'Client Phone Number'}, {name: 'Agent Phone Number'}, {name: 'Call Status'}, {name: 'Call Direction'} ]);
  const [searchInput, setSearchInput] = useState("")
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCallLogsCount, setTotalCallLogsCount] = useState(0);
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const classes = useStyles();
  
  const handle_call_center_logs = async () => {
    try {
      let response = await HttpService.get_call_center_logs(rowsPerPage)
      
      setCallCenterLogsData(response.data.result)
      setTotalCallLogsCount(response.data.totalCallCenterLogs)
    } catch(error) {
      console.log('error: ', error.message)
      console.error(error)
    }
  }
  const handle_filter_click = async (input) => {
    props.setLoading(true)
    try {
      let obj = {}
      obj.startDate = startDate
      obj.endDate = endDate
      obj.searchInput = searchInput
      obj.selectedSearchType = selectedSearchType
      obj.rowsPerPage = rowsPerPage
      if(input.newPage) {
        obj.newPage = input.newPage
      }
      if(input.newRowsPerPage) {
        obj.rowsPerPage = Number(input.newRowsPerPage)
      }
      let response = await HttpService.get_filtered_call_center_logs(obj)
      if(response.status == 202) {
        props.setLoading(false)
        props.setSnackInfo(response.data, 'error')
        return
      }
      setCallCenterLogsData(response.data.result)    
      setTotalCallLogsCount(response.data.totalCallCenterLogs)
    } catch(error) {
      console.log('error: ', error.message)
      console.error(error)
    }
    props.setLoading(false)
  }
  const handle_search_type_selection = (value) => {
    setSelectedSearchType(value)
    setSearchInput('')
  }
  const handle_download_csv_click = async () => {
    props.setLoading(true)
    try {
      const wb = XLSX.utils.book_new();
      let callCenterLogsDataForCSV = []
      for(let i = 0; i < callCenterLogsData.length; i++) {
        let obj = {}
        obj['Sl. No'] = i+1
        obj['Client Phone Number'] = callCenterLogsData[i]['caller_number']
        obj['Agent Phone Number'] = callCenterLogsData[i]['agent_number']
        obj['Call Ringing Date'] = moment(callCenterLogsData[i]['call_date']).format('DD-MM-YYYY')
        obj['Call Ringing Time'] = callCenterLogsData[i]['call_time']
        obj['Call Duration'] = callCenterLogsData[i]['call_duration']
        obj['Call Recording Link'] = callCenterLogsData[i]['recording_url']
        obj['Call Direction'] = callCenterLogsData[i]['call_direction']
        obj['Call Status'] = callCenterLogsData[i]['call_status']
        
        callCenterLogsDataForCSV.push(obj)
      }
      const ws = XLSX.utils.json_to_sheet(callCenterLogsDataForCSV);
      XLSX.utils.book_append_sheet(wb, ws, 'Call Center Logs');
      XLSX.writeFile(wb, 'call-center-logs.xlsx');
    }
    catch(error)
    {
      console.log('error', error.message)
      console.error(error)
    }
    props.setLoading(false)
  }
  const handle_change_page_click = (event, newPage) => {
    setPage(newPage);
    handle_filter_click({newPage: newPage})
  };
  const handle_change_rows_per_page_click = (event) => {
      setRowsPerPage(Number(event.target.value));
      setPage(0);
      handle_filter_click({newRowsPerPage: Number(event.target.value)})
  };
  const clearFilter = () => {
    setStartDate('')
    setEndDate('')
    setSelectedSearchType([])
    setSearchInput("")
    setPage(0)
    setRowsPerPage(10)
    handle_call_center_logs()
    
  }
  useEffect(() => {
    props.setLoading(true)
    handle_call_center_logs()
    props.setLoading(false)
  },[]);
  return (
    <div>
      <Button disabled={false} className={classes.downloadCsvButton} variant="contained" onClick={handle_download_csv_click} >
        Download CSV
      </Button>
      <Paper style={{ textAlign: 'center', marginBottom: 20, display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '50%', margin: 'Auto' }}>
          <TextField
            select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedSearchType}
            label="Select Type"
            name="searchType"
            onChange={(e) => handle_search_type_selection(e.target.value)}
            variant="outlined"
            style={{width: '50%', marginRight: '2%'}}
            size="small"
            className={classes.noArrow}
            >
            {searchTypes.map((el) =>(
              <MenuItem value={el}>{el.name}</MenuItem>
            ))}
          </TextField>
          <TextField label={`Enter your input`} name="searched" size="small" value={searchInput} onChange={(e) => {setSearchInput(e.target.value)}} variant="outlined" style={{width: '80%'}} />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1%', marginBottom: '1%' }}>
          <TextField
              id="date"
              name="startDate"
              label="From Date"
              type="date"
              variant="outlined"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              InputLabelProps={{
                  shrink: true,
              }}
              fullWidth
              className={classes.textField}
              style={{ width: '30%', marginRight: '2%', marginLeft: '2%' }}
              size="small"
          />
          <TextField
              id="date"
              name="endDate"
              label="Till Date"
              type="date"
              variant="outlined"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              InputLabelProps={{
                  shrink: true,
              }}
              fullWidth
              className={classes.textField}
              style={{ width: '30%', marginRight: '2%', marginLeft: '2%' }}
              size="small"
          />
          <Button disabled={false} className={classes.filterButton} variant="contained" style={{ alignSelf: "center", marginLeft: 20 }} onClick={handle_filter_click} >
                Filter
          </Button>
          <Button className={classes.resetButton} variant="contained" onClick={()=>clearFilter()} >
                Reset
          </Button>
        </div>
      </Paper>
      <Paper style={{ textAlign: 'center', marginBottom: 20, display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
        <TableContainer component={Paper}>
          <Table className="table table-responsive table-bordered" aria-label="sticky table">
            <TableHead>
              <StyledTableRow>
                <TableCell colSpan={9} style={{padding: '0'}}>
                  <TablePagination
                    rowsPerPageOptions={[10, 50, 100, 500, { label: "All", value: totalCallLogsCount }]}
                    count={totalCallLogsCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handle_change_page_click}
                    onRowsPerPageChange={handle_change_rows_per_page_click}
                    style={{ display: 'flex', justifyContent: 'flex-start', border: 'none'}}
                  />
                </TableCell>
              </StyledTableRow>
              <StyledTableRow className={classes.tableHeader}>
                <TableCell className={classes.tableCell}>S.No</TableCell>
                <TableCell className={classes.tableCell}>Client Phone Number</TableCell>
                <TableCell className={classes.tableCell}>Agent Phone Number</TableCell>
                <TableCell className={classes.tableCell}>Call Ringing Date</TableCell>
                <TableCell className={classes.tableCell}>Call Ringing Time</TableCell>
                <TableCell className={classes.tableCell}>Call Duration</TableCell>
                <TableCell className={classes.tableCell}>Call Recording Link</TableCell>
                <TableCell className={classes.tableCell}>Call Direction</TableCell>
                <TableCell className={classes.tableCell}>Call Status</TableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {callCenterLogsData.map((row, i) => {
                return (
                  <TableRow key={i + 1}>
                    <TableCell style={{textAlign:"center" }}>{i + 1}</TableCell>
                    <TableCell style={{textAlign:"center" }}>{row.caller_number ? row.caller_number : 'NA'}</TableCell>
                    <TableCell style={{textAlign:"center" }}>{row.agent_number ? row.agent_number : 'NA'}</TableCell>
                    <TableCell style={{textAlign:"center" }}>{row.call_date ? moment(row.call_date).format('DD-MM-YYYY') : 'NA'}</TableCell>
                    <TableCell style={{textAlign:"center" }}>{row.call_time ? row.call_time : 'NA'}</TableCell>
                    <TableCell style={{textAlign:"center" }}>{row.call_duration ? row.call_duration : 'NA'}</TableCell>
                    {row.recording_url && row.recording_url != "None" ?
                    <TableCell style={{textAlign:"center" }} onClick={() => window.open(row.recording_url)}>
                      <a
                      href={row.recording_url} 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      style={{ color: 'blue', textDecoration: 'none' }}
                      >
                        <span 
                          style={{ textDecoration: 'none' }}
                          onMouseOver={e => e.currentTarget.style.color = 'red'}
                          onMouseOut={e => e.currentTarget.style.color = 'inherit'}
                        >
                          Click to Download
                        </span>
                      </a>
                    </TableCell> : <TableCell style={{textAlign:"center" }}>NA</TableCell>}
                    <TableCell style={{textAlign:"center" }}>{row.call_direction ? row.call_direction : 'NA'}</TableCell>
                    <TableCell style={{textAlign:"center" }}>{row.call_status ? row.call_status : 'NA'}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>  
        </TableContainer>
      </Paper>
    </div>
  );
};
export default connect(null, { setLoading, setSnackInfo })(CallCenterLogs);