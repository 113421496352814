import React, { useState, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { setLoading, setSnackInfo } from '../../../redux/actions/appAction'
import { getAllRoles, getUniqueRoleFields } from '../../../redux/actions/rolesActions'
import { getAllScopes } from '../../../redux/actions/projectAction';
import { TextField, InputLabel, Checkbox, MenuItem, FormControl, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, } from '@material-ui/core';
import HttpService from '../../../services/httpService';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  }
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#ccc",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Roles = (props) => {
  const classes = useStyles();

  const [addRoleModal, setAddRoleModal] = useState(false)
  const [roleName, setRoleName] = useState("")
  // const [rolesList, setRolesList] = useState([])
  // const [uniqueRoleFields, setUniqueRoleFields] = useState([])
  const [selectedRole, setSelectedRole] = useState("")
  const [selectedRoleData, setSelectedRoleData] = useState({})
  // const { scopes } = useSelector(state => state.project)
  const { rolesList, uniqueRoleFields } = useSelector(state => state.roles)
  // const [selectedScopes, setSelectedScopes] = useState([])
  useEffect(() => {
    getAllRoles()
    getUniqueRoleFields()
    getAllScopes()
    // eslint-disable-next-line
  }, [selectedRoleData])

  const getAllRoles = async () => {
    props.setLoading(true)
    try {
      await props.getAllRoles()
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data, "error")
    }
    props.setLoading(false)
  }

  const getUniqueRoleFields = async () => {
    props.setLoading(true)
    try {
      await props.getUniqueRoleFields()
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data, "error")
    }
    props.setLoading(false)
  }

  const getAllScopes = async () => {
    props.setLoading(true)
    try {
      await props.getAllScopes()
    } catch (error) {
      console.error(error)
      props.setSnackInfo('Error fetching scopes', "error")
    }
    props.setLoading(false)
  }
  const addRole = async () => {
    props.setLoading(true)
    try {
      await HttpService.addRole({ roleName })
      props.setSnackInfo('Role created successfully.Give access permissions', "success")
      setAddRoleModal(false)
      getAllRoles()
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  const handleChange = (e) => {
    setSelectedRole(e.target.value)
    setSelectedRoleData(rolesList.filter(role => role.name === e.target.value)[0])
    // setSelectedScopes(rolesList.filter(role => role.name === e.target.value)[0].scopes.map(scope => scope._id))
  }

  const handleCheck = async (push, value, type) => {
    props.setLoading(true)
    try {
      if (type === "CAN")
        await HttpService.editRole({ id: selectedRoleData._id, push, value })
      else
        await HttpService.editScope({ id: selectedRoleData._id, push, value })
      props.setSnackInfo('Field Updated', "success")
      const result = await HttpService.getAllRoles()
      const filteredData = result.data.filter(role => role.name === selectedRoleData.name)[0]
      setSelectedRoleData(filteredData)
      // setSelectedScopes(filteredData.scopes.map(scope => scope._id))
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button variant="contained" color="primary" onClick={() => setAddRoleModal(true)}>
          Add Role
        </Button>
      </div>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Roles</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          style={{ width: 250 }}
          value={selectedRole}
          onChange={handleChange}
          label="Roles"
        >
          <MenuItem value=""> <em>None</em></MenuItem>
          {rolesList?.map(role => <MenuItem value={role.name}>{role.name}</MenuItem>)}
        </Select>
      </FormControl>
      {selectedRole !== "" &&
        <div>
          <TableContainer component={Paper}>
            <Table className={classes.table} size="small" aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Sl No</StyledTableCell>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell>Create</StyledTableCell>
                  <StyledTableCell>Read</StyledTableCell>
                  <StyledTableCell>Update</StyledTableCell>
                  <StyledTableCell>Delete</StyledTableCell>
                  <StyledTableCell>Upload</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {uniqueRoleFields?.map((data, i) => (
                  <StyledTableRow key={i + 1}>
                    <StyledTableCell>{i + 1}</StyledTableCell>
                    <StyledTableCell>{data}</StyledTableCell>
                    <StyledTableCell><Checkbox onChange={() => handleCheck(!selectedRoleData?.can.includes(`${data}::CREATE`), `${data}::CREATE`, "CAN")} checked={selectedRoleData?.can.includes(`${data}::CREATE`)} /></StyledTableCell>
                    <StyledTableCell><Checkbox onChange={() => handleCheck(!selectedRoleData?.can.includes(`${data}::READ`), `${data}::READ`, "CAN")} checked={selectedRoleData?.can.includes(`${data}::READ`)} /></StyledTableCell>
                    <StyledTableCell><Checkbox onChange={() => handleCheck(!selectedRoleData?.can.includes(`${data}::UPDATE`), `${data}::UPDATE`, "CAN")} checked={selectedRoleData?.can.includes(`${data}::UPDATE`)} /></StyledTableCell>
                    <StyledTableCell><Checkbox onChange={() => handleCheck(!selectedRoleData?.can.includes(`${data}::DELETE`), `${data}::DELETE`, "CAN")} checked={selectedRoleData?.can.includes(`${data}::DELETE`)} /></StyledTableCell>
                    <StyledTableCell><Checkbox onChange={() => handleCheck(!selectedRoleData?.can.includes(`${data}::UPLOAD`), `${data}::UPLOAD`, "CAN")} checked={selectedRoleData?.can.includes(`${data}::UPLOAD`)} /></StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {/* <div style={{ marginTop: 10, background: "#fff" }}>
            <Typography variant="h6" gutterBottom>
              Assign Scopes
            </Typography>
            {scopes?.map(data => <div style={{ width: "33%" }}>
              <Checkbox onChange={() => handleCheck(!selectedScopes.includes(data._id), data._id, "SCOPE")} checked={selectedScopes.includes(data._id)} />{data.name}
            </div>)}
          </div> */}
        </div>
      }
      <Dialog
        open={addRoleModal}
        TransitionComponent={Transition}
        keepMounted
        fullWidth={true}
        maxWidth={'sm'}
        onClose={() => setAddRoleModal(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Add Role"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description" style={{ display: "flex", flexDirection: "column" }}>
            <TextField label="Role" style={{ marginBottom: 10 }} value={roleName} onChange={(e) => setRoleName(e.target.value)} variant="outlined" />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={addRole} color="primary">
            ADD
          </Button>
        </DialogActions>
      </Dialog>
    </div >
  )
}

export default connect(null, { setLoading, setSnackInfo, getAllScopes, getUniqueRoleFields, getAllRoles })(Roles)
