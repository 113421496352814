import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import { useHistory } from "react-router-dom";
// import HttpService from '../services/httpService';
import { setLoading, setSnackInfo } from '../redux/actions/appAction';
import { Paper, Divider, Grid, Button } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { ROLENAME } from '../utils/index';
import { commaSeparateAmount } from './commaSeparate';
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: 'black',
    },
}));

const TopHeaderOption = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const { userDetails } = useSelector(state => state.user);
    const [marketingUserCount, setMarketingUserCount] = useState({});

    useEffect(() => {
        checkUser(props.role)
    if ((props.role === ROLENAME.SalesManager || props.role === ROLENAME.SalesHead || props.role === ROLENAME.AssistantSalesManager)) {
        separeteLeadStatusWise(props.salesdata);
    }
        // eslint-disable-next-line
    }, [props])

    const checkUser = (roleid) => {
        if (roleid === ROLENAME.MarketingManager || roleid === ROLENAME.MarketingUser) {
            getLeadCountDetails(props.marketingdata);
        } else if ((roleid === ROLENAME.SalesManager || roleid === ROLENAME.SalesHead || props.role === ROLENAME.AssistantSalesManager)) {
            if (props.isUserSelected && props.isUserSelected.length === 0){
                getSalesLeadCountDetails(props.salesdata);
            } 
        } else if (roleid === ROLENAME.SalesUser) {
            getSalesLeadCountDetails(props.salesdata);
        } else {
            separeteLeadStatusWise(props.selectedUserDetails);
        }

    }

    // Get Marketing user Lead details
    const getLeadCountDetails = async (data) => {
        Object.keys(data).forEach(function (key) {
            if (key === props.selectedexpcenter) {
                var value = data[key];
                separeteLeadStatusWise(value);
            }
        });
    }

    // Get Sales user and manager Lead details
    const getSalesLeadCountDetails = async (data) => {
        separeteLeadStatusWise(data);
    }

    const separeteLeadStatusWise = (data) => {
        if (props.role === ROLENAME.MarketingManager) {
            setMarketingUserCount({ target: data.target, targetAchieved: data.achived })
        } else if (props.role === ROLENAME.MarketingUser || props.role === ROLENAME.SalesUser) {
            setMarketingUserCount(data)
        } else if (props.role === ROLENAME.SalesManager || props.role === ROLENAME.SalesHead || props.role === ROLENAME.AssistantSalesManager) {
            data.forEach(ele => {
                if (ele.name === 'topInfo') {
                    setMarketingUserCount(ele)
                }
            })
        }
    }

    const createLeadRoute = (link) => {
        history.push(link)
    }

    const changePageRoute = (data) => {
        if (props.role === ROLENAME.SalesManager || props.role === ROLENAME.SalesHead || props.role === ROLENAME.AssistantSalesManager) {
            let userId = [];
            if (props.selectedUserDetails.length !== 0) {
                let filterUser = props.selectedUserDetails.filter((ele, i) => ele.userId);
                filterUser.forEach((ele, i) => {
                    userId.push(ele.userId);
                })
            }
            history.push(`/status-information/${data}/${props.selecteddate.start}/${props.selecteddate.end}/status/false`, {
                experienceCenterId: props.selectedexpcenterid,
                locationId: props.selectedlocationid,
                data: marketingUserCount,
                selectedUserId: userId,
                filterOption: props.filteroption,
                displayData: props.salesdata
            });
        } else {
            history.push(`/status-information/${data}/${props.selecteddate.start}/${props.selecteddate.end}/status/false`, {
                experienceCenterId: props.selectedexpcenterid,
                locationId: props.selectedlocationid,
                data: marketingUserCount.info,
                selectedUserId: [],
                filterOption: props.filteroption
            });
        }
    }

    const changePageRouteNew = (toGoStage)=>{
        if(toGoStage === 'leadToMeeting')
        {
        history.push(`/Lead-To-Meeting`, {
            data: marketingUserCount,
            displayData: props.salesdata
        });}
        else if(toGoStage === 'leadToOrder')
        {
        history.push(`/Lead-To-Order`, {
            data: marketingUserCount,
            displayData: props.salesdata
        });}
        else if(toGoStage === 'meetingToOrder')
        {
        history.push(`/Meeting-To-Order`, {
            data: marketingUserCount,
            displayData: props.salesdata
        });}
        else if(toGoStage === 'leadInMarketing')
        {
        history.push(`/Lead-In-Marketing`, {
            data: marketingUserCount,
            displayData: props.salesdata
        });}
    }

    const showAchivedVsTargetData = (currentRole) => {
        let currentUserInfo = currentRole === 'Sales Head' ? userDetails.departmentId :
            currentRole === "Sales Manager" ? userDetails.teamId : userDetails._id;
        history.push(`/target-details`, {
            experienceCenterId: props.selectedexpcenterid,
            locationId: props.selectedlocationid,
            filterOption: props.filteroption,
            currentSelecredRole: currentRole,
            currentUserInfo: currentUserInfo,
            startDate: props.selecteddate.start,
            endDate: props.selecteddate.end
        });
    }
    return (
        <div>
            <Divider />

            <Paper className={classes.paper} style={{ marginTop: 20 }}>
                <div style={{ display: 'flex', marginTop: 10, marginBottom: 10 }}>
                    <div className={classes.root}>
                        <Grid container spacing={3}>
                            {
                                props.role === ROLENAME.MarketingUser && <>
                                    <Grid item md={6} xs={12}>
                                        <Paper className={classes.paper} style={{ height: '75px' }}>
                                            <Button style={{ padding: 0 }} onClick={() => createLeadRoute('create-new-lead')} color="primary">
                                                Create Lead
                                            </Button>
                                        </Paper>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <Paper className={classes.paper}>
                                            <Button style={{ padding: 0 }} onClick={() => changePageRoute('To-Be-Called')} color="primary">
                                                To Be Called <br />
                                                ({marketingUserCount.toBeCalledCount ? marketingUserCount.toBeCalledCount : `0`})
                                            </Button>
                                        </Paper>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <Paper className={classes.paper}>
                                            <Button style={{ padding: 0 }} onClick={() => changePageRoute('Hold')} color="primary">
                                                On Hold <br />
                                                ({marketingUserCount.onHoldCount ? marketingUserCount.onHoldCount : `0`})
                                            </Button>
                                        </Paper>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <Paper className={classes.paper}>
                                            <Button style={{ padding: 0 }} onClick={() => changePageRoute('Junk')} color="primary">
                                                Junk <br />
                                                ({marketingUserCount.junkLeadCount ? marketingUserCount.junkLeadCount : `0`})
                                            </Button>
                                        </Paper>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <Paper className={classes.paper}>
                                            <Button style={{ padding: 0 }} onClick={() => changePageRoute('Lead-Returned-By-Sales')} color="primary">
                                                Lead Returned By Sales <br />
                                                ({marketingUserCount.leadReturnedFromSalesCount ? marketingUserCount.leadReturnedFromSalesCount : `0`})
                                            </Button>
                                        </Paper>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <Paper className={classes.paper}>
                                            <Button style={{ padding: 0, cursor: 'initial', color: '#000' }} color="primary">
                                                Target Achieved Vs Target <br />
                                                ({marketingUserCount.achived ? marketingUserCount.achived : `0`} / {marketingUserCount.target ? marketingUserCount.target : `0`})
                                            </Button>
                                        </Paper>
                                    </Grid>
                                </>
                            }

                            {
                                props.role === ROLENAME.MarketingManager && <>
                                    <Grid item md={6} xs={12}>
                                        <Paper className={classes.paper} style={{ height: '75px' }}>
                                            <Button style={{ padding: 0 }} onClick={() => createLeadRoute('set-marketing-budget')} color="primary">
                                                Enter Spend Detail
                                            </Button>
                                        </Paper>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <Paper className={classes.paper}>
                                            <Button style={{ padding: 0, cursor: 'initial', color: '#000' }} color="primary">
                                                Target Achieved Vs Target <br />
                                                ({marketingUserCount.targetAchieved ? marketingUserCount.targetAchieved : `0`} / {marketingUserCount.target ? marketingUserCount.target : `0`})
                                            </Button>
                                        </Paper>
                                    </Grid>
                                </>
                            }

                            {
                                props.role === ROLENAME.SalesUser && <>
                                    <Grid item xs={12} >
                                        <Grid container spacing={2}>
                                            <Grid item md={6} xs={12}>
                                                <Paper className={classes.paper}>
                                                    <Button style={{ padding: 0 }} color="primary" onClick={() => showAchivedVsTargetData(props.role)}>
                                                        Won vs target (Current month) <br />
                                                        ( ₹ {marketingUserCount?.info?.achived ? commaSeparateAmount(marketingUserCount?.info?.achived) : `0`} /  ₹ {marketingUserCount?.info?.target ? commaSeparateAmount(marketingUserCount?.info?.target) : `0`})
                                                    </Button>
                                                </Paper>
                                            </Grid>
                                            {/* <Grid item  md={6} xs={12}>
                                                <Paper className={classes.paper}>
                                                    <Button style={{ padding: 0 }} onClick={() => changeSalesStagePageRoute('Won-Deals-Pending-Designer-Assignment', userDetails._id, 'user')} color="primary">
                                                        Won Deals Pending Designer Assignment <br />
                                                        ({marketingUserCount.info?.designerPending ? marketingUserCount.info.designerPending : `0`})
                                                    </Button>
                                                </Paper>
                                            </Grid> */}
                                            <Grid item md={6} xs={12}>
                                                <Paper className={classes.paper}>
                                                    <Button style={{ padding: 0 }} onClick={() => changePageRoute('Won-Deals-Pending-10-')} color="primary">
                                                        Won Deals Pending 10% <br />
                                                        ({marketingUserCount.info?.leadsPending10.length ? marketingUserCount.info.leadsPending10.length : `0`})
                                                    </Button>
                                                </Paper>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {marketingUserCount?.leadToMeeting && <>
                                        <Grid item md={6} xs={12}>
                                            <Paper className={classes.paper}>
                                                <Button style={{ padding: 0 }} color="primary" onClick={() => changePageRouteNew('leadToMeeting')}>
                                                    Lead To Meeting ({(marketingUserCount.leadToMeeting.leadToMeetingTotal !== 0) ? ((marketingUserCount.leadToMeeting.leadToMeetingVal / marketingUserCount.leadToMeeting.leadToMeetingTotal) * 100).toFixed(0) + "%" : `0%`}) <br />
                                                    ({marketingUserCount.leadToMeeting ? marketingUserCount.leadToMeeting.leadToMeetingVal + "/" + marketingUserCount.leadToMeeting.leadToMeetingTotal : `0`})
                                                </Button>
                                            </Paper>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <Paper className={classes.paper}>
                                                <Button style={{ padding: 0 }} color="primary" onClick={() => changePageRouteNew('meetingToOrder')}>
                                                    Meeting to order ({marketingUserCount.leadToMeeting.leadToMeetingVal !== 0 ? ((marketingUserCount.leadToMeeting.MeetingToOrder / marketingUserCount.leadToMeeting.leadToMeetingVal) * 100).toFixed(0) + "%" : `0%`}) <br />
                                                    ({marketingUserCount.leadToMeeting ? marketingUserCount.leadToMeeting.MeetingToOrder + "/" + marketingUserCount.leadToMeeting.leadToMeetingVal : `0`})
                                                </Button>
                                            </Paper>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <Paper className={classes.paper}>
                                                <Button style={{ padding: 0 }} color="primary" onClick={() => changePageRouteNew('leadToOrder')}>
                                                    Lead to order ({marketingUserCount.leadToMeeting.leadToMeetingTotal !== 0 ? ((marketingUserCount.leadToMeeting.MeetingToOrder / marketingUserCount.leadToMeeting.leadToMeetingTotal) * 100).toFixed(0) + "%" : `0%`}) <br />
                                                    ({marketingUserCount.leadToMeeting ? marketingUserCount.leadToMeeting.MeetingToOrder + "/" + marketingUserCount.leadToMeeting.leadToMeetingTotal : `0`})
                                                </Button>
                                            </Paper>
                                        </Grid>
                                    </>
                                    }
                                </>
                            }

                            {
                                (props.role === ROLENAME.SalesManager || props.role === ROLENAME.SalesHead || props.role === ROLENAME.AssistantSalesManager) && <>
                                    <Grid item xs={12} >
                                        <Grid container spacing={2}>
                                            <Grid item md={6} xs={12}>
                                                <Paper className={classes.paper}>
                                                    <Button style={{ padding: 0 }} color="primary" onClick={() => showAchivedVsTargetData(props.role)}>
                                                        Won vs target (Current month) <br />
                                                        {/* ({marketingUserCount.achived ? marketingUserCount.target : `0`}) */}
                                                        ( ₹ {marketingUserCount.achived ? commaSeparateAmount(marketingUserCount.achived) : `0`} / ₹ {marketingUserCount.target ? commaSeparateAmount(marketingUserCount.target) : `0`})
                                                    </Button>
                                                </Paper>
                                            </Grid>
                                            {/* <Grid item xs={4}>
                                                <Paper className={classes.paper}>
                                                    <Button style={{ padding: 0 }} onClick={() => changeSalesStagePageRoute('Won-Deals-Pending-Designer-Assignment', userDetails.teamId, 'team')} color="primary">
                                                        Won Deals Pending Designer Assignment <br />
                                                        ({marketingUserCount.designerPending ? marketingUserCount.designerPending : `0`})
                                                    </Button>
                                                </Paper>
                                            </Grid> */}
                                            <Grid item md={6} xs={12}>
                                                <Paper className={classes.paper}>
                                                    <Button style={{ padding: 0 }} onClick={() => changePageRoute('Won-Deals-Pending-10-')} color="primary">
                                                        Won Deals Pending 10% <br />
                                                        ({marketingUserCount.leadsPending10 ? marketingUserCount.leadsPending10.length : `0`})
                                                    </Button>
                                                </Paper>
                                            </Grid>
                                            {(marketingUserCount?.leadToMeeting) && <>
                                            {(marketingUserCount.leadToMeeting?.leadInMarketing) && <>
                                            <Grid item md={6} xs={12}>
                                            <Paper className={classes.paper}>
                                                <Button style={{ padding: 0 }} color="primary" onClick={() => changePageRouteNew('leadInMarketing')}>
                                                        Lead In Marketing ({marketingUserCount.leadToMeeting?.leadInMarketing}) <br />
                                                </Button>
                                            </Paper>
                                            </Grid>
                                            </>}
                                            <Grid item md={6} xs={12}>
                                                <Paper className={classes.paper}>
                                                    <Button style={{ padding: 0 }} color="primary" onClick={() => changePageRouteNew('leadToMeeting')}>
                                                            Lead To Meeting ({(marketingUserCount.leadToMeeting.leadToMeetingTotal !== 0 ) ? ((marketingUserCount.leadToMeeting.leadToMeetingVal / marketingUserCount.leadToMeeting.leadToMeetingTotal) * 100 ).toFixed(0)+ "%" : `0%`}) <br />
                                                            ({marketingUserCount.leadToMeeting ? marketingUserCount.leadToMeeting.leadToMeetingVal + "/" + marketingUserCount.leadToMeeting.leadToMeetingTotal: `0`})
                                                    </Button>
                                                </Paper>
                                            </Grid>
                                            <Grid item md={6} xs={12}>
                                                <Paper className={classes.paper}>
                                                    <Button style={{ padding: 0 }} color="primary" onClick={() => changePageRouteNew('meetingToOrder')}>
                                                            Meeting to order ({marketingUserCount.leadToMeeting.leadToMeetingVal !== 0 ? ((marketingUserCount.leadToMeeting.MeetingToOrder / marketingUserCount.leadToMeeting.leadToMeetingVal) * 100).toFixed(0) + "%" : `0%`}) <br />
                                                        ({marketingUserCount.leadToMeeting ? marketingUserCount.leadToMeeting.MeetingToOrder + "/" + marketingUserCount.leadToMeeting.leadToMeetingVal : `0`})
                                                    </Button>
                                                </Paper>
                                            </Grid>
                                            <Grid item md={6} xs={12}>
                                                <Paper className={classes.paper}>
                                                    <Button style={{ padding: 0 }} color="primary" onClick={() => changePageRouteNew('leadToOrder')}>
                                                            Lead to order ({marketingUserCount.leadToMeeting.leadToMeetingTotal !== 0 ? ((marketingUserCount.leadToMeeting.MeetingToOrder / marketingUserCount.leadToMeeting.leadToMeetingTotal) * 100).toFixed(0) + "%" : `0%`}) <br />
                                                            ({marketingUserCount.leadToMeeting ? marketingUserCount.leadToMeeting.MeetingToOrder + "/" + marketingUserCount.leadToMeeting.leadToMeetingTotal: `0`})
                                                    </Button>
                                                </Paper>
                                            </Grid>
                                            </>
                                                }
                                        </Grid>
                                    </Grid>
                                </>
                            }
                        </Grid>
                    </div>
                </div>
            </Paper>
        </div>
    )
}

export default connect(null, { setLoading, setSnackInfo })(TopHeaderOption)
