import React, { useState, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { setLoading, setSnackInfo } from '../../../redux/actions/appAction'
import { getAllMaterials } from '../../../redux/actions/vendorAction'
import { getAllUnits } from '../../../redux/actions/projectAction'
import HttpService from '../../../services/httpService'
import { Table, Button, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import EditIcon from '@material-ui/icons/Edit';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import Tooltip from '@material-ui/core/Tooltip';
import { Modal } from 'antd';
// import 'antd/dist/antd.css';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DownloadOutlined } from '@ant-design/icons';

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  }
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#ccc",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const Vendors = (props) => {
  const classes = useStyles();

  const [vendors, setVendors] = useState([])
  const [vendorsList, setVendorsList] = useState([])
  const [addVenorModal, setAddVendorModal] = useState(false)
  const [searchField, setSearchField] = useState("")
  const [isEdit, setIsEdit] = useState(false)
  const { units } = useSelector(state => state.project)
  const { allMaterials } = useSelector(state => state.vendor)
  useEffect(() => {
    getVendors()
    // eslint-disable-next-line
  }, [])

  const getVendors = async () => {
    props.setLoading(true)
    try {
      const result = await HttpService.getAllVendors()
      setVendors(result.data)
      setVendorsList(result.data)
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  const searchVendor = (e) => {
    setSearchField(e.target.value)
    setVendors(vendorsList.filter(vendor => vendor.name.toLowerCase().includes(e.target.value.toLowerCase())))
  }

  const [vendorDetails, setVendorDetails] = useState({
    _id: "",
    name: "",
    companyName: "",
    address: "",
    contactNumber: "",
    email: "",
    panNumber: "",
    gstinNumber: "",
    website: "",
    alternateNumber: "",
    category: "",
    aadharCardLink: "",
    panCardLink: "",
    bankStatementLink: "",
    aadharNumber:""
  })

  const [accountDetails, setAccountDetails] = useState({
    accountHolderName: "",
    accountNumber: "",
    ifsc: "",
    bankName: "",
    bankBranch: ""
  })

  const { name, companyName, address, contactNumber, email, panNumber, gstinNumber, website, alternateNumber, category, aadharCardLink, panCardLink, bankStatementLink, aadharNumber} = vendorDetails
  const { accountHolderName, accountNumber, ifsc, bankName, bankBranch } = accountDetails
  const addVendor = async () => {
    props.setLoading(true)
    try {
      let obj = {
        name, companyName, address, contactNumber, email, panNumber, gstinNumber, website, alternateNumber, accountDetails, category, aadharCardLink, panCardLink, bankStatementLink, aadharNumber
      }
      await HttpService.addVendor(obj)
      props.setSnackInfo('Vendor Sent For Approval', "success")
      getVendors()
      setAddVendorModal(false)
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }
  const getVendorById = async (vendorId) => {
    props.setLoading(true)
    try {
      const result = await HttpService.getVendorById(vendorId)
      const { name, companyName, address, contactNumber, email, panNumber, gstinNumber, website, alternateNumber, _id, category, accountDetails, aadharNumber } = result.data
      setVendorDetails({ name, companyName, address, contactNumber, email, panNumber, aadharNumber, gstinNumber, website, category, alternateNumber, _id })
      setAccountDetails(accountDetails)
      setIsEdit(true)
      setAddVendorModal(true)
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  const editVendor = async () => {
    props.setLoading(true)
    
    try {
      await HttpService.editVendor({...vendorDetails, accountDetails: {...accountDetails}})
      setIsEdit(false)
      setAddVendorModal(false)
      getVendors()
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  const [showVendorMaterialsModal, setShowVendorMaterialsModal] = useState(false)
  const [rowsToAdd, setRowsToAdd] = useState(0)
  const [selectedUom, setSelectedUom] = useState([])
  const [materials, setMaterials] = useState([])
  const [pricePerUnit, setPricePerUnit] = useState([])
  const [tableRows, setTableRows] = useState([{ value: null }])
  // const [allMaterials, setAllMaterials] = useState([])
  const [selectedVendor, setSelectedVendor] = useState("")

  const openVendorMaterialsModal = async (vendorId) => {
    props.setLoading(true)
    try {
      setSelectedVendor(vendorId)
      await props.getAllUnits()
      await getAllMaterials()
      const result = await HttpService.getVendorMaterialById(vendorId)
      console.log(result.data, "result")
      if (result.data?.length > 0) {
        addRows(null, result.data?.length)
        let uom = [...selectedUom]
        let material = [...materials]
        let price = [...pricePerUnit]
        result.data?.map((item, i) => {
          uom[i] = item.unitId
          material[i] = item.materialId
          price[i] = item.pricePerUnit
          return null
        })
        setSelectedUom(uom)
        setMaterials(material)
        setPricePerUnit(price)
      } else {
        setPricePerUnit([])
        setSelectedUom([])
        setMaterials([])
        setTableRows([{ value: null }])
      }
      setShowVendorMaterialsModal(true)
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }
  const getAllMaterials = async () => {
    try {
      await props.getAllMaterials()
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")

    }
  }

  const addRows = (e, value) => {
    let length = value ?? rowsToAdd
    const rows = [...tableRows];
    for (let i = 0; i < length; i++) {
      rows.push({ value: null })
    }
    setTableRows(rows)
    setRowsToAdd(0)
  }

  const handleUomSelect = (e, v, i) => {
    let uom = [...selectedUom]
    uom[i] = v
    setSelectedUom(uom)
  }
  const getSelectedUom = (i) => {
    const item = selectedUom[i]
    return item || {}
  }
  const getMaterial = (i) => {
    const item = materials[i]
    return item || []
  }
  const getPricePerUnit = (i) => {
    const item = pricePerUnit[i]
    return item || ""
  }

  const handleMaterial = (e, v, i) => {
    let material = [...materials]
    material[i] = v
    setMaterials(material)
  }

  const handlePrice = (e, i) => {
    let price = [...pricePerUnit]
    price[i] = e.target.value
    setPricePerUnit(price)
  }

  const submit = async () => {
    props.setLoading(true)
    try {
      const obj = materials.map((material, i) => {
        return {
          materialId: material,
          vendorId: selectedVendor,
          unitId: selectedUom[i],
          pricePerUnit: pricePerUnit[i]
        }
      })
      
      let res = await HttpService.mapMaterialsForVendor(selectedVendor, obj)
      
      props.setSnackInfo(res.data, "success")
      
      closeVendorMaterialMapModal()
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  const closeVendorMaterialMapModal = () => {
    setShowVendorMaterialsModal(false)
    setSelectedVendor("")
    setPricePerUnit([])
    setSelectedUom([])
    setMaterials([])
    setTableRows([{ value: null }])
  }

  const handlePanCard  = (e) =>{
    const {name,value} = e.target;
    let convert = value.toUpperCase()
    setVendorDetails({ ...vendorDetails, [e.target.name]: convert })
  }


  const handleFileInput = async (e) => {
    props.setLoading(true)
    const formData = new FormData()
    formData.append('file', e.target.files[0])
    formData.append('vendorName', vendorDetails.name)
    const result = await HttpService.uploadVendorDocs(formData)
  
    switch(e.target.id){
      case 'addAadhar':
        setVendorDetails({...vendorDetails, aadharCardLink: result.data})
        break;
      case 'updateAadhar':
        setVendorDetails({...vendorDetails, aadharCardLink: result.data})
        break;
      case 'addPan':
        setVendorDetails({...vendorDetails, panCardLink: result.data})
        break;
      case 'updatePan':
        setVendorDetails({...vendorDetails, panCardLink: result.data})
        break;
      case 'addBank':
        setVendorDetails({...vendorDetails, bankStatementLink: result.data})
        break;
      case 'updateBank':
        setVendorDetails({...vendorDetails, bankStatementLink: result.data})
        break;
      default:
        console.log("default")
    }
    props.setLoading(false)
  }

  const handleAddVendorButton = () => {
    setIsEdit(false)
    setVendorDetails({ _id: "",
      name: "",
      companyName: "",
      address: "",
      contactNumber: "",
      email: "",
      panNumber: "",
      aadharNumber:"",
      gstinNumber: "",
      website: "",
      alternateNumber: "",
      category: "",
      aadharCardLink: "",
      panCardLink: "",
      bankStatementLink: ""
    })
    setAccountDetails({
      accountHolderName: "",
      accountNumber: "",
      ifsc: "",
      bankName: "",
      bankBranch: ""
    })
    setAddVendorModal(true)

  }
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 10 }}>
        <TextField label="Search Vendors" value={searchField} style={{ marginBottom: 10 }} name="name" onChange={searchVendor} variant="outlined" />
        <Button variant="contained" color="primary" style={{maxHeight: '55px'}} onClick={() => handleAddVendorButton()}>
          Add Vendor
        </Button>
      </div>

      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Vendor Unique No</StyledTableCell>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Address</StyledTableCell>
              <StyledTableCell>Phone No</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>PAN Number</StyledTableCell>
              <StyledTableCell>PAN</StyledTableCell>
              <StyledTableCell>Aadhaar Number</StyledTableCell>
              <StyledTableCell>Aadhaar</StyledTableCell>
              <StyledTableCell>Bank Ac Number</StyledTableCell>
              <StyledTableCell>Bank</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {vendors?.map((data, i) => (
              <StyledTableRow key={i + 1}>
                <StyledTableCell>{data?.vendorUniqueNo}</StyledTableCell>
                <StyledTableCell>{data?.name}</StyledTableCell>
                <StyledTableCell>{data?.address}</StyledTableCell>
                <StyledTableCell>{data?.contactNumber}</StyledTableCell>
                <StyledTableCell>{data?.email}</StyledTableCell>
                <StyledTableCell>{data?.panNumber}</StyledTableCell>
                <StyledTableCell>{data?.panCardLink === "" ? 'NA' : <DownloadOutlined onClick={() => window.open(data.panCardLink)} style={{ color: 'blue' }} />}</StyledTableCell>
                <StyledTableCell>{data?.aadharNumber}</StyledTableCell>
                <StyledTableCell>{data?.aadharCardLink === "" ? 'NA' : <DownloadOutlined onClick={() => window.open(data.aadharCardLink)} style={{ color: 'blue' }} />}</StyledTableCell>
                <StyledTableCell>{data?.accountDetails?.accountNumber}</StyledTableCell>
                <StyledTableCell>{data?.bankStatementLink === "" ? 'NA' : <DownloadOutlined onClick={() => window.open(data.bankStatementLink)} style={{ color: 'blue' }} />}</StyledTableCell>
                <StyledTableCell>
                  <StyledTableRow>
                    <StyledTableCell>
                      <Tooltip title="Edit Mapping">
                        <BorderColorIcon onClick={() => openVendorMaterialsModal(data._id)} />
                      </Tooltip></StyledTableCell>
                    <StyledTableCell>
                      <Tooltip title="Edit Vendor">
                        <EditIcon onClick={() => getVendorById(data._id)} />
                      </Tooltip></StyledTableCell>
                  </StyledTableRow >
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={addVenorModal}
        TransitionComponent={Transition}
        keepMounted
        fullWidth={true}
        maxWidth={'sm'}
        onClose={() => setAddVendorModal(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
         <DialogTitle id="alert-dialog-slide-title">{isEdit ? "Update Vendor" : "Add Vendor"}</DialogTitle>
         <DialogContent>
          <DialogContentText id="alert-dialog-slide-description" style={{ display: "flex", flexDirection: "column" }}>
            <TextField label="Vendor Name" style={{ marginBottom: 10 }} name="name" value={name} onChange={(e) => setVendorDetails({ ...vendorDetails, [e.target.name]: e.target.value })} variant="outlined" />
            <TextField label="Company Name" style={{ marginBottom: 10 }} name="companyName" value={companyName} onChange={(e) => setVendorDetails({ ...vendorDetails, [e.target.name]: e.target.value })} variant="outlined" /> 
            <TextField label="Category" style={{ marginBottom: 10 }} name="category" value={category} onChange={(e) => setVendorDetails({ ...vendorDetails, [e.target.name]: e.target.value })} variant="outlined" />
            <TextField label="Vendor Address" multiline={true} rows={3} style={{ marginBottom: 10 }} name="address" value={address} onChange={(e) => setVendorDetails({ ...vendorDetails, [e.target.name]: e.target.value })} variant="outlined" />
            <TextField label="Contact Number" style={{ marginBottom: 10 }} name="contactNumber" value={contactNumber} onChange={(e) => setVendorDetails({ ...vendorDetails, [e.target.name]: e.target.value })} variant="outlined" />
            <TextField label="Alternate Contact Number" style={{ marginBottom: 10 }} name="alternateNumber" value={alternateNumber} onChange={(e) => setVendorDetails({ ...vendorDetails, [e.target.name]: e.target.value })} variant="outlined" />
            <TextField label="Email" style={{ marginBottom: 10 }} name="email" value={email} onChange={(e) => setVendorDetails({ ...vendorDetails, [e.target.name]: e.target.value })} variant="outlined" /> 
            <hr />
            <hr />
            <TextField label={<> Aadhar Number<sup style={{color:"red"}}>*</sup> </>}style={{ marginBottom: 10 }} name="aadharNumber"value={aadharNumber}onChange={(e) =>setVendorDetails({...vendorDetails,[e.target.name]: e.target.value})}variant="outlined"/>
            {isEdit ? [<h5 style={{color: 'blue'}}>Update Aadhaar Card</h5>,<input accept="*" className={classes.input} id="updateAadhar" onChange={(e) => handleFileInput(e)} type="file"/>]:[<h5 style={{color: 'blue'}}>Add Aadhaar Card<sup style={{color:"red"}}>*</sup></h5>,<input accept="*" className={classes.input} id="addAadhar" onChange={(e) => handleFileInput(e)} type="file"/>]}
            <hr />
            <hr />

            <div style={{ color: "black", fontWeight: "bold" }}> Vendor A/C Details</div>
            <TextField label="Account Holder Name" style={{ marginBottom: 10 }} name="accountHolderName" value={accountHolderName} onChange={(e) => setAccountDetails({ ...accountDetails, [e.target.name]: e.target.value })} variant="outlined" />
            <TextField label="Account Number " style={{ marginBottom: 10 }} name="accountNumber" value={accountNumber} onChange={(e) => setAccountDetails({ ...accountDetails, [e.target.name]: e.target.value })} variant="outlined" />
            <TextField label="IFSC CODE" style={{ marginBottom: 10 }} name="ifsc" value={ifsc} onChange={(e) => setAccountDetails({ ...accountDetails, [e.target.name]: e.target.value.toUpperCase() })} variant="outlined" />
            <TextField label="Bank Name" style={{ marginBottom: 10 }} name="bankName" value={bankName} onChange={(e) => setAccountDetails({ ...accountDetails, [e.target.name]: e.target.value })} variant="outlined" />

            <TextField label="Bank Branch" style={{ marginBottom: 10 }} name="bankBranch" value={bankBranch} onChange={(e) => setAccountDetails({ ...accountDetails, [e.target.name]: e.target.value })} variant="outlined" />
            {isEdit ? [<h5 style={{color: 'blue'}}>Update Bank Statement</h5>,<input type="file" id='updateBank' onChange={e => handleFileInput(e)} />] : [<h5 style={{color: 'blue'}}>Add Bank Statement</h5>,<input type="file" id='addBank' onChange={e => handleFileInput(e)} />]}
            <hr />
            <hr />
 
            <TextField label={<> PAN Number<sup style={{color:"red"}}>*</sup> </>}style={{ marginBottom: 10 }} name="panNumber" value={panNumber} onChange={(e) => handlePanCard(e)} variant="outlined" />
            
            {isEdit ? [<h5 style={{color: 'blue'}}>Update PAN Card</h5>,<input type="file" id='updatePan' onChange={e => handleFileInput(e)} />] : [<h5 style={{color: 'blue'}}>Add PAN Card <sup style={{color:"red"}}>*</sup></h5>,<input type="file" id='addPan' onChange={e => handleFileInput(e)} />]}
            <hr />
            <hr /> 

            <TextField label="GSTIN Number" style={{ marginBottom: 10 }} name="gstinNumber" value={gstinNumber} onChange={(e) => setVendorDetails({ ...vendorDetails, [e.target.name]: e.target.value })} variant="outlined" />

            <TextField label="Website" style={{ marginBottom: 10 }} name="website" value={website} onChange={(e) => setVendorDetails({ ...vendorDetails, [e.target.name]: e.target.value })} variant="outlined" /> 
          </DialogContentText>
          <DialogActions>
            { 
            isEdit ? <Button onClick={editVendor} variant ="contained" color = "primary"> UPDATE </Button>:<Button onClick={addVendor} variant ="contained" color = "primary" disabled = {vendorDetails.aadharNumber ==="" || vendorDetails.panNumber ==="" ||vendorDetails.aadharCardLink === "" || vendorDetails.panCardLink === ""}> Add</Button>
            }
          </DialogActions>
        </DialogContent>  
        
      </Dialog>

      <Modal
        title="Vendor Mapped Materials"
        centered
        visible={showVendorMaterialsModal}
        onCancel={closeVendorMaterialMapModal}
        width={1000}
        maskClosable={false}
        footer={null}
      >
        <TableContainer component={Paper}>
          <Table className={classes.table} size="small" aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Sl No</StyledTableCell>
                <StyledTableCell>Material Name</StyledTableCell>
                <StyledTableCell>Material Code</StyledTableCell>
                <StyledTableCell>Price</StyledTableCell>
                <StyledTableCell>Unit</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableRows?.map((data, i) => (
                <StyledTableRow key={i + 1}>
                  <StyledTableCell>{i + 1}</StyledTableCell>
                  <StyledTableCell><Autocomplete
                    value={getMaterial(i)}
                    onChange={(e, v) => handleMaterial(e, v, i)}
                    options={allMaterials}
                    getOptionLabel={(option) => option.name}
                    style={{ width: 250 }}
                    renderInput={(params) => <TextField {...params} label="Materials" fullWidth variant="outlined" />}
                  /></StyledTableCell>
                  <StyledTableCell><TextField disabled={true} className={classes.textField} value={materials[i]?.name} variant="outlined" /></StyledTableCell>
                  <StyledTableCell><TextField className={classes.textField} onChange={(e) => handlePrice(e, i)} type="number" value={getPricePerUnit(i)} variant="outlined" /></StyledTableCell>
                  <StyledTableCell><Autocomplete
                    value={getSelectedUom(i)}
                    onChange={(e, v) => handleUomSelect(e, v, i)}
                    options={units}
                    getOptionLabel={(option) => option.name}
                    style={{ width: 150 }}
                    renderInput={(params) => <TextField {...params} label="Select UOM" fullWidth variant="outlined" />}
                  /></StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div style={{ display: "flex", flexDirection: "row-reverse", marginTop: 15 }} >
          <Button variant="contained" color="primary" style={{ background: "#716ACA", margin: 5 }} onClick={addRows}>
            Add Row
          </Button>
          <TextField label="Rows to add" value={rowsToAdd} type="number" onChange={(e) => setRowsToAdd(e.target.value)} variant="outlined" />
        </div>

        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 15 }} onClick={submit} >
          <Button variant="contained" color="primary" style={{ background: "#716ACA", margin: 5 }} >
            Save
          </Button>
        </div>
      </Modal>
    </div>
  )
}

export default connect(null, { setLoading, setSnackInfo, getAllUnits, getAllMaterials })(Vendors)
