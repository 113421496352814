import axios from 'axios'

export const logout = () => {
    // localStorage.removeItem('TOKEN_KEY');
    localStorage.clear()
}

export const isLogin = () => {
    if (localStorage.getItem('TOKEN_KEY')) {
        return true;
    }
    return false;
}

export const isCustomerLoggedIn = () => {
    if (localStorage.getItem('token')) {
        return true;
    }
    return false;
}
export const setAuthTokenForCustomer = (token) => {
    if (token) {
        // console.log(token,"this is token")
        axios.defaults.headers.common["Authorization"] = token;
        axios.defaults.headers.common["x-auth"] = token;
    } else {
        delete axios.defaults.headers.common["Authorization"];
        delete axios.defaults.headers.common["x-auth"];
    }
};

export const setAuthToken = (token) => {
    if (token) {
        axios.defaults.headers.common["Authorization"] = token;
        axios.defaults.headers.common["x-auth"] = token;
    } else {
        delete axios.defaults.headers.common["Authorization"];
        delete axios.defaults.headers.common["x-auth"];
    }
}

export const PAYMENT_APPROVER = "kanishka@decorpot.com"

export const ADMINHEADER = ["Sl No", "Scope", "Unit", "Qty/Area", "Total Price", "PO/MTR Requested", "SO Requested", "Overall Cost", "Margin %"]

export const ADMINHEADERMULTSELECTNEW = ["Sl No", "Scope", "Area(mm)", "Area(SqFt)", "Qty", "Total Price", "PO/MTR Requested", "SO Requested", "Overall Cost", "Margin %"]

export const NONADMINHEADERMULTSELECTNEW = ["Sl No", "Scope", "Area(mm)", "Area(SqFt)", "Qty", "PO/MTR Requested", "SO Requested"]

export const TABLEHEADER = ["Sl No", "Scope", "Unit", "Qty/Area", "PO/MTR Requested", "SO Requested"]

export const EM_TABLEHEADER = ["Sl No", "Scope","PO/MTR Requested", "SO Requested"]

export const SCOPEDPRODUCTSHEADER = ['Sl No', 'Materila Name', 'Qty/Area', 'Price Per Unit', "Total"]

export const INWARDTABLEHEADER = ['Sl No', 'Order Id', 'Status', 'Vendor', 'Value', 'Created At', 'Action']

export const POSORAISEDHEADER = ['Sl No', 'Order Id', 'Status', 'Vendor', 'Value', 'Created At', 'Action']

export const ROLENAME = {
    Admin: 'Admin',
    MarketingManager: 'Marketing Manager',
    MarketingUser: 'Marketing User',
    DGM_Marketing: 'DGM Marketing',
    SalesManager: 'Sales Manager',
    SalesUser: 'Sales User',
    SalesHead: 'Sales Head',
    DGM_Sales: 'DGM Sales',
    OperationDesignManager: 'Operation Design Manager',
    DesignManager: 'Design Manager',
    AssistantDesignManager: 'Assistant Design Manager',
    DesignUser: 'Design User',
    DesignHead: 'Design Head',
    DGM_Design: 'DGM Design',
    FinanceManager: 'Finance Manager',
    FinanceExecutive:'Finance Executive',
    BOMManager: 'BOM Manager',
    CenterHead: 'Center Head',
    BusinessHead: 'Business Head',
    DocumentUploader: 'Document Uploader',
    FinalMarking: 'Final Marking',
    QualityControl: 'Quality Control',
    QCManager: 'QC Manager',
    SeniorOperationManager: 'Senior Operation Manager',
    ScopeAdmin: 'Scope Admin',
    SiteQC: 'Site QC',
    IMOSManager: 'IMOS Manager',
    BOMExecutive: 'BOM Executive',
    ProcurementExecutive: 'Procurement Executive',
    OperationHead: 'Operation Head',
    IMOSUser: 'IMOS User',
    FactoryManager: 'Factory Manager',
    AssistantSalesManager:'Assistant Sales Manager',
    LnDManager: 'LnD_Manager',
    LnDUser: 'LnD_User',
    AssistanceDesignManager: 'Assistance Design Manager',
    MaterialManager: 'Material Manager',
    FactoryBom:"Factory Bom",
    SiteBom:"Site Bom",
    CHM_Manager: 'CHM_Manager',
    CHM_User :'CHM_User',
    Execution_Manager:'Execution Manager',
    ExecutionUser:'Execution User',
    Store_Manager:'Store Manager',
    FinanceUser:'Finance User',
    VendorSOManager: "Vendor SO Manager",
    VendorSOExecutive: "Vendor SO Executive",
    OperationManager:"Operation Manager",
    DGM_Ops:"DGM Ops",
    FactoryCrmUser: "Factory CRM",
    Customer: 'Customer',
    ProductionManager: 'Production Manager',
    DataAnalyst : 'Data Analyst',
    TeleCallerUser : 'TeleCaller',
    TeleCallerManager : 'TeleCallerManager'
}

export const CURRENT_STAGE = ['Lead Received', 'Won', 'Lost', 'Assign to Designer', 'Designer Assigned', "Design Sign-off", 'Site QC', ]

export const LEADSTATUS = {
    RealLead: 'Real',
    JunkLead: 'Junk',
    HoldLead: 'Hold',
    TBCLead: 'To Be Called',
    LeadReceived: 'Lead Received',
    TotalLeadsGenerated: 'Total Leads Generated',
    LeadReturnedBySales: 'Lead Returned By Sales'
}

export const EXPCENTERID = {
    whiltefield: '61ea728cb2e70b55ec16a88c',
    hsr: '61e7fcd79905175441887eb9',
    Gachibowli: '61f22f7bb2e70b55eca2c6b7'
}

export const designDepartment = '5cb70b89ffa4965f53aa22d8';
export const salesDepartment = '5cb5b38bcf5531e174cb23e0';
export const opsTeam = '62beef6ad75fa548e7a03d30';

export const LOCATIONID = {
    bangalore: '61e7faac331230532d894dbf',
    hyderabad: '61f22f30b2e70b55eca2c127'
}

export const rolesId = {
    designHead: '6253d10559e133b2384c5d97',
    designUser: '61c030feb011183618a10051',
    designManager: '61515c24b2880930387b804d',
    docsUploader: '629ee2e321eccd6f346be0a4'
}

export const constantDepartmentId = {
    marketing: '62833e4c6999f0dd8be397a0',
    design: '5cb70b89ffa4965f53aa22d8',
    sales: '5cb5b38bcf5531e174cb23e0',
    factory: '5cb5b3f2cf5531e174cb2457',
    imos: '5cb5b412cf5531e174cb2480',
    operation: '62beef42d75fa548e7a03cff',
    chm: '637b3a50a3d82d3d63bfc6cf',
    TeleCallerUser : '66445875fa9dfc06260ddbd9'
}

//for software download
export const softwate = {
    naraciWindows :"https://naraci-production.s3.ap-south-1.amazonaws.com/naraci-installer/windows/NARACI_7.8.0.exe",
    naraciMac :"https://naraci-production.s3.ap-south-1.amazonaws.com/naraci-installer/mac/NARACI-7.8.0.dmg",
    sketchUp :"https://dp-test-staging.s3.ap-south-1.amazonaws.com/ATTACHMENT/1747461.1/1712214096854_sketchup_0404202407013684.rar",
    autoCad :"https://dp-test-staging.s3.ap-south-1.amazonaws.com/ATTACHMENT/1747461.1/1712216493862_autocad_0404202407413385.rar",
    autoCadVideo : "https://dp-test-staging.s3.ap-south-1.amazonaws.com/ATTACHMENT/1747336.1/1715237736431_Installation_guide_Autodesk_AutoCAD_0905202406553642.mp4",
    naraciMacVideo : "https://dp-test-staging.s3.ap-south-1.amazonaws.com/ATTACHMENT/1747336.1/1715237763619_Installation_guide_Naraci_mac_0905202406560361.mp4",
    naraciWindowsVideo : "https://dp-test-staging.s3.ap-south-1.amazonaws.com/ATTACHMENT/1747336.1/1715237774399_Installation_guide_Naraci_windows_0905202406561439.mp4",
    sketchUpVideo : "https://dp-test-staging.s3.ap-south-1.amazonaws.com/ATTACHMENT/1747336.1/1715237783742_Installation_guide_SketchUp_0905202406562374.mp4"
}


export const constantUnitTypeMaster = {
    Sqft: '5ca989cc4585958841722acd',
    mm: '5ca98a104585958841722b26'
}

export const rftUnitTypeId = '61497a00631f527959443fd5';
export const virtualPaymentId = '64f6e042afc49f16a60f9ff2';

export const constantPlyTypeMaster = {
    plywood: '62833b70a4170cdc752d86a7'
}

export const CONST_USER_ID = {
	Kanishka: "61543da213b518292f34a854",
	Swapna: "61262a8b56f00a0a9245a707",
    Deepak: "61ceedb0451c99174f712634",
    Shomil: "6126288a56f00a0a9245a6e5",
};
export const SriKrishnaId = "649abe224cff80287aa160c1";
export const PalisettiTejaId = "62cd13a726c3cf4d62910416";
export const ThirumaleshId = "6463810d91e79e0d4157af7d";
export const PalaPrasanthKumarId = "656dbe6b68c2676c6b0cb4ce";

export const DONT_SHOW_REMAP_TABLE = ['brijeshkumar@decorpot.com', 'muralikrishnam@decorpot.com']

export const factoryCRMstagesArr = [
    "Assign To IMOS User",
    "100% Material Received & Site BOM In-progress",
    "Assign To IMOS User & Site BOM Completed",
    "Assign To IMOS User & Site BOM In-progress",
    "Assign To IMOS User null",
    "Completed",
    "Completed-MWP",
    "Site QC",
    "IMOS Completed & Site BOM Completed",
    "IMOS Completed & Site BOM In-progress",
    "IMOS Drawing In-Progress & Site BOM Completed",
    "Ready for dispatch",
    "Under Execution",
    "Under Procurement",
    "Under Procurement & Site BOM In-progress",
    "Under Production - Fixing",
    "Under Production - Fixing & Site BOM In-progress",
    "Under Production - Machine & Site BOM In-progress",
    "100% Material Received & Site BOM In-progress",
    "Under Production - Machine"
]
export const paymentMode = {
    upi: "UPI",
    cashFree: "Cash Free - ERP",
    bankTransferVirtualAccount: "Bank Transfers - Virtual Account",
    cardPineLabsErp: "Card - Pine Labs (via ERP)",
    cheque: "Cheque",
    cardPineLabsNotErp: "Card - Pine Labs (not via ERP)",
    bankTransferNonVirtualAccount: "Bank Transfers - Non Virtual Account",
    cash: "Cash",
    others: "Others",
};
export const paymentModeType = {
    automatic: "Automatic", 
    manual: "Manual",
};

export const saleLostApprovalHeader = 'Sales Lost Leads Approval Requests'
export const designLostApprovalHeader = 'Design Lost Leads Approval Requests'

export const chmTeamTaggingArr = ["Refund / Referral / Balance Sheet / Invoice", "Other-Finance", "Procurement / Vendor / Carpenter Issue", "Marketing", "Service / Others", "Operation / Factory / Material Delivery", "Site Related", "Career / HR / Job Related"]
export const chmCentreWiseTaggingArr = ["Design", "Sales"]