import React, { useState, useEffect, useRef } from 'react'
import { setLoading, setSnackInfo } from '../../redux/actions/appAction'
import { setVendorName } from '../../redux/actions/vendorAction'
import HttpService from '../../services/httpService'
import { connect } from 'react-redux'
import { useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import TableHead from '@material-ui/core/TableHead';
import { DownloadOutlined, FileFilled, RobotFilled, RollbackOutlined } from '@ant-design/icons';
import { Button, Input, Menu} from '@material-ui/core';
import moment from 'moment'
import { Link } from 'react-router-dom'
import { ROLENAME, designDepartment, constantDepartmentId, CURRENT_STAGE } from '../../utils/index';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Accordion, AccordionSummary, AccordionDetails, AccordionActions, Typography, Grid, Divider, DialogContentText, Box, OutlinedInput } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select/Select';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DeleteIcon from '@material-ui/icons/Delete'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Card, CardContent } from '@material-ui/core';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import { Http, TabletAndroidOutlined, ThumbUpOutlined } from '@material-ui/icons';
import { CSVLink } from "react-csv";


const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;
  // const { vendorDetail } = useSelector(state => state.vendor)

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };
  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  table: {
    minWidth: 500,
  },
  textField: {
    width: "150px"
  },
  paperClass: {
    height: '100%'
  },
  returnButton: { backgroundColor: 'white', color: '#9c261e', padding: '1', marginBottom: '1px', borderRadius: '20px', "&:hover": { backgroundColor: "#9c261e", color: 'white' } },
  approveButton: { backgroundColor: 'white', color: '#157525', padding: '1', marginBottom: '1px', borderRadius: '20px', "&:hover": { backgroundColor: "#157525", color: 'white' } },
  UploadDocsbuttons: { borderRadius: 50, backgroundColor: 'white', color: '#2c2e3e', padding: '1', marginBottom: '1px', borderRadius: '20px', "&:hover": { backgroundColor: "#2c2e3e", color: 'white' } },
  deleteIcon: { cursor: "pointer", verticalAlign: 'middle', marginLeft: 10, color: "red", fontSize: 20 },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#2c2e3e",
    color: '#fff',
  },
  body: {
    fontSize: 14,
    backgroundColor: "#fff",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const DesignKickOff = (props) => {
  let j = 1;
  const classes = useStyles2();
  useEffect(() => {
    getAllDesignKickOffLeads()
    getDepartments();
    getStages();
    // eslint-disable-next-line
  }, [])

  const fileInputRef1 = useRef(null)
  const fileInputRef2 = useRef(null)
  const fileInputRef3 = useRef(null)
  
  const { userDetails } = useSelector(state => state.user)
  const [designKickOffLeads, setDesignKickOffLeads] = useState([])
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, designKickOffLeads.length - page * rowsPerPage)
  const [leadDetails, setLeadDetails] = useState({})
  const [openDesignKickOffCustomerMom, setOpenDesignKickOffCustomerMom] = useState(false)
  const [openDesignKickOffCustomerDocsUploadDialog, setOpenDesignKickOffCustomerDocsUploadDialog] = useState(false)
  const [openDesignKickOffCustomerApprovalDialogForChm, setOpenDesignKickOffCustomerApprovalDialogForChm] = useState(false)
  const [openDesignKickOffCustomerRejectionDialogForChm, setOpenDesignKickOffCustomerRejectionDialogForChm] = useState(false)

  const [previousUsers, setPreviousUsers] = useState([])
  const [selectedPreviousUserData, setSelectedPreviousUserData] = useState({})
  const [chmRemarksForRejection, setChmRemarksForRejection] = useState("")

  const getAllDesignKickOffLeads = async () =>
  {
    props.setLoading(true)
    try {
      const response = await HttpService.getAllDesignKickOffLeads(userDetails.selectedExpCenter)
      setDesignKickOffLeads(response.data)
    }
    catch(error){
      props.setSnackInfo(error)
    }
    props.setLoading(false)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDesignKickOffCustomerRejectClick = (event, data) =>
  {
    try
    {
      setLeadDetails({...data})

      let arr = []

      if(data.salesWonUser)
      {
        arr.push(data.salesWonUser)
      }

      if(data.designUser)
      {
        arr.push(data.designUser)
      }
      
      setPreviousUsers(arr)

      setOpenDesignKickOffCustomerRejectionDialogForChm(true)
    }
    catch(error)
    {
      console.log('error', error.message)
    }
  }
  const handleDesignKickOffCustomerApproveClick = (event, data) =>
  {
    setLeadDetails(data)

    let role = userDetails.roles[0].name
    if(role == 'Design User' || role == 'Assistant Design Manager')
    {
        let obj =
        {
            leadId: data._id,
            customerId: data.customerId ? data.customerId._id : 'NA',
            designerUserName: data.designUser ? data.designUser.name : 'NA',
            designerManagerName: data.designManager ? data.designManager.name : 'NA',
            clientName: data.customerId ? data.customerId.name : 'NA',
            ApartmentName: data.customerId ? data.customerId.address : 'NA',
            chmName: data.chmUser ? data.chmUser.name : 'NA',
            salesOwnerName: data.salesWonUser ? data.salesWonUser.name : 'NA',
            designerAssignmentDate: data.designerAssignedDate ? moment(data.designerAssignedDate).format('YYYY-MM-DD') : 'NA',
            dateOfKickOffMeeting: null,
            possesionDate: data.possessionDate ? moment(data.possessionDate).format('YYYY-MM-DD') : 'NA',
            tentativeDesignSignOffDate: data.customerDesignSignOffDate ? moment(data.customerDesignSignOffDate).format('YYYY-MM-DD') : 'NA',
            tentativeMoveInDate: data.clientMoveinDate ? moment(data.clientMoveinDate).format('YYYY-MM-DD') : 'NA'
        }
        setDesignerMomChecklistData({...designerMomChecklistData, ...obj})
        setOpenDesignKickOffCustomerDocsUploadDialog(true)
    }
    // else if(role == 'Design Manager'|| role == 'Operation Design Manager'|| role == 'Design Head'|| role == 'Assistant Design Manager' )
    // {
    //   setOpenDesignKickOffCustomerApprovalDialogForChm(true)
    // }
  }

  const handleDesignKickOffCustomerApproveClickApproval = (event, data) =>
  {
    setLeadDetails(data)
    let role = userDetails.roles[0].name
    {
      setOpenDesignKickOffCustomerApprovalDialogForChm(true)
      }
    }


  const handleDesignKickOffCustomerRejectSubmitFromChm = async () =>
  {

    let obj =
      {
        leadDetails : leadDetails,
        status : 'Rejected',
        selectedUserData: {...selectedPreviousUserData},
        chmReasonForDesignKickOffRejection: chmRemarksForRejection
      }
    await HttpService.updateDesignKickOffCustomerFromChm(obj)
    setOpenDesignKickOffCustomerRejectionDialogForChm(false)
    setChmRemarksForRejection('')
    setSelectedPreviousUserData({})
    setLeadDetails({})
    getAllDesignKickOffLeads()
  }

  const handleDesignKickOffCustomerApprovalFromChm = async () =>
  {
    props.setLoading(true)
    try
    {
      let obj =
      {
        leadDetails : leadDetails,
        status : 'Approved'
      }
      await HttpService.updateDesignKickOffCustomerFromChm(obj)
      setOpenDesignKickOffCustomerApprovalDialogForChm(false)
      setLeadDetails({})
      getAllDesignKickOffLeads()
    }
    catch(error)
    {
      console.log('error', error.message)
    }
    props.setLoading(false)
  }

  const handleDesignerMomClick = () =>
  {
    
    setOpenDesignKickOffCustomerMom(true)
  }

  const handleDesignerMomChanges = e =>
  {
    setDesignerMomChecklistData({...designerMomChecklistData, [e.target.name]: e.target.value})
  }

  const handleDesignKickOffApprovalDocsFromDesign = async (e) =>
  {
    props.setLoading(true)
    try
    {
      let obj = 
      {
        leadDetails : leadDetails,
        designKickOffCustomerFileLinks: designKickOffCustomerFileLinks
      }
      await HttpService.updateDesignKickOffCustomerFromDesign(obj)
        
      setOpenDesignKickOffCustomerDocsUploadDialog(false)
      
      setLeadDetails({})
      
      setDesignKickOffCustomerFileLinks({
        skpFileLink: '',
        quotationFileLink: '',
        momFileLink: '',
        screenshShotsLinks: []
      })
      setDesignKickOffCustomerFileNames({
        skpFileName: [],
        quotationFileName: [],
        screenshotsNames: []
  
      })
      getAllDesignKickOffLeads()
    }
    catch(error)
    {
      console.log('error', error.message)

    }
    props.setLoading(false)
  }

  const [designKickOffCustomerFileNames, setDesignKickOffCustomerFileNames] = useState(
    {
      skpFileName: [],
      quotationFileName: [],
      screenshotsNames: []

    });

  const [designKickOffCustomerFileLinks, setDesignKickOffCustomerFileLinks] = useState(
    {
      skpFileLink: '',
      quotationFileLink: '',
      momFileLink: '',
      screenshShotsLinks: []
    })

  const handleDesignKickOffCancelFromDesign = () =>
  {
    setOpenDesignKickOffCustomerDocsUploadDialog(false)

    setDesignKickOffCustomerFileLinks({
      skpFileLink: '',
      quotationFileLink: '',
      momFileLink: '',
      screenshShotsLinks: []
    })
    
    setDesignKickOffCustomerFileNames({
      skpFileName: [],
      quotationFileName: [],
      screenshotsNames: []

    })
  }

  const handleDeleteFile = (index, type) =>
  {

    if(type == 'quotationFileName')
    {
      setDesignKickOffCustomerFileNames({...designKickOffCustomerFileNames, quotationFileName : []})
      setDesignKickOffCustomerFileLinks({...designKickOffCustomerFileLinks, quotationFileLink : ''})
    }
    else if(type == 'skpFileName')
    {
      setDesignKickOffCustomerFileNames({...designKickOffCustomerFileNames, skpFileName : []})
      setDesignKickOffCustomerFileLinks({...designKickOffCustomerFileLinks, skpFileLink : ''})
    }
    else if(type == 'screenshotsNames')
    {
      let currentScreenshotsNames = [...designKickOffCustomerFileNames.screenshotsNames]
      currentScreenshotsNames = currentScreenshotsNames.filter((el,i)=>i != index)
      setDesignKickOffCustomerFileNames({...designKickOffCustomerFileNames, screenshotsNames : currentScreenshotsNames})

      let currentScreenshotsLinks = [...designKickOffCustomerFileLinks.screenshShotsLinks]
      currentScreenshotsLinks = currentScreenshotsLinks.filter((el,i)=>i != index)
      setDesignKickOffCustomerFileLinks({...designKickOffCustomerFileLinks, screenshShotsLinks : currentScreenshotsLinks})
    }
  }
  const [departmentsList, setDepartmentsList] = useState([]);
  const [departmentStage, setDepartmentsStage] = useState([]);
  const [chmList, setChmList] = useState([]);
  const [stagesList, setStagesList] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [selectedStage, setSelectedStage] = useState([]);
  const [filteredLeads, setFilteredLeads] = useState([]);
  const [searchbtn, setSearchBtn] = useState(false);
  const [analytical, setAnalytical] = useState(false);
  const [chmUsers, setChmUsers] = useState([]);
  const [chmUserCounts, setChmUserCounts] = useState([]);
  const [csvData, setCsvData] = useState([]);
	const csvButtonRef = useRef(null);
  const [headers, setHeaders] = useState([]);

  const handleSearchUser = (event, newValue) => {
      setSelectedUser(newValue);
  }
  const handleSearchStage = (event, newValue) => {
    setSelectedStage(newValue);
  }
  const clearFilters = () => {
    setSelectedUser([]);
    setSelectedStage([]);
    setSearchBtn(false);
    getAllDesignKickOffLeads()
  };
  const getStages = async() => {
    try{
      const departmentss = await HttpService.getDepartments();
      const salesStage = departmentss.data.find(item=> item.name === "Design")
      if (salesStage){
        const suers = ["Won Deals Pending Designer Assignment", "Assign to Designer","Design Kick-Off : Internal","Design Kick-Off : Customer"]
        setStagesList(suers);
      }
    }catch(error){
      console.log(error,"get Stages");
    }
  }
  const getDepartments = async () => {
    try {
        const department = await HttpService.getDepartments();
        const chmStage = department.data.find(item=> item.name === "CHM")
        const designStage = department.data.find(item => item.name === "Design")
        const salesStage = department.data.find(item=> item.name === "Sales")
        if (chmStage || designStage || salesStage){
            const chm_users = chmStage.users
            const designUser = designStage.users
            const salesUser = salesStage.users
            const allUsers = chm_users.concat(designUser).concat(salesUser)
            setChmList(allUsers);
            setChmUsers(chm_users);
        }
    } catch (error) {
        console.log(error, "get department error")
    }
  }
  const handleDesignKickOffDocsUpload = async (e) =>
  {
    props.setLoading(true)
    try
    {
      
      let files = e.target.files

      if(files.length < 1)
      {
        return null
      }
      
      let names = Array.from(files).map(file=>file.name)

      if(e.target.id == 'upload_screenshots')
      {
        let links = []

        for(let index = 0; index < files.length; index++)
        {
          
          let newFormData = new FormData()
          
          newFormData.append('file', files[index])
          newFormData.append("customerName",leadDetails.lead_no)

          setDocsUploading(true)

          const result = await HttpService.uploadDesignKickOffDocs(newFormData)

          links.push(result.data)
         
        }

        setDesignKickOffCustomerFileLinks({...designKickOffCustomerFileLinks, screenshShotsLinks: [...designKickOffCustomerFileLinks.screenshShotsLinks, ...links]})
        
        setDesignKickOffCustomerFileNames({...designKickOffCustomerFileNames, screenshotsNames: [...designKickOffCustomerFileNames.screenshotsNames,...names]})
      }
      else
      {
        const formData = new FormData()

        formData.append('file', files[0])

        setDocsUploading(true)
        
        const result = await HttpService.uploadDesignKickOffDocs(formData)

        switch(e.target.id)
        {
          case 'upload_quotation_pdf':
            setDesignKickOffCustomerFileLinks({...designKickOffCustomerFileLinks, quotationFileLink: result.data})
            setDesignKickOffCustomerFileNames({...designKickOffCustomerFileNames, quotationFileName: names})
            break;

          case 'upload_skp_file':
            setDesignKickOffCustomerFileLinks({...designKickOffCustomerFileLinks, skpFileLink: result.data})
            setDesignKickOffCustomerFileNames({...designKickOffCustomerFileNames, skpFileName: names})
            break;

          default:
            console.log('default case')

        }
      }

      if (fileInputRef1.current) {
        fileInputRef1.current.value = '';
      }

      if (fileInputRef2.current) {
        fileInputRef2.current.value = '';
      }

      if (fileInputRef3.current) {
        fileInputRef3.current.value = '';
      }

      setDocsUploading(false)

    }
    catch(error)
    {
      console.log('error', error.message)
    }
    props.setLoading(false)
  }

  const handleDesignerMomChecklistActions = (category, type, index, e) =>
  {

    let tempDesignerMomChecklistData = {...designerMomChecklistData}
    
    if
    (
      category == 'services' ||
      category == 'standards' ||
      category == 'falseCeiling' ||
      category == 'electricals' ||
      category == 'electricals' ||
      category == 'miscellaneous'
    )
    {
        tempDesignerMomChecklistData[category][index]['value'] = e.target.value
        setDesignerMomChecklistData({...tempDesignerMomChecklistData})
    }
    else if(category == 'additionalComments')
    {
      tempDesignerMomChecklistData[category]['value'] = e.target.value
      setDesignerMomChecklistData({...tempDesignerMomChecklistData})
    }
  }
  const [docsUploading, setDocsUploading] = useState(false)
  const [designerMomChecklistData, setDesignerMomChecklistData] = useState(
    {
      
    //   designerUserName: leadDetails.designUser ? leadDetails.designUser.name : 'NA',
    //   designerManagerName: leadDetails.designManager ? leadDetails.designManager.name : 'NA',
    //   clientName: leadDetails.customerId ? leadDetails.customerId.name : 'NA',
    //   ApartmentName: leadDetails.address ? leadDetails.customerId.address : 'NA',
    //   chmName: leadDetails.chmUser ? leadDetails.chmUser.name : 'NA',
    //   salesOwnerName: leadDetails.salesWonUser ? leadDetails.salesWonUser.name : 'NA',
    //   designerAssignmentDate: leadDetails.designerAssignedDate ? moment(leadDetails.designerAssignedDate).format('DD-MM-YYYY') : 'NA',
    //   dateOfKickOffMeeting: null,
    //   possesionDate: leadDetails.possessionDate ? moment(leadDetails.possessionDate).format('DD-MM-YYYY') : 'NA',
    //   tentativeDesignSignOffDate: leadDetails.customerDesignSignOffDate ? moment(leadDetails.customerDesignSignOffDate).format('DD-MM-YYYY') : 'NA',
    //   tentativeMoveInDate: leadDetails.clientMoveinDate ? moment(leadDetails.clientMoveinDate).format('DD-MM-YYYY') : 'NA',
      
      services:
        [
          {
            type: 'boolean',
            description: 'Sketch up model design discussion is completed as per the initial sales quote',
            value: ''
          },
          {
            type: 'boolean',
            description: 'Sales quotation Walk through is done',
            value: ''
          },
          {
            type: 'boolean',
            description:'Any additions apart from the initial quote',
            value: ''
          },
          
          {
            type: 'comments',
            description:'Additions pls brief here (mandatory)*',
            value: ''
          },
          
  
        ],
      standards:
        [
          {
            type: 'boolean',
            description: 'Decorpot standard finishes, colors and handles has been discussed with the client',
            value: ''
          },
          {
            type: 'boolean',
            description: 'If any G- profile or Gola profile handles specify to confirm with client',
            value: ''
          },
          {
            type: 'comments',
            description:'Specifications pls mention - Areas and type of handles (mandatory)*',
            value: ''
          },
          {
            type: 'boolean',
            description: 'Re confirmed on the Soft close hinges and channels and area covered as per the quote',
            value: ''
          },
          {
            type: 'comments',
            description: 'Addition in soft close pls mention here and estimate also (mandatory)*',
            value: ''
          }
        ],
      falseCeiling:
        [
          {
            type: 'boolean',
            description: 'False ceiling area specific quote ( As per the final design quote might vary)',
            value: ''
          },
          {
            type: 'boolean',
            description: 'False ceiling with paint or without paint quote will vary (cross check what is added in the initial quote and to be discussed with the client on the same)',
            value: ''
          },
          {
            type: 'boolean',
            description:'False celing quote does not include any electricals',
            value: ''
          },
        ],
      electricals:
        [
          {
            type: 'boolean',
            description: 'Electricals scope under Decorpot',
            value: ''
          },
          {
            type: 'boolean',
            description: 'Electricals points mentioned in the quote is only for provision. Does not include fixtures',
            value: ''
          },
        ],
      miscellaneous:
        [
          {
            type: 'boolean',
            description: 'Granite / Quartz removal is a must for fixing cabinates in case if its fixed at site',
            value: ''
          },
          {
            type: 'boolean',
            description: 'Granite / Quartz removal & refixing are chargeable as per the area',
            value: ''
          },
        ],
      additionalComments:
      {
        type: 'comments',
        description: 'Additional Comments (mandatory)*',
        value: ''
      },

    })

    const searchFilter = () => {
      setSearchBtn(true);
    
      if (selectedUser.length > 0 || selectedStage.length > 0) {
        // Filter leads based on selected CHM users or designers or sales users
        const filteredData = designKickOffLeads.filter((data) => {
          const usersToCheck = [
            data.chmUser,
            data.designUser,
            data.salesWonUser,
            data.customerId,
          ];
    
          const userMatch = usersToCheck.some((user) => {
            return user && selectedUser.some((selected) => selected.name === user.name);
          });
    
          const stageMatch = selectedStage.some((stage) => stage === data.currentStage);
          if(selectedUser.length > 0 && selectedStage.length > 0){
            return userMatch && stageMatch
          }
          else {
            return  userMatch || stageMatch
          }
        });
    
        setFilteredLeads(filteredData);
        setPage(0);
      }
    };
    
    const analyticalData = () => {
      setAnalytical(!analytical);
      const designKickOffCustomerCounts = {};
      const designKickOffInternalCounts = {};

      designKickOffLeads.forEach((data) => {
        if (data.chmUser) {
          if (data.currentStage === "Design Kick-Off : Customer") {
            if (designKickOffCustomerCounts[data.chmUser.name]) {
              designKickOffCustomerCounts[data.chmUser.name]++;
            } else {
              designKickOffCustomerCounts[data.chmUser.name] = 1;
            }
          } else if (data.currentStage === "Design Kick-Off : Internal") {
            if (designKickOffInternalCounts[data.chmUser.name]) {
              designKickOffInternalCounts[data.chmUser.name]++;
            } else {
              designKickOffInternalCounts[data.chmUser.name] = 1;
            }
          }
        }
      });
    
      const userCounts = chmUsers.map((user) => ({
        name: user.name,
        designKickOffCustomerCount: designKickOffCustomerCounts[user.name] || 0,
        designKickOffInternalCount: designKickOffInternalCounts[user.name] || 0,
      }));
    
      setChmUserCounts(userCounts);
    };

    const prepareCSVData = () => {
      return (searchbtn ? filteredLeads : designKickOffLeads).map((data) => ({
        lead_no: data.lead_no || 'NA',
        erpProjectNo: data.erpProjectNo || 'NA',
        clientName: data.customerId ? data.customerId.name : 'NA',
        leadWonDate: data.leadWonDate ? moment(data.leadWonDate).format('DD-MM-YYYY') : 'NA',
        designerAssignedDate: data.designerAssignedDate ? moment(data.designerAssignedDate).format('DD-MM-YYYY') : 'NA',
        salesExecutive: data.salesWonUser ? data.salesWonUser.name : 'NA',
        designer: data.designUser ? data.designUser.name : 'NA',
        chm: data.chmUser ? data.chmUser.name : 'NA',
        currentStage: data.currentStage || 'NA',
      }));
    };
    

    const downloadCSV = () => {
      props.setLoading(true);
    
      try {
        const csvArray = prepareCSVData();
        setCsvData(csvArray);
        const headers = Object.keys(csvArray[0]).map((key) => ({
          key,
          label: key === "lead_no" ? "Lead no." : key.charAt(0).toUpperCase() + key.slice(1), // Customize label if needed
        }));
        setHeaders(headers);
        setTimeout(() => {
          csvButtonRef.current.link.click();
          props.setLoading(false);
        });
      } catch (error) {
        console.error(error.message);
        props.setLoading(false);
      }
    };
    
    const chmUserLeads = designKickOffLeads.filter((item) => item.chmUser?.name === userDetails.name);
    const nameMatch = designKickOffLeads.map((item) => item.chmUser?.name);
    const isNameMatch = nameMatch.includes(userDetails.name);

    const handleDesignKickOffCustomerDesignMomSubmit = async (e) =>
    {
    try
    {
        setOpenDesignKickOffCustomerMom(false)

        const response = await HttpService.createDesignMom(designerMomChecklistData)

        setDesignKickOffCustomerFileLinks({...designKickOffCustomerFileLinks, momFileLink: response.data})
    }
    catch(error)
    {
        console.log('error', error)
    }
    }

    const handleDesignKickOffCustomerDesignMomCancel = (e) =>
    {
    try
    {
        setOpenDesignKickOffCustomerMom(false)
    }
    catch(error)
    {
        console.log('error', error)
    }
    }

  return (
    <div >

      {/* heading */}
      <h1 style={{textAlign: 'center'}}>Leads Under Design Kick-Off</h1>

      {userDetails.roles.find(role=>role.name === ROLENAME.Admin || role.name === ROLENAME.CHM_Manager  || role.name === ROLENAME.CHM_User ||
        role.name === ROLENAME.DesignManager || role.name === ROLENAME.AssistantDesignManager || role.name === ROLENAME.DesignUser || role.name === ROLENAME.SalesManager || role.name === ROLENAME.AssistantSalesManager || role.name === ROLENAME.SalesUser || role.name === ROLENAME.OperationDesignManager) && 
        <Paper variant='outlined'>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <FormControl fullWidth style={{ marginBottom: '10px' }} variant='outlined' size='small'>
              <Autocomplete
                multiple
                value={selectedUser}
                onChange={handleSearchUser}
                options={chmList}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Search User'
                    variant='outlined'
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth style={{ marginBottom: '15px' }} variant='outlined' size='small'>
              <Autocomplete
                multiple
                value={selectedStage}
                onChange={handleSearchStage}
                options={stagesList}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Search Stage'
                    variant='outlined'
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs = {4}>
          <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '15px' }}>
            <Button onClick={searchFilter} variant='contained' color='primary' size='small'>
              Search
            </Button>
            <Button onClick={clearFilters} variant='contained' color='secondary' size='small' style={{ marginLeft: '10px' }}>
              Clear Filter
            </Button>
          </div>
          </Grid>
          <Grid item xs={12}>
            <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '10px' }}>
              { userDetails.roles.find(role => role.name === ROLENAME.Admin || role.name === ROLENAME.CHM_Manager) && (
                <Button onClick={analyticalData} variant='contained' color='primary' size='small' style={{ marginLeft: '10px' }}>
                  Analytics
                </Button>
              )}
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                <CSVLink
                  headers={headers}
                  filename={'Design_KickOff_Leads.csv'}
                  data={csvData}
                  ref={csvButtonRef}
                  target='_blank'
                />
                <Button
                  onClick={downloadCSV}
                  variant='contained'
                  color='primary'
                  size='small'
                >
                  Download CSV
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
        </Paper>
      }
      <>
      {analytical &&(
        <div style={{ marginTop: '10px', marginBottom : '25px' }}>
          <TableContainer component={Paper}>

          <Table className={classes.table} size="small" aria-label="custom pagination table">
  
            <TableHead>
              <TableRow>
                <StyledTableCell style={{textAlign: 'center'}}>CHM</StyledTableCell>
                <StyledTableCell style={{textAlign: 'center'}}>Design Kick Off Internal</StyledTableCell>
                <StyledTableCell style={{textAlign: 'center'}}>Design Kick Off Customer</StyledTableCell>
              </TableRow>
            </TableHead>
          <TableBody>
          {chmUserCounts ? chmUserCounts.map((user) => (
            <StyledTableRow key={user.name}>
              <StyledTableCell>{user.name}</StyledTableCell>
              <StyledTableCell>{user.designKickOffInternalCount}</StyledTableCell>
              <StyledTableCell>{user.designKickOffCustomerCount}</StyledTableCell>
            </StyledTableRow>
          )) : ""}
          </TableBody>
          </Table>
          </TableContainer>
        </div>
      )}
      </>
      <TableContainer component={Paper}>

        <Table className={classes.table} size="small" aria-label="custom pagination table">

          <TableHead>
          <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 20, 50, { label: 'All', value: designKickOffLeads?.length }]}
                colSpan={14}
                count={designKickOffLeads.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>

            <TableRow>
              <StyledTableCell style={{textAlign: 'center'}}>Sl No.</StyledTableCell>
              <StyledTableCell style={{textAlign: 'center'}}>Lead No.</StyledTableCell>
              <StyledTableCell style={{textAlign: 'center'}}>Project No.</StyledTableCell>
              <StyledTableCell style={{textAlign: 'center'}}>Client Name</StyledTableCell>
              <StyledTableCell style={{textAlign: 'center'}}>Sales Won Date</StyledTableCell>
              <StyledTableCell style={{textAlign: 'center'}}>Designer Assigned Date</StyledTableCell>
              <StyledTableCell style={{textAlign: 'center'}}>Sales Executive</StyledTableCell>
              <StyledTableCell style={{textAlign: 'center'}}>Designer</StyledTableCell>
              <StyledTableCell style={{textAlign: 'center'}}>CHM</StyledTableCell>
              <StyledTableCell style={{textAlign: 'center'}}>ADM</StyledTableCell>
              <StyledTableCell style={{textAlign: 'center'}}>Current Stage</StyledTableCell>
              {!userDetails.roles.find(role=>role.name == ROLENAME.SalesManager || role.name == ROLENAME.SalesUser || role.name == ROLENAME.Admin || role.name == ROLENAME.AssistantSalesManager) && <StyledTableCell colSpan={3} style={{textAlign: 'center'}}>Action</StyledTableCell>}
            </TableRow>
          </TableHead>

          <TableBody>

            {/* designKickOffLeads */}
            {(rowsPerPage > 0 && searchbtn
              ? filteredLeads
              : designKickOffLeads.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ).filter((data) => {
                if (userDetails.roles.find(role => role.name === ROLENAME.CHM_User)) {
                  // Only CHM user sees filtered leads
                  return isNameMatch ? data.chmUser?.name === userDetails.name : true;
                } else {
                  // Other users see all leads
                  return true;
                }
              })
              .map((data, i) => (
              
              <>
                
                <StyledTableRow key={j++}>

                <StyledTableCell>
                    {i+1}
                  </StyledTableCell>


                  <StyledTableCell>
										{
                      data.lead_no ?
                      <Link to={`/project-logs/${data._id}`} style={{ color: "#000", textDecoration: 'underline', textAlign: 'center' }}>  {data.lead_no}</Link> :
                      'NA'
                    }
									
									</StyledTableCell>
                  <StyledTableCell>
                    {
                      data.erpProjectNo ?
                      <Link to={`/project-logs/${data?._id}`} style={{ color: "#000", textDecoration: 'underline', textAlign: 'center' }}>  {data.erpProjectNo}</Link> :
                      'NA'
                    }
									
									</StyledTableCell>
                  <StyledTableCell>
                  {
                      data.customerId ?
                      data.customerId.name :
                      'NA'
                    }
									
									</StyledTableCell>
                  
                  <StyledTableCell style={{textAlign: 'center'}}>{data.leadWonDate ? moment(data.leadWonDate).format('DD-MM-YYYY') : 'NA'}</StyledTableCell>
                  <StyledTableCell style={{textAlign: 'center'}}>{data.designerAssignedDate ? moment(data.designerAssignedDate).format('DD-MM-YYYY') : 'NA'}</StyledTableCell>
                  <StyledTableCell style={{textAlign: 'center'}}>{data.salesWonUser ? data.salesWonUser.name : 'NA'}</StyledTableCell>
                  <StyledTableCell style={{textAlign: 'center'}}>{data.designUser ? data.designUser.name : 'NA'}</StyledTableCell>
                  <StyledTableCell style={{textAlign: 'center'}}>{data.chmUser ? data.chmUser.name : 'NA'}</StyledTableCell>
                  <StyledTableCell style={{textAlign: 'center'}}>{data.designManager ? data.designManager.name : 'NA'}</StyledTableCell>
                  <StyledTableCell style={{textAlign: 'center'}}>{data.currentStage ? data.currentStage : 'NA'}</StyledTableCell>
                  
                  {
                  !userDetails.roles.find(role=>role.name == ROLENAME.SalesManager || role.name == ROLENAME.SalesUser || role.name == ROLENAME.AssistantSalesManager  || role.name == ROLENAME.DGM_Design || role.name == ROLENAME.DesignHead || role.name == ROLENAME.OperationDesignManager || role.name == ROLENAME.Admin) &&
                  <StyledTableCell style={{ textAlign: 'center' }}>
                      <Button
                        disabled=
                        {
                          // data.designKickOffCustomerActionFromChm != 'Pending' ||
                          (userDetails.roles.find(role=>role.name == ROLENAME.DesignManager) && (!data.isDesignKickOffCustomerApprovedFromDesign || data.currentStage != 'Design Kick-Off : Customer')) ||
                          (userDetails.roles.find(role=>role.name == ROLENAME.DesignUser|| role.name == ROLENAME.AssistantDesignManager  ) && (data.currentStage != 'Design Kick-Off : Customer' || data.isDesignKickOffCustomerApprovedFromDesign)) 
                        }
                        className={classes.approveButton}
                        variant="contained"
                        onClick={(e) => handleDesignKickOffCustomerApproveClick(e, data)}
                      >
                        SEND FOR APPROVAL
                      </Button>
                    </StyledTableCell>
                  }
                { 
                  !userDetails.roles.find(role=>role.name == ROLENAME.SalesManager || role.name == ROLENAME.SalesUser || role.name == ROLENAME.AssistantSalesManager   || role.name == ROLENAME.DesignUser || role.name == ROLENAME.Admin ) &&
                  <StyledTableCell style={{textAlign: 'center'}}>
                  <Button
                    disabled=
                    {
                      // data.designKickOffCustomerActionFromChm != 'Pending' ||
                      (userDetails.roles.find(role=>role.name == ROLENAME.DesignManager || role.name === ROLENAME.AssistantDesignManager|| role.name === ROLENAME.OperationDesignManager || role.name === ROLENAME.DGM_Design) && (!data.isDesignKickOffCustomerApprovedFromDesign || data.currentStage != 'Design Kick-Off : Customer')) 
                    }
                    className={classes.approveButton}
                    variant="contained"
                    onClick={(e) => handleDesignKickOffCustomerApproveClickApproval(e, data)}
                    >
                    Approve
                  </Button>
                </StyledTableCell>
                }


                  {
                    userDetails.roles.find(role=>role.name == ROLENAME.DesignManager ||role.name == ROLENAME.AssistantDesignManager || role.name == ROLENAME.OperationDesignManager || role.name == ROLENAME.DGM_Design ) &&
                    <StyledTableCell style={{textAlign: 'center'}}>
                        <Button
                        // disabled
                        // {
                        //  data.currentStage != 'Design Kick-Off : Customer' ||
                        //  data.designKickOffCustomerActionFromChm != 'Pending' ||
                        // !data.isDesignKickOffCustomerApprovedFromDesign ||
                        //  !userDetails.roles.find(role=>role.name == ROLENAME.CHM_User || role.name == ROLENAME.CHM_Manager)
                        // }
                        className={classes.returnButton} variant="contained" onClick={(e) => handleDesignKickOffCustomerRejectClick(e, data)}>
                            Reject
                        </Button>
                  </StyledTableCell>
                  }

                </StyledTableRow>
              </>
            ))}

            {emptyRows > 0 && (
              <StyledTableRow style={{ height: 53 * emptyRows }}>
                <StyledTableCell colSpan={9} />
              </StyledTableRow>
            )}
            
          </TableBody>

          {/* <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 20, 50, { label: 'All', value: -1 }]}
                colSpan={6}
                count={designKickOffLeads.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter> */}
          
        </Table>
      </TableContainer>

      {/* openDesignKickOffCustomerApprovalDialogForChm */}
      <Dialog open={openDesignKickOffCustomerApprovalDialogForChm} onClose={() => setOpenDesignKickOffCustomerApprovalDialogForChm(false)}>
                <DialogTitle>Docs Uploaded by Design</DialogTitle>
                <DialogContent>
                    
                    <Box sx={{ minWidth: 550 }}>
                      <TableContainer component={Paper}>
                        <Table className={classes.table} size="small" aria-label="custom pagination table">
                          <TableBody>
                            <TableRow>
                              <StyledTableCell style={{textAlign: 'center'}}>Designer MOM</StyledTableCell>
                              <StyledTableCell style={{textAlign: 'center'}}><DownloadOutlined onClick={() => window.open(leadDetails?.designKickOffCustomerFileLinks?.momFileLink)} /></StyledTableCell>
                            </TableRow>
                            <TableRow>
                              <StyledTableCell style={{textAlign: 'center'}}>SKP File</StyledTableCell>
                              <StyledTableCell style={{textAlign: 'center'}}><DownloadOutlined onClick={() => window.open(leadDetails?.designKickOffCustomerFileLinks?.skpFileLink)} /></StyledTableCell>
                            </TableRow>
                            <TableRow>
                              <StyledTableCell style={{textAlign: 'center'}}>Quotation File</StyledTableCell>
                              <StyledTableCell style={{textAlign: 'center'}}><DownloadOutlined onClick={() => window.open(leadDetails?.designKickOffCustomerFileLinks?.quotationFileLink)} /></StyledTableCell>
                            </TableRow>
                            {
                              leadDetails?.designKickOffCustomerFileLinks?.screenshShotsLinks.map((el,index) =>
                              {
                                return (
                                <TableRow>
                                  <StyledTableCell style={{textAlign: 'center'}}>ScreenShot {index + 1}</StyledTableCell>
                                  <StyledTableCell style={{textAlign: 'center'}}><DownloadOutlined onClick={() => window.open(el)} /></StyledTableCell>
                                </TableRow>)
                              })
                              
                            }
                          </TableBody>
                          
                        </Table>

                      </TableContainer>
                    </Box>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleDesignKickOffCustomerApprovalFromChm()} disabled={false} variant="contained" color="primary">Proceed</Button>
                    <Button onClick={() => setOpenDesignKickOffCustomerApprovalDialogForChm(false)} variant="outlined" color="primary">Cancel</Button>
                    
                        
                    

                </DialogActions>
      </Dialog>

      {/* openDesignKickOffCustomerRejectionDialogForChm */}
      <Dialog open={openDesignKickOffCustomerRejectionDialogForChm} onClose={() => setOpenDesignKickOffCustomerRejectionDialogForChm(false)}>
                <DialogTitle>Return to Previous Owners</DialogTitle>
                <DialogContent>
                    
                    <Box sx={{ minWidth: 550 }}>
                      <FormControl fullWidth variant="outlined" size="small">
                      
                          <TextField
                          variant='outlined'
                          value={chmRemarksForRejection}
                          label={'Reason for the Rejection'}
                          style={{ width: 500, marginBottom: 10 ,marginLeft:25}}
                          onChange={(e)=>setChmRemarksForRejection(e.target.value)}
                          />
                          <br />

                          <Autocomplete
                              value={selectedPreviousUserData}
                              onChange={(e, v) => v ? setSelectedPreviousUserData(v) : setSelectedPreviousUserData({})}
                              options={previousUsers}
                              getOptionLabel={(option) => option.name}
                              style={{ width: 500, marginBottom: 10 ,marginLeft:25}}
                              renderInput={(params) => <TextField {...params} label="Select Previous Users" fullWidth variant="outlined" />}
                          />
                      </FormControl>
                    </Box>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleDesignKickOffCustomerRejectSubmitFromChm()} disabled={!Boolean(selectedPreviousUserData.name) || !Boolean(chmRemarksForRejection)} variant="contained" color="primary">Submit</Button>
                    <Button onClick={() => {setOpenDesignKickOffCustomerRejectionDialogForChm(false); setSelectedPreviousUserData({}); setChmRemarksForRejection('')}} variant="outlined" color="primary">Cancel</Button>
                    
                        
                    

                </DialogActions>
      </Dialog>

      {/* Dialog for DesignKickOffCustomerDocsUpload */}
      <Dialog open={openDesignKickOffCustomerDocsUploadDialog} onClose={() => setOpenDesignKickOffCustomerDocsUploadDialog(false)}>
                <DialogTitle>Required Details & Docs</DialogTitle>
                <DialogContent>
                    
                    <Box sx={{ minWidth: 550 }}>

                        <div style={{ paddingBottom: 10, paddingTop: 10 }} variant="outlined" size="small">
                          
                        <Button className={classes.UploadDocsbuttons}  disabled={docsUploading} onClick={() => handleDesignerMomClick()} variant="contained" color="primary">Fill MOM</Button>
                            {
                                designKickOffCustomerFileLinks.momFileLink && <CheckCircleIcon style={{ verticalAlign: 'middle', marginLeft: 10, color: "green", fontSize: 30 }} />

                            }
                          
                        </div>
                        <br />

                        <div style={{ paddingBottom: 10, paddingTop: 10 }} variant="outlined" size="small">
                            <input
                              accept=".skp"
                              style={{ display: 'none' }}
                              id="upload_skp_file"
                              type="file"
                              onChange={(e)=>handleDesignKickOffDocsUpload(e)}
                              ref={fileInputRef1}
                            />
                            <label htmlFor="upload_skp_file">
                            <Button disabled={docsUploading} className={classes.UploadDocsbuttons} variant="contained" color="primary" component="span">
                              Upload SKP File
                              </Button>
                              {
                                designKickOffCustomerFileLinks.skpFileLink && <CheckCircleIcon style={{ verticalAlign: 'middle', marginLeft: 10, color: "green", fontSize: 30 }} />

                              }
                            </label>
                            <ul>
                            {designKickOffCustomerFileNames.skpFileName.map((name, index) => (
                              
                              <li key={index}>{name}<DeleteIcon style={{ cursor: "pointer", verticalAlign: 'middle', marginLeft: 10, color: "red", fontSize: 20 }} onClick={() => handleDeleteFile(index, 'skpFileName')} /></li>
                              
                            ))}
                          </ul>
                        </div>

                        <div style={{ paddingBottom: 10, paddingTop: 10 }} variant="outlined" size="small">
                            <input
                              accept=".pdf"
                              style={{ display: 'none' }}
                              id="upload_quotation_pdf"
                              type="file"
                              onChange={(e)=>handleDesignKickOffDocsUpload(e)}
                              ref={fileInputRef2}
                            />
                            <label htmlFor="upload_quotation_pdf">
                            <Button disabled={docsUploading} className={classes.UploadDocsbuttons} variant="contained" color="primary" component="span">
                              Upload Quotation PDF
                              </Button>
                              {
                                designKickOffCustomerFileLinks.quotationFileLink && <CheckCircleIcon style={{ verticalAlign: 'middle', marginLeft: 10, color: "green", fontSize: 30 }} />

                              }
                            </label>
                            <ul>
                            {designKickOffCustomerFileNames.quotationFileName.map((name, index) => (
                              
                              <li key={index}>{name}<DeleteIcon style={{ cursor: "pointer", verticalAlign: 'middle', marginLeft: 10, color: "red", fontSize: 20 }} onClick={() => handleDeleteFile(index, 'quotationFileName')} /></li>
                              
                            ))}
                          </ul>
                        </div>

                        <div style={{ paddingBottom: 10, paddingTop: 10 }} variant="outlined" size="small">
                          <input
                            multiple
                            accept=".jpg, .jpeg, .png, .pdf"
                            id="upload_screenshots"
                            style={{ display: 'none' }}
                            type="file"
                            onChange={(e) => handleDesignKickOffDocsUpload(e)}
                            ref={fileInputRef3}
                          />
                          <label htmlFor="upload_screenshots">
                            <Button disabled={docsUploading} className={classes.UploadDocsbuttons} variant="contained" color="primary" component="span">
                                Choose Screenshots
                            </Button>
                              {
                                designKickOffCustomerFileLinks.screenshShotsLinks.length > 0 && <CheckCircleIcon style={{ verticalAlign: 'middle', marginLeft: 10, color: "green", fontSize: 30 }} />

                              }
                          </label>
                          <ul>
                            {designKickOffCustomerFileNames.screenshotsNames.map((name, index) => (
                              
                              <li key={index}>{name}<DeleteIcon style={{ cursor: "pointer", verticalAlign: 'middle', marginLeft: 10, color: "red", fontSize: 20 }} onClick={() => handleDeleteFile(index, 'screenshotsNames')} /></li>
                              
                              
                            ))}
                          </ul>
                        </div>
                        
                    </Box>

                </DialogContent>
                <DialogActions>
                    
                    <Button style={{borderRadius: 50}} onClick={(e) => handleDesignKickOffApprovalDocsFromDesign(e)} disabled={docsUploading || designKickOffCustomerFileLinks.skpFileLink == '' || designKickOffCustomerFileLinks.quotationFileLink == '' || designKickOffCustomerFileLinks.momFileLink == '' || designKickOffCustomerFileLinks.screenshShotsLinks.length == 0} variant="contained" color="primary">Submit</Button>
                    <Button style={{borderRadius: 50}} onClick={() => handleDesignKickOffCancelFromDesign()} variant="outlined" color="primary">Cancel</Button>
                    
                        
                    

                </DialogActions>
      </Dialog>

      {/* Designer MOM format */}
      <Dialog fullWidth={true} maxWidth={'xl'} open={openDesignKickOffCustomerMom} onClose={() => setOpenDesignKickOffCustomerMom(false)}>
          <div style={{ margin: 'auto' }}>
                <br />
                 <Typography style={{ textAlign: 'center' }} variant="h5" component="h2"><strong>Design Kick off M.O.M</strong></Typography>
                 <br />

                 {/* Common Details Section */}
                 <Paper>
                    <Card  className={classes.root} variant="outlined">
                        <CardContent>
                            <TableContainer component={Paper} style={{ width: '100%' }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableBody>
                                        <TableRow key='0'>
                                            <TableCell component="th" scope="row">Designer Name</TableCell>
                                            <TableCell component="th" scope="row">{designerMomChecklistData.designerUserName}</TableCell>
                                        </TableRow>
                                        <TableRow key='1'>
                                            <TableCell component="th" scope="row">Design Manager Name</TableCell>
                                            <TableCell component="th" scope="row">{designerMomChecklistData.designerManagerName}</TableCell>

                                        </TableRow>
                                        <TableRow key='2'>
                                            <TableCell component="th" scope="row">Client Name</TableCell>
                                            <TableCell component="th" scope="row">
                                              <FormControl style={{ width: 300 }}>
                                                    <TextField onChange={e => handleDesignerMomChanges(e)} size="small" id="outlined-basic" value={designerMomChecklistData.clientName} variant="outlined" name="clientName" type='text' />
                                              </FormControl>
                                            </TableCell>

                                        </TableRow>
                                        <TableRow key='3'>
                                            <TableCell component="th" scope="row">Apartment Name</TableCell>
                                            <TableCell>
                                              <FormControl style={{ width: 300 }}>
                                                      <TextField onChange={e => handleDesignerMomChanges(e)} size="small" id="outlined-basic" value={designerMomChecklistData.ApartmentName} variant="outlined" name="ApartmentName" type='text' />
                                              </FormControl>
                                            </TableCell>

                                        </TableRow>
                                        <TableRow key='4'>
                                            <TableCell component="th" scope="row">CHM Name</TableCell>
                                            <TableCell component="th" scope="row">{designerMomChecklistData.chmName}</TableCell>

                                        </TableRow>
                                        <TableRow key='5'>
                                            <TableCell component="th" scope="row">Sales Owner Name</TableCell>
                                            <TableCell component="th" scope="row">{designerMomChecklistData.salesOwnerName}</TableCell>

                                        </TableRow>
                                        <TableRow key='6'>
                                            <TableCell component="th" scope="row">Designer Assignment Date</TableCell>
                                            <TableCell component="th" scope="row">{designerMomChecklistData.designerAssignmentDate}</TableCell>

                                        </TableRow>

                                        <TableRow key='7'>
                                            <TableCell component="th" scope="row">Date of kick off meeting</TableCell>
                                            <TableCell component="th" scope="row">
                                              <FormControl style={{ width: 300 }}>
                                                    <TextField
                                                        // style={{ margin: '10px' }}
                                                        id="dateOfKickOffMeeting"
                                                        name="dateOfKickOffMeeting"
                                                        value={designerMomChecklistData.dateOfKickOffMeeting}
                                                        onChange={(e) => handleDesignerMomChanges(e)}
                                                        // label="Date of kick off meeting"
                                                        type="date"
                                                        variant="outlined"
                                                        InputLabelProps={{
                                                          shrink: true
                                                      }}
                                                      size="small"
                                                        
                                                    />
                                              </FormControl>
                                              </TableCell>
                                        </TableRow>
                                        
                                        <TableRow key='8'>
                                            <TableCell component="th" scope="row">Possesion Date</TableCell>
                                            <TableCell component="th" scope="row">
                                              <FormControl style={{ width: 300 }}>
                                                  <TextField
                                                      // style={{ margin: '10px' }}
                                                      id="possesionDate"
                                                      name="possesionDate"
                                                      value={designerMomChecklistData.possesionDate}
                                                      onChange={(e) => handleDesignerMomChanges(e)}
                                                      // label="Possesion Date"
                                                      type="date"
                                                      variant="outlined"
                                                      InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    size="small"
                                                      
                                                  />
                                              </FormControl>
                                              </TableCell>
                                        </TableRow>

                                        <TableRow key='9'>
                                            <TableCell component="th" scope="row">Tentative Design sign off Date</TableCell>
                                            <TableCell>
                                            <FormControl style={{ width: 300 }}>
                                                  <TextField
                                                      // style={{ margin: '10px' }}
                                                      id="tentativeDesignSignOffDate"
                                                      name="tentativeDesignSignOffDate"
                                                      value={designerMomChecklistData.tentativeDesignSignOffDate}
                                                      onChange={(e) => handleDesignerMomChanges(e)}
                                                      // label="Possesion Date"
                                                      type="date"
                                                      variant="outlined"
                                                      InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    size="small"
                                                      
                                                  />
                                              </FormControl>
                                            </TableCell>
                                        </TableRow>

                                        <TableRow key='10'>
                                            <TableCell component="th" scope="row">Tentative Move - in Date</TableCell>
                                            <TableCell>
                                            <FormControl style={{ width: 300 }}>
                                                  <TextField
                                                      // style={{ margin: '10px' }}
                                                      id="tentativeMoveInDate"
                                                      name="tentativeMoveInDate"
                                                      value={designerMomChecklistData.tentativeMoveInDate}
                                                      onChange={(e) => handleDesignerMomChanges(e)}
                                                      // label="Possesion Date"
                                                      type="date"
                                                      variant="outlined"
                                                      InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    size="small"
                                                      
                                                  />
                                              </FormControl>
                                            </TableCell>
                                        </TableRow>

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                </Paper> 
                <br />
                
                {/* Services Section */}
                <br />
                <Paper>
                    <Card className={classes.root} variant="outlined">
                        <CardContent>
                            <TableContainer component={Paper} className={classes.container}>
                                <Table className={classes.table} stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ textAlign: 'left', fontWeight: 700, fontSize: 20, width: 1000}}>Services</TableCell>
                                            <TableCell colSpan={2} style={{ textAlign: 'center', fontWeight: 700 }}>Approved by client</TableCell>
                                          
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {designerMomChecklistData.services.map((row, index) =>
                                          {
                                            const labelId = `enhanced-table-checkbox-${index}`;
                                            return (
                                                row.type == 'boolean' ? (<TableRow key={index}>

                                                    <TableCell component="th" scope="row">{row?.description}</TableCell>
                                                      
                                                    <TableCell style={{ textAlign: 'center' }} component="th">Yes
                                                      <Radio
                                                      value='yes'
                                                      checked={row.value == 'yes'}
                                                      name="radio-button-demo"
                                                      inputProps={{ 'aria-label': labelId }}
                                                      onChange={(e) => handleDesignerMomChecklistActions('services', 'booleans', index, e)}
                                                      />
                                                    </TableCell>
                                              
                                                    <TableCell style={{textAlign: 'center' }} component="th">No
                                                      <Radio
                                                      value='no'
                                                      checked={row.value == 'no'}
                                                      name="radio-button-demo"
                                                      inputProps={{ 'aria-label': labelId }}
                                                      onChange={(e) => handleDesignerMomChecklistActions('services', 'booleans', index, e)}
                                                    />
                                                  </TableCell>

                                                </TableRow>) :
                                                (
                                                  <TableRow key={index}>
                                          
                                                    <TableCell colSpan={3}>
                                                      <TextField
                                                      variant='outlined'
                                                      fullWidth
                                                      value={row.value}
                                                      label={row.description}
                                                      onChange={(e)=>handleDesignerMomChecklistActions('services', 'comments', index , e)}
                                                      />
                                                    </TableCell>
                                                      
                                                  </TableRow>
                                                  
                                                )
                                                
                                            );
                                          }
                                        )}

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                </Paper>
                
                <br />
                
                {/* Standards Section */}
                <Paper>
                  <Card className={classes.root} variant="outlined">
                    <CardContent component={Paper}>
                      <Table  className={classes.table} stickyHeader aria-label="sticky table">
                        
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ textAlign: 'left', fontWeight: 700, fontSize: 20, width: 1000 }}>Standards - Finishes  & Handles </TableCell>
                                <TableCell colSpan={2} style={{ textAlign: 'center', fontWeight: 700 }}>Approved by client</TableCell>
                              
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {designerMomChecklistData.standards.map((row, index) =>
                              {
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    row.type == 'boolean' ? (
                                    <TableRow key={index}>
                                        <TableCell component="th" scope="row">{row?.description}</TableCell>
                                          
                                        <TableCell style={{ textAlign: 'center' }} component="th">Yes
                                          <Radio
                                          value='yes'
                                          checked={row.value == 'yes'}
                                          name="radio-button-demo"
                                          inputProps={{ 'aria-label': labelId }}
                                          onChange={(e) => handleDesignerMomChecklistActions('standards', 'booleans', index, e)}
                                          />
                                        </TableCell>
                                  
                                        <TableCell style={{textAlign: 'center' }} component="th">No
                                          <Radio
                                          value='no'
                                          checked={row.value == 'no'}
                                          name="radio-button-demo"
                                          inputProps={{ 'aria-label': labelId }}
                                          onChange={(e) => handleDesignerMomChecklistActions('standards', 'booleans', index, e)}
                                        />
                                      </TableCell>
                                    </TableRow>) :
                                    (
                                      <TableRow key={index}>
                              
                                        <TableCell  component="th" scope="row" colSpan={3}>
                                          <TextField
                                          variant='outlined'
                                          fullWidth
                                          value={row.value}
                                          label={row.description}
                                          onChange={(e)=>handleDesignerMomChecklistActions('standards', 'comments', index, e)}
                                          />
                                        </TableCell>
                                          
                                      </TableRow>
                                    )
                                    
                                );
                              }
                            )}
                        </TableBody>
                      </Table>
                    </CardContent>
                  </Card>
                </Paper>

                {/* False-ceiling Section */}
                <br/> 
                <Paper>
                    <Card className={classes.root} variant="outlined">
                        <CardContent>
                            <TableContainer component={Paper} className={classes.container}>
                                <Table className={classes.table} stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ textAlign: 'left', fontWeight: 700, fontSize: 20, width: 1000 }}>False - ceiling</TableCell>
                                            <TableCell colSpan={2} style={{ textAlign: 'center', fontWeight: 700 }}>Approved by client</TableCell>
                                          
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {designerMomChecklistData.falseCeiling.map((row, index) =>
                                          {
                                            const labelId = `enhanced-table-checkbox-${index}`;
                                            return (
                                                row.type == 'boolean' ? (<TableRow key={index}>

                                                    <TableCell component="th" scope="row">{row?.description}</TableCell>
                                                      
                                                    <TableCell style={{ textAlign: 'center' }} component="th">Yes
                                                      <Radio
                                                      value='yes'
                                                      checked={row.value == 'yes'}
                                                      name="radio-button-demo"
                                                      inputProps={{ 'aria-label': labelId }}
                                                      onChange={(e) => handleDesignerMomChecklistActions('falseCeiling', 'booleans', index, e)}
                                                      />
                                                    </TableCell>
                                              
                                                    <TableCell style={{textAlign: 'center' }} component="th">No
                                                      <Radio
                                                      value='no'
                                                      checked={row.value == 'no'}
                                                      name="radio-button-demo"
                                                      inputProps={{ 'aria-label': labelId }}
                                                      onChange={(e) => handleDesignerMomChecklistActions('falseCeiling', 'booleans', index, e)}
                                                    />
                                                  </TableCell>

                                                </TableRow>) :
                                                (
                                                  <TableRow key={index}>
                                          
                                                    <TableCell colSpan={3}>
                                                      <TextField
                                                      variant='outlined'
                                                      fullWidth
                                                      value={row.value}
                                                      label={row.description}
                                                      onChange={(e)=>handleDesignerMomChecklistActions('falseCeiling', 'comments', index, e)}
                                                      />
                                                    </TableCell>
                                                      
                                                  </TableRow>
                                                )
                                                
                                            );
                                          }
                                        )}

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                </Paper>

                {/* Electricals Section */}
                <br/> 
                <Paper>
                    <Card className={classes.root} variant="outlined">
                        <CardContent>
                            <TableContainer component={Paper} className={classes.container}>
                                <Table className={classes.table} stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ textAlign: 'left', fontWeight: 700, fontSize: 20, width: 1000 }}>Electricals</TableCell>
                                            <TableCell colSpan={2} style={{ textAlign: 'center', fontWeight: 700 }}>Approved by client</TableCell>
                                          
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {designerMomChecklistData.electricals.map((row, index) =>
                                          {
                                            const labelId = `enhanced-table-checkbox-${index}`;
                                            return (
                                                row.type == 'boolean' ? (<TableRow key={index}>

                                                    <TableCell component="th" scope="row">{row?.description}</TableCell>
                                                      
                                                    <TableCell style={{ textAlign: 'center' }} component="th">Yes
                                                      <Radio
                                                      value='yes'
                                                      checked={row.value == 'yes'}
                                                      name="radio-button-demo"
                                                      inputProps={{ 'aria-label': labelId }}
                                                      onChange={(e) => handleDesignerMomChecklistActions('electricals', 'booleans', index, e)}
                                                      />
                                                    </TableCell>
                                              
                                                    <TableCell style={{textAlign: 'center' }} component="th">No
                                                      <Radio
                                                      value='no'
                                                      checked={row.value == 'no'}
                                                      name="radio-button-demo"
                                                      inputProps={{ 'aria-label': labelId }}
                                                      onChange={(e) => handleDesignerMomChecklistActions('electricals', 'booleans', index, e)}
                                                    />
                                                  </TableCell>

                                                </TableRow>) :
                                                (
                                                  <TableRow key={index}>
                                          
                                                    <TableCell colSpan={3}>
                                                      <TextField
                                                      variant='outlined'
                                                      fullWidth
                                                      value={row.value}
                                                      label={row.description}
                                                      onChange={(e)=>handleDesignerMomChecklistActions('electricals', 'comments', index, e)}
                                                      />
                                                    </TableCell>
                                                      
                                                  </TableRow>
                                                )
                                                
                                            );
                                          }
                                        )}

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                </Paper>

                {/* Miscellaneous Section */}
                <br/> 
                <Paper>
                    <Card className={classes.root} variant="outlined">
                        <CardContent>
                            <TableContainer component={Paper} className={classes.container}>
                                <Table className={classes.table} stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ textAlign: 'left', fontWeight: 700, fontSize: 20, width: 1000 }}>Miscellaneous</TableCell>
                                            <TableCell colSpan={2} style={{ textAlign: 'center', fontWeight: 700 }}>Approved by client</TableCell>
                                          
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {designerMomChecklistData.miscellaneous.map((row, index) =>
                                          {
                                            const labelId = `enhanced-table-checkbox-${index}`;
                                            return (
                                                row.type == 'boolean' ? (<TableRow key={index}>

                                                    <TableCell component="th" scope="row">{row?.description}</TableCell>
                                                      
                                                    <TableCell style={{ textAlign: 'center' }} component="th">Yes
                                                      <Radio
                                                      value='yes'
                                                      checked={row.value == 'yes'}
                                                      name="radio-button-demo"
                                                      inputProps={{ 'aria-label': labelId }}
                                                      onChange={(e) => handleDesignerMomChecklistActions('miscellaneous', 'booleans', index, e)}
                                                      />
                                                    </TableCell>
                                              
                                                    <TableCell style={{textAlign: 'center' }} component="th">No
                                                      <Radio
                                                      value='no'
                                                      checked={row.value == 'no'}
                                                      name="radio-button-demo"
                                                      inputProps={{ 'aria-label': labelId }}
                                                      onChange={(e) => handleDesignerMomChecklistActions('miscellaneous', 'booleans', index, e)}
                                                    />
                                                  </TableCell>

                                                </TableRow>) :
                                                (
                                                  <TableRow key={index}>
                                          
                                                    <TableCell colSpan={3}>
                                                      <TextField
                                                      variant='outlined'
                                                      fullWidth
                                                      value={row.value}
                                                      label={row.description}
                                                      onChange={(e)=>handleDesignerMomChecklistActions('miscellaneous', 'comments', index, e)}
                                                      />
                                                    </TableCell>
                                                      
                                                  </TableRow>
                                                )
                                                
                                            );
                                          }
                                        )}

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                </Paper>

                <br />
                {/* Additional comments */}
                <TableContainer component={Paper} style={{ width: '100%' }}>
                  <Table stickyHeader aria-label="sticky table">
                      <TableBody>
                      
                        <TableRow key={0}>

                        <TableCell colSpan={3}>
                          <TextField
                          variant='outlined'
                          fullWidth
                          value={designerMomChecklistData.additionalComments.value}
                          label={designerMomChecklistData.additionalComments.description}
                          onChange={(e)=>handleDesignerMomChecklistActions('additionalComments', 'comments', 0, e)}
                          />
                        </TableCell>

                        </TableRow>
                      
                      </TableBody>
                    </Table>
                </TableContainer>
                
                <br />
                <DialogActions>
                  <Button
                  onClick={(e) => handleDesignKickOffCustomerDesignMomSubmit(e)}
                  variant="contained"
                  color="primary"
                //   disabled={false}
                disabled=
                  {
                    (
                      designerMomChecklistData.services.find(el => (el.type == 'boolean' && el.value == '')) ||
                      designerMomChecklistData.services.find(el => (el.type == 'comments' && el.value == '')) ||
                      designerMomChecklistData.standards.find(el => (el.type == 'boolean' && el.value == '')) ||
                      designerMomChecklistData.standards.find(el => (el.type == 'comments' && el.value == '')) ||
                      designerMomChecklistData.falseCeiling.find(el => (el.type == 'boolean' && el.value == '')) ||
                      designerMomChecklistData.electricals.find(el => (el.type == 'boolean' && el.value == '')) ||
                      designerMomChecklistData.miscellaneous.find(el => (el.type == 'boolean' && el.value == '')) ||
                      designerMomChecklistData.additionalComments.value == '' ||
                      !designerMomChecklistData.dateOfKickOffMeeting ||
                      !designerMomChecklistData.possesionDate ||
                      !designerMomChecklistData.tentativeDesignSignOffDate ||
                      !designerMomChecklistData.tentativeMoveInDate ||
                      !designerMomChecklistData.clientName ||
                      !designerMomChecklistData.ApartmentName
                    ) ?
                    true :
                    false
                  }
                  style={{marginRight: 10}}
                  >
                  Submit
                  </Button>
                  <Button
                  onClick={(e) => handleDesignKickOffCustomerDesignMomCancel(e)}
                  variant="outlined"
                  color="primary"
                  disabled={false}
                  >
                  Cancel
                  </Button>
                </DialogActions>

                <br />
          </div>
      </Dialog>
       
    </div>
  )
}

// const mapstateToProps = state => { //changes
//     return {
//         vendorName: state.vendorName
//     }
// }

export default connect(null, { setLoading, setSnackInfo })(DesignKickOff)
