import React, { useState, useEffect } from 'react';
import { useSelector, connect } from 'react-redux';
import { TextField, MenuItem, FormControl, Button } from '@material-ui/core';
import HttpService from '../../services/httpService'
import { setLoading, setSnackInfo } from '../../redux/actions/appAction';
import { setUserDetails } from '../../redux/actions/userAction';
import { ROLENAME } from '../../utils';

const OrganisationPage = (props) => {
    const { userDetails } = useSelector(state => state.user)
    const [expcenters, setExpcenters] = useState([]);
    const [locations, setLocations] = useState([]);
    const [defaultExpCenter, setDefaultExpCenter] = useState('');
    const [defaultLocation, setDefaultLocation] = useState('');

    useEffect(() => {
        getLocationList();
        // eslint-disable-next-line
    }, [])



    const getLocationList = async () => {
        try {
            const response = await HttpService.getLocationList();
            // const userLocation = response.data.filter(ele => ele._id === userDetails.locationId[0]._id);
            let userLocation = [];
            for (let i = 0; i < response.data.length; i++) {
                for (let j = 0; j < userDetails.locationId.length; j++) {
                    if (response.data[i]._id === userDetails.locationId[j]._id) {
                        userLocation.push({
                            _id: userDetails.locationId[j]._id,
                            name: userDetails.locationId[j].name
                        })
                    }
                }
            }
            setLocations(userLocation)
            console.log(userLocation, "userLocation");
            setDefaultLocation(userLocation[0]['_id']);
            getExperienceCenterByLocation(userLocation[0]['_id'])
        } catch (error) {
            console.log(error)
        }
    }
    
    const getExpCenterByCity = async (cityid) => {
        setDefaultLocation(cityid);
        await getExperienceCenterByLocation(cityid)
    }

    const getExperienceCenterByLocation = async (locid) => {
        try {
            const response = await HttpService.getExpericenCentersByLocation(locid)
            let userExpCenters = [];
            for (let i = 0; i < response.data.length; i++) {
                for (let j = 0; j < userDetails.experienceCenterId.length; j++) {
                    if (userDetails.experienceCenterId[j] === response.data[i]['_id']) {
                        userExpCenters.push({
                            name: response.data[i]['name'],
                            _id: response.data[i]['_id']
                        });
                    }
                }
            }
            setExpcenters(userExpCenters);
        } catch (error) {
            console.log(error)
        }
    }

    const setLocationAndExpCenter = () => {
        // console.log('userDetails', userDetails)
        let teleCallerUser = userDetails?.roles?.find(role => role.name == ROLENAME.TeleCallerUser)
        // console.log('teleCallerUser', teleCallerUser)
        if(teleCallerUser) {
            props.history.push('/home', {
                location: defaultLocation,
                experiencecenter: defaultExpCenter
            });
        }
        else {
            props.history.push('/dashboard', {
                location: defaultLocation,
                experiencecenter: defaultExpCenter
            });
        }
        
        props.setUserDetails({
            ...userDetails,
            selectedExpCenter: defaultExpCenter,
            selectedLocation: defaultLocation
        })
    }

    return (
        <div className="verticalAlignCenter">
            <div style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <h2>Welcome to ERP Solution</h2>
            </div>
            <div style={{ alignItems: "center", justifyContent: "center", display: "grid", marginTop: 20 }}>
                <FormControl style={{ width: 300, marginRight: 20, marginBottom: 20 }}>
                    <TextField
                        select
                        labelId="demo-simple-select-label"
                        id="demo-expcenter-simple-select"
                        name="city"
                        value={defaultLocation}
                        label="Select Location"
                        onChange={(e) => getExpCenterByCity(e.target.value)}
                        variant="outlined"
                        size="small"
                    >
                        {
                            locations?.map((data, i) => (
                                <MenuItem value={data._id} key={i + 1}>{data.name}</MenuItem>
                            ))
                        }
                    </TextField>
                </FormControl>
                <FormControl style={{ width: 300, marginRight: 20, marginBottom: 20 }}>
                    <TextField
                        select
                        labelId="demo-simple-select-label"
                        id="demo-expcenter-simple-select"
                        name="experienceCenterId"
                        value={defaultExpCenter}
                        label="Select Experience Center"
                        onChange={(e) => setDefaultExpCenter(e.target.value)}
                        variant="outlined"
                        size="small"
                    >
                        {
                            expcenters?.map((data, i) => (
                                <MenuItem value={data._id} key={i + 1}>{data.name}</MenuItem>
                            ))
                        }
                    </TextField>
                </FormControl>
                <Button onClick={setLocationAndExpCenter} variant="outlined" color="primary" style={{width: "40%"}}>Proceed</Button>
            </div>
        </div>
    )
}
export default connect(null, { setLoading, setSnackInfo, setUserDetails })(OrganisationPage);