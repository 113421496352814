import React, { useState, useEffect, useRef } from 'react';
import { useParams } from "react-router-dom";
import { connect, useSelector } from 'react-redux'
import { Link } from "react-router-dom";
import { setLoading, setSnackInfo } from '../redux/actions/appAction';
import HttpService from '../services/httpService';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { CSVLink } from "react-csv";
import TextField from '@material-ui/core/TextField';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Grid, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import AttachmentIcon from '@material-ui/icons/Attachment';
import { commaSeparateAmount } from './commaSeparate';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ROLENAME } from '../utils/index';
// import { DataGrid } from '@material-ui/data-grid';

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 700,
    },
    root: {
        width: '100%',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));

const LeadToMeeting = (props) => {
    // console.log('LeadToMeeting props are ',props)
    const classes = useStyles();
    // const { statusName, startDate, endDate, check, isUser } = useParams();
    const [displayArray, setDisplayArray] = useState(props?.location?.state?.data?.leadToMeeting?.leadToMeetingValData)

    return (
        <>
            <div style={{ marginBottom: 20 }}>
                <Paper className={classes.paper}>
                    <h3 style={{ textAlign: 'center' }}>
                        {<>Status - Lead To Meeting</>}
                    </h3>
                </Paper>
            </div>
            <Divider />
            {
                    <>
                        <Divider />
                        <div style={{ display: 'flex', marginTop: 10, marginBottom: 20 }}>
                            <Paper>
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Sr. No.</TableCell>
                                                <TableCell>Customer</TableCell>
                                                <TableCell>Lead No</TableCell>
                                                <TableCell>Project No.</TableCell>
                                                <TableCell>Current Stage</TableCell>
                                                <TableCell>Email</TableCell>
                                                <TableCell>Mobile</TableCell>
                                                <TableCell>Address</TableCell>
                                                <TableCell>Area</TableCell>
                                                <TableCell>Lead Type</TableCell>
                                                <TableCell>Assigned to</TableCell>
                                                <TableCell>Quotation Amount</TableCell>
                                                <TableCell>Meeting Date</TableCell>
                                                <TableCell>Created At</TableCell>
                                                <TableCell>Expected Closure Date</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {displayArray?.length !== 0 ?
                                                displayArray
                                                    .sort((a, b) => b.createdAt.split('-').reverse().join().localeCompare(a.createdAt.split('-').reverse().join()))
                                                    .map((row, i) => {
                                                        return (
                                                            <TableRow key={i + 1}>
                                                                <TableCell>{i + 1}</TableCell>
                                                                <TableCell component="th" scope="row">
                                                                    <Link to={`/project-logs/${row?._id}`} style={{ color: "#000", textDecoration: 'underline' }}>{row?.customerId?.name}</Link>
                                                                </TableCell>
                                                                <TableCell component="th">{row?.lead_no || 'N/A'}</TableCell>
                                                                <TableCell component="th">{row?.erpProjectNo || 'N/A'}</TableCell>
                                                                <TableCell component="th">{row?.currentStage || 'N/A'}</TableCell>
                                                                <TableCell component="th">{row?.customerId?.email || 'N/A'}</TableCell>
                                                                <TableCell component="th">{row?.customerId?.contact_no || 'N/A'}</TableCell>
                                                                <TableCell component="th">{row?.address || 'N/A'}</TableCell>
                                                                <TableCell component="th">{row?.area || 'N/A'}</TableCell>
                                                                <TableCell component="th">{row?.leadType || 'N/A'}</TableCell>
                                                                <TableCell component="th">{row?.assignToName || 'N/A'}</TableCell>
                                                                <TableCell component="th">₹ {commaSeparateAmount(row?.grandTotal)}</TableCell>
                                                                <TableCell component="th">{moment(row?.meetingWithCustomer.meetingDate).format("DD-MM-YYYY")}</TableCell>
                                                                <TableCell component="th">{moment(row?.createdAt).format("DD-MM-YYYY")}</TableCell>
                                                                <TableCell component="th">{moment(row?.expectedSalesclosureDate).format("DD-MM-YYYY")}</TableCell>
                                                            </TableRow>
                                                        );
                                                    })
                                                :
                                                <TableRow>
                                                    <TableCell colSpan={9} style={{ textAlign: 'center' }}>No Records Found</TableCell>
                                                </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </div>
                    </>
            }
        </>
    )
}

export default connect(null, { setLoading, setSnackInfo })(LeadToMeeting)