import { GET_ALL_ROLES, GET_ALL_UNIQUE_ROLE_FIELDS } from '../actions/types'

const initialState = {
  rolesList: [],
  uniqueRoleFields: [],
}

export default function rolesReducer(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case GET_ALL_ROLES:
      return {
        ...state,
        rolesList: payload,
      }
    case GET_ALL_UNIQUE_ROLE_FIELDS:
      return {
        ...state,
        uniqueRoleFields: payload
      }
    default:
      return state
  }
}
