import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import decorpotLogo from "../assets/img/org/deco_new_logo.png";
import { connect } from "react-redux";
import { setLoading, setSnackInfo } from "../redux/actions/appAction";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from '@material-ui/core/MenuItem/MenuItem';

import {
  AccordionDetails,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableBody,
} from "@material-ui/core";

import HttpService from "../services/httpService";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import {
  TextField,
  FormControl,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Paper,
  Divider,
  Box,
  DialogContentText,
  Grid,
} from "@material-ui/core";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  surveyform: {
    backgroundColor: "#f0ebf8",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  textFieldWidth: {
    width: "100%",
  },
}));

const CustomerDesignHoldApprovalView = (props) => {
  const classes = useStyles();
  const { leadId } = useParams();
  const [designHoldData, setDesignHoldData] = useState([]);
  const [restartDate, setRestartDate] = useState("");
  const [restartDesignSignOffDate, setRestartDesignSignOffDate] = useState("");
  const [customerRemark, setCustomerRemark] = useState("");
  const [status, setStatus] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [oldProjectRestartDate, setOldProjectRestartDate] = useState("")
  const [oldProjectDesignSignOffDate, setOldProjectDesignSignOffDate] = useState("");
  const [designerRemark, setDesignerRemark] = useState('NA');
  const [designHoldSelectedType, setDesignHoldSelectedType] = useState("")
  const availableDesignHoldOptions = [
    { name: 'Possesion Delay' },
    { name: 'Cx not available' },
    { name: 'Cx having Financial Issue' },
    { name: 'Cx wants to put the projects on hold' },
    { name: 'Probable Loss' },
    { name: 'Others' },
  ];

  useEffect(() => {
    getLeadDetails();
  }, []);

  const getLeadDetails = async () => {
    try{
        const response = await HttpService.getLeadDetails(leadId);
        const data = response.data;
        setDesignHoldData(data);
        setRestartDate(
        moment(data?.proposedDesignStartDate).format("YYYY-MM-DD")
        );
        setRestartDesignSignOffDate(
        moment(data?.probableDesignSignOffDate).format("YYYY-MM-DD")
        );
        setCustomerRemark(data?.customerDesignHoldRemark )
        setDesignHoldSelectedType(data?.designHoldSelectedTypes)   
        setDesignerRemark(data?.designHoldReason)            // designer remark
    }
    catch(err){
        props.setSnackInfo("Something went wrong","error");
    }
  };

  const updateDesignHoldApprovalStatus = async () => {
    props.setLoading(true);
    try {
      const obj = {
        probableDesignSignOffDate: restartDesignSignOffDate,
        proposedDesignStartDate: restartDate,
        customerDesignHoldRemark: customerRemark,
        customerApproval: status,
        designHoldSelectedTypes: designHoldSelectedType,
        designHoldReason: designHoldData?.designHoldStatusApproval?.customerApproval ? designerRemark :  'NA',

      };
      const response = await HttpService.updateCustomerDesignHold(leadId, obj);
      if (status === "Approved") {
        props.setSnackInfo("Design-Hold form approved successfully", "success");
      } else if (status === "Rejected") {
        props.setSnackInfo("Design-Hold form rejected successfully", "success");
      }
      setOpenDialog(false);
    } catch (err) {
      props.setSnackInfo("Something went wrong", "error");
    }
    props.setLoading(false);
    getLeadDetails();
    // window.location.reload();
  };

  return (
    <div>
      <div style={{ marginBottom: 20, marginTop: 50 }}>
        <div
          style={{ display: "flex", flexDirection: "column", margin: "auto" }}
        >
          <div style={{ textAlign: "center" }}>
            <img
              src={decorpotLogo}
              alt="decorpot logo"
              style={{ width: "18%", textAlign: "center" }}
            />
          </div>
        </div>
        <h3 style={{ textAlign: "center" }}>Verify and Approve Design-Hold</h3>
      </div>
      <Divider />
      <div style={{ margin: "50px auto", width: "90%" }}>
        <Grid container spacing={5}>
          <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
            {status === "Approved" ? (
              <DialogTitle>Please Confirm Design-Hold Approval</DialogTitle>
            ) : (
              <DialogTitle>
                Do you really want to reject Design-Hold Approval
              </DialogTitle>
            )}
            <DialogActions>
              <Button
                onClick={() => setOpenDialog(false)}
                variant="outlined"
                color="secondary"
              >
                CANCEL
              </Button>
              <Button
                onClick={() => updateDesignHoldApprovalStatus()}
                variant="outlined"
                color="primary"
              >
                OK
              </Button>
            </DialogActions>
          </Dialog>

          <AccordionDetails style={{ padding: "8px 0px 16px" }}>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="customized table">
                <TableBody>
                  <TableRow key="1">
                    <TableCell>Design-Hold Reason:</TableCell>
                    <TableCell>
                    <FormControl fullWidth style={{ paddingTop: 20 }}  size="small">
                                <TextField
                                        select
                                        name="design-hold-select-reason"
                                        value={ designHoldData?.designHoldStatusApproval?.customerApproval ? designHoldData.designHoldSelectedTypes : designHoldSelectedType}
                                        required = "true"
                                        onChange={(e)=>setDesignHoldSelectedType(e.target.value)}
                                        label="Design-Hold Reason"
                                         variant="outlined"
                                         size="small"
                                         disabled={
                                            designHoldData?.designHoldStatusApproval &&
                                            (designHoldData?.designHoldStatusApproval
                                              ?.customerApproval === "Approved" ||
                                              designHoldData?.designHoldStatusApproval
                                                ?.customerApproval === "Rejected") || designHoldData?.designHoldStatusApproval?.customerApproval
                                          }
                                    >
                                        {availableDesignHoldOptions && availableDesignHoldOptions.map((option, index) => (
                                        <MenuItem key={index} value={option.name}>
                                            {option.name}
                                        </MenuItem>
                                        ))}
                                    </TextField>
                             </FormControl> 
                    </TableCell>
                  </TableRow>
                  <TableRow key="2">
                    <TableCell>
                      Probable Date for Restarting the Designing
                    </TableCell>
                    <TableCell>
                      <FormControl style={{ width: "100%" }}>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          name="design-hold-restarting-date"
                          size="small"
                          disabled={
                            designHoldData?.designHoldStatusApproval &&
                            (designHoldData?.designHoldStatusApproval
                              ?.customerApproval === "Approved" ||
                              designHoldData?.designHoldStatusApproval
                                ?.customerApproval === "Rejected")
                          }
                          value={designHoldData?.designHoldStatusApproval?.customerApproval ? restartDate : oldProjectRestartDate}
                          type="date"
                          onChange={(e) =>{ setRestartDate(e.target.value);setOldProjectRestartDate(e.target.value)}}
                          inputProps={{
                            min: moment().format('YYYY-MM-DD'), // Set the min attribute to today's date
                        }}
                        />
                      </FormControl>
                    </TableCell>
                  </TableRow>
                  <TableRow key="3">
                    <TableCell>Probable Date of Design Sign-off</TableCell>
                    <TableCell>
                      <FormControl style={{ width: "100%" }}>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          name="design-hold-designSignoff-date"
                          size="small"
                          value={designHoldData?.designHoldStatusApproval?.customerApproval  ? restartDesignSignOffDate: oldProjectDesignSignOffDate}
                          type="date"
                          disabled={
                            designHoldData?.designHoldStatusApproval &&
                            (designHoldData?.designHoldStatusApproval
                              ?.customerApproval === "Approved" ||
                              designHoldData?.designHoldStatusApproval
                                ?.customerApproval === "Rejected")
                          }
                          onChange={(e) =>{
                            setRestartDesignSignOffDate(e.target.value);
                            setOldProjectDesignSignOffDate(e.target.value);
                          } }
                          inputProps={{
                            min: restartDate ? moment(restartDate).add(1, 'month').format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'), // Set the min attribute to proposedDesignStartDate
                        }}
                        />
                      </FormControl>
                    </TableCell>
                  </TableRow>
                  <TableRow key="4">
                    <TableCell>Remarks By Designer</TableCell>
                    <TableCell>
                      <FormControl style={{ width: "100%" }}>
                        <TextField
                          id="outlined-basic"
                          disabled
                          variant="outlined"
                          name="design-hold-remark"
                          size="small"
                          value={ designHoldData?.designHoldStatusApproval?.customerApproval ? designerRemark : 'NA'}
                          onChange={(e)=>setDesignerRemark(e.target.value)}                        />
                      </FormControl>
                    </TableCell>
                  </TableRow>
                  <TableRow key="5">
                    <TableCell>Remarks By Customer</TableCell>
                    <TableCell>
                      <FormControl style={{ width: "100%" }}>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          name="design-hold-customer-remark"
                          size="small"
                          disabled={
                            designHoldData?.designHoldStatusApproval &&
                            (designHoldData?.designHoldStatusApproval
                              ?.customerApproval === "Approved" ||
                              designHoldData?.designHoldStatusApproval
                                ?.customerApproval === "Rejected")
                          }
                          value={customerRemark}
                          label="Customer Remark"
                          type="text"
                          onChange={(e) => setCustomerRemark(e.target.value)}
                        />
                      </FormControl>
                    </TableCell>
                  </TableRow>
                  <TableRow key="6">
                    <TableCell>
                      <div style={{ marginTop: 20 }}>
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={
                            (designHoldData?.designHoldStatusApproval &&
                              (designHoldData?.designHoldStatusApproval
                                ?.customerApproval === "Approved" ||
                                designHoldData?.designHoldStatusApproval
                                  ?.customerApproval === "Rejected")) ||
                            !customerRemark
                          }
                          style={{ marginRight: 10, marginLeft: 30 }}
                          onClick={() => {
                            setOpenDialog(true);
                            setStatus("Approved");
                          }}
                        >
                          Approve
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          disabled={
                            (designHoldData?.designHoldStatusApproval &&
                              (designHoldData?.designHoldStatusApproval
                                ?.customerApproval === "Approved" ||
                                designHoldData?.designHoldStatusApproval
                                  ?.customerApproval === "Rejected")) ||
                            !customerRemark
                          }
                          onClick={() => {
                            setOpenDialog(true);
                            setStatus("Rejected");
                          }}
                        >
                          Reject
                        </Button>
                      </div>
                      <br />
                      <div
                        style={{
                          marginBottom: 15,
                          marginLeft: 30,
                          fontWeight: 600,
                          color: "green",
                        }}
                      >
                        {designHoldData?.designHoldStatusApproval
                          ?.customerApproval === "Approved" && (
                          <span>
                            {" "}
                            Already Approved, Project in Design-Hold Stage{" "}
                            <CheckCircleIcon
                              style={{
                                fontSize: "24px",
                                fontWeight: "bold",
                                color: "green",
                              }}
                            />
                          </span>
                        )}
                        {designHoldData?.designHoldStatusApproval
                          ?.customerApproval === "Rejected" && (
                          <span style={{ color: "red" }}>
                            {" "}
                            Design-Hold Approval Rejected{" "}
                          </span>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Grid>
      </div>
    </div>
  );
};

export default connect(null, { setLoading, setSnackInfo })(
  CustomerDesignHoldApprovalView
);