import React, { useState, useEffect } from 'react'
// import 'antd/dist/antd.css';
import moment from 'moment'
import HttpService from '../../services/httpService';
import { makeStyles, useTheme,withStyles } from '@material-ui/core/styles';
import { connect, useSelector } from "react-redux";
import { setLoading, setSnackInfo } from '../../redux/actions/appAction';
import { getApprovedProjects } from '../../redux/actions/financeAction'
import { getAllMaterials } from '../../redux/actions/vendorAction';
import { getAllScopes, getAllUnits } from '../../redux/actions/projectAction';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import PropTypes from 'prop-types';


const useStyles = makeStyles({
	table: {
		minWidth: 700,
	},
	root: {
		width: '100%',
	}
});

const useStyles1 = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  }));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
  
  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#ccc",
      color: theme.palette.common.black,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

const Approve_Material_Return = (props) => {
    let j = 1
    useEffect(() => {
		getApprovedProjects()
        getMtrPendingReturns()
	}, [])

    const classes = useStyles();
    const [allPendingReturns, setAllPendingReturns] = useState([])
    
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, allPendingReturns.length - page * rowsPerPage)
    

    const getApprovedProjects = async () => {
        props.setLoading(true)
		try {
			await props.getApprovedProjects()
		} catch (error) {
			console.error(error)
			props.setSnackInfo(error.response.data.message, "error")
		}
        props.setLoading(false)
	}

    const getMtrPendingReturns = async () => {
        props.setLoading(true)
		try {
			const response = await HttpService.getMtrPendingReturns()
            setAllPendingReturns(response.data)
		} catch (error) {
            console.error(error)
			props.setSnackInfo(error.response.data.message, "error")
		}
        props.setLoading(false)
	}


    const handleReturnAction = async (e, data) => {
        data.action = e.target.innerHTML
        props.setLoading(true)
        try {
            const response = await HttpService.handleReturnAction(data)
            if(response.data.msg === "approved"){
                props.setSnackInfo('MTR Return approved successfully!', "success")
            } else if(response.data.msg === "rejected"){
                props.setSnackInfo('MTR Return rejected successfully!', "error")
            }
            getMtrPendingReturns()
        } catch (error) {
            console.error(error)
			props.setSnackInfo('some error found', 'error')
        }
        props.setLoading(false)

    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };

    return (
        <>
            <h1 style={{textAlign: "center", marginBottom: 20, color: "white", backgroundColor: "#2C2E3E", padding: 10, borderRadius: 50, width: "100%",}}>Returns Pending for Approval</h1>
            <TableContainer component={Paper}>

        <Table className={classes.table} size="small" aria-label="custom pagination table">

          <TableHead>
            <TableRow>
              <StyledTableCell style={{textAlign: 'center'}}>S.No.</StyledTableCell>
              <StyledTableCell style={{textAlign: 'center'}}>Project Name</StyledTableCell>
              <StyledTableCell style={{textAlign: 'center'}}>Material</StyledTableCell>
              <StyledTableCell style={{textAlign: 'center'}}>Return Qty</StyledTableCell>
              <StyledTableCell style={{textAlign: 'center'}}>Request Date</StyledTableCell>
              <StyledTableCell style={{textAlign: 'center'}}>Location</StyledTableCell>
              <StyledTableCell style={{textAlign: 'center'}}>Action</StyledTableCell>
              
            </TableRow>
          </TableHead>

          <TableBody>
            {(rowsPerPage > 0
              ? allPendingReturns.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : allPendingReturns
            ).map((data, i) => (
              <>  
                <StyledTableRow key={j++}>

                <StyledTableCell style={{textAlign: 'center'}}>{j}</StyledTableCell>
                <StyledTableCell style={{textAlign: 'center'}}>{data?.projectName}</StyledTableCell>
                <StyledTableCell style={{textAlign: 'center'}}>{data?.materialName}</StyledTableCell>
                <StyledTableCell style={{textAlign: 'center'}}>{data?.qty}</StyledTableCell>
                <StyledTableCell style={{textAlign: 'center'}}>{moment(data?.createdAt).format('DD-MM-YYYY')}</StyledTableCell>
                <StyledTableCell style={{textAlign: 'center'}}>{data?.location}</StyledTableCell>
                <StyledTableCell style={{textAlign: 'center'}}><Button variant="contained" style={{ backgroundColor: 'white', color: '#2C2E3E', padding: '1', marginRight: 5 }} onClick={(e) => handleReturnAction(e, data)}>
                    Approve
                  </Button>
                  <Button variant="contained" style={{ backgroundColor: '#2C2E3E', color: 'white', padding: '1' }} onClick={(e) => handleReturnAction(e, data)}>
                    Reject
                  </Button></StyledTableCell>
              </StyledTableRow>
              </>
            ))}

            {emptyRows > 0 && (
              <StyledTableRow style={{ height: 53 * emptyRows }}>
                <StyledTableCell colSpan={8} />
              </StyledTableRow>
            )}
            
          </TableBody>

          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 20, 50, { label: 'All', value: -1 }]}
                colSpan={8}
                count={allPendingReturns.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
          
        </Table>
      </TableContainer>
            
        </>
    )

}

export default connect(null, { setLoading, getApprovedProjects, setSnackInfo, getAllScopes,getAllMaterials, getAllUnits })(Approve_Material_Return);