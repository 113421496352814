import React, { useState, useEffect, useRef } from 'react';
import { connect, useSelector } from 'react-redux'
import { setLoading, setSnackInfo } from '../redux/actions/appAction'
import HttpService from '../services/httpService'
import TableBody from '@material-ui/core/TableBody'
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TextField from '@material-ui/core/TextField';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button'
import Autocomplete from '@material-ui/lab/Autocomplete';
const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1
    },
    gridWrapper: {
      border: "1px solid grey",
      display: "grid",
      backgroundColor: "grey",
      gridRowGap: 1,
      gridColumnGap: 1,
      gridTemplateAreas: `
      "title title title"
      "a1 a2 a3"
      "b1 b2 b3"
      "c1 c2 c3"
      `,
      gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
      "& > *": {
        backgroundColor: "white"
      }
    },
    paper: {
      padding: theme.spacing(2),
      margin: "auto",
      maxWidth:200
    },
    bottomRight: {
      justifyContent: "flex-end"
    },
    tablecontainer: {
      display:"flex"

    },
    tableform1:{
display:"flex"
    },
    outerColumn: {
      borderRight: "1px solid grey",
      borderBottom: "1px solid grey",
      borderLeft: "1px solid grey"
    },
    centerColumn: {
      borderBottom: "1px solid grey"
    }
  }));
const ContractLead = (props) => {
    const { userDetails } = useSelector(state => state.user)
    const classes = useStyles();
    const [expcenters, setExpcenters] = useState([]);
    const [designdata,setdesigndata]=useState({})
    const [selectedExpCenter,setSelectedExpCenter]=useState([])
    const [designNotAssigned,setNotAssignedData]=useState([])
    const [designed,setDesign]=useState([])
    const [lost,setLost]=useState([])
    const [designHold,setDesignHold]=useState([]);
    const [designCompleted,setDesignCompleted]=useState([])
    const [catergoriez,setCategories]=useState([])
    const [Mnth,setMonth]=useState([])

    useEffect(() => {
        expcenter()
        contractLead()
    }, [])
    
      const expcenter = async ()=>{
        try {
            const response = await HttpService.getExpericenCenters() 
            setExpcenters(response.data)
        } catch (error) {
            console.log(error)
          }
      }
      const handleDesignData =(v)=>{
        contractLeadSearch(v)
         setSelectedExpCenter(v)
      }
    const contractLead = async () => {
      props.setLoading(true)
        try {  
          let exp_ctr_ids=[]
          exp_ctr_ids.push([userDetails.selectedExpCenter])
          let  result= await HttpService.getContractLeads({exp_ctr_ids: exp_ctr_ids})
            let categories = []
            for(let i = 0; i < Object.keys(result.data).length; i++)
            {   
            categories.push(Object.keys(result.data)[i])
            }
            setCategories(categories)
            let months = []
            months = [...Object.keys(result.data[categories[0]]["value"])]
    
            let contract_leads_array = months.map(el=>{
            return {month: el,value: result.data["contract_leads"].value[el], count: result.data["contract_leads"].count[el]}
                })
            contract_leads_array.push({ 
            month: 'Grand Total', value: result.data["contract_leads"].grand_total_value, count: result.data["contract_leads"].grand_total_count})
        
            setNotAssignedData([...contract_leads_array])
            setdesigndata(result.data)
        } catch (error) {
            console.log(error, "error")
            props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }
    const contractLeadSearch = async (v) => {
        props.setLoading(true)     
          try {
              let  result = null
            let exp_ctr_ids=[]
            if(v.length===0){
              exp_ctr_ids.push([userDetails.selectedExpCenter])
              result= await HttpService.getContractLeads({exp_ctr_ids: exp_ctr_ids})
            }
            else
            {
              for(let i=0;i<v.length;i++)
              {
                  exp_ctr_ids.push(v[i]["_id"])
              }
              result= await HttpService.getContractLeads({exp_ctr_ids: exp_ctr_ids})
            }
             
  
              let categories = []
              for(let i = 0; i < Object.keys(result.data).length; i++)
              {   
              categories.push(Object.keys(result.data)[i])
              }
              setCategories(categories)
              let months = []
              months = [...Object.keys(result.data[categories[0]]["value"])]      
              let contract_leads_array = months.map(el=>{
              return {month: el,value: result.data["contract_leads"].value[el], count: result.data["contract_leads"].count[el]}
                  })
              contract_leads_array.push({ 
              month: 'Grand Total', value: result.data["contract_leads"].grand_total_value, count: result.data["contract_leads"].grand_total_count})
              setNotAssignedData([...contract_leads_array])
              console.log('notAssignData',designNotAssigned)
  
              setdesigndata(result.data)
              console.log(designdata,"data is caterijdfsdkfsdk")
          } catch (error) {
              console.log(error, "error")
              props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
          }
          props.setLoading(false)
      }
    return (
        <div> 
            <div>
               <Autocomplete
                 multiple
                value={selectedExpCenter}
                onChange={(e, v) => handleDesignData(v)}
                options={expcenters}
                getOptionLabel={(option) => option.name}
                style={{ width: 500, marginBottom: 10 }}
                renderInput={(params) => <TextField {...params} label="Select Exp-Center" fullWidth variant="outlined" />}
              />
           
            </div>
            
            <TableContainer component={Paper}>
                                    <Table  style={{ width: 600 }} className={classes.table} stickyHeader aria-label="sticky table">
                                        <TableHead>
                                        
                                            <TableRow>
                                            <TableCell>Contract Month </TableCell>
                                            <TableCell>Sum Of Budget</TableCell>
                                             <TableCell>Counts of Sales Month</TableCell>
                                            </TableRow>
                                            {designNotAssigned.map((el, i) => {
                                                          return (
                                                            <>
                                                            {(i === designNotAssigned.length - 1) ?
                                                              (<TableRow> 
                                                              <TableCell style={{fontWeight:"700"}}>{el.month}</TableCell>
                                                              <TableCell  style={{fontWeight:"700"}}>{(el.value).toFixed(2)}</TableCell>
                                                              <TableCell style={{fontWeight:"700"}}>{el.count}</TableCell>
                                                            </TableRow>):(<TableRow> 
                                                              <TableCell>{el.month}</TableCell>
                                                              <TableCell>{(el.value).toFixed(2)}</TableCell>
                                                              <TableCell>{el.count}</TableCell>
                                                            </TableRow>)}
                                                        </>
                                                          )})}
                                            </TableHead>

                                            </Table>
                                            </TableContainer><br/>
                                            </div>
                                        
    )
}
export default connect(null, { setLoading, setSnackInfo })(ContractLead)