import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { setLoading, setSnackInfo } from '../../redux/actions/appAction'
import HttpService from '../../services/httpService'
import { Table, Button, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import { DeleteOutlined } from '@ant-design/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  }
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#ccc",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow)

{/* <Autocomplete
value={materialSelected}
onChange={(e, v) => setMaterialSelected(v)}
options={allMaterials}
getOptionLabel={(option) => option.name}
style={{ width: 350, marginBottom: 10 }}
renderInput={(params) => <TextField {...params} label="Select Material" fullWidth variant="outlined" />}
/> */}

const ExperienceCenter = (props) => {
  const classes = useStyles();

  const [experienceCenterList, setExperienceCenterList] = useState([])
  const [experienceCenterModal, setExperienceCenterModal] = useState(false)
  const [experienceCenterData, setExperienceCenterData] = useState({
    _id: "",
    name: "",
    locationId: {}
  })
  const [isEdit, setIsEdit] = useState(false)
  useEffect(() => {
    getExperienceCenter()
    // eslint-disable-next-line
  }, [])

  const getExperienceCenter = async () => {
    props.setLoading(true)
    try {
      const response = await HttpService.getExperinceCenter()
      setExperienceCenterList(response.data)
    } catch (error) {
      setExperienceCenterList([])
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message, "error")
    }
    props.setLoading(false)
  }

  const openExperienceCenterModal = () => {
    props.setLoading(true)
    try {
      setExperienceCenterModal(true)
      getLocation()
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message, "error")
    }
    props.setLoading(false)
  }

  const [locationList, setLocationList] = useState([])

  const getLocation = async () => {
    props.setLoading(true)
    try {
      const response = await HttpService.getLocation()
      setLocationList(response.data)
    } catch (error) {
      console.error(error)
      setLocationList([])
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  const submit = async () => {
    props.setLoading(true)
    try {
      await HttpService.addExperinceCenter({ name: experienceCenterData.name, locationId: experienceCenterData.locationId._id })
      setExperienceCenterModal(false)
      props.setSnackInfo("Experience Center Added", "success")
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    getExperienceCenter()
    props.setLoading(false)
  }

  const update = async () => {
    props.setLoading(true)
    try {
      await HttpService.updateExperinceCenter(experienceCenterData._id, { name: experienceCenterData.name, locationId: experienceCenterData.locationId._id })
      setExperienceCenterModal(false)
      props.setSnackInfo("Experience Center Updated", "success")
      setIsEdit(false)
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    getExperienceCenter()
    props.setLoading(false)
  }

  const editExperienceCenter = (data) => {
    setExperienceCenterData({ name: data.name, _id: data._id, locationId: data?.locationId })
    setExperienceCenterModal(true)
    setIsEdit(true)
  }

  const deleteExpCenter = async (id) => {
    props.setLoading(false)
    try {
      await HttpService.deleteExperinceCenter(id)
      props.setSnackInfo('Experience Center Deleted', "success")
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props.setLoading(false)
    getExperienceCenter()
  }

  return (
    <div>

      <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: 10 }}>
        <Button variant="contained" color="primary" onClick={() => openExperienceCenterModal()}>
          Add Experience Center
        </Button>
      </div>

      {experienceCenterList.length > 0 && <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Sl No</StyledTableCell>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Location</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {experienceCenterList?.map((data, i) => (
              <StyledTableRow key={i + 1}>
                <StyledTableCell>{i + 1}</StyledTableCell>
                <StyledTableCell>{data?.name}</StyledTableCell>
                <StyledTableCell>{data?.locationId?.name}</StyledTableCell>
                <StyledTableCell>
                  <StyledTableRow>
                    <StyledTableCell>
                      <Tooltip title="Edit Experience Center">
                        <EditIcon onClick={() => editExperienceCenter(data)} />
                      </Tooltip></StyledTableCell>
                    <StyledTableCell>
                      <Tooltip title="Delete Experience Center">
                        <DeleteOutlined onClick={() => deleteExpCenter(data?._id)} />
                      </Tooltip></StyledTableCell>
                  </StyledTableRow >
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>}

      <Dialog
        open={experienceCenterModal}
        TransitionComponent={Transition}
        keepMounted
        fullWidth={true}
        maxWidth={'sm'}
        onClose={() => { setExperienceCenterModal(false); setIsEdit(false) }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Add Experience Center"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description" style={{ display: "flex", flexDirection: "column" }}>
            <TextField label="Location Name" style={{ marginBottom: 10 }} name="name" onChange={(e) => setExperienceCenterData({ ...experienceCenterData, name: e.target.value })} value={experienceCenterData.name} variant="outlined" />
            <Autocomplete
              value={experienceCenterData.locationId}
              onChange={(e, v) => setExperienceCenterData({ ...experienceCenterData, locationId: v })}
              options={locationList}
              getOptionLabel={(option) => option.name}
              style={{ width: 350, marginBottom: 10 }}
              renderInput={(params) => <TextField {...params} label="Select Location" fullWidth variant="outlined" />}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {isEdit ? <Button onClick={update} color="primary">
            UPDATE
          </Button> : <Button onClick={submit} color="primary">
            ADD
          </Button>}
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default connect(null, { setLoading, setSnackInfo })(ExperienceCenter)
