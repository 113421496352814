import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { setLoading, setSnackInfo } from '../redux/actions/appAction';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { Typography, FormControl, RadioGroup, FormControlLabel, Radio, Dialog, DialogTitle, DialogContent, TextField, DialogActions } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import HttpService from '../services/httpService';

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 700,
        textAlign: 'center'
    },
    root: {
        width: '100%',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
}));

const FinalPaymentApproval = (props) => {
    const classes = useStyles();
    const [finalPayments, setFinalPayments] = useState([]);
    const [radioValue, setRadioValue] = useState("finalPayment")
    const [openPaymentReceiptModal, setOpenPaymentReceiptModal] = useState(false)

    useEffect(() => {
        getAllFinalPaymentApproveRequest('finalPayment')
        // eslint-disable-next-line
    }, [])

    const handleRadioChange = (e) => {
        setRadioValue(e.target.value)
        getAllFinalPaymentApproveRequest(e.target.value);
    }

    const getAllFinalPaymentApproveRequest = async (type) => {
        props.setLoading(true)
        try {
            const response = await HttpService.getAllFinalPaymentApproveRequest(type)
            setFinalPayments(response.data)
        } catch (error) {
            console.log(error, 'error')
            setFinalPayments([]);
        }
        props.setLoading(false)
    }
    const [selectedRow, setSelectedRow] = useState([])
    const [financeReceiptdata, setFinanceReceiptData] = useState({
        remark: '',
        accountName: '',
        accountThrough: '',
        onAccountOf: '',
    })

    const paymentApproveButton = async (data) => {
        setOpenPaymentReceiptModal(true);
        setSelectedRow(data)
    }

    const updateAndSubmitData = async () => {
        let obj = {
            leadId: selectedRow.leadId._id,
            lead_no: selectedRow.leadId.lead_no,
            status: 'Approve',
            customerName: selectedRow.leadId.customerId.name,
            customerEmail: selectedRow.leadId.customerId.email,
            paymentAmount: selectedRow.amount,
            financeReceipt: {
                accountName: financeReceiptdata.accountName,
                accountThrough: financeReceiptdata.accountThrough,
                onAccountOf: financeReceiptdata.onAccountOf,
                paymentDone: selectedRow.amount
            }
        }
        if (radioValue === 'designerUpdatedPayment') {
            props.setLoading(true)
            try {
                const response = await HttpService.updateDesignerPaymentStatus(selectedRow._id, obj)
                props.setSnackInfo(response.data && "Payment approved successfully", "success")
                getAllFinalPaymentApproveRequest(radioValue)
                setOpenPaymentReceiptModal(false);
            } catch (error) {
                console.log(error, 'error')
                setFinalPayments([]);
            }
            props.setLoading(false)
        } else {
            obj.eprProjectCode = selectedRow.leadId.erpProjectId.code;
            props.setLoading(true)
            try {
                const response = await HttpService.updateFinalPaymentStatus(selectedRow._id, obj)
                props.setSnackInfo(response.data && "Payment approved successfully", "success")
                getAllFinalPaymentApproveRequest(radioValue)
                setOpenPaymentReceiptModal(false);
            } catch (error) {
                console.log(error, 'error')
                setFinalPayments([]);
            }
            props.setLoading(false)
        }
    }

    return (
        <div>
            <Paper style={{ textAlign: 'center', marginBottom: 20 }}>
                <Typography variant="h6" gutterBottom style={{ paddingTop: 10, fontSize: 27, fontWeight: 700 }}>
                    Payment Approval
                </Typography>
                <FormControl component="fieldset" className={classes.root}>
                    <RadioGroup aria-label="type" name="type" value={radioValue} onChange={handleRadioChange}>
                        <div className={classes.radioButton}>
                            <FormControlLabel value="finalPayment" control={<Radio />} label="Final Payment Approval" />
                            <FormControlLabel value="designerUpdatedPayment" control={<Radio />} label="Payment Approval" />
                        </div>
                    </RadioGroup>
                </FormControl>
            </Paper>
            <Divider />

            <div style={{ display: 'flex', marginTop: 10, marginBottom: 20 }}>
                <Paper>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} size="small" stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Sr. No.</TableCell>
                                    <TableCell>Lead Name</TableCell>
                                    <TableCell>Project Code</TableCell>
                                    <TableCell>Amount</TableCell>
                                    <TableCell>Payment Attachment</TableCell>
                                    <TableCell>Updated By Name</TableCell>
                                    <TableCell>Note</TableCell>
                                    <TableCell colSpan={2}>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {finalPayments.length !== 0 ?
                                    finalPayments.map((row, i) => {
                                        return (
                                            <TableRow key={i + 1}>
                                                <TableCell>{i + 1}</TableCell>
                                                <TableCell component="th" scope="row">{row?.leadId?.customerId?.name} - ({row?.leadId?.lead_no})</TableCell>
                                                <TableCell component="th" scope="row">{row?.leadId?.erpProjectNo ? row?.leadId?.erpProjectId?.code : 'NA'}</TableCell>
                                                <TableCell component="th">{row?.amount}</TableCell>
                                                <TableCell component="th" scope="row">
                                                    <Button color="primary" variant="outlined" onClick={() => window.open(row?.paymentImageUrl)} style={{ marginLeft: 10 }}>
                                                        View Payment
                                                    </Button>
                                                </TableCell>
                                                <TableCell component="th">{row?.uploadedBy?.name}</TableCell>
                                                <TableCell component="th">{row?.note}</TableCell>
                                                <TableCell>
                                                    <Button variant="contained" color="primary" size="small" onClick={() => paymentApproveButton(row)}>Approve</Button>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                    :
                                    <TableRow>
                                        <TableCell colSpan={9} style={{ textAlign: 'center' }}>No Records Found</TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </div>

            <Dialog open={openPaymentReceiptModal} onClose={() => setOpenPaymentReceiptModal(false)} aria-labelledby="form-dialog-title" maxWidth='sm'>
                <DialogTitle id="form-dialog-title">
                    Enter remark
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <div style={{ paddingBottom: 15 }}>
                        <FormControl fullWidth style={{ width: '75%', marginLeft: 10, marginRight: 10, marginTop: 20, marginBottom: 10 }}>
                            <TextField
                                id="outlined-multiline-static"
                                label="Enter Narration"
                                onChange={(e) => setFinanceReceiptData({...financeReceiptdata , [e.target.name]: e.target.value})}
                                name="remark"
                                multiline
                                    maxRows={4}
                                variant="outlined"
                                size="small"
                                className={classes.textFieldWidth}
                            />
                        </FormControl>
                        <FormControl style={{ marginLeft: 10, marginRight: 10, marginTop: 20, marginBottom: 10 }}>
                            <TextField id="outlined-basic" label="Amount Paid" value={selectedRow.amount} size="small" variant="outlined" name="paymentDone" disabled />
                        </FormControl>
                        <FormControl style={{ marginLeft: 10, marginRight: 10, marginTop: 20, marginBottom: 10 }}>
                            <TextField id="outlined-basic" label="Account Name" size="small" variant="outlined" name="accountName" onChange={(e) => setFinanceReceiptData({...financeReceiptdata , [e.target.name]: e.target.value})} />
                        </FormControl>
                        <FormControl style={{ marginLeft: 10, marginRight: 10, marginTop: 20, marginBottom: 10 }}>
                            <TextField id="outlined-basic" label="Account Through" size="small" variant="outlined" name="accountThrough" onChange={(e) => setFinanceReceiptData({...financeReceiptdata , [e.target.name]: e.target.value})} />
                        </FormControl>
                        <FormControl style={{ marginLeft: 10, marginRight: 10, marginTop: 20, marginBottom: 10 }}>
                            <TextField id="outlined-basic" label="On Account Of" size="small" variant="outlined" name="onAccountOf" onChange={(e) => setFinanceReceiptData({...financeReceiptdata , [e.target.name]: e.target.value})} />
                        </FormControl>
                    </div>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={() => setOpenPaymentReceiptModal(false)} color="secondary" variant='outlined'>
                        Cancel
                    </Button>
                    <Button onClick={() => updateAndSubmitData()} color="primary" variant='contained'>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default connect(null, { setLoading, setSnackInfo })(FinalPaymentApproval)
