import React, { useState, useEffect } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import HttpService from '../services/httpService';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { connect } from "react-redux";
import { setLoading, setSnackInfo } from '../redux/actions/appAction';
import PoSoMtrNewMaterialAddition from './PoSoMtrNewMaterialAddition';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	table: {
		minWidth: 850,
	},
	formControl: {
		margin: theme.spacing(3),
	},
	root: {
		display: 'flex',
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: 200,
	},
}));

const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: "#ccc",
		color: theme.palette.common.black,
	},
	body: {
		fontSize: 14,
	},
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
	root: {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover,
		},
	},
}))(TableRow);

const RaisePoModal = (props) => {

	const classes = useStyles();

	const [materialChosen, setMaterialChosen] = useState([])
	const [materialChosenDetails, setMaterialChosenDetails] = useState([])
	const [bomMaterials, setBomMaterials] = useState([])
	const [units, setUnits] = useState([])
	const [gstRequired, setGstRequired] = useState(false)
	const [extraCharge, setExtraCharge] = useState('')
	const [selectedUom, setSelectedUom] = useState([])
	const [selectedVendor, setSelectedVendor] = useState([])
	const [newQuantity, setNewQuantity] = useState([])

	const gstPercent = [
		"5", "12", "18", "28"
	]

	const [gstDetails, setGstDetails] = useState({
		gst: "",
		rate: ""
	})
	const handleGstType = (e) => {
		setGstDetails({ ...gstDetails, gst: e.target.value })
	}

	useEffect(() => {
		getUnits()
		// eslint-disable-next-line 
	}, [])

	useEffect(() => {
		clear()
		getBomMaterials()
		// eslint-disable-next-line 
	}, [props.SCOPE, props.MOUNTED])

	const getBomMaterials = async () => {
		props.setLoading(true)
		try {
			const result = await HttpService.getBomOfProjectScope(props.SELECTEDPROJECT._id, props.SCOPE, props.TYPE)
			setBomMaterials(result.data)
		} catch (error) {
			console.error(error)
			props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
		}
		props.setLoading(false)
	}

	const getUnits = async () => {
		try {
			const result = await HttpService.getAllUnits()
			setUnits(result.data)
		} catch (error) {
			console.error(error)
		}
	}

	const handleChange = (e) => {
		if (e.target.checked) {
			setMaterialChosen([...materialChosen, e.target.name])
			setMaterialChosenDetails([...materialChosenDetails, bomMaterials.filter(bom => {
				if (bom?.materialId?.name === e.target.name) {
					setNewQuantity([...newQuantity, Number(((bom?.quantity - bom?.raisedQty)).toFixed(2))])
					setSelectedUom([...selectedUom, bom?.materialId?.unitId ?? bom?.unitType])
					return bom
				}
				return false
			})[0]])
		} else {
			setMaterialChosen(materialChosen.filter(material => material !== e.target.name))
			setMaterialChosenDetails(materialChosenDetails.filter((bom, i) => {
				if (bom?.materialId?.name !== e.target.name) {
					return bom
				} else {
					setSelectedVendor(selectedVendor.filter((q, idx) => i !== idx))
					setNewPrice(newPrice.filter((q, idx) => i !== idx))
					setSelectedUom(selectedUom.filter((q, idx) => i !== idx))
					setNewQuantity(newQuantity.filter((q, idx) => i !== idx))
				}
				return false
			}
			))
		}
	}

	const handleUomSelect = (e, v, i) => {
		let uom = [...selectedUom]
		uom[i] = v
		setSelectedUom(uom)
	}

	const getSelectedUom = (i) => {
		const item = selectedUom[i]
		return item || {}
	}
	const [newPrice, setNewPrice] = useState([])

	const handleVendorSelect = (e, v, i) =>
	{
		if(props.TYPE === 'PO')
		{
			setSelectedVendor([])
			let missing_docs = ''
			if(!v.vendorId.aadharCardLink || (v.vendorId.aadharCardLink.length < 1))
			{
				missing_docs += 'Aadhar Card,'
				
			}
			if(!v.vendorId.panCardLink || (v.vendorId.panCardLink.length < 1))
			{
				missing_docs += 'PAN Card,'
				
			}
			if(missing_docs.length > 0)
			{
				props.setSnackInfo(`${v.vendorId.name} did not provided ${missing_docs} can't raise PO/SO for them.`, 'error')
				return
			}
		}

		
		if (props.TYPE === "MTR") {
			let quantity = newQuantity[i]
			if (quantity > (materialChosenDetails[i].materialId?.stocks[v?.name]?.available - materialChosenDetails[i]?.mtr[v?.name])) {
				return props.setSnackInfo('Required quantity is more than the available', "error")
			}
		}
		let vendor = [...selectedVendor]
		vendor[i] = v
		setSelectedVendor(vendor)
		let price = [...newPrice]
		price[i] = v?.pricePerUnit
		setNewPrice(price)
	}

	const getSelectedVendor = (i) => {
		const item = selectedVendor[i]
		return item || {}
	}


	const handleQuantity = (e, i, data) => {
		
		let quant = [...newQuantity]
		quant[i] = e.target.value
		setNewQuantity(quant)
	}

	const handlePrice = (e, i) => {
		let price = [...newPrice]
		price[i] = e.target.value
		setNewPrice(price)
	}

	const [poDetails, setPoDetails] = useState({
		deliveryDate: "",
		discount: 0,
		freight: 0,
		transport: 0,
		comment: ""
	})

	const createPo = async () => {
		props.setLoading(true)
		try {
			if (props.TYPE === "PO") {
				const obj = materialChosenDetails.map((material, i) => {
					return {
						vendor: selectedVendor[i],
						materialId: material?.materialId,
						projectMaterialId: material?._id,
						newQuantity: newQuantity[i],
						quantity: material.quantity,
						raisedQty: material.raisedQty,
						unitId: selectedUom[i],
						newPrice: newPrice[i],
						scopeId: material.scopeId._id,
						deliveryDate: poDetails.deliveryDate,
					}
				})
				const data = {}
				data.itemList = []
				data.itemList = obj
				data.projectId = props.SELECTEDPROJECT._id
				data.gstRequired = gstRequired
				data.gstDetails = gstDetails
				data.discount = poDetails.discount
				data.transportationCharge = poDetails.transport
				data.freight = poDetails.freight
				data.comment = poDetails.comment
				const result = await HttpService.createProcurementForProject(data)
				if (result.status === 200) {
					clear()
					props.CLOSEMODAL()
					props.setSnackInfo("Procurement Request Raised", "success")

				}
			} else {
				const hasValue = materialChosenDetails.some((val, i) => {
					return selectedVendor[i] === undefined || selectedUom[i] === undefined;
				});
				if (hasValue) {
					props.setLoading(false)
					return props.setSnackInfo("Please Fill All The Fields", "error")
				}
				const obj = materialChosenDetails.map((material, i) => {
					return {
						transferFrom: selectedVendor[i].name,
						materialId: material?.materialId,
						projectMaterialId: material?._id,
						requestedQuantity: newQuantity[i],
						quantity: material.quantity,
						raisedQty: material.raisedQty,
						unitId: selectedUom[i],
					}
				})
				const data = {}
				data.transferType = "Project"
				data.transferTo = "site"
				data.projectId = props.SELECTEDPROJECT._id
				data.deliveryDate = poDetails.deliveryDate
				data.scopeId = props.SCOPE
				data.materials = obj
				const result = await HttpService.raiseMaterialTransferRequest(data)
				if (result.status === 201) {
					clear()
					props.CLOSEMODAL()
					props.setSnackInfo("Material Transfer Request Raised", "success")
				}
			}
		} catch (error) {
			console.error(error)
			props.setSnackInfo(error?.response?.data?.message ?? "Something Went Wrong", "error")
		}
		props.setLoading(false)
	}

	const [showNewMaterialModal,setShowNewMaterialModal] = useState(false);
	const addMoreMaterialHandler = (data)=>{
		let newArr  = [...materialChosenDetails,...data];
		setMaterialChosenDetails(newArr);
	}
	
	const clear = () => {
		setGstRequired(false)
		setGstDetails({
			gst: "",
			rate: ""
		})
		setExtraCharge("")
		setMaterialChosen([])
		setMaterialChosenDetails([])
		setBomMaterials([])
		setSelectedUom([])
		setSelectedVendor([])
		setNewQuantity([])
		setNewPrice([])
		setPoDetails({
			deliveryDate: "",
			discount: 0,
			freight: 0,
			transport: 0
		})
	}


	return (
		<div>
			{bomMaterials.length > 0 ? <div>
				{props.TYPE === "PO" ? <div className="procurement-header">Create Procurement Order</div> : <div className="procurement-header">Material Transfer</div>}
				<div>
					<TableContainer component={Paper}>
						<Table className={classes.table} size="small" aria-label="customized table">
							<TableHead>
								<TableRow>
									<StyledTableCell>Sl No</StyledTableCell>
									<StyledTableCell>Material Name</StyledTableCell>
									<StyledTableCell>Uom</StyledTableCell>
									<StyledTableCell>Quantity</StyledTableCell>
									<StyledTableCell>Raised Qty</StyledTableCell>
									<StyledTableCell>Received Qty</StyledTableCell>
									<StyledTableCell>Action</StyledTableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{bomMaterials.map((data, i) => <StyledTableRow StyledTableRow key={i + 1}>
									<StyledTableCell>{i + 1}</StyledTableCell>
									<StyledTableCell>{data?.materialId?.name}</StyledTableCell>
									<StyledTableCell>{data?.materialId?.unitId?.name}</StyledTableCell>
									<StyledTableCell>{data?.quantity}</StyledTableCell>
									<StyledTableCell>{data?.raisedQty}</StyledTableCell>
									<StyledTableCell>{data?.receivedQty}</StyledTableCell>
									<StyledTableCell><FormControl component="fieldset" className={classes.formControl}>
										<FormGroup>
											<FormControlLabel
												control={<Checkbox checked={materialChosen.includes(data?.materialId?.name)} onChange={handleChange} name={data?.materialId?.name} />}
											/>
										</FormGroup>
									</FormControl></StyledTableCell>
								</StyledTableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</div>
				<div className='modal-header' style={{ justifyContent: "space-between", paddingTop: 10 }}>
					<div style={{ width: "40%" }}>
						<b>Project: </b>
						<span>{`${props.PROJECTINFO.projectCode}_${props.PROJECTINFO.clientName}`}</span>
					</div>
					<div style={{ width: "40%" }}>
						<TextField
							id="date"
							name="deliveryDate"
							onChange={(e) => setPoDetails({ ...poDetails, [e.target.name]: e.target.value })}
							label="Expected Delivery Date"
							type="date"
							className={classes.textField}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					</div>
				</div>
				{!showNewMaterialModal &&  <Button variant="contained" color="primary" style={{ background: "#716ACA", margin: 5 }} onClick={()=> {setShowNewMaterialModal(true);} }>Add New Material</Button>}
				{showNewMaterialModal && <PoSoMtrNewMaterialAddition MOUNTED={true} SCOPE={props.SCOPE} addMoreMaterialHandler={addMoreMaterialHandler} setShowNewMaterialModal={setShowNewMaterialModal} bomMaterials={bomMaterials} TYPE={props.TYPE}/>}
				{materialChosenDetails.length > 0 && <div>
					<TableContainer component={Paper} style={{ height: '350px' }}>
						<Table className={classes.table} size="small" aria-label="customized table">
							<TableHead>
								<TableRow>
									<StyledTableCell>Sl No</StyledTableCell>
									<StyledTableCell>Name/Code</StyledTableCell>
									<StyledTableCell>Quantity</StyledTableCell>
									<StyledTableCell>New Quantity</StyledTableCell>
									<StyledTableCell>Unit</StyledTableCell>
									<StyledTableCell>Pick Vendor</StyledTableCell>
									{props.TYPE === "PO" ? <><StyledTableCell>Price</StyledTableCell>
									<StyledTableCell>New Price</StyledTableCell></> : <><StyledTableCell>Stocks Available(Factory)</StyledTableCell><StyledTableCell>Overall MTR Raised(Factory)</StyledTableCell><StyledTableCell>Stocks Available(Store)</StyledTableCell><StyledTableCell>Overall MTR Raised(Store)</StyledTableCell></>}
								</TableRow>
							</TableHead>
							<TableBody>
								{materialChosenDetails.map((data, i) => <StyledTableRow StyledTableRow key={i + 1}>
									<StyledTableCell>{i + 1}</StyledTableCell>
									<StyledTableCell>{data?.materialId?.name}</StyledTableCell>
									<StyledTableCell>{data?.quantity}</StyledTableCell>
									<StyledTableCell><TextField id="newQty" className={classes.textField} type="number" onChange={(e) => handleQuantity(e, i, data)} name="newQuantity" value={newQuantity[i]} variant="outlined" /></StyledTableCell>
									<StyledTableCell><Autocomplete
										value={getSelectedUom(i)}
										onChange={(e, v) => handleUomSelect(e, v, i)}
										id="Unit"
										options={units}
										getOptionLabel={(option) => option.name}
										style={{ width: 150 }}
										renderInput={(params) => <TextField {...params} fullWidth variant="outlined" />}
									/></StyledTableCell>
									<StyledTableCell>
										{props.TYPE === "PO" ? < Autocomplete
											value={getSelectedVendor(i)}
											onChange={(e, v) => handleVendorSelect(e, v, i)}
											id="Vendor"
											options={data?.vendorDetails}
											getOptionLabel={(option) => option?.vendorId?.name}
											style={{ width: 250 }}
											renderInput={(params) => <TextField {...params} fullWidth variant="outlined" />}
										/> : < Autocomplete
											value={getSelectedVendor(i)}
											onChange={(e, v) => handleVendorSelect(e, v, i)}
											id="Vendor"
											options={[{ name: 'factory' }, { name: 'store' }]}
											getOptionLabel={(option) => option?.name}
											style={{ width: 250 }}
											renderInput={(params) => <TextField {...params} fullWidth variant="outlined" />} />
										}
									</StyledTableCell>
									{props.TYPE === "PO" ? <>
										<StyledTableCell>{selectedVendor[i]?.pricePerUnit}</StyledTableCell>
										<StyledTableCell><TextField id="newPrice" className={classes.textField} value={newPrice[i]} onChange={(e) => handlePrice(e, i)} type="number" variant="outlined" /></StyledTableCell></> : <>
										<StyledTableCell>{data?.materialId?.stocks?.factory?.available}</StyledTableCell>
										<StyledTableCell>{data?.mtr?.factory}</StyledTableCell>
										<StyledTableCell>{data?.materialId?.stocks?.store?.available}</StyledTableCell>
										<StyledTableCell>{data?.mtr?.store}</StyledTableCell></>}

								</StyledTableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
					{props.TYPE === "PO" && <div>
						<TextField id="comment" name="comment" value={poDetails.comment} onChange={(e) => setPoDetails({ ...poDetails, [e.target.name]: e.target.value })} label="Comment" variant="outlined" className='procurement-discount' style={{ marginTop: 20 }} />
						<div className='gst-header'>Add GST and extra charge details</div>
						<div className='gst-checked-options'>
							<TextField id="discount" type="number" name="discount" onChange={(e) => setPoDetails({ ...poDetails, [e.target.name]: e.target.value })} label="Discount" variant="outlined" className='procurement-discount' />
							<span className='procurement-discount' style={{ border: '1.5px solid #ccc', padding: '5.5px 0px', borderRadius: '3.5px' }}><Checkbox
								checked={gstRequired}
								onChange={(e) => setGstRequired(e.target.checked)}
								inputProps={{ 'aria-label': 'primary checkbox' }}
							/>GST Required</span>
						</div>
						{gstRequired && <div className='gst-radio-percent'>
							<FormControl component="fieldset" className={classes.root}
								style={{ border: '1.5px solid #ccc', padding: '5.5px 0px', borderRadius: '3.5px', width: '48%' }}>
								<RadioGroup aria-label="type" name="type" value={gstDetails?.gst} onChange={handleGstType}>
									<div className='gst-type-radio'>
										<FormControlLabel value="SGST" control={<Radio />} label="CGST/SGST" />
										<FormControlLabel value="IGST" control={<Radio />} label="IGST" />
									</div>
								</RadioGroup>
							</FormControl>
							<Autocomplete
								value={gstDetails?.rate}
								onChange={(e, value) => setGstDetails({ ...gstDetails, rate: value })}
								id="percent"
								options={gstPercent}
								getOptionLabel={(option) => `${option}%`}
								style={{ width: '48%' }}
								renderInput={(params) => <TextField {...params} label={`Select ${gstDetails?.gst} percent`} fullWidth variant="outlined" />}
							/></div>}
						<div className='gst-radio-percent'>
							<FormControl component="fieldset" className={classes.root}
								style={{ border: '1.5px solid #ccc', padding: '5.5px 0px', borderRadius: '3.5px', width: '48%' }}>
								<RadioGroup aria-label="type" name="type" value={extraCharge} onChange={(e) => setExtraCharge(e.target.value)}>
									<div className='gst-type-radio'>
										<FormControlLabel value="freight" control={<Radio />} label="Freight" />
										<FormControlLabel value="transport" control={<Radio />} label="Transport" />
									</div>
								</RadioGroup>
							</FormControl>
							<TextField id="extraCharge" onChange={(e) => setPoDetails({ ...poDetails, [`${extraCharge}`]: e.target.value })} label={`${extraCharge} charge`} type="number" variant="outlined"
								style={{ width: '48%' }} />
						</div>
					</div>}
					<button onClick={createPo} className='procurement-create-btn'>Create</button>
				</div>}
			</div > : <div>Please Upload a BOM Material for this Scope</div>

			}
		</div >
	)
}

export default connect(null, { setLoading, setSnackInfo })(RaisePoModal)
