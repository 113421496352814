import React, { useEffect, useState, useRef } from "react";
import HttpService from "../../services/httpService";
import { setLoading, setSnackInfo } from "../../redux/actions/appAction";
import { connect } from "react-redux";
import { FormControl, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from "@material-ui/core/Grid";
import Tooltip from '@material-ui/core/Tooltip';
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    withStyles,
    TableContainer
} from "@material-ui/core";
import { Button } from "antd";
import { Modal } from "antd";
import { CSVLink } from "react-csv";
import moment from "moment";
import { ROLENAME } from '../../utils/index';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    bordercolors: {
        border: '1px solid #eee5e5',
        background: "blue"
    },
    table: {
        minWidth: 700,
    },
    root: {
        width: '100%',
    },
    title: {
        fontSize: 14,
    },
    customTableContainer: {
        overflowX: "initial"
    },
    pos: {
        marginBottom: 12,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginpivotRight: theme.spacing(1),
        width: 200,
    },
    table: {
        width: "fit-content",
    },
    csv: {
        display: "flex",
        alignItems: "center",
        marginBottom: 20,
    },
    report: {
        marginBottom: 20,
        alignItems: "center",
        justifyContent: "center",
    },
    leadSearchButton: {
        backgroundColor: '#b7ccf7',
        color: '#151921',
        padding: '1',
        marginRight: '0.5em',
        borderRadius: '20px',
        width: '20%',
        "&:hover": { backgroundColor: "#14419c", color: 'white' }
    },
    leadClearButton: {
        backgroundColor: '#f7b7b7', // light red background
        color: '#151921',
        padding: '1',
        marginRight: '0.5em',
        borderRadius: '20px',
        width: '20%',
        // "&:hover": { 
        //     backgroundColor: "#f7b7b7", // same light red on hover
        //     color: '#151921' // same text color on hover
        // }
    }
}));

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#3F51B5",
        color: "#FFF",
        fontWeight: "500"
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);


const BookingStats = (props) => {
    const classes = useStyles();
    const { userDetails } = useSelector((state) => state.user);
    const [store, setStore] = useState([]);
    const [commonExpCenter, setExpCenter] = useState([])
    const roleToPivotMap = {
        [ROLENAME.DGM_Sales]: 'value',
        [ROLENAME.SalesManager]: 'value',
        [ROLENAME.AssistantSalesManager]: 'value',
        [ROLENAME.SalesUser]: 'value',
        [ROLENAME.DGM_Design]: 'overallsummary',
        [ROLENAME.OperationDesignManager]: 'overallsummary',
        [ROLENAME.AssistantDesignManager]: 'overallsummary',
        [ROLENAME.DesignUser]: 'overallsummary',
        default: 'value',
    };

    const initialPivot = userDetails.roles.reduce((pivotValue, role) => {
        return roleToPivotMap[role.name] || pivotValue;
    }, roleToPivotMap.default);

    const [pivot, setPivot] = useState(initialPivot);
    const [storeDateFilter, setPivotDatefilters] = useState({ startDate: "", endDate: "" })
    const [selectedExperienceCenter, setSelectedExperienceCenter] = useState([])
    const months = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', "Oct", "Nov", "Dec"];
    const [selectedValue, setSelectedValue] = useState("");
    const [showDetailedModal, setShowDetailedModal] = useState(false);
    const [csvData, setCsvData] = useState([]);
    const csvButtonRef = useRef(null);
    const [popMonthData, setPopMonthData] = useState([]);
    const [selectedRole, setSelectedRole] = useState('');
    const [usersData, setUsersData] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [status, setStaus] = useState('');
    const [selectLost, setSeletLost] = useState('')
    const [selectedDepartment, setSelectedDepartment] = useState("")
    const [searchInput, setSearchInput] = useState("")
    const [selectedSearchType, setSelectedSearchType] = useState("");
    const searchTypes = ['Customer Name', 'Email ID', 'Lead No', 'Project No', 'Contact No', 'Unique Lead No']

    useEffect(() => {
        expCenters();
        if ((userDetails.roles.find(role => role.name === ROLENAME.AssistantSalesManager))) {
            callFunction()
            setSelectedRole(ROLENAME.AssistantSalesManager)
        } else if ((userDetails.roles.find(role => role.name === ROLENAME.SalesUser))) {
            callFunction()
            setSelectedRole(ROLENAME.SalesUser)
        } else if ((userDetails.roles.find(role => role.name === ROLENAME.DGM_Design || role.name === ROLENAME.OperationDesignManager))) {
            overallsummary()
            setSelectedRole("ALL")
        } else if (userDetails.roles.find(role => role.name === ROLENAME.AssistantDesignManager)) {
            overallsummary()
            setSelectedRole(ROLENAME.AssistantDesignManager)
        } else if (userDetails.roles.find(role => role.name === ROLENAME.DesignUser)) {
            overallsummary()
            setSelectedRole(ROLENAME.DesignUser)
        } else {
            setSelectedRole("ALL")
            callFunction();
        }
    }, []);

    const headers = [
        { key: "serialNo", label: "Sl. No." },
        { key: "projectCode", label: "Project No." },
        { key: "leadNumber", label: "Lead No." },
        ...(pivot === "leads" ? [
            { key: "createdAt", label: "Created At" },
            { key: "sourceOfLead", label: "Source Of Lead" },
        ] : []),
        { key: "clientName", label: "Client Name" },
        ...(pivot === "value" ? [
            { key: "salesUser", label: "Sales Executive" },
            { key: "salesAssistantManager", label: "Sales Assistant Manager" },
            { key: "salesManager", label: "Sales Manager" },
            { key: "salesDGM", label: "Sales DGM" },
        ] : []),
        ...(pivot === "overallsummary" ? [
            { key: "designUser", label: "Design User" },
            { key: "designAssistantManager", label: "Design Assistant Manager" },
            { key: "designManager", label: "Design Manager" },
            { key: "designDGM", label: "Design DGM" },
            { key: "designStage", label: "Design Stage" },
            { key: "currentStage", label: "Current Stage" },
        ] : []),
        { key: "totalValue", label: "Value (INR)" },
        { key: "expCtrName", label: "Exp. Ctr Name" },
        { key: "salesWonDate", label: "Sales Won Date" },
        ...((pivot !== 'designHoldAnalysis' && pivot !== 'leads') ? [
            { key: "internalDesignSignOffDate", label: "Internal Design Sign-Off Date" },
            { key: "designSignOffDate", label: "Customer Design Sign-Off Approval Date" },
            { key: "materialDeliveryDate", label: "Material Delivery Date" },
            { key: "actualCompletionDate", label: "Actual Completion Date" },
        ] : []),
        ...(pivot === 'designHoldAnalysis' ? [
            { key: "reasonDesignHold", label: "Reason for Design-Hold" },
            { key: "probableRestartingDate", label: "Probable Date for Restarting the Designing" },
            { key: "probableDesignSignOffDate", label: "Probable Date of Design Sign-off" },
            { key: "adm", label: "Assistant Design Manager" },
            { key: "operationDM", label: "Operation Design Manager" },
        ] : []),
        { key: "currentStage", label: "Current Stage" },
        { key: "department", label: "Department" }
    ];

    const csvDownloadHandler = async (data) => {
        setLoading(true);
        try {
            let csvArray = [];
            if (pivot !== 'mis' && pivot !== 'leads') {
                for (let i = 0; i < popMonthData.length; i++) {
                    let obj = {
                        serialNo: i + 1,
                        projectCode: popMonthData[i]?.leadId?.erpProjectNo || "N/A",
                        leadNumber: popMonthData[i]?.leadId?.lead_no || "N/A",
                        clientName: popMonthData[i]?.leadId?.customerId?.name,
                        totalValue: (
                            pivot === 'salesOverallReview' || pivot === "value" || pivot === "total" || status === 'designerNotAssigned'
                        ) ?
                            (popMonthData[i]?.leadId?.staticSalesWonBudget) :
                            (
                                pivot !== 'salesOverallReview' && pivot !== "value" && pivot !== "total" && status !== 'designerNotAssigned' && status === 'completed'
                            ) ?
                                ((popMonthData[i]?.leadId?.staticDesignWonAmount / 1.18) / 100000).toFixed(2) :
                                (
                                    pivot !== 'salesOverallReview' && pivot !== "value" && pivot !== "total" && status !== 'completed' && status !== 'designerNotAssigned'
                                ) ?
                                    (popMonthData[i]?.leadId?.staticSalesWonBudget) : "-",
                        expCtrName: popMonthData[i]?.salesExpCenter?.name,
                        salesWonDate: popMonthData[i]?.SalesWonDate
                            ? moment(popMonthData[i]?.SalesWonDate).format("DD-MM-YYYY")
                            : "-",
                        salesUser: popMonthData[i]?.salesUser ? popMonthData[i]?.salesUser?.name : 'NA',
                        salesManager: popMonthData[i]?.salesManager ? popMonthData[i]?.salesManager?.name : 'NA',
                        salesDGM: popMonthData[i]?.salesDGM ? popMonthData[i]?.salesDGM?.name : 'NA',
                        salesAssistantManager: popMonthData[i]?.salesAssistantManager ? popMonthData[i]?.salesAssistantManager?.name : 'NA',

                        designUser: popMonthData[i]?.designUser ? popMonthData[i]?.designUser?.name : 'NA',
                        designManager: popMonthData[i]?.designManager ? popMonthData[i]?.designManager?.name : 'NA',
                        designDGM: popMonthData[i]?.designDGM ? popMonthData[i]?.designDGM?.name : 'NA',
                        designAssistantManager: popMonthData[i]?.designAssistantManager ? popMonthData[i]?.designAssistantManager?.name : 'NA',
                        currentStage: popMonthData[i]?.leadId?.currentStage ? popMonthData[i]?.leadId?.currentStage : 'NA',
                        department: popMonthData[i]?.leadId?.departmentId?.name ? popMonthData[i]?.leadId?.departmentId?.name : 'NA',
                        designStage: popMonthData[i]?.stage ? popMonthData[i]?.stage : "NA",


                        internalDesignSignOffDate: popMonthData[i]?.leadId?.internalDesignSignOffDate
                            ? moment(popMonthData[i]?.leadId?.internalDesignSignOffDate).format("DD-MM-YYYY")
                            : "-",
                        designSignOffDate: popMonthData[i]?.leadId?.customerDesignSignOffDate
                            ? moment(popMonthData[i]?.leadId?.customerDesignSignOffDate).format("DD-MM-YYYY")
                            : "-",
                        materialDeliveryDate: popMonthData[i]?.leadId?.materialDispatchedDate
                            ? moment(popMonthData[i]?.leadId?.materialDispatchedDate).format(
                                "DD-MM-YYYY"
                            )
                            : "-",
                        actualCompletionDate: popMonthData[i]?.leadId?.executionCompletionDate
                            ? moment(popMonthData[i]?.leadId?.executionCompletionDate).format(
                                "DD-MM-YYYY"
                            )
                            : "-",
                        HoldRevocationDate: popMonthData[i]?.leadId?.proposedDesignStartDate
                            ? moment(popMonthData[i]?.leadId?.proposedDesignStartDate).format(
                                "DD-MM-YYYY"
                            )
                            : "-",
                        reasonDesignHold: popMonthData[i]?.leadId?.designHoldSelectedTypes ? popMonthData[i]?.leadId?.designHoldSelectedTypes : '-',
                        probableRestartingDate: popMonthData[i].leadId?.proposedDesignStartDate ?
                            moment(popMonthData[i]?.leadId?.proposedDesignStartDate).format('DD-MM-YYY') : "-",
                        probableDesignSignOffDate: popMonthData[i].leadId?.probableDesignSignOffDate ?
                            moment(popMonthData[i].leadId?.probableDesignSignOffDate).format('DD-MM-YYY') : "-",
                        adm: popMonthData[i]?.designAssistantManager ? popMonthData[i]?.designAssistantManager?.name : "-",
                        operationDM: popMonthData[i]?.designManager ? popMonthData[i]?.designManager?.name : "-",
                    };
                    csvArray.push(obj);
                    obj = {
                        serialNo: "",
                        projectCode: "",
                        clientName: "",
                        totalValue: "",
                        expCtrName: "",
                        salesWonDate: "",
                        internalDesignSignOffDate: "",
                        designSignOffDate: "",
                        materialDeliveryDate: "",
                        actualCompletionDate: "",
                        HoldRevocationDate: "",
                    };
                    csvArray.push(obj);
                }
            } else if (pivot === 'mis' || pivot === 'leads') {
                for (let i = 0; i < popMonthData.length; i++) {
                    let obj = {
                        serialNo: i + 1,
                        projectCode: popMonthData[i]?.erpProjectNo || "N/A",
                        leadNumber: popMonthData[i]?.lead_no || "N/A",
                        createdAt: popMonthData[i]?.createdAt
                            ? moment(popMonthData[i]?.createdAt).format("DD-MM-YYYY")
                            : "-",                        
                        sourceOfLead: popMonthData[i]?.sourceOfLead?.name || "N/A",
                        clientName: popMonthData[i]?.customerId?.name,
                        totalValue: (
                            pivot === 'salesOverallReview' || pivot === "value" || pivot === "total" || status === 'designerNotAssigned'
                        ) ?
                            (popMonthData[i].staticSalesWonBudget) :
                            (
                                pivot !== 'salesOverallReview' && pivot !== "value" && pivot !== "total" && status !== 'designerNotAssigned' && status === 'completed'
                            ) ?
                                ((popMonthData[i]?.leadId?.staticDesignWonAmount / 1.18) / 100000).toFixed(2) :
                                (
                                    pivot !== 'salesOverallReview' && pivot !== "value" && pivot !== "total" && status !== 'completed' && status !== 'designerNotAssigned'
                                ) ?
                                    (popMonthData[i].staticSalesWonBudget) : "-",
                        expCtrName: popMonthData[i]?.experienceCenterId?.name,
                        salesWonDate: popMonthData[i]?.leadWonDate
                            ? moment(popMonthData[i]?.leadWonDate).format("DD-MM-YYYY")
                            : "-",
                        // salesUser: popMonthData[i]?.salesUser ? popMonthData[i]?.salesUser?.name : 'NA',
                        // salesManager: popMonthData[i]?.salesManager ? popMonthData[i]?.salesManager?.name : 'NA',
                        // salesDGM: popMonthData[i]?.salesDGM ? popMonthData[i]?.salesDGM?.name : 'NA',
                        // salesAssistantManager: popMonthData[i]?.salesAssistantManager ? popMonthData[i]?.salesAssistantManager?.name : 'NA',

                        // designUser: popMonthData[i]?.designUser ? popMonthData[i]?.designUser?.name : 'NA',
                        // designManager: popMonthData[i]?.designManager ? popMonthData[i]?.designManager?.name : 'NA',
                        // designDGM: popMonthData[i]?.designDGM ? popMonthData[i]?.designDGM?.name : 'NA',
                        // designAssistantManager: popMonthData[i]?.designAssistantManager ? popMonthData[i]?.designAssistantManager?.name : 'NA',
                        currentStage: popMonthData[i].currentStage ? popMonthData[i]?.currentStage : 'NA',
                        department: popMonthData[i]?.departmentId?.name ? popMonthData[i]?.departmentId?.name : 'NA',
                        designStage: popMonthData[i]?.stage ? popMonthData[i]?.stage : "NA",


                        internalDesignSignOffDate: popMonthData[i]?.internalDesignSignOffDate
                            ? moment(popMonthData[i]?.internalDesignSignOffDate).format("DD-MM-YYYY")
                            : "-",
                        designSignOffDate: popMonthData[i]?.customerDesignSignOffDate
                            ? moment(popMonthData[i]?.customerDesignSignOffDate).format("DD-MM-YYYY")
                            : "-",
                        materialDeliveryDate: popMonthData[i]?.materialDispatchedDate
                            ? moment(popMonthData[i]?.materialDispatchedDate).format(
                                "DD-MM-YYYY"
                            )
                            : "-",
                        actualCompletionDate: popMonthData[i]?.executionCompletionDate
                            ? moment(popMonthData[i]?.executionCompletionDate).format(
                                "DD-MM-YYYY"
                            )
                            : "-",
                    };
                    csvArray.push(obj);
                    obj = {
                        serialNo: "",
                        projectCode: "",
                        clientName: "",
                        totalValue: "",
                        expCtrName: "",
                        salesWonDate: "",
                        internalDesignSignOffDate: "",
                        designSignOffDate: "",
                        materialDeliveryDate: "",
                        actualCompletionDate: "",
                    };
                    csvArray.push(obj);
                }
            }
            setCsvData(csvArray);
            downloadExcel();
        } catch (error) {
            console.log("error", error);
            props?.setSnackInfo(
                error?.response?.data?.message ?? "Something went wrong",
                "error"
            );
        }
        setLoading(false);
    };

    const downloadExcel = () => {
        setLoading(true);
        try {
            setTimeout(() => {
                csvButtonRef.current.link.click();
            });
        } catch (err) {
            console.error(err.message);
        }
        setLoading(false);
    };
    const expCenterData = {};
    store.forEach((el) => {
        if (!expCenterData[el.experienceCenterName]) {
            expCenterData[el.experienceCenterName] = [];
        }
        expCenterData[el.experienceCenterName].push(el);
    });
    const uniqueexperienceCenterCode = Array.from(new Set(store.map((el) => el.expCenterCode)));
    let uniqueexperienceCenterNames = Array.from(new Set(store.map((el) => el.experienceCenterName)));


    const expCenters = async () => {
        props.setLoading(true);
        try {
            let response = await HttpService.getExpericenCenters();
            const matchedUsers = response.data.filter(user => {
                return userDetails.experienceCenterId.includes(user._id);
            });;
            //   setExpCenter(response.data)
            setExpCenter(matchedUsers)
        } catch (error) {
            console.error("Error fetching experience centers:", error);
            props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
        }
        props.setLoading(false);
    }

    const leadsCreated = async () => {
        let obj = {
            startDate: storeDateFilter.startDate,
            endDate: storeDateFilter.endDate
        }
        props.setLoading(true);
        try {
            let response = await HttpService.getLeadsCreated(obj);
            setStore(response.data);
        } catch (error) {
            props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
        }

        props.setLoading(false);
    }

    const callFunction = async (addExpCenter, usersData, selectedSearchType, searchInput) => {
        let expCenterData = addExpCenter?.map((el) => el._id)
        let users = ''
        if ((usersData?.users && usersData?.users.length !== 0) || (selectedUsers && selectedUsers.length !== 0)) {
            users = usersData?.users?.map((el) => el._id) || selectedUsers?.map((el) => el._id)
        }
        let obj = {
            startDate: storeDateFilter.startDate,
            endDate: storeDateFilter.endDate,
            selectedExperienceCenter: expCenterData ? expCenterData : "",
            tokenPercentage: selectedValue !== "" ? selectedValue : "",
            users: users ? users : "",
            // selectedRole: usersData?.role ? usersData?.role : ROLENAME.SalesUser,
            userExpCenter: userDetails.experienceCenterId,
            lost: selectLost !== "" ? selectLost : "",
            pivotValue: 'value',
            searchType: selectedSearchType ? selectedSearchType : "",
            search: searchInput ? searchInput.toUpperCase() : ""
        }
        if (userDetails.roles.find(role => role.name === ROLENAME.AssistantSalesManager)) {
            obj.selectedRole = usersData?.role ? usersData?.role : ROLENAME.AssistantSalesManager
        } else if (userDetails.roles.find(role => role.name === ROLENAME.SalesUser)) {
            obj.selectedRole = usersData?.role ? usersData?.role : ROLENAME.SalesUser
        } else {
            obj.selectedRole = usersData?.role ? usersData?.role : (selectedRole ? selectedRole : "ALL")
        }
        props.setLoading(true);
        try {
            let response = await HttpService.getSalesDataBasedOnExpCenter(obj);
            setStore(response.data);
        } catch (error) {
            props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
        }

        props.setLoading(false);
    }

    const designSignOffReview = async (addExpCenter, usersData, selectedSearchType, searchInput) => {
        let expCenterData = addExpCenter?.map((el) => el._id)
        let users = ''
        if ((usersData?.users && usersData?.users.length !== 0) || (selectedUsers && selectedUsers.length !== 0)) {
            users = usersData?.users?.map((el) => el._id) || selectedUsers?.map((el) => el._id)
        }
        let obj = {
            startDate: storeDateFilter.startDate,
            endDate: storeDateFilter.endDate,
            selectedExperienceCenter: expCenterData ? expCenterData : "",
            tokenPercentage: selectedValue !== "" ? selectedValue : "",
            users: users ? users : "",
            // selectedRole: usersData?.role ? usersData?.role : "",
            userExpCenter: userDetails.experienceCenterId,
            lost: selectLost !== "" ? selectLost : "",
            pivotValue: 'designSignOffReview',
            searchType: selectedSearchType ? selectedSearchType : "",
            search: searchInput ? searchInput.toUpperCase() : ""
        }
        if (userDetails.roles.find(role => role.name === ROLENAME.AssistantDesignManager)) {
            obj.selectedRole = usersData?.role ? usersData?.role : ROLENAME.AssistantDesignManager
        } else if (userDetails.roles.find(role => role.name === ROLENAME.DesignUser)) {
            obj.selectedRole = usersData?.role ? usersData?.role : ROLENAME.DesignUser
        } else {
            obj.selectedRole = usersData?.role ? usersData?.role : (selectedRole ? selectedRole : "ALL")
        }
        props.setLoading(true);
        try {
            let response = await HttpService.getSalesDataBasedOnExpCenter(obj);
            setStore(response.data);
        } catch (error) {
            props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
        }

        props.setLoading(false);
    }

    const overallReview = async (addExpCenter, usersData) => {
        let expCenterData = addExpCenter?.map((el) => el._id)
        let users = ''
        if ((usersData?.users && usersData?.users.length !== 0) || (selectedUsers && selectedUsers.length !== 0)) {
            users = usersData?.users?.map((el) => el._id) || selectedUsers?.map((el) => el._id)
        }

        let obj = {
            startDate: storeDateFilter.startDate,
            endDate: storeDateFilter.endDate,
            selectedExperienceCenter: expCenterData ? expCenterData : "",
            userExpCenter: userDetails.selectedExpCenter,
            tokenPercentage: selectedValue !== "" ? selectedValue : "",
            users: users ? users : "",
            selectedRole: usersData?.role ? usersData?.role : selectedRole,
        }
        props.setLoading(true);
        try {
            let response = await HttpService.overallReviewDashBoard(obj);
            setStore(response.data);
        } catch (error) {
            props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
        }
        props.setLoading(false);
    }

    const misReview = async () => {
        let obj = {
            startDate: storeDateFilter.startDate,
            endDate: storeDateFilter.endDate,
            tokenPercentage: selectedValue !== "" ? selectedValue : "",
        }
        props.setLoading(true);
        try {
            let response = await HttpService.overallMISDashBoard(obj);
            setStore(response.data.data);
        } catch (error) {
            props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
        }
        props.setLoading(false);
    }

    const overallsummary = async (addExpCenter, usersData, selectedSearchType, searchInput) => {
        let expCenterData = addExpCenter?.map((el) => el._id)
        let users = ''
        if ((usersData?.users && usersData?.users.length !== 0) || (selectedUsers && selectedUsers.length !== 0)) {
            users = usersData?.users?.map((el) => el._id) || selectedUsers?.map((el) => el._id)
        }
        let obj = {
            startDate: storeDateFilter.startDate,
            endDate: storeDateFilter.endDate,
            selectedExperienceCenter: expCenterData ? expCenterData : "",
            tokenPercentage: selectedValue !== "" ? selectedValue : "",
            users: users ? users : "",
            // selectedRole: usersData?.role ? usersData?.role : ROLENAME.DesignUser,
            userExpCenter: userDetails.experienceCenterId,
            searchType: selectedSearchType ? selectedSearchType : "",
            search: searchInput ? searchInput.toUpperCase() : ""
        }
        if (userDetails.roles.find(role => role.name === ROLENAME.AssistantDesignManager)) {
            obj.selectedRole = usersData?.role ? usersData?.role : ROLENAME.AssistantDesignManager
        } else if (userDetails.roles.find(role => role.name === ROLENAME.DesignUser)) {
            obj.selectedRole = usersData?.role ? usersData?.role : ROLENAME.DesignUser
        } else {
            obj.selectedRole = usersData?.role ? usersData?.role : (selectedRole ? selectedRole : "ALL")
        }
        props.setLoading(true);
        try {
            let response = await HttpService.overallSummaryDashBoard(obj);
            setStore(response.data.data);
        } catch (error) {
            props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
        }
        props.setLoading(false);
    }

    const lostDataAnalysis = async (addExpCenter, usersData, selectedSearchType, searchInput) => {
        let expCenterData = addExpCenter?.map((el) => el._id)
        let users = ''
        if ((usersData?.users && usersData?.users.length !== 0) || (selectedUsers && selectedUsers.length !== 0)) {
            users = usersData?.users?.map((el) => el._id) || selectedUsers?.map((el) => el._id)
        }
        let obj = {
            startDate: storeDateFilter.startDate,
            endDate: storeDateFilter.endDate,
            selectedExperienceCenter: expCenterData ? expCenterData : "",
            tokenPercentage: selectedValue !== "" ? selectedValue : "",
            users: users ? users : "",
            userExpCenter: userDetails.experienceCenterId,
            searchType: selectedSearchType ? selectedSearchType : "",
            search: searchInput ? searchInput.toUpperCase() : "",
            department: selectedDepartment ? selectedDepartment : ""
        }
        if (userDetails.roles.find(role => role.name === ROLENAME.AssistantSalesManager)) {
            obj.selectedRole = usersData?.role ? usersData?.role : ROLENAME.AssistantSalesManager
        } else if (userDetails.roles.find(role => role.name === ROLENAME.SalesUser)) {
            obj.selectedRole = usersData?.role ? usersData?.role : ROLENAME.SalesUser
        } else {
            obj.selectedRole = usersData?.role ? usersData?.role : (selectedRole ? selectedRole : "ALL")
        }
        props.setLoading(true);
        try {
            let response = await HttpService.lostDataAnalysis(obj);
            setStore(response.data);
        } catch (error) {
            props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
        }
        props.setLoading(false);
    }


    const salesOverallReview = async (addExpCenter, usersData) => {
        let expCenterData = addExpCenter?.map((el) => el._id)
        let users = ''
        if ((usersData?.users && usersData?.users.length !== 0) || (selectedUsers && selectedUsers.length !== 0)) {
            users = usersData?.users?.map((el) => el._id) || selectedUsers?.map((el) => el._id)
        }

        let obj = {
            startDate: storeDateFilter.startDate,
            endDate: storeDateFilter.endDate,
            selectedExperienceCenter: expCenterData ? expCenterData : "",
            userExpCenter: userDetails.selectedExpCenter,
            tokenPercentage: selectedValue !== "" ? selectedValue : "",
            users: users ? users : "",
            selectedRole: usersData?.role ? usersData?.role : selectedRole,
            lost: selectLost !== "" ? selectLost : ""
        }
        props.setLoading(true);
        try {
            let response = await HttpService.getSalesDatoverallReviewDashBoard(obj);
            setStore(response.data);
        } catch (error) {
            props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
        }

        props.setLoading(false);
    }

    const checkPivot = async (value) => {
        setPivot(value)
        if (value === 'value' || value === 'total') {
            callFunction()
            setSelectedExperienceCenter([])
            setSelectedUsers([])
            setSelectedRole("ALL")
            setSearchInput("")
            setSelectedSearchType("")
            handleSelectRoles(null, ROLENAME.SalesUser)
        } else if (value === "overallReview") {
            overallReview();
            setSelectedExperienceCenter([])
            setSelectedUsers([])
            setSelectedRole(ROLENAME.DesignUser)
            handleSelectRoles(null, ROLENAME.DesignUser)
        } else if (value === "overallsummary") {
            overallsummary()
            setSelectedExperienceCenter([])
            setSelectedUsers([])
            setSelectedRole("ALL")
            setSearchInput("")
            setSelectedSearchType("")
            handleSelectRoles(null, ROLENAME.DesignUser)
        } else if (value === "designHoldAnalysis") {
            overallsummary()
            setSelectedExperienceCenter([])
            setSelectedUsers([])
            setSelectedRole("ALL")
            handleSelectRoles(null, ROLENAME.DesignUser)
        }
        else if (value === 'lost') {
            lostDataAnalysis()
            setSelectedExperienceCenter([])
            setSearchInput("")
            setSelectedSearchType("")
            setSelectedRole("")
        } else if (value === 'salesOverallReview') {
            salesOverallReview()
            setSelectedExperienceCenter([])
            setSelectedUsers([])
            setSelectedRole(ROLENAME.SalesUser)
            handleSelectRoles(null, ROLENAME.SalesUser)
        } else if (value === 'designSignOffReview') {
            designSignOffReview()
            setSelectedExperienceCenter([])
            setSelectedUsers([])
            setSelectedRole("ALL")
            setSearchInput("")
            setSelectedSearchType("")
            handleSelectRoles(null, ROLENAME.DesignUser)
        } else if (value === 'mis') {
            misReview()
        } else if (value === 'leads') {
            leadsCreated()
        }
    }

    const formatNumberToTwoDecimalPlaces = (value) => {
        return parseFloat(value).toFixed(2);
    }

    const storePivotDate = (e) => {
        const { name, value } = e.target
        setPivotDatefilters({ ...storeDateFilter, [name]: value })
    }
    const handlePivotDateFilter = () => {
        if (pivot === 'value' || pivot === 'total') {
            callFunction(selectedExperienceCenter, selectedUsers, selectedSearchType, searchInput)
        } else if (pivot === "overallReview") {
            overallReview(selectedExperienceCenter);
        } else if (pivot === "overallsummary") {
            overallsummary(selectedExperienceCenter, selectedUsers, selectedSearchType, searchInput)
        }else if (pivot === "designHoldAnalysis") {
            overallsummary(selectedExperienceCenter, selectedUsers, selectedSearchType, searchInput)
        }
        else if (pivot === 'lost') {
            lostDataAnalysis(selectedExperienceCenter, selectedUsers, selectedSearchType, searchInput)
        }
        else if (pivot === 'salesOverallReview') {
            salesOverallReview(selectedExperienceCenter)
        }
        else if (pivot === 'designSignOffReview') {
            designSignOffReview(selectedExperienceCenter, selectedUsers, selectedSearchType, searchInput)
        }
        else if (pivot === 'mis') {
            misReview()
        } 
        else if (pivot === 'leads') {
            leadsCreated()
        }
    }

    const handleSearchType = (value) => {
        setSelectedSearchType(value)
        setSearchInput('')
    }

    const handleSearch = async (value) => {
        if (pivot === 'value' || pivot === 'total') {
            callFunction(selectedExperienceCenter, selectedUsers, selectedSearchType, searchInput)
        } else if (pivot === 'overallsummary' || pivot === 'designHoldAnalysis') {
            overallsummary(selectedExperienceCenter, selectedUsers, selectedSearchType, searchInput)
        } else if (pivot === 'lost') {
            lostDataAnalysis(selectedExperienceCenter, selectedUsers, selectedSearchType, searchInput)
        } else if (pivot === 'designSignOffReview') {
            designSignOffReview(selectedExperienceCenter, selectedUsers, selectedSearchType, searchInput)
        }
    }

    const handleSelectChange = async (event) => {
        setSelectedValue(event.target.value);
        let expCenterData = selectedExperienceCenter?.map((el) => el._id)
        let obj = {
            startDate: storeDateFilter.startDate,
            endDate: storeDateFilter.endDate,
            selectedExperienceCenter: expCenterData ? expCenterData : "",
            userExpCenter: userDetails.selectedExpCenter,
            tokenPercentage: event.target.value !== "" ? event.target.value : "",
            lost: selectLost !== "" ? selectLost : "",
            searchType: selectedSearchType ? selectedSearchType : "",
            search: searchInput !== "" ? searchInput.toUpperCase() : ""
        }
        props.setLoading(true);
        if (pivot === 'value' || pivot === 'total') {
            try {
                let users = ''
                if (selectedUsers && selectedUsers.length !== 0) {
                    users = selectedUsers?.map((el) => el._id)
                }
                obj.users = users ? users : ""
                obj.selectedRole = selectedRole
                obj.pivotValue = pivot
                let response = await HttpService.getSalesDataBasedOnExpCenter(obj);
                setStore(response.data);
            } catch (error) {
                props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
            }
        } else if (pivot === "overallReview") {
            try {
                let users = ''
                if (selectedUsers && selectedUsers.length !== 0) {
                    users = selectedUsers?.map((el) => el._id)
                }

                obj.users = users ? users : ""
                obj.selectedRole = selectedRole

                let response = await HttpService.overallReviewDashBoard(obj);
                setStore(response.data);

            } catch (error) {
                props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
            }
        } else if (pivot === "overallsummary") {
            try {
                let users = ''
                if (selectedUsers && selectedUsers.length !== 0) {
                    users = selectedUsers?.map((el) => el._id)
                }
                obj.users = users ? users : ""
                obj.selectedRole = selectedRole
                let response = await HttpService.overallSummaryDashBoard(obj);
                setStore(response.data.data);
            } catch (error) {
                props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
            }
        }else if (pivot === "designHoldAnalysis") {
            try {
                let users = ''
                if (selectedUsers && selectedUsers.length !== 0) {
                    users = selectedUsers?.map((el) => el._id)
                }
                obj.users = users ? users : ""
                obj.selectedRole = selectedRole
                let response = await HttpService.overallSummaryDashBoard(obj);
                setStore(response.data.data);
            } catch (error) {
                props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
            }
        }
        

        else if (pivot === 'lost') {
            try {
                let response = await HttpService.lostDataAnalysis(obj);
                setStore(response.data);
            } catch (error) {
                props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
            }
        } else if (pivot === 'salesOverallReview') {
            try {
                let users = ''
                if (selectedUsers && selectedUsers.length !== 0) {
                    users = selectedUsers?.map((el) => el._id)
                }
                obj.users = users ? users : ""
                obj.selectedRole = selectedRole
                let response = await HttpService.getSalesDatoverallReviewDashBoard(obj);
                setStore(response.data);
            } catch (error) {
                props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
            }
        } else if (pivot === 'designSignOffReview') {
            try {
                let users = ''
                if (selectedUsers && selectedUsers.length !== 0) {
                    users = selectedUsers?.map((el) => el._id)
                }
                obj.users = users ? users : ""
                obj.selectedRole = ""
                obj.pivotValue = pivot
                let response = await HttpService.getSalesDataBasedOnExpCenter(obj);
                setStore(response.data);
            } catch (error) {
                props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
            }
        }

        props.setLoading(false);
    }

    const handleSelctLost = async (event) => {
        setSeletLost(event.target.value)
        let expCenterData = selectedExperienceCenter?.map((el) => el._id)
        let obj = {
            startDate: storeDateFilter.startDate,
            endDate: storeDateFilter.endDate,
            selectedExperienceCenter: expCenterData ? expCenterData : "",
            tokenPercentage: selectedValue !== "" ? selectedValue : "",
            lost: event.target.value !== "" ? event.target.value : '',
            searchType: selectedSearchType ? selectedSearchType : "",
            search: searchInput !== "" ? searchInput.toUpperCase() : ""
        }
        props.setLoading(true);
        if (pivot === 'value' || pivot === 'total') {
            try {
                let users = ''
                if (selectedUsers && selectedUsers.length !== 0) {
                    users = selectedUsers?.map((el) => el._id)
                }
                obj.users = users ? users : ""
                obj.selectedRole = selectedRole
                obj.pivotValue = 'value'
                obj.userExpCenter = userDetails.experienceCenterId
                let response = await HttpService.getSalesDataBasedOnExpCenter(obj);
                setStore(response.data);
            } catch (error) {
                props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
            }
        } else if (pivot === 'salesOverallReview') {
            try {
                let users = ''
                if (selectedUsers && selectedUsers.length !== 0) {
                    users = selectedUsers?.map((el) => el._id)
                }
                obj.users = users ? users : ""
                obj.selectedRole = selectedRole
                let response = await HttpService.getSalesDatoverallReviewDashBoard(obj);
                setStore(response.data);
            } catch (error) {
                props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
            }
        }
        props.setLoading(false);
    }

    const handleSelectDepartment = (event) => {
        setSelectedDepartment(event.target.value)
    }

    const handleSelectRoles = async (event, role) => {
        setSelectedUsers([])
        let obj = {}
        if (role) {
            obj.role = role
        } else {
            setSelectedRole(event.target.value)
            obj.role = event.target.value
        }
        obj.pivotValue = pivot
        try {
            let response = await HttpService.getSelectedUsersBasedOnRoles(obj);
            if (!role) {
                await (pivot === 'value'
                    ? callFunction(selectedExperienceCenter, obj, selectedSearchType, searchInput)
                    : pivot === 'designSignOffReview'
                        ? designSignOffReview(selectedExperienceCenter, obj, selectedSearchType, searchInput)
                        : pivot === 'lost'
                            ? lostDataAnalysis(selectedExperienceCenter, obj, selectedSearchType, searchInput)
                            : overallsummary(selectedExperienceCenter, obj, selectedSearchType, searchInput));
            }
            setUsersData(response.data);
        } catch (error) {
            props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
        }
    }

    const handleMultipleExpData = (e, newvalue) => {
        setSelectedExperienceCenter(newvalue)
        handleExpData(newvalue)
    }
    const handleExpData = async (newvalue) => {
        props.setLoading(true);
        if (pivot === 'value' || pivot === 'total') {
            await callFunction(newvalue, selectedUsers, selectedSearchType, searchInput)
        } else if (pivot === "overallReview") {
            await overallReview(newvalue);
        } else if (pivot === "overallsummary") {
            await overallsummary(newvalue, selectedUsers, selectedSearchType, searchInput)
        } else if (pivot === 'lost') {
            await lostDataAnalysis(newvalue, selectedUsers, selectedSearchType, searchInput)
        } else if (pivot === 'salesOverallReview') {
            await salesOverallReview(newvalue)
        } else if (pivot === 'designSignOffReview') {
            await designSignOffReview(newvalue, selectedUsers, selectedSearchType, searchInput)
        }
        props.setLoading(false);
    }

    const handleMultipleUsers = (e, newvalue) => {
        setSelectedUsers(newvalue)
        handleUsersData(newvalue)
    }

    const handleUsersData = async (newvalue) => {
        props.setLoading(true);
        let obj = {
            users: newvalue,
            role: selectedRole
        }
        if (pivot === "overallReview") {
            await overallReview(null, obj);
        } else if (pivot === 'salesOverallReview') {
            await salesOverallReview(null, obj)
        } else if (pivot === 'value') {
            await callFunction(selectedExperienceCenter, obj, selectedSearchType, searchInput)
        } else if (pivot === 'overallsummary' || pivot === 'designHoldAnalysis') {
            await overallsummary(selectedExperienceCenter, obj, selectedSearchType, searchInput)
        } else if (pivot === 'designSignOffReview') {
            await designSignOffReview(selectedExperienceCenter, obj, selectedSearchType, searchInput)
        } else if (pivot === 'lost') {
            await lostDataAnalysis(selectedExperienceCenter, obj, selectedSearchType, searchInput)
        }
        props.setLoading(false);
    }

    const dataBasedOnClick = async (e, ele, status) => {
        setStaus(status)
        let expCenterData = selectedExperienceCenter?.map((el) => el._id)
        let users = ''
        if (selectedUsers && selectedUsers.length !== 0) {
            users = selectedUsers?.map((el) => el._id)
        }
        let obj = {
            status: status,
            _id: ele._id,
            selectedExperienceCenter: expCenterData.length !== 0 ? expCenterData : "",
            userExpCenter: userDetails.experienceCenterId,
            tokenPercentage: selectedValue !== "" ? selectedValue : "",
            users: users ? users : "",
            searchType: selectedSearchType ? selectedSearchType : "",
            search: searchInput !== "" ? searchInput.toUpperCase() : ""
        }
        props.setLoading(true);
        if (pivot === "overallReview") {
            obj.role = selectedRole
            if ((status === 'total' && ele._id === 'totals') || ele._id === 'totals') {
                let data = store?.map((el) => el._id)
                data.pop()
                obj._id = data
                obj.startDate = storeDateFilter.startDate
                obj.endDate = storeDateFilter.endDate
            } else {
                let arr = []
                arr.push(ele._id)
                obj._id = arr
                obj.startDate = storeDateFilter.startDate
                obj.endDate = storeDateFilter.endDate
            }
            try {
                let response = await HttpService.getDataBasedClickOveralReview(obj);
                setPopMonthData(response.data)
                setShowDetailedModal(true)
            } catch (error) {
                props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
            }
            props.setLoading(false);
        } else if(pivot === 'designHoldAnalysis'){    //Design-Hold Analysis Dashboard
            obj.role = selectedRole
            if (status === 'total' && ele._id.year !== 'Grand Total') {
                obj.startDate = ''
                obj.endDate = ''
            }
            if (ele?._id?.year === 'Grand Total' && ele?._id?.month === 'Total') {
                obj.startDate = storeDateFilter.startDate
                obj.endDate = storeDateFilter.endDate
            }
            try {
                let response = await HttpService.getDataBasedClickOverallSummary(obj);
                setPopMonthData(response.data)
                setShowDetailedModal(true)
            } catch (error) {
                props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
            }
            props.setLoading(false);

        } else if (pivot === 'mis') {
            if (status === 'total' && ele._id.year !== 'Grand Total') {
                obj.startDate = ''
                obj.endDate = ''
            }
            if (ele?._id?.year === 'Grand Total' && ele?._id?.month === 'Total') {
                obj.startDate = storeDateFilter.startDate
                obj.endDate = storeDateFilter.endDate
            }
            try {
                let response = await HttpService.getDataBasedClickOverallMIS(obj);
                setPopMonthData(response.data)
                setShowDetailedModal(true)
            } catch (error) {
                props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
            }
            props.setLoading(false);
        } else {
            obj.role = selectedRole
            if (status === 'total' && ele._id.year !== 'Grand Total') {
                obj.startDate = ''
                obj.endDate = ''
            }
            if (ele?._id?.year === 'Grand Total' && ele?._id?.month === 'Total') {
                obj.startDate = storeDateFilter.startDate
                obj.endDate = storeDateFilter.endDate
            }
            try {
                let response = await HttpService.getDataBasedClickOverallSummary(obj);
                setPopMonthData(response.data)
                setShowDetailedModal(true)
            } catch (error) {
                props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
            }
            props.setLoading(false);
        }
    }

    const lostDataBasedOnClick = async (e, month, innerEl, val) => {
        let obj = {}
        let users = ''
        let expCenterData =
          selectedExperienceCenter && selectedExperienceCenter.length > 0
            ? selectedExperienceCenter.map((el) => el._id)
            : "";
        if (selectedUsers && selectedUsers.length !== 0) {
            users = selectedUsers?.map((el) => el._id)
        }
        if (val === 'value') {
            obj = {
                _id: { month: month.monthNumber, year: month.year },
                selectedExperienceCenter: innerEl.experienceCenterId ? innerEl.experienceCenterId : expCenterData,
                tokenPercentage: selectedValue !== "" ? selectedValue : "",
                lost: selectLost !== "" ? selectLost : "",
                pivotValue: 'value',
                selectedRole: selectedRole ? selectedRole : "",
                userExpCenter: userDetails.experienceCenterId,
                users: users ? users : "",
                searchType: selectedSearchType ? selectedSearchType : "",
                search: searchInput !== "" ? searchInput.toUpperCase() : "",
                department: selectedDepartment ? selectedDepartment : ""
            }
            if (innerEl.name === "Sales Grand Total") {
                obj.selectedRole = "ALL"
                obj.name = "Sales Grand Total"
            } else if (innerEl.name === "Sales Lost Total") {
                obj.selectedRole = "ALL"
                obj.name = "Sales Lost Total"
            } else if (innerEl.name === "Design Lost Total") {
                obj.selectedRole = "ALL"
                obj.name = "Design Lost Total"
            }
        } else if (val === 'total') {
            if (innerEl === "Grand Total") {
                obj = {
                    _id: { startMonth: month[0].monthNumber, startYear: month[0].year, endtMonth: month[month.length - 1].monthNumber, endtYear: month[month.length - 1].year },
                    selectedExperienceCenter: expCenterData,
                    tokenPercentage: selectedValue !== "" ? selectedValue : "",
                    lost: selectLost !== "" ? selectLost : "",
                    pivotValue: 'value',
                    selectedRole: selectedRole ? selectedRole : "",
                    userExpCenter: userDetails.experienceCenterId,
                    users: users ? users : "",
                    searchType: selectedSearchType ? selectedSearchType : "",
                    search: searchInput !== "" ? searchInput.toUpperCase() : "",
                    department: selectedDepartment ? selectedDepartment : ""
                }
            } else if (innerEl === "Sales Grand Total") {
                obj = {
                    _id: { startMonth: month[0].monthNumber, startYear: month[0].year, endtMonth: month[month.length - 1].monthNumber, endtYear: month[month.length - 1].year },
                    selectedExperienceCenter: expCenterData,
                    tokenPercentage: selectedValue !== "" ? selectedValue : "",
                    lost: selectLost !== "" ? selectLost : "",
                    pivotValue: 'value',
                    selectedRole: selectedRole ? selectedRole : "",
                    userExpCenter: userDetails.experienceCenterId,
                    users: users ? users : "",
                    selectedRole: "ALL",
                    name: "Sales Grand Total",
                    searchType: selectedSearchType ? selectedSearchType : "",
                    search: searchInput !== "" ? searchInput.toUpperCase() : "",
                    department: selectedDepartment ? selectedDepartment : ""
                }
            } else if (innerEl === "Sales Lost Total") {
                obj = {
                    _id: { startMonth: month[0].monthNumber, startYear: month[0].year, endtMonth: month[month.length - 1].monthNumber, endtYear: month[month.length - 1].year },
                    selectedExperienceCenter: expCenterData,
                    tokenPercentage: selectedValue !== "" ? selectedValue : "",
                    lost: selectLost !== "" ? selectLost : "",
                    pivotValue: 'value',
                    selectedRole: selectedRole ? selectedRole : "",
                    userExpCenter: userDetails.experienceCenterId,
                    users: users ? users : "",
                    name: "Sales Lost Total",
                    searchType: selectedSearchType ? selectedSearchType : "",
                    search: searchInput !== "" ? searchInput.toUpperCase() : "",
                    department: selectedDepartment ? selectedDepartment : ""
                }
            } else if (innerEl === "Design Lost Total") {
                obj = {
                    _id: { startMonth: month[0].monthNumber, startYear: month[0].year, endtMonth: month[month.length - 1].monthNumber, endtYear: month[month.length - 1].year },
                    selectedExperienceCenter: expCenterData,
                    tokenPercentage: selectedValue !== "" ? selectedValue : "",
                    lost: selectLost !== "" ? selectLost : "",
                    pivotValue: 'value',
                    selectedRole: selectedRole ? selectedRole : "",
                    userExpCenter: userDetails.experienceCenterId,
                    users: users ? users : "",
                    name: "Design Lost Total",
                    searchType: selectedSearchType ? selectedSearchType : "",
                    search: searchInput !== "" ? searchInput.toUpperCase() : "",
                    department: selectedDepartment ? selectedDepartment : ""
                }
            } else {
                let expCenter;
                for (let i = 0; i < store.length; i++) {
                    if (store[i].experienceCenterName === innerEl) {
                        expCenter = store[i].experienceCenterId
                        break;
                    }
                }
                obj = {
                    _id: { startMonth: month[0].monthNumber, startYear: month[0].year, endtMonth: month[month.length - 1].monthNumber, endtYear: month[month.length - 1].year },
                    selectedExperienceCenter: expCenter,
                    tokenPercentage: selectedValue !== "" ? selectedValue : "",
                    lost: selectLost !== "" ? selectLost : "",
                    pivotValue: 'value',
                    selectedRole: selectedRole ? selectedRole : "",
                    userExpCenter: userDetails.experienceCenterId,
                    users: users ? users : "",
                    searchType: selectedSearchType ? selectedSearchType : "",
                    search: searchInput !== "" ? searchInput.toUpperCase() : "",
                    department: selectedDepartment ? selectedDepartment : ""
                }
            }
        }
        props.setLoading(true);
        try {
            let response = await HttpService.getDataBasedClickLost(obj);
            setPopMonthData(response.data)
            setShowDetailedModal(true)
        } catch (error) {
            props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
        }
        props.setLoading(false);
    }

    const getDataBasedOnClickMarketing = async (e, month, innerEl, val) => {
        let obj = {}
        let expCenterData =
            selectedExperienceCenter && selectedExperienceCenter.length > 0
                ? selectedExperienceCenter.map((el) => el._id)
                : "";
        if (val === 'leads') {
            obj = {
                _id: { month: month.monthNumber, year: month.year },
                selectedExperienceCenter: innerEl.experienceCenterId ? innerEl.experienceCenterId : expCenterData,
                userExpCenter: userDetails.experienceCenterId,
            }
        } else if (val === 'total') {
            if (innerEl === "Grand Total") {
                obj = {
                    _id: { startMonth: month[0].monthNumber, startYear: month[0].year, endtMonth: month[month.length - 1].monthNumber, endtYear: month[month.length - 1].year },
                    selectedExperienceCenter: expCenterData,
                    userExpCenter: userDetails.experienceCenterId,
                }
            } else {
                let expCenter;
                for (let i = 0; i < store.length; i++) {
                    if (store[i].experienceCenterName === innerEl) {
                        expCenter = store[i].experienceCenterId
                        break;
                    }
                }
                obj = {
                    _id: { startMonth: month[0].monthNumber, startYear: month[0].year, endtMonth: month[month.length - 1].monthNumber, endtYear: month[month.length - 1].year },
                    selectedExperienceCenter: expCenter,
                    userExpCenter: userDetails.experienceCenterId,
                }
            }
        }
        props.setLoading(true);
        try {
            let response = await HttpService.getDataBasedClickMarketing(obj);
            setPopMonthData(response.data)
            setShowDetailedModal(true)
        } catch (error) {
            props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
        }
        props.setLoading(false);
    }

    const getDataBasedOnClickSales = async (e, month, innerEl, val) => {
        let obj = {}
        let users = ''
        let expCenterData =
          selectedExperienceCenter && selectedExperienceCenter.length > 0
            ? selectedExperienceCenter.map((el) => el._id)
            : ""; 
        if (selectedUsers && selectedUsers.length !== 0) {
            users = selectedUsers?.map((el) => el._id)
        }
        if (val === 'value') {
            obj = {
                _id: { month: month.monthNumber, year: month.year },
                selectedExperienceCenter: innerEl.experienceCenterId ? innerEl.experienceCenterId : expCenterData,
                tokenPercentage: selectedValue !== "" ? selectedValue : "",
                lost: selectLost !== "" ? selectLost : "",
                pivotValue: 'value',
                selectedRole: selectedRole ? selectedRole : "",
                userExpCenter: userDetails.experienceCenterId,
                users: users ? users : "",
                searchType: selectedSearchType ? selectedSearchType : "",
                search: searchInput !== "" ? searchInput.toUpperCase() : ""
            }
        } else if (val === 'total') {
            if (innerEl === "Grand Total") {
                obj = {
                    _id: { startMonth: month[0].monthNumber, startYear: month[0].year, endtMonth: month[month.length - 1].monthNumber, endtYear: month[month.length - 1].year },
                    selectedExperienceCenter: expCenterData,
                    tokenPercentage: selectedValue !== "" ? selectedValue : "",
                    lost: selectLost !== "" ? selectLost : "",
                    pivotValue: 'value',
                    selectedRole: selectedRole ? selectedRole : "",
                    userExpCenter: userDetails.experienceCenterId,
                    users: users ? users : "",
                    searchType: selectedSearchType ? selectedSearchType : "",
                    search: searchInput !== "" ? searchInput.toUpperCase() : ""
                }
            } else {
                let expCenter;
                for (let i = 0; i < store.length; i++) {
                    if (store[i].experienceCenterName === innerEl) {
                        expCenter = store[i].experienceCenterId
                        break;
                    }
                }
                obj = {
                    _id: { startMonth: month[0].monthNumber, startYear: month[0].year, endtMonth: month[month.length - 1].monthNumber, endtYear: month[month.length - 1].year },
                    selectedExperienceCenter: expCenter,
                    tokenPercentage: selectedValue !== "" ? selectedValue : "",
                    lost: selectLost !== "" ? selectLost : "",
                    pivotValue: 'value',
                    selectedRole: selectedRole ? selectedRole : "",
                    userExpCenter: userDetails.experienceCenterId,
                    users: users ? users : "",
                    searchType: selectedSearchType ? selectedSearchType : "",
                    search: searchInput !== "" ? searchInput.toUpperCase() : ""
                }
            }
        }
        props.setLoading(true);
        try {
            let response = await HttpService.getDataBasedClickSales(obj);
            setPopMonthData(response.data)
            setShowDetailedModal(true)
        } catch (error) {
            props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
        }
        props.setLoading(false);
    }

    const getDataBasedOnClickDesignSignOff = async (e, month, innerEl, val) => {
        let obj = {}
        let users = ''
        let expCenterData =
          selectedExperienceCenter && selectedExperienceCenter.length > 0
            ? selectedExperienceCenter.map((el) => el._id)
            : "";
        if (selectedUsers && selectedUsers.length !== 0) {
            users = selectedUsers?.map((el) => el._id)
        }
        if (val === 'designSignOffReview') {
            obj = {
                _id: { month: month.monthNumber, year: month.year },
                selectedExperienceCenter: innerEl.experienceCenterId ? innerEl.experienceCenterId : expCenterData,
                tokenPercentage: selectedValue !== "" ? selectedValue : "",
                lost: selectLost !== "" ? selectLost : "",
                pivotValue: 'designSignOffReview',
                userExpCenter: userDetails.experienceCenterId,
                users: users ? users : "",
                searchType: selectedSearchType ? selectedSearchType : "",
                search: searchInput !== "" ? searchInput.toUpperCase() : ""
            }
        } else if (val === 'total') {
            if (innerEl === "Grand Total") {
                obj = {
                    _id: { startMonth: month[0].monthNumber, startYear: month[0].year, endtMonth: month[month.length - 1].monthNumber, endtYear: month[month.length - 1].year },
                    selectedExperienceCenter: expCenterData,
                    tokenPercentage: selectedValue !== "" ? selectedValue : "",
                    lost: selectLost !== "" ? selectLost : "",
                    pivotValue: 'designSignOffReview',
                    userExpCenter: userDetails.experienceCenterId,
                    users: users ? users : "",
                    searchType: selectedSearchType ? selectedSearchType : "",
                    search: searchInput !== "" ? searchInput.toUpperCase() : ""
                }
            } else {
                let expCenter;
                for (let i = 0; i < store.length; i++) {
                    if (store[i].experienceCenterName === innerEl) {
                        expCenter = store[i].experienceCenterId
                        break;
                    }
                }
                obj = {
                    _id: { startMonth: month[0].monthNumber, startYear: month[0].year, endtMonth: month[month.length - 1].monthNumber, endtYear: month[month.length - 1].year },
                    selectedExperienceCenter: expCenter,
                    tokenPercentage: selectedValue !== "" ? selectedValue : "",
                    lost: selectLost !== "" ? selectLost : "",
                    pivotValue: 'designSignOffReview',
                    userExpCenter: userDetails.experienceCenterId,
                    users: users ? users : "",
                    searchType: selectedSearchType ? selectedSearchType : "",
                    search: searchInput !== "" ? searchInput.toUpperCase() : ""
                }
            }
        }
        if (userDetails.roles.find(role => role.name === ROLENAME.AssistantDesignManager)) {
            obj.selectedRole = usersData?.role ? usersData?.role : ROLENAME.AssistantDesignManager
        } else if (userDetails.roles.find(role => role.name === ROLENAME.DesignUser)) {
            obj.selectedRole = usersData?.role ? usersData?.role : ROLENAME.DesignUser
        } else {
            obj.selectedRole = usersData?.role ? usersData?.role : (selectedRole ? selectedRole : "ALL")
        }
        props.setLoading(true);
        try {
            let response = await HttpService.getDataBasedClickSales(obj);
            setPopMonthData(response.data)
            setShowDetailedModal(true)
        } catch (error) {
            props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
        }
        props.setLoading(false);
    }

    const getDataBasedOnClickSalesOverview = async (userId, data) => {
        let expCenterData = selectedExperienceCenter?.map((el) => el._id)
        let obj = {
            months: { month: data.monthNumber, year: data.year },
            startDate: storeDateFilter.startDate,
            endDate: storeDateFilter.endDate,
            selectedExperienceCenter: expCenterData.length !== 0 ? expCenterData : "",
            userExpCenter: userDetails.selectedExpCenter,
            tokenPercentage: selectedValue !== "" ? selectedValue : "",
            role: selectedRole,
            lost: selectLost !== "" ? selectLost : ""
        }
        if (userId === 'Grand Total') {
            obj._id = store?.map((el) => el.userId)
            obj._id.pop()
        } else {
            let arr = []
            arr.push(userId)
            obj._id = arr
        }
        props.setLoading(true);
        try {
            let response = await HttpService.getDataBasedClickSalesOverview(obj);
            setPopMonthData(response.data)
            setShowDetailedModal(true)
        } catch (error) {
            props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
        }
        props.setLoading(false);
    }

    const clearDateFilter = () => {
        setSearchInput("")
        setSelectedSearchType("")
        if (pivot === 'value' || pivot === 'total') {
            callFunction(selectedExperienceCenter, selectedUsers, "", "")
        } else if (pivot === 'overallsummary') {
            overallsummary(selectedExperienceCenter, selectedUsers, "", "")
        } else if (pivot === 'lost') {
            lostDataAnalysis(selectedExperienceCenter, selectedUsers, "", "")
        } else if (pivot === 'designSignOffReview') {
            designSignOffReview(selectedExperienceCenter, selectedUsers, "", "")
        }
    }

    const calculatePercentageSummary = (month, expCenterData) => {
        const findMonthlyGrandTotal = (key) => {
            const data = expCenterData[key];
            if (!data) return 0;
    
            for (const innerEl of data) {
                const found = innerEl.monthlyGrandTotal.find(
                    (entry) => entry.monthName === month.monthName && entry.year === month.year
                );
                if (found) return found.totalGrandTotal || 0;
            }
            return 0;
        };
    
        const grandTotal = findMonthlyGrandTotal("Grand Total");
        const salesGrandTotal = findMonthlyGrandTotal("Sales Grand Total");
    
        const percentage = (grandTotal && salesGrandTotal) ? ((grandTotal / salesGrandTotal) * 100).toFixed(2) : 0;
        return `${percentage}%`;
    };

    const calculatePercentageCount = (month, expCenterData) => {
        const findMonthlyGrandTotal = (key) => {
            const data = expCenterData[key];
            if (!data) return 0;
    
            for (const innerEl of data) {
                const found = innerEl.monthlyGrandTotal.find(
                    (entry) => entry.monthName === month.monthName && entry.year === month.year
                );
                if (found) return found.count || 0;
            }
            return 0;
        };
    
        const grandTotal = findMonthlyGrandTotal("Grand Total");
        const salesGrandTotal = findMonthlyGrandTotal("Sales Grand Total");
    
        const percentage = (grandTotal && salesGrandTotal) ? ((grandTotal / salesGrandTotal) * 100).toFixed(2) : 0;
        return `${percentage}%`;
    };

    const percentageLost = (store) => {
        let grandTotalValue = 0;
        let salesGrandTotalValue = 0;

        store.forEach(storeItem => {
            if (storeItem.experienceCenterName === "Grand Total") {
                grandTotalValue = storeItem.grandTotal;
            } else if (storeItem.experienceCenterName === "Sales Grand Total") {
                salesGrandTotalValue = storeItem.grandTotal;
            }
        });
        let percentageResult = 0;
        if (salesGrandTotalValue !== 0) {
            percentageResult = (grandTotalValue / salesGrandTotalValue) * 100;
        }

        return percentageResult.toFixed(2);
    }

    const percentageCount = (store) => {
        let grandTotalValue = 0;
        let salesGrandTotalValue = 0;

        store.forEach(storeItem => {
            if (storeItem.experienceCenterName === "Grand Total") {
                grandTotalValue = storeItem.count;
            } else if (storeItem.experienceCenterName === "Sales Grand Total") {
                salesGrandTotalValue = storeItem.count;
            }
        });
        let percentageResult = 0;
        if (salesGrandTotalValue !== 0) {
            percentageResult = (grandTotalValue / salesGrandTotalValue) * 100;
        }

        return percentageResult.toFixed(2);
    }

    const buttons = [
        {
            name: 'Leads Generated',
            pivot: 'leads',
            roles: [ROLENAME.CenterHead, ROLENAME.BusinessHead, ROLENAME.Admin, ROLENAME.DGM_Marketing, ROLENAME.DataAnalyst]
        },
        {
            name: 'Sales Summary',
            pivot: 'value',
            roles: [ROLENAME.CenterHead, ROLENAME.BusinessHead, ROLENAME.Admin, ROLENAME.DGM_Marketing, ROLENAME.DataAnalyst, ROLENAME.DGM_Sales, ROLENAME.SalesManager, ROLENAME.AssistantSalesManager, ROLENAME.SalesUser, ROLENAME.FinanceManager],
        },
        // {
        //     name: 'Sales Overall Review',
        //     pivot: 'salesOverallReview',
        //     roles: [ROLENAME.CenterHead, ROLENAME.BusinessHead, ROLENAME.DesignHead, ROLENAME.Admin, ROLENAME.DGM_Sales, ROLENAME.SalesManager, ROLENAME.AssistantSalesManager, ROLENAME.SalesUser],
        // },
        {
            name: 'Overall Summary',
            pivot: 'overallsummary',
            roles: [ROLENAME.CenterHead, ROLENAME.BusinessHead, ROLENAME.DesignHead, ROLENAME.Admin, ROLENAME.DataAnalyst, ROLENAME.OperationDesignManager, ROLENAME.AssistantDesignManager, ROLENAME.DesignUser, ROLENAME.FinanceManager, ROLENAME.DGM_Sales],
        },
        // {
        //     name: 'Overall Review',
        //     pivot: 'overallReview',
        //     roles: [ROLENAME.CenterHead, ROLENAME.BusinessHead, ROLENAME.DesignHead, ROLENAME.Admin, ROLENAME.OperationDesignManager, ROLENAME.AssistantDesignManager, ROLENAME.DesignUser],
        // },
        {
            name: 'Design Sign-Off Review',
            pivot: 'designSignOffReview',
            roles: [ROLENAME.CenterHead, ROLENAME.BusinessHead, ROLENAME.Admin, ROLENAME.DesignHead, ROLENAME.OperationDesignManager, ROLENAME.AssistantDesignManager, ROLENAME.DesignUser],
        },
        {
            name: 'Lost data analysis',
            pivot: 'lost',
            roles: [ROLENAME.CenterHead, ROLENAME.BusinessHead, ROLENAME.DesignHead, ROLENAME.Admin, ROLENAME.DataAnalyst, ROLENAME.FinanceManager],
        },
        {
            name: 'Design-Hold Analysis',
            pivot: 'designHoldAnalysis',
            roles: [ROLENAME.CenterHead, ROLENAME.BusinessHead, ROLENAME.DesignHead, ROLENAME.Admin, ROLENAME.OperationDesignManager, ROLENAME.AssistantDesignManager, ROLENAME.DesignUser, ROLENAME.FinanceManager, ROLENAME.DataAnalyst],
        },
        {
            name: 'MIS - 1',
            pivot: 'mis',
            roles: [ROLENAME.CenterHead, ROLENAME.BusinessHead, ROLENAME.Admin]
        }

    ];

    return (
        <div className="App">
            <Grid container spacing={2} justifyContent="center" alignItems="center">
                {buttons.map((button, index) => {
                    const isVisible = userDetails?.roles?.some(role => button.roles.includes(role.name));
                    return (
                        isVisible && (
                            <Grid item xs={2} key={index}>
                                <Button
                                    onClick={() => checkPivot(button.pivot)}
                                    style={{
                                        backgroundColor: pivot === button.pivot ? '#F50057' : 'inherit',
                                        color: pivot === button.pivot ? 'white' : 'inherit',
                                    }}
                                >
                                    {button.name}
                                </Button>
                            </Grid>
                        )
                    )
                })}
            </Grid>

            <br />
            <div>
                <Paper className={classes.paper}>
                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                    {(pivot !== 'mis' && pivot !== 'leads') &&
                            <Grid item xs={12} sm={3}>
                                {userDetails?.roles && userDetails.roles.some(role => [ROLENAME.CenterHead, ROLENAME.BusinessHead, ROLENAME.DesignHead, ROLENAME.DGM_Sales, ROLENAME.Admin, ROLENAME.DGM_Marketing, ROLENAME.DataAnalyst, ROLENAME.OperationDesignManager, ROLENAME.AssistantDesignManager, ROLENAME.DesignUser].includes(role.name)) && (
                                    <FormControl style={{ width: '100%', marginTop: '0.4rem' }}>
                                        <Autocomplete
                                            multiple
                                            value={selectedExperienceCenter}
                                            onChange={handleMultipleExpData}
                                            options={commonExpCenter}
                                            getOptionLabel={(option) => option.name}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Select Exp-Center"
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    </FormControl>
                                )}
                            </Grid>
                        }
                        <Grid item xs={12} sm={2}>
                            <TextField
                                id="startDate"
                                name="startDate"
                                label="From Date"
                                type="date"
                                variant="outlined"
                                value={storeDateFilter.startDate}
                                onChange={storePivotDate}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <TextField
                                id="endDate"
                                name="endDate"
                                label="End Date"
                                type="date"
                                variant="outlined"
                                value={storeDateFilter.endDate}
                                onChange={storePivotDate}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Button variant="contained" color="primary" onClick={handlePivotDateFilter} style={{ backgroundColor: "#F50057", color: "#fff", padding: "0.4rem 2rem 2rem 2rem" }}>
                                Submit
                            </Button>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                    {(pivot !== 'mis' && pivot !== 'leads') &&
                            <Grid item xs={12} sm={3}>
                                <FormControl style={{ width: '100%', marginTop: "0.4rem" }}>
                                    <TextField
                                        select
                                        labelId="project-doc"
                                        label="10 % Payment"
                                        variant="outlined"
                                        value={selectedValue}
                                        onChange={(e) => handleSelectChange(e)}
                                    >
                                        <MenuItem value="2">All</MenuItem>
                                        <MenuItem value="1">YES</MenuItem>
                                        <MenuItem value="0">NO</MenuItem>
                                    </TextField>
                                </FormControl>
                            </Grid>
                        }

                        {(userDetails?.roles && userDetails.roles.some(role => [ROLENAME.CenterHead, ROLENAME.BusinessHead, ROLENAME.DesignHead, ROLENAME.Admin, ROLENAME.DataAnalyst, ROLENAME.DGM_Sales].includes(role.name))) && (pivot === "lost") && (
                            <>
                                <Grid item xs={12} sm={3}>
                                    <FormControl style={{ width: '90%', marginTop: "0.4rem" }}>
                                        <TextField
                                            select
                                            labelId="project-doc"
                                            label="Select Department"
                                            variant="outlined"
                                            value={selectedDepartment}
                                            onChange={(e) => handleSelectDepartment(e)}
                                        >
                                            <MenuItem value="Sales">Sales</MenuItem>
                                            <MenuItem value="Design">Design</MenuItem>
                                        </TextField>
                                    </FormControl>
                                </Grid>
                            </>
                        )}

                        {(userDetails?.roles && userDetails.roles.some(role => [ROLENAME.CenterHead, ROLENAME.BusinessHead, ROLENAME.DesignHead, ROLENAME.Admin, ROLENAME.DataAnalyst, ROLENAME.DGM_Sales].includes(role.name))) && (pivot === "value" || pivot === "lost" || pivot === "overallsummary" || pivot === "designSignOffReview" || pivot === "designHoldAnalysis") && (
                            <>
                                <Grid item xs={12} sm={3}>
                                    <FormControl style={{ width: '90%', marginTop: "0.4rem" }}>
                                        <TextField
                                            select
                                            labelId="project-doc"
                                            label="Select Role"
                                            variant="outlined"
                                            value={selectedRole}
                                            onChange={(e) => handleSelectRoles(e)}
                                            disabled={pivot === "lost" && !selectedDepartment}  // Disable if selectedDepartment is not set and pivot is "lost"
                                        >
                                            <MenuItem value={(pivot === "salesOverallReview" || pivot === "value") ? "ALL" : "ALL"}>ALL</MenuItem>
                                            <MenuItem value={(pivot === "salesOverallReview" || pivot === "value" || selectedDepartment === "Sales") ? ROLENAME.DGM_Sales : ROLENAME.DGM_Design}>DGM</MenuItem>
                                            <MenuItem value={(pivot === "salesOverallReview" || pivot === "value" || selectedDepartment === "Sales") ? ROLENAME.SalesManager : ROLENAME.OperationDesignManager}>Manager</MenuItem>
                                            <MenuItem value={(pivot === "salesOverallReview" || pivot === "value" || selectedDepartment === "Sales") ? ROLENAME.AssistantSalesManager : ROLENAME.AssistantDesignManager}>Assistant Manager</MenuItem>
                                            <MenuItem value={(pivot === "salesOverallReview" || pivot === "value" || selectedDepartment === "Sales") ? ROLENAME.SalesUser : ROLENAME.DesignUser}>Executive</MenuItem>
                                        </TextField>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={3}>
                                    <FormControl style={{ width: '100%', marginTop: '0.30rem' }}>
                                        <Autocomplete
                                            multiple
                                            value={selectedUsers}
                                            onChange={(e, v) => handleMultipleUsers(e, v)}
                                            options={usersData}
                                            getOptionLabel={(option) => option.name}
                                            renderInput={(params) => <TextField {...params} label="Select Users" variant="outlined" />}
                                            disabled={pivot === "lost" && !selectedDepartment}  // Disable if selectedDepartment is not set and pivot is "lost"
                                        />
                                    </FormControl>
                                </Grid>
                            </>
                        )}

                        {(userDetails?.roles && userDetails.roles.some(role => [ROLENAME.SalesManager, ROLENAME.OperationDesignManager].includes(role.name))) && (pivot === "value" || pivot === "overallsummary"|| pivot === "designSignOffReview" || pivot === "designHoldAnalysis") && (
                            <>
                                <Grid item xs={12} sm={2}>
                                    <FormControl style={{ width: '80%', marginTop: "0.4rem" }}>
                                        <TextField
                                            select
                                            labelId="project-doc"
                                            label="Select Role"
                                            variant="outlined"
                                            value={selectedRole}
                                            onChange={(e) => handleSelectRoles(e)}
                                        >
                                            <MenuItem value={pivot === "value" ? "ALL" : "ALL"}>ALL</MenuItem>
                                            <MenuItem value={pivot === "value" ? ROLENAME.SalesManager : ROLENAME.OperationDesignManager}>Manager</MenuItem>
                                            <MenuItem value={pivot === "value" ? ROLENAME.AssistantSalesManager : ROLENAME.AssistantDesignManager}>Assistant Manager</MenuItem>
                                            <MenuItem value={pivot === "value" ? ROLENAME.SalesUser : ROLENAME.DesignUser}>Executive</MenuItem>
                                        </TextField>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <FormControl style={{ width: '100%', marginTop: '0.30rem' }}>
                                        <Autocomplete
                                            multiple
                                            value={selectedUsers}
                                            onChange={(e, v) => handleMultipleUsers(e, v)}
                                            options={usersData}
                                            getOptionLabel={(option) => option.name}
                                            renderInput={(params) => <TextField {...params} label="Select Users" variant="outlined" />}
                                        />
                                    </FormControl>
                                </Grid>
                            </>
                        )}

                        {(userDetails?.roles && userDetails.roles.some(role => [ROLENAME.AssistantSalesManager, ROLENAME.AssistantDesignManager].includes(role.name))) && (pivot === "value" || pivot === "overallsummary" || pivot === "designSignOffReview" || pivot === "designHoldAnalysis") && (
                            <>
                                <Grid item xs={12} sm={4}>
                                    <FormControl style={{ width: '100%', marginTop: "0.4rem" }}>
                                        <TextField
                                            select
                                            labelId="project-doc"
                                            label="Select Role"
                                            variant="outlined"
                                            value={selectedRole}
                                            onChange={(e) => handleSelectRoles(e)}
                                        >
                                            <MenuItem value={pivot === "value" ? ROLENAME.AssistantSalesManager : ROLENAME.AssistantDesignManager}>Assistant Manager</MenuItem>
                                            <MenuItem value={pivot === "value" ? ROLENAME.SalesUser : ROLENAME.DesignUser}>Executive</MenuItem>
                                        </TextField>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <FormControl style={{ width: '100%', marginTop: '0.30rem' }}>
                                        <Autocomplete
                                            multiple
                                            size="medium"
                                            value={selectedUsers}
                                            onChange={(e, v) => handleMultipleUsers(e, v)}
                                            ListboxProps={{ style: { maxHeight: "15rem" }, position: "bottom-start" }}
                                            options={usersData}
                                            getOptionLabel={(option) => option.name}
                                            renderInput={(params) => <TextField {...params} label="Select Users" variant="outlined" />}
                                        />
                                    </FormControl>
                                </Grid>
                            </>
                        )}

                        {(userDetails?.roles && userDetails.roles.some(role => [ROLENAME.CenterHead, ROLENAME.BusinessHead, ROLENAME.DGM_Sales, ROLENAME.SalesManager, ROLENAME.DesignHead, ROLENAME.Admin, ROLENAME.DataAnalyst].includes(role.name))) && (pivot === "value" || pivot === "total" || pivot === 'salesOverallReview') && (
                            <Grid item xs={12} sm={3}>
                                <FormControl style={{ width: '100%', marginTop: "0.4rem" }}>
                                    <TextField
                                        select
                                        labelId="lost-doc"
                                        label="Lost"
                                        variant="outlined"
                                        value={selectLost}
                                        onChange={(e) => handleSelctLost(e)}
                                    >
                                        <MenuItem value="2">All</MenuItem>
                                        <MenuItem value="1">Yes</MenuItem>
                                        <MenuItem value="0">No</MenuItem>
                                    </TextField>
                                </FormControl>
                            </Grid>
                        )}
                    </Grid>

                    {(pivot !== 'mis' && pivot !== 'leads') &&
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '10px' }}>
                            {/* Search Type */}
                            <TextField
                                select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedSearchType}
                                label="Search Type"
                                name="searchType"
                                onChange={(e) => handleSearchType(e.target.value)}
                                variant="outlined"
                                style={{ width: '30%', marginRight: '2%' }}
                                size="small"
                            >

                                {searchTypes && searchTypes?.map((el, index) => (
                                    <MenuItem key={index} value={el}>{el}</MenuItem>
                                ))}
                            </TextField>

                            {/* Search Input */}
                            <TextField
                                label="Enter the input"
                                name="searched"
                                size="small"
                                value={searchInput}
                                onChange={(e) => { setSearchInput(e.target.value) }}
                                variant="outlined"
                                style={{ width: '80%' }} />

                            {/* Search Button */}
                            <Button
                                disabled={!selectedSearchType || !searchInput}
                                variant="contained"
                                color="primary"
                                size="medium"
                                style={{ alignSelf: "center", marginLeft: 20 }} // Added height
                                className={classes.leadSearchButton}
                                onClick={() => handleSearch(searchInput.toUpperCase())}
                            >
                                Search
                            </Button>

                            {/* Clear Filter */}
                            <Button
                                variant="contained"
                                color="secondary"
                                size="medium"
                                style={{ marginLeft: 10 }}
                                className={classes.leadClearButton}
                                onClick={clearDateFilter}
                            >
                                Clear Filter
                            </Button>
                        </div>
                    }
                </Paper>
            </div>
            <br />
            <div>
                {(pivot === "leads") && (store.length != 0) &&
                    <>
                        <Grid container spacing={2} justifyContent="center" alignItems="center">
                            <Grid item xs={2}>
                                <Button
                                    style={{
                                        backgroundColor: pivot === 'leads' ? '#F50057' : 'inherit',
                                        color: pivot === 'leads' ? 'white' : 'inherit',
                                        marginTop: '20px',

                                    }}
                                >
                                    Total No of Leads Generated
                                </Button>
                            </Grid>
                        </Grid>
                        <div style={{ maxHeight: '500px', overflow: 'auto' }}>
                            <TableContainer component={Paper} classes={{ root: classes.customTableContainer }} style={{ marginTop: '30px' }}>
                                <Table style={{ minWidth: '600px' }}> {/* Ensure a minimum width for horizontal scrolling */}
                                    <TableHead style={{ position: 'sticky', top: 0, zIndex: 1, background: 'white' }}>
                                        <StyledTableRow>
                                            <StyledTableCell className={classes.bordercolors} style={{ position: 'sticky', left: 0, zIndex: 9, textAlign: "center" }}>Lead Generated Month</StyledTableCell>
                                            {store.slice(0, uniqueexperienceCenterCode.length - 1).map((experienceCenterName, index) => (
                                                <StyledTableCell className={classes.bordercolors} key={index}
                                                    style={{
                                                        fontSize: index === uniqueexperienceCenterNames.length - 1 ? '15px' : '13px',
                                                        fontWeight: index === uniqueexperienceCenterNames.length - 1 ? 'bolder' : 'normal',
                                                        textAlign: 'center',
                                                        position: 'sticky',
                                                        cursor: 'pointer',
                                                        top: 0,
                                                        zIndex: 1, // Ensure the same zIndex for all cells
                                                    }}>
                                                    <Tooltip title={`${experienceCenterName.experienceCenterName}-${experienceCenterName.expCtrCityName}`} placement="top">
                                                        <span style={{ cursor: "pointer" }}>
                                                            {experienceCenterName.expCenterCode}
                                                        </span>
                                                    </Tooltip>
                                                </StyledTableCell>
                                            ))}
                                            <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center" }}>
                                                Grand Total
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    </TableHead>
                                    <TableHead style={{ position: 'sticky', top: 0, background: 'white' }}>
                                        {store[0]?.monthlyGrandTotal?.map((month, monthIndex) => (
                                            <StyledTableRow key={monthIndex}>
                                                <StyledTableCell className={classes.bordercolors} style={{ position: 'sticky', left: 0, textAlign: "center" }}>
                                                    {`${month.monthName.slice(0, 3)}-${month.year % 100} `}
                                                </StyledTableCell>
                                                {uniqueexperienceCenterNames.map((experienceCenterName, expCenterIndex) => (
                                                    <>
                                                        {expCenterData[experienceCenterName].map((innerEl, dataIndex) => (
                                                            <StyledTableCell
                                                                className={classes.bordercolors}
                                                                key={dataIndex}
                                                                style={{
                                                                    textAlign: "center",
                                                                    cursor: "pointer",
                                                                    cursor: "pointer",
                                                                    color: "blue",
                                                                    textDecoration: "underline",
                                                                    background: "white",
                                                                    fontWeight: expCenterIndex === uniqueexperienceCenterNames.length - 1 ? 'bolder' : 'normal',
                                                                    color: expCenterIndex === uniqueexperienceCenterNames.length - 1 ? "#004d40" : "blue"
                                                                }}
                                                                onClick={(e) => getDataBasedOnClickMarketing(e, month, innerEl, 'leads')}
                                                            >
                                                                {
                                                                    formatNumberToTwoDecimalPlaces(
                                                                        innerEl.monthlyGrandTotal.find(
                                                                            (entry) => entry.monthName === month.monthName && entry.year === month.year
                                                                        )?.count || 0
                                                                    )
                                                                }
                                                            </StyledTableCell>
                                                        ))}
                                                    </>
                                                ))}
                                            </StyledTableRow>
                                        ))}

                                        <StyledTableRow>
                                            <StyledTableCell className={classes.bordercolors} style={{ position: 'sticky', left: 0, zIndex: 9, textAlign: "center" }}>Grand Total</StyledTableCell>
                                            {uniqueexperienceCenterNames.map((experienceCenterName, index) => (
                                                <StyledTableCell className={classes.bordercolors} key={index} style={{ textAlign: "center", cursor: "pointer", color: "#004d40", zIndex: 1, textDecoration: "underline", fontWeight: 'bolder', background: "white" }} onClick={(e) => getDataBasedOnClickMarketing(e, store[0].monthlyGrandTotal, experienceCenterName, 'total')}>
                                                    {
                                                        formatNumberToTwoDecimalPlaces(store.reduce((total, el) => total + (el.experienceCenterName === experienceCenterName ? el.count : 0), 0))
                                                    }
                                                </StyledTableCell>
                                            ))}
                                        </StyledTableRow>
                                    </TableHead>
                                </Table>
                            </TableContainer>
                        </div>
                    </>
                }
                {(pivot === "value" || pivot === "total") && (store.length != 0) &&
                    <>
                        <Grid container spacing={2} justifyContent="center" alignItems="center">
                            <Grid item xs={2} >
                                <Button
                                    style={{
                                        backgroundColor: pivot === 'value' ? '#F50057' : 'inherit',
                                        color: pivot === 'value' ? 'white' : 'inherit',
                                        marginBottom: '10px',

                                    }}
                                >
                                    Sales Summary-Values
                                </Button>
                            </Grid>
                        </Grid>
                        <div style={{ maxHeight: '500px', overflow: 'auto' }}>
                            <TableContainer component={Paper} classes={{ root: classes.customTableContainer }} style={{ marginTop: '30px' }}>
                                <Table style={{ minWidth: '600px' }}> {/* Ensure a minimum width for horizontal scrolling */}
                                    <TableHead style={{ position: 'sticky', top: 0, zIndex: 1, background: 'white' }}>
                                        <StyledTableRow>
                                            <StyledTableCell className={classes.bordercolors} style={{ position: 'sticky', left: 0, zIndex: 9, textAlign: "center" }}>Sales Month</StyledTableCell>
                                            {store.slice(0, uniqueexperienceCenterCode.length - 1).map((experienceCenterName, index) => (
                                                <StyledTableCell
                                                    className={classes.bordercolors}
                                                    key={index}
                                                    style={{
                                                        fontSize: index === uniqueexperienceCenterNames.length - 1 ? '15px' : '13px',
                                                        fontWeight: index === uniqueexperienceCenterNames.length - 1 ? 'bolder' : 'normal',
                                                        textAlign: 'center',
                                                        position: 'sticky',
                                                        top: 0,
                                                        zIndex: 1, // Ensure the same zIndex for all cells
                                                    }}
                                                >
                                                    <Tooltip title={`${experienceCenterName.experienceCenterName}-${experienceCenterName.expCtrCityName}`} placement="top">
                                                        <span style={{ cursor: "pointer" }}>
                                                            {experienceCenterName.expCenterCode}
                                                        </span>
                                                    </Tooltip>
                                                </StyledTableCell>
                                            ))}
                                            <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center" }}>
                                                Grand Total
                                            </StyledTableCell>


                                        </StyledTableRow>
                                    </TableHead>

                                    <TableHead style={{ position: 'sticky', top: 0, background: 'white' }}>
                                        {store[0]?.monthlyGrandTotal?.map((month, monthIndex) => (
                                            <StyledTableRow key={monthIndex}>
                                                <StyledTableCell className={classes.bordercolors} style={{ position: 'sticky', left: 0, textAlign: "center" }}>
                                                    {`${month.monthName.slice(0, 3)}-${month.year % 100} `}
                                                </StyledTableCell>

                                                {uniqueexperienceCenterNames.map((experienceCenterName, expCenterIndex) => (
                                                    <>
                                                        {expCenterData[experienceCenterName].map((innerEl, dataIndex) => (
                                                            <StyledTableCell
                                                                className={classes.bordercolors}
                                                                key={dataIndex}
                                                                style={{
                                                                    textAlign: "center",
                                                                    cursor: "pointer",
                                                                    top: 0,
                                                                    background: "white",
                                                                    fontWeight: expCenterIndex === uniqueexperienceCenterNames.length - 1 ? 'bolder' : 'normal',
                                                                    color: expCenterIndex === uniqueexperienceCenterNames.length - 1 ? "#004d40" : "blue"
                                                                }}
                                                                onClick={(e) => getDataBasedOnClickSales(e, month, innerEl, 'value')}
                                                            >
                                                                {
                                                                    formatNumberToTwoDecimalPlaces(
                                                                        innerEl.monthlyGrandTotal.find(
                                                                            (entry) => entry.monthName === month.monthName && entry.year === month.year
                                                                        )?.totalGrandTotal || 0
                                                                    )
                                                                }
                                                            </StyledTableCell>
                                                        ))}
                                                    </>
                                                ))}
                                            </StyledTableRow>
                                        ))}

                                        <StyledTableRow>
                                            <StyledTableCell className={classes.bordercolors} style={{ position: 'sticky', left: 0, zIndex: 9, textAlign: "center" }}>Grand Total</StyledTableCell>
                                            {uniqueexperienceCenterNames.map((experienceCenterName, index) => (
                                                <StyledTableCell className={classes.bordercolors} key={index} style={{ textAlign: "center", cursor: "pointer", color: "#004d40", zIndex: 1, textDecoration: "underline", fontWeight: 'bolder', background: "white" }} onClick={(e) => getDataBasedOnClickSales(e, store[0].monthlyGrandTotal, experienceCenterName, 'total')}>
                                                    {
                                                        formatNumberToTwoDecimalPlaces(store.reduce((total, el) => total + (el.experienceCenterName === experienceCenterName ? el.grandTotal : 0), 0))
                                                    }
                                                </StyledTableCell>
                                            ))}
                                        </StyledTableRow>
                                    </TableHead>
                                </Table>
                            </TableContainer>
                        </div>


                        <Grid container spacing={2} justifyContent="center" alignItems="center">
                            <Grid item xs={2}>
                                <Button
                                    style={{
                                        backgroundColor: pivot === 'value' ? '#F50057' : 'inherit',
                                        color: pivot === 'value' ? 'white' : 'inherit',
                                        marginTop: '20px',

                                    }}
                                >
                                    Total No of Customers
                                </Button>
                            </Grid>
                        </Grid>
                        <div style={{ maxHeight: '500px', overflow: 'auto' }}>
                            <TableContainer component={Paper} classes={{ root: classes.customTableContainer }} style={{ marginTop: '30px' }}>
                                <Table style={{ minWidth: '600px' }}> {/* Ensure a minimum width for horizontal scrolling */}
                                    <TableHead style={{ position: 'sticky', top: 0, zIndex: 1, background: 'white' }}>
                                        <StyledTableRow>
                                            <StyledTableCell className={classes.bordercolors} style={{ position: 'sticky', left: 0, zIndex: 9, textAlign: "center" }}>Sales Month</StyledTableCell>
                                            {store.slice(0, uniqueexperienceCenterCode.length - 1).map((experienceCenterName, index) => (
                                                <StyledTableCell className={classes.bordercolors} key={index}
                                                    style={{
                                                        fontSize: index === uniqueexperienceCenterNames.length - 1 ? '15px' : '13px',
                                                        fontWeight: index === uniqueexperienceCenterNames.length - 1 ? 'bolder' : 'normal',
                                                        textAlign: 'center',
                                                        position: 'sticky',
                                                        top: 0,
                                                        zIndex: 1, // Ensure the same zIndex for all cells
                                                    }}>
                                                    <Tooltip title={`${experienceCenterName.experienceCenterName}-${experienceCenterName.expCtrCityName}`} placement="top">
                                                        <span style={{ cursor: "pointer" }}>
                                                            {experienceCenterName.expCenterCode}
                                                        </span>
                                                    </Tooltip>
                                                </StyledTableCell>
                                            ))}
                                            <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center" }}>
                                                Grand Total
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    </TableHead>
                                    <TableHead style={{ position: 'sticky', top: 0, background: 'white' }}>
                                        {store[0]?.monthlyGrandTotal?.map((month, monthIndex) => (
                                            <StyledTableRow key={monthIndex}>
                                                <StyledTableCell className={classes.bordercolors} style={{ position: 'sticky', left: 0, textAlign: "center" }}>
                                                    {`${month.monthName.slice(0, 3)}-${month.year % 100} `}
                                                </StyledTableCell>
                                                {uniqueexperienceCenterNames.map((experienceCenterName, expCenterIndex) => (
                                                    <>
                                                        {expCenterData[experienceCenterName].map((innerEl, dataIndex) => (
                                                            <StyledTableCell
                                                                className={classes.bordercolors}
                                                                key={dataIndex}
                                                                style={{
                                                                    textAlign: "center",
                                                                    cursor: "pointer",
                                                                    cursor: "pointer",
                                                                    color: "blue",
                                                                    textDecoration: "underline",
                                                                    background: "white",
                                                                    fontWeight: expCenterIndex === uniqueexperienceCenterNames.length - 1 ? 'bolder' : 'normal',
                                                                    color: expCenterIndex === uniqueexperienceCenterNames.length - 1 ? "#004d40" : "blue"
                                                                }}
                                                                onClick={(e) => getDataBasedOnClickSales(e, month, innerEl, 'value')}
                                                            >
                                                                {
                                                                    formatNumberToTwoDecimalPlaces(
                                                                        innerEl.monthlyGrandTotal.find(
                                                                            (entry) => entry.monthName === month.monthName && entry.year === month.year
                                                                        )?.count || 0
                                                                    )
                                                                }
                                                            </StyledTableCell>
                                                        ))}
                                                    </>
                                                ))}
                                            </StyledTableRow>
                                        ))}

                                        <StyledTableRow>
                                            <StyledTableCell className={classes.bordercolors} style={{ position: 'sticky', left: 0, zIndex: 9, textAlign: "center" }}>Grand Total</StyledTableCell>
                                            {uniqueexperienceCenterNames.map((experienceCenterName, index) => (
                                                <StyledTableCell className={classes.bordercolors} key={index} style={{ textAlign: "center", cursor: "pointer", color: "#004d40", zIndex: 1, textDecoration: "underline", fontWeight: 'bolder', background: "white" }} onClick={(e) => getDataBasedOnClickSales(e, store[0].monthlyGrandTotal, experienceCenterName, 'total')}>
                                                    {
                                                        formatNumberToTwoDecimalPlaces(store.reduce((total, el) => total + (el.experienceCenterName === experienceCenterName ? el.count : 0), 0))
                                                    }
                                                </StyledTableCell>
                                            ))}
                                        </StyledTableRow>
                                    </TableHead>
                                </Table>
                            </TableContainer>
                        </div>
                    </>
                }
                {(pivot === "designSignOffReview") && (store.length != 0) &&
                    <>
                        <Grid container spacing={2} justifyContent="center" alignItems="center">
                            <Grid item xs={2} >
                                <Button
                                    style={{
                                        backgroundColor: pivot === 'designSignOffReview' ? '#F50057' : 'inherit',
                                        color: pivot === 'designSignOffReview' ? 'white' : 'inherit',
                                        marginBottom: '20px',
                                        marginTop: '30px',
                                    }}
                                >
                                    Design Sign-Off - Values
                                </Button>
                            </Grid>
                        </Grid>
                        <div style={{ maxHeight: '500px', overflow: 'auto' }}>
                            <TableContainer component={Paper} classes={{ root: classes.customTableContainer }} style={{ marginTop: '30px' }}>
                                <Table style={{ minWidth: '600px' }}> {/* Ensure a minimum width for horizontal scrolling */}
                                    <TableHead style={{ position: 'sticky', top: 0, zIndex: 1, background: 'white' }}>
                                        <StyledTableRow>
                                            <StyledTableCell className={classes.bordercolors} style={{ position: 'sticky', left: 0, zIndex: 9 }}>Design Sign-Off Month</StyledTableCell>
                                            {uniqueexperienceCenterNames.map((experienceCenterName, index) => (
                                                <StyledTableCell
                                                    className={classes.bordercolors}
                                                    key={index}
                                                    style={{
                                                        fontSize: index === uniqueexperienceCenterNames.length - 1 ? '15px' : '13px',
                                                        fontWeight: index === uniqueexperienceCenterNames.length - 1 ? 'bolder' : 'normal',
                                                        textAlign: 'center',
                                                        position: 'sticky',
                                                        top: 0,
                                                        zIndex: 1, // Ensure the same zIndex for all cells
                                                    }}
                                                >
                                                    {experienceCenterName}
                                                </StyledTableCell>
                                            ))}
                                        </StyledTableRow>
                                    </TableHead>

                                    <TableHead style={{ position: 'sticky', top: 0, background: 'white' }}>
                                        {store[0]?.monthlyGrandTotal?.map((month, monthIndex) => (
                                            <StyledTableRow key={monthIndex}>
                                                <StyledTableCell className={classes.bordercolors} style={{ position: 'sticky', left: 0 }}>
                                                    {month.monthName} {month.year}
                                                </StyledTableCell>

                                                {uniqueexperienceCenterNames.map((experienceCenterName, expCenterIndex) => (
                                                    <>
                                                        {expCenterData[experienceCenterName].map((innerEl, dataIndex) => (
                                                            <StyledTableCell
                                                                className={classes.bordercolors}
                                                                key={dataIndex}
                                                                style={{
                                                                    textAlign: "center",
                                                                    cursor: "pointer",
                                                                    top: 0,
                                                                    background: "white",
                                                                    fontWeight: expCenterIndex === uniqueexperienceCenterNames.length - 1 ? 'bolder' : 'normal',
                                                                    color: expCenterIndex === uniqueexperienceCenterNames.length - 1 ? "#004d40" : "blue"
                                                                }}
                                                                onClick={(e) => getDataBasedOnClickDesignSignOff(e, month, innerEl, 'designSignOffReview')}
                                                            >
                                                                {
                                                                    formatNumberToTwoDecimalPlaces(
                                                                        innerEl.monthlyGrandTotal.find(
                                                                            (entry) => entry.monthName === month.monthName && entry.year === month.year
                                                                        )?.totalGrandTotal || 0
                                                                    )
                                                                }
                                                            </StyledTableCell>
                                                        ))}
                                                    </>
                                                ))}
                                            </StyledTableRow>
                                        ))}
                                        <StyledTableRow>
                                            <StyledTableCell className={classes.bordercolors} style={{ position: 'sticky', left: 0, zIndex: 9 }}>Grand Total</StyledTableCell>
                                            {uniqueexperienceCenterNames.map((experienceCenterName, index) => (
                                                <StyledTableCell className={classes.bordercolors} key={index} style={{ textAlign: "center", cursor: "pointer", color: "#004d40", zIndex: 1, textDecoration: "underline", fontWeight: 'bolder', background: "white" }} onClick={(e) => getDataBasedOnClickDesignSignOff(e, store[0].monthlyGrandTotal, experienceCenterName, 'total')}>
                                                    {
                                                        formatNumberToTwoDecimalPlaces(store.reduce((total, el) => total + (el.experienceCenterName === experienceCenterName ? el.grandTotal : 0), 0))
                                                    }
                                                </StyledTableCell>
                                            ))}
                                        </StyledTableRow>
                                    </TableHead>
                                </Table>
                            </TableContainer>
                        </div>


                        <Grid container spacing={2} justifyContent="center" alignItems="center">
                            <Grid item xs={2}>
                                <Button
                                    style={{
                                        backgroundColor: pivot === 'designSignOffReview' ? '#F50057' : 'inherit',
                                        color: pivot === 'designSignOffReview' ? 'white' : 'inherit',
                                        marginBottom: '20px',
                                        marginTop: '20px',

                                    }}
                                >
                                    Total No of Customers
                                </Button>
                            </Grid>
                        </Grid>
                        <div style={{ maxHeight: '500px', overflow: 'auto' }}>
                            <TableContainer component={Paper} classes={{ root: classes.customTableContainer }} style={{ marginTop: '30px' }}>
                                <Table style={{ minWidth: '600px' }}> {/* Ensure a minimum width for horizontal scrolling */}
                                    <TableHead style={{ position: 'sticky', top: 0, zIndex: 1, background: 'white' }}>
                                        <StyledTableRow>
                                            <StyledTableCell className={classes.bordercolors} style={{ position: 'sticky', left: 0, zIndex: 9 }}>Design Sign-Off Month</StyledTableCell>
                                            {uniqueexperienceCenterNames.map((experienceCenterName, index) => (
                                                <StyledTableCell className={classes.bordercolors} key={index}
                                                    style={{
                                                        fontSize: index === uniqueexperienceCenterNames.length - 1 ? '15px' : '13px',
                                                        fontWeight: index === uniqueexperienceCenterNames.length - 1 ? 'bolder' : 'normal',
                                                        textAlign: 'center',
                                                        position: 'sticky',
                                                        top: 0,
                                                        zIndex: 1, // Ensure the same zIndex for all cells
                                                    }}>{experienceCenterName}</StyledTableCell>
                                            ))}
                                        </StyledTableRow>
                                    </TableHead>
                                    <TableHead style={{ position: 'sticky', top: 0, background: 'white' }}>
                                        {store[0]?.monthlyGrandTotal?.map((month, monthIndex) => (
                                            <StyledTableRow key={monthIndex}>
                                                <StyledTableCell className={classes.bordercolors} style={{ position: 'sticky', left: 0 }}>
                                                    {month.monthName} {month.year}</StyledTableCell>
                                                {uniqueexperienceCenterNames.map((experienceCenterName, expCenterIndex) => (
                                                    <>
                                                        {expCenterData[experienceCenterName].map((innerEl, dataIndex) => (
                                                            <StyledTableCell
                                                                className={classes.bordercolors}
                                                                key={dataIndex}
                                                                style={{
                                                                    textAlign: "center",
                                                                    cursor: "pointer",
                                                                    cursor: "pointer",
                                                                    color: "blue",
                                                                    textDecoration: "underline",
                                                                    background: "white",
                                                                    fontWeight: expCenterIndex === uniqueexperienceCenterNames.length - 1 ? 'bolder' : 'normal',
                                                                    color: expCenterIndex === uniqueexperienceCenterNames.length - 1 ? "#004d40" : "blue"
                                                                }}
                                                                onClick={(e) => getDataBasedOnClickDesignSignOff(e, month, innerEl, 'designSignOffReview')}
                                                            >
                                                                {
                                                                    formatNumberToTwoDecimalPlaces(
                                                                        innerEl.monthlyGrandTotal.find(
                                                                            (entry) => entry.monthName === month.monthName && entry.year === month.year
                                                                        )?.count || 0
                                                                    )
                                                                }
                                                            </StyledTableCell>
                                                        ))}
                                                    </>
                                                ))}
                                            </StyledTableRow>
                                        ))}

                                        <StyledTableRow>
                                            <StyledTableCell className={classes.bordercolors} style={{ position: 'sticky', left: 0, zIndex: 9 }}>Grand Total</StyledTableCell>
                                            {uniqueexperienceCenterNames.map((experienceCenterName, index) => (
                                                <StyledTableCell className={classes.bordercolors} key={index} style={{ textAlign: "center", cursor: "pointer", color: "#004d40", zIndex: 1, textDecoration: "underline", fontWeight: 'bolder', background: "white" }} onClick={(e) => getDataBasedOnClickDesignSignOff(e, store[0].monthlyGrandTotal, experienceCenterName, 'total')}>
                                                    {
                                                        formatNumberToTwoDecimalPlaces(store.reduce((total, el) => total + (el.experienceCenterName === experienceCenterName ? el.count : 0), 0))
                                                    }
                                                </StyledTableCell>
                                            ))}
                                        </StyledTableRow>
                                    </TableHead>
                                </Table>
                            </TableContainer>
                        </div>
                    </>
                }
                {(pivot === "overallsummary" || pivot === "overallReview" || pivot === "designHoldAnalysis") &&
                    <>
                        <div style={{ maxHeight: '600px', overflow: 'auto' }}>
                            <TableContainer component={Paper} classes={{ root: classes.customTableContainer }} style={{ marginTop: '30px' }}>
                                <Table style={{ minWidth: '600px' }}>
                                    <TableHead style={{ position: 'sticky', top: 0, zIndex: 9, background: 'white' }}>
                                        <StyledTableRow>
                                            {
                                                pivot === "overallReview" ?
                                                    <>
                                                        <StyledTableCell className={classes.bordercolors} style={{ position: 'sticky', left: 0, zIndex: 9 }} rowSpan={2}>Users</StyledTableCell>
                                                    </> :
                                                    <StyledTableCell className={classes.bordercolors} style={{ position: 'sticky', left: 0, zIndex: 9, textAlign: "center" }} rowSpan={2}>Month And Year</StyledTableCell>
                                            }
                                            
                                        {pivot === "designHoldAnalysis" ? (
                                            <>
                                                <StyledTableCell colSpan={2} className={classes.bordercolors} style={{ textAlign: 'center' }}>Design Hold Pending Approval</StyledTableCell>
                                                <StyledTableCell colSpan={2} className={classes.bordercolors} style={{ textAlign: 'center' }}>Possesion Delay</StyledTableCell>
                                                <StyledTableCell colSpan={2} className={classes.bordercolors} style={{ textAlign: 'center' }}>Cx not available</StyledTableCell>
                                                <StyledTableCell colSpan={2} className={classes.bordercolors} style={{ textAlign: 'center' }}>Cx having Financial Issue</StyledTableCell>
                                                <StyledTableCell colSpan={2} className={classes.bordercolors} style={{ textAlign: 'center' }}>Cx wants to put the projects on hold</StyledTableCell>
                                                <StyledTableCell colSpan={2} className={classes.bordercolors} style={{ textAlign: 'center' }}>Probable Loss</StyledTableCell>
                                                <StyledTableCell colSpan={2} className={classes.bordercolors} style={{ textAlign: 'center' }}>Others</StyledTableCell>                                                    <StyledTableCell colSpan={2} className={classes.bordercolors} style={{
                                                    fontSize: '15px',
                                                    textAlign: 'center'
                                                }}>Grand Total</StyledTableCell>
                                            </>
                                        ) : (
                                            <>
                                                <StyledTableCell colSpan={2} className={classes.bordercolors} style={{ textAlign: 'center' }}>Designer Not Assigned</StyledTableCell>
                                                <StyledTableCell colSpan={2} className={classes.bordercolors} style={{ textAlign: 'center' }}>Design Kick Off</StyledTableCell>
                                                <StyledTableCell colSpan={2} className={classes.bordercolors} style={{ textAlign: 'center' }}>Design</StyledTableCell>
                                                <StyledTableCell colSpan={2} className={classes.bordercolors} style={{ textAlign: 'center' }}>Design-Hold</StyledTableCell>
                                                <StyledTableCell colSpan={2} className={classes.bordercolors} style={{ textAlign: 'center' }}>Completed</StyledTableCell>
                                                {/* <StyledTableCell colSpan={2} className={classes.bordercolors} style={{ textAlign: 'center' }}>Design Hold - Possession more than 3 months</StyledTableCell> */}
                                                <StyledTableCell colSpan={2} className={classes.bordercolors} style={{ textAlign: 'center' }}>Lost</StyledTableCell>
                                                <StyledTableCell colSpan={2} className={classes.bordercolors} style={{
                                                    fontSize: '15px',
                                                    textAlign: 'center'
                                                }}>Grand Total</StyledTableCell>
                                            </>
                                        )}                                   
                                        </StyledTableRow>
                                        <StyledTableRow>
                                        {pivot === "designHoldAnalysis" ? (
                                            <>
                                            <StyledTableCell className={classes.bordercolors} style={{ textAlign: 'center' }}>Count</StyledTableCell>
                                            <StyledTableCell className={classes.bordercolors} style={{ textAlign: 'center' }}>Value</StyledTableCell>
                                            <StyledTableCell className={classes.bordercolors} style={{ textAlign: 'center' }}>Count</StyledTableCell>
                                            <StyledTableCell className={classes.bordercolors} style={{ textAlign: 'center' }}>Value</StyledTableCell>
                                            <StyledTableCell className={classes.bordercolors} style={{ textAlign: 'center' }}>Count</StyledTableCell>
                                            <StyledTableCell className={classes.bordercolors} style={{ textAlign: 'center' }}>Value</StyledTableCell>
                                            <StyledTableCell className={classes.bordercolors} style={{ textAlign: 'center' }}>Count</StyledTableCell>
                                            <StyledTableCell className={classes.bordercolors} style={{ textAlign: 'center' }}>Value</StyledTableCell>
                                            <StyledTableCell className={classes.bordercolors} style={{ textAlign: 'center' }}>Count</StyledTableCell>
                                            <StyledTableCell className={classes.bordercolors} style={{ textAlign: 'center' }}>Value</StyledTableCell>
                                            <StyledTableCell className={classes.bordercolors} style={{ textAlign: 'center' }}>Count</StyledTableCell>
                                            <StyledTableCell className={classes.bordercolors} style={{ textAlign: 'center' }}>Value</StyledTableCell>
                                            <StyledTableCell className={classes.bordercolors} style={{ textAlign: 'center' }}>Count</StyledTableCell>
                                            <StyledTableCell className={classes.bordercolors} style={{ textAlign: 'center' }}>Value</StyledTableCell>
                                            <StyledTableCell className={classes.bordercolors} style={{ textAlign: 'center' }}>Count</StyledTableCell>
                                            <StyledTableCell className={classes.bordercolors} style={{ textAlign: 'center' }}>Value</StyledTableCell>
                                            </>
                                        ) : (
                                            <>
                                            <StyledTableCell className={classes.bordercolors}>Count</StyledTableCell>
                                            <StyledTableCell className={classes.bordercolors}>Value</StyledTableCell>
                                            <StyledTableCell className={classes.bordercolors}>Count</StyledTableCell>
                                            <StyledTableCell className={classes.bordercolors}>Value</StyledTableCell>
                                            <StyledTableCell className={classes.bordercolors}>Count</StyledTableCell>
                                            <StyledTableCell className={classes.bordercolors}>Value</StyledTableCell>
                                            <StyledTableCell className={classes.bordercolors}>Count</StyledTableCell>
                                            <StyledTableCell className={classes.bordercolors}>Value</StyledTableCell>
                                            {/* <StyledTableCell className={classes.bordercolors}>Count</StyledTableCell>
                                            <StyledTableCell className={classes.bordercolors}>Value</StyledTableCell> */}
                                            <StyledTableCell className={classes.bordercolors}>Count</StyledTableCell>
                                            <StyledTableCell className={classes.bordercolors}>Value</StyledTableCell>
                                            <StyledTableCell className={classes.bordercolors}>Count</StyledTableCell>
                                            <StyledTableCell className={classes.bordercolors}>Value</StyledTableCell>
                                            <StyledTableCell className={classes.bordercolors}>Count</StyledTableCell>
                                            <StyledTableCell className={classes.bordercolors}>Value</StyledTableCell> 
                                            </>
                                        )}
                                        </StyledTableRow>
                                    </TableHead>
                                    <TableHead style={{ position: 'sticky', top: 0, background: 'white' }}>
                                        {store.map((ele, index) => (
                                            <StyledTableRow key={index}>
                                                {
                                                    pivot === "overallReview" ?
                                                        <>
                                                            <StyledTableCell className={classes.bordercolors} style={{
                                                                fontSize: index === store.length - 1 ? '15px' : '13px',
                                                                fontWeight: index === store.length - 1 ? 'bolder' : 'normal',
                                                                left: 0,
                                                                position: 'sticky',
                                                            }}>{ele.userName}</StyledTableCell>
                                                        </> :
                                                        <>
                                                            {index === store.length - 1 ?
                                                                <StyledTableCell className={classes.bordercolors} style={{
                                                                    fontSize: index === store.length - 1 ? '15px' : '13px',
                                                                    fontWeight: index === store.length - 1 ? 'bolder' : 'normal',
                                                                    left: 0,
                                                                    position: 'sticky',
                                                                    textAlign: 'center'
                                                                }}>{ele?._id?.year}</StyledTableCell> :
                                                                <StyledTableCell className={classes.bordercolors} style={{
                                                                    fontSize: index === store.length - 1 ? '15px' : '13px',
                                                                    fontWeight: index === store.length - 1 ? 'bolder' : 'normal',
                                                                    left: 0,
                                                                    position: 'sticky',
                                                                    textAlign: "center"
                                                                }}>
                                                                    {months[ele?.month]}-{ele?._id?.year % 100}
                                                                    {/* {ele?.month}/{ele?._id?.year % 100} */}
                                                                </StyledTableCell>
                                                            }
                                                        </>
                                                }

                                                {pivot === "designHoldAnalysis" ? (
                                                    <>
                                                        <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", color: index !== store.length - 1 ? "blue" : "#004d40", textDecoration: "underline", fontWeight: index !== store.length - 1 ? 'normal' : 'bolder', background: "white" }} onClick={(e) => dataBasedOnClick(e, ele, "Design-Hold Pending Approval")}>{ele.designHoldPendingApprovalCount}</StyledTableCell>
                                                        <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", color: index !== store.length - 1 ? "blue" : "#004d40", textDecoration: "underline", fontWeight: index !== store.length - 1 ? 'normal' : 'bolder', background: "white" }} onClick={(e) => dataBasedOnClick(e, ele, "Design-Hold Pending Approval")}>{(ele.designHoldPendingApprovalGrand)?.toFixed(2)}</StyledTableCell>
                                                        <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", color: index !== store.length - 1 ? "blue" : "#004d40", textDecoration: "underline", fontWeight: index !== store.length - 1 ? 'normal' : 'bolder', background: "white" }} onClick={(e) => dataBasedOnClick(e, ele, "Possesion Delay")}>{ele.possesionDelayCount}</StyledTableCell>
                                                        <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", color: index !== store.length - 1 ? "blue" : "#004d40", textDecoration: "underline", fontWeight: index !== store.length - 1 ? 'normal' : 'bolder', background: "white" }} onClick={(e) => dataBasedOnClick(e, ele, "Possesion Delay")}>{(ele.possesionDelayGrand)?.toFixed(2)}</StyledTableCell>
                                                        <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", color: index !== store.length - 1 ? "blue" : "#004d40", textDecoration: "underline", fontWeight: index !== store.length - 1 ? 'normal' : 'bolder', background: "white" }} onClick={(e) => dataBasedOnClick(e, ele, "Cx not available")}>{ele.cxNotAvailableCount}</StyledTableCell>
                                                        <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", color: index !== store.length - 1 ? "blue" : "#004d40", textDecoration: "underline", fontWeight: index !== store.length - 1 ? 'normal' : 'bolder', background: "white" }} onClick={(e) => dataBasedOnClick(e, ele, "Cx not available")}>{(ele.cxNotAvailableGrand)?.toFixed(2)}</StyledTableCell>
                                                        <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", color: index !== store.length - 1 ? "blue" : "#004d40", textDecoration: "underline", fontWeight: index !== store.length - 1 ? 'normal' : 'bolder', background: "white" }} onClick={(e) => dataBasedOnClick(e, ele, "Cx Financial Issue")}>{ele.cxHaveFinancialIssueCount}</StyledTableCell>
                                                        <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", color: index !== store.length - 1 ? "blue" : "#004d40", textDecoration: "underline", fontWeight: index !== store.length - 1 ? 'normal' : 'bolder', background: "white" }} onClick={(e) => dataBasedOnClick(e, ele, "Cx Financial Issue")}>{(ele.cxHaveFinancialIssueGrand)?.toFixed(2)}</StyledTableCell>
                                                        <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", color: index !== store.length - 1 ? "blue" : "#004d40", textDecoration: "underline", fontWeight: index !== store.length - 1 ? 'normal' : 'bolder', background: "white" }} onClick={(e) => dataBasedOnClick(e, ele, "Cx wants projects on hold")}>{ele.cxProjectOnHoldCount}</StyledTableCell>
                                                        <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", color: index !== store.length - 1 ? "blue" : "#004d40", textDecoration: "underline", fontWeight: index !== store.length - 1 ? 'normal' : 'bolder', background: "white" }} onClick={(e) => dataBasedOnClick(e, ele, "Cx wants projects on hold")}>{(ele.cxProjectOnHoldGrand)?.toFixed(2)}</StyledTableCell>
                                                        <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", color: index !== store.length - 1 ? "blue" : "#004d40", textDecoration: "underline", fontWeight: index !== store.length - 1 ? 'normal' : 'bolder', background: "white" }} onClick={(e) => dataBasedOnClick(e, ele, "Probable Loss")}>{ele.probableLossCount}</StyledTableCell>
                                                        <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", color: index !== store.length - 1 ? "blue" : "#004d40", textDecoration: "underline", fontWeight: index !== store.length - 1 ? 'normal' : 'bolder', background: "white" }} onClick={(e) => dataBasedOnClick(e, ele, "Probable Loss")}>{(ele.probableLossGrand)?.toFixed(2)}</StyledTableCell>
                                                        <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", color: index !== store.length - 1 ? "blue" : "#004d40", textDecoration: "underline", fontWeight: index !== store.length - 1 ? 'normal' : 'bolder', background: "white" }} onClick={(e) => dataBasedOnClick(e, ele, "Others")}>{ele.othersCount}</StyledTableCell>
                                                        <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", color: index !== store.length - 1 ? "blue" : "#004d40", textDecoration: "underline", fontWeight: index !== store.length - 1 ? 'normal' : 'bolder', background: "white" }} onClick={(e) => dataBasedOnClick(e, ele, "Others")}>{(ele.othersGrand)?.toFixed(2)}</StyledTableCell>
                                                        <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", cursor: "pointer", color: "#004d40", textDecoration: "underline", fontWeight: 'bolder', background: "white", textAlign: "center" }} onClick={(e) => dataBasedOnClick(e, ele, "designHoldAnalysisTotal")}>{ele?.designHoldAnalysisTotalCount}</StyledTableCell>
                                                        <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", cursor: "pointer", color: "#004d40", textDecoration: "underline", fontWeight: 'bolder', background: "white", textAlign: "center" }} onClick={(e) => dataBasedOnClick(e, ele, "designHoldAnalysisTotal")}>{(ele?.designHoldAnalysisGrandTotal)?.toFixed(2)}</StyledTableCell>
                                                    </>) : (
                                                     <>
                                                        <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", cursor: "pointer", color: index !== store.length - 1 ? "blue" : "#004d40", textDecoration: "underline", fontWeight: index !== store.length - 1 ? 'normal' : 'bolder', background: "white" }} onClick={(e) => dataBasedOnClick(e, ele, "designerNotAssigned")}>{ele.designerNotAssignedCount}</StyledTableCell>
                                                        <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", cursor: "pointer", color: index !== store.length - 1 ? "blue" : "#004d40", textDecoration: "underline", fontWeight: index !== store.length - 1 ? 'normal' : 'bolder', background: "white" }} onClick={(e) => dataBasedOnClick(e, ele, "designerNotAssigned")}>{(ele.designerNotAssigned)?.toFixed(2)}</StyledTableCell>
                                                        <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", cursor: "pointer", color: index !== store.length - 1 ? "blue" : "#004d40", textDecoration: "underline", fontWeight: index !== store.length - 1 ? 'normal' : 'bolder', background: "white" }} onClick={(e) => dataBasedOnClick(e, ele, "designKickOff")}>{(ele.designKickOffCount)}</StyledTableCell>
                                                        <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", cursor: "pointer", color: index !== store.length - 1 ? "blue" : "#004d40", textDecoration: "underline", fontWeight: index !== store.length - 1 ? 'normal' : 'bolder', background: "white" }} onClick={(e) => dataBasedOnClick(e, ele, "designKickOff")}>{(ele.designKickOff)?.toFixed(2)}</StyledTableCell>
                                                        <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", cursor: "pointer", color: index !== store.length - 1 ? "blue" : "#004d40", textDecoration: "underline", fontWeight: index !== store.length - 1 ? 'normal' : 'bolder', background: "white" }} onClick={(e) => dataBasedOnClick(e, ele, "design")}>{ele.designCount}</StyledTableCell>
                                                        <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", cursor: "pointer", color: index !== store.length - 1 ? "blue" : "#004d40", textDecoration: "underline", fontWeight: index !== store.length - 1 ? 'normal' : 'bolder', background: "white" }} onClick={(e) => dataBasedOnClick(e, ele, "design")}>{(ele.design)?.toFixed(2)}</StyledTableCell>
                                                        <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", cursor: "pointer", color: index !== store.length - 1 ? "blue" : "#004d40", textDecoration: "underline", fontWeight: index !== store.length - 1 ? 'normal' : 'bolder', background: "white" }} onClick={(e) => dataBasedOnClick(e, ele, "design-Hold")}>{ele.designHoldcount}</StyledTableCell>
                                                        <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", cursor: "pointer", color: index !== store.length - 1 ? "blue" : "#004d40", textDecoration: "underline", fontWeight: index !== store.length - 1 ? 'normal' : 'bolder', background: "white" }} onClick={(e) => dataBasedOnClick(e, ele, "design-Hold")}>{(ele.designHoldGrand)?.toFixed(2)}</StyledTableCell>
                                                        <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", cursor: "pointer", color: index !== store.length - 1 ? "blue" : "#004d40", textDecoration: "underline", fontWeight: index !== store.length - 1 ? 'normal' : 'bolder', background: "white" }} onClick={(e) => dataBasedOnClick(e, ele, "completed")}>{ele.completedCount}</StyledTableCell>
                                                        <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", cursor: "pointer", color: index !== store.length - 1 ? "blue" : "#004d40", textDecoration: "underline", fontWeight: index !== store.length - 1 ? 'normal' : 'bolder', background: "white" }} onClick={(e) => dataBasedOnClick(e, ele, "completed")}>{(ele.completed)?.toFixed(2)}</StyledTableCell>
                                                        <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", cursor: "pointer", color: index !== store.length - 1 ? "blue" : "#004d40", textDecoration: "underline", fontWeight: index !== store.length - 1 ? 'normal' : 'bolder', background: "white" }} onClick={(e) => dataBasedOnClick(e, ele, "lost")}>{ele.lostcount}</StyledTableCell>
                                                        <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", cursor: "pointer", color: index !== store.length - 1 ? "blue" : "#004d40", textDecoration: "underline", fontWeight: index !== store.length - 1 ? 'normal' : 'bolder', background: "white" }} onClick={(e) => dataBasedOnClick(e, ele, "lost")}>{(ele.lost)?.toFixed(2)}</StyledTableCell>
                                                        {/* <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", cursor: "pointer", color: index !== store.length - 1 ? "blue" : "#004d40", textDecoration: "underline", fontWeight: index !== store.length - 1 ? 'normal' : 'bolder', background: "white" }} onClick={(e) => dataBasedOnClick(e, ele, "Design Hold - Possession more than 3 months")}>{ele.designPossesionHoldCount}</StyledTableCell> */}
                                                        {/* <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", cursor: "pointer", color: index !== store.length - 1 ? "blue" : "#004d40", textDecoration: "underline", fontWeight: index !== store.length - 1 ? 'normal' : 'bolder', background: "white" }} onClick={(e) => dataBasedOnClick(e, ele, "Design Hold - Possession more than 3 months")}>{(ele.designPossesionHoldGrand)?.toFixed(2)}</StyledTableCell> */}
                                                        <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", cursor: "pointer", color: "#004d40", textDecoration: "underline", fontWeight: 'bolder', background: "white", textAlign: "center" }} onClick={(e) => dataBasedOnClick(e, ele, "total")}>{ele?.totalCount}</StyledTableCell>
                                                        <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", cursor: "pointer", color: "#004d40", textDecoration: "underline", fontWeight: 'bolder', background: "white", textAlign: "center" }} onClick={(e) => dataBasedOnClick(e, ele, "total")}>{(ele?.grandTotal)?.toFixed(2)}</StyledTableCell> 
                                                     </>
                                            )}

                                            </StyledTableRow>
                                        ))}
                                    </TableHead>
                                </Table>
                            </TableContainer>
                        </div>

                    </>
                }
                {(pivot === "lost") &&
                    <>
                        <Grid container spacing={2} justifyContent="center" alignItems="center">
                            <Grid item xs={2} >
                                <Button
                                    style={{
                                        backgroundColor: pivot === 'lost' ? '#F50057' : 'inherit',
                                        color: pivot === 'lost' ? 'white' : 'inherit',
                                        marginBottom: '10px',

                                    }}
                                >
                                    Lost Summary-Values
                                </Button>
                            </Grid>
                        </Grid>
                        <div style={{ maxHeight: '500px', overflow: 'auto' }}>
                            <TableContainer component={Paper} classes={{ root: classes.customTableContainer }} style={{ marginTop: '30px' }}>
                                <Table style={{ minWidth: '600px' }}> {/* Ensure a minimum width for horizontal scrolling */}
                                    <TableHead style={{ position: 'sticky', top: 0, zIndex: 1, background: 'white' }}>
                                        <StyledTableRow>
                                            <StyledTableCell className={classes.bordercolors} style={{ position: 'sticky', left: 0, zIndex: 9, textAlign: "center" }}>Sales Month</StyledTableCell>
                                            {store.slice(0, uniqueexperienceCenterCode.length - 1).map((experienceCenterName, index) => (
                                                <StyledTableCell
                                                    className={classes.bordercolors}
                                                    key={index}
                                                    style={{
                                                        fontSize: index === uniqueexperienceCenterNames.length - 1 ? '15px' : '13px',
                                                        fontWeight: index === uniqueexperienceCenterNames.length - 1 ? 'bolder' : 'normal',
                                                        textAlign: 'center',
                                                        position: 'sticky',
                                                        cursor: 'pointer',
                                                        top: 0,
                                                        zIndex: 1, // Ensure the same zIndex for all cells
                                                    }}
                                                >
                                                    <Tooltip title={`${experienceCenterName.experienceCenterName}-${experienceCenterName.expCtrCityName}`} placement="top">
                                                        {/* <span>
                                                {experienceCenterName.expCenterCode} <InfoIcon />
                                                </span> */}
                                                        <span style={{ cursor: "pointer" }}>
                                                            {experienceCenterName.expCenterCode}
                                                        </span>
                                                    </Tooltip>

                                                </StyledTableCell>
                                            ))}
                                            <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center" }}>
                                                Sales Lost Total
                                            </StyledTableCell>
                                            <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center" }}>
                                                Design Lost Total
                                            </StyledTableCell>
                                            <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center" }}>
                                                Lost Grand Total
                                            </StyledTableCell>
                                            <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center" }}>
                                                Sales Grand Total
                                            </StyledTableCell>
                                            <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center" }}>
                                                Percentage
                                            </StyledTableCell>


                                        </StyledTableRow>
                                    </TableHead>

                                    <TableHead style={{ position: 'sticky', top: 0, background: 'white' }}>
                                        {store[0]?.monthlyGrandTotal?.map((month, monthIndex) => (
                                            <StyledTableRow key={monthIndex}>
                                                <StyledTableCell className={classes.bordercolors} style={{ position: 'sticky', left: 0, textAlign: "center" }}>
                                                    {`${month.monthName.slice(0, 3)}-${month.year % 100} `}
                                                </StyledTableCell>

                                                {uniqueexperienceCenterNames.map((experienceCenterName, expCenterIndex) => (
                                                    <>
                                                        {expCenterData[experienceCenterName].map((innerEl, dataIndex) => (
                                                            <StyledTableCell
                                                                className={classes.bordercolors}
                                                                key={dataIndex}
                                                                style={{
                                                                    textAlign: "center",
                                                                    cursor: "pointer",
                                                                    top: 0,
                                                                    background: "white",
                                                                    fontWeight: (expCenterIndex === uniqueexperienceCenterNames.length - 1 || expCenterIndex === uniqueexperienceCenterNames.length - 2 || expCenterIndex === uniqueexperienceCenterNames.length - 3 || expCenterIndex === uniqueexperienceCenterNames.length - 4) ? 'bolder' : 'normal',
                                                                    color: (expCenterIndex === uniqueexperienceCenterNames.length - 1 || expCenterIndex === uniqueexperienceCenterNames.length - 2 || expCenterIndex === uniqueexperienceCenterNames.length - 3 || expCenterIndex === uniqueexperienceCenterNames.length - 4) ? "#004d40" : "blue"
                                                                }}
                                                                onClick={(e) => lostDataBasedOnClick(e, month, innerEl, 'value')}
                                                            >
                                                                {
                                                                    formatNumberToTwoDecimalPlaces(
                                                                        innerEl.monthlyGrandTotal.find(
                                                                            (entry) => entry.monthName === month.monthName && entry.year === month.year
                                                                        )?.totalGrandTotal || 0
                                                                    )
                                                                }
                                                            </StyledTableCell>
                                                        ))}
                                                    </>
                                                ))}
                                                <StyledTableCell
                                                    className={classes.bordercolors}
                                                    style={{
                                                        textAlign: "center",
                                                        cursor: "pointer",
                                                        top: 0,
                                                        background: "white",
                                                        fontWeight: 'bolder',
                                                        color: "#004d40"
                                                    }}
                                                >
                                                    {calculatePercentageSummary(month, expCenterData)}
                                                </StyledTableCell>

                                            </StyledTableRow>
                                        ))}

                                        <StyledTableRow>
                                            <StyledTableCell className={classes.bordercolors} style={{ position: 'sticky', left: 0, zIndex: 9, textAlign: "center" }}>Grand Total</StyledTableCell>
                                            {uniqueexperienceCenterNames.map((experienceCenterName, index) => (
                                                <StyledTableCell className={classes.bordercolors} key={index} style={{ textAlign: "center", cursor: "pointer", color: "#004d40", zIndex: 1, textDecoration: "underline", fontWeight: 'bolder', background: "white" }} onClick={(e) => lostDataBasedOnClick(e, store[0].monthlyGrandTotal, experienceCenterName, 'total')}>
                                                    {
                                                        formatNumberToTwoDecimalPlaces(store.reduce((total, el) => total + (el.experienceCenterName === experienceCenterName ? el.grandTotal : 0), 0))
                                                    }
                                                </StyledTableCell>
                                            ))}
                                            <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", color: "#004d40", zIndex: 1, textDecoration: "underline", fontWeight: 'bolder', background: "white" }}>
                                                {`${percentageLost(store)}`}%
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    </TableHead>
                                </Table>
                            </TableContainer>
                        </div>


                        <Grid container spacing={2} justifyContent="center" alignItems="center">
                            <Grid item xs={2}>
                                <Button
                                    style={{
                                        backgroundColor: pivot === 'lost' ? '#F50057' : 'inherit',
                                        color: pivot === 'lost' ? 'white' : 'inherit',
                                        marginTop: '20px',

                                    }}
                                >
                                    Total No of Customers
                                </Button>
                            </Grid>
                        </Grid>
                        <div style={{ maxHeight: '500px', overflow: 'auto' }}>
                            <TableContainer component={Paper} classes={{ root: classes.customTableContainer }} style={{ marginTop: '30px' }}>
                                <Table style={{ minWidth: '600px' }}> {/* Ensure a minimum width for horizontal scrolling */}
                                    <TableHead style={{ position: 'sticky', top: 0, zIndex: 1, background: 'white' }}>
                                        <StyledTableRow>
                                            <StyledTableCell className={classes.bordercolors} style={{ position: 'sticky', left: 0, zIndex: 9, textAlign: "center" }}>Sales Month</StyledTableCell>
                                            {store.slice(0, uniqueexperienceCenterCode.length - 1).map((experienceCenterName, index) => (
                                                <StyledTableCell className={classes.bordercolors} key={index}
                                                    style={{
                                                        fontSize: index === uniqueexperienceCenterNames.length - 1 ? '15px' : '13px',
                                                        fontWeight: index === uniqueexperienceCenterNames.length - 1 ? 'bolder' : 'normal',
                                                        textAlign: 'center',
                                                        position: 'sticky',
                                                        cursor: 'pointer',
                                                        top: 0,
                                                        zIndex: 1, // Ensure the same zIndex for all cells
                                                    }}>
                                                    <Tooltip title={`${experienceCenterName.experienceCenterName}-${experienceCenterName.expCtrCityName}`} placement="top">
                                                        <span style={{ cursor: "pointer" }}>
                                                            {experienceCenterName.expCenterCode}
                                                        </span>
                                                    </Tooltip>
                                                </StyledTableCell>
                                            ))}
                                            <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center" }}>
                                                Sales Lost Total
                                            </StyledTableCell>
                                            <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center" }}>
                                                Design Lost Total
                                            </StyledTableCell>
                                            <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center" }}>
                                                Lost Grand Total
                                            </StyledTableCell>
                                            <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center" }}>
                                                Sales Grand Total
                                            </StyledTableCell>
                                            <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center" }}>
                                                Percentage
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    </TableHead>
                                    <TableHead style={{ position: 'sticky', top: 0, background: 'white' }}>
                                        {store[0]?.monthlyGrandTotal?.map((month, monthIndex) => (
                                            <StyledTableRow key={monthIndex}>
                                                <StyledTableCell className={classes.bordercolors} style={{ position: 'sticky', left: 0, textAlign: "center" }}>
                                                    {`${month.monthName.slice(0, 3)}-${month.year % 100} `}
                                                </StyledTableCell>
                                                {uniqueexperienceCenterNames.map((experienceCenterName, expCenterIndex) => (
                                                    <>
                                                        {expCenterData[experienceCenterName].map((innerEl, dataIndex) => (
                                                            <StyledTableCell
                                                                className={classes.bordercolors}
                                                                key={dataIndex}
                                                                style={{
                                                                    textAlign: "center",
                                                                    cursor: "pointer",
                                                                    cursor: "pointer",
                                                                    color: "blue",
                                                                    textDecoration: "underline",
                                                                    background: "white",
                                                                    fontWeight: (expCenterIndex === uniqueexperienceCenterNames.length - 1 || expCenterIndex === uniqueexperienceCenterNames.length - 2 || expCenterIndex === uniqueexperienceCenterNames.length - 3 || expCenterIndex === uniqueexperienceCenterNames.length - 4) ? 'bolder' : 'normal',
                                                                    color: (expCenterIndex === uniqueexperienceCenterNames.length - 1 || expCenterIndex === uniqueexperienceCenterNames.length - 2 || expCenterIndex === uniqueexperienceCenterNames.length - 3 || expCenterIndex === uniqueexperienceCenterNames.length - 4) ? "#004d40" : "blue"
                                                                }}
                                                                onClick={(e) => lostDataBasedOnClick(e, month, innerEl, 'value')}
                                                            >
                                                                {
                                                                    formatNumberToTwoDecimalPlaces(
                                                                        innerEl.monthlyGrandTotal.find(
                                                                            (entry) => entry.monthName === month.monthName && entry.year === month.year
                                                                        )?.count || 0
                                                                    )
                                                                }
                                                            </StyledTableCell>
                                                        ))}
                                                    </>
                                                ))}
                                                <StyledTableCell
                                                    className={classes.bordercolors}
                                                    style={{
                                                        textAlign: "center",
                                                        cursor: "pointer",
                                                        top: 0,
                                                        background: "white",
                                                        fontWeight: 'bolder',
                                                        color: "#004d40"
                                                    }}
                                                >
                                                    {calculatePercentageCount(month, expCenterData)}
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}

                                        <StyledTableRow>
                                            <StyledTableCell className={classes.bordercolors} style={{ position: 'sticky', left: 0, zIndex: 9, textAlign: "center" }}>Grand Total</StyledTableCell>
                                            {uniqueexperienceCenterNames.map((experienceCenterName, index) => (
                                                <StyledTableCell className={classes.bordercolors} key={index} style={{ textAlign: "center", cursor: "pointer", color: "#004d40", zIndex: 1, textDecoration: "underline", fontWeight: 'bolder', background: "white" }} onClick={(e) => lostDataBasedOnClick(e, store[0].monthlyGrandTotal, experienceCenterName, 'total')}>
                                                    {
                                                        formatNumberToTwoDecimalPlaces(store.reduce((total, el) => total + (el.experienceCenterName === experienceCenterName ? el.count : 0), 0))
                                                    }
                                                </StyledTableCell>
                                            ))}
                                            <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", color: "#004d40", zIndex: 1, textDecoration: "underline", fontWeight: 'bolder', background: "white" }}>
                                                {`${percentageCount(store)}`}%
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    </TableHead>
                                </Table>
                            </TableContainer>
                        </div>
                    </>
                }
                {(pivot === 'mis') &&
                    <>
                        <Grid container spacing={2} justifyContent="center" alignItems="center">
                            <Grid item xs={2} >
                                <Button
                                    style={{
                                        backgroundColor: pivot === 'mis' ? '#F50057' : 'inherit',
                                        color: pivot === 'mis' ? 'white' : 'inherit',
                                        marginBottom: '10px',

                                    }}
                                >
                                    MIS -1 Summary-Values
                                </Button>
                            </Grid>
                        </Grid>
                        <div style={{ maxHeight: '600px', overflow: 'auto' }}>
                            <TableContainer component={Paper} classes={{ root: classes.customTableContainer }} style={{ marginTop: '30px' }}>
                                <Table style={{ minWidth: '600px' }}>
                                    <TableHead style={{ position: 'sticky', top: 0, zIndex: 9, background: 'white' }}>
                                        <StyledTableRow>
                                            <>
                                                <StyledTableCell className={classes.bordercolors} style={{ position: 'sticky', left: 0, zIndex: 9, textAlign: "center" }} rowSpan={2}>Month And Year</StyledTableCell>
                                                <StyledTableCell className={classes.bordercolors} style={{ textAlign: 'center' }}>Designer Not Assigned</StyledTableCell>
                                                <StyledTableCell className={classes.bordercolors} style={{ textAlign: 'center' }}>Design Kick Off</StyledTableCell>
                                                <StyledTableCell className={classes.bordercolors} style={{ textAlign: 'center' }}>Design</StyledTableCell>
                                                <StyledTableCell className={classes.bordercolors} style={{ textAlign: 'center' }}>Design-Hold</StyledTableCell>
                                                <StyledTableCell className={classes.bordercolors} style={{ textAlign: 'center' }}>Design Sign-Off</StyledTableCell>
                                                <StyledTableCell className={classes.bordercolors} style={{ textAlign: 'center' }}>Design Sign-Off Hold</StyledTableCell>
                                                <StyledTableCell className={classes.bordercolors} style={{ textAlign: 'center' }}>Workng Drawing Received</StyledTableCell>
                                                <StyledTableCell className={classes.bordercolors} style={{ textAlign: 'center' }}>Production Drawing Received</StyledTableCell>
                                                <StyledTableCell className={classes.bordercolors} style={{ textAlign: 'center' }}>Under Machine</StyledTableCell>
                                                <StyledTableCell className={classes.bordercolors} style={{ textAlign: 'center' }}>Under Fixing</StyledTableCell>
                                                <StyledTableCell className={classes.bordercolors} style={{ textAlign: 'center' }}>Ready For Dispatch</StyledTableCell>
                                                <StyledTableCell className={classes.bordercolors} style={{ textAlign: 'center' }}>Under Execution</StyledTableCell>
                                                <StyledTableCell className={classes.bordercolors} style={{ textAlign: 'center' }}>Completed</StyledTableCell>
                                                <StyledTableCell className={classes.bordercolors} style={{ textAlign: 'center' }}>Sales Lost</StyledTableCell>
                                                <StyledTableCell className={classes.bordercolors} style={{ textAlign: 'center' }}>Design Lost</StyledTableCell>
                                                <StyledTableCell className={classes.bordercolors} style={{
                                                    fontSize: '15px',
                                                    textAlign: 'center'
                                                }}>Grand Total</StyledTableCell>
                                            </>
                                        </StyledTableRow>
                                    </TableHead>
                                    <TableHead style={{ position: 'sticky', top: 0, background: 'white' }}>
                                        {store.map((ele, index) => (
                                            <StyledTableRow key={index}>
                                                {
                                                    <>
                                                        {index === store.length - 1 ?
                                                            <StyledTableCell className={classes.bordercolors} style={{
                                                                fontSize: index === store.length - 1 ? '15px' : '13px',
                                                                fontWeight: index === store.length - 1 ? 'bolder' : 'normal',
                                                                left: 0,
                                                                position: 'sticky'
                                                            }}>{ele?._id?.year}</StyledTableCell> :
                                                            <StyledTableCell className={classes.bordercolors} style={{
                                                                fontSize: index === store.length - 1 ? '15px' : '13px',
                                                                fontWeight: index === store.length - 1 ? 'bolder' : 'normal',
                                                                left: 0,
                                                                position: 'sticky',
                                                                textAlign: "center"
                                                            }}>
                                                                {months[ele?.month]}-{ele?._id?.year % 100}
                                                            </StyledTableCell>
                                                        }
                                                    </>
                                                }
                                                <>
                                                    <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", cursor: "pointer", color: index !== store.length - 1 ? "blue" : "#004d40", textDecoration: "underline", fontWeight: index !== store.length - 1 ? 'normal' : 'bolder', background: "white" }} onClick={(e) => dataBasedOnClick(e, ele, "designerNotAssigned")}>{(ele.designerNotAssigned)?.toFixed(2)}</StyledTableCell>
                                                    <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", cursor: "pointer", color: index !== store.length - 1 ? "blue" : "#004d40", textDecoration: "underline", fontWeight: index !== store.length - 1 ? 'normal' : 'bolder', background: "white" }} onClick={(e) => dataBasedOnClick(e, ele, "designKickOff")}>{(ele.designKickOff)?.toFixed(2)}</StyledTableCell>
                                                    <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", cursor: "pointer", color: index !== store.length - 1 ? "blue" : "#004d40", textDecoration: "underline", fontWeight: index !== store.length - 1 ? 'normal' : 'bolder', background: "white" }} onClick={(e) => dataBasedOnClick(e, ele, "design")}>{(ele.design)?.toFixed(2)}</StyledTableCell>
                                                    <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", cursor: "pointer", color: index !== store.length - 1 ? "blue" : "#004d40", textDecoration: "underline", fontWeight: index !== store.length - 1 ? 'normal' : 'bolder', background: "white" }} onClick={(e) => dataBasedOnClick(e, ele, "design-Hold")}>{(ele.designHoldGrand)?.toFixed(2)}</StyledTableCell>
                                                    <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", cursor: "pointer", color: index !== store.length - 1 ? "blue" : "#004d40", textDecoration: "underline", fontWeight: index !== store.length - 1 ? 'normal' : 'bolder', background: "white" }} onClick={(e) => dataBasedOnClick(e, ele, "designSignOff")}>{(ele.designSignOff)?.toFixed(2)}</StyledTableCell>
                                                    <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", cursor: "pointer", color: index !== store.length - 1 ? "blue" : "#004d40", textDecoration: "underline", fontWeight: index !== store.length - 1 ? 'normal' : 'bolder', background: "white" }} onClick={(e) => dataBasedOnClick(e, ele, "designSignOffHold")}>{(ele.designSignOffHold)?.toFixed(2)}</StyledTableCell>
                                                    <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", cursor: "pointer", color: index !== store.length - 1 ? "blue" : "#004d40", textDecoration: "underline", fontWeight: index !== store.length - 1 ? 'normal' : 'bolder', background: "white" }} onClick={(e) => dataBasedOnClick(e, ele, "workingDrawingReceived")}>{(ele.workingDrawingReceived)?.toFixed(2)}</StyledTableCell>
                                                    <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", cursor: "pointer", color: index !== store.length - 1 ? "blue" : "#004d40", textDecoration: "underline", fontWeight: index !== store.length - 1 ? 'normal' : 'bolder', background: "white" }} onClick={(e) => dataBasedOnClick(e, ele, "productionDrawingReceived")}>{(ele.productionDrawingReceived)?.toFixed(2)}</StyledTableCell>
                                                    <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", cursor: "pointer", color: index !== store.length - 1 ? "blue" : "#004d40", textDecoration: "underline", fontWeight: index !== store.length - 1 ? 'normal' : 'bolder', background: "white" }} onClick={(e) => dataBasedOnClick(e, ele, "underMachine")}>{(ele.underMachine)?.toFixed(2)}</StyledTableCell>
                                                    <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", cursor: "pointer", color: index !== store.length - 1 ? "blue" : "#004d40", textDecoration: "underline", fontWeight: index !== store.length - 1 ? 'normal' : 'bolder', background: "white" }} onClick={(e) => dataBasedOnClick(e, ele, "underFixing")}>{(ele.underFixing)?.toFixed(2)}</StyledTableCell>
                                                    <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", cursor: "pointer", color: index !== store.length - 1 ? "blue" : "#004d40", textDecoration: "underline", fontWeight: index !== store.length - 1 ? 'normal' : 'bolder', background: "white" }} onClick={(e) => dataBasedOnClick(e, ele, "readyForDispatch")}>{(ele.readyForDispatch)?.toFixed(2)}</StyledTableCell>
                                                    <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", cursor: "pointer", color: index !== store.length - 1 ? "blue" : "#004d40", textDecoration: "underline", fontWeight: index !== store.length - 1 ? 'normal' : 'bolder', background: "white" }} onClick={(e) => dataBasedOnClick(e, ele, "underExecution")}>{(ele.underExecution)?.toFixed(2)}</StyledTableCell>
                                                    <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", cursor: "pointer", color: index !== store.length - 1 ? "blue" : "#004d40", textDecoration: "underline", fontWeight: index !== store.length - 1 ? 'normal' : 'bolder', background: "white" }} onClick={(e) => dataBasedOnClick(e, ele, "completedMWP")}>{(ele.completedMWP)?.toFixed(2)}</StyledTableCell>
                                                    <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", cursor: "pointer", color: index !== store.length - 1 ? "blue" : "#004d40", textDecoration: "underline", fontWeight: index !== store.length - 1 ? 'normal' : 'bolder', background: "white" }} onClick={(e) => dataBasedOnClick(e, ele, "salesLost")}>{(ele.salesLost)?.toFixed(2)}</StyledTableCell>
                                                    <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", cursor: "pointer", color: index !== store.length - 1 ? "blue" : "#004d40", textDecoration: "underline", fontWeight: index !== store.length - 1 ? 'normal' : 'bolder', background: "white" }} onClick={(e) => dataBasedOnClick(e, ele, "designLost")}>{(ele.designLost)?.toFixed(2)}</StyledTableCell>
                                                    <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", cursor: "pointer", color: "#004d40", textDecoration: "underline", fontWeight: 'bolder', background: "white" }} onClick={(e) => dataBasedOnClick(e, ele, "total")}>{(ele.grandTotal)?.toFixed(2)}</StyledTableCell>
                                                </>

                                            </StyledTableRow>
                                        ))}
                                    </TableHead>
                                </Table>
                            </TableContainer>
                        </div>

                        <Grid container spacing={2} justifyContent="center" alignItems="center">
                            <Grid item xs={2}>
                                <Button
                                    style={{
                                        backgroundColor: pivot === 'mis' ? '#F50057' : 'inherit',
                                        color: pivot === 'mis' ? 'white' : 'inherit',
                                        marginTop: '20px',

                                    }}
                                >
                                    Total No of Customers
                                </Button>
                            </Grid>
                        </Grid>
                        <div style={{ maxHeight: '600px', overflow: 'auto' }}>
                            <TableContainer component={Paper} classes={{ root: classes.customTableContainer }} style={{ marginTop: '30px' }}>
                                <Table style={{ minWidth: '600px' }}>
                                    <TableHead style={{ position: 'sticky', top: 0, zIndex: 9, background: 'white' }}>
                                        <StyledTableRow>
                                            <>
                                                <StyledTableCell className={classes.bordercolors} style={{ position: 'sticky', left: 0, zIndex: 9, textAlign: "center" }} rowSpan={2}>Month And Year</StyledTableCell>
                                                <StyledTableCell className={classes.bordercolors} style={{ textAlign: 'center' }}>Designer Not Assigned</StyledTableCell>
                                                <StyledTableCell className={classes.bordercolors} style={{ textAlign: 'center' }}>Design Kick Off</StyledTableCell>
                                                <StyledTableCell className={classes.bordercolors} style={{ textAlign: 'center' }}>Design</StyledTableCell>
                                                <StyledTableCell className={classes.bordercolors} style={{ textAlign: 'center' }}>Design-Hold</StyledTableCell>
                                                <StyledTableCell className={classes.bordercolors} style={{ textAlign: 'center' }}>Design Sign-Off</StyledTableCell>
                                                <StyledTableCell className={classes.bordercolors} style={{ textAlign: 'center' }}>Design Sign-Off Hold</StyledTableCell>
                                                <StyledTableCell className={classes.bordercolors} style={{ textAlign: 'center' }}>Workng Drawing Received</StyledTableCell>
                                                <StyledTableCell className={classes.bordercolors} style={{ textAlign: 'center' }}>Production Drawing Received</StyledTableCell>
                                                <StyledTableCell className={classes.bordercolors} style={{ textAlign: 'center' }}>Under Machine</StyledTableCell>
                                                <StyledTableCell className={classes.bordercolors} style={{ textAlign: 'center' }}>Under Fixing</StyledTableCell>
                                                <StyledTableCell className={classes.bordercolors} style={{ textAlign: 'center' }}>Ready For Dispatch</StyledTableCell>
                                                <StyledTableCell className={classes.bordercolors} style={{ textAlign: 'center' }}>Under Execution</StyledTableCell>
                                                <StyledTableCell className={classes.bordercolors} style={{ textAlign: 'center' }}>Completed</StyledTableCell>
                                                <StyledTableCell className={classes.bordercolors} style={{ textAlign: 'center' }}>Sales Lost</StyledTableCell>
                                                <StyledTableCell className={classes.bordercolors} style={{ textAlign: 'center' }}>Design Lost</StyledTableCell>
                                                <StyledTableCell className={classes.bordercolors} style={{
                                                    fontSize: '15px',
                                                    textAlign: 'center'
                                                }}>Grand Total</StyledTableCell>
                                            </>
                                        </StyledTableRow>
                                    </TableHead>
                                    <TableHead style={{ position: 'sticky', top: 0, background: 'white' }}>
                                        {store.map((ele, index) => (
                                            <StyledTableRow key={index}>
                                                {
                                                    <>
                                                        {index === store.length - 1 ?
                                                            <StyledTableCell className={classes.bordercolors} style={{
                                                                fontSize: index === store.length - 1 ? '15px' : '13px',
                                                                fontWeight: index === store.length - 1 ? 'bolder' : 'normal',
                                                                left: 0,
                                                                position: 'sticky'
                                                            }}>{ele?._id?.year}</StyledTableCell> :
                                                            <StyledTableCell className={classes.bordercolors} style={{
                                                                fontSize: index === store.length - 1 ? '15px' : '13px',
                                                                fontWeight: index === store.length - 1 ? 'bolder' : 'normal',
                                                                left: 0,
                                                                position: 'sticky',
                                                                textAlign: "center"
                                                            }}>
                                                                {months[ele?.month]}-{ele?._id?.year % 100}
                                                            </StyledTableCell>
                                                        }
                                                    </>
                                                }
                                                <>

                                                    <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", cursor: "pointer", color: index !== store.length - 1 ? "blue" : "#004d40", textDecoration: "underline", fontWeight: index !== store.length - 1 ? 'normal' : 'bolder', background: "white" }} onClick={(e) => dataBasedOnClick(e, ele, "designerNotAssigned")}>{ele.designerNotAssignedCount}</StyledTableCell>
                                                    <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", cursor: "pointer", color: index !== store.length - 1 ? "blue" : "#004d40", textDecoration: "underline", fontWeight: index !== store.length - 1 ? 'normal' : 'bolder', background: "white" }} onClick={(e) => dataBasedOnClick(e, ele, "designKickOff")}>{(ele.designKickOffCount)}</StyledTableCell>
                                                    <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", cursor: "pointer", color: index !== store.length - 1 ? "blue" : "#004d40", textDecoration: "underline", fontWeight: index !== store.length - 1 ? 'normal' : 'bolder', background: "white" }} onClick={(e) => dataBasedOnClick(e, ele, "design")}>{ele.designCount}</StyledTableCell>
                                                    <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", cursor: "pointer", color: index !== store.length - 1 ? "blue" : "#004d40", textDecoration: "underline", fontWeight: index !== store.length - 1 ? 'normal' : 'bolder', background: "white" }} onClick={(e) => dataBasedOnClick(e, ele, "design-Hold")}>{ele.designHoldcount}</StyledTableCell>
                                                    <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", cursor: "pointer", color: index !== store.length - 1 ? "blue" : "#004d40", textDecoration: "underline", fontWeight: index !== store.length - 1 ? 'normal' : 'bolder', background: "white" }} onClick={(e) => dataBasedOnClick(e, ele, "designSignOff")}>{ele.designSignOffCount}</StyledTableCell>
                                                    <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", cursor: "pointer", color: index !== store.length - 1 ? "blue" : "#004d40", textDecoration: "underline", fontWeight: index !== store.length - 1 ? 'normal' : 'bolder', background: "white" }} onClick={(e) => dataBasedOnClick(e, ele, "designSignOffHold")}>{ele.designSignOffHoldCount}</StyledTableCell>
                                                    <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", cursor: "pointer", color: index !== store.length - 1 ? "blue" : "#004d40", textDecoration: "underline", fontWeight: index !== store.length - 1 ? 'normal' : 'bolder', background: "white" }} onClick={(e) => dataBasedOnClick(e, ele, "workingDrawingReceived")}>{ele.workingDrawingReceivedCount}</StyledTableCell>
                                                    <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", cursor: "pointer", color: index !== store.length - 1 ? "blue" : "#004d40", textDecoration: "underline", fontWeight: index !== store.length - 1 ? 'normal' : 'bolder', background: "white" }} onClick={(e) => dataBasedOnClick(e, ele, "productionDrawingReceived")}>{ele.productionDrawingReceivedCount}</StyledTableCell>
                                                    <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", cursor: "pointer", color: index !== store.length - 1 ? "blue" : "#004d40", textDecoration: "underline", fontWeight: index !== store.length - 1 ? 'normal' : 'bolder', background: "white" }} onClick={(e) => dataBasedOnClick(e, ele, "underMachine")}>{ele.underMachineCount}</StyledTableCell>
                                                    <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", cursor: "pointer", color: index !== store.length - 1 ? "blue" : "#004d40", textDecoration: "underline", fontWeight: index !== store.length - 1 ? 'normal' : 'bolder', background: "white" }} onClick={(e) => dataBasedOnClick(e, ele, "underFixing")}>{ele.underFixingCount}</StyledTableCell>
                                                    <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", cursor: "pointer", color: index !== store.length - 1 ? "blue" : "#004d40", textDecoration: "underline", fontWeight: index !== store.length - 1 ? 'normal' : 'bolder', background: "white" }} onClick={(e) => dataBasedOnClick(e, ele, "readyForDispatch")}>{ele.readyForDispatchCount}</StyledTableCell>
                                                    <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", cursor: "pointer", color: index !== store.length - 1 ? "blue" : "#004d40", textDecoration: "underline", fontWeight: index !== store.length - 1 ? 'normal' : 'bolder', background: "white" }} onClick={(e) => dataBasedOnClick(e, ele, "underExecution")}>{ele.underExecutionCount}</StyledTableCell>
                                                    <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", cursor: "pointer", color: index !== store.length - 1 ? "blue" : "#004d40", textDecoration: "underline", fontWeight: index !== store.length - 1 ? 'normal' : 'bolder', background: "white" }} onClick={(e) => dataBasedOnClick(e, ele, "completedMWP")}>{ele.completedMWPCount}</StyledTableCell>
                                                    <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", cursor: "pointer", color: index !== store.length - 1 ? "blue" : "#004d40", textDecoration: "underline", fontWeight: index !== store.length - 1 ? 'normal' : 'bolder', background: "white" }} onClick={(e) => dataBasedOnClick(e, ele, "salesLost")}>{ele.salesLostCount}</StyledTableCell>
                                                    <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", cursor: "pointer", color: index !== store.length - 1 ? "blue" : "#004d40", textDecoration: "underline", fontWeight: index !== store.length - 1 ? 'normal' : 'bolder', background: "white" }} onClick={(e) => dataBasedOnClick(e, ele, "designLost")}>{ele.designLostCount}</StyledTableCell>
                                                    <StyledTableCell className={classes.bordercolors} style={{ textAlign: "center", cursor: "pointer", cursor: "pointer", color: "#004d40", textDecoration: "underline", fontWeight: 'bolder', background: "white" }} onClick={(e) => dataBasedOnClick(e, ele, "total")}>{ele.totalCount}</StyledTableCell>
                                                </>

                                            </StyledTableRow>
                                        ))}
                                    </TableHead>
                                </Table>
                            </TableContainer>
                        </div>
                    </>
                }
                {(pivot === 'salesOverallReview') && (store.length != 0) &&
                    <>
                        <TableContainer component={Paper} style={{ marginTop: '50px' }}>
                            <Grid container spacing={2} justifyContent="center" alignItems="center">
                                <Grid item xs={2}>
                                    <Button
                                        style={{
                                            backgroundColor: pivot === 'salesOverallReview' ? '#F50057' : 'inherit',
                                            color: pivot === 'salesOverallReview' ? 'white' : 'inherit',
                                            marginBottom: '30px',
                                            marginTop: '20px',
                                        }}
                                    >
                                        Sales Summary-Values
                                    </Button>
                                </Grid>
                            </Grid>

                            <div style={{ maxHeight: '600px', overflow: 'auto' }}>
                                <TableContainer component={Paper} classes={{ root: classes.customTableContainer }} style={{ marginTop: '30px' }}>
                                    <Table style={{ minWidth: '600px' }}>
                                        <TableHead style={{ position: 'sticky', top: 0, zIndex: 2 }}>
                                            <StyledTableRow>
                                                <StyledTableCell className={classes.bordercolors} style={{ position: 'sticky', left: 0, zIndex: 9 }}>Sales Users</StyledTableCell>
                                                {store[0]?.monthlyGrandTotal?.map((month, index) => (
                                                    <StyledTableCell className={classes.bordercolors} style={{ position: 'sticky', top: 0, zIndex: 2, }}>
                                                        {`${month.monthName} ${month.year}`}
                                                    </StyledTableCell>
                                                ))}
                                                <StyledTableCell className={classes.bordercolors} style={{ position: 'sticky', top: 0, zIndex: 2, textAlign: "center" }}>
                                                    Grand Total
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        </TableHead>
                                        <TableHead style={{ position: 'sticky', top: 0, background: 'white' }}>
                                            {store?.map((el, i) => (
                                                <StyledTableRow key={i}>
                                                    <StyledTableCell style={{
                                                        fontSize: i === store.length - 1 ? '15px' : '13px',
                                                        fontWeight: i === store.length - 1 ? 'bolder' : 'normal',
                                                        position: 'sticky',
                                                        left: 0,
                                                        zIndex: 9,
                                                    }}>
                                                        {el?.userName}
                                                    </StyledTableCell>
                                                    {el.monthlyGrandTotal?.map((data, index) => (
                                                        <React.Fragment key={index}>
                                                            <StyledTableCell style={{
                                                                textAlign: "center",
                                                                cursor: "pointer",
                                                                color: i === store.length - 1 ? "#004d40" : 'blue',
                                                                textDecoration: "underline",
                                                                fontWeight: i === store.length - 1 ? 'bolder' : 'normal',
                                                                position: 'sticky',
                                                                top: 0,
                                                                zIndex: 1,
                                                                background: 'white'
                                                            }} className={classes.bordercolors} onClick={(e) => getDataBasedOnClickSalesOverview(el?.userId, data)}>
                                                                {formatNumberToTwoDecimalPlaces(data?.totalGrandTotal)}
                                                            </StyledTableCell>
                                                        </React.Fragment>
                                                    ))}
                                                    <StyledTableCell style={{
                                                        textAlign: "center",
                                                        cursor: "pointer",
                                                        color: "#004d40",
                                                        textDecoration: "underline",
                                                        fontWeight: 'bolder',
                                                        position: 'sticky',
                                                        top: 0,
                                                        zIndex: 1,
                                                        background: 'white'
                                                    }} className={classes.bordercolors} onClick={(e) => getDataBasedOnClickSalesOverview(el?.userId, 'total')}>
                                                        {el?.grandTotal ? (el?.grandTotal).toFixed(2) : 0}
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                        </TableHead>
                                    </Table>
                                </TableContainer>
                            </div>


                        </TableContainer>
                        <TableContainer component={Paper}>
                            <Grid container spacing={2} justifyContent="center" alignItems="center">
                                <Grid item xs={2}>
                                    <Button
                                        style={{
                                            backgroundColor: pivot === 'salesOverallReview' ? '#F50057' : 'inherit',
                                            color: pivot === 'salesOverallReview' ? 'white' : 'inherit',
                                            marginBottom: '30px',
                                            marginTop: '20px',

                                        }}
                                    >
                                        Total No of Customers
                                    </Button>
                                </Grid>
                            </Grid>
                            <div style={{ maxHeight: '600px', overflow: 'auto' }}>
                                <TableContainer component={Paper} classes={{ root: classes.customTableContainer }} style={{ marginTop: '30px' }}>

                                    <Table style={{ minWidth: '600px' }}>
                                        <TableHead style={{ position: 'sticky', top: 0, zIndex: 1, background: 'white' }}>
                                            <StyledTableRow>
                                                <StyledTableCell className={classes.bordercolors} style={{ position: 'sticky', left: 0, zIndex: 9 }}>Sales Users</StyledTableCell>
                                                {store[0]?.monthlyGrandTotal?.map((month, index) => (
                                                    <StyledTableCell key={index} >{`${month.monthName} ${month.year}`}</StyledTableCell>
                                                ))}
                                                <StyledTableCell className={classes.bordercolors} style={{ position: 'sticky', top: 0, zIndex: 2, textAlign: "center" }}>
                                                    Grand Total</StyledTableCell>
                                            </StyledTableRow>
                                        </TableHead>
                                        <TableHead style={{ position: 'sticky', top: 0 }}>
                                            {store?.map((el, i) => (
                                                <StyledTableRow key={i}>
                                                    <StyledTableCell style={{
                                                        fontSize: i === store.length - 1 ? '15px' : '13px',
                                                        fontWeight: i === store.length - 1 ? 'bolder' : 'normal',
                                                        position: 'sticky',
                                                        left: 0,
                                                        zIndex: 9,

                                                    }} >{el?.userName}</StyledTableCell>
                                                    {el.monthlyGrandTotal?.map((data, index) => (
                                                        <React.Fragment>
                                                            <StyledTableCell style={{
                                                                textAlign: "center", cursor: "pointer", cursor: "pointer", color: i === store.length - 1 ? "#004d40" : 'blue', textDecoration: "underline", fontWeight: i === store.length - 1 ? 'bolder' : 'normal',
                                                                position: 'sticky',
                                                                top: 0,
                                                                zIndex: 1,
                                                                background: 'white'
                                                            }} className={classes.bordercolors} key={index} onClick={(e) => getDataBasedOnClickSalesOverview(el?.userId, data)}>
                                                                {formatNumberToTwoDecimalPlaces(data?.count)}
                                                            </StyledTableCell>
                                                        </React.Fragment>
                                                    ))}
                                                    <StyledTableCell style={{ textAlign: "center", cursor: "pointer", cursor: "pointer", color: "blue", textDecoration: "underline", color: "#004d40", textDecoration: "underline", fontWeight: 'bolder', background: "white" }} className={classes.bordercolors} onClick={(e) => getDataBasedOnClickSalesOverview(el?.userId, 'total')}>
                                                        {el?.count ? el?.count : 0}
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))}

                                        </TableHead>
                                    </Table>
                                </TableContainer>
                            </div>
                        </TableContainer>
                    </>
                }
            </div>
            <Modal
                title={pivot}
                centered
                visible={showDetailedModal}
                onCancel={() => setShowDetailedModal(false)}
                width={1000}
                maskClosable={true}
                footer={null}
            >
                <div className={classes.csv}>
                    <CSVLink
                        headers={headers}
                        filename={`${pivot}.csv`}
                        data={csvData}
                        ref={csvButtonRef}
                    />
                    <Button
                        onClick={() => csvDownloadHandler(popMonthData)}
                        style={{ backgroundColor: "green" }}
                        variant="contained"
                        color="secondary"
                    >
                        Download CSV
                    </Button>
                </div>
                {popMonthData && (
                    <TableContainer component={Paper} className={classes.table}>
                        <Table
                            style={{ width: 450 }}
                            className={classes.table}
                            stickyHeader
                            aria-label="sticky table"
                        >
                            <TableHead>
                                <StyledTableRow>
                                    <StyledTableCell>Sl. No.</StyledTableCell>
                                    <StyledTableCell>Project No.</StyledTableCell>
                                    <StyledTableCell>Lead No.</StyledTableCell>
                                    {
                                        (pivot === 'leads') &&
                                        <>
                                            <StyledTableCell>Created At</StyledTableCell>
                                            <StyledTableCell>Source of Lead</StyledTableCell>
                                        </>
                                    }
                                    <StyledTableCell>Client Name</StyledTableCell>
                                    <StyledTableCell>Value (INR)</StyledTableCell>
                                    <StyledTableCell>Exp. Ctr. Name</StyledTableCell>
                                    <StyledTableCell>Sales Won Date</StyledTableCell>
                                    {/* <StyledTableCell>Internal Design Sign-Off Date</StyledTableCell>
                                    <StyledTableCell>Customer Design Sign-Off Approval Date</StyledTableCell>
                                    <StyledTableCell>Material Delivery Date</StyledTableCell>
                                    <StyledTableCell>Actual Completion Date</StyledTableCell>
                                    { (status === "design-Hold" || status === "Design Hold - Possession more than 3 months") &&  <StyledTableCell>Revocation Date</StyledTableCell>}
                                     */}

                                    {(pivot !== 'designHoldAnalysis' && pivot !== 'leads') &&
                                        <>
                                            <StyledTableCell>Internal Design Sign-Off Date</StyledTableCell>
                                            <StyledTableCell>Customer Design Sign-Off Approval Date</StyledTableCell>
                                            <StyledTableCell>Material Delivery Date</StyledTableCell>
                                            <StyledTableCell>Actual Completion Date</StyledTableCell>
                                        </>
                                    }
                                    {
                                        (pivot === 'designHoldAnalysis') &&
                                        <>
                                            <StyledTableCell>Reason for Design-Hold</StyledTableCell>
                                            <StyledTableCell>Probable Date for Restarting the Designing</StyledTableCell>
                                            <StyledTableCell>Probable Date of Design Sign-off</StyledTableCell>
                                            <StyledTableCell>ADM</StyledTableCell>
                                            <StyledTableCell>Operation Design Manager</StyledTableCell>
                                            {/* <StyledTableCell>Revocation Date</StyledTableCell> */}
                                        </>
                                    }
                                    {
                                        (pivot === 'mis' || pivot === 'leads') &&
                                        <>
                                            <StyledTableCell>Current Department</StyledTableCell>
                                            <StyledTableCell>Current Stage</StyledTableCell>
                                        </>
                                    }

                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {popMonthData && popMonthData.map((data, i) => {
                                    return (
                                        <>
                                            {(pivot !== 'mis' && pivot !== 'leads') && (
                                                <StyledTableRow key={i}>
                                                    <StyledTableCell>{i + 1}</StyledTableCell>
                                                    <StyledTableCell component="th" style={{ textAlign: "center" }}>
                                                        {data?.leadId?.erpProjectNo ? (
                                                            <Link
                                                                to={`/project-logs/${data?.leadId?._id}`}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                style={{ color: "#000", textDecoration: 'underline', textAlign: 'center' }}
                                                            >
                                                                {data?.leadId?.erpProjectNo}
                                                            </Link>
                                                        ) : (
                                                            'NA'
                                                        )}
                                                    </StyledTableCell>
                                                    <StyledTableCell component="th" style={{ textAlign: "center" }}>
                                                        {data?.leadId?.lead_no ? (
                                                            <Link
                                                                to={`/project-logs/${data?.leadId?._id}`}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                style={{ color: "#000", textDecoration: 'underline', textAlign: 'center' }}
                                                            >
                                                                {data?.leadId?.lead_no}
                                                            </Link>
                                                        ) : (
                                                            'NA'
                                                        )}
                                                    </StyledTableCell>
                                                    <StyledTableCell component="th" style={{ textAlign: "center" }}>
                                                        {data?.leadId?.customerId?.name}
                                                    </StyledTableCell>

                                                    {(pivot === 'salesOverallReview' || pivot === "value" || pivot === "total" || status === 'designerNotAssigned') && (
                                                        <StyledTableCell component="th" style={{ textAlign: "center" }}>
                                                            {data?.leadId?.staticSalesWonBudget ? data?.leadId?.staticSalesWonBudget : "N/A"}
                                                        </StyledTableCell>
                                                    )}

                                                    {(pivot !== 'salesOverallReview' && pivot !== "value" && pivot !== "total" && (pivot === 'designSignOffReview' || status === 'completed')) && (
                                                        <StyledTableCell component="th" style={{ textAlign: "center" }}>
                                                            {((data?.leadId?.staticDesignWonAmount / 1.18) / 100000)?.toLocaleString("en-IN", {
                                                                maximumFractionDigits: 2,
                                                                style: "currency",
                                                                currency: "INR",
                                                            })}
                                                        </StyledTableCell>
                                                    )}

                                                    {(pivot !== 'salesOverallReview' && pivot !== "value" && pivot !== "total" && pivot !== 'designSignOffReview' && status !== 'completed' && status !== 'designerNotAssigned') && (
                                                        <StyledTableCell component="th" style={{ textAlign: "center" }}>
                                                            {data?.leadId?.staticSalesWonBudget ? data?.leadId?.staticSalesWonBudget : "N/A"}
                                                        </StyledTableCell>
                                                    )}

                                                    <StyledTableCell component="th">
                                                        {data?.salesExpCenter?.name}
                                                    </StyledTableCell>
                                                    <StyledTableCell component="th">
                                                        {data?.SalesWonDate ? moment(data?.SalesWonDate).format("DD-MM-YYYY") : "-"}
                                                    </StyledTableCell>

                                                    {pivot !== 'designHoldAnalysis' && (
                                                        <>
                                                            <StyledTableCell component="th">
                                                                {data?.leadId?.internalDesignSignOffDate ? moment(data?.leadId?.internalDesignSignOffDate).format("DD-MM-YYYY") : "-"}
                                                            </StyledTableCell>
                                                            <StyledTableCell component="th">
                                                                {data?.leadId?.customerDesignSignOffDate ? moment(data?.leadId?.customerDesignSignOffDate).format("DD-MM-YYYY") : "-"}
                                                            </StyledTableCell>
                                                            <StyledTableCell component="th">
                                                                {data?.leadId?.materialDispatchedDate ? moment(data?.leadId?.materialDispatchedDate).format("DD-MM-YYYY") : "-"}
                                                            </StyledTableCell>
                                                            <StyledTableCell component="th">
                                                                {data?.leadId?.executionCompletionDate ? moment(data?.leadId?.executionCompletionDate).format("DD-MM-YYYY") : "-"}
                                                            </StyledTableCell>
                                                        </>
                                                    )}

                                                    {pivot === 'designHoldAnalysis' && (
                                                        <>
                                                            <StyledTableCell component="th">
                                                                {data?.leadId?.designHoldSelectedTypes ? data?.leadId?.designHoldSelectedTypes : 'NA'}
                                                            </StyledTableCell>
                                                            <StyledTableCell component="th">
                                                                {data?.leadId?.proposedDesignStartDate ? moment(data?.leadId?.proposedDesignStartDate).format("DD-MM-YYYY") : "NA"}
                                                            </StyledTableCell>
                                                            <StyledTableCell component="th">
                                                                {data?.leadId?.probableDesignSignOffDate ? moment(data?.leadId?.probableDesignSignOffDate).format("DD-MM-YYYY") : "NA"}
                                                            </StyledTableCell>
                                                            <StyledTableCell component="th">
                                                                {data?.designAssistantManager?.name ? data?.designAssistantManager?.name : 'NA'}
                                                            </StyledTableCell>
                                                            <StyledTableCell component="th">
                                                                {data?.designManager ? data.designManager.name : 'NA'}
                                                            </StyledTableCell>
                                                        </>
                                                    )}
                                                </StyledTableRow>
                                            )}
                                            {(pivot === 'mis' || pivot === 'leads') && (
                                                <StyledTableRow key={i}>
                                                    <StyledTableCell>{i + 1}</StyledTableCell>
                                                    <StyledTableCell component="th" style={{ textAlign: "center" }}>
                                                        {data?.erpProjectNo ? (
                                                            <Link
                                                                to={`/project-logs/${data?._id}`}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                style={{ color: "#000", textDecoration: 'underline', textAlign: 'center' }}
                                                            >
                                                                {data?.erpProjectNo}
                                                            </Link>
                                                        ) : (
                                                            'NA'
                                                        )}
                                                    </StyledTableCell>
                                                    <StyledTableCell component="th" style={{ textAlign: "center" }}>
                                                        {data?.lead_no ? (
                                                            <Link
                                                                to={`/project-logs/${data?._id}`}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                style={{ color: "#000", textDecoration: 'underline', textAlign: 'center' }}
                                                            >
                                                                {data?.lead_no}
                                                            </Link>
                                                        ) : (
                                                            'NA'
                                                        )}
                                                    </StyledTableCell>
                                                    {pivot === 'leads' &&
                                                        <>
                                                            <StyledTableCell component="th" style={{ textAlign: "center" }}>
                                                                {data?.createdAt ? moment(data?.createdAt).format("DD-MM-YYYY") : "-"}
                                                            </StyledTableCell>
                                                            <StyledTableCell component="th" style={{ textAlign: "center" }}>
                                                                {data?.sourceOfLead?.name}
                                                            </StyledTableCell>
                                                        </>
                                                    }
                                                    <StyledTableCell component="th" style={{ textAlign: "center" }}>
                                                        {data?.customerId?.name}
                                                    </StyledTableCell>

                                                    <StyledTableCell component="th" style={{ textAlign: "center" }}>
                                                        {(pivot === 'leads' || ["designerNotAssigned", "designKickOff", "design", "design-Hold", "salesLost", "designLost"].includes(status)) ?
                                                            (data?.staticSalesWonBudget ? data?.staticSalesWonBudget : "N/A") :
                                                            (data?.staticDesignWonBudget ? data?.staticDesignWonBudget : "N/A")}
                                                    </StyledTableCell>

                                                    <StyledTableCell component="th">
                                                        {data?.experienceCenterId?.name}
                                                    </StyledTableCell>
                                                    <StyledTableCell component="th">
                                                        {data?.leadWonDate ? moment(data?.leadWonDate).format("DD-MM-YYYY") : "-"}
                                                    </StyledTableCell>
                                                    {
                                                        pivot !== 'leads' &&
                                                        <>
                                                            <StyledTableCell component="th">
                                                                {data?.internalDesignSignOffDate ? moment(data?.internalDesignSignOffDate).format("DD-MM-YYYY") : "-"}
                                                            </StyledTableCell>
                                                            <StyledTableCell component="th">
                                                                {data?.customerDesignSignOffDate ? moment(data?.customerDesignSignOffDate).format("DD-MM-YYYY") : "-"}
                                                            </StyledTableCell>
                                                            <StyledTableCell component="th">
                                                                {data?.materialDispatchedDate ? moment(data?.materialDispatchedDate).format("DD-MM-YYYY") : "-"}
                                                            </StyledTableCell>
                                                            <StyledTableCell component="th">
                                                                {data?.executionCompletionDate ? moment(data?.executionCompletionDate).format("DD-MM-YYYY") : "-"}
                                                            </StyledTableCell>
                                                        </>
                                                    }
                                                    <StyledTableCell component="th">
                                                        {data?.departmentId ? data.departmentId?.name : 'NA'}
                                                    </StyledTableCell>
                                                    <StyledTableCell component="th">
                                                        {data?.currentStage ? data.currentStage : 'NA'}
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            )}
                                        </>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Modal>
        </div >
    );
}

export default connect(null, { setLoading, setSnackInfo })(BookingStats);
