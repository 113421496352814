import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { setLoading, setSnackInfo } from '../../redux/actions/appAction'
import HttpService from '../../services/httpService'
import { Table, Button, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import { ROLENAME } from '../../utils/index';
import { useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {   DialogTitle } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import { DownloadOutlined } from '@ant-design/icons';
import moment from 'moment'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    }
});

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#ccc",
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow)

const VendorSoManage = (props) => {
    const classes = useStyles();
    const { userDetails } = useSelector(state => state.user)
    const [data,setData] = useState([])
    const [executives,setExecutives] = useState([])
    const [openModal,setOpenModal] = useState(false)
    const [assignFormData,setAssignFormData] = useState('')
    const [selectedProject,setSelectedProject] = useState('')
    const [poData,setPoData] = useState([])
    const [searchField, setSearchField] = useState("")
    useEffect(() => {

        getData();
        getExecutives();
    }, [userDetails])

    const getData = async () =>{
        let id = ''
        for(let i = 0;i<userDetails.roles.length;i++ ){
            if (userDetails.roles[i].name === "Vendor SO Executive"){
                id = userDetails._id
            }
        }
        props.setLoading(true)
        try {
            let response =  await HttpService.getTheVendorSOManage(id)
            setData(response.data)
            console.log(response.data)
        } catch (error) {
            console.error(error)
            props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const getExecutives = async () =>{
        props.setLoading(true)
        try {
            let response = await HttpService.getVendorSoExecutives()
            setExecutives(response.data)
        } catch (error) {
            console.error(error)
            props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const openDialog = (data) =>{
        setSelectedProject(data._id)
        setOpenModal(true)
    }

    const AssignUser = async () =>{
        props.setLoading(true)
        try {
            let response = await HttpService.updateVendorSoExecutive(selectedProject, assignFormData)
            props.setSnackInfo(response?.data ?? "Updated Successfully", "success")
            setOpenModal(false)
        } catch (error) {
            console.error(error)
            props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const searchProject = (e) => {
        setSearchField(e.target.value);
        const searchQuery = String(e.target.value).toLowerCase();
        let filteredData = [];
        for (let i = 0; i < data.length; i++) {
            if (String(data[i].projectId.code).toLowerCase().includes(searchQuery)) {
                filteredData.push(data[i]);
                setData(filteredData);
            }
        }
        if (e.target.value === "") {
            getData()
        }
    };
return (
        <div>
            <div style={{display:"flex",justifyContent:"space-between"}}>
            <div style={{ marginBottom: 30 }}>
                <h2 style={{ textAlign: 'center' }}>Vendor SO Management</h2>
            </div>
            <div style={{ marginBottom: 20 }}>
                <TextField
                    label="Search Project"
                    type="text"
                    // value={searchField}
                    name="searchField"
                    onChange={searchProject}
                    variant="outlined"
                />
            </div>
            </div>

            <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>SL No.</StyledTableCell>
                            <StyledTableCell>Project NO</StyledTableCell>
                            <StyledTableCell>SO for carpenter</StyledTableCell>
                            <StyledTableCell>Modular delivery date</StyledTableCell>
                            <StyledTableCell>Days from modular delivery</StyledTableCell>
                            <StyledTableCell>Days taken for assignment</StyledTableCell>
                            {(userDetails?.roles?.find(role => role.name === ROLENAME.VendorSOManager )) &&
                            <StyledTableCell>Assign Project</StyledTableCell>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((el, i) => (
                            <StyledTableRow key={i + 1}>
                                {console.log(el, "el")}
                                <StyledTableCell >{i + 1}</StyledTableCell>
                                <StyledTableCell >{el?.projectId?.code}</StyledTableCell>
                                    {el?.serviceOrders.length !== 0 ?
                                    <>
                                     <StyledTableCell>
                                        {el.serviceOrders.map((element)=>{
                                            return(
                                                <div style={{display:"flex", margin:"1rem"}}>
                                                    <div style={{ display: "flex", margin: "1rem" }}>
                                                <DownloadOutlined onClick={() => window.open(element?.soLink)} />
                                                </div>
                                                <div>
                                                <p style={{color:"blue"}}>{element.orderId}</p>
                                                </div>
                                                </div>
                                            )
                                        })
                                    }
                                    </StyledTableCell>
                                    </> :
                                    <>
                                    <StyledTableCell style={{margin:"1rem",textAlign : "center"}}>
                                         NA
                                    </StyledTableCell>
                                    </>
                                   }
                                <StyledTableCell>{moment(el?.createdAt).format('DD-MM-YYYY')}</StyledTableCell>
                                <StyledTableCell>{el?.daysFromModularDelivery}</StyledTableCell>
                                <StyledTableCell>{el?.daysTakenForAssignment}</StyledTableCell>
                                {(userDetails?.roles?.find(role => role.name === ROLENAME.VendorSOManager)) &&
                                    <Button variant="contained" color="primary" style={{ background: "#34bf73", margin: 5 }} onClick={() => openDialog(el)}>
                                    Assign Project</Button>
                                }
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog open={openModal} onClose={() => setOpenModal(false)} aria-labelledby="form-dialog-title" maxWidth='lg'>
                <DialogTitle id="form-dialog-title">Select User</DialogTitle>
                <Divider />
                <DialogContent>
                    {
                        executives &&
                        <Grid item sm={4}>
                            <FormControl>
                                <TextField
                                    select
                                    labelId="demo-stage-select-label"
                                    id="demo-stage-select"
                                    name="users"
                                    label="Users"
                                    onChange={(e) => setAssignFormData(e.target.value)}
                                    variant="outlined"
                                    style={{ width: 300 }}
                                    fullWidth
                                >
                                    {
                                        executives?.map((data, i) => (
                                            <MenuItem value={data._id} key={i + 1}>{data.name}</MenuItem>
                                        ))
                                    }
                                </TextField>
                            </FormControl>
                        </Grid>
                    }
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={() => setOpenModal(false)} variant="outlined">No</Button>
                    <Button onClick={() => AssignUser()} variant="contained" color="primary" >Assign</Button>
                </DialogActions>
            </Dialog>

        </div >
    )
}

export default connect(null, { setLoading, setSnackInfo })(VendorSoManage)
