import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import { setLoading, setSnackInfo } from '../redux/actions/appAction';
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow, TableHead } from '@material-ui/core';
import { ROLENAME, LEADSTATUS } from '../utils/index';
import { Typography } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment'
import { commaSeparateAmount } from './commaSeparate';
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }
}));

const ProposedContractForMonth = (props) => {
    const classes = useStyles();
    const [leadsData, setLeadsData] = useState([]);

    useEffect(() => {
        // setCanEdit(props.editable)
        checkUserRole(props.ROLE)
        // eslint-disable-next-line
    }, [props.SELECTEDDATES, props.SELECTEDEXPCENTER])

    const checkUserRole = (roleid) => {
        let data = [];
        if (roleid === ROLENAME.DesignUser) {
            props.DATA.proposedContractForTheMonth.forEach((ele, i) => {
                data.push({
                    leadId: ele?._id,
                    customerName: ele.customerId?.name,
                    budget: ele.grandTotal,
                    stage: ele.designStages,
                    contractSignDate: moment(ele.designSignOffDate).format('DD-MM-YYYY'),
                    expectedDesignSignOffDate: ele.expectedDesignSignOffDate
                })
                console.log(ele?._id);
            })
            setLeadsData(data.sort((a, b) => a.customerName.localeCompare(b.customerName)))
        } else if (roleid === ROLENAME.DesignManager) {
            props.DATA.proposedContractForTheMonth.forEach((ele, i) => {
                data.push({
                    leadId: ele?._id,
                    customerName: ele.customerId?.name,
                    designerName: ele.assignTo?.name,
                    budget: ele.grandTotal,
                    stage: ele.designStages,
                    contractSignDate: moment(ele.designSignOffDate).format('DD-MM-YYYY'),
                    expectedDesignSignOffDate: ele.expectedDesignSignOffDate
                })
            })
            setLeadsData(data.sort((a, b) => a.customerName.localeCompare(b.customerName)))
        } else {
            props.DATA.proposedContractForTheMonth.forEach((ele, i) => {
                data.push({
                    leadId: ele?._id,
                    customerName: ele.customerId?.name,
                    AssistanceDesignManager: ele.assignTo?.name,
                    designerName: ele.assignTo?.name,
                    budget: ele.grandTotal,
                    stage: ele.designStages,
                    contractSignDate: moment(ele.designSignOffDate).format('DD-MM-YYYY'),
                    expectedDesignSignOffDate: ele.expectedDesignSignOffDate
                })
            })
            setLeadsData(data.sort((a, b) => a.customerName.localeCompare(b.customerName)))
        }
    }

    return (
        <div>
            <div style={{ marginTop: 25, marginBottom: 25 }}>
                <Paper className={classes.paper}>
                    <Typography component="h3">{props.TITLE}</Typography>
                    {
                        <TableContainer component={Paper} style={{ marginTop: 10 }}>
                            <Table className={classes.table} stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow >
                                        <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Sl No</TableCell>
                                        <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Customer Name</TableCell>
                                        {
                                            props.ROLE === ROLENAME.DesignManager &&
                                            <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Designer</TableCell>
                                        }
                                        {
                                            props.ROLE === ROLENAME.DesignHead && <>
                                                <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>ADM / DM</TableCell>
                                                <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Designer</TableCell>
                                            </>
                                        }
                                        <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Budget</TableCell>
                                        <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Stage</TableCell>
                                        <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Expected Design Sign-off Date</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        leadsData.length !== 0 ?
                                            leadsData.map((status, i) => {
                                                return (
                                                    <TableRow key={i + 1}>
                                                        <TableCell component="th" scope="row" style={{ textAlign: 'center' }} >{i + 1}</TableCell>
                                                        <TableCell component="th" scope="row" style={{ textAlign: 'center' }} >
                                                        <Link to={`/project-logs/${status?.leadId}`} style={{ color: "#000", textDecoration: 'underline' }}>  {status.customerName}</Link>
                                                            </TableCell>
                                                        {
                                                            props.ROLE === ROLENAME.DesignManager &&
                                                            <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{status.designerName}</TableCell>
                                                        }
                                                        {
                                                            props.ROLE === ROLENAME.DesignHead && <>
                                                                <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{status.AssistanceDesignManager}</TableCell>
                                                                <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{status.designerName}</TableCell>
                                                            </>
                                                        }
                                                        <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>₹ {commaSeparateAmount(status.budget)}</TableCell>
                                                        <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{status.stage}</TableCell>
                                                        <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{status.expectedDesignSignOffDate ?  moment(status.expectedDesignSignOffDate).format('DD-MM-YYYY') :'-' }</TableCell>
                                                    </TableRow>
                                                )
                                            })
                                            :
                                            <TableRow>
                                                <TableCell colSpan={9} style={{ textAlign: "center" }}>No Data Found</TableCell>
                                            </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                </Paper>
            </div>
        </div>
    )
}

export default connect(null, { setLoading, setSnackInfo })(ProposedContractForMonth)
