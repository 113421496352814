import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux'
import { useParams, useHistory, Link } from "react-router-dom";
import { setLoading, setSnackInfo } from '../redux/actions/appAction';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { ROLENAME } from '../utils';
import moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import { Modal } from 'antd';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import HttpService from '../services/httpService';
const useStyles = makeStyles((theme) => ({
    table: {
        width: '80vw',
        textAlign: 'center'
    },
    root: {
        width: '100%',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
}));
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#ccc",
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);
function DesignHoldView(props) {
    const classes = useStyles()
    const { userDetails } = useSelector(state => state.user);
    const history = useHistory();
    const [lostApprovalLeads, setDesignSignoffHoldLeads] = useState([]);
    useEffect(() => {
        getAllDesignSignoffHoldLeads();
    }, [])
    const getAllDesignSignoffHoldLeads = async () => {
        props.setLoading(true)
        try {
            const response = await HttpService.getDesignSignoffHoldProjects()
            if (response.data == "No leads found.") {
                setDesignSignoffHoldLeads([])
            } else {
                setDesignSignoffHoldLeads(response.data);
            }
        } catch (error) {
            props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    return (
        <div>
            <div style={{ marginBottom: 20 }}>
                <h3 style={{ textAlign: 'center' }}>
                    Design Signoff Hold- Leads
                </h3>
            </div>
            <Divider />
            <Paper>
                <TableContainer component={Paper}>
                    <Table className={classes.table} stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow style={{ backgroundColor: '#333', color: '#fff' }}>
                                <TableCell>Sl. No</TableCell>
                                <TableCell>Customer Name</TableCell>
                                <TableCell>Lead No.</TableCell>
                                <TableCell>Unique Lead ID</TableCell>
                                <TableCell>Project No.</TableCell>
                                <TableCell>Designer Name</TableCell>
                                <TableCell>ADM Name</TableCell>
                                <TableCell>Date on which hold will be Revoked</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {lostApprovalLeads.length !== 0 ?
                                lostApprovalLeads.sort((a, b) => b.createdAt.split('-').reverse().join().localeCompare(a.createdAt.split('-').reverse().join())).map((row, i) => {
                                    return (
                                        <TableRow key={i + 1}>
                                            <TableCell>{i + 1}</TableCell>
                                            <TableCell component="th" scope="row"> {row?.customerId?.name} </TableCell>
                                            <TableCell component="th">
                                                <Link to={`/project-logs/${row?._id}`} style={{ color: "#000", textDecoration: 'underline' }}>{row?.lead_no || "N/A"}</Link>
                                            </TableCell>
                                            {/* <TableCell component="th">{moment(row?.createdAt).format('DD-MM-YYYY')}</TableCell> */}
                                            <TableCell component="th">{row?.uniqueLeadNumber}</TableCell>
                                            <TableCell component="th">{row?.erpProjectNo}</TableCell>
                                            <TableCell component="th">{row?.assignTo?.name || 'NA'}</TableCell>
                                            <TableCell component="th">{row?.designManager?.name || 'NA'}</TableCell>
                                            <TableCell component="th">{moment(row?.designSignoffRevokeDate || 'NA').format('DD-MM-YYYY')}</TableCell>

                                        </TableRow>
                                    );
                                })
                                :
                                <TableRow>
                                    <TableCell colSpan={9} style={{ textAlign: 'center' }}>No Records Found</TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    )
}
export default connect(null, { setLoading, setSnackInfo })(DesignHoldView)