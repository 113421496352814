import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import HttpService from '../../../services/httpService';
import { setLoading, setSnackInfo } from '../../../redux/actions/appAction'
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow, TableHead, MenuItem, DialogContentText } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import { useSelector } from 'react-redux';
import { ROLENAME } from '../../../utils/index';
import TableFooter from '@material-ui/core/TableFooter';
import { commaSeparateAmount } from '../../../components/commaSeparate';
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));

const ManageTarget = (props) => {
    const classes = useStyles();
    const [budgetSouce, setBudgetSouce] = useState([]);
    const [departmentList, setDepartmentList] = useState([]);
    const [dupDepartmentList, setDupDepartmentList] = useState([]);
    const [teamList, setTeamList] = useState([]);
    const [monthwise, setMonthwise] = useState([]);
    const { userDetails } = useSelector(state => state.user)
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const [filterData, setFilterData] = useState({
        department: "",
        team: "",
        year: ""
    })
    const { department, team, year } = filterData

    useEffect(() => {
        getDepartmentList()
        // eslint-disable-next-line
    }, [])

    const getDepartmentList = async () => {
        props.setLoading(true)
        try {
            const result = await HttpService.getDepartments()
            setDupDepartmentList(result.data);
            if (userDetails?.roles?.find(role => role.name === ROLENAME.SalesManager || role.name === ROLENAME.MarketingManager || role.name === ROLENAME.AssistantSalesManager)) {
                // checkLoginUserDetail();
                const loginDept = result.data.filter(ele => ele._id === userDetails.departmentId);
                let ddata = [];
                let teamName = [];
                loginDept.forEach(ele => {
                    ddata.push({ name: ele.name, _id: ele._id })
                    ele.teams.forEach(ele1 => {
                        if (ele1._id === userDetails.teamId._id) {
                            teamName.push({ name: ele1.name, _id: ele1._id })
                        }
                    })
                })
                setDepartmentList(ddata)
                setTeamList(teamName)
                setFilterData({ ...filterData, 'department': ddata[0]._id, 'team': teamName[0]._id })
                // getTeamList(ddata[0]._id);
                // const team = result.data.filter(ele => ele._id === ddata[0]._id)
                // setTeamList(team[0]['teams'])
                years();
            } else {
                let departName = [];
                result.data.forEach(ele => {
                    departName.push({ name: ele.name, _id: ele._id })
                })
                setDepartmentList(departName);
            }
        } catch (error) {
            props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }


    const getSelectedDepartment = (e) => {
        setFilterData({ ...filterData, [e.target.name]: e.target.value })
        getTeamList(e.target.value);
        years();
    }

    const getTeamList = (tid) => {
        const team = dupDepartmentList.filter(ele => ele._id === tid)
        setTeamList(team[0]['teams'])
    }

    const getAllDetails = async () => {
        props.setLoading(true)
        try {
            const result = await HttpService.userTarget(filterData.year, filterData.team)
            setBudgetSouce(result.data.sort((a, b) => a.customerole.localeCompare(b.customerole)));
            let sortedData = result.data.sort((a, b) => a.customerole.localeCompare(b.customerole));
            let arr = []
            for (let i = 1; i < sortedData.length; i++) {
                for (let j = 0; j < sortedData[i].budgetArray.length; j++) {
                    arr.push(sortedData[i].budgetArray[j])
                }
            }
            let obj = [];
            for (let s = 0; s < 12; s++) {
                obj.push({ month: s, value: 0 })
            }
            for (let p = 0; p < obj.length; p++) {
                for (let k = 0; k < arr.length; k++) {
                    if (arr[k].month === obj[p].month && arr[k].value !== "NA") {
                        obj[p].value += arr[k].value;
                    }
                }
            }
            setMonthwise(obj)
        } catch (error) {
            console.error(error)
            props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const [openBudgetModal, setOpenBudgetModal] = useState(false);
    const [budgetValue, setBudgetValue] = useState(0)
    const [isNewBudget, setIsNewBudget] = useState(false);
    const [selectedUser, setSelectedUser] = useState({})
    const [selectedMonth, setSelectedMonth] = useState({})

    const [fieldLabel, setFieldLabel] = useState('');
    const setBudget = (budgetId, selectedUsr, budgetVal) => {
        setOpenBudgetModal(true);
        setBudgetValue(budgetVal.value);
        setSelectedUser(selectedUsr)
        setSelectedMonth(budgetVal)
        if (budgetId === undefined || !budgetId) {
            setIsNewBudget(false)
        } else {
            setIsNewBudget(true)
        }
        if (userDetails?.roles?.find(role => role.name === ROLENAME.SalesHead || role.name === ROLENAME.SalesManager || role.name === ROLENAME.SalesUser || role.name === ROLENAME.AssistantSalesManager)) {
            setFieldLabel('Set Budget Value')
        } else {
            setFieldLabel('Set Target Value')
        }
    }

    const saveBudget = async (e) => {
        e.preventDefault();
        if (budgetValue < 0) return;

        if (isNewBudget) {
            let data = {
                value: budgetValue
            }
            props.setLoading(true)
            try {
                await HttpService.editTarget(selectedMonth._id, data)
                props.setSnackInfo("Target Updated Successfully", "Success")
                setOpenBudgetModal(false);
                getAllDetails()
            } catch (error) {
                props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
            }
            props.setLoading(false)

        } else {
            let data = {
                year: filterData.year,
                month: selectedMonth.month,
                value: budgetValue,
                userId: selectedUser.userId
            }
            props.setLoading(true)
            try {
                await HttpService.newTarget(data)
                props.setSnackInfo("Target Added Successfully", "Success")
                setOpenBudgetModal(false);
                getAllDetails()
            } catch (error) {
                props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
            }
            props.setLoading(false)
        }
    }

    const clearFilter = () => {
        setBudgetSouce([]);
        setFilterData({
            department: "",
            team: "",
            year: ""
        })
    }
    const [yearsList, setYearsList] = useState([])

    const years = () => {
        const year = new Date().getFullYear();
        let lastyears = []
        lastyears = Array.from({ length: 5 }, (v, i) => year - 5 + i + 1);
        setYearsList(lastyears)
    }

    return (
        <div>
            <div style={{ marginTop: 15, marginBottom: 15, textAlign: 'right' }}>
                <TextField
                    select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="department"
                    value={department}
                    label="Select Department"
                    onChange={getSelectedDepartment}
                    variant="outlined"
                    style={{ marginRight: 20, width: 200 }}
                    size='small'
                >
                    {
                        departmentList?.map((data, i) => (
                            <MenuItem value={data._id} key={i + 1}>{data.name}</MenuItem>
                        ))
                    }
                </TextField>
                <TextField
                    select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="team"
                    value={team}
                    label="Select Team"
                    onChange={(e) => setFilterData({ ...filterData, [e.target.name]: e.target.value })}
                    variant="outlined"
                    style={{ marginRight: 20, width: 200 }}
                    size='small'
                    disabled={!department}
                >
                    {
                        teamList?.map((data, i) => (
                            <MenuItem value={data._id} key={i + 1}>{data.name}</MenuItem>
                        ))
                    }
                </TextField>
                <TextField
                    select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="year"
                    value={year}
                    label="Select Year"
                    onChange={(e) => setFilterData({ ...filterData, [e.target.name]: e.target.value })}
                    variant="outlined"
                    style={{ marginRight: 20, width: 200 }}
                    size='small'
                    disabled={!team}
                >
                    {
                        yearsList?.map((data, i) => (
                            <MenuItem value={data} key={i + 1}>{data}</MenuItem>
                        ))
                    }
                </TextField>
                <Button disabled={!department || !team || !year} onClick={getAllDetails} variant="contained" color="primary">View Details</Button>
                <Button onClick={clearFilter} variant="contained" color="secondary" style={{marginLeft:10}}>clearFilter</Button>
            </div>

            <Paper className={classes.paper}>
                <TableContainer component={Paper} style={{ marginTop: 40 }}>
                    <Table className={classes.table} stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow >
                                <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Source</TableCell>
                                {
                                    months.map((row, i) => {
                                        return (
                                            <TableCell key={i + 1} style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>{row}</TableCell>
                                        )
                                    })
                                }
                                <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Total</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {budgetSouce.map((row, i) => {
                                return (<>
                                    <TableRow key={i + 1}>
                                        <TableCell component="th" scope="row" style={{ padding: 10, width: 500, textAlign: 'center' }}>
                                            {row.customerole === 'Manager' ?
                                                <>
                                                    <b>{row.name}</b>
                                                    <span><b>(Manager)</b></span>
                                                </>
                                                :
                                                row.name
                                            }
                                        </TableCell>
                                        {
                                            row.budgetArray.map((row1, i) => {
                                                return (
                                                    <TableCell key={i + 1} component="th" scope="row" style={{ padding: 10, width: 500, textAlign: 'center' }}>
                                                        {commaSeparateAmount(row1.value)}
                                                        <EditTwoToneIcon
                                                            style={{ marginTop: -5, marginLeft: 5, cursor: 'pointer', fontSize: "inherit" }}
                                                            onClick={(e) => setBudget(row1._id, row, row1)} />
                                                    </TableCell>
                                                )
                                            })
                                        }
                                        <TableCell component="th" scope="row" style={{ padding: 10, width: 500, textAlign: 'center' }}>
                                            {commaSeparateAmount(row.total)}
                                        </TableCell>
                                    </TableRow>
                                </>
                                );
                            })}
                        </TableBody>
                        <TableFooter style={{ height: "80px" }}>
                            <TableRow key="ft1">
                                <TableCell component="th" scope="row" style={{ padding: 10, width: 500, textAlign: 'center' }}>
                                    <b>Team Target  </b>
                                </TableCell>
                                {monthwise.map((user, i) => (
                                    <TableCell key={i + 1} component="th" scope="row" style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 900, color: "#000" }}>
                                        {commaSeparateAmount(user.value)}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Paper>

            <Dialog open={openBudgetModal} onClose={() => setOpenBudgetModal(false)} aria-labelledby="form-dialog-title" fullWidth maxWidth='sm'>
                <DialogTitle id="form-dialog-title">
                    Set {userDetails?.roles?.find(role => role.name === ROLENAME.SalesManager || role.name === ROLENAME.SalesUser) ? `Budget` : `Target`} for - {selectedUser.name}
                </DialogTitle>

                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description" style={{ display: "flex", flexDirection: "column" }}>
                        <TextField
                            label={fieldLabel}
                            value={budgetValue}
                            style={{ marginBottom: 10 }}
                            name="budgetValue"
                            onChange={(e) => setBudgetValue(e.target.value)}
                            variant="outlined"
                            size="small"
                            type="number" />
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => setOpenBudgetModal(false)} variant="contained" color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={saveBudget} variant="contained" color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default connect(null, { setLoading, setSnackInfo })(ManageTarget)
