import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import HttpService from '../../services/httpService';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const CustomerEscalation = ({onEscalationData}) => {
  const classes = useStyles();
  const { customerDetails } = useSelector((state) => state.customer);
  const [escalationRoles, setEsclationRoles] = useState([]);
  const [userEsclation,setUserEsclation] = useState([])

  const getAssignedUser = async () => {
    try {
      let res = await HttpService.getAssignedUser(customerDetails.leadId);
      getManagementRoles(res.data);
    } catch (error) {
      console.error("Token not available.");
    }
  };

  const getManagementRoles = (data) => {
    let userDetails = data.userDetails;
    let userManagers = data.userDetailed;
  
    let updatedRoles = [];
    let updatedRolesForUser = []
  
    for (let i = 0; i < userDetails.length; i++) {

      for (let j = 0; j < userManagers.length; j++) {
        if (
          userDetails[i]?.teamId === userManagers[j]?.teamId
        ) {
          // Check if the execution Manager
          if (
            userManagers[j].roles.find(
              (role) =>
                role.name === 'Execution Manager' &&
                userDetails[i]?.teamId === userManagers[j]?.teamId
            )
          ) {
            updatedRoles.push({
              role: 'Execution Manager',
              user: { ...userManagers[j], teamId: userDetails[i]?.teamId },
            });
          }
  
          // Check if the user is a Design Manager
          if (
            userManagers[j].roles.find(
              (role) =>
                role?.name === 'Design Manager' &&
                userDetails[i]?.teamId === userManagers[j]?.teamId
            )
          ) {
            updatedRoles.push({
              role: 'Design Manager',
              user: { ...userManagers[j], teamId: userDetails[i]?.teamId },
            });
          }
          //for execution manager
          if (
            userManagers[j].roles.find(
              (role) =>
                role.name === 'Sales Manager' &&
                userDetails[i]?.teamId === userManagers[j]?.teamId
            )
          ) {
            updatedRoles.push({
              role: 'Sales Manager',
              user: { ...userManagers[j], teamId: userDetails[i]?.teamId },
            });
          }

          //sales user for roles
          if (
            userDetails[i]?.roles.find(
              (role) =>
                role.name === 'Sales User' &&
                userDetails[i]?.teamId === userManagers[j]?.teamId
            )
          ) {
            updatedRoles.push({
              role: 'Sales',
              user: { ...userDetails[i], teamId: userDetails[i]?.teamId },
            });
          }
           //design user for roles
           if (
            userDetails[i]?.roles.find(
              (role) =>
                role.name === 'Design User' &&
                userDetails[i]?.teamId === userManagers[j]?.teamId
            )
          ) {
            updatedRoles.push({
              role: 'Design',
              user: { ...userDetails[i], teamId: userDetails[i]?.teamId },
            });
          }
           //exwecution user for roles
           if (
            userDetails[i]?.roles.find(
              (role) =>
                role.name === 'Execution User' &&
                userDetails[i]?.teamId === userManagers[j]?.teamId
            )
          ) {
            updatedRoles.push({
              role: 'Execution',
              user: { ...userDetails[i], teamId: userDetails[i]?.teamId },
            });
          }
  
         
        }
      }
    }
  
    // Use Set to ensure unique entries based on teamId and role
    const uniqueRolesSet = new Set(
      updatedRoles.map((role) => `${role.userDetailsTeamId}-${role.role}`)
    );

    // const uniqueRolesSetForUser = new Set(
    //   updatedRolesForUser.map((role) => `${role.userDetailsTeamId}-${role.role}`)
    // );
  
    // Convert Set back to an array
    const uniqueRolesArray = Array.from(uniqueRolesSet).map((uniqueKey) =>
      updatedRoles.find((role) => `${role.userDetailsTeamId}-${role.role}` === uniqueKey)
    );

  //   //for the user
  //   const uniqueRolesArrayForUser = Array.from(uniqueRolesSetForUser).map((uniqueKey) =>
  //   updatedRolesForUser.find((role) => `${role.userDetailsTeamId}-${role.role}` === uniqueKey)
  // );
  
  localStorage.setItem('uniqueRolesArray', JSON.stringify(uniqueRolesArray));

  
  // setUserEsclation(uniqueRolesArrayForUser)
  setEsclationRoles(uniqueRolesArray); // Update the state once with the new array
  onEscalationData(uniqueRolesArray);

  };  
 
  

  useEffect(() => {
    getAssignedUser();
  }, []);

  return (
<>
</>
  );
};

export default CustomerEscalation;