import React, { useState, useEffect } from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import { setLoading, setSnackInfo } from '../redux/actions/appAction'
import Tooltip from '@material-ui/core/Tooltip';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import HttpService from '../services/httpService';
import { DownloadOutlined } from '@ant-design/icons'
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import {DialogTitle} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';

function LinearProgressWithLabel(props) {
  return (
    <Box display="block" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={50}>
        <Typography variant="body2" color="textSecondary">{`${props.value.toFixed(2)}%`}</Typography>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 850,
  }
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#ccc",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);



const PaymentRequestView = (props) => {
  const classes = useStyles();

  const [paymentList, setPaymentList] = useState([])
  const [openDialog,setOpenDialog] = useState(false)
  const [totalData,setTotalData ] = useState({
    attachment:"",
    remarks:""
  })
  const [fileUploaded,setFileUploaded] = useState(false)
  const [selectedData,setSelectedData]  = useState({})

  useEffect(() => {
    setData()
    // eslint-disable-next-line
  }, [props])

  const setData = () => {
    console.log(props,"props")
    setPaymentList(props.PAYMENTLIST)
  }
  const [amountType, setAmountType] = useState([])
  const handleAmountRadioChange = (e, index) => {

    let parsedAmountType = JSON.parse(JSON.stringify(amountType))
    let type = parsedAmountType.find((item, idx) => idx === index)
    type = {}
    type.type = e.target.value
    type.value = ""
    parsedAmountType[index] = type
    setAmountType(parsedAmountType)
  }

  const handleAmountChange = (e, index) => {
    let parsedAmountType = JSON.parse(JSON.stringify(amountType))
    const type = parsedAmountType.find((item, idx) => idx === index)
    if (type.type === "AMOUNT" && +e.target.value <= +paymentList[index].remainingAmount) {
      type.value = e.target.value
      type.amount = e.target.value
      parsedAmountType[index] = type
      setAmountType(parsedAmountType)
    } else if (type.type === "PERCENTAGE" && (Number(((paymentList[index]?.remainingAmount / paymentList[index]?.total) * 100).toFixed(2)) >= e.target.value)) {
      let value = (paymentList[index]?.total * e.target.value) / 100
      type.amount = value
      type.value = e.target.value
      parsedAmountType[index] = type
      setAmountType(parsedAmountType)
    }
  }


  const raisePayementRequest = async (index) =>
  {
    

    props.setLoading(true)
    try
    {
      if(props.RADIOVALUE === 'PO' || props.RADIOVALUE === 'SO')
      {
        let missing_docs = ''

        if(!paymentList[index].vendorAadhar || (paymentList[index].vendorAadhar.length < 1))
        {
          missing_docs += 'Aadhar Card,'
          
        }

        if(!paymentList[index].vendorPAN || (paymentList[index].vendorPAN.length < 1))
        {
          missing_docs += 'PAN Card,'
          
        }

        if(missing_docs.length > 0)
        {
          props.setLoading(false)
          props.setSnackInfo(`${paymentList[index].vendorName} did not provided ${missing_docs} can't raise PO/SO for them.`, 'error')
          return
        }
      }

      if (props.RADIOVALUE === "SO")
      {
        const response = await HttpService.raiseSOPaymentRequest(paymentList[index].id, { amount: amountType[index].amount }, props.SCOPE)
        if (response.status === 200) {
          props.setSnackInfo("Payment Request Raised Successfully", "success")
          setAmountType([])
        }else if (response.status === 406) {
          props.setSnackInfo("Payment request already raised", "error")
          setAmountType([])
          
        }
      }
      else
      {
        const response = await HttpService.raisePOPaymentRequest(paymentList[index].id, { amount: amountType[index].amount }, props?.SCOPE)
        if (response.status === 200) {
          props.setSnackInfo("Payment Request Raised Successfully", "success")
          setAmountType([])
          
        } else if (response.status === 406) {
          setAmountType([])
          props.setSnackInfo("Payment request already raised", "error")
        }
      }
      props.setLoading(false)
    }
    catch (error)
    {
      console.error(error)
      props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
    }

    props.REFRESH()

    props.setLoading(false)
  }

  
  const openDialogBox = (data, index)=>{
    setSelectedData(data)
    try{
      props.setLoading(true)
      if (props.RADIOVALUE === 'PO' || props.RADIOVALUE === 'SO') {
        let missing_docs = ''

        if (!paymentList[index].vendorAadhar || (paymentList[index].vendorAadhar.length < 1)) {
          missing_docs += 'Aadhar Card,'

        }

        if (!paymentList[index].vendorPAN || (paymentList[index].vendorPAN.length < 1)) {
          missing_docs += 'PAN Card,'
        }
        if (missing_docs.length > 0) {
          props.setLoading(false)
          props.setSnackInfo(`${paymentList[index].vendorName} did not provided ${missing_docs} can't raise PO/SO for them.`, 'error')
          return
        } else {
          props.setLoading(false)
          setOpenDialog(true)
        }
      }
    }catch(err){
      props.setSnackInfo(`Error`, 'error')
    }  
  }

  const onImageChange = async (e) => {
    props.setLoading(true)
    const fd = new FormData()
    fd.append('file', e.target.files[0])
    fd.append('customerName', selectedData.orderId + '_' + selectedData.code)
    try {
      const fileUploadRes = await HttpService.uploadFile(fd);
      setTotalData({
        ...totalData,
        attachment: fileUploadRes.data
      })
      setFileUploaded(true);
    } catch (err) {
      props.setSnackInfo(err?.response?.data ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  const raisePayementCompleted = async()=>{
    try{
      let obj = {
        amount: selectedData.total,
        attachment: totalData.attachment,
        remarks: totalData.remarks
      }
      
      if (props.RADIOVALUE === "SO")
      {
        const response = await HttpService.soPaymentCompltedRequest(selectedData.id, obj, props?.SCOPE)
        if (response.status === 200) {
          props.setSnackInfo("Payment Request Raised Successfully", "success")
          setAmountType([])
        }else if (response.status === 406) {
          props.setSnackInfo("Payment request already raised", "error")
          setAmountType([])
          
        }
      }
      else
      {
        const response = await HttpService.raisePOPaymentCompleted(selectedData.id, obj, props?.SCOPE)
        if (response.status === 200) {
          props.setSnackInfo("Payment Request Raised Successfully", "success")
          setAmountType([])
          
        } else if (response.status === 406) {
          setAmountType([])
          props.setSnackInfo("Payment request already raised", "error")
        }
      }
      
    }catch (err){
      console.error(err)
      props.setSnackInfo(err?.response?.data ?? "Something Went Wrong", "error")
    }
    
    setOpenDialog(false)
    props.REFRESH()
    props.setLoading(false)

  }
  return (
    <div>
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Sl No</StyledTableCell>
              <StyledTableCell>Order Id</StyledTableCell>
              <StyledTableCell>Client & Code</StyledTableCell>
              <StyledTableCell>Vendor</StyledTableCell>
              <StyledTableCell>Created At</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Total</StyledTableCell>
              <StyledTableCell>Type</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paymentList.map((data, i) => <StyledTableRow key={i + 1}>
              <StyledTableCell>{i + 1}</StyledTableCell>
              <StyledTableCell>{data?.orderId}</StyledTableCell>
              <StyledTableCell>{data?.code}</StyledTableCell>
              <StyledTableCell>{data?.vendorName}</StyledTableCell>
              <StyledTableCell>{data?.createdAt}</StyledTableCell>
              <StyledTableCell>{data?.status}</StyledTableCell>
              <StyledTableCell>{data?.total}</StyledTableCell>
              <StyledTableCell>
                <FormControl component="fieldset">
                  <RadioGroup aria-label="type" name="type" value={amountType[i]?.type} onChange={(e) => handleAmountRadioChange(e, i)}>
                    <div className='modal-header-radio'>
                      <FormControlLabel value="AMOUNT" control={<Radio />} label="Amount" />
                      <FormControlLabel value="PERCENTAGE" control={<Radio />} label="Percentage" />
                    </div>
                  </RadioGroup>
                  {amountType[i]?.type && <Tooltip title={`Remaining value ${amountType[i]?.type === "PERCENTAGE" ? `${((data?.remainingAmount / data?.total) * 100).toFixed(2)}%` : `${data?.remainingAmount}₹`} `}>
                    <TextField InputLabelProps={{ shrink: true }} type="number" onChange={(e) => handleAmountChange(e, i)} value={amountType[i]?.value} label={amountType[i]?.type} variant="outlined" />
                  </Tooltip>}
                  {amountType[i]?.type === "PERCENTAGE" && <TextField style={{ marginTop: 10 }} InputLabelProps={{ shrink: true }} type="number" disabled={true} label="AMOUNT" value={amountType[i]?.amount} variant="outlined" />}
                </FormControl>
              </StyledTableCell>
                <StyledTableRow >
                  <StyledTableCell style={{ cursor: "pointer" }} >
                  <Button variant="contained" color="primary" style={{ background: "#F50057", margin: 5 }} onClick={() => raisePayementRequest(i)} disabled={data?.paymentCompleted}>Raise Payment</Button>
                  </StyledTableCell>
                </StyledTableRow>
              <StyledTableRow >
                <StyledTableCell style={{ cursor: "pointer" }} >
                <Button variant="contained" color="primary" style={{ background: "#F50057", margin: 5 }} onClick={() => openDialogBox(data, i)} disabled={data?.paymentCompleted}>Payment Completed</Button>
                </StyledTableCell>
              </StyledTableRow>
              <Tooltip title={`Download ${props.RADIOVALUE}`} >
                <StyledTableRow style={{ cursor: "pointer" }}>
                  <StyledTableCell onClick={() => window.open(data.link)}><DownloadOutlined /></StyledTableCell>
                </StyledTableRow>
              </Tooltip>
              <StyledTableRow>
                {data.paymentCompleted === false ?
                <StyledTableCell>
                  <LinearProgressWithLabel value={((data?.total - data?.remainingAmount) / data?.total) * 100} />
                </StyledTableCell>
                :<>
                    <StyledTableCell>Payment Completed</StyledTableCell>
                </>
                }
              </StyledTableRow>
            </StyledTableRow>)}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} aria-labelledby="form-dialog-title" maxWidth='lg'>
        <DialogTitle id="form-dialog-title">Verify Details</DialogTitle>
        <FormControl style={{ marginLeft: 10, marginRight: 10, marginTop: 20, marginBottom: 10, display: 'block' }}>
           <>
              <input
                accept="*"
                className={classes.input}
                id="contained-button-file"
                multiple
                type="file"
                onChange={onImageChange}
                style={{ display: 'none' }}
              />
              <label htmlFor="contained-button-file">
                <Button variant="contained" color="primary" component="span" startIcon={<CloudUploadIcon />}>
                  upload payment attachment
                </Button>
                {
                  fileUploaded && <CheckCircleIcon style={{ verticalAlign: 'middle', marginLeft: 10, color: "green", fontSize: 30 }} />
                }
              </label>
          
              <TextField
              autoFocus
              margin="dense"
              label="Remarks"
              fullWidth
              onChange={(e) => setTotalData({
                ...totalData,
                remarks: e.target.value
              })}
              />
      
            </>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)} color="secondary" variant='outlined'>
              Cancel
            </Button>
            <Button onClick={() => raisePayementCompleted()} color="primary" variant='contained'>
              Submit
            </Button>
          </DialogActions>
        </FormControl>
      </Dialog>

    </div >
  )
}

export default connect(null, { setLoading, setSnackInfo })(PaymentRequestView)
