import React, { useEffect, useState } from "react";
import HttpService from "../../services/httpService";
import { useSelector } from "react-redux";
import {
  Card,
  CardContent,
  Container,
  Stepper,
  Step,
  StepLabel,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import moment from "moment";
import { connect } from 'react-redux'
import { setCustomerDetails } from '../../redux/actions/customerAction';
import CustomerEscalation from "./customerEscalation";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import PersonIcon from "@material-ui/icons/Person";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import { setLoading, setSnackInfo } from "../../redux/actions/appAction";

const useStyles = makeStyles((theme) => ({
  verticalRoot: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    paddingLeft: theme.spacing(2),
  },
  designStagess: {
    display: "flex",
    flexDirection: "row", // Adjusted to "row" for horizontal alignment
    alignItems: "center",
    paddingLeft: theme.spacing(2),
  },
  buttonContainer: {
    display: 'flex',
    margin: theme.spacing(2),
    alignItems: 'center',
  },
  button: {
    textTransform: 'none',
    fontSize: '12px',
    margin:"3px",
    height: '30px',  // Set a fixed height for all buttons
  },

  root: {
    width: "100%",
    "& .MuiStepIcon-active": { color: "orange" },
    "& .MuiStepIcon-completed": { color: "green" },
    "& .Mui-disabled .MuiStepIcon-root": { color: "gray" },
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
  },
  marginRight: {
    marginRight: theme.spacing(1), // Adjust the margin as needed
  },
}));

const CustomerDashboard = (props) => {
  const classes = useStyles();

  //lead details

  const [leadDetails, setLeadDetails] = useState([]);
  const [designerDetails,setDesignerDetails] = useState([])
  const { customerDetails } = useSelector((state) => state.customer);
  const [step, setStep] = useState(0);

  //sales stage
  const [salesStep, setSalesStep] = useState([]);
  //design milesStone
  const [logs, setLogs] = useState([]);
  const [designStep, setDesignStep] = useState(0);
  const [designStage, setDesignStage] = useState([]);

  //imos milesStone
  const [imosLogs, setImosLogs] = useState([]);
  const [preProdctionStep, setPreproductionStep] = useState(0);
  const [preProductionStages, setPreproductionStage] = useState([]);

  //for production stage
  const [productionLogs, setProductionLogs] = useState([]);
  const [productionStep, setProductionStep] = useState(0);
  const [productionStage, setProductionStage] = useState([]);

  //for on-site (Execution)
  const [onsiteLogs, setOnSiteLogs] = useState([]);
  const [onsiteStep, setOnSiteStep] = useState(0);
  const [onSiteStages, setOnSiteStages] = useState([]);

  //designer assignment logs
  const [designerAssigned,setDesignerAssigned] = useState([])


  //store role data 
  const [newRoles, setNewRoles] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);

  //for desginer
  const [openDesignDialog, setOpenDesignDialog] = useState(false);

  const [openDialogForChm, setOpenDialogChm] = useState(false);

  const [store, setStore] = useState([]);

  //esclation hook
  const [esclation, setEsclationRoles] = useState([]);

  //for completed
  const [completedStep, setCompletedStep] = useState(0);

  //get clicked value
  const [clickedRole, setClickedRole] = useState(null);

  //store chm details
  const [chmDetails,setChmDetails] = useState([])

  //new quptotation 
  const [revisedQuotation,setRevisedQuotation] = useState("")

  //store payment details 
  const [paymentDetails,setCustomerPaymentDetails] = useState([])

  //sales quotation
  const [salesQuotation,setSalesQuotation] = useState("")
  const [designQuotation,setDesignQuotation] = useState("")
  const [updatedDesignStage, setUpdatedDesignStage] = useState([]);
  const [designHoldStage, setDesignHoldStage] = useState("");

  const milestones = [
    "On Board",
    "Design",
    "Pre Production",
    "Production",
    "On Site",
  ];

  const getCustomerDetails = async () => {
    try {
      const res = await HttpService.getCustomerDetails(customerDetails._id);
      let sort =res.data.leadDetails.designerChangeLogs.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate))
       setDesignerAssigned(sort)
      setLeadDetails(res.data.leadDetails);
      getPreviousDesigner(res.data.leadDetails.previouslyAssignedTo)
      setSalesStep(res.data.leadDetails.customerApproved);
      getMileStone(res.data.leadDetails);
    } catch (error) {
      console.error("Token not available.");
    }
  };

  let customerStagesArray = [];
  const getDesignStageList = async ()=>{
    props.setLoading(true);
    try {
        const department = await HttpService.getDesignStageList();
        let designData = department?.data;
        for(let i=0; i<designData.length; i++){
          if(designData[i]?.name === 'Design'){
            setUpdatedDesignStage(designData[i]?.stages);
            customerStagesArray = designData[i]?.customerStages;
            return designData[i]?.stages;
          }
        }
    }
    catch(err){
      console.log(err);
    }
    props.setLoading(false);
  }

  const designStages = [
    "Designer Assigned",
    "Design Sign-off",
    "QC User Approval",
    "QC Manager Approval",
    "ADM/DM Approval",
    "Customer Approved Design Contract",
    "Finance Approval",
  ];
  const preProductionStage = [
    "Production Drawing Completed",
    "Move to Production",
  ];
  const production = ["Production Drawing Recieved", "Dispatched"];
  const onSite = ["Under Execution", "Completed"];

  const getCustomerLogs = async () => {
    props.setLoading(true)
    try {
      const res = await HttpService.getCustomerLogs(customerDetails.leadId);

      if (res.data.leadLogs && res.data.leadLogs.length > 0) {
        //on site work
        setOnSiteLogs((prevLogs) => {
          const updatedOnSiteLogs = res.data.leadLogs.map((log) => {
            let stage = null;
            let createdAt = null;
        
            // Update the conditions based on your On Site stages
            if (log.stage || log.dealActivity) {
              if (log.stage === "Under Execution" || (log.dealActivity && log.dealActivity.includes("status to Under Execution"))) {
                stage = "Under Execution";
                createdAt = log.createdAt;
              } 
              if(log.stage === 'Complete' || log.dealActivity &&  log.dealActivity.includes("status to Completed")){
                stage = "Completed";
                createdAt = log.createdAt;
              }
             if (log.stage === "Completed-MWP") {
                stage = "Completed-MWP";
                createdAt = log.createdAt;
              }

            }
        
            return {
              ...log,
              stage,
              createdAt,
            };
          });

          const stagesToAppend = updatedOnSiteLogs
            .filter((log) => log.stage && !onSite?.includes(log.stage))
            .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
            .map((log) => log.stage);

        

          const updatedpreProductionStages = [
            onSite[0],
            ...stagesToAppend,
            ...onSite.slice(1),
          ];
          setOnSiteStages(updatedpreProductionStages);
          getOnSiteStep(updatedOnSiteLogs);

          return updatedOnSiteLogs;
        });

        //for production

        setProductionLogs((prevLogs) => {
          const updatedLogsforProduction = res.data.leadLogs.map((log) => {
              let stage = null;
              let createdAt = null;
      
              if(log.stage || log.dealActivity){
                  if ((log.stage && log.stage === "Material Recieved") || log.dealActivity &&  (log.dealActivity.includes("material has been received in the factory.") || log.dealActivity.includes("Project stage to Site Bom Completed"))) {
                      stage = "Production Drawing Recieved";
                      createdAt = log.createdAt;
                  }
                  if ((log.stage && log.stage === "Under Production - Machine") || log.dealActivity && log.dealActivity.includes("Under Production - Machine")){
                      stage = "Under Production - Machine";
                      createdAt = log.createdAt;
                  }
                  if ((log.stage && log.stage === "Under Production - Fixing") || log.dealActivity && log.dealActivity.includes("Under Production - Fixing")) {
                      stage = "Under Production - Fixing"; // Corrected
                      createdAt = log.createdAt;
                  }
                  
                  if ((log.stage && log.stage === "Ready for dispatch") || log.dealActivity && log.dealActivity.includes("Ready for dispatch")) {
                      stage = "Ready for dispatch";
                      createdAt = log.createdAt;
                  }
                  if (log.stage && log.stage === "Under Execution" || (log.dealActivity && (log.dealActivity.includes("Under Execution and Material Dispatched") || log.dealActivity.includes("status to Completed and Execution")))) {
                      stage = "Dispatched";
                      createdAt = log.createdAt;
                  }
              }
              return { ...log, stage, createdAt };
          });
      
          const stagesToAppend = updatedLogsforProduction
              .filter((log) => log.stage && !production.includes(log.stage))
              .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
              .map((log) => log.stage);
      
          const dispatchedActive = updatedLogsforProduction.some((log) => log.stage === "Dispatched");
      
          if (dispatchedActive) {
              // Find the dispatched log to get its createdAt date
              const dispatchedLog = updatedLogsforProduction.find((log) => log.stage === "Dispatched");
              const dispatchedCreatedAt = dispatchedLog ? dispatchedLog.createdAt : null;
          
              // Create a new array with the updated values
              const updatedLogs = [...updatedLogsforProduction];
      
              // Iterate through the production stages
              productionStage.forEach((designStage) => {
                  if (!updatedLogs.some((log) => log.stage === designStage)) {
                      // Set the createdAt date of the inactive stage to the dispatched createdAt date
                      updatedLogs.push({ stage: designStage, createdAt: dispatchedCreatedAt });
                  }
              });
      
              // Update the state with the new array
              setProductionLogs(updatedLogs);
          }
          
          let stagesToAppends = new Set([...stagesToAppend]);
      
          const updatedpreProductionStages = [
              production[0],
              ...stagesToAppends,
              ...production.slice(1),
          ];
      
          setProductionStage(updatedpreProductionStages);
          getProductionStep(updatedLogsforProduction);
      
          return updatedLogsforProduction;
      });
      

        //for preproduction(imos)
        setImosLogs((prevLogs) => {
          const updatedLogsforImos = res.data.leadLogs.map((log) => {
            let stage = null;
            let createdAt = null;
        
            if (log.stage || log.dealActivity) {
              if (log.stage === "IMOS Drawing In-Progress" || log.stage ==="Assign To IMOS User" || 
                  (log.dealActivity && log.dealActivity.includes("IMOS Completed"))
                 ) {
                stage = "Production Drawing Completed";
                createdAt = log.createdAt;
              }  if (log.stage === "IMOS QC In-Progress") {
                stage = "Prodcution Drawings Quality Check Completed"
                createdAt = log.createdAt;
              }  if (log.stage === "IMOS Completed" || log.dealActivity && (log.dealActivity.includes("Project status to Under Procurement") || log.dealActivity.includes("sent project to factory bom"))) {
                stage = "Move to Production";
                createdAt = log.createdAt;
              }  if (log.stage === "Site BOM Completed") {
                stage = "Site BOM Completed";
                createdAt = log.createdAt;
              }
            }
            return { ...log, stage, createdAt };
          });
        
          const stagesToAppend = updatedLogsforImos
            .filter((log) => log.stage && !preProductionStage.includes(log.stage))
            .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
            .map((log) => log.stage);
        

          const updatedpreProductionStages = [
            preProductionStage[0],
            ...stagesToAppend,
            ...preProductionStage.slice(1),
          ];

          setPreproductionStage(updatedpreProductionStages);
          getPreProductionStep(updatedLogsforImos);

          return updatedLogsforImos;
        });
        setLogs((prev) => {
          let updatedLogs = []; // Declare updatedLogs here
      
          updatedLogs = res.data.leadLogs.map((log) => {
              let stage = null;
              let createdAt = null;
      
              // Map stages based on log.dealActivity
              if (log.stage || log.dealActivity) {
                  if (
                    (log && log.dealActivity && (log.dealActivity.includes("now moved to 'Designer Assigned'.") || log.dealActivity.includes("now moved to Designer Assigned."))||
                    (log && log.stage === "Designer Assigned" ||
                    (log && log.dealActivity && log.dealActivity.includes("the stage to Designer Assigned"))))) {
                      stage = "Designer Assigned";
                      createdAt = log.createdAt;
                  }
               
                  if ((log.stage && (log.stage == "Initial Measurement Done" || log.stage == "Initial Measurement")) || log.dealActivity && ((log.dealActivity.includes("Initial Measurement Done")) || (log.dealActivity.includes("Initial Measurement")))) {
                      stage = "Initial Measurement";
                      createdAt = log.createdAt;
                  }
                  // if ((log.stage && (log.stage == "1st Meeting Completion") || log.dealActivity && (log.dealActivity.includes("1st Meeting Completion")))) {
                  //     stage = "1st Meeting Completion";
                  //     createdAt = log.createdAt;
                  // }
                  if ((log.stage && ((log.stage == "Design Discussion") || (log.stage == "1st Meeting Completion"))) || log.dealActivity && ((log.dealActivity.includes("Design Discussion")) || (log.dealActivity.includes("1st Meeting Completion")))) {
                    stage = "Design Discussion";
                    createdAt = log.createdAt;
                }
                  if ((log.stage && log.stage == "Revised Quote Shared") || log.dealActivity && log.dealActivity.includes("Revised Quote Shared")) {
                      stage = "Latest Quotation Shared";
                      createdAt = log.createdAt;
                  }
                  if ((log.stage && log.stage == "3D rendering Started") || log.dealActivity && log.dealActivity.includes("3D rendering Started")) {
                      stage = "3D rendering Started";
                      createdAt = log.createdAt;
                  }
                  if ((log.stage && (log.stage == "Final Quotation Approval"  || log.stage == "Final Quote and Design")) || log.dealActivity && (log.dealActivity.includes("Final Quotation Approval") || log.dealActivity.includes("Final Quote and Design"))) {
                      stage = "Customer Approved Design Quotation";
                      createdAt = log.createdAt;
                  }
                  if ((log.stage && (log.stage == "Final Working Drawing Completed" || log.stage == "Drawing QC")) || log.dealActivity && (log.dealActivity.includes("Final Working Drawing Completed") || log.dealActivity.includes("Drawing QC"))) {
                      stage = "Working Drawing Completed";
                      createdAt = log.createdAt;
                  }
                  if ((log.stage && (log.stage === "Project Kick-Off" || log.stage === "Site QC/Project Kick-Off")) || log.dealActivity && (log.dealActivity.includes("stage to Project Kick-Off") || log.dealActivity.includes("stage to Site QC/Project Kick-Off"))) {
                    stage = "Site QC/Project Kick-Off";
                      createdAt = log.createdAt;
                  }
                  if (
                    (log.stage && log.stage === "Design Sign-off HOLD") ||
                    (log.dealActivity && (log.dealActivity.includes("Design Sign-off HOLD") || log.dealActivity.includes("Contract Signed")))
                  ) {
                    stage = "Design Sign-off HOLD";
                    createdAt = log.createdAt;
                  } else if (
                    (log.stage && log.stage === "Design Sign-off") ||
                    (log.dealActivity && (log.dealActivity.includes("Design Sign-off") || log.dealActivity.includes("Contract Signed")))
                  ) {
                    stage = "Design Sign-off";
                    createdAt = log.createdAt;
                  }
                  if (log.stage === "QC Approved" || log.dealActivity && log.dealActivity.includes("Approved and sent for QC Manager Approval")) {
                      stage = "QC User Approval";
                      createdAt = log.createdAt;
                  }
      
                  if ((log.stage === "QC Manager Approved") || (log.dealActivity && (log.dealActivity.includes("project has been moved to IMOS") || log.dealActivity.includes("Approved and sent for Design Manager Approval")))) {
                      stage = "QC Manager Approval";
                      createdAt = log.createdAt;
                  }
      
                  if ((log.stage && log.stage === "Customer Design Approval") || (log.dealActivity && (log.dealActivity.includes("sent for Customer Approval")))) {
                      stage = "ADM/DM Approval";
                      createdAt = log.createdAt;
                  }
      
                  if ((log.stage && log.stage === "Customer has approved the Design Sign-off") ||
                      (log.dealActivity && log.dealActivity === "Customer has approved the Design Sign-off Documents, the lead has been sent for Finance approval now.")) {
                      stage = "Customer Approved Design Contract";
                      createdAt = log.createdAt;
                  }
                  
                  if(log.dealActivity && (log.dealActivity.includes("Customer has Approved Design-Hold Approval"))){
                    stage = 'Design-Hold';
                    createdAt = log.createdAt;
                  }
      
                  if (log.dealActivity && (log.dealActivity.includes("project has been moved to IMOS") || log.dealActivity.includes("Finance Manager Approved and assigned"))) {
                      stage = "Finance Approval";
                      createdAt = log.createdAt;
      
                      // Activate all stages
                      designStages.forEach((designStage) => {
                          updatedLogs.push({ stage: designStage, createdAt: log.createdAt });
                      });
                  }
                  for (let i = 0; i < customerStagesArray.length; i++) {
                    if (customerStagesArray[i] !== 'Design Sign-off' && customerStagesArray[i] !== 'Design Sign-off HOLD' && customerStagesArray[i] !== 'Design-Hold') {
                      if ((log.stage && log.stage === customerStagesArray[i]) || (log.dealActivity && (log.dealActivity.includes(customerStagesArray[i])))) {
                        stage = customerStagesArray[i];
                        if (customerStagesArray[i] === "Final Quote and Design") {
                          stage = 'Customer Approved Design Quotation'
                        }
                        if (customerStagesArray[i] === 'Drawing QC') {
                          stage = 'Working Drawing Completed'
                        }
                        // if(customerStagesArray[i] === 'Design-Hold'){
                        //   const  startIndex = log?.dealActivity?.indexOf('Proposed');
                        //   const newStr = log?.dealActivity?.slice(startIndex);
                        //   setDesignHoldStage(`Design-Hold (${newStr ? newStr : ""})`);
                        //   stage = customerStagesArray[i]
                        // }
                        createdAt = log.createdAt;
                      }
                    }
                   }
          }
      
              return { ...log, stage, createdAt };
          });
      
          const stagesToAppend = updatedLogs
          .filter((log) => {
              const shouldBeAppended = log.stage && !designStages.includes(log.stage);
              return shouldBeAppended;
          })
          .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
          .map((log) => log.stage);

    // If "Finance Approval" is active and any other stage is inactive, activate the missing stages
    const financeApprovalActive = updatedLogs.some((log) => log.stage === "Finance Approval");
    if (financeApprovalActive) {
        // Find the finance approval log to get its createdAt date
        const financeApprovalLog = updatedLogs.find((log) => log.stage === "Finance Approval");
        const financeCreatedAt = financeApprovalLog ? financeApprovalLog.createdAt : null;
    
        designStages.forEach((designStage) => {
            if (!updatedLogs.some((log) => log.stage === designStage)) {
                // Set the createdAt date of the inactive stage to the finance createdAt date
                updatedLogs.push({ stage: designStage, createdAt: financeCreatedAt });
            }
        });
    }
    

    let stagesToAppends = new Set([...stagesToAppend]);
    const updatedpreProductionStages = [
        designStages[0],
        ...stagesToAppends,
        ...designStages.slice(1),
    ];

    setDesignStage(updatedpreProductionStages);

    getActiveDesignStep(updatedLogs);

    return updatedLogs;
});

      
      } else {
        console.error("Lead logs array is empty or not found.");
      }
      props.setLoading(false)
    } catch (error) {
      console.error("Error fetching logs:", error.message);
    }
  };

  const getMileStone = (data) => {
    if (data.salesStage === "Won") {
      setStep((prev) => prev + 1);
    } else {
      return;
    }

    if (data.contractFinanceApproved === true) {
      setStep((prev) => prev + 1);
    } else {
      return;
    }

    if (data.imosStage === "IMOS Completed") {
      setStep((prev) => prev + 1);
    } else {
      return;
    }

    if (
      (data.readyToDispatchDate !== "" &&
      data.readyToDispatchDate !== undefined) || data.factoryStage === "Completed"
    ) {
      setStep((prev) => prev + 1);
    } else {
      return;
    }

    if (data.currentStage == "Completed") {
      setStep((prev) => prev + 1);
    } else {
      return;
    }
  };

  const filteredOnSiteStages = onSiteStages.filter((stage) => stage);

  // factory - production active stepper
  const getProductionStep = (productionLogs) => {
    setProductionStep((previousProductionStep) => {
      let newProductionStep = previousProductionStep;

      productionStage.forEach((stage, index) => {
        const matchingLog = productionLogs.find((log) => log.stage === stage);

        // Check if the stage is found in logs and has a date
        if (matchingLog && matchingLog.createdAt) {
          // If the matching stage is found and it has a createdAt date, set the newProductionStep to the current index
          newProductionStep = Math.max(newProductionStep, index + 1);
        } 
      });

      return newProductionStep;
    });
  };

  // on site
  // Make sure to update setOnSiteStep when receiving new data
  const getOnSiteStep = (onsiteLogs) => {
    setOnSiteStep((previousOnSiteStep) => {
      let newOnSiteStep = previousOnSiteStep;
  
      onSiteStages.forEach((stage, index) => {
        const matchingLog = onsiteLogs.find((log) => log.stage === stage);
  
        if (matchingLog && matchingLog.createdAt) {
          // If the matching stage is found and it has a created date, set the newOnSiteStep to the current index
          newOnSiteStep = Math.max(newOnSiteStep, index + 1);
        }
      });
  
      return newOnSiteStep;
    });
  };
  
  //for imos

  const getPreProductionStep = (imosLogs) => {
    setPreproductionStep((previousProductionStep) => {
      let newPreProduction = previousProductionStep;

        preProductionStages.forEach((stage, index) => {
          const matchingLog = imosLogs.find((log) => log.stage === stage);

          // Check if the stage is found in logs and has a date
          if (matchingLog && matchingLog.createdAt) {
            // If the matching stage is found and it has a created date, set the newPreProduction to the current index
            newPreProduction = Math.max(newPreProduction, index + 1);
          } 
        });

      return newPreProduction;
    });
  };

  //  for design stepper
  const getActiveDesignStep = (logs) => {
    setDesignStep((prevDesignStep) => {
        let newDesignStep = prevDesignStep;

        // Check if "Finance Approval" is active
        const financeApprovalActive = logs.some((log) => log.stage === "Finance Approval");

        // Check if "QC User Approval" is inactive
        const qcUserApprovalInactive = !logs.some(log => log.stage === "QC User Approval");

        // If "Finance Approval" is active and "QC User Approval" is inactive, activate it
        if (financeApprovalActive && qcUserApprovalInactive) {
            const qcUserApprovalIndex = designStage.indexOf("QC User Approval");
            newDesignStep = Math.max(newDesignStep, qcUserApprovalIndex + 1);
        }

        // Loop through each required stage and check if it's active
        designStage.forEach((requiredStage, index) => {
            const matchingLog = logs.find((log) => log.stage === requiredStage);
            
            // If the required stage is found in logs and has a date, set the newDesignStep to the current index
            if (matchingLog && matchingLog.createdAt) {
                newDesignStep = Math.max(newDesignStep, index + 1);
            }
        });

        return newDesignStep;
    });
};

  //handle downlaod
  const handleLeadAttachment = (e) => {
    if (e.currentTarget.getAttribute("data-value") === "sales") {
      window.open(leadDetails.salesChecklistPdfFile);
    }
  };

  // Funciton to download multiple attachement all at once 
  const handleLeadMultipleAttachment = async (fileType) => {
    try {
      let fileUrl;
  
      // Determine the file URL based on the fileType
      switch (fileType) {
        case  'salesquotation':
          fileUrl = leadDetails.salesQuotation ? leadDetails.salesQuotation : ""
          break;

        case  'designQuotation' :
          fileUrl = leadDetails.latestQuotationShared ? leadDetails.latestQuotationShared : ""
          break;
        case 'Contract Sign' :
          fileUrl = leadDetails?.termsAndConditionsPdf;
          break;
        case  'scanQuotationFile':
          fileUrl = leadDetails?.scanQuotationFile;
          break;
          case 'siteqcFile':
            fileUrl = leadDetails?.scanSiteQCCheckListFile;
            break;
        case 'Sales Checklist':
          fileUrl = leadDetails?.salesChecklistPdfFile;
          break;
          case 'Projectkickoffchecklist':
            fileUrl = leadDetails?.scanProjectKickOffCheckListFile;
            break;
        case 'terms' :
          fileUrl = leadDetails?.termsAndConditionsPdf;
          break;
        case 'Working Drawing':
          fileUrl = leadDetails?.workingDrawingFile;
          break;
        // Add more cases for other file types if needed
  
        default:
          console.error('Invalid file type:', fileType);
          return;
      }
  
      // Open the file URL or perform the download as needed
      if (fileUrl) {
        window.open(fileUrl);
        // If you want to trigger a download instead, you can use a library like axios to download the file.
        // Example:
        // const response = await axios.get(fileUrl, { responseType: 'blob' });
        // download(response.data, 'filename.extension');
      } else {
        console.error(`File URL not available for ${fileType}`);
      }
    } catch (error) {
      console.error('Error handling multiple attachments:', error);
    }
  };
  
  const getCustomerDetailsAndLogs =  async() => {
    await getCustomerDetails();
    await getDesignStageList();
      await receiveEscalationData();
      await  getCustomerLogs();
  };

  const handleDesigner = () =>{
    setOpenDesignDialog(true)
  }

  const handleViewTeamClick = (role) => {
    let rolesToMatch = [];
    if (role === "Sales") {
      rolesToMatch = ["Sales Manager", "Sales"];
    } else if (role === "Design") {
      rolesToMatch = ["Design Manager", "Design"];
    }
    else if(role === "Execution"){
      rolesToMatch = ["Execution Manager", "Execution"];
    }

    // Use the 'roles' prop directly in receiveEscalationData
    receiveEscalationData(store, rolesToMatch);
  };

  const receiveEscalationData = (roles, rolesToMatch) => {
    const storedData = localStorage.getItem('uniqueRolesArray');

    // Parse the retrieved data back into an array
    const uniqueRolesArray = JSON.parse(storedData);

        setStore(roles);
    let filteredRoles = [];

    if (rolesToMatch) {
      setOpenDialog(true); // Open the dialog

      // Filter roles based on rolesToMatch
      for (let i = 0; i < uniqueRolesArray?.length; i++) {
        if (rolesToMatch.includes( uniqueRolesArray[i]?.role)) {
          filteredRoles.push(uniqueRolesArray[i]);
        }
      }
      setNewRoles(filteredRoles);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setOpenDesignDialog(false)
  };

  const getChmManager = async () => {
    try {
        const res = await HttpService.getChmManager();
        const chmManagerDetails = res.data.chmManagers;
        let store = leadDetails.chmUser
        setChmDetails([...chmManagerDetails,store])
    } catch (error) {
        console.log(error);
    }
};

const getNewlyCreatedQuotation = async () =>{
  try{
    const res = await HttpService.getNewlyCreatedQuotation(customerDetails.leadId);
    setRevisedQuotation(res?.data?.quotation?.s3Location?.toString());
  }catch(error){
    console.log(error)
  }
}

const getQuotationDownloadLogs = async () => {
  try {
      const res = await HttpService.getQuotationDownloadLogs(customerDetails.leadId);
      let salesLatestQuotation = null; 
      let designLatestQuotation = null

      for (let i = 0; i < res.data.length; i++) {
          const log = res.data[i];
          if (log.departmentName && log.departmentName === "Sales") {
              // Check if the current log is the latest sales quotation
              if (!salesLatestQuotation || log.createdAt > salesLatestQuotation.createdAt) {
                  salesLatestQuotation = log;
              }
          }
          if (log.departmentName && log.departmentName === "Design") {
            // Check if the current log is the latest sales quotation
            if (!designLatestQuotation || log.createdAt > designLatestQuotation.createdAt) {
              designLatestQuotation = log;
            }
        }
      }

      if (salesLatestQuotation) {
          // Update salesQuotation only if a latest quotation is found
          setSalesQuotation(salesLatestQuotation.fileLink);
      } 
      if (designLatestQuotation) {
        setDesignQuotation(designLatestQuotation.fileLink)
        // Update salesQuotation only if a latest quotation is found
    } 
  } catch (error) {
      console.log(error);
  }
};

  useEffect(() => {
    getCustomerDetailsAndLogs();
  }, []);


  useEffect(() => {
    getChmManager()
    getActiveDesignStep(logs);
    getPreProductionStep(imosLogs);
    getProductionStep(productionLogs);
    getOnSiteStep(onsiteLogs)
  }, [
    logs,
    designStage,
    imosLogs,
    productionLogs,
    productionStage,
    clickedRole,
    onsiteLogs,
    onSiteStages
  ]);

  const showChmDetails = () => {
    setOpenDialogChm(true);
  };

  const handleCloseDialogForChm = () => {
    setOpenDialogChm(false);
  };
  const getPreviousDesigner = (data) => {
    let store = [];
    let managerFound = false;
    let userFound = false;

    for (let i = 0; i < data.length; i++) {
        // Check if Design Manager or Design User is already found
        if (managerFound && userFound) {
            break; // No need to continue if both are found
        }

        // Iterate over the roles array for each item in data
        for (let j = 0; j < data[i].roles.length; j++) {
            // Check if the role name is "Design Manager" and it's not already found
            if (data[i].roles[j].name === "Design Manager" && !managerFound) {
                store.push(data[i]);
                managerFound = true;
                break; // Break the inner loop since we found a relevant role for the current item
            }
            // Check if the role name is "Design User" and it's not already found
            else if (data[i].roles[j].name === "Design User" && !userFound) {
                store.push(data[i]);
                userFound = true;
                break; // Break the inner loop since we found a relevant role for the current item
            }
        }
    }
    setDesignerDetails(store)
};

  return (
    <Container
      maxWidth="md"
      style={{ marginTop: "8rem", marginBottom: "12rem" }}
    >
      <Card>
      <Typography variant="h4" style={{ textAlign: 'center',marginTop: "2rem" }}>
        Welcome {""} {customerDetails.name}
      </Typography>       
       <CardContent>
          {/* Horizontal Stepper */}
          <Stepper className={classes.root} activeStep={step} alternativeLabel>
            {milestones.map((label, index) => (
              <Step key={index} completed={index < step}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </CardContent>
      </Card>

      {/* for sales  */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
<DialogTitle style={{ textAlign: "center" }}>Team Details</DialogTitle>
        <DialogContent>
          <DialogContentText>
          {newRoles
  .sort((a, b) => {
    // Sort managers before non-managers
    if (a.role.includes('Manager') && !b.role.includes('Manager')) {
      return -1; // a comes first
    }
    if (!a.role.includes('Manager') && b.role.includes('Manager')) {
      return 1; // b comes first
    }
    return 0; // no change in order
  })
  .map((role, index) => (
    <Card
      key={role.user._id}
      variant="outlined"
      style={{ marginBottom: 16 }}
    >
      <CardContent>
        {index === 0 && (
          <Typography variant="h5">Level 2 {" "} ( Manager)</Typography>
        )}
        {index === 1 && (
          <Typography variant="h5">Level 1 {" "} ( Executive)</Typography>
        )}
        <div
          style={{
                        display: "flex",
                        alignItems: "center",
            marginBottom: 8,
            padding: 4,
          }}
        >
          <PersonIcon style={{ marginRight: 8 }} />
          <Typography variant="body1" style={{ paddingLeft: 8 }}>
            {role.user.name}
          </Typography>
        </div>
        <div
          style={{
                        display: "flex",
                        alignItems: "center",
            marginBottom: 8,
            padding: 4,
          }}
        >
          <EmailIcon style={{ marginRight: 8 }} />
          <a
            href={`mailto:${role.user.email}`}
                        style={{ textDecoration: "none", color: "inherit" }}
          >
            <Typography variant="body1" style={{ paddingLeft: 8 }}>
              {role.user.email}
            </Typography>
          </a>
        </div>
        {role.type !== 'Manager' && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: 8,
              padding: 4,
            }}
          >
               {index !== 0 && (
                  <>
            <PhoneIcon style={{ marginRight: 8 }} />
            <a
              href={`tel:${role.user.mobile}`}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <Typography variant="body1" style={{ paddingLeft: 8 }}>
                {role.user.mobile}
              </Typography>
            </a>
            </>)}
            
          </div>
        )}
        {/* Add other fields if needed */}
      </CardContent>
    </Card>
  ))}

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* //for design */}
      <Dialog
  open={openDesignDialog}
  onClose={() => setOpenDesignDialog(false)}
  maxWidth="md"
  fullWidth
>
  <DialogTitle style={{ textAlign: "center" }}>Team Details</DialogTitle>
  <DialogContent>
  {leadDetails.designerChangeLogs && leadDetails.designerChangeLogs.length > 0 ? (
    <>
      {designerAssigned.map((el, i) => (
        <div key={i} style={{ marginBottom: "16px" }}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom style={{ opacity: i === 0 ? 1 : 0.6 }}>
                Level 1 (Design Manager)
              </Typography>
              {el.managerId.roles && el.managerId.roles.length > 0 && el.managerId.roles[0].name === "Design Manager" && (
                <>
                  <Typography variant="body1" component="p" style={{ display: "flex", alignItems: "center", padding: "10px", opacity: i === 0 ? 1 : 0.6 }}>
                    <PersonIcon style={{ marginRight: 8 }} />
                    <span style={{ marginRight: 8 }}>Name:</span>
                    <span style={{ flex: 1 }}>{el.managerId.name}</span>
                  </Typography>
                  <Typography variant="body1" component="p" style={{ display: "flex", alignItems: "center", padding: "10px", opacity: i === 0 ? 1 : 0.6 }}>
                    <EmailIcon style={{ marginRight: 8 }} />
                    <span style={{ marginRight: 8 }}>Email:</span>
                    <span>
                      <a href={`mailto:${el.managerId.email}`} style={{ textDecoration: "none", color: "inherit" }}>
                        <Typography variant="body1" style={{ paddingLeft: 8 }}>{el.managerId.email}</Typography>
                      </a>
                    </span>
                  </Typography>
                </>
              )}
              <Typography variant="h6" gutterBottom style={{ marginTop: "16px", opacity: i === 0 ? 1 : 0.6 }}>
                Level 2 (Design Executive)
              </Typography>
              <Typography variant="body1" component="p" style={{ display: "flex", alignItems: "center", padding: "10px", opacity: i === 0 ? 1 : 0.6 }}>
                <PersonIcon style={{ marginRight: 8 }} />
                <span style={{ marginRight: 8 }}>Name:</span>
                <span style={{ flex: 1 }}>{el.userId.name}</span>
              </Typography>
              <Typography variant="body1" component="p" style={{ display: "flex", alignItems: "center", padding: "10px", opacity: i === 0 ? 1 : 0.6 }}>
                <EmailIcon style={{ marginRight: 8 }} />
                <span style={{ marginRight: 8 }}>Email:</span>
                <span>
                  <a href={`mailto:${el.userId.email}`} style={{ textDecoration: "none", color: "inherit" }}>
                    <Typography variant="body1" style={{ paddingLeft: 8 }}>{el.userId.email}</Typography>
                  </a>
                </span>
              </Typography>
              <Typography variant="body1" component="p" style={{ display: "flex", alignItems: "center", padding: "10px", opacity: i === 0 ? 1 : 0.6 }}>
                <PhoneIcon style={{ marginRight: 8 }} />
                <span style={{ marginRight: 8 }}>Mobile:</span>
                <span>
                  <a href={`tel:${el.userId.mobile}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Typography variant="body1" style={{ paddingLeft: 8 }}>{el.userId.mobile}</Typography>
                  </a>
                </span>
              </Typography>
              <Typography variant="body1" component="p" style={{ display: "flex", alignItems: "center", padding: "10px", opacity: i === 0 ? 1 : 0.6 }}>
                {i !== 0 && (
                  `Designer Change Date ${moment(el.createdDate && el.createdDate).format("DD-MM-YYYY")})`
                )}
              </Typography>
            </CardContent>
          </Card>
        </div>
      ))}
    </>
  ) : (
    <>
    <DialogContent>
  {designerDetails.map((el, i) => (
    <div key={i} style={{ marginBottom: "16px" }}>
      <Card>
        <CardContent>
          <Typography variant="h6" gutterBottom style={{ opacity: i === 0 ? 1 : 0.6 }}>
            Design Details
          </Typography>
          {/* Design Manager Section */}
          {el.roles.some(role => role.name === "Design Manager") && (
            <>
              <Typography variant="subtitle1" gutterBottom style={{ marginTop: "16px" }}>
                Level 1 (Design Manager)
              </Typography>
              <Typography variant="body1" component="p" style={{ display: "flex", alignItems: "center", padding: "10px", opacity: i === 0 ? 1 : 0.6 }}>
                <PersonIcon style={{ marginRight: 8 }} />
                <span style={{ marginRight: 8 }}>Name:</span>
                <span>{el.name}</span>
              </Typography>
              <Typography variant="body1" component="p" style={{ display: "flex", alignItems: "center", padding: "10px", opacity: i === 0 ? 1 : 0.6 }}>
                <EmailIcon style={{ marginRight: 8 }} />
                <span style={{ marginRight: 8 }}>Email:</span>
                <span>
                  <a href={`mailto:${el.email}`} style={{ textDecoration: "none", color: "inherit" }}>
                    {el.email}
                  </a>
                </span>
              </Typography>
            </>
          )}
          {/* Design User Section */}
          {el.roles.some(role => role.name === "Design User") && (
            <>
              <Typography variant="subtitle1" gutterBottom style={{ marginTop: "16px" }}>
                Level 2 (Design User)
              </Typography>
              <Typography variant="body1" component="p" style={{ display: "flex", alignItems: "center", padding: "10px" }}>
                <PersonIcon style={{ marginRight: 8 }} />
                <span style={{ marginRight: 8 }}>Name:</span>
                <span>{el.name}</span>
              </Typography>
              <Typography variant="body1" component="p" style={{ display: "flex", alignItems: "center", padding: "10px" }}>
                <EmailIcon style={{ marginRight: 8 }} />
                <span style={{ marginRight: 8 }}>Email:</span>
                <span>
                  <a href={`mailto:${el.email}`} style={{ textDecoration: "none", color: "inherit" }}>
                    {el.email}
                  </a>
                </span>
              </Typography>
              <Typography variant="body1" component="p" style={{ display: "flex", alignItems: "center", padding: "10px"}}>
                <PhoneIcon style={{ marginRight: 8 }} />
                <span style={{ marginRight: 8 }}>Mobile:</span>
                <span>
                  <a href={`tel:${el.mobile}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                    {el.mobile}
                  </a>
                </span>
              </Typography>
            </>
          )}
        </CardContent>
      </Card>
    </div>
  ))}
</DialogContent>


    </>
  )}
</DialogContent>

  <DialogActions>
    <Button onClick={handleCloseDialog} color="primary">
      Close
    </Button>
  </DialogActions>
</Dialog>


      <Card style={{ marginTop: "2rem" }}>
        <CardContent>
          <Typography variant="h5" className={classes.designStagess}>
            On Board
            <span className={classes.marginRight}>&nbsp;</span>
            {salesStep[0]?.status === "Approved" &&
            <>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleViewTeamClick("Sales")}
              className={classes.button}
              style={{ textTransform: "none", padding: "2px 10px",fontSize:"14px" }}
              >           
            View Team 
            </Button>
            </>
            }
          </Typography>
          {/* Vertical Stepper */}
          <Stepper
            className={
              classes.orientation === "vertical"
                ? classes.verticalRoot
                : classes.root
            }
            activeStep={designStep}
            orientation="vertical"
          >
            <Step key={1} completed={salesStep[0]?.status === "Approved"}>
              <StepLabel>
                Check List Approved on{" "}
                {salesStep[0]?.status === "Approved"  && (
                  <>
                    {moment(salesStep[0]?.approvedDate).format("DD-MM-YYYY")}
                    <span className={classes.marginRight}>&nbsp;</span>
                    <div
                      className={
                        classes.orientation === "vertical"
                          ? classes.verticalRoot
                          : classes.root
                      }
                      activeStep={designStep}
                      orientation="vertical"
                    >
                      <div>
                        {/* Button for the first purpose */}
                        {(leadDetails.salesChecklistPdfFile && leadDetails.salesChecklistPdfFile !=="") &&(
                          <>
                        <Button
                          className={classes.button}
                          variant="contained"
                          color="secondary"
                          onClick={() => handleLeadMultipleAttachment('Sales Checklist')}
                        >
                          DownLoad Checklist
                        </Button>
                        </>)}

                        <Button
                          className={classes.button}
                          variant="contained"
                          color="secondary"
                          onClick={() => handleLeadMultipleAttachment('terms')}
                        >
                          DownLoad Contract Document
                        </Button>

                        {/* Button for the second purpose */}
                        {leadDetails.salesQuotation && leadDetails.salesQuotation !== "" && (
                          <Button
                            className={classes.button}
                            variant="contained"
                            color="secondary"
                            onClick={() => handleLeadMultipleAttachment('salesquotation')}
                          >
                            Download Sales Quotation
                          </Button>
                        )}

                        {/* Button for the fourth purpose */}
                        {/* <Button
                          className={classes.button}
                          variant="contained"
                          color="secondary"
                          onClick={() => handleLeadMultipleAttachment('Working Drawing')}
                        >
                          Working Drawing
                        </Button> */}
                      </div>
                    </div>
                  </>
                )}

              </StepLabel>
            </Step>
          </Stepper>
        </CardContent>
      </Card>

      {/* Design active stepper */}
      <Card style={{ marginTop: "2rem" }}>
  <CardContent>
    <Typography variant="h5" className={classes.designStagess}>
      Design
      <span className={classes.marginRight}>&nbsp;</span>
      {leadDetails.designerChangeLogs && leadDetails.designerChangeLogs.length > 0 ? (
  <Button
    variant="contained"
    color="primary"
    onClick={handleDesigner}
    className={classes.button}
    style={{ textTransform: "none", padding: "2px 10px", fontSize: "14px" }}
  >
    View Team
  </Button>
) : (
  <Button
    variant="contained"
    color="primary"
    onClick={handleDesigner}
    className={classes.button}
    style={{ textTransform: "none", padding: "2px 10px", fontSize: "14px" }}
  >
    View Team
  </Button>
)}

    </Typography>
    <Stepper
      className={
        classes.orientation === "vertical"
          ? classes.verticalRoot
          : classes.root
      }
      activeStep={designStep}
      orientation="vertical"
    >
      {designStage.map((label, index) => {
        const matchingLog = logs.find((log) => log.stage === label);
        const showDownloadRevisedQuotation = label === "Customer Approved Design Quotation";
        const showDesignDescussion = label === 'Design Discussion'
        const showDownloadCustomerApproved = label == "Customer Approved Design Contract" &&  matchingLog && matchingLog.createdAt ;
        const DesignHoldApprovalLabel = label == "Design-Hold" &&  matchingLog && matchingLog.createdAt ;


        return (
          <Step key={index} completed={matchingLog?.createdAt !== undefined}>
            <StepLabel>
              {label}
              {/* {label === 'Design-Hold' ? designHoldStage : label} */}
              {matchingLog?.createdAt ? ` on `+  moment(matchingLog.createdAt).format("DD-MM-YYYY") : ''}


              {/* //for revised quotation Shared */}
              {/* <div className={
                classes.orientation === "vertical"
                  ? classes.verticalRoot
                  : classes.root
              }
                style={{ marginTop: "5px" }}>
                {
                  !leadDetails?.contractCustomerApproved && showDownloadRevisedQuotation && (
                    <Button
                      variant="contained"
                      color="secondary"
                      style={{
                        textTransform: "none",
                        fontSize: "0.75rem",
                      }}
                      onClick={() => window.open(leadDetails.latestQuotationShared)}
                    >
                      Customer Approved Latest Quotation
                    </Button>

                  )
                }
              </div> */}

               {/* when project is design-hold stage */}
               {DesignHoldApprovalLabel &&
                <div className={
                  classes.orientation === "vertical"
                    ? classes.verticalRoot
                    : classes.root
                }
                  style={{ marginTop: "5px", fontFamily: 'inherit', fontSize: '12px' }}
                >
                  <p><strong>Reason is:</strong> {leadDetails?.designHoldSelectedTypes}</p>
                  <p><strong>Probable Date for Restarting the Designing:</strong> {moment(leadDetails?.proposedDesignStartDate).format('DD-MM-YYYY')}</p>
                  <p><strong>Probable Date of Design Sign-off:</strong> {moment(leadDetails?.probableDesignSignOffDate).format('DD-MM-YYYY')}</p>
                </div>
              }

              {showDesignDescussion &&
                <div
                  className={
                    classes.orientation === "vertical"
                      ? classes.verticalRoot
                      : classes.root
                  }
                  style={{ marginTop: "5px" }}
                >
                  {
                    leadDetails?.designDiscussionDetails && leadDetails?.designDiscussionDetails.map(
                      (detail, index) => (
                        <Button
                          key={index}  // It is a good practice to provide a unique key for each item in the list
                          variant="contained"
                          color="secondary"
                          style={{
                            textTransform: "none",
                            fontSize: "0.75rem",
                            marginLeft: '10px'
                          }}
                          onClick={() => { detail?.momDiscussionFile ? window.open(detail?.momDiscussionFile) : props.setSnackInfo("Not Available", "error") }}
                        >
                          {detail?.discussionNo}
                        </Button>
                      )
                    )
                  }
                </div>
              }

              {showDownloadCustomerApproved && (
                <div className={
                  classes.orientation === "vertical"
                    ? classes.verticalRoot
                    : classes.root
                }
                  style={{ marginTop: "5px" }}>
                  {/* Button for the first purpose */}

                  {
                    (leadDetails.scanProjectKickOffCheckListFile && leadDetails.scanProjectKickOffCheckListFile !== "") && (

                      <Button
                        className={classes.button}
                        variant="contained"
                        color="secondary"
                        onClick={() => handleLeadMultipleAttachment('Projectkickoffchecklist')}
                      >
                        DownLoad Project Kick-Off Checklist
                      </Button>
                    )}
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="secondary"
                    onClick={() => handleLeadMultipleAttachment('Contract Sign')}
                  >
                    DownLoad Contract Document
                  </Button>

                  {/* Button for the fourth purpose */}
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="secondary"
                    onClick={() => handleLeadMultipleAttachment('Working Drawing')}
                  >
                    DownLoad Working Drawing
                  </Button>

                  {
                    (leadDetails.scanQuotationFile && leadDetails.scanQuotationFile !== "") && (
                      <>
                        <Button
                          className={classes.button}
                          variant="contained"
                          color="secondary"
                          onClick={() => handleLeadMultipleAttachment('scanQuotationFile')}
                        >
                          DownLoad Design Quotation
                        </Button>
                      </>)}
                  {
                    (leadDetails.scanSiteQCCheckListFile && leadDetails.scanSiteQCCheckListFile !== "") && (
                      <Button
                        className={classes.button}
                        variant="contained"
                        color="secondary"
                        onClick={() => handleLeadMultipleAttachment('siteqcFile')}
                      >
                        DownLoad Site QC File
                      </Button>
                    )}
                </div>
              )}

              {/* end */}
            </StepLabel>
          </Step>
        );
      })}
    </Stepper>
  </CardContent>
</Card>


      {/* for the preProdctionStep */}
      <Card style={{ marginTop: "2rem" }}>
        <CardContent>
          {/* Vertical Stepper */}
          <Typography variant="h5" className={classes.designStagess}>
            Pre Production
          <span className={classes.marginRight}>&nbsp;</span>
          {salesStep[0]?.status === "Approved" &&

            <Button
              variant="contained"
              color="primary"
              onClick={showChmDetails}
              className={classes.button}
              style={{ textTransform: "none", padding: "2px 10px",fontSize:"14px" }}
                  >
        
              View Team
            </Button>
}
            </Typography>

          <Stepper
            className={
              classes.orientation === "vertical"
                ? classes.verticalRoot
                : classes.root
            }
            activeStep={preProdctionStep}
            orientation="vertical"
          >
            {preProductionStages.map((label, index) => {
              const matchingLog = imosLogs.find((log) => log.stage === label);
              return (
                <Step
                  key={index}
                  completed={matchingLog?.createdAt !== undefined}
                >
                  <StepLabel>
                    {label}
                    {matchingLog?.createdAt ? ` on `+  moment(matchingLog.createdAt).format("DD-MM-YYYY") : ''}

                    
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </CardContent>
      </Card>
      {/* for chm dialog box */}
  
      <Dialog open={openDialogForChm} onClose={handleCloseDialogForChm}  maxWidth="md"
        fullWidth>
<DialogTitle style={{ textAlign: "center" }}>Team Details</DialogTitle>
        <DialogContent>
  {chmDetails.map((user, index) => (
    <Card key={index} style={{padding:3, marginBottom:15}}>
    <CardContent>
  {index === 0 && (
    <Typography variant="h5">Level 3 {" "} (CHM Head) </Typography>
  )}
  {index === 1 && (
    <Typography variant="h5">Level 2 {" "} (CHM Manager) </Typography>
  )}
  {index === 2 && (
    <Typography variant="h5">Level 1 {" "} (CHM Executive) </Typography>
  )}

  <div style={{ display: 'flex', alignItems: 'center', marginBottom: 8, padding: 4 }}>
    <PersonIcon style={{ marginRight: 8 }} />
    <Typography variant="body1">{user?.name}</Typography>
  </div>

  <div style={{ display: 'flex', alignItems: 'center', marginBottom: 8, padding: 4 }}>
    <EmailIcon style={{ marginRight: 8 }} />
    <span>
              <a
            href={`mailto:${user?.email}`}
                        style={{ textDecoration: "none", color: "inherit" }}
          >
            <Typography variant="body1" style={{ paddingLeft: 8 }}>
            {user?.email}
              </Typography>
          </a></span>
  </div>

  {index !== 0 && (
    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 8, padding: 4 }}>
      <PhoneIcon style={{ marginRight: 8 }} />
      <span>
              <a
            href={`mailto:${user?.mobile}`}
                        style={{ textDecoration: "none", color: "inherit" }}
          >
            <Typography variant="body1" style={{ paddingLeft: 8 }}>
            {user?.mobile}
              </Typography>
          </a></span>
    </div>
  )}
</CardContent>

    </Card>
  ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogForChm} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>



      {/* //for Prodction */}
      <Card style={{ marginTop: "2rem" }}>
        <CardContent>
          {/* Vertical Stepper */}
          <Typography variant="h5" className={classes.designStagess}>
            Production
            <span className={classes.marginRight}>&nbsp;</span>
            {salesStep[0]?.status === "Approved" &&
            <Button
              variant="contained"
              color="primary"
              onClick={showChmDetails}
              className={classes.button}
              style={{ textTransform: "none", padding: "2px 10px",fontSize:"14px" }}
                  >
        
              View Team
            </Button>
}
            </Typography>
   
          <Stepper
            className={
              classes.orientation === "vertical"
                ? classes.verticalRoot
                : classes.root
            }
            activeStep={productionStep}
            orientation="vertical"
          >
            {productionStage.map((label, index) => {
              const matchingLog = productionLogs.find(
                (log) => log.stage === label
              );


              return (
                <Step
                  key={index}
                  completed={matchingLog?.createdAt !== undefined}
                >
                  <StepLabel>
                    {label}
                    {matchingLog?.createdAt ? ` on `+  moment(matchingLog.createdAt).format("DD-MM-YYYY") : ''}

                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </CardContent>
      </Card>

      {/* //on site */}
      <Card style={{ marginTop: "2rem" }}>
        <CardContent>
          {/* Vertical Steppr */}
          <Typography variant="h5" className={classes.designStagess}>
            On Site
            <span className={classes.marginRight}>&nbsp;</span>
                        {
              leadDetails.readyToDispatchDate && leadDetails?.readyToDispatchDate !== "" && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleViewTeamClick("Execution")}
                  className={classes.button}
                  style={{ textTransform: "none", padding: "2px 10px", fontSize: "12px" }}
                >
                  View Team
                </Button>
              )
            }

          </Typography>
          <Stepper
            className={
              classes.orientation === "vertical"
                ? classes.verticalRoot
                : classes.root
            }
            activeStep={onsiteStep}
            orientation="vertical"
          >
            {filteredOnSiteStages.map((label, index) => {
              const matchingLog = onsiteLogs.find((log) => log.stage === label);

              return (
                <Step key={index} completed={index < onsiteStep}>
                  <StepLabel>
                    {label}
                    {matchingLog?.createdAt ? ` on `+  moment(matchingLog.createdAt).format("DD-MM-YYYY") : ''}

                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </CardContent>
      </Card>
      <CustomerEscalation onEscalationData={receiveEscalationData} />
    </Container>
  );
};

export default connect(null, { setCustomerDetails, setSnackInfo, setLoading })(CustomerDashboard);