import React, { useState, useEffect, useRef, useCallback } from 'react';
import { connect, useSelector } from 'react-redux'
import { Link } from "react-router-dom";
import { setLoading, setSnackInfo } from '../../redux/actions/appAction'
import HttpService from '../../services/httpService'
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { FormControl, MenuItem } from '@material-ui/core';
import moment from 'moment'
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import { ROLENAME } from '../../utils/index';
import { CSVLink } from "react-csv";
import { commaSeparateAmount } from '../../components/commaSeparate';
import { setUserDetails } from '../../redux/actions/userAction';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 700,
    },
    root: {
        width: '100%',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    }
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#ccc",
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

// const desginStages = [
//     "Assign to Designer",
//     "Designer Assigned",
//     "Sketchup Model Ready",
//     "1st Meeting Completion",
//     "Revised Quote Shared",
//     "Booking Confirmed (10% received)",
//     "Initial Measurement Done",
//     "3D rendering Started",
//     "Final Quotation Shared",
//     "Final Marking Done",
//     "Final Working Drawing Completed",
//     "Contract Signed",
//     "Lost"
// ];

// const salesStages = [
//     "Lead Received",
//     "Pre - Sales call",
//     "Sales Call",
//     "Quotation Sent",
//     "Site/Office visit",
//     "Negotiation",
//     "Closure",
//     "Lost"
// ];

const QTProjects = (props) => {
    const classes = useStyles();
    const { userDetails } = useSelector(state => state.user)
    const [qtprojects, setQTProjectRows] = useState([]);
    // const [qtprojectsList, setQTProjectList] = useState([]);
    const [qtBoardViewProjects, setQTBoardViewProject] = useState([]);
    const [dupqtprojects, setDupQtProjects] = useState([])
    const [searched, setSearched] = useState("")
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [projectViewType, setProjectViewType] = useState();
    // const [departmentName, setDepartmentName] = useState('');
    const [totalaPages, setTotalPages] = useState(0)
    // const [customersList, setCustomerList] = useState([]);
    const [assignToList, setAssignToList] = useState([]);
    // const [selectedCustomers, setSelectedCustomers] = useState([]);
    const [selectedAssignTo, setSelectedAssignTo] = useState([]);
    const [selectedStage, setSelectedStage] = useState([]);
    const [filterd, setfilterd] = useState(false);
    const [usersData, setUsersData] = useState([])

    const buttonRef = useRef(null);
    const headers = [
        { key: "name", label: "Name" },
        { key: "lead_no", label: "Lead No" },
        { key: "assignTo", label: "Assign To" },
        { key: "stage", label: "Stage" },
        { key: "lostReason", label: "Lost Reason" },
        { key: "department", label: "Department" },
        { key: "QuotationAmount", label: "Quotation Amount" },
        { key: "createdAt", label: "Created At" },
        { key: "salesUser", "label": "sales User" },
        { key: "salesManager", "label": "sales Manager" }
    ];
    useEffect(() => {
        getDepartments()
        getUserdata()
    }, []);

    useEffect(() => {
        getAllQtProjects()
        let val = localStorage.getItem('View')
        if (val !== null) {
            setProjectViewType(val)
        } else {
            if (userDetails.roles.find(role => role.name === ROLENAME.Admin)) {
                setProjectViewType('table')
            } else {
                setProjectViewType('board');
            }
        }
        // eslint-disable-next-line
    }, [rowsPerPage])

    const getUserdata = async () => {
        const users = await HttpService.getAllMangers();
        setUsersData(users.data)
    }

    const getSalesUser = (item) => {
        let salesDataObj = {
            sales_user: '',
            sales_teamId: '',
            sales_manager: ''
        }

        if (item.assignTo.roles.find(ele => ele.name === ROLENAME.SalesUser)) {
            salesDataObj.sales_user = item?.assignTo?.name;
            salesDataObj.sales_teamId = item?.assignTo?.teamId
        }
        for (let i = 0; i < item?.previouslyAssignedTo?.length; i++) {
            for (let j = 0; j < item?.previouslyAssignedTo[i]?.roles.length; j++) {
                if (item.salesLeadOwnRole === 'SalesUser' && item?.previouslyAssignedTo[i]?.roles[j].name === ROLENAME.SalesUser) {
                    salesDataObj.sales_user = item?.previouslyAssignedTo[i]?.name
                    salesDataObj.sales_teamId = item?.previouslyAssignedTo[i]?.teamId
                } else if (item.salesLeadOwnRole === 'SalesManager' && item?.previouslyAssignedTo[i]?.roles[j].name === ROLENAME.SalesManager) {
                    salesDataObj.sales_manager = item?.previouslyAssignedTo[i]?.name
                    salesDataObj.sales_user = 'NA'
                }
            }
        }

        if (item.assignTo.roles.find(ele => ele.name === ROLENAME.SalesManager)) {
            salesDataObj.sales_manager = item?.assignTo?.name
        }
        return salesDataObj;
    }

    const getAllQtProjects = useCallback(async () => {
        props.setLoading(true)
        try {
            if (userDetails.roles.find(role => role.name === ROLENAME.Admin)) {
                const result = await HttpService.getAllLeads(rowsPerPage, page, null, userDetails.selectedExpCenter)
                console.log(result.data, "result")
                const qtAllProjects = []
                const stageList = [];
                const assignUserList = [];
                result.data.docs.forEach(item1 => {
                    item1['projects'].forEach(item => {
                        let data = getSalesUser(item)
                        let obj = {
                            projectId: item?._id,
                            name: item?.customerId?.name,
                            lead_no: item?.lead_no,
                            customerId: item?.customerId?._id,
                            status: item?.status,
                            // stage: item?.stage ? item?.stage : item.salesStage,
                            stage: item?.salesStage === 'Won' ? item?.designStages : item?.salesStage,
                            assignTo: item?.assignTo?.name,
                            department: item?.departmentId?.name,
                            totalCustomerOutflow: item?.grandTotal ? item?.grandTotal : `0`,
                            reasonForLost: item?.salesStage === 'Lost' ? item?.reasonForLost : '-',
                            createdAt: moment(item?.createdAt).format("DD-MM-YYYY"),
                            salesUser: data.sales_user !== '' ? data.sales_user : 'NA',
                            salesUserTeamId: data.sales_teamId,
                            salesManagerName: data.sales_manager
                        }
                        // setDepartmentName(obj.department);
                        stageList.push(obj.stage);
                        assignUserList.push(obj.assignTo);
                        qtAllProjects.push(obj)
                        return obj
                    })
                })
                const uniqueData = Array.from(new Set(qtAllProjects.map(a => a.name)))
                    .map(id => {
                        return qtAllProjects.find(a => a.name === id)
                    })

                const uniqueStageList = Array.from(new Set(stageList.map(a => a)))
                    .map(id => {
                        return stageList.find(a => a === id)
                    })

                const uniqueAssignToList = Array.from(new Set(assignUserList.map(a => a)))
                    .map(id => {
                        return assignUserList.find(a => a === id)
                    })
                setDepartmentsStage(uniqueStageList);
                // setCustomerList(uniqueData);
                setAssignToList(uniqueAssignToList);
                // setQTProjectList(qtAllProjects)
                setQTProjectRows(qtAllProjects)
                setDupQtProjects(qtAllProjects)
                setTotalPages(result.data.totalDocs)
            } else {
                const qtUserProjectList = await HttpService.getAllLeads(rowsPerPage, page, null, userDetails.selectedExpCenter);
                let i = 1;
                let userTableViewData = []
                if (qtUserProjectList?.data.docs.length !== 0) {
                    const assignUserList = [];
                    qtUserProjectList.data.docs.forEach(ele => {
                        ele['projects'].forEach(item => {
                            let data = {}
                            if (userDetails.roles.find(role => role.name === ROLENAME.SalesHead || role.name === ROLENAME.AssistantSalesManager)) {
                                data = getSalesUser(item)
                            }
                            item.serial = i++;
                            let obj = {
                                projectId: item?._id,
                                name: item?.customerId?.name,
                                lead_no: item?.lead_no,
                                customerId: item?.customerId?._id,
                                status: item?.status,
                                // stage: item?.stage ? item?.stage : item.salesStage,
                                stage: item?.salesStage === 'Won' ? item.designStages : item?.salesStage,
                                assignTo: item?.assignTo?.name,
                                department: item?.departmentId?.name,
                                totalCustomerOutflow: item?.grandTotal ? item?.grandTotal : `0`,
                                reasonForLost: item?.salesStage === 'Lost' ? item?.reasonForLost : '-',
                                createdAt: moment(item?.createdAt).format("DD-MM-YYYY")
                            }
                            if (userDetails.roles.find(role => role.name === ROLENAME.SalesHead || role.name === ROLENAME.AssistantSalesManager)) {
                                obj.salesUser = data.sales_user !== '' ? data.sales_user : 'NA';
                                obj.salesUserTeamId = data.sales_teamId;
                                obj.salesManagerName = data.sales_manager;
                            }
                            userTableViewData.push(obj)
                            assignUserList.push(item.assignTo?.name);
                            // setDepartmentName(obj.department);
                        });
                    })

                    // To get the unique stage based on the response
                    // const uniqueData = Array.from(new Set(userTableViewData.map(a => a.stage)))
                    //     .map(id => {
                    //         return userTableViewData.find(a => a.stage === id)
                    //     })
                    const uniqueData = Array.from(new Set(userTableViewData.map(a => a.name)))
                        .map(id => {
                            return userTableViewData.find(a => a.name === id)
                        })
                    const uniqueAssignToList = Array.from(new Set(assignUserList.map(a => a)))
                        .map(id => {
                            return assignUserList.find(a => a === id)
                        })
                    // setCustomerList(uniqueData)
                    setAssignToList(uniqueAssignToList);
                    // setQTProjectList(userTableViewData)
                    setQTProjectRows(userTableViewData)
                    setDupQtProjects(userTableViewData)
                    setQTBoardViewProject(qtUserProjectList.data.docs)
                    setTotalPages(qtUserProjectList.data.totalDocs)
                } else {
                    setQTProjectRows([])
                    setDupQtProjects([])
                    setQTBoardViewProject([])
                    setTotalPages(0)
                }
            }
        } catch (error) {
            console.error(error)
            props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }, [userDetails.selectedExpCenter, rowsPerPage, page])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setfilterd(false)
        getAllQtProjects()
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        setfilterd(false);
        setQTProjectRows([])
        setDupQtProjects([])
        getAllQtProjects()
        // setQTProjectRows(0);
    };

    const setHandleSearched = (value) => {
        setSearched(value)
        requestSearch(value)
    }

    const requestSearch = async (search) => {
        try {
            if (search.length === 0) {
                getAllQtProjects()
            } else {
                let data = {};
                if (userDetails.roles.find(role => role.name === ROLENAME.Admin)) {
                    data.role = 'admin';
                    if (startDate && endDate) {
                        data.startDate = startDate;
                        data.endDate = endDate;
                    }
                } else {
                    data.role = 'others';
                }
                const response = await HttpService.getAllSearchLeads(search, userDetails.selectedExpCenter, data)
                let i = 1;
                let userTableViewData = []
                const assignUserList = [];
                response.data.docs.forEach(ele => {
                    ele['projects'].forEach(item => {
                        let data = getSalesUser(item)
                        item.serial = i++;
                        let obj = {
                            projectId: item?._id,
                            name: item?.customerId?.name,
                            lead_no: item?.lead_no,
                            customerId: item?.customerId?._id,
                            status: item?.status,
                            // stage: item?.stage ? item?.stage : item.salesStage,
                            stage: item?.salesStage === 'Won' ? item.designStages : item?.salesStage,
                            assignTo: item?.assignTo?.name,
                            department: item?.departmentId?.name,
                            totalCustomerOutflow: item?.grandTotal ? item?.grandTotal : `0`,
                            reasonForLost: item?.salesStage === 'Lost' ? item?.reasonForLost : '-',
                            createdAt: moment(item?.createdAt).format("DD-MM-YYYY"),
                            salesUser: data.sales_user !== '' ? data.sales_user : 'NA',
                            salesUserTeamId: data.sales_teamId,
                            salesManagerName: data.sales_manager
                        }
                        userTableViewData.push(obj)
                        assignUserList.push(item.assignTo?.name);
                        // setDepartmentName(obj.department);
                    });
                })

                const uniqueData = Array.from(new Set(userTableViewData.map(a => a.name)))
                    .map(id => {
                        return userTableViewData.find(a => a.name === id)
                    })
                const uniqueAssignToList = Array.from(new Set(assignUserList.map(a => a)))
                    .map(id => {
                        return assignUserList.find(a => a === id)
                    })
                // setCustomerList(uniqueData)
                setAssignToList(uniqueAssignToList);
                // setQTProjectList(userTableViewData)
                setQTProjectRows(userTableViewData)
                setDupQtProjects(userTableViewData)
                setQTBoardViewProject(response.data.docs)
                setTotalPages(response.data.totalDocs)
            }
        } catch (error) {
            console.log(error)
        }
        // const filteredRow = dupqtprojects.filter(row =>
        //     row.name.toLowerCase().includes(search.toLowerCase()) ||
        //     row.status.toLowerCase().includes(search.toLowerCase()) ||
        //     row.stage.toLowerCase().includes(search.toLowerCase())
        //     // row.assignTo.toLowerCase().includes(search.toLowerCase()) || 
        //     // row.projectNo.toString().toLowerCase().includes(search)
        // )
        // setQTProjectRows(filteredRow);
    }

    const projectTableView = (value) => {
        localStorage.setItem('View', value);
        setProjectViewType(value);
    }

    const projectBoardView = (value) => {
        localStorage.setItem('View', value);
        setProjectViewType(value);
    }

    const [departmentStage, setDepartmentsStage] = useState([]);
    const [departmentList, setDepartmentList] = useState([]);
    const getDepartments = async () => {
        try {
            const department = await HttpService.getDepartments();
            setDepartmentList(department.data)
            getDepartmentStages(department.data);
        } catch (error) {
            console.log(error, "get department error")
        }
    }

    const getDepartmentStages = async (data) => {
        let stages = []
        if (userDetails.roles.find(role => role.name === ROLENAME.MarketingUser || role.name === ROLENAME.MarketingManager)) {
            data.forEach((element) => {
                if (element['name'] === 'Sales') {
                    stages.push(element['stages']);
                };
            });
        } else if (userDetails.roles.find(role => role.name === ROLENAME.Admin)) {
            return
        } else {
            data.forEach((element) => {
                if (element['_id'] === userDetails.departmentId) {
                    stages.push(element['stages']);
                };
            });
        }
        setDepartmentsStage(stages[0]);
    }

    // const selectStageFilter = (stagename) => {
    //     if (stagename === 'All') {
    //         setQTProjectRows(dupqtprojects)
    //     } else {
    //         const filterStage = dupqtprojects.filter(ele => ele.stage === stagename);
    //         console.log(filterStage, 'ssss');
    //         setQTProjectRows(filterStage)
    //     }
    // }

    const filterData = () => {
        const newArr = [];
        setfilterd(true)
        dupqtprojects.forEach(o => {
            if (selectedStage.length !== 0 && selectedAssignTo.length !== 0) {
                if (selectedStage.some(stage => stage === o.stage) && selectedAssignTo.some(assign => assign === o.assignTo)) {
                    newArr.push(o)
                }
            } else if (selectedStage.length !== 0 || selectedAssignTo.length !== 0) {
                if (selectedStage.some(stage => stage === o.stage) || selectedAssignTo.some(assign => assign === o.assignTo)) {
                    newArr.push(o)
                }
            } else {
                newArr.push(o)
                setfilterd(false)
            }
        });
        setQTProjectRows(newArr)
        setTotalPages(newArr.length)
    };

    const [teamLists, setTeamLists] = useState([])
    const [deptStages, setDeptStages] = useState([])
    const [selectedDepartment, setSelectedDepartment] = useState('');
    const [selectedTeam, setSelectedTeam] = useState('');
    const [selectedDeptStage, setSelectedDeptStage] = useState('');

    const getDepartmentTeam = async (departmentId) => {
        setSelectedDepartment(departmentId);
        const selectedDept = departmentList.find(ele => ele._id === departmentId);
        let activeTeams = selectedDept.teams.filter(ele => ele.isActive === true);
        setTeamLists(activeTeams)
        setDeptStages(selectedDept.stages);
    }

    const filterDataForAdmin = async () => {
        let obj = {
            teamId: selectedTeam,
            departmentId: selectedDepartment,
            stage: selectedDeptStage,
            expCenterId: userDetails.selectedExpCenter
        }
        if (startDate && endDate) {
            obj.startDate = startDate;
            obj.endDate = endDate;
        }
        props.setLoading(true)
        try {
            const filterRes = await HttpService.getFilterResponse(obj);
            let i = 1;
            let userTableViewData = []
            filterRes.data.forEach(item => {
                let data = getSalesUser(item)
                item.serial = i++;
                let obj = {
                    projectId: item?._id,
                    name: item?.customerId?.name,
                    lead_no: item?.lead_no,
                    customerId: item?.customerId?._id,
                    status: item?.status,
                    stage: item?.salesStage === 'Won' ? item.designStages : item?.salesStage,
                    assignTo: item?.assignTo?.name,
                    department: item?.departmentId?.name,
                    totalCustomerOutflow: item?.grandTotal ? item?.grandTotal : `0`,
                    reasonForLost: item?.salesStage === 'Lost' ? item?.reasonForLost : '-',
                    createdAt: moment(item?.createdAt).format("DD-MM-YYYY"),
                    salesUser: data.sales_user !== '' ? data.sales_user : 'NA',
                    salesUserTeamId: data.sales_teamId,
                    salesManagerName: data.sales_manager
                }
                userTableViewData.push(obj)
                // setDepartmentName(obj.department);
            });
            // setQTProjectList(userTableViewData)
            setQTProjectRows(userTableViewData)
            setDupQtProjects(userTableViewData)
            setQTBoardViewProject(filterRes.data)
            setTotalPages(filterRes.data.length)
        } catch (error) {
            console.log(error, "get filter error")
            // setQTProjectList([])
            setQTProjectRows([])
            setDupQtProjects([])
            setQTBoardViewProject([])
            setTotalPages(0)
        }
        props.setLoading(false)
    }

    const clearFilterAdminData = () => {
        getAllQtProjects();
        setSelectedDepartment('');
        setSelectedTeam('');
        setSelectedDeptStage('');
    }
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState();
    const [dates, setDates] = useState({
        start: startDate,
        end: endDate
    });
    const [dateselected, setDateselected] = useState(false)
    const getLeadsOnSelectedDateByRole = async () => {
        setDateselected(true)
        setDates({
            start: startDate,
            end: endDate
        })
        props.setUserDetails({
            ...userDetails,
            selectedStartDate: startDate,
            selectedEndDate: endDate,
        })
        let data = {
            startDate: startDate,
            endDate: endDate,
            departmentId: userDetails.departmentId,
            search: null
        }
        try {
            props.setLoading(true)
            const qtUserProjectList = await HttpService.getDatefilterLeads(rowsPerPage, page, userDetails.selectedExpCenter, data);
            let i = 1;
            let userTableViewData = []
            let assignUserList = []
            if (qtUserProjectList?.data.docs.length !== 0) {
                qtUserProjectList.data.docs.forEach(ele => {
                    ele['projects'].forEach(item => {
                        let data = getSalesUser(item)
                        item.serial = i++;
                        let obj = {
                            projectId: item?._id,
                            name: item?.customerId?.name,
                            lead_no: item?.lead_no,
                            customerId: item?.customerId?._id,
                            status: item?.status,
                            // stage: item?.stage ? item?.stage : item.salesStage,
                            currentStage: item?.currentStage,
                            stage: item?.salesStage === 'Won' ? item.designStages : item?.salesStage,
                            assignTo: item?.assignTo?.name,
                            department: item?.departmentId?.name,
                            totalCustomerOutflow: item?.grandTotal ? item?.grandTotal : `0`,
                            reasonForLost: item?.salesStage === 'Lost' ? item?.reasonForLost : '-',
                            createdAt: moment(item?.createdAt).format("DD-MM-YYYY"),
                            salesUser: data.sales_user !== '' ? data.sales_user : 'NA',
                            salesUserTeamId: data.sales_teamId,
                            salesManagerName: data.sales_manager
                        }
                        userTableViewData.push(obj)
                        assignUserList.push(item.assignTo?.name);
                        // setDepartmentName(obj.department);
                    });
                })
                const uniqueData = Array.from(new Set(userTableViewData.map(a => a.name)))
                    .map(id => {
                        return userTableViewData.find(a => a.name === id)
                    })
                const uniqueAssignToList = Array.from(new Set(assignUserList.map(a => a)))
                    .map(id => {
                        return assignUserList.find(a => a === id)
                    })
                // setCustomerList(uniqueData)
                setAssignToList(uniqueAssignToList);
                // setQTProjectList(userTableViewData)
                setQTProjectRows(userTableViewData)
                setDupQtProjects(userTableViewData)
                setQTBoardViewProject(qtUserProjectList.data.docs)
                setTotalPages(qtUserProjectList.data.totalDocs)
            } else {
                setQTProjectRows([])
                setDupQtProjects([])
                setQTBoardViewProject([])
                setTotalPages(0)
            }
        } catch (error) {
            console.log(error, "get filter error")
        }
        props.setLoading(false)
    }


    const clearFilterDate = () => {
        setStartDate('');
        setEndDate('');
        setDateselected(false)
        setSelectedAssignTo([]);
        setSelectedStage([]);
        setfilterd(false)
        getAllQtProjects();
    }
    const [csvData, setCsvData] = useState([]);
    const 
    i = async () => {
        props.setLoading(true)
        let csvArray = []
        for (let l = 0; l < qtprojects.length; l++) {
            let obj = {
                name: qtprojects[l].name,
                lead_no: qtprojects[l].lead_no,
                assignTo: qtprojects[l].assignTo,
                createdAt: qtprojects[l].createdAt,
                stage: qtprojects[l].stage,
                department: qtprojects[l].department,
                lostReason: qtprojects[l].stage === 'Lost' ? qtprojects[l].reasonForLost : '-',
                QuotationAmount: qtprojects[l].totalCustomerOutflow,
                salesUser: qtprojects[l].salesUser
            }
            if (qtprojects[l].salesUserTeamId !== '') {
                for (let k = 0; k < usersData.length; k++) {
                    if (usersData[k]['teamId'] === qtprojects[l]['salesUserTeamId']) {
                        for (let n = 0; n < usersData[k]['roles'].length; n++) {
                            if (usersData[k].roles[n].name === 'Sales Manager') {
                                obj.salesManager = usersData[k].name
                            }
                        }
                    }
                }
            } else {
                obj.salesManager = qtprojects[l].salesManagerName
            }
            csvArray.push(obj)
        }
        setCsvData(csvArray)
        downloadExcel()
    }
    const downloadExcel = () => {
        // props.setLoading(true)
        try {
            setTimeout(() => {
                buttonRef.current.link.click();
            });
        } catch (err) {
            console.error(err.message);
        }
        props.setLoading(false)
    }

    return (
        <div>
            {/* {
                userDetails?.roles?.find(role => role.name === 'Design Head' || role.name === 'Sales Head' || role.name === 'Admin') && */}
                <Paper className={classes.paper}>
                    <h5>To View and Export the lead</h5>
                    <br />
                    <TextField
                        id="date"
                        name="startDate"
                        label="From Date"
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        fullWidth
                        className={classes.textField}
                    />
                    <TextField
                        id="date"
                        name="endDate"
                        label="End Date"
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        fullWidth
                        className={classes.textField}
                    />
                    <Button variant="contained" color="primary" disabled={!startDate || !endDate} style={{ marginLeft: 10 }} onClick={getLeadsOnSelectedDateByRole} >
                        Submit
                    </Button>
                    <Button variant="contained" color="secondary" style={{ margin: 10 }} onClick={() => clearFilterDate()} >
                        Clear All Filter
                    </Button>
                </Paper>
            {/* } */}
            <Paper style={{ marginTop: '20px', marginBottom: "20px", padding: "20px" }}>
                <div style={{ display: "flex" }}>
                    <Grid item xs>
                        <TextField label="Search By Customer Name, Lead Number and Project No." name="searched" size="small" value={searched} onChange={(e) => setHandleSearched(e.target.value)} variant="outlined" fullWidth />
                    </Grid>
                    {
                        userDetails?.roles?.find(role => role.name === 'Admin') && <>
                            <CSVLink
                                headers={headers}
                                filename={"leadsExcel" + '.csv'}
                                data={csvData}
                                ref={buttonRef}
                            />
                            <Button variant="contained" color="primary" size="small" style={{ marginLeft: 10 }} onClick={() => 
                                i()}>download Csv</Button>
                        </>
                    }
                    {/* <div style={{ display: "flex" }}>
                        {
                            departmentName === 'Sales' &&
                            <FormControl fullWidth style={{ width: 250 }}>
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    label="Select Stage"
                                    name="stageName"
                                    onChange={(e) => selectStageFilter(e.target.value)}
                                    variant="outlined"
                                    size='small'
                                >
                                    <MenuItem value="All">All</MenuItem>
                                    {
                                        salesStages?.map((data, i) => (
                                            <MenuItem value={data} key={i + 1}>{data}</MenuItem>
                                        ))
                                    }
                                </TextField>
                            </FormControl>
                        }
                        {
                            departmentName === 'Design' &&
                            <FormControl fullWidth style={{ marginBottom: 20, width: 300 }}>
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    label="Select Stage"
                                    name="stageName"
                                    onChange={(e) => selectStageFilter(e.target.value)}
                                    variant="outlined"
                                    size='small'
                                >
                                    <MenuItem value="All">All</MenuItem>
                                    {
                                        desginStages?.map((data, i) => (
                                            <MenuItem value={data} key={i + 1}>{data}</MenuItem>
                                        ))
                                    }
                                </TextField>
                            </FormControl>
                        }
                    </div> */}
                    {
                        userDetails?.roles?.find(role => role.name !== 'Admin') &&
                        <div style={{ display: "flex" }}>
                            <Button variant="contained" color="primary" size="small" style={{ marginLeft: 10 }} onClick={() => projectTableView('table')}>
                                Table View
                            </Button>
                            <Button variant="contained" color="secondary" size="small" style={{ marginLeft: 10 }} onClick={() => projectBoardView('board')}>
                                Board View
                            </Button>
                        </div>
                    }
                </div>
            </Paper>

            {
                projectViewType === 'table' && userDetails.roles.find(role => role.name !== ROLENAME.Admin) ?
                    <Paper style={{ marginTop: '20px', marginBottom: "20px", padding: "20px" }}>
                        <Grid container>
                            <Grid item md={4} sm={6} xs={12}>
                                <Autocomplete
                                    multiple
                                    onChange={(e, v) => setSelectedStage(v)}
                                    id="users-dropdown"
                                    options={departmentStage}
                                    value={selectedStage}
                                    getOptionLabel={(option) => option}
                                    filterSelectedOptions
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Select Stage"
                                            placeholder="Sales Stage"
                                        />
                                    )}
                                />
                            </Grid>
                            {userDetails.roles.find(role => role.name !== ROLENAME.SalesUser) &&
                                <Grid item md={4} sm={6} xs={12}>
                                    <Autocomplete
                                        multiple
                                        onChange={(e, v) => setSelectedAssignTo(v)}
                                        id="users-dropdown"
                                        options={assignToList}
                                        getOptionLabel={(option) => option}
                                        filterSelectedOptions
                                        value={selectedAssignTo}
                                        style={{ marginLeft: 10 }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label="Select User"
                                                placeholder="Sales User"
                                            />
                                        )}
                                    />
                                </Grid>
                            }
                            <Grid item md={2} sm={6} xs={12} style={{
                                margin: 'auto',
                                textAlign: "center"
                            }}>
                                <Button variant="contained" color="primary" onClick={() => filterData()}>
                                    Filter Data
                                </Button>
                            </Grid>
                            <Grid item md={2} sm={6} xs={12} style={{
                                margin: 'auto',
                                textAlign: "center"
                            }}>
                                <CSVLink
                                    headers={headers}
                                    filename={"leadsExcel" + '.csv'}
                                    data={csvData}
                                    ref={buttonRef}
                                />
                                <Button variant="contained" color="primary" onClick={() => 
                                    i()}>download Csv</Button>
                            </Grid>
                        </Grid>
                    </Paper>
                    :
                    null}

            {
                userDetails.roles.find(role => role.name === ROLENAME.Admin) ?
                    <Paper style={{ marginTop: '20px', marginBottom: "20px", padding: "20px" }}>
                        <div style={{ display: "flex" }}>
                            <FormControl variant="outlined" size="small" style={{ width: 200, marginRight: 10 }}>
                                <TextField
                                    select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name="department"
                                    label="Select Department"
                                    value={selectedDepartment}
                                    onChange={(e) => getDepartmentTeam(e.target.value)}
                                    variant="outlined"
                                    size="small"
                                >
                                    {
                                        departmentList?.map((data, i) => (
                                            <MenuItem value={data._id} key={i + 1}>{data.name}</MenuItem>
                                        ))
                                    }
                                </TextField>
                            </FormControl>
                            <FormControl variant="outlined" size="small" style={{ width: 200, marginRight: 10 }}>
                                <TextField
                                    select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name="team"
                                    label="Select Team"
                                    value={selectedTeam}
                                    onChange={(e) => setSelectedTeam(e.target.value)}
                                    variant="outlined"
                                    size="small"
                                >
                                    {
                                        teamLists?.map((data, i) => (
                                            <MenuItem value={data._id} key={i + 1}>{data.name}</MenuItem>
                                        ))
                                    }
                                </TextField>
                            </FormControl>
                            <FormControl variant="outlined" size="small" style={{ width: 200, marginRight: 10 }}>
                                <TextField
                                    select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name="stage"
                                    label="Select Stage"
                                    value={selectedDeptStage}
                                    onChange={(e) => setSelectedDeptStage(e.target.value)}
                                    variant="outlined"
                                    size="small"
                                >
                                    <MenuItem value='all' key='all'>All</MenuItem>
                                    {
                                        deptStages?.map((data, i) => (
                                            <MenuItem value={data} key={i + 1}>{data}</MenuItem>
                                        ))
                                    }
                                </TextField>
                            </FormControl>
                            <Button variant="contained" color="primary" size="small" style={{ marginLeft: 10 }} onClick={() => filterDataForAdmin()}>
                                View Details
                            </Button>
                            <Button variant="contained" color="secondary" size="small" style={{ marginLeft: 10 }} onClick={() => clearFilterAdminData()}>
                                Clear Filter
                            </Button>
                        </div>
                    </Paper>
                    :
                    null
            }
            <Paper>
                {
                    projectViewType === 'table' ?

                        <TableContainer component={Paper}>
                            <Table className={classes.table} stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <StyledTableRow>
                                        {
                                            filterd && <>
                                                <StyledTableCell colSpan={3} style={{ fontSize: '20px', backgroundColor: 'transparent', color: 'Green' }}>Data is filtered</StyledTableCell>
                                            </>
                                        }
                                        <TablePagination
                                            rowsPerPageOptions={[20, 50, 100, { label: 'All', value: totalaPages }]}
                                            colSpan={10}
                                            count={totalaPages}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            SelectProps={{
                                                inputProps: { 'aria-label': 'rows per page' },
                                                native: true,
                                            }}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                                    </StyledTableRow>
                                </TableHead>
                                <TableHead>
                                    <StyledTableRow>
                                        <StyledTableCell>Sr. No.</StyledTableCell>
                                        <StyledTableCell>Customer</StyledTableCell>
                                        <StyledTableCell>Lead No</StyledTableCell>
                                        {userDetails.roles.find(role => role.name === ROLENAME.MarketingUser || role.name === ROLENAME.MarketingManager || role.name === ROLENAME.Admin) && <StyledTableCell>Status</StyledTableCell>}
                                        <StyledTableCell>Stage</StyledTableCell>
                                        <StyledTableCell>Assigned to</StyledTableCell>
                                        <StyledTableCell>Department</StyledTableCell>
                                        <StyledTableCell>Quotation Amount</StyledTableCell>
                                        <StyledTableCell>Created At</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {qtprojects.sort((a, b) => b.createdAt.split('-').reverse().join().localeCompare(a.createdAt.split('-').reverse().join())).map((row, i) => {
                                        return (
                                            <StyledTableRow key={i + 1}>
                                                <StyledTableCell>{i + 1}</StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <Link to={`/project-logs/${row.projectId}`} style={{ color: "#000", textDecoration: 'underline' }}>{row?.name}</Link>
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">{row?.lead_no}</StyledTableCell>
                                                {userDetails.roles.find(role => role.name === ROLENAME.MarketingUser || role.name === ROLENAME.MarketingManager || role.name === ROLENAME.Admin) &&
                                                    <StyledTableCell component="th" scope="row">{row?.status}</StyledTableCell>
                                                }
                                                <StyledTableCell component="th" scope="row">{row?.stage}</StyledTableCell>
                                                <StyledTableCell component="th" scope="row">{row?.assignTo}</StyledTableCell>
                                                <StyledTableCell component="th" scope="row">{row?.department}</StyledTableCell>
                                                <StyledTableCell component="th" scope="row">₹ {commaSeparateAmount(row?.totalCustomerOutflow)}</StyledTableCell>
                                                <StyledTableCell component="th" scope="row">{row?.createdAt}</StyledTableCell>
                                            </StyledTableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        :
                        // style={{height: "500px"}}
                        <TableContainer component={Paper}>
                            <Table className="table table-responsive table-bordered" stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <StyledTableRow>
                                        <TablePagination
                                            rowsPerPageOptions={[20, 50, 100, { label: 'All', value: totalaPages }]}
                                            colSpan={10}
                                            count={totalaPages}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            SelectProps={{
                                                inputProps: { 'aria-label': 'rows per page' },
                                                native: true,
                                            }}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    <StyledTableRow>
                                        <StyledTableCell style={{ display: "inline-flex" }}>
                                            {qtBoardViewProjects.map((row, i) => {
                                                return (
                                                    <Table key={i + 1} className="table-responsive" style={{ width: "200px", borderRight: "1px solid #000" }}>
                                                        <TableHead style={{ height: "100px" }}>
                                                            <StyledTableRow>
                                                                <StyledTableCell>{row?.stage} - ({row?.projects?.length})</StyledTableCell>
                                                            </StyledTableRow>
                                                        </TableHead>
                                                        <TableBody style={{ display: "block", height: "60vh", overflow: "auto" }}>
                                                            {
                                                                row.projects.map((row1, i) => {
                                                                    return (
                                                                        <StyledTableRow key={i + 1} style={{ height: "100px", display: "flex", flexWrap: "nowrap", alignContent: "center", justifyContent: "space-evenly", flexDirection: "row" }}>
                                                                            <StyledTableCell style={{ border: "none", textAlign: "center" }}>
                                                                                <Link to={`/project-logs/${row1._id}`} style={{ color: "#000" }}>
                                                                                    {row1?.customerId?.name} <br />
                                                                                    (₹ {row1.grandTotal ? commaSeparateAmount(row1?.grandTotal) : `0`})
                                                                                </Link>
                                                                            </StyledTableCell>
                                                                        </StyledTableRow>
                                                                    )
                                                                })
                                                            }
                                                        </TableBody>
                                                        <TableFooter style={{ height: "80px" }}>
                                                            <StyledTableRow>
                                                                <StyledTableCell style={{ color: 'black' }}>
                                                                    <b>Total : ₹ {commaSeparateAmount(row.finalAmount)} </b>
                                                                </StyledTableCell>
                                                            </StyledTableRow>
                                                        </TableFooter>
                                                    </Table>
                                                );
                                            })}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                }
            </Paper>
        </div>
    )
}
export default connect(null, { setUserDetails, setLoading, setSnackInfo })(QTProjects)
