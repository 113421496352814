import { SET_USER_DETAILS } from '../actions/types'

const initialState = {
  userDetails: {},
}

export default function userReducer(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case SET_USER_DETAILS:
      return {
        ...state,
        userDetails: payload,
      }
    default:
      return state
  }
}