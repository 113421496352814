import React from 'react'
import { Table, TableBody, TableCell, TableHead, TableContainer, TableRow, withStyles, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import HttpService from '../services/httpService';
import { EyeOutlined } from '@ant-design/icons';
import { setLoading, setSnackInfo } from '../redux/actions/appAction'
import { connect } from 'react-redux'

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  }
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#ccc",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const MaterialTransferListView = (props) => {
  const classes = useStyles();

  const generateMTR = async (mtrId) => {
    props.setLoading(true)
    try {
      const result = await HttpService.generateMTR(mtrId)
      window.open(result.data.link)
      props.REFERSH()
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
    }
    props.setLoading(false)
  }

  return (
    <div>
      {props.MATERIALTRANSFERLIST?.length > 0 ? <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Sl No</StyledTableCell>
              <StyledTableCell>Order Id</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Transfer From</StyledTableCell>
              <StyledTableCell>Transfer To</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.MATERIALTRANSFERLIST?.map((data, i) => (
              <StyledTableRow key={i + 1}>
                <StyledTableCell>{i + 1}</StyledTableCell>
                <StyledTableCell>{data?.orderId}</StyledTableCell>
                <StyledTableCell>{data?.status}</StyledTableCell>
                <StyledTableCell>{data?.transferFrom?.toUpperCase()}</StyledTableCell>
                <StyledTableCell>{data?.transferTo?.toUpperCase()}</StyledTableCell>
                <StyledTableCell>
                  <StyledTableRow>
                    {(data.status === "Approved" || data.status === "Delivered" || data.status === "Outwarding") && <StyledTableCell style={{ cursor: "pointer" }} onClick={() => generateMTR(data?._id)}>Generate  MTR</StyledTableCell>}
                    <StyledTableCell style={{ cursor: "pointer" }} onClick={() => props.OPENDETAILSMODAL(data?._id, `${props.TITLE === "Po/Mtr Raised/Requested" ? "Po/Mtr Raised/Requested Details" : "Approve MTR"}`)} >View <EyeOutlined /></StyledTableCell>
                  </StyledTableRow>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer> : <div style={{ display: 'flex', justifyContent: "center" }}> No Material Request Found</div>
      }
      {/* <Modal
        title="MTR Details"
        centered
        visible={mtrDetailsModal}
        onCancel={() => setMtrDetailsModal(false)}
        width={1000}
        footer={null}
      >
        <MaterialTransferDetailsModal MTRDETAILS={mtrDetails} MOUNTED={mounted} />
      </Modal> */}
    </div>
  )
}

export default connect(null, { setLoading, setSnackInfo })(MaterialTransferListView)
