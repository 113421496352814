import React, { useEffect, useState } from "react";
import HttpService from "../../services/httpService";
import { setLoading, setSnackInfo } from "../../redux/actions/appAction";
import StatisticReport from "../../components/StatisticsReport";
import { connect } from "react-redux";

const DesignSignOffStats = (props) => {
	const [userBasedData, setUserBasedData] = useState([]);
	const [managerBasedData, setManagerBasedData] = useState([]);

	// To Get Design User & Design Manger Wise Data for (Design Sign Off)/Statistic Dashboard
	const getDesignUsersAndManagersBasedData = async (
		startDate,
		endDate,
		leadStatus,
		tokenValue
	) => {
		props.setLoading(true);
		try {
			const response = await HttpService.getDesignUsersAndManagersBasedData(
				startDate,
				endDate,
				leadStatus,
				tokenValue
			);
			setManagerBasedData(response.data[0].Manager.userTotals);
			setUserBasedData(response.data[1].User.userTotals);
		} catch (error) {
			console.log(error);
			setSnackInfo(error?.response?.data ?? "Something went wrong", "error");
		}
		props.setLoading(false);
	};

	// To Get Design User & Design Manger Wise Data for (Design Sign Off)/Statistic Dashboard Based on date selected
	const getDesignUsersAndManagersBasedDataForDateFilter = (
		startDate,
		endDate,
		leadStatus,
		tokenValue
	) => {
		getDesignUsersAndManagersBasedData(
			startDate,
			endDate,
			leadStatus,
			tokenValue
		);
	};

	useEffect(() => {
		getDesignUsersAndManagersBasedData("", "", "Won", "All Bookings");
	}, []);

	return (
		<StatisticReport
			TYPE="Design Sign Off"
			UserBasedData={userBasedData}
			ManagerBasedData={managerBasedData}
			FilterDataOnDates={getDesignUsersAndManagersBasedDataForDateFilter}
		/>
	);
};

export default connect(null, { setLoading, setSnackInfo })(DesignSignOffStats);
