import React, { useState } from 'react';
import { connect } from 'react-redux';
import { TextField, Button, Container, Typography, makeStyles, Paper, Snackbar } from '@material-ui/core';
import { setLoading, setSnackInfo } from '../../redux/actions/appAction';
import HttpService from '../../services/httpService';
import MuiAlert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
  },
  paper: {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    width: '100%', // Take full width of the Paper component
  },
  submitButton: {
    marginTop: theme.spacing(2),
  },
}));

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const CreateNewCustomer = (props) => {
  const classes = useStyles();

  const [formData, setFormData] = useState({
    email: '',
    contact_no: '',
    alternativeContactNo: '',
    address: '',
    customerName: '',
  });

  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [snackType, setSnackType] = useState('success');

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackOpen(false);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const isFilled = Object.values(formData).every(val => val.trim() !== ''); // Check if all values are not empty

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if(formData.contact_no.length < 11 && formData.contact_no.length >9 && formData.alternativeContactNo.length < 11 && formData.alternativeContactNo.length > 9){
        const res = await HttpService.createNewCustomer(formData);
      }else{
        props.setSnackInfo("Phone No Should be 10 Digit","error")
      }
    } catch (error) {
 
      props.setSnackInfo(error?.response?.data?.message,"error")
    }
  };

  return (
    <Container className={classes.container}>
      <Paper className={classes.paper}>
        <Typography variant="h4" component="h1" gutterBottom>
          Create a New Customer
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            label="Name"
            type="text"
            name="customerName"
            value={formData.customerName}
            onChange={handleChange}
            required
            fullWidth
            variant="outlined"
          />
          <TextField
            label="Email"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            fullWidth
            variant="outlined"
          />
          <TextField
            label="Phone Number"
            type="text"
            name="contact_no"
            value={formData.contact_no}
            onChange={handleChange}
            required
            fullWidth
            variant="outlined"
          />
          <TextField
            label="Alternative Phone Number"
            type="text"
            name="alternativeContactNo"
            value={formData.alternativeContactNo}
            onChange={handleChange}
            fullWidth
            variant="outlined"
          />
          <TextField
            label="Address"
            type="text"
            name="address"
            value={formData.address}
            onChange={handleChange}
            fullWidth
            variant="outlined"
          />
         
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submitButton}
            disabled={!isFilled}
          >
            Create Customer
          </Button>
        </form>
      </Paper>
      <Snackbar open={snackOpen} autoHideDuration={6000} onClose={handleSnackClose}>
        <Alert onClose={handleSnackClose} severity={snackType}>
          {snackMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default connect(null, { setLoading, setSnackInfo })(CreateNewCustomer);
