import './App.css';
import React, { useEffect } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LoginPage from './pages/login'
import { isLogin, isCustomerLoggedIn } from './utils'
import ForgotPassword from './pages/forgotPassword'
import Sidebar from './components/sidebar'
import ChangePasswordScreen from './components/changePasswordScreen'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from "@material-ui/lab/Alert";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { setAuthToken, setAuthTokenForCustomer } from './utils/index'
import { setSnack } from './redux/actions/appAction'
import { connect } from 'react-redux'
import OrganisationPage from './pages/organisations'
import CustomerCheckLists from './components/customerCheckLists';
import CustomerContractSignView from './components/customerContractSignView';
import CustomerDesignHoldApprovalView from './components/CustomerDesignHoldApprovalView';
//import Customerpreviewquotations from './components/customerpreviewQuotations';
import CustomerSurveyForm from './pages/customerSurveyForm';
import ThankYouPage from './components/thankYou';
import TermsAndCondition from './components/termAndCondition';
import UserLogin from './pages/cx_customer/login'
import CustomerDashboard from './pages/cx_customer/Dashboard';
import CustomerRoute from './pages/cx_customer/customerRoute';
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function App(props) {
  const classes = useStyles();
  const { loading, showSnack, snackMsg, snackSeverity } = useSelector(state => state.app)

  const hideSnack = () => {
    props.setSnack(false)
  };

  setAuthTokenForCustomer()
  if(localStorage.getItem('token')){
    setAuthTokenForCustomer(localStorage.getItem('token'))
  }


  setAuthToken()
  if (localStorage.getItem('TOKEN_KEY')) {
    setAuthToken(localStorage.getItem('TOKEN_KEY'));
  }

  return (
    <BrowserRouter>
      <Snackbar open={showSnack} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={4000} key={'topcenter'} onClose={hideSnack}>
        <Alert onClose={hideSnack} severity={snackSeverity}>
          {snackMsg}
        </Alert>
      </Snackbar>
      <Switch>
        <Route component={LoginPage} path="/" exact />
        <Route component={UserLogin} path="/user-login" exact />
        <Route component={ForgotPassword} path="/forgotPassword" exact />
        <Route component={ChangePasswordScreen} path="/changePassword" exact />
        <Route component={OrganisationPage} path="/select-organisation" exact />
        <Route component={CustomerCheckLists} path="/customer-check-lists-item/:leadId" exact />
        <Route component={CustomerContractSignView} path="/customer-contract-sign/:leadId" exact />
        <Route component={CustomerDesignHoldApprovalView} path="/customer-design-hold-approval/:leadId" exact />
        {/* <Route component={Customerpreviewquotations} path="/customerpreviewquotations/:quotion_id" exact/> */}
        <Route component={CustomerSurveyForm} path="/customer-survey-form/:type/:leadId" exact />
        <Route component={TermsAndCondition} path="/terms-and-condition" exact />
        <Route component={ThankYouPage} path="/thank-you" exact />
        {isLogin() &&  <Sidebar />}
        {isCustomerLoggedIn() && <CustomerRoute/>}
      </Switch>
      <Backdrop className={classes.backdrop} open={loading} >
        <CircularProgress color="inherit" />
      </Backdrop>
    </BrowserRouter>
  );
}

export default connect(null, { setSnack })(App);
