import React, { useState, useEffect, useRef } from 'react'
import { Link } from "react-router-dom";
import HttpService from '../../services/httpService'
import { connect, useSelector } from 'react-redux'
import { setLoading, setSnackInfo } from '../../redux/actions/appAction'
import { Typography, Paper, Table, TableBody, TableContainer, TableHead, TableRow, TableCell } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { CSVLink } from "react-csv";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Button, Input, Menu} from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import { ROLENAME, factoryCRMstagesArr } from '../../utils/index';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
// import 'antd/dist/antd.css';

const useStyles = makeStyles((theme)=> ({
    table: {
        minWidth: 700,
    },
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    input: {
        flex: 1,
    },
    iconButton: {
        padding: 10,
        width: '10%'
    },
    divider: {
        height: 28,
        margin: 4,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
      },
}));
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#ccc",
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const CHMLeads = (props) => {
    const classes = useStyles();
    const { userDetails } = useSelector(state => state.user)
    const [chmLeadList, setChmLeadList] = useState([])
    const [csvData, setCsvData] = useState([])
    const [selectedExpCenters,setSelectedExpcenters] = useState([]);
    const [storeExpCenter,setStoreExpcenter] = useState([])
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [totalaPages, setTotalPages] = useState(0)
    const [selectedUsers,setSelectedUsers] = useState([])
    const [storeChmUser,setChmUser] = useState([])
    const csvButtonRef = useRef(null);
    const [headers, setHeaders] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getCHMLeadLists()
        getExperienceCenters()
        getAllChmUsers()
    }, [rowsPerPage, page])

    const getExperienceCenters = async () => {
        props.setLoading(true)
        try {
            const response = await HttpService.getExpericenCenters()
            setStoreExpcenter(response.data)
        } catch (error) {
            props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const getAllChmUsers = async () => {
        props.setLoading(true)
        try {
            const response = await HttpService.getChmUser()
            setChmUser(response.data)
        } catch (error) {
            props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const getCHMLeadLists = async (obj) => {
        setLoading(true)
         try {
            let object = {
                selectedExpCenters: obj?.selectedExpCenters,
                selectedUsers: obj?.selectedUsers,
                limit : rowsPerPage,
                page : page
            }
            const result = await HttpService.getCHMLeadLists(object);
            setChmLeadList(result.data.data)
            setTotalPages(result.data.count)
            if (userDetails?.roles?.find(role => role.name === ROLENAME.FactoryCrmUser)) {
                console.log("factoryCRMstagesArr is ",factoryCRMstagesArr);
                const newData = result.data.data.filter((el) => {
                    return factoryCRMstagesArr.includes(el?.leadId?.currentStage)
                });
                setChmLeadList(newData)
                setTotalPages(newData.length)
            }
         } catch (error) {
            console.error(error)
            props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
        }
        setLoading(false)
    }

    const prepareCSVData = () => {
        return chmLeadList.map((row, index) => ({
          "Sr. No.": index + 1,
          "Project No.": row?.leadId?.erpProjectNo || 'NA',
          "Customer": row?.leadId?.customerId?.name || 'NA',
          'Email ID': row?.leadId?.customerId?.email || 'NA',
          'Phone Number': row?.leadId?.customerId?.contact_no || 'NA',
          "Lead No": row?.leadId?.lead_no || 'NA',
          "Stage": row?.leadId?.currentStage || 'NA',
          "Assigned To": row?.assignTo?.name || 'NA',
          "Assigned Designer": row?.leadId?.designUser?.name || 'NA',
          "Quotation Amount": row?.leadId?.grandTotal || 'NA',
        }));
      };
            
        const handleChangePage = (event, newPage) => {
            setPage(newPage);
        };
        const handleChangeRowsPerPage = (event) => {
            setRowsPerPage(+event.target.value);
            setPage(0);
        };
        
      const downloadCSV = () => {
        props.setLoading(true);
      
        try {
          const csvArray = prepareCSVData();
          setCsvData(csvArray);
          const headers = Object.keys(csvArray[0]).map((key) => ({
            key,
            label: key === "lead_no" ? "Lead no." : key.charAt(0).toUpperCase() + key.slice(1), // Customize label if needed
          }));
          setHeaders(headers);
          setTimeout(() => {
            csvButtonRef.current.link.click();
            props.setLoading(false);
          });
        } catch (error) {
          console.error(error.message);
          props.setLoading(false);
        }
      };

        const handleExpData = (v,value) => {
            const idArray = v.map(item => item._id);
            if(value ==='expcenter'){
                setSelectedExpcenters(idArray);
            }else{
                setSelectedUsers(idArray);
            }
         }
         const sendData = () =>{
            let obj = {
                selectedExpCenters : selectedExpCenters,
                selectedUsers : selectedUsers,
            }
            getCHMLeadLists(obj)
         }
    
         const clearNewData =() =>{
            setSelectedExpcenters([]); 
            setSelectedUsers([]); 
            getCHMLeadLists()
         }         

    return (
        <div>
            <Paper>
                <Typography variant="h6" gutterBottom style={{ marginLeft: 20, marginTop: 20, paddingTop: 20 }}>
                    CHM Leads
                </Typography >
                <div style={{padding:"0rem 0rem 0rem 1rem", display:"flex",  margin:"2rem"}}>
                <div style={{display:"flex"}}>
                <div>
                <div>
                <Autocomplete
                multiple
                value={selectedExpCenters.map(id => storeExpCenter.find(option => option._id === id))} 
                onChange={(e, v) => handleExpData(v, "expcenter")}
                options={storeExpCenter}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) => option._id === value._id} 
                style={{ width: 500, marginBottom: 10 }}
                renderInput={(params) => <TextField {...params} label="Select Exp-Center" fullWidth variant="outlined" />}
                />


                    </div>
                {
                      (userDetails?.roles?.find(role => role.name === "CHM_Manager" || role.name === ROLENAME.FactoryCrmUser) && (
                        <div>
                        <Autocomplete
                          multiple
                          value={selectedUsers.map(id => storeChmUser.find(option => option._id === id))} 
                          onChange={(e, v) => handleExpData(v,"user")}
                          options={storeChmUser ? storeChmUser : []}
                          getOptionLabel={(option) => option.name}
                          style={{ width: 500, marginBottom: 10 }}
                          renderInput={(params) => <TextField {...params} label="Select CHM_USERS" fullWidth variant="outlined" />}
                      /> </div>
                      ))
                }
               </div>
            </div>
            <div style={{ marginLeft: "1rem"}}>
                <div style={{ display: "flex", flexDirection: "row", width:"120%"}}>
                    <div>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={sendData}
                        style={{padding: "0.8rem 3rem 1rem 3rem"}}
                    >
                        Submit
                    </Button>
                    <CSVLink
                        headers={headers}
                        filename={"CHM_Leads.csv"}
                        data={csvData}
                        ref={csvButtonRef}
                        target="_blank"
                    />
                    <Button
                    variant="contained"
                    color="secondary"
                    onClick={clearNewData}
                        style={{ marginLeft: "1rem", padding: "0.8rem 3rem 1rem 3rem" }}
                    >
                        Clear Filter
                    </Button>
                    {
                    (userDetails?.roles?.find(role => role.name === "CHM_User") && (
                    <Button
                    onClick={downloadCSV}
                    variant="contained"
                    color="primary"
                    style={{ marginLeft: "1rem", padding: "0.8rem 3rem 1rem 3rem", backgroundColor: "green", color: "white"}}
                    size="small"
                    >
                    Download CSV
                    </Button>
                    ))}
                    </div>
                </div>
                {
                    (userDetails?.roles?.find(role => role.name === "CHM_Manager" || role.name === ROLENAME.FactoryCrmUser) && (
                <div>
                    <Button
                    onClick={downloadCSV}
                    variant="contained"
                    color="primary"
                    style={{padding: "0.8rem 3rem 1rem 3rem" , marginTop:"0.82rem", width:"100%", backgroundColor: "green", color: "white"}}
                    size="small"
                    >
                    Download CSV
                    </Button>
                </div>
                    ))}
                </div>
  </div>

                <TableContainer component={Paper}>
                    <Table className={classes.table} stickyHeader aria-label="sticky table">
                        <TableHead>
                        { !userDetails?.roles?.find(role => role.name === ROLENAME.FactoryCrmUser) && <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[50, 100, 200, 500, { label: 'All', value: totalaPages.length }]}
                                    colSpan={10}
                                    count={totalaPages.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: { 'aria-label': 'rows per page' },
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </TableRow>}
                            <StyledTableRow>
                                <StyledTableCell>Sr. No.</StyledTableCell>
                                <StyledTableCell>Project No.</StyledTableCell>
                                <StyledTableCell>Customer</StyledTableCell>
                                <StyledTableCell>Email ID</StyledTableCell>
                                <StyledTableCell>Phone No</StyledTableCell>
                                <StyledTableCell>Lead No</StyledTableCell>
                                <StyledTableCell>Stage</StyledTableCell>
                                <StyledTableCell>Assigned To</StyledTableCell>
                                <StyledTableCell>Assigned Designer</StyledTableCell>
                                <StyledTableCell>Quotation Amount</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {chmLeadList.map((row, i) => {
                                return (
                                    <StyledTableRow key={i + 1}>
                                        <StyledTableCell>{i + 1}</StyledTableCell>
                                        <StyledTableCell>{row?.leadId?.erpProjectNo}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">
                                            <Link to={`/project-logs/${row.leadId?._id}`} style={{ color: "#000", textDecoration: 'underline' }}>
                                                {row?.leadId?.customerId?.name}
                                            </Link>
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row">{row?.leadId?.customerId?.email}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">{row?.leadId?.customerId?.contact_no}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">{row?.leadId?.lead_no}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">
                                            
                                            {/*
                                            Not required
                                            {
                                                row?.leadId?.salesStage === 'Won' ? row?.leadId?.designStages :
                                                    row?.leadId?.designStages === 'Desing Sign-Off' ? row?.leadId?.imosStage :
                                                        row?.leadId?.imosStage === 'IMOS Completed' ? row?.leadId?.factoryStage : row?.leadId?.executionStage
                                            } */}
                                            { row?.leadId?.currentStage ? row?.leadId?.currentStage :"NA"}
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row">{row?.assignTo?.name}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">{row?.leadId?.designUser?.name ? row?.leadId?.designUser?.name :"NA"}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">{row?.leadId?.grandTotal}</StyledTableCell>
                                    </StyledTableRow>
                                );
                            })}
                        </TableBody>

                    </Table>
                </TableContainer>

            </Paper>
            <Backdrop className={classes.backdrop} open={loading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div >
    )
}

export default connect(null, { setLoading, setSnackInfo })(CHMLeads)