import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import HttpService from '../services/httpService';
import { setLoading, setSnackInfo } from '../redux/actions/appAction';

const useStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const PaymentReport = (props) => {
  const classes = useStyles();
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")

  const generatePaymentReportForPo = async () => {
    props.setLoading(true)
    if (!startDate || !endDate) {
      props.setSnackInfo("Please enter start and end date", "error");
      props.setLoading(false);
      return;
  }
    try {
      const result = await HttpService.generatePaymentReportForPo({ startDate, endDate })
      window.open(result.data)
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  const generatebankformatPaymentReportForPo = async () => {
    props.setLoading(true)
    if (!startDate || !endDate) {
      props.setSnackInfo("Please enter start and end date", "error");
      props.setLoading(false);
      return;
  }
    try {
      const result = await HttpService.generatebankformatPaymentReportForPo({ startDate, endDate })
      window.open(result.data)
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  const generatePaymentReportForSo = async () => {
    props.setLoading(true)
    if (!startDate || !endDate) {
      props.setSnackInfo("Please enter start and end date", "error");
      props.setLoading(false);
      return;
  }
    try {
      const result = await HttpService.generatePaymentReportForSo({ startDate, endDate })
      window.open(result.data)
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }
  const generatebankformatPaymentReportForSo = async () => {
    props.setLoading(true)
    if (!startDate || !endDate) {
      props.setSnackInfo("Please enter start and end date", "error");
      props.setLoading(false);
      return;
  }
    try {
      const result = await HttpService.generatebankformatPaymentReportForSo({ startDate, endDate })
      window.open(result.data)
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  return (
    <div>
      <div style={{ fontSize: 18 }}><b>Generate Payment Report</b></div>
      <div style={{ display: 'flex', marginTop: 10 }}>
        <TextField
          id="date"
          name="startDate"
          label="Start Date"
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          id="date"
          name="endDate"
          label="End Date"
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
        />

         <Button variant="contained" color="primary" style={{ alignSelf: "center", marginLeft: 20 }} onClick={generatebankformatPaymentReportForPo} >
           Bank Format Generate Report For Po
        </Button>
        <Button variant="contained" color="primary" style={{ alignSelf: "center", marginLeft: 20 }} onClick={generatePaymentReportForPo} >
          Generate Report For Po
        </Button>
        <Button variant="contained" color="primary" style={{ alignSelf: "center", marginLeft: 20 }} onClick={generatePaymentReportForSo} >
          Generate Report For So
        </Button>
        <Button variant="contained" color="primary" style={{ alignSelf: "center", marginLeft: 20 }} onClick={generatebankformatPaymentReportForSo} >
          Bank Format Generate Report For So
        </Button>
        
      </div>
    </div>
  )
}

export default connect(null, { setLoading, setSnackInfo })(PaymentReport)
