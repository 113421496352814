import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { setLoading, setSnackInfo } from '../redux/actions/appAction'
import HttpService from '../services/httpService'
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow, TableHead } from '@material-ui/core';
import moment from 'moment';
import { TextField } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 850,
    },
    textField: {
        width: 100
    },
    radioButton: {    
        // padding:20,
        marginLeft: "20px"
    }
}));

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#ccc",
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);


const PaymentApprover = (props) => {
    const classes = useStyles();
    const [radioValue, setRadioValue] = useState('PO')
    const [paymentRequests, setPaymentRequests] = useState([])
    const [totalValues, setTotalValues] = useState({
    total: 0,
    paymentDoneTotal: 0,
    raisedAmountTotal: 0,
    amountDueTotal: 0,
    approvingInputTotal:0
  })
    useEffect(() => {
      getData(radioValue)
    },radioValue)

    const handleRadioChange = (e) => {
        setRadioValue(e.target.value)
        getData(e.target.value)
    }

    const getData = async (type) =>{
      props.setLoading(true)
        if(type === "PO"){
          try {
            const result = await HttpService.getPopaymentreq()
            setPaymentRequests(result.data)
            let paymentRequests = result.data
            let total = 0, paymentDoneTotal = 0, raisedAmountTotal = 0, amountDueTotal = 0
        if (paymentRequests.length > 0) {
            total = paymentRequests.reduce((acc, crr) => ({ total: +acc.total + +crr.total })).total
            paymentDoneTotal = paymentRequests.reduce((acc, crr) => ({ paymentDone: +acc.paymentDone + +crr.paymentDone })).paymentDone
            raisedAmountTotal = paymentRequests.reduce((acc, crr) => ({ raisedAmount: +acc.raisedAmount + +crr.raisedAmount })).raisedAmount
            amountDueTotal = +total - (+paymentDoneTotal + +raisedAmountTotal)
            setTotalValues({ ...totalValues, total, paymentDoneTotal, raisedAmountTotal, amountDueTotal })
        }
          } catch (error) {
            console.error(error)
            props.setSnackInfo('Error fetching data', "error")
            props.setLoading(false)
          }
        }else if(type === "SO"){
          try {
            const result = await HttpService.getSopaymentreq()
            // console.log('result.data for getSopaymentreq is',result.data)
            setPaymentRequests(result.data)
            let paymentRequests = result.data
            let total = 0, paymentDoneTotal = 0, raisedAmountTotal = 0, amountDueTotal = 0, approvingInputTotal = 0
        if (paymentRequests.length > 0) {
            total = paymentRequests.reduce((acc, crr) => ({ total: +acc.total + +crr.total })).total
            paymentDoneTotal = paymentRequests.reduce((acc, crr) => ({ paymentDone: +acc.paymentDone + +crr.paymentDone })).paymentDone
            raisedAmountTotal = paymentRequests.reduce((acc, crr) => ({ raisedAmount: +acc.raisedAmount + +crr.raisedAmount })).raisedAmount
            amountDueTotal = +total - (+paymentDoneTotal + +raisedAmountTotal)
            approvingInputTotal = paymentRequests.reduce((acc, paymentRequest) => acc + +paymentRequest.paymentRequests[0].amount, 0);
            // console.log('approvingInputTotal is ',approvingInputTotal)
            setTotalValues({ ...totalValues, total, paymentDoneTotal, raisedAmountTotal, amountDueTotal , approvingInputTotal: approvingInputTotal})
        }
          } catch (error) {
            console.error(error)
            props.setSnackInfo('Error fetching data', "error")
            props.setLoading(false)
          }
        }
        props.setLoading(false)
    }

    const editPayementRequest = async (status, payment) => {
      props.setLoading(true)
      try {
        if (radioValue === "SO") {
          const response = await HttpService.approvereditServiceOrderPaymentStatus(payment?._id, { status, amount: payment.amount })
          if (response.status === 200) {
            window.location.reload(true)
            props.setSnackInfo(`Payment ${status}`, "success")
          }
        } else if(radioValue === "PO"){
          const response = await HttpService.approverProcurementOrderPaymentStatus(payment?._id, { status, amount: payment.amount })
          if (response.status === 200) {
            window.location.reload(true)
            props.setSnackInfo(`Payment ${status}`, "success")
          }
        }
      } catch (error) {
        console.error(error)
        props.setSnackInfo(error?.response?.data ?? "Something went Wrong", "error")
      }
      props.setLoading(false)
    }
  
    const changeRaisedAmount = (e, i, j) => {
      // console.log('triggered changeRaisedAmount')
      // console.log('paymentRequests[i] is ',paymentRequests[i])
      const remainingSoAmount = +paymentRequests[i].total - +paymentRequests[i].statusApprovedAmount

      if(remainingSoAmount >= +e.target.value)
      {
      let payments = [...paymentRequests]
      payments[i].paymentRequests[j].amount = e.target.value
      // console.log('payments are ',payments)
      setPaymentRequests(payments)
      }
      else{
        alert(`Remaining SO Amount limit is ${remainingSoAmount}`)
      }
    }

    return (
        <div>
            <Paper className={classes.paper} style={{ textAlign: 'center', marginBottom: 20 }}>
                <FormControl component="fieldset" className={classes.root}>
                    <RadioGroup aria-label="type" name="type" value={radioValue} onChange={handleRadioChange}>
                        <div className={classes.radioButton}>
                            <>
                                <FormControlLabel value="PO" control={<Radio />} label="PO" />
                                <FormControlLabel value="SO" control={<Radio />} label="SO" />
                            </>
                        </div>
                    </RadioGroup>
                </FormControl>
            </Paper>


            {paymentRequests?.length > 0 ? <TableContainer TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Sl No</StyledTableCell>
              <StyledTableCell>Order Id</StyledTableCell>
              <StyledTableCell>Client & Code</StyledTableCell>
              <StyledTableCell>Vendor Name</StyledTableCell>
              <StyledTableCell>Vendor Category</StyledTableCell>
              <StyledTableCell>{`${radioValue} Total`}</StyledTableCell>
              {/* <StyledTableCell>Payment Done</StyledTableCell> */}
              <StyledTableCell>Raised Amount</StyledTableCell>
              <StyledTableCell>Payment (%)</StyledTableCell>
              <StyledTableCell>Amount Due</StyledTableCell>
              <StyledTableCell>Created At</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paymentRequests?.map((data, i) => <StyledTableRow key={i + 1}>
              <StyledTableCell>{i + 1}</StyledTableCell>
              <StyledTableCell>{data?.orderId}</StyledTableCell>
              <StyledTableCell>{`${data?.projectId?.clientName ?? 'OPEN'} ${data?.projectId?.code ?? 'PROCUREMENT'}`}</StyledTableCell>
              <StyledTableCell>{data?.vendorName || data?.vendorId?.name || data?.materials[0]?.vendorId?.name}</StyledTableCell>
              <StyledTableCell>NA</StyledTableCell>
              <StyledTableCell>{data?.total}</StyledTableCell>
              {/* <StyledTableCell>{`${+data?.paymentDone} (${((+data?.paymentDone / +data?.total) * 100).toFixed(2)}%)`}</StyledTableCell> */}
              <StyledTableCell>{+data?.raisedAmount}</StyledTableCell>
              <StyledTableCell>{(((data.paymentRequests[0].amount) / +data?.total) * 100).toFixed(2)}%</StyledTableCell>
              <StyledTableCell>{(+data?.total - (+data?.paymentDone + +data?.statusApprovedAmount)).toFixed(2)}</StyledTableCell>
              <StyledTableCell>{moment(data?.createdAt).format('DD-MM-YYYY')}</StyledTableCell>
              <StyledTableCell>{data?.status}</StyledTableCell>
              <TableRow>
                <StyledTableCell>Amount</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
              {data?.paymentRequests?.map((request, j) => <StyledTableRow>
                <StyledTableCell>
                  <StyledTableRow>
                    <StyledTableCell>
                      <TextField value={request?.amount} onChange={(e) => changeRaisedAmount(e, i, j)} variant="outlined" className={classes.textField} />
                    </StyledTableCell>
                    <StyledTableCell>
                      {`(${((+request?.amount / +data?.total) * 100).toFixed(2)}%)`}
                    </StyledTableCell>
                  </StyledTableRow>
                </StyledTableCell>
                <StyledTableCell><div className="approve-reject-btn">
                  <button onClick={() => editPayementRequest("Approved", request)} className='approve-btn'>Approve</button>
                  <button onClick={() => editPayementRequest("Rejected", request)} className='reject-btn'>Reject</button>
                </div></StyledTableCell>
              </StyledTableRow>)}
            </StyledTableRow>)}
            {/* <StyledTableRow key={'total'}>
              <StyledTableCell>Total</StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell>{totalValues.total}</StyledTableCell>
              <StyledTableCell>{totalValues.paymentDoneTotal}</StyledTableCell>
              <StyledTableCell>{totalValues.raisedAmountTotal}</StyledTableCell>
              <StyledTableCell>{(((+totalValues.paymentDoneTotal + +totalValues.raisedAmountTotal) / +totalValues.total) * 100).toFixed(2)}%</StyledTableCell>
              <StyledTableCell>{totalValues.amountDueTotal.toFixed(2)}</StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
              {console.log('totalValues.approvingInputTotal is ',totalValues.approvingInputTotal)}
              <StyledTableCell>{totalValues.approvingInputTotal}</StyledTableCell>
            </StyledTableRow> */}
          </TableBody>
        </Table>
      </TableContainer> : <div style={{ display: 'flex', justifyContent: "center" }}> No Payment Request Raised</div>
      }
        </div >
    )
}

export default connect(null, { setLoading, setSnackInfo })(PaymentApprover)
