import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import HttpService from '../../services/httpService';
import { setLoading, setSnackInfo } from '../../redux/actions/appAction';
import {Paper, Button } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    myDashboardButton: { 
        backgroundColor: '#e6ecfa', 
        color: '#14419c', 
        padding: '1em',
        margin: '0.5em 0',
        borderRadius: '20px', 
        "&:hover": { backgroundColor: "#14419c", color: 'white' } 
    },

    whatsAppChatButton: { 
        backgroundColor: '#c4f5c1', 
        color: '#2e572c', 
        padding: '1em',
        margin: '0.5em 0',
        borderRadius: '20px', 
        "&:hover": { backgroundColor: "#2e572c", color: 'white' } 
    },

    incomingCallButton: { 
        backgroundColor: '#c0e2ed', 
        color: '#224f5e', 
        padding: '1em',
        margin: '0.5em 0',
        borderRadius: '20px', 
        "&:hover": { backgroundColor: "#224f5e", color: 'white' } 
    },

    breakMeetingButton: { 
        backgroundColor: '#edbdb4', 
        color: '#6b2012', 
        padding: '1em',
        margin: '0.5em 0',
        borderRadius: '20px', 
        "&:hover": { backgroundColor: "#6b2012", color: 'white' } 
    },

    outBoundCallButton: { 
        backgroundColor: '#dbc1e8', 
        color: '#472d54', 
        padding: '1em',
        margin: '0.5em 0',
        borderRadius: '20px', 
        "&:hover": { backgroundColor: "#472d54", color: 'white' } 
    },
    centered: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '2%'
    },

    buttonSpacing: {
        margin: '0 10px'
    },
    fullHeightPaper: {
        height: '80vh',
        justifyContent: 'center'
    },
    largeButton: {
        padding: '40px 40px',
        fontSize: '2rem'
    },
    
}));

const Home = (props) => {
    const classes = useStyles();

    useEffect(() => {
    }, [])

    const goToDashboard = () => {
        props.history.push(`/dashboard`);
    }

    const goToWhatsAppChat = () => {
        props.setSnackInfo('WhatsApp Chat Page will be implemented soon', 'success')
        // write logic here
    }

    const goToIncomingCall = () => {
        props.setSnackInfo('Incoming Call Page will be implemented soon', 'success')
        // write logic here
    }

    const handleBreakMeeting = () => {
        props.setSnackInfo('Break/Meeting functionality will be implemented soon', 'success')
        // write logic here
    }

    const goToOutboundCall = () => {
        props.setSnackInfo('OutBound Call Page will be implemented soon', 'success')
        // write logic here
    }

    

    return (
        <div>
            <Paper className={classes.fullHeightPaper}>
                <div style={{ padding: 10 }}>
                    <div style={{ fontSize: 18, marginBottom: '2%' }}>
                        <Button className={classes.myDashboardButton} onClick={goToDashboard} variant="outlined">
                            My Dashboard
                        </Button>
                    </div>

                    <div className={classes.centered}>
                        <Button className={`${classes.whatsAppChatButton} ${classes.largeButton}`} onClick={goToWhatsAppChat} variant="outlined">
                            WhatsApp Chat
                        </Button>
                        <div className={classes.buttonSpacing}></div>
                        <Button className={`${classes.incomingCallButton} ${classes.largeButton}`} onClick={goToIncomingCall} variant="outlined">
                            Incoming Call
                        </Button>
                    </div>

                    <div className={classes.centered}>
                        <Button className={`${classes.breakMeetingButton} ${classes.largeButton}`} onClick={handleBreakMeeting} variant="outlined">
                            Break / Meeting
                        </Button>
                        <div className={classes.buttonSpacing}></div>
                        <Button className={`${classes.outBoundCallButton} ${classes.largeButton}`} onClick={goToOutboundCall} variant="outlined">
                            Outbound Call
                        </Button>
                    </div>
                </div>
            </Paper>
        </div>
    )
}

export default connect(null, { setLoading, setSnackInfo })(Home)
