import React, { useState, useEffect, useRef } from 'react';
import { useParams } from "react-router-dom";
import { connect, useSelector } from 'react-redux'
import { Link } from "react-router-dom";
import { setLoading, setSnackInfo } from '../redux/actions/appAction';
import HttpService from '../services/httpService';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { CSVLink } from "react-csv";
import TextField from '@material-ui/core/TextField';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Grid, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import AttachmentIcon from '@material-ui/icons/Attachment';
import { commaSeparateAmount } from './commaSeparate';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ROLENAME } from '../utils/index';
// import { DataGrid } from '@material-ui/data-grid';

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 700,
    },
    root: {
        width: '100%',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));

const ShowStatusInformation = (props) => {
    const classes = useStyles();
    const { statusName, startDate, endDate, check, isUser } = useParams();
    const [leadStatusData, setLeadStatusData] = useState([])
    const [duplicateLeadStatusData, setDuplicateLeadStatusData] = useState([])
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(200);
    const { userDetails } = useSelector(state => state.user)
    const [selectedFilterOption, setSelectedFilterOption] = useState('');
    const [startDateFilter, setStartDate] = useState(startDate)
    const [endDateFilter, setEndDate] = useState(endDate);
    const headers = [
        { key: "customerName", label: "Customer Name" },
        { key: "lead_no", label: "Lead No" },
        { key: "email", label: "Email Id" },
        { key: "contact_no", label: "Mobile No." },
        { key: 'salesStage', label: "salesStage" },
        { key: "lostReason", label: "Lost Reason" },
        { key: "address", label: "Address" },
        { key: "area", label: "Area" },
        { key: 'leadType', label: "Lead Type" },
        { key: "assignTo", label: 'Assign To' },
        { key: "estimatedBudget", label: 'Quotation Amount' },
        { key: "createdAt", label: 'Created At' },
    ];

    const expectedSalesclosureDateHeader = userDetails.roles.find(role =>
        role.name === ROLENAME.SalesUser ||
        role.name === ROLENAME.SalesManager ||
        role.name === ROLENAME.AssistantSalesManager ||
        role.name === ROLENAME.Admin
    );
    
    if (expectedSalesclosureDateHeader) {
        headers.push({ key: "expectedSalesclosureDate", label: 'Expected Sales Closure Date' });
    }


    const [csvData, setCsvData] = useState([]);
    const buttonRef = useRef(null);
    const [searched, setSearched] = useState("")

    const handleChangeFilterOption = (event) => {
        setSelectedFilterOption(event.target.value);
    };


    const [isRemiderStatus, setIsReminderStatus] = useState(false);
    useEffect(() => {
        getLeadCountDetails();
        // eslint-disable-next-line
    }, [statusName,startDate, endDate, selectedFilterOption])

    const getLeadCountDetails = async () => {
        let stname = statusName;
        let obj = {
            startDate: startDateFilter,
            endDate: endDateFilter
        }
        if (userDetails.departmentId === '5cb5b38bcf5531e174cb23e0') {
            // obj.filterOption = props.location.state === null ? 'createdAt' : props.location.state.filterOption;
            obj.filterOption = selectedFilterOption
        }
        if (props.location.state !== null) {
            obj.experienceCenterId = props.location.state.experienceCenterId
        } else {
            obj.experienceCenterId = userDetails.selectedExpCenter
        }
        if (check === 'status') {
            if (stname !== 'Total-Leads-Generated') {
                obj.status = stname.replace(/-/g, ' ')
            } else if (stname === 'Total-Leads-Generated') {
                obj.status = "Total-Leads-Generated"
            }
            if (stname === 'Leads-Assiged-(Stage-1)') {
                obj.status = 'Leads-Assiged-(Stage-1)'
                obj.salesStage = 'Lead Received'
                obj.assignTo = userDetails._id
            }
            // if (stname === 'Lead-Returned-By-Sales') {
            //     // obj.leadRetunedBySales = 'Lead Returned By Sales'
            //     getMarketingData('Lead Returned By Sales')
            // }
            // if (stname === 'Real' || stname === 'Hold' || stname === 'Junk' || stname === 'To-Be-Called') {
            //     // obj.leadType = stname
            //     getMarketingData(stname)
            // }
            if (stname === 'Leads-Pending-Assignment' && props.location.state.selectedUserId.length !== 0) {
                obj.selectedUserList = props.location.state.selectedUserId
                obj.isUserSelected = 'true'
            } else {
                obj.isUserSelected = 'false'
            }
            if (isUser !== 'false') {
                obj.leadStatus = stname
                obj.assignTo = isUser
                obj.status = 'NA'
            }
        } else if (check === 'salesHead') {
            console.log(isUser, "Is User")
            obj.assignTo = isUser
            obj.stageStatus = statusName === 'Site Office visit' ? 'Site/Office visit' : statusName
            obj.status = 'salesHead'
        } else if (check === 'salesuser') {
            obj.assignTo = isUser
            obj.stageStatus = statusName === 'Site Office visit' ? 'Site/Office visit' : statusName
            obj.status = 'salesuser'
        } 
        // else if (check !== 'salesuser' && check !== 'status') {
        //     console.log('calling team')
        //     obj.teamId = check
        // }
        if (stname === 'won-customer-in-other-dept') {
            getWonCustomerInOtherDeptList()
        } else if (stname === 'Open-Leads(Current-and-last-2-month)') {
            getFilterWiseData(props.location.state.data.openLeads);
        } else if (stname === 'Won-Deals-Pending-10-') {
            getFilterWiseData(props.location.state.data.leadsPending10);
        } else if (stname === 'Activity-For-Today') {
            setUserReminderData(props.location.state.data.activitiesForToday);
            setIsReminderStatus(true)
        } else if (stname === 'Real' || stname === 'Hold' || stname === 'Junk' || stname === 'To-Be-Called' || stname === 'Lead-Returned-By-Sales' || stname === 'Total-Leads-Generated') {
            getMarketingData(stname)
        } else if (check !== 'salesuser' && check !== 'salesHead' && check !== 'status') {
            let teamData = {
                name: 'team',
                teamId: check
            }
            getMarketingData(teamData)
         } else {
            props.setLoading(true)
            try {
                console.log(obj, "obj")
                const response = await HttpService.getStatusWiseLeadData(obj)
                getFilterWiseData(response.data);
            } catch (error) {
                console.log(error, 'error')
                // props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
            }
            props.setLoading(false)
        }
    }

    const getMarketingData = async (type) => {
        props.setLoading(true)
        try {
            let obj = {
                startDate: startDateFilter,
                endDate: endDateFilter
            }
            if (props.location.state !== null) {
                obj.experienceCenterId = props.location.state.experienceCenterId
            } else {
                obj.experienceCenterId = userDetails.selectedExpCenter
            }
            if (type === 'Lead-Returned-By-Sales') {
                obj.status = 'Lead Returned By Sales'
            } else if (type === 'To-Be-Called') {
                obj.type = 'To Be Called'
            } else if (type === 'Total-Leads-Generated') {
                obj.totalLead = 'totalLeads'
            } else if (type.name === 'team') {
                obj.teamId = type.teamId
            } else {
                obj.type = type
            }
            const response = await HttpService.getMarketingStatusWiseData(obj)
            getFilterWiseData(response.data);
        } catch (error) {
            console.log(error, 'error')
        }
        props.setLoading(false)
    }

    const getWonCustomerInOtherDeptList = async () => {
        props.setLoading(true)
        try {
            let obj = {
                selectedFilterOption: selectedFilterOption,
                startDate: startDateFilter,
                endDate: endDateFilter,
                experienceCenterId: userDetails.selectedExpCenter
            }
            const response = await HttpService.getWonCustomerInOtherDeptList(obj)
            getFilterWiseData(response.data);
        } catch (error) {
            console.log(error)
        }
        props.setLoading(false)
    }
    const [assignToList, setAssignToList] = useState([]);
    const [departmentStage, setDepartmentsStage] = useState([]);
    const [selectedAssignTo, setSelectedAssignTo] = useState([]);
    const [selectedStage, setSelectedStage] = useState([]);
    const getFilterWiseData = (data) => {
        if (statusName.includes('Sales-Team')) {
            const sname = data.filter(ele => ele.assignTo.teamId.name.replace(/[\s+%/\\#,+$~.'":*?<>{}]/g, '') === statusName.replace(/-/g, ''));
            const statusLead = data.map(item => {
                let obj = {
                    leadId: item?._id,
                    lead_no: item?.lead_no,
                    customerName: item?.customerId?.name,
                    customerId: item?.customerId?._id,
                    email: item?.customerId?.email,
                    contact_no: item?.customerId?.contact_no,
                    address: item?.address,
                    area: item?.area,
                    salesStage: item?.salesStage,
                    currentStage: item?.currentStage,
                    lostReason: item?.salesStage === 'Lost' ? item?.reasonForLost : '-',
                    leadType: item?.leadType,
                    assignTo: item?.assignTo?.name,
                    estimatedBudget: item?.grandTotal,
                    createdAt: moment(item?.createdAt).format("DD-MM-YYYY"),
                    expectedSalesclosureDate:  moment(item?.expectedSalesclosureDate).format("DD-MM-YYYY"),
                }
                return obj
            })
            setLeadStatusData(statusLead);
            setDuplicateLeadStatusData(statusLead);
            setCsvData(statusLead);
        } else {
            let assignUserList = []
            let stageList = [];
            const statusLead = data?.map(item => {
                let obj = {
                    leadId: item?._id,
                    lead_no: item?.lead_no,
                    erpProjectNo:item?.erpProjectNo,
                    customerName: item?.customerId?.name,
                    customerId: item?.customerId?._id,
                    salesStage: item?.salesStage,
                    currentStage: item?.currentStage,
                    lostReason: item?.salesStage === 'Lost' ? item?.reasonForLost : '-',
                    email: item?.customerId?.email,
                    contact_no: item?.customerId?.contact_no,
                    address: item?.address,
                    area: item?.area,
                    leadType: item?.leadType,
                    assignTo: item?.assignTo?.name,
                    estimatedBudget: item?.grandTotal,
                    createdAt: moment(item?.createdAt).format("DD-MM-YYYY"),
                    expectedSalesclosureDate:  moment(item?.expectedSalesclosureDate).format("DD-MM-YYYY"),

                }
                stageList.push(item?.salesStage);
                assignUserList.push(item?.assignTo?.name)
                return obj
            })
            const uniqueStageList = Array.from(new Set(stageList.map(a => a)))
                .map(id => {
                    return stageList.find(a => a === id)
                })

            const uniqueAssignToList = Array.from(new Set(assignUserList.map(a => a)))
                .map(id => {
                    return assignUserList.find(a => a === id)
                })
            setAssignToList(uniqueAssignToList);
            setDepartmentsStage(uniqueStageList);
            setLeadStatusData(statusLead);
            setDuplicateLeadStatusData(statusLead);
            setCsvData(statusLead);
        }
    }

    const [reminderNotes, setReminderNotes] = useState([]);
    const setUserReminderData = (data) => {
        setReminderNotes(data)
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) =>
    {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const downloadExcel = () => {
        props.setLoading(true)
        try {
            setTimeout(() => {
                buttonRef.current.link.click();
            });
        } catch (err) {
            console.error(err.message);
        }
        props.setLoading(false)
    }

    const setHandleSearched = (e) => {
        setSearched(e.target.vlue)
        requestSearch(e.target.value)
    }

    const requestSearch = (search) => {
        const filteredRow = duplicateLeadStatusData.filter(row =>
            row.customerName.toLowerCase().includes(search.toLowerCase()) ||
            row.lead_no.toString().includes(search) ||
            row.erpProjectNo?.toString().includes(search) ||
            row.email.toLowerCase().includes(search.toLowerCase()) ||
            row.salesStage.toLowerCase().includes(search.toLowerCase()) ||
            row.currentStage.toLowerCase().includes(search.toLowerCase()) ||
            row.assignTo.toLowerCase().includes(search.toLowerCase()) ||
            row.contact_no.toLowerCase().includes(search.toLowerCase()) ||
            row.address.toLowerCase().includes(search.toLowerCase()) ||
            row.area.toString().toLowerCase().includes(search.toLowerCase()) ||
            row.leadType.toString().toLowerCase().includes(search.toLowerCase())
        )
        setLeadStatusData(filteredRow);
        setCsvData(filteredRow);
    }



    const getLeadsOnSelectedDateByRole = () => {
        if (statusName === 'won-customer-in-other-dept') {
            getWonCustomerInOtherDeptList()
        } else if (statusName === 'Open-Leads(Current-and-last-2-month)') {
            if (selectedFilterOption === 'createdAt') {
                let filterDatas = duplicateLeadStatusData.filter(data => new Date(data.createdAt) >= new Date(startDateFilter) && new Date(data.createdAt) <= new Date(endDateFilter))
                filterMoreData(filterDatas);
            } else if (selectedFilterOption === 'leadWonDate') {
                let filterDatas = duplicateLeadStatusData.filter(data => new Date(data.leadWonDate) >= new Date(startDateFilter) && new Date(data.leadWonDate) <= new Date(endDateFilter))
                filterMoreData(filterDatas);
            } else {
                let filterDatas = duplicateLeadStatusData.filter(data => new Date(startDate) >= new Date(startDateFilter) && new Date(endDate) <= new Date(endDateFilter))
                filterMoreData(filterDatas);
            }
        } else if (statusName === 'Won-Deals-Pending-10-') {
            if (selectedFilterOption === 'createdAt') {
                let filterDatas = duplicateLeadStatusData.filter(data => new Date(data.createdAt) >= new Date(startDateFilter) && new Date(data.createdAt) <= new Date(endDateFilter))
                filterMoreData(filterDatas);
            } else if (selectedFilterOption === 'leadWonDate') {
                let filterDatas = duplicateLeadStatusData.filter(data => new Date(data.leadWonDate) >= new Date(startDateFilter) && new Date(data.leadWonDate) <= new Date(endDateFilter))
                filterMoreData(filterDatas);
            } else {
                let filterDatas = duplicateLeadStatusData.filter(data => new Date(startDate) >= new Date(startDateFilter) && new Date(endDate) <= new Date(endDateFilter))
                filterMoreData(filterDatas);
            }
        } else if (statusName === 'Activity-For-Today') {
            setUserReminderData(props.location.state.data.activitiesForToday);
            setIsReminderStatus(true)
        } else {
            getLeadCountDetails()
        }
    }

    const filterMoreData = (filterData) => {
        let assignUserList = []
        let stageList = [];
        filterData.forEach(item => {
            stageList.push(item?.salesStage);
            assignUserList.push(item?.assignTo)
        })
        const uniqueStageList = Array.from(new Set(stageList.map(a => a)))
            .map(id => {
                return stageList.find(a => a === id)
            })

        const uniqueAssignToList = Array.from(new Set(assignUserList.map(a => a)))
            .map(id => {
                return assignUserList.find(a => a === id)
            })
        setAssignToList(uniqueAssignToList);
        setDepartmentsStage(uniqueStageList);
        setLeadStatusData(filterData)
        setDuplicateLeadStatusData(filterData);
        setCsvData(filterData);
    }

    const filterData = () => {
        const newArr = [];
        duplicateLeadStatusData.forEach(o => {
            if (selectedStage.length !== 0 && selectedAssignTo.length !== 0) {
                if (selectedStage.some(stage => stage === o.salesStage) && selectedAssignTo.some(assign => assign === o.assignTo)) {
                    newArr.push(o)
                }
            } else if (selectedStage.length !== 0 || selectedAssignTo.length !== 0) {
                if (selectedStage.some(stage => stage === o.salesStage) || selectedAssignTo.some(assign => assign === o.assignTo)) {
                    newArr.push(o)
                }
            } else {
                newArr.push(o)
            }
        });
        setLeadStatusData(newArr);
        setCsvData(newArr);
    };

    const clearFilterDate = () => {
        setStartDate('');
        setEndDate('');
        setSelectedFilterOption('')
        setSearched("")
        setAssignToList([])
        setDepartmentsStage([]);
        setSelectedAssignTo([])
        setSelectedStage([])
        getLeadsOnSelectedDateByRole();
    }

    return (
        <>
            <div style={{ marginBottom: 20 }}>
                <Paper className={classes.paper}>
                    <h3 style={{ textAlign: 'center' }}>
                        {statusName === 'Lead-Returned-By-Sales' ? <>Status - Lead Returned by Sales</> :
                            <>Status - {statusName.replace(/-/g, ' ')}</>}
                    </h3>
                </Paper>
            </div>
            <Divider />
            {
                !isRemiderStatus ?
                    <>
                        {statusName !== 'Open-Leads(Current-and-last-2-month)' &&
                            <Paper className={classes.paper}>
                                {
                                    userDetails.roles.find(role => role.name !== ROLENAME.MarketingManager && role.name !== ROLENAME.MarketingUser) &&
                                    <FormControl component="fieldset" style={{ marginBottom: 20 }}>
                                        <FormLabel component="legend">Select Filter Option</FormLabel>
                                        <RadioGroup row aria-label="filterOption" name="filterOption1" value={selectedFilterOption} onChange={handleChangeFilterOption}>
                                            <FormControlLabel value="leadWonDate" control={<Radio />} label="Lead Won Date" />
                                            <FormControlLabel value="createdAt" control={<Radio />} label="Lead Created At" />
                                            {/* {userDetails.departmentId === designDepartment && <FormControlLabel value="contractSignedDate" control={<Radio />} label="Contract Sign Date" />} */}
                                        </RadioGroup>
                                    </FormControl>
                                }
                                <br />
                                <TextField
                                    id="date"
                                    name="startDate"
                                    label="From Date"
                                    type="date"
                                    value={startDateFilter}
                                    onChange={(e) => setStartDate(e.target.value)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth
                                    className={classes.textField}
                                />
                                <TextField
                                    id="date"
                                    name="endDate"
                                    label="End Date"
                                    type="date"
                                    value={endDateFilter}
                                    onChange={(e) => setEndDate(e.target.value)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth
                                    className={classes.textField}
                                />
                                <Button variant="contained" color="primary" disabled={!startDate || !endDate} style={{ alignSelf: "center", marginLeft: 20, marginTop: 15 }} onClick={getLeadsOnSelectedDateByRole} >
                                    Submit
                                </Button>
                                <Button variant="contained" color="secondary" style={{ alignSelf: "center", marginLeft: 20, marginTop: 15 }} onClick={() => clearFilterDate()} >
                                    Clear All Filter
                                </Button>
                            </Paper>
                        }
                        {
                            statusName === 'Open-Leads(Current-and-last-2-month)' &&
                            <Paper style={{ marginTop: '20px', marginBottom: "20px", padding: "20px" }}>
                                <Grid container>
                                    <Grid item md={4} sm={6} xs={12}>
                                        <Autocomplete
                                            multiple
                                            onChange={(e, v) => setSelectedStage(v)}
                                            id="users-dropdown"
                                            options={departmentStage}
                                            value={selectedStage}
                                            getOptionLabel={(option) => option}
                                            filterSelectedOptions
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    label="Select Stage"
                                                    placeholder="Sales Stage"
                                                />
                                            )}
                                        />
                                    </Grid>

                                    {userDetails.roles.find(role => role.name !== ROLENAME.SalesUser) &&
                                        <Grid item md={4} sm={6} xs={12}>
                                            <Autocomplete
                                                multiple
                                                onChange={(e, v) => setSelectedAssignTo(v)}
                                                id="users-dropdown"
                                                options={assignToList}
                                                getOptionLabel={(option) => option}
                                                filterSelectedOptions
                                                value={selectedAssignTo}
                                                style={{ marginLeft: 10 }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        label="Select User"
                                                        placeholder="Sales User"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    }
                                    <Grid item md={2} sm={6} xs={12} style={{ margin: 'auto', textAlign: "center" }}>
                                        <Button variant="contained" color="primary" onClick={() => filterData()}>Filter Data</Button>
                                    </Grid>
                                    <Grid item md={2} sm={6} xs={12} style={{ margin: 'auto', textAlign: "center" }}>
                                        <Button onClick={downloadExcel} variant="contained" color="primary" style={{ marginTop: 2 }}>Download Excel</Button>
                                        <CSVLink
                                            headers={headers}
                                            filename={"leadsExcel" + '.csv'}
                                            data={csvData}
                                            ref={buttonRef}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                        }
                        <Paper style={{ marginTop: '20px', marginBottom: "20px", padding: "20px", textAlign: 'right' }}>
                            <TextField label="Search here.." name="searched" value={searched} size="small" onChange={setHandleSearched} variant="outlined" style={{ marginRight: 20 }} />
                            {
                                statusName !== 'Open-Leads(Current-and-last-2-month)' && <>
                                    <Button onClick={downloadExcel} variant="contained" color="primary" style={{ marginTop: 2 }}>
                                        Download Excel
                                    </Button>
                                    <CSVLink
                                        headers={headers}
                                        filename={statusName.replace(/-/g, ' ') + '.csv'}
                                        data={csvData}
                                        ref={buttonRef}
                                    />
                                </>
                            }
                        </Paper>

                        <Divider />
                        <div style={{ display: 'flex', marginTop: 10, marginBottom: 20 }}>
                            <Paper>
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} stickyHeader aria-label="sticky table">
                                        <TableHead>

                                        <TableRow>
                                                <TablePagination
                                                    rowsPerPageOptions={[200, 300, 400, { label: 'All', value: leadStatusData?.length }]}
                                                    colSpan={14}
                                                    count={leadStatusData?.length}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    SelectProps={{
                                                        inputProps: { 'aria-label': 'rows per page' },
                                                        native: true,
                                                    }}
                                                    onPageChange={handleChangePage}
                                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                                />
                                            </TableRow>

                                            <TableRow>
                                                <TableCell>Sr. No.</TableCell>
                                                <TableCell>Customer</TableCell>
                                                <TableCell>Lead No</TableCell>
                                                <TableCell>Project No.</TableCell>
                                                <TableCell>Current Stage</TableCell>
                                                <TableCell>Email</TableCell>
                                                <TableCell>Mobile</TableCell>
                                                <TableCell>Address</TableCell>
                                                <TableCell>Area</TableCell>
                                                <TableCell>Lead Type</TableCell>
                                                <TableCell>Assigned to</TableCell>
                                                <TableCell>Quotation Amount</TableCell>
                                                <TableCell>Created At</TableCell>
                                                <TableCell>Expected Closure Date</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {leadStatusData?.length !== 0 ?
                                                leadStatusData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    .sort((a, b) => b.createdAt.split('-').reverse().join().localeCompare(a.createdAt.split('-').reverse().join()))
                                                    .map((row, i) => {
                                                        return (
                                                            <TableRow key={i + 1}>
                                                                <TableCell>{i + 1}</TableCell>
                                                                <TableCell component="th" scope="row">
                                                                    <Link to={`/project-logs/${row?.leadId}`} style={{ color: "#000", textDecoration: 'underline' }}>{row?.customerName}</Link>
                                                                </TableCell>
                                                                <TableCell component="th">{row?.lead_no}</TableCell>
                                                                <TableCell component="th">{row?.erpProjectNo}</TableCell>
                                                                <TableCell component="th">{row?.currentStage}</TableCell>
                                                                <TableCell component="th">{row?.email}</TableCell>
                                                                <TableCell component="th">{row?.contact_no}</TableCell>
                                                                <TableCell component="th">{row?.address}</TableCell>
                                                                <TableCell component="th">{row?.area}</TableCell>
                                                                <TableCell component="th">{row?.leadType}</TableCell>
                                                                <TableCell component="th">{row?.assignTo}</TableCell>
                                                                <TableCell component="th">₹ {commaSeparateAmount(row?.estimatedBudget)}</TableCell>
                                                                <TableCell component="th">{row?.createdAt}</TableCell>
                                                                <TableCell component="th">{row?.expectedSalesclosureDate}</TableCell>
                                                            </TableRow>
                                                        );
                                                    })
                                                :
                                                <TableRow>
                                                    <TableCell colSpan={9} style={{ textAlign: 'center' }}>No Records Found</TableCell>
                                                </TableRow>
                                            }
                                        </TableBody>
                                        {/* <TableFooter>
                                            <TableRow>
                                                <TablePagination
                                                    rowsPerPageOptions={[200, 300, 400, { label: 'All', value: leadStatusData?.length }]}
                                                    colSpan={10}
                                                    count={leadStatusData?.length}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    SelectProps={{
                                                        inputProps: { 'aria-label': 'rows per page' },
                                                        native: true,
                                                    }}
                                                    onPageChange={handleChangePage}
                                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                                />
                                            </TableRow>
                                        </TableFooter> */}
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </div>
                    </>
                    :
                    <>
                        {reminderNotes.length !== 0 ?
                            reminderNotes.map((data, i) => (
                                <Grid item lg={6} md={6} key={i}>
                                    <Paper style={{ padding: 10 }}>
                                        <Accordion defaultExpanded className="accordian">
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography className={classes.heading}>
                                                    <LocalActivityIcon />
                                                    <span className="titlename">Deal Activity</span>
                                                    <span className="datedata">{moment(data?.createdAt).format('lll')}</span>
                                                </Typography>
                                            </AccordionSummary>
                                            <Divider />
                                            <AccordionDetails>
                                                <Typography>
                                                    <strong>Customer Name: {data?.leadId?.customerId?.name}</strong>
                                                    <br />
                                                    <strong>Message:</strong> {data?.notes}
                                                    <br />
                                                    <hr />
                                                    <strong><small>Created By:</small></strong> <small>{data?.createdBy?.name}</small>
                                                </Typography>
                                                <AttachmentIcon onClick={() => window.open(data.s3Location[0])} />
                                            </AccordionDetails>
                                        </Accordion >
                                    </Paper>
                                </Grid>
                            ))
                            :
                            <p style={{ marginTop: 20, textAlign: 'center' }}>No Reminder Found</p>
                        }
                    </>
            }
        </>
    )
}

export default connect(null, { setLoading, setSnackInfo })(ShowStatusInformation)