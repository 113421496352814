import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import { useParams, useHistory, Link } from "react-router-dom";
import { setLoading, setSnackInfo } from "../redux/actions/appAction";
import Divider from "@material-ui/core/Divider";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import HttpService from "../services/httpService";

const useStyles = makeStyles((theme) => ({
  table: {
    width: "80vw",
    textAlign: "center",
  },
  root: {
    width: "100%",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#ccc",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const DesignHoldApprovalView = (props) => {
  const classes = useStyles();
  const { userDetails } = useSelector((state) => state.user);
  const history = useHistory();
  const [allDesignHoldLeadData, setAllDesignHoldLeadData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [status, setStatus] = useState("");
  const [remark, setRemark] = useState("");
  const [projectId, setProjectId] = useState("");

  useEffect(() => {
    getAllDesignHoldLeads();
  }, []);

  const getAllDesignHoldLeads = async () => {
    props.setLoading(true);
    try {
      const response = await HttpService.getAllDesignHoldLeads();
      arrayUpdateFunction(response.data);
      // setAllDesignHoldLeadData(response.data);
    } catch (error) {
      props.setSnackInfo("Something went wrong", "error");
    }
    props.setLoading(false);
  };

  const arrayUpdateFunction = (masterArray) => {
    const newArr = masterArray.filter((row) => {
      return userDetails?.experienceCenterId?.includes(row.experienceCenterId);
    });
    setAllDesignHoldLeadData(newArr);
  };

  const updateDesignHoldApprovalStatus = async () => {
    try {
      const obj = {
        status: status,
        remark : remark
      };
      const data = await HttpService.updateDesignHoldApprovalStatus(obj,projectId);
      setOpenDialog(false);
      setRemark("");
      getAllDesignHoldLeads();
    } catch (err) {
      props.setSnackInfo("Something went wrong", "error");
    }
  };

  return (
    <div>
      <div style={{ marginBottom: 20 }}>
        <h3 style={{ textAlign: "center" }}>
          {" "}
          Design-Hold Approval Dashboard{" "}
        </h3>
      </div>
      <Divider />
      <Paper>
        <TableContainer component={Paper}>
          <Table
            className={classes.table}
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead>
              <TableRow style={{ backgroundColor: "#333", color: "#fff" }}>
                <TableCell>Sl. No</TableCell>
                <TableCell>Customer Name</TableCell>
                <TableCell>Project No.</TableCell>
                <TableCell>Lead No.</TableCell>
                <TableCell>Design Hold Reason</TableCell>
                <TableCell>Remarks</TableCell>
                <TableCell>Probable Date for Restarting the Designing</TableCell>
                <TableCell>Probable Date of Design Sign-off</TableCell>
                <TableCell style={{textAlign : 'center'}}>Action</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {allDesignHoldLeadData &&
                allDesignHoldLeadData?.map((item, index) => {
                  return (
                    <TableRow>
                      <TableCell component="th">{index + 1}</TableCell>
                      <TableCell component="th">
                        {item?.customerId?.name}
                      </TableCell>
                      <TableCell component="th">{item?.erpProjectNo}</TableCell>
                      <TableCell>
                        <Link
                          to={`/project-logs/${item._id}`}
                          style={{ color: "#000", textDecoration: "underline" }}
                        >
                          {item?.lead_no}
                        </Link>{" "}
                      </TableCell>
                      <TableCell component="th">
                        {item?.designHoldSelectedTypes}    {/*design-hold selected reason */}
                      </TableCell>
                      <TableCell component="th">
                        {item?.designHoldReason}            {/*design-hold remarks */}
                      </TableCell>
                      <TableCell component="th">{moment(item?.proposedDesignStartDate).format('DD-MM-YYYY')}</TableCell>
                      <TableCell component="th">{moment(item?.probableDesignSignOffDate).format('DD-MM-YYYY')}</TableCell>
                      <TableCell component="th">
                        <Grid style={{display:'flex', gap:5}}>
                        <Button
                          color="primary"
                          variant="outlined"
                          onClick={() => {
                            setOpenDialog(true);
                            setStatus("Approved");
                            setProjectId(item?._id)
                          }}
                        >
                          Approve
                        </Button>
                        <Button
                          color="secondary"
                          variant="outlined"
                          onClick={() => {
                            setOpenDialog(true);
                            setStatus("Rejected");
                            setProjectId(item?._id)
                          }}
                        >
                          Reject
                        </Button>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <div>
        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
          <DialogTitle>Design-Hold Approval Pending</DialogTitle>
          <div style={{ padding: "16px" }}>
            <TextField
              id="outlined-basic"
              label="Remark"
              variant="outlined"
              value={remark}
              onChange={(e) => setRemark(e.target.value)}
              fullWidth
            />
          </div>
          <DialogActions>
            <Button
              onClick={() => setOpenDialog(false)}
              variant="outlined"
              color="primary"
            >
              CANCEL
            </Button>
            <Button
              onClick={() => updateDesignHoldApprovalStatus()}
              variant="outlined"
              color="secondary"
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default connect(null, { setLoading, setSnackInfo })(
  DesignHoldApprovalView
);
