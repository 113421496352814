import React, { useState, useEffect, useRef} from "react";
import { connect } from "react-redux";
import { useSelector } from "react-redux";
import { setLoading, setSnackInfo } from "../redux/actions/appAction";
import HttpService from "../services/httpService";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import moment from "moment";
import { CSVLink } from "react-csv";
import Grid from "@material-ui/core/Grid";
import {
  Button,
  FormControl,
  MenuItem,
  TextField,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import "../App.css";
import { virtualPaymentId } from "../utils/index";
import {
  TablePagination,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#ccc",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const HdfcVirtualPayment = (props) => {
  const classes = useStyles();
  const buttonRef = useRef(null);
  const { userDetails } = useSelector((state) => state.user);
  const [virtualPayment,setVirtualPayment] = useState([])
  const [paymentModes, setSelectedPaymentMode] = useState([]);
  const [datefilter, setDatefilter] = useState({ startDate: "", endDate: "" });
  const [searchQuery, setSearchQuery] = useState("");
  const [downloadExcels,setDownloadExcel] = useState([])
  const [anotherDialog, setOpenAnotherDialog] = useState(false);
  const [storedInfo, setStoredInfo] = useState({
    paymentMode: "",
    attachment: null,
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(0); 

  useEffect(() => {
    paymentModesPineLabs();
    getData();
  }, []);

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setDatefilter({ ...datefilter, [name]: value });
  };
  const clearDateFilter = () => {
    datefilter.startDate = "";
    datefilter.endDate = "";
    setSearchQuery("")
    getData();
  };
  const setHandleSearch = (e) =>{
    setSearchQuery(e.target.value)
  }
  const setHandleSearchedByEnter = (e) =>{
   if(e.keyCode === 13){
    getData(searchQuery)
   }
  }

  const paymentModesPineLabs = async () => {
    try {
      const response = await HttpService.getpaymentPatners();
      setSelectedPaymentMode(response.data);
    } catch (error) {
      props.setSnackInfo(
        error?.response?.data ?? "Something went wrong",
        "error"
      );
    }
  };

  const getData = async (searchQuery) => {
    try {
      let obj = {
        startDate: datefilter.startDate,
        endDate: datefilter.endDate,
        searchQuery : searchQuery ? searchQuery : ''
      };
      const response = await HttpService.getVirtualPaymentsData(obj);
      let completestore = [];
      props.setLoading(true);
      response.data.forEach((el) => {
        const obj = {
          customerName: el?.leadId?.customerId?.name ? el?.leadId?.customerId?.name : "NA",
          leadNo : el?.leadId?.lead_no ? el?.leadId?.lead_no : "NA",
          clientCode : el?.clientCode ? el?.clientCode : "NA",
          clientName : el?.clientName ? el?.clientName : "NA",
          remitterAccount: el?.remitterAccount ? el?.remitterAccount : "NA",
          referenceNo: el?.referenceNo ?  el?.referenceNo : "NA",
          remitterBank: el?.remitterBank ? el?.remitterBank : "NA",
          remitterName: el?.remitterName ? el?.remitterName : "NA",
          amount : el?.amount ? el?.amount : "NA",
          csvUploadedUrl : el?.csvUploadedUrl ? el?.csvUploadedUrl : "NA",
          createdBy : el?.createdBy?.name ? el?.createdBy?.name  : "NA",
          postingdate : el?.postingdate ? el?.postingdate : "NA",
          createdAt :  el?.createdAt ? moment(el?.createdAt).format("DD-MM-YYYY") : "NA"
        }
        completestore.push(obj)
      })
      setData(completestore);
      setTotalPages(Math.ceil(response.data.length / rowsPerPage)); 
    } catch (error) {
      props.setSnackInfo(
        error?.response?.data ?? "Something went wrong",
        "error"
      );
    }
    props.setLoading(false);
  };
  const filterDateRangeData = () => {
    getData();
  };

  const onImageChange = async (e) => {
    props.setLoading(true);
    const fd = new FormData();
    fd.append("file", e.target.files[0]);

    try {
      const fileUploadRes = await HttpService.uploadFile(fd);
      setStoredInfo({
        ...storedInfo,
        attachment: fileUploadRes.data, // Store the file information in the state
      });
    } catch (err) {
      props.setSnackInfo(
        err?.response?.data ?? "Something went wrong",
        "error"
      );
    }
    props.setLoading(false);
  };

  const handleOpen = () => {
    setOpenAnotherDialog(true);
  };

  const handleSubmit = async () => {
    let obj = {
      url: storedInfo.attachment,
      createdBy: userDetails._id,
      updatedBy: userDetails._id,
      paymentPatnerId: virtualPaymentId,
    };
    try {
      await HttpService.UploadVirtualPayments(obj);
      props.setSnackInfo("File Uploded SuccessFully");
      storedInfo.attachment = null;
      getData();
      setOpenAnotherDialog(false);
    } catch (error) {
      props.setSnackInfo("File Not Uploded", "error");
      console.log(error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClose = () => {
    setOpenAnotherDialog(false);
  };
  const downloadExcel = () => {
    props.setLoading(true)
    try {
        setTimeout(() => {
            buttonRef.current.link.click();
        });
    } catch (err) {
        console.error(err.message);
    }
    props.setLoading(false)
}


var slicedData = data.slice(
  page * rowsPerPage,
  page * rowsPerPage + rowsPerPage
); 
const headers = [
  { key: "customerName", label: "Lead Id" },
  { key: "leadNo", label: "Lead No" },
  { key: "clientCode", label: "Client Code" },
  { key: "clientName", label: "Client Name" },
  { key: "remitterAccount", label: "Remitter Account" },
  { key: "remitterBank", label: "Remitter Bank" },
  { key: "remitterName", label: "Remitter Name" },
  { key: "amount", label: "Amount" },
  { key: "createdBy", label: "Created By" },
  { key: "postingdate", label: "Posting Date" },
  { key: "createdAt", label: "created Date" },


]

  return (
    <>
      <Paper>
        <Grid
          container
          style={{
            display: "flex",
            alignItems: "center",
            padding: "0rem 0rem 2rem 2rem",
          }}
        >
          <Grid item md={3} xs={12}>
          <TextField    
            placeholder='Search Project'
            type="text"
            label="Search Project"
            variant="outlined"
            onKeyDown={setHandleSearchedByEnter}
            onChange={setHandleSearch}
            value={searchQuery}
            InputLabelProps={{
                shrink: true,
            }}
            fullWidth
            className={classes.textFields}
        />
          </Grid>
          <Grid item md={4} xs={12}>
            <Button
            onClick={downloadExcel}
             style={{padding:"1rem 2rem 1rem 3rem", marginTop:"5px", marginLeft:"20px", background:"green", color:"white"}}>
            Download Excel
            </Button>
                <CSVLink
                    headers={headers}
                    data={slicedData}
                    ref={buttonRef}
                />
          </Grid>
          <Grid item md={5} xs={12} style={{ textAlign: "right" }}>
            {/* <Button
              variant="contained"
              color="primary"
              onClick={handleOpen}
              style={{
                padding: "1rem 4rem 1rem 4rem",
                marginTop: "2rem",
                marginBottom: "2rem",
                marginRight: "1rem",
              }}
            >
              Upload
            </Button> */}
          </Grid>
        </Grid>

        <Grid
          container
          style={{
            display: "flex",
            alignItems: "center",
            padding: "0rem 0rem 2rem 3rem",
          }}
        >
          <Grid item md={3} xs={12}>
            <TextField
              id="startDate"
              name="startDate"
              label="From Date"
              type="date"
              value={datefilter.startDate}
              onChange={handleDateChange}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              className={classes.textField}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              id="endDate"
              name="endDate"
              label="To Date"
              type="date"
              value={datefilter.endDate}
              onChange={handleDateChange}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              className={classes.textField}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={filterDateRangeData}
              disabled={datefilter.startDate == "" || datefilter.endDate == ""}
            >
              Submit
            </Button>
          </Grid>
          <Grid item md={3} xs={12}>
            <Button
              variant="contained"
              color="secondary"
              onClick={clearDateFilter}
            >
              Clear Filter
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <Dialog
        open={anotherDialog}
        onClose={() => setOpenAnotherDialog(false)}
        aria-labelledby="form-dialog-title"
        maxWidth={"lg"}
        style={{ padding: "0rem 0rem 5rem 0rem" }}
      >
        <DialogContent style={{ margin: "auto" }}>
          <h2>Upload Virtual Payment File</h2>
        </DialogContent>
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <FormControl
              fullWidth
              margin="normal"
              style={{ padding: "0rem 0rem 0rem 0rem" }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <input
                  accept="*"
                  id="contained-button-file"
                  type="file"
                  onChange={onImageChange}
                  style={{ display: "none" }}
                />
                <label htmlFor="contained-button-file">
                  <Button
                    variant="contained"
                    component="span"
                    style={{ padding: "0.6rem 2rem" }}
                  >
                    Upload File
                  </Button>
                </label>
                {storedInfo.attachment && (
                  <CheckCircleIcon style={{ color: "green" }} />
                )}
              </div>
            </FormControl>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "1rem",
              }}
            >
              <div style={{ padding: "2rem 2rem 0rem 3.5rem" }}>
                <Button
                  variant="contained"
                  color="secondary"
                  style={{ padding: "0.6rem 2rem" }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </div>
              <div style={{ padding: "2rem" }}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ padding: "0.6rem 2rem" }}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </>
      </Dialog>
      <Paper>
        <TableContainer>
          <Table
            className={classes.table}
            style={{ padding: "1rem 2rem 1rem 2rem" }}
            size="small"
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[20, 50, 100, 250, 500]}
                  count={data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Sl No</StyledTableCell>
                  <StyledTableCell colSpan={2}>Customer Name</StyledTableCell>
                  <StyledTableCell>Lead Id</StyledTableCell>
                  <StyledTableCell colSpan={5}>Client Name</StyledTableCell>
                  <StyledTableCell>Client Code</StyledTableCell>
                  <StyledTableCell>Remitter Account</StyledTableCell>
                  <StyledTableCell>Reference No</StyledTableCell>
                  <StyledTableCell>Remitter Bank</StyledTableCell>
                  <StyledTableCell>Remitter Name</StyledTableCell>
                  <StyledTableCell>Amount</StyledTableCell>
                  <StyledTableCell>Download</StyledTableCell>
                  <StyledTableCell>Created By</StyledTableCell>
                  <StyledTableCell>Posting Date</StyledTableCell>
                  <StyledTableCell>Created At</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {slicedData.map((val, i) => (
                  <StyledTableRow key={i + 1}>
                    <StyledTableCell>{i + 1}</StyledTableCell>
                    <StyledTableCell colSpan={2}>
                      {val?.customerName}
                    </StyledTableCell>
                    <StyledTableCell>{val?.leadNo}</StyledTableCell>
                    <StyledTableCell colSpan={5}>{val?.clientName}</StyledTableCell>
                    <StyledTableCell>{val?.clientCode}</StyledTableCell>
                    <StyledTableCell>{val?.remitterAccount}</StyledTableCell>
                    <StyledTableCell>{val?.referenceNo}</StyledTableCell>
                    <StyledTableCell>{val?.remitterBank}</StyledTableCell>
                    <StyledTableCell>{val?.remitterName}</StyledTableCell>
                    <StyledTableCell>{val?.amount}</StyledTableCell>
                    <StyledTableCell>
                      <Button color="primary" variant="outlined" onClick={() => window.open(val?.csvUploadedUrl)} style={{ marginLeft: 10 }} disabled={!val?.csvUploadedUrl}> Click</Button>
                    </StyledTableCell>
                    <StyledTableCell>{val?.createdBy}</StyledTableCell>
                    <StyledTableCell>{val?.postingdate}</StyledTableCell>
                    <StyledTableCell>{moment(val?.createdAt).format("DD-MM-YYYY")}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>

            </TableHead>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default connect(null, { setLoading, setSnackInfo })(HdfcVirtualPayment);
