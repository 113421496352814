import React, { useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import { Card, CardContent, Container, Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import { setCustomerDetails } from '../../redux/actions/customerAction';
import HttpService from '../../services/httpService';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import { setSnackInfo } from '../../redux/actions/appAction';
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  avatarContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
  iconButton: {
    position: 'relative',
    top: theme.spacing(-5),
    color: "#fff"
  },
  input: {
    display: 'none',
  },
}));

const Profile = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { customerDetails } = useSelector((state) => state.customer);
  const [open, setOpen] = useState(false);
  const [newImage, setNewImage] = useState(null);
  const [passwords, setPasswords] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });



  useEffect(() => {
    const updateImage = async () => {
      try {
        if (newImage) {
          // Dispatch the action to set customer details with the updated image link
          await dispatch(setCustomerDetails({ ...customerDetails, customerImage: newImage }));
          // Reset the newImage state after successful upload
          setNewImage(null);
        }
      } catch (error) {
        console.error('Error updating image:', error);
        // Handle errors, show snack, etc.
      }
    };

    updateImage();
  }, [newImage, dispatch, customerDetails]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setPasswords({
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
  };

  const handleChange = (field) => (event) => {
    setPasswords({ ...passwords, [field]: event.target.value });
  };



  const handleImageChange = async (e) => {
    const fd = new FormData();
    fd.append('file', e.target.files[0]);
    fd.append('customerName', customerDetails.name);
  
    try {
      const fileUploadRes = await HttpService.uploadFile(fd);
      const uploadedImage = fileUploadRes.data;
  
      // Check if the image upload was successful before updating Redux store
      if (uploadedImage) {
        // Dispatch the action to set customer details with the updated image link
        dispatch(setCustomerDetails({ ...customerDetails, customerImage: uploadedImage }));
      } else {
        // Handle the case where the image upload was not successful
        console.error('Error uploading image. Please try again.');
      }
    } catch (error) {
      console.error('Error uploading image:', error);
      // Handle errors, show snack, etc.
    }
  };

  // forgot password

  const handleChangePassword = async () => {
    // Validate passwords and make API call to change password
    // Example validation: Ensure new password and confirm password match
    if (passwords.newPassword !== passwords.confirmPassword) {
      // Handle password mismatch error
      props.setSnackInfo("Password Not Match", "error");
      return;
    }
  
    try {
      // Example API call to change password
      await changeCustomerPassword(passwords.currentPassword, passwords.newPassword);
      // Password changed successfully
      props.setSnackInfo("Password changed successfully", "success");
      handleClose(); // Close the dialog
    } catch (error) {
      // Handle API error
      props.setSnackInfo("Error changing password", "error");
    }
  };
  
  const changeCustomerPassword = async (currentPassword, newPassword) => {
    try {
      let obj = {
        currentPassword: currentPassword,
        newPassword: newPassword,
        email: customerDetails.email,
      };
      let res = await HttpService.changePasswords(obj);
    } catch (error) {
      // If you want to handle specific errors, you can do it here
      console.error("Error in API call:", error);
      throw error; // Rethrow the error for the higher-level catch to handle
    }
  };
  
    
  

  return (
    <Container maxWidth="md" style={{ marginTop: '7rem', marginBottom:"8rem" }}>
      <Card>
        <CardContent>
          <Grid container className={classes.avatarContainer}>
            <Avatar
              alt={customerDetails.name ? customerDetails.name.substring(0, 1) : ""}
              src={customerDetails?.customerImage}
              className={classes.avatar}
            />
            <input
              accept="image/*"
              className={classes.input}
              id="icon-button-file"
              type="file"
              onChange={handleImageChange}
            />
            <label htmlFor="icon-button-file">
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
                className={classes.iconButton}
              >
                <PhotoCameraIcon />
              </IconButton>
            </label>
          </Grid>

          <TextField variant="outlined" disabled fullWidth label="Name" value={customerDetails.name} className={classes.textField} />
          <TextField variant="outlined" disabled fullWidth label="Email" value={customerDetails.email} className={classes.textField} />
          <TextField variant="outlined" disabled fullWidth label="Address" value={customerDetails.address} className={classes.textField} />
       
          <Button 
  variant="contained" 
  color="primary" 
  onClick={handleOpen}
  style={{ color: 'white' }} // Set font color to white
>
  Change Password
</Button>

             </CardContent>
     
     {/* //forgot password */}
     <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Change Password</DialogTitle>
            <DialogContent>
              <TextField
                label="Current Password"
                type="password"
                value={passwords.currentPassword}
                onChange={handleChange("currentPassword")}
                fullWidth
                margin="dense"
              />
              <TextField
                label="New Password"
                type="password"
                value={passwords.newPassword}
                onChange={handleChange("newPassword")}
                fullWidth
                margin="dense"
              />
              <TextField
                label="Confirm Password"
                type="password"
                value={passwords.confirmPassword}
                onChange={handleChange("confirmPassword")}
                fullWidth
                margin="dense"
              />
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={handleChangePassword}>
                Submit
              </Button>
            </DialogActions>
          </Dialog>


      </Card>
    </Container>
  );
};

export default connect(null, { setCustomerDetails, setSnackInfo })(Profile);