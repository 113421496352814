import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Modal } from 'antd';
// import 'antd/dist/antd.css';
import { useRef } from 'react';
import './index.css'
import { ADMINHEADER, SCOPEDPRODUCTSHEADER , EM_TABLEHEADER, ADMINHEADERMULTSELECTNEW, NONADMINHEADERMULTSELECTNEW, ROLENAME} from '../../../utils'
import HttpService from '../../../services/httpService';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { connect, useSelector } from "react-redux";
import { setLoading, setSnackInfo } from '../../../redux/actions/appAction';
import { getApprovedProjects } from '../../../redux/actions/financeAction'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Radio, RadioGroup, FormControlLabel, FormControl, Button, Grid } from '@material-ui/core';
import { CSVLink } from "react-csv";
import moment from 'moment'

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
    tableContainer: {
        marginBottom: '2.5rem',
    },
    root: {
        width: '100%',
    },
    tableRow:{
        backgroundColor: 'rgba(100, 100, 100)'
    },
    autoComplete:{
        marginBottom: '1.5rem',
    }
});

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#3F51B5",
        color: '#FFF',
        fontWeight: '500'
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const Index = (props) => {

    const classes = useStyles();
    const { approvedProjects } = useSelector(state => state.finance)
    const { userDetails } = useSelector(state => state.user)
    const buttonRef = useRef(null)
    const [summedSelectedProjectsScopedData, setSummedSelectedProjectsScopedData] = useState({Carpentry: {areaOrQty:0, materialRequested: 0, totalDecorpotPrice:0, poRequested:0, soRequested:0}, Procurement_Others: {areaOrQty:0, materialRequested: 0, totalDecorpotPrice:0, poRequested:0, soRequested:0}, Modular:{areaOrQty:0, materialRequested: 0, totalDecorpotPrice:0, poRequested:0, soRequested:0}, Services:{areaOrQty:0, materialRequested: 0, totalDecorpotPrice:0, poRequested:0, soRequested:0}, Hardware:{areaOrQty:0, materialRequested: 0, totalDecorpotPrice:0, poRequested:0, soRequested:0}})
    const [scopeModal, setScopeModal] = useState(false)
    const [scopedProducts, setScopedProducts] = useState([])
    const [scopeDisplay, setScopeDisplay] = useState("")
    const [newState,setNewState] = useState([])
    const [grandTotal,setGrandTotal] = useState({})
    const [projectNamesForCsv, setProjectNamesForCsv] = useState([]);
    const ScopeList = ['Carpentry', 'Procurement_Others', 'Modular', 'Services', 'Hardware'];
    const fieldNames = ['Areamm', 'AreaSqFt', 'Qty', 'materialRequested', 'totalDecorpotPrice', 'poRequested', 'soRequested'];

    useEffect(() => {
        getApprovedProjects()
        // eslint-disable-next-line
    }, [])


    const openScopeModal = async (scope) => {
     try {
         props.setLoading(true)
         setScopeDisplay(scope)
         setScopeModal(true)                            //visibility of modal
         props.setLoading(false)
     } catch (error) {
         console.log(error)
         props.setLoading(false)
         props.setSnackInfo("Couldn't fetch product details", "error")
     }
    }

    

    const changeHandler = async (event,values) =>{
        props.setLoading(true)
        setProjectNamesForCsv(values.map((el)=>el?.searchName))
        let response;
        let newData;
        let newData2;
        if(values.length > newState.length)
        {
        response = await HttpService.getProjectsScopedData(values[values.length-1]._id);
        newData = [...newState,response.data];
        setNewState(newData)
        
        const responses = await Promise.all(
            ScopeList.map(scopeName => HttpService.getProjectScopedProducts(values[values.length-1]._id, scopeName))
        );
        const datax = responses.map((responsei,i) => { const scopeData = {};
        scopeData[ScopeList[i]] = responsei.data;
        return scopeData;})

        const objectOfObjects = Object.fromEntries(
            datax.map(item => [Object.keys(item)[0], Object.values(item)[0]])
          );

        const newDatax = [...scopedProducts, {clientName:values[values.length-1].clientName, projectId:values[values.length-1]._id, data: objectOfObjects}]
        setScopedProducts(newDatax)
        }
        else{
            newData = newState.filter((project)=>{return values.find((value)=> value._id === project.projectId)})
            newData2 = scopedProducts.filter((project)=>{return values.find((value)=> value._id === project.projectId)})
            setNewState(newData)
            setScopedProducts(newData2)
        }

        let ProjectDetails = {Carpentry: {areaOrQty:0, materialRequested: 0, totalDecorpotPrice:0, poRequested:0, soRequested:0, Areamm:0, AreaSqFt:0, Qty:0}, Procurement_Others: {areaOrQty:0, materialRequested: 0, totalDecorpotPrice:0, poRequested:0, soRequested:0, Areamm:0, AreaSqFt:0, Qty:0}, Modular:{areaOrQty:0, materialRequested: 0, totalDecorpotPrice:0, poRequested:0, soRequested:0, Areamm:0, AreaSqFt:0, Qty:0}, Services:{areaOrQty:0, materialRequested: 0, totalDecorpotPrice:0, poRequested:0, soRequested:0, Areamm:0, AreaSqFt:0, Qty:0}, Hardware:{areaOrQty:0, materialRequested: 0, totalDecorpotPrice:0, poRequested:0, soRequested:0, Areamm:0, AreaSqFt:0, Qty:0}}
        newData.forEach((iproject)=>{
            for(const itype of iproject.result){  //Accessing object for each type from result array of types
                const currenttype = itype?.type
                ProjectDetails[currenttype].areaOrQty += itype?.area ? (+itype?.area || 0) : (+itype?.quantity || 0);
                ProjectDetails[currenttype].Areamm += itype.unit == 'mm' ? (+itype.area ?? 0) : 0;
                ProjectDetails[currenttype].AreaSqFt += itype.unit == 'SqFt' ? (+itype.area ?? 0) : 0;
                ProjectDetails[currenttype].Qty += +itype.quantity ?? 0;
                ProjectDetails[currenttype].totalDecorpotPrice += +itype?.totalDecorpotPrice || 0;
                ProjectDetails[currenttype].poRequested += +itype?.poRequested || 0
                ProjectDetails[currenttype].soRequested += +itype?.soRequested || 0
                ProjectDetails[currenttype].materialRequested += +itype?.materialRequested || 0
            }
        })
        setSummedSelectedProjectsScopedData(ProjectDetails);

        for(const field of fieldNames){
            const newObj ={};   
            let sum = 0;
            for(const scope of ScopeList){
                sum += ProjectDetails[scope][field]
            }
            newObj[field]=sum;
            setGrandTotal(grandTotal => ({...grandTotal, ...newObj}))
        }
        props.setLoading(false)
        }
        const headers = userDetails?.roles?.find(role => role.name === ROLENAME.Admin || role.name === ROLENAME.OperationHead) ? [
            { key: "srNo", label: "Sr. No" },
            { key: "scope", label: "Scope" },
            // { key: "unit", label: "Unit" },
            // { key: "qtyOrArea", label : "QTY/Area"},
            { key: "Areamm", label : "Area(mm)"},
            { key: "AreaSqFt", label : "Area(SqFt)"},
            { key: "Qty", label : "QTY"},
            { key: "totalPrice", label : "Total Price"},
            { key: "POorMTRRequested", label: "PO/MTR Requested" },
            { key: "SORequested", label: "SO Requested" },
            { key: "OverallCost", label: "Overall Cost"},
            { key: "Margin", label: "Margin %"},
            // { key: "selectedProjects", label: "Selected Projects"}
           
        ] : [
            { key: "srNo", label: "Sr. No" },
            { key: "scope", label: "Scope" },
            // { key: "unit", label: "Unit" },
            // { key: "qtyOrArea", label : "QTY/Area"},
            { key: "Areamm", label : "Area(mm)"},
            { key: "AreaSqFt", label : "Area(SqFt)"},
            { key: "Qty", label : "QTY"},
            { key: "POorMTRRequested", label: "PO/MTR Requested" },
            { key: "SORequested", label: "SO Requested" }
            // { key: "selectedProjects", label: "Selected Projects"}
           
        ]
        const [csvData, setCsvData] = useState([])
    
        const downloadCsv = async () => {
            try {
                const finalArr = []
                Object.entries(summedSelectedProjectsScopedData).forEach(([category, data], i) =>{
                    const newObj = {}
                    newObj.srNo = i+1;
                    newObj.scope = category;
                    // newObj.unit = 'mm';
                    newObj.Areamm = data?.Areamm?.toFixed(2);
                    newObj.AreaSqFt = data?.AreaSqFt?.toFixed(2);
                    newObj.Qty = data?.Qty?.toFixed(2);
                    newObj.totalPrice = data?.totalDecorpotPrice?.toFixed(2);
                    newObj.POorMTRRequested = data?.poRequested?.toFixed(2);
                    newObj.SORequested = data?.soRequested?.toFixed(2);
                    newObj.OverallCost = (data?.poRequested + data?.soRequested).toFixed(2);
                    newObj.Margin = data?.totalDecorpotPrice ? (((data?.totalDecorpotPrice - (data?.poRequested + data?.soRequested)) / data?.totalDecorpotPrice)*100).toFixed(2) : 0;
                    finalArr.push(newObj)
                })
                const totalObj = {
                    srNo:'Total',
                    Areamm: (grandTotal?.Areamm || 0).toFixed(2),
                    AreaSqFt: (grandTotal?.AreaSqFt || 0).toFixed(2),
                    Qty: (grandTotal?.Qty || 0).toFixed(2),
                    totalPrice: (grandTotal?.totalDecorpotPrice || 0).toFixed(2),
                    POorMTRRequested: (grandTotal?.poRequested || 0).toFixed(2),
                    SORequested: (grandTotal?.soRequested || 0).toFixed(2),
                    OverallCost: ((grandTotal?.poRequested+grandTotal?.soRequested) || 0).toFixed(2),
                    Margin: ((((grandTotal?.totalDecorpotPrice - (grandTotal?.poRequested+grandTotal?.soRequested)) / grandTotal?.totalDecorpotPrice) * 100) || 0).toFixed(2),

                }
                finalArr.push(totalObj)


                const selectedProjects = projectNamesForCsv.map((el,i) => 
                ({
                    srNo:i+1,
                    scope: el 
                })
                )
                const tempHeader = {
                    scope: 'SELECTED PROJECTS',
                }
                
                finalArr.push({})
                finalArr.push(tempHeader)
                finalArr.push(...selectedProjects)

                setCsvData(finalArr);
                setTimeout(() => {
                    buttonRef.current.link.click();
                });
            } catch (err) {
                console.log(err.message);
            }
            props.setLoading(false)
        } 


    return (
        <>

        <div style={{ display: 'flex', justifyContent: "space-between", paddingBottom: 20 }}>
        <Autocomplete
            multiple
            onChange={changeHandler}
            id="project-code-dropdown"
            options={approvedProjects}
            getOptionLabel={(option) => option.searchName}
            style={{ width: 500 }}
            renderInput={(params) => <TextField {...params} label="Search Project" fullWidth variant="outlined" />}
            className = {classes.autoComplete}
        />
        <CSVLink
        headers={headers}
        filename={`CompanyP&L-Multiple projects-${moment().format('DD-MM-YYYY')}` + '.csv'}
        data={csvData}
        ref={buttonRef}
        />
        <Button  variant="contained" color="primary" style={{ background: "#24803d", margin: 5, width: 200, height: 50 }} onClick={downloadCsv}>Download Excel</Button>
        </div>



            <TableContainer component={Paper}>
                        <Table className={classes.table} size="small" aria-label="customized table">
                            <TableHead>

                            {
                            userDetails?.roles?.find(role => role.name === ROLENAME.Admin || role.name === ROLENAME.OperationHead) ?
                            <TableRow className={classes.tableRow}>{ADMINHEADERMULTSELECTNEW.map(header => <StyledTableCell>{header}</StyledTableCell>)}</TableRow>
                                :
                            <TableRow>{NONADMINHEADERMULTSELECTNEW.map(header => <StyledTableCell>{header}</StyledTableCell>)}</TableRow>
                            }

                            </TableHead>

                            <TableBody>
                                {
                                    Object.entries(summedSelectedProjectsScopedData).map(([category, data], i) => {
                                        return (
                                            <StyledTableRow key={i + 1}>
                                                <StyledTableCell>{i + 1}</StyledTableCell>
                                                <StyledTableCell onClick={() => openScopeModal(category)} style={{ cursor: 'pointer', color: "blue", textDecoration: 'underline' }}>{category}</StyledTableCell>
                                                {userDetails?.roles?.find(role => role.name !== "Execution Manager") &&
                                                 <>
                                                    {/* <StyledTableCell>mm</StyledTableCell> */}
                                                    {/* <StyledTableCell>{data?.areaOrQty?.toFixed(2)}</StyledTableCell> */}
                                                    <StyledTableCell>{data?.Areamm?.toFixed(2) ?? 0}</StyledTableCell>
                                                    <StyledTableCell className={data?.AreaSqFt>0 ?? classes.invisible}>{data?.AreaSqFt?.toFixed(2) ?? 0}</StyledTableCell>
                                                    <StyledTableCell>{data?.Qty?.toFixed(2) ?? 0}</StyledTableCell>
                                                    {userDetails?.roles?.find(role => role.name === ROLENAME.Admin || role.name === ROLENAME.OperationHead) && <> 
                                                    <StyledTableCell>{data?.totalDecorpotPrice?.toFixed(2)}</StyledTableCell></>} </>}
                                                <StyledTableCell>{data?.poRequested?.toFixed(2)}</StyledTableCell>
                                                <StyledTableCell>{data?.soRequested?.toFixed(2)}</StyledTableCell>
                                                {userDetails?.roles?.find(role => role.name === ROLENAME.Admin || role.name === ROLENAME.OperationHead) && <>
                                                    <StyledTableCell>{(data?.poRequested + data?.soRequested).toFixed(2)}</StyledTableCell>
                                                    <StyledTableCell>{data?.totalDecorpotPrice ? (((data?.totalDecorpotPrice - (data?.poRequested + data?.soRequested)) / data?.totalDecorpotPrice)*100).toFixed(2) : 0}</StyledTableCell>
                                                </>}
                                            </StyledTableRow>
                                        )
                                    })
                                }
                                <StyledTableRow key={'total'}>
                                    <StyledTableCell style={{fontWeight:'800'}}>Total</StyledTableCell>
                                    <StyledTableCell></StyledTableCell>
                                    {userDetails?.roles?.find(role => role.name !== "Execution Manager") && <>
                                    <StyledTableCell>{(grandTotal?.Areamm || 0).toFixed(2)}</StyledTableCell>
                                    <StyledTableCell>{(grandTotal?.AreaSqFt || 0).toFixed(2)}</StyledTableCell>
                                    <StyledTableCell>{(grandTotal?.Qty || 0).toFixed(2)}</StyledTableCell>
                                    {userDetails?.roles?.find(role => role.name === ROLENAME.Admin || role.name === ROLENAME.OperationHead) && <>
                                    <StyledTableCell>{(grandTotal?.totalDecorpotPrice || 0).toFixed(2)}</StyledTableCell></>} </>}
                                    <StyledTableCell>{(grandTotal?.poRequested || 0).toFixed(2)}</StyledTableCell>
                                    <StyledTableCell>{(grandTotal?.soRequested || 0).toFixed(2)}</StyledTableCell>
                                    {userDetails?.roles?.find(role => role.name === ROLENAME.Admin || role.name === ROLENAME.OperationHead) && <>
                                    <StyledTableCell>{((grandTotal?.poRequested+grandTotal?.soRequested) || 0).toFixed(2)}</StyledTableCell>
                                    <StyledTableCell>{((((grandTotal?.totalDecorpotPrice - (grandTotal?.poRequested+grandTotal?.soRequested)) / grandTotal?.totalDecorpotPrice) * 100) || 0).toFixed(2)}%</StyledTableCell></>}
                                </StyledTableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>


            <Modal
                title="Scope Materials"
                centered
                visible={scopeModal}
                onCancel={() => setScopeModal(false)}
                width={1000}
                footer={null}
            >
                {scopedProducts?.length>0 && scopedProducts.map((scopei)=>{ 
                    return <>
                <div>
                    <div className='modal-header'>
                        <div>
                            <b>Client Name: </b>
                            <span>{scopei?.clientName}</span>
                        </div>
                        <div>
                            <b>Scope: </b>
                            <span>{scopeDisplay}</span>
                        </div>
                    </div>
                    {scopei.data[scopeDisplay]?.length > 0 && <div>
                        <TableContainer component={Paper} className={classes.tableContainer}>
                            <Table className={classes.table} size="small" aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        {SCOPEDPRODUCTSHEADER.map(header => <StyledTableCell>{header}</StyledTableCell>)}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {scopei?.data[scopeDisplay]?.map((data, i) => (
                                        <StyledTableRow key={i + 1}>
                                            <StyledTableCell>{i + 1}</StyledTableCell>
                                            <StyledTableCell>{data?.productId?.name}</StyledTableCell>
                                            <StyledTableCell>{(data?.area !== 0 && data?.area !== undefined) ? `${data?.area} (Area)` : `${data?.quantity} (Qty)`}</StyledTableCell>
                                            <StyledTableCell>{data?.pricePerUnit}</StyledTableCell>
                                            <StyledTableCell>{data?.totalPrice}</StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    }
                </div>
                </>
                
                })
}
            </Modal>

        </>
    )
}

export default connect(null, { setLoading, getApprovedProjects, setSnackInfo })(Index);
