import React, { useEffect, useState } from 'react'
import HttpService from '../../services/httpService'
import { connect } from 'react-redux'
import { setLoading, setSnackInfo } from '../../redux/actions/appAction'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import { Modal } from 'antd';
// import 'antd/dist/antd.css';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import ProjectDocumentTable from '../../components/projectDocumentTable'

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
  },
  root: {
    minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#ccc",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FinanceApprove = (props) => {
  const classes = useStyles();

  useEffect(() => {
    getAllProjectForApproval()
    // eslint-disable-next-line
  }, [])
  const [projectForApproval, setProjectsForApproval] = useState([])
  const getAllProjectForApproval = async () => {
    props.setLoading(true)
    try {
      const result = await HttpService.getAllProjectForApproval()
      setProjectsForApproval(result.data)
    } catch (error) {
      console.error(error)
      setProjectsForApproval([])
      props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
    }
    props.setLoading(false)
  }

  const [projectDetailsModal, setProjectDetailsModal] = useState(false)
  const [projectDetail, setProjectDetail] = useState({})
  const getProjectForApproval = async (projectId) => {
    props.setLoading(true)
    try {
      const result = await HttpService.getProjectForApproval(projectId)
      setProjectDetail(result.data)
      setProjectDetailsModal(true)
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
    }
    props.setLoading(false)
  }
  const [viewInfo, setViewInfo] = useState(false)
  const [projectDocuments, setProjectDocuments] = useState({})
  const [remark, setRemark] = useState("")
  const [key, setKey] = useState("")

  const handleDailogView = (id, key) => {
    props.setLoading(true)
    setKey(key)
    setProjectDocuments(projectForApproval.filter(project => project._id === id)[0])
    setViewInfo(true)
    props.setLoading(false)
  }

  const handleSubmit = () => {
    if (key === "Approve")
      approveProject()
    else
      sendBackToDesign()
  }
  const sendBackToDesign = async () => {
    props.setLoading(true)
    try {
      await HttpService.sendToDesign(projectDocuments._id, { documents: projectDocuments.documents, remark })
      props.setSnackInfo("Document sent back to design", "success")
      getAllProjectForApproval()
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
    }
    handleDialogClose()
    props.setLoading(false)
  }

  const approveProject = async () => {
    props.setLoading(true)
    try {
      await HttpService.approveProject(projectDocuments._id, { documents: projectDocuments.documents, remark })
      props.setSnackInfo("Approved", "success")
      getAllProjectForApproval()
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
    }
    handleDialogClose()
    props.setLoading(false)
  }

  const handleDialogClose = () => {
    setRemark("")
    setProjectDocuments({})
    setViewInfo(false)
    setKey("")
  }

  return (
    <div>
      {projectForApproval.length > 0 ? <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Sl No</StyledTableCell>
              <StyledTableCell>Project Code</StyledTableCell>
              <StyledTableCell>Client Name</StyledTableCell>
              <StyledTableCell>Start Date</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {projectForApproval?.map((data, i) => (
              <StyledTableRow key={i + 1}>
                <StyledTableCell>{i + 1}</StyledTableCell>
                <StyledTableCell>{data?.code}</StyledTableCell>
                <StyledTableCell>{data?.clientName}</StyledTableCell>
                <StyledTableCell>{moment(data?.startDate).format("DD-MM-YYYY")}</StyledTableCell>
                <StyledTableCell>
                  <StyledTableRow>
                    <button onClick={() => handleDailogView(data?._id, "Approve")} className='primary-btn'> Approve</button>
                    <button onClick={() => handleDailogView(data?._id, "Design")} className='primary-btn'>Send To Design</button>
                    <button onClick={() => getProjectForApproval(data?._id)} className='primary-btn'>Preview</button>
                  </StyledTableRow>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer> : <div style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>No Projects Pending for Approval</div>}

      <Modal
        title="Project Details"
        centered
        visible={projectDetailsModal}
        onCancel={() => setProjectDetailsModal(false)}
        width={1000}
        maskClosable={false}
        footer={null}
      >
        <div>
          <div style={{ display: "flex", justifyContent: "space-evenly", marginBottom: 12 }}>
            <div>
              <b>Client Name</b>: {projectDetail?.project?.clientName}
            </div>
            <div>
              <b>Project Code</b>: {projectDetail?.project?.code}
            </div>
          </div>
          {projectDetail?.projectProducts?.length > 0 && <TableContainer component={Paper}>
            <Table className={classes.table} size="small" aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Sl No</StyledTableCell>
                  <StyledTableCell>Space</StyledTableCell>
                  <StyledTableCell>Product Name</StyledTableCell>
                  <StyledTableCell>Product Code</StyledTableCell>
                  <StyledTableCell>Scope</StyledTableCell>
                  <StyledTableCell>Area/Qty</StyledTableCell>
                  <StyledTableCell>Unit</StyledTableCell>
                  <StyledTableCell>Price</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {projectDetail?.projectProducts?.map((data, i) => (
                  <StyledTableRow key={i + 1}>
                    <StyledTableCell>{i + 1}</StyledTableCell>
                    <StyledTableCell>{data?.category}</StyledTableCell>
                    <StyledTableCell>{data?.productId?.name}</StyledTableCell>
                    <StyledTableCell>{data?.projectId?.code}</StyledTableCell>
                    <StyledTableCell>{data?.scopeId?.name}</StyledTableCell>
                    <StyledTableCell>{(data?.area !== 0 && data?.area !== undefined) ? `${data?.area} (Area)` : `${data?.quantity} (Qty)`}</StyledTableCell>
                    <StyledTableCell>{data?.unitType}</StyledTableCell>
                    <StyledTableCell>{data?.totalPrice}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>}
          <div style={{ marginTop: 25, width: "50%" }}>
            <Card className={classes.root}>
              <CardContent >
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography variant="h6" color="textSecondary" gutterBottom>
                    Total quotation value :
                  </Typography>
                  <Typography variant="h6" color="textSecondary" gutterBottom>
                    {projectDetail?.project?.totalPrice.toFixed(2)}
                  </Typography>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography variant="h6" color="textSecondary" gutterBottom >
                    Discount ({projectDetail?.project?.discountPercent}%) :
                  </Typography>
                  <Typography variant="h6" color="textSecondary" gutterBottom>
                    {projectDetail?.project?.discount.toFixed(2)}
                  </Typography>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography variant="h6" color="textSecondary" gutterBottom>
                    Sub total :
                  </Typography>
                  <Typography variant="h6" color="textSecondary" gutterBottom>
                    {projectDetail?.project?.discount !== 0 ? +(projectDetail?.project?.totalPrice - +projectDetail?.project?.discount).toFixed(2) : +projectDetail?.project?.totalPrice.toFixed(2)}
                  </Typography>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography variant="h6" color="textSecondary" gutterBottom>
                    GST :
                  </Typography>
                  <Typography variant="h6" color="textSecondary" gutterBottom>
                    {projectDetail?.project?.gst.toFixed(2)}
                  </Typography>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography variant="h6" color="textSecondary" gutterBottom>
                    Total :
                  </Typography>
                  <Typography variant="h6" color="textSecondary" gutterBottom>
                    {projectDetail?.project?.customerOutflow}
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </Modal>
      <Dialog
        open={viewInfo}
        TransitionComponent={Transition}
        keepMounted
        fullWidth={true}
        maxWidth={'md'}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"List of Documents"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <ProjectDocumentTable PROJECTDOCUMENT={projectDocuments?.documents} />
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Remarks"
            fullWidth
            value={remark}
            onChange={(e) => setRemark(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit} color="primary">
            {key === "Approve" ? "Approve" : "Send To Design"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default connect(null, { setLoading, setSnackInfo })(FinanceApprove)
