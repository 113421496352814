// import React, { useState, useEffect, useRef } from 'react';
// import { connect, useSelector } from 'react-redux'
// import { setLoading, setSnackInfo } from '../redux/actions/appAction'
// import HttpService from '../services/httpService'
// import Paper from '@material-ui/core/Paper';
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
// import TableHead from '@material-ui/core/TableHead';
// import TableRow from '@material-ui/core/TableRow';
// import { makeStyles, withStyles } from '@material-ui/core/styles';
// import { useLocation } from 'react-router-dom';
// import moment from 'moment'
// import { CSVLink } from "react-csv";
// import TextField from '@material-ui/core/TextField';
// import { Link } from 'react-router-dom';
// import Typography from '@material-ui/core/Typography';
// import Button from '@material-ui/core/Button';
// const useStyles = makeStyles((theme) => ({
//     table: {
//         minWidth: 700,
//         marginTop: "20px"
//     },
//     root: {
//         width: '100%',
//     },
//     title: {
//         fontSize: 14,
//     },
//     pos: {
//         marginBottom: 12,
//     },
//     paper: {
//         padding: theme.spacing(2),
//         textAlign: 'center',
//         color: theme.palette.text.secondary,
//     },
//     textField: {
//         marginLeft: theme.spacing(1),
//         marginRight: theme.spacing(1),
//         width: 200,
//     }
// }));
// const StyledTableCell = withStyles((theme) => ({
//     head: {
//         backgroundColor: "#ccc",
//         color: theme.palette.common.black,
//     },
//     body: {
//         fontSize: 14,
//     },
// }))(TableCell);
// const StyledTableRow = withStyles((theme) => ({
//     root: {
//         '&:nth-of-type(odd)': {
//             backgroundColor: theme.palette.action.hover,
//         },
//     },
// }))(TableRow);
// const OperationOne = (props) => {
//     const classes = useStyles();
//     const [operationoneData, setOperationoneData] = useState([]);
//     const [searchField, setSearchField] = useState("")
//     const [startDate, setStartDate] = useState(moment().subtract(2, 'months').format('YYYY-MM-DD'))
//     const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'))
//     const buttonRef = useRef(null)

//     useEffect(() => {
//         getOpsDashboardOne()
//     }, [])
//     const getOpsDashboardOne = async () => {
//         let obj = {
//             startDate: startDate,
//             endDate: endDate,
//         }
//         props.setLoading(true)
//         try {
//             const result = await HttpService.opsOneTableData(obj)
//             let allData=[]
//             result.data.forEach((el)=>{
//                 let obj={
//                 leadId : el?.leadId,
//                 projectNo : el?.projectNo,
//                 customerName : el?.customerName,
//                 designSignOffDate :el?.designSignOffDate ? moment(el.designSignOffDate).format('YYYY-MM-DD') :"-",
//                 siteQc : el?.siteQc,
//                 imos : el?.imos,
//                 siteBom : el?.siteBom,
//                 factoryBom : el?.factoryBom,
//                 bom : el?.bom,
//                 imosUser: el?.imosUser,
//                 factoryBomUser: el?.factoryBomUser,
//                 siteQcUser: el?.siteQcUser,
//                 siteBomUser: el?.siteBomUser
//                 }
//                 allData.push(obj)
//             })
//             setOperationoneData(allData)
//         } catch (error) {
//             props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
//         }
//         props.setLoading(false)
//     }
//     const searchProject = (e) => {
//         setSearchField(e.target.value);
//         const searchQuery = String(e.target.value).toLowerCase();
//         let filteredData = [];
//         for (let i = 0; i < operationoneData.length; i++) {
//             if (String(operationoneData[i].projectNo).toLowerCase().includes(searchQuery)) {
//                 filteredData.push(operationoneData[i]);
//                 setOperationoneData(filteredData);
//             }
//         }
//         if (e.target.value === "") {
//             getOpsDashboardOne()
//         }
//     };
//     const getdataOnSelectedDate = async (type) => {
//         let obj = {
//             startDate: startDate,
//             endDate: endDate
//         }
//         props.setLoading(true)
//         try {
//             const result = await HttpService.opsOneTableData(obj)
//             setOperationoneData(result.data)
//         } catch (error) {
//             props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
//         }
//         props.setLoading(false)
//     }
//     const headers = [
//         { key: "projectNo", label: "Erp Project No" },
//         { key: "customerName", label: "Customer Name" },
//         {key: "designSignOffDate", label: "DesignSign-Off-Date Stage"},
//         { key: "siteQc", label : "Site-Qc"},
//         { key: "siteQcUser", label : "AssignedTo-SiteQc"},
//         { key: "imos", label: "Imos-Stage" },
//         { key: "imosUser", label: "AssignedTo-imos" },
//         { key: "siteBom", label: "Site-Bom"},
//         { key: "siteBomUser", label: "AssignedTo-SiteBom"},
//         { key: "factoryBom", label: "factory-Bom"},
//         { key: "factoryBomUser", label: "AssignedTo-FactoryBom"},
//         { key: "bom", label: "Bom"},
//     ];
//     const downloadCsv = () => {
//         try {
//             setTimeout(() => {
//                 buttonRef.current.link.click();
//             });
//         } catch (err) {
//             console.error(err.message);
//         }
//         props.setLoading(false)
//     }

//     const clearFilterDate = () => {
//         setStartDate('');
//         setEndDate('');
//         props.setLoading(true)
//         getOpsDashboardOne()
//         props.setLoading(false)
//     }


//     return (
//         <div>
//             <Paper className={classes.paper} style={{ textAlign: 'center', marginBottom: 20 }}>
//                 <Typography variant="h6" gutterBottom style={{ paddingTop: 10, fontSize: 27, fontWeight: 700 }}>
//                     OPS-1 DASHBOARD
//                 </Typography>
//                 <TextField
//                     id="date"
//                     name="startDate"
//                     label="Design-SignOff-StartDate "
//                     type="date"
//                     value={startDate}
//                     onChange={(e) => setStartDate(e.target.value)}
//                     InputLabelProps={{
//                         shrink: true,
//                     }}
//                     fullWidth
//                     className={classes.textField}
//                 />
//                 <TextField
//                     id="date"
//                     name="endDate"
//                     label="Design-SignOff-EndDate"
//                     type="date"
//                     value={endDate}
//                     onChange={(e) => setEndDate(e.target.value)}
//                     InputLabelProps={{
//                         shrink: true,
//                     }}
//                     fullWidth
//                     className={classes.textField}
//                 />
//                 <Button variant="contained" color="primary" disabled={!startDate || !endDate} style={{ marginLeft: 8 }} onClick={getdataOnSelectedDate} >
//                     Submit
//                 </Button>
//                 <Button variant="contained" color="secondary" style={{ margin: 8 }} onClick={() => clearFilterDate()} >
//                     Clear All Filter
//                 </Button>
//                 <CSVLink
//                     headers={headers}
//                     filename={"leadsExcel" + '.csv'}
//                     data={operationoneData}
//                     ref={buttonRef}
//                     />
//                     <Button variant="contained" onClick={() => downloadCsv() }style={{backgroundColor:"green", color:"white"}}>download csv</Button>
//             </Paper>
//             <Paper style={{ marginTop: "50px" }}>
//                 {/* <h1 style={{textAlign:"center",color:"grey"}}>OPS-1 DASHBOARD</h1> */}
//                 <TextField
//                     label="Search Project"
//                     type="text"
//                     value={searchField}
//                     style={{ marginBottom: 10, marginTop: 15, marginRight: 10, float: "right" }}
//                     name="searchField"
//                     onChange={searchProject}
//                     variant="outlined"
//                 />
//                 <TableContainer component={Paper}>
//                     <Table className={classes.table} stickyHeader aria-label="sticky table">
//                         <TableHead>
//                             <StyledTableRow>
//                                 <StyledTableCell>Sr No.</StyledTableCell>
//                                 <StyledTableCell>Project No.</StyledTableCell>
//                                 <StyledTableCell>Customer Name</StyledTableCell>
//                                 <StyledTableCell>Design SignOff Date</StyledTableCell>
//                                 <StyledTableCell>Site Qc</StyledTableCell>
//                                 <StyledTableCell>Imos</StyledTableCell>
//                                 <StyledTableCell>Site Bom</StyledTableCell>
//                                 <StyledTableCell>Factory Bom</StyledTableCell>
//                                 <StyledTableCell>Bom</StyledTableCell>
//                             </StyledTableRow>
//                         </TableHead>
//                         <TableBody>
//                             {operationoneData.map((el, i) => {
//                                 return (
//                                     <StyledTableRow key={i + 1}>
//                                         <StyledTableCell>{i + 1}</StyledTableCell>
//                                         <StyledTableCell>
//                                             <Link to={`/project-logs/${el?.leadId}`} style={{ color: "#000", textDecoration: 'underline' }}>
//                                                 {el?.projectNo ? el?.projectNo : "-"}
//                                             </Link>
//                                         </StyledTableCell>
//                                         <StyledTableCell>{el?.customerName ? el?.customerName :'-'}</StyledTableCell>
//                                         <StyledTableCell>{moment(el?.designSignOffDate ? el?.designSignOffDate : "-").format('DD-MM-YYYY')}</StyledTableCell>
//                                         <StyledTableCell>{el?.siteQc ? el?.siteQc : "-"} <br/> {el?.siteQcUser ? el?.siteQcUser : "-"}</StyledTableCell>
//                                         <StyledTableCell>{el?.imos ? el?.imos : "-"} <br/> {el?.imosUser ? el?.imosUser : "-" }</StyledTableCell>
//                                         <StyledTableCell>{el?.siteBom ? el?.siteBom : "-"} <br/> {el?.siteBomUser ? el?.siteBomUser : "-" }</StyledTableCell>
//                                         <StyledTableCell>{el?.factoryBom ? el?.factoryBom : "-"} <br/> {el?.factoryBomUser ? el?.factoryBomUser : "-" }</StyledTableCell>
//                                         <StyledTableCell>{el?.bom ? el?.bom : "-"}</StyledTableCell>
//                                     </StyledTableRow>
//                                 );
//                             })}
//                         </TableBody>
//                     </Table>
//                 </TableContainer>
//             </Paper>
//         </div>
//     )
// }
// export default connect(null, { setLoading, setSnackInfo })(OperationOne)