import React, { useState, useEffect } from 'react';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import { commaSeparateAmount } from '../../components/commaSeparate';
import { DownloadOutlined } from "@ant-design/icons";
import moment from "moment";
import {
  Card,
  CardContent,
  Container,
  Typography,
  Button,
  Grid,
  IconButton,
} from "@material-ui/core";
import { GetApp } from '@material-ui/icons';
import { useParams } from "react-router-dom";
import HttpService from '../../services/httpService';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, TableFooter, Paper } from '@material-ui/core';
import { useReactToPrint } from 'react-to-print';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { DownCircleOutlined } from '@ant-design/icons';



const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const CustomerFinance = () => {
  const classes = useStyles();
  const tableRef = useRef();
  const { customerDetails } = useSelector((state) => state.customer);
  const { leadId } = useParams();

  const [customerPayments, setCustomerPayments] = useState([]);
  const [expandedRow, setExpandedRow] = useState(null);
  const [selectedPaymentIndex, setSelectedPaymentIndex] = useState(null);


  const [activeCardIndex, setActiveCardIndex] = useState(0); // Initially, the first card is active
  const [leadDetails,setLeadDetails] = useState([])
  const [additionalRowsIncluded, setAdditionalRowsIncluded] = useState(false);
  const [mapTokenPecentage,setTokenPercentage] = useState([])


  useEffect(() => {
    const fetchData = async () => {
      await getCustomerNewDetails();
      handleCalculation();
      // Now, customerPayments should be available, so you can call handleCalculation
    };
  
    fetchData();
  }, []); // Empty dependency array to run once on mount
  
  useEffect(() => {
    // This effect will run whenever customerPayments changes
    if (customerPayments) {
      handleCalculation(leadDetails, customerPayments);
    }
  }, [customerPayments]);
  
  const getCustomerNewDetails = async () => {
    try {
      await getCustomerDetails();
      let res = await HttpService.getCustomerNewDetails(customerDetails.leadId);
      setCustomerPayments(res.data);
    } catch (error) {
      console.error("Token not available.");
    }
  };
  
  const getCustomerDetails = async () => {
    try {
      const res = await HttpService.getCustomerDetails(customerDetails._id);
      setLeadDetails(res.data.leadDetails);
    } catch (error) {
      console.error("Token not available.");
    }
  };


const handleCalculation = (leadDetails) => {
  const grandTotal = leadDetails?.grandTotal || 0;
  // const amountReceivedInSales = leadDetails.amountReceivedInSales || 0;
  // const contractSignedValue = leadDetails.contractSignedValue || 0;


  let store = customerPayments.map((el) => el.Amount_Received);

  // Calculate the sum using reduce
  let sumOfAmountReceived = store.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  const totalPayment = sumOfAmountReceived;

  if (grandTotal === 0) {
    console.error("Grand Total is zero, unable to calculate percentages.");
    return [];
  }

  const paymentDue = grandTotal - totalPayment;

  const paymentDonePercentage = (totalPayment / grandTotal) * 100;

  if (isNaN(paymentDonePercentage) || !isFinite(paymentDonePercentage)) {
    console.error("Invalid paymentDonePercentage value, unable to calculate percentages.");
    return [];
  }

  const data = [
    { type: 'Final Quotation Value', amount: grandTotal, tokenPercentage: 100 },
    { type: 'Amount Due', amount: paymentDue, tokenPercentage: 100 - paymentDonePercentage },
    { type: 'Amount Paid', amount: totalPayment, tokenPercentage: paymentDonePercentage },
  ];

  setTokenPercentage(data);
  return data;
};


  const handleToggleRow = (rowType) => {
    // Toggle the visibility of the details for the clicked row
    setExpandedRow((prevExpandedRow) =>
      prevExpandedRow === rowType ? null : rowType
    );
  };





  const handleRowClick = (row) => {
    // Access createdAt and financeReceipt properties and perform actions
  };
  
  

  const handleDownload = (attachment) => {
    window.open(attachment);
  };

  const handleCardClick = (index) => {
    setActiveCardIndex(index);
  };
  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
  });
  const handleDownloadClick = (data) => {
    window.open(data.financeReceipt)
  };


  const handleDownloadPDF = () => {
    const pdf = new jsPDF();
  
    // Set red border for the page, ensuring visibility:
    pdf.setDrawColor(255, 0, 0);
    pdf.setLineWidth(2); // Make border thicker for clarity
    pdf.rect(0, 0, pdf.internal.pageSize.width, pdf.internal.pageSize.height, 'D');
  
    // Set title with centered alignment:
    pdf.setFontSize(18);
    pdf.text('Payment Receipt', pdf.internal.pageSize.width / 2, 15, { align: 'center' });
  
    // Prepare data for the table
    const tableData = mapTokenPecentage.map(row => {
      if (row.type === 'Amount Paid') {
        const additionalDetails = customerPayments
          .filter(el => el.Amount_Received > 0)
          .map(el => `Payment Done ${commaSeparateAmount(el.Amount_Received.toFixed(1))} On ${moment(el.createdAt).format("DD-MM-YYYY")}`)
          .join('\n');
  
        return [`${row.type}\n${additionalDetails}`, commaSeparateAmount(row.amount.toFixed(2)), `${commaSeparateAmount(row.tokenPercentage.toFixed(2))}%`, ''];
      } else {
        return [row.type, commaSeparateAmount(row.amount.toFixed(2)), `${commaSeparateAmount(row.tokenPercentage.toFixed(2))}%`, '']; // Empty string for other rows
      }
    });
  
    // Set font size for the entire table
    pdf.setFontSize(14); // Adjust the font size as needed
  
    // Define column headers
    const headers = ['Type', 'Amount', 'Token Percentage']; // Add a new column for additional details
  
    // Add the table with autoTable
    pdf.autoTable({
      head: [headers],
      body: tableData,
      startY: 30,
      theme: 'striped', // You can use 'plain' for a simple style
      styles: { halign: 'center', valign: 'middle' },
      columnStyles: {
        0: { halign: 'left', overflow: 'linebreak' }, // Adjust alignment for the first column if needed
        1: { halign: 'center' },
        2: { halign: 'center' },
        3: { halign: 'left' } // Align the new column to the left for additional details
      },
      margin: { top: 10, left: 10, right: 10, bottom: 10 },
    });
  
    // Create a Blob from the PDF content
    const pdfBlob = pdf.output('blob');
  
    // Create a downloadable link
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(pdfBlob);
    downloadLink.download = 'table.pdf';
  
    // Trigger a click on the link to start the download
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  
    setAdditionalRowsIncluded(false);
  };
  
  


  return (
    <Container maxWidth="md" style={{ marginTop: '7rem', marginBottom: "8rem" }}>

      {/* // Quotation Table is here  */}
      <div>
      <TableContainer component={Paper} style={{ marginBottom: '1rem' }} ref={tableRef}>
    <Table id="my-table" className={classes.table} aria-label="simple table" style={{ borderCollapse: 'collapse' }}>
      <TableHead>
        <TableRow>
          <TableCell style={{ borderBottom: '1px solid rgb(221,221,221)' }}>Type</TableCell>
          <TableCell align="right" style={{ borderBottom: '1px solid rgb(221,221,221)' }}>Amount</TableCell>
          <TableCell align="right" style={{ borderBottom: '1px solid rgb(221,221,221)' }}>Token Percentage</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
      {mapTokenPecentage.map((row, index) => (
  <React.Fragment key={row.type}>
    <TableRow>
      <TableCell component="th" scope="row" style={{ border: '1px solid rgb(221,221,221)' }}>
        {row.type}
        {row.type === 'Amount Paid' && (
          <>
            <IconButton onClick={() => handleToggleRow(row.type)}>
              <AddIcon />
            </IconButton>
            {expandedRow === row.type && (
              <>
                {customerPayments.map((el, paymentIndex) => (
                  <div key={paymentIndex} style={{ display: 'flex', alignItems: 'center' }}>
                    {el.Amount_Received > 0 && (
                      <>
                        <span style={{ marginRight: '8px' }}>
                          Payment Done ₹{commaSeparateAmount(el.Amount_Received)} {` On `} {moment(el.createdAt).format("DD-MM-YYYY")} {" "}    <DownloadOutlined onClick={() => handleDownloadClick(el)} style={{ cursor: 'pointer' }} />
                        </span>
                        {/* Additional Payment Details */}
                        {/* You can add more details here */}
                      </>
                    )}
                  </div>
                ))}
              </>
            )}
          </>
        )}
      </TableCell>
      <TableCell align="right" style={{ border: '1px solid rgb(221,221,221)' }}>
        ₹{commaSeparateAmount(row.amount.toFixed(2))}

      </TableCell>
      <TableCell align="right" style={{ border: '1px solid rgb(221,221,221)' }}>
        {commaSeparateAmount(row.tokenPercentage.toFixed(2))}%
      </TableCell>
    </TableRow>
  </React.Fragment>
))}
</TableBody>




      <TableFooter>
        <TableRow>
          <TableCell colSpan={3} align="center">
            {/* Download Button in Table Footer */}
            <Button variant="contained" color="primary" onClick={handleDownloadPDF} style={{ marginRight: '1rem' }}>
              Download PDF
            </Button>
          </TableCell>
        </TableRow>
      </TableFooter>
    </Table>
  </TableContainer>
    </div>
    </Container>
  );
};

export default CustomerFinance;