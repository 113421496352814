import React, { useState, useEffect, useCallback } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { isLogin, setAuthToken } from '../../utils';
import './index.css';
import HttpService from '../../services/httpService'
import { setLoading, setSnackInfo } from '../../redux/actions/appAction';
import { connect } from "react-redux";
import { setUserDetails } from '../../redux/actions/userAction'
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import decorpotLogo from '../../assets/img/org/latestDecorpot.png'
// import decorpotLogo from '../../assets/img/org'

const useStyles = makeStyles({

  input: {
    color: "white",
    borderBottom: "1px solid #fff",
  },
  inputLabel: {
    color: "#aca9a9 !important",
  },
  icon: {
    color: 'white',
  },
  loginButton: { backgroundColor: 'white', marginTop: '10%', color: '#2c2e3e', margin: 'center', padding: 5, borderRadius: '50px', width: '100px', "&:hover": { backgroundColor: "#3f51b5", color: 'white' } },

});

const Index = (props) => {

  const classes = useStyles();

  const history = useHistory()

  const [formValue, setFormValue] = useState({
    email: "",
    password: "",
  })

  const { email, password } = formValue;
  
  const onSubmit = useCallback(async () => {
    props.setLoading(true)
    try {
      let isEmailValid = email.match(
        /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
      )
      if (isEmailValid) {
        
        // console.log('email is', email)
        
        props.setLoading(false)
        
        localStorage.setItem('EMAIL_ID', email)
        
        // history.push('/changePassword');
        
        // return
        
        if (!localStorage.getItem('TOKEN_KEY')) {
        
          const response = await HttpService.login(formValue)
        
          // console.log('response', response)
        
          if(response.status == 202) {
        
            history.push('/changePassword');
        
            props.setSnackInfo(response.data.message, "warning")
        
            return
        
          }
        
          let user = response.data.userDetails
        
          // console.log('user', user)
        
          let MyLastPasswordCreationDate = new Date(user.LastPasswordCreationDate)
        
          // console.log('MyLastPasswordCreationDate', MyLastPasswordCreationDate)
        
          let CurrentDate = new Date()
        
          // console.log('CurrentDate', CurrentDate)
        
          let timeDifference = CurrentDate.getTime() - MyLastPasswordCreationDate.getTime();
        
          // console.log('timeDifference', timeDifference)
        
          let DaysRemainingToChangePassword = 90 - (Math.floor(timeDifference / (1000 * 60 * 60 * 24)))
        
          // console.log('DaysRemainingToChangePassword', DaysRemainingToChangePassword)
          
          if(DaysRemainingToChangePassword < 1) {
        
            history.push('/changePassword');
        
            return
        
          }
        
          setAuthToken(response.data.token)
        
          localStorage.setItem('TOKEN_KEY', response.data.token)
          const userAccess = await HttpService.getUserNavDeatails(response.data.userDetails._id)
          if(response.data.userDetails.experienceCenterId.length!== 1) {
            props.setUserDetails({...response.data.userDetails, canAccess : userAccess.data})
          // if(response.data.userDetails?.roles[0]['name'] === 'Admin') {
            props.history.push('/select-organisation')
        
          } else {
        
            let userDetails = response.data.userDetails
        
            let MyLastPasswordCreationDate = new Date(userDetails.LastPasswordCreationDate)
        
            // console.log('MyLastPasswordCreationDate', MyLastPasswordCreationDate)
        
            let CurrentDate = new Date()
        
            // console.log('CurrentDate', CurrentDate)
        
            let timeDifference = CurrentDate.getTime() - MyLastPasswordCreationDate.getTime();
        
            // console.log('timeDifference', timeDifference)
        
            let DaysRemainingToChangePassword = 90 - (Math.floor(timeDifference / (1000 * 60 * 60 * 24)));
        
            // console.log('differenceInDays', DaysRemainingToChangePassword)
        
            props.setUserDetails({...response.data.userDetails,
              canAccess : userAccess.data,
              selectedExpCenter:response.data.userDetails.experienceCenterId[0],
              selectedLocation:response.data.userDetails.locationId[0]._id,
              DaysRemainingToChangePassword: DaysRemainingToChangePassword          } )
            props.history.push('/dashboard');
        
          }
        
        } else {
        
          props.setUserDetails({})
        
          localStorage.clear()
        
          props.setSnackInfo('Please logout from other tabs', "error")
        
        }
      
      } else {
      
        props.setSnackInfo('Invalid Credentials!', "error")
      
      }
    
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error.response.data.failed, "error")
    }
    props.setLoading(false)
  })

  useEffect(() => {
    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        onSubmit()
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [formValue, onSubmit]);

  const onChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value })
  }

  if (isLogin()) {
    return (<Redirect to="/dashboard" />)
  }

  return (
    <div className='lp-main-container'>
      
      {/* <div className='lp-left-wrapper'> */}
        {/* add decorpot image background */}
      {/* </div> */}
      
      <div className='lp-right-wrapper rounded-container' >
      
        <div className="decorpotLogo" >
        
          <img className="decorpot_logo" src={decorpotLogo} alt="decorpot logo" style={{ width: '150px', height: 'auto' }} />
        
        </div>

        <h3 style={{color: 'white', marginTop: '10%'}}>User Account Login</h3>
        
        <input type='email' id='email' value={email} name='email' placeholder='Email ID' onChange={onChange} style={{ borderRadius: '50px', marginBottom: '5%', marginTop: '10%', height: '40px', padding: '0 10px' }} />
        
        <input type='password' id='password' value={password} name='password' placeholder='Password' onChange={onChange} style={{ borderRadius: '50px', height: '40px', padding: '0 10px' }} />
        
        {/* <button type="submit" className={classes.loginButton} onClick={onSubmit} variant="contained">Login</button> */}
        
        <Button className={classes.loginButton} onClick={onSubmit} variant="contained">
          Sign in
        </Button>
        
        <Link style={{ display: "flex", justifyContent: "flex-end", cursor: 'pointer',  marginTop: '1%', color: 'white' }} to="/forgotPassword">
        
          {/* <span style={{ fontSize: 12, fontWeight: 200, color: 'white', marginRight: '2%' }}>Forgot Password?</span> */}
        
          <span 
          style={{ 
            fontSize: 12, 
            fontWeight: 200, 
            color: 'white', 
            marginRight: '2%', 
            cursor: 'pointer' // Add cursor pointer to indicate it's clickable
          }}
          onMouseOver={(e) => { e.target.style.color = 'grey'; }} // Change style on hover
          onMouseOut={(e) => { e.target.style.color = 'white'; }} // Restore original style when not hovered
          >
          Forgot Password?
          </span>
        
        </Link>
        
        <br />
        {/* <Link style={{ display: "flex", justifyContent: "flex-end", cursor: 'pointer' }} to="/changePassword">
          <span style={{ fontSize: 12, fontWeight: 200, }}>Change Password?</span>
        </Link> */}
        
      </div>

    </div>
  )
}

export default connect(null, { setLoading, setSnackInfo, setUserDetails })(Index);
