import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { setLoading, setSnackInfo } from '../redux/actions/appAction'
import HttpService from '../services/httpService'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tooltip, TextField } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { EyeOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
// import 'antd/dist/antd.css';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 850,
  }
}));


const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#ccc",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const OutwardingView = (props) => {
  const classes = useStyles();

  const [materialTransferOutwardingList, setMaterialTransferOutwardingList] = useState([])
  const [mtrDetails, setMtrDetails] = useState({})
  const [showMtrDetailsModal, setShowMtrDetailsModal] = useState(false)
  const [approvedQty, setApprovedQty] = useState([])

  useEffect(() => {
    getMaterialTransferForOutwarding()
    // eslint-disable-next-line
  }, [])

  const getMaterialTransferForOutwarding = async () => {
    props.setLoading(true)
    try {
      const result = await HttpService.getMaterialTransferForOutwarding(props.TRANSFERFROM)
      setMaterialTransferOutwardingList(result.data)
      // console.log(Object.keys(result.data.length, mtrDetails).length, "Object.keys(mtrDetails).length", result.data?.filter(mtr => mtr._id === mtrDetails._id))
      if (Object.keys(mtrDetails).length > 0) {
        setMtrDetails(result.data?.filter(mtr => mtr._id === mtrDetails._id)[0])
      }
      if (result.data.length === 0) {
        setShowMtrDetailsModal(false)
      }
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", 'error')
    }
    props.setLoading(false)
  }
  const [selectedOrderId, setSelectedOrderId] = useState("");
  const openMtrDetaislModal = (data) => {
    setSelectedOrderId(data.orderId)
    props.setLoading(true)
    let qty = [...approvedQty]
    data.materials.map((material, i) => {
      qty[i] = +material.receivedQuantity - +material.approvedQunatity
      return null
    })
    setApprovedQty(qty)
    setMtrDetails(data)
    setShowMtrDetailsModal(true)
    props.setLoading(false)
  }


  const outwardMaterial = async (id, i, materialId) => {
    props.setLoading(true)
    try {
      await HttpService.outwardMaterial(id, { approvedQunatity: approvedQty[i], materialId })
      getMaterialTransferForOutwarding(props.TRANSFERFROM)
      props.setSnackInfo('Material Outwarded', "success")
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong")
    }
    props.setLoading(false)
  }

  const getApprovedQty = (i) => {
    const item = approvedQty[i]
    return item || {}
  }
  const handleQtyChange = (e, i, quantity) => {
    if (+e.target.value <= +quantity) {
      let qty = [...approvedQty]
      qty[i] = e.target.value
      setApprovedQty(qty)
    } else
      props.setSnackInfo('Cannot exceed requested Qty', "error")
  }

  const generateOutwarding = async () => {
    props.setLoading(true)
    try {
      const response = await HttpService.generateOutwarding(selectedOrderId)
      window.open(response.data)
    } catch (error) {
      console.error(error)
      props.setLoading(error?.response?.data?.message ?? "Something went wrong")
    }
    props.setLoading(false)
  }

  return (
    <div>
      {materialTransferOutwardingList.length > 0 && <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Sl No</StyledTableCell>
              <StyledTableCell>Order ID</StyledTableCell>
              <StyledTableCell>Code_ClientName</StyledTableCell>
              <StyledTableCell>Created At</StyledTableCell>
              <StyledTableCell>Created By</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {materialTransferOutwardingList?.map((data, i) =>
            <StyledTableRow key={i + 1}>
              <StyledTableCell>{i + 1}</StyledTableCell>
              <StyledTableCell>{data?.orderId}</StyledTableCell>
              {
                data.transferType === "Project" ? <StyledTableCell>{`${data?.projectId?.code}_${data?.projectId?.clientName}`}</StyledTableCell> : <StyledTableCell>-</StyledTableCell>
              }
              <StyledTableCell>{moment(data?.createdAt).format('DD-MM-YYYY hh:mm A')}</StyledTableCell>
              <StyledTableCell>{data?.createdBy?.name}</StyledTableCell>
              <StyledTableCell>{data?.status}</StyledTableCell>
              <StyledTableCell> <Tooltip title="View"><EyeOutlined onClick={() => openMtrDetaislModal(data)} /></Tooltip> </StyledTableCell>
            </StyledTableRow>)}
          </TableBody>
        </Table>
      </TableContainer>}

      <Modal
        title={`Outwarding ${selectedOrderId}`}
        centered
        visible={showMtrDetailsModal}
        onCancel={() => setShowMtrDetailsModal(false)}
        width={1000}
        footer={null}
        maskClosable={false}
      >
        <TableContainer component={Paper}>
          <Table className={classes.table} size="small" aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Sl No</StyledTableCell>
                <StyledTableCell>Material Name</StyledTableCell>
                <StyledTableCell>{props.TRANSFERFROM.charAt(0).toUpperCase()}{props.TRANSFERFROM.slice(1)} Available Stocks</StyledTableCell>
                <StyledTableCell>Requested Qty</StyledTableCell>
                <StyledTableCell>Approved Qty</StyledTableCell>
                <StyledTableCell>Outwarded Qty</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {mtrDetails?.materials?.map((data, i) => <StyledTableRow key={i + 1}>
                <StyledTableCell>{i + 1}</StyledTableCell>
                <StyledTableCell>{data?.materialId?.name}</StyledTableCell>
                <StyledTableCell>{`${data.materialId.stocks[props.TRANSFERFROM].available}`}</StyledTableCell>
                <StyledTableCell>{data?.quantity}</StyledTableCell>
                <StyledTableCell>{data?.receivedQuantity}</StyledTableCell>
                <StyledTableCell>{data?.approvedQunatity}</StyledTableCell>
                {data?.status !== "Rejected" && data?.status !== "Outwarded" ?
                  <StyledTableRow>
                    <StyledTableCell > <button className='btn' onClick={() => outwardMaterial(data?._id, i, data?.materialId)}> Approve </button></StyledTableCell>
                    <StyledTableCell>
                      <TextField InputLabelProps={{ shrink: true }} type="number" value={getApprovedQty(i)} onChange={(e) => handleQtyChange(e, i, data?.receivedQuantity)} label='Approving Qty' variant="outlined" />
                    </StyledTableCell>
                  </StyledTableRow> : <StyledTableCell>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      {data?.status}
                    </div>
                  </StyledTableCell>}
              </StyledTableRow>)}
            </TableBody>
          </Table>
        </TableContainer>
        <div style={{ marginTop: 10, display: "grid", placeContent: "flex-end" }}>
          <button className='btn' onClick={() => generateOutwarding()}> Print </button>
        </div>
      </Modal>
    </div >
  )
}

export default connect(null, { setLoading, setSnackInfo })(OutwardingView)
