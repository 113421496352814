import React, { useState, useEffect ,useRef} from 'react';
import { connect, useSelector } from 'react-redux'
import { Link } from "react-router-dom";
import { setLoading, setSnackInfo } from '../../../redux/actions/appAction'
import HttpService from '../../../services/httpService'
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Route, Routes, useLocation } from 'react-router-dom';
import Button from '@material-ui/core/Button'
import { CSVLink } from "react-csv";
import { FormControl, TextField, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { ROLENAME } from '../../../utils';

import moment from 'moment'
const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 700,
        marginTop:"30px"
    },
    root: {
        width: '100%',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        border: '2px solid #ccc'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#ccc",
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);
const UnderExecutionProject = (props) => {
    const classes = useStyles();
    const location = useLocation();
    const { userDetails } = useSelector(state => state.user)
    const [underexecution, setUnderExecution] = useState([]);
    const [selectedFilterOption, setSelectedFilterOption] = useState();
    const [startDateFilter, setStartDate] = useState()
    const [endDateFilter, setEndDate] = useState();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [totalaPages, setTotalPages] = useState(0)
    const [selectedExpCenter,setSelectedExpCenter]=useState([])
    const [expcenters, setExpcenters] = useState([]);
    const [expCtrIds, setExpCtrIds] = useState([]);
    const buttonRef = useRef(null)
    useEffect(() => {
        expcenter()
        getUnderExecutionProjects(expCtrIds)
    }, [rowsPerPage, page])

    const getUnderExecutionProjects = async (ExpCtrIds) =>
    {
        let obj = {}

        obj.leadExpCtrIds = ExpCtrIds

        if (selectedFilterOption && startDateFilter && endDateFilter) {
            obj.filterOption = selectedFilterOption
            obj.startDate = startDateFilter
            obj.endDate = endDateFilter
        }
        if (userDetails.roles.find(role => role.name === ROLENAME.ExecutionUser)) {
            obj.isUser = true
        }
        props.setLoading(true)
        try {
            let understore=[];
            const result = await HttpService.getUnderExecutionProjects(userDetails.selectedExpCenter,obj)
            result.data.forEach((el)=>{
                let obj={
                    leadid:el?._id,
                    erpproject:el?.erpProjectId?.code,
                    customerName: el?.customerId.name,
                    currentStage: el?.currentStage,
                    designsignoff: el.designSignOffDate ? moment(el?.designSignOffDate).format('DD-MM-YYYY') :"-",
                    projectCompletion: el.projectCompletionDate ? moment(el?.projectCompletionDate).format('DD-MM-YYYY') : "-",
                    remianingDays: el?.remainingDays,
                    Budget:el?.grandTotal,
                    assignTo : el?.assignTo?.name,
                    experienceCenterId: el?.experienceCenterId
                }
                understore.push(obj)
            })

            setUnderExecution(understore);
            setTotalPages(result.data.length)
        } catch (error) {
            console.log(error, "error")
            props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }
    const headers = [
        { key: "erpproject", label: "Erp Project No" },
        { key: "customerName", label: "Erp Project No" },
        { key: "assignTo", label: "Current User" },
        {key: "currentStage", label: "Current Stage"},
        { key: "designsignoff", label : "DesignSignOffDate"},
        { key: "projectCompletion", label: "ProjectCompletion Date" },
        { key: "remianingDays", label: "Remaining Days"},
        { key: "Budget", label: "Budget"},
    ];
    const downloadCsv = () => {
        try {
            setTimeout(() => {
                buttonRef.current.link.click();
            });
        } catch (err) {
            console.error(err.message);
        }
        props.setLoading(false)
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const handleChangeFilterOption = (event) => {
        setSelectedFilterOption(event.target.value);
        console.log(event)
    };

    const handleUnderExecutionData = (v) =>
    {
        
        setSelectedExpCenter(v)
        
        let expIds = []

        if(v.length > 0)
        {
            v.forEach(el=>expIds.push(el._id))
        }

        setExpCtrIds(expIds)
        getUnderExecutionProjects(expIds)
        
      }

    const expcenter = async () =>
    {
        try
        {
            const response = await HttpService.getExpericenCenters() 
            setExpcenters(response.data)
        }
        catch (error)
        {
            console.log(error)
        }
    }
    return (
        <div>
            <Paper className={classes.paper}>
                <FormControl component="fieldset" style={{ marginBottom: 20 }}>
                    <FormLabel component="legend">Select Filter Option</FormLabel>
                    <RadioGroup row aria-label="filterOption" name="filterOption1" value={selectedFilterOption} onChange={handleChangeFilterOption}>
                        <FormControlLabel value="designSignOffDate" control={<Radio />} label="Design Sign Off Date" />
                        <FormControlLabel value="expected-Completion-Date" control={<Radio />} label="Expected Completion Date" />
                    </RadioGroup>
                </FormControl>
                <br />
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <Autocomplete
                    multiple
                    value={selectedExpCenter}
                    onChange={(e, v) => handleUnderExecutionData(v)}
                    options={expcenters}
                    getOptionLabel={(option) => option.name}
                    style={{ width: 500, marginBottom: 10 ,marginLeft:25}}
                    renderInput={(params) => <TextField {...params} label="Select Project Exp-Center" fullWidth variant="outlined" />}
                    />
                    <TextField
                        id="date"
                        name="startDate"
                        label="From Date"
                        type="date"
                        value={startDateFilter}
                        onChange={(e) => setStartDate(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        fullWidth
                        className={classes.textField}
                    />
                    <TextField
                        id="date1"
                        name="endDate"
                        label="End Date"
                        type="date"
                        value={endDateFilter}
                        onChange={(e) => setEndDate(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                            
                            
                        }}
                        fullWidth
                        className={classes.textField}
                    />
                    <Button style={{width: '10%'}} variant="contained" color="primary" disabled={!startDateFilter || !endDateFilter} onClick={() => getUnderExecutionProjects(expCtrIds)} >
                        Submit
                    </Button>
                </div>
            </Paper>
            <Paper>
            <div style={{marginBottom:"30px",marginTop:"20px", }}>
                <CSVLink
                                    headers={headers}
                                    filename={"leadsExcel" + '.csv'}
                                    data={underexecution}
                                    ref={buttonRef}
                                />
                                <Button variant="contained" color="primary" onClick={() => downloadCsv() }style={{marginTop:"20px" ,float:"right", marginRight:"20px"}}>download Csv</Button>
                                </div>
                <TableContainer component={Paper}>
                    <Table className={classes.table} stickyHeader aria-label="sticky table">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell>Sr. No.</StyledTableCell>
                                <StyledTableCell>Project No.</StyledTableCell>
                                <StyledTableCell>Customer Name</StyledTableCell>
                                <StyledTableCell>Current User</StyledTableCell>
                                <StyledTableCell>Current Stage</StyledTableCell>
                                <StyledTableCell>Design Signoff Date</StyledTableCell>
                                <StyledTableCell>Expected Completion Date</StyledTableCell>
                                <StyledTableCell>Days Pending for Completion</StyledTableCell>
                                <StyledTableCell>Budget</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {underexecution.map((row, i) => {
                                return (
                                    <StyledTableRow key={i + 1}>
                                        <StyledTableCell>{i + 1}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">
                                            <Link to={`/underexecution-project/logs/${row.leadid}`} style={{ color: "#000", textDecoration: 'underline' }}>
                                                {row?.erpproject}
                                            </Link>
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row">{row?.customerName}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">{row?.assignTo}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">{row?.currentStage}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">{row?.designsignoff}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">{row?.projectCompletion}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">{row?.remianingDays}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">{row?.Budget}</StyledTableCell>
                                    
                                    </StyledTableRow>
                                );
                            })}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 20, 50, { label: 'All', value: totalaPages }]}
                                    colSpan={10}
                                    count={totalaPages}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: { 'aria-label': 'rows per page' },
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    )
}
export default connect(null, { setLoading, setSnackInfo })(UnderExecutionProject)