import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux'
import { useHistory } from "react-router-dom";
import { setLoading, setSnackInfo } from '../redux/actions/appAction';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import HttpService from '../services/httpService';
import { ROLENAME } from '../utils';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { commaSeparateAmount } from './commaSeparate';
import { Typography } from 'antd';
const useStyles = makeStyles((theme) => ({
    table: {
        width: '80vw',
        textAlign: 'center'
    },
    root: {
        width: '100%',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
}));
const LeadApprovalView = (props) => {
    const classes = useStyles();
    const { userDetails } = useSelector(state => state.user);
    const history = useHistory();
    const [designerAssignment, setDesignerAssignment] = useState([]);
    const [openRemarkModal, setOpenRemarkModal] = useState(false);

    const [data, setData] = useState({
        grandTotal: "",
        paymentDone: ""
    })

    const { grandTotal, paymentDone } = data
    const changeHandler = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })
    }

    useEffect(() => {
        getAllApprovedLeads()
        // eslint-disable-next-line
    }, [])

    const getAllApprovedLeads = async () => {
        props.setLoading(true)
        try {
            const response = await HttpService.getApprovedLeadLists(userDetails.selectedExpCenter)
            setDesignerAssignment(response.data);
        } catch (error) {
            setDesignerAssignment([]);
            props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const [remark, setRemark] = useState("");
    const [actionStatus, setActionStatus] = useState("");
    const [selectedLeadId, setSelectedLeadId] = useState("");
    const [selectedLead, setSelectedLead] = useState([]);
    const [salesUserEmail, setSalesUserEmail] = useState("")
    const openModalAction = async (status, lead) => {
        setActionStatus(status)
        setOpenRemarkModal(true);
        if (status === 'approve') {
            const { grandTotal, paymentDone } = lead
            setData({ grandTotal, paymentDone })
        }
        setSelectedLead(lead);
        setSelectedLeadId(lead._id);
        let salesUser = [];
        for (let i = 0; i < lead.previouslyAssignedTo.length; i++) {
            for (let j = 0; j < lead.previouslyAssignedTo[i].roles.length; j++) {
                if (lead.previouslyAssignedTo[i]['roles'][j].name === 'Sales User') {
                    salesUser.push(lead.previouslyAssignedTo[i])
                }
            }
        }
        if (salesUser.length !== 0) {
            let finalSalesUser = salesUser.pop();
            setSalesUserEmail(finalSalesUser.email)
        } else {
            setSalesUserEmail(lead.assignTo.email)
        }
    }

    const [fileUploaded, setFileUploaded] = useState(false);

    const onImageChange = async (e) => {
        props.setLoading(true)
        const fd = new FormData()
        fd.append('file', e.target.files[0])
        fd.append('customerName', selectedLead.lead_no + '_' + selectedLead.customerId.name)
        try {
            const fileUploadRes = await HttpService.uploadFile(fd);
            setData({
                ...data,
                paymentProofAtachement: fileUploadRes.data
            })
            setFileUploaded(true);
        } catch (err) {
            props.setSnackInfo(err?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const updateDesignManagerToLead = async () => {
        setOpenRemarkModal(false);
        let obj = {};
        if (actionStatus === 'approve') {
            if (userDetails.roles.find(role => role.name === ROLENAME.FinanceManager)) {
                if (selectedLead.reinitiateProcess === 'Send for Approval'){
                obj = {
                    takenAction: 'approved',
                    reinitiateProcess:"approved",
                    finanaceManagerApproved: [{
                        isApproved: true,
                        status: 'Approved',
                        remark: remark,
                        approvedBy: userDetails._id
                    }]
                }
            }else{
                    obj = {
                        takenAction: 'approved',
                        finanaceManagerApproved: [{
                            isApproved: true,
                            status: 'Approved',
                            remark: remark,
                            approvedBy: userDetails._id
                        }]
                    }
            }
            } else if (userDetails.roles.find(role => role.name === ROLENAME.BusinessHead)) {
                obj = {
                    takenAction: 'approved',
                    businessHeadApproved: [{
                        isApproved: true,
                        status: 'Approved',
                        remark: remark,
                        approvedBy: userDetails._id
                    }],
                    //salesStage: 'Won',
                    //leadStatus: "Won",
                    approvalDone: true,
                    salesUserEmail: salesUserEmail
                }
            }
        } else {
            if (userDetails.roles.find(role => role.name === ROLENAME.FinanceManager)) {
                obj = {
                    takenAction: 'rejected',
                    salesStage: 'Won Deals Pending Designer Assignment',
                    currentStage: 'Won Deals Pending Designer Assignment',
                    salesExecutiveApproved: [{ isApproved: false, status: 'Send for Approval' }],
                    salesManagerApproved: [{ isApproved: false, status: 'Approval Not Initiated' }],
                    customerApproved: [{ isApproved: false, status: 'Approval Not Initiated' }],
                    finanaceManagerApproved: [{
                        isApproved: false,
                        status: 'Rejected',
                        remark: remark
                    }],
                    centerHeadApproved: [{ isApproved: false, status: 'Approval Not Initiated' }],
                    salesLeadOwnRole: "NA"
                }
            } else if (userDetails.roles.find(role => role.name === ROLENAME.BusinessHead)) {
                let salesUser = [];
                for (let i = 0; i < selectedLead.previouslyAssignedTo.length; i++) {
                    for (let j = 0; j < selectedLead.previouslyAssignedTo[i].roles.length; j++) {
                        if (selectedLead.previouslyAssignedTo[i]['roles'][j].name === 'Sales User') {
                            salesUser.push(selectedLead.previouslyAssignedTo[i])
                        }
                    }
                }
                JSON.stringify(salesUser);
                let finalSalesUser = salesUser.pop();
                obj = {
                    takenAction: 'rejected',
                    salesLeadOwnRole: "NA",
                    salesStage: 'Won Deals Pending Designer Assignment',
                    currentStage: 'Won Deals Pending Designer Assignment',
                    salesExecutiveApproved: [{ isApproved: false, status: 'Send for Approval' }],
                    // salesManagerApproved: [{ isApproved: false, status: 'Approval Not Initiated' }],
                    // customerApproved: [{ isApproved: false, status: 'Approval Not Initiated' }],
                    // finanaceManagerApproved: [{ isApproved: false, status: 'Approval Not Initiated' }],
                    // centerHeadApproved: [{ isApproved: false, status: 'Approval Not Initiated' }],
                    departmentId: finalSalesUser?.departmentId,
                    teamId: finalSalesUser?.teamId,
                    assignTo: finalSalesUser?._id,
                    businessHeadApproved: [{
                        isApproved: false,
                        status: 'Rejected',
                        remark: remark
                    }]
                }
            }
        }
        console.log(selectedLeadId, obj)
        props.setLoading(true)
        try {
            const response = await HttpService.salesManagersApprovedLead(selectedLeadId, obj);
            history.push('/lead-approval');
            getAllApprovedLeads()
            props.setSnackInfo(response?.data ?? "Updated Successfully", "success")
        } catch (error) {
            props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const navigateToCheckList = (data) => {
        history.push({
            pathname: '/check-lists',
            state: {
                actionCheck: 'yes',
                leadId: data._id,
                customerDetail: data.customerId,
            }
        })
    }

    return (
        <div>
            <div style={{ marginBottom: 20 }}>
                <h3 style={{ textAlign: 'center' }}>
                    {userDetails.roles.find(role => role.name === ROLENAME.SalesManager) && `Sales Manager Lead Approval`}
                    {userDetails.roles.find(role => role.name === ROLENAME.CenterHead) && `Center Head Approval`}
                    {userDetails.roles.find(role => role.name === ROLENAME.FinanceManager) && `Finance Manager Lead Approval`}
                    {userDetails.roles.find(role => role.name === ROLENAME.BusinessHead) && `Business Head Approval`}
                </h3>
            </div>
            <Divider />


            <div style={{ display: 'flex', marginTop: 10, marginBottom: 20 }}>
                <Paper>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Sr. No.</TableCell>
                                    <TableCell>Lead Name</TableCell>
                                    <TableCell>Lead No.</TableCell>
                                    {/* {userDetails.roles.find(role => role.name === ROLENAME.FinanceManager) &&
                                        <TableCell>Customer Email Id</TableCell>
                                    } */}
                                    {userDetails.roles.find(role => role.name === ROLENAME.BusinessHead || role.name === ROLENAME.FinanceManager ) ?
                                        <TableCell>Assigned To</TableCell>
                                        :
                                        <TableCell>Sales Executive</TableCell>
                                    }
                                    {userDetails.roles.find(role => role.name === ROLENAME.SalesHead || role.name === ROLENAME.SalesManager || role.name === ROLENAME.CenterHead || role.name === ROLENAME.SalesManager || role.name === ROLENAME.AssistantSalesManager) && (
                                        <>
                                            <TableCell>View Details</TableCell>
                                            <TableCell>Remarks</TableCell>
                                        </>
                                    )}
                                    {userDetails.roles.find(role => role.name === ROLENAME.CenterHead) &&
                                        <TableCell component="th">Payment Percentage</TableCell>
                                    }
                                    {userDetails.roles.find(role => role.name === ROLENAME.BusinessHead || role.name === ROLENAME.FinanceManager) && (
                                        <>
                                            <TableCell>Quotation Value</TableCell>
                                            <TableCell>Quotation Amount Paid</TableCell>
                                            <TableCell>Proof (Attachment)</TableCell>
                                            <TableCell>Token %</TableCell>
                                            <TableCell>B2B GST Invoice</TableCell>
                                            <TableCell colSpan={2}>Action</TableCell>
                                        </>
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {designerAssignment.length !== 0 ?
                                    designerAssignment.sort((a, b) => b.createdAt.split('-').reverse().join().localeCompare(a.createdAt.split('-').reverse().join())).map((row, i) => {
                                        return (
                                            <TableRow key={i + 1}>
                                                <TableCell>{i + 1}</TableCell>
                                                <TableCell component="th" scope="row"> {row?.customerId?.name} </TableCell>
                                                <TableCell component="th"> {row?.lead_no}</TableCell>
                                                {/* {userDetails.roles.find(role => role.name === ROLENAME.FinanceManager) &&
                                                    <TableCell>{row?.customerId?.email}</TableCell>
                                                } */}
                                                <TableCell component="th">{row?.assignTo?.name}</TableCell>
                                                {userDetails.roles.find(role => role.name === ROLENAME.SalesHead || role.name === ROLENAME.SalesManager || role.name === ROLENAME.CenterHead || role.name === ROLENAME.SalesManager || role.name === ROLENAME.AssistantSalesManager) && (
                                                    <>
                                                        <TableCell component="th">
                                                            <Button
                                                                disabled={
                                                                    userDetails.roles.find(role => role.name === ROLENAME.CenterHead) &&
                                                                    (row.salesManagerApproved.find((ele1) => ele1.status !== 'Approved' || ele1.isApproved === false))
                                                                }
                                                                onClick={() => navigateToCheckList(row)} color="primary" variant='outlined'>
                                                                View Details
                                                            </Button>
                                                        </TableCell>
                                                        <TableCell component="th">{row?.remark}</TableCell>
                                                    </>
                                                )}
                                                {userDetails.roles.find(role => role.name === ROLENAME.CenterHead) &&
                                                    <TableCell component="th">{((row?.paymentDone / row?.grandTotal) * 100).toFixed(2)} %</TableCell>
                                                }
                                                {userDetails.roles.find(role => role.name === ROLENAME.BusinessHead || role.name === ROLENAME.FinanceManager ) && (
                                                    <>
                                                        <TableCell component="th">₹ {commaSeparateAmount(row?.grandTotal)}</TableCell>
                                                        <TableCell component="th">₹ {commaSeparateAmount(row?.paymentDone)}</TableCell>
                                                        <TableCell component="th">
                                                            <Button color="primary" variant="outlined" disabled={!row?.salesPaymentProofAtachement || row?.salesPaymentProofAtachement === 'NA'} onClick={() => window.open(row?.salesPaymentProofAtachement)}>Download</Button>
                                                        </TableCell>
                                                        <TableCell component="th">{row?.tokenPercent}</TableCell>
                                                        <TableCell component="th" style={{ width: 100 }}>
                                                            {row?.customerRequriedGst ?
                                                                    <>
                                                                            <Typography>{row?.customerGstInName}</Typography>
                                                                            <Typography>{row?.customerGstInNumber}</Typography>
                                                                            <Typography>{row?.customerGstShipping}</Typography>
                                                                    </> : <Typography>NA</Typography>}
                                                                </TableCell>
                                                        <TableCell component="th">
                                                            <Button color="primary" variant="contained" onClick={() => openModalAction('approve', row)}
                                                                disabled={
                                                                    (userDetails.roles.find(role => role.name === ROLENAME.FinanceManager) &&
                                                                        row.finanaceManagerApproved.find((ele1, i) => ele1.status === 'Approved' || ele1.status === 'Approval Not Initiated')) ||
                                                                    (userDetails.roles.find(role => role.name === ROLENAME.BusinessHead) &&
                                                                        row.businessHeadApproved.find((ele1, i) => ele1.status === 'Approved'))
                                                                }
                                                                style={{ marginRight: 5 }}>
                                                                Approve
                                                            </Button>
                                                            <Button color="secondary" variant="outlined" onClick={() => openModalAction('reject', row)}
                                                                disabled={
                                                                    (userDetails.roles.find(role => role.name === ROLENAME.FinanceManager) &&
                                                                        row.finanaceManagerApproved.find((ele1, i) => ele1.status === 'Approved' || ele1.status === 'Approval Not Initiated')) ||
                                                                    (userDetails.roles.find(role => role.name === ROLENAME.BusinessHead) &&
                                                                        row.businessHeadApproved.find((ele1, i) => ele1.status === 'Approved' || ele1.status === 'Approval Not Initiated'))
                                                                } >
                                                                Reject
                                                            </Button>
                                                        </TableCell>
                                                    </>
                                                )}
                                            </TableRow>
                                        );
                                    })
                                    :
                                    <TableRow>
                                        <TableCell colSpan={9} style={{ textAlign: 'center' }}>No Records Found</TableCell>
                                    </TableRow>
                                }
                            </TableBody>

                        </Table>
                    </TableContainer>
                </Paper>
            </div>

            <Dialog open={openRemarkModal} onClose={() => setOpenRemarkModal(false)} aria-labelledby="form-dialog-title" maxWidth='sm'>
                <DialogTitle id="form-dialog-title">
                    {(userDetails.roles.find(role => role.name === ROLENAME.BusinessHead) && actionStatus === 'approve') ?
                        `Select Design Manager and Enter Remark`
                        :
                        `Enter Remark`}
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <div style={{ paddingBottom: 15 }}>
                        <FormControl fullWidth >
                            <TextField
                                id="outlined-multiline-static"
                                label="Enter Narration"
                                multiline
                                rows={4}
                                onChange={(e) => setRemark(e.target.value)}
                                name="remark"
                                variant="outlined"
                                className={classes.textFieldWidth}
                            />
                        </FormControl>
                        {/* {(userDetails.roles.find(role => role.name === ROLENAME.FinanceManager) && actionStatus === 'approve') &&
                            <div style={{ marginBottom: 20 }}>
                                <FormControl style={{ marginLeft: 10, marginRight: 10, marginTop: 20, marginBottom: 10 }}>
                                    <TextField id="outlined-basic" label="Quotation Value" value={grandTotal} variant="outlined" name="grandTotal" onChange={changeHandler} disabled />
                                </FormControl>
                                <FormControl style={{ marginLeft: 10, marginRight: 10, marginTop: 20, marginBottom: 10 }}>
                                    <TextField id="outlined-basic" label="Amount Paid" value={paymentDone} variant="outlined" name="paymentDone" onChange={changeHandler} />
                                </FormControl>
                                <FormControl style={{ marginLeft: 10, marginRight: 10, marginTop: 20, marginBottom: 10 }}>
                                    <TextField id="outlined-basic" label="Token %" value={((paymentDone / grandTotal) * 100).toFixed(2)} variant="outlined" name="tokenPercent" onChange={changeHandler} />
                                </FormControl>
                                <FormControl style={{ marginLeft: 10, marginRight: 10, marginTop: 20, marginBottom: 10 }}>
                                    <TextField id="outlined-basic" label="Account Name" variant="outlined" name="accountName" onChange={changeHandler} />
                                </FormControl>
                                <FormControl style={{ marginLeft: 10, marginRight: 10, marginTop: 20, marginBottom: 10 }}>
                                    <TextField id="outlined-basic" label="Account Through" variant="outlined" name="accountThrough" onChange={changeHandler} />
                                </FormControl>
                                <FormControl style={{ marginLeft: 10, marginRight: 10, marginTop: 20, marginBottom: 10 }}>
                                    <TextField id="outlined-basic" label="On Account Of" variant="outlined" name="onAccountOf" onChange={changeHandler} />
                                </FormControl>
                                <FormControl style={{ marginLeft: 10, marginRight: 10, marginTop: 20, marginBottom: 10, display: 'block' }}>
                                    {
                                        userDetails.roles.find(role => role.name === ROLENAME.FinanceManager) && <>
                                            <input
                                                accept="image/*"
                                                className={classes.input}
                                                id="contained-button-file"
                                                multiple
                                                type="file"
                                                onChange={onImageChange}
                                                style={{ display: 'none' }}
                                            />
                                            <label htmlFor="contained-button-file">
                                                <Button variant="contained" color="primary" disabled={userDetails.roles.find(role => role.name === ROLENAME.SalesManager)} component="span" startIcon={<CloudUploadIcon />}>
                                                    Upload Payment Proof
                                                </Button>
                                                {
                                                    fileUploaded && <CheckCircleIcon style={{ verticalAlign: 'middle', marginLeft: 10, color: "green", fontSize: 30 }} />
                                                }
                                            </label>
                                        </>
                                    }
                                </FormControl>
                            </div>
                        } */}
                    </div>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={() => setOpenRemarkModal(false)} color="secondary" variant='outlined'>
                        Cancel
                    </Button>
                    <Button onClick={() => updateDesignManagerToLead()} color="primary" variant='contained'>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default connect(null, { setLoading, setSnackInfo })(LeadApprovalView)
