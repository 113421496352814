import React, { useState, useEffect, useRef } from 'react'
import {Paper, Grid, Checkbox } from '@material-ui/core';
import moment from 'moment';
import { useHistory } from 'react-router-dom'
import { connect, useSelector } from 'react-redux'
import { setLoading, setSnackInfo } from '../../../redux/actions/appAction'
import { getAllMaterials, setVendorName } from '../../../redux/actions/vendorAction'
import { getAllUnits } from '../../../redux/actions/projectAction'
import HttpService from '../../../services/httpService'
import { Table, Button, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CSVLink } from "react-csv";
import { getApprovedProjects } from '../../../redux/actions/financeAction';


const useStyles = makeStyles
({
  table: {
    minWidth: 700,
  }
});

const StyledTableCell = withStyles((theme) =>
({
  head:
  {
    backgroundColor: "#ccc",
    color: theme.palette.common.black,
  },
  body:
  {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) =>
({
  root:
  {
    '&:nth-of-type(odd)':
    {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);


const Vendors = (props) =>
{
  const classes = useStyles();
  const history = useHistory();
  const buttonRef = useRef(null);
  const { approvedProjects } = useSelector(state => state.finance)
  const [fromDateValue, setFromDateValue] = useState(null)
  const [toDateValue, setToDateValue] = useState(null)
  const [vendors, setVendors] = useState([])
  const [vendorsList, setVendorsList] = useState([])
  const [csvData, setCsvData] = useState([]);
  const [checkArr, setcheckArr] = useState([])
  const [projectsIds, setProjectsIds] = useState([])
  
  const headers =
  [
    { key: "vendorUniqueNo", label: "Vendor Unique Code" },
    { key: "poSoNumber", label: "Po/So Number" },
    { key: "poSoDate", label: "Po/So Date" },
    { key: "materialName", label: "Material Name" },
    { key: "pricePerUnit", label: "Rate" },
    { key: "quantity", label: "Qty" },
    { key: "requestedQuantity", label: "Requested Qty" },
    { key: "itemTotal", label: "Item Total Value" },
    { key: "transportationCharge", label: "Transportation Charge" },
    { key: "gstRate", label: "GST Rate" },
    { key: "discount", label: "Discount" },
    { key: "freight", label: "Fright Charge" },
    { key: "poVal", label: "PO Value" },

  ];

  useEffect(() =>
  {
    getVendors()
    getApprovedProjects()
  }, [])

  const getApprovedProjects = async () =>
  {
		try {
			props.setLoading(true)
			await props.getApprovedProjects()
			props.setLoading(false)
		} catch (error) {
			console.error(error)
			props.setLoading(false)
			props.setSnackInfo(error.response.data.message, "error")
		}
	}

  const handleFromDateChange = (e) =>
  {
    setFromDateValue(e.target.value)
  }

  const handleToDateChange = (e) =>
  {
    if(fromDateValue === null)
    {
      props.setSnackInfo("Please select From Date first", "error")
      return
    }
    if(Date.parse(e.target.value) < Date.parse(fromDateValue))
    {
      props.setSnackInfo("To Date cannot be less than from Date", "error")
      return
    }
    setToDateValue(e.target.value)
  }

  const handleCheckBox = (e, vendorId) =>
  {
    let combineArr = []

    if(e.target.checked)
    {
      combineArr = [...checkArr, ...[vendorId]]
    }
    else
    {
      combineArr = [...checkArr]
      combineArr = combineArr.filter(el=> el !== vendorId)
    }
    setcheckArr(combineArr)
  }

  const handleCsvButton  = async () =>
  {
    if((fromDateValue !== null && toDateValue === null) || (fromDateValue === null && toDateValue !== null))
    {
      props.setSnackInfo("Please enter both Dates correctly", "error")
      return
    }
    props.setLoading(true)
    
      try
      {
        let vendorsIds = [...checkArr]
        if(vendorsIds.length === 0){
          vendorsIds = []
          vendors.forEach(vendor=>{
            vendorsIds.push(vendor._id)
          })
        }
        let obj = {vendorsIds}
        let response = await HttpService.getProcurementsforVendors(obj)
        let poData = response.data
        
        let soResponse = await HttpService.getServiceOrdersforVendors(obj)
        let soData = soResponse.data
        
        poData = [...poData, ...soData]

        if(projectsIds.length > 0){
          let tempData = []
          for(let i = 0; i < poData.length; i++){
            if(projectsIds.includes(poData[i].projectId?._id)){
              tempData.push(poData[i])
            }
          }
          poData = [...tempData]
        }

        let vendorsData = []

        vendorsIds.forEach(el=>{
          let obj = vendors.filter(item=> item._id == el)
          vendorsData.push(obj[0])
        })

        let csvArray = []
  
        for(let k = 0; k < vendorsData.length; k++)
        {
          let vendorPoData = poData.filter(po=> po.vendorId?._id == vendorsData[k]?._id)  
          for (let i = 0; i < vendorPoData.length; i++)
          {
            let poValue = 0
            for (let j = 0; j <= vendorPoData[i].materials.length; j++)
            {  
              if(fromDateValue !== null && toDateValue !== null){
                if(Date.parse(moment(vendorPoData[i].createdAt).format("MM-DD-YYYY")) < Date.parse(fromDateValue) || Date.parse(moment(vendorPoData[i].createdAt).format("MM-DD-YYYY")) > Date.parse(toDateValue)){
                  continue
                }
              }
              let s = 0
              if(vendorPoData[i].orderId[0] == "S"){
                s = 1
              }
              if (j === vendorPoData[i].materials.length) {
    
                let obj = {}
                if(s === 0)
                {  
                  obj = {
                    vendorUniqueNo: null,
                    poSoNumber: null,
                    poSoDate: null,
                    materialName: null,
                    pricePerUnit: null,
                    quantity: null,
                    requestedQuantity :null,
                    transportationCharge : vendorPoData[i]?.transportationCharge || 0,
                    itemTotal: null,
                    discount: vendorPoData[i]?.discount,
                    freight: vendorPoData[i]?.freight,
                    gstRate: vendorPoData[i]?.gstDetails[0]?.rate + "%",
                    poVal : ((poValue * (100 + (parseInt(vendorPoData[i]?.gstDetails[0]?.rate))) / 100) + vendorPoData[i]?.freight - vendorPoData[i]?.discount) + vendorPoData[i].transportationCharge,
                  }
                }
                else
                {
                  obj = {
                    vendorUniqueNo: null,
                    poSoNumber: null,
                    poSoDate: null,
                    materialName: null,
                    pricePerUnit: null,
                    quantity: null,
                    requestedQuantity : null,
                    transportationCharge : vendorPoData[i]?.transportationCharge || 0,
                    itemTotal: null,
                    discount: "NA",
                    freight: "NA",
                    gstRate: "NA",
                    poVal: poValue
                  }
                }
    
                csvArray.push(obj)
    
              } else {
                let obj = {}

                 if(s === 0){
                  obj = {
                    vendorUniqueNo: vendorsData[k].vendorUniqueNo,
                    poSoNumber: vendorPoData[i].orderId,
                    poSoDate: moment(vendorPoData[i].createdAt).format("DD-MM-YYYY"),
                    materialName: vendorPoData[i].materials[j]?.materialId?.name,
                    pricePerUnit: vendorPoData[i].materials[j]?.requestedPrice,
                    quantity: vendorPoData[i].materials[j]?.quantity,
                    requestedQuantity : vendorPoData[i]?.materials[j]?.requestedQuantity,
                    itemTotal: (Number(vendorPoData[i].materials[j]?.requestedPrice)) * vendorPoData[i].materials[j]?.requestedQuantity,
                  }
                 } else {
                  obj = {
                    vendorUniqueNo: vendorsData[k].vendorUniqueNo,
                    poSoNumber: vendorPoData[i].orderId,
                    poSoDate: moment(vendorPoData[i].createdAt).format("DD-MM-YYYY"),
                    materialName: vendorPoData[i].materials[j]?.materialId?.name,
                    pricePerUnit: vendorPoData[i].materials[j]?.price,
                    quantity: vendorPoData[i].materials[j]?.quantity,
                    requestedQuantity : vendorPoData[i]?.materials[j]?.requestedQuantity,
                    itemTotal: (Number(vendorPoData[i].materials[j]?.price)) * vendorPoData[i].materials[j]?.requestedQuantity,
                  }
                 }

                  let tempVal = 0
                  if(s === 0){
                    tempVal = (Number(vendorPoData[i].materials[j]?.requestedPrice)) * vendorPoData[i].materials[j]?.requestedQuantity
                  } else {
                    tempVal = (Number(vendorPoData[i].materials[j]?.price)) * vendorPoData[i].materials[j]?.requestedQuantity
                  }
                
                  poValue += tempVal 
                
                csvArray.push(obj)
    
              }
            }
          }
        }
        setCsvData(csvArray)
        downloadExcel()
      }
      catch (error)
      {
        console.log("error",error)
        props?.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
      }
    props.setLoading(false)
  }

  const downloadExcel = () =>
  {
    props.setLoading(true)
    try
    {
      setTimeout(() => {
        buttonRef.current.link.click();
      });
    }
    catch (err)
    {
      console.error(err.message);
    }
    props.setLoading(false)
  }

  const getVendors = async () =>
  {
    props.setLoading(true)
    try {
      const result = await HttpService.getAllVendors()
      setVendors(result.data)
      setVendorsList(result.data)
    } catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
    }
    props.setLoading(false)
  }

  const handleProjectIds = async (e, v) =>
  {
    let ids = []
    v.forEach(el=> ids.push(el._id))
    setProjectsIds(ids)
  }

  const searchVendor = async (e, v) =>
  {
    let arr = []
    if(v.length > 0){
    for(let i = 0; i < v.length; i++){
      arr.push(...(vendorsList.filter(vendor => vendor.name.toLowerCase().includes(v[i].name.toLowerCase()))))
    }
    setVendors(arr)
    } else{
      props.setLoading(true)
      const result = await HttpService.getAllVendors()
      setVendors(result.data)
      setVendorsList(result.data)
      props.setLoading(false)
    }
  }

  const updateVendor = (e, data) =>
  {
    props.setVendorName(data)
    history.push({
      pathname: '/vendor_po/all',
      state: {
        vendorName: data?.name,
        vendorId: data?._id,
        projectIds: projectsIds
      }
    })
  }

  return (

    <div>
      <Paper>
          <div style={{ display: 'flex', marginTop: 10, marginBottom: 20}}>
              <div className={classes.root}>
                  <Grid container style={{ padding: 10 }}>
                      <Autocomplete
                          multiple
                          onChange={(e, v) => searchVendor(e, v)}
                          id="users-dropdown"
                          options={vendorsList}
                          getOptionLabel={(option) => option.name}
                          style={{ width: 300, marginRight: 10 }}
                          filterSelectedOptions
                          renderInput={(params) => (
                              <TextField
                                  {...params}
                                  variant="outlined"
                                  label="Search By Vendors"
                                  placeholder="Enter the Vendor Name"
                              />
                              )}
                      />
                      <Autocomplete
                          multiple
                          onChange={(e, v) => {
                            handleProjectIds(e, v)
                            console.log("projectsIds: ",projectsIds)
                          }}
                          id="project-code-dropdownn"
                          options={approvedProjects}
                          getOptionLabel={(option) => option.searchName}
                          style={{ width: 300, marginRight: 150 }}
                          filterSelectedOptions
                          renderInput={(params) => (
                              <TextField
                                  {...params}
                                  variant="outlined"
                                  label="Filter By Projects"
                                  placeholder="Enter the Project No."
                              />
                              )}
                      />
                      <TextField
                          id="date"
                          name="startDate"
                          label="From Date"
                          type="date"
                          value={fromDateValue}
                          onChange={e=>handleFromDateChange(e)}
                          InputLabelProps={{
                              shrink: true,
                          }}
                      />
                      <TextField
                          style={{marginLeft: 10}}
                          id="date"
                          name="endDate"
                          label="End Date"
                          type="date"
                          value={toDateValue}
                          onChange={e=>handleToDateChange(e)}
                          InputLabelProps={{
                              shrink: true,
                          }}
                      />
                  </Grid>
                  
              </div>
          </div>
      </Paper>
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell style={{textAlign: 'center'}}>Vendor Unique No</StyledTableCell>
              <StyledTableCell style={{textAlign: 'center'}}>Name</StyledTableCell>
              <StyledTableCell style={{textAlign: 'center'}}>Address</StyledTableCell>
              <StyledTableCell style={{textAlign: 'center'}}>Phone No</StyledTableCell>
              <StyledTableCell style={{textAlign: 'center'}}>Email</StyledTableCell>
              <StyledTableCell style={{textAlign: 'center'}}>POs/SOs</StyledTableCell>
              <StyledTableCell style={{textAlign: 'center'}}><span >Get</span><Button variant="contained" style={{ backgroundColor: 'white', color: 'green', padding: '0', marginBottom: '2px', borderRadius: '20px' }} onClick={handleCsvButton}>CSV</Button></StyledTableCell>
              <CSVLink
                    headers={headers}
                    filename={"vendorsExcel" + '.csv'}
                    data={csvData}
                    ref={buttonRef}
                  />
            </TableRow>
          </TableHead>
          <TableBody>
            {vendors?.map((data, i) => (
              <StyledTableRow key={i + 1}>
                <StyledTableCell style={{textAlign: 'center'}}>{data?.vendorUniqueNo}</StyledTableCell>
                <StyledTableCell style={{textAlign: 'center'}}>{data?.name}</StyledTableCell>
                <StyledTableCell style={{textAlign: 'center'}}>{data?.address}</StyledTableCell>
                <StyledTableCell style={{textAlign: 'center'}}>{data?.contactNumber}</StyledTableCell>
                <StyledTableCell style={{textAlign: 'center'}}>{data?.email}</StyledTableCell>
                <StyledTableCell style={{textAlign: 'center'}}>
                  <Button variant="contained" style={{ backgroundColor: 'green', color: 'white', padding: '0', marginBottom: '2px', borderRadius: '20px' }} onClick={(e) => updateVendor(e, data)}>
                    View
                  </Button>
                </StyledTableCell>
                <StyledTableCell style={{textAlign: 'center'}}>
                  <Checkbox
                        style={{display: 'inline-block', verticalAlign: 'middle'}}
                        name="csvCheckbox"
                        color="primary"
                        onChange = {e => handleCheckBox(e, data?._id)}
                      />
                      
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default connect(null, { setLoading, setSnackInfo, getAllUnits, getAllMaterials, setVendorName, getApprovedProjects })(Vendors)
