import React, { useState, useEffect, useRef, useCallback } from "react";
import { setLoading, setSnackInfo } from "../redux/actions/appAction";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import HttpService from "../services/httpService";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { CSVLink } from "react-csv";
import { useSelector } from 'react-redux';
import moment from "moment";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FormControl from "@material-ui/core/FormControl";
import { MenuItem, Checkbox, withStyles, Select } from "@material-ui/core";
import Autocomplete, {
    createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import XLSX from "xlsx";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
    container: {
        maxHeight: "90vh",
    },
    dateField: {
        width: "200px",
    },
}));

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#3F51B5",
        color: "#FFF",
        fontWeight: "500",
    },
    body: {
        fontSize: 14,
        padding: "0", // Set padding to 0
    },
}))(TableCell);

const RawLeads = (props) => {
    const buttonRef = useRef(null);
    const classes = useStyles();
    const [rawLead, setRawLeads] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [totalCount, setTotalCount] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [csvData, setCsvData] = useState([]);
    const [selectedData, setSelectedData] = useState([]);
    const [uploadedData, setUploadedData] = useState([]);
    const [dupManagerOfSalesTeam, setDupManagerOfSalesTeam] = useState([]);
    const [cities, setCities] = useState([]);
    const [expcenters, setExpcenters] = useState([]);
    const [managerOfSalesTeam, setManagerOfSalesTeam] = useState([]);
    const [leadMaster, setLeadMaster] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(
        Array(rawLead.length).fill("")
    );
    const [selectedCities, setSelectedCities] = useState(
        Array(rawLead.length).fill(null)
    );
    const [selectedAssignToManager, setSelectedAssignToManager] = useState(
        Array(rawLead.length).fill([])
    );
    const [selectedBHK, setSelectedBHK] = useState(
        Array(rawLead.length).fill("")
    );

    const [selectedSourceOfLead, setSelectedSourceOfLead] = useState(
        Array(rawLead.length).fill("")
    );
    const [selectedExperienceCenters, setSelectedExperienceCenters] = useState(
        Array(rawLead.length).fill("")
    );
    const [textValues, setTextValues] = useState(() => Array(rawLead.length).fill('')); // Use a functional approach

    const [store, setStore] = useState([]);
    const { userDetails } = useSelector(state => state.user)
    const [duplicateLeads, setDuplicateLeadView] = useState([])


    const callRawLeads = async () => {
        props.setLoading(true);
        try {
            let obj = {};
            if (startDate !== "" && endDate !== "") {
                obj.startDate = startDate;
                obj.endDate = endDate;
                obj.limit = rowsPerPage;
                obj.page = page;
            } else {
                obj.limit = rowsPerPage;
                obj.page = page;
            }
            const response = await HttpService.gerRawLeads(obj);
            let res = response.data.data;
            setRawLeads(res);
            setTotalCount(response.data.totalLength);
            const totalPagesCount = Math.ceil(
                response.data.totalLength / rowsPerPage
            );
            setTotalPages(totalPagesCount);
            handleGetCsvClick(res);
        } catch (error) {
            props.setSnackInfo("Something went wrong", error);
        }
        props.setLoading(false);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage =
            event.target.value === "All" ? -1 : parseInt(event.target.value, 10);
        setRowsPerPage(newRowsPerPage);
        setPage(0);
    };
    const handleSubmit = () => {
        callRawLeads();
    };
    const handleGetCsvClick = async (res) => {
        let rawLead = res;
        let store = [];
        console.log(rawLead.length,"this is the leafngts")
        for (let i = 0; i < rawLead.length; i++) {
            let obj = {};
            obj.name = rawLead[i].name;
            obj.phone = rawLead[i].phone;
            obj.email = rawLead[i].email;
            obj.Enquiry = moment(rawLead[i].Enquiry).format("DD-MM-YYYY"); // Format the Enquiry date
            store.push(obj); // Add the object to the store array
        }
        setCsvData(store);
    };
    const headers = [
        { key: "name", label: "Customer Name" },
        { key: "phone", label: "Phone No" },
        { key: "email", label: "Email Id" },
        { key: "Enquiry", label: "Enquiry Date" },
    ];
    const downLoadData = () => {
        props.setLoading(true);
        try {
            setTimeout(() => {
                buttonRef.current.link.click();
            });
        } catch (err) {
            console.error(err.message);
        }
        props.setLoading(false);
    };

    useEffect(() => {
        props.setLoading(true);
        callRawLeads();
        getLeadMaster();
        props.setLoading(false);
    }, [rowsPerPage, totalCount, page]);

    useEffect(() => {
        getLocationList();
    }, []);

    const getLeadMaster = async () => {
        props.setLoading(true);
        try {
            const response = await HttpService.getLeadMaster();
            setLeadMaster(response.data);
        } catch (error) {
            props.setSnackInfo(
                error?.response?.data?.message ?? "Something went wrong",
                "error"
            );
        }
        props.setLoading(false);
    };

    const handleUpload = () => {
        // Prepare CSV data with empty fields
        const csvData = selectedData.map(({ name, email, phone }) => [
            name,
            "",
            "",
            "",
            phone,
            email,
            "",
            "",
            "",
            "",
        ]);

        // Prepare workbook with headers and data
        const ws = XLSX.utils.aoa_to_sheet([
            [
                "Name",
                "Apartment Name",
                "Bhk",
                "Branch",
                "Contact number",
                "Email ID",
                "Scope of work ",
                "Registration done",
                "Source",
                "Assign to",
            ],
            ...csvData,
        ]);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

        // Save file
        XLSX.writeFile(wb, "selected_leads.xlsx");
    };

    //   const checkAssignDetail = (e) => {
    //     if (e.target.value) {
    //       let teamDetail = [];
    //       dupManagerOfSalesTeam.forEach((ele) => {
    //         if (ele?._id === e.target.value) {
    //           teamDetail.push(ele);
    //         }
    //       });
    //       setLeadFormData({
    //         ...leadFormData,
    //         [e.target.name]: e.target.value,
    //         assignToName: teamDetail[0].name,
    //         assignTo: teamDetail[0]._id,
    //         department: teamDetail[0].departmentId._id,
    //         team: teamDetail[0].teamId._id,
    //         status: "Lead Received",
    //         teamName: teamDetail[0].teamId.name,
    //       });
    //     } else {
    //       setLeadFormData({
    //         ...leadFormData,
    //         [e.target.name]: e.target.value,
    //         assignToName: "",
    //         assignTo: "",
    //         department: "",
    //         team: "",
    //         teamName: "",
    //       });
    //     }
    //   };

    const getUsers = async () => {
        props.setLoading(true);
        try {
            const result = await HttpService.getUsers();
            getSalesManager(result.data);
        } catch (error) {
            console.error(error);
            props.setSnackInfo(
                error?.response?.data ?? "Something went wrong",
                "error"
            );
        }
        props.setLoading(false);
    };

    const getManagerBasedOnLocation = (data, index) => {
        let salesManagerArr = [];
        dupManagerOfSalesTeam.forEach((ele) => {
            ele.experienceCenterId.forEach((ele1) => {
                if (ele1._id) {
                    salesManagerArr.push(ele);
                }
            });
        });

        // Store previous manager in another hook
        const previousManager = selectedAssignToManager[index];

        // Preserve the previous values of selectedAssignToManager
        // setSelectedAssignToManager((prevSelectedAssignToManager) => {
        //   const updatedSelectedAssignToManager = [...prevSelectedAssignToManager];
        //   if (previousManager) {
        //     updatedSelectedAssignToManager[index] = previousManager;
        //   }
        //   return updatedSelectedAssignToManager;
        // });

        setManagerOfSalesTeam(salesManagerArr);
    };


    //get sales manager
    const getSalesManager = (data) => {
        let salesArr = [];
        let salesManagerArr = [];
        data.forEach((ele) => {
            if (
                ele.roles?.find((role) => role.name === "Sales Manager") &&
                ele?.teamId?.isActive === true &&
                ele.isActive === true
            ) {
                salesArr.push(ele);
                salesManagerArr.push(ele.teamId);
            } else if (
                ele.roles?.find((role) => role.name === "Assistant Sales Manager") &&
                !ele?.teamId?.manager &&
                ele?.teamId?.isActive === true &&
                ele.isActive === true
            ) {
                salesArr.push(ele);
                salesManagerArr.push(ele.teamId);
            }
        });
        // setManagerOfSalesTeam(salesManagerArr)
        setDupManagerOfSalesTeam(salesArr);
    };

    const handleCityChange = (event, index) => {
        const { value } = event.target;

        setCities((prevCities) => {
            const updatedCities = [...prevCities];
            updatedCities[index] = value;
            return updatedCities;
        });
    };

    //get city
    const setLoadingState = (loading) => {
        props.setLoading(loading);
    };

    const getLocationList = async () => {
        setLoadingState(true);
        try {
            const response = await HttpService.getLocationList();
            setCities(response.data);
        } catch (error) {
            props.setSnackInfo(
                error?.response?.data?.message ?? "Something went wrong",
                "error"
            );
        } finally {
            setLoadingState(false);
        }
    };

    const handleCityAutocompleteChange = (newValue, index) => {
        const updatedSelectedCities = [...selectedCities];
        updatedSelectedCities[index] = newValue || ""; // Update selected city for current row
        setSelectedCities(updatedSelectedCities); // Update state

        if (newValue) {
            getExperienceCenterByLocation(newValue, index); // Pass the city ID and index
        } else {
            setExpcenters((prevExpcenters) => {
                const updatedExpcenters = [...prevExpcenters];
                updatedExpcenters[index] = [];
                return updatedExpcenters;
            });
            setSelectedExperienceCenters((prevSelectedExperienceCenters) => {
                const updatedSelectedExperienceCenters = [
                    ...prevSelectedExperienceCenters,
                ];
                updatedSelectedExperienceCenters[index] = "";
                return updatedSelectedExperienceCenters;
            });
        }
    };

    const handleExperienceCenterChange = (event, index) => {

        const { value } = event.target;
        setStore(value);
        setSelectedExperienceCenters((prevSelectedExperienceCenters) => {
            const updatedSelectedExperienceCenters = [
                ...prevSelectedExperienceCenters,
            ];
            updatedSelectedExperienceCenters[index] = value; // Update the selected experience center ID at the specified index
            return updatedSelectedExperienceCenters;
        });

        const newData = [...selectedData];
        const existingIndex = newData.findIndex(
            (data) => data.experienceCenterId === value
        );
        if (existingIndex !== -1) {
            newData.splice(existingIndex, 1);
            setSelectedData(newData);
        }

        getManagerBasedOnLocation(value, index);
    };

    const getExperienceCenterByLocation = async (cityId, index) => {
        setLoadingState(true);
        try {
            const response = await HttpService.getExpericenCentersByLocation(cityId);
            setExpcenters((prevExpcenters) => {
                const updatedExpcenters = [...prevExpcenters];
                updatedExpcenters[index] = response.data;
                return updatedExpcenters;
            });
            getUsers();
        } catch (error) {
            props.setSnackInfo(
                error?.response?.data?.message ?? "Something went wrong",
                "error"
            );
        } finally {
            setLoadingState(false);
        }
    };

    const handleSourceOfLeadChange = (event, index) => {
        const { value } = event.target;
        setSelectedSourceOfLead((prevSelectedSourceOfLead) => {
            const updatedSelectedSourceOfLead = [...prevSelectedSourceOfLead];
            updatedSelectedSourceOfLead[index] = value; // Update the selected source of lead value at the specified index
            return updatedSelectedSourceOfLead;
        });
    };

    const handleAssignToManagerChange = (newValue, index) => {
        setSelectedAssignToManager((prevSelectedAssignToManager) => {
            const updatedSelectedAssignToManager = [...prevSelectedAssignToManager];
            updatedSelectedAssignToManager[index] = newValue; // Assuming newValue is an object with _id property

            return updatedSelectedAssignToManager;
        });

        // // Enable the checkbox for the selected index
        // const updatedSelectedData = [...selectedData];
        // updatedSelectedData[index] = newValue ? true : false;
        // setSelectedData(updatedSelectedData);
    };

    const handleStatusChange = (event, index) => {
        const { value } = event.target;

        setSelectedStatus((prevSelectedStatus) => {
            const updatedSelectedStatus = [...prevSelectedStatus];
            updatedSelectedStatus[index] = value;
            return updatedSelectedStatus;
        });
    };

    const handleBHKChange = (event, index) => {
        const { value } = event.target;
        setSelectedBHK((prevSelectedBHK) => {
            const updatedSelectedBHK = [...prevSelectedBHK];
            updatedSelectedBHK[index] = value;
            return updatedSelectedBHK;
        });
    };



    const handleCheckboxClick = (e, i, rowData) => {

        const selectedIndex = selectedData.findIndex(
            (data) => data.customerName === rowData.name
        );

        if (selectedIndex === -1) {
            // Find the selected city index
            const leadTypeIndex = selectedStatus[i] ?? "";
            const bhkType = selectedBHK[i] ?? "";

            const selectedExpCenterIndex = expcenters[i]
                ?.filter((el) => el?._id === selectedExperienceCenters[i])
                ?.map((el) => el?.name);

            const selectedCityIndex = cities.find((city) => city._id === selectedCities[i]);

            const selectedSource = leadMaster
                .filter((el) => el._id === selectedSourceOfLead[i])
                .map((el) => el._id);

            // Find the manager based on selectedAssignToManager[i]
            const manager = managerOfSalesTeam.find(
                (el) => el._id === selectedAssignToManager[i]
            );

            // Extract departmentId and managerId from the manager object
            const departmentId = manager?.departmentId?._id || "";
            const assignTo = manager?._id || "";
            const assignToName = manager?.name;
            const assignToManager = manager?.teamId?.manager;
            const teamId = manager?.teamId?._id;


            let selectedDataItem = {};
            if (selectedStatus[i] === "Real") {
                // Create the selected data item
                selectedDataItem.customerName = rowData.name;

                selectedDataItem.email = rowData.email;
                selectedDataItem.contact_no = rowData.phone;
                selectedDataItem.cityName = selectedCityIndex.name;
                selectedDataItem.city = selectedCityIndex._id;
                selectedDataItem.experienceCenterName = selectedExpCenterIndex[0];
                selectedDataItem.experienceCenterId = selectedExperienceCenters[i];
                selectedDataItem.address = textValues[i];
                selectedDataItem.sourceOfLead = selectedSource[0];
                selectedDataItem.leadType = leadTypeIndex;
                selectedDataItem.projectType = bhkType;
                selectedDataItem.department = departmentId; // Add departmentId to selectedDataItem
                selectedDataItem.assignTo = assignTo;
                selectedDataItem.assignToName = assignToName;
                selectedDataItem.assignToManager = assignToManager;
                selectedDataItem.teamId = teamId;
                selectedDataItem.status = "Lead Received"
            } else {
                selectedDataItem.customerName = rowData.name;

                selectedDataItem.email = rowData.email;
                selectedDataItem.contact_no = rowData.phone;
                selectedDataItem.cityName = selectedCityIndex.name;
                selectedDataItem.city = selectedCityIndex._id;
                selectedDataItem.experienceCenterName = selectedExpCenterIndex[0];
                selectedDataItem.experienceCenterId = selectedExperienceCenters[i];
                selectedDataItem.address = textValues[i];
                selectedDataItem.sourceOfLead = selectedSource[0];
                selectedDataItem.leadType = leadTypeIndex;
                selectedDataItem.projectType = bhkType;
                selectedDataItem.department = userDetails.departmentId; // Add departmentId to selectedDataItem
                selectedDataItem.assignTo = userDetails._id;
                selectedDataItem.assignToName = userDetails.name;
                selectedDataItem.assignToManager = assignToManager;
                selectedDataItem.teamId = userDetails.teamId.manager._id;
                selectedDataItem.status = leadTypeIndex;

            }

            setSelectedData([...selectedData, selectedDataItem]);
        } else {
            const newData = [...selectedData];
            newData.splice(selectedIndex, 1);
            setSelectedData(newData);
        }
    };

    const handleTextChange = useCallback((event, index) => {
        const newValues = [...textValues];
        newValues[index] = event.target.value;
        setTextValues(newValues);
    }, [textValues, setTextValues]);


    const handleRawLead = async () => {
        props.setLoading(true)
        try {
            let res = await HttpService.pushRawLead(selectedData);
            setSelectedStatus(Array(rawLead.length).fill(""));
            setSelectedCities(Array(rawLead.length).fill(null));
            setSelectedAssignToManager(Array(rawLead.length).fill([]));
            setSelectedBHK(Array(rawLead.length).fill(""));
            setSelectedSourceOfLead(Array(rawLead.length).fill(""));
            setSelectedExperienceCenters(Array(rawLead.length).fill(""));
            setTextValues(Array(rawLead.length).fill(''));
            setSelectedData([])


            if (res.data.warning.length > 0) {
                setDuplicateLeadView(res.data.warning)
                handleOpenDialog();
                props.setSnackInfo("lead Alreasy Exist")
            }
            callRawLeads();
        } catch (error) {
            props.setSnackInfo(error, "Something Went Wrong")
        }
        props.setLoading(false)
    }

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };



    return (
        <Paper
            className={classes.paper}
            style={{ textAlign: "center", marginBottom: 20 }}
        >
            <Grid
                container
                style={{
                    display: "flex",
                    alignItems: "center",
                    width: "60vw",
                    marginLeft: 15,
                }}
            >
                <Grid item md={3} xs={12}>
                    <TextField
                        variant="outlined"
                        id="start-date"
                        label="Start Date"
                        type="date"
                        value={startDate}
                        className={classes.dateField}
                        onChange={(e) => setStartDate(e.target.value)}
                        InputLabelProps={{
							shrink: true,
						}}
                    />
                </Grid>
                <Grid item md={3} xs={12}>
                    <TextField
                        variant="outlined"
                        id="end-date"
                        label="End Date"
                        type="date"
                        value={endDate}
                        className={classes.dateField}
                        onChange={(e) => setEndDate(e.target.value)}
                        InputLabelProps={{
							shrink: true,
						}}
                    />
                </Grid>
                <Grid item md={2} xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        style={{ marginRight: "65px" }}
                    >
                        Submit
                    </Button>
                </Grid>
                <Grid item md={2} xs={12}>
                    <Button
                        variant="contained"
                        onClick={downLoadData}
                        style={{ backgroundColor: "green", color: "white" }}
                    >
                     Download Excel
                    </Button>
                    <CSVLink
                        headers={headers}
                        filename={"vendorsExcel" + ".csv"}
                        data={csvData}
                        ref={buttonRef}
                    />
                </Grid>
                <Grid item md={2} xs={12}>
                <Button
                variant="contained"
                onClick={handleRawLead}
                style={{
                    backgroundColor: selectedData.length === 0 ? "grey" : "green",
                    color: selectedData.length === 0 ? "white" : "white"
                }}
                disabled={selectedData.length === 0} // Check if selectedData is empty
            >
                Upload Bulk Lead
            </Button>
                </Grid>
            </Grid>
       
     

            <TablePagination
                rowsPerPageOptions={[
                    50,
                    100,
                    500,
                    5000,
                    { label: "All", value: totalCount },
                ]}
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

            <TableContainer component={Paper} className={classes.container}>
                <Table className={classes.table} aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell style={{ width: "3%", textAlign: "center" }}>
                                S.No
                            </StyledTableCell>
                            <StyledTableCell style={{ width: "8%", textAlign: "center" }}>
                                Customer Name
                            </StyledTableCell>
                            <StyledTableCell style={{ width: "5%", textAlign: "center" }}>
                                Email
                            </StyledTableCell>
                            <StyledTableCell style={{ width: "5%", textAlign: "center" }}>
                                Phone No
                            </StyledTableCell>
                            <StyledTableCell style={{ width: "5%", textAlign: "center" }}>
                                Created Date
                            </StyledTableCell>

                            <StyledTableCell style={{ width: "5%", textAlign: "center" }}>
                                Address
                            </StyledTableCell>

                            <StyledTableCell style={{ width: "5%", textAlign: "center" }}>
                                Property Type
                            </StyledTableCell>
                            <StyledTableCell style={{ width: "3%", textAlign: "center" }}>
                                Lead Type
                            </StyledTableCell>
                            <StyledTableCell style={{ width: "3%", textAlign: "center" }}>
                                Source
                            </StyledTableCell>
                            <StyledTableCell style={{ width: "3%", textAlign: "center" }}>
                                City
                            </StyledTableCell>
                            <StyledTableCell style={{ width: "3%", textAlign: "center" }}>
                                Exp Center
                            </StyledTableCell>
                            <StyledTableCell style={{ width: "3%", textAlign: "center" }}>
                                Assing To
                            </StyledTableCell>
                            <StyledTableCell style={{ width: "3%", textAlign: "center" }}>
                                Check
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rawLead.length > 0 &&
                            rawLead.map((el, i) => (
                                <TableRow key={i}>
                                    <StyledTableCell style={{ width: "3%", textAlign: "center" }}>
                                        {i + 1}
                                    </StyledTableCell>
                                    <StyledTableCell style={{ width: "8%", textAlign: "center" }}>
                                        {el.name}
                                    </StyledTableCell>
                                    <StyledTableCell style={{ width: "8%", textAlign: "center" }}>
                                        {el.email}
                                    </StyledTableCell>
                                    <StyledTableCell style={{ width: "5%", textAlign: "center" }}>
                                        {el.phone}
                                    </StyledTableCell>
                                    <StyledTableCell style={{ width: "3%", textAlign: "center" }}>
                                        {moment(el.Enquiry).format("DD-MM-YYYY")}
                                    </StyledTableCell>
                                    <StyledTableCell style={{ width: "3%", textAlign: "center" }}>
                                        <TextField
                                           style={{marginLeft:"4px",marginRight:"4px"}}
                                            variant="outlined"
                                            size="small"
                                            value={textValues[i]} // Set value based on index
                                            onChange={(event) => handleTextChange(event, i)} // Pass index to handleTextChange
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell style={{ width: "3%", textAlign: "center" }}>
                                        <TextField
                                            select
                                            label="BHK"
                                            variant="outlined"
                                            size="small"
                                            style={{marginLeft:"4px",marginRight:"4px"}}
                                            value={selectedBHK[i] || ""} // Use the BHK value at the specified index
                                            onChange={(event) => handleBHKChange(event, i)} // Pass index to handleBHKChange
                                            SelectProps={{
                                                multiple: false, // Set to false for single selection
                                                value: selectedBHK[i] || "", // Use the BHK value at the specified index
                                            }}
                                        >
                                            {/* Add children elements here */}
                                            <MenuItem value="1BHK">1BHK</MenuItem>
                                            <MenuItem value="2BHK">2BHK</MenuItem>
                                            <MenuItem value="3BHK">3BHK</MenuItem>
                                            <MenuItem value="Villa">Villa</MenuItem>
                                        </TextField>
                                    </StyledTableCell>
                                    <StyledTableCell style={{ width: "5%", textAlign: "center" }}>
                                        <FormControl fullWidth>
                                            <TextField
                                                select
                                                label="Status"
                                                variant="outlined"
                                                size="small"
                                                style={{marginLeft:"4px",marginRight:"4px"}}
                                                value={selectedStatus[i] || ""} // Use the status value at the specified index
                                                onChange={(event) => handleStatusChange(event, i)} // Pass index to handleStatusChange
                                            >
                                                {/* Add children elements here */}
                                                <MenuItem value="Real">Real</MenuItem>
                                                <MenuItem value="Junk">Junk</MenuItem>
                                                <MenuItem value="Hold">Hold</MenuItem>
                                                <MenuItem value="To Be Called">To Be Called</MenuItem>
                                            </TextField>
                                        </FormControl>
                                    </StyledTableCell>
                                    <StyledTableCell style={{ width: "3%", textAlign: "center" }}>
                                        <FormControl fullWidth>
                                            <TextField
                                                id={`demo-simple-select-${i}`}
                                                select
                                                label="Source*"
                                                style={{marginLeft:"4px",marginRight:"4px"}}
                                                value={selectedSourceOfLead[i] || ""}
                                                onChange={(event) =>
                                                    handleSourceOfLeadChange(
                                                        {
                                                            target: {
                                                                value: event.target.value,
                                                                name: "sourceOfLead",
                                                            },
                                                        },
                                                        i
                                                    )
                                                }
                                                variant="outlined"
                                                size="small"
                                                SelectProps={{
                                                    MenuProps: {
                                                        PaperProps: {
                                                            style: {
                                                                maxHeight: 200,
                                                            },
                                                        },
                                                    },
                                                }}
                                            >
                                                {leadMaster.map((option) => (
                                                    <MenuItem key={option._id} value={option._id}>
                                                        {option.name}
                                                    </MenuItem>
                                                ))}
                                            </TextField>

                                        </FormControl>
                                    </StyledTableCell>
                                    <StyledTableCell
                                        style={{ width: "10%", textAlign: "center" }}
                                    >
                                        <FormControl fullWidth>
                                            <TextField
                                                id={`demo-simple-select-${i}`}
                                                select
                                                label="City *"
                                                style={{marginLeft:"4px",marginRight:"4px"}}
                                                value={selectedCities[i] || ""}
                                                onChange={(event) => handleCityAutocompleteChange(event.target.value, i)}
                                                variant="outlined"
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                SelectProps={{
                                                    MenuProps: {
                                                        PaperProps: {
                                                            style: {
                                                                maxHeight: 200,
                                                            },
                                                        },
                                                    },
                                                }}
                                            >
                                                {cities.map((option) => (
                                                    <MenuItem key={option._id} value={option._id}>
                                                        {option.name}
                                                    </MenuItem>
                                                ))}
                                            </TextField>

                                        </FormControl>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <FormControl fullWidth>
                                            <TextField
                                                style={{marginLeft:"4px",marginRight:"4px"}}
                                                id={`demo-expcenter-simple-select-${i}`}
                                                select
                                                label="Exp Center*"
                                                value={selectedExperienceCenters[i] || ""}
                                                onChange={(event) =>
                                                    handleExperienceCenterChange(
                                                        { target: { value: event.target.value, name: "experienceCenterId" } },
                                                        i
                                                    )
                                                }
                                                variant="outlined"
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                SelectProps={{
                                                    MenuProps: {
                                                        PaperProps: {
                                                            style: {
                                                                maxHeight: 200,
                                                            },
                                                        },
                                                    },
                                                }}
                                            >
                                                {(expcenters[i] || []).map((option) => (
                                                    <MenuItem key={option._id} value={option._id}>
                                                        {option.name}
                                                    </MenuItem>
                                                ))}
                                            </TextField>

                                        </FormControl>
                                    </StyledTableCell>

                                    <StyledTableCell style={{ width: "3%", textAlign: "center" }}>
                                        <FormControl fullWidth style={{ padding:"5px" }}>
                                            <TextField
                                                id={`demo-simple-select-${i}`}
                                                select
                                                label="Team"
                                                style={{marginLeft:"4px",marginRight:"4px"}}
                                                value={selectedAssignToManager[i] || ""}
                                                onChange={(event) => handleAssignToManagerChange(event.target.value, i)}
                                                variant="outlined"
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled={selectedStatus[i] !== "Real"} // Disable if lead status is not "Real"

                                                SelectProps={{
                                                    MenuProps: {
                                                        PaperProps: {
                                                            style: {
                                                                maxHeight: 200,
                                                            },
                                                        },
                                                    },
                                                }}
                                            >
                                                {managerOfSalesTeam
                                                    .filter((manager, index, self) => {
                                                        // Filter out duplicate managers based on their IDs
                                                        return index === self.findIndex((m) => m._id === manager._id);
                                                    })
                                                    .filter((manager) =>
                                                        manager.experienceCenterId.some(
                                                            (expCenter) => expCenter._id === selectedExperienceCenters[i]
                                                        )
                                                    )
                                                    .map((option) => (
                                                        <MenuItem key={option._id} value={option._id}>
                                                            {option.name}
                                                        </MenuItem>
                                                    ))}
                                            </TextField>


                                        </FormControl>
                                    </StyledTableCell>

                                    <StyledTableCell style={{ width: "3%", textAlign: "center" }}>
                                        <Checkbox
                                            checked={
                                                selectedData.findIndex(
                                                    (data) => data.customerName === el.name
                                                ) !== -1
                                            }
                                            disabled={!selectedExperienceCenters[i] || !selectedCities[i] || !selectedSourceOfLead[i] || !selectedBHK[i] || !selectedStatus[i] || !textValues[i] || (selectedStatus[i] === "Real" && !selectedAssignToManager[i])}
                                            onClick={(e) => handleCheckboxClick(e, i, el)}
                                        />


                                    </StyledTableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {/* duplicate lead show in view */}
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>Duplicate Leads</DialogTitle>
                <DialogContent>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Phone</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {duplicateLeads.map((lead, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{lead.customerName}</TableCell>
                                        <TableCell>{lead.email}</TableCell>
                                        <TableCell>{lead.contact_no}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Close</Button>
                </DialogActions>
            </Dialog>

        </Paper>
    );
};
export default connect(null, { setLoading, setSnackInfo })(RawLeads);