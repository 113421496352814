import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux'
import { useHistory, Link } from "react-router-dom";
import { setLoading, setSnackInfo } from '../redux/actions/appAction';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import RadioGroup from '@material-ui/core/RadioGroup';
import HttpService from '../services/httpService';
import { ROLENAME, designDepartment, rolesId } from '../utils';
import { TextField, FormControl,Checkbox, MenuItem } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import moment from 'moment'
import { commaSeparateAmount } from './commaSeparate';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';


const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 700,
    },
    root: {
        width: '100%',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    container: {
        maxHeight: 620,
    },
}));
const CheckLists = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const { userDetails } = useSelector(state => state.user)
    const [leadDetail, setLeadDetail] = useState([]);
    const [checkList, setCheckList] = useState([]);
    const [quotationList, setQuotationList] = useState([])
    const [finalQuotation, setFinalQuotation] = useState([]);
    const [wonDateValue, setwonDateValue] = useState('');
    const [emailValue, setEmailValue] = useState('');
    const [selectedQuotation, setSelectedQuotation] = useState(null);
    const [Daysdiff, setDaysdiff] = useState('');
    const [storeSalesQuotation,setStoreSalesQuotation] = useState([])
    const [selectedVersion, setSelectedVersion] = useState('');
    const [s3Location, setS3Location] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [quotationselected,setQuotationSelected ]  = useState(false)

    const [selected, setSelected] = useState([]);
    // const [closureDate, setClosureDate] = useState(new Date("2018-01-01T00:00:00.000Z"));
    // const [possessionDate, setPossessionDate] = useState(new Date("2018-01-01T00:00:00.000Z"));
    // const [clientMoveinDate, setClientMoveinDate] = useState(new Date("2018-01-01T00:00:00.000Z"));

    const [data, setData] = useState({
        grandTotal: "",
        paymentDone: "",
        checkListRemarkBySalesUser: "",
        backPlySupport: "",
        salesPaymentProofAtachement: '',
        discountPercent:""
    })

    const { grandTotal,discountPercent, paymentDone, checkListRemarkBySalesUser, backPlySupport } = data
    const changeHandler = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })
        setRemark(e.target.value);
    }

    useEffect(() => {
        getProjectLeadDetails()
        getCheckListItems();
        getAllQuotationsForProject();
        // eslint-disable-next-line
    }, [])

    const radiochangeHandler1 = (e) => {
        const value = e.target.value;
        setwonDateValue(value);
    };
    const radiochangeHandler2 = (e) => {
        const value = e.target.value;
        setEmailValue(value);
    };
    const radiochangeHandler3 = (e) => {
        const value = e.target.value;
        setDaysdiff(value);
    };
    const handleClickOpenDialog = () => {
        setOpenDialog(true);
      };
    
      const handleCloseDialog = () => {
        setOpenDialog(false);
      };

    const getAllQuotationsForProject = async () => {
        props.setLoading(true)
        try {
          const response = await HttpService.getAllQuotationsForProject(props.location.state.leadId)
          setStoreSalesQuotation(response.data)
        } catch (error) {
          console.error(error)
          props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
        }
        props.setLoading(false)
      }
    
    const handleCheckboxChange = (event, quotation) => {
        if (event.target.checked) {
            setSelectedQuotation(quotation);
            updateSelectedQuotation(quotation)
            getSelectedQuotationForProject(quotation)
            setComplimentaryItemPresent(false)
            setOpenDialog(false)
        } else {
            setSelectedQuotation(null);
            setOpenDialog(false)
        }
    };

    const updateSelectedQuotation = async (quotation) => {
        try {
            let obj = {
                grandTotal: quotation.grandTotal,
                discountPercent: quotation.discountPercent,
                salesQuotation: quotation.s3Location,
                leadId: quotation.leadId._id,
                version: quotation.version
            }
            let res = await HttpService.updateSalesQuotation(obj)
            if (res.status == 200) {
                setQuotationSelected(true)
                getProjectLeadDetailsWithSelectedQuotation();
            }
        } catch (error) {
            console.log(error)
        }
    }

    const [isDisabled, setIsDisabled] = useState(false);
    const [btnDisable, setBtnDisable] = useState(true);
    const [isEditable, setIsEditable] = useState(false);
    const [invalidEmail, setInvalidEmail] = useState(true);
    const [salesUserdata, setSalesUserdata] = useState("")
    const [salesUserEmail, setSalesUserEmail] = useState("")
    const [salesManagerEmail, setSalesManagerEmail] = useState("")
    const [salesManagerName, setSalesManagerName] = useState("")
    const [latestTwoRemarks, setLatestTwoRemarks] = useState([]);
    const [spaceAndProductNames, setSpaceAndProductNames] = useState([]);
    const [complimentaryItemPresent, setComplimentaryItemPresent] = useState(false);
    const [clicked, setClicked] = useState(false);

    const getProjectLeadDetails = async () => {
        props.setLoading(true)
        try {
            const response = await HttpService.getProjectLeadDetails(props.location.state.leadId);
            const leadLogsDetails = await HttpService.getLeadLogsUsingLeadId(response.data[0]._id);
            setLatestTwoRemarks(leadLogsDetails.data);
            setTimeout(() => {
            setLeadDetail(response.data[0])
            let salesUser = [];
            for (let i = 0; i < response.data[0].previouslyAssignedTo.length; i++) {
                for (let j = 0; j < response.data[0].previouslyAssignedTo[i].roles.length; j++) {
                    if (response.data[0].salesLeadOwnRole === 'SalesUser' && response.data[0].previouslyAssignedTo[i]['roles'][j].name === 'Sales User') {
                        salesUser.push(response.data[0].previouslyAssignedTo[i])
                    } else if (response.data[0].salesLeadOwnRole === 'SalesManager' && response.data[0].previouslyAssignedTo[i]['roles'][j].name === 'Sales Manager') {
                        salesUser.push(response.data[0].previouslyAssignedTo[i])
                    }
                }
            }
            if (salesUser.length !== 0) {
                let finalSalesUser = salesUser.pop();
                setSalesUserdata(finalSalesUser.name)
                setSalesUserEmail(finalSalesUser.email)
            } else {
                setSalesUserdata(response.data[0].assignTo.name)
                setSalesUserEmail(response.data[0].assignTo.email)
            }
            let salesManager = null;
            // Check if assignTo and teamId exist in the response
            if (response.data[0].assignTo && response.data[0].assignTo.teamId) {
                const usersInTeam = response.data[0].assignTo.teamId.users;
                // Iterate through users in the team
                for (let i = 0; i < usersInTeam?.length; i++) {
                    const user = usersInTeam[i];
                    const isSalesManager = user.roles.some(role => role.name === 'Sales Manager');
                    if (isSalesManager) {
                        salesManager = user;
                        break; // Exit the loop if Sales Manager is found
                    }
                }
            }
            if (salesManager) {
                setSalesManagerName(salesManager.name);
                setSalesManagerEmail(salesManager.email);
            }
            const { paymentDone, checkListRemarkBySalesUser, backPlySupport, salesPaymentProofAtachement, closureDate, possessionDate, clientMoveinDate, leadWonDate, expectedSalesclosureDate,staticSalesWonAmount  } = response.data[0]
            setData({ paymentDone, checkListRemarkBySalesUser, backPlySupport, salesPaymentProofAtachement, closureDate, possessionDate, clientMoveinDate, leadWonDate,staticSalesWonAmount })

            if (userDetails.roles.find(role => role.name === ROLENAME.SalesManager)) {
                if (response.data[0]?.salesManagerApproved.find((ele, i) => ele.status === 'Approved')) {
                    setIsDisabled(true);
                }
            }

            if (userDetails.roles.find(role => role.name === ROLENAME.CenterHead)) {
                if (response.data[0]?.centerHeadApproved.find((ele, i) => ele.status === 'Approved' || ele.status === 'Approval Not Initiated')) {
                    setIsDisabled(true);
                }
            }

            if (response.data[0].salesCheckList.length !== 0) {
                setCheckList(response.data[0].salesCheckList);
                setSelected(response.data[0].salesCheckList);
                setBtnDisable(true)
                setIsEditable(true);
            } else {
                getCheckListItems()
                setBtnDisable(true)
            }
            let regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
            if (regex.test(response.data[0]?.customerId?.email)) {
                setInvalidEmail(false)
            }
        }, 500);
        } catch (error) {
            props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }
    const [notes, setNotes] = useState([]);
    const getCheckListItems = async () => {
        props.setLoading(true)
        try {
            const result = await HttpService.getCheckList();
            setCheckList(result.data[0]['checkList'])
            setNotes(result.data[0]['notes'])
        } catch (error) {
            console.error(error)
            props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const getSelectedQuotationForProject = async (quotation) => {
        props.setLoading(true)
        try {
            const response = await HttpService.getAllQuotationsForProject(props.location.state.leadId)
            setQuotationList(response.data)
            if (response.data.length > 0) {
                const sortedQuotations = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                setFinalQuotation(sortedQuotations[0]);
                // Assuming sortedQuotations is an array of Quotation objects and each Quotation object has a 'components' array
                const components = quotation.components;
                const extractedSpacesAndProducts = [];
                components.forEach((component) => {
                    const complimentaryProduct = component.products
                        .flatMap((product) => product.product)
                        .find((product) => product.isComplimentaryItem);
                    
                    // const complimentaryFinish = component.products
                    //     .flatMap((product) => product.finishType)
                    //     .find((finishType) => finishType.isComplimentaryItem)
                    if (complimentaryProduct) {
                        setComplimentaryItemPresent(true)
                        const spaceName = component.categoryId?.name || '';


                        const productNamesInSpace = component.products
                            .flatMap((product) => product)
                            .filter((product) => product.product.isComplimentaryItem)
                            .map((product) => ({
                                name: product.product.name,
                                area: product.area
                            }));                      

                        extractedSpacesAndProducts.push({ spaceName, chApproval: true, productNames: productNamesInSpace});
                    }
                });
                setSpaceAndProductNames(extractedSpacesAndProducts)
            }
        } catch (error) {
            console.error(error)
            props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const handleRadioButtonClick = (event, index, data) => {
        const newSelected = [...checkList];

        newSelected[index].actionSelected = event.target.value;
        newSelected[index].id = data.id;
        newSelected[index].sortId = data.ids ? data.ids : data.sortId;
        newSelected[index].description = data.description;
        delete newSelected[index]['na'];
        delete newSelected[index]['no'];
        delete newSelected[index]['yes'];
        delete newSelected[index]['ids'];

        setSelected(newSelected);

        let countCheck = 0;
        newSelected.forEach((ele, i) => {
            if (ele.isChecked === true) {
                countCheck++
            }
        })
        if (newSelected.length === countCheck) {
            setBtnDisable(true);
            setIsEditable(true)
        } else {
            setBtnDisable(false)
            setIsEditable(false)
        }
    }

    const [fileUploaded, setFileUploaded] = useState(false);

    const onImageChange = async (e) => {
        props.setLoading(true)
        const fd = new FormData()
        fd.append('file', e.target.files[0])
        fd.append('customerName', leadDetail.lead_no + '_' + leadDetail.customerId.name)
        try {
            const fileUploadRes = await HttpService.uploadFile(fd);
            setData({
                ...data,
                salesPaymentProofAtachement: fileUploadRes.data
            })
            setFileUploaded(true);
        } catch (err) {
            props.setSnackInfo(err?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const proceedToSalesManager = async (status) => {
        setClicked(true); 
        if (status === 'Yes') {

            let salesWonManager = ""
            let salesWonManagerName = ""

            if(userDetails.roles.find(role => role.name === 'Sales Manager' || role.name === 'Assistant Sales Manager')){
                salesWonManager = userDetails._id
                salesWonManagerName = userDetails.name
            } else {
                const response = await HttpService.userRecord(userDetails._id);
                let salesManager = null;

                response.data.teamId.users.forEach(user => {
                    user.roles.forEach(role => {
                        if (role.name === 'Sales Manager') {
                            salesManager = user;
                            return;
                        }
                    });

                    // If Sales Manager is not found, search for Assistant Sales Manager
                    if (!salesManager) {
                        user.roles.forEach(role => {
                            if (role.name === 'Assistant Sales Manager' && user._id === userDetails.assistantManagerId) {
                                salesManager = user;
                                return;
                            }
                        });
                    }

                    if (salesManager) {
                        return;
                    }
                });
                salesWonManager = salesManager?._id
                salesWonManagerName = salesManager?.name
            }

            let new_checkList = []
            for (let i = 0; i < selected.length; i++) {
                if (selected[i].id === "5" || selected[i].id === "4" || selected[i].id === "6") {
                    let obj = {
                        _id: selected[i]._id,
                        actionSelected: 'yes',
                        id: selected[i].id,
                        sortId: selected[i].ids ? selected[i].ids : selected[i].sortId,
                        description: selected[i].description
                    }
                    new_checkList.push(obj)
                } else {
                    new_checkList.push(selected[i])
                }
            }

            let obj = {
                paymentDone: data.paymentDone,
                tokenPercent: ((data.paymentDone / data.grandTotal) * 100).toFixed(2),
                tenPercentPaid: ((data.paymentDone / data.grandTotal) * 100).toFixed(2) >= 10 ? true : false,
                amountReceivedInSales: data.paymentDone,
                salesCheckList: new_checkList.sort((a, b) => a.sortId - b.sortId),
                salesComplimentaryItems: spaceAndProductNames,
                salesExecutiveApproved: [{
                    isApproved: true,
                    status: 'Approved',
                    approvedBy: userDetails._id
                }],
                salesManagerApproved: [{
                    isApproved: false,
                    status: 'Send for Approval'
                }],
                customerApproved: [{
                    isApproved: false,
                    status: 'Approval Not Initiated'
                }],
                // finanaceManagerApproved: [{
                //     isApproved: false,
                //     status: 'Approval Not Initiated'
                // }],
                businessHeadApproved: [{
                    isApproved: false,
                    status: 'Approval Not Initiated'
                }],
                centerHeadApproved: [{
                    isApproved: false,
                    status: 'Approval Not Initiated'
                }],
                salesUserId: userDetails._id,
                leadType: 'Real',
                leadStatus: 'Open',
                // leadStatus: 'Won',
                salesPaymentProofAtachement: data.salesPaymentProofAtachement ? data.salesPaymentProofAtachement : `NA`,
                salesStage: 'Won Deals Pending Designer Assignment',
                clientMoveinDate: data.clientMoveinDate,
                leadWonDate: data.leadWonDate,
                closureDate: data.closureDate,
                expectedSalesclosureDate: data.expectedSalesclosureDate,
                possessionDate: data.possessionDate,
                checkListRemarkBySalesUser: data.checkListRemarkBySalesUser,
                backPlySupport: data.backPlySupport,
                salesLeadOwnRole: userDetails.roles.find(role => role.name === ROLENAME.SalesUser) ? 'SalesUser' : 'SalesManager',
                salesWonUser:userDetails._id,
                salesWonUserName: userDetails.name,
                salesWonManager: salesWonManager,
                salesWonManagerName: salesWonManagerName,
                salesWonExpCtrId: userDetails.selectedExpCenter,
                staticSalesWonAmount : data.grandTotal,
                customerId:leadDetail.customerId._id,
                staticSalesWonBudget : ( (data.grandTotal/1.18) / 100000).toFixed(2),
                salesToken : ((data.paymentDone / data.grandTotal) * 100).toFixed(2)
            }
            props.setLoading(true)
            try {
                const response = await HttpService.updateLead(props.location.state.leadId, obj);
                history.push('/qtProjects');
                props.setSnackInfo(response?.data ?? "Updated Successfully", "success")
            } catch (error) {
                props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
            }
            props.setLoading(false)
        } else {
            history.push('/qtProjects');
        }
    }

    const [openDesignManager, setOpenDesignManger] = useState(false);
    const [remark, setRemark] = useState("");
    const [selectedDesginManager, setSelectedDesginManager] = useState("");
    const [moreThanFivePercent, setMoreThanFivePercent] = useState({});
    const [lessThanFivePercentage, setLessThanFivePercentage] = useState({});

    const [selectedStatus, setSelectedStatus] = useState('')
    const daysDifference =
    data.clientMoveinDate && data.closureDate
      ? moment(data.clientMoveinDate).diff(moment(data.closureDate), 'days')
      : null;
    const approveStatus = (status) => {
        setSelectedStatus(status)
        setOpenDesignManger(true)
        let obj = {};
        if (status === 'approve') {
            if (userDetails.roles.find(role => role.name === ROLENAME.CenterHead || role.name === ROLENAME.SalesManager)) {
                let tokenPercentage = ((paymentDone / grandTotal) * 100).toFixed(2)

                 // if (tokenPercentage < 5) {
                if (tokenPercentage < 4.49) {
                    obj = {
                        centerHeadApproved: [{
                            isApproved: true,
                            status: 'Approved',
                            approvedBy: userDetails._id
                        }],
                        businessHeadApproved: [{
                            isApproved: false,
                            status: 'Send for Approval'
                        }],
                        customerApproved: [{
                            isApproved: false,
                            status: 'Send for Approval'
                        }],
                        lessThanFivePercent: true,
                        customerDetail: props.location.state.customerDetail
                    }
                    setLessThanFivePercentage(obj);
                    // callLeadUpdateFunction(obj);
                } else {
                    obj = {
                        centerHeadApproved: [{
                            isApproved: true,
                            status: 'Approved',
                            approvedBy: userDetails._id
                        }],
                        businessHeadApproved: [{
                            isApproved: false,
                            status: 'Not Required'
                        }],
                    }
                    setMoreThanFivePercent(obj);
                }
            }
        }
    }

    const [designManagers, setDesginManagers] = useState([]);
    const [designManagerData, setDesignManagerData] = useState([]);

    const [teamId, setTeamId] = useState('')
    const [departmentId, setDepartmentId] = useState('')
    const setDesignManager = (e) => {
        setSelectedDesginManager(e.target.value)
        for (let i = 0; i < designManagerData.length; i++) {
            if (designManagerData[i].users.length !== 0) {
                for (let j = 0; j < designManagerData[i].users.length; j++) {
                    if (designManagerData[i].users[j]._id === e.target.value) {
                        setTeamId(designManagerData[i]['_id'])
                        setDepartmentId(designManagerData[i]['departmentId'])
                    }
                }
            }
        }
    }

    const updateDesginManagerToLead = async () => {
        let obj = {};
        obj.takenAction = 'approved';
        if (lessThanFivePercentage.lessThanFivePercent === true) {
            obj.centerHeadApproved = [{
                isApproved: true,
                status: 'Approved',
                remark: remark,
                approvedBy: userDetails._id,
            }];
            obj.businessHeadApproved = [{
                isApproved: false,
                status: 'Send for Approval'
            }];
            obj.customerApproved = [{
                isApproved: false,
                status: 'Send for Approval'
            }]
            obj.lessThanFivePercent = true;
            obj.customerDetail = props.location.state.customerDetail;
            // obj.designManagerAssigned = selectedDesginManager;
            // obj.assignTo = selectedDesginManager;
            // obj.teamId = teamId;
            // obj.departmentId = departmentId;
            // obj.leadStatus = "Won";
            // obj.salesStage = 'Won';
            obj.chApprovalRequired = leadDetail?.defaultCheckDescription.length > 0 ? true : false || remark?.length > 0 || checkListRemarkBySalesUser?.length > 0;
            // obj.checkListRemarkBySalesUser = remark;
            obj.designStatus = 'Design';
            obj.designStages = 'Designer Assignment Pending';
            obj.currentStage = 'Designer Assignment Pending';
        } else {
            obj.centerHeadApproved = [{
                isApproved: moreThanFivePercent?.centerHeadApproved ? moreThanFivePercent?.centerHeadApproved[0]?.isApproved : null,
                status: moreThanFivePercent?.centerHeadApproved ? moreThanFivePercent?.centerHeadApproved[0]?.status : null,
                remark: remark,
                approvedBy: moreThanFivePercent?.centerHeadApproved ? moreThanFivePercent?.centerHeadApproved[0]?.approvedBy : null
            }];
            obj.customerApproved = [{
                isApproved: false,
                status: 'Send for Approval'
            }]
            // obj.centerHeadApproved['remark'] = remark
            obj.businessHeadApproved = moreThanFivePercent.businessHeadApproved
            // obj.remark = remark;
            // obj.designManagerAssigned = selectedDesginManager;
            // obj.assignTo = selectedDesginManager;
            // obj.teamId = teamId;
            // obj.departmentId = departmentId;
            obj.moreThanFivePercent = true;
            // obj.leadStatus = "Won";
            // obj.salesStage = 'Won';
            obj.designStatus = 'Design';
            obj.designStages = 'Designer Assignment Pending';
            obj.currentStage = 'Designer Assignment Pending';
            // obj.designHeadAssigned = true;
            obj.chApprovalRequired = leadDetail?.defaultCheckDescription.length > 0 ? true : false || remark?.length > 0 || checkListRemarkBySalesUser?.length > 0;
            // obj.checkListRemarkBySalesUser = remark;
            obj.approvalDone = true;
            obj.salesUserEmail = salesUserEmail;
            obj.salesManagerEmail = salesManagerEmail;
        }
        props.setLoading(true)
        try {
            const response = await HttpService.salesManagersApprovedLead(props.location.state.leadId, obj);
            if (userDetails.roles.find(role => role.name === ROLENAME.CenterHead)) {
                history.push('/lead-approval');
            } else {
                history.push('/qtProjects');
            }
            props.setSnackInfo(response?.data ?? "Updated Successfully", "success")
        } catch (error) {
            props.setSnackInfo("Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const updateToLead = async (status) => {
        let obj = {};
        if (status === 'approve') {
            if (userDetails.roles.find(role => role.name === ROLENAME.SalesManager || role.name === ROLENAME.AssistantSalesManager|| role.name === ROLENAME.SalesHead )) {
                obj = {
                    takenAction: 'approved',
                    // salesManagerId: userDetails._id,
                    salesManagerApproved: [{ isApproved: true, status: 'Approved', remark: remark, approvedBy: userDetails._id }],
                    finanaceManagerApproved: [{ isApproved: false, status: 'Send for Approval' }],
                    // centerHeadApproved: [{
                    //     isApproved: moreThanFivePercent.centerHeadApproved[0].isApproved,
                    //     status: moreThanFivePercent.centerHeadApproved[0].status,
                    //     remark: remark,
                    //     approvedBy: moreThanFivePercent.centerHeadApproved[0].approvedBy }],
                    // moreThanFivePercent: moreThanFivePercent.moreThanFivePercent,
                    // customerApproved: [{ isApproved: false, status: 'Send for Approval' }],
                    // businessHeadApproved:moreThanFivePercent.businessHeadApproved,
                    // approvalDone: true,
                    // salesUserEmail: salesUserEmail,
                    // customerDetail: props.location.state.customerDetail,
                    financeReceipt: {
                        accountName: data.accountName,
                        accountThrough: data.accountThrough,
                        onAccountOf: data.onAccountOf,
                        paymentDone: data.paymentDone
                    },
                    paymentDone: data.paymentDone,
                    tokenPercent: Number(((paymentDone / grandTotal) * 100).toFixed(2)),
                    tenPercentPaid: ((data.paymentDone / data.grandTotal) * 100).toFixed(2) >= 10 ? true : false,
                    amountReceivedInSales: data.paymentDone,
                    lead: leadDetail,
                    paymentProofAtachement: data.salesPaymentProofAtachement,
                    stage:"Sales Manager Approval",
                    // Setting up a key based on Default Check-Description Array getting from backend whether center head Approval is required or not
                    chApprovalRequired: leadDetail?.defaultCheckDescription.length > 0 ? true : false || remark?.length > 0 || checkListRemarkBySalesUser?.length > 0,
                    // checkListRemarkBySalesUser: remark,
                
                }
                let tokenPercentage = ((paymentDone / grandTotal) * 100).toFixed(2)
                // if (tokenPercentage < 5) {
                if (tokenPercentage < 4.49) {
                    obj = {
                        ...obj,
                        centerHeadApproved: [{
                            isApproved: true,
                            status: 'Approved',
                            approvedBy: userDetails._id
                        }],
                        businessHeadApproved: [{
                            isApproved: false,
                            status: 'Send for Approval'
                        }],
                        customerApproved: [{
                            isApproved: false,
                            status: 'Send for Approval'
                        }],
                        lessThanFivePercent: true,
                        customerDetail: props.location.state.customerDetail,
                        designStatus : 'Design',
                        designStages : 'Designer Assignment Pending',
                        currentStage : 'Designer Assignment Pending'
                    }
                } else {
                    obj = {
                        ...obj,
                        centerHeadApproved: [{
                            isApproved: true,
                            status: 'Approved',
                            approvedBy: userDetails._id
                        }],
                        businessHeadApproved: [{
                            isApproved: false,
                            status: 'Not Required'
                        }],
                        customerApproved: [{
                            isApproved: false,
                            status: 'Send for Approval'
                        }],
                        moreThanFivePercent: true,
                        designStatus: 'Design',
                        designStages: 'Designer Assignment Pending',
                        currentStage: 'Designer Assignment Pending',
                        approvalDone: true,
                        salesUserEmail:salesUserEmail,
                        salesManagerEmail: salesManagerEmail
                    }
                }
                // Based on default checks in check-list if Center Head Approval is required sent it to the Center Head
                if (obj.chApprovalRequired) {
                    obj = {
                        takenAction: 'approved',
                        // salesManagerId: userDetails._id,
                        salesManagerApproved: [{ isApproved: true, status: 'Approved', remark: remark, approvedBy: userDetails._id }],
                        finanaceManagerApproved: [{ isApproved: false, status: 'Send for Approval' }],
                        centerHeadApproved: [{ isApproved: false, status: 'Send for Approval' }],
                        // Setting up a key based on Default Check-Description Array getting from backend whether center head Approval is required or not
                        chApprovalRequired: leadDetail?.defaultCheckDescription.length > 0 ? true : false || remark?.length > 0 || checkListRemarkBySalesUser?.length > 0,
                        // checkListRemarkBySalesUser: remark,
                        customerDetail: props.location.state.customerDetail,
                        financeReceipt: {
                            accountName: data.accountName,
                            accountThrough: data.accountThrough,
                            onAccountOf: data.onAccountOf,
                            paymentDone: data.paymentDone
                        },
                        paymentDone: data.paymentDone,
                        tokenPercent: Number(((paymentDone / grandTotal) * 100).toFixed(2)),
                        tenPercentPaid: ((data.paymentDone / data.grandTotal) * 100).toFixed(2) >= 10 ? true : false,
                        amountReceivedInSales: data.paymentDone,
                        lead: leadDetail,
                        paymentProofAtachement: data.salesPaymentProofAtachement,
                        stage: "Sales Manager Approval"
                    }
                }

                // Based on default checks in check-list if Center Head Approval is not required sent it directly to the customer
                // if(!obj.chApprovalRequired){
                //     obj.centerHeadApproved = [{
                //         isApproved: true,
                //         status: "Not Required",
                //         remark: remark,
                //         approvedBy: userDetails._id,
                //     }];
                //     obj.customerApproved = [{
                //         isApproved: false,
                //         status: 'Send for Approval'
                //     }]
                // }
            }

        } else {
            if (userDetails.roles.find(role => role.name === ROLENAME.SalesManager || role.name === ROLENAME.AssistantSalesManager || role.name === ROLENAME.SalesHead)) {
                obj = {
                    takenAction: 'rejected',
                    salesManagerApproved: [{ isApproved: false, remark: remark, status: 'Rejected' }],
                    salesStage: 'Won Deals Pending Designer Assignment',
                    salesExecutiveApproved: [{ isApproved: false, status: 'Send for Approval' }],
                    centerHeadApproved: [{ status: 'Approval Not Initiated', isApproved: false }],
                    customerDetail: props.location.state.customerDetail,
                    salesLeadOwnRole : "NA"
                }
            } else if (userDetails.roles.find(role => role.name === ROLENAME.CenterHead)) {
                obj = {
                    takenAction: 'rejected',
                    centerHeadApproved: [{ isApproved: false, status: 'Rejected', remark: remark }],
                    salesStage: 'Won Deals Pending Designer Assignment',
                    currentStage: 'Won Deals Pending Designer Assignment',
                    salesExecutiveApproved: [{ isApproved: false, status: 'Send for Approval' }],
                    //salesManagerApproved: [{ isApproved: false, status: 'Approval Not Initiated' }],
                    //customerApproved: [{ status: 'Approval Not Initiated', isApproved: false }],
                    //finanaceManagerApproved: [{ status: 'Approval Not Initiated', isApproved: false }],
                    lessThanFivePercent: true,
                    salesLeadOwnRole: "NA",
                    salesManagerName: salesManagerName,
                    salesManagerEmail: salesManagerEmail,
                    chApprovalRequired: leadDetail?.defaultCheckDescription.length > 0 ? true : false || remark?.length > 0 || checkListRemarkBySalesUser?.length > 0,
                    // checkListRemarkBySalesUser: remark,
                    salesUserEmail: salesUserEmail
                }
            }
        }
        props.setLoading(true)
        try {
            const response = await HttpService.salesManagersApprovedLead(props.location.state.leadId, obj);
            if (userDetails.roles.find(role => role.name === ROLENAME.SalesManager)) {
                history.push('/qtProjects');
            } else {
                history.push('/lead-approval');
            }
            if(obj.chApprovalRequired && response.status === 200 && obj.takenAction === 'approved'){
                props.setSnackInfo("Updated Successfully and Sent to Center Head for approval", "success");
            }else if(!obj.chApprovalRequired && response.status === 200 && obj.takenAction === 'approved' && obj.businessHeadApproved[0].status === "Send for Approval"){
                props.setSnackInfo("Updated Successfully and Sent to Business Head for approval", "success");
            }else if(!obj.chApprovalRequired && response.status === 200 && obj.takenAction === 'approved' && obj.businessHeadApproved[0].status === "Not Required"){
                props.setSnackInfo("Updated Successfully and Sent to Customer for approval", "success");
            }else {
                props.setSnackInfo(response?.data ?? "Updated Successfully", "success")
            }
        } catch (error) {
            props.setSnackInfo("Something went wrong", "error")
        }
        props.setLoading(false)
    }

    // function to handle the formatting if items is present in the defaultCheckDescription Array
    const checkIfPresentHandler = (str) => {
        for (let i = 0; i < leadDetail?.defaultCheckDescription?.length; i++) {
            // Check if the given string is present in the 'description' key of the current object
            if (leadDetail?.defaultCheckDescription[i].description.includes(str)) {
                return true; // If found, return true
            }
        }
        return false; // If not found in any object, return false
    }


    //recall function

    // new function called
    const getProjectLeadDetailsWithSelectedQuotation = async () => {
        try {
            const response = await HttpService.getProjectLeadDetails(props.location.state.leadId);
            setTimeout(() => {
            setLeadDetail(response.data[0])
            let salesUser = [];
            for (let i = 0; i < response.data[0].previouslyAssignedTo.length; i++) {
                for (let j = 0; j < response.data[0].previouslyAssignedTo[i].roles.length; j++) {
                    if (response.data[0].salesLeadOwnRole === 'SalesUser' && response.data[0].previouslyAssignedTo[i]['roles'][j].name === 'Sales User') {
                        salesUser.push(response.data[0].previouslyAssignedTo[i])
                    } else if (response.data[0].salesLeadOwnRole === 'SalesManager' && response.data[0].previouslyAssignedTo[i]['roles'][j].name === 'Sales Manager') {
                        salesUser.push(response.data[0].previouslyAssignedTo[i])
                    }
                }
            }
            if (salesUser.length !== 0) {
                let finalSalesUser = salesUser.pop();
                setSalesUserdata(finalSalesUser.name)
                setSalesUserEmail(finalSalesUser.email)
            } else {
                setSalesUserdata(response.data[0].assignTo.name)
                setSalesUserEmail(response.data[0].assignTo.email)
            }
            let salesManager = null;
            // Check if assignTo and teamId exist in the response
            if (response.data[0].assignTo && response.data[0].assignTo.teamId) {
                const usersInTeam = response.data[0].assignTo.teamId.users;
                // Iterate through users in the team
                for (let i = 0; i < usersInTeam.length; i++) {
                    const user = usersInTeam[i];

                    const isSalesManager = user.roles.some(role => role.name === 'Sales Manager');

                    if (isSalesManager) {
                        salesManager = user;
                        break; // Exit the loop if Sales Manager is found
                    }
                }
            }
            if (salesManager) {
                setSalesManagerName(salesManager.name);
                setSalesManagerEmail(salesManager.email);
            } 

            const { grandTotal,discountPercent, paymentDone, checkListRemarkBySalesUser, backPlySupport, salesPaymentProofAtachement, closureDate,expectedSalesclosureDate , possessionDate, clientMoveinDate, leadWonDate } = response.data[0]
            setData({ grandTotal,discountPercent, paymentDone, checkListRemarkBySalesUser, backPlySupport, salesPaymentProofAtachement, closureDate, possessionDate,expectedSalesclosureDate, clientMoveinDate, leadWonDate })

            if (userDetails.roles.find(role => role.name === ROLENAME.SalesManager)) {
                if (response.data[0]?.salesManagerApproved.find((ele, i) => ele.status === 'Approved')) {
                    setIsDisabled(true);
                }
            }

            if (userDetails.roles.find(role => role.name === ROLENAME.CenterHead)) {
                if (response.data[0]?.centerHeadApproved.find((ele, i) => ele.status === 'Approved' || ele.status === 'Approval Not Initiated')) {
                    setIsDisabled(true);
                }
            }

            if (response.data[0].salesCheckList.length !== 0) {
                setCheckList(response.data[0].salesCheckList);
                setSelected(response.data[0].salesCheckList);
                setBtnDisable(true)
                setIsEditable(true);
            } else {
                getCheckListItems()
                setBtnDisable(true)
            }
            let regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
            if (regex.test(response.data[0]?.customerId?.email)) {
                setInvalidEmail(false)
            }
        }, 500);
        } catch (error) {
            props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
        }
    }

    useEffect(() => {
        if (leadDetail.salesQuotation && leadDetail.salesQuotation !== "" && leadDetail.salesQuotation !== null && leadDetail.salesQuotation !== undefined) {
            getProjectLeadDetailsWithSelectedQuotation();
        }
    }, [leadDetail.salesQuotation]); // Include only leadDetail.salesQuotation in the dependency array
    
    return (
        <div>
            <div style={{ marginBottom: 20 }}>

                <h2 style={{ textAlign: 'center' }}>Post Sales Check List</h2>
            </div>
            <Divider />

            <div style={{ margin: 'auto' }}>
                <Paper>
                    <Card className={classes.root} variant="outlined">
                        <CardContent>
                            <Typography variant="h5" component="h2"><strong>Client Master Data</strong></Typography>
                            <hr />
                            <TableContainer component={Paper} style={{ width: '100%' }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableBody>
                                        <TableRow key='1'>
                                            <TableCell style={{ padding: '5px' }} component="th" scope="row">Customer Name</TableCell>
                                            <TableCell style={{ padding: '5px' }} component="th" scope="row">{leadDetail?.customerId?.name}</TableCell>
                                        </TableRow>
                                        <TableRow key='1'>
                                            <TableCell style={{ padding: '5px' }} component="th" scope="row">Lead No.</TableCell>
                                            <TableCell style={{ padding: '5px' }} component="th" scope="row">{leadDetail?.lead_no}</TableCell>
                                        </TableRow>
                                        <TableRow key='2'>
                                            <TableCell style={{ padding: '5px' }} component="th" scope="row">Property Name</TableCell>
                                            <TableCell style={{ padding: '5px' }} component="th" scope="row">{leadDetail?.propertyType} - {leadDetail?.customerId?.address}</TableCell>
                                        </TableRow>
                                        <TableRow key='3'>
                                            <TableCell style={{ padding: '5px' }} component="th" scope="row">Contact Number</TableCell>
                                            <TableCell style={{ padding: '5px' }} component="th" scope="row">{leadDetail?.customerId?.contact_no}</TableCell>
                                        </TableRow>
                                        <TableRow key='4'>
                                            <TableCell style={{ padding: '5px' }} component="th" scope="row">Email Id</TableCell>
                                            <TableCell style={{ padding: '5px' }} component="th" scope="row">{leadDetail?.customerId?.email}</TableCell>
                                        </TableRow>
                                        <TableRow key='8'>
                                            <TableCell style={{ padding: '5px' }} component="th" scope="row">Sales Executive</TableCell>
                                            <TableCell style={{ padding: '5px' }} component="th" scope="row">{salesUserdata}</TableCell>
                                        </TableRow>
                                        <TableRow key='5'>
                                            <TableCell style={{ padding: '5px' }} component="th" scope="row">Tentative Possession/Registration Date</TableCell>
                                            <TableCell style={{ padding: '5px' }} component="th" scope="row">
                                                <FormControl style={{ width: 300 }}>
                                                    <TextField
                                                        style={{ margin: '5px' }}
                                                        id="clientMovein-Date"
                                                        name="possessionDate"
                                                        value={data?.possessionDate ? moment(data?.possessionDate).format('YYYY-MM-DD') : ""}
                                                        onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}
                                                        label="Possession/Registration Date"
                                                        type="date"
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        variant="outlined"
                                                        size="small"
                                                        disabled={leadDetail.salesLeadOwnRole === 'SalesUser' || leadDetail.salesLeadOwnRole === 'SalesManager'|| userDetails.roles.find(role => role.name === ROLENAME.CenterHead)}
                                                    />
                                                </FormControl>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow key='6'>
                                        <TableCell style={{ padding: '5px', color:`${checkIfPresentHandler("Tentative Design Closure (Contract) Sign Date") ? "Red" : ""}`,fontWeight: `${checkIfPresentHandler("Tentative Design Closure (Contract) Sign Date") ? "700" : ""}`  }} component="th" scope="row">Tentative Design Closure (Contract) Sign Date</TableCell>
                                            <TableCell style={{ padding: '5px' }} component="th" scope="row">
                                                <FormControl style={{ width: 300 }}>
                                                    <TextField
                                                        style={{ margin: '5px' }}
                                                        id="closure-Date"
                                                        name="closureDate"
                                                        value={data?.closureDate ? moment(data?.closureDate).format('YYYY-MM-DD') : ""}
                                                        onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}
                                                        label="Design Closure (Contract) Sign Date"
                                                        type="date"
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        variant="outlined"
                                                        size="small"
                                                        disabled={leadDetail.salesLeadOwnRole === 'SalesUser' || leadDetail.salesLeadOwnRole === 'SalesManager'  || userDetails.roles.find(role => role.name === ROLENAME.CenterHead)}
                                                    />
                                                </FormControl>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow key='7'>
                                        <TableCell style={{ padding: '5px',color: `${checkIfPresentHandler("Tentative Client Move-in Date") ? "Red" : ""}`,fontWeight: `${checkIfPresentHandler("Tentative Client Move-in Date") ? "700" : ""}` }} component="th" scope="row">Tentative Client Move-in Date</TableCell>
                                            <TableCell style={{ padding: '5px' }} component="th" scope="row">
                                                <FormControl style={{ width: 300 }}>
                                                    <TextField
                                                        style={{ margin: '5px' }}
                                                        id="clientMovein-Date"
                                                        name="clientMoveinDate"
                                                        value={data?.clientMoveinDate ? moment(data?.clientMoveinDate).format('YYYY-MM-DD') : ""}
                                                        onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}
                                                        label="Client Move in Date"
                                                        type="date"
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        variant="outlined"
                                                        size="small"
                                                        disabled={leadDetail.salesLeadOwnRole === 'SalesUser' || leadDetail.salesLeadOwnRole === 'SalesManager'  || userDetails.roles.find(role => role.name === ROLENAME.CenterHead)}
                                                    />
                                                </FormControl>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow key='17'>
                                            <TableCell style={{ padding: '5px' }} component="th" scope="row">Select the Lead Won Date</TableCell>
                                            <TableCell style={{ padding: '5px' }} component="th" scope="row">
                                                {
                                                     data?.leadWonDate || userDetails.roles.find(role => role.name === ROLENAME.SalesUser || role.name === ROLENAME.SalesManager || role.name === ROLENAME.AssistantSalesManager || role.name === ROLENAME.SalesHead) ?
                                                        <FormControl style={{ width: 300 }}>
                                                            <TextField
                                                                style={{ margin: '5px' }}
                                                                id="leadWon-Date"
                                                                name="leadWonDate"
                                                                value={data?.leadWonDate ? moment(data?.leadWonDate).format('YYYY-MM-DD') : ""}
                                                                onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}
                                                                label="Lead Won Date"
                                                                type="date"
                                                                InputLabelProps={{
                                                                    shrink: true
                                                                }}
                                                                variant="outlined"
                                                                size="small"
                                                                disabled={leadDetail.salesLeadOwnRole === 'SalesUser' || leadDetail.salesLeadOwnRole === 'SalesManager' || userDetails.roles.find(role => role.name === ROLENAME.CenterHead)}
                                                                inputProps={{
                                                                    max: moment().format('YYYY-MM-DD'),
                                                                    onKeyDown: (e) => e.preventDefault() 
                                                                }}
                                                            />
                                                        </FormControl>
                                                        :
                                                        'NA'
                                                }
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <hr />
                            <br />
                            <Typography variant="h5" component="h2"><strong>Quotation Primary Data</strong></Typography>

                            <TableContainer component={Paper} style={{ width: '100%' }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableBody>
                                    <TableRow key='11a'>
                                        <TableCell style={{ padding: '5px' }} component="th" scope="row">Upload Sales Quotation</TableCell>
                                        <TableCell style={{ padding: '5px' }} component="th" scope="row">
                                        <Button 
                                    onClick={handleClickOpenDialog} 
                                    variant="contained" color="primary" 
                                    disabled={
                                        leadDetail.salesLeadOwnRole === 'SalesUser' || 
                                        leadDetail.salesLeadOwnRole === 'SalesManager' || 
                                        userDetails.roles.some(role => role.name === ROLENAME.CenterHead)
                                    }
                                >
                                    Select Quotation
                                </Button>
                                {"     "}
                                <span>{leadDetail?.version}</span>

                                        </TableCell>

                                        <Dialog open={openDialog} onClose={handleCloseDialog}>
                                            <DialogTitle>Quotation Details</DialogTitle>
                                            <DialogContent>
                                            <Table>
                                                <TableBody>
                                                <TableCell style={{ padding: '5px' }} component="th" scope="row">S.No</TableCell>
                                                <TableCell style={{ padding: '5px' }} component="th" scope="row">Version</TableCell>
                                                <TableCell style={{ padding: '5px' }} component="th" scope="row">Created Date</TableCell>
                                                <TableCell style={{ padding: '5px' }} component="th" scope="row">Created By</TableCell>
                                                <TableCell style={{ padding: '5px' }} component="th" scope="row">Total Customer OutFlow</TableCell>
                                                <TableCell style={{ padding: '5px' }} component="th" scope="row">Select Quotation </TableCell>

                                                {storeSalesQuotation.map((quotation, index) => (
                                                    <TableRow key={index}>
                                                    <TableCell style={{ padding: '5px' }} component="th" scope="row">{index+1}</TableCell>
                                                    <TableCell style={{ padding: '5px' }} component="th" scope="row">{quotation.version}</TableCell>
                                                    <TableCell style={{ padding: '5px' }} component="th" scope="row">{moment(quotation.createdAt).format('YYYY-MM-DD')}</TableCell>
                                                    <TableCell style={{ padding: '5px' }} component="th" scope="row">{quotation.createdBy.name}</TableCell>
                                                    <TableCell style={{ padding: '5px' }} component="th" scope="row">{quotation.totalCustomerOutflow}</TableCell>
                                                    <TableCell style={{ padding: '5px' }}>
                                                    <Checkbox checked={leadDetail.version === quotation.version} onChange={(event) => handleCheckboxChange(event, quotation)} />
                                                    </TableCell>
                                                    </TableRow>
                                                ))}
                                                </TableBody>
                                            </Table>
                                            </DialogContent>
                                            <DialogActions>
                                            <Button onClick={handleCloseDialog}>Close</Button>
                                            </DialogActions>
                                        </Dialog>
                                    </TableRow>
                                        <TableRow key='8'>
                                            <TableCell style={{ padding: '5px' }} component="th" scope="row">Tentative Sales Quotation</TableCell>
                                            <TableCell style={{ padding: '5px' }} component="th" scope="row">₹ {commaSeparateAmount(grandTotal)}</TableCell>
                                        </TableRow>
                                        <TableRow key='9'>
                                            <TableCell style={{ padding: '5px' }} component="th" scope="row">Disc agreed</TableCell>
                                            <TableCell style={{ padding: '5px' }} component="th" scope="row">{discountPercent}</TableCell>
                                        </TableRow>
                                        <TableRow key='10'>
                                            <TableCell style={{ padding: '5px' }} component="th" scope="row">Total Amount Paid</TableCell>
                                            <TableCell style={{ padding: '5px' }} component="th" scope="row">
                                                <FormControl style={{ width: 300 }}>
                                                    <TextField style={{ margin: '5px' }} size="small" id="outlined-basic" label="Amount Paid" value={paymentDone} variant="outlined" name="paymentDone" onChange={changeHandler} type='number'
                                                        disabled={leadDetail.salesLeadOwnRole === 'SalesUser' || leadDetail.salesLeadOwnRole === 'SalesManager' || userDetails.roles.find(role => role.name === ROLENAME.CenterHead)} />
                                                </FormControl>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow key='11'>
                                            <TableCell style={{ padding: '5px' }} component="th" scope="row">Upload Payment Attachment</TableCell>
                                            <TableCell style={{ padding: '5px' }} component="th" scope="row">
                                                <FormControl style={{ marginLeft: 10, marginRight: 10, marginTop: 20, marginBottom: 10, display: 'block' }}>
                                                    {
                                                        userDetails.roles.find(role => role.name === ROLENAME.SalesUser || role.name === ROLENAME.SalesManager || role.name === ROLENAME.AssistantSalesManager|| role.name === ROLENAME.SalesHead ) && <>
                                                            <input
                                                                accept="*"
                                                                className={classes.input}
                                                                id="contained-button-file"
                                                                multiple
                                                                type="file"
                                                                onChange={onImageChange}
                                                                style={{ display: 'none' }}
                                                            />
                                                            <label htmlFor="contained-button-file">
                                                                <Button variant="contained" color="primary" 
                                                                    disabled={leadDetail.salesLeadOwnRole === 'SalesUser' || leadDetail.salesLeadOwnRole === 'SalesManager'} 
                                                                    component="span" startIcon={<CloudUploadIcon />}>
                                                                    Upload Payment Proof
                                                                </Button>
                                                                {
                                                                    fileUploaded && <CheckCircleIcon style={{ verticalAlign: 'middle', marginLeft: 10, color: "green", fontSize: 30 }} />
                                                                }
                                                            </label>
                                                        </>
                                                    }
                                                    {
                                                        userDetails.roles.find(role => role.name === ROLENAME.SalesManager || role.name === ROLENAME.CenterHead || role.name === ROLENAME.AssistantSalesManager) &&
                                                        <Button color="primary" variant="outlined" onClick={() => window.open(leadDetail?.salesPaymentProofAtachement)} style={{ marginLeft: 10 }} disabled={!leadDetail?.salesPaymentProofAtachement || leadDetail?.salesPaymentProofAtachement === 'NA'}>
                                                            View Payment
                                                        </Button>
                                                    }
                                                </FormControl>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow key='12'>
                                            <TableCell style={{ padding: '5px' }} component="th" scope="row">% Amount Paid</TableCell>
                                            <TableCell style={{ padding: '5px' }} component="th" scope="row">
                                                <FormControl style={{ width: 300 }}>
                                                    <TextField style={{ margin: '5px' }} size="small" id="outlined-basic" label="Token %" value={((paymentDone / grandTotal) * 100).toFixed(2)} variant="outlined" name="tokenPercent" onChange={changeHandler} disabled />
                                                </FormControl>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <hr />
                            <br />
                            {(userDetails?.roles?.find(role => role.name === ROLENAME.SalesUser) && (complimentaryItemPresent && (leadDetail.complimentaryItemStatus === "Approved" || leadDetail.complimentaryItemStatus === "Sent for Approval" || leadDetail.complimentaryItemStatus === "Rejected"))) && (
                                <>
                                    <Paper>
                                        <Card className={classes.root} variant='outlined'>
                                            <CardContent>
                                                <Typography variant='h5' component="h2"><strong>Complimentary Items</strong></Typography>

                                                <TableContainer component={Paper} className={classes.container}>
                                                    <Table className={classes.table} stickyHeader aria-label='sticky table'>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell><strong>Space Name</strong></TableCell>
                                                                <TableCell><strong>Complimentary Items</strong></TableCell>
                                                                <TableCell><strong>Area(SqFt)/Quantity</strong></TableCell>
                                                            </TableRow>
                                                        </TableHead>

                                                        <TableBody>
                                                            {spaceAndProductNames.map((row, i) => (
                                                                <TableRow key={i + 1}>
                                                                    <TableCell>{row.spaceName}</TableCell>
                                                                    <TableCell>
                                                                        {row.productNames.map((product, j) => (
                                                                            <div key={j}>
                                                                                <span>{product.name}</span>
                                                                            </div>
                                                                        ))}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {row.productNames.map((product, j) => (
                                                                            <div key={j}>
                                                                                <span>{product.area}</span>
                                                                            </div>
                                                                        ))}
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </CardContent>
                                        </Card>
                                    </Paper>
                                </>
                            )}
                            {(userDetails?.roles?.find(role => role.name === ROLENAME.SalesManager || role.name === ROLENAME.CenterHead) && leadDetail.salesComplimentaryItems?.length > 0 && (leadDetail.complimentaryItemStatus === "Approved" || leadDetail.complimentaryItemStatus === "Sent for Approval" || leadDetail.complimentaryItemStatus === "Rejected")) &&
                                <>
                                    <Paper>
                                        <Card className={classes.root} variant='outilned'>
                                            <CardContent>
                                                <Typography variant='h5' component="h2"><strong>Complimentary Items</strong></Typography>

                                                <TableContainer component={Paper} className={classes.container}>
                                                    <Table className={classes.table} stickyHeader aria-label='sticky table'>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell><strong>Space Name</strong></TableCell>
                                                                <TableCell><strong>Complimentary Items</strong></TableCell>
                                                                <TableCell><strong>Area(SqFt)/Quantity</strong></TableCell>
                                                            </TableRow>
                                                        </TableHead>

                                                        <TableBody>
                                                            {leadDetail.salesComplimentaryItems.map((row, i) => {
                                                                return (
                                                                    <TableRow key={i + 1}>
                                                                        <TableCell>{row.spaceName}</TableCell>
                                                                        <TableCell>
                                                                            {row.productNames.map((product, j) => (
                                                                                <div key={j}>
                                                                                    <TableCell>{product.name}</TableCell>
                                                                                </div>
                                                                            ))}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {row.productNames.map((product, j) => (
                                                                                <div key={j}>
                                                                                    <TableCell>{product.area}</TableCell>
                                                                                </div>
                                                                            ))}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )
                                                            })}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </CardContent>

                                        </Card>
                                    </Paper>
                                </>
                            }
                            <hr />
                            <br />

                            {/* Data to Show If Check-List are not default or not as per the quotation  */}
                            {/*
                            {(userDetails?.roles?.find(role => role.name === ROLENAME.CenterHead)) &&
                                <>
                                    <Typography variant='h5' component="h2"><strong>Not In Default Check</strong></Typography>

                                    <TableContainer component={Paper} className={classes.container}>
                                        <Table className={classes.table} stickyHeader aria-label='sticky table'>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell><strong>Description</strong></TableCell>
                                                    <TableCell><strong>Action Selected</strong></TableCell>
                                                    <TableCell><strong>Action Expected</strong></TableCell>
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                {leadDetail?.defaultCheckDescription?.map((row, i) => {
                                                    return (
                                                        <TableRow key={i}>
                                                            <TableCell component={"th"}>{row?.description}</TableCell>
                                                            <TableCell component={"th"}>
                                                                {row?.actionSelected}
                                                            </TableCell>
                                                            <TableCell component={"th"}>
                                                                {row?.valueExpected.join(" | ")}
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <hr />
                                </>
                            }
                            <br></br>
                            */}

                            <Typography variant="h5" component="h2"><strong>Quotation Inclusion/Exclusion</strong></Typography>

                            <TableContainer component={Paper} className={classes.container}>
                                <Table className={classes.table} stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>Sr.No.</TableCell>
                                            <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>Description</TableCell>
                                            <RadioGroup defaultValue="abc" aria-label="action" name="customized-radios" style={{ display: 'table-cell', top: 0, left: 0, zIndex: 2, position: 'sticky', backgroundColor: "#fafafa" }}>
                                                <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>Yes</TableCell>
                                                <TableCell style={{ textAlign: 'center', fontWeight: 700,width:"58.6px",height:"58px"}}>No</TableCell>
                                                <TableCell style={{ textAlign: 'center', fontWeight: 700 ,width:"58.6px",height:"58px" }}>NA</TableCell>
                                            </RadioGroup>
                                            {/* <TableCell>Action</TableCell> */}
                                            {/* {userDetails.roles.find(role => role.name === ROLENAME.CenterHead) &&
                                                <RadioGroup defaultValue="abc" aria-label="action" name="customized-radios" style={{ display: 'table-cell', top: 0, left: 0, zIndex: 2, position: 'sticky', backgroundColor: "#fafafa" }}>
                                                    <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>Accept</TableCell>
                                                    <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>Reject</TableCell>
                                                </RadioGroup>
                                            } */}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {checkList.sort().map((row, i) => {
                                            const labelId = `enhanced-table-checkbox-${i}`;
                                            return (
                                                <TableRow key={i + 1}>
                                                    <TableCell style={{ padding: '5px', textAlign: 'center' }}>{row?.id}</TableCell>
                                                    <TableCell style={{ padding: '5px', color:`${checkIfPresentHandler(row?.description) ? "Red" : ""}`,fontWeight: `${checkIfPresentHandler(row?.description) ? "700" : ""}`}} component="th" scope="row">{row?.description}</TableCell>
                                                    <RadioGroup aria-label='option' name="customized-radios"
                                                        value={row?.actionSelected}
                                                        onChange={(e) => handleRadioButtonClick(e, i, row)}
                                                        style={{ display: 'block' }}>
                                                        <TableCell style={{ padding: '6px', textAlign: 'center', width: "58.6px", height: "58px" }} component="th">
                                                            {(row.id === "1" || row.id === "2" || row.id === "3" || row.id === "1(a)" || row.id === "1(b)") ? <Radio
                                                                value='yes'
                                                                checked={row?.actionSelected === 'yes'}
                                                                name="radio-button-demo"
                                                                inputProps={{ 'aria-label': labelId }}
                                                                disabled={leadDetail.salesLeadOwnRole === 'SalesUser' || leadDetail.salesLeadOwnRole === 'SalesManager' || userDetails.roles.find(role => role.name === ROLENAME.CenterHead)}
                                                            /> : <Radio
                                                                value='yes'
                                                                name="radio-button-demo"
                                                                inputProps={{ 'aria-label': labelId }}
                                                                checked='cheked'
                                                                disabled={leadDetail.salesLeadOwnRole === 'SalesUser' || leadDetail.salesLeadOwnRole === 'SalesManager' || userDetails.roles.find(role => role.name === ROLENAME.CenterHead)}
                                                            />}
                                                        </TableCell>
                                                        <TableCell style={{ padding: '6px', textAlign: 'center', width: "58.6px", height: "58px" }} component="th">
                                                            <Radio
                                                                value='no'
                                                                checked={row?.actionSelected === 'no'}
                                                                name="radio-button-demo"
                                                                inputProps={{ 'aria-label': labelId }}
                                                                disabled={leadDetail.salesLeadOwnRole === 'SalesUser' || leadDetail.salesLeadOwnRole === 'SalesManager' || userDetails.roles.find(role => role.name === ROLENAME.CenterHead || row.id === "5" || row.id === "4" || row.id === "6")}
                                                            />
                                                        </TableCell>
                                                        <TableCell style={{ padding: '6px', textAlign: 'center', width: "58.6px", height: "58px" }} component="th">
                                                            <Radio
                                                                value='na'
                                                                checked={row?.actionSelected === 'na'}
                                                                name="radio-button-demo"
                                                                inputProps={{ 'aria-label': labelId }}
                                                                disabled={leadDetail.salesLeadOwnRole === 'SalesUser' || leadDetail.salesLeadOwnRole === 'SalesManager' || userDetails.roles.find(role => role.name === ROLENAME.CenterHead || row.id === "5" || row.id === "4" || row.id === "6")}
                                                            />
                                                        </TableCell>
                                                    </RadioGroup>

                                                    {/* {userDetails.roles.find(role => role.name === ROLENAME.CenterHead) &&
                                                        <RadioGroup aria-label={{ 'aria-label': labelId + 1 }}
                                                            name="customized-radios-customer"
                                                            value={row?.customerSelected}
                                                            style={{ display: 'table-cell' }}>

                                                            <TableCell style={{ padding: '6px', textAlign: 'center', width: 81 }} component="th">
                                                                <Radio
                                                                    value='yes'
                                                                    checked={row?.customerAction === 'yes'}
                                                                    name="radio-button-demo"
                                                                    disabled
                                                                    inputProps={{ 'aria-label': labelId }}
                                                                />
                                                            </TableCell>
                                                            <TableCell style={{ padding: '6px', textAlign: 'center', width: 81 }} component="th">
                                                                <Radio
                                                                    value='no'
                                                                    checked={row?.customerAction === 'no'}
                                                                    name="radio-button-demo"
                                                                    disabled
                                                                    inputProps={{ 'aria-label': labelId }}
                                                                />
                                                            </TableCell>
                                                        </RadioGroup>
                                                    } */}
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <hr />
                            <br />

                            {/* Remarks component to show to center head when remarks are added by sales-user | sales-manager */}
                            {userDetails.roles.find(role => role.name === ROLENAME.CenterHead) && latestTwoRemarks &&
                                <TableContainer>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>User </TableCell>
                                                <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>Remarks </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {latestTwoRemarks.map((data, i) => (
                                                data?.dealActivityAfterColon?.length > 0 && // Move the condition here
                                                <TableRow key={i + 1}>
                                                    <TableCell style={{ padding: '5px', width: 80, textAlign: 'center' }} component="th" scope="row">{data.userName}</TableCell>
                                                    <TableCell style={{ padding: '5px' }} component="th" scope="row">{data.dealActivityAfterColon}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            }
                            <hr />
                            <br />
                            {userDetails.roles.find(role => role.name !== ROLENAME.CenterHead) && 
                            <>
                            <Typography variant="h5" component="h2"><strong>Remarks</strong></Typography>

                            <FormControl style={{ width: 300, paddingTop: 10 }}>
                                <TextField
                                    id="outlined-multiline-flexible"
                                    label="Enter Remark"
                                    multiline
                                    maxRows={4}
                                    value={checkListRemarkBySalesUser}
                                    name="checkListRemarkBySalesUser"
                                    onChange={changeHandler}
                                    variant="outlined"
                                    disabled={leadDetail.salesLeadOwnRole === 'SalesUser' || leadDetail.salesLeadOwnRole === 'SalesManager' || userDetails.roles.find(role => role.name === ROLENAME.CenterHead)}
                                />
                            </FormControl>
                            </>}

                            <hr />
                            <br />
                            <Typography variant="h5" component="h2"><strong>Notes</strong></Typography>
                            <TableContainer component={Paper} style={{ width: '100%' }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableBody>
                                        {
                                            notes.map((data, i) => (
                                                <TableRow key={i + 1}>
                                                    <TableCell style={{ padding: '5px', width: 80, textAlign: 'center' }} component="th" scope="row">{data.id}</TableCell>
                                                    <TableCell style={{ padding: '5px' }} component="th" scope="row">{data.value}</TableCell>
                                                </TableRow>
                                            ))
                                        }
                                        <TableRow key="tc">
                                            <TableCell style={{ padding: '5px', width: 80, textAlign: 'center' }} component="th" scope="row">{notes.length - 2}</TableCell>
                                            <TableCell style={{ padding: '5px' }} component="th" scope="row">
                                                For more terms and conditions, please visit <a href="/terms-and-condition" target="_blank" style={{ color: "blue", textDecoration: 'underline' }}>link</a>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            {/*<hr />
                            <br />
                            <Typography variant="h5" component="h2"><strong>Other Terms And Conditions</strong></Typography>
                            <TableContainer component={Paper} style={{ width: '100%' }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableBody>
                                        {
                                            termsAndCondition.map((data, i) => (
                                                <TableRow key={i + 1}>
                                                    <TableCell style={{ padding: '5px' }} component="th" scope="row">{data.id}</TableCell>
                                                    <TableCell style={{ padding: '5px' }} component="th" scope="row">{data.description}</TableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <hr />
                            <br />
                            <FormControl>
                                <p>If the mirror fixing is preferred without back ply support no gurantee is provided</p>
                                <TextField style={{ margin: '5px' }} size="small" id="outlined-basic" variant="outlined" name="backPlySupport" value={backPlySupport} onChange={changeHandler} disabled={userDetails.roles.find(role => role.name === ROLENAME.CenterHead || role.name === ROLENAME.SalesManager)} />
                            </FormControl> */}
                        </CardContent>
                    </Card>

                    <div style={{ marginTop: '20px', marginBottom: '20px', textAlign: 'center' }}>
                        {
                            (leadDetail.salesLeadOwnRole === 'SalesUser' || leadDetail.salesLeadOwnRole === 'SalesManager') && userDetails.roles.find(role => role.name === ROLENAME.CenterHead) && <>
                                <Button onClick={() => approveStatus("reject")} variant="outlined" disabled={isDisabled} style={{ marginRight: '30px' }}>Reject</Button>
                                <Button onClick={() => approveStatus("approve")} disabled={isDisabled} variant="contained" color="primary">Approve</Button>
                            </>
                        }
                        {
                            (leadDetail.salesLeadOwnRole === 'SalesUser' || leadDetail.salesLeadOwnRole === 'SalesManager') && userDetails.roles.find(role => role.name === ROLENAME.SalesManager || role.name === ROLENAME.AssistantSalesManager || role.name === "Sales Head") && (
                                <>
                                    <Button onClick={() => setOpenDesignManger(true)} disabled={isDisabled} variant="contained" color="primary">
                                        View
                                    </Button>
                                </>
                            )
                        }
                        {
                            invalidEmail && <>
                                <div style={{ marginBottom: 5, fontWeight: 700 }}>Please update the valid customer email id</div> <br />
                            </>
                        }
                        {
                            (leadDetail.salesLeadOwnRole === 'NA') && userDetails.roles.find(role => role.name === ROLENAME.SalesManager || role.name === ROLENAME.SalesUser || role.name === ROLENAME.AssistantSalesManager|| role.name === ROLENAME.SalesHead) && <>
                                <Button onClick={() => proceedToSalesManager("No")} variant="outlined" style={{ marginRight: '30px' }}>No</Button>
                                <Button onClick={() => proceedToSalesManager("Yes")} disabled={clicked || btnDisable && !isEditable || invalidEmail || !data.closureDate || !data.possessionDate || !data.leadWonDate || !data.clientMoveinDate || leadDetail.version === "" } variant="contained" color="primary">Yes</Button>
                            </>
                        }

                    </div>
                    <br />
                </Paper>
            </div>
            <Dialog open={openDesignManager} onClose={() => setOpenDesignManger(false)} aria-labelledby="form-dialog-title" maxWidth='sm'>
                <DialogTitle id="form-dialog-title">
                    {(userDetails.roles.find(role => role.name === ROLENAME.CenterHead) && selectedStatus === 'approve') ? `Select Design Head and Enter Remark` : `Enter Remark`}
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <div style={{ paddingBottom: 15 }}>
                        {/* {
                            (userDetails.roles.find(role => role.name === ROLENAME.CenterHead) && selectedStatus === 'approve') &&
                            <FormControl fullWidth style={{ marginBottom: 20 }}>
                                <TextField
                                    select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name="designManager"
                                    label="Select Design Head"
                                    onChange={setDesignManager}
                                    variant="outlined"
                                    size="small"
                                >
                                    {
                                        designManagers?.map((data, i) => (
                                            <MenuItem value={data._id} key={i + 1}>{data.name}</MenuItem>
                                        ))
                                    }
                                </TextField>
                            </FormControl>
                        } */}
                        <FormControl fullWidth >
                            <TextField
                                id="outlined-multiline-static"
                                label="Enter Remark"
                                multiline
                                rows={4}
                                // onChange={(e) => setRemark(e.target.value)}
                                onChange={changeHandler}
                                name="remark"
                                variant="outlined"
                                size="small"
                                className={classes.textFieldWidth}
                            />
                        </FormControl>
                        
                        {userDetails.roles.find(role => role.name === ROLENAME.SalesManager || role.name === ROLENAME.AssistantSalesManager || role.name === ROLENAME.SalesHead) &&
                            <div style={{ marginBottom: 20 }}>
                                {/* <FormControl style={{ marginLeft: 10, marginRight: 10, marginTop: 20, marginBottom: 10 }}>
                                    <TextField id="outlined-basic" label="Quotation Value" value={grandTotal} variant="outlined" name="grandTotal" onChange={changeHandler} disabled />
                                </FormControl>
                                <FormControl style={{ marginLeft: 10, marginRight: 10, marginTop: 20, marginBottom: 10 }}>
                                    <TextField id="outlined-basic" label="Amount Paid" value={paymentDone} variant="outlined" name="paymentDone" onChange={changeHandler} />
                                </FormControl>
                                <FormControl style={{ marginLeft: 10, marginRight: 10, marginTop: 20, marginBottom: 10 }}>
                                    <TextField id="outlined-basic" label="Token %" value={((paymentDone / grandTotal) * 100).toFixed(2)} variant="outlined" name="tokenPercent" onChange={changeHandler} />
                                </FormControl>
                                <FormControl style={{ marginLeft: 10, marginRight: 10, marginTop: 20, marginBottom: 10 }}>
                                    <TextField id="outlined-basic" label="Account Name" variant="outlined" name="accountName" onChange={changeHandler} />
                                </FormControl>
                                <FormControl style={{ marginLeft: 10, marginRight: 10, marginTop: 20, marginBottom: 10 }}>
                                    <TextField id="outlined-basic" label="Account Through" variant="outlined" name="accountThrough" onChange={changeHandler} />
                                </FormControl>
                                <FormControl style={{ marginLeft: 10, marginRight: 10, marginTop: 20, marginBottom: 10 }}>
                                    <TextField id="outlined-basic" label="On Account Of" variant="outlined" name="onAccountOf" onChange={changeHandler} />
                                </FormControl>
                                <FormControl style={{ marginLeft: 10, marginRight: 10, marginTop: 20, marginBottom: 10, display: 'block' }}>
                                    {
                                        userDetails.roles.find(role => role.name === ROLENAME.SalesManager || role.name === ROLENAME.AssistantSalesManager) && <>
                                            <input
                                                accept="image/*"
                                                className={classes.input}
                                                id="contained-button-file"
                                                multiple
                                                type="file"
                                                onChange={onImageChange}
                                                style={{ display: 'none' }}
                                            />
                                            <label htmlFor="contained-button-file">
                                                <Button variant="contained" color="primary" component="span" startIcon={<CloudUploadIcon />}>
                                                    Upload Payment Proof
                                                </Button>
                                                {
                                                    fileUploaded && <CheckCircleIcon style={{ verticalAlign: 'middle', marginLeft: 10, color: "green", fontSize: 30 }} />
                                                }
                                            </label>
                                        </>
                                    }
                                </FormControl> */}
                                <FormControl style={{ marginLeft: 10, marginRight: 10, marginTop: 20, marginBottom: 10 }}>
                                    <TextField id="outlined-basic" label="Lead Won Date" value={data?.leadWonDate ? moment(data?.leadWonDate).format('D MMMM, YYYY') : ""} variant="outlined" name="LeadWonDte" onChange={changeHandler} disabled />
                                </FormControl>

                                <FormControl component="fieldset" style={{ marginLeft: 10, marginRight: 100, marginTop: 20, marginBottom: 10 }}>
                                    <FormLabel component="legend">Is Lead Won Date Correct?</FormLabel>
                                    <RadioGroup row aria-label="wondate-value" name="wondate-value" value={wonDateValue} onChange={radiochangeHandler1}>
                                        <FormControlLabel value="leadwon-yes" control={<Radio />} label="Yes" name="leadwonYes" />
                                        <FormControlLabel value="leadwon-no" control={<Radio />} label="No" name="leadwonNo" />
                                    </RadioGroup>
                                </FormControl>

                                <FormControl style={{ marginLeft: 10, marginRight: 10, marginTop: 20, marginBottom: 10 }}>
                                    <TextField id="outlined-basic" label="Customer Email ID" value={leadDetail?.customerId?.email} variant="outlined" name="LeadWonDte" onChange={changeHandler} disabled />
                                </FormControl>

                                <FormControl component="fieldsets" style={{ marginLeft: 10, marginRight: 10, marginTop: 20, marginBottom: 10 }}>
                                    <FormLabel component="legend">Is Client Email ID Correct?</FormLabel>
                                    <RadioGroup row aria-label="email-value" name="email-value" value={emailValue} onChange={radiochangeHandler2}>
                                        <FormControlLabel value="email-yes" control={<Radio />} label="Yes" name="EmailYes" />
                                        <FormControlLabel value="email-no" control={<Radio />} label="No" name="EmailNo" />
                                    </RadioGroup>
                                </FormControl>

                                <FormControl component="fieldsets" style={{ marginLeft: 10, marginRight: 10, marginTop: 20, marginBottom: 10 }}>
                                <FormLabel component="legend">Tentative Client Move-in Date is <strong> {daysDifference !== null ? daysDifference : ''} </strong> days from Design Sign off  </FormLabel>
                                <RadioGroup row aria-label="diff-value" name="diff-value" value={Daysdiff} onChange={radiochangeHandler3} style={{ marginLeft: 30 }}>
                                            <FormControlLabel value="diff-yes" control={<Radio />} label="Yes" name="diffYes" />
                                            <FormControlLabel value="diff-no" control={<Radio />} label="No" name="diffNo" />
                                        </RadioGroup>
                                </FormControl>

                                {/* <FormControl style={{ marginLeft: 5, marginRight: 5, marginTop: 5, marginBottom: 5 }}>
                                    <div style={{ display: 'flex' }}>
                                        <TextField
                                            id="outlined-basic"
                                            label="Days difference"
                                            value={daysDifference !== null ? daysDifference : ''}
                                            variant="outlined"
                                            name="DaysDiff"
                                            onChange={changeHandler}
                                            disabled
                                        />
                                        <RadioGroup row aria-label="diff-value" name="diff-value" value={Daysdiff} onChange={radiochangeHandler3} style={{ marginLeft: 30 }}>
                                            <FormControlLabel value="diff-yes" control={<Radio />} label="Yes" name="diffYes" />
                                            <FormControlLabel value="diff-no" control={<Radio />} label="No" name="diffNo" />
                                        </RadioGroup>
                                    </div>
                                </FormControl> */}
                            </div>
                        }

                    </div>
                </DialogContent>
                <Divider />
                <DialogActions>
                    {/* <Button onClick={() => setOpenDesignManger(false)} color="secondary" variant='outlined'>
                        Cancel
                    </Button> */}
                    {
                        (userDetails.roles.find(role => role.name === ROLENAME.CenterHead) && selectedStatus === 'approve') ?
                            (
                                <>
                                    <Button onClick={() => setOpenDesignManger(false)} color="secondary" variant='outlined'>Cancel</Button>
                                    <Button onClick={() => updateDesginManagerToLead()} color="primary" variant='contained'>Assign Design Manager</Button>
                                </>
                            )
                            :
                            (
                                <>
                                    <Button onClick={() => setOpenDesignManger(false)} color="secondary" variant='outlined'>Cancel</Button>
                                    <Button onClick={() => updateToLead("reject")} variant="outlined" disabled={isDisabled} style={{ marginRight: '30px' }}>Reject</Button>
                                    <Button onClick={() => updateToLead('approve')} disabled={wonDateValue !== 'leadwon-yes' || emailValue !== 'email-yes' || Daysdiff !== 'diff-yes'} color="primary" variant='contained'>Approve</Button>
                                </>
                            )
                    }
                </DialogActions>
            </Dialog>
        </div >
    )
}

export default connect(null, { setLoading, setSnackInfo })(CheckLists)