import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import './index.css'
import HttpService from '../../../services/httpService';
import { makeStyles } from '@material-ui/core/styles';
import { connect, useSelector } from "react-redux";
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { setLoading, setSnackInfo } from '../../../redux/actions/appAction';
import { getApprovedProjects } from '../../../redux/actions/financeAction'
import {Button, Grid } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
		root: {
			width: '100%'
		},
		heading:{
			margin: 'auto',
			textAlign: "center", 
			color: "black",
			fontWeight: 'normal'
		},
		table: {
			minWidth: 700
		},
		projectInput:{
		width: "50%",
		margin: 'auto',
		marginBottom: 10,
		borderRadius: 50
			},
		sawFileButton: {
			backgroundColor: '#141d5c',
			width: '200px',
			marginLeft: '10px',
			 borderRadius: 50,
			 "&:hover": {
				backgroundColor: "white",
			 color: '#141d5c'
			}},
		excelFileButton: {
			backgroundColor: '#19571b',
			width: '200px',
			marginRight: '10px',
			 borderRadius: 50,
			  "&:hover": 
			  {
				backgroundColor: "white",
				color: '#19571b'
			}},
		uploadButton: {
			backgroundColor:"#2C2E3E",
			margin: 'auto', height: '5%', width: "10%", marginTop: 20, borderRadius: 50,
			"&:hover": {backgroundColor: "white",color: '#2C2E3E'}
			},
			textField: {
				marginLeft: theme.spacing(1),
				marginRight: theme.spacing(1),
				width: 200,
			},
}))
const Index = (props) =>
{
	const classes = useStyles();
	const [approvedProjects, setApprovedProjects] = useState([]);
	const [selectedProject, setSelectedProject] = useState([])
	const [projectNo, setProjectNumber] = useState("");
	const [uploadedFileLink, setUploadedFileLink] = useState({excelFileLink: null, sawFileLink: null})
	const { userDetails } = useSelector(state => state.user)

	useEffect(() => {
		getApprovedProjects()
	}, [])

	const getApprovedProjects = async () => {
		try {
			props.setLoading(true)
			const response = await HttpService.getApprovedProjects()
			setApprovedProjects(response.data)
			props.setLoading(false)
		} catch (error) {
			props.setLoading(false)
			props.setSnackInfo(error.response.data.message, "error")
		}
	}

	const handleAddProject = async (event, value) => {
		props.setLoading(true)
		try
		{
			setSelectedProject(value)
		}
		catch (error)
		{
			props.setSnackInfo('Some error during Project selection', 'error')
		}
		props.setLoading(false)
	}

	const handleChooseFile = async (event) =>
	{
		try
		{
			const formData = new FormData()
			formData.append('file', event.target.files[0])
			formData.append('customerName', `OptimisationFile`)

			const response = await HttpService.uploadFile(formData)

			switch(event.target.id)
			{
				case 'excel_file':
					setUploadedFileLink({...uploadedFileLink, excelFileLink: response.data})
					break;
				case 'saw_file':
					setUploadedFileLink({...uploadedFileLink, sawFileLink: response.data})
					break;
			}
		}
		catch(error)
		{
			props.setSnackInfo('Some error during File Upload', "error")
		}
	}

	const handleFileUpload = async () => {
		props.setLoading(true)
		try
		{
			if(selectedProject.length === 0)
			{
				props.setSnackInfo('Please Tag Projects', "error")
				props.setLoading(false)
				return
			}

			if(!uploadedFileLink.excelFileLink && !uploadedFileLink.sawFileLink)
			{
				props.setSnackInfo('Please upload Optimisation File', "error")
				props.setLoading(false)
				return
			}

			let projects = []

			selectedProject.forEach(el=>projects.push(el._id))

			let data =
			{
				projects : projects,
				excelFileLink: uploadedFileLink.excelFileLink ? uploadedFileLink.excelFileLink : "",
				sawFileLink: uploadedFileLink.sawFileLink ? uploadedFileLink.sawFileLink : "",
				uploadedBy: userDetails._id,
				optimizationNumber: projectNo.optimizationNumber
			}
			let response = await HttpService.updateOptimisationFiles(data)

			if(response.status == 200)
			{
				setSelectedProject([])
				setProjectNumber("")
				setUploadedFileLink({...uploadedFileLink, excelFileLink: null, sawFileLink: null})
				props.setSnackInfo('File Uploaded Successfully', "success")
			}
		}
		catch(error)
		{
			props.setSnackInfo('Some error during File Updation in DB', "error")
			
		}
		props.setLoading(false)
	  };


	return (
		<>
			<h1 className={classes.heading}>Note: Upload Optimisation Files with their respective Projects</h1>
			<div style={{ display: "flex", justifyContent: "space-between", paddingBottom: 20, marginTop: 10 }}>
				
				<Autocomplete
					multiple
					className={classes.projectInput}
					value={selectedProject}
					onChange={(e, v) => handleAddProject(e, v)}
					options={approvedProjects}
					getOptionLabel={(option) => option.searchName}
					renderInput={(params) => <TextField {...params} label="Tag Projects" fullWidth variant="outlined" />}
				/>
			</div>
			<div style={{ display: "flex", justifyContent: "space-between", paddingBottom: 20, marginTop: 10 }}>
			<TextField label="Unique Optimization Number"
			className={classes.projectInput}
			type="text" 
			size="small" 
			style={{ marginBottom: 20, textAlign: "center" }}
			name="optimizationNumber"
			onChange={(e) => setProjectNumber({ [e.target.name]: e.target.value })} 
			variant="outlined" />

			</div>
			<Grid container justify="center">
				<input
					accept=".xlsx, .xls, .csv"
					style={{ display: 'none' }}
					id="excel_file"
					type="file"
					onChange={(e)=>handleChooseFile(e)}
				/>
				<label htmlFor="excel_file">
					<Button className={classes.excelFileButton} variant="contained" color="primary" component="span">
					Excel File
					</Button>
					{
						uploadedFileLink.excelFileLink && <CheckCircleIcon style={{ verticalAlign: 'middle', marginRight: 10, color: "green", fontSize: 30 }} />

					}
				</label>
				<input
					accept=".saw"
					style={{ display: 'none' }}
					id="saw_file"
					type="file"
					onChange={(e)=>handleChooseFile(e)}
				/>
				<label htmlFor="saw_file">
				<Button className={classes.sawFileButton} variant="contained" color="primary" component="span">
					Saw File
					</Button>
					{
						uploadedFileLink.sawFileLink && <CheckCircleIcon style={{ verticalAlign: 'middle', marginRight: 10, color: "green", fontSize: 30 }} />

					}
				</label>
			</Grid>

			<Grid container >
				<Button className={classes.uploadButton} variant="contained" shape="round" color="secondary" onClick={handleFileUpload} disabled={!projectNo.optimizationNumber || selectedProject.length === 0}>
						Upload
				</Button>
			</Grid>
		</>
	)
}

export default connect(null, { setLoading, getApprovedProjects, setSnackInfo })(Index);
