import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { NavLink, useHistory } from "react-router-dom";
import { connect } from 'react-redux'
import { setLoading, setSnackInfo } from '../redux/actions/appAction';
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow, TableHead, Button } from '@material-ui/core';
import { ROLENAME } from '../utils/index';
import { FormControl, TextField, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { Typography } from 'antd';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));

const ExecutionBoard = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const [executionData, setExecutionData] = useState([]);
    const [startDateFilter, setStartDate] = useState()
    const [endDateFilter, setEndDate] = useState();

    useEffect(() => {
        setExecutionData(props.DATA)
    }, [props.DATA])

    const changePageRoute = (executionStage, userId) => {
        history.push(`/execution-dashboard-projects/${executionStage.replace(/[\s+%/\\#,+$~.'":*?<>{}]/g, '-')}`, {
            experienceCenterId: props.selectedexpcenterid,
            locationId: props.selectedlocationid,
            assignTo: userId,
            startDate: startDateFilter,
            endDate: endDateFilter
        });
    }

    const getLeadsOnSelectedDate= () => {
        props.onChildData({
            startDate: startDateFilter,
            endDate: endDateFilter
        })
    }
    return (
        <div>
              <Paper className={classes.paper}>
                <br />
                <TextField
                    id="date"
                    name="startDate"
                    label="Design-Sign-Off-From-Date"
                    type="date"
                    value={startDateFilter}
                    onChange={(e) => setStartDate(e.target.value)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    fullWidth
                    className={classes.textField}
                />
                <TextField
                    id="date1"
                    name="endDate"
                    label="Design-Sign-Off-End-Date"
                    type="date"
                    value={endDateFilter}
                    onChange={(e) => setEndDate(e.target.value)}
                    InputLabelProps={{
                        shrink: true,

                        
                    }}
                    fullWidth
                    className={classes.textField}
                />
                <Button variant="contained" color="primary" disabled={!startDateFilter || !endDateFilter} onClick={getLeadsOnSelectedDate} >
                    Submit
                </Button>
            </Paper>
            <div style={{ marginTop: 25, marginBottom: 25 }}>
                <Paper className={classes.paper}>
                    <Typography component="h3">{props.TITLE}</Typography>

                    <TableContainer component={Paper} style={{ marginTop: 10 }}>
                        <Table className={classes.table} stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow >
                                {
                                (props.ROLE === ROLENAME.Execution_Manager) && <>
                                <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Sr No</TableCell>
                                <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>User Name</TableCell>
                                </>
                                }
                                    <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Under Execution</TableCell>
                                    <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Completed</TableCell>
                                    <TableCell style={{ padding: 10, width: 500, textAlign: 'center', fontWeight: 700 }}>Completed-MWP</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    executionData.length !== 0 ?
                                        executionData.map((status, i) => {
                                            return (
                                                <TableRow key={i + 1}>
                                                        {
                                                        (props.ROLE === ROLENAME.Execution_Manager) && <>
                                                            <TableCell component="th" scope="row" style={{ textAlign: 'center' }} >{i + 1}</TableCell>
                                                            <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>{status.user}</TableCell>
                                                        </>
                                                    }
                                                    <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>
                                                        <Button onClick={() => changePageRoute('Under Execution', status.userId)} color="primary">
                                                            {status.stage['Under Execution']}
                                                        </Button>
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>
                                                        <Button onClick={() => changePageRoute('Completed', status.userId)} color="primary">
                                                            {status.stage['Completed']}
                                                        </Button>
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>  
                                                        <Button onClick={() => changePageRoute('Completed-MWP', status.userId)} color="primary">
                                                            {status.stage['Completed-MWP']}
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                        :
                                        <TableRow>
                                            <TableCell colSpan={9} style={{ textAlign: "center" }}>No Data Found</TableCell>
                                        </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </div>
        </div>
    )
}

export default connect(null, { setLoading, setSnackInfo })(ExecutionBoard)
