import React, { useState, useEffect } from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import HttpService from '../services/httpService';
import { setLoading, setSnackInfo } from '../redux/actions/appAction'
import { connect } from 'react-redux'
import { Checkbox, FormControl, FormControlLabel, Radio, RadioGroup, Select, InputLabel, MenuItem } from '@material-ui/core';
import moment from 'moment';
const useStyles = makeStyles({
	table: {
		minWidth: 700,
	},
	root: {
		width: '100%',
	},
	textField: {
		width: "100px"
	},
	dateField: {
		width: "200px"
	},
});
const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: "#ccc",
		color: theme.palette.common.black,
	},
	body: {
		fontSize: 14,
	},
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
	root: {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover,
		},
	},
}))(TableRow);
const AutoSelectOpenPoModal = (props) => {
	const classes = useStyles();
	const [tableRows, setTableRows] = useState(Array(props.MATERIALS.length).fill({value:null}))
	const [selectedMaterial, setSelectedMaterial] = useState([])
	const [selectedVendor, setSelectedVendor] = useState([])
	const [selectedUom, setSelectedUom] = useState([])
	const [quantity, setQuantity] = useState([])
	const [price, setPrice] = useState([])
	const [rowsToAdd, setRowsToAdd] = useState(0)
	const [procurementFor, setProcurementFor] = useState(props.STOCKTYPE.charAt(0).toUpperCase() + props.STOCKTYPE.slice(1))
	const [units, setUnits] = useState([])
	const [vendors, setVendors] = useState([])
	const [materials, setAllMaterials] = useState([])
	const [discount, setDiscount] = useState(0)
	const [gstRequired, setGstRequired] = useState(false)
	const [extraCharge, setExtraCharge] = useState(0)
	const [vendorsarr, setVendorsarr] = useState([])
	useEffect(() => {
		getAllMaterials()
		selecterFunction()
		supersetter()
		// getVendors()
		// eslint-disable-next-line
	}, [])
	useEffect(()=>{
		supersetter()
	},[materials])
	const gstPercent = [
		"5", "12", "18", "28"
	]
	const [gstDetails, setGstDetails] = useState({
		gst: "",
		rate: ""
	})
	const handleDiscount = (e) => {
		setDiscount(e.target.value)
	}
	const handleGstType = (e) => {
		setGstDetails({ ...gstDetails, gst: e.target.value })
	}
	const getAllMaterials = async () => {
		props.setLoading(true)
		try {
			const result = await HttpService.getAllMaterialsNames();
			let propmaterial = props.MATERIALS;
			let newmaterial = result.data.filter((item)=> propmaterial.includes(item.name))
			setAllMaterials(newmaterial)
			getAllUnits()
		} catch (error) {
			console.error(error)
			props.setLoading(false)
			props.setSnackInfo('Error fetching Materials/Hardware', 'error')
		}
	}
	const getAllUnits = async () => {
		try {
			const result = await HttpService.getAllUnits()
			setUnits(result.data)
		} catch (error) {
			console.error(error)
			props.setLoading(false)
			props.setSnackInfo('Error fetching Units', "error")
		}
		props.setLoading(false)
	}
	const addRows = (e, value) => {
		let length = value ?? rowsToAdd
		const rows = [...tableRows];
		for (let i = 0; i < length; i++) {
			rows.push({ value: null })
		}
		setTableRows(rows)
		setRowsToAdd(0)
	}
	const handleMaterialSelect = (e, v, i) => {
		if (v) {
			let materials = [...selectedMaterial]
			materials[i] = v
			setSelectedMaterial(materials)
			setVendors(v?.vendorDetails)
		}
	}
	const selecterFunction = ()=>{
		const propsarr = props.MATERIALS;
		let materials1 = materials.filter(item=>{
			propsarr.includes(item.name)
		})
		setAllMaterials(materials1)
	}
	const handleVendorSelect = (e, v, i) => {
		if (v) {
			let vendors = [...selectedVendor]
			vendors[i] = v
			setSelectedVendor(vendors)
			let uom = [...selectedUom]
			uom[i] = v?.unitId
			setSelectedUom(uom)
			let rate = [...price]
			// rate[i] = v?.materialId.pricePerUnit
			rate[i] = v?.pricePerUnit // updated
			setPrice(rate)
		}
	}
	const handleUomSelect = (e, v, i) => {
		let uom = [...selectedUom]
		uom[i] = v
		setSelectedUom(uom)
	}
	const handleQuantity = (e, i) => {
		let quant = [...quantity]
		quant[i] = e.target.value
		setQuantity(quant)
	}
	const handlePrice = (e, i) => {
		let rate = [...price]
		rate[i] = e.target.value
		setPrice(rate)
	}
	const supersetter = ()=>{
		setSelectedMaterial(materials);
		setVendorsarr(materials.map(item=>item?.vendorDetails))
	}
	const getSelectedMaterial = (i) => {
		const item = selectedMaterial[i]
		return item || {}
	}
	const getSelectedVendor = (i) => {
		const item = selectedVendor[i]
		return item || {}
	}
	const getSelectedUom = (i) => {
		const item = selectedUom[i]
		return item || {}
	}
	const [poDetails, setPoDetails] = useState({
		deliveryDate: "",
		discount: 0,
		freight: 0,
		transport: 0
	})
	const submit = async (status) => {
		props.setLoading(true)
		let openPOMaterial = selectedMaterial.map((item, i) => {
			let obj = {
				materialId: item?._id,
				vendor: selectedVendor[i],
				unitType: selectedUom[i],
				quantity: quantity[i],
				pricePerUnit: price[i],
				scopeId: item?.scopeId?._id,
				deliveryDate: poDetails.deliveryDate
			}
			return obj
		})
		const material = {}
		material.itemList = openPOMaterial
		material.procurementType = procurementFor
		material.procurementPlace = procurementFor
		material.gstRequired = gstRequired
		material.gstDetails = gstDetails
		material.discount = poDetails.discount
		material.status = status
		material.transportationCharge = poDetails.transport
		material.freight = poDetails.freight
		try {
			await HttpService.createPurchaseOrder(material)
			props.setSnackInfo(`PO ${status}`)
			props.CLOSEMODAL()
		} catch (error) {
			console.error(error)
			props.setSnackInfo(`Error while${status === "Saved" ? 'saving' : 'submitting'} data`, "error")
			props.CLOSEMODAL()
		}
		props.setLoading(false)
	}
	return (
		<div>
			<div style={{ paddingBottom: 20, paddingTop: 20, display: "flex", justifyContent: "space-between" }}>
				<div>
					<InputLabel id="project-doc">Procurement For</InputLabel>
					<Select
						labelId="project-doc"
						value={procurementFor}
						style={{ width: 500 }}
						// onChange={(e) => setProcurementFor(e.target.value)}
						disabled={true}
					>
						<MenuItem value='Factory'>Factory</MenuItem>
						<MenuItem value='Store'>Store</MenuItem>
					</Select>
				</div>
				<TextField
					id="date"
					name="deliveryDate"
					onChange={(e) => setPoDetails({ ...poDetails, [e.target.name]: e.target.value })}
					label="Expected Delivery Date"
					type="date"
					defaultValue={moment().format('YYYY-MM-DD')}
					className={classes.dateField}
					InputLabelProps={{
						shrink: true,
					}}
				/>
			</div>
			<TableContainer component={Paper}>
				<Table className={classes.table} size="small" aria-label="customized table">
					<TableHead>
						<TableRow>
							<StyledTableCell>Sl No</StyledTableCell>
							<StyledTableCell>Select Material</StyledTableCell>
							<StyledTableCell>Select Vendor</StyledTableCell>
							<StyledTableCell>UOM</StyledTableCell>
							<StyledTableCell>Qty</StyledTableCell>
							<StyledTableCell>Enter Price</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{materials?.map((row, i) => (
							<StyledTableRow key={i + 1}>
								<StyledTableCell>{i + 1}</StyledTableCell>
								<StyledTableCell><Autocomplete
									defaultValue={materials[i].name}
									value={getSelectedMaterial(i)}
									onChange={(e, v) => handleMaterialSelect(e, v, i)}
									options={materials}
									getOptionLabel={(option) => option?.name}
									style={{ width: 300 }}
									renderInput={(params) => <TextField {...params} label="Select Material/Hardware" fullWidth variant="outlined" />}
								/></StyledTableCell>
								<StyledTableCell><Autocomplete
									value={getSelectedVendor(i)}
									onChange={(e, v) => handleVendorSelect(e, v, i)}
									options={vendorsarr[i] || vendors}
									getOptionLabel={(option) => option?.vendorId?.name}
									style={{ width: 200 }}
									renderInput={(params) => <TextField {...params} label="Select Vendor" fullWidth variant="outlined" />}
								/></StyledTableCell>
								<StyledTableCell><Autocomplete
									value={getSelectedUom(i)}
									onChange={(e, v) => handleUomSelect(e, v, i)}
									options={units}
									getOptionLabel={(option) => option.name}
									style={{ width: 150 }}
									renderInput={(params) => <TextField {...params} label="Select UOM" fullWidth variant="outlined" />}
								/></StyledTableCell>
								<StyledTableCell><TextField className={classes.textField} value={quantity[i]} onChange={(e) => handleQuantity(e, i)} type="number" variant="outlined" /></StyledTableCell>
								<StyledTableCell><TextField className={classes.textField} value={price[i]} onChange={(e) => handlePrice(e, i)} type="number" variant="outlined" /></StyledTableCell>
							</StyledTableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			{/* <div style={{ display: "flex", flexDirection: "row-reverse", marginTop: 15 }} >
				<Button variant="contained" color="primary" style={{ background: "#716ACA", margin: 5 }} onClick={addRows}>
					Add Row
				</Button>
				<TextField label="Rows to add" value={rowsToAdd} type="number" onChange={(e) => setRowsToAdd(e.target.value)} variant="outlined" />
			</div> */}
			<div>
				<div className='gst-header'>Add GST and extra charge details</div>
				<div className='gst-checked-options'>
					<TextField id="discount" type="number" label="Discount" variant="outlined" value={discount} onChange={handleDiscount} className='procurement-discount' />
					<span className='procurement-discount' style={{ border: '1.5px solid #ccc', padding: '5.5px 0px', borderRadius: '3.5px' }}><Checkbox
						checked={gstRequired}
						onChange={(e) => setGstRequired(e.target.checked)}
						inputProps={{ 'aria-label': 'primary checkbox' }}
					/>GST Required</span>
				</div>
				{gstRequired && <div className='gst-radio-percent'>
					<FormControl component="fieldset" className={classes.root}
						style={{ border: '1.5px solid #ccc', padding: '5.5px 0px', borderRadius: '3.5px', width: '48%' }}>
						<RadioGroup aria-label="type" name="type" value={gstDetails?.gst} onChange={handleGstType}>
							<div className='gst-type-radio'>
								<FormControlLabel value="SGST" control={<Radio />} label="CGST/SGST" />
								<FormControlLabel value="IGST" control={<Radio />} label="IGST" />
							</div>
						</RadioGroup>
					</FormControl>
					<Autocomplete
						id="Unit"
						value={gstDetails?.rate}
						onChange={(e, value) => setGstDetails({ ...gstDetails, rate: value })}
						options={gstPercent}
						getOptionLabel={(option) => `${option}%`}
						style={{ width: '48%' }}
						renderInput={(params) => <TextField {...params} label={`Select ${gstDetails?.gst} percent`} value={gstDetails?.gst} fullWidth variant="outlined" />}
					/></div>}
				<div className='gst-radio-percent'>
					<FormControl component="fieldset" className={classes.root}
						style={{ border: '1.5px solid #ccc', padding: '5.5px 0px', borderRadius: '3.5px', width: '48%' }}>
						<RadioGroup aria-label="type" name="type" value={extraCharge} onChange={(e) => setExtraCharge(e.target.value)}>
							<div className='gst-type-radio'>
								<FormControlLabel value="freight" control={<Radio />} label="Freight" />
								<FormControlLabel value="transport" control={<Radio />} label="Transport" />
							</div>
						</RadioGroup>
					</FormControl>
					<TextField id="extraCharge" label={`${extraCharge} charge`} type="number" variant="outlined"
						style={{ width: '48%' }} onChange={(e) => setPoDetails({ ...poDetails, [`${extraCharge}`]: e.target.value })} />
				</div>
			</div>
			<div style={{ display: "flex", flexDirection: "row-reverse", marginTop: 15 }} >
				<Button variant="contained" color="primary" style={{ background: "#716ACA", margin: 5 }} onClick={() => submit('Submitted')}>
					Submit
				</Button>
			</div>
		</div >
	)
}
export default connect(null, { setLoading, setSnackInfo })(AutoSelectOpenPoModal)
