import React, { useEffect, useState, useRef } from "react";
import {
	Table,
	Paper,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	makeStyles,
	withStyles,
	Button,
} from "@material-ui/core";
import HttpService from "../services/httpService";
import { setLoading, setSnackInfo } from "../redux/actions/appAction";
import { Modal } from "antd";
import { CSVLink } from "react-csv";
import moment from "moment";
import AccordionComponent from "./AccordionComponent";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: theme.spacing(2),
		margin: "auto",
		maxWidth: 500,
	},
	table: {
		width: "fit-content",
	},
	csv: {
		display: "flex",
		alignItems: "center",
		marginBottom: 20,
	},
	report: {
		marginBottom: 20,
		alignItems: "center",
		justifyContent: "center",
	},
}));

const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: "#ccc",
		color: theme.palette.common.black,
	},
	body: {
		fontSize: 14,
	},
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
	root: {
		"&:nth-of-type(odd)": {
			backgroundColor: theme.palette.action.hover,
		},
	},
}))(TableRow);

const StatisticReport = (props) => {
	const classes = useStyles();
	const [expCtrBasedData, setExpCtrBasedData] = useState([]);
	const [title, setTitle] = useState("Dummy Title");
	const [showDetailedModal, setShowDetailedModal] = useState(false);
	const [csvData, setCsvData] = useState([]);
	// const [startDate, setStartDate] = useState();
	// const [endDate, setEndDate] = useState();
	const csvButtonRef = useRef(null);
	const [popMonthData, setPopMonthData] = useState([]);
	let stage = "";

	// Headers for csv file
	const headers = [
		{ key: "serialNo", label: "Sl. No." },
		{ key: "projectCode", label: "Project No." },
		{ key: "clientName", label: "Client Name" },
		{ key: "totalValue", label: "Value (INR)" },
		{ key: "expCtrName", label: "Exp. Ctr Name" },
		{ key: "salesWonDate", label: "Sales Won Date" },
		{ key: "designSignOffDate", label: "Design Sign-Off Date" },
		{ key: "materialDeliveryDate", label: "Material Delivery Date" },
		{ key: "actualCompletionDate", label: "Actual Completion Date" },
	];

	// Function to download csv
	const csvDownloadHandler = async (data) => {
		setLoading(true);
		try {
			let csvArray = [];
			for (let i = 0; i < popMonthData.length; i++) {
				let obj = {
					serialNo: i + 1,
					projectCode: popMonthData[i]?.erpProjectNo || "N/A",
					clientName: popMonthData[i]?.customerId?.name,
					totalValue: popMonthData[i]?.totalCustomerOutflow || "-",
					expCtrName: popMonthData[i]?.experienceCenterId?.name,
					salesWonDate: popMonthData[i]?.leadWonDate
						? moment(popMonthData[i]?.leadWonDate).format("DD-MM-YYYY")
						: "-",
					designSignOffDate: popMonthData[i]?.designSignOffDate
						? moment(popMonthData[i]?.designSignOffDate).format("DD-MM-YYYY")
						: "-",
					materialDeliveryDate: popMonthData[i]?.materialDispatchedDate
						? moment(popMonthData[i]?.materialDispatchedDate).format(
								"DD-MM-YYYY"
						  )
						: "-",
					actualCompletionDate: popMonthData[i]?.executionCompletionDate
						? moment(popMonthData[i]?.executionCompletionDate).format(
								"DD-MM-YYYY"
						  )
						: "-",
				};
				csvArray.push(obj);
				obj = {
					serialNo: "",
					projectCode: "",
					clientName: "",
					totalValue: "",
					expCtrName: "",
					salesWonDate: "",
					designSignOffDate: "",
					materialDeliveryDate: "",
					actualCompletionDate: "",
				};
				csvArray.push(obj);
			}
			setCsvData(csvArray);
			downloadExcel();
		} catch (error) {
			console.log("error", error);
			props?.setSnackInfo(
				error?.response?.data?.message ?? "Something went wrong",
				"error"
			);
		}
		setLoading(false);
	};

	const downloadExcel = () => {
		setLoading(true);
		try {
			setTimeout(() => {
				csvButtonRef.current.link.click();
			});
		} catch (err) {
			console.error(err.message);
		}
		setLoading(false);
	};

	// Function to open detailed modal whenever there is click on count/value
	const showDetailedModalHandler = async (
		data,
		month,
		year,
		stage,
		leadStatus,
		tokenValue,
		firstColumn
	) => {
		props.setLoading(true);
		try {
			// Adding a check to include the userRole, making it clear for which role we are attempting to retrieve the data
			let userRole = "";
			if(props.TYPE == "Booking" &&  firstColumn == "Manager Name"){
				userRole = "Sales Manager";
			}
			if(props.TYPE == "Booking" &&  firstColumn == "User Name"){
				userRole = "Sales User";
			}
			if(props.TYPE == "Design Sign Off" &&  firstColumn == "Manager Name"){
				userRole = "Design Manager";
			}
			if(props.TYPE == "Design Sign Off" &&  firstColumn == "User Name"){
				userRole = "Design User";
			}

			let obj = {};

			// Adding steps to figure out which ID the search query will focus on.
			if (data?.userId) {
				obj["previouslyAssignedTo"] = data?.userId;
			} else if (data?.experienceCenterId) {
				obj["experienceCenterId"] = data?.experienceCenterId;
			}
			obj.month = month;
			obj.year = year;
			obj.stage = stage;
			obj.leadStatus = leadStatus;
			obj.tokenValue = tokenValue;
			obj.userRole = userRole;

			// Api calling to get data based on the userId/experienceCtrId , month & year
			const response = await HttpService.getDataBasedOnQuery(obj);
			setPopMonthData(response.data);
			setTitle(
				(data?.experienceCenterName || data?.name) +
					" " +
					"|" +
					" " +
					month +
					"-" +
					year
			);
			setShowDetailedModal(true);
		} catch (error) {
			console.log(error);
			setSnackInfo(error.message || "Something went wrong");
		}
		props.setLoading(false);
	};

	// Experience Center Wise Data for Statistic Dashboard Based On Stages
	const getExpCenterBasedData = async (
		startDate,
		endDate,
		leadStatus,
		tokenValue
	) => {
		props.setLoading(true);
		try {
			stage = "Sales";
			if (props.TYPE == "Booking") {
				stage = "Sales";
			} else if (props.TYPE == "Design Sign Off") {
				stage = "Design";
			} else if (props.TYPE == "Material Delivery") {
				stage = "Material Delivery";
			} else if (props.TYPE == "Project Completion") {
				stage = "Completed";
			}
			let data = {
				stage: stage,
				startDate: startDate,
				endDate: endDate,
				leadStatus: leadStatus,
				tokenValue: tokenValue,
			};
			const response = await HttpService.getExpCenterBasedData(data);
			setExpCtrBasedData(response.data.experienceCenterTotals);
		} catch (error) {
			console.log(error);
			setSnackInfo(error?.response?.data ?? "Something went wrong", "error");
		}
		props.setLoading(false);
	};

	// Function to handle start and end date
	const startAndEndDateHandler = (
		startDate,
		endDate,
		leadStatus,
		tokenValue
	) => {
		getExpCenterBasedData(startDate, endDate, leadStatus, tokenValue);
	};

	useEffect(() => {
		getExpCenterBasedData("", "", "Won", "All Bookings");
	}, []);

	return (
		<>
			<div className={classes.report}>
				<Paper className={classes.paper}>
					<center>
						<h2>{props.TYPE} Report</h2>
					</center>
				</Paper>
			</div>

			{/* Exp Center Based Data */}
			{expCtrBasedData && (
				<AccordionComponent
					DATA_ARRAY={expCtrBasedData}
					FIRST_COLUMN_NAME={"Exp. Ctr Name"}
					HEADING={"Experience Center"}
					SHOWDETAILMODALHANDLER={showDetailedModalHandler}
					STAGE={props.TYPE}
					HANDLE_DATE={startAndEndDateHandler}
				/>
			)}

			{/* Manager Based Data */}
			{props.ManagerBasedData && (
				<AccordionComponent
					DATA_ARRAY={props.ManagerBasedData}
					FIRST_COLUMN_NAME={"Manager Name"}
					HEADING={"Managers"}
					SHOWDETAILMODALHANDLER={showDetailedModalHandler}
					STAGE={props.TYPE}
					HANDLE_DATE={props?.FilterDataOnDates}
				/>
			)}

			{/* User Based Data */}
			{props.UserBasedData && (
				<AccordionComponent
					DATA_ARRAY={props.UserBasedData}
					FIRST_COLUMN_NAME={"User Name"}
					HEADING={"Users"}
					SHOWDETAILMODALHANDLER={showDetailedModalHandler}
					STAGE={props.TYPE}
					HANDLE_DATE={props?.FilterDataOnDates}
				/>
			)}

			{/* Detailed Modal for project */}
			<Modal
				title={title}
				centered
				visible={showDetailedModal}
				onCancel={() => setShowDetailedModal(false)}
				width={1000}
				maskClosable={true}
				footer={null}
			>
				<div className={classes.csv}>
					<CSVLink
						headers={headers}
						filename={title + ".csv"}
						data={csvData}
						ref={csvButtonRef}
					/>
					<Button
						onClick={() => csvDownloadHandler(expCtrBasedData)}
						style={{ backgroundColor: "green" }}
						variant="contained"
						color="secondary"
					>
						Download CSV
					</Button>
				</div>
				{popMonthData && (
					<TableContainer component={Paper} className={classes.table}>
						<Table
							style={{ width: 450 }}
							className={classes.table}
							stickyHeader
							aria-label="sticky table"
						>
							<TableHead>
								<StyledTableRow>
									<StyledTableCell>Sl. No.</StyledTableCell>
									<StyledTableCell>Project No.</StyledTableCell>
									<StyledTableCell>Lead No.</StyledTableCell>
									<StyledTableCell>Client Name</StyledTableCell>
									<StyledTableCell>Value (INR)</StyledTableCell>
									<StyledTableCell>Exp. Ctr. Name</StyledTableCell>
									<StyledTableCell>Sales Won Date</StyledTableCell>
									<StyledTableCell>Design Sign-Off Date</StyledTableCell>
									<StyledTableCell>Material Delivery Date</StyledTableCell>
									<StyledTableCell>Actual Completion Date</StyledTableCell>
								</StyledTableRow>
							</TableHead>
							<TableBody>
								{popMonthData?.map((data, i) => {
									return (
										<StyledTableRow key={i}>
											<StyledTableCell>{i + 1}</StyledTableCell>
											<StyledTableCell
												component="th"
												style={{ textAlign: "center" }}
											>
												{data?.erpProjectNo || "N/A"}
											</StyledTableCell>
											<StyledTableCell
												component="th"
												style={{ textAlign: "center" }}
											>
												{data?.lead_no}
											</StyledTableCell>
											<StyledTableCell
												component="th"
												style={{ textAlign: "center" }}
											>
												{data?.customerId.name}
											</StyledTableCell>
											<StyledTableCell
												component="th"
												style={{ textAlign: "center" }}
											>
												{data?.totalCustomerOutflow?.toLocaleString("en-IN", {
													maximumFractionDigits: 2, // Maximum number of decimal places
													style: "currency",
													currency: "INR",
												})}
											</StyledTableCell>
											<StyledTableCell>
												{data?.experienceCenterId?.name}
											</StyledTableCell>
											<StyledTableCell>
												{data?.leadWonDate
													? moment(data?.leadWonDate).format("DD-MM-YYYY")
													: "-"}
											</StyledTableCell>
											<StyledTableCell>
												{data?.designSignOffDate
													? moment(data?.designSignOffDate).format("DD-MM-YYYY")
													: "-"}
											</StyledTableCell>
											<StyledTableCell>
												{data?.materialDispatchedDate
													? moment(data?.materialDispatchedDate).format(
															"DD-MM-YYYY"
													  )
													: "-"}
											</StyledTableCell>
											<StyledTableCell>
												{data?.executionCompletionDate
													? moment(data?.executionCompletionDate).format(
															"DD-MM-YYYY"
													  )
													: "-"}
											</StyledTableCell>
										</StyledTableRow>
									);
								})}
							</TableBody>
						</Table>
					</TableContainer>
				)}
			</Modal>
		</>
	);
};
export default connect(null, { setLoading, setSnackInfo })(StatisticReport);
