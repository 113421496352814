import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux'
import HttpService from '../../services/httpService'
import { setLoading, setSnackInfo } from '../../redux/actions/appAction'
import moment from 'moment';
import { Link } from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Divider } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 700,
    },
    root: {
        width: '100%',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
}));

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#ccc",
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const DiscountApproval = (props) => {
    const classes = useStyles();
    const [discountedProjectLists, setDiscountedProjectLists] = useState([]);
    const [openProjectLogsModal, setOpenProjectLogsModal] = useState(false);
    const [openProjectInfoModal, setOpenProjectInfoModal] = useState(false);
    const [projectLogsData, setProjectLogsData] = useState([]);
    const [projectDiscount, setProjectDiscount] = useState(0);
    const { userDetails } = useSelector((state) => state.user);
    const [data, setData] = useState({
        "discountPercent": "",
        "projectId": "",
        "assignTo": "",
        "discountRequestedBy": "",
        "discountApprovalRequest": "",
        "discountStatus": ""
    });
    const [projectId, setProjectId] = useState("");
    useEffect(() => {
        discountedProject()
        // eslint-disable-next-line
    }, [])

    const discountedProject = async () => {
        props.setLoading(true)
        try {
            const response = await HttpService.discountedProject(userDetails.selectedExpCenter);
            if (response.data === 'No projects found.') {
                setDiscountedProjectLists([]);
            } else {
                setDiscountedProjectLists(response.data);
            }

        } catch (error) {
            console.log(error, "Discounted Project not found")
        }
        props.setLoading(false)
    }

    const showProjectLogs = (logData) => {
        setOpenProjectLogsModal(true);
        setProjectLogsData(logData);
    }

    const showInfo = (data) => {
        console.log(data, "data");
        setProjectDiscount(data?.discountPercent)
        let obj = {
            discountPercent: data?.discountPercent,
            assignTo: data?.assignTo?._id,
            discountRequestedBy: data?.discountRequestedBy ? data?.discountRequestedBy?._id : ''
        }
        setData(obj);
        setProjectId(data?._id);
        setOpenProjectInfoModal(true);
    }

    const approveDiscount = async (status) => {
        data.discountApprovalRequest = status;
        data.discountStatus = status ? 'Rejected' : 'Approved'
        console.log(status, "Discount Status", data, "mmm", projectId);
        props.setLoading(true)
        try {
            const response = await HttpService.approveDiscount(projectId, data);
            console.log(response.data, "Data", response.data.length)
            discountedProject()
            setOpenProjectInfoModal(false);
        } catch (error) {
            console.log(error, "Discounted Project not found")
        }
        props.setLoading(false)
    }

    return (
        <div>
            <Paper>
                <TableContainer component={Paper}>
                    <Table className={classes.table} stickyHeader aria-label="sticky table">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell>Sr. No.</StyledTableCell>
                                <StyledTableCell>Customer</StyledTableCell>
                                <StyledTableCell>Lead No</StyledTableCell>
                                <StyledTableCell>Created At</StyledTableCell>
                                <StyledTableCell>Budget</StyledTableCell>
                                <StyledTableCell>Discount Percentage</StyledTableCell>
                                <StyledTableCell>Requested By</StyledTableCell>
                                <StyledTableCell>Assigned To</StyledTableCell>
                                <StyledTableCell>Logs</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {discountedProjectLists.map((row, i) => {
                                return (
                                    <StyledTableRow key={i + 1}>
                                        <StyledTableCell>{i + 1}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">
                                            <Link to={`/project-logs/${row._id}`} style={{ color: "#000" }}>{row?.customerId?.name}</Link>
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row">
                                            {row?.lead_no}
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row">
                                            {moment(row?.createdAt).format('DD-MM-YYYY')}
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row">{row?.grandTotal}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">{row?.discountPercent}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">{row.discountRequestedBy ? row?.discountRequestedBy?.name : `NA`}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">
                                            {row?.assignTo?.name}
                                            <InfoIcon onClick={() => showInfo(row)} />
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row">
                                            <Button onClick={() => showProjectLogs(row['discountLogs'])} variant="contained" color="primary">Show Logs</Button>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>

            {/* Project Log Details */}
            <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: 10 }}>
                <Dialog open={openProjectLogsModal} onClose={() => setOpenProjectLogsModal(false)}>
                    <DialogTitle>Logs</DialogTitle>
                    <Divider />
                    <DialogContent>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <StyledTableRow>
                                        <StyledTableCell>Sr. No.</StyledTableCell>
                                        <StyledTableCell>Discount</StyledTableCell>
                                        <StyledTableCell>Status</StyledTableCell>
                                        <StyledTableCell>Assigned To</StyledTableCell>
                                        <StyledTableCell>Requested By</StyledTableCell>
                                        <StyledTableCell>Action Taken By</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {projectLogsData.map((row, i) => {
                                        return (
                                            <StyledTableRow key={i + 1}>
                                                <StyledTableCell>{i + 1}</StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    {row?.discountValue}
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    {
                                                        row?.approvalStatus === true ? `Approved` : `Rejected`
                                                    }
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    {row?.user?.name}
                                                </StyledTableCell>
                                                <StyledTableCell>{row?.discountPercent}</StyledTableCell>
                                                <StyledTableCell>{row.discountRequestedBy ? row?.discountRequestedBy?.name : `NA`}</StyledTableCell>
                                                <StyledTableCell>{row?.assignTo?.name}</StyledTableCell>
                                                <StyledTableCell>
                                                    {row?.actionTakenBy?.name}
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </DialogContent>
                </Dialog>
            </div>

            {/* Deal Discount Modal */}
            <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: 10 }}>
                <Dialog open={openProjectInfoModal} onClose={() => setOpenProjectInfoModal(false)}>
                    <DialogTitle>Deal Discount</DialogTitle>
                    <Divider />
                    <DialogContent>
                        {projectDiscount} % discount for the customer
                    </DialogContent>
                    <Divider />
                    <DialogActions>
                        <Button onClick={() => approveDiscount(true)} variant="outlined" color="secondary">Reject</Button>
                        <Button onClick={() => approveDiscount(false)} variant="contained" color="primary">Approve</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    )

}

export default connect(null, { setLoading, setSnackInfo })(DiscountApproval)