import { GET_ALL_PROJECTS, SET_SNACK, SET_SNACK_INFO, GET_ALL_SCOPES, GET_ALL_UNITS, GET_ALL_PAYMENT_MODES, GET_ALL_SUB_CATEGORY, GET_ALL_PAYMENT_PARTNERS } from "./types";
import HttpService from "../../services/httpService";

export const getAllProjects = () => async dispatch => {
  try {
    const result = await HttpService.getAllProjects()
    dispatch({ type: GET_ALL_PROJECTS, payload: result.data })
  } catch (error) {
    console.error(error);
    dispatch({ type: SET_SNACK, payload: true })
    dispatch({ type: SET_SNACK_INFO, payload: { msg: error.response.data, snackSeverity: "error" } })
  }
}

export const getAllScopes = () => async dispatch => {
  try {
    const result = await HttpService.getAllScopes()
    dispatch({ type: GET_ALL_SCOPES, payload: result.data })
  } catch (error) {
    console.error(error);
    dispatch({ type: SET_SNACK, payload: true })
    dispatch({ type: SET_SNACK_INFO, payload: { msg: error.response.data, snackSeverity: "error" } })
  }
}

export const getAllUnits = () => async dispatch => {
  try {
    const result = await HttpService.getAllUnits()
    dispatch({ type: GET_ALL_UNITS, payload: result.data })
  } catch (error) {
    console.error(error);
    dispatch({ type: SET_SNACK, payload: true })
    dispatch({ type: SET_SNACK_INFO, payload: { msg: error.response.data, snackSeverity: "error" } })
  }
}


export const getAllPaymentPartners = () => async dispatch => {
  try {
    const result = await HttpService.paymentpatners()
    dispatch({ type: GET_ALL_PAYMENT_PARTNERS, payload: result.data })
  } catch (error) {
    console.error(error);
    dispatch({ type: SET_SNACK, payload: true })
    dispatch({ type: SET_SNACK_INFO, payload: { msg: error.response.data, snackSeverity: "error" } })
  }
}
export const getAllPaymentModes = () => async dispatch => {
  try {
    const result = await HttpService.getAllPaymentModes();

    dispatch({ type: GET_ALL_PAYMENT_MODES, payload: result.data })
  } catch (error) {
    console.error(error);
    dispatch({ type: SET_SNACK, payload: true })
    dispatch({ type: SET_SNACK_INFO, payload: { msg: error.response.data, snackSeverity: "error" } })
  }
}

export const getAllSubCategory = () => async dispatch => {
  try {
    const result = await HttpService.getAllSubCategory()
    dispatch({ type: GET_ALL_SUB_CATEGORY, payload: result.data })
  } catch (error) {
    console.error(error);
    dispatch({ type: SET_SNACK, payload: true })
    dispatch({ type: SET_SNACK_INFO, payload: { msg: error.response.data, snackSeverity: "error" } })
  }
}