import React, { Fragment, useEffect, useRef, useState } from "react";
import {
	AppBar,
	Grid,
	TextField,
	Box,
	Paper,
	Tab,
	Tabs,
	Typography,
	makeStyles,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	withStyles,
	Button,
  Dialog
} from "@material-ui/core";
import PropTypes from "prop-types";
import HttpService from "../services/httpService";
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/Edit';
import { setLoading, setSnackInfo } from "../redux/actions/appAction";
import { connect, useSelector } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { green, red, blue } from '@material-ui/core/colors';
import {chmTeamTaggingArr, chmCentreWiseTaggingArr} from '../utils/index'

const useStyles = makeStyles((theme) => ({
	root: {
		"& > *": {
			margin: theme.spacing(1),
		},
	},
	table: {
		width: "fit-content",
		// width: "auto", /* or specify a fixed width */
    	// min-width: '100%', /* Set a minimum width */
	},
	tablecontainer: {
		display: "flex",
		margin: "25px",
	},
	tableform: {
		display: "flex",
	},
	paper: {
		padding: theme.spacing(2),
		margin: "auto",
		maxWidth: 500,
	},
	normalTableCell: {
		color: "black",
		"&:hover": {
			cursor: "pointer",
			color: "blue", // you can change the color when hovering here
			textDecoration: "underline",
		},
	},
	tableCell: {
		color: "blue",
		cursor: "pointer",
		textDecoration: "underline",
	},
  textField:{
    width: "600px",
  },
  reassign:{
    minWidth: "500px",
    // minHeight: "300px",
  },
  reassignAutocomplete:{
    margin: "20px",
  },
  editDialogue:{
    minWidth: "600px",
    cursor: 'default'
  },
  redButton: {
    backgroundColor: "#F50057 !important",
    color: "white !important",
    transition: "background-color 0.3s, color 0.3s",
    margin: "10px"
  },
  normalButton:{
    margin: "10px"
  }
}));

const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: "#3F51B5",
		color: "#FFF",
		fontWeight: "500",
	},
	body: {
		fontSize: 14,
	},
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
	root: {
		"&:nth-of-type(odd)": {
			backgroundColor: theme.palette.action.hover,
		},
	},
}))(TableRow);


function ChmAddTicketCategory(props) {
    const classes = useStyles();
	const { userDetails } = useSelector((state) => state.user);
	const [value, setValue] = useState("");
	const [editValue, setEditValue] = useState("");
	const [editDataObj, setEditDataObj] = useState({});
	const [fetchData, setFetchData] = useState([]);
	const [viewTagCategoryIssueDialog, setViewTagCategoryIssueDialog] = useState(false);
	// const [viewTagCategoryNameEditDialog, setViewTagCategoryNameEditDialog] = useState(false);
	const [selectedDepartment, setSelectedDepartment] = useState({});
	const [allDepartments, setAllDepartments] = useState("");
	const [usersOfSelectedTeam, setUsersOfSelectedTeam] = useState([]);
	const [selectedTeam, setSelectedTeam] = useState({});
	const [selectedUser, setSelectedUser] = useState({});

    useEffect(()=>{
      getDataOnPageLoading()
    },[])
    const handleCategoryInputChange = (e)=>{
        setValue(e.target.value)
    }
    const handleEditCategoryInputChange = (e)=>{
        setEditValue(e.target.value)
    }
    
    const handleAddTicketCategoryRequest = async ()=>{
        // props.setLoading(true);
        try{


            let obj = {
              value:value.trim()
            }

            if(chmTeamTaggingArr.find((el)=> el.toLowerCase().includes(value.toLowerCase()))){
              obj = {
                ...obj,
                assignedDepartment:selectedDepartment._id,
                assignedTeam:selectedTeam._id,
                assignedUser:selectedUser._id
              }
            }
            let response;
            if(editValue!=''){
              obj.value = editValue.trim()
              response = await HttpService.editCHMticketCategory(obj, editDataObj._id);
            }
            else{
              response = await HttpService.addCHMticketCategory(obj);
            }

            if(response.status === 201 || response.status === 200){
              props.setSnackInfo("Ticket Category Created Successfully", "Success");
              setValue("")
              setViewTagCategoryIssueDialog(false);
              getDataOnPageLoading()
            }
        }
        catch(error){
            props.setSnackInfo(error.message, "Error");
        }
        // props.setLoading(false);
    }

    const getDataOnPageLoading = async ()=>{
        // props.setLoading(true);
        try{
            const response = await HttpService.getAllCHMticketCategory();
            const departments = await HttpService.getDepartments();
            
            setFetchData(response.data);
            setAllDepartments(departments.data);
            clearAutoCompleteFields()
        }
        catch(error){
            props.setSnackInfo(error.message, "Error");
        }
        // props.setLoading(false);
    }

    const handleDeleteCategory = async (e,data)=>{
        try{
            const response = await HttpService.deleteCHMticketCategory(data._id);

            if(response.status === 200){
              setValue("")
              props.setSnackInfo("Ticket Category Deleted Successfully", "Info");
              getDataOnPageLoading()
          }
        }
        catch(error){
            props.setSnackInfo(error.message, "Error");
        }
        // props.setLoading(false);
    }

    const handleTeamSelectionAutocomplete = (v)=>{
      if(v!=null){
        setSelectedTeam(v)
        const teamUsers = selectedDepartment?.users?.filter((user)=> user?.teamId === v._id)
        setUsersOfSelectedTeam(teamUsers)
      }
    }
    
    const clearAutoCompleteFields = ()=>{
      setSelectedDepartment({})
      setUsersOfSelectedTeam([])
      setSelectedUser({})
      setSelectedTeam({})
    }

    const handleCategoryEditButton = (e,data)=>{

      const categoryName = data.value;

      //check if front End Flow
      if(chmTeamTaggingArr.find((el)=> el.toLowerCase().includes(categoryName.toLowerCase()))){
        setViewTagCategoryIssueDialog(true);
      }
      setEditValue(data.value)
      setEditDataObj(data);
    }

    const handleCategoryAddButton = ()=>{
      //check if front End Flow
      if(chmTeamTaggingArr.find((el)=> el.toLowerCase().includes(value.toLowerCase().trim()))){
        setViewTagCategoryIssueDialog(true);
      }
      else{
        handleAddTicketCategoryRequest()
      }
    }

    function isEmpty(obj) {
      return Object.keys(obj).length === 0;
    }

  return (
    <>
        <TextField
                id="addCategory"
                name="Add Category"
                label="Add Category"
                value={value}
                defaultValue=""
                onChange={handleCategoryInputChange}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                className={classes.textField}
                required
              />

              <Button
              color="primary"
              variant="contained"
              style={{
                padding: 10,
                width: 180,
                textAlign: "center",
                margin: 20,
                height: 30,
              }}
              onClick={()=>{
                handleCategoryAddButton();
              }}
              disabled={value === ""}
              >
                Add Category
              </Button>

              {fetchData && (
                  <TableContainer component={Paper} className={classes.table}>
          <Table
            // style={{ width: 450 }}
            className={classes.table}
            stickyHeader
            aria-label="sticky table"
            >

            <TableHead>
              <StyledTableRow>
                <StyledTableCell>Sl. No.</StyledTableCell>
                <StyledTableCell>Category Name</StyledTableCell>
                <StyledTableCell>Delete Category</StyledTableCell>
                <StyledTableCell>Assigned User</StyledTableCell>
                <StyledTableCell>Change Assignee</StyledTableCell>
              </StyledTableRow>
            </TableHead>

            <TableBody>
              {fetchData?.map((data, i) => {
                return (
                  <StyledTableRow key={i}>

                    <StyledTableCell component="th">{i + 1}</StyledTableCell>

                    <StyledTableCell
                      component="th"
                      style={{ textAlign: "center" }}
                    >
                      {data?.value || "N/A"}
                    </StyledTableCell>

                    <StyledTableCell
                      component="th"
                      style={{ textAlign: "center" }}
                    >
                        <Button
                        onClick={(e) => {handleDeleteCategory(e,data)}}
                        >
                          <DeleteOutlinedIcon></DeleteOutlinedIcon>
                        </Button>
                    </StyledTableCell>

                    <StyledTableCell
                      component="th"
                      style={{ textAlign: "center" }}
                    >
                        {data?.assignedUser?.name || '-'}
                    </StyledTableCell>

                    <StyledTableCell
                      component="th"
                      style={{ textAlign: "center" }}
                    >
                        {/* {data.value.toLowerCase()!="design" && data.value.toLowerCase()!="sales" && */}
                        {chmTeamTaggingArr.find((el)=> el.toLowerCase().includes(data.value.toLowerCase())) &&
                        <Button
                        onClick={(e) => {handleCategoryEditButton(e,data)}}
                        >
                          <EditIcon></EditIcon>
                        </Button>}
                    </StyledTableCell>

                  </StyledTableRow>

                );
              })}
            </TableBody>

          </Table>
        </TableContainer>)}

        <Dialog
          open={viewTagCategoryIssueDialog}
          keepMounted
          onClose={() => {
            setViewTagCategoryIssueDialog(false);
            clearAutoCompleteFields()
        }}
        >
          <Grid
              container
              className={classes.reassign}
              direction="column"
              justifyContent="space-evenly"
              alignItems="stretch"
              key="ReassignGridContainer"
            >
              
          <h3 className={`${classes.reassignAutocomplete}`}>Tagging for : {value || editValue}</h3>

          <Autocomplete
                value={selectedDepartment}
                onChange={(e, newValue) => newValue ? setSelectedDepartment(newValue) : setSelectedDepartment({})}
                options={allDepartments}
                getOptionLabel={(option) => option?.name || ''}
                className={classes.reassignAutocomplete}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Tag Department"
                    fullWidth
                    variant="outlined"
                  />
                )}
              />

              {selectedDepartment?.teams && (
                <Autocomplete
                  value={selectedTeam}
                  onChange={(e, newValue) => handleTeamSelectionAutocomplete(newValue)}
                  options={selectedDepartment?.teams || ''}
                  getOptionLabel={(option) => option?.name || ''}
                  className={classes.reassignAutocomplete}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Tag Team"
                      fullWidth
                      variant="outlined"
                    />
                  )}
                />
              )}

              {selectedDepartment?.users && usersOfSelectedTeam && (
                <Autocomplete
                  value={selectedUser}
                  onChange={(e, newValue) => newValue ? setSelectedUser(newValue) : setSelectedUser({})}
                  options={usersOfSelectedTeam}
                  getOptionLabel={(option) => option?.name || ''}
                  className={classes.reassignAutocomplete}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Tag User"
                      fullWidth
                      variant="outlined"
                    />
                  )}
                />
              )}

        </Grid>

        <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
        >
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => {
                  setViewTagCategoryIssueDialog(false);
                  clearAutoCompleteFields();
                }}
                className={
                  classes.redButton
                }
              >
                Cancel
              </Button>

              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={handleAddTicketCategoryRequest}
                className={
                  classes.normalButton
                }
                disabled={isEmpty(selectedDepartment) || isEmpty(selectedTeam) || isEmpty(selectedUser)}
              >
                Confirm
              </Button>
        </Grid>
        </Dialog>

        {/* <Dialog
          open={viewTagCategoryNameEditDialog}
          keepMounted
          onClose={() => {
            setViewTagCategoryNameEditDialog(false);
            clearAutoCompleteFields()
        }}
        >
          <Grid
              container
              className={classes.reassign}
              direction="column"
              justifyContent="space-evenly"
              alignItems="stretch"
              key="ReassignGridContainer"
            >

          <TextField
          id="addCategory"
          name="Add Category"
          label="Add Category"
          value={editValue}
          defaultValue=""
          onChange={handleEditCategoryInputChange}
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
          className={classes.textField}
          required
          >

          </TextField>

        </Grid>
        <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
        >
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => {
                  setViewTagCategoryIssueDialog(false);
                  clearAutoCompleteFields();
                }}
                className={
                  classes.redButton
                }
              >
                Cancel
              </Button>

              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={handleEditTicketCategoryRequest}
                className={
                  classes.normalButton
                }
              >
                Confirm
              </Button>
        </Grid>
        </Dialog> */}
        
    </>
  )
}
export default connect(null, { setLoading, setSnackInfo })(ChmAddTicketCategory)