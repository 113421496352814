import React, { useState, useEffect, useRef, useMemo } from 'react'
import { setLoading, setSnackInfo } from '../../redux/actions/appAction'
import { connect, useSelector } from 'react-redux'
import { useParams, useHistory } from "react-router-dom";
import HttpService from '../../services/httpService'
import moment from 'moment'
import { NavLink } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles, withStyles} from '@material-ui/core/styles';
import { Accordion, AccordionSummary, AccordionDetails, AccordionActions, Typography, Paper, Grid, Divider, Button, Table, TableBody, TableCell, TableContainer, TableRow, DialogContentText, Box, OutlinedInput, RadioGroup, Radio , Snackbar, SnackbarContent} from '@material-ui/core';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InfoIcon from '@material-ui/icons/Info';
import AttachmentIcon from '@material-ui/icons/Attachment';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select/Select';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import './index.css';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ROLENAME, designDepartment, constantDepartmentId, CURRENT_STAGE, salesDepartment } from '../../utils/index';
import { EditorState, convertToRaw } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createMentionPlugin, { defaultSuggestionsFilter, } from '@draft-js-plugins/mention';
import '@draft-js-plugins/mention/lib/plugin.css';
import { commaSeparateAmount } from '../../components/commaSeparate';
import { PlusCircleOutlined } from '@ant-design/icons';
import TableHead from '@material-ui/core/TableHead';
// import { , CheckCircleOutlined, StopOutlined, DownloadOutlined } from '@ant-design/icons'
import { DownloadOutlined, EyeFilled } from '@ant-design/icons';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { Checkbox,FormControlLabel,Card, CardContent } from '@material-ui/core';

// import TableHead from '@material-ui/core/TableHead';
import { Http } from '@material-ui/icons';
//import { FaBold, FaItalic, FaUnderline } from 'react-icons/fa';
import XLSX from 'xlsx';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { FaArrowRight } from "react-icons/fa";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightBold,
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalpaper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    textFieldWidth: {
        width: "100%"
    },
    generateButton: {
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
        "&:hover": {
            backgroundColor: theme.palette.primary.dark,
        },
    },
}));

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#ccc",
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const getCurrentDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

const getCurrentTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
};

const ManageProjectLogs = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const editorRef = useRef();
    const buttonRef = useRef(null);
    const { projectId } = useParams();
    const { userDetails } = useSelector(state => state.user)
    const [projectDetails, setProjectDetails] = useState([]);
    const [projectLogs, setProjectLogs] = useState([]);
    const [stageInfo, setStageInfo] = useState("");
    const [stageIndex, setStageIndex] = useState(0);
    const [openNotesModal, setOpenNotesModal] = useState(false);
    const [openDesignKickOffScreenshotsModal, setOpenDesignKickOffScreenshotsModal] = useState(false);
    const createNoteFile = useRef()
    const [fileUploaded, setFileUploaded] = useState(false);
    const [openNotesInfoModal, setOpenNotesInfoModal] = useState(false);
    const [paymentDetailsModal, setPaymentDetailsModal] = useState(false)
    const [editProjectModal, setEditProjectModal] = useState(false);
    const [newErpProjectNo, setNewErpProjectNo] = useState("");
    const [quotationselected,setQuotationSelected ]  = useState(false)
    const [designSignOff,setDesignSignOff]=useState("")
    const [openErpProjectNoModal, setOpenErpProjectNoModal] = useState(false);
    const [openDesignSignOff, setOpenDesignSignOffModal] = useState(false);
    const [daysfromModulardelivery, setDaysFromModularDelivery] = useState("")
    const [daysfromContractsign, setDaysFromContractSign] = useState("");
    const [allSiteQc, setAllSiteQc] = useState([]);
    const [openAssignSiteQcModel, setOpenAssignSiteQcModel] = useState(false);
    const [selectedQuotation, setSelectedQuotation] = useState(null);
    const [videos, setVideos] = useState([""]);
    const [showButton,setShowButton] = useState("")
    const [reRender, setReRender] = useState(false);
    const [storeVersion,setStoreVersion] = useState("")
    const [tableHeading, setTableHeading] = useState(["S.No", "Project Unique Id", "Client Name", "Payment Date", "Transaction ID", "Amount", "Payment Mode", "Department", "Requested By", "Status", "Stage", "Payment Partners", "Finance Action Status", "Finance Action Remarks"])
    const [tableData, setTableData] = useState([{ name: "test" }, { payment: "20000" }, { date: "29/12/2023" }, { status: "Completed" }])
    const [selectedSiteQcUserData, setSelectedSiteQcUserData] = useState({});
    // const [showPaymentPreview,setPaymentPreview] = useState([])
    const [customerInformation, setCustomerInformation] = useState([])
    const [checkVideoUploaded, setCheckVideoUploaded] = useState([]);
    const [showPaymentPreview, setPaymentPreview] = useState([])
    const [checkFileUploaded, setCheckFileUploaded] = useState([{ status: null }]);
    const [fileUploadeds, setFileUploadeds] = useState(false);
    const [expectedSalesclosureDate, setExpectedSalesClosureDate] = useState("")
    const [openEnterSalesClosureDate, setOpenEnterSalesClosureDateModal] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [storeLatestQuotation,setstoreLatestQuotation] = useState([])

    const [dialogForm, setDialogForm] = useState({
        expectedSalesClosureDate: new Date(),
      });
    const [expectedSalesclosureDatenew, setExpectedSalesclosureDatenew] = useState(/* initial value */);
    const [openSelectQuotationVersion,setOpenSelectQuotationVersion] = useState(false);
    const [selectedQuotationVersion, setSelectedQuotationVersion] = useState('');
    const [quotationList, setQuotationList] = useState([])
    const [quotationPdf, setQuotationPdf] = useState();
    const [quotationCsv, setQuotationCsv] = useState();
    const [qtVersion, setQtVersion] = useState("");
    const [openMomPage, setOpenMomPage] = useState(false);
    const [momSubmit, setMomSubmit] = useState(false);
    const [discussionNo, setDiscussionNo] = useState("");
    const [designDiscussion, setDesignDiscussion] = useState(['Discussion 1','Discussion 2', 'Discussion 3','Discussion 4']);
    const [momDiscussionsDetails, setMomDiscussionsDetails] = useState({
        time: getCurrentTime(),
        designer: userDetails.name,
        date: getCurrentDate(),
        typeOfMeeting: "",
        quotationNo: "",
        attendees : "",
        minutesBy: "",
        momNo: 0,
        venue: "",
        agenda : "",
        discussion : "",
        owners : "",
        customerRemarks : "",
        nextMeeting: "",
        remark: "",
    })
    const [clicked, setClicked] = useState(false);

    const [uploadSize, setUploadSize] = useState({
        workingDrawingFile: 0,
        threeworkingDrawingFile: 0,
        contractSignedPaymentReceviedAttachemnt: 0,
        scanQuotationFile: 0,
        scanCheckListFile: 0,
        scanProjectKickOffCheckListFile: 0,
        scanSiteQCCheckListFile: 0,
        designerPaymentAttachement: 0,
        // scanContractFile: false
        quotationCsvFile: 0,
        siteQCPhotos: [],
        paymentRecievedAttachments: []
    })
    const [totalSize, setTotalSize] = useState(0);
    const [formData, setFormData] = useState({
        notes: "",
        reminderDate: "",
        s3Location: "",
        leadId: "",
        user: "",
        stage: ""
    })

    const [openPayment, setOpenPayment] = useState(false);
    const [paymentLink, setPaymentLink] = useState("");
    const [openNew, setOpenNew] = useState(false);
    const [amount, setAmount] = useState('');
    // const [remarks, setRemarks] = useState('');
    const [isValidAmount, setIsValidAmount] = useState(false);
    const [payExtra, setPayExtra] = useState(false);
    const [checkSalesToken, setCheckSalesToken] = useState(false)
    const [currentStageIndex, setCurrentStageIndex] = useState(0);
    const [callLogsModal, setCallLogsModal] = useState(false)
    const [designHoldSelectedTypes, setDesignHoldSelectedTypes] = useState("")
    const [probableDesignSignOffDate, setProbableDesignSignOffDate] = useState("")
    const availableDesignHoldOptions = [
        { name: 'Possesion Delay' },
        { name: 'Cx not available' },
        { name: 'Cx having Financial Issue' },
        { name: 'Cx wants to put the projects on hold' },
        { name: 'Probable Loss' },
        { name: 'Others' },
      ];



    const handleGeneratePaymentLink = async () => {
        props.setLoading(true)
        let amountWithCharges
        if(payExtra === true){
            amountWithCharges = +amount * 0.0236;
            amountWithCharges = (+amountWithCharges + +amount).toFixed(0);
        }else{
            amountWithCharges = amount;
        }
        const Details = {
            customer_details: {
                customer_email: projectDetails?.customerId?.email,
                customer_name: projectDetails?.customerId?.name,
                customer_phone: projectDetails?.customerId?.contact_no,
                customerAddress: projectDetails?.customerId?.address
            },
            link_amount: amountWithCharges,
            link_purpose: `${projectDetails?.customerId?.name}`,
            Stage: projectDetails.currentStage,
            departmentId: projectDetails.departmentId._id,
            uniqueLeadNumber: projectDetails.uniqueLeadNumber

        };
        try {
            const response = await HttpService.cashFreePaymentUpload(projectDetails._id, Details)
            // setProjectLogs(response.data)
            setPaymentLink(response.data.link_url);
            setOpenPayment(true);
        } catch (error) {
            props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false)

    };

    const handleClose = () => {
        setOpenPayment(false);
    };

    const handleClickOpenNew = () => {
        setOpenNew(true);
    };

    const handleCloseNew = () => {
        setOpenNew(false);
    };

    const handleSave = () => {
        handleGeneratePaymentLink()
        handleCloseNew();

    };

    const handleAmountChange = (e) => {
        const value = e.target.value;
        setAmount(value);
        setIsValidAmount(value !== '' && parseFloat(value) > 0);
        let token;
        if (projectDetails?.departmentId?._id === salesDepartment) {
            token = +(((value / projectDetails?.grandTotal) * 100).toFixed(2));
        } else { 
            token = +(((value / projectDetails?.staticSalesWonAmount) * 100).toFixed(2));
        }
        let knowToken = projectDetails?.paymentPercent + token
        setCheckSalesToken(projectDetails?.paymentPercent + token <= 10 ? false : true)
    };


    useEffect(() => {
        getProjectLogs();
        getcustomerDetails();
        getChmUsersList();
        getAllQuotationsForProject();
        userDetails.roles.find(role=>role.name === ROLENAME.SeniorOperationManager) && getAllSiteQc()

    }, [reRender])

   const handleCalled = () =>{
    getAllQuotationsForProject();
   }

    useEffect(()=>{
        calculateTotalSize();
    }, [uploadSize])

    const getAllQuotationsForProject = async () => {
        props.setLoading(true)
        try {
          const response = await HttpService.getAllQuotationsForProject(projectId)
          setstoreLatestQuotation(response.data)
          setQuotationList(response.data)
        } catch (error) {
          console.error(error)
          props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
        }
        props.setLoading(false)
      }

          
    const handleCheckboxChange = (event, quotation) => {
        if (event.target.checked) {
            setShowButton(true)
          setSelectedQuotation(quotation);
          updateSelectedQuotation(quotation)
          setOpenDialog(false)
        } else {
          setSelectedQuotation(null);
          setOpenDialog(false)
          setShowButton(true)
        }
      };

      const updateSelectedQuotation = async(quotation) =>{
        try{
            let obj ={
                latestQuotationShared: quotation.s3Location,
                leadId: quotation.leadId._id,
                designVersion:quotation.version,
                grandTotal: quotation.grandTotal
            }
         let res = await HttpService.updateDesignQuotation(obj)
         getAllQuotationsForProject();
         getcustomerDetailsWithVersion()
         if(res.status == 200){
            setQuotationSelected(true)
         }
        }catch(error){
            console.log(error)
        }
      }
    

    const getAllSiteQc = async () =>
    {
        props.setLoading(true)

        try {
            const response = await HttpService.getAllSiteQcDetails()

            setAllSiteQc(response.data)
        }
        catch (error) {
            console.log("error: ", error.message)

            console.error(error)
        }

        props.setLoading(false)
    }

    const handleAssignSiteQc = async () => {
        props.setLoading(true)
        try {
            let data =
            {
                leadId: projectId,
                siteQcUserId: selectedSiteQcUserData._id,
                siteQcUserTeamId: selectedSiteQcUserData.teamId._id,
                siteQcUserTeamName: selectedSiteQcUserData.teamId.name,
                siteQcUserDepttId: selectedSiteQcUserData.departmentId,
                siteQcUserName: selectedSiteQcUserData.name,
                siteQcUserEmail: selectedSiteQcUserData.email
            }
            const response = await HttpService.assignSiteQC_Manually(data)
            setOpenAssignSiteQcModel(false)
            props.setSnackInfo(`You assigned ${selectedSiteQcUserData.name}  as a Site QC for this project`)
            setSelectedSiteQcUserData({})
            setReRender(true)
            setReRender(false)
        }
        catch (error) {
            console.log("error", error.message)
            console.error(error)
        }
        props.setLoading(false)
    }

    const getProjectLogs = async () => {
        props.setLoading(true)
        try {
            const response = await HttpService.getProjectLog(projectId)
            setProjectLogs(response.data)
        } catch (error) {
            console.log("No logs found")
        }
        props.setLoading(false)
    }

      const cloneSelectedVersion = async () =>{
        let lastVersion = quotationList[quotationList.length - 1];
        let obj = {
          versionId: selectedQuotationVersion,
          leadId: projectId,
         lastVersionNo: lastVersion.version,
        }
        props.setLoading(true)
        try {
            uploadWDFileDocument(quotationCsv,"quotationcsv");
          const response = await HttpService.cloneSelectedQuotation(obj)
          getAllQuotationsForProject();
          setOpenSelectQuotationVersion(false)
          props.setSnackInfo("File Uploaded Successfully", "success")

        } catch (error) {
          props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false);
      }

      const generateQuotationExcel = async (quotationId) => {
        props.setLoading(true)
        try {
          const response = await HttpService.generateQuotationExcel(quotationId)

          let results = response?.data?.components;
          var data = [];
    
          for (let i = 0; i < results.length; i++) {
            for (let j = 0; j < results[i]['products'].length; j++) {
              let obj = {};
              obj['categoryname'] = results[i]['categoryId']?.['name'];
              obj['code'] = results[i]['products'][j]['product']?.['code'];
              obj['productname'] = results[i]['products'][j]['product']?.['name'];
              obj['description'] = results[i]['products'][j]['product']?.['description'];
              if (results[i]['products'][j]['subUnitType'] === 'Area') {
                obj['plyType'] = results[i]['plyType']?.['name'];
                obj['finishType'] = results[i]['products'][j]['finishType']?.['name'];
                obj['Qty'] = String(1);
              } else {
                obj['plyType'] = 'NA';
                obj['finishType'] = 'NA'
                obj['Qty'] = results[i]['products'][j]['area']
              }
    
              if (results[i]['products'][j]['subUnitType'] === 'Qty') {
                obj['area'] = String(0);
              } else {
                if (results[i]['products'][j]['productCustomArea'] !== 0) {
                  obj['area'] = results[i]['products'][j]['productCustomArea']
                } else {
                  obj['area'] = results[i]['products'][j]['area']
                }
              }
    
              obj['length'] = results[i]['products'][j]['length'];
              obj['width'] = results[i]['products'][j]['width'];
              obj['unittype'] = results[i]['products'][j]['unitType'] !== null ? results[i]['products'][j]['unitType']?.['name'] : 'null';
              obj['cost'] = results[i]['products'][j]['productCustomPrice'] === 0 ? results[i]['products'][j]['totalPricePerUnit'] : results[i]['products'][j]['productCustomPrice'];
              obj['itemTotal'] = results[i]['products'][j]['productPrice'];
              // When scope is enable that time remove the comment from next line
              obj['scope'] = results[i]['products'][j]['product']['scopeId']['name'];
    
              if (i === 0 && j === 0) {
                if (projectDetails.discountOnAllScopes === true) {
                  obj['quotationValue'] = response?.data?.['totalAmount'];
                  obj['discountPercent'] = response?.data?.['discountPercent'];
                  obj['discount'] = response?.data?.['discountAmount'] ? response?.data?.['discountAmount'] : 0;
                  obj['subTotal'] = response?.data?.['tempSt'] ? response?.data?.['tempSt'] : 0;
                  //obj['customerToProc'] = response?.data?.['customerToBeProcured'] ? response?.data?.['customerToBeProcured'] : 0;
                  //obj['customerToProcPercent'] = response?.data?.['materialProcured'] ? response?.data?.['materialProcured'] : 0;
                  //obj['decorpotScope'] = response?.data?.['decorpotScope'] ? response?.data?.['decorpotScope'] : 0;
                  obj['gst'] = response?.data?.['gstValue'] ? response?.data?.['gstValue'] : 0;
                  obj['miscellaneous'] = response?.data?.['miscTotal'] ? response?.data?.['miscTotal'] : 0;
                  obj['total'] = response?.data?.['mainTotal'] ? response?.data?.['mainTotal'] : 0;
                  obj['customerTotalOutflow'] = response?.data?.['totalCustomerOutflow'] ? response?.data?.['totalCustomerOutflow'] : 0;
                } else {
                  obj['discountPercent'] = response?.data?.['discountPercent'];
                  obj['discount'] = response?.data?.['discountAmount'] ? response?.data?.['discountAmount'] : 0;
                  if (response?.data?.['discItemsSubTotal' && response?.data?.['totalNonDiscPrAmnt']]) {
                    obj['quotationValue'] = response?.data?.['totalDiscPrAmnt'] + response?.data?.['totalNonDiscPrAmnt'];
                    obj['subTotal'] = response?.data?.['discItemsSubTotal'] ? response?.data?.['discItemsSubTotal'] + response?.data?.['totalNonDiscPrAmnt'] : 0 + response?.data?.['totalNonDiscPrAmnt'];
                  } else {
                    obj['quotationValue'] = response?.data?.['totalAmount'];
                    obj['subTotal'] = response?.data?.['tempSt'] ? response?.data?.['tempSt'] : 0;
                  }
                  obj['gst'] = response?.data?.['gstValue'] ? response?.data?.['gstValue'] : 0;
                  obj['miscellaneous'] = response?.data?.['miscTotal'] ? response?.data?.['miscTotal'] : 0;
                  obj['total'] = response?.data?.['mainTotal'] ? response?.data?.['mainTotal'] : 0;
                  obj['customerTotalOutflow'] = response?.data?.['totalCustomerOutflow'] ? response?.data?.['totalCustomerOutflow'] : 0;
                }
              }
              data.push(obj);
            }
          }
    
          if (response.data.hasOwnProperty('miscellaneousComponent')) {
            response.data['miscellaneousComponent']['products'].forEach((misc) => {
              let obj = {}
              obj['categoryname'] = response.data['miscellaneousComponent']['projectComponent']['name'];
              obj['code'] = misc['product']['code'];
              obj['productname'] = misc['product']['name'];
              obj['description'] = misc['product']['description'];
              if (misc['subUnitType'] === 'Area') {
                obj['plyType'] = response.data['miscellaneousComponent']['name'];
                obj['finishType'] = misc['finishType']['name'];
                obj['Qty'] = String(1);
              } else {
                obj['plyType'] = 'NA';
                obj['finishType'] = 'NA'
                obj['Qty'] = misc['area']
              }
              if (misc['subUnitType'] === 'Qty') {
                obj['area'] = String(0);
              } else {
                if (misc['productCustomArea'] !== 0) {
                  obj['area'] = misc['productCustomArea']
                } else {
                  obj['area'] = misc['area']
                }
              }
              obj['length'] = misc['length'];
              obj['width'] = misc['width'];
              obj['unittype'] = misc['unitType']['name'];
              obj['cost'] = misc['productPricePerUnit'];
              obj['itemTotal'] = misc['productPrice'];
              obj['scope'] = misc['product']['scopeId']['name'];
              data.push(obj);
            })
          }

          // Convert the data to a worksheet
          const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.json_to_sheet(data);
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

            // Convert the worksheet to a CSV string
            const csvData = XLSX.utils.sheet_to_csv(worksheet);

            // Convert the CSV string to a Blob
            const csvBlob = new Blob([csvData], { type: 'text/csv' });

            // Create a FormData object and append the CSV Blob
            const fd = new FormData();
            fd.append('file', csvBlob, 'filename.csv'); // 'filename.csv' is the name you want to give to the file
            fd.append('customerName', projectDetails.lead_no);

            // Upload the CSV file
            const fileUploadRes = await HttpService.uploadFile(fd);
            setQuotationCsv(fileUploadRes.data);
            console.log("fileUploadRes.data",fileUploadRes.data);
            // uploadWDFileDocument(fileUploadRes.data, 'quotationcsv');
            return data;

        } catch (error) {
          console.error(error)
          props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
        }
        props.setLoading(false)
      }

      const getcustomerDetailsWithVersion = async () => {
        props.setLoading(true)
        try {
            const response = await HttpService.getProjectLeadDetails(projectId)
            // console.log('getProjectLeadDetails', response)
            console.log(response.data[0].designVersion,"this is design called")
            setStoreVersion(response.data[0].designVersion)
        } catch (error) {
            props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const getcustomerDetails = async () => {
        props.setLoading(true)
        try {
            const response = await HttpService.getProjectLeadDetails(projectId)
            // console.log('getProjectLeadDetails', response)
            setProjectDetails(response.data[0])
            setLeadDesignHoldReason(response.data[0].designHoldReason)
            // setProposedDesignStartDate(response.data[0].proposedDesignStartDate)
            if (userDetails?.roles?.find(role => role.name === ROLENAME.DesignHead || role.name === ROLENAME.DesignManager || role.name === ROLENAME.OperationDesignManager || role.name === ROLENAME.AssistantDesignManager || role.name === ROLENAME.DesignUser)) {
                setSelectedStage(response.data[0]['designStages']);
                setSelectedDesignStatus(response.data[0]['designStatus'])
            } else {
                setSelectedStage(response.data[0]['salesStage']);
                if (response.data[0]['status'] === 'Lead Received') {
                    setCheckStageStatus(true)
                } else {
                    setCheckStageStatus(false)
                }
                setLeadStageStatus(response.data[0]['status'])
            }
            let date = new Date()
            if (response.data[0].designSignOffDate) {
                let str = moment(response.data[0]?.designSignOffDate).valueOf()
                let time_difference = date.getTime() - str
                let result = time_difference / (1000 * 60 * 60 * 24);
                setDaysFromContractSign(result);
            }
            if (response.data[0]?.materialDispatchedDate) {
                let materialdispatch = moment(response.data[0]?.materialDispatchedDate).valueOf()
                let materialDiff = date.getTime() - materialdispatch
                let materialResult = materialDiff / (1000 * 60 * 60 * 24);
                setDaysFromModularDelivery(materialResult)
            }
        } catch (error) {
            props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const openCreateNotesModal = () => {
        getAllUsers();
        setOpenNotesModal(true);
        setFormData({
            ...formData,
            user: projectDetails?.assignTo?._id,
            stage: userDetails?.roles?.find(role => role.name === ROLENAME.SalesHead || role.name === ROLENAME.SalesManager || role.name === ROLENAME.SalesUser || role.name === ROLENAME.AssistantSalesManager) ? projectDetails?.salesStage : projectDetails?.designStages,
            leadId: projectId
        })
    }

    const handleDesignKickOffScreenshots = () => {
        try {
            setOpenDesignKickOffScreenshotsModal(true)
        }
        catch (error) {
            console.log('error', error)
            console.error(error)
        }

    }

    const [allUsersList, setAllUsersList] = useState([]);
    const getAllUsers = async () => {
        // console.log('called')
        props.setLoading(true)
        try {
            const response = await HttpService.getAllUsers()
            let userData = [];
            response.data.forEach((ele) => {
                userData.push({
                    name: ele.name,
                    userId: ele._id
                })
            })
            setAllUsersList(userData)

        } catch (error) {
            props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );
    const [mentionOpen, setMentionOpen] = useState(false);
    const [suggestions, setSuggestions] = useState(allUsersList);

    const { MentionSuggestions, plugins } = useMemo(() => {
        const mentionPlugin = createMentionPlugin();
        // eslint-disable-next-line no-shadow
        const { MentionSuggestions } = mentionPlugin;
        // eslint-disable-next-line no-shadow
        const plugins = [mentionPlugin];
        return { plugins, MentionSuggestions };
    }, []);

    const setEditorStateData = (editorState) => {
        setEditorState(editorState);
        setFormData({ ...formData, 'notes': editorRef.current?.editor?.editor?.innerText })
    };

    const onOpenChange = (mentionOpen) => {
        if (mentionOpen === false) {
            setMentionOpen(false)
        } else {
            setMentionOpen(true)
        }
    };

    const onSearchChange = ({ value }) => {
        setSuggestions(defaultSuggestionsFilter(value, allUsersList));
    };

    const onAddMention = (mention) => {
        setMentionOpen(false)
    }

    const [openStatusModal, setOpenStatusModal] = useState(false);
    const openStatusUpdateModal = () => {
        setOpenStatusModal(true);
        getSalesManagerList();
    }

    const [leadStageStatus, setLeadStageStatus] = useState('');
    const [checkStageStatus, setCheckStageStatus] = useState(false)
    const [selectedDesignStatus, setSelectedDesignStatus] = useState('');
    const [leadDesignHoldReason, setLeadDesignHoldReason] = useState('');
    const [proposedDesignStartDate, setProposedDesignStartDate] = useState('');

    const designStageStatusChange = (e) => {
        setDesignStageStatus(e.target.value)
        setSelectedDesignStatus(e.target.value)
    }

    const leadStageStatusChange = (e) => {
        if (e.target.value === 'Lead Received') {
            setCheckStageStatus(true)
            getSalesManagerList();
        } else {
            setCheckStageStatus(false)
        }
        setLeadStageStatus(e.target.value)
    }
    const [designStageStatus, setDesignStageStatus] = useState('Design');
    const updateLeadStatus = async () => {
        let currentDate = moment().toISOString(new Date());
        if (checkStageStatus === true) {
            selectedSalesManager.status = leadStageStatus;
            selectedSalesManager.leadType = leadStageStatus === 'Lead Received' ? 'Real' : 'To Be Called';
            selectedSalesManager.stage = leadStageStatus === 'Lead Received' ? 'Lead Received' : stage;
            // selectedSalesManager.leadStatus = 'Won'
            // console.log(selectedSalesManager, 'selectedSalesManager');
            props.setLoading(true)
            try {
                await HttpService.assignProject(selectedSalesManager)
                props.setSnackInfo("Sales Manager Assigned Successfully", "success")
                setOpenStatusModal(false)
                getcustomerDetails();
                getProjectLogs();
            } catch (error) {
                props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
            }
            props.setLoading(false)
        } else {
            // let obj = {}
            let obj = {
                designHoldStatusApproval: {}
            }
            if (userDetails.departmentId === designDepartment) {
                // obj.designStatus = designStageStatus;
                // obj.designHoldReason = designStageStatus === 'Design-Hold' ? leadDesignHoldReason : 'NA';
                // if (designStageStatus === 'Design-Hold' || designStageStatus === 'Design Hold - Possession more than 3 months') {
                //     obj.proposedDesignStartDate = proposedDesignStartDate;
                // }
                obj.designStatus = designStageStatus;  //design status
                obj.designHoldSelectedTypes = designStageStatus === 'Design-Hold' ? designHoldSelectedTypes : 'NA'  //design Hold Types
                obj.designHoldReason = designStageStatus === 'Design-Hold' ? leadDesignHoldReason : 'NA';   //remark
                obj.proposedDesignStartDate = designStageStatus === 'Design-Hold' ? proposedDesignStartDate : currentDate;  //design probable date restarting 
                obj.probableDesignSignOffDate =  designStageStatus === 'Design-Hold' ? probableDesignSignOffDate : currentDate; // probable design sign-off date
                obj.designHoldStatusApproval.admApproval =  selectedDesignStatus === 'Design-Hold' ? 'Sent for Approval' : '';
                obj.designHoldStatusApproval.odmApproval =  selectedDesignStatus === 'Design-Hold' ? 'Not Initiated' : '';
                obj.designHoldStatusApproval.customerApproval =  selectedDesignStatus === 'Design-Hold' ? 'Not Initiated' : '';
            } else {
                obj.leadStatus = 'Open'
                obj.status = leadStageStatus;
                obj.leadType = leadStageStatus === 'Lead Received' ? 'Real' : leadStageStatus === 'Junk' ? 'Junk' : leadStageStatus === 'Hold' ? 'Hold' : 'To Be Called';
                obj.stage = leadStageStatus === 'Lead Received' ? 'Lead Received' : stage;
            }
            // console.log(obj, 'Obj')
            props.setLoading(true)
            try {
                const response = await HttpService.updateLeadStageStatus(obj, projectId)
                props.setSnackInfo(response?.data ?? "Something went wrong", "success")
                setOpenStatusModal(false);
                getcustomerDetails();
                getProjectLogs();
            } catch (error) {
                props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
            }
            props.setLoading(false)
        }
    }

    const saveNotes = async () => {
        const contentState = editorState.getCurrentContent();
        const raw = convertToRaw(contentState);
        let mentionedUsers = [];
        for (let key in raw.entityMap) {
            const ent = raw.entityMap[key];
            if (ent.type === "mention") {
                mentionedUsers.push(ent.data.mention);
            }
        }
        let taggedUser = [];
        mentionedUsers.forEach((ele, i) => {
            taggedUser.push(ele.userId);
        })

        let obj = {
            ...formData,
            taggedUser: taggedUser
        }
        // console.log(obj, 'obj formdata')

        props.setLoading(true);
        try {
            const addNoteResponse = await HttpService.addNotes(obj);
            if (addNoteResponse.status === 200) {
                setOpenNotesModal(false);
                getProjectLogs();
                getcustomerDetails();
                setFormData({
                    ...formData,
                    s3Location: "",
                    reminderDate: ""
                })
                props.setSnackInfo("Notes Added Successfully", "success")
            }
        } catch (err) {
            props.setSnackInfo(err?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false);
    }

    const uploadCreateNoteImg = async (e) => {
        props.setLoading(true)
        const fd = new FormData()
        fd.append('file', e.target.files[0])
        fd.append('customerName', projectDetails.lead_no + '_' + projectDetails.customerId.name)
        try {
            const fileUploadRes = await HttpService.uploadFile(fd);
            setFormData({
                ...formData,
                s3Location: fileUploadRes.data
            })
            setFileUploaded(true);
        } catch (err) {
            props.setSnackInfo(err?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const [openActivity, setOpenActivity] = useState('')
    const [paymentLogsData, setPaymentLogsData] = useState(
        [
            // { date: '2023-09-25', value: 100 },
            // { date: '2023-09-26', value: 150 },
            // { date: '2023-09-27', value: 200 }
        ]);
    const [showPaymentLogs, setShowPaymentLogs] = useState(false)
    const showInfo = (i, status) => {
        setStageIndex(i);
        setOpenNotesInfoModal(true);
        setStageInfo(projectLogs[i]['stage'])
        setOpenActivity(status)
    }

    const downloadNotesFile = (data) => {
        data.s3Location.forEach((link) => {
            if (link) {
                try {
                    window.open(link);
                } catch (error) {
                    console.error(`Failed to open link: ${link}`, error);
                }
            }
        });
    };

    const handleViewLogsClick = async (data) => {
        props.setLoading(true)
        try {
            //   console.log('handleViewLogsClick called')
            //   console.log('data', data)
            const response = await HttpService.getPaymentLogsOfLead({ leadId: data.leadId })
            //   console.log('paymentLogs response', response.data)
            setPaymentLogsData(response.data)
            setShowPaymentLogs(true)
        }
        catch (error) {
            console.log('error', error.message)
            console.error(error)
        }
        props.setLoading(false)
    }

    const viewQuotationPage = () => {
        props.history.push(`/manageQuotations/${projectId}`);
    }
    const [projectDetailsFields, setProjectDetailsFields] = useState({
        name: "",
        email: "",
        address: "",
        contact_no: "",
        projectType: "",
        propertyType: "",
        scopeOfWork: "",
        description: "",
        floorPlan: "",
        isRegistered: "",
        area: "",
        leadType: "",
        alternativeContactNo: "",
        discountOnAllScopes: "",
        assignTo: "",
        leadId: "",
        assignToManager: ""
    })
    const { name, email, contact_no, projectType, propertyType, alternativeContactNo, scopeOfWork, address, description, floorPlan, isRegistered, area, leadType, discountOnAllScopes, leadId, assignTo, assignToManager } = projectDetailsFields

    const openEditProjectModal = () => {
        setEditProjectModal(true);
        getSalesManagerList();
        setProjectDetailsFields({
            // plyType: projectDetails.plyType._id,
            name: projectDetails?.customerId.name,
            email: projectDetails?.customerId.email.trim(),
            contact_no: projectDetails?.customerId?.contact_no,
            alternativeContactNo: projectDetails?.customerId?.alternativeContactNo,
            projectType: projectDetails?.projectType,
            propertyType: projectDetails?.propertyType,
            scopeOfWork: projectDetails?.scopeOfWork,
            description: projectDetails?.remark,
            floorPlan: projectDetails?.floorPlan,
            floorPlanAttachment: projectDetails?.floorPlanAttachment,
            isRegistered: projectDetails?.isRegistered,
            leadType: projectDetails?.leadType,
            assignTo: projectDetails.assignTo._id,
            area: projectDetails?.area,
            address: projectDetails?.customerId?.address,
            discountOnAllScopes: projectDetails?.discountOnAllScopes
        })
    }
    const handlePayment = () => {
        getPaymentPreview()
        setPaymentDetailsModal(true)
    }

    // const getPaymentPreview = async() =>{
    //     try {
    //         const user = await HttpService.getPaymentPreview(projectId);
    //         let paymentDetails = []
    //         user.data.forEach((el,i)=>{
    //             console.log(el.customerName,"this is name")
    //             let obj={
    //              customerName : el.customerName ? el.customerName : 'NA',
    //              uniqueLeadId : el?.paymentLogsId?.TransactionNumber ? el?.paymentLogsId?.TransactionNumber : 'NA',
    //              createdAt: el.createdAt ? moment(el?.createdAt).format('DD-MM-YYYY') : "-",
    //              Amount : el.Amount ? el.Amount : 0,
    //              Status : el.Status ? el.Status : 'NA',
    //              Stage : el.Stage ? el.Stage : "NA",
    //              createdBy : el.createdBy.name ? el.createdBy.name : "NA",
    //              departmentId : el.departmentId.name ? el.departmentId.name : "NA",
    //              paymentMode: el.TransactionData.map((transaction, i) =>
    //              transaction.Tag === 'PaymentMode' ? transaction.Value : null
    //          ).filter(payment => payment !== null)
    //             }
    //             console.log(obj,"this is obj")
    //             paymentDetails.push(obj)
    //         })
    //         console.log(paymentDetails,"detaus is called")
    //         setPaymentPreview(paymentDetails)
    //     }
    //     catch(error) {
    //         console.log(error)
    //     }
    // }

    // const getPaymentPreview = async() =>{
    //     try {
    //         const user = await HttpService.getPaymentPreview(projectId);
    //         let paymentDetails = []
    //         console.log('user', user)
    //         user?.data?.paymentTransactions?.forEach((el, i) =>
    //         {
    //         let obj =
    //         {
    //             _id: el._id,
    //             leadId: el.leadId,
    //             customerName: el?.customerName ? el.customerName : "NA",
    //             newUniqueId: el?.leadId?.newUniqueLeadId ? el?.leadId?.newUniqueLeadId : "NA",
    //             uniqueLeadNumber: el?.leadId?.uniqueLeadNumber ? el?.leadId?.uniqueLeadNumber : "NA",
    //             transactionNumber: el?.cashTransactionNumber ? el.cashTransactionNumber : el?.paymentLogsId?.TransactionNumber ? el?.paymentLogsId?.TransactionNumber : "NA",
    //             createdAt: el?.createdAt ? moment(el?.createdAt).format("DD-MM-YYYY") : "-",
    //             Amount: el?.Amount ? el.Amount : 0,
    //             Status: el?.Status ? el.Status : "NA",
    //             Stage: el?.Stage ? el?.Stage : "NA",
    //             createdByUser: el?.createdBy.name ? el?.createdBy.name : "NA",
    //             createdBy: el?.createdBy?._id ? el?.createdBy?._id : "NA",
    //             departmentName: el?.departmentId?.name ? el?.departmentId.name : "NA",
    //             departmentId: el?.departmentId?._id ? el?.departmentId?._id : "NA",
    //             paymentMode: el?.selectedPaymentMode ? el?.selectedPaymentMode : el?.TransactionData?.map((transaction, i) => transaction.Tag === "PaymentMode" ? transaction.Value : null ).filter((payment) => payment !== null),
    //             slip_no: el.slip_no ? el.slip_no : 'NA',
    //             trxId: el.trxId ? el.trxId : 'NA',
    //             cheQueNo: el.cheQueNo ? el.cheQueNo : 'NA',
    //             paymentPartnerName: el?.paymentPatnerId?.name ? el?.paymentPatnerId?.name : "NA",
    //             financeAction: el.financeAction ? el.financeAction : 'NA',
    //             financeActionStatus: el.financeActionStatus,
    //             financeActionRemarks: el.financeActionRemarks,
    //             financeEditRemarks: el.financeEditRemarks,
    //             paymentLogs : el.paymentLogs ? el.paymentLogs : []
    //         };
    //         console.log('obj', obj)
    //         paymentDetails.push(obj);

    //         });

    //         console.log('paymentDetails', paymentDetails)

    //         // setPaymentInfo(paymentDetails);
    //         setPaymentPreview(paymentDetails)
    //     }
    //     catch(error) {
    //         console.log(error)
    //     }
    // }
    const getPaymentPreview = async () => {
        try {
            // const user = await HttpService.getPaymentPreview(projectId);
            let payload = {}
            payload.leadId = projectId
            // console.log('getPaymentPreview function called, payload', payload)
            let result = await HttpService.getApprovedPaymentTransactionsForLead(payload)
            let paymentDetails = []
            // console.log('result', result)
            let data = result.data
            let cust_id_data = {}
            let temp_data = []

            for (let i = 0; i < data.length; i++) {
                let obj = {}

                if (!cust_id_data[`${data[i].leadId.uniqueLeadNumber}`]) {
                    cust_id_data[`${data[i].leadId.uniqueLeadNumber}`] = {}
                }

                cust_id_data[`${data[i].leadId.uniqueLeadNumber}`].leadId = data[i].leadId._id
                cust_id_data[`${data[i].leadId.uniqueLeadNumber}`].amount_requested = (cust_id_data[`${data[i].leadId.uniqueLeadNumber}`].amount_requested + data[i].Amount_Requested || data[i].Amount_Requested)
                if (data[i].selectedPaymentModeType == "Automatic") {
                    cust_id_data[`${data[i].leadId.uniqueLeadNumber}`].amount_received_auto_ping = (cust_id_data[`${data[i].leadId.uniqueLeadNumber}`].amount_received_auto_ping + data[i].Amount_Received || data[i].Amount_Received)
                }
                else {
                    cust_id_data[`${data[i].leadId.uniqueLeadNumber}`].amount_received_manual_ping = (cust_id_data[`${data[i].leadId.uniqueLeadNumber}`].amount_received_manual_ping + data[i].Amount_Received || data[i].Amount_Received)
                }


            }

            // console.log('cust_id_data', cust_id_data)

            let keys = Object.keys(cust_id_data)

            // console.log('keys', keys)

            for (let val of keys) {
                // console.log('val', val)
                // console.log('data', cust_id_data[`${val}`])

                let obj = {}
                obj.leadId = cust_id_data[`${val}`][`leadId`]
                obj.account_number = val
                obj['amount_requested'] = cust_id_data[`${val}`][`amount_requested`] || 0
                obj['amount_received_auto_ping'] = cust_id_data[`${val}`][`amount_received_auto_ping`] || 0
                obj['amount_received_manual_ping'] = cust_id_data[`${val}`][`amount_received_manual_ping`] || 0

                temp_data.push(obj)
            }
            // console.log('temp_data', temp_data)
            setCustomerInformation(temp_data)
            // result?.data?.paymentTransactions?.forEach((el, i) =>
            // {
            // let obj =
            // {
            //     _id: el._id,
            //     leadId: el.leadId,
            //     customerName: el?.customerName ? el.customerName : "NA",
            //     newUniqueId: el?.leadId?.newUniqueLeadId ? el?.leadId?.newUniqueLeadId : "NA",
            //     uniqueLeadNumber: el?.leadId?.uniqueLeadNumber ? el?.leadId?.uniqueLeadNumber : "NA",
            //     transactionNumber: el?.cashTransactionNumber ? el.cashTransactionNumber : el?.paymentLogsId?.TransactionNumber ? el?.paymentLogsId?.TransactionNumber : "NA",
            //     createdAt: el?.createdAt ? moment(el?.createdAt).format("DD-MM-YYYY") : "-",
            //     Amount: el?.Amount ? el.Amount : 0,
            //     Status: el?.Status ? el.Status : "NA",
            //     Stage: el?.Stage ? el?.Stage : "NA",
            //     createdByUser: el?.createdBy.name ? el?.createdBy.name : "NA",
            //     createdBy: el?.createdBy?._id ? el?.createdBy?._id : "NA",
            //     departmentName: el?.departmentId?.name ? el?.departmentId.name : "NA",
            //     departmentId: el?.departmentId?._id ? el?.departmentId?._id : "NA",
            //     paymentMode: el?.selectedPaymentMode ? el?.selectedPaymentMode : el?.TransactionData?.map((transaction, i) => transaction.Tag === "PaymentMode" ? transaction.Value : null ).filter((payment) => payment !== null),
            //     slip_no: el.slip_no ? el.slip_no : 'NA',
            //     trxId: el.trxId ? el.trxId : 'NA',
            //     cheQueNo: el.cheQueNo ? el.cheQueNo : 'NA',
            //     paymentPartnerName: el?.paymentPatnerId?.name ? el?.paymentPatnerId?.name : "NA",
            //     financeAction: el.financeAction ? el.financeAction : 'NA',
            //     financeActionStatus: el.financeActionStatus,
            //     financeActionRemarks: el.financeActionRemarks,
            //     financeEditRemarks: el.financeEditRemarks,
            //     paymentLogs : el.paymentLogs ? el.paymentLogs : []
            // };
            // console.log('obj', obj)
            // paymentDetails.push(obj);

            // });

            // console.log('paymentDetails', paymentDetails)

            // // setPaymentInfo(paymentDetails);
            // setPaymentPreview(paymentDetails)
        }
        catch (error) {
            console.log(error)
        }
    }


    const changeHandler = (e) => {
        setProjectDetailsFields(e.target.value);
        if (e.target.value === 'Real') {
            getSalesManagerList()
            setProjectDetailsFields({ ...projectDetailsFields, [e.target.name]: e.target.value })
        } else if (e.target.name === 'assignToManager') {
            let teamDetail = [];
            dupManagerOfSalesTeam.forEach(ele => {
                if (ele?._id === e.target.value) {
                    teamDetail.push(ele)
                }
            })
            setProjectDetailsFields({
                ...projectDetailsFields, [e.target.name]: e.target.value,
                'assignTo': teamDetail[0]._id,
                'department': teamDetail[0].departmentId._id,
                'team': teamDetail[0].teamId._id
            })
        } else {
            setProjectDetailsFields({ ...projectDetailsFields, [e.target.name]: e.target.value })
        }
    }

    // function to handle input of customer name
    const customerNameInputHandler = (event) => {
        try {
            const value = event.target.value;
            // Regex pattern to check for the presence of only letters (uppercase & lowercase) and spaces and excluding special characters and numeric value
            const specialCharacters = /^[a-zA-Z\s]*$/;
            const containsSpecialCharacters = !specialCharacters.test(value);
            // If special characters is present display alert
            if (containsSpecialCharacters) {
                props.setSnackInfo("Letters and spaces only. No special characters.", "warning");
            } else {
                setProjectDetailsFields({ ...projectDetailsFields, [event.target.name]: event.target.value });
            }
        } catch (error) {
            console.log("Error -> ", error);
        }
    }
    // function to handle the input of sqft area
    const sqFtAreaInputHandler = (event) => {
        // regex to except only number in input field
        let area = event.target.value.replace(/[^0-9]/g, "");
        setProjectDetailsFields({ ...projectDetailsFields, [event.target.name]: area })
    }

    const setAdress = (event) => {
        const { name, value } = event.target;
        setProjectDetailsFields({ ...projectDetailsFields, [name]: value })
    }


    // function to handle input of phone number
    const phoneNumberInputHandler = (event) => {
        // regex to except only number in input field
        let mobileNo = event.target.value.replace(/[^0-9]/g, "");
        setProjectDetailsFields({ ...projectDetailsFields, [event.target.name]: mobileNo })
    }

    const [designKickOffCustomerForm, setDesignKickOffCustomerForm] = useState({
        salesOwnerMeetingDate: '',
        floorPlanReceived: '',
        quotationDiscussed: '',
        requirementDiscussed: ''
    });

    const captureDesignKickOffCustomerDetails = (e, v) => {
        setDesignKickOffCustomerForm(
            {
                ...designKickOffCustomerForm,
                [e.target.name]: e.target.value
            })
    }

    const saveProjectDetail = async () => {
        // console.log(projectDetailsFields, "projectDetailsFields");
        props.setLoading(true)
        try {
            const response = await HttpService.saveProjectDetails(projectId, projectDetailsFields)
            setEditProjectModal(false);
            getProjectLogs();
            getcustomerDetails();
            props.setSnackInfo(response.data, "success")
        } catch (error) {
            props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }
    const [open, setOpen] = useState(false);
    const [anotherDialog, setOpenAnotherDialog] = useState(false);
    const [departmentLists, setDepartmentsList] = useState([]);
    const [departmentStage, setDepartmentsStage] = useState([]);
    const [stage, setSelectedStage] = useState("");
    const [department, setSelectedDepartment] = useState("");
    const [team, setSelectedTeam] = useState("");
    const [dealOwner, setSelectedDealOwner] = useState("");
    // const [wdFile, setWDFileLink] = useState("");
    const [requirements, setRequirements] = useState("");
    const [estimatedBudget, setEstimateBudget] = useState("");
    const [salesCallComment, setSalesCallComment] = useState("");
    const [momQuotationStage, setMomQuotationStage] = useState("");
    const [momMeetingStage, setMomMeetingStage] = useState("");
    const [momSiteVisitStage, setMomSiteVisitStage] = useState("");
    const [momNegotiationStage, setMomNegotiationStage] = useState("");
    const [remark, setRemark] = useState("");
    const [preSalesCallDate, setHandlepreSalesCallDate] = useState("");
    const [nextFollowUpDate, setHandleNextFollowUpDate] = useState("");
    const [salesCallDate, setHandleSalesCallDate] = useState("");
    const [quotationSentDate, setQuotationSentDate] = useState("");
    const [siteVisitDate, setSiteVisitDate] = useState("");
    const [negotiationDate, setNegotiationDate] = useState("");
    const [closureDate, setClosureDate] = useState(new Date("2018-01-01T00:00:00.000Z"));
    const [clientMoveinDate, setClientMoveinDate] = useState(new Date("2018-01-01T00:00:00.000Z"));
    // const [contractSignedDate, setContractSignedDate] = useState(new Date("2018-01-01T00:00:00.000Z"));
    // const [projectCompletionDate, setProjectCompletionDate] = useState(new Date("2018-01-01T00:00:00.000Z"));
    const [teamLists, setTeamList] = useState([]);
    const [dealOwnerLists, setDealOwnersList] = useState([]);
    // const [openSalesManagerModal, setOpenSalesManagerModal] = useState(false);
    const [selectedSalesManager, setSelectedSalesManager] = useState({});
    const [salesManagerList, setSalesManagerList] = useState([]);
    // const [salesData, setsalesData] = useState([]);
    const [dupManagerOfSalesTeam, setDupManagerOfSalesTeam] = useState([]);
    const [meetingDone, setMeetingDone] = useState("")
    const [meetingType, setMeetingType] = useState("")
    const [meetingDate, setMeetingDate] = useState(null);
    const [newPaymentSlip, setNewPaymentSlip] = useState("")
    const [amountPaid, setAmountPaid] = useState(0)
    const [checkForSales, setCheckForSales] = useState(false)
    const [checkForFloor, setCheckForFloor] = useState(false)
    const [floorType, setFloorType] = useState("");
    const [chmList, setChmList] = useState([]);
    const [chmUser, setSelectedChmUser] = useState('');
    const [openChmUser, setOpenChmUser] = useState(false);
    const [erpProjectExists, setErpProjectExists] = useState(false);
    const [managerOrHeadId, setManagerOrHeadId] = useState('');
    const [qtVersionGrandTotal, setQtVersionGrandTotal]  = useState(0)
    const [contractSignStageForm, setContractSignStageForm] = useState({
        grandTotal: 0,
        qtVersionGrandTotal:0,
        contractSignedValue: 0,
        tokenPercent: 0,
        daysAsPerContractSign: 0,
        designSignOffDate: null,
        projectCompletionDate: null,
        DesignSignOffRevokeDate: null,
        internalDesignSignOffDate: null
    })
    const [files, setFiles] = useState([""]);
    const [callLogs, setCallLogs] = useState([])


    const hasAttachments = files.every(file => file !== "");
    const onImageChangeCheck = async (e, index) => {
        const newFiles = [...files];
        const newFileUploaded = [...checkFileUploaded];

        props.setLoading(true);
        const fd = new FormData();
        fd.append("file", e.target.files[0]);
        fd.append('customerName', projectDetails.lead_no);

        const file = e.target.files[0]
        const fileSize = file.size;
        let newPaymentRecievedAttachments = uploadSize.paymentRecievedAttachments;
        newPaymentRecievedAttachments[index] = fileSize
        // console.log('newPaymentRecievedAttachments is ',newPaymentRecievedAttachments)
        setUploadSize({
            ...uploadSize,
            paymentRecievedAttachments: newPaymentRecievedAttachments
        })
        try {
            const fileUploadRes = await HttpService.uploadFile(fd);
            newFiles[index] = fileUploadRes.data;
            setContractSignStageForm({
                ...contractSignStageForm,
                contractSignedPaymentReceviedAttachemnts: newFiles,
            });
            setFiles(newFiles);
            setCheckFileUploaded(newFileUploaded);
        } catch (err) {
            props.setSnackInfo(err?.response?.data ?? "Something went wrong", "error");
        }
        props.setLoading(false);
    }

    const isDisabledToken = (projectDetails?.departmentId?._id === salesDepartment ||  projectDetails?.departmentId?._id === designDepartment)&& checkSalesToken === false;
    const [pics, setPics] = useState([""]);
    const hasPhotos = pics.every(pic => pic !== '');
    const hasVideos = videos.every(video => video !== '');
    const onImageChange = async (event, index) => {
        try {
            const newFiles = [...pics];
            const newFileUploaded = [...checkFileUploaded];

            props.setLoading(true);
            const fd = new FormData();
            fd.append("file", event.target.files[0]);
            fd.append('customerName', projectDetails.lead_no);

            const file = event.target.files[0]
            const fileSize = file.size;
            let newSiteQCPhotos = uploadSize.siteQCPhotos;
            newSiteQCPhotos[index] = fileSize
            // console.log('newSiteQCPhotos is ',newSiteQCPhotos)
            setUploadSize({
                ...uploadSize,
                siteQCPhotos: newSiteQCPhotos
            })
            const fileUploadRes = await HttpService.uploadFile(fd);
            newFiles[index] = fileUploadRes.data;
            setContractSignStageForm({
                ...contractSignStageForm,
                scanSiteQCPhotos: newFiles,
            });
            setPics(newFiles)
            setCheckIsUpload({ ...checkIsUpload, siteQCPhotos: true });
            props.setSnackInfo("SiteQC Photo Upload Success", "success");
        } catch (err) {
            console.log('photos upload error',err)
          props.setSnackInfo(err?.response?.data ?? "Something went wrong", "error");
          props.setLoading(false);
        }
    };
    const handleAddFilesInput = () => {
        let newFiles = [...pics];
        newFiles.push("");
        setPics(newFiles);
        setContractSignStageForm({
            ...contractSignStageForm,
            scanSiteQCPhotos: newFiles,
        });
    };
    const handleRemoveFilesInput = (index) => {
        const newFiles = [...pics];
        newFiles.splice(index, 1);
        setPics(newFiles);

        let newSiteQCPhotos = uploadSize.siteQCPhotos;
        newSiteQCPhotos.splice(index, 1);
        // console.log('newSiteQCPhotos is ',newSiteQCPhotos)
        setUploadSize({
            ...uploadSize,
            siteQCPhotos: newSiteQCPhotos
        })
    }
    // const assignSalesManager = () => {
    //     setOpenSalesManagerModal(true);
    //     getSalesManagerList();
    // }

    const [lostReason, setLostReason] = useState('')
    const handlelostReason = (e) => {
        setLostReason(e.target.value)
    }

    // Floor Button_1 Function
    const [fBtnShow, setfBtnShow] = useState(false)
    const floorPlanButton = () => {
        setfBtnShow(true);
    }

    const getSalesManagerList = async () => {
        props.setLoading(true)
        try {
            const result = await HttpService.getUsers()
            let salesArr = [];
            let salesManagerArr = [];
            let activeTeamUser = result.data.filter(ele => ele.isActive === true);
            activeTeamUser.forEach(ele => {
                if (ele.roles?.find(role => role.name === ROLENAME.AssistantSalesManager) && ele?.teamId?.isActive === true ) {
                    salesArr.push(ele);
                } else if (ele.roles?.find(role => role.name === ROLENAME.SalesManager) && ele?.teamId?.assistantManagers?.length === 0 && ele?.teamId?.isActive === true ) {
                    salesArr.push(ele);
                }
            })
            dupManagerOfSalesTeam.forEach(ele => {
                ele.experienceCenterId.forEach(ele1 => {
                    if (ele1._id === projectDetails.experienceCenterId._id) {
                        salesManagerArr.push(ele)
                    }
                })
            })
            setSalesManagerList(salesManagerArr)
            setDupManagerOfSalesTeam(salesArr);
        } catch (error) {
            props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }
    const getSelectedSalesManagerDetail = (e) => {
        let teamDetail = [];
        dupManagerOfSalesTeam.forEach(ele => {
            if (ele?.teamId?._id === e.target.value) {
                teamDetail.push(ele)
            }
        })
        setSelectedSalesManager({
            department: teamDetail[0].departmentId._id,
            team: teamDetail[0].teamId._id,
            id: teamDetail[0]._id,
            status: 'Lead Received',
            stage: 'Lead Received',
            leadId: projectId
        })
    }

    // const assignLeadToSalesManager = async () => {
    //     console.log(selectedSalesManager);
    //     props.setLoading(true)
    //     try {
    //         await HttpService.assignProject(selectedSalesManager)
    //         props.setSnackInfo("Sales Manager Assigned Successfully", "success")
    //         setOpenSalesManagerModal(false)
    //         getProjectLogs();
    //         getcustomerDetails();
    //     } catch (error) {
    //         props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
    //     }
    //     props.setLoading(false)
    // }

    const handleClickOpen = () => {
        if (projectDetails.designStatus === 'Design-Hold' || projectDetails.designStatus === 'Design Hold - Possession more than 3 months' ){
            return props.setSnackInfo("Design Status is Design-Hold" , "error")
        }
        setOpen(true);
        getDepartments();
    };
    const openChmUserDialog = () => {
        setOpenChmUser(true);
        getChmUsersList();
    }
    const [marketingUser, setMarketingUser] = useState([]);
    const openAnotherDialog = () => {
        if (projectDetails.salesStage === "Won Deals Pending Designer Assignment" && stage != "Lost") {
            return props.setSnackInfo("Client has not approved the checklist" , "error")
        }
        projectDetails.previouslyAssignedTo.forEach((ele, i) => {
            if (ele.departmentId === constantDepartmentId.marketing || ele.departmentId === callCenterId) {
                setMarketingUser(ele)
            }
        })
        setOpen(false);
        setOpenAnotherDialog(open);
        getDepartments();
    }

    const openDesignKickOffCustomerDialog = () => {
        if (projectDetails.salesStage === "Won Deals Pending Designer Assignment"){
            return props.setSnackInfo("Client has not approved the checklist", 'error')
        }
        getDepartments();
        setDesignKickOffCustomerDialog(true)
        setOpen(false);
    }

    const handleSelectedDepartmentChange = (event) => {
        setSelectedDepartment(event.target.value);
        getTeamsList(event.target.value)
    }
    const handleSelectedTeamChange = (event) => {
        setSelectedTeam(event.target.value);
        getUserList(event.target.value)
    }
    const handleSelectedUserChange = (event) => {
        setSelectedDealOwner(event.target.value);
    }

    const handleChmUserChange = (event) => {
        setSelectedChmUser(event.target.value);
    }

    const updateChmUser = async () => {
        let obj = {
            projectId: projectId,
            chmUser: chmUser
        }
        props.setLoading(true)
        try {
            const response = await HttpService.updateChmUser(obj)
            props.setSnackInfo(response?.data ?? "User Changed successfully", "success")
            setOpenChmUser(false);
            getProjectLogs();
            getcustomerDetails();
            getChmUsersList();
        } catch (error) {
            props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
        }
        props.setLoading(false)
    }

    const [callCenterId,setCallCenterId] = useState();
    const getDepartments = async () => {
        try {
            const department = await HttpService.getDepartments();
            if (userDetails?.roles?.find(role => role.name !== 'Admin')) {
                if (userDetails?.roles?.find(role => role.name === ROLENAME.MarketingUser || role.name === ROLENAME.MarketingManager || role.name === ROLENAME.TeleCallerManager || role.name === ROLENAME.TeleCallerUser)) {
                    setDepartmentsList(department.data.filter(ele => ele.name === 'Sales'));
                } else {
                    setDepartmentsList(department.data.filter(ele => ele._id === userDetails.departmentId))
                }
            }
            let callCenterData = department.data?.find(name => name.name === 'Call Center');
            setCallCenterId(callCenterData._id)
            getDepartmentStages(department.data);
        } catch (error) {
            console.log(error, "get department error")
        }
    }
    const getChmUsersList = async () => {
        try {
            const chmusers = await HttpService.getDepartments();
            const chmStage = chmusers.data.find(item => item.name === "CHM")
            if (chmStage) {
                const chmUsers = chmStage.users
                setChmList(chmUsers);
            }
        }
        catch (error) {
            console.log(error, "getchmuserlist");
        }
    }

    const getTeamsList = async (deptid) => {
        let teams = [];
        teams = departmentLists.filter(ele => ele._id === deptid)
        const centerTeams = [];
        if (userDetails.roles.find(role => role.name === ROLENAME.DesignHead || role.name === ROLENAME.DesignManager || role.name === ROLENAME.OperationDesignManager || role.name === ROLENAME.AssistantDesignManager)) {
            teams[0]['teams'].forEach(ele => {
                centerTeams.push(ele)
            })
        } else {
            teams[0]['teams'].forEach(ele => {
                ele.experienceCenterId.forEach(centerId => {
                    if (centerId === userDetails.selectedExpCenter) {
                        centerTeams.push(ele)
                    }
                })
            })
        }
        const activeTeamList = centerTeams.filter(ele => ele.isActive === true)
        setTeamList(activeTeamList)
    }

    const getUserList = async (teamid) => {
        if (userDetails?.roles?.find(role => ROLENAME.MarketingUser || role.name === ROLENAME.MarketingManager)) {
            try {
                const users = await HttpService.getQTTeamUsers(teamid);
                let activeUsers = users.data.users.filter(ele => ele.isActive === true)
                let finalUsers = []
                if (userDetails.roles.find(role => role.name === ROLENAME.DesignHead || role.name === ROLENAME.DesignManager || role.name === ROLENAME.OperationDesignManager || role.name === ROLENAME.AssistantDesignManager)) {
                    for (let j = 0; j < activeUsers.length; j++) {
                        finalUsers.push(activeUsers[j])
                    }
                } else {
                    for (let i = 0; i < activeUsers.length; i++) {
                        for (let j = 0; j < activeUsers[i]['experienceCenterId'].length; j++) {
                            if (activeUsers[i]['experienceCenterId'][j]['_id'] === userDetails.selectedExpCenter) {
                                finalUsers.push(activeUsers[i])
                            }
                        }
                    }
                }
                setDealOwnersList(finalUsers)
                setManagerOrHeadId(users.data.manager._id);
            } catch (error) {
                console.log(error, "Error");
            }
        } else if (teamid === userDetails.teamId._id) {
            try {
                const users = await HttpService.getQTTeamUsers(teamid);
                let activeUsers = users.data.users.filter(ele => ele.isActive === true)
                setDealOwnersList(activeUsers)
                setManagerOrHeadId(users.data.manager._id);
            } catch (error) {
                console.log(error, "Error");
            }
        } else {
            try {
                const teamUsers = await HttpService.getQtTeamManager(teamid);
                let teamManager = [];
                let obj = {
                    _id: teamUsers.data.manager._id,
                    name: teamUsers.data.manager.name
                }
                teamManager.push(obj);
                setDealOwnersList(teamManager)
            } catch (error) {
                console.log(error, "Error");
            }
        }
    }

    const onVideoChangeCheck = async (e, index) => {
        const newVideos = [...videos];
        const newVideoUploaded = [...checkVideoUploaded];
    
        props.setLoading(true);
        const fd = new FormData();
        fd.append("file", e.target.files[0]);
        fd.append('customerName', projectDetails.lead_no);
    
        try {
            const fileUploadRes = await HttpService.uploadFile(fd);
            newVideos[index] = fileUploadRes.data;
            setContractSignStageForm({
                ...contractSignStageForm,
                siteQcVideo: newVideos,
            });
            setVideos(newVideos);
            setCheckVideoUploaded(newVideoUploaded);
        } catch (err) {
            props.setSnackInfo(err?.response?.data ?? "Something went wrong", "error");
        }
        props.setLoading(false);
    };


const handleAddVideoInput = (index) => {
  const newVideos = [...videos, ""];
  setVideos(newVideos);
};

const handleRemoveVideoInput = (index) => {
  const newVideos = videos.filter((_, i) => i !== index);
  setVideos(newVideos);
};


    const getDepartmentStages = async (data) => {
        let stages = []
        if (userDetails.roles.find(role => role.name === ROLENAME.MarketingUser || role.name === ROLENAME.MarketingManager || role.name === ROLENAME.TeleCallerManager || role.name === ROLENAME.TeleCallerUser)) {
            data.forEach((element) => {
                if (element['name'] === 'Sales') {
                    stages.push(element['stages']);
                };
            });
        } else if (userDetails.roles.find(role => role.name === ROLENAME.DesignHead || role.name === ROLENAME.DesignManager || role.name === ROLENAME.OperationDesignManager || role.name === ROLENAME.AssistantDesignManager || role.name === ROLENAME.DesignUser)) {
            data.forEach((element) => {
                if (element['name'] === 'Design') {
                    // element['stages'] = [];
                    // Check if the user is not a DesignUser and this logic for disbale and enable of design stage option mapping only for user
                 
                    const designStages = [
                        "Assign to Designer",
                        // "Internal Kick-Off",
                        "Design Kick-Off : Customer",
                        "Initial Measurement",
                        "Design Discussion",
                        "Finalization of Design",
                        "Finalization of Materials",
                        "Renders",
                        "Final Quote and Design",
                        "Site QC/Project Kick-Off",
                        "Drawing QC",
                        "Design Sign-off HOLD",
                        "Design Sign-off",
                        "Lost"
                    ]
                    const newStage = [];

                    for(let i = 0; i < designStages.length; i++){
                        if(element['stages'].includes(designStages[i])){
                            newStage.push(designStages[i]);
                        }
                    }

                    const index = newStage.indexOf(projectDetails.currentStage);
                    if (projectDetails.currentStage === 'Design Kick-Off : Internal'){
                        setCurrentStageIndex(1);
                    } else if(projectDetails.currentStage === 'Designer Assigned'){
                        setCurrentStageIndex(2);
                    } else {
                        setCurrentStageIndex(index+1);
                    }
                    stages.push(newStage);
                    // stages.push(element['stages']);

                };
            });
        } else {
            data.forEach((element) => {
                if (element['_id'] === userDetails.departmentId) {
                    element['stages'].push('Move to Marketing');
                    stages.push(element['stages']);
                };
            });
        }
        setDepartmentsStage(stages[0]);
    }

    const handleCreateInternalTicket = (event) => {
        event.preventDefault();
        window.open("/CHM-Ticket-Creation", "_blank", "noopener,noreferrer");
      };
      
    const handleSelectedStageChange = (event) => {
        setSelectedStage(event.target.value);
    }
    const [checkIsUpload, setCheckIsUpload] = useState({
        workingDrawingFile: false,
        threeworkingDrawingFile: false,
        contractSignedPaymentReceviedAttachemnt: false,
        scanQuotationFile: false,
        scanCheckListFile: false,
        scanProjectKickOffCheckListFile: false,
        siteQCPhotos: false,
        scanSiteQCCheckListFile: false,
        designerPaymentAttachement: false,
        // scanContractFile: false
        quotationCsvFile: false
    })

    function calculateTotalSize() {
        const totalSize = Object.values(uploadSize).reduce((acc, el) => {
            if (Array.isArray(el)) {
                return acc + el.reduce((arraySum, arrayItem) => arraySum + arrayItem, 0);
            } else {
                return acc + (typeof el === 'number' ? el : 0);
            }
        }, 0);

        const totalSizeMB = totalSize / (1024 * 1024);
        setTotalSize(totalSizeMB);
        if (totalSizeMB >= 25) {
            props.setSnackInfo('Size exceeds 25MB limit, please try again', 'Error')
        }
        // console.log('total size in MB: ', totalSizeMB)
        // console.log('upload size: ', uploadSize)
    };
    const uploadWDFileDocument = async (e, optionType) => {
        try {
            if (optionType !== "quotationFile" && optionType !== "quotationcsv") {
                // console.log("Option -> ", optionType);
                const { name, value } = e.target.value
                props.setLoading(true)
                const formData = new FormData()
                formData.append('file', e.target.files[0])
                const file = e.target.files[0]
                const fileSize = file.size;
                const fileSizeInKB = file.size / 1024; // Convert bytes to kilobytes
                const fileSizeInMB = fileSizeInKB / 1024; // Convert kilobytes to megabytes
                // console.log('Current File Size is:', fileSizeInMB);

                formData.append('customerName', projectDetails.lead_no + '_' + projectDetails.customerId.name)
                const result = await HttpService.wdFileUpload(formData)
                if (result.status === 200 && optionType === 'wdfile') { //done
                    console.log('wdfile')
                    setContractSignStageForm({ ...contractSignStageForm, 'workingDrawingFile': result.data })
                    setCheckIsUpload({ ...checkIsUpload, workingDrawingFile: true })
                    setUploadSize({ ...uploadSize, workingDrawingFile: fileSize })
                    props.setSnackInfo("Working Drawing Upload Success", "success")
                } else if (result.status === 200 && optionType === '3wdfile') { //done
                    console.log('3dwdfile')
                    setContractSignStageForm({ ...contractSignStageForm, 'ThreeDworkingDrawingFile': result.data })
                    setCheckIsUpload({ ...checkIsUpload, threeworkingDrawingFile: true })
                    setUploadSize({ ...uploadSize, threeworkingDrawingFile: fileSize })
                    props.setSnackInfo("Working Drawing Upload Success", "success")
                }
                // else if (result.status === 200 && optionType === 'quotationFile') { //done
                //     console.log('quotationFile')
                //     setContractSignStageForm({ ...contractSignStageForm, 'scanQuotationFile': e})
                //     setCheckIsUpload({ ...checkIsUpload, scanQuotationFile: true })
                //     setUploadSize({ ...uploadSize, scanQuotationFile: fileSize })
                //     props.setSnackInfo("Quotation File Upload Success", "success")
                // } 

                else if (result.status === 200 && optionType === 'CheckList') { //done
                    console.log('CheckList')
                    setContractSignStageForm({ ...contractSignStageForm, 'scanCheckListFile': result.data })
                    setCheckIsUpload({ ...checkIsUpload, scanCheckListFile: true })
                    setUploadSize({ ...uploadSize, scanCheckListFile: fileSize })
                    props.setSnackInfo("Check List File Upload Success", "success")
                } else if (result.status === 200 && optionType === 'ProjectKickOffCheckList') { //done
                    console.log('Project Kick Off CheckList')
                    setContractSignStageForm({ ...contractSignStageForm, 'scanProjectKickOffCheckListFile': result.data })
                    setCheckIsUpload({ ...checkIsUpload, scanProjectKickOffCheckListFile: true })
                    setUploadSize({ ...uploadSize, scanProjectKickOffCheckListFile: fileSize })
                    props.setSnackInfo("Project Kick Off CheckList File Upload Success", "success")
                } else if (result.status === 200 && optionType === 'siteqcchecklist') { //done
                    console.log('SiteQC CheckList')
                    setContractSignStageForm({ ...contractSignStageForm, 'scanSiteQCCheckListFile': result.data })
                    setCheckIsUpload({ ...checkIsUpload, scanSiteQCCheckListFile: true })
                    setUploadSize({ ...uploadSize, scanSiteQCCheckListFile: fileSize })
                    props.setSnackInfo("SiteQC Check List File Upload Success", "success")
                } else if (result.status === 200 && optionType === 'designerPayment') {
                    setPaymentUpdateForm({ ...paymentUpdateForm, 'paymentAttachement': result.data })
                    setCheckIsUpload({ ...checkIsUpload, designerPaymentAttachement: true })
                    setUploadSize({ ...uploadSize, designerPaymentAttachement: fileSize })
                    props.setSnackInfo("Check List File Upload Success", "success")
                }
                // else if (result.status === 200 && optionType === 'contractpdf') {
                //     console.log('contractpdf')
                //     setContractSignStageForm({ ...contractSignStageForm, 'scanContractFile': result.data })
                //     setCheckIsUpload({ ...checkIsUpload, scanContractFile: true })
                //     props.setSnackInfo("Contract Signed File Upload Success", "success")
                // } else if (result.status === 200 && optionType === 'quotationcsv') { //done
                //     setContractSignStageForm({ ...contractSignStageForm, 'quotationCsvFile': e })
                //     setCheckIsUpload({ ...checkIsUpload, quotationCsvFile: true })
                //     setUploadSize({ ...uploadSize, quotationCsvFile: fileSize })
                //     props.setSnackInfo("Quotation Csv File Upload Success", "success")
                // }
                else if (result.status === 200 && optionType === 'salesPaymentSlip') {
                    setNewPaymentSlip(result.data)
                    setCheckForSales(true)
                    // setUploadSize({ ...uploadSize, scanQuotationFile: fileSize })
                    props.setSnackInfo("Sales Payment Slip Upload Success", "success")
                }
                // else if (result.status === 200 && optionType === 'salesPaymentSlip'){
                //     setNewPaymentSlip(result.data)
                //     setCheckForSales(true)
                //     props.setSnackInfo("Sales Payment Slip Upload Success", "success")
                // }
                else if (result.status === 200) {
                    setFloorType(result.data);
                    setCheckForFloor(true);
                    // setUploadSize({ ...uploadSize, scanQuotationFile: fileSize })
                    props.setSnackInfo("Floor Plan Upload Success", "success")
                }
            } else {
                props.setLoading(true);
                const fileSize = e.size;
                const fileSizeInKB = e.size / 1024; // 
                if (optionType === 'quotationFile') { //done
                    // console.log('quotationFile')
                    setContractSignStageForm({ ...contractSignStageForm, 'scanQuotationFile': e })
                    setCheckIsUpload({ ...checkIsUpload, scanQuotationFile: true })
                    setUploadSize({ ...uploadSize, scanQuotationFile: fileSize })
                    props.setSnackInfo("Quotation File Upload Success", "success")
                } else if (optionType === 'quotationcsv') { //done
                    setContractSignStageForm({ ...contractSignStageForm, 'quotationCsvFile': e })
                    setCheckIsUpload({ ...checkIsUpload, quotationCsvFile: true })
                     setUploadSize({ ...uploadSize, quotationCsvFile: fileSize })
                    props.setSnackInfo("Quotation Csv File Upload Success", "success")
                }
                props.setLoading(false);
            }
        } catch (error) {
            console.log(error)
            props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
        }
        props.setLoading(false)
    }

    const assignToMarketingUser = async () => {
        let dealData = {
            status: "Lead Returned By Sales",
            stage: 'Move to Marketing',
            department: marketingUser.departmentId,
            team: marketingUser.teamId,
            id: marketingUser._id,
            leadId: projectId
        }
        try {
            props.setLoading(true)
            const assignProject = await HttpService.assignProject(dealData);
            if (assignProject.status === 200) {
                setOpenAnotherDialog(false);
                getProjectLogs();
                getcustomerDetails();
                history.push('/qtProjects');
                props.setSnackInfo("Project assigned Successful", "success")
            }
        } catch (error) {
            console.log(error, "assign project error");
            props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
        }
    }

    const sendRequestToFinanceAndDesignManager = async () => {
        if (projectDetails?.designKickOffCustomerActionFromChm !== "Approved") {
            return props.setSnackInfo( "Design Kick-off is not Approved by ADM/ODM", "error")
        }
        contractSignStageForm.grandTotal = projectDetails.grandTotal;
        contractSignStageForm.qtVersionGrandTotal = qtVersionGrandTotal;
        // contractSignStageForm.tokenPercent = ((projectDetails.paymentDone / projectDetails.grandTotal) * 100).toFixed(2);
        const tokenPercentValue = parseInt(qtVersionGrandTotal) !== 0 ? ((parseInt(projectDetails.paymentDone) / parseInt(qtVersionGrandTotal)) * 100).toFixed(2) : "0.00";
        contractSignStageForm.tokenPercent = tokenPercentValue;
        contractSignStageForm.designToExecutionLogsStatus = "Sent For Approval";
        contractSignStageForm.stage = stage;
        contractSignStageForm.designSignOffUser = projectDetails.assignTo._id;
        contractSignStageForm.designSignOffUserName = userDetails.name;
        contractSignStageForm.designQuotationVersion = selectedQuotationVersion;
        contractSignStageForm.qtVersion = qtVersion;
        // console.log(contractSignStageForm, 'contractSignStageForm')
        try {
            props.setLoading(true)
            const assignProject = await HttpService.sendRequrestToFinanceAndDesgin(projectId, contractSignStageForm);
            if (assignProject.status === 200) {
                setOpenAnotherDialog(false);
                setSelectedStage('');
                setSelectedDepartment('');
                setSelectedTeam('');
                setSelectedDealOwner('');
                getProjectLogs();
                getcustomerDetails();
                props.setSnackInfo("Request has been sent successfully.", "success")
            }
        } catch (error) {
            console.log(error, "assign project error");
            props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
        }
        props.setLoading(false)
    }

    const assignProject = async () => {
        if (projectDetails.salesStage === "Won Deals Pending Designer Assignment" && stage != "Lost"){
            return props.setSnackInfo("Client has not approved checklist", "error")
            }    
            if (projectDetails.currentStage === "Design Kick-Off : Customer" && stage != "Lost"){
                return props.setSnackInfo("Design Kick-off is not Approved by ADM/ODM", "error")
                }    
        setDesignKickOffCustomerDialog(false)
        let dealData = {
            stage: stage,
            team: userDetails?.roles?.find(role => role.name === ROLENAME.DesignUser) ? userDetails.teamId._id : team,
            id: userDetails?.roles?.find(role => role.name === ROLENAME.DesignUser) ? userDetails._id : dealOwner,
            leadId: projectId,
            TeamManagerOrHeadId: managerOrHeadId,
        };

        let isDesignUserFound = userDetails?.roles?.find(role => role.name === ROLENAME.DesignUser);
        let isTeleCallerorMarketingUser = userDetails?.roles?.find(role => role.name === ROLENAME.MarketingManager || role.name === ROLENAME.MarketingUser || role.name === ROLENAME.TeleCallerManager || role.name === ROLENAME.TeleCallerUser);

        if (isDesignUserFound) {
            dealData.department = userDetails.departmentId;
        } else {
            dealData['department'] = department
        }
        if (userDetails.departmentId === constantDepartmentId.design) {
            dealData.stageDepartment = 'design'
        } else if (userDetails.departmentId === constantDepartmentId.sales) {
            dealData.stageDepartment = 'sales'
        } else if (userDetails.departmentId === constantDepartmentId.factory) {
            dealData.stageDepartment = 'factory'
        }

        if(isTeleCallerorMarketingUser){
            dealData = {
                stage: stage,
                leadId: projectId,
                leadType : 'Real',
                status : 'Lead Received',
                stage: stage,
                leadStatus: 'Open'
            };
        }

        if (stage === 'Pre - Sales call') {
            if (dealOwner) {
                dealData['preSalesCallDate'] = preSalesCallDate
                dealData['nextFollowUpDate'] = nextFollowUpDate
                dealData['leadStatus'] = 'Open'
            } else {
                dealData = {
                    stage: stage,
                    leadId: projectId,
                    nextFollowUpDate: nextFollowUpDate,
                    preSalesCallDate: preSalesCallDate,
                    leadStatus: 'Open'
                };
            }
        } else if (stage === 'Sales Call') {
            if (dealOwner) {
                dealData['salesCallDate'] = salesCallDate;
                dealData['requirements'] = requirements;
                dealData['estimatedBudget'] = estimatedBudget;
                dealData['nextFollowUpDate'] = nextFollowUpDate;
                dealData['salesCallComment'] = salesCallComment;
                dealData['leadStatus'] = 'Open'
            } else {
                dealData = {
                    stage: stage,
                    leadId: projectId,
                    salesCallDate: salesCallDate,
                    requirements: requirements,
                    estimatedBudget: estimatedBudget,
                    nextFollowUpDate: nextFollowUpDate,
                    salesCallComment: salesCallComment,
                    leadStatus: 'Open'
                };
            }

        } else if (stage === 'Quotation Sent') {
            if (dealOwner) {
                dealData['quotationSentDate'] = quotationSentDate;
                dealData['nextFollowUpDate'] = nextFollowUpDate;
                dealData['momQuotationStage'] = momQuotationStage;
                dealData['leadStatus'] = 'Open'
            } else {
                dealData = {
                    stage: stage,
                    leadId: projectId,
                    nextFollowUpDate: nextFollowUpDate,
                    quotationSentDate: quotationSentDate,
                    momQuotationStage: momQuotationStage,
                    leadStatus: 'Open'
                };
            }

        } else if (stage === 'Site/Office visit') {
            if (dealOwner) {
                dealData['siteVisitDate'] = siteVisitDate;
                dealData['momMeetingStage'] = momMeetingStage;
                dealData['momSiteVisitStage'] = momSiteVisitStage;
                dealData['nextFollowUpDate'] = nextFollowUpDate;
                dealData['leadStatus'] = 'Open'
                dealData['meetingDone'] = meetingDone;
                dealData['meetingType'] = meetingType;
                dealData['meetingDate'] = meetingDate;
                dealData['meetingDoneStage'] = stage;

            } else {
                dealData = {
                    stage: stage,
                    leadId: projectId,
                    siteVisitDate: siteVisitDate,
                    momMeetingStage: momMeetingStage,
                    momSiteVisitStage: momSiteVisitStage,
                    nextFollowUpDate: nextFollowUpDate,
                    leadStatus: 'Open',
                    meetingDone: meetingDone,
                    meetingType: meetingDone === "Yes" ? meetingType : "",
                    meetingDate: meetingDone === "Yes" ? meetingDate : "",
                    meetingDoneStage: meetingDone === "Yes" ? stage : "",
                };
            }

        } else if (stage === 'Negotiation') {
            if (dealOwner) {
                dealData['negotiationDate'] = negotiationDate;
                dealData['momNegotitationStage'] = momNegotiationStage;
                dealData['nextFollowUpDate'] = nextFollowUpDate;
                dealData['leadStatus'] = 'Open'
                dealData['meetingDone'] = meetingDone;
                dealData['meetingType'] = meetingType;
                dealData['meetingDate'] = meetingDate;
                dealData['meetingDoneStage'] = stage;

            } else {
                dealData = {
                    stage: stage,
                    leadId: projectId,
                    negotiationDate: negotiationDate,
                    momNegotitationStage: momNegotiationStage,
                    nextFollowUpDate: nextFollowUpDate,
                    leadStatus: 'Open',
                    meetingDone: meetingDone,
                    meetingType: meetingDone === "Yes" ? meetingType : "",
                    meetingDate: meetingDone === "Yes" ? meetingDate : "",
                    meetingDoneStage: meetingDone === "Yes" ? stage : "",
                };
            }
        } else if (stage === 'Won') {
            if (dealOwner) {
                dealData['closureDate'] = closureDate;
                dealData['remark'] = '';
                dealData['finalQuoteAttached'] = '';
                dealData['clientMoveinDate'] = clientMoveinDate;
                dealData['meetingDone'] = meetingDone;
                dealData['meetingType'] = meetingType;
                dealData['meetingDate'] = meetingDate;
                dealData['meetingDoneStage'] = stage;

            } else {
                dealData = {
                    stage: stage,
                    leadId: projectId,
                    closureDate: closureDate,
                    remark: '',
                    finalQuoteAttached: '',
                    clientMoveinDate: clientMoveinDate,
                    meetingDone: meetingDone,
                    meetingType: meetingDone === "Yes" ? meetingType : "",
                    meetingDate: meetingDone === "Yes" ? meetingDate : "",
                    meetingDoneStage: meetingDone === "Yes" ? stage : "",
                };
            }
        } else if (stage === 'Design Sign-off') {
            dealData['designSignOffDate'] = contractSignStageForm.designSignOffDate;
            dealData['workingDrawingFile'] = contractSignStageForm.wdFile;
            dealData['projectCompletionDate'] = contractSignStageForm.projectCompletionDate;
            dealData['leadStatus'] = 'Open'
        } else if (stage === 'Lost') {
            dealData = {
                ...dealData,
                stage: stage,
                department: department,
                team: team,
                id: dealOwner,
                leadId: projectId,
                leadStatus: 'Lost',
                reasonForLost: lostReason,
                lostRaiseUser: userDetails?.name || 'NA',
                meetingDone: meetingDone,
                meetingType: meetingDone === "Yes" ? meetingType : "",
                meetingDate: meetingDone === "Yes" ? meetingDate : "",
                meetingDoneStage: meetingDone === "Yes" ? stage : "",
            }
            // } else if (stage === 'Final Quotation Approval') {
            //     dealData['quotation_no'] = selectedQuote;
        }
        else if (stage == 'Assign to Designer') {
            // console.log('dealOwner', dealOwner, dealOwnerLists)
            // const user = dealOwnerLists.filter(el => el._id.toString() == dealOwner.toString())
            // console.log('user', user)

            dealData['stage'] = 'Design Kick-Off : Internal'
            if (!projectDetails.erpProjectNo) {
                try {
                    const response = await HttpService.changeErpProjectNo(projectDetails?.erpProjectNo, newErpProjectNo, projectDetails?._id)
                    if (response.status == 204) {
                        setErpProjectExists(true)
                        return
                    }
                    else {
                        setErpProjectExists(false)
                    }
                } catch (error) {
                    console.log("error", error)
                }
            }
        }
        else if (stage === 'Design Kick-Off : Customer') {
            dealData['designerAndSalesOwnerMeetingDate'] = designKickOffCustomerForm.salesOwnerMeetingDate;
            dealData['floorPlanReceivedToDesigner'] = designKickOffCustomerForm.floorPlanReceived;
            dealData['designerDiscussedQuotationWithClient'] = designKickOffCustomerForm.quotationDiscussed;
            dealData['designerDiscussedRequirementWithClient'] = designKickOffCustomerForm.requirementDiscussed;
        }
        else if (userDetails.roles.find(role => role.name === ROLENAME.DesignUser)) {
            dealData = {
                ...dealData,
                department: userDetails.departmentId,
                team: userDetails.teamId._id,
                id: userDetails._id,
            }
        } else {
            // console.log(dealOwnerLists, "dealOwnerLists")
            dealData = {
                ...dealData,
                stage: stage,
                department: department,
                team: team,
                id: dealOwner,
                leadId: projectId,
                leadStatus: projectDetails.leadStatus === 'Won' ? 'Won' : 'Open'
            }
        }

        for (let b = 0; b < dealOwnerLists.length; b++) {
            if (dealOwnerLists[b]._id === dealOwner) {
                if (userDetails.roles.find(role => role.name === ROLENAME.DesignManager || role.name === ROLENAME.OperationDesignManager || role.name === ROLENAME.AssistantDesignManager)) {
                    dealData = {
                        ...dealData,
                        department: userDetails.departmentId,
                        // city: dealOwnerLists[b].locationId[0]._id,
                        // experienceCenterId: dealOwnerLists[b].experienceCenterId[0]._id,
                    }
                    break;
                } else {
                    dealData = {
                        ...dealData,
                        id: dealOwner
                        // city: dealOwnerLists[b].locationId[0]._id,
                        // experienceCenterId: dealOwnerLists[b].experienceCenterId[0]._id,
                    }
                }
            }
        }
        try {
            props.setLoading(true)
            const assignProject = await HttpService.assignProject(dealData);
            if (assignProject.status === 200) {
                setOpenAnotherDialog(false);
                setOpen(false)
                setSelectedStage('');
                setSelectedDepartment('');
                setHandleNextFollowUpDate('')
                setSelectedTeam('');
                setSelectedDealOwner('');
                getProjectLogs();
                getcustomerDetails();
                // getCustomerProjectDetail(projectId);
                props.setSnackInfo("Project assigned Successful", "success")
            }
        } catch (error) {
            props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
        }
        window.location.reload(true);
        props.setLoading(false)
    }
    const [openNoDialog, setOpenNoDialog] = useState(false);
    const [newsalesWonDate, setNewsalesWonDate] = useState("");


    const setSalesWonDate = async () => {
        setClicked(true); 
        try {

            let salesWonManager = "";


            if (userDetails.roles.find(role => role.name == "Sales Manager" || role.name === 'Assistant Sales Manager')) {
                salesWonManager = userDetails._id
            }
            else {
                salesWonManager = userDetails.teamId.manager ? userDetails.teamId.manager : userDetails.assistantManagerId
            }

            let obj = {
                salesStage: 'Won Deals Pending Designer Assignment',
                currentStage: 'Won Deals Pending Designer Assignment',
                leadWonDate: newsalesWonDate,
                salesWonUser: userDetails._id,
                salesWonManager: salesWonManager,
                salesPaymentProofAtachement: newPaymentSlip,
                paymentDone: amountPaid,
                finanaceManagerApproved: [{
                    isApproved: false,
                    status: 'Send for Approval',
                }],
                reinitiateProcess: "Send for Approval",
                salesWonExpCtrId: userDetails.selectedExpCenter
            }
            const response = await HttpService.leadWonRejection(projectId, obj)
            props.setSnackInfo(response?.data ?? "Something went wrong", "success")
            setOpenNoDialog(false)
            getcustomerDetails();
            console.log("done")
        } catch (error) {
            props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
        }

    }

    const updateClosureStatus = async (statusAct) => {
        if (statusAct === 'Yes') {
            let dealData = {}
            if (meetingDone === "Yes") {
                dealData = {
                    leadId: projectId,
                    meetingDone: meetingDone,
                    meetingType: meetingDone === "Yes" ? meetingType : "",
                    meetingDate: meetingDone === "Yes" ? meetingDate : "",
                    meetingDoneStage: meetingDone === "Yes" ? stage : "",
                }
                try {
                    await HttpService.leadUpdateMeeting(dealData);
                } catch (error) {
                    props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
                }
            }
            setOpenAnotherDialog(false)
            history.push({
                pathname: '/check-lists',
                state: {
                    actionCheck: statusAct,
                    leadId: projectId
                }
            })
        } else if (statusAct === 'No') {
            setOpenAnotherDialog(false)
            props.setLoading(true)
            try {
                setOpenStatusModal(false);
                setOpenNoDialog(true)
            } catch (error) {

            }
            props.setLoading(false)
        }
    }

    const navigateToVerifyPage = () => {
        props.history.push(`/verify-documents/${projectId}`);
    }

    const viewCheckList = () => {
        props.history.push(`/view-customer-checklist/${projectId}`);
    }
    const [openClientModel, setOpenClientModel] = useState(false);
    const [clientDelay, setClientDelay] = useState(0);
    const [openDesignerAssignmentDate, setOpenDesignerAssignmentDate] = useState(false)
    const [designManagerData, setDesignManager] = useState([]);
    // const [openGenerateLinkPopUp, setOpenGenerateLinkPopUp] = useState(false);
    const getDesignUsers = async () => {
        props.setLoading(true)
        try {
            const response = await HttpService.getDesignUserByTeam(userDetails.teamId._id)
            let designUser = [];
            let designManager = [];
            for (let i = 0; i < response.data.length; i++) {
                for (let j = 0; j < response.data[i].roles.length; j++) {
                    if (response.data[i].roles[j].name === ROLENAME.OperationDesignManager) {
                        designManager.push({
                            name: response.data[i].name,
                            email: response.data[i].email,
                            mobile: response.data[i].mobile,
                            _id: response.data[i]._id
                        })
                    }
                }
            }
            setDesignManager(designManager)
        } catch (error) {
            props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const [designerAssignmentDate, setDesignerAssignmentDate] = useState(new Date());
    const updateDesignerAssignmentDate = async () => {
        // Setting the obj to set the design user, design manager and design assignment date
        let obj = {
            designManager: {
                name: designManagerData[0].name,
                email: designManagerData[0].email,
                mobile: designManagerData[0].mobile,
            },
            designerAssignmentDate: designerAssignmentDate,
            customerEmailId: projectDetails?.customerId?.email,
            customerName: projectDetails?.customerId?.name
        }

        props.setLoading(true)
        try {
            const response = await HttpService.updateDesignerAssignementDetail(projectId, obj)
            props.setSnackInfo(response?.data ?? "Mail send to customer successfully", "success")
            setOpenDesignerAssignmentDate(false);
            getProjectLogs();
        } catch (error) {
            props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const [openUpdatePaymentModel, setOpenUpdatePaymentModel] = useState(false)
    const [paymentUpdateForm, setPaymentUpdateForm] = useState({
        paymentAmount: '',
        paymentAttachement: '',
        note: ''
    });
    const updateCustomerPayment = async () => {
        let obj = {
            amount: paymentUpdateForm.paymentAmount,
            paymentAttachement: paymentUpdateForm.paymentAttachement,
            note: paymentUpdateForm.note,
            lead_no: projectDetails.lead_no,
        }
        props.setLoading(true)
        try {
            const response = await HttpService.updateCustomerPaymentAndSendToFinance(projectId, obj)
            props.setSnackInfo(response?.data ?? "Payment updated successfully", "success")
            setOpenUpdatePaymentModel(false);
            getProjectLogs();
        } catch (error) {
            props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const handleChangeProjectNo = async (v) => {
        props.setLoading(true)
        try {
            const response = await HttpService.changeErpProjectNo(projectDetails?.erpProjectNo, newErpProjectNo, projectDetails?._id)

            if (response.status == 204) {
                setOpenErpProjectNoModal(false)
                props.setLoading(false)
                props.setSnackInfo("This Number is already being used for ERP Project.", "error")
            }
            else {
                setOpenErpProjectNoModal(false)
                props.setLoading(false)
                props.setSnackInfo("Current Project No. is Updated Now!", "success")
                window.location.reload()
            }
        } catch (error) {
            console.log("error", error)
        }
        props.setLoading(false)
    }
    const handleDesignSignOffDate = async (v) => {
        props.setLoading(true)
        let obj = {
            expectedDesignSignOffDate: designSignOff
        }
        try {
            const response = await HttpService.updateExpectedDesignSignOffDate(obj, projectId)
            setOpenDesignSignOffModal(false)
            props.setLoading(false)
            props.setSnackInfo("Date is updated")
            window.location.reload()
        }
        catch (error) {
            console.log("error", error)
        }
        props.setLoading(false)
    }

    const salesClosureDateHandler = (e) => {
        setExpectedSalesClosureDate(e.target.value);
    }
    const handleEnterSalesClosureDate = async () => {
        props.setLoading(true);

        let obj = {
            expectedSalesclosureDate: expectedSalesclosureDate,
        };

        try {
            const response = await HttpService.updateExpectedSalesClosureDate(obj, projectId);
            setOpenEnterSalesClosureDateModal(false);
            props.setSnackInfo("Date is updated");
            // Instead of directly reloading, you might want to update your state or perform a more controlled navigation.
            // window.location.reload();
        } catch (error) {
            console.error("Error updating date:", error);
            props.setSnackInfo("Failed to update date. Please try again.");
        } finally {
            props.setLoading(false);
        }
        getProjectLogs();
        getcustomerDetails();
    };

    const clientExtraDays = () => {
        setOpenClientModel(true)
    }

    const clientDelayfunc = async () => {
        try {
            const response = await HttpService.extraDaysDuetoClientDependency(projectId, clientDelay)
            props.setSnackInfo("Updated Successfully", "success")
            setOpenClientModel(false)
        } catch (error) {
            props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }
    const [newModals, setNewModal] = useState(false);
    const [paymentProof, setpaymentProof] = useState([])

    const paymentData = async () => {
        try {
            setNewModal(true)
            const response = await HttpService.finalPaymentApprovedByFm(projectId)
            setpaymentProof(response.data)
        } catch (error) {
            props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const [alertShown, setAlertShown] = useState(false);

    const [designKickOffCustomerDialog, setDesignKickOffCustomerDialog] = useState(false)

    const isDisabled = contractSignStageForm.designSignOffDate > contractSignStageForm.projectCompletionDate;

    if (isDisabled && !alertShown) {
        setContractSignStageForm(prevState => ({
            ...prevState,
            designSignOffDate: contractSignStageForm.designSignOffDate === null,
            projectCompletionDate: contractSignStageForm.projectCompletionDate === null,
        }));
        props.setSnackInfo("Design Sign-Off date cannot be earlier than the Expected Completion Date!", "error")
    }

    //floor plan attachment

    const floorPlanFunc = async () => {
        try {
            const response = await HttpService.floorPlanUpdate(projectId, floorType)
            props.setSnackInfo("Updated Successfully", "success")
            setOpenClientModel(false)
            setfBtnShow(false);
        } catch (err) {
            props.setSnackInfo(err?.response?.data ?? "Something went wrong", "error")
        }
    }

    const handleAddFileInput = () => {
        let newFiles = [...files];
        newFiles.push("");
        setFiles(newFiles);
        setContractSignStageForm({
            ...contractSignStageForm,
            contractSignedPaymentReceviedAttachemnts: newFiles,
        });
    };

    const handleRemoveFileInput = (index) => {
        const newFiles = files.filter((file, id) => id !== index);
        setFiles([...newFiles]);
        setContractSignStageForm({ ...contractSignStageForm, contractSignedPaymentReceviedAttachemnts: newFiles })
        let newPaymentRecievedAttachments = uploadSize.paymentRecievedAttachments;
        newPaymentRecievedAttachments.splice(index, 1);
        // console.log('newPaymentRecievedAttachments is ',newPaymentRecievedAttachments)
        setUploadSize({
            ...uploadSize,
            paymentRecievedAttachments: newPaymentRecievedAttachments
        })
    }
    const handleClickOpenDialog = () => {
        setOpenDialog(true);
      };
      const handleCloseDialog = () => {
        setOpenDialog(false);
      };

      const sendToImosAndSiteBOM = async () =>{
        props.setLoading(true)
        try {
            await HttpService.uploadAndSendToImosAndSiteBOM(projectDetails._id);
            props.setSnackInfo("Project Created Successfully", "success")
            history.push(`/lead-design-to-execution/${moment().subtract(1, 'months').format('YYYY-MM-DD')}/${moment().add(1, 'days').format('YYYY-MM-DD')}`);
        } catch (error) {
            props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }
    const uploadProductDocument = async () => {
        try {
            props.setLoading(true)
            let divisionOfProject = ""
            if (projectDetails?.grandTotal < 1000000) {
                divisionOfProject = '<10L'
            } else if (projectDetails?.grandTotal > 2500000) {
                divisionOfProject = '25L+'
            } else {
                divisionOfProject = "10-25L"
            }
            let obj = {}
            obj.code = projectDetails.erpProjectNo
            obj.clientName = projectDetails.customerId.name
            obj.apartmentName = projectDetails.customerId.address
            obj.clientAddress = projectDetails.customerId.address
            obj.contactNumber = projectDetails.customerId.contact_no
            obj.status = "Site QC"
            obj.locationId = projectDetails.city
            obj.experienceCenterId = projectDetails.experienceCenterId
            obj.leadId = projectDetails._id
            obj.stage = projectDetails.currentStage
            obj.financeStage = projectDetails.contractFinanceApproved
            obj.divisionOfProject = divisionOfProject
            obj.existingProject = false
            obj.documentFiles = [
                {
                    documentType: 'CheckList',
                    documentLink: projectDetails?.scanCheckListFile,
                    status: 'InSiteQC'
                },
                {
                    documentType: 'ContractSignaturePDF',
                    documentLink: projectDetails?.termsAndConditionsPdf,
                    status: 'InSiteQC'
                },
                {
                    documentType: 'WorkingDrawingPDF',
                    documentLink: projectDetails?.workingDrawingFile,
                    status: 'InSiteQC'
                }
            ]
            const result = await HttpService.getProductDocAndCreateProject(obj)
            if (result.status === 200) {
                sendToImosAndSiteBOM();
            }
        } catch (error) {
            props.setLoading(false)
            console.log(error)
            props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
        }
    }


    // const handleRequestLink =  async() => {
    //     props.setLoading(true)
    //     try{
    //         const response = await HttpService.sendRequestForLink(projectId)
    //         props.setSnackInfo("Request Submitted Successfully", "success")
    //         setOpenGenerateLinkPopUp(false)
    //     }catch(err){
    //         props.setSnackInfo(err?.response?.data ?? "Something went wrong", "error")
    //     }
    //     props.setLoading(false)
    // }
    const [copied, setCopied] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const handleCopyLink = () => {
        navigator.clipboard.writeText(paymentLink)
            .then(() => {
                setCopied(true);
                setSnackbarOpen(true);
            })
            .catch(error => {
                setSnackbarOpen(true);
            });
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    // move deal design discussion stage
    const handleDiscussionButton = async()=>{
        setOpenAnotherDialog(false)
        props.setLoading(true)
        let obj={
            momDiscussionsDetails : momDiscussionsDetails,
            discussionNo : discussionNo,
            leadId: projectId,
            stage : stage
        }
        try{
             let res = await HttpService.discussionNotesData(obj);
             setOpen(false);
             getProjectLogs();
             setDiscussionNo("");
            setMomDiscussionsDetails({
                time: getCurrentTime(),
                designer: userDetails.name,
                date: getCurrentDate()
            })
             setMomSubmit(false);
             getcustomerDetails();
             getAllQuotationsForProject();

        }
        catch(err){
            props.setSnackInfo("Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const handleMomFormDetail = (e) => {
        setMomDiscussionsDetails({
            ...momDiscussionsDetails,
            [e.target.name]: e.target.value,
        });
    };
    const handleDiscussionDetailsSubmit = ()=>{
        setMomSubmit(true);
        setOpenMomPage(false);
    }

    const areAllFieldsFilled = () => {
        for (const key in momDiscussionsDetails) {
            if (momDiscussionsDetails.hasOwnProperty(key) && momDiscussionsDetails[key] === "") {
                return false;
            }
        }
        return true;
    };

    const openCHMticketPage = ()=>{
        try{
            let newLocalObj = JSON.parse(JSON.stringify(projectDetails));
            newLocalObj.departmentId = projectDetails.departmentId._id;
            // console.log('projectDetails.departmentId is ',projectDetails.departmentId);
            // console.log('newLocalObj.departmentId is ', newLocalObj.departmentId);
            localStorage.setItem('CHMTicketLeadDetails', JSON.stringify(newLocalObj))
            // console.log('projectDetails are',projectDetails)
            window.open("/CHM-Ticket-Creation", "_blank", "noopener,noreferrer");
        }
        catch(err){
            console.log('err ',err)
        }
    }

    // getting all the call logs for a particular lead
    const handleCallLogs = async () => {
        props.setLoading(true)
        try {
            let payload = {}
            payload.customer_contact_no = projectDetails?.customerId?.contact_no
            const response = await HttpService.getCallLogsForLead(payload)
            setCallLogs(response.data.result)
            setCallLogsModal(true)
        } catch(error) {
            console.log('error: ', error)
        }
        props.setLoading(false)
    }

    return (
        <div>
            <div className={classes.root}>
                <Grid container spacing={3}>
                    {/* Details */}
                    <Grid item lg={6} md={6} sm={12}>
                        <Paper className={classes.paper}>

                            {/* Customer Details */}
                            <Accordion defaultExpanded>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className={classes.heading}>Customer Details</Typography>
                                </AccordionSummary>

                                <Divider />

                                <AccordionDetails style={{ padding: '8px 0px 16px' }}>
                                    <TableContainer component={Paper}>
                                        <Table className={classes.table} size="small" aria-label="customized table">
                                            <TableBody>
                                                <TableRow key='1'>
                                                    <TableCell>Customer Name</TableCell>
                                                    <TableCell>{projectDetails?.customerId?.name}</TableCell>
                                                </TableRow>
                                                <TableRow key='2'>
                                                    <TableCell>Contact</TableCell>
                                                    <TableCell>{projectDetails?.customerId?.contact_no}</TableCell>
                                                </TableRow>
                                                <TableRow key='3'>
                                                    <TableCell>Email</TableCell>
                                                    <TableCell>{projectDetails?.customerId?.email}</TableCell>
                                                </TableRow>
                                                <TableRow key='4'>
                                                    <TableCell>Address</TableCell>
                                                    <TableCell>{projectDetails?.customerId?.address}</TableCell>
                                                </TableRow>
                                                <TableRow key='5'>
                                                    <TableCell>Department</TableCell>
                                                    <TableCell>{projectDetails?.departmentId?.name}</TableCell>
                                                </TableRow>
                                                <TableRow key='6'>
                                                    <TableCell>Current Stage</TableCell>
                                                    <TableCell>
                                                        {projectDetails.currentStage}
                                                    </TableCell>
                                                </TableRow>
                                                {(projectDetails?.designStatus === 'Design-Hold'  || projectDetails?.designStatus === 'Design-Hold Pending Approval') && (
                                                    <TableRow key='7'>
                                                        <TableCell>Design Status</TableCell>
                                                        <TableCell>
                                                            {projectDetails?.designStatus || "N/A"}
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                                {(projectDetails?.designStatus === 'Design-Hold' || projectDetails?.designStatus === 'Design-Hold Pending Approval') && (
                                                    <TableRow key='8'>
                                                        <TableCell>Probable Date for Restarting the Designing</TableCell>
                                                        <TableCell>
                                                            {/* {moment(projectDetails?.proposedDesignStartDate).format('DD-MM-YYYY') || "N/A"} */}
                                                            {projectDetails?.proposedDesignStartDate ? moment(projectDetails?.proposedDesignStartDate).format('DD-MM-YYYY') : 'NA'}
                                                        </TableCell>
                                                    </TableRow>
                                                )}

                                                {(projectDetails?.designStatus === 'Design-Hold' || projectDetails?.designStatus === 'Design-Hold Pending Approval') && (
                                                    <TableRow key='8.1'>
                                                        <TableCell>Probable Date of Design Sign-off </TableCell>
                                                        <TableCell>
                                                            {projectDetails?.probableDesignSignOffDate ? moment(projectDetails?.probableDesignSignOffDate).format('DD-MM-YYYY') : 'NA'}
                                                        </TableCell>
                                                    </TableRow>
                                                )}

                                                <TableRow key='9'>
                                                    <TableCell>Assigned To</TableCell>
                                                    <TableCell>{projectDetails?.assignTo?.name}</TableCell>
                                                </TableRow>
                                                <TableRow key='10'>
                                                    <TableCell>Lead Number</TableCell>
                                                    <TableCell>{projectDetails?.lead_no}</TableCell>
                                                </TableRow>
                                                <TableRow key='11'>
                                                    <TableCell>Unique Lead Number</TableCell>
                                                    <TableCell>{projectDetails?.uniqueLeadNumber}</TableCell>
                                                </TableRow>
                                                <TableRow key='12'>
                                                    <TableCell>Project No.</TableCell>
                                                    <TableCell>{projectDetails?.erpProjectNo ? projectDetails?.erpProjectNo : '-'}</TableCell>
                                                </TableRow>
                                                <TableRow key='13'>
                                                    <TableCell>ExperienceCenter</TableCell>
                                                    <TableCell>{projectDetails?.experienceCenterId?.name ? projectDetails?.experienceCenterId?.name : '-'}</TableCell>
                                                </TableRow>

                                                {/* <TableRow key='9a'> */}
                                                {/* <TableCell>Total Amount Paid by Customer</TableCell>
                                                    <TableCell style={{ color: "#0000ff" }}>
                                                        <span onClick={() => paymentData()}>
                                                                ₹ {projectDetails?.totalAmountPaidByCustomer ? commaSeparateAmount(projectDetails?.totalAmountPaidByCustomer) : `0`}
                                                            </span> */}
                                                {/* </TableCell>
                                                    </TableRow>
                                                    <TableRow key='9'>
                                                    <TableCell>Customer Pending Amount</TableCell>
                                                    <TableCell> ₹ {projectDetails?.pendingAmountByCustomer ? commaSeparateAmount(projectDetails?.pendingAmountByCustomer) : `0`}</TableCell>
                                                </TableRow>  */}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </AccordionDetails>

                                <Divider />


                                <AccordionActions>

                                    {/* Start - Payment Integration */}
                                    {
                                        (userDetails?.roles?.find(role => role.name === ROLENAME.Admin) || (userDetails?.roles?.find(role => role.name === ROLENAME.DesignManager || role.name === ROLENAME.OperationDesignManager) || (userDetails?.roles?.find(role => role.name === ROLENAME.DesignHead)))) &&
                                        <Button variant="contained" color="secondary" size="small" onClick={handlePayment}>Payment Details</Button>
                                    }
                                    {/* End - Payment Integration */}

                                    {
                                        userDetails?.roles?.find(role => role.name === ROLENAME.DocumentUploader) &&
                                        <Button variant="contained" color="primary" size="small" onClick={navigateToVerifyPage}>Verify Documents</Button>
                                    }

                                    {
                                        userDetails.email === projectDetails?.assignTo?.email && userDetails?.roles?.find(role => role.name === ROLENAME.MarketingUser || role.name === ROLENAME.MarketingManager) &&
                                        <Button variant="contained" color="primary" size="small" onClick={openStatusUpdateModal}>Update Status</Button>
                                    }
                                    {
                                        (userDetails?.roles?.find(role => role.name === ROLENAME.Admin) || (userDetails?.roles?.find(role => role.name === ROLENAME.DesignManager || role.name === ROLENAME.OperationDesignManager) || (userDetails?.roles?.find(role => role.name === ROLENAME.DesignHead)) || (userDetails?.roles?.find(role => role.name === ROLENAME.DesignUser)))) &&
                                        <Button variant="contained" color="secondary" size="small" onClick={() => setOpenErpProjectNoModal(true)}>Change Project No.</Button>
                                    }
                                    {
                                        userDetails?.roles?.find(role => role.name === ROLENAME.DesignHead || role.name === ROLENAME.OperationDesignManager || role.name === ROLENAME.DesignUser || role.name == ROLENAME.AssistantDesignManager || role.name === ROLENAME.DGM_Design) &&
                                        <Button variant="contained" color="primary" size="small" onClick={openStatusUpdateModal}>Update Status</Button>
                                    }
                                    {
                                        userDetails?.roles?.find
                                            (role =>
                                                role.name === ROLENAME.SeniorOperationManager &&
                                                projectDetails.currentStage == CURRENT_STAGE[6]) &&
                                        <Button variant="contained" style={{ background: '#2c2e3e', color: 'white' }} size="small" onClick={() => setOpenAssignSiteQcModel(true)}>Assign Site QC</Button>
                                    }
                                    {
                                        userDetails?.roles?.find
                                            (role => role.name === ROLENAME.CHM_Manager || role.name === ROLENAME.Admin) &&
                                        <Button variant='contained' color="primary" size="small" onClick={openChmUserDialog} disabled={projectDetails?.salesStage !== 'Won'}>Change CHM User</Button>   //button should be disable before lead won  because when lead won then lead assigning chm user using round robin 
                                    }
                                  {
                                        userDetails?.roles?.find
                                        (role => role.name === ROLENAME.DesignUser || role.name === ROLENAME.AssistantDesignManager|| role.name === ROLENAME.DGM_Design|| role.name === ROLENAME.DesignHead ) && (projectDetails.currentStage === "Design Sign-off HOLD" && projectDetails.contractFinanceApproved == true) &&
                                        (<Button variant='contained' color="primary" size="small" onClick={() => uploadProductDocument()}>Revoke Design Sign-off HOLD & Move to Production</Button>)
                                    }
                                    <Button variant="contained" color="primary" size="small" onClick={openCreateNotesModal}>Add Notes</Button>
                                    {
                                        userDetails?.roles?.find(role => role.name !== ROLENAME.MarketingUser && role.name !== ROLENAME.MarketingManager && role.name !== ROLENAME.DocumentUploader && role.name !== ROLENAME.ScopeAdmin) &&
                                        <Button variant="contained" color="secondary" size="small" onClick={viewQuotationPage}>View Quotation</Button>
                                    }
                                    {
                                        (userDetails?.roles?.find(role => role.name === ROLENAME.CHM_Manager || role.name === ROLENAME.CHM_User || role.name === ROLENAME.Admin || role.name === ROLENAME.TeleCallerUser || role.name === ROLENAME.TeleCallerManager)) &&
                                        <Button variant="contained" color="secondary" size="small" onClick={openCHMticketPage}>Raise Internal Ticket</Button>
                                    }
                                    {
                                         userDetails?.roles?.find(role => role.name === ROLENAME.TeleCallerManager || role.name === ROLENAME.TeleCallerUser) &&
                                         <>
                                     <Button variant="contained" color="primary" size="small">
                                        <a 
                                            href="https://decorpot.raiseaticket.com/support/#/newticket" 
                                            target="_blank" 
                                            rel="noopener noreferrer" 
                                            style={{ color: "white", textDecoration: "none" }}
                                        >
                                            Raise Service Ticket
                                        </a>
                                        </Button>
                                         </>
                                    }

                                    {
                                        (userDetails?.roles?.find(role => role.name !== ROLENAME.Admin && role.name !== ROLENAME.MarketingUser && role.name !== ROLENAME.MarketingManager && role.name !== ROLENAME.ScopeAdmin && role.name !== ROLENAME.DocumentUploader && role.name !== ROLENAME.TeleCallerUser || role.name !== ROLENAME.TeleCallerManager) &&
                                            (userDetails.email === projectDetails?.assignTo?.email || userDetails?.teamId?._id === projectDetails?.assignTo?.teamId?._id || userDetails.departmentId === projectDetails?.departmentId?._id)
                                            // && projectDetails?.salesStage !== 'Won Deals Pending Designer Assignment'
                                        ) &&
                                         <>
                                         <Button variant="contained" color="secondary" size="small" onClick={handleClickOpen}
                                         disabled={ 
                                         (
                                            projectDetails?.currentStage === "Design Sign-off" &&
                                            (
                                            projectDetails?.contractQualityControlApproved || 
                                            projectDetails?.contractQCManagerApproved ||
                                            projectDetails?.contractDesignManagerApproved ||
                                            projectDetails?.contractCustomerApproved ||
                                            projectDetails?.contractFinanceApproved 
                                            )
                                            ) 
                                        }> Move Deal</Button>
                                        </>
                                        }
                                </AccordionActions>
                                <AccordionActions>
                                    <Button variant="contained" color="secondary" size="small" onClick={handleCallLogs}>Call Logs</Button>

                                    {
                                        (userDetails?.roles?.find(role => role.name === ROLENAME.Admin || ((role.name === "Sales Manager" || role.name === 'Sales Head') && !projectDetails.customerApproved?.[0].isApproved) || (role.name === "Design Manager" && projectDetails?.departmentId?.name === 'Design') || (role.name === "Operation Design Manager" && projectDetails?.departmentId?.name === 'Design')|| (role.name === "Assistant Design Manager" && projectDetails?.departmentId?.name === 'Design')  || role.name === "Design Head" && projectDetails?.departmentId?.name === 'Design')) &&
                                        <Button variant="contained" color="primary" size="small" onClick={openEditProjectModal}>Edit Lead</Button>
                                    }
                                    {
                                        userDetails?.roles?.find(role => role.name !== ROLENAME.MarketingManager || role.name !== ROLENAME.marketingUser) &&
                                        <Button variant="contained" color="primary" size="small" onClick={() => viewCheckList()}>View Check List</Button>
                                    }
                                    {
                                        userDetails?.roles?.find(role => role.name === ROLENAME.DesignManager || role.name === ROLENAME.OperationDesignManager || role.name === ROLENAME.AssistantDesignManager || role.name === ROLENAME.DesignHead || role.name === ROLENAME.DesignUser) &&
                                        <Button variant="contained" color="secondary" size="small" onClick={() => setOpenDesignSignOffModal(true)}>Expected Design Sign-off Date</Button>
                                    }

                                    {
                                        projectDetails.designStages === 'Assign to Designer' && userDetails?.roles?.find(role => role.name === ROLENAME.DesignManager || role.name === ROLENAME.OperationDesignManager || role.name === ROLENAME.AssistantDesignManager) &&
                                        <Button variant="contained" color="primary" size="small" onClick={() => { setOpenDesignerAssignmentDate(true); getDesignUsers() }}> Set Designer Assignment Date </Button>
                                    }
                                    {
                                        userDetails?.roles?.find(role => role.name === ROLENAME.DesignUser || role.name === ROLENAME.CHM_Manager || role.name === ROLENAME.CHM_User) &&
                                        <Button variant="contained" color="primary" size="small" onClick={() => setOpenUpdatePaymentModel(true)} > Update Payment </Button>
                                    }
                                    {
                                        userDetails?.roles?.find(role => role.name === ROLENAME.Execution_Manager || role.name === ROLENAME.CHM_Manager || role.name === ROLENAME.CHM_User || role.name === ROLENAME.SeniorOperationManager) &&
                                        <Button variant="contained" color="primary" size="small" onClick={clientExtraDays}>Extra days due to client dependency</Button>
                                    }
                                    {
                                        userDetails?.roles?.find(role => role.name === ROLENAME.SalesManager || role.name === ROLENAME.SalesHead || role.name === ROLENAME.SalesUser) &&
                                        <Button variant="contained" color="primary" size="small" onClick={floorPlanButton}>Floor Plan</Button>
                                    }
                                    {
                                        userDetails?.roles?.find(role => role.name === ROLENAME.SalesManager || role.name === ROLENAME.SalesHead || role.name === ROLENAME.SalesUser || role.name === ROLENAME.AssistantSalesManager) &&
                                        <Button variant="contained" color="secondary" size="small" onClick={() => setOpenEnterSalesClosureDateModal(true)}>Enter Expected Sales Closure Date</Button>
                                    }
                                   {
                                        ((userDetails?.departmentId === salesDepartment || userDetails?.departmentId === designDepartment || userDetails?.departmentId === constantDepartmentId.chm || (userDetails?.roles?.find(role => role.name === ROLENAME.FactoryCrmUser))) && (userDetails?.roles?.find(role => role.name !== ROLENAME.QCManager && role.name !== ROLENAME.QualityControl)))&&
                                        <Button
                                            variant="contained"
                                            color="secondary" size="small"
                                            className={classes.generateButton}
                                            onClick={handleClickOpenNew}
                                            disabled={(userDetails?.departmentId === constantDepartmentId.chm || (userDetails?.roles?.find(role => role.name === ROLENAME.FactoryCrmUser)) ? false : userDetails?.departmentId !== projectDetails?.departmentId?._id)}
                                        >
                                            Generate Payment Link
                                        </Button>
                                    }
                                    {/* {
                                        (userDetails?.roles?.find(role => role.name === ROLENAME.DesignUser) && (projectDetails?.cashFreeLinkRequest === undefined || projectDetails?.cashFreeLinkRequest === null || projectDetails?.cashFreeLinkRequest?.isApproved === false || projectDetails?.cashFreeLinkRequest?.isRequested === false)) && 
                                        <Button
                                            variant="contained"
                                            color="secondary" size="small"
                                            className={classes.generateButton}
                                            onClick={() => setOpenGenerateLinkPopUp(true)}
                                            disabled={projectDetails?.cashFreeLinkRequest?.isRequested === true}
                                        >
                                            Request For Generate Link
                                        </Button>
                                    } */}
                                </AccordionActions>
                            </Accordion>

                            {/* Project Details  */}
                            <Accordion defaultExpanded>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Typography className={classes.heading}>Sales & Marketing Details</Typography>
                                </AccordionSummary>
                                <Divider />

                                <AccordionDetails style={{ padding: '35px 0px 16px' }}>
                                    <TableContainer component={Paper}>
                                        <Table className={classes.table} size="small" aria-label="customized table">
                                            <TableBody>

                                                {
                                                    projectDetails?.salesStage === 'Won' &&
                                                    <>
                                                        <TableRow key='2a'>
                                                            <TableCell>Tentative Possession</TableCell>
                                                            <TableCell>{moment(projectDetails?.possessionDate).format('DD-MM-YYYY')}</TableCell>
                                                        </TableRow>
                                                        <TableRow key='2b'>
                                                            <TableCell>Tentative Design Closure</TableCell>
                                                            <TableCell>{moment(projectDetails?.closureDate).format('DD-MM-YYYY')}</TableCell>
                                                        </TableRow>
                                                        <TableRow key='2c'>
                                                            <TableCell>Tentative Client Move-in Date</TableCell>
                                                            <TableCell>{moment(projectDetails?.clientMoveinDate).format('DD-MM-YYYY')}</TableCell>
                                                        </TableRow>
                                                        <TableRow key='2d'>
                                                            <TableCell>Expected Sales Won Date</TableCell>
                                                            <TableCell>{projectDetails?.expectedTenPercent ? moment(projectDetails?.expectedTenPercent).format('DD-MM-YYYY') : `-`}</TableCell>
                                                        </TableRow>
                                                    </>
                                                }
                                                <TableRow key='3'>
                                                    <TableCell>Project Type</TableCell>
                                                    <TableCell>{projectDetails?.projectType ? projectDetails?.projectType : `-`}</TableCell>
                                                </TableRow>
                                                <TableRow key='4'>
                                                    <TableCell>Property Type</TableCell>
                                                    <TableCell>{projectDetails?.propertyType ? projectDetails?.propertyType : `-`}</TableCell>
                                                </TableRow>
                                                <TableRow key='5'>
                                                    <TableCell>Scope of Work</TableCell>
                                                    <TableCell>{projectDetails?.scopeOfWork ? projectDetails?.scopeOfWork : `-`}</TableCell>
                                                </TableRow>
                                                <TableRow key='6'>
                                                    <TableCell>AssignedTo</TableCell>
                                                    <TableCell>{projectDetails?.assignTo?.name ? projectDetails?.assignTo?.name : `-`}</TableCell>
                                                </TableRow>
                                                <TableRow key='8'>
                                                    <TableCell>City </TableCell>
                                                    <TableCell>{projectDetails?.city ? projectDetails?.city?.name : `-`}</TableCell>
                                                </TableRow>
                                                <TableRow key='9'>
                                                    <TableCell>FloorPlan </TableCell>
                                                    <TableCell>{projectDetails?.floorPlanAttachment ? 'Yes' : `No`}</TableCell>
                                                </TableRow>
                                                <TableRow key='10'>
                                                    <TableCell>Sq.Ft Area</TableCell>
                                                    <TableCell>{projectDetails?.area ? projectDetails?.area : `-`}</TableCell>
                                                </TableRow>
                                                <TableRow key='11'>
                                                    <TableCell>LeadType</TableCell>
                                                    <TableCell>{projectDetails?.leadType ? projectDetails?.leadType : `-`}</TableCell>
                                                </TableRow>

                                                <TableRow key='11q'>
                                                    <TableCell>Reason to Move To Be Called/Hold</TableCell>
                                                    <TableCell>{projectDetails?.reasonForOtherLeadType ? projectDetails?.reasonForOtherLeadType : `-`}</TableCell>
                                                </TableRow>
                                                    
                                                
                                                <TableRow key='12'>
                                                    <TableCell>Status</TableCell>
                                                    <TableCell>{projectDetails?.status ? projectDetails?.status : `-`}</TableCell>
                                                </TableRow>
                                                <TableRow key='13'>
                                                    <TableCell>Registered </TableCell>
                                                    <TableCell>{projectDetails?.isRegistered ? projectDetails?.isRegistered : `-`}</TableCell>
                                                </TableRow>
                                                <TableRow key='14'>
                                                    <TableCell>Source</TableCell>
                                                    <TableCell>{projectDetails?.sourceOfLead ? projectDetails?.sourceOfLead?.name : `-`}</TableCell>
                                                </TableRow>
                                                <TableRow key='15'>
                                                    <TableCell>Remark(scopeOfWork)</TableCell>
                                                    <TableCell>{projectDetails?.remark ? projectDetails?.remark : `-`}</TableCell>
                                                </TableRow>
                                                <TableRow key='16'>
                                                    <TableCell>Estimate Quotation Amount </TableCell>
                                                    <TableCell>₹ {projectDetails?.estimatedBudget ? commaSeparateAmount(projectDetails?.estimatedBudget) : `0`}</TableCell>
                                                </TableRow>
                                                <TableRow key='17'>
                                                    <TableCell>Quotation Amount </TableCell>
                                                    <TableCell>₹ {projectDetails?.grandTotal ? commaSeparateAmount(projectDetails?.grandTotal) : `0`}</TableCell>
                                                </TableRow>
                                                <TableRow key='18'>
                                                    <TableCell>Apply Discount on All Scopes:</TableCell>
                                                    <TableCell>{projectDetails?.discountOnAllScopes === true ? `True` : `-`}</TableCell>
                                                </TableRow>
                                                <TableRow key='18a'>
                                                    <TableCell>Apply Discount Percentage</TableCell>
                                                    <TableCell>{projectDetails?.discountPercent ? projectDetails?.discountPercent + "%" : `0`}</TableCell>
                                                </TableRow>
                                                <TableRow key='19a'>
                                                    <TableCell>Created At:</TableCell>
                                                    <TableCell>{moment(projectDetails?.createdAt).format('DD-MM-YYYY')}</TableCell>
                                                </TableRow>
                                                <TableRow key='19b'>
                                                    <TableCell>Meeting With Customer:</TableCell>
                                                    <TableCell>{projectDetails?.meetingWithCustomer?.meetingDate ? moment(projectDetails?.meetingWithCustomer?.meetingDate).format('DD-MM-YYYY') : `-`}</TableCell>
                                                </TableRow>
                                                <TableRow key='19b1'>
                                                    <TableCell>Expected Sales Closure Date:</TableCell>
                                                    <TableCell>{projectDetails?.expectedSalesclosureDate ? moment(projectDetails?.expectedSalesclosureDate).format('DD-MM-YYYY') : `-`}</TableCell>
                                                </TableRow>
                                                <TableRow key='19c'>
                                                    <TableCell>Audio Recording File</TableCell>
                                                    <TableCell>
                                                        {projectDetails?.recordingFile ?
                                                            <span onClick={() => window.open(projectDetails?.recordingFile)} > <GetAppOutlinedIcon /> </span>
                                                            :
                                                            `-`
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow key='19d'>
                                                    <TableCell>Floor Plan Attachment</TableCell>
                                                    <TableCell>
                                                        {projectDetails?.floorPlanAttachment ?
                                                            <span onClick={() => window.open(projectDetails?.floorPlanAttachment)} > <GetAppOutlinedIcon /> </span>
                                                            :
                                                            `-`
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                                {
                                                    projectDetails?.salesStage === 'Won' &&
                                                    <>
                                                        <TableRow key='2e'>
                                                            <TableCell>GST Required</TableCell>
                                                            <TableCell>{projectDetails?.customerRequriedGst === true ? `Yes` : projectDetails?.customerRequriedGst === false ? `No` : `-`}</TableCell>
                                                        </TableRow>
                                                        <TableRow key='2e'>
                                                            <TableCell>GST Invoice Name</TableCell>
                                                            <TableCell>{projectDetails?.customerGstInName ? projectDetails?.customerGstInName : `-`}</TableCell>
                                                        </TableRow>
                                                        <TableRow key='2e'>
                                                            <TableCell>GST Number</TableCell>
                                                            <TableCell>{projectDetails?.customerGstInNumber ? projectDetails?.customerGstInNumber : `-`}</TableCell>
                                                        </TableRow>
                                                        <TableRow key='2e'>
                                                            <TableCell>GST Shipping</TableCell>
                                                            <TableCell>{projectDetails?.customerGstShipping ? projectDetails?.customerGstShipping : `-`}</TableCell>
                                                        </TableRow>

                                                    </>
                                                }
                                                <TableRow key='2e'>
                                                    <TableCell>CHM Executive</TableCell>
                                                    <TableCell>{projectDetails?.chmUser ? projectDetails?.chmUser?.name : '-'}</TableCell>
                                                </TableRow>

                                                {/* Add New Column of Expected handover date when lead is created */}
                                                <TableRow key='21a'>
                                                    <TableCell>Expected Handover Date</TableCell>
                                                    <TableCell>{projectDetails?.expectedHandoverDate ? moment(projectDetails?.expectedHandoverDate).format('DD-MM-YYYY') : '-'}</TableCell>
                                                </TableRow>

                                                <TableRow key='21b'>
                                                    <TableCell>Sales Won Experience Center</TableCell>
                                                    <TableCell>{projectDetails?.salesWonExpCtrId?.name ? projectDetails?.salesWonExpCtrId?.name : 'NA'}</TableCell>
                                                </TableRow>

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </AccordionDetails>

                            </Accordion>

                            {/*design details */}
                            <Accordion defaultExpanded>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Typography className={classes.heading}>Design Details</Typography>
                                </AccordionSummary>
                                <Divider />
                                <AccordionDetails style={{ padding: '8px 0px 16px' }}>
                                    <TableContainer component={Paper}>
                                        <Table className={classes.table} size="small" aria-label="customized table">
                                            <TableBody>
                                                <TableRow key='1'>
                                                    <TableCell>Internal Design Sign-off Date</TableCell>
                                                    <TableCell>{projectDetails?.internalDesignSignOffDate ? moment(projectDetails?.internalDesignSignOffDate).format('DD-MM-YYYY') : `-`}</TableCell>
                                                </TableRow>
                                                <TableRow key='2'>
                                                    <TableCell>Expected Design Sign-off Date</TableCell>
                                                    <TableCell>{projectDetails?.expectedDesignSignOffDate ? moment(projectDetails?.expectedDesignSignOffDate).format('DD-MM-YYYY') : `-`}</TableCell>
                                                </TableRow>

                                                <TableRow key='3'>
                                                    <TableCell>Days from Contract sign:</TableCell>
                                                    <TableCell>{daysfromContractsign !== "" ? daysfromContractsign.toFixed(0) : `-`}</TableCell>
                                                </TableRow>

                                                <TableRow key='4'>
                                                    <TableCell>Design Kick-off SKP:</TableCell>
                                                    <TableCell>
                                                        {projectDetails?.designKickOffCustomerFileLinks?.skpFileLink ?
                                                            <span onClick={() => window.open(projectDetails?.designKickOffCustomerFileLinks?.skpFileLink)} > <GetAppOutlinedIcon /> </span>
                                                            :
                                                            `-`
                                                        }
                                                    </TableCell>
                                                </TableRow>

                                                <TableRow key='5'>
                                                    <TableCell>Design Kick-off Quotation PDF:</TableCell>
                                                    <TableCell>
                                                        {projectDetails?.designKickOffCustomerFileLinks?.quotationFileLink ?
                                                            <span onClick={() => window.open(projectDetails?.designKickOffCustomerFileLinks?.quotationFileLink)} > <GetAppOutlinedIcon /> </span>
                                                            :
                                                            `-`
                                                        }
                                                    </TableCell>
                                                </TableRow>

                                                <TableRow key='6'>
                                                    <TableCell>Design Kick-off Designer MOM:</TableCell>
                                                    <TableCell>
                                                        {projectDetails?.designKickOffCustomerFileLinks?.momFileLink ?
                                                            <span onClick={() => window.open(projectDetails?.designKickOffCustomerFileLinks?.momFileLink)} > <GetAppOutlinedIcon /> </span>
                                                            :
                                                            `-`
                                                        }
                                                    </TableCell>
                                                </TableRow>

                                                <TableRow key='7'>
                                                    <TableCell>Design Kick-off Screenshots:</TableCell>
                                                    <TableCell>
                                                        <EyeFilled style={{ fontSize: 25 }} onClick={handleDesignKickOffScreenshots} />
                                                    </TableCell>
                                                </TableRow>

                                                <TableRow key='8'>
                                                    <TableCell>Design Stage</TableCell>
                                                    <TableCell>
                                                        {projectDetails?.designStatus || "N/A"}
                                                    </TableCell>
                                                </TableRow>

                                                <TableRow key='9'>
                                                    <TableCell>Design Executive</TableCell>
                                                    <TableCell>
                                                        {projectDetails?.designUser ? projectDetails?.designUser?.name : "-"}
                                                    </TableCell>
                                                </TableRow>

                                                <TableRow key='10'>
                                                    <TableCell>Design Manager</TableCell>
                                                    <TableCell>
                                                        {projectDetails?.designManager ? projectDetails?.designManager.name : "-"}
                                                    </TableCell>
                                                </TableRow>

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </AccordionDetails>
                            </Accordion>

                            {/* Deals Details */}
                            <Accordion defaultExpanded>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Typography className={classes.heading}>Post Design Sign-off Details</Typography>
                                </AccordionSummary>
                                <Divider />
                                <AccordionDetails style={{ padding: '8px 0px 16px' }}>
                                    <TableContainer component={Paper}>
                                        <Table className={classes.table} size="small" aria-label="customized table">
                                            <TableBody>
                                                <TableRow key='1a'>
                                                    <TableCell>Days from Modular delivery:</TableCell>
                                                    <TableCell>{daysfromModulardelivery !== "" ? daysfromModulardelivery.toFixed(0) : `-`}</TableCell>
                                                </TableRow>

                                                <TableRow key='1b'>
                                                    <TableCell>Imos Drawing Date to Factory</TableCell>
                                                    <TableCell>{projectDetails?.imosToFactoryDate ? moment(projectDetails?.imosToFactoryDate).format('DD-MM-YYYY') : `-`}</TableCell>
                                                </TableRow>
                                                <TableRow key='3'>
                                                    <TableCell>Design Sign-off Date</TableCell>
                                                    {/* <TableCell>{projectDetails?.designSignOffDate ? moment(projectDetails?.designSignOffDate).format('DD-MM-YYYY') : `-`}</TableCell> */}
                                                    <TableCell>{projectDetails?.customerDesignSignOffDate ? moment(projectDetails?.customerDesignSignOffDate).format('DD-MM-YYYY') : `-`}</TableCell>
                                                </TableRow>
                                                <TableRow key='3a'>
                                                    <TableCell>Extra Days Due To Client Dependency:</TableCell>
                                                    <TableCell>{projectDetails?.extraDaysDueToClientDependency ? projectDetails?.extraDaysDueToClientDependency : `-`}</TableCell>
                                                </TableRow>
                                                <TableRow key='4'>
                                                    <TableCell>Working Drawing File</TableCell>
                                                    <TableCell>
                                                        {projectDetails?.workingDrawingFile ?
                                                            <span onClick={() => window.open(projectDetails?.workingDrawingFile)} > <GetAppOutlinedIcon /> </span>
                                                            :
                                                            `-`
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow key='5'>
                                                    <TableCell>3D Working Drawing File</TableCell>
                                                    <TableCell>
                                                        {projectDetails?.ThreeDworkingDrawingFile ?
                                                            <span onClick={() => window.open(projectDetails?.ThreeDworkingDrawingFile)} > <GetAppOutlinedIcon /> </span>
                                                            :
                                                            `-`
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow key='18'>
                                                    <TableCell>Project Kick-Off Check List File</TableCell>
                                                    <TableCell>
                                                        {projectDetails?.scanProjectKickOffCheckListFile ?
                                                            <span onClick={() => window.open(projectDetails?.scanProjectKickOffCheckListFile)} > <GetAppOutlinedIcon /> </span>
                                                            :
                                                            `-`
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow key='19'>
                                                    <TableCell>SiteQC Check List File</TableCell>
                                                    <TableCell>
                                                        {projectDetails?.scanSiteQCCheckListFile ?
                                                            <span onClick={() => window.open(projectDetails?.scanSiteQCCheckListFile)} > <GetAppOutlinedIcon /> </span>
                                                            :
                                                            `-`
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow key='20'>
                                                    <TableCell>SiteQC Photos</TableCell>
                                                    <TableCell>
                                                        {projectDetails?.scanSiteQCPhotos && projectDetails.scanSiteQCPhotos.length > 0 ? (
                                                            projectDetails.scanSiteQCPhotos.map((photo, index) => (
                                                                <span key={index} onClick={() => window.open(photo)}>
                                                                    <GetAppOutlinedIcon />
                                                                </span>
                                                            ))
                                                        ) : (
                                                            `-`
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow key='20a'>
                                                    <TableCell>SiteQC Video</TableCell>
                                                    <TableCell>
                                                        {projectDetails?.siteQcVideo && projectDetails.siteQcVideo.length > 0 ? (
                                                            projectDetails.siteQcVideo.map((video, index) => (
                                                                <span key={index} onClick={() => window.open(video)}>
                                                                    <GetAppOutlinedIcon />
                                                                </span>
                                                            ))
                                                        ) : (
                                                            `-`
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow key='6'>
                                                    <TableCell>Signed Check list File</TableCell>
                                                    <TableCell>
                                                        {projectDetails?.salesChecklistPdfFile ?
                                                            <span onClick={() => window.open(projectDetails?.salesChecklistPdfFile)} > <GetAppOutlinedIcon /> </span>
                                                            :
                                                            `-`
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow key='7'>
                                                    <TableCell>Contract Sign File</TableCell>
                                                    <TableCell>
                                                        {projectDetails?.termsAndConditionsPdf ?
                                                            <span onClick={() => window.open(projectDetails?.termsAndConditionsPdf)} > <GetAppOutlinedIcon /> </span>
                                                            :
                                                            `-`
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow key='8a'>
                                                    <TableCell>Quotation File</TableCell>
                                                    <TableCell>
                                                        {projectDetails?.scanQuotationFile ?
                                                            <span onClick={() => window.open(projectDetails?.scanQuotationFile)} > <GetAppOutlinedIcon /> </span>
                                                            :
                                                            `-`
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow key='8b'>
                                                    <TableCell>Quotation Csv File</TableCell>
                                                    <TableCell>
                                                        {projectDetails?.quotationCsvFile ?
                                                            <span onClick={() => window.open(projectDetails?.quotationCsvFile)} > <GetAppOutlinedIcon /> </span>
                                                            :
                                                            `-`
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                                {/* <TableRow key='8bc'>
                                                    <TableCell>Final Working Drawing File</TableCell>
                                                    <TableCell>
                                                        {projectDetails?.finalWDFile ?
                                                            <span onClick={() => window.open(projectDetails?.finalWDFile)} > <GetAppOutlinedIcon /> </span>
                                                            :
                                                            `-`
                                                        }
                                                    </TableCell>
                                                </TableRow> */}
                                                {
                                                    userDetails.roles.find(role => role.name === ROLENAME.Admin || role.name === ROLENAME.CHM_Manager || role.name === ROLENAME.CHM_User) &&
                                                    <>
                                                        <TableRow key='8'>
                                                            <TableCell>Carpentry File</TableCell>
                                                            <TableCell>
                                                                {projectDetails?.imosCarpentryFile ?
                                                                    <span style={{ cursor: "pointer" }} onClick={() => window.open(projectDetails?.imosCarpentryFile)} > <GetAppOutlinedIcon /> </span>
                                                                    : `-`
                                                                }
                                                            </TableCell>
                                                            <TableCell>
                                                                {projectDetails?.newImosCarpentryFile ?
                                                                    <Tooltip title="New File">
                                                                        <span style={{ cursor: "pointer" }} onClick={() => window.open(projectDetails?.newImosCarpentryFile)} > <GetAppOutlinedIcon /> </span>
                                                                    </Tooltip>
                                                                    :
                                                                    `-`
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow key='9'>
                                                            <TableCell>Custom File</TableCell>
                                                            <TableCell>
                                                                {projectDetails?.imosCustomFile ?
                                                                    <span style={{ cursor: "pointer" }} onClick={() => window.open(projectDetails?.imosCustomFile)} > <GetAppOutlinedIcon /> </span>
                                                                    : `-`
                                                                }
                                                            </TableCell>
                                                            <TableCell>
                                                                {projectDetails?.newImosCustomFile ?
                                                                    <Tooltip title="New File">
                                                                        <span style={{ cursor: "pointer" }} onClick={() => window.open(projectDetails?.newImosCustomFile)} > <GetAppOutlinedIcon /> </span>
                                                                    </Tooltip>
                                                                    : `-`
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow key='10'>
                                                            <TableCell>Edge Band File</TableCell>
                                                            <TableCell>
                                                                {projectDetails?.imosEdgeBandingFile ?
                                                                    <span style={{ cursor: "pointer" }} onClick={() => window.open(projectDetails?.imosEdgeBandingFile)} > <GetAppOutlinedIcon /> </span>
                                                                    : `-`
                                                                }
                                                            </TableCell>
                                                            <TableCell>
                                                                {projectDetails?.newImosEdgeBandingFile ?
                                                                    <Tooltip title="New File">
                                                                        <span style={{ cursor: "pointer" }} onClick={() => window.open(projectDetails?.newImosEdgeBandingFile)} > <GetAppOutlinedIcon /> </span>
                                                                    </Tooltip>
                                                                    : `-`
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow key='11'>
                                                            <TableCell>Hardware File</TableCell>
                                                            <TableCell>
                                                                {projectDetails?.imosHardwareFile ?
                                                                    <span style={{ cursor: "pointer" }} onClick={() => window.open(projectDetails?.imosHardwareFile)} > <GetAppOutlinedIcon /> </span>
                                                                    : `-`
                                                                }
                                                            </TableCell>
                                                            <TableCell>
                                                                {projectDetails?.newImosHardwareFile ?
                                                                    <Tooltip title="New File">
                                                                        <span style={{ cursor: "pointer" }} onClick={() => window.open(projectDetails?.newImosHardwareFile)} > <GetAppOutlinedIcon /> </span>
                                                                    </Tooltip>
                                                                    : `-`
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow key='12'>
                                                            <TableCell>Pasting PDF File</TableCell>
                                                            <TableCell>
                                                                {projectDetails?.imosPastingListPdf ?
                                                                    <span style={{ cursor: "pointer" }} onClick={() => window.open(projectDetails?.imosPastingListPdf)} > <GetAppOutlinedIcon /> </span>
                                                                    : `-`
                                                                }
                                                            </TableCell>
                                                            <TableCell>
                                                                {projectDetails?.newImosPastingListPdf ?
                                                                    <Tooltip title="New File">
                                                                        <span style={{ cursor: "pointer" }} onClick={() => window.open(projectDetails?.newImosPastingListPdf)} > <GetAppOutlinedIcon /> </span>
                                                                    </Tooltip>
                                                                    : `-`
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow key='13'>
                                                            <TableCell>Pasting Excel File</TableCell>
                                                            <TableCell>
                                                                {projectDetails?.imosPastingListExcel ?
                                                                    <span style={{ cursor: "pointer" }} onClick={() => window.open(projectDetails?.imosPastingListExcel)} > <GetAppOutlinedIcon /> </span>
                                                                    : `-`
                                                                }
                                                            </TableCell>
                                                            <TableCell>
                                                                {projectDetails?.newImosPastingListExcel ?
                                                                    <Tooltip title="New File">
                                                                        <span style={{ cursor: "pointer" }} onClick={() => window.open(projectDetails?.newImosPastingListExcel)} > <GetAppOutlinedIcon /> </span>
                                                                    </Tooltip>
                                                                    : `-`
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow key='14'>
                                                            <TableCell>Saw File</TableCell>
                                                            <TableCell>
                                                                {projectDetails?.imosSawFile ?
                                                                    <span style={{ cursor: "pointer" }} onClick={() => window.open(projectDetails?.imosSawFile)} > <GetAppOutlinedIcon /> </span>
                                                                    : `-`
                                                                }
                                                            </TableCell>
                                                            <TableCell>
                                                                {projectDetails?.newImosSawFile ?
                                                                    <Tooltip title="New File">
                                                                        <span style={{ cursor: "pointer" }} onClick={() => window.open(projectDetails?.newImosSawFile)} > <GetAppOutlinedIcon /> </span>
                                                                    </Tooltip>
                                                                    : `-`
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow key='15'>
                                                            <TableCell>MPR File</TableCell>
                                                            <TableCell>
                                                                {projectDetails?.imosMprFile ?
                                                                    <span style={{ cursor: "pointer" }} onClick={() => window.open(projectDetails?.imosMprFile)} > <GetAppOutlinedIcon /> </span>
                                                                    : `-`
                                                                }
                                                            </TableCell>
                                                            <TableCell>
                                                                {projectDetails?.newImosMprFile ?
                                                                    <Tooltip title="New File">
                                                                        <span style={{ cursor: "pointer" }} onClick={() => window.open(projectDetails?.newImosMprFile)} > <GetAppOutlinedIcon /> </span>
                                                                    </Tooltip>
                                                                    : `-`
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow key='16'>
                                                            <TableCell>Tracking File</TableCell>
                                                            <TableCell>
                                                                {projectDetails?.imosTrackingFile ?
                                                                    <span style={{ cursor: "pointer" }} onClick={() => window.open(projectDetails?.imosTrackingFile)} > <GetAppOutlinedIcon /> </span>
                                                                    : `-`
                                                                }
                                                            </TableCell>
                                                            <TableCell>
                                                                {projectDetails?.newImosTrackingFile ?
                                                                    <Tooltip title="New File">
                                                                        <span style={{ cursor: "pointer" }} onClick={() => window.open(projectDetails?.newImosTrackingFile)} > <GetAppOutlinedIcon /> </span>
                                                                    </Tooltip>
                                                                    : `-`
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow key='17'>
                                                            <TableCell>Updated Pasting List File</TableCell>
                                                            <TableCell>
                                                                {projectDetails?.updatedPastingListFile ?
                                                                    <span style={{ cursor: "pointer" }} onClick={() => window.open(projectDetails?.updatedPastingListFile)} > <GetAppOutlinedIcon /> </span>
                                                                    : `-`
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableCell>Jali File</TableCell>
                                                        <TableCell>
                                                            {projectDetails?.imosJaliFile ?
                                                                <span style={{ cursor: "pointer" }} onClick={() => window.open(projectDetails?.imosJaliFile)} > <GetAppOutlinedIcon /> </span>
                                                                : `-`
                                                            }
                                                        </TableCell>
                                                        <TableCell>
                                                            {projectDetails?.newImosJaliFile ?
                                                                <Tooltip title="New File">
                                                                    <span style={{ cursor: "pointer" }} onClick={() => window.open(projectDetails?.newImosJaliFile)} > <GetAppOutlinedIcon /> </span>
                                                                </Tooltip>
                                                                : `-`
                                                            }
                                                        </TableCell>
                                                    </>
                                                }
                                                <TableRow key='9'>
                                                    <TableCell>Factory Ready to Dispatch Date</TableCell>
                                                    <TableCell>{projectDetails?.readyToDispatchDate ? moment(projectDetails?.readyToDispatchDate).format('DD-MM-YYYY') : `-`}</TableCell>
                                                </TableRow>
                                                <TableRow key='10a'>
                                                    <TableCell>Material Dispatched Date</TableCell>
                                                    <TableCell>{projectDetails?.materialDispatchedDate ? moment(projectDetails?.materialDispatchedDate).format('DD-MM-YYYY') : `-`}</TableCell>
                                                </TableRow>
                                                <TableRow key='10b'>
                                                    <TableCell>Modular Delivery Date</TableCell>
                                                    <TableCell>{projectDetails?.modularDeliveryDate ? moment(projectDetails?.modularDeliveryDate).format('DD-MM-YYYY') : `-`}</TableCell>
                                                </TableRow>
                                                <TableRow key='10c'>
                                                    <TableCell>Expected Project Completion Date</TableCell>
                                                    {/* <TableCell>{projectDetails?.projectCompletionDate ? moment(projectDetails?.projectCompletionDate).format('DD-MM-YYYY') : `-`}</TableCell> */}
                                                    <TableCell>{projectDetails?.customerDesignSignOffDate ? moment(projectDetails?.customerDesignSignOffDate).add(60, 'days').format('DD-MM-YYYY') : `-`}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </AccordionDetails>
                            </Accordion>
                        </Paper>
                    </Grid>

                    {/* Notes Details */}
                    <Grid item lg={6} md={6} sm={12}>
                        {projectLogs?.map((data, i) => (
                            <Paper className={classes.paper} key={i + 1}>
                                {
                                    data?.dealActivity && <Accordion defaultExpanded className="accordian">
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography className={classes.heading}>
                                                <LocalActivityIcon />
                                                <span className="titlename">Deal Activity</span>
                                                <span className="datedata">{moment(data?.createdAt).format('lll')}</span>
                                                {/* {{pl.createdAt | date}}, {{pl.createdAt | date:'shortTime' }}  */}
                                            </Typography>
                                        </AccordionSummary>
                                        <Divider />
                                        <AccordionDetails>
                                            <Typography variant="body2" gutterBottom>
                                                {data?.dealActivity}
                                                {/* design-hold pending approval sequence */}
                                                {data.isDesignHoldApprovalSent && projectDetails?.departmentId?.name === 'Design' &&
                                                     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px'}}>
                                                     <h5
                                                         style={{
                                                             color: projectDetails?.designHoldStatusApproval?.admApproval === 'Sent for Approval' ? 'red' 
                                                                 : projectDetails?.designHoldStatusApproval?.admApproval === 'Approved' ? 'green' : 'inherit'
                                                         }}> ADM <FaArrowRight />
                                                     </h5>
 
                                                     <h5 style={{
                                                         color: projectDetails?.designHoldStatusApproval?.odmApproval === 'Sent for Approval' ? 'red'
                                                             : projectDetails?.designHoldStatusApproval?.odmApproval === 'Approved' ? 'green' : 'inherit'
                                                     }}> ODM Design <FaArrowRight /></h5>
 
                                                     <h5 style={{
                                                          color: projectDetails?.designHoldStatusApproval?.customerApproval === 'Sent for Approval' ? 'red'
                                                             : projectDetails?.designHoldStatusApproval?.customerApproval === 'Approved' ? 'green' : 'inherit'
                                                      }}> Customer </h5>
                                                 </div>
                                                }
                                                {data.isLostApprovalSent && projectDetails?.departmentId?.name === 'Design' &&
                                                     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px'}}>
                                                     <h5
                                                         style={{
                                                             color: projectDetails?.designLostApproval?.odmApproval === 'Sent for Approval' ? 'red' 
                                                                 : projectDetails?.designLostApproval?.odmApproval === 'Approved' ? 'green' : 'inherit'
                                                         }}> Operation Design Manager <FaArrowRight />
                                                     </h5>
 
                                                     <h5 style={{
                                                         color: projectDetails?.designLostApproval?.dgmDesignApproval === 'Sent for Approval' ? 'red'
                                                             : projectDetails?.designLostApproval?.dgmDesignApproval === 'Approved' ? 'green' : 'inherit'
                                                     }}> DGM Design <FaArrowRight /></h5>
 
                                                     <h5 style={{
                                                          color: projectDetails?.designLostApproval?.adminApproval === 'Sent for Approval' ? 'red'
                                                             : projectDetails?.designLostApproval?.adminApproval === 'Approved' ? 'green' : 'inherit'
                                                      }}> Admin </h5>
                                                 </div>
                                                }
                                                 {data.isLostApprovalSent && projectDetails?.departmentId?.name === 'Sales' && projectDetails.chmReasonForDesignKickOffRejection &&
                                                     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px'}}>
 
                                                     <h5 style={{
                                                         color: projectDetails?.salesLostApproval?.smApproval === 'Sent for Approval' ? 'red'
                                                             : projectDetails?.salesLostApproval?.smApproval === 'Approved' ? 'green' : 'inherit'
                                                     }}> Sales Manager <FaArrowRight /> </h5>
 
                                                    <h5 style={{
                                                        color: projectDetails?.salesLostApproval?.dgmSalesApproval === 'Sent for Approval' ? 'red'
                                                         : projectDetails?.salesLostApproval?.dgmSalesApproval === 'Approved' ? 'green' : 'inherit'
                                                     }}> DGM Sales <FaArrowRight /> </h5>

                                                    <h5 style={{
                                                        color: projectDetails?.salesLostApproval?.centerHeadApproval === 'Sent for Approval' ? 'red'
                                                         : projectDetails?.salesLostApproval?.centerHeadApproval === 'Approved' ? 'green' : 'inherit'
                                                     }}> Center Head </h5>
                                                    
                                                 </div>
                                                }
                                                {data.designDiscussionPdf &&   
                                                    <DownloadOutlined
                                                        onClick={() => window.open(data.designDiscussionPdf)}
                                                        style={{
                                                            color: "green",
                                                            cursor: "pointer",
                                                            fontWeight: "bold",
                                                            fontSize: '24px',
                                                        }}
                                                    />}
                                            </Typography>
                                        </AccordionDetails>

                                        {/* <Divider /> */}
                                        {
                                            (data?.leadId?.designStages === 'Design Sign-off' &&
                                                data?.leadId.contractFinanceApproved &&
                                                data?.leadId.contractCustomerApproved &&
                                                data?.quotationChangesActivity
                                            ) &&
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                                {data?.QtChangesPaymentReceipt?.length > 0 && (<span style={{ fontWeight: 'bold', marginLeft: '12px' }}>Uploaded Payment Receipt:</span>)}                                               
                                                     {data?.QtChangesPaymentReceipt?.map((url, index) => (
                                                        <div key={index} style={{ marginRight: '10px' }}>
                                                            <DownloadOutlined
                                                                onClick={() => window.open(url)}
                                                                style={{
                                                                    color: "green",
                                                                    cursor: "pointer",
                                                                    fontWeight: "bold",
                                                                    fontSize: '24px',
                                                                }}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                                { data.approvalSeqVisibility ?
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px' }}>
                                                    <h5
                                                        style={{
                                                            color: projectDetails?.sentForQuotationChangeApproval?.DesignManagerApproval === 'Sent for Approval' ? 'red'
                                                                : projectDetails?.sentForQuotationChangeApproval?.DesignManagerApproval === 'Approved' ? 'green' : 'inherit'
                                                        }}> DM/ADM <FaArrowRight />
                                                    </h5>

                                                    <h5 style={{
                                                        color: projectDetails?.sentForQuotationChangeApproval?.OpsDesignApproval === 'Sent for Approval' ? 'red'
                                                            : projectDetails?.sentForQuotationChangeApproval?.OpsDesignApproval === 'Approved' ? 'green' : 'inherit'
                                                    }}> Operation DM <FaArrowRight /></h5>

                                                    {data?.allPositiveValues !== true ?
                                                        (
                                                            <h5 style={{
                                                                color: projectDetails?.sentForQuotationChangeApproval?.DgmOpsApproval === 'Sent for Approval' ? 'red'
                                                                    : projectDetails?.sentForQuotationChangeApproval?.DgmOpsApproval === 'Approved' ? 'green' : 'inherit'
                                                            }}> DGM Ops <FaArrowRight /></h5>
                                                        ) : null}

                                                    <h5 style={{
                                                        color: projectDetails?.sentForQuotationChangeApproval?.FinanceManagerApproval === 'Sent for Approval' ? 'red'
                                                            : projectDetails?.sentForQuotationChangeApproval?.FinanceManagerApproval === 'Approved' ? 'green' : 'inherit'
                                                    }}> Finance Manager 
                                                     <FaArrowRight style={{visibility : (data?.valuesGreaterThan20k === true ||
                                                        data?.delayCategoryCheck === true)  ? "visible" :'hidden' }} />
                                                    </h5>

                                                    {data?.valuesGreaterThan20k === true ||
                                                       data?.delayCategoryCheck === true ? (
                                                        <h5 style={{
                                                            color: projectDetails?.sentForQuotationChangeApproval?.OperationHeadApproval === 'Sent for Approval' ? 'red'
                                                                : projectDetails?.sentForQuotationChangeApproval?.OperationHeadApproval === 'Approved' ? 'green' : 'inherit'
                                                        }}> Director Approval </h5>
                                                    ) : null}
                                                </div> 
                                                : null}
                                            </div>
                                        }
                                        <Divider />
                                        {/* <Divider /> */}
                                        <AccordionActions style={{ display: "block" }}>
                                            <InfoIcon style={{ cursor: 'pointer' }} onClick={() => showInfo(i, 'dealActivity')} />
                                            {
                                                data.s3Location.length !== 0 &&
                                                <AttachmentIcon style={{ cursor: 'pointer' }} onClick={() => downloadNotesFile(data)} />
                                            }
                                        </AccordionActions>
                                    </Accordion >
                                }
                                {
                                    data?.notes && <Accordion defaultExpanded className="accordian">
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography className={classes.heading}>
                                                <EditTwoToneIcon />
                                                <span className="titlename">Notes</span>
                                                <span className="datedata">{moment(data?.createdAt).format('lll')}</span>
                                            </Typography>
                                        </AccordionSummary>
                                        <Divider />
                                        <AccordionDetails>
                                            <Typography variant="body2" gutterBottom>
                                                {data?.notes} <br /> <span style={{ fontSize: "12px" }}>{data?.createdBy?.name} has left a note</span>
                                            </Typography>
                                        </AccordionDetails>
                                        <Divider />
                                        <AccordionActions style={{ display: "block" }}>
                                            <InfoIcon style={{ cursor: 'pointer' }} onClick={() => showInfo(i, 'notes')} />
                                            {data.s3Location[0] !== '' &&
                                                <AttachmentIcon style={{ cursor: 'pointer' }} onClick={() => downloadNotesFile(data)} />
                                            }
                                            {/* {data.s3Location.length !== 0 && <AttachmentIcon onClick={() => window.open(data.s3Location[0])} />} */}
                                        </AccordionActions>
                                    </Accordion>
                                }
                            </Paper>
                        ))}
                    </Grid>
                </Grid>
            </div>
            {/* Create Notes Modal */}
            <Dialog open={openNotesModal} onClose={() => setOpenNotesModal(false)} aria-labelledby="form-dialog-title" maxWidth='sm'>
                <DialogTitle id="form-dialog-title">Create Notes</DialogTitle>
                <Divider />
                <DialogContent>
                    <div style={{ paddingBottom: 15 }}>
                        <div className="editor">
                            <Editor
                                editorKey={'editor'}
                                editorState={editorState}
                                onChange={setEditorStateData}
                                plugins={plugins}
                                ref={editorRef}
                            />
                            <MentionSuggestions
                                open={mentionOpen}
                                onOpenChange={onOpenChange}
                                suggestions={suggestions}
                                onSearchChange={onSearchChange}
                                onAddMention={onAddMention}
                                onClose={() => { }}
                            />
                        </div>

                    </div>
                    <div style={{ paddingBottom: 15 }}>
                        <TextField
                            id="start_date"
                            label="Set Reminder"
                            name="reminderDate"
                            onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}
                            type="datetime-local"
                            className={classes.textFieldWidth}
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </div>
                    <div style={{ paddingBottom: 15 }}>
                        <input
                            accept="*"
                            ref={createNoteFile}
                            className={classes.input}
                            id="project-document"
                            onChange={uploadCreateNoteImg}
                            type="file"
                        />
                        <label htmlFor="project-document">
                            {
                                fileUploaded && <CheckCircleIcon />
                            }
                        </label>
                    </div>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={() => setOpenNotesModal(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={saveNotes} color="primary">
                        Save Note
                    </Button>
                </DialogActions>
            </Dialog>

            {/* DesignKickOff Screenshots */}
            <Dialog open={openDesignKickOffScreenshotsModal} onClose={() => setOpenDesignKickOffScreenshotsModal(false)} aria-labelledby="form-dialog-title" maxWidth='sm'>
                <DialogTitle id="form-dialog-title">Click to Download</DialogTitle>
                <Divider />
                <DialogContent>

                    <Box sx={{ minWidth: 550 }}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="custom pagination table">
                                <TableBody>
                                    {
                                        projectDetails?.designKickOffCustomerFileLinks?.screenshShotsLinks.map((el, index) => {
                                            return (
                                                <TableRow>
                                                    <TableCell style={{ textAlign: 'center' }}>ScreenShot {index + 1}</TableCell>
                                                    <TableCell style={{ textAlign: 'center' }}><DownloadOutlined onClick={() => window.open(el)} /></TableCell>
                                                </TableRow>)
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>

                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={() => setOpenDesignKickOffScreenshotsModal(false)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Assign SiteQc Model */}
            <Dialog open={openAssignSiteQcModel} onClose={() => setOpenAssignSiteQcModel(false)}>
                <DialogTitle style={{ textAlign: 'center' }}>You can assign below User's for this Project</DialogTitle>
                <DialogContent>
                    <Box sx={{ minWidth: 450 }}>
                        <FormControl fullWidth variant="outlined" size="small">
                            <Autocomplete
                                value={selectedSiteQcUserData}
                                onChange={(e, v) => setSelectedSiteQcUserData(v)}
                                options={allSiteQc}
                                getOptionLabel={(option) => option.name}
                                style={{ width: 500, marginBottom: 10, marginLeft: 25 }}
                                renderInput={(params) => <TextField {...params} label="Select Site QC" fullWidth variant="outlined" />}
                            />
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions >
                    <Button onClick={() => setOpenAssignSiteQcModel(false)} variant="outlined" color="primary">Cancel</Button>

                    <Button variant="contained" color="primary" onClick={handleAssignSiteQc}>Submit</Button>
                </DialogActions>
            </Dialog>

            {/* Pop up while generating link */}
            {/* <Dialog 
                open={openGenerateLinkPopUp} 
                onClose={() => setOpenGenerateLinkPopUp(false)} 
                maxWidth="md" 
            >
            <DialogTitle style={{ textAlign: 'center' }}>Are You Sure You Want Submit ?</DialogTitle>
                <DialogContent>
            </DialogContent>
            <DialogActions >
            <Button onClick={() => setOpenGenerateLinkPopUp(false)} variant="outlined" color="primary">Cancel</Button>
            <Button variant="contained" color="primary" onClick={handleRequestLink}>Submit</Button>
            </DialogActions>
            </Dialog> */}

            {/* Edit ERP Project No Model */}
            <Dialog open={openErpProjectNoModal} onClose={() => setOpenErpProjectNoModal(false)}>
                <DialogTitle>Enter Detail</DialogTitle>
                <DialogContent>
                    <Box sx={{ minWidth: 450 }}>
                        <FormControl fullWidth variant="outlined" size="small">
                            <TextField label="New ERP Project No." size="small" value={newErpProjectNo} style={{ marginBottom: 20 }} name="floorPlan" onChange={e => setNewErpProjectNo(e.target.value.toUpperCase())} variant="outlined" />
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenErpProjectNoModal(false)} variant="outlined" color="primary">Cancel</Button>

                    <Button variant="contained" color="primary" size="small" onClick={handleChangeProjectNo}>Change</Button>

                </DialogActions>
            </Dialog>

            {/* //design sign off  */}
            <Dialog open={openDesignSignOff} onClose={() => setOpenDesignSignOffModal(false)}>
                <DialogTitle>Enter Date</DialogTitle>
                <DialogContent>
                    <Box sx={{ minWidth: 450 }}>
                        <FormControl fullWidth variant="outlined" size="small">
                            <TextField type="date" size="small" value={designSignOff} style={{ marginBottom: 20 }} name="floorPlan" onChange={e => setDesignSignOff(e.target.value)} variant="outlined" />
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDesignSignOffModal(false)} variant="outlined" color="primary">Cancel</Button>

                    <Button variant="contained" color="primary" size="medium" onClick={handleDesignSignOffDate}>Change</Button>
                </DialogActions>
            </Dialog>

            {/* expectedSalesclosureDate dialog box */}
            <Dialog open={openEnterSalesClosureDate} onClose={() => setOpenEnterSalesClosureDateModal(false)}>
                <DialogTitle>Enter Date</DialogTitle>
                <DialogContent>
                    <Box sx={{ minWidth: 450 }}>
                        <FormControl fullWidth variant="outlined" size="small">
                            <TextField type="date" size="small" value={expectedSalesclosureDate} style={{ marginBottom: 20 }} name="expectedsalesclosuredate" onChange={salesClosureDateHandler} variant="outlined" />
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenEnterSalesClosureDateModal(false)} variant="outlined" color="primary">Cancel</Button>
                    <Button variant="contained" color="customColor" size="medium" onClick={handleEnterSalesClosureDate}>Change</Button>
                </DialogActions>
            </Dialog>

            {/* Show Notes and Deal Activity Info Modal */}
            <Dialog open={openNotesInfoModal} onClose={() => setOpenNotesInfoModal(false)} aria-labelledby="form-dialog-title" fullWidth maxWidth='sm'>
                <DialogTitle id="form-dialog-title">
                    {(stageInfo === 'Quotation Sent' && openActivity === 'dealActivity') && <>Quotation Sent</>}
                    {(stageInfo === 'Pre - Sales call' && openActivity === 'dealActivity') && <>Pre-sales Call</>}
                    {(stageInfo === 'Sales Call' && openActivity === 'dealActivity') && <>Sales Call</>}
                    {(stageInfo === 'Negotiation' && openActivity === 'dealActivity') && <>Negotiation</>}
                    {(stageInfo === 'Won' && openActivity === 'dealActivity') && <>Won</>}
                    {(stageInfo === 'Site/Office visit' && openActivity === 'dealActivity') && <>Site/Office visit</>}
                    {openActivity === 'notes' && <>Notes</>}
                    {(stageInfo !== 'Pre - Sales call' && stageInfo !== 'Sales Call' && stageInfo !== 'Quotation Sent' && stageInfo !== 'Site/Office visit' && stageInfo !== 'Negotiation' && stageInfo !== 'Won' && openActivity === 'dealActivity') && <>Deal Activity</>}
                </DialogTitle>
                <Divider />
                <DialogContent>
                    {projectLogs[stageIndex]?.stage === "Quotation Sent" &&
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="customized table">
                                <TableBody>
                                    <TableRow key='1'>
                                        <TableCell>Quotation sent Date</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['quotationSentDate'] != null ? moment(projectLogs[stageIndex]?.['quotationSentDate']).format('DD-MM-YYYY') : "NA"}</TableCell>
                                    </TableRow>
                                    <TableRow key='2'>
                                        <TableCell>MOM</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['momQuotationStage']}</TableCell>
                                    </TableRow>
                                    <TableRow key='3'>
                                        <TableCell>Next Follow update</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['nextFollowUpDate'] != null ? moment(projectLogs[stageIndex]?.['nextFollowUpDate']).format('DD-MM-YYYY') : "NA"}</TableCell>
                                    </TableRow>
                                    <TableRow key='3'>
                                        <TableCell>Assigned To</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['user']?.['name']}</TableCell>
                                    </TableRow>
                                    <TableRow key='4'>
                                        <TableCell>Department</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['leadId']?.['departmentId']?.['name']}</TableCell>
                                    </TableRow>
                                    <TableRow key='5'>
                                        <TableCell>Team</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['leadId']?.['teamId']?.['name']}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                    {projectLogs[stageIndex]?.stage === "Pre - Sales call" &&
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="customized table">
                                <TableBody>
                                    <TableRow key='1'>
                                        <TableCell>Pre-sales Call Date</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['preSalesCallDate'] != null ? moment(projectLogs[stageIndex]?.['preSalesCallDate']).format('DD-MM-YYYY') : "NA"}</TableCell>
                                    </TableRow>
                                    <TableRow key='3'>
                                        <TableCell>Next Follow update</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['nextFollowUpDate'] != null ? moment(projectLogs[stageIndex]?.['nextFollowUpDate']).format('DD-MM-YYYY') : "NA"}</TableCell>
                                    </TableRow>
                                    <TableRow key='3'>
                                        <TableCell>Assigned To</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['user']?.['name']}</TableCell>
                                    </TableRow>
                                    <TableRow key='4'>
                                        <TableCell>Department</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['leadId']?.['departmentId']?.['name']}</TableCell>
                                    </TableRow>
                                    <TableRow key='5'>
                                        <TableCell>Team</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['leadId']?.['teamId']?.['name']}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                    {projectLogs[stageIndex]?.stage === "Sales Call" &&
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="customized table">
                                <TableBody>
                                    <TableRow key='1'>
                                        <TableCell>Sales Call Date</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['salesCallDate'] != null ? moment(projectLogs[stageIndex]?.['salesCallDate']).format('DD-MM-YYYY') : "NA"}</TableCell>
                                    </TableRow>
                                    <TableRow key='2'>
                                        <TableCell>Requirements</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['requirements']}</TableCell>
                                    </TableRow>
                                    <TableRow key='3'>
                                        <TableCell>Estimated Budget</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['estimatedBudget']}</TableCell>
                                    </TableRow>
                                    <TableRow key='4'>
                                        <TableCell>Sales Call Comment</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['salesCallComment']}</TableCell>
                                    </TableRow>
                                    <TableRow key='5'>
                                        <TableCell>Next Follow update</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['nextFollowUpDate'] != null ? moment(projectLogs[stageIndex]?.['nextFollowUpDate']).format('DD-MM-YYYY') : "NA"}</TableCell>
                                    </TableRow>
                                    <TableRow key='6'>
                                        <TableCell>Assigned To</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['user']?.['name']}</TableCell>
                                    </TableRow>
                                    <TableRow key='4'>
                                        <TableCell>Department</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['leadId']?.['departmentId']?.['name']}</TableCell>
                                    </TableRow>
                                    <TableRow key='5'>
                                        <TableCell>Team</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['leadId']?.['teamId']?.['name']}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                    {projectLogs[stageIndex]?.stage === "Negotiation" &&
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="customized table">
                                <TableBody>
                                    <TableRow key='1'>
                                        <TableCell>Negotiation Date</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['negotiationDate'] != null ? moment(projectLogs[stageIndex]?.['negotiationDate']).format('DD-MM-YYYY') : "NA"}</TableCell>
                                    </TableRow>
                                    <TableRow key='2'>
                                        <TableCell>MOM Negotitation Stage</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['momNegotitationStage']}</TableCell>
                                    </TableRow>
                                    <TableRow key='5'>
                                        <TableCell>Next Follow update</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['nextFollowUpDate'] != null ? moment(projectLogs[stageIndex]?.['nextFollowUpDate']).format('DD-MM-YYYY') : "NA"}</TableCell>
                                    </TableRow>
                                    <TableRow key='6'>
                                        <TableCell>Assigned To</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['user']?.['name']}</TableCell>
                                    </TableRow>
                                    <TableRow key='7'>
                                        <TableCell>Department</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['leadId']?.['departmentId']?.['name']}</TableCell>
                                    </TableRow>
                                    <TableRow key='8'>
                                        <TableCell>Team</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['leadId']?.['teamId']?.['name']}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                    {projectLogs[stageIndex]?.['Won'] &&
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="customized table">
                                <TableBody>
                                    <TableRow key='1'>
                                        <TableCell>Won Date</TableCell>
                                        <TableCell>{moment(projectLogs[stageIndex]?.['closureDate']).format('DD-MM-YYYY')}</TableCell>
                                    </TableRow>
                                    <TableRow key='2'>
                                        <TableCell>Remarks</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['remark']}</TableCell>
                                    </TableRow>
                                    <TableRow key='3'>
                                        <TableCell>Payment Plan</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['paymentPlan']}</TableCell>
                                    </TableRow>
                                    <TableRow key='4'>
                                        <TableCell>Final Quote Attached</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['finalQuoteAttached'] === true ? `Yes` : `No`}</TableCell>
                                    </TableRow>
                                    <TableRow key='5'>
                                        <TableCell>Client Move in Date</TableCell>
                                        <TableCell>{moment(projectLogs[stageIndex]?.['clientMoveinDate']).format('DD-MM-YYYY')}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                    {projectLogs[stageIndex]?.stage === "Site/Office visit" &&
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="customized table">
                                <TableBody>
                                    <TableRow key='1'>
                                        <TableCell>Site/Visit Date</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['siteVisitDate'] != null ? moment(projectLogs[stageIndex]?.['siteVisitDate']).format('DD-MM-YYYY') : "NA"}</TableCell>
                                    </TableRow>
                                    <TableRow key='2'>
                                        <TableCell>MOM Meeting Stage</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['momMeetingStage']}</TableCell>
                                    </TableRow>
                                    <TableRow key='3'>
                                        <TableCell>MOM Site Visit Stage</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['momSiteVisitStage']}</TableCell>
                                    </TableRow>
                                    <TableRow key='5'>
                                        <TableCell>Next Follow update</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['nextFollowUpDate'] != null ? moment(projectLogs[stageIndex]?.['nextFollowUpDate']).format('DD-MM-YYYY') : "NA"}</TableCell>
                                    </TableRow>
                                    <TableRow key='6'>
                                        <TableCell>Assigned To</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['user']?.['name']}</TableCell>
                                    </TableRow>
                                    <TableRow key='4'>
                                        <TableCell>Department</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['leadId']?.['departmentId']?.['name']}</TableCell>
                                    </TableRow>
                                    <TableRow key='5'>
                                        <TableCell>Team</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['leadId']?.['teamId']?.['name']}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }

                    {
                        (
                            stageInfo !== 'Pre - Sales call' &&
                            stageInfo !== 'Sales Call' &&
                            stageInfo !== 'Negotiation' &&
                            stageInfo !== 'Quotation Sent' &&
                            stageInfo !== 'Site/Office visit' &&
                            stageInfo !== 'Won' &&
                            !projectLogs[stageIndex]?.['notes']
                        )
                            ?
                            <TableContainer component={Paper}>
                                <Table className={classes.table} size="small" aria-label="customized table">
                                    <TableBody>
                                        <TableRow key='1'>
                                            <TableCell>Assigned To</TableCell>
                                            <TableCell>{projectLogs[stageIndex]?.['user']?.['name']}</TableCell>
                                        </TableRow>
                                        <TableRow key='4'>
                                            <TableCell>Department</TableCell>
                                            <TableCell>{projectLogs[stageIndex]?.['leadId']?.['departmentId']?.['name']}</TableCell>
                                        </TableRow>
                                        <TableRow key='5'>
                                            <TableCell>Team</TableCell>
                                            <TableCell>{projectLogs[stageIndex]?.['leadId']?.['teamId']?.['name']}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            :
                            <TableContainer component={Paper}>
                                <Table className={classes.table} size="small" aria-label="customized table">
                                    <TableBody>
                                        {
                                            projectLogs[stageIndex]?.['notes'] &&

                                            <TableRow key='2'>
                                                <TableCell>Notes</TableCell>
                                                <TableCell>{projectLogs[stageIndex]?.['notes']}</TableCell>
                                            </TableRow>
                                        }
                                        {
                                            projectLogs[stageIndex]?.['s3Location'].length !== 0 &&

                                            <TableRow key='3'>
                                                <TableCell>Attachments</TableCell>
                                                {projectLogs[stageIndex]?.['s3Location']?.map((data, i) => (
                                                    <TableCell key={i + 1}>{data}</TableCell>
                                                ))}
                                            </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                    }
                    {/* {
                        (
                            projectLogs[stageIndex]?.['quotationSentDate'] === null ||
                            projectLogs[stageIndex]?.['preSalesCallDate'] === null ||
                            projectLogs[stageIndex]?.['salesCallDate'] === null ||
                            projectLogs[stageIndex]?.['negotiationDate'] === null ||
                            projectLogs[stageIndex]?.['siteVisitDate'] === null
                        )
                        &&
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="customized table">
                                <TableBody>
                                    <TableRow key='1'>
                                        <TableCell>Assigned To</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['user']?.['name']}</TableCell>
                                    </TableRow>
                                    <TableRow key='4'>
                                        <TableCell>Department</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['leadId']?.['departmentId']?.['name']}</TableCell>
                                    </TableRow>
                                    <TableRow key='5'>
                                        <TableCell>Team</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['leadId']?.['teamId']?.['name']}</TableCell>
                                    </TableRow>
                                    <TableRow key='2'>
                                        <TableCell>Notes</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['notes']}</TableCell>
                                    </TableRow>
                                    <TableRow key='3'>
                                        <TableCell>Attachments</TableCell>
                                        {projectLogs[stageIndex]?.['s3Location']?.map((data, i) => (
                                            <TableCell key={i + 1}>{data}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    } */}
                </DialogContent>
            </Dialog>

            {/*Start - customer payment information */}
            {/* <Dialog open={paymentDetailsModal} onClose={() => setPaymentDetailsModal(false)} aria-labelledby="form-dialog-title" fullWidth maxWidth='lg' style={{padding:"10rem 10rem 10rem 10rem"}}>
                <DialogTitle id="form-dialog-title" style={{ textAlign: "center" }}>
                    All Payments Details
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Table className={classes.table} size="small" aria-label="customized table">
                            <StyledTableRow>
                                {tableHeading.map((heading) => (
                                    <StyledTableCell key={heading} component="th" scope="row">{heading}</StyledTableCell>
                                ))}
                          </StyledTableRow>
                          <TableBody>
                            {showPaymentPreview.map((row, i) => {
                                return (
                                    <StyledTableRow key={i + 1}>
                                        <StyledTableCell>{i + 1}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">{row?.uniqueLeadNumber}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">{row?.customerName}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">{row?.createdAt}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">{row?.transactionNumber}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">{row?.Amount}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">{row?.paymentMode}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">{row?.departmentName}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">{row?.createdByUser}</StyledTableCell>
                                        <TableCell component="th" scope="row">{row?.Status === 'successful' ? 'Successful' : row?.Status ==='cancel' ? 'Cancel' :""}</TableCell>
                                        <StyledTableCell component="th" scope="row">{row?.Stage}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">{row?.paymentPartnerName}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">{row?.financeActionStatus}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">{row?.financeActionRemarks}</StyledTableCell>
                                                                                                         
                                    </StyledTableRow>
                                );
                            })}
                        </TableBody>
                        </Table>
                    </DialogContentText>
                </DialogContent>
            </Dialog> */}

            {/* Genrate Payment Model */}
            <Dialog open={openPayment} onClose={handleClose} fullWidth maxWidth="sm">
            <DialogContent style={{ backgroundColor: "#222", color: "white", padding: '20px', minWidth: '400px', position: 'relative' }}>
                <IconButton onClick={handleClose} style={{ color: "white", position: 'absolute', top: '10px', right: '10px', zIndex: '1000' }}>
                    <CloseIcon />
                </IconButton>
                <TextField
                    value={paymentLink}
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputProps={{
                        readOnly: true,
                        style: { color: "white", paddingRight: '40px' },
                        endAdornment: (
                            <IconButton onClick={handleCopyLink} style={{ color: "white", position: 'absolute', right: '5px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer', zIndex: '1000' }}>
                                {copied ? <span>Copied</span> : <FileCopyIcon />}
                            </IconButton>
                        )
                    }}
                    style={{ width: 'calc(100% - 40px)', margin: '0' }}
                />
            </DialogContent>
            <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
            >
            <SnackbarContent
            message={
                <span style={{ fontSize: '14px' }}>
                {copied ? "Copied!" : "Copy failed!"}
            </span>
        }
    />
</Snackbar>
        </Dialog>
            <Dialog open={paymentDetailsModal} onClose={() => setPaymentDetailsModal(false)} aria-labelledby="form-dialog-title" fullWidth maxWidth='lg' style={{ padding: "10rem 10rem 10rem 10rem" }}>
                <DialogTitle id="form-dialog-title" style={{ textAlign: "center" }}>
                    Payments Logs
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Table className={classes.table} size="small" aria-label="customized table">
                            {/* <StyledTableRow>
                                {tableHeading.map((heading) => (
                                    <StyledTableCell key={heading} component="th" scope="row">{heading}</StyledTableCell>
                                ))}
                            </StyledTableRow> */}
                            <StyledTableRow>
                                <TableCell style={{ fontWeight: "800", textAlign: "center" }}>S.No</TableCell>
                                <TableCell style={{ fontWeight: "800", textAlign: "center" }}>Account Number</TableCell>
                                <TableCell style={{ fontWeight: "800", textAlign: "center" }}>Amount Requested</TableCell>
                                <TableCell style={{ fontWeight: "800", textAlign: "center" }}>Amount Received Automated Ping</TableCell>
                                <TableCell style={{ fontWeight: "800", textAlign: "center" }}>Amount Received Manual Approved</TableCell>
                                <TableCell style={{ fontWeight: "800", textAlign: "center" }}>Total Amount Received</TableCell>
                                <TableCell style={{ fontWeight: "800", textAlign: "center" }}>Requested amount -  Total Amount Received</TableCell>
                            </StyledTableRow>
                            <TableBody>
                                {customerInformation.map((row, i) => {
                                    return (
                                        // <StyledTableRow key={i + 1}>
                                        //     <StyledTableCell>{i + 1}</StyledTableCell>
                                        //     <StyledTableCell component="th" scope="row">{row?.uniqueLeadNumber}</StyledTableCell>
                                        //     <StyledTableCell component="th" scope="row">{row?.customerName}</StyledTableCell>
                                        //     <StyledTableCell component="th" scope="row">{row?.createdAt}</StyledTableCell>
                                        //     <StyledTableCell component="th" scope="row">{row?.transactionNumber}</StyledTableCell>
                                        //     <StyledTableCell component="th" scope="row">{row?.Amount}</StyledTableCell>
                                        //     <StyledTableCell component="th" scope="row">{row?.paymentMode}</StyledTableCell>
                                        //     <StyledTableCell component="th" scope="row">{row?.departmentName}</StyledTableCell>
                                        //     <StyledTableCell component="th" scope="row">{row?.createdByUser}</StyledTableCell>
                                        //     <TableCell component="th" scope="row">{row?.Status === 'successful' ? 'Successful' : row?.Status ==='cancel' ? 'Cancel' :""}</TableCell>
                                        //     <StyledTableCell component="th" scope="row">{row?.Stage}</StyledTableCell>
                                        //     <StyledTableCell component="th" scope="row">{row?.paymentPartnerName}</StyledTableCell>
                                        //     <StyledTableCell component="th" scope="row">{row?.financeActionStatus}</StyledTableCell>
                                        //     <StyledTableCell component="th" scope="row">{row?.financeActionRemarks}</StyledTableCell>

                                        // </StyledTableRow>
                                        <TableRow key={i + 1}>

                                            <TableCell style={{ textAlign: "center" }}>{i + 1}</TableCell>
                                            <TableCell style={{ textAlign: "center" }}>{row.account_number} <PlusCircleOutlined onClick={() => handleViewLogsClick(row)} /></TableCell>
                                            <TableCell style={{ textAlign: "center" }}>{row.amount_requested}</TableCell>
                                            <TableCell style={{ textAlign: "center" }}>{row.amount_received_auto_ping}</TableCell>
                                            <TableCell style={{ textAlign: "center" }}>{row.amount_received_manual_ping}</TableCell>
                                            <TableCell style={{ textAlign: "center" }}>{row.amount_received_auto_ping + row.amount_received_manual_ping}</TableCell>
                                            <TableCell style={{ textAlign: "center" }}>{row.amount_requested - (row.amount_received_auto_ping + row.amount_received_manual_ping)}</TableCell>

                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            {/*End - customer payment information*/}

            {/* start - customer payment logs */}

            <Dialog open={showPaymentLogs} onClose={() => { setShowPaymentLogs(false); setPaymentLogsData([]) }} aria-labelledby="form-dialog-title" fullWidth maxWidth='lg' style={{ padding: "10rem 10rem 10rem 10rem" }}>
                <DialogTitle id="form-dialog-title" style={{ textAlign: "center" }}>
                    Payment Logs
                </DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        <Table className={classes.table} size="small" aria-label="customized table">
                            <StyledTableRow>
                                <StyledTableCell style={{ textAlign: "center" }} component="th" scope="row">S.No.</StyledTableCell>
                                <StyledTableCell style={{ textAlign: "center" }} component="th" scope="row">Amount Received / Requested</StyledTableCell>
                                <StyledTableCell style={{ textAlign: "center" }} component="th" scope="row">Requested By / Received Mode</StyledTableCell>
                                <StyledTableCell style={{ textAlign: "center" }} component="th" scope="row">Amount</StyledTableCell>
                                <StyledTableCell style={{ textAlign: "center" }} component="th" scope="row">Date</StyledTableCell>
                                <StyledTableCell style={{ textAlign: "center" }} component="th" scope="row">Receipts</StyledTableCell>

                            </StyledTableRow>
                            <TableBody>
                                {paymentLogsData.map((row, i) => {
                                    return (
                                        <StyledTableRow key={i + 1}>
                                            <StyledTableCell>{i + 1}</StyledTableCell>
                                            {/* <StyledTableCell style={{textAlign:"center" }} component="td" scope="row">{Amount_Received.includes(row?.Status) ? 'Amount Received' : 'Amount Requested'}</StyledTableCell> */}
                                            <StyledTableCell style={{ textAlign: "center" }} component="td" scope="row">{row?.Status}</StyledTableCell>
                                            <StyledTableCell style={{ textAlign: "center" }} component="td" scope="row">{row?.Status == 'Payment Requested' ? row?.PaymentUser?.name : row?.paymentMode}</StyledTableCell>
                                            <StyledTableCell style={{ textAlign: "center" }} component="td" scope="row">{row?.amount}</StyledTableCell>
                                            {/* <StyledTableCell style={{textAlign:"center" }} component="td" scope="row">{moment(row?.createdAt).format('DD-MM-YYYY')}</StyledTableCell> */}
                                            <StyledTableCell style={{ textAlign: "center" }} component="td" scope="row">{moment(row?.paymentDate).format('DD-MM-YYYY')}</StyledTableCell>
                                            {/* <StyledTableCell component="th" scope="row" >{}<DownloadOutlined  onClick={() => window.open(row?.paymentTransactionId?.attachment[0])} /></StyledTableCell> */}
                                            <StyledTableCell style={{ textAlign: "center" }} component="th" scope="row" >
                                                {/* <Button variant="outlined" className={classes.downloadButton} onClick={() => window.open(row?.paymentTransactionId?.attachment[0])}><DownloadOutlined /></Button> */}
                                                {/* <Button variant="outlined" className={classes.downloadButton} onClick={() => window.open(row?.paymentTransactionId?.financeReceipt)}><DownloadOutlined /></Button> */}
                                                {row?.Status == 'Payment Requested' ? 'NA' : <Button variant="outlined" className={classes.downloadButton} onClick={() => window.open(row?.paymentTransactionId?.financeReceipt)}><DownloadOutlined /></Button>}
                                            </StyledTableCell>




                                        </StyledTableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            {/* end - customer payment logs */}

            {/* Edit Project Modal */}
            <Dialog open={editProjectModal} onClose={() => setEditProjectModal(false)} aria-labelledby="form-dialog-title" fullWidth maxWidth='sm'>
                <DialogTitle id="form-dialog-title">
                    Edit Project
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description" style={{ display: "flex", flexDirection: "column" }}>
                        {/* <FormControl fullWidth variant="outlined" size="small" style={{ marginBottom: 20, marginTop: 20 }}>
                            <InputLabel id="demo-simple-select-label">Select Ply Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={plyType}
                                label="Select Ply Type"
                                onChange={changeHandler}
                                variant="outlined"
                            >
                                {plyTypeMaster.map((row, i) => {
                                    return (
                                        <MenuItem key={i + 1} value={row._id}>{row.name}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl> */}
                        <TextField label="Customer Name" size="small" value={name} style={{ marginBottom: 20, marginTop: 20 }} name="name" onChange={customerNameInputHandler} variant="outlined" />
                        <TextField label="Customer Email Id" size="small" value={email} style={{ marginBottom: 20 }} name="email" onChange={changeHandler} variant="outlined" />
                        <TextField label="Address" size="small" value={address} style={{ marginBottom: 20 }} name="address" onChange={setAdress} variant="outlined" />
                        <TextField label="Customer Contact No." size="small" value={contact_no} style={{ marginBottom: 20 }} name="contact_no" onChange={phoneNumberInputHandler} variant="outlined" />
                        {
                            projectDetails?.alternativeContactNo ?
                                <>
                                    <TextField
                                        label="Alternate Contact No"
                                        size="small"
                                        value={alternativeContactNo}
                                        style={{ marginBottom: 20 }}
                                        name="alternativeContactNo"
                                        onChange={phoneNumberInputHandler}
                                        variant="outlined"
                                        error={alternativeContactNo?.length !== 10}
                                        helperText={alternativeContactNo?.length !== 10 ? 'Must be 10 characters' : ''}
                                    />
                                </>
                                :
                                <>
                                    <TextField
                                        label="Alternate Contact No"
                                        size="small"
                                        value={alternativeContactNo}
                                        style={{ marginBottom: 20 }}
                                        name="alternativeContactNo"
                                        onChange={phoneNumberInputHandler}
                                        variant="outlined"
                                        error={alternativeContactNo?.length !== 10}
                                        helperText={alternativeContactNo?.length !== 10 ? 'Must be 10 characters' : ''}
                                    />
                                </>
                        }
                        {/* {
                        userDetails.roles[0]['name'] === 'Admin' &&
                        <>
                        <FormControl fullWidth variant="outlined" size="small" style={{ marginBottom: 20 }}>
                            <InputLabel id="demo-simple-select-label">Project Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={projectType}
                                label="Project Type"
                                name="projectType"
                                onChange={changeHandler}
                                variant="outlined"
                            >
                                <MenuItem value='1BHK'>1BHK</MenuItem>
                                <MenuItem value='2BHK'>2BHK</MenuItem>
                                <MenuItem value='3BHK'>3BHK</MenuItem>
                                <MenuItem value='Villa'>Villa</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth variant="outlined" size="small" style={{ marginBottom: 20 }}>
                            <InputLabel id="demo-simple-select-label">Property Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={propertyType}
                                label="Property Type"
                                name="propertyType"
                                onChange={changeHandler}
                                variant="outlined"
                            >
                                <MenuItem value='Residential/New Apartment'>Residential/New Apartment</MenuItem>
                                <MenuItem value='Independent House'>Independent House</MenuItem>
                                <MenuItem value='Commercial'>Commercial</MenuItem>
                                <MenuItem value='Renovation'>Renovation</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth variant="outlined" size="small" style={{ marginBottom: 20 }}>
                            <InputLabel id="demo-simple-select-label">Scope Of Work</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={scopeOfWork}
                                label="Scope Of Work"
                                name="scopeOfWork"
                                onChange={changeHandler}
                                variant="outlined"
                            >
                                <MenuItem value='Kitchens and Wardrobes'>Kitchens and Wardrobes</MenuItem>
                                <MenuItem value='Kitchens and Wardrobes + some other elements(movable+Fixed+Decorative'>Kitchens and Wardrobes + some other elements(movable+Fixed+Decorative</MenuItem>
                                <MenuItem value='End to End implementation(Entire Home)'>End to End implementation(Entire Home)</MenuItem>
                                <MenuItem value='Commercial Requirements'>Commercial Requirements</MenuItem>
                            </Select>
                        </FormControl>
                        {/* <TextField label="Description" size="small" value={description} style={{ marginBottom: 20 }} name="description" onChange={changeHandler} variant="outlined" /> */}
                        {/* <TextField label="Floor Plan" size="small" value={floorPlan} style={{ marginBottom: 20 }} name="floorPlan" onChange={changeHandler} variant="outlined" />
                        <FormControl fullWidth variant="outlined" size="small" style={{ marginBottom: 20 }}>
                            <InputLabel id="demo-simple-select-label">Registration Done</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={isRegistered}
                                label="Registration Done"
                                name="isRegistered"
                                onChange={changeHandler}
                                variant="outlined"
                            >
                                <MenuItem value='Yes'>Yes</MenuItem>
                                <MenuItem value='No'>No</MenuItem>
                                <MenuItem value='Will get it in 15-20 days'>Will get it in 15-20 days</MenuItem>
                            </Select>
                        </FormControl> */}
                        {/* <TextField label="Sq.Ft Area" value={area} size="small" style={{ marginBottom: 20 }} name="area" onChange={sqFtAreaInputHandler} variant="outlined" />
                        <FormControl fullWidth variant="outlined" size="small" style={{ marginBottom: 20 }}>
                            <InputLabel id="demo-simple-select-label">Lead Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={leadType}
                                label="Lead Type"
                                name="leadType"
                                onChange={changeHandler}
                                variant="outlined"
                            >
                                <MenuItem value='Real'>Real</MenuItem>
                                <MenuItem value='Junk'>Junk</MenuItem>
                                <MenuItem value='Hold'>Hold</MenuItem>
                            </Select>
                        </FormControl> */}
                        {/* </> } */}
                        {/* {
                            userDetails.roles[0]['name'] === 'Admin' &&
                            <FormControl fullWidth size="small" variant="outlined" style={{ marginBottom: 20 }}>
                                <InputLabel id="demo-simple-select-label">Apply Discount on All Scopes</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={discountOnAllScopes}
                                    label="Apply Discount on All Scopes"
                                    name="discountOnAllScopes"
                                    onChange={changeHandler}
                                    variant="outlined"
                                >
                                    <MenuItem value='true'>Yes</MenuItem>
                                    <MenuItem value='false'>No</MenuItem>
                                </Select>
                            </FormControl>
                        } */}
                        {/* {
                            (userDetails.roles[0]['name'] === 'Admin' && leadType === 'Real') &&
                            <FormControl fullWidth size="small" variant="outlined" style={{ marginBottom: 20 }}>
                                <InputLabel id="demo-simple-select-label">Assign To</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={assignToManager}
                                    label="Assign To Team"
                                    name="assignToManager"
                                    onChange={(e) => changeHandler(e)}
                                    variant="outlined"
                                >
                                    {salesManagerList.map((row, i) => {
                                        return (
                                            <MenuItem key={i + 1} value={row._id}>{`${row?.teamId?.name} - ${row?.name}`}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        } */}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setEditProjectModal(false)} variant="contained" color="secondary">
                        Cancel
                    </Button>

                    <Button onClick={saveProjectDetail} disabled={contact_no.length > 10 || contact_no.length < 10} variant="contained" color="primary">
                        Save Project Detail
                    </Button>

                </DialogActions>
            </Dialog>


            <Dialog open={openClientModel} onClose={() => setOpenClientModel(false)} aria-labelledby="form-dialog-title" maxWidth='sm'>
                <DialogTitle id="form-dialog-title">Extra days due to client dependency</DialogTitle>
                <Divider />
                <DialogContent>
                    <TextField label="Extra days due to client dependency" type="number" size="small" style={{ marginBottom: 20, textAlign: "center" }} name="extraDaysDueToClientDependency" onChange={(e) => setClientDelay({ [e.target.name]: e.target.value })} variant="outlined" />
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={() => setOpenClientModel(false)} color="secondary" variant="outlined">
                        Cancel
                    </Button>
                    <Button onClick={clientDelayfunc} color="primary" variant="outlined">
                        Update
                    </Button>
                </DialogActions>
            </Dialog>

            {/* floor plan dialog box  */}
            {/* Floor Plan Dialogbox */}
            <Dialog open={fBtnShow} onClose={() => setfBtnShow(false)} aria-labelledby="form-dialog-title" maxWidth='sm'>
                <DialogTitle id="form-dialog-title">Floor Plan Button Dialog</DialogTitle>
                <Divider />
                <DialogContent>
                    <div style={{ paddingBottom: 15, paddingTop: 10 }} variant="outlined" size="small">
                        <input
                            accept=".pdf"
                            id="floorplan-document"
                            onChange={(e) => uploadWDFileDocument(e, 'FloorPlanFile')}
                            type="file"
                            style={{ display: 'none' }}
                        />
                        <label htmlFor="floorplan-document">
                            <Button variant="contained" color="primary" style={{ background: "#716ACA", margin: 5 }} component="span">
                                Upload FloorPlan
                            </Button>
                        </label>
                        {checkForFloor && <CheckCircleIcon style={{ color: "green", marginTop: "2rem", marginLeft: "2rem", marginRight: "1rem" }} />}

                    </div>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={() => setfBtnShow(false)} color="secondary" variant="outlined">
                        Cancel
                    </Button>
                    <Button onClick={floorPlanFunc} color="primary" variant="outlined">
                        Upload Floor Plan
                    </Button>
                </DialogActions>
            </Dialog>
            {/*Change CHM user */}
            <Dialog open={openChmUser} onClose={() => setOpenChmUser(false)}>
                <DialogTitle>Change CHM User</DialogTitle>
                <DialogContent>
                    <Box sx={{ minWidth: 450 }}>
                        <FormControl fullWidth>
                            <TextField
                                select
                                labelId="user-select-label"
                                id="user-select"
                                label="Select User"
                                variant="outlined"
                                value={chmUser}
                                onChange={(e) => handleChmUserChange(e)} // Update the selected CHM user
                            >
                                {chmList.map((user) => (
                                    <MenuItem key={user.id} value={user._id}>
                                        {user.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenChmUser(false)} color="primary" variant='outlined'>
                        Cancel
                    </Button>
                    <Button onClick={updateChmUser} color="primary" variant='contained'>
                        Change
                    </Button>
                </DialogActions>
            </Dialog>
            {/* Dialog for select the stage */}
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Change Stages</DialogTitle>
                <DialogContent>
                    <Box sx={{ minWidth: 450 }}>
                        <FormControl fullWidth variant="outlined" size="small">
                            <InputLabel id="select-stage">Select Stage</InputLabel>
                            <Select labelId="select-stage"
                                id="select-stage"
                                value={stage}
                                label="Select Stage"
                                variant="outlined"
                                // onChange={handleSelectedStageChange}
                                onChange={(e) => handleSelectedStageChange(e)}
                            >
                                {departmentStage?.map((row, i) => {
                                     let isActive = true;

                                     if ((projectDetails?.currentStage === 'Design Discussion' && (row === 'Design Discussion' || row === 'Lost')) 
                                        ||
                                        ((projectDetails?.currentStage === 'Drawing QC' || projectDetails?.currentStage === 'Design Sign-off HOLD' || projectDetails.currentStage === 'Design Sign-off') && (row === 'Design Sign-off' || row === 'Design Sign-off HOLD' || row === 'Lost')) 
                                        ||
                                        (i === currentStageIndex || row === 'Lost')) {
                                          isActive = false;
                                      }
                                 
                                     return (
                                        projectDetails?.departmentId?.name === 'Design' && userDetails?.roles?.find(role => role.name === ROLENAME.DesignUser) ? (
                                            <MenuItem key={i + 1} value={row} disabled={isActive}>{row}</MenuItem>
                                        ) : (
                                            <MenuItem key={i + 1} value={row}>{row}</MenuItem>
                                        )
                                    );
                                })}
                            </Select>
                            {
                                stage === "Final Quotation Approval" ?
                                 <Button style={{marginTop:"10px"}} variant="outlined" color="primary" onClick={handleClickOpenDialog}>Select Quotation Want to Share</Button>
                                 :""
                            }
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions>
                <Button onClick={() => setOpen(false)} variant="outlined" color="primary">Cancel</Button>
                    {
                        userDetails?.roles?.find(role => role.name === ROLENAME.DesignUser || role.name === ROLENAME.DesignManager|| role.name === ROLENAME.DesignHead|| role.name === ROLENAME.DGM_Design|| role.name === ROLENAME.AssistantDesignManager|| role.name === ROLENAME.OperationDesignManager) &&
                        stage === 'Design Kick-Off : Customer' ?
                        <Button onClick={openDesignKickOffCustomerDialog} variant="contained" color="primary">Change</Button> :
                        stage !== 'Design Sign-off' && stage !== 'Design Kick-Off : Customer' ?
                        stage === 'Final Quotation Approval' ?
                        <Button onClick={openAnotherDialog} disabled={!showButton} variant="contained" color="primary">Change</Button> :
                        <Button onClick={openAnotherDialog} variant="contained" color="primary">Change</Button>
                        :
                        <Button onClick={openAnotherDialog} variant="contained" color="primary">Change</Button>
                    }


                </DialogActions>
            </Dialog>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                                            <DialogTitle>Quotation Details</DialogTitle>
                                            <DialogContent>
                                            <Table>
                                                <TableBody>
                                                <TableCell style={{ padding: '5px' }} component="th" scope="row">S.No</TableCell>
                                                <TableCell style={{ padding: '5px' }} component="th" scope="row">Version</TableCell>
                                                <TableCell style={{ padding: '5px' }} component="th" scope="row">Created Date</TableCell>
                                                <TableCell style={{ padding: '5px' }} component="th" scope="row">Created By</TableCell>
                                                <TableCell style={{ padding: '5px' }} component="th" scope="row">Total Customer OutFlow</TableCell>
                                                <TableCell style={{ padding: '5px' }} component="th" scope="row">Select Quotation </TableCell>

                                                {storeLatestQuotation.map((quotation, index) => (
                                                    <TableRow key={index}>
                                                    <TableCell style={{ padding: '5px' }} component="th" scope="row">{index+1}</TableCell>
                                                    <TableCell style={{ padding: '5px' }} component="th" scope="row">{quotation.version}</TableCell>
                                                    <TableCell style={{ padding: '5px' }} component="th" scope="row">{moment(quotation.createdAt).format('YYYY-MM-DD')}</TableCell>
                                                    <TableCell style={{ padding: '5px' }} component="th" scope="row">{quotation.createdBy.name}</TableCell>
                                                    <TableCell style={{ padding: '5px' }} component="th" scope="row">{quotation.totalCustomerOutflow}</TableCell>
                                                    <TableCell style={{ padding: '5px' }}>
                                                    <Checkbox checked={storeVersion == quotation.version ? storeVersion == quotation.version : projectDetails.designVersion == quotation.version } onChange={(event) => handleCheckboxChange(event, quotation)} />
                                                    </TableCell>
                                                    </TableRow>
                                                ))}
                                                </TableBody>
                                            </Table>
                                            </DialogContent>
                        </Dialog>

            {/* Dialog for capturing details during stage move to Design Kick-Off : Customer */}
            <Dialog open={designKickOffCustomerDialog} onClose={() => setDesignKickOffCustomerDialog(false)}>
                <DialogTitle>Required Details</DialogTitle>
                <DialogContent>

                    <Box sx={{ minWidth: 450 }}>
                        <FormControl fullWidth style={{ marginBottom: "15px" }} variant="outlined" size="small">
                            <TextField
                                id="salesOwnerMeetingDate"
                                name="salesOwnerMeetingDate"
                                value={designKickOffCustomerForm.salesOwnerMeetingDate}
                                onChange={(e, v) => captureDesignKickOffCustomerDetails(e, v)}
                                label="Meeting Date with Sales Owner"
                                type="date"
                                InputLabelProps={{
                                    shrink: true
                                }}
                                variant="outlined"
                                size="small"
                            />
                        </FormControl>
                       
                        <FormControl fullWidth variant="outlined" size="small" style={{ marginBottom: 20 }}>
                            <InputLabel id="floor-plan-received">Floor Plan Received ?</InputLabel>
                            <Select
                                labelId="floor-plan-received"
                                id="floor-plan-received"
                                value={designKickOffCustomerForm.floorPlanReceived}
                                label="Floor Plan Received ?"
                                name="floorPlanReceived"
                                onChange={(e, v) => captureDesignKickOffCustomerDetails(e, v)}
                                variant="outlined"
                            >
                                <MenuItem value='Yes'>Yes</MenuItem>
                                <MenuItem value='No'>No</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl fullWidth variant="outlined" size="small" style={{ marginBottom: 20 }}>
                            <InputLabel id="quotation-discussed">Quotation Discussed ?</InputLabel>
                            <Select
                                labelId="quotation-discussed"
                                id="quotation-discussed"
                                value={designKickOffCustomerForm.quotationDiscussed}
                                label="quotation discussed ?"
                                name="quotationDiscussed"
                                onChange={(e, v) => captureDesignKickOffCustomerDetails(e, v)}
                                variant="outlined"
                            >
                                <MenuItem value='Yes'>Yes</MenuItem>
                                <MenuItem value='No'>No</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth variant="outlined" size="small" style={{ marginBottom: 20 }}>
                            <InputLabel id="requirement-discussed">Requirement Discussed ?</InputLabel>
                            <Select
                                labelId="requirement-discussed"
                                id="requirement-discussed"
                                value={designKickOffCustomerForm.requirementDiscussed}
                                label="Requirement Discussed ?"
                                name="requirementDiscussed"
                                onChange={(e, v) => captureDesignKickOffCustomerDetails(e, v)}
                                variant="outlined"
                            >
                                <MenuItem value='Yes'>Yes</MenuItem>
                                <MenuItem value='No'>No</MenuItem>
                            </Select>
                        </FormControl>

                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={assignProject} disabled={!designKickOffCustomerForm.salesOwnerMeetingDate || designKickOffCustomerForm.floorPlanReceived != 'Yes' || designKickOffCustomerForm.quotationDiscussed != 'Yes' || designKickOffCustomerForm.requirementDiscussed != 'Yes'} variant="contained" color="primary">Submit</Button>
                    <Button onClick={() => setDesignKickOffCustomerDialog(false)} variant="outlined" color="primary">Cancel</Button>



                </DialogActions>
            </Dialog>

            {/* Dialog for select the details of previous dialog selected stage */}
            <Dialog open={anotherDialog} onClose={() => setOpenAnotherDialog(false)}>
                <DialogTitle>{stage === 'Move to Marketing' ? `Assign to Marketing User` : `Select Details`}</DialogTitle>
                <DialogContent>
                    <Box sx={{ minWidth: 450 }}>
                        {
                            // Move to mrketing
                            stage === 'Move to Marketing' &&
                            `Assigning lead to previous owner ${marketingUser.name}`
                        }
                        {
                            // Pre sales call date
                            stage === 'Pre - Sales call' &&
                            <FormControl fullWidth style={{ marginBottom: "15px" }} variant="outlined" size="small">
                                <TextField
                                    id="preSalesCall-Date"
                                    name={preSalesCallDate}
                                    onChange={(e) => setHandlepreSalesCallDate(e.target.value)}
                                    label="Pre sales call date"
                                    type="date"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    variant="outlined"
                                    size="small"
                                />
                            </FormControl>
                        }
                        {
                            // Next Follow Update Date
                            (stage === 'Pre - Sales call' || stage === 'Sales Call' || stage === 'Quotation Sent' || stage === 'Site/Office visit' || stage === 'Negotiation') &&
                            <FormControl fullWidth style={{ marginBottom: "15px" }} variant="outlined" size="small">
                                <TextField
                                    id="nextFollowUp-Date"
                                    name={nextFollowUpDate}
                                    onChange={(e) => setHandleNextFollowUpDate(e.target.value)}
                                    label="Next Follow Update Date"
                                    type="date"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    variant="outlined"
                                    size="small"
                                />
                            </FormControl>
                        }
                        {
                            // Sales call Data, Requirements, Estimate Budget, Sales call comments
                            stage === 'Sales Call' &&
                            <div>
                                <FormControl fullWidth style={{ marginBottom: "15px" }} variant="outlined" size="small">
                                    <TextField
                                        id="salesCall-Date"
                                        name={salesCallDate}
                                        onChange={(e) => setHandleSalesCallDate(e.target.value)}
                                        label="sales call date"
                                        type="date"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        variant="outlined"
                                        size="small"
                                    />
                                </FormControl>
                                <FormControl fullWidth style={{ marginBottom: "15px" }} variant="outlined" size="small">
                                    <TextField
                                        id="standard-multiline-static"
                                        label="Requirements"
                                        multiline
                                        rows={4}
                                        placeholder="Enter Requirements"
                                        value={requirements}
                                        onChange={(e) => setRequirements(e.target.value)}
                                        variant="outlined"
                                        size="small"
                                    />
                                </FormControl>
                                <FormControl fullWidth style={{ marginBottom: "15px" }} variant="outlined" size="small">
                                    <InputLabel htmlFor="outlined-adornment-amount">Estimate Budget</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-amount"
                                        value={estimatedBudget}
                                        onChange={(e) => setEstimateBudget(e.target.value)}
                                        label="Estimate Budget"
                                        variant="outlined"
                                        size="small"
                                    />
                                </FormControl>
                                <FormControl fullWidth style={{ marginBottom: "15px" }} variant="outlined" size="small">
                                    <TextField
                                        id="standard-multiline-static"
                                        label="Sales call comment"
                                        multiline
                                        rows={4}
                                        placeholder="Enter Sales Call Comment"
                                        value={salesCallComment}
                                        onChange={(e) => setSalesCallComment(e.target.value)}
                                        variant="outlined"
                                        size="small"
                                    />
                                </FormControl>
                            </div>
                        }
                        {
                            // Quotation sent date, mom quotation stage
                            stage === 'Quotation Sent' &&
                            <div>
                                <FormControl fullWidth style={{ marginBottom: "15px" }} variant="outlined" size="small">
                                    <TextField
                                        id="quotationSent-Date"
                                        name={quotationSentDate}
                                        onChange={(e) => setQuotationSentDate(e.target.value)}
                                        label="Quotation send date"
                                        type="date"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        variant="outlined"
                                        size="small"
                                    />
                                </FormControl>
                                <FormControl fullWidth style={{ marginBottom: "15px" }} variant="outlined" size="small">
                                    <TextField
                                        id="standard-multiline-static"
                                        label="MOM Quotation Stage"
                                        multiline
                                        rows={4}
                                        placeholder="Enter MOM Quotation Stage"
                                        value={momQuotationStage}
                                        onChange={(e) => setMomQuotationStage(e.target.value)}
                                        variant="outlined"
                                        size="small"
                                    />
                                </FormControl>
                            </div>
                        }
                        {
                            // site visit date, mom meeting stage, mom site visit stage
                            stage === 'Site/Office visit' &&
                            <div>
                                <FormControl fullWidth style={{ marginBottom: "15px" }} variant="outlined" size="small">
                                    <TextField
                                        id="siteVisit-Date"
                                        name={siteVisitDate}
                                        onChange={(e) => setSiteVisitDate(e.target.value)}
                                        label="Site Visit date"
                                        type="date"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        variant="outlined"
                                        size="small"
                                    />
                                </FormControl>
                                <FormControl fullWidth style={{ marginBottom: "15px" }} variant="outlined" size="small">
                                    <TextField
                                        id="standard-multiline-static"
                                        label="MOM Meeting Stage"
                                        multiline
                                        rows={4}
                                        placeholder="Enter MOM Meeting Stage"
                                        value={momMeetingStage}
                                        onChange={(e) => setMomMeetingStage(e.target.value)}
                                        variant="outlined"
                                        size="small"
                                    />
                                </FormControl>
                                <FormControl fullWidth style={{ marginBottom: "15px" }} variant="outlined" size="small">
                                    <TextField
                                        id="standard-multiline-static"
                                        label="MOM Site visit Stage"
                                        multiline
                                        rows={4}
                                        placeholder="Enter MOM Site visit Stage"
                                        value={momSiteVisitStage}
                                        onChange={(e) => setMomSiteVisitStage(e.target.value)}
                                        variant="outlined"
                                        size="small"
                                    />
                                </FormControl>
                            </div>
                        }
                        {
                            // Negotiation date, mom negotiation stage
                            stage === 'Negotiation' &&
                            <div>
                                <FormControl fullWidth style={{ marginBottom: "15px" }} variant="outlined" size="small">
                                    <TextField
                                        id="negotiation-Date"
                                        name={negotiationDate}
                                        onChange={(e) => setNegotiationDate(e.target.value)}
                                        label="Negotiation date"
                                        type="date"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        variant="outlined"
                                        size="small"
                                    />
                                </FormControl>
                                <FormControl fullWidth style={{ marginBottom: "15px" }} variant="outlined" size="small">
                                    <TextField
                                        id="standard-multiline-static"
                                        label="MOM Negotiation Stage"
                                        multiline
                                        rows={4}
                                        placeholder="Enter MOM Negotiation Stage"
                                        value={momNegotiationStage}
                                        onChange={(e) => setMomNegotiationStage(e.target.value)}
                                        variant="outlined"
                                        size="small"
                                    />
                                </FormControl>
                            </div>
                        }
                        {
                            // Negotiation date, mom negotiation stage
                            ((stage === 'Negotiation' || stage === 'Won' || stage === 'Site/Office visit' || (stage === 'Lost' && (projectDetails.salesStage === "Negotiation" || projectDetails.salesStage === "Site/Office visit"))) && (!projectDetails.meetingWithCustomer)) &&
                            <div>
                                <FormControl fullWidth variant="outlined" size="small" style={{ marginBottom: 20 }}>
                                    <InputLabel id="demo-simple-select-label">Meet Done</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={meetingDone}
                                        label="Registration Done"
                                        name="isRegistered"
                                        onChange={(e) => setMeetingDone(e.target.value)}
                                        variant="outlined"
                                    >

                                        <MenuItem value="Yes">Yes</MenuItem>
                                        <MenuItem value="No">No</MenuItem>

                                    </Select>
                                </FormControl>
                                {meetingDone === "Yes" &&
                                    <div>
                                        <FormControl fullWidth variant="outlined" size="small" style={{ marginBottom: 20 }}>
                                            <InputLabel id="demo-simple-select-label">Meet Type</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={meetingType}
                                                label="Registration Done"
                                                name="isRegistered"
                                                onChange={(e) => setMeetingType(e.target.value)}
                                                variant="outlined"
                                            >

                                                {/* <MenuItem value="Virtual Meeting">Virtual Meeting</MenuItem> */}
                                                <MenuItem value="Office Meet">Office Meet</MenuItem>
                                                <MenuItem value="Residence Meeting">Residence Meeting</MenuItem>

                                            </Select>
                                        </FormControl>
                                        <FormControl fullWidth style={{ marginBottom: "15px" }} variant="outlined" size="small">
                                            <TextField
                                                id="meetingDoneDate"
                                                name={meetingDate}
                                                onChange={(e) => setMeetingDate(e.target.value)}
                                                label="Meeting Done Date"
                                                type="date"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                variant="outlined"
                                                size="small"
                                            />
                                        </FormControl>

                                    </div>}
                            </div>
                        }
                        {
                            // Won date, Remark, client move in date
                            stage === 'Won' &&
                            <div>
                                <h2>Do you want to assign designer now</h2>
                            </div>
                        }
                        {
                            // Design Sign-off date, Project Completion date, WD Attachement
                            (stage === 'Design Sign-off' || stage === 'Design Sign-off HOLD') &&
                            <div>
                                {/* <FormControl fullWidth style={{ marginBottom: "15px" }} variant="outlined" size="small">
                                    <TextField
                                        id="contractSigned-Date"
                                        name="designSignOffDate"
                                        value={contractSignStageForm.designSignOffDate}
                                        onChange={(e) => setContractSignStageForm({ ...contractSignStageForm, [e.target.name]: e.target.value })}
                                        label="Design Sign-off Date"
                                        type="date"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        variant="outlined"
                                        size="small"
                                        disabled="true"
                                    />
                                </FormControl>
                                <FormControl fullWidth style={{ marginBottom: "15px" }} variant="outlined" size="small">
                                    <TextField
                                        id="projectCompletionDate-Date"
                                        name="projectCompletionDate"
                                        value={contractSignStageForm.projectCompletionDate}
                                        onChange={(e) => setContractSignStageForm({ ...contractSignStageForm, [e.target.name]: e.target.value })}
                                        label="Expected Project Completion Date"
                                        type="date"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        variant="outlined"
                                        size="small"
                                    />
                                </FormControl> */}

                                {/* upload pdf foramt */}

                               {/* <div style={{ paddingBottom: 15, paddingTop: 10 }} variant="outlined" size="small">
                                    <input
                                        accept=".pdf"
                                        className={classes.input}
                                        id="quotation-document"
                                        onChange={(e) => uploadWDFileDocument(e, 'quotationFile')}
                                        type="file"
                                        style={{ display: 'none' }}
                                        disabled={!checkIsUpload.scanQuotationFile && totalSize > 25}
                                    />
                                    <label htmlFor="quotation-document">
                                        <Button variant="contained" color="primary" style={{ background: "#716ACA", margin: 5 }} component="span" disabled={!checkIsUpload.scanQuotationFile && totalSize > 25}>
                                            Upload Quotation PDF
                                        </Button>
                                    </label>
                                    {checkIsUpload.scanQuotationFile && (
                                        <>
                                            <CheckCircleIcon style={{ color: "green" }} />
                                            <h5 style={{ display: "inline-block", marginLeft: "5px" }}>
                                                File Size: {(uploadSize.scanQuotationFile / (1024 * 1024)).toFixed(2)} MB
                                            </h5>
                                        </>
                                    )}

                                    </div>   */}

                                <div style={{ paddingBottom: 15, paddingTop: 10 }} variant="outlined" size="small">
                                    <input
                                        accept=".pdf"
                                        className={classes.input}
                                        id="checkList-document"
                                        onChange={(e) => uploadWDFileDocument(e, 'CheckList')}
                                        type="file"
                                        style={{ display: 'none' }}
                                        disabled={!checkIsUpload.scanCheckListFile && totalSize > 25}
                                    />
                                    <label htmlFor="checkList-document">
                                        <Button variant="contained" color="primary" style={{ background: "#716ACA", margin: 5 }} component="span" disabled={!checkIsUpload.scanCheckListFile && totalSize > 25}>
                                            Upload Checklist
                                        </Button>
                                    </label>
                                    {/* {checkIsUpload.scanCheckListFile && <CheckCircleIcon style={{ color: "green" }} />} */}
                                    {checkIsUpload.scanCheckListFile && (
                                        <>
                                            <CheckCircleIcon style={{ color: "green" }} />
                                            <h5 style={{ display: "inline-block", marginLeft: "5px" }}>
                                                File Size: {(uploadSize.scanCheckListFile / (1024 * 1024)).toFixed(2)} MB
                                            </h5>
                                        </>
                                    )}
                                </div>

                                <div style={{ paddingBottom: 15, paddingTop: 10 }} variant="outlined" size="small">
                                    <input
                                        accept=".pdf"
                                        className={classes.input}
                                        id="projectKickOffCheckList-document"
                                        onChange={(e) => uploadWDFileDocument(e, 'ProjectKickOffCheckList')}
                                        type="file"
                                        style={{ display: 'none' }}
                                        disabled={!checkIsUpload.scanProjectKickOffCheckListFile && totalSize > 25}
                                    />
                                    <label htmlFor="projectKickOffCheckList-document">
                                        <Button variant="contained" color="primary" style={{ background: "#716ACA", margin: 5 }} component="span" disabled={!checkIsUpload.scanProjectKickOffCheckListFile && totalSize > 25}>
                                            Upload Project Kick Off Checklist
                                        </Button>
                                    </label>
                                    {/* {checkIsUpload.scanProjectKickOffCheckListFile && <CheckCircleIcon style={{ color: "green" }} />} */}
                                    {checkIsUpload.scanProjectKickOffCheckListFile && (
                                        <>
                                            <CheckCircleIcon style={{ color: "green" }} />
                                            <h5 style={{ display: "inline-block", marginLeft: "5px" }}>
                                                File Size: {(uploadSize.scanProjectKickOffCheckListFile / (1024 * 1024)).toFixed(2)} MB
                                            </h5>
                                        </>
                                    )}
                                </div>

                                <div style={{ paddingBottom: 15, paddingTop: 10 }} variant="outlined" size="small">
                                    <input
                                        accept=".pdf"
                                        className={classes.input}
                                        id="wdfile-document"
                                        onChange={(e) => uploadWDFileDocument(e, 'wdfile')}
                                        type="file"
                                        style={{ display: 'none' }}
                                        disabled={!checkIsUpload.workingDrawingFile && totalSize > 25}
                                    />
                                    <label htmlFor="wdfile-document">
                                        <Button variant="contained" color="primary" style={{ background: "#716ACA", margin: 5 }} component="span" disabled={!checkIsUpload.workingDrawingFile && totalSize > 25}>
                                            Upload Working Drawing File
                                        </Button>
                                    </label>
                                    {/* {checkIsUpload.workingDrawingFile && <CheckCircleIcon style={{ color: "green" }} />} */}
                                    {checkIsUpload.workingDrawingFile && (
                                        <>
                                            <CheckCircleIcon style={{ color: "green" }} />
                                            <h5 style={{ display: "inline-block", marginLeft: "5px" }}>
                                                File Size: {(uploadSize.workingDrawingFile / (1024 * 1024)).toFixed(2)} MB
                                            </h5>
                                        </>
                                    )}
                                </div>

                                <div style={{ paddingBottom: 15, paddingTop: 10 }} variant="outlined" size="small">
                                    <input
                                        accept=".pdf"
                                        className={classes.input}
                                        id="3wdfile-document"
                                        onChange={(e) => uploadWDFileDocument(e, '3wdfile')}
                                        type="file"
                                        style={{ display: 'none' }}
                                        disabled={!checkIsUpload.threeworkingDrawingFile && totalSize > 25}
                                    />
                                    <label htmlFor="3wdfile-document">
                                        <Button variant="contained" color="primary" style={{ background: "#716ACA", margin: 5 }} component="span" disabled={!checkIsUpload.threeworkingDrawingFile && totalSize > 25}>
                                            Upload 3D Working Drawing File
                                        </Button>
                                    </label>
                                    {/* {checkIsUpload.threeworkingDrawingFile && <CheckCircleIcon style={{ color: "green" }} />} */}
                                    {checkIsUpload.threeworkingDrawingFile && (
                                        <>
                                            <CheckCircleIcon style={{ color: "green" }} />
                                            <h5 style={{ display: "inline-block", marginLeft: "5px" }}>
                                                File Size: {(uploadSize.threeworkingDrawingFile / (1024 * 1024)).toFixed(2)} MB
                                            </h5>
                                        </>
                                    )}
                                </div>

                                {/* <div style={{ paddingBottom: 15, paddingTop: 10 }} variant="outlined" size="small">
                                    <input
                                            accept=".xlsx, .csv"
                                        className={classes.input}
                                        id="quotationcsv-document"
                                        onChange={(e) => uploadWDFileDocument(e, 'quotationcsv')}
                                        type="file"
                                        style={{ display: 'none' }}
                                        disabled={!checkIsUpload.quotationCsvFile && totalSize > 25}
                                    />
                                    <label htmlFor="quotationcsv-document">
                                        <Button variant="contained" color="primary" style={{ background: "#716ACA", margin: 5 }} component="span" disabled={!checkIsUpload.quotationCsvFile && totalSize > 25}>
                                            Upload Quotation Excel/CSV
                                        </Button>
                                    </label>
                                    {checkIsUpload.quotationCsvFile && (
                                        <>
                                            <CheckCircleIcon style={{ color: "green" }} />
                                            <h5 style={{ display: "inline-block", marginLeft: "5px" }}>
                                                File Size: {(uploadSize.quotationCsvFile / (1024 * 1024)).toFixed(2)} MB
                                            </h5>
                                        </>
                                    )}
                                </div> */}

                                <div style={{ paddingBottom: 15, paddingTop: 10 }} variant="outlined" size="small">
                                    <input
                                        accept='.pdf'
                                        className={classes.input}
                                        id="siteqc-checklist"
                                        onChange={(e) => uploadWDFileDocument(e, 'siteqcchecklist')}
                                        type='file'
                                        style={{ display: 'none' }}
                                        disabled={!checkIsUpload.scanSiteQCCheckListFile && totalSize > 25}
                                    />
                                    <label htmlFor='siteqc-checklist'>
                                        <Button variant='contained' color='primary' style={{ background: "#716ACA", margin: 5 }} component="span" disabled={!checkIsUpload.scanSiteQCCheckListFile && totalSize > 25}>
                                            Upload SiteQC CheckList
                                        </Button>
                                    </label>
                                    {/* {checkIsUpload.scanSiteQCCheckListFile && <CheckCircleIcon style={{ color: "green" }} />} */}
                                    {checkIsUpload.scanSiteQCCheckListFile && (
                                        <>
                                            <CheckCircleIcon style={{ color: "green" }} />
                                            <h5 style={{ display: "inline-block", marginLeft: "5px" }}>
                                                File Size: {(uploadSize.scanSiteQCCheckListFile / (1024 * 1024)).toFixed(2)} MB
                                            </h5>
                                        </>
                                    )}
                                </div>

                                <div style={{ paddingBottom: 20, paddingTop: 10, display: "inline-fix" }} variant="outlined" size="small">
                                    {videos.map((video, index) => (
                                        <div key={index}>
                                        <input
                                            type="file"
                                            accept='video/*'
                                            id={`contained-button-video-${index}`}
                                            onChange={(event) => onVideoChangeCheck(event, index)}
                                            style={{ display: 'none' }}
                                        />
                                        <label htmlFor={`contained-button-video-${index}`}>
                                            <Button
                                            variant="contained"
                                            color="primary"
                                            component="span"
                                            style={{ background: "#716ACA", marginBottom: 15, marginLeft: 5 }}
                                            >
                                            UPLOAD SITE VIDEOS
                                            </Button>
                                        </label>
                                        {videos[index] !== "" && (
                                            <>
                                            <CheckCircleIcon style={{ verticalAlign: 'middle', marginLeft: 10, color: 'green', fontSize: 30 }} />
                                            </>
                                        )}
                                        <Button onClick={() => handleAddVideoInput(index)} startIcon={<AddCircleOutlineRoundedIcon />} style={{ marginLeft: 10 }} />
                                        {index !== 0 && (
                                            <Button onClick={() => handleRemoveVideoInput(index)} startIcon={<DeleteOutlineOutlinedIcon />} style={{ marginLeft: 10 }} />
                                        )}
                                        </div>
                                    ))}
                                    </div>

                                    <div style={{ paddingBottom: 20, paddingTop: 10, display: "inline-fix" }} variant="outlined" size="small">
                                        {pics.map((pic, index) => (
                                            <div key={index}>
                                                <input
                                                    type="file"
                                                    accept=".jpg,.png"
                                                    id={`contained-button-pic-${index}`}
                                                    onChange={(event) => onImageChange(event, index)}
                                                    style={{ display: 'none' }}
                                                    disabled={!uploadSize.siteQCPhotos[index] && totalSize>25}
                                                />
                                                <label htmlFor={`contained-button-pic-${index}`}>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        component="span"
                                                        style={{ background: "#716ACA", marginBottom: 15, marginLeft: 5 }}
                                                        disabled={!uploadSize.siteQCPhotos[index] && totalSize>25}
                                                    >
                                                        UPLOAD SITE PHOTOS
                                                    </Button>
                                                </label>
                                                {pics[index] !== "" && (<>
                                                    <CheckCircleIcon style={{ verticalAlign: 'middle', marginLeft: 10, color: 'green', fontSize: 30 }} />
                                                    <h5 style={{ display: "inline-block", marginLeft: "5px" }}>
                                                        File Size: {(uploadSize.siteQCPhotos[index] / (1024 * 1024)).toFixed(2) || 0} MB
                                                    </h5>
                                                
                                                </>)}
                                                <Button onClick={() => handleAddFilesInput(index)} startIcon={<AddCircleOutlineRoundedIcon />} style={{ marginLeft: 10 }} disabled={!uploadSize.siteQCPhotos[index] && totalSize>25}/>
                                                {index !== 0 && (
                                                    <Button onClick={() => handleRemoveFilesInput(index)} startIcon={<DeleteOutlineOutlinedIcon />} style={{ marginLeft: 10 }} />
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                 {/* Clone Quotation Changes for uplaod file pdf and excel of design sign off */}
                                { <>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{ background: "#716ACA", margin: 5 }}
                                        component="span"
                                        disabled={!checkIsUpload.scanQuotationFile && totalSize>25}
                                        onClick={() => setOpenSelectQuotationVersion(true)}
                                    >
                                        Select Quotation Version
                                    </Button>
                                    {checkIsUpload?.quotationCsvFile && checkIsUpload?.scanQuotationFile && (
                                        <>
                                            <CheckCircleIcon style={{ color: "green" }} />
                                            {/* <h5 style={{ display: "inline-block", marginLeft: "5px" }}>
                                                File Size: {(uploadSize.scanQuotationFile / (1024 * 1024)).toFixed(2)} MB
                                            </h5> */}
                                        </>
                                    )}
                                </>
                                 }

                                    {stage === 'Design Sign-off HOLD' &&
                                        <FormControl fullWidth style={{ marginTop: "15px", marginBottom: "15px" }} variant="outlined" size="small">
                                            <TextField
                                                id="designSignOffRevokeDate"
                                                name="designSignOffRevokeDate"
                                                onChange={(e) => setContractSignStageForm({ ...contractSignStageForm, designSignOffRevokeDate: new Date(e.target.value) })}
                                                label="When Hold will be revoked"
                                                type="date"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                variant="outlined"
                                                size="small"
                                            />
                                        </FormControl>}
                                    
                                    <FormControl fullWidth style={{ marginTop: "15px", marginBottom: "15px" }} variant="outlined" size="small">
                                        <TextField
                                            id="internalDesignSignOffDate"
                                            name="internalDesignSignOffDate"
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                setContractSignStageForm({
                                                    ...contractSignStageForm,
                                                    internalDesignSignOffDate: value ? new Date(value) : null
                                                });
                                            }}
                                            label="Internal Design Sign-Off Date"
                                            type="date"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            inputProps={{
                                                min: new Date(Date.now() - 10 * 24 * 60 * 60 * 1000).toISOString().split("T")[0],
                                                max: new Date().toISOString().split("T")[0],
                                            }}
                                            variant="outlined"
                                            size="small"
                                        />
                                    </FormControl>
                                <FormControl fullWidth style={{ marginBottom: "15px", marginTop: 15 }} variant="outlined" size="small">
                                    <TextField
                                        id="grandTotal"
                                        name="grandTotal"
                                        value={projectDetails.staticSalesWonAmount}
                                        label="Tentative Sales Quotation"
                                        type="text"
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        size="small"
                                        disabled="true"
                                    />
                                </FormControl>

                                <FormControl fullWidth style={{ marginBottom: "15px", marginTop: 15 }} variant="outlined" size="small">
                                    <TextField
                                        id="grandTotal"
                                        name="grandTotal"
                                        // value={projectDetails.grandTotal}
                                        value={qtVersionGrandTotal}
                                        label="Design Sign Off Total Amount"
                                        type="text"
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        size="small"
                                        disabled="true"
                                    />
                                </FormControl>

                                <FormControl fullWidth style={{ marginBottom: "15px" }} variant="outlined" size="small">
                                    <TextField
                                        id="amountReceived"
                                        name="contractSignedValue"
                                        value={contractSignStageForm.contractSignedValue}
                                        onChange={(e) => setContractSignStageForm({ ...contractSignStageForm, [e.target.name]: Number(e.target.value) })}
                                        label="Amount Received"
                                        type="number"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        variant="outlined"
                                        size="small"
                                    />
                                </FormControl>
                                <FormControl fullWidth style={{ marginBottom: "15px" }} variant="outlined" size="small">
                                    <TextField
                                        id="percentageReceived"
                                        name="tokenPercent"
                                        // value={((contractSignStageForm.contractSignedValue / projectDetails.grandTotal) * 100).toFixed(2)}
                                        value={qtVersionGrandTotal !== 0 ? ((parseInt(contractSignStageForm.contractSignedValue) / parseInt(qtVersionGrandTotal)) * 100).toFixed(2) : "0.00"}
                                        onChange={(e) => setContractSignStageForm({ ...contractSignStageForm, [e.target.name]: e.target.value })}
                                        label="Percentage Received"
                                        type="text"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        variant="outlined"
                                        size="small"
                                        disabled="true"
                                    />
                                </FormControl>
                                {/* <FormControl fullWidth style={{ marginBottom: "15px", marginTop: 15 }} variant="outlined" size="small">
                                        <TextField
                                            id="standard-number"
                                            label="Days As Per Contract"
                                            name="daysAsPerContractSign"
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => setContractSignStageForm({ ...contractSignStageForm, [e.target.name]: Number(e.target.value) })}
                                            variant="outlined"
                                            size="small"
                                        />
                                    </FormControl> */}
                                {files?.map((file, index) => (
                                    <div style={{ display: 'flex', alignItems: 'center', margin: 10, padding: 2 }}>
                                        <FormControl style={{ width: 500 }}>
                                            <input
                                                key={`${index}`}
                                                accept="*"
                                                className={classes.input}
                                                id={`contained-button-file-${index}`}
                                                name="contractSignedPaymentReceviedAttachemnts"
                                                onChange={(e) => onImageChangeCheck(e, index)}
                                                type="file"
                                                style={{ display: 'none' }}
                                                disabled={!uploadSize.paymentRecievedAttachments[index] && totalSize > 25}
                                            />
                                            <label htmlFor={`contained-button-file-${index}`}>
                                                <Button variant="contained" color="primary" style={{ background: "#716ACA" }} component="span" disabled={!uploadSize.paymentRecievedAttachments[index] && totalSize > 25}>
                                                    Upload Payment Received Attachments
                                                </Button>
                                            </label>
                                        </FormControl>
                                        {files[index] !== "" && (<>
                                            <CheckCircleIcon
                                                style={{ verticalAlign: "middle", color: "green", fontSize: 30, marginLeft: 10 }}
                                            />
                                            <h5 style={{ display: "inline-block", marginLeft: "5px" }}>
                                                File Size: {(uploadSize.paymentRecievedAttachments[index] / (1024 * 1024)).toFixed(2) || 0} MB
                                            </h5>
                                        </>)}
                                        <Button onClick={() => handleAddFileInput(index)} startIcon={<AddCircleOutlineRoundedIcon />} style={{ marginLeft: 10 }} disabled={!uploadSize.paymentRecievedAttachments[index] && totalSize > 25} />
                                        {index !== 0 && (
                                            <Button onClick={() => handleRemoveFileInput(index)} startIcon={<DeleteOutlineOutlinedIcon />} style={{ marginLeft: 10 }} />
                                        )}
                                    </div>
                                ))}

                            </div>
                        }
                        {/* {

                            stage === 'Final Quotation Approval' &&
                            <div>
                                <FormControl fullWidth style={{ marginBottom: 20 }}>
                                    <TextField
                                        select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name="Quotations"
                                        value={selectedQuote}
                                        label="Select Quotation"
                                        onChange={handleQuotations}
                                        variant="outlined"
                                        size="small"
                                    >
                                        {quotationsdata.map((row, i) => {
                                            return (
                                                <MenuItem key={i + 1} value={row._id}>{row.version} - {row.grandTotal}</MenuItem>
                                            )
                                        })}
                                    </TextField>
                                </FormControl>
                            </div>
                        } */}
                        {stage === "Lost" &&
                            <div>
                                <FormControl fullWidth style={{ marginBottom: "15px" }} variant="outlined" size="small">
                                    <InputLabel htmlFor="outlined-adornment-amount">Reason for Lost *</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-amount"
                                        value={lostReason}
                                        onChange={handlelostReason}
                                        label="Estimate Budget"
                                        variant="outlined"
                                        size="small"
                                    />
                                </FormControl>
                            </div>
                        }
                        {stage === 'Design Discussion' &&
                            <div>
                                <FormControl fullWidth style={{ marginBottom: "15px" }} variant="outlined" size="small">
                                    <InputLabel id="select-design-discussion">Select Discussion</InputLabel>
                                    <Select
                                        labelId="select-design-discussion"
                                        id="demo-simple-select1"
                                        value={discussionNo}
                                        label="Select Stage"
                                        onChange={(e)=> setDiscussionNo(e.target.value)}
                                        variant="outlined"
                                        size="small"
                                        // disabled = {discussionNo !== ""}
                                    >
                                        {designDiscussion
                                            ?.filter((row) => !projectDetails?.designDiscussionDetails?.some((detail) => detail?.discussionNo === row))
                                            ?.map((row, i) => (
                                                <MenuItem key={i + 1} value={row}>{row}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                                <FormControl sx={{ marginBottom: '5px', display: 'flex', alignItems: 'center' }}>
                                    <Button onClick={()=>setOpenMomPage(true)} variant="contained" color="primary" disabled = {discussionNo === ''}>Fill MOM</Button>
                                     {
                                         momSubmit && <CheckCircleIcon style={{ marginLeft: '5px', color:"green" ,display: 'flex'}}  />
                                    }
                                </FormControl>
                            </div>
                        } 

                        {stage !== 'Won' && stage !== 'Lost' && stage !== 'Move to Marketing' && stage !== 'Design Sign-off' && stage !== 'Design Sign-off HOLD' && stage !== 'Design Discussion' && <>
                            {/* Select Department */}
                            <FormControl fullWidth style={{ marginBottom: "15px" }} variant="outlined" size="small">
                                <InputLabel id="demo-simple-select-label">Select Department</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label1"
                                    id="demo-simple-select1"
                                    value={department}
                                    label="Select Stage"
                                    onChange={handleSelectedDepartmentChange}
                                    variant="outlined"
                                    size="small"
                                >
                                    {departmentLists.map((row, i) => {
                                        return (
                                            <MenuItem key={i + 1} value={row._id}>{row.name}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                            {/* Select Team */}
                            <FormControl fullWidth style={{ marginBottom: "15px" }} variant="outlined" size="small">
                                <InputLabel id="demo-simple-select-label2">Select Team</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label2"
                                    id="demo-simple-select2"
                                    value={team}
                                    label="Select Team"
                                    onChange={handleSelectedTeamChange}
                                    variant="outlined"
                                    size="small"
                                >
                                    {teamLists.map((row, i) => {
                                        return (
                                            <MenuItem key={i + 1} value={row._id}>{row.name}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                            {/* Select Deal Owner User */}
                            <FormControl fullWidth style={{ marginBottom: "15px" }} variant="outlined" size="small">
                                <InputLabel id="demo-simple-select-label3">Select User</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label3"
                                    id="demo-simple-select3"
                                    value={dealOwner}
                                    label="Select User"
                                    onChange={handleSelectedUserChange}
                                    variant="outlined"
                                    size="small"
                                >
                                    {dealOwnerLists.map((row, i) => {
                                        return (
                                            <MenuItem key={i + 1} value={row._id}>{row.name}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                            {/* Give Project No.*/}
                            {(stage === "Assign to Designer" && !projectDetails.erpProjectNo) &&
                                <div>
                                    <Box sx={{ minWidth: 450 }}>
                                        <FormControl fullWidth variant="outlined" size="small">
                                            <TextField
                                                label="ERP Project No."
                                                size="small"
                                                value={newErpProjectNo}
                                                style={{ marginBottom: 20 }}
                                                name="floorPlan"
                                                onChange={(e) => {
                                                    setNewErpProjectNo(e.target.value);
                                                }} variant="outlined"
                                            />
                                        </FormControl>
                                    </Box>
                                    {erpProjectExists && (
                                        <p style={{ color: 'red' }}>ERP Project No already exists. Please change the number.</p>
                                    )}
                                </div>
                            }
                        </>}
                    </Box>
                </DialogContent>
                <DialogActions>
                    {
                        stage === 'Won' && meetingDone === "Yes" ? <>
                            <Button onClick={() => updateClosureStatus("No")} variant="outlined">No</Button>
                            <Button onClick={() => updateClosureStatus("Yes")} variant="contained" color="primary" disabled={meetingType === "" || meetingDate === null}>Yes</Button>
                        </> :
                        stage === 'Won' ? <>
                            <Button onClick={() => updateClosureStatus("No")} variant="outlined">No</Button>
                            <Button onClick={() => updateClosureStatus("Yes")} variant="contained" color="primary" >Yes</Button>
                        </> : stage === 'Move to Marketing' ? <>
                            <Button onClick={() => setOpenAnotherDialog(false)} variant="outlined">Cancel</Button>
                            <Button onClick={assignToMarketingUser} variant="contained" color="primary">Assign To Marketing User</Button>
                        </> : stage === 'Lost' && meetingDone === "Yes" ? <>
                            <Button onClick={() => setOpenAnotherDialog(false)} variant="outlined">Cancel</Button>
                            <Button onClick={assignProject} variant="contained" color="primary" disabled={lostReason == "" || meetingType === "" || meetingDate === null}>Assign Project</Button>
                        </> : stage === 'Lost' ? <>
                            <Button onClick={() => setOpenAnotherDialog(false)} variant="outlined">Cancel</Button>
                            <Button onClick={assignProject} variant="contained" color="primary" disabled={lostReason == ""}>Assign Project</Button>
                        </> : stage === 'Design Sign-off' ? <>
                            <Button onClick={() => setOpenAnotherDialog(false)} variant="outlined">Cancel</Button>
                            <Button onClick={sendRequestToFinanceAndDesignManager} variant="contained" color="primary"
                                disabled={!hasAttachments || !hasPhotos || !hasVideos || !checkIsUpload.scanCheckListFile ||
                                    !checkIsUpload.scanQuotationFile || !checkIsUpload.threeworkingDrawingFile || !checkIsUpload.quotationCsvFile || !checkIsUpload.scanQuotationFile
                                    || !checkIsUpload.workingDrawingFile || !checkIsUpload.scanProjectKickOffCheckListFile || !checkIsUpload.scanSiteQCCheckListFile || !contractSignStageForm.internalDesignSignOffDate || isDisabled || totalSize>=25}
                       
                            >Send Approval Request</Button>
                                            </> : stage === 'Design Sign-off HOLD' ? <>
                                                <Button onClick={() => setOpenAnotherDialog(false)} variant="outlined">Cancel</Button>
                                                <Button onClick={sendRequestToFinanceAndDesignManager} variant="contained" color="primary"
                                                    disabled={!hasAttachments || !hasPhotos || !checkIsUpload.scanCheckListFile ||
                                                        !checkIsUpload.scanQuotationFile || !checkIsUpload.threeworkingDrawingFile || !checkIsUpload.quotationCsvFile
                                                        || !checkIsUpload.workingDrawingFile || !checkIsUpload.scanProjectKickOffCheckListFile || !checkIsUpload.scanSiteQCCheckListFile || !contractSignStageForm.internalDesignSignOffDate || isDisabled || totalSize >= 25}
                                                >MOVE TO HOLD</Button>
                        </> : meetingDone === "Yes" ? <>
                            <Button onClick={() => setOpenAnotherDialog(false)} variant="outlined">Cancel</Button>
                            <Button onClick={assignProject} variant="contained" color="primary"
                                                disabled={department === '' || team === '' || dealOwner === '' || meetingType === "" || meetingDate === "" || meetingDate === null}
                            >Assign Project</Button>
                        </> : stage === 'Design Discussion' ? <>
                        <Button onClick={() => setOpenAnotherDialog(false)} variant="outlined"> Cancel </Button>
                        <Button variant="contained" color="primary" disabled = { discussionNo === '' || !momSubmit} onClick={handleDiscussionButton}>Submit</Button>
                        </>
                        : <>
                            <Button onClick={() => setOpenAnotherDialog(false)} variant="outlined">Cancel</Button>
                            <Button onClick={assignProject} variant="contained" color="primary"
                                disabled={department === '' || team === ''|| dealOwner === ''|| (stage === 'Assign to Designer' && !projectDetails?.erpProjectNo && !newErpProjectNo) || (stage === 'Assign to Designer' && userDetails?.roles?.find(role => role.name === ROLENAME.DesignUser))}
                            >Assign Project</Button>
                        </>
                    }
                </DialogActions>
            </Dialog>
            {/* Upload Mom File Dialog */}
            <Dialog fullWidth={true} maxWidth={'xl'} open={openMomPage} onClose={() => setOpenMomPage(false)}>
                <div style={{ margin: '3% 18%' }}>
                    <br />
                    <Typography style={{ textAlign: 'center'}} variant="h5" component="h2"><strong>Design Discussion M.O.M</strong></Typography>
                    <br />

                    <Card className={classes.root} variant="outlined">
                        <CardContent>

                        {/* Additional minutes details */}
                            <div className="row" style={{ marginLeft: '5px', marginRight: '5px' }}>
                                <div style={{ marginTop: '1em' }}>
                                    <div className="row" style={{ marginLeft: '5px', marginRight: '5px' }}>
                                        <div style={{ marginTop: '1em' }}>
                                            <TableContainer component={Paper} style={{ width: '100%' }}>
                                                <Table stickyHeader aria-label="sticky table" style={{ width: "100%" }}>
                                                    <TableBody>
                                                    <TableRow>
                                                        <TableCell style={{textAlign:'center', background:'#DCDCDC', fontSize: '14px', fontWeight:'600'}} colSpan={4}>MINUTES OF MEETING</TableCell>
                                                    </TableRow>
                                                        <TableRow key='0'>
                                                            <TableCell component="th" scope="row">Project No</TableCell>
                                                            <TableCell component="th">
                                                                <FormControl style={{ width: 250 }}>
                                                                    <TextField size="small" id="outlined-basic" disabled value={projectDetails.erpProjectNo ? projectDetails.erpProjectNo : "-"} variant="outlined" name="projectNo" type='text' />
                                                                </FormControl>
                                                            </TableCell>

                                                            <TableCell component="th" scope="row">Designer</TableCell>
                                                            <TableCell component="th">
                                                                <FormControl style={{ width: 250 }}>
                                                                    <TextField size="small" id="outlined-basic" disabled value={momDiscussionsDetails.designer} onChange={(e) => handleMomFormDetail(e)} variant="outlined" name="designer" type='text' />
                                                                </FormControl>
                                                            </TableCell>
                                                        </TableRow>

                                                        <TableRow key='1'>
                                                            <TableCell component="th" scope="row">Date</TableCell>
                                                            <TableCell component="th">
                                                                <FormControl style={{ width: 250 }}>
                                                                    <TextField size="small" id="outlined-basic" disabled value={momDiscussionsDetails.date} variant="outlined" name="date" type='text' />
                                                                </FormControl>
                                                            </TableCell>

                                                            <TableCell component="th" scope="row">Time</TableCell>
                                                            <TableCell component="th">
                                                                <FormControl style={{ width: 250 }}>
                                                                    <TextField size="small" id="outlined-basic" disabled value={momDiscussionsDetails.time} variant="outlined" name="time" type='text' />
                                                                </FormControl>
                                                            </TableCell>
                                                        </TableRow>

                                                        <TableRow key='2'>
                                                            <TableCell component="th" scope="row">Type of Meeting</TableCell>
                                                            <TableCell component="th">
                                                                <FormControl style={{ width: 250 }}>
                                                                    <TextField size="small" id="outlined-basic" required value={momDiscussionsDetails.typeOfMeeting} onChange={(e) => handleMomFormDetail(e)} variant="outlined" name="typeOfMeeting" type='text' />
                                                                </FormControl>
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">Quotation No.</TableCell>
                                                            <TableCell component="th">
                                                                <FormControl style={{ width: 250 }}>
                                                                    <TextField size="small" id="outlined-basic" required value={momDiscussionsDetails.quotationNo} onChange={(e) => handleMomFormDetail(e)} variant="outlined" name="quotationNo" type='text' />
                                                                </FormControl>
                                                            </TableCell>
                                                        </TableRow>

                                                        <TableRow key='3'>
                                                            <TableCell component="th" scope="row">Attendees</TableCell>
                                                            <TableCell component="th">
                                                                <FormControl style={{ width: 250 }}>
                                                                    <TextField size="small" id="outlined-basic" required value={momDiscussionsDetails.attendees} onChange={(e) => handleMomFormDetail(e)} variant="outlined" name="attendees" type='text' />
                                                                </FormControl>
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">Minutes By</TableCell>
                                                            <TableCell component="th">
                                                                <FormControl style={{ width: 250 }}>
                                                                    <TextField size="small" id="outlined-basic" required value={momDiscussionsDetails.minutesBy} onChange={(e) => handleMomFormDetail(e)} variant="outlined" name="minutesBy" type='text' />
                                                                </FormControl>
                                                            </TableCell>
                                                        </TableRow>

                                                        <TableRow key='4'>
                                                            <TableCell component="th" scope="row">MOM No</TableCell>
                                                            <TableCell component="th">
                                                                <FormControl style={{ width: 250 }}>
                                                                    <TextField size="small" id="outlined-basic" required value={momDiscussionsDetails.momNo} onChange={(e) => handleMomFormDetail(e)} variant="outlined" name="momNo" type='text' />
                                                                </FormControl>
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">Venue</TableCell>
                                                            <TableCell component="th">
                                                                <FormControl style={{ width: 250 }}>
                                                                    <TextField size="small" id="outlined-basic" required value={momDiscussionsDetails.venue} onChange={(e) => handleMomFormDetail(e)} variant="outlined" name="venue" type='text' />
                                                                </FormControl>
                                                            </TableCell>
                                                        </TableRow>

                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />

                        {/* Additional Agenda Summary */}
                            <TableContainer component={Paper} style={{ width: '100%' }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell style={{textAlign:'center', background:'#DCDCDC', fontSize: '14px', fontWeight:'600'}}>AGENDA SUMMARY</TableCell>
                                        </TableRow>
                                        <TableRow key={0}>
                                            <TableCell colSpan={3}>
                                                <TextField
                                                    variant='outlined'
                                                    fullWidth
                                                    value={momDiscussionsDetails.agenda}
                                                    name='agenda'
                                                    required
                                                    size = "small"
                                                    onChange={(e) => handleMomFormDetail(e)}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <br />

                        {/* Additional Discussion Summary */}
                            <div className="row" style={{ marginLeft: '5px', marginRight: '5px' }}>
                                <div style={{ marginTop: '1em' }}>
                                    <div className="row" style={{ marginLeft: '5px', marginRight: '5px' }}>
                                        <div style={{ marginTop: '1em' }}>
                                            <TableContainer component={Paper} style={{ width: '100%' }}>
                                                <Table stickyHeader aria-label="sticky table" style={{ width: "100%" }}>
                                                    <TableBody>

                                                        <TableRow key='0'>
                                                            <TableCell style={{ textAlign: 'center' }} component="th"><b>Sl No.</b></TableCell>
                                                            <TableCell style={{ textAlign: 'center' }} component="th"><b>Discussion</b></TableCell>
                                                            <TableCell style={{ textAlign: 'center' }} component="th"><b>Owners</b></TableCell>
                                                            <TableCell style={{ textAlign: 'center' }} component="th"><b>Customer remarks (To be filled only if there is change/disagreements on the points)</b></TableCell>
                                                        </TableRow>
                                                        <TableRow key='1'>
                                                            <TableCell style={{ textAlign: 'center' }} component="th">01</TableCell>
                                                            <TableCell style={{ textAlign: 'justify' }} component="th">
                                                                <TextField
                                                                    variant='outlined'
                                                                    fullWidth
                                                                    value={momDiscussionsDetails.discussionSummary}
                                                                    name='discussion'
                                                                    onChange={(e) => handleMomFormDetail(e)}
                                                                    required
                                                                    style={{ width: 400 }}
                                                                    />
                                                            </TableCell>
                                                            <TableCell style={{ textAlign: 'center' }} component="th">
                                                                <FormControl style={{ width: 120 }}>
                                                                    <TextField size="small" id="outlined-basic" required value={momDiscussionsDetails.owners} onChange={(e) => handleMomFormDetail(e)} variant="outlined" name="owners" type='text' />
                                                                </FormControl>
                                                            </TableCell>
                                                            <TableCell style={{ textAlign: 'center' }} component="th">
                                                                <FormControl style={{ width: 150 }}>
                                                                    <TextField size="small" id="outlined-basic" required value={momDiscussionsDetails.customerRemarks} onChange={(e) => handleMomFormDetail(e)} variant="outlined" name="customerRemarks" type='text' />
                                                                </FormControl>
                                                            </TableCell>
                                                        </TableRow>

                                                        <TableRow key='2'>
                                                            <TableCell colSpan={4}><b>Next Meeting: </b>
                                                            <FormControl style={{ width: 200, marginLeft:'5%' }}>
                                                                    <TextField size="small" id="outlined-basic" required value={momDiscussionsDetails.nextMeeting} onChange={(e) => handleMomFormDetail(e)} variant="standard" name="nextMeeting" type='date' />
                                                                </FormControl>
                                                            </TableCell>
                                                        </TableRow>

                                                        <TableRow key='3'>
                                                            <TableCell colSpan={4}><b>Remark: </b>
                                                            <FormControl style={{ width: 200 , marginLeft: '9%'}}>
                                                                    <TextField size="small" id="outlined-basic" required value={momDiscussionsDetails.remark} onChange={(e) => handleMomFormDetail(e)} variant="standard" name="remark" type='text' />
                                                                </FormControl>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </div>
                                       <h5 style={{display: 'flex', alignItems:'right', justifyContent : 'right',marginRight: '15%', marginTop: '3%'}}>Client Acknowledgement:</h5>
                                       <h5 style={{display: 'flex', alignItems:'right', justifyContent : 'right', marginRight: '15%'}}>Date:</h5>
                                    </div>
                                </div>
                            </div>
                            <br />
                        </CardContent>
                    </Card>
                    <DialogActions>

                        <Button
                            onClick={handleDiscussionDetailsSubmit}
                            disabled={!areAllFieldsFilled()}
                            variant="contained"
                            color="primary"
                            style={{ marginRight: 10 }}
                        >
                            Submit
                        </Button>
                        <Button
                            onClick={() => {
                                setOpenMomPage(false);
                                setDiscussionNo("");
                            }}
                            variant="outlined"
                            color="primary"
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                    <br />
                </div>
            </Dialog>

            <Dialog
            open={openNew}
            onClose={handleCloseNew}
            aria-labelledby="form-dialog-title"
            maxWidth="xl"
        >
            <DialogTitle id="form-dialog-title">Enter Details</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="amount"
                    label="Amount"
                    type="number"
                    fullWidth
                    value={amount}
                    onChange={handleAmountChange}
                    variant="outlined"
                    required
                    error={!isValidAmount}
                    helperText={!isValidAmount && 'Please enter a valid amount'}
                    InputProps={{
                        style: { fontSize: '20px', padding: '12px' }
                    }}
                    style={{ marginBottom: '20px' }}
                />
                <FormControlLabel
                    control={<Checkbox checked={payExtra} onChange={(e) => setPayExtra(e.target.checked)} />}
                    label="Customer is willing to pay extra 2.36%"
                    disabled={isDisabledToken}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseNew} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSave} color="primary" variant="contained" disabled={!isValidAmount || isDisabledToken === false ? !payExtra : payExtra}>
                    Submit
                </Button>
            </DialogActions>
        </Dialog>


            {/* Change Lead Status Modal */}
            <Dialog open={openStatusModal} onClose={() => setOpenStatusModal(false)} aria-labelledby="form-dialog-title" maxWidth='sm'>
                <DialogTitle id="form-dialog-title">
                    {userDetails?.roles?.find(role => role.name === ROLENAME.MarketingUser || role.name === ROLENAME.MarketingManager) && `Change the Lead Status`}
                    {userDetails?.roles?.find(role => role.name === ROLENAME.DesignHead || role.name === ROLENAME.DesignManager || role.name === ROLENAME.OperationDesignManager || role.name === ROLENAME.AssistantDesignManager || role.name === ROLENAME.DesignUser) && `Change the Design Status`}
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <div style={{ paddingBottom: 15, marginTop: 15 }}>
                        {
                            userDetails?.roles?.find(role => role.name === ROLENAME.MarketingUser || role.name === ROLENAME.MarketingManager) &&

                            <FormControl fullWidth variant="outlined" size="small">
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    label="Lead Status"
                                    name="status"
                                    value={leadStageStatus}
                                    // onChange={(e) => setLeadStageStatus({ [e.target.name]: e.target.value })}
                                    onChange={(e) => leadStageStatusChange(e)}
                                    variant="outlined"
                                    size="small"
                                >
                                    <MenuItem key={1} value='Lead Received'>Lead Received</MenuItem>
                                    <MenuItem key={2} value='To Be Called'>To Be Called</MenuItem>
                                    <MenuItem key={3} value='Hold'>Hold</MenuItem>
                                    {/* <MenuItem key={4} value='Lead Returned By Sales'>Lead Returned By Sales</MenuItem> */}
                                    <MenuItem key={5} value='Junk'>Junk Lead</MenuItem>
                                </TextField>
                            </FormControl>
                        }
                        {
                            checkStageStatus === true &&
                            <FormControl fullWidth style={{ paddingTop: 20 }} size="small">
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    label="Select Sales Manager"
                                    name="salesManager"
                                    onChange={(e) => getSelectedSalesManagerDetail(e)}
                                    variant="outlined"
                                    size="small"
                                >
                                    {
                                        salesManagerList?.map((data, i) => (
                                            <MenuItem value={data._id} key={i + 1}> {`${data?.teamId?.name} - ${data?.name}`}</MenuItem>
                                        ))
                                    }
                                </TextField>
                            </FormControl>
                        }
                        {
                            userDetails?.roles?.find(role => role.name === ROLENAME.DesignHead || role.name === ROLENAME.DesignManager || role.name === ROLENAME.OperationDesignManager || role.name === ROLENAME.AssistantDesignManager || role.name === ROLENAME.DesignUser) &&

                            <FormControl fullWidth variant="outlined" size="small">
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    label="Design Status"
                                    name="status"
                                    value={selectedDesignStatus}
                                    onChange={(e) => designStageStatusChange(e)}
                                    variant="outlined"
                                    size="small"
                                >
                                    <MenuItem key={1} value='Design'>Design</MenuItem>
                                    <MenuItem key={2} value='Design-Hold'>Design Hold</MenuItem>
                                    {/* <MenuItem key={2} value='Design Hold - Possession more than 3 months'>Design Hold - Possession more than 3 months</MenuItem> */}
                                </TextField>
                            </FormControl>
                        }
                        {
                            (selectedDesignStatus === 'Design-Hold')  && <>

                                <FormControl fullWidth style={{ paddingTop: 20 }} size="small">
                                    <TextField
                                        select
                                        name="design-hold-select-reason"
                                        value={designHoldSelectedTypes}
                                        required="true"
                                        onChange={(e) => { setDesignHoldSelectedTypes(e.target.value); setLeadDesignHoldReason("") }}
                                        label="Design-Hold Reason"
                                        variant="outlined"
                                        size="small"
                                    >
                                        {availableDesignHoldOptions && availableDesignHoldOptions.map((option, index) => (
                                            <MenuItem key={index} value={option.name}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </FormControl>

                            <FormControl fullWidth style={{ paddingTop: 20 }} variant="outlined" size="small">
                                    <TextField
                                        id="design-hold-reason"
                                        label="Remarks"
                                        name="resonDesignHold"
                                        value={leadDesignHoldReason}
                                        required="true"
                                        onChange={(e) => setLeadDesignHoldReason(e.target.value)}
                                        variant="outlined"
                                        size="small"
                                    />
                                </FormControl>
                                <FormControl fullWidth style={{ paddingTop: 20 }} variant="outlined" size="small">
                                    <TextField
                                        style={{ margin: '5px' }}
                                        id="proposedDesignStart-Date"
                                        name="proposedDesignStartDate"
                                        value={moment(proposedDesignStartDate).format('YYYY-MM-DD')}
                                        onChange={(e) => setProposedDesignStartDate(e.target.value)}
                                        label="Probable Date for Restarting the Designing"
                                        type="date"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        inputProps={{
                                            min: moment().format('YYYY-MM-DD'), // Set the min attribute to today's date
                                        }}
                                        required = "true"
                                        variant="outlined"
                                        size="small"
                                    />
                                </FormControl>
                                 {/* Probable Date of Design Sign-Off  */}
                                 <FormControl fullWidth style={{ paddingTop: 20 }} variant="outlined" size="small">
                                    <TextField
                                        style={{ margin: '5px' }}
                                        id="probabaleDateDesignSignOff"
                                        name="probabaleDateDesignSignOff"
                                        value={moment(probableDesignSignOffDate).format('YYYY-MM-DD')}
                                        onChange={(e) => setProbableDesignSignOffDate(e.target.value)}
                                        label="Probable Date of Design Sign-off" 
                                        type="date"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        inputProps={{
                                            min: proposedDesignStartDate ? moment(proposedDesignStartDate).add(1, 'month').format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'), // Set the min attribute to proposedDesignStartDate
                                        }}
                                        variant="outlined"
                                        size="small"
                                        required = "true"
                                    />
                                    </FormControl>
                            </>
                        }
                    </div>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={() => setOpenStatusModal(false)} variant="outlined" color="secondary">
                        Cancel
                    </Button>
                    <Button
                        disabled={(designStageStatus === 'Design-Hold') && (!probableDesignSignOffDate || !proposedDesignStartDate || !designHoldSelectedTypes)}
                        onClick={updateLeadStatus} variant="outlined" color="primary">
                        {checkStageStatus === true ? `Update Status and Assign Lead` : `Update Status`}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openDesignerAssignmentDate} onClose={() => setOpenDesignerAssignmentDate(false)}>
                <DialogTitle>Set Designer Assignment Date</DialogTitle>
                <Divider />
                <DialogContent>
                    <FormControl fullWidth style={{ marginTop: 20, marginBottom: 20 }}>
                        <TextField
                            id="designerAssignement-Date"
                            name="designerAssignmentDate"
                            onChange={(e) => setDesignerAssignmentDate(e.target.value)}
                            label="Select Designer Assignment Date"
                            type="date"
                            InputLabelProps={{
                                shrink: true
                            }}
                            variant="outlined"
                            size="small"
                        />
                    </FormControl>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={() => setOpenDesignerAssignmentDate(false)} variant="outlined" color="primary">Cancel</Button>
                    <Button onClick={() => updateDesignerAssignmentDate()} variant="contained" color="primary">Update Designer Assignment Date</Button>
                </DialogActions>
            </Dialog>

            {/* clone button----------------------------- */}
            <Dialog open={openSelectQuotationVersion} onClose={() => setOpenSelectQuotationVersion(false)} aria-labelledby="form-dialog-title" maxWidth='sm'>
                <DialogTitle id="form-dialog-title">Select Quotation version to Upload CSV and Pdf</DialogTitle>
                <Divider />
                <DialogContent>
                    <div style={{ paddingBottom: 15, paddingTop: 20 }}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                <StyledTableCell align="center">Sl No</StyledTableCell>
                                <StyledTableCell align="center">Quotation Name</StyledTableCell>
                                <StyledTableCell align="center">Created By</StyledTableCell>
                                <StyledTableCell align="center">Created At</StyledTableCell>
                                <StyledTableCell align="center">Lead Amount</StyledTableCell>
                                <StyledTableCell align="center" >Action</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                   {/* Map over quotationList to display the table rows */}
                                    {quotationList.map((data, index) => (
                                        <StyledTableRow key={data._id}>
                                            <StyledTableCell>{index + 1}</StyledTableCell>
                                            <StyledTableCell>{data.version}</StyledTableCell>
                                            <StyledTableCell>{data?.createdBy?.name}</StyledTableCell>
                                            <StyledTableCell>{moment(data?.createdAt).format('DD-MM-YYYY')}</StyledTableCell>
                                            <StyledTableCell>{commaSeparateAmount(data.grandTotal)}</StyledTableCell>
                                            <StyledTableCell>
                                                <Radio
                                                    aria-label="Quotation Version"
                                                    name="quotationVersion"
                                                    value={data._id}
                                                    checked={selectedQuotationVersion === data._id}
                                                    onChange={(e) => { handleCalled()
                                                        const selectedQuotationId = e.target.value;
                                                        setSelectedQuotationVersion(selectedQuotationId);
                                                        getAllQuotationsForProject();
                                                        generateQuotationExcel(selectedQuotationId);
                                                        const selectedQuotation = quotationList.find(quotation => quotation._id === selectedQuotationId);
                                                        if (selectedQuotation) {
                                                            setQtVersion(selectedQuotation.version);
                                                            const selectedQuotationS3Location = selectedQuotation.s3Location;
                                                            setQuotationPdf(selectedQuotationS3Location);
                                                            setQtVersionGrandTotal(selectedQuotation.grandTotal);
                                                            uploadWDFileDocument(selectedQuotationS3Location, 'quotationFile');
                                                            // console.log("selectedQuotationS3Location", selectedQuotationS3Location)
                                                        }
                                                        props.setSnackInfo("Click the Upload Quotation Excel/CSV and PDF Button","warning")
                                                    }}
                                                />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}

                            </TableBody>
                         </Table>
                    </TableContainer>
                </div>
                </DialogContent>
                <Divider />
                <DialogActions>
                    {/* <Button onClick={() => setOpenSelectQuotationVersion(false)} variant="outlined" color="secondary">
                        Cancel
                    </Button> */}
                    <Button onClick={() => cloneSelectedVersion()} variant="contained" color="primary" disabled={!checkIsUpload.scanQuotationFile}>
                        Upload Quotation Excel/CSV and Pdf
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openUpdatePaymentModel} onClose={() => setOpenUpdatePaymentModel(false)}>
                <DialogTitle>Update customer payment</DialogTitle>
                <Divider />
                <DialogContent>
                    <FormControl fullWidth style={{ marginTop: 20 }}>
                        <TextField
                            style={{ margin: '5px' }}
                            id="paymentAmount-design"
                            name="paymentAmount"
                            onChange={(e) => setPaymentUpdateForm({ ...paymentUpdateForm, [e.target.name]: e.target.value })}
                            label="Payment Amount"
                            InputLabelProps={{
                                shrink: true
                            }}
                            type="number"
                            variant="outlined"
                            size="small"
                        />
                    </FormControl>
                    <FormControl fullWidth style={{ marginTop: 20, display: 'inline-block' }}>
                        <input
                            accept="*"
                            className={classes.input}
                            id="designerPaymentAttachement-document"
                            onChange={(e) => uploadWDFileDocument(e, 'designerPayment')}
                            type="file"
                            style={{ display: 'none' }}
                        />
                        <label htmlFor="designerPaymentAttachement-document">
                            <Button variant="contained" color="primary" style={{ background: "#716ACA", margin: 5 }} component="span">
                                Upload Payment Attachment
                            </Button>
                        </label>
                        {checkIsUpload.designerPaymentAttachement && <CheckCircleIcon style={{ color: "green" }} />}

                    </FormControl>
                    <FormControl fullWidth style={{ marginTop: 20 }}>
                        <TextField
                            style={{ margin: '5px' }}
                            id="note-payment"
                            name="note"
                            onChange={(e) => setPaymentUpdateForm({ ...paymentUpdateForm, [e.target.name]: e.target.value })}
                            label="Enter note"
                            InputLabelProps={{
                                shrink: true
                            }}
                            variant="outlined"
                            size="small"
                        />
                    </FormControl>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={() => setOpenUpdatePaymentModel(false)} variant="outlined" color="primary">Cancel</Button>
                    <Button onClick={() => updateCustomerPayment()} disabled={paymentUpdateForm.paymentAmount === "" || paymentUpdateForm.paymentAttachement === ""} variant="contained" color="primary">Update Payment</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openNoDialog} onClose={() => setOpenNoDialog(false)}>
                <DialogTitle>Lead Details</DialogTitle>
                <Divider />
                <DialogContent>
                    <FormControl fullWidth style={{ marginTop: 20 }}>
                        <TextField
                            id="SalesWon-Date"
                            name="SalesWonDate"
                            onChange={(e) => setNewsalesWonDate(e.target.value)}
                            label="Select Sales Won Date"
                            type="date"
                            InputLabelProps={{
                                shrink: true
                            }}
                            inputProps={{
                                max: moment().format('YYYY-MM-DD'),
                                onKeyDown: (e) => e.preventDefault() 
                            }}
                            variant="outlined"
                            size="small"
                        />
                    </FormControl>
                </DialogContent>
                <FormControl fullWidth >
                    <TextField
                        style={{ margin: '1.1rem' }}
                        id="paymentAmount-design"
                        name="paymentAmount"
                        onChange={(e) => setAmountPaid(e.target.value)}
                        label="Payment Amount"
                        InputLabelProps={{
                            shrink: true
                        }}
                        type="number"
                        variant="outlined"
                        size="small"
                    />
                </FormControl>
                <FormControl fullWidth style={{ display: 'inline-block' }}>
                    <input
                        accept="*"
                        className={classes.input}
                        id="PaymentAttachement-document"
                        onChange={(e) => uploadWDFileDocument(e, 'salesPaymentSlip')}
                        type="file"
                        style={{ display: 'none' }}
                    />
                    <label htmlFor="PaymentAttachement-document">
                        <Button variant="contained" color="primary" style={{ background: "#716ACA", marginLeft: "2rem", marginBottom: "1rem", marginRight: "1rem" }} component="span">
                            Upload Payment Attachment
                        </Button>
                    </label>
                    {checkForSales && <CheckCircleIcon style={{ color: "green", marginTop: "2rem", marginLeft: "2rem", marginRight: "1rem" }} />}

                </FormControl>
                <Divider />
                <DialogActions>
                    <Button onClick={() => setSalesWonDate()} variant="contained" color="primary" disabled={clicked || newsalesWonDate === "" || newPaymentSlip === "" || amountPaid === 0}>Update</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={newModals} onClose={() => setNewModal(false)} aria-labelledby="form-dialog-title" maxWidth='lg'>
                <DialogTitle id="form-dialog-title">Approved Payments By Finance Manager</DialogTitle>
                <Divider />
                <DialogContent>
                    {paymentProof.map((el, i) => {
                        return (
                            <>
                                <TableCell >Proof {i + 1}<DownloadOutlined onClick={() => window.open(el?.paymentImageUrl)} /></TableCell>
                            </>)
                    })
                    }
                </DialogContent>
                <Divider />
                <DialogActions>
                </DialogActions>
            </Dialog>
            {/* Assign Lead To Sales Manager Modal */}
            {/* <Dialog open={openSalesManagerModal} onClose={() => setOpenSalesManagerModal(false)} aria-labelledby="form-dialog-title" maxWidth='sm'>
                <DialogTitle id="form-dialog-title">Assign Lead to Sales Manager</DialogTitle>
                <Divider />
                <DialogContent>
                    <div style={{ paddingBottom: 15, paddingTop: 20 }}>
                        <FormControl fullWidth >
                            <TextField
                                id="outlined-select-currency"
                                select
                                label="Select Sales Manager"
                                name="salesManager"
                                onChange={(e) => getSelectedSalesManagerDetail(e)}
                                variant="outlined"
                            >
                                {
                                    salesManagerList?.map((data, i) => (
                                        <MenuItem value={data._id} key={i + 1}>{data.name}</MenuItem>
                                    ))
                                }
                            </TextField>
                        </FormControl>
                    </div>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={() => setOpenSalesManagerModal(false)} variant="outlined" color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={assignLeadToSalesManager} variant="contained" color="primary">
                        Assign Manager
                    </Button>
                </DialogActions>
            </Dialog> */}

            {/* <Dialog open={openCheckListModal} onClose={() => setOpenCheckListModal(false)} aria-labelledby="form-dialog-title" maxWidth='lg'>
                <DialogTitle id="form-dialog-title">Fill the Checklist</DialogTitle>
                <Divider />
                <DialogContent>
                    <div style={{ paddingBottom: 15 }}>
                        <FormControl fullWidth style={{ marginBottom: "15px" }}>
                            <TextField
                                id="standard-multiline-static"
                                label="Remark"
                                multiline
                                rows={4}
                                placeholder="Enter Remark"
                                value={remark}
                                onChange={(e) => setRemark(e.target.value)}
                                variant="outlined"
                                size="small"
                            />
                        </FormControl>
                        <FormControl fullWidth style={{ marginBottom: "15px" }}>
                            <TextField
                                id="closure-Date"
                                name={closureDate}
                                onChange={(e) => setClosureDate(e.target.value)}
                                label="Closure date"
                                type="date"
                                InputLabelProps={{
                                    shrink: true
                                }}
                                variant="outlined"
                                size="small"
                            />
                        </FormControl>

                        <FormControl fullWidth style={{ marginBottom: "15px" }}>
                            <TextField
                                id="clientMovein-Date"
                                name={clientMoveinDate}
                                onChange={(e) => setClientMoveinDate(e.target.value)}
                                label="Client Move in Date"
                                type="date"
                                InputLabelProps={{
                                    shrink: true
                                }}
                                variant="outlined"
                                size="small"
                            />
                        </FormControl>
                    </div>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={() => { setOpenCheckListModal(false); setOpenAnotherDialog(true) }} variant="outlined" color="error">
                        Cancel
                    </Button>
                    <Button onClick={updateLeadCheckList} variant="outlined" color="primary">
                        Update Lead
                    </Button>
                </DialogActions>
            </Dialog> */}

<Dialog open={callLogsModal} onClose={() => { setCallLogsModal(false); setCallLogs([]) }} aria-labelledby="form-dialog-title" fullWidth maxWidth='lg' style={{ padding: "10rem 10rem 10rem 10rem" }}>
                <DialogTitle id="form-dialog-title" style={{ textAlign: "center" }}>
                    List of Call Logs
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Table className={classes.table} size="small" aria-label="customized table">
                            <StyledTableRow>
                                <StyledTableCell style={{ textAlign: "center" }} component="th" scope="row">S.No.</StyledTableCell>
                                <StyledTableCell style={{ textAlign: "center" }} component="th" scope="row">Call Recording</StyledTableCell>
                                <StyledTableCell style={{ textAlign: "center" }} component="th" scope="row">Date</StyledTableCell>
                            </StyledTableRow>
                            <TableBody>
                                {callLogs.map((row, i) => {
                                    return (
                                        <StyledTableRow key={i + 1}>
                                            <StyledTableCell style={{ textAlign: "center" }}>{i + 1}</StyledTableCell>
                                            <StyledTableCell style={{ textAlign: "center" }} component="td" scope="row">
                                                {row.recording_url ? 
                                                    <a href={row.recording_url} target="_blank" rel="noopener noreferrer">Click to Download</a> 
                                                    : 'NA'}
                                            </StyledTableCell>
                                            <StyledTableCell style={{ textAlign: "center" }} component="td" scope="row">{row.createdAt ? moment(row.createdAt).format("DD-MM-YYYY") : ''}</StyledTableCell>  
                                        </StyledTableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </div>
    )
}


export default connect(null, { setLoading, setSnackInfo })(ManageProjectLogs)