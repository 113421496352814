import React, { useState, useEffect, useRef } from 'react'
import { setLoading, setSnackInfo } from '../../redux/actions/appAction'
import { connect } from 'react-redux'
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import HttpService from '../../services/httpService';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { CSVLink } from "react-csv";
import { useSelector } from 'react-redux';
import { ROLENAME } from '../../utils/index';
import { Link } from 'react-router-dom';




const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    container: {
        maxHeight: 450,
    },
    radioButton: {
        // padding:20,
        marginLeft: "20px"
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    }

}))

const ViewCustomerSurveyForms = (props) => {
    const classes = useStyles();
    const history = useHistory();

    const headers = [
        { key: "leadId", label: "Lead Id" },
        {key: "projectNo", label: "Project No"},
        { key: "customerName", label: "Customer Name" },
        { key: "assignTo", label: "Assigned To" },
        { key: "email", label: 'Email' },
        { key: "feedback", label: "Feedback" },
        { key: "satisfactionavg", label: "Statisfaction Avg" },
        { key: "Clarityofcommunication", label: 'Clarity Of Communication' },
        { key: "Understandingofrequirements", label: "Understanding Of Requirements" },
        { key: "Promptnessinresponse", label: 'Promptness In Response' },
        { key: "Professionalism", label: 'Professionalism' },
        { key: "Timelydeliveryofquotations", label: "Timely Delivery Of Quotations" },
        { key: "Experiencecenterwalkthrough", label: 'Experiencecenter Walkthrough' },
        { key: "Materialknowledgeofsalesperson", label: "Material Knowledge Of Salesperson" },
        { key: "Budgetexpectationsmet", label: 'Budget Expectations Met' },
        { key: "Approachability", label: 'Approachability' },
        { key: "Productrangeoffered", label: 'Productrange Offered' },
        { key: "Communication_Callsandfollowups", label: 'Communication_Callsandfollowups' },
        { key: "reasonToChooseDecorpot", label: 'Reason To Choose Decorpot' },
        { key: "otherReasonToChooseDecorpot", label: 'Other Reason To Choose Decorpot' },
        { key: "Professionalismandattitude", label: 'Professionalism and Attitude' },
        { key: "Knowledgeonsubjectmatter", label: 'Knowledgeon Subjectmatter' },
        { key: "Punctuality", label: "Punctuality" },
        { key: "Communication", label: 'Communication' },
        { key: "Designoptions", label: "Designoptions" },
        { key: "DesignerCreativity", label: 'DesignerCreativity' },
        { key: "Promptnessinresponse", label: 'Promptness In Response' },
        { key: "isDesignManagerInvolved", label: "Is Design Manager Involed" },
        { key: "DesignManagerApproachability", label: 'DesignManager Approachability' },
        { key: "DesignManagerCommunication", label: "DesignManager Communication" },
        { key: "DesignManagerPromptnessinresponse", label: 'DesignManager Promptnessinresponse' },
        { key: "DesignManagerLeadership", label: 'DesignManager Leadership' },
        { key: "DesignManagerOthers", label: 'DesignManager Others' },
        { key: "designManagerAvg", label: 'Design Manager Avg' },
        { key: "chmName", label: "CHM Name" },
        { key: "isChmInvolved", label: 'Is Design Manager Involed' },
        { key: "ChmApproachability", label: 'Chm Approachability' },
        { key: "ChmCommunication", label: "Chm Communication" },
        { key: "ChmPromptnessinresponse", label: 'Chm Promptness In Response' },
        { key: "ChmLeadership", label: 'Chm Leadership' },
        { key: "ChmOthers", label: 'Chm Others' },
        { key: "chmAvg", label: 'CHM Avg' }
    ];

    const [csvData, setCsvData] = useState([]);
    const { userDetails } = useSelector(state => state.user)
    const [radioValue, setRadioValue] = useState("")
    const buttonRef = useRef(null);

    useEffect(() => {
        if (userDetails.roles.find(role => role.name === ROLENAME.DesignHead || role.name === ROLENAME.DesignManager || role.name === ROLENAME.DesignUser || role.name === ROLENAME.AssistanceDesignManager || role.name === ROLENAME.AssistantDesignManager || role.name === ROLENAME.OperationDesignManager || role.name === ROLENAME.DGM_Design)) {
            setRadioValue("design")
            getSurveyForm(props.location.state === null ? 'design' : props.location.state.selectedRadio);
        }
        else if (userDetails.roles.find(role => role.name === ROLENAME.Execution_Manager || role.name === ROLENAME.ExecutionUser || role.name === ROLENAME.OperationManager)) {
            setRadioValue("execution")
            getSurveyForm(props.location.state === null ? 'execution' : props.location.state.selectedRadio);

        }
        else if (userDetails.roles.find(role => role.name === ROLENAME.SalesHead || role.name === ROLENAME.SalesManager || role.name === ROLENAME.SalesUser || role.name === ROLENAME.AssistantSalesManager || role.name === ROLENAME.DGM_Sales)) {
            setRadioValue("won")
            getSurveyForm(props.location.state === null ? 'won' : props.location.state.selectedRadio);

        }
        else {
            setRadioValue(props.location.state === null ? 'real' : props.location.state.selectedRadio)
            getSurveyForm(props.location.state === null ? 'real' : props.location.state.selectedRadio);

        }
        // eslint-disable-next-line
    }, [])
    const [open, setOpen] = useState(false);

    const handleRadioChange = (e) => {
        setRadioValue(e.target.value)
        getSurveyForm(e.target.value);
    }

    const [surveyFormData, setSurveyFormData] = useState([])
    const getSurveyForm = async (type) => {
        // console.log('called')
        props.setLoading(true)
        try {
            const obj = {experienceCenterId: userDetails?.selectedExpCenter}
            // console.log('type', type)
            // console.log('obj', obj)
            const response = await HttpService.getSurveyFormList(type, obj)
            setSurveyFormData(response.data)
            let satisfactionlength = 0;
            for (let i = 0; i < response.data.length; i++) {
                let value = 0;
                satisfactionlength = response.data[i].satisfactionIndexRatio.length;
                for (let j = 0; j < response.data[i].satisfactionIndexRatio.length; j++) {
                    value += +response.data[i].satisfactionIndexRatio[j].actionSelected
                }
                let total = value / satisfactionlength;
                response.data[i]["satisfactionavg"] = total.toFixed(1)
            }


            let lengthOfDesignManager = 0
            for (let d = 0; d < response.data.length; d++) {
                if (response.data[d].isDesignManagerInvolved === "Yes") {
                    let valueManager = 0;
                    lengthOfDesignManager = response.data[d].satisfactionIndexForDesignManager.length;
                    for (let j = 0; j < response.data[d].satisfactionIndexForDesignManager.length; j++) {
                        valueManager += +response.data[d].satisfactionIndexForDesignManager[j].actionSelected
                    }
                    let total = valueManager / lengthOfDesignManager
                    response.data[d]["designManagerAvg"] = total.toFixed(1)
                }
            }

            let lengthOfChm = 0
            for (let d = 0; d < response.data.length; d++) {
                if (response.data[d].isChmInvolved === "Yes") {
                    let valueChm = 0;
                    lengthOfChm = response.data[d].satisfactionIndexForChm.length;
                    for (let j = 0; j < response.data[d].satisfactionIndexForChm.length; j++) {
                        valueChm += +response.data[d].satisfactionIndexForChm[j].actionSelected
                    }
                    let total = valueChm / lengthOfChm;
                    response.data[d]["chmAvg"] = total.toFixed(1);
                }
            }
            setSurveyFormData(response.data)
            let completestore = []
            if (type == "real" || type == "junk") {
                response.data.forEach((el) => {
                    let obj = {
                        leadId: el?.leadId?.lead_no,
                        projectId: el?.leadId?._id,
                        projectNo: el?.leadId?.erpProjectNo,
                        customerName: el?.customerId.name,
                        assignTo: el?.leadOwner?.name,
                        email: el?.customerId?.email,
                        feedback: el?.feedback,
                        satisfactionavg: el?.satisfactionavg,
                        Clarityofcommunication: el?.satisfactionIndexRatio[0].actionSelected,
                        Understandingofrequirements: el?.satisfactionIndexRatio[1].actionSelected,
                        Promptnessinresponse: el?.satisfactionIndexRatio[2].actionSelected,
                        Professionalism: el?.satisfactionIndexRatio[3].actionSelected,
                    }
                    completestore.push(obj)
                })
            } else if (type === "lost" || type === "won") {
                response.data.forEach((el) => {
                    let obj = {
                        leadId: el?.leadId?.lead_no,
                        projectId: el?.leadId?._id,
                        projectNo: el?.leadId?.erpProjectNo,
                        customerName: el?.customerId?.name,
                        assignTo: el?.leadOwner?.name,
                        email: el?.customerId?.email,
                        feedback: el?.comments,
                        isVisitedExperienceCenter: el?.isVisitedExperienceCenter,
                        invitedExperienceCenter: el?.invitedExperienceCenter,
                        satisfactionavg: el?.satisfactionavg,
                        Understandingofrequirements: el?.satisfactionIndexRatio[0]?.actionSelected,
                        Timelydeliveryofquotations: el?.satisfactionIndexRatio[1]?.actionSelected,
                        Experiencecenterwalkthrough: el?.satisfactionIndexRatio[2]?.actionSelected,
                        Productrangeoffered: el?.satisfactionIndexRatio[3]?.actionSelected,
                        Materialknowledgeofsalesperson: el?.satisfactionIndexRatio[4]?.actionSelected,
                        Budgetexpectationsmet: el?.satisfactionIndexRatio[5]?.actionSelected,
                        Professionalism: el?.satisfactionIndexRatio[6]?.actionSelected,
                        Approachability: el?.satisfactionIndexRatio[7]?.actionSelected,
                        Communication_Callsandfollowups: el?.satisfactionIndexRatio[8]?.actionSelected,
                        reasonToChooseDecorpot: el?.reasonToChooseDecorpot,
                        otherReasonToChooseDecorpot: el?.otherReasonToChooseDecorpot,
                    }
                    completestore.push(obj)
                })

            } else if (type === "design") {
                response.data.forEach((el) => {
                    let obj = {
                        leadId: el?.leadId?.lead_no,
                        projectId: el?.leadId?._id,
                        projectNo: el?.leadId?.erpProjectNo,
                        customerName: el?.customerId?.name,
                        assignTo: el?.leadOwner?.name,
                        email: el?.customerId?.email,
                        feedback: el?.feedback,
                        isVisitedExperienceCenter: el?.isVisitedExperienceCenter,
                        invitedExperienceCenter: el?.invitedExperienceCenter,
                        satisfactionavg: el?.satisfactionavg,
                        Professionalismandattitude: el?.satisfactionIndexRatio[0]?.actionSelected,
                        Knowledgeonsubjectmatter: el?.satisfactionIndexRatio[1]?.actionSelected,
                        Understandingofrequirements: el?.satisfactionIndexRatio[2]?.actionSelected,
                        Punctuality: el?.satisfactionIndexRatio[3]?.actionSelected,
                        Communication: el?.satisfactionIndexRatio[4]?.actionSelected,
                        Approachability: el?.satisfactionIndexRatio[5]?.actionSelected,
                        Designoptions: el?.satisfactionIndexRatio[6]?.actionSelected,
                        DesignerCreativity: el?.satisfactionIndexRatio[7]?.actionSelected,
                        Promptnessinresponse: el?.satisfactionIndexRatio[8]?.actionSelected,
                        isDesignManagerInvolved: el?.isDesignManagerInvolved,
                        DesignManagerApproachability: el?.satisfactionIndexForDesignManager[0]?.actionSelected,
                        DesignManagerCommunication: el?.satisfactionIndexForDesignManager[1]?.actionSelected,
                        DesignManagerPromptnessinresponse: el?.satisfactionIndexForDesignManager[2]?.actionSelected,
                        DesignManagerLeadership: el?.satisfactionIndexForDesignManager[3]?.actionSelected,
                        DesignManagerOthers: el?.satisfactionIndexForDesignManager[4]?.actionSelected,
                        designManagerAvg: el?.designManagerAvg,
                        reasonForNotMovingAhead: el?.reasonForNotMovingAhead,
                        chmName: el?.chmId?.assignTo?.name
                    }
                    completestore.push(obj)
                })

            } else if (type === "design-lost") {
                response.data.forEach((el) => {
                    let obj = {
                        leadId: el?.leadId?.lead_no,
                        projectId: el?.leadId?._id,
                        projectNo: el?.leadId?.erpProjectNo,
                        customerName: el?.customerId?.name,
                        assignTo: el?.leadOwner?.name,
                        email: el?.customerId?.email,
                        feedback: el?.feedback,
                        isVisitedExperienceCenter: el?.isVisitedExperienceCenter,
                        invitedExperienceCenter: el?.invitedExperienceCenter,
                        satisfactionavg: el?.satisfactionavg,
                        Professionalismandattitude: el?.satisfactionIndexRatio[0]?.actionSelected,
                        Knowledgeonsubjectmatter: el?.satisfactionIndexRatio[1]?.actionSelected,
                        Understandingofrequirements: el?.satisfactionIndexRatio[2]?.actionSelected,
                        Punctuality: el?.satisfactionIndexRatio[3]?.actionSelected,
                        Communication: el?.satisfactionIndexRatio[4]?.actionSelected,
                        Approachability: el?.satisfactionIndexRatio[5]?.actionSelected,
                        Designoptions: el?.satisfactionIndexRatio[6]?.actionSelected,
                        DesignerCreativity: el?.satisfactionIndexRatio[7]?.actionSelected,
                        Promptnessinresponse: el?.satisfactionIndexRatio[8]?.actionSelected,
                        isDesignManagerInvolved: el?.isDesignManagerInvolved,
                        DesignManagerApproachability: el?.satisfactionIndexForDesignManager[0]?.actionSelected,
                        DesignManagerCommunication: el?.satisfactionIndexForDesignManager[1]?.actionSelected,
                        DesignManagerPromptnessinresponse: el?.satisfactionIndexForDesignManager[2]?.actionSelected,
                        DesignManagerLeadership: el?.satisfactionIndexForDesignManager[3]?.actionSelected,
                        DesignManagerOthers: el?.satisfactionIndexForDesignManager[4]?.actionSelected,
                        designManagerAvg: el?.designManagerAvg,
                        isChmInvolved: el?.isChmInvolved,
                        ChmApproachability: el?.satisfactionIndexForChm[0]?.actionSelected,
                        ChmCommunication: el?.satisfactionIndexForChm[1]?.actionSelected,
                        ChmPromptnessinresponse: el?.satisfactionIndexForChm[2]?.actionSelected,
                        ChmLeadership: el?.satisfactionIndexForChm[3]?.actionSelected,
                        ChmOthers: el?.satisfactionIndexForChm[4]?.actionSelected,
                        chmAvg: el?.chmAvg,
                        reasonForNotMovingAhead: el?.reasonForNotMovingAhead,
                        chmName: el?.chmId?.assignTo?.name
                    }
                    completestore.push(obj)
                })
            }
            else {
                response.data.forEach((el) => {
                    let obj = {
                        leadId: el?.leadId?.lead_no,
                        projectId: el?.leadId?._id,
                        projectNo: el?.leadId?.erpProjectNo,
                        customerName: el?.customerId?.name,
                        assignTo: el?.leadOwner?.name,
                        email: el?.customerId?.email,
                        feedback: el?.feedback,
                        isVisitedExperienceCenter: el?.isVisitedExperienceCenter,
                        invitedExperienceCenter: el?.invitedExperienceCenter,
                        satisfactionavg: el?.satisfactionavg,
                        Understandingofrequirements: el?.satisfactionIndexRatio[0]?.actionSelected,
                        Timelydeliveryofquotations: el?.satisfactionIndexRatio[1]?.actionSelected,
                        Experiencecenterwalkthrough: el?.satisfactionIndexRatio[2]?.actionSelected,
                        Productrangeoffered: el?.satisfactionIndexRatio[3]?.actionSelected,
                        Materialknowledgeofsalesperson: el?.satisfactionIndexRatio[4]?.actionSelected,
                        Budgetexpectationsmet: el?.satisfactionIndexRatio[5]?.actionSelected,
                        Professionalism: el?.satisfactionIndexRatio[6]?.actionSelected,
                        Approachability: el?.satisfactionIndexRatio[7]?.actionSelected,
                        Communication_Callsandfollowups: el?.satisfactionIndexRatio[8]?.actionSelected,
                        reasonToChooseDecorpot: el?.reasonToChooseDecorpot,
                        otherReasonToChooseDecorpot: el?.otherReasonToChooseDecorpot,
                    }
                    completestore.push(obj)
                })
            }
            setCsvData(completestore)

        } catch (error) {
            props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const showCustomerForm = (type, data) => {
        history.push(`/customer-survey-form-details`, {
            data: data,
            type: type
        });
    }
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState();
    const getdataOnSelectedDate = async (type) => {
        let obj = {
            startDate: startDate,
            endDate: endDate,
            experienceCenterId: userDetails?.selectedExpCenter
        }
        props.setLoading(true)
        try {
            const response = await HttpService.filteredSurveyFormList(radioValue, obj)
            setSurveyFormData(response.data)
            let satisfactionlength = 0;
            for (let i = 0; i < response.data.length; i++) {
                let value = 0;
                satisfactionlength = response.data[i].satisfactionIndexRatio.length;
                for (let j = 0; j < response.data[i].satisfactionIndexRatio.length; j++) {
                    value += +response.data[i].satisfactionIndexRatio[j].actionSelected
                }
                let total = value / satisfactionlength;
                response.data[i]["satisfactionavg"] = total.toFixed(1)
            }


            let lengthOfDesignManager = 0
            for (let d = 0; d < response.data.length; d++) {
                if (response.data[d].isDesignManagerInvolved === "Yes") {
                    let valueManager = 0;
                    lengthOfDesignManager = response.data[d].satisfactionIndexForDesignManager.length;
                    for (let j = 0; j < response.data[d].satisfactionIndexForDesignManager.length; j++) {
                        valueManager += +response.data[d].satisfactionIndexForDesignManager[j].actionSelected
                    }
                    let total = valueManager / lengthOfDesignManager
                    response.data[d]["designManagerAvg"] = total.toFixed(1)
                }
            }

            let lengthOfChm = 0
            for (let d = 0; d < response.data.length; d++) {
                if (response.data[d].isChmInvolved === "Yes") {
                    let valueChm = 0;
                    lengthOfChm = response.data[d].satisfactionIndexForChm.length;
                    for (let j = 0; j < response.data[d].satisfactionIndexForChm.length; j++) {
                        valueChm += +response.data[d].satisfactionIndexForChm[j].actionSelected
                    }
                    let total = valueChm / lengthOfChm;
                    response.data[d]["chmAvg"] = total.toFixed(1);
                }
            }
            setSurveyFormData(response.data)
            let completestore = []
            if (type == "real" || type == "junk") {
                response.data.forEach((el) => {
                    let obj = {
                        leadId: el?.leadId?.lead_no,
                        projectId: el?.leadId?._id,
                        projectNo: el?.leadId?.erpProjectNo,
                        customerName: el?.customerId.name,
                        assignTo: el?.leadOwner?.name,
                        email: el?.customerId?.email,
                        feedback: el?.feedback,
                        satisfactionavg: el?.satisfactionavg,
                        Clarityofcommunication: el?.satisfactionIndexRatio[0].actionSelected,
                        Understandingofrequirements: el?.satisfactionIndexRatio[1].actionSelected,
                        Promptnessinresponse: el?.satisfactionIndexRatio[2].actionSelected,
                        Professionalism: el?.satisfactionIndexRatio[3].actionSelected,
                    }
                    completestore.push(obj)
                })
            } else if (type === "lost" || type === "won") {
                response.data.forEach((el) => {
                    let obj = {
                        leadId: el?.leadId?.lead_no,
                        projectId: el?.leadId?._id,
                        projectNo: el?.leadId?.erpProjectNo,
                        customerName: el?.customerId?.name,
                        assignTo: el?.leadOwner?.name,
                        email: el?.customerId?.email,
                        feedback: el?.comments,
                        isVisitedExperienceCenter: el?.isVisitedExperienceCenter,
                        invitedExperienceCenter: el?.invitedExperienceCenter,
                        satisfactionavg: el?.satisfactionavg,
                        Understandingofrequirements: el?.satisfactionIndexRatio[0]?.actionSelected,
                        Timelydeliveryofquotations: el?.satisfactionIndexRatio[1]?.actionSelected,
                        Experiencecenterwalkthrough: el?.satisfactionIndexRatio[2]?.actionSelected,
                        Productrangeoffered: el?.satisfactionIndexRatio[3]?.actionSelected,
                        Materialknowledgeofsalesperson: el?.satisfactionIndexRatio[4]?.actionSelected,
                        Budgetexpectationsmet: el?.satisfactionIndexRatio[5]?.actionSelected,
                        Professionalism: el?.satisfactionIndexRatio[6]?.actionSelected,
                        Approachability: el?.satisfactionIndexRatio[7]?.actionSelected,
                        Communication_Callsandfollowups: el?.satisfactionIndexRatio[8]?.actionSelected,
                        reasonToChooseDecorpot: el?.reasonToChooseDecorpot,
                        otherReasonToChooseDecorpot: el?.otherReasonToChooseDecorpot,
                    }
                    completestore.push(obj)
                })

            } else if (type === "design-lost") {
                response.data.forEach((el) => {
                    let obj = {
                        leadId: el?.leadId?.lead_no,
                        projectId: el?.leadId?._id,
                        projectNo: el?.leadId?.erpProjectNo,
                        customerName: el?.customerId?.name,
                        assignTo: el?.leadOwner?.name,
                        email: el?.customerId?.email,
                        feedback: el?.feedback,
                        isVisitedExperienceCenter: el?.isVisitedExperienceCenter,
                        invitedExperienceCenter: el?.invitedExperienceCenter,
                        satisfactionavg: el?.satisfactionavg,
                        Professionalismandattitude: el?.satisfactionIndexRatio[0]?.actionSelected,
                        Knowledgeonsubjectmatter: el?.satisfactionIndexRatio[1]?.actionSelected,
                        Understandingofrequirements: el?.satisfactionIndexRatio[2]?.actionSelected,
                        Punctuality: el?.satisfactionIndexRatio[3]?.actionSelected,
                        Communication: el?.satisfactionIndexRatio[4]?.actionSelected,
                        Approachability: el?.satisfactionIndexRatio[5]?.actionSelected,
                        Designoptions: el?.satisfactionIndexRatio[6]?.actionSelected,
                        DesignerCreativity: el?.satisfactionIndexRatio[7]?.actionSelected,
                        Promptnessinresponse: el?.satisfactionIndexRatio[8]?.actionSelected,
                        isDesignManagerInvolved: el?.isDesignManagerInvolved,
                        DesignManagerApproachability: el?.satisfactionIndexForDesignManager[0]?.actionSelected,
                        DesignManagerCommunication: el?.satisfactionIndexForDesignManager[1]?.actionSelected,
                        DesignManagerPromptnessinresponse: el?.satisfactionIndexForDesignManager[2]?.actionSelected,
                        DesignManagerLeadership: el?.satisfactionIndexForDesignManager[3]?.actionSelected,
                        DesignManagerOthers: el?.satisfactionIndexForDesignManager[4]?.actionSelected,
                        designManagerAvg: el?.designManagerAvg,
                        isChmInvolved: el?.isChmInvolved,
                        ChmApproachability: el?.satisfactionIndexForChm[0]?.actionSelected,
                        ChmCommunication: el?.satisfactionIndexForChm[1]?.actionSelected,
                        ChmPromptnessinresponse: el?.satisfactionIndexForChm[2]?.actionSelected,
                        ChmLeadership: el?.satisfactionIndexForChm[3]?.actionSelected,
                        ChmOthers: el?.satisfactionIndexForChm[4]?.actionSelected,
                        chmAvg: el?.chmAvg,
                        reasonForNotMovingAhead: el?.reasonForNotMovingAhead,
                        chmName: el?.chmId?.assignTo?.name
                    }
                    completestore.push(obj)
                })
            } else if (type === "design-lost") {
                response.data.forEach((el) => {
                    let obj = {
                        leadId: el?.leadId?.lead_no,
                        projectId: el?.leadId?._id,
                        projectNo: el?.leadId?.erpProjectNo,
                        customerName: el?.customerId?.name,
                        assignTo: el?.leadOwner?.name,
                        email: el?.customerId?.email,
                        feedback: el?.feedback,
                        isVisitedExperienceCenter: el?.isVisitedExperienceCenter,
                        invitedExperienceCenter: el?.invitedExperienceCenter,
                        satisfactionavg: el?.satisfactionavg,
                        Professionalismandattitude: el?.satisfactionIndexRatio[0]?.actionSelected,
                        Knowledgeonsubjectmatter: el?.satisfactionIndexRatio[1]?.actionSelected,
                        Understandingofrequirements: el?.satisfactionIndexRatio[2]?.actionSelected,
                        Punctuality: el?.satisfactionIndexRatio[3]?.actionSelected,
                        Communication: el?.satisfactionIndexRatio[4]?.actionSelected,
                        Approachability: el?.satisfactionIndexRatio[5]?.actionSelected,
                        Designoptions: el?.satisfactionIndexRatio[6]?.actionSelected,
                        DesignerCreativity: el?.satisfactionIndexRatio[7]?.actionSelected,
                        Promptnessinresponse: el?.satisfactionIndexRatio[8]?.actionSelected,
                        isDesignManagerInvolved: el?.isDesignManagerInvolved,
                        DesignManagerApproachability: el?.satisfactionIndexForDesignManager[0]?.actionSelected,
                        DesignManagerCommunication: el?.satisfactionIndexForDesignManager[1]?.actionSelected,
                        DesignManagerPromptnessinresponse: el?.satisfactionIndexForDesignManager[2]?.actionSelected,
                        DesignManagerLeadership: el?.satisfactionIndexForDesignManager[3]?.actionSelected,
                        DesignManagerOthers: el?.satisfactionIndexForDesignManager[4]?.actionSelected,
                        designManagerAvg: el?.designManagerAvg,
                        isChmInvolved: el?.isChmInvolved,
                        ChmApproachability: el?.satisfactionIndexForChm[0]?.actionSelected,
                        ChmCommunication: el?.satisfactionIndexForChm[1]?.actionSelected,
                        ChmPromptnessinresponse: el?.satisfactionIndexForChm[2]?.actionSelected,
                        ChmLeadership: el?.satisfactionIndexForChm[3]?.actionSelected,
                        ChmOthers: el?.satisfactionIndexForChm[4]?.actionSelected,
                        chmAvg: el?.chmAvg,
                        reasonForNotMovingAhead: el?.reasonForNotMovingAhead,
                        chmName: el?.chmId?.assignTo?.name
                    }
                    completestore.push(obj)
                })
            } else {
                response.data.forEach((el) => {
                    let obj = {
                        leadId: el?.leadId?.lead_no,
                        projectId: el?.leadId?._id,
                        projectNo: el?.leadId?.erpProjectNo,
                        customerName: el?.customerId?.name,
                        assignTo: el?.leadOwner?.name,
                        email: el?.customerId?.email,
                        feedback: el?.feedback,
                        isVisitedExperienceCenter: el?.isVisitedExperienceCenter,
                        invitedExperienceCenter: el?.invitedExperienceCenter,
                        satisfactionavg: el?.satisfactionavg,
                        Understandingofrequirements: el?.satisfactionIndexRatio[0].actionSelected,
                        Timelydeliveryofquotations: el?.satisfactionIndexRatio[1].actionSelected,
                        Experiencecenterwalkthrough: el?.satisfactionIndexRatio[2].actionSelected,
                        Productrangeoffered: el?.satisfactionIndexRatio[3].actionSelected,
                        Materialknowledgeofsalesperson: el?.satisfactionIndexRatio[4].actionSelected,
                        Budgetexpectationsmet: el?.satisfactionIndexRatio[5].actionSelected,
                        Professionalism: el?.satisfactionIndexRatio[6].actionSelected,
                        Approachability: el?.satisfactionIndexRatio[7].actionSelected,
                        Communication_Callsandfollowups: el?.satisfactionIndexRatio[8].actionSelected,
                        reasonToChooseDecorpot: el?.reasonToChooseDecorpot,
                        otherReasonToChooseDecorpot: el?.otherReasonToChooseDecorpot,
                    }
                    completestore.push(obj)
                })
            }
            setCsvData(completestore)

        } catch (error) {
            props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
        }
        props.setLoading(false)

    }

    const clearFilterDate = () => {
        setStartDate('');
        setEndDate('');
    }

    const downloadExcel = () => {
        props.setLoading(true)
        try {
            setTimeout(() => {
                buttonRef.current.link.click();
            });
        } catch (err) {
            console.error(err.message);
        }
        props.setLoading(false)
    }


    return (
        <div>
            <Paper className={classes.paper} style={{ textAlign: 'center', marginBottom: 20 }}>
                <Typography variant="h6" gutterBottom style={{ paddingTop: 10, fontSize: 27, fontWeight: 700 }}>
                    Customer Survey Forms
                </Typography>
                <TextField
                    id="date"
                    name="startDate"
                    label="From Date"
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    fullWidth
                    className={classes.textField}
                />
                <TextField
                    id="date"
                    name="endDate"
                    label="End Date"
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    fullWidth
                    className={classes.textField}
                />
                <Button variant="contained" color="primary" disabled={!startDate || !endDate} style={{ marginLeft: 8 }} onClick={getdataOnSelectedDate} >
                    Submit
                </Button>
                <Button variant="contained" color="secondary" style={{ margin: 8 }} onClick={() => clearFilterDate()} >
                    Clear All Filter
                </Button>
                {/* Download Excel has to check */}

                <Button onClick={downloadExcel} variant="contained" color="primary">
                    Download Excel
                </Button>
                <CSVLink
                    headers={headers}
                    data={csvData}
                    ref={buttonRef}
                />

                <FormControl component="fieldset" className={classes.root}>
                    <RadioGroup aria-label="type" name="type" value={radioValue} onChange={handleRadioChange}>
                        <div className={classes.radioButton}>
                            {
                               userDetails.roles.find(role => role.name === ROLENAME.Admin || role.name === ROLENAME.DataAnalyst ||  role.name === ROLENAME.CHM_Manager || role.name === ROLENAME.CHM_User || role.name === ROLENAME.DGM_Marketing) &&
                                <>
                                    <FormControlLabel value="real" control={<Radio />} label="Marketing - Real" />
                                    <FormControlLabel value="junk" control={<Radio />} label="Marketing - Junk" />
                                </>
                            }
                            {
                               userDetails.roles.find(role => role.name === ROLENAME.Admin || role.name === ROLENAME.DataAnalyst ||  role.name === ROLENAME.SalesHead || role.name === ROLENAME.SalesManager || role.name === ROLENAME.SalesUser || role.name === ROLENAME.AssistantSalesManager || role.name === ROLENAME.CHM_Manager || role.name === ROLENAME.CHM_User || role.name === ROLENAME.DGM_Sales) &&
                                <>
                                    <FormControlLabel value="won" control={<Radio />} label="Sales - Won" />
                                    <FormControlLabel value="lost" control={<Radio />} label="Sales - Lost" />
                                </>
                            }
                            {
                                userDetails.roles.find(role => role.name === ROLENAME.DesignHead || role.name === ROLENAME.DesignManager || role.name === ROLENAME.OperationDesignManager || role.name === ROLENAME.DesignUser || role.name === ROLENAME.AssistanceDesignManager || role.name === ROLENAME.AssistantDesignManager || role.name === ROLENAME.Admin || role.name === ROLENAME.DataAnalyst ||  role.name === ROLENAME.CHM_Manager || role.name === ROLENAME.CHM_User || role.name === ROLENAME.DGM_Design) &&
                                <div>
                                    <FormControlLabel value="design" control={<Radio />} label="Design" />
                                    <FormControlLabel value="design-lost" control={<Radio />} label="Design - Lost" />
                                </div>
                            }
                            {
                                userDetails.roles.find(role => role.name === ROLENAME.OperationManager || role.name === ROLENAME.Execution_Manager || role.name === ROLENAME.Admin ||  role.name === ROLENAME.DataAnalyst ||  role.name === ROLENAME.CHM_Manager || role.name === ROLENAME.CHM_User || role.name === ROLENAME.DGM_Design) &&
                                <FormControlLabel value="execution" control={<Radio />} label="Execution" />
                            }
                        </div>
                    </RadioGroup>
                </FormControl>
            </Paper>

            {
                radioValue === "real" &&
                <TableContainer component={Paper} className={classes.container}>
                    <Table className={classes.table} aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>Customer / Lead</TableCell>
                                <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>Project No</TableCell>
                                <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>Lead Owner</TableCell>
                                <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>Satisfaction with the interaction</TableCell>
                                <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>Feedback</TableCell>
                                <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>Nps Score</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {surveyFormData.map((row, i) => {
                                return (
                                    <>
                                        <TableRow key={i + 1}>
                                            <TableCell component="th" scope="row">
                                                <Button color="primary" onClick={() => showCustomerForm('real', row)} >
                                                    {row?.customerId?.name} - {row?.leadId?.lead_no}
                                                </Button>
                                            </TableCell>
                                            <TableCell component="th" scope="row" style={{ textAlign: "center" }}>
                                                <Link to={`/project-logs/${row?.leadId?._id}`} style={{ color: "#000", textDecoration: 'underline', textAlign: 'center' }}>{row?.leadId?.erpProjectNo}</Link>
                                            </TableCell>
                                            <TableCell component="th" scope="row">{row?.leadOwner.name}</TableCell>
                                            <TableCell component="th" scope="row">{row?.howSatisfiedWithInteraction}</TableCell>
                                            <TableCell component="th" scope="row">{row?.feedback}</TableCell>
                                            <TableCell component="th" scope="row">{row?.satisfactionavg}</TableCell>
                                        </TableRow>
                                    </>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            }

            {
                radioValue === "junk" &&
                <TableContainer component={Paper} className={classes.container}>
                    <Table className={classes.table} aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>Customer / Lead</TableCell>
                                <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>Project No</TableCell>
                                <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>Lead Owner</TableCell>
                                <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>Flat available for interior</TableCell>
                                <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>Budget For Interiors</TableCell>
                                <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>Feedback</TableCell>
                                <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>Conveyed Message</TableCell>
                                <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>Nps Score</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {surveyFormData.map((row, i) => {
                                return (
                                    <>
                                        <TableRow key={i + 1}>
                                            <TableCell component="th" scope="row">
                                                <Button color="primary" onClick={() => showCustomerForm('junk', row)} >
                                                    {row?.customerId?.name} - {row?.leadId?.lead_no}
                                                </Button>
                                            </TableCell>
                                            <TableCell component="th" scope="row" style={{ textAlign: "center" }}>
                                                <Link to={`/project-logs/${row?.leadId?._id}`} style={{ color: "#000", textDecoration: 'underline', textAlign: 'center' }}>{row?.leadId?.erpProjectNo}</Link>
                                            </TableCell>
                                            <TableCell component="th" scope="row">{row?.leadOwner.name}</TableCell>
                                            <TableCell component="th" scope="row">{row?.whenFlatAvailbleForInterior}</TableCell>
                                            <TableCell component="th" scope="row">{row?.budgetForInteriors}</TableCell>
                                            <TableCell component="th" scope="row">{row?.feedback}</TableCell>
                                            <TableCell component="th" scope="row">{row?.conveyedMessage}</TableCell>
                                            <TableCell component="th" scope="row">{row?.satisfactionavg}</TableCell>
                                        </TableRow>
                                    </>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            }

            {
                radioValue === "won" &&
                <TableContainer component={Paper} className={classes.container}>
                    <Table className={classes.table} aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>Customer / Lead</TableCell>
                                <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>Project No</TableCell>
                                <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>Lead Owner</TableCell>
                                <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>Visited Experience Center</TableCell>
                                <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>Reason to choose decorpot</TableCell>
                                <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>Comments</TableCell>
                                <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>Nps Score</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {surveyFormData.map((row, i) => {
                                return (
                                    <>
                                        <TableRow key={i + 1}>
                                            <TableCell component="th" scope="row">
                                                <Button color="primary" onClick={() => showCustomerForm('won', row)} >
                                                    {row?.customerId?.name} - {row?.leadId?.lead_no}
                                                </Button>
                                            </TableCell>
                                            <TableCell component="th" scope="row" style={{ textAlign: "center" }}>
                                                <Link to={`/project-logs/${row?.leadId?._id}`} style={{ color: "#000", textDecoration: 'underline', textAlign: 'center' }}>{row?.leadId?.erpProjectNo}</Link>
                                            </TableCell>
                                            <TableCell component="th" scope="row">{row?.leadOwner.name}</TableCell>
                                            <TableCell component="th" scope="row">{row?.isVisitedExperienceCenter === 'Yes' ? row?.visitedExperienceCenter : row?.isVisitedExperienceCenter}</TableCell>
                                            <TableCell component="th" scope="row">
                                                {row?.reasonToChooseDecorpot === 'Other' ? row?.otherReasonToChooseDecorpot : row?.reasonToChooseDecorpot}
                                            </TableCell>
                                            <TableCell component="th" scope="row">{row?.comments}</TableCell>
                                            <TableCell component="th" scope="row">{row?.satisfactionavg}</TableCell>
                                        </TableRow>
                                    </>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            }

            {
                radioValue === "lost" &&
                <TableContainer component={Paper} className={classes.container}>
                    <Table className={classes.table} aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>Customer / Lead</TableCell>
                                <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>Project No</TableCell>
                                <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>Lead Owner</TableCell>
                                <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>Visited Experience Center</TableCell>
                                <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>Reason not to choose decorpot</TableCell>
                                <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>Comments</TableCell>
                                <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>Nps Score</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {surveyFormData.map((row, i) => {
                                return (
                                    <>
                                        <TableRow key={i + 1}>
                                            <TableCell component="th" scope="row">
                                                <Button color="primary" onClick={() => showCustomerForm('lost', row)} >
                                                    {row?.customerId?.name} - {row?.leadId?.lead_no}
                                                </Button>
                                            </TableCell>
                                            <TableCell component="th" scope="row" style={{ textAlign: "center" }}>
                                                <Link to={`/project-logs/${row?.leadId?._id}`} style={{ color: "#000", textDecoration: 'underline', textAlign: 'center' }}>{row?.leadId?.erpProjectNo}</Link>
                                            </TableCell>
                                            <TableCell component="th" scope="row">{row?.leadOwner.name}</TableCell>
                                            <TableCell component="th" scope="row">{row?.isVisitedExperienceCenter === 'Yes' ? row?.invitedExperienceCenter : row?.isVisitedExperienceCenter}</TableCell>
                                            <TableCell component="th" scope="row">
                                                {row?.reasonForNotChoosingDecorpot === 'Other' ? row?.otherReasonForNotChoosingDecorpot : row?.reasonForNotChoosingDecorpot}
                                            </TableCell>
                                            <TableCell component="th" scope="row">{row?.comments}</TableCell>
                                            <TableCell component="th" scope="row">{row?.satisfactionavg}</TableCell>
                                        </TableRow>
                                    </>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            }

            {
                radioValue === "design" &&
                <TableContainer component={Paper} className={classes.container}>
                    <Table className={classes.table} aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>Customer / Lead</TableCell>
                                <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>Project No</TableCell>
                                <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>Experience Center</TableCell>
                                <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>Lead Owner</TableCell>
                                <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>CHM Name</TableCell>
                                <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>Design Manager Involved</TableCell>
                                <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>Feedback</TableCell>
                                <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>Nps Score</TableCell>
                                <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>Manager Nps Score</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {surveyFormData.map((row, i) => {
                                return (
                                    <>
                                        <TableRow key={i + 1}>
                                            <TableCell component="th" scope="row">
                                                <Button color="primary" onClick={() => showCustomerForm('design', row)} >
                                                    {row?.customerId?.name} - {row?.leadId?.lead_no}
                                                </Button>
                                            </TableCell>
                                            <TableCell component="th" scope="row" style={{ textAlign: "center" }}>
                                                <Link to={`/project-logs/${row?.leadId?._id}`} style={{ color: "#000", textDecoration: 'underline', textAlign: 'center' }}>{row?.leadId?.erpProjectNo}</Link>
                                            </TableCell>
                                            <TableCell component="th" scope="row">{row?.leadId?.experienceCenterId?.name}</TableCell>
                                            <TableCell component="th" scope="row">{row?.leadOwner?.name}</TableCell>
                                            <TableCell component="th" scope="row">{row?.chmId?.assignTo?.name}</TableCell>
                                            <TableCell component="th" scope="row">{row?.isDesignManagerInvolved}</TableCell>
                                            <TableCell component="th" scope="row">{row?.feedback}</TableCell>
                                            <TableCell component="th" scope="row">{row?.satisfactionavg}</TableCell>
                                            <TableCell component="th" scope="row">{row?.designManagerAvg ? row?.designManagerAvg : "NA"}</TableCell>
                                        </TableRow>
                                    </>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            }

            {
                radioValue === "design-lost" &&
                <TableContainer component={Paper} className={classes.container}>
                    <Table className={classes.table} aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>Customer / Lead</TableCell>
                                <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>Project No</TableCell>
                                <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>Lead Owner</TableCell>
                                <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>CHM Name</TableCell>
                                <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>Design Manager Involved</TableCell>
                                <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>Feedback</TableCell>
                                <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>Nps Score</TableCell>
                                <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>Manager Nps Score</TableCell>
                                <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>Chm Nps Score</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {surveyFormData.map((row, i) => {
                                return (
                                    <>
                                        <TableRow key={i + 1}>
                                            <TableCell component="th" scope="row">
                                                <Button color="primary" onClick={() => showCustomerForm('design-lost', row)} >
                                                    {row?.customerId?.name} - {row?.leadId?.lead_no}
                                                </Button>
                                            </TableCell>
                                            <TableCell component="th" scope="row" style={{ textAlign: "center" }}>
                                                <Link to={`/project-logs/${row?.leadId?._id}`} style={{ color: "#000", textDecoration: 'underline', textAlign: 'center' }}>{row?.leadId?.erpProjectNo}</Link>
                                            </TableCell>
                                            <TableCell component="th" scope="row">{row?.leadOwner.name}</TableCell>
                                            <TableCell component="th" scope="row">{row?.chmId?.assignTo?.name}</TableCell>
                                            <TableCell component="th" scope="row">{row?.isDesignManagerInvolved}</TableCell>
                                            <TableCell component="th" scope="row">{row?.feedback}</TableCell>
                                            <TableCell component="th" scope="row">{row?.satisfactionavg}</TableCell>
                                            <TableCell component="th" scope="row">{row?.designManagerAvg ? row?.designManagerAvg : "NA"}</TableCell>
                                            <TableCell component="th" scope="row">{row?.chmAvg ? row?.chmAvg : "NA"}</TableCell>
                                        </TableRow>
                                    </>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            }

            {
                radioValue === "execution" &&
                <TableContainer component={Paper} className={classes.container}>
                    <Table className={classes.table} aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>Customer / Lead</TableCell>
                                <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>Project No</TableCell>
                                <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>Lead Owner</TableCell>
                                <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>CHM Name</TableCell>
                                <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>Satisfaction with the execution and delivery</TableCell>
                                <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>Satisfaction with the execution team</TableCell>
                                <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>Vendor Feedback</TableCell>
                                <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>Feedback</TableCell>
                                <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>Nps Score</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {surveyFormData.map((row, i) => {
                                return (
                                    <>
                                        <TableRow key={i + 1}>
                                            <TableCell component="th" scope="row">
                                                <Button color="primary" onClick={() => showCustomerForm('execution', row)} >
                                                    {row?.customerId?.name} - {row?.leadId?.lead_no}
                                                </Button>
                                            </TableCell>
                                            <TableCell component="th" scope="row" style={{ textAlign: "center" }}>
                                                <Link to={`/project-logs/${row?.leadId?._id}`} style={{ color: "#000", textDecoration: 'underline', textAlign: 'center' }}>{row?.leadId?.erpProjectNo}</Link>
                                            </TableCell>
                                            <TableCell component="th" scope="row">{row?.leadOwner.name}</TableCell>
                                            <TableCell component="th" scope="row">{row?.chmId?.assignTo?.name}</TableCell>
                                            <TableCell component="th" scope="row">{row?.howSatisfiedWithExecutionAndDelivery}</TableCell>
                                            <TableCell component="th" scope="row">{row?.howSatisfiedWithExecutionTeam}</TableCell>
                                            <TableCell component="th" scope="row">{row?.feedbackForSpecificVendor}</TableCell>
                                            <TableCell component="th" scope="row">{row?.additionalFeedback}</TableCell>
                                            <TableCell component="th" scope="row">{row?.satisfactionavg}</TableCell>
                                        </TableRow>
                                    </>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </div>
    )
}

export default connect(null, { setLoading, setSnackInfo })(ViewCustomerSurveyForms)