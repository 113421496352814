import React, { useState, useEffect, useCallback, useRef } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { setLoading, setSnackInfo } from "../redux/actions/appAction";
import HttpService from "../services/httpService";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import { useSelector } from 'react-redux';
import InfoIcon from '@material-ui/icons/Info';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Select,
} from "@material-ui/core";
import moment from "moment";
import TablePagination from "@material-ui/core/TablePagination";
import { Button, Tooltip } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { TextField } from "@material-ui/core";
import { CSVLink } from "react-csv";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import EditIcon from "@material-ui/icons/Edit";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import { commaSeparateAmount } from './commaSeparate';
import { ConsoleSqlOutlined } from "@ant-design/icons";


const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 850,
  },
  tableHeader:{
    position: 'sticky',
    top: 0,
    zIndex: 1
  },
  textField: {
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(5),
    width: 300,
  },
  paper: {
    padding: "2rem 2rem 2rem 2rem",
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  radioButton: {
    // padding:20,
    marginLeft: "20px",
  },
  editButton: { backgroundColor: 'white', color: '#3d444f', padding: '1', marginRight: '0.5em', borderRadius: '20px', "&:hover": { backgroundColor: "#3d444f", color: 'white' } },
  approveButton: { backgroundColor: 'white', color: '#157525', padding: '1', marginRight: '0.5em', marginLeft: '0.5em', borderRadius: '20px', "&:hover": { backgroundColor: "#157525", color: 'white' } },
  rejectButton: { backgroundColor: 'white', color: '#9c261e', padding: '1', marginRight: '0.5em', borderRadius: '20px', "&:hover": { backgroundColor: "#9c261e", color: 'white' } },
  yesButton: { backgroundColor: 'white', color: '#14419c', padding: '1', marginRight: '0.5em', marginLeft: '0.5em', borderRadius: '20px', "&:hover": { backgroundColor: "#14419c", color: 'white' } },
  noButton: { backgroundColor: 'white', color: '#151921', padding: '1', marginRight: '0.5em', borderRadius: '20px', "&:hover": { backgroundColor: "#151921", color: 'white' } },
  updateButton: { backgroundColor: 'white', color: '#157525', padding: '1', margin: 'auto', borderRadius: '20px', "&:hover": { backgroundColor: "#157525", color: 'white' } },
  financeActionRemarks: { margin: 'auto', width: '100%' }
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#ccc",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const PaymentDetails = (props) =>
{
  const { userDetails } = useSelector(state => state.user)
  const classes = useStyles();
  
  const buttonRef = useRef(null);
  
  const [paymentInfo, setPaymentInfo] = useState([]);
  const [departmentWiseUser,setDepartmentWiseUser] = useState([])
  const [departmentList, setDepartmentsList] = useState([]);
  const [paymentModes, setPaymentMode] = useState([]);
  const [paymentStage, setPaymentStage] = useState([]);
  
  const [datefilter, setDatefilter] = useState({ startDate: "", endDate: "" });
  const [searchQuery, setSearchQuery] = useState("");
  const [searchStageWise, setStageWiseFilter] = useState("");
  const [financeActionStatus, setFinanceActionStatus] = useState("");
  const [financeActionRemarks, setFinanceActionRemarks] = useState("");
  
  const [financeActionPaymentDetails, setFinanceActionPaymentDetails] = useState({});
  
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  
  const [editProjectModal, setEditProjectModal] = useState(false);
  const [openFinanceActionModal, setOpenFinanceActionModal] = useState(false);
  
  const [storeEditDetails, setstoreEditDetails] = useState({
    _id: "",
    // customerName: "",
    // uniqueLeadNumber: "",
    // transactionNumber: "",
    createdAt: "",
    Amount: "",
    Status: "",
    Stage: "",
    createdBy: "",
    // departmentName: "",
    departmentId: "",
    paymentMode: "",
    // paymentModeId: "",
    // paymentPartnerName: "",
    finance_remarks: ""
  });

  const [isHovered, setIsHovered] = useState(false);
  const handleHover = (data, type) => {

    console.log('handleHover called')
    console.log('type', type)
    console.log('data', data)
    let prop = ''
    if(type == 'createdAt')
    {
      console.log('createdAt type')
      prop = 'paymentDate'
    }
    else if(type == 'Amount')
    {
      console.log('Amount type')
      prop = 'amount'
    }
    else if(type == 'paymentMode')
    {
      console.log('paymentMode type')
      prop = 'paymentMode'
    }
    else if(type == 'departmentName')
    {
      console.log('departmentName type')
      prop = 'departmentId'
    }
    else if(type == 'createdByUser')
    {
      console.log('createdByUser type')
      prop = 'PaymentUser'
    }
    else if(type == 'Status')
    {
      console.log('Status type')
      prop = 'Status'
    }
    else if(type == 'Stage')
    {
      console.log('Stage type')
      prop = 'Stage'
    }
    else
    {
      console.log('Other type')
    }

    setPaymentLogsData([])
    let dataToSet = []
    let paymentLogs = data.paymentLogs
    for(let i = 0; i < paymentLogs.length; i++)
    {
      // console.log('i', i, paymentLogs[i])
      // console.log('date', paymentLogs[i]['createdAt'], 'value', paymentLogs[i]['paymentMode'])
      let obj = {}
      if(prop == 'departmentId' || prop == 'PaymentUser')
      {
        obj = {date: moment(paymentLogs[i]['createdAt']).format("DD-MM-YYYY hh:mm A"), value: paymentLogs[i][prop]['name'], finance_remarks: paymentLogs[i]['finance_remarks']}
      }
      else if(prop == 'paymentDate')
      {
        obj = {date: moment(paymentLogs[i]['createdAt']).format("DD-MM-YYYY hh:mm A"), value: moment(paymentLogs[i][prop]).format('DD-MM-YYYY'), finance_remarks: paymentLogs[i]['finance_remarks']}
      }
      else
      {
        obj = {date: moment(paymentLogs[i]['createdAt']).format("DD-MM-YYYY hh:mm A"), value: paymentLogs[i][prop], finance_remarks: paymentLogs[i]['finance_remarks']}
      }
      dataToSet.push(obj)
    }
    setPaymentLogsData([...dataToSet])
    setIsHovered(true);
    // You can perform additional actions here when hovering starts
  };

  const handleUnhover = () => {
    console.log('handleUnhover called')
    setIsHovered(false);
    setPaymentLogsData([])
    // You can perform additional actions here when hovering ends
  };

  const [paymentLogsData, setPaymentLogsData] = useState(
    [
      // { date: '2023-09-25', value: 100 },
      // { date: '2023-09-26', value: 150 },
      // { date: '2023-09-27', value: 200 }
    ]);

  const tableContent = (
    <table>
      <thead>
        <tr>
          <th>Date</th>
          <th>Value</th>
          <th>Remarks</th>
        </tr>
      </thead>
      <tbody>
        {paymentLogsData.map((item, index) => (
          <tr key={index}>
            <td>{item.date}</td>
            <td>{item.value}</td>
            <td>{item.finance_remarks}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
  
  // const {
  //   customerName,
  //   uniqueLeadNumber,
  //   transactionNumber,
  //   createdAt,
  //   Amount,
  //   Status,
  //   Stage,
  //   createdByUser,
  //   createdBy,
  //   departmentName,
  //   departmentId,
  //   paymentMode,
  //   paymentModeId,
  //   paymentPartnerName,
  //   finance_remarks
  // } = storeEditDetails;

  let storeSearch = ["Cash", "CARD"];

  const paymentMethod = useCallback(async () =>
  {
    try
    {
      const respo = await HttpService.getAllPaymentModes();
      
      setPaymentMode(respo.data);
    }
    catch (error)
    {
      props.setSnackInfo(error?.response?.data ?? "Something went wrong","error");
    }
  },[])

  const getSalesDesginUserDetail = useCallback(async () =>
  {
    try
    {
      const respo = await HttpService.getSalesDesginUserDetail();
      
      console.log('respo', respo.data.data)
      setDepartmentWiseUser(respo.data.data);
    }
    catch (error)
    {
      props.setSnackInfo(error?.response?.data ?? "Something went wrong","error");
    }
  },[])

  const stages = useCallback(async () =>
  {
    try
    {
      const res = await HttpService.getSalesDesignStages();
      
      setPaymentStage(res.data.stages);
    }
    catch (error)
    {
      props.setSnackInfo(error?.response?.data ?? "Something went wrong","error");
    }
  },[])

  const handle_edit_payment_cancel = () =>
  {
    try
    {
      console.log('handle_edit_payment_cancel function called')
      setEditProjectModal(false)

    }
    catch(error)
    {
      console.log('error', error.message)
      console.error(error)
    }
  }

  const updateDetails = async () =>
  {
    props.setLoading(true)
    try
    {
      setEditProjectModal(false)
      console.log('storeEditDetails', storeEditDetails)

      let response = await HttpService.editPaymentTransaction(storeEditDetails)

      console.log('response', response.data)

      setstoreEditDetails(
        {
        _id: "",
        customerName: "",
        uniqueLeadNumber: "",
        transactionNumber: "",
        createdAt: "",
        Amount: "",
        Status: "",
        Stage: "",
        createdBy: "",
        departmentName: "",
        departmentId: "",
        paymentMode: "",
        paymentModeId: "",
        paymentPartnerName: "",
        finance_remarks: ""
      })
      

      getPaymentAllPreview();

      props.setSnackInfo(`${storeEditDetails.transactionNumber} has been update successfully.`, 'success')
    }
    catch (error)
    {
      console.log('error', error.message)
      props.setSnackInfo(error?.response?.data ?? "Something went wrong","error");
    }
    props.setLoading(false)
  }


  const handleSearchChange = (e) =>
  {
    const selecStage = e.target.value;
    
    setStageWiseFilter(selecStage);
  };

  const handleDateChange = (e) =>
  {
    const { name, value } = e.target;
  
    setDatefilter({ ...datefilter, [name]: value });
  };

  const clearDateFilter = (e) =>
  {
    setDatefilter({ startDate: "", endDate: "" });

    setStageWiseFilter("")

    getPaymentAllPreview("")

    setSearchQuery("")
  };

  const getDepartments = useCallback(async () =>
  {
    try
    {
      props.setLoading(true);
      
      const department = await HttpService.getDepartments();
      
      let dept = [];
      
      department.data.forEach((ele) => {dept.push({ name: ele.name, _id: ele._id })});
      
      console.log('dept', dept)
      setDepartmentsList(dept);
    }
    catch (error)
    {
      console.log(error, "get department error");
    }
    props.setLoading(false);
  },[])

  const handle_finance_action = useCallback((data,value) =>
  {
    console.log('data', data)
    console.log('value', value)

    setFinanceActionStatus(value)
    setFinanceActionPaymentDetails(data)
    setOpenFinanceActionModal(true)

  },[])

  const handle_finance_confirmation = async (value) => 
  {
    props.setLoading(true)
    console.log('value', value)

    try
    {
      setOpenFinanceActionModal(false)
    
      console.log('financeActionStatus', financeActionStatus)
      console.log('financeActionRemarks', financeActionRemarks)
      console.log('_id', financeActionPaymentDetails._id)
      console.log('now you can proceed')

      if(value === 'Yes')
      {
        let obj =
        {
          _id: financeActionPaymentDetails._id, financeActionStatus, financeActionRemarks
        }

        const result = await HttpService.updateFinancePaymentAction(obj)
        
        console.log('status', result.status)
        console.log('result', result)

        if(result.status == 200)
        {
          props.setSnackInfo(result.data, 'success')
        }
        
      }

      setFinanceActionStatus('')
      setFinanceActionRemarks('')
      setFinanceActionPaymentDetails({})
      getPaymentAllPreview()
    }
    catch(error)
    {
      console.log('error', error.message)
      console.error(error)
    }
    props.setLoading(false)

    

    
  }

  // const cancel_finance_action = () => 
  // {
    // console.log('financeActionName', financeActionName)
    // console.log('financeActionData', financeActionData)
    // console.log('financeRemarks', financeRemarks)
    
    // setFinanceActionName('')
    
    // setFinanceActionData({})
    
    // setFinanceRemarks('')
    
    // setFinanceActionModal(false)
    
  //   console.log('cleared states')
  // }

  

  const getPaymentAllPreview = useCallback(async (searchQueries) =>
    {
      props.setLoading(true);
      
      const obj =
      {
        startDate: datefilter.startDate,
        endDate: datefilter.endDate,
        selectedPaymentSearch: searchStageWise.toString() ? searchStageWise.toString() : "",
        page: page + 1,
        searchCustomerName: searchQueries ? searchQueries : "",
        rowsPerPage: rowsPerPage,
      };

      try
      {
        let user = await HttpService.getPaymentAllPreview(obj);
        
        console.log("getPaymentAllPreview data", user.data);
        
        setTotalPages(user.data.totalLength);
        
        let paymentDetails = [];
        
        user?.data?.paymentTransactions?.forEach((el, i) =>
        {
          let obj =
          {
            _id: el._id,
            leadId: el.leadId,
            customerName: el?.customerName ? el.customerName : "NA",
            newUniqueId: el?.leadId?.newUniqueLeadId ? el?.leadId?.newUniqueLeadId : "NA",
            uniqueLeadNumber: el?.leadId?.uniqueLeadNumber ? el?.leadId?.uniqueLeadNumber : "NA",
            transactionNumber: el?.cashTransactionNumber ? el.cashTransactionNumber : el?.paymentLogsId?.TransactionNumber ? el?.paymentLogsId?.TransactionNumber : "NA",
            createdAt: el?.createdAt ? moment(el?.createdAt).format("DD-MM-YYYY") : "-",
            Amount: el?.Amount ? el.Amount : 0,
            Status: el?.Status ? el.Status : "NA",
            Stage: el?.Stage ? el?.Stage : "NA",
            createdByUser: el?.createdBy.name ? el?.createdBy.name : "NA",
            createdBy: el?.createdBy?._id ? el?.createdBy?._id : "NA",
            departmentName: el?.departmentId?.name ? el?.departmentId.name : "NA",
            departmentId: el?.departmentId?._id ? el?.departmentId?._id : "NA",
            paymentMode: el?.selectedPaymentMode ? el?.selectedPaymentMode : el?.TransactionData?.map((transaction, i) => transaction.Tag === "PaymentMode" ? transaction.Value : null ).filter((payment) => payment !== null),
            slip_no: el.slip_no ? el.slip_no : 'NA',
            trxId: el.trxId ? el.trxId : 'NA',
            cheQueNo: el.cheQueNo ? el.cheQueNo : 'NA',
            paymentPartnerName: el?.paymentPatnerId?.name ? el?.paymentPatnerId?.name : "NA",
            financeAction: el.financeAction ? el.financeAction : 'NA',
            financeActionStatus: el.financeActionStatus,
            financeActionRemarks: el.financeActionRemarks,
            financeEditRemarks: el.financeEditRemarks,
            paymentLogs : el.paymentLogs ? el.paymentLogs : []
          };
          console.log('obj', obj)
          paymentDetails.push(obj);
        
        });

        console.log('paymentDetails', paymentDetails)
        
        setPaymentInfo(paymentDetails);
      }
      catch (error)
      {
        console.log(error);
      }

      props.setLoading(false);

    },[datefilter, page, searchStageWise, rowsPerPage]);

  const headers =
  [
    { key: "customerName", label: "Customer Name" },
    { key: "uniqueLeadNumber", label: "Customer Unique Id" },
    { key: "transactionNumber", label: "Transaction Number" },
    { key: "createdAt", label: "Payment Date" },
    { key: "Amount", label: "Amount" },
    { key: "Status", label: "Status" },
    { key: "Stage", label: "Stage" },
    { key: "createdByUser", label: "Created By" },
    { key: "departmentName", label: "Department Name" },
    { key: "paymentMode", label: "Payment Mode" },
    { key: "paymentPartnerName", label: "Vendor Name" },
  ];

  const handleChangePage = async (event, newPage) =>
  {
    props.setLoading(true)

    try
    {
      setPage(newPage);
  
      await getPaymentAllPreview(newPage);
    }
    catch(error)
    {
      console.error(error.message);
    }
    props.setLoading(false)

  };

  const handleChangeRowsPerPage = async (event) =>
  {
    props.setLoading(true)

    try
    {
      setRowsPerPage(+event.target.value);
  
      setPage(0);
    
      setPaymentInfo([]);
    }
    catch(error)
    {
      console.error(error.message);
    }
    props.setLoading(false)
  };
  
  const downloadExcel = () =>
  {
    props.setLoading(true);
  
    try
    {
      setTimeout(() => {buttonRef.current.link.click();});
    }
    catch (err)
    {
      console.error(err.message);
    }

    props.setLoading(false);
  };

  const editPaymentDetails = (row) =>
  {
    console.log('row', row)
    
    setstoreEditDetails({
      _id: row._id,
      leadId: row.leadId,
      customerName: row.customerName,
      uniqueLeadNumber: row.uniqueLeadNumber,
      transactionNumber: row.transactionNumber,
      createdAt: moment(row.createdAt, "DD-MM-YYYY").format("YYYY-MM-DD"),
      Amount: row.Amount,
      Status: row.Status,
      Stage: row.Stage,
      // createdByUser: row.createdByUser,
      createdBy : row.createdBy,
      // departmentName: row.departmentName,
      departmentId: row.departmentId,
      paymentMode: row.paymentMode,
      paymentPartnerName: row.paymentPartnerName,
      finance_remarks: row.finance_remarks
    });
    
    setEditProjectModal(true);
  };

  const changeHandler = (e) =>
  {
    console.log('name', e.target.name)
    console.log('value', e.target.value)
    setstoreEditDetails({...storeEditDetails,[e.target.name]: e.target.value,});
  };

  const filterSearch = (e) =>
  {
    setSearchQuery(e.target.value)
  };
  
  const filterValue = (e) =>
  {
    if (e.keyCode === 13)
    {
      getPaymentAllPreview(searchQuery);
    }
  }

  useEffect(() => {
    getPaymentAllPreview();
    getDepartments();
    paymentMethod();
    getSalesDesginUserDetail()
    stages();
  },[getPaymentAllPreview, searchStageWise]);

  const show_payment_log = (data) =>
  {
    console.log('show_payment_log function called, data', data)
  }

  // console.log(rowsPerPage+"this is"+totalPages+"this is length")


  return (
    <div>
      
      <Paper className={classes.paper}>
        
        <Grid container style={{ display: "flex", alignItems: "center" }}>
          
          <Grid item md={3} xs={12}>
            
            <TextField
              id="startDate"
              name="startDate"
              label="From Date"
              type="date"
              value={editPaymentDetails.createdAt}
              onChange={handleDateChange}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              className={classes.textField}
            />

          </Grid>

          <Grid item md={3} xs={12}>
            
            <TextField
              id="endDate"
              name="endDate"
              label="To Date"
              type="date"
              value={datefilter.endDate}
              onChange={handleDateChange}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              className={classes.textField}
            />

          
          </Grid>

          <Grid item md={3} xs={12}>
            
            <Button
              variant="contained"
              color="secondary"
              style={{ padding: "0.5rem 2rem 0.5rem 2rem", marginTop: "5px" }}
              onClick={clearDateFilter}
            >
              Clear Filter
            </Button>

          </Grid>

          <Grid item md={3} xs={12}>
            
            <Button
              onClick={downloadExcel}
              style={{
                padding: "0.5rem 2rem 0.5rem 2rem",
                marginTop: "5px",
                background: "green",
                color: "white",
              }}
            >
              Download Excel
            </Button>
            
            <CSVLink headers={headers} data={paymentInfo} ref={buttonRef} />
          
          </Grid>

        </Grid>

        <Grid container style={{ display: "flex", alignItems: "center" }}>
          
          <Grid style={{ padding: "2rem 0px 0px 3rem" }} item md={3} xs={12}>
            
            <TextField
              id="searchQuery"
              name="searchQuery"
              placeholder="Search Project"
              type="text"
              label="Search Project"
              variant="outlined"
              onChange={filterSearch}
              onKeyDown={filterValue}
              value={searchQuery}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              className={classes.textFields}
            />

          </Grid>

          <Grid style={{ padding: "2rem 0px 0px 3rem" }} item md={3} xs={12}>
            
            <TextField
              select
              name="searchQuery"
              placeholder="Search Stage Wise"
              type="text"
              label="Search Stage Wise"
              variant="outlined"
              onChange={handleSearchChange}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              className={classes.textFields}
              value={searchStageWise}
            >
              {paymentStage.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>

          </Grid>

        </Grid>

      </Paper>
      <br />

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 100, 250, 500]}
          colSpan={20}
          count={totalPages}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: true,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>


      <TableContainer component={Paper}>
        
        
        <Table className="table table-responsive table-bordered" aria-label="sticky table">
          
        <TableHead className={classes.tableHeader}>

            <StyledTableRow>
              <TableCell style={{ fontWeight: "800", textAlign:"center" }}>S.No</TableCell>
              <TableCell style={{ fontWeight: "800", textAlign:"center" }}>Project Unique Id</TableCell>
              <TableCell style={{ fontWeight: "800", textAlign:"center" }}>Client Name</TableCell>
              <TableCell style={{ fontWeight: "800", textAlign:"center" }}>Payment Date</TableCell>
              <TableCell style={{ fontWeight: "800", textAlign:"center" }}>Transaction ID</TableCell>
              <TableCell style={{ fontWeight: "800", textAlign:"center" }}>Amount</TableCell>
              <TableCell style={{ fontWeight: "800", textAlign:"center" }}>Payment Mode</TableCell>
              <TableCell style={{ fontWeight: "800", textAlign:"center" }}>Department</TableCell>
              <TableCell style={{ fontWeight: "800", textAlign:"center" }}>Requested By</TableCell>
              <TableCell style={{ fontWeight: "800", textAlign:"center" }}>Status</TableCell>
              <TableCell style={{ fontWeight: "800", textAlign:"center" }}>Stage</TableCell>
              <TableCell style={{ fontWeight: "800", textAlign:"center" }}>Payment Partners</TableCell>
              <TableCell style={{ fontWeight: "800", textAlign:"center" }}>Finance Action Status</TableCell>
              <TableCell style={{ fontWeight: "800", textAlign:"center" }}>Finance Action Remarks</TableCell>
              {/* <TableCell style={{ fontWeight: "800", textAlign:"center" }}>Payment Edit Remarks</TableCell> */}
              <TableCell style={{ fontWeight: "800", textAlign:"center" }}>Action</TableCell>
            </StyledTableRow>
            
            </TableHead>

            <TableBody>

            {paymentInfo.map((row, i) => {
              return (
                <TableRow key={i + 1}>

                  <TableCell style={{textAlign:"center" }}>{i + 1}</TableCell>
                  <TableCell style={{textAlign:"center" }} component="th" scope="row">
                    <Link to={`/project-logs/${row.leadId._id}`} style={{ color: "#000", textDecoration: 'underline' }}>{row?.uniqueLeadNumber}</Link>
                  </TableCell>
                  <TableCell style={{textAlign:"center" }} component="th" scope="row">{row?.customerName}</TableCell>
                  
                  <TableCell onMouseEnter={() => handleHover(row, 'createdAt')} onMouseLeave={handleUnhover} style={{textAlign:"center"}} component="th" scope="row">
                    {row?.createdAt}
                    <Tooltip placement='top' title={isHovered ? tableContent : ''} arrow>
                      <InfoIcon style={{ cursor: 'pointer' }} fontSize="small" color="primary" onClick={() => show_payment_log(row)} />
                    </Tooltip>
                  </TableCell>
                  
                  <TableCell style={{textAlign:"center" }} component="th" scope="row">{row.paymentMode == 'Cash' ? row.slip_no : (row.paymentMode == 'Credit Fare' || row.paymentMode == 'UPI others') ? row.trxId : row.paymentMode == 'Cheque' ? row.cheQueNo : row.transactionNumber}</TableCell>

                  <TableCell onMouseEnter={() => handleHover(row, 'Amount')} onMouseLeave={handleUnhover} style={{textAlign:"center" }} component="th" scope="row">
                    ₹ {commaSeparateAmount(row?.Amount)}
                    <Tooltip placement='top' title={isHovered ? tableContent : ''} arrow>
                      <InfoIcon style={{ cursor: 'pointer' }} fontSize="small" color="primary" onClick={() => show_payment_log(row)} />
                    </Tooltip>
                  </TableCell>

                  <TableCell onMouseEnter={() => handleHover(row, 'paymentMode')} onMouseLeave={handleUnhover} style={{textAlign:"center" }} component="th" scope="row">
                    {row?.paymentMode}
                    <Tooltip placement='top' title={isHovered ? tableContent : ''} arrow>
                      <InfoIcon style={{ cursor: 'pointer' }} fontSize="small" color="primary" onClick={() => show_payment_log(row)} />
                    </Tooltip>
                  </TableCell>

                  <TableCell onMouseEnter={() => handleHover(row, 'departmentName')} onMouseLeave={handleUnhover} style={{textAlign:"center"}} component="th" scope="row">
                    {row?.departmentName}
                    <Tooltip placement='top' title={isHovered ? tableContent : ''} arrow>
                      <InfoIcon style={{ cursor: 'pointer' }} fontSize="small" color="primary" onClick={() => show_payment_log(row)} />
                    </Tooltip>
                  </TableCell>

                  <TableCell onMouseEnter={() => handleHover(row, 'createdByUser')} onMouseLeave={handleUnhover} style={{textAlign:"center" }} component="th" scope="row">
                    {row.createdByUser}
                    <Tooltip placement='top' title={isHovered ? tableContent : ''} arrow>
                      <InfoIcon style={{ cursor: 'pointer' }} fontSize="small" color="primary" onClick={() => show_payment_log(row)} />
                    </Tooltip>
                  </TableCell>

                  <TableCell onMouseEnter={() => handleHover(row, 'Status')} onMouseLeave={handleUnhover} style={{textAlign:"center" }} component="th" scope="row">
                    {row?.Status === "successful" ? "Successful" : row?.Status === "cancel" ? "Cancel" : ""}
                    <Tooltip placement='top' title={isHovered ? tableContent : ''} arrow>
                      <InfoIcon style={{ cursor: 'pointer' }} fontSize="small" color="primary" onClick={() => show_payment_log(row)} />
                    </Tooltip>
                  </TableCell>

                  <TableCell onMouseEnter={() => handleHover(row, 'Stage')} onMouseLeave={handleUnhover} style={{textAlign:"center" }} component="th" scope="row">
                    {row?.Stage}
                    <Tooltip placement='top' title={isHovered ? tableContent : ''} arrow>
                      <InfoIcon style={{ cursor: 'pointer' }} fontSize="small" color="primary" onClick={() => show_payment_log(row)} />
                    </Tooltip>
                  </TableCell>
                  
                  <TableCell style={{textAlign:"center" }} component="th" scope="row">{row?.paymentPartnerName}</TableCell>
                  <TableCell style={{textAlign:"center" }} component="th" scope="row">{row?.financeActionStatus}</TableCell>
                  <TableCell style={{textAlign:"center" }} component="th" scope="row">{row?.financeActionRemarks}</TableCell>
                  
                  <TableCell component="th" scope="row" style={{ borderRight: "0px", alignItems: "center" }}>
                    <div style={{ marginLeft: "auto", display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Tooltip title="Edit" placement="top"><Button disabled={row?.financeActionStatus !== 'Pending'} className={classes.editButton} variant="contained" onClick={() => editPaymentDetails(row)}><EditIcon /></Button></Tooltip>
                      {/* <EditIcon disabled={row?.financeActionStatus !== 'Pending'} onClick={() => editPaymentDetails(row)} style={{ cursor: "pointer" }} /> */}
                      <Tooltip title="Approve" placement="top"><Button disabled={row?.financeActionStatus !== 'Pending'} className={classes.approveButton} variant="contained" onClick={() => handle_finance_action(row, "Approved")}><CheckIcon /></Button></Tooltip>
                      <Tooltip title="Reject" placement="top"><Button disabled={row?.financeActionStatus !== 'Pending'} className={classes.rejectButton} variant="contained" onClick={() => handle_finance_action(row, "Rejected")}><ClearIcon /></Button></Tooltip>
                    </div>
                  </TableCell>

                  
                  {/* <TableCell component="th" scope="row" style={{borderLeft:"0px", borderRight:"0px"}}>
                  </TableCell>

                  <TableCell component="th" scope="row" style={{borderLeft:"0px"}}>
                  </TableCell> */}

                
                  
                  {/* <TableCell component="th" scope="row" style={{borderLeft:"0px", borderRight:"0px", display: 'inline', justifyContent: 'space-between'}}>
                    <EditIcon onClick={() => editPaymentDetails(row)} style={{ cursor: "pointer" }} />
                     <Button style={{float:"right",padding:"0.6rem 2rem 0.6rem 2rem", background: "green",color: "white"}} variant="contained" onClick={()=>handle_finance_action(row,"approved")}><CheckIcon/></Button>
                     <Button style={{float:"right",padding:"0.6rem 2rem 0.6rem 2rem"}} variant="contained" color="secondary" onClick={()=>handle_finance_action(row,"cancel")}><ClearIcon/></Button>
                  </TableCell> */}
                  
                  

                </TableRow>
              );
            })}
          </TableBody>
          
        </Table>

      </TableContainer>

      {/* Start - Dialog for Finance Action on Payments */}
      <Dialog
        open={openFinanceActionModal}
        onClose={() => setOpenFinanceActionModal(false)}
        fullWidth
        // maxWidth={"md"}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="form-dialog-title" style={{ margin: "auto" }}>
          Are You Sure, {userDetails.name} ?
        </DialogTitle>
        <p style={{color: 'red', margin: "auto"}}>** Payment will be marked as {financeActionStatus} **</p>

        <DialogContent>

            <TextField
              label="Enter Your Remarks"
              className={classes.financeActionRemarks}
              
              name="finance_remarks"
              value={financeActionRemarks}
              onChange={(e) => setFinanceActionRemarks(e.target.value)}
              variant="outlined"
              // disabled
            />

        </DialogContent>

        <DialogActions>
          <Button className={classes.noButton} onClick={() => handle_finance_confirmation('No')} variant="outlined">
            No
          </Button>
          <Button className={classes.yesButton} onClick={() => handle_finance_confirmation('Yes')} disabled={!financeActionRemarks} variant="contained">
            Yes
          </Button>
        </DialogActions>

      </Dialog>
      {/* Close - Dialog for Finance Action on Payments */}

      {/* Start - Dialog for Edit Payment Details */}
      <Dialog
        open={editProjectModal}
        onClose={() => setEditProjectModal(false)}
        fullWidth
        maxWidth={"md"}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="form-dialog-title" style={{ margin: "auto" }}>
        Edit Payment Details
        </DialogTitle>

        <DialogContent>
          
          <DialogContentText id="alert-dialog-slide-description" style={{ display: "flex", flexDirection: "column" }}>
            <TextField
              label="Customer  Name"
              style={{ marginBottom: 20 }}
              name="customerName"
              value={storeEditDetails.customerName}
              onChange={changeHandler}
              variant="outlined"
              disabled
            />

            <TextField
              label="Unique ID"
              style={{ marginBottom: 20 }}
              name="uniqueLeadNumber"
              value={storeEditDetails.uniqueLeadNumber}
              onChange={changeHandler}
              variant="outlined"
              disabled
            />

            <TextField
              label="Transaction Number"
              style={{ marginBottom: 20 }}
              name="transactionNumber"
              value={storeEditDetails.transactionNumber}
              onChange={changeHandler}
              variant="outlined"
              disabled
            />

            <TextField
              label="Payment Date"
              style={{ marginBottom: 20 }}
              type="date"
              name="createdAt"
              value={storeEditDetails.createdAt}
              onChange={changeHandler}
              variant="outlined"
            />

            <TextField
              type="number"
              label="Amount"
              style={{ marginBottom: 20 }}
              name="Amount"
              value={storeEditDetails.Amount}
              onChange={changeHandler}
              variant="outlined"
            />
            
            <FormControl
              fullWidth
              variant="outlined"
              size="small"
              style={{ marginBottom: 20 }}
            >
              <TextField
                select
                label="Payment Status"
                style={{ marginBottom: 20 }}
                name="Status"
                value={storeEditDetails.Status}
                onChange={changeHandler}
                variant="outlined"
              >
                <MenuItem value="successful">successful</MenuItem>
                <MenuItem value="cancel">cancel</MenuItem>

              </TextField>
            </FormControl>
            
            <FormControl
              fullWidth
              variant="outlined"
              size="small"
              style={{ marginBottom: 20 }}
            >
              <TextField
                select
                labelId="demo-simple-select-label"
                label="Stage"
                id="demo-simple-select"
                name="Stage"
                value={storeEditDetails.Stage}
                onChange={changeHandler}
                variant="outlined"
              >
                {paymentStage.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>
              
            </FormControl>

            <FormControl
              fullWidth
              variant="outlined"
              size="small"
              style={{ marginBottom: 20 }}
            >
              {/* <TextField
              select
              label="Requested By"
              style={{ marginBottom: 20 }}
              name="createdBy"
              value={storeEditDetails.createdBy}
              onChange={changeHandler}
              variant="outlined"
              >
                {departmentWiseUser.map((item) => (
                  <MenuItem key={item} value={item?._id}>
                    {item?.name}
                  </MenuItem>
                ))}
              </TextField> */}

            </FormControl>

            <FormControl
              fullWidth
              variant="outlined"
              size="small"
              style={{ marginBottom: 20 }}
            >
              <TextField
              select
              label="Requested By"
              style={{ marginBottom: 20 }}
              name="createdBy"
              value={storeEditDetails.createdBy}
              onChange={changeHandler}
              variant="outlined"
              >
                {departmentWiseUser.map((item) => (
                  <MenuItem key={item} value={item?._id}>
                    {item?.name}
                  </MenuItem>
                ))}
              </TextField>

            <TextField
              select
              label="Department"
              style={{ marginBottom: 20 }}
              name="departmentId"
              value={storeEditDetails.departmentId}
              onChange={changeHandler}
              variant="outlined"
              >
                {departmentList.map((item) => (
                  <MenuItem key={item} value={item?._id}>
                    {item?.name}
                  </MenuItem>
                ))}

              </TextField>

            </FormControl>

            <FormControl
              fullWidth
              variant="outlined"
              size="small"
              style={{ marginBottom: 20 }}
            >
            <TextField
              select
              label="Payment Mode"
              style={{ marginBottom: 20 }}
              name="paymentMode"
              value={storeEditDetails.paymentMode}
              onChange={changeHandler}
              variant="outlined"
              >
                {paymentModes.map((item) => (
                  <MenuItem key={item} value={item.name}>
                    {item?.name}
                  </MenuItem>
                ))}

              </TextField>

            </FormControl>

            <TextField
              label="Payment Partner"
              style={{ marginBottom: 20 }}
              name="paymentPartnerName"
              value={storeEditDetails.paymentPartnerName}
              onChange={changeHandler}
              variant="outlined"
              disabled
            />

            <TextField
              label={
                <span>
                  Remarks <span style={{ transform: "rotate(180deg)", color: 'red' }}>*</span>
                </span>
              }
              style={{ marginBottom: 20 }}
              name="finance_remarks"
              value={storeEditDetails.finance_remarks}
              onChange={changeHandler}
              variant="outlined"
              
            />

            <Grid container style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>

              <Grid item md={12} xs={12}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Button variant="outlined" className={classes.noButton} onClick={handle_edit_payment_cancel}>Cancel</Button>
                  <Button
                    disabled =
                    {
                      
                      !storeEditDetails.customerName ||
                      !storeEditDetails.uniqueLeadNumber ||
                      !storeEditDetails.transactionNumber ||
                      !storeEditDetails.createdAt ||
                      !storeEditDetails.Amount ||
                      !storeEditDetails.Status ||
                      !storeEditDetails.Stage ||
                      !storeEditDetails.createdBy ||
                      !storeEditDetails.departmentId ||
                      !storeEditDetails.paymentMode ||
                      !storeEditDetails.paymentPartnerName ||
                      !storeEditDetails.paymentPartnerName ||
                      !storeEditDetails.finance_remarks
                    }
                    variant="contained" className={classes.yesButton} onClick={updateDetails}
                  >
                    Update
                  </Button>
                </div>
              </Grid>
            </Grid>
            
          </DialogContentText>

        </DialogContent>

      </Dialog>

    </div>
  );
};

export default connect(null, { setLoading, setSnackInfo })(PaymentDetails);

