import { GET_APPROVED_PROJECTS } from '../actions/types'

const initialState = {
  approvedProjects: [],
  loading: true
}

export default function financeReducer(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case GET_APPROVED_PROJECTS:
      return {
        ...state,
        approvedProjects: payload,
        loading: false
      }
    default:
      return state
  }
}