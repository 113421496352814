import React, { useState, useEffect, useRef, useMemo } from 'react'
import { setLoading, setSnackInfo } from '../../../redux/actions/appAction'
import { connect, useSelector } from 'react-redux'
import { useParams, useHistory } from "react-router-dom";
import HttpService from '../../../services/httpService'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles';
import { Accordion, AccordionSummary, AccordionDetails, AccordionActions, Typography, Paper, Grid, Divider, Button, Table, TableBody, TableCell, TableContainer, TableRow, Box, Tooltip } from '@material-ui/core';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InfoIcon from '@material-ui/icons/Info';
import AttachmentIcon from '@material-ui/icons/Attachment';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select/Select';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
// import './index.css';
import { ROLENAME, constantDepartmentId } from '../../../utils/index';
import { EditorState, convertToRaw } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createMentionPlugin, { defaultSuggestionsFilter, } from '@draft-js-plugins/mention';
import '@draft-js-plugins/mention/lib/plugin.css';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightBold,
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    dialogPaper: {
       minWidth:"20%"
    },
    modalpaper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    textFieldWidth: {
        width: "100%"
    },
}));


const ManageReadyToDispatchProjectLogs = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const editorRef = useRef();
    const { projectId } = useParams();
    const { userDetails } = useSelector(state => state.user)
    const [projectDetails, setProjectDetails] = useState([]);
    const [projectLogs, setProjectLogs] = useState([]);
    const [openNotesModal, setOpenNotesModal] = useState(false);
    const createNoteFile = useRef()
    const [fileUploaded, setFileUploaded] = useState(false);
    const [daysfromContractsign, setDaysFromContractSign] = useState("")

    const [formData, setFormData] = useState({
        notes: "",
        reminderDate: "",
        s3Location: "",
        projectId: "",
        user: "",
        stage: ""
    })

    useEffect(() => {
        getProjectLogs();
        getcustomerDetails();
        imosDataOfStartandEnd()
        // eslint-disable-next-line
    }, [])

    const getProjectLogs = async () => {
        props.setLoading(true)
        try {
            const response = await HttpService.getProjectLog(projectId)
            setProjectLogs(response.data)
        } catch (error) {
            console.log("No logs found")
        }
        props.setLoading(false)
    }
    const [daysfromModulardelivery, setDaysFromModularDelivery] = useState("")
    const getcustomerDetails = async () => {
        props.setLoading(true)
        try {
            const response = await HttpService.getProjectLeadDetails(projectId)
            setProjectDetails(response.data[0])
            let date = new Date()
            if (response.data[0].designSignOffDate){
            let str = moment(response.data[0]?.designSignOffDate).valueOf()
            let time_difference = date.getTime() - str
            let result = time_difference / (1000 * 60 * 60 * 24);
            setDaysFromContractSign(result);
            }
            if (response.data[0]?.materialDispatchedDate){
            let materialdispatch = moment(response.data[0]?.materialDispatchedDate).valueOf()
            let materialDiff = date.getTime() - materialdispatch
            let materialResult = materialDiff / (1000 * 60 * 60 * 24);
            setDaysFromModularDelivery(materialResult)
            }
        } catch (error) {
            props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const openCreateNotesModal = () => {
        getAllUsers();
        setOpenNotesModal(true);
        setFormData({
            ...formData,
            user: projectDetails?.assignTo?._id,
            leadId: projectId
        })
    }

    const [openMailModel, setOpenMailModel] = useState(false);
    const [openUpdateModel, setOpenUpdateModel] = useState(false)
    const [sendMailCustomerData, setSendMailCustomerData] = useState({
        finalSubmitDate: "",
        amountDue: ""
    });
    const [openClientModel, setOpenClientModel] = useState(false);
    const [clientDelay, setClientDelay] = useState(0);

    const [updatestatus, setupdatestatus] = useState({
        materialDispatchedDate: "",
        status:"Under Execution"
    });
    const clientExtraDays = () =>{
        setOpenClientModel(true)
    }
    const sendMailToCustomer = () => {
        setOpenMailModel(true);
    }
    const viewCheckList = () => {
        props.history.push(`/view-customer-checklist/${projectId}`);
    }

    const sendEmailToCustomer = async () => {
        console.log(sendMailCustomerData, "sendMailCustomerData")
        props.setLoading(true)
        sendMailCustomerData.leadId = projectId;
        sendMailCustomerData.customerEmail = projectDetails.customerId?.email;
        sendMailCustomerData.customerName = projectDetails.customerId?.name;
        try {
            const response = await HttpService.sendFinalEmailToCustomer(sendMailCustomerData)
            console.log(response.data, "Response data")
            props.setSnackInfo("Email Send to customer", "success")
            setOpenMailModel(false)
        } catch (error) {
            props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const clientDelayfunc = async () =>{
        try {
            const response = await HttpService.extraDaysDuetoClientDependency(projectId, clientDelay)
            props.setSnackInfo("Updated Successfully", "success")
            setOpenClientModel(false)
        } catch (error) {
            props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }
    // const changeStatusMaterialDispatched = async ()=>{
    //     props.setLoading(true)
    //     updatestatus.leadId = projectId;
    //     try {
    //         const response = await HttpService.updateStatusMaterialDispatched(updatestatus)
    //         getProjectLogs();
    //         getcustomerDetails();
    //         props.setSnackInfo("Status Updated", "success")
    //         setOpenUpdateModel(false)
    //     } catch (error) {
    //         props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
    //     }
    //     props.setLoading(false)
    // }

    // const updateStatus = ()=>{
    // setOpenUpdateModel(true)
    // }
    const changeStatusUnderExecution = async ()=>{
        props.setLoading(true)
        updatestatus.leadId = projectId;
        // updatestatus.executionManagerRoleId='637e0a2b065dae0956c9487d'
        try {
            const response = await HttpService.updateStatusUnderExecution(updatestatus)
            getProjectLogs();
            getcustomerDetails();
            props.setSnackInfo("Status Updated", "success")
            setOpenUpdateModel(false)
        } catch (error) {
            props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const updateStatus = ()=>{
    setOpenUpdateModel(true)
    }


    const [openPaymentUpdateModel, setOpenPaymentUpdateModel] = useState(false);
    const [sendPaymentUpdateData, setSendPaymentUpdateData] = useState({
        paymentAttachement: "",
        amount: "",
        note: ""
    });

    const updateCustomerPayment = () => {
        setOpenPaymentUpdateModel(true);
    }

    const updateCustomerPaymentAndSendToFinance = async () => {
        console.log(sendPaymentUpdateData, "sendPaymentData")
        props.setLoading(true)
        sendPaymentUpdateData.leadId = projectId;
        sendPaymentUpdateData.customerEmail = projectDetails.customerId?.email;
        sendPaymentUpdateData.customerName = projectDetails.customerId?.name;
        try {
            const response = await HttpService.updateCustomerPaymentAndSendToFinance(projectId, sendPaymentUpdateData)
            console.log(response.data, "Response data")
            setIsFinalPaymentReceived(false);
            setOpenPaymentUpdateModel(false);
            props.setSnackInfo("Payment Updated successfully", "success")
        } catch (error) {
            props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const [moveDealOpen, setMoveDealOpen] = useState(false);
    const moveDeal = () => {
        console.log("called")
        setMoveDealOpen(true)
        getDepartments();
    }

    const [allUsersList, setAllUsersList] = useState([]);
    const getAllUsers = async () => {
        console.log('called')
        props.setLoading(true)
        try {
            const response = await HttpService.getAllUsers()
            let userData = [];
            response.data.forEach((ele) => {
                userData.push({
                    name: ele.name,
                    userId: ele._id
                })
            })
            // console.log(response.data, "res", userData);
            setAllUsersList(userData)

        } catch (error) {
            props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );
    const [mentionOpen, setMentionOpen] = useState(false);
    const [suggestions, setSuggestions] = useState(allUsersList);

    const { MentionSuggestions, plugins } = useMemo(() => {
        const mentionPlugin = createMentionPlugin();
        // eslint-disable-next-line no-shadow
        const { MentionSuggestions } = mentionPlugin;
        // eslint-disable-next-line no-shadow
        const plugins = [mentionPlugin];
        return { plugins, MentionSuggestions };
    }, []);

    const setEditorStateData = (editorState) => {
        setEditorState(editorState);
        setFormData({ ...formData, 'notes': editorRef.current?.editor?.editor?.innerText })
    };

    const onOpenChange = (mentionOpen) => {
        if (mentionOpen === false) {
            setMentionOpen(false)
        } else {
            setMentionOpen(true)
        }
    };

    const onSearchChange = ({ value }) => {
        setSuggestions(defaultSuggestionsFilter(value, allUsersList));
    };

    const onAddMention = (mention) => {
        setMentionOpen(false)
    }

    const [openStatusModal, setOpenStatusModal] = useState(false);
    const openStatusUpdateModal = () => {
        setOpenStatusModal(true);
    }

    const saveNotes = async () => {
        const contentState = editorState.getCurrentContent();
        const raw = convertToRaw(contentState);
        let mentionedUsers = [];
        for (let key in raw.entityMap) {
            const ent = raw.entityMap[key];
            if (ent.type === "mention") {
                mentionedUsers.push(ent.data.mention);
            }
        }
        let taggedUser = [];
        mentionedUsers.forEach((ele, i) => {
            taggedUser.push(ele.userId);
        })

        let obj = {
            ...formData,
            taggedUser: taggedUser
        }
        console.log(obj, 'obj formdata')

        props.setLoading(true);
        try {
            const addNoteResponse = await HttpService.addNotes(obj);
            if (addNoteResponse.status === 200) {
                setOpenNotesModal(false);
                getProjectLogs();
                getcustomerDetails();
                setFormData({
                    ...formData,
                    s3Location: "",
                    reminderDate: ""
                })
                props.setSnackInfo("Notes Added Successfully", "success")
            }
        } catch (err) {
            props.setSnackInfo(err?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false);
    }

    const uploadCreateNoteImg = async (e) => {
        props.setLoading(true)
        const fd = new FormData()
        fd.append('file', e.target.files[0])
        fd.append('customerName', projectDetails.lead_no + '_' + projectDetails.customerId.name)
        try {
            const fileUploadRes = await HttpService.uploadFile(fd);
            setFormData({
                ...formData,
                s3Location: fileUploadRes.data
            })
            setFileUploaded(true);
        } catch (err) {
            props.setSnackInfo(err?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }
    const [stageIndex, setStageIndex] = useState(0);
    const [openNotesInfoModal, setOpenNotesInfoModal] = useState(false);
    const [stageInfo, setStageInfo] = useState("");
    const [openActivity, setOpenActivity] = useState('')
    const showInfo = (i, status) => {
        setStageIndex(i);
        setOpenNotesInfoModal(true);
        setStageInfo(projectLogs[i]['stage'])
        setOpenActivity(status)
        console.log(projectLogs[i], 'projectLogs[i]')
    }

    const downloadNotesFile = (data) => {
        window.open(data.s3Location[0])
    }

    const [departmentLists, setDepartmentsList] = useState([]);
    const [selectedStage, setSelectedStage] = useState("");
    const [department, setSelectedDepartment] = useState("");
    const [team, setSelectedTeam] = useState("");
    const [dealOwner, setSelectedDealOwner] = useState("");
    const [teamLists, setTeamList] = useState([]);
    const [dealOwnerLists, setDealOwnersList] = useState([]);
    const [departmentStage, setDepartmentsStage] = useState([]);

    const [imosDrawingDateToFactory, setImosDrawingDateToFactory] = useState("")
    const imosDataOfStartandEnd = async () => {
        try {
            props.setLoading(true)
            const response = await HttpService.getDetailsOfImosProject(projectId);
            if (response.length !== 0) {
                let date = new Date()
                let startDate = moment(response.data[0]?.startedDate).valueOf()
                let endDate = moment(response.data[0]?.endDate).valueOf()
                let time_difference = endDate - startDate;
                let result = time_difference / (1000 * 60 * 60 * 24);
                setImosDrawingDateToFactory(result);
            }
        } catch (error) {
            props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
        }
        props.setLoading(false)
    }


    const [openMoveDealDetailsDialog, setOpenMoveDealDetailsDialog] = useState(false)
    const openAnotherDialog = () => {
        setMoveDealOpen(false);
        setOpenMoveDealDetailsDialog(true);
        getDepartments();
    }
    const handleSelectedDepartmentChange = (event) => {
        setSelectedDepartment(event.target.value);
        getTeamsList(event.target.value)
    }
    const handleSelectedTeamChange = (event) => {
        setSelectedTeam(event.target.value);
        getUserList(event.target.value)
    }
    const handleSelectedUserChange = (event) => {
        setSelectedDealOwner(event.target.value);
    }
    const getDepartments = async () => {
        try {
            const department = await HttpService.getDepartments();
            if (userDetails?.roles?.find(role => role.name !== 'Admin')) {
                if (userDetails?.roles?.find(role => role.name === ROLENAME.MarketingUser || role.name === ROLENAME.MarketingManager)) {
                    setDepartmentsList(department.data.filter(ele => ele.name === 'Sales'));
                } else {
                    setDepartmentsList(department.data.filter(ele => ele._id === userDetails.departmentId))
                }
            }
            getDepartmentStages(department.data);
        } catch (error) {
            console.log(error, "get department error")
        }
    }

    const getTeamsList = async (deptid) => {
        let teams = [];
        teams = departmentLists.filter(ele => ele._id === deptid)
        const centerTeams = [];
        teams[0]['teams'].forEach(ele => {
            ele.experienceCenterId.forEach(centerId => {
                if (centerId === userDetails.selectedExpCenter) {
                    centerTeams.push(ele)
                }
            })
        })
        const activeTeamList = centerTeams.filter(ele => ele.isActive === true)
        setTeamList(activeTeamList)
    }

    const getUserList = async (teamid) => {
        if (userDetails?.roles?.find(role => ROLENAME.MarketingUser || role.name === ROLENAME.MarketingManager)) {
            try {
                const users = await HttpService.getQTTeamUsers(teamid);
                let activeUsers = users.data.users.filter(ele => ele.isActive === true)
                setDealOwnersList(activeUsers)
            } catch (error) {
                console.log(error, "Error");
            }
        } else if (teamid === userDetails.teamId._id) {
            try {
                const users = await HttpService.getQTTeamUsers(teamid);
                let activeUsers = users.data.users.filter(ele => ele.isActive === true)
                setDealOwnersList(activeUsers)
            } catch (error) {
                console.log(error, "Error");
            }
        } else {
            try {
                const teamUsers = await HttpService.getQtTeamManager(teamid);
                let teamManager = [];
                let obj = {
                    _id: teamUsers.data.manager._id,
                    name: teamUsers.data.manager.name
                }
                teamManager.push(obj);
                setDealOwnersList(teamManager)
            } catch (error) {
                console.log(error, "Error");
            }
        }
    }

    const getDepartmentStages = async (data) => {
        let stages = []
        console.log(data, "data")
        if (userDetails.roles.find(role => role.name === ROLENAME.IMOSManager ||  role.name === ROLENAME.IMOSUser)) {
            data.forEach((element) => {
                if (element['name'] === 'IMOS') {
                    stages.push(element['stages']);
                };
            });
        }
        setDepartmentsStage(stages[0]);
    }

    const handleSelectedStageChange = (event) => {
        setSelectedStage(event.target.value);
    }

    const assignLeadToUser = async () => {
        let dealData = {
            stage: selectedStage,
            department: department,
            team: team,
            id: dealOwner,
            leadId: projectId
        };
        if (userDetails.departmentId === constantDepartmentId.imos) {
            dealData.stageDepartment = 'imos'
        }
        try {
            props.setLoading(true)
            const assignProject = await HttpService.assignProject(dealData);
            if (assignProject.status === 200) {
                setOpenMoveDealDetailsDialog(false);
                setSelectedStage('');
                setSelectedDepartment('');
                setSelectedTeam('');
                setSelectedDealOwner('');
                getProjectLogs();
                getcustomerDetails();
                props.setSnackInfo("Project assigned Successful", "success")
            }
        } catch (error) {
            console.log(error, "assign project error");
            props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
        }
        props.setLoading(false)
    }

    const [openStageStatusModal, setOpenStageStatusModal] = useState(false)
    const openUpdateStageModal = () => {
        setOpenStageStatusModal(true);
    }
    const [selectedStageStatus, setSelectedStageStatus] = useState('')
    const [dispatchDate, setDispatchDate] = useState(moment().toISOString(new Date()));
    const handleSelectedStageStatus = (event) => {
        setSelectedStageStatus(event.target.value)
    }

    const updateStageStatus = async () => {
        if (selectedStageStatus === 'Ready for dispatch') {
            let obj = {
                readyToDispatchDate: dispatchDate,
                factoryStage: selectedStageStatus
            }
            try {
                props.setLoading(true)
                const assignProject = await HttpService.updateToReadyToDispatch(projectId, obj);
                if (assignProject.status === 200) {
                    setOpenStageStatusModal(false);
                    getProjectLogs();
                    getcustomerDetails();
                    props.setSnackInfo("Project assinged to CRM Successful", "success")
                }
            } catch (error) {
                console.log(error, "assign project error");
                props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
            }
            props.setLoading(false)
        } else {
            let obj = {
                factoryStage: selectedStageStatus
            }
            try {
                props.setLoading(true)
                const assignProject = await HttpService.updateFactoryStage(projectId, obj);
                if (assignProject.status === 200) {
                    setOpenStageStatusModal(false);
                    getProjectLogs();
                    getcustomerDetails();
                    props.setSnackInfo("Project Factory Stage Successful", "success")
                }
            } catch (error) {
                console.log(error, "stage update error");
                props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
            }
            props.setLoading(false)
        }
    }

    const [openUpdateMaterialStatusModal, setOpenUpdateMaterialStatusModal] = useState(false);
    const [selectedMaterilPercentage, setSelectedMaterilPercentage] = useState('');
    const updateMaterialReceived = async () => {
        let obj = {}
        if (selectedMaterilPercentage === 'Yes') {
           obj = { materialReceived: true }
        } else {
            obj = { materialReceived: false }
        }
        try {
            props.setLoading(true)
            const assignProject = await HttpService.uploadUpdatedFileAndData(projectId, obj);
            if (assignProject.status === 200) {
                setOpenUpdateMaterialStatusModal(false);
                getProjectLogs();
                getcustomerDetails();
                props.setSnackInfo("Project Factory Stage Successful", "success")
            }
        } catch (error) {
            console.log(error, "stage update error");
            props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
        }
        props.setLoading(false)
    }

    const [isFinalPaymentReceived, setIsFinalPaymentReceived] = useState(false);
    const uploadWDFileDocument = async (e) => {
        try {
            props.setLoading(true)
            const formData = new FormData()
            formData.append('file', e.target.files[0])
            formData.append('customerName', projectDetails.lead_no + '_' + projectDetails.customerId.name)
            const result = await HttpService.wdFileUpload(formData);
            setSendPaymentUpdateData({ ...sendPaymentUpdateData, paymentAttachement: result.data })
            setIsFinalPaymentReceived(true);
            props.setSnackInfo("Design Sign-off File Upload Success", "success")
        } catch (error) {
            console.log(error)
            props.setSnackInfo(error?.response?.data ?? "Something Went Wrong", "error")
        }
        props.setLoading(false)
    }
    return (
        <div>
            <div className={classes.root}>
                <Grid container spacing={3}>
                    {/* Details */}
                    <Grid item lg={6} md={6} sm={12}>
                        <Paper className={classes.paper}>

                            {/* Customer Details */}
                            <Accordion defaultExpanded>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className={classes.heading}>Customer Details</Typography>
                                </AccordionSummary>

                                <Divider />

                                <AccordionDetails style={{ padding: '8px 0px 16px' }}>
                                    <TableContainer component={Paper}>
                                        <Table className={classes.table} size="small" aria-label="customized table">
                                            <TableBody>
                                                <TableRow key='1'>
                                                    <TableCell>Customer Name</TableCell>
                                                    <TableCell>{projectDetails?.customerId?.name}</TableCell>
                                                </TableRow>
                                                <TableRow key='2'>
                                                    <TableCell>Contact</TableCell>
                                                    <TableCell>{projectDetails?.customerId?.contact_no}</TableCell>
                                                </TableRow>
                                                <TableRow key='3'>
                                                    <TableCell>Email</TableCell>
                                                    <TableCell>{projectDetails?.customerId?.email}</TableCell>
                                                </TableRow>
                                                <TableRow key='4'>
                                                    <TableCell>Address</TableCell>
                                                    <TableCell>{projectDetails?.customerId?.address}</TableCell>
                                                </TableRow>
                                                <TableRow key='5'>
                                                    <TableCell>Current Stage</TableCell>
                                                    <TableCell>
                                                        {projectDetails.currentStage}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow key='6'>
                                                    <TableCell>Assigned To</TableCell>
                                                    <TableCell>
                                                        {projectDetails?.assignTo?.name}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow key='7'>
                                                    <TableCell>Lead Number</TableCell>
                                                    <TableCell>{projectDetails?.lead_no}</TableCell>
                                                </TableRow>
                                                <TableRow key='8'>
                                                    <TableCell>Project No.</TableCell>
                                                    <TableCell>{projectDetails?.erpProjectNo ? projectDetails?.erpProjectNo : '-'}</TableCell>
                                                </TableRow>
                                                <TableRow key='9'>
                                                    <TableCell>ExperienceCenter</TableCell>
                                                    <TableCell>{projectDetails?.experienceCenterId?.name ? projectDetails?.experienceCenterId?.name :'-'}</TableCell>
                                                </TableRow> 
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </AccordionDetails>

                                <Divider />

                                <AccordionActions>
                                <Button variant="contained" color="primary" size="small" onClick={() => viewCheckList()}>View Check List</Button>
                                    <Button variant="contained" color="primary" size="small" onClick={sendMailToCustomer} disabled={projectDetails?.finalPaymentApprovalRequestStatus === 'Sent' || projectDetails?.finalPaymentApprovalRequestStatus === 'Approved'} >Send Mail to Customer</Button>
                                    <Button variant="contained" color="primary" size="small" onClick={updateCustomerPayment} disabled={projectDetails?.finalPaymentApprovalRequestStatus === 'Sent' || projectDetails?.finalPaymentApprovalRequestStatus === 'Approved'} >Update Payment</Button>
                                    <Button variant="contained" color="primary" size="small" onClick={openCreateNotesModal}>Add Notes</Button>
                                    <Button variant="contained" color="primary" size="small" onClick={updateStatus} disabled={projectDetails?.finalPaymentApprovalRequestStatus === 'Sent' || projectDetails?.finalPaymentApprovalRequestStatus === 'Approved'} >Update Status</Button>
                                    <Button variant="contained" color="primary" size="small" onClick={clientExtraDays}>Extra days due to client dependency</Button>
                                </AccordionActions>
                            </Accordion>

                            {/* Design Details  */}
                            <Accordion defaultExpanded>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Typography className={classes.heading}>Design Details</Typography>
                                </AccordionSummary>
                                <Divider />

                                <AccordionDetails style={{ padding: '35px 0px 16px' }}>
                                    <TableContainer component={Paper}>
                                        <Table className={classes.table} size="small" aria-label="customized table">
                                            <TableBody>
                                                <TableRow key='3'>
                                                    <TableCell>Internal Design Sign-off Date</TableCell>
                                                    <TableCell>{projectDetails?.internalDesignSignOffDate ? moment(projectDetails?.internalDesignSignOffDate).format('DD-MM-YYYY') : `-`}</TableCell>
                                                </TableRow>
                                                <TableRow key='1'>
                                                    <TableCell>Expected Design Sign-off Date</TableCell>
                                                    <TableCell>{projectDetails?.expectedDesignSignOffDate ? moment(projectDetails?.expectedDesignSignOffDate).format('DD-MM-YYYY') : `-`}</TableCell>
                                                </TableRow>
                                                <TableRow key='2'>
                                                    <TableCell>Days from Contract sign:</TableCell>
                                                    <TableCell>{daysfromContractsign !== "" ? daysfromContractsign.toFixed(0) : `-`}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </AccordionDetails>

                            </Accordion>

                            {/* Deals Details */}
                            <Accordion defaultExpanded>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Typography className={classes.heading}>Design Details</Typography>
                                </AccordionSummary>
                                <Divider />
                                <AccordionDetails style={{ padding: '8px 0px 16px' }}>
                                    <TableContainer component={Paper}>
                                        <Table className={classes.table} size="small" aria-label="customized table">
                                            <TableBody>
                                                <TableRow key='3'>
                                                    <TableCell>Internal Design Sign-off Date</TableCell>
                                                    <TableCell>{projectDetails?.internalDesignSignOffDate ? moment(projectDetails?.internalDesignSignOffDate).format('DD-MM-YYYY') : `-`}</TableCell>
                                                </TableRow>
                                                <TableRow key='1'>
                                                    <TableCell>Expected Design Sign-off Date</TableCell>
                                                    <TableCell>{projectDetails?.expectedDesignSignOffDate ? moment(projectDetails?.expectedDesignSignOffDate).format('DD-MM-YYYY') : `-`}</TableCell>
                                                </TableRow>
                                                <TableRow key='2'>
                                                    <TableCell>Days from Contract sign:</TableCell>
                                                    <TableCell>{daysfromContractsign !== "" ? daysfromContractsign.toFixed(0) : `-`}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </AccordionDetails>
                            </Accordion>
                        </Paper>
                    </Grid>

                    {/* Notes Details */}
                    <Grid item lg={6} md={6} sm={12}>
                        {projectLogs?.map((data, i) => (
                            <Paper className={classes.paper} key={i + 1}>
                                {
                                    data?.dealActivity && <Accordion defaultExpanded className="accordian">
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography className={classes.heading}>
                                                <LocalActivityIcon />
                                                <span className="titlename">Deal Activity</span>
                                                <span className="datedata">{moment(data?.createdAt).format('lll')}</span>
                                                {/* {{pl.createdAt | date}}, {{pl.createdAt | date:'shortTime' }}  */}
                                            </Typography>
                                        </AccordionSummary>
                                        <Divider />
                                        <AccordionDetails>
                                            <Typography variant="body2" gutterBottom>
                                                {data?.dealActivity}
                                            </Typography>
                                        </AccordionDetails>
                                        <Divider />
                                        <AccordionActions style={{ display: "block" }}>
                                            <InfoIcon style={{ cursor: 'pointer' }} onClick={() => showInfo(i, 'dealActivity')} />
                                            {
                                                data.s3Location.length !== 0 &&
                                                <AttachmentIcon style={{ cursor: 'pointer' }} onClick={() => downloadNotesFile(data)} />
                                            }
                                        </AccordionActions>
                                    </Accordion >
                                }
                                {
                                    data?.notes && <Accordion defaultExpanded className="accordian">
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography className={classes.heading}>
                                                <EditTwoToneIcon />
                                                <span className="titlename">Notes</span>
                                                <span className="datedata">{moment(data?.createdAt).format('lll')}</span>
                                            </Typography>
                                        </AccordionSummary>
                                        <Divider />
                                        <AccordionDetails>
                                            <Typography variant="body2" gutterBottom>
                                                {data?.notes} <br /> <span style={{ fontSize: "12px" }}>{data?.createdBy?.name} has left a note</span>
                                            </Typography>
                                        </AccordionDetails>
                                        <Divider />
                                        <AccordionActions style={{ display: "block" }}>
                                            <InfoIcon style={{ cursor: 'pointer' }} onClick={() => showInfo(i, 'notes')} />
                                            {data.s3Location.length !== 0 &&
                                                <AttachmentIcon style={{ cursor: 'pointer' }} onClick={() => downloadNotesFile(data)} />
                                            }
                                            {/* {data.s3Location.length !== 0 && <AttachmentIcon onClick={() => window.open(data.s3Location[0])} />} */}
                                        </AccordionActions>
                                    </Accordion>
                                }
                            </Paper>
                        ))}
                    </Grid>
                </Grid>
            </div>

            {/* Show Notes and Deal Activity Info Modal */}
            <Dialog open={openNotesInfoModal} onClose={() => setOpenNotesInfoModal(false)} aria-labelledby="form-dialog-title" fullWidth maxWidth='sm'>
                <DialogTitle id="form-dialog-title">
                    {(stageInfo === 'Quotation Sent' && openActivity === 'dealActivity') && <>Quotation Sent</>}
                    {(stageInfo === 'Pre - Sales call' && openActivity === 'dealActivity') && <>Pre-sales Call</>}
                    {(stageInfo === 'Sales Call' && openActivity === 'dealActivity') && <>Sales Call</>}
                    {(stageInfo === 'Negotiation' && openActivity === 'dealActivity') && <>Negotiation</>}
                    {(stageInfo === 'Won' && openActivity === 'dealActivity') && <>Won</>}
                    {(stageInfo === 'Site/Office visit' && openActivity === 'dealActivity') && <>Site/Office visit</>}
                    {openActivity === 'notes' && <>Notes</>}
                    {(stageInfo !== 'Pre - Sales call' && stageInfo !== 'Sales Call' && stageInfo !== 'Quotation Sent' && stageInfo !== 'Site/Office visit' && stageInfo !== 'Negotiation' && stageInfo !== 'Won' && openActivity === 'dealActivity') && <>Deal Activity</>}
                </DialogTitle>
                <Divider />
                <DialogContent>
                    {projectLogs[stageIndex]?.['quotationSentDate'] &&
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="customized table">
                                <TableBody>
                                    <TableRow key='1'>
                                        <TableCell>Quotation sent Date</TableCell>
                                        <TableCell>{moment(projectLogs[stageIndex]?.['quotationSentDate']).format('DD-MM-YYYY')}</TableCell>
                                    </TableRow>
                                    <TableRow key='2'>
                                        <TableCell>MOM</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['momQuotationStage']}</TableCell>
                                    </TableRow>
                                    <TableRow key='3'>
                                        <TableCell>Next Follow update</TableCell>
                                        <TableCell>{moment(projectLogs[stageIndex]?.['nextFollowUpDate']).format('DD-MM-YYYY')}</TableCell>
                                    </TableRow>
                                    <TableRow key='3'>
                                        <TableCell>Assigned To</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['user']?.['name']}</TableCell>
                                    </TableRow>
                                    <TableRow key='4'>
                                        <TableCell>Department</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['leadId']?.['departmentId']?.['name']}</TableCell>
                                    </TableRow>
                                    <TableRow key='5'>
                                        <TableCell>Team</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['leadId']?.['teamId']?.['name']}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                    {projectLogs[stageIndex]?.['preSalesCallDate'] &&
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="customized table">
                                <TableBody>
                                    <TableRow key='1'>
                                        <TableCell>Pre-sales Call Date</TableCell>
                                        <TableCell>{moment(projectLogs[stageIndex]?.['preSalesCallDate']).format('DD-MM-YYYY')}</TableCell>
                                    </TableRow>
                                    <TableRow key='3'>
                                        <TableCell>Next Follow update</TableCell>
                                        <TableCell>{moment(projectLogs[stageIndex]?.['nextFollowUpDate']).format('DD-MM-YYYY')}</TableCell>
                                    </TableRow>
                                    <TableRow key='3'>
                                        <TableCell>Assigned To</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['user']?.['name']}</TableCell>
                                    </TableRow>
                                    <TableRow key='4'>
                                        <TableCell>Department</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['leadId']?.['departmentId']?.['name']}</TableCell>
                                    </TableRow>
                                    <TableRow key='5'>
                                        <TableCell>Team</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['leadId']?.['teamId']?.['name']}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                    {projectLogs[stageIndex]?.['salesCallDate'] &&
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="customized table">
                                <TableBody>
                                    <TableRow key='1'>
                                        <TableCell>Sales Call Date</TableCell>
                                        <TableCell>{moment(projectLogs[stageIndex]?.['salesCallDate']).format('DD-MM-YYYY')}</TableCell>
                                    </TableRow>
                                    <TableRow key='2'>
                                        <TableCell>Requirements</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['requirements']}</TableCell>
                                    </TableRow>
                                    <TableRow key='3'>
                                        <TableCell>Estimated Budget</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['estimatedBudget']}</TableCell>
                                    </TableRow>
                                    <TableRow key='4'>
                                        <TableCell>Sales Call Comment</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['salesCallComment']}</TableCell>
                                    </TableRow>
                                    <TableRow key='5'>
                                        <TableCell>Next Follow update</TableCell>
                                        <TableCell>{moment(projectLogs[stageIndex]?.['nextFollowUpDate']).format('DD-MM-YYYY')}</TableCell>
                                    </TableRow>
                                    <TableRow key='6'>
                                        <TableCell>Assigned To</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['user']?.['name']}</TableCell>
                                    </TableRow>
                                    <TableRow key='4'>
                                        <TableCell>Department</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['leadId']?.['departmentId']?.['name']}</TableCell>
                                    </TableRow>
                                    <TableRow key='5'>
                                        <TableCell>Team</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['leadId']?.['teamId']?.['name']}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                    {projectLogs[stageIndex]?.['negotiationDate'] &&
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="customized table">
                                <TableBody>
                                    <TableRow key='1'>
                                        <TableCell>Negotiation Date</TableCell>
                                        <TableCell>{moment(projectLogs[stageIndex]?.['negotiationDate']).format('DD-MM-YYYY')}</TableCell>
                                    </TableRow>
                                    <TableRow key='2'>
                                        <TableCell>MOM Negotitation Stage</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['momNegotitationStage']}</TableCell>
                                    </TableRow>
                                    <TableRow key='5'>
                                        <TableCell>Next Follow update</TableCell>
                                        <TableCell>{moment(projectLogs[stageIndex]?.['nextFollowUpDate']).format('DD-MM-YYYY')}</TableCell>
                                    </TableRow>
                                    <TableRow key='6'>
                                        <TableCell>Assigned To</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['user']?.['name']}</TableCell>
                                    </TableRow>
                                    <TableRow key='7'>
                                        <TableCell>Department</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['leadId']?.['departmentId']?.['name']}</TableCell>
                                    </TableRow>
                                    <TableRow key='8'>
                                        <TableCell>Team</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['leadId']?.['teamId']?.['name']}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                    {projectLogs[stageIndex]?.['Won'] &&
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="customized table">
                                <TableBody>
                                    <TableRow key='1'>
                                        <TableCell>Won Date</TableCell>
                                        <TableCell>{moment(projectLogs[stageIndex]?.['closureDate']).format('DD-MM-YYYY')}</TableCell>
                                    </TableRow>
                                    <TableRow key='2'>
                                        <TableCell>Remarks</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['remark']}</TableCell>
                                    </TableRow>
                                    <TableRow key='3'>
                                        <TableCell>Payment Plan</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['paymentPlan']}</TableCell>
                                    </TableRow>
                                    <TableRow key='4'>
                                        <TableCell>Final Quote Attached</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['finalQuoteAttached'] === true ? `Yes` : `No`}</TableCell>
                                    </TableRow>
                                    <TableRow key='5'>
                                        <TableCell>Client Move in Date</TableCell>
                                        <TableCell>{moment(projectLogs[stageIndex]?.['clientMoveinDate']).format('DD-MM-YYYY')}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                    {projectLogs[stageIndex]?.['siteVisitDate'] &&
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="customized table">
                                <TableBody>
                                    <TableRow key='1'>
                                        <TableCell>Site/Visit Date</TableCell>
                                        <TableCell>{moment(projectLogs[stageIndex]?.['siteVisitDate']).format('DD-MM-YYYY')}</TableCell>
                                    </TableRow>
                                    <TableRow key='2'>
                                        <TableCell>MOM Meeting Stage</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['momMeetingStage']}</TableCell>
                                    </TableRow>
                                    <TableRow key='3'>
                                        <TableCell>MOM Site Visit Stage</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['momSiteVisitStage']}</TableCell>
                                    </TableRow>
                                    <TableRow key='5'>
                                        <TableCell>Next Follow update</TableCell>
                                        <TableCell>{moment(projectLogs[stageIndex]?.['nextFollowUpDate']).format('DD-MM-YYYY')}</TableCell>
                                    </TableRow>
                                    <TableRow key='6'>
                                        <TableCell>Assigned To</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['user']?.['name']}</TableCell>
                                    </TableRow>
                                    <TableRow key='4'>
                                        <TableCell>Department</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['leadId']?.['departmentId']?.['name']}</TableCell>
                                    </TableRow>
                                    <TableRow key='5'>
                                        <TableCell>Team</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['leadId']?.['teamId']?.['name']}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }

                    {
                        (
                            stageInfo !== 'Pre - Sales call' &&
                            stageInfo !== 'Sales Call' &&
                            stageInfo !== 'Negotiation' &&
                            stageInfo !== 'Quotation Sent' &&
                            stageInfo !== 'Site/Office visit' &&
                            stageInfo !== 'Won' &&
                            !projectLogs[stageIndex]?.['notes']
                        )
                            ?
                            <TableContainer component={Paper}>
                                <Table className={classes.table} size="small" aria-label="customized table">
                                    <TableBody>
                                        <TableRow key='1'>
                                            <TableCell>Assigned To</TableCell>
                                            <TableCell>{projectLogs[stageIndex]?.['user']?.['name']}</TableCell>
                                        </TableRow>
                                        <TableRow key='4'>
                                            <TableCell>Department</TableCell>
                                            <TableCell>{projectLogs[stageIndex]?.['leadId']?.['departmentId']?.['name']}</TableCell>
                                        </TableRow>
                                        <TableRow key='5'>
                                            <TableCell>Team</TableCell>
                                            <TableCell>{projectLogs[stageIndex]?.['leadId']?.['teamId']?.['name']}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            :
                            <TableContainer component={Paper}>
                                <Table className={classes.table} size="small" aria-label="customized table">
                                    <TableBody>
                                        {
                                            projectLogs[stageIndex]?.['notes'] &&

                                            <TableRow key='2'>
                                                <TableCell>Notes</TableCell>
                                                <TableCell>{projectLogs[stageIndex]?.['notes']}</TableCell>
                                            </TableRow>
                                        }
                                        {
                                            projectLogs[stageIndex]?.['s3Location'].length !== 0 &&

                                            <TableRow key='3'>
                                                <TableCell>Attachments</TableCell>
                                                {projectLogs[stageIndex]?.['s3Location']?.map((data, i) => (
                                                    <TableCell key={i + 1}>{data}</TableCell>
                                                ))}
                                            </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                    }
                    {
                        (
                            projectLogs[stageIndex]?.['quotationSentDate'] === null ||
                            projectLogs[stageIndex]?.['preSalesCallDate'] === null ||
                            projectLogs[stageIndex]?.['salesCallDate'] === null ||
                            projectLogs[stageIndex]?.['negotiationDate'] === null ||
                            projectLogs[stageIndex]?.['siteVisitDate'] === null
                        )
                        &&
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="customized table">
                                <TableBody>
                                    <TableRow key='1'>
                                        <TableCell>Assigned To</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['user']?.['name']}</TableCell>
                                    </TableRow>
                                    <TableRow key='4'>
                                        <TableCell>Department</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['leadId']?.['departmentId']?.['name']}</TableCell>
                                    </TableRow>
                                    <TableRow key='5'>
                                        <TableCell>Team</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['leadId']?.['teamId']?.['name']}</TableCell>
                                    </TableRow>
                                    <TableRow key='2'>
                                        <TableCell>Notes</TableCell>
                                        <TableCell>{projectLogs[stageIndex]?.['notes']}</TableCell>
                                    </TableRow>
                                    <TableRow key='3'>
                                        <TableCell>Attachments</TableCell>
                                        {projectLogs[stageIndex]?.['s3Location']?.map((data, i) => (
                                            <TableCell key={i + 1}>{data}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                </DialogContent>
            </Dialog>

            {/* Create Notes Modal */}
            <Dialog open={openNotesModal} onClose={() => setOpenNotesModal(false)} aria-labelledby="form-dialog-title" maxWidth='sm'>
                <DialogTitle id="form-dialog-title">Create Notes</DialogTitle>
                <Divider />
                <DialogContent>
                    <div style={{ paddingBottom: 15 }}>
                        <div className="editor">
                            <Editor
                                editorKey={'editor'}
                                editorState={editorState}
                                onChange={setEditorStateData}
                                plugins={plugins}
                                ref={editorRef}
                            />
                            <MentionSuggestions
                                open={mentionOpen}
                                onOpenChange={onOpenChange}
                                suggestions={suggestions}
                                onSearchChange={onSearchChange}
                                onAddMention={onAddMention}
                                onClose={() => { }}
                            />
                        </div>

                    </div>
                    <div style={{ paddingBottom: 15 }}>
                        <TextField
                            id="start_date"
                            label="Set Reminder"
                            name="reminderDate"
                            onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}
                            type="datetime-local"
                            className={classes.textFieldWidth}
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </div>
                    <div style={{ paddingBottom: 15 }}>
                        <input
                            accept="*"
                            ref={createNoteFile}
                            className={classes.input}
                            id="project-document"
                            onChange={uploadCreateNoteImg}
                            type="file"
                        />
                        <label htmlFor="project-document">
                            {
                                fileUploaded && <CheckCircleIcon />
                            }
                        </label>
                    </div>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={() => setOpenNotesModal(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={saveNotes} color="primary">
                        Save Note
                    </Button>
                </DialogActions>
            </Dialog>


            {/* Send Mail to customer */}
            <Dialog open={openMailModel} onClose={() => setOpenMailModel(false)} aria-labelledby="form-dialog-title" maxWidth='sm'>
                <DialogTitle id="form-dialog-title">Send Email to Customer</DialogTitle>
                <Divider />
                <DialogContent>
                    <TextField
                        id="pending_payment_date"
                        label="Pending Payment Date"
                        name="pendingPaymentDate"
                        onChange={(e) => setSendMailCustomerData({ ...sendMailCustomerData, [e.target.name]: e.target.value })}
                        className={classes.textFieldWidth}
                        type="date"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        style={{ marginBottom: 20, textAlign: "center", marginTop: 20 }}
                    />
                    <TextField label="Amount Due" className={classes.textFieldWidth} size="small" style={{ marginBottom: 20, textAlign: "center" }} name="amountDue" onChange={(e) => setSendMailCustomerData({ ...sendMailCustomerData, [e.target.name]: e.target.value })} variant="outlined" />
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={() => setOpenMailModel(false)} color="secondary" variant="outlined">
                        Cancel
                    </Button>
                    <Button onClick={sendEmailToCustomer} color="primary" variant="outlined">
                        Send Email
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openClientModel} onClose={() => setOpenClientModel(false)} aria-labelledby="form-dialog-title" maxWidth='sm'>
                <DialogTitle id="form-dialog-title">Extra days due to client dependency</DialogTitle>
                <Divider />
                <DialogContent>
                    <TextField label="Extra days due to client dependency" type="number" size="small" style={{ marginBottom: 20, textAlign: "center" }} name="extraDaysDueToClientDependency" onChange={(e) => setClientDelay({ [e.target.name]: e.target.value })} variant="outlined" />
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={() => setOpenClientModel(false)} color="secondary" variant="outlined">
                        Cancel
                    </Button>
                    <Button onClick={clientDelayfunc} color="primary" variant="outlined">
                        Update
                    </Button>
                </DialogActions>
            </Dialog>

            {/* customer Payment update model */}
            <Dialog open={openPaymentUpdateModel} onClose={() => setOpenPaymentUpdateModel(false)} aria-labelledby="form-dialog-title" maxWidth='sm'>
                <DialogTitle id="form-dialog-title">Update Customer Payment</DialogTitle>
                <Divider />
                <DialogContent>
                    <TextField label="Amount" className={classes.textFieldWidth} size="small" style={{ marginBottom: 20, textAlign: "center" }} name="amount" onChange={(e) => setSendPaymentUpdateData({ ...sendPaymentUpdateData, [e.target.name]: e.target.value })} variant="outlined" />
                    <br />
                    <div style={{ paddingBottom: 15, paddingTop: 10, display: 'inline-flex' }} variant="outlined" size="small">
                        <input
                            accept="*"
                            className={classes.input}
                            id="paymentAttachement-document"
                            onChange={(e) => uploadWDFileDocument(e, 'paymentReceived')}
                            type="file"
                            style={{ display: 'none' }}
                        />
                        <label htmlFor="paymentAttachement-document">
                            <Button variant="contained" color="primary" style={{ background: "#716ACA", margin: 5 }} component="span">
                                Upload Payment Received Attachment
                            </Button>
                        </label>
                        {isFinalPaymentReceived && <CheckCircleIcon style={{ color: "green" }} />}
                    </div>
                    <br />
                    <TextField label="Note" className={classes.textFieldWidth} size="small" style={{ marginBottom: 20, textAlign: "center" }} name="note" onChange={(e) => setSendPaymentUpdateData({ ...sendPaymentUpdateData, [e.target.name]: e.target.value })} variant="outlined" />
                    <br />
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={() => setOpenPaymentUpdateModel(false)} color="secondary" variant="outlined">
                        Cancel
                    </Button>
                    <Button onClick={updateCustomerPaymentAndSendToFinance} color="primary" variant="outlined">
                        Update Customer Payment
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Move Deal Model window */}
            <Dialog open={moveDealOpen} onClose={() => setMoveDealOpen(false)}>
                <DialogTitle>Change Stage</DialogTitle>
                <DialogContent>
                    <Box sx={{ minWidth: 450 }}>
                        <FormControl fullWidth variant="outlined" size="small">
                            <InputLabel id="demo-simple-select-label">Select Stage</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedStage}
                                label="Select Stage"
                                variant="outlined"
                                onChange={handleSelectedStageChange}
                            >
                                {departmentStage.map((row, i) => {
                                    return (
                                        <MenuItem key={i + 1} value={row}>{row}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setMoveDealOpen(false)} variant="outlined" color="primary">Cancel</Button>
                    <Button onClick={openAnotherDialog} variant="contained" color="primary">Change</Button>
                </DialogActions>
            </Dialog>
            <Dialog classes={{ paper: classes.dialogPaper }} open={openUpdateModel} onClose={() => setOpenUpdateModel(false)} aria-labelledby="form-dialog-title" maxWidth='sm'>
                <DialogTitle id="form-dialog-title">Under Execution</DialogTitle>
                <Divider />
                <DialogContent>
                    <TextField
                        id="material_Dispatched_Date"
                        label="Material Dispatched Date"
                        name="materialDispatchedDate"
                        onChange={(e) => setupdatestatus({ ...updatestatus, [e.target.name]: e.target.value })}
                        className={classes.textFieldWidth}
                        type="date"
                        size="small"
                        inputProps={{
                            max: moment().format('YYYY-MM-DD'),
                            onKeyDown: (e) => e.preventDefault() 
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        style={{ marginBottom: 20, textAlign: "center", marginTop: 20 }}
                    />
                    {/* <TextField label="Amount Due" className={classes.textFieldWidth} size="small" style={{ marginBottom: 20, textAlign: "center" }} name="amountDue" onChange={(e) => setSendMailCustomerData({ ...sendMailCustomerData, [e.target.name]: e.target.value })} variant="outlined" /> */}
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={() => setOpenUpdateModel(false)} color="secondary" variant="outlined">
                        Cancel
                    </Button>
                    {/* <Button onClick={changeStatusMaterialDispatched} color="primary" variant="outlined"> */}
                    <Button onClick={changeStatusUnderExecution} color="primary" variant="outlined">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>


            {/* Dialog for select the details of previous dialog selected stage */}
            <Dialog open={openMoveDealDetailsDialog} onClose={() => setOpenMoveDealDetailsDialog(false)}>
                <DialogTitle>Select Details</DialogTitle>
                <DialogContent>
                    <Box sx={{ minWidth: 450 }}>
                        {/* Select Department */}
                        <FormControl fullWidth style={{ marginBottom: "15px" }} variant="outlined" size="small">
                            <InputLabel id="demo-simple-select-label">Select Department</InputLabel>
                            <Select
                                labelId="demo-simple-select-label1"
                                id="demo-simple-select1"
                                value={department}
                                label="Select Stage"
                                onChange={handleSelectedDepartmentChange}
                                variant="outlined"
                                size="small"
                            >
                                {departmentLists.map((row, i) => {
                                    return (
                                        <MenuItem key={i + 1} value={row._id}>{row.name}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                        {/* Select Team */}
                        <FormControl fullWidth style={{ marginBottom: "15px" }} variant="outlined" size="small">
                            <InputLabel id="demo-simple-select-label2">Select Team</InputLabel>
                            <Select
                                labelId="demo-simple-select-label2"
                                id="demo-simple-select2"
                                value={team}
                                label="Select Team"
                                onChange={handleSelectedTeamChange}
                                variant="outlined"
                                size="small"
                            >
                                {teamLists.map((row, i) => {
                                    return (
                                        <MenuItem key={i + 1} value={row._id}>{row.name}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                        {/* Select Deal Owner User */}
                        <FormControl fullWidth style={{ marginBottom: "15px" }} variant="outlined" size="small">
                            <InputLabel id="demo-simple-select-label3">Select User</InputLabel>
                            <Select
                                labelId="demo-simple-select-label3"
                                id="demo-simple-select3"
                                value={dealOwner}
                                label="Select User"
                                onChange={handleSelectedUserChange}
                                variant="outlined"
                                size="small"
                            >
                                {dealOwnerLists.map((row, i) => {
                                    return (
                                        <MenuItem key={i + 1} value={row._id}>{row.name}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenMoveDealDetailsDialog(false)} variant="outlined">Cancel</Button>
                    <Button variant="contained" color="primary" onClick={() => assignLeadToUser()}>Assign Project</Button>
                </DialogActions>
            </Dialog>

            {/* Change Stage Status Modal */}
            <Dialog open={openStageStatusModal} onClose={() => setOpenStageStatusModal(false)} aria-labelledby="form-dialog-title" maxWidth='sm'>
                <DialogTitle id="form-dialog-title">
                    Update Stage Staus
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <div style={{ paddingBottom: 15, marginTop: 15 }}>
                        <FormControl fullWidth variant="outlined" size="small">
                            <InputLabel id="demo-simple-select-label">Select Stage Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedStageStatus}
                                label="Select Stage Status"
                                variant="outlined"
                                onChange={(e) => handleSelectedStageStatus(e)}
                            >
                                <MenuItem key='1' value='Under Production - Machine'>Under Production - Machine</MenuItem>
                                <MenuItem key='2' value='Under Production - Fixing'>Under Production - Fixing</MenuItem>
                                <MenuItem key='3' value='Ready for dispatch'>Ready for dispatch</MenuItem>
                            </Select>
                        </FormControl>
                        {
                            selectedStageStatus === 'Ready for dispatch' &&
                            <FormControl fullWidth style={{ paddingTop: 20 }} variant="outlined" size="small">
                                <TextField
                                    style={{ margin: '5px' }}
                                    id="dispatch-Date"
                                    name="dispatchDate"
                                    value={moment(dispatchDate).format('YYYY-MM-DD')}
                                    onChange={(e) => setDispatchDate(e.target.value)}
                                    label="Ready to dispatch date"
                                    type="date"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    variant="outlined"
                                    size="small"
                                />
                            </FormControl>
                        }
                    </div>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={() => setOpenStageStatusModal(false)} variant="outlined" color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={() => updateStageStatus()} variant="outlined" color="primary">
                        Update Stage Status
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Update Material Received Status Modal */}
            <Dialog open={openUpdateMaterialStatusModal} onClose={() => setOpenUpdateMaterialStatusModal(false)} aria-labelledby="form-dialog-title" maxWidth='sm'>
                <DialogTitle id="form-dialog-title">
                    Update Material Received Status
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <div style={{ paddingBottom: 15, marginTop: 15 }}>
                        <FormControl fullWidth variant="outlined" size="small">
                            <InputLabel id="demo-simple-select-label">Select Material Received Status </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedMaterilPercentage}
                                label="Select Stage Status"
                                variant="outlined"
                                onChange={(e) => setSelectedMaterilPercentage(e.target.value)}
                            >
                                <MenuItem key='1' value='Yes'>Yes</MenuItem>
                                <MenuItem key='4' value='No'>No</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={() => setOpenUpdateMaterialStatusModal(false)} variant="outlined" color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={() => updateMaterialReceived()} variant="outlined" color="primary">
                    Update Material Received Status
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}


export default connect(null, { setLoading, setSnackInfo })(ManageReadyToDispatchProjectLogs)