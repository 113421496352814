import React, { useState } from 'react'
import { Typography, TextField, Button } from '@material-ui/core';
import { setSnackInfo, setLoading } from '../../redux/actions/appAction';
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom';
import HttpService from '../../services/httpService';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  input: {
    color: "white",
    borderBottom: "1px solid #fff",
  },
  inputLabel: {
    color: "#aca9a9 !important",
  },
  icon: {
    color: 'white',
  },
  sendPasswordButton: { backgroundColor: 'white', marginTop: '10%', color: '#2c2e3e', margin: 'center', padding: 5, borderRadius: '50px', width: '100px', "&:hover": { backgroundColor: "#3f51b5", color: 'white' } },
});

const ForgotPassword = (props) => {

  const classes = useStyles();

  const [email, setEmail] = useState("")
  
  const history = useHistory()

  const forgotPassword = async () => {
  
    props.setLoading(true)
  
    try {
  
      await HttpService.forgotPassword({email})
  
      props.setSnackInfo('Please Check Email', "success")
  
      history.push('/');
  
    } catch (error) {
  
      console.error(error)
  
      props.setSnackInfo(error?.response?.data?.message || error?.response?.data || "Something went wrong", "error")
  
    }
  
    props.setLoading(false)
  
  }

  // const handleSendOTP = async () => {
  //   props.setLoading(true)
  //   try {
  //     console.log('email typed', email)
  //     // await HttpService.forgotPassword({email})
  //     props.setSnackInfo('Please Check Email', "success")
  //     // history.push('/');
  //   } catch (error) {
  //     console.error(error)
  //     props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
  //   }
  //   props.setLoading(false)
  // }

  return (
    <div style={{ display: "flex", justifyContent: "center", marginTop: "25vh" }}>
      
      <div>
      
        <Typography variant="h4" gutterBottom>
          Forgot Password
        </Typography>
      
        <div style={{ margin: 20 }}></div>
      
        <div>
      
          <TextField fullWidth id="outlined-basic" label="Email" variant="outlined" value={email} onChange={(e) => setEmail(e.target.value)} />
      
          <div style={{ display: 'flex', justifyContent: "flex-end", marginTop: 10 }}>
      
            <Button className={classes.sendPasswordButton} variant="contained" color="primary" onClick={forgotPassword}>
              Send
            </Button>
      
            {/* <Button disabled={email.length < 1} variant="contained" color="primary" onClick={handleSendOTP}>
              Send OTP
            </Button> */}
      
          </div>
      
        </div>
      
      </div>
    
    </div>
  )
}

export default connect(null, { setLoading, setSnackInfo })(ForgotPassword)
