export const commaSeparateAmount = (value) => {
    if (value === undefined) return ' ';
    
    // Round the number to two decimal places
    const roundedValue = Number(value).toFixed(2);

    // Convert the rounded value to a string
    let val = String(roundedValue);

    let finalval = '';
    let j = 0;
    let flag = 0;
    let isNegative = false;
    let decimalPart = '';

    // Check if the value is negative
    if (val[0] === '-') {
        isNegative = true;
        val = val.slice(1);
    }

    // Split into integer and decimal parts
    const parts = val.split('.');
    let integerPart = parts[0];
    if (parts.length > 1) {
        decimalPart = '.' + parts[1];
    }

    // Process integer part for comma separation
    for (let i = integerPart.length - 1; i > -1; i--) {
        finalval = integerPart[i] + finalval;
        j++;

        if (j === 3 && i !== 0) {
            finalval = ',' + finalval;
            j = 0;
            flag = 1;
        }

        if (flag === 1 && i !== 0 && j === 2) {
            finalval = ',' + finalval;
            j = 0;
        }
    }

    // Combine integer and decimal parts
    finalval += decimalPart;
    
    return isNegative ? '-' + finalval : finalval;
};
