import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { connect, useSelector } from 'react-redux'
import { setLoading, setSnackInfo } from '../../redux/actions/appAction';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import HttpService from '../../services/httpService';
import RadioGroup from '@material-ui/core/RadioGroup';
import { ROLENAME } from '../../utils/index';
import { Typography, Card, CardContent } from '@material-ui/core';
import moment from 'moment'
import { commaSeparateAmount } from '../../components/commaSeparate';

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 700,
    },
    root: {
        width: '100%',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    container: {
        maxHeight: 450,
    },
}));
const ViewCustomerCheckList = (props) => {
    const classes = useStyles();
    const { leadId } = useParams();
    const { userDetails } = useSelector(state => state.user)
    const [checkList, setCheckList] = useState([
        { ids: 1, id: '1', description: 'Basic False ceiling is considered in preliminary quote (subject to variation on actual designs)', yes: '', no: '', na: '' },
        { ids: 2, id: '1(a)', description: 'False ceiling paint - Present in quotation', yes: '', no: '', na: '' },
        { ids: 3, id: '1(b)', description: 'Lights and electrical - Present in quotation (approx)', yes: '', no: '', na: '' },
        { ids: 4, id: '2', description: 'Final Charges for Electrical works will be quoted after design finalization', yes: '', no: '', na: '' },
        { ids: 5, id: '3', description: 'Stone cladding charges quoted are without polish', yes: '', no: '', na: '' },
        { ids: 6, id: '4', description: 'Agreed Discount % is as per mentioned in the field "Disc agreed". Under No event, discount% will change.', yes: '', no: '', na: '' },
        { ids: 7, id: '5', description: 'As a gesture of goodwill, the company is waiving MDR charges for the bank for the payments towards the design initiation. However, if payment is made through a method other than net banking or UPI, the Bank`s MDR charges will still apply and the customer will be responsible for paying them.', yes: '', no: '', na: '' },
        { ids: 8, id: '6', description: 'Decorpot, at no time, while the project is ongoing, will fix any accessories or HW which are not supplied by Decorpot.', yes: '', no: '', na: '' }
    ]);
    const [data, setData] = useState({
        grandTotal: "",
        paymentDone: "",
        staticSalesWonAmount : ""
    })

    const { grandTotal, paymentDone, staticSalesWonAmount} = data

    useEffect(() => {
        getLeadDetails();
        getNotesData();
        getLastQuotationForProject();
        // eslint-disable-next-line
    }, [leadId])

    const [leadDetail, setLeadDetails] = useState([]);
    const [selected, setSelected] = useState([]);
const [spaceAndProductNames, setSpaceAndProductNames] = useState([]);
const [complimentaryItemPresent, setComplimentaryItemPresent] = useState(false);
    let [salesUserdata, setSalesUserdata] = useState("")

    const getLeadDetails = async () => {
        props.setLoading(true)
        try {
            const response = await HttpService.getLeadDetails(leadId);
            setLeadDetails(response.data)
            let salesUser = [];
            for (let i = 0; i < response.data.previouslyAssignedTo.length; i++) {
                for (let j = 0; j < response.data.previouslyAssignedTo[i].roles.length; j++) {
                    if (response.data.salesLeadOwnRole === 'SalesUser' && response.data.previouslyAssignedTo[i]['roles'][j].name === 'Sales User') {
                        salesUser.push(response.data.previouslyAssignedTo[i])
                    } else if (response.data.salesLeadOwnRole === 'SalesManager' && response.data.previouslyAssignedTo[i]['roles'][j].name === 'Sales Manager') {
                        salesUser.push(response.data.previouslyAssignedTo[i])
                    }
                }
            }
            let finalSalesUser = salesUser.pop();
            setSalesUserdata(finalSalesUser.name)
            const { grandTotal, paymentDone, staticSalesWonAmount } = response.data
            setData({ grandTotal, paymentDone, staticSalesWonAmount })
            if (response.data.salesCheckList.length !== 0) {
                setCheckList(response.data.salesCheckList);
                setSelected(response.data.salesCheckList);
            } else {
                setCheckList([
                    { ids: 1, id: '1', description: 'Basic False ceiling is considered in preliminary quote (subject to variation on actual designs)', yes: '', no: '', na: '' },
                    { ids: 2, id: '1(a)', description: 'False ceiling paint - Present in quotation', yes: '', no: '', na: '' },
                    { ids: 3, id: '1(b)', description: 'Lights and electrical - Present in quotation (approx)', yes: '', no: '', na: '' },
                    { ids: 4, id: '2', description: 'Final Charges for Electrical works will be quoted after design finalization', yes: '', no: '', na: '' },
                    { ids: 5, id: '3', description: 'Stone cladding charges quoted are without polish', yes: '', no: '', na: '' },
                    { ids: 6, id: '4', description: 'Agreed Discount % is as per mentioned in the field "Disc agreed". Under No event, discount% will change.', yes: '', no: '', na: '' },
                    { ids: 7, id: '5', description: 'As a gesture of goodwill, the company is waiving MDR charges for the bank for the payments towards the design initiation. However, if payment is made through a method other than net banking or UPI, the Bank`s MDR charges will still apply and the customer will be responsible for paying them.', yes: '', no: '', na: '' },
                    { ids: 8, id: '6', description: 'Decorpot, at no time, while the project is ongoing, will fix any accessories or HW which are not supplied by Decorpot.', yes: '', no: '', na: '' }
                ])
            }
        } catch (error) {
            props.setSnackInfo(error?.response?.data ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }

    const [notes, setNotes] = useState([]);
    const getNotesData = async () => {
        const notesList = [
            { id: 1, value: 'Only things which are specifically mentioned in the quotation are included in the scope of work. Rest all are Client Scope' },
            { id: 2, value: 'Removal & Re Fixing of existing granite slabs(Extra costs applicable), \n Existing granite needs to be removed in case fixed on angles \n If existing granite, fixed on angles, is not wished to be removed, the kitchen cost will be on carpentry basis' },
            { id: 3, value: 'Stone / Marble/ Quartz / Granite are bought in slabs and will be charged accordingly' },
            { id: 4, value: 'Gas copper piping will be charged as per actuals' },
            { id: 5, value: 'Decorpot Exclusive handles are considered in all furniture. (Any handles other than above like G-profile, C-profile, J-profile, pull grip & push to open are charged additionally, Customized handle price is charged additionally based on design)' },
            { id: 6, value: 'Decorpot Furnitures comes with Hettich Hinges and channels (Non- Soft close). Soft close, if required needs to be added additonally in the quotation' },
            { id: 7, value: 'Basic Pooja unit is considered for preliminary quotation. (subject to variation based on the finalized design, size and material)' },
            { id: 8, value: 'Booking Advance  -  Booking advance is non-refundable' },
            { id: 9, value: 'Payment terms' },
            { id: "a)", value: "  10% of the quotation for design meetings and booking confirmation." },
            { id: "b)", value: '   50% upon signing of the contract.' },
            { id: "c)", value: " 40% before delivery of the modular furnitures at the site." },
            { id: 10, value: 'Sales quotation is tentative and not the final value of project' },
            { id: 11, value: 'Final Value of project will depend on final designs and measurements, any changes in materials' },
            { id: 12, value: "Dates committed  for design can only be met from the day site is ready and allowed for initial measurement" },
            { id: 13, value: "If design sign-off is planned to happen after 3 months of the designer's assigned date, the Designer can only do 1-hour meeting per week." }

        ]
        setNotes(notesList)
    }
    
    const getLastQuotationForProject = async () => {
        props.setLoading(true)
        try {
            const response = await HttpService.getAllQuotationsForProject(leadId)
            // setQuotationList(response.data)
            if (response.data.length > 0) {
                const sortedQuotations = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                // setFinalQuotation(sortedQuotations[0]);
                // Assuming sortedQuotations is an array of Quotation objects and each Quotation object has a 'components' array
                const components = sortedQuotations[0].components;
                const extractedSpacesAndProducts = [];
                components.forEach((component) => {
                    const complimentaryProduct = component.products
                        .flatMap((product) => product.product)
                        .find((product) => product.isComplimentaryItem);
                    
                    // const complimentaryFinish = component.products
                    //     .flatMap((product) => product.finishType)
                    //     .find((finishType) => finishType.isComplimentaryItem)
                    if (complimentaryProduct) {
                        setComplimentaryItemPresent(true)
                        const spaceName = component.categoryId?.name || '';

                        
                        const productNamesInSpace = component.products
                            .flatMap((product) => product)
                            .filter((product) => product.product.isComplimentaryItem)
                            .map((product) => ({
                                name: product.product.name,
                                area: product.area
                            }));                      

                        extractedSpacesAndProducts.push({ spaceName, chApproval: true, productNames: productNamesInSpace});
                    }
                });
                setSpaceAndProductNames(extractedSpacesAndProducts)
            }
        } catch (error) {
            console.error(error)
            props.setSnackInfo(error?.response?.data?.message ?? "Something went wrong", "error")
        }
        props.setLoading(false)
    }
    return (
        <div>
            <div style={{ marginBottom: 20 }}>
                <Typography variant="h5" component="h2" style={{ textAlign: 'center' }}><strong>Customer Check List</strong></Typography>
            </div>

            <div style={{ margin: 'auto' }}>
                <Paper>
                    <Card className={classes.root} variant="outlined">
                        <CardContent>
                            <Typography variant="h5" component="h2"><strong>Client Master Data</strong></Typography>
                            <hr />
                            <TableContainer component={Paper} style={{ width: '100%' }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableBody>
                                        <TableRow key='1'>
                                            <TableCell style={{ padding: '5px' }} component="th" scope="row">Customer Name</TableCell>
                                            <TableCell style={{ padding: '5px' }} component="th" scope="row">{leadDetail?.customerId?.name}</TableCell>
                                        </TableRow>
                                        <TableRow key='2'>
                                            <TableCell style={{ padding: '5px' }} component="th" scope="row">Property Name</TableCell>
                                            <TableCell style={{ padding: '5px' }} component="th" scope="row">{leadDetail?.propertyType} - {leadDetail?.customerId?.address}</TableCell>
                                        </TableRow>
                                        <TableRow key='3'>
                                            <TableCell style={{ padding: '5px' }} component="th" scope="row">Contact Number</TableCell>
                                            <TableCell style={{ padding: '5px' }} component="th" scope="row">{leadDetail?.customerId?.contact_no}</TableCell>
                                        </TableRow>
                                        <TableRow key='4'>
                                            <TableCell style={{ padding: '5px' }} component="th" scope="row">Email Id</TableCell>
                                            <TableCell style={{ padding: '5px' }} component="th" scope="row">{leadDetail?.customerId?.email}</TableCell>
                                        </TableRow>
                                        <TableRow key='5'>
                                            <TableCell style={{ padding: '5px' }} component="th" scope="row">Sales Executive</TableCell>
                                            <TableCell style={{ padding: '5px' }} component="th" scope="row">{salesUserdata}</TableCell>
                                        </TableRow>
                                        <TableRow key='6'>
                                            <TableCell style={{ padding: '5px' }} component="th" scope="row">Tentative Possession/Registration Date</TableCell>
                                            <TableCell style={{ padding: '5px' }} component="th" scope="row">{moment(leadDetail?.possessionDate).format('DD-MM-YYYY')}</TableCell>
                                        </TableRow>
                                        <TableRow key='7'>
                                            <TableCell style={{ padding: '5px' }} component="th" scope="row">Tentative Design Closure (Contract) Sign Date</TableCell>
                                            <TableCell style={{ padding: '5px' }} component="th" scope="row">{moment(leadDetail?.closureDate).format('DD-MM-YYYY')}</TableCell>
                                        </TableRow>
                                        <TableRow key='8'>
                                            <TableCell style={{ padding: '5px' }} component="th" scope="row">Tentative Client Move-in Date</TableCell>
                                            <TableCell style={{ padding: '5px' }} component="th" scope="row">{moment(leadDetail?.clientMoveinDate).format('DD-MM-YYYY')}</TableCell>
                                        </TableRow>
                                        <TableRow key='9'>
                                            <TableCell style={{ padding: '5px' }} component="th" scope="row">Lead Won Date</TableCell>
                                            <TableCell style={{ padding: '5px' }} component="th" scope="row">{moment(leadDetail?.leadWonDate).format('DD-MM-YYYY')}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                </Paper>
                <br />
                <Paper>
                    <Card className={classes.root} variant="outlined">
                        <CardContent>
                            <Typography variant="h5" component="h2"><strong>Quotation Primary Data</strong></Typography>
                            <hr />
                            <TableContainer component={Paper} style={{ width: '100%' }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableBody>
                                        <TableRow key='8'>
                                            <TableCell style={{ padding: '5px' }} component="th" scope="row">Tentative Sales Quotation</TableCell>
                                            <TableCell style={{ padding: '5px' }} component="th" scope="row">₹ {(staticSalesWonAmount !== null && staticSalesWonAmount !== undefined && staticSalesWonAmount !== '') ? commaSeparateAmount(staticSalesWonAmount) : commaSeparateAmount(grandTotal)}</TableCell>
                                        </TableRow>
                                        <TableRow key='9'>
                                            <TableCell style={{ padding: '5px' }} component="th" scope="row">Disc agreed</TableCell>
                                            <TableCell style={{ padding: '5px' }} component="th" scope="row">{leadDetail?.discountPercent}</TableCell>
                                        </TableRow>
                                        <TableRow key='10'>
                                            <TableCell style={{ padding: '5px' }} component="th" scope="row">Total Amount Paid</TableCell>
                                            <TableCell style={{ padding: '5px' }} component="th" scope="row">₹ {commaSeparateAmount(paymentDone)}</TableCell>
                                        </TableRow>
                                        <TableRow key='11'>
                                            <TableCell style={{ padding: '5px' }} component="th" scope="row">Upload Payment Attachment</TableCell>
                                            <TableCell style={{ padding: '5px' }} component="th" scope="row">
                                                <Button color="primary" variant="outlined" onClick={() => window.open(leadDetail?.salesPaymentProofAtachement)} style={{ marginLeft: 10 }} disabled={!leadDetail?.salesPaymentProofAtachement || leadDetail?.salesPaymentProofAtachement === 'NA'}>
                                                    View Payment
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow key='12'>
                                            <TableCell style={{ padding: '5px' }} component="th" scope="row">% Amount Paid</TableCell>
                                            <TableCell style={{ padding: '5px' }} component="th" scope="row">₹ { (staticSalesWonAmount !== null && staticSalesWonAmount !== undefined && staticSalesWonAmount !== '') ? commaSeparateAmount(((paymentDone / staticSalesWonAmount) * 100).toFixed(2)) :commaSeparateAmount(((paymentDone / grandTotal) * 100).toFixed(2))}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                </Paper>

                <br />
                {(leadDetail.salesComplimentaryItems?.length > 0 && (leadDetail.complimentaryItemStatus === "Approved" || leadDetail.complimentaryItemStatus === "Sent for Approval" || leadDetail.complimentaryItemStatus === "Rejected")) &&
                    <>
                        <Paper>
                            <Card className={classes.root} variant='outilned'>
                                <CardContent>
                                    <Typography variant='h5' component="h2"><strong>Complimentary Items</strong></Typography>

                                    <TableContainer component={Paper} className={classes.container}>
                                        <Table className={classes.table} stickyHeader aria-label='sticky table'>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell><strong>Space Name</strong></TableCell>
                                                    <TableCell><strong>Complimentary Items</strong></TableCell>
                                                    <TableCell><strong>Area(SqFt)/Quantity</strong></TableCell>
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                {leadDetail.salesComplimentaryItems.map((row, i) => {
                                                    return (
                                                        <TableRow key={i + 1}>
                                                            <TableCell>{row.spaceName}</TableCell>
                                                            <TableCell>
                                                                {row.productNames.map((product, j) => (
                                                                    <div key={j}>
                                                                        <TableCell>{product.name}</TableCell>
                                                                    </div>
                                                                ))}
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.productNames.map((product, j) => (
                                                                    <div key={j}>
                                                                        <TableCell>{product.area}</TableCell>
                                                                    </div>
                                                                ))}
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </CardContent>

                            </Card>
                        </Paper>
                    </>
                }
                <br />
                <Paper>
                    <Card className={classes.root} variant="outlined">
                        <CardContent>
                            <Typography variant="h5" component="h2"><strong>Quotation Inclusion/Exclusion</strong></Typography>
                            <hr />
                            <TableContainer component={Paper} className={classes.container}>
                                <Table className={classes.table} stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ textAlign: 'center', fontWeight: 700,borderLeft:"none",borderRight:"1px"}}>Sr. No.</TableCell>
                                            <TableCell style={{ textAlign: 'center', fontWeight: 700 }}>Description</TableCell>
                                            <RadioGroup defaultValue="abc" aria-label="action" name="customized-radios" style={{ display: 'table-cell', top: 0, left: 0, zIndex: 2, position: 'sticky', backgroundColor: "#fafafa" }}>
                                                <TableCell style={{ textAlign: 'center', fontWeight: 700 , height:"58.6px" ,width:"58.6px" }}>Yes</TableCell>
                                                <TableCell style={{ textAlign: 'center', fontWeight: 700, height:"58.6px" ,width:"58.6px" }}>No</TableCell>
                                                <TableCell style={{ textAlign: 'center', fontWeight: 700 ,height:"58.6px" ,width:"58.6px"}}>NA</TableCell>
                                            </RadioGroup>
                                            <RadioGroup defaultValue="abc" aria-label="action" name="customized-radios" style={{ display: 'table-cell', top: 0, left: 0, zIndex: 2, position: 'sticky', backgroundColor: "#fafafa" }}>
                                                <TableCell style={{ textAlign: 'center', fontWeight: 700,height:"58.6px" ,width:"58.6px" }}>Accept</TableCell>
                                                <TableCell style={{ textAlign: 'center', fontWeight: 700,height:"58.6px" ,width:"58.6px" }}>Reject</TableCell>
                                            </RadioGroup>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {checkList.map((row, i) => {
                                            const labelId = `enhanced-table-checkbox-${i}`;
                                            return (
                                                <TableRow key={i + 1}>
                                                    <TableCell style={{ padding: '5px', textAlign: 'center' }}>{row?.id}</TableCell>
                                                    <TableCell style={{ padding: '5px' }} component="th" scope="row">{row?.description}</TableCell>
                                                    <RadioGroup aria-label={{ 'aria-label': labelId + 1 }}
                                                        name="customized-radios"
                                                        value={row?.actionSelected}
                                                        style={{ display: 'block' }}>

                                                        <TableCell style={{ padding: '7.3px', textAlign: 'center' }} component="th">
                                                            <Radio
                                                                value='yes'
                                                                checked={row?.actionSelected === 'yes'}
                                                                name="radio-button-demo"
                                                                disabled
                                                                inputProps={{ 'aria-label': labelId }}
                                                            />
                                                        </TableCell>
                                                        <TableCell style={{ padding: '6px',height:"58.6px" ,width:"58.6px", textAlign: 'center' }} component="th">
                                                            <Radio
                                                                value='no'
                                                                checked={row?.actionSelected === 'no'}
                                                                name="radio-button-demo"
                                                                disabled
                                                                inputProps={{ 'aria-label': labelId }}
                                                            />
                                                        </TableCell>
                                                        <TableCell style={{ padding: '5.8px', height:"58.6px" ,width:"58.6px", textAlign: 'center' }} component="th">
                                                            <Radio
                                                                value='na'
                                                                checked={row?.actionSelected === 'na'}
                                                                name="radio-button-demo"
                                                                disabled
                                                                inputProps={{ 'aria-label': labelId }}
                                                            />
                                                        </TableCell>
                                                    </RadioGroup>

                                                    <RadioGroup aria-label={{ 'aria-label': labelId + 1 }}
                                                        name="customized-radios"
                                                        value={row?.customerAction}
                                                        style={{ display: 'table-cell' ,borderBottom:"none"}}>

                                                        <TableCell style={{ padding: '7.3px', textAlign: 'center', height:"58.6px" ,width:"81.59px",}} component="th">
                                                            <Radio
                                                                value='yes'
                                                                checked={row?.customerAction === 'yes'}
                                                                disabled
                                                                name="radio-button-demo"
                                                                inputProps={{ 'aria-label': labelId }}
                                                            />
                                                        </TableCell>
                                                        <TableCell style={{ padding: '7.3px', textAlign: 'center', height:"58.6px" ,width:"76.93px", }} component="th">
                                                            <Radio
                                                                value='no'
                                                                checked={row?.customerAction === 'no'}
                                                                disabled
                                                                name="radio-button-demo"
                                                                inputProps={{ 'aria-label': labelId }}
                                                            />
                                                        </TableCell>
                                                    </RadioGroup>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                </Paper>

                <br />
                <Paper>
                    <Card className={classes.root} variant="outlined">
                        <CardContent>
                            <Typography variant="h5" component="h2"><strong>Notes</strong></Typography>
                            <hr />
                            <TableContainer component={Paper} style={{ width: '100%' }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableBody>
                                        {
                                            notes.map((data, i) => (
                                                <TableRow key={i + 1}>
                                                    <TableCell style={{ padding: '5px', width: 80, textAlign: 'center' }} component="th" scope="row">{data.id}</TableCell>
                                                    <TableCell style={{ padding: '5px' }} component="th" scope="row">{data.value}</TableCell>

                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                </Paper>

                <br />
                <Paper>
                    <Card className={classes.root} variant="outlined">
                        <CardContent>
                            <Typography variant="h5" component="h2"><strong>Remarks</strong></Typography>
                            <hr />
                            <Typography variant="p" component="p"><strong>Sales Remark:</strong> {leadDetail?.checkListRemarkBySalesUser}</Typography>

                        </CardContent>
                    </Card>
                </Paper>
                <br />
            </div>
        </div>
    )
}

export default connect(null, { setLoading, setSnackInfo })(ViewCustomerCheckList)
