import React, { useState, useEffect } from 'react';
import PaymentReport from '../../components/paymentReport';
import { useSelector, connect } from 'react-redux';
import { useHistory, Link } from "react-router-dom";
import TopHeaderOption from '../../components/topHeaderOption';
import CalenderFilerData from '../../components/calenderFilterData';
import SalesUpview from '../../components/salesDashboardViewUp';
import { Divider, Paper, TextField, Button, FormControl, MenuItem, Grid, Radio, RadioGroup, FormControlLabel, FormLabel, InputLabel, Select, Input, Checkbox, ListItemText, Typography, Card, CardContent } from '@material-ui/core';
import { ROLENAME, designDepartment, salesDepartment } from '../../utils/index';
import { Table, TableBody, TableCell, TableContainer, TableRow, TableHead, Box, Accordion, AccordionSummary, AccordionDetails, AccordionActions } from '@material-ui/core';
import moment from 'moment';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import HttpService from '../../services/httpService'
import ProposedContractForMonth from '../../components/proposedContractForMonth';
import contractSignLeads from '../../components/contractSignLeads';
import ActiveCustomersList from '../../components/ActiveCustomersList';
import DesignHoldCustomerList from '../../components/DesignHoldCustomerList';
import DesignPerformance from '../../components/DesignPerformance';
import { setUserDetails } from '../../redux/actions/userAction';
import { setLoading, setSnackInfo } from '../../redux/actions/appAction';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ImosDashboard from '../../components/imosDashboard';
import ReadyToDispatchProject from '../readyToDispatchProject/projects';
import ExecutionBoard from  '../../components/executionDashboard';
import ContractSignLeads from '../../components/contractSignLeads';
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    tablealign: {
        display: "flex"
    },
    tablecontainer: {
        display: "flex",
        marginTop: "25px"
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));

const useStyles2 = makeStyles({
    table: {
        minWidth: 700,
    },
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    input: {
        flex: 1,
    },
    iconButton: {
        padding: 10,
        width: '10%'
    },
    divider: {
        height: 28,
        margin: 4,
    },
});
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#ccc",
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const Index = (props) => {
    const classes = useStyles();
    const classes2 = useStyles2();
    const history = useHistory();
    const { userDetails } = useSelector(state => state.user)
    const [startDate, setStartDate] = useState(userDetails.selectedStartDate ? userDetails.selectedStartDate : moment().subtract(1, 'months').format('YYYY-MM-DD'))
    const [endDate, setEndDate] = useState(userDetails.selectedEndDate ? userDetails.selectedEndDate : moment().add(1, 'days').format('YYYY-MM-DD'));
    const [expcenters, setExpcenters] = useState([]);
    const [defaultExpCenter, setDefaultExpCenter] = useState(userDetails.selectedExpCenter);
    const [defaultCity, setDefaultCity] = useState(userDetails.selectedLocation);
    const [cities, setCities] = useState([]);
    const [defaultExpCenterId, setDefaultExpCenterId] = useState(userDetails.selectedExpCenter);
    const [defaultCityId, setDefaultCityId] = useState(userDetails.selectedLocation);
    const [selectedLocationDashboard, setSelectedLocationDashboard] = useState()
    const [selectedFilterOption, setSelectedFilterOption] = useState(userDetails.selectedFilterOption ? userDetails.selectedFilterOption : 'leadWonDate');
    const [totalValue, setTotalValue] = useState(0)
    const [salesDataval, setSalesDataval] = useState([])
    const [sortedData, setSortedData] = useState([])
    const [totalCol, setTotalCol] = useState([]);
    const [rowTotal, setRowTotal] = useState([]);
    const [selectedExpcenters, setSelectedExpcenters] = useState([]);
    const [dumpExpCenters, setDumpExpcenters] = useState([]);
    const [TokenPercentage, setTokenPercentage] = useState("")
    const [chmLeadCount, setChmLeadCount] = useState([])
    const [dataFromChild, setDataFromChild] = useState(null);
    const [parentData,setParentData] = useState({})

    
    useEffect(() => {
        getLocationList();
        // if (userDetails?.roles?.find(role => role.name === ROLENAME.DesignUser || role.name === ROLENAME.DesignManager || role.name === ROLENAME.DesignHead || role.name === ROLENAME.CenterHead)) {
            if (userDetails?.roles?.find(role => role.name === ROLENAME.DesignUser || role.name === ROLENAME.DesignManager || role.name === ROLENAME.DesignHead || role.name === ROLENAME.CenterHead || role.name === ROLENAME.AssistantDesignManager|| role.name === ROLENAME.OperationDesignManager)) {
                getDesignData();
        }
        if (userDetails?.roles?.find(role => role.name === ROLENAME.CHM_Manager)) {
            get_chm_lead_counts();
        }
        if (userDetails?.roles?.find(role => role.name === ROLENAME.MarketingManager || role.name === ROLENAME.MarketingUser)) {
            getMarketingData();
        }
        if (userDetails?.roles?.find(role => role.name === ROLENAME.SalesHead || role.name === ROLENAME.SalesManager || role.name === ROLENAME.SalesUser || role.name === ROLENAME.AssistantSalesManager)) {
            
            
                getSalesData();
            
        }
        if (userDetails?.roles?.find(role => role.name === ROLENAME.SalesHead)) {
            getSalesDepartmentUsers();
        } else if (userDetails?.roles?.find(role => role.name === ROLENAME.SalesManager || role.name === ROLENAME.AssistantSalesManager)) {
            getTeamUsers();
        }
        if (userDetails?.roles?.find(role => role.name === ROLENAME.DocumentUploader)) {
            getDocumentUploaderCount()
        }
        if (userDetails?.roles?.find(role => role.name === ROLENAME.IMOSManager || role.name === ROLENAME.IMOSUser)) {
            getImosProjectData();
        }
        if (userDetails?.roles?.find(role => role.name === ROLENAME.Execution_Manager || role.name === ROLENAME.ExecutionUser))
        {
            getExecutionProjectCount()
        }
        // }if(userDetails?.roles?.find(role => role.name === ROLENAME.CenterHead || role.name === ROLENAME.BusinessHead)){
        //     yearWiseSlectedExpCenterData()
        //     getExperienceCenters();
        // }
        // eslint-disable-next-line
    }, [userDetails])

    const get_chm_lead_counts = async () => 
    {
        const response = await HttpService.get_chm_lead_counts(userDetails.selectedExpCenter)
        setChmLeadCount(response.data)
    }
    
    const [designData, setDesignData] = useState([]);

    const getDesignData = async () => {
        let obj = {};
        obj.startDate = startDate;
        obj.endDate = endDate;
        obj.expcenterId = userDetails.selectedExpCenter;
        props.setLoading(true)
        try {
            
            const response = await HttpService.getDesignData(obj)

            setDesignData(response.data);
        } catch (error) {
            
            console.log(error)
        }
        props.setLoading(false)
    }

    const [marketingData, setMarketingData] = useState([]);
    const getMarketingData = async () => {
        let data = {
            startDate: startDate,
            endDate: endDate,
            experienceCenterId: defaultExpCenterId
        }
        props.setLoading(true)
        try {
            const response = await HttpService.getLeadsOnSelectedDate(data)
            setMarketingData(response?.data);
        } catch (error) {
            console.error(error)
        }
        props.setLoading(false)
    }

    const handleChangeFilterOption = (event) => {
        setSelectedFilterOption(event.target.value);
    };

    const [salesData, setSalesData] = useState([]);
    const getSalesData = async () => {
        let data = {
            startDate: startDate,
            endDate: endDate,
            experienceCenterId: defaultExpCenterId,
            filterOption: selectedFilterOption
        }
        props.setLoading(true)
        try {
            const response = await HttpService.getSalesLeadOnSelectedDate(data)
            setSalesData(response.data);
        } catch (error) {
            console.error(error)
        }
        
        props.setLoading(false)
    }

    const getLocationList = async () => {
        props.setLoading(true)
        try {
            const response = await HttpService.getLocationList()
            let userLocation = [];
            if (userDetails?.roles?.find(role => role.name === ROLENAME.Admin)) {
                setCities(response.data)
            } else {
                // setCities(response.data.filter(ele => ele._id === userDetails.locationId._id))
                for (let i = 0; i < response.data.length; i++) {
                    for (let j = 0; j < userDetails.locationId.length; j++) {
                        if (response.data[i]._id === userDetails.locationId[j]._id) {
                            userLocation.push({
                                _id: userDetails.locationId[j]._id,
                                name: userDetails.locationId[j].name
                            })
                        }
                    }
                }
                setCities(userLocation)
            }
            const ddd = userLocation.filter(ele => ele._id === userDetails.selectedLocation);
            setDefaultCity(ddd[0]['name'])
            setDefaultCityId(ddd[0]['_id'])
            setSelectedLocationDashboard(ddd[0]['_id'])
            getExperienceCenterByLocation(ddd[0]['_id'])
        } catch (error) {
            console.log(error)
        }
        props.setLoading(false)
    }

    const [locationExpCenters, setLocationExpCenters] = useState([]);
    const getExperienceCenterByLocation = async (locid) => {
        props.setLoading(true)
        try {
            const response = await HttpService.getExpericenCentersByLocation(locid)
            let userExpCenters = [];
            for (let i = 0; i < response.data.length; i++) {
                for (let j = 0; j < userDetails.experienceCenterId.length; j++) {
                    if (userDetails.experienceCenterId[j] === response.data[i]['_id']) {
                        userExpCenters.push({
                            name: response.data[i]['name'],
                            _id: response.data[i]['_id']
                        });
                    }
                }
            }
            setLocationExpCenters(response.data)
            setExpcenters(userExpCenters);
            const finalselected = userExpCenters.filter(ele => ele._id === userDetails.selectedExpCenter)
            setDefaultExpCenter(finalselected[0]['name']);
            setDefaultExpCenterId(finalselected[0]['_id'])
        } catch (error) {
            console.log(error)
        }
        props.setLoading(false)
    }

    const getExperienceCenter = async (e) => {
        try {
            const ddd = cities.filter(ele => ele.name === e.target.value);
            setDefaultCity(ddd[0]['name'])
            setSelectedLocationDashboard(ddd[0]['_id'])
            await getExperienceCenterByLocation(ddd[0]['_id'])
        } catch (error) {
            console.log(error)
        }
    }

    const setExp = (e) => {
        if (e.target.value === 'All') {
            setDefaultExpCenter('All')
        } else {
            let dataval = expcenters.filter(ele => ele.name === e.target.value);
            setDefaultExpCenter(e.target.value)
            setDefaultExpCenterId(dataval[0]['_id'])
        }
    }

    const [dates, setDates] = useState({
        start: startDate,
        end: endDate
    });

    const [personName, setPersonName] = useState([]);
    const [teamUsers, setTeamUsers] = useState([]);
    const [salesDepartmentUsers, setSalesDepartmentUsers] = useState([]);
    const [selectedUserData, setSelectedUserData] = useState([]);

    const handleChange = async (event, values) => {
        
        
        props.setLoading(true)
        try
        {
            setPersonName(values);            
            if (userDetails?.roles?.find(role => role.name === ROLENAME.SalesManager || role.name === ROLENAME.AssistantSalesManager)) {
                await getSelectedUserData(values)
            } else {
                await getSelectedSalesHeadUserData(values)
            }
        }
        catch(err)
        {
            console.log(err.message)
            console.error(err)
        }
        props.setLoading(false)
    };
    

    const getSelectedUserData = async (userList) => {
        let userIds = [];
        for (let i = 0; i < teamUsers.length; i++) {
            for (let j = 0; j < userList.length; j++) {
                if (teamUsers[i].name === userList[j].name) {
                    userIds.push(teamUsers[i]._id)
                }
            }
        }
        let obj = {
            assignTo: userIds,
            startDate: dates.start,
            endDate: dates.end,
            experienceCenterId: defaultExpCenterId,
            filterOption: selectedFilterOption
        }
        props.setLoading(true)
        try {
            const response = await HttpService.getSelectedUsersRecords(obj)
            setSelectedUserData(response.data);
            userList.length > 0 ? setSalesData(response.data) : getSalesData()
        } catch (error) {
            console.error(error)
        }
        props.setLoading(false)
        
    }

    const getSelectedSalesHeadUserData = async (userList) => {
        let salesHeadSelectedUsers = [];
        for (let i = 0; i < salesDepartmentUsers.length; i++) {
            for (let j = 0; j < userList.length; j++) {
                if (salesDepartmentUsers[i].name === userList[j].name) {
                    salesHeadSelectedUsers.push(salesDepartmentUsers[i]._id)
                }
            }
        }
        
        let obj = {
            assignTo: salesHeadSelectedUsers,
            startDate: dates.start,
            endDate: dates.end,
            experienceCenterId: defaultExpCenterId,
            filterOption: selectedFilterOption
        }
        props.setLoading(true)
        try {
            const response = await HttpService.getSelectedUsersRecords(obj)
            setSelectedUserData(response.data);
            userList.length > 0 ? setSalesData(response.data) : getSalesData()
        } catch (error) {
            console.error(error)
        }
        props.setLoading(false)
    }

    const getTeamUsers = async () => {
        props.setLoading(true)
        try {
            const response = await HttpService.getTeamUsersList(userDetails.teamId._id)
            let sortedResponse = response.data.sort((a, b) => a.name.localeCompare(b.name))
            setTeamUsers(sortedResponse);
        } catch (error) {
            console.error(error)
        }
        props.setLoading(false)
    }
    const [docUploaderCount, setDocUploaderCount] = useState({})
    const getDocumentUploaderCount = async () => {
        props.setLoading(true)
        try {
            const response = await HttpService.getDocumentUploaderCount(userDetails.selectedExpCenter)
            setDocUploaderCount(response?.data);
        } catch (error) {
            console.error(error)
        }
        props.setLoading(false)
    }

    const [imostProjectsCount, setImosProjectsCount] = useState([])
    const getImosProjectData = async () => {
        props.setLoading(true)
        try {
            const response = await HttpService.getImosProjectCount(userDetails.selectedExpCenter)
            setImosProjectsCount(response?.data);
        } catch (error) {
            console.error(error)
        }
        props.setLoading(false)
    }

    const [executionProjectsCount,setExecutionProjectsCount] = useState([])
    const getExecutionProjectCount = async (data) => {

        let obj = {}
        if (userDetails.roles.find(role => role.name === ROLENAME.ExecutionUser)) {
            obj.isUser = true
        }
        props.setLoading(true)
        try {
            const response = await HttpService.getExecutionProjectCount(userDetails.selectedExpCenter,obj)
            setExecutionProjectsCount(response.data);
        } catch (error) {
            console.error(error)
        }
        props.setLoading(false)
    }

    const getSalesDepartmentUsers = async () => {
        props.setLoading(true)
        try {
            const response = await HttpService.getSalesDepartmentUsers(userDetails.departmentId, userDetails.selectedExpCenter)
            let sortedResponse = response.data.sort((a, b) => a.name.localeCompare(b.name))
            setSalesDepartmentUsers(sortedResponse);
        } catch (error) {
            console.error(error)
        }
        props.setLoading(false)
    }

    const getLeadsOnSelectedDateByRole = () => {
        setDates({
            start: startDate,
            end: endDate
        })
        props.setUserDetails({
            ...userDetails,
            selectedStartDate: startDate,
            selectedEndDate: endDate,
            selectedFilterOption: selectedFilterOption
        })
        if (userDetails?.roles?.find(role => role.name === ROLENAME.DesignHead || role.name === ROLENAME.DesignManager  || role.name === ROLENAME.OperationDesignManager || role.name === ROLENAME.DesignUser)) {
            getDesignData();
        }
        if (userDetails?.roles?.find(role => role.name === ROLENAME.MarketingManager || role.name === ROLENAME.MarketingUser)) {
            getMarketingData();
        }
        if (userDetails?.roles?.find(role => role.name === ROLENAME.SalesHead || role.name === ROLENAME.SalesManager || role.name === ROLENAME.SalesUser || ROLENAME.AssistantSalesManager)) {
            getSalesData();
        }
    }

    const clearFilterDate = () => {
        setStartDate(moment().subtract(1, 'months').format('YYYY-MM-DD'));
        setEndDate(moment().add(1, 'days').format('YYYY-MM-DD'));
        setDates({
            start: startDate,
            end: endDate
        })
    }

    const viewLeadRoute = (pageLink) => {
        history.push(`/${pageLink}`);
    }

    const getLeadsOnSelectedDate = async (data) =>{
        props.setLoading(true)
        try {
            const response = await HttpService.getExecutionProjectCount(userDetails.selectedExpCenter,data)
            setExecutionProjectsCount(response.data);
        } catch (error) {
            console.error(error)
        }
        props.setLoading(false)
    }
    return (
        <div>
            {/* <div style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <h4>{userDetails.name} - {userDetails?.orgName ? userDetails.orgName : `Decorpot`}</h4>
            </div> */}
            {
                userDetails?.roles?.find(role => role.name === ROLENAME.SalesManager || role.name === ROLENAME.SalesHead || role.name === ROLENAME.AssistantSalesManager) &&
                <Paper>
                    <Grid container style={{ padding: 10 }}>
                        <Autocomplete
                            multiple
                            onChange={(e, v) => {
                                handleChange(e, v);
                              }}
                            
                            id="users-dropdown"
                                options={userDetails?.roles?.find(role => role.name === ROLENAME.SalesManager || role.name === ROLENAME.AssistantSalesManager) ? teamUsers : salesDepartmentUsers}
                            getOptionLabel={(option) => option.name}
                            style={{ width: 500 }}
                            filterSelectedOptions
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Select Users"
                                    placeholder="Sales Users"
                                />
                            )}
                        />
                    </Grid>
                </Paper>
            }
            <Divider />

            {userDetails?.roles?.find(role => role.name === ROLENAME.FinanceManager || role.name === ROLENAME.Admin) && <PaymentReport />}
            {userDetails?.roles?.find(role => role.name === ROLENAME.SalesManager || role.name === ROLENAME.SalesHead || role.name === ROLENAME.SalesUser || role.name === ROLENAME.AssistantSalesManager) &&
                cities.length !== 0 ?
                <>
                    <SalesUpview
                        role={userDetails.roles[0]['name']}
                        company={'Decorpot'}
                        selecteddate={dates}
                        selectedexpcenter={defaultExpCenter}
                        selectedexpcenterid={defaultExpCenterId}
                        selectedlocationid={defaultCityId}
                        selectedUserDetails={selectedUserData}
                        selectedLocation={selectedLocationDashboard}
                        isUserSelected={personName}
                        salesdata={salesData}
                        filteroption={selectedFilterOption}
                    />
                </>
                :
                null
            }
            {userDetails?.roles?.find(role => role.name !== ROLENAME.Admin && role.name !== ROLENAME.CenterHead && role.name !== ROLENAME.FinanceManager && role.name !== ROLENAME.BusinessHead && role.name !== ROLENAME.IMOSManager && role.name !== ROLENAME.IMOSUser && role.name!==ROLENAME.ProcurementExecutive && role.name !==ROLENAME.Execution_Manager ) && !userDetails?.roles?.find(role => role.name === ROLENAME.OperationHead) && <>
                <Paper className={classes.paper} style={{ marginBottom: 20, marginTop: 20 }}>
                    {userDetails.departmentId === salesDepartment && <FormControl component="fieldset" style={{ marginBottom: 20 }}>
                        <FormLabel component="legend">Select Filter Option</FormLabel>
                        <RadioGroup row aria-label="filterOption" name="filterOption1" value={selectedFilterOption} onChange={handleChangeFilterOption}>
                            <FormControlLabel value="leadWonDate" control={<Radio />} label="Lead Won Date" />
                            <FormControlLabel value="createdAt" control={<Radio />} label="Lead Created At" />
                            {/* {userDetails.departmentId === designDepartment && <FormControlLabel value="contractSignedDate" control={<Radio />} label="Contract Sign Date" />} */}
                        </RadioGroup>
                    </FormControl>
                    }
                    <br />
                    <Grid container>
                        <Grid item md={3} xs={12}>
                            <TextField
                                id="date"
                                name="startDate"
                                label="From Date"
                                type="date"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                                className={classes.textField}
                            />
                        </Grid>
                        <Grid item md={1} xs={12}></Grid>
                        <Grid item md={3} xs={12}>
                            <TextField
                                id="date"
                                name="endDate"
                                label="End Date"
                                type="date"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                                className={classes.textField}
                            />
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <Button variant="contained" color="primary" disabled={!startDate || !endDate} onClick={getLeadsOnSelectedDateByRole} >
                                Submit
                            </Button>
                        </Grid>
                        <Grid item md={2} xs={12}>
                            <Button variant="contained" color="secondary" onClick={clearFilterDate} >
                                Clear Filter
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </>
            }
             {/* {
                userDetails?.roles?.find(role=>role.name=== ROLENAME.Admin || role.name===ROLENAME.SeniorOperationManager ||role.name=== ROLENAME.OperationHead ||role.name=== ROLENAME.FactoryManager ||role.name=== ROLENAME.Store_Manager || role.name ===ROLENAME.BOMManager) &&
                <>
                <OperationOne/>
                </>
          } */}
            {
                userDetails?.roles?.find(role => role.name === ROLENAME.CHM_Manager) &&
                <>
                    <Paper>
                    
                        <h2 className={classes.paper}>All CHM's Leads</h2>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <StyledTableRow>
                                        <StyledTableCell align="center" style={{ verticalAlign: 'top' }}>Sr. No.</StyledTableCell>
                                        <StyledTableCell align="center" style={{ verticalAlign: 'top' }}>Name</StyledTableCell>
                                        <StyledTableCell align="center" style={{ verticalAlign: 'top' }}>Count</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {chmLeadCount.map((row, i) => {
                                        return (
                                            <StyledTableRow key={i + 1}>
                                                <StyledTableCell align="center" style={{ verticalAlign: 'top' }}>{i + 1}</StyledTableCell>
                                                <StyledTableCell align="center" style={{ verticalAlign: 'top' }}>{row?.name}</StyledTableCell>
                                                <StyledTableCell align="center" style={{ verticalAlign: 'top' }} component="th" scope="row">
                                                    <Link to={`/chm-user-leads/${row?.id}`} style={{ color: "#000", textDecoration: 'underline' }}>
                                                        {row?.lead_count}
                                                    </Link>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </>
            }

            {userDetails?.roles?.find(role => role.name === ROLENAME.MarketingManager || role.name === ROLENAME.MarketingUser) &&
                cities.length !== 0 ?
                <>
                    <TopHeaderOption
                        role={userDetails.roles[0]['name']}
                        company={'Decorpot'}
                        selecteddate={dates}
                        selectedexpcenter={defaultExpCenter}
                        selectedLocation={selectedLocationDashboard}
                        selectedexpcenterid={defaultExpCenterId}
                        selectedlocationid={defaultCityId}
                        marketingdata={marketingData}
                        filteroption={selectedFilterOption}
                    />

                    <Divider />

                    <CalenderFilerData
                        role={userDetails.roles[0]['name']}
                        company={'Decorpot'}
                        editable={userDetails?.roles?.find(role => role.name === ROLENAME.MarketingManager) ? true : false}
                        selecteddate={dates}
                        selectedexpcenter={defaultExpCenter}
                        selectedLocation={selectedLocationDashboard}
                        selectedexpcenterid={defaultExpCenterId}
                        selectedlocationid={defaultCityId}
                        locationExperienceCenterList={locationExpCenters}
                        marketingdata={marketingData}
                        filteroption={selectedFilterOption}
                    />
                </>
                :
                null
            }


            {userDetails?.roles?.find(role => role.name === ROLENAME.SalesManager || role.name === ROLENAME.SalesHead || role.name === ROLENAME.AssistantSalesManager) &&
                cities.length !== 0 ?
                <>
                    <TopHeaderOption
                        role={userDetails.roles[0]['name']}
                        company={'Decorpot'}
                        selecteddate={dates}
                        selectedexpcenter={defaultExpCenter}
                        selectedexpcenterid={defaultExpCenterId}
                        selectedlocationid={defaultCityId}
                        selectedUserDetails={selectedUserData}
                        selectedLocation={selectedLocationDashboard}
                        isUserSelected={personName}
                        salesdata={salesData}
                        filteroption={selectedFilterOption}
                    />

                    <Divider />

                    <CalenderFilerData
                        role={userDetails.roles[0]['name']}
                        company={'Decorpot'}
                        editable={true}
                        selecteddate={dates}
                        selectedexpcenter={defaultExpCenter}
                        selectedexpcenterid={defaultExpCenterId}
                        selectedlocationid={defaultCityId}
                        selectedUserDetails={selectedUserData}
                        selectedLocation={selectedLocationDashboard}
                        isUserSelected={personName}
                        locationExperienceCenterList={locationExpCenters}
                        salesdata={salesData}
                        filteroption={selectedFilterOption}
                    />
                </>
                :
                null
            }

            {userDetails?.roles?.find(role => role.name === ROLENAME.SalesUser) &&
                cities.length !== 0 ?
                <>
                    <TopHeaderOption
                        role={userDetails.roles[0]['name']}
                        company={'Decorpot'}
                        selecteddate={dates}
                        selectedexpcenter={defaultExpCenter}
                        selectedexpcenterid={defaultExpCenterId}
                        selectedlocationid={defaultCityId}
                        selectedLocation={selectedLocationDashboard}
                        salesdata={salesData}
                        filteroption={selectedFilterOption}
                    />

                    <Divider />

                    <CalenderFilerData
                        role={userDetails.roles[0]['name']}
                        company={'Decorpot'}
                        editable={true}
                        selecteddate={dates}
                        selectedexpcenter={defaultExpCenter}
                        selectedexpcenterid={defaultExpCenterId}
                        selectedlocationid={defaultCityId}
                        selectedLocation={selectedLocationDashboard}
                        salesdata={salesData}
                        filteroption={selectedFilterOption}
                    />
                </>
                :
                null
            }

    {userDetails?.roles?.find(role => role.name === ROLENAME.DesignUser || role.name === ROLENAME.DesignManager || role.name === ROLENAME.DesignHead || role.name === ROLENAME.AssistantDesignManager|| role.name === ROLENAME.OperationDesignManager) &&
                designData.length !== 0 ?
                <>
                    <ContractSignLeads
                        ROLE={userDetails.roles}
                        TITLE={'Tentative Contract Sign Leads'}
                        COMPANY={'Decorpot'}
                        SELECTEDDATES={dates}
                        SELECTEDEXPCENTER={defaultExpCenter}
                        DATA={designData}
                        selectedexpcenterid={defaultExpCenterId}
                        selectedlocationid={defaultCityId}
                    />
                    <Divider />

                    <ProposedContractForMonth
                        ROLE={userDetails.roles[0]['name']}
                        TITLE={'Proposed contract for this month'}
                        COMPANY={'Decorpot'}
                        SELECTEDDATES={dates}
                        SELECTEDEXPCENTER={defaultExpCenter}
                        DATA={designData}
                        selectedexpcenterid={defaultExpCenterId}
                        selectedlocationid={defaultCityId}
                    />
                    <Divider />

                    <ActiveCustomersList
                        ROLE={userDetails.roles[0]['name']}
                        TITLE={'Active Customer'}
                        COMPANY={'Decorpot'}
                        editable={true}
                        SELECTEDDATES={dates}
                        SELECTEDEXPCENTER={defaultExpCenter}
                        DATA={designData}
                        selectedexpcenterid={defaultExpCenterId}
                        selectedlocationid={defaultCityId}
                    />
                    <Divider />

                    <DesignHoldCustomerList
                        ROLE={userDetails.roles[0]['name']}
                        TITLE={'Design Hold Customers'}
                        COMPANY={'Decorpot'}
                        editable={true}
                        SELECTEDDATES={dates}
                        SELECTEDEXPCENTER={defaultExpCenter}
                        DATA={designData}
                        selectedexpcenterid={defaultExpCenterId}
                        selectedlocationid={defaultCityId}
                    />
                    <Divider />

                    <DesignPerformance
                        ROLE={userDetails.roles[0]['name']}
                        TITLE={'Performance for this month'}
                        COMPANY={'Decorpot'}
                        SELECTEDDATES={dates}
                        SELECTEDEXPCENTER={defaultExpCenter}
                        DATA={designData}
                        selectedexpcenterid={defaultExpCenterId}
                        selectedlocationid={defaultCityId}
                    />
                </>
                :
                null
            }
            {userDetails?.roles?.find(role => role.name === ROLENAME.CenterHead) &&
                designData.length !== 0 ?
                <>
                    <ContractSignLeads
                        ROLE={userDetails.roles}
                        TITLE={'Tentative Contract Sign Leads'}
                        COMPANY={'Decorpot'}
                        SELECTEDDATES={dates}
                        SELECTEDEXPCENTER={defaultExpCenter}
                        DATA={designData}
                        selectedexpcenterid={defaultExpCenterId}
                        selectedlocationid={defaultCityId}
                    />
                </>
                :
                null
            }

            {
                userDetails?.roles?.find(role => role.name === ROLENAME.DocumentUploader) &&
                <Paper>
                    <div style={{ display: 'flex', margin: 20, textAlign: 'center' }}>
                        <Grid container spacing={6} style={{marginTop: 20}}>
                            <Grid item xs={12} sm={6}>
                                <Card >
                                    <CardContent>
                                        <Typography variant="body2" component="p">
                                            Ready To Dispatch Leads
                                        </Typography>
                                        <Button size="small" color="primary" onClick={() => viewLeadRoute('ready-to-dispatch-project')}> {docUploaderCount.readyToDispatch}</Button>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                        <div style={{ textAlign: "center", justifyContent: "center",marginBottom:15}}>
                            <h3 style={{ marginBottom: 15 }}>Leads To Be Dispatched</h3>
                        <ReadyToDispatchProject />
                    </div>
                </Paper>
            }

            {
                userDetails?.roles?.find(role => role.name === ROLENAME.IMOSManager || role.name === ROLENAME.IMOSUser) &&
                    imostProjectsCount.length !== 0 ?
                    <>
                        <ImosDashboard
                            ROLE={userDetails.roles[0]['name']}
                            TITLE={'IMOS Data'}
                            COMPANY={'Decorpot'}
                            SELECTEDDATES={dates}
                            SELECTEDEXPCENTER={defaultExpCenter}
                            DATA={imostProjectsCount}
                            selectedexpcenterid={defaultExpCenterId}
                            selectedlocationid={defaultCityId}
                        />
                    </>
                    :
                    null
            }
               {
                userDetails?.roles?.find(role => role.name === ROLENAME.Execution_Manager || role.name === ROLENAME.ExecutionUser) && 
                executionProjectsCount.length !==0 ?
                <>
                <ExecutionBoard
                ROLE={userDetails.roles[0]['name']}
                TITLE={'Execution Data'}
                onChildDataChange={getExecutionProjectCount}
                COMPANY={'Decorpot'}
                SELECTEDDATES={dates}
                SELECTEDEXPCENTER={defaultExpCenter}
                DATA={executionProjectsCount}
                selectedexpcenterid={defaultExpCenterId}
                selectedlocationid={defaultCityId}
                onChildData={getLeadsOnSelectedDate}
            />
            </>
            : null
            }
        </div>
    )
}
export default connect(null, { setUserDetails, setLoading, setSnackInfo })(Index);