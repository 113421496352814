import React, { useEffect, useState, useRef } from 'react'
import { connect, useSelector } from "react-redux";
import { setLoading, setSnackInfo } from '../redux/actions/appAction'
import { Table, TableBody, TextField, TableCell, TableHead, TableContainer, TableRow, withStyles, Paper, TableFooter, TablePagination, Switch, FormControlLabel, Button, FormControl, FormLabel, Radio, RadioGroup } from '@material-ui/core';
import { getAllMaterials } from '../redux/actions/vendorAction';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { CSVLink } from "react-csv";
import HttpService from '../services/httpService';
import AutoSelectOpenPoModal from './AutoSelectOpenPoModal';
import { Modal } from 'antd';
const useStyles2 = makeStyles({
  table: {
    minWidth: 500,
  },
  textField: {
    width: "150px"
  },
});
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#ccc",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
const MultiCheckCreatePo = (props) => {
  const classes = useStyles2();
  const [page, setPage] = useState(0);
  const [allMoqFilteredMaterials,setAllMoqFilteredMaterials] = useState([]);
  const [csvData, setCsvData] = useState([])
  const [stockType, setStockType] = useState('store');
  const [limit, setLimit] = useState(100)
  const [paginatedData, setPaginatedData] = useState([])
  const [raiseOpenPOModal, setRaiseOpenPoModal] = useState(false)
  const [arrChecked, setArrchecked] = useState([])
  const [selectAll, setSelectAll] = useState(null)
	const csvButtonRef = useRef(null);
  const headers = [
		{ key: "slno", label: "Sl No" },
		{ key: "materialname", label: "Material Name" },
		{ key: "materialcode", label: "Material Code" },
		{ key: "consumedqty", label: "Consumed Qty" },
		{ key: "availableqty", label: "Available Qty" },
		{ key: "orderedQuantity", label: "Ordered Qty" },
		{ key: "moq", label: "MOQ" }
	];
  useEffect(() => {
    getAllMaterials()
    // eslint-disable-next-line
  }, [stockType])

  function removeSpecialCharacters(str) {
    // Use a regular expression to match any characters that are not letters, numbers, or spaces
    const regex = /[^A-Za-z0-9\s-_\/]/g;
    // Replace the matched characters with an empty string
    return str.replace(regex, '');
  }
  
  //sets allMoqFilteredMaterials, paginatedData, arrChecked, selectAll, page, limit, csvData
  const getAllMaterials = async () => {
    props.setLoading(true)
    try {
      
      const response = await HttpService.getAllMaterialsStockAndMoq(stockType);
      const orderedData = await HttpService.getOrderedMaterialsQty(stockType.charAt(0).toUpperCase() + stockType.slice(1));
      
      const arr = response.data.filter((el) => {
        return (Number(el.stocks[`${stockType}`].available) <= Number(el.moq))
      });
      
      arr.forEach((el)=>{el.checkedBox = false; el.orderedQuantity = orderedData.data.myObject[`${el._id}`] ?? 0})

      setAllMoqFilteredMaterials(arr);
      setPaginatedData(arr.slice(0,limit))
      let csvArrData = []
      arr.forEach((el,i)=>{
        let newObj = {
          slno:i+1,
          materialname:el.name || 'N/A',
          materialcode:el.code || 'N/A',
          consumedqty:el?.stocks?.[`${stockType}`]?.consumed || 'N/A',
          availableqty:el?.stocks?.[`${stockType}`]?.available || 'N/A',
          moq:el?.moq || 'N/A',
          orderedQuantity: orderedData.data.myObject[`${el._id}`] || 0,
        }
        newObj.materialname = removeSpecialCharacters(newObj.materialname) ?? 'N/A';
        newObj.materialcode = removeSpecialCharacters(newObj.materialcode) ?? 'N/A';
        csvArrData.push(newObj)
      })
    setCsvData(csvArrData)
    setSelectAll(null)
    setArrchecked([])
    setLimit(100)
    setPage(0);
    } 
    catch (error) {
      console.error(error)
      props.setSnackInfo(error?.response?.data?.message ?? "Something Went Wrong", "error")
    }
    props.setLoading(false)
  }
  
  const handleStocksChange = (event) => {
    setStockType(event.target.value);
  };
  const handleCheckedChange = (event, index) => {
    const checked = event.target.checked;
    const val = event.target.value;
    let newArr = allMoqFilteredMaterials
    newArr[page * limit + index].checkedBox = checked;
    setAllMoqFilteredMaterials(newArr)
    if(checked){
        setArrchecked([...arrChecked, val])
    }
    else{
        setArrchecked(arrChecked.filter((item)=>{return item !== val}))
    }
  };
  const handleSwitchChange = (event) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      props.setSnackInfo('All Materials Selected')
      setSelectAll(true)  //for disabling input field when select all is on
      setArrchecked(allMoqFilteredMaterials.map(data=>data?.code))
      const arr = allMoqFilteredMaterials.map((el) => 
      {return { ...el, checkedBox: true };}
      )
      setAllMoqFilteredMaterials(arr);
      setPaginatedData(arr.slice(page*limit, (page+1)*limit))
    } else {
      // Switch is off
      setSelectAll(false)
      setArrchecked([])
      const arr = allMoqFilteredMaterials.map((el) => 
      {return { ...el, checkedBox: false };}
      )
      setAllMoqFilteredMaterials(arr);
      setPaginatedData(arr.slice(page*limit, (page+1)*limit))
    }
  }
  
  const handleDialogClose = async () => {
    setRaiseOpenPoModal(false)
    setTimeout(()=>{
      window.location.reload()
    },500)
  }
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setPaginatedData(allMoqFilteredMaterials.slice(newPage*limit, (newPage+1)*limit))
  };
  const handleChangeRowsPerPage = (event) => {
    const newLimit = parseInt(event.target.value, 10)
    setLimit(newLimit);
    setPage(0);
    setPaginatedData(allMoqFilteredMaterials.slice(0, newLimit))
  };
  
  const csvDownloadHandler = async () => {
		setLoading(true);
		try {
			setCsvData(csvData);
			downloadExcel();
		} catch (error) {
			console.log("error", error);
			props?.setSnackInfo(
				error?.response?.data?.message ?? "Something went wrong",
				"error"
			);
		}
		setLoading(false);
	};
	const downloadExcel = () => {
		setLoading(true);
		try {
			setTimeout(() => {
				csvButtonRef.current.link.click();
			});
		} catch (err) {
			console.error(err.message);
		}
		setLoading(false);
	};
  return (
    <div>
      {/* To select the type of stock type be called from backend */}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <FormControl>
          <FormLabel id="demo-controlled-radio-buttons-group">
            Change Selection
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={stockType}
            onChange={handleStocksChange}
            row
          >
            <FormControlLabel value="store" control={<Radio />} label="store" />
            <FormControlLabel
              value="factory"
              control={<Radio />}
              label="factory"
            />
          </RadioGroup>
        </FormControl>
      <div style={{ display: 'flex', justifyContent: "space-between", alignItems:'center' }}>
      <Button variant="contained" color="primary" style={{ alignSelf: "center", height:30 }} onClick={()=>{setRaiseOpenPoModal(true)}}>CREATE OPEN PO</Button>
        <CSVLink
						headers={headers}
						filename={"Moq Materials for "+ stockType + ".csv"}
						data={csvData}
						ref={csvButtonRef}
					/>
					<Button
						onClick={csvDownloadHandler}
						style={{ marginLeft: 20, backgroundColor: "green" , color:'white', height: 30 }}
						variant="contained"
						color="success"
					>
						Download CSV
					</Button>
          <div>
        </div>
      </div>
      </div>
      {/* Have to Revise */}
      <Modal
				centered
				visible={raiseOpenPOModal}
				onCancel={handleDialogClose}
				width={1000}
				maskClosable={false}
				footer={null}
			>
				<AutoSelectOpenPoModal MATERIALS={arrChecked} STOCKTYPE={stockType} CLOSEMODAL={ async () => {setRaiseOpenPoModal(false); setTimeout(()=>{window.location.reload()},500)}} />
        </Modal>
      {/* Switch To Select All Materials To Be Ordered */}
      {/* <FormControlLabel
          value="end"
          control={
          <Switch
          color="primary"
          onChange={(e) => { handleSwitchChange(e);}}
          disabled={true}
          />
        }
        label="Select All Materials To Order"
        labelPlacement="end"
        /> */}
      {/* Table with Material Data Below Moq to be Ordered */}
      <TablePagination
        rowsPerPageOptions={[20,50,100,250,500, { label: "All", value: allMoqFilteredMaterials.length }]}
        component="div"
        count={allMoqFilteredMaterials.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={limit}
        onRowsPerPageChange={handleChangeRowsPerPage}
        />
      <TableContainer component={Paper}>
        <Table
          // stickyHeader
          stickyHeader
          aria-label="sticky table"
          className={classes.table}
          size="small"
          // aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>Sl No</StyledTableCell>
              <StyledTableCell>Material Name</StyledTableCell>
              <StyledTableCell>Material Code</StyledTableCell>
              <StyledTableCell>Consumed Qty</StyledTableCell>
              <StyledTableCell>Available Qty</StyledTableCell>
              <StyledTableCell>Ordered Qty</StyledTableCell>
              <StyledTableCell>MOQ</StyledTableCell>
              <StyledTableCell>Open PO Raise</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedData.map((data, i) => (
              <StyledTableRow key={page*limit + i + 1}>
                <StyledTableCell>{page*limit + i + 1}</StyledTableCell>
                <StyledTableCell>{data?.name}</StyledTableCell>
                <StyledTableCell>{data?.code}</StyledTableCell>
                <StyledTableCell>{parseFloat(data?.stocks?.[`${stockType}`]?.consumed)?.toFixed(2) || 'N/A'}</StyledTableCell>
                <StyledTableCell>{parseFloat(data?.stocks?.[`${stockType}`]?.available)?.toFixed(2) || 'N/A'}</StyledTableCell>
                <StyledTableCell>{data?.orderedQuantity}</StyledTableCell>
                <StyledTableCell>{data?.moq}</StyledTableCell>
                <StyledTableCell>
                  <input
                    type="checkbox"
                    id={data?.code}
                    value={data?.code}
                    onChange={(e)=>{handleCheckedChange(e,i)}}
                    checked={data.checkedBox}
                    disabled={selectAll}
                  ></input>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
export default connect(null, { setLoading, setSnackInfo, getAllMaterials })(MultiCheckCreatePo)
